export default [
    {
        obec: "Abertamy",
        kod: 554979,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36235,
        latitude: 50.368855,
        longitude: 12.818377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Abertamy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, v poměru 6 : 1 : 1. Uprostřed pod žlutou liliovou korunou bílá hornická kladívka na žlutých topůrkách, držená dvěma bílými přivrácenými lvy s červenými jazyky a žlutou zbrojí, stojícími na horním okraji žlutého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná hornická kladívka na zlatých topůrkách."
    },
    {
        obec: "Adamov",
        kod: 535826,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 49.000624,
        longitude: 14.539603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Adamov_Ceske_Budejovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a osm střídavě bílých a červených kosmých pruhů. V žerďovém pruhu černé písmeno A nad obrácenou kotvou převýšenou korunou, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve druhém červeném poli tři kosmo položená stříbrná břevna. Nad znakem je položená stříbrná kolčí přilba zdobená zlatou korunou. Klenotem je polcený pták s pravým polem barvy červené a levým polem barvy černé. Znak je konečně ozdoben červeno-černými přikryvadly."
    },
    {
        obec: "Adamov",
        kod: 581291,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67904,
        latitude: 49.295708,
        longitude: 16.663955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Adamov_Blansko_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený v poměru 1 : 3 : 1. V bílém pruhu černá kvádrovaná vysoká pec, z níž šlehají červené plameny. Pod pecí hnědá ostrev o třech sucích, jedním k žerďovému okraji listu a horní polovinou v prázdném topeništi. Nad plameny černé kolo, horní polovina ozubená. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá kvádrovaná vysoká pec s prázdným topeništěm, z níž šlehají červené plameny. Nad nimi černé kolo s horní ozubenou polovinou. Pod pecí postavená hnědá ostrev o třech sucích."
    },
    {
        obec: "Adamov",
        kod: 531367,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.857917,
        longitude: 15.409018
    },
    {
        obec: "Adršpach",
        kod: 547786,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54957,
        latitude: 50.624387,
        longitude: 16.083501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Adr%C5%A1pach_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový klín s vrcholem ve středu listu a tři vodorovné pruhy modrý, bílý a modrý. Ve žlutém klínu modrý květ lnu se zeleným středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě, pod zvýšeným zlatým klínem s modrým květem lnu se zeleným středem na zeleném trávníku, prázdná stříbrně kvádrovaná gotická brána s otevřenými zlatými vraty mezi stříbrnými skalami."
    },
    {
        obec: "Albrechtice",
        kod: 598925,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73543,
        latitude: 49.786552,
        longitude: 18.524545,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Znak_mesta_albrechtice.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh, široký jednu čtvrtinu délky listu, a tři vodorovné pruhy, červený, bílý a červený. V modrém pruhu zelený květ hvězdnatce, provázený nahoře a dole žlutými hornickými kladívky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou stříbrný kůl. V hlavě květ hvězdnatce přirozené barvy mezi zlatými hornickými kladívky."
    },
    {
        obec: "Albrechtice",
        kod: 547981,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.927643,
        longitude: 16.644801,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Albrechtice_%28Usti_nad_Orlici%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří sedm kosmých pruhů, bílý, červený, bílý, modrý, bílý, červený a bílý, v poměru 3:1:2:4:2:1:3. V modrém pruhu tři čelně hledící bílé lví hlavy s červenými jazyky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé modré břevno se třemi čelně hledícími stříbrnými lvími hlavami s červenými jazyky, provázené nahoře položenou radlicí a dole kosmo vztyčeným krojidlem, obojí červené."
    },
    {
        obec: "Albrechtice nad Orlicí",
        kod: 576077,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51722,
        latitude: 50.139886,
        longitude: 16.064472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Albrechtice_nad_Orlic%C3%AD.png",
        coatOfArmsFlagDescription: "List praporu tvoří pět vodorovných pruhů - červený, bílý, modrý, bílý a červený v poměru 2:1:2:1:2. Modrý pruh je zvlněný. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít. Vpravo dvě modrá vlnitá břevna, vlevo stříbrná vykořeněná borovice."
    },
    {
        obec: "Albrechtice nad Vltavou",
        kod: 549258,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39816,
        latitude: 49.253337,
        longitude: 14.302929,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. V zeleném pruhu žlutý klíč zuby nahoru a k žerďovému okraji a bílý meč hrotem dolů se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-stříbrně vlevo vlnitě polcený štít. V zeleném poli zlatý vztyčený klíč zuby ven a stříbrný postavený meč se zlatým jílcem."
    },
    {
        obec: "Albrechtice v Jizerských horách",
        kod: 563528,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46843,
        latitude: 50.7624,
        longitude: 15.275813,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Albrechtice_v_Jizersk%C3%BDch_hor%C3%A1ch_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým pětiúhelníkovým polem jehož vrcholy jsou v polovině a sedmé osmině žerďového a vlajícího okraje a uprostřed horního okraje listu. Na dolním okraji bílého pole dva zelené vrchy, z každého vyrůstá zelený smrk s hnědým kmenem, mezi nimi červené mlýnské vodní korečkové kolo na hřídeli s položeným černým lopatkovým čepem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se zvýšeným stříbrným hrotem na zúžené modré patě červené mlýnské vodní korečkové kolo na hřídeli s položeným černým lopatkovým čepem provázené dvěma zelenými vrchy, každý s vyrůstajícím smrkem přirozené barvy."
    },
    {
        obec: "Albrechtičky",
        kod: 568741,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74255,
        latitude: 49.70179,
        longitude: 18.095627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Znak_obce_Albrechti%C4%8Dky.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1:2:1. V modrém pruhu žlutá berla závitem k žerdi, na ní ve spodní části bílý plod kotvice plovoucí se čtyřmi žlutými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá berla přeložená stříbrným plodem kotvice plovoucí se čtyřmi zlatými listy a provázená nahoře dvěma stříbrnými liliemi."
    },
    {
        obec: "Alojzov",
        kod: 506761,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79804,
        latitude: 49.423541,
        longitude: 17.041589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Alojzov_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený. Uprostřed vztyčená zelená větev s pěti (1,2,2) listy, na horním sedí černý pták. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě vztyčená zelená větev s pěti (1,2,2) listy, na horním sedí černý pták."
    },
    {
        obec: "Andělská Hora",
        kod: 538001,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36471,
        latitude: 50.20449,
        longitude: 12.962918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Andelskk_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list posypaný žlutými šesticípými hvězdami. Uprostřed archanděl Michael v bílém rouchu se žlutou svatozáří držící v pravé ruce nad hlavou šikmo bílý plamenný meč se žlutým jílcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém, zlatými hvězdami posypaném štítě archanděl Michael ve stříbrném šatě a se zlatou svatozáří, držící v pravé ruce nad hlavou šikmo stříbrný plamenný meč se zlatým jílcem a levicí se opírající o zlatý štít. V něm tři (2, 1) štítky. První zlatý s černým ondřejským křížem, druhý stříbrný, třetí černý se zlatým lvem s červenou zbrojí a korunou."
    },
    {
        obec: "Andělská Hora",
        kod: 551929,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79331,
        latitude: 50.060911,
        longitude: 17.389192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/And%C4%9Blsk%C3%A1-hora_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a modrý v poměru 5 : 1 : 2. V horním pruhu tři žluté lilie vedle sebe. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Anenská Studánka",
        kod: 573426,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.849856,
        longitude: 16.54033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Anensk%C3%A1_Stud%C3%A1nka_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý se žlutou lilií v horní polovině, bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku s položenou stříbrnou radlicí stříbrná fontána v prázdném stříbrném altánu s červenou kupolí zakončenou zlatou makovicí. V horních rozích dvě zlaté lilie."
    },
    {
        obec: "Archlebov",
        kod: 586030,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69633,
        latitude: 49.043689,
        longitude: 17.004911,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Archlebov_znak.JPG",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý se žlutým sluncem bez tváře a žlutý se zeleným vinným hroznem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý polský plot se dvěma vyššími kůly po stranách a zeleným vinným hroznem uprostřed. Za ním vyrůstají dvě borovice přirozených barev se zlatými slaměnými víchy na kmenech, mezi nimi zlaté slunce bez tváře."
    },
    {
        obec: "Arneštovice",
        kod: 509388,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.53161,
        longitude: 15.117873
    },
    {
        obec: "Arnolec",
        kod: 586854,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.436298,
        longitude: 15.823702,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Arnolec_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se třemi zelenými žerďovými klíny s vrcholy v první třetině délky listu. Ve střední a vlající části červená orlice se žlutou korunou a s černo-bíle čtvrceným štítkem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším s uprostřed vytékajícím stříbrno-modrým pramenem, červená korunovaná orlice s černo-stříbrně čtvrceným štítkem na hrudi."
    },
    {
        obec: "Arnoltice",
        kod: 562343,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40714,
        latitude: 50.837124,
        longitude: 14.264055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Arnoltice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v každém žerďový klín sahající do třetiny délky listu. V modrých pruzích klíny žluté, v bílém zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným trojvrším dva andělé ve zlatých řízách, se stříbrnými křídly a zlatými svatozářemi, přidržující mezi sebou nahoře stříbrnou lilii."
    },
    {
        obec: "Babice",
        kod: 537241,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.024865,
        longitude: 14.237677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Babice_Pt_znak.JPG",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, bílý, modrý a žlutý, v poměru 2 : 3 : 6 : 3 : 2. V bílém pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný zvon s převrácenou polovinou zlaté lilie na místě srdce na zlatém závěsu, kterým prochází zúžené stříbrné břevno. Zvon provázejí dva položené odvrácené zlaté lipové listy. Ve zvonu červená růže se zlatým semeníkem."
    },
    {
        obec: "Aš",
        kod: 554499,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35201,
        latitude: 50.223983,
        longitude: 12.195113,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Coat_of_arms_of_A%C5%A1%2C_Cheb.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi bílými vlajícími klíny s vrcholy v polovině délky listu. V žerďové polovině tři bílí lipani pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři stříbrní lipani pod sebou."
    },
    {
        obec: "Babice",
        kod: 590274,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.135413,
        longitude: 15.770586,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Coa_Czech_Town_Babice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý oboustranně zubatý posunutý se dvěma zuby vysokými poloviny šířky pruhu a třemi stejnými mezerami a červený, v poměru 2 : 1 : 6. V dolním pruhu tři (2,1) žluté propletené kruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad třemi zlatými propletenými kruhy (2,1) stříbrné, oboustranně střídavě cimbuřovité břevno."
    },
    {
        obec: "Babice",
        kod: 569828,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.192196,
        longitude: 15.585617
    },
    {
        obec: "Babice",
        kod: 552356,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.737929,
        longitude: 17.260966,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zvlněný bílý, modrý a žlutý, v poměru 3:5:5. V modrém pruhu půl žluté osmicípé hvězdy přiléhající k půli modré lilie ve žlutém pruhu. Zvlněný pruh má tři vrcholy a čtyři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě nad stříbrnou vlnitou patou přiléhající půl osmihroté hvězdy a půl lilie opačných barev."
    },
    {
        obec: "Babice",
        kod: 538043,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 50.008089,
        longitude: 14.715907,
        coatOfArmsFlagDescription: "Modro-zeleně šikmo dělený list. Uprostřed žlutý korunovaný drak s červenou zbrojí, pod ním bílý maltézský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně šikmo děleném štítě zlatý korunovaný drak s červenou zbrojí, pod ním v zeleném poli stříbrný maltézský kříž."
    },
    {
        obec: "Babice",
        kod: 592013,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68703,
        latitude: 49.123859,
        longitude: 17.476827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Babice%2C_znak.png",
        coatOfArmsFlagDescription: "Modro-červený list šikmo dělený čtyřmi, střídavě bílými a modrými pruhy, v poměru 12 : 1 : 1 : 1 : 1 : 11. V modrém poli svěšená bílá větev se čtyřmi listy, žlutou hruškou a jablkem, v červeném žlutá hlavice berly, závitem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně vlevo polcený štít, vpravo nad dvěma stříbrnými vlnitými břevny svěšená stříbrná větev se čtyřmi listy, zlatou hruškou a jablkem, vlevo zlatá berla se stříbrným sudariem, závitem doleva."
    },
    {
        obec: "Babice nad Svitavou",
        kod: 582794,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66401,
        latitude: 49.283402,
        longitude: 16.696235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Znak_Babice_nad_Svitavou.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým klínem s vrcholem ve druhé třetině délky listu. V klínu modrý tlapatý procesní kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě snížený zlatý hrot s modrým tlapatým procesním křížem, provázený vpravo vztyčeným zlatým dubovým listem, vlevo vyrůstajícím zlatým smrkem s černým kmenem."
    },
    {
        obec: "Babylon",
        kod: 553433,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.398761,
        longitude: 12.862893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Babylon_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a modrý, v poměru 10:1:1. V zeleném pruhu šikmo žlutá sekera ostřím k dolnímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě dělený štít s modrou patou, v horním poli šikmo položená zlatá sekera."
    },
    {
        obec: "Babice u Rosic",
        kod: 582808,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.173078,
        longitude: 16.358273,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Babice_u_Rosic_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným šikmým pruhem širokým polovinu šířky listu. V červeném pruhu bílá radlice hrotem nahoru a ostřím k žerdi. V horním rohu černá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně zvýšeně šikmo děleném štítě nahoře uprostřed černá hornická kladívka, vpravo antonínský kříž provázený dole dvěma vztyčenými odkloněnými lipovými listy, vše zelené, dole vztyčená, doleva obrácená stříbrná radlice."
    },
    {
        obec: "Bácovice",
        kod: 561118,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.455771,
        longitude: 15.158118
    },
    {
        obec: "Bačalky",
        kod: 553701,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.385047,
        longitude: 15.15353
    },
    {
        obec: "Bačetín",
        kod: 576085,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.299877,
        longitude: 16.233416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Bacetin_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:6:1. V modrém pruhu žlutý žerďový klín s vrcholem ve středu listu, ve vlající polovině žluté slunce bez obličeje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nahoře vpravo vykračující kůň, vlevo doleva obrácená radlice, pod nimi slunce provázené dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Bačice",
        kod: 590282,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.081736,
        longitude: 16.027802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Ba%C4%8Dice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý. V horním a dolním rohu po jednom bílém čtyřlistém květu se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená, doleva obrácená zlatá radlice provázená dvěma stříbrnými čtyřlistými květy se zlatými středy na prohnutých stříbrných stoncích."
    },
    {
        obec: "Bačkov",
        kod: 546194,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.735384,
        longitude: 15.462118,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/CoA_of_Ba%C4%8Dkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý a červený, s pěti šesticípými hvězdami (2,2,1), v černém a červeném pruhu žlutými, v bílém modrými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-stříbrno-červeně děleném štítě pět (2,2,1) hvězd, nahoře a dole zlaté, uprostřed modré."
    },
    {
        obec: "Bačkovice",
        kod: 544833,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.965699,
        longitude: 15.589
    },
    {
        obec: "Bakov nad Jizerou",
        kod: 535427,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29401,
        latitude: 50.482406,
        longitude: 14.941596,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Bakov_nad_Jizerou_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílým lemem širokým jednu desetinu šířky listu. Uprostřed bílá čtyřhranná kvádrovaná věž s prázdnou branou, valbovou střechou a cimbuřím, dvěma okny vedle sebe na čelní stěně a dvěma pod sebou na boční stěně. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Baliny",
        kod: 595217,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.332666,
        longitude: 15.952501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Baliny_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, zelený, bílý a modrý, v poměru 1 : 1 : 1 : 1 : 4. V modrém pruhu bílý mlýnský kámen s černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrným vlnitým břevnem dělený štít. Nahoře stříbrná zeď do krajů, uprostřed se stříbrnou branou s černou valbovou střechou a otevřenými zlatými vraty na černých závěsech. Dole stříbrný mlýnský kámen s černou kypřicí."
    },
    {
        obec: "Balkova Lhota",
        kod: 563251,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.452124,
        longitude: 14.608319
    },
    {
        obec: "Banín",
        kod: 572560,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.665712,
        longitude: 16.46203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Ban%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří osm šikmých pruhů, bílý, zelený, bílý, hnědý, bílý, červený, žlutý a červený, v poměru 7:3:5:3:15:15:3:15. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně šikmo děleném štítě nahoře dubová větévka se vztyčeným žaludem a svěšeným listem přirozené barvy, dole zlatý kalich s hostií."
    },
    {
        obec: "Báňovice",
        kod: 562726,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.011855,
        longitude: 15.49034
    },
    {
        obec: "Bantice",
        kod: 593729,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.883187,
        longitude: 16.182493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Bantice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, v horní části knížecí čepice, pod ní bílá radlice ostřím k žerďovému okraji listu a žlutý vinný hrozen se stonkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod knížecí čepicí vpravo stříbrná radlice a vlevo zlatý vinný hrozen se stonkem."
    },
    {
        obec: "Barchov",
        kod: 569836,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.197698,
        longitude: 15.56774,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý žlutý, v poměru 1:4. Žlutý pruh má tři obdélníkové zuby a dvě stejné mezery, poměr výšky k délce zubů je 1:4. V modrém pruhu, v mezerách zubů, po jedné žluté šesticípé hvězdě. Ve žlutém pruhu černý kohout s červenou zbrojí vykračující k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou cimbuřovou hlavou se dvěma stínkami a dvěma zlatými hvězdami nad nimi doleva vykračující černý kohout s červenou zbrojí."
    },
    {
        obec: "Barchov",
        kod: 574724,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.999411,
        longitude: 15.68091,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Barchov_%28Pardubice_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a modrých, v poměru 12 : 1 : 1 : 1 : 1. Druhý až čtvrtý pruh vlnitý s pěti vrcholy a čtyřmi prohlubněmi, pátý zvlněný. V horním pruhu tři zelené vrbové pruty s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři zelené vrbové pruty s listy nad dvěma modrými vlnitými břevny v patě štítu."
    },
    {
        obec: "Bánov",
        kod: 592021,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68754,
        latitude: 48.988113,
        longitude: 17.717631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Coat_of_arms_of_B%C3%A1nov.svg",
        coatOfArmsFlagDescription: "List tvoří žerďová část o šířce jedné třetiny délky listu rozdělená na osm vodorovných pruhů střídavě červených a bílých a červená vlající část s bílým, k vlajícímu okraji vykračujícím kohoutem se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným lemem posázeným červenými růžemi stříbrný, na zeleném návrší doleva vykračující kohout se zlatou zbrojí (lalok i hřeben rovněž zlatý)."
    },
    {
        obec: "Barchovice",
        kod: 533173,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.944355,
        longitude: 14.969206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Barchovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený. V horní části listu u styku pruhů v bílém pruhu zkřížené černé hornické špičáky na šedých násadách, v červeném pruhu u styku bílá hlava jednorožce s krkem se žlutou zbrojí a jazykem. V dolní části listu položený černě okřídlený žlutý orlí pařát, křídlem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít. Nahoře vpravo zkřížené černé hornické špičáky na dřevěných násadách přirozené barvy, vlevo stříbrná hlava jednorožce s krkem se zlatou zbrojí a jazykem. Dole doleva obrácený položený černě okřídlený zlatý orlí pařát."
    },
    {
        obec: "Bartošovice",
        kod: 599212,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74254,
        latitude: 49.66894,
        longitude: 18.054715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Barto%C5%A1ovice_znak.svg",
        coatOfArmsFlagDescription: "Červený list s kosmo položeným mečem hrotem nahoru šikmo přeloženým bílým klíčem se zuby dolů. Ve vlající části bílý svislý pruh široký jednu dvanáctinu délky listu se čtyřmi trojúhelníkovými zuby sahajícími do poslední čtvrtiny listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad čtyřmi sníženými hroty kosmo vztyčený meč šikmo přeložený klíčem zuby dolů, vše stříbrné."
    },
    {
        obec: "Bartošovice v Orlických horách",
        kod: 576093,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51761,
        latitude: 50.16408,
        longitude: 16.547862,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Barto%C5%A1ovice_v_Orlick%C3%BDch_hor%C3%A1ch_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1 : 6 : 1. V žerďové polovině bílého pruhu hnědá medvědí hlava s bílými zuby a červeným jazykem, ve vlající polovině tři modré květy orlíčku obecného, jeden u hlavy a dva pod sebou u vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kvádrovaný most s jedním obloukem, převýšený třemi modrými květy orlíčku obecného se zlatými středy. V oblouku medvědí hlava přirozené barvy s červeným jazykem."
    },
    {
        obec: "Bartoušov",
        kod: 548260,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.601128,
        longitude: 15.618547
    },
    {
        obec: "Bařice-Velké Těšany",
        kod: 588300,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.245527,
        longitude: 17.426201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Coat_of_Ba%C5%99ice-Velk%C3%A9_T%C4%9B%C5%A1any.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, každý dělený v poměru 3 : 1, žerďový červeno-bíle a vlající bílo-zeleně. V červeném poli žlutý snop, v horním bílém poli zelená lípa s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě vpravo zlatý snop nad doleva položenou stříbrnou radlicí, vlevo lípa přirozené barvy nad položenou zelenou radlicí."
    },
    {
        obec: "Baška",
        kod: 598011,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73901,
        latitude: 49.645943,
        longitude: 18.372443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Ba%C5%A1ka_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "Červený list s bílou ovcí a žlutým leknínovým trojlistem v horním vlajícím cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná ovce provázená v levém horním rohu zlatým leknínovým trojlistem."
    },
    {
        obec: "Bašnice",
        kod: 572667,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.334576,
        longitude: 15.605129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Basnice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 11:1:6. V horním modrém pruhu žlutý zvon mezi dvěma bílými odkloněnými liliemi na stoncích, každý s odvráceným listem. V dolním modrém pruhu volný žlutý oboustranně zubatý posunutý pruh s pěti zuby (3,2), dlouhý třetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným břevnem zlatý zvon mezi dvěma stříbrnými odkloněnými liliemi na stoncích, každý s odvráceným listem, dole volné zlaté cimbuřové břevno."
    },
    {
        obec: "Bašť",
        kod: 538051,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25065,
        latitude: 50.205242,
        longitude: 14.477405,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Coats_of_arms_Ba%C5%A1%C5%A5.jpeg",
        coatOfArmsFlagDescription: "List tvoří bílé karé nad stejně velkým červeným polem a dva vodorovné pruhy, modrý a bílý. V karé kosmo černá orlice se žlutou zbrojí a perizoniem. Přes vodorovné pruhy žlutý zvon se závěsem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, vpravo dělený. Nahoře ve stříbrném poli kosmá černá orlice se zlatou zbrojí a perizoniem, dole v červeno-stříbrně děleném poli zlatý zvon se závěsem. V levém modrém poli nad zlatou kvádrovanou hradbou s cimbuřím dva odvrácené stříbrno-červeně šachované rohy."
    },
    {
        obec: "Batelov",
        kod: 586862,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.290179,
        longitude: 15.410734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Batelov_nov%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "List dělený žlutým a bílým kosmým pruhem na červené žerďové a modré vlající pole. Žlutý pruh vychází z první čtvrtiny žerďového okraje do čtvrté čtvrtiny dolního okraje, bílý pruh vychází z první čtvrtiny horního okraje do čtvrté čtvrtiny vlajícího okraje. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře kosmo děleném štítě nahoře ze spojených bůvolích rohů vyrůstá rytíř s taseným mečem v pravici, s levicí v bok, zlatým pštrosím perem na přilbě se zvednutým hledím, vše stříbrné. Dole zkřížené vztyčené zlaté gotické klíče přeložené vztyčeným stříbrným mečem."
    },
    {
        obec: "Batňovice",
        kod: 579041,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54237,
        latitude: 50.517692,
        longitude: 16.03674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Batnovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený. V žerďové části žlutého pruhu černé zkřížené ostrve. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným kopcem černé zkřížené ostrve."
    },
    {
        obec: "Bavorov",
        kod: 550809,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.121951,
        longitude: 14.079028,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 1 : 3 : 1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Bavory",
        kod: 584304,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69201,
        latitude: 48.834789,
        longitude: 16.622349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Bavory_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou kostelní věží, se zavřenými vraty, se třemi černými okny nad sebou, červenou cibulovitou střechou zakončenou žlutou makovicí s křížem, provázená dvěma prohnutými bílými vinnými stonky, každý střídavě se třemi žlutými hrozny a bílými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vyrůstající kostelní věž se zavřenými vraty, se třemi černými okny nad sebou, červenou cibulovitou střechou zakončenou zlatou makovicí s křížem, provázenou dvěma prohnutými stříbrnými vinnými stonky, každý se třemi zlatými hrozny a stříbrnými listy."
    },
    {
        obec: "Bavoryně",
        kod: 534421,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.895907,
        longitude: 13.961126
    },
    {
        obec: "Bdeněves",
        kod: 566756,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33032,
        latitude: 49.76964,
        longitude: 13.235291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Coat_of_arms_of_Bden%C4%9Bves.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žernovem s postavenou červenou rozšířenou kypřicí, v ní bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý žernov s postavenou červenou rozšířenou kypřicí, v ní stříbrná lilie."
    },
    {
        obec: "Bdín",
        kod: 565423,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.214245,
        longitude: 13.858851
    },
    {
        obec: "Bečice",
        kod: 536156,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.215784,
        longitude: 14.501318,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Be%C4%8Dice_%28CB%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří černý žerďový pruh široký čtvrtinu délky listu se žlutou berlou závitem k vlajícímu okraji a bílým sudariem, a červené pole s bílou polovinou koně se žlutými kopyty a uzděním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod černou hlavou se zkříženými, závity dolů otočenými, zlatými berlami se stříbrnými sudarii polovina stříbrného koně se zlatými kopyty a uzděním."
    },
    {
        obec: "Bečice",
        kod: 563366,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39175,
        latitude: 49.381437,
        longitude: 14.539787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Be%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, vlnitý bílý, vlnitý červený, vlnitý bílý a zvlněný zelený, v poměru 2 : 1 : 1 : 1 :11. Vlnité pruhy mají dva vrcholy a tři prohlubně.V dolním pruhu čelně bílá beraní hlava se žlutými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrným vlnitým břevnem se zúženým červeným vlnitým břevnem čelně stříbrná beraní hlava se zlatými rohy."
    },
    {
        obec: "Bečov",
        kod: 567043,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43526,
        latitude: 50.449824,
        longitude: 13.717955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Becov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý černý žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, bílý a modrý. Přes černý pruh dva kosmé žluté pruhy, vycházející z první a třetí čtvrtiny žerďového okraje a dosahující k dolní polovině bílého a modrého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě polovina zlatého lva s červenou zbrojí. V pravé tlapě drží vztyčenou stříbrnou střelu, na levé tlapě má zavěšen černý gotický štítek se dvěma zlatými kosmými břevny."
    },
    {
        obec: "Bečov nad Teplou",
        kod: 554995,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36464,
        latitude: 50.083561,
        longitude: 12.838429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Znak_m%C4%9Bsta_Be%C4%8Dov_nad_Teplou.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký pět dvanáctin délky listu, a pět vodorovných pruhů, střídavě modrých a bílých. V červeném pruhu bílý dvouocasý lev se žlutou zbrojí, držící v pravé tlapě černý mlátek na hnědé násadě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná zeď, za ní dvě stříbrné kruhové kvádrované věže, každá se třemi (2,1) černými klíčovými střílnami, cimbuřím a černou jehlancovou střechou se zlatou makovicí. Uprostřed červený štítek se stříbrným dvouocasým lvem se zlatou zbrojí, držícím v pravé tlapě černý mlátek na topůrku přirozené barvy. Na štítku kolčí helm, klenotem vyrůstající zlatá postava Panny Marie se svatozáří mezi rozloženými černými křídly, přikrývadla červeno-stříbrná."
    },
    {
        obec: "Bečváry",
        kod: 533181,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28143,
        latitude: 49.956809,
        longitude: 15.079916,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Be%C4%8Dv%C3%A1ry_znak.jpg",
        coatOfArmsFlagDescription: "List dělený bílým šikmým pruhem vycházejícím z první šestiny dolního okraje do poslední šestiny horního okraje listu na modré žerďové a červené vlající pole. V modrém bílo-červeně polcená růže se žlutým semeníkem a kališními lístky. V červeném poli položený žlutý sud. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně stříbrným cimbuřovým břevnem o pěti stínkách děleném štítě nahoře stříbrno-červeně polcená růže se zlatým semeníkem a kališními lístky mezi dvěma vyrůstajícími zlatými obilnými klasy, dole položený zlatý sud."
    },
    {
        obec: "Bedihošť",
        kod: 589268,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79821,
        latitude: 49.448359,
        longitude: 17.166534,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Bediho%C5%A1%C5%A5_PV_CZ_CoA.png",
        coatOfArmsFlagDescription: "Bílý list se svislým červeným pruhem vycházejícím z páté šestiny horního okraje listu. V žerďovém poli tokající modrý páv s hlavou otočenou k žerdi, se žlutou korunkou a zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad červenou patou s položenou stříbrnou radlicí modrý tokající páv se zlatou korunkou a zbrojí."
    },
    {
        obec: "Bednárec",
        kod: 562548,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.195339,
        longitude: 15.093296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Bedn%C3%A1rec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a sedm vodorovných pruhů střídavě modrých a žlutých, v poměru 3 : 2 : 2 : 2 : 2 : 2 : 3. V žerďovém pruhu na položené zavřené žluté knize s bílou ořízkou žlutý svícen s bílou svící hořící žlutým plamenem zářícím střídavě delšími a kratšími žlutými paprsky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém modrém poli na položené zavřené zlaté knize se stříbrnou ořízkou zlatý svícen se stříbrnou svící hořící zlatým plamenem zářícím střídavě delšími a kratšími zlatými paprsky. V levém modrém poli tři zlatá břevna."
    },
    {
        obec: "Bednáreček",
        kod: 561053,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.210179,
        longitude: 15.144034,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Bedn%C3%A1re%C4%8Dek_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list. V žerďové polovině bílá radlice hrotem k žerdi a ostřím nahoru, nad ní pět (3, 2) šesticípých hvězd, pod ní knoflík, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě doleva položená stříbrná radlice, nad ní pět (3, 2) hvězd, pod ní knoflík, vše zlaté."
    },
    {
        obec: "Bedřichov",
        kod: 581313,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.461276,
        longitude: 16.465422
    },
    {
        obec: "Bedřichov",
        kod: 563536,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46812,
        latitude: 50.791188,
        longitude: 15.142571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Bedrichov_%28Jablonec_nad_Nisou%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, horní pole stříbrno - modře děleno, nahoře zkřížené dřevěné lyže přirozené barvy, dole doleva položené stříbrné sklářské kleště. V dolním zeleném poli zlaté vozové kolo."
    },
    {
        obec: "Běhařov",
        kod: 541842,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.344879,
        longitude: 13.160291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/B%C4%9Bha%C5%99ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý vodorovný pruh široký pět osmin šířky listu a sedm svislých pruhů střídavě bílých a červených. V modrém pruhu bílé, žlutě zdobené obrněné rameno držící bílou orientální šavli se žlutým jílcem hrotem k hornímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře stříbrné, zlatě zdobené obrněné rameno držící stříbrnou orientální šavli se zlatým jílcem, dole tři červené kůly."
    },
    {
        obec: "Běhařovice",
        kod: 593737,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67139,
        latitude: 49.004036,
        longitude: 16.078723,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/B%C4%9Bha%C5%99ovice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý a střídavě černé a bílé, v poměru 5:1:1:1:1:1. V žerďové polovině modrého pruhu bílý půlměsíc cípy k žerdi, provázený u horního okraje listu dvěma žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nahoře černo-stříbrně dělený štít, v horním poli se dvěma stříbrnými břevny, dole stříbrný dorůstající půlměsíc provázený dvěma zlatými hvězdami."
    },
    {
        obec: "Běchary",
        kod: 572675,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.31413,
        longitude: 15.292617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Bechary_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý v poměru 3 : 1 : 3. V modrém pruhu žlutá berla s bílým sudariem závitem k žerďovému okraji, v bílých pruzích po modré radlici hrotem nahoru a ostřím od středu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kůl se zlatou berlou se stříbrným sudariem provázený dvěma vztyčenými odvrácenými modrými radlicemi."
    },
    {
        obec: "Bechlín",
        kod: 564575,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41186,
        latitude: 50.416252,
        longitude: 14.341026
    },
    {
        obec: "Bechyně",
        kod: 552054,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.295336,
        longitude: 14.468202,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Bechyne_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: modrý, žlutý a modrý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bělá",
        kod: 573566,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.754546,
        longitude: 15.240686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/B%C4%9Bl%C3%A1_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, bílý, žlutý a zelený, v poměru 5 : 1 : 1 : 5. V červeném pruhu žlutý skleněný pohár, v zeleném pruhu žlutá věž s cimbuřím, černým obloukovým oknem a rozšířeným kvádrovaným soklem s černým prolomeným obloukovým vchodem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě nahoře zlatý skleněný pohár a zlatá věž s cimbuřím se třemi stínkami, černým obloukovým oknem a rozšířeným kvádrovaným soklem s prolomeným černým obloukovým vchodem, mezi nimi stříbrná hornická kladívka na zlatých topůrkách, dole stříbrný leknový trojlist."
    },
    {
        obec: "Bělá",
        kod: 561126,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.312617,
        longitude: 15.260715
    },
    {
        obec: "Bělá",
        kod: 576972,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.534417,
        longitude: 15.445427,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Bela_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 2:1:1. V žerďové části bílého pruhu bílý mlýnský kámen položený na modrém květu lnu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře sníženě vlnitě děleném štítě nahoře červený snížený hrot, provázený vpravo vykořeněným jehličnatým stromem přirozené barvy a vlevo modrým květem lnu, dole tři (1,2) stříbrné mlýnské kameny."
    },
    {
        obec: "Bělá",
        kod: 512974,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74723,
        latitude: 49.972429,
        longitude: 18.144995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Bela_%28Opava%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené žerďové pole s bílou labutí se žlutou zbrojí a bílé vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná labuť se zlatou zbrojí."
    },
    {
        obec: "Bělá nad Radbuzou",
        kod: 553441,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34526,
        latitude: 49.591261,
        longitude: 12.717718,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Bela_nad_Radbuzou_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, tři oboustranně vlnkovité, bílý, modrý a bílý, a vlnkovitý červený, v poměru 8 : 1 : 1 : 1 : 3. V horním pruhu bílý volný most se šesti oblouky a zvýšenými pilíři mezi prvním a druhým, třetím a čtvrtým, pátým a šestým obloukem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Bělá nad Svitavou",
        kod: 572586,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56905,
        latitude: 49.640287,
        longitude: 16.482734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/B%C4%9Bl%C3%A1_nad_Svitavou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s kosmým bílým pruhem širokým jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné kosmé břevno."
    },
    {
        obec: "Bělá pod Bezdězem",
        kod: 535443,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29421,
        latitude: 50.501314,
        longitude: 14.80429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Bpb_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, červený s českým lvem, bílý a modrý v poměru 1:1:3:1:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bělá pod Pradědem",
        kod: 523917,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79001,
        latitude: 50.164036,
        longitude: 17.196677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Znak_obce_B%C4%9Bl%C3%A1_pod_Prad%C4%9Bdem.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým vykořeněným listnatým stromem podloženým bílými váhami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě z černého trojvrší vyrůstající zlatý listnatý strom podložený stříbrnými váhami."
    },
    {
        obec: "Bělá u Jevíčka",
        kod: 505391,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.639593,
        longitude: 16.646009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/B%C4%9Bl%C3%A1_u_Jev%C3%AD%C4%8Dka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří sedm šikmých pruhů, modrý, žlutý, modrý, bílý, modrý, žlutý a modrý, v poměru 11:4:2:12:2:4:11. Na ose bílého pruhu tři červená srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi červenými srdci zlatý obilný snop, provázený dvěma stříbrnými odvrácenými parohy."
    },
    {
        obec: "Bělčice",
        kod: 550817,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38743,
        latitude: 49.502517,
        longitude: 13.875856,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/B%C4%9Bl%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "Červený list, uprostřed zkřížené bílé gotické klíče podložené bílým mečem se žlutým jílcem, hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Běleč",
        kod: 560448,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.543779,
        longitude: 14.837357,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/B%C4%9Ble%C4%8D%28TA%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý žlutý, v poměru 1 : 9. Žlutý pruh má pět obdélníkových zubů, dva poloviční a šest čtvercových mezer. Ve žlutém pruhu černá kančí hlava s bílými kly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou cimbuřovou hlavou černá kančí hlava se stříbrnými kly."
    },
    {
        obec: "Běleč",
        kod: 581321,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.434036,
        longitude: 16.383235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/B%C4%9Ble%C4%8D_BV_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, modrý a bílý, v poměru 7 : 1 : 1 : 1. V zeleném pruhu k vlajícímu okraji listu běžící bílý jelen. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě doleva běžící stříbrný jelen nad stříbrnou patou se čtyřmi oblouky. V patě modré zúžené vlnité břevno."
    },
    {
        obec: "Běleč",
        kod: 535010,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27363,
        latitude: 50.056269,
        longitude: 13.993082,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, žlutý a zelený, v poměru 13 : 1 : 1 : 1. Z horního zeleného pruhu vyrůstá černá lípa se zelenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá lípa se zelenými listy vyrůstající ze zelené cimbuřové paty se zlatou korunou."
    },
    {
        obec: "Běleč nad Orlicí",
        kod: 569852,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.198081,
        longitude: 15.942805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/B%C4%9Ble%C4%8D_nad_Orlic%C3%AD_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a žlutý, v poměru 1 : 1 : 3. Z dolního okraje žlutého pruhu vyrůstá listnatý strom s hnědým kmenem a zelenou korunou, provázenou po stranách vztyčeným zeleným lipovým listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zvýšeně vlnitě dělený štít. Horní pole stříbrno-modře vlnitě dělené, v dolním zlatém poli vyrůstající listnatý strom přirozené barvy provázený po stranách vztyčeným zeleným lipovým listem."
    },
    {
        obec: "Bělkovice-Lašťany",
        kod: 500526,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78316,
        latitude: 49.668757,
        longitude: 17.317289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Belkovice-Lastany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeným klínem, lemovaným žlutou krokví s vrcholem na středu žerďové části. Ramena krokve vycházejí z jedné šestiny horního a dolního vlajícího okraje listu. Nad a pod vrcholem krokve v žerďové části po žluté šesticípé hvězdě o průměru sedm osmnáctin šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítu červeno-zlatě dělená biskupská mitra, nahoře zlatě lemovaná, v její horní části zlatý rostoucí lev s modrou zbrojí, dole tři červené růže se zlatými semeníky; mitra je v horní části štítu provázena dvěma zlatými hvězdami."
    },
    {
        obec: "Běloky",
        kod: 532070,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27353,
        latitude: 50.131539,
        longitude: 14.220862,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/B%C4%9Bloky_CoA.png",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. V horním poli bílý mlýnský kámen s černou kypřicí, v dolním dva šikmé modré pruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně šikmo děleném štítě nahoře stříbrný mlýnský kámen s černou kypřicí, dole dvě šikmá modrá břevna."
    },
    {
        obec: "Bělotín",
        kod: 512231,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75364,
        latitude: 49.5913,
        longitude: 17.806648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/B%C4%9Blot%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - zelený, bílý a zelený, v poměru 1:2:1, přes které je položen červený středový kříž široký jednu třetinu šířky bílého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vzpínající se drak s krkem probodeným červeným kopím se zlatým hrotem, jež vychází tlamou, všema nohama držící červený štít s červeným heroldským křížem."
    },
    {
        obec: "Bělov",
        kod: 588318,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.21609,
        longitude: 17.484879,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/CZ_B%C4%9Blov_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, přes které je ve střední části položen svislý červený pruh široký jednu třetinu délky listu. V červeném pruhu žluté slunce, bílá radlice hrotem k žerďovému okraji a ostřím k dolnímu okraji s modrou šesticípou hvězdou a půlměsíc s tváří cípy k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli pod sebou zlaté slunce s tváří, stříbrná položená radlice s modrou hvězdou a zlatý stoupající půlměsíc s tváří, levé pole třikrát stříbrno-modře dělené."
    },
    {
        obec: "Bělušice",
        kod: 533190,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.069172,
        longitude: 15.322001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/B%C4%9Blu%C5%A1ice_%28Kol%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3 : 1. Na zeleném pruhu vykračující bílý kůň se žlutou zbrojí, hřívou a zdviženým ocasem. V zeleném pruhu vztyčený žlutý dubový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrný kůň se zlatou zbrojí, hřívou a zdviženým ocasem vykračující na zeleném trávníku se vztyčeným zlatým dubovým listem."
    },
    {
        obec: "Benátky",
        kod: 569861,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.310292,
        longitude: 15.736096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Ben%C3%A1tky_%28HK%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, vlnitý bílý, vlnitý modrý, vlnitý bílý a zvlněný modrý, v poměru 9 : 1 : 1 : 1 : 4. Vlnité pruhy mají pět vrcholů a čtyři prohlubně. V červeném pruhu dělo, v dolním modrém pruhu svěšený lipový list, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře sníženým modrým, stříbrně lemovaným, vlnitým břevnem dělený štít. Nahoře dělo, dole svěšený lipový list, obojí zlaté."
    },
    {
        obec: "Bělušice",
        kod: 567051,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.449262,
        longitude: 13.762928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Coat_of_arms_of_B%C4%9Blu%C5%A1ice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený s bílým běžícím koněm a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zvýšeným zlatým trojvrším s černými vidlemi - podávkami bez násady stříbrný běžící kůň."
    },
    {
        obec: "Benátky",
        kod: 577774,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.854287,
        longitude: 16.326847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Ben%C3%A1tky_%28SV%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý se žlutou loďkou, v poměru 1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou palisádovou hlavou zlatá loďka."
    },
    {
        obec: "Benátky nad Jizerou",
        kod: 535451,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29471,
        latitude: 50.289261,
        longitude: 14.824612,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Benatky_n_j_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, modrý a červený v poměru 1:2:1. V modrém poli žlutá větev se třemi vinnými listy z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Benecko",
        kod: 576981,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51237,
        latitude: 50.666433,
        longitude: 15.548272,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Benecko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List se středovým žlutým křížem, s rameny širokými jednu pětinu šířky listu. Horní žerďové a dolní vlající pole je bílé, horní vlající a dolní žerďové je zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleném polceném štítě je hlava a krk jelena dvanácteráka přirozené barvy, který má mezi parohy zlatý latinský kříž."
    },
    {
        obec: "Benešov",
        kod: 581330,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67953,
        latitude: 49.509531,
        longitude: 16.770698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Benesov_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, zelený, bílý a zelený, v poměru 4:1:1:1:1. Do žerďové poloviny červeného pruhu vyrůstá žlutý jelen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře vyrůstající zlatý jelen, dole dva zkřížené zelené věníky."
    },
    {
        obec: "Benešov",
        kod: 529303,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.783882,
        longitude: 14.68747,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Benesov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Benešov nad Černou",
        kod: 545406,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38282,
        latitude: 48.729507,
        longitude: 14.627471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Benesov_nad_cernou_znak.png"
    },
    {
        obec: "Benešov nad Ploučnicí",
        kod: 562351,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40722,
        latitude: 50.741692,
        longitude: 14.3125,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/CoA_of_Bene%C5%A1ov_nad_Plou%C4%8Dnic%C3%AD.svg",
        coatOfArmsFlagDescription: "Bílý list s černým vlnitým vodorovným pruhem o třech vrcholech a dvou prohlubních v sedmé osmině šířky listu. Nad ním polovina černého stojícího medvěda. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Benešov u Semil",
        kod: 576999,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51206,
        latitude: 50.603258,
        longitude: 15.368478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Bene%C5%A1ov_u_Semil_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 4 : 1 : 3. Uprostřed bílého pruhu modrá kráčející lvice s červenou zbrojí a jazykem, ve střední části červeného pruhu dva žluté kosmé pruhy široké jednu osminu šířky listu. Poměr šířky k délce listuje 2 : 3.",
        coatOfArmsDescription: "Ve sníženě stříbrno - červeně děleném štítě nahoře kráčející modrá lvice s červenou zbrojí a jazykem nad modrým vlnitým břevnem, dole dvě kosmá zlatá břevna."
    },
    {
        obec: "Benešovice",
        kod: 560723,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.726964,
        longitude: 12.901799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/CoA_of_Bene%C5%A1ovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený se žlutou lilií, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůl provázený vpravo jednou a vlevo třemi zlatými liliemi pod sebou."
    },
    {
        obec: "Benetice",
        kod: 590304,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67506,
        latitude: 49.297738,
        longitude: 15.867436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Benetice_TR_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, žlutý, černý a žlutý, v poměru 1:4:1:1:1. V modrém pruhu kráčející žlutý lev sv. Marka s červenou zbrojí a bílou knihou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě snížené zlaté břevno se třemi dolů obrácenými černými mnišskými kápěmi, nad ním kráčející zlatý lev sv. Marka s červenou zbrojí a stříbrnou knihou."
    },
    {
        obec: "Beňov",
        kod: 512281,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.41746,
        longitude: 17.501452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Znak_Be%C5%88ov.jpeg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu polovinu délky listu a dva vodorovné pruhy bílý a červený. V modrém pruhu půl žlutého středového kříže s rameny širokými jednu sedminu šířky listu a půl černé zubří hlavy se žlutou zbrojí, houžví a červeným jazykem, přiléhajícími k vlajícímu okraji modrého pruhu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít pod modrou hlavou se zlatým heroldským křížem. V pravém zlatém poli půl černé zubří hlavy se zlatým rohem, houžví a červeným jazykem, v levém stříbrno-červeně děleném poli pravá ruka přirozené barvy s červeným stigmatem na dlani."
    },
    {
        obec: "Bernardov",
        kod: 531111,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 50.014231,
        longitude: 15.398668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/CoA_of_Bernardov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený. Uprostřed modré čtvercové pole, široké polovinu šířky listu, s bílou hlavou psa věžníka s červeným jazykem se žlutým obojkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina stříbrno-modře polcena, vpravo tři (2,1) zkřížené černé hřeby, dolní vztyčený. Vlevo stříbrná hlava psa věžníka s červeným jazykem a zlatým obojkem, v dolním červeném poli vynikající polovina zlatého vozového kola."
    },
    {
        obec: "Bernartice",
        kod: 549266,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.368999,
        longitude: 14.381118,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Bernartice_znak.png"
    },
    {
        obec: "Bernartice",
        kod: 579050,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54204,
        latitude: 50.644783,
        longitude: 15.965926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Bernartice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 1:5:1. V bílém pruhu černý kráčející medvěd. Pod zeleným pruhem čtyři černé siluety vedle sebe letících ptáků. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, vlnitě děleném štítě nahoře zelené trojvrší, z krajních vrchů vyrůstají dva zelené smrky s černými kmeny, nad prostředním vrchem čtyři (2,1,1) černé siluety letících ptáků, dole nad modrou vlnitou patou kráčející černý medvěd."
    },
    {
        obec: "Bernartice",
        kod: 524891,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79057,
        latitude: 50.389907,
        longitude: 17.078379,
        coatOfArmsFlagDescription: "Bílý list s červeným rovným tlapatým křížem. Poměr šířky k déle listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě volný červený tlapatý kříž."
    },
    {
        obec: "Bernartice",
        kod: 532568,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.675666,
        longitude: 15.129249
    },
    {
        obec: "Bernartice nad Odrou",
        kod: 568481,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74101,
        latitude: 49.610207,
        longitude: 17.947885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Bernartice_nad_Odrou_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, bílý a modrý v poměru 1:1:2. V modrém pruhu bílý kůň ve skoku k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůň ve skoku obrácený doleva, nad ním zlatá radlice, vše obtočeno stříbrným růžencem se zlatým křížkem dole."
    },
    {
        obec: "Beroun",
        kod: 531057,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 49.967305,
        longitude: 14.086384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Beroun_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V žerďové polovině bílého pruhu hnědý medvěd s červeným jazykem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Běrunice",
        kod: 537021,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28908,
        latitude: 50.185843,
        longitude: 15.333694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/B%C4%9Brunice_CoA.svg",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. V modrém poli na žluté lovecké trubce stojící bílý kohout s pěti černými ocasními pery, červenou zbrojí a žlutou hradební korunou na krku. V červeném poli tři bílé vlčí zuby vyrůstající z dolního cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě nahoře na zlaté lovecké trubce stojící stříbrný kohout s pěti černými ocasními pery, červenou zbrojí a zlatou hradební korunou na krku, dole tři stříbrné vlčí zuby vyrůstající z okraje štítu."
    },
    {
        obec: "Beřovice",
        kod: 532088,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27371,
        latitude: 50.269119,
        longitude: 14.12366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Coat_of_arms_of_Be%C5%99ovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, uprostřed šikmo žlutý šíp s bílým hrotem k hornímu okraji listu a opeřením. V horním rohu bílá čtyřlistá růže se žlutým semeníkem a kališními lístky. Z dolní poloviny vlajícího okraje listu vynikají tři bílé klíny s vrcholy dosahujícími do dvou třetin délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě šikmo zlatý šíp se stříbrným hrotem a opeřením provázený vpravo nahoře stříbrnou čtyřlistou růží se zlatým semeníkem a kališními lístky, vlevo dole třemi stříbrnými vlčími zuby vyrůstajícími z okraje štítu."
    },
    {
        obec: "Besednice",
        kod: 545414,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38281,
        latitude: 48.790089,
        longitude: 14.556987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Coat_of_arms_of_Besednice.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným klínem na dolním okraji listu. Na jeho vrcholu, sahajícím do jedné třetiny šířky listu, černý vykračující kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném vrchu vykračující černý kohout s červenou zbrojí."
    },
    {
        obec: "Běstovice",
        kod: 553760,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.020237,
        longitude: 16.211026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Coat_of_arms_of_B%C4%9Bstovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 3 : 1 : 1. V modrém pruhu turnajská přilba s červeno-bílými přikrývadly nesoucí rozevřená křídla, bílé a červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou palisádou s červenou korunou korunovaná turnajská přilba přirozené barvy s červeno-stříbrnými přikrývadly, nesoucí rozevřená křídla, stříbrné a červené."
    },
    {
        obec: "Běstvina",
        kod: 571181,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53845,
        latitude: 49.837134,
        longitude: 15.595868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/B%C4%9Bstvina_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 5 : 1. Ze zeleného pruhu vyniká bílá kaple se dvěma vchody s oslími hřbety, převýšenými dvěma kosmými šesticípými hvězdami, se žlutou římsou a bílou lucernou se dvěma šesticípými hvězdami. Vchody a hvězdy černé a vedle sebe, lucerna se žlutou stanovou střechou zakončenou makovicí s hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku s pěti (3, 2) zlatými hvězdami stříbrná kaple se dvěma vchody s oslími hřbety, převýšenými dvěma kosmými hvězdami, se zlatou římsou a stříbrnou lucernou se dvěma hvězdami. Vchody a hvězdy černé a vedle sebe, lucerna se zlatou stanovou střechou zakončenou makovicí s hvězdou."
    },
    {
        obec: "Běšiny",
        kod: 555797,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.301709,
        longitude: 13.312677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/B%C4%9B%C5%A1iny_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a černý, s lemem listu opačných barev, širokým dvanáctinu šířky listu. Ve žlutém pruhu vztyčená černá uťatá medvědí tlapa s červenými drápy, v černém levý bílý býčí roh. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-černě polcený štít s lemem opačných barev. Vpravo vztyčená černá uťatá medvědí tlapa s červenými drápy, vlevo levý stříbrný býčí roh."
    },
    {
        obec: "Běštín",
        kod: 531073,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.807406,
        longitude: 14.01634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Bestin_CZ_CoA.png",
        coatOfArmsFlagDescription: "Šikmo dělený list; v zeleném žerďovém poli dva žluté zkřížené hřeby hroty dolů, vlající pole tvoří tři šikmé pruhy, černý, bílý a červený, vycházející z dolního okraje listu v poměru 5:5:7. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva zlaté zkřížené hřeby nad děleným trojvrším, nahoře červeným a dole stříbrno-černě polceným."
    },
    {
        obec: "Bezděčí u Trnávky",
        kod: 574309,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.692503,
        longitude: 16.763539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Bezd%C4%9B%C4%8D%C3%AD_u_Trn%C3%A1vky%2C_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, v dolní střední části půlměsíc s cípy k hornímu okraji listu. Mezi cípy dvě dvojice odkloněných obilných klasů, každý s listem, vše žluté. Mezi klasy bílý zvon se závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvě dvojice odkloněných obilných klasů, každý s listem, nad stoupajícím půlměsícem, obojí zlaté. Mezi klasy stříbrný zvon se závěsem."
    },
    {
        obec: "Bezdědovice",
        kod: 598895,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.444294,
        longitude: 13.880068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Bezd%C4%9Bdovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým šikmým pruhem vycházejícím ze druhé čtvrtiny dolního okraje do druhé čtvrtiny vlajícího okraje listu, v pruhu tři červené čtyřlisté květy se žlutými středy. V žerďové polovině listu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým šikmým stříbrným vlnitým břevnem se třemi červenými čtyřlistými květy se zlatými středy zlatá lilie."
    },
    {
        obec: "Bezděkov",
        kod: 548430,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.732931,
        longitude: 15.730501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Bezd%C4%9Bkov_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a tři vodorovné pruhy, zelený, žlutý a zelený. V červeném pruhu bílý mořský pes se žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě vpravo stříbrný mořský pes se zlatým jazykem, vlevo zlatá sedmihrotá hvězda."
    },
    {
        obec: "Bezděkov",
        kod: 574741,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.008869,
        longitude: 15.644768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Bezd%C4%9Bkov_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 1:6:1. V modrém pruhu žlutý zvon se závěsem mezi dvěma přivrácenými bílými rybami hlavami nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se třemi stříbrnými kužely zlatý zvon se závěsem mezi dvěma vztyčenými přivrácenými stříbrnými rybami."
    },
    {
        obec: "Bezděkov",
        kod: 555801,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.3795,
        longitude: 13.297971,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Bezd%C4%9Bkov_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým kruhovým polem dole provázeným do oblouku pěti žlutými šesticípými hvězdami. V poli červená kvádrovaná věž s cimbuřím převýšená modrou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s černou čtvrtí se zlatou růží s červeným semeníkem šikmo postavený zlatý meč. Vše přeloženo stříbrným oválným štítkem s červenou kvádrovanou věží s cimbuřím, převýšenou modrou hvězdou. Štítek provázen dole doleva do oblouku pěti zlatými hvězdami."
    },
    {
        obec: "Bezděkov",
        kod: 541095,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.824389,
        longitude: 13.596645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Bezd%C4%9Bkov_%28Rokycany_District%29_CoA.png",
        coatOfArmsFlagDescription: "Červený list s horním žerďovým trojúhelníkem, vycházejícím ze sedmi desetin žerďového a dvou třetin horního okraje listu a tvořeným sedmi střídavě modrými a žlutými vodorovnými pruhy. Z deváté desetiny žerďového okraje listu vychází bílý vodorovný pruh. V horní polovině vlající části žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě s modrým koutem se třemi zlatými břevny stříbrná kaple s lucernou se zvonem, zakončená křížkem, provázená vlevo nahoře zlatou lilií."
    },
    {
        obec: "Bezděkov nad Metují",
        kod: 573884,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54964,
        latitude: 50.509838,
        longitude: 16.229341,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Bezd%C4%9Bkov_nad_Metuj%C3%AD_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a zelený. V modrém pruhu nad sebou tři žluté růže s červenými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší se zlatou, vlevo položenou ostrví stříbrné průčelí barokního kostela se dvěma věžemi a černými střechami. Nad ním tři zlaté růže s červenými semeníky."
    },
    {
        obec: "Bezděkov pod Třemšínem",
        kod: 529672,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.577203,
        longitude: 13.878564
    },
    {
        obec: "Bezděz",
        kod: 561398,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.533742,
        longitude: 14.722084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Bezdez_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký jednu třetinu délky listu, dělený červeno-bíle v poměru 5:3, a dva vodorovné pruhy, bílý a červený, v poměru 5:3. V červeném žerďovém poli bílý dvouocasý korunovaný lev se žlutou zbrojí vyrůstající z bílé kvádrované hradby s cimbuřím na zeleném návrší se žlutým šípem hrotem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný dvouocasý korunovaný lev se zlatou zbrojí vyrůstající ze stříbrné kvádrované hradby s cimbuřím na zeleném návrší se zlatým položeným šípem."
    },
    {
        obec: "Bezdružice",
        kod: 560740,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34953,
        latitude: 49.906771,
        longitude: 12.971351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Bezdru%C5%BEice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh červeno-zeleně dělený v poměru 5:2, široký tři sedminy délky listu, a tři vodorovné pruhy, modrý, bílý a žlutý, v poměru 5:1:1. Ze zeleného pruhu vyrůstá bílá kvádrovaná věž s prázdnou bránou, černým oknem, cimbuřím a černou stanovou střechou se žlutou makovicí. V modrém pruhu bílo-červeně polcená orlice se žlutou zbrojí a jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bezkov",
        kod: 593745,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.871321,
        longitude: 15.951352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Coat_of_arms_of_Bezkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý. V modrých pruzích po jedné žluté lilii, v bílém pruhu červený osmihrotý kříž nad červenou šesticípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná čelně postavená radlice nahoře provázená dvěma zlatými liliemi, v ní osmihrotý kříž nad hvězdou, obojí červené."
    },
    {
        obec: "Bezměrov",
        kod: 588326,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.329903,
        longitude: 17.334894,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Bezm%C4%9Brov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, zelený se žlutým obilným snopem, bílý a červený, v poměru 2 : 1 : 6 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se čtyřmi stříbrnými kužely zlatý obilný snop provázený dvěma vztyčenými přivrácenými stříbrnými radlicemi."
    },
    {
        obec: "Bezno",
        kod: 535478,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29429,
        latitude: 50.367388,
        longitude: 14.795726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Bezno_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, modrý, žlutý, modrý a červený v poměru 4:1:2:1:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná hradba s prolomenou branou, za ní dvě věže s cimbuřím a jedním černým oknem. Mezi věžemi vyrůstající stříbrný lev se zlatou korunou a zbrojí. V hradební bráně stříbrné břevno přeložené modrým štítkem se stříbrným pštrosím perem provázeným dvěma stříbrnými odvrácenými rybářskými háčky."
    },
    {
        obec: "Bezuchov",
        kod: 512401,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.462827,
        longitude: 17.608881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Bezuchov_znak.png",
        coatOfArmsFlagDescription: "Černo-červeně kosmo dělený list se žlutým gryfem ve střední části držícím rýč se žlutou násadou a červeným listem s bílým heroltským křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě kosmo děleném štítě zlatý gryf držící rýč se zlatou násadou, jehož list je červený se stříbrným heroltským křížem."
    },
    {
        obec: "Bílá",
        kod: 563901,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46343,
        latitude: 50.664931,
        longitude: 15.035522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/B%C3%ADl%C3%A1_%28Liberec_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 5 : 3. V žerďové polovině listu v zeleném pruhu kosmo svěšená žlutá lipová větev o pěti listech, pod ní v bílém pruhu modrá čtrnácticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně sníženě vlnitě děleném štítě nahoře kosmo svěšená zlatá lipová větev o pěti listech, dole modrá čtrnáctihrotá hvězda."
    },
    {
        obec: "Bezvěrov",
        kod: 558656,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33041,
        latitude: 49.995118,
        longitude: 13.059113,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Bezverov_znak.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list na žerďové černo-bíle šachované pole (4x6) a červené vlající pole se žlutou medvědí tlapou s modrou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Šikmo dělený štít, horní pole černo-stříbrně šachováno, v dolním červeném poli zlatá medvědí tlapa s modrou zbrojí."
    },
    {
        obec: "Bílá",
        kod: 598020,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73915,
        latitude: 49.442171,
        longitude: 18.453037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/B%C3%ADl%C3%A1_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se zvlněným žerďovým bílým pruhem širokým jednu třetinu délky listu se dvěma vrcholy a třemi prohlubněmi. Z vlajícího okraje vycházejí tři bílé klíny s vrcholy sahajícími k bílému žerďovému pruhu, ve kterém je zelený smrk s černým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře sníženě vlnitě dělený štít, nahoře vyrůstají tři zelené smrky s černými kmeny, dole tři stříbrné kužely."
    },
    {
        obec: "Bílá Hlína",
        kod: 565750,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.532604,
        longitude: 14.925357
    },
    {
        obec: "Bílá Lhota",
        kod: 500623,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78321,
        latitude: 49.709629,
        longitude: 16.97518,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Bila_Lhota_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: červený, bílý, modrý, bílý a červený v poměru 3:1:13:1:3. Uprostřed žlutý vykořeněný dub. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli zlatý vykořeněný dub, dole ve stříbrno-červeně polceném poli vyniká dolní polovina palečného kola opačných barev."
    },
    {
        obec: "Bílá Třemešná",
        kod: 579068,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54472,
        latitude: 50.444749,
        longitude: 15.74112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Bila_tremesna_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 1 : 4 : 1. V bílém pruhu černý lev s červenou zbrojí, se třemi hořícími modrými granáty pod sebou na trupu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli svěšené květenství střemchy obecné přirozené barvy, levé pole stříbrno-červeně dělené, nahoře černý lev s červenou zbrojí, se třemi hořícími modrými granáty pod sebou na trupu, dole stříbrná svatojakubská mušle."
    },
    {
        obec: "Bílá Voda",
        kod: 525227,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79069,
        latitude: 50.442133,
        longitude: 16.916515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/B%C3%ADl%C3%A1_Voda_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: červený, žlutý a modrý v poměru 12:1:3. V horním pruhu dva bílí lososi hlavami vzhůru. Mezi nimi pod sebou dva žluté řecké křížky, v žerďové a vlající části po jednom křížku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bílčice",
        kod: 597198,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79368,
        latitude: 49.869715,
        longitude: 17.565611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/B%C3%ADl%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou lípou, pod ní do oblouku šňůra pěti bílých perel zakončená na obou koncích osmicípými žlutými hvězdami na úrovni poloviny kmene. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lípa, pod ní do oblouku šňůra pěti stříbrných perel zakončená na obou stranách zlatou osmihrotou hvězdou."
    },
    {
        obec: "Bílé Podolí",
        kod: 533971,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28572,
        latitude: 49.956586,
        longitude: 15.491127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Znak_obce_B%C3%ADl%C3%A9_Podol%C3%AD.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, zelený, žlutý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zelenou patou divý muž držící pravou rukou palmu, vše přirozené barvy, provázené zlatými majuskulními písmeny W a M."
    },
    {
        obec: "Bílé Poličany",
        kod: 579076,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54452,
        latitude: 50.390894,
        longitude: 15.731985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Bile_policany_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Uprostřed dva k sobě obrácení korunovaní dvouocasí lvi s červenou zbrojí, v modrém poli žlutý, v bílém červený, mezi nimi nahoře žlutý prsten s červeným kamenem a dole bílo - červeně polcený tlapatý kříž. Pod nimi volný žluto - modře polcený oboustranně zubatý posunutý pruh se třemi zuby k hornímu a dvěma zuby k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně polceném štítě nad zlato - modře polceným volným cimbuřovým břevnem, dva k sobě obrácení korunovaní dvouocasí lvi s červenou zbrojí, pravý zlatý, levý červený, mezi nimi nahoře zlatý prsten s červeným kamenem a dole stříbrno - červeně polcený tlapatý kříž."
    },
    {
        obec: "Bílence",
        kod: 562980,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.424657,
        longitude: 13.50601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/B%C3%ADlence_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, bílý, zelený oboustranně zubatý posunutý s obdélníkovými zuby (6,5), bílý, červený, bílý, vlnitý modrý se třemi vrcholy a dvěma prohlubněmi a zvlněný bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zelené cimbuřové paty se stříbrným vlnitým břevnem modrá berla podložená položeným červeným nožem."
    },
    {
        obec: "Bílina",
        kod: 567451,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41801,
        latitude: 50.548647,
        longitude: 13.775459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/B%C3%ADlina_m%C4%9Bsto_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: bílý, červený a bílý v poměru 2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě hradba, s prázdnou branou s vytaženou zlatou mříží s černými hroty, provázená po obou stranách dvěma okny nad klíčovou střílnou. Za hradbou vynikají dvě věže, každá se třemi (2, 1) okny, cimbuřím a červenou stanovou střechou se zlatou makovicí. Hradba a věže stříbrné a kvádrované, okna a střílny černé. Mezi věžemi polcený štítek vpravo červeno-stříbrně dělený, v levém stříbrném poli kosmá černá orlice se zlatou zbrojí a perizoniem. Za štítkem dva zkřížené praporce s vlaštovčími ocasy, pravý dělený černo-stříbrně, levý stříbrno-červeně, oba na zlatých žerdích. V bráně dole dvě zúžená stříbrná vlnitá břevna."
    },
    {
        obec: "Bílichov",
        kod: 535125,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.262151,
        longitude: 13.916459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Coat_of_arms_of_B%C3%ADlichov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený. Uprostřed bílého pruhu tři (2, 1) červené šestilisté květy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůl, v něm nad zeleným stonkem s listem vlevo devět červených šestilistých květů (1, 2, 1, 2, 1, 2)."
    },
    {
        obec: "Bílov",
        kod: 546984,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74301,
        latitude: 49.73417,
        longitude: 18.001712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/B%C3%ADlov_NJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy žlutý a zelený v poměru 5:1. Ze zeleného vyrůstá zelená vrba s černým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší zelená vrba s černým kmenem, za ní červený pluh."
    },
    {
        obec: "Bílkovice",
        kod: 530743,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.759822,
        longitude: 14.861056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Coat_of_arms_of_B%C3%ADlkovice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy v poměru 5 : 1 : 1 : 1, červeno-černě polcený, bílý zubatý, modrý a bílý. Zubatý pruh má šest čtvercových zubů a sedm stejně velikých mezer, krajní poloviční. V horním pruhu dva zvony, mezi nimi dole tlapatý vrubový kříž, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-černě polcený štít se stříbrnou cimbuřovou patou. V každém poli zvon, mezi nimi dole tlapatý vrubový kříž, vše zlaté. V patě modré vlnité břevno."
    },
    {
        obec: "Bílov",
        kod: 530239,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 50.017964,
        longitude: 13.42495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/B%C3%ADlov_CoA.png"
    },
    {
        obec: "Bílovice",
        kod: 592030,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.099749,
        longitude: 17.549716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/B%C3%ADlovick%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a bílé pole. V červeném pruhu pod sebou žlutá včela mezi dvěma bílými šesticípými hvězdami. V bílém poli černý orel se žlutou zbrojí a červenými jazyky a se svěšeným lipovým trojlistem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatou včelou provázenou dvěma stříbrnými hvězdami černý orel se zlatou zbrojí a červenými jazyky, na hrudi svěšený zlatý lipový trojlist."
    },
    {
        obec: "Bílovec",
        kod: 599247,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74301,
        latitude: 49.756486,
        longitude: 18.015919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/CoA_of_B%C3%ADlovec.svg",
        coatOfArmsFlagDescription: "Na červeném listu praporu bílá zavinutá střela. Poměr šířky praporu k délce je 2:3."
    },
    {
        obec: "Bílovice nad Svitavou",
        kod: 582824,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66401,
        latitude: 49.247178,
        longitude: 16.672575,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Znak_obce_Bilovice_nad_Svitavou.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů: zelený, bílý, zelený, bílý, modrý, žlutý a modrý v poměru 3:3:3:3:4:4:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní pole třikrát zeleno-stříbrně dělené, v dolním modrém poli horní část zlatého mlýnského kola se třemi rameny a lopatkami."
    },
    {
        obec: "Bílovice-Lutotín",
        kod: 589276,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79841,
        latitude: 49.511097,
        longitude: 17.043319
    },
    {
        obec: "Bílsko",
        kod: 550833,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.159145,
        longitude: 14.059108
    },
    {
        obec: "Bílsko",
        kod: 552062,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.644661,
        longitude: 17.019776,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Bilsko_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu a zelené pole. V červeném pruhu tři bílé vlající klíny s vrcholy na žerďovém okraji, v zeleném poli tři dole spojené odkloněné žluté obilné klasy, krajní s listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými kužely tři dole spojené odkloněné zlaté obilné klasy, krajní s listem."
    },
    {
        obec: "Bílsko u Hořic",
        kod: 548901,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.369489,
        longitude: 15.600604,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Bilsko_u_Horic_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu k dolnímu okraji listu obrácené zkřížené bílé kluky s červeným opeřením a hroty, nahoře a dole provázené chmelovou šišticí, po stranách chmelovým listem, vše žluté a odvrácené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dolů obrácené zkřížené stříbrné kluky s červeným opeřením a hroty, nahoře a dole provázené chmelovou šišticí, vpravo a vlevo chmelovým listem, vše zlaté a odvrácené."
    },
    {
        obec: "Bílý Kámen",
        kod: 586889,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58841,
        latitude: 49.417417,
        longitude: 15.560205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Coat_of_Arms_of_B%C3%ADl%C3%BD_K%C3%A1men.svg",
        coatOfArmsFlagDescription: "Červený list s bílým vrchem vynikajícím z dolního okraje listu do poloviny šířky listu. Nad ním kosmo gotický klíč zuby nahoru a k žerďovému okraji podložený šikmo mečem, obojí žluté. V návrší obrácená černá hornická kladívka na červených topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrným vrchem s dolů obrácenými černými hornickými kladívky na červených topůrkách kosmo vztyčený gotický klíč šikmo podložený vztyčeným mečem, obojí zlaté."
    },
    {
        obec: "Bílý Kostel nad Nisou",
        kod: 563919,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46331,
        latitude: 50.823246,
        longitude: 14.924507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Bily_Kostel_nad_Nisou_CoA_CZ.png",
        coatOfArmsFlagDescription: "List praporu tvoří zelený žerďový pruh široký jednu čtvrtinu délky listu a tři pruhy: modrý, bílý a modrý v poměru 1:7:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně sníženě děleném štítě nahoře stříbrné průčelí trojlodního kostela s gotickými zlatými zavřenými vraty a černým prolomeným oknem, s červenou stanovou střechou zakončenou zlatým latinským křížem. Dole stříbrné vlnité břevno."
    },
    {
        obec: "Bílý Potok",
        kod: 546631,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46362,
        latitude: 50.873842,
        longitude: 15.222203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Bily_Potok_LB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé nad stejně velkým červeno-žlutě polceným polem a šest vodorovných pruhů, zelený, vlnité, střídavě bílé a zelené se třemi vrcholy a dvěma prohlubněmi, a zvlněný bílý. V karé bílé vozové kolo. V červeném poli bílý středový vodorovný pruh s červenými zkříženými ostrvemi se čtyřmi suky široký dvě pětiny žerďového okraje červeného pole. Ve žlutém poli vyniká od žerďového okraje pole černý orel s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zvýšeně dělený štít, nahoře polcený. V pravém modrém poli stříbrné vozové kolo, levé pole červeno-zlatě polcené, vpravo stříbrné břevno se zkříženými červenými ostrvemi se čtyřmi suky, vlevo doleva vynikající černý orel s červenou zbrojí. Dolní pole pětkrát zeleno-stříbrně vlnitě dělené."
    },
    {
        obec: "Bílý Újezd",
        kod: 576107,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.238541,
        longitude: 16.22537
    },
    {
        obec: "Biřkov",
        kod: 578088,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.518156,
        longitude: 13.230256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Bi%C5%99kov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, červeno-bíle pětkrát dělený, modrý a bílý. Ve vlající polovině listu bílo-červeně polcená orlice se žlutou zbrojí, jetelovitě zakončeným perizoniem a korunou navlečenou na krku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole polcené, vpravo červené, vlevo červeno-stříbrně pětkrát dělené. V levém, modro-stříbrně polceném poli stříbrno-červeně polcená orlice se zlatou zbrojí, jetelovitě zakončeným perizoniem a korunou navlečenou na krku."
    },
    {
        obec: "Biskoupky",
        kod: 582832,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.097789,
        longitude: 16.281386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Biskoupky_Brno_venkov_znak.svg",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo zlatá hlavice berly závitem doleva, vlevo stříbrný vinařský nůž-kosíř."
    },
    {
        obec: "Biskupice",
        kod: 589284,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79812,
        latitude: 49.459774,
        longitude: 17.236699,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Biskupice_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným ondřejským křížem s rameny širokými jednu šestinu šířky listu. Uprostřed černá zubří hlava s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá zubří hlava s červeným jazykem a zlatou houžví, kosmo podložená berlou závitem ven a šikmo podložená procesním křížem, obojí červené."
    },
    {
        obec: "Biskupice",
        kod: 573949,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.872861,
        longitude: 15.512907
    },
    {
        obec: "Biskupice",
        kod: 572519,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.647572,
        longitude: 16.758148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/CoA_of_Biskupice_%28okres_Svitavy%29.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou radlicí hrotem k hornímu a ostřím k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčená zlatá radlice."
    },
    {
        obec: "Biskupice",
        kod: 585076,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76341,
        latitude: 49.083208,
        longitude: 17.710527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Biskupice_ZL_CoA_CZ.JPG",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy červený, zelený a červený v poměru 1:2:1. V žerďovém pruhu čtyři vlající a ve vlajícím pruhu čtyři žerďové klíny, všechny bílé. V zeleném pruhu zkřížené žluté berly s bílými sudarii, mezi nimi nahoře žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se čtyřmi stříbrnými kužely zkřížené odvrácené berly se stříbrnými sudarii, mezi nimi nahoře osmihrotá hvězda, vše zlaté."
    },
    {
        obec: "Biskupice-Pulkov",
        kod: 590312,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67558,
        latitude: 49.038255,
        longitude: 16.009555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Biskupice-pulkov_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list s červenou krokví širokou čtvrtinu šířky listu, vycházející z dolního rohu a cípu listu s vrcholem na horním okraji listu a provázenou třemi (2, 1) žlutými růžemi. V krokvi dva proti sobě vyskakující bílí lvi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná kvádrovaná hradba se sedmi stínkami, s prolomenou branou a po každé straně s černým oknem. Z hradby vynikají dvě stříbrné věže s cimbuřím se třemi stínkami a se třemi (2, 1) černými okny, s modrou stanovou střechou se zlatou makovicí. Mezi věžemi modrý štítek s červenou sníženou krokví provázenou třemi (2, 1) zlatými růžemi, v ní dva proti sobě vyskakující stříbrní lvi, v bráně levá polovina zlatého palečného kola."
    },
    {
        obec: "Bítouchov",
        kod: 535486,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29401,
        latitude: 50.475882,
        longitude: 14.889523
    },
    {
        obec: "Bítov",
        kod: 593753,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67110,
        latitude: 48.936395,
        longitude: 15.729075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Znak_obce_bitova.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, červený a bílý, v poměru 4:1:3. V žerďové části modrého pruhu žlutá merka tvořená dole půlměsícem cípy k hornímu okraji, z něhož vyrůstá k hornímu okraji střela s příčným břevnem pod hrotem a od žerdi přiléhajícím obloukem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s prázdnou branou se zlatou spuštěnou mříží, černým oknem a červenou střechou, na ní stříbrná věžička se čtyřmi černými okny vedle sebe a s červenou obloukovou střechou. Na ní zlatá merka tvořená stoupajícím půlměsícem, vztyčenou střelou s příčným břevnem pod hrotem a zprava přiléhajícím obloukem."
    },
    {
        obec: "Bítov",
        kod: 554936,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74301,
        latitude: 49.800422,
        longitude: 18.047592,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/B%C3%ADtov_NJ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým planoucím srdcem. Na vlajícím okraji svislý bílý pruh široký jednu šestinu délky listu se třemi čtvercovými zuby, dvěma obdélníkovými mezerami a dvěma polovičními mezerami. Výška zubů je jedna dvanáctina délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad cimbuřím planoucí srdce, obojí stříbrné."
    },
    {
        obec: "Bítovany",
        kod: 505005,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.897307,
        longitude: 15.86473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/B%C3%ADtovany-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 4 : 1. Ve žlutém pruhu kosmo červený plod kotvice, z něhož vyrůstají dva odkloněné zelené listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou kosmo červený plod kotvice, z něhož vyrůstají dva odkloněné zelené listy."
    },
    {
        obec: "Bítovčice",
        kod: 586897,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58822,
        latitude: 49.368136,
        longitude: 15.734153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Coat_of_arms_of_B%C3%ADtov%C4%8Dice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 6 : 1 : 1. V žerďové polovině horního modrého pruhu bílý vykračující kohout s červenou zbrojí a ocasními pery střídavě bílými a černými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem stříbrný vykračující kohout s červenou zbrojí a ocasními pery střídavě stříbrnými a černými."
    },
    {
        obec: "Bitozeves",
        kod: 565997,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.37326,
        longitude: 13.640793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Bitozeves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový klín s vrcholem ve středu listu a tři vodorovné pruhy, červený, bílý a modrý, v poměru 3 : 2 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře vlnitým stříbrným břevnem děleném štítě nahoře doleva obrácená zlatá koroptev, dole volná stříbrná tvrz s černými vraty a červenými střechami."
    },
    {
        obec: "Blanné",
        kod: 593761,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 48.992037,
        longitude: 15.883346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Coat_of_arms_of_Blann%C3%A9.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu dvě růže pod sebou, mezi nimi kypřice, vše bílo-červeně polcené, růže se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém stříbrno-červeně šikmo děleném poli kosmo černý hák bez násady. V levém modrém poli dvě růže pod sebou, mezi nimi kypřice, vše stříbrno-červeně polcené, růže se zlatými semeníky."
    },
    {
        obec: "Blansko",
        kod: 581283,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67801,
        latitude: 49.36495,
        longitude: 16.647855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Blansko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý v poměru 2:5:2. V modrém pruhu bílá, od základů se zužující kvádrovaná věž s kupolovitou střechou s jedním kuželem se zlatou makovicí na vrcholu a dvěma po stranách. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Blatce",
        kod: 561401,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.502986,
        longitude: 14.603251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Blatce_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, z druhé třetiny dolního okraje listu vyniká věž s cimbuřím, nad ní koruna provázená dvěma svěšenými chmelovými šišticemi, vše žluté. Ve věži zkřížené černé ostrve. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá věž s cimbuřím, v ní zkřížené černé ostrve. Nad věží koruna provázená dvěma svěšenými chmelovými šišticemi, vše zlaté."
    },
    {
        obec: "Blatec",
        kod: 500801,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78375,
        latitude: 49.528444,
        longitude: 17.238673,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Blatec_znak.png",
        coatOfArmsFlagDescription: "List tvoří červená žerďová a modrá vlající část, přes které je položen žlutý středový kříž s rameny širokými jednu desetinu šířky listu, v kantonu kosmo vztyčený bílý meč se žlutým jílcem a záštitou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se dvěma spojenými stříbrno-červeně šachovanými křídly kosmo vztyčený stříbrný meč se zlatou rukojetí šikmo přeložený zlatým latinským křížem."
    },
    {
        obec: "Blatnice",
        kod: 590321,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.070235,
        longitude: 15.871431,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Blatnice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V žerďové části červeného pruhu lilie, ve vlající části modrého pruhu půlměsíc cípy k žerdi, oboje bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít s červenou patou a modrým vydutým hrotem. V hrotě provázeném dvěma zkříženými černými ostrvemi stříbrný dorůstající půlměsíc, v patě tři (1, 2) spojené zlaté kruhy provázené dvěma stříbrnými liliemi."
    },
    {
        obec: "Blatná",
        kod: 550850,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.424993,
        longitude: 13.881864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Znak_blatna_cz.jpg",
        coatOfArmsFlagDescription: "List tvoří vodorovný žlutý a červený pruh. V horním rohu je k žerdi obrácená černá kančí hlava s červeným jazykem a bílými kly a zuby. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Blatnice",
        kod: 558672,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33025,
        latitude: 49.719824,
        longitude: 13.156096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Blatnice_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený, v poměru 1 : 6 : 1. Ve žlutém pruhu červený lev mezi dvěma černými jeleními parohy o pěti výsadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červený lev mezi dvěma přivrácenými černými parohy o pěti výsadách, pod nimi tři (2, 1) zelené blatouchové listy."
    },
    {
        obec: "Blatnice pod Svatým Antonínkem",
        kod: 586048,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69671,
        latitude: 48.946824,
        longitude: 17.466069,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Blatnice_pod_Svatym_Antoninkem_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1:4:1. Uprostřed bílá kvádrovaná kaple se dvěma černými půlkruhovými okny a stanovou střechou zakončenou žlutou makovičkou s křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se zeleným pravým koutem stříbrná kvádrovaná kaple se dvěma černými půlkruhovými okny a stanovou střechou zakončenou zlatou makovičkou s křížkem, provázená vpravo rozevřenou knihou a vlevo vinným hroznem, obojí zlaté."
    },
    {
        obec: "Blatnička",
        kod: 586056,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69671,
        latitude: 48.935609,
        longitude: 17.530151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Blatnicka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a žlutý, v poměru 1:2:1. Uprostřed kosmo položená bílá kosa, šikmo přeložená žlutými hráběmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo položená stříbrná kosa šikmo přeložená zlatými hráběmi."
    },
    {
        obec: "Blatno",
        kod: 562998,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.511799,
        longitude: 13.358172,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Blatno_CV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový klín lemovaný černo-žlutě děleným lemem širokým dvacetinu šířky listu na žerďovém okraji s vrcholem na vlajícím okraji listu. Horní vlající pole bílé, dolní zelené. V klínu vodorovně plamenný meč hořící osmi plameny (4, 4) hrotem k žerdi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravé stříbrno-zlatě dělené polovině nahoře černý heroldský kříž, dole zvýšené zelené trojvrší se stříbrným vlnitým kůlem, v levé modré polovině vztyčený plamenný meč hořící osmi plameny (4, 4), vše zlaté."
    },
    {
        obec: "Blatno",
        kod: 566004,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43984,
        latitude: 50.097026,
        longitude: 13.389809,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Blatno_LN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě zelené a bílé. Ve střední části nad šesticípou hvězdou maltézský kříž bez horního ramene, oboje červené. Nad křížem žluté obrněné rameno držící červeně hořící meč se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě nad hvězdou maltézský kříž bez horního ramene, obojí červené. Nad křížem zlaté obrněné rameno držící červeně hořící meč se zlatým jílcem."
    },
    {
        obec: "Blazice",
        kod: 542318,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.44257,
        longitude: 17.645401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/CoA_of_Blazice.svg",
        coatOfArmsFlagDescription: "Modro-červeně čtvrcený list žlutým středovým křížem s rameny širokými jednu desetinu délky listu. V horním žerďovém poli žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně zlatým heroldským křížem čtvrcený štít, v prvním poli zlatá osmihrotá hvězda."
    },
    {
        obec: "Blažejov",
        kod: 561711,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37852,
        latitude: 49.142295,
        longitude: 15.096553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/CZE_Bla%C5%BEejov_COA.svg",
        coatOfArmsFlagDescription: "Modrý list s třemi bílými vlajícími klíny nad sebou s vrcholy ve druhé třetině délky listu. V žerďové a střední části listu krokvovitě (od žerdi 2,2,1) pět žlutých růží s červenými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vykořeněný pařez s pěti vyrůstajícími zlatými růžemi s červenými semeníky na zelených stoncích s listy a trny, od středu odkloněnými."
    },
    {
        obec: "Blažim",
        kod: 566764,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33038,
        latitude: 49.916024,
        longitude: 13.047724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Bla%C5%BEim_%28PS%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed čelně hledící polovina bílého zubra ve skoku se žlutou zbrojí, jazykem a houžví, provázená nad hřbetem bílým maltézským křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě polovina stříbrného zubra ve skoku se zlatou zbrojí, jazykem a houžví, provázená vlevo nahoře stříbrným maltézským křížem."
    },
    {
        obec: "Blažejovice",
        kod: 532380,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.619897,
        longitude: 15.200036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Bla%C5%BEejovice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeným žerďovým pruhem širokým třetinu délky listu. V žerďové polovině listu kosmo klíč zuby nahoru a k žerdi, šikmo podložený třemi dole spojenými obilnými klasy, krajní odkloněné s listem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě kosmo vztyčený klíč, šikmo podložený třemi dole spojenými obilnými klasy, krajní odkloněné s listem, vše zlaté."
    },
    {
        obec: "Blažim",
        kod: 566012,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.40695,
        longitude: 13.628683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Bla%C5%BEim_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů. Červený, bílý, modrý, bílý a modrý, v poměru 2 : 1 : 1 : 1 : 1. V červeném pruhu žlutý obilný klas se dvěma odkloněnými listy, podložený v dolní části čelně postavenou bílou radlicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli zlatý obilný klas se dvěma odkloněnými listy, dole podložený čelně postavenou stříbrnou radlicí, levé pole třikrát stříbrno-modře polcené."
    },
    {
        obec: "Blažkov",
        kod: 595241,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.473174,
        longitude: 16.185267,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Blazkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený s bílým jelenem a žlutý, v poměru 10:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou patou stříbrný jelen."
    },
    {
        obec: "Blažovice",
        kod: 582841,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66408,
        latitude: 49.165785,
        longitude: 16.786216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Bla%C5%BEovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený. V žerďové polovině zeleného pruhu rozevřená kniha, pod ní v červeném pruhu lilie, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně děleném štítě nahoře rozevřená kniha, dole lilie, obojí stříbrné."
    },
    {
        obec: "Blešno",
        kod: 569879,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.214011,
        longitude: 15.930746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/CoA_of_Ble%C5%A1no.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý, v poměru 5:3. Uprostřed žlutá loďka. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleno-modře zvýšeně vlnitě děleném štítě nahoře na dělící linii zlatá loďka, dole postavená stříbrná vrš."
    },
    {
        obec: "Blevice",
        kod: 532100,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27328,
        latitude: 50.209825,
        longitude: 14.236267,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Blevice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový zubatý pruh, široký pětinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami, a zelené pole, v něm šikmo žluté housle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou šikmo zlaté housle."
    },
    {
        obec: "Blízkov",
        kod: 595250,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59442,
        latitude: 49.400584,
        longitude: 15.90881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Blizkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, žlutý a zelený v poměru 2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře dva zlaté zkřížené ovesné stvoly, dole v zeleném hrotu zlatý utržený vztyčený lískový oříšek."
    },
    {
        obec: "Blížejov",
        kod: 553450,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34545,
        latitude: 49.500115,
        longitude: 12.989366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Blizejov_znak.png",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list. Uprostřed bílý beran se žlutou zbrojí stojící na žlutém jelením parohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno - modře šikmo děleném štítě stříbrný beran se zlatou zbrojí stojící na zlatém jelením parohu."
    },
    {
        obec: "Blíževedly",
        kod: 561410,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47104,
        latitude: 50.608503,
        longitude: 14.396598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Blizevedly_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. Z dolního okraje listu do modrého pruhu vyniká hradba s prázdnou bránou s vytaženou žlutou mříží. Na hradbě věž s cimbuřím a černým, křížem rozděleným oknem, provázená po stranách žlutou šesticípou hvězdou. Hradba a věž bílé, kvádrované. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na hradbě s prázdnou bránou s vytaženou zlatou mříží věž s cimbuřím a černým, křížem rozděleným oknem, provázená po stranách zlatou hvězdou. Hradba a věž stříbrné, kvádrované."
    },
    {
        obec: "Blížkovice",
        kod: 593770,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67155,
        latitude: 48.999862,
        longitude: 15.834918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Blizkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů střídavě žlutých a zelených, v poměru 1 : 1 : 6 : 1 : 1. Na dolním okraji listu zelené návrší široké polovinu délky listu a vysoké pětinu šířky listu. Přes návrší do žlutého pole tři vějířovitě rozložená paví pera. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným návrším tři vějířovitě rozložená paví pera přirozené barvy."
    },
    {
        obec: "Blovice",
        kod: 557587,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.582303,
        longitude: 13.540199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Blovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list; uprostřed volná bílá kvádrovaná hradba s prázdnou branou se žlutými vraty a černou vytaženou mříží. Za hradbou vynikají tři věže, prostřední vyšší, každá se dvěma černými okny vedle sebe, s cimbuřím a červenou stanovou střechou zakončenou žlutým křížkem. Střecha prostřední věže je provázená půlměsícem cípy k žerďovému okraji a šesticípou hvězdou, oboje žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou se zlatými vraty a černou vytaženou mříží. Za hradbou vynikají tři věže, prostřední vyšší, každá se dvěma černými okny vedle sebe, s cimbuřím a červenou stanovou střechou zakončenou zlatým křížkem. Střecha prostřední věže je provázená dorůstajícím půlměsícem a hvězdou, oboje zlaté."
    },
    {
        obec: "Blšany",
        kod: 566021,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43988,
        latitude: 50.217441,
        longitude: 13.471313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Blsany_znak.jpg"
    },
    {
        obec: "Blšany u Loun",
        kod: 542547,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.347086,
        longitude: 13.852757,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Bl%C5%A1any_u_Loun_znak.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutou berlou závitem k žerďovému okraji podloženou dvakrát zkříženou větévkou vinné révy s hroznem a lodyhou chmele s trojicí šištic, obojí se třemi listy nahoře, jedním dole a úponky, vše žluté. V závitu berly žlutý tlapatý kříž přeložený žlutým červeně hořícím mezikružím, v něm černý gotický štítek se žlutým břevnem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě berla podložená dole dvakrát zkříženou větévkou vinné révy s hroznem a lodyhou chmele s trojicí šištic, obojí s třemi listy nahoře, jedním dole a úponky, vše zlaté. V závitu berly zlatý tlapatý kříž přeložený červeně hořícím kruhem, v něm černý gotický štítek se zlatým břevnem."
    },
    {
        obec: "Blučina",
        kod: 582859,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66456,
        latitude: 49.055066,
        longitude: 16.644601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Blu%C4%8Dina_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se zeleným rovnostranným trojúhelníkem s vrcholy na horním a dolním okraji listu a v dolním cípu. V zeleném trojúhelníku žlutý, šikmo položený pravoúhlý lichoběžník vycházející ze druhé třetiny dolního okraje trojúhelníka a dotýkající se jedním rohem vlající strany trojúhelníka. V modrém žerďovém poli šest (3,2,1) vzájemně se dotýkajících žlutých kruhových polí, z nichž horní tři se dotýkají současně horního okraje listu, první žerďového okraje a tři žerďové strany trojúhelníka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně sníženě děleném štítě dva vinaři nesoucí na tyči hrozen s listem, vše zlaté."
    },
    {
        obec: "Bludov",
        kod: 525588,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78961,
        latitude: 49.941116,
        longitude: 16.928111,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Znak_obce_Bludov_u_%C5%A0umperka.gif",
        coatOfArmsFlagDescription: "Na červeném listu, černý Žerotínský lev držící v tlapách bílé majuskulní písmeno B, vyrůstající z bílého jednopětinového pruhu při dolním okraji; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "V červeném poli ze stříbrného trojvrší vyrůstá až po kýty černý lev se zlatou korunkou, jazykem a zbrojí, držící v tlapách stříbrné latinské majuskulní písmeno B."
    },
    {
        obec: "Bludov",
        kod: 530964,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.807592,
        longitude: 15.255286
    },
    {
        obec: "Bobnice",
        kod: 537039,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28931,
        latitude: 50.219681,
        longitude: 15.053622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Coats_of_arms_Bobnice.jpeg",
        coatOfArmsFlagDescription: "List kosmo dělený bílým a černým pruhem, každý široký jednu dvanáctinu šířky listu, na červené žerďové a žluté vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo stříbrná dvoupatrová věž se soklem, dveřmi, dvěma obloukovými okny nad sebou, zakončená zvoncovitou střechou, vlevo šikmo vztyčený černý lipový list s plody."
    },
    {
        obec: "Bobrová",
        kod: 595268,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.482402,
        longitude: 16.118399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Bobrova_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký polovinu délky listu a pět vodorovných pruhů střídavě černých a bílých. Ve žlutém pruhu černá zubří hlava se žlutou houžví a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-černě děleném štítě nahoře černá zubří hlava se zlatou houžví a červeným jazykem, dole dvě stříbrná břevna."
    },
    {
        obec: "Bobrůvka",
        kod: 595276,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.44874,
        longitude: 16.093912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Bobruvka_CoA_CZ_2.png",
        coatOfArmsFlagDescription: "Bílý list s černým svislým pruhem širokým jednu třetinu šířky listu, jehož osa je v jedné třetině délky listu od žerďového okraje. Bílé žerďové a vlající pole zasahující do černého pruhu dvěma rovnoramennými pravoúhlými trojúhelníky se společným vrcholem ve středu černého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným lemem posázeným zlatými liliemi, černý sedící bobr."
    },
    {
        obec: "Bocanovice",
        kod: 511633,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73991,
        latitude: 49.569174,
        longitude: 18.738514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Bocanovice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý v poměru 1:3:1. V zeleném pruhu bílý kráčející čáp s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kráčející čáp přirozené barvy."
    },
    {
        obec: "Boháňka",
        kod: 572705,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.365894,
        longitude: 15.708485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Boh%C3%A1%C5%88ka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 2, bílý a vypouklý zelený s obloukem sahajícím do sedmi šestnáctin šířky listu. V bílém pruhu dva vztyčené odkloněné červené javorové listy. V zeleném pruhu bílá pemrlice se žlutým toporem, kosmo přeloženým dlátem a šikmo podloženým špicí, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zvýšeným zeleným návrším dva vztyčené odkloněné červené javorové listy. V návrší stříbrná pemrlice se zlatým toporem, kosmo přeloženým dlátem a šikmo podloženým špicí, obojí stříbrné."
    },
    {
        obec: "Boharyně",
        kod: 569887,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50323,
        latitude: 50.203911,
        longitude: 15.630961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Coat_of_arms_of_Boharyn%C4%9B.svg",
        coatOfArmsFlagDescription: "Žlutý list, uprostřed hnědé mlýnské kolo obklopené pěti (2, 2, 1) odvrácenými zelenými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě dřevěné mlýnské kolo přirozené barvy obklopené pěti (2, 2, 1) odvrácenými zelenými lipovými listy."
    },
    {
        obec: "Bohatice",
        kod: 561428,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47002,
        latitude: 50.669033,
        longitude: 14.679426,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Bohatice_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 3:2:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trávníku smrk přirozené barvy."
    },
    {
        obec: "Bohaté Málkovice",
        kod: 592897,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.189126,
        longitude: 17.014284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Znak_Bohat%C3%A9_M%C3%A1lkovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý. Uprostřed bílé liliové žezlo provlečené dole žlutou korunou. Ze žezla vyrůstají do stran dva žluté lipové listy na jednom stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě stříbrné liliové žezlo provlečené dole zlatou korunou. Ze žezla vyrůstají do stran dva zlaté lipové listy na jednom stonku."
    },
    {
        obec: "Bohdalec",
        kod: 595284,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.474379,
        longitude: 16.05987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Bohdalec_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a černý, v poměru 8:1:1. Z bílého pruhu vyskakuje žlutý jelen s bílou zbrojí provázený v žerďové části bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrné paty s černým břevnem vyskakující zlatý jelen se stříbrnou zbrojí, provázený vpravo nahoře stříbrnou lilií."
    },
    {
        obec: "Bohdalice-Pavlovice",
        kod: 592901,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68341,
        latitude: 49.214143,
        longitude: 17.030228,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Znak_Bohdalice-Pavlovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený s bílou lilií v žerďové části, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře lilie a vyrůstající obrněný muž se sekyrou přes rameno, vše stříbrné, dole kosmo položená stříbrná radlice šikmo podložená stříbrným postaveným mečem se zlatým jílcem."
    },
    {
        obec: "Bohdalín",
        kod: 547549,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39491,
        latitude: 49.307781,
        longitude: 15.015943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Coat_of_arms_of_Bohdal%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří žluté karé nad stejně velkým bílým čtvercovým polem a sedm vodorovných pruhů, střídavě bílých a vlnitých modrých. V karé modrý květ lnu se žlutým středem, v bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod hrotem oddělenou zlatou hlavou se dvěma modrými květy lnu se zlatými středy červená růže se zlatým semeníkem a zelenými kališními lístky nad třemi zúženými modrými vlnitými břevny."
    },
    {
        obec: "Bohdalov",
        kod: 595292,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59213,
        latitude: 49.480764,
        longitude: 15.876542,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Bohdalov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černou zubří hlavou s polovinou zelené houžve v levé nozdře. Mezi rohy černé zkřížené ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá zubří hlava s polovinou zelené houžve v levé nozdře. Mezi rohy černé zkřížené ostrve."
    },
    {
        obec: "Bohdalovice",
        kod: 536253,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38101,
        latitude: 48.74294,
        longitude: 14.286864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Bohdalovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část a modrá vlající část. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo vykořeněná jedle přirozených barev, vlevo zlatý obilný klas. V horní části štítu na poltící linii stříbrný štítek se třemi černými, do středu se slétajícími havrany, držícími v zobácích zlatou korunu."
    },
    {
        obec: "Bohdaneč",
        kod: 533980,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28525,
        latitude: 49.778273,
        longitude: 15.222656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Bohdanec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutou krokví vycházející z druhé třetiny horního a dolního okraje listu, s vrcholem na žerďovém okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá z hradby se dvěma stínkami do krajů šraňk, vše zlaté."
    },
    {
        obec: "Bohdašín",
        kod: 576115,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.338561,
        longitude: 16.225153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Bohda%C5%A1%C3%ADn_%28RK%29%2C_znak.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým zubatým pruhem širokým jednu čtvrtinu délky listu se třemi čtvercovými a dvěma polovičními zuby a čtyřmi čtvercovými mezerami. Uprostřed bílého pole vykořeněná lípa se zelenými listy a hnědým kmenem, podloženým vodorovně červeným kladivem, tloukem nahoru, s topůrkem přeloženým na vlající straně listu zelenou šesticípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zelenou cimbuřovou hlavou vykořeněná lípa přirozené barvy s kmenem podloženým položeným červeným kladivem, tloukem nahoru, s topůrkem vlevo přeloženým zelenou hvězdou."
    },
    {
        obec: "Bohdíkov",
        kod: 525804,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78964,
        latitude: 50.010058,
        longitude: 16.904438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Bohdikov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 1 : 2 : 1. V bílém pruhu černý korunovaný dvouocasý lev ve skoku s červenou zbrojí a jazykem, v červených pruzích dvě žluté šesticípé hvězdy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně polceném štítě vpravo černý korunovaný dvouocasý lev ve skoku s červenou zbrojí a jazykem, vlevo postavený stříbrný meč se zlatým jílcem prostrčený vztyčeným zlatým gotickým dvojklíčem a provázený čtyřmi (2, 2) zlatými hvězdami."
    },
    {
        obec: "Bohostice",
        kod: 564559,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.602243,
        longitude: 14.138233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/CoA_of_Bohostice.svg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový klín s vrcholem ve středu listu a pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 1 : 1 : 2 : 1 : 1.Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zeleného trojvrší zlatý tlapatý kříž se dvěma stříbrnými zvony zavěšenými na ramenech."
    },
    {
        obec: "Bohumilice",
        kod: 550116,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.09618,
        longitude: 13.816441,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Bohumilice_%28Prachatice_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a bílých, v poměru 1 : 1 : 14 : 1 : 1. V prostředním pruhu čelně bílá rozkřídlená holubice se zkříženými třískami v zobáku, převýšená sedmicípou hvězdou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě čelně stříbrná rozkřídlená holubice se zkříženými třískami v zobáku, převýšená sedmihrotou hvězdou, vše zlaté. Dole dvě stříbrná vlnitá břevna."
    },
    {
        obec: "Bohumín",
        kod: 599051,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73581,
        latitude: 49.904426,
        longitude: 18.357074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Bohumin_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3 : 4. V modrém pruhu žluté okřídlené železniční kolo, v červeném pruhu bílá obrácená vidlice s rameny širokými čtvrtinu délky listu, uprostřed přeložená černým ozubeným kolem s rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se zlatým okřídleným železničním kolem uprostřed stříbrné obrácené vidlice černé ozubené kolo s rameny."
    },
    {
        obec: "Bohunice",
        kod: 537527,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.132496,
        longitude: 13.973287
    },
    {
        obec: "Bohuňov",
        kod: 595306,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.560508,
        longitude: 16.202547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Bohunov_ZR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve středu listu a dva vodorovné pruhy, zelený a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně plamenným řezem děleném štítě vyrůstá stříbrná zlatovlasá dívka od ramen nahoru."
    },
    {
        obec: "Bohuňov",
        kod: 577812,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.599198,
        longitude: 16.463955,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý, v poměru 7 : 1 : 1 : 1. V červeném pruhu bílá otevřená kniha převýšená žlutým patriarším křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě červeno-stříbrně dělený štít. Nahoře stříbrná otevřená kniha převýšená zlatým patriarším křížem, dole modré břevno."
    },
    {
        obec: "Bohuňovice",
        kod: 500852,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78314,
        latitude: 49.663112,
        longitude: 17.287037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Bohu%C5%88ovice_OL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, žlutý, červený a modrý v poměru 5:1:1:1:3. V horním rohu bílý beránek se žlutou zbrojí a latinským křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně čtvrcený štít, v prvním a čtvrtém poli stříbrný beránek se zlatou zbrojí a latinským křížem, ve druhém poli černá plamenná orlice se zlatou zbrojí, ve třetím poli postavené krojidlo a radlice, obojí červené."
    },
    {
        obec: "Bohuňovice",
        kod: 577821,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.907982,
        longitude: 16.262964
    },
    {
        obec: "Bohuslavice",
        kod: 586901,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.14953,
        longitude: 15.575917
    },
    {
        obec: "Bohuslavice",
        kod: 573892,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54906,
        latitude: 50.312731,
        longitude: 16.088688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Bohuslavice_NA_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu bílá vykořeněná lekna a bílá obrácená kotva s okem, provlečeným žlutým lanem. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, vpravo třikrát šikmo červeno - stříbrně dělený, v levém modrém poli nahoře vykořeněná lekna, dole kosmá obrácená kotva s okem provlečeným zlatým lanem, vše stříbrné."
    },
    {
        obec: "Bohuslavice",
        kod: 589292,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79856,
        latitude: 49.620952,
        longitude: 16.957654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Bohuslavice_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V bílém pruhu červená kvádrovaná věž s cimbuřím, podložená dvěma zkříženými modrými noži hroty nahoru a ostřím dolů, s černými rukojeťmi se dvěma žlutými nýty. V modrém pruhu žlutá medvědí hlava s bílými zuby a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře červená kvádrovaná věž s cimbuřím, podložená dvěma zkříženými modrými noži s černými rukojeťmi se dvěma zlatými nýty, dole zlatá medvědí hlava se stříbrnými zuby a červeným jazykem."
    },
    {
        obec: "Bohuslavice",
        kod: 525880,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78972,
        latitude: 49.826153,
        longitude: 16.940636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Znak_obce_Bohuslavice_u_%C5%A0umperka.gif",
        coatOfArmsFlagDescription: "Bílý list s modrým zvlněným pruhem na dolním okraji širokým jednu třetinu šířky listu. Pruh má pět vrcholů a čtyři prohlubně. V bílém poli modrý kapr s červenými ploutvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít vlnitě dělený, nahoře stříbrný a dole dvakrát rovněž vlnitě dělený modře a stříbrně, v horní části modrý kapr s červenými ploutvemi."
    },
    {
        obec: "Bohuslavice",
        kod: 506192,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74719,
        latitude: 49.942415,
        longitude: 18.128764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Bohuslavice_%28okres_Opava%29_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi bílými růžemi se žlutými semeníky v žerďové části, oddělené v jedné třetině délky listu bílým zvlněným pruhem o šířce 1/40 délky listu. Bílý pruh má směrem k žerdi tři vrcholy a čtyři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno provázené třemi (2,1) stříbrnými růžemi se zlatými semeníky."
    },
    {
        obec: "Bohuslavice u Zlína",
        kod: 585092,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76351,
        latitude: 49.163201,
        longitude: 17.636864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Bohuslavice_u_Zl%C3%ADna_CoA.png",
        coatOfArmsFlagDescription: "List dělený bílo-modře dělenou krokví vycházející z poslední pětiny žerďového a vlajícího okraje listu s vrcholem v jedné pětině šířky listu na modré horní pole a bílý dolní klín. Nad krokví v žerďové části osmicípá hvězda a ve vlající části kosmo radlice hrotem dolů a ostřím k hornímu cípu listu, obojí žluté. Pod krokví modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně sníženou, stříbrno-modře dělenou krokví dělený štít. Nahoře vpravo osmihrotá hvězda, vlevo kosmo doleva obrácená radlice, obojí zlaté. Dole modrá lilie."
    },
    {
        obec: "Bohuslávky",
        kod: 569178,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.555256,
        longitude: 17.567886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Bohusl%C3%A1vky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, zelený, bílý a červený, v poměru 1:1:4:1:1. Z dolního bílého pruhu vyrůstá do zeleného pruhu žlutý dub. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá z červené paty se stříbrnou zavinutou střelou zlatý dub, obojí provázené stříbrnou sekerou na zlatém topůrku a stříbrnou radlicí, obojí odvrácené."
    },
    {
        obec: "Bohušice",
        kod: 550400,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.085207,
        longitude: 15.852226
    },
    {
        obec: "Bohušov",
        kod: 597201,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.243389,
        longitude: 17.713963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/CoA_of_Bohu%C5%A1ov.svg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed žluté kruhové pole probodené třemi bílými meči, jejichž žluté jílce směřují do rohů listu a vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém poli zlatá koule probodená třemi (2,1) stříbrnými meči se zlatými jílci provázená vpravo zlatým sluncem, vlevo stříbrným srpkem měsíce."
    },
    {
        obec: "Bohušovice nad Ohří",
        kod: 564591,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41156,
        latitude: 50.493264,
        longitude: 14.150595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Bohu%C5%A1ovice_nad_Oh%C5%99%C3%AD_znak.png",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými vlnitými pruhy vycházejícími z páté a sedmé osminy žerďového a vlajícího okraje nad pruhy půl bílého dvouocasého lva se žlutou korunou a zbrojí držícího žlutou ostrev se třemi suky. Pruhy mají dva vrcholy a jednu prohlubeň. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bohutice",
        kod: 593788,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67176,
        latitude: 48.991375,
        longitude: 16.357739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Bohutice%2C_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s žerďovým modrým pruhem širokým jednu třetinu délky listu. V modrém pruhu srp přivrácený ke vztyčené listnaté větvi, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě srp přivrácený ke vztyčené listnaté větvi, obojí zlaté."
    },
    {
        obec: "Bohuslavice nad Vláří",
        kod: 557102,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.090273,
        longitude: 17.926421,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Bohuslavice_n_v_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým klínem s vrcholem v jedné třetině délky listu a se zkříženými hořícími černými ostrvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě dvě přivrácená červená račí klepeta držící dole zkřížené hořící černé ostrve, mezi nimi modrá ryba hlavou dolů."
    },
    {
        obec: "Bohutín",
        kod: 525979,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78962,
        latitude: 49.953611,
        longitude: 16.882039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Znak_obce_Bohut%C3%ADn_u_%C5%A0umperka.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý se žlutou korunou s červeným vyložením, bílý a modrý, v poměru 4:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná žába převýšená zlatou korunou s červeným vyložením."
    },
    {
        obec: "Bohutín",
        kod: 539953,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26241,
        latitude: 49.65562,
        longitude: 13.943995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Bohutin_%28Pribram%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý se zkříženými hornickými kladívky v žerďové části, žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod černou hlavou se zkříženými stříbrnými hornickými kladívky zelené trojvrší vynikající ze stříbrné vlnité paty štítu."
    },
    {
        obec: "Bohy",
        kod: 566471,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.937892,
        longitude: 13.575531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Bohy_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a bílý s modrými žerďovými trojúhelníky s odvěsnami dlouhými polovinu šířky listu. Z dělící linie vyniká do žlutého pruhu polovina černého raka hlavou k žerdi, do bílého pruhu polovina červeného středového kříže s rameny širokými šestinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě polceném štítě s modrými kouty přiléhají k poltící linii zprava půl červeného heroldského kříže a zleva půl černého raka."
    },
    {
        obec: "Bochoř",
        kod: 512532,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.421475,
        longitude: 17.428459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Bocho%C5%99_znak.png",
        coatOfArmsFlagDescription: "List s pěti vodorovnými pruhy - modrým, bílým, modrým, bílým a modrým v poměru 4:1:1:1:1. V horním modrém pruhu ve střední části žlutý laťkový plot. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělen vlnitým řezem, v horní modré polovině vyrůstá zlatý laťkový plot, dolní polovina třikrát stříbrno-modře vlnitě dělena."
    },
    {
        obec: "Bochov",
        kod: 555029,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36471,
        latitude: 50.151007,
        longitude: 13.045282,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Bochov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu dolů do oblouku volná bílá kvádrovaná hradba s prázdnou branou. Z hradby vyniká bílá okrouhlá dvoupodlažní věž se čtyřmi představenými pilíři v prvním podlaží zakončeném červenou stříškou. Druhé podlaží černě prosklené s červenou kupolovou střechou, z ní vyrůstá bílá lucerna s dvěma okny, červenou kupolovou střechou se žlutou makovicí a křížkem. Za věží vyniká červená sedlová střecha s bílou kvádrovanou štítovou zdí k vlajícímu okraji a dvěma žlutými makovicemi s křížky na koncích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dolů do oblouku stříbrná kvádrovaná hradba s prázdnou branou, vpravo vedle ní červený, šikmý štítek se zlatými hráběmi. Z hradby vyniká stříbrná okrouhlá dvoupodlažní věž se čtyřmi představenými pilíři v prvním podlaží zakončeném červenou stříškou. Druhé podlaží černě prosklené s červenou kupolovou střechou, z ní vyrůstá stříbrná lucerna s dvěma okny, červenou kupolovou střechou se zlatou makovicí a křížkem. Za věží vyniká červená sedlová střecha, vlevo se stříbrnou kvádrovanou štítovou zdí a dvěma zlatými makovicemi s křížky na koncích."
    },
    {
        obec: "Bochovice",
        kod: 590347,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.320924,
        longitude: 15.896424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Bochovice_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 3 : 1. V žerďové polovině zeleného pruhu vztyčené zkřížené bílé lopaty na žlutých násadách, provázené po stranách a dole po jednom bílém čtyřlistém květu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženou stříbrnou vlnitou patou dvě vztyčené zkřížené stříbrné lopaty na zlatých násadách, provázené po stranách a dole po jednom stříbrném čtyřlistém květu se zlatým středem."
    },
    {
        obec: "Bojanov",
        kod: 571202,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53826,
        latitude: 49.841414,
        longitude: 15.704985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Bojanov_znak.jpg"
    },
    {
        obec: "Bojanovice",
        kod: 593796,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.968259,
        longitude: 15.989159,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Bojanovice_%28Znojmo_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a modrý, v poměru 15 : 2 : 15. Žlutý pruh je podložen kosmou žlutou berlou závitem k žerdi, a přeložen bílým sudariem se žlutým třepením. Hlavici berly na vlající straně provázejí tři bílé lilie (2, 1). Uprostřed modrého pruhu žlutá kotva. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno - modře zlatým vlnitým břevnem dělený štít. Břevno podloženo kosmou zlatou berlou a přeloženo stříbrným sudariem se zlatým třepením. Vlevo nahoře tři (2, 1) stříbrné lilie, dole zlatá kotva."
    },
    {
        obec: "Bojanovice",
        kod: 539104,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25206,
        latitude: 49.854542,
        longitude: 14.352145,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Bojanovice_PZ_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a modrý, v poměru 15 : 2 : 15. Žlutý pruh je podložen kosmou žlutou berlou závitem k žerdi, a přeložen bílým sudariem se žlutým třepením. Hlavici berly na vlající straně provázejí tři bílé lilie (2, 1). Uprostřed modrého pruhu žlutá kotva. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno - modře zlatým vlnitým břevnem dělený štít. Břevno podloženo kosmou zlatou berlou a přeloženo stříbrným sudariem se zlatým třepením. Vlevo nahoře tři (2, 1) stříbrné lilie, dole zlatá kotva."
    },
    {
        obec: "Bojiště",
        kod: 568457,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.667888,
        longitude: 15.286899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Boji%C5%A1t%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 4 : 1. Na bílém pruhu stojí žlutý bojový vůz, z něhož vyrůstají vedle sebe tři bílá kopí na žlutých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném trávníku zlatý bojový vůz, z něhož vyrůstají vedle sebe tři vztyčená stříbrná kopí na zlatých násadách."
    },
    {
        obec: "Bojkovice",
        kod: 592048,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68771,
        latitude: 49.038744,
        longitude: 17.814983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Bojkovice_CoA.png",
        coatOfArmsFlagDescription: "Červený list se žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bolatice",
        kod: 506214,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74723,
        latitude: 49.951819,
        longitude: 18.08369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/CoA_of_Bolatice.svg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole se žlutým mečem hrotem vzhůru a přes něj bílé zlámané kolo posázené noži. Vlající pole je žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma zlatými obilnými klasy vyrůstajícími ze zlatého trojvrší zlatý meč a přes něj stříbrné zlámané kolo posázené noži."
    },
    {
        obec: "Boleboř",
        kod: 563005,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43121,
        latitude: 50.540271,
        longitude: 13.413447,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Bolebo%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a zelený, v poměru 4:5:1. V červeném pruhu vedle sebe tři žlutá jablka se stopkou a dvěma listy, v bílém pruhu dvě odvrácené zelené smrkové větévky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se třemi zlatými jablky, každé na stopce se dvěma listy, dvě odvrácené smrkové větévky nad návrším, vše zelené."
    },
    {
        obec: "Bolkov",
        kod: 540463,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.533675,
        longitude: 13.223973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Bolkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 8 : 1 : 3. V horním žlutém pruhu dva volské rohy převýšené dvojím jelením parožím vedle sebe, vše černé. V dolním žlutém pruhu červený květ hvozdíku kartouzku s bílým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad sníženým modrým vlnitým břevnem dva volské rohy převýšené dvojím jelením parožím vedle sebe, vše černé. Dole červený květ hvozdíku kartouzku se stříbrným středem."
    },
    {
        obec: "Boňkov",
        kod: 573558,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58255,
        latitude: 49.54715,
        longitude: 15.447573
    },
    {
        obec: "Bor",
        kod: 560758,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34802,
        latitude: 49.711706,
        longitude: 12.775276,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Bor_%28Tachov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zubatý bílý s devíti čtvercovými zuby a osmi stejnými mezerami. V červeném pruhu bílá labuť se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají z dlážděné paty dvě natočené věže, každá s cimbuřím, čtyřmi (2, 2) obloukovými okny a čtyřmi (2, 2) obdélníkovými okny nad obloukovou branou. Mezi věžemi hradba s prázdnou branou, vytaženou mříží, nahoře po stranách brány obdélníkové okno. Vše stříbrné, kvádrované, okna a brány černé. Na hradbě mezi věžemi červený štít se stříbrnou labutí se zlatou zbrojí, na štítě korunovaná kolčí přilba s červeno - stříbrnými přikrývadly, klenotem doprava obrácená stříbrná labutí hlava s krkem se zlatou zbrojí."
    },
    {
        obec: "Bor u Skutče",
        kod: 547867,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.822412,
        longitude: 16.127052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Bor_u_Skut%C4%8De_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký jednu třetinu délky listu a tři vodorovné pruhy, zelený, hnědý a zelený, v poměru 5:2:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě borová šiška na větévce přirozené barvy."
    },
    {
        obec: "Borač",
        kod: 595314,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59261,
        latitude: 49.400915,
        longitude: 16.361217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Bora%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vykračujícím koněm. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý vykračující kůň."
    },
    {
        obec: "Bordovice",
        kod: 568431,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74401,
        latitude: 49.546979,
        longitude: 18.152729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Bordovice_znak.PNG",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové části bílého pruhu zelený javorový list, pod ním v červeném pruhu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z červeného návrší se stříbrnou lilií zelený javor s černým kmenem."
    },
    {
        obec: "Boreč",
        kod: 535508,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29426,
        latitude: 50.407453,
        longitude: 14.732498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Bore%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, červený, bílý a modrý, v poměru 3:1:1:3. Přes ně žlutý středový svislý pruh široký jednu čtvrtinu délky listu, v něm pod sebou svěšená hnědá borová šiška a na zeleném stonku s listy vztyčený modrý květ koniklece se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý kůl přeložený volnou stříbrnou bránou s červenou valbovou stříškou, v ní pod sebou svěšená borová šiška přirozené barvy a na zeleném stonku s listy vztyčený modrý květ koniklece se zlatým středem."
    },
    {
        obec: "Borek",
        kod: 544272,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37367,
        latitude: 49.023491,
        longitude: 14.500984,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Borek_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-zeleně šikmo dělený list. V červeném poli bílý dvouocasý lev se žlutou zbrojí, v zeleném poli šikmo tři žluté vztyčené borové šišky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně šikmo děleném štítě nahoře stříbrný dvouocasý lev se zlatou zbrojí, dole tři šikmo postavené vztyčené zlaté borové šišky."
    },
    {
        obec: "Boleradice",
        kod: 584321,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69112,
        latitude: 48.966776,
        longitude: 16.814026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Boleradice_znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílou věží s červenou stanovou střechou a černou branou. Přes věž od žerďového k vlajícímu okraji vodorovný středový pruh široký jednu čtvrtinu šířky listu. Žerďová polovina pruhu je červená, vlající polovina je bílo-černo-bílo-černá. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Borek",
        kod: 568465,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.791915,
        longitude: 15.590083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Borek_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý. V zeleném pruhu na větévce svěšená borová šiška, v modrém pruhu dolů obrácená ostruha s hvězdicovým ostruhovým kolečkem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě vpravo na větévce svěšená borová šiška, vlevo dolů obrácená ostruha s hvězdicovým ostruhovým kolečkem, vše zlaté."
    },
    {
        obec: "Borek",
        kod: 548979,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.438962,
        longitude: 15.654346
    },
    {
        obec: "Bolešiny",
        kod: 555835,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.410002,
        longitude: 13.361712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Bole%C5%A1iny_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed horní části listu zkřížené žluté palcáty, mezi nimi bílý maltézský kříž, uprostřed dolní části listu bílo-žlutě polcené palečné kolo, v modrém pruhu s kuželkovými rameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě dva zkřížené zlaté palcáty provázené nahoře stříbrným maltézským křížem a po stranách dvěma zlatými osmihrotými hvězdami, dole stříbrno-zlatě polcené palečné kolo, vlevo s kuželkovými rameny."
    },
    {
        obec: "Borek",
        kod: 574783,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.127301,
        longitude: 15.856639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Borek_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, černo - žlutě šachovaný (8 x 2) a zelený, v poměru 5 : 2 : 5. V zelených pruzích po jedné svěšené žluté borové šišce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě černo - zlatě šachovaný kůl provázený dvěma svěšenými zlatými borovými šiškami."
    },
    {
        obec: "Borek",
        kod: 534684,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.222684,
        longitude: 14.647521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Sheild_of_Borek.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, žlutý, pět vlnitých střídavě modrých a bílých se čtyřmi vrcholy a pěti prohlubněmi a zvlněný bílý, v poměru 10 : 1 : 1 : 1 : 1 : 1 : 1. Z dolního okraje listu vyrůstá hnědý kmen borovice se zelenou korunou ve žlutém pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlnitě dělený štít, horní pole zlaté, dolní pětkrát modro-stříbrně vlnitě dělené. Ve štítu vyrůstající borovice přirozené barvy."
    },
    {
        obec: "Borkovany",
        kod: 584339,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69175,
        latitude: 49.028264,
        longitude: 16.810211,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Coat_of_arms_of_Borkovany.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. V modrém pruhu žlutý vinný hrozen se dvěma svěšenými listy po stranách. Dole položená modrá půlková kraslice s lemem, růží a dvěma odvrácenými horními polovinami lilií, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě děleném štítě nahoře zlatý vinný hrozen se dvěma svěšenými listy po stranách, dole položená modrá půlková kraslice s lemem, růží a dvěma odvrácenými horními polovinami lilií, vše stříbrné."
    },
    {
        obec: "Borkovice",
        kod: 552097,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.208048,
        longitude: 14.643532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Borkovice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 5, modro - bíle polcený a černo - červeně polcený. V modrém poli žlutá růže s červeným semeníkem, v bílém poli červená růže se žlutým semeníkem. V dolním pruhu zkřížená žlutá borkovací želízka násadami k dolnímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo - červeně polceném štítě, pod modro - stříbrně polcenou hlavou vpravo se zlatou růží s červeným semeníkem a vlevo s červenou růží se zlatým semeníkem, zlatá zkřížená borkovací želízka."
    },
    {
        obec: "Borohrádek",
        kod: 576131,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51724,
        latitude: 50.09787,
        longitude: 16.093365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Borohr%C3%A1dek_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: modrý, červený, bílý, zelený a modrý v poměru 2:1:1:1:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Borotice",
        kod: 593800,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.857391,
        longitude: 16.242711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Borotice_ZN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý zubatý kvádrovaný, v poměru 5:3. Zubatý pruh má osm zubů vysokých jednu osminu šířky listu a sedm stejných mezer. Z něj vyrůstá bílý kůň s červenou zbrojí a uzděním. Nad druhým zubem pruhu meč hrotem k dolnímu okraji, nad sedmým zubem obilný klas se dvěma listy, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá kvádrovaná věž s cimbuřím, z níž vyrůstá stříbrný kůň s červenou zbrojí a uzděním, provázený vpravo postaveným mečem a vlevo obilným klasem, obojí zlaté."
    },
    {
        obec: "Borotice",
        kod: 539970,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26215,
        latitude: 49.735072,
        longitude: 14.279808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Borotice_PB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý se čtyřmi vrcholy a pěti prohlubněmi a zvlněný modrý, v poměru 13:2:5. V červeném pruhu žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě s vlnitou patou pětkrát stříbrno-modře vlnitě dělenou vyrůstající berla provázená vpravo korunou a vlevo lilií, vše zlaté."
    },
    {
        obec: "Borotín",
        kod: 552101,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39135,
        latitude: 49.505132,
        longitude: 14.61307,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Borot%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu přední polovina žlutého koně s červeným uzděním. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Borová",
        kod: 573906,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54701,
        latitude: 50.386091,
        longitude: 16.253287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Borov%C3%A1_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený a žlutý v poměru 3:1. V zeleném pruhu vyrůstá bílá lípa provázená u kmene na žerďové straně borovou šiškou a na vlající straně srdcem, obojí žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na zlatém návrší stříbrná lípa provázená vpravo vztyčenou borovou šiškou, vlevo srdcem, obojí zlaté."
    },
    {
        obec: "Borová",
        kod: 577839,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56982,
        latitude: 49.741782,
        longitude: 16.162337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Borov%C3%A1_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílo-červeně polcený, modrý, bílý a modrý, v poměru 13 : 1 : 1 : 1. V bílém poli zelená borovice s hnědým vykořeněným kmenem, v červeném bílá šesticípá hvězda nad žlutým půlkruhem o průměru třetiny délky listu vyrůstajícím z modrého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou patou. Vpravo vykořeněná borovice přirozené barvy, vlevo vyrůstající zlatý kotouč převýšený stříbrnou hvězdou, v patě stříbrné břevno."
    },
    {
        obec: "Borová Lada",
        kod: 545902,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38492,
        latitude: 48.989939,
        longitude: 13.659961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Borov%C3%A1_Lada.png",
        coatOfArmsFlagDescription: "Zelený list s bílou žerďovou vidlicí jejíž ramena vycházejí z první pětiny horního a dolního okraje listu a široké na vlajícím okraji jednu pětinu šířky listu. V klínu o vrcholu ve třech pětinách délky listu tři spojené žluté borové šišky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná obrácená vidlice, vpravo borová větev se třemi šiškami, vlevo březová větev se třemi listy a dvěma jehnědami, obojí zlaté. Dole kosmo položená stříbrná sekera na zlatém topůrku, šikmo přeložená stříbrným, dolů obráceným loveckým tesákem se zlatým jílcem a záštitou."
    },
    {
        obec: "Borovany",
        kod: 544281,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37312,
        latitude: 48.898704,
        longitude: 14.642367,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý kvádrovaný bílý, v poměru 3:5. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Borovany",
        kod: 598780,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.343099,
        longitude: 14.392567,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Borovany_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, modrý a zelený, v poměru 1 : 2 : 1. Do modrého pruhu z dolního okraje listu vyrůstá borovice, po stranách kmene vyrůstají dvě věže, každá s valbovou střechou a dvěma obloukovými černými okny nad sebou, horní sdružená, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší borovice a po stranách kmene dvě věže, každá s valbovou střechou a dvěma obloukovými černými okny nad sebou, horní sdružená, vše zlaté."
    },
    {
        obec: "Borovnice",
        kod: 535681,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.912174,
        longitude: 14.519089
    },
    {
        obec: "Borotín",
        kod: 581356,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67937,
        latitude: 49.581447,
        longitude: 16.671585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Borotin_BK_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy červený se žlutým tlapatým křížem, bílý s modrou švestkou na zeleném stonku se dvěma listy a modrý s bílou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrno-modře dělený štít, nahoře zlatý tlapatý kříž, uprostřed modrá švestka na zeleném stonku se dvěma listy, dole stříbrná hvězda."
    },
    {
        obec: "Borovná",
        kod: 587184,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.164698,
        longitude: 15.394712
    },
    {
        obec: "Borovnice",
        kod: 595322,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59242,
        latitude: 49.667328,
        longitude: 16.202654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Borovnice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, zelený, žlutý a modrý zvlněný, v poměru 2 : 2 : 1 : 1. V zeleném pruhu bílá rozevřená kniha. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou s černou zubří hlavou s červeným jazykem a zlatou houžví stříbrná otevřená kniha nad zlatým trojvrším vynikajícím z modré vlnité paty."
    },
    {
        obec: "Borovnice",
        kod: 576140,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.063008,
        longitude: 16.245418,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Borovnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 3:2:3. Na horním okraji listu tři bílé vlčí zuby, v bílém pruhu čtyři červená srdce vedle sebe. V modrém pruhu vodorovně bílá vrš. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře stříbrným břevnem dělený štít, v něm čtyři červená srdce, nahoře tři stříbrné vlčí zuby vynikající z horního okraje štítu, dole položená stříbrná vrš."
    },
    {
        obec: "Borovnice",
        kod: 579092,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54477,
        latitude: 50.509256,
        longitude: 15.617294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Borovnice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, bílý a modrý, v poměru 4 : 7 : 4. V bílém pruhu vykořeněná zelená borovice s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vykořeněná borovice přirozené barvy provázená podél kmenu černou kohoutí hlavou s červenou zbrojí a modrou lví hlavou se stříbrnou zbrojí a červeným jazykem, obě přivrácené."
    },
    {
        obec: "Borovnice",
        kod: 532096,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.648835,
        longitude: 15.018438
    },
    {
        obec: "Borovnička",
        kod: 548821,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54475,
        latitude: 50.500964,
        longitude: 15.667313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Borovni%C4%8Dka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 2 : 1. Ze zeleného pruhu vyrůstá zelená borovice s hnědým kmenem, v zeleném pruhu bílé zkřížené ostrve. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě borovice přirozené barvy na zeleném trávníku se stříbrnými zkříženými ostrvemi."
    },
    {
        obec: "Borovník",
        kod: 595331,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.355656,
        longitude: 16.229456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Borovn%C3%ADk_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 1 : 1 : 2 : 1 : 1. V modrém pruhu pět bílých šesticípých hvězd (1, 3, 1). Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý vydutý hrot s pěti (1, 3, 1) stříbrnými hvězdami, provázený dvěma vztyčenými červenými borovými šiškami."
    },
    {
        obec: "Borovno",
        kod: 566705,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33561,
        latitude: 49.622087,
        longitude: 13.690481,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Borovno_CoA.png"
    },
    {
        obec: "Boršice",
        kod: 592064,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68709,
        latitude: 49.062643,
        longitude: 17.350953,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Bor%C5%A1ice%2C_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se žlutým vinným listem v modrém kruhovém poli o průměru 2/3 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrné dolů obrácené odvrácené vinohradnické nože provázené po stranách dvěma zlatými vinnými hrozny a dole zlatým vinným listem."
    },
    {
        obec: "Borovy",
        kod: 542156,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.525208,
        longitude: 13.302442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Borovy_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými čtyřiadvacetinu délky listu. Uprostřed svěšená borová šiška provázená mezi rameny kříže v žerďové a vlající části liliemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě svěšená zlatá borová šiška provázená nahoře dvěma zlatými liliemi a dole zkříženými stříbrnými hornickými motykami na zlatých násadách."
    },
    {
        obec: "Boršice u Blatnice",
        kod: 592056,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68763,
        latitude: 48.934962,
        longitude: 17.571104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Bor%C5%A1ice_u_Blatnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 5:8:5. Ve žlutém pruhu červená merka hrotem k hornímu okraji s půlměsícem cípy dolů a vodorovně přeložená břevnem se dvěma odvrácenými půloblouky přes okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo zlatá věž s kvádrovaným soklem, jedním oknem a cimbuřím, vlevo červená merka tvořená klesajícím půlměsícem se vztyčenou střelou přeloženou zúženým břevnem se dvěma odvrácenými půloblouky přes okraje."
    },
    {
        obec: "Boršov",
        kod: 586927,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.404043,
        longitude: 15.434297
    },
    {
        obec: "Boršov nad Vltavou",
        kod: 544299,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37382,
        latitude: 48.921882,
        longitude: 14.434092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Bor%C5%A1ov_nad_Vltavou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zvlněný modrý. V horním rohu kosmá červená střela. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře vlnitě děleném štítě nahoře kosmá červená střela, dole stříbrná dolů obrácená svatojakubská mušle."
    },
    {
        obec: "Borušov",
        kod: 572632,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.780652,
        longitude: 16.740556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Borusov_znak.png",
        coatOfArmsFlagDescription: "Zelený list s bílým koněm se žlutou zbrojí, hřívou a ocasem a červeným jazykem, vzpínajícím se nad vztyčeným kopáčem hrotem k vlajícímu okraji, šikmo přeloženým mlátkem a kosmo klíčem zuby dolů a k hornímu cípu, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůň se zlatou zbrojí, hřívou a ocasem a červeným jazykem, vzpínající se nad vztyčeným doleva obráceným kopáčem, šikmo přeloženým mlátkem a kosmo postaveným gotickým klíčem, vše zlaté."
    },
    {
        obec: "Bory",
        kod: 595641,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59461,
        latitude: 49.425865,
        longitude: 16.026282,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Bory_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, zelený a žlutý v poměru 3:2:3. Ve žlutých polích po jedné červené borové šišce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněná borovice provázená po obou stranách šiškou, vše přirozených barev."
    },
    {
        obec: "Bořanovice",
        kod: 538086,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25065,
        latitude: 50.178336,
        longitude: 14.478857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Bo%C5%99anovice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým klínem lemovaným černou krokví s vrcholem na vlajícím okraji. Ramena krokve vycházejí z první a poslední osminy žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě pod modrou hlavou se třemi hvězdami hlava jelena s krkem šikmo prostřeleným šípem."
    },
    {
        obec: "Bořenovice",
        kod: 549690,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.361618,
        longitude: 17.564472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Borenovice_znak.svg",
        coatOfArmsFlagDescription: "Zelený list se dvěma žlutými šikmými pruhy vycházejícími ze sedmé devítiny žerďového okraje a třetí devítiny dolního okraje a dosahujícími do sedmé devítiny horního a třetí devítiny vlajícího okraje listu. V horním rohu a dolním cípu po jedné osmicípé hvězdě, v dolním rohu a horním cípu po jednom vztyčeném lipovém listu, uprostřed lilie, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vykořeněná lípa, provázená podél kmene vpravo osmihrotou hvězdou a vlevo lilií, vše zlaté."
    },
    {
        obec: "Bořetice",
        kod: 584347,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69108,
        latitude: 48.913124,
        longitude: 16.853168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Znak_obce_Bo%C5%99etice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový zelený a vlající červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít. V pravé polovině polní brány, v levé postavená radlice, obojí zlaté."
    },
    {
        obec: "Bořetice",
        kod: 561240,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.496166,
        longitude: 15.11005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Bo%C5%99etice_%28Pelh%C5%99imov_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a modré pole. V žerďové a střední části listu zkřížené bílé meče se žlutými jílci, hroty k hornímu okraji listu, provázené nahoře bílou křížovou kypřicí, po stranách bílým květem lnu se žlutým středem a dole bílou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zkřížené vztyčené stříbrné meče se zlatými jílci provázené nahoře stříbrnou křížovou kypřicí, po stranách stříbrným květem lnu se zlatým středem a dole stříbrnou lilií."
    },
    {
        obec: "Bořetín",
        kod: 562742,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.189321,
        longitude: 15.211941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Bo%C5%99et%C3%ADn_%28JH%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílými andělskými křídly. Nad nimi žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky nad přivrácenými stříbrnými andělskými křídly."
    },
    {
        obec: "Bořetín",
        kod: 509418,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.310234,
        longitude: 14.947722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Bo%C5%99et%C3%ADn_PE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 1 : 6 : 1. Ve žlutém pruhu dva vztyčení černí přivrácení medvědi s modrou zbrojí a jazyky, držící svisle červený list gonfanonu se třemi cípy a třemi závěsnými kroužky, v něm bílá vykořeněná lekna, mezi nimi žlutý křížek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře děleném štítě nahoře dva vztyčení přivrácení černí medvědi s modrou zbrojí a jazyky, držící svěšený červený list gonfanonu se třemi cípy a třemi závěsnými kroužky, v něm stříbrná vykořeněná lekna, mezi nimi zlatý křížek. Dole dvě zlatá břevna."
    },
    {
        obec: "Bořice",
        kod: 571229,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.97695,
        longitude: 15.925435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Bo%C5%99ice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, modrý, bílý, žlutý, černý, žlutý, bílý a modrý, v poměru 4:4:4:5:4:4:4. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou hlavou se třemi stříbrnými kůly doleva obrácený černý kolčí helm s klenotem sedmi modrých pštrosích per."
    },
    {
        obec: "Bořislav",
        kod: 567469,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.579462,
        longitude: 13.929313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Borislav_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, modrý a žlutý. V červeném pruhu na bílém vztyčeném meči se žlutým jílcem navlečeny koruna a nad ní prsten s modrým kamenem, obojí žluté. Ve střední části listu v modrém pruhu tři (2,1) bílé šesticípé hvězdy, pod nimi ve žlutém pruhu tři (1, 2) modrá čtvercová pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli na stříbrném vztyčeném meči se zlatým jílcem navlečeny koruna a nad ní prsten s modrým kamenem, obojí zlaté. V levém modro - zlatě děleném poli nahoře tři (2, 1) stříbrné hvězdy, dole tři (1, 2) modré kostky."
    },
    {
        obec: "Bořitov",
        kod: 581364,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.425135,
        longitude: 16.591291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Bo%C5%99itov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a černých v poměru 5:2:2:2:5, přeložené uprostřed svislým modrým pruhem širokým polovinu délky listu. V modrém pruhu žlutý korunovaný drak s červenou zbrojí shora kosmo probodnutý bílým kopím s červeným hrotem a s bílým praporkem - plamenem s červeným křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze stříbrné paty s dvěma černými břevny vyrůstají dvě stříbrné věže, každá s prázdnou branou, dvěma prázdnými okny a červenou stanovou střechou se zlatým křížkem, mezi věžemi zlatý korunovaný drak s červenou zbrojí shora kosmo probodnutý stříbrným kopím s červeným hrotem a se stříbrným praporkem - plamenem s červeným heroldským křížem."
    },
    {
        obec: "Boseň",
        kod: 535516,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.504204,
        longitude: 15.022906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Bose%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. V žerďové části bílého pole modrá věž s cimbuřím a prázdnou gotickou branou, na věži žluté slunce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve stříbrném sníženém hrotu provázeném čtyřmi (2,2) zlatými slunci s tváří modrá věž s cimbuřím a s prázdnou gotickou branou."
    },
    {
        obec: "Boskovice",
        kod: 581372,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.487612,
        longitude: 16.660076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Boskovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list s bílým hřebenem o sedmi zubech, vycházejícím z první desetiny horního a dolního okraje listu. Prostřední zub dosahuje do dvou třetin délky listu, vzniklý žerďový klín dosahuje vrcholem do první třetiny délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Boskovštejn",
        kod: 593818,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 48.982797,
        longitude: 15.929654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Boskov%C5%A1tejn%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 7 : 2 : 1. V horním zeleném pruhu bílá patrová budova zámku s valbovou střechou se dvěma makovicemi a s dvěma nárožními věžicemi se stanovými střechami s makovicemi, střechy červené, makovice žluté. Zámek má oboustranné schodiště, v přízemí dveře a čtyři okna, v patře pět oken, věžice po okně v patře, dveře a okna černá. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným vlnitým břevnem stříbrná patrová budova zámku s valbovou střechou se dvěma makovicemi a s dvěma nárožními věžicemi se stanovými střechami s makovicemi, střechy červené, makovice zlaté. Zámek má oboustranné schodiště, v přízemí dveře a čtyři okna, v patře pět oken, věžice po okně v patře, dveře a okna černá."
    },
    {
        obec: "Bošice",
        kod: 550124,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.089388,
        longitude: 13.840431,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Bo%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed bílá hlava koně s krkem se žlutým uzděním mezi dvěma dvojicemi žlutých obilných klasů, každý s listem ke středu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná hlava koně s krkem se zlatým uzděním převýšená zlatou korunou, mezi dvěma dvojicemi zlatých obilných klasů, každý s listem ke středu. Ve stříbrné, čtyřmi oblouky oddělené patě dva vztyčené odkloněné zelené lipové listy."
    },
    {
        obec: "Bošilec",
        kod: 535401,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37365,
        latitude: 49.149418,
        longitude: 14.646319
    },
    {
        obec: "Bošín",
        kod: 548006,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.034233,
        longitude: 16.203069
    },
    {
        obec: "Bošovice",
        kod: 592919,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68354,
        latitude: 49.053609,
        longitude: 16.836868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Bosovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený. V žerďovém pruhu žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vztyčená radlice, šikmo přeložená stříbrným kosířem se zlatou rukojetí, pod ním zlatý vinný hrozen."
    },
    {
        obec: "Boudy",
        kod: 562122,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.448057,
        longitude: 14.035591
    },
    {
        obec: "Bousín",
        kod: 589306,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79861,
        latitude: 49.455207,
        longitude: 16.889847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Bousin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. V žerďové polovině modrého pruhu půlměsíc s tváří, cípy k vlajícímu okraji a se dvěma šesticípými hvězdami nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě doprava zatočený vztyčený zlatý ještěr s červeným jazykem, provázený vpravo ubývajícím půlměsícem s tváří a dvěma hvězdami, vše stříbrné."
    },
    {
        obec: "Bouzov",
        kod: 500861,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78325,
        latitude: 49.704361,
        longitude: 16.892988,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Bouzov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zubatý bílý, modrý s bílou rybou a zubatý bílý, v poměru 3:12:1. Horní zubatý pruh má pět čtvercových a dva poloviční zuby a šest čtvercových mezer, dolní pruh má šest čtvercových zubů, pět stejných a dvě poloviční mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vzhůru plovoucí stříbrná ryba."
    },
    {
        obec: "Bozkov",
        kod: 577006,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51213,
        latitude: 50.640171,
        longitude: 15.337206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Bozkov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeným šikmým pruhem širokým jednu pětinu šířky listu, lemovaným po obou stranách žlutým vlnkovitým proužkem. V žerďové části žlutá lilie, ve vlající části utržená žlutá hlava gryfa s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červená krokev se zlatým trnitým lemem, provázená nahoře zlatými liliemi a dole zlatou utrženou hlavou gryfa s červenou zbrojí."
    },
    {
        obec: "Božanov",
        kod: 573914,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54974,
        latitude: 50.526878,
        longitude: 16.365391,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Znak_obce_Bo%C5%BEanov.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý. Ve střední části listu červená růže se žlutým semeníkem a zelenými kališními lístky, převýšená žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném trojvrší vyrůstajícím z modré paty červená růže se zlatým semeníkem a zelenými kališními lístky, z níž vytékají tři stříbrné prameny. Na trojvrší zlatá koruna."
    },
    {
        obec: "Bousov",
        kod: 571237,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.901849,
        longitude: 15.535529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Bousov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a černo-červeno dělený. V modrém dva zkřížené žluté obilné klasy, každý s listem, v děleném pruhu bílý kapr hlavou k dolnímu a hřbetem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém modrém poli dva zkřížené zlaté obilné klasy, každý s listem. V levém černo-červeně děleném poli doleva dolů obrácený stříbrný kapr."
    },
    {
        obec: "Božejov",
        kod: 547581,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39461,
        latitude: 49.362207,
        longitude: 15.155899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Bo%C5%BEejov_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list, v něm kosmo bílý meč se žlutým jílcem hrotem k hornímu rohu, šikmo překřížený červenými vidlemi-podávkami hroty k hornímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě kosmo stříbrný meč se zlatým jílcem šikmo přeložený vztyčenými červenými vidlemi-podávkami."
    },
    {
        obec: "Boží Dar",
        kod: 506486,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.409844,
        longitude: 12.924571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Bo%C5%BE%C3%AD_Dar_znak.png"
    },
    {
        obec: "Božice",
        kod: 593826,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67164,
        latitude: 48.836967,
        longitude: 16.288856,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu klíč zuby nahoru a k vlajícímu okraji a meč hrotem dolů, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená doleva obrácená radlice a postavené krojidlo, obojí zlaté. V radlici vztyčený gotický klíč zuby doleva, v krojidle postavený meč, obojí červené."
    },
    {
        obec: "Božičany",
        kod: 555045,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36225,
        latitude: 50.258843,
        longitude: 12.769752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Bozicany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo a šikmo čtvrcený list, uprostřed modrý kosočtverec s vrcholy na okrajích listu. V něm mezi dvěma do oblouku dole překříženými žlutými obilnými klasy žluto-červeně polcený plamenný meč hrotem nahoru se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní pole červeno-stříbrně polceno, dolní modré. Uprostřed vztyčený zlato-červeně polcený plamenný meč se zlatým jílcem, provázený nahoře vpravo zlatou konvicí, vlevo modrými hornickými kladívky s černými topůrky a dole do oblouku dvěma zlatými, dole překříženými obilnými klasy."
    },
    {
        obec: "Božetice",
        kod: 549291,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.45123,
        longitude: 14.444001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Bo%C5%BEetice_CoA.jpg",
        coatOfArmsFlagDescription: "Modro - bíle kosmo dělený list. V modrém poli bílá lilie, v bílém červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyniká ze stříbrného oblaku v levém horním rohu zlatě oděné rameno držící v ruce přirozené barvy stříbrnou liliovou hůlku. V oblaku červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Bradáčov",
        kod: 552127,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.490902,
        longitude: 14.871519
    },
    {
        obec: "Bradlec",
        kod: 570788,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29306,
        latitude: 50.451452,
        longitude: 14.910293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Bradlec_MB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva červené rovnoramenné žerďové trojúhelníky, bílá krokev, modré pole a svislý bílý pruh, na horním okraji v poměru 5 : 2 : 6 : 2. V modrém poli kráčející žlutá lvice s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře zvýšenou stříbrnou krokví dělený štít. Dole nad stříbrnou vlnitou patou kráčející zlatá lvice s červeným jazykem."
    },
    {
        obec: "Brada-Rybníček",
        kod: 549100,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.465297,
        longitude: 15.337606,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Brada-Rybn%C3%AD%C4%8Dek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a vlnkovitý zelený, v poměru 7 : 3. Ze zeleného pruhu vyrůstá korunovaný černý lev držící červený nůž ostřím k žerďovému okraji listu. V zeleném pruhu žlutý vinný hrozen. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě ze zelené vlnkovité paty se zlatým vinným hroznem vyrůstá korunovaný černý lev držící červený nůž."
    },
    {
        obec: "Bradlecká Lhota",
        kod: 577014,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 50713,
        latitude: 50.488672,
        longitude: 15.396332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Bradleck%C3%A1_Lhota_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a modrý, v poměru 3 : 10 : 3. Do žlutého pruhu vyrůstají tři zelené listnaté stromy s černými kmeny, prostřední s kmenem obtočeným modrým šálem, v modrém pruhu žlutá kypřice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě mezi zelenou cimbuřovou hlavou a modrou vlnitou patou se zlatou kypřicí tři vyrůstající zelené listnaté stromy s černými kmeny, prostřední s kmenem obtočeným modrým šálem."
    },
    {
        obec: "Brambory",
        kod: 530832,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.97825,
        longitude: 15.470316
    },
    {
        obec: "Brandýs nad Labem - Stará Boleslav",
        kod: 538094,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25001,
        latitude: 50.186426,
        longitude: 14.659344
    },
    {
        obec: "Braňany",
        kod: 567060,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43522,
        latitude: 50.543055,
        longitude: 13.700283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Znak_obce_Bra%C5%88any.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým středovým vydutým křížem s rameny širokými na okraji listu čtvrtinu šířky listu. Uprostřed listu červená kvádrovaná věž s cimbuřím, dvěma černými okny vedle sebe a otevřenou branou se žlutými vraty na černých závěsech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se čtyřmi zelenými kruhovými kouty červená kvádrovaná věž s cimbuřím, dvěma černými okny vedle sebe a otevřenou branou se zlatými vraty na černých závěsech."
    },
    {
        obec: "Brandov",
        kod: 567078,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43547,
        latitude: 50.63211,
        longitude: 13.390831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Znak_Brandova.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1:4:1. Ve žlutém pruhu červený plamenný hořící meč hrotem nahoru, dole provázený dvěma odkloněnými zelenými smrkovými větvičkami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným návrším vztyčený červený plamenný hořící meč provázený dvěma odkloněnými zelenými smrkovými větvičkami."
    },
    {
        obec: "Brandýsek",
        kod: 532118,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27341,
        latitude: 50.18933,
        longitude: 14.162098,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Brandysek_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený vlnitým šikmým bílým pruhem širokým jednu dvanáctinu délky listu o jednom vrcholu a jedné prohlubni, na žerďové modré a vlající zelené pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zelený štít šikmo dělený stříbrným vlnitým břevnem. Nahoře kosmo položený mlátek šikmo přeložený želízkem, dole olšová větévka se třemi listy, vše stříbrné."
    },
    {
        obec: "Branice",
        kod: 549304,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.402569,
        longitude: 14.33964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Znak_obce_Branice.jpg",
        coatOfArmsFlagDescription: "Modrý list s prázdnou zastřešenou bránou, v ní zkřížené odvrácené berly, vše žluté. Křížení berlí je na dolním okraji brány. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě prázdná zastřešená brána, v ní zkřížené odvrácené berly závity ven, vše zlaté."
    },
    {
        obec: "Braníškov",
        kod: 582875,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66471,
        latitude: 49.294469,
        longitude: 16.345762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Bran%C3%AD%C5%A1kov_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou lípou vyrůstající z červeno-bíle polceného obráceného patriaršího kříže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá z obráceného, červeno-stříbrně polceného patriaršího kříže zlatá lípa."
    },
    {
        obec: "Branišov",
        kod: 551490,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.978807,
        longitude: 14.395791
    },
    {
        obec: "Brandýs nad Orlicí",
        kod: 579947,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56112,
        latitude: 50.002042,
        longitude: 16.285384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Brand%C3%BDs_nad_Orlic%C3%AD.svg",
        coatOfArmsFlagDescription: "Modrý list se zkříženými žlutými hráběmi z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Branišovice",
        kod: 593834,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67177,
        latitude: 48.962911,
        longitude: 16.431951,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Brani%C5%A1ovice_-_obecn%C3%AD_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:1:6:1:1. V modrém pruhu bílá volavka se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý hrot se stříbrnou volavkou se zlatou zbrojí, provázený dvěma červenými vinnými hrozny, převýšenými modrými položenými a odvrácenými vinařskými noži - kosíři se zlatými rukojeťmi."
    },
    {
        obec: "Branka u Opavy",
        kod: 554197,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74741,
        latitude: 49.888057,
        longitude: 17.882754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Blason_Branka_u_opavy.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým pruhem na vlajícím okraji širokým jednu čtvrtinu délky listu. V zeleném poli bílá radlice hrotem nahoru a ostřím k žerdi, bílý pruh má pět čtvercových zubů a čtyři mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě prázdná brána s cimbuřím, v ní vztyčená radlice, obojí stříbrné."
    },
    {
        obec: "Brankovice",
        kod: 592927,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.156081,
        longitude: 17.134911,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Brankovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří bílá žerďová část s polovinou černé orlice s červeným jazykem přiléhající k modrému středovému svislému pruhu širokému jednu čtvrtinu délky listu se dvěma žlutými liliemi pod sebou a bílá vlající část, tvořená sedmi kosmými střídavě bílými a červenými pruhy v poměru 2:1:1:1:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou modrý kůl se zlatou berlou závitem doprava, provázenou v hlavě štítu zlatými liliemi. V pravém poli vyniká půl černé orlice s červeným jazykem, v levém tři červená kosmá břevna."
    },
    {
        obec: "Branky",
        kod: 541648,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75645,
        latitude: 49.461056,
        longitude: 17.89306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/CZ_Branky_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. Uprostřed kvádrovaná věž s prázdnou branou, dvěma černými okny a cimbuřím se třemi zuby. Horní polovina věže je modrá na polovině bílého kopřivového listu přiléhajícího k modrému pruhu. Dolní polovina věže je bílá, provázená třemi liliemi, z nichž dvě bílé jsou po jejích stranách a třetí, žlutá, je pod ní a sahá do prázdné brány. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě modro-stříbrně dělená kvádrová věž s cumbuřím, dvěma černými okny a prázdnou branou. V horním poli věž položena na polovinu stříbrného kopřivového listu, dole provázena třemi liliemi, stříbrnými po stranách a zlatou dole."
    },
    {
        obec: "Branná",
        kod: 526169,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78825,
        latitude: 50.15336,
        longitude: 17.011954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Brann%C3%A1_znak.png",
        coatOfArmsFlagDescription: "Na červeném listě tři žluté lilie (2,1) a černý dvouocasý lev se žlutou zbrojí, bílým okem a zuby, vyrůstající z bílého vodorovného pruhu na dolním okraji. Bílý pruh je široký 1/5 šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Branov",
        kod: 541672,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.012981,
        longitude: 13.843021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coats_of_arms_Branov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý. Ve žlutém pruhu tři černé psí hlavy s červenými jazyky, bílou zbrojí a šedými obojky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno se třemi černými psími hlavami, se stříbrnou zbrojí a obojky a červenými jazyky."
    },
    {
        obec: "Bransouze",
        kod: 590363,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.302776,
        longitude: 15.751924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Coat_of_arms_of_Bransouze%2C_T%C5%99eb%C3%AD%C4%8D_District.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma zkříženými bílými sekerami-bradaticemi přeloženými postaveným žlutým veslem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě zkřížené stříbrné sekery-bradatice přeložené postaveným zlatým veslem."
    },
    {
        obec: "Brantice",
        kod: 597210,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79393,
        latitude: 50.063603,
        longitude: 17.629214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Brantice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým žlutým klínem s vrcholem ve středu listu a dvěma bílými krokvemi. První vychází z druhé osminy a druhá ze čtvrté osminy horního okraje listu. Vrchol druhé krokve je na vlajícím okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vpravo zlatá radlice, vlevo stříbrný drak se zlatou brojí, dole stříbrné vozové kolo."
    },
    {
        obec: "Branžež",
        kod: 571946,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29402,
        latitude: 50.507685,
        longitude: 15.058005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Bran%C5%BEe%C5%BE_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-zeleně děleným žerďovým pruhem širokým čtvrtinu délky listu. V modrém poli žlutý dvouocasý lev s červenou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zelenou patou vpravo vynikají dvě stříbrné skály za sebou, vrcholící zlatou kvádrovanou věží se třemi stínkami cimbuří, vlevo zlatý dvouocasý lev s červenou zbrojí a jazykem."
    },
    {
        obec: "Braškov",
        kod: 532126,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.102133,
        longitude: 14.100859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Bra%C5%A1kov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: bílý, modrý a bílý v poměru 1:5:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným lemem z levého okraje zlatý pařát dravého ptáka s hnědým peřím, držící stříbrnou ostruhu kolečkem dolů."
    },
    {
        obec: "Bratčice",
        kod: 582883,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66467,
        latitude: 49.065247,
        longitude: 16.523184,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Bratcice_znak.png",
        coatOfArmsFlagDescription: "Kosmo dělený list, v modré žerďové části žlutý postavený vinařský nůž (kosíř) hrotem k vlajícímu okraji a v bílé vlající části modrý vinný hrozen se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře kosmo dělený štít, nahoře modrý vinný hrozen se dvěma zelenými listy, dole vztyčený, doleva obrácený zlatý vinařský nůž (kosíř)."
    },
    {
        obec: "Bratčice",
        kod: 531286,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.855317,
        longitude: 15.430482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Bratcice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy bílo-žlutě polcený, žluto-modře polcený a modro-bíle polcený, v poměru 3:14:3. V bílých pruzích po třech hranostajových ocáscích, ve středním žlutém poli půl modré orlice s červenou zbrojí přiléhající k modrému poli se žlutým okřídleným kolem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít se stříbrnou hlavou s pěti hranostajovými ocásky a v patě se stříbrným trojvrším se snítkou tisu se dvěma plody přirozené barvy. V pravém poli půl modré orlice s červenou zbrojí vynikající z poltící linie, v levém poli zlaté okřídlené kolo s jedním křídlem."
    },
    {
        obec: "Bratkovice",
        kod: 539988,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.740519,
        longitude: 13.999043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Bratkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený smrkový se šestí vrcholy, bílý vlnitý a modrý zvlněný, v poměru 13 : 3 : 2 : 14. Vrcholy smrkového pruhu dosahují do tří dvaatřicetin šířky listu. Vlnitý pruh má pět vrcholů a čtyři prohlubně. V modrém pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zúženým stříbrným vlnitým břevnem dělený štít. Horní pole stříbrno-zeleně děleno smrkovým řezem, v dolním modrém poli zlaté mlýnské kolo."
    },
    {
        obec: "Bratronice",
        kod: 537063,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.365361,
        longitude: 13.842454,
        coatOfArmsFlagDescription: "Kosmo dělený list. V žerďovém poli čtyři kosmé pruhy, střídavě žluté a červené, ve vlajícím modrém poli knížecí čepice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V kosmo děleném štítě nahoře v modrém poli knížecí čepice, dolní pole třikrát kosmo zlato-červeno dělené."
    },
    {
        obec: "Bratronice",
        kod: 532142,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27363,
        latitude: 50.067851,
        longitude: 14.014265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Bratronice_KL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-modře čtvrcený list. V horním žerďovém poli lipový list, v dolním vlajícím poli dubový list, oba vztyčené zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý zúžený vlnitý kůl, podložený dvěma vztyčenými a dole do oblouku spojenými černými větvemi, každá se čtyřmi odvrácenými větvičkami zakončenými zelenými listy, vpravo lipovými, vlevo dubovými."
    },
    {
        obec: "Bratrušov",
        kod: 569437,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78701,
        latitude: 50.009487,
        longitude: 16.949028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Znak_Bratru%C5%A1ova.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým čápem s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném dvojvrší stříbrný čáp s červenou zbrojí, držící v zobáku zelenou chmelovou větvičku s jedním listem a dvěma šišticemi."
    },
    {
        obec: "Bratřejov",
        kod: 585106,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.222558,
        longitude: 17.913427,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/CoA_of_Brat%C5%99ejov.svg",
        coatOfArmsFlagDescription: "Zelený list s bílou letící holubicí s červenou zbrojí a cimbuřovým obojkem, držící v zobáku na žlutém stonku tři modré pětilisté květy se žlutými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná letící holubice s červenou zbrojí a cimbuřovým obojkem, držící v zobáku na zlatém stonku tři modré pětilisté květy se zlatými středy."
    },
    {
        obec: "Bratřice",
        kod: 547603,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.516554,
        longitude: 15.001328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Brat%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "Modro - žlutě čtvrcený list. V horním žerďovém poli zkřížené žluté berly závity ven, s vlajícími bílými sudarii se žlutým třepením, mezi hlavicemi žlutá lilie. V dolním vlajícím poli žluté slunce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zkřížené zlaté berly závity ven, s vlajícími stříbrnými sudarii se zlatým třepením, mezi hlavicemi lilie, dole slunce s tváří, obojí zlaté."
    },
    {
        obec: "Bratříkovice",
        kod: 553107,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74752,
        latitude: 49.932674,
        longitude: 17.687641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Coat_of_arms_of_Brat%C5%99%C3%ADkovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený, uprostřed kosmo zelený jetelový čtyřlist přeložený žlutou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě kosmo zelený jetelový čtyřlist přeložený zlatou lilií."
    },
    {
        obec: "Bratřínov",
        kod: 571199,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25205,
        latitude: 49.842681,
        longitude: 14.340242,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Bratrinov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, zelený, žlutý a zelený, v poměru 1 : 1 : 4 : 1 : 1. V prostředním zeleném pruhu žlutý zvon se závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrným vlnitým břevnem zvon se závěsem provázený čtyřmi (2, 2) vyrůstajícími palisádovými kůly, krajní vyšší, vše zlaté."
    },
    {
        obec: "Bravantice",
        kod: 556858,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74281,
        latitude: 49.757218,
        longitude: 18.082863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Bravantice_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě žluté a červené, v poměru 5:1:1:1. V horním žlutém pruhu černý kráčející medvěd. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod černým kráčejícím medvědem položená červená radlice se zlatou lilií."
    },
    {
        obec: "Brdy (vojenský újezd)",
        kod: 539996,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.789268,
        longitude: 13.903243
    },
    {
        obec: "Brázdim",
        kod: 538108,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.183633,
        longitude: 14.58829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Coats_of_arms_Br%C3%A1zdim.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, bílý a žlutý. V červeném pruhu polovina bílého korunovaného dvouocasého lva se žlutou zbrojí, držícího v levé tlapě větev ořešáku s listy a plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze zlatého návrší stříbrný korunovaný dvouocasý lev se zlatou zbrojí držící v levé tlapě větev ořešáku s listy a plody přirozené barvy."
    },
    {
        obec: "Bražec",
        kod: 500101,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36471,
        latitude: 50.172681,
        longitude: 13.046575,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Bra%C5%BEec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, zelený, bílý a zelený, v poměru 9 : 1 : 1 : 1. Do žlutého pruhu vyrůstá šípková růže s květem, dvěma listy a dvěma plody (1, 2, 2). Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá ze zeleného trojvrší se stříbrným vlnitým břevnem šípková růže s květem, dvěma listy a dvěma plody (1, 2, 2), vše přirozené barvy."
    },
    {
        obec: "Brloh",
        kod: 545431,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38206,
        latitude: 48.92998,
        longitude: 14.218667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Brloh_CK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým klínem vycházejícím z horního okraje listu s vrcholem na dolním okraji listu. V klínu hnědý vzpřímený medvěd s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný klín, v něm vzpřímený medvěd přirozené barvy s červenou zbrojí, obklopený pěti (3,2) červenými růžemi se zlatými semeníky."
    },
    {
        obec: "Brloh",
        kod: 574791,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.000688,
        longitude: 15.557385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Znak_obce_Brloh_u_Pardubic.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1:2:1. V červeném pruhu bílý železný klobouk se žlutým šňůrovým podbradníkem a třemi vyrůstajícími žlutými leknínovými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad dolů obráceným zlatým korbelem stříbrný železný klobouk se zlatým šňůrovým podbradníkem a třemi vyrůstajícími zlatými leknínovými listy."
    },
    {
        obec: "Brňany",
        kod: 564613,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.481073,
        longitude: 14.141967,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Brnany_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V bílém polovina červeného koně ve skoku se žlutou zbrojí, v modrém lilie provázená dole do oblouku pěti šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře sníženě vlnitě děleném štítě nahoře polovina červeného koně ve skoku se zlatou zbrojí, dole lilie provázená dole do oblouku pěti hvězdami, vše zlaté."
    },
    {
        obec: "Brněnec",
        kod: 577863,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.627449,
        longitude: 16.522125,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Brn%C4%9Bnec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě bílé a červené, v poměru 3:5:5:5. Na druhý až čtvrtý pruh položen modrý kosočtverec s vrcholy na okrajích listu a na středu vlajícího okraje bílého žerďového pruhu. V kosočtverci vztyčená žlutá dubová větev s jedním žaludem mezi dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně třikrát děleném štítě s užším horním břevnem modrý hrot se vztyčenou zlatou dubovou větví se dvěma listy a jedním žaludem."
    },
    {
        obec: "Brníčko",
        kod: 530727,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78975,
        latitude: 49.894982,
        longitude: 16.968308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Znak_obce_Brn%C3%AD%C4%8Dko_u_%C5%A0umperka.gif",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový zubatý pruh, široký třetinu délky listu, a modré pole. Zubatý pruh má dva celé a dva poloviční obdélníkové zuby vysoké jednu desetinu šířky listu a tři stejné mezery. V modrém poli kosmo bílý kapr se žlutým obojkem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou kvádrovanou hradbou s cimbuřím kosmý stříbrný kapr se zlatým obojkem."
    },
    {
        obec: "Brnířov",
        kod: 566136,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.382698,
        longitude: 13.04969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Brn%C3%AD%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 9 : 7. V zeleném pruhu kyrys mezi dvěma vlčími udicemi závěsnými kroužky ke středu, vše bílé. V bílém pruhu hnědý vykořeněný pařez. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně sníženě děleném štítě nahoře kyrys mezi dvěma vlčími udicemi závěsnými kroužky do středu štítu, vše stříbrné, dole vykořeněný pařez přirozené barvy."
    },
    {
        obec: "Brniště",
        kod: 561444,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47129,
        latitude: 50.729288,
        longitude: 14.703495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Brniste_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list s vodorovně děleným červeno-žlutým žerďovým klínem s vrcholem ve čtvrtině délky listu a s vodorovně dělenou žluto-červenou krokví s vrcholem ve středu listu. Krokev vychází z první čtvrtiny horního a dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad třemi (2,1) zlatými koulemi zlato-červeně polcená snížená obrácená krokev. Pole nad ní opačných barev."
    },
    {
        obec: "Brod nad Dyjí",
        kod: 584355,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69181,
        latitude: 48.875309,
        longitude: 16.535482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Coat_of_arms_of_Brod_nad_Dyji.jpeg",
        coatOfArmsFlagDescription: "Modrý list s pěti do kruhu uspořádanými osmicípými žlutými hvězdami nad dvěma vlnitými žlutými pruhy se dvěma vrcholy a jednou prohlubní. Pruhy jsou široké jednu desetinu šířky listu a vycházejí ze sedmé a deváté desetiny žerďového a vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma vlnitými břevny pět osmihrotých hvězd do kruhu, vše zlaté."
    },
    {
        obec: "Brod nad Tichou",
        kod: 541605,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.835396,
        longitude: 12.741203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/CoA_of_Brod_nad_Tichou.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a modrý, v poměru 4:2:1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky, na dolním okraji černého pruhu bílé půleliptické pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na modré patě černý most s jedním obloukem, nad ním červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Brno",
        kod: 582786,
        okres: "Brno-město",
        kod_okres: "CZ0642",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 60200,
        latitude: 49.19516,
        longitude: 16.606937,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Brno_%28znak%29.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy - bílý, červený, bílý a červený v poměru 1:2:2:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Brodce",
        kod: 535559,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29473,
        latitude: 50.33078,
        longitude: 14.86928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Brodce_CoA_CZ.svg",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s cimbuřím, uprostřed stříbrná věž s otevřenou branou se zlatými vraty na černých závěsech a černou vytaženou mříží, se třemi (2,1) černými okny, zakončená cimbuřím a červenou stanovou střechou se zlatou makovicí. Z věže vpravo vyrůstá půl černého orla se zlatou zbrojí a vlevo vyskakuje černý kůň se zlatým uzděním."
    },
    {
        obec: "Brodec",
        kod: 546895,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.295025,
        longitude: 13.801725
    },
    {
        obec: "Brodek u Konice",
        kod: 589314,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79846,
        latitude: 49.548791,
        longitude: 16.83297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Brodek_u_Konice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným vykořeněným listnatým stromem s hnědým kmenem nad modrou radlicí hrotem k žerdi. Strom je provázen po každé straně třemi modrými kruhovými poli pod sebou o průměru rovném jedné dvacetině délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněný listnatý strom nad modrou položenou radlicí, provázený po každé straně třemi modrými koulemi pod sebou."
    },
    {
        obec: "Brodek u Prostějova",
        kod: 589322,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79807,
        latitude: 49.370064,
        longitude: 17.090164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Brodek_u_Prostejova_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červeno-bíle polcený, černý se třemi žlutými šesticípými hvězdami, a bílo-červeně polcený. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zlatém poli černé orlí křídlo se třemi zlatými hvězdami, levé pole červeno-stříbrně šachované."
    },
    {
        obec: "Brodek u Přerova",
        kod: 512800,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.484289,
        longitude: 17.338354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Znak_m%C4%9Bstyse_Brodek_u_P%C5%99erova.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. V zeleném žlutá hlava jelena s krkem, v bílém půl červeného nožového kola přiléhajícího k zelenému pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zelené děleném štítě nahoře vyrůstá korunovaná postava sv. Kateřiny v červeném šatě s modrou pláštěnkou, se zlatými vlasy, držící v pravé ruce zelenou palmovou ratolest a levou rukou se opírá o polovinu čeveného nožového kola, dole doleva běžící zlatý jelen."
    },
    {
        obec: "Brodeslavy",
        kod: 566489,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.953778,
        longitude: 13.557097,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Brodeslavy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý se žlutou berlou, s bílým sudariem, závitem s lilií k žerdi a bílý, v poměru 3:1:3. V bílých pruzích k modrému pruhu přivrácená červená křídla se žlutými jetelovitě zakončenými perizonii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kůl se zlatou berlou s lilií v hlavici a se stříbrným sudariem provázený dvěma přivrácenými červenými křídly se zlatými, jetelovitě zakončenými perizonii."
    },
    {
        obec: "Broumov",
        kod: 541401,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.889478,
        longitude: 12.606805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Broumov_okr._tachov_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý zelený, v poměru 3:2. V bílém pruhu zkřížené černé čakany. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zelenou cimbuřovou patou zkřížené černé čakany."
    },
    {
        obec: "Broumov",
        kod: 573922,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55001,
        latitude: 50.585758,
        longitude: 16.331915,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Broumov_znak.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou labutí se žlutou zbrojí a žlutou šesticípou hvězdou ve vlajícím horním cípu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Broumy",
        kod: 531081,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26742,
        latitude: 49.955171,
        longitude: 13.852368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Broumy_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - bílý, modrý a bílý v poměru 1:2:1. Na modrém pruhu jsou bílá kruhová pole, pět v horní, šest v dolní řadě. Poměr šířky praporu k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré břevno posázené dvěma řadami stříbrných skleněných terčíků, provázené nahoře jehličnatou, dole listnatou větévkou v přirozených barvách."
    },
    {
        obec: "Brozany nad Ohří",
        kod: 564621,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41181,
        latitude: 50.453763,
        longitude: 14.145512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Brozany_nad_Ohri_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část se žlutým půlměsícem cípy vzhůru a žlutá vlající část. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatým stoupajícím půlměsícem stříbrný drak."
    },
    {
        obec: "Brtnice",
        kod: 586943,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58832,
        latitude: 49.307047,
        longitude: 15.676522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Brtnice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žluto-modře polcený, bílý, vlnitý modrý, vlnitý bílý a zvlněný modrý, v poměru 6:1:2:2:1. Vlnité pruhy mají pět vrcholů a šest prohlubní. V horním pruhu dva přivrácení lvi opačných barev s červenou zbrojí, držící mezi sebou červenou useň. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve zlato-modře polceném poli dva přivrácení lvi opačných barev s červenou zbrojí držící mezi sebou červenou useň. V dolním modrém poli nad stříbrno-modře třikrát vlnitě dělenou patou stříbrný most se dvěma oblouky, přiléhající k dělící linii."
    },
    {
        obec: "Brtnička",
        kod: 590371,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67527,
        latitude: 49.237281,
        longitude: 15.624317,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Brtni%C4%8Dka_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým andělem se žlutou svatozáří a botami, držící v pravici žlutý meč hrotem k hornímu okraji a v levici vztyčenou zelenou ratolest, provázený po stranách pod křídly odkloněnou žlutou včelou s bílými křídly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný anděl se zlatou svatozáří a botami, držící v pravici vztyčený zlatý meč a v levici vztyčenou zelenou ratolest, provázený po stranách pod křídly odkloněnou zlatou včelou se stříbrnými křídly."
    },
    {
        obec: "Brťov-Jeneč",
        kod: 581381,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.413781,
        longitude: 16.517076
    },
    {
        obec: "Brumov",
        kod: 581402,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.462484,
        longitude: 16.425856,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Brumov_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se šikmým červeným pruhem širokým polovinu šířky listu. V pruhu tři trojice žlutých vykořeněných buků se společnými korunami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmé červené břevno se třemi trojicemi zlatých vykořeněných buků se společnými korunami."
    },
    {
        obec: "Brumov-Bylnice",
        kod: 585114,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76331,
        latitude: 49.092465,
        longitude: 18.022809,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Znak_m%C4%9Bsta_Brumov-Bylnice.svg",
        coatOfArmsFlagDescription: "Červený list se žlutou padací mříží o dvou vodorovných břevnech a pěti svislých břevnech s černými hroty. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Brumovice",
        kod: 584363,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69111,
        latitude: 48.960895,
        longitude: 16.89626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Brumovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený v poměru 3:1:1:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná radlice, v ní zelená révová ratolest se čtyřmi listy a dvěma modrými hrozny."
    },
    {
        obec: "Brumovice",
        kod: 506320,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74771,
        latitude: 50.015393,
        longitude: 17.749685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Coa_Czech_Town_Brumovice.svg",
        coatOfArmsFlagDescription: "List tvoří svislý červený žerďový pruh se třemi žlutými liliemi. Ve zbývající části listu sedm kosmých pruhů střídavě žlutých a černých, šířka žerďového pruhu je 1/4 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlatý štít se třemi černými kosmými břevny a červenou hlavou se třemi zlatými liliemi."
    },
    {
        obec: "Bruntál",
        kod: 597180,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.988277,
        longitude: 17.463794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Coat_of_arms_of_Brunt%C3%A1l.jpg",
        coatOfArmsFlagDescription: "Modrý list s černým žerďovým klínem sahajícím do středu listu a bílou krokví s vrcholem ve třech čtvrtinách délky listu a vycházející z první čtvrtiny horního a dolního okraje listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Brusné",
        kod: 588377,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.36344,
        longitude: 17.660807,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Brusn%C3%A9_CoA.png",
        coatOfArmsFlagDescription: "Modrý list uprostřed se dvěma přivrácenými křídly, žerďovým bílým se třemi červenými pruhy a vlajícím červeným s bílými cimbuřovými pruhy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze stříbrného trojvrší s červeným mlýnským kamenem s černou kypřicí vyrůstají rozevřená křídla, pravé stříbrné se třemi černými břevny, levé červené se třemi stříbrnými cimbuřovitými břevny."
    },
    {
        obec: "Brušperk",
        kod: 598038,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73944,
        latitude: 49.700196,
        longitude: 18.222205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Bru%C5%A1perk_CoA.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným heroldským křížem. Šířka ramene kříže je 1/5 šířky listu. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Bruzovice",
        kod: 598046,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73936,
        latitude: 49.717092,
        longitude: 18.409749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Bruzovice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:1:4. Ve vlajícím pruhu kosmo žluté polní brány. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou se třemi vztyčenými modrými lipovými listy kosmo položené zlaté polní brány."
    },
    {
        obec: "Brzánky",
        kod: 546755,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.461941,
        longitude: 14.301296
    },
    {
        obec: "Brzice",
        kod: 573931,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.444489,
        longitude: 15.958411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Brzice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se zkříženými žlutými lněnými snopy, šikmý přes kosmý, provázenými po stranách a dole bílým květem lnu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené zlaté lněné snopy, šikmý přes kosmý, provázené po stranách a dole stříbrným květem lnu se zlatým středem."
    },
    {
        obec: "Brzkov",
        kod: 586951,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.526068,
        longitude: 15.72724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Brzkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed vzlétající bílý kačer s pětkrát černo-bíle děleným levým křídlem a černým páskem na krku, se žlutou zbrojí a červeným jazykem, provázený dvěma odvrácenými bílými vinařskými noži se žlutými rukojeťmi k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad stříbrným kvádrovaným mostem stříbrný vzlétající kačer s pětkrát černo-stříbrně děleným levým křídlem a černým páskem na krku, se zlatou zbrojí a červeným jazykem, provázený dvěma odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi. V prázdném mostním oblouku převrácená stříbrná hornická kladívka se zlatými topůrky."
    },
    {
        obec: "Břasy",
        kod: 559725,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.837107,
        longitude: 13.578439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Brasy_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý se žlutou osmicípou hvězdou a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad vlnitou patou doleva položená retorta převýšená osmihrotou hvězdou, vše zlaté."
    },
    {
        obec: "Břeclav",
        kod: 584291,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69002,
        latitude: 48.75324,
        longitude: 16.882617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/B%C5%99eclav_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, žlutý, bílý a modrý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Břehov",
        kod: 536059,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.022388,
        longitude: 14.330668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/B%C5%99ehov_CoA.jpg"
    },
    {
        obec: "Břehy",
        kod: 574805,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.051994,
        longitude: 15.577582,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Coat_of_arms_of_B%C5%99ehy.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: bílý, modrý, žlutý a modrý v poměru 1:1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou pilovitou hlavou zlaté břevno."
    },
    {
        obec: "Břest",
        kod: 588385,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76823,
        latitude: 49.351167,
        longitude: 17.440825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Brest_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, bílý a červený. V bílém poli vykořeněný listnatý strom (jilm) v přirozených barvách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná špice, v ní vykořeněný listnatý strom (jilm) přirozených barev. V červených polích po jedné stříbrné svatojakubské mušli."
    },
    {
        obec: "Břestek",
        kod: 592072,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68708,
        latitude: 49.094985,
        longitude: 17.356023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Brestek_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:4:1. V modrém pruhu červeno-žlutě polcené srdce se sedmi vějířovitě zabodnutými bílými meči se žlutými jílci. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým trojvrším s modrým vinným hroznem červeno-zlatě polcené srdce se sedmi vějířovitě zabodnutými stříbrnými meči se zlatými jílci."
    },
    {
        obec: "Břevnice",
        kod: 548278,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.631361,
        longitude: 15.612697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Coat_of_arms_of_B%C5%99evnice.svg",
        coatOfArmsFlagDescription: "Ve žlutém listu s kosmým zvlněným modrým pruhem, širokým šestinu šířky listu, šikmo černá ostrev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě s kosmým modrým vlnitým břevnem šikmo černá ostrev."
    },
    {
        obec: "Březí",
        kod: 530018,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 26242,
        latitude: 49.507873,
        longitude: 13.797583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/B%C5%99ez%C3%AD_%28ST%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vozovým kolem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina zlato-modře polcená; vpravo černá kančí hlava, vlevo zlaté vozové kolo. V dolní stříbrné polovině dva zelené odvrácené trojlístky jetele."
    },
    {
        obec: "Březejc",
        kod: 595349,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.346399,
        longitude: 16.091562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/B%C5%99ezejc_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list, ve střední části položená osekaná březová větev s vyrůstajícím bílým trojlistem a provázena nahoře dvěma a dole třemi žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě položená osekaná březová větev přirozené barvy s vyrůstajícím stříbrným trojlistem. Větev nahoře provázena dvěma a dole třemi (2, 1) zlatými hvězdami."
    },
    {
        obec: "Březí",
        kod: 584371,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69181,
        latitude: 48.819377,
        longitude: 16.567583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Coat_of_arms_of_Brezi.jpeg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole červené, dolní vlající zelené, horní vlající a dolní žerďové bílá kvádrovaná. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se zeleným lemem stříbrná okrouhlá kvádrová studna s okovem."
    },
    {
        obec: "Březí",
        kod: 595357,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.343279,
        longitude: 16.216045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/B%C5%99ez%C3%AD_ZK_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a bílý. V černém pruhu kosmo tři žluté svěšené březové listy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-stříbrně děleném štítě nahoře položená oboustranně uťatá březová větev přirozené barvy se třemi svěšenými zlatými listy, dole dvě černé, doleva obrácené a položené radlice nad sebou."
    },
    {
        obec: "Březí",
        kod: 564869,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 50.014766,
        longitude: 14.699389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Coats_of_arms_B%C5%99ez%C3%AD.jpeg",
        coatOfArmsFlagDescription: "List tvoří zelený vodorovný pruh, široký čtyři sedmnáctiny šířky listu, a dva svislé pruhy, červený a žlutý. V zeleném pruhu knížecí koruna, pod ním uprostřed žluto-červeně polcená useň. V její žerďové polovině na zeleném trávníku bříza, ve vlající polovině bílý středový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě pod zelenou hlavou s knížecí korunou zlato-červeně polcená useň, v ní vpravo na zeleném trávníku bříza přirozené barvy, vlevo stříbrný heroldský kříž."
    },
    {
        obec: "Březí nad Oslavou",
        kod: 595365,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.502993,
        longitude: 15.935697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/B%C5%99ez%C3%AD_nad_Oslavou%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě žlutých a zelených, v poměru 4 : 1 : 1 : 1 : 1. Zelené pruhy jsou vlnité se třemi vrcholy a dvěma prohlubněmi. V horním pruhu nad prohlubněmi dva zelené vztyčené březové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad dvěma sníženými vlnitými břevny dva vztyčené březové listy, vše zelené."
    },
    {
        obec: "Březina",
        kod: 507733,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.290639,
        longitude: 14.90976,
        coatOfArmsFlagDescription: "Bílo - zeleně šikmo dělený list. V bílém poli vztyčený zelený březový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně šikmo děleném štítě vpravo vztyčený zelený březový list."
    },
    {
        obec: "Březina",
        kod: 549070,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.434394,
        longitude: 15.313367
    },
    {
        obec: "Březina",
        kod: 577871,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56923,
        latitude: 49.651038,
        longitude: 16.619713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/B%C5%99ezina_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý v poměru 3:1. U žerďového okraje pod sebou čtyři vztyčené březové listy opačných barev. V horním cípu žluté čtvrtkruhové pole o poloměru rovném třem čtvrtinám šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravá polovina zeleno-stříbrně polcena, ve stříbrném poli čtyři vztyčené zelené březové listy nad sebou, v levé zelené polovině stříbrný hlemýžď se zlatou ulitou."
    },
    {
        obec: "Březina",
        kod: 559733,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.807642,
        longitude: 13.596901,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, a vlající zubatý bílo-modře dělený pruh široký třetinu délky listu s pěti čtvercovými zuby a čtyřmi stejnými mezerami. V žerďové a střední části listu žluto-zeleně dělená osmicípá hvězda velká třináct patnáctin šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě nad cimbuřovou patou opačných barev zlato-zeleně polcená osmihrotá hvězda."
    },
    {
        obec: "Březina",
        kod: 535567,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29411,
        latitude: 50.548647,
        longitude: 15.032901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/B%C5%99ezina_%28Mlad%C3%A1_Boleslav_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký třetinu délky listu, a dva vodorovné pruhy, červený a modrý. V bílém pruhu dva zelené březové listy, horní kosmo vztyčený, dolní šikmo svěšený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou vlnitou patou široký červený rošt nahoře s držadlem provázeným dvěma vztyčenými odkloněnými zelenými březovými listy."
    },
    {
        obec: "Březina (vojenský újezd)",
        kod: 592935,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 79805,
        latitude: 49.368199,
        longitude: 16.960939,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený se šesti bílými rovnoramennými trojúhelníky a zelený. Vrcholy trojúhelníku jsou na žerďovém okraji středního pruhu a základny na vlajícím okraji středního pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou se šesti zelenými věžemi s cimbuřím a prázdnými branami šest stříbrných kuželů, pod nimi šest stříbrných věží s cimbuřím a prázdnými branami."
    },
    {
        obec: "Březina",
        kod: 581429,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67905,
        latitude: 49.282167,
        longitude: 16.755163,
        coatOfArmsFlagDescription: "List tvoří žerďový zelený klín s vrcholem ve středu listu, bílá krokev vycházející z první třetiny horního a dolního okraje, přilehlá zelená krokev široká jednu šestinu délky listu s vrcholem na vlajícím okraji listu a dvě žlutá trojúhelníková vlající pole. V bílé krokvi čtyři červené růže se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný hrot, v něm bříza přirozené barvy dole do oblouku provázená čtyřmi červenými růžemi se zlatými semeníky. Hrot je provázen vpravo svěšeným žaludem, vlevo kosmo položenou sekerou - širočinou, obojí zlaté."
    },
    {
        obec: "Březina",
        kod: 582891,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.281698,
        longitude: 16.749623,
        coatOfArmsFlagDescription: "Bílo - zeleně šikmo dělený list. V bílém poli vztyčený zelený březový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně šikmo děleném štítě vpravo vztyčený zelený březový list."
    },
    {
        obec: "Březinky",
        kod: 572250,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.662787,
        longitude: 16.78703
    },
    {
        obec: "Březnice",
        kod: 552135,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39171,
        latitude: 49.251874,
        longitude: 14.514089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/B%C5%99eznice_TA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, vlnitý bílý a zvlněný modrý, v poměru 1 : 1 : 4. Zvlněný a vlnitý pruh mají tři vrcholy a dvě prohlubně. V modrém pruhu bílý rozkřídlený pták s červeným levým křídlem a žlutou zbrojí. Na křídlech dolů obrácený antonínský kříž opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou se dvěma doleva položenými zelenými březovými listy stříbrný rozkřídlený pták s červeným levým křídlem a zlatou zbrojí. Na křídlech dolů obrácený antonínský kříž opačných barev."
    },
    {
        obec: "Březiny",
        kod: 577898,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.690726,
        longitude: 16.118233
    },
    {
        obec: "Březnice",
        kod: 538744,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76001,
        latitude: 49.184965,
        longitude: 17.656742,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/CZ_B%C5%99eznice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovné pruhy - červený a bílý a žerďové modré pole se žlutou snítkou břízy ze znaku. Modré pole sahá do 3/7 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítu na zelené hůrce se zlatou vlnitou patou stříbrné průčelí kostela s věží uprostřed a s červenou střechou. Do věže jsou prolomeny dveře a pět oken (tři a dvě), vše černé. V obou horních rozích štítu je zlatá březová snítka se třemi lístky a dvěma jehnědami."
    },
    {
        obec: "Březnice",
        kod: 540013,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.557739,
        longitude: 13.950734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Breznice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, vlnitý bílý a zvlněný modrý, v poměru 1 : 1 : 4. Zvlněný a vlnitý pruh mají tři vrcholy a dvě prohlubně. V modrém pruhu bílý rozkřídlený pták s červeným levým křídlem a žlutou zbrojí. Na křídlech dolů obrácený antonínský kříž opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou se dvěma doleva položenými zelenými březovými listy stříbrný rozkřídlený pták s červeným levým křídlem a zlatou zbrojí. Na křídlech dolů obrácený antonínský kříž opačných barev."
    },
    {
        obec: "Březník",
        kod: 590380,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67574,
        latitude: 49.172155,
        longitude: 16.194204,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/B%C5%99ezn%C3%ADk_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a černý, v poměru 1:2:1. Ve žlutém pruhu černý lev se žlutou korunou a červenou zbrojí mezi čtyřmi (2,2) vztyčenými zelenými březovými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černý korunovaný lev s červenou zbrojí obklopený dolů do oblouku osmi zelenými březovými listy."
    },
    {
        obec: "Březno",
        kod: 535583,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.406292,
        longitude: 15.005584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/B%C5%99ezno_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 7 : 3. Z bílého pruhu vyrůstá bílý lev se žlutou zbrojí, držící levou tlapou žlutou žerď se žluto-černě polcenou korouhví se žlutým ocasem. V bílém pruhu zelený březový dvoulist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze stříbrné hradby stříbrný lev se zlatou zbrojí, držící levou tlapou zlatou žerď se zlato-černě polcenou korouhví se zlatým ocasem. V hradbě zelený březový dvoulist."
    },
    {
        obec: "Březno",
        kod: 563013,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43145,
        latitude: 50.40228,
        longitude: 13.420942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/B%C5%99ezno_%28Chomutov_District%29_-_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým zubatým kvádrovaným pruhem s pěti červeně zakončenými čtvercovými zuby a čtyřmi stejnými mezerami, širokým jednu pětinu délky listu. V modrém poli polovina bílého ozbrojence v plátové zbroji se šalířem, držícího v pravici halapartnu ostřím k žerďovému okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Březolupy",
        kod: 592081,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68713,
        latitude: 49.121484,
        longitude: 17.580451,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/B%C5%99ezolupy_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrou krokví, vycházející ze čtvrté čtvrtiny žerďového a vlajícího okraje, s vrcholem v první osmině šířky listu. V horním rohu a cípu zelené svěšené odkloněné lipové listy. Pod krokví modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou sníženou zúženou krokví, provázenou nahoře dvěma svěšenými odkloněnými zelenými březovými listy, modrá lilie."
    },
    {
        obec: "Březová",
        kod: 537870,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.197494,
        longitude: 12.870986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Brezova_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým vodorovným vlnitým pruhem o třech vrcholech a dvou prohlubních, vycházejícím z osmé devítiny žerďového a vlajícího okraje. Nad prostředním vrcholem žlutá bříza s bílým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad sníženým stříbrným vlnitým břevnem stříbrný hamr bucharem doleva. Ze středu hamru vyrůstá zlatá bříza se stříbrným kmenem."
    },
    {
        obec: "Březová",
        kod: 560294,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.145752,
        longitude: 12.643488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Brezova_SO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a žlutý, v poměru 5:7:5. V bílém pruhu šikmo zelený list břízy, řapíkem k dolnímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Březová",
        kod: 506451,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74744,
        latitude: 49.791678,
        longitude: 17.865664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/B%C5%99ezov%C3%A1_%28okres_Opava%29_znak.jpeg",
        coatOfArmsFlagDescription: "Šikmo dělený list, horní žerďové pole bílé s černě kreslenou vykořeněnou lípou se zelenými listy. Dolní vlající pole červené s bílou čtverečnou mříží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně šikmo dělený štít. Nahoře vykořeněná bříza přirozených barev, dolní pole stříbrně mřežované."
    },
    {
        obec: "Březová",
        kod: 531090,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.904184,
        longitude: 13.883116,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/B%C5%99ezov%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zubatý žlutý a bílý, v poměru 1 : 2 : 9. Žlutý pruh má k žerdi čtyři obdélníkové zuby a tři stejně široké mezery dosahující do první šestiny délky listu. V bílém pruhu u žlutého pruhu zelený březový trojlist položený k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou s korunou mezi dvěma vyrůstajícími věžemi s cimbuřím, vše zlaté, svěšený zelený březový trojlist."
    },
    {
        obec: "Březová",
        kod: 592099,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68767,
        latitude: 48.922188,
        longitude: 17.742769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Emblem_Brezova_uh_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý se žlutou lilií, žlutý se zelenou břízou s bílo-černým kmenem a modrý se žlutým tlapatým patriarším křížem, v poměru 2:3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý hrot, v něm na zeleném trojvrší bříza přirozené barvy, hrot je provázen vpravo lilií, vlevo patriarším křížem, obojí zlaté."
    },
    {
        obec: "Březová",
        kod: 585131,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 48.923332,
        longitude: 17.741218,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/B%C5%99ezov%C3%A1_%28Zl%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým kruhovým polem o průměru jedné poloviny šířky listu s vykořeněnou břízou. Okolo kruhového pole osm žlutých osmicípých hvězd s černobílým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrým lemem posázeným osmi zlatými osmihrotými hvězdami vykořeněná bříza přirozené barvy."
    },
    {
        obec: "Březová nad Svitavou",
        kod: 505145,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56902,
        latitude: 49.644277,
        longitude: 16.518096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/B%C5%98EZOV%C3%81_NAD_SVITAVOU_ZNAK_s_damaskem.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, modrý, bílý a modrý v poměru 5:1:2:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Březová-Oleško",
        kod: 599735,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25245,
        latitude: 49.904148,
        longitude: 14.411028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Brezova-Olesko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký čtvrtinu délky listu a pět vodorovných pruhů, střídavě bílých a zelených. V modrém pruhu žlutá berla závitem k žerdi, s bílým sudariem s černým křížkem a žlutým třepením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně vpravo polceném štítě vpravo zlatá berla se stříbrným sudariem s černým křížkem a zlatým třepením, vlevo doleva položená větev se třemi březovými listy a dvěma jehnědami nahoře, třemi olšovými listy a dvěma jehnědami dole, vše zelené."
    },
    {
        obec: "Březovice",
        kod: 599514,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29424,
        latitude: 50.470818,
        longitude: 14.733711
    },
    {
        obec: "Březské",
        kod: 595381,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.330018,
        longitude: 16.238271,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/B%C5%99ezsk%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, bílý a černý, v poměru 2 : 1 : 5 : 8. V černém pruhu kosmo tři svěšené žluté březové listy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-černě děleném štítě nahoře položené červené polní brány, dole položená, oboustranně uťatá březová větev přirozené barvy se třemi svěšenými zlatými listy."
    },
    {
        obec: "Březsko",
        kod: 589331,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.608693,
        longitude: 16.892539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/B%C5%99ezsko_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým běžícím koněm. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý běžící kůň."
    },
    {
        obec: "Březůvky",
        kod: 585149,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76345,
        latitude: 49.153427,
        longitude: 17.699728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/B%C5%99ez%C5%AFvky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dvě žerďová čtvercová pole, červené nad zeleným, svislý žlutý pruh široký jednu třetinu délky listu se zelenou břízou s bílo-černým kmenem, a dvě čtvercová vlající pole, zelené nad červeným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-červeně polcený štít se zelenou patou. Vpravo vyrůstající bříza přirozené barvy, vlevo tři zkřížené zlaté dřevce, prostřední hrotem nahoru, ostatní obrácené."
    },
    {
        obec: "Břežany",
        kod: 593842,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67165,
        latitude: 48.870023,
        longitude: 16.341852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/B%C5%99e%C5%BEany_%28ZN%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s černo-žlutě šachovaným (8x2) žerďovým pruhem širokým šestinu délky listu. V modrém poli na volném žlutém trojvrší bílý pták s červenou zbrojí, provázený v horním cípu žlutou růží s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod černo-zlatě dvěma řadami šachovanou hlavou na zlatém trojvrší stříbrný pták s červenou zbrojí, provázený vlevo nahoře zlatou růží s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Břežany",
        kod: 541923,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.348864,
        longitude: 13.61746,
        coatOfArmsFlagDescription: "List tvoří vydutý bílo-modře dělený žerďový klín a dva vodorovné pruhy, zelený a žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě zeleno-stříbrně polcený vydutý hrot se stříbrno-modře polcenou lilií provázený vpravo vztyčeným zeleným lipovým listem, vlevo zlatou osmihrotou hvězdou."
    },
    {
        obec: "Břežany",
        kod: 565041,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27034,
        latitude: 50.00696,
        longitude: 13.582896,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Coats_of_arms_B%C5%99e%C5%BEany.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed vztyčený březový list opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě vztyčený březový list opačných barev."
    },
    {
        obec: "Břežany I",
        kod: 533211,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.035026,
        longitude: 15.079055
    },
    {
        obec: "Břežany II",
        kod: 533220,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.093862,
        longitude: 14.804403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/B%C5%99e%C5%BEany_II_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeno - žlutě šikmo děleným žerďovým trojúhelníkem s vrcholem v horním rohu a s odvěsnami dlouhými polovinu šířky listu. V modrém poli sokol letící k vlajícímu okraji a držící v oranžových pařátech šikmo žlutou rybu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s červeno - zlatě šikmo děleným pravým koutem šikmo letící sokol přirozené barvy držící v pařátech zlatou rybu."
    },
    {
        obec: "Břidličná",
        kod: 597228,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79351,
        latitude: 49.911767,
        longitude: 17.371179,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/B%C5%99idli%C4%8Dn%C3%A1_znak.png",
        coatOfArmsFlagDescription: "Červený list se šikmým bílo-žlutým pruhem vycházejícím z dolní poloviny žerďového okraje do horní poloviny vlajícího okraje. V horním rohu žlutovlasá hlava anděla podložená bílými zkříženými křídly. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bříství",
        kod: 537047,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28915,
        latitude: 50.134028,
        longitude: 14.841845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/B%C5%99%C3%ADstv%C3%AD_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3:1. V bílém pruhu dvě černé zkřížené větve břestovce, každá se třemi zelenými listy a dvěma suky, převýšené černou orlí hlavou s červeným zobákem a jazykem. V zeleném pruhu žlutý svazek šípů hroty k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším s položeným zlatým svazkem šípů dvě černé zkřížené větve břestovce, každá se třemi zelenými listy a dvěma suky, převýšené černou orlí hlavou s červeným zobákem a jazykem."
    },
    {
        obec: "Bříšťany",
        kod: 548855,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.316342,
        longitude: 15.620923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/B%C5%99%C3%AD%C5%A1%C5%A5any_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým třetinu délky listu a vodorovným pruhem opačných barev vycházejícím z páté devítiny šířky listu. V bílých žerďových polích kosmo vztyčený zelený jilmový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře cimbuřově děleném štítě nahoře dva kosmo vztyčené zelené jilmové listy, dole stříbrná ryba."
    },
    {
        obec: "Bříza",
        kod: 564648,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.360615,
        longitude: 14.216366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Briza_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu se žlutým tlapatým křížem v horní polovině pruhu a dva vodorovné pruhy, bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatým tlapatým křížem bříza přirozené barvy na zeleném trávníku."
    },
    {
        obec: "Břvany",
        kod: 566063,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.401253,
        longitude: 13.721648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Brvany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. Ze středu horního okraje žlutého pruhu odkloněně tryská šest (3, 3) bílých pramenů. Ve žlutém pruhu modrý meč s hrotem na dolním okraji listu a s čepelí mezi dvěma půlemi rozprostřeného červeného pláště, nahoře se zelenými vázacími šňůrami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít. Nahoře šest (3, 3) odkloněně tryskajících stříbrných pramenů. Dole postavený modrý meč mezi dvěma půlemi rozprostřeného červeného pláště, nahoře se zelenými vázacími šňůrami."
    },
    {
        obec: "Bublava",
        kod: 560308,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.374494,
        longitude: 12.505287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Bublava_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý oblakový, modrý vlnitý a bílý zvlněný, v poměru 2:2:1:3. Vlnitý pruh má tři vrcholy a dvě prohlubně. V horním pruhu pět žlutých šesticípých hvězd vedle sebe, ve zvlněném pruhu červená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou oblakovou hlavou s pěti zlatými hvězdami modré vlnité břevno, pod ním červená hornická kladívka."
    },
    {
        obec: "Bubovice",
        kod: 531103,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.969761,
        longitude: 14.166601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Bubovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 6 : 1. V modrém pruhu bílá, žlutě zdobená mitra s fimbriemi, s červenými a zelenými drahokamy, provázená po stranách dvěma do oblouku přivrácenými žlutými obilnými klasy s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma přivrácenými zlatými obilnými klasy s listem stříbrná, zlatě zdobená mitra s fimbriemi, s červenými a zelenými drahokamy. V patě štítu zlatý vydutý hrot s volným černým ostrvovým křížem."
    },
    {
        obec: "Bučí",
        kod: 558699,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.885275,
        longitude: 13.302879,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Bu%C4%8D%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině tři vodorovné pruhy, černý, bílý a červený, vlající polovina je žlutá. V bílém pruhu černé parohy se žlutými hroty výsad, ve vlající polovině zelená vztyčená buková větev se třemi listy a jednou bukvicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole 2x černo-stříbrno-červeně dělené, uprostřed černé jelení parohy se zlatými hroty výsad, v levém zlatém poli vztyčená buková větev se třemi listy a jednou bukvicí přirozené barvy."
    },
    {
        obec: "Bučina",
        kod: 579963,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.896972,
        longitude: 16.192941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Bu%C4%8Dina.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý se zeleným vztyčeným bukovým trojlistem u žerdi a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě nahoře zelený bukový trojlist."
    },
    {
        obec: "Bučovice",
        kod: 592943,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.149402,
        longitude: 17.003981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Znak_m%C4%9Bsta_Bu%C4%8Dovice.svg",
        coatOfArmsFlagDescription: "List tvoří horní modré a dolní červené pole oddělené třemi bílými kosmo položenými kosodélníky tak, že jejich horní rohy leží na polovině listu a dolní rohy na desetině šířky listu. Kratší strana každého kosodélníku se rovná pětině šířky listu. V horním rohu listu jsou tři bílé věže s červenými střechami a žlutými křížky z městského znaku. Střední věž je větší. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Budeč",
        kod: 546020,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37892,
        latitude: 49.087277,
        longitude: 15.589973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Bude%C4%8D_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, bílý, žlutý a modrý, v poměru 5 : 2 : 2 : 2 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatým vlnitým břevnem v hlavě zkřížené stříbrné válečné sekery, pod nimi svěšený zlatý leknínový list."
    },
    {
        obec: "Budčeves",
        kod: 548952,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.308573,
        longitude: 15.254641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Bud%C4%8Deves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 3 : 2 : 3. V modrém pruhu žlutý, sedmkrát (1, 2, 1, 2, 1) děrovaný kotouč přeložený žlutou sekyrou s bílým toporem. V žerďové polovině listu zelená lipová větévka se dvěma listy, ve vlající polovině listu zelený stvol orobince s listem a s hnědou palicí; obojí do oblouku odkloněné a provlečené krajními otvory kotouče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kůl, v něm zlatý, sedmkrát (1, 2, 1, 2, 1) děrovaný kotouč přeložený zlatou sekyrou se stříbrným toporem. Vpravo zelená lipová větévka se dvěma listy, vlevo zelený stvol orobince s listem a s palicí přirozené barvy; obojí do oblouku odkloněné a provlečené krajními otvory kotouče."
    },
    {
        obec: "Budeč",
        kod: 595390,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.53647,
        longitude: 15.912421
    },
    {
        obec: "Budětice",
        kod: 555894,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.283469,
        longitude: 13.58477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Bud%C4%9Btice_znak.jpg",
        coatOfArmsFlagDescription: "List dělený červenou kosmou routovou korunou s pěti trojlisty na žerďové žluté a vlající bílé pole. V žerďovém poli černá kančí hlava s bílou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-stříbrně kosmou červenou routovou korunou s pěti trojlisty děleném štítě dole černá kančí hlava se stříbrnou zbrojí."
    },
    {
        obec: "Budětsko",
        kod: 589349,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.587129,
        longitude: 16.92698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Bud%C4%9Btsko_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem, jehož ramena vycházejí z třetí a deváté devítiny horního a dolního okraje. V žerďovém poli vztyčený bílý dubový dvoulist se žaludem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné sekery se zlatými topůrky, pod nimi vztyčený stříbrný dubový dvoulist se žaludem."
    },
    {
        obec: "Budíkov",
        kod: 547638,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.579243,
        longitude: 15.362705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Budikov_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a bílý, v poměru 1:6:1. V zeleném pruhu tři žluté obilné snopy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené stříbrné kosy nad třemi (1,2) zlatými obilnými snopy."
    },
    {
        obec: "Budislav",
        kod: 552143,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.293941,
        longitude: 14.837518,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 4 : 1. Z druhé a třetí šestiny modrého pruhu vyniká vydutý klín zakončený horní polovinou lilie převýšenou třemi (2, 1) červenými čtvercovými poli, vše provázeno po stranách červenými býčími rohy. Pod lilií bílá střela hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě mezi dvěma červenými býčími rohy a pod třemi (2,1) červenými kostkami horní polovina lilie na sníženém vydutém hrotu, obojí modré. V hrotu vyrůstající stříbrná střela."
    },
    {
        obec: "Budiměřice",
        kod: 537055,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.195426,
        longitude: 15.098869
    },
    {
        obec: "Budíškovice",
        kod: 546038,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37891,
        latitude: 49.076348,
        longitude: 15.531006,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Bud%C3%AD%C5%A1kovice_znak.JPG",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, modrý, červený a bílý, v poměru 1 : 2 : 2 : 1. V modrém pruhu bílá věž s cimbuřím, s černým obloukovým vstupem a dvěma černými okny. Pod ní žlutá švestková větévka se dvěma plody a dvěma listy. V červeném pruhu bílá jelení hlava s krkem, se žlutým parožím a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo pod stříbrnou věží s cimbuřím, s černým obloukovým vstupem a dvěma černými okny zlatá švestková větévka se dvěma plody a dvěma listy. Vlevo stříbrná jelení hlava s krkem, se zlatým parožím a jazykem."
    },
    {
        obec: "Budišov",
        kod: 590401,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.271484,
        longitude: 16.003938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Budi%C5%A1ov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červeno-žlutě čtvrcený list. V horním žerďovém poli žlutá kvádrovaná věž s černými vraty, čtyřmi černými čtvercovými okny vedle sebe a modrou valbovou střechou se dvěma žlutými odvrácenými praporky. Na věži bílý štítek s červeným kůlem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Budišov nad Budišovkou",
        kod: 506460,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74787,
        latitude: 49.79521,
        longitude: 17.629792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Znak_obce_Budi%C5%A1ov_nad_Budi%C5%A1ovkou.jpg",
        coatOfArmsFlagDescription: "Kosmo dělený list, horní pole žluté, dolní červené. Mezi žerďovou a střední částí vinařský nůž s bílou čepelí a žlutou střenkou a mezi střední a vlající částí zelené majuskulní 'F'. Obě figury vysoké tři čtvrtiny šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Budislav",
        kod: 577910,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56965,
        latitude: 49.801511,
        longitude: 16.174739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Budislav_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Kosmo dělený list s vlajícím zeleným polem a žerďovým žlutým polem. V zeleném poli žlutý vykračující lev s červenými drápy a jazykem, ve žlutém poli dva kosmé pruhy, zelený na černém, každý o šířce desetiny délky listu. Dělící čára mezi pruhy vychází z poloviny žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě čtyřmi pahrbky sníženě kosmo dělený štít. Po pahrbcích vykračující zlatý lev s červenou zbrojí, dole kosmo položený zelený toulec se dvěma šípy podložený černým lukem, tětivou nahoru."
    },
    {
        obec: "Budišovice",
        kod: 568261,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74764,
        latitude: 49.861418,
        longitude: 18.039719,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Budi%C5%A1ovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a žlutý, v poměru 2:1. V černém pruhu bílý kráčející lev s červenou zbrojí, držící žlutou berlu závitem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě nad položenou zlatou radlicí stříbrný kráčející lev s červenou zbrojí držící zlatou berlu závitem ven."
    },
    {
        obec: "Budkov",
        kod: 561576,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.070156,
        longitude: 14.007074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Budkov_%28Prachatice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý, v poměru 5 : 1 : 4 : 1 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s červeným, zlatě lemovaným břevnem. Nahoře vztyčený lipový list mezi dvěma včelami, vše zlaté, dole zlatý mlýnský kámen s černou kypřicí."
    },
    {
        obec: "Budkov",
        kod: 590410,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67542,
        latitude: 49.054591,
        longitude: 15.65903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Coat_of_arms_of_Budkov_TR.jpg",
        coatOfArmsDescription: "V modrém štítě vyrůstající stříbrná patrová věž s gotickou černou prolomenou branou, se třemi okny a červenou stanovou střechou zakončenou stříbrnou lucernou a červenou věžičkou, dole přeložená zkříženými zlatými obilnými klasy. V červených koutech štítu stříbrné šikmé a kosmé břevno."
    },
    {
        obec: "Budyně",
        kod: 560171,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.14606,
        longitude: 14.071176
    },
    {
        obec: "Budyně nad Ohří",
        kod: 564656,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41118,
        latitude: 50.404377,
        longitude: 14.126018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Budyn%C4%9B_n_H_znak.png",
        coatOfArmsFlagDescription: "Modro-žlutě čtvrcený list v dolním vlajícím poli žlutý zkrácený středový kříž. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Buchlovice",
        kod: 592102,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68708,
        latitude: 49.086371,
        longitude: 17.338628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Znak_m%C4%9Bstyse_Buchlovice.svg",
        coatOfArmsFlagDescription: "Červený list s kosým bílým pruhem vycházejícím ze dvou pětin žerďového okraje a sahajícím do dvou pětin vlajícího okraje. Do bílého pruhu jsou položeny dva zelené pruhy v celkovém vzájemném poměru 1:1:2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně šikmo dělený štít. Nahoře zelená dubová ratolest o třech listech a třech žaludech. Dole stříbrná lilie."
    },
    {
        obec: "Bujanov",
        kod: 545449,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38241,
        latitude: 48.702429,
        longitude: 14.429747
    },
    {
        obec: "Bujesily",
        kod: 559741,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.914681,
        longitude: 13.574492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Bujesily_znak.jpg",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. Z červeného pole k žerdi vyskakující bílý kůň se žlutými kopyty. V červeném poli šikmo pod sebou žlutá partyzána s bílým ratištěm hrotem dolů, žlutý cep s bílým kováním se svislým žlutým tloukem u vlajícího okraje a žlutý obilný klas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě nahoře vyskakující stříbrný kůň se zlatou zbrojí, dole šikmo položené pod sebou zlatá partyzána se stříbrným hrotem dolů, zlatý cep se stříbrným kováním a zlatý obilný klas."
    },
    {
        obec: "Buk",
        kod: 537519,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.038011,
        longitude: 13.845247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Buk_PT_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým černo - žluto - černě děleným polem a dva vodorovné pruhy, bílý a zelený. Z dolního okraje karé vyrůstá bílý korunovaný dvouocasý lev se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Vpravo červeno - černě dělený, nahoře vyrůstající stříbrný korunovaný dvouocasý lev se zlatou zbrojí a jazykem, dole zlaté břevno. V levém stříbrném poli na zeleném návrší buk přirozené barvy."
    },
    {
        obec: "Buk",
        kod: 512826,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75121,
        latitude: 49.504545,
        longitude: 17.468822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Buk_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý červený, v poměru 11:9. Uprostřed listu vykořeněný buk, v bílém pruhu zelený, v červeném bílý, podložený v červeném pruhu položenou bílou sekerou se žlutým topůrkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve sníženě stříbrno-červeně cimbuřovitě děleném štítě zeleno-stříbrně dělený vykořeněný buk s kmenem podloženým položenou stříbrnou sekerou se zlatým topůrkem."
    },
    {
        obec: "Bukov",
        kod: 595403,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.454403,
        longitude: 16.223666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Bukov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý v poměru 1:2. V zeleném pruhu dva žluté bukové listy s bukvicí, v bílém pruhu černé orlí křídlo se žlutým perizoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zelenou hlavou se dvěma zlatými bukovými listy s bukvicí černé orlí křídlo se zlatým perizoniem."
    },
    {
        obec: "Buková",
        kod: 589357,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79848,
        latitude: 49.509838,
        longitude: 16.829233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Bukov%C3%A1_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým kosmým pruhem širokým jednu čtvrtinu šířky listu a dvěma žlutými kosmými pruhy širokými jednu osminu šířky listu a vzdálenými od bílého pruhu jednu osminu šířky listu. V bílém pruhu tři zelené kosmé bukové listy řapíkem k hornímu rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno se třemi zelenými doleva položenými bukovými listy, nad ním zkřížené stříbrné kopáče na zlatých násadách, dole stříbrný květ přirozené lilie."
    },
    {
        obec: "Buková",
        kod: 546372,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33452,
        latitude: 49.537495,
        longitude: 13.158861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Bukov%C3%A1_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným, zeleně lemovaným křížem. Ramena kříže jsou široká jednu čtvrtinu šířky listu, lem je široký jednu osminu šířky listu. V kříži bílý meč se žlutým jílcem hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený latinský kříž do krajů, v něm postavený stříbrný meč se zlatým jílcem. Nad rameny dvě odvrácené zelené dračí hlavy s červenými jazyky, pod rameny vyrůstají dvě dvojice od sebe odkloněných zelených bukových listů."
    },
    {
        obec: "Buková u Příbramě",
        kod: 540021,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.755238,
        longitude: 14.066683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Bukov%C3%A1_u_P%C5%99%C3%ADbram%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a pilovitý bílý, v poměru 3 : 1. Bílý pruh má čtyři zuby s hroty v polovině šířky listu. V zeleném pruhu čtyři žluté vztyčené bukvice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě čtyři vztyčené zlaté bukvice nad stříbrnou palisádou o čtyřech kůlech."
    },
    {
        obec: "Bukovany",
        kod: 586072,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69631,
        latitude: 49.042267,
        longitude: 17.100961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Bukovany_HO_CZ_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s červeným žerďovým klínem s vrcholem ve středu listu. V klínu uťatý bílý buk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad červenou zvýšenou patou se šikmým zlatým vinařským nožem-kosířem s rukojetí přirozené barvy a kosmým vztyčeným zlatým krojidlem uťatý stříbrný buk."
    },
    {
        obec: "Bukovany",
        kod: 560316,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35755,
        latitude: 50.166437,
        longitude: 12.572766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Bukovany_Sokolov_CoA.gif",
        coatOfArmsFlagDescription: "Zelený list se žlutým vykořeněným bukem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vykořeněný buk, pod ním kosmo položený mlátek, šikmo přeložený želízkem, vše zlaté."
    },
    {
        obec: "Bukovany",
        kod: 552402,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.60536,
        longitude: 17.343594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Znak_Bukovany_OL.JPG",
        coatOfArmsFlagDescription: "Zelený list se žlutou bukovou snítkou se dvěma listy a dvěma bukvicemi pod nimi. Červený žerďový pruh široký jednu čtvrtinu délky listu se třemi bílými klíny s vrcholy na žerďovém okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými kužely zlatá buková snítka s dvěma listy a dvěma bukvicemi."
    },
    {
        obec: "Bukovany",
        kod: 532924,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25741,
        latitude: 49.822898,
        longitude: 14.624388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Bukovany_%28Bene%C5%A1ov_District%29_CoA.jpg"
    },
    {
        obec: "Bukovany",
        kod: 564664,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.574617,
        longitude: 14.10264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Bukovany-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký třetinu délky listu a bílé pole s modrým džbánem uchem k vlajícímu okraji se vztyčeným žlutým bukovým listem. V žerďovém pruhu bílá čtyřpatrová věž se soklem, se čtyřmi okny nad sebou, zakončená červenou bání s lucernou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně vpravo polcený štít. Vpravo stříbrná čtyřpatrová věž se soklem, se čtyřmi okny nad sebou, zakončená červenou bání s lucernou, vlevo modrý džbán se vztyčeným zlatým bukovým listem."
    },
    {
        obec: "Bukovec",
        kod: 511935,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73984,
        latitude: 49.556686,
        longitude: 18.821553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Bukovec_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová a žlutá vlající část, v modrém poli polovina žluté orlice s červenou zbrojí spojená s polovinou zelené bukové ratolesti o půl a dvou listech ve žlutém poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít, vpravo vyniká půl zlaté orlice s červenou zbrojí, vlevo půl zelené bukové ratolesti s jedním polovičním a dvěma celými listy."
    },
    {
        obec: "Bukovec",
        kod: 553506,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.586461,
        longitude: 12.995414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Bukovec_%28Doma%C5%BElice_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, v poměru 3 : 7. Horní pruh v žerďové polovině červený, ve vlající polovině bílo-červeno-bíle dělený. Dolní pruh žlutý se vztyčeným zeleným bukovým trojlistem se dvěma svěšenými odvrácenými bukvicemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod červeno-stříbrně polcenou hlavou, vlevo s červeným břevnem, vztyčený zelený bukový trojlist se dvěma svěšenými odvrácenými bukvicemi."
    },
    {
        obec: "Bukovice",
        kod: 581437,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.406829,
        longitude: 16.489825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Bukovice_%28Brno-Country%29_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 3:1:3. V žerďové polovině horního bílého pruhu dva odvrácené zelené stvoly prvosenky jarní, každý se třemi žlutými květy, pod nimi v dolním bílém pruhu tři vztyčené, dole spojené bukové listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré vlnité břevno provázené nahoře dvěma odvrácenými zelenými stvoly prvosenek jarních, každý se třemi zlatými květy, dole třemi vztyčenými a spojenými zelenými bukovými listy."
    },
    {
        obec: "Bukovice",
        kod: 547751,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54954,
        latitude: 50.549647,
        longitude: 16.228038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Bukovice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vykořeněným bukem s kmenem provlečeným žlutou korunou a červenou růží se žlutým semeníkem a zelenými kališními lístky v koruně buku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vykořeněný buk s kmenem provlečeným zlatou korunou a s červenou růží se zlatým semeníkem a zelenými kališními lístky v koruně buku."
    },
    {
        obec: "Bukovina",
        kod: 581445,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67905,
        latitude: 49.297608,
        longitude: 16.773268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Bukovina_%28Blansko%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem na vlajícím okraji listu překrytý červeným žerďovým klínem s vrcholem v polovině délky listu, a žlutým žerďovým klínem s vrcholem ve čtvrtině délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zlatého trojvrší jednorožec držící vztyčené krojidlo, obojí stříbrné. V trojvrší vztyčený červený bukový list mezi dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Bukovina nad Labem",
        kod: 574813,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.12496,
        longitude: 15.823385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Bukovina_nad_Labem_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 2:1. V žerďové části modrého pruhu žlutý vztyčený bukový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zlaté paty zlatý buk."
    },
    {
        obec: "Bukovina u Čisté",
        kod: 577031,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.542694,
        longitude: 15.58394,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Bukovina_u_%C4%8Cist%C3%A9_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list. V horním žerďovém poli vyrůstající žlutý lev s červenou zbrojí, v dolním žerďovém poli vztyčená zelená buková větev se třemi listy a dvěma červenými bukvicemi mezi nimi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře vyrůstající zlatý lev s červenou zbrojí, dole vztyčená zelená buková větev se třemi listy a dvěma červenými bukvicemi mezi nimi."
    },
    {
        obec: "Bukovina u Přelouče",
        kod: 574821,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.948338,
        longitude: 15.562977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Bukovina_u_P%C5%99elou%C4%8De_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 1 : 2. V žerďové plovině zeleného pruhu žlutý bukový list řapíkem k žerďovému okraji, pod ním ve žlutém pruhu černé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou hlavou s doleva položeným zlatým bukovým listem černé mlýnské kolo."
    },
    {
        obec: "Bukovinka",
        kod: 581453,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67905,
        latitude: 49.294279,
        longitude: 16.805039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Bukovinka_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vlajícím klínem s vrcholem v polovině délky listu. V žerďové polovině tři žluté žaludy stopkami k sobě - horní vztyčený, prostřední položený a blíže k žerdi, dolní svěšený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě položená uťatá dubová větev se třemi vyrůstajícími žaludy, prostředním vztyčeným a dvěma odvrácenými, pod nimi dva odkloněné listy, vše zlaté."
    },
    {
        obec: "Bukovka",
        kod: 574830,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.101131,
        longitude: 15.625408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Bukovka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílými propletenými beraními rohy a zelený s čelně postavenou žlutou radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně šikmo děleném štítě nahoře propletené stříbrné beraní rohy, dole čelně postavená zlatá radlice."
    },
    {
        obec: "Bukovník",
        kod: 530085,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.222588,
        longitude: 13.661427,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Bukovn%C3%ADk_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed žlutý buk, kmen provázen třemi (2, 1) bílými tlapatými kříži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý buk, kmen provázen třemi (2, 1) stříbrnými tlapatými kříži."
    },
    {
        obec: "Bukovno",
        kod: 535605,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.446863,
        longitude: 14.840687
    },
    {
        obec: "Bukvice",
        kod: 573353,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.412586,
        longitude: 15.294637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Bukvice_%28okres_Ji%C4%8D%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma vztyčenými žlutými rozevřenými bukvicemi kosmo nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvě vztyčené zlaté rozevřené bukvice kosmo nad sebou."
    },
    {
        obec: "Bulhary",
        kod: 584380,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69189,
        latitude: 48.831565,
        longitude: 16.748843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Coat_of_arms_of_Bulhary.jpeg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed zkřížené bílé parmy, šikmá přes kosmou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné parmy, šikmá přes kosmou."
    },
    {
        obec: "Bulovka",
        kod: 563935,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.971244,
        longitude: 15.12367,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Bulovka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, modrý, žlutý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo stříbrný jelení paroh se třemi lipovými listy vyrůstajícími z výsad, vlevo vztyčený červený hořící plamenný meč."
    },
    {
        obec: "Buřenice",
        kod: 561312,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.552815,
        longitude: 15.066329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Coat_of_arms_of_Bu%C5%99enice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý. Uprostřed bílé zkřížené meče se žlutými jílci hroty nahoru. V horní části listu bílá kniha ve žlutých deskách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě zkřížené vztyčené stříbrné meče se zlatými jílci. Nad křížením rozevřená stříbrná kniha ve zlatých deskách."
    },
    {
        obec: "Buš",
        kod: 540048,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25208,
        latitude: 49.80341,
        longitude: 14.384043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Bus_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý. Uprostřed žlutá polygonální kaple na bílém soklu, s obloukovými dveřmi a střechou s lucernou, obojí černé, provázená v horní části v zeleného pruhu kosmým dubovým listem, v horní části modrého pruhu šikmým lipovým listem, obojí vztyčené a žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře polceném štítě zlatá polygonální kaple na stříbrném soklu, s obloukovými dveřmi a střechou s lucernou, vše černé. Nahoře je kaple provázena kosmým dubovým a šikmým lipovým listem, oba vztyčené a zlaté."
    },
    {
        obec: "Bušanovice",
        kod: 550159,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.115542,
        longitude: 13.934788,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Bu%C5%A1anovice_CoA.jpg"
    },
    {
        obec: "Bušín",
        kod: 532894,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78962,
        latitude: 49.976227,
        longitude: 16.837978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Bu%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý. V zeleném pruhu žlutý jehličnatý strom s kmenem přeloženým obrácenou bílou podkovou, v červeném pruhu bílá hvězda, v bílém pruhu zelená hvězda, obě šesticípé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleno-stříbrně polcený štít. V prostředním poli zlatý jehličnatý strom s kmenem přeloženým převrácenou stříbrnou podkovou. V pravém poli stříbrná a v levém poli zelená hvězda."
    },
    {
        obec: "Bušovice",
        kod: 559750,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.795629,
        longitude: 13.534766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Busovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, červený, bílý, žlutý a bílý, v poměru 1:1:4:1:1. V prostředním tři červené spojené obilné klasy se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zlatě sníženě děleným klínem zeleno-červeně polcený štít. Do stříbrného pole vyrůstají tři červené obilné klasy."
    },
    {
        obec: "Buštěhrad",
        kod: 532169,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27343,
        latitude: 50.156043,
        longitude: 14.189083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Bu%C5%A1t%C4%9Bhrad_znak.png"
    },
    {
        obec: "Butoves",
        kod: 549282,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.386551,
        longitude: 15.427515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Butoves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, pilovitý zelený se šesti zuby a žlutý, v poměru 2 : 5 : 1. V zeleném pruhu žluté železniční kolo mezi dvěma bílými křídly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě mezi stříbrnou pilovitou hlavou a zlatou palisádovou hradbou zlaté železniční kolo mezi dvěma stříbrnými křídly."
    },
    {
        obec: "Buzice",
        kod: 529966,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.42273,
        longitude: 13.932715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Buzice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červeno-černě polcený, černo-bíle polcený a žlutý, v poměru 1 : 1 : 3. Ve žlutém pruhu černá kančí hlava s bílou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá kančí hlava se stříbrnou zbrojí a červeným jazykem pod červeno-černo-černo-stříbrně čtvrceným praporcem s červeným ocasem a vpravo vyrůstající modrou žerdí."
    },
    {
        obec: "Býčkovice",
        kod: 546780,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.559879,
        longitude: 14.213625,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/B%C3%BD%C4%8Dkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1 : 2 : 5. V červeném pruhu bílý trojkříž s rameny vycházejícími z okrajů červeného pruhu, v něm černý středový kříž s rameny širokými třetinu šířky bílého ramene. Ve vlajícím žlutém pruhu červená býčí hlava s černými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zlatě pilovitě dělený štít. Nahoře stříbrný vodorovný trojramenný heroldský kříž, v něm černý heroldský kříž. Dole červená býčí hlava s černými rohy."
    },
    {
        obec: "Býchory",
        kod: 533238,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.067727,
        longitude: 15.273572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/B%C3%BDchory_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed bílá lilie nad šikmo položenými houslemi s černým hmatníkem, kosmo podloženými smyčcem, obojí žluté. V horním rohu a horním cípu po jedné žluté šesticípé hvězdě. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie provázená dvěma zlatými hvězdami, pod ní šikmo položené housle s černým hmatníkem kosmo podložené smyčcem, obojí zlaté."
    },
    {
        obec: "Býkev",
        kod: 534714,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27601,
        latitude: 50.347271,
        longitude: 14.418129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/B%C3%BDkev_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed polcená hlava, v modrém pruhu žlutá býčí s bílým rohem a polovinou jazyka, v červeném bílá zubří se žlutým rohem, polovinou jazyka a polovinou houžve. Hlava dole provázená vztyčenými odvrácenými žlutými lipovými listy a pod nimi bílou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě polcená hlava, vpravo zlatá býčí se stříbrným rohem a polovinou jazyka, vlevo stříbrná zubří se zlatým rohem, polovinou jazyka a polovinou houžve. Hlava dole provázená vztyčenými odvrácenými zlatými lipovými listy a pod nimi stříbrnou hvězdou."
    },
    {
        obec: "Bykoš",
        kod: 534145,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26701,
        latitude: 49.883057,
        longitude: 14.063765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Bykos_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve středu listu a dva vodorovné pruhy, zelený a žlutý. V zeleném pruhu červená, žlutě lemovaná knížecí čepice, ve žlutém pruhu zelený vztyčený žalud se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze zlatého návrší s vyrůstajícím vztyčeným zeleným žaludem a dvěma listy vyrůstají dvě stříbrná lekna, mezi nimi červená, zlatě lemovaná knížecí čepice."
    },
    {
        obec: "Býkovice",
        kod: 581461,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.430303,
        longitude: 16.538385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Bykovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se svislým žlutým zubatým pruhem se třemi čtvercovými zuby k žerdi, vycházejícím z třetí dvanáctiny horního a dolního okraje listu. Mezery mezi zuby se rovnají zubům, mezery mezi zuby a okraji listu jsou dvojnásobné. Ve střední a vlající části listu žlutý kráčející býk s kruhem v nozdrách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zlato-červeně cimbuřovitě dělenou patou kráčející zlatý býk s kruhem v nozdrách."
    },
    {
        obec: "Býkov-Láryšov",
        kod: 569607,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79401,
        latitude: 50.052449,
        longitude: 17.687486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/B%C3%BDkov-L%C3%A1ry%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1:2:1. V modrém pruhu žlutá býčí hlava s krkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou býčí hlavou s krkem zkřížené stříbrné cepy přeložené zlatou sekerou."
    },
    {
        obec: "Bylany",
        kod: 571245,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53801,
        latitude: 49.957375,
        longitude: 15.733261,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Bylany_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílo-červeně šikmo dělené pole a svislý vlající černo-žlutě šachovaný (5x2) pruh. V bílo-červeném poli černý kráčející býk se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Sníženě dělený štít, v horním stříbrno-červeně šikmo děleném poli černý kráčející býk se zlatou zbrojí a červeným jazykem, dolní pole černo-zlatě dvěma řadami šachované."
    },
    {
        obec: "Bynovec",
        kod: 544647,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.820934,
        longitude: 14.264287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Bynovec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, v poměru 2 : 3, zelený a pilovitý žlutý s třinácti zuby, krajní poloviční. V zeleném pruhu knížecí koruna, ve žlutém tři (2, 1) včely. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zvýšeně zeleno-zlatě pilovitě děleném štítě nahoře knížecí koruna, dole tři (2, 1) včely přirozené barvy."
    },
    {
        obec: "Bystrá",
        kod: 561801,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.50857,
        longitude: 15.371288
    },
    {
        obec: "Bystrá nad Jizerou",
        kod: 577049,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.604834,
        longitude: 15.401976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Bystra_nad_Jizerou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý s bílou devíticípou fasetovanou hvězdou, v poměru 3 : 1. Do bílého pruhu vyrůstá zelená borovice s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě borovice přirozené barvy vyrůstající z modré vlnité paty se stříbrnou devítihrotou fasetovanou hvězdou."
    },
    {
        obec: "Bystré",
        kod: 576166,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.321556,
        longitude: 16.255333,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Bystre_RK_CZ_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutým pruhem na dolním okraji, širokým čtvrtinu šířky listu, ve vlající polovině zvlněným se třemi vrcholy a dvěma prohlubněmi. V žerďové polovině zeleného pole žluté průčelí kostela s červenými dveřmi a třemi červenými obloukovými okny vedle sebe, s červenou střechou, z níž uprostřed vyniká žlutá věž s červeným obloukovým oknem a červenou střechou zakončenou křížkem. Pod ním ve žlutém pruhu vodorovně modrý tkalcovský člunek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou vlnitou patou s položeným modrým tkalcovským člunkem zlaté průčelí kostela s červenými dveřmi a třemi červenými obloukovými okny vedle sebe, s červenou střechou, z níž uprostřed vyniká zlatá věž s červeným obloukovým oknem a červenou střechou zakončenou křížem."
    },
    {
        obec: "Bystré",
        kod: 577928,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56992,
        latitude: 49.628557,
        longitude: 16.346893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Znak_m%C4%9Bsta_Bystr%C3%A9.png",
        coatOfArmsFlagDescription: "Červený list se třemi svislými vlnitými pruhy: černým, červeným a žlutým, z nichž každý má jeden vrchol a jednu prohlubeň. Poměr všech polí na horním okraji listu je 3:2:1:2:7, na dolním okraji 4:2:1:2:6. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "barvy štítu z modré na červenou"
    },
    {
        obec: "Bystročice",
        kod: 500879,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.536347,
        longitude: 17.193626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Bystro%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List čtvrcený bílým ondřejským křížem s rameny širokými jednu pětinu šířky listu. Žerďové a vlající pole červené se žlutou šesticípou hvězdou, horní a dolní pole zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně kosmo čtvrceném štítě stříbrný kříž přeložený zlatou berlou, provázenou dvěma zlatými hvězdami."
    },
    {
        obec: "Bystrovany",
        kod: 547026,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.597116,
        longitude: 17.323754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Bystrovany_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený a modrý, vlnovitě oddělené třemi celými a dvěma polovičními modrými oblouky, výška vln je jedna devítina šířky listu. Uprostřed ve vzdálenosti jedné čtvrtiny délky listu od žerďového a vlajícího okraje listu šikmo položený bílý meč se žlutým jílcem a záštitou, zkřížený se žlutou berlou s vlajícím bílým sudariem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě šikmo vztyčený stříbrný meč se zlatým jílcem, kosmo přeložený zlatou berlou se stříbrným sudariem."
    },
    {
        obec: "Bystřany",
        kod: 567477,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41761,
        latitude: 50.628776,
        longitude: 13.8643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/CoA_of_Byst%C5%99any.svg",
        coatOfArmsFlagDescription: "List tvoří žerďová část s bílým karé nad červeným čtvercovým polem a dva vodorovné pruhy: červený a bílý, v karé červený zkrácený berličkový kříž se žlutým gotickým štítkem se vztyčenými černými hráběmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na červeném volném berličkovém kříži zlatý gotický štítek s černými vztyčenými hráběmi."
    },
    {
        obec: "Bystřec",
        kod: 579971,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56154,
        latitude: 50.011781,
        longitude: 16.619105,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Byst%C5%99ec.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený v poměru 1:3:1. V modrém poli žlutá koňská hlava s krkem, červenou ohlávkou a uzdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně polcená věž s cimbuřím a velkým prolomeným obloukovým oknem. V něm zlatá koňská hlava s krkem, červenou ohlávkou a uzdou. Věž provázená dvěma zlatými slunci."
    },
    {
        obec: "Bystřice",
        kod: 572772,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.392983,
        longitude: 15.244218,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Byst%C5%99ice_%28JC%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým bílým pruhem širokým šestinu šířky listu na zelené žerďové a modré vlající pole. V zeleném poli bílé mlýnské kolo s devíti lopatkami, v modrém kosmo žlutý keltský meč hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně kosmým stříbrným vlnitým břevnem dělený štít. Nahoře postavený zlatý keltský meč, dole stříbrné mlýnské kolo s devíti lopatkami."
    },
    {
        obec: "Bystřice",
        kod: 598062,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73995,
        latitude: 49.63667,
        longitude: 18.720488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Byst%C5%99ice_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole s polovinou žluté orlice u středového okraje a vlající bílé pole s modrým krojidlem hrotem dolů u středu listu. V jedné pětině šířky listu od dolního okraje vlnkovitý vodorovný proužek, na modrém poli bílý, na bílém modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít, vpravo vyniká půl zlaté orlice, vlevo modré krojidlo hrotem dolů, v patě štítu tři vlnkovitá zúžená břevna střídavých barev."
    },
    {
        obec: "Bystřice",
        kod: 529451,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25751,
        latitude: 49.732235,
        longitude: 14.667506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Byst%C5%99ice_%28BN%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 : 1. Na zeleném pruhu vůz, v něm beranidlo s hrotem, vše žluté. Z vozu vyrůstá pět modrých pomněnek se žlutými středy na odkloněných zelených stoncích s listy. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Bystřice nad Pernštejnem",
        kod: 595411,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.523046,
        longitude: 16.261571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Znak_m%C4%9Bsta_Byst%C5%99ice_nad_Pern%C5%A1tejnem.png",
        coatOfArmsFlagDescription: "Žlutý list s modrým svislým středovým pruhem širokým jednu osminu délky listu ke kterému přiléhají v žerďové části polovina černé zubří hlavy se žlutou houžví a červeným jazykem a ve vlající části polovina černého orla s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý kůl, vpravo přiléhají černá polovina zubří hlavy se zlatou houžví a červeným jazykem, vlevo černá polovina orla s červenou zbrojí."
    },
    {
        obec: "Bystřice pod Hostýnem",
        kod: 588393,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.399337,
        longitude: 17.674121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Bystrice_pod_Hostynem-znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - červený, černý, bílý, černý a bílý v poměru 4:1:1:1:1. Ve středu červeného pruhu bílý vyrůstající kozel z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Bystřice pod Lopeníkem",
        kod: 592111,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68755,
        latitude: 48.974921,
        longitude: 17.764159,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Byst%C5%99ice_pod_Lopen%C3%ADkem_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:3:1. V modrém pruhu žlutý dravý pták s červenou zbrojí a žlutým jazykem stojící na bílé šavli hrotem k žerdi s červeným jílcem a žlutou záštitou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý dravý pták s červenou zbrojí a zlatým jazykem, obklopený třemi stříbrnými šavlemi s červenými jílci a zlatými záštitami."
    },
    {
        obec: "Bystřička",
        kod: 541711,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75624,
        latitude: 49.415411,
        longitude: 17.974099,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/CZ_Bystricka_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 2:1:1. V žerďové polovině zeleného pruhu zkřížené žluté lopaty - karbovačky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené zlaté lopaty - karbovačky nad stříbrnou kvádrovanou zdí vyrůstající z modré vlnité paty."
    },
    {
        obec: "Byšice",
        kod: 534722,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27732,
        latitude: 50.310544,
        longitude: 14.61147,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/By%C5%A1ice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 7 : 3. V bílém pruhu u žerďového okraje černá korunovaná orlice se žlutou zbrojí a korunou na krku. Orlice má každé křídlo přeloženo červenou věží s rozšířeným soklem, s černou valbovou střechou se dvěma žlutými makovicemi. Věž má vysokou obloukovou branku pod oknem a podsebitím se třemi obdelníkovými střílnami, vše černé, branka a okno bíle lemované. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Býškovice",
        kod: 512877,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.467566,
        longitude: 17.714164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/CoA_of_B%C3%BD%C5%A1kovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva zelené pruhy, mezi nimi kosmo dělené pole s horním žlutým a dolním červeným polem, přes ně je položen bílý sloup. Šíře zeleného pruhu je 1/4 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný sloup mezi dvěma zlatými obilnými klasy, na středu sloupu položen rodový dietrichštejnský štítek."
    },
    {
        obec: "Býšovec",
        kod: 595420,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.475308,
        longitude: 16.289398,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/B%C3%BD%C5%A1ovec%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, zelený, červený a žlutý, v poměru 1 : 2 : 2 : 1. Ze žlutého pruhu vyniká přes červený pruh do zeleného žlutě zdobené bílé obrněné pravé rameno, držící v drátěné rukavici žlutý smrk. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě zleva vynikající stříbrné zlatě zdobené obrněné pravé rameno držící v drátěné rukavici zlatý smrk."
    },
    {
        obec: "Býšť",
        kod: 574848,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53322,
        latitude: 50.132532,
        longitude: 15.911265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Coat_of_Arms_of_village_Byst.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným středovým křížem s rameny širokými jednu pětinu šířky listu, uprostřed listu černá zubří hlava se žlutými rohy, jazykem a houžví. Kolem hlavy na ramenech kříže po jedné žluté osmicípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na červeném heroldském kříži se čtyřmi zlatými osmihrotými hvězdami černá zubří hlava se zlatými rohy, jazykem a houžví."
    },
    {
        obec: "Byzhradec",
        kod: 576174,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.215939,
        longitude: 16.1903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Byzhradec_CoA.jpg"
    },
    {
        obec: "Bzová",
        kod: 531120,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26743,
        latitude: 49.897991,
        longitude: 13.862507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Bzov%C3%A1_okr_Beroun_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V žerďové části červeného pruhu sedící bílý pes se žlutým jazykem a obojkem. V žerďové části bílého pruhu stonek bezu černého se dvěma listy a plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře sedící stříbrný pes se zlatým jazykem a obojkem, dole vztyčený stonek bezu černého se dvěma listy a plody přirozené barvy."
    },
    {
        obec: "Bzenec",
        kod: 586081,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69681,
        latitude: 48.973463,
        longitude: 17.266959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Bzenec_%28znak%29.png",
        coatOfArmsFlagDescription: "List dělený bílým kosmým pruhem vycházejícím z první čtvrtiny horního okraje do čtvrté a páté osminy dolního okraje na žerďové modré pole a vlající červené pole s modrým klínem vycházejícím z druhé poloviny horního okraje a dosahujícím do druhé třetiny šířky listu a s bílým kosmým liliovým žezlem u bílého pruhu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Bžany",
        kod: 567485,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.589151,
        longitude: 13.879624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Coat_of_arms_of_B%C5%BEany.svg",
        coatOfArmsFlagDescription: "Červeno-bíle štenýřovitě dělený list. Uprostřed je do štenýře vepsaný modrý obdélník, široký tři pětiny šířky listu, se žlutou hlavou kohouta s červeným zobákem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítku na stříbrno-červeném štenýři zlatá hlava kohouta s červenou zbrojí."
    },
    {
        obec: "Cebiv",
        kod: 560782,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34952,
        latitude: 49.829449,
        longitude: 12.979175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Cebiv_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s polovinou červeného korunovaného lva držícího černý paroh. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vynikající polovina červeného korunovaného lva držícího černý paroh."
    },
    {
        obec: "Cehnice",
        kod: 550906,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38752,
        latitude: 49.215169,
        longitude: 14.029493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Znak_obce_Cehnice.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:4:1. V modrém pruhu bíle oděný vousatý zbrojnoš s červenou kápí, botami a opaskem se žlutou přezkou, držící v pravici vztyčené bílé kopí na žlutém ratišti. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrně oděný vousatý zbrojnoš s červenou kápí, botami a opaskem se zlatou přezkou, držící v pravici vztyčené stříbrné kopí na zlatém ratišti."
    },
    {
        obec: "Cejle",
        kod: 586978,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.3694,
        longitude: 15.472878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Cejle_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený, v poměru 5 : 1 : 4. V horním pruhu u žerďového okraje žlutý latinský kříž se středem přeloženým bílou perlou a dolním ramenem zakončeným hrotem. V dolním pruhu u žerďového okraje bílé mezikruží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě děleném sníženým zúženým zlatým břevnem nahoře mezi dvěma stříbrnými listnatými stromy se zlatými kmeny zlatý latinský kříž se středem přeloženým stříbrnou perlou a dolním ramenem zakončeným hrotem. Dole stříbrný mlýnský kámen."
    },
    {
        obec: "Cekov",
        kod: 559768,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.821968,
        longitude: 13.763901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Cekov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Ve střední části listu žlutá podkova převýšená bílým lososem. Uprostřed podkovy žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatá podkova převýšená stříbrným lososem. Uprostřed podkovy zlatý zvon."
    },
    {
        obec: "Cep",
        kod: 509191,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 48.919602,
        longitude: 14.807457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Cep_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. Do červeného pruhu vyrůstá bílá orlice se žlutou zbrojí, v modrém pruhu čelně žlutá radlice hrotem k hornímu okraji, nahoře podložená bílým krojidlem ostřím dolů a hrotem k vlajícímu okraji. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě, nahoře vyrůstající stříbrná orlice se zlatou zbrojí, dole čelně vztyčená zlatá radlice nahoře podložená doleva položeným stříbrným krojidlem."
    },
    {
        obec: "Cerekvice nad Bystřicí",
        kod: 572781,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50777,
        latitude: 50.333348,
        longitude: 15.723633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Cerekvice_nad_Bystrici_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílým mečem hrotem nahoru a modrý se žlutým roštem držadlem dolů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrný meč, vlevo zlatý rošt, obojí vztyčené."
    },
    {
        obec: "Cerekvice nad Loučnou",
        kod: 577936,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56953,
        latitude: 49.898991,
        longitude: 16.215295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Cerekvice_nad_Lou%C4%8Dnou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 2:1:2. V bílém pruhu bílé kruhové pole o průměru jedné poloviny šířky listu se středem v první třetině délky listu. V kruhovém poli červená, žlutě zdobená knížecí koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Šikmým bílým břevnem červeno-modře dělený štít, nahoře ve stříbrném kotouči knížecí koruna, dole stříbrná lilie."
    },
    {
        obec: "Cerekvička-Rosice",
        kod: 586986,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.334875,
        longitude: 15.587278,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Cerekvi%C4%8Dka-Rosice_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým vlnitým svislým pruhem širokým dvanáctinu délky listu na žerďové červené a vlající modré pole. V červeném poli tlapatý kříž, v modrém lilie, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře stříbrným vlnitým břevnem dělený štít. Nahoře tlapatý kříž, dole lilie, obojí zlaté."
    },
    {
        obec: "Cerhenice",
        kod: 533246,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28102,
        latitude: 50.071376,
        longitude: 15.07214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Cerhenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, žlutý a červený. V modrém pruhu bílý čelně stojící zbrojnoš opírající se o meč. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo zlatá gotická litera C, vlevo stříbrný stojící zbrojnoš opírající se o meč."
    },
    {
        obec: "Cerhonice",
        kod: 549321,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.418097,
        longitude: 14.055739
    },
    {
        obec: "Cerhovice",
        kod: 531138,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26761,
        latitude: 49.849643,
        longitude: 13.834934,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Cerhovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červeno-bíle čtvrcený a černý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Cetechovice",
        kod: 588407,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76802,
        latitude: 49.172391,
        longitude: 17.261484,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Cetechovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a bílý, v poměru 3 : 10 : 3. Uprostřed zeleného pruhu kosmo položená dolní polovina zlomeného žlutého antického sloupu šikmo podložená bílým kamenickým kladivem na žlutém topůrku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě listnatý strom přirozené barvy vyrůstající ze zeleného trojvrší. V prostředním vrchu kosmo položená dolní polovina zlomeného zlatého antického sloupu šikmo podložená stříbrným kamenickým kladivem na zlatém topůrku."
    },
    {
        obec: "Cetenov",
        kod: 563943,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46348,
        latitude: 50.644523,
        longitude: 14.917705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Cetenov_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list, z dolního okraje vyrůstá zelená lípa, u horního okraje listu vodorovně červený řetěz vynikající z okrajů listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstající zelená lípa převýšená červeným řetězem vynikajícím z okrajů štítu."
    },
    {
        obec: "Cetkovice",
        kod: 581470,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67938,
        latitude: 49.578884,
        longitude: 16.721351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Cetkovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým železným kloboukem se třemi pštrosími pery, střední bílé, krajní červená a žlutým podbradníkem. Po stranách klobouku v žerďové a vlající části po jedné žluté šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný železný klobouk se třemi pštrosími pery, červeným, stříbrným, červeným a zlatou šňůrou, provázený dvěma zlatými hvězdami."
    },
    {
        obec: "Cetoraz",
        kod: 547662,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39411,
        latitude: 49.455335,
        longitude: 14.957412,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Cetoraz_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1 : 1 : 12 : 1 : 1. V modrém pruhu červená knížecí čepice, ozdobená hermelínem a žlutým jablkem s křížkem, provázená dole do oblouku devíti částečně se překrývajícími žlutými mincemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zvýšenou stříbrnou patou červená knížecí čepice, ozdobená hermelínem a zlatým jablkem s křížkem, provázená dole do oblouku devíti částečně se překrývajícími zlatými mincemi. V patě tři (2, 1) červené růže se zlatými semeníky."
    },
    {
        obec: "Cetyně",
        kod: 564419,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.599728,
        longitude: 14.121455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Cetyn%C4%9B_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a červené, v poměru 9 : 1 : 1 : 5. V horním pruhu dva odkloněné vztyčené zelené kaštanové listy. Druhý a třetí pruh vlnitý s pěti vrcholy a čtyřmi prohlubněmi. V dolním zvlněném pruhu žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě vlnitým červeno-stříbrně vlnitě děleným břevnem nahoře dva odkloněné vztyčené zelené kaštanové listy, dole zlatá koruna."
    },
    {
        obec: "Církvice",
        kod: 533998,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28533,
        latitude: 49.945703,
        longitude: 15.335153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Cirkvice_KH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma šikmými pruhy: žlutým a červeným, každým o šířce jedné čtvrtiny šířky listu. Dělící linii mezi pruhy tvoří úhlopříčka. V modré žerďové a vlající části po bílé mušli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červený hrot se zlatým postaveným roštem, provázený stříbrnými mušlemi."
    },
    {
        obec: "Cidlina",
        kod: 590428,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.128295,
        longitude: 15.735638,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Cidlina_%28T%C5%99eb%C3%AD%C4%8D%29_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a modré pole se třemi bílými vztyčenými lipovými listy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě věž bez oken a dveří, se stanovou střechou, na ní dva zkřížené šípy s rozeklanými konci, z nichž vyrůstá žerď s trojúhelníkovým praporkem. Ze základu vyrůstají do stran tři lipové listy na prohnutých stoncích, vše stříbrné."
    },
    {
        obec: "Církvice",
        kod: 599476,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28144,
        latitude: 49.909456,
        longitude: 15.015818,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/C%C3%ADrkvice_%28okres_Kol%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým bílým čtvercovým polem a dva vodorovné pruhy, bílý a červený. V karé dva zkřížené bílé meče hroty nahoru se žlutými jílci nad bílou srubovou kaplí se zavřenými dveřmi na černých závěsech se žlutým křížem na vrcholu lomeného štítu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dva zkřížené stříbrné meče se zlatými jílci nad stříbrnou srubovou kaplí se zavřenými dveřmi na černých závěsech a zlatým křížem na vrcholu lomeného štítu."
    },
    {
        obec: "Cotkytle",
        kod: 579980,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56132,
        latitude: 49.936282,
        longitude: 16.72414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Cotkytle.svg",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové pole bílé s červeným srdcem kvetoucím třemi modrými květy se žlutými středy na zelených stoncích. Dolní žerďové pole modré, horní vlající pole zelené a dolní vlající pole bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Čtvrcený štít, v prvním stříbrném poli červené srdce kvetoucí třemi modrými květy se zlatými středy na zelených stoncích, ve druhém zeleném poli zlatý květ mezi vztyčenou kosou bez kosiště a doleva obrácenou radlicí, obojí stříbrné, ve třetím modrém poli na zeleném trojvrší zlatý rozdvojený pahýl stromu se dvěma svěšenými listy, ve čtvrtém stříbrném poli červený heroldský kříž."
    },
    {
        obec: "Císařov",
        kod: 569135,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.466135,
        longitude: 17.352711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Znak_obce_C%C3%ADsa%C5%99ov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený se žlutou korunou a zelený, v poměru 3:2. K červeném pruhu přiléhá v zeleném půl bílého liliového kříže s vrcholy ramen na okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře zlatá koruna, dole polovina stříbrného liliového kříže přiléhající k červenému poli."
    },
    {
        obec: "Crhov",
        kod: 554162,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.545112,
        longitude: 16.434827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Crhov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, černý oboustranně zubatý posunutý a žlutý, v poměru 11:1:3:3. V modrém pruhu žlutý patriarší kříž mezi dvěma odkloněnými bílými přirozenými liliemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě sníženě děleném štítě nahoře zlatý patriarší kříž provázený dvěma odkloněnými stříbrnými přirozenými liliemi, dole černé cimbuřové břevno."
    },
    {
        obec: "Citov",
        kod: 512982,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.467819,
        longitude: 17.325561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Znak_obce_Citov.gif",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a zelené pole. V bílém pruhu červený středový kříž s rameny širokými jednu desetinu délky listu. V zeleném poli žlutý lev s červenou zbrojí vyrůstající z poloviny bílého mlýnského kola. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě z dolní poloviny stříbrného mlýnského kola vyrůstající zlatý lev s červenou zbrojí, držící stříbrný štítek s červeným heroldským křížem."
    },
    {
        obec: "Citice",
        kod: 560324,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.162671,
        longitude: 12.61344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/CoA_of_Citice.svg",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy - zelený a černý. Ve středu zeleného pruhu je žluté okřídlené železniční kolo, ve středu černého žlutá zkřížená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-černě dělený štít, v horním poli okřídlené železniční kolo, v dolním zkřížená hornická kladívka, obojí zlaté."
    },
    {
        obec: "Cikháj",
        kod: 588016,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59102,
        latitude: 49.644947,
        longitude: 15.96749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Cikh%C3%A1j_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy modrý a zelený, v poměru 4 : 1. Do modrého pruhu vyrůstá bílá jedle se žlutým podrostem provázená nahoře dvěma bílými květy přirozené lilie se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrná jedle se zlatým podrostem provázená nahoře dvěma stříbrnými květy přirozené lilie se zlatým středem."
    },
    {
        obec: "Cítov",
        kod: 534731,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27704,
        latitude: 50.372354,
        longitude: 14.398243,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/C%C3%ADtov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu vzpřímený bílý jednorožec se žlutou zbrojí provázený čelně hledící žlutou lví hlavou pod předníma nohama. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vzpřímený stříbrný jednorožec se zlatou zbrojí provázený vpravo čelně hledící zlatou lví hlavou."
    },
    {
        obec: "Ctiboř",
        kod: 579459,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.82733,
        longitude: 12.61012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Ctibo%C5%99_%28okres_Tachov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed bílého pruhu tři modré rovnoramenné trojúhelníky se základnami na horním okraji červeného pruhu, širokými jednu šestinu délky listu, a s vrcholy v první osmině šířky listu. V červeném pruhu srdce hořící třemi plameny, jedním nahoru do prostředního modrého trojúhelníku a dvěma do stran, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě volná stříbrná kvádrovaná hradba s pěti stínkami, v ní tři modré hroty. Z hradby vyniká stříbrná okrouhlá věž se třemi černými okny vedle sebe a modrou kuželovou střechou, provázená dvěma přivrácenými stříbrnými korunovanými labutěmi se zlatou zbrojí. Dole srdce hořící třemi plameny, jedním nahoru do středního modrého hrotu, dvěma do stran, vše zlaté."
    },
    {
        obec: "Ctětín",
        kod: 571253,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.830355,
        longitude: 15.839524
    },
    {
        obec: "Cizkrajov",
        kod: 546054,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37881,
        latitude: 49.03065,
        longitude: 15.389754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Cizkrajov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modro - červeně polcený a žlutý, v poměru 1 : 6 : 1. Uprostřed mezi ulomenými bílými parohy se třemi výsadami žluté říšské jablko kvetoucí třemi bílými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě mezi ulomenými stříbrnými parohy se třemi výsadami zlaté říšské jablko kvetoucí třemi stříbrnými liliemi, dole kosmo vztyčený zlatý klíč se zubem s průsekem do tvaru meče."
    },
    {
        obec: "Ctiboř",
        kod: 532690,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.736767,
        longitude: 14.904302,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed bílého pruhu tři modré rovnoramenné trojúhelníky se základnami na horním okraji červeného pruhu, širokými jednu šestinu délky listu, a s vrcholy v první osmině šířky listu. V červeném pruhu srdce hořící třemi plameny, jedním nahoru do prostředního modrého trojúhelníku a dvěma do stran, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě volná stříbrná kvádrovaná hradba s pěti stínkami, v ní tři modré hroty. Z hradby vyniká stříbrná okrouhlá věž se třemi černými okny vedle sebe a modrou kuželovou střechou, provázená dvěma přivrácenými stříbrnými korunovanými labutěmi se zlatou zbrojí. Dole srdce hořící třemi plameny, jedním nahoru do středního modrého hrotu, dvěma do stran, vše zlaté."
    },
    {
        obec: "Cítoliby",
        kod: 542571,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43902,
        latitude: 50.332834,
        longitude: 13.812247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/C%C3%ADtoliby_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed žluté přenosné varhany převýšené bílou mincí s vyraženým českým lvem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté přenosné varhany převýšené stříbrnou mincí s vyraženým českým lvem."
    },
    {
        obec: "Ctidružice",
        kod: 593869,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 48.982616,
        longitude: 15.86009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Ctidruzice_coat_of_arms.svg",
        coatOfArmsFlagDescription: "Žlutý list s modrou šesticípou hvězdou uprostřed mezi dvěma dvakrát překříženými černými krokvemi s vrcholy na středu horního a dolního okraje, které vycházejí ze čtvrté a deváté dvanáctiny žerďového a vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černé zkřížené ostrve, provázené nahoře čelně postavenou radlicí, po stranách a dole pěti hvězdami, vše modré."
    },
    {
        obec: "Ctiměřice",
        kod: 570991,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29446,
        latitude: 50.377891,
        longitude: 14.993465
    },
    {
        obec: "Ctiněves",
        kod: 564672,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.374801,
        longitude: 14.307223,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Ctin%C4%9Bves_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý vztyčený lipový list s jedním plodem."
    },
    {
        obec: "Citonice",
        kod: 593851,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67101,
        latitude: 48.884055,
        longitude: 15.964567,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Citonice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červeno-bíle šachovaný (2x5), modrý se žlutým plamenným mečem hrotem nahoru a bílo-červeně šachovaný (2x5), v poměru 2:3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý plamenný meč mezi dvěma přivrácenými stříbrno-červeně šachovanými křídly."
    },
    {
        obec: "Cvikov",
        kod: 561479,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47154,
        latitude: 50.777293,
        longitude: 14.633019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Cvikov_znak.png",
        coatOfArmsFlagDescription: "Žlutý list se dvěma černými vodorovnými pruhy v poměru 1:1:6:1:1. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Cvrčovice",
        kod: 550272,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69123,
        latitude: 48.993768,
        longitude: 16.514581,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Cvrcovice_znak.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým modrým vlnitým pruhem o třech vrcholech a dvou prohlubních širokým jednu patnáctinu délky listu s bílými lemy širokými jednu třetinu šířky modrého pruhu na žlutou žerďovou a zelenou vlající část. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě listnatý vykořeněný strom přirozené barvy, provázený nahoře dvěma modrými růžemi se stříbrnými semeníky."
    },
    {
        obec: "Cvrčovice",
        kod: 532185,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27341,
        latitude: 50.179896,
        longitude: 14.154895,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Cvr%C4%8Dovice_%28Kladno_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zvlněný černý se třemi vrcholy a dvěma prohlubněmi, v poměru 4 : 1. V modrém pruhu obilný klas mezi dvěma zkříženými klíči zuby nahoru, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad černou vlnitou patou obilný klas mezi dvěma zkříženými klíči zuby nahoru, vše zlaté."
    },
    {
        obec: "Čachotín",
        kod: 568490,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.695303,
        longitude: 15.608696
    },
    {
        obec: "Čachovice",
        kod: 535621,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29443,
        latitude: 50.27557,
        longitude: 14.946032,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/%C4%8Cachovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký čtvrtinu délky listu a tři vodorovné pruhy, červený, bílý a červený, v poměru 5 : 6 : 5. V modrém pruhu dva gotické klíče, žlutý nad bílým, oba zuby dolů a k vlajícímu okraji listu, v bílém pruhu modrý meč hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou hlavou se dvěma doleva položenými gotickými klíči, zlatým a stříbrným, zuby dolů, dvě červené kvádrované věže se soklem a s cimbuřím o třech stínkách, každá s černým oknem, mezi nimi postavený modrý meč."
    },
    {
        obec: "Čachrov",
        kod: 555941,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.265538,
        longitude: 13.302687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/%C4%8Cachrov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý a zelený, v poměru 2 : 1 : 1. V černém pruhu knížecí čepice ozdobená křížkem a bílá třinácticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším s třikrát lomeným stříbrným břevnem černý kůl provázený dvěma vyrůstajícími zelenými smrky s černými kmeny. V kůlu knížecí čepice ozdobená křížkem nad stříbrnou třináctihrotou hvězdou."
    },
    {
        obec: "Čakov",
        kod: 535541,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.980659,
        longitude: 14.307773,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/%C4%8Cakov_%28%C4%8Cesk%C3%A9_Bud%C4%9Bjovice_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, zelený, bílý a zelený, v poměru 7 : 3 : 1 : 1. Do modrého pruhu vyniká bílý kostel s červenými střechami, s věží k žerďovému okraji listu a se sníženým kněžištěm. Věž je zakončena žlutým křížkem a má tři černá okna nad sebou, větší obloukové nad dvěma obdélníkovými. V lodi dvě okna vedle sebe, v kněžišti jedno, všechna černá a oblouková. V horním zeleném pruhu tři žluté zvony vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný kostel s červenými střechami, s věží vpravo a se sníženým kněžištěm. Věž zakončena zlatým křížkem a má tři černá okna nad sebou, větší obloukové nad dvěma obdélníkovými. V lodi dvě okna vedle sebe, v kněžišti jedno, všechna černá a oblouková. V návrší tři zlaté zvony (1, 2) nad stříbrným vlnitým břevnem."
    },
    {
        obec: "Čakov",
        kod: 529478,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25724,
        latitude: 49.825696,
        longitude: 14.836436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/%C4%8Cakov_BN_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě červené a modré. V žerďové polovině listu nad žlutým zvonem podloženým bílou vlčí udicí vynikají z žerďového okraje listu a z vlajícího okraje prvního modrého pruhu bíle oděné přivrácené paže, v červeném pruhu levá, držící bílý čakan se žlutým topůrkem ostřím k žerďovému okraji, a v modrém pruhu pravá, držící žlutou hůl rukojetí k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vynikají z okrajů štítu stříbrně oděné paže držící vpravo stříbrný čakan se zlatým topůrkem a vlevo zlatou hůl s rukojetí. Pod nimi zlatý zvon podložený položenou stříbrnou vlčí udicí."
    },
    {
        obec: "Čaková",
        kod: 552003,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79316,
        latitude: 50.051137,
        longitude: 17.548483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/%C4%8Cakov%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu tři žluté, krajní odvrácené obilné klasy, dole proložené bílým srpem ostřím k dolnímu a žlutou rukojetí k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři, krajní odvrácené, zlaté obilné klasy, dole proložené položeným stříbrným srpem se zlatou rukojetí."
    },
    {
        obec: "Čakovičky",
        kod: 598291,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.231701,
        longitude: 14.531487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/%C4%8Cakovi%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený ostrvový a bílý. Do žlutého pruhu vyniká půl černého orla se žlutou zbrojí a červeným jazykem, do poloviny bílého pruhu vyniká suk k hornímu cípu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Do pravého zlatého pole vyniká půl černého orla se zlatou zbrojí a červeným jazykem. Levé pole červeno - stříbrně polcené, do stříbrného pole vyniká šikmé zkrácené červené břevno."
    },
    {
        obec: "Čankovice",
        kod: 571270,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.963804,
        longitude: 15.937395,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/%C4%8Cankovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu dvě volné bílé věže s cimbuřím, každá s prázdnou bránou s otevřenými černými vraty na žlutých závěsech a s černým gotickým oknem. V bílém pruhu černá vlčí hlava s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít. Nahoře dvě volné stříbrné věže s cimbuřím, každá s prázdnou bránou s otevřenými černými vraty na zlatých závěsech a s černým gotickým oknem. Dole černá vlčí hlava s krkem."
    },
    {
        obec: "Čáslav",
        kod: 534005,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.910995,
        longitude: 15.390894,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/%C4%8C%C3%A1slav_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým dvouocasým lvem se zlatou zbrojí. Lev ve středu listu zaujímá jednu třetinu délky a tři čtvrtiny šířky listu. Poměr šířky praporu k délce je 2:3."
    },
    {
        obec: "Čáslavice",
        kod: 590436,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67524,
        latitude: 49.152316,
        longitude: 15.772506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Caslavice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. Do červeného pruhu vyrůstá bílá rozkřídlená husa se žlutou zbrojí, v modrém pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře vyrůstající stříbrná rozkřídlená husa se zlatou zbrojí, dole stříbrná růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Čáslavsko",
        kod: 547689,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.592512,
        longitude: 15.00285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/%C4%8C%C3%A1slavsko.png",
        coatOfArmsFlagDescription: "Modrý list s bílým vlajícím klínem. V horním modrém poli hlávka zelí, v dolním unciální písmeno M, obojí žluté. V klínu u vlajícího okraje listu zelený vykořeněný smrk. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot se zeleným vykořeněným smrkem, provázený vpravo hlávkou zelí a vlevo unciálním písmenem M, obojí zlaté."
    },
    {
        obec: "Částkov",
        kod: 560804,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34801,
        latitude: 49.756077,
        longitude: 12.673799,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílými vidlemi - podávkami bez násady, a bílý s černou šikmou ostrví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrné vidle - podávky bez násady, vlevo šikmá černá ostrev."
    },
    {
        obec: "Částkov",
        kod: 592137,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.10453,
        longitude: 17.619917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Znak_C%C3%A1stkov.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zubatý žlutý, modrý, žlutý a zubatý modrý. Uprostřed bílo-modře polcená lilie. Zubaté pruhy mají pět obdélníkových zubů a čtyři stejné mezery, které sahají do poloviny šířky zubatých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro zlatě polceném štítě věž s cimbuřím, převýšená stříbrno-modře polcenou lilií a provázená dvěma buvolími rohy, obojí opačných barev."
    },
    {
        obec: "Častohostice",
        kod: 553964,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67602,
        latitude: 49.014482,
        longitude: 15.819302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/%C4%8Castohostice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 12 : 1 : 1 : 1 : 1. V žerďové části bílý šestilistý květ se žlutým semeníkem na bílém stonku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstající stříbrná rotunda s dveřmi a dvěma obloukovými okny, vše černé, a červenou kuželovou střechou zakončenou zlatým patriarším křížkem, vpravo provázená stříbrným šestilistým květem se zlatým semeníkem na stonku, vlevo zkříženými zlatými ostrvemi."
    },
    {
        obec: "Častolovice",
        kod: 576182,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51750,
        latitude: 50.129194,
        longitude: 16.181387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Castolovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou rybou vztyčenou do oblouku a šikmo prostřelenou bílým šípem hrotem k dolnímu rohu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčená, do oblouku prohnutá zlatá ryba, shora šikmo prostřelená stříbrným šípem."
    },
    {
        obec: "Častrov",
        kod: 547719,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39463,
        latitude: 49.307899,
        longitude: 15.180392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/%C4%8Castrov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červeno-modře dělený a žlutý, v poměru 1:6:1. Uprostřed bílá žlutě zdobená mitra, jejíž bílé žlutým třepením zakončené fimbrie tvoří zavinutí u dvou bílých zavinutých střel po stranách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrná zlatě zdobená mitra, jejíž stříbrné zlatým třepením zakončené fimbrie tvoří zavinutí u dvou stříbrných zavinutých střel po stranách. Vše provázeno třemi (2,1) zlatými kotouči."
    },
    {
        obec: "Časy",
        kod: 572799,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.069473,
        longitude: 15.898214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/%C4%8Casy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 1:6:1. Ve žlutém pruhu černý orel s červenou zbrojí a bílým zvonem se závěsem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na modré vlnité patě v dřevěné loďce přirozené barvy černý orel s červenou zbrojí se stříbrným zvonem se závěsem na hrudi."
    },
    {
        obec: "Čavisov",
        kod: 569119,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74764,
        latitude: 49.829577,
        longitude: 18.080797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/%C4%8Cavisov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 3:1. Z bílého pruhu vyrůstají dva žluté od sebe prohnuté obilné klasy s listy. Mezi nimi bílá lipová ratolest o pěti listech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě mezi dvěma zlatými od sebe prohnutými obilnými klasy vyrůstajícími ze stříbrného návrší stříbrná lipová ratolest o pěti listech."
    },
    {
        obec: "Čebín",
        kod: 582913,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66423,
        latitude: 49.313339,
        longitude: 16.478018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Cebin_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém pruhu bílé křídlo s červeným středovým křížem, v bílém pruhu černé křídlo se žlutým oboustranně jetelovitě zakončeným perizoniem, křídla uprostřed listu přivrácená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo stříbrné křídlo s červeným heroldským křížem, vlevo černé křídlo se zlatým oboustranně jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Čečelice",
        kod: 534749,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27732,
        latitude: 50.294103,
        longitude: 14.618687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Cecelice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a černý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-černě děleném štítě vyrůstající zlatá, hrano dopředu natočená věž se dvěma prolomenými románskými okny, cimbuřím a stanovou střechou."
    },
    {
        obec: "Čečelovice",
        kod: 510068,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.374116,
        longitude: 13.794971,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/%C4%8Ce%C4%8Delovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Z dvanácté šestnáctiny žerďového a vlajícího okraje listu vychází obloukový bílý pruh s vrcholem v deváté šestnáctině šířky listu. Nad ním kosmo sekera šikmo podložená kosou, obojí bílé na žlutých násadách. Pod pruhem volný dvojitý, spojený řecký a ondřejský, kříž v mezikruží, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě snížené stříbrné obloukové břevno, nad ním kosmo sekera šikmo podložená kosou, obojí stříbrné se zlatými násadami, dole volný dvojitý, spojený řecký a ondřejský, kříž v kruhu, vše zlaté."
    },
    {
        obec: "Čečkovice",
        kod: 548375,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.784709,
        longitude: 15.664069,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/%C4%8Ce%C4%8Dkovice_CoA.jpg"
    },
    {
        obec: "Čečovice",
        kod: 566080,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.585171,
        longitude: 13.021847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/%C4%8Ce%C4%8Dovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a čtyři vodorovné pruhy, střídavě červené a bílé, v poměru 1 : 1 : 1 : 2. V žerďovém pruhu berla provázená třemi kruhovými poli, horním v závitu a dolními pod hlavicí, vše bílé. Ve střední části listu v horních dvou pruzích šestilistá růže opačných barev se žlutým semeníkem, v dolním bílém pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli berla provázená třemi kotouči, horním v závitu a dolními pod hlavicí, vše stříbrné. Levé pole děleno červeným břevnem. V horním červeno-stříbrně děleném poli šestilistá růže opačných barev se zlatým semeníkem, v dolním stříbrném poli červená růže se zlatým semeníkem."
    },
    {
        obec: "Čehovice",
        kod: 589365,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79821,
        latitude: 49.43255,
        longitude: 17.175182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/%C4%8Cehovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu černá, nahoře uťatá zubří hlava se žlutou houžví. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Čechočovice",
        kod: 590444,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.202965,
        longitude: 15.793702,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/CoA_of_%C4%8Cecho%C4%8Dovice.svg",
        coatOfArmsFlagDescription: "Bílý list s černou utrženou supí hlavou s červeným zobákem a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá utržená supí hlava s červeným zobákem a jazykem."
    },
    {
        obec: "Čechtice",
        kod: 529486,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.624128,
        longitude: 15.048346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/CoA_of_%C4%8Cechtice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený s bílou lilií a bílý, v poměru 1 : 3 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná lilie."
    },
    {
        obec: "Čechtín",
        kod: 590452,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.293149,
        longitude: 15.819975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/%C4%8Cecht%C3%ADn_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený bílým pruhem vycházejícím z druhé čtvrtiny horního okraje do dolního vlajícího cípu listu. V žerďovém modrém poli bílá kosa hrotem dolů na kosmém žlutém kosišti. Ve vlajícím červeném poli bílý pětilistý květ. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře zvýšeným stříbrným kosmým břevnem děleném štítě nahoře stříbrný pětilistý květ, dole kosmá stříbrná kosa se zlatým kosištěm."
    },
    {
        obec: "Čechy",
        kod: 552771,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75115,
        latitude: 49.429155,
        longitude: 17.534434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/%C4%8Cechy_PR_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílým gryfem bez křídel se žlutou zbrojí, držícím černou kotvu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný gryf bez křídel se zlatou zbrojí držící černou kotvu."
    },
    {
        obec: "Čejč",
        kod: 586099,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69614,
        latitude: 48.946631,
        longitude: 16.965214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/%C4%8Cej%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou lilií a bílým vinařským nožem hrotem k žerďovému a žlutou rukojetí k dolnímu okraji listu, pod nimi bílá radlice hrotem k vlajícímu a ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá lilie a stříbrný vinařský nůž se zlatou rukojetí, pod nimi položená stříbrná radlice."
    },
    {
        obec: "Čejetice",
        kod: 550922,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.25292,
        longitude: 14.020488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/%C4%8Cejetice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žluté mezikruží hořící pěti plameny, uprostřed bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě uprostřed prázdného zlatého, pěti zlatými plameny hořícího kruhu, stříbrná lilie. Vše převýšeno kosmým palcátem šikmo podloženým postaveným mečem, obojí stříbrné."
    },
    {
        obec: "Čejkovice",
        kod: 544329,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.014436,
        longitude: 14.383008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/%C4%8Cejkovice_%28%C4%8Cesk%C3%A9_Bud%C4%9Bjovice_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým horním žerďovým trojúhelníkem s vrcholem uprostřed horního okraje listu a dvěma žlutými šikmými pruhy vycházejícími ze sedmé a deváté dvanáctiny horního okraje listu do první a třetí dvanáctiny dolního okraje listu. V bílém poli černá lilie, do modrého pole vyrůstá z dolního okraje listu hlava čejky s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít se zlato-modro-zlatě vlnkovitě děleným vlnkovitým břevnem, z něhož vynikají tři stříbrné věže s cimbuřím, každá s černou lilií. Dole vyrůstající hlava čejky s krkem přirozené barvy."
    },
    {
        obec: "Čechy pod Kosířem",
        kod: 589381,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79858,
        latitude: 49.551061,
        longitude: 17.037775,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Cechy_pod_Kosirem_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý s bílou lilií, bílý se žlutě korunovaným červeným lvem s modrou zbrojí a červený s polovinou bílé zavinuté střely přiléhající k bílému pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít se sníženým stříbrným hrotem. Vpravo stříbrná lilie, vlevo půl stříbrné zavinuté střely přiléhající k dělící linii, v hrotu červený korunovaný lev s modrou zbrojí."
    },
    {
        obec: "Čejkovice",
        kod: 586102,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69615,
        latitude: 48.906019,
        longitude: 16.942409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/%C4%8Cejkovice_Hodon%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "Bílý list s červeným templářským tlapatým křížem s rameny širokými jednu sedminu šířky listu dosahujícími na okraje listu. Uprostřed listu vzlétající čejka přirozené barvy se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě přes červený tlapatý kříž vzlétající čejka přirozené barvy se zlatou zbrojí."
    },
    {
        obec: "Čejkovice",
        kod: 593877,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.877801,
        longitude: 16.287428,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/%C4%8Cejkovice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a žlutý, v poměru 8 : 1 : 1. Z modrého pruhu vyrůstá rozkřídlená čejka s modrou lilií na hrudi. Přes každé křídlo žlutý stonek fenyklu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě z modro - zlatě vlnitě dělené vlnité paty vyrůstá rozkřídlená čejka přirozené barvy s modrou lilií na hrudi. Přes každé křídlo zlatý stonek fenyklu."
    },
    {
        obec: "Čejkovice",
        kod: 529524,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.791923,
        longitude: 15.349767
    },
    {
        obec: "Čeladná",
        kod: 598071,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73912,
        latitude: 49.548829,
        longitude: 18.337697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/%C4%8Celadn%C3%A1_CoA.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým beranem v žerďové polovině a dvěma bílými pravoúhlými trojúhelníky, každý se zelenou šesticípou hvězdou ve vlajících cípech. Kratší odvěsny trojúhelníku tvoří vlající okraj listu, delší odvěsny dosahují do první čtvrtiny okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený hrot se stříbrným beranem. Hrot je provázen dvěma zelenými hvězdami."
    },
    {
        obec: "Čelčice",
        kod: 589390,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79823,
        latitude: 49.412429,
        longitude: 17.19374,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/%C4%8Cel%C4%8Dice_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. Uprostřed listu černý medvěd ve skoku s červenou zbrojí a bílými zuby, provázený v zeleném pruhu žlutým tlapatým křížem a v bílém pruhu červenou růží se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě černý medvěd ve skoku s červenou zbrojí a stříbrnými zuby, provázený vpravo zlatým tlapatým křížem a vlevo červenou růží se zlatým semeníkem."
    },
    {
        obec: "Čejov",
        kod: 547735,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.565751,
        longitude: 15.379353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Cejov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 7:1. V bílém pruhu černá korunovaná harpyje s břevnem na hrudi a dvěma šesticípými hvězdami na křídlech, vše žluté. V modrém pruhu pět šesticípých žlutých hvězd vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou patou černá korunovaná harpyje s břevnem na hrudi a dvěma hvězdami na křídlech, pod ní pět hvězd do oblouku, vše zlaté."
    },
    {
        obec: "Čelechovice",
        kod: 513059,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.510136,
        longitude: 17.372246,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/%C4%8Celechovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a černý, v poměru 2:1:1. V žerďové polovině zeleného pruhu bílá přirozená lilie na stonku se dvěma listy mezi dvěma přivrácenými žlutými krojidly hroty dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně zvýšeně děleném štítě nahoře vyrůstající černá plamenná orlice se zlatým zobákem a jetelovitě zakončeným perizoniem, dole stříbrná přirozená lilie na stonku se dvěma listy, provázená dvěma zlatými přivrácenými krojidly."
    },
    {
        obec: "Čelechovice na Hané",
        kod: 589403,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79816,
        latitude: 49.516364,
        longitude: 17.093902,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Coat_of_arms_of_%C4%8Celechovice_na_Han%C3%A9.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý. V žerďovém tři bílé vlající klíny s vrcholy na žerďovém okraji, ve středním bílá radlice ostřím k žerdi a hrotem dolů a ve vlajícím černý trilobit. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě pod červenou hlavou se třemi stříbrnými klíny vpravo stříbrná radlice, vlevo černý trilobit."
    },
    {
        obec: "Čelistná",
        kod: 529826,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.360262,
        longitude: 15.206421
    },
    {
        obec: "Čelákovice",
        kod: 538132,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25088,
        latitude: 50.162803,
        longitude: 14.751089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Celakovice_znak.jpg",
        coatOfArmsFlagDescription: "Světle modrý list se dvěma svislými pruhy na vlajícím okraji, žlutým a červeným, každým o šířce jedné šestiny délky listu. Ve světle modrém poli vykořeněný dub se zelenými listy, žlutými žaludy a hnědým kmenem se třemi suky a pěti kořeny. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Čeložnice",
        kod: 586111,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69651,
        latitude: 49.051964,
        longitude: 17.15476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/%C4%8Celo%C5%BEnice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List dělený žlutým horním vydutým klínem na žerďové modré a vlající zelené pole. V modrém poli svěšený žlutý vinný hrozen s bílým stonkem s úponky, v klínu vztyčená černá lopata na červené násadě a v zeleném poli bílý srp se žlutou rukojetí a ostřím k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně zlatým vydutým klínem polcený štít. V klínu vztyčená černá lopata na červené násadě, vpravo svěšený zlatý vinný hrozen se stříbrným stonkem s úponky, vlevo stříbrný srp se zlatou rukojetí."
    },
    {
        obec: "Čeminy",
        kod: 558711,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.80007,
        longitude: 13.253217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/%C4%8Ceminy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 3 : 2 : 3. V červeném pruhu lilie mezi dvěma hvězdami, v modrém pruhu tři hvězdy vedle sebe, vše bílé, hvězdy šesticípé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře stříbrným, nahoře cimbuřovým a dole vlnitým břevnem dělený štít. Nahoře lilie mezi dvěma hvězdami, dole tři hvězdy (2, 1), vše stříbrné."
    },
    {
        obec: "Čenkov",
        kod: 540072,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.777803,
        longitude: 14.00086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Coats_of_arms_%C4%8Cenkov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý a červený, v poměru 3:1:3. Bílý pruh má čtyři vrcholy a pět prohlubní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně vlnitým břevnem dělený štít, nahoře hamr, dole zkřížené meče hroty dolů, vše stříbrné."
    },
    {
        obec: "Čenkov u Bechyně",
        kod: 598976,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.233538,
        longitude: 14.500943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/%C4%8Cenkov_u_Bechyn%C4%9B_-_CoA.png",
        coatOfArmsFlagDescription: "List dělený kosmým modro-žlutě děleným pruhem širokým desetinu šířky listu na žerďové modré pole se žlutou osmicípou hvězdou a vlající bílé se žlutým černě okřídleným orlím pařátem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře kosmým modro-zlatě děleným břevnem dělený štít. Nahoře zlatý, černě okřídlený orlí spár, dole zlatá osmihrotá hvězda."
    },
    {
        obec: "Čenkovice",
        kod: 579998,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56164,
        latitude: 50.011481,
        longitude: 16.682509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/%C4%8Cenkovice.svg",
        coatOfArmsFlagDescription: "Zelený list se šikmým žlutým pruhem širokým jednu desetinu délky listu. V horním žerďovém poli žluto-červeně polcené srdce, v dolním vlajícím poli bílá růže s červeným semeníkem a kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě se zlatou mříží tvořenou dvěma zúženými kůly a dvěma zúženými břevny uprostřed zlato-červeně polcené srdce, nahoře a po stranách čtyři stříbrné růže s červenými semeníky a kališními lístky."
    },
    {
        obec: "Čeperka",
        kod: 574856,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.13312,
        longitude: 15.773339,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Znak_obce_%C4%8Ceperka.png",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým žlutým čtvercovým polem a dva vodorovné pruhy, bílý a modrý. V karé dvě žluté královské koruny vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se dvěma zlatými královskými korunami stříbrno-modro-zlato-modro-stříbrno trnitě dělený štít."
    },
    {
        obec: "Čepí",
        kod: 574864,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53332,
        latitude: 49.985238,
        longitude: 15.717675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Znak_obce_%C4%8Cep%C3%AD.svg",
        coatOfArmsFlagDescription: "Červený list se třemi (2,1) bílými kapry hlavami k hornímu okraji a hřbetem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě tři (2,1) stříbrní kapři hlavami nahoru."
    },
    {
        obec: "Čepřovice",
        kod: 550949,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38756,
        latitude: 49.155339,
        longitude: 13.975181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Coat_of_arms_of_%C4%8Cep%C5%99ovice.svg",
        coatOfArmsFlagDescription: "Dolním žlutým klínem dělený list na modré žerďové a zelené vlající pole. V klínu tři (1, 2) červené růže se žlutými semeníky a zelenými kališními lístky, horní růže větší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě čelně vztyčená zlatá radlice se třemi (1, 2) červenými růžemi se zlatými semeníky a zelenými kališními lístky, horní růže větší."
    },
    {
        obec: "Čeradice",
        kod: 530581,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.308851,
        longitude: 13.494015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/%C4%8Ceradice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý zubatý pruh, široký polovinu délky listu, a tři vodorovné pruhy, bílý, červený a bílý. Modrý pruh má pět čtvercových zubů a šest stejných mezer. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně cimbuřovitě polceném štítě vpravo od středu vyniká půl zlaté hvězdy, vlevo přes červené břevno zelený obilný klas se dvěma listy."
    },
    {
        obec: "Čerčany",
        kod: 529516,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25722,
        latitude: 49.853032,
        longitude: 14.703093,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/CoA_of_%C4%8Cer%C4%8Dany.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů bílý, zelený, bílý, modrý, bílý a modrý, v poměru 4:4:1:1:1:1. V zeleném pruhu žlutá zavinutá střela hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad třikrát stříbrno - modře vlnitě dělenou vlnitou patou zelený vrch se zlatou zavinutou střelou."
    },
    {
        obec: "Čermná",
        kod: 579106,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54377,
        latitude: 50.550083,
        longitude: 15.769729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Cermna_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 6 : 1 : 1. Z bílého pruhu vyrůstají dva květy zvonku na překřížených stoncích. Stonek k žerďsvému okraji se svěšeným květem a se čtyřmi listy, dva nahoře a dva dole. Stonek k vlajícímu okraji se vztyčeným a rozevřeným květem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ze stříbrného vlnitého břevna v patě vyrůstající dva květy zvonku na překřížených stoncích, levý se čtyřmi listy, dva nahoře a dva dole. Pravý květ svěšený, levý vztyčený a rozevřený, vše zlaté."
    },
    {
        obec: "Čermákovice",
        kod: 593885,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67173,
        latitude: 49.032153,
        longitude: 16.195072,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Coat_of_arms_of_%C4%8Cerm%C3%A1kovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, a zubatý pruh opačných barev na dolním okraji listu, široký čtvrtinu šířky listu se sedmi zuby a šesti stejnými mezerami. Nad ním černý rehek - čermáček se žlutou zbrojí a hnědým ocasním brkem, za ním nahoře červená svatojakubská mušle se žlutou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě nad sníženou stříbrno-zeleně polcenou hradbou rehek - čermáček přirozené barvy se zlatou zbrojí provázený vlevo nahoře červenou svatojakubskou mušlí se zlatou hvězdou."
    },
    {
        obec: "Čermná",
        kod: 553514,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.531701,
        longitude: 13.116707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/%C4%8Cermn%C3%A1_%28okres_Doma%C5%BElice%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý pětkrát lomený a žlutý pilovitý se třemi zuby, v poměru 8 : 3 : 6. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pětkrát zalomené stříbrné břevno podložené dvěma postavenými meči a provázené nahoře makovicí a dole dolů obrácenou podkovou, vše zlaté."
    },
    {
        obec: "Čermná nad Orlicí",
        kod: 576191,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51725,
        latitude: 50.080411,
        longitude: 16.144089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Cermna_nad_Orlici_CoA_CZ_2.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - červený, zelený a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou vlnitou patou červený cihlový most o dvou obloucích. V horní části dva zelené odvrácené listy, pravý lipový, levý dubový."
    },
    {
        obec: "Čermná ve Slezsku",
        kod: 569950,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74901,
        latitude: 49.782431,
        longitude: 17.702213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/%C4%8Cermn%C3%A1_ve_Slezsku_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrou radlicí, ke které přiléhá půl slezské orlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vpravo modrá radlice k níž přiléhá půl slezské orlice."
    },
    {
        obec: "Černá",
        kod: 595438,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59442,
        latitude: 49.426181,
        longitude: 15.86393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/%C4%8Cern%C3%A1_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 1:4:1. V bílém pruhu dvě odvrácená červená račí klepeta. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším dvě odvrácená červená račí klepeta, nad pravým dolů obrácená černá mnišská kápě, nad levým vztyčený zelený javorový list."
    },
    {
        obec: "Černá Hora",
        kod: 581496,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.413707,
        longitude: 16.581502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/%C4%8Cern%C3%A1_Hora_%28okres_Blansko%29_znak.png",
        coatOfArmsFlagDescription: "Červený list s žerďovým černým klínem dosahujícím do třetiny délky listu, ke kterému přiléhá bílý hřeben o sedmi zubech, vycházející z první desetiny horního a dolního okraje listu do dvou třetin délky listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Černá u Bohdanče",
        kod: 572896,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.05613,
        longitude: 15.671936,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/%C4%8Cern%C3%A1_u_Bohdan%C4%8De.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký jednu třetinu délky listu s černým dubem vyrůstající z černého vodorovného pruhu širokého jednu pětinu šířky listu a tři vodorovné pruhy žlutý, vlnitý modrý a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě polceném štítě vpravo černý dub vyrůstající z černé paty štítu a vlevo modré vlnité břevno."
    },
    {
        obec: "Černá v Pošumaví",
        kod: 545457,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38223,
        latitude: 48.738072,
        longitude: 14.110637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Cerna_v_Posumavi_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnitý modrý, vlnitý bílý a černý, v poměru 10 : 2 : 1 : 3. Vlnité pruhy mají tři vrcholy a dvě prohlubně. V žerďové polovině horního bílého pruhu červená růže se žlutým semeníkem a zelenými kališními lístky podložená kosmo mlátkem a šikmo želízkem, obojí modré s černými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě, nad modro - černě stříbrným vlnitým břevnem dělenou zvýšenou vlnitou patou, červená růže se zlatým semeníkem a zelenými kališními lístky podložená kosmo mlátkem a šikmo želízkem, obojí modré s černými topůrky."
    },
    {
        obec: "Černá Voda",
        kod: 533491,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79054,
        latitude: 50.308312,
        longitude: 17.1471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/%C4%8Cern%C3%A1_Voda_znak.png",
        coatOfArmsFlagDescription: "Bílý list s černým vodorovným vlnitým pruhem širokým tři osminy šířky listu, o třech vrcholech a dvou prohlubních, se třemi žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý vlnitý kůl se třemi zlatými hvězdami."
    },
    {
        obec: "Černava",
        kod: 538019,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36221,
        latitude: 50.291365,
        longitude: 12.711084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/%C4%8Cernava_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, vlnitý černý, vlnitý žlutý, vlnitý modrý a žlutý, v poměru 3 : 2 : 6 : 2 : 3. V prostředním pruhu tři černá kruhová pole vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě mezi dvěma vlnitými břevny, černým a modrým, tři černé kotouče vedle sebe."
    },
    {
        obec: "Černčice",
        kod: 573957,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.335841,
        longitude: 16.102688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/%C4%8Cern%C4%8Dice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed vykořeněná lekna, mezi nimi dolů obrácená mušle, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vykořeněná lekna mezi nimi dolů obrácená mušle, vše stříbrné."
    },
    {
        obec: "Černčice",
        kod: 542628,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43901,
        latitude: 50.361485,
        longitude: 13.845324,
        coatOfArmsFlagDescription: "Bílý list se svislým středovým modrým pruhem se třemi žlutými šesticípými hvězdami pod sebou, širokým jednu pětinu délky listu. Z modrého pruhu vyrůstá do žerďové a do vlající části červená lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kůl se třemi zlatými hvězdami pod sebou, z kůlu vyrůstá do stran červená lilie."
    },
    {
        obec: "Černé Voděrady",
        kod: 533254,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.941659,
        longitude: 14.80689,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Coats_of_arms_%C4%8Cern%C3%A9_Vod%C4%9Brady.jpeg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě zelených a bílých, s červeným karé. V něm žluté miskové váhy kosmo přeložené bílým brkem a šikmo bílým mečem se žlutým jílcem, hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně vlnitě děleném štítě nahoře na zeleném trávníku tři listnaté stromy přirozené barvy, dole zlaté miskové váhy kosmo přeložené stříbrným brkem a šikmo vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Černěves",
        kod: 546852,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.454495,
        longitude: 14.243217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/%C4%8Cern%C4%9Bves_CoA.svg",
        coatOfArmsFlagDescription: "Červený list s modrým žerďovým pruhem širokým třetinu délky listu. V žerďovém pruhu žlutá berla závitem k žerďovému okraji, dole přeložená bílou mitrou s červeným latinským křížem do krajů a fimbriemi, ve střední části listu koruna provázená nahoře a dole hvězdou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo zlatá berla, dole přeložená stříbrnou mitrou s červeným latinským křížem do krajů a fimbriemi, vlevo koruna provázená nahoře a dole pětihrotou hvězdou, vše zlaté."
    },
    {
        obec: "Černíč",
        kod: 587001,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.127972,
        longitude: 15.45943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/%C4%8Cern%C3%AD%C4%8D.png",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým čtvrtinu délky listu se třemi svěšenými zelenými leknínovými listy pod sebou. V modrém poli žluté palečné kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou cimbuřovou hlavou se třemi svěšenými zelenými leknovými listy zlaté palečné kolo."
    },
    {
        obec: "Černíkovice",
        kod: 576204,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51704,
        latitude: 50.186688,
        longitude: 16.207062,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/%C4%8Cern%C3%ADkovice_RK_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři šikmé pruhy, žlutý, červený, bílý a modrý, v poměru 7:3:2:8. Ve žlutém poli černá lovecká trubka se závěsem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě sv. Florián v červeném plášti držící v levé ruce červenou korouhev se zlatým řeckým křížem, provázený vpravo nahoře černou loveckou trubkou se závěsem."
    },
    {
        obec: "Černíkov",
        kod: 553531,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.422778,
        longitude: 13.13013,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/%C4%8Cern%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:2:1. V modrém pruhu žlutá konvice s pootevřeným víčkem převýšená žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli zlatá konvice s pootevřeným víčkem převýšená zlatou korunou, levé pole pětkrát stříbrno-červeně děleno."
    },
    {
        obec: "Černíkovice",
        kod: 566632,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.975551,
        longitude: 13.56701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/%C4%8Cern%C3%ADkovice_%28okres_Plze%C5%88-sever%29_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými čtvrtinu šířky listu. Pole na dolním okraji listu černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě nahoře dvě zkřížené stříbrné berly přeložené zlatým olistěným prutem, dole tři vyrůstající černé jehličnaté stromy."
    },
    {
        obec: "Černíky",
        kod: 599301,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28915,
        latitude: 50.102118,
        longitude: 14.820104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/%C4%8Cern%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký třetinu délky listu a žluté pole. V pruhu černá plamenná orlice se žlutým, jetelovitě zakončeným perizoniem na křídlech a se žlutou zbrojí a jazykem. Ve střední části listu černá kančí hlava nad modrou růží se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě polceném štítě vpravo černá plamenná orlice se zlatým, jetelovitě zakončeným perizoniem na křídlech a se zlatou zbrojí a jazykem, vlevo černá kančí hlava nad modrou růží se zlatým semeníkem."
    },
    {
        obec: "Černín",
        kod: 593893,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.983053,
        longitude: 16.021013,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/%C4%8Cern%C3%ADn_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou radlicí ostřím k vlajícímu a hrotem k hornímu okraji, kosmo podloženou žlutě zdobenou bílou poutnickou holí s mušlí a šikmo bílým krojidlem ostřím k žerďovému okraji a hrotem k hornímu cípu listu, vše provázeno dvěma žlutými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácená vztyčená stříbrná radlice kosmo podložená stříbrnou, zlatě zdobenou poutnickou holí s mušlí a šikmo stříbrným krojidlem, vše provázeno dvěma zlatými liliemi."
    },
    {
        obec: "Černilov",
        kod: 569917,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50343,
        latitude: 50.262743,
        longitude: 15.922644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/%C4%8Cernilov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým bílým čtvercovým polem a dva vodorovné pruhy, bílý a červený. V karé královská koruna nad zkříženými palmovými ratolestmi, vše žluté. V dolním bílém poli na návrší kůň ve skoku, obojí černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně čtvrcený štít. V 1. a 4. poli královská koruna nad zkříženými palmovými ratolestmi, vše zlaté. Ve 2. a 3. poli na návrší kůň ve skoku, obojí černé."
    },
    {
        obec: "Černiv",
        kod: 564699,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.445046,
        longitude: 14.059049,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/%C4%8Cerniv_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým čtvrtinu šířky listu na zelené žerďové a modré vlající pole. V žerďovém poli žluté kruhové pole s černým středovým ostrvovým křížem, v pruhu tři červené růže se žlutými semeníky, ve vlajícím poli žlutý běžící zajíc. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrným vlnitým břevnem se třemi červenými růžemi se zlatými semeníky dělený štít. Nahoře zlatý kotouč s černým heroldským ostrvovým křížem, dole běžící zlatý zajíc."
    },
    {
        obec: "Černíny",
        kod: 534013,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.840116,
        longitude: 15.218931
    },
    {
        obec: "Černolice",
        kod: 539121,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.90979,
        longitude: 14.299358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Cernolice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, žlutý a černý, přes ně bílý klín vycházející z dolního okraje listu s vrcholem na horním okraji listu. Z druhé až čtvrté pětiny dolního okraje listu vyrůstá černé skalnaté trojvrší se žlutou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černém štítě se zlatým břevnem stříbrný hrot, v něm černé skalnaté trojvrší se zlatou lilií."
    },
    {
        obec: "Černošice",
        kod: 539139,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25228,
        latitude: 49.960184,
        longitude: 14.319902,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/%C4%8Cerno%C5%A1ice_znak.png",
        coatOfArmsFlagDescription: "List je žlutý a modrý; v horním žlutém poli je v žerďovém rohu zelený trojlístek se stonkem, vysoký 1/2 šířky praporu; poměr žlutého a modrého pole je 3:1; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Zlatomodře polcený štít se zaoblenou modrou patou; v zlatém poli zelený jetelový trojlístek se stonkem."
    },
    {
        obec: "Černotín",
        kod: 513067,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75368,
        latitude: 49.531975,
        longitude: 17.772231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/%C4%8Cernot%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený, v poměru 1:2:1. Do žerďového pruhu vynikají dvě, do vlajícího jedna žlutá lilie. V prostředním pruhu černý kůň ve skoku s červenou zbrojí, držící modrý list kosy hrotem dolů a ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý štítek, z jehož rohů a paty vynikají tři lilie. Ve štítku černý kůň ve skoku s červenou zbrojí držící postavený modrý list kosy, hrotem dolů a ostřím doleva."
    },
    {
        obec: "Černouček",
        kod: 542423,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.360924,
        longitude: 14.307446,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/%C4%8Cernou%C4%8Dek_CoA.png",
        coatOfArmsFlagDescription: "List s lemem širokým osminu šířky listu, v žerďové polovině zeleným, ve vlající polovině žlutým. Vnitřní pole v žerďové polovině bílé s červeným středovým křížem s rameny širokými osminu šířky listu a se žlutou šesticípou hvězdou uprostřed. Kříž provázen čtyřmi zelenými šesticípými hvězdami. Ve vlající zelené polovině bílá rozevřená kniha se žlutými deskami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně obloukem dělený štít se zeleno-zlatě děleným lemem. Nahoře červený heroldský kříž se zlatou hvězdou uprostřed, provázený čtyřmi zelenými hvězdami, dole stříbrná rozevřená kniha se zlatými deskami."
    },
    {
        obec: "Černousy",
        kod: 545996,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46373,
        latitude: 51.004564,
        longitude: 15.050573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Cernousy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, černý, bílý, červený a modrý. Uprostřed přes tři horní pruhy vztyčený žalud se dvěma listy, v modrém pruhu pět šesticípých hvězd, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní pole dvakrát červeno-stříbrno-černě děleno, dolní pole modré. Nahoře zlatý vztyčený žalud se dvěma listy, dole tři (2,1) odvrácené stříbrné půlměsíce nad pěti zlatými hvězdami do oblouku."
    },
    {
        obec: "Černošín",
        kod: 560812,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34958,
        latitude: 49.816245,
        longitude: 12.883925,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/%C4%8Cerno%C5%A1%C3%ADn_-_coat_of_arms.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3:1. Uprostřed listu bílá labuť se žlutou zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Černov",
        kod: 561142,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.344066,
        longitude: 15.314148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/%C4%8Cernov.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý, v poměru 2 : 1. V bílém pruhu mezi dvěma vztyčenými zelenými listy kaštanu černý zvon se závěsem, v modrém převrácená bílá hornická kladívka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý zvon se závěsem mezi dvěma vztyčenými zelenými kaštanovými listy nad zvýšenou modrou vlnitou patou, v ní převrácená stříbrná hornická kladívka."
    },
    {
        obec: "Černovice",
        kod: 547760,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39494,
        latitude: 49.372757,
        longitude: 14.960993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/CoA_%C4%8Cernovice.png",
        coatOfArmsFlagDescription: "List je bílý a modrý šikmo dělený. V bílém poli je červená růže a v modrém poli žlutá růže, obě z městského znaku. Růže jsou umístěny na průsečících os úhlů příslušného trojúhelníkového pole a zaujímají jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Černovice",
        kod: 581500,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67975,
        latitude: 49.48363,
        longitude: 16.423018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/%C4%8Cernovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý v poměru 1:2:1. V modrém žlutý listnatý vykořeněný strom. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít. Nahoře černá zubří hlava s červeným jazykem a zlatou houžví. Dole ze zlatého trávníku vyrůstá zlatý listnatý strom, provázený vpravo kosmo vztyčeným krojidlem a vlevo šikmo postavenou radlicí obojí stříbrné."
    },
    {
        obec: "Černovice",
        kod: 563021,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.447783,
        longitude: 13.359354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/%C4%8Cernovice_%28Chomutov_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a černý, v poměru 3 : 1. Z černého pruhu vyrůstají tři zelené stromy s hnědými kmeny, listnatý mezi jehličnatými. V černém pruhu pod každým stromem bílý mlýnský kámen s černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě z černého návrší vyrůstají tři stromy přirozené barvy, listnatý mezi jehličnatými. V návrší tři (1, 2) stříbrné mlýnské kameny s černými kypřicemi."
    },
    {
        obec: "Černovice",
        kod: 566098,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.615661,
        longitude: 12.99704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/%C4%8Cernovice_DO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, zelený, černý a bílý, v poměru 1 : 2 : 2 : 1. V zeleném pruhu bílá lilie z níž dolů vyrůstá žlutý svěšený list jírovce, v černém pruhu vyniká z bílého žernovu s položenou žlutou kypřicí žlutý tlapatý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-černě polceném štítě vpravo stříbrná lilie, z níž dolů vyrůstá svěšený zlatý list jírovce, vlevo zlatý tlapatý kříž vyrůstající ze stříbrného mlýnského kamene s položenou zlatou kypřicí."
    },
    {
        obec: "Čerňovice",
        kod: 530328,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33036,
        latitude: 49.813006,
        longitude: 13.103784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/%C4%8Cer%C5%88ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý se zelenou lilií a modrý, v poměru 1 : 4 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře sníženě děleném štítě nahoře zelená lilie uvnitř černého paroží, v modrém poli tři (2,1) svěšené zlaté borové šišky."
    },
    {
        obec: "Černožice",
        kod: 569925,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50304,
        latitude: 50.318732,
        longitude: 15.874133,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/CoA_of_%C4%8Cerno%C5%BEice.svg",
        coatOfArmsFlagDescription: "Zelený list se třemi kosmými pruhy, bílým, modrým a bílým v poměru 1:2:1. Šířka modrého pruhu je jednou třetinou šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři modrá vlnitá břevna, z horního vyrůstá zelený most o třech obloucích, nad ním červený gotický štítek se stříbrným hadem šestkrát esovitě prohnutým."
    },
    {
        obec: "Černuc",
        kod: 532207,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27323,
        latitude: 50.301245,
        longitude: 14.202603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/%C4%8Cernuc_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký šestinu délky listu a tři vodorovné pruhy, červený, žlutý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně zlatým bleskem polcený štít s modrou patou. V pravém poli doleva obrácená koňská hlava s krkem, v levém lilie, v patě doleva položená ostrev se třemi (2, 1) suky, vše zlaté."
    },
    {
        obec: "Černvír",
        kod: 595446,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59262,
        latitude: 49.445413,
        longitude: 16.346286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/%C4%8Cernv%C3%ADr_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zvlněný černý, v poměru 3:7:2. V modrém pruhu bílý půlměsíc cípy k hornímu okraji, mezi nimi žlutá šesticípá hvězda. V bílém pruhu černá zubří hlava s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrný štít s modrou hlavou a černou vlnitou patou. V hlavě stříbrný stoupající půlměsíc převýšený zlatou hvězdou, ve štítu černá zubří hlava s červeným jazykem a zlatou houžví."
    },
    {
        obec: "Černýšovice",
        kod: 563722,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.325232,
        longitude: 14.520546
    },
    {
        obec: "Červená Hora",
        kod: 505099,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54941,
        latitude: 50.450166,
        longitude: 16.058927,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Cervena_Hora_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Červený list se žlutým zubatým žerďovým pruhem, širokým jednu třetinu délky listu, se čtyřmi obdélníkovými zuby a třemi stejnými mezerami hlubokými třetinu žlutého pruhu. V horním rohu zkřížené černé ostrve. V červeném poli bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě most s jedním obloukem a s věží, obojí zlaté s cimbuřím, ve věži zkřížené černé ostrve, po stranách dvě stříbrné lilie."
    },
    {
        obec: "Červená Lhota",
        kod: 590461,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.284564,
        longitude: 15.806208,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/%C4%8Cerven%C3%A1_Lhota_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, zubatý bílý a červený, v poměru 6:1:1. V horním pruhu vodorovně bílá zavinutá střela hrotem k žerdi. Bílý pruh má pět čtvercových zubů a šest stejných mezer. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná věž s cimbuřím, přeložená cimbuřovým břevnem opačných barev a převýšená položenou stříbrnou zavinutou střelou."
    },
    {
        obec: "Černý Důl",
        kod: 579114,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54344,
        latitude: 50.635059,
        longitude: 15.710701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/%C4%8Cern%C3%BD_D%C5%AFl_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:2:1. V bílém pruhu v zeleném vavřínovém věnci s červenými plody, kosmý mlátek šikmo podložený želízkem, oboje šedé s hnědými násadami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě v zeleném vavřínovém věnci s červenými plody kosmý mlátek šikmo podložený želízkem, oboje přirozené barvy."
    },
    {
        obec: "Červená Řečice",
        kod: 547778,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39446,
        latitude: 49.511334,
        longitude: 15.178468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Cervenarecice_znak.jpg"
    },
    {
        obec: "Červená Třemešná",
        kod: 548995,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.39943,
        longitude: 15.643602
    },
    {
        obec: "Červená Voda",
        kod: 580015,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56161,
        latitude: 50.040389,
        longitude: 16.742787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/%C4%8Cerven%C3%A1_Voda.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým kosmým klínem vycházejícím ze šesté až osmé osminy vlajícího okraje a stejné vzdálenosti na dolním okraji listu a s vrcholem v horním rohu listu. Uprostřed listu modrý květ lnu o průměru rovném polovině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý kosmý zúžený hrot přeložený modrým květem lnu."
    },
    {
        obec: "Červené Janovice",
        kod: 534021,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28542,
        latitude: 49.834712,
        longitude: 15.253438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Cervene_Janovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list, uprostřed na žlutých miskových vahách stojící vzpřímená přirozená orlice a dvouocasý lev, obojí přivrácené bílé, korunované a se žlutou zbrojí. Mezi miskami žluté ostruhové kolečko. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě doleva obrácená vzpřímená přirozená orlice a dvouocasý lev, obojí přivrácené stříbrné, korunované a se zlatou zbrojí, stojící na miskových vahách, mezi miskami ostruhové kolečko, vše zlaté."
    },
    {
        obec: "Červené Pečky",
        kod: 533262,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28121,
        latitude: 49.978318,
        longitude: 15.208666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/%C4%8Cerven%C3%A9_Pe%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým středovým křížem s rameny širokými osminu délky listu. Na kříži černé krokve s rameny širokými osminu šířky listu s vrcholy do středu listu. Uprostřed listu žluté kruhové pole s černou hlavou jelena s krkem a hrudí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý heroldský kříž s osmi černými přivrácenými krokvemi (2, 2, 2, 2), uprostřed zlatý štítek s černou jelení hlavou s krkem a hrudí."
    },
    {
        obec: "Červené Poříčí",
        kod: 542172,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.501992,
        longitude: 13.294553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Coat_of_arms_of_%C4%8Cerven%C3%A9_Po%C5%99%C3%AD%C4%8D%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 4:1:4. V červeném pruhu dvě bílé lilie, v modrém žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě stříbrné vlnité břevno, provázené nahoře dvěma stříbrnými liliemi a dole zlatou korunou."
    },
    {
        obec: "Červenka",
        kod: 552186,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78401,
        latitude: 49.718889,
        longitude: 17.083808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Coat_of_arms_of_%C4%8Cervenka.svg",
        coatOfArmsFlagDescription: "Červeno - žlutě šachovaný list (4 x 6), uprostřed bílá radlice hrotem k dolnímu a ostřím k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zlatě šikmo děleném štítě vpravo šikmá stříbrná radlice, vlevo tři černá vlnitá břevna."
    },
    {
        obec: "Červený Hrádek",
        kod: 509116,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.122981,
        longitude: 15.539027,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/%C4%8Cerven%C3%BD_Hr%C3%A1dek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, a vodorovný zubatý bílý kvádrovaný, na dolním okraji listu široký čtvrtinu šířky listu, se šesti obdélníkovými zuby, pěti stejnými mezerami a dvěma polovičními mezerami. Ze zubatého pruhu vyniká věž s cimbuřím a prázdným oknem, převýšená lilii a provázená přivrácenými křídly, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě prázdná kvádrovaná cimbuřová brána, z níž vyniká věž s cimbuřím a prázdným oknem, převýšená lilií a provázená přivrácenými křídly, vše stříbrné."
    },
    {
        obec: "Červený Újezd",
        kod: 529532,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25788,
        latitude: 49.555506,
        longitude: 14.604165,
        coatOfArmsFlagDescription: "Červený list, pod šesti (4, 2) bílými šesticípými hvězdami bílá rozevřená kniha ve žlutých deskách, v ní černá majuskulní řecká písmena alfa a omega. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod šesti (4, 2) stříbrnými hvězdami stříbrná rozevřená kniha ve zlatých deskách, v ní černá majuskulní řecká písmena alfa a omega."
    },
    {
        obec: "Červený Kostelec",
        kod: 573965,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54941,
        latitude: 50.476361,
        longitude: 16.093,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Cerveny_Kostelec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - modrý, červený a modrý. V žerďové polovině červeného pruhu tři bílé kříže. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Červený Újezd",
        kod: 532215,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.069809,
        longitude: 14.166017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Cerveny_Ujezd_PZ_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, žlutý, bílý, žlutý a červený v poměru 1:1:2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ve zlatém kruhu na ostrvi o třech sucích vzlétající havran, obojí stříbrné, držící v zobáku zlatý prsten s modrým kamenem."
    },
    {
        obec: "Česká",
        kod: 582921,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66431,
        latitude: 49.280178,
        longitude: 16.56551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Ceska_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s radlicí hrotem k hornímu a ostřím k vlajícímu okraji, pod ní do oblouku dole zkřížené lipové ratolesti, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě doleva obrácená vztyčená radlice, pod ní do oblouku dole zkřížené lipové ratolesti, vše stříbrné."
    },
    {
        obec: "Česká Bělá",
        kod: 568503,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58261,
        latitude: 49.643015,
        longitude: 15.691066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/%C4%8Cesk%C3%A1_B%C4%9Bl%C3%A1_znak_2015.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 5 : 3. V horním pruhu český lev, v dolním kosmo mlátek šikmo přeložený želízkem, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě český lev nad zvýšenou zelenou patou, v ní kosmý mlátek šikmo přeložený želízkem, obojí stříbrné."
    },
    {
        obec: "Česká Bříza",
        kod: 558745,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.828513,
        longitude: 13.429819,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/%C4%8Cesk%C3%A1_B%C5%99%C3%ADza_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V červeném pruhu zkřížené žluté klíče podložené bílým mečem se žlutým jílcem, hrotem k dolnímu okraji listu. Z dolního okraje bílého pruhu vyrůstá bříza. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo zkřížené zlaté klíče podložené postaveným stříbrným mečem se zlatým jílcem, vlevo na zeleném trávníku bříza přirozené barvy."
    },
    {
        obec: "Česká Čermná",
        kod: 573973,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54921,
        latitude: 50.400822,
        longitude: 16.229527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Ceska_cermna_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo vlnitě čtyřmi vrcholy a třemi prohlubněmi dělený list. V červeném poli kosmo žlutý obilný klas, do bílého pole vyskakuje černý jelen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo vlnitě děleném štítě nahoře doleva vyskakující černý jelen, dole kosmý zlatý obilný klas."
    },
    {
        obec: "Česká Kamenice",
        kod: 562394,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40721,
        latitude: 50.797908,
        longitude: 14.417784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Znak_m%C4%9Bsta_%C4%8Cesk%C3%A1_Kamenice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, uprostřed žluto-černě svisle dělené kruhové pole s průměrem rovným jedné třetině délky listu. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Česká Kubice",
        kod: 553549,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34532,
        latitude: 49.369488,
        longitude: 12.858904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/CoA_of_%C4%8Cesk%C3%A1_Kubice.svg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, modrý a červený. V zeleném pruhu bílá lilie, ve střední a vlající části listu žluté slunce bez tváře. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlaté slunce nad zeleným vrchem se stříbrnou lilií."
    },
    {
        obec: "Česká Lípa",
        kod: 561380,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47001,
        latitude: 50.67862,
        longitude: 14.539799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/%C4%8Cesk%C3%A1_L%C3%ADpa_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy, horní bílý se středově položenými zkříženými černými ostrvemi o šesti sucích, dolní pruh modrý. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Česká Metuje",
        kod: 573981,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54956,
        latitude: 50.545672,
        longitude: 16.180328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/%C4%8Cesk%C3%A1_Metuje_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1:4:1. V bílém pruhu tři (2,1) červené růže se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na položené zlaté ostrvi vykračující stříbrný ohlížející se beránek s červenou zbrojí, držící na zlaté žerdi stříbrnou korouhev s ocasem se třemi (2,1) červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Česká Rybná",
        kod: 580023,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56185,
        latitude: 50.071923,
        longitude: 16.392345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/%C4%8Cesk%C3%A1_Rybn%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý s bílou rybou a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě ryby opačných barev, horní plovoucí doprava, dolní doleva."
    },
    {
        obec: "Česká Skalice",
        kod: 573990,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55203,
        latitude: 50.394767,
        longitude: 16.042868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Ceska_Skalice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Ve středu modrého listu je položena šesticípá žlutá hvězda. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Česká Třebová",
        kod: 580031,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56002,
        latitude: 49.901982,
        longitude: 16.447385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/%C4%8Cesk%C3%A1_T%C5%99ebov%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - žlutý, červený a žlutý v poměru 1:2:1. Na červeném pruhu černý kohout se žlutými pařáty a židovskou hlavou (figura z městského znaku). Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Česká Ves",
        kod: 569356,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79081,
        latitude: 50.257454,
        longitude: 17.228153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/%C4%8Cesk%C3%A1_Ves_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s modrým zvlněným pruhem širokým tři osminy šířky listu. Modrý pruh má tři vrcholy a dvě prohlubně. Uprostřed zkřížené kladivo a plamenný meč hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě zkřížené kladivo a dolů obrácený plamenný meč, obojí stříbrné."
    },
    {
        obec: "České Budějovice",
        kod: 544256,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.975758,
        longitude: 14.480355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Coat_of_arms_of_%C4%8Cesk%C3%A9_Bud%C4%9Bjovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "České Heřmanice",
        kod: 580040,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56552,
        latitude: 49.931529,
        longitude: 16.253203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/%C4%8Cesk%C3%A9_He%C5%99manice.svg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed nad položenou bílou vrší dva přivrácení žlutí dvouocasí lvi s červenou zbrojí držící nad sebou knížecí korunu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad položenou stříbrnou vrší dva přivrácení zlatí dvouocasí lvi s červenou zbrojí držící nad sebou knížecí korunu."
    },
    {
        obec: "České Lhotice",
        kod: 571296,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.847036,
        longitude: 15.777938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Ceske_Lhotice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, žlutý, červený, žlutý a zelený, v poměru 6:1:10:1:6. V zelených pruzích po jednom žlutém obilném klasu se dvěma listy. V červeném pruhu bílý meč se žlutým jílcem, hrotem dolů, provázený při čepeli dvěma odvrácenými klíči zuby nahoru, na žerďové straně žlutým a na vlající straně bílým. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo nad dvěma vyrůstajícími palisádovými kůly dva obilné klasy s listy, vše zlaté, vlevo postavený stříbrný meč se zlatým jílcem, provázený dvěma vztyčenými odvrácenými gotickými klíči, pravý zlatý a levý stříbrný."
    },
    {
        obec: "České Libchavy",
        kod: 580058,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56114,
        latitude: 50.029927,
        longitude: 16.371803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/%C4%8Cesk%C3%A9_Libchavy.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, žlutý a zelený, v poměru 7:1:1:1. Na horním zeleném pruhu vykračuje k vlajícímu okraji červený kohout se žlutou zbrojí, proti němu vyrůstá černý lev s červenou zbrojí, držící zelenou lipovou ratolest se třemi listy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trojvrší se zlatým parohem položeným doleva stojí vpravo doleva obrácený červený kohout se zlatou zbrojí a vlevo vyrůstá černý lev s červenou zbrojí držící zelenou lipovou ratolest se třemi listy."
    },
    {
        obec: "České Meziříčí",
        kod: 576212,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.286898,
        longitude: 16.044385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Ceske_Mezirici_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou šikmo položenou pravou železnou rukavicí, držící na žluté udici se žlutým vlascem a háčkem bílou rybu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě šikmo položená stříbrná pravá železná rukavice držící na zlaté udici, se zlatým vlascem a háčkem, stříbrnou rybu."
    },
    {
        obec: "České Petrovice",
        kod: 580066,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.119374,
        longitude: 16.605474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/%C4%8Cesk%C3%A9_Petrovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed bílý meč se žlutým jílcem hrotem dolů převýšený žlutou korunou a provázený dvěma vztyčenými odvrácenými gotickými klíči zuby nahoru, žerďovým žlutým a vlajícím bílým. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě postavený stříbrný meč se zlatým jílcem převýšený zlatou korunou a provázený dvěma vztyčenými odvrácenými gotickými klíči, pravým zlatým a levým stříbrným."
    },
    {
        obec: "České Velenice",
        kod: 546089,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37810,
        latitude: 48.768608,
        longitude: 14.963775,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/%C4%8Cesk%C3%A9_Velenice.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů - modrý, bílý, červený, bílý a modrý v poměru 1:1:6:1:1. Ve středu červeného pole bílý kaštanový list. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Český Brod",
        kod: 533271,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.074304,
        longitude: 14.860919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/%C4%8Cesk%C3%BD_Brod_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a červený, v poměru 1 : 2 : 1. Z dolního okraje modrého pruhu vyniká do krajů modrého pruhu bílá kvádrovaná hradba s cimbuřím o jedenácti červeně krytých stínkách, prolomená černou branou s vytaženou bílou mříží a otevřenými žlutými vraty na černých závěsech. Uprostřed z hradby vyniká bílá věž s černým a bílým křížem děleným oknem, cimbuřím o čtyřech červeně krytých stínkách a červenou valbovou střechou se dvěma bílými makovicemi. V horní polovině žlutého pruhu černý orel se žlutou zbrojí, v horní polovině červeného pruhu český lev. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Český Dub",
        kod: 563960,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46343,
        latitude: 50.661116,
        longitude: 14.996287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/%C4%8Cesk%C3%BD_Dub_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 1 : 3 : 1. V prostředním pruhu vykořeněný dub s hnědým kmenem a větvemi se zelenými listy, s kořeny v dolním zeleném pruhu. Na větvích dvanáct žlutých žaludů v hnědých číškách. Poměr šířky k délce listu je 2 : 3"
    },
    {
        obec: "Český Jiřetín",
        kod: 567108,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43601,
        latitude: 50.70759,
        longitude: 13.547489,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Coa_Czech_Town_%C4%8Cesk%C3%BD_Ji%C5%99et%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů, střídavě zelených a bílých, bílé vlnité se třemi vrcholy a pěti prohlubněmi. V červeném pruhu bílá hlava beránka se žlutou svatozáří. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře stříbrný beránek boží se zlatým procesním křížem, dole dvě stříbrná vlnitá břevna."
    },
    {
        obec: "Český Krumlov",
        kod: 545392,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38101,
        latitude: 48.812835,
        longitude: 14.317566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/CZ_%C4%8Cesk%C3%BD_Krumlov_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:4:1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Český Rudolec",
        kod: 546097,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37883,
        latitude: 49.068454,
        longitude: 15.324472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/%C4%8Cesk%C3%BD_Rudolec_znak.png",
        coatOfArmsDescription: "V červeno-modře polceném štítě, vpravo český lev, vlevo stříbrná kvádrovaná hradba, z níž vyniká zlatá věž s cimbuřím."
    },
    {
        obec: "Český Šternberk",
        kod: 529541,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.810991,
        longitude: 14.928256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Cesky_sternberk_znak_obec.png"
    },
    {
        obec: "Čestice",
        kod: 550957,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.167929,
        longitude: 13.8038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/%C4%8Cestice_%28Strakonice_District%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List dělený bílým šikmým pruhem, širokým jednu čtvrtinu šířky listu, na modré žerďové a červené vlající pole. V modrém poli bílý kapr plující k vlajícímu okraji, v bílém pruhu pět kosmých zelených lipových listů, v červeném poli na volném zeleném návrší tři bílé latinské kříže, prostřední vyšší. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Český Těšín",
        kod: 598933,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73701,
        latitude: 49.747144,
        longitude: 18.623896,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Cesky_Tesin_CoA.png",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - červený, žlutý a modrý. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Čestice",
        kod: 576221,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.127918,
        longitude: 16.145832,
        coatOfArmsFlagDescription: "Zelený list s bílým koněm se žlutou zbrojí, hřívou a vztyčeným ocasem skákajícím k vlajícímu okraji přes žlutý vykořeněný pařez. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůň se zlatou zbrojí, hřívou a vztyčeným ocasem doleva skákající přes zlatý vykořeněný pařez."
    },
    {
        obec: "Čestín",
        kod: 534030,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28510,
        latitude: 49.807713,
        longitude: 15.104503,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Cestin_znak.jpg"
    },
    {
        obec: "Čestlice",
        kod: 538141,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 50.002617,
        longitude: 14.583719,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Cestlice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Ve žlutém pruhu šikmo černá hořící ostrev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vpravo tři modrá, do poloviny zkrácená břevna, vlevo šikmá černá hořící ostrev."
    },
    {
        obec: "Češov",
        kod: 572811,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.339204,
        longitude: 15.360429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/%C4%8Ce%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou palisádou vycházející z šesté a sedmé osminy žerďového a vlajícího okraje listu s hroty dosahujícími do devíti šestnáctin šířky listu. Z palisády vyrůstá bílý lev se žlutou zbrojí a jazykem provázený k žerdi třemi bílými mezikružími nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný lev se zlatou zbrojí a jazykem vyrůstající ze zlaté palisády na modré patě. Lev provázen vpravo třemi stříbrnými kruhy pod sebou."
    },
    {
        obec: "Číčenice",
        kod: 550965,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38771,
        latitude: 49.153152,
        longitude: 14.230951,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C4%8C%C3%AD%C4%8Denice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s černo-žluto-černě děleným žerďovým pruhem širokým šestinu délky listu. Uprostřed listu tři (2, 1) žluté obilné snopy převýšené knížecí korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo černo-zeleně polcený štít, vpravo zlaté břevno, vlevo tři (2, 1) zlaté obilné snopy převýšené knížecí korunou."
    },
    {
        obec: "Číčovice",
        kod: 539147,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25268,
        latitude: 50.156814,
        longitude: 14.249483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Cicovice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4:1. Do modrého pruhu vyniká z druhé a třetí čtvrtiny horního okraje zeleného pruhu zelený vrch se žlutou kaplí s pěti černými okny, třemi v patře a dvěma v přízemí po stranách černého vchodu, s červenou stanovou střechou, završenou červeným sanktusníkem s cibulovou bání se žlutou makovicí a křížem. Pod kaplí v zeleném poli tři bílé hřeby, dva zkřížené, hroty nahoru, přeložené třetím, hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší se třemi stříbrnými hřeby, dva zkřížené vztyčené přeložené postaveným, zlatá kaple s pěti černými okny, třemi v patře a dvěma v přízemí po stranách černého vchodu, s červenou stanovou střechou, završenou černým sanktusníkem s cibulovou bání se zlatou makovicí a křížem."
    },
    {
        obec: "Číhalín",
        kod: 590479,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.268545,
        longitude: 15.812163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/%C4%8C%C3%ADhal%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list. V žerďovém poli černá zubří hlava s červeným jazykem a žlutými rohy, žlutou houžví, ve vlajícím poli žlutý vrubový kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně šikmo děleném štítě nahoře černá zubří hlava se zlatými rohy a zlatou houžví, dole zlatý vrubový kříž."
    },
    {
        obec: "Číhaň",
        kod: 542024,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.34251,
        longitude: 13.425893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/%C4%8C%C3%ADha%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu bílo - červeně polcená orlí hlava se žlutým zobákem a s krkem provlečeným žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě přivrácené červeno - stříbrně a naopak polcené orlí hlavy se zlatými zobáky a s krky provlečenými zlatými korunami, mezi nimi vztyčený stříbrný kamenný klín převýšený zlatou osmihrotou hvězdou."
    },
    {
        obec: "Číhošť",
        kod: 568520,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58287,
        latitude: 49.741853,
        longitude: 15.33518,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Cihost_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V červeném pruhu bílá kostelní věž s černým oknem a černou přilbovou střechou zakončenou makovicí s křížkem. V bílém pruhu černý středový kříž s rameny širokými jednu devítinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrná kostelní věž s černým oknem a černou přilbovou střechou zakončenou makovicí s křížkem, v levém poli černý heroldský kříž."
    },
    {
        obec: "Čichalov",
        kod: 506621,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36452,
        latitude: 50.12436,
        longitude: 13.17119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C4%8Cichalov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový zubatý pruh široký třetinu délky listu s pěti čtvercovými zuby a čtyřmi stejnými mezerami a zelené pole. V pruhu čtyři černé merlety nad sebou, v poli bílá převrácená muří noha se žlutým tlapatým křížkem uprostřed. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou cimbuřovou hlavou se čtyřmi černými merletami stříbrná převrácená muří noha se zlatým tlapatým křížkem uprostřed."
    },
    {
        obec: "Číchov",
        kod: 590487,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.284719,
        longitude: 15.762199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/%C4%8C%C3%ADchov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 8 : 1 : 1 : 1 : 5. Modrý a bílé pruhy vlnité se třemi vrcholy a dvěma prohlubněmi. V horním pruhu dva vztyčené zvonkovité květy, v dolním šesticípá hvězda, oboje bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vlnitě stříbrno-modro-stříbrně dělené vlnité břevno, nahoře dva vztyčené zvonkovité květy, dole hvězda, vše stříbrné."
    },
    {
        obec: "Čikov",
        kod: 590495,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67578,
        latitude: 49.269605,
        longitude: 16.141012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Cikov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými štikami nad sebou, horní plovoucí k žerďovému, dolní k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrné štiky nad sebou, horní plovoucí doprava, dolní doleva."
    },
    {
        obec: "Čilá",
        kod: 540927,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.961766,
        longitude: 13.742166
    },
    {
        obec: "Čilec",
        kod: 599671,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28925,
        latitude: 50.219494,
        longitude: 14.981754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/%C4%8Cilec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý. V žerďové části zeleného pruhu tři bílé topoly, v modrém pruhu tři žluté šesticípé hvězdy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře děleném štítě nahoře vyrůstají tři stříbrné topoly, dole tři (2, 1) zlaté hvězdy."
    },
    {
        obec: "Číměř",
        kod: 546101,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37832,
        latitude: 49.060706,
        longitude: 15.073772,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Cimer_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Čimelice",
        kod: 549339,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.465681,
        longitude: 14.069328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Cimelice_erb.png",
        coatOfArmsFlagDescription: "Červeno-modře šikmým zvlněným řezem dělený list s pěti vrcholy a čtyřmi prohlubněmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře šikmo vlnitě děleném štítě nahoře doleva obrácená stříbrná husa se zlatou zbrojí, dole dvě zlaté vztyčené a odvrácené válečné kosy."
    },
    {
        obec: "Číměř",
        kod: 590509,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67501,
        latitude: 49.200243,
        longitude: 15.998557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Cimer_coa.svg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed kosmo vztyčená bílá motyka se žlutou násadou šikmo podložená žlutou berlou závitem k vlajícímu okraji a s bílým sudariem s červeným tlapatým křížkem vlajícím k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě kosmo vztyčená stříbrná motyka se zlatou násadou, šikmo podložená zlatou berlou, závitem doleva, se stříbrným sudariem s červeným tlapatým křížkem vlajícím doprava."
    },
    {
        obec: "Čím",
        kod: 540081,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.77862,
        longitude: 14.377907,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Coats_of_arms_%C4%8C%C3%ADm.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý vlnitý a červený. Bílý pruh je široký jednu dvanáctinu šířky listu. V modrém pruhu žluté slunce, v červeném zkřížené bílé sekery se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně zúženým stříbrným vlnitým břevnem děleném štítě nahoře zlaté slunce, dole dvě zkřížené stříbrné sekery se zlatými topůrky."
    },
    {
        obec: "Čímice",
        kod: 578495,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.253746,
        longitude: 13.605991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/%C4%8C%C3%ADmice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 5 : 3. V horním zeleném pruhu zkřížený procesní tlapatý kříž a berla závitem k vlajícímu okraji listu, obojí žluté a dole přeložené rozevřenou bílou knihou s červenými deskami. Dolní pruh polcený, v žerďové polovině čtyři vodorovné pruhy střídavě bílé a modré, vlající polovina červená. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít s polcenou patou. Nahoře zkřížený procesní tlapatý kříž a berla závitem doleva, obojí zlaté a dole přeložené rozevřenou stříbrnou knihou s červenými deskami. Pata vpravo stříbrno-modře třikrát dělená, vlevo červená."
    },
    {
        obec: "Činěves",
        kod: 537080,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28901,
        latitude: 50.230096,
        longitude: 15.213992
    },
    {
        obec: "Čisovice",
        kod: 539155,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25204,
        latitude: 49.86348,
        longitude: 14.314883,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/%C4%8Cisovice_Znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a vlnitých modrých s pěti vrcholy a čtyřmi prohlubněmi, v poměru 12 : 1 : 1 : 1 : 1. V horním pruhu šikmo vztyčená zelená větev tisu s červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě šikmo vztyčená zelená větev tisu s červenými plody nad třikrát modro-stříbrně vlnitě dělenou vlnitou patou."
    },
    {
        obec: "Čistá",
        kod: 577944,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56956,
        latitude: 49.827322,
        longitude: 16.328783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/%C4%8Cist%C3%A1_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, a tři vodorovné pruhy u dolního okraje listu, bílý, modrý a bílý, každý široký osminu šířky listu. Z horního bílého pruhu vyrůstá do červeného pole žlutá berla závitem k žerďovému okraji, v modrém poli žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě, nad stříbrnou patou s modrým břevnem, vpravo vyrůstající berla, vlevo lilie, obojí zlaté."
    },
    {
        obec: "Čistá",
        kod: 535630,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29423,
        latitude: 50.472083,
        longitude: 14.843595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/%C4%8Cist%C3%A1_%28okres_Mlad%C3%A1_Boleslav%29_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s červeným žerďovým rovnoramenným trojúhelníkem s vrcholem v horním rohu listu. V žerďové a střední části listu bílo-modře šikmo dělený korunovaný dvouocasý lev, v červeném poli se žlutou a ve žlutém poli s červenou zbrojí, držící vztyčený žlutý rošt. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě šikmo děleném štítě stříbrno-modře šikmo dělený korunovaný dvouocasý lev, nahoře se zlatou a dole s červenou zbrojí, držící vztyčený zlatý rošt."
    },
    {
        obec: "Čistá",
        kod: 541699,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27034,
        latitude: 50.099047,
        longitude: 13.733683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/%C4%8Cist%C3%A1_Rakovn%C3%ADk-znak.gif",
        coatOfArmsFlagDescription: "ISOList tvoří čtyři vodorovné pruhy, žlutý, červený, bílý a červený, v poměru 5 : 1 : 1 : 1. Do žlutého pruhu vyrůstá sv. Václav v bílé zbroji s knížecí korunou, držící v pravici dvoucípý praporec a v levici štít, obojí bílé s černou orlicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně děleném štítě nahoře vyrůstající sv. Václav ve stříbrné zbroji s knížecí korunou, držící v pravici vlající dvoucípý praporec a v levici štít, obojí stříbrné s černou orlicí, dole stříbrné břevno."
    },
    {
        obec: "Čistá u Horek",
        kod: 577057,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51235,
        latitude: 50.5317,
        longitude: 15.60765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/%C4%8Cist%C3%A1_u_Horek_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, vlnitý modrý se dvěma vrcholy a třemi prohlubněmi, bílý zvlněný a modrý, v poměru 1 : 1 : 1 : 9. Ve střední části listu mezi zkříženými bílými limpami žlutá berla závitem k žerďovému okraji listu, v závitu bílý květ lnu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vpravo polcený štít. Vpravo modrý vlnitý kůl, vlevo mezi zkříženými stříbrnými limpami zlatá berla se stříbrným květem lnu se zlatým středem v závitu."
    },
    {
        obec: "Čistěves",
        kod: 569933,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.28893,
        longitude: 15.731935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/%C4%8Cist%C4%9Bves_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý, v poměru 1 : 3 : 1. V červeném pruhu nad dvěma dole zkříženými žlutými jasanovými listy dvě zkřížené bílé šavle se žlutými jílci, hroty a ostřím k dolnímu okraji, převýšené žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad dvěma dole zkříženými zlatými jasanovými listy dvě zkřížené postavené stříbrné šavle se zlatými jílci, převýšené zlatou korunou."
    },
    {
        obec: "Čižice",
        kod: 557641,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33209,
        latitude: 49.648043,
        longitude: 13.397992,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C4%8Ci%C5%BEice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a červený v poměru 1 : 2 : 1. V modrém pruhu bílý lamačský klín podložený dvěma zkříženými žlutými šípy s tupými hroty a s červeným opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře sníženě děleném štítě nahoře vyrůstající zlatá lilie, na jejíž ramenech sedí dva přivrácení stříbrní ptáčci s modrou zbrojí, dole stříbrný lamačský klín podložený dvěma zkříženými vztyčenými zlatými šípy s tupými hroty a s červeným opeřením."
    },
    {
        obec: "Čížkov",
        kod: 598704,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.440552,
        longitude: 15.118556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/%C4%8C%C3%AD%C5%BEkov_%28Pelh%C5%99imov_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list se žlutým čížkem s černými pery a s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě zlatý čížek s černými pery a s červenou zbrojí."
    },
    {
        obec: "Čížkov",
        kod: 557650,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33564,
        latitude: 49.540479,
        longitude: 13.683496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/%C4%8C%C3%AD%C5%BEkov_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s vykořeněným zeleným dubem s osmi žlutými žaludy a s hnědým kmenem. Přes dolní cíp šikmo pět vlnitých střídavě modrých a bílých pruhů, vycházejících z dvanácté až osmnácté jedenadvacetiny dolního okraje do páté až jedenácté čtrnáctiny vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněný dub přirozené barvy s osmi zlatými žaludy nad modrou vlnitou patou se dvěma stříbrnými vlnitými břevny."
    },
    {
        obec: "Čížkovice",
        kod: 564711,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41112,
        latitude: 50.484284,
        longitude: 14.028506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/%C4%8C%C3%AD%C5%BEkovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu s červeným středovým křížem s rameny širokými jednu dvanáctinu délky listu, a dva vodorovné pruhy, žlutý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít, nahoře červený heroldský kříž, dole položené červeno-zlatě šachované orlí křídlo."
    },
    {
        obec: "Čížkrajice",
        kod: 551503,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.806592,
        longitude: 14.636226,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/%C4%8C%C3%AD%C5%BEkrajice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy v poměru 3 : 2, žerďový žluto-zeleně kosmo dělený a vlající popeličinový. V žerďovém pruhu z dělící linie vyrůstají nahoru i dolu dva lipové listy opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Horní pole kosmo zeleno-zlatě dělené, z dělící linie vyrůstají nahoru i dolu dva lipové listy opačných barev. V dolním popeličinovém poli zavřená dřevěná vrata přirozené barvy se stříbrným ostěním a černými závěsy."
    },
    {
        obec: "Čížov",
        kod: 587117,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58601,
        latitude: 49.38725,
        longitude: 15.575048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/%C4%8C%C3%AD%C5%BEov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 8 : 1 : 1 : 1 : 5. Tři prostřední pruhy oboustranně vlnkovité. V horním pruhu čížek, v dolním pruhu dvě bílé křížové kypřice vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě snížená zúžená stříbrná vlnkovitá břevna. Nad nimi čížek přirozené barvy, dole dvě stříbrné křížové kypřice vedle sebe."
    },
    {
        obec: "Čížová",
        kod: 549347,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39831,
        latitude: 49.356861,
        longitude: 14.093158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Cizova_znak.png",
        coatOfArmsFlagDescription: "Zelený list se žlutou svatojakubskou mušlí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá svatojakubská mušle."
    },
    {
        obec: "Čkyně",
        kod: 550167,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.115134,
        longitude: 13.829161,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Ckyne_znak.jpg"
    },
    {
        obec: "Člunek",
        kod: 561703,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37861,
        latitude: 49.111546,
        longitude: 15.12686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/%C4%8Clunek_%28Jind%C5%99ich%C5%AFv_Hradec_District%29_CoA.jpg"
    },
    {
        obec: "Čmelíny",
        kod: 578568,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.488923,
        longitude: 13.654594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/%C4%8Cmel%C3%ADny_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým liliovým křížem přeloženým žlutou podkovou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný liliový kříž přeložený zlatou podkovou."
    },
    {
        obec: "Čtveřín",
        kod: 544531,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.592605,
        longitude: 15.100281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/%C4%8Ctve%C5%99%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 3 : 1. V zeleném pruhu žlutý lev s červenou zbrojí a s červeným šálem kolem krku, vzadu se třemi konci, prostředním červeným s položenou žlutou prázdnou routou mezi dvěma hermelínovými. Lev drží levou tlapou kosmo žlutou žerď se žluto-modře čtvrceným praporcem se dvěma cípy. Ve žlutém pruhu položený zelený dubový list s červeným žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou patou s položeným zeleným dubovým listem s červeným žaludem zlatý lev s červenou zbrojí a s červeným šálem kolem krku, vzadu se třemi konci, prostředním červeným s položenou zlatou prázdnou routou mezi dvěma hermelínovými. Lev drží levou tlapou kosmo zlatou žerď se zlato-modře čtvrceným praporcem se dvěma cípy."
    },
    {
        obec: "Čtyřkoly",
        kod: 529567,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25722,
        latitude: 49.868613,
        longitude: 14.719742
    },
    {
        obec: "Čučice",
        kod: 582930,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.137181,
        longitude: 16.278687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Cucice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a bílý, v poměru 1:2. V horním žlutá pěti hrotá koruna, v dolním modrý vinný hrozen se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se zlatou pětihrotou korunou ve stříbrném poli modrý vinný hrozen se dvěma zelenými listy."
    },
    {
        obec: "Dačice",
        kod: 546127,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.07992,
        longitude: 15.434304,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Coat_of_arms_of_Da%C4%8Dice.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - modrý, žlutý, modrý v poměru 1:2:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dalečín",
        kod: 595454,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59241,
        latitude: 49.59137,
        longitude: 16.240185,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Znak_obce_Dale%C4%8D%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3:1. Na zeleném pruhu dvě bílé kvádrované věže, každá s cimbuřím a jedním červeným oknem spojené hradbou s červenou branou a cimbuřím, mezi věžemi žlutá šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší dvě stříbrné kvádrované věže, každá s jedním červeným oknem a cimbuřím, spojené hradbou s červenou branou a cimbuřím, mezi věžemi zlatá hvězda."
    },
    {
        obec: "Daleké Dušníky",
        kod: 540099,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.727533,
        longitude: 14.186636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Dalek%C3%A9_Du%C5%A1n%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, červený, modrý a žlutý, v poměru 1 : 3 : 3 : 1. Z vlajícího okraje listu vyniká žlutý latinský liliový kříž s rameny širokými šestnáctinu šířky listu, hroty lilií se dotýkají vnitřních okrajů žlutých pruhů a žerďového okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vynikající liliový kříž do krajů, provázený dole po obou stranách korunou, vše zlaté."
    },
    {
        obec: "Dalešice",
        kod: 590517,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67554,
        latitude: 49.129105,
        longitude: 16.08201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Dale%C5%A1ice_%28T%C5%99eb%C3%AD%C4%8D_District%29_CoA.png",
        coatOfArmsFlagDescription: "Ve stříbrném štítě na skále přirozené barvy červená okrouhlá věž s ochozem a kuželovou střechou zakončenou zlatou makovicí s křížkem. Věž má obloukový vchod a dvě kruhové střílny v patře, vše černé."
    },
    {
        obec: "Dalešice",
        kod: 530425,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46802,
        latitude: 50.681858,
        longitude: 15.184679,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Dale%C5%A1ice_%28Jablonec_nad_Nisou%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 2 : 1 : 2. V horním červeném pruhu dvě bílé dalešické perly navlečené na bílé vodorovně položené sklářské jehle. V dolním červeném pruhu vodorovně položené bílé sklářské mačkářské kleště. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se dvěma dalešickými perlami přirozené barvy navlečenými na stříbrné sklářské jehle stojí na červené patě s položenými stříbrnými sklářskými mačkářskými kleštěmi stříbrná budova mačkárny (drikety) s červenou střechou a stříbrným komínem vlevo."
    },
    {
        obec: "Dalovice",
        kod: 537918,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36263,
        latitude: 50.247898,
        longitude: 12.895923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Dalovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List má dva vodorovné pruhy, žlutý a zelený. V horním žerďovém rohu zelený dubový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněný dub provázený nahoře žaludem a listem, vše přirozených barev."
    },
    {
        obec: "Dalovice",
        kod: 570818,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.425788,
        longitude: 14.880041
    },
    {
        obec: "Dambořice",
        kod: 586129,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69635,
        latitude: 49.038383,
        longitude: 16.917678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Znak_obce_Dambo%C5%99ice.svg",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové a dolní vlající pole bílé. Dolní žerďové a horní vlající pole tvoří pět vodorovných pruhů střídavě černých a bílých. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-stříbrně dělený štít, nahoře dvě stříbrná břevna."
    },
    {
        obec: "Damnice",
        kod: 593907,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.920412,
        longitude: 16.374203
    },
    {
        obec: "Damníkov",
        kod: 580074,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56123,
        latitude: 49.871344,
        longitude: 16.560355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Damn%C3%ADkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy bílý, modrý a bílý v poměru 1:4:1. V modrém pruhu bílá věž s červenou klíčovou střílnou, na kvádrovaném soklu, s červenou jehlanovou střechou se žlutou makovicí a praporkem - plamenem se dvěma vodorovnými pruhy, žlutým a červeným. Věž je podložená žlutými zkříženými přivrácenými berlami se žluto-červenými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž na kvádrovaném soklu s červenou klíčovou střílnou provázená na okrajích dvěma stříbrnými věžemi s jedním červeným oknem, všechny tři věže mají červené jehlanové střechy zakončené zlatou makovicí a zlatočerveným doleva vlajícím praporkem - plamenem. Mezi věžemi dvě zlaté odvrácené berly s červeno-zlatými sudarii."
    },
    {
        obec: "Daňkovice",
        kod: 595462,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.654082,
        longitude: 16.148711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Da%C5%88kovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené žerďové pětiúhelníkové pole s vrcholem ve čtvrtině délky listu a k němu přiléhající bílá vidlice s rameny širokými čtvrtinu šířky listu. Horní a dolní vlající pole modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrým zvýšeným trojvrším se stříbrným kalichem dva vztyčené odvrácené zelené lipové listy."
    },
    {
        obec: "Darkovice",
        kod: 568228,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74717,
        latitude: 49.936016,
        longitude: 18.222234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Coa_Czech_Town_Darkovice.svg",
        coatOfArmsFlagDescription: "Modrý list s poloviční žlutou lilií spojenou s bílou radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě půl zlaté lilie spojená se stříbrnou radlicí."
    },
    {
        obec: "Daskabát",
        kod: 552445,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.578642,
        longitude: 17.447349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Znak_obce_Daskab%C3%A1t.gif",
        coatOfArmsFlagDescription: "Zelený list se třemi uťatými bílými stromy, vyšší jehličnatý mezi listnatými, dole kosmo podložený žlutou radlicí ostřím k žerdi a hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři uťaté stříbrné stromy, vyšší jehličnatý mezi listnatými, dole kosmo podložený vztyčenou zlatou radlicí."
    },
    {
        obec: "Dasnice",
        kod: 560332,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35709,
        latitude: 50.146566,
        longitude: 12.567344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Dasnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, horní zelený a dolní žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě zvýšeně dělený štít. V horním poli pět zlatých obilných klasů s osinami; od středového klasu jsou dvojice klasů symetricky odkloněny. V dolním poli okřídlené železniční kolo, pod ním zkřížená hornická kladívka se zavěšeným kahanem - vše černé."
    },
    {
        obec: "Dasný",
        kod: 535249,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.019337,
        longitude: 14.407512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Dasn%C3%BD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký čtvrtinu délky listu a dva vodorovné pruhy zelený a modrý, v nich bílá vykořeněná vrba. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě stříbrná vykořeněná vrba převýšená zlatou korunou."
    },
    {
        obec: "Dašice",
        kod: 574899,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53303,
        latitude: 50.028543,
        longitude: 15.912545,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Da%C5%A1ice_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů střídavě červených a bílých v poměru 1:3:9:3:1. V prostředním pruhu bílý dvouocasý lev se žlutou korunou a zbrojí zády k žerdi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Davle",
        kod: 539163,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25206,
        latitude: 49.89228,
        longitude: 14.400478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Davle_znak.svg",
        coatOfArmsFlagDescription: "Červený list, uprostřed k vlajícímu okraji obrácená hlava sv. Jana Křtitele ve žluté míse, obklopená žlutou svatozáří s jedenácti paprsky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ve zlaté míse doleva obrácená hlava sv. Jana Křtitele přirozené barvy obklopená zlatou svatozáří s jedenácti paprsky."
    },
    {
        obec: "DĚČÍN",
        kod: 562335,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.772656,
        longitude: 14.212861,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné zvlněné pruhy - bílý, žlutý a červený. Žlutý má dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Deblín",
        kod: 582948,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66475,
        latitude: 49.320459,
        longitude: 16.346542,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Deblin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým kopřivovým listem, na němž je položeno obdélníkové modré pole s bílou kvádrovou věží, s cimbuřím se třemi zuby, černou branou a dvěma okny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném kopřivovém listu modrý štítek se stříbrnou kvádrovanou věží s cimbuřím o třech stínkách, s černou branou a dvěma okny."
    },
    {
        obec: "Děčany",
        kod: 564729,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.438699,
        longitude: 13.908578
    },
    {
        obec: "Dědice",
        kod: 590525,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67541,
        latitude: 49.029803,
        longitude: 15.727855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/CoA_D%C4%9Bdice_TR.svg",
        coatOfArmsFlagDescription: "Modrý list s kosmým klínem, vycházejícím ze čtvrté čtvrtiny vlajícího okraje a z třetí třetiny dolního okraje listu, s vrcholem v horním rohu. V horním cípu srdce, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácená vztyčená radlice provázená vlevo nahoře srdcem, obojí stříbrné."
    },
    {
        obec: "Dědová",
        kod: 571300,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.761203,
        longitude: 15.986569
    },
    {
        obec: "Dehtáře",
        kod: 561924,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.486202,
        longitude: 15.275386
    },
    {
        obec: "Děhylov",
        kod: 568236,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74794,
        latitude: 49.869481,
        longitude: 18.163431,
        coatOfArmsFlagDescription: "Zelený list s bílým lvem se žlutou zbrojí v žerďové části zády k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě doleva obrácený stříbrný lev se zlatou zbrojí."
    },
    {
        obec: "Děkanovice",
        kod: 532746,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.618789,
        longitude: 15.148645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/D%C4%9Bkanovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed zkřížené klíče, nahoře provázené svěšeným lipovým listem, dole růží s červeným semeníkem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené klíče provázené nahoře svěšeným lipovým listem a dole růží s červeným semeníkem, vše zlaté."
    },
    {
        obec: "Děkov",
        kod: 565181,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.171283,
        longitude: 13.554483
    },
    {
        obec: "Děpoltovice",
        kod: 538116,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36225,
        latitude: 50.294681,
        longitude: 12.814015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Depoltovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý pilovitý žerďový pruh široký jednu třetinu délky listu se třemi celými a dvěma polovičními trojúhelníkovými listy a dva vodorovné pruhy, červený a zelený, v poměru 3:1. Výška zubů je jedna šestina délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze zeleného návrší zlatá berla se stříbrným rozevlátým velem, závitem doleva, přeložená zlatým štítkem se trojím (2,1) černým jelením parožím."
    },
    {
        obec: "Desná",
        kod: 563552,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46861,
        latitude: 50.75481,
        longitude: 15.313862,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Desn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, žlutý a zelený, v poměru 6:1:1:6. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva smrky přirozené barvy provázené rozděleným zlatým letopočtem 1691 a vyrůstající ze dvou zvýšených zelených návrších, mezi nimi vytéká stříbrná řeka na mlýnské kolo přirozené barvy."
    },
    {
        obec: "Desná",
        kod: 572357,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.807641,
        longitude: 16.224152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Desn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými svislými pruhy. Poměr vzniklých polí 4:3:2:1:10. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná snítka rdesna s jedním klasem a čtyřmi listy."
    },
    {
        obec: "Dešenice",
        kod: 556041,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34022,
        latitude: 49.27441,
        longitude: 13.171052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Desenice_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a žlutý. V modrém pruhu bílá hlava jelena osmeráka se žlutým parožím a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná hlava jelena osmeráka s červeným jazykem a zlatým parožím, mezi ním stříbrná třípatrová věž se zlatou stanovou střechou, černou prolomenou branou a pěti (1,2,2) černými okny."
    },
    {
        obec: "Dešná",
        kod: 546143,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37873,
        latitude: 48.957675,
        longitude: 15.542926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Coa_Czech_Town_De%C5%A1n%C3%A1.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi šikmými pruhy, bílým, červeným a bílým, které vycházejí z třetin žerďového okraje do první, druhé a třetí šestiny horního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený modrý hrot se stříbrným srdcem kvetoucím třemi růžemi s červenými semeníky na stoncích, krajní se dvěma, a prostřední se třemi trny. V rozích šikmé a kosmé červené břevno."
    },
    {
        obec: "Dešná",
        kod: 585157,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.266709,
        longitude: 17.846028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/De%C5%A1n%C3%A1_ZL_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1:3:1. V zeleném pruhu polovina divého muže se žlutými vlasy, vousy a nataženým lukem se šípem, spojená s polovinou bílého koně se žlutými kopyty a ohonem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě polovina divého muže přirozené barvy se zlatými vlasy, vousem a nataženým lukem se šípem, spojená s dolní polovinou stříbrného koně se zlatými kopyty a ohonem."
    },
    {
        obec: "Dešov",
        kod: 590533,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67533,
        latitude: 48.985724,
        longitude: 15.70151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/De%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se šikmým, žluto-modro-bíle děleným pruhem širokým tři čtvrtiny šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém zlatém poli přirozená růže s čelně postaveným květem na stonku se dvěma listy, vše červené, semeník zlatý. Levé pole modro-červeně dělené stříbrným, vzhůru zalomeným břevnem, na něm stříbrná vykračující rozkřídlená volavka se zlatou zbrojí."
    },
    {
        obec: "Deštná",
        kod: 546151,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37825,
        latitude: 49.265237,
        longitude: 14.924058,
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu pětinu délky listu a bílé pole s červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Deštná",
        kod: 581518,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.612653,
        longitude: 16.563659,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý s modrou kapkou a červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně cimbuřovitě děleném štítě nahoře doleva položený klíč zuby nahoru, pod ním položený meč, obojí modré. Dole ve stříbrném zúženém hrotu modrá kapka."
    },
    {
        obec: "Deštné v Orlických horách",
        kod: 576247,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51791,
        latitude: 50.304743,
        longitude: 16.350751,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/De%C5%A1tn%C3%A9_v_Orl._hor%C3%A1ch_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílý list se třemi modrými kapkami nad vrcholy zeleného trojvrší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě ze zeleného trojvrší vyrůstá jasan přirozené barvy, nad ním tři modré dešťové kapky."
    },
    {
        obec: "Deštnice",
        kod: 566128,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.230738,
        longitude: 13.609915,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/De%C5%A1tnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, zelený a žlutý, v poměru 4:1:1:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě děleném štítě nahoře zlaté jelení parohy, dole jehličnatá větévka a větévka chmele se třemi šišticemi a dvěma listy, obojí zelené, vztyčené a odvrácené."
    },
    {
        obec: "Dětenice",
        kod: 572829,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50724,
        latitude: 50.36818,
        longitude: 15.170627
    },
    {
        obec: "Dětkovice",
        kod: 592978,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68323,
        latitude: 49.272783,
        longitude: 17.144316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Znak_D%C4%9Btkovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žlutá rozletitá střela. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě čelně stojící rytíř v modré kroužkové zbroji překryté zlato-modře čtvrceným šatem, s mečem u boku, držící v pravici na červené žerdi modrý praporec s ocasem se zlatou rozletitou střelou a v levici modrou, zlatě zdobenou hrncovou přilbu."
    },
    {
        obec: "Dětkovice",
        kod: 589420,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79804,
        latitude: 49.415458,
        longitude: 17.082286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/D%C4%9Btkovice_PV_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s polovinou černé zubří hlavy s červeným jazykem a žlutou houžví, přiléhající k červeně úhlopříčně mřežované vlající polovině listu. V dolním rohu modrá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě nad zlatou patou s červenou kosmou mříží vpravo polovina černé zubří hlavy s červeným jazykem se zlatou houžví přiléhající k poltící linii a provázená modrou osmihrotou hvězdou, vlevo stříbrná zavinutá střela."
    },
    {
        obec: "Dětmarovice",
        kod: 598941,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73571,
        latitude: 49.894214,
        longitude: 18.460901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Dziecmorowice_herb.png",
        coatOfArmsFlagDescription: "Modrý list s bílým vlnitým vodorovným pruhem se šesti vrcholy a pěti prohlubněmi širokými 1/10 šířky listu a vzdáleným od horního okraje listu 6/10 šířky listu. V horním rohu žluté oběžné kolo turbíny, v dolním rohu bílý květ koukolu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem zlaté oběžné kolo turbíny, dole stříbrný květ koukolu."
    },
    {
        obec: "Dětřichov",
        kod: 546607,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.892748,
        longitude: 15.036676,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Detrichov_LB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list. V žerďové části červený paroh obrácený k vlajícímu okraji, ve střední a vlající části modrá lilie nahoře kvetoucí do stran dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky, prostrčená červeným tkalcovským člunkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrá lilie nahoře kvetoucí do stran dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky, prostrčená červeným tkalcovským člunkem. Vše převýšeno doleva položeným červeným parohem."
    },
    {
        obec: "Dětřichov",
        kod: 577961,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.80007,
        longitude: 16.532701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Detrichov_SY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé žerďové pruhy, modrý a žlutý, a modré čtvercové pole, v poměru 3:1:8. V modrém žerďovém pruhu dvě bílé lilie nad sebou, mezi nimi kosmo klíč zuby dolů a k hornímu cípu, šikmo podložený mečem hrotem k dolnímu rohu, oboje žluté. Ve vlajícím modrém poli polovina bílého koně ve skoku s červenou zbrojí a uzděním a žlutou hřívou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou polovinou koně ve skoku s červenou zbrojí a uzděním a zlatou hřívou kosmo položený gotický klíč zuby dolů a ven šikmo podložený postaveným mečem, obojí zlaté, provázené dvěma stříbrnými liliemi."
    },
    {
        obec: "Dětřichov nad Bystřicí",
        kod: 597252,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79303,
        latitude: 49.834288,
        longitude: 17.401831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/D%C4%9Bt%C5%99ichov_nad_Byst%C5%99ic%C3%AD_znak.png",
        coatOfArmsFlagDescription: "Zelený list se zkříženými bílými sekerami, mezi jejichž topůrka vyrůstá z dolního okraje listu přirozená žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zkříženými stříbrnými sekerami vyrůstá zlatá přirozená lilie."
    },
    {
        obec: "Dětřichov u Moravské Třebové",
        kod: 572683,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.787241,
        longitude: 16.710609,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/D%C4%9Bt%C5%99ichov_u_Moravsk%C3%A9_T%C5%99ebov%C3%A9_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zubatý žlutý a červený, v poměru 8:5:18. V žerďovém pruhu tři bílé šestilisté květy se žlutými středy, ve vlajícím pruhu zkřížené žluté hrábě. Žlutý pruh má pět zubů a čtyři mezery obrácené k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý most s jedním obloukem a s cimbuřím, provázený nahoře třemi šestilistými stříbrnými květy se zlatými středy, dole zkříženými zlatými hráběmi."
    },
    {
        obec: "Dílce",
        kod: 549118,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.467293,
        longitude: 15.359434
    },
    {
        obec: "Díly",
        kod: 553557,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.446373,
        longitude: 12.786498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/D%C3%ADly_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký dvě sedminy délky listu a devět vodorovných pruhů střídavě žlutých a černých v poměru 4:1:4:1:4:1:4:1:4. V zeleném pruhu tři žluté jedlové šišky pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou hlavou se třemi zlatými jedlovými šiškami čtyři černé zúžené kůly."
    },
    {
        obec: "Dírná",
        kod: 552208,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39127,
        latitude: 49.242967,
        longitude: 14.844867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/D%C3%ADrn%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list s žerďovým horním červeným a dolním zeleným polem, s vlajícím horním černým a dolním bílým polem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-černě, dole zeleno-stříbrně polcený, v dolní polovině svislý lipový list opačných barev."
    },
    {
        obec: "Diváky",
        kod: 584401,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69171,
        latitude: 48.988794,
        longitude: 16.791869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Coat_of_arms_of_Divaky.JPG",
        coatOfArmsFlagDescription: "Zelený list, v horní polovině bílý šraňk, v dolní polovině bílá lilie a žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrným šraňkem vpravo stříbrná lilie, vlevo zlatý vinný hrozen."
    },
    {
        obec: "Dívčí Hrad",
        kod: 551864,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.243942,
        longitude: 17.633923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Divci_Hrad_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým maltézským křížem v žerďové polovině. Na dolním okraji listu žlutý zubatý pruh široký jednu čtvrtinu šířky listu se šesti čtvercovými zuby, pěti čtvercovými mezerami a dvěma polovičními mezerami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstající zlatovlasá dívka ve stříbrném, modře lemovaném šatě, držící v pravici hrad a v levici osmihrotý kříž, obojí zlaté."
    },
    {
        obec: "Dívčí Kopy",
        kod: 529753,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.260383,
        longitude: 15.046007
    },
    {
        obec: "Dívčice",
        kod: 544361,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37348,
        latitude: 49.108733,
        longitude: 14.309399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Divcice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Zeleno - modře šikmo dělený list s pěti (2 ,1, 2) bílými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře šikmo děleném štítě pět (2, 1, 2) stříbrných lilii."
    },
    {
        obec: "Divec",
        kod: 569941,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50003,
        latitude: 50.239875,
        longitude: 15.921759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/CoA_of_Divec.svg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým pilovitým pruhem širokým jednu třetinu délky listu. Pruh má sedm zubů a dva poloviční zuby, mezery mezi nimi dosahují k první čtvrtině délky listu. V červeném poli kosmo bílé kovářské kladivo se žlutým topůrkem šikmo proložené bílými kovářskými kleštěmi. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou plamennou hlavou kosmé stříbrné kovářské kladivo se zlatým topůrkem šikmo proložené stříbrnými kovářskými kleštěmi."
    },
    {
        obec: "Divišov",
        kod: 529621,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.788592,
        longitude: 14.875895,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/ZnakDivisov.png"
    },
    {
        obec: "Dlažkovice",
        kod: 564737,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.465654,
        longitude: 13.96506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Dla%C5%BEkovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu se třemi broušenými červenými českými granáty nad sebou a modré pole s kvádrovanou studnou se zavěšeným dřevěným okovem, nahoře provázenou dvěma zvony, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zlatým lemem posázeným broušenými červenými českými granáty kvádrovaná studna se zavěšeným dřevěným okovem provázená nahoře dvěma zvony, vše stříbrné."
    },
    {
        obec: "Dlažov",
        kod: 556068,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.365855,
        longitude: 13.16645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Dla%C5%BEov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré pole a dva svislé vlající pruhy, bílý a zelený, každý široký dvanáctinu délky listu. Uprostřed listu žluté vozové kolo se čtyřmi bílými šesticípými hvězdami místo kosmých a šikmých ramen. V horním rohu listu žlutá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším zlaté vozové kolo se čtyřmi rameny, provázené po stranách čtyřmi (2, 2) stříbrnými a dole zlatou hvězdou. Pod ní do oblouku přes návrší šňůra sedmi stříbrných perel."
    },
    {
        obec: "Dlouhá Brtnice",
        kod: 587010,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58834,
        latitude: 49.236176,
        longitude: 15.604269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Coat_of_arms_of_Dlouh%C3%A1_Brtnice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý s červenou knížecí čepicí se žlutou obroučkou a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou hlavou s červenou knížecí čepicí zlatá maršálská hůl mezi dvěma stříbrnými buvolími rohy."
    },
    {
        obec: "Dlouhá Lhota",
        kod: 563650,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39155,
        latitude: 49.352618,
        longitude: 14.789052
    },
    {
        obec: "Dlouhá Lhota",
        kod: 581526,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.428757,
        longitude: 16.5097,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Dlouh%C3%A1_Lhota_%28Blansko%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a červený, v poměru 1 : 2 : 1. V žerďovém pruhu tři žerďové klíny, ve vlajícím pruhu tři vlající klíny, všechny bílé a s vrcholem na okraji modrého pruhu. V modrém pruhu žlutý kalich s nohou ozdobenou třemi drahokamy vedle sebe, zeleným mezi červenými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad červenou patou se třemi stříbrnými kužely zlatý kalich s nohou ozdobenou třemi drahokamy vedle sebe, zeleným mezi červenými, provázený dvěma odvrácenými zlatými lipovými ratolestmi se třemi listy. Na horních listech sedí dva přivrácení stříbrní ptáčci s červenou zbrojí."
    },
    {
        obec: "Dlouhá Lhota",
        kod: 535656,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29405,
        latitude: 50.420201,
        longitude: 15.054223,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Dlouh%C3%A1_Lhota_MB_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem širokým dvacetinu šířky listu, uprostřed přeloženým žlutou zvonicí s červenou bání zakončenou žlutým křížkem, s černým obloukovým oknem se zavěšeným žlutým zvonem. Zvonice je provázená v žerďové a vlající části listu položenými odvrácenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "V modrém štítě zlatá zvonice s červenou bání, zakončenou zlatým křížkem, černým obloukovým oknem, v němž je zavěšen zlatý zvon. Zvonice podložená zkříženými stříbrnými liliovými hůlkami a provázená položenými odvrácenými zlatými lipovými listy."
    },
    {
        obec: "Dlouhá Lhota",
        kod: 513504,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.721224,
        longitude: 14.120705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Dlouh%C3%A1_Lhota_%28P%C5%99%C3%ADbram_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém pruhu žlutá věž se vztyčenou červenou střelou s modrým hrotem a bílým opeřením a s červenou valbovou střechou. V bílém pruhu tři červení kapři nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. Vpravo zlatá věž se vztyčenou červenou střelou s modrým hrotem a stříbrným opeřením a s červenou valbovou střechou. Vlevo tři červení kapři nad sebou. V patě štítu vlnité břevno opačných barev."
    },
    {
        obec: "Dlouhá Loučka",
        kod: 501476,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78386,
        latitude: 49.816357,
        longitude: 17.179781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Dlouha_Loucka_%28Olomouc%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy červený žerďový se dvěma bílými žerďovými klíny s vrcholy v polovině listu a vlající s pěti střídavě bílými a černými šikmými pruhy. Přes list kosmo položený žlutý nůž hrotem k hornímu rohu a ostřím dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo dva stříbrné snížené kužely, vlevo dvě černá šikmá břevna, vše kosmo přeloženo vztyčeným zlatým nožem."
    },
    {
        obec: "Dlouhá Loučka",
        kod: 577987,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.700947,
        longitude: 16.641393,
        coatOfArmsFlagDescription: "Žlutý list se šedým dolním žerďovým trojúhelníkem sahajícím do poloviny délky listu provázeným ve žlutém poli šesti modrými květy hořečku brvitého. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vztyčená, doleva obrácená radlice provázená po stranách dvanácti (6, 6) květy horečku brvitého, vše přirozené barvy."
    },
    {
        obec: "Dlouhá Stráň",
        kod: 551708,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.960143,
        longitude: 17.500561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Dlouh%C3%A1_Str%C3%A1%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 6:1:1. V žerďové polovině horního modrého pruhu bílý vykračující býk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Třemi oblouky sníženě modro-stříbrně dělený štít. Nahoře vykračující býk a dole lilie opačných barev."
    },
    {
        obec: "Dlouhá Třebová",
        kod: 548014,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56117,
        latitude: 49.940498,
        longitude: 16.423397,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Dlouh%C3%A1_T%C5%99ebov%C3%A1.svg",
        coatOfArmsFlagDescription: "Žlutý list s červenou berlou otočenou k žerdi, k níž je červeným obojkem připoután černý rozkřídlený ďábel v podobě draka s červenou zbrojí a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě postavená červená berla, k níž je červeným obojkem připoután rozkřídlený ďábel v podobě draka s červenou zbrojí a jazykem."
    },
    {
        obec: "Dlouhá Ves",
        kod: 568538,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.578654,
        longitude: 15.67485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Dlouh%C3%A1_Ves_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým kosmým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, širokým jednu desetinu délky listu, šikmo přeloženým žlutou berlou závitem k hornímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě kosmé stříbrné vlnité břevno šikmo přeložené zlatou berlou."
    },
    {
        obec: "Dlouhá Ves",
        kod: 556076,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.196383,
        longitude: 13.50936,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Dlouh%C3%A1_Ves_KT_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a vlnitých žlutých, v poměru 12 : 1 : 1 : 1 : 1. V horním pruhu bílé kopí na žluté násadě provázené bílými býčími rohy postrkanými třemi bílými kruhovými štíty s hrotem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma zlatými zúženými vlnitými břevny vztyčené stříbrné kopí na zlaté násadě mezi stříbrnými býčími rohy postrkanými třemi stříbrnými kruhovými štíty s hrotem."
    },
    {
        obec: "Dlouhé",
        kod: 595471,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.509081,
        longitude: 16.123148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Dlouhe_znak.svg",
        coatOfArmsFlagDescription: "List tvoří bílé karé široké jednu polovinu šířky listu se žluto-červeně polcenou lilií a dva vodorovné pruhy, červený a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně šikmo dělený lev s červenou zbrojí a jazykem, držící v předních tlapách na zlaté žerdi stříbrný praporec s ocasem se žluto-červeně polcenou lilií a mezi zadními tlapami stříbrný mlýnský kámen s postavenou červenou kypřicí."
    },
    {
        obec: "Dlouhomilov",
        kod: 533688,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.906997,
        longitude: 16.991411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Dlouhomilov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený. Uprostřed mezi dvěma pětilistými jabloňovými květy se žlutými středy dva zkřížené rýče ostřím k hornímu rohu a cípu, vše opačných barev, přeložené žlutým krojidlem ostřím k vlajícímu a hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě dva zkřížené vztyčené rýče mezi dvěma pětilistými jabloňovými květy se zlatými středy, vše opačných barev, přeložené doleva obráceným zlatým krojidlem."
    },
    {
        obec: "Dlouhoňovice",
        kod: 548031,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.070296,
        longitude: 16.441645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Dlouho%C5%88ovice.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými jednu osminu šířky listu. V žerďovém a vlajícím poli po jednom vztyčeném dubovém listu, v horním poli růže, v dolním poli zvon se závěsem a srdcem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené kosy bez kosišť, provázené nahoře růží, po stranách dvěma vztyčenými dubovými listy a dole zvonem se závěsem, vše zlaté."
    },
    {
        obec: "Dlouhopolsko",
        kod: 537098,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28903,
        latitude: 50.175313,
        longitude: 15.306231
    },
    {
        obec: "Dlouhý Most",
        kod: 530468,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46312,
        latitude: 50.711714,
        longitude: 15.075978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Dlouh%C3%BD_Most_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým křížem s rameny širokými šestinu délky listu. Horní vlající a dolní žerďové pole spojuje šikmo prohnutá zelená lipová ratolest se dvěma listy ve svislých ramenech kříže. V ramenech u ratolesti čtyři odvrácené modré javorové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný heroldský kříž, v něm uprostřed šikmo prohnutá zelená lipová ratolest se dvěma listy, v ramenech kříže čtyři odvrácené modré javorové listy."
    },
    {
        obec: "Dlouhý Újezd",
        kod: 560839,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.768405,
        longitude: 12.62858,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/CoA_of_Dlouh%C3%BD_%C3%9Ajezd.svg",
        coatOfArmsFlagDescription: "Zelený list s bílý žerďovým klínem s vrcholem na vlajícím okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný klín."
    },
    {
        obec: "Dnešice",
        kod: 557668,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33443,
        latitude: 49.60493,
        longitude: 13.264653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Dne%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým vodorovným pruhem širokým jednu osminu šířky listu. V horním modrém pruhu knížecí koruna mezi dvěma do oblouku přivrácenými a z bílého pruhu vyrůstajícími žlutými obilnými klasy se dvěma listy. V dolním modrém pruhu ledňáček sedící na zkřížených žlutých berlách s bílými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě volný stříbrný most se třemi kvádrovanými pilíři s červenými stříškami, dvěma oblouky a dvěma půloblouky. Z mostu vyrůstají dva do oblouku přivrácené zlaté obilné klasy se dvěma listy, mezi nimi knížecí koruna. Pod mostem ledňáček přirozené barvy sedící na zkřížených zlatých berlách se stříbrnými sudarii."
    },
    {
        obec: "Dobelice",
        kod: 593923,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.017342,
        longitude: 16.27873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Dobelice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu dvě zkřížené bílé vinohradnické motyky na žlutých násadách, mezi nimi nahoře svěšený žalud, dole vinný hrozen se dvěma úponky přes násady, každý se vztyčeným listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zkřížené stříbrné vinohradnické motyky na zlatých násadách, nahoře mezi nimi svěšený žalud, dole vinný hrozen se dvěma úponky přes násady, každý se vztyčeným listem, vše zlaté."
    },
    {
        obec: "Dobev",
        kod: 549363,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.29535,
        longitude: 14.048531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Dobev_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi zkříženými žlutými obilnými klasy mezi dvěma odkloněnými a odvrácenými bílými sekerami na žlutých topůrkách. Vše přeloženo bílou mitrou s fimbriemi. V ní modrý heroldský kříž se žlutou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě tři zkřížené zlaté obilné klasy mezi dvěma odkloněnými a odvrácenými stříbrnými sekerami na zlatých topůrkách. Vše přeloženo stříbrnou mitrou s fimbriemi. V ní modrý heroldský kříž se zlatou lilií."
    },
    {
        obec: "Dobkovice",
        kod: 562408,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40703,
        latitude: 50.714949,
        longitude: 14.193259,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Znak_obce_Dobkovice.png",
        coatOfArmsFlagDescription: "Bílý list s dolním modrým klínem s vrcholem ve třetí osmině šířky listu, v něm bílá kotva. V bílém poli vztyčené přivrácené dubové větévky s žaludem mezi dvěma listy, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě snížený modrý hrot se stříbrnou kotvou, provázený dvěma vztyčenými přivrácenými dubovými větévkami s žaludem mezi dvěma listy, vše zelené."
    },
    {
        obec: "Dobrá",
        kod: 598089,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.673927,
        longitude: 18.414037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Dobr%C3%A1_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vztyčeným kozlem v žerďové části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčený zlatý kozel držící stříbrný kostel s věží."
    },
    {
        obec: "Dobrá Voda",
        kod: 561932,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.358246,
        longitude: 15.270103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Dobr%C3%A1_Voda_%28Pelh%C5%99imov_District%29_CoA.jpg"
    },
    {
        obec: "Dobrá Voda",
        kod: 595489,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.393997,
        longitude: 16.065144,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Dobr%C3%A1_Voda_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý v poměru 2 : 1. Ve žlutém pruhu dvě zkřížené ryby, provázené čtyřmi (1, 2, 1) červenými růžemi se žlutým semeníkem a zelenými kališními lístky. V modrém pruhu tři bílé vlnité pruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou patou se třemi stříbrnými vlnitými břevny dvě zkřížené ryby přirozené barvy, provázené čtyřmi (1, 2, 1) červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Dobrá Voda u Českých Budějovic",
        kod: 535206,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37316,
        latitude: 48.968311,
        longitude: 14.525035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Znak_obce_Dobr%C3%A1_Voda_u_%C4%8Cesk%C3%BDch_Bud%C4%9Bjovic.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých, v poměru 6:1:1:1:1:1:1. V horním žerďovém rohu bílé kruhové pole o průměru čtyři devítiny šířky listu s černým hořícím hornickým olejovým kahanem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou vlnitou patou černý hořící hornický olejový kahan."
    },
    {
        obec: "Dobrá Voda u Hořic",
        kod: 572837,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50773,
        latitude: 50.348096,
        longitude: 15.602674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Coat_of_arms_of_Dobr%C3%A1_Voda_u_Ho%C5%99ic.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:5:1. Uprostřed bílého pruhu modrý vykračující kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou hlavou a patou modrý vykračující kohout s červenou zbrojí."
    },
    {
        obec: "Dobrá Voda u Pacova",
        kod: 561762,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.40438,
        longitude: 15.02781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Dobr%C3%A1_Voda_u_Pacova.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý s jedním vrcholem a dvěma prohlubněmi. V bílém pruhu kosmo džbán, v modrém zvon, obojí opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě děleném štítě nahoře kosmo džbán, dole zvon, obojí opačných barev."
    },
    {
        obec: "Dobratice",
        kod: 552542,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.660491,
        longitude: 18.492374,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Dobratice_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým pluhem s koly převýšeným vztyčenou bukovou větví s pěti listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad pluhem s koly vztyčená buková větev s pěti listy, vše zlaté."
    },
    {
        obec: "Dobrčice",
        kod: 513105,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.402129,
        longitude: 17.480594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Dobr%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým kosmým pruhem širokým polovinu šířky listu. V pruhu kosmo bílý, žlutě zdobený palcát, v horním rohu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném kráčejícím koni se zlatou zbrojí a červeným postrojem rytíř v úplné stříbrné, zlatě zdobené plátové zbroji, s burgundským šalířem, se zlatým mečem u pasu a stříbrným palcátem ve zdvižené pravici, provázený vlevo nahoře zlatou osmihrotou hvězdou."
    },
    {
        obec: "Dobré",
        kod: 576263,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51793,
        latitude: 50.269855,
        longitude: 16.263866,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Dobr%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou vykořeněnou lípou provázenou pěti bílými květy se žlutými středy, nahoře třemi vedle sebe, dvěma po stranách kmene. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá vykořeněná lípa provázená pěti stříbrnými osmilistými květy se zlatými středy, třemi nahoře a dvěma po stranách kmene."
    },
    {
        obec: "Dobré Pole",
        kod: 584410,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69181,
        latitude: 48.824436,
        longitude: 16.535282,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Dobre_pole_znak.png",
        coatOfArmsFlagDescription: "Bílý list s červeným srdcem z něhož na žlutých stoncích vyrůstají tři červené květy se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červené srdce z něhož na zlatých stoncích vyrůstají tři červené květy se zlatými semeníky."
    },
    {
        obec: "Dobrkovice",
        kod: 585165,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76307,
        latitude: 49.094555,
        longitude: 17.669619,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/CZ_Dobrkovice_COA.svg",
        coatOfArmsFlagDescription: "List šikmo dělený na modré a bílé pole, na horním okraji listu v poměru 2 : 1, na dolním okraji v poměru 1 : 2. V modrém poli bílá růže se žlutým semeníkem, z ní vyniká žlutý jetelovitě zakončený procesní kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vpravo vyniká ze stříbrné růže se zlatým semeníkem zlatý jetelovitě zakončený procesní kříž, vlevo stříbrné krojidlo."
    },
    {
        obec: "Dobrná",
        kod: 545783,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40741,
        latitude: 50.769646,
        longitude: 14.293376
    },
    {
        obec: "Dobročkovice",
        kod: 592986,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.163035,
        longitude: 17.109099,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Dobro%C4%8Dkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed polcené kruhové pole, jehož žerďová polovina je červená s půlí bílé lilie a vlající polovinu tvoří čtyři vodorovné pruhy, střídavě bílé a černé, přeložené žlutou radlicí hrotem dolů a ostřím k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě polcený kotouč, vpravo červený s půlí stříbrné lilie, vlevo třikrát stříbrno-černě dělený se zlatou radlicí obrácenou doleva."
    },
    {
        obec: "Dobročovice",
        kod: 565008,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25082,
        latitude: 50.056421,
        longitude: 14.700015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Dobrocovice_PH_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žluto-modře kosmo dělený list. Z dolního okraje žerďového pole vyniká pět červených plamenů, ve vlajícím poli žlutá radlice ostřím k hornímu okraji listu a hrotem k hornímu rohu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře kosmo děleném štítě, nahoře kosmá vztyčená doleva obrácená zlatá radlice, dole vyniká pět červených plamenů."
    },
    {
        obec: "Dobrohošť",
        kod: 562416,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.078488,
        longitude: 15.488583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/CoA_of_Dobroho%C5%A1%C5%A5.svg",
        coatOfArmsFlagDescription: "Modrý list. V horní části žerďové poloviny listu žlutá růže s červeným semeníkem a vztyčený bílý levý jelení paroh. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a vztyčený stříbrný levý jelení paroh."
    },
    {
        obec: "Dobrochov",
        kod: 589438,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79807,
        latitude: 49.385961,
        longitude: 17.105673,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Dobrochov_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu, a žluté pole se zeleným vykořeněným dubem s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněný dub přirozené barvy nad červeně mřežovanou patou se zlatými hvězdami v křížení."
    },
    {
        obec: "Dobroměřice",
        kod: 546861,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.370197,
        longitude: 13.794844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Dobrom%C4%9B%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký jednu třetinu délky listu, a pět vodorovných pruhů střídavě modrých a žlutých. V bílém pruhu černý medvěd s červenou zbrojí ve skoku, na levé tlapě s červeným gotickým štítem se šesticípou hvězdou a dorůstajícím půlměsícem, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo černý medvěd s červenou zbrojí ve skoku, na levé tlapě s červeným gotickým štítem s hvězdou a dorůstajícím půlměsícem, obojí zlaté. Vlevo nad sebou dva zlaté mosty se třemi oblouky a čtyřmi pilíři."
    },
    {
        obec: "Dobromilice",
        kod: 589446,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79825,
        latitude: 49.35854,
        longitude: 17.141647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Dobromilice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: červený, bílý, modrý, bílý a červený v poměru 4:1:11:1:4. Uprostřed bílé na koso položené čtvercové pole dotýkající se bílých pruhů s červeným oškrtovým křížem se žlutě lemovaným čtvercovým otvorem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vidlice, v ní uprostřed červený oškrtový kříž se zlatě lemovaným čtvercovým otvorem, provázený třemi modrými, do středu obrácenými rybami."
    },
    {
        obec: "Dobronice u Bechyně",
        kod: 552224,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.343411,
        longitude: 14.496837,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Dobronice_u_Bechyn%C4%9B_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými obdélníkovými poli vycházejícími z druhé a třetí sedminy, a z páté a šesté sedminy vlajícího okraje do druhé třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad kolčí přilbou polovina koně, vše stříbrné doleva obrácené."
    },
    {
        obec: "Dobronín",
        kod: 587028,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58812,
        latitude: 49.478429,
        longitude: 15.650023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Dobronin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý zubatý, červený a modrý, v poměru 1:2:1. Z modrého pruhu vyskakuje půl žlutého býka, bílý pruh má čtyři zuby a tři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo nad stříbrnou kvádrovanou hradbou s cimbuřím z poltící linie vyskakující zlatý býk, vlevo stříbrné ciborium."
    },
    {
        obec: "Dobroslavice",
        kod: 569895,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74794,
        latitude: 49.880434,
        longitude: 18.141029,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Dobroslavice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a červený v poměru 7:2:7. V modrém pruhu tři žluté šesticípé hvězdy vedle sebe, v červeném pruhu žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červený štít dělený zúženým břevnem, provázeným nahoře třemi hvězdami, dole korunou, vše zlaté."
    },
    {
        obec: "Dobroutov",
        kod: 587036,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.462343,
        longitude: 15.73659
    },
    {
        obec: "Dobrovice",
        kod: 535672,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29441,
        latitude: 50.369427,
        longitude: 14.962439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Dobrovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený. V horním žerďovém rohu modrý lev s červenou zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dobrovítov",
        kod: 529559,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.787634,
        longitude: 15.328817
    },
    {
        obec: "Dobršín",
        kod: 530123,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.261401,
        longitude: 13.561152
    },
    {
        obec: "Dobruška",
        kod: 576271,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.292111,
        longitude: 16.160118,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Dobru%C5%A1ka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět kosmých pruhů - červený, bílý, červený, bílý a červený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dobřany",
        kod: 576280,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.322221,
        longitude: 16.285397,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Dobrany_v_orlickych_horach_coat_of_arms.JPG",
        coatOfArmsFlagDescription: "Červený list s modrým zubatým žerďovým pruhem, širokým jednu třetinu délky listu, se dvěma obdélníkovými zuby a třemi stejnými mezerami. Červené pole se dvěma bílými kosmými pruhy vycházejícími z první a třetí čtvrtiny žerďového okraje pole do druhé a čtvrté čtvrtiny vlajícího okraje listu. Zuby sahají do páté dvanáctiny délky listu. V modrém pruhu žlutá berla závitem k vlajícímu okraji, přeložená bílou otevřenou knihou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně cimbuřově polceném štítě vpravo zlatá berla závitem doleva přeložená stříbrnou otevřenou knihou, vlevo dvě stříbrná kosmá břevna."
    },
    {
        obec: "Dobrovíz",
        kod: 539171,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25261,
        latitude: 50.113163,
        longitude: 14.217841,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Dobrov%C3%ADz_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý, a žlutý ondřejský kříž s rameny širokými čtvrtinu šířky listu. Mezi rameny kříže v zeleném pruhu bílá podkova obloukem k vlajícímu okraji listu, v modrém pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s modrou patou kosmo stříbrná kosa se zlatým kosištěm, šikmo podložená zlatými hráběmi, nahoře obrácená stříbrná podkova, dole zlaté mlýnské kolo."
    },
    {
        obec: "Dobřany",
        kod: 557676,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33441,
        latitude: 49.654933,
        longitude: 13.293177,
        coatOfArmsFlagDescription: "List tvoří tři pole - žerďové a vlající je bílé, střední čtvercové je modré. Poměr polí je 1:4:1. Na střed modrého pole je položena bílá okrouhlá věž s cimbuřím a třemi černými okny (1+2) a s červenou kuželovou střechou se zlatou makovicí; stejná střecha vyrůstá ze základny. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dobřejovice",
        kod: 538167,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.981717,
        longitude: 14.57837,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Coats_of_arms_Dob%C5%99ejovice.jpeg",
        coatOfArmsFlagDescription: "Bílý list s červeným kosmým pruhem, širokým polovinu délky listu. V pruhu kosmo za sebou tři vztyčené listy, žlutý javorový mezi dvěma bílými lipovými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé červené břevno se třemi kosmými vztyčenými listy, zlatým javorovým mezi stříbrnými lipovými."
    },
    {
        obec: "Dobřeň",
        kod: 531570,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.479505,
        longitude: 14.556812
    },
    {
        obec: "Dobříč",
        kod: 558770,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33005,
        latitude: 49.88466,
        longitude: 13.468475,
        coatOfArmsFlagDescription: "Kosmo a šikmo čtvrcený list. Žerďové pole modré, horní bílé, vlající zelené a dolní žluté. V bílém poli červená lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modro-zeleno-zlatě kosmo čtvrcený štít. V horním poli červená lilie."
    },
    {
        obec: "Dobřenice",
        kod: 569968,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50325,
        latitude: 50.147821,
        longitude: 15.641146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Dob%C5%99enice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Žluto-modře kosmo dělený list. V žerďovém poli zelená lípa s hnědým kmenem, ve vlajícím poli čáp bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě kosmo děleném štítě nahoře stříbrný čáp s červenou zbrojí, dole vztyčený zelený lipový list."
    },
    {
        obec: "Dobříč",
        kod: 539180,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25225,
        latitude: 50.020021,
        longitude: 14.259017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Dobric_PZ_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, modrý, bílý a modrý, v poměru 5:1:1:1. V červeném pruhu žlutá hlavice berly závitem i bílým sudariem k vlajícímu okraji a bílá hlava jednorožce s krkem a se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře sníženě děleném štítě nahoře vedle sebe zlatá hlavice berly se stříbrným sudariem, obojí doleva, a stříbrná hlava jednorožce s krkem, se zlatou zbrojí, dole volný stříbrný klín provázený vztyčenými odkloněnými stříbrnými mlátky se zlatými topůrky."
    },
    {
        obec: "Dobřichov",
        kod: 533289,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.079731,
        longitude: 15.032473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Dobrichov_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký jednu třetinu délky listu se žlutým jetelovým trojlístkem na stonku a šest vodorovných pruhů, střídavě černých a bílých, v poměru 1:1:1:1:1:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli zlatý jetelový trojlist na stonku, levé pole černo-stříbrně děleno, nahoře dvě stříbrná břevna."
    },
    {
        obec: "Dobřichovice",
        kod: 539198,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25229,
        latitude: 49.927597,
        longitude: 14.274802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Dobrichovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a modrý, v horním rohu osmihrotý kříž nad šesticípou hvězdou, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře polcený štít, vlevo půl zlatého slunce, vpravo osmihrotý kříž nad hvězdou, obojí červené."
    },
    {
        obec: "Dobříkov",
        kod: 580091,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 50.002605,
        longitude: 16.133921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Dob%C5%99%C3%ADkov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a modrý. V žerďové polovině obou pruhů po jedné žluté osmicípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zkřížené vztyčené stříbrné rýče se zlatým kováním, provázené dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Dobříň",
        kod: 564745,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.437691,
        longitude: 14.29389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Dob%C5%99%C3%AD%C5%88_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, žlutý, černý a žlutý, v poměru 4:1:1:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě dvě snížená břevna, modré a černé, z modrého břevna na zelených odkloněných stoncích vyrůstají dva květy sněženky podsněžníku přirozené barvy provázené dvěma zelenými odkloněnými listy."
    },
    {
        obec: "Dobřínsko",
        kod: 593931,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.052312,
        longitude: 16.26177,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Dob%C5%99%C3%ADnsko_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a žlutý, v poměru 1 : 2 : 1. V zeleném pruhu bílý vlk ve skoku s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo zlatá berla se stříbrným sudariem, obtočená zlatým stonkem vinné révy, střídavě se dvěma listy a dvěma hrozny, vlevo nad zlatou hradbou s červeným tlapatým křížem stříbrný vlk ve skoku s červenou zbrojí."
    },
    {
        obec: "Dobříš",
        kod: 540111,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.781238,
        longitude: 14.167273,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Dobris-znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a bílých. Bílé pruhy jsou ve druhé čtvrtině šířky listu spojeny vodorovným dolů vydutým zubatým pruhem se třemi čtvercovými zuby a čtyřmi stejnými mezerami. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dobřív",
        kod: 559776,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33844,
        latitude: 49.715763,
        longitude: 13.686921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Dobriv_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, zvlněný černý a zelený v poměru 1:5:1:1. V bílém pruhu na rozhraní žerďové a střední části v zeleném nahoře rozevřeném a dole červenou stuhou převázaném vavřínovém věnci dvě zkřížená černá hamernická kladiva na hnědých násadách, přeložená vztyčenou modrou vidlicí a červenou stuhou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným lemem nad černou vlnitou patou v zeleném nahoře rozevřeném a dole červenou stuhou převázaném vavřínovém věnci dvě zkřížená černá hamernická kladiva na násadách přirozené barvy, přeložená vztyčenou modrou vidlicí a červenou stuhou."
    },
    {
        obec: "Dobšice",
        kod: 536199,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.216003,
        longitude: 14.483885
    },
    {
        obec: "Dobšice",
        kod: 546941,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67182,
        latitude: 48.848447,
        longitude: 16.085512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Dob%C5%A1ice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se šikmým vlnitým bílým pruhem s jednou prohlubní a jedním vrcholem, vycházejícím z dolní třetiny žerďového okraje a sahajícím do horní třetiny vlajícího okraje. V zelených polích po jednom žlutém kříži se zaoblenými rameny a rozšířenou patou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmé vlnitě prohnuté stříbrné břevno provázené dvěma zlatými kříži se zaoblenými rameny a rozšířenou patou."
    },
    {
        obec: "Dobšice",
        kod: 537101,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28905,
        latitude: 50.132535,
        longitude: 15.268716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Coats_of_arms_Dob%C5%A1ice.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 1 : 2 : 1. V bílém pruhu dva zelené topoly s hnědými kmeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně polceném štítě nad modrou patou se stříbrným vlnitým břevnem vpravo dva topoly přirozené barvy, vlevo stříbrný pes ve skoku se zlatou zbrojí a obojkem s kroužkem."
    },
    {
        obec: "Dobšín",
        kod: 571989,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.483425,
        longitude: 15.116761,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Dob%C5%A1%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1 : 4 : 1. Ve žlutém pruhu modré kamenické kladivo na červeném topůrku podloženém zkříženými postavenými modrými kamenickými sekáči, kosmým špičákem a šikmým lemovacím dlátem, provázené dvěma volnými položenými přivrácenými modrými kamenickými klíny. Vše nad červenou vztyčenou borovicovou větví s pěti větévkami, každou zakončenou zeleným svazkem jehlic, dole se dvěma svěšenými červenými šiškami. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modré kamenické kladivo na červeném topůrku, podloženém zkříženými postavenými modrými kamenickými sekáči, kosmým špičákem a šikmým lemovacím dlátem, provázené dvěma volnými položenými přivrácenými modrými kamenickými klíny. Vše nad červenou vztyčenou borovicovou větví s pěti větévkami, každou zakončenou zeleným svazkem jehlic, dole se dvěma svěšenými červenými šiškami."
    },
    {
        obec: "Dohalice",
        kod: 569976,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50313,
        latitude: 50.287371,
        longitude: 15.694605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Dohalice_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Horní část listu tvoří červený kanton se žlutou hlavou Medúzy a černo-žlutě šachované pole (5x8). Dolní část listu tvoří čtyři vodorovné pruhy, střídavě modré a bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít nahoře polcený, vpravo v červeném poli zlatá hlava Medúzy, levé pole černo-zlatě šachováno, dolní pole třikrát modro-stříbrně děleno."
    },
    {
        obec: "Doksany",
        kod: 564753,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41182,
        latitude: 50.454991,
        longitude: 14.16124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Doksany_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině dva svislé pruhy, modrý a červený, v poměru 1 : 5 a ve vlající polovině sedm střídavě modrých a bílých vodorovných pruhů. K modrému žerďovému pruhu přiléhá v červeném pruhu žerďové půlkruhové modré pole s korunou, provázenou nahoře a dole lilií, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá z vlnité paty stříbrná kvádrovaná zeď se třemi věžemi. Každá věž s černým obloukovým oknem, červenou cibulovou střechou se zlatou makovicí, nad prostřední koruna a nad krajními lilie, vše zlaté."
    },
    {
        obec: "Doksy",
        kod: 532223,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27364,
        latitude: 50.119102,
        longitude: 14.04793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Doksy_CoA_CZ.svg"
    },
    {
        obec: "Doksy",
        kod: 561495,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.565945,
        longitude: 14.654377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Doksy_CL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, červený, bílý, červený a zelený, v poměru 1:1:4:1:1. V bílém pruhu červené čtvercové pole široké polovinu šířky listu. V něm na zeleném trávníku dvě zelená, na vnitřní straně hnědá skalnatá návrší, na každém bílá čtyřhranná, hranou čelně otočená a v dolní polovině kvádrovaná věž s cimbuřím se třemi zuby a se dvěma černými obdélníkovými okny vedle sebe. Z věže u vlajícího okraje pole vyskakuje k žerdi bílý jelen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku dvě zelená, z vnitřní strany skalnatá návrší přirozené barvy, na každém stojí stříbrná čtyřhranná, hranou čelně otočená a v dolní polovině kvádrovaná věž s cimbuřím o třech stínkách a se dvěma černými obdélníkovými okny vedle sebe. Z pravého okna levé věže vyskakuje stříbrný jelen."
    },
    {
        obec: "Dolany",
        kod: 574015,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55201,
        latitude: 50.380098,
        longitude: 15.96138,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Dolany_NA_CoA.JPG",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký čtvrtinu délky listu s pěti jablky pod sebou, každé se stopkou a šikmým listem, vše červené, a zelené pole se žlutým průčelím kaple s černými dveřmi a kruhovým oknem, završeným prázdnou vížkou s křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně vpravo polcený štít. Vpravo pět jablek pod sebou, každé se stopkou a šikmým listem, vše červené. Vlevo zlaté průčelí kaple s černými dveřmi a kruhovým oknem, završené prázdnou vížkou s křížkem."
    },
    {
        obec: "Dolánky nad Ohří",
        kod: 553646,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.472956,
        longitude: 14.162716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Dol%C3%A1nky_nad_Oh%C5%99%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý se zeleným horním klínem s vrcholem na dolním okraji pruhu, modrý, bílý a modrý, v poměru 9 : 1 : 1 : 1. Do klínu vyniká bílý sloup s černým obloukovým výklenkem v hlavici. V žerďové části žlutého pruhu vztyčený zelený lipový list, ve vlající části čelně postavená modrá radlice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou patou se stříbrným vlnitým břevnem zelený klín se stříbrným sloupem s černým obloukovým výklenkem v hlavici. Klín je provázen vpravo vztyčeným zeleným lipovým listem a vlevo čelně postavenou modrou radlicí."
    },
    {
        obec: "Dolany",
        kod: 501646,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78316,
        latitude: 49.649973,
        longitude: 17.322508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Dolany_znak.jpg",
        coatOfArmsFlagDescription: "V zeleném listu mezi dvěma do oblouku zahnutými a dole žlutě převázanými bílými vavřínovými ratolestmi žlutá koruna nad třemi (2, 1) bílými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě mezi dvěma do oblouku zahnutými a dole zlatě převázanými stříbrnými vavřínovými ratolestmi zlatá koruna nad třemi (2,1) stříbrnými hvězdami."
    },
    {
        obec: "Dolany",
        kod: 574902,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.113474,
        longitude: 15.691707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Dolany_PA_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 3:1. Do zeleného pruhu vyrůstá berla závitem k žerdi provázená šesti (3,3) odkloněnými ustupujícími klasy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrné paty vyrůstající berla provázená šesti (3,3) odkloněnými ustupujícími obilnými klasy, vše zlaté."
    },
    {
        obec: "Dolany",
        kod: 556106,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.443368,
        longitude: 13.248222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Dolany_%28Okres_Klatovy%29_CoA.png"
    },
    {
        obec: "Dolany",
        kod: 558788,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.807924,
        longitude: 13.472466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Dolany_%28Plze%C5%88-North_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým pruhem širokým šestinu délky listu. V modrém poli obilný snop dole provázen dvěma vztyčenými odkloněnými listy, k žerďovému okraji lipovým a k vlajícímu habrovým, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě obilný snop nad mostem se dvěma oblouky, snop dole provázen dvěma vztyčenými odkloněnými listy, vpravo lipovým, vlevo habrovým, vše zlaté."
    },
    {
        obec: "Dolany",
        kod: 513130,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.115571,
        longitude: 14.149791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Dolany_%28Kladno_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý vypouklý a zelený vydutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-zeleně dolů obloukem dělený štít."
    },
    {
        obec: "Dolany",
        kod: 539201,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27801,
        latitude: 50.22214,
        longitude: 14.349629,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Dolany_ME_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět kosmých pruhů, červený u dolního rohu, bílý, modrý, bílý a modrý. Na ose prostředního pruhu tři žluté šesticípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé modré břevno se třemi zlatými hvězdami, provázené nahoře švestkou na stopce s jedním listem přirozené barvy, dole červenou hvězdou."
    },
    {
        obec: "Dolce",
        kod: 539945,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.552967,
        longitude: 13.393513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Dolce_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý pilovitý, v poměru 2:1. Modrý pruh má tři zuby s vrcholy v první třetině délky listu. Na vrcholu středního zubu vodorovně bílá lilie provázená nahoře a dole dvěma žlutými tlapatými kříži, ve vlající části vztyčená bílá trojúhelníková zednická lžíce se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře pětkrát pilovitě děleném štítě nahoře na středním hrotu stříbrná lilie provázená dvěma zlatými tlapatými kříži. Dole vztyčená stříbrná trojúhelníková zednická lžíce se zlatou rukojetí."
    },
    {
        obec: "Dolenice",
        kod: 593958,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.909233,
        longitude: 16.365753,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Dolenice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým středovým křížem s rameny širokými tři jedenáctiny šířky listu. Ve svislém rameni kříže zelená vinná réva se čtyřmi hrozny obtočená okolo černého kolíku provázená ve vodorovném rameni dvěma červenými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný heroldský kříž, v něm zelená vinná réva se čtyřmi hrozny obtočená okolo černého kolíku a v příčném rameni kříže provázená dvěma červenými liliemi."
    },
    {
        obec: "Dolní Bečva",
        kod: 541800,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75655,
        latitude: 49.455076,
        longitude: 18.194298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/CZ_Doln%C3%AD_Be%C4%8Dva_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný bílý o třech vrcholech a dvou prohlubních, v poměru 2:1. Z prostředního vrcholu vyrůstá od kýt žlutý dvouocasý lev s bílou korunou a červenou zbrojí, držící bílý trojramenný řecko-katolický kříž. V bílém pruhu zelený bodlák s červeným okvětím. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrné vlnité paty od kýt vyrůstající zlatý dvouocasý lev se stříbrnou korunou a červenou zbrojí, držící stříbrný trojramenný řecko-katolický kříž, v patě zelený bodlák s červeným okvětím."
    },
    {
        obec: "Dolní Bělá",
        kod: 558796,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.896264,
        longitude: 13.273369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Coat_of_arms_of_Doln%C3%AD_B%C4%9Bl%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě žluté a černé, v poměru 7 : 1 : 1 : 7, druhý a třetí vlnité se třemi vrcholy a dvěma prohlubněmi. V horním pruhu nad prohlubněmi dvě černá jelení paroží, v dolním žluté jelení paroží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-černě dělený štít černo-zlatě vlnitě děleným vlnitým břevnem. Nahoře dvě a dole jedno jelení paroží opačných barev."
    },
    {
        obec: "Dolní Benešov",
        kod: 506702,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74722,
        latitude: 49.923215,
        longitude: 18.101406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Doln%C3%AD_Bene%C5%A1ov_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří červené žerďové pole s kosmo položenou bílou štikou držící v hubě bílou plotici, a bílé vlající pole. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Beřkovice",
        kod: 534765,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27701,
        latitude: 50.393236,
        longitude: 14.450352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Doln%C3%AD_Be%C5%99kovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, vlnitý bílý se dvěma vrcholy a třemi prohlubněmi, a modrý, v poměru 3 : 1 : 8. V červeném pruhu tři bílé lilie pod sebou, v modrém pruhu polovina korunovaného žlutého lva s červenou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře zvýšeným stříbrným vlnitým břevnem dělený štít. Nahoře tři stříbrné lilie, dole polovina korunovaného zlatého lva s červenou zbrojí a jazykem."
    },
    {
        obec: "Dolní Bezděkov",
        kod: 505030,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.960537,
        longitude: 15.883877,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Doln%C3%AD_Bezd%C4%9Bkov_%28okres_Chrudim%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. V zeleném bílý meč se žlutým jílcem hrotem k hornímu okraji listu, podložený dvěma zkříženými žlutými obilnými klasy, každý s odkloněným listem. V červeném pruhu dva šikmé bílé pruhy vycházející z druhé a čtvrté čtvrtiny žerďového okraje pruhu do první a třetí čtvrtiny vlajícího okraje listu, v nich červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo stříbrný vztyčený meč se zlatým jílcem, podložený dvěma zkříženými zlatými obilnými klasy, každý s odkloněným listem, vlevo dvě šikmá stříbrná břevna, v každém červená růže se zlatým semeníkem."
    },
    {
        obec: "Dolní Bojanovice",
        kod: 586137,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69617,
        latitude: 48.858707,
        longitude: 17.028691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Doln%C3%AD_Bojanovice_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List praporu tvoří šest vodorovných pruhů - modrý, černý, bílý, černý, bílý a černý v poměru 5:1:1:1:1:1. V modrém pruhu šikmá vinná větev se třemi hrozny a jedním listem, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-černě dělený štít, v horním modrém poli šikmá stříbrná vinná větev se třemi hrozny a jedním listem, v dolním poli dvě stříbrná břevna."
    },
    {
        obec: "Dolní Bousov",
        kod: 535702,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.438348,
        longitude: 15.128232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Doln%C3%AD_Bousov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem vycházejícím z první třetiny dolního okraje listu do třetí třetiny horního okraje listu na žerďové modré a červené vlající pole. Přes celý list žluté, jetelovitě zakončené perizonium. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Branná",
        kod: 579122,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54362,
        latitude: 50.593265,
        longitude: 15.593924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Dolni_Branna_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, žlutý, modrý, bílý, modrý, žlutý a modrý v poměru 3:1:1:2:1:1:3. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno provázené nahoře dvěma a dole jedním postaveným zlatým obilným snopem."
    },
    {
        obec: "Dolní Bukovsko",
        kod: 544388,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37365,
        latitude: 49.170963,
        longitude: 14.58137,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Coa_Czech_Town_Bavorov.svg",
        coatOfArmsFlagDescription: "Bílý list s červenou růží. V rozích a cípech po jednom zeleném bukovém listu směřujícím do středu listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Břežany",
        kod: 539210,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25241,
        latitude: 49.963314,
        longitude: 14.458611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/CoA_of_Doln%C3%AD_B%C5%99e%C5%BEany.svg",
        coatOfArmsFlagDescription: "Žlutý list s černým svislým žerďovým pruhem širokým jednu čtvrtinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě zlatá skála."
    },
    {
        obec: "Dolní Cerekev",
        kod: 587044,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58845,
        latitude: 49.344588,
        longitude: 15.456651,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Doln%C3%AD_Cerekev_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 7 : 3. V žerďové polovině červeného pruhu kosmo klíč zuby nahoru a k žerdi přeložený šikmo vidlemi-podávkami hroty nahoru, vše bílé. Bílý pruh má tři zuby ve druhé, čtvrté a šesté dvanáctině délky, sahající do tří pětin šířky listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Dolní Brusnice",
        kod: 554863,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54472,
        latitude: 50.458843,
        longitude: 15.726876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Dolni_Brusnice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 7 : 2 : 3. V horním bílém pruhu zelená větévka brusnice s červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad sníženým modrým vlnitým břevnem dvě vztyčené větévky brusinek s plody přirozené barvy."
    },
    {
        obec: "Dolní Čermná",
        kod: 580112,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56153,
        latitude: 49.979635,
        longitude: 16.564857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Doln%C3%AD_%C4%8Cermn%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 1 : 3. V červeném pruhu šikmo dolů letící bílá holubice. V bílém pruhu sv. Jiří na černém koni probodávající kopím ležícího zeleného draka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou s šikmo dolů letící stříbrnou holubicí sv. Jiří na černém koni probodávající kopím ležícího zeleného draka."
    },
    {
        obec: "Dolní Dobrouč",
        kod: 580121,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56102,
        latitude: 49.992831,
        longitude: 16.497763,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Doln%C3%AD_Dobrou%C4%8D.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená odvrácená radlice a krojidlo, obojí stříbrné, provázené nahoře vně dvěma a dole mezi nimi jednou zlatou čtyřlistou růží."
    },
    {
        obec: "Dolní Domaslavice",
        kod: 598101,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73938,
        latitude: 49.712825,
        longitude: 18.481063,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Doln%C3%AD_Domaslavice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů: modrý, bílý, modrý, bílý, modrý a bílý v poměru 5:1:1:1:1:1. V horním žerďovém rohu zkřížená bílá sekera - bradatice se žlutým toporem a žlutý cep. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře křížem přeložené, stříbrná sekyra - bradatice se zlatým toporem a zlatý cep, dole dvě modrá břevna."
    },
    {
        obec: "Dolní Dubňany",
        kod: 593966,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67173,
        latitude: 49.055887,
        longitude: 16.2268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Doln%C3%AD_Dub%C5%88any_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, v poměru 2 : 3. V zeleném pruhu vyrůstá z žerďového okraje listu zelený dubový dvoulist se žaludem uprostřed, v bílém červený rovný tlapatý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně sníženě děleném štítě nahoře červený tlapatý kříž, do dolního pole vyrůstá svěšený dubový dvoulist se žaludem uprostřed, vše stříbrné."
    },
    {
        obec: "Dolní Dunajovice",
        kod: 584428,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69185,
        latitude: 48.854571,
        longitude: 16.592933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Dolnidunajovice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list, uprostřed zelený volný kopec se zelenou jedlí s hnědým kmenem mezi dvěma červenými věžemi s cimbuřím, prázdnou branou a dvěma obloukovými okny. V kopci bílý vinařský nůž - kosíř s hnědou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném kopci jedle přirozené barvy provázená dvěma červenými věžemi s cimbuřím, prázdnou branou a dvěma obloukovými okny. V kopci vinařský nůž - kosíř přirozené barvy."
    },
    {
        obec: "Dolní Dvořiště",
        kod: 545465,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38272,
        latitude: 48.656521,
        longitude: 14.452309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Dolnidvoriste_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých, v poměru 11:2:2:2:2:2:11. Uprostřed červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Dvůr",
        kod: 579149,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54342,
        latitude: 50.652348,
        longitude: 15.655683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Doln%C3%AD_Dv%C5%AFr_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílo-červeně šikmo dělený list. Uprostřed žlutý štítek, v něm černá zkřížená hornická kladívka na hnědých topůrkách. Na horním okraji štítku dva zelené jehličnaté stromy s hnědými kmeny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně šikmo děleném štítě zlatý střední štítek, v něm černá zkřížená hornická kladívka na hnědých topůrkách. Na horním okraji štítku dva jehličnaté stromy přirozené barvy."
    },
    {
        obec: "Dolní Habartice",
        kod: 545856,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.747281,
        longitude: 14.333391,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Doln%C3%AD_Habartice_CoA.png",
        coatOfArmsFlagDescription: "List dělený horním, modro-bíle vlnitě děleným klínem na žerďové žluté a vlající černé pole. V klínu šesticípá hvězda nad čelně postavenou radlicí opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-černě polceném štítě modro-stříbrně vlnitě dělený klín s hvězdou nad čelně postavenou radlicí opačných barev."
    },
    {
        obec: "Dolní Hbity",
        kod: 540129,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26262,
        latitude: 49.657558,
        longitude: 14.169935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Doln%C3%AD_Hbity_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou, obloukem s křížkem uzavřenou a červeně vyloženou liliovou korunou nad osmi (4, 3, 1) žlutými květy třezalky tečkované. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá, obloukem s křížkem uzavřená a červeně vyložená liliová koruna nad osmi (4, 3, 1) zlatými květy třezalky tečkované."
    },
    {
        obec: "Dolní Heřmanice",
        kod: 595501,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.304851,
        longitude: 16.060208,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Dhermanice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:2:1. Uprostřed bílého pruhu dvě spojená černá křídla se žlutým perizoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře vyrůstající okřídlený černý býk se svatozáří a zlatým perizoniem, dole pod stříbrným cimbuřovým břevnem zlatá ryba."
    },
    {
        obec: "Dolní Hořice",
        kod: 552241,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39155,
        latitude: 49.435483,
        longitude: 14.850632,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/CoA_of_Doln%C3%AD_Ho%C5%99ice.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh, široký polovinu délky listu se žlutým kruhovým polem přeloženým čelně letícím černým netopýrem, a sedm střídavě bílých a červených kosmých pruhů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně děleném štítě nahoře zlatý kotouč přeložený čelně letícím černým netopýrem, dole tři červená kosmá břevna."
    },
    {
        obec: "Dolní Hradiště",
        kod: 558800,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.913843,
        longitude: 13.49632,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Doln%C3%AD_Hradi%C5%A1t%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 3 : 2 : 3. V bílém pruhu orličí hlava s krkem nad růží, obojí červené. Ze žerďového i vlajícího okraje listu vynikají čtyři žluté klíny s vrcholy v třetinách délky listu. ",
        coatOfArmsDescription: "V modrém štítě stříbrný kůl s orličí hlavou s krkem nad růží, vše červené. Po stranách vynikají od krajů dva zlaté kůly palisády."
    },
    {
        obec: "Dolní Hrachovice",
        kod: 560529,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.486182,
        longitude: 14.797204
    },
    {
        obec: "Dolní Chvatliny",
        kod: 533297,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28144,
        latitude: 49.980602,
        longitude: 15.06939,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Dolni_Chvatliny_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh a pět vodorovných pruhů, střídavě červených a bílých oboustranně posunutě zubatých pruhů, v poměru 1:2:2:2 na vlajícím okraji modrého pruhu a 2:2:2:1 na vlajícím okraji listu. Bílé pruhy mají na horním okraji jeden poloviční a čtyři čtvercové zuby a pět stejných mezer, na dolním okraji pět čtvercových zubů a jednu poloviční a čtyři čtvercové mezery. V modrém pruhu pod žlutou osmicípou hvězdou vztyčená bílá pravá ruka držící žlutou hostii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo nad cimbuřovou patou dvě oboustranná cimbuřová břevna, vše stříbrné, vlevo pod zlatou osmihrotou hvězdou vztyčená stříbrná pravá ruka držící zlatou hostii."
    },
    {
        obec: "Dolní Kalná",
        kod: 579157,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54374,
        latitude: 50.538973,
        longitude: 15.6393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/CoA_of_Doln%C3%AD_Kaln%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnitý červený, vlnitý bílý a zvlněný zelený, v poměru 1 : 1 : 1 : 7. Vlnité pruhy mají tři vrcholy a dvě prohlubně. V zeleném pruhu knížecí koruna pod bílou krokví, vycházející z poslední osminy žerďového a vlajícího okraje, s vrcholem ve třetí osmině šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou vlnitou hlavou s červeným vlnitým břevnem snížená stříbrná krokev nad knížecí korunou."
    },
    {
        obec: "Dolní Kounice",
        kod: 582956,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.070212,
        longitude: 16.465024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Doln%C3%AD-Kounice.png",
        coatOfArmsFlagDescription: "Červený list s hnědým rozkřídleným krahujcem v jedné třetině délky listu. Ve žlutém zobáku drží krahujec modrý vinný hrozen na zelené stopce a pod žlutými pařáty má zelený vinný list. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Kralovice",
        kod: 529648,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.644223,
        longitude: 15.177648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Doln%C3%AD_Kralovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list se žlutou růží s červeným semeníkem a zelenými kališními lístky nad bílou rybou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky nad stříbrnou rybou."
    },
    {
        obec: "Dolní Krupá",
        kod: 568597,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58271,
        latitude: 49.660734,
        longitude: 15.60314,
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a modrých, v poměru 8:3:1:3:1. V žerďové polovině horního bílého pruhu zkřížené černé ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré břevno posázené třemi řadami stříbrných gotických oken. Nahoře černé zkřížené ostrve, dole kohout přirozené barvy držící červenou misku."
    },
    {
        obec: "Dolní Krupá",
        kod: 535711,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.546419,
        longitude: 14.86727,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Doln%C3%AD_Krup%C3%A1_%28okres_Mlad%C3%A1_Boleslav%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými jednu pětinu šířky listu. Žerďové pole tvoří dva vodorovné pruhy, bílý a červený. V horním, dolním a vlajícím poli bílá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě kosmé zlaté ratiště, se stříbrným hrotem a patkou a s šikmo vlajícím stříbrno-červeně děleným praporcem - vlaštovčím ocasem, šikmo podložené zlatou ostrví. Po stranách a dole tři stříbrné růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Dolní Lánov",
        kod: 579165,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54341,
        latitude: 50.587095,
        longitude: 15.664426,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Dolni_Lanov_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy bílý, zelený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zelený zvlněný, v poměru 3:1:1:1. V žerďové polovině horního bílého pruhu modře oděný kovář v černé zástěře a vysokých botách probodává červeným oštěpem vzpřímeného černého medvěda s červenou zbrojí. Mezi nimi dole červená podkova. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně dělený štít. Nahoře doleva obrácený modře oděný kovář v černé zástěře a vysokých botách probodává červeným oštěpem vzpřímeného černého medvěda s červenou zbrojí. Mezi nimi dole červená podkova. Dole stříbrné vlnité břevno."
    },
    {
        obec: "Dolní Lažany",
        kod: 587664,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.100034,
        longitude: 15.822815,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Doln%C3%AD_La%C5%BEany_TR_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, uprostřed bílá radlice hrotem k hornímu a ostřím k žerďovému okraji, podložená bílou kosou bez kosiště, hrotem k žerďovému rohu a ostřím k vlajícímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě pod dvěma zlatými zúženými břevny vztyčená radlice kosmo podložená vztyčenou a doleva obrácenou kosou bez kosiště, vlevo nahoře provázená zkříženými ostrvemi, vše stříbrné."
    },
    {
        obec: "Dolní Lhota",
        kod: 506711,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74766,
        latitude: 49.842458,
        longitude: 18.092517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Znak_obce_Doln%C3%AD_Lhota_u_Opavy.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, uprostřed bílá radlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě stříbrná radlice."
    },
    {
        obec: "Dolní Lhota",
        kod: 585173,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76323,
        latitude: 49.137271,
        longitude: 17.812463,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Doln%C3%AD_Lhota_ZL_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý, bílý, černý, červený, bílý a modrý v poměru 1:1:2:2:1:1. Uprostřed bílý kosočtverec dlouhý dvě třetiny délky listu a vysoký polovinu šířky listu s modrou švestkou se zelenou stopkou a dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-červeno-stříbrně dělený štít, v horních polích dva poloviční zápasící stříbrní berani se zlatou zbrojí a červenými jazyky. V dolním poli modrá švestka se zelenou stopkou se dvěma listy."
    },
    {
        obec: "Dolní Libochová",
        kod: 595519,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59253,
        latitude: 49.408387,
        longitude: 16.184706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Doln%C3%AD_Libochov%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma vodorovnými vlnitými bílými pruhy vycházejícími z druhé a čtvrté pětiny žerďového okraje listu. Horní vlající čtvrtina listu žlutá s modrou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se dvěma stříbrnými vlnitými břevny zlatá levá volná čtvrť s modrou lilií."
    },
    {
        obec: "Dolní Lochov",
        kod: 549088,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.452243,
        longitude: 15.286389
    },
    {
        obec: "Dolní Lomná",
        kod: 511951,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73991,
        latitude: 49.547928,
        longitude: 18.706198,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Doln%C3%AD_Lomn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část široká jednu třetinu listu s polovinou žluté orlice s červenou zbrojí přiléhající k polovině zeleného listnatého stromu s hnědým kmenem v bílé vlající části listu, a vyrůstající z modrého vlnitého pruhu o dvou vrcholech a jedné prohlubni širokého jednu osminu šířky listu a vzdáleného od dolního okraje listu jednu šestnáctinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. Vpravo vyniká půl zlaté orlice s červenou zbrojí, vlevo z modrého vlnitého břevna půl listnatého stromu přirozené barvy."
    },
    {
        obec: "Dolní Loučky",
        kod: 595527,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.361092,
        longitude: 16.358812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Doln%C3%AD_Lou%C4%8Dky_znak.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, bílý, modrý, bílý, černý, bílý, modrý a bílý v poměru 2:1:1:1:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři břevna, černé mezi modrými."
    },
    {
        obec: "Dolní Lukavice",
        kod: 557684,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33444,
        latitude: 49.602031,
        longitude: 13.34434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Doln%C3%AD_Lukavice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, pětkrát lomený bílý a modrý pilovitý, v poměru 3 : 1 : 1. V červeném pruhu kosmo žlutý klíč, zuby nahoru a k dolnímu okraji listu, šikmo podložený bílým mečem se žlutým jílcem, hrotem k dolnímu okraji listu. V modrém pruhu pět (3, 2) žlutých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře sníženým, pětkrát lomeným stříbrným břevnem, dělený štít. Nahoře kosmo zlatý klíč, šikmo podložený postaveným stříbrným mečem se zlatým jílcem. Dole pět (3, 2) zlatých hvězd."
    },
    {
        obec: "Dolní Lutyně",
        kod: 598968,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73553,
        latitude: 49.898856,
        longitude: 18.428262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Lutynia_Dolna_herb.png",
        coatOfArmsFlagDescription: "List praporu opakuje znak: modrý list se žlutým snopem tvořený třemi viditelnými hrstmi mezi dvěma bílými růžemi se žlutými semeníky. Poměr šířky praporu k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý snop tvořený třemi viditelnými hrstmi, provázený po stranách stříbrnými růžemi se zlatými semeny."
    },
    {
        obec: "Dolní Město",
        kod: 568601,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58233,
        latitude: 49.627806,
        longitude: 15.382401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Doln%C3%AD_M%C4%9Bsto_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zubatý červený se čtyřmi čtvercovými zuby a třemi stejnými a dvěma polovičními mezerami, v poměru 5 : 3. V bílém pruhu červený žerďový klín s bílou růží se žlutým semeníkem a zelenými kališními lístky. Klín vychází z druhé až šesté sedminy žerďového okraje a dosahuje do páté dvanáctiny délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad kvádrovanou hradbou s prázdnu gotickou branou dva zkřížené hvězdicové vrtáky, vše stříbrné. V bráně stříbrná růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Dolní Morava",
        kod: 580163,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56169,
        latitude: 50.122417,
        longitude: 16.799687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Doln%C3%AD_Morava.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a modrý. V žerďovém pruhu pod sebou tři červené růže se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou se třemi červenými růžemi se zlatými semeníky a zelenými kališními lístky na zeleném návrší listnatý strom přirozené barvy."
    },
    {
        obec: "Dolní Moravice",
        kod: 597287,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79501,
        latitude: 49.978038,
        longitude: 17.32237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Znak_obce_Doln%C3%AD_Moravice.gif",
        coatOfArmsFlagDescription: "Čtvrcený list, v horním žerďovém a dolním vlajícím bílém poli modrá mušle, v dolním žerďovém a horním vlajícím modrém poli žluté zkřížené hornické motyky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě smrk opačných barev, provázený nahoře dvěma modrými mušlemi, dole podložený zlatými zkříženými hornickými motykami."
    },
    {
        obec: "Dolní Němčí",
        kod: 592145,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68762,
        latitude: 48.968468,
        longitude: 17.585962,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Doln%C3%AD_N%C4%9Bm%C4%8D%C3%AD_znak.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem širokým jednu osminu délky listu na modré žerďové a červené vlající pole. V modrém poli vinný hrozen, v červeném palice česneku, obojí bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlaté hrábě, provázené vpravo vinným hroznem, vlevo palicí česneku, obojí stříbrné."
    },
    {
        obec: "Dolní Nětčice",
        kod: 569259,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.476274,
        longitude: 17.675121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Znak_obce_Doln%C3%AD_N%C4%9Bt%C4%8Dice.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, červený a bílý, v poměru 1:3:1. Na červeném poli bílý kůň se žlutou zbrojí, ocasem a hřívou, kráčející po bílém vinařském noži se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kráčí po stříbrném vinařském noži se zlatou rukojetí stříbrný kůň se zlatou zbrojí, ocasem a hřívou."
    },
    {
        obec: "Dolní Nivy",
        kod: 560341,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.243218,
        longitude: 12.637158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Doln%C3%AD_Nivy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. V žerďové polovině vyrůstají do modrého pruhu tři dole spojené obilné klasy, krajní odkloněné s listem, pod nimi v zeleném pruhu hornická kladívka, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší se zlatými hornickými kladívky tři zlaté obilné klasy dole spojené, krajní odkloněné s listem."
    },
    {
        obec: "Dolní Novosedly",
        kod: 562301,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.329225,
        longitude: 14.196093
    },
    {
        obec: "Dolní Olešnice",
        kod: 579173,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54375,
        latitude: 50.524045,
        longitude: 15.712271
    },
    {
        obec: "Dolní Pěna",
        kod: 562467,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.110604,
        longitude: 15.022153
    },
    {
        obec: "Dolní Podluží",
        kod: 562432,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40755,
        latitude: 50.879988,
        longitude: 14.595146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Doln%C3%AD_Podlu%C5%BE%C3%AD_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, zelený, bílý vlnitý se třemi vrcholy a dvěma prohlubněmi a zelený, v poměru 4:1:2:1. Do červeného pruhu vyrůstá žluté kolo sv. Kateřiny s bílými noži, převýšené ve vylomení žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře vyrůstající zlaté kolo sv. Kateřiny se stříbrnými noži, převýšené ve vylomení zlatou korunou, dole stříbrné vlnité břevno."
    },
    {
        obec: "Dolní Pohleď",
        kod: 531260,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.743519,
        longitude: 15.133625
    },
    {
        obec: "Dolní Poustevna",
        kod: 562441,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40782,
        latitude: 50.982619,
        longitude: 14.286956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Doln%C3%AD_Poustevna_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh jednu třetinu délky listu a dva vodorovné pruhy: bílý a červený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dolní Přím",
        kod: 569984,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50316,
        latitude: 50.239725,
        longitude: 15.708601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Doln%C3%AD_P%C5%99%C3%ADm_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou věží s cimbuřím a třemi černými střílnami pod sebou. U horního okraje listu pět žlutých šesticípých hvězd. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s cimbuřím a třemi černými střílnami pod sebou, převýšená pěti zlatými hvězdami v hlavě štítu."
    },
    {
        obec: "Dolní Radechová",
        kod: 574023,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54911,
        latitude: 50.438436,
        longitude: 16.151339,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Doln%C3%AD_Radechov%C3%A1_znak.png",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo dělený list. V zeleném poli obrácená bílá podkova, v ní bílý květ lnu se žlutým středem. V bílém poli k vlajícímu okraji listu obrácená sedící korunovaná zelená žába. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo děleném štítě nahoře obrácená stříbrná podkova, v ní stříbrný květ lnu se zlatým středem, dole doleva obrácená sedící korunovaná zelená žába."
    },
    {
        obec: "Dolní Roveň",
        kod: 574911,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53371,
        latitude: 50.029373,
        longitude: 15.967844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Dolni_Roven_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a sedm střídavě zelených a žlutých vodorovných pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě dvakrát do oblouku prohnutá zelená lipová větev se čtyřmi listy."
    },
    {
        obec: "Dolní Rožínka",
        kod: 595535,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.47711,
        longitude: 16.210566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Dolni_Rozinka_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, červený a bílý. Na žerďovém bílém zkřížené černé ostrve, na středním červeném žlutý oheň se třemi plameny a na bílém vlajícím zkřížená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený kůl se zlatými plameny provázený vpravo nahoře černými zkříženými ostrvemi, vlevo nahoře černými hornickými kladívky."
    },
    {
        obec: "Dolní Rychnov",
        kod: 538591,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35604,
        latitude: 50.164731,
        longitude: 12.645192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Doln%C3%AD_Rychnov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed žlutá hornická kladívka, nad nimi tři bílé květy se žlutými středy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě vedle sebe tři stříbrné květy se zlatými středy nad zlatými hornickými kladívky."
    },
    {
        obec: "Dolní Řasnice",
        kod: 563994,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.945787,
        longitude: 15.168786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Dolni_Rasnice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:4:1. V modrém pruhu podkova, v ní kovářské kleště, oboje žluté. Pod nimi červeno-žlutě čtvrcený tkalcovský člunek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě snížené stříbrné vlnité břevno, nad ním podkova a v ní kovářské kleště, obojí zlaté, pod ním červeno-zlatě čtvrcený tkalcovský člunek."
    },
    {
        obec: "Dolní Ředice",
        kod: 574929,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53375,
        latitude: 50.077906,
        longitude: 15.924033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Doln%C3%AD_%C5%98edice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 1:4:1. V bílém pruhu polovina červeného lva se žlutou zbrojí držícího v pravé tlapě na žluté žerdi třícípý černý praporec se žlutým heroldským křížem a v levé tlapě kosmo černou ostrev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě polovina červeného lva se zlatou zbrojí držícího v pravé tlapě na zlaté žerdi třícípý černý praporec se zlatým heroldským křížem a v levé tlapě černou ostrev."
    },
    {
        obec: "Dolní Slivno",
        kod: 535729,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29478,
        latitude: 50.308882,
        longitude: 14.732843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Ds_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů modrý, žlutý, modrý, bílý a zubatý červený, v poměru 1:1:1:1:2. Zubatý pruh má pět čtvercových zubů a čtyři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře zlaté slunce s tváří, dole červené cimbuřové břevno."
    },
    {
        obec: "Dolní Sokolovec",
        kod: 548448,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.726648,
        longitude: 15.715276,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Doln%C3%AD_Sokolovec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený, v poměru 1 : 2 : 1. V bílém pruhu černý sokol s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s pravým modrým a levým zeleným bokem černý sokol s červenou zbrojí."
    },
    {
        obec: "Dolní Stakory",
        kod: 570940,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.437396,
        longitude: 14.972584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Doln%C3%AD_Stakory_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým žerďovým pruhem širokým jednu třetinu délky listu. V bílém pruhu červená berla závitem k žerďovému okraji a s červeným sudariem zakončeným žlutým třepením. V zeleném poli bílý kolčí helm, klenotem žlutý vyrůstající kozel s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně vpravo polceném štítě vpravo červená berla se sudariem zakončeným zlatým třepením, vlevo stříbrný kolčí helm, klenotem zlatý vyrůstající kozel s červenou zbrojí."
    },
    {
        obec: "Dolní Studénky",
        kod: 553379,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78820,
        latitude: 49.93516,
        longitude: 16.971178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Dolni_studenky_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý, a dva žluté klíny, žerďový s vrcholem v první třetině délky listu a vlající s vrcholem ve druhé třetině délky listu. Uprostřed pod propletenými bílými rohy žlutá kvádrovaná studna s červeným otvorem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě zlatá kvádrovaná studna s červeným otvorem, nad ní dva propletené stříbrné rohy, po stranách dvě přivrácené zlaté berly ovinuté zlatými řetězy."
    },
    {
        obec: "Dolní Těšice",
        kod: 552984,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.495466,
        longitude: 17.806908,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Doln%C3%AD_T%C4%9B%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 5 : 3. Do modrého pruhu vyrůstají dva odkloněné žluté upolíny evropské, každý s květem a odvráceným listem, mezi nimi bílá osmicípá hvězda. Uprostřed červeného pruhu tři dotýkající se bílé klíny vycházející z dolního okraje listu a s vrcholy na dolním okraji modrého pruhu, každý široký jednu šestinu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně sníženě děleném štítě nahoře dva vyrůstající odkloněné zlaté upolíny evropské každý s květem a odvráceným listem, mezi nimi stříbrná osmihrotá hvězda, dole tři stříbrné kužely."
    },
    {
        obec: "Dolní Tošanovice",
        kod: 552607,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73953,
        latitude: 49.684257,
        longitude: 18.488568,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Dolni_tosanovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část se žlutou vykořeněnou lípou a žlutá vlající část. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá vykořeněná lípa."
    },
    {
        obec: "Dolní Třebonín",
        kod: 545473,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38201,
        latitude: 48.855526,
        longitude: 14.409805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Dolni_Trebonin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, a dva kosmé bílé pruhy, každý široký jednu šestnáctinu šířky listu a vzdálené od sebe tři šestnáctiny šířky listu. Mezi bílými pruhy, přes horní a pod dolním, šikmo k hornímu cípu proplouvá žlutý kapr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě z pravého rohu vynikající stříbrné vidle - podávky, mezi hroty šikmo vzhůru plovoucí zlatý kapr."
    },
    {
        obec: "Dolní Újezd",
        kod: 513199,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75123,
        latitude: 49.546068,
        longitude: 17.535582,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Dolni_Ujezd_PR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý v poměru 1:2:1. V zeleném pruhu bílý kozel ve skoku s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít, vpravo vyniká od středu půl stříbrné zavinuté střely, vlevo stříbrný, červeně ozbrojený kozel ve skoku."
    },
    {
        obec: "Dolní Újezd",
        kod: 577995,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56961,
        latitude: 49.82572,
        longitude: 16.25471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Doln%C3%AD_%C3%9Ajezd_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - červený, žlutý, červený, bílý a červený v poměru 3:2:3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dvě zlaté zkřížené odvrácené berly. Pod nimi stříbrná lilie."
    },
    {
        obec: "Dolní Věstonice",
        kod: 584436,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69129,
        latitude: 48.888324,
        longitude: 16.643792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Znak_obce_Doln%C3%AD_V%C4%9Bstonice.gif",
        coatOfArmsFlagDescription: "List dělený žlutou krokví na horní červené a dolní modré pole. Krokev vychází ze čtvrté čtvrtiny žerďového a vlajícího okraje listu a má vrchol ve středu listu. V červeném poli dva odvrácené vodorovně položené žluté vinné hrozny se zkříženými stonky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě s modrou vlnitou patou dřevěný most na dvou trámových podpěrách, nad mostem dva položené vinné hrozny se zkříženými stonky, vše zlaté. Pod mostem doleva plovoucí stříbrná ryba."
    },
    {
        obec: "Dolní Vilémovice",
        kod: 590550,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.153662,
        longitude: 15.977292,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/CoA_of_Doln%C3%AD_Vil%C3%A9movice.svg",
        coatOfArmsFlagDescription: "Modrý list se svislým žlutým žerďovým pruhem širokým jednu třetinu délky listu. Na dělící lince polcená hrncová přilba s přikrývadly a dvěma rozloženými křídly, vše opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě polcená hrncová přilba s přikrývadly a dvěma rozloženými křídly, vše opačných barev."
    },
    {
        obec: "Dolní Vilímeč",
        kod: 587061,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.135989,
        longitude: 15.520541
    },
    {
        obec: "Dolní Zálezly",
        kod: 567931,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40301,
        latitude: 50.597423,
        longitude: 14.04319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Dolni_Zalezly_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý s bílou růží se žlutým semeníkem u žerdi, bílý, červený, bílý a modrý, v poměru 5:2:2:2:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno s červeným, vzhůru prohnutým lososem, nad ním tři stříbrné růže se zlatými semeníky."
    },
    {
        obec: "Dolní Zimoř",
        kod: 531987,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.426717,
        longitude: 14.501473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Doln%C3%AD_Zimo%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 3 : 1. V zeleném pruhu čelně bílá hlava boha větru Borease foukající vítr na dolní okraj listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě čelně stříbrná hlava boha větru Borease foukající vítr mezi dvě zlaté pískovcové skály vynikající z okrajů štítu."
    },
    {
        obec: "Dolní Žandov",
        kod: 554502,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35493,
        latitude: 50.018055,
        longitude: 12.551125,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Znak_Dolni_Zandov.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 5 : 1. Do červeného pruhu vynikají dvě bílé kvádrované věže s rozšiřujícím se soklem, dvěma černými obloukovými okny vedle sebe pod cimbuřím a s černou kuželovou střechou zakončenou žlutou makovicí. Mezi věžemi modrý štít, dotýkající se horního okraje zeleného pruhu, s bílou hlavou psa-věžníka se žlutým obojkem. Na štítu korunovaná turnajská přilba s modro-bílými přikryvadly, klenotem vyrůstající panna se žlutými vlasy, v bílém šatě, s rozpaženýma rukama. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Dolní Žďár",
        kod: 562602,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.101227,
        longitude: 14.988276
    },
    {
        obec: "Dolní Životice",
        kod: 553051,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74756,
        latitude: 49.897418,
        longitude: 17.779797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Dolni_zivotice_CoA_cz.png",
        coatOfArmsFlagDescription: "Modrý list s bílým zubatým pruhem na dolním okraji listu. Poměr modrého pole k bílému pruhu je 2:1. Výška obdélníkových zubů je devítina šířky listu, šířka je šestina délky listu. V žerďové modré polovině listu bílý pancíř s červeným heroldským křížem a bílými stehenními pláty. Pancíř je podložen zkříženými žlutými šípy hroty vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou stříbrnou hradbou o třech stínkách stříbrný pancíř se stehenními pláty, zdobený červeným heroldským křížem a podložený dvěma zkříženými zlatými šípy."
    },
    {
        obec: "Doloplazy",
        kod: 568392,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78356,
        latitude: 49.567276,
        longitude: 17.411581,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Doloplazy_%28OL%29_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed žlutá berla závitem k žerdi kosmo podložená bílou sekerou na žlutém topůrku a šikmo bílou radlicí, hrotem k hornímu cípu a ostřím k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě zlatá berla kosmo podložená stříbrnou sekerou na zlatém topůrku a šikmo stříbrnou vztyčenou, doleva obrácenou radlicí."
    },
    {
        obec: "Doloplazy",
        kod: 589454,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79826,
        latitude: 49.350312,
        longitude: 17.160101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Doloplazy_PV_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se vztyčenými žlutými hráběmi prostrčenými dvěma přivrácenými a dvakrát zkříženými bílými srpy se žlutými rukojeťmi dolů. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Domamil",
        kod: 590568,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67543,
        latitude: 49.083336,
        longitude: 15.695675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Domamil_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět šikmých pruhů, červený, bílý, modrý, bílý a červený. Modrý pruh vychází z první třetiny dolního okraje do třetí třetiny horního okraje listu, v něm tři bílé růže se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo srdce kvetoucí třemi růžemi se zlatými semeníky, vlevo vztyčená, doleva obrácená radlice, vše stříbrné."
    },
    {
        obec: "Domanín",
        kod: 562840,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 48.971783,
        longitude: 14.739869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Doman%C3%ADn_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "Modro - bíle čtvrcený list, v horním žerďovém poli knížecí koruna, v dolním žerďovém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Nahoře ve stříbrno - modře sedmkrát polceném poli na prostředním vrchu černého trojvrší nasazená knížecí koruna. Dole ve stříbrném poli červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Domanín",
        kod: 586145,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69683,
        latitude: 49.00177,
        longitude: 17.284869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Doman%C3%ADn_%28Hodon%C3%ADn%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným středovým tlapatým křížem s rameny širokými na okraji listu tři pětiny šířky listu. Ve středu kříže žlutý vinný hrozen převýšený žluto-červenou knížecí korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený tlapatý kříž, v něm postavený stříbrný meč se zlatým jílcem obtočený zlatou vinnou ratolestí se třemi listy vpravo a dvěma hrozny vlevo, převýšený knížecí korunou a provázený dvěma odvrácenými zlatými vinařskými noži - kosíři."
    },
    {
        obec: "Domašín",
        kod: 563048,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43151,
        latitude: 50.420806,
        longitude: 13.172313
    },
    {
        obec: "Dománovice",
        kod: 513181,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.111955,
        longitude: 15.322621,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Domanovice_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list. V žerďové polovině mezi modrými býčími rohy vykořeněný dub s hnědým kmenem se dvěma nahoře překříženými větvemi a se zelenými listy. Mezi větvemi vztyčený zelený habrový dvoulist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě mezi dvěma modrými býčími rohy vykořeněný dub přirozené barvy se dvěma nahoře překříženými větvemi, mezi nimi vztyčený zelený habrový dvoulist."
    },
    {
        obec: "Domašov",
        kod: 582964,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.245429,
        longitude: 16.343924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Doma%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými obilnými klasy se čtyřmi listy spojenými u dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři obilné spojené klasy se čtyřmi listy."
    },
    {
        obec: "Domašov nad Bystřicí",
        kod: 545279,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78306,
        latitude: 49.742397,
        longitude: 17.445404,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Coat_of_arms_of_Doma%C5%A1ov_nad_Byst%C5%99ic%C3%AD.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný bílý o čtyřech vrcholech a pěti prohlubních, v poměru 5 : 1. V červeném pruhu bílá levá ruka palcem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Domašov u Šternberka",
        kod: 552313,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.705886,
        longitude: 17.338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Doma%C5%A1ov_u_%C5%A0ternberka_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý se žlutým kruhovým polem v žerďové části a červený s bílou lilií v žerďové části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně dělený štít. Nahoře vyrůstá postava sv. Štěpána v červeno-stříbrném šatě, se zlatými vlasy a svatozáří, držící v pravici kámen a v levici palmovou ratolest, obojí zlaté. Dole stříbrná lilie."
    },
    {
        obec: "Domaželice",
        kod: 513211,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75115,
        latitude: 49.427781,
        longitude: 17.545867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Znak_obce_Doma%C5%BEelice.jpg",
        coatOfArmsFlagDescription: "Modrý list se zeleným vodorovným pruhem na dolním okraji, širokém jednu šestinu šířky listu, ze kterého vyniká žlutý, bíle korunovaný lev s červenou zbrojí, provázený v žerďové části žlutou šesticípou hvězdou a ve vlající části žlutou svatojakubskou mušlí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zeleného trojvrší vyrůstající zlatý, stříbrně korunovaný lev s červenou zbrojí, provázený nahoře vpravo hvězdou, vlevo svatojakubskou mušlí, obojí zlaté."
    },
    {
        obec: "Domažlice",
        kod: 553425,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.439803,
        longitude: 12.931243,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Doma%C5%BElice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Domoraz",
        kod: 578517,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.250731,
        longitude: 13.662108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Coat_of_arms_of_Domoraz.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě modré a žluté, v poměru 4:3:4:5. V horním žlutém pruhu tři červené růže se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým trojvrším, prostřední vrch nižší, šikmé zlaté břevno se třemi červenými růžemi se zlatými semeníky."
    },
    {
        obec: "Domousnice",
        kod: 535745,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29448,
        latitude: 50.394302,
        longitude: 15.101704
    },
    {
        obec: "Domoušice",
        kod: 566152,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43968,
        latitude: 50.234257,
        longitude: 13.729627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Domou%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý, zelený a žlutý, v poměru 2 : 1 : 2 : 1. V žerďové části červeného pruhu žlutý chmelový list s úponky a šišticí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít s červenou hlavou a zelenou patou. V hlavě chmelová šištice provázená po stranách chmelovým listem s úponky, vše zlaté. Z paty vyrůstají dva listnaté, korunami spojené, stromy přirozené barvy."
    },
    {
        obec: "Doňov",
        kod: 507610,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.21318,
        longitude: 14.776207,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Do%C5%88ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, vlnitý modrý, vlnitý bílý, vlnitý modrý a zvlněný bílo-červeně polcený, v poměru 1 : 1 : 1 : 1 : 12. V bílém poli půl červené růže přiléhající k půli bílého vozového kola v červeném poli, uprostřed žlutý semeník. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě pod třikrát stříbrno-modře vlnitě dělenou vlnitou hlavou zprava přiléhá k poltící linii půl červené růže, zleva půl stříbrného vozového kola, uprostřed zlatý semeník."
    },
    {
        obec: "Doubek",
        kod: 564885,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 50.017715,
        longitude: 14.737871,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Doubek_%28Prague-East%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červeno-žlutě polcený a bílo-červeně polcený. V horním pruhu tři vztyčené dubové listy opačných barev, v dolním pruhu koruna opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním červeno-zlatě polceném poli tři dubové listy opačných barev, v dolním stříbrno-červeně polceném poli koruna opačných barev."
    },
    {
        obec: "Doubice",
        kod: 530417,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40747,
        latitude: 50.888706,
        longitude: 14.461724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Doubice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený. V žerďové části žlutého pruhu ulomená kosmo svěšená černá dubová větev se čtyřmi zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve zlatém poli zelený vykořeněný dub s černým kmenem, vpravo s ulomenou větví se čtyřmi listy, dolní pole stříbrno-červeně polceno, vpravo půl černé orlice se zlatou zbrojí, vlevo tři stříbrné vlčí zuby."
    },
    {
        obec: "Doubrava",
        kod: 568864,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73533,
        latitude: 49.858829,
        longitude: 18.480287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Doubrava_%28okres_Karvin%C3%A1%29_znak.jpg",
        coatOfArmsFlagDescription: "Na modrém listě bílý dub z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít, v jehož pravé polovině je půl slezské orlice, v levé vykořeněný dub, nad ním hornická kladívka, vše stříbrné."
    },
    {
        obec: "Doubravčice",
        kod: 533301,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.022153,
        longitude: 14.793181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Doubrav%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílo-zelený a zeleno-bílý, dělené v poměru 3 : 1. V horním žerďovém poli vztyčený zelený dubový list, v horním vlajícím poli žlutý obilný klas s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít s cimbuřovou patou opačných barev. Nad ní vpravo vztyčený zelený dubový list, vlevo zlatý obilný klas s listy."
    },
    {
        obec: "Doubravice",
        kod: 535664,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37006,
        latitude: 48.935899,
        longitude: 14.510822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Doubravice_CB_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený černým šikmým pruhem vycházejícím z první čtvrtiny dolního okraje listu do čtvrté čtvrtiny horního okraje na bílé žerďové a žluté vlající pole. V bílém poli šikmo svěšený zelený dubový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-černě polcený štít. Vpravo šikmo svěšený dubový list nad svěšeným žaludem, vše přirozené barvy, vlevo doleva obrácený zlatý lev s červenou zbrojí."
    },
    {
        obec: "Doubravice",
        kod: 550981,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38735,
        latitude: 49.352117,
        longitude: 13.862024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Doubravice_%28okres_Strakonice%29_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým žerďovým pruhem, širokým jednu třetinu délky listu. V žerďové a střední části vztyčený dubový list opačných barev, v něm bílo-červeně polcený osmihrotý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vztyčený dubový list opačných barev, v něm stříbrno-červeně polcený osmihrotý kříž."
    },
    {
        obec: "Doubravice",
        kod: 579181,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54451,
        latitude: 50.405574,
        longitude: 15.761986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Doubravice_TU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a bílý v poměru 9 : 2 : 9. Z horního okraje černého pruhu vyrůstá meduňka lékařská se třemi listy, krajními odkloněnými, a dvěma květy mezi nimi. V dolním bílém pruhu tři (2, 1) po obvodu červeně hořící modrá kruhová pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černé břevno, z něhož vyrůstá meduňka lékařská přirozené barvy se třemi listy, krajními odkloněnými, a dvěma květy mezi nimi. Dole tři (2, 1) po obvodu červeně hořící modré kotouče."
    },
    {
        obec: "Doubravice nad Svitavou",
        kod: 581542,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67911,
        latitude: 49.436736,
        longitude: 16.629473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Doubravice_nad_Svitavou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený, v poměru 5:7:5. Ve žlutém pruhu zelený vykořeněný listnatý strom s hnědým kmenem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Doubravička",
        kod: 571067,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29430,
        latitude: 50.395208,
        longitude: 14.776212,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Doubravi%C4%8Dka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, přeložené bílým středovým křížem s rameny širokými pětinu šířky listu. V horním žerďovém poli kosmo a v horním vlajícím poli šikmo dubový dvoulist se žaludem. Z osmé desetiny žerďového okraje listu vychází žlutý vodorovný pruh přes dolní rameno kříže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrný heroldský kříž dole přeložený zlatou korunou, horní rameno kříže provázeno vpravo kosmo a vlevo šikmo vztyčeným zlatým dubovým dvoulistem s žaludem."
    },
    {
        obec: "Doubravník",
        kod: 595551,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59261,
        latitude: 49.424208,
        longitude: 16.352799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Doubravnik_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma zkříženými procesními kříži, provázenými po stranách dvěma osmicípými hvězdami a dole mezi žerděmi lilií, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva zkřížené procesní kříže, provázené po stranách dvěma osmihrotými hvězdami a dole lilií, vše zlaté."
    },
    {
        obec: "Doubravy",
        kod: 585181,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76345,
        latitude: 49.142547,
        longitude: 17.668211,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Doubravy_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové a dolní vlající pole bílé, dolní žerďové a horní vlající červené s bílým listem lekna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě švestková větev se čtyřmi ratolestmi, každá s jedním plodem, pod ním dvě uťatá lekna, vše stříbrné."
    },
    {
        obec: "Doudleby",
        kod: 544400,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.893631,
        longitude: 14.501587,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Doudleby_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s žerďovým modrým klínem. V klínu polovina rytíře v bílé zbroji s otevřenou přilbou se žlutým chocholem, s taseným bílým mečem a levou rukou v bok. Klín nahoře a dole provázen vodorovnou černou píšťalou náustkem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít se zvýšeným vydutým modrým hrotem, v něm polovina rytíře ve stříbrné zbroji s otevřenou přílbou se zlatým chocholem, s taseným stříbrným mečem a levou rukou v bok. V horních polích po jedné vztyčené černé píšťale, pravá šikmo, levá kosmo."
    },
    {
        obec: "Doupovské Hradiště",
        kod: 500127,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36272,
        latitude: 50.25,
        longitude: 13,
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a tři vodorovné pruhy, zelený, bílý a modrý. V červeném pruhu žlutá dvouuchá váza, v ní tři bílé pětilisté květy se žlutými středy na zelených stoncích se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít s modrou cimbuřovou patou. Vpravo zlatá dvouuchá váza, v ní tři stříbrné pětilisté květy se zlatými středy na zelených stoncích se dvěma listy. Vlevo zelený jehličnatý strom s černým kmenem obklopeným červenou obrácenou podkovou. V patě zlatá lilie."
    },
    {
        obec: "Doudleby nad Orlicí",
        kod: 576301,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51742,
        latitude: 50.107644,
        longitude: 16.261846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Doudleby_nad_Orlici_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se dvěma modrými vlnitými pruhy, s jedním vrcholem a jednou prohlubní. Poměry na žerďovém okraji 3:1:1:1:2 a na vlajícím 2:1:1:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně čtvrcený štít, uprostřed do krajů štítu most s obloukovým zábradlím provázeným nahoře dvěma přivrácenými dubovými listy, dole dvě vlnitá břevna, vše stříbrné."
    },
    {
        obec: "Doupě",
        kod: 587079,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.236937,
        longitude: 15.434721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Doup%C4%9B_%28Jihlava_District%29_CoA.jpg"
    },
    {
        obec: "Drahanovice",
        kod: 501751,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78344,
        latitude: 49.578656,
        longitude: 17.077119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Znak_obce_Drahanovice.gif",
        coatOfArmsFlagDescription: "Červeno-zeleně kosmo dělený list, uprostřed bílý kozel ve skoku se žlutou zbrojí a obojkem s kroužkem, provázený v zeleném poli čtyřmi šesticípými hvězdami (1,2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně kosmo děleném štítě stříbrný kozel ve skoku se zlatou zbrojí a zlatým obojkem s kroužkem, provázený vlevo čtyřmi (1,2,1) zlatými šestihrotými hvězdami."
    },
    {
        obec: "Drahany",
        kod: 589462,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79861,
        latitude: 49.433359,
        longitude: 16.897488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Coa_Czech_Town_Drahany.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a modrý, v poměru 1:1:8. V žerďové polovině modrého pruhu bílá ohlížející se ovečka obrácená k vlajícímu okraji, levou přední nohou přidržující kosmo žlutou žerď s křížem a bílou dvoucípou korouhví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlato-červeně dělenou hlavou na zeleném trojvrší doleva obrácená ohlížející se stříbrná ovečka, držící na zlaté žerdi s křížem stříbrnou dvoucípou korouhev."
    },
    {
        obec: "Drahelčice",
        kod: 531146,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25219,
        latitude: 50.031926,
        longitude: 14.203056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Drahelcice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list, uprostřed červený orel s modrou zbrojí přeložený černou kotvou, kterou drží pařáty za ramena. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červený orel s modrou zbrojí přeložený černou kotvou, kterou drží pařáty za ramena."
    },
    {
        obec: "Drahenice",
        kod: 599298,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26285,
        latitude: 49.520949,
        longitude: 13.95851,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Coats_of_arms_Drahenice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed listu kosmo bílý meč se žlutým jílcem hrotem dolů, nad bílou kosmo vztyčenou hornickou motykou se žlutou násadou, obojí šikmo podložené stejnými figurami. Mezi jílci žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě kosmo postavený stříbrný meč se zlatým jílcem, pod ním kosmo vztyčená stříbrná hornická motyka se zlatou násadou, obojí šikmo podloženo stejnými figurami, nahoře uprostřed zlatá lilie."
    },
    {
        obec: "Drahkov",
        kod: 540251,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.557261,
        longitude: 13.47254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Drahkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vlnkovitý modrý, v poměru 2 : 3. V bílém pruhu modrá lilie mezi dvěma vztyčenými zelenými dubovými listy, v modrém mlýnské kolo pod dvěma zkříženými zlomenými obilnými klasy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnkovitě děleném štítě nahoře modrá lilie mezi dvěma vztyčenými zelenými dubovými listy, dole mlýnské kolo pod dvěma zkříženými zlomenými obilnými klasy, vše zlaté."
    },
    {
        obec: "Drahlín",
        kod: 540145,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.732317,
        longitude: 13.968974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Drahl%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žluto-zeleně polcený, zubatý zeleno-žlutě polcený, vlnitý bílo-modře polcený a zvlněný zeleno-žlutě polcený, v poměru 4 : 3 : 1 : 1. V horním žerďovém poli černá kančí hlava. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Cimbuřovitě dělený štít. Horní pole zlato-zeleně polcené, vpravo černá kančí hlava, vlevo zlatá hornická kladívka. V dolním zeleno-zlatě polceném poli stříbrno-modře polcené vlnité břevno."
    },
    {
        obec: "Drahňovice",
        kod: 532151,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.822575,
        longitude: 14.90175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Drah%C5%88ovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým pruhem širokým čtvrtinu délky listu. V modrém poli bílá kaple s oknem v patře a dveřmi v přízemí, obojí černé, s černou stanovou střechou s lucernou se zavěšeným žlutým zvonem, zakončenou makovicí s křížkem a nahoře provázenou dvěma vztyčenými přivrácenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zlatém návrší stříbrná kaple s oknem v patře a dveřmi v přízemí, obojí černé, s černou stanovou střechou s lucernou se zavěšeným zlatým zvonem, zakončenou makovicí s křížkem. Věž nahoře provázena dvěma vztyčenými přivrácenými zlatými lipovými listy."
    },
    {
        obec: "Drahobudice",
        kod: 564681,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28144,
        latitude: 49.937579,
        longitude: 15.066361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Drahobudice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy žlutý, červený a žlutý, v poměru 1 : 2 : 1. V červeném pruhu knížecí čepice s hermelínovým lemem ve žlutém kruhovém poli, do něhož jsou vetknuty tři (2, 1) bílé meče se žlutými jílci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo knížecí čepice s hermelínovým lemem ve zlatém kotouči, do něhož jsou vetknuty tři (2, 1) stříbrné meče se zlatými jílci, vlevo nad dřevěnou palisádou přirozené barvy černý kohout s červenou zbrojí."
    },
    {
        obec: "Drahobuz",
        kod: 564770,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.525222,
        longitude: 14.320808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Drahobuz_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zubatý bílý se třemi čtvercovými zuby a čtyřmi mezerami, krajní poloviční, červený s bílým zvonem a zvlněný bílý se třemi vrcholy, v poměru 1 : 6 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě šikmé červené břevno, nahoře cimbuřové, dole vlnité. V břevnu šikmo dolů vyrůstající větev kaštanu se dvěma listy a se šikmo zavěšeným zvonem, vše stříbrné. Pod břevnem šikmo červené krojidlo."
    },
    {
        obec: "Drahonice",
        kod: 551015,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.200853,
        longitude: 14.074682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Drahonice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. V bílém pruhu kosmo k hornímu rohu černý šíp s modrým hrotem a červeným opeřením, v modrém pruhu žlutý býčí roh postrkaný třemi bílými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo kosmý černý šíp s modrým hrotem a červeným opeřením, vlevo zlatý býčí roh postrkaný třemi stříbrnými lipovými listy."
    },
    {
        obec: "Drahonín",
        kod: 595560,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59261,
        latitude: 49.414161,
        longitude: 16.277005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Drahon%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. Přes ně vodorovný červený oboustranně zubatý posunutý pruh široký jednu devítinu šířky listu. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře polceném štítě, s červeným oboustranně cimbuřovým břevnem, buvolí rohy opačných barev, nahoře provázené vpravo černou zubří hlavou s červeným jazykem a zlatou houžví, vlevo zlatou hlavicí berly."
    },
    {
        obec: "Drahoňův Újezd",
        kod: 530379,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.877208,
        longitude: 13.725945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Draho%C5%88%C5%AFv_%C3%9Ajezd_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, v horní polovině dvě věže zakončené cimbuřím se třemi zuby a s černou obloukovou branou, v dolní svatojakubská mušle, vše červeno-bíle polcené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nahoře vedle sebe dvě věže s černými obloukovými branami a cimbuřím o třech stínkách, dole svatojakubská mušle, vše červeno-stříbrně polceno."
    },
    {
        obec: "Drahotín",
        kod: 553573,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.52424,
        longitude: 12.758259,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Drahot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed obrácená bílá podkova. Uvnitř podkovy z bílo-červeně polcené růže vyrůstají tři žluté obilné klasy, krajní odkloněné s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná obrácená podkova. Uvnitř podkovy ze stříbrno-červeně polcené růže vyrůstají tři zlaté obilné klasy, krajní odkloněné s listem."
    },
    {
        obec: "Drahouš",
        kod: 529711,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27033,
        latitude: 50.080232,
        longitude: 13.47577,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Drahou%C5%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou jelení hlavou v žerďové a střední části. Mezi parohy žlutě zářící bílý latinský kříž. Ve vlající části tři bílé šesticípé hvězdy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá jelení hlava provázená mezi parohy zlatě zářícím stříbrným latinským křížem a dole třemi (2, 1) stříbrnými hvězdami."
    },
    {
        obec: "Drahov",
        kod: 563765,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.175466,
        longitude: 14.754277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Drahov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. V modrém pruhu žlutě přepásaná bílá lilie provázená dvěma žlutými růžemi s červenými semeníky. V zeleném pruhu bílý květ leknínu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně vlnitě dělený štít. V horním poli stříbrná, zlatě přepásaná lilie provázená nahoře dvěma zlatými růžemi s červenými semeníky. V dolním poli stříbrný květ leknínu se zlatým středem."
    },
    {
        obec: "Drachkov",
        kod: 560219,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.242924,
        longitude: 13.84157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Drachkov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký polovinu délky listu a tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1 : 3 : 1. Ve žlutém pruhu vztyčená červená střela s modrým hrotem a bílým opeřením. V bílém pruhu červený maltézský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo vztyčená červená střela s modrým hrotem a stříbrným opeřením, vlevo doleva obrácená stříbrná radlice, v ní červený maltézský kříž."
    },
    {
        obec: "Drahotěšice",
        kod: 535958,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.124437,
        longitude: 14.546683
    },
    {
        obec: "Dráchov",
        kod: 552275,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.226539,
        longitude: 14.706724
    },
    {
        obec: "Drásov",
        kod: 582972,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66424,
        latitude: 49.331934,
        longitude: 16.47809,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Drasov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým snopem ve střední části. V žerďové části a vlající části po bílém liliovém kříži. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý snop mezi dvěma stříbrnými liliovými kříži."
    },
    {
        obec: "Drásov",
        kod: 540153,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.702186,
        longitude: 14.117807,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Coats_of_arms_Dr%C3%A1sov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 5:2:5. V červeném pruhu bílá obrněná noha obrácená k vlajícímu okraji, v bílém pruhu tři červení kapři nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně modrým zúženým kůlem polcený štít, vpravo doleva obrácená stříbrná obrněná noha, vlevo tři červení kapři nad sebou."
    },
    {
        obec: "Dražeň",
        kod: 578665,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33101,
        latitude: 49.926953,
        longitude: 13.287978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Dra%C5%BEe%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Uprostřed mezi dvěma žlutými vykořeněnými lípami žlutý latinský kříž na bílém soklu se dvěma stupni. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně děleném štítě mezi dvěma zlatými vykořeněnými lípami zlatý latinský kříž na stříbrném soklu se dvěma stupni."
    },
    {
        obec: "Draženov",
        kod: 553581,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.455812,
        longitude: 12.872799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Dra%C5%BEenov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený. V bílém pruhu černá psí hlava s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravá polovina stříbrno-modře dělená, nahoře černá psí hlava s krkem, dole kosmo stříbrný čakan s topůrkem přirozené barvy šikmo podložený stříbrným svitkem pergamenu. V levém zeleném poli volné stříbrné průčelí kaple s vchodem, oknem a lucernou s oknem, zakončenou červenou stříškou se zlatým křížkem, vchod a okna oblouková a černá."
    },
    {
        obec: "Dražice",
        kod: 552283,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.415254,
        longitude: 14.640682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Drazice_TA_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 2 : 1 a svislý vlající červený pruh široký třetinu délky listu. Z bílého pruhu vyrůstá žlutý lev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně děleném štítě nahoře vyrůstající zlatý lev, dole červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Dražíč",
        kod: 549371,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.305974,
        longitude: 14.383923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Drazic_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed žlutý černě okřídlený spár s pěti pery, v každém žlutá šesticípá hvězda. V modrém pruhu spár provázený třemi (2,1) žlutými kruhovými poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě zlatý černě okřídlený spár s pěti pery, v každém zlatá hvězda, provázený dole třemi (2,1) zlatými kotouči."
    },
    {
        obec: "Dražičky",
        kod: 552291,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39175,
        latitude: 49.399131,
        longitude: 14.59882
    },
    {
        obec: "Drážov",
        kod: 551023,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.167415,
        longitude: 13.740707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Dr%C3%A1%C5%BEov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 4 : 1. V modrém pruhu bílá lilie, dole tvořená dvěma svěšenými odkloněnými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyniká zlaté vozové kolo se třemi viditelnými loukotěmi, z něhož vyrůstá zlatý lovecký oštěp s křížovým hrotem na stříbrném ratišti, provázený čtyřmi (2, 2) stříbrnými liliemi, dole tvořenými dvěma svěšenými odkloněnými zlatými lipovými listy."
    },
    {
        obec: "Dražovice",
        kod: 592994,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.194842,
        longitude: 16.943484,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/CoA_of_Dra%C5%BEovice_%28Vy%C5%A1kov_District%29.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1:4:1. Červený pruh je čtvrcen templářským křížem; v horním žerďovém a dolním vlajícím poli šesticípá hvězda, v horním vlajícím a dolním žerďovém poli slunce bez tváře, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na horním okraji položené zlaté radlice ohlížející se vykračující stříbrná ovečka se zlatou zbrojí, za ní zlatý procesní kříž na stříbrné holi, provázená nahoře vpravo hvězdou a vlevo sluncem, obojí zlaté."
    },
    {
        obec: "Dražovice",
        kod: 566683,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.224775,
        longitude: 13.607113,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Dra%C5%BEovice_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 1:2:1. Uprostřed modrého pruhu bílá kvádrovaná věž s cimbuřím, podložená žlutými zkříženými šípy hroty nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli stříbrná kvádrovaná věž s cimbuřím podložená zkříženými vztyčenými zlatými šípy, dolní polovina polcena, pravé pole třikrát stříbrno-modře dělené, levé červené."
    },
    {
        obec: "Dražůvky",
        kod: 586153,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69633,
        latitude: 49.030633,
        longitude: 17.019078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Dra%C5%BE%C5%AFvky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, modrý, červený a bílý, v poměru 2:3:3:2. Uprostřed bílá husa se žlutou zbrojí. V červeném poli nad husou tři zkřížené bílé liliové hůlky, prostřední obrácená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrná husa se zlatou zbrojí, provázená vlevo nahoře třemi zkříženými stříbrnými liliovými hůlkami, prostřední obrácená."
    },
    {
        obec: "Drevníky",
        kod: 540170,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.72026,
        longitude: 14.274132,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Coats_of_arms_Drevn%C3%ADky.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a červený, v poměru 2:1:1. V žerďové části zeleného pruhu žlutý zvon zavěšený na bílé vodorovné ostrvi se čtyřmi suky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou s červenou, zlatě lemovanou knížecí čepicí podloženou doleva položeným modrým mečem se zlatým jílcem zlatý zvon zavěšený na stříbrné ostrvi se čtyřmi suky."
    },
    {
        obec: "Drhovice",
        kod: 563307,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.432915,
        longitude: 14.558481,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Drhovice_CoA.png",
        coatOfArmsFlagDescription: "List dělený kosmým bílým pruhem širokým sedminu šířky listu na žerďové červené a vlající modré pole. V červeném poli kalich, v modrém kosmo lipová ratolest se třemi listy a plodenstvím, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně stříbrným kosmým vlnitým břevnem dělený štít. Nahoře kosmo lipová ratolest se třemi (1, 2) listy a plodenstvím, dole kalich, obojí zlaté."
    },
    {
        obec: "Drhovle",
        kod: 549380,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.333979,
        longitude: 14.04237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Drhovle_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý. V modrém pruhu žlutá dvoupatrová věž s prázdnou branou, třemi okny (1,2), horním obloukovým, s červenou mansardovou střechou se žlutým arkýřem zakončenou zlatou makovicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá dvoupatrová věž s prázdnou branou, třemi okny (1,2), horním obloukovým, s červenou mansardovou střechou se zlatým arkýřem, zakončenou zlatou makovicí, provázená dvěma zlatými liliemi."
    },
    {
        obec: "Drhovy",
        kod: 540188,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.739163,
        longitude: 14.232487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Coats_of_arms_Drhovy.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtvrcený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, červený a bílý. V žerďovém pruhu horní žerďové pole tvoří šest vodorovných pruhů, střídavě bílých a červených, dolní žerďové pole je žluté, horní vlající pole je žluté se zeleným stonkem lnu s listy a třemi modrými květy, dolní vlající pole je červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole pětkrát stříbrno-červeně dělené, v levém zlatém poli stonek lnu s listy a třemi květy přirozené barvy."
    },
    {
        obec: "Drmoul",
        kod: 554511,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.93907,
        longitude: 12.665618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Znak_Drmoul.gif",
        coatOfArmsFlagDescription: "List tvoří bílo-červeně dělené karé široké jednu třetinu délky listu nad stejně velkým čtvercovým bílým polem a tři vodorovné pruhy, červený, bílý a červený, v poměru 1:1:2. V karé černá věž s cimbuřím o třech zubech a žlutou branou, provázená dvěma odvrácenými hnědými poutnickými holemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě černá věž s cimbuřím o třech stínkách a se zlatou branou, provázená dvěma odvrácenými poutnickými holemi přirozené barvy."
    },
    {
        obec: "Drnek",
        kod: 512991,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27377,
        latitude: 50.195744,
        longitude: 13.972514
    },
    {
        obec: "Drnholec",
        kod: 584444,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69183,
        latitude: 48.857558,
        longitude: 16.485962,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Drnholec_znak.png",
        coatOfArmsFlagDescription: "List tvoří horní vodorovný modrý pruh široký polovinu šířky listu a čtyři svislé střídavě bílé a černé pruhy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli vyrůstá moravská orlice, dolní pole třikrát stříbrno-černě polceno."
    },
    {
        obec: "Drnovice",
        kod: 581551,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67976,
        latitude: 49.469406,
        longitude: 16.542335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Drnovice_BK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných střídavě černých a žlutých pruhů, uprostřed překrytých svislým modrým pruhem širokým jednu třetinu délky listu s bílou patrovou věží s dvojitým cimbuřím, černou branou a oknem. Nad věží žlutá šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě pětkrát děleném štítě modrý hrot, v něm stříbrná patrová věž s dvojitým cimbuřím, černou branou a oknem, převýšená zlatou hvězdou."
    },
    {
        obec: "Drnovice",
        kod: 593001,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68304,
        latitude: 49.276395,
        longitude: 16.951569,
        coatOfArmsFlagDescription: "Modrá žerďová část, střední a vlající žlutá část se vztyčenou zelenou palmovou ratolestí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít, vpravo zlatá rozletitá střela nad zlatým vinným hroznem, vlevo zelená palmová ratolest."
    },
    {
        obec: "Drnovice",
        kod: 585190,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76325,
        latitude: 49.17899,
        longitude: 17.957414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Drnovice_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, v poměru 2 : 1 : 2 : 3 : 2 : 1 : 2. Liché pruhy zeleno - červeně polcené, prostřední bílý s červenou růží se žlutým semeníkem a zelenými kališními lístky provázenou svěšenými zelenými lipovými listy, druhý a šestý pruh žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě stříbrné vlnité břevno s červenou růží se zlatým semeníkem a zelenými kališními lístky mezi dvěma svěšenými zelenými lipovými listy, převýšené zlatou korunou. Z paty štítu šlehá pět zlatých plamenů."
    },
    {
        obec: "Drobovice",
        kod: 531341,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.889628,
        longitude: 15.413523,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Drobovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, bílý, modrý, bílý, černý, bílý, modrý a bílý. V bílém karé o velikosti tří sedmin šířky listu červená lilie, v dolním rohu stejně veliké bílé čtvercové pole s červeným mlýnským kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý heroldský kříž nahoře provázený vpravo lilií, vlevo mlýnským kolem, obojí červené, dole přeložený modrým vlnitým břevnem."
    },
    {
        obec: "Droužetice",
        kod: 560243,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.289184,
        longitude: 13.89812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/CoA_of_Drou%C5%BEetice.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným středovým křížem s rameny širokými pětinu šířky listu. V horním žerďovém poli lilie, v dolním vlajícím poli kosmo střela obojí černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený heroldský kříž provázený vpravo nahoře lilií, vlevo dole kosmou střelou, obojí černé."
    },
    {
        obec: "Droužkovice",
        kod: 563056,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43144,
        latitude: 50.430887,
        longitude: 13.429205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Drouzkovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, žlutý, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě uprostřed zlatý kůl se zeleným listnatým stonkem růže po obou stranách kvetoucím stříbrnými květy se zlatými semeníky."
    },
    {
        obec: "Drozdov",
        kod: 531154,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26761,
        latitude: 49.863241,
        longitude: 13.840685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Drozdov_%28Beroun_District%29_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list se zeleným zubatým lemem, u žerďového a vlajícího okraje se třemi zuby, u horního a dolního okraje s pěti zuby. Uprostřed hnědý drozd s černým zobákem a červenými pařáty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným cimbuřovým lemem drozd přirozené barvy."
    },
    {
        obec: "Drslavice",
        kod: 537187,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.025427,
        longitude: 13.920865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Drslavice_%28Prachatice_District%29_CoA.jpg"
    },
    {
        obec: "Drslavice",
        kod: 592153,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68733,
        latitude: 49.047152,
        longitude: 17.600184,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, oboustranně zubatý žlutý a zelený, v poměru 2:1:2. V červeném pruhu lekno, v zeleném vinný hrozen na stonku, oboje bílé a přivrácené. Žlutý pruh má pět zubů a čtyři mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatá okrouhlá věž s cimbuřím a třemi (2,1) červenými okny na rozšířeném kvádrovém soklu, provázená přivrácenými vpravo leknem a vlevo vinným hroznem na stonku, obojí stříbrné a přivrácené."
    },
    {
        obec: "Drozdov",
        kod: 570141,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.907835,
        longitude: 16.78745,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Drozdov_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů střídavě modrých a žlutých, v poměru 5:1:1:1:1:1:5. Ve druhé a třetí šestině délky listu bílé čtvercové pole s drozdem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném návrší s drozdem přirozené barvy tři zlaté jehličnaté stromy, prostřední vyšší s kmenem přeloženým stříbrnou dvojruční pilou se zlatými rukojeťmi."
    },
    {
        obec: "Druhanov",
        kod: 568619,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.69888,
        longitude: 15.410323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Druhanov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1:6:1. V modrém pruhu mezi dvěma přivrácenými bílými křídli se žlutými jetelovitě zakončenými perizonii žlutý smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě, pod červenou hlavou se zkříženými stříbrnými sklářskými píšťalami se zlatými náustky, mezi dvěma přivrácenými stříbrnými křídly se zlatými jetelovitě zakončenými perizonii zlatý smrk."
    },
    {
        obec: "Drunče",
        kod: 560987,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.296949,
        longitude: 14.936562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Drun%C4%8De_CoA.png",
        coatOfArmsFlagDescription: "List dělený žlutým a bílým šikmým pruhem na modré žerďové a zelené vlající pole. Žlutý pruh vychází ze čtvrté čtvrtiny žerďového okraje do čtvrté čtvrtiny horního okraje listu, bílý pruh vychází z první čtvrtiny dolního okraje do první čtvrtiny vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně šikmo děleném štítě nahoře zlatá růže s červeným semeníkem a zelenými kališními lístky, dole zlatá koule postrkaná stříbrnými pery."
    },
    {
        obec: "Druztová",
        kod: 558834,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33007,
        latitude: 49.797516,
        longitude: 13.448829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Druztov%C3%A1_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. V zeleném pruhu bílá kvádrovaná věž s červenou valbovou střechou se dvěma žlutými makovicemi, prázdnou branou a oknem. Ve žlutém pruhu zelená vykořeněná lípa s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zeleném poli stříbrná kvádrovaná věž s červenou valbovou střechou se dvěma zlatými makovicemi, prázdnou branou a oknem. Levá polovina zlato-modře dělena, nahoře vykořeněná lípa přirozené barvy, dole stříbrná labuť se zlatou zbrojí."
    },
    {
        obec: "Družec",
        kod: 532274,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27362,
        latitude: 50.103086,
        longitude: 14.04551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Druzec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modro-bíle kosmo dělený a bílý se šikmým vlnitým modrým pruhem širokým desetinu šířky listu se třemi vrcholy a dvěma prohlubněmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrno-modře kosmo děleném štítě růže opačných barev se zelenými kališními lístky. V levém stříbrném poli modré zúžené vlnité břevno."
    },
    {
        obec: "Drysice",
        kod: 593010,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68321,
        latitude: 49.334642,
        longitude: 17.057846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Drysice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a červené pole se dvěma bílými vlajícími klíny s vrcholy dosahujícími k zelenému pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně dělený štít, nahoře radlice a vztyčený vinařský nůž (kosíř), dole dva kužely, vše stříbrné."
    },
    {
        obec: "Držkov",
        kod: 563561,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46824,
        latitude: 50.68764,
        longitude: 15.303285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Dr%C5%BEkov_CoA.png",
        coatOfArmsFlagDescription: "Červený list. V žerďové polovině šest bílých korálů navlečených pod sebou na bílé niti vycházející z horního a dolního okraje listu a korunovaný bílý dvouocasý lev se žlutou zbrojí a jazykem obrácený k vlajícímu okraji listu, držící v levé tlapě kosmo žlutý meč hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě doleva obrácený korunovaný stříbrný dvouocasý lev se zlatou zbrojí a jazykem držící kosmo vztyčený zlatý meč a provázený vpravo šesti stříbrnými korály navlečenými pod sebou na stříbrné niti vynikající z okrajů štítu."
    },
    {
        obec: "Držková",
        kod: 585203,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76319,
        latitude: 49.319242,
        longitude: 17.78562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Dr%C5%BEkov%C3%A1_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený v poměru 1:1:12:1:1. Uprostřed mezi žlutými parohy dvě zkřížené bílé valašky se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý vykračující jelen, na něm sedí zlatovlasý mladík v černých botách, červených kalhotech se zlatým pásem a stříbrné košili, držící se pravou rukou paroží a v levici třímající stříbrnou valašku na zlatém topůrku."
    },
    {
        obec: "Držovice",
        kod: 558419,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79607,
        latitude: 49.491208,
        longitude: 17.134108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Dr%C5%BEovice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu a modré pole. Na styku zkřížené vztyčené bílé otky na hnědých násadách. Mezi nimi nahoře a dole žlutá osmicípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře polceném štítě zkřížené vztyčené stříbrné otky na dřevěných násadách přirozené barvy, mezi nimi nahoře a dole zlatá osmihrotá hvězda."
    },
    {
        obec: "Dřenice",
        kod: 504301,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.982634,
        longitude: 15.745503,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/D%C5%99enice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s žerďovým zvlněným zeleným pruhem širokým čtvrtinu délky listu, se čtyřmi vrcholy a třemi prohlubněmi, v něm pět bílých šesticípých hvězd pod sebou. Ve střední části listu půl hnědého zubra ve skoku se žlutou zbrojí, houžví a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pravý zelený vlnitý bok s pěti stříbrnými hvězdami pod sebou. Z levého okraje štítu vyskakuje hnědý zubr se zlatou zbrojí, houžví v nozdrách a s červeným jazykem."
    },
    {
        obec: "Dřešín",
        kod: 551040,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.161244,
        longitude: 13.775775,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/D%C5%99e%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pilovitý bílo - červeně dělený pruh, široký pět dvanáctin délky listu a dva vodorovné pruhy, červený a bílý. Horní červený a dolní bílý pruh dosahují dvěma zuby do první čtvrtiny délky listu. V horním rohu zelený vztyčený lipový list, v dolním bílém pruhu tři (2, 1) svěšené puklé hnědé ořechy v zeleném oplodí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě pod pilovitou hlavou opačných barev, vpravo se vztyčeným zeleným lipovým listem, vlevo tři (2, 1) svěšené puklé ořechy přirozené barvy."
    },
    {
        obec: "Dřetovice",
        kod: 532282,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27342,
        latitude: 50.182789,
        longitude: 14.210368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Dretovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, modrý, žlutý a modrý, v poměru 5 : 1 : 1 : 1. Do bílého pruhu vyrůstá černá plamenná orlice s červenou zbrojí, se žlutým perizoniem a žlutým vinným hroznem na hrudi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře vyrůstající černá plamenná orlice s červenou zbrojí, zlatým perizoniem a zlatým vinným hroznem na hrudi, dole spodní polovina zlaté lilie."
    },
    {
        obec: "Dřevčice",
        kod: 538191,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25001,
        latitude: 50.167854,
        longitude: 14.628327,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Drevcice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a zubatý žlutý, v poměru 1:2:1. V červeném pruhu čtyři bílé vlající klíny s vrcholy na žerďovém okraji listu. V zeleném pruhu kosmo žlutá berla závitem k žerdi, šikmo podložená bílým mečem se žlutým jílcem hrotem k dolnímu okraji. Žlutý pruh má čtyři čtvercové zuby a tři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně cimbuřovitě vpravo polceném štítě pod červenou hlavou se čtyřmi stříbrnými kužely kosmá zlatá berla šikmo podložená postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Dřevěnice",
        kod: 573337,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50713,
        latitude: 50.45237,
        longitude: 15.447215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/CoA_of_D%C5%99ev%C4%9Bnice.svg",
        coatOfArmsFlagDescription: "List rozdělený bílým vydutým klínem vycházejícím z horního okraje listu s vrcholem na dolním okraji listu na červené žerďové a černé vlající pole. V bílém klínu zelená lípa s černým kmenem, provázená v žerďovém a vlajícím poli bílou osmicípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném vydutém klínu červeno - černě polceného štítu zelená lípa s černým kmenem, klín provázen dvěma stříbrnými osmihrotými hvězdami."
    },
    {
        obec: "Dřevnovice",
        kod: 589489,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79826,
        latitude: 49.328187,
        longitude: 17.143364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/D%C5%99evnovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed bílá věž s cimbuřím a prázdným půlkruhovým oknem, přes níž je položena žlutá dubová ostrev s jedním vztyčeným listem a jedním svěšeným žaludem uprostřed a čtyřmi suky po stranách proti sobě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrná věž s cimbuřím a prázdným půlkruhovým oknem, přes níž je položena zlatá dubová ostrev s jedním vztyčeným listem a jedním svěšeným žaludem uprostřed a čtyřmi suky po stranách proti sobě."
    },
    {
        obec: "Dřínov",
        kod: 532291,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27371,
        latitude: 50.275664,
        longitude: 14.069855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/D%C5%99%C3%ADnov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý klínový s vrcholem ve druhé pětině šířky listu a modrý, v poměru 1 : 4. Do bílého pruhu vyrůstá zelená rozdvojená dřínová větev se čtyřmi červenými plody (2, 2). V modrém pruhu meč hrotem k dolnímu okraji listu mezi dvěma odkloněnými obilnými klasy s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod klínem oddělenou stříbrnou hlavou s vyrůstající zelenou rozdvojenou dřínovou větví se čtyřmi červenými plody (2, 2) postavený meč mezi dvěma odkloněnými obilnými klasy s listem, vše zlaté."
    },
    {
        obec: "Dřínov",
        kod: 534773,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27745,
        latitude: 50.273754,
        longitude: 14.39761,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/D%C5%99%C3%ADnov_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným dřínem s červenými plody a hnědým kmenem vyrůstajícím ze zeleného vrchu vycházejícího z dolního okraje a sahajícího do třetí čtvrtiny šířky listu. V návrší bílý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném vrchu se stříbrným zvonem dřín přirozené barvy."
    },
    {
        obec: "Dřínov",
        kod: 588431,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.292989,
        longitude: 17.233486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/D%C5%99%C3%ADnov_%28Krom%C4%9B%C5%99%C3%AD%C5%BE_District%29_CoA.PNG",
        coatOfArmsFlagDescription: "Bílý list se zelenou snítkou dřínu s dvěma červenými plody dole provázenou odvrácenými račími klepety (obecní znak je převeden do listu praporu). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě větévka dřínu se dvěma listy a dvěma plody, provázená po obou stranách dvěma červenými račími klepety, vše přirozené barvy."
    },
    {
        obec: "Dřísy",
        kod: 534781,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.256089,
        longitude: 14.643653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/D%C5%99%C3%ADsy_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným vydutým klínem vycházejícím ze druhé až čtvrté pětiny horního okraje s vrcholem zakončeným vinným listem dotýkajícím se dolního okraje listu. V klínu knížecí čepice ve žlutém mezikruží. Nad vinným listem provázejí klín dva červené hrozny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vydutý klín zakončený vinným listem, provázený po stranách červeným vinným hroznem. V klínu nahoře knížecí čepice ve zlatém kruhu."
    },
    {
        obec: "Dřevohostice",
        kod: 513229,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.426043,
        longitude: 17.592153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/D%C5%99evohostice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:2:1. V modrém pruhu na dolním okraji zelený vodorovný pruh široký jednu desetinu šířky listu, na něm stojí bílá kvádrovaná věž s prázdnou branou se zdviženou žlutou mříží a červenou valbovou střechou se dvěma žlutými makovicemi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dříteč",
        kod: 574953,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53305,
        latitude: 50.105191,
        longitude: 15.808857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Dritec-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý, modrý a žlutý, v poměru 3:1:1:1. V žerďové části červeného pruhu kosmo klíč zuby nahoru a k hornímu okraji, šikmo podložený mečem hrotem k hornímu okraji, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě děleném štítě nahoře kosmo vztyčený klíč zuby doleva, šikmo podložený vztyčeným mečem, obojí zlaté, dole modré břevno."
    },
    {
        obec: "Dub",
        kod: 550183,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38425,
        latitude: 49.108339,
        longitude: 14.012153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Dub_PT_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílými buvolími rohy, každý postrkaný třemi dlaněmi. Uprostřed mezi rohy svěšený dubový list pod včelou, oboje žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý dub provázený nahoře dvěma zlatými včelami a dole dvěma stříbrnými buvolími rohy, každý postrkaný třemi dlaněmi přirozené barvy."
    },
    {
        obec: "Dříteň",
        kod: 544426,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37351,
        latitude: 49.142904,
        longitude: 14.346061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Driten_CZ_CoA.png",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. V modrém žerďovém poli polovina žlutého koně ve skoku s červeným uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě polovina zlatého koně ve skoku s červeným uzděním."
    },
    {
        obec: "Dub nad Moravou",
        kod: 501794,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78375,
        latitude: 49.482585,
        longitude: 17.27734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Dub_nad_Moravou_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu čtvrtinu délky listu se zeleným vztyčeným dubovým listem a zelené pole se dvěma žlutými liliemi vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou se zelenou vztyčenou dubovou snítkou o dvou listech a jednom žaludu dvě zlaté lilie."
    },
    {
        obec: "Dubá",
        kod: 561533,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47141,
        latitude: 50.540444,
        longitude: 14.540343,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Barevn%C3%BD_znak_Dub%C3%A9.jpg",
        coatOfArmsFlagDescription: "Šikmo bílo-zeleně dělený list, v žerďové části vztyčený dubový dvojlist se třemi žaludy. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dubany",
        kod: 572977,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.993164,
        longitude: 15.725091,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Dubany_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký čtvrtinu délky listu, a červené pole. V horní polovině zeleného pruhu svěšený žlutý žalud, v červeném poli kráčející bílý beránek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad zvýšeným zeleným návrším se svěšeným zlatým žaludem kráčející stříbrný beránek."
    },
    {
        obec: "Dubčany",
        kod: 552071,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.649318,
        longitude: 17.078926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Coat_of_arms_of_Dub%C4%8Dany.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Uprostřed vztyčený zelený žalud se žlutou číškou, provázený ve žlutém pruhu dvěma vztyčenými přivrácenými zelenými dubovými listy, v modrém pruhu žlutým půlměsícem cípy nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě vztyčený zelený žalud se zlatou číškou, provázený nahoře dvěma vztyčenými přivrácenými zelenými dubovými listy, dole stoupajícím zlatým půlměsícem."
    },
    {
        obec: "Dubenec",
        kod: 579190,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54455,
        latitude: 50.37772,
        longitude: 15.795177,
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký čtvrtinu délky listu, bílo-zeleně dělený v poměru 3 : 1, a dva vodorovné pruhy, zelený a bílý, ve stejném poměru. Na styku horních polí vztyčený dubový list opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě se zeleno-stříbrně polceným návrším vykořeněný dub, opačných barev."
    },
    {
        obec: "Dubenec",
        kod: 598381,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.695809,
        longitude: 14.07934,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Coats_of_arms_Dubenec.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed mezi dvěma vztyčenýma bílýma přivrácenýma býčíma nohama se žlutými paznehty vztyčený dubový dvoulist se žaludem převýšený korunou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě mezi dvěma vztyčenýma stříbrnýma přivrácenýma býčíma nohama se zlatými paznehty žlutý vztyčený dubový dvoulist se žaludem převýšený korunou, vše zlaté."
    },
    {
        obec: "Dubí",
        kod: 567507,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41701,
        latitude: 50.681139,
        longitude: 13.788947,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Dubi_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří černá žerďová a žlutá vlající část. Uprostřed dub opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě vykořeněný dub opačných barev."
    },
    {
        obec: "Dubicko",
        kod: 534927,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78972,
        latitude: 49.828159,
        longitude: 16.962769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Dubicko_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým pruhem na žerďovém okraji širokým jednu třetinu délky listu. Bílý pruh má šest zubů a pět mezer o výšce jedné desetiny šířky listu. V zeleném poli žlutá dubová snítka se dvěma listy a třemi žaludy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrné kvádrované hradby s cimbuřím vyrůstá zlatá dubová ratolest se dvěma listy a třemi žaludy."
    },
    {
        obec: "Dubičné",
        kod: 535788,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 48.981174,
        longitude: 14.538863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Dubicne_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se zeleným vlajícím pětiúhelníkovým polem na okraji listu dlouhým tři osminy délky listu a s vrcholem ve třetí osmině délky listu. V poli žlutý dubový trojlist listy k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě v zeleném zvýšeném návrší vztyčený zlatý dubový trojlist."
    },
    {
        obec: "Dublovice",
        kod: 540218,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26251,
        latitude: 49.671881,
        longitude: 14.360998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/CoA_of_Dublovice.svg",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou patou červená růže se zlatým semeníkem a se zelenými okvětními lístky."
    },
    {
        obec: "Dubňany",
        kod: 586161,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69603,
        latitude: 48.917038,
        longitude: 17.090148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Dub%C5%88any.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - zelený, bílý a zelený. Ve středu bílého pole je hnědá větévka s pěti žaludy z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dubné",
        kod: 544442,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.976295,
        longitude: 14.360478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Dubn%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s vlajícím modro-bíle vlnkovitě v poměru 2 : 1 děleným pruhem, širokým třetinu délky listu. Ve žlutém poli kosmo vztyčený zelený dubový list. Z bílého pole s černou lilií vyniká do modrého pole žlutá věž s černým gotickým oknem pod hodinami a černou valbovou střechou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít se stříbrnou vlnkovitou patou s černou lilií. V pravém poli kosmo vztyčený zelený dubový list, v levém poli zlatá věž s černým gotickým oknem pod hodinami a černou valbovou střechou."
    },
    {
        obec: "Dubno",
        kod: 564508,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.695958,
        longitude: 14.051851,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Coats_of_arms_Dubno.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a zelený, přeložené vodorovným žlutým pruhem vycházejícím z druhé čtvrtiny žerďového a vlajícího okraje listu. Z druhé a třetí čtvrtiny dolního okraje vychází bílý klín s vrcholem na dolním okraji žlutého pruhu. V klínu žalud mezi dvěma odkloněnými listy, ve středu žlutého pruhu tři lilie vedle sebe, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo - zeleně polcený štít se zlatým břevnem, pod ním stříbrný hrot. V břevně tři lilie vedle sebe, v hrotu vyrůstající vztyčený žalud mezi dvěma odkloněnými listy, vše zelené."
    },
    {
        obec: "Dubnice",
        kod: 561541,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47126,
        latitude: 50.726074,
        longitude: 14.808823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Dubnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a žluto - černě polcený, v poměru 5 : 1 : 2. V bílém pruhu zelený dubový trojlist se dvěma svěšenými červenými žaludy do stran. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zúženým vlnitým modrým břevnem sníženě dělený štít, v horním stříbrném poli zelený dubový trojlist se dvěma svěšenými červenými žaludy do stran, dolní pole zlato - černě polcené."
    },
    {
        obec: "Dubovice",
        kod: 551546,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.424582,
        longitude: 15.216681
    },
    {
        obec: "Dudín",
        kod: 587087,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.451107,
        longitude: 15.39771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Dud%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým dolním vlajícím trojúhelníkem sahajícím do třetí desetiny šířky listu. V zeleném poli bílá růže, v bílém poli šikmo vztyčený zelený bukový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo sníženě děleném štítě nahoře stříbrná růže, dole šikmo vztyčený zelený bukový list."
    },
    {
        obec: "Důl",
        kod: 529869,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.452191,
        longitude: 15.030185
    },
    {
        obec: "Dukovany",
        kod: 590576,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67556,
        latitude: 49.081352,
        longitude: 16.192279,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Dukovany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý v poměru 1:2:1. V bílém pruhu volný středový červený tlapatý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrném poli červený tlapatý kříž, levá polovina červeno-modře dělena, nahoře vztyčená stříbrná radlice ostřím doleva, dole vykořeněná lípa přirozené barvy."
    },
    {
        obec: "Duchcov",
        kod: 567515,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41901,
        latitude: 50.603871,
        longitude: 13.746322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/CoA_of_Duchcov.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě červených a černých; přes tři střední pruhy český lev. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dunice",
        kod: 532843,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.603247,
        longitude: 15.152746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Dunice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 4 : 1 : 4. V modrém pruhu žlutá dýně s bílou stopkou a úponky podložená zkříženými bílými gotickými klíči, v červeném vztyčený žlutý lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně zúženým stříbrným vlnitým břevnem dělený štít. Nahoře zlatá dýně se stříbrnou stopkou a úponky podložená zkříženými stříbrnými gotickými klíči, dole vztyčený zlatý lipový list."
    },
    {
        obec: "Dušejov",
        kod: 587095,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.408817,
        longitude: 15.432836,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Coat_of_arms_of_Du%C5%A1ejov.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, obloukový bílý s vrcholem sahajícím do třinácti dvacetin šířky listu a červený, v poměru 14 : 1 : 5. Z bílého pruhu vyrůstají tři žluté lípy. Z dolního okraje listu vyniká do červeného pruhu bílý latinský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně sníženým zúženým stříbrným obloukovým břevnem děleném štítě nahoře vyrůstající tři zlaté lípy, dole vynikající stříbrný latinský kříž."
    },
    {
        obec: "Dušníky",
        kod: 564818,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.424637,
        longitude: 14.190273,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Du%C5%A1n%C3%ADky_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Zelený list se dvěma do oblouku odkloněnými obilnými klasy, každý s jedním listem, mezi nimi berla závitem k žerdi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě berla provázená dvěma do oblouku odkloněnými obilnými klasy, každý s jedním listem do stran, vše zlaté."
    },
    {
        obec: "Dunajovice",
        kod: 562815,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.035149,
        longitude: 14.695348
    },
    {
        obec: "Dvorce",
        kod: 506729,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.375465,
        longitude: 15.490478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Dvorce_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 8 : 1 : 1. V horním pruhu vztyčený gotický dvojklíč provlečený dvěma zkříženými obilnými klasy, každý s odkloněným listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným vlnitým břevnem v patě vztyčený gotický dvojklíč provlečený dvěma zkříženými obilnými klasy, každý s odkloněným listem, vše zlaté."
    },
    {
        obec: "Dvakačovice",
        kod: 504955,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.979022,
        longitude: 15.894469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Dvaka%C4%8Dovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, bílo-modře dělený, červený a žlutý, v poměru 1 : 2 : 2 : 1. V bílém poli černá zubří hlava se žlutou houžví. Do modrého pole z červeného pruhu vyniká k žerdi dolní čtvrtina žlutého palečného kola. V širším červeném pruhu žlutý kalich nad třemi bílými vlčími zuby vynikajícími ze žlutého pruhu a dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, vpravo stříbrno-modře dělený, vlevo červený. V pravém horním poli černá zubří hlava se zlatou houžví, dole čtvrtina zleva vynikajícího zlatého palečného kola. Vlevo zlatý kalich nad třemi stříbrnými, z okraje štítu vyrůstajícími vlčími zuby."
    },
    {
        obec: "Dvorce",
        kod: 597317,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79368,
        latitude: 49.833416,
        longitude: 17.547725,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Dvorce_u_Brunt%C3%A1lu_CoA.png",
        coatOfArmsFlagDescription: "Červený list se dvěma žlutými šikmými pruhy vycházejícími z první a třetí čtvrtiny dolního okraje listu a končícími v první a třetí čtvrtině vlajícího okraje listu. V horním trojúhelníkovém poli žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Dvory",
        kod: 537144,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.039499,
        longitude: 13.946255
    },
    {
        obec: "Dvory",
        kod: 537110,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.21175,
        longitude: 14.995558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Coats_of_arms_Dvory.jpeg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým vlnitým pruhem širokým jednu pětinu šířky listu na žerďové modré a vlající zelené pole. V horním rohu žlutý lipový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně šikmým stříbrným vlnitým břevnem děleném štítě nahoře zlatý lipový trojlist."
    },
    {
        obec: "Dvory nad Lužnicí",
        kod: 509141,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37808,
        latitude: 48.852822,
        longitude: 14.900629
    },
    {
        obec: "Dvůr Králové nad Labem",
        kod: 579203,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.43182,
        longitude: 15.814127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/2019_coat_of_arms_of_Dv%C5%AFr_Kr%C3%A1lov%C3%A9_nad_Labem.png",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - zelený, bílý a zelený v poměru 2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná kvádrovaná hradba s cimbuřím o šesti stínkách prolomená branou s otevřenými vraty a vytaženou mříží, obojí zlaté. Z hradby vynikají dvě stříbrné kvádrované věže s cimbuřím o třech stínkách, s černým oknem a červenou valbovou střechou zakončenou dvěma zlatými makovicemi. Mezi věžemi na hradbě český lev."
    },
    {
        obec: "Dyjákovice",
        kod: 593974,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67126,
        latitude: 48.771975,
        longitude: 16.301068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Dyj%C3%A1kovice_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se dvěma černými zkříženými ostrvemi, provázenými dvěma odvrácenými červenými kapry hlavami k hornímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zkřížené černé ostrve, provázené dvěma odvrácenými červenými kapry hlavami k hornímu okraji."
    },
    {
        obec: "Dyjákovičky",
        kod: 593982,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.7824,
        longitude: 16.095728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Dyj%C3%A1kovi%C4%8Dky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a červený, v poměru 1 : 6 : 1. V zeleném pruhu žlutý vinný hrozen s listem provázený odvrácenými bílými vinařskými noži se žlutými rukojeťmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý kotel s červenými plameny, nad ním zlatý vinný hrozen s listem provázený odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi."
    },
    {
        obec: "Dyje",
        kod: 593991,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.847219,
        longitude: 16.1166
    },
    {
        obec: "Dymokury",
        kod: 537128,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28901,
        latitude: 50.245776,
        longitude: 15.202652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Coats_of_arms_Dymokury.jpeg",
        coatOfArmsFlagDescription: "Zelený list s bílým vlnitým klínem, vycházejícím z horního okraje listu, překrytý na dolním okraji listu černým návrším vysokým třetinu šířky listu. V klínu vztyčená červená svídová větev se třemi zelenými listy (1, 2). V návrší bílá růže s černým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vlnitý klín, v něm vztyčená červená svídová větev se třemi zelenými listy (1, 2) , dole černé návrší se stříbrnou růží s černým semeníkem."
    },
    {
        obec: "Dynín",
        kod: 544451,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37364,
        latitude: 49.137304,
        longitude: 14.631296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Coat_of_arms_of_Dyn%C3%ADn.svg",
        coatOfArmsFlagDescription: "List v žerďové půli od středu listu zeleno-žlutě štenýřově dělený s půlkou liliového kříže opačných barev. Ve vlající polovině listu pět vodorovných pruhů, střídavě bílých a modrých, v poměru 4 : 1 : 1 : 1 : 1. V horním bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, vpravo půl zlato-zeleného štenýře s půlkou liliového kříže opačných barev, v levém stříbrném poli nad dvěma sníženými modrými břevny červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Dýšina",
        kod: 558851,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33002,
        latitude: 49.777455,
        longitude: 13.491611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Znak_obce_D%C3%BD%C5%A1ina.gif",
        coatOfArmsFlagDescription: "Červený list se šikmým bílým pruhem širokým jednu osminu délky listu. V žerďovém poli bílá chrtice ve skoku se zdviženým ocasem a žlutým obojkem s kroužkem. Ve vlajícím poli bílý hamr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě šikmé stříbrné břevno provázené nahoře stříbrnou chrticí ve skoku se zdviženým ocasem a zlatým obojkem s kroužkem, dole stříbrným hamrem."
    },
    {
        obec: "Dyjice",
        kod: 587109,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.174727,
        longitude: 15.493972
    },
    {
        obec: "Dzbel",
        kod: 589497,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79853,
        latitude: 49.612993,
        longitude: 16.850985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Dzbel_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový a žlutý vlající svislý pruh, každý široký jednu šestinu délky listu. Mezi nimi žluto-modře šikmo dělené pole s půlí černého koně s červenou zbrojí a uzděním, vyskakujícího z modrého pole se žlutým liliovým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře šikmo děleném štítě vpravo vyskakující černý kůň s červenou zbrojí a uzděním, vlevo zlatý liliový kříž."
    },
    {
        obec: "Džbánov",
        kod: 580210,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.916456,
        longitude: 16.159605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/D%C5%BEb%C3%A1nov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý, v poměru 1 : 4 : 1. V červeném pruhu žlutý dvouuchý džbán přeložený přes dva menší bílé džbány vedle sebe, uchem k žerďovému a vlajícímu okraji listu. Pod džbány zkřížené bílé meče se žlutými jílci, hroty k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý dvouuchý džbán přeložený přes dva menší stříbrné džbány vedle sebe, uchy k okrajům štítu. Pod nimi zkřížené postavené stříbrné meče se zlatými jílci."
    },
    {
        obec: "Džbánice",
        kod: 594008,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67171,
        latitude: 49.001361,
        longitude: 16.211038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Coat_of_arms_of_D%C5%BEb%C3%A1nice.svg",
        coatOfArmsFlagDescription: "Červený list s červeným žerďovým pruhem širokým čtvrtinu délky listu, v pruhu čtyři bílé rovnoramenné trojúhelníky s vrcholy na žerďovém okraji listu a základnami širokými čtvrtinu šířky listu na vlajícím okraji pruhu. V červeném poli bílý dvouuchý džbán. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod hlavou se čtyřmi kužely vedle sebe dvouuchý džbán, vše stříbrné."
    },
    {
        obec: "Ejpovice",
        kod: 559792,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.74619,
        longitude: 13.514367,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Ejpovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu sedminu šířky listu, svislý bílý pruh široký jednu třetinu délky listu a tři vodorovné pruhy, červený, bílý a černý, v poměru 3:1:3. Ve svislém bílém pruhu červená věž s cimbuřím, černým oknem a branou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít s modrou hlavou, v pravém stříbrném poli červená věž s cimbuřím s černým oknem a branou, levé pole stříbrným břevnem červeno-černě dělené."
    },
    {
        obec: "Erpužice",
        kod: 560855,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.807058,
        longitude: 13.036075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Erpu%C5%BEice_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým křížem s rameny širokými osminu šířky listu na červená žerďová a modrá vlající pole. V horním červeném poli žlutá lilie, v dolním červeném poli uprostřed tři dotýkající se volná bílá obdélníková pole pod sebou (od žerdi 1, 2), ve vlající polovině listu vztyčený žlutý drak s červenou zbrojí a chrlící červené plameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře doprava posunutým stříbrným heroldským křížem dělený štít. Vpravo nahoře zlatá lilie. Dole tři dotýkající se stříbrné kvádry pod sebou (zprava 1, 2), vlevo vztyčený zlatý drak s červenou zbrojí a chrlící červené plameny."
    },
    {
        obec: "Eš",
        kod: 561754,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.434699,
        longitude: 15.001399
    },
    {
        obec: "Evaň",
        kod: 564834,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.384155,
        longitude: 14.028989
    },
    {
        obec: "Felbabka",
        kod: 531162,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.813487,
        longitude: 13.942011
    },
    {
        obec: "Frahelž",
        kod: 562637,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.117232,
        longitude: 14.732029,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Frahel%C5%BE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. V modrém pruhu dva bílé šestilisté bramborové květy se žluto-zelenými středy, v bílém pruhu modrá růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě nahoře dva šestilisté bramborové květy přirozené barvy, dole modrá růže se zlatým semeníkem."
    },
    {
        obec: "Francova Lhota",
        kod: 542644,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75614,
        latitude: 49.201841,
        longitude: 18.11186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/CZ_Francova_Lhota_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: žlutý, zelený a žlutý v poměru 1:7:1. V zeleném poli bílý šraňk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na zlatém pahorku stříbrný šraňk přeložený dvěma zlatými vztyčenými klasy."
    },
    {
        obec: "Františkov nad Ploučnicí",
        kod: 545899,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40723,
        latitude: 50.725809,
        longitude: 14.327018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Franti%C5%A1kov_nad_Plou%C4%8Dnic%C3%AD_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílo-černě polcené karé nad stejně velkým červeným čtvercovým polem s bílým lvem se žlutou zbrojí a jazykem a dva vodorovné pruhy, modrý a pruh dělený žlutým kosmým vlnitým pruhem vycházejícím z horní poloviny žerďového okraje a dolní poloviny vlajícího okraje pruhu na horní zelené a dolní modré pole. Do modrého pruhu vyniká bílá kvádrovaná věž s cimbuřím se čtyřmi zuby, ve žlutém pruhu kosmo černý tkalcovský člunek s červeným přadenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Vpravo dělený, nahoře stříbrno-černě polcený, dole v červeném poli stříbrný lev se zlatou zbrojí a jazykem. V levém modrém poli stříbrná kvádrovaná věž s cimbuřím se čtyřmi stínkami na zeleno-modře zlatým kosmým vlnitým břevnem děleném návrší. V břevnu kosmo černý tkalcovský člunek s červeným přadenem."
    },
    {
        obec: "FRÝDEK-MÍSTEK",
        kod: 598003,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73801,
        latitude: 49.682031,
        longitude: 18.367422,
        coatOfArmsFlagDescription: "List praporu opakuje městský znak v poměru 2:3.",
        coatOfArmsDescription: "Čtvrcený štít - v 1. a 4. poli pravá polovice zlaté orlice s červenou zbrojí přiléhající ke stříbrnému písmenu F. Ve 2. a 3. stříbrném poli zkřížené černé ostrve o šesti sucích provazující třemi (1,2) červenými růžemi."
    },
    {
        obec: "Františkovy Lázně",
        kod: 554529,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35101,
        latitude: 50.120159,
        longitude: 12.349781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Frnati%C5%A1kovy_L%C3%A1zn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a bílý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Frenštát pod Radhoštěm",
        kod: 599344,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74401,
        latitude: 49.548445,
        longitude: 18.21089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Fren%C5%A1t%C3%A1t_pod_Radho%C5%A1t%C4%9Bm_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Na červeném listu bílá postava sv. Martina z Tours se žlutou berlou v pravici, sedící na žlutém stolci a žehnající levicí. Na opěradle odvrácené bílé husy se žlutou zbrojí, hledící k biskupovi. Tři stupně pod stolcem jsou bílé (list praporu opakuje městský znak). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě sedí na zlatém stolci, postaveném na třech stříbrných stupních v patě štítku, sv. Martin Tourský ve stříbrném šatě s mitrou na hlavě, v pravé ruce drží zlatou berlu, levicí žehná. Na opěradlech stolce stojí dvě odvrácené stříbrné husy se zlatou zbrojí, hledící k biskupovi."
    },
    {
        obec: "Fryčovice",
        kod: 598135,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73945,
        latitude: 49.666867,
        longitude: 18.223315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Fry%C4%8Dovice_CoA.svg",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými vlajícími klíny o základnách rovných polovině šířky listu s vrcholy na žerďovém okraji; mezi nimi u žerdi žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dva stříbrné kužely, mezi nimi zlatá lilie."
    },
    {
        obec: "Frýdlant",
        kod: 564028,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.921496,
        longitude: 15.079851,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Fr%C3%BDdlant_CoA_CZ.svg"
    },
    {
        obec: "Frýdlant nad Ostravicí",
        kod: 598143,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73911,
        latitude: 49.591902,
        longitude: 18.358605,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 5:1:2. V žerďové části červeného pruhu stojící žlutý kovář, obrácený k vlajícímu okraji listu, s černými vlasy a černou zástěrou, držící ve zdvižené pravici modré kladivo se žlutou násadou a přidržující levicí žluté železo na žluté kovadlině se špalkem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném trávníku doleva obrácený zlatý kovář s černými vlasy a černou zástěrou, držící ve zdvižené pravici modré kladivo se zlatou násadou a přidržující levicí zlaté železo na zlaté kovadlině se špalkem. Vpravo vyniká zlatá kovářská patrová pec s modrou stříškou a černými výklenky, horní se zlatým plamenem."
    },
    {
        obec: "Frýdštejn",
        kod: 563579,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46342,
        latitude: 50.652357,
        longitude: 15.158867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Fr%C3%BDd%C5%A1tejn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 4:3:1. V žerďové polovině červeného pruhu bílý vykračující lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou hradbou z kyklopských kamenů s modrou gotickou branou a s cimbuřím vykračující stříbrný lev se zlatou zbrojí."
    },
    {
        obec: "Frymburk",
        kod: 545481,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38279,
        latitude: 48.661044,
        longitude: 14.165732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Frymburk_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, bílý a modrý. Uprostřed červená růže o průměru jedné poloviny šířky listu se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Frymburk",
        kod: 578525,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.249661,
        longitude: 13.707375,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, černý, zubatý žlutý s pěti čtvercovými zuby a čtyřmi stejnými mezerami, zelený a modrý, v poměru 2 : 4 : 3 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě s černou cimbuřovou hlavou a modrou patou stříbrná lilie v zeleném vrchu."
    },
    {
        obec: "Fryšava pod Žákovou horou",
        kod: 595578,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59204,
        latitude: 49.630512,
        longitude: 16.044434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Coat_of_arms_of_Fry%C5%A1ava_pod_%C5%BD%C3%A1kovou_horou.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu desetinu délky listu. V bílém poli vodorovný vlnitý pruh s pěti vrcholy a čtyřmi prohlubněmi široký jednu pětinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, zeleně lemovaném štítě, nad modrým vlnitým břevnem kosmo položený mlátek, šikmo podložený želízkem, oba přirozené barvy."
    },
    {
        obec: "Fryšták",
        kod: 585211,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76316,
        latitude: 49.285304,
        longitude: 17.683572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Frystak_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílá věž s červenou valbovou střechou se dvěma zlatými makovicemi, dvěma černými okny a otevřenou branou se žlutými vraty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s červenou střechou, dvěma zlatými makovicemi, dvěma černými okny a otevřenou branou se zlatými veřejemi."
    },
    {
        obec: "Grešlové Mýto",
        kod: 594016,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67156,
        latitude: 48.980854,
        longitude: 15.886269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Gre%C5%A1lov%C3%A9_M%C3%BDto_-_znak.jpg",
        coatOfArmsFlagDescription: "Modro-červeně čtvrcený list, uprostřed žlutá poštovní trubka obtočená bílou šňůrou se dvěma třapci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře čtvrceném štítě stříbrný kvádrovaný most, nad ním vpravo zlatá padací mříž, vlevo korunovaný zlatý dvouocasý lev s červenou zbrojí, pod obloukem zlatá poštovní trubka obtočená stříbrnou šňůrou se dvěma třapci."
    },
    {
        obec: "Grunta",
        kod: 599450,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.974214,
        longitude: 15.255089
    },
    {
        obec: "Fulnek",
        kod: 599352,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74245,
        latitude: 49.712474,
        longitude: 17.903301,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/CoA_of_Fulnek.svg",
        coatOfArmsFlagDescription: "List praporu opakuje městský znak. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře čtvrcený štít, v prvním poli stříbrná jednopatrová hradební věž s prolomenou branou, dvěma okny a cimbuřím, ve druhém a třetím poli šikmé zúžené břevno provázené nahoře dvěma, dole jednou lilií, vše zlaté, ve čtvrtém poli stříbrná zavinutá střela. Do středu štítu položeno dvojité majuskulní W pod korunou s pěti viditelnými listy, vše zlaté."
    },
    {
        obec: "Grygov",
        kod: 501841,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78373,
        latitude: 49.537953,
        longitude: 17.310944,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Grygov_CoA_CZ.png",
        coatOfArmsFlagDescription: "Zelený list s bílým svislým žerďovým pruhem o šířce jedné třetiny délky listu. V zeleném poli žlutá koňská hlava s krkem, červenou ohlávkou a uzdou. V bílém pruhu květ koniklece přirozených barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou se dvěma květy koniklece přirozených barev zlatá koňská hlava s krkem červenou ohlávkou a uzdou."
    },
    {
        obec: "Golčův Jeníkov",
        kod: 568635,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.816361,
        longitude: 15.476967,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Golcuv_jenikov-znak.svg"
    },
    {
        obec: "Grymov",
        kod: 569194,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75121,
        latitude: 49.481108,
        longitude: 17.498114,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Coat_of_arms_of_Grymov.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, oboustranně zubatý posunutý žlutý a zubatý modrý se šesti zuby a pěti stejnými mezerami, v poměru 6 : 2 : 3. V horním pruhu žluté mlýnské kolo mezi dvěma květy třezalky tečkované. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté cimbuřové břevno, nahoře dva květy třezalky tečkované přirozené barvy, dole zlaté mlýnské kolo."
    },
    {
        obec: "Habartice",
        kod: 564036,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46373,
        latitude: 51.019134,
        longitude: 15.065266,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Habartice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Uprostřed polcené vozové kolo, ve žlutém poli červené, v modrém žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě zlato-červeně polcené vozové kolo."
    },
    {
        obec: "Gruna",
        kod: 574325,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.762991,
        longitude: 16.742872,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Gruna_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Uprostřed kosmá černá ostrev, ze které vyrůstá do červeného pruhu žlutý vinný list a do žlutého pruhu vztyčený červený vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě kosmá černá ostrev, z níž vpravo dole vyrůstá zlatý vinný list a vlevo nahoře vztyčený červený vinný hrozen."
    },
    {
        obec: "Habartov",
        kod: 560359,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35709,
        latitude: 50.183078,
        longitude: 12.550653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Habartov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří černý žerďový pruh široký jednu třetinu délky listu a zelené pole. V žerďové a střední části listu hornický kahan provázený dvěma lipovými větévkami se třemi listy a trojicí plodů, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo - zeleně polceném štítě hornický kahan provázený dvěma lipovými větévkami se třemi listy a trojicí plodů, vše zlaté."
    },
    {
        obec: "Habrovany",
        kod: 593028,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.232934,
        longitude: 16.878393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Habrovany_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "V modrém listě v žerďové polovině listu žlutá habrová ratolest se třemi listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě habrová ratolest o třech listech a jednom květu, provázená vlevo nahoře jetelovým křížem, vše zlaté."
    },
    {
        obec: "Habrovany",
        kod: 567957,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.598676,
        longitude: 13.981796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Habrovany_UL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh, široký jednu třetinu délky listu, se zeleným trojlístkem v horním rohu, a modré pole s bílým vlnitým pruhem, širokým jednu čtvrtinu šířky listu, s jedním vrcholem a jednou prohlubní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo dva zelené trojlístky pod sebou, vlevo stříbrné vlnité břevno."
    },
    {
        obec: "Habrůvka",
        kod: 581569,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67905,
        latitude: 49.3036,
        longitude: 16.72362,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Habruvka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří osm vodorovných pruhů - bílý, zelený, bílý, zelený, bílý, zelený, bílý a modrý v poměru 1:1:1:1:1:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se stříbrným hrotem. V něm tři zelené listnaté stromy - habry (1,2). Hrot je z obou stran provázen přivrácenými stříbrnými sekyrami."
    },
    {
        obec: "Habry",
        kod: 568651,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58281,
        latitude: 49.756128,
        longitude: 15.484963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Znak_m%C4%9Bsta_Habry.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1:3:1. V žerďové části červeného pruhu kosmo bílý klíč zuby nahoru a k žerďovému okraji, přeložený šikmo bílým mečem se žlutým jílcem hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Habří",
        kod: 535575,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.948248,
        longitude: 14.334947
    },
    {
        obec: "Habřina",
        kod: 569992,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.326035,
        longitude: 15.826971,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Hab%C5%99ina_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a bílý, v poměru 4 : 1 : 3. Z černého pruhu vyrůstá k hornímu okraji černé plamenné křídlo s bílým perizoniem, k dolnímu okraji vyrůstají tři odkloněné zelené habrové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě z černého zúženého břevna nahoru vyrůstá černé plamenné křídlo se stříbrným perizoniem a dolů vyrůstají tři odkloněné zelené habrové listy."
    },
    {
        obec: "Hačky",
        kod: 549967,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79855,
        latitude: 49.622521,
        longitude: 16.937715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Coat_of_arms_of_Ha%C4%8Dky.svg",
        coatOfArmsFlagDescription: "Zelený list se třemi žlutými hřeby - podkováky hroty nahoru, prostřední delší k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři zlaté vztyčené hřeby - podkováky, prostřední dolů delší."
    },
    {
        obec: "Hadravova Rosička",
        kod: 562696,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.250953,
        longitude: 15.047507
    },
    {
        obec: "Háj u Duchcova",
        kod: 567523,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41722,
        latitude: 50.630921,
        longitude: 13.712691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/H%C3%A1j_u_Duchcova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3:1. V žerďové polovině listu kokrhající červený kohout se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší kokrhající červený kohout se zlatou zbrojí."
    },
    {
        obec: "Háj ve Slezsku",
        kod: 506753,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74792,
        latitude: 49.899842,
        longitude: 18.089995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Haj_ve_Slezsku_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 4:1. Z bílého pruhu vyrůstá háj tvořený pěti žlutými listnatými stromy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném návrší zlatý háj tvořený pěti listnatými stromy."
    },
    {
        obec: "Hajany",
        kod: 582999,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66443,
        latitude: 49.110916,
        longitude: 16.55509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Znak_Hajany.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. Z dolní poloviny žluté lilie v červeném pruhu vyrůstá do modrého pruhu bílý lev s ocasem se třemi lipovými listy a s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře vyrůstající stříbrný lev s ocasem se třemi lipovými listy a s červenou zbrojí. Dole z dělící linie vyrůstá spodní polovina zlaté lilie."
    },
    {
        obec: "Háje",
        kod: 598402,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.672735,
        longitude: 14.047452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/H%C3%A1je_P%C5%98_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a žlutý, v poměru 1 : 2 : 1. V zeleném pruhu žlutá kančí hlava s bílou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vykořeněný dub se zlatými žaludy a zlatá kančí hlava se stříbrnou zbrojí a červeným jazykem."
    },
    {
        obec: "Hájek",
        kod: 561690,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.11119,
        longitude: 14.061257
    },
    {
        obec: "Hajany",
        kod: 551597,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.446654,
        longitude: 13.835973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Hajany_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a zelený, v poměru 2 : 1 : 1. V modrém pruhu čáp bílý, ve žlutém černá kančí hlava s bílou zbrojí a červeným jazykem a v zeleném žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít se zvýšeným vydutým modrým klínem, v něm čáp přirozené barvy, v pravém poli černá kančí hlava se stříbrnou zbrojí a červeným jazykem, v levém poli zlatá lilie."
    },
    {
        obec: "Háje nad Jizerou",
        kod: 577073,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.613238,
        longitude: 15.421312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/H%C3%A1je_nad_Jizerou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, modrý, bílý a modrý, v poměru 3 : 7 : 1 : 1. Ve žlutém pruhu pod sebou tři modré květy lnu se zelenými středy, ve středním modrém pruhu žlutá lví tlapa se čtyřmi červenými drápy, kosmo podložená bílým mečem se žlutým jílcem, hrotem k dolnímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře vpravo polcený štít. Vpravo tři modré květy lnu se zelenými středy pod sebou, vlevo nad stříbrným vlnitým břevnem zlatá lví tlapa se čtyřmi červenými drápy podložená kosmo postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Hájek",
        kod: 538159,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.283247,
        longitude: 12.919811,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Hajek_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý, v poměru 5 : 3. V zeleném žlutá věž s cimbuřím se třemi stínkami, bosovanou branou a se dvěma okny vedle sebe, vše černé, z ní do stran vyrůstají dvojice bílých odkloněných lipových listů na prohnutých stoncích. V modrém pruhu tři žluté šesticípé hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře sníženě děleném štítě nahoře zlatá věž s cimbuřím se třemi stínkami, s bosovanou branou a se dvěma okny, vše černé, z ní do stran vyrůstají dvojice stříbrných odkloněných lipových listů na prohnutých stoncích, dole kosmá stříbrná štika provázená pěti (2, 3) kosmo postavenými zlatými hvězdami."
    },
    {
        obec: "Hajnice",
        kod: 579211,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54466,
        latitude: 50.487956,
        longitude: 15.908701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Hajnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Světle modrý list se zeleným trojvrším, vycházejícím z dolního okraje a ze čtvrté a páté pětiny žerďového a vlajícího okraje listu. Z prostředního nižšího vrchu vyrůstá bílá věž s dřevěným podbitím a špičatou střechou, oboje hnědé, provázená na krajních vrších zelenými smrky s hnědými kmeny. V prostředním vrchu bílý květ lnu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zeleného trojvrší s prostředním nižším vrchem stříbrná věž s dřevěným podbitím a špičatou střechou přirozené barvy, provázená dvěma smrky přirozené barvy. V trojvrší stříbrný květ lnu."
    },
    {
        obec: "Halámky",
        kod: 562807,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37806,
        latitude: 48.85324,
        longitude: 14.915223,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Hal%C3%A1mky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a čtyři vodorovné pruhy, bílý, červený, žlutý a zelený. V modrém pruhu jelen vyrůstající z koruny, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. Vpravo jelen vyrůstající z koruny, obojí zlaté. Vlevo ve zvýšeném zeleném a nahoře pěti červenými plameny hořícím vrchu zlatá sekera."
    },
    {
        obec: "Halenkov",
        kod: 542679,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75603,
        latitude: 49.317501,
        longitude: 18.1476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/CZ_Halenkov_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý šikmo oboustranně pilovitý a zelený, v poměru 7:1:2. V žerďové polovině horního pruhu žlutý javorový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vztyčený javorový list, pod ním šikmo oboustranně trojnásobně stupňovité břevno, vše zlaté."
    },
    {
        obec: "Haluzice",
        kod: 585238,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76324,
        latitude: 49.137973,
        longitude: 17.897406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/CoA_of_Haluzice.svg",
        coatOfArmsFlagDescription: "List tvoři pět vodorovných pruhů střídavě zelených a žlutých, v poměru 1 : 1 : 4 : 1 : 1. Ve středním pruhu žlutý zvon od žerdi protknutý bílou vlčí udicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý zvon zprava protknutý stříbrnou vlčí udicí a převýšený dvěma vztyčenými zlatými listy jírovce."
    },
    {
        obec: "Halže",
        kod: 560863,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.831133,
        longitude: 12.579508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/CoA_of_Hal%C5%BEe.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín sahající do dvou třetin délky listu k červenému svislému pruhu ve vlající části. Trojúhelníková pole nad a pod klínem jsou zelená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě s červenou patou stříbrný klín, v něm modrý knoflík se čtyřmi (1,2,1) otvory."
    },
    {
        obec: "Hamr",
        kod: 562386,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37806,
        latitude: 48.949506,
        longitude: 14.913529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Hamr_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený a střídavě bílé a modré, v poměru 10 : 1 : 1 : 1 : 1 : 1 : 1. V červeném pruhu bílý chvostový hamr s polovinou palečného kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlnitě dělený štít. V horním červeném poli stříbrný chvostový hamr s polovinou palečného kola. Dolní pole pětkrát stříbrno-modře vlnitě dělené."
    },
    {
        obec: "Hamr na Jezeře",
        kod: 544337,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47128,
        latitude: 50.702975,
        longitude: 14.838293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Hamr_na_Jezere_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý oboustranně zubatý a žluto-černě polcený, v poměru 1:2:1. Modrý má nahoře i dole po čtyřech zubech a pěti mezerách, vysokých jednu osminu šířky listu. V modrém pruhu žlutý kráčející lev s červenou zbrojí držící v předních tlapách bílou rybu hlavou nahoru a hřbetem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se zlatou cimbuřovou hlavou zlatý kráčející lev s červenou zbrojí držící v předních tlapách stříbrnou rybu, pod ním zlato-černě polcený štítek."
    },
    {
        obec: "Hamry",
        kod: 571377,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.739067,
        longitude: 15.926515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Hamry_%28Chrudim_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zvlněný bílý, v poměru 1 : 2. V modrém pruhu dvě zkřížená bílá kovářská kladiva, v bílém vykořeněný zelený smrk s hnědým kmenem přeloženým modrým oblakem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou vlnitou patou vykořeněný smrk přirozené barvy s kmenem přeloženým modrým oblakem. V patě zkřížená stříbrná kovářská kladiva."
    },
    {
        obec: "Halenkovice",
        kod: 585220,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76363,
        latitude: 49.171122,
        longitude: 17.471664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/CoA_of_Halenkovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 10:3:2. V žerďové polovině horního modrého pruhu tři (2,1) žluté lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři zlaté od středu odkloněné liliové hůlky, pod nimi položena zlatá radlice."
    },
    {
        obec: "Hamry",
        kod: 578240,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34022,
        latitude: 49.215418,
        longitude: 13.160547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Hamry_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý zelený žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, bílý a modrý. V zeleném pruhu žlutý korunovaný lev s červenou zbrojí a jazykem držící bílé rychtářské právo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně děleném štítě nahoře stříbrné palečné kolo se čtyřmi palci, dole zlatý korunovaný lev s červenou zbrojí a jazykem držící stříbrné rychtářské právo."
    },
    {
        obec: "Hamry nad Sázavou",
        kod: 595586,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.566124,
        longitude: 15.901791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Hamry_nad_S%C3%A1zavou_znak.gif",
        coatOfArmsFlagDescription: "Žlutý list se šikmo položenou palicí křížem přeloženou hornickým želízkem, oba nástroje černé s hnědými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě šikmo položená kamenická palice křížem přeložená hornickým želízkem, oba nástroje černé s hnědými topůrky."
    },
    {
        obec: "Hanušovice",
        kod: 535532,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78833,
        latitude: 50.080583,
        longitude: 16.936514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Coat_of_arms_of_Hanu%C5%A1ovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 1 : 2. Ve žlutém pruhu modrý květ lnu se zeleným středem mezi dvěma zelenými jehličnatými stromy s hnědými kmeny, vyrůstajícími z modrého pruhu. V modrém pruhu bílé ozubené kolo mezi dvěma dole zkříženými žlutými obilnými klasy s listem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Harrachov",
        kod: 577081,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51246,
        latitude: 50.772186,
        longitude: 15.431521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Harrachov_znak_mesta.svg"
    },
    {
        obec: "Hartinkov",
        kod: 572284,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.679202,
        longitude: 16.814035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Hartinkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý s bílou šesticípou hvězdou, žlutý a modrý s bílou radlicí hrotem k žerďovému a ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno provázené nahoře hvězdou a dole doleva položenou radlicí, obojí stříbrné."
    },
    {
        obec: "Hartmanice",
        kod: 536075,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37365,
        latitude: 49.208049,
        longitude: 14.565216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Hartmanice_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý se žlutou berlou závitem k vlajícímu okraji a s bílým velem a žlutý se zeleným obilným klasem s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě vpravo zlatá berla se stříbrným velem, závitem doleva, vlevo zelený obilný klas."
    },
    {
        obec: "Hartmanice",
        kod: 578037,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56992,
        latitude: 49.625328,
        longitude: 16.373032,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Hartmanice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a modrý, v poměru 1:3. Přes pruhy lípa provázená kolem kmene pěti šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě s černou hlavou lípa provázaná dole pěti hvězdami do oblouku, vše zlaté."
    },
    {
        obec: "Hartmanice",
        kod: 556181,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.169587,
        longitude: 13.454657,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Hartmanice_-_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílá kvádrovaná věž se dvěma římsami oddělenými patry, se třemi (1, 2) černými okny a červenou stanovou střechou. V prázdné bráně s vytaženou bílou mříží žlutá bečka (solná prostice). Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hartvíkovice",
        kod: 590584,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67576,
        latitude: 49.171199,
        longitude: 16.090438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/CZ_Hartv%C3%ADkovice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy bílý, zvlněný modrý se třemi vrcholy a čtyřmi prohlubněmi a žlutý, v poměru 15:3:2. V bílém pruhu na červeném položeném parohu černý stojící kos se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s vlnitou modrou patou na položeném červeném parohu stojící černý kos se zlatou zbrojí. V patě zlatý patriarší kříž přeložený položeným stříbrným šípem se zlatým hrotem a opeřením."
    },
    {
        obec: "HAVÍŘOV",
        kod: 555088,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73601,
        latitude: 49.780492,
        longitude: 18.430725,
        coatOfArmsFlagDescription: "List praporu opakuje městský znak. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hať",
        kod: 568210,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74716,
        latitude: 49.94654,
        longitude: 18.23942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Ha%C5%A5_OP_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část s bílým zvlněným hadem se žlutou korunou a jazykem. Zbývající dvoutřetinové pole je modré s bílou propletenou kosmou a šikmou mříží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře dělený štít, nahoře poloviční plazící se stříbrný korunovaný had, dolní pole kosmo stříbrně mřížované."
    },
    {
        obec: "Haškovcova Lhota",
        kod: 563625,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.332597,
        longitude: 14.466726
    },
    {
        obec: "Hatín",
        kod: 546291,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.108751,
        longitude: 14.911134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Hat%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, černý, bílý a modrý, v poměru 10 : 1 : 2 : 1 : 10. V zeleném pruhu dvě přivrácené bílé koňské hlavy s krky se žlutými hřívami, v modrém pruhu žlutá růže s červeným semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře černým, stříbrně lemovaným vlnitým břevnem dělený štít. Nahoře dvě přivrácené stříbrné koňské hlavy s krky se zlatými hřívami, dole zlatá růže s červeným semeníkem."
    },
    {
        obec: "Havlíčkova Borová",
        kod: 568660,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58223,
        latitude: 49.635883,
        longitude: 15.781671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Havlickova_Borova_-_znak.JPG",
        coatOfArmsFlagDescription: "Červeno - bíle šikmo dělený list. V červeném poli bílý korunovaný lev se žlutou zbrojí a červeným jazykem držící v tlapách bílou sekeru na černém topůrku. V bílém poli dvě zelené vztyčené borové šišky vedle sebe. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Havlíčkův Brod",
        kod: 568414,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.604436,
        longitude: 15.579755,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Havl%C3%AD%C4%8Dk%C5%AFv_Brod_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý nad červeným. V horním rohu červená kvádrová hradba s otevřenou branou se žlutými vraty mezi dvěma věžemi. Nad branou žlutý štítek s černými zkříženými ostrvemi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Havlovice",
        kod: 579220,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54232,
        latitude: 50.487625,
        longitude: 16.029428,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Havlovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý se žlutým třmenem a bílý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s cimbuřím a prolomenou branou s červeným ostěním a vytaženou zlatou mříží. Vpravo z hradby vyrůstá stříbrná věž s cimbuřím a černým gotickým oknem s červeným ostěním, provázená vlevo zlatým třmenem."
    },
    {
        obec: "Havraň",
        kod: 567141,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43501,
        latitude: 50.450149,
        longitude: 13.598881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Havra%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílo-žlutě polcený, modrý a zelený, v poměru 4:3:1. V bílém poli býčí hlava s červeným jazykem, ve žlutém poli jelení hlava, obojí černé a čelně hledící. Ze zeleného pruhu vyniká do modrého bílý kostel s věží k vlajícímu okraji. Věž má okno, červenou stanovou střechu s makovicí a patriarším křížem, obojí žluté. Loď má dvě okna, snížené kněžiště a sedlovou červenou střechu. Okna jsou černá a oblouková. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělený, nahoře stříbrno-zlatě polcený, vpravo býčí hlava s červeným jazykem, vlevo jelení hlava, obojí černé a čelně hledící. Dole v modrém poli na zeleném návrší stříbrný kostel vlevo s věží, která má červenou stanovou střechu s makovicí a patriarším křížem, obojí zlaté, a okno. Loď se dvěma okny a sníženým kněžištěm má sedlovou červenou střechu. Okna jsou černá a oblouková."
    },
    {
        obec: "Havraníky",
        kod: 594024,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.811525,
        longitude: 16.008488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Havran%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným pruhem na dolním okraji širokým osminu šířky listu. Uprostřed latinský kotvicový kříž zakončený dole šesticípou hvězdou, obojí červené, nahoře provázený černými přivrácenými havrany a dole červeným vinným hroznem, svěšeným k vlajícímu okraji, na zeleném stonku s listem a úponkem vyrůstajícím ze zeleného pruhu a červeným vinařským nožem - kosířem se zelenou rukojetí ostřím k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený latinský kotvicový kříž zakončený dole hvězdou postavenou spodním hrotem na zelené patě a provázený nahoře dvěma přivrácenými černými havrany. Vpravo na vyrůstajícím zeleném prohnutém stonku s listem a úponkem červený vinný hrozen, svěšený do leva, vlevo šikmo postavený červený vinařský nůž - kosíř se zelenou rukojetí."
    },
    {
        obec: "Hazlov",
        kod: 554545,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35132,
        latitude: 50.156457,
        longitude: 12.272617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/HazlovCoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený, a vydutý klín opačných barev vycházející z dolního okraje listu s vrcholem na horním okraji listu. V bílém žerďovém pruhu červený lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě polcený vydutý hrot opačných barev, provázený vpravo červeným lvem se zlatou zbrojí."
    },
    {
        obec: "Hejná",
        kod: 541796,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.28948,
        longitude: 13.6748,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Hejn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "V červené žerďové části tři bílé šikmo položené ostrve nad sebou, každá o třech sucích, v zelené vlající části bílé zkřížené špičáky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít, vpravo tři šikmo položené ostrve nad sebou, každá o třech sucích, vlevo zkřížené špičáky, vše stříbrné."
    },
    {
        obec: "Hejnice",
        kod: 580228,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.055981,
        longitude: 16.399519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Hejnice_UO_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý a střídavě bílé a zelené, v poměru 12 : 1 :1: 1 : 1 . Na horním bílém pruhu vzpínající se bílý kůň. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vzpínající se stříbrný kůň na třikrát stříbrno-zeleně dělené patě."
    },
    {
        obec: "Hejnice",
        kod: 564044,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46362,
        latitude: 50.877301,
        longitude: 15.181785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Hejnice_CoA_LB_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh, široký jednu třetinu délky listu, červeno-zeleně dělený, v poměru 5:3, a tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 3:2:3. V červeném poli na horním okraji zeleného pole stojí bílé průčelí chrámu mezi dvěma věžemi. Každá věž má vchod, dvě okna nad sebou, lucernu a báň s makovicí a patriarším křížem. Průčelí s oknem nad vchodem vrcholí kopulí s tamburem a bání s makovicí a patriarším křížem. Všechny báně jsou cibulovité, všechny vchody a okna půlkruhové a černé, ke vchodům vedou schody. Z dolního okraje zeleného pole vyrůstají tři odkloněné žluté obilné klasy, prostřední se dvěma, krajní s jedním listem. Uprostřed listu v modrých pruzích a pod žlutým pruhem s černým ozubeným kolem, bílý vykořeněný smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole červeno-zeleně sníženě dělené. Nahoře vyniká stříbrné průčelí chrámu mezi dvěma věžemi. Každá věž má vchod, dvě okna nad sebou, lucernu a báň s makovicí a patriarším křížem. Všechny báně jsou cibulovité, všechny vchody a okna půlkruhové a černé, ke vchodům vedou schody. Dole vyrůstají tři odkloněné zlaté obilné klasy, prostřední se dvěma, krajní s jedním listem. V levém modrém poli stříbrný vykořeněný smrk přeložený zlatým břevnem s černým ozubeným kolem."
    },
    {
        obec: "Hejtmánkovice",
        kod: 574031,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55001,
        latitude: 50.599352,
        longitude: 16.299544,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Hejtm%C3%A1nkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký jednu čtvrtinu délky listu a modré pole. V bílém pruhu pod sebou tři červené růže se žlutými semeníky a zelenými kališními lístky. V modrém poli otevřená bílá burgundská přilba se žlutým zdobením a červeným vyložením, podložená dvěma, šikmo a kosmo vztyčenými žlutými cepy s odvrácenými vodorovnými tlouky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky a zelenými kališními lístky otevřená stříbrná burgundská přilba se zlatým zdobením a červeným vyložením, podložená dvěma šikmo a kosmo vztyčenými zlatými cepy s položenými odvrácenými tlouky."
    },
    {
        obec: "Helvíkovice",
        kod: 548049,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.092319,
        longitude: 16.43105,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Helv%C3%ADkovice.svg",
        coatOfArmsFlagDescription: "Zelený list se zkříženými žlutými hráběmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlaté zkřížené hrábě."
    },
    {
        obec: "Herálec",
        kod: 568678,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58255,
        latitude: 49.530935,
        longitude: 15.457184,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Heralec_HB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, černo-žlutě dělený a modrý, v poměru 1:2:1. Uprostřed listu bílo-červeně dělená věž, dole kvádrovaná, s cimbuřím, s branou a obloukovým oknem, obojí černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém černo-zlatě děleném poli stříbrno-červeně dělená věž, dole kvádrovaná, s cimbuřím, s branou a obloukovým oknem, obojí černé. V levém modrém poli zlatý gotický dvojklíč."
    },
    {
        obec: "Herálec",
        kod: 595594,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59201,
        latitude: 49.689015,
        longitude: 15.994419,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Her%C3%A1lec_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, v něm nad volným mostem se třemi oblouky jedle se zásekem v kmeni a dřevorubec držící kosmo sekeru-širočinu, vše bílé a nahoře provázené uprostřed lilií a po stranách dvěma šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad volným mostem se třemi oblouky vpravo jedle se zásekem v kmeni a vlevo dřevorubec držící kosmo sekeru-širočinu, vše stříbrné. Nahoře uprostřed lilie provázená dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Heraltice",
        kod: 590592,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.230933,
        longitude: 15.730648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Heratlice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý červený. Zubatý pruh má pět obdélníkových zubů, šest stejných mezer a dva poloviční zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře trojí stříbrné cimbuří, první a třetí řada s třemi stínkami, prostřední se čtyřmi, krajní vynikající. Dole ze zeleného návrší v patě štítu tryskají do stran dva modré vodní prameny."
    },
    {
        obec: "Herink",
        kod: 564915,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.966961,
        longitude: 14.575012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Coats_of_arms_Herink.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 11 : 2 : 11. V modrém pruhu tři slanečci - herinci nad sebou, v červeném pruhu volná půle lilie blíže k bílému pruhu, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně zúženým stříbrným kůlem polceném štítě vpravo tři stříbrní slanečci - herinci nad sebou, vlevo volná půle stříbrné lilie."
    },
    {
        obec: "Heroltice",
        kod: 583014,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.311457,
        longitude: 16.412898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Heroltice_znak.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem, širokým tři čtvrtiny šířky listu, na zelené žerďové a modré vlající pole. V bílém pruhu červená račí klepeta. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře šikmým širokým stříbrným břevnem dělený štít, v něm červená račí klepeta."
    },
    {
        obec: "Heršpice",
        kod: 550213,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.119869,
        longitude: 16.913668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Coa_Czech_Town_Her%C5%A1pice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý s modrým vinným hroznem na zeleném stonku s listem a červený, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný kůl s modrým vinným hroznem a zeleným listem, provázený vpravo vztyčenou stříbrnou radlicí, vlevo stříbrným vinařským nožem."
    },
    {
        obec: "Heřmaň",
        kod: 598593,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.91146,
        longitude: 14.503761
    },
    {
        obec: "Heřmaň",
        kod: 549398,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39811,
        latitude: 49.232575,
        longitude: 14.13555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/He%C5%99ma%C5%88_%28P%C3%ADsek_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se šikmým bílým vlnitým pruhem širokým dvě sedminy šířky listu. V žerďovém poli kosmo mlátek šikmo přeložený želízkem, obojí žluté. Ve vlajícím poli šikmo bílý šíp hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě šikmé stříbrné vlnité břevno, provázené nahoře kosmým mlátkem šikmo přeloženým želízkem, obojí zlaté, a dole šikmo vztyčeným stříbrným šípem."
    },
    {
        obec: "Heřmanice",
        kod: 548596,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.82401,
        longitude: 15.54733
    },
    {
        obec: "Heřmaneč",
        kod: 546305,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.129602,
        longitude: 15.28546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Hermanec-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu a modré pole se třemi pilovitými zuby k žerďovému okraji listu, s hroty do šestiny délky listu. U žerďového pruhu tři bílé lilie pod sebou, ve vlající polovině listu žlutá radlice ostřím nahoru probodnutá bílým krojidlem ostřím dolů, obojí hroty k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou pilovitou hlavou šikmo vztyčená zlatá radlice probodnutá kosmo stříbrným krojidlem, nad nimi tři stříbrné lilie vedle sebe."
    },
    {
        obec: "Heřmanice",
        kod: 574040,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55212,
        latitude: 50.379697,
        longitude: 15.91865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/He%C5%99manice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. V modrém pruhu žlutá vykračující lvice s červenou zbrojí. V červeném pruhu bílá žerďová vidlice s rameny širokými jednu desetinu šířky listu a vycházejícími z horního a dolního rohu pruhu, provázená ve vlající polovině čtyřmi (2,2) bílými květy heřmánku se žlutými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře zlatá vykračující lvice s červenou zbrojí, dole stříbrná vidlice provázená podél ramen čtyřmi (2,2) stříbrnými květy heřmánku se zlatými středy."
    },
    {
        obec: "Heřmanice",
        kod: 544353,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.897,
        longitude: 15.006457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Znak_He%C5%99manice.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký pět dvanáctin délky listu a sedm vodorovných pruhů střídavě bílých a zelených. V červeném pruhu bílá, žlutě zdobená plátová zbroj podložená žlutým mečem hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrná, zlatě zdobená plátová zbroj podložená vztyčeným zlatým mečem, vlevo tři červené květy netřesku se zlatými středy na lodyhách rostoucích z růžice, obojí zelené."
    },
    {
        obec: "Heřmanice u Oder",
        kod: 568562,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.713775,
        longitude: 17.805443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/He%C5%99manice_u_Oder_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: modrý, žlutý a modrý v poměru 1:2:1. Ve žlutém pruhu dvě přivrácené modré sekery s černou lilií mezi topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá linie mezi dvěma modrými přivrácenými sekerami."
    },
    {
        obec: "Heřmaničky",
        kod: 529702,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25789,
        latitude: 49.605411,
        longitude: 14.582592,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Hermanicky_BN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé nad stejně velkým bílým polem a dva vodorovné pruhy, bílý a modrý. V karé dvě odvrácené žluté kosy bez kosišť hrotem k hornímu okraji. V žerďové a střední části listu šikmo červený meč s hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě šikmo vztyčený červený meč. V 1. poli dvě vztyčené odvrácené zlaté kosy bez kosišť."
    },
    {
        obec: "Heřmánkovice",
        kod: 574058,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54984,
        latitude: 50.620004,
        longitude: 16.323954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/He%C5%99m%C3%A1nkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1:2:1. V bílém pruhu zelený list javoru nad červenou růží se žlutým semeníkem a zelenými kališními lístky. V žerďovém a vlajícím pruhu žluté ostrve se třemi suky (1,2 a 2,1) vycházející z horního a dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zvýšený stříbrný hrot, v něm zelený uťatý javor provázený u kmene dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky. Hrot je provázen zlatými ostrvovými břevny."
    },
    {
        obec: "Heřmánky",
        kod: 568571,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.707069,
        longitude: 17.768413,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/He%C5%99m%C3%A1nky_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým vykračujícím jelenem se žlutou zbrojí a dvěma bílými šikmými pruhy vycházejícími z druhé čtvrtiny žerďového okraje do druhé šestiny horního okraje a z páté šestiny dolního okraje do třetí čtvrtiny vlajícího okraje. Trojúhelníková pole v horním rohu a dolním cípu jsou žlutá. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném návrší mezi dvěma zlatými listnatými stromy stříbrný vykračující jelen se zlatou zbrojí."
    },
    {
        obec: "Heřmanov",
        kod: 595608,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59458,
        latitude: 49.379465,
        longitude: 16.181332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/He%C5%99manov_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený zeleným kosmým pruhem vycházejícím z polovin okrajů listu na žerďové žluté a vlající bílé pole. V zeleném pruhu kosmo dva bílé květy heřmánku se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dva zkřížené postavené stříbrné rýče se zlatými násadami nad dvěma stříbrnými květy heřmánku se zlatými středy na zlatých zkřížených prohnutých stoncích vyrůstajících z dolního okraje štítu."
    },
    {
        obec: "Heřmanov",
        kod: 562483,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.726587,
        longitude: 14.291315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/He%C5%99manov_DC_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený klín dosahující do poloviny délky listu a přilehlá modrá vidlice s rameny vycházejícími z první šestiny horního a dolního okraje listu, s vodorovným ramenem širokým desetinu délky listu. V klínu žluté mlýnské kolo. V horním bílém poli kosmo zelený bukový dvojlist s červenou bukvicí, v dolním bílém poli šikmo zelený dubový dvojlist s červeným žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít s modrou vidlicí, v horním zeleném poli zlaté mlýnské kolo. V pravém stříbrném poli šikmo svěšený zelený dubový dvojlist s červeným žaludem, v levém stříbrném poli kosmo svěšený zelený bukový dvojlist s červenou bukvicí."
    },
    {
        obec: "Haňovice",
        kod: 552178,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78321,
        latitude: 49.681155,
        longitude: 17.045152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Hanovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý, modře mřížovaný pruh, široký jednu třetinu délky listu a červené pole s bílým vlajícím klínem s vrcholem ve třetině délky listu. V bílém klínu modrá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou hlavou s modrou mříží stříbrný hrot s modrou lilií."
    },
    {
        obec: "Heřmanova Huť",
        kod: 558869,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.709118,
        longitude: 13.089275,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Hermanova_Hut_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu a modré pole. V červeném pruhu dva žluté ondřejské kříže nad sebou s rameny vycházejícími z první, třetí, čtvrté a šesté šestiny žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře zúžené břevno nahoře osazené třemi liliemi, dole kosmá proplétaná mříž, vše zlaté."
    },
    {
        obec: "Heřmanovice",
        kod: 597325,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79374,
        latitude: 50.193392,
        longitude: 17.397871
    },
    {
        obec: "Heřmanův Městec",
        kod: 571385,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.94717,
        longitude: 15.665028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Hermanuv_Mestec_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a zelený, v poměru 3:1:2. V horním žerďovém rohu zelené lekno. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hevlín",
        kod: 594032,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67169,
        latitude: 48.75219,
        longitude: 16.381414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Hevl%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý a modrý vodorovný pruh. V bílém pruhu modrá ryba dlouhá 2/3 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře polcený štít s kolmo postavenou rybou a radlicí obrácených barev. V hlavě štítu tři červené růže se zlatými semeníky, v patě štítu jedna táž růže."
    },
    {
        obec: "Hladké Životice",
        kod: 569666,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74247,
        latitude: 49.683,
        longitude: 17.957475,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Hladk%C3%A9_%C5%BDivotice_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s vozovým kolem s osmi loukotěmi a lilií ve středu, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrné vozové kolo s osmi loukotěmi a lilií ve středu."
    },
    {
        obec: "Hladov",
        kod: 587125,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.214727,
        longitude: 15.611458
    },
    {
        obec: "Hlasivo",
        kod: 560481,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.498562,
        longitude: 14.750424
    },
    {
        obec: "Hlásná Třebaň",
        kod: 531171,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.922344,
        longitude: 14.198762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Ht_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy zelený, žlutý a modrý. V horním pruhu bílý květ kakostu se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře kosmo děleném štítě kosmo položená, esovitě prohnutá zlatá lovecká trubka náustkem dolů a ouškem nahoru bez závěsu, provázená nahoře stříbrným květem kakostu se zlatým semeníkem."
    },
    {
        obec: "Hlavatce",
        kod: 535851,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37348,
        latitude: 49.067405,
        longitude: 14.263279
    },
    {
        obec: "Hlásnice",
        kod: 552330,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.753458,
        longitude: 17.294675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/CoA_of_Hl%C3%A1snice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a zelený, v poměru 4 : 1 : 1. V žerďové polovině listu vyniká ze žlutého pruhu bílá kvádrovaná hlásná věž nahoře s černým gotickým oknem, zakončená cimbuřím. Mezi žerďovým okrajem listu a věží žlutá osmicípá hvězda dotýkající se cípem žlutého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze zlato-zeleně dělené paty vyniká stříbrná kvádrovaná hlásná věž nahoře s černým gotickým oknem, zakončená cimbuřím. Vpravo u paty věže zlatá osmihrotá hvězda."
    },
    {
        obec: "Hlavatce",
        kod: 552321,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39173,
        latitude: 49.295598,
        longitude: 14.621315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Hlavatce_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílým žerďovým pruhem s dvanácti (6 x 2) kosmými vzájemně a okrajů pruhů se dotýkajícími červenými čtvercovými poli. Uprostřed zeleného pole tři zkřížené žluté obilné klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmo dělený štít. Horní pole zelené se třemi zkříženými zlatými obilnými klasy, dolní pole kosmo stříbrno-červeně šachované."
    },
    {
        obec: "Hlavečník",
        kod: 574961,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53315,
        latitude: 50.075555,
        longitude: 15.426616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Hlave%C4%8Dn%C3%ADk-znak.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu, bíle mřížovaný dvěma svislými a pěti vodorovnými pruhy, a žluté pole. V něm černý vykořeněný pařez hořící mezi dvěma černými borovými šiškami třemi červenými plameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod stříbrně mřížovanou zelenou hlavou černý vykořeněný pařez hořící mezi dvěma černými borovými šiškami třemi červenými plameny."
    },
    {
        obec: "Hlavice",
        kod: 564052,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46348,
        latitude: 50.631998,
        longitude: 14.926704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Hlavice_CoA.png",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrné průčelí kostela s věží. Ve věži vchod, nad ním v patře obloukové okno, pod červenou cibulovou střechou s lucernou. V lodi s červenou střechou čtyři (2, 2) okna nad sebou. Vchod a okna černá. V horních rozích štítu odvrácené zlaté lipové listy."
    },
    {
        obec: "Hlavenec",
        kod: 565989,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29474,
        latitude: 50.238392,
        longitude: 14.701862,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Coats_of_arms_Hlavenec.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, červený a zelený. V žerďovém pruhu bílá čelně hledící hlava jelena se žlutým latinským křížem mezi parohy, v červeném rakouská císařská koruna nad loveckou trubkou se závěsem, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo čelně hledící stříbrná hlava jelena se zlatým latinským křížem mezi parohy, vlevo rakouská císařská koruna nad loveckou trubkou se závěsem, obojí zlaté."
    },
    {
        obec: "Hlavnice",
        kod: 553093,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74752,
        latitude: 49.924487,
        longitude: 17.727963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Znak_obce_Hlavnice.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým koněm ve skoku. Nad hřbetem koně tři (2,1) bílé šesticípé hvězdy, pod břichem bílý mlýnský kámen s černou položenou kypřicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý kůň ve skoku provázený vlevo nahoře třemi (2,1) stříbrnými hvězdami a vpravo dole stříbrným mlýnským kamenem s černou položenou kypřicí."
    },
    {
        obec: "Hlavňovice",
        kod: 556203,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.237952,
        longitude: 13.395157
    },
    {
        obec: "Hlína",
        kod: 583022,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.113718,
        longitude: 16.426268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Znak_obce_Hl%C3%ADna.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 2:1. V zeleném pruhu vinný hrozen s listem a úponkem a vinařský nůž - kosíř hrotem a ostřím k žerďovému okraji, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vinný hrozen na stonku s jedním listem a úponkem a vinařský nůž - kosíř, vše zlaté."
    },
    {
        obec: "Hlince",
        kod: 566390,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.948335,
        longitude: 13.625133,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Znak_obce_Hlince.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 6:1. V červeném pruhu v žerďové části kosmo položený korbel, ve vlající části vykořeněný listnatý strom a v dolní části uprostřed tři (1,2) spojené cihly, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad vlnitou modrou patou tři (1,2) spojené cihly, nad nimi vpravo kosmo položený korbel a vlevo vykořeněný listnatý strom, vše stříbrné."
    },
    {
        obec: "Hlincová Hora",
        kod: 598607,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 48.983725,
        longitude: 14.56456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Hlincova_Hora_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1 : 2 : 1. V zeleném pruhu vyrůstá z dolního okraje listu bílá berla závitem k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená hora, z níž vyrůstají tři zelené smrky s černými kmeny. V hoře vyrůstající stříbrná berla."
    },
    {
        obec: "Hlinka",
        kod: 551872,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.280106,
        longitude: 17.672253,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, žlutý a modrý v poměru 7:2:7. V žerďové části horního rohu bílá růže se žlutým semeníkem a v žerďové části dolního rohu bílý osmihrotý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno provázané nahoře stříbrnou růží se zlatým semeníkem, dole stříbrným osmihrotým křížem."
    },
    {
        obec: "Hlinná",
        kod: 564842,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.572465,
        longitude: 14.10679,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Hlinn%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 3. Zelený pruh tvoří trojvrší, krajní vrchy široké čtvrtinu délky listu. Z prostředního vrchu vyrůstají dva odvrácené koniklece polní, pod nimi v zeleném pruhu dvě jablka, každé se dvěma odvrácenými listy na stopce, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě dva odvrácené koniklece polní přirozené barvy vyrůstající ze zeleného trojvrší, v něm vedle sebe dvě jablka, každé se dvěma odvrácenými listy na stopce, vše zlaté."
    },
    {
        obec: "Hlinsko",
        kod: 569267,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.49464,
        longitude: 17.579251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Hlinsko_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červeno-žlutě kosmo dělený a modrý. V žerďovém pruhu bílý vinařský nůž hrotem nahoru a ostřím k žerďovému okraji listu, ve středním modrá lilie a ve vlajícím bílý vinařský nůž se žlutou rukojetí hrotem nahoru a ostřím k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze zlaté kádě s modrou lilií vyrůstající muž v červeno-zlatě kosmo děleném šatě, držící v rozpažených rukou odvrácené stříbrné vinařské nože se zlatými rukojeťmi."
    },
    {
        obec: "Hlinsko",
        kod: 571393,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.762231,
        longitude: 15.907661,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Hlinsko_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, červený a bílý v poměru 1:3:1. V červeném poli polovina bílého kráčejícího koně s modrou uzdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě polovina stříbrného kráčejícího koně s modrou uzdou."
    },
    {
        obec: "Hlízov",
        kod: 531197,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28532,
        latitude: 49.985096,
        longitude: 15.295206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Hlizov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý, v poměru 1:4:1. V modrém pruhu bílý kořen křenu se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zleva ze stříbrného oblaku vynikající rameno přirozené barvy držící zlatou pochodeň s červeným plamenem, podložené stříbrným kořenem křenu se dvěma zelenými listy."
    },
    {
        obec: "Hlohová",
        kod: 553620,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.53414,
        longitude: 13.074684,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Hlohov%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 11 : 1 : 4. V modrém pruhu žlutý vyrůstající korunovaný rys s červenou zbrojí a bílými zuby, držící v tlamě kosmý bílý šíp s červeným hrotem a opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře vyrůstající zlatý korunovaný rys s červenou zbrojí a stříbrnými zuby držící v tlamě kosmý stříbrný šíp s červeným hrotem a opeřením, dole tři (2, 1) stříbrné květy hlohu se zelenými středy."
    },
    {
        obec: "Hlohovčice",
        kod: 566641,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.52085,
        longitude: 13.113875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Hlohov%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, červený, bílý a zelený, v poměru 1:2:2:1. Uprostřed vztyčená větev hlohu se třemi zelenými listy, s pěti bílými pětilistými květy s červenými středy v červeném pruhu a s pěti červenými hložinkami v bílém pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vztyčená větev hlohu se třemi zelenými listy a pod nimi na stoncích vpravo pět stříbrných pětilistých květů s červenými středy a vlevo pět červených hložinek."
    },
    {
        obec: "Hlohovec",
        kod: 584452,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69143,
        latitude: 48.774086,
        longitude: 16.762404,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Hlohovec_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s červeným srdcem, z něhož vyrůstají na dvou zelených stoncích, z nichž žerďový je rozdvojený a každý má dva lístky, tři červené šestilisté květy se žlutými středy. Stonky jsou vodorovně přeloženy modrou radlicí hrotem k žerdi a ostřím k dolnímu okraji se žlutým šestilistým květem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstají z červeného srdce na dvou zelených stoncích, z nichž levý je rozdvojený a každý má dva lístky, tři červené šestilisté květy se zlatými středy. Vše přeloženo položenou, doleva obrácenou modrou radlicí se zlatým šestilistým květem."
    },
    {
        obec: "Hlohovice",
        kod: 559806,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.889578,
        longitude: 13.643448,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Hlohovice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s kosmým zeleným pruhem širokým čtvrtinu délky listu se čtyřmi bílými květy hlohu se žlutými středy. V žerďové části svěšený, ve vlající části vztyčený zelený list hlohu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé zelené břevno se čtyřmi stříbrnými květy hlohu se zlatými středy provázené dvěma zelenými hlohovými listy, nahoře vztyčeným, dole svěšeným."
    },
    {
        obec: "Hlubočany",
        kod: 593036,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.230519,
        longitude: 17.000469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Znak_hlubocany_nove.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený se žlutým žerďovým klínem sahajícím do jedné třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý do poloviny zkrácený klín provázený vztyčenými přivrácenými stříbrnými krojidlem a radlicí."
    },
    {
        obec: "Hlubočec",
        kod: 568368,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74769,
        latitude: 49.840666,
        longitude: 17.97043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Hlubo%C4%8Dec_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým pruhem širokým jednu třetinu délky listu. V žerďové a střední části listu zeleno-bíle polcený uťatý smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě zeleno-stříbrně polcený uťatý smrk."
    },
    {
        obec: "Hluboká",
        kod: 571407,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53973,
        latitude: 49.84568,
        longitude: 16.071649,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Hlubok%C3%A1_%28Chrudim_District%29_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s dvěma zelenými čtvrtkruhovými poli, horním žerďovým a dolním vlajícím, vycházejícími z poloviny horního a dolního okraje listu. V žerďovém poli kosmo žlutý gotický klíč zuby nahoru a k žerďovému okraji listu šikmo podložený bílým mečem se žlutým jílcem hrotem k hornímu okraji listu. Ve vlajícím poli vzlétající bílá holubice se žlutou zbrojí, držící v zobáku svěšenou žlutou lipovou ratolest se čtyřmi listy a dvěma plodenstvími. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou vypuklou hlavou zelené návrší. V hlavě kosmo zlatý gotický klíč šikmo podložený vztyčeným stříbrným mečem se zlatým jílcem. V návrší stříbrná vzlétající holubice se zlatou zbrojí, držící v zobáku svěšenou zlatou lipovou ratolest se čtyřmi listy a dvěma plodenstvími."
    },
    {
        obec: "Hlubočky",
        kod: 502146,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78361,
        latitude: 49.627673,
        longitude: 17.402638,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Hlubo%C4%8Dky_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 7 : 14 : 7. Žerďový a vlající pruh tvoří osm vodorovných, střídavě červených a žlutých pruhů. Střední pruh je zeleno-modře dělený, v poměru 3 : 1. V zeleném poli bílý rovnoramenný trojúhelník se základnou na horním okraji modrého pole a s vrcholem na horním okraji listu. V něm modrá mísa se šlehajícími žlutými, červeně lemovanými plameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s modrou patou stříbrná špice a v ní modrá mísa, z níž šlehají plameny přirozené barvy."
    },
    {
        obec: "Hluboká nad Vltavou",
        kod: 544485,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.052354,
        longitude: 14.434371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Hlubok%C3%A1_nad_Vltavou_znak.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, žlutý, pět vlnitých střídavě červených a bílých a zvlněný žlutý, v poměru 5 : 2 : 2 : 2 : 2 : 2 : 5, se třemi vrcholy a dvěma prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Nahoře stříbrno - zlatě polcený, vpravo tři červené vlnité kůly, vlevo dolů obrácená mušle přirozené barvy. V dolním zlatém poli na zeleném trávníku muž v brnění se zavřeným hledím držící v pravici nad hlavou palcát, vše přirozené barvy, a v levici červený gotický štít se stříbrným tlapatým křížem."
    },
    {
        obec: "Hluboké",
        kod: 590614,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.226034,
        longitude: 16.227607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Coat_of_arms_of_Hlubok%C3%A9%2C_T%C5%99eb%C3%AD%C4%8D_District.svg",
        coatOfArmsFlagDescription: "Zelený list s modrým žerďovým klínem a bílou krokví vycházející z první a dvanácté dvanáctiny žerďového okraje listu s vrcholem na vlajícím okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zelený štít s modrým, stříbrně lemovaným klínem, v něm stříbrná hrdlička se zlatou zbrojí, černým obojkem a zelenou větvičkou v zobáku."
    },
    {
        obec: "Hluboké Dvory",
        kod: 581577,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.383241,
        longitude: 16.514309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Hlubok%C3%A9_Dvory_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený se žlutým pluhem a žlutý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou pluh převýšený dvěma volskými rohy, obojí zlaté."
    },
    {
        obec: "Hluboš",
        kod: 540242,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26222,
        latitude: 49.746325,
        longitude: 14.020299,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Hlubo%C5%A1_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. V žerďové části červeného pruhu tři žluté cvočky (1,2) hroty k sobě. Na podélné ose zeleného pruhu žlutý řetěz ze sedmi článků vycházející z žerďového a vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou vypuklou hlavou se třemi (1,2) cvočky hroty k sobě vodorovný řetěz ze sedmi článků, vše zlaté."
    },
    {
        obec: "Hlubyně",
        kod: 564605,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.559767,
        longitude: 13.923345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Hlubyn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "Šikmo dělený list. Horní žerďové pole žluté s čelně postavenou modrou radlicí mezi lipovou a dubovou ratolestí, obě zelené. V dolním vlajícím poli pět šikmých pruhů, zelený, bílý, modrý, bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně dolů do oblouku děleném štítě nahoře čelně modrá radlice mezi lipovou a dubovou ratolestí, obě zelené, dole modré, stříbrně lemované vlnité břevno."
    },
    {
        obec: "Hluchov",
        kod: 589501,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79841,
        latitude: 49.541496,
        longitude: 16.996747,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Znak_obce_Hluchov.png",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký jednu třetinu délky listu se čtyřmi vlajícími bílými klíny s vrcholy na žerďovém okraji a modré pole s lilií a radlicí hrotem nahoru a ostřím k vlajícímu okraji, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se třemi kužely lilie provázená dvěma vztyčenými odvrácenými radlicemi, vše stříbrné."
    },
    {
        obec: "Hluboké Mašůvky",
        kod: 594041,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67152,
        latitude: 48.923757,
        longitude: 16.025651,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Hlubok%C3%A9_Ma%C5%A1%C5%AFvky_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed červeno-žlutě polcené srdce s vyrůstající bílo-červeně polcenou lilií, podložené zkříženými odvrácenými bílo-červeně dělenými praporci s ocasy na žlutých žerdích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červeno-zlatě polcené srdce s vyrůstající stříbrno-červeně polcenou lilií, podložené zkříženými odvrácenými stříbrno-červeně dělenými praporci s ocasy na zlatých žerdích."
    },
    {
        obec: "Hlučín",
        kod: 507016,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74801,
        latitude: 49.896627,
        longitude: 18.190649,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Hlu%C4%8D%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, zelený a žlutý v poměru 1:3:1. V zeleném poli žlutá figura z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hluk",
        kod: 592170,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68725,
        latitude: 48.988219,
        longitude: 17.526788,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Hluk_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří šikmý modrý pruh široký jednu třetinu délky listu. Horní žerďové pole bílé, dolní vlající zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě hoch ve hnědé přepásané tunice držící v pravici modrý vinný hrozen s jedním zeleným listem, v levici vinařský nůž přirozené barvy."
    },
    {
        obec: "Hlupín",
        kod: 536628,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.334796,
        longitude: 13.806877
    },
    {
        obec: "Hlušice",
        kod: 570001,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50356,
        latitude: 50.264697,
        longitude: 15.403364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Hlu%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a modrý, v poměru 16 : 1 : 1 : 1 : 1. V zeleném pruhu ve žlutém mezikruží knížecí čepice s křížkem, pod ním zkřížené žluté obilné klasy, každý s dvěma odkloněnými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s vlnitou patou knížecí čepice s křížkem ve zlatém kruhu, pod ním zkřížené zlaté obilné klasy, každý se dvěma odkloněnými listy. Pata třikrát stříbrno-modře vlnitě dělená."
    },
    {
        obec: "Hlušovice",
        kod: 552020,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78314,
        latitude: 49.638193,
        longitude: 17.277495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Hlu%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeným vlajícím pruhem širokým jednu třetinu délky listu. V zeleném poli kosmo bílá radlice hrotem a ostřím dolů, nad ní ve střední části listu žlutá šesticípá hvězda. V červeném pruhu bílá zděná boží muka se žlutou stříškou a křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo stříbrná radlice převýšená zlatou hvězdou, vlevo stříbrná zděná boží muka se zlatou stříškou a křížkem."
    },
    {
        obec: "Hnačov",
        kod: 542083,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34034,
        latitude: 49.361927,
        longitude: 13.48178
    },
    {
        obec: "Hnanice",
        kod: 594059,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.798587,
        longitude: 15.98678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Hnanice_-_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou velkou literou 'W' překrytou žlutou berlou závitem k vlajícímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hnátnice",
        kod: 580261,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56101,
        latitude: 50.020435,
        longitude: 16.438656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Hn%C3%A1tnice.svg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část se žlutým klíčem zubem nahoru k žerdi a červená vlající část se žlutým mečem hrotem nahoru. List překryt bílým středovým křížem s rameny širokými jednu sedminu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě kříž ze dvou stříbrných kostí (hnátů), podložený vpravo zlatým vztyčeným klíčem a vlevo zlatým vztyčeným mečem."
    },
    {
        obec: "Hněvčeves",
        kod: 570010,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.314969,
        longitude: 15.71623,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Hnevceves_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Ve žlutém brk v kalamáři, obojí červené, v zeleném žlutý obilný snop. U dolního okraje listu dvě zkřížené lipové ratolesti opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě vpravo červený brk v kalamáři, vlevo zlatý obilný snop, v patě štítu zkřížené lipové ratolesti opačných barev."
    },
    {
        obec: "Hněvkovice",
        kod: 568694,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58294,
        latitude: 49.685299,
        longitude: 15.202877,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Hn%C4%9Bvkovice_HB_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s černo-žlutým štenýřovým lemem, širokým osminu šířky listu, a bílou korunovanou lví hlavou s krkem a žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě s černo-zlatým štenýřovým lemem stříbrná korunovaná hlava lva s krkem a zlatým jazykem."
    },
    {
        obec: "Hněvnice",
        kod: 530221,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.728607,
        longitude: 13.103241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Coat_of_arms_of_Hn%C4%9Bvnice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý. Uprostřed žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě děleném štítě zlatá lilie."
    },
    {
        obec: "Hněvošice",
        kod: 507105,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74735,
        latitude: 50.00309,
        longitude: 18.008393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Znak_obce_Hn%C4%9Bvo%C5%A1ice.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, zelený a žlutý v poměru 1:2:1. V zeleném poli bílý beran se žlutými rohy a žlutými kopyty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný beran se zlatou zbrojí, provázený nahoře zlatým mečem, dole zlatým klíčem."
    },
    {
        obec: "Hněvotín",
        kod: 502235,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78347,
        latitude: 49.572151,
        longitude: 17.179642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Hn%C4%9Bvot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, trnový modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu berla závitem k žerdi a s kruhovým polem v závitu, obojí žluté, dole přeložená bílým štítkem, v něm šikmo radlice nahoře kosmo podložená krojidlem, obojí červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi stříbrnými oblačnými boky berla s kotoučem v závitu, obojí zlaté, dole přeložená stříbrným štítkem, v něm šikmo radlice nahoře kosmo podložená krojidlem, obojí červené."
    },
    {
        obec: "Hnojice",
        kod: 502405,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.716409,
        longitude: 17.224227,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Hnojice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s kosmými vidlemi šikmo přeloženými rýčem, obojí bílé se žlutými násadami hroty nahoru, provázené nahoře lilií a pěticípou hvězdou, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo položené vidle šikmo přeložené stříbrným rýčem, obojí vztyčené, stříbrné na zlatých násadách, provázené nahoře lilií a dole hvězdou, obojí zlaté."
    },
    {
        obec: "Hnojník",
        kod: 598160,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73953,
        latitude: 49.682383,
        longitude: 18.541393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Znak-obce-hnojnik.gif",
        coatOfArmsFlagDescription: "Žluto-modře šikmo dělený list se šikmo položenou, oboustranně uťatou lipovou větví o dvou listech a dvou sucích opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře šikmo děleném štítě šikmo položená oboustranně uťatá lipová větev se dvěma listy a dvěma suky opačných barev."
    },
    {
        obec: "Hobšovice",
        kod: 513075,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27321,
        latitude: 50.270647,
        longitude: 14.162268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Hob%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červeno - zeleně polcený, v poměru 1 : 3 a žlutý v poměru 1 : 6 : 1. Na styku červeného a zeleného pruhu červená knížecí čepice ozdobená křížkem v mezikruží, vše žluté, pod ní tři články položeného bílého řetězu, krajní poloviční rozlomené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně děleném štítě pod stříbrnou plamenitou hlavou ve zlatém kruhu knížecí čepice zdobená křížkem, pod ním tři články položeného stříbrného řetězu, krajní poloviční rozlomené."
    },
    {
        obec: "Hodějice",
        kod: 593044,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.141595,
        longitude: 16.913792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Znak_Hod%C4%9Bjice.jpg",
        coatOfArmsFlagDescription: "Modrý list s hnědou vodorovně položenou větví vinné révy se čtyřmi zelenými listy a žlutým hroznem. Nad ní šikmo krojidlo kosmo přeložené radlicí, oboje bílé, hroty nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě položená větev vinné révy se čtyřmi listy přirozené barvy a zlatým hroznem, nad ní šikmo krojidlo kosmo přeložené radlicí, obojí vztyčené a stříbrné."
    },
    {
        obec: "Hodětín",
        kod: 598992,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.252274,
        longitude: 14.548733
    },
    {
        obec: "Hodice",
        kod: 587141,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58901,
        latitude: 49.270172,
        longitude: 15.480092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Hodice_znak.gif",
        coatOfArmsFlagDescription: "Červený list se dvěma bůvolími rohy z obecního znaku v žerďové části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dva zlaté bůvolí rohy."
    },
    {
        obec: "Hodíškov",
        kod: 595624,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.503592,
        longitude: 16.038149,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Hod%C3%AD%C5%A1kov_CoA.jpg",
        coatOfArmsFlagDescription: "Modro - žlutě oblačným řezem dělený list.V modrém pruhu lilie a tiskařský tampon, obojí žluté, ve žlutém tři (2, 1) odstředivé zelené lipové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro - zlatě oblačným řezem dělený štít. Nahoře vedle sebe lilie a tiskařský tampon, obojí zlaté, dole tři (2, 1) odstředivé zelené lipové listy."
    },
    {
        obec: "Hodonice",
        kod: 562904,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.268626,
        longitude: 14.487466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Hodonice_TA_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým snopem nad bílým pluhem s kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý snop nad stříbrným pluhem s kolem."
    },
    {
        obec: "Hodkovice nad Mohelkou",
        kod: 564061,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46342,
        latitude: 50.665963,
        longitude: 15.08996,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Hodkovice_nad_Mohelkou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý se vzpřímeným černým medvědem a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hodonice",
        kod: 594067,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67125,
        latitude: 48.8369,
        longitude: 16.163476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Hodonice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým klínem vycházejícím z dolního okraje listu s vrcholem sahajícím do první čtvrtiny šířky listu. V žerďové a vlající části po žlutém vinném hroznu. V klínu osmihrotý kříž nad šesticípou hvězdou, obojí červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se sníženým stříbrným hrotem dva zlaté vinné hrozny. V hrotu osmihrotý kříž nad hvězdou, obojí červené."
    },
    {
        obec: "Hodonín",
        kod: 581593,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.501284,
        longitude: 16.410379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Hodon%C3%ADn_%28Blansko%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a vlnitý žlutý, v poměru 5 : 4 : 1. V horním pruhu černá zubří hlava se žlutou houžví a červeným jazykem, v červeném pruhu krojidlo ostřím dolů a hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně děleném štítě nahoře černá zubří hlava se zlatou houžví a červeným jazykem, dole nad zlatou vlnitou patou doleva položené stříbrné krojidlo ostřím dolů."
    },
    {
        obec: "Hodonín",
        kod: 547794,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.837054,
        longitude: 15.78717,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Hodonin_CR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 9:1:9. V horním modrém pruhu žlutý patriarší kříž a dva vztyčené, odvrácené, dole přeložené gotické klíče, žlutý a bílý, vodorovně proložené žlutým mečem hrotem k žerdi. V dolním modrém pruhu žlutý zvon. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno, nahoře vpravo zlatý patriarší kříž, vlevo dva vztyčené, odvrácené a dole přeložené gotické klíče, zlatý a stříbrný, proložené položeným zlatým mečem, dole zlatý zvon."
    },
    {
        obec: "Hodonín",
        kod: 586021,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69501,
        latitude: 48.853039,
        longitude: 17.126102,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Hodonin_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - modrý, bílý a modrý, v poměru 1:2:1. V bílém poli červená šestilistá růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hodov",
        kod: 590622,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67504,
        latitude: 49.29408,
        longitude: 15.983949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Coat_of_arms_of_Hodov.jpg",
        coatOfArmsFlagDescription: "Zelený list. Uprostřed kalich a radlice hrotem nahoru a ostřím k vlajícímu okraji listu, obojí bílé. Nad nimi pět žlutých šesticípých hvězd do oblouku, pod nimi dva bílé květy lnu se žlutými středy na zkřížených žlutých stoncích s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pohár a vztyčená, doleva obrácená radlice, obojí stříbrné. Nad nimi pět zlatých hvězd do oblouku, pod nimi dva stříbrné květy lnu se zlatými středy na zkřížených zlatých stoncích s listy."
    },
    {
        obec: "Hodslavice",
        kod: 599409,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74271,
        latitude: 49.53866,
        longitude: 18.023783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Hodslavice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený, modrý a bílý v poměru 1:3:1. V modrém pruhu žlutý jelen běžící k vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrno-červeně dvěma řadami šachovanou hlavou doleva běžící zlatý jelen, pod ním stříbrná rozevřená kniha."
    },
    {
        obec: "Hojanovice",
        kod: 547883,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.597358,
        longitude: 15.265616
    },
    {
        obec: "Hojkov",
        kod: 587150,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.389244,
        longitude: 15.413786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Coat_of_arms_of_Hojkov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý, červený a vodorovný bílý pruh vycházející z osmé desetiny žerďového a vlajícího okraje listu, nad ním v modrém pruhu lilie, v červeném rosnatka okrouhlolistá, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad stříbrným vlnitým břevnem vpravo lilie, vlevo rosnatka okrouhlolistá, obojí zlaté."
    },
    {
        obec: "Hojovice",
        kod: 561096,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.32846,
        longitude: 14.919872
    },
    {
        obec: "Holany",
        kod: 561584,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47002,
        latitude: 50.618099,
        longitude: 14.493073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Holany_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou kvádrovanou hradbou s cimbuřím a dvěma věžemi, každá se dvěma černými okny pod sebou a červenou stanovou střechou se žlutou makovicí, mezi nimi vyrůstá zelená bříza s bílým kmenem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s cimbuřím a se dvěma věžemi, každá se dvěma černými okny pod sebou a červenou stanovou střechou se zlatou makovicí. Uprostřed vyrůstá bříza přirozené barvy."
    },
    {
        obec: "Holasice",
        kod: 583031,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.075956,
        longitude: 16.607667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Holasice-znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy bílý a červený a vlající půlkruhové pole opačných barev o poloměru rovném polovině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě oboustranně zkrácené vlnité břevno opačných barev."
    },
    {
        obec: "Holasovice",
        kod: 507113,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74774,
        latitude: 49.998525,
        longitude: 17.808573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Coat_of_arms_of_Holasovice.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým rovným tlapatým křížem. Na vlajícím okraji pilovitý bílý pruh široký jednu čtvrtinu délky listu se čtyřmi trojúhelníkovými zuby sahajícími do poslední třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou palisádovou hradbou se čtyřmi kůly zlatý tlapatý kříž."
    },
    {
        obec: "Holčovice",
        kod: 597341,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79371,
        latitude: 50.156849,
        longitude: 17.486079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Hol%C4%8Dovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a bílý, v poměru 5:10:4. V zeleném pruhu kráčející žlutý kůň s bílou hřívou a ocasem, s černým postrojem a s bílým vlečným řetězem. Horní okraj zeleného pruhu je pilovitý se šesti zuby v podobě rovnoramenných trojúhelníků, Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou pilovitou hlavou zlatý kráčející kůň se stříbrnou hřívou a ocasem, černým postrojem táhnoucí na stříbrném řetězu tři klády přirozených barev, do nichž je zaťata stříbrná sekera se zlatým topůrkem. V patě štítu stříbrná kamenná hráz."
    },
    {
        obec: "Holedeč",
        kod: 566187,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.277534,
        longitude: 13.563098,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Holede%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. Na dolním okraji listu zvlněný červeno - žlutě polcený pruh se třemi vrcholy a dvěma prohlubněmi. V bílém poli zelený stonek chmele se dvěma listy a dvěma dvojicemi šištic. V modrém poli kaplička s prázdnými dveřmi a oknem, zakončená křížkem, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře polceném štítě nad sníženým červeno - zlatě polceným vlnitým břevnem vpravo zelený stonek chmele se dvěma listy a dvěma dvojicemi šištic, vlevo kaplička s prázdnými dveřmi a oknem, zakončená křížkem, vše stříbrné."
    },
    {
        obec: "Holenice",
        kod: 577111,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 50601,
        latitude: 50.520473,
        longitude: 15.300548
    },
    {
        obec: "Holešov",
        kod: 588458,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.33341,
        longitude: 17.57843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Coat_of_arms_of_Hole%C5%A1ov.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 4 : 1. V modrém pruhu na třech stupních kvádrovaná věž se dvěma černými okny vedle sebe, cimbuřím se čtyřmi zuby a stanovou střechou zakončenou osmicípou hvězdou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na třech stupních kvádrovaná věž se dvěma černými okny vedle sebe, cimbuřím se čtyřmi stínkami a stanovou střechou zakončenou osmihrotou hvězdou, vše zlaté."
    },
    {
        obec: "Holetín",
        kod: 571440,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53971,
        latitude: 49.794858,
        longitude: 15.923513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Holet%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 2:1. Uprostřed listu bílý pelikán se žlutou zbrojí skloněný nad třemi bílými mláďaty ve žlutém hnízdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně sníženě děleném štítě stříbrný pelikán se zlatou zbrojí skloněný nad třemi stříbrnými mláďaty ve zlatém hnízdě."
    },
    {
        obec: "Holice",
        kod: 574988,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.06611,
        longitude: 15.986005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Znak_m%C4%9Bsta_Holice.jpg",
        coatOfArmsFlagDescription: "Žluto-modře šikmo dělený list. Ve žlutém poli šikmý černý pruh vycházející ze sedmé osminy žerďového okraje do sedmé osminy horního okraje listu. V modrém poli šikmý bílý pruh vycházející ze druhé osminy dolního okraje do druhé osminy vlajícího okraje listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Holín",
        kod: 572900,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.451706,
        longitude: 15.320807,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Hol%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list. Uprostřed černý lev s červenou zbrojí. Po obvodu listu dvanáct (5, 2, 5) červených lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černý lev s červenou zbrojí. Okraj štítu lemován dvanácti červenými liliemi."
    },
    {
        obec: "Holohlavy",
        kod: 570028,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.308038,
        longitude: 15.860186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Holohlavy_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým žlutým pruhem širokým desetinu šířky listu na zelené žerďové a modré vlající pole. V zeleném poli kosmý obilný klas šikmo podložený brkem, oboje žluté, v modrém poli bílá hlava mnicha s krkem, černým věncem vlasů s tonzurou a s černou shrnutou kápí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně zúženým zlatým břevnem kosmo děleném štítě nahoře stříbrná hlava mnicha s krkem, s černým věncem vlasů s tonzurou a černou shrnutou kápí, dole kosmý obilný klas šikmo podložený brkem, obojí zlaté."
    },
    {
        obec: "Holotín",
        kod: 573019,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.945993,
        longitude: 15.580349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Holot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, střídavě zelených a žlutých, v poměru 1:1:1:3:1:1:1. V prostředním pruhu zelený bodlák s červeným květem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý kůl se zeleným bodlákem s červeným květem, provázený dvěma zlatými obilnými klasy, každý se dvěma svěšenými listy."
    },
    {
        obec: "Holovousy",
        kod: 572918,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.375381,
        longitude: 15.577533,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Holovousy_Ho%C5%99ice-znak.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým jablkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté jablko."
    },
    {
        obec: "Holoubkov",
        kod: 559814,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33801,
        latitude: 49.776089,
        longitude: 13.692566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Znak_holoubkov.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený. V bílém pruhu kosmo položený mlátek šikmo přeložený želízkem, přes ně postaveny hutnické kleště, vše černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným lemem kosmo položený mlátek šikmo přeložený želízkem, přes ně postaveny hutnické kleště, vše černé."
    },
    {
        obec: "Holovousy",
        kod: 566730,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.96798,
        longitude: 13.614819
    },
    {
        obec: "Holubice",
        kod: 550825,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68351,
        latitude: 49.177588,
        longitude: 16.812243,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Holubice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou holubicí se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná holubice se zlatou zbrojí a zlatým obilným klasem v zobáčku, stojící na položené zlaté amfoře."
    },
    {
        obec: "Holubov",
        kod: 545490,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38203,
        latitude: 48.890096,
        longitude: 14.321188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Holubov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, modrý a bílý. V zeleném pruhu bílá bokem postavená kvádrová věž s cimbuřím, nahoře se dvěma gotickými a dole obdélníkovým oknem a gotickou branou, vše černé. V modrém pruhu čelně vzlétající bílý holub s červenou zbrojí, v bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít. V pravém zeleném poli stříbrná bokem postavená kvádrovaná věž s cimbuřím nahoře se dvěma gotickými a dole obdélníkovým oknem a gotickou branou, vše černé. Levé pole modro-stříbrně dělené, nahoře čelně vzlétající stříbrný holub s červenou zbrojí, dole červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Holýšov",
        kod: 553654,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.593721,
        longitude: 13.101403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Holysov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém tři (2, 1) černá paroží, v modrém kosmo klíč zuby nahoru a k dolnímu rohu listu, šikmo podložený mečem hrotem k hornímu cípu listu, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře dělený štít. V horním poli troje černé paroží (2+1), v dolním poli křížem přeloženým klíč a meč, vše stříbrné."
    },
    {
        obec: "Homole",
        kod: 544493,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.939,
        longitude: 14.429572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Homole_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý se čtyřmi propletenými zelenými obilnými klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červené volné čtvrti stříbrného štítu půl stříbrného lva. Ve štítě nahoře černý dubový trojlist (2,1) proložený třemi žaludy. Dole čtyři zelené propletené obilné klasy."
    },
    {
        obec: "Holštejn",
        kod: 581615,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67913,
        latitude: 49.406486,
        longitude: 16.777804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Hol%C5%A1tejn_CoA.svg",
        coatOfArmsFlagDescription: "Červený list, uprostřed na bílé skále, dole s černou jeskyní, bílý hrad s kvádrovanou hradbou, z níž vynikají dvě věže, každá s černým gotickým oknem a cimbuřím. V hradbě gotická brána se zavřenými vraty, křídlo k žerďovému okraji listu pětkrát černo-bíle kosmo dělené, křídlo k vlajícímu okraji červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na stříbrné skále, dole s černou jeskyní, stříbrný hrad s kvádrovanou hradbou, z níž vynikají dvě věže, každá s černým gotickým oknem a cimbuřím. V hradbě gotická brána se zavřenými vraty, jejichž pravé křídlo je pětkrát černo-stříbrně kosmo dělené a levé červené."
    },
    {
        obec: "Holubice",
        kod: 539228,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25265,
        latitude: 50.203189,
        longitude: 14.292949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Holubice_%28Praha-zapad%29_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý kvádrovaný a červený, v poměru 4:7:4. V žerďovém pruhu bílý plamenný meč hrotem k hornímu okraji listu, v prostředním pruhu velké unciální písmeno M, ve vlajícím pruhu bílá otka bodcem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě široký stříbrný kvádrovaný kůl s červeným unciálním písmenem M, provázený vpravo vztyčeným plamenným mečem, vlevo postavenou otkou, obojí stříbrné."
    },
    {
        obec: "Homole u Panny",
        kod: 567973,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.62938,
        longitude: 14.186494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Homole_u_Panny_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, zelený, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot provázený dvěma stříbrnými růžemi se zlatými semeníky. V hrotu na zeleném návrší hřib přirozené barvy."
    },
    {
        obec: "Honbice",
        kod: 571458,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.933561,
        longitude: 15.893093
    },
    {
        obec: "Honětice",
        kod: 542342,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76813,
        latitude: 49.203806,
        longitude: 17.253216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Hon%C4%9Btice_CoA.PNG",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a žlutý, v poměru 1:4:1. V zeleném pruhu bílo-červeně polcená lilie podložená dvěma zkříženými žlutými šípy hroty nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatým stoupajícím půlměsícem stříbrno-červeně polcená lilie podložená zkříženými zlatými šípy hroty vzhůru."
    },
    {
        obec: "Honezovice",
        kod: 540668,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.638318,
        longitude: 13.062664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Honezovice_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list přeložený modrým svislým pruhem širokým jednu třetinu délky listu. V modrém pruhu žlutá berla s lilií v hlavici s bílým sudariem do stran, přeložená červenou žlutě zdobenou knížecí čepicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli zlatá berla s lilií v hlavici se stříbrným sudariem do stran, přeložená červenou zlatě zdobenou knížecí čepicí, v levém, červeno-stříbrně děleném poli nahoře zlatá věž s cimbuřím s černým valeným oknem a branou, dole kosmá černá orlice se zlatou zbrojí a perizoniem a s červeným jazykem."
    },
    {
        obec: "Hora Svaté Kateřiny",
        kod: 567167,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43546,
        latitude: 50.606416,
        longitude: 13.436834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Hora_Svat%C3%A9_Kate%C5%99iny_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hora Svatého Šebestiána",
        kod: 563064,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43182,
        latitude: 50.510328,
        longitude: 13.251454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Hss_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový vlnkovaný pruh s pěti černými hermelínovými ocásky vodorovně položenými v odpovídajících obloucích, zasahujících do jedné čtvrtiny délky listu a jedenáct střídavě červených a žlutých vodorovných pruhů. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hora Svatého Václava",
        kod: 566276,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.518986,
        longitude: 12.739958
    },
    {
        obec: "Horažďovice",
        kod: 556254,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.320794,
        longitude: 13.701106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Hora%C5%BE%C4%8Fovice_znak.svg"
    },
    {
        obec: "Horčápsko",
        kod: 564257,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.584502,
        longitude: 13.984557
    },
    {
        obec: "Horka",
        kod: 571466,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.887322,
        longitude: 15.916873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Horka_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Do bílého pruhu vyrůstá v žerďové polovině zelený borůvkový keř s modrými plody, v žerďové polovině zeleného pruhu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený borůvkový keř s modrými plody vyrůstající ze zeleného vrchu se zlatou lilií."
    },
    {
        obec: "Horka I",
        kod: 531430,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.982795,
        longitude: 15.44004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Horka_I_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý v poměru 1:2:1. V modrém pruhu vyrůstají ze zeleného návrší tři dole spojené, odkloněné žluté obilné klasy, krajní s listem, provázené dole dvěma odvrácenými bílými kosami bez kosišť hroty nahoru. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší tři dole spojené, odkloněné zlaté obilné klasy, krajní s listem, provázené dole dvěma vztyčenými odvrácenými stříbrnými kosami bez kosišť."
    },
    {
        obec: "Horka II",
        kod: 534056,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.732179,
        longitude: 15.13385
    },
    {
        obec: "Horka nad Moravou",
        kod: 502545,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78335,
        latitude: 49.640186,
        longitude: 17.210806,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Horka_nad_Moravou_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle kosmo zubatě dělený list se šesti kosodélníkovými zuby vysokými jednu osminu šířky listu a sedmi stejnými mezerami. Z prostřední mezery vyrůstá žlutá berla závitem k žerdi s bílým sudáriem zakončeným žlutými třásněmi. Horní okraj zubů protíná žerďový okraj v první čtvrtině, vlající okraj ve čtvrté čtvrtině. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá z kosmé kvádrové zdi s cimbuřím zlatá berla závitem doprava, se stříbrným sudariem, zakončeným zlatými třásněmi."
    },
    {
        obec: "Horka u Staré Paky",
        kod: 574201,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51234,
        latitude: 50.52618,
        longitude: 15.57583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Horka_u_Star%C3%A9_Paky_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým vozovým kolem v žerďové a střední části, překrytým bílým vlajícím pětiúhelníkovým polem, na okraji listu dlouhým jednu třetinu délky listu a s vrcholem v první třetině délky listu. V něm zelený listnatý strom. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný hrot se zeleným listnatým stromem. Z hrotu vyniká zlaté vozové kolo."
    },
    {
        obec: "Horky",
        kod: 578053,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.920822,
        longitude: 16.243471
    },
    {
        obec: "Horky",
        kod: 531359,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.868864,
        longitude: 15.439898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Horky_%28Kutn%C3%A1_Hora_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a žlutých, v poměru 1 : 1 : 4 : 1 : 1. V prostředním pruhu čelně normanská přilba nad devíticípou hvězdou, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma zúženými zlatými kůly čelně normanská přilba nad devítihrotou hvězdou, obojí stříbrné."
    },
    {
        obec: "Horky nad Jizerou",
        kod: 535818,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29473,
        latitude: 50.327164,
        longitude: 14.856169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Horky_nad_Jizerou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3 : 2 : 3. V horním rohu koruna s červenými drahokamy, v dolním rohu tlapatý kříž, v horním cípu šesticípá hvězda, v dolním cípu kruhové pole, vše žluté. Do bílého pruhu vynikají tři zelená návrší dotýkající se okrajů pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvrší se stříbrným vlnitým břevnem koruna s červenými drahokamy převýšená koulí mezi tlapatým křížem a hvězdou, vše zlaté."
    },
    {
        obec: "Horní Bečva",
        kod: 542687,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75657,
        latitude: 49.43226,
        longitude: 18.288701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/CZ_Horn%C3%AD_Be%C4%8Dva_COA.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutou vztyčenou troubou mezi bílými křídly. Na vlajícím okraji tři bílé rovnostranné trojúhelníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným trojvrším se zelenou korunou zlatá vztyčená trouba mezi stříbrnými křídly."
    },
    {
        obec: "Horní Bělá",
        kod: 558877,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.889081,
        longitude: 13.264218,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Horn%C3%AD_B%C4%9Bl%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký polovinu délky listu a dva vodorovné pruhy, červený a zelený. V bílém pruhu tři (2, 1) černá jelení paroží, v červeném žlutý zvon a v zeleném čelně bílá beraní hlava se žlutými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrném poli tři (2, 1) černá jelení paroží, levé pole červeno-zeleně dělené, nahoře zlatý zvon, dole čelně stříbrná beraní hlava se zlatými rohy."
    },
    {
        obec: "Horní Benešov",
        kod: 597350,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79312,
        latitude: 49.966918,
        longitude: 17.60273,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Horn%C3%AD_Bene%C5%A1ov_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část s polovinou černé orlice s červenou zbrojí přiléhající k červené vlající části se dvěma šikmo pod sebou položenými bílými hornickými motykami na hnědých násadách. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Horní Beřkovice",
        kod: 564851,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41185,
        latitude: 50.358792,
        longitude: 14.346588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Horn%C3%AD_Be%C5%99kovice_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Ze zeleného pruhu vyskakuje bílý jednorožec se žlutou zbrojí. Pod ním mezi dvěma zkříženými klasy, každý s listem, jablko se dvěma odkloněnými listy na stopce, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně dělený štít. Nahoře vyskakující stříbrný jednorožec se zlatou zbrojí. Dole dva zkřížené obilné klasy, každý s listem, nahoře mezi nimi jablko se dvěma odkloněnými listy na stopce, vše zlaté."
    },
    {
        obec: "Horní Bezděkov",
        kod: 532312,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.082352,
        longitude: 14.067597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Horni_Bezdekov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený v poměru 1:3:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě se zelenou hlavou a patou tři černé ostrve nad sebou, prostřední vrcholem vpravo, krajní opačně."
    },
    {
        obec: "Horní Blatná",
        kod: 555169,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36235,
        latitude: 50.390493,
        longitude: 12.771048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Horni_Blatna_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině tři vodorovné pruhy, červený, bílý a červený v poměru 3:2:3. Ve vlající červené polovině kráčející bílý lev obrácený k vlajícímu okraji nad kosmo vztyčenými ryžovacími hráběmi šikmo podloženými kopáčem, oboje bílé na žlutých násadách. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Horní Bludovice",
        kod: 598178,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73937,
        latitude: 49.749749,
        longitude: 18.436881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Horn%C3%AD_Bludovice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list uprostřed se žlutým obilným snopem mezi dvěma bílými přivrácenými srpy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný snop provázený dvěma přivrácenými stříbrnými srpy."
    },
    {
        obec: "Horní Bojanovice",
        kod: 584461,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.949805,
        longitude: 16.800252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Znak_obce_Horn%C3%AD_Bojanovice.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů: červený, bílý, černý, bílý, černý, bílý a červený v poměru 2:1:1:3:1:1:2, na středu modrý kosočtverec dotýkající se vnitřních okrajů červených pruhů, s bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít, v horním poli stříbrný kráčející lev se zlatou zbrojí držící zlatý vinný hrozen. V dolním poli modrý hrot se stříbrnou lilií provázený vpravo šikmým a vlevo kosmým pruhem."
    },
    {
        obec: "Horní Bradlo",
        kod: 571474,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53953,
        latitude: 49.802435,
        longitude: 15.744393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Horn%C3%AD_Bradlo_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu polovina žlutého zubra ve skoku s kruhem v nozdrách a s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vlevo ze stříbrné vyrůstající skály vyskakující zubr s kruhem v nozdrách, obojí zlaté, s červeným jazykem, šikmo podložený vztyčenou sklářskou píšťalou a provázený v pravém rohu osmihrotou hvězdou, obojí stříbrné."
    },
    {
        obec: "Horní Branná",
        kod: 577120,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51236,
        latitude: 50.608357,
        longitude: 15.571571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Horn%C3%AD_Brann%C3%A1_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu žlutý lev obrácený k vlajícímu okraji, držící žlutou kouli postrkanou třemi (2,1) bílými pštrosími pery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácený zlatý lev držící zlatou kouli postrkanou třemi (2,1) stříbrnými pštrosími pery."
    },
    {
        obec: "Horní Brusnice",
        kod: 579238,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54474,
        latitude: 50.473162,
        longitude: 15.68114
    },
    {
        obec: "Horní Břečkov",
        kod: 594075,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.890055,
        longitude: 15.898559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Horni_Breckov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, každý široký jednu osminu délky listu, a tři vodorovné pruhy, žlutý, zelený a žlutý. V horním žlutém pruhu kosmo modrá dřevorubecká pila s černými držadly, šikmo podložená černou ostrví, v zeleném pruhu žlutá lilie a v dolním žlutém pruhu černá kotva se dvěma příčnými rameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve zlatém poli vedle sebe kosmo položená modrá dřevorubecká pila s černými držadly šikmo podložená černou ostrví a černá kotva se dvěma příčnými rameny, dolní pole zeleno-červeně polceno, vpravo zlatá lilie, vlevo stříbrné břevno."
    },
    {
        obec: "Horní Bříza",
        kod: 558885,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33012,
        latitude: 49.84023,
        longitude: 13.355689,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Znak_m%C4%9Bsta_Horn%C3%AD_B%C5%99%C3%ADza.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - bílý, červený a zelený v poměru 2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít polcený zúženým červeným kůlem. V pravém stříbrném poli vykořeněná bříza přirozených barev, levé pole stříbrno-zeleně šachované."
    },
    {
        obec: "Horní Bukovina",
        kod: 535834,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.543257,
        longitude: 14.926231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Horn%C3%AD_Bukovina_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, žlutý a pět střídavě červených a bílých, v poměru 5 : 1 : 1 : 1 : 1 : 1. V žerďové a střední části žlutého pruhu dva zelené šikmo vztyčené bukové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně sníženě děleném štítě nahoře vykořeněný buk přirozené barvy, dole dvě stříbrná břevna."
    },
    {
        obec: "Horní Cerekev",
        kod: 547913,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39403,
        latitude: 49.320362,
        longitude: 15.327794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Horn%C3%AD_Cerekev_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s kosmým bílým pruhem vycházejícím z první třetiny horního okraje do třetí třetiny dolního okraje listu, překrytým šikmým žlutým pruhem vycházejícím z první třetiny dolního okraje do třetí třetiny horního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě čelně hledící kančí hlava přirozené barvy, nad ní kosmo položené stříbrné vidle - podávky křížem přeložené zlatým klíčem zuby dolů."
    },
    {
        obec: "Horní Čermná",
        kod: 580279,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56156,
        latitude: 49.970628,
        longitude: 16.607822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Horn%C3%AD_%C4%8Cermn%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové a bílé vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. V pravém poli stříbrná postavená zavřená kniha, v levém poli vykořeněná lípa přirozených barev."
    },
    {
        obec: "Horní Domaslavice",
        kod: 512192,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.694745,
        longitude: 18.461438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Znak_obce_Horn%C3%AD_Domaslavice.gif",
        coatOfArmsFlagDescription: "Zelený list s pěti žlutými svázanými obilnými klasy podloženými zkříženými bílými cepy v horním žerďovém rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva stříbrné překřížené cepy přeložené pěti zlatými svázanými obilnými klasy."
    },
    {
        obec: "Horní Dubenky",
        kod: 587168,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58852,
        latitude: 49.259681,
        longitude: 15.317105,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Horn%C3%AD_Dubenky_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým dubem s bílým kalichem mezi odkloněnými větvemi. Pod dubem zkřížené žluté sklářské píšťaly s bílou sklovinou, provázené třemi (2, 1) odvrácenými žlutými obilnými klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý dub se stříbrným kalichem mezi odkloněnými větvemi. Pod dubem zkřížené zlaté sklářské píšťaly se stříbrnou sklovinou, provázené třemi (2, 1) odvrácenými zlatými obilnými klasy."
    },
    {
        obec: "Horní Dubňany",
        kod: 594083,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67173,
        latitude: 49.06346,
        longitude: 16.201379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Horn%C3%AD_Dub%C5%88any2_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a červený. V bílém pruhu zelený vztyčený dubový list nad červeným tlapatým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě uťatý dub přirozené barvy nad červeným tlapatým křížem, vše provázeno dvěma červenými šestilistými květy se stříbrnými středy."
    },
    {
        obec: "Horní Dunajovice",
        kod: 594091,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67134,
        latitude: 48.950344,
        longitude: 16.161627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Horn%C3%AD_Dunajovice_-_CoA.png"
    },
    {
        obec: "Horní Dvořiště",
        kod: 545503,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38293,
        latitude: 48.603883,
        longitude: 14.405822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Horni_Dvoriste_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Červený list; uprostřed bílá věž s cimbuřím a prázdnou bránou s černou mříží. Nad branou mezi dvěma černými obdélníkovými kladkovými otvory červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Horní Habartice",
        kod: 545929,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.762175,
        longitude: 14.340721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Horn%C3%AD_Habartice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé žerďové pruhy, bílý a černý, každý široký jednu čtvrtinu délky listu, a dva vodorovné pruhy, bílý a modrý. V žerďové polovině listu žlutá kančí hlava s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-černě polceném štítě se stříbrno-modře vlnitě děleným lemem zlatá kančí hlava s červenou zbrojí."
    },
    {
        obec: "Horní Heřmanice",
        kod: 590631,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.329454,
        longitude: 15.920489,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Horn%C3%AD_He%C5%99manice_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. V červeném pruhu bílá zavinutá střela hrotem k žerďovému okraji, z pruhu vyrůstá žlutý lev s červenou zbrojí držící vztyčené bílé kopí se žlutým hrotem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro - červeně dělený štít. Nahoře vyrůstající zlatý lev s červenou zbrojí, držící vztyčené stříbrné kopí se zlatým hrotem. Dole položená stříbrná zavinutá střela."
    },
    {
        obec: "Horní Heřmanice",
        kod: 580295,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56133,
        latitude: 49.961095,
        longitude: 16.711296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Horn%C3%AD_He%C5%99manice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený v poměru 2:1. V bílém pruhu červený středový kříž s rameny širokými jednu desetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zelenou patou s položenou stříbrnou radlicí červený heroldský kříž se stříbrnou rozkřídlenou holubicí. Kříž je podložen zkříženými modrými květy lnu na zelených stoncích s listy."
    },
    {
        obec: "Horní Jelení",
        kod: 574996,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53374,
        latitude: 50.049109,
        longitude: 16.084069,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Horn%C3%AD_Jelen%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 7:16:7. V červeném pruhu bílá hlava jelena - šestnácteráka s krkem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Horní Jiřetín",
        kod: 567175,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43543,
        latitude: 50.5732,
        longitude: 13.547284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Znak_obce_Horn%C3%AD_Ji%C5%99et%C3%ADn.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým klínem a zelenou žerďovou vidlicí vycházející z první a páté pětiny žerďového okraje s vrcholem ve středu listu, oddělenou od modrých polí úzkým bílým lemem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Horní Kalná",
        kod: 579254,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54371,
        latitude: 50.562856,
        longitude: 15.62487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Horni_Kalna_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, bílý a pilovitý modrý se třemi zuby, v poměru 2:2:9:3. Krajní zuby pruhu sahají do poloviny šířky listu, prostřední vyšší do třetí osminy šířky listu. Mezery mezi prostředním a krajními zuby sahají do páté osminy šířky listu. Uprostřed modrého pruhu kosmo mlátek, šikmo podložený kladivem, oboje bílé se žlutými násadami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zvýšeným červeným vlnitým břevnem modré hrotité trojvrší, v něm kosmý mlátek šikmo podložený kladivem, obojí stříbrné se zlatými topůrky."
    },
    {
        obec: "Horní Kamenice",
        kod: 553662,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.569701,
        longitude: 13.101163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/CoA_of_Horn%C3%AD_Kamenice_%28okres_Doma%C5%BElice%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Přes ně jsou položeny tři vodorovné pruhy, žlutý vycházející z druhé osmnáctiny žerďového okraje, žlutý oboustranně posunutý zubatý vycházející z desáté až dvanácté osmnáctiny žerďového okraje a bílý vycházející ze sedmnácté osmnáctiny žerďového okraje. Zubatý pruh má nahoře šest zubů a pět mezer, dole pět zubů a šest mezer, nad ním žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlaté cimbuřové břevno po stranách podložené zlatým obilným klasem, každý se dvěma listy, provázené nahoře zlatou lilií, dole stříbrno-červeně polcenou růží se zlatým semeníkem."
    },
    {
        obec: "Horní Kněžeklady",
        kod: 536105,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.193834,
        longitude: 14.481489,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Horni_Knezeklady_znak.jpeg",
        coatOfArmsFlagDescription: "Žlutý list. V žerďové části tři položené zelené dubové listy pod sebou řapíky k žerďovému okraji, ve střední a vlající části polovina černého koně s červenými kopyty a uzděním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě polovina černého koně s červenými kopyty a uzděním, provázená třemi zelenými dubovými listy, nahoře vztyčenými a odvrácenými, dole položeným."
    },
    {
        obec: "Horní Kounice",
        kod: 594105,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 49.026094,
        longitude: 16.152366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Horn%C3%AD_Kounice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a pilovitý zelený, v poměru 5 : 1. V červeném pruhu bílý středový kříž vycházející z druhé čtvrtiny žerďového a vlajícího okraje listu. Zelený pruh má šest zubů s vrcholy v třetí čtvrtině šířky listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Horní Kozolupy",
        kod: 541290,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34952,
        latitude: 49.837622,
        longitude: 12.941751,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Horni_kozolupy_znak.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list; horní žerďové pole žluté, horní vlající a dolní žerďové pole červená, dolní vlající pole modré. V žerďové polovině listu hnědý džbán se dvěma uchy mezi černým jelením parožím.Ve vlající polovině listu bílá labuť se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít. V pravém zlatém poli nad červenou mříží hliněný džbán se dvěma uchy přirozené barvy mezi černým jelením parožím. Levé pole červeno-modře dělené, nahoře stříbrná labuť se zlatou zbrojí, dole postavený stříbrný meč se zlatým jílcem mezi dvěma vztyčenými odvrácenými gotickými klíči, pravým zlatým a levým stříbrným."
    },
    {
        obec: "Horní Krupá",
        kod: 568708,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.672094,
        longitude: 15.588589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Horn%C3%AD_Krup%C3%A1_coat_of_arms.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, vlnitý zelený, vlnitý žlutý a zvlněný zelený, v poměru 7 : 1 : 1 : 7. V horním pruhu vedle sebe zkřížené ostrve a hornická kladívka, vše černé. V dolním pruhu kalich provázený po stranách dvěma šesticípými hvězdami, na žerďové kosmo, na vlající šikmo, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně dělený štít zeleno-zlatě vlnitě děleným vlnitým břevnem. Nahoře vpravo zkřížené ostrve, vlevo hornická kladívka, vše černé, dole kalich mezi čtyřmi (2, 2) hvězdami, vše zlaté."
    },
    {
        obec: "Horní Kruty",
        kod: 533327,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28146,
        latitude: 49.919546,
        longitude: 14.959813,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Horn%C3%AD_Kruty_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 2 : 1. Ze zeleného pruhu, s pěti čelně postavenými bílými radlicemi vedle sebe, vyrůstá černá plamenná orlice se žlutou zbrojí a jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně dělený štít. Nahoře vyrůstá plamenná orlice se zlatou zbrojí a jetelovitě zakončeným perizoniem, dole pět (3, 2) čelně postavených stříbrných radlic."
    },
    {
        obec: "Horní Lapač",
        kod: 588474,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.301023,
        longitude: 17.617522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Horn%C3%AD_Lapa%C4%8D_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý. Přes horní části červeného a modrého pruhu zkřížené bílé šavle se žlutými jílci, hroty a ostřím k hornímu okraji listu, v dolní části červeného pruhu bílý tlapatý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě zlaté, kosmo nakloněné vědro s uchem a stříbrnou vytékající vodou, vpravo provázené stříbrným tlapatým křížkem a převýšené zkříženými vztyčenými stříbrnými šavlemi se zlatými jílci, ostřím nahoru."
    },
    {
        obec: "Horní Lhota",
        kod: 569500,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74764,
        latitude: 49.853142,
        longitude: 18.068485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Horn%C3%AD_Lhota_%28Ostrava-m%C4%9Bsto%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý, v poměru 4:1. Ze žlutého pruhu vyrůstá bílá lípa, provázená dvěma žlutými radlicemi hrotem nahoru, v žerďové části ostřím k žerdi, ve vlající části ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze zlatého návrší vyrůstající stříbrná lípa, provázená dole dvěma vztyčenými odvrácenými zlatými radlicemi."
    },
    {
        obec: "Horní Lhota",
        kod: 585246,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76323,
        latitude: 49.154412,
        longitude: 17.80455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Horn%C3%AD_Lhota_ZL_CoA_CZ.JPG",
        coatOfArmsFlagDescription: "Červeno-modrý, šikmým cimbuřovým bílým břevnem dělený list, v horním žerďovém rohu dvě dvakrát zkřížené žluté lipové snítky, každá s jedním listem a plodenstvím. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě vyrůstá nahoře zlatovlasý anděl ve stříbrném šatě se zlatým pásem a lemy rukávů, s červenými křídly, každé se třemi stříbrnými cimbuřovými břevny, dole dvě dvakrát zkřížené zlaté lipové snítky, každá s jedním listem a plodenstvím."
    },
    {
        obec: "Horní Libchava",
        kod: 561592,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47111,
        latitude: 50.712584,
        longitude: 14.493538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Horn%C3%AD_Libchava_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým žlutým polem a dva vodorovné pruhy, žlutý a červený. V karé bílá svatojakubská mušle podložená žlutými zkříženými poutnickými holemi, ve žlutém žerďovém poli dvě černé zkřížené ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo stříbrná svatojakubská mušle podložená zkříženými zlatými poutnickými holemi, vlevo zkřížené černé ostrve."
    },
    {
        obec: "Horní Libochová",
        kod: 595659,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.407729,
        longitude: 16.148981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Horn%C3%AD_Libochov%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. V modrém pruhu roubená zvonice se zavěšeným zvonem v prázdném okně, zakončená makovicí s bílým praporkem s vlaštovčím ocasem k žerďovému okraji listu, nahoře provázená dvěma odkloněnými lipovými listy, vše žluté. Pod listy po jedné žluté včele s bílými křídly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá roubená zvonice se zvonem zavěšeným v prázdné lucerně, zakončená makovicí se stříbrným praporkem s vlaštovčím ocasem, nahoře provázená dvěma vztyčenými odkloněnými zlatými lipovými listy, po stranách dvěma zlatými včelami se stříbrnými křídly."
    },
    {
        obec: "Horní Lideč",
        kod: 542725,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.181314,
        longitude: 18.061103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/CZ_Horn%C3%AD_Lide%C4%8D_COA.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři svislé pruhy - bílý, zelený, bílý. V žerďovém bílém je svatováclavská černá orlice (s červenými plaménky), ve vlajícím (rovněž bílém pruhu) je zelený vykořeněný listnatý strom; výška orlice a stromu je rovna polovině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, v pravém poli postava sv. Václava v přirozených barvách, v levém poli zelený listnatý vykořeněný strom."
    },
    {
        obec: "Horní Loděnice",
        kod: 569798,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78305,
        latitude: 49.771108,
        longitude: 17.37728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Znak_obce_Horn%C3%AD_Lod%C4%9Bnice.gif",
        coatOfArmsFlagDescription: "Kosmo dělený list se zelenou žerďovou a žlutou vlající částí, uprostřed bílá radlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně kosmo děleném štítě stříbrná radlice."
    },
    {
        obec: "Horní Lomná",
        kod: 511986,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73991,
        latitude: 49.527977,
        longitude: 18.637844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Horn%C3%AD_Lomn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část s polovinou slezské orlice přiléhající k modré vlající části s vodorovným pětkrát lomeným bílým pruhem o třech vrcholech širokým jednu šestnáctinu šířky listu. Z pruhu vyrůstá žlutý jehličnatý strom, v dolní části žlutý latinský jetelový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít, vpravo vyniká půl slezské orlice, vlevo stříbrné pětkrát lomené břevno, provázené nahoře vyrůstajícím jehličnatým stromem, dole latinským jetelovým křížem, obojí zlaté."
    },
    {
        obec: "Horní Loučky",
        kod: 595667,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.366906,
        longitude: 16.341499,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Horn%C3%AD_Lou%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený obloukový, bílý obloukový a zelený vypouklý s vrcholem v polovině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě v zeleném zvýšeném návrší vztyčené krojidlo a radlice, obojí stříbrné a doleva obrácené, nahoře zelené obloukové břevno nahoru."
    },
    {
        obec: "Horní Lukavice",
        kod: 539821,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.612427,
        longitude: 13.32708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Horn%C3%AD_Lukavice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým pětkrát lomeným pruhem vycházejícím ze čtvrté a páté jedenáctiny horního a dolního okraje listu. Ve vlající polovině listu žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá lilie převýšená stříbrným, pětkrát lomeným břevnem."
    },
    {
        obec: "Horní Maršov",
        kod: 579262,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54226,
        latitude: 50.658659,
        longitude: 15.819859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Horn%C3%AD_Mar%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené karé na stejně velkým žlutým polem a dva vodorovné pruhy, žlutý a zelený. V karé turnajský helm se žluto-bílými přikrývadly. V klenotu vyrůstající žlutá postava Spravedlnosti držící v pravici bílý meč hrotem nahoru se žlutým jílcem a v levici žluté miskové váhy. Na hlavě má bílý turnajský helm s bílo-žlutou točenicí a třemi červenými pštrosími pery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě turnajský helm se zlato-stříbrnými přikrývadly, v klenotu vyrůstající zlatá postava Spravedlnosti držící v pravici vztyčený stříbrný meč se zlatým jílcem a v levici zlaté miskové váhy. Na hlavě má stříbrný turnajský helm se stříbrno-zlatou točenicí a třemi červenými pštrosími pery."
    },
    {
        obec: "Horní Město",
        kod: 597368,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79344,
        latitude: 49.908546,
        longitude: 17.211221,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Horn%C3%AD_M%C4%9Bsto_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 3:1. Na zeleném pruhu stojí černý havran s červenou zbrojí podložený zkříženými černými hornickými kladívky s hnědými násadami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší černý havran s červenou zbrojí podložený zkříženými černými hornickými kladívky na násadách přirozené barvy."
    },
    {
        obec: "Horní Meziříčko",
        kod: 598658,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.156684,
        longitude: 15.244609,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Coat_of_arms_of_Horn%C3%AD_Mezi%C5%99%C3%AD%C4%8Dko.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 1 : 1 : 6 : 1 : 1. V prostředním pruhu žlutá růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže mezi dvěma zúženými stříbrnými vlnitými břevny."
    },
    {
        obec: "Horní Moštěnice",
        kod: 513491,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75117,
        latitude: 49.412225,
        longitude: 17.4589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Horni_Mostenice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi, v poměru 7:2. V zeleném pruhu v žerďové polovině rozletitá střela, ve vlající polovině lilie, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad dvěma stříbrnými vlnitými břevny vpravo rozletitá střela, vlevo lilie, obojí zlaté."
    },
    {
        obec: "Horní Myslová",
        kod: 587206,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.165516,
        longitude: 15.422663
    },
    {
        obec: "Horní Němčí",
        kod: 592188,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68764,
        latitude: 48.933273,
        longitude: 17.624276,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Znak_obce_Horn%C3%AD_N%C4%9Bm%C4%8D%C3%AD.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, oboustranně zubatý modrý a bílý, v poměru 1:2:1. Modrý má po třech obdélníkových zubech, dosahujících do poloviny bílých pruhů, a po čtyřech stejných mezerách. Uprostřed vztyčené hrábě kosmo přeložené gotickým klíčem zuby dolů a k vlajícímu okraji a šikmo podložené mečem hrotem k dolnímu okraji, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou cimbuřovou hlavou vztyčené hrábě kosmo přeložené postaveným gotickým klíčem zuby nahoru a šikmo podložené postaveným mečem, vše zlaté."
    },
    {
        obec: "Horní Němčice",
        kod: 562785,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.1579,
        longitude: 15.27978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Horn%C3%AD_N%C4%9Bm%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se dvěma dotýkajícími se bílými horními rovnoramennými trojúhelníky, v žerďovém kosmo vztyčený lipový list ve vlajícím šikmo vztyčený leknínový list. V zeleném poli kosmo bílé polní brány. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrnými kouty kosmo stříbrné polní brány. V koutech vztyčené zelené listy, vpravo kosmo lipový, vlevo šikmo leknínový."
    },
    {
        obec: "Horní Nětčice",
        kod: 569275,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.470245,
        longitude: 17.684498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Horn%C3%AD_N%C4%9Bt%C4%8Dice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - zelený, bílý a žlutý, v poměru 1:2:1. V bílém poli červený rovný kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním zeleném poli stříbrný beránek Boží s korouhví, v dolním zlato-červeně děleném poli zkřížené stříbrné vinařské nože se zlatými rukojeťmi."
    },
    {
        obec: "Horní Olešnice",
        kod: 579271,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54371,
        latitude: 50.53276,
        longitude: 15.677502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Horn%C3%AD_Ole%C5%A1nice.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený s bílým vlnitým pruhem na dolním okraji listu, širokým čtvrtinu šířky listu, a bílý s červeným plamenným pruhem se čtyřmi plameny na dolním okraji listu, širokým čtvrtinu šířky listu. Na styku zeleného a bílého pruhu vyrůstá z dělení olšová větev se čtyřmi listy opačných barev, pod ní v bílém poli červený broušený český granát, v červeném bílá růže s červeným semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě olšová větev se čtyřmi listy opačných barev vyrůstající ze stříbrno-červeně polcené paty, vpravo vlnité a vlevo planoucí čtyřmi plameny. V patě vpravo červený broušený český granát, vlevo stříbrná růže s červeným semeníkem."
    },
    {
        obec: "Horní Paseka",
        kod: 548243,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.629757,
        longitude: 15.293283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Horn%C3%AD_Paseka.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, oboustranně vlnkovitý bílý a vlnkovitý zelený, v poměru 1 : 1 : 6. V zeleném pruhu tři bílé smrky, prostřední větší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod červeno-stříbrně vlnkovitě dělenou vlnkovitou hlavou tři stříbrné smrky, prostřední větší."
    },
    {
        obec: "Horní Pěna",
        kod: 546364,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37831,
        latitude: 49.104515,
        longitude: 15.042397,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Horn%C3%AD_P%C4%9Bna_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří vodorovné pruhy, bílý a sedm střídavě modrých a žlutých, v poměru 7:1:1:1:1:1:1:1. Ve střední části listu černý medvěd ve skoku s červeným jazykem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě doleva obrácený černý medvěd ve skoku s červeným jazykem přidržující pravou půli modrého štítu se třemi zlatými břevny."
    },
    {
        obec: "Horní Planá",
        kod: 545511,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38226,
        latitude: 48.767465,
        longitude: 14.032672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Horn%C3%AD_Plan%C3%A1_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený vypouklý, v poměru 9 : 1. Přes ně sedící vztyčený hnědý medvěd s červeným jazykem a bílými zuby držící červenou růži se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným návrším sedící vztyčený medvěd přirozené barvy držící červenou růži se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Horní Podluží",
        kod: 562505,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40757,
        latitude: 50.881485,
        longitude: 14.545731,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Horn%C3%AD_Podlu%C5%BE%C3%AD_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, bílý, červený a zelený. Uprostřed pod sebou tři růže opačných barev se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zeleném poli zlatá přeslice, v levém stříbrno-červeně polceném poli pod sebou tři růže opačných barev se zlatými semeníky."
    },
    {
        obec: "Horní Police",
        kod: 561606,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47106,
        latitude: 50.704219,
        longitude: 14.404106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Znak_obce_Horn%C3%AD_Police.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 3:2:3. V žerďové části horního pruhu vyrůstá ze středního pruhu žlutý kříž, pod ním v dolním modrém pruhu bílá položená dvojitá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý kvádrový most se dvěma oblouky a vyrůstajícím zlatým latinským křížem, pod ním položená stříbrná dvojitá lilie."
    },
    {
        obec: "Horní Poříčí",
        kod: 536881,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.286063,
        longitude: 13.782924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/CoA_of_Horn%C3%AD_Po%C5%99%C3%AD%C4%8D%C3%AD_%28okres_Strakonice%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý. V žerďové části zeleného pruhu bílý maltézský kříž, pod ním v modrém pruhu žluté kolo se čtyřmi rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě vpravo stříbrný maltézský kříž, vlevo zlaté kolo se čtyřmi rameny."
    },
    {
        obec: "Horní Počaply",
        kod: 534790,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27703,
        latitude: 50.424505,
        longitude: 14.390039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Horn%C3%AD_Po%C4%8Daply_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modro-červeně polcený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, žlutý a zelený. V žerďovém pruhu bílá lilie převýšená žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém modro-červeně polceném poli stříbrná lilie převýšená zlatou korunou, v levém zeleném poli čtyřikrát zalomený šikmý zlatý blesk."
    },
    {
        obec: "Horní Poříčí",
        kod: 581631,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.587696,
        longitude: 16.477436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/CZ_Horn%C3%AD_Po%C5%99%C3%AD%C4%8D%C3%AD_BK_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a vlnitý žlutý se čtyřmi vrcholy, v poměru 1 : 3. Na dolním okraji listu obloukové zelené pole dosahující do čtvrtiny šířky listu. Ve žlutém pruhu červené srdce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou vlnitou hlavou červené srdce nad zeleným návrším."
    },
    {
        obec: "Horní Radechová",
        kod: 574066,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54946,
        latitude: 50.46743,
        longitude: 16.144576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Horn%C3%AD_Radechov%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, červený, bílý a modrý, v poměru 6:1:1:6. Uprostřed listu bílo-červeně polcená orlice se žlutou zbrojí a jetelovitě zakončeným perizoniem. Orlice, bílý a červený pruh jsou provlečeny žlutým tkalcovským člunkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červeno-stříbrně polcený kůl, přes něj stříbrno-červeně polcená orlice se zlatou zbrojí a jetelovitě zakončeným perizoniem. V dolní části kůl a orlice provlečeny zlatým tkalcovským člunkem."
    },
    {
        obec: "Horní Radouň",
        kod: 546381,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37843,
        latitude: 49.25718,
        longitude: 15.005828
    },
    {
        obec: "Horní Rápotice",
        kod: 551589,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.575426,
        longitude: 15.320586,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Horn%C3%AD_R%C3%A1potice_-_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v každém kříž opačné barvy s rameny širokými desetinu šířky listu. Ramena křížů vycházejí z třetí a osmé desetiny žerďového a vlajícího okraje a čtvrté patnáctiny horního a dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě nahoře nad vlnitým, dvakrát vlnitě děleným břevnem dva heroldské kříže, dole sedmihrotá hvězda, vše opačných barev."
    },
    {
        obec: "Horní Rožínka",
        kod: 595683,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.5001,
        longitude: 16.193,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Horn%C3%AD_Ro%C5%BE%C3%ADnka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený se žlutou hlavicí berly závitem k vlajícímu okraji a zelený se žlutým obilným klasem se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo hlavice berly závitem dovnitř, vlevo obilný klas se dvěma listy, obojí zlaté."
    },
    {
        obec: "Horní Řasnice",
        kod: 564079,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.965564,
        longitude: 15.201599,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Horni_rasnice_znak.png",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. V bílém poli modrý šikmý pruh vycházející z druhé sedminy dolního okraje do druhé sedminy vlajícího okraje listu. V žerďové polovině listu ledňáček s červenou zbrojí, držící v zobáku za hlavu přivráceného bílého pstruha. V dolním cípu listu modrá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrné skále s modrou lilií sedící ledňáček přirozené barvy s červenou zbrojí, držící v zobáku stříbrného pstruha."
    },
    {
        obec: "Horní Ředice",
        kod: 575011,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53375,
        latitude: 50.076611,
        longitude: 15.958886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Horn%C3%AD_%C5%98edice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy v poměru 3:1:1:1, zvlněný zelený se třemi žlutými klasy v žerďové části, vlnitý žlutý, vlnitý modrý a zvlněný žlutý. Pruhy mají dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě šikmo dělený štít, nahoře tři zlaté obilné klasy, dole modré vlnité břevno."
    },
    {
        obec: "Horní Radslavice",
        kod: 595675,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.338339,
        longitude: 15.902364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Horn%C3%AD_Radslavice.png",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. V bílém poli červený k hornímu rohu posunutý kříž, do jehož svislého ramene vyniká žluté křížové kopí. V modrém poli bílý zvon se žlutým srdcem ve tvaru kříže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře šikmo dělený štít. Nahoře v červeném heroldském kříži vynikající zlaté křížové kopí, dole stříbrný zvon se zlatým srdcem ve tvaru kříže."
    },
    {
        obec: "Horní Řepčice",
        kod: 546763,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.556722,
        longitude: 14.253325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Horn%C3%AD_%C5%98ep%C4%8Dice_-_CoA.png",
        coatOfArmsFlagDescription: "Bílý list, v horní polovině červená řepa se čtyřmi zelenými listy mezi dvěma vyrůstajícími odkloněnými zelenými větvemi jeřábu oskeruše, každá se dvěma vztyčenými zelenými listy nad sebou a dole se čtyřmi žlutými plody, dva horní vztyčené, dva dolní svěšené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nahoře červená řepa se čtyřmi zelenými listy mezi dvěma vyrůstajícími odkloněnými zelenými větvemi jeřábu oskeruše, každá se dvěma vztyčenými zelenými listy nad sebou a dole se čtyřmi zlatými plody, dva horní vztyčené, dva dolní svěšené."
    },
    {
        obec: "Horní Skrýchov",
        kod: 561061,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.172879,
        longitude: 15.028473
    },
    {
        obec: "Horní Slatina",
        kod: 562319,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.090577,
        longitude: 15.568834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Horn%C3%AD_Slatina_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený, v poměru 7 : 1 : 2. V žerďové polovině horního pruhu bílá radlice ostřím k žerdi a žlutý šíp s bílým hrotem a opeřením, obojí hroty k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým zlatým břevnem vedle sebe stříbrná radlice a zlatý šíp se stříbrným hrotem a opeřením, obojí vztyčené."
    },
    {
        obec: "Horní Slavkov",
        kod: 560367,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35731,
        latitude: 50.138742,
        longitude: 12.807697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Coat_of_arms_of_Horn%C3%AD_Slavkov.jpg",
        coatOfArmsFlagDescription: "Bílo - červeně čtvrcený list, uprostřed zelené čtvercové pole široké polovinu šířky listu s bílými hornickými kladívky na žlutých topůrkách, přeloženými žlutými vztyčenými hráběmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Čtvrcený štít. V prvním červeno - stříbrně děleném poli dvouocasý korunovaný lev opačných barev se zlatou zbrojí. Ve druhém červeném poli dvakrát stříbrno - červeně polcené břevno. Ve třetím zlatém poli doleva obrácený černý lev s červenou zbrojí. Ve čtvrtém zeleném poli stříbrná hornická kladívka na zlatých topůrkách přeložená vztyčenými zlatými hráběmi."
    },
    {
        obec: "Horní Slivno",
        kod: 599531,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29479,
        latitude: 50.304132,
        longitude: 14.704984,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Horn%C3%AD_Slivno_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 2:1:1. V žerďové polovině horního bílého pruhu svěšená zelená větev se dvěma listy a třemi modrými švestkami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě běžící liška přirozené barvy nad svěšenou zelenou větví se dvěma listy a třemi modrými švestkami."
    },
    {
        obec: "Horní Smrčné",
        kod: 550612,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.32869,
        longitude: 15.756199
    },
    {
        obec: "Horní Smržov",
        kod: 513695,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.618358,
        longitude: 16.577085,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Horn%C3%AD_Smr%C5%BEov_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se žerďovým a vlajícím červeným klínem s vrcholy sahajícími do jedné třetiny délky listu. Uprostřed květ modré macešky se zeleným středem a stonkem s listy. V klínech po jedné žluté šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě čelně vztyčená stříbrná radlice s modrým lněným květem se zeleným středem a stonkem, provázená dvěma zlatými hvězdami."
    },
    {
        obec: "Horní Stropnice",
        kod: 544515,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37335,
        latitude: 48.761347,
        longitude: 14.735114,
        coatOfArmsFlagDescription: "Bílý list s červenou růží se žlutým semeníkem a zelenými kališními lístky a se dvěma červenými trojúhelníky. Žerďový, s vrcholy v horním rohu a uprostřed žerďového a horního okraje. Vlající, s vrcholy v dolním cípu a uprostřed vlajícího a dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Horní Studénky",
        kod: 535770,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.952757,
        longitude: 16.811723,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Horn%C3%AD_Stud%C3%A9nky%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 9 : 1 : 6. V žerďové polovině zeleného pruhu bílá radlice hrotem k hornímu a ostřím k vlajícímu okraji listu, provázená po stranách pěti ( 2, 3 do oblouku) žlutými osmicípými hvězdami. V modrém pruhu pod radlicí vyrůstá z dolního okraje listu hlavice žluté berly se závitem zakončeným lilií a obráceným k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrným zúženým vlnitým břevnem dělený štít. Nahoře doleva obrácená vztyčená stříbrná radlice provázená po stranách pěti zlatými osmihrotými hvězdami (2, 3), dole vyrůstající zlatá berla se závitem zakončeným lilií přerušující zlatý řetěz vynikající z okrajů."
    },
    {
        obec: "Horní Suchá",
        kod: 552739,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73535,
        latitude: 49.797901,
        longitude: 18.481997,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Sucha_Gorna_herb.svg",
        coatOfArmsFlagDescription: "List tvoří bílé žerďové čtvrtkruhové pole sahající do dvou třetin horního okraje. Z poslední třetiny horního okraje vychází zelené pole vymezené čtvrtinou elipsy se středem v horním rohu a končící v dolním rohu listu. Zbytek listu je černý. Do bílého pole vyrůstá kosmo borovice se zelenou korunou a černým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku borovice přirozených barev."
    },
    {
        obec: "Horní Štěpánov",
        kod: 589519,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79847,
        latitude: 49.549106,
        longitude: 16.790882,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Horn%C3%AD_%C5%A0t%C4%9Bp%C3%A1nov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. V červeném vztyčený rošt, v zeleném čelně radlice hrotem k hornímu okraji listu nad krojidlem hrotem k dolnímu a ostřím k žerďovému okraji listu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo vztyčený rošt, vlevo nahoře čelně vztyčená radlice, dole krojidlo, vše zlaté."
    },
    {
        obec: "Horní Těšice",
        kod: 552968,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.495759,
        longitude: 17.786086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Znak_obce_Horn%C3%AD_T%C4%9B%C5%A1ice.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, červený, bílý, černý, bílý, černý, bílý, černý, bílý a červený, v poměru 2:2:2:1:2:1:2:2:2. Bílé a černé pruhy jsou překryty modrým žerďovým trojúhelníkem s vrcholy ve druhé a čtrnácté šestnáctině žerďového okraje a druhé šestnáctině vlajícího okraje. V žerďové polovině modrého pole žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se čtyřmi stříbrnými kužely modro-stříbrně šikmo dělený štít, nahoře zlatá koruna, dole tři černá břevna."
    },
    {
        obec: "Horní Tošanovice",
        kod: 552631,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73953,
        latitude: 49.692472,
        longitude: 18.505428,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Horni_tosanovice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a žlutý v poměru 3:1. V žerďové části modrého pruhu váza s vyrůstající lilií, sekerou a cepem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě z vázy vyrůstající lilie mezi sekerou a cepem, vše zlaté."
    },
    {
        obec: "Horní Třešňovec",
        kod: 580333,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.939321,
        longitude: 16.602641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Horni-tresnovec_ok.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, modrý vlnitý, žlutý vlnitý a červený zvlněný. V žerďové polovině horního pruhu žlutá koruna převýšená bílým třešňovým květem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad sníženým modro-zlatě děleným vlnitým břevnem zlatá koruna převýšená stříbrným třešňovým květem."
    },
    {
        obec: "Horní Újezd",
        kod: 590649,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.142893,
        longitude: 15.842256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Horni_Ujezd_TR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 2:1:1. V žerďové polovině horního pruhu bílá špičatá skála. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno, nahoře stříbrná špičatá skála, dole kosmo položený zlatý klíč zuby vzhůru a ven, šikmo přeložený stříbrným vztyčeným mečem se zlatou rukojetí a záštitou."
    },
    {
        obec: "Horní Újezd",
        kod: 572365,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.803578,
        longitude: 16.233042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Horn%C3%AD_%C3%9Ajezd_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, žlutý a zelený, v poměru 5:2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě rozsévač s černým kloboukem a botami, stříbrnou košilí a punčochami a zlatými spodky sedící doleva na pařezu přirozené barvy na zeleném trávníku."
    },
    {
        obec: "Horní Ves",
        kod: 547930,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.293507,
        longitude: 15.307554,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Horn%C3%AD_Ves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílo-červeně šachovaný (8x4), modrý a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-stříbrně dvěma řadami šachovanou cimbuřovitou hlavou se třemi stříbrnými dolů obrácenými stínkami na šikmém zeleném trávníku zlatý, doleva obrácený pluh."
    },
    {
        obec: "Horní Věstonice",
        kod: 584479,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69181,
        latitude: 48.874651,
        longitude: 16.62566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Coat_of_arms_of_Horni_Vestonice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 7 : 1. Do červeného pruhu vyrůstá bílý keř vinné révy se žlutými hrozny přivázaný ke žlutému kolíku. Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku vyrůstající stříbrný keř vinné révy se zlatými hrozny, přivázaný ke zlatému kolíku."
    },
    {
        obec: "Horní Újezd",
        kod: 569542,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.448387,
        longitude: 17.727285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Horni_Ujezd_PR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, modrý a žlutý v poměru 1:2:1. Uprostřed modrého pole tři bílé lilie (2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný pták s rozevřenými křídly a zlatou zbrojí. Pravé křídlo kosmo, levé šikmo zlato-červeně děleno, na každém stříbrný vinařský nůž se zlatou rukojetí, hroty vně. V horní části tři (1,1,1) stříbrné lilie."
    },
    {
        obec: "Horní Vilémovice",
        kod: 590657,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.29166,
        longitude: 15.880909
    },
    {
        obec: "Horní Vltavice",
        kod: 550205,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38491,
        latitude: 48.957149,
        longitude: 13.758797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Horn%C3%AD_Vltavice_CoA.jpg"
    },
    {
        obec: "Horní Životice",
        kod: 551805,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79312,
        latitude: 49.968984,
        longitude: 17.6409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Horn%C3%AD_%C5%BDivotice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílo-zeleně kosmo šachovaný. V červeném pruhu žlutá berla závitem k žerďovému okraji, dole přeložená bílými zkříženými hornickými kladívky. Vlající pruh tvoří devět zelených a osm bílých čtverců, čtyři bílé trojúhelníky u červeného pruhu a na vlajícím okraji a tři zelené trojúhelníky na horním a dolním okraji pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli zlatá berla závitem ven, přeložená dole dvěma zkříženými stříbrnými hornickými kladívky, levé pole stříbrno-zeleně kosmo šachované."
    },
    {
        obec: "Hornice",
        kod: 590665,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.995287,
        longitude: 15.680464
    },
    {
        obec: "Hornosín",
        kod: 536369,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38742,
        latitude: 49.482857,
        longitude: 13.839873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Hornos%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, červený a plamenný žlutý, v poměru 4 : 7 : 1. Vrcholy pěti vodorovných plamenů dosahují do třetiny délky listu. Ve střední části žlutá putýnka s modrou vodou, uchem k plamenům. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-červeně polceném štítě se zlatou plamennou patou s pěti plameny vpravo kosmo stříbrný praporec s ocasem s červeným heroldským křížem na zlaté žerdi, vlevo zlatá putýnka s vodou."
    },
    {
        obec: "Horoměřice",
        kod: 539236,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25262,
        latitude: 50.131831,
        longitude: 14.338894,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Horomerice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modré kosodélníkové pole se třemi žlutými podkovami, položenými nakoso pod sebou, lemované dvěma bílými lichoběžníkovými poli, vymezenými horním žerďovým rohem a dolním vlajícím cípem; modré pole dosahuje vždy do poloviny délky listu. U horního vlajícího cípu červené trojúhelníkové pole sahající do čtvrtiny délky a do poloviny šířky listu. Obdobné červené pole u dolního žerďového rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném koni ve skoku, se zlatými kopyty, uzdou a sedláním, jezdec ve stříbrné košili a červených kalhotách."
    },
    {
        obec: "Horosedly",
        kod: 561525,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.512896,
        longitude: 14.054107,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Coat_of_arms_of_Horosedly.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, zelený, bílý a zelený, v poměru 16 : 3 : 2 : 3. V modrém pruhu bílý patriarší kříž obklopený do kruhu pěti (1, 2, 2) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším se stříbrným vlnitým břevnem stříbrný patriarší kříž obklopený do kruhu pěti (1, 2, 2) zlatými hvězdami."
    },
    {
        obec: "Horoušany",
        kod: 538221,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25082,
        latitude: 50.106504,
        longitude: 14.74068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Horou%C5%A1any_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým kosmým pruhem širokým čtvrtinu délky listu. V žerďovém trojúhelníku bílá hornická kladívka na žlutých násadách, ve vlajícím trojúhelníku bílý mlýnský kámen s položenou černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté kosmé břevno provázené nahoře stříbrným mlýnským kamenem s položenou černou kypřicí, dole stříbrnými hornickými kladívky na zlatých topůrkách."
    },
    {
        obec: "Horská Kvilda",
        kod: 542091,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 38501,
        latitude: 49.057721,
        longitude: 13.558075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Horsk%C3%A1_Kvilda_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a červený. V žerďové polovině žlutého pruhu černá rýžovací pánev se žlutou horninou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlato-zeleně polceném štítě v prostředním poli vykořeněný smrk přirozené barvy nad černou rýžovací pánví se zlatou horninou."
    },
    {
        obec: "Horšice",
        kod: 557722,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33455,
        latitude: 49.531575,
        longitude: 13.386298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Horsice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, zelený, žlutý, červený a bílý v poměru 2:2:1:2:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít se zlatým kůlem. Vpravo tři stříbrné koule pod sebou, vlevo čtyři zleva položené stříbrné klíny."
    },
    {
        obec: "Horšovský Týn",
        kod: 553671,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.530286,
        longitude: 12.944527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Hor%C5%A1ovsk%C3%BD_T%C3%BDn_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se čtvrceným, červeno-bílo-bílo-černým, žerďovým pruhem širokým čtvrtinu délky listu. V modrém poli dva volné bílé hrady, oba s rozšířeným soklem, černou prázdnou bosovanou bránou, nahoře provázenou dvěma okny. Nad branou římsa, z níž vynikají tři spojené věže, prostřední větší, každá s oknem a s červenou stanovou střechou. Všechna okna černá oblouková. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Horušice",
        kod: 531481,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28573,
        latitude: 49.996584,
        longitude: 15.429043
    },
    {
        obec: "Hořany",
        kod: 534854,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28914,
        latitude: 50.09771,
        longitude: 14.945897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Ho%C5%99any_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 7 : 1, zelený a vypouklý bílý s vrcholem ve čtyřech pětinách šířky listu. Z něj vyniká průčelí kaple s prázdnou lucernou zakončenou makovičkou s křížkem a provázenou po obou stranách svěšeným odkloněným lipovým listem s plodenstvím, vše bílé. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na návrší průčelí kaple s prázdnou lucernou zakončenou makovičkou s křížkem a provázenou po obou stranách svěšeným odkloněným lipovým listem s plodenstvím, vše stříbrné."
    },
    {
        obec: "Hory",
        kod: 551651,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.213854,
        longitude: 12.789994,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Hory_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3 : 1. Ze zeleného pruhu vyrůstají dvě přivrácená žlutě obrněná ramena, žerďové držící kladivo, vlající mlátek, obojí bílé na žlutých topůrkách. Uprostřed listu bílý meč se žlutým jílcem s hrotem v zeleném pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zeleného trojvrší dvě přivrácená, zlatě obrněná ramena, pravé drží kladivo, levé mlátek, obojí stříbrné na zlatých topůrkách, uprostřed postavený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Hořátev",
        kod: 537152,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28913,
        latitude: 50.150278,
        longitude: 15.039418,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Coats_of_arms_Ho%C5%99%C3%A1tev.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý s červeným kalichem a červený plamenný, v poměru 11:7. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně plamenným řezem děleném štítě nahoře červený kalich."
    },
    {
        obec: "Hořenice",
        kod: 547531,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.369279,
        longitude: 15.910937,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Ho%C5%99enice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 5 : 3. V červeném pruhu polovina bílého lva se žlutou zbrojí a jazykem držícího žlutý obilný snop. V žerďové a vlající části bílého pruhu modrý vodorovný pruh, široký osminu šířky listu, ve střední části modrý květ lnu s bílým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě polovina stříbrného lva se zlatou zbrojí a jazykem držícího zlatý obilný snop nad zvýšenou stříbrnou cimbuřovou patou s modrým zúženým vlnitým břevnem nad modrým květem lnu se stříbrným středem."
    },
    {
        obec: "Hořepník",
        kod: 547948,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39421,
        latitude: 49.512566,
        longitude: 15.107097,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Coat_of_arms_of_Ho%C5%99epn%C3%ADk.jpg",
        coatOfArmsFlagDescription: "Modrý list, ve střední části volná bílá okrouhlá kvádrovaná věž se třemi (1, 2) černými okny, červenou kuželovou střechou zakončenou makovicí s praporkem s vlaštovčím ocasem, cípy k žerďovému okraji listu, obojí žluté. V žerďové a vlající části bílá lilie. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hořesedly",
        kod: 541729,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.16239,
        longitude: 13.602769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Coats_of_arms_Ho%C5%99esedly.jpeg",
        coatOfArmsFlagDescription: "Žlutý list. Na dolním okraji listu zelená hora. Na ní sedí dvě zády se opírající bosé postavy - na žerďové straně muž s černými vlasy, kloboukem, halenou a kalhotami; na vlající straně žena s černými vlasy a v červených šatech. V horním rohu a horním cípu po jedné větvi chmele do oblouku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zelené hoře sedí dvě zády se opírající bosé postavy, vpravo muž s černými vlasy v černém klobouku, haleně a kalhotách, vlevo žena s černými vlasy v červených šatech. Nad nimi do oblouku dvě zelené chmelové větve."
    },
    {
        obec: "Hořešovice",
        kod: 532321,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.27169,
        longitude: 13.966906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Ho%C5%99e%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed zelený dubový dvojlist položený k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě položený zelený dubový dvojlist."
    },
    {
        obec: "Hořešovičky",
        kod: 535150,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.274989,
        longitude: 13.95589
    },
    {
        obec: "Hořice",
        kod: 547956,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.599362,
        longitude: 15.185502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Coat_of_arms_of_Ho%C5%99ice_PE.jpg",
        coatOfArmsFlagDescription: "Červený list, z žerďového okraje vyniká sedm žlutých plamenů do první třetiny délky listu. Ve střední a vlající části zkřížené žluté gotické klíče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou plamennou hlavou zkřížené zlaté gotické klíče."
    },
    {
        obec: "Hořice",
        kod: 572926,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.366189,
        longitude: 15.63194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Znak_m%C4%9Bsta_Ho%C5%99ice.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů : červený, bílý, modrý, bílý a červený v poměru 1:1:2:1:1. Na střední modrý pruh je položeno pět vzájemně se dotýkajících žlutých kosočtverců s rohy sahajícími na okraj modrého pole. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Hořice na Šumavě",
        kod: 545520,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38222,
        latitude: 48.766018,
        longitude: 14.178522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Ho%C5%99ice_na_%C5%A0umav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 4 : 1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zelenými boky červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Hořičky",
        kod: 574074,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.444856,
        longitude: 15.993634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Coat_of_arms_of_Ho%C5%99i%C4%8Dky.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a vypouklý bílý sahající do sedmi desetin šířky listu, v poměru 4 : 1. V zeleném pruhu k vlajícímu okraji listu obrácená bílá rozkřídlená holubice se žlutou zbrojí a žlutou pětilistou ratolestí v zobáku, provázená pod levým křídlem bílým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou horou doleva obrácená rozkřídlená stříbrná holubice se zlatou zbrojí a zlatou pětilistou ratolestí v zobáku, provázená pod levým křídlem stříbrným křížem."
    },
    {
        obec: "Hořín",
        kod: 534803,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27601,
        latitude: 50.34492,
        longitude: 14.464285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Horin_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrou žerďovou vidlicí, s rameny širokými jednu čtvrtinu šířky listu, se čtyřmi žlutými šesticípými hvězdami do vidlice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá vlnitá dolů obrácená vidlice se čtyřmi zlatými hvězdami uprostřed, v horních koutech provázená dvěma do středu obrácenými černými orlicemi se zlatou zbrojí a jetelovitě zakončeným perizoniem, dole osmihrotý kříž převýšený hvězdou, obojí červené."
    },
    {
        obec: "Hořiněves",
        kod: 570044,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50306,
        latitude: 50.309639,
        longitude: 15.767421,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/CoA_of_Ho%C5%99in%C4%9Bves.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílo - červeně šachovaný (8 x 3), zelený s bílou hlavicí berly závitem k vlajícímu okraji a bílý, v poměru 1 : 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrno - červeně šachovanou hlavou vpravo vyrůstající doleva otočená berla, vlevo otevřená kniha, obojí stříbrné."
    },
    {
        obec: "Hořovice",
        kod: 531189,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.836078,
        longitude: 13.902789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Znak_m%C4%9Bsta_Ho%C5%99ovice2.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh s polovinou černé orlice se žlutou zbrojí široký jednu třetinu délky listu a tři vodorovné pruhy, bílý, modrý a bílý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hořovičky",
        kod: 541737,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.155721,
        longitude: 13.531515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Ho%C5%99ovi%C4%8Dky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve středu listu a pět vodorovných pruhů, střídavě modrých a žlutých, v poměru 1 : 1 : 2 : 1 : 1. V prostředním modrém pruhu chmelová šištice a obilný snop, obojí žluté a položené k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným trojvrším mezi dvěma přivrácenými stříbrnými sekerami na zlatých topůrkách svěšená chmelová šištice nad obilným snopem, obojí zlaté."
    },
    {
        obec: "Hosín",
        kod: 544523,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.037403,
        longitude: 14.476024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Hosin_znak.jpeg",
        coatOfArmsFlagDescription: "Červeno-zeleně šikmo dělený list. V červeném poli kosmo žlutý gotický klíč zuby dolů a k žerďovému okraji listu, šikmo přeložený bílým mečem se žlutým jílcem, hrotem k hornímu okraji listu. V zeleném poli žlutý snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně šikmo děleném štítě nahoře kosmo postavený zlatý gotický klíč zuby dolů, šikmo přeložený vztyčeným stříbrným mečem se zlatým jílcem, dole postavený zlatý obilný snop."
    },
    {
        obec: "Hoslovice",
        kod: 551104,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.191286,
        longitude: 13.763173
    },
    {
        obec: "Hospozín",
        kod: 532339,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27322,
        latitude: 50.306981,
        longitude: 14.17187,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Hospoz%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, v poměru 1 : 3. V žerďovém pruhu bílá orientální šavle se žlutým jílcem, hrotem k hornímu a ostřím k žerďovému okraji. V modrém poli bílá lovecká trubka se žlutým kováním prostrčená smyčkou žlutého hadího těla zakončeného dole na obou koncích odvrácenou korunovanou hlavou s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s červenou hlavou s doleva položenou stříbrnou orientální šavlí se zlatým jílcem. V modrém poli stříbrná lovecká trubka se zlatým kováním prostrčená smyčkou zlatého hadího těla zakončeného dole na obou koncích odvrácenou korunovanou hlavou s červeným jazykem."
    },
    {
        obec: "Hospříz",
        kod: 546402,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.128663,
        longitude: 15.085627
    },
    {
        obec: "Hostašovice",
        kod: 568511,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74101,
        latitude: 49.534363,
        longitude: 17.997408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Znak_obce_Hosta%C5%A1ovice.png",
        coatOfArmsFlagDescription: "List tvoří zelená žerďová a modrá vlající část. Uprostřed zkřížený cep a hrábě provázené čtyřmi šesticípými hvězdami, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě zkřížený cep a hrábě, provázené čtyřmi hvězdami, vše stříbrné."
    },
    {
        obec: "Hostějov",
        kod: 592196,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68741,
        latitude: 49.040274,
        longitude: 17.257265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/CoA_of_Host%C4%9Bjov.svg",
        coatOfArmsFlagDescription: "Zelený list s červeno-bíle polcenou podkovou, v ní žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě červeno-stříbrně polcená podkova, v ní zlatý vinný hrozen."
    },
    {
        obec: "Hostěnice",
        kod: 583057,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66404,
        latitude: 49.236641,
        longitude: 16.776652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Host%C4%9Bnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: žlutý, černý, červený, černý a žlutý v poměru 3:1:3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červené srdce podložené zkříženým černým mlátkem a želízkem."
    },
    {
        obec: "Hostěradice",
        kod: 594113,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67171,
        latitude: 48.950156,
        longitude: 16.259416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Host%C4%9Bradice_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černou orlicí s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá orlice s červenou zbrojí."
    },
    {
        obec: "Hostěrádky-Rešov",
        kod: 593052,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68352,
        latitude: 49.117751,
        longitude: 16.784406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Host%C4%9Br%C3%A1dky-Re%C5%A1ov_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený se žlutým vinným hroznem a bílý v poměru 2:3:1. V modrém pruhu čtyři bílé žerďové klíny s vrcholy sahajícími do první třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se čtyřmi stříbrnými kužely zlatý vinný hrozen provázený vpravo stříbrnou vztyčenou radlicí a vlevo stříbrným vinařským nožem-kosířem se zlatou rukojetí."
    },
    {
        obec: "Hostětice",
        kod: 587192,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.191764,
        longitude: 15.414157
    },
    {
        obec: "Hostětín",
        kod: 550736,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68771,
        latitude: 49.050017,
        longitude: 17.879516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Hostetin_znak.gif",
        coatOfArmsFlagDescription: "Červeno-modře kosmo dělený list. V červeném žerďovém poli bílá lilie, v modrém vlajícím žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně kosmo děleném štítě nahoře zlatá osmihrotá hvězda, dole stříbrná lilie."
    },
    {
        obec: "Hostim",
        kod: 594121,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 49.018691,
        longitude: 15.894995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Hostim_CoA.png"
    },
    {
        obec: "Hostín",
        kod: 531499,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27732,
        latitude: 50.340525,
        longitude: 14.588998
    },
    {
        obec: "Hostín u Vojkovic",
        kod: 531928,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27744,
        latitude: 50.297177,
        longitude: 14.397072,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Host%C3%ADn_u_Vojkovic_CoA.jpg",
        coatOfArmsDescription: "V červeno-zlatě děleném štítě nahoře zkřížené klíče přeložené zubří hlavou, vše zlaté, dole tři (1, 2) uťaté výhonky chřestu mezi dvěma odvrácenými lipovými listy, vše zelené."
    },
    {
        obec: "Hostinné",
        kod: 579297,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54371,
        latitude: 50.540762,
        longitude: 15.723442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Hostinn%C3%A9.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými žerďovými klíny o základnách rovných jedné polovině šířky listu a s vrcholy na vlajícím okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hostišová",
        kod: 585254,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76301,
        latitude: 49.253781,
        longitude: 17.591153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Hosti%C5%A1ov%C3%A1_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou osmicípou hvězdou uprostřed. Z páté a osmé dvanáctiny žerďového a vlajícího okraje vycházejí dva bílé vodorovné třikrát lomené pruhy tak, že se vrcholem druhého, pravoúhlého lomu dotýkají středů horního a dolního okraje listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hostomice",
        kod: 531201,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.825363,
        longitude: 14.045712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Hostomice_BE_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Červený list se třemi šikmými bílými pruhy vycházejícími z první, třetí a páté šestiny dolního okraje listu a končící v první, třetí a páté šestině vlajícího okraje listu. V horním červeném poli český lev vysoký dvě třetiny šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hostivice",
        kod: 539244,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25301,
        latitude: 50.081679,
        longitude: 14.25867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/COA_hostivice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, a na dolním okraji vodorovný bílý zubatý pruh, široký pět šestnáctin šířky listu, s pěti obdélníkovými zuby zasahujícími do třetí osminy šířky listu a se šesti stejnými mezerami. V červeném pruhu tři (2,1) bílé podkovy, v modrém pruhu žlutá, k žerdi do oblouku vztyčená větev vinné révy se třemi listy. V bílém pruhu modré ozubené kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno - modře polcený štít, v patě stříbrná kvádrovaná hradba s cimbuřím a modrým ozubeným kolem. Vpravo tři stříbrné podkovy (2,1), vlevo zlatá do oblouku vztyčená větev vinné révy se třemi listy."
    },
    {
        obec: "Hostomice",
        kod: 567531,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41752,
        latitude: 50.587806,
        longitude: 13.808142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/CoA_of_Hostomice.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým polem ohraničeným pěti červenými kosmo a šikmo položenými čtvercovými poli dotýkajícími se protilehlými rohy. Strany čtverců jsou rovny jedné pětině šířky listu a první čtverec je položen na začátku druhé čtvrtiny horního okraje listu, poslední na začátku druhé čtvrtiny dolního okraje listu. V zeleném poli žlutá zkřížená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně pěti stupni dělený štít, na každém stupni červená kostka, pod nimi zlatá zkřížená hornická kladívka."
    },
    {
        obec: "Hostouň",
        kod: 553689,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34525,
        latitude: 49.559821,
        longitude: 12.771578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Hostou%C5%88_-_CoA.png",
        coatOfArmsFlagDescription: "V modrém listu bílá kvádrovaná hradba s prázdnou branou se žlutou vytaženou mříží a otevřenými žlutými vraty na bílých závěsech. Z hradby vynikají dvě bílé věže, každá s pěti (2, 1, 2) černými okny, cimbuřím a červenou stanovou střechou se žlutou makovicí. Mezi věžemi sníženě dělený štítek, nahoře polcený, v pravém žlutém poli černé jelení paroží se čtrnácti výsadami, v levém bílo-žlutě děleném poli lev ve skoku opačných barev, dolní pole červené. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hostouň",
        kod: 532347,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27353,
        latitude: 50.11449,
        longitude: 14.201384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Hostou%C5%88_KL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý zelený, v poměru 1 : 3. V zeleném pruhu, se třemi obdélníkovými zuby sahajícími do poloviny bílého pruhu a čtyřmi stejně širokými mezerami, dva zkřížené nože s bílými čepelemi a žlutými rukojeťmi u dolního okraje listu. Mezi čepelemi bílé osmicípé ostruhové kolečko. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou dva zkřížené stříbrné nože se zlatými rukojeťmi provázené nahoře stříbrným osmihrotým ostruhovým kolečkem."
    },
    {
        obec: "Hostovlice",
        kod: 534064,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28562,
        latitude: 49.860355,
        longitude: 15.466403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Hostovlice_CoA.jpg"
    },
    {
        obec: "Hosty",
        kod: 535524,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.260396,
        longitude: 14.393359,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Znak_obce_Hosty.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký jednu třetinu délky listu a dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu zelený vztyčený lipový list s plodenstvím. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, vpravo ve stříbrném poli vyniká půl černé plamenné orlice se zlatým perizoniem a červenou zbrojí, levé pole zlato-modře vlnitě dělené, nahoře vztyčený zelený lipový list s plodenstvím."
    },
    {
        obec: "Hošťálková",
        kod: 542750,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75622,
        latitude: 49.354774,
        longitude: 17.869548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/CZ_Ho%C5%A1%C5%A5%C3%A1lkov%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý v poměru 1:2:1. V červeném poli v horní žerďové části bílá sekera, v horní vlající části bílá radlice a v dolní části žlutý kráčející jelen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kráčející zlatý jelen provázený nahoře odvrácenou sekerou a radlicí, obojí stříbrné."
    },
    {
        obec: "Hošťálkovy",
        kod: 597392,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79401,
        latitude: 50.111701,
        longitude: 17.599627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Coat_of_arms_of_Ho%C5%A1%C5%A5%C3%A1lkovy.svg",
        coatOfArmsFlagDescription: "Zelený list; uprostřed bílé oválné zrcadlo se žlutým rámem provázené dole do oblouku čtyřmi bílými šestilistými květy se žlutými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné oválné zrcadlo ve zlatém rámu provázené dole do oblouku čtyřmi stříbrnými šestilistými květy se zlatými středy."
    },
    {
        obec: "Hošťalovice",
        kod: 571482,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.934901,
        longitude: 15.57603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Hostalovice_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černým vztyčeným medvědem s červenou zbrojí a sečnou ranou na levé tlapě držící uťatou břízu s bílým kmenem a zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černý vztyčený medvěd s červenou zbrojí a sečnou ranou na levé tlapě, držící uťatou břízu přirozených barev."
    },
    {
        obec: "Hoštejn",
        kod: 535885,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.874482,
        longitude: 16.774872,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Ho%C5%A1tejn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva pruhy, modrý a zubatý žlutý v poměru 3:1. Žlutý pruh má pět obdélníkových zubů a čtyři stejné mezery. V žerďové polovině modrého pruhu zkřížené bílé sekery se žlutými topůrky, podložené žlutými vztyčenými hráběmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší se dvěma zkříženými stříbrnými sekerami se zlatými topůrky zlatá věž s cimbuřím a jedním prolomeným oknem, provázená zlatými vztyčenými hráběmi."
    },
    {
        obec: "Hoštice",
        kod: 551121,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.194319,
        longitude: 13.909569
    },
    {
        obec: "Hoštice",
        kod: 588482,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76813,
        latitude: 49.208645,
        longitude: 17.233888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Hostice_%28Kromeriz%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová a modrá vlající část, přes které je v horní části položená bílá dubová ratolest se třemi listy a třemi žaludy, na jejímž vrcholu sedí bílý pták se žlutou zbrojí. Přes dolní část dva vodorovné žluté pruhy tak, že poměr pruhů vzniklých u žerdi je 6:1:1:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě nad zkříženými stříbrnými sekerami se zlatými topůrky stříbrná dubová ratolest se třemi listy a třemi žaludy, na jejímž vrcholu sedí stříbrný pták se zlatou zbrojí."
    },
    {
        obec: "Hoštice-Heroltice",
        kod: 593061,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.287107,
        longitude: 17.062864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Coa_Czech_Town_Ho%C5%A1tice-Heroltice.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým šikmým pruhem širokým jednu třetinu šířky listu. V horním žerďovém rohu dvě žluté šesticípé hvězdy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrným sníženým břevnem dělený zelený štít, nahoře stříbrný ohlížející se beránek přidržující zlatý kříž, provázený nahoře dvěma zlatými hvězdami. Dole stříbrná vztyčená radlice."
    },
    {
        obec: "Hoštka",
        kod: 564877,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41172,
        latitude: 50.488694,
        longitude: 14.33511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Erb_Ho%C5%A1tka.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Uprostřed listu žluté slunce bez obličeje střídavě se šesti rovnými a šesti plamennými paprsky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hošťka",
        kod: 560898,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34806,
        latitude: 49.696306,
        longitude: 12.597568,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Ho%C5%A1%C5%A5ka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, červený a bílý, v poměru 1:4:1. V červeném pruhu žlutou korunou prostrčené zkřížené bílé čakany. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo zlatou korunou prostrčené zkřížené stříbrné čakany, vlevo zelený drak s červenou zbrojí a jazykem."
    },
    {
        obec: "Hovězí",
        kod: 542768,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75601,
        latitude: 49.303745,
        longitude: 18.060734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/CZ_Hov%C4%9Bz%C3%AD_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, přeložené bílým vodorovným pruhem vycházejícím z deváté desetiny žerďového a vlajícího okraje. Uprostřed bílá ležící kráva se žlutou zbrojí, obrácená k vlajícímu okraji listu. V horním rohu a cípu po dvou bílých hradebních baštách vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nad vlnitou patou ležící doleva obrácená kráva se zlatou zbrojí provázená nahoře čtyřmi hradebními baštami, vše stříbrné."
    },
    {
        obec: "Hovorany",
        kod: 586170,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69612,
        latitude: 48.95503,
        longitude: 16.993562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Hovorany_znak.png",
        coatOfArmsFlagDescription: "Žlutý list s červeným žerďovým pruhem širokým jednu třetinu délky listu s černou ostrví s osmi suky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na červeném polštáři se čtyřmi třapci postavená černá ostrev s osmi suky, čtyřmi na každé straně."
    },
    {
        obec: "Hovorčovice",
        kod: 538230,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25064,
        latitude: 50.178764,
        longitude: 14.517993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Hovorcovice_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým ondřejským křížem jehož ramena vycházejí z druhé pětiny žerďového a vlajícího okraje do první a čtvrté čtvrtiny dolního okraje. Nad křížem bílá křtící miska s modrou vodou převýšená žlutou šesticípou hvězdou. Přes dolní ramena kříže bílá stuha (hovořící páska) široká jednu desetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatou hvězdou převýšená stříbrná křtící miska s modrou vodou nad sníženým zlatým ondřejským křížem, přes jehož spodní ramena je přehozena stříbrná stuha (hovořící páska)."
    },
    {
        obec: "Hraběšice",
        kod: 553387,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78815,
        latitude: 49.97673,
        longitude: 17.079623,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Hrab%C4%9B%C5%A1ice_CoA.JPG",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, zelený, bílý, zelený a žlutý, v poměru 1 : 1 : 6 : 1 : 1. Na dolním zeleném pruhu stojí černý havran s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném pahorku se zlatou korunou černý havran s červenou zbrojí."
    },
    {
        obec: "Hraběšín",
        kod: 531413,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.852623,
        longitude: 15.331919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Hrab%C4%9B%C5%A1%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý, a vodorovný zelený pruh u dolního okraje listu široký dvě sedminy šířky listu. V bílém pruhu vykořeněná lípa se zelenými listy a hnědým kmenem, v modrém bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře stříbrno-modře polcený. V pravém poli vykořeněná lípa přirozené barvy, v levém poli stříbrné mlýnské kolo, dolní pole zelené."
    },
    {
        obec: "Hrabětice",
        kod: 594130,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67168,
        latitude: 48.797751,
        longitude: 16.393527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Hrab%C4%9Btice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý. V bílém pruhu tři svěšené hnědé žaludy se zelenou číškou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad červeno-stříbrně šachovanou patou stříbrný kůl se třemi svěšenými žaludy přirozené barvy, provázený vpravo vztyčenou stříbrnou radlicí a vlevo postaveným zlatým nožem s rukojetí přirozené barvy."
    },
    {
        obec: "Hrabišín",
        kod: 536091,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78804,
        latitude: 49.91415,
        longitude: 17.036459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/CoA_of_Hrabi%C5%A1%C3%ADn.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým žerďovým pilovitým pruhem širokým šestinu délky listu a žlutým středovým vodorovným pruhem širokým desetinu šířky listu. Nad a pod vodorovným pruhem dvě bílé šesticípé hvězdy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté hrábě provázené podél násady čtyřmi (2,2) stříbrnými hvězdami."
    },
    {
        obec: "Hrabová",
        kod: 553221,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.845548,
        longitude: 16.953951,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Hrabova_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelená žerďová část se třemi žlutými habrovými plody (2+1) a bílá vlající část s modrou radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo tři (2,1) zlaté habrové plody, vlevo modrá radlice."
    },
    {
        obec: "Hrabůvka",
        kod: 513636,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.578604,
        longitude: 17.691361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Hrab%C5%AFvka_PR_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným uťatým habrem se dvěma zkříženými žlutými hráběmi v koruně a provázeným u kmenu modrými květy lnu se zelenými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě ze zeleného trojvrší vyrůstající zelený habr se dvěma zkříženými zlatými hráběmi v koruně, provázený dole dvěma modrými květy lnu se zelenými středy."
    },
    {
        obec: "Hrabyně",
        kod: 507261,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74763,
        latitude: 49.882583,
        longitude: 18.054935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Hrabyn%C4%9B_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:4:1. V bílém pruhu zelený habr s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě habr přirozené barvy."
    },
    {
        obec: "Hradce",
        kod: 535460,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.933382,
        longitude: 14.359977
    },
    {
        obec: "Hradčany",
        kod: 583065,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66603,
        latitude: 49.327964,
        longitude: 16.442271,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Hrad%C4%8Dany_okr_Brno_venkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý oboustranně zubatý se třemi čtvercovými zuby a čtyřmi stejnými mezerami a červený, v poměru 1 : 2 : 1. V bílém pruhu modrý dvouuchý džbán se žlutou růží s červeným semeníkem a zelenými kališními lístky. Ze džbánu vyrůstají na zelených stoncích tři červené růže se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červeným cimbuřovým lemem modrý dvouuchý džbán se zlatou růží s červeným semeníkem a zelenými kališními lístky, z něhož vyrůstají na zelených stoncích tři červené růže se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Hradčany",
        kod: 513733,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.452714,
        longitude: 17.571737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Hrad%C4%8Dany_PR_znak.png",
        coatOfArmsFlagDescription: "List tvoří vodorovné pruhy - červený a černý; na středové části bílá věž s cimbuřím o třech stínkách a prázdnou branou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě děleném štítě dva zkřížené zlaté nože, přes něž je položena stříbrná kvádrová hradní věž s cimbuřím a prázdnou branou."
    },
    {
        obec: "Hradčany",
        kod: 537161,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28905,
        latitude: 50.158082,
        longitude: 15.269964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Hrad%C4%8Dany_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a sedm střídavě zelených a bílých vodorovných pruhů. V horní části červeného pruhu květ vstavače nachového nad žlutou kvádrovanou hradbou se třemi čtvercovými zuby a dvěma mezerami, vysokou čtyři sedminy šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo květ vstavače nachového přirozené barvy nad zlatou kvádrovanou hradbou, vlevo tři stříbrná břevna."
    },
    {
        obec: "Hradčany-Kobeřice",
        kod: 589527,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79807,
        latitude: 49.364185,
        longitude: 17.128429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Hrad%C4%8DanyKobe%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 1:2:1. V červeném tři bílé žerďové klíny s vrcholy na okraji bílého pruhu, v modrém tři žluté vlající klíny s vrcholy na okraji bílého pruhu, v němž je červený dvouocasý lev ve skoku se žlutou zbrojí, držící modrou limpu hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-stříbrně polcený. Vpravo tři (2,1) stříbrné kužely, vlevo červený dvouocasý lev se zlatou zbrojí držící modrou limpu, dole od dělící linie na modré polovině vynikající polovina zlatého mlýnského kola."
    },
    {
        obec: "Hradec",
        kod: 568724,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.707329,
        longitude: 15.295565,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Hradec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zubatý červený a zelený, v poměru 2 : 3 : 3. Červený pruh má šest zubů sahajících do pětiny délky listu a pět celých a dvě poloviční mezery. V červeném a zeleném pruhu bílý leknínový dvojlist vyrůstající z bílé sedmilisté růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě pod stříbrnou cimbuřovou hlavou stříbrný leknínový dvojlist vyrůstající ze stříbrné sedmilisté růže se zlatým semeníkem."
    },
    {
        obec: "Hradčovice",
        kod: 592200,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68733,
        latitude: 49.049868,
        longitude: 17.582201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Hrad%C4%8Dovice_znak.gif",
        coatOfArmsFlagDescription: "Bílý list s modrým dvouhlavým orlem s červenou zbrojí, se žlutým vinným hroznem na hrudi, držící v obou spárech žlutá liliová žezla. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý dvouhlavý orel s červenou zbrojí a zlatým vinným hroznem na hrudi, držící v obou spárech zlatá liliová žezla."
    },
    {
        obec: "Hradec",
        kod: 557731,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33211,
        latitude: 49.635159,
        longitude: 13.121483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Hradec_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým modrým pruhem širokým pětinu délky listu na bílé žerďové a červené vlající pole. V bílém poli hnědá palisádová věž s cimbuřím, v červeném zkřížené bílé meče se žlutými jílci, hroty nahoru. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně šikmým modrým břevnem děleném štítě nahoře dřevěná palisádová věž s cimbuřím přirozené barvy, dole zkřížené vztyčené stříbrné meče se zlatými jílci."
    },
    {
        obec: "Hradec Králové",
        kod: 569810,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50002,
        latitude: 50.210461,
        longitude: 15.825311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Hradec_Kralove_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List je tvořen třemi vodorovnými pruhy, bílým, žlutým a červeným. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hradec nad Moravicí",
        kod: 507270,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74741,
        latitude: 49.871107,
        longitude: 17.875924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Coa_Czech_Town_Hradec_nad_Moravic%C3%AD.svg",
        coatOfArmsFlagDescription: "List praporu tvoří červené a bílé zubaté pole s pěti čtvercovými bílými zuby. Poměr mezi červeným polem, výškou zubů a bílým polem je 3:1:4. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hradec nad Svitavou",
        kod: 572691,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56901,
        latitude: 49.71153,
        longitude: 16.480687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Hradec_nad_Svitavou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zubatý zelený se třemi čtvercovými zuby a čtyřmi stejnými mezerami a žlutý, v poměru 2 : 5 : 1. V zeleném pruhu bílý dvouocasý gryf s červenou zbrojí a žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný hrad s kvádrovanou hradbou s cimbuřím a s prázdnou gotickou branou a se dvěma věžemi, každá s prázdným oknem a s cimbuřím. Mezi věžemi stříbrný dvouocasý gryf s červenou zbrojí a zlatým jazykem, v bráně vedle sebe postaveny stříbrný meč se zlatým jílcem a levá polovina zlatého kola svaté Kateřiny."
    },
    {
        obec: "Hradec-Nová Ves",
        kod: 553301,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79084,
        latitude: 50.283247,
        longitude: 17.281327,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Hradec-Nov%C3%A1_Ves_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým svislým pruhem na vlajícím okraji, širokým jednu čtvrtinu délky listu. Bílý pruh má tři čtvercové zuby. V zeleném poli žlutá šesticípá hvězda cípem vzhůru podložená bílými zkříženými cepy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou hradební zdí s cimbuřím zkřížené stříbrné cepy přeložené zlatou hvězdou."
    },
    {
        obec: "Hradečno",
        kod: 532355,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27304,
        latitude: 50.188435,
        longitude: 13.991367
    },
    {
        obec: "Hrádek",
        kod: 594148,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67127,
        latitude: 48.772933,
        longitude: 16.268293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Mainlogo.gif",
        coatOfArmsFlagDescription: "List tvoří žerďový zubatý žlutý pruh široký jednu čtvrtinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami a modré pole s bílou husou se žlutou zbrojí provázenou v horní polovině vlající části listu kosmo položeným žlutým gotickým klíčem zuby dolů a k vlajícímu okraji, šikmo podloženým bílým mečem hrotem dolů se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou zlatou kvádrovanou hradbou s cimbuřím stříbrná husa, se zlatou zbrojí provázená vlevo nahoře kosmo postaveným zlatým gotickým klíčem zuby nahoru, šikmo podloženým postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Hrádek",
        kod: 570052,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.218662,
        longitude: 15.675654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/CoA_of_Hr%C3%A1dek_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a žluto-černě šachovaný (8x3) v poměru 1:2:1. V bílém pruhu červený středový kříž s rameny širokými jednu dvanáctinu délky listu. V modrém pruhu hrad tvořený dvěma kvádrovanými věžemi s cimbuřím a jednou černou střílnou spojenými prázdnou branou se zdviženou mříží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli stříbrný hrad tvořený dvěma kvádrovanými věžemi s cimbuřím a jednou černou střílnou spojenými prázdnou branou se zdviženou mříží, dolní polovina polcena, v pravém stříbrném poli červený heroldský kříž, levé pole zlato-černě šachované."
    },
    {
        obec: "Hrádek",
        kod: 512176,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73997,
        latitude: 49.616708,
        longitude: 18.737315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Grodek_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý v poměru 5:3, oddělené cimbuřovým řezem se třemi čtvercovými zuby o výšce jedné osminy šířky listu. V modrém poli žluté vozové váhy se dvěma brdečky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou hradbou s cimbuřím vozové váhy se dvěma brdečky, vše zlaté."
    },
    {
        obec: "Hrádek",
        kod: 547972,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 49.968309,
        longitude: 16.338406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Hr%C3%A1dek.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu bílý hrad tvořený dvěma věžemi s cimbuřím, každá s černým oknem, spojenými branou s cimbuřím a černými zavřenými vraty. Z brány vyrůstá žlutý smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrad tvořený dvěma věžemi s cimbuřím, každá s černým oknem, spojenými branou s cimbuřím a černými zavřenými vraty. Z brány vyrůstá zlatý smrk, pod vraty zlatý tlapatý kříž."
    },
    {
        obec: "Hrádek",
        kod: 556301,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.261381,
        longitude: 13.498603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Hr%C3%A1dek_KT_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 5 : 1. V modrém pruhu volný bílý hrádek se dvěma kvádrovanými věžemi na bílých soklech spojenými kvádrovanou hradbou s pěti zuby a čtyřmi mezerami. Každá věž má černé okno a červenou kuželovou střechu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volný stříbrný kvádrovaný hrádek se dvěma věžemi na soklech uprostřed spojenými hradbou. Každá věž má černé okno a červenou kuželovou střechu. Nad hradbou kosmo rošt, pod hrádkem karafa se zátkou, vše zlaté."
    },
    {
        obec: "Hrádek",
        kod: 559822,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33842,
        latitude: 49.710027,
        longitude: 13.65421,
        coatOfArmsFlagDescription: "Zelený list se zkříženými hutnickými kladivy podloženými vidlicí, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížená hutnická kladiva podložená vidlicí, vše zlaté."
    },
    {
        obec: "Hrádek nad Nisou",
        kod: 564095,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46334,
        latitude: 50.852892,
        longitude: 14.844658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Hradek_nad_nisou_znak.jpg",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. V modrém poli bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrová hradba se třemi stínkami, prolomená prázdnou branou s otevřenými zlatými vraty. Z prostřední stínky vyrůstá trs listů orobince se dvěma palicemi na odkloněných stvolech, vše přirozené barvy a provázené třemi (1, 2) stříbrnými liliemi. V bráně červená lovecká trubka se zlatým kováním a závěsem."
    },
    {
        obec: "Hradešice",
        kod: 556319,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.323206,
        longitude: 13.596547
    },
    {
        obec: "Hradešín",
        kod: 564800,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.038947,
        longitude: 14.756771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Hrade%C5%A1%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4:1. Do modrého pruhu vyniká žlutá volná zeď s bílou branou s hnědými otevřenými vraty na černých závěsech, v bráně červený středový kříž. Za zdí vyniká rotunda s černým románským oknem, po stranách se zastřešenými apsidami, vše žluté a kvádrované, střechy červené, prostřední se žlutým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší volná zlatá zeď se stříbrnou branou s otevřenými dřevěnými vraty přirozené barvy na černých závěsech, v bráně červený heroldský kříž. Za zdí vyniká rotunda s černým románským oknem, po stranách se zastřešenými apsidami, vše zlaté a kvádrované, střechy červené, prostřední se zlatým křížem."
    },
    {
        obec: "Hradiště",
        kod: 566179,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.464875,
        longitude: 13.051832,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Hradi%C5%A1t%C4%9B_%28Doma%C5%BElice_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým svatopetrským křížem, jehož svislá ramena vycházejí z třetí osminy horního okraje a vodorovná z páté šestiny žerďového okraje listu. Ve svislých ramenech modrý meč hrotem k hornímu okraji listu, v horním žerďovém poli žlutý gotický klíč zuby nahoru a k žerďovému okraji listu. V horním vlajícím poli u horního ramene kříže bílá labuť se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vpravo stříbrný heroldský svatopetrský kříž se vztyčeným modrým mečem provázený v horních polích vpravo vztyčeným zlatým gotickým klíčem a vlevo stříbrnou labutí se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Hradiště",
        kod: 557749,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33544,
        latitude: 49.439432,
        longitude: 13.756199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Hradi%C5%A1t%C4%9B_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy střídavě červené a bílé, přes oba prostřední pruhy mlýnské kolo opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě dole mlýnské kolo opačných barev, vpravo nahoře dřevěný ondřejský kříž přirozené barvy, vlevo nahoře zlatý pšeničný klas se dvěma listy."
    },
    {
        obec: "Hradiště",
        kod: 541001,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.963121,
        longitude: 13.717739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Hradi%C5%A1t%C4%9B_%28Rokycany_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový modro-zeleně dělený v poměru 5 : 3 a vlající tvořený osmi vodorovnými pruhy, žlutým, modrým, bílým, modrým, žlutým, zeleným, žlutým a zeleným. V modrém poli bílý kříž jehož vodorovné rameno vychází z třetí osminy šířky listu, svislé rameno vychází ze třetí dvanáctiny délky listu. V zeleném pruhu pod svislým ramenem kříže žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyniká ze zeleného návrší mezi dvěma trojicemi zlatých kůlů palisády stříbrný latinský kříž do krajů. V návrší zlatá koruna."
    },
    {
        obec: "Hradiště (vojenský újezd)",
        kod: 555177,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36006,
        latitude: 50.174549,
        longitude: 13.046831,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý pilovitý se šesti zuby, v poměru 3:1. V zeleném pruhu zkřížené bílé meče hroty dolů, mezi jílci bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou palisádou zkřížené postavené stříbrné meče, nad nimi stříbrná růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Hradiště",
        kod: 532932,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.684271,
        longitude: 14.848805
    },
    {
        obec: "Hradištko",
        kod: 537179,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28912,
        latitude: 50.165593,
        longitude: 14.935157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Hradi%C5%A1tko_NY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý zubatý se sedmi čtvercovými zuby a osmi stejnými mezerami, modrý a žlutý, v poměru 9 : 6 : 5 : 20. V dolním žlutém pruhu vztyčená zelená dubová větvička střídavě se třemi červenými žaludy a dvěma zelenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod červenou cimbuřovou hlavou vztyčená zelená dubová větvička střídavě se třemi červenými žaludy a dvěma zelenými listy, převýšená modrým vlnitým břevnem."
    },
    {
        obec: "Hradištko",
        kod: 539252,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25209,
        latitude: 49.870321,
        longitude: 14.407963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Znak_obce_Hradi%C5%A1tko.gif",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu a pět vodorovných pruhů, střídavě zelených a bílých. Ve žlutém pruhu vykořeněný kvetoucí kandík - psí zub. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě vpravo dvě stříbrná vlnitá břevna, vlevo vykořeněný kvetoucí kandík - psí zub přirozené barvy."
    },
    {
        obec: "Hracholusky",
        kod: 550221,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.0589,
        longitude: 14.088637
    },
    {
        obec: "Hracholusky",
        kod: 565202,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27041,
        latitude: 50.003379,
        longitude: 13.773965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Coats_of_arms_Hracholusky.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy černý a červený v poměru 1:2. V horním dva zkřížené žluté hrachové lusky, v dolním bílá k vlajícímu okraji obrácená obrněná noha se žlutou ostruhou. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod černou hlavou se dvěma zkříženými zlatými hrachovými lusky vlevo obrácená stříbrná obrněná noha se zlatou ostruhou."
    },
    {
        obec: "Hrachoviště",
        kod: 562831,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 48.929471,
        longitude: 14.77121
    },
    {
        obec: "Hranice",
        kod: 544540,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.83852,
        longitude: 14.868392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Hranice-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílo-černě cihlovaný (2x3) a zelený, v poměru 5 : 2 : 5. V horním zeleném pruhu dvě vztyčené žluté borové šišky, v dolním bílý pětilistý květ rojovníku se zeleným středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvěma řadami stříbrno-černě cihlované břevno, nahoře dvě vztyčené zlaté borové šišky, dole stříbrný pětilistý květ rojovníku se zeleným středem."
    },
    {
        obec: "Hranice",
        kod: 554553,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35124,
        latitude: 50.304702,
        longitude: 12.175894,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Hranice%2Cznak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3 : 2. Na horním okraji zeleného pruhu stojí bílý kůň se skloněnou hlavou. Z první třetiny horního okraje zeleného pruhu vychází kosmý bílý pruh do třetí třetiny dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně dělený štít. Nahoře stříbrný kůň se skloněnou hlavou, stojící na dělící linii. Dole stříbrné kosmé vlnité břevno."
    },
    {
        obec: "Hranice",
        kod: 513750,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.549764,
        longitude: 17.735022,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Hranice_na_Morav%C4%9B_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří pět šikmých pruhů - červený, žlutý, modrý, žlutý a červený v poměru 4:3:1:3:4. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hraničné Petrovice",
        kod: 546976,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78306,
        latitude: 49.740948,
        longitude: 17.405043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Coat_of_arms_of_Hrani%C4%8Dn%C3%A9_Petrovice.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černou plechovou nádobou-hrotkem provázenou dole a po stranách dvěma odkloněnými zelenými stonky lnu s listy, každý s modrým květem se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá plechová nádoba-hrotek provázená dole a po stranách dvěma odkloněnými zelenými stonky lnu s listy, každý s modrým květem se zlatým středem."
    },
    {
        obec: "Hrazany",
        kod: 549410,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.52294,
        longitude: 14.33498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Hrazany_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a bílý v poměru 3:4. Bílý pruh má deset trojúhelníkových rovnoramenných zubů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná propletená palisáda."
    },
    {
        obec: "Hrčava",
        kod: 598232,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73998,
        latitude: 49.520116,
        longitude: 18.833235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Herczawa_herb.png",
        coatOfArmsFlagDescription: "List se zeleným svislým pruhem širokým tři desetiny délky listu. Zbytek listu tvoří bílý vlající klín s vrcholem na středu okraje zeleného pruhu, horní modré trojúhelníkové pole a dolní červené pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít pod zelenou hlavou rozdělen stříbrnou špicí sahající až k hlavě štítu, vpravo červený a vlevo modrý, ve špici je zelený smrk bez kořenů."
    },
    {
        obec: "Hrdějovice",
        kod: 544558,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37361,
        latitude: 49.019035,
        longitude: 14.478672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Coa_Czech_Town_Hrd%C4%9Bjovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý, uprostřed červeno-žlutě polcená růže se semeníkem opačných barev a zelenými kališními lístky, převýšená žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě červeno-zlatě polcená růže se semeníkem opačných barev a zelenými kališními lístky převýšená zlatou korunou."
    },
    {
        obec: "Hrdibořice",
        kod: 589535,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79812,
        latitude: 49.478575,
        longitude: 17.220521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Hrdibo%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a modré pole. V červeném pruhu žlutý obilný klas se dvěma listy, v modrém poli třemi (2,1) červenými plameny hořící bílý mlýnský kámen s otvorem pro položenou kypřici. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě, pod červenou hlavou s položeným zlatým obilným klasem se dvěma listy, třemi (2,1) červenými plameny hořící stříbrný mlýnský kámen s otvorem pro položenou kypřici."
    },
    {
        obec: "Hrdlořezy",
        kod: 535869,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29307,
        latitude: 50.451494,
        longitude: 14.875505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Hrdlo%C5%99ezy_%28okres_Mlad%C3%A1_Boleslav%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 4 : 1 : 3. V červeném pruhu kráčející bílá lvice se žlutou zbrojí a jazykem, v modrém poli bílý tesák se žlutým jílcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře stříbrným vlnitým břevnem děleném štítě nahoře kráčející stříbrná lvice se zlatou zbrojí a jazykem, dole položený stříbrný tesák se zlatým jílcem."
    },
    {
        obec: "Hrdlív",
        kod: 532363,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27306,
        latitude: 50.199356,
        longitude: 14.072592,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Hrdliv_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý žerďový červený pruh, široký polovinu délky listu, a dva vodorovné pruhy bílý a žlutý. V červeném pruhu žlutá růže na stonku se dvěma listy nad bílými vykořeněnými lekny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá růže na stonku se dvěma listy nad stříbrnými vykořeněnými lekny."
    },
    {
        obec: "Hrejkovice",
        kod: 549428,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39859,
        latitude: 49.474473,
        longitude: 14.290912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Hrejkovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený. V horním zeleném pruhu dvě šesticípé žluté hvězdy, v dolním tři. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vlnitý kůl provázený vpravo dvěma a vlevo třemi hvězdami pod sebou, vše zlaté."
    },
    {
        obec: "Hrob",
        kod: 567558,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41704,
        latitude: 50.659299,
        longitude: 13.726873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Wapenschild_Hrop.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý s vodorovným černým pruhem širokým osminu šířky listu na dolním okraji pruhu. Uprostřed k vlajícímu okraji obrácený český lev a žlutá berla vynikající z černého pruhu závitem k žerďovému okraji, přeložená bílými hornickými kladívky na černých topůrkách. Poměr šířky k délce pruhu je 2 : 3."
    },
    {
        obec: "Hrobce",
        kod: 564893,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41183,
        latitude: 50.462572,
        longitude: 14.230906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Hrobce_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 1:1:2. V modrém pruhu dvakrát zatočený žlutý roh kozorožce, obrácený dolů a k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Vlnitě dělený štít, horní pole červeno-stříbrně děleno, v dolním modrém poli dolů obrácený a vzhůru dvakrát zatočený zlatý roh kozorožce."
    },
    {
        obec: "Hrobčice",
        kod: 567566,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41757,
        latitude: 50.517448,
        longitude: 13.785716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Hrob%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé nad stejně velkým žlutým polem a dva vodorovné pruhy, žlutý a modrý. V karé žlutý medvěd s červeným jazykem držící bílou ostrev a stojící na zeleném návrší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší zlatý medvěd ve skoku s červeným jazykem držící vztyčenou stříbrnou ostrev."
    },
    {
        obec: "Hrobice",
        kod: 575046,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.103527,
        longitude: 15.78888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Hrobice_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 1: 2 : 6 : 2 : 1. Ve středním pruhu žlutý zvon pod bílým jabloňovým květem se žlutým středem. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dole dvě stříbrná vlnitá břevna, z horního vyrůstají dva zlaté orobince s listy, mezi nimi zlatý zvon pod stříbrným jabloňovým květem se zlatým středem."
    },
    {
        obec: "Hrobice",
        kod: 585262,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.277494,
        longitude: 17.788329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Hrobice_%28Zlin%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, modrý, žlutý, modrý, bílý, modrý, žlutý, modrý, žlutý a modrý, v poměru 1:1:1:5:5:1:1:1:1. Ve středním modrém pruhu žlutý luk se šípem se žlutým hrotem dolů, z jehož bílého dříku vyrůstá zelená rozložená švestková větev se dvěma modrými plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno, nad ním tři zlaté osmihroté hvězdy, pod ním zlatý dolů obrácený luk, do něhož je vložen stříbrný šíp se zlatým hrotem, přecházející v zelenou rozloženou švestkovou větev se dvěma modrými plody."
    },
    {
        obec: "Hromnice",
        kod: 558915,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.849175,
        longitude: 13.44157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Hromnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 7 : 1 : 4. V modrém pruhu mezi čtyřmi (2, 2) žlutými šesticípými hvězdami nad sebou žlutý svícen s bílou, žlutě hořící hromniční svíčkou. V zeleném pruhu žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně sníženým stříbrným vlnitým břevnem děleném štítě nahoře mezi čtyřmi (2, 2) zlatými hvězdami nad sebou zlatý svícen se stříbrnou, zlatě hořící hromniční svíčkou, dole zlatá koruna."
    },
    {
        obec: "Hrochův Týnec",
        kod: 571491,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.959563,
        longitude: 15.910648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Znak_m%C4%9Bsta_Hroch%C5%AFv_T%C3%BDnec.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým mečem vodorovně prostrčeným zezadu žlutým mezikružím hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném vykračujícím koni se zlatým uzděním sv .Martin se svatozáří a ve zbroji dělící se o stříbrný plášť s vlevo klečícím žebrákem."
    },
    {
        obec: "Hronov",
        kod: 574082,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54931,
        latitude: 50.480164,
        longitude: 16.181777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Hronov-znak.svg"
    },
    {
        obec: "Hrotovice",
        kod: 590673,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.1078,
        longitude: 16.060777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Hrotovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list s vodorovným červeným pruhem o šířce 1/20 šířky listu vzdáleným 1/5 od horního okraje listu. Ve středu zbývajícího dolního modrého pole tři bílé vlnité pruhy o jednom vrcholu a jedné prohlubni, pruhy i mezery mezi pruhy jsou široké 1/20 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítu stříbrná věž s proraženou branou a dvěma okny nad sebou a s červenou valbovou střechou, provázená dvěma dole překříženými zlatými obilnými klasy. V patě štítu tři stříbrné vlnité pruhy."
    },
    {
        obec: "Hroubovice",
        kod: 571504,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53854,
        latitude: 49.882632,
        longitude: 15.990827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Hroubovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s letící holubicí se žlutou zbrojí držící v zobáku olivovou ratolest, dole provázená propletenou Davidovou hvězdou, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmo letící holubice se zlatou zbrojí držící v zobáku olivovou ratolest, dole provázená propletenou Davidovou hvězdou, vše stříbrné."
    },
    {
        obec: "Hroznatín",
        kod: 550639,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.29784,
        longitude: 15.908371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Hroznat%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoři pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 1 : 1 : 8 : 1 : 1. V modrém pruhu žlutý vinný hrozen se dvěma listy. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý vinný hrozen se dvěma listy pod červenou hlavou oddělenou zúženým stříbrným obloukovým břevnem."
    },
    {
        obec: "Hroznětín",
        kod: 555185,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36233,
        latitude: 50.309508,
        longitude: 12.871918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Hroznetin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký třetinu délky listu se třemi černými parožími nad sebou a červené pole s volnou bílou kvádrovanou věží s cimbuřím a prázdnou branou. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Hrubá Vrbka",
        kod: 586196,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69673,
        latitude: 48.871977,
        longitude: 17.477119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Hruba_Vrbka_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2:1. Ve žlutém pruhu zelená vrba s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole červeno-modře dělené, nahoře stříbrná zavinutá střela, dole stříbrný doleva obrácený vinařský nůž - kosíř s rukojetí přirozené barvy a vztyčené stříbrné krojidlo, v levém zlatém poli vrba přirozené barvy."
    },
    {
        obec: "Hrubčice",
        kod: 589543,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79821,
        latitude: 49.450115,
        longitude: 17.193197,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Hrub%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý, v poměru 1:1:6:1:1. V červeném pruhu žlutý, k vlajícímu okraji zalomený obilný klas s jedním listem a pod ním bílý vinný list řapíkem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře kráčející zlatý lev s červenou zbrojí, dole zlatý doleva zalomený obilný klas s jedním listem a pod ním položený stříbrný vinný list."
    },
    {
        obec: "Hrubý Jeseník",
        kod: 599638,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28932,
        latitude: 50.251247,
        longitude: 15.094151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Hruby_Jesenik_NB_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List dělený oboustranným lipovým řezem (2, 1) na dva svislé pruhy, zelený a žlutý, v poměru 3 : 1. V žerďovém pruhu ve žlutém mezikruží knížecí čepice zdobená křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - zlatě sníženým oboustranným lipovým řezem (2, 1) děleném štítě, nahoře ve zlatém kruhu knížecí čepice zdobená křížkem."
    },
    {
        obec: "Hroznová Lhota",
        kod: 586188,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69663,
        latitude: 48.90794,
        longitude: 17.417082,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Hroznova_Lhota_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy zelený, červený a zelený v poměru 1:2:1. Uprostřed dva zelené vinné hrozny na zkřížených žlutých větévkách. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Hruška",
        kod: 543543,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79827,
        latitude: 49.358882,
        longitude: 17.22677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Hru%C5%A1ka_PV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě žluté a zelené, v poměru 1:2:1:2. V zeleném pruhu žlutá hruška se dvěma listy, ve vlajícím zeleném pruhu pět bílých šesticípých hvězd do oblouku k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě hruška se dvěma listy provázená dvěma obilnými klasy se dvěma listy, vše zlaté a převýšené pěti stříbrnými hvězdami do oblouku."
    },
    {
        obec: "Hrubá Skála",
        kod: 577146,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.544906,
        longitude: 15.200342,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Hrub%C3%A1_Sk%C3%A1la_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 6 : 1. V modrém pruhu bílá věž s kvádrovaným soklem, cimbuřím, obloukovým oknem a stanovou střechou, obojí červené, provázená dvěma přivrácenými křídly, na žerďové straně žlutým a na vlající straně bílo-černě kosmo děleným. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zlatém dvoustupňovém soklu stříbrná věž s kvádrovaným soklem, s cimbuřím, obloukovým oknem a stanovou střechou, obojí červené, provázená dvěma přivrácenými křídly, pravým zlatým a levým stříbrno-černě kosmo děleným. Dole vyrůstá zelená hůl závitem do leva kvetoucí do stran dvěma vztyčenými modrými květy zvonky."
    },
    {
        obec: "Hrusice",
        kod: 538248,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25166,
        latitude: 49.909951,
        longitude: 14.738162,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/CoA_of_Hrusice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený s bílou zavinutou střelou v žerďové části, bílý a žlutý, v poměru 2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná zavinutá střela provázená třemi (2,1) zlatými lipovými listy řapíky do středu štítu."
    },
    {
        obec: "Hrušky",
        kod: 584487,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69156,
        latitude: 48.792823,
        longitude: 16.97415,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Hru%C5%A1ky_%28B%C5%99eclav%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 3:1:3. V žerďovém pruhu dvě žluté hrušky pod sebou, ve vlajícím pruhu bílý nůž se žlutou rukojetí, hrotem nahoru a ostřím k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze zlatého pařezu vyrůstající zlatá hrušeň se čtyřmi větvemi a plody, provázená dole vpravo zlatým vinným hroznem a vlevo vztyčeným stříbrným nožem se zlatou rukojetí."
    },
    {
        obec: "Hrušky",
        kod: 593079,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68352,
        latitude: 49.129301,
        longitude: 16.833583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Hru%C5%A1ky_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. V zeleném pruhu tři (1,2) žluté hrušky nad bílým vlnitým pruhem širokým jednu dvacetinu šířky listu a vzdáleným jednu pětinu šířky listu od dolního okraje. V červeném pruhu maltézský kříž nad vykořeněnými lekny, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo ze zeleného trávníku s vlnitým stříbrným břevnem vyrůstá hrušeň přirozené barvy se třemi (1,2) zlatými hruškami, vlevo maltézský kříž nad vykořeněnými lekny, vše stříbrné."
    },
    {
        obec: "Hrušov",
        kod: 565733,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29473,
        latitude: 50.344973,
        longitude: 14.846482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Hru%C5%A1ov_MB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. V červeném pruhu horní polovina lilie, dole spojená s hruškou na stopce se dvěma svěšenými listy, vše žluté. V zeleném pruhu bílá věž, dole s rozšířeným soklem a nad ním s nárožní bosáží a červenou stanovou střechou zakončenou černo-červeno-bíle děleným praporkem se dvěma cípy. Věž je prolomena pěti černými okny (2, 1, 1, 1), dvěma horními obloukovými a v soklu jedním větším. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě, vpravo půl lilie spojené s hruškou na stopce se dvěma svěšenými listy, vše zlaté. Vlevo stříbrná věž, dole s rozšířeným soklem a nad ním s nárožní bosáží, s červenou stanovou střechou, zakončenou černo-červeno-bíle děleným praporkem se dvěma cípy. Věž je prolomena pěti černými okny (2, 1, 1, 1), dvěma horními obloukovými a v soklu jedním větším."
    },
    {
        obec: "Hrušová",
        kod: 580341,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56555,
        latitude: 49.91361,
        longitude: 16.197832
    },
    {
        obec: "Hrušovany",
        kod: 563072,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43143,
        latitude: 50.387359,
        longitude: 13.498471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Hru%C5%A1ovany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 1 : 1 : 4 : 1 : 1. V prostředním pruhu knížecí čepice mezi dvěma do oblouku přivrácenými žlutými obilnými klasy, každý s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený stříbrný hrot s černou orlicí se zlatou zbrojí a perizoniem a s červeným jazykem, převýšený knížecí čepicí mezi dvěma z hrotu vyrůstajícími a do oblouku stočenými zlatými obilnými klasy s listy."
    },
    {
        obec: "Hrušovany u Brna",
        kod: 583081,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66462,
        latitude: 49.038731,
        longitude: 16.59439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Coat_of_arms_of_Hru%C5%A1ovany_u_Brna.svg",
        coatOfArmsFlagDescription: "V zeleném listu kosmo položený žlutý šíp šikmo podložený bílými hráběmi násadou dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo dolů položený zlatý šíp šikmo podložený stříbrnými hráběmi."
    },
    {
        obec: "Hrušovany nad Jevišovkou",
        kod: 594156,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67167,
        latitude: 48.830008,
        longitude: 16.40281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Hru%C5%A1ovany_nad_Jevi%C5%A1ovkou_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - červený, bílý, červený, bílý a červený v poměru 3:1:1:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítu stříbrná štika plující vpravo a pod ní vlevo plující stříbrný kapr."
    },
    {
        obec: "Hrutov",
        kod: 590681,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67527,
        latitude: 49.255545,
        longitude: 15.680298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Znak_obce_Hrutov.jpg",
        coatOfArmsFlagDescription: "Modrý list se šikmým žlutým klínem vycházejícím ze čtvrté čtvrtiny žerďového a první čtvrtiny dolního okraje do horního cípu listu. V žerďovém modrém poli půl žluté svatojakubské mušle a půl bílé lilie. Ve vlajícím modrém poli tři bílé kosmé pruhy široké jednu osminu délky listu a kolmé na dolní stranu klínu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě šikmý zlatý zúžený hrot, nahoře půl zlaté mušle a půl stříbrné lilie, dole tři kosmá stříbrná břevna."
    },
    {
        obec: "Hřebeč",
        kod: 532371,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27345,
        latitude: 50.136054,
        longitude: 14.164571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/H%C5%99ebe%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, červený, žlutý, červený, žlutý, modrý, žlutý a modrý, v poměru 5 : 1 : 1 : 1 : 1 : 1 : 5. Z žerďového a vlajícího okraje listu vynikají vykračující bílí hřebci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě nad zlatou palisádou dva vykračující stříbrní hřebci vynikající z okrajů štítu, mezi nimi dva zkřížené zlaté obilné klasy s listy."
    },
    {
        obec: "Hřebečníky",
        kod: 541761,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27041,
        latitude: 49.984643,
        longitude: 13.753332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Coats_of_arms_H%C5%99ebe%C4%8Dn%C3%ADky.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zubatý černý s bílou hlavou hřebce s krkem bez hřívy, v poměru 1 : 3. Černý pruh má čtyři čtvercové zuby a tři stejné mezery. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zřícenina hradu na zvýšeném návrší, obojí černé, v něm vyrůstající stříbrná hlava hřebce s krkem bez hřívy."
    },
    {
        obec: "Hředle",
        kod: 531219,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.903899,
        longitude: 13.920708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/H%C5%99edle_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, bílý, zelený, červený, zelený a bílý, v poměru 5 : 2 : 6 : 2 : 5. V bílých pruzích po černém, na zadních nohou stojícím zajíci, hledícím ke středu listu. V červeném pruhu nahoře a dole kosmý mlátek šikmo podložený želízekem, vše žluté s bílými topůrky, uprostřed pruhu tři (1, 2) žluté hrušky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zvýšený stříbrný hrot, v něm z modrého vlnitého břevna v patě vyrůstající hrušeň přirozené barvy se třemi (1, 2) zlatými hruškami a se dvěma černými zajíci stojícími na břevnu a opírajícími se ze stran o kmen. Hrot provázen dvěma kosmými mlátky šikmo podloženými želízky, vše zlaté se stříbrnými topůrky."
    },
    {
        obec: "Hředle",
        kod: 541770,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27008,
        latitude: 50.188106,
        longitude: 13.749524,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/HredleOkresRakovnik_ZnakObce.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý kvádrovaný v poměru 4:5. Do zeleného pruhu vyráží bílý býk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě vpravo vyrážející stříbrný býk, levé pole kvádrované."
    },
    {
        obec: "Hřensko",
        kod: 562513,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40717,
        latitude: 50.87451,
        longitude: 14.242617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/H%C5%99ensko_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, červený a zelený. Uprostřed listu na bílém a červeném pruhu zelený věnec z vinné révy, šestkrát obtočený bílo-červenou stuhou. Ve věnci v bílo-černě polceném poli žlutá konvice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-černě polceném štítě zlatá konvice v zeleném věnci z vinné révy, šestkrát obtočeném stříbrno-červenou stuhou."
    },
    {
        obec: "Hřibiny-Ledská",
        kod: 548642,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.148079,
        longitude: 16.175968,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/H%C5%99ibiny-Ledsk%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a bílé pole. U styku pruhu a pole dva buvolí rohy postrkané nahoře dvěma račími klepety, mezi rohy polcený hřib. V červeném pruhu vše bílé, v bílém poli vše černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě dva buvolí rohy postrkané nahoře dvěma račími klepety, pravý stříbrný a levý černý, mezi nimi stříbrno - černě polcený hřib."
    },
    {
        obec: "Hřibojedy",
        kod: 579301,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.392163,
        longitude: 15.834831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/H%C5%99ibojedy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý a zvlněný modrý, v poměru 10 : 1 : 5. V zeleném pruhu vedle sebe dva hřiby s hnědými klobouky a žlutými třeni. Bílý pruh má pět vrcholů a čtyři prohlubně. V modrém pruhu bílá šesticípá hvězda. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře sníženým stříbrným vlnitým břevnem dělený štít. Nahoře dva hřiby přirozené barvy, dole stříbrná hvězda."
    },
    {
        obec: "Hřiměždice",
        kod: 540285,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26214,
        latitude: 49.686956,
        longitude: 14.275016,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/H%C5%99im%C4%9B%C5%BEdice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě modré a bílé, v poměru 4 : 1 : 2 : 1. V horním modrém pruhu říšské jablko mezi dvěma liliemi, v dolním modrém pruhu pět šesticípých hvězd, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě nahoře říšské jablko mezi dvěma liliemi, vše zlaté, dole modré vlnité břevno s pěti zlatými hvězdami."
    },
    {
        obec: "Hříšice",
        kod: 546445,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.103431,
        longitude: 15.495538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Hrisice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. Uprostřed oblouková pila nad pluhem s kolem, obojí v bílém poli červené, v modrém bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě pod obloukovou pilou pluh s kolem, vše červeno-stříbrně polcené."
    },
    {
        obec: "Hříškov",
        kod: 566195,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43904,
        latitude: 50.291438,
        longitude: 13.864363,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/H%C5%99%C3%AD%C5%A1kov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílý postavený hříč, nad ním tři (2,1) žluté obilné snopy, každý o pěti klasech. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrný hříč, nad ním tři (2, 1) zlaté obilné snopy, každý o pěti klasech."
    },
    {
        obec: "Hřivice",
        kod: 566217,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43965,
        latitude: 50.288215,
        longitude: 13.730349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/H%C5%99ivice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě zelené a modré, v poměru 1 : 2 : 2 : 1, a červený klín vycházející z prostředních čtyř šestin horního okraje listu s vrcholem uprostřed listu. V červeném poli bílá svatojakubská mušle, v širším modrém pruhu půl bílého orla se žlutou zbrojí, s korunou na krku a jetelovitě zakončeným perizoniem, vše žluté. V širším zeleném pruhu ze žluté koruny vyniká bílé rameno hříče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vidlicovým řezem červeno-modro-zeleně dělený štít. V horním poli stříbrná svatojakubská mušle. V pravém poli půl stříbrného orla se zlatou zbrojí, korunou na krku a jetelovitě zakončeným perizoniem. V levém poli ze zlaté koruny vyniká stříbrné rameno hříče."
    },
    {
        obec: "Hubenov",
        kod: 587222,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.392193,
        longitude: 15.469125
    },
    {
        obec: "Hřivínův Újezd",
        kod: 585271,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76307,
        latitude: 49.121057,
        longitude: 17.690424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/H%C5%99iv%C3%ADn%C5%AFv_%C3%9Ajezd_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu tři pštrosí pera, červené mezi bílými, vyrůstající ze žluté koruny. V červeném pruhu žlutý patriarší kříž dole přepásaný bílou lilií bez středního listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě dole dvě stříbrná vlnitá břevna, nad nimi vpravo ze zlaté koruny vyrůstající tři pštrosí pera, červené mezi stříbrnými, vlevo zlatý patriarší kříž dole přepásaný stříbrnou lilií bez středního listu."
    },
    {
        obec: "Hudčice",
        kod: 513580,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.531789,
        longitude: 13.922614,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Hud%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnitý zelený, vlnitý bílý a zvlněný zelený, v poměru 9 : 1 : 1 : 5. V horním pruhu mlýnské kolo a houslový klíč, obojí červené, v dolním vztyčená palice podložená zkříženými kamenickými dláty, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně vlnitě dělený štít. Nahoře vedle sebe mlýnské kolo a houslový klíč, obojí červené, dole pod zúženým vlnitým břevnem vztyčená palice podložená dvěma zkříženými kamenickými dláty, vše stříbrné."
    },
    {
        obec: "Hudlice",
        kod: 531227,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26703,
        latitude: 49.961069,
        longitude: 13.970742,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Hudlice_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy červený, bílý a zelený v poměru 2:1:1. V žerďové části červeného pruhu rozevřená bílá kniha s černým textem a žlutou ořízkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze zeleného trojvrší vyrůstající stříbrný korunovaný dvouocasý lev se zlatou zbrojí držící otevřenou stříbrnou knihu s černým textem a zlatou ořízkou."
    },
    {
        obec: "Hukvaldy",
        kod: 598691,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73946,
        latitude: 49.623907,
        longitude: 18.221998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Hukvaldy_CoA.svg",
        coatOfArmsFlagDescription: "List praporu opakuje městský znak. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělený cimbuřovým řezem o pěti stínkách, nahoře stříbrný, dole červený. Nahoře červená liška sedící na dělící čáře, v levém horním rohu doprovázená modrou lyrou, v dolní části čtyři stříbrné kužele."
    },
    {
        obec: "Hulice",
        kod: 529737,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25763,
        latitude: 49.709703,
        longitude: 15.087693,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/CoA_of_Hulice.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký čtvrtinu délky listu a sedm vodorovných pruhů, střídavě červených a bílých, vše překryté modrým zvlněným dolním vlajícím trojúhelníkem dosahujícím k dolnímu rohu. V trojúhelníku šikmo žlutá pastýřská hůl závitem k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmo vlnitě dělený štít. Horní pole vpravo polcené, vpravo červené, vlevo pětkrát červeno-stříbrně dělené. V dolním modrém poli šikmo zlatá pastýřská hůl se zatočenou hlavicí."
    },
    {
        obec: "Hulín",
        kod: 588491,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76824,
        latitude: 49.316992,
        longitude: 17.463848,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Hul%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý svislý žerďový pruh o třetině délky listu se žlutým pšeničným klasem se dvěma lístky a vodorovný bílý a červený pruh. Na středu dolního červeného pole jsou dvě bílá trojúhelníková pole se základnou na dolním okraji listu. Základna trojúhelníkového pole je jedna pětina délky listu. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Humburky",
        kod: 570087,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.226124,
        longitude: 15.511631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/CoA_of_Humburky.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, zubatý červený, bílý a modrý, v poměru 2:1:1:8. Červený pruh má čtyři čtvercové zuby k žerďovému okraji, tři stejné a dvě poloviční mezery. V modrém pruhu žlutý obilný klas vyrůstající z obrácené bílé podkovy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě zvýšeně děleném štítě nahoře lilie mezi dvěma vyrůstajícími hrady se třemi věžemi, prostřední vyšší a širší, vše červené, dole zlatý obilný klas vyrůstající z obrácené stříbrné podkovy."
    },
    {
        obec: "Huntířov",
        kod: 562521,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.789539,
        longitude: 14.303537,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Hunt%C3%AD%C5%99ov-znak.png",
        coatOfArmsFlagDescription: "List s žerďovým bílým pruhem širokým třetinu délky listu a modrým polem, z něhož vyniká půl červené orlice se žlutou zbrojí. Z páté dvanáctiny horního okraje listu do poslední dvanáctiny dolního okraje listu vychází kosmý žlutý pruh. Ve třetí až páté šestnáctině šířky listu z něj vychází k vlajícímu okraji listu vodorovný bílý pruh s červeným středovým křížem s rameny širokými dvacetinu šířky listu. Ve dvanácté až čtrnácté šestnáctině šířky listu vychází ze žlutého pruhu k žerďovému pruhu vlnitý bílý pruh se třemi vrcholy a dvěma prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře polcený štít. Vpravo půl červené orlice se zlatou zbrojí vynikající z poltící linie, levé pole dělené zúženým kosmým zlatým břevnem, nahoře stříbrné břevno s červeným heroldským křížem, dole stříbrné vlnité břevno."
    },
    {
        obec: "Humpolec",
        kod: 547999,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.541624,
        longitude: 15.359423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Humpolec_znak.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovný bílý a modrý pruh, u žerdi červené karé se zkříženými žlutými vidlemi. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Hůrky",
        kod: 559849,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.745983,
        longitude: 13.683207,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/CoA_of_H%C5%AFrky_%28okres_Rokycany%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5:1. V bílém pruhu černá hornická kladívka nad červenou růží se žlutým semeníkem a zelnými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zelenou hůrkou červená růže se zlatým semeníkem a zelenými kališními lístky převýšená černými hornickými kladívky."
    },
    {
        obec: "Hurtova Lhota",
        kod: 530646,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.596462,
        longitude: 15.508912
    },
    {
        obec: "Hůry",
        kod: 535753,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 49.006592,
        longitude: 14.541183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Znak_hury_small.gif",
        coatOfArmsFlagDescription: "Červeno-bíle šikmo dělený list. Z bílého pole vyrůstá bílý lev držící tři žluté obilné klasy, v dolním cípu kosmý mlátek křížem přeložený želízkem, oboje černé na hnědých násadách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně šikmo děleném štítě nahoře vyrůstající stříbrný lev se zlatou zbrojí držící tři zlaté obilné klasy, dole kosmý mlátek křížem přeložený želízkem, obojí černé s násadami přirozené barvy."
    },
    {
        obec: "Husí Lhota",
        kod: 599522,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.435646,
        longitude: 15.00482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Hus%C3%AD_Lhota_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy střídavě červené a zelené, v poměru 1 : 2 : 2 : 1. V širším zeleném pruhu bílá vykračující husa se žlutou zbrojí a jazykem, v širším červeném pod lilií obilný snop, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo stříbrná vykračující husa se zlatou zbrojí a jazykem, vlevo pod lilií obilný snop, obojí zlaté."
    },
    {
        obec: "Husinec",
        kod: 550230,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38421,
        latitude: 49.055065,
        longitude: 13.987074,
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým klínem s vrcholem na vlajícím okraji a dvěma vlajícími modrými klíny vycházejícími z první až páté a osmé až dvanácté dvanáctiny vlajícího okraje. Strany klínů jsou rovnoběžné. V žerďovém klínu kosmo vztyčené veslo, šikmo podložené berlou závitem k žerdi, oboje žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Husinec",
        kod: 538256,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25068,
        latitude: 50.173845,
        longitude: 14.375186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Coats_of_arms_Husinec.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a bílé pole se třemi modrými vodorovnými oboustranně vlnkovitými pruhy. V červeném pruhu bílá vykračující rozkřídlená husa se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrná vykračující rozkřídlená husa se zlatou zbrojí, vlevo tři modrá vlnkovitá břevna."
    },
    {
        obec: "Huslenky",
        kod: 542784,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75602,
        latitude: 49.303274,
        longitude: 18.090479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/CZ_Huslenky_COA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy střídavě bílé a červené v poměru 3:1:1:1. V žerďovém pruhu červené housle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě s vlnitou patou opačných barev vpravo červené housle, vlevo stříbrná vykořeněná lípa."
    },
    {
        obec: "Hustopeče",
        kod: 584495,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.940946,
        longitude: 16.737726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Znak_m%C4%9Bsta_Hustope%C4%8De_hires.svg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část a vlající část rozdělená na tři vodorovné pruhy, červený, žlutý a červený v poměru 1:1:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hustopeče nad Bečvou",
        kod: 513768,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75366,
        latitude: 49.530631,
        longitude: 17.870046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Hustope%C4%8De_nad_Be%C4%8Dvou_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 2:1:1. V žerďové části červeného pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrná věž s rozšířeným kvádrovaným soklem, červeným oknem, cimbuřím a kuželovou střechou se zlatou makovicí, provázená dvěma stříbrnými zavinutými střelami."
    },
    {
        obec: "Huštěnovice",
        kod: 592218,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68703,
        latitude: 49.108271,
        longitude: 17.464577,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Znak_Hu%C5%A1tenovice.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou uťatou lípou podloženou bílým pluhem s koly. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá uťatá lípa, dole podložená pluhem s koly a nahoře provázená vpravo tlapatým křížem a vlevo lilií se dvěma vyrůstajícími růžemi se zlatými semeníky, vše stříbrné."
    },
    {
        obec: "Hutisko-Solanec",
        kod: 542814,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75662,
        latitude: 49.429584,
        longitude: 18.217836,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Hutisko-Solanec_znak.png",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část s bílou lilií nad bílou skleněnou číší a modrá vlající část se žlutým kráčejícím jelenem s červeným jazykem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít, vpravo lilie nad skleněnou číší, obojí stříbrné, vlevo zlatý kráčející jelen s červeným jazykem."
    },
    {
        obec: "Huzová",
        kod: 597414,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79351,
        latitude: 49.820063,
        longitude: 17.298064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Huzova_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 6:1:1. V horním pruhu žlutá rozkřídlená sova sedící na bílém pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá rozkřídlená sova na sníženém stříbrném břevně."
    },
    {
        obec: "Hvězdlice",
        kod: 593087,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68341,
        latitude: 49.193463,
        longitude: 17.083627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Coa_Czech_Town_Hv%C4%9Bzdlice.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou šesticípou hvězdou v žerďové části. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Hvězdonice",
        kod: 529745,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25724,
        latitude: 49.872921,
        longitude: 14.777379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Hv%C4%9Bzdonice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy modrý, bílý a modrý poměru 8:1:7. Do horního modrého pruhu vyrůstá žlutý lev s červenou zbrojí. V dolním modrém pruhu žlutá šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné zúžené břevno, z něhož vyrůstá zlatý lev s červenou zbrojí, dole zlatá hvězda."
    },
    {
        obec: "Hvězdoňovice",
        kod: 590690,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.220011,
        longitude: 15.762318
    },
    {
        obec: "Hvozd",
        kod: 589560,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79855,
        latitude: 49.636303,
        longitude: 16.910181
    },
    {
        obec: "Hvozd",
        kod: 558931,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33101,
        latitude: 49.939841,
        longitude: 13.26323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Hvozd_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a zelený. Uprostřed žlutý maltézský kříž. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře maltézský kříž a koruna, oboje zlaté. Dole kosmý zlatý klas šikmo přeložený stříbrnou doleva obrácenou radlicí."
    },
    {
        obec: "Hvozdec",
        kod: 531235,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.807152,
        longitude: 13.880406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Hvozdec_okr_Beroun_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zelený. V červeném pruhu bílá obrněná noha k vlajícímu okraji s poutem na přetrženém řetězu. V bílém pruhu kosmo položený mlátek šikmo podložený želízkem, obojí černé na hnědých topůrkách. V zeleném pruhu šikmo dva listy, dubový nad březovým, oba bílé a řapíky k dolnímu rohu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě zelené návrší, v něm dva vztyčené a odvrácené stříbrné listy, pravý dubový, levý březový. V pravém poli kosmo položený mlátek šikmo podložený želízkem, obojí černé na topůrkách přirozené barvy. V levém poli doleva obrácená obrněná noha s poutem na přetrženém řetězu, vše stříbrné."
    },
    {
        obec: "Hvožďany",
        kod: 566331,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.505677,
        longitude: 12.771387
    },
    {
        obec: "Hvozdnice",
        kod: 573621,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.191374,
        longitude: 15.711346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Hvozdnice_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29_znak.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list, žerďové pole bílé se zelenou, k vlajícímu okraji otočenou vztyčenou dubovou ratolestí s žaludem a čtyřmi listy. Vlající pole modré s bílým šikmým pruhem, poměr pruhů na vlajícím okraji je 2 : 2 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře šikmo děleném štítě nahoře vztyčená doleva otočená zelená dubová ratolest s žaludem a čtyřmi listy, dole stříbrná, dvakrát prohnutá šála."
    },
    {
        obec: "Hvozdná",
        kod: 585289,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76311,
        latitude: 49.248581,
        longitude: 17.751742,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/CZ_Hvozdn%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý. V horním žerďovém rohu žlutý korunovaný dvouocasý lev s červenou zbrojí držící bílý dubový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý dvouocasý korunovaný lev ve skoku, s červenou zbrojí, drží v předních tlapách stříbrný postavený dubový list."
    },
    {
        obec: "Hvožďany",
        kod: 540315,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26244,
        latitude: 49.528354,
        longitude: 13.805216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Hvozdany_%28Pribram%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělený a shora polcený. V prvním zlatém poli černá uťatá kančí hlava s červeným jazykem a stříbrnými klechtáky. Ve druhém modrém poli zkřížené zlaté jelení parohy, v dolním stříbrném poli na zeleném trávníku tři smrky přirozených barev."
    },
    {
        obec: "Hybrálec",
        kod: 550281,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58601,
        latitude: 49.409551,
        longitude: 15.589163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Hybr%C3%A1lec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 4 : 1. V modrém pruhu čelně bílá radlice hrotem k hornímu okraji listu, v ní černá plamenná orlice s červenou zbrojí a bílým perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě v čelně vztyčené stříbrné radlici černá plamenná orlice s červenou zbrojí a stříbrným perizoniem. Radlice podložena zkříženými stříbrnými kosami se zlatými kosišti a provázena nahoře a dole do oblouku třemi stříbrnými šestilistými květy se zlatými středy."
    },
    {
        obec: "Hvozdnice",
        kod: 539261,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25205,
        latitude: 49.872607,
        longitude: 14.3712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Hvozdnice_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy, zelený jedlový, žlutý a zelený jedlový, v poměru 1 : 6 : 1. Jedlové pruhy mají po třech vrcholech a dvou mezerách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě vpravo na černém trávníku vztyčený král milíře podložený zkříženými uhlířskými hráběmi a pohrabáčem, vše zlaté, vlevo zlaté rozšířené oboustranné jedlové břevno."
    },
    {
        obec: "Hynčice",
        kod: 574163,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54983,
        latitude: 50.623831,
        longitude: 16.285403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Hyn%C4%8Dice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou berlou s bílým sudariem do stran, závitem k žerďovému okraji, přeloženou dolů obrácenou krokví vycházející z třetí až páté třináctiny žerďového a vlajícího okraje, s vrcholem ve dvanácté třináctině šířky listu. Na krokvi tři modré květy lnu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dolů lomené stříbrné břevno se třemi modrými květy lnu, podložené zlatou berlou se stříbrným sudariem do stran."
    },
    {
        obec: "Hynčina",
        kod: 536113,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.848884,
        longitude: 16.793108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Hyn%C4%8Dina_CoA.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým pruhem širokým čtvrtinu délky listu, na dolním okraji vodorovný pruh opačných barev široký pětinu šířky listu. U zeleného žerďového pruhu volný červený skákající jelen. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený jelen skákající k zelenému jehličnatému stromu s černým kmenem na zeleném návrší s čelně postavenou stříbrnou radlicí."
    },
    {
        obec: "Hýskov",
        kod: 531243,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26706,
        latitude: 49.990829,
        longitude: 14.050654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Hyskov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtvrcený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a zelený. V horním žerďovém bílém poli ledňáček říční sedící na zelené vrbové větvi se čtyřmi listy nad modrým vodorovným pruhem vycházejícím z páté šestiny žerďového okraje pole. V horním zeleném poli červeně hořící žlutá kvádrovaná vysoká pec s červeným topeništěm. Dolní žerďové pole červené vedle bílého. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo nad sníženým modrým vlnitým břevnem ledňáček říční přirozené barvy sedící na zelené vrbové větvi se čtyřmi listy, vlevo zlatá kvádrovaná červeně hořící vysoká pec s červeným topeništěm."
    },
    {
        obec: "Hýsly",
        kod: 586200,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69650,
        latitude: 49.023388,
        longitude: 17.179934,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Hysly_coat_of_arms.png",
        coatOfArmsFlagDescription: "Modrý list s bílou krokví vycházející z první třetiny horního a dolního okraje listu s vrcholem na středu vlajícího okraje. V žerďovém klínu žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné rámě držící zlatou vinnou ratolest s jedním hroznem a třemi listy, provázené vlevo nahoře stříbrným berličkovým křížem."
    },
    {
        obec: "Chabařovice",
        kod: 568007,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40317,
        latitude: 50.673287,
        longitude: 13.941955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Chabarovice_znak.png"
    },
    {
        obec: "Chaloupky",
        kod: 531251,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.791959,
        longitude: 13.868465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Chaloupky_BE_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červený, bílý a zelený,v poměru 1 : 1 : 6 : 1 : 1. Uprostřed zkřížené bílé hornické motykyna žlutých násadách, mezi násadami nahoře osmicípá hvězda a dole lilie, obojížluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně děleném štítě nahoře zkřížené stříbrné hornické motyky na zlatých násadách, provázené nahoře osmihrotou hvězdou a dole lilií, obojí zlaté. Dole tři vrchy, mezi zelenými vyšší černý se zkříženými stříbrnými sekerami na zlatých topůrkách."
    },
    {
        obec: "Chabeřice",
        kod: 534081,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.750246,
        longitude: 15.073898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Chabe%C5%99ice_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený s kbelíkem, z něhož vyrůstají čtyři odkloněné obilné klasy, krajní s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě kbelík se čtyřmi vyrůstajícími odkloněnými obilnými klasy, krajní s listem, vše zlaté."
    },
    {
        obec: "Chanovice",
        kod: 556335,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.405001,
        longitude: 13.717463,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Chanovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým kruhovým štítem s puklicí a hrotem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším stříbrný kruhový štít s puklicí a hrotem."
    },
    {
        obec: "Charvatce",
        kod: 565784,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29445,
        latitude: 50.322727,
        longitude: 14.998718,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Charvatce_%28okres_Mlad%C3%A1_Boleslav%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým lvem s červenou zbrojí, držícím přivrácenou bílou rybu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý lev s červenou zbrojí držící přivrácenou stříbrnou rybu."
    },
    {
        obec: "Chářovice",
        kod: 532886,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25741,
        latitude: 49.820354,
        longitude: 14.577967,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Ch%C3%A1%C5%99ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých, v poměru 10 : 1: 1: 1 : 1 : 1 : 1. V horním modrém pruhu zvon mezi dvěma odkloněnými obilnými klasy s listem, vše žluté. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli zvon mezi dvěma odkloněnými obilnými klasy s listem, vše zlaté, dolní pole pětkrát stříbrno-modře dělené."
    },
    {
        obec: "Chbany",
        kod: 563081,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43157,
        latitude: 50.332881,
        longitude: 13.430488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Chbany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový, bíle propleteně mřežovaný pruh, široký třetinu délky listu a modré pole. Ve střední části listu tři (2, 1) žluté lilie nad čtyřmi (2, 2) bílými růžemi se žlutými semeníky, pod nimi položená žlutá hruška s listem k hornímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé červené pole stříbrně proplétaně mřežované. V levém modrém čtyři (2, 2) stříbrné růže se zlatými semeníky, nad nimi tři (2, 1) lilie, pod nimi doleva položená hruška s listem, vše zlaté."
    },
    {
        obec: "Cheb",
        kod: 554481,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.079633,
        longitude: 12.369964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Cheb_coat_of_arms.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed listu znak města. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Chelčice",
        kod: 551139,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.121887,
        longitude: 14.169215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Chelcice-znak.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým žerďovým pruhem širokým jednu čtvrtinu délky listu, ke kterému přiléhá žluté půlkruhové pole se středem vzdáleným jednu čtvrtinu délky listu od žerďového a horního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě kosmo děleném štítě nahoře zlaté gotické majuskulní 'P', dole zelené jablko."
    },
    {
        obec: "Charváty",
        kod: 568872,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78375,
        latitude: 49.518462,
        longitude: 17.254067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Charv%C3%A1ty_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů střídavě modrých a vlnitých bílých s třemi vrcholy a dvěma prohlubněmi, v poměru 14 : 1 : 1 : 1 : 1 : 1 : 1. V horním pruhu krojidlo a radlice hroty k hornímu a ostřím k žerďovému okraji listu provázené třemi (1, 2) hvězdami, nahoře větší osmicípou, po stranách šesticípými, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčené krojidlo a radlice provázené třemi (1, 2) hvězdami, horní větší a osmihrotá, dole tři snížená zúžená vlnitá břevna, vše stříbrné."
    },
    {
        obec: "Cheznovice",
        kod: 559857,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33806,
        latitude: 49.779032,
        longitude: 13.785491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Cheznovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým šerkem (cvoček do bot). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý šerk (cvoček do bot)."
    },
    {
        obec: "Chleby",
        kod: 532878,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25741,
        latitude: 49.827106,
        longitude: 14.556986
    },
    {
        obec: "Chlebičov",
        kod: 512923,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74731,
        latitude: 49.959517,
        longitude: 17.967589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Chlebicov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený se třemi bílými klasy vyrůstajícími z dolního bílého pruhu, ve kterém je červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít, nahoře vedle sebe vyrůstají tři stříbrné obilné klasy, dole červené srdce."
    },
    {
        obec: "Chleby",
        kod: 599620,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28931,
        latitude: 50.222917,
        longitude: 15.089496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Coats_of_arms_Chleby.jpeg",
        coatOfArmsFlagDescription: "Žluto-zeleně čtvrceně dělený list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-zeleně dělený štít, nahoře dva chlebové pecny přirozené barvy, dole stříbrné kosmé vlnité břevno."
    },
    {
        obec: "Chleny",
        kod: 576310,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51745,
        latitude: 50.077977,
        longitude: 16.243846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Chleny.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed listu vykořeněný žlutý strom, v zeleném pruhu s listy a plodenstvím lípy, v červeném pruhu s listy a nažkou javoru-klenu. Poměr šířky k délce listu vlajky je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vykořeněný zlatý strom, vpravo s listy a plodenstvím lípy, vlevo s listy a nažkou javoru-klenu."
    },
    {
        obec: "Chlistov",
        kod: 556343,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.320001,
        longitude: 13.360983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Chlistov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý kvádrovaný bílý, v poměru 9 : 7. V modrém pruhu vzlétající čejka s červenou zbrojí, v bílém se čtyřmi zuby a třemi mezerami hlubokými polovinu šířky zubu červený tlapatý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na stříbrné kvádrované hradbě se čtyřmi stínkami vzlétající čejka přirozené barvy s červenou zbrojí. V hradbě červený tlapatý kříž."
    },
    {
        obec: "Chlístov",
        kod: 590703,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.202158,
        longitude: 15.743777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Chl%C3%ADstov_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým šikmým pruhem vycházejícím z druhé třetiny dolního okraje do první poloviny vlajícího okraje listu. V žerďové polovině bílý anděl se žlutou svatozáří, s dolů obráceným kopím se žlutým hrotem v pravici a žlutou přirozenou lilií vyrůstající na bílém stonku u levé nohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě snížený červený hrot převýšený červeným cimbuřovým břevnem. V hrotu stříbrný anděl se zlatou svatozáří, s dolů obráceným kopím se zlatým hrotem v pravici a zlatou přirozenou lilií vyrůstající na stříbrném stonku u levé nohy, hrot provázen vpravo patriarším křížem, vlevo poštovní trubkou, obojí černé."
    },
    {
        obec: "Chlístov",
        kod: 548791,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.322469,
        longitude: 16.169563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Chl%C3%ADstov_RK_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, modrý, žlutý, modrý, červený, žlutý a červený, v poměru 2 : 1 : 5 : 5 : 1 : 2. Uprostřed zvon provázený po stranách dole zkříženými lískovými větvemi, každá s osmi listy a trojicí spojených lískových oříšků, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zvon se závěsem, provázený dvěma, dole zkříženými lískovými větvemi, každá s osmi listy a trojicí spojených lískových oříšků, vše zlaté."
    },
    {
        obec: "Chlístov",
        kod: 532045,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.801625,
        longitude: 14.654822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Chl%C3%ADstov_%28Bene%C5%A1ov_District%29_CoA.jpg"
    },
    {
        obec: "Chlum",
        kod: 529982,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.447303,
        longitude: 13.851194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/CoA_of_Chlum_%28Strakonice_District%29.svg",
        coatOfArmsFlagDescription: "List vlajky tvoří čtyři vodorovné pruhy, modrý, bílý, zelený a černý, v poměru 1 : 1 : 1 : 2. V modrém pruhu pět žlutých šesticípých hvězd. V černém pruhu žlutý vlnitý pruh se třemi vrcholy a dvěma prohlubněmi široký polovinu šířky pruhu. Poměr šířky k délce listu vlajky je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-černě dělený štít s modrou hlavou s pěti zlatými hvězdami. Nahoře zelený kopec, dole zlaté vlnité břevno."
    },
    {
        obec: "Chlístovice",
        kod: 534099,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.885817,
        longitude: 15.202598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Chl%C3%ADstovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 5 : 4, bílý a zubatý červený se třemi zuby vysokými devítinu šířky listu a čtyřmi stejnými mezerami. V bílém pruhu červená deseticípá hvězda, v červeném bílá zavinutá střela. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně cimbuřově dělený štít. Nahoře červená desetihrotá hvězda, dole stříbrná zavinutá střela."
    },
    {
        obec: "Chlum",
        kod: 590711,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.313525,
        longitude: 15.767159,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Chlum_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s horním žerďovým bílým trojúhelníkem. U žerdi zelený smrk s hnědým kmenem, v zeleném poli váhy s korunou mezi miskami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelené návrší, na něm černé větrné vodní čerpadlo provázené po stranách čtyřmi (2, 2) vyrůstajícími smrky v přirozených barvách. V návrší váhy s korunou mezi miskami, vše zlaté."
    },
    {
        obec: "Chlum",
        kod: 561614,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.578538,
        longitude: 14.562772,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Chlum_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu osminu šířky listu. Uprostřed volný červený ostrvový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře stříbrno-zeleně polcený, vpravo červený ostrvový kříž, vlevo vyrůstající zlatý drak s červenou zbrojí, v dolním, zeleno-červeně polceném poli stříbrná lekna vyrůstající ze zlatého návrší."
    },
    {
        obec: "Chlum",
        kod: 557773,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.605305,
        longitude: 13.479305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Chlum_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3:1. Ze zeleného pruhu vyrůstá bílá kaple s černými dveřmi a prázdnou nikou nad nimi, s červenou stanovou střechou zakončenou lucernou s červenou bání a žlutým křížkem, provázená z každé strany do půlkruhu třemi odvrácenými žlutými lipovými listy, horní největší, dolní nejmenší. V zeleném pruhu dolní polovina bílé lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrná kaple s černými dveřmi a prázdnou nikou nad nimi, s červenou stanovou střechou zakončenou lucernou s červenou bání a zlatým křížkem, provázená z každé strany do oblouku třemi odvrácenými zlatými lipovými listy, horní největší, spodní nejmenší, v návrší dolní polovina stříbrné lilie."
    },
    {
        obec: "Chlum",
        kod: 529770,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25763,
        latitude: 49.693104,
        longitude: 14.99586,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modro-bíle polcený a zelený, v poměru 5:3. V horním pruhu vyrůstá od pařátů bílo-červeně polcená orlice se žlutou zbrojí a žlutým, jetelovitě zakončeným perizoniem. V zeleném pruhu pět šesticípých žlutých hvězd vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě stříbrno-červeně polcená orlice se zlatou zbrojí a zlatým, jetelovitě zakončeným perizoniem, vyrůstající od pařátů ze zeleného vrchu s pěti (2,3) zlatými hvězdami."
    },
    {
        obec: "Chlum",
        kod: 540803,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.935789,
        longitude: 13.65979,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Chlum_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený zeleným šikmým pruhem na žerďové bílé a vlající žluté pole. Horní okraj pruhu tvoří úhlopříčku listu, šířka pruhu na vlajícím okraji je dvě pětiny šířky listu. V bílém poli šikmo zelená větévka tisu červeného se dvěma červenými plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném návrší s doleva položenou zelenou větévkou tisu červeného se dvěma červenými plody čtyři vyrůstající zlaté jehličnaté stromy."
    },
    {
        obec: "Chlum u Třeboně",
        kod: 546461,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37804,
        latitude: 48.96245,
        longitude: 14.928089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Chlum_u_Trebone_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílý list s modrým svislým středovým oboustranně vlnkovitým pruhem a modrým svislým vlnkovitým pruhem na vlajícím okraji. Šířka každého pruhu a mezery mezi nimi je jednou pětinou délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou vlnkovitou patou snížené modré vlnkovité břevno."
    },
    {
        obec: "Chlumčany",
        kod: 557781,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33442,
        latitude: 49.632666,
        longitude: 13.313337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Znak_obce_Chlum%C4%8Dany.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů střídavě bílých a černých. V žerďovém pruhu sedm žlutých šesticípých hvězd ve dvou svislých řadách (4,3). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-černě polceném štítě pod modrou hlavou se sedmi (4,3) zlatými hvězdami na zeleném návrší dva býčí rohy opačných barev vyrůstající ze zlaté koruny."
    },
    {
        obec: "Chlumany",
        kod: 550248,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.06667,
        longitude: 13.965462,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Chlumany_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 3 : 1. V zeleném pruhu obilný klas se dvěma listy, podložený kosmo cepem a šikmo hráběmi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou vlnitou patou se zelenou lilií obilný klas se dvěma listy podložený kosmo cepem a šikmo hráběmi, vše zlaté."
    },
    {
        obec: "Chlumčany",
        kod: 566233,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43903,
        latitude: 50.335042,
        longitude: 13.843328
    },
    {
        obec: "Chlum Svaté Maří",
        kod: 560375,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35709,
        latitude: 50.149949,
        longitude: 12.535762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Chlum_Svate_Mari_CZ_CoA.svg"
    },
    {
        obec: "Chlumec",
        kod: 536229,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.870362,
        longitude: 14.395976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Chlumec_%C4%8CK_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo vlnitě dělený list. V bílém poli černá lilie, v zeleném kosmo vztyčený žlutý lipový dvoulist s plodenstvím uprostřed. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně šikmo vlnitě dělený štít. Vpravo černá lilie, vlevo kosmo vztyčený zlatý lipový dvoulist s plodenstvím uprostřed."
    },
    {
        obec: "Chlumec",
        kod: 568015,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40339,
        latitude: 50.699825,
        longitude: 13.939752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Chlumec_UL_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 :1. Z prostředních čtyř šestin zeleného pruhu vyniká zelený vrch, na něm kráčející bílý lev se žlutou zbrojí držící v pravé přední tlapě bílý meč se žlutým jílcem hrotem k hornímu okraji. Pod lvem tři (1, 2) hroty se dotýkající šesticípé žluté hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném vrchu se třemi (1, 2) hroty se dotýkajícími zlatými hvězdami kráčející stříbrný lev se zlatou zbrojí, držící v pravé přední tlapě vztyčený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Chlumec nad Cidlinou",
        kod: 570109,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.154506,
        longitude: 15.460363,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/CoA_of_Chlumec_nad_Cidlinou.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký polovinu délky listu a tři vodorovné pruhy, bílý, vlnitý modrý a zvlněný bílý, v poměru 4:1:4. V žerďovém pruhu bílý kvádrovaný kostel s průčelím u žerdi, červenou sedlovou střechou zakončenou žlutými makovicemi s křížky. V průčelí dveře pod obloukovým oknem, v boční stěně tři stejná okna, vše černé. Uprostřed střechy bílá věžička s hranou obrácenou dopředu a dvěma černými obloukovými okny, žlutým cimbuřím a červenou kopulí zakončenou žlutým křížkem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chlumek",
        kod: 595713,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59442,
        latitude: 49.374585,
        longitude: 15.854861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Chlumek_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 4:1. V bílém pruhu lipový a kaštanový list, oba zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad vrchem (Chlumem) vedle sebe lipový a kaštanový list, vše zelené."
    },
    {
        obec: "Chlumětín",
        kod: 595721,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59202,
        latitude: 49.727586,
        longitude: 16.003178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/CoA_of_Chlum%C4%9Bt%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červeno-bíle kosmo dělený a žlutý v poměru 1:2. Z dolního okraje listu vyrůstá zelený kopec (chlum). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod stříbrno-červeně kosmo dělenou hlavou zelený kopec (Chlum)."
    },
    {
        obec: "Chlumín",
        kod: 534820,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27743,
        latitude: 50.289218,
        longitude: 14.449384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Chlumin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, bílý, červený, bílý, červený, bílý a modrý, v poměru 4 : 4 : 1 : 2 : 1 : 4 : 4. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou s vytaženou černou mříží a otevřenými stříbrnými vraty na černých závěsech. Nad branou vyniká stříbrná věž s černým oknem, s cimbuřím a červenou stanovou střechou se zlatou makovicí. Po stranách věže dva červené štítky se stříbrným břevnem."
    },
    {
        obec: "Chlum-Korouhvice",
        kod: 595705,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.575317,
        longitude: 16.320783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Chlum-Korouhvice.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným vrchem na dolním okraji listu, vysokým pět dvanáctin šířky listu. Nad vrchem na zkřížených černých žerdích korouhve s ocasem, na kosmé žerdi červená, na šikmé modrá. Ve vrchu svěšený žlutý lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným vrchem se svěšeným zlatým lipovým listem zkřížené korouhve na černých žerdích, kosmá červená, šikmá modrá."
    },
    {
        obec: "Chlumy",
        kod: 540307,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.43786,
        longitude: 13.638713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Chlumy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, v poměru 2 : 1 : 7, zelený, obloukový bílo-červeně polcený s vrcholem sahajícím do desetiny šířky listu a zelený. V dolním pruhu bílé průčelí kaple s prázdnými dveřmi a lucernou se zvonem, zakončenou křížkem a provázenou dvěma žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě, pod stříbrno-červeně polceným zúženým obloukovým břevnem v hlavě štítu, stříbrné průčelí kaple s prázdnými dveřmi a lucernou se zvonem, zakončenou křížkem a provázenou dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Chlustina",
        kod: 534455,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.878043,
        longitude: 13.919627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Chlustina_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3 : 5. V červeném pruhu žlutá zavřená liliová koruna.V zeleném pruhu žlutý obilný snop, podložený odvrácenými bílými kosami na zkřížených hnědých kosištích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře zlatá zavřená liliová koruna, dole zlatý obilný snop podložený odvrácenými stříbrnými kosami na zkřížených kosištích přirozené barvy."
    },
    {
        obec: "Chmelík",
        kod: 572730,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.773488,
        longitude: 16.332883,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Chmel%C3%ADk_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Uprostřed listu vztyčený chmelový stonek, v červeném pruhu bílý s listem nad dvěma šišticemi, v bílém černý se třemi (2, 1) šišticemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vztyčený chmelový stonek, vpravo stříbrný s listem nad dvěma šišticemi, vlevo černý se třemi (2, 1) šišticemi."
    },
    {
        obec: "Chmelná",
        kod: 529788,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.648205,
        longitude: 14.988457
    },
    {
        obec: "Chobot",
        kod: 536903,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.462516,
        longitude: 13.93382
    },
    {
        obec: "Choceň",
        kod: 580350,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.001704,
        longitude: 16.223131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Choce%C5%88.svg",
        coatOfArmsFlagDescription: "Červený list se svatým Václavem v pravici se štítem a v levici s praporcem provázený dvěma vzlétajícími anděly ve žlutých řízách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě sv. Václav v pravici se štítem a v levici s praporcem provázený vzlétajícími anděly ve zlatých řízách."
    },
    {
        obec: "Chocenice",
        kod: 557803,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.547648,
        longitude: 13.520152
    },
    {
        obec: "Chocerady",
        kod: 529796,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25724,
        latitude: 49.873005,
        longitude: 14.802174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Chocerady_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý s jedním vrcholem a dvěma prohlubněmi a modrý, v poměru 2 : 1 : 1. V horním modrém pruhu žlutý korunovaný lev s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem zlatý korunovaný lev s červeným jazykem."
    },
    {
        obec: "Chocnějovice",
        kod: 535923,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29413,
        latitude: 50.577007,
        longitude: 14.971496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Chocn%C4%9Bjovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý, v poměru 3 : 7. V červeném pruhu běžící hranostaj s modrým, žlutě lemovaným obojkem se žlutým kroužkem. Ve žlutém pruhu modrý korunovaný dvouocasý lev s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít s červenou hlavou. V hlavě běžící hranostaj přirozené barvy s modrým, zlatě lemovaným obojkem se zlatým kroužkem. Ve štítě modrý korunovaný dvouocasý lev s červenou zbrojí."
    },
    {
        obec: "Chocomyšl",
        kod: 553727,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.467282,
        longitude: 13.13077,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Chocomy%C5%A1l_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a sedm vodorovných pruhů, v poměru 14 : 1 : 1 : 1 : 1 : 1 : 1, střídavě bílých a modrých vlnitých s pěti vrcholy a čtyřmi prohlubněmi. V červeném pruhu pět bílých šesticípých hvězd do oblouku s vrcholem u žerďového okraje. V horním bílém pruhu šikmo červená lipová ratolest střídavě se třemi listy a dvěma plodenstvími. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně vpravo polcený štít. Vpravo pět stříbrných hvězd nad sebou do oblouku, vlevo červená lipová ratolest střídavě se třemi listy a dvěma plodenstvími nad třemi sníženými zúženými modrými vlnitými břevny."
    },
    {
        obec: "Chodouň",
        kod: 534447,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.898288,
        longitude: 13.986668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Chodoun_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďové pětiúhelníkové pole a tři vodorovné pruhy, zelený, bílý a zelený. Pětiúhelníkové pole tvoří tři vodorovné pruhy, bílý, černý a bílý, jeho vrchol dosahuje do tří pětin délky listu a jeho strany na horním a dolním okraji mají délku dvou pětin délky listu. V černém pruhu žluté perizonium. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně vydutým klínovým řezem dělený štít, nahoře vyrůstá černá orlice se zlatým perizoniem a červenou zbrojí. Dole stříbrné vlnité břevno."
    },
    {
        obec: "Chodouny",
        kod: 564923,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41171,
        latitude: 50.47327,
        longitude: 14.250171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Chodouny_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý v poměru 1:4:1. V modrém pruhu pět obilných klasů dole přeložených žlutým půlměsícem cípy nahoru. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě pět obilných klasů, přes ně stoupající půlměsíc, obojí zlaté."
    },
    {
        obec: "Chodov",
        kod: 578011,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36464,
        latitude: 50.068626,
        longitude: 12.863016
    },
    {
        obec: "Chodov",
        kod: 560383,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35735,
        latitude: 50.23983,
        longitude: 12.747709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Chodov_znak.png",
        coatOfArmsFlagDescription: "List čtvrcený, horní žerďové a dolní vlající pole je zelené, horní vlající a dolní žerďové pole je žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chodov",
        kod: 553735,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34533,
        latitude: 49.418088,
        longitude: 12.830346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Chodov_DO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Zelený list s párem odvrácených bílých plstěných bot (válenek). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pár odvrácených plstěných bot (válenek), pod nimi vlčí udice kroužkem nahoru, vše stříbrné."
    },
    {
        obec: "Chodová Planá",
        kod: 560901,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34813,
        latitude: 49.893323,
        longitude: 12.730254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Znakchplana.png",
        coatOfArmsFlagDescription: "Červený list s bílým vzpřímeným lvem se žlutou zbrojí a jazykem obráceným k vlajícímu okraji listu a držícím žlutým hrotem zakončené hnědé ratiště se žlutým, k vlajícímu okraji listu vlajícím, dvoucípým praporcem, v něm červený štítek s bílým břevnem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Chodovlice",
        kod: 564931,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.463209,
        longitude: 13.993372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Chodovlice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 7 : 1. Na horním okraji zeleného pruhu bílý kanelovaný sloup bez hlavice a patky, stojící na dvou bílých stupních, se třemi kosmými vavřínovými věnci s červenými plody pod sebou. Na sloupu bílý holub se žlutou zbrojí. Z horního stupně vynikají k žerďovému okraji kosmo dva obilné klasy a kosa hrotem k dolnímu rohu listu, k vlajícímu okraji vynikají šikmo hrábě a dva obilné klasy, krajní klasy s odkloněným listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku stříbrný kanelovaný sloup bez hlavice a patky, stojící na dvou stříbrných stupních, se třemi kosmými zelenými vavřínovými věnci s červenými plody pod sebou. Na sloupu stříbrný holub se zlatou zbrojí. Z horního stupně vynikají vpravo kosmo dva obilné klasy a kosa, vlevo šikmo hrábě a dva obilné klasy, krajní klasy s odkloněným listem, vše zlaté."
    },
    {
        obec: "Chodská Lhota",
        kod: 553743,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.358456,
        longitude: 13.083271,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Chodska_Lhota_zank.JPG",
        coatOfArmsFlagDescription: "Žlutý list s černým žerďovým pruhem širokým jednu třetinu délky listu. V černém pruhu žlutá vlčí udice hroty k vlajícímu okraji, ve žlutém poli černá psí hlava s krkem a červeným jazykem, šikmo podložená černým čakanem na červeném topůrku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod černou hlavou s dolů obrácenou zlatou vlčí udicí černá psí hlava s krkem a červeným jazykem, šikmo podložená černým čakanem na červeném topůrku."
    },
    {
        obec: "Chodský Újezd",
        kod: 560910,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.864425,
        longitude: 12.650047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Chodsky_ujezd_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílou lví tlapou se žlutou zbrojí držící kosmý čakan a šikmý latinský kříž, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná lví tlapa se zlatou zbrojí držící kosmý čakan a šikmý latinský kříž, obojí zlaté."
    },
    {
        obec: "Cholenice",
        kod: 549355,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.324828,
        longitude: 15.280763,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Cholenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý, v poměru 13 : 5 : 64 : 5 : 13. V červeném pruhu vykračující okřídlený bílý lev se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrný vykračující okřídlený lev se zlatou zbrojí a jazykem, převýšený vpravo vavřínovým věncem a vlevo podkovou, obojí zlaté."
    },
    {
        obec: "Cholina",
        kod: 502839,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.656198,
        longitude: 17.054513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Coat_of_arms_of_Cholina.png",
        coatOfArmsFlagDescription: "List tvoří svislý červeno-žlutě dělený žerďový pruh široký jednu čtvrtinu délky listu, a modré pole s polovinou bílého dvouocasého lva s červenou zbrojí. V žerďovém pruhu pod sebou tři osmicípé hvězdy opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-zlatě polcenou hlavou se třemi osmihrotými hvězdami opačných barev vyrůstající stříbrný dvouocasý lev s červenou zbrojí."
    },
    {
        obec: "Choltice",
        kod: 575054,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53361,
        latitude: 49.987822,
        longitude: 15.619515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Znak_obce_Choltice.svg",
        coatOfArmsFlagDescription: "Červený list s bílým mlýnským kamenem, převýšeným zkříženými bílými hornickými kladívky na hnědých násadách. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chomle",
        kod: 541141,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.86238,
        longitude: 13.63461
    },
    {
        obec: "Chomutice",
        kod: 572969,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50753,
        latitude: 50.359371,
        longitude: 15.496287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Chomutice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed tři vodorovné vlnité pruhy, bílý, modrý a bílý, každý široký jednu dvacetinu délky listu. V horním zeleném pruhu tři podkovy, v dolním zeleném pruhu pluh s koly, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvakrát stříbrno-modře vlnitě dělené vlnité břevno, provázené nahoře třemi zlatými podkovami a dole zlatým pluhem s koly."
    },
    {
        obec: "Chomutov",
        kod: 562971,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43004,
        latitude: 50.463598,
        longitude: 13.410837,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/ChomutovCoA.PNG",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed listu znak města. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Chomýž",
        kod: 588504,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.362473,
        longitude: 17.645513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Chomyz_znak.jpg",
        coatOfArmsDescription: "Štít dělený, v horním modrém poli vyrůstají z dělící čáry ze společného stonku se šesti listy přirozené barvy tři stříbrné květy o šesti okvětních lístcích se zlatými semeníky. Dolní pole je děleno trojím cimbuřovým řezem, střídavě stříbrně a červeně."
    },
    {
        obec: "Choratice",
        kod: 532606,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.83943,
        longitude: 14.874791
    },
    {
        obec: "Chornice",
        kod: 578096,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56942,
        latitude: 49.667478,
        longitude: 16.742731
    },
    {
        obec: "Chorušice",
        kod: 534838,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27737,
        latitude: 50.390682,
        longitude: 14.670873
    },
    {
        obec: "Choryně",
        kod: 542831,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75642,
        latitude: 49.495846,
        longitude: 17.898722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/CZ_Choryn%C4%9B_COA.svg",
        coatOfArmsFlagDescription: "V bílé žerďové části černý buvolí roh postrkaný třemi červenými radlicemi a v zelené vlající části bílý buvolí roh postrkaný třemi žlutými radlicemi. Rohy jsou ve střední části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít, vpravo černý buvolý roh postrkaný třemi červenými radlicemi, vlevo stříbrný buvolí roh postrkaný třemi zlatými radlicemi."
    },
    {
        obec: "Choťánky",
        kod: 537217,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.138263,
        longitude: 15.16254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Cho%C5%A5%C3%A1nky_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, žlutý a červený v poměru 8:3:2:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná patrová tvrz s cimbuřím a valbovou střechou, s černou branou, dvěma kruhovými střílnami po stranách a pěti okny. Na střeše tvrze sedí zlatá kočka."
    },
    {
        obec: "Chotěboř",
        kod: 568759,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.720714,
        longitude: 15.671142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Chotebor.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 3 : 2. V žerďové polovině červeného pruhu český lev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě český lev."
    },
    {
        obec: "Chotěbudice",
        kod: 550477,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.058086,
        longitude: 15.574099
    },
    {
        obec: "Chotěbuz",
        kod: 555291,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73561,
        latitude: 49.768592,
        longitude: 18.569232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Chot%C4%9Bbuz_znak.png",
        coatOfArmsFlagDescription: "Zelený list se svislým pilovitým žlutým pruhem na vlajícím okraji širokým jednu šestinu délky listu. V zeleném poli dvě zkřížené kosy, propletené se dvěma vztyčenými zkříženými srpy, vše bílé na žlutých násadách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou palisádou dvě zkřížené kosy, propletené se dvěma vztyčenými zkříženými srpy, vše stříbrné na zlatých násadách."
    },
    {
        obec: "Choteč",
        kod: 549037,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50781,
        latitude: 50.432957,
        longitude: 15.516036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Chote%C4%8D_%28okres_Ji%C4%8D%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, černo - bíle šachovaný (4 x 8) a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě utržená černo - stříbrně šachovaná hlava kozla s krkem."
    },
    {
        obec: "Choteč",
        kod: 575062,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53304,
        latitude: 50.083128,
        longitude: 15.880341,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Chotec_PA_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý klín s vrcholem ve dvou třetinách délky listu a pět vodorovných pruhů, střídavě bílých a černých, v poměru 3:4:6:4:3. V klínu vodorovně tři žluté žitné klasy s listy, vrcholy k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrný štít s modrým zvýšeným vydutým hrotem, v něm tři zlaté žitné klasy s listy, vzpřímený mezi zlomenými, z hrotu vyrůstá na každé straně jeden černý zubří roh."
    },
    {
        obec: "Choteč",
        kod: 539287,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25226,
        latitude: 49.986657,
        longitude: 14.283022,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Chote%C4%8D_PZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zubatý bílý pruh široký třetinu délky listu a zelené pole. V bílém pruhu se šesti zuby, jejichž mezery sahají do čtvrtiny délky listu, jsou pod sebou tři červené růže se žlutými semeníky a zelenými kališními lístky. V zeleném poli půl k žerďovému okraji zlomeného žlutého kola s bílými noži, jehož šikmé rameno bez nože je podloženo zlomeným bílým šípem s červeným opeřením k hornímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně cimbuřově vpravo polceném štítě vpravo tři červené růže se zlatými semeníky a zelenými kališními lístky pod sebou, vlevo levá půle zlomeného zlatého kola se stříbrnými noži, jehož šikmé rameno bez nože je podloženo postaveným zlomeným stříbrným šípem s červeným opeřením."
    },
    {
        obec: "Chotěmice",
        kod: 552453,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.275324,
        longitude: 14.877036
    },
    {
        obec: "Chotěnov",
        kod: 578100,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.823356,
        longitude: 16.189972,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Chot%C4%9Bnov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červeno-bíle šikmo dělený list. V červeném poli dva bílé šikmé pruhy vycházející z páté a sedmé desetiny žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrné palium."
    },
    {
        obec: "Chotěšov",
        kod: 557838,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33214,
        latitude: 49.65419,
        longitude: 13.202822,
        coatOfArmsFlagDescription: "List tvoří žlutý svislý žerďový pruh rovný jedné třetině délky listu se třemi černými parožími (2, 1) a vodorovný horní bílý a dolní modrý pruh. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít, nahoře stříbrná osmihrotá hvězda, dole troje (2,1) jelení parohy."
    },
    {
        obec: "Chotěšov",
        kod: 564940,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.439891,
        longitude: 14.085254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Chotesov_%28Litomerice%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma odkloněnými obilnými klasy, každý s jedním listem, střídavě mezi třemi, krajními odkloněnými liliemi na stoncích, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva odkloněné obilné klasy, každý s jedním listem, střídavě mezi třemi, krajními odkloněnými liliemi na stoncích, vše zlaté."
    },
    {
        obec: "Chotětov",
        kod: 535931,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29428,
        latitude: 50.337534,
        longitude: 14.801624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Chot%C4%9Btov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: červený, žlutý, bílý hermelínový, žlutý a červený v poměru 8:1:14:1:8. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě hermelínový kůl, provlečený zlatou liliovou korunou o třech viditelných listech, s červeným vyložením a zlatým obloukem vrcholícím křížkem."
    },
    {
        obec: "Chotěvice",
        kod: 579319,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54371,
        latitude: 50.521498,
        longitude: 15.766799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Chot%C4%9Bvice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část s bílou vidlicí a modrá vlající část s bílým kolem sv. Kateřiny, vidlice vychází z horní a dolní pětiny žerďového okraje a její ramena jsou široká jednu sedminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo vidlice, vlevo kolo sv. Kateřiny, obojí stříbrné."
    },
    {
        obec: "Chotíkov",
        kod: 558940,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33017,
        latitude: 49.793052,
        longitude: 13.317774,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Chot%C3%ADkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký třetinu délky listu, a dva vodorovné pruhy, bílý a zelený. V červeném pruhu bílý kříž s rameny širokými osminu šířky listu, jehož vodorovné rameno vychází ze čtvrté osminy šířky listu. Kříž je přeložený žlutým kruhovým polem o průměru pětiny délky listu se třemi (1, 2) černými hřeby. Ve střední části listu v bílém pruhu kosmo letící krkavec, pod ním v zeleném pruhu žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - stříbrně polcený štít. Vpravo stříbrný latinský kříž do krajů, přeložený zlatým kotoučem se třemi (1, 2) černými hřeby, vlevo nad zeleným návrším se zlatým zvonem kosmo letící černý krkavec."
    },
    {
        obec: "Chotěšice",
        kod: 537225,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28901,
        latitude: 50.278663,
        longitude: 15.273164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Chot%C4%9B%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený vodorovný pruh při horním okraji, široký třetinu šířky listu a dva svislé pruhy, bílý a černý. V červeném pruhu žlutý kapr, v bílém pruhu černá beraní hlava se žlutou zbrojí a červeným jazykem, v černém pruhu šikmý žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře v červeném poli zlatý kapr. Dolní pole stříbrno-černě polceno, vpravo černá beraní hlava se zlatou zbrojí a červeným jazykem, vlevo šikmý zlatý obilný klas se dvěma listy."
    },
    {
        obec: "Chotilsko",
        kod: 540323,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.770587,
        longitude: 14.352585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Chotilsko_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, modrý, bílý a zelený, v poměru 1:2:2:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít se stříbrným sníženým vlnitým břevnem. Vpravo zlaté slunce a pod ním šikmo položené želízko křížem přeložené mlátkem, obojí stříbrné na hnědých topůrkách. Vlevo na zeleném vrchu dřevěná rozhledna se střechou přirozené barvy."
    },
    {
        obec: "Chotiměř",
        kod: 564958,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.548326,
        longitude: 14.000996,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Chotim%C4%9B%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé nad čtvercovým polem a dva vodorovné pruhy, bílý a červený. V karé bílo-červeně šachované orlí křídlo. Čtvercové pole je pilovitě polceno červeno-bíle třemi zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vyrůstají ze stříbrno-červeně polceného cimbuří na zlatých žerdích dvě odvrácené korouhve s ocasy, vpravo stříbrná se třemi sníženými červenými hroty, vlevo modrá se stříbrno-červeně šachovaným orlím křídlem."
    },
    {
        obec: "Chotiněves",
        kod: 564966,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.552187,
        longitude: 14.279353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Chotin%C4%9Bves_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V žerďové polovině bílého pruhu vedle sebe dva propletené zelené věnce, žerďový se šesti červenými růžemi se žlutými semeníky, vlající chmelový se šesti trojicemi žlutých šištic. Pod věnci v modrém pruhu žlutá díž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře vedle sebe dva zelené propletené věnce, vpravo listový se šesti červenými růžemi se zlatými semeníky, vlevo chmelový se šesti trojicemi zlatých šištic, dole zlatá díž."
    },
    {
        obec: "Chotovice",
        kod: 561622,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47301,
        latitude: 50.74039,
        longitude: 14.559384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Chotovice_%28CL%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s žerďovým vydutým červeným klínem s vrcholem na vlajícím okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červený vydutý hrot provázený vpravo zkříženými přivrácenými postavenými kosami bez kosišť, vlevo sklářskými kleštěmi, vše černé. V hrotu stříbrný tryskající pramen."
    },
    {
        obec: "Chotovice",
        kod: 578118,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.851152,
        longitude: 16.171354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Chotovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, přes které je položen středový svislý červený pruh, široký jednu čtvrtinu délky listu. V něm žlutá berla závitem k žerdi s bílým sudariem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se dvěma stříbrnými břevny červený kůl, v něm zlatá berla se stříbrným sudariem."
    },
    {
        obec: "Choťovice",
        kod: 534994,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28905,
        latitude: 50.142489,
        longitude: 15.31978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Cho%C5%A5ovice_CoA.png",
        coatOfArmsFlagDescription: "Modro-zeleně šikmo dělený list. V žerďovém poli bílá růže se žlutým semeníkem a zelenými kališními lístky, ve vlajícím poli žluté vozové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně šikmo vlnitě dělený štít. Nahoře stříbrná růže se zlatým semeníkem a zelenými kališními lístky, dole zlaté vozové kolo."
    },
    {
        obec: "Chotoviny",
        kod: 552461,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39137,
        latitude: 49.478132,
        longitude: 14.677049,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Chotoviny-znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrném polceném štítě vpravo kosmo vztyčený klíč zuby ven, šikmo podložený vztyčeným mečem, obojí stříbrné, vlevo vlk ve skoku přirozené barvy."
    },
    {
        obec: "Chotusice",
        kod: 534102,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28576,
        latitude: 49.949241,
        longitude: 15.394385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Chotusice_znak.jpg",
        coatOfArmsFlagDescription: "Uprostřed žlutého listu nakročený zelený divý muž držící v pravici nad hlavou zelenou dřevěnou palici a v levici před sebou šedý kruhový štít s puklicí a hrotem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nakročený zelený divý muž držící v pravici nad hlavou zelenou dřevěnou palici a v levici před sebou železný kruhový štít s puklicí a hrotem přirozené barvy."
    },
    {
        obec: "Chotutice",
        kod: 533343,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28103,
        latitude: 50.070077,
        longitude: 14.989985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Chotutice_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a bílý. Uprostřed červený kůň ve skoku se žlutou zbrojí, hřívou a ocasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s černým břevnem červený kůň ve skoku se zlatou zbrojí, hřívou a ocasem."
    },
    {
        obec: "Chotýčany",
        kod: 535907,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37362,
        latitude: 49.067394,
        longitude: 14.520691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Chotycany_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vypouklý zelený, v poměru 3:1. Oblouk zeleného pruhu dosahuje do poloviny šířky listu a vyrůstá z něj černá plamenná orlice s červeným jazykem, žlutou zbrojí a jetelovitě zakončeným perizoniem. V zeleném pruhu obrácená bílá podkova. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá plamenná orlice se zlatou zbrojí, jetelovitě zakončeným perizoniem a s červeným jazykem, vyrůstající ze zvýšeného zeleného návrší s obrácenou stříbrnou podkovou."
    },
    {
        obec: "Chotyně",
        kod: 564109,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46334,
        latitude: 50.83632,
        longitude: 14.869039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Znak_obce_Chotyn%C4%9B.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed vyrůstá ze žluté koruny korunovaná panna se žlutými vlasy v bílo-modře polceném šatě, pravicí přidržuje bílý paroh rovněž z koruny vyrůstající, v levici drží bílou věž s cimbuřím, kvádrovaným soklem, černým oknem a modrou stanovou střechou se žlutou makovicí; pod věží bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vyrůstá ze zlaté koruny korunovaná panna se zlatými vlasy ve stříbrno-modře polceném šatě, pravicí přidržuje stříbrný paroh rovněž z koruny vyrůstající, v levici drží stříbrnou věž s cimbuřím, kvádrovaným soklem, černým oknem a modrou stanovou střechou se zlatou makovicí. Pod věží stříbrná lilie."
    },
    {
        obec: "Chotýšany",
        kod: 529818,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25728,
        latitude: 49.744203,
        longitude: 14.814683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Chot%C3%BD%C5%A1any_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený uprostřed červené pole o průměru jedné poloviny šířky listu s bílým lipovým trojlistem na stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný gotický kostel s červenými střechami a černými okny. V návrší červený kotouč se stříbrným lipovým trojlistem na stonku."
    },
    {
        obec: "Choustníkovo Hradiště",
        kod: 579327,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54442,
        latitude: 50.426514,
        longitude: 15.878682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Choustn%C3%ADkovo_Hradi%C5%A1t%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. V červeném pruhu bílé, k hornímu okraji listu rozevřené kružidlo nad žlutým osmiramenným vozovým kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrné, nahoru rozevřené kružidlo nad zlatým osmiramenným vozovým kolem."
    },
    {
        obec: "Chraberce",
        kod: 546429,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.408357,
        longitude: 13.829608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Chraberce_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 7 : 3. Ze druhé až sedmé dvanáctiny horního okraje zeleného pruhu vyniká do modrého pruhu zelený vrch, z něhož vyrůstá bílý lev s červeným jazykem, bílými zuby a žlutou zbrojí, držící v pravé tlapě žlutou sekeru a v levé červený kruhový štít se žlutými zkříženými obilnými klasy, každý s odkloněným listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze zeleného vrchu vyrůstá stříbrný lev s červeným jazykem, stříbrnými zuby a zlatou zbrojí, držící v pravé tlapě zlatou sekeru a v levé na hrudi červený kruhový štít se zkříženými zlatými obilnými klasy, každý s odkloněným listem."
    },
    {
        obec: "Chrast",
        kod: 571539,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.902149,
        longitude: 15.934067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Znak_m%C4%9Bsta_Chrast.jpg",
        coatOfArmsFlagDescription: "Bílý list se dvěma černými orlicemi s červenou zbrojí se zvednutým jedním křídlem, přivrácené k sobě horní částí svých těl. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Choustník",
        kod: 552470,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39118,
        latitude: 49.333045,
        longitude: 14.838492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Choustn%C3%ADk_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým rámem širokým šestnáctinu šířky listu a stejně širokým modrým lemem. Uprostřed kosmo žlutý hradební žebřík. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s volným lemem kosmý hradební žebřík, obojí zlaté."
    },
    {
        obec: "Chrást",
        kod: 558966,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33003,
        latitude: 49.793305,
        longitude: 13.49369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Chr%C3%A1st_PM_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zubatý červený s deseti zuby a devíti stejnými mezerami, v poměru 1 : 2 : 1. V bílém pruhu čelně postavená korunovaná hrncová přilba s černými přikrývadly nesoucí rozevřená černá křídla. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou hlavou nad červenou cimbuřovou patou čelně postavená korunovaná hrncová přilba přirozené barvy s černými přikrývadly nesoucí rozevřená černá křídla."
    },
    {
        obec: "Chrást",
        kod: 537233,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28914,
        latitude: 50.119017,
        longitude: 14.898236
    },
    {
        obec: "Chrást",
        kod: 564249,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.594513,
        longitude: 13.956768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Chr%C3%A1st_%28P%C5%99%C3%ADbram_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zeleno-bílo-zeleně dělený v poměru 5 : 2 : 5, žlutý, široký jednu dvanáctinu šířky listu, a modro-bílo-modře dělený v poměru 5 : 2 : 5. V zelených polích nahoře žlutá dřevěná prázdná brána s valbovou střechou, dole čelně postavená bílá radlice. V modrých polích nahoře bílé mlýnské kolo, dole žlutá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatým kůlem zeleno-modře polcený štít. V obou polích stříbrné vlnité břevno. Vpravo nahoře zlatá dřevěná prázdná brána s valbovou střechou, dole čelně postavená stříbrná radlice. Vlevo nahoře stříbrné mlýnské kolo, dole zlatá hvězda."
    },
    {
        obec: "Chožov",
        kod: 566241,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43922,
        latitude: 50.39887,
        longitude: 13.859103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Cho%C5%BEov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený vlnitý se dvěma vrcholy a jednou prohlubní, v poměru 1 : 3. Ve žlutém pruhu knížecí čepice, pod ní v zeleném pruhu bílá šesticípá hvězda nad žlutým kalichem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít se zlatou vlnitou hlavou. V hlavě knížecí čepice, ve štítě zlatý kalich převýšený stříbrnou hvězdou."
    },
    {
        obec: "Chrastava",
        kod: 564117,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46331,
        latitude: 50.817027,
        longitude: 14.968947,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Chrastava_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý, v poměru 3:4. Zubatý pruh má dva obdélníkové zuby a jednu mezeru širokou sedmnáct osmadvacetin délky listu. Mezeru vyplňuje zelené hnízdo se dvěma odkloněnými orobinci, ve kterém sedí bílá labuť držící ve žlutém zobáku černý šíp se žlutým hrotem k žerdi. V horním rohu a horním cípu po jedné odvrácené lovecké trubce se žlutým závěsem a kováním, v rohu modrá a v cípu červená. Uprostřed bílého pruhu na dolním okraji listu modrá brána s kvádrovým obloukem a žlutými otevřenými vraty na hnědých závěsech. V ní zkřížené žluté hornické motyky na černých násadách. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chrastavec",
        kod: 578126,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.617858,
        longitude: 16.503245,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Chrastavec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červeno-černě polcený, modrý se třemi žlutými osmicípými hvězdami a žlutý pilovitý, v poměru 4:9:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-černě polcenou hlavou palisáda převýšená třemi osmihrotými hvězdami, vše zlaté."
    },
    {
        obec: "Chrášťany",
        kod: 544591,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37304,
        latitude: 49.296087,
        longitude: 14.387621,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Chr%C3%A1%C5%A1%C5%A5any_%28%C4%8Cesk%C3%A9_Bud%C4%9Bjovice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a tři vodorovné pruhy, žlutý, bílý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zlatě polcený štít se zvýšenou modrou vlnitou patou. V pravém poli vykořeněný jehličnatý strom přirozené barvy, v levém pět svázaných zelených obilných klasů, v patě rozevřená stříbrná kniha se zlatými deskami, v ní černé vozové kolo."
    },
    {
        obec: "Chrastavice",
        kod: 553751,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.453663,
        longitude: 12.956921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Coat_of_arms_of_Chrastavice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 1:3. V modrém pruhu tři (2,1) bílo-červeně polcené růže se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené modré návrší, v něm tři (2,1) stříbrno-červeně polcené růže se zlatými semeníky."
    },
    {
        obec: "Chrášťany",
        kod: 532037,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.792155,
        longitude: 14.582032
    },
    {
        obec: "Chrášťany",
        kod: 533351,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.065584,
        longitude: 14.930251
    },
    {
        obec: "Chrášťany",
        kod: 539295,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25219,
        latitude: 50.046148,
        longitude: 14.261423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Chr%C3%A1%C5%A1%C5%A5any_PZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černo-žluto-černě dělený, bílý se čtyřmi červenými růžemi pod sebou a modro-žluto-modře dělený, v poměru 5 : 2 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-modře kosmo dělený štít se stříbrnou hlavou se čtyřmi červenými růžemi vedle sebe. Vpravo zlaté břevno, vlevo kosmo položený zlatý zvon."
    },
    {
        obec: "Chrášťany",
        kod: 541818,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27001,
        latitude: 50.146543,
        longitude: 13.667388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Chrastany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový vlnkovitý pruh s osmi vrcholy, široký jednu čtvrtinu délky listu a tři vodorovné pruhy: bílý, žlutý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě se zeleným obloučkovým lemem zlatá věž se třemi (2,1) prázdnými okny, z nichž šlehají plameny."
    },
    {
        obec: "Chraštice",
        kod: 540358,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.576891,
        longitude: 14.071933
    },
    {
        obec: "Chrášťovice",
        kod: 551155,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.333292,
        longitude: 13.89714,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Chr%C3%A1%C5%A1%C5%A5ovice_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s horním modrým klínem. V klínu žlutá osmicípá hvězda, v žerďovém a vlajícím poli černá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý klín se zlatou osmihrotou hvězdou provázený dvěma černými liliemi."
    },
    {
        obec: "Chrbonín",
        kod: 563145,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39155,
        latitude: 49.358766,
        longitude: 14.864401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Chrbon%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 5 : 3. Do zeleného pruhu vyrůstá bílo-červeně polcená orlice se žlutým, jetelovitě zakončeným perizoniem, žlutou zbrojí, červeným jazykem a žlutou korunou na krku. Ve žlutém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky, podložená dvěma zkříženými dvojicemi zelených obilných klasů do oblouku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlatého dvojvrší stříbrno-červeně polcená orlice se zlatým, jetelovitě zakončeným perizoniem, zlatou zbrojí a červeným jazykem a zlatou korunkou na krku. V dvojvrší červená růže se zlatým semeníkem a zelenými kališními lístky, podložená dvěma zkříženými dvojicemi zelených obilných klasů do oblouku."
    },
    {
        obec: "Chroboly",
        kod: 550264,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38404,
        latitude: 48.956632,
        longitude: 14.067078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Chroboly_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 3 : 4 : 3. V bílém pruhu nahoře červená růže se žlutým semeníkem a zelenými kališními lístky, dole zelená lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná patrová bosovaná věž s červenou dělící stříškou a stanovou střechou se zlatou makovicí, v zúženém patře červená růže se zlatým semeníkem a zelenými kališními lístky, dole zelená lilie. Věž nahoře provázena dvěma stříbrnými tisy."
    },
    {
        obec: "Chromeč",
        kod: 569305,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.933846,
        longitude: 16.893779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Chrome%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s vodorovným modrým břevnem širokým jednu devítinu délky listu překrývajícím červený středový kůl o téže šíři. V horním žerďovém rohu a dolním vlajícím cípu červený trojlist se stopkou, v horním vlajícím cípu a dolním žerďovém rohu černá půlkruhová škrabka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo stříbrné propletené volské rohy, vlevo modré břevno provázené nahoře červeným trojlistem se stopkou, dole s černou půlkruhovou škrabkou."
    },
    {
        obec: "Chropyně",
        kod: 588512,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76811,
        latitude: 49.35654,
        longitude: 17.364618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Chropyn%C4%9B_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři pole, žerďové a vlající žlutá, střední čtvercové červené se žlutou vydrou s modrou zbrojí držící v tlamě a předních tlapách bílou štiku. Poměr polí je 1:4:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá vydra s modrou zbrojí, držící v tlamě a předních tlapách stříbrnou štiku."
    },
    {
        obec: "Chroustov",
        kod: 534706,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28902,
        latitude: 50.28669,
        longitude: 15.347273
    },
    {
        obec: "Chroustovice",
        kod: 571547,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53863,
        latitude: 49.955625,
        longitude: 15.991536,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Znak_obce_Chroustovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý s červenou svatojakubskou mušlí a zelený, v poměru 3:1. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trávníku svatý Jakub Větší v poutnickém oděvu s holí, přirozené barvy."
    },
    {
        obec: "Chrtníč",
        kod: 568767,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.767642,
        longitude: 15.443719
    },
    {
        obec: "Chrtníky",
        kod: 572764,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.9819,
        longitude: 15.604954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Chrtn%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list. V dolní části žerďové poloviny kosmý mlátek šikmo přeložený želízkem, oboje bílé se žlutými topůrky. Ve střední a vlající části dva, kosmo nad sebou vyskakující bílí chrti s červenými jazyky a černými obojky se žlutými trny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad kosmým mlátkem, šikmo přeloženým želízkem, obojí stříbrné se zlatými topůrky, dva nad sebou vyskakující stříbrní chrti s červenými jazyky a černými obojky se zlatými trny."
    },
    {
        obec: "Chrudichromy",
        kod: 581534,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.502996,
        longitude: 16.628098,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Chrudichromy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, v poměru 1 : 3 : 1, červený, žluto-zeleno-žlutě polcený a modrý. V zeleném poli bílá radlice hrotem k hornímu a ostřím k žerďovému okraji listu, přeložená kosmo vztyčenou bílou otkou na žluté násadě. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s červenou hlavou a modrou patou mezi zlatými boky vztyčená stříbrná radlice přeložená kosmo vztyčenou stříbrnou otkou na zlaté násadě."
    },
    {
        obec: "Chrudim",
        kod: 571164,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.949824,
        longitude: 15.795158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Chrudim_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, bílý a červený. V bílém pruhu černá orlice s červenou zbrojí a jazykem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chrustenice",
        kod: 533670,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26712,
        latitude: 50.006145,
        longitude: 14.152325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Znak_Chrustenice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové polovině bílého pruhu dvě přivrácená červená račí klepeta, v žerďové polovině červeného pruhu zkřížená žlutá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad červeným návrším se zkříženými zlatými hornickými kladívky dvě přivrácená červená račí klepeta."
    },
    {
        obec: "Chržín",
        kod: 532398,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27324,
        latitude: 50.295476,
        longitude: 14.270238
    },
    {
        obec: "Chřenovice",
        kod: 573574,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.716741,
        longitude: 15.216372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Ch%C5%99enovice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list. Ze třetí čtvrtiny horního a druhé čtvrtiny dolního okraje listu vycházejí do poloviny šířky listu modré, černě kvádrované svislé pruhy. Z horního pruhu vyniká k žerďovému okraji listu černá a z dolního k vlajícímu okraji červená hlava orlice, obě se žlutým zobákem a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě odsazený modrý kvádrovaný kůl, z něhož vyniká vpravo nahoře černá a vlevo dole červená hlava orlice, obě se zlatým zobákem a červeným jazykem."
    },
    {
        obec: "Chřibská",
        kod: 562530,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40744,
        latitude: 50.863346,
        longitude: 14.483083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Chribska_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. V žerďové části modrého pruhu ve žluté koruně s červeným vyložením hnědá loďka, v níž sedí se žlutým veslem černě oděná panna se závitím s vlajícími konci a zástěrou, oboje bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chříč",
        kod: 558974,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.97192,
        longitude: 13.646002,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Znak_obce_Ch%C5%99%C3%AD%C4%8D.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu v žerďové polovině chroust, ve vlající polovině jelení paroh, obojí žluté. V bílém pruhu zelená větévka tisu s červenými plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře vedle sebe chroust a jelení paroh, obojí zlaté, dole zelená větev tisu s červenými plody."
    },
    {
        obec: "Chudčice",
        kod: 583111,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66471,
        latitude: 49.288591,
        longitude: 16.458829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Chudcice-znak.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a modré pole s čelně postavenou radlicí nad zvonem se závěsem, obojí žluté. V červeném pruhu dva bílé čtverce, široké jednu čtvrtinu šířky listu, dotýkající se stejného čtverce v modrém poli. Bílá pole v červeném pruhu jsou od okrajů listu vzdálena jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě s červenou patou nahoře čelně postavená radlice a zvon se závěsem, obojí zlaté, na dělící linii tři (1,2) dotýkající se stříbrné kostky."
    },
    {
        obec: "Chudenice",
        kod: 556378,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.466785,
        longitude: 13.17397,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Chudenice_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý, modrý a bílý, v poměru 5:1:1:1. V červeném pruhu dvě věže na kvádrovaném soklu se dvěma černými okny nad sebou. Mezi věžemi kvádrovaná hradba s prázdnou bránou, s vytaženou žlutou mříží se šedými hroty, vše bílé a s cimbuřím. Na každé věži na žlutých žerdích odvráceně vlající prapory tvořené třemi vodorovnými pruhy, žlutým, modrým a bílým. Mezi věžemi na cimbuří vzpřímený hnědý medvěd s červeným jazykem držící v pravé tlapě žlutý oštěp a levou tlapou přidržující modrý štít s bílým půlměsícem, s cípy k vlajícímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě volně mezi dvěma věžemi se dvěma černými okny nad sebou hradba s prázdnou branou s vytaženou zlatou mříží se železnými hroty přirozené barvy, vše stříbrné, kvádrované, se třemi stínkami. Na obou věžích na zlaté žerdi odvráceně vlající prapory dělené zlato-modro-stříbrně. Mezi věžemi na cimbuří vzpřímený medvěd přirozené barvy s červeným jazykem držící v pravé tlapě zlatý oštěp a levou tlapou přidržující modrý štít se stříbrným ubývajícím půlměsícem."
    },
    {
        obec: "Chudenín",
        kod: 556386,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34022,
        latitude: 49.29315,
        longitude: 13.100904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Chuden%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený. V žerďové části koruna, nahoře a dole provázená osmicípou hvězdou, vše žluté. Ve střední a vlající části rozkřídlená bílá labuť se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrná labuť se zlatou zbrojí, převýšená korunou mezi dvěma osmihrotými hvězdami, vše zlaté."
    },
    {
        obec: "Chuderov",
        kod: 568023,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.68875,
        longitude: 14.046285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Chuderov_CoA.jpg"
    },
    {
        obec: "Chudeřice",
        kod: 570125,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.149139,
        longitude: 15.550972,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Chude%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:6:1. V modrém pruhu bílý ohlížející se drak s bílo-červeně šachovaným křídlem, červenou zbrojí a jazykem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádou stříbrný ohlížející se drak se stříbrno-červeně šachovaným křídlem, červenou zbrojí a jazykem."
    },
    {
        obec: "Chudíř",
        kod: 571849,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29445,
        latitude: 50.308893,
        longitude: 15.013907,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Chud%C3%AD%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem ze čtvrté čtvrtiny horního okraje do druhé čtvrtiny dolního okraje listu na červené žerďové pole a vlající zelené pole. V červeném poli bílý zvon s liliovým srdcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě cimbuřově šikmo dělený štít. V horním červeném poli šikmý stříbrný zvon s liliovým srdcem. Dolní pole stříbrno - zeleně šikmo vlnitě dělené."
    },
    {
        obec: "Chudoslavice",
        kod: 546810,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.583028,
        longitude: 14.187346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Chudoslavice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, modrý, bílý a zelený, v poměru 2 : 1 : 1 : 1. V horním pruhu zelená hruška mezi dvěma červenými jablky, vše se dvěma zelenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se zelenou hruškou mezi dvěma červenými jablky, vše se dvěma zelenými listy, stříbrné trojvrší vyrůstající ze zelené obloukové paty."
    },
    {
        obec: "Chuchelna",
        kod: 577154,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.602387,
        longitude: 15.300449
    },
    {
        obec: "Chuchelná",
        kod: 507334,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74724,
        latitude: 49.986735,
        longitude: 18.116667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Chucheln%C3%A1_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, bílý a červený, v poměru 1:2:1, v bílém dva zelené stonky vinné révy, každý s jedním listem a modrým hroznem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná mitra a na ní dva zelené stonky vinné révy, každý s jedním listem a jedním modrým hroznem."
    },
    {
        obec: "Chvalatice",
        kod: 594164,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.947809,
        longitude: 15.751157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Chvalatice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, modrý a žlutý, v poměru 1 : 6 : 1. V modrém pruhu dvě přivrácené bílé rozkřídlené labutě se žlutou zbrojí a propletenými krky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou kvádrovanou zdí s volným černým ostrvovým křížem dvě přivrácené stříbrné rozkřídlené labutě se zlatou zbrojí a propletenými krky."
    },
    {
        obec: "Chvalčov",
        kod: 506737,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76872,
        latitude: 49.389281,
        longitude: 17.711595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/CoA_of_Chval%C4%8Dov.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - modrý, bílý, červený, bílý a červený, v poměru 4:1:1:1:1. V žerďové části modrého pole bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělen obloučkovým řezem se třemi oblouky. V horní modré polovině stříbrná lilie, dolní polovina 3x stříbrno-červeně dělená."
    },
    {
        obec: "Chvaleč",
        kod: 579335,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54211,
        latitude: 50.595999,
        longitude: 16.03941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Chvale%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý s černým ptákem obráceným k vlajícímu okraji a modrý se žlutým klíčem zuby nahoru a k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo zlatá skála s modrým pramenem, s vyrůstající borovicí přirozené barvy, na níž stojí doleva obrácený černý pták. Vlevo svatý Petr ve stříbrném šatě, držící v levé ruce vztyčený zlatý gotický klíč."
    },
    {
        obec: "Chválenice",
        kod: 557846,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33205,
        latitude: 49.643095,
        longitude: 13.476053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Chv%C3%A1lenice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed polovina bílé rozkřídlené husy se žlutým zobákem, pod ní obrněné bílé rameno se šikmým žlutým mečem s čepelí podloženou pod husou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě polovina stříbrné rozkřídlené husy se zlatým zobákem, pod ní stříbrné obrněné rameno se šikmým zlatým mečem s čepelí podloženou pod husou."
    },
    {
        obec: "Chvaletice",
        kod: 575071,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53312,
        latitude: 50.034532,
        longitude: 15.418562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Znak_m%C4%9Bsta_Chvaletice.PNG",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a červené pole. V modrém pruhu půl ozubeného kola přiléhajícího k půli oběžného kola turbíny v červeném poli, mezi nimi černá hornická kladívka. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chvalkovice",
        kod: 593095,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68341,
        latitude: 49.187125,
        longitude: 17.110212,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Znak_Chvalkovice.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a čtyři vodorovné pruhy, zelený, žlutý, bílý a zelený v poměru 2:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zelený štít s červenou patou, nahoře stříbrný dřevěný větrný mlýn nad stříbrným vinným hroznem, obojí provázeno po stranách dvěma zlatými odkloněnými obilnými klasy vyrůstajícími z dělící linie. V patě štítu položený stříbrný nůž se zlatou rukojetí, ostřím nahoru."
    },
    {
        obec: "Chvalíkovice",
        kod: 569909,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74706,
        latitude: 49.885738,
        longitude: 17.911052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Chval%C3%ADkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým zubatým pruhem širokým dvě devítiny šířky listu a se žlutým obilným snopem. Zubatý pruh má pět čtvercových zubů a čtyři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod cimbuřovou hlavou obilný snop, vše zlaté."
    },
    {
        obec: "Chvalovice",
        kod: 537420,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.01045,
        longitude: 14.231802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Chvalovice_%28Prachatice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed dva zkřížené klasy ječmene provázené nahoře korunou, po stranách svěšenou chmelovou šišticí, vše žluté, a dole bílými hornickými kladívky na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě dva zkřížené klasy ječmene provázené nahoře korunou, po stranách svěšenou chmelovou šišticí, vše zlaté, a dole stříbrnými hornickými kladívky na zlatých topůrkách."
    },
    {
        obec: "Chvalnov-Lísky",
        kod: 588547,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76805,
        latitude: 49.166079,
        longitude: 17.231009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Chvalnov-L%C3%ADsky_CoA.PNG",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílo-červeně dělený, zelený a červeno-bíle dělený, v poměru 1:2:1. V žerďovém a vlajícím pruhu po zeleném lískovém trojlistu se třemi (2,1) hnědými oříšky. V zeleném pruhu žlutý dvouocasý lev s červenou zbrojí držící bílou lilii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý dvouocasý lev s červenou zbrojí držící stříbrnou lilii. Pravý roh štítu šikmo, levý roh kosmo červeno-stříbrně dělený, v obou zelený lístkový trojlist se třemi (2,1) lískovými oříšky přirozené barvy."
    },
    {
        obec: "Chvalovice",
        kod: 594172,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.786677,
        longitude: 16.082449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Chvalovice_Znojmo_znak.png",
        coatOfArmsFlagDescription: "V červeném listu půl bílé lilie a vztyčený bílý vinařský nůž ostřím k vlajícímu okraji se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě půl stříbrné lilie a vztyčený vinařský nůž ostřím doleva se zlatou rukojetí."
    },
    {
        obec: "Chvalšiny",
        kod: 545546,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38208,
        latitude: 48.854119,
        longitude: 14.211239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Znak_obce_chvalsiny.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové a dolní vlající pole modré se žlutou korunou, dolní žerďové a horní vlající pole červené s bílým tlapatým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně čtvrcený štít, v 1. a 4. poli zlatá koruna, ve 2. a 3. poli stříbrný tlapatý kříž."
    },
    {
        obec: "Chvalkovice",
        kod: 574112,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55204,
        latitude: 50.414437,
        longitude: 15.978392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Chvalkovice-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zubatý zelený se šesti zuby a sedmi mezerami. V zeleném poli bílý vykračující čáp s černými křídly a červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou se šesti zelenými stínkami vykračující čáp přirozené barvy s červenou zbrojí."
    },
    {
        obec: "Chvatěruby",
        kod: 534846,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27801,
        latitude: 50.232842,
        longitude: 14.342788,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Chvat%C4%9Bruby_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu modrý korunovaný lev s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo modrý korunovaný lev s červenou zbrojí, vlevo postavený stříbrný meč se zlatým jílcem přeložený položeným gotickým klíčem a položeným palcátem, obojí zlaté."
    },
    {
        obec: "Chvojenec",
        kod: 575089,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.109676,
        longitude: 15.937327,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Znak_chvojenec.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: zelený, žlutý, červený, žlutý a zelený v poměru 5:1:3:1:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z červené, stříbrně spárované cihlové zdi zelená jedlová větévka."
    },
    {
        obec: "Chyjice",
        kod: 549223,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.387734,
        longitude: 15.29557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Coat_of_arms_of_Chyjice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 10 : 1 : 1. V horním modrém pruhu bílá rozevřená kniha se žlutými deskami, na stránce k žerďovému okraji červená lilie, na stránce k vlajícímu okraji listu zelený vztyčený lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná rozevřená kniha se zlatými deskami, na pravé stránce červená lilie, na levé vztyčený zelený lipový list. Pod knihou stříbrné vlnité břevno."
    },
    {
        obec: "Chyňava",
        kod: 531294,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26707,
        latitude: 50.027467,
        longitude: 14.074092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Chy%C5%88ava_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a bílé pole se dvěma vodorovnými zelenými, sedmkrát lomenými smrkovými pruhy, vycházejícími z první a třetí čtvrtiny žerďového a vlajícího okraje pole, s vrcholy pruhů na horním a dolním okraji listu. V červeném pruhu královská koruna nad žlutým obilným klasem, podloženým šikmo bílými kovářskými kleštěmi a kosmo bílým kladivem se žlutým topůrkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli královská koruna nad zlatým obilným klasem, podloženým šikmo stříbrnými kovářskými kleštěmi a kosmo stříbrným kladivem se zlatým topůrkem. Levé pole třikrát dolů dělené stříbrno-zeleným smrkovým řezem."
    },
    {
        obec: "Chýně",
        kod: 539309,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25301,
        latitude: 50.060826,
        longitude: 14.227054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Chyne_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, zubatý žlutý a modrý, v poměru 2:1:5. V černém pruhu tři žluté lilie pod sebou. Žlutý pruh má čtyři obdélníkové zuby k vlajícímu okraji a tři stejné mezery. V modrém pruhu dva zkřížené žluté gotické klíče. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod černou hlavou se třemi zlatými liliemi modrá zvýšená hradba se třemi stínkami, v ní zkřížené zlaté gotické klíče."
    },
    {
        obec: "Chýnice",
        kod: 513431,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25217,
        latitude: 49.995792,
        longitude: 14.264385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Chynice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 5 : 1 : 9. V červeném pruhu koruna, v modrém vodní kolo, oboje žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně stříbrným zúženým břevnem zvýšeně děleném štítě nahoře koruna, dole mlýnské vodní kolo, obojí zlaté."
    },
    {
        obec: "Chýnov",
        kod: 552496,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39155,
        latitude: 49.406872,
        longitude: 14.811319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Ch%C3%BDnov_znak.png",
        coatOfArmsFlagDescription: "Červené čtvercové žerďové pole se dvěma zkříženými klíči. Vlající část s pěti vodorovnými pruhy střídavě modrými (3) a bílými (2). Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Chýstovice",
        kod: 561339,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.585781,
        longitude: 15.079667
    },
    {
        obec: "Chyše",
        kod: 555207,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36453,
        latitude: 50.104736,
        longitude: 13.249179,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Chy%C5%A1e_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 2 : 5 : 2. V modrém pruhu bílá kvádrovaná hradba do oblouku. Uprostřed věž s prolomenou černou branou s vytaženou bílou mříží, po stranách z hradby vynikají dvě vyšší věže. Všechny věže bílé, kvádrované s cimbuřím, s černým obloukovým oknem a červenou valbovou střechou se dvěma žlutými makovicemi. Nad prostřední věží kosmo žlutý štítek s trojím (2, 1) černým parožím. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Chyšky",
        kod: 549452,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39853,
        latitude: 49.52356,
        longitude: 14.427673,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Chysky_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý v poměru 5:3. Do modrého pruhu vynikají tři stupňovité hroty, každý o třech čtvercových stupních širokých jednu osmnáctinu délky listu. Krajní stupně jsou vzdáleny od žerďového a vlajícího okraje jednu šestatřicetinu délky listu. Pod prostředním zubem červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně třemi stupňovitými hroty děleném štítě nahoře stříbrný stočený provaz se čtyřmi uzly (cingulum), dole červená růže se zlatým semeníkem."
    },
    {
        obec: "Chyšná",
        kod: 561321,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.586467,
        longitude: 15.10003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Chy%C5%A1n%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu bílá sloupová zvonička se žlutým zvonem na závěsu, zakončená tlapatým křížkem. Sloup přeložen obrácenou žlutou podkovou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modro-stříbrně polcený štít. V modrém poli stříbrná sloupová zvonička se zlatým zvonem na závěsu, zakončená tlapatým křížkem. Sloup přeložen obrácenou zlatou podkovou."
    },
    {
        obec: "Ivaň",
        kod: 584517,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69123,
        latitude: 48.929811,
        longitude: 16.575843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Ivan_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se třemi bílými čtvercovými poli o šířce jedné třetiny délky listu umístěné v horním žerďovém rohu, ve středu listu a v dolním vlajícím cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrné vlnité břevno provázené nahoře volně postavenou zlatou palisádovou hradbou se dvěma věžemi, dole zlatým vykořeněným bezlistým dubem s devíti žaludy na konci větví."
    },
    {
        obec: "Chýšť",
        kod: 575097,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.128259,
        longitude: 15.541389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CoA_of_Ch%C3%BD%C5%A1%C5%A5.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 2:9:2. Uprostřed bílý zvon. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod dvěma zlatými uťatými lípami stříbrný zvon."
    },
    {
        obec: "Ivaň",
        kod: 589578,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79823,
        latitude: 49.424694,
        longitude: 17.251454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Iva%C5%88_PV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, zelený, červený a bílý. Uprostřed žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrný vykračující čáp se zlatou zbrojí, provázený vpravo obilným klasem se dvěma listy a vlevo nahoře tlapatým křížem, obojí zlaté."
    },
    {
        obec: "Ivančice",
        kod: 583120,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.101545,
        longitude: 16.377622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Ivan%C4%8Dice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Na modrém listu tři žluté poháry dny k sobě. Osy obou horních pohárů směřují do horního rohu a horního cípu, dolní pohár kolmo k dolnímu okraji listu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři odvrácené (2, 1) zlaté poháry."
    },
    {
        obec: "Jabkenice",
        kod: 535966,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29445,
        latitude: 50.324168,
        longitude: 15.01488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Jabkenice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se šikmým červeným pruhem širokým čtvrtinu šířky listu. V žerďovém poli červené jablko se zelenou stopkou s listem, ve vlajícím poli modrá lyra. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na modrém trávníku červená věž s kvádrovaným soklem, cimbuřím, s černou branou a oknem, provázená vpravo červeným jablkem a zelenou stopkou s listem, vlevo modrou lyrou."
    },
    {
        obec: "Jabloňany",
        kod: 581666,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.465713,
        longitude: 16.6061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Jablonany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený, modrý, bílý, modrý a bílý, v poměru 7:3:1:1:1:1. V žerďové polovině bílého a zeleného pruhu vykořeněná zelená jabloň s osmi žlutými jablky, hnědým kmenem a kořeny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo děleném štítě vpravo na zeleném trávníku vykořeněná jabloň přirozené barvy s osmi zlatými jablky, vlevo stříbrná rozevřená kniha."
    },
    {
        obec: "Jablonec nad Jizerou",
        kod: 577162,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51243,
        latitude: 50.703569,
        longitude: 15.429698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Jablonecj_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, červený a modrý. V bílém pruhu hnědý pahýl jabloně, z něhož k vlajícímu okraji listu vyrůstá větev se zelenými listy a červenými plody. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jablonná",
        kod: 540374,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26263,
        latitude: 49.661146,
        longitude: 14.138547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Jablonn%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, a vodorovný zelený pruh na dolním okraji listu široký pětinu šířky listu. Z druhé třetiny zeleného pruhu vyniká vrch s vyrůstající jabloní s hnědým kmenem a polcenou korunou, k žerďovému okraji tvořenou bílými květy se žlutými středy a k vlajícímu okraji zelenou s červenými plody. Ve vrchu žlutý mlýnský kámen s položenou černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě zelený vrch, z něhož vyrůstá jabloň s kmenem přirozené barvy a s polcenou korunou, vpravo tvořenou stříbrnými květy se zlatými středy, vlevo zelenou s červenými plody. Ve vrchu zlatý mlýnský kámen s položenou černou kypřicí."
    },
    {
        obec: "Jablonné nad Orlicí",
        kod: 580376,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56164,
        latitude: 50.029734,
        longitude: 16.600698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Jablonn%C3%A9_nad_Orlic%C3%AD_coat_of_arms.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - zelený, bílý a zelený v poměru 1:6:1. Ve středu bílého pole sedící černý medvěd s červenou zbrojí třímající žlutou berlu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jablonné v Podještědí",
        kod: 561631,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47125,
        latitude: 50.765382,
        longitude: 14.760633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Jablonn%C3%A9_v_Podje%C5%A1t%C4%9Bd%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed modrý čtverec o straně rovné jedné polovině šířky listu se žlutými podávkami bez násady hroty nahoru. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jabloňov",
        kod: 595730,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.324114,
        longitude: 16.089764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Jablo%C5%88ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. V žerďové polovině listu vykořeněná jabloň s hnědým kmenem a větvemi, zelenými listy a červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod stříbrno-zeleně třikrát dělenou hlavou na zeleném návrší vykořeněná jabloň přirozené barvy s červenými plody."
    },
    {
        obec: "Jablůnka",
        kod: 542865,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75623,
        latitude: 49.383651,
        longitude: 17.950317,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/CZ_Jabl%C5%AFnka_COA.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným pruhem širokým jednu sedminu šířky listu na dolním okraji. Pilovitý okraj pruhu tvoří šest zubů, jejichž hroty dosahují jedné čtvrtiny šířky listu. Z pruhu vyrůstá jabloň se zelenou korunou, červenými jablky a černým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě jabloň se zelenou korunou, červenými jablky a černým kmenem vyrůstající z červené palisády."
    },
    {
        obec: "Jablunkov",
        kod: 598259,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73991,
        latitude: 49.576815,
        longitude: 18.764694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Jablunkov_arms.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký polovinu délky listu s půlí černé orlice se žlutou zbrojí, červeným jazykem a bílým jetelovitě zakončeným perizoniem s křížkem, přiléhající ke dvěma vodorovným pruhům ve vlající polovině, modrému a červenému. V modrém pruhu bílý beránek se svatozáří, přidržující šikmo žlutou, křížem zakončenou žerď s bílou dvoucípou korouhví s červeným křížem. V červeném pruhu zelená jabloň se žlutými jablky a hnědým kmenem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jahodov",
        kod: 548782,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51601,
        latitude: 50.150364,
        longitude: 16.333536
    },
    {
        obec: "Jáchymov",
        kod: 555215,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36251,
        latitude: 50.358573,
        longitude: 12.934767,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/J%C3%A1chymov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, červený a žlutý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jakubčovice nad Odrou",
        kod: 554065,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74236,
        latitude: 49.695138,
        longitude: 17.787781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Coat_of_arms_of_Jakub%C4%8Dovice_nad_Odrou.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým vodorovným vlnitým pruhem o čtyřech vrcholech a třech prohlubních širokým jednu šestinu šířky listu a vzdálený jednu šestinu šířky listu od dolního okraje. Nad pruhem zkřížená tyčová dláta podložená vztyčenou kamenickou palicí a provázená po stranách dvěma růžemi, vše žluté, semeníky červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno, nad ním dvě zlaté růže s červenými semeníky, pod ním zkřížená tyčová dláta podložená vztyčenou kamenickou palicí, vše zlaté."
    },
    {
        obec: "Jakubov u Moravských Budějovic",
        kod: 590746,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.081114,
        longitude: 15.761519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Jakubov_u_Moravskych_Budejovic_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo dělený list, uprostřed kosmo položená svatojakubská mušle opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě kosmá svatojakubská mušle opačných barev."
    },
    {
        obec: "Jakubovice",
        kod: 553344,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78991,
        latitude: 49.994405,
        longitude: 16.828875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/CoA_of_Jakubovice_%28okres_%C5%A0umperk%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý se dvěma žlutými osmicípými hvězdami, bílý a červeno-černě polcený se žlutou osmicípou hvězdou, v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrné trojvrší s položenou černou radlicí, nad vrchy tři zlaté osmihroté hvězdy."
    },
    {
        obec: "Jalubí",
        kod: 592226,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68705,
        latitude: 49.116359,
        longitude: 17.427985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Jalub%C3%AD%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílo-červeně čtvrcený. V modrém pruhu vinařský nůž - kosíř ostřím k vlajícímu okraji a vinný hrozen s listem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s černou branou, oknem a červenou stanovou střechou zakončenou zlatou makovicí se stříbrno-červeně čtvrceným praporkem, provázená doleva obráceným vinařským nožem - kosířem a vinným hroznem s listem, obojí zlaté."
    },
    {
        obec: "Jamné",
        kod: 587249,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.431232,
        longitude: 15.723948,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Jamn%C3%A9_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V červeném pruhu bílá polovina kozla se žlutou zbrojí a žlutým křídlem s volným modrým tlapatým křížem, přiléhající k dolní polovině žlutého gryfa s červenou zbrojí v modrém pruhu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě stříbrná polovina kozla se zlatou zbrojí a zlatým křídlem s volným modrým tlapatým křížem přiléhající k dolní polovině zlatého gryfa s červenou zbrojí."
    },
    {
        obec: "Jamné nad Orlicí",
        kod: 580392,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56165,
        latitude: 50.039685,
        longitude: 16.633004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Jamn%C3%A9_nad_Orlic%C3%AD.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým běžícím koněm s červeným jazykem. (List praporu opakuje obecní znak.) Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou patou stříbrný běžící kůň s červeným jazykem."
    },
    {
        obec: "Jamolice",
        kod: 594181,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.072435,
        longitude: 16.253784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Jamolice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený se dvěma žlutými liliemi vedle sebe a bílý s červeným rovným tlapatým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě volná hradba s vynikající věží, vše stříbrné, kvádrované a s cimbuřím, v hradbě červený tlapatý kříž, ve věži černé prolomené okno. Věž provázena dvěma zlatými liliemi."
    },
    {
        obec: "Jankov",
        kod: 544612,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.966544,
        longitude: 14.297986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Jankov_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným vlajícím klínem s vrcholem na žerďovém okraji listu. V horním a dolním rohu listu červená růže se žlutým semeníkem, ve vlající části klínu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstající červená silueta štítu barokního selského stavení, v něm stříbrná lilie. V horních rozích dvě červené růže se zlatými semeníky."
    },
    {
        obec: "Jámy",
        kod: 595756,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59232,
        latitude: 49.530363,
        longitude: 16.005752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/J%C3%A1my_ofici%C3%A1ln%C3%AD_znak_obce.jpg",
        coatOfArmsFlagDescription: "List tvoří zelená žerďová část s polovinou bílého kozla s černou zbrojí, vynikající z vlající části tvořené sedmi vodorovnými pruhy střídavě bílými a černými v poměru 1:1:1:1:1:1:6. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít, vpravo vyniká půl stříbrného kozla s černou zbrojí, v horní části levého pole tři černá břevna."
    },
    {
        obec: "Jankov",
        kod: 561461,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.415196,
        longitude: 15.38632
    },
    {
        obec: "Jankov",
        kod: 529842,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25703,
        latitude: 49.653039,
        longitude: 14.730847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Jankov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, černý a žlutý, v poměru 1 : 5 : 1. V černém pruhu žlutá, červeně vyložená pětilistá liliová koruna se čtyřmi perlami, zdobená červenými a zelenými kameny, provázená nahoře pěti žlutými šesticípými hvězdami do oblouku. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jankovice",
        kod: 575101,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.007735,
        longitude: 15.529259,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Jankovice_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 5 : 3 : 5. Uprostřed listu kosmo svěšená žlutá lipová větev se třemi listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrným kůlem kosmo svěšená zlatá lipová větev se třemi listy."
    },
    {
        obec: "Jankovice",
        kod: 588555,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.35638,
        longitude: 17.618744,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Jankovice_%28Kromeriz%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Dvojitým černo-bílým středovým křížem čtvrcený bílo-červený list s modrým kruhovým polem uprostřed o průměru rovném dvěma třetinám šířky listu s bílým ohlížejícím se beránkem se žlutou zbrojí držící žlutý kříž. Šířka ramen středového kříže je jedna pětina šířky listu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole modro-černě děleno, nahoře stříbrný ohlížející se beránek se zlatou zbrojí, držící zlatý kříž, dole dvě stříbrná břevna. V levém červeném poli půl stříbrného heroldského kříže."
    },
    {
        obec: "Jankovice",
        kod: 592234,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68704,
        latitude: 49.154219,
        longitude: 17.388585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Jankovice_%28UH%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů modrý, žlutý, červený, bílý, červený, žlutý a modrý v poměru 3:1:6:1:3. V červeném pruhu bílý středový kříž s rameny širokými jednu třetinu šířky červeného pruhu ve středu kříže modrá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře zvýšeně dělený štít, nahoře stříbrný heroldský kříž s modrou lilií uprostřed, dole ze stříbrného návrší vyrůstají tři zlaté listnaté stromy."
    },
    {
        obec: "Janoušov",
        kod: 553212,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78991,
        latitude: 50.01321,
        longitude: 16.845209,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Janou%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu bílo - červeně šachované křídlo obrácené k vlajícímu okraji, v červeném pruhu černý korunovaný dvouocasý lev se žlutou zbrojí vyrůstající ze žluté růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo doleva obrácené stříbrno - červeně šachované křídlo, vlevo černý korunovaný dvouocasý lev se zlatou zbrojí vyrůstající ze zlaté růže."
    },
    {
        obec: "Janov",
        kod: 576328,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.332407,
        longitude: 16.25188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Janov_RK_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 2. Do bílého pruhu vyrůstá červený latinský kříž mezi dvěma zelenými stromy s černými kmeny, v žerďové části lípa, ve vlající tis. V zeleném pruhu bílý tkalcovský člunek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zelené patě mezi dvěma zelenými stromy s černými kmeny, vpravo lípou a vlevo tisem, vyrůstá červený latinský kříž, v patě položený stříbrný tkalcovský člunek."
    },
    {
        obec: "Janov",
        kod: 597431,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79384,
        latitude: 50.244949,
        longitude: 17.481294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Janov_%28Czech_Silesia%29_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list se zkříženými černými hornickými kladívky ve vlající části listu. Na dolním okraji zelený vodorovný pruh široký 1/4 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným trojvrším zkřížená hornická kladívka přirozených barev."
    },
    {
        obec: "Janov",
        kod: 565270,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27006,
        latitude: 50.210508,
        longitude: 13.639913,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Janov_RA_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným mezikružím o průměru rovném polovině délky listu s černým středem o průměru jedné desetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený kotouč s černým středem."
    },
    {
        obec: "Janov",
        kod: 578134,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56955,
        latitude: 49.840871,
        longitude: 16.385333,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Janov_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. Uprostřed v červeném pruhu žlutý kotel se třemi černými vodorovnými pruhy a závěsem v modrém pruhu. Z kotle vyrůstá kosmo žlutá berla s bílým sudariem, závitem k žerďovému okraji a žlutý korunovaný lev s červenou zbrojí obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě zlatý kotel se závěsem a třemi červenými břevny, z něhož kosmo vyniká zlatá berla se stříbrným sudariem závitem ven a doleva vyrůstá zlatý korunovaný lev s červenou zbrojí."
    },
    {
        obec: "Janov",
        kod: 544680,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.860304,
        longitude: 14.265378,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Janov_%28okres_D%C4%9B%C4%8D%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a červený. Uprostřed listu dvě odvrácené vztyčené sekery s topůrky, vše opačných barev, přeloženými zkříženými černými ostrvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně polceném štítě dvě odvrácené vztyčené sekery s topůrky, vše opačných barev, přeloženými černými zkříženými ostrvemi."
    },
    {
        obec: "Janov nad Nisou",
        kod: 563595,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46811,
        latitude: 50.772141,
        longitude: 15.169244,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Janovnadnisou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, prostřední tvořený sedmi vodorovnými pruhy střídavě žlutými a modrými vlnitými se dvěma vrcholy a třemi prohlubněmi v poměru 7:1:1:1:1:1:2, a modrý. V černém pruhu bílé sklářské kleště, v horní polovině prostředního pruhu černé lopatkové kolo. V modrém pruhu na bílém návrší bílá věž s černými dveřmi, černým oknem v prvním a druhém patře, třemi černými okny ve třetím patře a červenou valbovou střechou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Janová",
        kod: 570346,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75501,
        latitude: 49.312144,
        longitude: 18.020158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/CZ_Janov%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů střídavě žlutých a zelených v poměru 11:1:1:1:1:1. V žerďové polovině vyrůstá z horního zeleného pruhu zvonek se čtyřmi modrými květy na zeleném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstají ze zeleného trojvrší čtyři modré květy zvonku na jednom zeleném stonku."
    },
    {
        obec: "Janovice",
        kod: 507423,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73911,
        latitude: 49.621505,
        longitude: 18.406126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Janovice_znak.svg",
        coatOfArmsFlagDescription: "Zelený list se třemi bílými obilnými klasy z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři stříbrné obilné klasy."
    },
    {
        obec: "Janovice nad Úhlavou",
        kod: 556394,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.345257,
        longitude: 13.218239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Janovice_nad_%C3%9Ahlavou_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří jedenáct svislých pruhů, bílý, modrý, bílý, modrý, bílý, červený, bílý, modrý, bílý, modrý a bílý, v poměru 1 : 1 : 1 : 1 : 1 : 10 : 1 : 1 : 1 : 1 : 1. Z dolního okraje červeného pruhu vyniká bílá kvádrovaná věž s cimbuřím a černou valbovou střechou se dvěma žlutými makovicemi, s černým oknem, prázdnou branou s vytaženou černou mříží a se žlutými otevřenými vraty na černých závěsech. Nad střechu od makovice vyniká kosmo žerď s bílým praporem. Z boku věže nad vraty vynikají dvě odvrácené žerdě s modrými prapory. Žerdě s hroty jsou žluté, listy praporů jsou k nim přichyceny cípy. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Janovice v Podještědí",
        kod: 561657,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46353,
        latitude: 50.762974,
        longitude: 14.824344
    },
    {
        obec: "Janská",
        kod: 530395,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.80461,
        longitude: 14.363559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Jansk%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý, široký třetinu šířky listu, a zeleno-žlutě polcený. V bílém pruhu tři vodorovné vlnité modré pruhy, v zeleném poli vztyčené žluté vřeteno, ve žlutém tři zkřížené zelené meče hroty dolů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-zlatě polcený štít se stříbrnou hlavou se třemi modrými vlnitými břevny. Vpravo vztyčené zlaté vřeteno, vlevo tři zkřížené postavené zelené meče."
    },
    {
        obec: "Ivanovice na Hané",
        kod: 593117,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68323,
        latitude: 49.305518,
        longitude: 17.093537,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Ivanovice_na_hane_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s maltézský křížem nad půlměsícem s tváří, cípy k hornímu okraji, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stoupajícím půlměsícem s tváří maltézský kříž, obojí stříbrné."
    },
    {
        obec: "Janské Lázně",
        kod: 579351,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54225,
        latitude: 50.631188,
        longitude: 15.781916,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/CoA_of_Jansk%C3%A9_L%C3%A1zn%C4%9B.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. V žerďové části modrého pruhu bílé zřídlo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně vpravo polceném štítě vpravo volný zlatý rákosový kříž, vlevo zvýšený modrý vrch, v něm stříbrné zřídlo."
    },
    {
        obec: "Janův Důl",
        kod: 546658,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46352,
        latitude: 50.704656,
        longitude: 14.940408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Januv_Dul_LB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, bílý, zelený a žlutý, v poměru 3:1:1:1, přes které je položen žlutý žerďový klín s vrcholem na žerďovém okraji žlutého pruhu. V klínu zelená uťatá lípa, ve žlutém pruhu modrý květ lnu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrným vlnitým břevnem nad zlatým trojvrším s modrým květem lnu zlatý klín se zelenou uťatou lípou."
    },
    {
        obec: "Janůvky",
        kod: 578142,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.671651,
        longitude: 16.592171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Jan%C5%AFvky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 2 : 5 : 2. Ve žlutém pruhu čelně modrá radlice, nahoře provázená dvěma modrými květy lnu se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě čelně modrá radlice, nahoře provázená dvěma modrými květy lnu se zlatými středy."
    },
    {
        obec: "Jarcová",
        kod: 542903,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75701,
        latitude: 49.444628,
        longitude: 17.965972,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/CZ_Jarcov%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List praporu opakuje znak. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít, v pravém poli postavená stříbrná radlice, v levém poli stříbrná hlava koně bez krku s červenou ohlávkou a dvěma zlatými puklicemi."
    },
    {
        obec: "Jablonec nad Nisou",
        kod: 563510,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46601,
        latitude: 50.722153,
        longitude: 15.170414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Jablonec-znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený. Uprostřed listu je městský znak. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jarohněvice",
        kod: 588563,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76801,
        latitude: 49.267176,
        longitude: 17.377672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Jarohn%C4%9Bvice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed žlutý obilný snop, přeložený vodorovně bílým parohem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatý obilný snop přeložený vodorovně stříbrným parohem."
    },
    {
        obec: "Jaroměř",
        kod: 574121,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.356904,
        longitude: 15.924238,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Jarom%C4%9B%C5%99_znak.png",
        coatOfArmsFlagDescription: "Červený list se třemi žerďovými klíny o základnách rovných jedné třetině šířky listu. Střední bílý klín s hrotem na středu vlajícího okraje, žluté klíny sahající do dvou třetin délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jaroměřice",
        kod: 578151,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56944,
        latitude: 49.625661,
        longitude: 16.751955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Jarom%C4%9B%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a vlnkovaný zelený se třemi vrcholy, v poměru 4 : 1. Z prostředního vrcholu vyrůstá bílý jednorožec. V žerďové části modrého pruhu dvě šesticípé žluté hvězdy nad sebou. Ve středu vlající části listu žlutá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný jednorožec vyrůstající ze zeleného trojvrší a provázený vpravo nahoře jednou a dole po stranách dvěma zlatými hvězdami."
    },
    {
        obec: "Jaroměřice nad Rokytnou",
        kod: 590754,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.094184,
        longitude: 15.893408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Jaromerice_nR_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - modrý a zelený v poměru 2:1. Na středu přes pruhy hnědý ležící jelen se žlutými parohy a kopyty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku ležící jelen přirozené barvy se zlatými parohy a kopyty."
    },
    {
        obec: "Jaroslav",
        kod: 575119,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.012339,
        longitude: 16.077983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Jaroslav_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým šikmým pruhem, širokým jednu šestinu šířky listu, na žerďové zelené pole s bílou radlicí hrotem dolů a ostřím k žerďovému okraji a vlající modré pole se žlutým kaprem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře šikmým stříbrným břevnem děleném štítě nahoře stříbrná radlice, dole zlatý kapr."
    },
    {
        obec: "Jaroslavice",
        kod: 594199,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.756671,
        longitude: 16.233615,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Jaroslavice_znak.jpg"
    },
    {
        obec: "Jarošov",
        kod: 578169,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56966,
        latitude: 49.824915,
        longitude: 16.16347,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Jaro%C5%A1ov_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý, v poměru 3 : 1 : 1 : 1. V červeném pruhu žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít. Nahoře zlatý zvon, dole modré břevno."
    },
    {
        obec: "Jarošov nad Nežárkou",
        kod: 546500,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37841,
        latitude: 49.189995,
        longitude: 15.06744,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Jaro%C5%A1ov_nad_Ne%C5%BE%C3%A1rkou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 1:4:1. V bílém pruhu černý medvěd ve skoku s červeným jazykem držící turnajský korunovaný helm s modro-žlutými přikrývadly. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, modře lemovaném štítě černý medvěd ve skoku s červeným jazykem držící turnajský korunovaný helm s modro-zlatými přikrývadly, jejichž levá část medvěda obtáčí."
    },
    {
        obec: "Jarov",
        kod: 578541,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.521192,
        longitude: 13.507696,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Jarov_%28Plze%C5%88-South_district%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený, v poměru 2 : 1 : 6. V modrém pruhu pod sebou šesticípá hvězda, tlapatý kříž a lilie, v zeleném květ prvosenky jarní, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod modrou, stříbrným vlnitým břevnem oddělenou hlavou zlatý pětilistý květ prvosenky jarní. V hlavě tlapatý kříž mezi hvězdou a lilií, vše zlaté."
    },
    {
        obec: "Jarov",
        kod: 558982,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.872569,
        longitude: 13.474387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Jarov_PS_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modro-žlutě polcenou krokví, vycházející z druhé čtvrtiny žerďového a vlajícího okraje s vrcholem na horním okraji listu, a s horními trojúhelníky opačných barev. Z dolního okraje listu vyrůstá listnatý strom se zelenou korunou se žlutou lilií a s hnědým kmenem, provázeným dvěma červenými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zlato-modře polcenou hlavou pod modro-zlatě polcenou zvýšenou krokví vyrůstající listnatý strom přirozené barvy se zlatou lilií v koruně, provázený dvěma červenými růžemi se zlatými semeníky."
    },
    {
        obec: "Jakartovice",
        kod: 507377,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74753,
        latitude: 49.915196,
        longitude: 17.684108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Jakartovice_CoA.png",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým pruhem širokým jednu šestinu délky listu ze kterého šikmo vyrůstají tři žluté klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vpravo stříbrná radlice, z níž šikmo nad sebou vynikají tři zlaté klasy."
    },
    {
        obec: "Jasenice",
        kod: 590762,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.25824,
        longitude: 16.163698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Jasenice_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s kosmým černým orlím ocasem s pařáty v horním rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kosmo shora vynikající černý orlí ocas s pařáty."
    },
    {
        obec: "Jarpice",
        kod: 532401,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27372,
        latitude: 50.321709,
        longitude: 14.08533,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Jarpice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Ve středu listu žlutá mitra se třemi (1, 2) vztyčenými zelenými vinnými listy bez stopek, s bílými fimbriemi, provázená po stranách vztyčenými přivrácenými korunovanými hady s vyplazenými jazyky a lilií mezi fimbriemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatá mitra se třemi (1, 2) vztyčenými zelenými vinnými listy bez stopek, se stříbrnými fimbriemi, provázená po stranách dvěma vztyčenými přivrácenými korunovanými hady s vyplazenými jazyky a lilií mezi fimbriemi, vše zlaté."
    },
    {
        obec: "Jasenná",
        kod: 574139,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55222,
        latitude: 50.318379,
        longitude: 15.99108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Jasenn%C3%A1_%28N%C3%A1chod_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s modrým kosmým pruhem vycházejícím z první šestiny horního do poslední šestiny dolního okraje listu. V bílých polích kosmo vztyčený zelený jasanový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zúžené modré kosmé břevno provázené dvěma kosmo vztyčenými zelenými jasanovými listy."
    },
    {
        obec: "Jasenná",
        kod: 585301,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76313,
        latitude: 49.253493,
        longitude: 17.894297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Jasenna_ZL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými jednu desetinu šířky listu. Uprostřed bílá koňská hlava s krkem a červeným uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná červeně uzděná koňská hlava s krkem, podložená dvěma stříbrnými sekerami - valaškami na zlatých topůrkách, provázená nahoře a dole dvěma zlatými bodláky, po stranách dvěma zlatými listy jasanu."
    },
    {
        obec: "Javor",
        kod: 541869,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.325542,
        longitude: 13.247541
    },
    {
        obec: "Javornice",
        kod: 576336,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51711,
        latitude: 50.171465,
        longitude: 16.349372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Javornice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým zvlněným žerďovým pruhem širokým jednu třetinu délky listu. V žerďovém pruhu červený kříž s rameny širokými jednu osminu šířky listu. V modrém pruhu bílý kůň ve skoku ve žlutou zbrojí a uzděním provázený v horním cípu žlutým javorovým listem. Bílý pruh má dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou zvlněnou hlavou s červeným heroldským křížem stříbrný kůň ve skoku se zlatou zbrojí a uzděním provázený vlevo nahoře vztyčeným zlatým javorovým listem."
    },
    {
        obec: "Javorek",
        kod: 595748,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.65028,
        longitude: 16.17387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Javorek_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý kvádrovaný list se zeleným žerďovým klínem s vrcholem ve druhé třetině délky listu. V klínu žlutý javorový list řapíkem k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná kvádrovaná hradba se zeleným sníženým hrotem, v něm zlatý javorový list."
    },
    {
        obec: "Javorník",
        kod: 586218,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69674,
        latitude: 48.862174,
        longitude: 17.533503,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Javornik_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 6 : 1 : 1. Uprostřed žlutý jelen ve skoku se zadními kopýtky na červeném pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatým jelenem ve skoku zlatý kalich doleva podložený stříbrno-červeně dělenou zavinulou střelou."
    },
    {
        obec: "Javorník",
        kod: 536148,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79070,
        latitude: 50.390868,
        longitude: 17.002821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Javornik_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený se vztyčeným hrotem střely převýšeným šesticípou hvězdou, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vztyčený hrot střely převýšený hvězdou, vše stříbrné."
    },
    {
        obec: "Javorník",
        kod: 572713,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.782812,
        longitude: 16.427483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Javorn%C3%ADk_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílé a červené, v poměru 1:1:2:1:1. Z prostředního pruhu vyrůstají do červených pruhů dvě protilehlá trojúhelníková pole se základnou rovnou jedné třetině délky listu a dotýkající se vrcholy horního a dolního bílého pruhu. Uprostřed listu v bílém pruhu zelený vztyčený javorový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený uťatý javor, provázený dole dvěma růžemi se zlatými semeníky."
    },
    {
        obec: "Javorník",
        kod: 580406,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.889566,
        longitude: 16.156118,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Javorn%C3%ADk_UO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 17 : 7. Ve žlutém pruhu vztyčený zelený javorový list, v žerďové a vlající polovině modrého pruhu po žluté lilii. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou patou se dvěma zlatými liliemi vztyčený zelený javorový list."
    },
    {
        obec: "Javorník",
        kod: 529851,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25763,
        latitude: 49.686598,
        longitude: 15.026636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Javorn%C3%ADk_BN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu bílý šál ve tvaru točenice, v bílém pruhu dva odkloněné javorové listy na stopkách vyrůstající z dvounažky, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře stříbrný šál ve tvaru točenice, dole dva odkloněné javorové listy na stopkách vyrůstající z dvounažky, vše zelené."
    },
    {
        obec: "Javůrek",
        kod: 583154,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.255628,
        longitude: 16.362283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Jav%C5%AFrek_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list. Uprostřed žluté jetelovitě zakončené perizonium pod bílým javorovým listem, nad hornickými kladívky, želízko žluté, mlátek bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté jetelovitě zakončené perizonium provázené nahoře vztyčeným stříbrným javorovým listem a dole hornickými kladívky, želízko zlaté, mlátek stříbrný."
    },
    {
        obec: "Jedlá",
        kod: 568783,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.73822,
        longitude: 15.234446,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Jedl%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, modrý, bílý a modrý, v poměru 5 : 1 : 1 : 1 : 3. Tři střední pruhy jsou vlnité s prohlubní a vrcholem. V zeleném pruhu k hornímu okraji listu posunutá bílá jedle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlnitě polcený štít, v pravém zeleném poli nahoře stříbrná jedle, levé pole třikrát stříbrno-modře vlnitě polcené."
    },
    {
        obec: "Jedlany",
        kod: 562963,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39137,
        latitude: 49.494676,
        longitude: 14.71654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Jedlany_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutým kosmým pruhem vycházejícím z prvních pěti osmin žerďového okraje listu do posledních pěti osmin vlajícího okraje listu. Do pruhu vyrůstají tři zelené jedle s černými kmeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě rozšířené zlaté kosmé břevno se třemi rostoucími zelenými jedlemi s černými kmeny."
    },
    {
        obec: "Jedlí",
        kod: 536288,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.931312,
        longitude: 16.794838,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Jedl%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený. V horním rohu žlutý maltézský kříž, v bílém pruhu zelená jedle s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným kůlem jedle přirozené barvy vyrůstající ze zeleného dvojvrší, provázená vpravo nahoře zlatým osmihrotým křížem."
    },
    {
        obec: "Jedlová",
        kod: 578185,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56991,
        latitude: 49.661104,
        longitude: 16.306186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Jedlov%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený a střídavě modré a bílé, v poměru 10:2:1:2:1:4. V červeném pruhu žlutá růže s modrým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře zlatá růže s modrým semeníkem a zelenými kališními lístky, dole dvě stříbrná břevna."
    },
    {
        obec: "Jedomělice",
        kod: 532410,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27378,
        latitude: 50.233057,
        longitude: 13.972409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Jedom%C4%9Blice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a dva vodorovné pruhy, zelený a zubatý bílý, v poměru 7 : 9. Bílý pruh má tři čtvercové a dva poloviční zuby. V červeném pruhu volné bílé vykořeněné lekno listem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo vyrůstá z poltící linie stříbrné vykořeněné lekno, vlevo stříbrná hornická kladívka nad stříbrnou hradbou s modrým vlnitým břevnem."
    },
    {
        obec: "Jedousov",
        kod: 575127,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.995451,
        longitude: 15.610295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Jedousov_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, pětkrát lomený žlutý a pilovitý zelený se třemi zuby, v poměru 4 : 1 : 2. V modrém pruhu dolů prohnutá štika, v zeleném lilie, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně zlatým pětkrát zalomeným břevnem dělený štít. Nahoře dolů prohnutá štika, dole lilie, obojí stříbrné."
    },
    {
        obec: "Jedovnice",
        kod: 581682,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.344631,
        longitude: 16.756055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Jedovnice_CoA.svg",
        coatOfArmsFlagDescription: "Červený list s polovinou modrého jelena ve skoku se žlutou zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jehnědí",
        kod: 580414,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 49.971109,
        longitude: 16.310007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Jehn%C4%9Bd%C3%AD.svg",
        coatOfArmsFlagDescription: "Bílo-modře čtvrcený list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné ohlížející se jehně se zlatou zbrojí."
    },
    {
        obec: "Jemnice",
        kod: 590789,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.019086,
        longitude: 15.570038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Jemnice_CoA.png",
        coatOfArmsFlagDescription: "Bílo-modře čtvrcený list. Uprostřed svislý červeno-bíle šachovaný pruh (6x2) široký jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná dvojitá kvádrovaná hradba s cimbuřím a dvěma věžemi, každá s cimbuřím, černou branou a dvěma okny. Mezi věžemi stojí na stínkách stříbrno-červeně šachovaná orlice se zlatou zbrojí."
    },
    {
        obec: "Jemníky",
        kod: 532428,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27401,
        latitude: 50.207644,
        longitude: 14.119076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Jemn%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený s propletenými bílými vykořeněnými lekny a zelený vydutý s bílými zkříženými hornickými kladívky. Poměř šířky k délce listuje 2 : 3.",
        coatOfArmsDescription: "Červený štít se zelenou vydutou patou. Ve štítě dvě propletená stříbrná vykořeněná lekna, v patě stříbrná hornická kladívka."
    },
    {
        obec: "Jenčice",
        kod: 505528,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.483044,
        longitude: 14.004486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Jen%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1:4:1. V bílém pruhu na zeleném návrší, vyrůstajícím z dolního okraje pruhu, černá žlutě kvádrovaná věž s prázdnou branou s otevřenými žlutými vraty a s cimbuřím, na kterém je na žluté žerdi vztyčena korouhev s ocasem, modrá s červeno-bíle šachovaným orlím křídlem. Po stranách věže vyrůstají z návrší dvě hnědé větve jeřábu oskeruše se zelenými listy, dole s červenými plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší černá kvádrovaná, zlatě spárovaná věž s prázdnou branou se zlatými otevřenými vraty a s cimbuřím, na něm na zlaté žerdi vztyčená korouhev s ocasem, modrá s červeno-stříbrně šachovaným orlím křídlem. Po stranách věže z návrší vyrůstají dvě větve jeřábu oskeruše, dole s trsem plodů, přirozené barvy."
    },
    {
        obec: "Jeníkov",
        kod: 547816,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.74259,
        longitude: 15.957449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Jen%C3%ADkov_CR_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list. V horním rohu ve žlutém mezikruží knížecí čepice. Ve střední části listu obilný klas se dvěma listy, vodorovně podložený v žerďové a střední části dolní poloviny listu obilným klasem k žerďovému okraji, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vpravo nahoře ve zlatém kruhu knížecí čepice, vlevo obilný klas se dvěma odkloněnými listy, dole podložený položeným obilným klasem, obojí zlaté."
    },
    {
        obec: "Jeníkov",
        kod: 567582,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41724,
        latitude: 50.628702,
        longitude: 13.749019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Jenikov_TP_CZ_CoA.jpg",
        coatOfArmsDescription: "Modro-stříbrně děleným vlnitým břevnem zlato-červeně dělený štít, dole stříbrná hlava jednorožce se zlatým rohem."
    },
    {
        obec: "Jeníkovice",
        kod: 570133,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.22828,
        longitude: 16.002737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Jenikovice_%28Hradec_Kralove%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý, v poměru 1:2 a vodorovný zelený pruh na dolním okraji široký jednu čtvrtinu šířky listu, ze kterého do bílého pruhu vyrůstá zelená lípa. V modrém pruhu ve střední části listu stojí žlutá kostelní věž s obloukovým oknem, obdélníkovými dveřmi, stanovou střechou se sanktusníkem pod jehlancovou střechou, vše červené, se žlutou makovicí s křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě se zelenou patou vpravo vyrůstající zelená lípa, vlevo na patě stojící zlatá kostelní věž s dveřmi, obloukovým oknem, stanovou střechou se sanktusníkem pod jehlancovou střechou, vše červené, zakončenou zlatou žerdí s makovicí a křížkem."
    },
    {
        obec: "Jeníkovice",
        kod: 575135,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.981594,
        longitude: 15.660618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Znak_obce_Jen%C3%ADkovice_u_Pardubic.jpg",
        coatOfArmsFlagDescription: "Zeleno-modře kosmo dělený list. V zeleném žerďovém poli ohlížející se jezevec, v modrém vlajícím poli žluté slunce bez obličeje s šestnácti paprsky střídavě plamennými a trojúhelníkovými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně kosmo děleném štítě nahoře zlaté slunce, dole ohlížející se jezevec přirozené barvy."
    },
    {
        obec: "Jeneč",
        kod: 539317,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25261,
        latitude: 50.087404,
        longitude: 14.214924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Jene%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým třetinu délky listu. V bílém pruhu kotvicový kříž nad šesticípou hvězdou, obojí červené. V modrém poli bílý rozkřídlený dravý pták se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou cimbuřovou hlavou s kotvicovým křížem a hvězdou vedle sebe, obojí červené, stříbrný rozkřídlený dravý pták se zlatou zbrojí a jazykem."
    },
    {
        obec: "Jenišov",
        kod: 537926,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.226649,
        longitude: 12.800046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Jenisov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým vlajícím klínem s vrcholem v první třetině délky listu. V žerďové části bílý meč hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný snížený hrot s černými zkříženými hornickými kladívky, nad ním stříbrný doprava položený meč."
    },
    {
        obec: "Jenišovice",
        kod: 563609,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46833,
        latitude: 50.627796,
        longitude: 15.136637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Jeni%C5%A1ovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a bílý posetý vztyčenými zelenými lipovými listy, v poměru 3:1:8. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo na zeleném návrší stříbrné průčelí kostela s věží s obloukovými dveřmi a dvěma okny nad sebou, čtvercovým a obdélníkovým, vše černé, a s červenou střechou zakončenou zlatou makovicí s křížkem, levé pole poseto vztyčenými zelenými lipovými listy."
    },
    {
        obec: "Jenišovice",
        kod: 571563,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53864,
        latitude: 49.929644,
        longitude: 16.03112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Jeni%C5%A1ovice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě zelené a červené, v poměru 1:2:2:1. Uprostřed listu čtyři mřežovitě propletené obilné klasy, každý s listem, převýšené třemi prostupujícími se kruhy (1,2), vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě čtyři mřežovitě propletené obilné klasy, každý s listem, převýšené třemi prostupujícími se kruhy (1,2), vše zlaté."
    },
    {
        obec: "Jenštejn",
        kod: 538264,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25073,
        latitude: 50.152759,
        longitude: 14.611909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Shield_of_Jen%C5%A1tejn.svg",
        coatOfArmsFlagDescription: "List tvoři dva vodorovné pruhy, modrý a zubatý bílý se čtyřmi obdélníkovými zuby sahajícími do pětiny šířky listu a pěti stejnými mezerami, v poměru 3 : 7. V bílém pruhu vyrůstají z dolního okraje listu dvě červené supí hlavy se spojenými krky, žlutými zobáky a červenými jazyky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou cimbuřovou hlavou vyrůstají dvě červené supí hlavy se spojenými krky, zlatými zobáky a červenými jazyky."
    },
    {
        obec: "Jersín",
        kod: 587265,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58825,
        latitude: 49.421358,
        longitude: 15.830768
    },
    {
        obec: "Jeřice",
        kod: 572993,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.344654,
        longitude: 15.680876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Je%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "Bílým šikmým vlnitým pruhem dělený list. V červeném žerďovém poli žlutá dóza na masti, v zeleném vlajícím poli čelně postavená radlice. Vlnitý pruh je široký jednu desetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně šikmým stříbrným vlnitým břevnem děleném štítě vpravo dóza na masti, vlevo čelně postavená radlice, obojí zlaté."
    },
    {
        obec: "Jeřišno",
        kod: 568805,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58274,
        latitude: 49.792454,
        longitude: 15.641764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Je%C5%99i%C5%A1no_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1:2:1. V zeleném pruhu korbel nábojem nahoru, jehož otvorem prochází stonek řeřichy se třemi listy a pěti květenstvími, vše žluté. V bílých pruzích po třech černých hranostajových ocáscích pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě široký zelený kůl, provázený po stranách čtyřmi (2,2) černými hranostajovými ocásky pod sebou. V kůlu korbel nábojem nahoru, jehož otvorem prochází stonek řeřichy se třemi listy a pěti květenstvími, vše zlaté."
    },
    {
        obec: "Jeřmanice",
        kod: 530484,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46312,
        latitude: 50.699666,
        longitude: 15.09393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Je%C5%99manice_CoA.png"
    },
    {
        obec: "Jesenec",
        kod: 589586,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79853,
        latitude: 49.608512,
        longitude: 16.862184,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Jesenec_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý, v poměru 2:1:4:1:2. V červeném pruhu bílá lilie mezi dvěma bílými růžemi se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se stříbrnou lilií provázenou dvěma stříbrnými růžemi se zlatými semeníky a zelenými kališními lístky pštros přirozené barvy s červeným zobákem, šikmo podložený zlatou berlou a vpravo provázený vztyčenou zlatou jasanovou větévkou."
    },
    {
        obec: "Jesenice",
        kod: 539325,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25242,
        latitude: 49.968246,
        longitude: 14.513602,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Jesenice_u_prahy_znak.svg",
        coatOfArmsFlagDescription: "Červený list s kosmo položeným klíčem zuby nahoru a k žerdi, šikmo přeloženým obilným klasem se dvěma listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kosmo položený gotický klíč zuby nahoru a ven, šikmo přeložený obilným klasem se dvěma listy, obojí zlaté."
    },
    {
        obec: "Jesenice",
        kod: 540391,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.607849,
        longitude: 14.47823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Coats_of_arms_Jesenice.jpeg",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. V žerďové části modrého pole žluté mlýnské kolo. V bílém vlajícím poli tři černé šikmé pruhy vycházející z druhé, čtvrté a šesté sedminy dolního okraje do druhé, čtvrté a šesté sedminy vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo zlaté mlýnské kolo, vlevo tři šikmá černá břevna."
    },
    {
        obec: "Jesenice",
        kod: 541834,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27033,
        latitude: 50.097137,
        longitude: 13.469595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Jesenice_RA_CZ_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílo - červeně polcenou orlicí se žlutou zbrojí a jetelovitě zakončeným perizoniem a se žlutými zkříženými gotickými klíči. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrno - červeně polcená orlice se zlatou zbrojí a jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Jeseník",
        kod: 536385,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79001,
        latitude: 50.224725,
        longitude: 17.198147,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Coat_of_arms_of_Jesen%C3%ADk.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, černý a zelený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jeseník nad Odrou",
        kod: 599468,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74233,
        latitude: 49.61204,
        longitude: 17.905365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Jesenik_nad_odrou_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, modrý, zelený a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrým vlnitým břevnem na zeleném stonku se čtyřmi listy červená růže se stříbrným semeníkem."
    },
    {
        obec: "Jesenný",
        kod: 577171,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51212,
        latitude: 50.660595,
        longitude: 15.338516
    },
    {
        obec: "Jestřabí",
        kod: 585319,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76333,
        latitude: 49.068135,
        longitude: 17.955981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Jest%C5%99ab%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm šikmých pruhů, střídavě modrých a žlutých, v poměru 7:2:2:8:2:2:7. V prostředním žlutém pruhu hnědý jestřáb se žlutým zobákem letící k dolnímu rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo zlatá noha dravého ptáka, vlevo jestřáb se zlatým zobákem slétající na šikmo dolů běžícího zajíce, oba přirozené barvy."
    },
    {
        obec: "Jestřabí Lhota",
        kod: 533360,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.089936,
        longitude: 15.261812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Jest%C5%99ab%C3%AD_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4 : 1. Uprostřed modrého pruhu jestřáb ohlížející se k vlajícímu okraji listu. V žerďové části modrého pruhu lilie, ve vlající části kalich, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku ohlížející se jestřáb přirozené barvy, provázený nahoře vpravo lilií a vlevo kalichem, obojí zlaté."
    },
    {
        obec: "Jestřabí v Krkonoších",
        kod: 577189,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.680618,
        longitude: 15.491835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Jest%C5%99ab%C3%AD_v_Krkono%C5%A1%C3%ADch_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 3. Z prostředních sedmi dvanáctin zeleného pruhu vyniká zelené trojvrší. Na prostředním vrchu stojí jestřáb, pod trojvrším tři (2, 1) žluté vrubové tlapaté kříže. Z krajů prostředního vrchu vyrůstá po zeleném stonku routy vonné. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě jestřáb přirozené barvy stojící na prostředním vrchu zeleného trojvrší se třemi (2, 1) zlatými vrubovými tlapatými kříži. Z krajů prostředního vrchu vyrůstá po zeleném stonku routy vonné."
    },
    {
        obec: "Jestřebí",
        kod: 574147,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.362184,
        longitude: 16.182163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Jest%C5%99eb%C3%AD_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3:2:3. V horním pruhu žlutý rozkřídlený jestřáb s ocasními pery provlečenými bílým tkalcovským člunkem, v prostředním pruhu tři modré květy lnu, v dolním pruhu dvě přivrácená bílá lekna. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi modrými květy lnu zlatý rozkřídlený jestřáb s ocasními pery provlečenými stříbrným tkalcovským člunkem, pod ním dvě přivrácená stříbrná lekna."
    },
    {
        obec: "Jestřebí",
        kod: 561665,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47161,
        latitude: 50.608694,
        longitude: 14.584657,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Jest%C5%99eb%C3%AD_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1 : 3 : 1. V červeném pruhu vztyčený bílý leknínový list. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jestřebí",
        kod: 536393,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.852199,
        longitude: 16.868736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Jest%C5%99eb%C3%AD_SU_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem s vrcholem na vlajícím okraji a dvěma bílými rovnoramennými trojúhelníky se základnami na svislé ose listu a s vrcholy na vlajícím okraji listu. V žerďové polovině bílého klínu vykračující jestřáb se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný hrot, v něm vykračující jestřáb přirozené barvy se zlatou zbrojí, po stranách dva stříbrné kužely."
    },
    {
        obec: "Ješetice",
        kod: 532134,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25789,
        latitude: 49.581263,
        longitude: 14.610228
    },
    {
        obec: "Jetětice",
        kod: 549479,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39848,
        latitude: 49.386055,
        longitude: 14.29271,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Jet%C4%9Btice_CoA.png",
        coatOfArmsFlagDescription: "List šikmo dělený bílým pruhem širokým devítinu délky listu na zelené žerďové pole se žlutou šesticípou hvězdou a červené vlající pole se žlutou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně šikmým stříbrným vlnitým břevnem děleném štítě nahoře hvězda, dole lilie, obojí zlaté."
    },
    {
        obec: "Jetřichov",
        kod: 574155,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54983,
        latitude: 50.615071,
        longitude: 16.266763,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Jet%C5%99ichov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se svislým bílým vlnitým pruhem širokým pětinu délky listu, s červenou vlčí udící. V modrých polích svisle dvě žluté ostrve, každá se třemi suky, jeden ke středu, dva k žerďovému a vlajícímu okraji, vlající dolů obrácená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vlnitý kůl s červenou vlčí udicí, provázený dvěma zlatými ostrvemi, každá se třemi suky, jeden dovnitř a dva ven, levá obrácená."
    },
    {
        obec: "Jevany",
        kod: 533378,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28166,
        latitude: 49.970671,
        longitude: 14.810582,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Jevany_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s modrým pruhem na vlajícím okraji širokým čtvrtinu délky listu. V zeleném poli zkřížené žluté větévky jívy s pupeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou vlnitou patou zkřížené zlaté větévky jívy s pupeny."
    },
    {
        obec: "Jevíčko",
        kod: 578193,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.632295,
        longitude: 16.71136,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Jev%C3%AD%C4%8Dko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu moravská orlice nesoucí na hrudi černý štítek se žlutým majuskulním písmenem G. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě moravská orlice nesoucí na hrudi černý štítek se zlatým majuskulním písmenem G."
    },
    {
        obec: "Jeviněves",
        kod: 531871,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27705,
        latitude: 50.345214,
        longitude: 14.33821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Jevin%C4%9Bves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a zelený, v poměru 1 : 2 : 1. V modrém pruhu žlutý vinný hrozen, ve žlutém červený kaštanový list a v zeleném pruhu žlutá pohanská koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou cimbuřovou hlavou se čtyřmi zlatými vinnými hrozny vztyčený červený kaštanový list nad zeleným návrším se zlatou pohanskou korunou."
    },
    {
        obec: "Jevišovka",
        kod: 584525,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69183,
        latitude: 48.82869,
        longitude: 16.46626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Coat_of_arms_of_Jevi%C5%A1ovka.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým blatouchem se třemi květy v horním rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý blatouch se třemi květy."
    },
    {
        obec: "Jevišovice",
        kod: 594202,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.987465,
        longitude: 15.990027,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Jevisovice_znak.gif",
        coatOfArmsFlagDescription: "Modrý list. Na dolním okraji žlutá kvádrovaná hradba vycházející ze třetí třetiny žerďového a vlajícího okraje listu s prázdnou branou, s černou vytaženou mříží a hnědými dřevěnými vraty na černých závěsech. Z hradby vyrůstají tři žluté věže, prostřední vyšší okrouhlá s renesančním arkýřem se stříškou a kopulovitou střechou, krajní věže se dvěma obloukovými okny a valbovými střechami. Vše kvádrované, střechy červené zakončené žlutými makovicemi, okna černá. Nad branou černo - bíle dělený štítek nahoře se dvěma bílými břevny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě hradba s prázdnou branou, černou vytaženou mříží a dřevěnými vraty přirozené barvy na černých závěsech. Z hradby vyrůstají tři věže, prostřední vyšší okrouhlá s renesančním arkýřem se stříškou a kopulovitou střechou, krajní věže se dvěma obloukovými okny a valbovými střechami. Vše zlaté, kvádrované, střechy červené zakončené zlatými makovicemi, okna černá. Nad branou černo - stříbrně dělený štítek nahoře se dvěma stříbrnými břevny."
    },
    {
        obec: "Jezbořice",
        kod: 575143,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.980674,
        longitude: 15.694777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Jezbo%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se třemi bílými žerďovými klíny s vrcholy sahajícími do třetiny délky listu, v každém vodorovně černý hranostají ocásek. V červeném poli zvon, provázený dvěma obrácenými podkovami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě čtyři zvýšené červené kužely, v nich zvon nahoře provázený dvěma obracenými podkovami, vše zlaté. Mezi kužely tři černé hranostají ocásky."
    },
    {
        obec: "Jezdkovice",
        kod: 547191,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74755,
        latitude: 49.923129,
        longitude: 17.774912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Jezdkovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 3:14:3. V žerďové části a střední části bílého pruhu zavinutá střela hrotem k žerdi a ve vlající části vozové kolo, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ve stříbrném rozšířeném kůlu zavinutá střela nad vozovým kolem, obojí červené."
    },
    {
        obec: "Jezdovice",
        kod: 587273,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58901,
        latitude: 49.323203,
        longitude: 15.484258,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Jezdovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený pruh široký polovinu šířky listu a bílý most se třemi modrými oblouky vyrůstající z dolního okraje listu, v prostředním oblouku vztyčený žlutý dubový list. V červeném pruhu kosmý mlátek podložený šikmo želízkem a mečem hrotem k dolnímu okraji listu, vše bílé se žlutými topůrky a jílcem, provázené v žerďové a vlající části žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - modře stříbrným mostem se třemi oblouky dělený štít. Nahoře kosmý mlátek šikmo podložený želízkem a postaveným mečem, vše stříbrné se zlatými topůrky a jílcem, provázené dvěma zlatými osmihrotými hvězdami, dole v prostředním oblouku vztyčený zlatý dubový list."
    },
    {
        obec: "Jezernice",
        kod: 556998,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.547873,
        longitude: 17.625008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Znak_obce_Jezernice.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý v poměru 8:1:1. Ze středního vrcholu a prohlubní bílého pruhu vyrůstají tři žluté orobince s listy. Zvlněný pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně vlnitě děleném štítě nahoře vyrůstající tři zlaté orobince s listy, dole dvě modrá vlnitá břevna."
    },
    {
        obec: "Jezeřany-Maršovice",
        kod: 594211,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67175,
        latitude: 49.028912,
        longitude: 16.437429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Jeze%C5%99any_Mar%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed listu bílá radlice hrotem nahoru a ostřím k žerdi, provázená v červeném pruhu bílým vinařským nožem - kosířem se žlutou rukojetí, ostřím k žerďovému okraji a v modrém pruhu žlutým hroznem na stříbrném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrná vztyčená radlice nad zkříženými stříbrnými motykami se zlatými násadami, provázená vpravo stříbrným vinařským nožem - kosířem se zlatou rukojetí a vlevo zlatým vinným hroznem se stříbrným stonkem."
    },
    {
        obec: "Ježená",
        kod: 587281,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58841,
        latitude: 49.416395,
        longitude: 15.465658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Je%C5%BEen%C3%A1_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 6 : 1 : 5. Do horního pruhu vyrůstá černá plamenná orlice se žlutým zobákem a jetelovitě zakončeným perizoniem, ve spodním pruhu převrácená černá hornická kladívka na červených topůrkách, přeložená žlutou svatojakubskou mušlí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě ze zúženého modrého vlnitého břevna vyrůstá černá plamenná orlice se zlatým zobákem a jetelovitě zakončeným perizoniem. Pod břevnem převrácená černá hornická kladívka na červených topůrkách, přeložená zlatou svatojakubskou mušlí."
    },
    {
        obec: "Ježov",
        kod: 586226,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69648,
        latitude: 49.027077,
        longitude: 17.210115,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Jezov_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-červeně kosmo dělený list se vzpřímeným vykračujícím žlutým ježkem s červenou zbrojí nesoucím v tlamě bílý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře kosmo děleném štítě vzpřímený vykračující zlatý ježek s červenou zbrojí nesoucí v tlamě stříbrný vinný hrozen."
    },
    {
        obec: "Ježkovice",
        kod: 593125,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68304,
        latitude: 49.298073,
        longitude: 16.892634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Je%C5%BEkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a pilovitý bílý s pěti zuby. V modrém pruhu tři žlutá jablka pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný ježek nesoucí na bodlinách tři zlatá jablka."
    },
    {
        obec: "Ježov",
        kod: 548073,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.613127,
        longitude: 15.23382
    },
    {
        obec: "Ježovy",
        kod: 541753,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.491162,
        longitude: 13.228504,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Coat_of_arms_of_Je%C5%BEovy.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 7. V horním červeném pět bílých sedmicípých hvězd vedle sebe, žerďová polovina dolního pruhu bílo-modře šupinována, ve vlající polovině sedm vodorovných pruhů, střídavě modrých a bílých. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Pod červenou hlavou s pěti stříbrnými sedmihrotými hvězdami vedle sebe polcený štít, pravé pole stříbrno-modře šupinováno, v levém modrém poli tři stříbrná břevna."
    },
    {
        obec: "Jickovice",
        kod: 562084,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.450736,
        longitude: 14.215979,
        coatOfArmsFlagDescription: "V zeleném listu červený žerďový klín se základnou na druhé až čtvrté pětině žerďového okraje dosahující do třetiny délky listu a k němu přiléhající modrá, žlutě lemovaná vidlice. Ramena vidlice jsou široká pětinu, lem patnáctinu šířky listu. Ve středu vidlice bílá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modrá vlnitá vidlice se stříbrnou hvězdou uprostřed, provázená nahoře červeným jetelovým křížem, dole vztyčenými zelenými listy s černými řapíky, vpravo lipovým a vlevo habrovým."
    },
    {
        obec: "Jičín",
        kod: 572659,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.435433,
        longitude: 15.361144,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Jicin_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed listu znak města. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jičíněves",
        kod: 573001,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50731,
        latitude: 50.373176,
        longitude: 15.337392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Ji%C4%8D%C3%ADn%C4%9Bves.png",
        coatOfArmsFlagDescription: "Červený list s bílým dolním klínem s vrcholem na horním okraji listu. V klínu čelně stojící červený páv hledící k žerdovému okraji listu, se žlutou korunkou, zbrojí a oky na pérech. V žerďovém trojúhelníku šikmo, ve vlajícím kosmo, vykořeněný žlutý topol. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít se stříbrný hrotem, v něm čelně stojící a doprava hledící červený páv se zlatou korunkou, zbrojí a oky na pérech. Hrot je provázen vykořeněnými zlatými topoly."
    },
    {
        obec: "Jihlava",
        kod: 586846,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58601,
        latitude: 49.41586,
        longitude: 15.595469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Jihlava_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Kosmo dělený list, horní pole bílé s červeným ježkem, dolní červené s českým lvem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Znak Jihlavy tvoří štít dělený na čtvrtiny. V prvním a čtvrtém rudém poli se nachází stříbrný korunovaný český lev ve skoku, ve druhém a třetím stříbrném poli se vyskytuje rudá figura ježka. Ježek je s Jihlavou spojován od přelomu 14. a 15"
    },
    {
        obec: "Jihlávka",
        kod: 587290,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.261225,
        longitude: 15.292576
    },
    {
        obec: "Jetřichovice",
        kod: 562556,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40716,
        latitude: 50.852494,
        longitude: 14.394022,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Znak_obce_Jet%C5%99ichovice.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu čtvrtinu listu se čtyřmi žlutými korunami a pět pruhů, zelený, bílý, vlnitý modrý, bílý a zelený v poměru 1:1:2:1:1. Modrý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve štítě pod červenou hlavou se čtyřmi zlatými korunami modrý vlnitý kůl se zlatým vztyčeným klíčem, postranní pole jsou vpravo zeleno-stříbrně a vlevo stříbrno-zeleně polcena."
    },
    {
        obec: "Jíkev",
        kod: 537250,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28932,
        latitude: 50.264465,
        longitude: 15.060123
    },
    {
        obec: "Jilem",
        kod: 562769,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.17677,
        longitude: 15.268924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Jilem_znak.png",
        coatOfArmsFlagDescription: "Zelený list. V žerďové polovině bílý osmilistý květ se žlutým středem a k žerďovému okraji listu prohnutým stonkem se třemi listy k žerdi nad sebou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný osmilistý květ se zlatým středem na zlatém, doprava prohnutém stonku se třemi listy vpravo nad sebou."
    },
    {
        obec: "Jilem",
        kod: 548413,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.713475,
        longitude: 15.58347,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Jilem_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý zelený, v poměru 3 : 1. Zelený pruh má šest čtvercových zubů a pět stejných mezer. Z prostřední mezery vyrůstá zelený jilm s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě jilm přirozené barvy vyrůstající ze zelené cimbuřové paty se stříbrným vydutým hrotem."
    },
    {
        obec: "Jílové",
        kod: 562564,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40701,
        latitude: 50.760925,
        longitude: 14.103948,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/J%C3%ADlov%C3%A9_D%C4%9B%C4%8D%C3%ADn.png",
        coatOfArmsFlagDescription: "Bílý list se zelenou krokví vymezenou bílým žerďovým klínem s vrcholem ve středu listu; vrchol krokve je na středu vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku při levém okraji štítu pahýl dubu s jednou doprava rostoucí větví zakončenou třemi listy, na které sedí sova, vše přirozených barev."
    },
    {
        obec: "Jilemnice",
        kod: 577197,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.608996,
        longitude: 15.506637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Jilemnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý se zeleným jilmem s hnědým kmenem a zelený v poměru 1:2:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jílové u Držkova",
        kod: 563617,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.670963,
        longitude: 15.295375,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/J%C3%ADlov%C3%A9_u_Dr%C5%BEkova_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 2, bílý a bílo-červeně kosmo dělený. Z prostředních tří pětin horního okraje dolního pruhu vyniká zelené trojvrší, z něhož vyrůstá zelená lípa s hnědým kmenem. V bílé části dolního pruhu tři červené vodorovné pruhy, v červené části u vlajícího okraje listu bílé zkřížené ostrve, každá se čtyřmi suky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě dělený štít. Nahoře ve stříbrném poli vyrůstá ze zeleného trojvrší lípa přirozené barvy. Dolní pole červeno-stříbrně kosmo dělené, nahoře stříbrné zkřížené ostrve, každá se čtyřmi suky, dole tři červená břevna."
    },
    {
        obec: "Jílovice",
        kod: 544639,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37332,
        latitude: 48.889587,
        longitude: 14.726899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Jilovice_CB_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným kosmým gotickým klíčem zuby nahoru a k žerdi. Na dolním okraji červené pětivrší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad pětivrším kosmý gotický klíč, vše červené."
    },
    {
        obec: "Jílové u Prahy",
        kod: 539333,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25401,
        latitude: 49.895549,
        longitude: 14.493434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/COA_J%C3%ADlov%C3%A9_u_Prahy.png",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy, horní bílý, dolní červený. Na střed listu do bílého pole je položena žlutá koruna, do červeného pole žluté 'W', oboje z převýšení štítu městského znaku. Písmeno 'W' zaujímá jednu třetinu délky listu. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Jílovice",
        kod: 576352,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51772,
        latitude: 50.250722,
        longitude: 16.012785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/J%C3%ADlovice_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu se žlutým obilným klasem se dvěma listy a červené pole s kosmým gotickým klíčem zuby dolů a k vlajícímu okraji listu, šikmo podloženým berlou, závitem k vlajícímu okraji, s bílým sudariem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně vpravo polceném štítě vpravo zlatý obilný klas se dvěma listy, vlevo kosmo postavený zlatý gotický klíč zuby ven, šikmo podložený zlatou berlou, závitem doleva, se stříbrným sudariem."
    },
    {
        obec: "Jíloviště",
        kod: 539341,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25202,
        latitude: 49.927626,
        longitude: 14.342843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Jiloviste_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červený, bílý a zelený, v poměru 2:1:2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůl, v něm vztyčená přirozená hořící otep dřeva převázaná zlatým provazem."
    },
    {
        obec: "Jimlín",
        kod: 546011,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.320403,
        longitude: 13.747256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Znak_obce_Jiml%C3%ADn.gif",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polceným orlem se žlutou zbrojí, korunou kolem krku a jetelovitě zakončeným perizoniem, stojícím na bílém hříči. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně polcený orel se zlatou zbrojí, korunou kolem krku a jetelovitě zakončeným perizoniem, stojící na stříbrném hříči."
    },
    {
        obec: "Jimramov",
        kod: 595772,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59242,
        latitude: 49.637288,
        longitude: 16.226424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Jimramov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi bílými rovnoramennými trojúhelníky se základnami na dolním okraji listu. Vrcholy krajních trojúhelníků dosahují do tří pětin šířky a prostředního do čtyř pětin šířky listu. Nad vrcholy trojúhelníků po jedné deseticípé hvězdě se střídavě zkrácenými cípy. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jinačovice",
        kod: 583171,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66434,
        latitude: 49.267903,
        longitude: 16.529591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Znak_jinacovice.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, červeno - bíle šachovaný (8 x 2), modrý a červený, v poměru 1 : 1 : 1 : 3. V červeném pruhu žlutý pohár s víkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře stříbrno - červeně polcený. V pravém poli postavené krojidlo a vztyčená radlice, obojí přivrácené a modré, v levém zlatý pohár s víkem. V dolním modrém poli položené stříbrno - červeně šachované křídlo."
    },
    {
        obec: "Jince",
        kod: 540404,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.786343,
        longitude: 13.978781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Jince_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, černý a červený v poměru 3 : 1 : 3. Uprostřed listu bílý trilobit. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Do pravého modrého pole vynikají šikmá skála, dole zalesněná, přirozené barvy a vyrůstající volná kvádrovaná stříbrná hradba se čtyřmi stínkami. Levé pole červeno - černě dělené, nahoře zkřížené cvočkářské hřeby, dole trilobit, obojí stříbrné."
    },
    {
        obec: "Jindřichov",
        kod: 597449,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79383,
        latitude: 50.251932,
        longitude: 17.519131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Jind%C5%99ichov_znak.png",
        coatOfArmsFlagDescription: "V žerďové polovině bílého listu černý kohout se žlutou zbrojí, červeným hřebínkem a lalokem, držící v pařátu červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý kohout se zlatou zbrojí a červeným hřebínkem držící v pařátu červené srdce."
    },
    {
        obec: "Jindřichov",
        kod: 513873,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.644693,
        longitude: 17.748345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Jind%C5%99ichov_P%C5%99erov.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - modrý, bílý a modrý v poměru 1:2:1. Na bílém pruhu zkřížené modré sekery s hnědými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie nad stříbrným trojvrším se zkříženými sekerami přirozených barev."
    },
    {
        obec: "Jindřichov",
        kod: 536521,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78823,
        latitude: 50.095891,
        longitude: 16.985006,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Jind%C5%99ichov_%C5%A0U_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 6 : 1. V modrém pruhu horní polovina bílého palečného kola o pěti palcích, z nich tři horní jsou zakončené horními polovinami žlutých lilií Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se stříbrnou vlnitou patou stříbrná horní polovina palečného kola o pěti palcích, z nich tři horní jsou zakončené horními polovinami zlatých lilií. V patě modrý tlapatý kříž."
    },
    {
        obec: "Jindřichovice",
        kod: 560413,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.28276,
        longitude: 12.602422,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Jind%C5%99ichovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 3 : 1. V žerďové polovině zeleného pruhu hnědý jelen běžící k vlajícímu okraji se zadníma nohama na horním okraji žlutého pruhu, provázený nad hřbetem kosmou motykou - nosatcem šikmo přeloženou vztyčenými rýžovacími hráběmi, oboje bílé na hnědých násadách. Pod jelenem ve žlutém pruhu bílá hornická kladívka na hnědých topůrkách. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jindřichovice",
        kod: 587303,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.137705,
        longitude: 15.638697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Coat_of_arms_of_Jind%C5%99ichovice_JI.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 3 : 1. V žerďové polovině zeleného pruhu hnědý jelen běžící k vlajícímu okraji se zadníma nohama na horním okraji žlutého pruhu, provázený nad hřbetem kosmou motykou - nosatcem šikmo přeloženou vztyčenými rýžovacími hráběmi, oboje bílé na hnědých násadách. Pod jelenem ve žlutém pruhu bílá hornická kladívka na hnědých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně obrácenou zvýšenou zlatou krokví dělený štít. Nahoře zlatá růže s červeným semeníkem a zelenými kališními lístky. Dole stříbrné vlnité břevno dotýkající se hrotu krokve."
    },
    {
        obec: "Jindřichovice pod Smrkem",
        kod: 564133,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46365,
        latitude: 50.960916,
        longitude: 15.249643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Znak_obce_Jind%C5%99ichovice_pod_Smrkem.svg",
        coatOfArmsFlagDescription: "List tvoří zelené karé nad žlutým čtvercovým polem a dva vodorovné pruhy, žlutý a zelený. V karé žlutá smrková větvička, ve žlutém poli červený, do kruhu stočený jelení paroh (korál). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě se zelenou hlavou oddělenou smrkovým řezem o dvou dolů obrácených větévkách do kruhu stočený červený jelení paroh (korál)."
    },
    {
        obec: "Jindřichův Hradec",
        kod: 545881,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.144582,
        longitude: 15.006239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Jind%C5%99ich%C5%AFv_Hradec%2C_znak.png",
        coatOfArmsFlagDescription: "Modrý list s dolů obrácenou žlutou růží s červeným semeníkem ve třetině délky listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jinín",
        kod: 551163,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.227879,
        longitude: 13.982656
    },
    {
        obec: "Jinočany",
        kod: 539350,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25225,
        latitude: 50.032857,
        longitude: 14.268789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Jinocany_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým trojúhelníkovým polem v horním rohu s odvěsnami rovnými dvěma třetinám šířky listu a se třemi šikmými bílými pruhy, vycházejícími z první, třetí a páté šestiny vlajícího okraje a rovnoběžnými s přeponou trojúhelníkového pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro - stříbrně polcený štít. Vpravo zlatá levá polovina vrat se stříbrným kováním, vlevo kosmo položený mlátek šikmo přeložený želízkem, obojí černé. V patě tři vlnitá břevna opačných barev."
    },
    {
        obec: "Jinolice",
        kod: 549151,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.478592,
        longitude: 15.32977
    },
    {
        obec: "Jinošov",
        kod: 590797,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.231477,
        longitude: 16.193554,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Jino%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto-červeně čtvrcený list. Uprostřed zelené čtvercové pole široké dvě třetiny šířky listu se dvěma zkříženými klíči, kosmý žlutý, šikmý bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře zeleno-zlatě polcený, vpravo zkřížené vztyčené klíče, kosmý zlatý, šikmý stříbrný, vlevo černý korunovaný dvouocasý lev s červenou zbrojí, v dolním červeném poli černá beraní hlava se zlatou zbrojí."
    },
    {
        obec: "Jiratice",
        kod: 550591,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.983174,
        longitude: 15.612866,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Jiratice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo - modře šikmo dělený list; v bílém poli šikmo nad sebou kosa bez kosiště a radlice, obojí černé, ostřím k žerďovému okraji a hroty nahoru. V modrém poli kosmo zlatá putna s bílou vytékající vodou. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné šikmé břevno, v něm kosiště a radlice, obojí černé a vztyčené, nahoře zlaté zkřížené ostrve, dole kosmo zlatá putna se stříbrnou vytékající vodou."
    },
    {
        obec: "Jirkov",
        kod: 563099,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43111,
        latitude: 50.500627,
        longitude: 13.448885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Jirkov_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří bílé karé nad stejně velkým modrým čtvercovým polem a dva vodorovné pruhy, modrý a bílý. V karé kosmý žlutý pruh se třemi kosmo položenými červenými srdci, vycházející z první čtvrtiny žerďového a horního okraje karé do poslední čtvrtiny dolního a vlajícího okraje karé. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Jirny",
        kod: 538272,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25090,
        latitude: 50.115879,
        longitude: 14.699337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Jirny_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým a vlajícím klínem s vrcholy ve středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná rostoucí saň."
    },
    {
        obec: "Jiřice",
        kod: 548081,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.55332,
        longitude: 15.31844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Jirice_PE_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý v poměru 1:3:1. V modrém poli bílý vykračující kohout nad dolů obráceným trojlistem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným, dolů obráceným, lipovým trojlistem stříbrný vykračující kohout s červenou zbrojí, provázený zlatými odvrácenými vztyčenými klíči."
    },
    {
        obec: "Jiřetín pod Bukovou",
        kod: 546585,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46843,
        latitude: 50.755788,
        longitude: 15.26303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Ji%C5%99et%C3%ADn_pod_Bukovou_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a zelený. Z první čtvrtiny horního okraje listu vychází žlutě lemovaná červená krokev s vrcholem ve dvou třetinách délky listu. Šířka krokve je jednou čtvrtinou a šířka lemu je jednou sedminou šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelené návrší s červenou, zlatě lemovanou krokví nad zlatou hvězdou. Z návrší vyrůstá buk přirozené barvy."
    },
    {
        obec: "Jiřetín pod Jedlovou",
        kod: 562572,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40756,
        latitude: 50.874552,
        longitude: 14.57519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CZE_Ji%C5%99et%C3%ADn_pod_Jedlovou_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý s bílou lilií v žerďové polovině listu a bílý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jiřice",
        kod: 599581,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28922,
        latitude: 50.252532,
        longitude: 14.836959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Ji%C5%99ice_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed bílý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě zvon, převýšený vpravo zkříženými vztyčenými meči a vlevo zlomeným nožovým kolem, vše stříbrné."
    },
    {
        obec: "Jiřice u Miroslavi",
        kod: 594229,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.920914,
        longitude: 16.39205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Jirice_u_miroslavi_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy červený a modrý. Uprostřed bílé žezlo s kruhem zakončeným držadlem z něhož k žerdi vyrůstá půl bílé lilie provázené v modrém pruhu půlí žluté osmicípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrné žezlo s kruhem zakončeným držadlem, z něhož doprava vyrůstá půl stříbrné lilie provázené vlevo půlí zlaté osmihroté hvězdy."
    },
    {
        obec: "Jiřice u Moravských Budějovic",
        kod: 550841,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 48.993974,
        longitude: 15.925489,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Ji%C5%99ice_u_Moravsk%C3%BDch_Bud%C4%9Bjovic_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a červený, v poměru 2:1:1. Do zeleného pruhu vyrůstá bílo-červeně šachovaný drak se žlutou zbrojí, držící na žluté žerdi bílý praporec s červeným středovým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě děleném štítě nahoře vyrůstající stříbrno-červeně šachovaný drak se zlatou zbrojí držící na zlaté žerdi stříbrný praporec s červeným heroldským křížem, dole krokev provázená třemi srdci, vše červené."
    },
    {
        obec: "Jiříkov",
        kod: 597457,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79351,
        latitude: 49.854028,
        longitude: 17.277651,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Ji%C5%99%C3%ADkov_%28okres_Brunt%C3%A1l%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý s červeným středovým křížem s rameny širokými osminu šířky listu, červeným a bílým se třemi šikmými černými pruhy vycházejícími ze sudých šestin žerďového okraje pruhu do lichých šestin vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Přes červeno - stříbrně polcený štít, vlevo se třemi šikmými černými břevny, sv.Jiří s modrým vlajícím pláštěm, sedící na zlatém koni s černým postrojem a modrou sedlovou pokrývkou, držící stříbrný štít s červeným heroldským křížem a probodávající stříbrným kopím zakončeným křížem zeleného draka pod koněm."
    },
    {
        obec: "Jiříkov",
        kod: 562581,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40753,
        latitude: 50.993471,
        longitude: 14.568449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Znak_-_Ji%C5%99%C3%ADkov_%28okr._D%C4%9B%C4%8D%C3%ADn%29.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, bílý, červený a žlutý, v poměru 1:2:2:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jiříkovice",
        kod: 583189,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66451,
        latitude: 49.166816,
        longitude: 16.758096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Znak_obce_Ji%C5%99%C3%ADkovice.gif",
        coatOfArmsFlagDescription: "Zelený list se šikmým bílým pruhem, vycházejícím z první čtvrtiny dolního okraje do čtvrté čtvrtiny horního okraje listu, podloženým uprostřed svislým žlutým pruhem širokým jednu dvacetinu délky listu. V žerďové části žlutý latinský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně děleném štítě nahoře zlatý latinský kříž a stříbrné obrněné rámě se zlatým kopím směřujícím dolů přes dělící linii k zelenému draku s červenou zbrojí."
    },
    {
        obec: "Jistebnice",
        kod: 552534,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39133,
        latitude: 49.485634,
        longitude: 14.527704
    },
    {
        obec: "Jistebník",
        kod: 599506,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74282,
        latitude: 49.754094,
        longitude: 18.130739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Jistebn%C3%ADk_CoA.jpg",
        coatOfArmsFlagDescription: "Kosmo dělený list, v žerďovém modrém poli bílá ryba, ve vlajícím bílém poli modré zkřížené klíče. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře vlnitě dělený štít, nahoře zkřížené klíče, dole ryba, obojí obrácených barev."
    },
    {
        obec: "Jivina",
        kod: 531308,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.794246,
        longitude: 13.836497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Jivina_%28Beroun_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a obloukový zelený vycházející z páté pětiny šířky listu, v poměru 7 : 1. Ve žlutém pruhu zkřížené bíle kvetoucí hnědé jívové větvičky, nahoře mezi nimi zelený hřeb. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným návrším zkřížené kvetoucí jívové větvičky přirozené barvy, nahoře mezi nimi zelený hřeb."
    },
    {
        obec: "Jitkov",
        kod: 546216,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.662324,
        longitude: 15.7274,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Jitkov_znak_%282%29.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů střídavě zelených a bílých, v horním žerďovém rohu žluté karé o šířce čtyři sedminy šířky listu se dvěma černými zkříženými ostrvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černé zkřížené ostrve provázené zelenými lipovými listy (2,2,1)."
    },
    {
        obec: "Jivina",
        kod: 535974,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29414,
        latitude: 50.554218,
        longitude: 14.947263
    },
    {
        obec: "Jivno",
        kod: 535761,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 48.99562,
        longitude: 14.569321,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Jivno_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, oboustranně vlnkovitý bílý a zelený, v poměru 2 : 1 : 7. V horním pruhu pět (3, 2) volných rohy se dotýkajících žlutých cihel. Z dolního okraje listu vyniká žlutá berla závitem k žerďovému okraji listu mezi dvěma dole zkříženými prohnutými bílými jívovými větvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě děleném zvýšeným stříbrným vlnkovitým břevnem nahoře pět (3, 2) zlatých volných rohy se dotýkajících se cihel, dole vyniká zlatá berla mezi dvěma dole zkříženými prohnutými stříbrnými jívovými větvemi."
    },
    {
        obec: "Jívová",
        kod: 503142,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78316,
        latitude: 49.70927,
        longitude: 17.394675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Jivova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Na dolním okraji červeného pruhu zelený vlnkovaný pruh široký jednu čtvrtinu šířky listu se třemi vrcholy, z prostředního vyrůstá zelený dub s hnědým kmenem. V modrém pruhu šesticípá hvězda a půlměsíc cípy k žerďovému okraji, oboje žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Jívoví",
        kod: 595802,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.40547,
        longitude: 16.090844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/J%C3%ADvov%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se vztyčenou žlutou větví jívy s bílými květy, provázenou u dolního okraje listu dvěma bílými šestilistými květy se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad třemi vyrůstajícími stříbrnými kameny vztyčená zlatá větev jívy se stříbrnými květy, provázená dvěma stříbrnými šestilistými květy se zlatými středy."
    },
    {
        obec: "Jizbice",
        kod: 537268,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.257782,
        longitude: 14.993959
    },
    {
        obec: "Josefov",
        kod: 586234,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69621,
        latitude: 48.839579,
        longitude: 17.010146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Josefov_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zelený. V červeném žlutá koruna, v bílém červený vinný hrozen na zeleném stonku s jedním listem a v zeleném bílá radlice hrotem dolů a ostřím k vlajícímu okraji. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě pod červenou hlavou se zlatou korunou vpravo červený vinný hrozen na zeleném stonku s jedním listem, vlevo doleva obrácená stříbrná radlice."
    },
    {
        obec: "Jizerní Vtelno",
        kod: 566039,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29431,
        latitude: 50.369496,
        longitude: 14.853294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Jizern%C3%AD_Vtelno_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě žluté a modré, v poměru 1 : 2 : 2 : 1. Uprostřed listu v modrém pruhu půl žluté vrby s vodorovně zabodnutým bílým mečem se žlutým jílcem, v žlutém pruhu černé křídlo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít. Vpravo půl zlaté vrby, do ní zprava zabodnutý stříbrný meč se zlatým jílcem. V levém poli černé křídlo."
    },
    {
        obec: "Josefov",
        kod: 511587,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35709,
        latitude: 50.211627,
        longitude: 12.579546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Josefov_SO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, černý a červený, v poměru 3:1:1. V žerďové polovině žlutého pruhu dvě zkřížené černé uťaté medvědí tlapy s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře zlato-červeně polcený, vpravo dvě zkřížené černé uťaté medvědí tlapy s červenou zbrojí, vlevo polovina stříbrného zubra se zlatou houžví, v dolním černém poli čtyři dole spojené, odkloněné zlaté obilné klasy, krajní jedním listem."
    },
    {
        obec: "Josefův Důl",
        kod: 563633,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46844,
        latitude: 50.782016,
        longitude: 15.231574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Josefuv_Dul_%28Jablonec_nad_Nisou%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým žerďovým klínem lemovaným dvěma krokvemi, bílou a červenou, vycházejícími ze žerďového okraje listu, kde jsou v poměru 1:2:18:2:1. Vrchol bílé krokve je na vlajícím okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný klín, v něm vyrůstající hnědá poutnická hůl, kvetoucí z každé strany jednou červenou růží se zlatým semeníkem na zeleném stonku se dvěma listy."
    },
    {
        obec: "Josefův Důl",
        kod: 529613,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29307,
        latitude: 50.453507,
        longitude: 14.893781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Josefuv_Dul_MB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole žluté s vyrůstajícím korunovaným červeným lvem s modrou zbrojí a jazykem držícím modrou šesticípou hvězdu. Dolní žerďové pole modré se žlutým kosmým pruhem vycházejícím ze třetí čtvrtiny žerďového okraje listu do druhé poloviny vlajícího okraje pole, v pruhu tři modré šesticípé hvězdy. Horní vlající pole modré, dolní vlající červené. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře vyrůstající červený korunovaný lev s modrou zbrojí a jazykem držící modrou hvězdu. Dole kosmé zlaté břevno se třemi modrými hvězdami."
    },
    {
        obec: "Kacákova Lhota",
        kod: 549312,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.409627,
        longitude: 15.419326
    },
    {
        obec: "Kacanovy",
        kod: 577201,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.550769,
        longitude: 15.14538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Kacanovy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list s žerďovým bílým klínem širokým polovinu šířky listu s vrcholem na vlajícím okraji listu. V něm stejně široký žerďový černý klín s vrcholem ve středu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný hrot s černým zkráceným hrotem, provázený vpravo vztyčeným dlátem, vlevo kamenickou palicí, obojí stříbrné."
    },
    {
        obec: "Kaceřov",
        kod: 560421,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35751,
        latitude: 50.14885,
        longitude: 12.504505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Kace%C5%99ov_%28Sokolov_District%29_CoA.jpeg",
        coatOfArmsFlagDescription: "List rozdělený bílým šikmým pruhem širokým jednu třetinu šířky listu na modrou žerďovou a zelenou vlající část. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stojí vysoká kvádrovaná zeď s prázdnou branou a zlatými otevřenými vraty, nad ní věž s červenou valbovou střechou se zlatými doprava otočenými trojúhelníkovými praporky. Na věži je zavěšen červeno-stříbrno-černě dělený štítek. Ke zdi se přimykají dvě věže, každá s prolomenou černou branou a třemi (2,1) okny zakončená cimbuřím se čtyřmi stínkami, vše stříbrné."
    },
    {
        obec: "Kaceřov",
        kod: 558991,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.870714,
        longitude: 13.512774,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Kace%C5%99ov_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část se třemi modrými šikmými pruhy širokými jednu šestinu šířky listu a červená vlající část se žlutou vykořeněnou lípou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-červeně polcený štít, vpravo tři šikmá modrá břevna, vlevo zlatá vykořeněná lípa."
    },
    {
        obec: "Kácov",
        kod: 534129,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28509,
        latitude: 49.777968,
        longitude: 15.027994,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/K%C3%A1cov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině listu složená křídla k vlajícímu okraji, červené s polovinou žlutého perizonia na bílém, pod nimi bílá hlava sv. Jana Křtitele ve žluté míse bradou k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácená složená křídla, červené s polovinou zlatého perizonia na stříbrném. Pod nimi stříbrná hlava sv. Jana Křtitele ve zlaté míse."
    },
    {
        obec: "Kačice",
        kod: 532444,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27304,
        latitude: 50.162716,
        longitude: 13.98826,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Kacice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Ve středu modrého listu bílá plovoucí kačice se žlutým zobákem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horní červené polovině tři zlatá žezla zakončená zlatými růžemi s červeným semeníkem. V dolní modré polovině plovoucí stříbrná kačice se zlatým zobákem."
    },
    {
        obec: "Kačlehy",
        kod: 562491,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.108963,
        longitude: 15.077166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Ka%C4%8Dlehy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. V červeném pruhu na dvou stupních bílá polygonální věž s cimbuřím, se třemi viditelnými stěnami, v každé černé gotické okno, s modrou stanovou polygonální střechou s navlečenou korunou a s makovicí, obojí žluté. V modrém pruhu pět (2, 1, 2) šesticípých hvězd nad kaprem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře polcený štít. V pravém poli na dvou stupních stříbrná polygonální věž s cimbuřím, se třemi viditelnými stěnami, v každé černé gotické okno, s modrou stanovou polygonální střechou s navlečenou korunou a s makovicí, obojí zlaté. V levém poli pět (2, 1, 2) hvězd nad kaprem, vše zlaté."
    },
    {
        obec: "Kadaň",
        kod: 563102,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43201,
        latitude: 50.376092,
        longitude: 13.271422,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Coat_of_arms_of_Kada%C5%88_II.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy modrý a bílý. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Kadlín",
        kod: 531774,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27735,
        latitude: 50.399568,
        longitude: 14.699845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Kadl%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrým kosočtvercem s vrcholy na okrajích listu. V něm obrácená žlutá svatojakubská mušle s modrým korunovaným dvouocasým lvem s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým návrším s položeným černým tkalcovským člunkem obrácená zlatá svatojakubská mušle, v ní modrý korunovaný dvouocasý lev s červenou zbrojí."
    },
    {
        obec: "Kadov",
        kod: 551180,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38733,
        latitude: 49.402831,
        longitude: 13.774881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Kadov_ST_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, černý, bílý, zelený s bílým vozovým kolem, bílý a černý, v poměru 1 : 1 : 8 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zvýšeném zeleném návrší se stříbrným vozovým kolem černý položený kámen - viklan provázený čtyřmi (2, 2) vztyčenými přivrácenými zelenými dubovými listy."
    },
    {
        obec: "Kadolec",
        kod: 595811,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.374803,
        longitude: 16.141838,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 11 : 2. Z horního okraje zeleného pruhu vyniká do páté dvanáctiny šířky listu bílý kopec, v něm k dolnímu okraji obrácené černé křídlo se žlutým jetelovitě zakončeným perisoniem. Nad kopcem bílá šesticípá hvězda mezi dvěma žlutými liliovými kříži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrný kopec s dolů obráceným černým křídlem se zlatým jetelovitě zakončeným perisoniem, převýšený stříbrnou hvězdou mezi dvěma zlatými liliovými kříži."
    },
    {
        obec: "Jívka",
        kod: 579378,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54213,
        latitude: 50.535096,
        longitude: 16.109111,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/J%C3%ADvka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý. V bílém pruhu šest modrých květů lnu se žlutými středy. Průměr květů se rovná čtvrtině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý třmen, přes něj obrácená stříbrná vidlice posázená šesti modrými květy lnu se zlatými středy."
    },
    {
        obec: "Kadov",
        kod: 594237,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 48.982858,
        longitude: 16.287457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Kadov_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým křížem s rameny širokými jednu osminu šířky listu. Uprostřed žlutá rozevřená kniha, v ní na žerďové stránce černá zubří hlava se žlutou houžví a červeným jazykem, na vlající stránce červený vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný volný kůl, nahoře se dvěma zkrácenými přibitými břevny, horním delším, dole přeložený zlatou rozevřenou knihou, na jejích stránkách vpravo černá zubří hlava se zlatou houžví a červeným jazykem, vlevo červený vinný hrozen."
    },
    {
        obec: "Kadov",
        kod: 595829,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.632666,
        longitude: 16.079322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Kadov.png",
        coatOfArmsFlagDescription: "Zelený list s bílým beránkem Božím se žlutou zbrojí a svatozáří přidržujícím pravou přední nohou opřenou o žluté mlýnské kolo žlutou žerď zakončenou křížkem s bílou dvoucípou korouhví s červeným křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný beránek Boží se zlatou zbrojí a svatozáří, přidržující pravou přední nohou, opřenou o zlaté mlýnské kolo, zlatou žerď zakončenou křížkem se stříbrnou korouhví s červeným heroldským křížem."
    },
    {
        obec: "Kájov",
        kod: 545554,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38221,
        latitude: 48.810921,
        longitude: 14.258692,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/K%C3%A1jov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, a bílý vlající klín s vrcholem ve středu listu. V modrém pruhu žlutá, červeně vyložená liliová koruna se dvěma perlami, zdobená červenými a modrými kameny. V červeném pruhu kosmo stříbrná sekera - širočina se žlutým topůrkem. V klínu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli zlatá, červeně vyložená liliová koruna se dvěma perlami, zdobená červenými a modrými kameny, dolní pole červeno-stříbrně polceno, vpravo kosmá stříbrná sekera - širočina se zlatým topůrkem, vlevo červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Kakejcov",
        kod: 579017,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.667036,
        longitude: 13.624286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Kakejcov_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový zubatý pruh, široký čtvrtinu délky listu a zelené pole. Pruh má čtyři čtvercové zuby a tři stejné mezery. V zeleném poli bílá kaple s dveřmi a čtvercovým okénkem nad nimi, obojí černé, s červenou seříznutou stanovou střechou zakončenou bílou lucernou se zavěšeným žlutým zvonem a červenou stanovou střechou se žlutým křížkem. Střecha kaple je podložena zkříženými bílými hornickými motykami na žlutých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou se čtyřmi stínkami stříbrná kaple s dveřmi a čtvercovým okénkem nad nimi, obojí černé, s červenou seříznutou stanovou střechou zakončenou stříbrnou lucernou se zavěšeným zlatým zvonem a červenou stanovou střechou se zlatým křížkem. Střecha kaple je podložena zkříženými stříbrnými hornickými motykami na zlatých násadách."
    },
    {
        obec: "Kalek",
        kod: 563111,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43132,
        latitude: 50.577837,
        longitude: 13.322071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Kalek%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 4 : 1. Z druhé až jedenácté dvanáctiny horního okraje zeleného pruhu vyniká zelené trojvrší se třemi žlutými zkříženými kovanými hřeby s hroty u dolního okraje listu. Z prostředního vyššího vrchu vyrůstá klikva bahenní. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zeleného trojvrší se třemi zlatými zkříženými kovanými hřeby klikva bahenní přirozené barvy."
    },
    {
        obec: "Kalenice",
        kod: 536946,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.271655,
        longitude: 13.7168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Kalenice_CoA.png",
        coatOfArmsFlagDescription: "List praporu tvoří pět svislých pruhů (červený - bílý - červený - bílý - červený) v poměru 2:1:2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným lemem dva stříbrné bůvolí rohy."
    },
    {
        obec: "Kalhov",
        kod: 587320,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.481127,
        longitude: 15.44153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Kalhov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, červený, zelený a žlutý, v poměru 1:2:2:1. Uprostřed bílý zvon nad vztyčeným žlutým gotickým dvojklíčem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrný zvon provázený dvěma svěšenými zlatými lipovými listy, dole vztyčený zlatý gotický dvojklíč."
    },
    {
        obec: "Kaliště",
        kod: 587338,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.241354,
        longitude: 15.301432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Coat_of_arms_of_Kali%C5%A1t%C4%9B_JI.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V bílém pruhu dva vztyčené zelené lipové listy. V modrém pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře sníženě vlnitě děleném štítě nahoře dva vztyčené zelené lipové listy, dole zlaté mlýnské kolo."
    },
    {
        obec: "Kaliště",
        kod: 548090,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39451,
        latitude: 49.5927,
        longitude: 15.304162,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Kaliste_PE_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, modrý, bílý a modrý, v poměru 6:1:1:1:1. V žerďové části zeleného pruhu bílá lyra s pěti žlutými strunami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou vlnitou patou se dvěma stříbrnými kůly stříbrná lyra s pěti zlatými strunami provázená dvěma vyrůstajícími, přivrácenými zlatými obilnými klasy."
    },
    {
        obec: "Kaliště",
        kod: 538281,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25165,
        latitude: 49.882761,
        longitude: 14.774613,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/CoA_of_Kali%C5%A1t%C4%9B_%28okres_Praha-v%C3%BDchod%29.svg",
        coatOfArmsFlagDescription: "List tvoří svislý červený pruh široký polovinu délky listu a sedm vodorovných pruhů střídavě modrých a žlutých. V červeném pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrná zavinutá střela, vlevo tři zlatá vlnitá břevna."
    },
    {
        obec: "Kalivody",
        kod: 565440,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.208527,
        longitude: 13.841968
    },
    {
        obec: "Kaly",
        kod: 595837,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.379422,
        longitude: 16.351706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/CoA_of_Kaly.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu půl žluté lilie přiléhající k půli modrého tlapatého kříže ve žlutém pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě půl lilie s přiléhající půlí tlapatého kříže opačných barev."
    },
    {
        obec: "Kamberk",
        kod: 531031,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25706,
        latitude: 49.597236,
        longitude: 14.840416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Kamberk_CoA.png"
    },
    {
        obec: "Kámen",
        kod: 568821,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58242,
        latitude: 49.711957,
        longitude: 15.519989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/K%C3%A1men_HB_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi svázanými odkloněnými žlutými obilnými klasy v žerďové a střední části. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou patou s červeným heroldským křížem tři svázané odkloněné zlaté obilné klasy provázené vpravo podkovou, vlevo odvrácenou sekerou, obojí stříbrné."
    },
    {
        obec: "Kámen",
        kod: 548103,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39413,
        latitude: 49.423391,
        longitude: 15.015964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/K%C3%A1men_PE_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bílo-žluto-modře čtvrcený list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělen zúženým zlatým břevnem. V horním, červeno-modře polceném poli vpravo zkřížené stříbrné meče se zlatými jílci, vlevo půl zlatého koně s červeným uzděním. V dolním modrém poli obdélná věž uprostřed kvádrované hradby, obojí stříbrné. Věž má nahoře tři prázdná okna vedle sebe a červenou valbovou střechu se dvěma zlatými makovicemi."
    },
    {
        obec: "Kámen",
        kod: 546453,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40713,
        latitude: 50.810319,
        longitude: 14.268547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/K%C3%A1men_%28D%C4%9B%C4%8D%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu pohanská koruna nad kamenem, obojí žluté. Ve žlutém pruhu vztyčená červená borová větev se čtyřmi svazky zelených jehlic a dole se svěšenou červenou šiškou k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít. Vpravo pohanská koruna nad kamenem, obojí zlaté. Vlevo vztyčená borová větev se čtyřmi svazky jehlic a vlevo dole se svěšenou šiškou, vše přirozené barvy."
    },
    {
        obec: "Kamenec",
        kod: 566811,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.881166,
        longitude: 13.596339
    },
    {
        obec: "Kamenec u Poličky",
        kod: 578207,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.707979,
        longitude: 16.233812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Kamenec_u_poli%C4%8Dky_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý a vodorovný zelený pruh na dolním okraji listu široký čtvrtinu šířky listu. Na rozhraní bílého a modrého pruhu květ lnu opačných barev se zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě nad zeleným trojvrším květ lnu opačných barev se zelenými kališními lístky."
    },
    {
        obec: "Kamenice",
        kod: 587346,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58823,
        latitude: 49.366688,
        longitude: 15.780333,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Kamenice_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý, v poměru 1 : 5. V bílém pruhu tři dolů obrácené černé mnišské kápě nad sebou, v modrém pruhu vyrůstá z dolního okraje listu závitem k žerdi žlutá hlavice berly s bílými sudarii se žlutým třepením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi dolů obrácenými černými mnišskými kápěmi vedle sebe vyrůstající zlatá berla se dvěma vlajícími stříbrnými sudarii se zlatým třepením."
    },
    {
        obec: "Kamenice",
        kod: 538299,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25168,
        latitude: 49.901617,
        longitude: 14.582527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Kameniceznak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s kosmým zeleným pruhem širokým jednu čtvrtinu šířky listu se třemi žlutými mezikružími. Ve žlutých polích po jednom kosmém černém lipovém listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelené kosmé břevno se třemi zlatými kruhy provázené dvěma kosmými černými lipovými listy."
    },
    {
        obec: "Kamenice nad Lipou",
        kod: 548111,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.303136,
        longitude: 15.075292,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Kamenice_nad_Lipou_znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílým svislým žerďovým pruhem se čtyřmi čtvercovými zuby. Plocha bílého pole je rovna třetině plochy listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná čtyřikrát zalomená hradební zeď s prolomenou branou a zlatou zdviženou mříží. Nad hradbou dvě stříbrné kvádrované věže s černými okny zakončené cimbuřím a červenými valbovými střechami se zlatými makovicemi. Věže jsou vně provázeny dvěma zlatými štítky s modrou růží se zlatým semeníkem."
    },
    {
        obec: "Kamenický Šenov",
        kod: 561681,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47114,
        latitude: 50.773693,
        longitude: 14.472979,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Kamenick%C3%BD_%C5%A0enov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, červený, bílý, červený a zelený, v poměru 1:1:4:1:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kameničky",
        kod: 571571,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53941,
        latitude: 49.736024,
        longitude: 15.975128,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/CoA_of_Kameni%C4%8Dky.svg",
        coatOfArmsFlagDescription: "List tvoří červený vodorovný pruh široký polovinu šířky listu, tři zelené vrchy vycházející ze třetí čtvrtiny žerďového a vlajícího okraje a modrý vodorovný pruh široký jednu čtvrtinu šířky listu. Nad každým vrchem bílý štítek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít s modrým vydutým hrotem, vpravo tři (1,2) stříbrné štítky, vlevo tři zelené zvýšené vrchy."
    },
    {
        obec: "Kameničná",
        kod: 580422,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.120278,
        longitude: 16.435011,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Kameni%C4%8Dn%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. Uprostřed lípa opačných barev vyrůstající ze žluto-černě polceného volného soklu o třech stupních, v něm růže opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě lípa opačných barev vyrůstající ze zlato-černě polceného soklu se třemi stupni, v něm růže opačných barev."
    },
    {
        obec: "Kamenná",
        kod: 535982,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.779882,
        longitude: 14.671182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Kamenna_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu na bílých miskových vahách vykračující bílý kohout se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na stříbrných miskových vahách vykračující stříbrný kohout se zlatou zbrojí. Před kohoutem dvě pod sebou a mezi miskami jedna růže, všechny stříbrné se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Kamenná",
        kod: 587362,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.51005,
        longitude: 15.652675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Kamenna_JI_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 5 : 7 : 4. V žerďové části bílého pruhu kosmý mlátek, šikmo podložený želízkem, obojí modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné kamenné trojvrší vyrůstající z modré vlnité paty, v něm kosmý mlátek šikmo podložený želízkem, obojí modré."
    },
    {
        obec: "Kamenná",
        kod: 590801,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.272529,
        longitude: 16.060488
    },
    {
        obec: "Kamenná",
        kod: 536571,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78974,
        latitude: 49.859436,
        longitude: 17.031142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Kamenna_SU_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. Z bílého pruhu vyrůstá polovina žlutého slunce bez obličeje se střídavě plamennými a trojúhelníkovými paprsky. Pod sluncem v bílém pruhu zelené kruhové síto mřežovaně propletené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vychází se stříbrného trojvrší se zeleným kruhovým mřežovaným sítem zlaté slunce."
    },
    {
        obec: "Kamenná Horka",
        kod: 578215,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.738007,
        longitude: 16.52403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Kamenn%C3%A1_Horka_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým kopřivovým listem a zeleným šikmým pruhem širokým jednu třetinu šířky listu. V zeleném pruhu bílý šíp červeným hrotem k hornímu cípu a s červeným opeřením, přeložený uprostřed žlutým lipovým trojlistem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na zlatém návrší stojí rytíř ve stříbrné zbroji se třemi pštrosími pery, červeným mezi stříbrnými na přilbě, držící v pravici dolů obrácený stříbrný šíp s červeným hrotem a opeřením a levicí se opírající o červený štít se stříbrným kopřivovým listem. V obou horních rozích zlatý lipový trojlist na stonku."
    },
    {
        obec: "Kamenná Lhota",
        kod: 568848,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58292,
        latitude: 49.643905,
        longitude: 15.275344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Kamenn%C3%A1_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, a na dolním okraji listu vodorovný zelený, široký tři desetiny šířky listu. V červeném pruhu dva bílé zkřížené hvězdicové vrtáky, přeložené žlutým mlátkem, v bílém černá vlčí hlava s krkem a červeným jazykem. V zeleném pruhu pět žlutých šesticípých hvězd vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě nad zeleným vrchem s pěti (3, 2) zlatými hvězdami, vpravo dva zkřížené hvězdicové vrtáky přeložené zlatým mlátkem, vlevo černá vlčí hlava s krkem."
    },
    {
        obec: "Kamenné Zboží",
        kod: 537276,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.193015,
        longitude: 14.99953,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Coats_of_arms_Kamenn%C3%A9_Zbo%C5%BE%C3%AD.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Uprostřed listu lamačský rozštěpovací klín nad kosmou šramovačkou šikmo přeloženou sekáčem, vše červeno-žlutě polcené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě lamačský rozštěpovací klín nad kosmou šramovačkou, šikmo přeloženou sekáčem, vše červeno-zlatě polcené."
    },
    {
        obec: "Kamenné Žehrovice",
        kod: 532452,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27301,
        latitude: 50.127079,
        longitude: 14.0182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Kamenne_Zehrovice_znak.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy. Horní bílý (2/5 šířky), střední červený (2/5 šířky), dolní žlutý (1/5 šířky). Poměr délky k šířce 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstají ze zlatého trojvrší dvě stříbrná lekna, mezi nimi je vztyčen zlatý pšeničný klas."
    },
    {
        obec: "Kamenný Malíkov",
        kod: 598631,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.211998,
        longitude: 15.122369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Coat_of_arms_of_Kamenn%C3%BD_Mal%C3%ADkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 6 : 1. V modrém pruhu bílý tlapatý kříž, po stranách provázený šesti (3, 3) odvrácenými žlutými lipovými listy do půlkruhů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný tlapatý kříž, po stranách provázený šesti (3, 3) odvrácenými zlatými lipovými listy do půlkruhů, nad stříbrným kvádrovaným mostem, v oblouku zlatá růže s červeným semeníkem."
    },
    {
        obec: "Kamenný Most",
        kod: 513032,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27326,
        latitude: 50.241505,
        longitude: 14.205845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Kamenn%C3%BD_Most_%28okres_Kladno%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, v poměru 3 : 1 : 1. V horním červeném pruhu žluté zkřížené klíče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad kvádrovaným mostem se dvěma oblouky zkřížené klíče, vše zlaté."
    },
    {
        obec: "Kamenný Újezd",
        kod: 544663,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37381,
        latitude: 48.897635,
        longitude: 14.446481,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Znak_kamenak.JPG",
        coatOfArmsFlagDescription: "List tvoří osm vodorovných pruhů, modrý a střídavě žluté a černé v poměru 7:1:1:1:1:1:1:1. V modrém pruhu žlutý šíp s bílým opeřením hrotem k žerdi. Přes žluté a černé pruhy ve střední části bílé čtvercové pole široké jednu třetinu délky listu, v něm červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli vztyčený zlatý šíp se stříbrným opeřením, levá polovina stříbrno-zlatě zvýšeně dělena. Nahoře červená růže se zlatým semeníkem, dole tři šikmá černá břevna."
    },
    {
        obec: "Kamenný Přívoz",
        kod: 539368,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25282,
        latitude: 49.863096,
        longitude: 14.503494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Kamenny_p_znak.svg",
        coatOfArmsFlagDescription: "Prapor tvoří modré pole, na kterém je položen bílý šátek vázaný dvěma cípy směřujícími k dolnímu rohu a cípu praporu.",
        coatOfArmsDescription: "Štít vlnitě dělený, nahoře modrý, dole se šesti vlnitými pruhy, střídavě modrými a stříbrnými, na nichž mezi dvěma stříbrnými skalami pluje zlatá loďka s kosmo přeloženým zlatým bidlem. Nad loďkou je stříbrný vázaný šátek sv. Ludmily."
    },
    {
        obec: "Kamenný Újezd",
        kod: 541150,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.723772,
        longitude: 13.618864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Kamenny_Ujezd_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a černý v poměru 1:2:1. V červeném pruhu bílé beraní rohy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě děleném štítě nahoře stříbrné beraní rohy, dole zlaté břevno."
    },
    {
        obec: "Kamýk",
        kod: 546771,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.558481,
        longitude: 14.07749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Kamyk_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - bílý a černý. V bílém poli červený kanton sahající do poloviny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvrší stříbrná věž se třemi stínkami a prolomeným černým oknem, z něhož vyniká na kosmé zlaté žerdi praporec s bílým ocasem. Jeho list tvoří dva pruhy - bílý a černý, v bílém pruhu červené karé. Od spodu štítu v zeleném trojvrší vyrůstají z jednoho stonku dvě zlatá lekna."
    },
    {
        obec: "Kanice",
        kod: 583197,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66401,
        latitude: 49.263805,
        longitude: 16.714624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Kanice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 3:1. V zeleném pruhu žluté káně letící k hornímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad kosmým mlátkem šikmo přeloženým kopáčem vzhůru letící káně, vše zlaté."
    },
    {
        obec: "Kamýk nad Vltavou",
        kod: 540439,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26263,
        latitude: 49.639124,
        longitude: 14.253549,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Knv_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s vlajícím svislým bílým pruhem širokým jednu třetinu délky listu. V modrém poli bílá kvádrovaná věž s cimbuřím, černým oknem a červenou stanovou střechou se žlutou makovicí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kanice",
        kod: 566144,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.475118,
        longitude: 13.072809
    },
    {
        obec: "Kaničky",
        kod: 553778,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.474432,
        longitude: 13.145358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Kani%C4%8Dky_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se zeleným dolním klínem lemovaným žlutou krokví vycházející z osmé osminy žerďového a vlajícího okraje listu s vrcholem ve středu listu. Nad ní rozkřídlená vzlétající káně provázená pod křídly mlýnskými křížovými kypřicemi, v klínu lilie, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně sníženou zlatou krokví dělený štít. Nahoře rozkřídlená vzlétající káně provázená pod křídly mlýnskými křížovými kypřicemi, dole lilie, vše zlaté."
    },
    {
        obec: "Kaňovice",
        kod: 598275,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73936,
        latitude: 49.744405,
        longitude: 18.39622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ka%C5%88ovice_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 3:1. V modrém pruhu zkřížené bílé sekery se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou patou zkřížené stříbrné sekery se zlatými topůrky."
    },
    {
        obec: "Kaňovice",
        kod: 585327,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76341,
        latitude: 49.109153,
        longitude: 17.698856,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Ka%C5%88ovice_ZL_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, černý se žlutým obilným klasem se dvěma listy, a modrý v poměru 8:3:8. U černého pruhu přivrácená křídla, ve žlutém pruhu modré se žlutou osmicípou hvězdou nahoře, v modrém pruhu žluté s modrou lilií nahoře. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře černým kůlem polcený štít. V kůlu zlatý obilný klas se dvěma listy. V obou polích přivrácená křídla opačných barev, v pravém nahoře zlatá osmihrotá hvězda, v levém modrá lilie."
    },
    {
        obec: "Kaplice",
        kod: 545562,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38241,
        latitude: 48.738589,
        longitude: 14.496373,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Coat_of_arms_of_Kaplice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým zubatým žerďovým pruhem širokým jednu čtvrtinu délky listu s pěti čtvercovými zuby a čtyřmi stejnými mezerami. V modrém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kanina",
        kod: 531651,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27735,
        latitude: 50.424809,
        longitude: 14.600046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Kanina_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, žlutý a zelený, v poměru 3 : 3 : 1 : 1. V bílém pruhu rozkřídlená hnědá káně. Ze žlutého pruhu vyrůstají dva odkloněné lipové listy, mezi nimi tři plody, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně zvýšeně děleném štítě nahoře rozkřídlená káně přirozené barvy, dole zlatá koruna, z níž vyrůstají dva odkloněné lipové listy, mezi nimi tři plody, vše stříbrné."
    },
    {
        obec: "Káranice",
        kod: 570150,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50366,
        latitude: 50.153056,
        longitude: 15.557599
    },
    {
        obec: "Káraný",
        kod: 564974,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25075,
        latitude: 50.174869,
        longitude: 14.7371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Karany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně děleném štítě se stříbrno-červeným lemem nahoře devět (5,4) zlatých obilných klasů, dole zelená dubová větev se třemi listy a pod nimi dvěma žaludy dole."
    },
    {
        obec: "Kardašova Řečice",
        kod: 546542,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.184882,
        longitude: 14.853256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Karda%C5%A1ova_%C5%98e%C4%8Dice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, bílý a modrý v poměru 2:1:2. Přes celou střední část žlutá růže se zelenými lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Karle",
        kod: 572748,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.766089,
        longitude: 16.364833,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Karle_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou kvádrovanou věží s jedním prázdným obloukovým oknem a cimbuřím, provázenou dvěma žlutými lípami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s jedním prázdným obloukovým oknem a cimbuřím, provázená dvěma zlatými lípami."
    },
    {
        obec: "Karlín",
        kod: 586242,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69614,
        latitude: 48.975875,
        longitude: 16.977406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Karlin_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1 : 3 : 1. V zeleném pruhu žlutý medvěd ve skoku s červenou zbrojí, držící v předních tlapách bílé mezikruží s vepsaným latinským křížem. Před a pod pravou zadní tlapou po žlutém vinném hroznu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý medvěd ve skoku s červenou zbrojí, držící v předních tlapách stříbrný kruh s vepsaným latinským křížem, vpravo dole dva zlaté vinné hrozny kosmo nad sebou."
    },
    {
        obec: "Karlov",
        kod: 595845,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.647105,
        longitude: 15.914538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Coat_of_arms_of_Karlov_ZR.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed nahoře ryba, pod ní větévka růže s květem a listy a vinařský nůž. Vše bílé, semeník a tři nýty v rukojeti žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nahoře ryba, pod ní větévka růže s květem a listy a vinařský nůž. Vše stříbrné, semeník a tři nýty v rukojeti zlaté."
    },
    {
        obec: "Karlova Ves",
        kod: 565288,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 49.987386,
        longitude: 13.862509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Znak_obce_Karlova_Ves.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 1 : 4 : 1, bílo-modře oblačně polcený, bílý se zeleným vykořeněným bukem a modro-bíle oblačně polcený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modro-stříbrným oblačným lemem zelený vykořeněný buk."
    },
    {
        obec: "Karlova Studánka",
        kod: 597473,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79324,
        latitude: 50.073193,
        longitude: 17.30696,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Znak_obce_karlova_studanka.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou fontánou s černým tlapatým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná fontána s černým tlapatým křížem."
    },
    {
        obec: "Karlík",
        kod: 599727,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25229,
        latitude: 49.935506,
        longitude: 14.259616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Karl%C3%ADk_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, zubatý bílý, vlnitý modrý a zvlněný bílý, v poměru 7 : 1 : 2 : 6. V horním pruhu bílá koruna. Zubatý pruh má čtyři obdélníkové zuby a pět mezer, krajní širší. V dolním pruhu červený osmihrotý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně cimbuřově děleném štítě nahoře stříbrná koruna, dole modré vlnité zúžené břevno nad červeným osmihrotým křížem."
    },
    {
        obec: "Karlovice",
        kod: 577219,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.561843,
        longitude: 15.20728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Karlovice_%28Semily%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří bílé karé nad stejně velkým bílým polem a dva vodorovné pruhy, žlutý a zelený. V karé nad zeleným čtyřvrším červený středový kříž s rameny širokými jednu šestnáctinu šířky listu a tři žluté klíny, vycházející z dolního okraje karé a s vrcholy dosahujícími do druhé třetiny šířky karé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zlatou, pětkrát pilovitě lomenou, patou ze zeleného čtyřvrší vyrůstající červený heroldský kříž."
    },
    {
        obec: "Karlovice",
        kod: 597481,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79326,
        latitude: 50.105736,
        longitude: 17.445738,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Karlovice_Brunt%C3%A1l_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1 : 3 : 1. V zeleném pruhu kosmo kovářské kleště provázené nahoře kokilou a dole kovadlinou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě kosmo kovářské kleště provázené nahoře kokilou a dole kovadlinou, vše stříbrné."
    },
    {
        obec: "Karlovice",
        kod: 587052,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76302,
        latitude: 49.176512,
        longitude: 17.585926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Karlovice_%28ZL%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1 : 4 : 1. V červeném pruhu dva vztyčení odvrácení bílí lososi provázení mezi hlavami a ocasy tlapatým křížkem a po stranách odvrácenými radlicemi hroty k hornímu okraji listu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě dva vztyčení odvrácení stříbrní lososi, nahoře a dole provázení tlapatým křížkem a po stranách odvrácenými vztyčenými radlicemi, vše zlaté."
    },
    {
        obec: "Karlštejn",
        kod: 531316,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.939604,
        longitude: 14.188146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Karl%C5%A1tejn_-_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým lemem širokým desetinu šířky listu. V červeném poli korunovaný bílý dvouocasý lev se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "KARVINÁ",
        kod: 598917,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73301,
        latitude: 49.856752,
        longitude: 18.543319,
        coatOfArmsFlagDescription: "List praporu opakuje městský znak. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Karlovy Vary",
        kod: 554961,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.231952,
        longitude: 12.872062,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Karlovy_Vary_COA.svg",
        coatOfArmsFlagDescription: "List je tvořen dvěma pruhy, bílým a červeným. Ve středu listu znak města na kartušovém štítě. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Karolín",
        kod: 587354,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.230694,
        longitude: 17.43461,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Karol%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý se žlutou osmicípou hvězdou v žerďové části a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré křídlo se zlatou osmihrotou hvězdou uprostřed."
    },
    {
        obec: "Karolinka",
        kod: 542911,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75605,
        latitude: 49.351376,
        longitude: 18.240174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/CZ_Karolinka_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - modrý, zelený a modrý, v poměru 1:3:1. Ve středu zeleného pole je bílá široká sektová sklenička, široká jednu pětinu délky listu a vysoký čtyři sedminy šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítu stříbrná široká číše nad třikrát stříbrno-modře vlnitě dělenou patou."
    },
    {
        obec: "Kařez",
        kod: 559903,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.824356,
        longitude: 13.781719,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Karez_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený s hnědým pařezem se třemi kořeny stejně vzdáleným od žerďového, horního a dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě pařez přirozené barvy se třemi kořeny."
    },
    {
        obec: "Kařízek",
        kod: 530361,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.813466,
        longitude: 13.799741
    },
    {
        obec: "Kasalice",
        kod: 575151,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.118105,
        longitude: 15.609768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Kasalice_CoA.jpg"
    },
    {
        obec: "Kasejovice",
        kod: 557862,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33544,
        latitude: 49.462805,
        longitude: 13.740706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Kasejovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a bílý v poměru 11:9. Bílý pruh má pět obdélníkových zubů, čtyři mezery a dvě poloviční mezery. Výška zubů a mezer se rovná jedné desetině šířky listu a jejich délka jedné desetině délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kašava",
        kod: 585343,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76319,
        latitude: 49.295509,
        longitude: 17.785771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Ka%C5%A1ava%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: červený, žlutý a modrý v poměru 1:4:1. Uprostřed dvě proti sobě stojící černé odvrácené orlice se žlutou korunou a červeným jazykem držící v pařátech dva zkřížené meče, žerďová modrý a protilehlá červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve zlatém poli dvě proti sobě stojící černé odvrácené korunované orlice s červenými jazyky, držící v pařátech dva zkřížené meče, pravá modrý, levá červený. Dolní pole červeno-modře polceno, v něm dolní polovina zlatého kola sv. Kateřiny."
    },
    {
        obec: "Kašnice",
        kod: 550256,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69172,
        latitude: 48.996536,
        longitude: 16.882945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Coat_of_arms_of_Kasnice.jpeg",
        coatOfArmsDescription: "V zeleném štítě stříbrný lev s červenou zbrojí držící v tlapách zlatý hrozen."
    },
    {
        obec: "Kašperské Hory",
        kod: 556432,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34192,
        latitude: 49.143,
        longitude: 13.556264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Ka%C5%A1persk%C3%A9_Hory_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý zubatý kvádrovaný s pěti zuby a čtyřmi stejnými mezerami a červený, žlutě damaskovaný, v poměru 3 : 2 : 3. Do modrého pruhu vyrůstá pravá paže v červeném rukávu s bílou manžetou držící mlátek. Z paže vyniká kosmo a šikmo želízko. Mlátek a želízka černá na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kateřinice",
        kod: 568643,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74258,
        latitude: 49.669288,
        longitude: 18.188284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Kate%C5%99inice_NJ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým kolem sv. Kateřiny a třemi žerďovými svislými pruhy: bílým, červeným a bílým, každý o šířce jedné dvanáctiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kolo sv. Kateřiny pod mečem zkříženým s palmovou ratolestí, vše stříbrné."
    },
    {
        obec: "Kateřinice",
        kod: 542946,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75621,
        latitude: 49.382193,
        longitude: 17.885865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/CZ_Kate%C5%99inice_COA.svg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině korunovaná postava sv. Kateřiny Alexandrijské v červeném šatě s bílým pláštěm a s bílým mečem hrotem k dolnímu okraji v pravé a zelenou palmovou ratolestí v levé ruce a žlutým zlámaným nožovým kolem u levé nohy. Ve vlající polovině horní části vedle sebe dvě šestilisté červené růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě korunovaná postava Kateřiny Alexandrijské v červeném šatě se stříbrným pláštěm, stříbrným postaveným mečem v pravé a zelenou palmovou ratolestí v levé ruce a zlatým zlámaným nožovým kolem u levé nohy, provázená nahoře dvěma červenými šestilistými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Katov",
        kod: 530042,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.281817,
        longitude: 14.828622
    },
    {
        obec: "Katov",
        kod: 587907,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.332793,
        longitude: 16.278861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Katov_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed žlutý vykořeněný listnatý strom nad bílou lilií. V dolní polovině žerďové a vlající části listu po jedné žluté šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nahoře zlatý vykořeněný listnatý strom, pod ním dvě zlaté hvězdy vedle sebe a dole stříbrná lilie."
    },
    {
        obec: "Katovice",
        kod: 551201,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38711,
        latitude: 49.273591,
        longitude: 13.830458,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Katovice_znak_color.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Z dolního okraje listu vyrůstá do modrého pruhu bílá věž s prázdnou branou, prázdným oknem, zakončená cimbuřím se třemi zuby a červenou stanovou střechou se žlutou makovicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá stříbrná věž s prázdnou branou, prázdným oknem, zakončená cimbuřím se třemi stínkami a červenou stanovou střechou se zlatou makovicí."
    },
    {
        obec: "Katusice",
        kod: 536008,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29425,
        latitude: 50.445203,
        longitude: 14.777464
    },
    {
        obec: "Kaznějov",
        kod: 559008,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.893239,
        longitude: 13.383058,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/CoA_of_Kazn%C4%9Bjov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně dělený štít, nahoře křivule, dole křížem přeložený mlátek a želízko, vše stříbrné."
    },
    {
        obec: "Kbel",
        kod: 557871,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.493815,
        longitude: 13.365703
    },
    {
        obec: "Kbel",
        kod: 513415,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.988323,
        longitude: 15.143645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Znak_obce_Kbel.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý s bílo - červeně polcenou růží, červený a modrý se šikmo vztyčeným žlutým dubovým listem. V červeném pruhu žlutý půlměsíc cípy k hornímu okraji listu shora prostřelený bílým šípem se žlutým hrotem a opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě, vpravo stoupající zlatý půlměsíc shora prostřelený stříbrným šípem se zlatým hrotem a opeřením, vlevo stříbrno - červeně polcená růže, pod ní šikmo vztyčený zlatý dubový list."
    },
    {
        obec: "Kbelany",
        kod: 538183,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.732444,
        longitude: 13.126409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Coat_of_arms_of_Kbelany.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným klínem na dolním okraji a vrcholem na horním okraji listu, v něm bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na černé patě zelený hrot, v něm stříbrná lilie."
    },
    {
        obec: "Kbelnice",
        kod: 549169,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.454929,
        longitude: 15.350713
    },
    {
        obec: "Kdousov",
        kod: 590819,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.986074,
        longitude: 15.647401
    },
    {
        obec: "Kdyně",
        kod: 553786,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.390348,
        longitude: 13.040638
    },
    {
        obec: "Keblice",
        kod: 565016,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.480149,
        longitude: 14.101914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Keblice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílý džber se žlutými obručemi, z něhož vyrůstá liliové žezlo mezi dvěma obilnými klasy, každý s odkloněným listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě stříbrný džber se zlatými obručemi, z něhož vyrůstá liliové žezlo mezi dvěma obilnými klasy, každý s odkloněným listem, vše zlaté."
    },
    {
        obec: "Keblov",
        kod: 529907,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.677749,
        longitude: 15.071992,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Keblov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, modrý, bílý, černý, žlutý a černý, v poměru 1 : 5 : 1 : 1 : 1 : 1. V modrém pruhu mezi dvěma růžemi červeně vyložená liliová koruna s oblouky, vše žluté. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - černě sníženě děleném štítě nahoře rozšířené modré oboustranně oblakové břevno, v něm mezi dvěma růžemi červeně vyložená liliová koruna s oblouky, dole břevno, vše zlaté."
    },
    {
        obec: "Kejnice",
        kod: 541826,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.266995,
        longitude: 13.694293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Kejnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený palisádový pruh, široký pětinu délky listu, a bílé pole s modrou hlavicí berly závitem k žerdi. V závitu červená růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou palisádovou hlavou modrá hlavice berly s červenou růží v závitu."
    },
    {
        obec: "Kejžlice",
        kod: 548120,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39452,
        latitude: 49.590575,
        longitude: 15.392728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Kejzlice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi, zvlněný modrý a žlutý, v poměru 5 : 1 : 1 : 1. Do horního pruhu vyrůstají žluté hrábě mezi odvrácenými bílými kosami na odkloněných žlutých kosištích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno, z něhož vyrůstají zlaté hrábě mezi dvěma odvrácenými stříbrnými kosami na odkloněných zlatých kosištích, dole zlatý snop."
    },
    {
        obec: "Kelč",
        kod: 542989,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75643,
        latitude: 49.478541,
        longitude: 17.815441,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Kel%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy červený, modrý a červený, v poměru 3:1:3. V horním červeném pruhu čtyři rovnoramenné bílé trojúhelníky se základnami na modrém pruhu a s vrcholy na horním okraji listu. Uprostřed dolního červeného pruhu dva stejné trojúhelníky s vrcholem na dolním okraji modrého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě modré břevno, na něm čtyři a pod ním dva stříbrné kužely."
    },
    {
        obec: "Kelčany",
        kod: 586251,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69649,
        latitude: 49.005319,
        longitude: 17.173901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Kel%C4%8Dany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou krokví vycházející z třetí pětiny horního a dolního okraje a s vrcholem na středu vlajícího okraje listu. V žerďové polovině listu bílý hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad vinným hroznem krokev provázená nahoře dvěma liliemi, vše stříbrné."
    },
    {
        obec: "Kelníky",
        kod: 592251,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76307,
        latitude: 49.110985,
        longitude: 17.644771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Keln%C3%ADky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové pruhy, zubatý bílý a červený, a dva vodorovné pruhy, modrý a bílý. Poměr pruhů na horním okraji listu je 3:5:16 a na vlajícím okraji listu 5:3. Bílý pruh má tři čtvercové zuby a čtyři stejné mezery. V modrém pruhu dvě zkřížené bílé šavle ostřím nahoru, pod nimi mezi žlutými jílci s červenými střenkami žlutá včela. Pod včelou v bílém pruhu modrá švestka na zeleném stonku se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře dvě zkřížené stříbrné šavle se zlatými jílci s červenými střenkami nad zlatou včelou, dole vyniká červené cimbuřové břevno, pod ním modrá švestka na zeleném stonku se dvěma listy."
    },
    {
        obec: "Kestřany",
        kod: 549487,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39821,
        latitude: 49.269153,
        longitude: 14.072641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Kestrany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 3:2. V bílém pruhu červené buvolí rohy, každý postrkaný třemi žlutými lipovými lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou vlnitou patou se dvěma modrými vlnitými břevny dvě stříbrné věže na soklu, každá s černým gotickým oknem a červenou valbovou střechou se dvěma zlatými makovicemi, mezi nimi zlaté vozové kolo a pod ním červené buvolí rohy, každý postrkaný třemi zlatými lipovými lístky."
    },
    {
        obec: "Ketkovice",
        kod: 583201,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.159216,
        longitude: 16.26264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Coat_of_arms_of_Ketkovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, zelený a bílý v poměru 1:2:1. V zeleném pruhu žluté hrábě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě, nad stříbrnou kvádrovanou zdí v dolní třetině, zlaté hrábě."
    },
    {
        obec: "Klabava",
        kod: 559911,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33841,
        latitude: 49.753546,
        longitude: 13.539382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Klabava_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, v horní části dvě k sobě přiléhající vysoké pece s prázdnými topeništi, v dolní části k vlajícímu okraji obrácený buchar hamru s kolem pohonu, bucharem a kovadlinou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě k sobě přistavené vysoké pece s prázdnými topeništi, pod nimi doleva obrácený buchar hamru s kolem pohonu a kovadlinou, vše zlaté."
    },
    {
        obec: "Kladeruby",
        kod: 542997,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75643,
        latitude: 49.492821,
        longitude: 17.865253,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/CoA_of_Kladeruby.svg",
        coatOfArmsFlagDescription: "List praporu tvoří bílý, červený, zelený a bílý pruh v poměru 2:1:1:2. Přes červený a zelený pruh je na střed listu položený bílý svěrček dotýkající se bílých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný, čelně stojící rytíř s červeno-stříbrným chocholem na zavřené přilbě, v pravé ruce držící svěšenou stříbrnou sekeru s červenou násadou ostřím vzhůru, na levé ruce má červeno-stříbrně dělený štít se stříbrným svěrčkem v horní půli."
    },
    {
        obec: "Kladeruby nad Oslavou",
        kod: 590827,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67575,
        latitude: 49.144911,
        longitude: 16.17187,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Coat_of_arms_of_Kladeruby_nad_Oslavou.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 3 : 1. Uprostřed listu vykračující daněk. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleném návrším vykračující daněk přirozené barvy."
    },
    {
        obec: "Kladky",
        kod: 589594,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79854,
        latitude: 49.650182,
        longitude: 16.841358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Kladky_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým zvlněným žerďovým pruhem širokým čtvrtinu délky listu. V modrém poli bílá mitra s červeným patriarším křížem, dole se žlutým lemem s červenými kameny a bílými fimbriemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou stříbrná mitra s červeným patriarším křížem, dole se zlatým lemem s červenými kameny a stříbrnými fimbriemi."
    },
    {
        obec: "Kladno",
        kod: 571580,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.772574,
        longitude: 15.982386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Kladno_CR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, žlutý, černý a bílý, přes ně uprostřed listu svislý modrý pruh se žlutou lilií, široký polovinu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý kůl s černou ostrví, nahoře provázený dvěma zlatými liliemi, dole přeložený stříbrným kvádrovaným mostem."
    },
    {
        obec: "Kladníky",
        kod: 569283,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.483773,
        longitude: 17.603034,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Kladniky_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými jednu desetinu šířky listu. Uprostřed bílá kvádrovaná věž s cimbuřím vysoká polovinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva zkřížené vztyčené zlaté rýče, přeložené v dolní části stříbrnou kvádrovanou věží s cimbuřím."
    },
    {
        obec: "Kladruby",
        kod: 560405,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.268814,
        longitude: 13.763561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Kladruby_%28okres_Strakonice%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a pilovitý zelený s pěti zuby, v poměru 1 : 2. V horní části zeleného pruhu bílá ohlížející se saň. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně pilovitě dělený štít. Nahoře dvě zkřížené odvrácené sekery přirozené barvy, dole stříbrná zpět hledící saň."
    },
    {
        obec: "Kladno",
        kod: 532053,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27201,
        latitude: 50.141799,
        longitude: 14.106846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Kladno_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém pruhu půl bílé orlice se žlutou zbrojí s červeným jazykem a žlutým jetelovým perizoniem, přiléhající k bílému pruhu. V bílém pruhu rys ve skoku dotýkající se předníma nohama modrého pruhu. V horní části listu zkřížená hornická kladívka převázaná žlutou stuhou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kladruby",
        kod: 559920,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.912977,
        longitude: 13.631799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Kladruby_RO_CZ_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s červeným žerďovým zubatým pruhem širokým čtvrtinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami. V modrém poli kosmo bílá sekera se žlutým topůrkem ostřím k žerďovému okraji, šikmo podložená bílou ostrví se čtyřmi suky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně polceném štítě nad červenou kvádrovanou hradbou se čtyřmi stínkami a zlatým osmihrotým křížem vpravo kosmá stříbrná sekera se zlatým topůrkem šikmo podložená stříbrnou ostrví se čtyřmi suky, vlevo červená orlice."
    },
    {
        obec: "Kladruby",
        kod: 560928,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34961,
        latitude: 49.715408,
        longitude: 12.980021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Kladruby_Tachov_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 2:1:6:1:2. V modrém pruhu královská koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kladruby",
        kod: 533084,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.723269,
        longitude: 14.950154,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/CoA_of_Kladruby%28okres_Bene%C5%A1ov%29.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě zelených a bílých, v poměru 1 : 1 : 6 : 1 : 1. V prostředním pruhu u žerďového okraje knížecí čepice ve žlutém mezikruží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený snížený hrot zakončený křížem a provázený dvěma vztyčenými zelenými ostrvemi o čtyřech sucích (2, 2). V hrotu knížecí čepice ve zlatém kruhu."
    },
    {
        obec: "Kladruby",
        kod: 567604,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.615093,
        longitude: 13.826385
    },
    {
        obec: "Kladruby nad Labem",
        kod: 575178,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53314,
        latitude: 50.057816,
        longitude: 15.487192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Kladruby_nad_Labem_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 3 : 1. Uprostřed listu vykračující bílý kladrubský kůň se žlutou zbrojí převýšený rakouskou císařskou korunou s fimbriemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě s modrou vlnitou patou vykračující stříbrný kladrubský kůň se zlatou zbrojí převýšený rakouskou císařskou korunou s fimbriemi."
    },
    {
        obec: "Klamoš",
        kod: 570168,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.125681,
        longitude: 15.501358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/ZnakKlamo%C5%A1e.jpg",
        coatOfArmsFlagDescription: "Žlutý list s bílým vykořeněným stromem bez listí v zeleném lipovém listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě ve vztyčeném zeleném lipovém listu stříbrný vykořeněný strom bez listí."
    },
    {
        obec: "Klapý",
        kod: 565024,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41116,
        latitude: 50.43154,
        longitude: 14.006583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Klap%C3%BD_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, z jeho dolního okraje vyniká šedá skála se třemi vrcholy, prostředním nižším, na krajních dvě bílé věže s cimbuřím a černým gotickým oknem, spojené žlutou hradbou se zkříženými černými ostrvemi. Mezi věžemi žlutý zajíc ve skoku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na skále přirozené barvy dvě stříbrné věže, každá s cimbuřím a černým gotickým oknem, spojené zlatou hradbou se dvěma zkříženými černými ostrvemi. Na hradbě zlatý zajíc ve skoku."
    },
    {
        obec: "Klášter",
        kod: 557897,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.50201,
        longitude: 13.576777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Kl%C3%A1%C5%A1ter_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů střídavě modrých a bílých, v poměru 3 : 1 : 4 : 1 : 3. V žerďovém a vlajícím pruhu po jedné osmicípé hvězdě, v prostředním pruhu větší osmicípá hvězda nad javorovým listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstající stříbrné prázdné gotické okno s kružbou, provázené v koutech a v kružbě třemi zlatými osmihrotými hvězdami. V okně zlatá hlavice berly se závitem zakončeným javorovým listem a se stříbrným sudariem se zlatým třepením."
    },
    {
        obec: "Klášter Hradiště nad Jizerou",
        kod: 536024,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29415,
        latitude: 50.523509,
        longitude: 14.944829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Kj_znak.gif",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy: červený, bílý a červený v poměru 1:3:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný vykračující lev se zlatou zbrojí a jazykem, držící v pravé přední tlapě zlatou berlu závitem ven."
    },
    {
        obec: "Klášterec nad Ohří",
        kod: 563129,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43151,
        latitude: 50.384561,
        longitude: 13.171393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Coat_of_arms_of_Kl%C3%A1%C5%A1terec_nad_Oh%C5%99%C3%AD.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý, poměru 2:1. Bílý pruh má sedm čtvercových zubů a šest stejných mezer. Z prostředního zubu vyrůstají tři žluté pšeničné klasy mezi dvěma lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Klášterec nad Orlicí",
        kod: 580431,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56182,
        latitude: 50.111702,
        longitude: 16.554593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Znak_obce_Kl%C3%A1%C5%A1terec_nad_Orlic%C3%AD.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý vlnitý, modrý vlnitý, bílý vlnitý a modrý zvlněný, v poměru 6:1:1:1:1. Ze žlutého vyrůstá bílá orlice se žlutou zbrojí a červeným srdcem na hrudi, nad ní tři žluté osmicípé hvězdy vedle sebe. Vlnité pruhy mají dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V patě modrého štítu dvě vlnitá břevna, horní zlaté a dolní stříbrné, z horního vyrůstá stříbrná orlice se zlatou zbrojí a červeným srdcem na hrudi, nad ní tři zlaté osmihroté hvězdy do oblouku."
    },
    {
        obec: "Klášterní Skalice",
        kod: 571687,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 50.023624,
        longitude: 14.98539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Klasterni_Skalice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílá, žlutě zdobená mitra s bílými fimbriemi vodorovně položenými v jedenácté osmnáctině šířky listu. Pod mitrou žlutá lilie, z níž vyrůstají do stran pod fimbriemi odvrácené a odkloněné žluté berly s bílými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná, zlatě zdobená mitra s fimbriemi do krajů štítu. Pod ní zlatá lilie, z níž do stran vyrůstají pod fimbriemi zlaté odvrácené a odkloněné berly se stříbrnými, do stran vlajícími sudarii."
    },
    {
        obec: "Klášterská Lhota",
        kod: 579386,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54371,
        latitude: 50.559794,
        longitude: 15.663687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Kl%C3%A1%C5%A1tersk%C3%A1_Lhota_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Černým kosmým pruhem, širokým jednu osminu šířky listu, dělený list na žerďové červené a vlající bílé pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na červené patě benediktin v černém řádovém oděvu s kapucí, opírající se pravicí o dřevěnou hůl zakončenou křížem přirozené barvy."
    },
    {
        obec: "Klatovec",
        kod: 587371,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.222518,
        longitude: 15.294793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Klatovec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, modrý, červený a bílý, v poměru 1 : 2 : 2 : 1. Uprostřed mezi bílými odvrácenými rohy unciální M převýšené korunou, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě se stříbrným návrším se svěšeným červeným javorovým listem unciální M převýšené korunou, obojí zlaté, a provázené odvrácenými stříbrnými rohy."
    },
    {
        obec: "Klec",
        kod: 562688,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.099391,
        longitude: 14.748496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Klec_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list. Uprostřed žlutý hrad s prolomenou gotickou branou s cimbuřím s pěti zuby a věží s cimbuřím se čtyřmi zuby. Po stranách brány a na věži po černé klíčové střílně. Z horní střílny vyniká kosmo na bílé žerdi žlutý praporec s ocasem, v něm modrá růže se žlutým semeníkem. Na vlající straně hradu na posledním zubu cimbuří brány sedí bílý ptáček s červenou zbrojí a křídlem, v bráně bílá klec s kruhovým závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý hrad s prolomenou gotickou branou s cimbuřím o pěti stínkách a věží s cimbuřím o čtyřech stínkách. Po stranách brány a na věži po černé klíčové střílně. Z horní střílny vyniká kosmo na stříbrné žerdi zlatý praporec s ocasem, v něm modrá růže se zlatým semeníkem. Vlevo na poslední stínce brány sedí stříbrný ptáček s červenou zbrojí a křídlem, v bráně stříbrná klec s kruhovým závěsem."
    },
    {
        obec: "Klatovy",
        kod: 555771,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.395655,
        longitude: 13.295194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Klatovy-znak.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a červené. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Klecany",
        kod: 538311,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25067,
        latitude: 50.176091,
        longitude: 14.411589,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený se žlutou ptačí klecí a zelený se třemi vodorovnými bílými pruhy vycházejícími z druhé, čtvrté a šesté dvanáctiny žerďového okraje pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít, vpravo zlatá ptačí klec, v horní části levého pole tři zúžená stříbrná břevna."
    },
    {
        obec: "Klenčí pod Čerchovem",
        kod: 553794,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34534,
        latitude: 49.434948,
        longitude: 12.814835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/CoA_of_Klen%C4%8D%C3%AD_pod_%C4%8Cerchovem.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Na horní okraj zeleného pruhu je položen bílý šraňk složený z vodorovného břevna a středového kůlu, které jsou široké jednu desetinu šířky listu, a z šesti (3+3) oboustranně zahrocených planěk. Délka šraňku je tři pětiny délky a výška tři pětiny šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kleneč",
        kod: 565032,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.392685,
        longitude: 14.248831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Klene%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červeno-bíle čtvrcený žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, červený a bílý. V horním žerďovém poli vodorovně tři bílé šípy střídavě hrotem k žerdi a vlajícímu okraji. V horním bílém poli na zeleném návrší vyrůstá hvozdík písečný český. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo tři položené stříbrné šípy, prostřední doleva, vlevo na zeleném návrší hvozdík písečný český přirozené barvy."
    },
    {
        obec: "Klenová",
        kod: 541851,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.333543,
        longitude: 13.233291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Klenov%C3%A1_%28Klatovy_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modro-bíle cihlovaný (3 x 8) a červený, v poměru 2 : 1 : 2. V horním pruhu vykračující bílý kohout se žlutou zbrojí a černým ocasem, v dolním pruhu vztyčený bílý list javoru klenu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě modro-stříbrně šachované břevno o třech řadách. Nahoře vykračující stříbrný kohout se zlatou zbrojí a černým ocasem, dole vztyčený stříbrný list javoru klenu."
    },
    {
        obec: "Klenovice",
        kod: 563986,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.278877,
        longitude: 14.715759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Klenovice_TA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, modrý, červený a bílý, v poměru 1 : 2 : 2 : 1. Uprostřed na modrém a červeném pruhu berla závitem k žerdi se dvěma listy javoru klenu, dole s plody (4, 4), vše žluté, překryté bílým sudariem. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě zlatá berla se stříbrným sudariem, přeložena dvěma zlatými vztyčenými zkříženými listy javoru klenu s plody (4, 4). Vše provázeno nahoře dvěma liliemi, vpravo zlatou, vlevo stříbrnou."
    },
    {
        obec: "Klenovice na Hané",
        kod: 589608,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79823,
        latitude: 49.403017,
        longitude: 17.211237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Coat_of_arms_of_Klenovice_na_Han%C3%A9.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě červených a bílých, v poměru 1:1:2:1:1. Prostřední pruh je kvádrovaný a oboustranně zubatý, vždy se čtyřmi čtvercovými zuby sahajícími do poloviny bílých pruhů a s pěti stejnými mezerami. Výška kvádrů se rovná šířce zubů. Uprostřed kvádrovaného pruhu bílá klíčová střílna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšená červená kvádrovaná hradba s cimbuřím a prázdnou klíčovou střílnou."
    },
    {
        obec: "Klentnice",
        kod: 584541,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69201,
        latitude: 48.844725,
        longitude: 16.644789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Klentnice_znak.png",
        coatOfArmsFlagDescription: "Modrý list s radlicí hrotem nahoru a ostřím k žerdi, pod ní dva bílé vzhůru obrácené vinné hrozny se zkříženými stopkami, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě radlice hrotem nahoru, provázená nahoře a dole dvojicí vinných hroznů na překřížených stoncích směřujících do středu, vše stříbrné."
    },
    {
        obec: "Klešice",
        kod: 573787,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.963712,
        longitude: 15.681376,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Klesice_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část a dva vodorovné pruhy: modrý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo stříbrné lekno, vlevo modrý vinný hrozen."
    },
    {
        obec: "Klíčany",
        kod: 538329,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25069,
        latitude: 50.202124,
        longitude: 14.434551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Kl%C3%AD%C4%8Dany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed kosmo postavený bílý klíč zuby dolů a k dolnímu okraji listu. Z dolního rohu vyniká žlutý oheň s pěti šikmými plameny, v horní polovině zeleného pruhu žlutá hlavice berly závitem ke klíči. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě kosmo postavený stříbrný klíč zuby dolů, vpravo dole vyniká oheň s pěti šikmými plameny, vlevo nahoře hlavice berly, obojí zlaté."
    },
    {
        obec: "Klimkovice",
        kod: 599549,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74283,
        latitude: 49.788191,
        longitude: 18.125961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Znak_Klimkovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Uprostřed bílý dvouocasý lev se žlutou korunou a zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Klínec",
        kod: 571211,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.900957,
        longitude: 14.343355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Kl%C3%ADnec_znak.svg",
        coatOfArmsFlagDescription: "Žlutý list se čtyřmi zelenými klíny, dva vycházející z prvních tří čtvrtin žerďového a vlajícího okraje a dva vycházející z druhé a páté šestiny dolního okraje listu, všechny s vrcholem ve středu listu. V horním žlutém poli modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad třemi (2, 1) zlatými smrky do poloviny zkrácený zlatý klín s modrou lilií."
    },
    {
        obec: "Klíny",
        kod: 567191,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43601,
        latitude: 50.638349,
        longitude: 13.548232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Kl%C3%ADny_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 3. V žerďové polovině bílého pruhu černé hrábě provázené dvěma černými přivrácenými plamennými křídly se žlutým perizoniem. Pod nimi v zeleném pruhu tři bílé rovnoramenné trojúhelníky - klíny hrotem dolů, široké osminu a vysoké čtvrtinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší se třemi volnými stříbrnými klíny vedle sebe černé hrábě provázené dvěma přivrácenými černými plamennými křídly se zlatým perizoniem."
    },
    {
        obec: "Klobouky u Brna",
        kod: 584550,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69172,
        latitude: 48.995112,
        longitude: 16.859619,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Znak_m%C4%9Bsta_Klobouky_u_Brna_hires.svg",
        coatOfArmsFlagDescription: "Bílý list se šikmým modrým pruhem, širokým jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě šikmo položený železný klobouk s podbradníkem a třemi péry, vše modré."
    },
    {
        obec: "Klokočí",
        kod: 577227,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.599761,
        longitude: 15.221716
    },
    {
        obec: "Klobuky",
        kod: 532461,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.294113,
        longitude: 13.987593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Klobuky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a žlutý, v poměru 1:2:1. V modrém pruhu bílý železný klobouk se žlutou šňůrou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře stříbrný železný klobouk se zlatou šňůrou, dolní polovina rozdělena mříží na šest polí, v horních tři kvádrované věže s cimbuřím, v prostředním dolním lilie, vše zlaté."
    },
    {
        obec: "Klokočí",
        kod: 514047,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75361,
        latitude: 49.560292,
        longitude: 17.682377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Kloko%C4%8D%C3%AD_PR_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list; uprostřed v modrém kruhovém poli, obtočeném růžencem z modrého klokočí, bílý vykračující ohlížející se beránek, provázený nahoře čtyřmi (1,2,1) žlutými tlapatými křížky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě v modrém kotouči obtočeném růžencem z modrého klokočí stříbrný vykračující ohlížející se beránek provázený nahoře čtyřmi (1,2,1) zlatými tlapatými křížky."
    },
    {
        obec: "Klokočov",
        kod: 568856,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.801828,
        longitude: 15.674289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Kloko%C4%8Dov_HB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 2 : 1. Uprostřed zelená vykořeněná lípa. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červenou patou zelená vykořeněná lípa."
    },
    {
        obec: "Klokočná",
        kod: 513628,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25164,
        latitude: 49.958513,
        longitude: 14.718623,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Kloko%C4%8Dn%C3%A1_-_znak.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý, bílý, černý, žlutý, černý a bílý, v poměru 11 : 1 : 1 : 1 : 1 : 1. Do žerďové poloviny modrého pruhu vyrůstá z horního bílého pruhu žlutá ratolest klokoče zpeřeného, nahoře se sedmi květy do kruhu, pod nimi na každé straně s lichozpeřeným listem s pěti lístky a dole na každé straně se dvěma svěšenými tobolkami; květy, lístky a tobolky bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá ratolest klokoče zpeřeného, nahoře se sedmi květy do kruhu, pod nimi na každé straně s lichozpeřeným listem s pěti lístky a dole na každé straně se dvěma svěšenými tobolkami; květy, lístky a tobolky stříbrné. Ratolest vyrůstá ze stříbrně lemovaného černého oblouku se sedmi zlatými kameny, v něm černo-stříbrně vlnkovitě dělené pole, dole se dvěma černými vlnkovitými břevny."
    },
    {
        obec: "Klopina",
        kod: 536687,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78973,
        latitude: 49.818661,
        longitude: 17.019494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Klopina_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu desetinu délky listu; uprostřed bílého pole svislý červený pruh široký jednu čtvrtinu šířky listu, provázený dvěma odvrácenými modrými radlicemi hrotem k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, zeleně lemovaném štítě vykořeněná jabloň přirozené barvy, provázená dole odvrácenými modrými radlicemi."
    },
    {
        obec: "Klopotovice",
        kod: 589616,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79821,
        latitude: 49.446217,
        longitude: 17.250871,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Klopotovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed listu tři žluté obilné klasy držené žlutým červeně okřídleným spárem se třemi bílými oboustrannými zubatými posunutými pruhy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě tři zlaté obilné klasy držené zlatým červeně okřídleným spárem se třemi stříbrnými cimbuřovými břevny."
    },
    {
        obec: "Klučov",
        kod: 590835,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.167093,
        longitude: 15.935181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Klu%C4%8Dov_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se šikmo položenou bílou radlicí ostřím a hrotem dolů, kosmo protknutou žlutým gotickým klíčem zuby dolů a k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmo postavená stříbrná radlice, kosmo protknutá postaveným zlatým gotickým klíčem zuby dolů."
    },
    {
        obec: "Klučenice",
        kod: 540447,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26256,
        latitude: 49.553233,
        longitude: 14.212131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Klu%C4%8Denice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě žlutých a zelených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykořeněný smrk přeložený dolů obrácenými zkříženými motykou a sekerou, vše přirozených barev."
    },
    {
        obec: "Klučov",
        kod: 533386,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.095114,
        longitude: 14.910171
    },
    {
        obec: "Kluky",
        kod: 549495,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39819,
        latitude: 49.316885,
        longitude: 14.245388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/CoA_of_Kluky_%28okres_P%C3%ADsek%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 2:1. V modrém pruhu žlutá růže s modrým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se zeleným návrším zlatá růže s modrým semeníkem na zlatém kmenu s kořeny."
    },
    {
        obec: "Kluky",
        kod: 534137,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28545,
        latitude: 49.907559,
        longitude: 15.325361
    },
    {
        obec: "Kluky",
        kod: 571075,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29426,
        latitude: 50.440316,
        longitude: 14.725295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Kluky_%28Mlad%C3%A1_Boleslav_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý. Uprostřed kosmo bílá sekera se žlutým topůrkem, šikmo podložená žlutou střelou-klukem s bílým opeřením k hornímu okraji listu nad vykořeněným žlutým pařezem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě kosmo stříbrná sekera se zlatým topůrkem šikmo podložená postavenou zlatou střelou-klukem se stříbrným opeřením, dole zlatý vykořeněný pařez."
    },
    {
        obec: "Kly",
        kod: 534897,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27741,
        latitude: 50.308894,
        longitude: 14.501593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Kly_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý zvlněný se třemi vrcholy a dvěma prohlubněmi, v poměru 5 : 3. V bílém pruhu dva svěšené modré vinné hrozny na zeleném stonku se dvěma listy vyrůstajícím z modrého pruhu. V modrém pruhu bílá růže s modrým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře vlnitě děleném štítě nahoře na vyrůstajícím zeleném stonku se dvěma listy dva svěšené modré vinné hrozny, dole stříbrná růže s modrým semeníkem."
    },
    {
        obec: "Kmetiněves",
        kod: 532479,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27322,
        latitude: 50.309285,
        longitude: 14.157106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Kmetin%C4%9Bves_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a žlutý, v poměru 6 : 1 : 1. V horním rohu bílý oblak vycházející z první poloviny žerďového a první třetiny horního okraje listu. Z oblaku vyniká žlutě oděná levá ruka, držící šikmo žlutou hůl. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V pravém rohu červeného štítu stříbrný oblak, z něhož vyniká zlatě oděná levá ruka přirozené barvy, držící nad zlatým pařezem na stříbrném trávníku, šikmo zlatou hůl."
    },
    {
        obec: "Kněždub",
        kod: 586269,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69664,
        latitude: 48.886761,
        longitude: 17.395572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Knezdub_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zelenou krokví vycházející ze druhé šestiny horního a dolního okraje listu a s vrcholem na vlajícím okraji listu. V žerďové části vztyčený zelený dubový list, ve střední části červený liliový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelená dubová větev se třemi listy a dvěma žaludy, provázená nahoře vpravo knížecí korunou a vlevo červeným liliovým křížem."
    },
    {
        obec: "Kněževes",
        kod: 581721,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.587125,
        longitude: 16.423892,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Coat_of_arms_of_Kn%C4%9B%C5%BEeves_BK.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně šachovaným (8x3) žerďovým pruhem. Ve střední části listu bílá dóza na masti, pod ní tři (2, 1) bílé pětilisté květy se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. Pravé pole stříbrno-červeně šachované, v levém modrém poli stříbrná dóza na masti, pod ní tři (2, 1) stříbrné pětilisté květy se zlatými středy."
    },
    {
        obec: "Kněževes",
        kod: 595853,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.456579,
        longitude: 15.980924,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. V zeleném pruhu vykořeněný listnatý strom, provázený dvěma odkloněnými šestilistými růžemi na stoncích, v modrém pruhu kosmo šipka s půlkruhovým zakončením šikmo přeložená šípem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře vlnitě děleném štítě nahoře vykořeněný listnatý strom provázený dvěma odkloněnými šestilistými růžemi na stoncích, dole kosmo šipka s půlkruhovým zakončením šikmo přeložená šípem, vše zlaté."
    },
    {
        obec: "Kněževes",
        kod: 539384,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25268,
        latitude: 50.121321,
        longitude: 14.259214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Knezeves_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, hermelínový, červený a zelený, v poměru 3:2:3. V žerďové polovině přes červený a zelený pruh zkřížené klíče, přeložené obilným klasem se dvěma listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě pod hermelínovou hlavou zkřížené klíče přeložené obilným klasem se dvěma listy, vše zlaté."
    },
    {
        obec: "Kněževes",
        kod: 541877,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27001,
        latitude: 50.146689,
        longitude: 13.637213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Kn%C4%9B%C5%BEeves_Rakovnik-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, bílý, modrý a žlutý, v poměru 1 : 3 : 3 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s cimbuřím a branou se zlatými zavřenými vraty na černých závěsech. Za hradbou dvě stříbrné věže s cimbuřím, každá se dvěma černými okny nad sebou a červenou stanovou střechou zakončenou zlatou makovicí. Mezi věžemi dvě přivrácené busty, muže a ženy, obě v přirozené barvě a stříbrném oděvu, nad nimi zkřížené chmelové stonky s listy a šišticemi převýšené žábou, vše zlaté."
    },
    {
        obec: "Kněžice",
        kod: 590843,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67529,
        latitude: 49.27088,
        longitude: 15.672268,
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký třetinu délky listu a modré pole. V pruhu tři kosmé černé pruhy vycházející z první, třetí a páté šestiny žerďového okraje do druhé, čtvrté a šesté šestiny vlajícího okraje pruhu. V poli žlutá svatojakubská mušle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře polcený štít, vpravo tři kosmá černá břevna. Přes vše zlatá svatojakubská mušle provázená vlevo nahoře vztyčeným zlatým lipovým listem."
    },
    {
        obec: "Kněžice",
        kod: 574007,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.861905,
        longitude: 15.527475
    },
    {
        obec: "Kněžice",
        kod: 537292,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28902,
        latitude: 50.257311,
        longitude: 15.335464
    },
    {
        obec: "Kněžičky",
        kod: 551481,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28908,
        latitude: 50.173997,
        longitude: 15.344934,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Kn%C4%9B%C5%BEi%C4%8Dky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený horní klín s knížecí čepicí nad žlutým obilným klasem se dvěma listy vyrůstajícím z dolního okraje listu. Z prvních tří šestin dolního okraje listu do třetin žerďového a z druhých tří šestin do třetin vlajícího okraje listu vycházejí tři pruhy, černý mezi žlutými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený vydutý klín mezi dvěma černými rohy. V klínu vyrůstající zlatý obilný klas se dvěma listy převýšený knížecí čepicí."
    },
    {
        obec: "Kněžmost",
        kod: 536041,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29402,
        latitude: 50.489274,
        longitude: 15.038396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Kn%C4%9B%C5%BEmost_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Uprostřed žluté slunce, v bílém poli bez paprsků. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kněžnice",
        kod: 573043,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.493286,
        longitude: 15.322548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Kneznice_znak.png",
        coatOfArmsFlagDescription: "V modrém listu knížecí koruna, pod ní obilný klas mezi třemi javorovými listy, dvěma odvrácenými a jedním svěšeným, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě knížecí koruna, pod ní obilný klas mezi třemi javorovými listy odvrácenými do stran a dolů, vše zlaté."
    },
    {
        obec: "Knínice",
        kod: 587389,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.094109,
        longitude: 15.604156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Coat_of_arms_of_Kn%C3%ADnice_JI.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed listu obrněné rameno s mečem, obojí žluté, šikmo probodávajícím položený levý bílý buvolí roh. V modrém pruhu mezi rohem a ramenem žlutý tlapatý křížek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě obrněné rameno s mečem, obojí zlaté, šikmo probodávajícím položený levý stříbrný buvolí roh a provázeným vlevo zlatým tlapatým křížkem."
    },
    {
        obec: "Kněžpole",
        kod: 592269,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.098647,
        longitude: 17.516817,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Znak_Kn%C4%9B%C5%BEpole.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým tlapatým křížem s rameny širokými na okrajích listu sedm šestnáctin délky listu. V něm žlutá berla závitem k žerďovému okraji, s bílým sudariem do vodorovných ramen kříže, přeložená uprostřed červenou žlutě zdobenou knížecí čepicí a pod ní bílou čelně postavenou radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný štítek zdobený v rozích a dole třemi vyrůstajícími liliemi. V něm volný modrý tlapatý kříž se zlatou berlou se stříbrným sudariem do stran, přeloženou uprostřed červenou zlatě zdobenou knížecí čepicí a dole čelně postavenou stříbrnou radlicí."
    },
    {
        obec: "Knínice u Boskovic",
        kod: 581739,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67934,
        latitude: 49.539688,
        longitude: 16.69513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Kninice_u_Boskovic_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým středovým křížem s rameny širokými jednu čtvrtinu šířky listu. Ve středu kříže modrá lilie. V horním žerďovém a dolním vlajícím poli po bílém vztyčeném lipovém listu, v horním vlajícím a dolním žerďovém po žlutém rovném tlapatém kříži. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zlaté palisádové hradby stříbrná lípa provázená u kmene dvěma zlatými tlapatými křížky, pod ní modrá lilie."
    },
    {
        obec: "Kňovice",
        kod: 598461,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.688403,
        longitude: 14.400412
    },
    {
        obec: "Knovíz",
        kod: 532487,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27401,
        latitude: 50.212665,
        longitude: 14.137056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Knoviz_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený žlutou a modrou krokví na dvě pole opačných barev. Poměr polí a krokví je 4 : 1 : 1 : 4 na žerďovém a vlajícím okraji. Vrchol žluté krokve je v první desetině šířky listu. Ve žlutém poli černá etážová amfora. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zlatě sníženým hrotem děleném štítě, nahoře snížená zlatá krokev, dole černá etážová amfora."
    },
    {
        obec: "Knyk",
        kod: 548286,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.647038,
        longitude: 15.578739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Knyk-znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se čtyřmi (1, 2, 1) odvrácenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě čtyři (1, 2, 1) odvrácené zlaté lipové listy."
    },
    {
        obec: "Koberovice",
        kod: 548146,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.587669,
        longitude: 15.262965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Coat_of_arms_of_Koberovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý oboustranně zubatý posunutý a zubatý modrý se třemi obdélníkovými zuby vysokými šestnáctinu šířky listu a čtyřmi stejnými mezerami, v poměru 1 : 1 : 6. V modrém pruhu tři zkřížené žluté klíče zuby nahoru a k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-stříbrně cimbuřově dělenou cimbuřovitou hlavou tři zkřížené vztyčené zlaté klíče."
    },
    {
        obec: "Koberovy",
        kod: 563641,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.62476,
        longitude: 15.228332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Koberovy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a červeno-zeleně polcený, v poměru 3:5. V černém pruhu osm srdíček (3,2,3), uprostřed dolního pruhu kalich s hostií, vše žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě pod černou hlavou s osmi (3,2,3) srdíčky kalich s hostií, vše zlaté."
    },
    {
        obec: "Kobeřice u Brna",
        kod: 593141,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.092284,
        longitude: 16.885919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Znak_obce_Kobe%C5%99ice_u_Brna.gif",
        coatOfArmsFlagDescription: "List tvoří svislý červený žerďový pruh dosahující do poloviny délky listu a ve vlající části dva vodorovné pruhy - žlutý nad modrým. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravé červené polovině stříbrný vinařský nůž, levá polovina zlato-modře dělená, nahoře černé parohy, dole zlatá rozletitá střela."
    },
    {
        obec: "Kobylá nad Vidnavkou",
        kod: 557218,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79065,
        latitude: 50.342045,
        longitude: 17.123713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Kobyl%C3%A1_nad_Vidnavkou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílo-červeně šikmo dělený. V modrém pruhu žlutá kobylí hlava s krkem. Vlající pruh je šikmo dělený na dvě trojúhelníková pole a dva pruhy střídavě červené a bílé. Pruhy vycházejí z třetí a čtvrté čtvrtiny žerďového okraje pruhu do první a druhé čtvrtiny vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli zlatá kobylí hlava s krkem, levé pole třikrát červeno-stříbrně šikmo dělené."
    },
    {
        obec: "Kobylí",
        kod: 584568,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69110,
        latitude: 48.932959,
        longitude: 16.891691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Znak_obce_Kobyl%C3%AD.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou rybou hlavou k hornímu okraji, prostřelenou třemi žlutými šípy hroty k vlajícímu okraji. U žerďového a vlajícího okraje po jednom žlutém vinném hroznu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná ryba hlavou k hornímu okraji štítu, zprava prostřelená třemi zlatými šípy pod sebou, nahoře provázená dvěma zlatými hrozny."
    },
    {
        obec: "Kobylice",
        kod: 573710,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.245928,
        longitude: 15.587203,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. U dolního okraje listu čtyři vodorovné polcené pruhy, každý široký šestnáctinu šířky listu. Z horního vodorovného pruhu vyrůstá strom, k žerďovému okraji dub a k vlajícímu lípa, v jeho koruně zvon, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě z třikrát dělené paty vyrůstá strom, vpravo dub a vlevo lípa, v jeho koruně zvon, vše opačných barev."
    },
    {
        obec: "Kobylnice",
        kod: 583219,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66451,
        latitude: 49.138134,
        longitude: 16.73193,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Kobylnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a červený, v poměru 4:1:5. V zeleném pruhu bílý vinařský nůž se žlutou rukojetí, hrotem nahoru a k vlajícímu okraji. V červeném pruhu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně zlatým zúženým kůlem polcený štít, vpravo stříbrný vinařský nůž se zlatou rukojetí, vlevo stříbrná lilie."
    },
    {
        obec: "Kobylnice",
        kod: 531405,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 50.006398,
        longitude: 15.375802
    },
    {
        obec: "Kobylnice",
        kod: 571172,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29446,
        latitude: 50.371738,
        longitude: 15.053963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Kobylnice_%28Mlad%C3%A1_Boleslav_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený, v poměru 5 : 7. Ve žlutém pruhu gotický klíč zuby nahoru a k žerďovému okraji a meč hrotem dolů, obojí zelené. V zeleném pruhu žlutá koňská hlava s krkem s černým uzděním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatým vrchem zlatá koňská hlava s krkem s černým uzděním. Ve vrchu doleva gotický klíč zuby nahoru, pod ním meč, obojí zelené a položené."
    },
    {
        obec: "Kobyly",
        kod: 564141,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.610271,
        longitude: 15.004443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Kobyly_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list. V žerďové části žlutá lipová větévka se sedmi listy a šesti plody. Ve střední a vlající části bílá kobylí hlava s krkem se žlutou hřívou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná kobylí hlava s krkem se zlatou hřívou převýšená zlatou lipovou větévkou se sedmi listy a šesti plody."
    },
    {
        obec: "Kocbeře",
        kod: 579394,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54464,
        latitude: 50.453757,
        longitude: 15.858823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Kocbe%C5%99e_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a zelený, v poměru 7 : 1 : 2. Do červeného pruhu vyrůstá bílý dvouocasý korunovaný lev se žlutou zbrojí a jazykem, držící žlutý latinský kříž s lipovitě zakončenými horními rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze zlatého návrší se třemi smrky přirozené barvy stříbrný dvouocasý korunovaný lev se zlatou zbrojí a jazykem, držící zlatý latinský kříž s lipovitě zakončenými horními rameny."
    },
    {
        obec: "Kocelovice",
        kod: 536601,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38742,
        latitude: 49.465172,
        longitude: 13.827677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/CoA_of_Kocelovice.svg",
        coatOfArmsFlagDescription: "List dělený kosmým žlutým pruhem širokým patnáctinu šířky listu na žerďové zelené a vlající modré pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře kosmo zlatým zúženým břevnem dělený štít. Vpravo kosmo položený stříbrný nůž se zlatou rukojetí, vlevo zlaté slunce."
    },
    {
        obec: "Koclířov",
        kod: 578231,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56911,
        latitude: 49.766393,
        longitude: 16.539938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Kocl%C3%AD%C5%99ov_znak.gif",
        coatOfArmsFlagDescription: "Žluto-modro-červeno-bíle čtvrcený list. Uprostřed černá zubří hlava se žlutou zbrojí a houžví a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře zlato-modře polcený, vpravo černá zubří hlava se zlatou zbrojí a houžví a s červeným jazykem, vlevo stříbrná kotva mezi zkříženými zlatými šípy s červenými hroty a opeřením, v dolním červeno-stříbrně polceném poli svatojakubská mušle opačných barev."
    },
    {
        obec: "Kočí",
        kod: 571610,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53861,
        latitude: 49.947276,
        longitude: 15.855614,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Ko%C4%8D%C3%AD_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list, v žerďové polovině černá orlice s červenou zbrojí a jazykem, nesoucí na hrudi žlutý kostel tvořený zvonicí s jehlanovou střechou s makovicí a křížkem, k níž od žerdi přiléhá zastřešená chodba prolomená čtyřmi černými oblouky a od vlajícího okraje kostelní loď s valbovou střechou a pěti černými okny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá orlice s červenou zbrojí a jazykem, nesoucí na hrudi zlatý kostel tvořený zvonicí s jehlanovou střechou s makovicí a křížkem, k níž zprava přiléhá zastřešená chodba prolomená čtyřmi černými oblouky a zleva kostelní loď s valbovou střechou a pěti černými okny."
    },
    {
        obec: "Kočín",
        kod: 578771,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.930488,
        longitude: 13.476248,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Ko%C4%8D%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký třetinu délky listu, a modré pole s bílým desetiramenným kočárovým kolem. V bílém pruhu pod sebou zelená růže, modrá šesticípá hvězda a zelená lilie. Poměr šířky k délce listu je 2: 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou stříbrné desetiramenné kočárové kolo. V hlavě modrá hvězda mezi růží a lilií, obojí zelené."
    },
    {
        obec: "Kohoutov",
        kod: 579408,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.451411,
        longitude: 15.904893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Kohoutov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě zelené a žluté, v poměru 7 : 1 : 1 : 1. V žerďové polovině horního zeleného pruhu vzpřímený bílý dvouocasý korunovaný lev se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V zeleném štítě na zlatém návrší vpravo vynikající stříbrná kostelní věž se dvěma černými okny nad sebou, kruhovým a obloukovým, a se zlatou střechou zakončenou křížkem, vlevo vzpřímený stříbrný dvouocasý korunovaný lev se zlatou zbrojí a jazykem převýšený třemi zlatými hvězdami vedle sebe."
    },
    {
        obec: "Kočov",
        kod: 541559,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.808767,
        longitude: 12.736709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Kocov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký třetinu délky listu a tři vodorovné pruhy, červený, bílý a červený. V modrém pruhu žlutý klíč, zuby nahoru a k žerďovému okraji listu, provlečený položenou bílou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo vztyčený zlatý klíč provlečený položenou stříbrnou kypřicí, vlevo stříbrné břevno."
    },
    {
        obec: "Kochánov",
        kod: 548529,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58253,
        latitude: 49.534276,
        longitude: 15.540336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Koch%C3%A1nov_CoA.jpg",
        coatOfArmsFlagDescription: "V modrém listu šikmo žlutá lipová větev, provázená dvěma bílými bramborovými květy se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě šikmo vztyčená zlatá lipová větev, provázená dvěma stříbrnými bramborovými květy se zlatými středy."
    },
    {
        obec: "Kochánky",
        kod: 536067,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29474,
        latitude: 50.277131,
        longitude: 14.780339,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Kochanky-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a pilovitý žlutý, v poměru 13:3. V modrém pruhu dvě bílé zkřížené sekery s modrými prázdnými tlapatými rovnými křížky na žlutých topůrkách, mezi nimi bílý hrozen. Pilovitý pruh má osm zubů dosahujících do desáté šestnáctiny šířky listu od horního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádou zkřížené stříbrné sekery s prolomenými prázdnými tlapatými křížky na zlatých topůrkách, nahoře mezi nimi stříbrný vinný hrozen."
    },
    {
        obec: "Kojatice",
        kod: 590851,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.998947,
        longitude: 15.703223,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Kojatice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu a zelené pole. V červeném pruhu dva vlající klíny s vrcholy na žerďovém okraji, mezi nimi bílý květ. V zeleném poli žlutý snop podložený kosmo gotickým klíčem zuby dolu a k dolnímu okraji a šikmo mečem hrotem k dolnímu rohu, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se dvěma stříbrnými kužely a stříbrným květem uprostřed postavený zlatý snop podložený kosmým gotickým klíčem zuby dolů a šikmo postaveným mečem, obojí stříbrné."
    },
    {
        obec: "Kojatín",
        kod: 510645,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.242707,
        longitude: 16.010276
    },
    {
        obec: "Kobeřice",
        kod: 507504,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74727,
        latitude: 49.98558,
        longitude: 18.052227,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Koberice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou berlou, na ní ve spodní části položena bílá lilie a za ní zkříženy dva bílé obilné klasy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě postavená zlatá berla, na ní ve spodní části položena stříbrná lilie a za ní zkříženy dva stříbrné obilné klasy."
    },
    {
        obec: "Kojátky",
        kod: 593150,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.171903,
        longitude: 17.026237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coat_of_arms_of_Koj%C3%A1tky.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým žlutým zubatým pruhem širokým čtvrtinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami. V modrém poli tři žluté růže pod sebou a žlutá radlice ostřím k vlajícímu okraji a hrotem dolů, v ní dvě modré šesticípé hvězdy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo zlato-modře cimbuřovitě polcený štít. V levém poli tři zlaté růže pod sebou a doleva obrácená zlatá radlice se dvěma modrými hvězdami pod sebou."
    },
    {
        obec: "Kojčice",
        kod: 598712,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39409,
        latitude: 49.476052,
        longitude: 15.255999
    },
    {
        obec: "Kojetice",
        kod: 590860,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67523,
        latitude: 49.157925,
        longitude: 15.818142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Coat_of_arms_of_Kojetice_TR.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a bílý. Ve třetině délky listu žluté hrábě, k nim přiléhá v modrém pruhu půl bílé orlice se žlutou zbrojí a jazykem, v červeném pruhu bílý buvolí roh postrkaný dvěma žlutými vinnými hrozny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlaté hrábě, k nim přiléhá vpravo půl stříbrné orlice se zlatou zbrojí a jazykem, vlevo stříbrný buvolí roh postrkaný dvěma zlatými vinnými hrozny."
    },
    {
        obec: "Kojetice",
        kod: 538345,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25072,
        latitude: 50.238202,
        longitude: 14.508588,
        coatOfArmsFlagDescription: "List tvoří tři žerďové pruhy, černý, žlutý a černý, každý široký jednu dvanáctinu délky listu a dva vodorovné pruhy žlutý a červený. Poměr šířky k délce listu 2:3.",
        coatOfArmsDescription: "Černo-červeně vpravo polcený štít, vpravo zlatý kůl, vlevo zlatý kohout."
    },
    {
        obec: "Kojetín",
        kod: 568881,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.657213,
        longitude: 15.658186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Kojet%C3%ADn_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a bílý. V žerďové části žlutého pruhu zkřížené černé ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě zeleno-stříbrně polcený uťatý smrk, provázený vpravo nahoře zkříženými černými ostrvemi."
    },
    {
        obec: "Kojetín",
        kod: 514055,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75201,
        latitude: 49.352911,
        longitude: 17.30366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Flag_of_Kojet%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, červený a modrý. Červený pruh kosmo a šikmo žlutě mřížovaný. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kojice",
        kod: 575194,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53312,
        latitude: 50.043079,
        longitude: 15.386682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Kojice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:2:6:2:1. Z dolního okraje modrého pruhu vyrůstá bílá skála s červeným mečem se žlutým jílcem hrotem nahoru. Nad skálou letí bílá holubice se zelenou olivovou ratolestí ve žlutém zobáku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou skálou se vztyčeným červeným mečem se zlatým jílcem letící stříbrná holubice se zelenou olivovou ratolestí ve zlatém zobáku."
    },
    {
        obec: "Kokořín",
        kod: 534901,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27723,
        latitude: 50.429981,
        longitude: 14.567322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Kokorin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy: bílý a červený. Ve žlutém pruhu černá ostrev o čtyřech sucích sahající od horního k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černé zkřížené ostrve přeložené červeným štítkem s polovinou stříbrného dvouocasého lva se zlatou zbrojí a jazykem."
    },
    {
        obec: "Kokory",
        kod: 514152,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75105,
        latitude: 49.494915,
        longitude: 17.375546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Kokory_znak.png",
        coatOfArmsFlagDescription: "V modrém listu čelně bíle opeřená žlutá husí noha. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kolaje",
        kod: 537306,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28904,
        latitude: 50.155469,
        longitude: 15.238544
    },
    {
        obec: "Koldín",
        kod: 580465,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.03692,
        longitude: 16.251721,
        coatOfArmsFlagDescription: "Červeno-modře kosmo dělený list. V dolním rohu vztyčený bílý list, uprostřed žlutý třmen, v horním cípu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrná věž s cimbuřím a prázdnou branou, provázená vpravo vztyčeným stříbrným listem, vlevo zlatou osmihrotou hvězdou. Nad branou modrý štítek se zlatým třmenem."
    },
    {
        obec: "Koleč",
        kod: 532495,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27329,
        latitude: 50.198872,
        longitude: 14.22351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Kolec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu pětinu délky listu a modré pole. V červeném pruhu tři žluté šesticípé hvězdy nad sebou. Ve střední části listu polovina žlutého korunovaného lva s červenou zbrojí a bílými zuby, držící v pravé tlapě šikmo bílý meč se žlutým jílcem a v levé zelený věnec. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře vpravo polceném štítě vpravo tři zlaté hvězdy, vlevo polovina zlatého korunovaného lva s červenou zbrojí a stříbrnými zuby, držící v pravé tlapě stříbrný meč se zlatým jílcem a v levé zelený věnec."
    },
    {
        obec: "Kolešov",
        kod: 565199,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.157254,
        longitude: 13.510143,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Kole%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a červený, v poměru 5 : 1 : 2. V žerďové části zeleného pruhu šikmo dolů letící bílý pták držící v zobáku na žlutém stonku bílý pětilistý květ se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo šikmo dolů letící stříbrný pták, držící v zobáku na zlatém stonku stříbrný pětilistý květ se zlatým středem, nad třemi stříbrnými pětilistými květy se zlatými středy na jednom vyrůstajícím stonku se dvěma listy, vše zlaté, vlevo stříbrné břevno se dvěma zelenými klíny provázené nahoře a dole stříbrným srdcem."
    },
    {
        obec: "Kolešovice",
        kod: 541893,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27002,
        latitude: 50.139748,
        longitude: 13.610387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Coats_of_arms_Kole%C5%A1ovice.jpeg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed ve žlutém půlměsíci cípy nahoru bílá věž se dvěma stínkami ve tvaru vlaštovčích ocasů, s červenou branou a třemi červenými okny vedle sebe. Z věže vyrůstá žlutý korunovaný dvouocasý lev s červenou zbrojí a s bílo-červeným pásem. V žerďové a vlající části listu žluté, k věži přivrácené, chmelové větévky s jedním vztyčeným listem a třemi svěšenými šišticemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě uprostřed zlatého stoupajícího půlměsíce stříbrná věž se dvěma stínkami ve tvaru vlaštovčích ocasů, s červenou branou a třemi červenými okny vedle sebe. Z věže vyrůstá zlatý korunovaný dvouocasý lev s červenou zbrojí a se stříbrno-červeným pásem. Lev je provázen dvěma přivrácenými zlatými chmelovými větévkami s jedním vztyčeným listem a třemi svěšenými šišticemi."
    },
    {
        obec: "Kolín",
        kod: 533165,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.027429,
        longitude: 15.202828,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kolinec",
        kod: 556467,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.299057,
        longitude: 13.439106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Kolinec_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým lvem se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrný lev se zlatou zbrojí a jazykem."
    },
    {
        obec: "Kolová",
        kod: 555258,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.187545,
        longitude: 12.903449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Kolova_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, černý a červený. Uprostřed v zeleném a černém pruhu bílé zkřížené sekery se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě trojvrší s krajními zelenými a středním černým vrchem, v něm zkřížené stříbrné sekery na zlatých topůrkách. Z prostředního vrchu šlehají červené plameny, z krajních vyrůstají dvě červené věže s cimbuřím, každá s jedním černým oknem."
    },
    {
        obec: "Kolomuty",
        kod: 570974,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.402199,
        longitude: 14.979095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Kolomuty_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý modrý, v poměru 1 : 4. Zubatý pruh má čtyři obdélníkové zuby sahající do osminy šířky listu a pět stejných mezer. V modrém pruhu nad dvěma bílými vlnitými pruhy, vycházejícími ze sedmnácté a devatenácté dvacetiny žerďového a vlajícího okraje, zkřížené kropáče proložené kladivem, vše bílé na žlutých násadách, dole provázené dvěma bílými býčími rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou cimbuřovou hlavou zkřížené kropáče proložené kladivem, vše stříbrné na zlatých násadách, provázené dole po stranách dvěma stříbrnými býčími rohy. Dole dvě zúžená stříbrná vlnitá břevna."
    },
    {
        obec: "Koloveč",
        kod: 553816,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.486895,
        longitude: 13.108876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Kolove%C4%8D-znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý se žlutým vozovým kolem a žlutý, v poměru 1 : 4 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté vozové kolo."
    },
    {
        obec: "Kolšov",
        kod: 536733,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78821,
        latitude: 49.903176,
        longitude: 16.947532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Coat_of_arms_of_Kol%C5%A1ov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený zubatý se třemi zuby a čtyřmi stejnými mezerami, v poměru 8:7. Uprostřed listu odvrácené husí hlavy s krky se žlutými zobáky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně cimbuřovitě polceném štítě odvrácené stříbrné husí hlavy s krky se zlatými zobáky."
    },
    {
        obec: "Komárno",
        kod: 588598,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76871,
        latitude: 49.434675,
        longitude: 17.780479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Kom%C3%A1rno_%28Krom%C4%9B%C5%99%C3%AD%C5%BE_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, zelený a bílý, v poměru 1:3:3:1 a bílý žerďový klín vycházející z druhé až sedmé osminy žerďového okraje, s vrcholem ve středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná hlava jelena s parožím."
    },
    {
        obec: "Komárov",
        kod: 599280,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.250167,
        longitude: 14.594124
    },
    {
        obec: "Komárov",
        kod: 547123,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.764167,
        longitude: 17.241052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Komarov_%28OL%29_znak.jpeg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed nad žlutou osmicípou hvězdou kosmo bílá kosa na žlutém kosišti, šikmo přeložená žlutými hráběmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou osmihrotou hvězdou kosmá stříbrná kosa na zlatém kosišti, šikmo přeložená zlatými hráběmi."
    },
    {
        obec: "Komárov",
        kod: 531324,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.806602,
        longitude: 13.856467,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Komarov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V bílém pruhu kosmo mlátek šikmo přeložený želízkem, oboje černé na žlutých topůrkách. V červeném pruhu k vlajícímu okraji obrácená obrněná noha s poutem na přetrženém řetězu nad ostruhou, vše bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Komárovice",
        kod: 590878,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.070089,
        longitude: 15.701107,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Kom%C3%A1rovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 1:6:5. V bílém pruhu zelený smrk s černým kmenem, v modrém pruhu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře sníženě vlnitě děleném štítě nahoře zelený smrk s černým kmenem provázený dvěma dole přivrácenými černými tesařskými úhelníky, dole stříbrná lilie."
    },
    {
        obec: "Komařice",
        kod: 535877,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37314,
        latitude: 48.878549,
        longitude: 14.545763,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Coat_of_arms_of_Koma%C5%99ice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, modrý, bílý a zelený, v poměru 4 : 1 : 1 : 4. U žerďového okraje listu v horním pruhu vykračující černý kohout s červenou zbrojí, pod ním v dolním pruhu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít s modrou patou. V pravém poli vykračující černý kohout s červenou zbrojí, v levém poli zlatá lilie, v patě stříbrné vlnité břevno."
    },
    {
        obec: "Komárov",
        kod: 549436,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76361,
        latitude: 49.150786,
        longitude: 17.568861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Coat_of_arms_of_Kom%C3%A1rov_ZL.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 9 : 5 : 2. Z bílého pruhu vyrůstají dva vztyčené odkloněné vinné hrozny mezi dvěma úponky, vše žluté. V bílém pruhu svěšená modrá švestka na zelené stopce se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstají ze stříbrného vrchu dva vztyčené odkloněné vinné hrozny mezi dvěma odvrácenými úponky, vše zlaté. Ve vrchu svěšená modrá švestka na zelené stopce se dvěma listy."
    },
    {
        obec: "Komňa",
        kod: 592277,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68771,
        latitude: 48.99393,
        longitude: 17.800925,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/CoA-Kom%C5%88a.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, červený, bílý a zelený v poměru 3:1:1:1. Z červeného pruhu vyniká v žerďové části bílý Pegas s červeným jazykem, žlutými kopýtky a žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli vyskakující stříbrný Pegas se zlatou zbrojí a korunou a červeným jazykem. Dolní pole stříbrno-zeleně děleno, nahoře zlatá ořízka černé ležící knihy s červenými písmeny J.A.K., dole položený stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Kokašice",
        kod: 541494,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34952,
        latitude: 49.878396,
        longitude: 12.947328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Koka%C5%A1ice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list, dole uprostřed vykračující kohout, v horním rohu a cípu po jedné labuti, vše bílé se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nahoře dvě labutě vedle sebe, dole vykračující kohout, vše stříbrné se zlatou zbrojí."
    },
    {
        obec: "Komorní Lhotka",
        kod: 507237,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73953,
        latitude: 49.658204,
        longitude: 18.527882,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Ligotka_Kameralna_herb.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý se žlutou orlicí s červenou zbrojí vyrůstající z červeného pruhu se žlutým pluhem bez kol obráceným k vlajícímu kraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře vyrůstající zlatá orlice s červenou zbrojí, dole zlatý pruh bez kol obrácený doleva."
    },
    {
        obec: "Komorovice",
        kod: 548162,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.508793,
        longitude: 15.345625,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Komorovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým klínem s vrcholem dosahujícím čtvrtiny délky listu a stejně velkým žlutým vlajícím klínem. Uprostřed žluté miskové váhy se závěsem v podobě vztyčeného lipového listu, kosmo přeložené žlutým liliovým žezlem a šikmo podložené bílým mečem se žlutým jílcem hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté miskové váhy se závěsem v podobě vztyčeného lipového listu, kosmo přeložené zlatým liliovým žezlem a šikmo podložené postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Komořany",
        kod: 593168,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.216446,
        longitude: 16.906888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Znak_Komo%C5%99any.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký jednu čtvrtinu délky listu a tři vodorovné pruhy, červený, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod stříbrnou zúženou hlavou modro-červeně stříbrným kůlem polcený štít. Vpravo stříbrná kosa na zlatém kosišti, vlevo stříbrná kvádrová věž se třemi černými okny nad sebou a cimbuřím."
    },
    {
        obec: "Konárovice",
        kod: 533394,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28125,
        latitude: 50.041558,
        longitude: 15.284322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Kon%C3%A1rovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. V žerďové části modrého pruhu bílá koňská hlava s krkem a žlutou hřívou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná koňská hlava s krkem se zlatou hřívou."
    },
    {
        obec: "Kondrac",
        kod: 529931,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.667248,
        longitude: 14.884609
    },
    {
        obec: "Konecchlumí",
        kod: 573051,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50705,
        latitude: 50.402345,
        longitude: 15.480335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Konecchlum%C3%AD_coA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým kosmým pruhem širokým třetinu délky listu. V pruhu kráčející černý vlk s červeným jazykem, v žerďovém poli kosmo klíč zuby nahoru a k žerďovému okraji, ve vlajícím poli kosmo meč hrotem k hornímu okraji listu, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmé stříbrné břevno s kráčejícím černým vlkem s červeným jazykem, provázené nahoře mečem a dole gotickým klíčem, obojí kosmo vztyčené a zlaté."
    },
    {
        obec: "Koněprusy",
        kod: 531332,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 49.920996,
        longitude: 14.065859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Coats_of_arms_Kon%C4%9Bprusy.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed na žluté koruně dva přivrácení koně ve skoku se zkříženýma předníma nohama, v červeném pruhu bílý se žlutou zbrojí, v modrém pruhu žlutý s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě na zlaté koruně dva přivrácení koně ve skoku se zkříženýma předníma nohama, pravý stříbrný se zlatou zbrojí, levý zlatý s červeným jazykem."
    },
    {
        obec: "Konětopy",
        kod: 531553,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.276506,
        longitude: 14.653932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Konetopy_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a modrý vlnitý s osmi vrcholy, v poměru 2:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě děleném štítě nahoře vyniká černá hlava koně s červeným jazykem."
    },
    {
        obec: "Konice",
        kod: 589624,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.590395,
        longitude: 16.889217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Konice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - horní bílý, dolní červený. V bílém pruhu je modré karé se žlutou rozletitou střelou se čtyřmi hroty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítu stříbrný jelen ve skoku se zlatou zbrojí, na něm modrý štítek se zlatou rozletitou střelou pánů Kounických ze Švábenic."
    },
    {
        obec: "Konojedy",
        kod: 564761,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.948677,
        longitude: 14.851354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Konojedy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý, do zeleného vyrůstá přední část bílého koně, ve žlutém u středu listu zelený smrk s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě polcený štít, vpravo z poltící linie vyniká přední část stříbrného koně, vlevo smrk přirozené barvy."
    },
    {
        obec: "Konstantinovy Lázně",
        kod: 560952,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34952,
        latitude: 49.881263,
        longitude: 12.977983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Konstantinovy_Lazne_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se stojícím červeným lvem se žlutou korunou a drápy, hledícím k vlajícímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít, nahoře na čtyřech zlatých pahorcích doleva stojící červený korunovaný lev se zlatou zbrojí a červeným jazykem. Dole kašna se dvěma tryskajícími prameny, vše stříbrné."
    },
    {
        obec: "Koněšín",
        kod: 590886,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67502,
        latitude: 49.190593,
        longitude: 16.041305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Kon%C4%9B%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsDescription: "V modrém štítě šikmé stříbrné vlnité břevno, provázené nahoře křížkovým křížem a dole šikmo postavenou berlou se sudariem, obojí zlaté."
    },
    {
        obec: "Kopidlno",
        kod: 573060,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.330951,
        longitude: 15.2704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Kopidlno_CoA_CZ.svg"
    },
    {
        obec: "Kopidlo",
        kod: 559024,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.943303,
        longitude: 13.464495
    },
    {
        obec: "Kopřivná",
        kod: 553247,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78833,
        latitude: 50.046475,
        longitude: 16.946863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Coat_of_arms_of_Kop%C5%99ivn%C3%A1.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným kopřivovým lemem. Uprostřed červený jetelový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kopřivový list. V něm červený jetelový trojlist."
    },
    {
        obec: "Kopřivnice",
        kod: 599565,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74221,
        latitude: 49.59957,
        longitude: 18.14491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Znak_m%C4%9Bsta_Kop%C5%99ivnice.svg",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy, horní bílý, dolní červený. V horním bílém pruhu je u žerdi umístěn znak z městského znaku - červený štítek s bílým kopřivovým listem. Poměr šířky praporu k délce je 2:3."
    },
    {
        obec: "Korkyně",
        kod: 599204,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26206,
        latitude: 49.780542,
        longitude: 14.351437,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Korkyn%C4%9B_CoA.JPG",
        coatOfArmsFlagDescription: "Modrý list se žlutou loďkou (korkou), z níž vyrůstá paví kyta o třech perech, v ní žluté granátové jablko. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá loďka (korka), z níž vyrůstá paví kyta o třech perech, v ní zlaté granátové jablko."
    },
    {
        obec: "Kornatice",
        kod: 579033,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.659042,
        longitude: 13.592325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Kornatice_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený s bílým korbelem, černý, bílý vlnitý a černý, v poměru 12:2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-červeně polceném štítě nad sníženým stříbrným vlnitým břevnem stříbrný korbel."
    },
    {
        obec: "Korno",
        kod: 533793,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.920584,
        longitude: 14.136968
    },
    {
        obec: "Korouhev",
        kod: 578258,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56993,
        latitude: 49.67142,
        longitude: 16.245888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Korouhev_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a šedý kamenný, v poměru 4 : 1. Uprostřed vyniká bílá věž, široká čtvrtinu délky listu, s černým oknem, s dřevěným zvonovým patrem a stanovou střechou hnědé barvy se žlutou makovicí. Věž nahoře provázena čtyřmi (2, 2) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na kamenné patě stříbrná věž s černým oknem, s dřevěným zvonovým patrem a stanovou střechou přirozené barvy se zlatou makovicí. Věž nahoře provázena čtyřmi (2, 2) zlatými hvězdami."
    },
    {
        obec: "Korolupy",
        kod: 594253,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.930251,
        longitude: 15.646296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Korolupy_CoA.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list, žerďové pole bílé s modru lilií, vlající pole tvoří tři vodorovné pruhy, modrý, žlutě mřížovaný červený a modrý, v poměru 1:2:1. Mřížování je tvořeno jedním vodorovným a čtyřmi viditelnými svislými pruhy širokými jednu čtyřiadvacetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně kosmo dělené křídlo, nahoře s modrou lilií a dole zlatě mřežované."
    },
    {
        obec: "Koroužné",
        kod: 595861,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.527574,
        longitude: 16.347737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Korou%C5%BEn%C3%A9_CoA.JPG",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, modrý, žlutý, modrý, bílý, modrý, bílý a modrý, v poměru 1 : 1 : 1 : 6 : 1 : 1 : 1. V prostředním pruhu černá zubří hlava se zelenou houžví a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře děleném štítě nahoře černá zubří hlava se zelenou houžví a červeným jazykem, dole zlaté hrábě provázené kosmým stříbrným pořízem se zlatými rukojeťmi a šikmou, doleva obrácenou stříbrnou radlicí."
    },
    {
        obec: "Korozluky",
        kod: 567221,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.478686,
        longitude: 13.723088,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Korozluky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a žlutých. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zeleného návrší na zelených stoncích tři zlaté květy, každý o dvanácti okvětních lístcích (hlaváček jarní)."
    },
    {
        obec: "Koruna",
        kod: 578266,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.846904,
        longitude: 16.714969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Koruna_%28Svitavy_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 4 : 1 : 4. V žerďové polovině horního pruhu žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě koruna nad seříznutým hrotem, obojí zlaté. V hrotu zvon ve vyrůstajícím prázdném gotickém oblouku, obojí modré."
    },
    {
        obec: "Koryčany",
        kod: 588601,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76805,
        latitude: 49.10649,
        longitude: 17.164435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Znak_obce_Kory%C4%8Dany.jpg",
        coatOfArmsFlagDescription: "List vodorovně dělený cimbuřovým řezem o čtyřech čtvercových zubech a dvou polovičních mezerách a svisle dělený tak, že horní žerďové pole je modré, dolní vlající červené a horní vlající a dolní žerďové jsou bílá. Plochy všech polí jsou stejné. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Koryta",
        kod: 559032,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.900611,
        longitude: 13.474862
    },
    {
        obec: "Koryta",
        kod: 599557,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29411,
        latitude: 50.572055,
        longitude: 15.011824
    },
    {
        obec: "Korytná",
        kod: 592285,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68752,
        latitude: 48.94107,
        longitude: 17.665308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Znak_obce_Korytn%C3%A1.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:1:5:1:1. V modrém pruhu zkřížené bílé meče hroty nahoru se žlutými záštitami a jílci, převýšené červenou knížecí čepicí se žlutým lemem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli zkřížené stříbrné meče se zlatými jílci a záštitami, převýšené červenou knížecí čepicí se zlatým lemem, dolní polovina červeno-stříbrně polcena, vpravo uťaté stříbrné lekno, vlevo modrá švestka na zeleném prohnutém stonku, obojí přivrácené."
    },
    {
        obec: "Kořenec",
        kod: 581755,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.530418,
        longitude: 16.754045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Ko%C5%99enec_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s žerďovým bílým pětiúhelníkovým polem s vrcholem ve dvou pětinách délky listu a stranami na horním a dolním okraji listu dlouhými dvě patnáctiny délky listu. V pětiúhelníku červená růže se žlutým semeníkem a zelenými kališními lístky. V zeleném poli žlutý ondřejský kříž, jehož ramena vycházejí z poslední desetiny horního a dolního okraje listu a jsou rovnoběžná se stranami žerďového pětiúhelníkového pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlatého kořene stříbrný větrný mlýn s černými dveřmi a červenou střechou se zlatým praporkem. Zlaté lopatky mlýna vycházejí z červené růže se zlatým semeníkem a zelenými okvětními lístky."
    },
    {
        obec: "Kořenice",
        kod: 533408,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.977216,
        longitude: 15.140969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Ko%C5%99enice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutou vykořeněnou lípou s kmenem obtočeným šálem s konci do stran a s tlapatým křížem mezi kořeny, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá vykořeněná lípa s kmenem obtočeným šálem s konci do stran a s tlapatým křížem mezi kořeny, obojí stříbrné."
    },
    {
        obec: "Kořenov",
        kod: 563668,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46849,
        latitude: 50.759359,
        longitude: 15.365424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Ko%C5%99enov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 1 : 3. V červeném pruhu pět žlutých kosmých pruhů vycházejících z lichých desetin horního okraje pruhu a sudých desetin dolního okraje pruhu. V modrém pruhu žluté slunce bez tváře, nad bílým vykořeněným pařezem, z něhož do stran vyrůstají dvě žluté dubové větévky se třemi listy a žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě, pod červenou hlavou s pěti zlatými kosmými břevny zlaté slunce nad stříbrným vykořeněným pařezem, z něhož do stran vyrůstají dvě zlaté dubové větévky se třemi listy a plodem."
    },
    {
        obec: "Kosice",
        kod: 570176,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.179841,
        longitude: 15.534913,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/CoA_of_Kosice.svg",
        coatOfArmsFlagDescription: "Žlutý list s červeným žerďovým zubatým pruhem širokým jednu čtvrtinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami. Ve žlutém poli černý kos s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červená kvádrovaná věž s cimbuřím, nad ní černý kos s červenou zbrojí."
    },
    {
        obec: "Kosmonosy",
        kod: 570826,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29306,
        latitude: 50.438608,
        longitude: 14.929915,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Kosmonosy.gif",
        coatOfArmsFlagDescription: "List tvoří červeno-bíle zubatě dělený kosmý pruh vycházející z první poloviny žerďového a horního okraje do druhé poloviny vlajícího a dolního okraje listu. Bílý pruh má čtyři čtvercové zuby a pět stejných mezer. Horní vlající pole je modré se žlutým lvem ve skoku s červenou zbrojí, dolní žerďové pole je zelené s bílým třešňovým květem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kosoř",
        kod: 539392,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25226,
        latitude: 49.98758,
        longitude: 14.326682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Kosor_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a zelené pole. V žerďové a střední části listu vyrůstá z dolního okraje bílé skalnaté návrší se seříznutým vrcholem, z něhož vyrůstají dvě bílé růže se žlutými semeníky na odkloněných stoncích s trny a dvěma listy, střídavě mezi třemi liliemi na stoncích, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě ze stříbrného skalnatého návrší se seříznutým vrcholem vyrůstají dvě stříbrné růže se zlatými semeníky na odkloněných stoncích s trny a dvěma listy, střídavě mezi třemi liliemi na stoncích, vše zlaté."
    },
    {
        obec: "Kosořice",
        kod: 536121,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29441,
        latitude: 50.33348,
        longitude: 14.96974
    },
    {
        obec: "Kosořín",
        kod: 574449,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 49.979239,
        longitude: 16.233825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Koso%C5%99%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červeno - bíle šikmo pruhovaný. Pět červených pruhů vychází z lichých osmin horního a dolního okraje pruhu. Uprostřed listu hrábě v modrém pruhu provázené liliemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně zvýšeně děleném štítě, dole se třemi šikmými červenými břevny, hrábě, nahoře provázené dvěma liliemi, vše zlaté."
    },
    {
        obec: "Kosov",
        kod: 536814,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.883233,
        longitude: 16.79882
    },
    {
        obec: "Kosova Hora",
        kod: 540498,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26291,
        latitude: 49.654247,
        longitude: 14.471834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Znak-kosova-hora.png",
        coatOfArmsFlagDescription: "List tvoří osm vodorovných pruhů, střídavě modrých a bílých, v poměru 13 : 1 : 1 : 1 : 1 : 1 : 1 : 1. Z dolního okraje listu vyniká bílá kvádrovaná věž s cimbuřím a červenou stanovou střechou se žlutou makovicí, dvěma černými okny vedle sebe a modrou prolomenou branou, v níž na zeleném návrší stojí k vlajícímu okraji listu obrácený černý kos se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kostelany",
        kod: 588521,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.203174,
        longitude: 17.382954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Kostelany_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - zelený, žlutý a zelený, v poměru 1:2:1. Ve žlutém poli je černý lovecký pes ve skoku s bílými zuby, červenou zbrojí a žlutým obojkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě polcený štít. V pravé polovině vozové kolo, pod ním kosmo položená širočina, obojí zlaté. V levé polovině černý lovecký pes ve skoku s bílými zuby, červenou zbrojí a zlatým obojkem."
    },
    {
        obec: "Kostelany nad Moravou",
        kod: 592293,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68601,
        latitude: 49.045476,
        longitude: 17.407042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Kostelany_nad_Moravou%2C_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým zvlněným pruhem o dvou vrcholech a třech prohlubních, širokým jednu čtvrtinu délky listu. V modrém poli bílo-červeně polcený rak klepety k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou stříbrno-červeně polcený rak."
    },
    {
        obec: "Kostelec",
        kod: 586277,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69651,
        latitude: 49.02693,
        longitude: 17.153306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Kostelec_CoA_HO_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů, červený, bílý, modrý, bílý a červený. V žerďovém pruhu červená, žlutě zdobená knížecí koruna, v modrém vodorovném pruhu dvě žluté šesticípé hvězdy, první ve střední, druhá ve vlající části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou s červenou, zlatě lemovanou knížecí korunou provázenou dvěma zlatými hvězdami modrý kůl se zlatým postaveným mečem, provázený dvěma červenými vinnými větévkami, každá se dvěma hrozny."
    },
    {
        obec: "Kostelec",
        kod: 587401,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58861,
        latitude: 49.3614,
        longitude: 15.490771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Kostelec-Jih_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílo-červeně šachovaný (7x3) pruh a modré pole. V poli sv. Kunhuta v bílém šatě, se žlutou korunou, pláštěm a botami, držící v levé ruce bílý kostel s červenými střechami, věží k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, pravé pole stříbrno-červeně šachované, v levém modrém poli sv. Kunhuta ve stříbrném šatě, se zlatou korunou, pláštěm a botami, držící v levé ruce stříbrný kostel s červenými střechami, věží doleva."
    },
    {
        obec: "Kostelec",
        kod: 548928,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.381344,
        longitude: 15.327377
    },
    {
        obec: "Kostelec",
        kod: 503916,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.674415,
        longitude: 13.026491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Kostelec_okr._tachov_znak.svg",
        coatOfArmsFlagDescription: "List kosmo modro-bíle kosodélníkově šindelovaný (2x6). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrný kostel s věží vlevo, červenými střechami, s prolomenými černými gotickými okny. Vlevo nahoře stříbrná lilie."
    },
    {
        obec: "Kostelec na Hané",
        kod: 589632,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79841,
        latitude: 49.514078,
        longitude: 17.058346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Kostelec_na_Han%C3%A9_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1 : 3 : 1. V červeném pruhu na bílém soklu bílé kvádrované průčelí kostela s prázdnou branou, dvěma černými obloukovými okny a třemi bílými věžemi, prostřední vyšší, každá s černým obloukovým oknem a modrou stanovou střechou zakončenou žlutým křížkem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kostelec nad Černými Lesy",
        kod: 533416,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.994137,
        longitude: 14.859324,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Cernykostelec.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. V modrém pruhu vyrůstá divý vousatý muž se zeleným věncem na hlavě a kolem beder s levicí v bok a v pozvednuté pravici držící na zeleném stonku se dvěma listy žlutou růži s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá divý vousatý muž se zeleným věncem na hlavě a kolem beder s levicí v bok a v pozvednuté pravici držící na zeleném stonku se dvěma listy zlatou růži s červeným semeníkem a zelenými kališními lístky. Vlevo nahoře zlatá hvězda."
    },
    {
        obec: "Kostelec nad Labem",
        kod: 534935,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27713,
        latitude: 50.226634,
        longitude: 14.585628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Kostelec_nad_Labem_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým šikmým pruhem vycházejícím z první třetiny dolního okraje do třetí třetiny horního okraje listu na červené žerďové a modré vlající pole. V červeném poli bílý dvouocasý lev se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrný kostel, vlevo na průčelí dveře a dvě okna, na lodi tři okna a na zprava přiléhající nízké hranolové věži dvě okna, na lodi sedlová střecha, z níž vyniká stříbrná polygonální věžička, se třemi okny nahoře a jedním dole, na obou věžích střechy stanové, dveře a všechna okna černá a oblouková, střechy červené, zakončené na věžích a v průčelí zlatou makovicí s křížkem. Vpravo nahoře červený štítek se stříbrným dvouocasým lvem."
    },
    {
        obec: "Kostelec nad Orlicí",
        kod: 576361,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.122511,
        longitude: 16.215084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Kostelec_nad_Orlici_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s českým bílým lvem, který drží v pravé tlapě dva žluté šípy hrotem vzhůru a v levé tlapě dvě zkřížené zelené ratolesti (figura z městského znaku). Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kostelec nad Vltavou",
        kod: 549509,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39858,
        latitude: 49.49999,
        longitude: 14.211859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Kostelec_nad_Vltavou_znak.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dole tři stříbrná vlnitá břevna, na horním zelené trojvrší. Z prostředního vrchu vyniká stříbrný kostel s věží vpravo, černými okny, červenými střechami se dvěma zlatými křížky."
    },
    {
        obec: "Kostelec u Heřmanova Městce",
        kod: 571628,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.923421,
        longitude: 15.651641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Kostelec-u-hermanova-mestce-znak.jpg"
    },
    {
        obec: "Kostelec u Holešova",
        kod: 588610,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76843,
        latitude: 49.374174,
        longitude: 17.511445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Kostelec_u_Hole%C5%A1ova_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým zubatým pruhem na žerďovém okraji širokým jednu třetinu délky listu. Bílý pruh má pět čtvercových zubů o výšce jedné desetiny šířky praporu. V červeném poli kosmo položený žlutý klíč křížem přeložený bílým mečem, hrotem dolů, se žlutým jílcem a záštitou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V dolní části červeného štítu stříbrná kvádrová hradba s cimbuřím, nad ní kosmo položený zlatý klíč křížem přeložený stříbrným mečem, hrotem dolů, se zlatým jílcem a záštitou."
    },
    {
        obec: "Kosičky",
        kod: 570184,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50365,
        latitude: 50.178703,
        longitude: 15.555822
    },
    {
        obec: "Kostelec u Křížků",
        kod: 538370,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25168,
        latitude: 49.907123,
        longitude: 14.557334
    },
    {
        obec: "Kostelecké Horky",
        kod: 576387,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.051755,
        longitude: 16.206964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Kosteleck%C3%A9_Horky_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým křížem s rameny širokými osminu šířky listu. V karé zelený vztyčený lipový list, pod ním v dolním rohu červená růže s bílým semeníkem a zelenými kališními lístky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší lípa přirozené barvy provázená vpravo červenou růží s prázdným semeníkem a zelenými kališními lístky, vlevo vyrůstajícím modrým latinským křížem."
    },
    {
        obec: "Kostelní Hlavno",
        kod: 536130,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29476,
        latitude: 50.257529,
        longitude: 14.699086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Coats_of_arms_Kosteln%C3%AD_Hlavno.jpeg",
        coatOfArmsFlagDescription: "List tvoří červené karé s bílou koňskou hlavou s krkem se žlutou hřívou a dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně děleném štítě nahoře vyniká stříbrný kostel s věží vpravo. Loď s pěti obloukovými okny vedle sebe a valbovou střechou se sanktusníkem s cibulovou střechou a makovicí s křížkem, věž s oknem a jehlancovou střechou s makovicí; střechy červené, okna a sanktusník černé, makovice a křížek zlaté. Dole stříbrná koňská hlava s krkem se zlatou hřívou."
    },
    {
        obec: "Kostelní Lhota",
        kod: 537314,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28912,
        latitude: 50.129367,
        longitude: 15.024308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Kosteln%C3%AD_Lhota_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený a střídavě bílé a modré, v poměru 14:1:1:1:1. V červeném pruhu žluté průčelí kostela s věží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad 3x stříbrno-modře dělenou patou zlaté průčelí kostela s věží."
    },
    {
        obec: "Kostelní Myslová",
        kod: 587419,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.147908,
        longitude: 15.42899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/CoA_of_Kosteln%C3%AD_Myslov%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červeno-zeleně šachovaný (3x9). V žerďové a střední části modrého pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v horním modrém poli zlatá růže s červeným semeníkem, dolní polovina červeno-zeleně šachovaná."
    },
    {
        obec: "Kostelní Radouň",
        kod: 509108,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.225517,
        longitude: 15.008829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Kosteln%C3%AD_Radou%C5%88_CoA.png",
        coatOfArmsFlagDescription: "Modrý list. Z dolního okraje listu vyniká bílá kostelní věž s červenou stanovou střechou zakončenou žlutou makovicí s křížkem. Ve věži černé hodiny se žlutými ručkami nad červeným gotickým oknem. V horní části listu věž provázena růží a mlýnským kolem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kostelní věž s červenou stanovou střechou zakončenou zlatou makovicí s křížkem. Ve věži černé hodiny se zlatými ručkami nad červeným gotickým oknem. Věž nahoře provázena vpravo růží a vlevo mlýnským kolem, obojí zlaté."
    },
    {
        obec: "Kostelní Vydří",
        kod: 508357,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.106163,
        longitude: 15.421758,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Kosteln%C3%AD_Vyd%C5%99%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 10 : 1 : 1 : 1 : 3. V žerďové polovině horního pruhu bílá kostelní věž se vchodem a oknem, obojí černé a obloukové, a s červenou stanovou střechou zakončenou žlutou makovicí a patriarším křížkem, přidržovaná dvěma přivrácenými žlutými vydrami ve skoku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volná stříbrná kostelní věž se vchodem a oknem, obojí černé a obloukové, a s červenou stanovou střechou zakončenou zlatou makovicí a patriarším křížkem, přidržovaná dvěma přivrácenými zlatými vydrami ve skoku. Dole dvě stříbrná vlnitá břevna."
    },
    {
        obec: "Kostěnice",
        kod: 575232,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.009667,
        longitude: 15.903791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Coat_of_arms_of_Kost%C4%9Bnice.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým klínem s vrcholem uprostřed horního okraje listu, provázeným u žerďového a vlajícího okraje dvěma bílými přivrácenými krojidly hroty k dolnímu okraji. V klínu černý kohout s červenou zbrojí vykračující k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý hrot s doleva vykračujícím černým kohoutem s červenou zbrojí provázený dvěma přivrácenými stříbrnými krojidly."
    },
    {
        obec: "Kostice",
        kod: 584576,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69152,
        latitude: 48.74695,
        longitude: 16.978794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Kostice_%28B%C5%99eclav%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým antonínským křížem s rameny širokými sedm osmnáctin šířky listu s vodorovným ramenem na horním okraji listu. Ve svislém ramenu kříže modrý vinný hrozen na hnědém stonku se dvěma zelenými listy ve vodorovném ramenu kříže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou hlavou zlatý kůl, v nich modrý vinný hrozen na stonku se dvěma zelenými listy. Vpravo radlice, vlevo doleva obrácený vinařský nůž - kosíř, obojí zlaté."
    },
    {
        obec: "Kostníky",
        kod: 590894,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.974807,
        longitude: 15.639908,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Kostn%C3%ADky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a červený, v poměru 1 : 2 : 1. V červených pruzích dva bílé šikmé pruhy dělící žerďový okraj pruhu v poměru 5 : 3 : 2 : 3 a vlající okraj v poměru 3 : 2 : 3 : 5, v modrém pruhu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ve zvýšeném modrém klínu, provázeném šikmým a kosmým stříbrným břevnem, stříbrná hlava laně s krkem a červeným jazykem, převýšená zlatou osmihrotou hvězdou."
    },
    {
        obec: "Kostomlátky",
        kod: 529630,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28921,
        latitude: 50.171133,
        longitude: 14.984131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Kostoml%C3%A1tky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, vlnitý modrý a zvlněný žlutý, v poměru 2 : 1 : 2. V horním pruhu vodorovně položená přelomená červená kost, v dolním pruhu zelený dubový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modré vlnité břevno provázené nahoře položenou, uprostřed přelomenou červenou kostí, dole zeleným dubovým trojlistem."
    },
    {
        obec: "Kostomlaty nad Labem",
        kod: 537331,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28921,
        latitude: 50.184587,
        longitude: 14.954085,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Kl_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 2:1:1. V žerďové části modrého pruhu bílá věž s cimbuřím a černým oknem, o níž se opírá kosmo žlutý žebřík. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyniká ze stříbrné vlnité paty zelené návrší se stříbrnou kvádrovanou věží s cimbuřím a černým oknem, o níž je zleva opřen zlatý žebřík."
    },
    {
        obec: "Kostomlaty pod Milešovkou",
        kod: 567612,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41754,
        latitude: 50.560217,
        longitude: 13.873166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Kostomlaty_nad_Mile%C5%A1ovkou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3 : 1. Uprostřed horního okraje zeleného pruhu vyniká zelené trojvrší, na něm žlutá věž s cimbuřím a černým gotickým oknem. Pod ní dvě bílé kosti pod sebou, zlomené k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvrší se dvěma stříbrnými, dolů zlomenými kostmi pod sebou zlatá věž s cimbuřím a černým gotickým oknem."
    },
    {
        obec: "Kostomlaty pod Řípem",
        kod: 565059,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.383135,
        longitude: 14.333177
    },
    {
        obec: "Košátky",
        kod: 566047,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29479,
        latitude: 50.315276,
        longitude: 14.667096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Kosatky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílá žerďová a červená vlající část. Na dolním okraji modrý zvlněný pruh široký jednu čtvrtinu délky listu s pěti vrcholy a čtyřmi prohlubněmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě nad modrou vlnitou patou vpravo červené prázdné gotické okno, vlevo stříbrná kypřice."
    },
    {
        obec: "Košetice",
        kod: 548171,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39422,
        latitude: 49.558467,
        longitude: 15.116371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Arms_of_Ko%C5%A1etice.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu polovinu délky listu s bílým kozlem ve skoku a devět střídavě bílých a modrých vodorovných pruhů, z nichž druhý až osmý jsou vlnité se dvěma vrcholy a jednou prohlubní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo čtyři modrá vlnitá břevna, vlevo stříbrný kozel ve skoku."
    },
    {
        obec: "Košice",
        kod: 552585,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39117,
        latitude: 49.325429,
        longitude: 14.751505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Ko%C5%A1ice_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký třetinu délky listu a zelené pole. V bílém pruhu tři modré vodorovné vlnité pruhy vycházející z jedenácté, třinácté a patnácté šestnáctiny žerďového okraje listu. Z horního modrého pruhu vyrůstá zelená skřípina lesní, u žerďového okraje zeleného pole tři šikmo vztyčené žluté dubové listy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít. Vpravo tři snížená modrá vlnitá břevna, z horního vyrůstá zelená skřípina lesní, vlevo tři šikmo vztyčené zlaté dubové listy."
    },
    {
        obec: "Košice",
        kod: 551465,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.895897,
        longitude: 15.150945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Ko%C5%A1ice_%28Kutn%C3%A1_Hora_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3 : 2. V modrém pruhu žlutá lilie s proraženou šesticípou hvězdou v prostředním listu. V červeném pruhu bílý letící holub se žlutým zobákem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně sníženě dělený štít. Nahoře zlatá lilie, jejíž prostřední horní list je proražen hvězdou. Dole stříbrný letící holub se zlatým zobákem."
    },
    {
        obec: "Košík",
        kod: 537349,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28935,
        latitude: 50.318352,
        longitude: 15.134157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Ko%C5%A1%C3%ADk_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh a zelené pole, v poměru 3 : 7. V pruhu kartas obrácený k žerďovému okraji listu, držící pravou rukou dlouhou hnědou hůl přes pravé rameno. Ve vlající části půl nožového kola s pěti rameny obloukem k vlajícímu okraji listu nad dubovým čtyřlistem (2, 2), vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít. Vpravo kartas v pravé ruce držící dlouhou dřevěnou hůl přes pravé rameno, vše přirozené barvy. Vlevo nad kosmým dubovým čtyřlistem levá půle zlomeného nožového kola, obojí zlaté."
    },
    {
        obec: "Košíky",
        kod: 592307,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68704,
        latitude: 49.157681,
        longitude: 17.416297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Ko%C5%A1%C3%ADky%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, modrý, bílý, modrý a bílý, v poměru 1:1:4:1:1. V prostředním pruhu červená růže se žlutým semeníkem a žlutými kališními lístky, mezi dvěma zelenými vinnými hrozny stopkami ke středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě proutěný košík přirozené barvy, z něhož vzhůru vyrůstají dva zelené stvoly vinné révy, každý nahoře s jedním hroznem a dole s jedním listem. Uprostřed v koši červená růže se zlatým semeníkem a kališními lístky, mezi hrozny zkřížené přivrácené modré vinařské nože - kosíře s černými rukojeťmi."
    },
    {
        obec: "Košťálov",
        kod: 577235,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51202,
        latitude: 50.571751,
        longitude: 15.404135,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Kostalov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a modrý, v poměru 2:1:1. V žerďové části bílého pruhu modrý květ lnu, v něm žlutá věž s cimbuřím a prázdnou branou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře červeným břevnem děleném štítě nahoře tři (2,1) modré květy lnu, dole zlatá věž s cimbuřím a prázdnou branou."
    },
    {
        obec: "Košín",
        kod: 562955,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39137,
        latitude: 49.456508,
        longitude: 14.658548
    },
    {
        obec: "Košťany",
        kod: 567621,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41723,
        latitude: 50.655181,
        longitude: 13.755656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Ko%C5%A1%C5%A5any_-_znak.png",
        coatOfArmsFlagDescription: "Červený list, z dolního okraje vyrůstá trojvrší, krajní vrchy černé, prostřední zelený, z něhož vyrůstá bílý dvouocasý lev se žlutou korunou a zbrojí. V zeleném vrchu křišťálový pohár s nálepy podložený hornickými kladívky, vše bílé. Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ze zelené hůrky mezi dvěma černými vrchy vyrůstá stříbrný dvouocasý lev se zlatou korunou a zbrojí. V zelené hůrce křišťálový pohár s nálepy podložený zkříženými hornickými kladívky, vše stříbrné."
    },
    {
        obec: "Koštice",
        kod: 566284,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43921,
        latitude: 50.403319,
        longitude: 13.944037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Kostice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 3 : 7. Uprostřed červeného pruhu čtyři bílé lilie vedle sebe. V bílém pruhu zelený chmelový list se dvěma odkloněnými dvojicemi šištic a stonkem provlečeným položenou modrou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě, pod červenou hlavou se čtyřmi stříbrnými liliemi vedle sebe, zelený chmelový list se dvěma odkloněnými dvojicemi šištic a stonkem provlečeným položenou modrou kypřicí."
    },
    {
        obec: "Kotenčice",
        kod: 513555,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.736397,
        longitude: 14.093917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/KOTEN%C4%8CICE_Znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vlnkovitý modrý. Na modrém pruhu v žerďové polovině plovoucí černá potápka roháč se žlutým zobákem, provázená nad křídlem modrou sedmicípou hvězdou. V dolním pruhu pod potápkou tři bílé zkřížené hřeby. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnkovitě děleném štítě nahoře plovoucí černá potápka roháč se zlatým zobákem, provázená vlevo nahoře modrou sedmihrotou hvězdou. Dole tři zkřížené stříbrné hřeby."
    },
    {
        obec: "Kotlasy",
        kod: 595870,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.492812,
        longitude: 15.946201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Kotlasy.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě žlutých a ostrvových černých s pěti suky (3, 2), v poměru 5 : 4 : 9 : 4 : 5. Suky na horním pruhu k vlajícímu okraji listu, suky na dolním k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě dvě černá ostrvová břevna o pěti (3, 2) sucích směřujících na horním břevnu vlevo, na dolním vpravo."
    },
    {
        obec: "Košařiska",
        kod: 507458,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73981,
        latitude: 49.589496,
        longitude: 18.69578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Koszarzyska_herb.png",
        coatOfArmsFlagDescription: "Modrý list s bílým kráčejícím kozlem. Na vlajícím okraji tři rovnostranné žluté trojúhelníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zlatém trojvrší stříbrný kráčející kozel."
    },
    {
        obec: "Kotovice",
        kod: 540633,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.672114,
        longitude: 13.154585
    },
    {
        obec: "Kotopeky",
        kod: 534072,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.856839,
        longitude: 13.925264
    },
    {
        obec: "Kotvrdovice",
        kod: 581763,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67907,
        latitude: 49.355206,
        longitude: 16.784459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Kotvrdovice-znak.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, červený a černý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě z černé hradby s cimbuřím vyrůstá lípa přirozené barvy. V hradbě červené zlatě zářící srdce s černou trnovou korunou."
    },
    {
        obec: "Kounov",
        kod: 576395,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51792,
        latitude: 50.298871,
        longitude: 16.258155,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:3:1. V modrém pruhu v žerďové polovině bílá ovce kráčející k vlajícímu okraji, ve vlající polovině skákající bílý vlk se žlutými zuby a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý listnatý strom vyrůstající ze zlaté paty s modrým vlnitým břevnem. Vpravo doleva kráčející stříbrná ovce, vlevo skákající stříbrný vlk se zlatými zuby a červeným jazykem."
    },
    {
        obec: "Kounice",
        kod: 537357,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28915,
        latitude: 50.10777,
        longitude: 14.856029,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Kounice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu půl bílé orlice s červeným jazykem, žlutou zbrojí a jetelovitě zakončeným perizoniem, přiléhající k červenému pruhu. Ve střední části listu v červeném pruhu vyrůstají z dolního okraje tři žluté odkloněné obilné klasy a jeden list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo vyniká půl stříbrné orlice s červeným jazykem, zlatou zbrojí a jetelovitě zakončeným perizoniem, vlevo tři vyrůstající zlaté odkloněné klasy ječmene a jeden list."
    },
    {
        obec: "Kounov",
        kod: 541907,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27006,
        latitude: 50.212979,
        longitude: 13.675205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Coats_of_arms_Kounov.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 2:5. V zeleném pruhu devět (3,3,3) bílých kamenů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou se zelenou chmelovou větévkou se čtyřmi šišticemi a třemi listy, prostřední větší, devět (3,3,3) stříbrným kamenů."
    },
    {
        obec: "Koupě",
        kod: 529681,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.514885,
        longitude: 13.918493
    },
    {
        obec: "Kouřim",
        kod: 533424,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28161,
        latitude: 50.003158,
        longitude: 14.977137,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Kou%C5%99im-znak.gif"
    },
    {
        obec: "Kout na Šumavě",
        kod: 553824,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34502,
        latitude: 49.402659,
        longitude: 13.002193,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Kout_na_%C5%A0umav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a červený, v poměru 1 : 2 : 13. V modrém pruhu tři žluté vlčí udice pod sebou, ve vlajícím pruhu ohlížející se bílá saň kráčející k vlajícímu okraji listu, s krkem zezadu a shora kosmo probodnutým žlutým kopím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se třemi zlatými vlčími udicemi vedle sebe doleva kráčející ohlížející se stříbrná saň s krkem zezadu a shora kosmo probodnutým zlatým kopím."
    },
    {
        obec: "Kouty",
        kod: 590908,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67508,
        latitude: 49.314835,
        longitude: 15.792795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Kouty_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s horním žerďovým a dolním vlajícím bílým trojúhelníkem s odvěsnami rovnými polovině šířky listu. V zeleném poli šikmo žlutá berla s bílým sudariem závitem k dolnímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě šikmá zlatá berla, závitem doleva, se stříbrným sudariem, provázená nahoře baňatou stříbrnou lahví se zlatou zátkou, dole vztyčeným stříbrným srpem se zlatou rukojetí."
    },
    {
        obec: "Kouty",
        kod: 534943,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.193218,
        longitude: 15.148855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Kouty_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, bílý, čtyři vlnité střídavě modré a bílé a zvlněný modrý, v poměru 4 : 3 : 3 : 3 : 3 : 28. Ve vlajícím pruhu žlutá, dolů prohnutá ryba. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kouty",
        kod: 548189,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.648891,
        longitude: 15.293094,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Znak_obce_Kouty_%28Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 5 : 3. V žerďové části vyniká z bílého pruhu žlutá kvádrovaná věž s cimbuřím se třemi zuby, pod ní v bílém pruhu vztyčená pemrlice toporem zezadu prostrčeným položenou kypřicí, obojí zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se zlatými kouty zlatá kvádrovaná věž s cimbuřím na stříbrném návrší, v něm vztyčená pemrlice toporem zezadu prostrčeným položenou kypřicí, obojí zelené. Věž provázena z okrajů štítu vyrůstajícími stříbrnými lvími tlapami se zlatou zbrojí."
    },
    {
        obec: "Kovalovice",
        kod: 583227,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66406,
        latitude: 49.204721,
        longitude: 16.81914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Kovalovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 1:1:2. V červeném pruhu mezi dvěma podkovami kosmo meč hrotem nahoru podložený šikmo klíčem zuby nahoru a k vlajícímu okraji, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře stříbrný racek s červenou zbrojí, dole kosmo vztyčený meč, šikmo podložený vztyčeným gotickým klíčem, po stranách dvě podkovy, vše zlaté."
    },
    {
        obec: "Koválovice-Osíčany",
        kod: 589641,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79829,
        latitude: 49.284523,
        longitude: 17.171314,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Kov%C3%A1lovice-Os%C3%AD%C4%8Dany_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list; horní žerďové modré pole s bílým vykračujícím a ohlížejícím se beránkem se žlutou zbrojí držícím za sebou šikmo žlutý procesní tlapatý kříž. Dolní žerďové bílé pole se třemi červenými plameny z žerďového okraje. Horní vlající červené pole s kosmo letícím žlutým ptákem s bílým vinným hroznem v zobáku, dolní vlající modré pole s hlavicí žluté berly závitem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Čtvrcený štít, v 1. modrém poli stříbrný vykračující a ohlížející se beránek se zlatou zbrojí držící za sebou šikmo zlatý procesní tlapatý kříž, ve 2. červeném poli kosmo letící zlatý pták se stříbrným vinným hroznem v zobáku, ve 3. stříbrném poli tři zprava vynikající červené plameny, ve 4. modrém poli hlavice zlaté berly."
    },
    {
        obec: "Kovač",
        kod: 548944,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.39615,
        longitude: 15.470916,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Kova%C4%8D_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, modrý a bílý, v poměru 7 : 1 : 1 : 1. V žerďové polovině zeleného pruhu vztyčený žlutý jasanový list v bílé obrácené podkově. Druhý a třetí pruh oboustranně vlnkovitý.",
        coatOfArmsDescription: "V zeleném štítě vztyčený zlatý jasanový list v obrácené stříbrné podkově nad stříbrnou vlnkovitou patou s modrým vlnkovitým břevnem."
    },
    {
        obec: "Kováň",
        kod: 571971,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29425,
        latitude: 50.423409,
        longitude: 14.777875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Kov%C3%A1%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký třetinu délky listu, a modré pole. Na jejich styku černo-bíle polcené kamenické kleště s hroty u horního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vztyčené, černo-stříbrně polcené kamenické kleště."
    },
    {
        obec: "Kovanec",
        kod: 572012,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29426,
        latitude: 50.417808,
        longitude: 14.77674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Kovanec_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3 : 1 : 1. V horním pruhu kosmo kladivo šikmo přeložené žezlem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno-modře vlnitě dělenou cimbuřovou patou kosmo kladivo šikmo přeložené žezlem, obojí zlaté."
    },
    {
        obec: "Kovanice",
        kod: 537373,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.169567,
        longitude: 15.070901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Coats_of_arms_Kovanice.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě modré a bílé, v poměru 1:2:2:1. V bílém žerďovém pruhu černé kovářské kladivo na žluté násadě přeložené černou podkovou provázenou nahoře dvěma červenými plamínky. V modrém vlajícím pruhu žluté lekno se dvěma listy pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo černé kovářské kladivo na zlaté násadě přeložené černou podkovou provázenou nahoře dvěma červenými plamínky, vlevo zlaté lekno se dvěma listy pod sebou."
    },
    {
        obec: "Kovářov",
        kod: 549517,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39855,
        latitude: 49.517705,
        longitude: 14.278199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Kov%C3%A1%C5%99ov_znak.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou k dolnímu okraji obrácenou podkovou, ze které vyrůstají žluté obilné klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dolů obrácená stříbrná podkova, z ní vyrůstají zlaté obilné klasy."
    },
    {
        obec: "Kovářská",
        kod: 563137,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43186,
        latitude: 50.438324,
        longitude: 13.053997,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Coat_of_arms_of_Kov%C3%A1%C5%99sk%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří žluté karé nad stejně velkým zeleným polem a dva vodorovné pruhy, zelený a žlutý. V karé černá, bíle spárovaná kvádrovaná hradba, v ní žlutý štítek s mlátkem překříženým želízkem, obojí černé. Z hradby vyniká zelený vrch, na něm černá, do horního okraje štítu šedě dýmající, vysoká pec s bílým topeništěm, provázená dvěma zelenými jehličnatými stromy s hnědými kmeny vyrůstajícími z cimbuří. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kovčín",
        kod: 578177,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.415936,
        longitude: 13.606717,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Coat_of_arms_of_Kov%C4%8D%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 6 : 7. V modrém pruhu tři hvězdy, mezi nimi na styku pruhů dvě hvězdy, všechny žluté šesticípé. V zeleném pruhu bílý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně dělený štít. Nahoře pět (3, 2) hvězd, z nichž dvě přes dělící linii, dole stříbrný zvon."
    },
    {
        obec: "Kozárov",
        kod: 581771,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.443935,
        longitude: 16.458762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Coat_of_arms_of_Koz%C3%A1rov.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed bílý snop lnu přeložený modrým květem lnu se žlutým středem, provázený lilií a šestiramenným kolem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný snop lnu přeložený modrým květem lnu se zlatým středem, provázený lilií a šestiramenným kolem, obojí zlaté."
    },
    {
        obec: "Kozárovice",
        kod: 540536,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26284,
        latitude: 49.555628,
        longitude: 14.106587,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Coats_of_arms_Koz%C3%A1rovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 2:1:1. V modrém pruhu kosmo kamenické kladivo šikmo podložené dlátem, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším s kosmým kamenickým kladivem šikmo podloženým dlátem, obojí zlaté, stříbrná kozlí hlava s krkem se zlatou zbrojí."
    },
    {
        obec: "Kozlany",
        kod: 550108,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68341,
        latitude: 49.20417,
        longitude: 17.035316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Kozlany_%28Vy%C5%A1kov_District%29_CoA_2021.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem vycházejícím z první šestiny dolního okraje do první šestiny vlajícího okraje listu, na zelené žerďové a červené vlající pole. Z pruhu do zeleného pole vyskakuje bílý kozel se žlutou zbrojí, v červeném poli šikmo žlutá putna s vytékající bílou vodou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně sníženým zlatým šikmým břevnem dělený štít. Nahoře vyskakující stříbrný kozel se zlatou zbrojí, dole šikmo zlatá putna s vytékající stříbrnou vodou."
    },
    {
        obec: "Kozlany",
        kod: 590916,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67502,
        latitude: 49.179832,
        longitude: 16.061293
    },
    {
        obec: "Kozlov",
        kod: 568899,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.734658,
        longitude: 15.293073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Kozlov_%28Havl%C3%AD%C4%8Dk%C5%AFv_Brod_District%29_CoA.png",
        coatOfArmsFlagDescription: "Zelený list. Z dolního okraje vyniká bílá kostelní věž se střechou zakončenou lucernou a bání s makovicí a křížkem, nahoře provázenou do oblouku čtyřmi vztyčenými žlutými lipovými listy. Ve věži vztyčený zelený lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyniká stříbrná kostelní věž se střechou zakončenou lucernou a bání s makovicí a křížkem a provázenou čtyřmi vztyčenými zlatými lipovými listy do oblouku. Ve věži vztyčený zelený lipový list."
    },
    {
        obec: "Kozlov",
        kod: 587427,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58821,
        latitude: 49.413143,
        longitude: 15.700567,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Kozlov-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:4:1. Uprostřed žlutý gotický dvojklíč, jehož držadlem je provlečen bílý meč se žlutým jílcem hrotem dolů. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý gotický dvojklíč, jehož držadlem je provlečen postavený stříbrný meč se zlatým jílcem provázený nahoře dvěma zlatými růžemi s prázdnými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Kozlov",
        kod: 500135,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77200,
        latitude: 49.603626,
        longitude: 17.535662,
        coatOfArmsFlagDescription: "Zelený list se svislým bílým vlnitým pruhem širokým dvanáctinu délky listu. V žerďovém poli lilie a ve vlajícím kalich, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zúžené stříbrné vlnité břevno, nahoře lilie a dole kalich, obojí zlaté."
    },
    {
        obec: "Kozlov",
        kod: 595888,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.379245,
        longitude: 16.078773
    },
    {
        obec: "Kozlovice",
        kod: 598321,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73947,
        latitude: 49.590494,
        longitude: 18.256672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Kozlovice_%28Frydek-Mistek%29_CoA_CZ.jpg",
        coatOfArmsDescription: "Štít dělen modře a červeně, v horní části z dělící čáry vyrůstající stříbrný kozel se zlatou zbrojí, držící zlatou valašku; v dolní části štítu tři stříbrné hroty."
    },
    {
        obec: "Kozlovice",
        kod: 540200,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.465098,
        longitude: 13.582934
    },
    {
        obec: "Kozly",
        kod: 546232,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47001,
        latitude: 50.655449,
        longitude: 14.45693,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Kozly_CL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červeno-bílo polcený, zelený a bílo-červeně polcený, v poměru 2 : 3 : 2. Do zeleného pruhu vyniká žlutý křížkový kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený kozel vyskakující ze zeleného vrchu, v něm vyrůstající zlatý křížkový kříž."
    },
    {
        obec: "Kozly",
        kod: 530557,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.45686,
        longitude: 13.784625
    },
    {
        obec: "Kozmice",
        kod: 547182,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74711,
        latitude: 49.9129,
        longitude: 18.15595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Kozmice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, modrý a bílý v poměru 1:2:1. V modrém poli zkřížené žluté liliové hůlky, dole překryté bílým mlýnským kolem s vodorovnou černou kypřicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V dolní polovině modrého štítu stříbrné mlýnské kolo s černou položenou kypřicí, podložené dvěma zlatými zkříženými liliovými hůlkami."
    },
    {
        obec: "Kozmice",
        kod: 529940,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.824883,
        longitude: 14.796183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Kozmice_%28Bene%C5%A1ov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu zkřížené žluté cepy s bílým kováním nad žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zkřížené zlaté cepy se stříbrným kováním nad zlatou osmihrotou hvězdou."
    },
    {
        obec: "Kozojedy",
        kod: 572136,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.316531,
        longitude: 15.374882,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Kozojedy_%28Ji%C4%8D%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a zelené pole. V horní třetině modrého pruhu knížecí čepice, v zeleném poli bílá hlava kozla s krkem se žlutými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně zvýšeně děleném štítě nahoře knížecí čepice, dole stříbrná hlava kozla s krkem se zlatými rohy."
    },
    {
        obec: "Kozojedy",
        kod: 559041,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.931343,
        longitude: 13.542516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Kozojedy_Plze%C5%88_znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílou parmou nad červeným rakem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kozojedy",
        kod: 533432,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.996652,
        longitude: 14.814366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Kozojedy_PH_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý s knížecí korunou v horní části pruhu, žlutý a červený, v poměru 2 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý kráčející kozel nad položenou stříbrnou šavlí se zlatým jílcem, vlevo nahoře provázený knížecí korunou."
    },
    {
        obec: "Kozojedy",
        kod: 565385,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.255389,
        longitude: 13.815687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Kozojedy_%28okres_Rakovn%C3%ADk%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. V zeleném bílý kozel ve skoku se žlutou zbrojí, v bílém nad červeným jablkem se stopkou a dvěma odkloněnými zelenými listy stonek chmele, nahoře s listem, pod ním se dvěma odkloněnými dvojicemi šištic, dole se dvěma úponky, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo stříbrný kozel ve skoku se zlatou zbrojí, vlevo nad červeným jablkem se stopkou a dvěma odkloněnými zelenými listy stonek chmele, nahoře s listem, pod ním se dvěma odkloněnými dvojicemi šištic, dole se dvěma úponky, vše zelené."
    },
    {
        obec: "Kozojídky",
        kod: 586285,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69663,
        latitude: 48.918744,
        longitude: 17.399733,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Kozoj%C3%ADdky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "V zeleném listu ve žlutém věnci bílý kráčející kozel. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ve zlatém věnci stříbrný kráčející kozel."
    },
    {
        obec: "Kozolupy",
        kod: 559059,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33032,
        latitude: 49.763902,
        longitude: 13.252214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Kozolupy_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se dvěma zkříženými žlutými ječmennými klasy se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva zkřížené zlaté ječmenné klasy se dvěma listy."
    },
    {
        obec: "Kozomín",
        kod: 571792,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27745,
        latitude: 50.236919,
        longitude: 14.371215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Kozom%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. V modrém pruhu nad žlutým kaprem žlutý sloup s černou šesticípou hvězdou. Ze sloupu vyskakují do stran dva bílí kozli se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým kaprem zlatý sloup s černou hvězdou. Ze sloupu vyskakují do stran dva stříbrní kozli se zlatou zbrojí."
    },
    {
        obec: "Kožichovice",
        kod: 545309,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.199963,
        longitude: 15.922024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Ko%C5%BEichovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 11:8:11. V bílých pruzích po jedné červené růži se žlutým semeníkem a zelenými kališními lístky, na červeném pruhu horní polovina žluté berly závitem k žerdi s bílým sudariem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený kůl, v něm zlatá doprava obrácená berla se stříbrným sudariem, provázený po každé straně dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Kožlí",
        kod: 561550,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.515875,
        longitude: 14.146759
    },
    {
        obec: "Kožlí",
        kod: 568902,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58293,
        latitude: 49.670414,
        longitude: 15.253313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Znak_obce_Ko%C5%BEl%C3%AD.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílou hlavou kozla s krkem a bílý černě mřížovaný. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrná hlava kozla s krkem, levé pole černě mřížované."
    },
    {
        obec: "Kožušany-Tážaly",
        kod: 503304,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78375,
        latitude: 49.537792,
        longitude: 17.253626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Ko%C5%BEu%C5%A1any-T%C3%A1%C5%BEaly_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený svislým středovým žlutým pruhem širokým jednu desetinu šířky listu, v zeleném žerďovém poli pod sebou dvě bílé růže se žlutými semeníky a kališními lístky, vlající pole tvoří polovina bílo-černého štenýře s polovinou liliového kříže opačných barev přiléhajícího ke žlutému pruhu, s rameny širokými jednu desetinu šířky listu a dlouhými jednu polovinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít je dělen zlatým zúženým břevnem, nahoře v zeleném poli zlatý pádící jelen provázený v rozích štítu dvěma stříbrnými růžemi se zlatými semeníky a kališními lístky. V dolní části štítu na polovičním černo-stříbrném štenýři dolní polovina liliového kříže opačných barev, dosahující k okrajům štítu."
    },
    {
        obec: "Kožlany",
        kod: 559067,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33144,
        latitude: 49.993998,
        longitude: 13.541176,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Ko%C5%BElany_znak.png",
        coatOfArmsFlagDescription: "Šikmo červeně-bílo dělený list. V bílém poli dva kosmé modré pruhy vycházející z druhé čtvrtiny úhlopříčky do druhé poloviny dolního okraje a ze čtvrté čtvrtiny úhlopříčky do první poloviny vlajícího okraje listu. Z dolního modrého pruhu do červeného pole vyrůstá kozel s rohy otočenými k žerdi držící v železných rukavicích válečnou sekeru, vše bílé. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kožušice",
        kod: 593184,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.15519,
        longitude: 17.18489,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Ko%C5%BEu%C5%A1ice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, žlutý, bílý a zelený 1:2:2:1. Ve žlutém polovina černé orlice s červeným jazykem přiléhající k bílému pruhu se třemi červenými kosmými pruhy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod dvěma zkříženými kosami pravou zlatou nad levou stříbrnou, zlato-stříbrně polcené jablko, vpravo půl černé orlice a vlevo tři kosmá červená břevna."
    },
    {
        obec: "Krabčice",
        kod: 565067,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41187,
        latitude: 50.405474,
        longitude: 14.301425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Krabcice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý klín s vrcholem na vlajícím okraji a dvě trojúhelníková pole, horní modré a dolní červené. Na žerďovém okraji zelené půlkruhové pole o průměru 3/4 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít se zlatým do poloviny sníženým hrotem, v něm zelené návrší. Vpravo obrněné rameno držící praporec, vlevo saň, vše zlaté."
    },
    {
        obec: "Kraborovice",
        kod: 548618,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.800604,
        longitude: 15.571977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Kraborovice_znak.jpeg",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové pole červené se dvěma žlutými korunami vedle sebe, dolní žerďové a horní vlající pole žluté, dolní vlající pole modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli kosmo postavený meč převýšený dvěma korunami vedle sebe, vše zlaté, levé pole zlato-modře dělené, nahoře vyrůstající zelená lípa s černým kmenem, dole tři (2, 1) zlaté hvězdy."
    },
    {
        obec: "Krahulčí",
        kod: 587435,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.181877,
        longitude: 15.414923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Krahul%C4%8D%C3%AD_Coa.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým červeně rozkřídleným krahujcem se žlutou zbrojí a s bílým kamenným klínem v levém křídle. Nad krahujcem pět šesticípých žlutých hvězd vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod pěti zlatými hvězdami vedle sebe stříbrný červeně rozkřídlený krahujec se zlatou zbrojí a se stříbrným kamenným klínem v levém křídle."
    },
    {
        obec: "Krahulov",
        kod: 550710,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.223007,
        longitude: 15.808241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Krahulov-znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma odvrácenými svislými gotickými klíči zuby nahoru, dole propojenými, žerďovým žlutým a vlajícím bílým, přeloženými vodorovným bílo-žlutě polceným pruhem širokým jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva vztyčené, dole propojené gotické klíče s odvrácenými zuby, pravý zlatý a levý stříbrný, přeložené stříbrno-zlatě polceným roztrženým řetězem."
    },
    {
        obec: "Krajková",
        kod: 560456,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35709,
        latitude: 50.216079,
        longitude: 12.534192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Krajkov%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, z vlajícího okraje vyniká pravé obrněné rameno držící v rukavici hornická kladívka na hnědých topůrkách. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Krajníčko",
        kod: 536342,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.147647,
        longitude: 14.028846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/CoA_of_Krajn%C3%AD%C4%8Dko.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý s červenou růží se žlutým semeníkem a zelenými kališními lístky a modrý se žlutou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným kopcem s červenou růží se zlatým semeníkem a zelenými kališními lístky pět zlatých hvězd do oblouku."
    },
    {
        obec: "Krakov",
        kod: 565351,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.037468,
        longitude: 13.647573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/CoA_of_Krakov.svg",
        coatOfArmsFlagDescription: "Červený list s vodorovným bílo-červeně šachovaným (3x16) pruhem širokým dvě sedminy šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě třemi řadami stříbrno-červeně šachované břevno."
    },
    {
        obec: "Krakovany",
        kod: 533441,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28127,
        latitude: 50.066166,
        longitude: 15.36897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Krakovany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, zelený a bílý v poměru 1:3:1. V zeleném pruhu žlutá liška ve skoku s červenou zbrojí a bílým kolčím helmem na hlavě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá liška ve skoku s červenou zbrojí a stříbrným kolčím helmem na hlavě, držící v pravé přední tlapě stříbrný lipový dvojlist na jednom stonku."
    },
    {
        obec: "Krakovec",
        kod: 565369,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.018944,
        longitude: 13.638347,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/CoA_of_Krakovec.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým kalichem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kalich."
    },
    {
        obec: "Kralice na Hané",
        kod: 589659,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79812,
        latitude: 49.463062,
        longitude: 17.180583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Kralice_na_Hane_CZ_CoA.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. Uprostřed bílá orlice se žlutou zbrojí. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě stříbrná orlice se zlatou zbrojí."
    },
    {
        obec: "Králíky",
        kod: 570192,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.259542,
        longitude: 15.53668
    },
    {
        obec: "Kralice nad Oslavou",
        kod: 590941,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67573,
        latitude: 49.199373,
        longitude: 16.201664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Kralice_nad_Oslavou_CoA_CZ.png",
        coatOfArmsFlagDescription: "Červený list se třemi svislými pruhy, bílým, žlutým a černým, vycházejícími ze čtvrté, páté a šesté dvanáctiny dolního okraje listu a dosahujícími do pěti sedmin šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod černou hlavou se dvěma zlatými sedícími přivrácenými králíky stříbrný tiskařský lis."
    },
    {
        obec: "Králova Lhota",
        kod: 549525,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.495809,
        longitude: 14.110652
    },
    {
        obec: "Králíky",
        kod: 580481,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56169,
        latitude: 50.083939,
        longitude: 16.760646,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Kr%C3%A1l%C3%ADky.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy červený, žlutý a červený. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Králova Lhota",
        kod: 576409,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.294662,
        longitude: 15.998922,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Kr%C3%A1lova_Lhota_%28Rychnov_nad_Kn%C4%9B%C5%BEnou%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě červené a bílé, v poměru 5:1:1:1. V žerďové polovině horního červeného pruhu žlutá královská koruna s bílým vyložením. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod sebou zlatá královská koruna se stříbrným vyložením, stříbrná kolébka a stříbrná poštovní trubka nátrubkem doprava."
    },
    {
        obec: "Královec",
        kod: 530808,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54203,
        latitude: 50.676859,
        longitude: 15.97381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Kr%C3%A1lovec_-_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma modrými rovnostrannými trojúhelníky na dolním okraji. Mezi nimi žlutá okřídlená Merkurova hůl. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma sníženými stříbrnými hroty zlatá Merkurova okřídlená hůl."
    },
    {
        obec: "Kralovice",
        kod: 559075,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.982024,
        longitude: 13.487584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Kralovice_CoA_CZ.jpg"
    },
    {
        obec: "Královice",
        kod: 535109,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27401,
        latitude: 50.263129,
        longitude: 14.055907,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Kralovice_KL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a modrý. V červeném pruhu žlutá šesticípá hvězda mezi dvěma bílými růžemi se žlutými semeníky, v modrém pruhu pět žlutých šesticípých hvězd. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělen zlatým mostem se dvěma oblouky, provázeným nahoře v červeném poli zlatou hvězdou mezi stříbrnými růžemi se zlatými semeníky a dole v modrém poli pěti (2,2,1) zlatými hvězdami."
    },
    {
        obec: "Královské Poříčí",
        kod: 560464,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.19412,
        longitude: 12.67954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Coat_of_arms_of_Kr%C3%A1lovsk%C3%A9_Po%C5%99%C3%AD%C4%8D%C3%AD.svg",
        coatOfArmsFlagDescription: "List praporu o pruzích v barvě červené, žluté a černé v poměru 5:1:2; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "V červenočerně děleném štítě nahoře zlatá koruna, dole stříbrná zkřížená hornická kladívka se zlatými topůrky."
    },
    {
        obec: "Kralupy nad Vltavou",
        kod: 534951,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27801,
        latitude: 50.241745,
        longitude: 14.310745,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Kralupy_nad_Vltavou_znak.png",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů střídavě modrých a bílých v poměru 2:1:1:4:1:1:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Králův Dvůr",
        kod: 533203,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26701,
        latitude: 49.949911,
        longitude: 14.034561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Znak_obce_Kr%C3%A1l%C5%AFv_Dv%C5%AFr_-_O%C5%98.JPG",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý zubatý kvádrovaný se šesti obdélníkovými zuby a pěti polovičními mezerami. V červeném pruhu žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou kvádrovanou zdí s cimbuřím zlatá koruna."
    },
    {
        obec: "Kramolín",
        kod: 590959,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67577,
        latitude: 49.134304,
        longitude: 16.132027,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Kramol%C3%ADn_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým vlnitým kosmým pruhem o šířce jedné pětiny délky listu. V horní vlající části kosmo položená bílá sekera se žlutým topůrkem, podložená bílou kramlí, v dolní žerďové části dvakrát lomený žlutý blesk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné kosmé vlnité břevno provázené nahoře stříbrnou sekerou se zlatým topůrkem, křížem podloženou stříbrnou kramlí, dole zlatým, dvakrát lomeným bleskem."
    },
    {
        obec: "Kramolín",
        kod: 557943,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.446183,
        longitude: 13.577065
    },
    {
        obec: "Kramolna",
        kod: 574546,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54701,
        latitude: 50.420798,
        longitude: 16.13492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Kramolna_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou krokví vycházející z první šestiny horního a dolního okraje listu s vrcholem ve druhé třetině délky listu. V žerďové části hvězda, ve vlající části dvě hvězdy pod sebou, všechny šesticípé, bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě průčelí kaple s prázdnými dveřmi a oknem, lucernou zakončenou křížkem, provázené třemi (2, 1) hvězdami, vše stříbrné."
    },
    {
        obec: "Kraselov",
        kod: 551261,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.227936,
        longitude: 13.804051
    },
    {
        obec: "Krásensko",
        kod: 593192,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68304,
        latitude: 49.364634,
        longitude: 16.829977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Znak_Kr%C3%A1sensko.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a osm svislých pruhů střídavě žlutých a zelených a přeložených středovým modrým vodorovným pruhem širokým jednu dvacetinu šířky listu. V červeném pruhu tři bílé žerďové klíny s vrcholy na žerďovém okraji prvního žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se třemi stříbrnými klíny a v nich třemi modrými knoflíky, modrým zúženým kůlem polcený štít, v pravém, sedmkrát stříbrno-zeleně děleném poli zlatý obilný klas, v levém zlatém poli zelená palmová ratolest."
    },
    {
        obec: "Krasíkov",
        kod: 580490,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.855681,
        longitude: 16.695801,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Kras%C3%ADkov_UO_coA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. Do bílého pruhu vyrůstá v žerďové části lipová, ve vlající části dubová ratolest, obě zelené. Mezi nimi na zeleném pruhu červený oheň, nad ním šikmo hnědá putna s vytékající modrou vodou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelené dvojvrší, mezi vrchy červený oheň. Z vrchů vyrůstají vpravo lipová a vlevo dubová ratolest, obojí zelené. Nahoře mezi nimi šikmo putna přirozené barvy s vytékající vodou."
    },
    {
        obec: "Krasíkovice",
        kod: 561371,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.461735,
        longitude: 15.228765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Kras%C3%ADkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou šesticípou hvězdou, v ní zelený leknínový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná hvězda, v ní zelený leknínový trojlist."
    },
    {
        obec: "Kraslice",
        kod: 560472,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.323835,
        longitude: 12.517588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Kraslice_znak.svg",
        coatOfArmsFlagDescription: "Žerďovou a střední část listu tvoří modré čtvercové pole s bílým majuskulním písmenem 'G', vlající část je bílá. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Krásná",
        kod: 538795,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35201,
        latitude: 50.234889,
        longitude: 12.168117,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Krasna_CH_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, horní šikmo červeno-bíle dělený a dolní kosmo bílo-červeně dělený. V horním rohu listu bílý květ s dvaceti čtyřmi okvětními lístky a žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělený, horní pole červeno-stříbrně šikmo děleno, vpravo stříbrný květ s dvaceti čtyřmi okvětními lístky a zlatým středem, dolní pole stříbrno-červeně kosmo děleno."
    },
    {
        obec: "Krásná",
        kod: 549673,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73904,
        latitude: 49.582088,
        longitude: 18.481485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Krasna_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a žlutý v poměru 4:1. Uprostřed tři bílé jedle, prostřední vyšší vyrůstající ze žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zlaté barvy tři stříbrné jedle, prostřední vyšší."
    },
    {
        obec: "Krásná Hora",
        kod: 568929,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58234,
        latitude: 49.602787,
        longitude: 15.470441,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Kr%C3%A1sn%C3%A1_Hora_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílo-zeleně dělený v poměru 3:1 a zeleno-bíle dělený v poměru 3:1. Do bílého žerďového pole vyrůstá zelený jehličnatý strom s černým kmenem. V zeleném vlajícím poli bílý latinský kříž s rameny širokými jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo na zelené hoře zelený jehličnatý strom s černým kmenem, vlevo nad vlnitou patou převázaný rákosový latinský kříž, obojí stříbrné."
    },
    {
        obec: "Krásná Hora nad Vltavou",
        kod: 540552,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26256,
        latitude: 49.604711,
        longitude: 14.277524,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Kr%C3%A1sn%C3%A1_Hora_n._Vltavou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3 : 1. V žerďové části ze zeleného pruhu vyniká bílá kvádrovaná věž s cimbuřím, černým oknem a prázdnou gotickou branou s vytaženou žlutou mříží. V bráně žlutá hornická kladívka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku stříbrná kvádrovaná věž s cimbuřím, černým oknem a prázdnou gotickou branou s vytaženou zlatou mříží. V bráně zlatá hornická kladívka."
    },
    {
        obec: "Krásná Lípa",
        kod: 562611,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40746,
        latitude: 50.913136,
        longitude: 14.510101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Kr%C3%A1sn%C3%A1_L%C3%ADpa_znak.png",
        coatOfArmsFlagDescription: "Červeno-žlutě kosmo dělený list. Ve vlajícím žlutém poli vykořeněná lípa s hnědým kmenem a zelenými listy. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Krásné",
        kod: 595896,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.677952,
        longitude: 16.145108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Kr%C3%A1sn%C3%A9_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený, v poměru 1 : 2. V červeném pruhu žlutá krokev široká třetinu šířky listu vycházející z dolního rohu a cípu s vrcholem na dolním okraji žlutého pruhu, v ní knížecí koruna. Pole pod krokví modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře zlatou krokví dělený štít se zlatou hlavou, v krokvi knížecí koruna."
    },
    {
        obec: "Krásné",
        kod: 571652,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.821656,
        longitude: 15.745623,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Kr%C3%A1sn%C3%A9_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 6 : 1. V modrém pruhu zkřížené žluté berly s bílými sudarii se žlutým třepením, mezi jejich hlavicemi žlutý zvon se závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítu vyrůstají ze zlatého návrší čtyři bledule jarní přirozené barvy, nad nimi zkřížené zlaté berly se stříbrnými sudarii se zlatým třepením, mezi jejich hlavicemi zlatý zvon se závěsem."
    },
    {
        obec: "Krásná Ves",
        kod: 536164,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29425,
        latitude: 50.42478,
        longitude: 14.792241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Kr%C3%A1sn%C3%A1_Ves_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, červený a černý. V horní polovině bílého pruhu černý ciferník s římskými číslicemi a ručičkami, obojí bílé. Z dolního okraje bílého pruhu vyrůstají tři černé obilné klasy, prostřední vyšší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém stříbrném poli černý ciferník s římskými číslicemi a ručičkami, obojí stříbrné, nad třemi vyrůstajícími černými obilnými klasy, prostředním vyšším. Levé pole červeno-černě dělené."
    },
    {
        obec: "Krásné Údolí",
        kod: 555304,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36401,
        latitude: 50.072448,
        longitude: 12.921351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/ZNAK_M%C4%9ASTA_KR%C3%81SN%C3%89_%C3%9ADOL%C3%8D.jpg",
        coatOfArmsFlagDescription: "Červený list s rytířem na bílém koni ve skoku se žlutou zbrojí a hnědým postrojem. Rytíř drží v pravici hnědé kopí s dvoucípým praporcem, v levici štít, na přilbě má klenot v podobě šestirohé desky s třapci. Praporec, štít i klenot žluté s černým ondřejským křížem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Krásněves",
        kod: 595900,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.44451,
        longitude: 15.981262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Kr%C3%A1sn%C4%9Bves_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle čtvrcený list, uprostřed červené čtvercové pole široké jednu třetinu délky listu s kosmým žlutým klíčem zuby dolů a k vlajícímu okraji, podloženým šikmým bílým klíčem zuby dolů a k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně čtvrcený štít, v 1. poli dva zkřížené postavené klíče zuby ven kosmý zlatý, šikmý stříbrný, ve 2. poli od středu půl zelené lípy, přes 3. a 4. pole liliový trojhran vpravo zelený, vlevo stříbrný."
    },
    {
        obec: "Krásno",
        kod: 538337,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35731,
        latitude: 50.109287,
        longitude: 12.786342,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Krasno_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, zelený, bílý a červený, v poměru 1 : 1 : 6 : 1 : 1. V zeleném pruhu dvě zkřížené, bílé oděné a v lokti ohnuté paže držící zkřížená žlutá topůrka kosmého želízka a šikmého mlátku, obojí bílé, přeložená žlutými rýžovacími hráběmi s hrabištěm přeloženým pažemi. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Krásný Les",
        kod: 578045,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.344955,
        longitude: 13.002857
    },
    {
        obec: "Krásný Dvůr",
        kod: 566306,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43972,
        latitude: 50.254271,
        longitude: 13.367878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Kr%C3%A1sn%C3%BD_Dv%C5%AFr_znak.jpg",
        coatOfArmsFlagDescription: "Na horním a dolním okraji listu žluté vodorovné pruhy široké jednu osminu šířky listu, mezi nimi červená žerďová část a vlající část tvořená sedmi vodorovnými pruhy střídavě modrými a bílými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě, vlevo se třemi stříbrnými břevny, lilie, převýšená korunou s červeným vyložením, o pěti viditelných listech, obojí zlaté."
    },
    {
        obec: "Krásný Les",
        kod: 564168,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.940324,
        longitude: 15.127667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Krasny_Les_LB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 7 : 11. V žerďové polovině žlutého pruhu červený položený jelení paroh, v žerďové polovině zeleného pruhu zkřížené žluté obilné klasy, každý s odkloněným zalomeným listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - zeleně smrkovým řezem děleném štítě nahoře položený červený jelení paroh, dole zkřížené zlaté obilné klasy, každý s odkloněným zalomeným listem."
    },
    {
        obec: "Krasonice",
        kod: 587443,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58864,
        latitude: 49.11513,
        longitude: 15.616146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Krasonice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý, uprostřed žlutá vavřínová ratolest a bílý jelení paroh, obojí přivrácené a dole překřížené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě zlatá vavřínová ratolest a stříbrný jelení paroh, obojí přivrácené a dole překřížené."
    },
    {
        obec: "Krasov",
        kod: 597511,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79401,
        latitude: 50.091869,
        longitude: 17.546206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Krasov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, bílý a zelený, v poměru 1:3:1. V bílém pruhu zelený listnatý strom s hnědým kmenem,v jehož koruně sedí dva černí, nahoře k vlajícímu okraji a ve vlající části na dolní větvi k žerdi hledící ptáci se žlutou zbrojí, provázený dole v žerďové části pruhu černou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě listnatý strom přirozené barvy, v jehož koruně sedí nahoře a vlevo dva přivrácení černí ptáci se zlatou zbrojí. Vpravo dole černá koruna."
    },
    {
        obec: "Krasová",
        kod: 581780,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.361476,
        longitude: 16.768035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Krasov%C3%A1_znak.svg",
        coatOfArmsFlagDescription: "List dělený žlutou žerďovou vidlicí na červený žerďový klín a horní a dolní modré pole. Vidlice široká pětinu šířky listu vychází z horního a dolního okraje listu, vrchol klínu sahá do třetiny délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zlatém návrší zlatá jabloň s pěti (2, 3) červenými jablky, kmen přeložen stříbrným kosmým štítkem, v něm na modrém kosmém trojvrší kráčející červený korunovaný dvouocasý lev se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Krašovice",
        kod: 559083,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33013,
        latitude: 49.872448,
        longitude: 13.30535,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Znak_obce_Kra%C5%A1ovice.png",
        coatOfArmsFlagDescription: "Modro-žlutě kosmo dělený list. V modrém poli kosmo žlutý zlomený šíp hrotem k dolnímu okraji listu a s opeřením k dolnímu cípu, hrot a opeření bílé. Ve žlutém poli černé paroží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře kosmo děleném štítě nahoře černé jelení paroží, dole dolů zlomený kosmý zlatý šíp se stříbrným hrotem a opeřením."
    },
    {
        obec: "Krašlovice",
        kod: 536806,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.166695,
        longitude: 14.132576
    },
    {
        obec: "Krátká Ves",
        kod: 568937,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.625837,
        longitude: 15.684616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Kr%C3%A1tk%C3%A1_Ves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 5 : 1. Ze čtvrté až sedmé desetiny horního okraje bílého pruhu vyniká vydutý hrot zakončený horní polovinou lilie. Po jeho stranách v červeném pruhu dvě (1, 1) postavené bílé ostrve, v bílém pruhu modrá triquetra. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě snížený vydutý hrot zakončený horní polovinou lilie, provázený dvěma postavenými ostrvemi, vše stříbrné. V hrotu modrá triquetra."
    },
    {
        obec: "Kratochvilka",
        kod: 583235,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.156753,
        longitude: 16.376457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Kratochvilka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3 : 1. V modrém pruhu kráčející bílý beránek se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zelenou patou kráčející stříbrný beránek se zlatou zbrojí, v patě stříbrná hornická kladívka."
    },
    {
        obec: "Kratonohy",
        kod: 570206,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50324,
        latitude: 50.169243,
        longitude: 15.607018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Kratonohy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý, v poměru 1 : 2 : 1. V modrém pruhu do oblouku bílá velryba se zuby a červeným jazykem, hlavou dolů a k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad doleva obrácenou dolů prohnutou stříbrnou velrybou se zuby a s červeným jazykem červeno - stříbrně polcená svatojakubská mušle podložená zkříženými vztyčenými zlatými šípy."
    },
    {
        obec: "Krátošice",
        kod: 560634,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.325991,
        longitude: 14.786807
    },
    {
        obec: "Kratušín",
        kod: 537136,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.020356,
        longitude: 13.928608
    },
    {
        obec: "Kravaře",
        kod: 561720,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47103,
        latitude: 50.632503,
        longitude: 14.392521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Kravare_CL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma žerďovými pruhy, žlutým a červeným, každý široký jednu osminu délky listu. V modrém poli bílá kvádrovaná hradba s prázdnou branou a třemi černými kruhovými střílnami, za ní dvě bílé věže se třemi (2,1) černými okny a červenými cibulovými střechami se žlutými makovicemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou a třemi černými kruhovými střílnami, za ní dvě stříbrné věže se třemi (2,1) černými okny a červenými cibulovými střechami se zlatými makovicemi."
    },
    {
        obec: "Kravaře",
        kod: 507580,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74721,
        latitude: 49.93213,
        longitude: 18.004827,
        coatOfArmsFlagDescription: "Žlutý list s černou zavinutou střelou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá zavinutá střela."
    },
    {
        obec: "Kravsko",
        kod: 594261,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67151,
        latitude: 48.921596,
        longitude: 15.986425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Kravsko_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem vycházejícím z druhé šestiny dolního okraje do poslední šestiny horního okraje na zelené žerďové a bílé vlající pole. V horní polovině žerďové části listu bílý čtyřlistý květ se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vpravo vztyčená stříbrná radlice, vlevo stříbrný čtyřlistý květ se zlatým středem na prohnutém zlatém stonku se čtyřmi listy."
    },
    {
        obec: "Krčmaň",
        kod: 552437,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.520843,
        longitude: 17.344253,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Kr%C4%8Dma%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s pěti červenými rovnoramennými trojúhelníky se základnami na dolním okraji listu rovnými jedné pětině délky listu s vrcholy dosahujícími do poloviny šířky listu. Z tří prostředních vrcholů vyrůstá po jednom konikleci přirozené barvy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři červené kužely, z každého vyrůstá koniklec přirozené barvy."
    },
    {
        obec: "Krejnice",
        kod: 536954,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.233828,
        longitude: 13.718584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Krejnice_CoA.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem širokým sedminu šířky listu na žerďové modré pole a vlající červené pole. V žerďovém poli šikmo bílá ostrev o třech (2, 1) sucích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatým šikmým břevnem modro-červeně dělený štít. Nahoře šikmo stříbrná ostrev o třech (2, 1) sucích, dole stříbrný maltézský kříž."
    },
    {
        obec: "Krhanice",
        kod: 529958,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25742,
        latitude: 49.856112,
        longitude: 14.557463,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Krhanice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý se čtyřmi vrcholy a třemi prohlubněmi a zvlněný modrý, v poměru 9:1:8. V červeném pruhu zkřížená pádla listy nahoru, v modrém pruhu tři (2,1) žluté osmicípé hvězdy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře stříbrným zúženým vlnitým břevnem dělený štít, nahoře zkřížená vztyčená pádla, dole tři (2,1) osmihroté hvězdy, vše zlaté."
    },
    {
        obec: "Krhová",
        kod: 500062,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75701,
        latitude: 49.465249,
        longitude: 17.97427,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnkovaný zelený s pěti vrcholy, vlnitý bílý s pěti vrcholy a čtyřmi prohlubněmi a zvlněný modrý, v poměru 2 : 4 : 1 : 3. Přes tři dolní pruhy dva žluté vykořeněné listnaté stromy, mezi nimi uprostřed modrého pruhu žlutá vztyčená jehličnatá větvička. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené trojvrší nad modrou patou oddělenou stříbrným vlnitým břevnem, v nich dva vykořeněné listnaté stromy, mezi nimi v patě vztyčená jehličnatá větvička, vše zlaté."
    },
    {
        obec: "Krhov",
        kod: 581798,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.46036,
        longitude: 16.583923
    },
    {
        obec: "Krhov",
        kod: 590967,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.100437,
        longitude: 16.019513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Krhov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu cep bijákem k žerďovému okraji listu a převrácená kosa hrotem k vlajícímu okraji listu, obojí žluté, s násadami postrkanými do stran dvěma vztyčenými bílými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Nahoře modro-stříbrně polceno, vpravo cep a převrácená, doleva obrácená kosa, obojí zlaté, s násadami postrkanými do stran dvěma vztyčenými stříbrnými lipovými listy, vlevo volná modrá věž s cimbuřím, s černou branou a dvěma obdélnými okny. V dolním stříbrno-modře polceném poli svatojakubská mušle opačných barev."
    },
    {
        obec: "Krhovice",
        kod: 594270,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.815911,
        longitude: 16.173693,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Krhovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a vlnkovitý červený. Do žlutého pruhu vyrůstá modrá orlice s červenou zbrojí a jetelovitě zakončeným, uprostřed přerušeným, žlutým perizoniem. V červeném pruhu žlutý vinný hrozen se dvěma svěšenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-červeně vlnkovitě dělený štít. Nahoře vyrůstající modrá orlice s červenou zbrojí a zlatým, jetelovitě zakončeným a uprostřed přerušeným perizoniem, dole zlatý vinný hrozen se dvěma svěšenými listy."
    },
    {
        obec: "Krchleby",
        kod: 548685,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.079206,
        longitude: 16.234632,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Krchleby_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, černý, bílý a zelený, v poměru 5 : 1 : 3 : 1 : 5. V horním pruhu pět žlutých šesticípých hvězd vedle sebe. V dolním pruhu žlutá včela s bílými křídly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít se sníženým, stříbrně lemovaným černým břevnem, nahoře pilovitým a dole obloukovým. Nahoře pět (3, 2) zlatých hvězd, dole zlatá včela se stříbrnými křídly."
    },
    {
        obec: "Krchleby",
        kod: 569372,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.820815,
        longitude: 16.833722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Krchleby_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, černo-červeně polcený a žlutý, v poměru 1:6:1. V horní polovině černo-červeného pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky mezi dvěma bílými odvrácenými přirozenými liliemi s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-červeně polceném štítě pod stříbrnou růží se zlatým semeníkem položená zlatá orlice, z níž vyrůstají dvě odvrácené stříbrné přirozené lilie, s jedním listem."
    },
    {
        obec: "Krchleby",
        kod: 534153,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.888996,
        longitude: 15.350407
    },
    {
        obec: "Krchleby",
        kod: 537390,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.239265,
        longitude: 15.022812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Krchleby_NB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, zelený a žlutý, v poměru 3 : 1 : 1 : 1. V horním zeleném pruhu bílé průčelí kaple provázené vztyčenými odkloněnými žlutými lipovými listy s plodenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné vlnité břevno. Nad ním stříbrné průčelí kaple provázené nahoře dvěma vztyčenými odkloněnými lipovými listy s plodenstvím, dole zkřížené obilné klasy s listy, vše zlaté."
    },
    {
        obec: "Krmelín",
        kod: 598348,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73924,
        latitude: 49.729234,
        longitude: 18.235519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Krmel%C3%ADn_CoA.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem sahajícím do středu vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít je dělen krokvovým řezem stříbrně a zeleně. V horním levém rohu tři červené šestirohé hvězdy (2+1), v pravém horním rohu zelená palmová ratolest překřížená šikmo červeným mečem. V horní části dolní poloviny štítu stříbrný terč se dvěma modrými vlnitými břevny."
    },
    {
        obec: "Krňany",
        kod: 529974,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25744,
        latitude: 49.844913,
        longitude: 14.477553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Kr%C5%88any%2C_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký čtvrtinu délky listu a dva vodorovné pruhy, bílý a zelený, v poměru 4 : 1. V modrém pruhu žlutá berla závitem k žerďovému okraji, s bílým sudariem. Uprostřed listu vyrůstá do bílého pruhu jahodník se třemi svěšenými červenými jahodami a s trojicí zelených listů po obou stranách stonku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vpravo polceném štítě vpravo zlatá berla se stříbrným sudariem, vlevo na zeleném vrchu jahodník se třemi svěšenými červenými jahodami a s trojicí zelených listů po obou stranách stonku."
    },
    {
        obec: "Krnov",
        kod: 597520,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79401,
        latitude: 50.090683,
        longitude: 17.703168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Krnov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - modrý, bílý a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Krnsko",
        kod: 536172,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29431,
        latitude: 50.372511,
        longitude: 14.863152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Krnsko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým parožím, k žerďovému okraji podloženým a k vlajícímu okraji přeloženým dole vydutým pruhem širokým pětinu šířky listu. Nad pruhem mezi parožím řecký kříž, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté paroží vpravo podložené a vlevo přeložené dole vydutým břevnem, nahoře provázeným řeckým křížem, obojí stříbrné."
    },
    {
        obec: "Krokočín",
        kod: 590975,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.244186,
        longitude: 16.23308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Znak_obce_Kroko%C4%8D%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý se žlutým klasem a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou položenou radlicí pět zlatých obilných klasů."
    },
    {
        obec: "Kroměříž",
        kod: 588296,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.291758,
        longitude: 17.39948,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/CZE_Krom%C4%9B%C5%99%C3%AD%C5%BE_COA.svg",
        coatOfArmsFlagDescription: "Bílý list se žlutými pruhy na horním a dolním okraji, širokými 2/15 šířky listu, mezi nimi červený žerďový klín sahající do středu vlajícího okraje. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Krompach",
        kod: 561738,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47157,
        latitude: 50.828174,
        longitude: 14.701621,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Coat_of_arms_of_Krompach.svg",
        coatOfArmsFlagDescription: "List dělený šikmým vlnitým modrým pruhem širokým šestinu šířky listu na žerďové žluté a vlající bílé pole. V žerďovém poli zkřížené černé ostrve, ve vlajícím poli šikmo zelená větévka tisu s červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-stříbrně šikmým modrým vlnitým břevnem dělený štít, nahoře zkřížené černé ostrve, dole šikmo položená větévka tisu s plody přirozené barvy."
    },
    {
        obec: "Kropáčova Vrutice",
        kod: 536181,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29479,
        latitude: 50.330635,
        longitude: 14.717485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Kropacova_v_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část se třemi žlutými jetelovými trojlístky na spojených stoncích a bílá vlající část s modrým středovým vlnitým pruhem o třech vrcholech a dvou prohlubních, širokým jednu pětinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře vyrůstají ze středu tři zlaté jetelové trojlístky na stoncích, dole modré vlnité břevno."
    },
    {
        obec: "Kroučová",
        kod: 541940,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.206875,
        longitude: 13.784056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Coats_of_arms_Krou%C4%8Dov%C3%A1.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a žlutý, v poměru 1:6:1. V červeném pruhu bílý chrt provázený v žerďové části hornickými kladívky a ve vlající části chmelovou šišticí mezi dvěma listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný chrt mezi dvěma do oblouku položenými chmelovými stonky s listy a šišticemi, převýšený hornickými kladívky, oboje zlaté."
    },
    {
        obec: "Krouna",
        kod: 571661,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53943,
        latitude: 49.772498,
        longitude: 16.026846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Krouna_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:2:1. V modrém pruhu žlutá královská koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou stříbrnou krokví zlatá královská koruna."
    },
    {
        obec: "Krsy",
        kod: 559091,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33038,
        latitude: 49.926348,
        longitude: 13.055063,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Krsy_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým středovým křížem s rameny širokými jednu devítinu šířky listu a zeleným kantonem. V kantonu bílá labuť se žlutou zbrojí, červeným jazykem a červeným maltézským křížem na křídle. V dolním vlajícím poli žlutá růže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vpravo nahoře zelená čtvrť, v ní stříbrná labuť se zlatou zbrojí, červeným jazykem a červeným maltézským křížem na křídle. Vlevo nahoře růže, dole mříž, vše zlaté."
    },
    {
        obec: "Krtov",
        kod: 563030,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.348553,
        longitude: 14.832583
    },
    {
        obec: "Krty",
        kod: 598500,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27033,
        latitude: 50.091708,
        longitude: 13.432026
    },
    {
        obec: "Krty-Hradec",
        kod: 536555,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.291408,
        longitude: 13.848107,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Krty-Hradec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří zelený, bílý, modrý, bílý a červený pruh ve vzájemném poměru 1:1:2:1:1; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou patou v pravém poli smrk přirozených barev, v levém poli stříbrná hradební věž s cimbuřím a černou branou."
    },
    {
        obec: "Krucemburk",
        kod: 568945,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58266,
        latitude: 49.688511,
        longitude: 15.852171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Krucemburk_znak.gif",
        coatOfArmsFlagDescription: "Červený list se třemi bílými vlajícími klíny. Horní klín má vrchol na žerďovém okraji, střední v první třetině listu a dolní ve druhé třetině listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Z levého okraje červeného štítu kosmo vynikají tři stříbrné vlčí zuby."
    },
    {
        obec: "Kruh",
        kod: 577243,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.565638,
        longitude: 15.480245,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Kruh_%28Semily%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Žlutý list se zeleným věncem a červeno-bíle šachovaným (5x3) vlajícím pruhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zlatém poli zelený lipový věnec, levé pole červeno-stříbrně šachované."
    },
    {
        obec: "Krumsín",
        kod: 589667,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.446019,
        longitude: 17.001558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Krums%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s černou zubří hlavou s červeným jazykem a žlutou houžví mezi dvěma červenými půlměsíci cípy nahoru. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá snížená krokev nahoře provázená červenými stoupajícími půlměsíci a dole černou zubří hlavou s červeným jazykem a zlatou houžví."
    },
    {
        obec: "Krumvíř",
        kod: 584584,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69173,
        latitude: 48.989106,
        longitude: 16.910379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Krumv%C3%AD%C5%99_CoA_01.jpg",
        coatOfArmsFlagDescription: "Kosmo dělený list, v žerďovém poli pět vodorovných pruhů, bílý, červený, bílý, červený a bílý s modrým hroznem na zeleném stonku s listem, v poměru 1 : 1 : 1 : 1 : 4, ve vlajícím zeleném poli kosmo bílý nůž se žlutou rukojetí, hrotem k hornímu okraji listu a ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně kosmo děleném štítě vpravo pod dvěma zvýšenými červenými břevny modrý vinný hrozen na zeleném stonku s listem, vlevo kosmo vztyčený stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Krupá",
        kod: 533459,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 50.017461,
        longitude: 14.871405,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Krup%C3%A1_%28okres_Kol%C3%ADn%29_znak.jpg",
        coatOfArmsDescription: "Ve zlatém černě lemovaném štítě dvoje zkřížené černé hrábě."
    },
    {
        obec: "Krupá",
        kod: 541966,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27009,
        latitude: 50.174955,
        longitude: 13.731687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Coats_of_arms_Krup%C3%A1.jpeg",
        coatOfArmsFlagDescription: "Zelený list se žlutým vrchem na dolním okraji, širokým čtyři pětiny délky listu a vysokým dvě pětiny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou se zeleným chmelovým šlahounem s jedním listem a čtyřmi šišticemi po stranách zlatý vrch."
    },
    {
        obec: "Krupka",
        kod: 567639,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41741,
        latitude: 50.684596,
        longitude: 13.858264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Krupka-znak.png",
        coatOfArmsFlagDescription: "List dělený žlutým šikmým pruhem vycházejícím z první třetiny dolního okraje do třetí třetiny horního okraje listu na červené žerďové a modré vlající pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-zlatě polcený. V pravém poli stříbrný korunovaný lev se zlatou zbrojí a s ocasem protaženým mezi zadníma nohama a doleva vynikajícím. V levém děleném poli nahoře vyrůstající lev, dole tři kosmá břevna, vše černé. V dolním modrém poli na a mezi skalami přirozené barvy se stříbrnými žílami cínu klečící horník ve stříbrné kytli, dobývající rudu stříbrným želízkem a mlátkem na topůrkách přirozené barvy."
    },
    {
        obec: "Krušovice",
        kod: 541974,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27053,
        latitude: 50.172357,
        longitude: 13.775232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Krusovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed žlutá koruna prostrčená zkříženými bílými limpami, mezi nimi vztyčený žlutý šíp s červeným hrotem a opeřením. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá koruna prostrčená zkříženými stříbrnými limpami, mezi nimi vztyčený zlatý šíp s červeným hrotem a opeřením."
    },
    {
        obec: "Kružberk",
        kod: 568180,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74786,
        latitude: 49.831881,
        longitude: 17.683296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Kru%C5%BEberk_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým křížem s rameny širokými jednu čtvrtinu šířky listu a s třetinovou vlající částí, tvořenou čtyřmi svislými, střídavě bílými a modrými pruhy, z nichž první tři jsou vlnité a čtvrtý zvlněný, vždy o třech vrcholech a dvou prohlubních. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý latinský kříž vyrůstající ze třikrát stříbrno-modře vlnitě děleného návrší."
    },
    {
        obec: "Krychnov",
        kod: 599484,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.006062,
        longitude: 15.057761
    },
    {
        obec: "Kryry",
        kod: 566314,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43981,
        latitude: 50.174477,
        longitude: 13.426708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Kryry_znak.png",
        coatOfArmsFlagDescription: "Žlutý list s černou krokví vycházející z první šestiny horního a dolního okraje s vrcholem ve středu listu. V žerďovém klínu černý havran. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě havran stojící na ostrvi, oboje černé."
    },
    {
        obec: "Kryštofovo Údolí",
        kod: 564176,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46001,
        latitude: 50.774549,
        longitude: 14.933501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Kry%C5%A1tofovo_%C3%9Adol%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Žlutý list, v žerďové polovině nad červenými hornickými kladívky polovina černého lva s červenou zbrojí, držící zelenou větévku bezu černého s listy a bílými květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad červenými hornickými kladívky polovina černého lva s červenou zbrojí, držící zelenou větévku bezu černého s listy a stříbrnými květy."
    },
    {
        obec: "Křeč",
        kod: 548201,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39495,
        latitude: 49.389356,
        longitude: 14.917101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/K%C5%99e%C4%8D_PE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová a bílá vlající část. Uprostřed je žluto-červeně polcená růže se semeníkem opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě zlato-červeně polcená růže se semeníkem opačných barev."
    },
    {
        obec: "Křečhoř",
        kod: 533467,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.025621,
        longitude: 15.127128
    },
    {
        obec: "Křečkov",
        kod: 537403,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.180061,
        longitude: 15.114377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/K%C5%99e%C4%8Dkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zvlněný modrý, v poměru 7 : 3 : 8. V žerďové části zeleného pruhu bílý sedící křeček. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře vlnitě děleném štítě nahoře stříbrný sedící křeček, dole dolní polovina zlatého palečného kola."
    },
    {
        obec: "Křečovice",
        kod: 529991,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25756,
        latitude: 49.721417,
        longitude: 14.475637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/K%C5%99e%C4%8Dovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět střídavě modrých a bílých pruhů. V žerďové polovině žlutý chrt ve skoku s červeným jazykem a obojkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře kosmo děleném štítě nahoře kosmo položený střevíčník přirozené barvy, šikmo přeložený červenými houslemi, dole dvě stříbrná břevna, přes ně zlatý chrt ve skoku s červeným jazykem a obojkem."
    },
    {
        obec: "Kryštofovy Hamry",
        kod: 563315,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43191,
        latitude: 50.499571,
        longitude: 13.136207,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Kry%C5%A1tofovy_Hamry_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeno - bílo - červeně dělenou krokví, s rameny vycházejícími ze druhé a třetí třetiny horního a dolního okraje s vrcholem na žerďovém okraji listu. Uprostřed horní části listu žlutě zdobené modré říšské jablko nad černými hornickými kladívky na žlutých topůrkách v dolní části listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrném poli vykořeněná jedle přirozené barvy nad černým vlnitým břevnem, v levém červeném poli se stříbrným břevnem modré, zlatě zdobené říšské jablko nad černými hornickými kladívky se zlatými topůrky."
    },
    {
        obec: "Křekov",
        kod: 586960,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76601,
        latitude: 49.129596,
        longitude: 17.967873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/K%C5%99ekov%2C_znak.jpeg",
        coatOfArmsFlagDescription: "Zelený list se žlutou korunou v dolní polovině, prostrčenou dvěma bílými odvrácenými krojidly hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě stříbrná vztyčená odvrácená krojidla prostrčená zlatou korunou."
    },
    {
        obec: "Křelov-Břuchotín",
        kod: 554901,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78336,
        latitude: 49.614533,
        longitude: 17.195665,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/K%C5%99elov-B%C5%99uchot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým ondřejským křížem s rameny širokými jednu desetinu délky listu. V žerďové a vlající části po bílé šesticípé hvězdě. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou, zlatě zdobenou mitrou kosmo položený šíp hrotem dolů, šikmo přeložený berlou závitem ven, obojí zlaté a provázené po stranách dvěma hvězdami a dole zvonem, vše stříbrné."
    },
    {
        obec: "Křelovice",
        kod: 548219,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39445,
        latitude: 49.536955,
        longitude: 15.168678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/K%C5%99elovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů v poměru 3 : 6 : 1 : 1 : 1, modrý se čtyřmi žlutými šesticípými hvězdami, bílý s červenou lilií, zelený, žlutý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku se zlatým břevnem stříbrný obloukový most se dvěma pilíři, převýšený čtyřmi zlatými hvězdami. Mezi pilíři stříbrná dopisní obálka s červenou lilií."
    },
    {
        obec: "Křelovice",
        kod: 559105,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33036,
        latitude: 49.874423,
        longitude: 13.075914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/K%C5%99elovice_PS_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře čtvrcený list. V horním žerďovém poli bílá labuť se žlutou zbrojí a černou náhrdelní stuhou s bílým maltézským křížem. V dolním žerďovém poli lilie, nahoře provázená dvěma jetelovými trojlístky, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře stříbrná labuť se zlatou zbrojí a černou stuhou kolem krku se zavěšeným stříbrným maltézským křížem, dole lilie mezi dvěma jetelovými trojlisty, vše zlaté."
    },
    {
        obec: "Křemže",
        kod: 545571,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38203,
        latitude: 48.90507,
        longitude: 14.305777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/K%C5%99em%C5%BEe%2C_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s dolním žerďovým a horním vlajícím trojúhelníkem s odvěsnami o délce poloviny šířky listu, oba bílé. V červeném poli kosmo bílý šíp hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě kosmo vztyčený stříbrný šíp."
    },
    {
        obec: "Křenice",
        kod: 556505,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.502807,
        longitude: 13.200398,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/K%C5%99enice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký třetinu délky listu se žlutou lilií a červené pole. Z druhé, čtvrté a šesté sedminy vlajícího okraje modrého pruhu vycházejí do druhé třetiny délky listu tři bílé vodorovné pruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo zlatá lilie, vlevo tři stříbrná břevna, zleva do půli zkrácená."
    },
    {
        obec: "Křenek",
        kod: 534960,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.229642,
        longitude: 14.629558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Coats_of_arms_K%C5%99enek.jpeg",
        coatOfArmsFlagDescription: "Bílý list s žerďovým zvlněným modrým pruhem, širokým třetinu délky listu, se čtyřmi vrcholy a třemi prohlubněmi. V bílém poli hnědý proutěný košík, v něm kosmo kořen křenu se třemi zelenými listy přes držadlo košíku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně vpravo vlnitě polceném štítě vlevo proutěný košík s kosmo nakloněným kořenem křenu se třemi listy přes držadlo, vše přirozené barvy."
    },
    {
        obec: "Křenice",
        kod: 564991,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25084,
        latitude: 50.031104,
        longitude: 14.668617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/K%C5%99enice_%28Prague-East_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "V modrém listu mezi dvěma žlutými obilnými klasy bílá sloupová zvonička zakončená křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma zlatými obilnými klasy stříbrná sloupová zvonička zakončená křížkem."
    },
    {
        obec: "Křenov",
        kod: 578274,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56922,
        latitude: 49.679791,
        longitude: 16.628932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/K%C5%99enov_SY_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno - žlutě modrým středovým křížem čtvrcený list. Ramena kříže jsou široká třetinu šířky listu. Ve svislém rameni kříže žlutý kalich s bílou hostií, provázený ve vodorovných ramenech žlutými korunami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádou s červeným tlapatým křížem zlatý kalich se stříbrnou hostií provázený dvěma zlatými korunami."
    },
    {
        obec: "Křenovice",
        kod: 562181,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.367396,
        longitude: 14.342995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/K%C5%99enovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh, široký polovinu délky listu, a tři vodorovné pruhy, černý, žlutý a černý, v poměru 3 : 2 : 3. V zeleném pruhu zvon se třemi svěšenými křenovými listy místo srdce, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-černě polceném štítě vpravo zvon se třemi svěšenými křenovými listy místo srdce, vlevo břevno, vše zlaté."
    },
    {
        obec: "Křenovice",
        kod: 593214,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68352,
        latitude: 49.142261,
        longitude: 16.829426
    },
    {
        obec: "Křenovice",
        kod: 569143,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75201,
        latitude: 49.330514,
        longitude: 17.272737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/K%C5%99enovice_PR_CoA_2.jpg",
        coatOfArmsFlagDescription: "Modrý list se zkříženými bílými kořeny křenu, každý s jedním zeleným listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné kořeny křenu, každý s jedním zeleným listem."
    },
    {
        obec: "Křenovy",
        kod: 553832,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.53926,
        longitude: 13.021618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/K%C5%99enovy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 4 : 1. Ve žlutém pruhu šedý kořen křenu se zelenými listy provázený přivrácenými černými plamennými křídly. Na styku pruhů v žerďové a vlající části po červeno-bíle dělené šestilisté růži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě s modrou patou kořen křenu přirozené barvy se zelenými listy mezi přivrácenými černými plamennými křídly. Na dělící linii dvě červeno-stříbrně dělené šestilisté růže vedle sebe."
    },
    {
        obec: "Křepenice",
        kod: 540579,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.702999,
        longitude: 14.347666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/K%C5%99epenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V žerďové polovině listu vykračující zelený papoušek se žlutou zbrojí provázený v bílém pruhu červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě vykračující zelený papoušek se zlatou zbrojí provázený vlevo nahoře červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Křepice",
        kod: 584592,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69165,
        latitude: 48.999961,
        longitude: 16.719993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Znak_obce_K%C5%99epice_%28B%C5%99eclav%29.svg",
        coatOfArmsFlagDescription: "Zelená žerďová část se dvěma bílými vinnými hrozny nad sebou u středu listu, ke které v bílé vlající části přiléhá polovina černé zubří hlavy se žlutou houžví a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít, vpravo dva stříbrné vinné hrozny pod sebou, vlevo vyniká od středu půl černé zubří hlavy se zlatou zbrojí a houžví a červeným jazykem."
    },
    {
        obec: "Křepice",
        kod: 594288,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 48.98749,
        longitude: 16.097506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Coat_of_arms_of_K%C5%99epice_ZN.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 10 : 1 : 1. U horního okraje listu tři žluté šesticípé hvězdy, ve střední části listu žlutý pluh bez kol s bílou radlicí, provázený po stranách bílou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod třemi zlatými hvězdami a nad stříbrným vlnitým břevnem zlatý pluh bez kol se stříbrnou radlicí, provázený vpravo nahoře a vlevo dole stříbrnou lilií."
    },
    {
        obec: "Křesetice",
        kod: 534161,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28547,
        latitude: 49.907238,
        longitude: 15.262953,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/K%C5%99esetice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem vycházejícím z páté pětiny žerďového okraje do první pětiny vlajícího okraje listu. Horní pole zeleno-černě polcené se žlutým obilným klasem se dvěma listy u žerďového okraje, dolní pole červeno-zeleně polcené, v dolním cípu kosmo mlátek šikmo přeložený želízkem, obojí bílé se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít, pravé pole černo-stříbrno-červeně dělené, v levém zeleném poli zlatý pšeničný klas se dvěma listy nad kosmým mlátkem šikmo přeloženým želízkem, obojí stříbrné se zlatými topůrky."
    },
    {
        obec: "Křesín",
        kod: 565075,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.394622,
        longitude: 13.980842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/K%C5%99es%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a modré pole. V žerďové a střední části listu vzhůru ohnutá bílá přední lví noha se žlutou zbrojí, převýšená knížecí čepicí s jablkem a křížkem ve žlutém mezikruží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vzhůru ohnutá stříbrná přední lví noha se zlatou zbrojí, převýšená knížecí čepicí ozdobenou jablkem s křížkem ve zlatém kruhu."
    },
    {
        obec: "Křešice",
        kod: 565083,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41148,
        latitude: 50.522692,
        longitude: 14.21461,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/K%C5%99e%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list. V žerďové polovině bílá halapartna ostřím k žerďovému okraji vložená mezi dva zkřížené žluté obilné klasy, dole s přikloněnými listy, provázená dvěma bílými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná halapartna vložená mezi dva zkřížené zlaté obilné klasy, dole s přikloněnými listy, provázená dvěma stříbrnými růžemi se zlatými semeníky."
    },
    {
        obec: "Křešín",
        kod: 548227,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39424,
        latitude: 49.581824,
        longitude: 15.043819
    },
    {
        obec: "Křešín",
        kod: 540587,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.799554,
        longitude: 13.948174
    },
    {
        obec: "Křetín",
        kod: 581801,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.563204,
        longitude: 16.504506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/K%C5%99et%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrým zvlněný pruhem na dolním okraji širokým jednu desetinu šířky listu. Ve žlutém poli červené srdce kvetoucí třemi červenými květy na zeleném rozvětveném stonku, postranní pětilisté se žlutými středy, prostřední zvonkovitý vztyčený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou červené srdce kvetoucí třemi červenými květy na zeleném rozvětveném stonku, postranní pětilisté se zlatými středy, prostřední zvonkovitý vztyčený."
    },
    {
        obec: "Křičeň",
        kod: 572861,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.109618,
        longitude: 15.65169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/K%C5%99i%C4%8De%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 1:3. V bílém pruhu červená koruna. V modrém pruhu žlutý obilný klas se dvěma listy proložený mezi zkříženými žlutými berlami se žluto-černě dělenými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou s červenou korunou zlatý obilný klas se dvěma listy proložený mezi zkříženými zlatými berlami se zlato-černě dělenými sudarii."
    },
    {
        obec: "Křídla",
        kod: 587842,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.52733,
        longitude: 16.122451,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/K%C5%99%C3%ADdla_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s rozkřídlenou k vlajícímu okraji hledící bílou labutí se žlutou zbrojí převýšenou šesti žlutými šesticípými hvězdami (1,2,3). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná rozkřídlená doleva hledící labuť se zlatou zbrojí, nad ní šest (1,2,3) zlatých hvězd."
    },
    {
        obec: "Křídlůvky",
        kod: 550086,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.781811,
        longitude: 16.239054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Coat_of_arms_of_K%C5%99%C3%ADdl%C5%AFvky.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 3 : 1. V žerďové polovině zeleného pruhu žlutý vinný hrozen s listem ovinutý bílým hadem se žlutým jazykem, hlavou k dolnímu rohu zeleného pruhu. Ve žlutém pruhu pod hroznem zelený šestilistý květ s bílým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vinný hrozen s listem ovinutý stříbrným hadem se zlatým jazykem, hlavou šikmo dolů. Ve zlaté patě zelený šestilistý květ se stříbrným středem."
    },
    {
        obec: "Křimov",
        kod: 563161,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.486132,
        longitude: 13.301587,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/K%C5%99imov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zeleno-bíle čtvrcený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, zelený a bílý. V zeleném žerďovém poli pod sebou přiléhající žlutá šesticípá hvězda, bílá růže se žlutým semeníkem a žlutá lilie. V horním bílém poli červený pes ve skoku se žlutým jazykem a žlutým obojkem s kroužkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo pod sebou přiléhající zlatá hvězda, stříbrná růže se zlatým semeníkem a zlatá lilie, vlevo červený pes ve skoku se zlatým jazykem a zlatým obojkem s kroužkem."
    },
    {
        obec: "Křinec",
        kod: 537411,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28933,
        latitude: 50.264587,
        longitude: 15.137929,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/K%C5%99inec_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 2 : 1. Na červeném pruhu stojí bílá postava Spravedlnosti s bílým mečem v pravé a se žlutými miskovými vahami v levé ruce, provázená dvěma vyrůstajícími zelenými ratolestmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě červená kvádrovaná zeď s prázdnou branou a stříbrnými vraty na černých závěsech. Na hradbě stojí stříbrná postava Spravedlnosti se stříbrným mečem v pravé a zlatými miskovými vahami v levé ruce, provázená dvěma vyrůstajícími zelenými ratolestmi."
    },
    {
        obec: "Křinice",
        kod: 574171,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55001,
        latitude: 50.571797,
        longitude: 16.306073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/CoA_of_K%C5%99inice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 4:1:4. V horním modrém pruhu dvě osmicípé hvězdy vedle sebe, v dolním modrém pruhu vodorovně ostrev se třemi suky (2,1), vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno provázené nahoře dvěma osmihrotými hvězdami a dole položenou ostrví se třemi suky (2,1), vše zlaté."
    },
    {
        obec: "Křišťanov",
        kod: 561673,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 48.909239,
        longitude: 14.020131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/K%C5%99i%C5%A1%C5%A5anov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý vlnitý se čtyřmi vrcholy a třemi prohlubněmi a modrý zvlněný, v poměru 6 : 1 : 1. V zeleném pruhu hrábě kosmo přeložené kosou a šikmo podložené cepem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrno-modře vlnitě dělenou vlnitou patou hrábě kosmo přeložené kosou a šikmo podložené cepem, vše zlaté."
    },
    {
        obec: "Křišťanovice",
        kod: 597538,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79368,
        latitude: 49.850911,
        longitude: 17.51114,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/K%C5%99i%C5%A1%C5%A5anovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém kosmo list jasanu s devíti zelenými lístky na hnědém řapíku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě jasan přirozené barvy vyrůstající ze zeleného návrší se stříbrným christogram tvořený řeckými přeloženými písmeny ióta a chí."
    },
    {
        obec: "Křivoklát",
        kod: 541982,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.037956,
        longitude: 13.870474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Krivoklat_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - modrý, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít se stříbrno-modrým oblačným lemem. V pravém poli půl černé přemyslovské plamenné orlice, v levém poli zlatá lilie."
    },
    {
        obec: "Křivsoudov",
        kod: 530000,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25766,
        latitude: 49.633077,
        longitude: 15.087465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/CoA_of_K%C5%99ivsoudov.svg",
        coatOfArmsFlagDescription: "Červený list s volnou trojicí bílých vlčích zubů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zleva vynikající tři stříbrné vlčí zuby."
    },
    {
        obec: "Křižánky",
        kod: 595918,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59202,
        latitude: 49.687674,
        longitude: 16.0644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/K%C5%99i%C5%BE%C3%A1nky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů červený, bílý, modrý, bílý a červený, v poměru 1:1:12:1:1. Uprostřed žlutý tlapatý kříž s kroužky na koncích ramen, podložený svisle dvěma odvrácenými bílými vinařskými noži se žlutými rukojeťmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý tlapatý kříž s kroužky podložený dvěma vztyčenými odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi a převýšený červenou knížecí čepicí, provázenou dvěma zlatými tlapatými křížky s kroužky."
    },
    {
        obec: "Křižanov",
        kod: 509752,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.39884,
        longitude: 14.378237
    },
    {
        obec: "Křižanov",
        kod: 595926,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.388682,
        longitude: 16.109707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Krizanov_%28Zdar_nad_Sazavou%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a černý. V žerďové polovině perizonium s jetelovými trojlístky k žerdi a křížkem k vlající části, v bílém pruhu černé, v černém žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černé orlí křídlo se zlatými jetelovými lístky zakončeným perizoniem uprostřed s křížkem."
    },
    {
        obec: "Křižanovice",
        kod: 593222,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.142428,
        longitude: 16.939154,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Znak_K%C5%99i%C5%BEanovice.jpg",
        coatOfArmsFlagDescription: "Modrý list s osmicípou žluto-červeně čtvrcenou hvězdou v žerďové polovině horní části listu a třemi (2,1) bílými otevřenými rolničkami s oušky a červenými paličkami ve vlající polovině dolní části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlato-červeně čtvrcená osmihrotá hvězda, pod ní tři (2,1) otevřené rolničky s oušky a červenými paličkami."
    },
    {
        obec: "Křižanovice",
        kod: 547824,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.859518,
        longitude: 15.761796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Krizanovice_CR_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černo-žlutě polcený a zvlněný červený se třemi vrcholy a dvěma prohlubněmi. V černém poli žlutý středový kříž s rameny širokými jednu osminu šířky listu, ve žlutém poli zkřížené černé ostrve. V červeném pruhu žlutý kapr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Vlnitě dělený štít, horní pole černo-zlatě polcené, vpravo zlatý heroldský kříž, vlevo černé zkřížené ostrve, v dolním červeném poli zlatý kapr."
    },
    {
        obec: "Křižanovice u Vyškova",
        kod: 593231,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.287758,
        longitude: 17.040305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Znak_obce_krizanovice_u_vyskova.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký jednu třetinu délky listu a bílé pole. V červeném pruhu tři žluté kosmé pruhy vycházející z první, třetí a páté osminy žerďového okraje do čtvrté, šesté a osmé osminy vlajícího okraje pruhu. V bílém poli dvě zkřížené modré sekery na černých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se třemi zlatými jablky dvě zkřížené modré sekery na černých topůrkách."
    },
    {
        obec: "Křižany",
        kod: 564184,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46353,
        latitude: 50.73858,
        longitude: 14.901417,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Krizany_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým a červeným parohem vyrůstajícími ze žluté koruny, mezi nimi kosmá žlutá berla závitem k žerďovému okraji, šikmo podložená bílým mečem se žlutou záštitou a hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zlaté koruny vyrůstají jelení parohy, pravý stříbrný, levý červený, mezi nimi kosmá zlatá berla závitem ven, křížem podložená postaveným stříbrným mečem se zlatým jílcem a záštitou."
    },
    {
        obec: "Křižínkov",
        kod: 595934,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59453,
        latitude: 49.32587,
        longitude: 16.270362,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/K%C5%99i%C5%BE%C3%ADnkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 3 : 8. V červeném pruhu bílý středový kříž s rameny širokými třetinu šířky červeného pruhu. V modrém pruhu lev držící kvádrovanou věž s cimbuřím a černým oknem, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se stříbrným heroldským křížem lev držící kvádrovanou věž s cimbuřím a černým oknem, vše stříbrné."
    },
    {
        obec: "Křížkový Újezdec",
        kod: 538418,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25168,
        latitude: 49.930882,
        longitude: 14.585468
    },
    {
        obec: "Křižovatka",
        kod: 554596,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.194269,
        longitude: 12.393172,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Krizovatka_CH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 11 : 2 : 11. V červeném pruhu bílý vodorovný pruh, vycházející z druhé osminy šířky listu, se třemi modrými rovnoramennými trojúhelníky se základnami na dolním okraji a vrcholy na horním okraji bílého pruhu. Pod ním polovina bílého zlomeného kola sv. Kateřiny s úplným nábojem zlomem k vlajícímu okraji. V modrém pruhu bílý vodorovný pruh, vycházející ze sedmé osminy vlajícího okraje, nad ním bílý meč hrotem k hornímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře stříbrným zúženým kůlem polceném štítě vpravo nahoře stříbrné břevno se třemi modrými hroty, pod ním pravá polovina zlomeného kola sv. Kateřiny s úplným nábojem, vlevo dole břevno, nad ním vztyčený meč, vše stříbrné."
    },
    {
        obec: "Křoví",
        kod: 595951,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59454,
        latitude: 49.306061,
        longitude: 16.26435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Krovi-znak.png",
        coatOfArmsFlagDescription: "Modrý list se zkříženými postavenými klíči zuby nahoru, šikmý žlutý přes kosmý bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zkřížené postavené klíče zuby nahoru, horní šikmý zlatý, spodní kosmý stříbrný."
    },
    {
        obec: "Křtiny",
        kod: 581828,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67905,
        latitude: 49.297155,
        longitude: 16.744194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Krtiny_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Na bílém listu zelený vykořeněný jehličnatý strom. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vykořeněný jehličnatý strom."
    },
    {
        obec: "Křtěnov",
        kod: 581810,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.545085,
        longitude: 16.419371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/K%C5%99t%C4%9Bnov_znak.jpg",
        coatOfArmsFlagDescription: "Modro-žluto-černo-modře čtvrcený list. Uprostřed bílá labuť se žlutou zbrojí, držící v zobáku kosmo pravou ruku ve zbroji bez rukavice s šikmým bílým mečem se žlutým jílcem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě s levým stříbrným čtvercovým koutem stříbrná labuť se zlatou zbrojí držící v zobáku obrněnou pravou ruku bez rukavice s šikmým stříbrným mečem se zlatým jílcem. V koutu černá zubří hlava se zlatou zbrojí, červeným jazykem a zlatou houžví."
    },
    {
        obec: "Křtomil",
        kod: 552780,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.409188,
        longitude: 17.626333,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/K%C5%99tomil_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem s paprsky střídavě trojúhelníkovými a plamennými, v něm červená, čelně hledící lví hlava s bílými zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve zlatém slunci červená, čelně hledící lví hlava se stříbrnými zuby."
    },
    {
        obec: "Kšely",
        kod: 571717,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.04352,
        longitude: 14.902034,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Ksely_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, modrý, žlutý a modrý, v poměru 9:2:1:2:1. Z bílého pruhu od nohou vyrůstají dvě přivrácené rozkřídlené husy s červenou zbrojí a zlatými jazyky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze stříbrného vlnitého břevna od nohou vyrůstají dvě přivrácené rozkřídlené stříbrné husy s červenou zbrojí a zlatými jazyky, dole kalich podložený dvěma dole zkříženými obilnými klasy do oblouku, vše zlaté."
    },
    {
        obec: "Kšice",
        kod: 560979,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.80252,
        longitude: 12.997365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/K%C5%A1ice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, bílý, modrý, bílý, zelený, bílý a zelený, v poměru 7 : 1 : 1 : 1 : 1 : 1 : 2. U žerďového okraje horního modrého pruhu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně zúženým, pětkrát lomeným břevnem děleném štítě nahoře lilie a křídlo, dole obrácená hornická kladívka, vše stříbrné."
    },
    {
        obec: "Ktiš",
        kod: 550329,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38403,
        latitude: 48.917082,
        longitude: 14.132752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Kti%C5%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi šikmými pruhy, bílým, červeným a bílým. Pruhy vycházejí z první, druhé a třetí dvanáctiny dolního okraje listu do desáté, jedenácté a dvanácté dvanáctiny horního okraje listu. V žerďovém poli žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě pod zelenou hlavou se zlatou korunou vpravo kosmo vztyčený stříbrný řeznický nůž ostřím doleva, vlevo šikmo vztyčená větévka tisu se dvěma plody, přirozené barvy ."
    },
    {
        obec: "Ktová",
        kod: 547484,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51263,
        latitude: 50.519937,
        longitude: 15.246847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Ktov%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým pruhem širokým jednu šestinu délky listu a s modrým vodorovným pruhem širokým jednu dvanáctinu délky listu. Nad pruhem červená růže se žlutým semeníkem a zelenými kališními lístky, pod pruhem modré mlýnské kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou modrý kůl provázený červenou růží se zlatým semeníkem a zelenými kališními lístky a modrým mlýnským kolem."
    },
    {
        obec: "Kublov",
        kod: 531375,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26741,
        latitude: 49.943803,
        longitude: 13.876779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Coats_of_arms_Of_Kublov.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5:3. Do bílého pruhu vyniká ze druhé a třetí šestiny horního okraje zeleného pruhu zelený vrch. Z něho vyrůstá od pařátů černá plamenná orlice se žlutou zbrojí, červeným jazykem a žlutým jetelovitě zakončeným perizoniem, pod ní žluté zkřížené ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá plamenná orlice se zlatou zbrojí, červeným jazykem a zlatým jetelovitě zakončeným perizoniem, vyrůstající od pařátů ze zeleného vrchu se zlatými zkříženými ostrvemi."
    },
    {
        obec: "Kubova Huť",
        kod: 563978,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38501,
        latitude: 48.983333,
        longitude: 13.771924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Kubova_Hut_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký jednu třetinu délky listu, se žlutým květem prhy horské a bílé pole se zeleným smrkem s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší se zlatým květem prhy horské smrk přirozené barvy."
    },
    {
        obec: "Kubšice",
        kod: 594296,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67176,
        latitude: 49.000765,
        longitude: 16.413164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Kub%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeným klínem vycházejícím z dolního okraje a s vrcholem v první třetině šířky listu. V horním rohu vinařský nůž-kosíř hrotem k žerdi, v horním cípu krojidlo hrotem dolů, v klínu lilie, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě červený snížený hrot s lilií, nad ním vinařský nůž-kosíř a krojidlo, vše zlaté."
    },
    {
        obec: "Kučerov",
        kod: 593249,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.218686,
        longitude: 17.005432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Znak_obce_Ku%C4%8Derov.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Ve žlutém pruhu modrý svatopetrský kříž s rameny širokými jednu desetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo modrý svatopetrský kříž, vlevo stříbrná vztyčená radlice."
    },
    {
        obec: "Kučeř",
        kod: 549541,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39834,
        latitude: 49.434342,
        longitude: 14.245724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Ku%C4%8De%C5%99_znak.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy střídavě modré a bílé, v poměru 5 : 1 : 1 : 1. V žerďové části horního modrého pruhu žlutý keltský kříž s prodlouženým dolním ramenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě modro-stříbrně pilovitě dělený štít, nahoře keltský kříž s prodlouženým dolním ramenem, provázený po stranách čtyřmi (2, 2) hvězdami, vše zlaté, dole dvě zúžená vlnitá břevna nad hvězdou, vše modré."
    },
    {
        obec: "Kudlovice",
        kod: 592323,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68703,
        latitude: 49.131767,
        longitude: 17.457274,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Kudlovice_znak.jpg",
        coatOfArmsFlagDescription: "Zeleno-červeně šikmo dělený list. Uprostřed bílý vinařský nůž-kosíř se žlutou rukojetí, hrotem nahoru a k vlajícímu okraji listu, a žlutý vinný hrozen s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně šikmo děleném štítě vpravo doleva obrácený stříbrný vinařský nůž-kosíř se zlatou rukojetí, vlevo zlatý vinný hrozen s listem."
    },
    {
        obec: "Kuchařovice",
        kod: 594300,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.876057,
        longitude: 16.077071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Kucha%C5%99ovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeno-bíle šachovaným (2×5) žerďovým pruhem. V modrém poli bílý vztyčený vinařský nůž ostřím k vlajícímu okraji se žlutou střenkou provázený po stranách dvěma trojicemi bílých do oblouku postavených květů se žlutými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-stříbrně šachovanou hlavou vztyčený stříbrný vinařský nůž se zlatou rukojetí, provázený po obou stranách třemi, do oblouku postavenými stříbrnými květy se zlatými středy."
    },
    {
        obec: "Kujavy",
        kod: 555312,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74245,
        latitude: 49.703412,
        longitude: 17.972636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Kujavy_CoA.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený zvlněným řezem na žerďové modré pole s kosmo položenou bílou radlicí ostřím dolů a hrotem k dolnímu cípu a zelené vlající pole s bílým lipovým listem na větvi přiléhající k modrému poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře kosmo vlnitě děleném štítě nahoře vyniká půl lipové větve s jedním vztyčeným listem, dole doleva položená radlice, obojí stříbrné."
    },
    {
        obec: "Kukle",
        kod: 572594,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.793206,
        longitude: 16.425709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Kukle_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým středovým křížem s rameny širokými jednu šestinu délky listu. Uprostřed listu červená růže se zeleným semeníkem a kališními lístky, o průměru rovném polovině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zlatém heroldském kříži červená růže se zeleným semeníkem a kališními lístky."
    },
    {
        obec: "Kuklík",
        kod: 595969,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.630022,
        longitude: 16.113247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Kukl%C3%ADk_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. V modrém pruhu kůň, v červeném trs kuklíku, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně děleném štítě nahoře kůň, dole trs kuklíku, obojí stříbrné."
    },
    {
        obec: "Kuks",
        kod: 579416,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54443,
        latitude: 50.401587,
        longitude: 15.888322,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu žluté granátové jablko s červeným středem na stopce se dvěma listy. Ve žlutém pruhu zelené trojvrší vysoké třetinu šířky listu vycházející z poslední čtvrtiny vlajícího okraje listu, v něm vodorovný bílý vlnitý pruh se třemi vrcholy a dvěma prohlubněmi. Z prostředního vrchu tryskají tři modré prameny, krajní odkloněné. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít. Nahoře zlaté granátové jablko s červeným středem na stopce se dvěma listy, dole ze zeleného trojvrší se stříbrným vlnitým břevnem tryskají tři modré prameny, krajní odkloněné."
    },
    {
        obec: "Kulířov",
        kod: 581836,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.379422,
        longitude: 16.846914
    },
    {
        obec: "Kunčice",
        kod: 570214,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.217617,
        longitude: 15.636021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Kun%C4%8Dice_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.jpg"
    },
    {
        obec: "Kunčice nad Labem",
        kod: 579424,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54361,
        latitude: 50.581962,
        longitude: 15.619536,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Kuncice_nL_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:2:1. V bílém pruhu utržená hnědá medvědí hlava se žlutým náhubkem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně sníženě děleném štítě nahoře vpravo vyrůstající listnatý strom a k němu kráčející medvěd se zlatým náhubkem, vše přirozené barvy, dole stříbrné vlnité břevno."
    },
    {
        obec: "Kunčice pod Ondřejníkem",
        kod: 598356,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73913,
        latitude: 49.550798,
        longitude: 18.261235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Kun%C4%8Dice_pod_Ond%C5%99ejn%C3%ADkem_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří bílá žerďová část s volným červeným ondřejským křížem, přes který je položeno vztyčené zelené kladivo a modrý květ lnu se zeleným semeníkem; vlající část je zelená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším volný červený ondřejský kříž přeložený vztyčeným zeleným kladivem a modrým květem lnu."
    },
    {
        obec: "Kunčina",
        kod: 578282,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56924,
        latitude: 49.794652,
        longitude: 16.627737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Kun%C4%8Dina_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým svislým pruhem vycházejícím ze šesté a sedmé čtyřiadvacetiny horního a dolního okraje listu. Pruh provázen dvěma žlutými obilnými klasy, každý se čtyřmi listy (2, 2). Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě mezi dvěma zlatými obilnými klasy, každý se čtyřmi (2, 2) listy, doleva obrácená stříbrná radlice nahoře provázená stříbrným lněným květem se zlatým středem."
    },
    {
        obec: "Kundratice",
        kod: 595977,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.402684,
        longitude: 16.134262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Kundratice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, červený, bílý s modrou radlicí, červený a zelený, v poměru 1:1:3:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá radlice provázená dvěma přivrácenými červenými vlčími máky na zelených stoncích."
    },
    {
        obec: "Kunčina Ves",
        kod: 581844,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.44708,
        longitude: 16.475366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Kun%C4%8Dina_Ves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený, bílý, modrý, bílý a zelený, v poměru 14 : 3 : 1 : 2 : 1 : 3. V horním pruhu vykračující černá kuna s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vykračující černá kuna s červenou zbrojí nad zeleným návrším s modrým, stříbrně lemovaným vlnitým břevnem."
    },
    {
        obec: "Kunějovice",
        kod: 559121,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33035,
        latitude: 49.862066,
        longitude: 13.237025,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Kun%C4%9Bjovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině dva bílé býčí rohy provázené uprostřed lilií a nahoře po stranách dvěma svěšenými chmelovými šišticemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dva stříbrné býčí rohy provázené uprostřed lilií a nahoře po stranách dvěma svěšenými chmelovými šišticemi, vše zlaté."
    },
    {
        obec: "Kunemil",
        kod: 548570,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.707561,
        longitude: 15.431616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Kunemil_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se středovým a ondřejským křížem s rameny širokými jednu osminu šířky listu, oba žluté, uprostřed s černou kančí hlavou s bílou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlaté palečné kolo přeložené černou kančí hlavou se stříbrnou zbrojí."
    },
    {
        obec: "Kunětice",
        kod: 573515,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53304,
        latitude: 50.070734,
        longitude: 15.827021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Znak_Kunetic.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černo-žlutě šachovaný (8x2), modrý a žluto-černě šachovaný (8x2), v poměru 1:4:1. V modrém pruhu bílá věž s cimbuřím, červenou stanovou střechou a černým půlkruhovým oknem, podložená dvěma zkříženými žlutými hradebními žebříky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zlato-černě šachovaném návrší stříbrná věž s cimbuřím, červenou stanovou střechou a černým půlkruhovým oknem, podložená dvěma zkříženými zlatými hradebními žebříky."
    },
    {
        obec: "Kunice",
        kod: 581852,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.478926,
        longitude: 16.490834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Kunice_%28okres_Blansko%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, modrý, bílý, černý, bílý, černý, bílý, černý, bílý a modrý, v poměru 2 : 1 : 2 : 2 : 2 : 2 : 2 : 1 : 2. Na žerďovém okraji přiléhá k užším bílým pruhům modré čtvercové pole se žlutou podkovou, uvnitř s bílým tlapatým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-stříbrně děleném štítě, nahoře se dvěma stříbrnými břevny, modrý hrot. V hrotu pod vztyčeným stříbrným listem jírovce zlatá podkova, uvnitř se stříbrným tlapatým křížem."
    },
    {
        obec: "Kunice",
        kod: 538426,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25163,
        latitude: 49.936652,
        longitude: 14.671607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Kunice_PH_CZ_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed zvon v trnové koruně, ze které vyrůstají tři lipové listy (2,1), vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zvon v trnové koruně, z níž do stran vynikají tři (2,1) lipové listy, vše zlaté."
    },
    {
        obec: "Kuničky",
        kod: 581861,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.435026,
        longitude: 16.681468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Coat_of_arms_of_Kuni%C4%8Dky.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, v poměru 13 : 11. Uprostřed listu bílý vykračující kůň se žlutými kopyty, provázený v zeleném pruhu pod břichem bílým patriarším křížem a v červeném pruhu nad ocasem bílým květem lnu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně vlevo polceném štítě stříbrný vykračující kůň se zlatými kopyty nad stříbrným patriarším křížem, provázený vlevo nahoře stříbrným květem lnu se zlatým středem."
    },
    {
        obec: "Kunín",
        kod: 568546,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74253,
        latitude: 49.63387,
        longitude: 17.989756,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Kunin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým emblémem z obecního znaku (vrba provázená po pravé straně radlicí, po levé straně krojidlem křížem podloženým brouskem). Emblém zaujímá dvě třetiny plochy listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vykořeněná vrba, provázená dole po pravé straně radlicí, po levé straně krojidlem křížem podloženým brouskem, vše stříbrné."
    },
    {
        obec: "Kunkovice",
        kod: 588628,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76813,
        latitude: 49.182821,
        longitude: 17.174357,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Znak_Kunkovice.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým větrným mlýnem se žlutými lopatkami, s černým vchodem a dvěma okny, červenou kuželovou střechou zakončenou vyrůstající bílou lilií. Pod mlýnem pět žlutých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný větrný mlýn se zlatými lopatkami, s černým vchodem a dvěma okny, s červenou kuželovou střechou zakončenou vyrůstající stříbrnou lilií. Pod mlýnem pět zlatých hvězd do oblouku."
    },
    {
        obec: "Kunovice",
        kod: 550744,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68604,
        latitude: 49.045088,
        longitude: 17.470215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Coa_Czech_Town_Kunovice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým ohlížejícím se beránkem se žlutou zbrojí přidržujícím pravou přední nohou žlutý kříž s bílou dvoucípou korouhví. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítu stříbrný ohlížející se beránek se zlatou zbrojí. Pravou přední nohou přidržuje zlatý kříž se stříbrnou dvoucípou korouhví."
    },
    {
        obec: "Kunovice",
        kod: 543021,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75644,
        latitude: 49.444343,
        longitude: 17.809991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/CZ_Kunovice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: dělený červeno-zelený a bílý, v poměru 1:2:1. Ve středním pruhu drží dvě žluté kuny vztyčenou bílou radlici. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě drží dvě zlaté kuny vztyčenou stříbrnou radlici."
    },
    {
        obec: "Kuňovice",
        kod: 530026,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.658386,
        longitude: 14.999521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Ku%C5%88ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý. V žerďovém pruhu třikrát červeno-bíle dělené křídlo, z žerďového okraje vlajícího pruhu vyniká do bílého pruhu vykračující červený kůň. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. Vpravo doleva obrácené třikrát červeno-stříbrně dělené křídlo, vlevo vyniká z okraje štítu vykračující červený kůň."
    },
    {
        obec: "Kunratice",
        kod: 530433,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.921515,
        longitude: 15.026152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Kunratice_LB_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Uprostřed k žerdi prohnutý červený jelení paroh - korál, provázený v modrém poli červeno - žlutě polceným srdcem, shora probodnutým bílým mečem se žlutým jílcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře polceném štítě červený, doprava prohnutý jelení paroh - korál, provázený v modrém poli červeno - zlatě polceným srdcem shora probodnutým stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Kunratice",
        kod: 546330,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.818924,
        longitude: 14.41998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Kunratice_%28D%C4%9B%C4%8D%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený se třemi svěšenými žlutými lipovými listy pod sebou a žlutý, v poměru 2 : 3 : 7. Ve žlutém pruhu zelený vlající klín s vrcholem na vlajícím okraji zeleného pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zvýšené zelené trojvrší, v něm nad stříbrným vlnitým břevnem tři svěšené zlaté lipové listy vedle sebe. Na prostředním vrchu kamzík, na krajních jehličnatý strom, vše přirozené barvy."
    },
    {
        obec: "Kunratice u Cvikova",
        kod: 561746,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47155,
        latitude: 50.768777,
        longitude: 14.678722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Kunratice_u_Cvikova_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým ondřejským křížem s rameny širokými jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou s černými zkříženými ostrvemi stříbrná kvádrovaná věž na soklu, s cimbuřím a černým gotickým oknem."
    },
    {
        obec: "Kunštát",
        kod: 581879,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67972,
        latitude: 49.506551,
        longitude: 16.517324,
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů střídavě bílých a černých v poměru 10:1:1:1:1:1:5. V horní žerďové části sedm černých per, vyrůstajících z horního černého pruhu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kunvald",
        kod: 580503,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56181,
        latitude: 50.129372,
        longitude: 16.50007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Kunvald_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří šest šikmých pruhů: červený, bílý, červený, modrý, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře šikmo dělený štít. Nahoře stříbrný beránek nesoucí stříbrnou korouhev s červeným heroldským křížem, dole sv. Jiří ve zlaté zbroji na stříbrném koni probodávající zeleného draka."
    },
    {
        obec: "Kunžak",
        kod: 546615,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37862,
        latitude: 49.12129,
        longitude: 15.190385,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/CoA_of_Kun%C5%BEak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. V modrém pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Kupařovice",
        kod: 583243,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.043314,
        longitude: 16.490816,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Kupa%C5%99ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě zelených a bílých, v poměru 2:1:6:1:2. V prostředním pruhu bílá věž s cimbuřím a prázdným oknem se žlutým zavěšeným zvonem, pod oknem červená lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná věž s cimbuřím a prázdným oknem se zlatým zavěšeným zvonem, pod oknem červená lilie. Věž provázená dvěma odvrácenými stříbrnými sudlicemi bez násad."
    },
    {
        obec: "Kurdějov",
        kod: 555282,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.957874,
        longitude: 16.763556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Znak_obce_Kurd%C4%9Bjov.svg",
        coatOfArmsFlagDescription: "Žlutý list, uprostřed kosmo vztyčená červená štika šikmo podložená černou ostrví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kosmo vztyčená červená štika šikmo podložená černou ostrví."
    },
    {
        obec: "Kuroslepy",
        kod: 550302,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67575,
        latitude: 49.154432,
        longitude: 16.210217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Kuroslepy_CoA.jpg"
    },
    {
        obec: "Kurovice",
        kod: 588636,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76852,
        latitude: 49.289235,
        longitude: 17.515668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Kurovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a červený v poměru 5:2:5. V zeleném pruhu žlutá radlice hrotem nahoru a ostřím k žerdi, v červeném bílá lilie. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V dolní části zeleno-červeně polceného štítu položená stříbrná rukavice (plechovice) držící vztyčenou zlatou radlici, vlevo nad ní stříbrná lilie."
    },
    {
        obec: "Kuřim",
        kod: 583251,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66434,
        latitude: 49.298618,
        longitude: 16.531546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Ku%C5%99im_CoA_CZ.png",
        coatOfArmsFlagDescription: "Bílý list, v horním žerďovém rohu černé křídlo s bílou jetelovou pružinou z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kuřimany",
        kod: 560391,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.204002,
        longitude: 13.968229,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Ku%C5%99imany_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s pěti (1, 2, 2) bílými šesticípými hvězdami do kruhu mezi třemi (2, 1) odvrácenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pět (1, 2, 2) stříbrných hvězd do kruhu mezi třemi (2, 1) odvrácenými zlatými lipovými listy."
    },
    {
        obec: "Kuřimská Nová Ves",
        kod: 595985,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.345656,
        longitude: 16.297158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Ku%C5%99imsk%C3%A1_Nov%C3%A1_Ves_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed z dolní poloviny bílé lilie vyrůstají dva žluté odkloněné lipové listy se třemi plody uprostřed. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě z dolní poloviny stříbrné lilie vyrůstají dva zlaté odkloněné lipové listy se třemi plody uprostřed."
    },
    {
        obec: "Kuřimské Jestřabí",
        kod: 595993,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.346676,
        longitude: 16.314032,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Coat_of_arms_of_Ku%C5%99imsk%C3%A9_Jest%C5%99ab%C3%AD.jpg",
        coatOfArmsFlagDescription: "Zelený list s žerďovým půlkruhovým bílým polem, v něm černé orlí křídlo s jetelovitě zakončeným žlutým perizoniem. V zeleném poli nahoře bílý rozkřídlený jestřáb se žlutou zbrojí, nad zkříženými bílými sekerami se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrnou patou stříbrný rozkřídlený jestřáb se zlatou zbrojí nad stříbrnými sekerami se zlatými topůrky. V patě černé křídlo, letkami dolů, se zlatým, jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Kutná Hora",
        kod: 533955,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.952531,
        longitude: 15.268754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/COA_Kutna_Hora.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová a červená vlající část. Ve žlutém poli černá do středu listu obrácená orlice se žlutou korunou a červenou zbrojí. V červeném poli bílý dvouocasý lev ve skoku se žlutou korunou a zbrojí. Obě figury stojí pravou nohou na hnědých topůrkách zkřížených bílých hornických kladívek a drží žlutý kalich převýšený žlutou, červeně podšitou císařskou korunou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kutrovice",
        kod: 571512,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27375,
        latitude: 50.261654,
        longitude: 14.018974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Kutrovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 1 : 6 : 1. Do bílého pruhu vyrůstá zelený listnatý strom s hnědým kmenem s korunou posázenou osmi žlutými vztyčenými listy jírovce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z červené paty listnatý strom přirozené barvy s korunou posázenou osmi vztyčenými zlatými listy jírovce. V patě šikmá zlatá mandolína se stříbrným popruhem."
    },
    {
        obec: "Kuželov",
        kod: 586293,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69673,
        latitude: 48.859737,
        longitude: 17.488933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Kuzelov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, žlutý, černý, žlutý a červený, v poměru 2:1:1:1:2. Uprostřed svislý modrý pruh široký dvě pětiny délky listu. V něm bílý větrný mlýn s červenou střechou, žlutými lopatkami, černými dveřmi a oknem, převýšený žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zlatě dělený štít s modrým hrotem, v něm stříbrný větrný mlýn s červenou střechou, zlatými lopatkami, černými dveřmi a oknem převýšený zlatou osmihrotou hvězdou. Nahoře dvě přivrácená zlatá obrněná ramena s orientálními šavlemi, dole černé břevno."
    },
    {
        obec: "Kvasice",
        kod: 588644,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.242324,
        longitude: 17.469853,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Kvasice-znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 8:3:3. V žerďové části horního červeného pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kvasiny",
        kod: 576425,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51702,
        latitude: 50.212594,
        longitude: 16.263334,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Kvasiny_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou rozkřídlenou orlicí sedící na žlutém oválném štítu s černým vydutým hrotem zakončeným tlapatým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná rozkřídlená orlice sedící na zlatém oválném štítu s černým vydutým hrotem zakončeným tlapatým křížem."
    },
    {
        obec: "Kváskovice",
        kod: 536750,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.188506,
        longitude: 14.003793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Kv%C3%A1skovice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutou loveckou trubkou, z níž vyniká bílá kvádrovaná věž s cimbuřím se třemi vyrůstajícími žlutými obilnými klasy. Klasy a věž provázeny pěti (3, 2) bílými sedmicípými hvězdami nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyniká ze zlaté lovecké trubky stříbrná kvádrovaná věž s cimbuřím se třemi vyrůstajícími zlatými obilnými klasy. Klasy a věž provázeny pěti (3, 2) stříbrnými sedmihrotými hvězdami nad sebou."
    },
    {
        obec: "Kvášňovice",
        kod: 541931,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.413124,
        longitude: 13.642076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Kv%C3%A1%C5%A1%C5%88ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žlutá berla závitem k žerďovému okraji, ovinutá bílým sudariem, provázená lilií a svěšenou chmelovou šišticí, obojí bílé. Pod berlou bílý nůž se žlutou rukojetí, hrotem k žerďovému a ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá berla ovinutá stříbrným sudariem, provázená vpravo lilií, vlevo svěšenou chmelovou šišticí, obojí stříbrné. Dole položený stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Květinov",
        kod: 568953,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.564751,
        longitude: 15.50741
    },
    {
        obec: "Květná",
        kod: 578291,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.736321,
        longitude: 16.34626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Kv%C4%9Btn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, v poměru 6:1:3. Uprostřed horního pruhu bílý květ s modrým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrným pětilistým květem s modrým semeníkem a zelenými okvětními lístky dvě zkřížené zlaté berly závity ven."
    },
    {
        obec: "Květnice",
        kod: 564982,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25084,
        latitude: 50.057363,
        longitude: 14.684222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Coat_of_arms_of_Kv%C4%9Btnice.svg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo zubatě pěti čtvercovými zuby a šesti stejnými mezerami dělený list z první osminy žerďového okraje do deváté desetiny dolního okraje listu, se zuby vysokými jednu osminu šířky listu. V žerďovém a vlajícím poli po jednom sedmilistém květu opačných barev se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmým cimbuřovým řezem děleném štítě dva sedmilisté květy opačných barev se zlatými středy."
    },
    {
        obec: "Květov",
        kod: 598801,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.425542,
        longitude: 14.275882,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Kv%C4%9Btov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, zelený a střídavě bílé a modré, v poměru 9 : 1 : 1 : 1 : 1 : 1 : 6. V horním pruhu žlutý pětilistý květ s červeným středem mezi žlutými jeleními parohy, v dolním žlutá osmicípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zvýšeně dělený štít. V horním zeleném poli zlatý pětilistý květ s červeným středem mezi zlatými jeleními parohy. Dolní pole stříbrno-modře dělené, nahoře dvě modrá břevna, dole zlatá osmihrotá hvězda."
    },
    {
        obec: "Kvíčovice",
        kod: 553841,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.590322,
        longitude: 13.073059,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Kv%C3%AD%C4%8Dovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma přivrácenými bílými kvíčalami s červenou zbrojí stojícími na žluté podkově, v ní bílý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě přivrácené stříbrné kvíčaly s červenou zbrojí stojící na zlaté podkově, v ní stříbrný zvon."
    },
    {
        obec: "Kvilda",
        kod: 550337,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38493,
        latitude: 49.019358,
        longitude: 13.57968,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Kvilda_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma vlnitými pruhy, žlutým vycházejícím ze čtvrté devítiny a bílým vycházejícím z šesté devítiny žerďového a vlajícího okraje. Pruhy mají tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlato-zeleno-stříbrně dělené vlnité břevno, nahoře zlatá koruna, dole stříbrný kámen."
    },
    {
        obec: "Kvítkovice",
        kod: 529729,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.957506,
        longitude: 14.33126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Kv%C3%ADtkovice_CB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1 : 3 : 1. V zeleném pruhu žlutý obilný snop se dvěma vyrůstajícími bílými růžemi se žlutými semeníky mezi třemi viditelnými klasy, krajními odkloněnými. Dole snop obklopen obrácenou bílou podkovou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný snop se dvěma vyrůstajícími stříbrnými růžemi se zlatými semeníky mezi třemi viditelnými klasy, krajními odkloněnými. Dole snop obklopen obrácenou stříbrnou podkovou."
    },
    {
        obec: "Kyje",
        kod: 572047,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50713,
        latitude: 50.494389,
        longitude: 15.37809
    },
    {
        obec: "Kvílice",
        kod: 532517,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27375,
        latitude: 50.258155,
        longitude: 14.002429
    },
    {
        obec: "Kyjov",
        kod: 548308,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.629564,
        longitude: 15.623325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Coat_of_arms_of_Kyjov_HB.jpg",
        coatOfArmsFlagDescription: "Žluto-zeleně šikmo dělený list. Ve žlutém poli černé zkřížené ostrve, v zeleném šikmo bílý kyj. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kyjov",
        kod: 586307,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69701,
        latitude: 49.009967,
        longitude: 17.12261,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Kyjov_znak.png",
        coatOfArmsFlagDescription: "Na modrém listu praporu stříbrné obrněné rámě bez rukavice, držící vztyčený kyj přirozené (hnědé) barvy se čtyřmi suky."
    },
    {
        obec: "Kvítkov",
        kod: 546259,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47001,
        latitude: 50.655024,
        longitude: 14.486766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Kv%C3%ADtkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, dělené modro-bíle a bílo-modře, v poměru 11 : 5. V žerďovém modrém poli vzpřímený černý vlk s červenou zbrojí. V žerďovém bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Kyjov",
        kod: 596001,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.443544,
        longitude: 15.881657
    },
    {
        obec: "Kyjovice",
        kod: 594318,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.913818,
        longitude: 16.166617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Kyjovice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů zelený, zubatý bílý, zelený, bílý, zelený a bílý, v poměru 8 : 3 : 1 : 1 : 1 : 2. Ze zubatého pruhu s osmi čtvercovými zuby a sedmi stejnými mezerami vyrůstá žlutý lev s červenou zbrojí, držící v pravé tlapě šikmo bílý nůž ostřím nahoru a hrotem k hornímu cípu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze stříbrné hradby zlatý lev s červenou zbrojí, držící v pravé tlapě šikmo stříbrný nůž. V hradbě vztyčený zelený vinný dvojlist."
    },
    {
        obec: "Kynice",
        kod: 568970,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.739281,
        longitude: 15.360244
    },
    {
        obec: "Kyjovice",
        kod: 512907,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74768,
        latitude: 49.832445,
        longitude: 18.042227,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Kyjovice_OP_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým jelenem se žlutou zbrojí a svislým vlajícím bílým plamenným pruhem širokým jednu šestinu délky listu se stejně vysokými plameny. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou patou oddělenou plamenným řezem vykračující stříbrný jelen se zlatou zbrojí."
    },
    {
        obec: "Kynšperk nad Ohří",
        kod: 560499,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35751,
        latitude: 50.119038,
        longitude: 12.530388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Kyn%C5%A1perk_nad_Oh%C5%99%C3%AD_-_CoA.png",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými vlajícími klíny vycházejícími z polovin vlajícího okraje a s vrcholy v polovině délky listu. V žerďové polovině český lev. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Kyselka",
        kod: 555347,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36272,
        latitude: 50.262803,
        longitude: 13.000007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Kyselka_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a zvlněný modrý, v poměru 7:5:2. Ve žlutém pruhu sedící k vlajícímu okraji obrácená ohlížející se černá rozkřídlená orlice s červenou zbrojí. V červeném pruhu čelně žlutá lví hlava chrlící modrou vodu do modrého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě nahoře doleva sedící ohlížející se černá rozkřídlená orlice s červenou zbrojí, dole čelně zlatá lví hlava chrlící modrou vodu."
    },
    {
        obec: "Kyselovice",
        kod: 588652,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76811,
        latitude: 49.377252,
        longitude: 17.402164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/CoA_of_Kyselovice.svg",
        coatOfArmsFlagDescription: "Modrý list ve střední části s bílou lilií převýšenou dvěma žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie provázená nahoře dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Kyšice",
        kod: 559130,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33001,
        latitude: 49.753353,
        longitude: 13.486312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Ky%C5%A1ice_PM_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed u dolního okraje listu rošt, provázený v horní části listu dvěma lípami, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě rošt provázený nahoře dvěma lípami, vše stříbrné."
    },
    {
        obec: "Kyšice",
        kod: 532525,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.091291,
        longitude: 14.106343,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Ky%C5%A1ice_%28okres_Kladno%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený s bílým parohem, modrý s půlí bílého mlýnského kola přilehajícího k červenému pruhu s prázdným otvorem pro vodorovnou kypřici a bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě se stříbrným lemem, nahoře cimbuřovým, vpravo jelení paroh, vlevo od středu vyniká půl mlýnského kamene s prázdným otvorem pro položenou kypřici, obojí stříbrné."
    },
    {
        obec: "Kyškovice",
        kod: 565091,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.448307,
        longitude: 14.285987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Kyskovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým šikmým pruhem, vycházejícím z první třetiny dolního okraje do třetí třetiny horního okraje listu, a žlutým vlajícím trojúhelníkovým polem s vrcholy v polovině vlajícího okraje a ve druhé třetině dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře šikmo děleném štítě nahoře modrý vinný hrozen na zeleném stonku s úponkem vpravo a listem vlevo, dole šikmo plovoucí zlatý kapr."
    },
    {
        obec: "Kytlice",
        kod: 562645,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40745,
        latitude: 50.812746,
        longitude: 14.53547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Kytlice_znak.jpg",
        coatOfArmsFlagDescription: "Žluto - modře šikmo dělený list. V horním rohu kosmo položená ostrev šikmo přeložená sklářskou píšťalou, obojí černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře šikmo vlnitě děleném štítě nahoře kosmo položená ostrev šikmo přeložená sklářskou píšťalou, obojí černé."
    },
    {
        obec: "Labská Stráň",
        kod: 544701,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.848754,
        longitude: 14.231709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Labsk%C3%A1_Str%C3%A1%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený bílým vlnitým pruhem širokým jednu třetinu šířky listu na žerďové červené a vlající modré pole se žlutou šesticípou hvězdou v horním cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrno-červeně dvakrát kosmo vlnitě dělený štít, v modrém poli zlatá hvězda."
    },
    {
        obec: "Labské Chrčice",
        kod: 575259,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 28126,
        latitude: 50.051519,
        longitude: 15.410279,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Labsk%C3%A9_Chr%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný červený, v poměru 4 : 1 : 3. V modrém pruhu běžící bílý chrt s červenou zbrojí a obojkem s kroužkem. V červeném pruhu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně sníženým stříbrným vlnitým břevnem dělený štít. Nahoře běžící stříbrný chrt s červenou zbrojí a obojkem s kroužkem, dole stříbrná lilie."
    },
    {
        obec: "Labuty",
        kod: 586315,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69648,
        latitude: 49.046739,
        longitude: 17.214588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Labuty_-_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou labutí s červenou zbrojí držící v zobáku žlutý vinný hrozen na stonku s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná labuť s červenou zbrojí držící v zobáku zlatý vinný hrozen na stonku s jedním listem."
    },
    {
        obec: "Lačnov",
        kod: 543098,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.178879,
        longitude: 18.016608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/CZ_La%C4%8Dnov_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: modrý, zelený a žlutý v poměru 1:3:1. V zeleném pruhu bílý kůň ve skoku se žlutými kopyty, hřívou a vztyčeným ocasem a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou patou stříbrný kůň ve skoku s červeným jazykem, zlatou hřívou, ocasem a kopyty, provázený dvěma zlatými vyrůstajícími orobinci."
    },
    {
        obec: "Kytín",
        kod: 571261,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.850721,
        longitude: 14.219156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Kyt%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý klínový s vrcholem v desetině šířky listu a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 2 : 2 : 1. Z prostředního vrcholu modrého pruhu vyrůstá kytice tří červených růží se žlutými semeníky na zelených stoncích s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod hrotem oddělenou zelenou hlavou vyrůstá ze snížené modré vlnité paty kytice tří červených růží se zlatými semeníky na zelených stoncích s listy."
    },
    {
        obec: "Ladná",
        kod: 558443,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69146,
        latitude: 48.805496,
        longitude: 16.872412,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Coat_of_arms_of_Ladna.jpeg",
        coatOfArmsFlagDescription: "Červený list s bílou radlicí ostřím k hornímu okraji a hrotem k vlajícímu okraji, provázenou nahoře dvěma bílými pětilistými květy se žlutými středy, pod ní postavený bílý nůž - kosíř se žlutou rukojetí ostřím k žerďovému okraji, provázený dvěma žlutými vinnými hrozny, každý na bílém stonku s odvráceným listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě doleva položená stříbrná radlice, provázená nahoře dvěma stříbrnými pětilistými květy se zlatými středy, pod ní doleva obrácený stříbrný vinařský nůž - kosíř se zlatou rukojetí, provázený dvěma zlatými vinnými hrozny, každý na stříbrném stonku s odvráceným listem."
    },
    {
        obec: "Lahošť",
        kod: 567647,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41725,
        latitude: 50.618346,
        longitude: 13.765404,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Laho%C5%A1%C5%A5_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a sedm vodorovných pruhů střídavě bílých a červených. V modrém pruhu žlutý žerďový klín vycházející z druhé až šesté sedminy žerďového okraje s vrcholem na vlajícím okraji modrého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře děleném štítě nahoře tři červené kůly, dole zlatý keltský kotlík."
    },
    {
        obec: "Lampertice",
        kod: 548804,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54101,
        latitude: 50.664684,
        longitude: 15.951068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Lampertice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a bílý v poměru 3:2. V modrém pruhu odvrácený bílý Beránek Boží a v bílém tři červené pletené úly vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrný sníženě dělený štít, nahoře stříbrný odvrácený Beránek Boží, dole tři červené pletené úly vedle sebe."
    },
    {
        obec: "Lančov",
        kod: 594326,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.907159,
        longitude: 15.767901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Znak_mesta_lancov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 3:2:3. Přes všechny pruhy dvě zkřížené žluté ostrve, mezi nimi v červeném pruhu žluté ciborium. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrno-modře děleném štítě zkřížené ostrve, nad nimi ciborium, vše zlaté."
    },
    {
        obec: "Lánov",
        kod: 579432,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54341,
        latitude: 50.620172,
        longitude: 15.65585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Lanov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy střídavě modré a bílé. Uprostřed přes všechny pruhy žlutá podkova. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horní zelené polovině kovář v bílé haleně a hnědé zástěře a v botách probodávající modrým kopím hnědého vztyčeného medvěda, v dolním třikrát modro-stříbrně děleném poli zlatá podkova."
    },
    {
        obec: "Lanškroun",
        kod: 580511,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.912141,
        longitude: 16.612597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Lan%C5%A1kroun.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, červený a žlutý v poměru 1:2:1. Ve středu červeného pruhu je žlutá koruna z městského znaku o šířce jedné třetiny délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lány",
        kod: 548481,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.759535,
        longitude: 15.712996,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Coat_of_arms_of_L%C3%A1ny_HB.jpg",
        coatOfArmsFlagDescription: "Zelený list s krokví vycházející z poslední osminy žerďového a vlajícího okraje listu s vrcholem uprostřed listu, z ní vyrůstá lípa, pod krokví zvon se závěsem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě lípa vyrůstající ze snížené krokve provázené dole zvonem se závěsem, vše zlaté."
    },
    {
        obec: "Lány",
        kod: 504807,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.94646,
        longitude: 15.727244,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/L%C3%A1ny_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 6:1:6. V modrém a červeném pruhu po jednom žlutém obilném klasu se dvěma listy. Přes klasy a pod bílým pruhem vodorovný žlutý pruh široký jednu osmnáctinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně stříbrným kůlem polcený štít. Na kůl navlečena kypřice podložená po stranách jedním obilným klasem se dvěma listy, vše zlaté."
    },
    {
        obec: "Lány",
        kod: 541991,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27061,
        latitude: 50.124811,
        longitude: 13.950519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Znak_obce_lany_KL_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený v poměru 1:2:1. V bílém poli květ jetele na stonku s jedním listem přirozených barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný rozšířený kůl, v něm květ jetele na stonku s jedním listem přirozených barev."
    },
    {
        obec: "Lány u Dašic",
        kod: 572845,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.043622,
        longitude: 15.889187
    },
    {
        obec: "Lanžhot",
        kod: 584622,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69151,
        latitude: 48.724531,
        longitude: 16.967053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Znak_m%C4%9Bsta_Lan%C5%BEhot.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - zelený, žlutý a zelený v poměru 1:3:1. Ve žlutém poli je zelený dubový list pět sedmin šířky listu vysoký a jednu pětinu délky listu široký. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě do kruhu stočená dubová ratolest se třemi žaludy v přirozených barvách."
    },
    {
        obec: "Lanžov",
        kod: 579441,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.386918,
        longitude: 15.760854,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Lan%C5%BEov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, černý, bílý a zelený, v poměru 2 : 1 : 4 : 1 : 8. V černém pruhu pět (2, 3) dotýkajících se žlutých čtvercových polí. V dolním pruhu žlutý prsten s červeným kamenem, v něm vztyčený žlutý lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod černým stříbrně lemovaným, nahoru prohnutým břevnem s pěti (2, 3) dotýkajícími se zlatými kostkami zlatý prsten s červeným kamenem. V prstenu vztyčený zlatý lipový list."
    },
    {
        obec: "Lásenice",
        kod: 546623,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37801,
        latitude: 49.074391,
        longitude: 14.970283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/L%C3%A1senice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V žerďové části bílého pruhu zelený vavřínový věnec kvetoucí čtyřmi (1,2,1) žlutými růžemi s červenými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vavřínový věnec kvetoucí čtyřmi (1,2,1) zlatými růžemi s červenými semeníky."
    },
    {
        obec: "Laškov",
        kod: 589675,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79857,
        latitude: 49.584113,
        longitude: 17.002235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/La%C5%A1kov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, modrý a žlutý v poměru 1:2:1. V modrém pruhu žlutá rozletitá střela nad bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá rozletitá střela nad stříbrnou lilií."
    },
    {
        obec: "Lašovice",
        kod: 542008,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27021,
        latitude: 50.05506,
        longitude: 13.78231
    },
    {
        obec: "Lavičky",
        kod: 596019,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.380597,
        longitude: 15.968444,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Lavi%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý, zvlněný žlutý, bílý, zvlněný modrý, bílý a zvlněný modrý, v poměru 4:5:4:5:4:5. Zvlněné pruhy mají čtyři vrcholy a pět prohlubní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít nahoře modrý, dole třikrát stříbrno-modře dělený vlnitým, rovným a vlnitým řezem. Nahoře přes dělící čáru kráčející zlatý lev s červenou zbrojí."
    },
    {
        obec: "Lavičné",
        kod: 578304,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.643678,
        longitude: 16.462574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Lavi%C4%8Dn%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žlutá římská přilba s červeným hřebenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s červenou hlavou se stříbrným heroldským křížem zlatá římská přilba s červeným hřebenem."
    },
    {
        obec: "Láz",
        kod: 510556,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67541,
        latitude: 49.012392,
        longitude: 15.785165,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/L%C3%A1z_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s kosmým černým ostrvovým pruhem se čtyřmi odvrácenými suky, širokým jednu desetinu šířky listu, přeloženým šikmým modrým pruhem, širokým jednu čtvrtinu šířky listu, se šikmým žlutým obilným klasem se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kosmé černé ostrvové břevno se čtyřmi odvrácenými suky, šikmo přeložené modrou vztyčenou radlicí se šikmým zlatým klasem se dvěma listy."
    },
    {
        obec: "Láz",
        kod: 540625,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26241,
        latitude: 49.652138,
        longitude: 13.916712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Znak_laz.png",
        coatOfArmsFlagDescription: "List tvoří žerďový vypouklý modrý pruh, široký jednu pětinu délky listu na horním a dolním okraji s obloukem sahajícím do jedné třetiny délky listu, a pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou vypouklou hlavou se stříbrnými zkříženými hornickými kladívky modrý vlnitý kůl, provázený po obou stranách zeleným smrkem vyrůstajícím ze zeleného návrší."
    },
    {
        obec: "Lazinov",
        kod: 581887,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.563913,
        longitude: 16.523495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Lazinov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený vlnitý a modrý v poměru 7:2:7. Vlnitý pruh má pět vrcholů a šest prohlubní, výška vln je jedna dvaatřicetina šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře vlnitým červeným břevnem dělený štít, nahoře černé položené orlí křídlo se zlatou pružinou."
    },
    {
        obec: "Lázně Bělohrad",
        kod: 573094,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50781,
        latitude: 50.428843,
        longitude: 15.582797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Znak_m%C4%9Bsta_L%C3%A1zn%C4%9B_B%C4%9Blohrad.jpg",
        coatOfArmsFlagDescription: "Bílo-červeně čtvrcený list. Uprostřed zelený vodorovný řetězový pruh se čtyřmi oblouky široký jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lázně Bohdaneč",
        kod: 574767,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.075702,
        longitude: 15.67989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Znak_L%C3%A1zn%C3%AD_Bohdane%C4%8D.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a červeno-bíle šindelovaný (3 x 3). V černém pruhu k vlajícímu okraji listu nakloněný zužující se žlutý žebřík o šesti příčkách. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Lázně Kynžvart",
        kod: 554600,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35491,
        latitude: 50.010693,
        longitude: 12.624853,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/L%C3%A1zn%C4%9B_Kyn%C5%BEvart_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, uprostřed listu polovina žlutého lva s bílou zbrojí a žlutým jazykem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Lázně Libverda",
        kod: 564206,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46362,
        latitude: 50.890241,
        longitude: 15.190471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Lazne_Libverda_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modro-žlutě šikmo dělený list s bílým šikmým pruhem širokým jednu čtvrtinu délky listu, jehož horní okraj tvoří úhlopříčku listu. V modrém žerďovém poli žlutý kráčející kohout s červenou zbrojí. Přes bílé a žluté pole červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté zřídlo se dvěma stříbrnými prameny, přeložené červeným srdcem. Na pramenech kráčející zlatý kohout s červenou zbrojí."
    },
    {
        obec: "Lázně Toušeň",
        kod: 538914,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25089,
        latitude: 50.16937,
        longitude: 14.716112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Lazne_tousen_znak.gif",
        coatOfArmsFlagDescription: "Po celé délce půlené, bílo-modře, uprostřed symbol lázeňského pramene střídavých barev.",
        coatOfArmsDescription: "Štít dělený, nahoře polcený. V prvním červeném poli český lev, ve druhém stříbrném poli na černé půdě modrá fontána s modrým tryskajícím pramenem. V dolním zlatém poli modré dvojité vlnité břevno."
    },
    {
        obec: "Lazníčky",
        kod: 514446,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75125,
        latitude: 49.545565,
        longitude: 17.460189,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Lazn%C3%AD%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký čtvrtinu délky listu a modré pole. V červeném pruhu bílá šavle - malchus se žlutým jílcem, v modrém poli žlutá radlice, oboje hrotem dolů. Radlice se dotýká špicí bílý srp se žlutou rukojetí, vše ostřím k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s červenou hlavou s doleva položenou stříbrnou šavlí - malchusem se zlatým jílcem. Dole vpravo zlatá radlice, vlevo stříbrný srp se zlatou rukojetí dotýkající se špicí radlice."
    },
    {
        obec: "Lazníky",
        kod: 514471,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75125,
        latitude: 49.524536,
        longitude: 17.462787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Lazn%C3%ADky_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - bílý a modrý. Ve středu bílého pole modrá růže se žlutým semeníkem, ve středu modrého pole dvě bílé růže se žlutým semeníkem tak, že s modrou růží tvoří rovnoramenný trojúhelník. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě postava až po stehna ve stříbrné plátové zbroji a se stříbrnou kolčí přilbou, mající na prsou modrou růži se zlatým semeníkem a provázená nahoře dvěma stříbrnými růžemi se zlatými semeníky."
    },
    {
        obec: "Lazsko",
        kod: 564346,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.623599,
        longitude: 14.003954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Coats_of_arms_Lazsko.jpeg",
        coatOfArmsFlagDescription: "Zelený list; uprostřed žlutá věž s cimbuřím, černou gotickou bránou a dvěma černými otvory se žlutými kladkami. V bráně vztyčená žlutá kuše s napínacím třmenem a nenapnutou tětivou, oboje bílé, podložená vodorovně dvěma bílými šípy se žlutými hroty a opeřením, horní k žerďovému, dolní k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá věž s cimbuřím s černou gotickou branou provázenou nahoře dvěma černými otvory se zlatými kladkami, v bráně vztyčená zlatá kuše s napínacím třemenem a nenapnutou tětivou, oboje stříbrné, podložená dvěma položenými stříbrnými šípy se zlatými hroty a opeřením, dolní doleva."
    },
    {
        obec: "Lažánky",
        kod: 598950,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.391505,
        longitude: 13.836837,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 4 : 1. V bílém pruhu na zeleném návrší vyrůstajícím z dolního okraje pruhu červená cihlová vápenná pec se šedou kamennou podstavou mezi jehličnatým a listnatým stromem, oba zelené s hnědými kmeny. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Lažánky",
        kod: 583260,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66471,
        latitude: 49.279046,
        longitude: 16.388167,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/La%C5%BE%C3%A1nky_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 4 : 1. V bílém pruhu na zeleném návrší vyrůstajícím z dolního okraje pruhu červená cihlová vápenná pec se šedou kamennou podstavou mezi jehličnatým a listnatým stromem, oba zelené s hnědými kmeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě stojí na zeleném pahorku cihlová pec s kamennou podstavou mezi listnatým a jehličnatým stromem v přirozených barvách."
    },
    {
        obec: "Lažany",
        kod: 560197,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.36295,
        longitude: 13.883237
    },
    {
        obec: "Lažany",
        kod: 581909,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.354468,
        longitude: 16.550117,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/CoA_of_La%C5%BEany_%28okres_Blansko%29.svg",
        coatOfArmsFlagDescription: "List dělený bílým kosmým pruhem, širokým jednu čtvrtinu šířky listu na žerďové modré a vlající červené pole. V modrém poli maltézský kříž, v červeném lomený volný hřeben se sedmi zuby hroty k hornímu okraji listu, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře kosmým stříbrným vlnitým břevnem dělený štít, nahoře volný hřeben, dole maltézský kříž, vše stříbrné."
    },
    {
        obec: "Lažany",
        kod: 545937,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.601,
        longitude: 15.109852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/La%C5%BEany_LI_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 10 : 1. Uprostřed listu bílý vykořeněný pahýl jabloně, z něhož k žerďovému okraji vyrůstá větev se třemi listy a žlutým plodem. Mezi pahýlem a vlajícím pruhem žlutý dvouocasý lev s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vykořeněný pahýl jabloně, z něhož vpravo vyrůstá větev se třemi listy a se zlatým plodem, vlevo provázený zlatým dvouocasým lvem s červenou zbrojí a převýšený položeným zlatým obilným klasem se dvěma listy."
    },
    {
        obec: "Lažiště",
        kod: 550345,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38432,
        latitude: 49.03766,
        longitude: 13.92781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/La%C5%BEi%C5%A1t%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List dělený bílou krokví vycházející ze sedmé osminy žerďového a vlajícího okraje a s vrcholem ve středu listu na horní zelené a dolní červené pole. V zeleném poli v třetině délky listu vyniká z krokve berla závitem k žerďovému okraji listu, v druhé třetině délky listu tři (2, 1) kruhová pole, vše žluté. V červeném poli bílá šestilistá růžice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně sníženou stříbrnou krokví děleném štítě nahoře vpravo vynikající berla, vlevo tři (2, 1) koule, vše zlaté, dole stříbrná šestilistá růžice."
    },
    {
        obec: "Lážovice",
        kod: 533939,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.85831,
        longitude: 14.071383
    },
    {
        obec: "Lčovice",
        kod: 561649,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.114639,
        longitude: 13.850712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/L%C4%8Dovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou krokví vycházející z první čtvrtiny horního a dolního okraje a s vrcholem ve středu vlajícího okraje listu. V žerďovém klínu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno. Nahoře lilie a sedmihrotá hvězda, obojí zlaté. Dole stříbrný kapr."
    },
    {
        obec: "Ledce",
        kod: 583278,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66462,
        latitude: 49.051603,
        longitude: 16.55712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Znak_obce_Ledce.svg",
        coatOfArmsFlagDescription: "Žlutý list s polovinou hnědého medvěda držícího před sebou modrý vinný hrozen se zeleným stonkem, listem a úponkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě polovina medvěda přirozené barvy držící před sebou modrý vinný hrozen se zeleným stonkem, listem a úponkem."
    },
    {
        obec: "Ledce",
        kod: 576433,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.223913,
        longitude: 16.04283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Ladce_CoA_HK_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový klín s vrcholem v jedné šestině délky listu, žlutá krokev vycházející z první šestiny horního a dolního okraje listu s vrcholem v třetině délky listu a pět vodorovných pruhů, modrý, bílý, červený, bílý a modrý, v poměru 3 : 1 : 2 : 1 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížená zlatá krokev na vrcholu s makovicí a praporkem, z níž nahoru vyrůstají dvě věže, každá se dvěma okny a stanovou střechou zakončenou makovicí a do středu vlajícím praporkem, dole vyrůstající věž se dvěma okny a stanovou střechou zakončenou křížkem, věže stříbrné, okna černá, střechy červené, makovice, trojúhelníkové praporky a křížek zlaté."
    },
    {
        obec: "Ledce",
        kod: 559148,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33014,
        latitude: 49.821738,
        longitude: 13.328535
    },
    {
        obec: "Ledce",
        kod: 532533,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27305,
        latitude: 50.196529,
        longitude: 14.005095
    },
    {
        obec: "Ledce",
        kod: 536202,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29447,
        latitude: 50.353466,
        longitude: 15.084579
    },
    {
        obec: "Ledčice",
        kod: 534978,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27708,
        latitude: 50.341051,
        longitude: 14.294576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Ledcice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modro-červeně čtvrcený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a červený. V horním žerďovém modrém poli půl žluté lilie přiléhající k hornímu červenému poli s bílým řeckým křížem nad žlutým kalichem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo polovina zlaté lilie vynikající od poltící linie, vlevo stříbrný řecký kříž nad zlatým kalichem."
    },
    {
        obec: "Ledeč nad Sázavou",
        kod: 568988,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.695269,
        longitude: 15.277827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Znak_m%C4%9Bsta_Lede%C4%8D_nad_S%C3%A1zavou.png",
        coatOfArmsFlagDescription: "Červený list s bílým pravoúhle lomeným pruhem širokým jednu devítinu délky listu a vycházejícím z druhé devítiny horního okraje a končícím na druhé dolní šestině vlajícího okraje listu. V takto vymezeném horním poli bílý leknínový trojlist jedním listem dolů. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ledečko",
        kod: 534170,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.84992,
        longitude: 14.94898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Lede%C4%8Dko_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelené karé s čelně postavenou bílou radlicí nad stejně velkým bílým čtvercovým polem s černým koněm ve skoku a dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Horní pole zeleno-stříbrně polcené, vpravo čelně postavená stříbrná radlice, vlevo černý kůň ve skoku. V dolním, stříbrno-zeleně polceném poli modro-stříbrně polcené vlnité břevno."
    },
    {
        obec: "Lednice",
        kod: 584631,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69144,
        latitude: 48.800018,
        longitude: 16.803498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Lednice_CoA.png",
        coatOfArmsFlagDescription: "Červeno-žlutě šindelovaný (3x5) list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě sedm ze středu vyrůstajících žaludů přirozené barvy, střídavě čtyři malé a tři velké. Dole třemi řadami černo-zlatě šachovaný kůl."
    },
    {
        obec: "Ledenice",
        kod: 544736,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37311,
        latitude: 48.932311,
        longitude: 14.618133,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Ledenice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1 : 3 : 1. V červeném pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Lechotice",
        kod: 588661,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76852,
        latitude: 49.273774,
        longitude: 17.588001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Lechotice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, černý, žlutý, modrý, žlutý a černý v poměru 1:1:6:1:1. Uprostřed modrého pruhu dva zkřížené bílé nože s červenými rukojeťmi, hroty a ostřím nahoru, provázené čtyřmi žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou s rostoucí černou korunovanou orlicí s červenou zbrojí zkřížené stříbrné nože s červenými rukojeťmi, hroty a ostřím nahoru, provázené čtyřmi zlatými osmihrotými hvězdami."
    },
    {
        obec: "Ledvice",
        kod: 567655,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41772,
        latitude: 50.585119,
        longitude: 13.772139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Ledvice_znak.png",
        coatOfArmsFlagDescription: "Zeleným kosmým pruhem širokým jednu čtvrtinu šířky listu dělený list na černé žerďové a bílé vlající pole. V zeleném pruhu šikmo tři žluté růže. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lechovice",
        kod: 594334,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67163,
        latitude: 48.872953,
        longitude: 16.221921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Lechovice_CoA.jpg",
        coatOfArmsFlagDescription: "Kosmo a šikmo bílo-zeleně dělený list, v žerďovém a vlajícím poli po jednom černém orlím křídle, v horním poli lilie, v dolním vinný hrozen, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrným zkráceným klínem s černým korunovaným orlem se zlatou zbrojí vinný hrozen provázený dvěma liliemi, vše zlaté."
    },
    {
        obec: "Lejšovka",
        kod: 570222,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.297761,
        longitude: 15.942389
    },
    {
        obec: "Lelekovice",
        kod: 583286,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66431,
        latitude: 49.291537,
        longitude: 16.578848,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Lelekovice_upravit.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a zubatý červený, v poměru 1:2:1. V modrém pruhu vztyčený bílý hrot kopí s hákem, hákem k žerďovému okraji. Ve žlutém pruhu černá býčí hlava s krkem. Červený pruh má tři čtvercové zuby a dvě stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě vpravo polceném štítě vpravo stříbrný hrot kopí s hákem, vlevo nad červenou kvádrovanou hradbou s cimbuřím černá býčí hlava s krkem."
    },
    {
        obec: "Leskovec nad Moravicí",
        kod: 597546,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79368,
        latitude: 49.905954,
        longitude: 17.574652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Leskovec_nad_Moravic%C3%AD_znak.png",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole bílé, dolní žerďové černé, horní vlající pole červené, dolní vlající žluté. Na středu hnědý vykořeněný strom se zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Čtvrcený štít, první pole stříbrné, druhé pole červené, třetí pole černé, čtvrté pole zlaté. V něm přirozený vykořeněný listnatý strom."
    },
    {
        obec: "Leskovice",
        kod: 548235,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39414,
        latitude: 49.43,
        longitude: 15.08121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Leskovice_%28Pelh%C5%99imov_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou volnou kaplí s černým obloukovým vchodem, žlutou stanovou střechou s bílou věžičkou s černým čtvercovým oknem a žlutou stanovou stříškou zakončenou bílým křížkem. Po obou stranách kaple vyrůstá z dolního okraje listu žlutá lipová větev, každá střídavě se třemi listy a dvěma plodenstvími. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volná stříbrná kaple s černým obloukovým vchodem, zlatou stanovou střechou se stříbrnou věžičkou s černým čtvercovým oknem a zlatou stanovou stříškou zakončenou stříbrným křížkem. Po obou stranách kaple vyrůstá zlatá lipová větev, každá střídavě se třemi listy a dvěma plodenstvími."
    },
    {
        obec: "Lesná",
        kod: 594342,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.907277,
        longitude: 15.868824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/CoA_of_Lesn%C3%A1_%28okres_Znojmo%29.svg",
        coatOfArmsFlagDescription: "Modrý list, v něm do kříže čtyři bílé lopatky větrného mlýna. Uprostřed žlutá růže s červeným semeníkem a zelenými kališními lístky, z níž mezi lopatky vynikají čtyři bílé hůlky zakončené žlutými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě do kříže čtyři stříbrné lopatky větrného mlýna. Uprostřed zlatá růže s červeným semeníkem a zelenými kališními lístky, z níž mezi lopatky vynikají čtyři stříbrné hůlky zakončené zlatými liliemi."
    },
    {
        obec: "Leskovec",
        kod: 544264,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.284384,
        longitude: 17.998777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/CZ_Leskovec_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 4:1:1. V žerďové polovině horního modrého pruhu tři svěšené spojené žluté lískové ořechy se stopkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným břevnem tři svěšené zlaté lístkové ořechy na jednom stonku."
    },
    {
        obec: "Lesná",
        kod: 562041,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.518016,
        longitude: 15.057662
    },
    {
        obec: "Lenora",
        kod: 550353,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38442,
        latitude: 48.922415,
        longitude: 13.79337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Lenora_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, modrý, bílý a červený, v poměru 1:1:1:1:8. V žerďové polovině červeného pruhu bílá amfora. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole třikrát modro-stříbrně polcené, v levém červeném poli stříbrná amfora."
    },
    {
        obec: "Lenešice",
        kod: 566322,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43923,
        latitude: 50.375267,
        longitude: 13.766007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Lenesice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 11 : 3. Uprostřed listu žlutý obilný snop, z něhož vynikají bílé hrábě, přeložený kosmo kosou a šikmo cepem, obojí bílé. Z obou stran snopu vyniká po jednom zeleném chmelovém listu se třemi šišticemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku postavený zlatý obilný snop, podložený vztyčenými stříbrnými hráběmi a přeložený zkříženými stříbrnými kosou a cepem po stranách, z obou stran snopů vyniká po jednom zeleném chmelovém listu se třemi šišticemi."
    },
    {
        obec: "Lesná",
        kod: 590983,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.171418,
        longitude: 15.679494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Lesn%C3%A1_%28T%C5%99eb%C3%AD%C4%8D_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 2 : 5 : 2. Žerďový a vlající pruh jsou žluté, prostřední tvoří čtyři vodorovné pruhy, bílý, smrkový zelený se čtyřmi vrcholy, vlnitý bílý a zvlněný zelený, v poměru 3 : 7 : 1 : 1. Vrcholy smrkového pruhu zasahují do první dvanáctiny šířky listu, vlnitý pruh má dva vrcholy a tři prohlubně. Mezi vrcholy smrkového pruhu vztyčené černé vlčí háky. Uprostřed smrkového pruhu krojidlo a radlice, obojí hrotem k hornímu a ostřím k vlajícímu okraji, nahoře provázené vztyčenými lipovými listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrnou hlavou se třemi postavenými černými vlčími háky, oddělenou smrkovým řezem, vztyčené, doleva obrácené krojidlo a radlice, nahoře provázené vztyčenými lipovými listy, vše zlaté, nad stříbrným vlnitým břevnem."
    },
    {
        obec: "Lesná",
        kod: 561002,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.754286,
        longitude: 12.537785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Lesna_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1:2:1. Ve žlutých pruzích červeně hořící černá ostrev se třemi suky se zelenými listy směřujícími v žerďovém pruhu k žerďovému a ve vlajícím pruhu k vlajícímu okraji listu. V zeleném pruhu žlutý korunovaný dvouocasý lev s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně vpravo polceném štítě vpravo vztyčená nahoře červeně hořící černá ostrev se třemi pravými suky se zelenými listy, vlevo zlatý korunovaný dvouocasý lev s červenou zbrojí."
    },
    {
        obec: "Lesní Hluboké",
        kod: 583294,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.268807,
        longitude: 16.306928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Lesni_Hluboke_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený s bílým křížem a červený se žlutou radlicí ostřím k dolnímu a hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně zvýšeně dělený štít, nahoře devět stříbrných křížů, prostřední nejvyšší, ostatní ustupující. Dole zlatá, doleva položená radlice."
    },
    {
        obec: "Lesní Jakubov",
        kod: 590991,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67573,
        latitude: 49.205878,
        longitude: 16.243465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/CoA_of_Lesn%C3%AD_Jakubov%2C_T%C5%99eb%C3%AD%C4%8D_District.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený pilovitý s pěti zuby vysokými šestinu délky listu, v poměru 1 : 2. V zeleném pruhu srdce kvetoucí třemi pětilistými květy na stoncích mezi dvěma listy, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod pilovitou hlavou srdce kvetoucí třemi pětilistými květy na stoncích mezi dvěma listy, vše stříbrné."
    },
    {
        obec: "Lesnice",
        kod: 537284,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.88386,
        longitude: 16.941325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/LesniceZnak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a modrých, v poměru 1:1:4:1:1. V prostředním pruhu modrá svatojakubská mušle provázená dvěma červenými květy okrotice červené na zeleném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nahoře dvě modrá břevna, přes ně orlí křídlo třikrát černo-stříbrně dělené, dole modrá svatojakubská mušle provázená dvěma červenými květy okrotice červené na zeleném stonku."
    },
    {
        obec: "Lesonice",
        kod: 594351,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.003355,
        longitude: 16.310735,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Lesonice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se středovým křížem s rameny širokými čtvrtinu šířky listu. Svislá ramena kříže jsou bílá, vodorovná žluto-červeně dělená. Uprostřed listu černé čtvercové pole široké polovinu šířky listu se žlutým zvonem se závěsem. V horních zelených polích po jednom lipovém listu, v dolních po jednom javorovém listu, vše vztyčené žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze zlato-červeně polcené paty vyrůstá stříbrná věž s červenou stanovou střechou a černým obloukovým oknem, v něm zlatý zvon se závěsem. Věž je provázena čtyřmi vztyčenými listy, nahoře lipovými, dole javorovými, vše zlaté. Dole černé zkřížené ostrve."
    },
    {
        obec: "Lesonice",
        kod: 591009,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.107958,
        longitude: 15.755986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Znak_obce_Lesonice.gif",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený pilovitý, bílý, zelený, žlutý a zelený, v poměru 9:3:1:2:1:2. V horním bílém pruhu hnědý skákající jelen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně pilovitě děleném štítě nahoře skákající jelen přirozené barvy, dole šikmo položená stříbrná pila - kaprovka se zlatými rukojeťmi, kosmo přeložená zlatou ostrví."
    },
    {
        obec: "Lestkov",
        kod: 561011,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34953,
        latitude: 49.883163,
        longitude: 12.871116,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Lestkov_CoA.png",
        coatOfArmsFlagDescription: "Červený list, na dolním okraji zelené trojvrší, na něm vykračující bílá labuť se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Lesůňky",
        kod: 573485,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.107167,
        longitude: 15.84245,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Znak_obce_Les%C5%AF%C5%88ky.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílá hlava psa věžníka se žlutým jazykem a trnitým obojkem, provázená v červeném pruhu kovářskými kleštěmi a v zeleném pruhu kladivem, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě nad zlatou kovadlinou stříbrná hlava psa věžníka se zlatým jazykem a trnitým obojkem, provázená kovářskými kleštěmi a kladivem, obojí zlaté."
    },
    {
        obec: "Lešany",
        kod: 589683,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79842,
        latitude: 49.499779,
        longitude: 17.024171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Le%C5%A1any_PV_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma kosmými žlutými pruhy vycházejícími z první a třetí osminy žerďového okraje do šesté a osmé osminy dolního okraje, podložené dvěma šikmými bílými pruhy vycházejícími z první a třetí osminy dolního okraje do první a třetí osminy vlajícího okraje listu. Nad nimi lilie převýšená korunou, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě čtyři (2+2) překřížené stříbrné řemdihy se zlatými bodci, nad nimi lilie převýšená korunou, obojí zlaté."
    },
    {
        obec: "Lešany",
        kod: 530051,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25744,
        latitude: 49.844269,
        longitude: 14.525051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Le%C5%A1any_%28Bene%C5%A1ov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený. V bílém pruhu černá kančí hlava s bílou zbrojí obrácená k vlajícímu okraji listu, pod ní v šesté až sedmé osmině šířky listu tři vodorovné pruhy, černý, žlutý a černý. V červeném pruhu bílá hlava psa věžníka s jazykem a trnitým obojkem, obojí žluté, pod ní v šesté až sedmé osmině šířky listu dva vodorovné oboustranně vlnkovité žluté pruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo nad sníženým dvakrát černo-zlato-černě děleným břevnem doleva hledící černá kančí hlava se stříbrnou zbrojí, vlevo nad dvěma sníženými zlatými vlnkovitými břevny stříbrná hlava psa-věžníka s jazykem a trnitým obojkem, obojí zlaté."
    },
    {
        obec: "Lešetice",
        kod: 513521,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.647301,
        longitude: 14.02119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Coats_of_arms_Le%C5%A1etice.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, černý, červený a žlutý. Ve střední části listu zkřížené berly převýšené korunou, provázené dole a po stranách třemi (2, 1) včelami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černo - červeně polcená useň, v ní zkřížené berly převýšené korunou a provázené po stranách a dole třemi včelami, vše zlaté."
    },
    {
        obec: "Leškovice",
        kod: 568996,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.762995,
        longitude: 15.537109,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1 : 1 : 2. V žerďové polovině listu srdce opačných barev. Ve vlajícím pruhu výr velký na otevřené bílé knize v červených deskách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém zlato-červeně polceném poli srdce opačných barev, v levém zlatém poli výr velký přirozené barvy na otevřené stříbrné knize v červených deskách."
    },
    {
        obec: "Lešná",
        kod: 544302,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75641,
        latitude: 49.520653,
        longitude: 17.930151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/CZ_Le%C5%A1n%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "Modrý list s kruhovým polem o průměru 4/5 šířky listu. Kruhové pole je vymezeno bílým mezikružím a obsahuje tři bílé vlčí zuby z obecního znaku na červeném podkladu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě archanděl Michael ve stříbrné zbroji se stříbrným a červeným křídlem, držící v pravici plamenný meč a v levici oválný štít s erbem Kinských - v červeném poli tři stříbrné vlčí zuby."
    },
    {
        obec: "Leština",
        kod: 537713,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78971,
        latitude: 49.868883,
        longitude: 16.927588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Le%C5%A1tina_SU_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed v bílém mezikruží provázeném třemi žlutými šestilistými květy (2,1) tři odvrácené žluté lískové oříšky (2,1) ve společném zeleném obalu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve stříbrném kruhu provázeném třemi (2,1) zlatými šestilistými květy tři (2,1) odvrácené zlaté lískové oříšky ve společném zeleném obalu."
    },
    {
        obec: "Leština",
        kod: 571695,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.867762,
        longitude: 16.117895,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Le%C5%A1tina_UO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, černý, žlutý, černý a zelený, v poměru 1:1:1:6, přes které je položen žlutý vodorovný pruh, vycházející ze čtvrté šestiny žerďového okraje listu. V horním zeleném poli vztyčená borová šiška mezi dvěma svěšenými žaludy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě z černého trojvrší se zlatým heroldským křížem vyrůstají čtyři odkloněné svázané klasy. Nahoře mezi nimi vztyčená borová šiška a po stranách dva svěšené žaludy, vše zlaté."
    },
    {
        obec: "Leština u Světlé",
        kod: 569011,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58286,
        latitude: 49.761453,
        longitude: 15.39771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Coat_of_arms_of_Le%C5%A1tina_u_Sv%C4%9Btl%C3%A9.jpg",
        coatOfArmsFlagDescription: "Červený list s černo-bíle šikmo děleným horním žerďovým trojúhelníkem a černo-bíle kosmo děleným horním vlajícím trojúhelníkem, oba s odvěsnami do poloviny šířky a délky listu. V červeném poli žlutý zvon s červeným rakem a se třemi svěšenými bílými lískovými oříšky na žluté stopce místo srdce, provázený k žerďovému okraji listu svěšeným květem bledule jarní a k vlajícímu okraji bílou růží se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě s černo-stříbrně dělenými kouty zlatý zvon s červeným rakem a třemi (2, 1) svěšenými stříbrnými lískovými oříšky na zlaté stopce místo srdce. Zvon provázen vpravo svěšeným květem bledule jarní přirozené barvy a vlevo stříbrnou růží se zlatým semeníkem."
    },
    {
        obec: "Leštinka",
        kod: 571709,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53973,
        latitude: 49.845309,
        longitude: 15.958993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Le%C5%A1tinka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2 : 1. Ve žlutém pruhu šikmo zelený olšový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou patou šikmo položený zelený olšový list."
    },
    {
        obec: "Letiny",
        kod: 557951,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.540003,
        longitude: 13.455391,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Letiny_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed žlutá koruna tryskající osmi (4+4) bílými vřídelními prameny, převýšená třemi žlutými šesticípými hvězdami vedle sebe, pod prostřední bílý půlměsíc cípy k hornímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá koruna tryskající osmi (4+4) stříbrnými vřídelními prameny, nahoře tři zlaté hvězdy, pod prostřední stříbrný stoupající půlměsíc."
    },
    {
        obec: "Letkov",
        kod: 540561,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 32600,
        latitude: 49.730543,
        longitude: 13.465091,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Letkov-znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý, v poměru 5 : 3. Bílý pruh má čtyři zuby vysoké šestnáctinu šířky listu a pět stejných mezer. V modrém pruhu postavený žlutý úhelník s kratším ramenem k vlajícímu okraji listu mezi bílými přivrácenými křídly. V bílém pruhu modrá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně cimbuřově dělený štít. Nahoře doleva obrácený postavený zlatý úhelník mezi dvěma přivrácenými stříbrnými křídly, dole modrá koruna."
    },
    {
        obec: "Letohrad",
        kod: 580538,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56151,
        latitude: 50.036122,
        longitude: 16.500231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Letohrad_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, zelený a červený. Na zeleném poli kráčející hnědý jelen. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Letonice",
        kod: 593257,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68335,
        latitude: 49.17736,
        longitude: 16.959237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Letonice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě červených a bílých, v poměru 1:1:2:1:1. V prostředním červeném pruhu bílý obilný klas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dva zkřížené obilné klasy, nad nimi vztyčený vinařský nůž (kosíř) ostřím doleva, vše stříbrné."
    },
    {
        obec: "Letovice",
        kod: 581917,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.547189,
        longitude: 16.573678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Letovice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Žlutý list, v horním žerďovém rohu dvě zkřížené černé ostrve o šesti sucích. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lety",
        kod: 549568,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.517034,
        longitude: 14.087866
    },
    {
        obec: "Lety",
        kod: 539406,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25229,
        latitude: 49.9211,
        longitude: 14.255311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Lety_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, střídavě modrých a bílých, v poměru 11 : 1 : 1 : 1 : 1 : 1. V horním pruhu zkřížené žluté gotické klíče, kosmý pod šikmým. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou zvlněnou patou se dvěma modrými vlnitými břevny zkřížené zlaté klíče."
    },
    {
        obec: "Levín",
        kod: 565105,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.613935,
        longitude: 14.284017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Lev%C3%ADn_%28okres_Litom%C4%9B%C5%99ice%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a červený v poměru 1 : 2 : 1 . Ve žlutém pruhu postava sv. Václava v bílé zbroji, s knížecí korunou, svatozáří a červeným hermelínovým pláštěm. V pravé ruce drží hnědý dřevec s hrotem a praporcem se dvěma cípy, levou rukou se opírá o kolčí štít, vše bílé. Na praporci a štítu černá orlice s bílým perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě postava sv. Václava ve stříbrném brnění, s knížecí korunou, svatozáří a červeným hermelínovým pláštěm. V pravé ruce drží dřevec přirozené barvy se stříbrným hrotem a stříbrnou dvoucípou korouhví s černou orlicí se stříbrným perizoniem. Levou rukou se opírá o stříbrný kolčí štít s černou orlicí se stříbrným perizoniem."
    },
    {
        obec: "Levínská Olešnice",
        kod: 577375,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51401,
        latitude: 50.528307,
        longitude: 15.540814,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Lev%C3%ADnsk%C3%A1_Ole%C5%A1nice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, uprostřed dvě bílé odvrácené postavené radlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá kvádrová hradba s baštou a cimbuřím a jednou střílnou. Na baště je zavěšen černý gotický štítek se zlatým, doleva obráceným lvem ve skoku. Bašta je provázena dvěma stříbrnými odvrácenými radlicemi."
    },
    {
        obec: "Lhánice",
        kod: 591025,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67575,
        latitude: 49.109192,
        longitude: 16.223507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Lh%C3%A1nice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, zelený se stojícím žlutým jelenem, bílý a zelený, v poměru 1 : 1 : 6 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně vpravo polcený štít se zúženým vlnitým břevnem opačných barev v patě. Vpravo tři zelené lipové listy kosmo vyrůstající nad sebou, vlevo zlatý jelen."
    },
    {
        obec: "Lhenice",
        kod: 550361,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38402,
        latitude: 48.994897,
        longitude: 14.149903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Lhenice_znak.png",
        coatOfArmsFlagDescription: "Modrý list se zeleným žerďovým klínem lemovaným bílou žerďovou krokví vycházející z první a osmnácté osmnáctiny žerďového okraje listu s vrcholem na vlajícím okraji listu. V klínu červeno - žlutě polcené jablko se žlutou stopkou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě hradba s červeně krytými stínkami s černými střílnami na krakorcích, vše kvádrované a stříbrné. Za hradbou vyrůstá jabloň s plody, vše přirozené barvy."
    },
    {
        obec: "Lhota",
        kod: 514497,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.497745,
        longitude: 17.614881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Lhota_P%C5%99erov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, zelený a žlutý, v poměru 1:2:1. Na zeleném poli běžící bílý jelen se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný běžící jelen se zlatou zbrojí, na něm položen ditrichštejnský štítek."
    },
    {
        obec: "Lhota",
        kod: 513041,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27301,
        latitude: 50.088744,
        longitude: 14.011917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Lhota_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým vztyčeným sedmičetným listem jírovce maďalu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vztyčený sedmičetný list jírovce maďalu."
    },
    {
        obec: "Lhota",
        kod: 534986,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.243309,
        longitude: 14.65652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Lhota_PH_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto-zeleně čtyřmi šikmými střídavě bílými a zelenými pruhy dělený list. V zeleném poli žlutý zvon, ve žlutém vztyčená zelená borová šiška. Pruhy vycházejí z druhé až páté dvanáctiny dolního okraje listu do deváté až dvanácté dvanáctiny horního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený hrot, v něm nad dvěma sníženými stříbrnými vlnitými břevny zlatá polygonální kaple se dvěma prázdnými okny a zvonem v lucerně. Hrot provázejí dvě vztyčené zelené borové šišky."
    },
    {
        obec: "Lhota",
        kod: 573434,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76302,
        latitude: 49.169435,
        longitude: 17.602533,
        coatOfArmsFlagDescription: "Modrý list; uprostřed žlutá prázdná krokvice s makovičkou a červeným praporkem - plamenem zezadu prostrčená volným, třikrát lomeným bílým pruhem s liliovitě zakončenými hroty (2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá prázdná krokvice s makovičkou a červeným praporkem - plamenem zezadu prostrčená třikrát lomeným stříbrným břevnem s liliovitě zakončenými hroty a převýšená třemi zlatými osmihrotými hvězdami."
    },
    {
        obec: "Lhota pod Hořičkami",
        kod: 574180,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.422788,
        longitude: 16.003204,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Lhota_pod_Ho%C5%99i%C4%8Dkami_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý a zvlněný modrý, v poměru 14 : 1 : 15. V horním pruhu čelně postavená bílá radlice provázená po obou stranách dvěma odkloněnými žlutými obilnými klasy s listy vedle sebe. V dolním pruhu žlutý zvon v obrácené bílé podkově. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zúžené stříbrné vlnité břevno. Nahoře čelně postavená stříbrná radlice provázená po obou stranách dvěma odkloněnými zlatými obilnými klasy s listy vedle sebe. Dole zlatý zvon v obrácené stříbrné podkově."
    },
    {
        obec: "Lhota pod Libčany",
        kod: 570231,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.172522,
        longitude: 15.696345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/CoA_of_Lhota_pod_Lib%C4%8Dany.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený. V žerďovém pruhu horní část žluté berly se dvěma bílými sudarii do stran a závitem k žerďovému okraji, ve vlajícím pruhu pod sebou dva vztyčené bílé lipové listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrným zkráceným klínem vyrůstající zlatá berla se dvěma stříbrnými sudarii do stran, provázená dvěma vztyčenými stříbrnými lipovými listy."
    },
    {
        obec: "Lhota pod Radčem",
        kod: 567001,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.827464,
        longitude: 13.710816,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Lhota_pod_Rad%C4%8Dem_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, v poměru 5 : 1 : 2, zelený, zubatý bílý se čtyřmi zuby, krajními širšími a třemi mezerami, a bílý se třemi červenými kosmými pruhy. Z prostředního pruhu vyrůstá v prostřední mezeře smrk provázený nad mezerami tlapatým procesním křížem a postavenou soukenickou střelou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrné hradby se třemi červenými kosmými břevny a stříbrným cimbuřím vyrůstající smrk provázený vpravo tlapatým procesním křížem a vlevo postavenou soukenickou střelou, vše zlaté."
    },
    {
        obec: "Lhota Rapotina",
        kod: 581925,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.466146,
        longitude: 16.624954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Lhota_Rapotina_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý zubatý pruh se čtyřmi čtvercovými zuby a třemi stejnými mezerami, široký jednu čtvrtinu délky listu, a modré pole s přivrácenými bílými husími krky se žlutými zobáky provlečené vodorovně položeným žlutým tkalcovským člunkem s modrým květem lnu uprostřed. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou cimbuřovou hlavou přivrácené stříbrné husí krky se zlatými zobáky provlečené položeným zlatým tkalcovským člunkem s modrým květem lnu uprostřed."
    },
    {
        obec: "Lhota u Lysic",
        kod: 581933,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.469778,
        longitude: 16.496885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Lhota_u_Lysic_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. V bílém poli kosmo tři zelené březové listy pod sebou, v modrém bílý bůvolí roh k vlajícími okraji postrkaný třema levýma lidskýma rukama. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vlnitě polcený štít. Vpravo tři zelené, kosmo vztyčené březové listy pod sebou, vlevo stříbrný buvolí roh postrkaný třema levýma lidskýma rukama přirozené barvy."
    },
    {
        obec: "Lhota u Olešnice",
        kod: 581941,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.544909,
        longitude: 16.390829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Lhota_u_Ole%C5%A1nice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 5 : 2 : 5. V bílém pruhu tři květy lnu pod sebou, pruh provázen bílými volskými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůl se třemi květy lnu přirozené barvy pod sebou provázený dvěma stříbrnými volskými rohy."
    },
    {
        obec: "Lhota u Příbramě",
        kod: 598411,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.714255,
        longitude: 13.980642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Lhota_u_P%C5%99%C3%ADbram%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, a vodorovný vlnitý bílý pruh se třemi vrcholy a dvěma prohlubněmi, vycházející z páté šestiny žerďového okraje listu. Nad vlnitým pruhem v červeném pruhu žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít. Vpravo obilný klas se dvěma listy, vlevo hutnické kleště podložené hornickými kladívky a převýšené korunou, vše zlaté. V patě štítu kosmé stříbrné vlnité břevno."
    },
    {
        obec: "Lhota u Vsetína",
        kod: 556866,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75501,
        latitude: 49.304675,
        longitude: 17.958094,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Coat_of_Arms_Lhota_u_Vset%C3%ADna.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, zelený, bílý a zelený, v poměru 9:1:1:1. Uprostřed listu, přes všechny pruhy, bílá sekera - valaška na žlutém topůrku ostřím k žerďovému okraji, podložená dvěma zkříženými kvetoucími větvičkami vrby jívy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítu se zeleným dvojvrším stříbrná sekera - valaška na zlatém topůrku podložená dvěma zkříženými kvetoucími větvičkami vrby jívy přirozené barvy."
    },
    {
        obec: "Lhota-Vlasenice",
        kod: 561282,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.302785,
        longitude: 15.12482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Coat_of_arms_of_Lhota-Vlasenice.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed polovina žlutého koně ve skoku s červeným uzděním, v horním rohu šesticípá hvězda, u vlajícího okraje berla závitem k žerďovému okraji listu, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě polovina zlatého koně ve skoku s červeným uzděním, provázená vpravo nahoře hvězdou a vlevo berlou, obojí zlaté."
    },
    {
        obec: "Lhotice",
        kod: 544876,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.019369,
        longitude: 15.60716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Lhotice_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, červený, bílý, červený, černý, červený, bílý a červený, v poměru 1 : 1 : 1 : 9 : 1 : 1 : 1. V černém pruhu vykořeněná žlutá lípa nad bílým půlměsícem cípy k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černém štítě s červenými kouty nad stříbrným stoupajícím půlměsícem zlatá vykořeněná lípa. V pravém koutu šikmé a v levém kosmé stříbrné břevno."
    },
    {
        obec: "Lhotka",
        kod: 587451,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.209194,
        longitude: 15.385751
    },
    {
        obec: "Lhotka",
        kod: 596035,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.582281,
        longitude: 16.002978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Lhotka_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu černý ondřejský kříž s rameny širokými jednu desetinu šířky listu, v modrém pruhu dva bílé vodorovné vlnité pruhy, široké jednu desetinu šířky listu, přes ně žlutá ryba. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře černé zkřížené ostrve, dole dvě stříbrná vlnitá břevna přeložená zlatou rybou."
    },
    {
        obec: "Lhotka",
        kod: 598364,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73947,
        latitude: 49.597285,
        longitude: 18.298465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Lhotka_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhu, červený, bílý, zelený, bílý a červený, v poměru 1:1:6:1:1. V zeleném pruhu bílý pluh obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným trojvrším se stříbrným pluhem obráceným doleva vpravo osminová nota a vlevo lilie, obojí červené."
    },
    {
        obec: "Lhotka",
        kod: 514527,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75124,
        latitude: 49.499836,
        longitude: 17.410166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Lhotka_%28PR%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žluté žerďové trojúhelníky sahající na horním a dolním okraji listu do poloviny délky listu a tři vodorovné pruhy, zelený, bílý a zelený. V trojúhelnících po červené růži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se zlatými kouty stříbrné průčelí kaple s černým vstupem a s věží. Věž stříbrná s černým obloukovým oknem a černou kuželovou střechou zakončenou zlatým křížkem. V koutech po červené růži."
    },
    {
        obec: "Lhotka",
        kod: 533335,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26723,
        latitude: 49.83232,
        longitude: 13.996335
    },
    {
        obec: "Lhotka",
        kod: 531898,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27731,
        latitude: 50.371468,
        longitude: 14.548888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Lhotka_%28okres_M%C4%9Bln%C3%ADk%29_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zvlněný modrý, v poměru 1 : 2 : 1. V zeleném pruhu vodorovně žluté květenství akátu s bílými květy, ve žlutém pruhu čelně kulíšek nejmenší, v modrém pruhu se třemi vrcholy a čtyřmi prohlubněmi svěšená žlutá borová šiška. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou hlavou s položeným zlatým květenstvím akátu se stříbrnými květy, nad modrou vlnitou patou se svěšenou zlatou borovou šiškou, čelně kulíšek nejmenší přirozené barvy mezi zelenými skalami."
    },
    {
        obec: "Lhotka nad Labem",
        kod: 565113,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.525685,
        longitude: 14.047429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Lhotka_nad_Labem_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 7 : 1 : 2. V červeném pruhu dva odkloněné obilné klasy provázené nahoře vinným hroznem a po stranách jablky s odvrácenými listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou vlnitou patou s modrou lilií dva odkloněné obilné klasy provázené nahoře vinným hroznem a po stranách jablky s odvrácenými listy, vše zlaté."
    },
    {
        obec: "Lhotka u Litultovic",
        kod: 553115,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74755,
        latitude: 49.872905,
        longitude: 17.730857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Lhotka_u_Litultovic_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, každý s bílým žerďovým klínem s vrcholem v jedné třetině délky listu; v modrém pruhu dva za sebou letící ptáci, v červeném pruhu kosmo sekera, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě děleném sníženým červeným zúženým břevnem se dvěma stříbrnými hroty nahoře pluh provázený dvěma letícími ptáky, dole kosmo sekera, vše stříbrné."
    },
    {
        obec: "Lhotky",
        kod: 536211,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.396401,
        longitude: 15.052956
    },
    {
        obec: "Lhotka u Radnic",
        kod: 559946,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.89004,
        longitude: 13.581715
    },
    {
        obec: "Lhotsko",
        kod: 549550,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.212296,
        longitude: 17.883478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Lhotsko-znak.jpg",
        coatOfArmsFlagDescription: "Bílo-zeleně čtvrcený list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad obloukovitě prohnutou stříbrnou větévkou stojící stříbrný kůň se zlatým uzděním."
    },
    {
        obec: "Lhoty u Potštejna",
        kod: 576441,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.055957,
        longitude: 16.268803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Lhoty_u_Pot%C5%A1tejna_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý a zvlněný zelený, v poměru 3 : 1 : 6. V červeném pruhu vodorovně dva žluté klíče zuby k sobě a dolů. Bílý pruh má pět vrcholů a čtyři prohlubně, v zeleném pruhu je bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít s červenou hlavou oddělenou stříbrným vlnitým břevnem. V hlavě dva položené přivrácené zlaté klíče. Ve štítě stříbrná lilie."
    },
    {
        obec: "Lhůta",
        kod: 578606,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33201,
        latitude: 49.70137,
        longitude: 13.52891,
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, červený a černý. Ve střední a vlající části listu kosmo bílý čtverec se stranami dlouhými tři desetiny délky listu, v něm pět (1, 3, 1) ",
        coatOfArmsDescription: "Polcený štít. V pravém stříbrném poli na zeleném vrchu se zlatou podkovou jehličnatý strom přirozené barvy. V levém červeno-černě polceném poli kosmo stříbrný čtverec, v něm pět (1,3,1) červených kotoučů."
    },
    {
        obec: "Libavá (vojenský újezd)",
        kod: 503941,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.723088,
        longitude: 17.523528
    },
    {
        obec: "Libá",
        kod: 554618,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35131,
        latitude: 50.128275,
        longitude: 12.230943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Liba_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy, bílý, červený a černý. V modrém pruhu na šedé skále bílá budova s pěti černými okny a červenou valbovou střechou, z níž vyniká bílá věž s jedním černým oknem a červenou přilbicovou střechou se žlutou makovicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, vpravo stříbrno-červeno-černě dělený, v levém modrém poli na skále přirozené barvy stříbrná budova s pěti černými okny a červenou valbovou střechou, z níž vyniká stříbrná věž s jedním černým oknem a červenou přilbicovou střechou se zlatou makovicí."
    },
    {
        obec: "Libáň",
        kod: 573108,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.375516,
        longitude: 15.21695,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Liban_znak.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým jednu čtvrtinu šířky listu na červené žerďové a žluté vlající pole. V horním rohu bílá podkova mezi žlutým parožím vyrůstajícím z černé lebeční kosti. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Libavské Údolí",
        kod: 560502,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35751,
        latitude: 50.128978,
        longitude: 12.552602,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Libavsk%C3%A9_%C3%9Adol%C3%AD_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:2:1. V bílém pruhu letící vlaštovka se zeleným jetelovým čtyřlístkem v zobáku, pod ní tři (1,2) červené růže se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě letící vlaštovka s jetelovým čtyřlístkem v zobáku, obojí přirozené barvy, pod ní tři (1,2) červené růže se zlatými semeníky."
    },
    {
        obec: "Libčany",
        kod: 570249,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50322,
        latitude: 50.191851,
        longitude: 15.695007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Coat_of_arms_of_Lib%C4%8Dany.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Uprostřed vykračující kohout opačných barev s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vykračující kohout opačných barev s červenou zbrojí."
    },
    {
        obec: "Libčeves",
        kod: 566349,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43926,
        latitude: 50.452574,
        longitude: 13.838289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Libceves_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě červených a bílých, v poměru 1:1:2:1:1. V prostředním pruhu vodorovně bílý meč se žlutým jílcem hrotem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se stříbrným mečem se zlatým jílcem kosmá černá orlice s červeným jazykem a zlatým, jetelovitě zakončeným perizoniem a zbrojí."
    },
    {
        obec: "Libčice nad Vltavou",
        kod: 539414,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25266,
        latitude: 50.199129,
        longitude: 14.362784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Libcice_nad_Vltavou_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený vlnitým bílým pruhem širokým jednu čtvrtinu délky listu, s jedním vrcholem a dvěma prohlubněmi, na modré žerďové a červené vlající pole. V modrém poli šikmo položená bílá ostrev se třemi suky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Libecina",
        kod: 580562,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.882981,
        longitude: 16.118331,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Libecina_CoA.jpg"
    },
    {
        obec: "Libědice",
        kod: 563188,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.315996,
        longitude: 13.385678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Lib%C4%9Bdice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří bílé karé s utrženou červenou kohoutí hlavou obrácenou k vlajícímu okraji listu a šest vodorovných pruhů, bílý, zelený, bílý, červený, bílý a červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít. Nahoře šikmo položená palmová ratolest provázená vpravo červenou přivrácenou utrženou kohoutí hlavou. Dole stříbrný běžící pes se zlatým obojkem."
    },
    {
        obec: "Liběchov",
        kod: 535001,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.407939,
        longitude: 14.446855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Libechov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý zvlněný žerďový pruh, široký jednu čtvrtinu délky listu, se třemi červenými vinnými hrozny pod sebou a modré pole s bílým korunovaným dvouocasým lvem s červenou zbrojí a jazykem. Zvlněný pruh má čtyři vrcholy a tři prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vpravo vlnitě polceném štítě vpravo tři červené vinné hrozny se stopkou, vlevo stříbrný korunovaný dvouocasý lev s červenou zbrojí a jazykem."
    },
    {
        obec: "Libějice",
        kod: 563544,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.376947,
        longitude: 14.623952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Lib%C4%9Bjice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 7 : 10 : 7. V bílém pruhu červený zvon nad červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s rozšířeným stříbrným kůlem s červeným zvonem nad červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Libějovice",
        kod: 551333,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38772,
        latitude: 49.114439,
        longitude: 14.193459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Lib%C4%9Bjovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, modrý, bílý, modrý, bílý, červený, popeličinový a červený, v poměru 1 : 1 : 1 : 3 : 1 : 1 : 1. V prostředním pruhu pod sebou tři červené růže se žlutými semeníky. V popeličinovém pruhu u horního okraje listu bílý křížek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít polcený stříbrným kůlem se třemi červenými růžemi se zlatými semeníky pod sebou. V pravém modrém poli stříbrný kůl, v levém červeném poli popeličinový kůl se stříbrným křížkem nahoře."
    },
    {
        obec: "Libel",
        kod: 548651,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.15517,
        longitude: 16.202418,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Libel_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým pruhem širokým jednu třetinu délky listu. V modrém pruhu pod sebou dva žluté lipové listy s plody, horní obrácený k žerďovému, dolní k vlajícímu okraji. V bílém poli červená zavřená vrata se žlutými závěsy ve tvaru lipových listů, v nich kosmo meč hrotem k hornímu okraji šikmo podložený cepem, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou se dvěma odvrácenými vztyčenými zlatými lipovými listy na stoncích s plodem červená zavřená vrata se zlatými závěsy ve tvaru lipových listů, v nich kosmý vztyčený meč šikmo podložený cepem, obojí zlaté."
    },
    {
        obec: "Libenice",
        kod: 533475,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.984575,
        longitude: 15.258007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Libenice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2 : 3. Ve žlutém červený kalich mezi dvěma odkloněnými vztyčenými zelenými dubovými listy. V modrém pruhu žlutá hlava jednorožce s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou s červeným kalichem mezi dvěma odkloněnými vztyčenými zelenými dubovými listy zlatá hlava jednorožce s krkem."
    },
    {
        obec: "Liberec",
        kod: 563889,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46001,
        latitude: 50.76638,
        longitude: 15.054439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Znak_Liberec.svg",
        coatOfArmsFlagDescription: "List je tvořen dvěma vodorovnými pruhy, červeným a bílým. V žerďové polovině znak města ve španělském štítě. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Liberk",
        kod: 576450,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51712,
        latitude: 50.199288,
        longitude: 16.343029,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Liberk_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým křížem s rameny širokými jednu čtvrtinu šířky listu, do kterého je vložen červený vodorovný pruh podložený zeleným svislým pruhem, oba široké jednu desetinu šířky listu. Střed kříže je v první třetině délky listu. V karé bílý, k hornímu okraji vztyčený květ zvonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný heroldský kříž, v něm červená věž s prázdnou branou, dvěma černými okny a střechou zakončenou černou žerdí s červenou makovicí a červeným, doleva vlajícím praporkem se dvěma cípy, provázená dvěma vztyčenými zelenými lipovými listy a dole červeným sníženým hrotem. Mezi rameny kříže čtyři vztyčené stříbrné květy zvonku."
    },
    {
        obec: "Libeř",
        kod: 539422,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25241,
        latitude: 49.924257,
        longitude: 14.480724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Liber_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: modrý, bílý a zelený v poměru 3:1:3. V modrém pruhu tři bílé křížem přeložené šípy hroty vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zelený stříbrným zúženým břevnem rozdělený štít, nahoře tři křížem přeložené šípy hroty vzhůru, dole pluh s radlicí, krojidlem a plazem, vše stříbrné."
    },
    {
        obec: "Liběšice",
        kod: 565121,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41146,
        latitude: 50.568962,
        longitude: 14.289175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Lib%C4%9B%C5%A1ice_%28okres_Litom%C4%9B%C5%99ice%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým karé širokým jednu polovinu šířky listu se dvěma zkříženými černými ostrvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný beránek se zlatou zbrojí, přidržující levou přední nohou na dřevěné žerdi se zlatým hrotem a patkou zlatý praporec s ocasem s černými zkříženými ostrvemi."
    },
    {
        obec: "Liběšice",
        kod: 566357,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43963,
        latitude: 50.293667,
        longitude: 13.621573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Lib%C4%9B%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové svislé pruhy, červeno-zeleně dělený a bílo-červeně dělený, každý široký jednu šestinu délky listu, a dva vodorovné pruhy zelený a bílý. V červené horní polovině prvního žerďového pruhu bílý dolní klín s vrcholem na horním okraji listu s modrým mečem hrotem nahoru, v bílé horní polovině druhého žerďového pruhu vztyčený zelený chmelový šlahoun se třemi listy a dvěma stonky, každý s třemi šišticemi, dolní polovina pruhu červená. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrný hrot se vztyčeným modrým mečem, vlevo vztyčený zelený chmelový šlahoun se třemi listy a dvěma stonky, každý s třemi šišticemi."
    },
    {
        obec: "Libětice",
        kod: 536831,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.219149,
        longitude: 13.862757
    },
    {
        obec: "Libhošť",
        kod: 500046,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74257,
        latitude: 49.626557,
        longitude: 18.077075,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 3 : 1. V zeleném pruhu hrábě vyrůstající ze dvou dole spojených odkloněných obilných klasů s listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě hrábě vyrůstající ze dvou dole spojených odkloněných obilných klasů s listy, vše zlaté."
    },
    {
        obec: "Líbeznice",
        kod: 538442,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25065,
        latitude: 50.192079,
        longitude: 14.493682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Libeznice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: horní v žerďové části modrý a ve vlající části bílý, střední žlutý, v dolní žerďové části bílý a ve vlající modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně čtvrcený štít. Uprostřed zlatý postavený meč, provázený ve třetím a čtvrtém poli šestihrotým ostruhovým kolečkem opačných barev."
    },
    {
        obec: "Libež",
        kod: 530069,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.757522,
        longitude: 14.916873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Coat_of_arms_of_Libe%C5%BE.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový klín s vrcholem ve třetině délky listu a dva svislé pruhy, vydutý žlutý, dosahující do dvou třetin délky listu, a vypouklý zelený. V klínu zakončeném jetelovým trojlistem sahajícím k vrcholu oblouku žlutá osmicípá hvězda. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý zkrácený klín zakončený jetelovým trojlistem nad zelenou vydutou patou. V klínu zlatá osmihrotá hvězda."
    },
    {
        obec: "Libchavy",
        kod: 580147,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56116,
        latitude: 50.005575,
        longitude: 16.38914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Libchavy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, bílý, žlutý, zelený s letícím žlutým skřivanem, žlutý a bílý, v poměru 3 : 2 : 9 : 2 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvě snížené stříbrné věže s cimbuřím do krajů a černým gotickým oknem. Z pravé věže vyskakuje doleva jelen, z levé kůň, oba stříbrní se zlatou zbrojí. Nahoře mezi nimi letící zlatý skřivan."
    },
    {
        obec: "Libchyně",
        kod: 547701,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.356657,
        longitude: 16.190034,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Libchyn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modře propleteně mřížovaný bílý a zelený, v poměru 3 : 2 : 3. V červeném pruhu dvě přivrácená lekna, v zeleném lilie, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně stříbrným, modře propleteně mřížovaným břevnem dělený štít. Nahoře dvě přivrácená lekna, dole lilie, vše stříbrné."
    },
    {
        obec: "Libice nad Cidlinou",
        kod: 537438,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28907,
        latitude: 50.126874,
        longitude: 15.178306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Libice_nad_Cidlinou_znak.png",
        coatOfArmsFlagDescription: "Bílý list s kosmým vlnitým modrým pruhem o čtyřech vlnách, vycházejícím z horní třetiny žerďového okraje a sahajícím do dolní třetiny vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyniká z modré vlnité paty zeď s průčelím kostela s jedním prolomeným oknem (vše stříbrné a z kvádrů), jehož střecha je ukončena zlatým tlapatým křížkem."
    },
    {
        obec: "Libice nad Doubravou",
        kod: 569020,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58277,
        latitude: 49.745023,
        longitude: 15.704338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Libicend_znak.png",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem o šířce 1/5 šířky listu. Horní pole listu zelené, dolní modré. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-modrý štít dělený šikmým břevnem, nahoře postavený jetelový trojlístek, dole tři vlnitá břevna - vše stříbrné."
    },
    {
        obec: "Libín",
        kod: 544744,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37373,
        latitude: 48.969727,
        longitude: 14.683706
    },
    {
        obec: "Libina",
        kod: 539961,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78805,
        latitude: 49.876529,
        longitude: 17.08282,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/LibinaZnak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, červený, žlutý a červený, v poměru 1:5:1:1:1. Do modrého pruhu vyrůstá žlutý lev s červenou zbrojí, držící bílý praporec s ocasem s červeným středovým křížem na žluté žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít v horním modrém poli vyrůstá zlatý lev s červenou zbrojí, držící stříbrný praporec s ocasem s červeným heroldským křížem na zlaté žerdi, dolní pole zlato-červeně šachované."
    },
    {
        obec: "Libiš",
        kod: 571784,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27711,
        latitude: 50.274275,
        longitude: 14.50251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Libis_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý zvlněný o třech vrcholech a dvou prohlubních, v poměru 3:1. Nad první prohlubní bílá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad vlnitou patou svatojakubská mušle, vše stříbrné."
    },
    {
        obec: "Libišany",
        kod: 575305,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.153387,
        longitude: 15.756738,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/CZ_Libi%C5%A1any_COA.svg",
        coatOfArmsFlagDescription: "Bílo-modře čtvrcený list. Uprostřed žluté čtvercové pole, široké polovinu šířky listu, v něm kosmá černá orlice s červeným jazykem a bílým perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku volná kvádrovaná stříbrná hradba, vlevo s branou, vpravo s dvěma klíčovými střílnami, vše černé. Vpravo z hradby vyniká stříbrná věž s červenou stanovou střechou, zakončenou makovicí s křížkem, obojí zlaté, a modrým obloukovým oknem se zavěšeným zlatým zvonem. Uprostřed z hradby vyniká na ratišti s hrotem praporec s ocasem, obojí zlaté, v něm kosmá černá orlice s červeným jazykem a stříbrným perizoniem."
    },
    {
        obec: "Libkov",
        kod: 571725,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.841833,
        longitude: 15.75641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Libkov_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy červený a zelený, uprostřed kosmo žlutý gotický klíč zuby dolů a k vlajícímu okraji šikmo podložený bílým mečem se žlutým jílcem hrotem dolů, proložené žlutou ostrví se čtyřmi suky proti sobě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě kosmo postavený zlatý gotický klíč zuby doleva šikmo podložený postaveným stříbrným mečem se zlatým jílcem, obojí proloženo položenou zlatou ostrví se čtyřmi suky proti sobě."
    },
    {
        obec: "Libkov",
        kod: 553859,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.366295,
        longitude: 13.13447,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Libkov_%28Doma%C5%BElice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený, bílý, modrý, bílý a zelený, v poměru 16 : 2 : 1 : 2 : 1 : 2. Z horního zeleného pruhu vyrůstá zelená lípa s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě lípa přirozené barvy na zeleném trávníku s modrým, stříbrně lemovaným vlnitým břevnem."
    },
    {
        obec: "Libkova Voda",
        kod: 598721,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39462,
        latitude: 49.376595,
        longitude: 15.191112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Libkova_Voda.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným svatopetrským křížem, jehož vodorovná ramena vycházejí z osmé desetiny žerďového a vlajícího okraje listu. Z horního svislého ramene podloženého vodorovným oboustranně vlnkovitým modrým pruhem vycházejícím z desáté šestnáctiny žerďového okraje listu vyrůstá zelená koruna lípy. V kříži bílý meč hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě lípa, jejíž kmen tvoří horní rameno svatopetrského kříže, obojí zelené a přeložené vztyčeným stříbrným mečem a podložené modrým vlnkovitým břevnem."
    },
    {
        obec: "Libkovice pod Řípem",
        kod: 565148,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.392532,
        longitude: 14.342424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Libkovice_pod_%C5%98%C3%ADpem_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený s vrchem vynikajícím z prostředních čtyř šestin pruhu, v poměru 3 : 1. V červeném pruhu položená vykořeněná žlutá větev vinné révy se třemi listy, v zeleném pruhu a vrchu žlutý kalich. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě položená vykořeněná zlatá větev vinné révy se třemi listy nad zeleným vrchem se zlatým kalichem."
    },
    {
        obec: "Liblice",
        kod: 531502,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27732,
        latitude: 50.31343,
        longitude: 14.590516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Liblice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 2 : 1 : 1, bílý se vztyčeným zeleným listem jírovce, černý a žlutý s kosmým černým pruhem vycházejícím z horní poloviny žerďového do dolní poloviny vlajícího okraje pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Vpravo ve stříbrném poli vztyčený zelený list jírovce. Levé pole černo-zlatě polcené, vlevo černé kosmé břevno."
    },
    {
        obec: "Liblín",
        kod: 559954,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.916919,
        longitude: 13.544351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Liblin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modro-červeně polcený, zelený a červeno-modře polcený, v poměru 2:1:2. Uprostřed bílá kvádrovaná věž s cimbuřím a dvěma černými, žlutě rámovanými trojdílnými okny nad sebou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Libňatov",
        kod: 579475,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54236,
        latitude: 50.481436,
        longitude: 16.002299,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Lib%C5%88atov_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený třemi pruhy, bílým, modrým a bílým, vycházejícími z první až třetí dvanáctiny dolního okraje listu do desáté až dvanácté dvanáctiny horního okraje listu, na zelené žerďové pole s bílým květem lnu a modré vlající pole se žlutým zvonem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrně lemovaným modrým břevnem dělený štít. Nahoře stříbrný květ lnu, dole zlatý zvon zavěšený na spodním lemu břevna."
    },
    {
        obec: "Libníč",
        kod: 535800,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 49.019769,
        longitude: 14.543731,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Libnic_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém pruhu bílá fontána, v bílém pruhu tři šikmé zelené jilmové listy nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně polceném štítě vpravo stříbrná fontána, vlevo tři šikmé zelené jilmové listy nad sebou."
    },
    {
        obec: "Libníkovice",
        kod: 570257,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.244268,
        longitude: 15.996241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/CoA_of_Libn%C3%ADkovice.svg",
        coatOfArmsFlagDescription: "Červený list s modrým žerďovým pruhem širokým jednu čtvrtinu délky listu s bílým rýčem na žluté násadě. V červeném poli u modrého pruhu tři vztyčené bílé lipové listy pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo stříbrný rýč na zlaté násadě, vlevo pod sebou tři vztyčené stříbrné lipové listy."
    },
    {
        obec: "Libočany",
        kod: 530590,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43975,
        latitude: 50.33349,
        longitude: 13.513599,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Libo%C4%8Dany_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 9 : 2. V červeném pruhu ohlížející se rozkřídlený bílý krahujec se žlutou zbrojí držící v pařátech rozevřenou bílou knihu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad modrou vlnitou patou ohlížející se rozkřídlený stříbrný krahujec se zlatou zbrojí, držící v pařátech rozevřenou stříbrnou knihu."
    },
    {
        obec: "Libodřice",
        kod: 533483,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.004416,
        longitude: 15.088387
    },
    {
        obec: "Libochovany",
        kod: 565156,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41103,
        latitude: 50.566669,
        longitude: 14.039146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Libochovany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list se žlutým žerďovým pruhem širokým jednu čtvrtinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli přes zelené trojvrší zlatá podkova. Dolní pole modro-stříbrně čtvrceno."
    },
    {
        obec: "Libochovice",
        kod: 565164,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41117,
        latitude: 50.406368,
        longitude: 14.044498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Libochovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině bílý zajíc vyrůstající ze žluté červeně vyložené koruny s pěti listy. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Libochovičky",
        kod: 564150,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27342,
        latitude: 50.172676,
        longitude: 14.239708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Coat_of_arms_of_Libochovi%C4%8Dky.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, v poměru 3 : 5 : 4, bílý, modrý zubatý se čtyřmi zuby a třemi mezerami hlubokými polovinu šířky zubu a bílý zvlněný se třemi vrcholy a dvěma prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré břevno, nahoře cimbuřové, dole vlnité."
    },
    {
        obec: "Liboměřice",
        kod: 571733,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.87195,
        longitude: 15.741396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Libom%C4%9B%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký třetinu délky listu a dva vodorovné pruhy, žlutý a modrý. V zeleném pruhu žluté rychtářské právo, ve žlutém kosmo vztyčená zelená lipová ratolest se čtyřmi listy, v modrém žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. V pravém zeleném poli zlaté rychtářské právo, v levém zlato-modře děleném poli nahoře kosmo vztyčená zelená lipová ratolest se čtyřmi listy, dole zlatý zvon."
    },
    {
        obec: "Libomyšl",
        kod: 531448,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26723,
        latitude: 49.872637,
        longitude: 13.998731,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/CoA_of_Libomy%C5%A1l.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: modrý dole zvlněný, vlnitý bílý, modrý, bílý a zvlněný červený v poměru 8:3:3:3:11 na žerďovém okraji. Pruhy mají pět vrcholů a čtyři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Třemi vlnitými břevny: stříbrným, modrým a stříbrným, modro-červeně dělený štít. Nahoře tři stříbrné zvony, dole zlatá lilie."
    },
    {
        obec: "Libořice",
        kod: 566381,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.254811,
        longitude: 13.515189,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Libo%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině dva svislé pruhy, červený a modrý, v poměru 6:1 a ve vlající polovině dva vodorovné pruhy, žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrým zúženým břevnem polcený štít. V pravé červené polovině stříbrný dvouocasý lev ve skoku obrácený doleva. Levá polovina dvakrát dělena. Nahoře ve zlatém poli zelený stonek se dvěma chmelovými šiškami, uprostřed v zeleném poli tři zlaté obilné klasy, dole ve zlatém poli vyrůstá ze zeleného dvouvrší zelený jetelový trojlístek."
    },
    {
        obec: "Liboš",
        kod: 569003,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78313,
        latitude: 49.691682,
        longitude: 17.225136,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Libos_znak.gif",
        coatOfArmsFlagDescription: "V zeleném listu mezi dvěma obilnými snopy berla závitem k žerďovému okraji, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě mezi dvěma obilnými snopy berla, vše zlaté. Nad každým snopem stříbrná růže s červeným semeníkem a zlatými kališními lístky."
    },
    {
        obec: "Libošovice",
        kod: 573116,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50744,
        latitude: 50.490669,
        longitude: 15.162912
    },
    {
        obec: "Libotenice",
        kod: 565172,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.477022,
        longitude: 14.229354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Libotenice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:6:1. V žerďové polovině modrého pruhu vyniká z bílé lodě žluté kolo sv. Kateřiny s bílými noži, převýšené ve vylomení žlutou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyniká ze stříbrné lodě zlaté kolo sv. Kateřiny se stříbrnými noži, převýšené ve vylomení zlatou lilií."
    },
    {
        obec: "Libotov",
        kod: 579483,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.399472,
        longitude: 15.811963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Libotov_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleno-modře vlnitě děleným horním klínem. V něm nahoře žlutá koruna s červenými drahokamy, dole bílá sedmicípá hvězda cípem dolu. Po stranách klínu vztyčený zelený lipový list, v žerďové polovině kosmo a ve vlající šikmo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zeleno-modře vlnitě dělený klín, v něm nahoře zlatá koruna s červenými drahokamy, dole stříbrná sedmihrotá hvězda. Klín je provázen dvěma vztyčenými odkloněnými zelenými lipovými listy."
    },
    {
        obec: "Libouchec",
        kod: 568058,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40335,
        latitude: 50.75865,
        longitude: 14.040826,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Libouchec_znak.png",
        coatOfArmsFlagDescription: "List tvoří žerďový pilovitý zelený pruh široký jednu třetinu délky listu se čtyřmi zuby sahajícími do poloviny délky listu na které navazuje z třetí šestiny horního a dolního okraje sedmkrát lomený bílý pruh, ze čtvrté šestiny horního a dolního okraje sedmkrát lomený zelený pruh a bílá vlající část. V žerďovém pruhu pod sebou tři žluté červeně vyložené zavřené koruny s pěti viditelnými půloblouky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně čtyřikrát lomeně dělený štít. Nahoře sedmkrát lomené zelené břevno, dole tři (2,1) zlaté, červeně vyložené zavřené koruny s pěti viditelnými půloblouky."
    },
    {
        obec: "Libovice",
        kod: 571601,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27379,
        latitude: 50.235701,
        longitude: 14.016452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Libovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 7 : 10 : 7. V modrém pruhu šesticípá žlutá hvězda, v bílém pruhu červený lev s modrou zbrojí a jazykem, v červeném pruhu půl žluté osmicípé hvězdy hroty k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou hlavou s pěti (3, 2) zlatými hvězdami. Vpravo červený lev s modrou zbrojí a jazykem, vlevo stříbrné vykořeněné lekno provázené vpravo levou půlí zlaté osmihroté hvězdy."
    },
    {
        obec: "Librantice",
        kod: 570265,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.242363,
        longitude: 15.95985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Librantice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým kosmým pruhem širokým třetinu délky listu, v pruhu kosmo modrý meč hrotem k hornímu rohu listu. Ve vlajícím poli tři (2, 1) kruhová pole, v žerďovém poli kosmo vztyčené pero, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmé stříbrné břevno se vztyčeným modrým mečem provázené nahoře třemi (2, 1) kotouči, dole kosmo vztyčeným perem, vše zlaté."
    },
    {
        obec: "Libštát",
        kod: 577294,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51203,
        latitude: 50.559503,
        longitude: 15.416037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Lib%C5%A1t%C3%A1t-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, zelený, bílý, zelený a červený, v poměru 1 : 1 : 6 : 1 : 1. V bílém pruhu z druhé a třetí čtvrtiny dolního okraje listu vyniká červený vrch. Z něho vyrůstá zelená lípa s hnědým kmenem, po jeho stranách stojí na vrchu dva černí havrani hledící ke koruně stromu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Libuň",
        kod: 573124,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50715,
        latitude: 50.498255,
        longitude: 15.298603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Libu%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět střídavě modrých a bílých vodorovných pruhů, v poměru 10:1:1:1:1. V horním pruhu šest (5,1) žlutých šesticípých hvězd nad kalamářem se šikmo zatknutým brkem, kosmo podloženým mečem hrotem k žerdi, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma břevny kalamář s šikmo zatknutým brkem, kosmo podložený vztyčeným mečem, vše stříbrné. Nahoře šest (5,1) zlatých hvězd."
    },
    {
        obec: "Libušín",
        kod: 532576,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27306,
        latitude: 50.168293,
        longitude: 14.05469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Libu%C5%A1%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, žlutý, modrý a černý, v poměru 1 : 1 : 2 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na černém trojvrší zlatá palisádová hradba uprostřed s prázdnou branou, nad ní zlatá pohanská koruna proložená kosmým mlátkem a šikmým želízkem, obojí stříbrné."
    },
    {
        obec: "Licibořice",
        kod: 547832,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53823,
        latitude: 49.877003,
        longitude: 15.760021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Licibo%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. V zeleném bílá věž s hnědými dveřmi, černým soklem a zárubní, dvěma černými okny, horním větším obloukovým, černou bání s lucernou, zakončenou žlutou makovicí s křížkem. V bílém pruhu šikmo zelený dubový list, v něm svěšená žlutá borová šiška. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít. Vpravo stříbrná kostelní věž s dřevěnými dveřmi přirozené barvy, černým soklem a zárubní. Nad nimi nad sebou menší a větší obloukové okno, obojí černé. Věž je zakončena bání s lucernou, obojí černé a s makovicí s křížem, obojí zlaté. Vlevo zelený dubový list v něm svěšená zlatá borová šiška."
    },
    {
        obec: "Libřice",
        kod: 570273,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50344,
        latitude: 50.287938,
        longitude: 15.963989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/CoA_of_Lib%C5%99ice.svg",
        coatOfArmsFlagDescription: "List tvoří v žerďové části tři vodorovné pruhy, černý, bílý a červený. Ve střední části svislý modrý pruh a ve vlající části tři vodorovné pruhy, červený, bílý a černý. V modrém pruhu kosmo žlutá berla s bílým sudariem závitem k žerdi, šikmo podložená žluto-červeně polceným plamenným mečem se žlutým jílcem, hrotem dolů. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole černo-stříbrně dělené, v něm zleva nad sebou vynikají tři korouhve s ocasem na zlatých žerdích, červená mezi stříbrnými. V levém modrém poli kosmá zlatá berla se stříbrným sudariem šikmo podložená zlato-červeně polceným plamenným mečem se zlatým jílcem."
    },
    {
        obec: "Lično",
        kod: 576468,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51735,
        latitude: 50.177967,
        longitude: 16.172124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Znak_lycno.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutým svislým vlajícím pruhem širokým jednu čtvrtinu délky listu. V modrém poli žlutý rovnostranný liliový trojhran, zakončený k vlajícímu okraji celou bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý rovnostranný liliový trojhran zakončený nahoře celou stříbrnou lilií, provázenou dvěma přivrácenými zlatými křídly."
    },
    {
        obec: "Lidečko",
        kod: 544370,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.20313,
        longitude: 18.051411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/CZ_Lide%C4%8Dko_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, a tři bílé vlající klíny, z nichž střední svým vrcholem sahá do poloviny délky listu a oba krajní do poslední třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad skalnatým trojvrším vpravo radlice, vlevo vyniká od středu půl kola svaté Kateřiny, vše stříbrné."
    },
    {
        obec: "Lidice",
        kod: 532584,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27354,
        latitude: 50.143626,
        longitude: 14.190234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Coat_of_arms_of_Lidice.svg",
        coatOfArmsFlagDescription: "Žlutý list s červeným lemem žerďového a dolního okraje listu, širokým desetinu šířky listu. Z dolního rohu žlutého pole do vlajícího cípu dvakrát prohnutá černá trnová větev provázená dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě šikmo zvýšeně dvakrát prohnutá černá trnová větev do krajů, přeložená červenou kapitálou L, provázenou dole třemi (2, 1) červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Lidmaň",
        kod: 561100,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.381938,
        longitude: 15.036826,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Lidma%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu pod žlutou korunou dvě vztyčené přivrácené ryby se zkříženými ocasními ploutvemi, v modrém bílá, v červeném žlutá. Mezi nimi žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě pod zlatou korunou dvě vztyčené přivrácené ryby se zkříženými ocasními ploutvemi, pravá stříbrná, levá zlatá, mezi nimi zlatá osmihrotá hvězda."
    },
    {
        obec: "Lichkov",
        kod: 580571,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56168,
        latitude: 50.097876,
        longitude: 16.666117,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Lichkov.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený bílý, červený s bílou radlicí, bílý a zelený v poměru 1:1:2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na modré patě dva zelené pahorky, na každém smrk přirozených barev. Mezi nimi červený štítek se stříbrnou radlicí."
    },
    {
        obec: "Lichnov",
        kod: 597554,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79315,
        latitude: 50.007958,
        longitude: 17.62651,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Lichnov_Brunt%C3%A1l_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 5:14:5, žlutý, modrý a žlutý. V modrém pruhu vinný hrozen se dvěma listy, z něhož vyrůstají na stoncích se dvěma lístky k žerdi osmilistý květ a k vlajícímu okraji žalud, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vinný hrozen se dvěma listy, z něhož vyrůstají na stoncích se dvěma lístky vpravo osmilistý květ, vlevo žalud, vše zlaté."
    },
    {
        obec: "Lichnov",
        kod: 599603,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74275,
        latitude: 49.563651,
        longitude: 18.170051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Lichnov_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem sahajícím do středu vlajícího okraje. V zeleném klínu je bílý pluh (hák). Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Štít dělen stříbrným břevnem, v horním stříbrném poli tři zelené kužely, v dolním zeleném poli stříbrný, doleva obrácený pluh (hák)."
    },
    {
        obec: "Lichoceves",
        kod: 571326,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25264,
        latitude: 50.152863,
        longitude: 14.284353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Lichoceves_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy modrý, červený a modrý, v poměru 1 : 5 : 1. V červeném pruhu bílý středový kříž s rameny širokými sedminu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbro-červeně polceném štítě s modrou hlavou vpravo svatý Jiří na koni, probodávajícím kopím draka, vše červené, vlevo stříbrný kostel s věží vpravo."
    },
    {
        obec: "Líně",
        kod: 559164,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33021,
        latitude: 49.694828,
        longitude: 13.257039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/L%C3%ADn%C4%9B_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a černý, v poměru 2 : 1. V modrém pruhu tři (2, 1) žlutí líni, v černém dolů obrácená bílá hornická kladívka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě tři (2, 1) zlatí líni nad černou patou s dolů obrácenými stříbrnými hornickými kladívky."
    },
    {
        obec: "Linhartice",
        kod: 578339,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.757429,
        longitude: 16.695338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Linhartice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se šikmým bílým pruhem širokým jednu čtvrtinu délky listu. V horním modrém poli bílá hlava jestřába se žlutým zobákem k vlajícímu okraji, v dolním modrém poli žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě polovina stříbrného rytíře s kolčí přilbou, červenou šerpou od pravého ramene a zlatým mečem u boku, držící v pravici stříbrného jestřába se zlatou zbrojí a provázená vlevo nahoře zlatou růží s červeným semeníkem."
    },
    {
        obec: "Lípa",
        kod: 569038,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58257,
        latitude: 49.554584,
        longitude: 15.542919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Lipa_HB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:3:1. V bílém pruhu zelená lípa s černým kmenem, v rozích a cípech po jednom vztyčeném bílém lipovém listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zelenou hlavou se čtyřmi vztyčenými stříbrnými lipovými listy ze zeleného návrší vyrůstající zelená lípa s černým kmenem."
    },
    {
        obec: "Lípa",
        kod: 549622,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76311,
        latitude: 49.215383,
        longitude: 17.768842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Lipa_ZL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, žlutý, černý, bílý, zelený, bílý, černý a žlutý v poměru 1:1:1:4:1:1:1, uprostřed zeleného pruhu tři žluté lipové listy na stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-černě dělený štít, v dolním poli dvě stříbrná břevna, vše přeloženo zeleným kůlem se zlatou vykořeněnou lípou, provázeným nahoře dvěma zelenými obilnými klasy."
    },
    {
        obec: "Lípa nad Orlicí",
        kod: 576476,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51721,
        latitude: 50.135714,
        longitude: 16.098112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/L%C3%ADpa_nad_Orlic%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a bílých, v poměru 2 : 1 : 9 : 1 : 2. Ve středním pruhu žlutá vykořeněná lípa s kmenem provázeným dvěma přivrácenými bílými čápy s černými křídly a červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá vykořeněná lípa s kmenem provázeným dvěma přivracenými čápy přirozené barvy. Dole stříbrné vlnité břevno."
    },
    {
        obec: "Lipec",
        kod: 513270,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28126,
        latitude: 50.085084,
        longitude: 15.364668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Lipec-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři šikmé pruhy, bílý, červený a modrý, vycházející ze žerďového a horního okraje, a žluté vlající pole. V modrém pruhu tři žluté koruny, ve žlutém poli šikmo vztyčená zelená lipová větev střídavě se třemi listy a plodenstvími. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě šikmo dělený štít. Do horního pole vyniká stříbrná kostelní věž s černým obloukovým oknem a červenou stanovou střechou s černým křížkem, vlevo provázená třemi (2, 1) zlatými korunami. V dolním poli šikmo vztyčená zelená lipová větev střídavě se třemi listy a plodenstvími."
    },
    {
        obec: "Lipí",
        kod: 544761,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.949581,
        longitude: 14.352239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Lip%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý. Uprostřed listu bílá lilie obklopená čtyřmi (2, 2) odvrácenými žlutými lipovými dvojlisty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře vlnitě děleném štítě stříbrná lilie obklopená čtyřmi (2, 2) odvrácenými zlatými lipovými dvojlisty."
    },
    {
        obec: "Lipina",
        kod: 552305,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.741758,
        longitude: 17.323633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Lipina_%28OL%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červené pětiúhelníkové pole vycházející z poloviny žerďového a vlajícího okraje s vrcholem na středu horního okraje listu a vodorovný zelený pruh široký desetinu šířky listu na dolním okraji. V horním bílých trojúhelnících vztyčené odvrácené zelené lipové listy s listenem a plody. V červeném poli na zeleném pruhu kráčející bílý kůň se žlutou zbrojí převýšený žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený červený hrot, provázený dvěma vztyčenými odvrácenými zelenými lipovými listy s listenem a plody, v něm na zeleném trávníku stříbrný kráčející kůň se zlatou zbrojí převýšený zlatou osmihrotou hvězdou."
    },
    {
        obec: "Lipinka",
        kod: 540005,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78383,
        latitude: 49.837875,
        longitude: 17.050055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Lipinka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a vlnkovitý bílý, v poměru 1 : 3 : 2. V zeleném pruhu žlutý vztyčený lipový list s plodenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trojvrší se zlatým sluncem lípa přirozené barvy. V její koruně tři (1, 2) vztyčené zlaté lipové listy s plodenstvím."
    },
    {
        obec: "Lipník",
        kod: 591041,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.144357,
        longitude: 15.950723,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Lipn%C3%ADk_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a bílý v poměru 2:1:1. V horním žerďovém rohu zelený vztyčený lipový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravá polovina pětkrát stříbrno-červeně dělená cimbuřovým řezem v levé zlaté polovině vykořeněná lípa přirozené barvy."
    },
    {
        obec: "Lipnice nad Sázavou",
        kod: 569046,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58232,
        latitude: 49.613125,
        longitude: 15.413664,
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh, široký jednu třetinu délky listu a tři vodorovné pruhy, modrý, zubatý bílý a modrý, v poměru 4:3:3. Ve žlutém pruhu černé zkřížené ostrve. Bílý pruh má tři zuby, dvě stejné a dvě poloviční mezery. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lipník",
        kod: 565563,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29443,
        latitude: 50.272647,
        longitude: 14.912532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Lipnik_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu dvanáctinu délky listu z něhož vyrůstá zelená lípa. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, zeleně lemovaném štítě vyrůstající zelená lípa."
    },
    {
        obec: "Lipník nad Bečvou",
        kod: 514705,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.526832,
        longitude: 17.586743,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Lipn%C3%ADk_nad_Be%C4%8Dvou_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a zelený v poměru 4:1. Ze zeleného pruhu vyrůstá bílý korunovaný dvouocasý lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lipno",
        kod: 566403,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.300702,
        longitude: 13.680972,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Lipno_%28Louny_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a žlutý, v poměru 9 : 2 : 9. V bílém pruhu tři vztyčené lipové listy na spojených řapících, krajní odkloněné s plodenstvím. Ve žlutém pruhu chmelový list se dvěma odkloněnými dvojicemi šištic, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zúženým modrým vlnitým břevnem stříbrno-zlatě dělený štít. Nahoře tři vztyčené lipové listy na spojených řapících, krajní odkloněné s plodenstvím, dole vztyčený chmelový list se dvěma odkloněnými dvojicemi šištic, vše zelené."
    },
    {
        obec: "Lipno nad Vltavou",
        kod: 545597,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38278,
        latitude: 48.639427,
        longitude: 14.22942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Lipno_nad_Vltavou_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 3 : 5. V bílém pruhu dva vztyčené zelené lipové listy, v modrém pruhu šikmo vor kosmo přeložený veslem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře zvýšeně vlnitě děleném štítě nahoře dva vztyčené zelené lipové listy, dole šikmo položený vor kosmo přeložený veslem, obojí zlaté."
    },
    {
        obec: "Lipoltice",
        kod: 575313,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53364,
        latitude: 49.98787,
        longitude: 15.568989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Lipoltice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým pruhem širokým čtvrtinu délky listu, v něm bílé kopí se žlutou stuhou uvázanou pod hrotem. V bílém poli tři (2, 1) černé zvony se srdci ve tvaru lipového listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně vpravo polcený štít. V pravém poli stříbrné kopí se zlatou stuhou uvázanou pod hrotem, vlevo tři (2, 1) černé zvony se srdci ve tvaru lipového listu."
    },
    {
        obec: "Lipová",
        kod: 554626,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.036398,
        longitude: 12.448928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Lipova_CH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý, v poměru 1 : 2 : 1. V modrém pruhu zkřížené bílé sekery se žlutými topůrky, nad nimi dva a pod nimi jeden svěšený žlutý lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo zkřížené stříbrné sekery se zlatými topůrky mezi třemi (2, 1) svěšenými zlatými lipovými listy, vlevo tři stříbrné lovecké trubky se zlatým kováním pod sebou, každá převýšena zlatým tlapatým křížkem."
    },
    {
        obec: "Lipov",
        kod: 586323,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69672,
        latitude: 48.905046,
        longitude: 17.46182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Lipov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeným šikmým pruhem širokým polovinu šířky listu, lemovaným dvěma žlutými pruhy, širokými jednu desetinu šířky listu. Uprostřed červeného pruhu šikmo dolů obrácená bílá lipová větev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně šikmo děleném štítě vpravo vyskakující zlatý kozel s červeným jazykem, vlevo šikmo dolů obrácená stříbrná lipová větev."
    },
    {
        obec: "Lipová",
        kod: 589691,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79845,
        latitude: 49.526065,
        longitude: 16.861891,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Lipov%C3%A1_PV_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře čtvrcený list. Uprostřed bílé kruhové pole o průměru poloviny šířky listu s modro-červeně polcenou lilií. V každé čtvrtině po jednom žlutém lipovém listu směřujícím do rohu nebo cípu listu praporu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vyrůstají ze stříbrného trojvrší s modro-červeně polcenou lilií tři zlaté lípy."
    },
    {
        obec: "Lipová",
        kod: 514772,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.409927,
        longitude: 17.614169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Lipova_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou postavenou kypřicí, z jejího středu vyrůstají do stran dvě dvojice od sebe odkloněných žlutých lipových listů na prohnutých řapících. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě postavená stříbrná kypřice, z jejího středu do stran vyrůstá dvojice od sebe odkloněných zlatých lipových listů na prohnutých řapících."
    },
    {
        obec: "Lipová",
        kod: 562661,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40781,
        latitude: 51.011146,
        longitude: 14.360332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Lipov%C3%A1_CoA_CZ.png",
        coatOfArmsFlagDescription: "Červeno-zeleně kosmo dělený list z první šestiny horního do páté šestiny dolního okraje. Ze středu listu vynikají k hornímu a dolnímu okraji listu lipové listy opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném návrší stříbrná postava Spravedlnosti se vztyčeným mečem v pravici a zlatými miskovými vahami v levici, se zlatým pásem, zelenou kruhovou sponou s červeným vztyčeným lipovým listem na hrudi."
    },
    {
        obec: "Lipová",
        kod: 586871,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.121999,
        longitude: 17.879725,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Lipov%C3%A1_%28Zl%C3%ADn_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Uprostřed listu vyrůstá ze žluté koruny bílá lípa se žlutými listy. Do kmene je shora vetknut žlutý meč. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě vyrůstá ze zlaté koruny stříbrná lípa se zlatými listy. Do kmene je shora vetknut zlatý meč."
    },
    {
        obec: "Lipová-lázně",
        kod: 540030,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79061,
        latitude: 50.227866,
        longitude: 17.14055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Lazne_Lipova_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: žlutý se zeleným lipovým dvoulistem, stonkem dolů a modrý s bílou fontánou se dvěma tryskajícími prameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře dělený štít, nahoře zelený vztyčený lipový dvoulist, dole stříbrná fontána se dvěma tryskajícími prameny."
    },
    {
        obec: "Lipovec",
        kod: 547875,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.916436,
        longitude: 15.545079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Lipovec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, a vlající pětiúhelníkové vyduté bílé pole, vycházející z poslední třetiny horního a dolního okraje listu a zakončené lipovým listem, dosahujícím do první čtvrtiny délky listu. V poli černé nožové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě snížený stříbrný vydutý hrot zakončený lipovým listem. V hrotu černé nožové kolo."
    },
    {
        obec: "Lipovice",
        kod: 537071,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.100635,
        longitude: 13.993981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Lipovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Ve žlutém pruhu lipový, v zeleném konopný list, odvráceně položené a opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně děleném štítě nahoře vztyčený lipový a dole svěšený konopný list opačných barev."
    },
    {
        obec: "Liptál",
        kod: 544396,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75501,
        latitude: 49.290608,
        longitude: 17.921879,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/CZ_Lipt%C3%A1l_COA.svg",
        coatOfArmsFlagDescription: "Bílý list s modrým svislým žerďovým pruhem o šířce jedné třetiny délky listu. V bílém poli červené srdce o výšce sedm desetin šířky listu a šířce sedm desetin délky bílého pole. V modrém pruhu tři vztyčené bílé květy zvonku nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou se třemi stříbrnými vztyčenými květy zvonku červené srdce, šikmo zdola prostřelené zlatým šípem."
    },
    {
        obec: "Lipovec",
        kod: 581950,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67915,
        latitude: 49.38404,
        longitude: 16.805938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Lipovec_%28Blansko%29_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a zelené pole. V první třetině délky listu lípa vyrůstající z vodorovného pruhu vycházejícího z dvanácté šestnáctiny žerďového okraje listu, pod ní devíticípá hvězda, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě z oblouku vyrůstající lípa, dole devítihrotá hvězda, vše stříbrné."
    },
    {
        obec: "Liptaň",
        kod: 597571,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.222367,
        longitude: 17.605521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Lipta%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:2:1. V modrém pruhu nad bílo-červeně šachovaným pohárem váhy provázené nahoře dvěma růžemi, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno-červeně šachovaným pohárem váhy provázené nahoře dvěma růžemi, vše stříbrné."
    },
    {
        obec: "Lipůvka",
        kod: 581968,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.340382,
        longitude: 16.553004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Lip%C5%AFvka_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílý patriarší tlapatý kříž zakončený kotvou, provázený čtyřmi žlutými lipovými listy směřujícími do rohů a cípů praporu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatá vykořeněná lípa, uprostřed mezi kořeny stříbrný patriarší kříž zakončený kotvou."
    },
    {
        obec: "Lísek",
        kod: 596051,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.586936,
        longitude: 16.201244,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/L%C3%ADsek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý, v poměru 7 : 10 : 7. V zeleném pruhu bílá, žlutě lemovaná a drahými kameny zdobená mitra s bílými fimbriemi se žlutým třepením, nad třemi (2, 1) žlutými koulemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, vpravo zlato-červeně dělený, nahoře trojlist (2, 1) se třemi lískovými oříšky přirozené barvy, dole stříbrný kůl. V levém zeleném poli stříbrná, zlatem a drahými kameny zdobená mitra se stříbrnými fimbriemi se zlatým třepením nad třemi (2, 1) zlatými koulemi."
    },
    {
        obec: "Lískovice",
        kod: 573086,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.336986,
        longitude: 15.554989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/L%C3%ADskovice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílo-zeleně vydutě dělený a červený, v poměru 1 : 2 : 1. Z dolního okraje žerďového pruhu vyniká bílá berla závitem k žerďovému okraji listu, se třemi (1, 2) žlutými koulemi v hlavici. Ve vydutém zeleném poli zakončeném listem jírovce, dotýkajícího se horního okraje, tři svěšené bílé lískové oříšky na žluté stopce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně vpravo polcený štít. V pravém poli vyrůstající stříbrná berla se třemi (1, 2) zlatými koulemi v závitu. V levém poli zelený vydutý hrot zakončený listem jírovce. V hrotu tři svěšené stříbrné oříšky na zlaté stopce."
    },
    {
        obec: "Líský",
        kod: 571555,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27376,
        latitude: 50.250661,
        longitude: 13.929036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/L%C3%ADsk%C3%BD_CoA.jpg",
        coatOfArmsFlagDescription: "Ve žlutém listu tři (od žerdi 1, 2) spojené odvrácené hnědé lískové oříšky se dvěma k žerdi vyrůstajícími zelenými listy a modré kruhové pole se žlutou korunou s červenými drahokamy, provlečenou bílým gotickým klíčem zuby dolů a k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nahoře tři (1, 2) spojené odvrácené lískové oříšky se dvěma vztyčenými odkloněnými listy, vše přirozené barvy, dole modrý kotouč se zlatou korunou s červenými drahokamy, provlečenou postaveným stříbrným gotickým klíčem."
    },
    {
        obec: "Lisov",
        kod: 540064,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.638638,
        longitude: 13.100966,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Lisov_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list; uprostřed čelně hledící hlava lišky držící v tlamě červené palečné kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě čelně hledící hlava lišky přirozené barvy, držící v tlamě červené palečné kolo."
    },
    {
        obec: "Lišany",
        kod: 542016,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27052,
        latitude: 50.147452,
        longitude: 13.742038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Lisany_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva pruhy žlutý a zelený, uprostřed lískový oříšek přirozené barvy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně děleném štítě lískový oříšek přirozené barvy."
    },
    {
        obec: "Lišany",
        kod: 566411,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.344465,
        longitude: 13.642025,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Lisany_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu pod bílým latinským křížem bílá husa sedící ve žluté koruně, ve žlutém pod svěšenou chmelovou větvičkou se třemi šišticemi a listem tři vějířovitě vyrůstající obilné klasy se dvěma listy, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo pod stříbrným latinským křížem stříbrná husa sedící ve zlaté koruně, vlevo svěšená chmelová větvička se třemi šišticemi a listem, pod ní tři vějířovitě vyrůstající obilné klasy se dvěma listy, vše zelené."
    },
    {
        obec: "Lišice",
        kod: 570290,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.181052,
        longitude: 15.408168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Li%C5%A1ice_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed bílý hrot kopí, s hákem k žerďovému a kroužkem na tuleji k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vztyčený stříbrný hrot kopí, vpravo s hákem a vlevo s kroužkem na tuleji."
    },
    {
        obec: "Líšina",
        kod: 540617,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.603604,
        longitude: 13.164824
    },
    {
        obec: "Líšná",
        kod: 596060,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.640272,
        longitude: 16.151155
    },
    {
        obec: "Líšná",
        kod: 514802,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75115,
        latitude: 49.411684,
        longitude: 17.547451,
        coatOfArmsFlagDescription: "Zelený list v žerďové a střední části bílý kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kohout s červenou zbrojí."
    },
    {
        obec: "Líšná",
        kod: 559962,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.885111,
        longitude: 13.806522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Lisna_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list; horní žerďové a dolní vlající pole bílá, horní vlající pole červené a dolní žerďové černé. Uprostřed zelené čtvercové pole široké dvě třetiny šířky listu se žlutými zkříženými klíči. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně polceném štítě dole černo - červeně polcená kvádrovaná hradba s knížecí korunou. V pravém poli kosmá černá orlice s červeným jazykem, v levém zkřížené zlaté klíče."
    },
    {
        obec: "Lišnice",
        kod: 567248,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.454552,
        longitude: 13.63312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Li%C5%A1nice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červeno-modře polcený a zubatý bílý, v poměru 5:2. Zubatý pruh má tři obdélníkové zuby a dvě mezery. V horní části listu tři žluté hrušky. V mezerách stojí bílá ovce se žlutou rolničkou a žlutý pes s červeným jazykem a černým obojkem se žlutým kroužkem, vzpřímené a přivrácené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě v patě stříbrné cimbuří se třemi stínkami, na krajních stojí stříbrná ovce se zlatým obojkem s rolničkou, černou zbrojí a zlatý pes s červeným jazykem a černým obojkem se zlatým kroužkem, vzpřímené a přivrácené, mezi nimi tři (1,2) zlaté hrušky."
    },
    {
        obec: "Líšnice",
        kod: 540161,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78985,
        latitude: 49.75594,
        longitude: 16.876089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/L%C3%AD%C5%A1nice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 3:2. V žerďové části modrého pruhu žlutá šesticípá hvězda o průměru rovném šířce dolního pruhu; její střed se nachází tři čtvrtiny jejího průměru do žerďového okraje. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Líšnice",
        kod: 580589,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56184,
        latitude: 50.079921,
        longitude: 16.508318,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Obec_L%C3%AD%C5%A1nice_%C3%9AO_-_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý se zkříženými žlutými cepy, bílý a červený v poměru 2:1:6:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené zlaté cepy, nahoře přeložené stříbrnou hlavou jednorožce s červeným jazykem, dole červeným bubnem (kotlem) se stříbrnou blánou."
    },
    {
        obec: "Líšnice",
        kod: 539457,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.888884,
        longitude: 14.319068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/L%C3%AD%C5%A1nice_PZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět kosmých pruhů, bílý, modrý, žlutý, zelený a žlutý, v poměru 2 : 3 : 2 : 3 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře zlatým kosmým břevnem dělený štít, nahoře šikmo tři lískové oříšky na stopce se dvěma listy, vše zlaté, dole stříbrná lilie."
    },
    {
        obec: "Líšný",
        kod: 563676,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.64567,
        longitude: 15.212313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/L%C3%AD%C5%A1n%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vydutým žerďovým klínem s vrcholem ve druhé třetině délky listu. V klínu tři srostlé lískové ořechy (od žerdi 2, 1), v modrém poli žlutý, do kruhu stočený rozkřídlený drak zády k vlajícímu okraji s ocasem pod klínem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený vydutý stříbrný hrot se třemi (1, 2) srostlými lískovými ořechy s oplodím přirozené barvy, nad ním zlatý rozkřídlený do kruhu stočený drak s ocasem pod hrotem."
    },
    {
        obec: "Lišov",
        kod: 544779,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37372,
        latitude: 49.016073,
        longitude: 14.608479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Li%C5%A1ov_znak.png",
        coatOfArmsFlagDescription: "Bílý list se šikmým červeným pruhem vycházejícím ze čtvrté a páté pětiny žerďového a první až třetí pětiny dolního okraje listu do třetí až páté pětiny horního a první a druhé pětiny vlajícího okraje listu. Uprostřed bílý dvouocasý lev se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrný dvouocasý lev se zlatou zbrojí a jazykem."
    },
    {
        obec: "Líšťany",
        kod: 559172,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33035,
        latitude: 49.831183,
        longitude: 13.181206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/L%C3%AD%C5%A1%C5%A5any_%28Plze%C5%88-North_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou lilií obklopenou do kruhu osmi odvrácenými žlutými lískovými oříšky s bílým oplodím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá lilie obklopená do kruhu osmi odvrácenými zlatými lískovými oříšky se stříbrným oplodím."
    },
    {
        obec: "Líšťany",
        kod: 546879,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.312594,
        longitude: 13.800031,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Listany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List se dvěma kosmými pruhy, žlutým a červeným, širokými jednu čtvrtinu šířky listu, jejichž dělící čára tvoří úhlopříčku listu. Horní vlající trojúhelníkové pole je červené, dolní žerďové žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zlatě kosmo dělený štít, nahoře zlatá lísková snítka se třemi listy a třemi oříšky, dole červené hořící srdce šikmo dolů prostřelené černým šípem."
    },
    {
        obec: "Líté",
        kod: 566420,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.913753,
        longitude: 13.26085,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/L%C3%ADt%C3%A9_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, zelený a střídavě bílé a modré, v poměru 11 : 1 : 1 : 1 : 1 : 1. Mezi přivrácenými bílými koňskými hlavami se žlutým uzděním, vyrůstajícími z žerďového a vlajícího okraje zeleného pruhu, vyrůstá z horního bílého pruhu žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou vynikají z okrajů štítu dvě přivrácené stříbrné koňské hlavy se zlatým uzděním, mezi nimi z paty vyrůstající zlatý obilný klas se dvěma listy. V patě dvě modrá vlnitá břevna."
    },
    {
        obec: "Litenčice",
        kod: 588695,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76813,
        latitude: 49.202303,
        longitude: 17.208524,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Liten%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý v poměru 1:2:1. V červeném pruhu tři bílé zkřížené liliové hůlky, prostřední obrácená k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít, vpravo na zeleném trojvrší listnatý strom přirozené barvy, vlevo tři zkřížené stříbrné liliové hůlky, prostřední dolů obrácená."
    },
    {
        obec: "Liteň",
        kod: 531456,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.903811,
        longitude: 14.149092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Liten_znak.png"
    },
    {
        obec: "Litíč",
        kod: 546470,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.375254,
        longitude: 15.84848,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, červený, bílý a modrý, v poměru 7 : 1 : 1 : 1. Z horního rohu listu vyniká kosmo žlutý sokolí spár s bílým opeřením a rolničkou, z horního cípu šikmo bílá lví tlapa se žlutou zbrojí. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s červeno-stříbrně pilovitě děleným břevnem. Nahoře z rohů štítu vynikají vpravo kosmo zlatý sokolí spár se stříbrným opeřením a rolničkou, vlevo šikmo stříbrná lví tlapa se zlatou zbrojí. Dole zlatá dóza na masti."
    },
    {
        obec: "Litichovice",
        kod: 532258,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.783034,
        longitude: 14.850678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Litichovice_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem s vrcholem ve druhé třetině délky listu. Z druhé a čtvrté pětiny žerďového okraje listu vycházejí dva modré klíny do vrcholu bílého klínu, vše zakončené polovinou bílé lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě modrý vrch, z něhož vyrůstá stříbrná lilie. Z jejího přepásání se do paty štítu rozlévají tři odvrácené stříbrné vlnité potoky."
    },
    {
        obec: "Litoboř",
        kod: 573388,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.449962,
        longitude: 16.015368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Erb_Litobo%C5%99.jpeg",
        coatOfArmsFlagDescription: "Bílo - zeleně jedním stupněm dělený list, na žerďovém okraji v poměru 9 : 5, na vlajícím 5 : 9. V žerďové polovině bílého pole červený korbel, ve vlající polovině zeleného pole bílá utržená kohoutí hlava s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně doleva stupněm děleném štítě nahoře červený korbel, dole stříbrná utržená kohoutí hlava s červenou zbrojí."
    },
    {
        obec: "Litobratřice",
        kod: 594369,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.887149,
        longitude: 16.402604,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Znak_Litobrat%C5%99ice.jpg",
        coatOfArmsFlagDescription: "Červený list se sv. Jiřím na koni kopím probodávajícím draka, vše bílé, provázené nahoře dvěma bílými květy se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě sv. Jiří na koni kopím probodávající draka, vše stříbrné, provázené nahoře dvěma stříbrnými květy se zlatým středy."
    },
    {
        obec: "Litohoř",
        kod: 550507,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.066937,
        longitude: 15.768521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Litoho%C5%99_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a červených, v poměru 1:1:2:1:1. Přes pruhy modrý kosočtverec s vrcholy na okrajích listu, uprostřed s červenou žlutě lemovanou knížecí čepicí provázenou nahoře žlutou lilií a dole pěti žlutými šesticípými hvězdami podél stran kosočtverce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá čelně postavená radlice, podložená nahoře doleva položeným červeným mečem. V radlici červená, zlatě lemovaná knížecí čepice provázená nahoře lilií, po stranách a dole pěti hvězdami, vše zlaté."
    },
    {
        obec: "Litohošť",
        kod: 561266,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.449741,
        longitude: 15.091095
    },
    {
        obec: "Litohlavy",
        kod: 553611,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.766543,
        longitude: 13.56469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Litohlavy_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový klín s vrcholem na vlajícím okraji a dvě trojúhelníková pole, horní žluté a dolní černé. V žerďové části klínu bílá busta dívčí hlavy z profilu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-zlatě polcený štít s červeným vydutým klínem, v něm stříbrná busta dívčí hlavy."
    },
    {
        obec: "Litochovice",
        kod: 551341,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.159413,
        longitude: 13.94153
    },
    {
        obec: "Litoměřice",
        kod: 564567,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.53852,
        longitude: 14.130646,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Litom%C4%9B%C5%99ice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list na dolním okraji bílý kvádrovaný zubatý pruh široký polovinu šířky listu, ze kterého vyrůstá český lev. Bílý pruh má devět obdélníkových zubů a osm mezer. Zuby jsou vysoké jednu desetinu šířky listu a široké jednu dvanáctinu délky listu, krajní zuby mají poloviční šířku jako všechny mezery. Pod lvem otevřená brána s vytaženou mříží a s otevřenými vraty, oboje žluté, závěsy černé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Litomyšl",
        kod: 578347,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.870623,
        longitude: 16.311162,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Litomy%C5%A1l_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Na červeném listu bílá lilie z městského znaku. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Litostrov",
        kod: 583308,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.225246,
        longitude: 16.330795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Litostrov_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným vykořeněným listnatým stromem, přes kmen vodorovně přeložená červená štika. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vykořeněný listnatý strom, přes kmen přeložená červená štika."
    },
    {
        obec: "Litovany",
        kod: 591068,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67557,
        latitude: 49.055056,
        longitude: 16.044024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Litovany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu polovina bílého koně ve skoku se žlutým uzděním, provázená za hlavou bílým rýčem bez násady. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě polovina stříbrného koně ve skoku se zlatým uzděním, provázeného vlevo nahoře stříbrným rýčem bez násady."
    },
    {
        obec: "Litovel",
        kod: 503444,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78401,
        latitude: 49.701309,
        longitude: 17.076255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Znak_m%C4%9Bsta_Litovel.png",
        coatOfArmsFlagDescription: "List je rozdělen na šest čtvercových polí, střídavě modrých a bílých. V horním žerďovém modrém poli bílý kapr a štika hlavami vzhůru a břichy k sobě. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Litultovice",
        kod: 507920,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74755,
        latitude: 49.90397,
        longitude: 17.751529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Litultovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý v poměru 1:2:1. Na modrém poli dvě bílá lekna vyrůstající ze stoupajícího bílo-červeně šachovaného půlměsíce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrná lekna vyrůstající ze stoupajícího stříbrno-červeně šachovaného půlměsíce."
    },
    {
        obec: "Litvínov",
        kod: 567256,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43601,
        latitude: 50.598526,
        longitude: 13.610342,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Litv%C3%ADnov_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, červený, zubatý bílý a žlutý v poměru 1:5:2:1. Bílý pruh má šest čtvercových zubů, pět stejných a dvě poloviční mezery. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Litošice",
        kod: 575330,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.984932,
        longitude: 15.514334,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Lito%C5%A1ice.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený a střídavě vlnité bílé a modré se čtyřmi vrcholy a pěti prohlubněmi, v poměru 12 : 1 : 1 : 1 : 1. V zeleném pruhu mezi žlutými klasy se dvěma listy bílá ženská hlava s krkem a žlutými vlasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě čelně stříbrná ženská hlava s krkem a zlatými vlasy provázená zlatými obilnými klasy se dvěma listy nad třikrát stříbrno-modře vlnitě dělenou vlnitou patou."
    },
    {
        obec: "Litvínovice",
        kod: 544795,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.962172,
        longitude: 14.451559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Litvinovice_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě bílých a modrých v poměru 5 : 1 : 1 : 1 : 1. V horním bílém pruhu tři (1, 2) dvojice zkřížených červených obilných klasů s odkloněnými listy po stranách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad dvěma sníženými modrými vlnitými břevny tři (1, 2) dvojice zkřížených červených obilných klasů s odkloněnými listy po stranách."
    },
    {
        obec: "Lkáň",
        kod: 546691,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.443126,
        longitude: 13.969778,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Lk%C3%A1%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a zelený, v poměru 3:1:1. V žerďové polovině červeného pruhu bílý vlk se žlutým jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře stříbrný vlk se zlatým jazykem, dole na zeleném trávníku smuteční vrba přirozené barvy."
    },
    {
        obec: "Lnáře",
        kod: 551350,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38742,
        latitude: 49.458006,
        longitude: 13.784168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Lnare_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým šikmý pruhem, širokým polovinu šířky listu, s šikmým žlutým gotickým klíčem zuby nahoru a k vlajícími okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě kosmo položený stříbrný kapr, šikmo podložený vztyčeným zlatým gotickým klíčem zuby ven."
    },
    {
        obec: "Lobeč",
        kod: 531677,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27736,
        latitude: 50.460567,
        longitude: 14.666882
    },
    {
        obec: "Lobendava",
        kod: 545708,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40784,
        latitude: 51.019574,
        longitude: 14.314344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Lobendava_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý, v poměru 1 : 2 : 1. V červeném pruhu dva vztyčení odvrácení bílí lososi přeložení záštitou vztyčeného bílého meče se žlutým jílcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě dva vztyčení odvrácení stříbrní lososi přeložení záštitou vztyčeného stříbrného meče se zlatým jílcem a provázeného nahoře zlatou a stříbrnou lilií."
    },
    {
        obec: "Lobodice",
        kod: 515191,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75101,
        latitude: 49.395559,
        longitude: 17.292031,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Lobodice_coat_of_arms.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a bílý, v poměru 1:2:1. V červeném pruhu žlutá věž s černou branou a oknem, se stanovou střechou zakončenou křížkem, provázená dvěma odvrácenými prohnutými bílými lososy hlavou k hornímu okraji a se žlutým tlapatým křížkem pod břichem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad modrou patou s vynikající stříbrnou polovinou palečného kola zlatá věž s černou branou a oknem, se zlatou stanovou střechou zakončenou křížkem, provázená dvěma odvrácenými prohnutými vztyčenými stříbrnými lososy se zlatým tlapatým křížkem pod břichem."
    },
    {
        obec: "Ločenice",
        kod: 544809,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37322,
        latitude: 48.824304,
        longitude: 14.527609,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Znak_obce_Lo%C4%8Denice.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený s bílým vykračujícím lvem a zelený se čtyřmi žlutými propletenými obilnými klasy, z nichž krajní mají po jednom listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře stříbrný vykračující lev, dole čtyři propletené zlaté obilné klasy, krajní s jedním listem."
    },
    {
        obec: "Loděnice",
        kod: 594377,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67175,
        latitude: 49.011698,
        longitude: 16.463176,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Lod%C4%9Bnice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, žlutý a červený, v poměru 3:1:1:1. Z horního červeného vyrůstá půl černé orlice se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít, nahoře vyrůstá černá orlice se zlatou zbrojí, dole dva zkřížené zlaté meče provázené čtyřmi stříbrnými růžemi se zlatými semeníky a zelenými kališními listy."
    },
    {
        obec: "Loděnice",
        kod: 531464,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26712,
        latitude: 49.994962,
        longitude: 14.157959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Lodenice_%28Beroun%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "V modrém listu loď s kosmým veslem, kormidlem a stěžněm na zádi, vše bílé. Na stěžni dvoucípý praporec vlající nad příď, tvořený dvěma vodorovnými pruhy, bílým a červeným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě loď s veslem, kormidlem a stěžněm na zádi, vše stříbrné. Na stěžni vlaje nad příď dvoucípý, stříbrno-červeně dělený praporec."
    },
    {
        obec: "Lodhéřov",
        kod: 546666,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37826,
        latitude: 49.215189,
        longitude: 14.960045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Lodh%C3%A9%C5%99ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. V žerďové polovině modrého pruhu žluté korunované unciální písmeno M. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté korunované unciální písmeno M."
    },
    {
        obec: "Lodín",
        kod: 570303,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.270952,
        longitude: 15.610553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Lodin_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed žlutý obilný snop dole podložený dvěma zkříženými bílými pštrosími pery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatý snop dole podložený dvěma zkříženými stříbrnými pštrosími pery."
    },
    {
        obec: "Lochenice",
        kod: 570311,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50302,
        latitude: 50.272221,
        longitude: 15.820017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/CoA_of_Lochenice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, vlnitý bílý se dvěma vrcholy a třemi prohlubněmi a zvlněný modrý, v poměru 4 : 1 : 1. V žerďové části koruna nad obilným snopem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlevo vlnitě polcený štít. V pravém červeném poli koruna nad obilným snopem, obojí zlaté, levé pole stříbrno-modře vlnitě polcené."
    },
    {
        obec: "Lochousice",
        kod: 566462,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.675204,
        longitude: 13.090555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Lochousice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, bílý, modrý, bílý, modrý, žlutý a modrý, a zelený kanton široký čtyři sedminy šířky listu. V kantonu bílý mlýnský kámen s položenou černou kypřicí provázený dvěma žlutými obilnými klasy, každý se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře děleném štítě nahoře stříbrný mlýnský kámen s položenou černou kypřicí provázený dvěma zlatými obilnými klasy, každý se dvěma listy, dole tři stříbrná vlnitá břevna přeložená zlatou lilií."
    },
    {
        obec: "Lochovice",
        kod: 531472,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26723,
        latitude: 49.853286,
        longitude: 13.981372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Lochovice_znak.png"
    },
    {
        obec: "Loket",
        kod: 560537,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35733,
        latitude: 50.186112,
        longitude: 12.754163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Loket_Sokolov_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý v poměru 2:1. V horním pruhu k žerdi obrácená a v lokti ohnutá obrněná paže s obrněnou rukavicí, třímající meč hrotem směřujícím k vlajícímu cípu, vše bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Loket",
        kod: 530093,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.655592,
        longitude: 15.116995
    },
    {
        obec: "Lom",
        kod: 536822,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.410552,
        longitude: 13.989092
    },
    {
        obec: "Lom",
        kod: 563587,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.362958,
        longitude: 14.62841,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Lom_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "List bílo-zeleně dělený zeleno-bíle děleným lomeným pruhem, vycházejícím z šesté a sedmé desetiny žerďového a vlajícího okraje listu, s vrcholem v desetině šířky listu. V zeleném poli bílý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně vzhůru lomeným břevnem dělený štít. Břevno zeleno-stříbrně dělené, pod ním stříbrný zvon."
    },
    {
        obec: "Lom",
        kod: 567264,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43511,
        latitude: 50.593384,
        longitude: 13.657403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Lom_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy červený, bílý a modrý, přes které je položen na podélné ose listu žlutý vodorovný zubatý pruh široký jednu čtvrtinu šířky listu, se třemi obdélníkovými zuby a dvěma stejnými mezerami. V prostředním zubu zelený vztyčený lipový list. Výška zubu je jedna třetina šířky žlutého pruhu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lom u Tachova",
        kod: 541702,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.818772,
        longitude: 12.692703,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Znak_obce_Lom_u_Tachova.gif",
        coatOfArmsFlagDescription: "Modrý list, uprostřed v bílé podkově žlutý zvon se závěsem, v něm modrá věž s cimbuřím, černou branou a dvěma okny vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve stříbrné podkově zlatý zvon se závěsem, v něm modrá věž s cimbuřím s černou branou a dvěma okny vedle sebe."
    },
    {
        obec: "Lomec",
        kod: 578070,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.371502,
        longitude: 13.267633
    },
    {
        obec: "Lomnice",
        kod: 581976,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.404719,
        longitude: 16.413696,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/CoA_of_Lomnice_JM.svg",
        coatOfArmsFlagDescription: "Bílý list s černým křídlem péry vzhůru se žlutým perizoniem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lomnice",
        kod: 560545,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.211851,
        longitude: 12.632789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Lomnice_%28Sokolov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy, žlutý, modrý a žlutý. V žerďovém pruhu bílý šíp hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo vztyčený stříbrný šíp, vlevo modré břevno."
    },
    {
        obec: "Lomnice",
        kod: 597589,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79302,
        latitude: 49.871099,
        longitude: 17.415174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Lomnice_%28okres_Brunt%C3%A1l%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným středovým křížem s rameny širokými dvě devítiny šířky listu. V horním žerďovém poli kosmo mlátek, šikmo podložený želízkem, obojí černé na hnědých topůrkách. V horním vlajícím poli červený květ kamzičníku rakouského se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený heroldský kříž provázený nahoře vpravo kosmým mlátkem, šikmo podloženým želízkem, obojí černé s topůrky přirozené barvy, vlevo červeným květem kamzičníku rakouského se zlatým středem."
    },
    {
        obec: "Lomnice nad Lužnicí",
        kod: 546674,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37816,
        latitude: 49.084786,
        longitude: 14.717374,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Lomnice_nad_Lu%C5%BEnic%C3%AD_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: bílý, červený a bílý v poměru 1:2:1. V červeném pruhu bílá růže se žlutým semeníkem mezi dvěma žlutými liliemi, jejichž osy jsou na čtvrtinách délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lomnice nad Popelkou",
        kod: 577308,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51251,
        latitude: 50.530724,
        longitude: 15.373517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Znaklomnice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý, v poměru 3:1. V červeném ozbrojenec v plátové zbroji s bojovou sekerou v pravé vztyčené ruce a halapartnou v levé; topůrko a dřevec jsou žluté, rukavice černé a vše ostatní bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lomy",
        kod: 587605,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.065617,
        longitude: 15.601184
    },
    {
        obec: "Lomnička",
        kod: 583316,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.367091,
        longitude: 16.431352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Lomni%C4%8Dka_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma bílými dýkami hrotem nahoru se žlutými jílci a bílými záštitami a s bílým vlnitým pruhem vycházejícím ze sedmé osminy žerďového a vlajícího okraje. Bílý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným vlnitým břevnem dvě stříbrné vztyčené dýky se zlatými rukojeťmi a stříbrnými záštitami."
    },
    {
        obec: "Lopeník",
        kod: 592340,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68767,
        latitude: 48.942882,
        longitude: 17.781225,
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhu střídavě žlutých a černých 1:1:6:1:1. Přes pruhy zelený kosočtverec s vrcholy na okraji listu se žlutou svěšenou dubovou větévkou s jedním žaludem mezi dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený od poloviny vydutý hrot se zlatou svěšenou dubovou větévkou s jedním žaludem a dvěma listy, provázený dvěma černými růžemi se zlatými semeníky a zelenými kališními listy."
    },
    {
        obec: "Losiná",
        kod: 558001,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.6693,
        longitude: 13.448442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Losin%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých, přeložených červeným svislým středovým pruhem širokým polovinu délky listu. V červeném pruhu žlutá osmicípá hvězda nad bílou růží se žlutým semeníkem. Poměr šířky k délce je listu 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo tři stříbrná břevna. Vlevo, nad stříbrnou růží se zlatým semeníkem, volná kvádrovaná hradba, z ní vpravo vyniká věž s cimbuřím a černým obloukovým oknem, obojí stříbrné. Nad hradbou zlatá osmihrotá hvězda."
    },
    {
        obec: "Lošany",
        kod: 533505,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.99764,
        longitude: 15.126048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Znak_obce_Lo%C5%A1any.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, modrý a zubatý kvádrovaný bílý se třemi čtvercovými zuby a dvěma stejnými mezerami. V červeném pruhu žlutá, k vlajícímu okraji obrácená dračí hlava s bílými zuby, zespodu probodnutá bílým mečem se žlutým jílcem. V modrém pruhu bílá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo doleva obrácená zlatá dračí hlava se stříbrnými zuby, zespodu probodnutá stříbrným mečem se zlatým jílcem, vlevo nad stříbrnou kvádrovanou hradbou s cimbuřím stříbrná růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Loštice",
        kod: 540196,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78983,
        latitude: 49.744796,
        longitude: 16.929028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Lo%C5%A1tice_CoA.png"
    },
    {
        obec: "Loucká",
        kod: 535095,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27324,
        latitude: 50.327578,
        longitude: 14.239126
    },
    {
        obec: "Loučany",
        kod: 552232,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78344,
        latitude: 49.601996,
        longitude: 17.082765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Loucany_znak.jpeg",
        coatOfArmsFlagDescription: "Zelený list s bílou rozkřídlenou husou se žlutou zbrojí a červeným jazykem. V horním rohu žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě rozkřídlená stříbrná husa se zlatou zbrojí a červeným jazykem, v levém rohu zlatá lilie."
    },
    {
        obec: "Loučeň",
        kod: 537446,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28937,
        latitude: 50.285812,
        longitude: 15.02016,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Lou%C4%8De%C5%88_znak.png"
    },
    {
        obec: "Loučim",
        kod: 553867,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.367168,
        longitude: 13.112222
    },
    {
        obec: "Loučka",
        kod: 552038,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.656944,
        longitude: 17.011221,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Loucka_znak.gif",
        coatOfArmsFlagDescription: "Zelený list, v žerďové části žlutá berla závitem k žerďovému okraji, ve střední a vlající části bílý kráčející kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kráčející kohout s červenou zbrojí podložený zlatou berlou."
    },
    {
        obec: "Loučka",
        kod: 544418,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75644,
        latitude: 49.438791,
        longitude: 17.832764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Loucka_VS_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým karé se dvěma červenými rovnoramennými trojúhelníky vycházejícími z dolního okraje karé a s vrcholy na horním okraji. Pod karé přivrácená radlice a krojidlo hroty dolů, oboje žluté. Ve střední části žlutá berla závitem k vlajícímu okraji. Ve vlající části kráčející ovce převýšená korunou, obojí bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná čtvrť se dvěma červenými kužely. Uprostřed štítu zlatá berla závitem doleva provázená vpravo dole radlicí a krojidlem, obojí zlaté a odvrácené, vlevo stříbrná kráčející ovce převýšená korunou, obojí stříbrné."
    },
    {
        obec: "Loučka",
        kod: 585432,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76325,
        latitude: 49.170133,
        longitude: 17.876186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/CoA_Lou%C4%8Dka.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený klín s vrcholem ve druhé třetině délky listu a tři vodorovné pruhy, černý, bílý a červený, v poměru 1:2:1. V klínu bílý leknínový trojlist, v bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zeleným do poloviny zkráceným klínem se stříbrným leknínovým trojlistem rozložená křídla pravé černé a levé červené a mezi nimi červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Loučky",
        kod: 573400,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.617659,
        longitude: 15.218593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Lou%C4%8Dky_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list s červenou, žlutě lemovanou krokví, vycházející z dolního okraje listu s vrcholem v první osmině šířky listu. Poměr lemů, krokve a zeleného pole na dolním okraji listu je 1 : 3 : 1 : 15 : 1 : 3 : 1. Krokev provázena nahoře dvěma bílými polovičními liliemi na stoncích se dvěma listy, dole žlutou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě červená, zlatě lemovaná snížená krokev, nahoře provázena dvěma stříbrnými polovičními liliemi na stoncích se dvěma listy, dole zlatou hvězdou."
    },
    {
        obec: "Loučná nad Desnou",
        kod: 540226,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78811,
        latitude: 50.07166,
        longitude: 17.091064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Loucna_nad_Desnou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a modrý v poměru 2:1:1. V žerďové části dvě žluté zkřížené motyky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít, v horním poli ze stříbrných oblaků v rozích vynikají dvě stříbrné tisknoucí se ruce se zlatými rukávy, v dolním poli v zeleném trojvrší dvě zlaté zkřížené motyky."
    },
    {
        obec: "Loučná pod Klínovcem",
        kod: 546518,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43191,
        latitude: 50.420711,
        longitude: 12.986674,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, a zelený vodorovný pruh na dolním okraji listu, široký šestinu šířky listu. V červeném poli bílý dvouocasý lev se žlutou zbrojí držící v tlapách valoun stříbrné rudy. V bílém poli červený vodorovný pruh široký šestinu šířky listu, nad ním červená lilie, pod ním zkřížená šedá hornická kladívka na hnědých topůrkách. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Loučovice",
        kod: 545601,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38276,
        latitude: 48.620292,
        longitude: 14.257565,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Lou%C4%8Dovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý, v poměru 3 : 1. V bílém pruhu zkřížené, dole vzhůru červeně hořící, černé louče. Mezi nimi nahoře červená růže se žlutým semeníkem. Modrý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou vlnitou patou zkřížené, dole vzhůru červeně hořící, černé louče. Mezi nimi nahoře červená růže se zlatým semeníkem."
    },
    {
        obec: "Louka",
        kod: 553875,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.529269,
        longitude: 16.43115
    },
    {
        obec: "Louka u Litvínova",
        kod: 567272,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43533,
        latitude: 50.587967,
        longitude: 13.636592,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Louka_u_Litvinova_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a modrý v poměru 1 : 1 : 4. V modrém pruhu dva žluté vlající klíny, šikmý a kosmý, a vlající bílý klín. Všechny s vrcholem na středu vlajícího okraje bílého pruhu. Základny klínů dělí vlající okraj listu v poměru 2 : 1 : 2 : 1 : 2. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "V modro - zeleně sníženě děleném štítě nahoře šest vějířovitě vyrůstajících zlatých obilných klasů, dva vnitřní s listem, vše přeloženo vyrůstající přirozenou lilií, dole sedm (4, 3) stříbrných květů se zlatými středy."
    },
    {
        obec: "Loukov",
        kod: 570770,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29411,
        latitude: 50.561381,
        longitude: 15.035739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Loukov_CoA_MB_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený, v poměru 7:12. V zeleném pruhu hermelínový trojlístek, v červeném pruhu bílá krokev vycházející ze třetí a osmé desetiny dolního okraje a s vrcholem na středu horního okraje pruhu, držená dvěma žlutými lvími tlapami s modrou zbrojí do oblouku vynikajícími ze zeleného pruhu. Mezi rameny krokve žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně zvýšeně děleném štítě nahoře hermelínový trojlístek, dole stříbrná krokev držená dvěma shora do oblouku vynikajícími zlatými lvími tlapami s modrou zbrojí, pod ní zlatá lilie."
    },
    {
        obec: "Loukov",
        kod: 588709,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76875,
        latitude: 49.421886,
        longitude: 17.721131,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, modrý a bílý v poměru 1:2:1. V modrém pruhu žluté slunce s tváří. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté slunce s tváří podložené v koutech třemi stříbrnými javorovými listy."
    },
    {
        obec: "Louka",
        kod: 586331,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69676,
        latitude: 48.915266,
        longitude: 17.489382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Louka_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeným kosmým pruhem širokým polovinu šířky listu. V pruhu věž s cimbuřím a prolomeným, prázdným oknem, kosmo provázená dvěma liliemi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě červené kosmé břevno, v něm prolomená prázdná věž s cimbuřím, provázená dvěma liliemi, vše zlaté."
    },
    {
        obec: "Loukovec",
        kod: 536261,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29411,
        latitude: 50.562014,
        longitude: 15.015815,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Loukovec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý zubatý s červeným maltézským křížem a zvlněný modrý, v poměru 7 : 2 : 1. Bílý pruh má tři obdélníkové zuby vysoké desetinu šířky listu a čtyři mezery. Z prostředního zubu do zeleného pruhu vyrůstá žlutá lípa. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá z modré vlnité paty stříbrná hradba se třemi stínkami a červeným maltézským křížem. Z prostřední stínky vyrůstá zlatá lípa."
    },
    {
        obec: "Loukovice",
        kod: 591092,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.137327,
        longitude: 15.793465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Loukovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s vodorovným středovým bílým vlnitým pruhem širokým devítinu šířky listu. Z pruhu vyrůstá žlutý lev s červenou zbrojí. Pod ním v dolní části listu kosmo radlice hrotem a ostřím k dolnímu okraji šikmo přeložená loveckou trubkou bez kování a závěsu, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě z vlnitého stříbrného břevna nahoře vyrůstající lev s červenou zbrojí, dole kosmo postavená doleva obrácená radlice, šikmo přeložená loveckou trubkou bez kování a závěsu, vše zlaté."
    },
    {
        obec: "Louňová",
        kod: 558010,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.562286,
        longitude: 13.608016,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Lou%C5%88ov%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým černým pruhem širokým čtvrtinu šířky listu na červené žerďové a bílé vlající pole. V červeném poli bílá lilie, v bílém černá hornická kladívka s hnědými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně černým kosmým břevnem dělený štít. Nahoře černá hornická kladívka s dřevěnými topůrky přirozené barvy, dole stříbrná lilie."
    },
    {
        obec: "Louňovice",
        kod: 538451,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25162,
        latitude: 49.982366,
        longitude: 14.761849,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Shield_of_Lou%C5%88ovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3 : 1 : 2. V horním modrém pruhu žluté kruhové pole přeložené černým kosmo letícím dravým ptákem, v dolním modrém pruhu žlutý kapr. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno provázené nahoře zlatým kotoučem přeloženým kosmo letícím černým dravým ptákem, dole doleva plovoucím zlatým kaprem."
    },
    {
        obec: "Louňovice pod Blaníkem",
        kod: 530107,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25706,
        latitude: 49.637336,
        longitude: 14.846795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Lou%C5%88ovice_pod_Blan%C3%ADkem_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červeno-bílo-červeně v poměru 3 : 2 : 3 dělený a modrý, v poměru 1 : 3. V modrém pruhu zelená lípa s hnědým vykořeněným kmenem, před ním černý kanec ve skoku. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Louny",
        kod: 565971,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.354081,
        longitude: 13.803455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Louny_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. Uprostřed modrého pruhu žlutá šesticípá hvězda a žlutý půlměsíc s cípy k žerdi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Loužnice",
        kod: 563684,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.679747,
        longitude: 15.2678
    },
    {
        obec: "Lovčice",
        kod: 586340,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69639,
        latitude: 49.063682,
        longitude: 17.05439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Lovcice_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1:4:1. V zeleném pruhu bílá lovecká kuše se žlutou povolenou tětivou a červenou střelou, provázená dole vinným hroznem a žaludem, oboje žluté a se stopkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vztyčená stříbrná lovecká kuše se zlatou povolenou tětivou a červenou střelou, provázená vpravo vinným hroznem a vlevo žaludem, obojí zlaté a se stopkou."
    },
    {
        obec: "Lovčice",
        kod: 570320,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50361,
        latitude: 50.165301,
        longitude: 15.38474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/CoA_of_Lov%C4%8Dice_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29.svg",
        coatOfArmsFlagDescription: "List šikmo dělený bílým pruhem vycházejícím z šesté šestiny žerďového okraje do šesté devítiny horního okraje listu na zelené žerďové pole se žlutou loveckou trubkou s kováním, šňůrou a nátrubkem k žerdi a modré vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně stříbrným zúženým břevnem děleném štítě nahoře vyrůstá zvonice s obloukovým oknem, stanovou střechou a makovicí s křížkem, dole lovecká trubka s kováním a šňůrou, nátrubkem doprava, vše zlaté."
    },
    {
        obec: "Lovčičky",
        kod: 593265,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68354,
        latitude: 49.068009,
        longitude: 16.850309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Lov%C4%8Di%C4%8Dky_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, černý a zelený, v poměru 9:2:9. V žerďové polovině žlutého pruhu dva modré vinné hrozny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trojvrší s položeným zlatým šípem dvě zelené hlavy vinné révy čtyřikrát obtočené kolem černého kolíku se sedmi modrými hrozny. Nad trojvrším dva zkřížené stříbrné vinařské nože - kosíře na černých rukojetích."
    },
    {
        obec: "Lovčovice",
        kod: 544957,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 48.981577,
        longitude: 15.53442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Lov%C4%8Dovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, žlutý s modrou věží s kvádrovaným soklem a cimbuřím, s branou a obloukovým oknem, obojí černé, modrý se žlutou lví hlavou s krkem a žlutý, v poměru 1 : 2 : 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě srdce, z něhož vyniká šíp podložený lukem, vše opačných barev. V pravé polovině srdce modrá věž s kvádrovaným soklem a cimbuřím, s branou a obloukovým oknem, obojí černé. V levé polovině srdce zlatá lví hlava s krkem."
    },
    {
        obec: "Lovečkovice",
        kod: 565211,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.621676,
        longitude: 14.263257,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Coat_of_arms_of_Love%C4%8Dkovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Uprostřed listu železniční kolo převýšené do oblouku třemi šestilistými květy bledule, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě železniční kolo převýšené do oblouku třemi šestilistými květy bledule, vše opačných barev."
    },
    {
        obec: "Lovosice",
        kod: 565229,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.515143,
        longitude: 14.051142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Znak_Lovosic.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký pět dvanáctin délky listu a dva vodorovné pruhy, žlutý a modrý. Ze žlutě propleteně mřežované dolní poloviny žerďového pruhu vyrůstá žlutý lev s červeným jazykem a bílými zuby. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "V modrém štítě hradba s prázdnou branou se dvěma vynikajícími věžemi s cimbuřím, vše zlaté a kvádrované. Věže mají tři (2, 1) černá okna, dolní oblouková, a červené valbové střechy se zlatými makovicemi. V bráně vyrůstá ze zlaté kosmo propletené mříže zlatý lev s červenou zbrojí a jazykem."
    },
    {
        obec: "Loza",
        kod: 566446,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.893909,
        longitude: 13.290472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Loza_-_znak.jpg",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít s modrou patou se stříbrným břevnem. Vpravo zlatá oblouková koruna završená křížkem, vlevo volná zelená lípa."
    },
    {
        obec: "Lozice",
        kod: 571750,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53854,
        latitude: 49.916576,
        longitude: 16.023891,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Lozice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 5:1. Z horního okraje vychází bílý klín s vrcholem na horním okraji modrého pruhu. V klínu vztyčený zelený vinný list se dvěma úponky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na modré patě stříbrný kvádrovaný most se třemi oblouky na dvou pilířích, nad ním zvýšený stříbrný klín, v něm vztyčený zelený vinný list se dvěma úponky."
    },
    {
        obec: "Lštění",
        kod: 532193,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25722,
        latitude: 49.864385,
        longitude: 14.71437,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/CoA_of_L%C5%A1t%C4%9Bn%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a červený a žlutý vodorovný pilovitý pruh u dolního okraje listu široký třetinu šířky listu. Žlutý pruh má jedenáct zubů s vrcholy dosahujícími do poloviny šířky listu. V červeném pruhu bílá zavinutá střela hrotem k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně polceném štítě vpravo vyniká půl černé plamenné orlice s celou hlavou a s červenou zbrojí, vlevo stříbrná zavinutá střela. V patě zlatá palisádová hradba z jedenácti kůlů."
    },
    {
        obec: "Lubě",
        kod: 581992,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.396274,
        longitude: 16.526068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Lub%C4%9B_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a zelený, v poměru 7 : 1 : 2. V žerďové polovině bílého pruhu vykračující černý kohout s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě stříbrno-zeleně šikmo vlnitě dělený štít, nahoře nad šikmým modrým vlnitým břevnem vykračující černý kohout s červenou zbrojí, dole stříbrný lub."
    },
    {
        obec: "Lubenec",
        kod: 566438,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43983,
        latitude: 50.132182,
        longitude: 13.313301,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Lubenec_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové části bílého pruhu černá orlice se žlutou zbrojí s křídly přeloženými červenými věžemi dole kvádrovanými, s cimbuřím, černým oknem a zavřenou bránou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lubná",
        kod: 578355,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56963,
        latitude: 49.774189,
        longitude: 16.223798,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Lubn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený a bílý zvlněný se dvěma vrcholy a jednou prohlubní, posázený černými šindeli, v poměru 4:1. Uprostřed zeleného pole žluté kruhové síto pod třemi bílými liliemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou posetou černými šindeli zlaté kruhové síto provázené po stranách dvěma svazky šesti zlatých pruhů a v hlavě třemi stříbrnými liliemi."
    },
    {
        obec: "Luběnice",
        kod: 552216,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78346,
        latitude: 49.576275,
        longitude: 17.120578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Lubenice_znak.jpeg",
        coatOfArmsFlagDescription: "Střídavě od žerdi modro-červeně kosmo a šikmo čtvrcený list s kosmo položeným bílým mečem hrotem k žerdi se žlutou záštitou a jílcem, šikmo přeloženým žlutým klíčem zuby k vlajícímu okraji. V horní části mezi mečem a klíčem bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně kosmo čtvrceném štítě kosmo vztyčený stříbrný meč se zlatou záštitou a jílcem, šikmo přeložený vztyčeným zlatým klíčem, nad nimi stříbrná lilie."
    },
    {
        obec: "Lubná",
        kod: 542032,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27036,
        latitude: 50.07703,
        longitude: 13.700847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Lubna_znak.gif",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu posetý červenými kříži vzájemně se dotýkajícími a tři vodorovné pruhy: modrý, žlutý a modrý. Šířka ramen křížů je jedna pětina šířky žerďového pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou posetou dotýkajícími se červenými křížky vyrůstají ze zeleného návrší se zlatými zkříženými hornickými kladívky tři zlaté obilné klasy."
    },
    {
        obec: "Lubná",
        kod: 588717,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.220846,
        longitude: 17.398055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Lubna_%28Kromeriz%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený v poměru 3:1. Ze zeleného pruhu vyrůstá černý dvouocasý lev se žlutou korunou s červeným jazykem a drápy držící červenou vztyčenou kuši se žlutou tětivou a napínacím třmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě, ze zeleného trojvrší vyrůstá černý zlatě korunovaný dvouocasý lev s červenou zbrojí držící červenou vztyčenou kuši se zlatou tětivou a napínacím třmenem."
    },
    {
        obec: "Lubné",
        kod: 596078,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.354268,
        longitude: 16.275314,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Coat_of_arms_of_Lubn%C3%A9.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed převrácené žluté, jetelovitě zakončené perizonium, převýšené dvěma odkloněnými žlutými horními polovinami lilií. Dole převrácená bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě převrácené zlaté, jetelovitě zakončené perizonium, převýšené dvěma odkloněnými zlatými horními polovinami lilií. Dole převrácená stříbrná lilie."
    },
    {
        obec: "Luboměř pod Strážnou",
        kod: 500151,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75362,
        latitude: 49.689117,
        longitude: 17.663752
    },
    {
        obec: "Lubnice",
        kod: 594385,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.939563,
        longitude: 15.613963
    },
    {
        obec: "Lubník",
        kod: 580619,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.888599,
        longitude: 16.661647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Lubn%C3%ADk.svg",
        coatOfArmsFlagDescription: "Červený list s modrým žerďovým klínem s vrcholem na vlajícím okraji a dvěma bílými žerďovými klíny se základnami širokými čtvrtinu šířky listu v polovině délky listu a s vrcholy na vlajícím okraji. V modrém klínu bílý meč se žlutým jílcem, hrotem dolů, prostrčený vztyčeným žlutým gotickým dvojklíčem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě v modrém hrotu provázeném dvěma stříbrnými kužely postavený stříbrný meč se zlatým jílcem prostrčený vztyčeným zlatým gotickým dvojklíčem."
    },
    {
        obec: "Luboměř",
        kod: 599646,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.688754,
        longitude: 17.706584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Lubom%C4%9B%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem ze znaku obce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté slunce."
    },
    {
        obec: "Luby",
        kod: 554634,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35137,
        latitude: 50.252592,
        longitude: 12.406065,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Luby_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý s dvouocasými korunovanými lvy opačných barev se žlutou zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lučany nad Nisou",
        kod: 563692,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46871,
        latitude: 50.741456,
        longitude: 15.220572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Lu%C4%8Dany_nad_Nisou_CoA.jpg"
    },
    {
        obec: "Lučice",
        kod: 569062,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58235,
        latitude: 49.662406,
        longitude: 15.496764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Znak_obce_Lu%C4%8Dice.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 7:2:7. V horním rohu žlutý luční květ, v dolním rohu bílý latinský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři zlaté luční květy na stoncích, krajní odkloněné, dole přeložené položeným stříbrným srpem se zlatou rukojetí ostřím nahoru, provázeným vlevo stříbrným latinským křížem."
    },
    {
        obec: "Lučina",
        kod: 598399,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73939,
        latitude: 49.716011,
        longitude: 18.449769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Lucina_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý, v poměru 3:2. V červeném pruhu dva žluté pětilisté luční květy s modrými středy. Modrý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zvýšeně vlnitě dělený štít, v horním červeném poli dva zlaté pětilisté luční květy s modrými středy na zlatých stoncích, dolní pole třikrát vlnitě modro-zlatě děleno."
    },
    {
        obec: "Ludgeřovice",
        kod: 507971,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74714,
        latitude: 49.89052,
        longitude: 18.24019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Ludge%C5%99ovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list praporu opakuje znamení ze znaku (znak je převeden do listu praporu). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná hlavice berly."
    },
    {
        obec: "Ludíkov",
        kod: 531006,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.455493,
        longitude: 16.731887,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Lud%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-žlutě kosmo a šikmo čtvrcený list. V červených klínech šikmé bílé pruhy, první vycházející z páté osminy žerďového a druhý ze třetí osminy vlajícího okraje listu, rovnoběžné s úhlopříčkou listu. Uprostřed černý kůň ve skoku s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě kosmo čtvrceném štítě černý kůň ve skoku s červeným jazykem, držící stříbrný procesní patriarší kříž a provázený vlevo nahoře dvěma odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi."
    },
    {
        obec: "Ludkovice",
        kod: 585441,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76341,
        latitude: 49.114836,
        longitude: 17.72672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/CoA_of_Ludkovice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým srdcem se třemi červenými vlčími zuby. Pod srdcem bílý tlapatý kříž provázený nahoře dvěma osmicípými žlutými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné srdce se třemi červenými vlčími zuby, pod ním stříbrný tlapatý kříž nahoře provázený dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Ludmírov",
        kod: 589705,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79855,
        latitude: 49.641418,
        longitude: 16.87332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Ludm%C3%ADrov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a černý, v poměru 1 : 1 : 4 : 1 : 1. V modrém pruhu pět (3, 2) žlutých lipových listů, horní svěšené, dolní vztyčené a ze třetiny vsunuté mezi horní. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený a dole polcený štít. V horním modrém poli pět (3, 2) zlatých lipových listů, horní svěšené, spodní vztyčené. V pravém dolním zeleném poli stříbrná širočina na zlatém topůrku, v levém stříbrném poli dvě černá břevna."
    },
    {
        obec: "Ludslavice",
        kod: 588725,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76852,
        latitude: 49.299616,
        longitude: 17.540777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Coat_of_arms_of_Ludslavice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - žlutý a zelený. Na středu černý kohout s červeným hřebínkem, lalokem a zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně děleném štítě černý kohout s červeným hřebínkem, lalokem a zbrojí."
    },
    {
        obec: "Ludvíkov",
        kod: 551996,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79326,
        latitude: 50.107531,
        longitude: 17.342351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Ludv%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a červený, v poměru 1:3:1. Ve žlutém pruhu červené kleště podložené dvěma černými zkříženými sekerami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červené kleště podložené dvěma zkříženými černými sekerami."
    },
    {
        obec: "Ludvíkovice",
        kod: 546496,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40713,
        latitude: 50.794169,
        longitude: 14.256524,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Ludv%C3%ADkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený, bílý, modrý, bílý a modrý, v poměru 10 : 6 : 1 : 1 : 1 : 1. V žerďové polovině bílého pruhu vztyčená červená větev borovice se třemi větvičkami zakončenými zelenými svazky jehlic, na krajních větvičkách po jedné svěšené červené šišce. Pod ní v zeleném pruhu dvě žluté lilie vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vrch se dvěma zlatými liliemi vedle sebe nad třikrát vlnitě stříbrno-modře dělenou vlnitou patou. Z vrchu vyrůstá červená větev borovice se třemi větvičkami, každá zakončená zeleným svazkem jehlic, na krajních větvičkách po jedné svěšené červené šišce."
    },
    {
        obec: "Luka",
        kod: 514161,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.51251,
        longitude: 14.664183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Luka_%28CL%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, pilovitý červený se třemi zuby sahajícími do první dvanáctiny délky listu a zelený, v poměru 2 : 5 : 5. Na styku červeného a zeleného pruhu vyrůstají ze žlutého váčku, převázaného bílou šňůrou, dvě odkloněné bílé přirozené lilie se žlutými tyčinkami na žlutých stoncích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě pod stříbrnou zvýšenou pilovitou hlavou vyrůstají ze zlatého váčku, převázaného stříbrnou šňůrou, dvě odkloněné stříbrné přirozené lilie se zlatými tyčinkami na zlatých stoncích."
    },
    {
        obec: "Luhačovice",
        kod: 585459,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76326,
        latitude: 49.099922,
        longitude: 17.757583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Luha%C4%8Dovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - modrý, bílý a modrý v poměru 1:3:1. Uprostřed bílého pruhu je jabloň z městského znaku vysoká šest sedmin šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku jabloň s plody přirozených barev, provázená vpravo modrým krojidlem a vlevo modrou radlicí."
    },
    {
        obec: "Luká",
        kod: 503622,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78324,
        latitude: 49.649243,
        longitude: 16.947383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Luka_znak.jpeg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílý poutník držící v pravici zahnutou hůl, v levici džbán, provázený nad levým ramenem šesticípou hvězdou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný poutník držící v pravici zahnutou hůl, v levici džbán, provázený vlevo nahoře hvězdou, vše zlaté."
    },
    {
        obec: "Luka nad Jihlavou",
        kod: 587478,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58822,
        latitude: 49.374132,
        longitude: 15.702039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Luka_nad_Jihlavou_Znak.jpg",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list, uprostřed bílý jeřáb se žlutou zbrojí držící v pravém pařátu žlutý kámen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě na zlatém návrší stříbrný jeřáb se zlatou zbrojí, držící v pravém pařátu zlatý kámen."
    },
    {
        obec: "Lukavec",
        kod: 548332,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39426,
        latitude: 49.565511,
        longitude: 14.990517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Lukavec_PE_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými hrádky, každý se dvěma věžemi, prolomenou branou a cimbuřím. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě s volnou stříbrnou čtvrtí dva stříbrné hrádky, každý se dvěma věžemi, prolomenou branou a cimbuřím."
    },
    {
        obec: "Lukavec",
        kod: 565237,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.501745,
        longitude: 14.085804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Lukavec_CoA_LT_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V obou odvrácený jednorožec se žlutou zbrojí, mezi nimi polcená růže se žlutým semeníkem a zelenými kališními lístky, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě dva odvrácení jednorožci se zlatou zbrojí pod třemi růžemi vedle sebe, se zlatými semeníky a zelenými kališními lístky, vše opačných barev."
    },
    {
        obec: "Lukavice",
        kod: 576492,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51603,
        latitude: 50.200324,
        longitude: 16.293747,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Lukavice_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený bílý pruhem vycházejícím z první šestiny dolního okraje listu do čtvrté šestiny horního okraje listu na modré žerďové pole a zelené vlající pole. V modrém poli bílá šesticípá hvězda, v zeleném poli tři obilné klasy, krajní odkloněné s listem, provázené dole u bílého pruhu kosmo položenou loveckou trubkou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod šikmým stříbrným vlnitým břevnem oddělenou modrou hlavou se stříbrnou hvězdou vpravo kosmo položená lovecká trubka, vlevo tři obilné klasy, krajní odkloněné s listem, vše zlaté."
    },
    {
        obec: "Lukavec u Hořic",
        kod: 573141,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.395819,
        longitude: 15.616987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Lukavec_u_Ho%C5%99ic_znak.jpg",
        coatOfArmsFlagDescription: "Žluto-červeně jedním stupněm dělený list, na žerďovém okraji v poměru 2:1, na vlajícím 1:2. Horní roh listu tvoří modrý rovnoramenný trojúhelník s rameny rovnými polovině šířky listu, dolní cíp tvoří stejný bílý trojúhelník. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě levý červený stupeň, nahoře modrá lovecká trubka s řemením, nátrubkem doprava, ve stupni vlevo podkovovitě stočený stříbrný úhoř."
    },
    {
        obec: "Lukavice",
        kod: 540234,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.823003,
        longitude: 16.920503,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Luznak.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu se třemi bílými trojúhelníky s vrcholy na žerďovém okraji a zelené pole s modrým zvlněným pruhem na dolním okraji, na kterém pluje bílá labuť s červeným zobákem a zdviženými křídly, modrý pruh je široký jednu desetinu délky listu a má tři vrcholy a čtyři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými kužely stříbrná labuť s červeným zobákem a zdviženými křídly, plující na vlnité, modro-stříbrně vlnitě dělené patě štítu."
    },
    {
        obec: "Lukavice",
        kod: 571768,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.888935,
        longitude: 15.839045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Lukavice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, vlnitý bílý, vlnitý červený a zvlněný bílý, v poměru 5:1:1:1. Dolní pruhy mají po třech vrcholech a dvou prohlubních, nad první prohlubní v žerďové polovině modrého pruhu bílý mořský pes s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě nahoře stříbrný mořský pes s červeným jazykem, dole červené vlnité břevno."
    },
    {
        obec: "Lukavice",
        kod: 580627,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56151,
        latitude: 50.060386,
        longitude: 16.482177,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Lukavice.svg",
        coatOfArmsFlagDescription: "Zelený list s kosmo a šikmo přeloženými bílými srpy se žlutými rukojeťmi, pod nimi černá lovecká trubka se žlutým závěsem a náústkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo a šikmo přeložené stříbrné srpy se zlatými rukojeťmi, pod nimi černá lovecká trubka se zlatým závěsem a náústkem."
    },
    {
        obec: "Lukov",
        kod: 594393,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.861685,
        longitude: 15.910794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Lukov_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, červeno-bíle kosmo dělený a modrý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrad tvořený třemi věžemi, krajní menší se třemi (2,1) černými okny a cimbuřím, prostřední vyšší se zavřenými jednokřídlými vraty, třemi (2,1) černými okny a červenou kuželovou střechou zakončenou zlatou makovicí. V rozích štítu dva stříbrno-červeně kosmo dělené štítky."
    },
    {
        obec: "Lukov",
        kod: 550566,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67602,
        latitude: 49.073601,
        longitude: 15.824336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Lukov_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s vlajícím dolním modrým trojúhelníkem. V červeném poli bílý beránek se svatozáří, přidržující šikmo procesní kříž, obojí žluté. V modrém poli vodorovně žlutá kosa bez kosiště, ostřím k hornímu a hrotem k žerďovému okraji listu, provázená nahoře a dole čelně obrácenými bílými radlicemi hroty k sobě. V horním cípu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě nahoře stříbrný beránek se svatozáří, přidržující šikmo procesní kříž, obojí zlaté. Dole položená zlatá kosa bez kosiště ostřím nahoru, provázená nahoře a dole čelně obrácenými stříbrnými radlicemi hroty k sobě. Vlevo nahoře stříbrná lilie."
    },
    {
        obec: "Lukov",
        kod: 546909,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41804,
        latitude: 50.528228,
        longitude: 13.885519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Lukov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V žerďové polovině listu vodorovně bílý luk s napjatou černou tětivou a žlutým šípem s červeným opeřením hrotem k hornímu okraji, provázeným v bílém pruhu zelenými vavřínovými ratolestmi do oblouku, dole převázanými červenou šňůrou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě stříbrný luk s napjatou černou tětivou a se vztyčeným zlatým šípem s červeným opeřením, provázeným zelenými vavřínovými ratolestmi do oblouku, dole převázanými červenou šňůrou."
    },
    {
        obec: "Lukov",
        kod: 585467,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76317,
        latitude: 49.290372,
        longitude: 17.729695,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Lukov_ZL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, modrý, žlutý, černý, bílý, černý, bílý, černý, žlutý a modrý, v poměru 2:1:1:1:1:1:1:1:2. Uprostřed kosmo postavené, modrých pruhů se dotýkající modré čtvercové pole se žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrno-černě čtvrcené patě zlatá kvádrovaná hradba se dvěma věžemi s cimbuřím a černým oknem. Mezi věžemi stříbrná lidská část kentaura se zlatým napjatým lukem a šípem. V prázdné bráně zlatá osmicípá hvězda."
    },
    {
        obec: "Luková",
        kod: 580635,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56123,
        latitude: 49.875582,
        longitude: 16.606774,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Lukov%C3%A1.svg",
        coatOfArmsFlagDescription: "Zelený list s postaveným žlutým roštem se šesti prázdnými poli, v každém bílý květ se žlutým středem. Okem držadla roštu je provlečen do oblouku bílý drak se žlutými zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě postavený zlatý rošt s šesti prázdnými poli, v každém stříbrný květ se zlatým středem. Okem držadla roštu provlečen do oblouku stříbrný drak se zlatými zuby."
    },
    {
        obec: "Lukovany",
        kod: 583324,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.162573,
        longitude: 16.297954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Lukovany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 7:4. Do bílého pruhu vyrůstá černý lev s červenou zbrojí a jazykem. V zeleném pruhu knížecí čepice zdobená jablkem s křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře vyrůstající černý lev s červenou zbrojí a jazykem, dole knížecí čepice zdobená jablkem s křížkem."
    },
    {
        obec: "Lukoveček",
        kod: 557145,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76316,
        latitude: 49.30359,
        longitude: 17.668809,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Lukove%C4%8Dek_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu na volném zeleném trávníku dva odkloněné zelené listnaté stromy s hnědými kmeny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě s modrou hlavou a patou na volném zeleném trávníku dva odkloněné listnaté stromy přirozené barvy."
    },
    {
        obec: "Luleč",
        kod: 593273,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68303,
        latitude: 49.253592,
        longitude: 16.928755,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Coa_Czech_Town_Lule%C4%8D.svg",
        coatOfArmsFlagDescription: "Šikmo dělený modrobílý list. V modrém poli žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné uťaté zbrojné rámě s mečem, v pravém horním rohu zlatá lilie."
    },
    {
        obec: "Lupenice",
        kod: 576506,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.132992,
        longitude: 16.273884,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Lupenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý a zvlněný zelený, v poměru 5 : 1 : 2. Vlnitý pruh má pět vrcholů a čtyři prohlubně, z druhého vrcholu vyrůstá lipová větévka se třemi listy a třemi listeny s plody, vše bílé a převýšené žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít se zelenou patou oddělenou stříbrným vlnitým břevnem, z něhož vyrůstá lipová větévka se třemi listy a třemi listeny s plody, vše stříbrné a převýšené zlatou korunou."
    },
    {
        obec: "Luštěnice",
        kod: 536270,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29442,
        latitude: 50.323059,
        longitude: 14.93678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Lu%C5%A1t%C4%9Bnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy, červený, bílý a zelený. V horní části modrého pruhu žlutá koruna, v bílém pruhu modrý meč hrotem k vlajícímu okraji. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě pod modrou hlavou se zlatou korunou stříbrný hrot, v něm vztyčený modrý meč."
    },
    {
        obec: "Lutín",
        kod: 503657,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78349,
        latitude: 49.558552,
        longitude: 17.135827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Lutin_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy červený, bílý, zelený a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými kužely stříbrná kráčející husa se zlatou zbrojí provázená vlevo nahoře zlatým trojzubcem."
    },
    {
        obec: "Lutonina",
        kod: 585483,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.238638,
        longitude: 17.882915,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Znak_Lutonina.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký jednu šestinu délky listu, kosmo bílo-modře čtvrcené čtvercové pole a modrý svislý pruh na vlajícím okraji. Uprostřed listu červená růže se žlutým semeníkem a zelenými kališními lístky nesená zobáky dvou přivrácených letících hýlů a provázená nahoře korunou a dole obrácenou korunou, oběma žlutými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně kosmo čtvrceném štítě zlatý korunovaný sloup, na něm uprostřed červená růže se zlatým semeníkem a zelenými kališními lístky nesená zobáky dvou letících hýlů přirozené barvy."
    },
    {
        obec: "Lužany",
        kod: 570354,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50305,
        latitude: 50.33965,
        longitude: 15.819476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Lu%C5%BEany_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílým šikmým pruhem širokým čtvrtinu šířky listu dělený list na modré žerďové pole se žlutým mlýnským kolem a zelené vlající pole posázené pěti žlutými kruhovými poli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně šikmým stříbrným vlnitým břevnem děleném štítě nahoře mlýnské kolo, dolní pole posázené pěti kotoučky, vše zlaté."
    },
    {
        obec: "Lutopecny",
        kod: 588733,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.301479,
        longitude: 17.344491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Lutopecny_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 4 : 3 : 1. V horním zeleném pruhu knížecí čepice, v bílém černá svatojakubská mušle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný hrot s černou svatojakubskou mušlí završený knížecí čepicí, provázený vpravo radlicí a vlevo krojidlem, obojí stříbrné, vztyčené a odvrácené."
    },
    {
        obec: "Lužany",
        kod: 573159,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50706,
        latitude: 50.431154,
        longitude: 15.470396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Lu%C5%BEany_%28Ji%C4%8D%C3%ADn_District%29_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a červený, v poměru 3:2:3. Ve žlutém pruhu dvě černé lilie, v červeném žlutý latinský tlapatý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě modré břevno provázené nahoře dvěma černými liliemi, dole zlatým tlapatým latinským křížem."
    },
    {
        obec: "Lužany",
        kod: 558028,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33454,
        latitude: 49.546752,
        longitude: 13.315847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Lu%C5%BEany_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 1 : 2 : 1. V bílém pruhu černý, červeně korunovaný vykračující kohout s černo-červenými ocasními pery a červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo tři (2, 1) stříbrné husí krky se zlatou zbrojí, dolní převrácený, vlevo černý, červeně korunovaný vykračující kohout s černo-červenými ocasními pery a červenou zbrojí, pod ním modrý úhelník s červeným květem tulipánu uvnitř."
    },
    {
        obec: "Lužce",
        kod: 534404,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.98422,
        longitude: 14.196827
    },
    {
        obec: "Luže",
        kod: 571776,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53854,
        latitude: 49.893513,
        longitude: 16.028604,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Coat_of_arms_of_Lu%C5%BEe.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a žlutých, v poměru 4:1:1:1:1:1:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři zlatá břevna."
    },
    {
        obec: "Lužec nad Cidlinou",
        kod: 570362,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50362,
        latitude: 50.213644,
        longitude: 15.411967,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/CoA_of_Lu%C5%BEec_nad_Cidlinou.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě zelených a bílých, v poměru 8:1:1:1:1. Do horního zeleného pruhu vyrůstá v žerďové polovině žlutá lípa s devíti listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dole dvě stříbrná břevna, z horního vyrůstá zlatá lípa s devíti listy."
    },
    {
        obec: "Lužec nad Vltavou",
        kod: 535028,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27706,
        latitude: 50.321488,
        longitude: 14.400326,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Lu%C5%BEec_nad_Vltavou_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s kosmým žlutým pruhem širokým jednu sedminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmé břevno provázené nahoře levou rukou vzhůru šikmo prostřelenou šípem, dole ozubeným kolem, vše zlaté."
    },
    {
        obec: "Luženičky",
        kod: 553891,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.458913,
        longitude: 12.897685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Lu%C5%BEeni%C4%8Dky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. Ve střední části listu žlutý kalich provázený v žerďové a vlající části modrého pruhu bílou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře vlnitě děleném štítě zlatý kalich, provázený dole dvěma stříbrnými liliemi."
    },
    {
        obec: "Lužice",
        kod: 537322,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.031398,
        longitude: 14.205036
    },
    {
        obec: "Lužice",
        kod: 586358,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69618,
        latitude: 48.841077,
        longitude: 17.071145,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Lu%C5%BEice_CoA_HO_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený v poměru 3:1:2. V žerďovém pruhu u žerdě bílý vinařský nůž - kosíř se žlutou rukojetí hrotem nahoru a ostřím obráceným k vlajícímu okraji a ke dvěma bílým šesticípým hvězdám pod sebou. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrným položeným vinařským nožem - kosířem se zlatou rukojetí zlatý naftařský kozlík provázený vpravo dole a vlevo nahoře stříbrnou hvězdou."
    },
    {
        obec: "Lužice",
        kod: 569844,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.715844,
        longitude: 17.259668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Luzice_%28OL%29_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. V žerďové polovině kosmo krojidlo ostřím k žerdi, ve vlající polovině šikmo paprsek bran, oboje bílé. Mezi nimi v modrém pruhu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítu vpravo kosmo postavené krojidlo a vlevo šikmo paprsek bran, obojí stříbrné, převýšené zlatou osmihrotou hvězdou."
    },
    {
        obec: "Lužice",
        kod: 567281,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43524,
        latitude: 50.491802,
        longitude: 13.75425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Lu%C5%BEice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové pruhy, modro-bílý a bílo-modrý, oba dělené v poměru 2:1, každý široký jednu čtvrtinu délky listu, a dva vodorovné pruhy, modrý a bílý v poměru 2:1. V horním žerďovém modrém poli bílá, obrácená podkova, z níž nahoru vystupuje žlutá lilie. V bílém poli druhého žerďového pruhu koniklec luční vyrůstající ze zeleného návrší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo zlatá lilie nad obrácenou stříbrnou podkovou, vlevo ze zeleného návrší vyrůstající koniklec luční přirozené barvy."
    },
    {
        obec: "Lužná",
        kod: 542041,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27051,
        latitude: 50.123876,
        longitude: 13.770152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Lu%C5%BEn%C3%A1_%28Rakovn%C3%ADk_District%29_znak_2016.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, jedlový zelený a modrý, v poměru 34 : 1 : 5. Vrcholy zelených větviček dosahují do jedenácté šestnáctiny šířky listu. V červeném pruhu žlutá královská koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít červeno-zeleně sníženě dělený jedlovým řezem nad modrou vlnitou patou. V červeném poli zlatá královská koruna."
    },
    {
        obec: "Lužná",
        kod: 544434,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.239886,
        longitude: 18.019755,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, modrý, bílý a modrý v poměru 6:1:1:1. V žerďové části žlutá sekera - širočina ostřím k žerdi, ve střední a vlající části vyrůstá z horního modrého pruhu bílá husa s červeným zobákem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou patou se stříbrným břevnem vyrůstající stříbrná husa s červeným zobákem, provázená vlevo nahoře zlatou, doleva obrácenou sekerou - širočinou."
    },
    {
        obec: "Lysice",
        kod: 582018,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.451697,
        longitude: 16.537263,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, černý, žlutý a zelený, v poměru 14:1:1:4. V bílém pruhu dva přivrácení černí, žlutě korunovaní dvouocasí lvi se žlutou zbrojí, držící mezi sebou dole dvě černé zkřížené ostrve o osmi sucích a nahoře červenou podušku se čtyřmi žlutými střapci v rozích a s černým písmenem L uprostřed, na níž leží žlutý půlměsíc cípy k hornímu okraji a nad ním žlutá šesticípá hvězda. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Lužnice",
        kod: 508501,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.063083,
        longitude: 14.756284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Lu%C5%BEnice_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s kosmým modrým vlnitým pruhem širokým jednu pětinu délky listu. V žerďovém poli kosmo zelený dubový list, ve vlajícím poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé modré vlnité břevno provázené nahoře červenou růží se zlatým semeníkem a zelenými kališními lístky, dole kosmým zeleným dubovým listem."
    },
    {
        obec: "Lysá nad Labem",
        kod: 537454,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28922,
        latitude: 50.201532,
        longitude: 14.832919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Znak_m%C4%9Bsto_Lys%C3%A1_nad_Labem_color.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený a zelený v poměru 3:1. Ze zeleného pole vyrůstá bílá věž s cimbuřím o pěti čtvercových zubech a s prolomenou branou, výška věže se rovná polovině šířky listu a základna věže odpovídá pěti devítinám délky listu. Výška brány je polovinou výšky věže bez cimbuří a šířka brány je devítinou délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mackovice",
        kod: 594407,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.883712,
        longitude: 16.309445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Mackovice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým pruhem širokým jednu třetinu délky listu. V horní polovině zeleného pruhu žluto-červeně polcená růže, v bílém poli černá plamenná orlice se žlutou zbrojí, ocasem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté obrněné rameno držící na zlaté žerdi s hrotem stříbrný praporec s ocasem s položenou černou plamennou orlicí se zlatou zbrojí a perizoniem. Uprostřed štítu zlato-červeně polcená růže."
    },
    {
        obec: "Lysovice",
        kod: 593281,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.21801,
        longitude: 16.970637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Znak_Lysovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený se žlutým vztyčeným lipovým listem a zelený, v poměru 2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku zlaté prázdné žudro, v něm zlatá lípa v rozích radlice a krojidlo obojí modré a vztyčené."
    },
    {
        obec: "Mahouš",
        kod: 537381,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.047948,
        longitude: 14.256061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Mahou%C5%A1_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s modrou lilií, provázenou v horní žerďové a vlající části červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modrá lilie provázená nahoře dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Mačkov",
        kod: 536466,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.40436,
        longitude: 13.886196,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Ma%C4%8Dkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1 : 2 : 1. V červeném pruhu buvolí rohy, provázené nahoře a uprostřed pěti šesticípými hvězdami (2, 1, 1, 1). Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V červeném štítě buvolí rohy provázené nahoře dvěma a uprostřed třemi hvězdami, vše stříbrné."
    },
    {
        obec: "Machová",
        kod: 585491,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76301,
        latitude: 49.254401,
        longitude: 17.545053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Machznak.gif",
        coatOfArmsFlagDescription: "Modrý list, uprostřed polopostava archanděla Michaela v červeném šatě se žlutým kyrysem a přilbou se třemi pštrosími pery, červeným mezi bílými, držící v pravici před sebou červeno-žlutě polcený plamenný meč hrotem k vlajícímu okraji a ve vztyčené levici žlutou osmicípou hvězdu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě polopostava archanděla Michaela v červeném šatě se zlatým kyrysem a přilbou se třemi pštrosími pery, červeným mezi stříbrnými, držící v pravici vodorovně před sebou červeno-zlatě polcený plamenný meč a ve vztyčené levici zlatou osmihrotou hvězdu."
    },
    {
        obec: "Majdalena",
        kod: 546712,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37803,
        latitude: 48.960809,
        longitude: 14.86141,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Majdalena_znak.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený modrým a bílým pruhem, každý široký jednu osminu šířky listu, na žerďové bílé a vlající modré pole. V bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky, v modrém poli bílá dóza na masti. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmým modro-stříbrně vlnitě děleným vlnitým břevnem děleném štítě nahoře červená růže se zlatým semeníkem a zelenými kališními lístky, dole stříbrná dóza na masti."
    },
    {
        obec: "Machov",
        kod: 574210,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54963,
        latitude: 50.499363,
        longitude: 16.277008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Machov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou budovou s prolomenou černou branou a s červenou valbovou střechou se dvěma žlutými makovicemi. K budově přiléhají dvě bílé věže, každá s prolomenou černou branou, černým obloukovým oknem a červenou bání se žlutou makovicí s křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná budova s prolomenou černou branou a červenou valbovou střechou se dvěma zlatými makovicemi. K budově přiléhají dvě stříbrné věže, každá s prolomenou černou branou, černým obloukovým oknem a červenou bání se zlatou makovicí s křížkem."
    },
    {
        obec: "Majetín",
        kod: 503738,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.498174,
        longitude: 17.333246,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Majet%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý s modrým krojidlem hrotem k vlajícímu okraji a ostřím dolů a červený s bílou radlicí hrotem k žerdi a ostřím dolů. Červený pruh má šest obdélníkových zubů a pět stejných obdélníkových mezer. Plocha bílého a červeného pole je totožná. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z červené kvádrové hradby s cimbuřím a stříbrnou položenou radlicí červený lev se zlatou zbrojí a jazykem držící modré postavené krojidlo."
    },
    {
        obec: "Makotřasy",
        kod: 532622,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27354,
        latitude: 50.144584,
        longitude: 14.214806,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Makot%C5%99asy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý, vlnitý modrý se třemi vrcholy a dvěma prohlubněmi a zvlněný žlutý, v poměru 5 : 1 : 1 : 1. V červeném pruhu tři žluté makovice na zkřížených stoncích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít se zlatou patou. Ve štítě tři zlaté makovice na zkřížených stoncích, v patě modré vlnité břevno."
    },
    {
        obec: "Makov",
        kod: 582026,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67972,
        latitude: 49.519247,
        longitude: 16.483791
    },
    {
        obec: "Makov",
        kod: 578363,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.855103,
        longitude: 16.191233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Makov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo dělený list. V červeném poli tři bílé kosmé pruhy vycházející ze třetí, šesté a deváté jedenáctiny žerďového okraje a končící v třetí, šesté a deváté jedenáctině dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře doleva vykračující kohout přirozené barvy, dole tři kosmá stříbrná břevna."
    },
    {
        obec: "Malá Hraštice",
        kod: 540714,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.807609,
        longitude: 14.279979,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Mal%C3%A1_Hra%C5%A1tice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zeleno-bíle polcený a zubatý bílo-zeleně polcený, v poměru 1 : 3. Zubatý pruh má v každé polovině tři zuby a čtyři stejné mezery. V žerďové polovině dolního pruhu zelená lilie, ve vlající čtyřmi červenými kameny zdobené žluté říšské jablko s jetelovým křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě s polcenou cimbuřovou hlavou opačných barev, vpravo zelená lilie, vlevo čtyřmi červenými kameny zdobené zlaté říšské jablko s jetelovým křížkem."
    },
    {
        obec: "Malá Bystřice",
        kod: 544469,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75627,
        latitude: 49.409504,
        longitude: 18.04863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/CZ_Mal%C3%A1_Byst%C5%99ice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý v poměru 1:3:1. V zeleném pruhu zkřížená žlutá sekerka a motyka, provázené čtyřmi bílými čtyřcípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo položená zlatá sekera, křížem přeložená zlatou motykou, provázené čtyřmi stříbrnými čtyřhrotými hvězdami."
    },
    {
        obec: "Malá Lhota",
        kod: 582034,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.39199,
        longitude: 16.549636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Mal%C3%A1_Lhota_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s čelně postavenou černou radlicí, v ní žluté přesýpací hodiny s bílými baňkami a žlutým sypajícím se pískem. Radlice nahoře provázená dvěma vztyčenými zelenými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě čelně postavená černá radlice se zlatými přesýpacími hodinami se stříbrnými baňkami a zlatým sypajícím se pískem, provázená nahoře dvěma vztyčenými zelenými lipovými listy."
    },
    {
        obec: "Malá Losenice",
        kod: 596086,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59211,
        latitude: 49.600028,
        longitude: 15.796704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Mal%C3%A1_Losenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 3:1. V žerďové polovině zeleného pruhu žlutý běžící los provázený nad hřbetem bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou zlatý běžící los provázený vlevo nahoře stříbrnou lilií."
    },
    {
        obec: "Malá Morava",
        kod: 540331,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78833,
        latitude: 50.096366,
        longitude: 16.826768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Mal%C3%A1_Morava_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílo - červeně šachovaný (4 x 2) a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 1 : 2. V modrém pruhu nad vozovým kolem zkřížená krojidla hroty a ostřím k hornímu okraji listu, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrno - červeně šachovanou vlnitou hlavou zkřížená krojidla nad vozovým kolem, vše stříbrné."
    },
    {
        obec: "Malá Morávka",
        kod: 597601,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79336,
        latitude: 50.021157,
        longitude: 17.311666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Mal%C3%A1_Mor%C3%A1vka_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3:1. Horní modrý má ve vlající části žluté dělo s hlavní směrem k hromadě deseti (1+2+3+4) bílých dělových koulí v žerďové části. Dolní pruh tvoří dvacet čtyři čtvercových polí ve dvou vodorovných řadách, střídavě bílých a červených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně šachované zúžené břevno, nahoře zlaté dělo provázené vpravo hromadou deseti stříbrných dělových koulí, dole dva stříbrní přivrácení ptáci sedící na spodních delších pahýlech zlaté vztyčené ostrve."
    },
    {
        obec: "Malá Roudka",
        kod: 582042,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67963,
        latitude: 49.60178,
        longitude: 16.644574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Mal%C3%A1_Roudka_znak.jpg",
        coatOfArmsFlagDescription: "Zeleno - červeně čtvrcený list. Uprostřed zkřížené sudlice bez ratišť, háky k žerďovému a vlajícímu okraji listu, žlutá kosmo přes bílou, mezi hroty žlutá hlavice berly závitem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě zkřížené sudlice s hákem bez ratišť, kosmá zlatá přes šikmou stříbrnou, nahoře mezi nimi zlatá hlavice berly."
    },
    {
        obec: "Malá Skála",
        kod: 563706,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.646408,
        longitude: 15.195534
    },
    {
        obec: "Malá Štáhle",
        kod: 551902,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79501,
        latitude: 49.9515,
        longitude: 17.341559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Mal%C3%A1_%C5%A0t%C3%A1hle_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vinným hroznem na bílém stonku se dvěma listy a se svislým bílým vlnitým pruhem vycházejícím z jedenácté třináctiny horního okraje, který má k vlajícímu okraji dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným sníženým vlnitým břevnem zlatý vinný hrozen se stříbrným stonkem a dvěma listy."
    },
    {
        obec: "Malá Úpa",
        kod: 579505,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54227,
        latitude: 50.744947,
        longitude: 15.820639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Mala_Upa_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, a vodorovný modrý zvlněný pruh na dolním okraji široký jednu třetinu šířky listu. V zeleném pruhu zkřížené bílé sekery na hnědých topůrkách. V bílém pruhu hnědé průčelí dřevěného kostela s červenou bání. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou vlnitou patou stříbrný vrch s průčelím dřevěného kostela přirozené barvy s červenou bání, nad ním zkřížené stříbrné sekery na topůrkách přirozené barvy."
    },
    {
        obec: "Malá Veleň",
        kod: 562700,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.741627,
        longitude: 14.270704
    },
    {
        obec: "Malá Víska",
        kod: 533319,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.776121,
        longitude: 13.875624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Mal%C3%A1_V%C3%ADska_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý klín s vrcholem ve středu listu a šest vodorovných pruhů, červený, bílý, červený, zelený, bílý a zelený. V klínu černý, červeně hořící granát. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vyniká stříbrná skála s černým, červeně hořícím granátem provázená nahoře dvěma odkloněnými vztyčenými dubovými větvemi, každá s žaludem mezi dvěma listy, vše stříbrné."
    },
    {
        obec: "Malá Vrbka",
        kod: 586366,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69673,
        latitude: 48.869505,
        longitude: 17.459307,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Mal%C3%A1_Vrbka_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:3:1. V bílém pruhu modrý žerďový klín s vrcholem v druhé třetině délky listu. V klínu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vrba s kmenem provázeným dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Malé Březno",
        kod: 567299,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.462594,
        longitude: 13.560308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Mal%C3%A9_B%C5%99ezno_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s modrým lemem širokým jednu šestinu šířky listu. Uprostřed polovina bílého orla bez křídel se žlutou zbrojí. Nad hlavami bílý brk hrotem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře vyrůstající stříbrný orel se zlatou zbrojí, převýšený položeným stříbrným brkem, dole dvoje položené odvrácené zlaté vidle - podávky, pod nimi stříbrná hornická kladívka."
    },
    {
        obec: "Malé Březno",
        kod: 568091,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.672709,
        longitude: 14.172366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Mal%C3%A9_B%C5%99ezno_%28%C3%9Ast%C3%AD_nad_Labem_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, v poměru 11 : 5, zelený a zvlněný žlutý se dvěma vrcholy a třemi prohlubněmi. Do zeleného pruhu vyrůstá bílý dvouocasý lev se žlutou zbrojí a jazykem držící v pravé tlapě vztyčený žlutý březový list. Ve žlutém pruhu na zelené stopce tři svěšené hnědé lískové oříšky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlaté vlnité paty stříbrný dvouocasý lev se zlatou zbrojí a jazykem držící v pravé tlapě vztyčený zlatý březový list. V patě na zelené stopce tři svěšené lískové oříšky přirozené barvy."
    },
    {
        obec: "Malé Hradisko",
        kod: 589713,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79849,
        latitude: 49.493414,
        longitude: 16.87669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Mal%C3%A9_Hradisko_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu šesticípá hvězda mezi dvěma letícími přivrácenými skřivany s červenými zobáky, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě palisádovým řezem děleném štítě nahoře zlatá hvězda a zlatý letící skřivan s červeným zobákem, dole doleva položený modrý nůž na krájení šindelů."
    },
    {
        obec: "Malé Kyšice",
        kod: 532631,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.061193,
        longitude: 14.09042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Male_Kysice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý v poměru 2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou patou s černým trojvrším polovina jelena přirozené barvy se zlatou zbrojí."
    },
    {
        obec: "Malčín",
        kod: 569071,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.688715,
        longitude: 15.465269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Znak_Mal%C4%8D%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý a červený, v poměru 1 : 1 : 2. V červeném pruhu žlutý kalich. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Horní pole černo-stříbrně dělené se šikmo postaveným mečem podloženým vztyčeným klíčem, obojí opačných barev. V dolním červeném poli zlatý kalich."
    },
    {
        obec: "Malé Přítočno",
        kod: 513113,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.107798,
        longitude: 14.135713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Mal%C3%A9_P%C5%99%C3%ADto%C4%8Dno_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, vlnitý modrý, zvlněný žlutý a modrý, v poměru 1 : 1 : 1 : 6. Ve vlajícím pruhu žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře vpravo polcený štít. Vpravo modrý vlnitý kůl, vlevo zlatý zvon."
    },
    {
        obec: "Malé Svatoňovice",
        kod: 579513,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54234,
        latitude: 50.534044,
        longitude: 16.049886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Male_Svatonovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a modrý, v poměru 1:2:1. V zeleném pruhu kruh sedmi bílých třešňových květů, provázený po stranách černými hornickými kladívky s mlátky ke středu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstající vykořeněná třešeň přirozené barvy, v koruně stříbrná Panna Marie s jezulátkem obklopená dolů do oblouku sedmí stříbrnými třešňovými květy. Strom provázejí černá hornická kladívka s mlátky ke středu. V kořenech stromu sedm stékajících modrých pramenů."
    },
    {
        obec: "Malé Výkleky",
        kod: 572802,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.119436,
        longitude: 15.538618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mal%C3%A9_V%C3%BDkleky_CoA.jpg"
    },
    {
        obec: "Malé Žernoseky",
        kod: 565245,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.534078,
        longitude: 14.05484,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Coat_of_arms_of_Mal%C3%A9_%C5%BDernoseky.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed vykořeněný hnědý keř vinné révy se třemi zelenými listy a dvěma úponky po stranách, provázený v modrém pruhu dvěma žlutými mlýnskými kameny o průměru tři desetiny šířky listu s černými čtvercovými otvory. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě vykořeněný keř vinné révy přirozené barvy se třemi listy a dvěma úponky po stranách, provázený dole dvěma zlatými mlýnskými kameny s černými čtvercovými otvory."
    },
    {
        obec: "Maleč",
        kod: 569089,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58276,
        latitude: 49.772128,
        longitude: 15.676917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Male%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žluté žerďové klíny s vrcholy v první čtvrtině délky listu, červené pole s bílým mořským psem se žlutou zbrojí a dva svislé pruhy, bílý a modrý, každý široký jednu osminu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrno-modře vlnitě dělenou vlnitou patou, pod dvěma zlatými, ke středu prolomenými zvýšenými klíny stříbrný mořský pes se zlatou zbrojí."
    },
    {
        obec: "Malečov",
        kod: 568104,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40327,
        latitude: 50.632028,
        longitude: 14.119558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Male%C4%8Dov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, červený a zelený, v poměru 1 : 1 : 5 : 1. Z prostředních tří pětin horního okraje zeleného pruhu vyniká zelené trojvrší, z něhož vyrůstají dvě bílá lekna, pod nimi bílý osmihrotý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod modro-zlatě dělenou hlavou dvě stříbrná lekna vyrůstající ze zeleného trojvrší, v něm stříbrný osmihrotý kříž."
    },
    {
        obec: "Malenice",
        kod: 551384,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38706,
        latitude: 49.126512,
        longitude: 13.882929,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Malenice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným trojúhelníkovým polem s vrcholy na polovině horního a na sedmi osminách žerďového a vlajícího okraje listu. V zeleném poli bílá svatojakubská mušle. V horním rohu listu kosmo a v horním cípu šikmo vztyčená červená malina se dvěma zelenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený zelený vydutý hrot, v něm zúžené vlnité břevno, nad ním svatojakubská mušle a pod ním kapr, vše stříbrné. Klín nahoře provázejí vztyčené a odkloněné červené maliny se dvěma zelenými listy."
    },
    {
        obec: "Malenovice",
        kod: 552593,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73911,
        latitude: 49.579287,
        longitude: 18.398071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Malenovice_%28FM%29_CoA.svg",
        coatOfArmsFlagDescription: "List praporu opakuje obecní znak. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červená malina."
    },
    {
        obec: "Malešov",
        kod: 534188,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28541,
        latitude: 49.911117,
        longitude: 15.224443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Znak_obce_Male%C5%A1ov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 5 : 1. Do červeného pruhu vyniká mezi dvěma věžemi prázdná brána, vše bílé, kvádrované a s cimbuřím. Každá věž se dvěma černými okny v patře. V bráně mezi žlutými otevřenými vraty na černých závěsech bílý hmoždíř. Nad branou černé křídlo. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Malešovice",
        kod: 583332,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66465,
        latitude: 49.023694,
        longitude: 16.501392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Male%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1:2:1. V obou zelených pruzích vztyčený přivrácený bílý vinařský nůž - kosíř se žlutou rukojetí, ve žlutém pruhu červený vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vydutý, liliovitě zakončený hrot s červeným vinným hroznem, provázený dvěma přivrácenými stříbrnými vinařskými noži - kosíři se zlatými rukojeťmi."
    },
    {
        obec: "Malhostovice",
        kod: 583341,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66603,
        latitude: 49.333699,
        longitude: 16.502322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Malhostovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Ve střední části listu v červeném pruhu žlutý obilný snop a v bílém pruhu černá dolů obrácená kosa hrotem k vlajícímu okraji na červeném kosišti. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo zlatý obilný snop, vlevo černá, dolů obrácená kosa na červeném kosišti."
    },
    {
        obec: "Maletín",
        kod: 540366,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.799359,
        longitude: 16.78743,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Malet%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílá mitra se žlutým lemováním a svěšenými bílými fimbriemi se žlutým třepením, provázená na podélné ose listu dvěma žlutými liliemi. Pod mitrou dva žluté zkřížené kopáče proložené bílým vztyčeným mlátkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná mitra se zlatým lemováním a svěšenými stříbrnými fimbriemi se zlatým třepením, provázená nahoře dvěma zlatými liliemi, pod ní zkřížené zlaté kopáče položené vztyčeným stříbrným mlátkem."
    },
    {
        obec: "Malhotice",
        kod: 515329,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.490108,
        longitude: 17.764264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Malhotice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílo-červeně polcený a zelený, v poměru 1:4:3. V prostředním pruhu černý vyskakující kozel s bílým dračím křídlem a červeným jazykem, přecházející v zeleném pruhu v bílý dračí ocas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený a v horní polovině stříbrno-červené polcený štít, v ní černý vyskakující kozel se stříbrným dračím křídlem a červeným jazykem, přecházející v dolní zelené polovině ve stříbrný dračí ocas."
    },
    {
        obec: "Malíkov",
        kod: 578371,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.731136,
        longitude: 16.69618
    },
    {
        obec: "Malíkovice",
        kod: 532657,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27377,
        latitude: 50.211503,
        longitude: 13.982657,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Mal%C3%ADkovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu k vlajícímu okraji listu vodorovně zelená lipová ratolest se třemi listy a plodenstvími, v modrém žlutá osmicípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít s modrou patou. Pravé pole pětkrát zlato-černě šikmo dělené, v levém zlatém poli vztyčená zelená lipová ratolest se třemi listy a plodenstvími, v patě zlatá osmihrotá hvězda."
    },
    {
        obec: "Malinová",
        kod: 565377,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.047922,
        longitude: 13.667032,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Malinova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený, uprostřed malina opačných barev se zelenou stopkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě malina opačných barev se zelenou stopkou."
    },
    {
        obec: "Malínky",
        kod: 593290,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.159408,
        longitude: 17.160321,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Mal%C3%ADnky_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se šikmým zeleným pruhem širokým jednu šestinu délky listu. V horním rohu a dolním cípu červená malina se zelenou stopkou a kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě z černé položené ostrve se suky doleva vyrůstají dva překřížené zelené malinové stvoly, každý se třemi listy a jednou svěšenou červenou malinou po straně."
    },
    {
        obec: "Malíč",
        kod: 542491,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.543636,
        longitude: 14.082115,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Malic_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrým žerďovým pruhem širokým jednu třetinu délky listu. Na rozhraní obou polí červený klobouk s hermelínovým lemem, ze kterého vyrůstají dvě orlí křídla, bílé k žerdi a modré k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě červený klobouk s hermelínovým lemem, z něhož vyrůstají dvě orlí křídla, vpravo stříbrné a vlevo modré."
    },
    {
        obec: "Málkov",
        kod: 534218,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26701,
        latitude: 49.888313,
        longitude: 14.023652,
        coatOfArmsFlagDescription: "List tvoří bílo-zeleně dělený vypouklý žerďový pruh široký třetinu délky listu dosahující do poloviny délky listu a tři vodorovné pruhy, zelený, modrý a bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě nahoře se rozšiřující a vypouklá deska s vydutými boky opačných barev, v ní vpravo modrý trilobit, vlevo stříbrný višňový květ se zlatým středem."
    },
    {
        obec: "Málkov",
        kod: 563200,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.446278,
        longitude: 13.333493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Malkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh a tři vodorovné pruhy: modrý, bílý a modrý v poměru 1:3:1. Žerďový pruh je široký jednu čtvrtinu délky listu a vynikají z něj do poloviny bílého pruhu tři zelené jetelové trojlístky na spojených stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít. Nahoře vyrůstající modrý lev se zlatou zbrojí držící v pravé tlapě na stoncích tři zelené jetelové trojlístky. Dole vedle sebe dva stříbrné mlýnské kameny s položenými černými kypřicemi."
    },
    {
        obec: "Malonty",
        kod: 545619,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38291,
        latitude: 48.686212,
        longitude: 14.576875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Malonty_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 7 : 3. Do zeleného pruhu ve střední části vyrůstá žlutá věž s černým oknem v patře a s červenou valbovou střechou, provázená osmi (4, 4) přivrácenými vztyčenými žlutými lipovými listy pod sebou. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném vrchu s červenou růží se zlatým semeníkem a zelenými kališními lístky zlatá věž s černým oknem v patře a s červenou valbovou střechou, provázená osmi(4, 4) přivrácenými vztyčenými zlatými lipovými listy pod sebou."
    },
    {
        obec: "Malotice",
        kod: 533513,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.965299,
        longitude: 14.977728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Malotice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. V druhé a třetí třetině šířky červeného pruhu polovina bílého leknínového trojlistu provázená mezi listy žlutou osmicípou hvězdou, v první a druhé třetině šířky modrého pruhu žlutá halapartna bez ratiště, ostřím k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrná polovina leknínového trojlistu provázená zlatou osmihrotou hvězdou, vlevo zlatá, ostřím doleva obrácená halapartna bez ratiště."
    },
    {
        obec: "Malovice",
        kod: 550418,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.091285,
        longitude: 14.225863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Malovice_%28Prachatice_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s červeným lemem širokým šestinu šířky listu. V modrém poli polovina bílého koně s černými kopyty a žlutým uzděním. Lem má v rozích, cípech a uprostřed horního a dolního okraje listu bílý květ jabloně se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s červeným lemem se šesti (3, 2, 1) stříbrnými květy jabloně se zlatými středy polovina stříbrného koně ve skoku s černými kopyty a zlatým uzděním."
    },
    {
        obec: "Malšice",
        kod: 552666,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39175,
        latitude: 49.364014,
        longitude: 14.578788,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/CoA_of_Mal%C5%A1ice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě červené a bílé.V žerďovém pruhu tři osmicípé žluté hvězdy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně třikrát děleném štítě nahoře tři zlaté osmihroté hvězdy vedle sebe."
    },
    {
        obec: "Malšín",
        kod: 536296,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38273,
        latitude: 48.678126,
        longitude: 14.287135
    },
    {
        obec: "Malšovice",
        kod: 562718,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.735259,
        longitude: 14.169817,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Mal%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, vlnitý modrý s jedním vrcholem a jednou prohlubní a žlutý, v poměru 3:2:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrým vlnitým kůlem stříbrno-zlatě polcený štít, vpravo nahoře zelená hruška, vlevo červené jablko, obojí na stopce se zeleným listem."
    },
    {
        obec: "Malý Beranov",
        kod: 587486,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58603,
        latitude: 49.396595,
        longitude: 15.637796
    },
    {
        obec: "Malý Bor",
        kod: 556629,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.330621,
        longitude: 13.652357,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Mal%C3%BD_Bor_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s červenou routovou korunou o sedmi routách. Dolní okraj koruny vychází z horní třetiny žerďového okraje a končí v jedné šestině vlajícího cípu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít. Nahoře kosmo položená červená koruna, dole přirozená levá ruka po zápěstí, držící stříbrnou nádobku na masti."
    },
    {
        obec: "Malý Újezd",
        kod: 535036,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27731,
        latitude: 50.33257,
        longitude: 14.529687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Mal%C3%BD_%C3%9Ajezd_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený s bílou berlou závitem k žerďovému okraji, žlutý se vztyčeným černým roštem a červený s bílým vztyčeným jelením parohem se čtyřmi výsadami k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít polcený zlatým kůlem se vztyčeným černým roštem, provázený vpravo berlou a vlevo vztyčeným jelením parohem se čtyřmi výsadami doleva, obojí stříbrné."
    },
    {
        obec: "Manětín",
        kod: 559202,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33162,
        latitude: 49.991884,
        longitude: 13.233304,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Znak_manetin.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. V červeném bílý tlapatý kříž. Z červeného pruhu vyniká půl černé orlice s červenou zbrojí s hlavou k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mankovice",
        kod: 568589,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.637559,
        longitude: 17.880249,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Mankovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a zelené pole se třemi žlutými liliemi pod sebou u bílého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vpravo stříbrná postvená radlice, vlevo tři zlaté lilie pod sebou."
    },
    {
        obec: "Maňovice",
        kod: 578410,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.393065,
        longitude: 13.653942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Coat_of_arms_of_Ma%C5%88ovice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílo-modře šachovaný (3x7), červený a bílý, v poměru 10 : 36 : 5 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dvakrát dělený štít. Horní pole červené, prostřední stříbrno-modře ve třech řadách šachované, dolní červeno-stříbrně dělené."
    },
    {
        obec: "Mariánské Lázně",
        kod: 554642,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.964699,
        longitude: 12.701298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Mari%C3%A1nsk%C3%A9_L%C3%A1zn%C4%9B_CoA.png"
    },
    {
        obec: "Markvartice",
        kod: 587494,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.178289,
        longitude: 15.617202,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Markvartice_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsDescription: "V modro-zlatě děleném štítě nahoře dvě stříbrná rozložená křídla, dole položený červený paroh."
    },
    {
        obec: "Mariánské Radčice",
        kod: 567302,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43532,
        latitude: 50.574142,
        longitude: 13.665101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Mari%C3%A1nsk%C3%A9_Rad%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý. V bílém pruhu červené srdce hořící dokola pěti žlutými plamínky nad modrými zkříženými hornickými kladívky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrým trojvrším se zkříženými stříbrnými hornickými kladívky červené srdce hořící dokola pěti zlatými plamínky."
    },
    {
        obec: "Markvartice",
        kod: 591114,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.200958,
        longitude: 15.767422,
        coatOfArmsFlagDescription: "Červený list s bílým vodorovným oboustranně zubatým posunutým pruhem, vycházejícím z druhé šestiny žerďového a vlajícího okraje, s dvěma obdélníkovými zuby a třemi stejnými mezerami (3,2). Pod pruhem bílý ohlížející se beránek se svatozáří, držící šikmo žlutý procesní kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný kráčející ohlížející se beránek se svatozáří držící zlatý procesní kříž, nad ním stříbrné cimbuřové břevno."
    },
    {
        obec: "Markvartice",
        kod: 573167,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50742,
        latitude: 50.430868,
        longitude: 15.196175
    },
    {
        obec: "Markvartice",
        kod: 545678,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40742,
        latitude: 50.775633,
        longitude: 14.355383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Markvartice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: červený, žlutý, bílý, žlutý a červený v poměru 14:1:10:1:14. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný kráčející lev se zlatou zbrojí a jazykem, stojící na dvou štítcích vedle sebe. Pravý štítek stříbrno-černý, levý zlato-černý."
    },
    {
        obec: "Markvartovice",
        kod: 508128,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74714,
        latitude: 49.906049,
        longitude: 18.23613,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Markvartovice_CoA.png",
        coatOfArmsFlagDescription: "Na modrém listu žlutý vykračující býk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý vykračující býk."
    },
    {
        obec: "Maršov",
        kod: 583359,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66471,
        latitude: 49.28378,
        longitude: 16.359854,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Mar%C5%A1ov_CoA_CZ.png",
        coatOfArmsFlagDescription: "Zeleno - bíle šikmo dělený list. V horním rohu bílá lilie, v dolním cípu na dolním okraji listu zelené návrší s jabloní s červenými jablky a hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ve stříbrném hrotu provázeném dvěma stříbrnými liliemi zelené návrší, na něm jabloň přirozené barvy s červenými jablky."
    },
    {
        obec: "Maršov u Úpice",
        kod: 579530,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54232,
        latitude: 50.484721,
        longitude: 15.976044,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/CoA_of_Mar%C5%A1ov_u_%C3%9Apice.svg",
        coatOfArmsFlagDescription: "Zelený list se dvěma žlutými krokvemi vycházejícími ze čtvrté a šesté desetiny žerďového a vlajícího okraje, s vrcholy v první a třetí desetině šířky listu. Pod krokvemi žlutý zvon se srdcem zakončeným lipovým listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě uprostřed dvě zúžené krokve, nad nimi čtyři smrky, krajní větší, dole zvon se srdcem zakončeným lipovým listem, vše zlaté."
    },
    {
        obec: "Maršovice",
        kod: 563714,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46801,
        latitude: 50.696455,
        longitude: 15.198577,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Mar%C5%A1ovice_%28Jablonec_nad_Nisou_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s žerďovým zeleným pruhem širokým třetinu délky listu se svislým bílým oválným lustrovým ověsem (buchtlí). Na vlajícím okraji listu dva zelené klíny s vrcholy na vlajícím okraji žerďového pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad sníženou modrou patou zelený vrch se dvěma vyrůstajícími jehličnatými stromy přirozené barvy. Ve vrchu položený stříbrný oválný lustrový ověs (buchtle)."
    },
    {
        obec: "Maršovice",
        kod: 530115,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25755,
        latitude: 49.71367,
        longitude: 14.564615,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Mar%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý modrý žerďový pruh široký polovinu délky listu a dva vodorovné pruhy, červený a bílý. V modrém pruhu na volném zeleném návrší dvě bílé kvádrované hranolové věže se soklem, každá s oknem pod třemi střílnami, vše černé, s červenou valbovou střechou se žlutými makovicemi. Mezi věžemi žlutá ohnivá dělová koule. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Martinice",
        kod: 596094,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.371786,
        longitude: 16.038309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Martinice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, první tři červeno-bíle kosmo dělené, zbývající černo-bíle kosmo dělené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrný kůň ve skoku se zlatou zbrojí, hřívou a ocasem, vlevo vyniká z dělící linie polovina černého křídla se zlatým perizoniem."
    },
    {
        obec: "Martinice",
        kod: 588741,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.310408,
        longitude: 17.601188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/CoA_of_Martinice_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29.svg",
        coatOfArmsFlagDescription: "List s modrým křížem s rameny širokými jednu čtvrtinu šířky listu. Žerďová pole bílá, horní vlající pole červené, dolní vlající pole žluté. V kříži bílý meč se žlutým jílcem hrotem u vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě položený modrý latinský kříž, v něm doleva položený stříbrný meč se zlatým jílcem, vlevo nahoře dva zleva vynikající zlaté plameny, vlevo dole doleva obrácená stříbrná radlice."
    },
    {
        obec: "Martinice u Onšova",
        kod: 561347,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.579951,
        longitude: 15.115482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Martinice_u_On%C5%A1ova_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 5 : 2 : 5. V zeleném a modrém pruhu žlutá lipová koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře zúženým stříbrným vlnitým břevnem dělený štít. Nahoře i dole zlatá lipová koruna."
    },
    {
        obec: "Martinice v Krkonoších",
        kod: 573418,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51232,
        latitude: 50.580946,
        longitude: 15.533412,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Martinice_v_Krkono%C5%A1%C3%ADch_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, modrý a žlutý v poměru 2:5:2. V modrém pruhu žlutý ohlížející se kůň ve skoku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý ohlížející se kůň ve skoku."
    },
    {
        obec: "Martiněves",
        kod: 565253,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41119,
        latitude: 50.372805,
        longitude: 14.152928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Martin%C4%9Bves_%28Litom%C4%9B%C5%99ice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu pod korunou o pěti listech lilie, v červeném pruhu dvě vzájemně propletené dvojice zkřížených obilných klasů nad sebou, každý klas s odkloněným listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo pod korunou o pěti listech lilie, vlevo dvě vzájemně propletené dvojice zkřížených obilných klasů nad sebou, každý klas s odkloněným listem, vše zlaté."
    },
    {
        obec: "Martínkov",
        kod: 550388,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67544,
        latitude: 49.096759,
        longitude: 15.711505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Znak_obce_Mart%C3%ADnkov.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1:5:1. V červeném pruhu žlutá krokev vycházející z horní třetiny vnitřních stran žlutých pruhů a s vrcholem na středu dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dva zlaté přivrácené válečné cepy se zkříženými tlouky, pod nimi ze zlatého stoupajícího půlměsíce vyrůstá zelená paví kyta, na ní položená zlatá hvězda."
    },
    {
        obec: "Martínkovice",
        kod: 574228,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54973,
        latitude: 50.547626,
        longitude: 16.342079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Mart%C3%ADnkovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým středovým křížem s rameny širokými jednu osminu šířky listu, přes který je položena bílá věž s cimbuřím a s červeným středovým křížem se stejně širokými rameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s cimbuřím a červeným heroldským křížem podložená postaveným zlatým mečem, nahoře provázená dvěma postavenými zlatými šípy s červenými hroty a opeřením."
    },
    {
        obec: "Mařenice",
        kod: 561827,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47156,
        latitude: 50.807507,
        longitude: 14.678659,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Ma%C5%99enice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zeleno-žlutě dělený žerďový pruh široký čtvrtinu délky listu a dva vodorovné pruhy, žlutý a zelený. V žerďovém pruhu meč hrotem k dolnímu okraji listu, v zeleném poli žlutý, ve žlutém černý. Ve střední části listu ve žlutém pruhu zkřížené černé ostrve, pod nimi v zeleném pruhu žlutá dóza na masti. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-zlatě vpravo čtvrcený štít. Přes 1. a 3. pole postavený zlato-černě dělený meč. Ve 2. poli zkřížené černé ostrve, ve 4. poli zlatá dóza na masti."
    },
    {
        obec: "Máslojedy",
        kod: 573779,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.297029,
        longitude: 15.75893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/CoA_of_M%C3%A1slojedy.svg",
        coatOfArmsFlagDescription: "List šikmo dělený bílým pruhem vycházejícím z třetí dvanáctiny dolního okraje listu do první osminy vlajícího okraje listu na zelené žerďové a červené vlající pole. V zeleném poli žlutá lípa, v červeném šikmo bílá šavle se žlutým jílcem hrotem a ostřím k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně sníženě šikmo zúženým stříbrným břevnem dělený štít. Nahoře zlatá lípa, dole šikmo vztyčená stříbrná šavle se zlatým jílcem."
    },
    {
        obec: "Máslovice",
        kod: 538469,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25069,
        latitude: 50.208722,
        longitude: 14.37903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Maslovice_CoA_CZ.jpg",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě dřevěná máselnice přirozených barev."
    },
    {
        obec: "Masojedy",
        kod: 564702,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.026078,
        longitude: 14.777985
    },
    {
        obec: "Mastník",
        kod: 591122,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.180293,
        longitude: 15.827933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Mastn%C3%ADk_TR_CoA.jpg",
        coatOfArmsDescription: "Čtvrcený štít, v prvním stříbrném poli černé orlí křídlo se zlatým perizoniem, druhé pole zlaté, třetí černé, ve čtvrtém stříbrném poli dolů obrácená zkřížená černá hornická kladívka na topůrkách přirozené barvy."
    },
    {
        obec: "Mašovice",
        kod: 594415,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.857475,
        longitude: 15.973646,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Ma%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 5:2:5. V žerďovém pruhu osmihrotý kříž nad šesticípou hvězdou, oboje červené. Ve vlajícím pruhu červený volný rovný tlapatý kříž nad modrým půlměsícem s tváří, cípy k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý kůl provázený vpravo červeným osmihrotým křížem nad červenou hvězdou, vlevo červeným tlapatým křížem nad modrým stoupajícím půlměsícem s tváří."
    },
    {
        obec: "Mašťov",
        kod: 563218,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43156,
        latitude: 50.262382,
        longitude: 13.280572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Ma%C5%A1%C5%A5ov_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, modrý, bílý, červený, modrý, červený, bílý a modrý, v poměru 3 : 2 : 3 : 20 : 3 : 2 : 3. Ve středním modrém pruhu bílá kvádrovaná hradba, po stranách kosmo a šikmo zalomená, se třemi stínkami uprostřed a dvěma po obou stranách, v každé stínce černá kruhová střílna. Hradba prolomena prázdnou branou s otevřenými hnědými vraty na černých závěsech a s černou vytaženou mříží. Po stranách brány po jedné černé klíčové střílně. Z hradby vyniká bílá kvádrovaná válcová věž se širším soklem a třemi (2, 1) černými okny, zakončená cimbuřím se třemi stínkami a červenou cibulovou střechou se žlutou makovicí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Matějov",
        kod: 596108,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.528697,
        longitude: 15.863108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Mat%C4%9Bjov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Uprostřed bílý kůň ve skoku se žlutou zbrojí, hřívou, ocasem a červeným jazykem nad žlutým jetelovitě zakončeným perizoniem, provázený nahoře žlutými květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě nad zlatým, jetelovitě zakončeným perizoniem stříbrný kůň ve skoku se zlatou zbrojí, hřívou, ocasem a červeným jazykem provázený nahoře dvěma zlatými květy."
    },
    {
        obec: "Mazelov",
        kod: 535940,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37363,
        latitude: 49.103053,
        longitude: 14.617886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Mazelov_CoA.png",
        coatOfArmsFlagDescription: "Šikmo dělený list. V žerďovém poli s pěti střídavě modrými a bílými vodorovnými pruhy žlutý obilný snop.V bílém vlajícím poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmo dělený štít. V horním modrém poli se třemi stříbrnými břevny zlatý obilný snop, v dolním stříbrném červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Mažice",
        kod: 562866,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.213602,
        longitude: 14.612918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Ma%C5%BEice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, černý, žlutý, černý, bílý a zelený, v poměru 5 : 1 : 1 : 1 : 1 : 1. V horním bílém pruhu do oblouku pět (1, 2, 2) modrých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá cihlová hradba prolomená prázdnou branou provázenou dvěma vyrůstajícími zlatými obilnými klasy, každý s listem do středu. Nad hradbou do oblouku pět (1, 2, 2) modrých hvězd, v bráně vztyčená zelená borová šiška."
    },
    {
        obec: "Mcely",
        kod: 537462,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28936,
        latitude: 50.294356,
        longitude: 15.075984,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Mcely_znak.jpg",
        coatOfArmsFlagDescription: "Ve žlutém listě modrá zkřížená liliová břevna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrá zkřížená liliová žezla převýšená knížecí čepicí."
    },
    {
        obec: "Meclov",
        kod: 553913,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34521,
        latitude: 49.505676,
        longitude: 12.880927,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Meclov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký třetinu délky listu a zelené pole. V pruhu modré kruhové pole o průměru čtvrtiny délky listu, v něm žlutá lilie, podložené červeným mečem hrotem k hornímu okraji listu, nahoře provázeným osmi (4, 4) červenými plaménky. Ve střední části listu bílá hornická kladívka nad žlutým obilným klasem se dvěma odkloněnými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít. Vpravo modrý kotouč se zlatou lilií podložený vztyčeným červeným mečem, nahoře obklopeným osmi (4, 4) červenými plaménky. Vlevo stříbrná hornická kladívka nad zlatým obilným klasem se dvěma odkloněnými listy."
    },
    {
        obec: "Mečeříž",
        kod: 565644,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29477,
        latitude: 50.29048,
        longitude: 14.7369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Meceriz_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý kvádrovaný bílý. V modrém pruhu žlutý meč hrotem k vlajícímu okraji. Zubatý pruh má šest obdélníkových zubů a pět polovičních mezer. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě za stříbrnou kvádrovanou hradbou s cimbuřím stříbrná kvádrovaná věž s černým oknem a červenou valbovou střechou, provázená zlatými vztyčenými meči."
    },
    {
        obec: "Mečichov",
        kod: 551392,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38736,
        latitude: 49.347873,
        longitude: 13.812134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Me%C4%8Dichov_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 5 : 2 : 5. Uprostřed listu kosmo meč hrotem k dolnímu cípu, šikmo podložený vztyčeným veslem, uprostřed modrého pruhu šesticípá hvězda, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře stříbrným vlnitým břevnem děleném štítě kosmo postavený meč šikmo podložený vztyčeným veslem, dole mezi nimi hvězda, vše zlaté."
    },
    {
        obec: "Měčín",
        kod: 556637,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.480195,
        longitude: 13.402993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Mecin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý svislý žerďový pruh rovný 1/3 délky listu a šest vodorovných pruhů střídavě bílých a červených, počínaje odshora bílým. V modrém poli od žerdě otočená horní část žluté berly. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, vpravo modrý se zlatou horní částí doleva otočené berly, vlevo pětkrát stříbrno-černě dělený."
    },
    {
        obec: "Medlice",
        kod: 594423,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 49.003081,
        longitude: 16.121404,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Medlice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list. V bílém poli tři černé vodorovné pruhy vycházející z první, třetí a páté čtrnáctiny žerďového okraje listu. V žerďovém poli šikmý červený plamenný meč s černým jílcem. Ve vlajícím poli rozevřená kniha, šikmo podložená berlou závitem k vlajícímu okraji listu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmo dělený štít. Pravé pole černo-stříbrně zvýšeně dělené, nahoře dvě stříbrná břevna, přes to šikmo vztyčený červený plamenný meč s černým jílcem, v levém zeleném poli rozevřená kniha šikmo podložená berlou závitem doleva, vše zlaté."
    },
    {
        obec: "Medlov",
        kod: 583367,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66466,
        latitude: 49.03519,
        longitude: 16.522482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Medlov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:3:1. V modrém pruhu vedle sebe vinný hrozen s ratolestí, vztyčený vinařský nůž - kosíř ostřím k žerdi a krojidlo hrotem dolů a ostřím k vlajícímu okraji, dole mezi vinařským nožem a krojidlem vinný hrozen, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vedle sebe vinný hrozen s ratolestí, vztyčený vinařský nůž - kosíř a krojidlo ostřím doleva, dole mezi vinařským nožem a krojidlem vinný hrozen, vše stříbrné."
    },
    {
        obec: "Medlov",
        kod: 552372,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78391,
        latitude: 49.787639,
        longitude: 17.062716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Medlov_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílou dvojitou pravoúhlou krokví širokou jednu šestinu délky listu. Krokev vychází z třetí šestiny horního a dolního okraje listu, její dva vrcholy sahají do jedné šestiny délky listu u žerdě, odvrácený vrchol sahá do středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrné postavené radlice a krojidlo."
    },
    {
        obec: "Měděnec",
        kod: 563226,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43184,
        latitude: 50.421723,
        longitude: 13.115541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/M%C4%9Bd%C4%9Bnec_-_CoA.jpg"
    },
    {
        obec: "Medlovice",
        kod: 550141,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.27451,
        longitude: 17.093049,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Coa_Czech_Town_Medlovice.svg",
        coatOfArmsFlagDescription: "Zelený list se šikmým bílým pruhem vycházejícím z první šestiny dolního okraje listu do páté šestiny horního okraje listu. V žerďovém poli šikmo žlutý obilný klas, ve vlajícím poli bílý maltézský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zvýšené šikmé stříbrné břevno provázené nahoře šikmým zlatým obilným klasem a dole stříbrným maltézským křížem."
    },
    {
        obec: "Medlovice",
        kod: 592366,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68741,
        latitude: 49.049125,
        longitude: 17.27186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Medlovice_UH_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří modrý horní rohový trojúhelník se žlutou lilií a čtyři vodorovné pruhy, žlutý, červený, žlutý a zelený, v poměru 1:2:1:2. Odvěsny trojúhelníka se rovnají šířce listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě červeným břevnem děleném štítě nahoře svěšený vinný list a lilie, obojí zlaté, dole zelený milíř."
    },
    {
        obec: "Medonosy",
        kod: 529575,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.494256,
        longitude: 14.487049,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Medonosy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červeno-bíle čtvrcený žerďový pruh široký šestinu délky listu a zelené pole, v něm u žerďového pruhu tři žluté včely s bílými křídly nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole červeno-stříbrně čtvrcené. V levém zeleném poli tři zlaté včely se stříbrnými křídly nad sebou."
    },
    {
        obec: "Medový Újezd",
        kod: 579009,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.773949,
        longitude: 13.719185,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Medov%C3%BD_%C3%9Ajezd%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žlutě plástvovaný žerďový pruh široký čtvrtinu délky listu a bílé pole se dvěma zelenými smrky. Mezi jejich černými kmeny u dolního okraje listu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zlatě plástvovanou zelenou hlavou dva zelené smrky s černými kmeny, dole mezi nimi červená růže se zlatým semeníkem."
    },
    {
        obec: "Měchenice",
        kod: 539490,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25206,
        latitude: 49.908274,
        longitude: 14.389375,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/M%C4%9Bchenice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem ve středu listu, svislým bílým pruhem širokým jednu desetinu délky listu, vzdáleným tři dvacetiny délky listu od vlajícího okraje. V klínu černé křídlo s červenými plaménky, ve střední části žlutý tlapatý kříž nad žlutým sluncem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou patou s modrým břevnem stříbrný klín s černým plamenným křídlem, provázený vpravo zlatým tlapatým křížem a vlevo zlatým sluncem."
    },
    {
        obec: "Měcholupy",
        kod: 539716,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33551,
        latitude: 49.519592,
        longitude: 13.532756,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/M%C4%9Bcholupy_PJ_coA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený se žlutým patriarším křížem a modrý s bílou otevřenou knihou se žlutými deskami, provázená nahoře žlutou lilií, dole osmicípou žlutou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě vpravo zlatý patriarší kříž, vlevo stříbrná otevřená kniha se zlatými deskami, provázená nahoře lilií a dole osmihrotou hvězdou, obojí zlaté."
    },
    {
        obec: "Měcholupy",
        kod: 566454,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43931,
        latitude: 50.26677,
        longitude: 13.537482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Mecholupy_znak.jpg"
    },
    {
        obec: "Měkynec",
        kod: 536415,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.15838,
        longitude: 14.029485
    },
    {
        obec: "Melč",
        kod: 508144,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74784,
        latitude: 49.850024,
        longitude: 17.757906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Mel%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 5:2:1. Z modrého pruhu se dvěma bílými růžemi se žlutými semeníky a zelenými kališními lístky vyrůstá bílý dvouocasý lev se žlutou zbrojí, držící vztyčený žlutý šíp s modrým hrotem a opeřením. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře vyrůstající stříbrný dvouocasý lev se zlatou zbrojí držící zlatý vztyčený šíp s modrým hrotem a opeřením, dole čelně vztyčená stříbrná radlice provázená nahoře dvěma stříbrnými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Mělčany",
        kod: 583375,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.076242,
        longitude: 16.4923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/M%C4%9Bl%C4%8Dany_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým pětiúhelníkovým polem, širokým polovinu délky listu a s vrcholem ve druhé třetině délky listu. V modrém poli vztyčený žlutý vinný list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčený zlatý vinný list nad šikmým krojidlem, kosmo přeloženým radlicí, obojí stříbrné a vztyčené."
    },
    {
        obec: "Mělnické Vtelno",
        kod: 535044,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27738,
        latitude: 50.352413,
        longitude: 14.695218,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/M%C4%9Blnick%C3%A9_Vtelno_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s černým středovým tlapatým křížem s rameny širokými jednu pětinu šířky listu. Uprostřed čtvercové červeno-modře polcené pole, široké třetinu délky listu. V žerďové polovině pole bílý buvolí roh, ve vlající polovině pole žlutá věž s cimbuřím, kvádrovaným soklem, černou branou a oknem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na černém tlapatém heroldském kříži červeno-modře polcený štítek, vpravo stříbrný buvolí roh, vlevo zlatá věž s cimbuřím, kvádrovaným soklem, černou branou a oknem."
    },
    {
        obec: "Mělník",
        kod: 534676,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27601,
        latitude: 50.354002,
        longitude: 14.481881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/M%C4%9Bln%C3%ADk_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Z červeného pruhu s českým lvem vyniká do žlutého pruhu půl černé korunované orlice se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Menhartice",
        kod: 545031,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 48.990055,
        longitude: 15.552611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Coat_of_arms_of_Menhartice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. Do zeleného pruhu z žerďového okraje listu vyniká bílá pravá ruka držící tři žluté klasy, krajní odkloněné s listem. Ve žlutém pruhu červený latinský kříž, jehož vodorovné rameno vychází z osmé až desáté čtyřiadvacetiny žerďového okraje pruhu. Pod žerďovým ramenem kříže postavené černé pero s bílým brkem, nad vlajícím ramenem černá osminová nota. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-zlatě polcený štít. Vpravo stříbrná pravá ruka vynikající z okraje štítu a držící tři zlaté obilné klasy, krajní s listem. Vlevo červený latinský kříž do krajů provázený vpravo dole postaveným černým perem se stříbrným brkem a vlevo nahoře černou osminovou notou."
    },
    {
        obec: "Měník",
        kod: 570397,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50364,
        latitude: 50.215505,
        longitude: 15.528273
    },
    {
        obec: "Měnín",
        kod: 583383,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66457,
        latitude: 49.082495,
        longitude: 16.694343,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Menin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a červený, v poměru 2:1:1. V horním rohu černý stojící havran s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstající černý strom bez listí, na jeho levé spodní větvi černý havran s červenou zbrojí."
    },
    {
        obec: "Měňany",
        kod: 531529,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.910104,
        longitude: 14.117791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/M%C4%9B%C5%88any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, modrý, bílý a modrý, v poměru 5 : 1 : 1 : 1. Ve žlutém pruhu vykračující černý býk s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře děleném štítě nahoře vykračující černý býk s červenou zbrojí, dole zkřížené stříbrné jelení parohy, mezi nimi zlatý zvon se závěsem."
    },
    {
        obec: "Merboltice",
        kod: 545791,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.684307,
        longitude: 14.339205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/CoA_of_Merboltice.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, červený, žlutý, červený a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrno-černě polceným lemem zlaté, nahoře rozlomené kolo sv. Kateřiny se stříbrnými noži, jehož středem prochází postavený stříbrný meč se zlatým jílcem a záštitou."
    },
    {
        obec: "Merklín",
        kod: 555363,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36234,
        latitude: 50.32819,
        longitude: 12.863613,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Merklin_%28Karlovy_Vary%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 1:3. V zeleném pruhu vodorovný třikrát lomený bílý pruh vycházející ze šesté a sedmé osminy žerďového a vlajícího okraje se dvěma vrcholy ve třetí osmině šířky listu a dolním prostředním v sedmé osmině šířky listu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým třikrát nahoru lomeným stříbrným břevnem zlatý jetelový čtyřlist na stonku."
    },
    {
        obec: "Merklín",
        kod: 558044,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33452,
        latitude: 49.560538,
        longitude: 13.198008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Merklin_znak.gif"
    },
    {
        obec: "Měrotín",
        kod: 568911,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78324,
        latitude: 49.692571,
        longitude: 17.001818,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Merotin_znak.gif",
        coatOfArmsFlagDescription: "Červený list s žerďovým bílým vydutým klínem přecházejícím ve střední a vlající části v lilii s vrcholem na vlajícím okraji, v něm modrý meč hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný liliovitě zakončený vydutý klín, v něm postavený modrý meč."
    },
    {
        obec: "Měrovice nad Hanou",
        kod: 552909,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75201,
        latitude: 49.34167,
        longitude: 17.245955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/M%C4%9Brovice_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se třemi žlutými, od středu odkloněnými obilnými klasy, krajní s jedním svěšeným listem, přeloženými uprostřed vodorovným oboustranně zubatým posunutým bílým pruhem, širokým jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě tři od středu odkloněné zlaté obilné klasy, krajní s jedním svěšeným listem po straně, přeložené stříbrným oboustranně cimbuřovým břevnem."
    },
    {
        obec: "Měrunice",
        kod: 567698,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41804,
        latitude: 50.480523,
        longitude: 13.818088,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/CoA_of_M%C4%9Brunice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený. Uprostřed žlutého pruhu červený český granát broušený do tvaru osmicípé hvězdy s kruhovým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě vpravo zlatý rýč, vlevo nahoře červený český granát broušený do tvaru osmihroté hvězdy s kruhovým středem."
    },
    {
        obec: "Měřín",
        kod: 596116,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59442,
        latitude: 49.393312,
        longitude: 15.883916,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/M%C4%9B%C5%99%C3%ADn_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený v poměru 1:3:1. V žerďové části žlutého pole tři (2+1) černé, dolů obrácené kápě s bílou podšívkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém červeně lemovaném štítě červená pravá ruka se stříbrným rukávem, od ní tři (2,1) černé, dolů obrácené kápě se stříbrnou podšívkou."
    },
    {
        obec: "Městečko",
        kod: 542067,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.050739,
        longitude: 13.86367
    },
    {
        obec: "Městec Králové",
        kod: 537489,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28903,
        latitude: 50.207281,
        longitude: 15.297687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Mestec_kralove_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list s dvouocasým lvem s korunou, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě český lev."
    },
    {
        obec: "Město Libavá",
        kod: 500160,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78307,
        latitude: 49.721619,
        longitude: 17.520135,
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký polovinu délky listu a tři vodorovné pruhy, v poměru 2 : 1 : 1. V žerďovém pruhu půl korunované černé orlice s červenou zbrojí přiléhající k vlajícím pruhům. Horní vlající červený pruh se dvěma odvrácenými vztyčenými bílými vinařskými noži se žlutými rukojeťmi pod žlutou korunou. Z dolního okraje prostředního červeného pruhu vynikají tři bílá trojúhelníková pole s vrcholy na horním okraji pruhu. Dolní pruh bílo-modře kosmo dělený. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Městečko Trnávka",
        kod: 578380,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56941,
        latitude: 49.709363,
        longitude: 16.727541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/M%C4%9Bste%C4%8Dko_Trn%C3%A1vka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký polovinu délky listu a třináct vodorovných pruhů střídavě modrých a žlutých. V žerďovém pruhu volná bílá kvádrovaná hradba s cimbuřím, s prázdnou branou s vytaženou žlutou mříží a dvěma černými okny po stranách. Z hradby mezi dvěma žlutými kopulemi vyniká bílá kvádrovaná věž s cimbuřím, černým oknem a žlutou kopulí. Na kopulích na černých žerdích žluté trojúhelníkové praporky, na střední a žerďové kopuli vlající k žerďovému, zbývající k vlajícímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě volná stříbrná kvádrovaná hradba s cimbuřím, s prázdnou branou s vytaženou zlatou mříží a se dvěma černými okny po stranách. Z hradby mezi dvěma zlatými kopulemi vyniká stříbrná kvádrovaná věž s cimbuřím, černým oknem a zlatou kopulí. Na kopulích na černých žerdích zlaté trojúhelníkové praporky, levý vlající doleva."
    },
    {
        obec: "Město Albrechtice",
        kod: 597635,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79395,
        latitude: 50.163036,
        longitude: 17.574913,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Mesto_albrechtice-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený v poměru 1:2:1. Ve žlutém pruhu vztyčený zelený list bílého topolu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Město Touškov",
        kod: 559211,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.77598,
        longitude: 13.251189,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/M%C4%9Bsto_Tou%C5%A1kov_CoA.png",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole černé, dolní žluté, dolní vlající pole bílé, dolní červené. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Měšice",
        kod: 538477,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25064,
        latitude: 50.198097,
        longitude: 14.520024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/M%C4%9B%C5%A1ice_%28Prague-East%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Žluto-modře čtvrceným středovým křížem modro-bíle čtvrcený list. V horním žerďovém poli žlutý půlměsíc cípy nahoru se dvěma vyrůstajícími bílo-červeně šachovanými rohy. V dolním žerďovém poli modrý maltézský kříž. Ramena středového kříže jsou široká jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě zlato-modře čtvrcená kotva, v 1. poli zlatý stoupající půlměsíc se dvěma vyrůstajícími stříbrno-červeně šachovanými rohy, ve 2. poli modrý maltézský kříž."
    },
    {
        obec: "Měšín",
        kod: 587508,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58601,
        latitude: 49.437406,
        longitude: 15.657133,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Coat_of_arms_of_M%C4%9B%C5%A1%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a modré pole. Na jejich styku nahoře list jírovce, dole dva vyrůstající odkloněné obilné klasy s listem, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vztyčený list jírovce nad dvěma vyrůstajícími odkloněnými obilnými klasy s listem, vše stříbrné."
    },
    {
        obec: "Mešno",
        kod: 579084,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.655921,
        longitude: 13.622053
    },
    {
        obec: "Metylovice",
        kod: 512184,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73949,
        latitude: 49.606756,
        longitude: 18.339222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Metylovice_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se svislým červeným pruhem širokým jednu čtvrtinu délky listu na vlajícím okraji. V modrém poli kráčející bílý kůň držící žlutý bič. Červený pruh je mřežován dvěma svislými a čtyřmi vodorovnými bílými pruhy širokými jednu třicetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně sníženě dělený štít, nahoře stříbrný kráčející kůň držící zlatý bič, dole stříbrná mříž."
    },
    {
        obec: "Mezholezy",
        kod: 553921,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.411826,
        longitude: 13.103952,
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, černý, bílý a zelený, v poměru 6 : 1 : 1 : 1 : 15. V modrém pruhu prohnutý žlutý obilný klas odkloněný k žerďovému okraji a provázený bílou růží. V žerďové části zeleného pruhu prohnutý žlutý obilný klas odkloněný k vlajícímu okraji a provázený svěšeným bílým lipovým listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně polcený štít černým, stříbrně lemovaným kůlem provázeným prohnutými odkloněnými zlatými obilnými klasy. Pravý klas provázen vpravo růží, levý klas vlevo svěšeným lipovým listem, obojí stříbrné."
    },
    {
        obec: "Mezholezy",
        kod: 553930,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.624867,
        longitude: 12.905124,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červeno-bíle polcený s šestilistou růží opačných barev se žlutým semeníkem a zelenými kališními lístky, a modrý se žlutým kaprem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. V horním červeno-stříbrně polceném poli šestilistá růže opačných barev se zlatým semeníkem a zelenými kališními lístky. V dolním modrém poli zlatý kapr."
    },
    {
        obec: "Meziboří",
        kod: 567311,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43513,
        latitude: 50.62126,
        longitude: 13.598813,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Mezibo%C5%99%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený. V bílém pruhu červené slunce bez obličeje se šestnácti střídavě plamennými a trojúhelníkovými paprsky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mezihoří",
        kod: 578461,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.488506,
        longitude: 13.254671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Meziho%C5%99%C3%AD_%28Klatovy_District%29_CoA.jpg"
    },
    {
        obec: "Mezilečí",
        kod: 574236,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.453464,
        longitude: 15.983292,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Mezile%C4%8D%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 3. Z horního okraje zeleného pruhu vyrůstají dva zelené jehličnaté stromy s hnědými kmeny, nahoře mezi nimi červené miskové váhy. V zeleném pruhu vějířovitě tři žluté hřeby hroty k sobě a k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelené dvojvrší s třemi postavenými a vějířovitě rozloženými zlatými hřeby. Z vrchů vyrůstá po jehličnatém stromu přirozené barvy, mezi nimi nahoře červené miskové váhy."
    },
    {
        obec: "Mezilesí",
        kod: 548367,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.541028,
        longitude: 14.949183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Meziles%C3%AD_PE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě zelených a žlutých, v poměru 2 : 1 : 2 : 1 : 2. Uprostřed kosmo položený bílý čtverec se stranami dlouhými sedm dvanáctin délky listu se dvěma přivrácenými černými kosy se žlutou zbrojí stojícími na volném oboustranně posunutě zubatém zeleném pruhu s pěti (3, 2) zuby. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný hrot, z něhož vyrůstají dva zlaté jehličnaté stromy, mezi nimi stříbrná svatojakubská mušle. V hrotu na volném zeleném cimbuřovém břevnu o pěti stínkách (3, 2) dva přivrácení černí kosi se zlatou zbrojí."
    },
    {
        obec: "Mezilesí",
        kod: 574244,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54923,
        latitude: 50.356207,
        longitude: 16.217095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Meziles%C3%AD_%28N%C3%A1chod_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 : 1. Uprostřed listu vykořeněný dub provázený v horním rohu třmenem a v horním cípu zkříženými ostrvemi, vše žluté. V zeleném pruhu položený bílý tkalcovský člunek se žlutou nití. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se zelenou patou dub provázený nahoře třmenem a zkříženými ostrvemi, vše zlaté, dole položený stříbrný tkalcovský člunek se zlatou nití."
    },
    {
        obec: "Meziměstí",
        kod: 574252,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54981,
        latitude: 50.624705,
        longitude: 16.242178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Mezimesti_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Zelený list se žlutou mufloní hlavou. Žerďový svislý bílý pruh široký čtyři desetiny šířky listu se třemi červenými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky zlatá mufloní hlava."
    },
    {
        obec: "Mezina",
        kod: 551724,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.958602,
        longitude: 17.477161,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Mezina_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým horním vlajícím trojúhelníkem s vrcholy v první třetině horního okraje, druhé třetině vlajícího okraje. V modrém poli bílý vykračující kůň, v bílém poli modrá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod kosmou stříbrnou hlavou s modrou hvězdou stříbrný vykračující kůň."
    },
    {
        obec: "Meziříčí",
        kod: 563234,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.436646,
        longitude: 14.575126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Mezi%C5%99%C3%AD%C4%8D%C3%AD_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu třikrát lomený bílý pruh vycházející z páté až sedmé osminy žerďového a vlajícího okraje pruhu s vrcholy osminu šířky od horního a dolního okraje pruhu. V bílém pruhu červená růže se žlutým semeníkem mezi dvěma svislými vlnitými modrými pruhy vycházejícími z druhé a sedmé osminy dolního okraje listu. Pruhy dvěma vrcholy a třemi prohlubněmi přivrácené k růži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou s třikrát lomeným stříbrným břevnem červená růže se zlatým semeníkem mezi dvěma modrými vlnitými kůly."
    },
    {
        obec: "Meziříčko",
        kod: 591149,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.109422,
        longitude: 15.660333
    },
    {
        obec: "Meziříčko",
        kod: 587516,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.405486,
        longitude: 15.844614
    },
    {
        obec: "Mezná",
        kod: 529834,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.34713,
        longitude: 15.211484
    },
    {
        obec: "Mezná",
        kod: 599255,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.258242,
        longitude: 14.800827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Mezn%C3%A1_TA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi. Uprostřed listu přivrácené husí krky opačných barev se žlutými zobáky. Ve střední části listu u dolního okraje kruhové modré pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě přivrácené husí krky opačných barev se zlatými zobáky, dole modrý kotouč."
    },
    {
        obec: "Mezno",
        kod: 530158,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25786,
        latitude: 49.534622,
        longitude: 14.64504,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Mezno_CoA.png",
        coatOfArmsFlagDescription: "List tvoří v žerďové části pět svislých pruhů, střídavě modrých a bílých, ve vlající části pět svislých pruhů, střídavě červených a bílých, všechny široké patnáctinu délky listu. Střední část má horní polovinu červeno-modře polcenou s bílou růží se žlutým semeníkem a zelenými kališními lístky, dolní polovina je žlutá s černým vydutým klínem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě na zlatém návrší čtyři stříbrné kamenné mezníky převýšené stříbrnou růží se zlatým semeníkem a zelenými kališními lístky. V návrší černý vydutý hrot."
    },
    {
        obec: "Mezouň",
        kod: 531537,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 50.004378,
        longitude: 14.214277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Mezou%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký třetinu délky listu a bílé pole s červeným džbánem. V žerďovém pruhu bílý kříž sv. Jana Křtitele. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně vpravo polcený štít. Vpravo stříbrný kříž sv. Jana Křtitele, vlevo červený džbán."
    },
    {
        obec: "Mičovice",
        kod: 550426,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 48.983641,
        longitude: 14.125165,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Mi%C4%8Dovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý se zeleným pětilistem s hnědými stopkami, se třemi lipovými listy nahoře a dvěma dubovými listy dole, a zelený, v poměru 3 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou hlavou se zlatou korunou pětilist se třemi lipovými listy nahoře a dvěma dubovými listy dole, vše přirozené barvy."
    },
    {
        obec: "Míčov-Sušice",
        kod: 571822,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.905062,
        longitude: 15.605872,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/M%C3%AD%C4%8Dov-Su%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutým pruhem širokým čtvrtinu šířky listu šikmo dělený list. V horním zeleném poli bílý květ lnu se žlutým středem, ve žlutém pruhu červené kopí s modrým hrotem k hornímu vlajícímu cípu, v dolním modrém poli bílý zvon se dvěma červenými tlapatými křížky nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře zlatým šikmým břevnem dělený štít. Nahoře stříbrný květ lnu se zlatým středem, dole stříbrný zvon se dvěma červenými tlapatými křížky nad sebou. V břevnu vztyčené červené kopí s modrým hrotem."
    },
    {
        obec: "Michalovice",
        kod: 548316,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.577036,
        longitude: 15.524426,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Michalovce-znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 2 : 3. Uprostřed žlutý plamenný meč hrotem vnikající v bílém pruhu do vztyčené a okem k žerďovému okraji listu obrácené zelené dračí hlavy s červeným jazykem. Meč je v červeném pruhu provázený bílými přivrácenými křídly a podložený miskovými vahami se žlutým vahadlem v červeném pruhu a s černými miskami v bílém pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně děleném štítě postavený zlatý plamenný meč, vnikající do otevřené tlamy nahoru a doleva obrácené zelené dračí hlavy s červeným jazykem, provázený nahoře dvěma stříbrnými přivrácenými křídly a uprostřed podložený miskovými vahami se zlatým vahadlem a černými miskami."
    },
    {
        obec: "Michalovice",
        kod: 542521,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.536682,
        longitude: 14.082042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Michalovice_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutým kosmým pruhem širokým jednu čtvrtinu délky listu. V pruhu červený plamenný meč hrotem k hornímu rohu, v horním vlajícím poli šikmo žlutý vinný list se dvěma úponky a v dolním žerďovém poli bílý pětilistý květ se žlutým středem a dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté kosmé břevno s červeným vztyčeným plamenným mečem, provázené nahoře šikmo položeným zlatým vinným listem se dvěma úponky a dole stříbrným pětilistým květem se zlatým středem a dvěma zelenými listy."
    },
    {
        obec: "Míchov",
        kod: 582069,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.524132,
        longitude: 16.615857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/M%C3%ADchov_CoA.png",
        coatOfArmsDescription: "Ve stříbrném štítě červený zvon nad modrou vlnitou patou."
    },
    {
        obec: "Mikolajice",
        kod: 568279,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74784,
        latitude: 49.877258,
        longitude: 17.793425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Mikolajice_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým modrým pruhem na červené žerďové a bílé vlající pole. Pruh vychází z poloviny horního a žerďového okraje do poloviny vlajícího a dolního okraje listu, v něm kosmo nakloněný džber s leknem vyrůstajícím k vlajícímu okraji, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou s hrotem kosmo nakloněný džber s vyrůstajícím pravým leknem, vše stříbrné."
    },
    {
        obec: "Mikulášovice",
        kod: 562751,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40779,
        latitude: 50.965179,
        longitude: 14.363794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Mikulasovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě modré a žluté. V žerďovém modrém pruhu vyrůstá z dolního okraje bílý tovární kvádrovaný komín, provázený dvěma žlutými obilnými klasy se dvěma listy. V žerďovém žlutém pruhu bílý meč hrotem nahoru provlečený zeleným vavřínovým věncem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo vyrůstá stříbrný kvádrovaný tovární komín provázený dvěma zlatými obilnými klasy, každý se dvěma listy, vlevo vztyčený meč přirozené barvy provlečený zeleným vavřínovým věncem, s rukojetí provázenou rozděleným černým letopočtem 19 16."
    },
    {
        obec: "Mikulčice",
        kod: 586374,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69619,
        latitude: 48.816526,
        longitude: 17.051171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Mikul%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1 : 3 : 1. Ve žlutém pruhu vztyčená vykořeněná dubová větev s pěti žaludy, vše zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod palisádovou hlavou vztyčená vykořeněná dubová větev s pěti žaludy, vše zelené."
    },
    {
        obec: "Mikuleč",
        kod: 578398,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.805919,
        longitude: 16.422601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Mikule%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, černý a červený, v poměru 2:5:2, přes které je položen žlutý středový kříž s rameny širokými jednu šestinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě se zlatým heroldským křížem červená dračí hlava s rozeklaným jazykem probodnutá zespodu černým kopím s červeným hrotem."
    },
    {
        obec: "Mikulov",
        kod: 567701,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41901,
        latitude: 50.687713,
        longitude: 13.721712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Znak_Mikulov_%28Teplice%29.png"
    },
    {
        obec: "Mikulov",
        kod: 584649,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69201,
        latitude: 48.805383,
        longitude: 16.637788,
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, červený, bílý a modrý, v poměru 3:1:1:1:3. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mikulovice",
        kod: 594431,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67133,
        latitude: 48.956878,
        longitude: 16.09478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Mikulovice_%28okres_Znojmo%29_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modro - červeně dělený a bílý. V modrém poli kosmý klíč zuby nahoru a k žerďovému okraji provázený dvěma šesticípými hvězdami, vše žluté. V červeném poli zelený vinný hrozen se dvěma listy. V bílém pruhu dva šedé zkřížené sypeny - korce s hnědými násadami nahoru. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře modro - červeně polcený. V pravém poli kosmo vztyčený klíč provázený dvěma hvězdami, vše zlaté, vlevo zelený vinný hrozen na stonku se dvěma listy. V dolním stříbrném poli dva zkřížené postavené sypeny - korce na dřevěných násadách přirozené barvy."
    },
    {
        obec: "Mikulovice",
        kod: 591157,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.161765,
        longitude: 15.846693
    },
    {
        obec: "Mikulovice",
        kod: 540382,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79084,
        latitude: 50.298634,
        longitude: 17.321655,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Mikulovice_%28okres_Jesen%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část s bílou žlutě zdobenou mitrou v horním rohu a modrá vlající část s žlutým zkříženým kladivem a dlátem v horním cípu. Uprostřed žlutá prázdná brána z kvádrů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít, z jehož dolního okraje vyniká zlatá prázdná brána z kvádrů, provázená nahoře vpravo stříbrnou, zlatě zdobenou mitrou, vlevo křížem přeloženými zlatými kladivem a dlátem."
    },
    {
        obec: "Mikulovice",
        kod: 575372,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.989526,
        longitude: 15.775487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Mikulovice_PA_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný červený, v poměru 4 : 1 : 5. V modrém pruhu knížecí čepice. V červeném pruhu žluté vozové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně zvýšeným zúženým stříbrným vlnitým břevnem dělený štít. Nahoře knížecí čepice, dole zlaté vozové kolo."
    },
    {
        obec: "Mikulůvka",
        kod: 544507,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75624,
        latitude: 49.413614,
        longitude: 17.925167,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Mikuluvka_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. V horní polovině žerďové části bílá, žlutě zdobená mitra, uprostřed bílá rozkřídlená husa se žlutou zbrojí, v horní polovině vlající části tři zkřížená bílá pštrosí péra. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě dopředu obrácená rozkřídlená stříbrná husa se zlatou zbrojí, provázená nahoře vpravo stříbrnou, zlatě zdobenou mitrou, vlevo třemi zkříženými stříbrnými pštrosími pery."
    },
    {
        obec: "Milavče",
        kod: 553948,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.467066,
        longitude: 12.980829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Milav%C4%8De_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 4 : 3 : 1. V červeném a modrém pruhu bílá, žlutě zdobená mitra s červenou růží se žlutým semeníkem a zelenými kališními lístky a s bílými fimbriemi, podložená kosmo žlutou berlou a šikmo bílým mečem se žlutým jílcem hrotem k dolnímu rohu, pod mitrou žlutý prehistorický obětní kotlík na vozíku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě se stříbrnou vlnitou patou stříbrná, zlatě zdobená mitra s červenou růží se zlatým semeníkem a zelenými kališními lístky a s fimbriemi, podložená kosmou zlatou berlou a šikmo postaveným stříbrným mečem se zlatým jílcem. Pod mitrou zlatý prehistorický obětní kotlík na vozíku."
    },
    {
        obec: "Milasín",
        kod: 549835,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.466338,
        longitude: 16.228909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Milas%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se dvěma šikmými červenými pruhy širokými jednu čtvrtinu šířky listu, oddělené bílým žlutým pruhem širokým jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě šikmo položené červené pootevřené rty."
    },
    {
        obec: "Milčice",
        kod: 537497,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.10547,
        longitude: 14.991803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Coats_of_arms_Mil%C4%8Dice.jpeg",
        coatOfArmsFlagDescription: "Žluto-červeně šikmo dělený list. V žerďovém poli zelená vykořeněná lípa s hnědým kmenem, ve vlajícím poli brk v kalamáři, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně šikmo děleném štítě nahoře vykořeněná lípa přirozené barvy, dole brk v kalamáři, obojí zlaté."
    },
    {
        obec: "Mileč",
        kod: 558052,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.469232,
        longitude: 13.605014,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Mile%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý se zelenou vykořeněnou lípou a zelený s bílým sloupem na vysokém stupňovitém podstavci. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě vpravo zelená vykořeněná lípa, vlevo stříbrný sloup na vysokém stupňovitém podstavci."
    },
    {
        obec: "Milejovice",
        kod: 536539,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.188193,
        longitude: 13.930185
    },
    {
        obec: "Milenov",
        kod: 515418,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75361,
        latitude: 49.562684,
        longitude: 17.66712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Milenov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1:3:1. V zeleném pruhu dva přivrácení bílí ptáci se žlutou zbrojí, stojící se zdviženými křídly na bílém srdci, z něhož vyrůstají tři žluté stonky, prostřední s bílým plodem a žlutými listy jmelí, krajní odvrácené a s jedním bílým šestilistým květem se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva přivrácení stříbrní ptáci se zlatou zbrojí stojící se zdviženými křídly na stříbrném srdci, z něhož vyrůstají tři zlaté stonky, prostřední se stříbrným plodem a zlatými listy jmelí, krajní odvrácené, každý s jedním stříbrným šestilistým květem se zlatým semeníkem."
    },
    {
        obec: "Milešín",
        kod: 587770,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.368503,
        longitude: 16.201661,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Mile%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou lopatou-srdcovkou mezi dvěma žlutými obilnými klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná lopata-srdcovka mezi dvěma zlatými obilnými klasy."
    },
    {
        obec: "Milešov",
        kod: 540749,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26256,
        latitude: 49.589338,
        longitude: 14.22151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Coats_of_arms_Mile%C5%A1ov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, modrý a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se čtyřmi stříbrnými zúženými vlnitými břevny ve spodní části. Přes ně kosmo položený mlátek křížem podložený želízkem, oba stříbrné se zlatými topůrky a převýšené zlatým sluncem s tváří."
    },
    {
        obec: "Milešovice",
        kod: 593320,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68354,
        latitude: 49.082845,
        longitude: 16.854355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Znak_Mile%C5%A1ovice.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a zelené pole se žlutým vinným hroznem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vinný hrozen s listem, provázený vpravo vinařským nožem, vlevo vztyčenou radlicí, oboje stříbrné."
    },
    {
        obec: "Miletín",
        kod: 573175,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.403916,
        longitude: 15.682367,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Znak_m%C4%9Bsta_Milet%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List s červeným středovým křížem s rameny širokými jednu čtvrtinu šířky listu. Žerďová pole jsou bílá. Horní vlající pole tvoří dva svislé pruhy, žlutý a modrý. Dolní vlající pole tvoří dva svislé pruhy, modrý a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Milevsko",
        kod: 549576,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.450998,
        longitude: 14.360109,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Milevsko_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří pět vodorovných pruhů - červený, žlutý, červený, žlutý a červený. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Milhostov",
        kod: 554651,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.156155,
        longitude: 12.453308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Milhostov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s korunovanou žlutou ženskou hlavou s krkem a dvěma odvrácenými tvářemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený zlatý hrot zakončený korunovanou ženskou hlavou se dvěma odvrácenými tvářemi."
    },
    {
        obec: "Miličín",
        kod: 530166,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25786,
        latitude: 49.570793,
        longitude: 14.66151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Milicin_znak_2022.jpg",
        coatOfArmsFlagDescription: "List tvoří kosmo dělený žerďový pruh, široký čtvrtinu délky listu s žerďovým polem černým a vlajícím žlutým, a bílé pole s červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. Pravé pole zlato-černě kosmo dělené, v levém stříbrném poli červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Milíčov",
        kod: 587524,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.395568,
        longitude: 15.393119
    },
    {
        obec: "Milíčovice",
        kod: 594440,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.891673,
        longitude: 15.932448,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Coat_of_arms_of_Mil%C3%AD%C4%8Dovice.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:1:4:1:1. V modrém pruhu červeno-bíle polcené srdce kvetoucí třemi bílými květy s červenými středy, převýšené žlutou korunou a provázené přivrácenými žlutými křídly. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně sníženě děleném štítě nahoře červeno-stříbrně polcené srdce kvetoucí třemi stříbrnými květy s červenými středy a převýšené zlatou korunou, provázené přivrácenými zlatými křídly, dole vyniká polovina stříbrného mlýnského kamene s položenou červenou kypřicí."
    },
    {
        obec: "Milíkov",
        kod: 538906,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.055886,
        longitude: 12.547716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Milikov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený pilovitý pruh široký jednu čtvrtinu délky listu s hroty sahajícími do jedné třetiny délky listu a žluté pole. V červeném pruhu tři bílé růže se žlutými semeníky a zelenými kališními lístky pod sebou, ve žlutém poli kosmo vztyčená černá medvědí tlapa s červenou zbrojí šikmo podložená hnědým kyjem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod červenou pilovitou hlavou se třemi stříbrnými růžemi se zlatými semeníky a zelenými kališními lístky kosmo vztyčená černá medvědí tlapa s červenou zbrojí šikmo podložená kyjem přirozené barvy."
    },
    {
        obec: "Milíkov",
        kod: 507547,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73981,
        latitude: 49.585749,
        longitude: 18.719541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Milikow_herb.png",
        coatOfArmsFlagDescription: "List praporu tvoří tři svislé pruhy: žlutý, modrý a žlutý v poměru 1:2:1. V modrém poli zkřížené žluté liliové hůlky, pod nimi bílé kolo s osmi rameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zkříženými zlatými liliovými hůlkami stříbrné vozové kolo."
    },
    {
        obec: "Milín",
        kod: 540757,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.632028,
        longitude: 14.046103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Milin_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:1:6:1:1. V žerďové polovině modrého pruhu bílo-červeně polcená orlice se žlutou zbrojí, červeným jazykem a žlutým jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Milínov",
        kod: 530140,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.643171,
        longitude: 13.566047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Mil%C3%ADnov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 1:2:1. Uprostřed bílého pruhu červená růže se žlutým semeníkem a zelenými kališními lístky mezi dvěma červenými parohy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném sníženém břevnu červená věž s cimbuřím, s gotickou branou, s čtvercovým oknem, obojí černé. Věž je provázena dvěma vyrůstajícími smrky přirozené barvy. Pod břevnem červená růže se zlatým semeníkem a zelenými kališními lístky mezi dvěma červenými parohy."
    },
    {
        obec: "Milíře",
        kod: 541532,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.796407,
        longitude: 12.5507783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Mil%C3%AD%C5%99e_%28okres_Tachov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, žlutý, černý a bílý, v poměru 5 : 1 : 1 : 5. V zeleném pruhu bílý meč ze žlutým jílcem hrotem k dolnímu okraji procházející okem žlutého gotického dvojklíče. V bílém pruhu černý milíř hořící třemi červenými plameny, v něm žlutý vztyčený žalud, pod milířem zkřížené černé sekery na hnědých topůrkách. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V zeleno - stříbrně polceném štítě zlato - černě polcený král milíře, vpravo vztyčený zlatý gotický dvojklíč navlečený na postavený stříbrný meč se zlatým jílcem, vlevo černý, třemi červenými plameny hořící milíř, v něm zlatý vztyčený žalud, pod milířem zkřížené černé sekery s topůrky přirozené barvy."
    },
    {
        obec: "Milonice",
        kod: 582077,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.366571,
        longitude: 16.568459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Milonice_%28Blansko_District%29_-_znak.jpg"
    },
    {
        obec: "Milonice",
        kod: 593338,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.15947,
        longitude: 17.071226,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Milonice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký pět dvanáctin délky listu a žluto-červeně šikmo dělené pole. V modrém pruhu kosmo žlutý gotický klíč zuby dolů a k vlajícímu okraji, šikmo podložený bílým mečem se žlutým jílcem hrotem dolů. Ve žlutém poli zelený a v červeném žlutý jetelový trojlist. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli kosmý zlatý gotický klíč zuby dolů a doleva, šikmo podložený postaveným stříbrným mečem se zlatým jílcem. Levé pole zlato-červeně dělené s orlími křídly opačných barev vyrůstajícími z dělící linie. Na horním křídle zlatý a na dolním křídle zelený jetelový trojlist."
    },
    {
        obec: "Miloňovice",
        kod: 551414,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.22067,
        longitude: 13.958469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Milo%C5%88ovice_znak.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený bílým pruhem širokým čtvrtinu délky listu na vlající červené a žerďové modré pole. V červeném kosmo bílá střela hrotem nahoru, v modrém bílý maltézský kříž. V bílém pruhu kosmo tři vztyčené zelené žaludy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře kosmým stříbrným břevnem dělený štít. V břevnu tři kosmo vztyčené zelené žaludy. Nad ním kosmo vztyčená střela, pod ním maltézský kříž, obojí stříbrné."
    },
    {
        obec: "Milostín",
        kod: 542075,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.193824,
        longitude: 13.669209
    },
    {
        obec: "Milotice",
        kod: 586382,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69605,
        latitude: 48.955858,
        longitude: 17.141387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Milotice_Hodon%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý a střídavě zelené a bílé v poměru 5:1:1:1:1:1. Z horního zeleného pruhu vyrůstá v žerďové polovině bílý Pegas se žlutou korunou a zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře vyrůstá stříbrný korunovaný Pegas se zlatou zbrojí a červeným jazykem, dole černý vinařský lis s jedním zeleným hroznem, provázený dvěma modrými odvrácenými radlicemi."
    },
    {
        obec: "Milotice nad Bečvou",
        kod: 515477,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75367,
        latitude: 49.536608,
        longitude: 17.839864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Mnb_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový zvlněný pruh se dvěma vrcholy a třemi prohlubněmi, široký sedm čtyřiadvacetin délky listu a modré pole s bílou vrbou. V bílém pruhu dvě modré lilie pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou se dvěma modrými liliemi stříbrná vrba."
    },
    {
        obec: "Milovice",
        kod: 584657,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69188,
        latitude: 48.852588,
        longitude: 16.698247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Milovice_BV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým vinařským nožem s hnědou rukojetí hrotem dolů a ostřím k žerďovému okraji, provázeným po stranách čtyřmi (2,2) žlutými liliemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě postavený stříbrný vinařský nůž s rukojetí přirozené barvy provázený čtyřmi (2,2) zlatými liliemi."
    },
    {
        obec: "Milotice nad Opavou",
        kod: 569526,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.995792,
        longitude: 17.542152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Coat_of_arms_of_Milotice_nad_Opavou.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 6:1:1. Ve střední části žluté srdce kvetoucí třemi sedmilistými lučními květy, provázené z každé strany nad postranními květy jednou bílou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté srdce kvetoucí třemi sedmilistými lučními květy, podložené sníženým stříbrným vlnitým břevnem a provázené v rozích stříbrnou lilií."
    },
    {
        obec: "Milovice",
        kod: 537501,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28924,
        latitude: 50.226061,
        longitude: 14.888739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Milovice-znak.svg",
        coatOfArmsFlagDescription: "Bílý list se zubatým zeleným dolním vodorovným pruhem se šesti rovnostrannými trojúhelníkovými zuby. Plocha bílého a zeleného pole je totožná. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vybíhajícím třemi hroty k hornímu okraji stříbrné hlavy štítu stříbrná hlava srny s krkem a zlatým jazykem."
    },
    {
        obec: "Milovice u Hořic",
        kod: 548863,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.329866,
        longitude: 15.63112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Milovice_u_Horic_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký dvě sedminy délky listu se žlutým mečem hrotem dolů a pět vodorovných pruhů, modrý, žlutý, černý, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V polceném štítě pod červenou hlavou se zlatým doleva položeným mečem v pravém modrém poli kosmo položený zlatý gotický klíč zuby nahoru a dovnitř šikmo podložený stříbrným postaveným mečem se zlatým jílcem a záštitou, levé pole stříbrno-černě děleno, nahoře černá poštovní trubka náústkem ven."
    },
    {
        obec: "Milý",
        kod: 565466,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.232771,
        longitude: 13.867148
    },
    {
        obec: "Minice",
        kod: 562149,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.489944,
        longitude: 14.01224
    },
    {
        obec: "Mimoň",
        kod: 561835,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47124,
        latitude: 50.655768,
        longitude: 14.724956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Mimo%C5%88_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se šikmým žlutým pruhem o šířce rovné 1/3 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kvádrovaná brána s otevřenými vraty, se zdviženou padací mříží. Z brány zakončené římsou a pěti stínkami vynikají dvě kvádrované věže s cimbuřím, každá s obdélníkovým a kruhovým černým oknem pod sebou a stanovou střechou s makovicí, vše zlaté. Na prostřední stínce přeražený stříbrný antický sloup."
    },
    {
        obec: "Mirkovice",
        kod: 545627,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.811076,
        longitude: 14.391352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Mirkovice_CoA.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem širokým sedminu šířky listu na žerďové červené pole s vozovým kolem a vlající zelené pole s obilným snopem o šesti klasech, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně šikmým zlatým vlnitým břevnem dělený štít. Nahoře vozové kolo, dole obilný snop o šesti klasech, obojí zlaté."
    },
    {
        obec: "Miroslav",
        kod: 594458,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67172,
        latitude: 48.94777,
        longitude: 16.312624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Miroslav_%28znak%29.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s polovinou bílého orla se žlutou zbrojí přiléhající k bílému pruhu se zelenou révovou ratolestí se dvěma listy a dvěma modrými hrozny u červeného pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo vyniká půl stříbrného orla se zlatou zbrojí, vlevo zelená vinná ratolest se dvěma listy a dvěma modrými hrozny."
    },
    {
        obec: "Miroslavské Knínice",
        kod: 594466,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67172,
        latitude: 48.977901,
        longitude: 16.32241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Miroslavsk%C3%A9_Kn%C3%ADnice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3:4. V modrém pruhu úl se třemi (2,1) včelami po stranách, v červeném pruhu vykořeněný keř vinné révy se dvěma odkloněnými hrozny, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně zvýšeně děleném štítě nahoře úl se třemi (2,1) včelami po stranách, dole vykořeněný keř vinné révy se dvěma odkloněnými hrozny, vše zlaté."
    },
    {
        obec: "Mirošov",
        kod: 587532,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.389533,
        longitude: 15.463549
    },
    {
        obec: "Mirošov",
        kod: 596132,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.465697,
        longitude: 16.155648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Miro%C5%A1ov_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se třemi čtvercovými poli o straně rovné jedné polovině šířky listu, černým v horní žerďové části, červeným uprostřed listu a žlutým v dolní vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý vykračující kůň s červeným uzděním a sedlem na žluté pokrývce."
    },
    {
        obec: "Mirošov",
        kod: 559997,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.68793,
        longitude: 13.658174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Mirosov_znak.gif",
        coatOfArmsFlagDescription: "List se třemi svislými pruhy - černým, červeným a černým v poměru 1:2:1. Na červeném poli je bílý dvouocasý lev. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Na červeno-černě polceném štítě stříbrný dvouocasý lev se zlatou zbrojí."
    },
    {
        obec: "Mirošovice",
        kod: 538485,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25166,
        latitude: 49.909949,
        longitude: 14.711306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Miro%C5%A1ovice_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s modrým vlajícím pruhem širokým třetinu délky listu. Z dolního okraje žlutého pole vyrůstá listnatý strom s černým kmenem, v zelené koruně zavinutá střela, uvnitř vinutí zvon, obojí bílé. V modrém pruhu tři bílé vlající klíny vycházející z první, třetí a páté pětiny vlajícího okraje se společným vrcholem v polovině vlajícího okraje žlutého pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z modré paty zelený listnatý strom s černým kmenem. V koruně stromu zavinutá střela, uvnitř vinutí zvon, obojí stříbrné. V patě tři sbíhající se stříbrné hroty."
    },
    {
        obec: "Mirotice",
        kod: 549584,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39801,
        latitude: 49.429185,
        longitude: 14.037078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Mirotice_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou orlicí s kruhovou svatozáří a žlutým zobákem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná orlice s kruhovou svatozáří a zlatým zobákem."
    },
    {
        obec: "Mírov",
        kod: 569381,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.798197,
        longitude: 16.84759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/CoA_of_M%C3%ADrov.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mírová",
        kod: 537934,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35735,
        latitude: 50.237288,
        longitude: 12.770109,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/M%C3%ADrov%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh se třemi žlutými liliemi pod sebou, široký čtvrtinu délky listu a tři vodorovné pruhy, červený, bílý a červený, v poměru 1 : 6 : 1. V bílém pruhu červený ohlížející se drak se žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělený, nahoře modro-červeně polcený, vpravo tři (2, 1) zlaté lilie, vlevo stříbrná hornická kladívka na zlatých topůrkách, v dolním stříbrném poli červený ohlížející se drak se zlatým jazykem."
    },
    {
        obec: "Mírová pod Kozákovem",
        kod: 577316,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.591643,
        longitude: 15.19435
    },
    {
        obec: "Mirovice",
        kod: 549592,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39806,
        latitude: 49.515657,
        longitude: 14.035924,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Mirovice_znak.gif",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým klínem v podobě gotického oblouku s vrcholem na středu vlajícího kraje. Na červeném poli u žerdě bílý dvouocasý lev vysoký i široký 7/10 šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Miřejovice",
        kod: 530506,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.553345,
        longitude: 14.109038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Mi%C5%99ejovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 5 : 1. Z dolního pruhu vyrůstá strom mišpule se třemi (1, 2) plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší strom mišpule se třemi (1, 2) plody, vše přirozené barvy."
    },
    {
        obec: "Miřetice",
        kod: 571831,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53955,
        latitude: 49.841358,
        longitude: 15.884829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Mi%C5%99etice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým klínem vycházejícím z horního okraje a s vrcholem na dolním okraji listu. V klínu černý vrbový kmen, dole žlutě zahrocený, nahoře zlomená větévka se dvěma pupeny mezi dvěma větvemi, každá se čtyřmi zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným klínem černý vrbový kmen, dole zlatě zahrocený, nahoře zlomená větévka se dvěma pupeny mezi dvěma větvemi, každá se čtyřmi zelenými listy."
    },
    {
        obec: "Miřetice",
        kod: 530174,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.659049,
        longitude: 14.976635
    },
    {
        obec: "Mířkov",
        kod: 553956,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.589255,
        longitude: 12.881612,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/M%C3%AD%C5%99kov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, zelený, bílý a zelený, v poměru 3 : 1 : 1 : 1. V žerďové polovině žlutého pruhu knížecí čepice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě knížecí čepice nad zeleným sedmivrším se stříbrným vlnitým břevnem."
    },
    {
        obec: "Miskovice",
        kod: 534200,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28501,
        latitude: 49.946226,
        longitude: 15.204823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Miskovice_KH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým zubatým žerďovým pruhem širokým jednu třetinu délky listu, se čtyřmi obdélníkovými zuby a pěti stejně velkými mezerami. Výška zubu je jednou dvacetinou délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě mezi dvěma stříbrnými cimbuřovými břevny tři stříbrné věže se dvěma černými okny, černými valbovými střechami s makovičkami a prázdnými branami."
    },
    {
        obec: "Místo",
        kod: 563242,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43158,
        latitude: 50.447156,
        longitude: 13.26742,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/M%C3%ADsto_CoA.jpg"
    },
    {
        obec: "Mistrovice",
        kod: 580643,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56164,
        latitude: 50.02377,
        longitude: 16.571618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Mistrovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 2:1. V bílém pruhu květ střevíčníku pantoflíčku, dole provázený dvěma modrými květy lnu se žlutými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá mezi dvěma stonky lnu, každý s jedním modrým květem a zlatým středem, květ střevíčníku pantoflíčku přirozené barvy."
    },
    {
        obec: "Mistřice",
        kod: 592382,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.086778,
        longitude: 17.536075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/CoA_of_Mist%C5%99ice.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou osmicípou hvězdou obklopenou bílým mezikružím širokým jednu devítinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě prázdný stříbrný kruh, uvnitř se zlatou osmihrotou hvězdou, převýšený stříbrným javorovým listem provázeným dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Míškovice",
        kod: 588750,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76852,
        latitude: 49.276669,
        longitude: 17.549103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Miskovice_KM_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Modrý list s červeným kantonem. V kantonu bílý středový kříž s rameny širokými jednu desetinu délky listu s modrou přirozenou lilií uprostřed. V dolní polovině vlající části listu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrný heroldský kříž s modrou přirozenou lilií uprostřed, vlevo nahoře položená zlatá putna s vytékající stříbrnou vodou, provázenou vlevo zlatou osmihrotou hvězdou."
    },
    {
        obec: "Míšov",
        kod: 546399,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33563,
        latitude: 49.622664,
        longitude: 13.725854,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/M%C3%AD%C5%A1ov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý, a bílý vodorovný vlnitý pruh široký desetinu šířky listu. Nad ním v červeném pruhu vztyčený žlutý lipový list a v černém čelně vztyčená bílá radlice, pod ním uprostřed bílý, žlutě zdobený pohár. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-černě polcený štít se stříbrným vlnitým břevnem. Nahoře vztyčený zlatý lipový list a čelně vztyčená stříbrná radlice, dole stříbrný, zlatě zdobený pohár."
    },
    {
        obec: "Mišovice",
        kod: 549606,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.495358,
        longitude: 14.005039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Mi%C5%A1ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 4 : 1 : 4. V zelených pruzích tři žluté obilné klasy bez stébel. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné vlnité břevno provázené šesti (3, 3) zlatými obilnými klasy bez stébel."
    },
    {
        obec: "Mladá Boleslav",
        kod: 535419,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.413525,
        longitude: 14.908538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Mlad%C3%A1_Boleslav_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mladá Vožice",
        kod: 552704,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.53323,
        longitude: 14.808669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Mlad%C3%A1_Vo%C5%BEice_znak.png",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký polovinu délky listu a tři vodorovné pruhy, červený, bílý a červený. V modrém pruhu volná bílá kvádrovaná hradba se třemi zuby, za ní bílá kvádrovaná věž s černým oknem a s červenou valbovou střechou se dvěma dvojitými žlutými makovicemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na skále přirozené barvy volná stříbrná kvádrovaná hradba se třemi stínkami, za ní stříbrná kvádrovaná věž s černým oknem a s červenou valbovou střechou se dvěma dvojitými zlatými makovicemi. Věž provázena dvěma červenými štítky převýšenými zlatými korunami, v pravém stříbrná krokev, v levém český lev."
    },
    {
        obec: "Mladé Bříště",
        kod: 548383,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39443,
        latitude: 49.491565,
        longitude: 15.335272,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Mlad%C3%A9_B%C5%99%C3%AD%C5%A1t%C4%9B_CoA.jpg"
    },
    {
        obec: "Mladecko",
        kod: 553042,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74754,
        latitude: 49.898335,
        longitude: 17.708278,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Mladecko_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílým vykračujícím beranem se žlutou zbrojí podložený žlutou lilií na stonku. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vykračující beran se zlatou zbrojí podložený zlatou lilií na stonku."
    },
    {
        obec: "Mladějov",
        kod: 573205,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50745,
        latitude: 50.482181,
        longitude: 15.232724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Mlad%C4%9Bjov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 4 : 1. V modrém pruhu kosmo berla závitem k žerďovému okraji, šikmo podložená úhelnicí kratším ramenem nahoru a k žerdi, dole mezi nimi devíticípá hvězda, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmo berla šikmo podložená úhelnicí, dole devítihrotá hvězda, vše zlaté."
    },
    {
        obec: "Mladějov na Moravě",
        kod: 578401,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56935,
        latitude: 49.822707,
        longitude: 16.591176,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Mlad%C4%9Bjov_na_Morav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině dva vodorovné pruhy, bílý a červený v poměru 1 : 2, a ve vlající polovině čtyři vodorovné pruhy, střídavě černé a bílé, v poměru 1 : 1 : 1 : 6. V bílém žerďovém pruhu červený středový kříž s rameny širokými devítinu šířky listu. Přes obě dolní pole k vlajícímu okraji obrácený ohlížející se bílo - černě polcený drak. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - stříbrně polcený štít se stříbrno - černě polcenou hlavou. V pravé půli hlavy červený heroldský kříž, v levé půli stříbrné břevno. Ve štítě doleva obrácený ohlížející se stříbrno - černě polcený se drak."
    },
    {
        obec: "Mladé Buky",
        kod: 579548,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54223,
        latitude: 50.606524,
        longitude: 15.833588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Mlad%C3%A9_Buky_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list. V horní části žlutý vykořeněný buk, v dolní části bílá ryba převýšená čtyřmi žlutými šesticípými hvězdami vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nahoře vykořeněný buk, pod ním čtyři hvězdy, vše zlaté, v patě stříbrná ryba."
    },
    {
        obec: "Mladějovice",
        kod: 552348,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.752475,
        longitude: 17.23154,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/CoA_of_Mlad%C4%9Bjovice_%28okres_Olomouc%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a červený, v poměru 8:11:11. V žerďovém pruhu tři bílé vlající klíny s vrcholy na žerďovém okraji, v zeleném pruhu bílá radlice hrotem dolů a ostřím k žerdi, k zelenému pruhu přiléhá ve vlajícím pruhu půl bílé zavinuté střely. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě pod červenou hlavou se třemi kužely vpravo radlice, vlevo vyniká půl zavinuté střely, vše stříbrné."
    },
    {
        obec: "Mladoňovice",
        kod: 591165,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 49.010221,
        longitude: 15.64373,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Znak_obce_Mlado%C5%88ovice_u_T%C5%99eb%C3%AD%C4%8De.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé žerďové pruhy, modrý a červený, každý široký jednu čtvrtinu délky listu a dva vodorovné pruhy, žlutý a bílý. V modrém pruhu půl bílé korunované orlice se žlutou zbrojí, korunou kolem krku a perizoniem, přiléhající k červenému pruhu se dvěma bílými liliemi pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo půl stříbrné korunované orlice se zlatou zbrojí, korunou kolem krku a jetelovitě zakončeným perizoniem, vynikající z poltící linie, vlevo zlatý žernov s vodorovným otvorem pro kypřici pod dvěma stříbrnými liliemi."
    },
    {
        obec: "Mladeč",
        kod: 504246,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78321,
        latitude: 49.705924,
        longitude: 17.01963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Mlade%C4%8D_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě bílých a červených v poměru 2:3:5:3:2. V prostředním pruhu vztyčený zelený dubový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě dvě červené, krky spojené supí hlavy, vyrůstající z jeho dolního okraje a držící ve zlatých zobácích zelené dubové snítky se dvěma lupeny a jedním žaludem. V dolní části zlatý plazící se had se zvednutou hlavou."
    },
    {
        obec: "Mladkov",
        kod: 580651,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56167,
        latitude: 50.096953,
        longitude: 16.627025,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. Z prostředních tří pětin zeleného pruhu vyniká zelené trojvrší, z jehož každého vrchu vyrůstá zelený jehličnatý strom s hnědým kmenem, prostřední vyšší. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mladoňovice",
        kod: 571857,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.887468,
        longitude: 15.724015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Mlado%C5%88ovice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelená žerďová a žlutá vlající část. Uprostřed žluto-černě polcená vykořeněná ostrev o osmi sucích a jedním svěšeným lipovým listem na každé straně, v žerďové části žlutým a ve vlající zeleným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě zlato-černě polcená vykořeněná ostrev o osmi sucích s jedním svěšeným lipovým listem na každé straně, pravý zlatý a levý zelený."
    },
    {
        obec: "Mladotice",
        kod: 559237,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.985347,
        longitude: 13.364505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Coat_of_arms_of_Mladotice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou šesticípou hvězdou v postaveném modrém, žlutě lemovaném vydutém šestiúhelníku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná hvězda v postaveném modrém, zlatě lemovaném vydutém šestiúhelníku."
    },
    {
        obec: "Mladošovice",
        kod: 544817,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 48.940569,
        longitude: 14.69925
    },
    {
        obec: "Mladý Smolivec",
        kod: 558061,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.506411,
        longitude: 13.735393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Mlad%C3%BD_Smolivec_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-žlutě kosmo dělený list. V červeném žerďovém poli žluté vozové kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně kosmo děleném štítě nahoře černá kančí hlava se stříbrnou zbrojí a červeným jazykem, dole zlaté vozové kolo."
    },
    {
        obec: "Mlázovice",
        kod: 573213,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50758,
        latitude: 50.411963,
        longitude: 15.521712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Mlazovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 :1. Ze zeleného pruhu vyniká bílá dole rozšířená věž s cimbuřím, obojí kvádrované, s černým obloukovým vstupem a dvěma černými okny vedle sebe. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mlečice",
        kod: 560006,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.920399,
        longitude: 13.696075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Mle%C4%8Dice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. Uprostřed smuteční vrba opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě smuteční vrba opačných barev."
    },
    {
        obec: "Mlékojedy",
        kod: 565296,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.526999,
        longitude: 14.11933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Mlekojedy_LT_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi, v poměru 5:3. V žerďové polovině červeného pruhu polovina bílého korunovaného dvouocasého lva se žlutou zbrojí, držícího v levé tlapě žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrnou vlnitou patou polovina stříbrného korunovaného dvouocasého lva se zlatou zbrojí, držícího v levé tlapě zlatý obilný klas se dvěma listy."
    },
    {
        obec: "Mlékosrby",
        kod: 570401,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.191824,
        longitude: 15.501571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Ml%C3%A9kosrby_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a modrý, v poměru 3 : 2 : 3. V zeleném bílý tlapatý kříž mezi dvěma žlutými putýnkami s černými obručemi, uchy od sebe, naplněnými mlékem. V modrém pruhu bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na modré vlnité patě zlatá palisáda. Nad palisádou stříbrný tlapatý kříž mezi dvěma zlatými putýnkami s černými obručemi, uchy od sebe, naplněnými mlékem. V patě stříbrné mlýnské kolo."
    },
    {
        obec: "Mlýnské Struhadlo",
        kod: 542059,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.432149,
        longitude: 13.469571
    },
    {
        obec: "Mlýny",
        kod: 552712,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.336335,
        longitude: 14.877147,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Ml%C3%BDny_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V horním pruhu mezi dvěma vztyčenými zelenými dubovými listy červená růže se žlutým semeníkem a zelenými kališními lístky, v dolním žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý seříznutý hrot se zlatým mlýnským kolem. Nad hrotem červená růže se zlatým semeníkem a zelenými kališními lístky. Vše provázeno dvěma vztyčenými zelenými dubovými listy."
    },
    {
        obec: "Mnetěš",
        kod: 565300,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.36565,
        longitude: 14.281839,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Mnetes_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým půlkruhovým polem a červeným křížem se středem ve druhé třetině délky listu s rameny širokými jednu desetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě zelené návrší, vpravo nad ním doleva obrácený zelený drak, vlevo stříbrný praporec s červeným heroldským křížem na šikmém dřevci přirozené barvy."
    },
    {
        obec: "Mnich",
        kod: 548391,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39492,
        latitude: 49.298466,
        longitude: 14.965835
    },
    {
        obec: "Mnichov",
        kod: 536563,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.302187,
        longitude: 13.830176,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/CoA_of_Mnichov_%28okres_Strakonice%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený s bílým maltézským křížem, zelený a žlutý s červenou střelou s modrým hrotem k žerďovému okraji a bílým opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém, červeno-zlatě děleném poli nahoře stříbrný maltézský kříž, dole kosmo červená střela s modrým hrotem a stříbrným opeřením. V levém zeleném poli volná stříbrná kostelní věž s oknem nad vchodem, obojí obloukové a černé, a s černou stanovou střechou se zlatou makovicí a křížkem."
    },
    {
        obec: "Mnichov",
        kod: 554677,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 50.03698,
        longitude: 12.787043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/CoA_Mnichov_Cheb.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým černým čtvercovým polem a dva vodorovné pruhy, bílý a červený. V karé bílý dvouocasý lev se žlutou zbrojí, v černém poli koruna, ze které vyrůstají dva parohy, vše žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mnichov",
        kod: 566292,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.49683,
        longitude: 12.764531
    },
    {
        obec: "Mnichovice",
        kod: 530191,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.668707,
        longitude: 15.039688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Mnichovice_BN_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým zubatým žerďovým pruhem širokým čtvrtinu délky listu, se čtyřmi zuby vysokými třetinu šířky pruhu a třemi stejnými mezerami. Ve střední části listu bílý čtyřlistý květ se žlutým středem a třemi žlutými kališními lístky na žlutém stonku se dvěma listy prohnutém k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vpravo cimbuřově polcený štít. Vlevo stříbrný čtyřlistý květ se zlatým středem a třemi zlatými kališními lístky na zlatém prohnutém stonku se dvěma listy."
    },
    {
        obec: "Mnichovice",
        kod: 538493,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25164,
        latitude: 49.936117,
        longitude: 14.709175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Mnichovice%2C_znak.jpg",
        coatOfArmsFlagDescription: "V červeném listu rytíř v plátové zbroji se zavřenou přilbou, s taseným mečem v pravici a s levicí v bok, stojící na hlavách dvou přivrácených pštrosů držících mezi sebou v zobácích drátěnou košili, vše bílé. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mnichovo Hradiště",
        kod: 536326,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.527029,
        longitude: 14.972104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Mnichovo_Hradiste_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý červený žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů, střídavě červených a bílých. Na žerďovém pruhu žlutý ondřejský klíč s rameny vycházejícími z horního a dolního červeného pruhu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mníšek",
        kod: 564231,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46331,
        latitude: 50.831733,
        longitude: 15.056405,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Znakmnisek.png",
        coatOfArmsFlagDescription: "List tvoří bílé karé nad stejně velkým červeným čtvercovým polem a dva vodorovné pruhy, zelený a bílý. V karé černý růženec, pětkrát přerušený červenou růží se zlatým semeníkem. Uprostřed růžence zelená vztyčená kvetoucí větévka tisu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý růženec, pětkrát přerušený červenou růží se zlatým semeníkem. Uprostřed vztyčená kvetoucí větévka tisu přirozené barvy."
    },
    {
        obec: "Mníšek pod Brdy",
        kod: 540765,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.866601,
        longitude: 14.261865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/CoA_of_Mn%C3%AD%C5%A1ek_pod_Brdy.svg",
        coatOfArmsFlagDescription: "List tvoří červené čtvercové pole s bílým dvouocasým lvem se žlutou zbrojí a jazykem a bílá vlající část. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Močerady",
        kod: 599174,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.518078,
        longitude: 13.073554
    },
    {
        obec: "Močovice",
        kod: 530999,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.906414,
        longitude: 15.348298
    },
    {
        obec: "Modlany",
        kod: 567710,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41713,
        latitude: 50.650606,
        longitude: 13.895449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Modlany_vznak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě žluto-černo-žlutě polcených v poměru 1 : 1 : 4 a černo-zeleně polcených v poměru 1 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - černě cihlovaném štítě hlava sv. Apolináře Ravennského přirozené barvy provázená dole obilným klasem a palmovou ratolestí, obojí zelené."
    },
    {
        obec: "Modletice",
        kod: 598267,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.97026,
        longitude: 14.591186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Modletice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s kosmým žlutým pruhem, širokým šestinu délky listu, vycházejícím z horní části žerďového okraje do vlající části dolního okraje. Nad pruhem kosmo kráčející žlutý dvouocasý lev s červenou zbrojí a jazykem, držící v levé přední tlapě vztyčený bílý list kaštanovníku, pod pruhem šesticípá žlutá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě kosmo děleném štítě nahoře zlatý kráčející dvouocasý lev s červenou zbrojí držící v levé tlapě stříbrný list kaštanovníku, dole modré orlí křídlo se zlatou hvězdou."
    },
    {
        obec: "Modlíkov",
        kod: 569127,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.608287,
        longitude: 15.770401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Modl%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se šikmým bílým pruhem širokým jednu pětinu šířky listu. V horním rohu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě doprava vyniká půl modrého orla se zlatou zbrojí, doleva půl stříbrné lilie."
    },
    {
        obec: "Modrá",
        kod: 592391,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68706,
        latitude: 49.112121,
        longitude: 17.403508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Coa_Czech_Town_Modr%C3%A1.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou ovcí stojící na bílém plochém kameni. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném plochém kameni stříbrná ovce."
    },
    {
        obec: "Modrá Hůrka",
        kod: 598615,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.180142,
        longitude: 14.525766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Modr%C3%A1_H%C5%AFrka_CoA.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem širokým pětinu šířky listu na červené žerďové a modré vlající pole. V červeném poli lilie, nahoře provázená dvěma šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad modrým, zlatě lemovaným vrchem lilie, nahoře provázená dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Modrava",
        kod: 542148,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34192,
        latitude: 49.024173,
        longitude: 13.499417,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Modrava_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílo-modře šachovaný (2,9) a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dvakrát polcený štít, vpravo zelený, uprostřed devětkrát stříbrno-modře šachovaný, vlevo červený, vše kosmo přeloženo zlatou sklářskou píšťalou se stříbrnou sklovinou dole."
    },
    {
        obec: "Modřice",
        kod: 583391,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66442,
        latitude: 49.12799,
        longitude: 16.614517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Modrice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - červený, zelený a červený, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vztyčená zelená dubová větev střídavě s jedním vztyčeným a dvěma odkloněnými zlatými žaludy a čtyřmi zelenými listy."
    },
    {
        obec: "Modřišice",
        kod: 577324,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.574176,
        longitude: 15.119437,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Mod%C5%99i%C5%A1ice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červeno - bíle kosmo dělený list s modrým středovým svislým pruhem širokým jednu třetinu délky listu, provázeným v žerďové části žlutým a ve vlající modrým platanovým listem. V modrém pruhu bílá zvonice na soklu, s červenou stanovou střechou zakončenou žlutou makovicí s křížkem, s dveřmi a nahoře zahroceným oknem, obojí černé. V okně zavěšený žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně kosmo děleném štítě modrý kůl provázený dvěma platanovými listy, vpravo zlatým a vlevo modrým.V kůlu stříbrná zvonice na soklu, s červenou stanovou střechou se zlatou makovicí s křížkem, dveřmi a oknem nahoře zahroceným, obojí černé. V okně zavěšen zlatý zvon."
    },
    {
        obec: "Modřovice",
        kod: 599751,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.617462,
        longitude: 13.956485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Mod%C5%99ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1 : 2 : 1. V bílém pruhu modrý kolčí helm s klenotem tvořeným černým křídlem s bílým břevnem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít. Vpravo stříbrný volský roh provázený vpravo nahoře zlatým křížkem, vlevo kolčí helm s klenotem tvořeným černým křídlem se stříbrným břevnem."
    },
    {
        obec: "Mohelnice",
        kod: 558079,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.48089,
        longitude: 13.641798,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Mohelnice_%28Plze%C5%88-South_District%29_CoA.jpg"
    },
    {
        obec: "Mohelnice",
        kod: 540471,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78985,
        latitude: 49.777082,
        longitude: 16.919567,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Mohelnice_SU_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1:8:1. Uprostřed šest bílých rovnoramenných trojúhelníků (4,2). Základny trojúhelníků se rovnají jedné pětině šířky listu a výšky třem desetinám šířky listu. Trojúhelníky se vzájemně dotýkají základnami a dolní navíc svými vrcholy základen horních středních trojúhelníků. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mohelnice nad Jizerou",
        kod: 565822,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29413,
        latitude: 50.559891,
        longitude: 14.977625
    },
    {
        obec: "Mohelno",
        kod: 591173,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67575,
        latitude: 49.114222,
        longitude: 16.190323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Mohelno_znak.jpg",
        coatOfArmsFlagDescription: "Černý list se žlutým kosmým pruhem širokým polovinu šířky listu. Ve žlutém pruhu na zeleném trávníku, přiléhajícím k žerďovému černému poli, sedí hnědý daněk s parohy zasahujícími do vlajícího černého pole. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mochov",
        kod: 538507,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25087,
        latitude: 50.141925,
        longitude: 14.795066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Mochov_CoA_CZ.jpg"
    },
    {
        obec: "Mochtín",
        kod: 556718,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.360477,
        longitude: 13.357005
    },
    {
        obec: "Mojné",
        kod: 598623,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.834818,
        longitude: 14.407373,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Mojn%C3%A9%2C_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s vlajícím zeleným klínem s vrcholem ve středu listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad sníženým zeleným hrotem vpravo černá trnová koruna, vlevo kosmo červená střela s modrým hrotem a stříbrným opeřením. V hrotu stříbrný kůň ve skoku se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Mokošín",
        kod: 575381,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.014912,
        longitude: 15.572047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Moko%C5%A1%C3%ADn_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě modrých a žlutých, v poměru 4 : 1 : 1 : 1 : 1. U žerďového okraje bílá bohyně Mokoša držící v levé ruce vřeteno a přidržující pravou rukou předivo zakončené dole přeslenem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná bohyně Mokoša, držící v levé ruce vřeteno a přidržující pravou rukou předivo zakončené dole přeslenem, vše zlaté, podložená dvěma sníženými zúženými zlatými vlnitými břevny a provázená dvěma zlatými odkloněnými obilnými klasy, vyrůstajícími z horního břevna."
    },
    {
        obec: "Mokrá-Horákov",
        kod: 583405,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66404,
        latitude: 49.22287,
        longitude: 16.751677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Znak_obce_Mokr%C3%A1_-_Hor%C3%A1kov.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, bílý a modrý v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se zúženým stříbrným břevnem. Nahoře zkřížený stříbrný klíč se stříbrným mečem se zlatou rukojetí. Dole stříbrná sekyra se zlatým topůrkem provázená dvěma zlatými žaludy."
    },
    {
        obec: "Mokré",
        kod: 576522,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.259297,
        longitude: 16.066529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Znak_obce_Mokr%C3%A9.jpg",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. Žerďové pole vyplňuje vykořeněná lípa s hnědým kmenem v žerďové části a se zelenými listy. Ve vlajícím poli dva bílé šikmé pruhy dělící okraje listu, v poměru 3 : 2 : 3 : 2 : 7. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo děleném štítě nahoře vykořeněná lípa přirozené barvy, dole šikmo postavená radlice obrácená doleva a pod ní šikmo krojidlo, obojí stříbrné, provázené vlevo nahoře a vpravo dole zlatou osmihrotou hvězdou."
    },
    {
        obec: "Mokré Lazce",
        kod: 508373,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74762,
        latitude: 49.904714,
        longitude: 18.029644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Mokr%C3%A9_Lazce_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý s pěti vrcholy a šesti prohlubněmi a modrý v poměru 5:1:2. Uprostřed šikmo položené hrábě podložené vidlemi se čtyřmi hroty, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným vlnitým břevnem šikmo položené vidle křížem přeložené hráběmi, obojí zlaté."
    },
    {
        obec: "Mokrosuky",
        kod: 556726,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.275464,
        longitude: 13.454904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Mokrosuky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 2 : 1 : 2. Bílý pruh nahoře vlnkovitý, dole ostrvový se čtyřmi suky k žerďovému okraji listu, sahajícími do sedmi desetin šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně dělený štít stříbrným břevnem, nahoře vlnkovitým, dole se čtyřmi šikmými suky. Nad ním vztyčený lipový dvojlist se dvěma listeny s plodenstvím, pod ním vztyčený dubový dvojlist s žaludem uprostřed, vše stříbrné."
    },
    {
        obec: "Mokrouše",
        kod: 540641,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33201,
        latitude: 49.715159,
        longitude: 13.524657,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Mokrou%C5%A1e_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký jednu čtvrtinu délky listu, s devíti modrými kapkami (2, 1 střídavě), a modré pole se žlutou rybou vztyčenou do oblouku, držící v tlamě bílý zvon se závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou s devíti (5, 4) modrými kapkami zlatá do oblouku vztyčená ryba držící v tlamě stříbrný zvon se závěsem."
    },
    {
        obec: "Mokrovousy",
        kod: 570419,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.27513,
        longitude: 15.68594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/CoA_of_Mokrovousy.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 2:1:2. V horním modrém pruhu pluh s koly, v dolním modrém pruhu dvě zkřížené radlice ostřím k sobě a hroty k dolnímu okraji listu, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno, nad ním zlatý pluh s koly, pod ním zkřížené zlaté radlice (krojidla)."
    },
    {
        obec: "Mokrovraty",
        kod: 540781,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.795772,
        longitude: 14.25023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Mokrovraty_%28znak%29.jpg",
        coatOfArmsFlagDescription: "List žluto-modře dělený šikmým zvlněným řezem. Ve žlutém poli k vlajícímu okraji obrácený modrý buchar hamru s kovadlinou, v modrém poli žlutá kvádrovaná kolomazná pec se třemi černými otvory, prostředním větším. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře šikmo vlnitě děleném štítě nahoře doleva obrácený modrý buchar hamru s kovadlinou, dole zlatá kvádrovaná kolomazná pec se třemi černými otvory, prostředním větším."
    },
    {
        obec: "Mokrý Lom",
        kod: 535842,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.844884,
        longitude: 14.514153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Mokry_Lom_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a vlnitých modrých se dvěma vrcholy a třemi prohlubněmi, v poměru 11 : 1 : 1 : 1 : 2. V horním bílém pruhu modrá krokev vycházející z páté osminy žerďového a vlajícího okraje, s vrcholem v jedné osmině šířky listu, mezi třemi (2, 1) červenými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá snížená krokev mezi třemi (2, 1) červenými růžemi se zlatými semeníky nad třikrát modro-stříbrně vlnitě dělenou vlnitou patou."
    },
    {
        obec: "Moldava",
        kod: 567728,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41781,
        latitude: 50.720738,
        longitude: 13.657342,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Moldava_znak.gif",
        coatOfArmsFlagDescription: "Modrý list s žerďovým červeným pruhem širokým jednu třetinu délky listu. V pruhu třikrát pravoúhle lomený bílý pruh vycházející z druhé šestiny horního okraje do druhé šestiny dolního okraje listu a dotýkající se svými vrcholy okrajů červeného pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně sníženě dělený štít, v horním modrém poli třikrát lomené stříbrné břevno provázené zlatým latinským křížkem. V dolním poli zkřížené stříbrné sklářské píšťaly."
    },
    {
        obec: "Morašice",
        kod: 594474,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67171,
        latitude: 48.957631,
        longitude: 16.209004,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, červený a zelený. V černém osmihrotý kříž nad šesticípou hvězdou, obojí červené, v červeném pruhu bílá lilie, v zeleném šikmo žlutý vinný hrozen s jedním listem a úponkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli rozevřená kniha nad lilií, obojí stříbrné, levé pole černo-zeleně dělené, nahoře osmihrotý kříž nad hvězdou, obojí červené, dole šikmý vinný hrozen s listem a jedním úponkem, vše zlaté."
    },
    {
        obec: "Morašice",
        kod: 571873,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53802,
        latitude: 49.934791,
        longitude: 15.709072,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Mora%C5%A1ice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří černo-žlutě šachovaný (5x2) žerďový pruh a modro-bíle šikmo dělené pole. V modrém poli bílé lekno, v bílém černá utržená kohoutí hlava s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina černo-zlatě dvěma řadami šachovaná. Dolní polovina modro-stříbrně polcena, vpravo stříbrné lekno, vlevo černá utržená kohoutí hlava s červenou zbrojí."
    },
    {
        obec: "Morašice",
        kod: 573035,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.001981,
        longitude: 15.486111,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Mora%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 7 : 1, horní zelený a dolní tvořený střídavě bílými a černými čtvercovými poli. V žerďové polovině zeleného pruhu bílý beránek Boží se žlutou zbrojí a s bílou třícípou korouhví s červeným kalichem zavěšenou na příčném ráhnu žluté žerdi zakončené křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrno-černě šachovanou patou stříbrný beránek Boží se zlatou zbrojí a se stříbrnou třícípou korouhví s červeným kalichem zavěšenou na příčném ráhnu zlaté žerdi zakončené křížkem."
    },
    {
        obec: "Morašice",
        kod: 578428,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56951,
        latitude: 49.867799,
        longitude: 16.233701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Mora%C5%A1ice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří osm svislých pruhů, bílý se dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky nad sebou, červený s bílou lilií, bílý, červený, žlutý, červený, bílý a červený, v poměru 6:6:1:1:1:1:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený červený vydutý hrot, v něm vztyčená stříbrná střela šikmo přeložená zlatým gotickým klíčem a kosmo stříbrným mečem se zlatým jílcem, převýšená stříbrnou lilií. V rozích štítu dvě červené růže se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Moravany",
        kod: 583413,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66448,
        latitude: 49.14566,
        longitude: 16.576269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Moravany_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsDescription: "V modrém štítě nahoře z jednoho stonku vyrůstají dva listy, vpravo lipový, vlevo dubový, provázené dole stojícími krojidlem a vinařským nožem, ostřím doleva. Vše zlaté."
    },
    {
        obec: "Moravany",
        kod: 586391,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69650,
        latitude: 49.040216,
        longitude: 17.169127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Moravany_HO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Bílý list se dvěma žerďovými klíny, černým a červeným, s vrcholy v první čtvrtině délky listu a se dvěma vlajícími klíny, červeným a černým s vrcholy ve třetí čtvrtině délky listu a s červeno-černě čtvrceným kosočtvercem s vrcholy ve středech okrajů listu. V kosočtverci bílá růže se žlutým semeníkem ve žlutém mezikruží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo pod sebou stříbrná růže se zlatým semeníkem, položený stříbrný vinařský nůž - kosíř se zlatou rukojetí a stříbrný vinný hrozen na zlatém stonku, vlevo modrá, zlatě zdobená hrncová přilba v klenotu s černým křídlem se zlatým kruhem uprostřed."
    },
    {
        obec: "Moravany",
        kod: 575399,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53372,
        latitude: 50.00105,
        longitude: 15.940811,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Moravany_%28Pardubice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový červeno-bíle šachovaný (6x3) pruh a modré pole s kosmo položeným gotickým klíčem zuby nahoru a k žerdi, šikmo podloženým mečem hrotem k hornímu cípu, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno-červeně dvěma řadami šachovanou patou kosmo položený gotický klíč zuby dolů a ven, šikmo podložený vztyčeným mečem, obojí zlaté."
    },
    {
        obec: "Moravec",
        kod: 596141,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59254,
        latitude: 49.440204,
        longitude: 16.143415,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Moravec_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s červeným horním rohovým trojúhelníkem s odvěsnami rovnými šířce listu. V červeném poli bílá růže, ve žlutém modrý latinský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě zvýšeně šikmo děleném štítě nahoře stříbrná růže, dole modrý latinský kříž."
    },
    {
        obec: "Moravecké Pavlovice",
        kod: 596159,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59262,
        latitude: 49.425534,
        longitude: 16.256425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/CoA_of_Moraveck%C3%A9_Pavlovice.svg",
        coatOfArmsFlagDescription: "Bílý list se třemi červenými jablky pod sebou s osou na čtvrtině délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným odsazeným kůlem tři (2,1) červená jablka."
    },
    {
        obec: "Moraveč",
        kod: 548405,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.409182,
        longitude: 15.076977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Morave%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 2 : 1. V červeném pruhu čelně bílá beraní hlava. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě čelně stříbrná beraní hlava nad zeleným dvojvrším."
    },
    {
        obec: "Moravice",
        kod: 569097,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74784,
        latitude: 49.857852,
        longitude: 17.720204,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Moravice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký jednu čtvrtinu délky listu se třemi červenými srdci pod sebou a červené pole s kosmo položenou kosou šikmo podloženou sekerou, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou hlavou se třemi červenými srdci kosmo položená kosa šikmo podložená sekerou, obojí stříbrné."
    },
    {
        obec: "Morávka",
        kod: 598445,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73904,
        latitude: 49.596169,
        longitude: 18.524824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Moravka_-_ofici%C3%A1ln%C3%AD_znak.png",
        coatOfArmsFlagDescription: "Zelený list s bílým vykračujícím kozlem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vykračující kozel."
    },
    {
        obec: "Moravičany",
        kod: 540480,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78982,
        latitude: 49.757034,
        longitude: 16.960527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Moravi%C4%8Dany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 9 : 14 : 9. V bílém pruhu modrý kohout s červenou zbrojí nad modrým kaprem s červenými ploutvemi, plujícím k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně modrým zúženým břevnem dělený štít, nahoře modrý zúžený kůl provázený vpravo dvěma modrými kapry s červenými ploutvemi plujícími nad sebou doleva, vlevo modrý kohout s červenou zbrojí, dole stříbrný kůl."
    },
    {
        obec: "Moravská Nová Ves",
        kod: 584665,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69155,
        latitude: 48.803142,
        longitude: 17.013814,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Coat_of_arms_of_Moravska_Nova_Ves.jpeg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů střídavě červených a žlutých v poměru 1:1:2:2:1:1. Ve dvou středních pruzích je po dvou vinných hroznech pod sebou, opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě ze středu vyrůstající dva révové pruty, každý se dvěma hrozny a dvěma listy opačných barev, provázené vpravo zlatou radlicí, vlevo červeným vinařským nožem hrotem dolů."
    },
    {
        obec: "Moravská Třebová",
        kod: 578444,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.757697,
        longitude: 16.663631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Moravsk%C3%A1_T%C5%99ebov%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Na modrém listu praporu je položena orlice z městského znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně šachovaná korunovaná orlice se zlatou zbrojí, s červeným jazykem,nese na hrudi modrý štítek se zlatou majuskulní literou T."
    },
    {
        obec: "Moravské Bránice",
        kod: 583421,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.083374,
        longitude: 16.436388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Moravsk%C3%A9_Br%C3%A1nice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed zkřížené bílé vinařské nože - kosíře se žlutými rukojeťmi, provázené nahoře bílým čtyřlistým květem se žlutým středem na žlutém stonku se dvěma listy, dole žlutým vinným hroznem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě zkřížené stříbrné vinařské nože - kosíře se zlatými rukojeťmi, provázené nahoře stříbrným čtyřlistým květem se zlatým středem na zlatém stonku se dvěma listy, dole zlatým vinným hroznem."
    },
    {
        obec: "Moravské Budějovice",
        kod: 591181,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67602,
        latitude: 49.05219,
        longitude: 15.808746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Moravsk%C3%A9_Bud%C4%9Bjovice_%28znak%29.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, žlutý a bílý v poměru 1:2:1. Ve žlutém pruhu dvě černé zkřížené ostrve o šesti sucích, jejichž šířka se rovná jedné dvanáctině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítu stříbrná kvádrová hradba s cimbuřím a černě prolomenou bránou, v níž je zlatá vytažená mříž a po stranách otevřená zlatá vrata s černým kováním. Nad hradbou se zvedají dvě stříbrné kulaté kvádrové věže s cimbuřím, každá s jedním černě prolomeným oknem. Nad bránou je zlatý štít se dvěma černými zkříženými ostrvemi, o něž se levou rukou opírá zbrojnoš v brnění, držící v pravé zvednuté ruce tasený meč, vše přirozené barvy."
    },
    {
        obec: "Moravské Knínice",
        kod: 583430,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66434,
        latitude: 49.293382,
        longitude: 16.50184,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Moravsk%C3%A9_Kn%C3%ADnice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu u středu listu krojidlo hrotem dolů a ostřím k žerdi, v červeném pruhu u středu listu vinařský nůž hrotem nahoru a ostřím k vlajícímu okraji, kolem nich kruhovitě osmnáct hvězd, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo radlice, vlevo vztyčený vinařský nůž, obklopené osmnácti pětihrotými hvězdami, vše stříbrné."
    },
    {
        obec: "Moravské Málkovice",
        kod: 593346,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.257021,
        longitude: 17.090766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Moravsk%C3%A9_M%C3%A1lkovice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílo-červeně šachovaný (3x9) pruh a zelené pole se žlutými polními branami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrno-červeně třemi řadami šachovanou hlavou zlaté polní brány."
    },
    {
        obec: "Moravskoslezský Kočov",
        kod: 551732,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.960503,
        longitude: 17.441174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Moravskoslezsk%C3%BD_Ko%C4%8Dov_znak.png",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část se žlutým květem lnu podloženým bílo-červeně šachovaným plamenným mečem hrotem dolů. Vlající část tvoří dva vodorovné pruhy, horní bílo-červeně šachovaný ve čtyřech řadách, dolní žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý květ lnu podložený stříbro-červeně šachovaným plamenným mečem."
    },
    {
        obec: "Moravský Beroun",
        kod: 597678,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79305,
        latitude: 49.79384,
        longitude: 17.442222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Moravsky_beroun_znak.jpeg"
    },
    {
        obec: "Moravský Krumlov",
        kod: 594482,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.049031,
        longitude: 16.311798,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Coat_of_Arms_of_Moravsk%C3%BD_Krumlov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 6 : 1. Ve střední části modrého pruhu stojí na zeleném pruhu bílá kvádrovaná věž s cimbuřím, s prázdnou branou s otevřenými vraty na bílých závěsech, s vytaženou mříží, obojí žluté, prázdným oknem, a s červenou stanovou střechou se žlutou makovicí. Nad branou knížecí korunou korunovaný žluto - červeně dělený štítek. Věž provázejí dva červené štítky s bílou zavinutou střelou. Poměr délky k šířce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrná kvádrovaná věž s cimbuřím, s prázdnou branou s otevřenými vraty na stříbrných závěsech, a s vytaženou mříží, obojí zlaté, prázdným oknem, s červenou stanovou střechou se zlatou makovicí. Nad branou knížecí korunou korunovaný zlato - červeně dělený štítek. Věž provázejí dva červené štítky se stříbrnou zavinutou střelou."
    },
    {
        obec: "Moravský Písek",
        kod: 586404,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69685,
        latitude: 48.990264,
        longitude: 17.332795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Moravsk%C3%BD_P%C3%ADsek_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Žluto-modře šikmo dělený list. Ve žlutém poli zelený vinný hrozen, v modrém žlutý kapr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zlato-modře vlnou děleném štítě nahoře zelený vinný hrozen, dole zlatý kapr."
    },
    {
        obec: "Moravský Žižkov",
        kod: 584673,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69101,
        latitude: 48.832952,
        longitude: 16.931502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/CoA_of_Moravsk%C3%BD_%C5%BDi%C5%BEkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený se třemi bílými liliemi (2,1) a žlutý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad třemi (2,1) stříbrnými liliemi vedle sebe vztyčená radlice a vinný hrozen, obojí zlaté."
    },
    {
        obec: "Morkovice-Slížany",
        kod: 588768,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.249941,
        longitude: 17.209733,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Morkovice-Slizany_CZ_znak.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým lemem širokým jednu devítinu šířky listu. Uprostřed listu tři (2,1) bílé lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlaté váhy přeložené postaveným zlatým mečem. Nahoře po stranách a dole na meči po jedné stříbrné lilii."
    },
    {
        obec: "Morkůvky",
        kod: 584681,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69172,
        latitude: 48.966228,
        longitude: 16.862319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Coat_of_arms_of_Mork%C5%AFvky.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. U žerdi dva žluté vinné hrozny nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně dělený štít, nahoře dva zlaté vinné hrozny, dole vztyčená radlice a kosíř, obojí stříbrné a přivrácené."
    },
    {
        obec: "Mořice",
        kod: 589721,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79828,
        latitude: 49.330574,
        longitude: 17.196995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Mo%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý, v poměru 2:1:4:1:2. V červeném pruhu žerďový bílý klín s vrcholem na vlajícím okraji, s modrým mečem se žlutou jílcem a záštitou hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít se stříbrným hrotem, v něm vztyčený modrý meč se zlatým jílcem a záštitou, vpravo stříbrná lilie vlevo zlatá křepelka."
    },
    {
        obec: "Mořina",
        kod: 531545,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26717,
        latitude: 49.95294,
        longitude: 14.20874
    },
    {
        obec: "Mořinka",
        kod: 533912,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.939081,
        longitude: 14.237277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Mo%C5%99inka_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se šikmým bílým pruhem vycházejícím z první šestiny dolního okraje do šesté šestiny horního okraje listu. V žerďové části koruna, ve vlající zvon, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížená zúžená krokev se dvěma do stran vyrůstajícími lipovými listy, nad ní koruna a pod ní zvon, vše stříbrné."
    },
    {
        obec: "Mořkov",
        kod: 599689,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74272,
        latitude: 49.536937,
        longitude: 18.059792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Morkov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým žerďovým pruhem se čtyřmi čtvercovými zuby a třemi čtvercovými mezerami širokým jednu čtvrtinu délky listu. V zeleném poli žlutý stojící srnec. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrnou cimbuřovou hlavou zlatý stojící srnec."
    },
    {
        obec: "Mostek",
        kod: 579556,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54475,
        latitude: 50.486326,
        longitude: 15.696323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Mostek_CoA_TU_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list, v horní polovině žerďové a střední části volný kvádrovaný bílý mostek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvě zúžená břevna, nad nimi volný kvádrovaný mostek, pod nimi mlýnský kámen s položenou černou kypřicí, vše stříbrné."
    },
    {
        obec: "Most",
        kod: 567027,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.501655,
        longitude: 13.633012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Most_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů střídavě modrých a bílých, v poměru 15:11. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mostek",
        kod: 580660,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.009486,
        longitude: 16.265146
    },
    {
        obec: "Mostkovice",
        kod: 589730,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79802,
        latitude: 49.472246,
        longitude: 17.052223,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Coat_of_arms_of_Mostkovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. V červeném dva bílé žerďové klíny s vrcholy sahajícími do poloviny délky listu, mezi nimi žlutá lilie. V modrém dva bílé vlnité pruhy vycházející ze druhé a čtvrté pětiny vlajícího okraje. Pruhy mají tři vrcholy a čtyři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře mezi dvěma stříbrnými kužely zlatá lilie, dole dvě zvlněná stříbrná břevna."
    },
    {
        obec: "Mosty u Jablunkova",
        kod: 598453,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73998,
        latitude: 49.527472,
        longitude: 18.754281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Mosty_u_Jablunkova-znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, žlutý a bílý v poměru 1:2:1, ve žlutém poli na dolním okraji listu červený most se dvěma oblouky, z něhož vyrůstá slezská orlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červený cihlový most se dvěma oblouky, z něhož vyrůstá slezská orlice."
    },
    {
        obec: "Mošnov",
        kod: 568686,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74251,
        latitude: 49.689142,
        longitude: 18.132574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Mo%C5%A1nov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma zelenými trojúhelníky na dolním okraji, které jsou vymezeny žlutým, třikrát lomeným pruhem vycházejícím z první do desáté desetiny dolního okraje. Dva vrcholy lomeného pruhu sahají do poloviny šířky listu, prostřední vrchol se dotýká dolního okraje. Mezi vrcholy bílé kruhové pole, v horní části listu žluté křídlo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté křídlo nad stříbrnou perlou mezi dvěma zkrácenými zelenými hroty."
    },
    {
        obec: "Mouchnice",
        kod: 593354,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.111822,
        longitude: 17.135627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Mouchnice-znak.jpg",
        coatOfArmsFlagDescription: "Bílo-červeně kosmo dělený list kosmou kvádrovanou zubatou hradbou s pěti zuby a čtyřmi stejnými mezerami. V žerďové části v hradbě modrý vinný hrozen na zeleném stonku, nad hradbou ve vlající polovině zkřížené bílé válečné sekery se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad kosmou stříbrnou kvádrovanou hradbou s cimbuřím zkřížené stříbrné válečné sekery se zlatými topůrky, v hradbě modrý vinný hrozen na zeleném stonku."
    },
    {
        obec: "Mouřínov",
        kod: 557048,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.121563,
        longitude: 16.978594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Mou%C5%99%C3%ADnov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed žlutá váza se zkříženými modrými sekerami na hnědých topůrkách, provázená dvěma přivrácenými bílými vykořeněnými lekny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatá váza se zkříženými modrými sekerami na dřevěných topůrkách přirozené barvy, provázená dvěma přivrácenými stříbrnými vykořeněnými lekny."
    },
    {
        obec: "Moutnice",
        kod: 583448,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66455,
        latitude: 49.049337,
        longitude: 16.73752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Znak_obce_Moutnice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem na vlajícím okraji. V žerďové části červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot, v něm červená růže na zeleném stonku o čtyřech listech, provázený vpravo vztyčeným šípem a vlevo vinařským nožem, obojí zlaté."
    },
    {
        obec: "Mrač",
        kod: 530204,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25721,
        latitude: 49.829754,
        longitude: 14.690616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/CoA_of_Mra%C4%8D.svg",
        coatOfArmsFlagDescription: "Bílý list s žerďovým zubatým pruhem širokým desetinu délky listu se dvěma zuby sahajícími do šestiny délky listu a třemi stejnými mezerami, a zavinutou střelou uprostřed listu, obojí červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod cimbuřovou hlavou zavinutá střela, obojí červené."
    },
    {
        obec: "Mrákotín",
        kod: 587541,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58854,
        latitude: 49.189556,
        longitude: 15.376196,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Mr%C3%A1kot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1:2:1. V modrém pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky a dva bílé klíny, žerďový a vlající, každý s vrcholem v třetině délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mrákotín",
        kod: 554847,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.814666,
        longitude: 15.95683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Mr%C3%A1kot%C3%ADn_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným pegasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený pegas."
    },
    {
        obec: "Mrákov",
        kod: 553981,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34501,
        latitude: 49.403252,
        longitude: 12.951311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Mr%C3%A1kov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a červený, v poměru 11 : 2 : 11. V zeleném pruhu žlutá koruna prostrčená dvěma odvrácenými bílými čakany, v červeném pruhu rošt přeložený klíčem s oboustrannými zuby k hornímu okraji listu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít se stříbrným kvádrovaným mostem se třemi oblouky. Nad mostem vpravo zlatá koruna prostrčená dvěma odvrácenými stříbrnými čakany, vlevo rošt přeložený klíčem s oboustrannými zuby, vše zlaté."
    },
    {
        obec: "Mratín",
        kod: 538515,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.203151,
        longitude: 14.551084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Coats_of_arms_Mrat%C3%ADn.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh se žlutým plamenným mečem hrotem dolů, široký jednu čtvrtinu délky listu, a modré pole se vztyčeným bílo - červeně šachovaným listem jírovce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou s položeným zlatým plamenným mečem vztyčený stříbrno - červeně šachovaný list jírovce."
    },
    {
        obec: "Mrlínek",
        kod: 553905,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.428534,
        longitude: 17.678364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Mrl%C3%ADnek_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým jelenem ve skoku s červenou zbrojí a žerďovým žlutým zubatým pruhem širokým jednu pětinu délky listu se čtyřmi čtvercovými zuby a třemi stejnými mezerami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad vyrůstající kvádrovou věží s cimbuřím stříbrný jelen ve skoku s červenou zbrojí."
    },
    {
        obec: "Mrtník",
        kod: 559245,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.895538,
        longitude: 13.312306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Mrtn%C3%ADk_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Do modrého pruhu vyrůstá bílé trojvrší, v horním rohu kosmý a v horním cípu šikmý obilný klas, oba žluté. V zeleném pruhu položený žlutý svazek klestí (mrtě). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně děleném štítě nahoře stříbrné trojvrší, nad krajními vrchy kosmo a šikmo položený zlatý obilný klas, dole položený zlatý svazek klestí (mrtě)."
    },
    {
        obec: "Mrsklesy",
        kod: 554944,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78365,
        latitude: 49.602712,
        longitude: 17.401657,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Mrsklesy_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V bílém pruhu černá plamenná orlice s červenou zbrojí, žlutým jazykem a žlutým jetelovitě zakončeným perizoniem, provázená dvěma vztyčenými černými šípy s červenými hroty a opeřením. V zeleném pruhu bílé zkřížené sekery-širočiny na žlutých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně sníženě děleném štítě nahoře černá plamenná orlice s červenou zbrojí, zlatým zobákem a zlatým jetelovitě zakončeným perizoniem provázená dvěma vztyčenými černými šípy s červenými hroty a opeřením, dole zkřížené stříbrné sekery-širočiny na zlatých topůrkách."
    },
    {
        obec: "Mrzky",
        kod: 513288,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.044722,
        longitude: 14.810127
    },
    {
        obec: "Mříčná",
        kod: 577332,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51204,
        latitude: 50.600813,
        longitude: 15.468897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/M%C5%99i%C4%8Dn%C3%A1_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří bílo-červeně čtvrcený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, bílý a zelený. V bílém žerďovém poli zelený vykořeněný smrk s hnědým kmenem na zeleném návrší. V horním červeném poli žlutý prsten s modrým kamenem, dole přeložený bílým mečem se žlutým jílcem hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo na zeleném návrší vykořeněný smrk přirozené barvy, vlevo zlatý prsten s modrým kamenem, dole přeložený hrotem vztyčeného stříbrného meče se zlatým jílcem."
    },
    {
        obec: "Mšec",
        kod: 542105,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27064,
        latitude: 50.205291,
        longitude: 13.898432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Msec_znak.png"
    },
    {
        obec: "Mšecké Žehrovice",
        kod: 542113,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27064,
        latitude: 50.179113,
        longitude: 13.923232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/M%C5%A1eck%C3%A9_%C5%BDehrovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou loďkou, ze které vyrůstá bílo-červeně polcená orlice se žlutou zbrojí, korunou na krku a perizoniem zakončeným obilnými klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze zlaté loďky vyrůstající stříbrno-červeně polcená orlice se zlatou zbrojí, korunou na krku a perizoniem zakončeným obilnými klasy, dole zlatý tlapatý keltský kříž."
    },
    {
        obec: "Mšené-lázně",
        kod: 565318,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41119,
        latitude: 50.362321,
        longitude: 14.127166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Coat_of_arms_of_M%C5%A1en%C3%A9-l%C3%A1zn%C4%9B.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný bílý se dvěma vrcholy a třemi prohlubněmi. V červeném žlutá ohnutá lví noha drápy k žerdi, v bílém tři vztyčené zelené leknínové listy (2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně vlnitě děleném štítě nahoře zlatá ohnutá lví noha, dole tři (2,1) vztyčené zelené leknínové listy."
    },
    {
        obec: "Mukařov",
        kod: 571865,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.56925,
        longitude: 14.925793
    },
    {
        obec: "Mšeno",
        kod: 535052,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27735,
        latitude: 50.438194,
        longitude: 14.632611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/M%C5%A1eno_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polcenou orlicí se žlutou zbrojí, červeným jazykem a žlutým jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mukařov",
        kod: 538523,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25162,
        latitude: 49.991254,
        longitude: 14.741654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Mukarov-Praha_vychod-znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Do bílého pruhu tři vyrůstající zelené smrky. V červeném pruhu v prázdném kamenném gotickém oblouku lilie. Oblouk je provázen v horní polovině pruhu vozovým kolem a mlýnským kamenem s prázdným otvorem po kypřici, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně zvýšeně děleném štítě nahoře tři vyrůstající zelené smrky, dole lilie v prázdném kamenném gotickém oblouku, provázeném nahoře vpravo vozovým kolem, vlevo mlýnským kamenem s prázdným otvorem po kypřici, vše stříbrné."
    },
    {
        obec: "Mutěnice",
        kod: 560278,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.23981,
        longitude: 13.89664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Mut%C4%9Bnice_%28Strakonice_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený s bílým maltézským křížem, modrý s bílým okounem s červenými ploutvemi a bílý s červeným jablkem s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modro-stříbrně dělený štít. Nahoře stříbrný maltézský kříž, uprostřed stříbrný okoun s červenými ploutvemi, dole červené jablko s listem."
    },
    {
        obec: "Mutějovice",
        kod: 542121,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27007,
        latitude: 50.194353,
        longitude: 13.709619,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Mut%C4%9Bjovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 2 : 1. V bílém pruhu zelený džbán uchem k žerďovému okraji listu. Poměr šířky a délky listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě kosmo děleném štítě nahoře doleva otočený džbán, dole šikmo vztyčený chmelový list, z nějž vyrůstají dvě odkloněné lodyhy, každá s lístkem a dvěma šišticemi, vše zelené."
    },
    {
        obec: "Mutěnice",
        kod: 586412,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69611,
        latitude: 48.904228,
        longitude: 17.02928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Mut%C4%9Bnice_CoA_HO_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou ostrví, z níž vyrůstá ratolest vinné révy se čtyřmi hrozny a čtyřmi úponky (znak je převeden do listu praporu). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě položená ostrev, z níž vyrůstá ratolest vinné révy se čtyřmi hrozny a čtyřmi úponky, vše stříbrné."
    },
    {
        obec: "Mutěnín",
        kod: 553999,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34525,
        latitude: 49.544857,
        longitude: 12.74467,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mut%C4%9Bn%C3%ADn_%28znak%29.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a bílý. Z bílého pruhu vyrůstá černě oděný vousatý horník držící v pravé ruce mlátek a v levé ruce želízko, obojí bílé s hnědými topůrky. V bílém pruhu běžící červený vlk. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Mutkov",
        kod: 547093,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79351,
        latitude: 49.801996,
        longitude: 17.284841,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Znak_obce_Mutkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zelený a zubatý červený, v poměru 4 : 1 : 1. V žerďové polovině listu vyniká ze zeleného pruhu bílá kaplička s červenou stanovou střechou zakončenou černým křížkem, s gotickým vchodem a oknem, obojí černé. V okně žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleno-červeně cimbuřovitě dělené patě stříbrná kaplička s červenou stanovou střechou zakončenou černým křížkem, s gotickým vchodem a oknem, obojí černé. V okně zlatý zvon."
    },
    {
        obec: "Mydlovary",
        kod: 535281,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37349,
        latitude: 49.091233,
        longitude: 14.35445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Mydlovary_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. V žerďové části modrého pruhu žlutá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně děleném štítě čelně postavená stříbrná kaple s dveřmi, černým kruhovým oknem a věžičkou s černým oknem, provázená vlevo nahoře zlatými hornickými kladívky."
    },
    {
        obec: "Myslejovice",
        kod: 589748,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79805,
        latitude: 49.406547,
        longitude: 17.027659,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Myslejovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, žlutý, černý, žlutý a zelený, přes které je položen vodorovný červený pruh široký tři pětiny šířky listu. V červeném pruhu lilie mezi dvěma podkovami, žerďová kosmo, vlající šikmo, vše žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně třemi oblouky děleném štítě nahoře černá zubří hlava se zlatou houžví a červeným jazykem provázená dvěma uťatými smrky přirozené barvy, dole lilie provázená nahoře dvěma odvrácenými podkovami, vše zlaté."
    },
    {
        obec: "Mysletice",
        kod: 587559,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.142671,
        longitude: 15.389524
    },
    {
        obec: "Mysletín",
        kod: 561819,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.476693,
        longitude: 15.368309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Coat_of_arms_of_Myslet%C3%ADn.jpg",
        coatOfArmsFlagDescription: "Šikmým bílým pruhem širokým čtvrtinu šířky listu dělený list na červené žerďové a zelené vlající pole. V červeném poli kosmo klíč zuby nahoru a k žerďovému okraji listu, šikmo přeložený vztyčenými vidlemi-podávkami, v zeleném poli růženec s křížkem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně šikmým stříbrným vlnitým břevnem dělený štít. Nahoře kosmo vztyčený klíč šikmo přeložený vidlemi-podávkami, dole růženec s křížkem, vše zlaté."
    },
    {
        obec: "Mysliboř",
        kod: 587567,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.209518,
        longitude: 15.484514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Coat_of_arms_of_Myslibo%C5%99.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a žlutých, v poměru 7 : 1 : 1 : 1 : 1 : 1 : 1. V horním modrém pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli zlatá růže s červeným semeníkem a zelenými kališními lístky, dolní pole pětkrát zlato-modře děleno."
    },
    {
        obec: "Myslibořice",
        kod: 591190,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67560,
        latitude: 49.102992,
        longitude: 15.98466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Myslibo%C5%99ice_CoA.png"
    },
    {
        obec: "Myslín",
        kod: 549614,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.532189,
        longitude: 14.02744,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Mysl%C3%ADn_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 2 : 1 : 2, červený se žlutým lipovým dvojlistem s plodenstvím, žlutý a popelicový se svislými střídavě modrými a bílými řadami šupin. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělený zlatým břevnem. Nahoře v červeném poli vztyčený zlatý lipový dvojlist s plodenstvím, dole modro - stříbrná šupinová popelčina."
    },
    {
        obec: "Myslinka",
        kod: 566543,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.748125,
        longitude: 13.219142,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Myslinka_CoA.png",
        coatOfArmsFlagDescription: "Zelený list. Z dvanácté šestnáctiny žerďového okraje listu vychází vodorovný bílý vlnitý pruh uprostřed přeložený žlutou berlou závitem s bílou lilií k žerďovému okraji, provázenou podloženými odkloněnými žlutými obilnými klasy s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížené zúžené stříbrné vlnité břevno přeložené zlatou berlou se stříbrnou lilií v závitu a podložené po stranách odkloněným zlatým obilným klasem s listem."
    },
    {
        obec: "Myslkovice",
        kod: 552721,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39116,
        latitude: 49.299224,
        longitude: 14.7457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Myslkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký šestinu délky listu a tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 1 : 8. Žerďový pruh je vodorovně dělen ve stejném poměru na modré, bílé a červeno-černě polcené pole. V žerďové polovině dolního bílého pruhu zvon dole provázený dotýkající se šesticípou hvězdou, nahoře obtočený dole přerušeným mezikružím, vše modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. Pravé pole pod modro-stříbrně dělenou hlavou červeno-černě polcené. V levém stříbrném poli pod stříbrno-modře dělenou hlavou zvon dole provázený dotýkající se hvězdou, nahoře obtočený dole přerušeným kruhem, vše modré."
    },
    {
        obec: "Myslovice",
        kod: 541745,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.406902,
        longitude: 13.387145
    },
    {
        obec: "Mysločovice",
        kod: 585505,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76301,
        latitude: 49.256096,
        longitude: 17.566252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Mysloznak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu s bílým středovým křížem a modré pole se třemi bílými klíny na horním okraji listu s vrcholy v jedné třetině šířky listu; pod prostředním klínem žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo pod třemi zkrácenými stříbrnými klíny zlatá osmihrotá hvězda, vlevo stříbrný heroldský kříž."
    },
    {
        obec: "Myslív",
        kod: 556734,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.414314,
        longitude: 13.572532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Mysl%C3%ADv_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 2 : 1 : 2. V horním pruhu koruna mezi dvěma šesticípými hvězdami, v dolním pruhu kapr, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno provázené nahoře korunou mezi dvěma hvězdami, dole kaprem, vše zlaté."
    },
    {
        obec: "Myštěves",
        kod: 570427,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.297719,
        longitude: 15.557476
    },
    {
        obec: "Mýto",
        kod: 560014,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33805,
        latitude: 49.789152,
        longitude: 13.734722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Myto_RO_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Bílý list se žlutým lemem širokým jednu desetinu šířky listu. V žerďové polovině červená růže se žlutým semeníkem a zelenými kališními lístky, ve vlající polovině červená hradební koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Mžany",
        kod: 570435,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.296508,
        longitude: 15.675954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/M%C5%BEany.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, a vodorovný červeno-bíle šachovaný pruh (2 x 12) široký čtvrtinu šířky listu na dolním okraji. Z třetí až desáté dvanáctiny šachovaného pruhu vychází bílé trojúhelníkové pole s vrcholem na středu horního okraje listu. V něm zelené návrší vynikající ze čtvrté až deváté dvanáctiny horního okraje šachovaného pruhu. Z návrší vyrůstají tři zelené listnaté stromy s hnědými kmeny, prostřední vyšší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrný hrot nad červeno-stříbrně šachovanou patou. V hrotu vyrůstají ze zeleného návrší tři listnaté stromy přirozené barvy, prostřední vyšší."
    },
    {
        obec: "Nabočany",
        kod: 571890,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.94387,
        longitude: 15.886976
    },
    {
        obec: "Myštice",
        kod: 551473,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.453257,
        longitude: 13.97019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/My%C5%A1tice_CoA.JPG",
        coatOfArmsFlagDescription: "Modro-bíle vlnitě polcený list. V žerďové půli vykračující bílá labuť se žlutou zbrojí, ve vlající deset (4, 3, 2, 1) šikmo vztyčených zelených lipových listů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě široký modrý vlnitý kůl, v něm vykračující stříbrná labuť se zlatou zbrojí. Kůl provázen deseti (5, 5) nad sebou šikmo vztyčenými zelenými lipovými listy."
    },
    {
        obec: "Načeradec",
        kod: 530212,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25708,
        latitude: 49.610286,
        longitude: 14.906434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Na%C4%8Deradec_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou věží vyrůstající z dolního okraje, s prázdnou branou, cimbuřím, červenou valbovou střechou se dvěma žlutými makovicemi, provázenou dvěma odvrácenými žlutými gotickými klíči zuby nahoru. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Načešice",
        kod: 571903,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.941391,
        longitude: 15.628949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Na%C4%8De%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 2 : 1. Ze zeleného pruhu vyrůstá bílý lev s červenou zbrojí držící žluté srdce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zelené pilovité paty stříbrný lev s červenou zbrojí držící zlaté srdce."
    },
    {
        obec: "Nadějov",
        kod: 587575,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.422331,
        longitude: 15.773162,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Nad%C4%9Bjov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými žerďovými krokvemi, vycházejícími z první a páté, šesté a desáté a jedenácté a patnácté patnáctiny žerďového okraje, s vrcholy v první třetině délky listu a vymezujícími tři zelené žerďové trojúhelníky. Ve střední a vlající části bílá přilba se spuštěným hledím, se třemi pštrosími péry, bílým mezi černými, se žlutě lemovaným límcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvrší stříbrná uzavřená přilba se zlatě lemovaným límcem a třemi pštrosími péry, stříbrným mezi černými."
    },
    {
        obec: "Nadryby",
        kod: 559253,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.820798,
        longitude: 13.524572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Nadryby_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a čtyřmi prohlubněmi. V žerďové polovině bílého pruhu loďka převýšená lilií a kosmo přeložená bidlem, vše modré, pod ní v modrém pruhu bílá ryba. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě děleném štítě nahoře loďka převýšená lilií a kosmo přeložená bidlem, vše modré, dole stříbrná ryba."
    },
    {
        obec: "Nahořany",
        kod: 574261,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54907,
        latitude: 50.351812,
        longitude: 16.082511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Naho%C5%99any_NA_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 21 : 3 : 4. Z prostředního vrcholu bílého pruhu vyrůstá pět žlutých obilných klasů, krajní odkloněné. V horním rohu a cípu po bílé přivrácené lví hlavě se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít, v patě stříbrné břevno, z něhož vyrůstá pět zlatých obilných klasů, krajní odkloněné, nad nimi dvě přivrácené stříbrné lví hlavy se zlatou zbrojí a jazyky."
    },
    {
        obec: "Nahošovice",
        kod: 552810,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.437824,
        longitude: 17.57822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/CoA_of_Naho%C5%A1ovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený, černý a červený. Na středu bílá zavinutá střela přesahující do třetin červených polí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě s černým kůlem stříbrná zavinutá střela."
    },
    {
        obec: "Nadějkov",
        kod: 549631,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39852,
        latitude: 49.502815,
        longitude: 14.479339
    },
    {
        obec: "Náchod",
        kod: 573868,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54701,
        latitude: 50.414672,
        longitude: 16.165735,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/N%C3%A1chod_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s prolomenou branou se zdviženou mříží mezi dvěma věžemi, vše bílé s cimbuřím. Každá věž má tři (1,2) prázdná okna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Náklo",
        kod: 504441,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78332,
        latitude: 49.654721,
        longitude: 17.129794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Znak_obce_N%C3%A1klo.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý s červeným svatojiřským středovým křížem a zelený. V zelených polích bílé husí hlavy s krky a žlutými zobáky a červenými jazyky obrácenými do středu listu. Šířka ramen kříže je jedna desetina délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůl s červeným heroldským křížem, provázený přivrácenými stříbrnými husími hlavami s krky, se zlatými zobáky a červenými jazyky."
    },
    {
        obec: "Nákří",
        kod: 535371,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37348,
        latitude: 49.118968,
        longitude: 14.329443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Nakri_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. Uprostřed bílo-černě polcená lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě stříbrno-černě polcená lilie."
    },
    {
        obec: "Naloučany",
        kod: 550779,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.233146,
        longitude: 16.135313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Nalou%C4%8Dany_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a modrý, v poměru 5 : 2 : 5. V zeleném pruhu bílá svatojakubská mušle podložená zkříženými žlutými poutnickými holemi, v modrém pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrným vlnitým břevnem dělený štít. Nahoře stříbrná svatojakubská mušle podložená zkříženými zlatými poutnickými holemi, dole zlaté mlýnské kolo."
    },
    {
        obec: "Nalžovice",
        kod: 540790,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26293,
        latitude: 49.69869,
        longitude: 14.370852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Coats_of_arms_Nal%C5%BEovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, zelený, bílý, červený, bílý a modrý, v poměru 4:1:1:1:1:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou patou šikmá zelená větévka tisu se čtyřmi červenými plody."
    },
    {
        obec: "Nalžovské Hory",
        kod: 556751,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.331486,
        longitude: 13.546557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/NHznak.gif"
    },
    {
        obec: "Náměšť na Hané",
        kod: 504505,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78344,
        latitude: 49.602226,
        longitude: 17.065499,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Namest_na_Hane_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně šachovaným orlím spárem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Náměšť nad Oslavou",
        kod: 591211,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.205556,
        longitude: 16.155845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/N%C3%A1m%C4%9B%C5%A1%C5%A5_nad_Oslavou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě žluté a červené, přes které je položené modré trojúhelníkové pole se základnou na dolním okraji, širokou polovinu délky listu s vrcholem na středu horního okraje listu. V něm na zeleném trojvrší bílá hrdlička s červenou zbrojí, černým obojkem a se zelenou ratolestí v zobáku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Napajedla",
        kod: 585513,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76361,
        latitude: 49.171658,
        longitude: 17.512053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Napajedla_znak_REKOS.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři šikmé pruhy, střídavě bílé a červené. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nárameč",
        kod: 591220,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.264513,
        longitude: 15.974825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Znak_obce_N%C3%A1rame%C4%8D.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou kvádrovanou prázdnou bránou s cimbuřím o čtyřech zubech, ve které je bílá levá ruka se zápěstím palcem k žerdi a prsty vzhůru. Z cimbuří vyrůstá zelená lípa s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná prázdná kvádrovaná brána se čtyřmi stínkami, z níž vyrůstá lípa přirozené barvy. V bráně stříbrná ruka po zápěstí dlaní vpřed."
    },
    {
        obec: "Narysov",
        kod: 564478,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.640955,
        longitude: 13.970987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Coats_of_arms_Narysov.jpeg",
        coatOfArmsFlagDescription: "Modrý list se žlutým rysem ve skoku s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý rys ve skoku s červenou zbrojí."
    },
    {
        obec: "Nasavrky",
        kod: 559016,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.452054,
        longitude: 14.640664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Nasavrky_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list. Ve střední části listu žlutý orobinec se dvěma listy, přeloženými po stranách dvěma žlutými obilnými klasy s odkloněnými listy, vše žluté. Okolo čtyři (2, 2) bílé, ke středu listu přivrácené podkovy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý orobinec se dvěma listy, přeloženými po stranách dvěma zlatými obilnými klasy s odkloněnými listy, vše zlaté a provázené čtyřmi stříbrnými, ke středu přivrácenými podkovami."
    },
    {
        obec: "Nasavrky",
        kod: 580678,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.025583,
        longitude: 16.259185
    },
    {
        obec: "Nasavrky",
        kod: 571911,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53825,
        latitude: 49.844597,
        longitude: 15.804712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Znak_obce_Nasavrky.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy - žlutý, zelený, bílý a zelený v poměru 3:1:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na černém trojvrší strom jedlého kaštanu přirozených barev se šestnácti stříbrnými plody."
    },
    {
        obec: "Násedlovice",
        kod: 586421,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69636,
        latitude: 49.012277,
        longitude: 16.960303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Nasedlovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, vlnkovaný bílý se třemi oblouky a modrý. V bílém pruhu modrý vinný hrozen na zeleném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře třemi oblouky dělený štít, nahoře zelená vodorovně položená vinná ratolest s modrým hroznem a dvěma listy po stranách, dole kosmo položená radlice hrotem dolů šikmo podložená krojidlem hrotem nahoru, obojí stříbrné."
    },
    {
        obec: "Našiměřice",
        kod: 594512,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67176,
        latitude: 48.968632,
        longitude: 16.372859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Na%C5%A1im%C4%9B%C5%99ice_-_CoA.png",
        coatOfArmsFlagDescription: "Zeleno-modře šikmo dělený list s bílým vlkem ve skoku s červenou zbrojí a bílými zuby, se žlutým šípem v tlamě, hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře šikmo děleném štítě stříbrný vlk ve skoku s červenou zbrojí a stříbrnými zuby, se vztyčeným zlatým šípem v tlamě."
    },
    {
        obec: "Návojná",
        kod: 585521,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76332,
        latitude: 49.108405,
        longitude: 18.053057,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/CoA_N%C3%A1vojn%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Přes červený pruh a dolní žerďový roh zeleného pruhu kosmo bílá halapartna bez ratiště ostřím k dolnímu rohu listu. V zeleném pruhu zkřížené bílé sekery se žlutými topůrky nad žlutým vztyčeným dubovým dvojlistem se žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě kosmá stříbrná halapartna bez ratiště, vlevo provázená zkříženými stříbrnými sekerami se zlatými topůrky nad zlatým vztyčeným dubovým dvojlistem se žaludem."
    },
    {
        obec: "Návsí",
        kod: 554014,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73992,
        latitude: 49.5873,
        longitude: 18.759182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Navsi_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý v poměru 1:4:1. V modrém poli vzhůru obrácený kosmo položený bílý rýč, křížem podložený bílou kosou, oboje se žlutými násadami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný, vzhůru obrácený rýč se zlatou násadou, křížem podložený kosou týchž barev. Pod nimi dva zlaté obilné klasy, stvoly dole překřížené."
    },
    {
        obec: "Nebahovy",
        kod: 550434,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38401,
        latitude: 49.005858,
        longitude: 14.052436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nebahovy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, vlnitý žlutý, vlnitý zelený a zvlněný bílý, v poměru 10 : 1 : 1 : 4. V horním pruhu vztyčená žlutá lipová ratolest s pěti listy, v dolním pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženým zlato-zeleně vlnitě děleným vlnitým břevnem zeleno-stříbrně dělený štít. Nahoře vztyčená zlatá lipová ratolest s pěti listy, dole červená růže se zlatým semeníkem."
    },
    {
        obec: "Nebanice",
        kod: 554693,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.115352,
        longitude: 12.472085,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Nebanice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, vlnitý modrý se třemi vrcholy a dvěma prohlubněmi a bílý, v poměru 3 : 1 : 4. V horním bílém pruhu čtyři šesticípé hvězdy vedle sebe, v dolním bílém pruhu koruna, vše červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené modré vlnité břevno provázené nahoře čtyřmi hvězdami a dole korunou, vše červené."
    },
    {
        obec: "Nebílovy",
        kod: 540340,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.630199,
        longitude: 13.429246,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Neb%C3%ADlovy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, modrý, bílý a modrý, v poměru 3 : 1 : 1 : 1, přeložené uprostřed svislým červeným pruhem, širokým jednu třetinu délky listu. V žerďové části žlutého pruhu černé paroží, ve vlající části žlutého pruhu obrácená červená svatojakubská mušle. V červeném pruhu bílý kůň ve skoku se žlutou zbrojí a modrým uzděním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli stříbrný kůň ve skoku se zlatou zbrojí a modrým uzděním, levé pole zlato-modře dělené, nahoře černé paroží a v něm obrácená červená svatojakubská mušle, dole stříbrné břevno."
    },
    {
        obec: "Nebovidy",
        kod: 583456,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66448,
        latitude: 49.141432,
        longitude: 16.551476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Nebovidy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a červený, v horním modrá radlice hrotem k vlajícímu okraji, v dolním bílý vinařský nůž se žlutou rukojetí k vlajícímu okraji a ostřím k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený, vpravo stříbrný vinařský nůž se zlatou rukojetí, vlevo modrá radlice."
    },
    {
        obec: "Nebovidy",
        kod: 533521,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.991627,
        longitude: 15.219584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Nebovidy_u_Kol%C3%ADna_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se třemi kosmými, k sobě přiléhajícími pruhy, bílým, modrým a bílým, z nichž je každý široký desetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kosmé stříbrné břevno se vztyčeným modrým šípem."
    },
    {
        obec: "Nebřehovice",
        kod: 536644,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.238537,
        longitude: 13.962118,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Neb%C5%99ehovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký třetinu délky listu a modré pole. V pruhu čtyři vztyčené zelené lipové listy pod sebou, v poli dva odvrácené žluté gotické klíče. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou se čtyřmi vztyčenými zelenými lipovými listy dva odvrácené zlaté gotické klíče."
    },
    {
        obec: "Nebužely",
        kod: 535061,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27734,
        latitude: 50.390406,
        longitude: 14.5909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Nebu%C5%BEely_znak.jpg",
        coatOfArmsFlagDescription: "List čtvrcený červeným středovým křížem s rameny širokými jednu čtvrtinu šířky listu. Horní žerďové a dolní vlající pole modrá se žlutým pravoúhle lomeným pruhem, širokým jednu šestnáctinu šířky listu a přiléhajícím ke kříži. Dolní žerďové a horní vlající pole hermelínová. V kříži pět prázdných vzájemně se dotýkajících žlutých rout (1, 3, 1). Výška routy se rovná šířce ramen kříže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vydutý hermelínový hrot s červeným heroldským křížem s pěti (1, 3, 1) prázdnými zlatými routami. Vpravo zlatá hlavice berly závitem doleva se stříbrným sudariem se zlatým třepením, vlevo zlatý lev s červenou zbrojí a jazykem."
    },
    {
        obec: "Nečín",
        kod: 540811,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26213,
        latitude: 49.698546,
        longitude: 14.23414
    },
    {
        obec: "Nečtiny",
        kod: 559261,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33162,
        latitude: 49.974944,
        longitude: 13.164739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Nectiny_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed volná kvádrovaná hradba s prázdnou branou se dvěma věžemi, každá s cimbuřím a černým oknem. V bráně ozbrojenec v úplné plátové zbroji, v pravé ruce drží vztyčenou sudlici na žluté násadě, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě vyrůstající kvádrovaná hradba s prázdnou branou, nad ní cimbuří. Z hradby vyrůstají dvě věže, každá s cimbuřím a černým oknem. V bráně stojí ozbrojenec v úplné plátové zbroji, v pravé ruce drží vztyčenou sudlici na zlaté násadě. Vše stříbrné."
    },
    {
        obec: "Nedabyle",
        kod: 544825,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37006,
        latitude: 48.928509,
        longitude: 14.515037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Nedabyle_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 7 : 3. Do bílého pruhu vyrůstá zelená lípa s černým kmenem. V zeleném pruhu vedle sebe kosmý mlátek šikmo podložený želízkem a půlměsíc s tváří, cípy k žerďovému okraji listu, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená lípa s černým kmenem vyrůstajícím ze zeleného návrší. V něm vpravo kosmý mlátek šikmo podložený želízkem a vlevo půlměsíc s tváří, vše stříbrné."
    },
    {
        obec: "Nedachlebice",
        kod: 592404,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.099385,
        longitude: 17.589225,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Nedachlebice-znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílou prohnutou štikou ocasem k dolnímu rohu, držící v tlamě ostrev s osmi suky a vinným hroznem ve středu listu, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vztyčená prohnutá štika držící zlatou ostrev s osmi suky a se zlatým vinným hroznem položeným doleva."
    },
    {
        obec: "Nedakonice",
        kod: 592412,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68738,
        latitude: 49.031694,
        longitude: 17.38152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Nedakonice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. Z prostředního vrcholu vyrůstá do bílého pruhu zelená lípa s hnědým kmenem. Pod ní v modrém pruhu bílý kalich. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě děleném štítě nahoře vyrůstající lípa přirozené barvy, dole stříbrný kalich."
    },
    {
        obec: "Nedašov",
        kod: 585530,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76332,
        latitude: 49.107884,
        longitude: 18.070468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/CoA_Neda%C5%A1ov.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutou lilií mezi dvěma oboustranně zubatými posunutými svislými pruhy vycházejícími ze druhé a deváté desetiny horního a dolního okraje listu. Pruhy mají po čtyřech obdélníkových zubech k žerďovému a k vlajícímu okraji a po třech stejných zubech k lilii. V horní části listu je žerďový pruh bílý a vlající pruh červený, v dolní části opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě mezi dvěma cimbuřovými břevny polcenými stříbrno-červeně a naopak tři zlaté lilie."
    },
    {
        obec: "Nedašova Lhota",
        kod: 585548,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76332,
        latitude: 49.121404,
        longitude: 18.079714,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/CZ_Neda%C5%A1ova_Lhota_COA.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a bílých, v poměru 2:1:6:1:2. V prostředním pruhu bílý lipový trojlist prostřelený žlutým šípem hrotem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vykořeněná lípa zleva prostřelená zlatým šípem."
    },
    {
        obec: "Neděliště",
        kod: 570443,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50312,
        latitude: 50.27676,
        longitude: 15.78561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Nedeliste_CZ_CoA.jpg",
        coatOfArmsDescription: "V červeno-modře děleném štítě vpravo vykračující čáp přirozené barvy, vlevo šikmo položený stříbrný meč s červenou rukojetí a zlatou záštitou, kosmo přeložený zlatou střelou."
    },
    {
        obec: "Nedomice",
        kod: 531863,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.259765,
        longitude: 14.616064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Nedomice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, zelený, žlutý, zelený a bílý, přeložených svislým červeným pruhem vycházejícím z druhé a třetí šestiny horního a dolního okraje listu. V něm bílý meč se žlutým jílcem hrotem k dolnímu okraji listu obtočený žlutou vinnou révou se dvěma hrozny a dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli postavený stříbrný meč se zlatým jílcem obtočený zlatou vinnou révou se dvěma hrozny a dvěma listy. Levé pole stříbrno-zeleně dělené, nahoře dvě svěšené zelené okurky na stonku se dvěma listy po stranách, dole zlatá cibule."
    },
    {
        obec: "Nedrahovice",
        kod: 540820,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.609069,
        longitude: 14.455837,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Nedrahovice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, modrý a bílý. V modrém pruhu kosmo bílá ryba - piskoř, ocasem k žerdi. V bílém pruhu černé kolo o osmi ramenech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře polcený štít s červenou hlavou. V pravém poli černé vozové kolo s osmi rameny, vlevo šikmo hlavou dolů stříbrný piskoř."
    },
    {
        obec: "Nedvědice",
        kod: 596175,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59262,
        latitude: 49.457116,
        longitude: 16.334163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Nedvedice-znak.png",
        coatOfArmsFlagDescription: "Bílý list, nahoře ve střední části černá zubří hlava s červeným jazykem a žlutou houžví, provázená dole v žerďové části kosmo postavenou černou vrší a ve vlající části černou uťatou medvědí tlapou šikmo k vlajícímu okraji s červenými drápy k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá zubří hlava s červeným jazykem a zlatou houžví, provázená vpravo dole kosmo postavenou proutěnou vrší a vlevo dole šikmo vztyčenou černou uťatou medvědí tlapou s červenou zbrojí."
    },
    {
        obec: "Nedvězí",
        kod: 578452,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56992,
        latitude: 49.630695,
        longitude: 16.301003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Nedv%C4%9Bz%C3%AD_SV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílo-červeně šachovaný (6 x 2), žlutý, červený a bílý, v poměru 2 : 5 : 1 : 1. Ve žlutém pruhu černý medvěd ve skoku s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě pod stříbrno-červeně šachovanou hlavou, vpravo stříbrná mitra nad zlatým lněným květem, vlevo černý medvěd ve skoku s červenou zbrojí."
    },
    {
        obec: "Nehodiv",
        kod: 578223,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.410858,
        longitude: 13.556463,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Nehodiv_znak.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo dělený list. V zeleném poli bílá koňská hlava, obrácená k vlajícímu okraji, s červeným, žlutě zdobeným uzděním s otěží. V bílém poli černý štír. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo děleném štítě nahoře doleva obrácená stříbrná koňská hlava s červeným, zlatě zdobeným uzděním s otěží, dole černý štír."
    },
    {
        obec: "Nehvizdy",
        kod: 538540,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25081,
        latitude: 50.130663,
        longitude: 14.730041,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Nehvizdy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 : 1. Ze zeleného pruhu vyniká bílá kvádrovaná hradba s cimbuřím prolomená prázdnou bránou s otevřenými vraty a vytaženou mříží, vše žluté. Za zdí vynikají dvě bílé kvádrované věže, každá s černým čtvrceným oknem, cimbuřím o třech zubech a modrou stanovou střechou zakončenou žlutou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Nechanice",
        kod: 570451,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.237468,
        longitude: 15.632865,
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený, zelený, bílý, žlutý, bílý, zelený a červený, v poměru 2:1:2:2:2:1:2. Přes tři prostřední pruhy na rozhraní žerďové a střední části červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nechvalice",
        kod: 540846,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.576649,
        longitude: 14.396667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Coats_of_arms_Nechvalice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 3:7. V červeném pruhu vedle sebe tři žlutá jablka, každé se stopkou a dvěma listy. V bílém pruhu černá orlice se žlutou zbrojí, červeným jazykem a čtyřikrát bílo-černě dělenými letkami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se třemi zlatými jablky se stopkou a dvěma listy, černá orlice se zlatou zbrojí, červeným jazykem a čtyřikrát stříbrno-černě dělenými letkami."
    },
    {
        obec: "Nechvalín",
        kod: 586439,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69631,
        latitude: 49.054642,
        longitude: 17.080689,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Nechval%C3%ADn_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy zelený, žlutý a zelený, v poměru 1:2:1. Ve žlutém pruhu červený vinný hrozen mezi dvěma modrými odvrácenými vinařskými noži - kosíři. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-stříbrně děleném štítě nahoře červený vinný hrozen mezi dvěma modrými odvrácenými vinařskými noži - kosíři, dole zelené trojvrší."
    },
    {
        obec: "Nejdek",
        kod: 555380,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36221,
        latitude: 50.322529,
        longitude: 12.729474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Znak_obce_Nejdek.gif",
        coatOfArmsFlagDescription: "Červený list s bílým klínem ve vlající části, s hrotem na středu žerďového okraje. Na průsečíku os úhlů klínu je položen červený kruh o průměru 1/4 šířky praporu, na průsečíku úhlů červených trojúhelníkových polí jsou stejně veliké bílé kruhy. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nejepín",
        kod: 548197,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.744607,
        longitude: 15.600679,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Nejep%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, v poměru 5 : 1, modrý a bílý zubatý s pěti zuby dosahujícími do druhé třetiny délky listu. Z žerďového okraje vyniká žluté levé obrněné rameno, držící v bílé ruce bílou dýku se žlutým jílcem hrotem k dolnímu okraji listu. Ve střední části listu pět (2, 2, 1) bílých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou pilovitou patou vyrůstá z pravého okraje štítu zlaté levé obrněné rameno držící ve stříbrné ruce postavenou stříbrnou dýku se zlatým jílcem, provázenou vlevo pěti (2, 2, 1) stříbrnými hvězdami."
    },
    {
        obec: "Nekmíř",
        kod: 559270,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33152,
        latitude: 49.858193,
        longitude: 13.263246,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Nekm%C3%AD%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými jednu patnáctinu šířky listu. V horním poli bílý liliový trojhran, v ostatních žluté paroží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné postavené meče se zlatými jílci, provázené nahoře stříbrným liliovitým trojhranem, po stranách a dole zlatým jelením parožím."
    },
    {
        obec: "Nekoř",
        kod: 580686,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56163,
        latitude: 50.055817,
        longitude: 16.551297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Neko%C5%99.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma šikmými bílými pruhy širokými jednu šestinu šířky listu, horní okraj prvního pruhu je úhlopříčkou listu a je vzdálen od dolního okraje druhého pruhu polovinu šířky listu. V horním rohu bílo-červeně polcená orlice se žlutou zbrojí a perizoniem, červeným jazykem a korunovaným červeným štítkem s bílým břevnem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné hrábě přeložené stříbrno-červeně polcenou orlicí se zlatou zbrojí a perizoniem, červeným jazykem a korunovaným červeným štítkem se stříbrným břevnem na hrudi."
    },
    {
        obec: "Nekvasovy",
        kod: 558095,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33547,
        latitude: 49.434661,
        longitude: 13.62585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Nekvasovy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1 : 6 : 1. V modrém pruhu volná prázdná bílá brána s červenou valbovou střechou a žlutými otevřenými vraty, provázena dvěma žlutými osmicípými hvězdami. V bráně bílý hrozen se žlutým stonkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se zlatou korunou volná prázdná stříbrná brána s červenou valbovou střechou a zlatými otevřenými vraty, nahoře provázená dvěma zlatými osmihrotými hvězdami. V bráně stříbrný vinný hrozen se zlatým stonkem."
    },
    {
        obec: "Nelahozeves",
        kod: 535079,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27751,
        latitude: 50.261583,
        longitude: 14.298919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Nelahozeves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. V žerďové polovině červeného pruhu bílý volný ondřejský kříž, pod ním v první prohlubni uprostřed šířky listu žlutá šesticípá hvězda nad žlutou lyrou v modrém pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě zlatá hvězda, nad ní volný stříbrný ondřejský kříž, pod ní zlatá lyra."
    },
    {
        obec: "Nelepeč-Žernůvka",
        kod: 583464,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.334089,
        longitude: 16.386382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Nelepe%C4%8D-%C5%BDern%C5%AFvka_CoA.jpg"
    },
    {
        obec: "Nelešovice",
        kod: 515787,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75103,
        latitude: 49.510794,
        longitude: 17.388256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Nele%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu červeno-bíle šachovaná hlava orlice s krkem a se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vpravo zlatý luk s tětivou přeloženou postaveným stříbrným mečem se zlatým jílcem, vlevo červeno-stříbrně šachovaná hlava orlice s krkem a se zlatou zbrojí."
    },
    {
        obec: "Nemanice",
        kod: 554006,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.436663,
        longitude: 12.720642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Coat_of_arms_of_Nemanice.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým rámem širokým jednu šestadvacetinu délky listu a zeleným lemem širokým jednu třináctinu délky listu. Ve vnitřním zeleném poli nad šesticípou hvězdou vlčí udice hroty k dolnímu okraji, provázená v každém rohu pole šesticípou hvězdou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři převrácené vlčí udice v ovále provázeném pěti (2,2,1) hvězdami, vše zlaté."
    },
    {
        obec: "Němčany",
        kod: 593371,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.164452,
        longitude: 16.919514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Nemcany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem s vrcholem na středu vlajícího okraje. V žerďové části černý antonínský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný klín s černým antonínským křížem provázený vpravo vinařským nožem, vlevo vztyčenou radlicí, oboje stříbrné."
    },
    {
        obec: "Němčice",
        kod: 550396,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.031903,
        longitude: 14.271361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/N%C4%9Bm%C4%8Dice_%28Prachatice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List dělený šikmým žlutým pruhem širokým čtvrtinu šířky listu na bílé žerďové a zelené vlající pole. V bílém poli černá supí hlava s krkem a se žlutým zobákem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo lilie nad horní polovinou berly, obojí zlaté, vlevo černá supí hlava s krkem a se zlatým zobákem."
    },
    {
        obec: "Němčice",
        kod: 536474,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.192799,
        longitude: 13.800188
    },
    {
        obec: "Němčice",
        kod: 582085,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67951,
        latitude: 49.450065,
        longitude: 16.718057,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CoA_N%C4%9Bm%C4%8Dice_%28okres_Blansko%29.svg",
        coatOfArmsFlagDescription: "Kosmo a šikmo čtvrcený list. V horním zeleném poli vztyčené odvrácené radlice a krojidlo ostřím k žerdi, oboje žluté, v dolním zeleném poli tři (1,2) žlutá jablka. V bílém žerďovém poli červená zavinutá střela, ve vlajícím zkřížená červená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V kosmo zeleno-stříbrně čtvrceném štítě v horním poli vztyčená odvrácená zlatá radlice a krojidlo, v pravém poli zavinutá střela, v levém poli zkřížená hornická kladívka, obojí červené, v dolním poli tři (1,2) zlatá jablka."
    },
    {
        obec: "Němčice",
        kod: 572870,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.093029,
        longitude: 15.805868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/CoA_of_N%C4%9Bm%C4%8Dice_%28okres_Pardubice%29.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký třetinu délky listu a modré pole s bílým roštem držadlem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad mostem se třemi oblouky rošt, obojí stříbrné."
    },
    {
        obec: "Němčice",
        kod: 580694,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56118,
        latitude: 49.890679,
        longitude: 16.343315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/N%C4%9Bm%C4%8Dice.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým šikmým vlnitým pruhem, širokým jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlaté vlnité paty od kolen sv. Jan Křtitel v bílé říze přepásané zlatým provazem, pravou rukou vylévá ze stříbrné misky zlatou vodu, v levici drží dřevěnou hůl přirozené barvy, zakončenou jetelovým křížem a vlající stuhou, obojí zlaté."
    },
    {
        obec: "Němčice",
        kod: 599166,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.425183,
        longitude: 13.077609
    },
    {
        obec: "Němčice",
        kod: 571636,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.084482,
        longitude: 15.294147,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Nemcice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu bílá rozsocha s kuželovou šindelovou stříškou a žlutým zvonem na hřídeli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém, zlatě lemovaném štítě stříbrná rozsocha s kuželovou šindelovou stříškou a zlatým zvonem na hřídeli, provázená dvěma zlatými lipovými ratolestmi se sedmi listy."
    },
    {
        obec: "Němčice",
        kod: 536334,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29442,
        latitude: 50.346866,
        longitude: 14.926664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/N%C4%9Bm%C4%8Dice_MB_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným ondřejským křížem s rameny širokými desetinu šířky listu, překrytým modrým kosočtvercem dotýkajícím se okrajů listu. V něm žlutý obilný snop se svisle vetknutým bílým mečem se žlutým jílcem. Snop provázen dvěma odkloněnými a odvrácenými bílými hasičskými sekerkami se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě čelně postavená modrá radlice, v ní zlatý snop se shora vetknutým stříbrným mečem se zlatým jílcem. Snop provázen dvěma odkloněnými a odvrácenými stříbrnými hasičskými sekerami se zlatými topůrky. Radlice podložena zkříženými červenými liliovými hůlkami."
    },
    {
        obec: "Němčice",
        kod: 588784,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76843,
        latitude: 49.364766,
        longitude: 17.497843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/N%C4%9Bm%C4%8Dice_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s polovinou bílého kopřivového listu, k němuž přiléhá polovina žlutého obilného snopu v zeleném pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo polovina stříbrného kopřivového listu, vlevo přiléhající polovina zlatého obilného snopu."
    },
    {
        obec: "Němčičky",
        kod: 583472,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66466,
        latitude: 49.050477,
        longitude: 16.5007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/N%C4%9Bm%C4%8Di%C4%8Dky_znak.svg",
        coatOfArmsFlagDescription: "Červený list s modrým vinným hroznem na zelené větévce a s bílým širokým vinařským nožem se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě modrý vinný hrozen na zelené větévce a široký stříbrný vinařský nůž se zlatou rukojetí."
    },
    {
        obec: "Němčice nad Hanou",
        kod: 589756,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79827,
        latitude: 49.341914,
        longitude: 17.206072,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/N%C4%9Bm%C4%8Dice_nad_Hanou_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část s polovinou černé zubří hlavy s červeným jazykem, žlutým rohem a houžví, přiléhající ke třem vodorovným pruhům: modrému, žlutému a zelenému ve vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít, vpravo půl černé zubří hlavy s červeným jazykem zlatým rohem a houžví, vlevo na zeleném návrší zlatý obilný snop."
    },
    {
        obec: "Němčičky",
        kod: 584703,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69107,
        latitude: 48.93569,
        longitude: 16.824364,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený s bílou lilií, žlutý s modrým vinným hroznem a zelený s bílým špičatým kloboukem se žlutou rolničkou na vrcholu a žlutou zatočenou podbradní šňůrou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dvakrát červeno-zlato-zeleně polcený štít, vpravo stříbrná lilie, uprostřed zeleného vršku černý kolík obtočený zelenou vinnou révou se čtyřmi modrými hrozny. Vlevo stříbrný špičatý klobouk se zlatou rolničkou na vrcholu a zlatou zatočenou podbradní šňůrou."
    },
    {
        obec: "Němčičky",
        kod: 594521,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.942345,
        longitude: 16.088509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Coat_of_arms_of_N%C4%9Bm%C4%8Di%C4%8Dky_ZN.jpg",
        coatOfArmsFlagDescription: "V červeno-bíle kosmo děleném listu kosmo vinařský nůž-kosíř opačných barev ostřím k dolnímu a hrotem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě kosmo vinařský nůž-kosíř opačných barev provázený nahoře červeným vinným hroznem a dole stříbrným třešňovým květem se zlatým středem."
    },
    {
        obec: "Němčovice",
        kod: 566837,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.877038,
        longitude: 13.574993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Nemcovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi v poměru 3 : 1 : 1. V žerďové části žlutého pruhu výr sedící na hnědém vykořeněném pařezu se zeleným dubovým listem k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrno-zeleně vlnitě polceném poli dvě postavené kypřice, červená a stříbrná, v levém zlatém poli na vykořeněném pařezu, vpravo s jedním dubovým listem, čelně sedící výr, vše přirozené barvy."
    },
    {
        obec: "Němětice",
        kod: 536725,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.194724,
        longitude: 13.879078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/N%C4%9Bm%C4%9Btice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým palisádovým pruhem širokým čtvrtinu délky listu se čtyřmi kůly a třemi mezerami. V modrém poli pět bílých hrotů šípů položených k žerďovému okraji listu, tři ve střední části, dva ve vlající části listu, vždy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou palisádovou hlavou pět (3, 2) stříbrných hrotů šípů."
    },
    {
        obec: "Nemochovice",
        kod: 593389,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.18054,
        longitude: 17.135056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Coat_of_arms_of_Nemochovice.svg",
        coatOfArmsFlagDescription: "List dělený bílým šikmým pruhem širokým jednu čtvrtinu šířky listu na žerďové červené pole s bílou lilií a modré vlající pole se žluto-červeně děleným orlím křídlem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrná lilie, vlevo kosmo položená zlatá putna, z níž šikmo do pravého pole vytéká stříbrná tekutina, pod ní zlato-červeně dělené orlí křídlo."
    },
    {
        obec: "Nemile",
        kod: 553476,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.874866,
        longitude: 16.847553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Coat_of_arms_of_Nemile.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém pruhu radlice a krojidlo, obojí bílé, odvrácené a hrotem k hornímu okraji. V bílém červený rak klepety k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě nad dvěma sníženými vlnitými břevny opačných barev vpravo radlice a krojidlo, obojí stříbrné, vztyčené a odvrácené, vlevo červený rak."
    },
    {
        obec: "Nemojany",
        kod: 593397,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68303,
        latitude: 49.246812,
        longitude: 16.913617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Nemojany_COA.svg",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo vlnitě dvěma vrcholy a jednou prohlubní dělený list. V zeleném poli bílý mlýnský kámen s postavenou prázdnou kypřicí, v bílém poli pět modrých šesticípých hvězd podél dělící linky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmé stříbrné vlnité břevno s pěti modrými hvězdami provázené nahoře stříbrným mlýnským kamenem s postavenou prázdnou kypřicí."
    },
    {
        obec: "Nemojov",
        kod: 579564,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54461,
        latitude: 50.467018,
        longitude: 15.753975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Nemojov_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrým kosmým pruhem, širokým dvě sedminy šířky listu, se dvěma žlutými kruhovými poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý lev s červenou zbrojí se dvěma zlatými kotoučky na horní části trupu."
    },
    {
        obec: "Nemotice",
        kod: 593401,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.129699,
        longitude: 17.124981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Znak_Nemotice.png",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová a vlající část se dvěma kosmými bílými pruhy vycházejícími z šesté a osmé desetiny horního okraje do páté a třetí desetiny vlajícího okraje a dvěma šikmými bílými pruhy vycházejícími z šesté a osmé desetiny dolního okraje do šesté a osmé desetiny vlajícího okraje. Uprostřed listu bílé kosočtverečné pole s modrou osmilistou růží a žlutým semeníkem. Výška bílého pole je čtyři pětiny šířky listu a jeho šířka je čtyři pětiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře vyrůstá stříbrná rozkřídlená husa se zlatým zobákem a červeným jazykem, dole modrá osmilistá růže se zlatým semeníkem provázená třemi červenými vpravo šikmými vlevo kosmými břevny."
    },
    {
        obec: "Nemyčeves",
        kod: 573230,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.384534,
        longitude: 15.370126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Nemy%C4%8Deves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velikým modrým čtvercovým polem se třemi vodorovnými bílými pruhy, a dva vodorovné pruhy žlutý a zelený. V karé tři žluté obilné klasy, každý se dvěma listy, u prostředního výše. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeno-modře děleném poli nahoře vyrůstající tři zlaté obilné klasy, každý se dvěma odkloněnými listy, u prostředního výše, dole tři stříbrná břevna. V levém zlatém poli na zeleném vrchu tři listnaté stromy přirozené barvy, prostřední vyšší."
    },
    {
        obec: "Nemyslovice",
        kod: 571156,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29429,
        latitude: 50.358549,
        longitude: 14.76393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Nemyslovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, žlutý, černý, žlutý a modrý, v poměru 4:1:1:1:4. Z vlajícího žlutého pruhu vyskakuje bílý vlk s červenou zbrojí, ve vlajícím modrém pruhu tři (2,1) žluté lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá věž se soklem a cimbuřím a s velkým černým obloukovým oknem, z něhož vyskakuje stříbrný vlk s červenou zbrojí. Věž vlevo provázena třemi (2,1) zlatými liliemi."
    },
    {
        obec: "Nemyšl",
        kod: 552747,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.513482,
        longitude: 14.693011,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Nemy%C5%A1l_CoA.png",
        coatOfArmsFlagDescription: "Červený list se zeleným vrchem vycházejícím z druhé a třetí čtvrtiny dolního okraje listu do tří desetin šířky listu. Z vrchu se sedmicípou hvězdou vyniká do horního okraje listu svislý pruh široký pětinu délky listu, postrkaný osmi (4, 4) hroty oštěpů, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném vrchu se sedmihrotou hvězdou kůl postrkaný osmi (4, 4) hroty oštěpu, vše stříbrné."
    },
    {
        obec: "Nenačovice",
        kod: 533602,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 50.019222,
        longitude: 14.139071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Nena%C4%8Dovice_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým vlnitým bílým pruhem širokým osminu šířky listu na modré žerďové a zelené vlající pole. V modrém poli žlutá koruna nad bílým osmilistým květem se žlutým středem, v zeleném šikmo položený prohnutý žlutý obilný klas s listem k pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně šikmým stříbrným vlnitým břevnem děleném štítě nahoře zlatá koruna nad stříbrným osmilistým květem se zlatým středem, dole šikmo položený prohnutý zlatý obilný klas s listem."
    },
    {
        obec: "Nenkovice",
        kod: 586447,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69637,
        latitude: 49.003151,
        longitude: 17.01104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/NenkoviceZnak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě červených a žlutých, v poměru 1:1:6:1:1:1:1. Ze druhého žlutého pruhu vyrůstají vějířovitě na stoncích tři lilie, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá váza s červeným vinným hroznem, z níž vyrůstají na stoncích tři stříbrné lilie, provázené dvěma vztyčenými odvrácenými stříbrnými vinařskými noži - kosíři se zlatými rukojeťmi."
    },
    {
        obec: "Neplachov",
        kod: 535435,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37365,
        latitude: 49.130401,
        longitude: 14.601091,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Neplachov_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a modré. Uprostřed červená růže se žlutým semeníkem a zelenými kališními lístky, podložená zkříženými svěšenými žlutými obilnými klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V sedmkrát stříbrno - modře děleném štítě červená růže se zlatým semeníkem a zelenými kališními lístky, podložená zkříženými svěšenými zlatými obilnými klasy."
    },
    {
        obec: "Neplachovice",
        kod: 553158,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74774,
        latitude: 49.992379,
        longitude: 17.809969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Neplachovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou radlicí ve střední části, bílou růží se žlutým semeníkem v žerďové části a stejnou růží ve vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná radlice provázená dvěma stříbrnými růžicemi se zlatými semeníky."
    },
    {
        obec: "Nepolisy",
        kod: 570478,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50363,
        latitude: 50.192306,
        longitude: 15.463452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/CoA_of_Nepolisy.svg",
        coatOfArmsFlagDescription: "Modrý list uprostřed se žlutou dózou na masti, u horního okraje listu tři šesticípé hvězdy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě dóza na masti, nad ní tři hvězdy vedle sebe, vše zlaté."
    },
    {
        obec: "Nepoměřice",
        kod: 534226,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28511,
        latitude: 49.880863,
        longitude: 15.148908
    },
    {
        obec: "Nepomuk",
        kod: 558109,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.48627,
        longitude: 13.582361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Nepomuk_znak.png",
        coatOfArmsFlagDescription: "Šikmo dělený bílo-modrý list s pěti šesticípými do kruhu položenými žlutými hvězdami v bílém poli. Průměr hvězdy je 1/7 šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nepomyšl",
        kod: 566501,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43971,
        latitude: 50.218181,
        longitude: 13.313325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Nepomysl_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s volnou bílou kvádrovanou hradbou s prázdnou branou, nad ní mezi zuby oblouková zeď se třemi černými kruhovými otvory do oblouku, z nichž vytéká bílá voda. Za hradbou vynikají dvě věže, každá s dvěma černými okny nad sebou, cimbuřím, červenou stanovou střechou se žlutou makovicí. Na zdi mezi věžemi sv. Mikuláš s bílými vlasy a vousy, v bílé albě, se žlutou štolou s černými křížky, ve žlutém, červeně podšitém pluviálu a se žlutou mitrou. Levou rukou drží šikmo žlutou berlu závitem k vlajícímu okraji, pravou rukou žehná. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou. Nad branou mezi stínkami oblouková zeď se třemi kruhovými otvory, z nichž vytéká stříbrná voda. Za hradbou vynikají dvě věže, každá s dvěma černými okny nad sebou, cimbuřím, červenou stanovou střechou se zlatou makovicí. Na zdi mezi věžemi sv. Mikuláš se stříbrnými vlasy a vousy, ve stříbrné albě, se zlatou štolou s černými křížky, ve zlatém, červeně podšitém pluviálu a se zlatou mitrou. Levou rukou drží šikmo zlatou berlu závitem doleva, pravou rukou žehná."
    },
    {
        obec: "Neprobylice",
        kod: 571521,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27375,
        latitude: 50.264054,
        longitude: 14.027719,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Coat_of_arms_of_Neprobylice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 1 : 3. V dolním bílém pruhu tři (2, 1) černé zvony. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené modré kvádrované břevno, pod ním tři (2, 1) černé zvony."
    },
    {
        obec: "Neratov",
        kod: 573078,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.079868,
        longitude: 15.647275,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Neratov_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a modrý, v poměru 1 : 3. Uprostřed dvě do oblouku vztyčené odvrácené bílé štiky hřbety k sobě, podložené dole žlutými zkříženými berlami závity k okrajům listu se žluto-černými sudarii. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s černou hlavou dvě vztyčené odvrácené stříbrné štiky dole podložené zkříženými zlatými berlami se zlato-černými sudarii."
    },
    {
        obec: "Nepomuk",
        kod: 564524,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.642895,
        longitude: 13.837368
    },
    {
        obec: "Nepřevázka",
        kod: 536351,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.378415,
        longitude: 14.916429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Nep%C5%99ev%C3%A1zka_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílo-červeně jedním stupněm dělený a zelený. Poměr bílého pole a červeného stupně je u žerďového pruhu v poměru 8 : 1, u vlajícího pruhu 7 : 2. Nad stupněm černá patrová kaple na soklu, obě patra se stříškou a prázdným obloukovým oknem, nahoře křížek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kůl, v něm nad červeným levým stupněm černá patrová kaple na soklu, obě patra se stříškou a prázdným obloukovým oknem, nahoře křížek."
    },
    {
        obec: "Neratovice",
        kod: 535087,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27711,
        latitude: 50.259361,
        longitude: 14.517703,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Neratovice_znak.png"
    },
    {
        obec: "Nerestce",
        kod: 562157,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.50082,
        longitude: 14.063758,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Nerestce_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1 : 2 : 1. Přes ně je položen středový červený kříž s rameny širokými čtvrtinu šířky listu. V kříži žlutý křížový hrot loveckého oštěpu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený zvýšený heroldský kříž, ve středu se zlatým křížovým hrotem loveckého oštěpu, dole přeložený modrým mostem s cimbuřím."
    },
    {
        obec: "Neslovice",
        kod: 583481,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.142313,
        longitude: 16.387883,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Neslovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, žlutý, bílý, červený, bílý a žlutý, v poměru 1:1:4:1:1. V červeném pruhu žlutý vinný hrozen na stonku s jedním listem a bílý vinařský nůž - kosíř se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vpravo zlatý vinný hrozen na stonku s jedním listem, vlevo stříbrný vinařský nůž - kosíř se zlatou rukojetí."
    },
    {
        obec: "Nesovice",
        kod: 593419,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.151205,
        longitude: 17.081054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Znak_Nesovice.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu, svislý modrý pruh široký jednu polovinu délky listu a čtyři vodorovné pruhy, střídavě bílé a černé. V modrém pruhu bílá věž se dvěma postranními arkýři a atikou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž se dvěma postranními arkýři a atikou, na ní zkříženy dva praporce s ocasy s červenými žerďovými poli a třikrát stříbrno-černě dělenými poli na zlatých ratištích. Věž provázejí dvě přivrácené radlice, pravá červená a levá třikrát stříbrno-černě dělená."
    },
    {
        obec: "Nespeky",
        kod: 530263,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25722,
        latitude: 49.859411,
        longitude: 14.659464,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Nespeky-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, modrý, čtyři vlnité střídavě bílé a modré a zvlněný žlutý, v poměru 2 : 1 : 2 : 1 : 2 : 16. Ve žlutém pruhu modrý poštovní dostavník se žlutou poštovní trubkou na dveřích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou se dvěma stříbrnými zvlněnými břevny modrý poštovní dostavník se zlatou poštovní trubkou na dveřích."
    },
    {
        obec: "Nestrašovice",
        kod: 564222,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.560455,
        longitude: 14.026157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Nestra%C5%A1ovice_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu se zeleným středovým křížem s rameny širokými šestinu šířky listu a modré pole s bílým beránkem božím se žlutou zbrojí a svatozáří. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou vlnitou hlavou se zeleným heroldským křížem stříbrný beránek boží se zlatou zbrojí a svatozáří."
    },
    {
        obec: "Nesuchyně",
        kod: 542130,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27007,
        latitude: 50.179666,
        longitude: 13.688009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Coats_of_arms_Nesuchyn%C4%9B.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 2:1. Ve žlutém pruhu vztyčený červený šípek se třemi zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vztyčený červený šípek se třemi zelenými listy."
    },
    {
        obec: "Nesvačilka",
        kod: 583499,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66454,
        latitude: 49.064124,
        longitude: 16.754104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Nesva%C4%8Dilka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a vlnkovitý žlutý, v poměru 2:1. V zeleném pruhu bílá mariánská lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou vlnkovitou patou stříbrná mariánská lilie."
    },
    {
        obec: "Nesvačily",
        kod: 534269,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.881363,
        longitude: 14.124461
    },
    {
        obec: "Netín",
        kod: 596183,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.413112,
        longitude: 15.950806,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Net%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě modrých a bílých, v poměru 1:1:9:1:1. V prostředním modrém pruhu bílý vlající klín s vrcholem v první třetině délky listu a žlutá koruna v žerďové části nahoře. V klínu černá orlice se žlutou zbrojí a perizoniem a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě snížený stříbrný vydutý hrot s černou orlicí se zlatou zbrojí a perizoniem a červeným jazykem převýšený zlatou korunou a provázený dvěma přivrácenými stříbrnými křídly."
    },
    {
        obec: "Netolice",
        kod: 550442,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.049406,
        longitude: 14.197098
    },
    {
        obec: "Netřebice",
        kod: 545643,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.790887,
        longitude: 14.455665,
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list, v bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky, v zeleném poli pět (1, 2, 2) žlutých šesticípých hvězd do kruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně šikmo děleném štítě nahoře červená růže se zlatým semeníkem a zelenými kališními lístky, dole pět (1, 2, 2) zlatých hvězd do kruhu."
    },
    {
        obec: "Netřebice",
        kod: 534919,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.213205,
        longitude: 15.142899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Net%C5%99ebice_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 5 : 4. Ve žlutém pruhu mezi svěšenými zelenými lipovými listy s plodenstvím červený zvon, v něm bílý osmihrotý kříž. V modrém pruhu deset (4, 3, 2 , 1) bílých čtvercových polí dotýkajících se rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře dělený štít. Nahoře mezi svěšenými zelenými lipovými listy s plodenstvím červený zvon, v něm stříbrný osmihrotý kříž. Dole deset (4, 3, 2, 1) stříbrných kostek dotýkajících se rohy."
    },
    {
        obec: "Netunice",
        kod: 558117,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.616473,
        longitude: 13.430689,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Netunice-PJ.png",
        coatOfArmsFlagDescription: "Červeno-zeleně šikmo dělený list s bílým koněm ve skoku se žlutými kopyty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně šikmo děleném štítě stříbrný kůň ve skoku se zlatými kopyty."
    },
    {
        obec: "Netvořice",
        kod: 530298,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25744,
        latitude: 49.815795,
        longitude: 14.518381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Netvo%C5%99ice_znak.jpg"
    },
    {
        obec: "Neubuz",
        kod: 585556,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.260516,
        longitude: 17.825454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/CoA_Neubuz.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý v poměru 2:5:2. V modrém poli žlutá plamenná monstrance. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít vpravo zlatá plamenná monstrance, vlevo zkřížené stříbrné meče se zlatými rukojeťmi a záštitami."
    },
    {
        obec: "Neuměř",
        kod: 554022,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.597691,
        longitude: 13.064248,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Neum%C4%9B%C5%99_CoA.jpg"
    },
    {
        obec: "Neuměřice",
        kod: 532665,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27326,
        latitude: 50.241781,
        longitude: 14.220097,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Neum%C4%9B%C5%99ice_CoA_ver2.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy žlutý, modrý a žlutý, v poměru 2 : 5 : 2. V modrém pruhu bílý vykračující Pegas. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vykračující Pegas převýšený obilným dvojklasem provázeným dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Neumětely",
        kod: 531588,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.852562,
        longitude: 14.036716
    },
    {
        obec: "Neurazy",
        kod: 558125,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33555,
        latitude: 49.441098,
        longitude: 13.515683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Neurazy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 5 : 8 : 5. Žerďový tvoří pět vodorovných, střídavě červených a bílých pruhů. Střední pruh je žlutý, na jeho dolním okraji zelený vodorovný pruh, široký pětinu šířky listu, z něhož vyrůstá šedá buková větev se sedmi (1, 2, 2, 2) zelenými listy a čtyřmi (2, 2) vztyčenými hnědými bukvicemi. Vlající pruh tvoří pět vodorovných, střídavě modrých a bílých pruhů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý hrot. V něm vyrůstá ze zeleného trojvrší buková větev se sedmi (1, 2, 2, 2) listy a čtyřmi (2, 2) vztyčenými bukvicemi, vše přirozené barvy. V trojvrší modré, stříbrně lemované vlnité břevno. Hrot je provázen vpravo šikmo, vlevo kosmo postaveným husím brkem."
    },
    {
        obec: "Neustupov",
        kod: 530301,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25786,
        latitude: 49.615106,
        longitude: 14.698704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Neustupov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, zelený, modrý, červený, bílý, červený, modrý a zelený, v poměru 1 : 3 : 1 : 5 : 1 : 3 : 1. V modrých pruzích po žluté šesticípé hvězdě, v bílém pruhu zeleně lemované modré kruhové pole s čelně vztyčenou žlutou radlicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrná kvádrovaná okrouhlá věž s cimbuřím, třemi (1, 2) černými okny a červenou cibulovitou střechou se zlatou makovicí. Mezi horním a dolními okny modrý, zeleně lemovaný kotouč, v něm čelně vztyčená zlatá radlice. Pod dolními okny zlaté iniciály L. I. Věž provázena po stranách vyrůstající lípou přirozené barvy převýšenou zlatou hvězdou."
    },
    {
        obec: "Nevcehle",
        kod: 587583,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.225836,
        longitude: 15.534527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coat_of_arms_of_Nevcehle.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 2 : 1 : 10 : 1 : 2. Ve středním modrém pruhu mezi dvěma přivrácenými medvědy ve skoku korunou převýšené unciální písmeno M nad růží, vše žluté, semeník a zbroj červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma přivrácenými medvědy ve skoku korunou převýšené unciální písmeno M nad růží, vše zlaté, semeník a zbroj červené. Nahoře a dole položený stříbrný býčí roh, horní doprava, dolní doleva, oba hrotem nahoru."
    },
    {
        obec: "Neveklov",
        kod: 530310,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25756,
        latitude: 49.753835,
        longitude: 14.533011,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Neveklov%2C_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými věžemi s cimbuřím, červenou stanovou střechou se žlutou makovicí, branou a oknem, oboje černé. Mezi věžemi červený štítek s bílým břevnem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Neveklovice",
        kod: 571997,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29413,
        latitude: 50.570239,
        longitude: 14.948481,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Neveklovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list. V žerďové části listu uťatá žlutá berla, závitem k vlajícímu okraji listu, s bílým sudariem, ve střední části bílá kaple s černým obloukovým oknem, s červenou stanovou střechou s prázdnou bílou lucernou a s červenou bání zakončenou žlutým křížkem. Ve vlající části vztyčená, k vlajícímu okraji obrácená žlutá lví tlapa s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyniká zlaté vozové kolo, na něm stříbrná kaple s černým obloukovým oknem, s červenou stanovou střechou s prázdnou stříbrnou lucernou a s červenou bání zakončenou zlatým křížkem. Kapli provází vpravo uťatá zlatá berla, závitem doleva, se stříbrným sudariem, vlevo vztyčená, doleva obrácená zlatá lví tlapa s červenou zbrojí."
    },
    {
        obec: "Nevězice",
        kod: 549657,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.477833,
        longitude: 14.153463,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/CoA_of_Nev%C4%9Bzice.svg",
        coatOfArmsFlagDescription: "Červeno-modře kosmo dělený list. V žerďovém poli kosmo bílá zednická lžíce se žlutou rukojetí k vlajícímu cípu. Ve vlajícím poli bílý maltézský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně kosmo děleném štítě nahoře stříbrný maltézský kříž, dole kosmo vztyčená stříbrná zednická lžíce se zlatou rukojetí."
    },
    {
        obec: "Nevid",
        kod: 541192,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.685555,
        longitude: 13.605743
    },
    {
        obec: "Nevojice",
        kod: 593427,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.135416,
        longitude: 17.049752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Nevojice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: zelený, žlutý a zelený v poměru 2:1:1. V žerďové části horního pruhu bílý svěrček provázený nahoře bílou, žlutě zdobenou mitrou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný svěrček provázený nahoře stříbrnou, zlatě zdobenou mitrou a dole třemi (1,2) zlatými jablky."
    },
    {
        obec: "Nevolice",
        kod: 554031,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.420508,
        longitude: 12.933341
    },
    {
        obec: "Nevratice",
        kod: 549207,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.347043,
        longitude: 15.493052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Nevratice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý se žlutým tlapatým křížem, bílý, vlnitý modrý, vlnitý bílý, vlnitý modrý a zvlněný bílý, v poměru 4 : 1 : 1 : 1 : 1 : 4. Z dolního okraje listu vyniká zelený vrch vysoký třetinu šířky listu, v něm vztyčený žlutý lipový dvojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou se zlatým tlapatým křížem dvě modrá vlnitá břevna nad zeleným návrším se vztyčeným zlatým lipovým dvojlistem."
    },
    {
        obec: "Nevřeň",
        kod: 559288,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.822935,
        longitude: 13.274982
    },
    {
        obec: "Nezabudice",
        kod: 598585,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.013274,
        longitude: 13.822228,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/CoA_of_Nezabudice.svg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list, bílá pole šikmo černě šrafovaná. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít nahoře dva zlaté pecny, dole černý vztyčený rošt sv. Vavřince."
    },
    {
        obec: "Nezabylice",
        kod: 546160,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.424293,
        longitude: 13.478643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Nezabylice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: modrý, červený, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s gotickou branou se zlatými zavřenými vraty, zakončená cimbuřím o třech stínkách, z něhož na zlaté žerdi vyniká doleva vlající praporec pětkrát kosmo červeno-stříbrně dělený s ocasem stříbrným, na rubu červeným."
    },
    {
        obec: "Nezamyslice",
        kod: 589764,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79826,
        latitude: 49.325526,
        longitude: 17.173366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Nezamyslice_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List se třemi vodorovnými pruhy - bílým, modrým a bílým. Ve středu modrého pruhu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Z levého kraje modrého štítu vynikají dvě stříbrné koňské hlavy s krky nad sebou a červenými jazyky. Pod nimi zlatá osmihrotá hvězda."
    },
    {
        obec: "Nezamyslice",
        kod: 578533,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.262507,
        longitude: 13.675029,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Nezamyslice_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "V modrém listu kostel se čtyřmi prázdnými gotickými okny a věží s jedním prázdným gotickým oknem, kruhovým okénkem a stanovou střechou. V horním rohu šikmo položená ostrev se třemi suky, v horním cípu šesticípá hvězda, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě kostel se čtyřmi prázdnými gotickými okny a věží s jedním prázdným gotickým oknem, kruhovým okénkem a stanovou střechou provázený vpravo nahoře šikmo položenou ostrví se třemi suky, vlevo hvězdou, vše stříbrné."
    },
    {
        obec: "Nezbavětice",
        kod: 553590,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.655024,
        longitude: 13.477167,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Nezbav%C4%9Btice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý. Uprostřed listu žlutá kaple s černým obloukovým vchodem a lucernou, zakončenou žlutou makovicí s křížkem. Nad kaplí položený jelení paroh k vlajícímu okraji, po stranách dva buvolí rohy, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě zlatá kaple s černým obloukovým vchodem a lucernou, zakončenou zlatou makovicí s křížkem. Nad kaplí doleva položený jelení paroh, po stranách dva buvolí rohy, vše stříbrné."
    },
    {
        obec: "Nezdenice",
        kod: 592421,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68732,
        latitude: 49.016782,
        longitude: 17.752313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Znak_nezdenice.jpeg",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list. Uprostřed bílá polovina rytíře v drátěné košili s dalmatikou a s hrncovou přilbou, držící v pravici za přilbou žlutý meč hrotem k vlajícímu okraji a v levici kosmo přes hruď červený gotický klíč zuby dolů a k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě stříbrná polovina rytíře v drátěné košili s dalmatikou a s hrncovou přilbou, držící v pravici za přilbou položený zlatý meč a v levici kosmo přes hruď postavený červený gotický klíč zuby dolů."
    },
    {
        obec: "Nezdice",
        kod: 542296,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.531654,
        longitude: 13.316677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Coat_of_arms_of_Nezdice.svg",
        coatOfArmsFlagDescription: "List kosmo dělený červeným pruhem na bílé žerďové a zelené vlající pole. Červený pruh vychází z první šestiny žerďového a horního okraje do šesté šestiny dolního a vlajícího okraje listu. V bílém poli červená růže s bílým semeníkem a černými kališními lístky, v zeleném poli žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrým břevnem zvýšeně dělený štít, v horním stříbrném poli zelené trojvrší se zlatým mlýnským kolem, dolní pole stříbrno-červeně polcené, vpravo tři (2, 1) červené růže se stříbrnými semeníky a černými kališními lístky, vlevo kosmé stříbrné břevno provázené dvěma šikmými odvrácenými liliemi."
    },
    {
        obec: "Nezdice na Šumavě",
        kod: 556815,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.172674,
        longitude: 13.612383
    },
    {
        obec: "Nezdřev",
        kod: 540102,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33544,
        latitude: 49.426677,
        longitude: 13.728163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Nezd%C5%99ev_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma žlutými klíny, žerďovým a vlajícím, s vrcholy ve třetinách délky listu. V zeleném poli bílá věž se soklem a žlutě hořícím cimbuřím, s červenou bosovanou branou a dvěma červenými okny vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná věž se soklem a zlatě hořícím cimbuřím, s červenou prázdnou bosovanou branou a dvěma červenými okny, po stranách provázaná dvěma přivrácenými zlatými polovinami kol se čtyřmi viditelnými rameny."
    },
    {
        obec: "Nezvěstice",
        kod: 558141,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.640535,
        longitude: 13.519727,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Nezv%C4%9Bstice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý se žlutým trojlistem v horním rohu a bílý, v poměru 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kvádrovaný most se dvěma oblouky, nad ním zlatý trojlist"
    },
    {
        obec: "Nicov",
        kod: 529893,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38473,
        latitude: 49.125538,
        longitude: 13.620561
    },
    {
        obec: "Nihošovice",
        kod: 551520,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.188367,
        longitude: 13.861812
    },
    {
        obec: "Níhov",
        kod: 596191,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.341792,
        longitude: 16.257645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Znak_obce_Nihov.gif",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými rohy pod žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva stříbrné rohy převýšené zlatou korunou."
    },
    {
        obec: "Nikolčice",
        kod: 584711,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69171,
        latitude: 48.993283,
        longitude: 16.754291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Znak_obce_Nikol%C4%8Dice.svg",
        coatOfArmsFlagDescription: "Zelený list se dvěma černými propletenými vinnými ratolestmi se třemi bílými hrozny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě propletené černé révové ratolesti se třemi stříbrnými vinnými hrozny."
    },
    {
        obec: "Niměřice",
        kod: 571121,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29430,
        latitude: 50.39588,
        longitude: 14.808547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Nim%C4%9B%C5%99ice_CoA.png",
        coatOfArmsFlagDescription: "List tvoři tři svislé pruhy, červený, modrý a bílý. V žerďové a střední části listu tři (2, 1) násadami spojené žluté palcáty provázené třemi (1, 2) bílými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě pod stříbrnou cimbuřovou hlavou tři (2, 1) násadami spojené zlaté palcáty provázené třemi (1, 2) stříbrnými hvězdami."
    },
    {
        obec: "Nimpšov",
        kod: 591238,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67541,
        latitude: 49.022725,
        longitude: 15.747338,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Z červeného pruhu vyrůstá černý jednorožec, z dolního okraje listu vyniká žlutý obilný snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře vyrůstající černý jednorožec, dole vyniká zlatý obilný snop."
    },
    {
        obec: "Nišovice",
        kod: 536962,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.15533,
        longitude: 13.897452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Ni%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý vlnitý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 6 : 1 : 3. V červeném pruhu byzantský kříž provázený dvěma maltézskými kříži, vše bílé. Do modrého pruhu vyniká z bílého pruhu bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zúženým vlnitým břevnem červeno-modře dělený štít, nahoře byzantský kříž provázený dvěma maltézskými kříži, dole z břevna vynikající mlýnské kolo, vše stříbrné."
    },
    {
        obec: "Nítkovice",
        kod: 588806,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76813,
        latitude: 49.203726,
        longitude: 17.166004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/N%C3%ADtkovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým půlměsícem cípy k hornímu okraji listu pod dvěma žlutými odvrácenými liliemi na zkřížených dole spojených stoncích s listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stoupajícím stříbrným půlměsícem dvě zlaté odvrácené liie na zkřížených, dole spojených stoncích."
    },
    {
        obec: "Niva",
        kod: 589772,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79861,
        latitude: 49.445315,
        longitude: 16.851911,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Niva_PV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený, uprostřed žlutý uťatý jehličnatý strom s kmenem podloženým bílou zavinutou střelou hrotem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatý uťatý jehličnatý strom, s kmenem podloženým položenou stříbrnou zavinutou střelou."
    },
    {
        obec: "Nivnice",
        kod: 592439,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68751,
        latitude: 48.974744,
        longitude: 17.647683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Nivnice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů střídavě černých a bílých, v poměru 1 : 1 : 1 : 1 : 1 : 10. V bílém vlajícím poli kosmo položená červená kniha se žlutou ořízkou a iniciálami J. A. K., šikmo přeložená žlutým husím brkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítu pod třemi vrchními černými pruhy kosmo položená červená kniha se zlatou ořízkou a zlatými inciálami J.A.K., přes ni šikmo zlatý brk."
    },
    {
        obec: "Nižbor",
        kod: 531596,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26705,
        latitude: 49.999065,
        longitude: 14.001056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Ni%C5%BEbor_znak.png",
        coatOfArmsFlagDescription: "Čtyři vodorovné pruhy - žlutý, červený, bílý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě šikmé stříbrné vlnité břevno provázené nahoře otevřenou královskou korunou, dole dubovou snítkou s jedním žaludem, vše zlaté."
    },
    {
        obec: "Nížkov",
        kod: 596205,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.532665,
        longitude: 15.805946,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/N%C3%AD%C5%BEkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a modrý, v poměru 6:1:9. V červeném bílá žlutě zdobená mitra s infulemi, v modrém bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře zlatým zúženým břevnem zvýšeně děleném štítě nahoře stříbrná, zlatě zdobená mitra s infulemi, dole stříbrná lilie nad zlatým tlapatým křížkem."
    },
    {
        obec: "Nížkovice",
        kod: 593435,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.109637,
        longitude: 16.901793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/N%C3%AD%C5%BEkovice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý klín s vrcholem ve středu listu a dva vodorovné pruhy, zelený a červený. V klínu červená radlice ostřím k vlajícímu okraji listu. Přes oba pruhy zkřížené přivrácené bílé vinařské nože - kosíře se žlutými rukojeťmi k vlajícímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě se zkráceným stříbrným klínem s červenou radlicí zkřížené přivrácené stříbrné vinařské nože - kosíře se zlatými rukojeťmi."
    },
    {
        obec: "Nižní Lhoty",
        kod: 552526,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.648584,
        longitude: 18.433859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Ni%C5%BEn%C3%AD_Lhoty_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou krokví, vycházející ze čtvrté čtvrtiny žerďového a vlajícího okraje, s vrcholem na horním okraji listu. Pod krokví bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá krokev, pod ní stříbrná lilie."
    },
    {
        obec: "Nosálov",
        kod: 531707,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27735,
        latitude: 50.473589,
        longitude: 14.670766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Nos%C3%A1lov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných, střídavě modrých a bílých pruhů a osmý žlutý, v poměru 7 : 1 : 1 : 1 : 1 : 1 : 2 : 2. Z horního bílého pruhu vyrůstají dvě bílé liliové hůlky, mezi nimi svěšená žlutá chmelová šištice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě uprostřed tři stříbrná zúžená břevna. Nahoře mezi dvěma vyrůstajícími stříbrnými liliovými hůlkami svěšená zlatá chmelová šištice, dole vyrůstající zlatý lipový dvojlist se dvěma listeny s oplodím."
    },
    {
        obec: "Nosislav",
        kod: 584720,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69164,
        latitude: 49.013902,
        longitude: 16.654416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Nosislav_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy žlutý, červený a zelený, v poměru 1:6:1. V červeném pruhu tři vinné hrozny vedle sebe, žlutý mezi modrými, vysoké jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nošovice",
        kod: 552518,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.660825,
        longitude: 18.426436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Nosovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, černý a žlutý v poměru 1:2:1. V černém poli žlutá nůše. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě pod zlatou hlavou s křížem přeloženým zeleným krojidlem a limpou zlatá nůše."
    },
    {
        obec: "Norberčany",
        kod: 597686,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79305,
        latitude: 49.754048,
        longitude: 17.506344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Znak_obce_Norber%C4%8Dany.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a zelený, v poměru 2:1:1. V horním pruhu v žerďové a střední části čtyři do oblouku položené bílé luční květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá kvádrovaná zeď s prázdnou branou, ze zdi vějířovitě vyrůstají čtyři stříbrné luční květy."
    },
    {
        obec: "Nová Buková",
        kod: 561177,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.346075,
        longitude: 15.297103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Nov%C3%A1_Bukov%C3%A1.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnitý modrý, zvlněný bílý a vypouklý zelený, v poměru 6 : 1 : 2 : 1. Ze zeleného pruhu, dosahujícího do čtvrté pětiny šířky listu, vyrůstá přes modrý pruh buk s šedým kmenem a větvemi se zelenými listy. V zeleném pruhu bílá růže se zeleným semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě buk přirozené barvy s kmenem podloženým modrým vlnitým břevnem, vyrůstající ze zeleného návrší se stříbrnou růži se zeleným semeníkem."
    },
    {
        obec: "Nová Bystřice",
        kod: 546798,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37833,
        latitude: 49.019361,
        longitude: 15.103258,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Nov%C3%A1_Byst%C5%99ice_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, červený, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě hradba se třemi stínkami a prázdnou branou se zlatou vytaženou mříží mezi dvěma čtyřhrannými, hranou čelně otočenými věžemi. Každá věž má hladký sokl, čtyři (2,2) černá oblouková okna, cimbuří a červenou valbovou střechu zakončenou dvěma zlatými makovicemi. Hradba i věže jsou stříbrné a kvádrované. Mezi věžemi je stříbrný štítek s černou korunovanou orlicí se zlatou zbrojí a červeným jazykem. V bráně je stříbrno-červeně kosmo dělený štítek."
    },
    {
        obec: "Nová Cerekev",
        kod: 548456,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39415,
        latitude: 49.417337,
        longitude: 15.116849,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Nov%C3%A1_Cerekev_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest střídavě modrých a bílých vodorovných pruhů, v poměru 5 : 1 : 1 : 1 : 1 : 1. Z dolního okraje listu vyniká bílá kvádrovaná věž s modrou branou se žlutou vytaženou mříží, dvěma černými okny, cimbuřím a červenou valbovou střechou se dvěma žlutými makovicemi. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Nová Dědina",
        kod: 588814,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.210212,
        longitude: 17.453821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Nov%C3%A1_D%C4%9Bdina_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "List kosmo a šikmo čtvrcený, žerďová a vlající část červená, horní žlutá s černou vyrůstající orlicí se žlutým zobákem a červeným jazykem, dolní část čtyřikrát černo-žlutě položeně routována. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Zlato-červeně dělený štít. Nahoře vyrůstá černá orlice se zlatou zbrojí a červeným jazykem, dole dvě zkřížené stříbrné sekery na zlatých topůrkách."
    },
    {
        obec: "Nová Hradečná",
        kod: 552381,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78383,
        latitude: 49.834478,
        longitude: 17.077461,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Nov%C3%A1_Hrade%C4%8Dn%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list se zeleným palisádovým pruhem širokým jednu pětinu délky listu s pěti hroty dosahujícími do jedné třetiny délky listu. V zeleném pruhu žlutý had s červeným jazykem plazící se podél žerďového okraje, ve žlutém poli červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou palisádovou hlavou se zlatým plazícím se hadem s červeným jazykem červené srdce."
    },
    {
        obec: "Nová Lhota",
        kod: 586455,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69674,
        latitude: 48.861047,
        longitude: 17.593418,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Nova_Lhota_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a žlutý, v poměru 1 : 4 : 1. V zeleném pruhu vztyčená bílá sekera se žlutým topůrkem ostřím k žerďovému okraji listu a polovina bílého berana se žlutou zbrojí, držícího žlutou halapartnu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad polovinou zlatého palečného kola vpravo stříbrná sekera se zlatým topůrkem, vlevo polovina stříbrného berana se zlatou zbrojí, držícího zlatou halapartnu."
    },
    {
        obec: "Nová Olešná",
        kod: 562734,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.175714,
        longitude: 15.161053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Nov%C3%A1_Ole%C5%A1n%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žluto-červeně dělený a modrý. Ve žlutém poli vztyčený zelený olšový list, v červeném bílý kalich. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře polcený štít se zlatou hlavou, v ní tři vztyčené zelené olšové listy. V pravém poli kalich, v levém zvon se závěsem, obojí stříbrné."
    },
    {
        obec: "Nová Paka",
        kod: 573248,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50901,
        latitude: 50.494593,
        longitude: 15.515138,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Nov%C3%A1_Paka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List s kosým zeleným břevnem o šířce jedné desetiny šířky listu začínajícím na žerďovém a končícím na vlajícím okraji listu. Horní pole je bílé, dolní červené. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nová Pec",
        kod: 550451,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38462,
        latitude: 48.778263,
        longitude: 13.930124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Nova_Pec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou kamennou kolomaznou s pecí nahoře s červeným plamenem, dole se třemi černými otvory. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou se dvěma modrými vlnitými břevny, kamenná kolomazná pec přirozené barvy se třemi černými otvory a plamenem na vrcholu."
    },
    {
        obec: "Nová Pláň",
        kod: 546950,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.922008,
        longitude: 17.475201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Nov%C3%A1_Pl%C3%A1%C5%88_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 3:1. V červeném pruhu do oblouku položeno pět bílých čtyřlistých květů se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatým šípem zleva prostřelené stříbrné srdce z něhož vyrůstá na zlatých stoncích pět stříbrných čtyřlistých květů se zlatými semeníky. Na prostředním květu zlatý pták se stříbrnou snítkou v zobáku."
    },
    {
        obec: "Nová Role",
        kod: 555398,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36225,
        latitude: 50.271028,
        longitude: 12.784334,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Znak_m%C4%9Bsta_Nov%C3%A1_Role.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu s bílou konvicí a dva vodorovné pruhy, bílý a zelený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nová Říše",
        kod: 587591,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58865,
        latitude: 49.13951,
        longitude: 15.563696,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Nova_Rise_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, bílý a červený, v poměru 1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý pivovarský šoufek přeložený zkříženým stříbrným klíčem a mečem se zlatým jílcem a záštitou."
    },
    {
        obec: "Nová Sídla",
        kod: 572608,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.884023,
        longitude: 16.237288,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Nov%C3%A1_S%C3%ADdla_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Uprostřed listu nad volným oboustranně zubatým posunutým pruhem opačných barev se třemi zuby nahoře a dvěma dole ve žlutém pruhu modrá lilie provázená mezi horními listy dvěma vztyčenými odkloněnými červenými granátovými jablky na stopkách a v modrém pruhu žlutý korunovaný dvouocasý lev s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě nad volným polceným cimbuřovým břevnem opačných barev vpravo modrá lilie provázená mezi horními listy dvěma vztyčenými odkloněnými červenými granátovými jablky na stopkách, vlevo zlatý korunovaný dvouocasý lev s červenou zbrojí."
    },
    {
        obec: "Nová Telib",
        kod: 536377,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.39127,
        longitude: 15.032963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Nov%C3%A1_Telib_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a modrý, v poměru 3 : 1 : 2. V bílém pruhu u žerdi zelený trojlístek na stonku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z modrého návrší černý kozel provázený nahoře vpravo zeleným trojlístkem na stonku a vlevo kosmo nakloněným červeným bubnem se stříbrnou blánou a kováním."
    },
    {
        obec: "Nová Včelnice",
        kod: 546801,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.239453,
        longitude: 15.072705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Nov%C3%A1_V%C4%8Delnice_znak.png",
        coatOfArmsFlagDescription: "List tvoří modré karé nad žlutým čtvercovým polem a dva vodorovné pruhy, žlutý a modrý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nová Ves",
        kod: 535648,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37315,
        latitude: 48.920709,
        longitude: 14.52874,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Nova_Ves_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed listu polovina červeného železničního kola se čtyřmi rameny s celým červeno-žlutě děleným nábojem, přiléhající k polovině obruby košíku se čtyřmi džbány, dnem ke středu a uchy k žerdi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě doprava vyniká půl červeného železničního kola se čtyřmi rameny a s celým červeno-zlatě polceným nábojem, vlevo půl obruby košíku, uvnitř čtyři vějířovitě položené džbány, vše zlaté."
    },
    {
        obec: "Nová Ves",
        kod: 513661,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38203,
        latitude: 48.948418,
        longitude: 14.24726,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 1, bílý se třemi červenými růžemi a zelený pilovitý se čtyřmi zuby s hroty v pěti osminách šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad čtyřmi zelenými hroty, prostřední vyšší, tři (1,2) červené růže."
    },
    {
        obec: "Nová Ves",
        kod: 560201,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.187495,
        longitude: 13.733768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Nov%C3%A1_Ves_%28Strakonice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 2 : 3. Ve žlutém pruhu tři červené gotické klíče zuby dolů a k vlajícímu okraji listu, v zeleném pruhu bílý vlnitý pruh široký dvě pětiny šířky listu s položeným červeným rakem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně zvýšeně smrkovým řezem děleném štítě nahoře tři postavené červené gotické klíče zuby doleva, dole stříbrné vlnité břevno, v něm položený červený rak."
    },
    {
        obec: "Nová Ves",
        kod: 583502,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66491,
        latitude: 49.108212,
        longitude: 16.310943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Nov%C3%A1_Ves_BO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu dva vodorovné vlnité bílé pruhy se třemi vrcholy a dvěma prohlubněmi, vycházející ze čtvrté a sedmé desetiny žerďového okraje listu. V červeném pruhu dvě zkřížené odvrácené kosy podložené hráběmi, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít, vpravo dvě stříbrná vlnitá břevna, vlevo dvě zkřížené odvrácené kosy podložené hráběmi, vše stříbrné."
    },
    {
        obec: "Nová Ves",
        kod: 560561,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36464,
        latitude: 50.084201,
        longitude: 12.775287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Nov%C3%A1_Ves_%28Sokolov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký třetinu délky listu a jedenáct vodorovných pruhů střídavě bílých a modrých. V zeleném pruhu čelně bílá hlava jelena se žlutým parožím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít. Vpravo čelně stříbrná hlava jelena se zlatým parožím, vlevo pět modrých vlnitých břeven."
    },
    {
        obec: "Nová Ves",
        kod: 591246,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.248618,
        longitude: 15.803952,
        coatOfArmsFlagDescription: "V modrém listu žlutý korunovaný dvouocasý lev s červenou zbrojí mezi dvěma odkloněnými polovinami bílé zavinuté střely nad bílo-červeně polceným svěšeným lipovým trojlistem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý korunovaný dvouocasý lev s červenou zbrojí mezi dvěma odkloněnými polovinami stříbrné zavinuté střely nad stříbrno-červeně polceným svěšeným lipovým trojlistem."
    },
    {
        obec: "Nová Ves",
        kod: 596213,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.391108,
        longitude: 16.196383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Nov%C3%A1_Ves_%28okres_%C5%BD%C4%8F%C3%A1r_nad_S%C3%A1zavou%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pětiúhelníkové pole vycházející z první šestiny délky listu s vrcholem ve středu listu a dva vodorovné pruhy, bílý a zelený. V modrém poli královská koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrno-zeleně sníženým vidlicovým řezem dělený štít. V modrém poli královská koruna, ve stříbrném vykořeněná lípa přirozené barvy, v zeleném stříbrný rys ve skoku se zlatou zbrojí."
    },
    {
        obec: "Nová Ves",
        kod: 576549,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51721,
        latitude: 50.13079,
        longitude: 16.050209
    },
    {
        obec: "Nová Ves",
        kod: 546593,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46331,
        latitude: 50.831607,
        longitude: 15.001175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Nova_Ves_LB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou beraní hlavou provázenou dvěma do oblouku dolů přivrácenými bílými rybami s červenými ploutvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nahoře čelně hledící zlatá beraní hlava provázená dvěma do oblouku dolů přivrácenými stříbrnými rybami s červenými ploutvemi, dole stříbrné vozové kolo."
    },
    {
        obec: "Nová Ves",
        kod: 599131,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.366549,
        longitude: 13.038868,
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, bílý a modrý, v poměru 4 : 14 : 3 : 3. V zeleném pruhu pět (1, 2, 2) žlutých šesticípých hvězd do kruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené trojvrší s pěti (1, 2, 2) zlatými hvězdami do kruhu nad stříbrno-modře vlnitě dělenou vlnitou patou."
    },
    {
        obec: "Nová Ves",
        kod: 540269,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33441,
        latitude: 49.686066,
        longitude: 13.292789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Nov%C3%A1_Ves_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2:1. Ve žlutém pruhu modrá šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zvýšenou patou hvězda, obojí modré."
    },
    {
        obec: "Nová Ves",
        kod: 535117,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27752,
        latitude: 50.312898,
        longitude: 14.308286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Nov%C3%A1_Ves_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vlajícím trojúhelníkem vycházejícím z druhé poloviny dolního okraje listu. V modrém poli polovina bílého dvouocasého lva s červenou zbrojí držícího žlutý jelení paroh. V bílém poli modrá lilie. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V modro - stříbrně sníženě vlnitě děleném štítě nahoře polovina stříbrného dvouocasého lva s červenou zbrojí držícího zlatý jelení paroh, dole modrá lilie."
    },
    {
        obec: "Nová Ves",
        kod: 538558,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.219324,
        longitude: 14.535739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Coats_of_arms_Nov%C3%A1_Ves.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. Z dolního žlutého pruhu vyrůstají do modrého pruhu tři žluté obilné klasy, prostřední se dvěma listy, krajní s jedním listem ke středu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře zvýšeně děleném štítě nahoře položený klíč zuby doleva a nahoru, pod ním položený meč, dole vyrůstající tři obilné klasy, prostřední se dvěma listy, krajní s jedním listem ke středu, vše opačných barev."
    },
    {
        obec: "Nová Ves",
        kod: 542636,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.285958,
        longitude: 13.842789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Nov%C3%A1_Ves_%28Louny_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý. V zeleném pruhu bílá kaple s vchodem a střechou s lucernou s prázdným obloukovým oknem a stanovou střechou zakončenou žlutým latinským křížkem. Vchod a střechy červené. Pod kaplí tři zkřížené žluté obilné klasy, krajní s listem. Ve žlutých pruzích vztyčená hnědá lipová větev se zelenými listy a světle zelenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený rozšířený kůl, v něm stříbrná kaple s vchodem a střechou s lucernou s prázdným obloukovým oknem a se stanovou střechou zakončenou zlatým latinským křížkem. Vchod a střechy červené. Pod kaplí tři zkřížené zlaté obilné klasy, krajní s listem. Ve zlatých polích vztyčená lipová větev s listy a plody přirozené barvy."
    },
    {
        obec: "Nová Ves I",
        kod: 533530,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.052246,
        longitude: 15.144556
    },
    {
        obec: "Nová Ves nad Lužnicí",
        kod: 562360,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37809,
        latitude: 48.810739,
        longitude: 14.925675
    },
    {
        obec: "Nová Ves nad Nisou",
        kod: 563731,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46827,
        latitude: 50.72524,
        longitude: 15.214758
    },
    {
        obec: "Nová Ves nad Popelkou",
        kod: 577341,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51271,
        latitude: 50.52041,
        longitude: 15.411952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Znaknovavesnadpopelkou.gif",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, vlnitý bílý, vlnitý modrý a zvlněný bílý, v poměru 7:1:1:1. V zeleném pruhu bílá husa se žlutým zobákem vzlétající ze žluté skořápky mezi dvěma bílými věžemi s prázdnou gotickou bránou, černým oknem a cimbuřím. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou s modrým vlnitým břevnem ze zlaté skořápky vzlétající stříbrná husa se zlatým zobákem, provázená dvěma stříbrnými věžemi, každá s prázdnou gotickou branou, černým oknem a cimbuřím."
    },
    {
        obec: "Nová Ves pod Pleší",
        kod: 540889,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26204,
        latitude: 49.831865,
        longitude: 14.275336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Nov%C3%A1_Ves_pod_Ple%C5%A1%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list, uprostřed kůň ve skoku, v modrém poli žlutý s červenými kopyty a jazykem, v červeném bílý se žlutými kopyty. V horním rohu kosmo položený mlátek šikmo podložený želízkem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě zlato-stříbrně šikmo dělený kůň ve skoku, nahoře s červenými kopyty a jazykem, dole se zlatými kopyty, provázený vpravo nahoře kosmo položeným mlátkem šikmo podloženým želízkem, obojí zlaté."
    },
    {
        obec: "Nová Ves u Bakova",
        kod: 571806,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29401,
        latitude: 50.490776,
        longitude: 14.926496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Nov%C3%A1_Ves_u_Bakova_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý s hnědou palicí orobince na zeleném stonku se čtyřmi listy, a černý. V krajních pruzích po třech žlutých osmilistých květech pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dvakrát zeleno-zlato-černě polcený štít. Uprostřed orobinec přirozené barvy se čtyřmi listy. V krajních polích po třech zlatých osmilistých květech pod sebou."
    },
    {
        obec: "Nová Ves u Chotěboře",
        kod: 569160,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58273,
        latitude: 49.756205,
        longitude: 15.648091,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Nov%C3%A1_Ves_u_Chot%C4%9Bbo%C5%99e_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu dvě bílé lilie. Na dolním okraji bílého pruhu modré půlkruhové pole o poloměru jedné čtvrtiny délky listu se vztyčeným bílým jetelovým trojlistem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě kvádrovaný most s jedním obloukem provázený nahoře dvěma liliemi, dole pěti vztyčenými jetelovými trojlisty do kruhu, vše stříbrné."
    },
    {
        obec: "Nová Ves u Chýnova",
        kod: 552763,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39155,
        latitude: 49.392801,
        longitude: 14.783071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Nov%C3%A1_Ves_u_Ch%C3%BDnova_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílo-zeleně dělený v poměru 3 : 1 a červeno-modře dělený. V žerďové polovině listu zelená borovice s hnědým kmenem. V červeném poli obrácená bílá podkova, v modrém volný žlutý oheň se třemi plameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít polcený. V pravém stříbrném poli na zeleném návrší borovice přirozené barvy, levé pole červeno-modře dělené, nahoře obrácená stříbrná podkova, dole volný zlatý oheň se třemi plameny."
    },
    {
        obec: "Nová Ves u Jarošova",
        kod: 572438,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.837637,
        longitude: 16.160009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Nov%C3%A1_Ves_u_Jaro%C5%A1ova_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, z dolního okraje vyniká bílá brána s otevřenými vraty a valbovou střechou, obojí žluté. V bráně žlutý obilný snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná brána s otevřenými vraty a valbovou střechou, obojí zlaté. V bráně zlatý obilný snop."
    },
    {
        obec: "Nová Ves u Leštiny",
        kod: 569151,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.785954,
        longitude: 15.404017
    },
    {
        obec: "Nová Ves u Mladé Vožice",
        kod: 563455,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.543134,
        longitude: 14.748066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Nov%C3%A1_Ves_u_Mlad%C3%A9_Vo%C5%BEice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený, široké čtvrtinu délky listu a pět vodorovných pruhů střídavě bílých a modrých. V žerďové polovině listu nahoře žlutá koruna se čtyřmi listy s perlou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatá koruna se čtyřmi listy s perlou nad sníženým stříbrným hrotem se dvěma modrými břevny."
    },
    {
        obec: "Nová Ves u Nového Města na Moravě",
        kod: 596221,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.540029,
        longitude: 16.084205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Nov%C3%A1_Ves_u_Nov%C3%A9ho_M%C4%9Bsta_na_Morav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi žlutými obilnými snopy (2+1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři (2,1) postavené zlaté obilné snopy."
    },
    {
        obec: "Nová Ves u Světlé",
        kod: 555266,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.648874,
        longitude: 15.437793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Znak_-_Nov%C3%A1_Ves_u_Sv%C4%9Btl%C3%A9.svg",
        coatOfArmsFlagDescription: "List dělený kosmým bílým pruhem vycházejícím z první šestiny horního okraje listu do poslední šestiny dolního okraje listu na žerďové červené pole s kosmým a k dolnímu rohu prohnutým bílým obilným klasem s listem a vlající černé pole s čelně hledící bílou lví hlavou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-červeně stříbrným kosmým břevnem, nahoře cimbuřovým a dole vlnitým, dělený štít. Nahoře čelně hledící lví hlava, dole kosmý a dolů prohnutý obilný klas s listem, obojí stříbrné."
    },
    {
        obec: "Nová Ves v Horách",
        kod: 567329,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43545,
        latitude: 50.594847,
        longitude: 13.482558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Coat_of_arms_of_Nov%C3%A1_Ves_v_Hor%C3%A1ch.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový klín, vymezený bílou žerďovou krokví s vrcholem ve středu listu, vycházející z první a páté pětiny žerďového okraje, a pět vodorovných, střídavě žlutých a zelených pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo zelený vykořeněný smrk, vlevo od středu vynikají dvě rámě se zlatými vyhrnutými rukávy, držící dláta, paže a dláta přirozené barvy."
    },
    {
        obec: "Nové Bránice",
        kod: 583511,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.071249,
        longitude: 16.441855,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Znak_obce_Nov%C3%A9_Br%C3%A1nice.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý v poměru 5:1:1:1:1. V horním žerďovém rohu vztyčená bílá radlice nad zkříženými bílými vinařskými noži, střenkami nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená stříbrná radlice provázená dvěma zlatými liliemi, pod ní dolů obrácené zkřížené stříbrné vinařské nože."
    },
    {
        obec: "Nové Dvory",
        kod: 587974,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.564483,
        longitude: 15.808971,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Nov%C3%A9_Dvory_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým býkem ve skoku s červenou zbrojí, držícím pod sebou červeno-žlutě kosmo dělený štítek se dvěma odvrácenými bílými vinařskými noži se žlutými rukojeťmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný býk ve skoku s červenou zbrojí držící pod sebou červeno-zlatě kosmo dělený štítek se dvěma odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi."
    },
    {
        obec: "Nové Dvory",
        kod: 534242,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28531,
        latitude: 49.970682,
        longitude: 15.324978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Nov%C3%A9_Dvory_%28Kutn%C3%A1_Hora_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří černé karé a dva vodorovné pruhy, červený a bílý. V karé bílá hlava psa - věžníka s červeným, žlutě lemovaným obojkem s kroužkem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Nové Dvory",
        kod: 540897,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.803328,
        longitude: 14.357021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Nov%C3%A9_Dvory_PB_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se šikmým modrým pruhem širokým pětinu délky listu kosmo podloženým šedým želízkem na hnědé násadě. V pruhu žlutý kalich mezi dvěma bílými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě šikmé modré břevno, podložené kosmým želízkem přirozené barvy. V břevně zlatý kalich mezi dvěma stříbrnými liliemi."
    },
    {
        obec: "Nové Dvory",
        kod: 565342,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.447702,
        longitude: 14.179516
    },
    {
        obec: "Nové Hamry",
        kod: 506494,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36221,
        latitude: 50.360145,
        longitude: 12.719531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Nove_Hamry_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, přes ně položen vodorovný vlnitý bílý pruh o třech vrcholech a dvou prohlubních, vycházející ze čtvrté pětiny žerďového a vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě dva buchary hamru nad vlnitým břevnem, vše stříbrné."
    },
    {
        obec: "Nové Heřminovy",
        kod: 569551,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 50.023575,
        longitude: 17.5283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Nov%C3%A9_He%C5%99minovy%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený. Z dolního okraje listu vyniká žlutým vlnitým pruhem dělené zeleno-modré návrší, z něhož do bílého pruhu vyrůstá zelený modřín s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným kůlem vyrůstá ze zeleno-zlato-modře vlnitě děleného návrší modřín přirozené barvy."
    },
    {
        obec: "Nové Hrady",
        kod: 571920,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53945,
        latitude: 49.851778,
        longitude: 16.143909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Nov%C3%A9_Hrady_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý se žlutou šesticípou hvězdou, zelený, žlutý a černý, v poměru 6:1:1:1. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě na černé patě se zlatým vydutým hrotem zelené trojvrší, nad ním zlatá hvězda."
    },
    {
        obec: "Nové Hrady",
        kod: 544868,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37333,
        latitude: 48.789733,
        longitude: 14.778483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Nov%C3%A9_Hrady_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý kvádrovaný bílý, v poměru 5:4. V modrém pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky. Bílý pruh má sedm obdélníkových zubů vysokých jednu devítinu šířky listu a šest třetinových mezer. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nové Hutě",
        kod: 561568,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38501,
        latitude: 49.038803,
        longitude: 13.645759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Nov%C3%A9_Hut%C4%9B_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi, v poměru 3 : 2. V zeleném pruhu skleněný pohár mezi dvěma smrky, vše bílé. V bílém pruhu červené srdce završené křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně sníženě vlnitě děleném štítě nahoře skleněný pohár mezi dvěma smrky, vše stříbrné, dole červené srdce završené křížkem."
    },
    {
        obec: "Nové Lublice",
        kod: 512893,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74901,
        latitude: 49.862875,
        longitude: 17.678131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Nov%C3%A9_Lublice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a červené pole. V žerďové a střední části listu žlutý dvouocasý lev s modrou zbrojí držící v zeleném pruhu bílou radlici hrotem k hornímu a ostřím k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě zlatý dvouocasý lev s modrou zbrojí držící vztyčenou stříbrnou radlici."
    },
    {
        obec: "Nové Město",
        kod: 570494,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.147769,
        longitude: 15.495214,
        coatOfArmsFlagDescription: "Zelený list s bílou sloupovou zvoničkou se žlutým zvonem, s červenou stříškou zakončenou bílým křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná sloupová zvonička se zlatým zvonem, s červenou stříškou zakončenou stříbrným křížkem nad stříbrnou vlnitou patou s modrým vlnitým břevnem."
    },
    {
        obec: "Nové Město na Moravě",
        kod: 596230,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.561543,
        longitude: 16.074288,
        coatOfArmsFlagDescription: "List je nakoso dělený, horní pole žluté, dolní červené; na třetinách délky listu jsou postaveny odvrácené vinařské nože; délka čepele nožů je 5/10 šířky listu a střenky 2/10 šířky listu; čepele jsou bílé, střenky hnědé; poměr délky k šířce je 3:2."
    },
    {
        obec: "Nové Město nad Metují",
        kod: 574279,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.344662,
        longitude: 16.151571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Nov%C3%A9_M%C4%9Bsto_nad_Metuj%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Prapor tvoří stejně široké vodorovné pruhy, horní bílý a dolní modrý; v horním žerďovém rohu je červené karé s bílými lekny. Poměr délky k šířce je 3:2."
    },
    {
        obec: "Nové Město pod Smrkem",
        kod: 564265,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46365,
        latitude: 50.925011,
        longitude: 15.229539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Nove_Mesto_pod_Smrkem_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů: modrý, bílý, modrý, bílý a modrý v poměru 1:1:5:1:1. Ve středu modrého pole bílé kolo s osmi rameny a pod ním zkřížená bílá hornická kladívka. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nové Mitrovice",
        kod: 558176,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33563,
        latitude: 49.579685,
        longitude: 13.683735,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Nov%C3%A9_Mitrovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, černý a červený. V černém pruhu pět žlutých šesticípých hvězd do kruhu nad kosmým mlátkem šikmo podloženým želízkem, obojí bílé na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-černo-červeně polceném štítě pět zlatých hvězd do kruhu nad kosmým mlátkem šikmo podloženým želízkem, obojí stříbrné na zlatých topůrkách."
    },
    {
        obec: "Nové Sady",
        kod: 593443,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68308,
        latitude: 49.408906,
        longitude: 16.902045,
        coatOfArmsDescription: "Ve zlatém štítě pod dvěma zelenými krokvemi červená kosmá putýnka s vytékající stříbrnou vodou."
    },
    {
        obec: "Nové Sady",
        kod: 596248,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59501,
        latitude: 49.293788,
        longitude: 16.193421,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Coat_of_arms_of_Nov%C3%A9_Sady_ZR.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle čtvrcený list. V horním žerďovém poli bílá psí hlava se žlutým jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou s bochníkem chleba přirozené barvy mezi dvěma červenými květy se zlatými středy stříbrná psí hlava se zlatým jazykem."
    },
    {
        obec: "Nové Sedlice",
        kod: 555274,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74706,
        latitude: 49.902222,
        longitude: 18.001326,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Nov%C3%A9_Sedlice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-červeně kosmo zubatě dělený list. V žerďovém červeném poli kosmo položená radlice hrotem a ostřím k žerdi, ve vlajícím bílém poli červené sedlo se třmenem. Červené pole má tři obdélníkové zuby široké jednu čtvrtinu šířky listu a vysoké jednu osminu šířky listu a čtyři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Cimbuřovým řezem kosmo stříbrno-červeně dělený štít. Nahoře sedlo se třmenem, dole kosmo položená radlice opačných barev."
    },
    {
        obec: "Nové Sedlo",
        kod: 566519,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.339745,
        longitude: 13.474941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Nov%C3%A9_Sedlo_znak.jpg",
        coatOfArmsFlagDescription: "Střídavě od žerdi bílo-červeně kosmo, svisle a šikmo dělený list. Uprostřed žluté kruhové pole široké jednu třetinu délky listu s knížecí čepicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít, nahoře stříbrný klín, dole červený hrot, na středu zlatý kotouč, v něm knížecí čepice."
    },
    {
        obec: "Nové Sedlo",
        kod: 560570,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35734,
        latitude: 50.206578,
        longitude: 12.738121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Ns_znak.jpg",
        coatOfArmsFlagDescription: "List se žlutým ondřejským křížem širokým jednu sedminu šířky listu. Žerďové trojúhelníkové pole bílé, horní a dolní červené a vlající zelené. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nové Strašecí",
        kod: 542164,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27101,
        latitude: 50.152823,
        longitude: 13.900538,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Znak_barevny.jpg",
        coatOfArmsFlagDescription: "List je čtvrcený, horní žerďové pole je červené s bílým českým lvem, dolní vlající pole je červené se žlutým písmenem 'W'. Horní vlající a dolní žerďové pole jsou bílá. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nové Syrovice",
        kod: 591254,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67541,
        latitude: 49.01773,
        longitude: 15.773555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Nov%C3%A9_Syrovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými jednu šestnáctinu šířky listu, přeloženým žlutým ondřejským křížem posunutým o jednu šestnáctinu šířky listu k dolnímu okraji. V horním modrém poli bílo-červeně polcená orlice se žlutou zbrojí a žlutým jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené stříbrné cepy. Přes dolní okraje násad křížem přeložené ostrve, z jejichž dolních vnitřních suků vyrůstají dva zkřížené obilné klasy, vše zlaté. Nahoře mezi cepy stříbrno-červeně polcená orlice se zlatou zbrojí a zlatým, jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Nové Veselí",
        kod: 596256,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.519866,
        longitude: 15.90863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Nov%C3%A9_Vesel%C3%AD_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: žlutý a červený. Dolní mřížovaný třemi svislými a dvěma vodorovnými bílými pruhy širokými jednu čtyřiadvacetinu délky listu. Na svislé bílé pruhy navazují v horním pruhu červené svislé řetězy dosahující k hornímu okraji listu, každý ze tří kruhových a dvou spojovacích článků. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Noviny pod Ralskem",
        kod: 561851,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47124,
        latitude: 50.692151,
        longitude: 14.746964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Noviny_pod_Ralskem_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří černý žerďový pruh široký jednu třetinu délky listu s deseti žlutými srdíčky ve třech svislých řadách (3,4,3) a pět vodorovných pruhů střídavě zelených a žlutých. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-zeleně dělený štít, nahoře deset zlatých srdíček (3,4,3) a dole dva zlaté kůly."
    },
    {
        obec: "Novosedly",
        kod: 551554,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.26277,
        longitude: 13.797628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Novosedly.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 7 : 1. V zeleném pruhu čelně postavená žlutá radlice, v ní kosmo střela s modrým hrotem k žerďovému okraji, červeným dříkem a bílým opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou čelně postavená zlatá radlice, v ní kosmo vztyčená červená střela s modrým hrotem a stříbrným opeřením"
    },
    {
        obec: "Novosedlice",
        kod: 567752,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41731,
        latitude: 50.656439,
        longitude: 13.823154,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Novosedlice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu šestinu délky listu a bílé pole s modrým vodorovným středovým pruhem širokým jednu pětinu šířky listu. K červenému a modrému pruhu přiléhají nahoře i dole žluté pravoúhle lomené pruhy široké jednu pětadvacetinu šířky listu a k nim přiléhají stejně široké zelené pravoúhle lomené pruhy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červenou patou modré břevno, přes něj hlava muže přirozené barvy s vousem provázená vpravo vztyčenou zelenou palmovou ratolestí, vlevo zlatou berlou. V patě zlatý stoupající půlměsíc."
    },
    {
        obec: "Novosedly",
        kod: 584746,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69182,
        latitude: 48.83714,
        longitude: 16.492837,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4:1. Na zeleném pruhu stojí žlutý kostel s věží uprostřed, červenými střechami a průčelím k žerdi. Nahoře provázený dvěma žlutými vinnými hrozny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku zlatý kostel s věží uprostřed, červenými střechami a průčelím vpravo. Nahoře provázený dvěma zlatými vinnými hrozny."
    },
    {
        obec: "Novosedly nad Nežárkou",
        kod: 546844,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37817,
        latitude: 49.081103,
        longitude: 14.82226,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/CoA_of_Novosedly_nad_Ne%C5%BE%C3%A1rkou.svg",
        coatOfArmsFlagDescription: "List tvoří osm vodorovných pruhů, žlutý a střídavě modré a bílé, v poměru 7:1:1:1:1:1:1:1. V horním rohu modrá růže s červeným semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře modrá růže s červeným semeníkem, dole tři stříbrná břevna."
    },
    {
        obec: "Nový Bor",
        kod: 561860,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47301,
        latitude: 50.757714,
        longitude: 14.555664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Nov%C3%BD_Bor_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nový Bydžov",
        kod: 570508,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.241602,
        longitude: 15.490926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Coat_of_arms_of_Nov%C3%BD_Byd%C5%BEov.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 1:2. V bílém svatováclavská koruna, v červeném český lev. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Nový Dům",
        kod: 542181,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26901,
        latitude: 50.08824,
        longitude: 13.831001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Nov%C3%BD_D%C5%AFm_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, zelený, bílý a zelený, v poměru 1 : 1 : 12 : 1 : 1. V žerďové části prostředního pruhu zubří hlava s houžví a s latinským křížem vyrůstajícím mezi rohy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se zeleno-stříbrným oblačným lemem pod volnou krokví zubří hlava s houžví a s latinským křížem vyrůstajícím mezi rohy, vše zlaté."
    },
    {
        obec: "Nový Dvůr",
        kod: 599654,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28932,
        latitude: 50.238112,
        longitude: 15.022165,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Coats_of_arms_Nov%C3%BD_Dv%C5%AFr.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a žlutý, v poměru 1:6:1. V žerďové polovině zeleného pruhu žlutá brána do dvora s červenou střechou a otevřenými vraty na černých závěsech. Pod ní vodorovně bílá kypřice prostrčená žlutým obilným klasem se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá brána do dvora s červenou střechou a otevřenými vraty na černých závěsech, pod ní položená stříbrná kypřice prostrčená zlatým obilným klasem se dvěma listy."
    },
    {
        obec: "Nový Hrádek",
        kod: 574287,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54922,
        latitude: 50.357375,
        longitude: 16.244478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Coat_of_arms_of_Nov%C3%BD_Hr%C3%A1dek.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - červený a bílý a zelené žerďové pole sahající do jedné třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá dvouuchá amfora s třemi přirozenými růžemi a dvěma svěšenými červenými tulipány po stranách, vše se zelenými listy."
    },
    {
        obec: "Nový Hrozenkov",
        kod: 544566,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75604,
        latitude: 49.337068,
        longitude: 18.198046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Coat_of_arms_of_Nov%C3%BD_Hrozenkov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - bílý, zelený a bílý v poměru 1:3:1. U žerdě v třetině zeleného pruhu žlutá šestilistá růže s červeným semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá šestilistá růže s červeným semeníkem nad třemi stříbrnými břevny."
    },
    {
        obec: "Nový Jáchymov",
        kod: 531600,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26703,
        latitude: 49.980867,
        longitude: 13.943296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Novy_Jachymov_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - červený, žlutý a zelený v poměru 3:2:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad dvěma zelenými vrchy spojenými modrou hladinou zlaté vycházející slunce provázené nahoře palečným kolem a hornickými kladívky, oboje stříbrné."
    },
    {
        obec: "Nový Jičín",
        kod: 599191,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74101,
        latitude: 49.594425,
        longitude: 18.013636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Coa_Novy_Jicin.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část s polovinou zavinuté střely, vlající část je bílá se zeleným svislým vlnkovitým pruhem se třemi prohlubněmi a dvěma ostrými vrcholy. Zelený pruh na středu přiléhá k bílé zavinuté střele. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nový Jimramov",
        kod: 596264,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59242,
        latitude: 49.634722,
        longitude: 16.187315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Nov%C3%BD_Jimramov_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým svislým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi vycházejícím z třetí desetiny horního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě kosmé vlnité břevno provázené nahoře lilií, dole kosmým vztyčeným gotickým klíčem, vše zlaté."
    },
    {
        obec: "Nový Knín",
        kod: 540901,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26203,
        latitude: 49.788108,
        longitude: 14.293661,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Coa_Novyknin.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé široké jednu polovinu šířky listu nad čtvercovým bílým polem a dva vodorovné pruhy, bílý a červený. V červeném karé český lev, v bílém poli pět černých hranostajích ocásků (2,1,2). Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nový Kostel",
        kod: 554707,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.217131,
        longitude: 12.445362,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a zelený, v poměru 1:2:1. V červeném pruhu bílý žerďový klín s vrcholem na vlajícím okraji pruhu. V modrém pruhu bílé průčelí kostela s černými obloukovými dveřmi a červenou, žlutě hořící střechou, uprostřed s věží s jedním černým obloukovým oknem a červenou stanovou střechou zakončenou žlutým křížkem. V zeleném pruhu bílý vlající klín s vrcholem na vlajícím okraji modrého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně čtvrcený štít dole se stříbrným hrotem. V prvním poli stříbrné průčelí kostela s černými obloukovými dveřmi a červenou, zlatě hořící střechou, uprostřed s věží s jedním černým obloukovým oknem a červenou stanovou střechou zakončenou zlatým křížkem, ve druhém poli stříbrný heroldský kříž, v něm postavené červené housle provázené po stranách dvěma zelenými dračími hlavami s červenými jazyky, v hrotu vyrůstající vztyčený zelený žalud na stonku mezi dvěma odkloněnými listy."
    },
    {
        obec: "Nový Kramolín",
        kod: 554057,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.477383,
        longitude: 12.805087,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Nov%C3%BD_Kramol%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a šedý, v poměru 7 : 3. Z horního okraje šedého pruhu vyniká do žerďové poloviny listu žlutý latinský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý latinský kříž na skále přirozené barvy."
    },
    {
        obec: "Nový Malín",
        kod: 540501,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78803,
        latitude: 49.942722,
        longitude: 17.03201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Nov%C3%BD_Mal%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený v poměru 4:1. Na zeleném pruhu bílý kostel průčelím k vlajícímu okraji, s červenou střechou, věží a žlutými křížky. V průčelí zavřená vrata, nad nimi dvě a v kostelní lodi čtyři prázdná okna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrný kostel, průčelím doleva, s červenou střechou, věží a zlatými křížky. V průčelí zavřená vrata, nad nimi dvě a v kostelní lodi čtyři prázdná okna."
    },
    {
        obec: "Nový Oldřichov",
        kod: 561878,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47113,
        latitude: 50.765514,
        longitude: 14.442254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Nov%C3%BD_Old%C5%99ichov_znak.png",
        coatOfArmsFlagDescription: "Červený list s obloukem vycházejícím z horní poloviny žerďového okraje do druhé třetiny dolního okraje listu. Oblouk tvoří bílé lemy široké jednu šestnáctinu šířky listu, mezi nimi v modrém poli žlutá rozvinutá listová koruna. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě kosmý stříbrně lemovaný modrý oblouk, v něm rozvinutá zlatá listová koruna."
    },
    {
        obec: "Nový Ples",
        kod: 574295,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.321268,
        longitude: 15.94929,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Nov%C3%BD_Ples_znak.jpg",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo vztyčená stříbrná levá rukavice - plechovice, vlevo ze zeleného návrší vyrůstající listnatý strom přirozené barvy."
    },
    {
        obec: "Nový Poddvorov",
        kod: 586463,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69616,
        latitude: 48.868447,
        longitude: 16.961126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Nov%C3%BD_Poddvorov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové pruhy černý a bílý, každý široký jednu desetinu délky listu a zelené pole s bílou půlí kola sv. Kateřiny zlomem k žerdi ve střední části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod černo-stříbrně dělenou hlavou dolní polovina zlomeného stříbrného kola sv. Kateřiny."
    },
    {
        obec: "Nový Přerov",
        kod: 584754,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69181,
        latitude: 48.809718,
        longitude: 16.493828,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Coat_of_arms_of_Nov%C3%BD_P%C5%99erov.svg",
        coatOfArmsFlagDescription: "Červený list s bílým horním vlajícím trojúhelníkem s vrcholy v cípech a ve středu horního okraje. V dolním žerďovém rohu bílý květ se žlutým středem na zeleném stonku se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vztyčená radlice provázená dvěma stříbrnými květy se zlatými středy na zelených stoncích se dvěma listy."
    },
    {
        obec: "Nový Rychnov",
        kod: 548464,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39404,
        latitude: 49.383617,
        longitude: 15.36629,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Nov%C3%BD_Rychnov_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list; uprostřed volná bílá kvádrovaná hradba s cimbuřím, s prázdnou branou, otevřenými vraty a žlutou vytaženou mříží. Za hradbou na jejích krajích dvě stříbrné věže s cimbuřím, každá s černým oknem a s černou valbovou střechou se žlutými makovicemi. Mezi věžemi vyrůstá k žerďovému okraji obrácená postava modře oblečeného strážce s černým kloboukem, troubícího na bílý roh v pravici a držícího přes levé rameno bílou halapartnu na hnědé násadě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná kvádrovaná hradba s cimbuřím, s prázdnou branou, otevřenými vraty a zlatou vytaženou mříží. Za hradbou dvě stříbrné věže s cimbuřím, každá s černým oknem a s černou valbovou střechou se zlatými makovicemi. Mezi věžemi vyrůstá postava modře oblečeného strážce s černým kloboukem, troubícího na stříbrný roh v pravici a držícího přes levé rameno stříbrnou halapartnu na násadě přirozené barvy."
    },
    {
        obec: "Nový Šaldorf-Sedlešovice",
        kod: 587729,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67181,
        latitude: 48.830248,
        longitude: 16.060675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Nov%C3%BD_%C5%A0aldorf_Sedle%C5%A1ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, uprostřed bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít, vpravo vztyčený vinařský nůž (kosíř), vlevo lilie, obojí stříbrné."
    },
    {
        obec: "Nový Telečkov",
        kod: 591262,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.32127,
        longitude: 15.938454
    },
    {
        obec: "Nový Vestec",
        kod: 538566,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25075,
        latitude: 50.183861,
        longitude: 14.720401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Coats_of_arms_Nov%C3%BD_Vestec.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, vlnitý žlutý, vlnitý zelený a zvlněný žlutý, v poměru 5 : 1 : 1 : 1. Zvlněné pruhy mají tři vrcholy a dvě prohlubně. V žerďové polovině horního zeleného pruhu žlutý vztyčený dubový list. V horní vlající čtvrtině žluto - modře (4 x 6) šachované pole. Poměr délky k šířce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se zlato - modře šachovanou levou čtvrtí nahoře vztyčený dubový list, dole dvě vlnitá břevna, vše zlaté."
    },
    {
        obec: "Nučice",
        kod: 533548,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.955626,
        longitude: 14.8845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Coats_of_arms_Nu%C4%8Dice.jpeg",
        coatOfArmsFlagDescription: "Žluto - červeně šachovaný list (2 x 3), dolní střední pole bílé. V horním červeném poli žlutý jehličnatý strom, v bílém červený kvádrovaný pilíř s patkou a deskou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně děleném štítě červeno-stříbrně dělený kůl, v něm nahoře zlatý jehličnatý strom, dole červený kvádrovaný pilíř s patkou a deskou."
    },
    {
        obec: "Nučice",
        kod: 531618,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25216,
        latitude: 50.018581,
        longitude: 14.230078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Nu%C4%8Dice_%28Prague-West%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List kosmo dělený. V černém žerďovém poli žlutý kahan, v zeleném vlajícím poli černá zkřížená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-černě kosmo dělený štít. Nahoře černá zkřížená hornická kladívka, dole zlatý hornický kahan."
    },
    {
        obec: "Nupaky",
        kod: 564907,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.994052,
        longitude: 14.602336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Nupaky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, červený a modrý, přes ně zkřížené žluté gotické klíče. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní pole červeno-stříbrně polcené, vpravo zkřížené vztyčené zlaté gotické klíče, vlevo modrý zvon se závěsem, v dolním modrém poli zlatý plot se stříbrnými hroty."
    },
    {
        obec: "Nýdek",
        kod: 507091,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73995,
        latitude: 49.656185,
        longitude: 18.756984,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Nydek_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, ze kterého do modrého pruhu vyrůstá žlutá orlice s červenou zbrojí. Poměr šířky k délce listu je 2:3. V modrém pruhu žlutý latinský jetelový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zvýšeném zeleném trojvrší, z něhož vyrůstá zlatá orlice s červenou zbrojí, zlatý latinský jetelový kříž."
    },
    {
        obec: "Nyklovice",
        kod: 596272,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.603976,
        longitude: 16.345044,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Nyklovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým poloeliptickým polem, jehož delší osu tvoří žerďový okraj. Bílé pole je položené na žluté poloeliptické pole sahající do poloviny délky listu, jehož kratší osu tvoří též žerďový okraj. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-černě polcený štít, vpravo zlatý kolovrat, vlevo vyniká polovina stříbrné osmihroté hvězdy, provázená mezi hroty čtyřmi zlatými chleby s odkrojenou patkou."
    },
    {
        obec: "Nymburk",
        kod: 537004,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.185682,
        longitude: 15.04376,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Nymburk_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed listu znak města. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Nýrov",
        kod: 582107,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67972,
        latitude: 49.528083,
        longitude: 16.539265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Coa_Czech_Town_N%C3%BDrov.svg",
        coatOfArmsFlagDescription: "Červený list s bílým horním vlajícím trojúhelníkem, v něm červený kosmý pruh vycházející ze šesté a sedmé devítiny horního okraje do druhé třetiny vlajícího okraje listu. V dolních dvou třetinách žerďové poloviny listu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně kosmo děleném štítě nahoře dvě kosmo položené červené růže se zlatými semeníky a zelenými kališními lístky, dole stříbrná lilie."
    },
    {
        obec: "Nýrsko",
        kod: 556831,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34022,
        latitude: 49.293979,
        longitude: 13.143639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Nyrsko_znak.JPG"
    },
    {
        obec: "Nýřany",
        kod: 559300,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.712816,
        longitude: 13.204705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Nyrany_znak.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří čtyři vodorovné pruhy; světle modrý (2/5 šířky) se žlutým pšeničným trojklasem v horním žerďovém rohu (výška trojklasu je 1/5 šířky), zelený (1/10 šířky), černý (1/10 šířky) a bílý (2/5 šířky); poměr délky k šířce je 3:2."
    },
    {
        obec: "Občov",
        kod: 513571,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.713206,
        longitude: 14.066994,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Ob%C4%8Dov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy bílý a černý v poměru 3 : 1. Z černého pruhu vyrůstá zelený dub s hnědým kmenem, v koruně žlutá sekera na bílém topůrku, ostřím k žerďovému okraji listu. V černém pruhu žlutá hornická kladívka na bílých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z černé paty dub přirozené barvy, v koruně zlatá sekera na stříbrném topůrku. V patě zlatá hornická kladívka na stříbrných topůrkách."
    },
    {
        obec: "Obecnice",
        kod: 540935,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26221,
        latitude: 49.716261,
        longitude: 13.947414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Obecnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 3 : 1. Z prostředních dvou čtvrtin horního okraje zeleného pruhu vyniká zelený vrch, na něm tokající tetřev hlušec. V zeleném poli dvě odvrácené bílé sekery se žlutými topůrky přeloženými bílou pilou-břichatkou se žlutými rukojeťmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném vrchu se dvěma odvrácenými stříbrnými sekerami se zlatými topůrky přeloženými stříbrnou pilou-břichatkou se zlatými rukojeťmi tokající tetřev hlušec přirozené barvy."
    },
    {
        obec: "Obědkovice",
        kod: 589799,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79823,
        latitude: 49.394852,
        longitude: 17.227643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Ob%C4%9Bdkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 5:3. V modrém pruhu nesou dva bílí letící ptáci v červených zobácích bílou, žlutě zdobenou mitru s infulemi a třemi červenými křížky pod sebou. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném sníženém vydutém hrotě s červenou růží se zlatým semeníkem a zelenými kališními lístky stříbrná mitra s infulemi zdobená třemi červenými křížky pod sebou a zlatým lemováním, nesená stříbrnými letícími ptáky s červenými zobáky."
    },
    {
        obec: "Obědovice",
        kod: 548065,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.161163,
        longitude: 15.582212,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/CoA_of_Ob%C4%9Bdovice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, modrý, bílý a modrý, v poměru 6:1:1:1. V červeném pruhu žlutý vztyčený rošt mezi dvěma bílými věžemi s cimbuřím a třemi střílnami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad modrou patou se stříbrným břevnem vztyčený rošt provázený dvěma stříbrnými věžemi s cimbuřím a třemi střílnami."
    },
    {
        obec: "Obora",
        kod: 582115,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.452311,
        longitude: 16.605726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Obora_%28Blansko%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy černý, bílý, zelený a žlutý, v poměru 1:4:3:2. V žerďové polovině zeleného pruhu hnědý kmen listnatého stromu, jehož zelená koruna vyrůstá do bílého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně vlnitě děleném štítě s černou hlavou a zlatou patou uťatý listnatý strom přirozené barvy."
    },
    {
        obec: "Obora",
        kod: 559318,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.889575,
        longitude: 13.413718,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Obora_PS_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu osminu šířky listu. V žerďové části bílého pole svislý modrý pruh, široký jednu třetinu délky listu, se třemi žlutými kosmými pruhy vycházejícímu z lichých šestin žerďového okraje pruhu do sudých šestin vlajícího okraje pruhu. Vedle modrého pruhu v bílém poli vztyčený plamenný meč obklopený plaménky, vše červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě se zeleným lemem vpravo tři zlatá kosmá břevna, vlevo vztyčený plamenný meč obklopený plaménky, vše červené."
    },
    {
        obec: "Obora",
        kod: 541443,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.814715,
        longitude: 12.5378,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Obora_%28Tachov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený a modrý, v poměru 2 : 1. V zeleném pruhu u modrého pruhu bílý plot vysoký čtvrtinu šířky listu. Nad ním dva odkloněné žluté jelení parohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou patou stříbrný plot. Nad ním dva odkloněné zlaté jelení parohy."
    },
    {
        obec: "Obora",
        kod: 542580,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.373356,
        longitude: 13.863081,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Obora_LN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, vlnitý modrý a zvlněný bílý. Modrý pruh má dva vrcholy, v každém žlutá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad sníženým modrým vlnitým břevnem se dvěma zlatými hvězdami kosmý losos přirozené barvy."
    },
    {
        obec: "Oborná",
        kod: 569577,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 50.003161,
        longitude: 17.497004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Oborn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1:3:1. V zeleném pruhu žluté váhy, mezi miskami bílý čtyřlistý luční květ se žlutými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zprava vynikající stříbrně oděná levá paže přirozené barvy držící zlaté miskové váhy. Pod nimi ze stříbrného návrší vyrůstající na zlatém stonku stříbrný čtyřlistý květ se zlatými kališními lístky."
    },
    {
        obec: "Obory",
        kod: 540943,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.680504,
        longitude: 14.216474
    },
    {
        obec: "Obořiště",
        kod: 540951,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26212,
        latitude: 49.742352,
        longitude: 14.151959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Obo%C5%99i%C5%A1t%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a zelený. V žerďovém pruhu žlutý okřídlený orlí pařát drápy k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo vztyčený zlatý okřídlený orlí pařát, vlevo zelená vykořeněná palma."
    },
    {
        obec: "Obrataň",
        kod: 548472,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39412,
        latitude: 49.425159,
        longitude: 14.943589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Obrata%C5%88_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s modrým svatopetrským křížem širokým jednu osminu délky listu, s vodorovnými rameny ve dvacáté až pětadvacáté dvaatřicetině šířky listu, s bílým lemem širokým jednu čtyřiadvacetinu délky listu. V kříži meč hrotem k hornímu okraji listu, v horním a ve vodorovných ramenech provázený jednou hvězdou. Po stranách horního ramene kříže v zelených polích odvrácená podkova, každá po stranách provázená dvěma hvězdami, vnitřní mezi rameny podkovy. Meč, podkovy a sedmicípé hvězdy bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě modrý, stříbrně lemovaný svatopetrský kříž. V něm vztyčený meč provázený v postranních ramenech kříže a nahoře sedmihrotou hvězdou, v horních polích štítu čtyři sedmihroté hvězdy (2, 2), horní uvnitř odvrácených podkov, vše stříbrné."
    },
    {
        obec: "Obrnice",
        kod: 567337,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43521,
        latitude: 50.505083,
        longitude: 13.695511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Obrnice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým ondřejským křížem s rameny širokými jednu osminu šířky listu, jejichž osy vycházejí z poloviny žerďového a vlajícího okraje a z dolního rohu a cípu. Nad křížem modrý vinný hrozen se třemi zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad sníženým modrým ondřejským křížem modrý vinný hrozen se třemi zelenými listy."
    },
    {
        obec: "Obrubce",
        kod: 570893,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.447959,
        longitude: 15.06372
    },
    {
        obec: "Obruby",
        kod: 536407,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29403,
        latitude: 50.460227,
        longitude: 15.082414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Obruby_CoA.png",
        coatOfArmsFlagDescription: "Žluto-zeleně šikmo dělený list. Ve žlutém poli tři modré vodorovné pruhy, v zeleném šikmo květ upolínu evropského na stonku s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně šikmo děleném štítě nahoře tři modrá břevna, dole šikmo květ upolínu evropského na stonku s listem, vše zlaté."
    },
    {
        obec: "Obříství",
        kod: 535133,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27742,
        latitude: 50.295983,
        longitude: 14.478492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Ob%C5%99%C3%ADstv%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list; v žerďové části tři žluté šesticípé hvězdy pod sebou, ve střední a vlající části šikmo žlutá kotva, na žerďové straně se třemi strunami lyry mezi dříkem a ramenem a na vlající straně provázená nad hrotem bílým tlapatým křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě šikmá kotva, mezi dříkem a horním ramenem tři struny lyry, vše zlaté. Vlevo nad hrotem stříbrný tlapatý křížek. Vpravo nahoře tři zlaté hvězdy vedle sebe."
    },
    {
        obec: "Obyčtov",
        kod: 596281,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.499959,
        longitude: 16.001685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Oby%C4%8Dtov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďová část se žlutým karé nad červeným čtvercovým polem a dva vodorovné pruhy: červený a žlutý. V karé červený lev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrným břevnem oddělenou hlavou stříbrná želva se zlatým krunýřem. Hlava štítu třikrát zlato-červeně polcená, v ní čtyři lvi opačných barev."
    },
    {
        obec: "Obytce",
        kod: 541915,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.390514,
        longitude: 13.378436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Obytce_CoA.JPG",
        coatOfArmsFlagDescription: "Černo-bíle kosmo dělený list přeložený šikmým černo-bíle děleným pruhem širokým pětinu šířky listu. V žerďovém poli žluté vozové kolo se čtyřmi rameny, ve vlajícím poli vztyčený zelený lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-černě kosmo dělený štít se šikmým černo-stříbrně děleným břevnem. Vpravo zlaté vozové kolo se čtyřmi rameny, vlevo vztyčený zelený lipový list."
    },
    {
        obec: "Ocmanice",
        kod: 510980,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.233244,
        longitude: 16.125399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Ocmanice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 3:1:3. Uprostřed listu černý kráčející býk s červenou zbrojí a černým křídlem se žlutým jetelovitě zakončeným perizoniem. Pod býkem v zeleném pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným kůlem kráčející černý býk s červenou zbrojí a černým křídlem se zlatým, jetelovitě zakončeným perizoniem. Pod býkem stříbrná zavinutá střela."
    },
    {
        obec: "Očelice",
        kod: 576557,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.239986,
        longitude: 16.067392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/O%C4%8Delice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Do bílého pruhu vyrůstají tři zelené listnaté stromy s černými kmeny, prostřední vyšší. V zeleném pruhu bílý pluh s koly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně děleném štítě nahoře vyrůstají tři zelené listnaté stromy s černými kmeny, prostřední vyšší, dole stříbrný pluh s koly."
    },
    {
        obec: "Očihov",
        kod: 566527,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43987,
        latitude: 50.19866,
        longitude: 13.460534,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/O%C4%8Dihov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a modrý, v poměru 11 : 2 : 11. Žlutý pruh je obtočený bílým chmelovým stonkem a bílým korunovaným hadem s červeným jazykem. Chmelový stonek má v červeném pruhu šištici s jedním listem a v modrém pruhu jeden list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě zlatý zúžený kůl obtočený zprava stříbrným chmelovým stonkem s šišticí a dvěma listy a zleva stříbrným korunovaným hadem s červeným jazykem."
    },
    {
        obec: "Odolena Voda",
        kod: 538574,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25070,
        latitude: 50.233515,
        longitude: 14.410889,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Odolena_Voda_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: bílý, modrý a bílý v poměru 2:1:2. Modrý pruh se v žerďové třetině rozšiřuje v modrý žerďový klín. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrným křídlem ze zlaté kotvy vyrůstající latinský kříž."
    },
    {
        obec: "Odrava",
        kod: 539554,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.103171,
        longitude: 12.48425,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Odrava_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1:2:1. V zeleném pruhu žlutý kvádrovaný most se třemi oblouky a dvěma pilíři, z něhož vyrůstá pět žlutých odkloněných obilných klasů. Pod mostem bílý vlnitý pruh se třemi vrcholy a dvěma prohlubněmi, široký jednu dvacetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným vlnitým břevnem v patě štítu zlatý kvádrovaný most do krajů se třemi oblouky a dvěma pilíři, z něhož vyrůstá pět odkloněných zlatých obilných klasů."
    },
    {
        obec: "Odrovice",
        kod: 583529,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.010907,
        longitude: 16.509119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Odrovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu tři spojené, kosmo postavené a od žerdi odkloněné žluté obilné klasy, ve žlutém pruhu modrý vinný hrozen na zeleném stonku se čtyřmi úponky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo tři spojené, kosmo postavené a doleva odkloněné zlaté obilné klasy. Vlevo modrý vinařský nůž-kosíř s dřevěnou rukojetí přirozené barvy nad modrým vinným hroznem na zeleném stonku se čtyřmi úponky."
    },
    {
        obec: "Odřepsy",
        kod: 537551,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28907,
        latitude: 50.14424,
        longitude: 15.188842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Od%C5%99epsy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 1 : 2 : 1. V bílém pruhu červený pes ve skoku s modrou zbrojí, jazykem a obojkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou patou. Vpravo červený pes ve skoku s modrou zbrojí, jazykem a obojkem, vlevo doleva obrácená stříbrná saň ve skoku šikmo dolů probodnutá zlatým kopím. V patě štítu zlatý květ blatouchu."
    },
    {
        obec: "Odry",
        kod: 599701,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.662648,
        longitude: 17.83095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Odry_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou věží na trámcové konstrukci. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Odunec",
        kod: 591289,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.120315,
        longitude: 16.014372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Odunec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 4:1:4. V horním modrém pruhu bílý šestilistý květ se žlutým středem, v dolním modrém pruhu žlutá dolů obrácená svatojakubská mušle, oboje mezi dvěma žlutými obilnými klasy, každý s jedním odvráceným listem, podloženými pod bílým pruhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno provázené nahoře stříbrným šestilistým květem se zlatým středem, dole zlatou, dolů obrácenou svatojakubskou mušlí a podložené po stranách dvěma zlatými obilnými klasy s jedním odvráceným listem do stran."
    },
    {
        obec: "Ohařice",
        kod: 549185,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.454396,
        longitude: 15.258528
    },
    {
        obec: "Ohaře",
        kod: 533556,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28130,
        latitude: 50.097882,
        longitude: 15.29512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Oha%C5%99e_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list. Uprostřed bílá hlava ohaře s krkem, provázená nahoře dvěma vztyčenými přivrácenými žlutými lipovými listy s plodenstvím. U dolního okraje listu pět žlutých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná hlava ohaře s krkem provázená nahoře dvěma vztyčenými přivrácenými lipovými listy s plodenstvím, dole do oblouku pět hvězd, vše zlaté."
    },
    {
        obec: "Ohaveč",
        kod: 548910,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.4497,
        longitude: 15.30877
    },
    {
        obec: "Ohníč",
        kod: 567761,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41765,
        latitude: 50.587135,
        longitude: 13.844786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Ohn%C3%AD%C4%8D_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a červený, v poměru 1:4:1. V zeleném pruhu bílý klín vycházející z horního okraje pruhu s vrcholem na středu dolního okraje listu. V klínu vztyčený černý hříč, provázený mezi rameny odvrácenými červenými plameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný klín, v něm vztyčený černý hříč provázený mezi rameny odvrácenými červenými plameny."
    },
    {
        obec: "Ohnišov",
        kod: 576565,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51784,
        latitude: 50.322692,
        longitude: 16.213912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Ohni%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým žerďovým pruhem širokým čtvrtinu délky listu, v pruhu modrý dvojkříž s rameny širokými jednu dvanáctiny délky listu a vycházejícími z okrajů pruhu. V červeném poli žlutý ohnivý kotouč s tváří k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou hlavou s položeným modrým dvojramenným heroldským křížem zlatý ohnivý kotouč s tváří vpravo."
    },
    {
        obec: "Ohnišťany",
        kod: 570516,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50354,
        latitude: 50.32963,
        longitude: 15.511087,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Ohnistany_znak.png",
        coatOfArmsFlagDescription: "Bílý list s knížecí čepicí v horním žerďovém modrém trojúhelníku, vycházejícím z pěti osmin žerďového okraje do horního cípu, a s červeným ohněm o třech plamenech vynikajícím z druhé poloviny dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně zvýšeně šikmo děleném štítě nahoře knížecí čepice, dole červený oheň o třech plamenech vynikající z dolního okraje štítu."
    },
    {
        obec: "Ohrazenice",
        kod: 577359,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.597745,
        longitude: 15.126071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Ohrazenice_%28Semily_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy žlutý a modrý, ve střední části listu dole volný obelisk na soklu o dvou stupních (pyrám) mezi dvěma přivrácenými lvy s červenou zbrojí, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě dole volný obelisk na soklu o dvou stupních (pyrám) mezi dvěma přivrácenými lvy s červenou zbrojí, vše opačných barev."
    },
    {
        obec: "Ohrazenice",
        kod: 540960,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26223,
        latitude: 49.78714,
        longitude: 13.959817,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Ohrazenice_%28P%C5%99%C3%ADbram_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě zelených a bílých, v poměru 1 : 1 : 1 : 1 : 1 : 1 : 6. K dolnímu rohu a cípu přiléhají žlutá obdélníková pole dlouhá tři osminy délky a široká třetinu šířky listu, na horním okraji se čtyřmi pilovitými zuby dosahujícími do sedmé dvanáctiny šířky listu. Mezi poli bílý hřeb kosmo podložený želízkem a šikmo přeložený mlátkem, obojí obrácené, bílé a se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zvýšeném zeleném návrší tři zelené jehličnaté stromy s černými kmeny. V návrší, v přerušené zlaté palisádové hradbě stříbrný hřeb kosmo podložený želízkem a šikmo přeložený mlátkem, obojí obrácené, stříbrné a se zlatými topůrky."
    },
    {
        obec: "Ohrobec",
        kod: 539503,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25245,
        latitude: 49.941829,
        longitude: 14.432139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Ohrobec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 5 : 2. V horní polovině žerďové části listu prázdná bílá zvonička, nahoře provázená lilií a postaveným lipovým listem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku prázdná stříbrná zvonička provázená nahoře vpravo lilií, vlevo vztyčeným lipovým listem, obojí zlaté."
    },
    {
        obec: "Ohrozim",
        kod: 589802,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.48656,
        longitude: 17.02073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Ohrozim_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý klín s vrcholem na vlajícím okraji a horní žluté a dolní červené trojúhelníkové pole. V modrém klínu bílý meč se žlutým jílcem a záštitou hrotem k vlajícímu okraji, překrytý na čepeli červenou, žlutě zdobenou knížecí korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zlatě polcený štít s modrou hlavou. V ní doleva položený stříbrný meč se zlatým jílcem a záštitou, přeložený červenou zlatě zdobenou knížecí korunou. Vpravo tři zlaté ryby pod sebou, vlevo půl černé zubří hlavy se zlatou houžví."
    },
    {
        obec: "Ochoz u Brna",
        kod: 583537,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66402,
        latitude: 49.254302,
        longitude: 16.737338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Znak_obce_Ochoz_u_Brna.svg",
        coatOfArmsFlagDescription: "Zelený list se čtyřmi bílými žerďovými klíny s hroty sahajícími do 1/3 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod čtyřmi klíny v hlavě zkřížený kopáč a sekyra nad radlicí, vše stříbrné."
    },
    {
        obec: "Ochoz u Tišnova",
        kod: 582123,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.425693,
        longitude: 16.394338
    },
    {
        obec: "Okarec",
        kod: 591297,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67502,
        latitude: 49.206057,
        longitude: 16.088033,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 7 : 1. Ve žlutém pruhu čelně červená radlice hrotem k hornímu okraji listu, provázená nahoře dvěma zelenými jetelovými trojlístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou čelně vztyčená červená radlice provázená nahoře dvěma zelenými jetelovými trojlístky."
    },
    {
        obec: "Okna",
        kod: 561886,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47162,
        latitude: 50.527594,
        longitude: 14.671066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Okna_%C4%8CL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Uprostřed listu vodorovný žluto-černě polcený ostrvový pruh se čtyřmi (2, 2) odvrácenými suky, široký dvacetinu délky listu, nahoře a dole provázený žluto-černě polcenými odvrácenými horními polovinami lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě zlato-černě polcené zúžené ostrvové břevno se čtyřmi (2, 2) odvrácenými suky, provázené nahoře a dole zlato-černě polcenými odvrácenými horními polovinami lilií."
    },
    {
        obec: "Ochoz",
        kod: 589811,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.600231,
        longitude: 16.915109,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Ochoz_PV_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílý zvon se zeleným obilným klasem se dvěma listy zakončený nahoře červenou, žlutě zdobenou knížecí čepicí, podložený žlutou rozletitou střelou se třemi (2,1) hroty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný zvon se zeleným obilným klasem se dvěma listy zakončený nahoře červenou, zlatě zdobenou knížecí čepicí a podložený zlatou rozletitou střelou se třemi (2,1) hroty."
    },
    {
        obec: "Okoř",
        kod: 571334,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25264,
        latitude: 50.162011,
        longitude: 14.258724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Okor_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými vlnitými pruhy, vycházejícími z dvanácté a čtrnácté patnáctiny žerďového a vlajícího okraje. V žerďové a střední části vyrůstá z horního bílého pruhu kvádrovaná hradba s cimbuřím, z níž vyniká ve střední části listu věž s cimbuřím a prázdným gotickým oknem, vše žluté. Vedle věže nad hradbou žluto-červeně šikmo pilovitě dělený štítek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá z vlnité, třikrát stříbrno-modře vlnitě dělené paty kvádrovaná hradba s cimbuřím, z níž vlevo vyniká věž s cimbuřím a prázdným gotickým oknem, vše zlaté. Vpravo nahoře zlato-červeně šikmo pilovitě dělený štítek."
    },
    {
        obec: "Okounov",
        kod: 563269,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43151,
        latitude: 50.362327,
        longitude: 13.106679,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Okounov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký čtvrtinu délky listu se sedmi střídavě červenými a bílými vodorovnými pruhy a zelené pole s daňkem ve skoku ve střední části listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně vpravo polcený štít, vpravo tři stříbrná břevna, vlevo daněk ve skoku přirozené barvy."
    },
    {
        obec: "Okrouhlá",
        kod: 529877,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.413732,
        longitude: 14.364412
    },
    {
        obec: "Okrouhlá",
        kod: 582131,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.507604,
        longitude: 16.732103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Okrouhla_znak.gif",
        coatOfArmsFlagDescription: "Zeleně-bílo šikmo dělený list; v bílém poli tři vodorovné pruhy, modrý, zelený a modrý, vycházející z osmé, deváté a desáté dvanáctiny vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo děleném štítě nahoře stříbrné brány, dole prohnutý stonek lnu přirozené barvy."
    },
    {
        obec: "Okrouhlá",
        kod: 538922,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.062566,
        longitude: 12.487772,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Okrouhla_CH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-červeně kosmo a šikmo čtvrcený list s kruhovým polem o průměru jedné poloviny šířky listu, modro-bíle čtvrceným úhlopříčkami listu. Z modrých polí do žerďového pole vyrůstá zelená větev jasanu o třech výhoncích se třemi listy a do vlajícího pole zelená větev dubu o pěti listech a se dvěma žaludy u modrého pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně kosmo čtvrceném štítě stříbrno-modře kosmo čtvrcený kotouč, z něhož do pravého pole vyrůstá zelená větev jasanu o třech výhoncích, každý se třemi listy a do levého pole zelená větev dubu s pěti listy a dvěma žaludy pod nimi."
    },
    {
        obec: "Okrouhlá",
        kod: 561894,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47301,
        latitude: 50.766932,
        longitude: 14.527685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Okrouhl%C3%A1_%28%C4%8Cesk%C3%A1_L%C3%ADpa_District%29_CoA.png",
        coatOfArmsFlagDescription: "Červený list, z první a šesté šestiny žerďového okraje vychází žluto-černě dělená vypouklá krokev s vrcholem ve středu listu. Žerďové pole poseto bílými kruhovými terčíky. Z šesté a desáté desetiny horního a dolního okraje listu vychází bílý ondřejský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlato-černě polcená snížená vydutá krokev, nad ní zkřížený štětec a řezák skla na násadce, pod ní pole poseto kruhovými terčíky, vše stříbrné."
    },
    {
        obec: "Okrouhlá Radouň",
        kod: 562475,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.239826,
        longitude: 15.017172,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Coat_of_arms_of_Okrouhl%C3%A1_Radou%C5%88.svg",
        coatOfArmsFlagDescription: "Šikmo dělený list. V žluté žerďové části černé zkřížené kladivo a mlátek, v modré vlající části žlutá růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře šikmo dělený štít, nahoře černá hornická kladívka, dole zlatá růže se zlatým semeníkem."
    },
    {
        obec: "Okrouhlice",
        kod: 569186,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58231,
        latitude: 49.630014,
        longitude: 15.490933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Okrouhlice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, černý ostrvový o pěti sucích (2,3) a modrý, v poměru 4:1:4. Ve žlutém pruhu černé beraní rohy, v modrém bílý kráčející kohout s červenou zbrojí a černými ocasními pery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou s černými beraními rohy, oddělenou černou ostrví s pěti suky (2,3) kráčející stříbrný kohout s červenou zbrojí a černými ocasními péry."
    },
    {
        obec: "Okrouhlička",
        kod: 548588,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58253,
        latitude: 49.534541,
        longitude: 15.556975
    },
    {
        obec: "Okrouhlo",
        kod: 539511,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25401,
        latitude: 49.918924,
        longitude: 14.447417
    },
    {
        obec: "Okřesaneč",
        kod: 534251,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.852528,
        longitude: 15.475842
    },
    {
        obec: "Okřešice",
        kod: 546933,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.25948,
        longitude: 15.852076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Ok%C5%99e%C5%A1ice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed bílá lilie na stonku se šesti listy, provázená dvěma odvrácenými cepy, žlutým v modrém pruhu, černým ve žlutém pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě stříbrná lilie na stonku se šesti listy, provázená dvěma odvrácenými cepy, vpravo zlatým a vlevo černým."
    },
    {
        obec: "Okřínek",
        kod: 537560,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.164391,
        longitude: 15.192429
    },
    {
        obec: "Okříšky",
        kod: 591301,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.245493,
        longitude: 15.769689,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Ok%C5%99%C3%AD%C5%A1ky_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou lilií ve tvaru majuskulního písmene M nad žlutým železničním kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým železničním kolem stříbrná lilie ve tvaru majuskulního písmene M."
    },
    {
        obec: "Olbramice",
        kod: 554049,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74283,
        latitude: 49.78965,
        longitude: 18.089056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Olbramice_OS_CZ_CoA.png",
        coatOfArmsFlagDescription: "Červený list se zeleným žerďovým pruhem širokým jednu třetinu délky listu. Na dělící lince žlutá loutka panoše s bílou tváří, krkem a rukama, držící ve zdvižené pravici vpravo šikmo zlatou radlici a v levici kosmo stříbrný nůž se zlatou rukojetí, oboje ostřím k hlavě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě zlatá loutka panoše se stříbrnou tváří a krkem, držící ve zdvižených stříbrných rukou vpravo šikmo zlatou radlici a vlevo kosmo stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Olbramice",
        kod: 552259,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.613591,
        longitude: 17.001576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Olbramovice_OL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed bílý beránek se žlutou zbrojí, s obojkem se zvonkem na krku, obojí žluté. Levou přední nohou přidržuje žlutou žerď s bílou korouhví s ocasem nesoucí k žerdi obrácenou červenou zavinutou střelu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrný beránek se zlatou zbrojí, s obojkem se zvonkem na krku, obojí zlaté. Levou přední nohou přidržuje zlatou žerď se stříbrnou korouhví s ocasem nesoucí k žerdi obrácenou červenou zavinutou střelu."
    },
    {
        obec: "Olbramkostel",
        kod: 594555,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67151,
        latitude: 48.921815,
        longitude: 15.949704,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Coat_of_arms_of_Olbramkostel.jpg",
        coatOfArmsFlagDescription: "Červený list se zeleným návrším vycházejícím z druhé až šesté sedminy dolního okraje listu, z něj vynikají dvě bílé okrouhlé věže s rozšířeným přízemím a ochozem pod černou stanovou střechou zakončenou makovicí s odvrácenou trojúhelníkovou korouhvičkou, oboje žluté. Každá věž s obloukovým vchodem se žlutými dveřmi na černých závěsech s panty ke středu, a s pěti černými okny, dvěma obloukovými v patře a třemi čtvercovými v ochozu. Mezi věžemi bíle spárovaná cihlová hradba s kruhovými střílnami ve třech zubech a klíčovou střílnou v hradbě, všechny černé. Nad hradbou sypen zkřížený se sladovnickým veslem, obojí žluté. Poměř šířky k délce listu je 2 : 3."
    },
    {
        obec: "Olbramovice",
        kod: 594563,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67176,
        latitude: 48.984904,
        longitude: 16.402498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Olbramovice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, červený, bílý a zelený, v poměru 1:1:4:1:1. V červeném pruhu žlutý vinný hrozen na stonku se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá z položené zelené hlavy vinné révy postava vinaře v modrém kabátci, s červenou stuhou u krku a černým širokým kloboukem, držící v pravici zlatý vinný hrozen se zelenými listy, v levici stříbrný vinařský nůž s rukojetí přirozené barvy."
    },
    {
        obec: "Olbramov",
        kod: 566888,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.843499,
        longitude: 12.866878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Olbramov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři žerďové pruhy, červený, žlutý a červený, každý široký jednu dvanáctinu délky listu, a bílé pole s kráčejícím černým vlkem s červeným jazykem, z jehož hřbetu vzlétá černý havran s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatým břevnem kráčející černý vlk s červeným jazykem, z jehož hřbetu vzlétá černý havran s červenou zbrojí."
    },
    {
        obec: "Olbramovice",
        kod: 530344,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25901,
        latitude: 49.67328,
        longitude: 14.640953,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Olbramovice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílo-červenou polcenou orlicí se žlutým perizoniem, zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrno-červeně polcená orlice se zlatým perizoniem, zbrojí a červeným jazykem."
    },
    {
        obec: "Oldřichov",
        kod: 552798,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.558828,
        longitude: 14.754364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Old%C5%99ichov_TA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem v třetině délky listu a dvě stejná trojúhelníková pole s vrcholy v třetinách délky listu. Zbývající horní trojúhelníková pole jsou modrá, dolní červená. V prostředním bílém poli červený dvouramenný kříž s horním delším ramenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeno polceném štítě stříbrný snížený hrot, v něm červený dvouramenný kříž s horním delším ramenem, vpravo stříbrný kůň ve skoku doleva se zlatou zbrojí, hřívou a ocasem a s červeným jazykem, vlevo stříbrný chrt ve skoku se zlatou zbrojí a jazykem a obojkem s kroužkem."
    },
    {
        obec: "Oldřichov",
        kod: 515825,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.49756,
        longitude: 17.539328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Old%C5%99ichov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně šachovaným (8 x 2) žerďovým pruhem širokým šestinu délky listu. V modrém poli zkrácený středový žlutý kříž, svislá ramena zakončena lilií, vodorovná lipovým listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrno-červeně ve dvou řadách šachovanou hlavou zlatý řecký kříž, jehož svislá ramena jsou zakončena lilií a vodorovná lipovým listem."
    },
    {
        obec: "Oldřichov v Hájích",
        kod: 564281,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46331,
        latitude: 50.849733,
        longitude: 15.083777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Oldrichov_v_Hajich_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh, široký jednu čtvrtinu délky listu, a zelené pole. Ve žlutém pruhu červený paroh, v zeleném poli bílý uťatý smrk mezi dvěma odvrácenými bílými sekerami na žlutých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou s doleva položeným červeným parohem stříbrný uťatý smrk mezi dvěma odvrácenými stříbrnými sekerami na zlatých topůrkách."
    },
    {
        obec: "Oldřichovice",
        kod: 549444,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76361,
        latitude: 49.181867,
        longitude: 17.561448,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/CoA_of_Old%C5%99ichovice.svg",
        coatOfArmsFlagDescription: "Žlutý list se dvěma modrými půlkruhovými poli, žerďovým a vlajícím, v každém po jednom žlutém obilném klasu s jedním listem, prohnutém k okraji listu praporu. Ve střední části listu černý řemdih. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě s modrými vydutými boky černý řemdih, v bocích dva odvrácené zlaté obilné klasy s jedním listem."
    },
    {
        obec: "Oldřiš",
        kod: 578479,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56982,
        latitude: 49.727227,
        longitude: 16.19381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Old%C5%99i%C5%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a kvádrovaný bílý v podobě mostu s modrým obloukem na dolním okraji listu, širokým třetinu délky listu. V oblouku žluté palečné kolo. Z mostu vějířovitě vyrůstají tři spojené žluté obilné klasy bez stonků. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrný kvádrovaný most, z něhož vějířovitě vyrůstají tři spojené zlaté obilné klasy bez stonku. V modrém mostním oblouku zlaté palečné kolo."
    },
    {
        obec: "Oldřišov",
        kod: 509574,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74733,
        latitude: 49.991395,
        longitude: 17.960845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Old%C5%99i%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou sovou v žerďové části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná sova."
    },
    {
        obec: "Oleksovice",
        kod: 594571,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67162,
        latitude: 48.902158,
        longitude: 16.248504,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Oleksovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a bílý, v poměru 1:2:1. Do modrého pruhu vyrůstá černá korunovaná orlice se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít. Nahoře vyrůstající černá korunovaná orlice se zlatou zbrojí a stříbrným písmenem R na hrudi, dole zlaté písmeno W."
    },
    {
        obec: "Olešenka",
        kod: 569208,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.549005,
        longitude: 15.765938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Ole%C5%A1enka_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře kosmo dělený list. Uprostřed listu dva vztyčené odvrácené bílé vinařské nože se žlutými rukojeťmi. Z ostří obou čepelí vyrůstá žlutá větvička olše se třemi listy, na prostředním stojí bílý ptáček se žlutou zbrojí zobákem k čepeli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně kosmo děleném štítě dva vztyčené odvrácené stříbrné vinařské nože se zlatými rukojeťmi. Z ostří nožů vyrůstají do stran zlaté větvičky olše se třemi listy, na prostředním stojí do středu obrácený stříbrný ptáček se zlatou zbrojí."
    },
    {
        obec: "Olešná",
        kod: 562190,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.346255,
        longitude: 14.310591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Ole%C5%A1n%C3%A1_%28P%C3%ADsek_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, střídavě modrých a bílých, v poměru 11 : 1 : 1 : 1 : 1 : 1. V žerďové polovině horního pruhu bílá větévka olše s kosmo vztyčeným listem a se žlutým květenstvím, pod listem samičím a k vlajícímu okraji listu samčím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou patou se dvěma modrými břevny stříbrná větévka olše s kosmo vztyčeným listem a se zlatým květenstvím, pod listem samičím a vlevo samčím."
    },
    {
        obec: "Oleško",
        kod: 542440,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.480652,
        longitude: 14.196615,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Ole%C5%A1ko_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed lilie opačných barev. Mezi listy lilie v modrém pruhu žlutý klas a ve žlutém červený květ sinokvětu chrpovitého na zeleném stonku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě polcený štít s lilií opačných barev, mezi listy vpravo zlatý klas, vlevo stonek sinokvětu chrpovitého přirozené barvy."
    },
    {
        obec: "Oleška",
        kod: 533564,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28162,
        latitude: 49.971695,
        longitude: 14.916786
    },
    {
        obec: "Olešná",
        kod: 561967,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.445848,
        longitude: 15.263754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Coat_of_arms_of_Ole%C5%A1n%C3%A1_PE.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a bílý, v poměru 5 : 1 : 4. Ve žlutém pruhu čtyři vztyčené zelené olšové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-stříbrně sníženým modrým vlnitým břevnem dělený štít, nahoře čtyři vztyčené zelené olšové listy."
    },
    {
        obec: "Olešná",
        kod: 569216,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.68167,
        longitude: 15.547785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Ole%C5%A1n%C3%A1_%28Havl%C3%AD%C4%8Dk%C5%AFv_Brod_District%29_CoA.png",
        coatOfArmsFlagDescription: "V modrém listu vztyčená žlutá olšová větev se třemi listy a svěšenými jehnědami, k žerdi třemi samičími a k vlajícímu okraji dvěma samčími, nad bílým mlýnským kamenem s černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčená zlatá olšová větev se třemi listy a svěšenými jehnědami, vpravo třemi samičími a vlevo dvěma samčími, nad stříbrným mlýnským kamenem s černou kypřicí."
    },
    {
        obec: "Olešná",
        kod: 531626,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26764,
        latitude: 49.780462,
        longitude: 13.810119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Ole%C5%A1n%C3%A1_%28Beroun_District%29_CoA.png",
        coatOfArmsFlagDescription: "Kosmo a šikmo čtvrcený list. V horním zeleném poli bílý hřeb kosmo podložený mlátkem a šikmo želízkem, obojí bílé na žlutých topůrkách. V žerďovém bílém poli kosmo vztyčený zelený list olše se třemi samičími květy, ve vlajícím bílém poli šikmo zelený obilný klas s listem. Z dolního okraje dolního zeleného pole vyrůstá bílá hlava koně se žlutým uzděním obrácená k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně kosmo čtvrcený štít. Nahoře stříbrný hřeb kosmo podložený mlátkem a šikmo želízkem, obojí stříbrné na zlatých topůrkách. Vpravo kosmo vztyčený zelený list olše se třemi samičími květy. Vlevo šikmo zelený obilný klas s listem. Dole vyrůstající, doleva obrácená stříbrná hlava koně se zlatým uzděním."
    },
    {
        obec: "Olešná",
        kod: 542199,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26901,
        latitude: 50.128597,
        longitude: 13.695961
    },
    {
        obec: "Olešnice",
        kod: 544884,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37331,
        latitude: 48.84133,
        longitude: 14.704931
    },
    {
        obec: "Olešnice",
        kod: 582158,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.557654,
        longitude: 16.421795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Ole%C5%A1nice-znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, bílý a černý. V žerďové části na žlutém a bílém pruhu černé křídlo se žlutou pružinou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Olešnice",
        kod: 570524,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.142363,
        longitude: 15.445092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Ole%C5%A1nice_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zubatý modrý v poměru 1:2. Modrý pruh má sedm obdélníkových zubů a šest polovičních, čtvercových mezer. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zelenou cimbuřovou hlavou se třemi zlatými vykořeněnými olšemi stříbrný lev s červenou zbrojí a jazykem."
    },
    {
        obec: "Olešnice",
        kod: 576581,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51736,
        latitude: 50.14431,
        longitude: 16.148606
    },
    {
        obec: "Olešnice",
        kod: 577367,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.545045,
        longitude: 15.119305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Ole%C5%A1nice_%28Semily_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý bílý se třemi zuby a dvěma užšími mezerami a zvlněný modrý, v poměru 5 : 2 : 1. V horním pruhu dvě přivrácené větévky olše, se samčím květenstvím v žerďové a se samičím květenstvím ve vlající polovině pruhu, každá se vztyčeným odvráceným listem, vše žluté. Ze zvlněného do bílého pruhu vyniká černé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná hradba o třech stínkách. Nahoře dvě přivrácené větévky olše, vpravo se samčím květenstvím a vlevo se samičím, každá se vztyčeným odvráceným listem, vše zlaté. V hradbě vyniká z modrého vlnitého břevna černé mlýnské kolo."
    },
    {
        obec: "Olešnice v Orlických horách",
        kod: 576573,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51783,
        latitude: 50.37326,
        longitude: 16.30998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Ole%C5%A1nice_v_Orlick%C3%BDch_hor%C3%A1ch_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině tři vodorovné pruhy červený, bílý a červený a ve vlající polovině modré pole se zeleným pruhem na dolním okraji širokým jednu osminu šířky listu. Na zeleném pruhu bílý kostel s věží u středu listu, se dvěma černými okny a červenou stanovou střechou se žlutým křížkem. Loď má dvě černá okna a vchod, červenou střechu a žlutý křížek nad průčelím. Nad kostelem žlutá olšová větévka. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli stříbrné břevno, v levém modrém poli na zeleném trávníku stříbrný kostel s věží vpravo se dvěma černými okny a červenou stanovou střechou se zlatým křížkem. Loď má dvě černá okna a vchod, červenou střechu a zlatý křížek nad průčelím. Nahoře zlatá olšová větévka."
    },
    {
        obec: "Olešník",
        kod: 544892,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37350,
        latitude: 49.107235,
        longitude: 14.363919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Olesnik_znak.jpg",
        coatOfArmsFlagDescription: "Modro-zeleně šikmo dělený list. V modrém poli koruna, v zeleném poli obilný snop, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně šikmo děleném štítě nahoře koruna, dole obilný snop, oboje zlaté."
    },
    {
        obec: "Olomouc",
        kod: 500496,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.593878,
        longitude: 17.250979,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Coat_of_arms_of_Olomouc%2C_city_in_the_Czech_Republic.svg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně šachovanou orlicí se žlutou korunkou a zbrojí a červeným jazykem, zaujímající polovinu délky listu na středu listu. V žerďové části žlutá majuskulní písmena S a Q, ve vlající části obdobná písmena P a O, vždy pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém poli stříbrno-červeně šachovaná korunovaná orlice se zlatou zbrojí a červeným jazykem, provázená v rozích zlatými majuskulními písmeny SPQO."
    },
    {
        obec: "Olomučany",
        kod: 582166,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67903,
        latitude: 49.33097,
        longitude: 16.671791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Olomucany_znak.gif",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, střídavě žlutých a zelených, v poměru 1:1:1:9:1:1:1. Uprostřed z bílého trojvrší vyrůstá bílá rostlina se třemi žlutými čtyřlistými květy a dva dubové listy po stranách jejího stonku. Rostlina je přeložena dvěma bílými zkříženými sekerami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze stříbrného trojvrší stříbrná rostlina se třemi zlatými čtyřlistými květy a dvěma vyrůstajícími stříbrnými dubovými listy po stranách. Vše přeloženo dvěma zkříženými stříbrnými sekerami a provázeno nahoře a po stranách čtyřmi zlatými hvězdami."
    },
    {
        obec: "Oloví",
        kod: 560588,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35707,
        latitude: 50.251243,
        longitude: 12.558884,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Olov%C3%AD_CoA.png"
    },
    {
        obec: "Olovnice",
        kod: 532673,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27326,
        latitude: 50.234699,
        longitude: 14.240487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Olovnice_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým pruhem širokým jednu třetinu délky listu. V červeném pruhu bílý kotvicový kříž s prodlouženými svislými rameny. V bílém poli uprostřed listu tři červení kapři nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně vpravo polceném štítě vpravo stříbrný kotvicový kříž s prodlouženými svislými rameny, vlevo tři červení kapři nad sebou."
    },
    {
        obec: "Olšany",
        kod: 550132,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.248136,
        longitude: 16.86616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Ol%C5%A1any_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. V zeleném pruhu bílá olšová větev se třemi listy a pěti žlutými jehnědami, dvěma samčími a třemi samičími, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo stříbrná olšová větev se třemi listy a pěti zlatými jehnědami, dvěma samčími a třemi samičími, vlevo stříbrná sekera se zlatým topůrkem."
    },
    {
        obec: "Olšany",
        kod: 587613,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.186221,
        longitude: 15.566091
    },
    {
        obec: "Olšany",
        kod: 540510,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78962,
        latitude: 49.965186,
        longitude: 16.859046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Ol%C5%A1any_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a modrý, v poměru 3:4:3. V žerďovém pruhu žlutá hlavice berly závitem k žerdi, v prostředním pruhu žlutá koruna prostrčená kosmo bílým svitkem papíru a šikmo žlutým procesním patriarším křížem, ve vlajícím pruhu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo zlatá koruna prostrčená kosmo stříbrným svitkem papíru a šikmo zlatým procesním patriarším křížem, vlevo zkřížené zlaté berly se stříbrnými sudarii nad stříbrnou lilií."
    },
    {
        obec: "Olšany",
        kod: 541958,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.400933,
        longitude: 13.627031,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Ol%C5%A1any_%28Klatovy_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý s knížecí čepicí, červený se vztyčeným bílým kamenickým kladivem podloženým zkříženými bílými dláty ostřím k dolnímu okraji listu a bílý se třemi šikmo vztyčenými zelenými olšovými listy nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole modro-červeně dělené, nahoře knížecí čepice, dole kamenické kladivo podložené zkříženými dláty, vše stříbrné. V levém stříbrném poli tři šikmo vztyčené zelené olšové listy nad sebou."
    },
    {
        obec: "Olšany u Prostějova",
        kod: 589829,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79814,
        latitude: 49.53655,
        longitude: 17.16467,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Ol%C5%A1any_u_Prost%C4%9Bjova_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s vlajícím pětiúhelníkovým bílým polem s vrcholem v polovině délky listu, se stranami na horním a dolním okraji rovnými jedné pětině délky listu. V zeleném poli dvě bílé olšové větévky o dvou listech směřující k vlajícímu okraji, horní se dvěma žlutými samčími a dolní se třemi samičími květy. V bílém poli červené palečné kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným hrotem s červeným palečným kolem dvě stříbrné větévky olše po dvou listech, pravá se dvěma zlatými samčími a levá se třemi samičími květy."
    },
    {
        obec: "Olší",
        kod: 596302,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59261,
        latitude: 49.421365,
        longitude: 16.290327,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Olsi_%28Brno-venkov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List čtvrcený žluto-bílým čtvrceným středovým křížem s rameny širokými jednu čtvrtinu šířky listu. Ve středu kříže zelený olšový čtyřlist s listy zasahujícími do ramen. Horní žerďové pole je zelené, dolní žerďové červené, horní vlající černé a dolní vlající modré. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-stříbrně čtvrcený štít, v prvním poli zelená olšová větévka se čtyřmi do rohů rozloženými listy a třemi plody, ve druhém poli dva černé buvolí rohy, ve třetím poli červená račí klepeta, ve čtvrtém poli růženec z klokočí s křížkem uprostřed, vše modré."
    },
    {
        obec: "Olší",
        kod: 587214,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.155548,
        longitude: 15.373333
    },
    {
        obec: "Olšovec",
        kod: 552844,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.590212,
        longitude: 17.717277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Ol%C5%A1ovec_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, žlutý a zelený v poměru 5:3:5. V žerďovém pruhu bílá olšová snítka se dvěma listy a se třemi žlutými samičími květy, ve vlajícím pruhu bílá olšová snítka se dvěma listy a dvěma žlutými samčími květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "v zeleném štítě zlatý obilný snop, provázený stříbrnými olšovými snítkami o dvou listech, pravá snítka se třemi zlatými samičími a levá se dvěma zlatými samčími květy."
    },
    {
        obec: "Olšovice",
        kod: 537365,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38411,
        latitude: 49.056923,
        longitude: 14.235971,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Ol%C5%A1ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zeleno-bílo-zeleně dělený. Ve žlutém vodorovně černá olšová větev se třemi zelenými listy, krajními k hornímu a dolnímu rohu a s prostředním k vlajícímu okraji listu. Mezi listy k hornímu okraji tři samičí a k dolnímu okraji listu vodorovně dvě samčí jehnědy, všechny hnědé. V bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným trojvrším svěšená černá olšová větev se třemi listy, krajními vztyčenými. Mezi listy vpravo tři samičí a vlevo dvě samčí jehnědy přirozené barvy. V trojvrší stříbrný vlnitý kůl s červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Omice",
        kod: 583545,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66441,
        latitude: 49.170033,
        longitude: 16.451834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Omice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý v poměru 4:1. V zeleném pruhu bílý jelen ve skoku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na návrší jelen ve skoku vlevo vyrůstající lípa, vše stříbrné."
    },
    {
        obec: "Omlenice",
        kod: 545660,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38241,
        latitude: 48.725918,
        longitude: 14.443732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Omlenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, v poměru 3 : 7, bílý a zvlněný zelený se třemi vrcholy a dvěma prohlubněmi. Uprostřed bílého pruhu pět modrých šesticípých hvězd vedle sebe. V zeleném pruhu mezi býčími rohy šíp hrotem k hornímu okraji listu, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou vlnitou hlavou s pěti modrými hvězdami vztyčený šíp mezi býčími rohy, obojí stříbrné."
    },
    {
        obec: "Ondřejov",
        kod: 537691,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.393042,
        longitude: 15.178062
    },
    {
        obec: "Ondratice",
        kod: 589837,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79807,
        latitude: 49.363049,
        longitude: 17.063785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Ondratice_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s žerďovým červeným pruhem se třemi bílými žerďovými klíny s vrcholy na vlajícím okraji pruhu. Ve žlutém poli modrá radlice hrotem nahoru a ostřím k vlajícímu okraji mezi dvěma zelenými dole zkříženými palmovými ratolestmi, nahoře provázená dvěma modrými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod červenou hlavou se třemi stříbrnými klíny vztyčená, doleva obrácená modrá radlice mezi dvěma dole překříženými zelenými palmovými ratolestmi, provázená dvěma modrými hvězdami."
    },
    {
        obec: "Ondřejov",
        kod: 538582,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25165,
        latitude: 49.904717,
        longitude: 14.78431,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Ond%C5%99ejov_-_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem širokým sedminu délky listu, v něm modrý ondřejský kříž široký osminu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě hradba s cimbuřím nad prázdnou branou s otevřenými dřevěnými vraty přirozené barvy na černých závěsech a vytaženou zlatou mříží. Z hradby vyrůstají dvě věže s cimbuřím, každá se dvěma černými okny vedle sebe a červenou stanovou střechou se zlatou makovicí, vše stříbrné a kvádrované. Mezi věžemi stojí svatý Ondřej podložený dřevěným ondřejským křížem, vše přirozené barvy."
    },
    {
        obec: "Onomyšl",
        kod: 534277,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.89933,
        longitude: 15.12436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Onomy%C5%A1l_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, v poměru 9 : 1 : 1 : 1 : 8, červeno-bíle polcený, černý, žlutý, černý a zelený. V polceném pruhu šestilistá růže opačných barev se žlutým semeníkem, v zeleném pruhu čtyři (1, 2, 1) bílé květy sedmikrásek se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělen černo-zlato-černě děleným břevnem. Nahoře v červeno-stříbrně polceném poli šestilistá růže opačných barev se zlatým semeníkem. V dolním zeleném poli čtyři (1, 2, 1) stříbrné květy sedmikrásek se zlatými středy."
    },
    {
        obec: "Onšov",
        kod: 594580,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.905385,
        longitude: 15.834328
    },
    {
        obec: "Onšov",
        kod: 548502,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.578327,
        longitude: 15.136477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/On%C5%A1ov_PE_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Uprostřed palečné kolo opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě palečné kolo opačných barev."
    },
    {
        obec: "Opařany",
        kod: 552801,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39161,
        latitude: 49.396884,
        longitude: 14.481477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Opa%C5%99any_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeným žerďovým pruhem širokým třetinu délky listu se žlutým latinským křížem do krajů s bílou štolou se žlutými třásněmi a černými tlapatými křížky, obtočenou kolem horního ramene a s přeloženými konci před křížem. Ve střední části listu žluté slunce nad bílým mrakem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě vpravo zlatý latinský kříž do krajů se stříbrnou štolou se zlatými třásněmi a černými tlapatými křížky, obtočenou okolo horního ramene a s přeloženými konci před křížem, vlevo zlaté slunce s tváří nad stříbrným mrakem."
    },
    {
        obec: "Opatov",
        kod: 587621,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58805,
        latitude: 49.431128,
        longitude: 15.394379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Opatov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a vypouklý bílý sahající do pěti osmin šířky listu, v poměru 3 : 1. Z bílého pruhu vyrůstají vedle sebe tři bílé šestilisté květy se žlutými středy na žlutých stoncích, prostřední se čtyřmi listy, krajní odkloněné a se dvěma listy na vnější straně. Z dolního okraje listu vyrůstá do bílého pruhu zelený kalich. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrného návrší vyrůstají tři stříbrné šestilisté květy se zlatými středy na zlatých stoncích, prostřední se čtyřmi listy, krajní odkloněné a se dvěma listy na vnější straně. Do návrší vyrůstá zelený kalich."
    },
    {
        obec: "Opatov",
        kod: 591319,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67528,
        latitude: 49.224435,
        longitude: 15.661768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Coat_of_arms_of_Opatov_TR.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 4 : 1. Ze zeleného pruhu vyrůstá vousatý divý muž s břečťanovým věncem na hlavě, držící v pozdvižené pravici kámen. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Opatov",
        kod: 578487,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56912,
        latitude: 49.825097,
        longitude: 16.504684
    },
    {
        obec: "Opatovec",
        kod: 572721,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.805398,
        longitude: 16.480232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Opatovec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý v poměru 1:6:1. Přes pruhy bílý kříž s rameny širokými jednu osminu délky listu. Uprostřed kříže modrá lilie převýšená červenou knížecí čepicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný latinský kříž do krajů, uprostřed modrá lilie převýšená knížecí čepicí, pod ní zkřížené odvrácené berly s červeno-stříbrně dělenými sudarii."
    },
    {
        obec: "Opatovice",
        kod: 583553,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.075131,
        longitude: 16.641079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Opatovice_BO_znak.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem vycházejícím z první a čtvrté čtvrtiny horního a dolního okraje listu přeloženým žlutým mezikružím o průměru poloviny délky listu a šířce jedné desetiny šířky listu a v něm černý opatský klobouk se šesti třapci na každé straně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný ondřejský kříž přeložený zlatým kruhem a v něm černý opatský klobouk se šesti třapci na každé straně."
    },
    {
        obec: "Opatovice",
        kod: 516201,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75356,
        latitude: 49.49929,
        longitude: 17.739289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Opatovice_PR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list, ve střední části zkřížené žluté cepy, přes které je položena žlutá berla s bílým vellem obtáčejícím cepy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zkřížené zlaté cepy a přes ně položena zlatá berla, jejíž stříbrné vellum obtáčí křížení."
    },
    {
        obec: "Opatovice I",
        kod: 530956,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.85525,
        longitude: 15.248583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Opatovice_I_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s kosmým černým pruhem širokým pětinu délky listu, šikmo přeloženým červenou opatskou berlou závitem k hornímu okraji listu.V černém pruhu nad a pod berlou bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s černým břevnem se dvěma stříbrnými liliemi červená opatská berla."
    },
    {
        obec: "Opatovice nad Labem",
        kod: 575429,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.145512,
        longitude: 15.790553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Znak_obce_Opatovice_u_Pardubic.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý vlnitý o dvou vrcholech a jedné prohlubni a modrý, v poměru 6:1:1. Nad prohlubní kosmo bílý rošt, jehož středem je šikmo prostrčená žlutá berla závitem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem kosmo stříbrný rošt, šikmo středem prostrčený zlatou berlou."
    },
    {
        obec: "Opava",
        kod: 505927,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74601,
        latitude: 49.94076,
        longitude: 17.894899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Opava_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Uprostřed listu znak města na kartušovém štítě. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Oplany",
        kod: 571679,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.924267,
        longitude: 14.867406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Oplany-znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zvlněný modrý se třemi vrcholy a čtyřmi prohlubněmi a bílý, v poměru 9 : 15. V modrém pruhu bílý gotický klíč zuby dolů a k vlajícímu okraji, v bílém pruhu dva zelené smrky s černými kmeny pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě polceném štítě vpravo postavený stříbrný gotický klíč zuby do středu, vlevo pod sebou dva zelené smrky s černými kmeny."
    },
    {
        obec: "Oplocany",
        kod: 553000,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75101,
        latitude: 49.409663,
        longitude: 17.260311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Oplocany_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a žlutý. V dolní části listu plot opačných barev o čtyřech špičatých plaňkách na dvou latích. Nad plotem bílo-červeně polcené srdce, z něhož vyrůstají dva zlomené obilné klasy s listy, bílý do černého a červený do žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě plot ze čtyř špičatých planěk a dvou latí opačných barev, nad ním stříbrno-červeně polcené srdce se dvěma vyrůstajícími zlomenými obilnými klasy, každý s jedním listem."
    },
    {
        obec: "Oplot",
        kod: 539783,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.579961,
        longitude: 13.274335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Oplot_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Z horního okraje listu vynikají tři horní bílé klíny s vrcholy ve čtvrtině šířky listu, z dolního okraje listu vynikají tři dolní žluté klíny s vrcholy ve třech čtvrtinách šířky listu. Uprostřed bílá lilie mezi odvrácenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě pod dvakrát dolů zalomeným zúženým stříbrným břevnem stříbrná lilie provázená dvěma odvrácenými zlatými lipovými listy. Dole zlatý plot."
    },
    {
        obec: "Opočnice",
        kod: 537578,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28904,
        latitude: 50.172581,
        longitude: 15.257777,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Opo%C4%8Dnice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým dvě devítiny šířky listu. V modrém poli dva dole spojené obilné klasy s listem, provázené po obou stranách třemi tlapatými křížky pod sebou, prostřední blíže klasům, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze stříbrné kamenné zdi dva dole spojené obilné klasy s listem, provázené po obou stranách třemi tlapatými křížky pod sebou, prostřední blíže klasům, vše zlaté."
    },
    {
        obec: "Opočno",
        kod: 542555,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.311585,
        longitude: 13.735342,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, uprostřed bílý hříč. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrný hříč."
    },
    {
        obec: "Opočno",
        kod: 576590,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51773,
        latitude: 50.267515,
        longitude: 16.114902,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Opocno_%28Rychnov_nad_Kneznou%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - modrý, žlutý a zelený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Opolany",
        kod: 537586,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28907,
        latitude: 50.130814,
        longitude: 15.217103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Coats_of_arms_Opolany.jpeg",
        coatOfArmsFlagDescription: "Šikmo dělený list. V žerďovém bílém poli vztyčený zelený topolový list, vlající pole tvoří tři šikmé pruhy, modrý, žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-modře šikmým stříbrným vlnitým břevnem dělený štít, v něm čtyři vztyčené zelené topolové listy, břevno kosmo podloženo zlatým obilným klasem se dvěma listy."
    },
    {
        obec: "Oponešice",
        kod: 591327,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 49.037839,
        longitude: 15.652148
    },
    {
        obec: "Oprostovice",
        kod: 516350,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.469951,
        longitude: 17.620402,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Oprostovice_znak.png",
        coatOfArmsFlagDescription: "Červený list se žlutým palečným kolem s osmi palci a bílou růží se žlutým semeníkem na jeho středu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlaté palečné kolo, na něm stříbrná růže se zlatým semeníkem."
    },
    {
        obec: "Orel",
        kod: 571962,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.919409,
        longitude: 15.839135,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Orel_%28Chrudim_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s červenými zkříženými vztyčenými napínacími háky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý orel s červenou zbrojí držící v pravém pařátu na červené žerdi před sebou stříbrný prapor s červenými zkříženými vztyčenými napínacími háky ke kuši."
    },
    {
        obec: "Oráčov",
        kod: 542202,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27032,
        latitude: 50.111647,
        longitude: 13.549003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Or%C3%A1%C4%8Dov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed červeno-bíle polcená svatojakubská mušle, v ní čelně radlice opačných barev hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě v červeno-stříbrně polcené svatojakubské mušli čelně radlice opačných barev."
    },
    {
        obec: "Orlické Podhůří",
        kod: 580716,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 50.00342,
        longitude: 16.35071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Orlick%C3%A9_Podh%C5%AF%C5%99%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, zelený, bílý vlnitý s třemi vrcholy a dvěma prohlubněmi a zelený zvlněný, v poměru 8 : 1 : 1 : 2. Do modrého pruhu vyrůstá na šikmo prohnutém zeleném stonku se dvěma listy žlutá desetilistá růže s červeným semeníkem a zelenými kališními lístky převýšená do oblouku devíti žlutými odkloněnými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze zeleného návrší se stříbrným vlnitým břevnem vyrůstá na šikmo prohnutém zeleném stonku se dvěma listy zlatá desetilistá růže s červeným semeníkem a zelenými kališními lístky převýšená do oblouku devíti zlatými hvězdami."
    },
    {
        obec: "Orlické Záhoří",
        kod: 576603,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51764,
        latitude: 50.278761,
        longitude: 16.475033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Orlick%C3%A9_Z%C3%A1ho%C5%99%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 7 : 1. Uprostřed zeleného pruhu vyrůstá zelená hora do poloviny šířky listu, široká polovinu délky listu. Ve střední části zeleného pruhu vyrůstá do hory bílá jedle. Z hory vyrůstá modrou korunou převýšená modrá orlice se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zelené hory se stříbrnou jedlí modrá orlice se zlatou zbrojí a jazykem převýšená modrou korunou."
    },
    {
        obec: "Orličky",
        kod: 580724,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56155,
        latitude: 50.031626,
        longitude: 16.681575,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Orli%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modro-červeně polcený, zelený, bílý a zelený, v poměru 6 : 2 : 1 : 2. Do horního pruhu vyrůstají dvě přivrácené bílé orlice se žlutou zbrojí a jazykem, mezi nimi nad zeleným pruhem zlatá koruna. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít se zeleným vrchem do poloviny štítu, v něm vztyčená, doleva obrácená, stříbrná radlice provázená dvěma odkloněnými květy lnu na stoncích se dvěma listy, vše zlaté. Z vrchu vyrůstají dvě přivrácené stříbrné orlice se zlatou zbrojí a jazykem, mezi nimi na vrchu zlatá koruna."
    },
    {
        obec: "Orlík nad Vltavou",
        kod: 549681,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39807,
        latitude: 49.509979,
        longitude: 14.165613,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Orl%C3%ADk_nad_Vltavou_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, červený a žlutý, v poměru 1 : 2 : 1. V červeném pruhu bílá věž s cimbuřím se třemi zuby, černou branou a černým oknem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zlatě polceném štítě vpravo na černém trojvrší stříbrná věž se třemi stínkami, černou branou a černým oknem, vlevo kosmá uťatá hlava Turka přirozené barvy s černým copem a knírem, na níž sedí černý krkavec se zlatým obojkem a pařátem jí drásá oko."
    },
    {
        obec: "Orlová",
        kod: 599069,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73511,
        latitude: 49.870238,
        longitude: 18.425108,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Orlov%C3%A1_znak.png",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole se žlutou půlorlicí s červenou zbrojí a bílé vlající pole s červeným vykořeněným listnatým stromem. Obě pole jsou oddělena černým kůlem širokým 1/10 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravé modré polovině půl zlaté orlice s červenou zbrojí, levá polovina stříbrno-černě dělená, nahoře červený vykořeněný listnatý strom, dole zkřížený stříbrný mlátek a želízko."
    },
    {
        obec: "Ořech",
        kod: 539520,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25225,
        latitude: 50.020209,
        longitude: 14.296653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Orech_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List praporu je čtvrcený, horní žerďové a vlající dolní pole je modré, dolní žerďové a vlající horní pole je bílé; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Štít stříbrnomodře polcený; vpravo pod sebou plod a list ořešáku přirozených barev, vlevo stříbrný vzpínající se kůň se zlatými kopýtky."
    },
    {
        obec: "Orlovice",
        kod: 593460,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.239184,
        longitude: 17.093701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Znak_orlovice.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, černý zubatý, zelený zubatý a bílý. Uprostřed červená žlutě zdobená knížecí koruna nad bílým maltézským křížem. Zubaté pruhy mají po čtyřech zubech sahajících do jedné třetiny bílých pruhů a pět stejných mezer. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře vyrůstající černý orel se zlatou zbrojí a stříbrným maltézským křížem na hrudi, dole stříbrná kvádrovaná hradba se dvěma stínkami, mezi nimi červená, zlatě zdobená knížecí koruna."
    },
    {
        obec: "Ořechov",
        kod: 583561,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66444,
        latitude: 49.11128,
        longitude: 16.523392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Orechov_BO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým klínem vycházejícím z horního okraje listu a s vrcholem na jeho dolním okraji. V klínu na zeleném návrší zelený listnatý strom (ořešák) s hnědým kmenem.V dolním rohu vinařský nůž - kosíř hrotem nahoru a ostřím k vlajícímu okraji, v dolním cípu radlice hrotem nahoru a ostřím k žerdi, oboje bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ve stříbrném klínu listnatý strom (ořešák) přirozené barvy na zeleném návrší. Klín provázejí vinařský nůž - kosíř a radlice, obojí vztyčené, přivrácené a stříbrné."
    },
    {
        obec: "Ořechov",
        kod: 587630,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.200027,
        longitude: 15.532067
    },
    {
        obec: "Ořechov",
        kod: 596329,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59452,
        latitude: 49.350482,
        longitude: 16.139044,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/CoA_of_O%C5%99echov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený se žlutou padací mříží s bílými nýty a žlutý se zkříženými černými ostrvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zlatě děleném štítě nahoře zlatá padací mříž se stříbrnými nýty, dole zkřížené černé ostrve."
    },
    {
        obec: "Ořechov",
        kod: 592447,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68737,
        latitude: 49.030686,
        longitude: 17.300934,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/O%C5%99echov_%28UH%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, zubatý žlutý a zelený, v poměru 9 : 4 : 3. Žlutý pruh má čtyři obdélníkové zuby a tři stejné mezery, z prostřední vyrůstá bílý ořešák. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlaté hradby s položeným zeleným vinařským nožem-kosířem stříbrný ořešák."
    },
    {
        obec: "Osečany",
        kod: 598470,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.697825,
        longitude: 14.437729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Ose%C4%8Dany_znak.png",
        coatOfArmsFlagDescription: "Bílý list s modrým vlnitým pruhem širokým jednu devítinu šířky listu. V horním rohu červený kapr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré vlnité břevno provázené dvěma červenými kapry."
    },
    {
        obec: "Osečná",
        kod: 564290,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46352,
        latitude: 50.69499,
        longitude: 14.921485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Ose%C4%8Dn%C3%A1_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílou hlavou gryfa se žlutou zbrojí a korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná korunovaná hlava gryfa se zlatou zbrojí."
    },
    {
        obec: "Osečnice",
        kod: 576611,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51703,
        latitude: 50.257266,
        longitude: 16.307
    },
    {
        obec: "Osek",
        kod: 598828,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.443367,
        longitude: 14.299945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Osek_%28P%C3%ADsek_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 10 : 1 : 2 : 1 : 10. Uprostřed listu zkřížené bílé sekery na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrně lemovaným modrým vlnitým břevnem zkřížené stříbrné sekery na zlatých topůrkách."
    },
    {
        obec: "Osek",
        kod: 551562,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.318408,
        longitude: 13.963291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Osek_%28Strakonice_District%29_CoA.jpg"
    },
    {
        obec: "Oseček",
        kod: 599662,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28941,
        latitude: 50.10165,
        longitude: 15.148691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Coats_of_arms_Ose%C4%8Dek.jpeg",
        coatOfArmsFlagDescription: "Bílý list se svislým modrým vlnitým pruhem vycházejícím z druhé šestiny horního a dolního okraje. V žerďovém poli červený kříž s rameny širokými jednu desetinu šířky listu. Ve střední a vlající části hnědý pařez se šikmo zaťatou modrou sekerou s hnědým topůrkem k dolnímu rohu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené modré vlnité břevno, nahoře červený heroldský kříž, dole do pařezu zprava šikmo zaťatá modrá sekera s topůrkem, obojí přirozené barvy."
    },
    {
        obec: "Osek",
        kod: 573264,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50743,
        latitude: 50.463947,
        longitude: 15.159618
    },
    {
        obec: "Osek",
        kod: 560057,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33821,
        latitude: 49.778115,
        longitude: 13.59104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Osek_RO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Červený list s bílým osekaným kmenem stromu s pěti suky a kořeny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou patou stříbrný osekaný kmen stromu s pěti suky a kořeny."
    },
    {
        obec: "Osek",
        kod: 531634,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26762,
        latitude: 49.819743,
        longitude: 13.858627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Osek_%28Beroun_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý, a tři vodorovné pruhy na dolním okraji listu, žlutý, červený a žlutý, každý široký osminu šířky listu, nad nimi kosmo sekera šikmo podložená kladivem, obojí bílé se žlutou násadou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře polcený štít se zlatou cimbuřovou patou. Ve štítě kosmo sekera šikmo podložená kladivem, obojí stříbrné se zlatou násadou. V patě červené vlnité břevno."
    },
    {
        obec: "Osek",
        kod: 567779,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41705,
        latitude: 50.622637,
        longitude: 13.691488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Osek_CoA_TP_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový černý pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a zelený. V černém pruhu čtyři žerďové žluté klíny s vrcholy na vlajícím okraji pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít s černou hlavou, v ní čtyři zlaté štítky vedle sebe, v prvním vyrůstající stříbrná berla závitem doleva, ve druhém na zeleném trávníku dub přirozené barvy, ve třetím kosmý mlátek křížem podložený želízkem, obojí přirozené barvy, ve čtvrtém vyrůstající stříbrné hrábě."
    },
    {
        obec: "Osek nad Bečvou",
        kod: 516619,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75122,
        latitude: 49.511313,
        longitude: 17.528401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Osek_nad_Be%C4%8Dvou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu zkřížené orobince, každý se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-zlatě polcený, vpravo zkřížené stříbrné vinařské nože se zlatými rukojeťmi, vlevo zkřížené orobince přirozené barvy, v dolním modrém poli zkřížené zlaté obilné klasy."
    },
    {
        obec: "Oselce",
        kod: 558184,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33546,
        latitude: 49.437309,
        longitude: 13.672931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Oselce_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list, v žerďové polovině čelně postavená kaple s věží zakončenou křížkem, ve vlající polovině kosmo tři spojené kosočtvercové přezky, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě čelně postavená kaple s věží zakončenou křížkem, pod ní tři spojené kosočtvercové přezky, vše zlaté."
    },
    {
        obec: "Osice",
        kod: 570532,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50326,
        latitude: 50.142888,
        longitude: 15.686098,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Osice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, nahoře kostel s věží, pod ním šikmo housle kosmo podložené smyčcem, vše bílé, provázené dvěma přivrácenými žlutými berlami s bílými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nahoře kostel s věží, pod ním šikmo položené housle podložené smyčcem, vše stříbrné, provázené dvěma přivrácenými zlatými berlami se stříbrnými sudarii."
    },
    {
        obec: "Osíčko",
        kod: 588822,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.431087,
        longitude: 17.751607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Os%C3%AD%C4%8Dko_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - zelený, žlutý a zelený. Ve žlutém poli modrý květ lnu se zeleným semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě dělený štít, v horní polovici vyrůstá stříbrný lev s červenou zbrojí, ve spodní polovině dva modré květy lnu vedle sebe."
    },
    {
        obec: "Osičky",
        kod: 570541,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.143649,
        longitude: 15.676288,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/CoA_of_Osi%C4%8Dky.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 4 : 1. V zeleném pruhu uprostřed kruhu s pěti (1, 2, 2) šesticípými hvězdami a dole se zavěšeným zvonem, vše žluté, k vlajícímu okraji obrácená žlutá hlavice berly s bílým patriarším křížem v závitu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě uprostřed kruhu s pěti (1, 2, 2) hvězdami a dole se zavěšeným zvonem, vše zlaté, doleva obrácená zlatá hlavice berly se stříbrným patriarším křížem v závitu."
    },
    {
        obec: "Osík",
        kod: 578509,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56967,
        latitude: 49.843645,
        longitude: 16.28477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Os%C3%ADk_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a zelený, v poměru 10:1:9. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku vykořeněný listnatý strom přirozené barvy, provázený vpravo dole zlatou lilií."
    },
    {
        obec: "Osiky",
        kod: 582174,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.456294,
        longitude: 16.421688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Osiky_znak.jpg"
    },
    {
        obec: "Oskava",
        kod: 540544,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78801,
        latitude: 49.895329,
        longitude: 17.132249,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Oskava_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červeno-bíle půlený, modrý a bílo-červeně půlený. V horním žerďovém poli vztyčený kopáč hrotem k vlajícímu okraji mezi dvěma zkříženými kovářskými kladivy, vše bílé na žlutých násadách. V modrém pruhu žlutý lev s červenou zbrojí a jazykem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře zlatý lev s červenou zbrojí a jazykem mezi dvěma vyrůstajícími zlatými smrky, dole červený hrot se vztyčeným kopáčem mezi dvěma zkříženými kovářskými kladivy, vše stříbrné na zlatých násadách, provázený dvěma modrými květy lnu se zelenými středy."
    },
    {
        obec: "Oskořínek",
        kod: 537616,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28932,
        latitude: 50.240157,
        longitude: 15.084104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Coats_of_arms_Osko%C5%99%C3%ADnek.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 1:4. V modrém pruhu čtyři bílé květy hlohu. Na dolním okraji žlutého pruhu zelené návrší, široké tři sedminy délky listu, z něhož vyrůstá keř se čtyřmi hnědými větvemi a zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou hlavou se čtyřmi stříbrnými květy hlohu vyrůstá ze zeleného návrší keř se čtyřmi olistěnými pruty přirozené barvy."
    },
    {
        obec: "Oslavany",
        kod: 583588,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66412,
        latitude: 49.123447,
        longitude: 16.336633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Oslavany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, černý a žlutý v poměru 6:5:1. Přes pruhy žlutý vinný keř se třemi listy a jedním hroznem v dolním žerďovém rohu na černém pruhu, přes kmen keře vzdálený jednu třetinu délky listu od žerďového okraje vodorovně položený bílý vinařský nůž se žlutou rukojetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-černě dělený štít, ze zlatého vršku vyrůstá zlatý vinný keř s jedním hroznem a třemi listy. Přes kmen vodorovně přeložen stříbrný vinařský nůž se zlatou rukojetí."
    },
    {
        obec: "Oslavice",
        kod: 596337,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.340872,
        longitude: 15.990807,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Znak_obce_Oslavice.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký čtvrtinu délky listu se třemi červenými růžemi se žlutými semeníky pod sebou, a modré pole s běžícím bílým koněm. Kopyta, hříva a ocas, tlapatý křížek nad koněm a srdce pod koněm, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky běžící stříbrný kůň se zlatými kopyty, hřívou a ocasem, nad ním tlapatý křížek a pod ním srdce, obojí zlaté."
    },
    {
        obec: "Oslavička",
        kod: 511412,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.315507,
        longitude: 15.966948,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Oslavi%C4%8Dka_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým zvlněným bílým pruhem širokým čtvrtinu délky listu se dvěma vrcholy a třemi prohlubněmi. V modrém poli žlutá koruna postrkaná třemi bílými pštrosími pery. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou zlatá koruna postrkaná třemi stříbrnými pštrosími pery, pod ní stříbrná kvádrovaná hradba s pěti stínkami."
    },
    {
        obec: "Oslnovice",
        kod: 594598,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.932188,
        longitude: 15.6875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Oslnovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, v poměru 1:3:1. Ve žlutém pruhu kosmo radlice ostřím a hrotem k dolnímu okraji, šikmo přeložená ostrví, obojí černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad kosmou radlicí šikmo přeloženou ostrví, obojí černé, červené hořící srdce."
    },
    {
        obec: "Oslov",
        kod: 549703,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39835,
        latitude: 49.399365,
        longitude: 14.212059,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Oslov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. U horního okraje listu tři bílé lilie vedle sebe. V červeném pruhu dva vlnité pruhy, bílý vycházející z páté osminy a žlutý vycházející ze sedmé osminy žerďového okraje listu. Z dolního okraje modrého pruhu vyniká žlutá berla závitem k žerďovému okraji listu, v něm bílá oslí hlava s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě nahoře vedle sebe tři stříbrné lilie. V pravém poli dvě vlnitá břevna, stříbrné nad zlatým, v levém poli vyniká zlatá berla, v jejím závitu stříbrná oslí hlava s krkem."
    },
    {
        obec: "Osoblaha",
        kod: 597716,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.275267,
        longitude: 17.715337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/CZE_Osoblaha_COA.svg",
        coatOfArmsFlagDescription: "Červený list s pěti bílými rovnoramennými trojúhelníky se základnami ve středu listu a třemi bílými rovnoramennými trojúhelníky se základnami na dolním okraji listu. Výška trojúhelníků je rovna polovině šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Osov",
        kod: 531642,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26725,
        latitude: 49.844478,
        longitude: 14.08402,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Osov.svg",
        coatOfArmsFlagDescription: "List tvoří bílo-červeně čtvrcený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, bílý a červený. V horním žerďovém bílém poli půl černé orlice se žlutou zbrojí, červeným jazykem a žlutým jetelovitě zakončeným perizoniem, přiléhající k hornímu červenému poli s vykořeněným bílým leknem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo z poltící linie vynikající černá orlice se zlatou zbrojí, červeným jazykem a zlatým, jetelovitě zakončeným perizoniem, vlevo vykořeněné stříbrné lekno."
    },
    {
        obec: "Osová Bítýška",
        kod: 596345,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.325143,
        longitude: 16.168556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/OsovaBityska-znak.png",
        coatOfArmsFlagDescription: "Bílý list s černou zubří hlavou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Osové",
        kod: 596353,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.325323,
        longitude: 16.015528,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Osov%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným křížem s rameny širokými jednu šestinu délky listu. V žerďových čtvercových polích po jedné červené růži se žlutým semeníkem a zelenými kališními lístky. V bílých vlajících polích zelený vodorovný pruh široký jednu třetinu šířky pole. V kříži vodorovně bílý meč se žlutým jílcem hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený latinský kříž do krajů přeložený dole zeleným odvráceným drakem s červeným jazykem, probodnutým shora a ze zadu stříbrným mečem se zlatým jílcem. V rozích dvě červené růže se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Ostašov",
        kod: 591335,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.155648,
        longitude: 15.918092,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Osta%C5%A1ov.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, bílý a černý, v poměru 5 : 1 : 1 : 1. Ze zeleného pruhu vyrůstá černá orlice se žlutým zobákem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě ze zeleného břevna na černé patě se třemi kosmými stříbrnými břevny vyrůstá černá orlice ze zlatým zobákem."
    },
    {
        obec: "Ostopovice",
        kod: 583596,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66449,
        latitude: 49.161102,
        longitude: 16.54559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Ostopovice_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s červeným srdcem, v něm žlutý vinný hrozen na stonku a bílý vinařský nůž-kosíř ostřím k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červené srdce, v němž vpravo na stonku zlatý vinný hrozen a vlevo stříbrný vinařský nůž."
    },
    {
        obec: "Ostrá",
        kod: 537624,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28922,
        latitude: 50.180537,
        longitude: 14.893003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Ostra_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se zeleným návrším na dolním okraji, širokým tři sedminy délky listu, ze kterého vyrůstají z jednoho stonku se dvěma listy tři žluté obilné klasy. Nad nimi vodorovně bílá ryba s ocasem otočeným k dolnímu okraji listu. V horním rohu a horním cípu po jedné žluté šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zeleného návrší vyrůstající na jednom stonku tři zlaté obilné klasy, nad nimi stříbrná ryba, provázená v rozích štítu dvěma zlatými hvězdami."
    },
    {
        obec: "Ostrata",
        kod: 557170,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76311,
        latitude: 49.271025,
        longitude: 17.76694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/CoA_of_Ostrata.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy bílý, modrý a bílý, v poměru 1:3:1. V modrém pruhu osmicípá hvězda mezi přivrácenými křídly, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno nahoře mezi přivrácenými křídly osmihrotá hvězda, dole srdce, vše zlaté."
    },
    {
        obec: "Ostravice",
        kod: 598542,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73914,
        latitude: 49.535196,
        longitude: 18.391751,
        coatOfArmsFlagDescription: "Bílý list se zeleným vykořeněným smrkem s hnědým kmenem. Na vlajícím okraji svislý modrý zvlněný pruh se třemi vrcholy a čtyřmi prohlubněmi, široký jednu pětinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad stříbrno-modře vlnitě dělenou patou zelená hora, z níž vyrůstá smrk přirozených barev."
    },
    {
        obec: "Ostrava",
        kod: 554821,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 70200,
        latitude: 49.821023,
        longitude: 18.262624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Ostrava_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým osedlaným koněm bez uzdy, v poskoku, s červenou pokrývkou pod zlatým sedlem. Za hlavou je zlatá pětilistá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.."
    },
    {
        obec: "Ostrolovský Újezd",
        kod: 535231,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.880718,
        longitude: 14.600707
    },
    {
        obec: "Ostroměř",
        kod: 573272,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50752,
        latitude: 50.372556,
        longitude: 15.549593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Ostromer_znak.svg",
        coatOfArmsFlagDescription: "Bílý list s modrou obrněnou paží třímající modrou sekyru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá obrněná paže s modrou sekyrou."
    },
    {
        obec: "Ostrov",
        kod: 555428,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.306035,
        longitude: 12.939183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/M%C4%9Bsto-Ostrov-znak.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový klín sahající do 4/9 délky praporu. Klín rozděluje list na horní bílé a dolní modré lichoběžníkové pole. Od modrého lichoběžníkového pole je oddělen bílým proužkem o šířce 1/15 délky praporu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ostrov",
        kod: 548359,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.693104,
        longitude: 15.301494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Coat_of_arms_of_Ostrov_HB.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zelený a žlutý. V modrém pruhu bílý bramborový květ se žlutým středem, v zeleném bílý kámen a ve žlutém černé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleno-zlatě vlnitě děleným návrším bramborový květ přirozené barvy provázený dvěma zlatými obilnými klasy s listy. V návrší nahoře tři (1, 2) stříbrné kameny, dole černé mlýnské kolo."
    },
    {
        obec: "Ostrov",
        kod: 574104,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53863,
        latitude: 49.972703,
        longitude: 16.030432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Ostrov_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený svislý pruh, široký polovinu délky listu a dva vodorovné pruhy, bílý a modrý, v poměru 2 : 1. V červeném pruhu ze žluté koruny vyrůstá bílý pes ve skoku s obojkem s kroužkem. Ze druhé a třetí čtvrtiny modrého pruhu vyrůstá návrší, nad ním vztyčený list, obojí zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli ze zlaté koruny vyrůstá stříbrný pes ve skoku s obojkem s kroužkem, levé pole stříbrno - modře sníženě dělené. Do stříbrného pole vyniká návrší, nad ním vztyčený list, obojí zelené."
    },
    {
        obec: "Ostrov",
        kod: 580732,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56122,
        latitude: 49.930345,
        longitude: 16.540637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Ostrov_UO_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a červený, v poměru 3 : 2 : 3. V bílém pruhu půl černé zubří hlavy se žlutou houžví, v modrém tři žluté lilie pod sebou, v červeném žlutá radlice hrotem dolů a ostřím k vlajícímu okraji, v ní dvě červené růže pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou hlavou se třemi zlatými liliemi vedle sebe. V pravém poli půl černé zubří hlavy se zlatou houžví, v levém poli doleva obrácená zlatá radlice se dvěma červenými růžemi pod sebou."
    },
    {
        obec: "Ostrov",
        kod: 599395,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25706,
        latitude: 49.665402,
        longitude: 14.851197,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/CoA_of_Ostrov_%28okres_Bene%C5%A1ov%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, zelený a bílý. V zeleném pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo stříbrná zavinutá střela, vlevo dvě modrá vlnitá břevna."
    },
    {
        obec: "Ostrov",
        kod: 598372,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.611219,
        longitude: 14.014609
    },
    {
        obec: "Ostrov nad Oslavou",
        kod: 596361,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59445,
        latitude: 49.486919,
        longitude: 15.990033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Ostrov_nad_Oslavou_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list s kosmým modrým pruhem se třemi žlutými šesticípými hvězdami vycházejícím z první čtvrtiny žerďového okraje do čtvrté čtvrtiny vlajícího okraje. V žerďovém pruhu kosmo ještěrka ve vlajícím kosmo běžící jelen, obojí černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kosmé modré břevno se třemi zlatými hvězdami provázené nahoře běžícím jelenem a dole ještěrkou, obojí černé."
    },
    {
        obec: "Ostrov u Bezdružic",
        kod: 559326,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33038,
        latitude: 49.900238,
        longitude: 13.042079
    },
    {
        obec: "Ostrov u Macochy",
        kod: 582182,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67914,
        latitude: 49.382462,
        longitude: 16.762786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Coat_of_arms_of_Ostrov_u_Macochy.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, bílý, sedm střídavě zelených a bílých a červený, v poměru 7:1:1:1:1:1:1:1:7. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleno-stříbrně svisle dělený štít, uprostřed nad trojvrším vztyčené přivrácené krojidlo a motyka, vše stříbrné, v levém poli tři černá kosmá břevna."
    },
    {
        obec: "Ostrovačice",
        kod: 583600,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66481,
        latitude: 49.210924,
        longitude: 16.409562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Ostrovacice_CoA.gif",
        coatOfArmsFlagDescription: "Žlutý list se zeleným kosmým pruhem vycházejícím z první poloviny horního okraje a horní poloviny žerďového okraje do dolní poloviny vlajícího a druhé poloviny dolního okraje listu. Uprostřed žlutý vinný hrozen se žlutým listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vinný stonek se čtyřmi hrozny a čtyřmi listy obtočený kolem tyče, vše zlaté."
    },
    {
        obec: "Ostrovánky",
        kod: 586471,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69631,
        latitude: 49.042793,
        longitude: 17.080434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Ostrov%C3%A1nky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy zelený, bílý a zelený v poměru 1:2:1. V bílém pruhu žlutý žalud se dvěma zelenými listy. Poměr šířky k délce listu je 2:3",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře zlatý žalud se dvěma zelenými listy, dole zlatý vinný hrozen se dvěma listy."
    },
    {
        obec: "Ostrovec",
        kod: 549711,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39833,
        latitude: 49.421243,
        longitude: 14.115581,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Ostrovec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 7 : 1 : 7. V horním pruhu dva dole přivrácené žluté zvony, v dolním pruhu zkřížené bílé sekery se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné vlnité břevno. Nahoře dva dole přivrácené zlaté zvony, dole zkřížené stříbrné sekery se zlatými topůrky."
    },
    {
        obec: "Ostrovec-Lhotka",
        kod: 566993,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.907578,
        longitude: 13.728204
    },
    {
        obec: "Ostrožská Lhota",
        kod: 592455,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68723,
        latitude: 48.975689,
        longitude: 17.467622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Ostro%C5%BEsk%C3%A1_Lhota%2C_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se žlutým žerďovým klínem ohraničeným černou vnitřní a modrou vnější krokví, vycházející z horního a dolního rohu s vrcholem na středu vlajícího okraje. Poměr barev na žerďovém okraji je 1:1:4:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Na červeném štítě s modrým lemem stříbrný kostel se dvěma vížkami, střechy modré."
    },
    {
        obec: "Ostružná",
        kod: 569330,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78825,
        latitude: 50.185253,
        longitude: 17.051691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Ostru%C5%BEn%C3%A1_%28okres_Jesen%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý, překryté žlutým ondřejským křížem s rameny vycházejícími z horního a dolního rohu do šesté osminy horního a dolního okraje, ve vlající části tři bílé ostruhové hvězdice nad sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě dvě zlaté zkřížené sekery nad třemi (2,1) stříbrnými ostruhovými hvězdicemi."
    },
    {
        obec: "Ostrožská Nová Ves",
        kod: 592463,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68722,
        latitude: 49.004438,
        longitude: 17.436427,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Ostro%C5%BEsk%C3%A1_Nov%C3%A1_Ves%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů - bílý, modrý, žlutý, červený, žlutý, modrý a bílý v poměru 1:2:1:1:1:2:1. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Červený štít s modrou patou. Nahoře tři zlaté dubové ratolesti s pěti (1,2,2) žaludy a dvěma listy. Dole tři stříbrná vlnitá břevna."
    },
    {
        obec: "Ostružno",
        kod: 573281,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.443397,
        longitude: 15.289734
    },
    {
        obec: "Ostředek",
        kod: 530352,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25724,
        latitude: 49.834893,
        longitude: 14.8324,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Coat_of_arms_of_Ost%C5%99edek.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a modrých, v poměru 1 : 1 : 12 : 1 : 1. V prostředním pruhu modré mezikruží postrkané dvanácti do oblouku zahnutými pštrosími pery, střídavě červenými a modrými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou s pěti stříbrnými hvězdami modrý prázdný kotouč postrkaný dvanácti do oblouku zahnutými pštrosími pery, střídavě červenými a modrými."
    },
    {
        obec: "Ostřešany",
        kod: 575437,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.993525,
        longitude: 15.805408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Ost%C5%99e%C5%A1any_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červenou vlající krokví širokou šestinu šířky listu, vycházející z poloviny horního a dolního okraje, s vrcholem v polovině žerďového okraje listu, a se třemi zelenými vztyčenými lipovými listy, jedním uprostřed listu a dvěma pod sebou ve vlající části. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou zvýšenou krokví tři (1, 2) vztyčené zelené lipové listy."
    },
    {
        obec: "Ostřetice",
        kod: 578061,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.422439,
        longitude: 13.347004
    },
    {
        obec: "Ostřetín",
        kod: 575445,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.044716,
        longitude: 16.030478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Ostretin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým světle modrým pruhem širokým jednu pětinu šířky listu na zelené žerďové a žluté vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně kosmo děleném štítě na stonku vyrůstající květ kosatce sibiřského přirozené barvy."
    },
    {
        obec: "Osvětimany",
        kod: 592471,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68742,
        latitude: 49.055871,
        longitude: 17.249789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Osv%C4%9Btimany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým medvědem s červenou zbrojí držícím bílou hůl s patriarším křížem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vzpřímený medvěd s červenou zbrojí držící stříbrnou hůl ukončenou patriarším křížem."
    },
    {
        obec: "Osvračín",
        kod: 554073,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.513274,
        longitude: 13.047035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Osvra%C4%8D%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "Červený list s žerďovým bílým zubatým pruhem širokým třetinu délky listu se čtyřmi obdélníkovými zuby a pěti stejnými mezerami. V červeném poli bílý beran ve skoku se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze stříbrné kvádrované hradby stříbrný beran se zlatou zbrojí."
    },
    {
        obec: "Ošelín",
        kod: 541681,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.772115,
        longitude: 12.856261,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/O%C5%A1el%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří vodorovný modrý pruh se čtyřmi žlutými liliemi široký tři osminy šířky listu a tři svislé pruhy bílý, černý a bílý. V černém pruhu bílý nůž se žlutou rukojetí hrotem k hornímu a ostřím k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě, pod modrou hlavou se čtyřmi zlatými liliemi černý kůl, v něm stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Otaslavice",
        kod: 589845,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79806,
        latitude: 49.388426,
        longitude: 17.071186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Otaslavice_CoA.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list, horní žerďová část červená, dolní vlající část dělená čtyřmi šikmými pruhy: žlutý, červený, bílý a červený v poměru 2:1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě dvě zkřížené stříbrné otky provázené nahoře stříbrnou růží a po stranách dvěma zlatými hvězdami."
    },
    {
        obec: "Otěšice",
        kod: 540293,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.544137,
        longitude: 13.220352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Ot%C4%9B%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým korunovaným kolčím helmem s červeno-bílými přikryvadly a klenotem - bílo - červeně polcenou ostruhou proloženou dvěma zkříženými žlutými šípy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný korunovaný kolčí helm s červeno - stříbrnými přikryvadly a klenotem - stříbrno - červeně polcenou ostruhou proloženou dvěma zkříženými zlatými šípy."
    },
    {
        obec: "Otice",
        kod: 509612,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74781,
        latitude: 49.916939,
        longitude: 17.869927,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Otice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou vztyčenou radlicí, ke které přiléhá polovina žlutého slunce, žerďová část s radlicí a slunce je od zbytku listu oddělena bílou krokví s vrcholem ve středu listu. Krokev je široká jednu osminu šířky listu a je umístěná jednu třetinu délky listu od žerďového pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vztyčená radlice, ke které přiléhá polovina zlatého slunce. Pod nimi stříbrná obrácená krokev."
    },
    {
        obec: "Otín",
        kod: 587648,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.2666,
        longitude: 15.569796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Ot%C3%ADn_JI_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený s osmi položenými bílými rovnoramennými trojúhelníky vysokými šestinu délky listu, základnami širokými osminu šířky listu, s vrcholy na žerďovém okraji listu, a rozkřídlený bílý dravý pták se žlutou zbrojí a jazykem držící kosmou bílou ostrev se čtyřmi suky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě rozkřídlený stříbrný dravý pták se zlatou zbrojí a jazykem držící kosmou stříbrnou ostrev se čtyřmi suky. V hlavě štítu osm dotýkajících se stříbrných hrotů vedle sebe."
    },
    {
        obec: "Otín",
        kod: 596370,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.362965,
        longitude: 15.906706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Ot%C3%ADn_%28%C5%BD%C4%8F%C3%A1r_nad_S%C3%A1zavou_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed ve žlutém mezikruží bílá šesticípá hvězda, v ní červený patriarší kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ve zlatém kruhu stříbrná hvězda, v ní červený patriarší kříž."
    },
    {
        obec: "Otmarov",
        kod: 506699,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66457,
        latitude: 49.100613,
        longitude: 16.672783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Otmarov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým šikmým pruhem vycházejícím z šesté šestiny horního okraje do třetí šestiny dolního okraje listu. V žerďové polovině listu žlutý prsten s červeným kamenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou s modrým vlnitým břevnem zlatý prsten s červeným kamenem."
    },
    {
        obec: "Otinoves",
        kod: 589853,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79861,
        latitude: 49.421275,
        longitude: 16.871783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Otinoves_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými klíny, žerďovým s vrcholem v první třetině a vlajícím s vrcholem ve druhé třetině délky listu. Ve střední části listu bílá zavinutá střela hrotem nahoru, provlečená žlutým tkalcovským člunkem. V klínech modrý květ lnu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený červený hrot se stříbrnou zavinutou střelou provlečenou položeným zlatým tkalcovským člunkem, provázený dvěma květy lnu přirozené barvy."
    },
    {
        obec: "Otmíče",
        kod: 534111,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.869145,
        longitude: 13.947252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Otm%C3%AD%C4%8De_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý se žlutým vinným hroznem se dvěma listy po stranách a žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zlatě polcený štít s modrou hlavou, v ní zlatý vinný hrozen se dvěma listy po stranách, v pravém poli stříbrné vykořeněné lekno provázené vlevo uprostřed pravou polovinou zlaté osmihroté hvězdy, vlevo květ koniklece přirozené barvy na zeleném vykořeněném stonku."
    },
    {
        obec: "Otnice",
        kod: 593478,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68354,
        latitude: 49.086517,
        longitude: 16.814533,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Znak_Otnice.jpg",
        coatOfArmsFlagDescription: "Modrý list, při žerdi dvě žluté lilie pod sebou, mezi nimi a vlajícím okrajem bílá rozkřídlená labuť se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná rozkřídlená labuť se zlatou zbrojí, provázená nahoře dvěma zlatými liliemi."
    },
    {
        obec: "Otovice",
        kod: 537969,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.25545,
        longitude: 12.871721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Otovice_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu šestinu délky listu a červené pole s bílou krokví vycházející z poslední třetiny horního a dolního okraje listu s vrcholem na vlajícím okraji žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná krokev provázená třemi zlatými korunami."
    },
    {
        obec: "Otov",
        kod: 566209,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.486597,
        longitude: 12.842602,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Otov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, v poměru 4 : 1 : 7, červený, žlutý vlnitý se čtyřmi vrcholy a pěti prohlubněmi a zvlněný zelený. V červeném pruhu obrácená bílá hornická kladívka se žlutými topůrky mezi dvěma postavenými bílými kypřicemi. V zeleném pruhu bílá husí hlava s krkem se žlutou zbrojí a jazykem mezi žlutými daňčími parohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně zúženým zlatým vlnitým břevnem dělený štít. Nahoře obrácená stříbrná hornická kladívka se zlatými topůrky mezi dvěma postavenými stříbrnými kypřicemi, dole stříbrná husí hlava s krkem se zlatou zbrojí a jazykem mezi zlatými daňčími parohy."
    },
    {
        obec: "Otovice",
        kod: 574317,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54972,
        latitude: 50.557245,
        longitude: 16.387685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Otovice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a žlutých, v poměru 5:2:2:2:5. Uprostřed listu bílá věž s kvádrovaným soklem, s cimbuřím a červenou stanovou střechou. Ve věži červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s kvádrovaným soklem, s cimbuřím a červenou stanovou střechou, ve věži červená růže se zlatým semeníkem a zelenými kališními lístky. Věž podložena položenou berlou závitem dolů a mečem hrotem doprava, obojí zlaté."
    },
    {
        obec: "Otradov",
        kod: 554952,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53943,
        latitude: 49.793442,
        longitude: 16.043608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Otradov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modro - červeně polcený a bílý, v poměru 1 : 6 : 1. Uprostřed polceného pruhu žlutá rozevřená kniha, v ní na žerďové straně černé zkřížené ostrve, na vlající straně modrý dvouocasý lev s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě zlatá rozevřená kniha, v ní vpravo zkřížené černé ostrve, vlevo modrý dvouocasý lev s červenou zbrojí, nad knihou položený stříbrný nůž se zlatou rukojetí."
    },
    {
        obec: "Otročín",
        kod: 555444,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36401,
        latitude: 50.032393,
        longitude: 12.892407,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Municipal_arms_of_Otro%C4%8D%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a osm střídavě bílých a červených vodorovných pruhů. V horní polovině žerďového pruhu vztyčený žlutý meč hořící sedmi červenými plameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli vztyčený zlatý meč hořící sedmi červenými plameny, levé pole sedmkrát stříbrno-červeně dělené."
    },
    {
        obec: "Otročiněves",
        kod: 531669,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26703,
        latitude: 49.980598,
        longitude: 13.978869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Otrocineves_znak_final_RGB.png",
        coatOfArmsFlagDescription: "List je kosmým žlutým pruhem širokým jednu třetinu délky listu rozdělen na dvě trojúhelníková pole, vlající modré a žerďové červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrá plamenná orlice s červenou zbrojí nesoucí na hrudi stříbrný štítek s černými hornickými kladívky."
    },
    {
        obec: "Otrokovice",
        kod: 585599,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76502,
        latitude: 49.210016,
        longitude: 17.530876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Znak_mesta_otrokovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý. Přes žerďový a střední pruh vztyčený lipový list opačných barev. Ve vlajícím modrém pruhu u středního pruhu bílé krojidlo hrotem dolů a ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Otvice",
        kod: 563277,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43111,
        latitude: 50.480923,
        longitude: 13.45081,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Otvice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list. Pod žlutou korunou vyrůstá z dolního okraje listu bílá přirozená lilie se třemi květy na žlutém stonku se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou korunou vyrůstající stříbrná přirozená lilie se třemi květy na zlatém stonku se dvěma listy."
    },
    {
        obec: "Otvovice",
        kod: 532681,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27327,
        latitude: 50.211664,
        longitude: 14.27303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Otvovice_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s červeným středovým křížem podloženým ondřejským křížem s černo-žluto-černě podélně dělenými rameny. Ramena obou křížů jsou široká čtvrtinu šířky listu. V červeném kříži kosmo a šikmo čtvrcený kříž s bílými svislými a žlutými vodorovnými rameny širokými dvanáctinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšený červený heroldský kříž, ve středu se zlatou korunou s modrými drahokamy, provlečenou postavenou stříbrnou sklářskou píšťalou. Kříž je podložen obrácenými černými hornickými kladívky a dole přeložený černou hlavou ďábla s červenými rohy a jazykem, se zlatým obojkem, vynikajícím z dolního okraje štítu, se dvěma řetězy do stran."
    },
    {
        obec: "Ouběnice",
        kod: 541010,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.717747,
        longitude: 14.159939
    },
    {
        obec: "Oucmanice",
        kod: 580741,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 49.989464,
        longitude: 16.281865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Oucmanice.svg",
        coatOfArmsFlagDescription: "Modrý list se žerďovým vydutým klínem zakončeným ve vlající části lilií s vrcholem na vlajícím okraji, provázeným nahoře a dole zvonem, vše žluté. V žerďové části klínu modrý zvon. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve sníženém zlatém vydutém hrotu zakončeném lilií modrý zvon, po stranách dva zlaté zvony."
    },
    {
        obec: "Oudoleň",
        kod: 569224,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58224,
        latitude: 49.659429,
        longitude: 15.755752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Oudole%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým žerďovým klínem s vrcholem na vlajícím okraji. Ve žlutém klínu tři červené žerďové klíny s vrcholy sahajícími do jedné čtvrtiny délky listu a dvě zkřížené černé ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatým zkráceným klínem s vycházejícím červeným sluncem zlatý štítek se zkříženými černými ostrvemi."
    },
    {
        obec: "Ovčáry",
        kod: 533572,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.062882,
        longitude: 15.239607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Ov%C4%8D%C3%A1ry_%28okres_Kol%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený.V žerďové polovině listu v červeném pruhu bílá svatojakubská mušle podložená zkříženými žlutými poutnickými holemi, v zeleném čelně hledící vykračující bílá ovečka se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně děleném štítě nahoře stříbrná svatojakubská mušle podložená zkříženými zlatými poutnickými holemi, dole vykračující, čelně hledící stříbrná ovečka se zlatou zbrojí."
    },
    {
        obec: "Ovčáry",
        kod: 535141,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27714,
        latitude: 50.253151,
        longitude: 14.613962,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Ov%C4%8D%C3%A1ry_ME_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 9 : 1 : 6. V zeleném pruhu bílá ovce se žlutou zbrojí. V modrém pruhu zvon provázený dvěma odkloněnými okurkovými květy s úponky, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře sníženým zúženým stříbrným vlnitým břevnem dělený štít. Nahoře stříbrná ovce se zlatou zbrojí, dole zvon provázený po obou stranách odkloněným okurkovým květem s úponkem, vše zlaté."
    },
    {
        obec: "Ovesná Lhota",
        kod: 569232,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.722657,
        longitude: 15.38416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Ovesn%C3%A1_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-žlutě plamenným řezem šikmo dělený list. V modrém poli bílá hlava psa - věžníka s červeným jazykem a žlutým obojkem s kroužkem, ve žlutém poli šikmo zelený ovesný klas. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě plamenně šikmo dělený štít. Nahoře stříbrná hlava psa - věžníka s červeným jazykem a zlatým obojkem s kroužkem, dole šikmo zelený ovesný klas."
    },
    {
        obec: "Ovesné Kladruby",
        kod: 539473,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.953112,
        longitude: 12.779206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/CoA_Ovesne_Kladruby.gif",
        coatOfArmsFlagDescription: "Žlutý list s červeným žerďovým pruhem širokým jednu čtvrtinu délky listu. V červeném pruhu bílý meč se žlutým jílcem hrotem k dolnímu okraji. Ve žlutém poli černé paroží, uprostřed kosmá modrá sekera na černém topůrku šikmo podložená zeleným ovesným klasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod červenou hlavou s doleva položeným stříbrným mečem se zlatým jílcem černé paroží, uprostřed kosmá modrá sekera na černém topůrku šikmo podložená zeleným ovesným klasem."
    },
    {
        obec: "Oznice",
        kod: 544574,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75624,
        latitude: 49.432567,
        longitude: 17.917399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/CZ_Oznice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, červený a žlutý, v poměru 5 : 2 : 1 : 4. V žerďové a vlající polovině horního červeného pruhu po žlutém zvonu se závěsem. Ve střední části bílého pruhu černé krojidlo ostřím k dolnímu a hrotem k vlajícímu okraji listu, ve žlutém pruhu černé vozové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad zlatým trojvrším s černým vozovým kolem volný stříbrný hrot s doleva obráceným černým krojidlem. Hrot nahoře provázejí dva zlaté zvony se závěsy."
    },
    {
        obec: "Paběnice",
        kod: 531278,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28543,
        latitude: 49.84174,
        longitude: 15.302429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Pabenice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V horním rohu bílá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě svatojakubská mušle opačných barev."
    },
    {
        obec: "Paceřice",
        kod: 545953,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46344,
        latitude: 50.619335,
        longitude: 15.113636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Pace%C5%99ice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý a červený, v poměru 5:1:2. Ze žlutého pruhu vyrůstá rozkřídlená hermelínová husa s červenou zbrojí a žlutým jazykem. V červeném pruhu čtyři svěšené žluté lipové listy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně sníženě děleném štítě, nahoře vynikající rozkřídlená hermelínová husa s červenou zbrojí a zlatým jazykem. Dole vyniká polovina vozového kola se čtyřmi svěšenými lipovými listy mezi rameny, vše zlaté."
    },
    {
        obec: "Pacetluky",
        kod: 588831,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76843,
        latitude: 49.379562,
        longitude: 17.56585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Pacetluky_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým obilným snopem převázaným bílo-červeno-černou trikolórou mezi dvěma, hroty vzhůru postavenými, přivrácenými bílými kosami bez násad. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý postavený obilný snop převázaný stříbrno-červeno-černou stuhou a provázený po stranách dvěma hroty vzhůru postavenými přivrácenými stříbrnými kosami bez násad."
    },
    {
        obec: "Pacov",
        kod: 548511,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.470865,
        longitude: 15.001783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Pacov-znak.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: modrý, červený, bílý a žlutý, v horním žerďovém rohu je žluté karé s figurou z městského znaku, s modro-červeně děleným lvem opírajícím se třemi tlapami o bílou věž s červenou střechou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Pačejov",
        kod: 556912,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.373007,
        longitude: 13.632835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Pa%C4%8Dejov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, červený, bílý, červený, modrý, bílý a modrý, v poměru 1 : 1 : 2 : 2 : 1 : 1. Uprostřed listu kolčí helm s mezikružím v klenotu, provázený po stranách lilií a nahoře do oblouku pěti kotoučky, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě kolčí helm s kotoučem s prázdným středem v klenotu, provázený po stranách lilií a nahoře do oblouku pěti kotoučky, vše stříbrné."
    },
    {
        obec: "Pačlavice",
        kod: 588849,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76834,
        latitude: 49.25977,
        longitude: 17.169012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Pa%C4%8Dlavice_znak.png",
        coatOfArmsFlagDescription: "Červený list se zkříženými vztyčenými přivrácenými bílými krojidly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě dvě zkřížená vztyčená přivrácená stříbrná krojidla."
    },
    {
        obec: "Páleč",
        kod: 564192,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27371,
        latitude: 50.312029,
        longitude: 14.049685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/P%C3%A1le%C4%8D_CoA.png",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. V modrém poli lilie a šesticípá hvězda, v červeném k vlajícímu okraji šikmo položená a dolů prohnutá vykořeněná větev vinné révy se třemi vzhůru rostoucími listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně šikmo dělený štít. Nahoře lilie a hvězda, dole šikmo doleva položená a dolů prohnutá vykořeněná větev vinné révy se třemi vzhůru rostoucími listy, vše zlaté."
    },
    {
        obec: "Palkovice",
        kod: 598551,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73941,
        latitude: 49.63477,
        longitude: 18.315185,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Palkovice_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy - bílý, červený, žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně dělený štít, nahoře dva stříbrné kužely, dole zlaté jehněcí rouno."
    },
    {
        obec: "Palonín",
        kod: 540595,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78983,
        latitude: 49.729051,
        longitude: 16.952733,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/CoA_of_Palon%C3%ADn.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým kosmým pruhem širokým polovinu šířky listu, v něm červené srdce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmé stříbrné břevno, v něm červené srdce podložené černým provaznickým hákem, obojí kosmé."
    },
    {
        obec: "Pálovice",
        kod: 550469,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.00562,
        longitude: 15.537655,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/P%C3%A1lovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu se žlutým vztyčeným klasem a červené pole s kosmo položenou žlutou ostrví, šikmo přeloženou vztyčenou bílou rybářskou udicí hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zelenou hlavou s položeným zlatým obilným klasem kosmo zlatá ostrev, šikmo přeložená vztyčenou stříbrnou rybářskou udicí hrotem doleva."
    },
    {
        obec: "Pamětice",
        kod: 582191,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.549563,
        longitude: 16.644594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Pam%C4%9Btice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným křížem s rameny širokými dvě sedminy šířky listu. Na svislých ramenech obilný klas se dvěma listy mezi dvěma liliemi na vodorovných ramenech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený heroldský kříž s obilným klasem se dvěma listy, provázeným dvěma liliemi, vše zlaté."
    },
    {
        obec: "Panenská Rozsíčka",
        kod: 587656,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58901,
        latitude: 49.252661,
        longitude: 15.515001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Panensk%C3%A1_Rozs%C3%AD%C4%8Dka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 9:1. Ze žlutého pruhu vyrůstá žlutá berla s bílou růží s červeným semeníkem a zelenými kališními lístky v hlavici závitem k žerďovému okraji, do stran z berly vyrůstají čtyři (2,2) stejné růže na bílých stoncích, každý se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zlatého návrší vyrůstá zlatá berla se stříbrnou růží s červeným semeníkem a zelenými kališními lístky v hlavici. Z berly vyrůstají do stran čtyřmi stejné růže na stříbrných stoncích, každý se dvěma listy."
    },
    {
        obec: "Panenské Břežany",
        kod: 538604,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25070,
        latitude: 50.213867,
        longitude: 14.44021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Panenske_Brezany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed dva bílé vodorovné pruhy široké a od sebe vzdálené jednu šestnáctinu šířky listu, proložené dvěma zkříženými žlutými berlami s bílými sudarii tak, že kosmá berla prochází nad horním a pod dolním bílým pruhem a šikmá berla pod horním a nad dolním pruhem. Mezi berlami nahoře vztyčený bílý březový list, dole žlutá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrná zúžená břevna proložená dvěma zkříženými zlatými berlami se stříbrnými sudarii, provázená nahoře vztyčeným stříbrným březovým listem a dole zlatou svatojakubskou mušlí."
    },
    {
        obec: "Panenský Týnec",
        kod: 566535,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43905,
        latitude: 50.295028,
        longitude: 13.917056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Panensk%C3%BD_T%C3%BDnec_znak_2015.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, bílý a černý, v poměru 1 : 8 : 1. V bílém pruhu černá orlice se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá orlice se zlatou zbrojí a jazykem."
    },
    {
        obec: "Panoší Újezd",
        kod: 542211,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27021,
        latitude: 50.036103,
        longitude: 13.716918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Pano%C5%A1%C3%AD_%C3%9Ajezd_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Červený list s kosmo položenou bílou kuší v žerďové polovině, mířící do horního rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným lemem stříbrná polovina panoše s kosmo drženou kuší."
    },
    {
        obec: "Panské Dubenky",
        kod: 587672,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 37853,
        latitude: 49.219628,
        longitude: 15.265918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Pansk%C3%A9_Dubenky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, s děleným žerďovým klínem opačných barev s vrcholem na vlajícím okraji listu. V klínu zelený dubový list řapíkem k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě hrot opačných barev, v něm vztyčený zelený dubový list."
    },
    {
        obec: "Paračov",
        kod: 551571,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.201136,
        longitude: 13.994739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Para%C4%8Dov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým petrským křížem vycházejícím z druhé šestiny horního okraje a šesté a sedmé osminy žerďového okraje listu. Ve svislých ramenech kříže modrý vztyčený meč, horní vlající pole červené s bílým leknínovým trojlistem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný petrský kříž do krajů a doprava, v něm modrý vztyčený meč. Levé horní pole červené se stříbrným leknínovým trojlistem."
    },
    {
        obec: "Paršovice",
        kod: 516635,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75355,
        latitude: 49.50123,
        longitude: 17.708077,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Par%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Uprostřed žlutý gryf držící v pravém pařátu bílou perlu a v levém bílý vinařský nůž se žlutou rukojetí ostřím k sobě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě zlatý gryf držící v pravém pařátu stříbrnou perlu a v levém stříbrný vinařský nůž se zlatou rukojetí."
    },
    {
        obec: "Pardubice",
        kod: 555134,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.034409,
        longitude: 15.781299,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Pardubice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list s bílou polovinou koně ve skoku se žlutou uzdou ve střední části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná polovina koně ve skoku se zlatým uzděním"
    },
    {
        obec: "Partutovice",
        kod: 516686,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.631419,
        longitude: 17.708201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Partutovice_znak.png",
        coatOfArmsFlagDescription: "Zelený list s bílým ondřejským křížem s rameny širokými jednu osminu délky listu. Na středu kříže žlutá berla. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná růžice větrného mlýna přeložená zlatou berlou."
    },
    {
        obec: "Pařezov",
        kod: 599182,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.470477,
        longitude: 12.83793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Pa%C5%99ezov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v obou vykořeněný pařez, z jehož boků vyrůstá do stran ratolest se dvěma lipovými listy. Pařez v bílém pruhu zelený, v zeleném žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně dělený štít. V obou polích vykořeněný pařez, z jehož boků vyrůstá do stran ratolest se dvěma lipovými listy. Horní pařez zelený, dolní zlatý."
    },
    {
        obec: "Paseka",
        kod: 504785,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78397,
        latitude: 49.796542,
        longitude: 17.222863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Paseka_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký dvě pětiny délky listu se žlutou korunou a bílé pole se třemi černými šikmými pruhy vycházejícími z první, třetí a páté šestiny dolního okraje bílého pole do druhé, čtvrté a šesté šestiny horního okraje pole. Na prostředním pruhu bílá Aesculapova hůl ovinutá žlutým hadem přesahující do bílých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatou korunou o třech liliových výběžcích tři šikmá černá břevna a na prostředním z nich stříbrná Aesculapova hůl, ovinutá zlatým hadem."
    },
    {
        obec: "Pasečnice",
        kod: 554081,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.395458,
        longitude: 12.893516
    },
    {
        obec: "Paseky",
        kod: 562254,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39811,
        latitude: 49.25174,
        longitude: 14.254921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Paseky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý, bílý, modrý, bílý, modrý a zelený, v poměru 1 : 1 : 1 : 1 : 1 : 5. Uprostřed zeleného pruhu žluté vozové kolo, na kole a na horním okraji zeleného pruhu stojí pták - ořešník kropenatý hledící k vlajícímu okraji. Poměr šířky k délce je v poměru 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nahoře dvě stříbrná břevna, pod nimi zelené trojvrší. V prostředním nižším vrchu zlaté vozové kolo, z krajních vyrůstá zelený listnatý strom se zlatým kmenem, mezi nimi, na prostředním vrchu, stojí doleva obrácený pták - ořešník kropenatý přirozené barvy."
    },
    {
        obec: "Paseky nad Jizerou",
        kod: 547476,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51247,
        latitude: 50.724065,
        longitude: 15.400555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Paseky_nad_Jizerou_CoA.svg",
        coatOfArmsFlagDescription: "Bílo - zeleně šikmo pilovitým řezem dělený list. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "Ve stříbrno - zeleně pilovitě šikmo děleném štítě nahoře šikmý černý levý houslový výřez - effe."
    },
    {
        obec: "Paskov",
        kod: 598569,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73921,
        latitude: 49.731885,
        longitude: 18.290478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Paskov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, modrý a bílý, v poměru 1:2:1. V modrém pruhu Panna Maria v bílém šatě v červeném plášti stojící na bílém půlměsíci. V pravici žluté žezlo, na levici Jezulátko se žlutým říšským jablkem v ruce. Obě postavy korunované a s nimby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném půlměsíci stojící Panna Marie ve stříbrném šatě a červeném plášti, držící v pravici zlaté žezlo, na levici Jezulátko se zlatým říšským jablkem v ruce, obě postavy korunované a s nimby."
    },
    {
        obec: "Pasohlávky",
        kod: 584762,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69122,
        latitude: 48.90306,
        longitude: 16.543748,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Pasohl%C3%A1vky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V žerďové části bílého pruhu červený rak klepety k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít. V horní stříbrné polovině nad modrou vlnitou patou červený ležící rak. Spodní polovina červeno-stříbrně polcená, vpravo stříbrný vinařský nůž s hnědou střenkou, vlevo dřevěná loďka, z níž vyrůstají z jednoho stonku dvě překřížené ratolesti zakončené žaludem, vše v přirozené barvě."
    },
    {
        obec: "Pastuchovice",
        kod: 566781,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33165,
        latitude: 50.069086,
        longitude: 13.382322
    },
    {
        obec: "Pastviny",
        kod: 580759,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.094975,
        longitude: 16.566309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Pastviny.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi vlnitými pruhy, bílým, modrým a bílým o šířce jedné dvaadvacetiny šířky listu v dolní třetině listu, přes pruhy je v žerďové části listu postavena žlutá vykořeněná lípa. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá vykořeněná lípa podložená v dolní části dvěma stříbrnými zúženými vlnitými břevny."
    },
    {
        obec: "Pašinka",
        kod: 513423,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.996452,
        longitude: 15.186297
    },
    {
        obec: "Pašovice",
        kod: 592480,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68756,
        latitude: 49.07963,
        longitude: 17.644446,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, červený a žlutý v poměru 1:3:1. V červeném poli bílé lekno přeložené žlutým vinným stonkem s hroznem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrné lekno přeložené zlatým vinným stonkem s hroznem."
    },
    {
        obec: "Pátek",
        kod: 537632,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.165617,
        longitude: 15.158562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/P%C3%A1tek_NB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a sedm vodorovných pruhů, bílý, červený, bílý, černý, bílý, červený a bílý. V zeleném pruhu pod sebou pět žlutých včel s bílými křídly, hlavou střídavě k žerďovému a vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zelenou hlavou s pěti zlatými včelami se stříbrnými křídly, střídavě hlavou nahoru a dolů, vztyčený černý rošt provázený dvěma vzhůru plovoucími přivrácenými červenými rybami."
    },
    {
        obec: "Patokryje",
        kod: 567345,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.499915,
        longitude: 13.705297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Patokryje_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a modrý. Ve žlutém pruhu noha po kotník palcem k dolnímu cípu, s modrým krytem paty s tříhrotou ostruhou upevněným přes nárt. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zlato-modře dvakrát polcený štít. Vpravo nahoře stříbrná chmelová šištice, uprostřed pod sebou tři nohy po kotník palcem kosmo dolů přirozené barvy, s modrým krytem paty upevněným přes nárt, prostřední kryt s modrou tříhrotou ostruhou. Vlevo nahoře zlatý vinný hrozen."
    },
    {
        obec: "Pavlice",
        kod: 594601,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67156,
        latitude: 48.971577,
        longitude: 15.899056,
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zelený, bílý, červený a žlutý. Uprostřed listu nad šesticípou hvězdou kotvicový kříž s kratšími vodorovnými rameny, oboje opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole stříbrno - červeně polcené s kotvicovým křížem nad hvězdou, obojí opačných barev. Levé pole zlato - zeleně nahoru třikrát lomeně dělené, nahoře dvakrát esovitě stočený zelený had s červeným jazykem, dole stříbrný džbán s uchem doleva."
    },
    {
        obec: "Pavlíkov",
        kod: 544248,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27021,
        latitude: 50.056094,
        longitude: 13.73647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/CoA_of_Pavl%C3%ADkov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a bílý v poměru 2:1. V červeném pruhu tři žluté od středu odkloněné obilné klasy, v bílém černá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou patou s černými hornickými kladívky tři zlaté od středu odkloněné obilné klasy."
    },
    {
        obec: "Pavlínov",
        kod: 596388,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.348216,
        longitude: 15.878564,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Pavlinov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, černý, bílý, červený, bílý a zelený v poměru 1:4:10:4:1. V červeném pruhu u žerdi bílá holubice se žlutou zbrojí prostřelená zespodu šikmo žlutým šípem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený hrot, v něm na stříbrné kvádrované zdi s cimbuřím stojí stříbrná holubice se zlatou zbrojí, prostřelená zespodu šikmo zlatým šípem. Hrot je provázen vpravo černou kápí a vlevo zeleným lipovým listem na stonku."
    },
    {
        obec: "Pavlov",
        kod: 584771,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69201,
        latitude: 48.874256,
        longitude: 16.672359,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Pavlov_BV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed u dolního okraje listu tři bílé kameny vedle sebe. Z prostředního vyrůstá bílá hlava vinné révy nahoře se dvěma listy nad dvěma žlutými hrozny. Mezi listy a hrozny přes stonek révy parma hlavou k žerďi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze tří stříbrných kamenů v patě štítu stříbrná hlava vinné révy se dvěma listy nahoře a dvěma zlatými hrozny uprostřed, přeložená doleva plovoucí parmou přirozené barvy."
    },
    {
        obec: "Pavlov",
        kod: 569241,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.699317,
        longitude: 15.332621,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Coat_of_arms_of_Pavlov_HB.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a zelené pole. V první třetině délky listu bílý skleněný pohár provázený nahoře dvěma žlutými obilnými klasy s listy a dole dvěma bílými kříži, v pruhu řeckým a v poli osmihrotým. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrný skleněný pohár provázený nahoře dvěma zlatými obilnými klasy s listy a dole dvěma stříbrnými kříži, vpravo řeckým a vlevo osmihrotým."
    },
    {
        obec: "Pavlov",
        kod: 561916,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.398772,
        longitude: 15.244081
    },
    {
        obec: "Pavlov",
        kod: 596396,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.450847,
        longitude: 15.913945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Pavlov_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a bílý, v poměru 2:1:1. V modrém pruhu bílý dvojramenný kříž s delším horním ramenem, dole kosmo přeložený žlutou knihou s červeným řeckým křížem a bílou ořízkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo doleva černé orlí křídlo s celým zlatým, jetelovitě zakončeným perizoniem, vlevo stříbrný dvojramenný kříž s delším horním ramenem, dole kosmo přeložený zlatou knihou s červeným řeckým křížem desce a se stříbrnou ořízkou."
    },
    {
        obec: "Pavlov",
        kod: 540609,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78985,
        latitude: 49.74289,
        longitude: 16.879352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Pavlov_SU_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s kráčejícím pávem dole v oblouku z šesti šesticípých hvězd, vše modré. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kráčející páv, nad ním šest hvězd vedle sebe, vše modré."
    },
    {
        obec: "Pavlov",
        kod: 532711,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.096481,
        longitude: 14.167154,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Pavlov_KL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, bílý a červený v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zkříženy zlatá limpa a stříbrný meč, přes ně položena zlatá koruna."
    },
    {
        obec: "Pavlov",
        kod: 587681,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.242892,
        longitude: 15.55721,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1:2:1, a dva žluté klíny, žerďový s vrcholem v první třetině a vlající s vrcholem ve druhé třetině délky listu. Mezi nimi žlutá sedmilistá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad modrou patou se zlatou sedmilistou růží zlatý vykračující kohout."
    },
    {
        obec: "Pavlovice",
        kod: 533076,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.715216,
        longitude: 14.933216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Pavlovice_BN_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 8 : 1 : 1. V horním pruhu bílé průčelí kaple s věží, s vchodem a oknem nad nikou, vše černé. Věž zakončena červenou stanovou střechou se žlutým křížkem. Kaple provázena v žerďové části zkříženými obilnými klasy, ve vlající části vztyčeným dubovým dvojlistem s žaludem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným vlnitým břevnem stříbrné průčelí kaple s věží, s vchodem a oknem nad nikou, vše černé. Věž zakončena červenou stanovou střechou se zlatým křížkem a provázena vpravo zkříženými obilnými klasy, vlevo vztyčeným dubovým dvojlistem s žaludem, vše zlaté."
    },
    {
        obec: "Pavlovice u Kojetína",
        kod: 557196,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79830,
        latitude: 49.302054,
        longitude: 17.212617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Pavlovice_u_Kojet%C3%ADna_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený žlutým ondřejským křížem s rameny širokými jednu osminu šířky listu. V zeleném žerďovém poli vztyčený žlutý lipový trojlist, v zeleném vlajícím poli letící bílá holubice se žlutou zbrojí, v červeném horním a dolním poli po jedné bílé lilii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno - zeleně zlatým ondřejským křížem dělený štít, v horním a dolním poli stříbrná lilie, v pravém poli vztyčený zlatý lipový trojlist, v levém poli letící stříbrná holubice se zlatou zbrojí."
    },
    {
        obec: "Pazderna",
        kod: 552500,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.704374,
        longitude: 18.434339,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Pazderna_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý s modrým květem lnu v žerďové části a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě tři (2,1) modré květy lnu."
    },
    {
        obec: "Pavlovice u Přerova",
        kod: 516694,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.46952,
        longitude: 17.547853,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Pavlovice_u_P%C5%99erova_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - modrý, červený a bílý, v poměru 1:2:1. V červeném poli tři bílé vlčí hlavy se žlutými jazyky (2+1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrá vyrůstající orlice se zlatou zbrojí a stříbrným perisoniem, v stříbrné hlavě štítu tři stříbrné vlčí hlavy se zlatými jazyky."
    },
    {
        obec: "Pec",
        kod: 554090,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.398684,
        longitude: 12.831288,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Pec_DO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed volná kvádrovaná bílá pec, podložená zkříženými žlutými sklářskými píšťalami a převýšená kosmo sekerou, šikmo podloženou koplicí, obojí bílé na žlutých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě volná stříbrná kvádrovaná pec podložená zkříženými zlatými sklářskými píšťalami a převýšená kosmou sekerou šikmo podloženou koplicí, obojí stříbrné na zlatých topůrkách."
    },
    {
        obec: "Pec pod Sněžkou",
        kod: 579581,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54221,
        latitude: 50.693981,
        longitude: 15.733267,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Pec_pod_Snezkou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým klínem na dolním okraji s vrcholem ve středu listu. Nad klínem tři (1,2) bílé sněhové vločky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým hrotem tři (1,2) sněhové vločky, vše stříbrné."
    },
    {
        obec: "Pecka",
        kod: 573299,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50782,
        latitude: 50.480435,
        longitude: 15.608329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Pecka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý bílý a červený, v poměru 5 : 1 : 1. V žerďové polovině modrého pruhu bílý půlměsíc cípy k hornímu okraji listu převýšený žlutou korunou. Bílý pruh má šest obdélníkových zubů vysokých polovinu jejich šířky a sedm stejných mezer. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba se třemi černými klíčovými střílnami a šesti stínkami cimbuří. Z hradby vyrůstají dvě stříbrné okrouhlé věže, každá s černým oknem a červenou kuželovou kvádrovanou střechou se zlatou makovicí. Mezi věžemi ze stříbrného stoupajícího půlměsíce vyrůstající, červeně oděná a korunovaná Panna Marie s Jezulátkem na levé ruce a zlatým žezlem v pravici, oba se zlatou svatozáří. V hradbě zlatá majuskulní písmena F. P. B. P."
    },
    {
        obec: "Peč",
        kod: 507717,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.057931,
        longitude: 15.392475
    },
    {
        obec: "Pečice",
        kod: 541028,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26232,
        latitude: 49.600518,
        longitude: 14.105591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Pe%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "Červený list. Uprostřed pod žlutou korunou bílý rošt provázený po stranách nahoře dvěma bílými kypřicemi a dole dvěma vztyčenými odkloněnými žlutými dubovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou korunou stříbrný rošt provázený po stranách nahoře dvěma stříbrnými kypřicemi a dole dvěma vztyčenými odkloněnými zlatými dubovými listy."
    },
    {
        obec: "Pěčice",
        kod: 571032,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29446,
        latitude: 50.35125,
        longitude: 15.004099,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/P%C4%9B%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-modře šikmo zubatě dělený list. Do zeleného pole vyrůstá žlutý kozel s červeným jazykem, v modrém poli šikmo položený bílý zvon s liliovým srdcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře šikmo cimbuřovitě děleném štítě vpravo vyrůstající zlatý kozel s červeným jazykem, vlevo šikmo položený stříbrný zvon s liliovitým srdcem."
    },
    {
        obec: "Pěčín",
        kod: 576620,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51757,
        latitude: 50.153878,
        longitude: 16.424789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/P%C4%9B%C4%8D%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, zelený, bílý, zelený, červený, bílý a červený, v poměru 2 : 1 : 3 : 3 : 1 : 2. Ve dvou prostředních pruzích bílá vykořeněná lekna, uprostřed třícípá bílá korouhev s červeným heroldským křížem na žluté žerdi zakončené jetelovým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně děleném štítě stříbrná vykořeněná lekna, uprostřed třícípá stříbrná korouhev s červeným heroldským křížem na zlaté žerdi zakončené jetelovým křížem."
    },
    {
        obec: "Pečky",
        kod: 537641,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.090527,
        longitude: 15.030275,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Pe%C4%8Dky.gif",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, žlutý, bílý, červený, bílý a modrý, v poměru 9 : 4 : 4 : 4 : 9. Ve žlutém pruhu bílá, černě zabalená homole cukru. V modrém pruhu žlutý pšeničný klas bez stébla. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Pěčnov",
        kod: 537543,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.053207,
        longitude: 13.942131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/P%C4%9B%C4%8Dnov_znak.jpg",
        coatOfArmsFlagDescription: "Zeleno-modře šikmo dělený list. V zeleném poli modrá bíle lemovaná šesticípá hvězda, v modrém poli polovina žlutého koně ve skoku s červenými kopyty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře šikmo děleném štítě nahoře stříbrně lemovaná modrá hvězda, dole polovina zlatého koně s červenými kopyty."
    },
    {
        obec: "Pelechy",
        kod: 566373,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.389227,
        longitude: 12.914379
    },
    {
        obec: "Pěnčín",
        kod: 563749,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46821,
        latitude: 50.687791,
        longitude: 15.236038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/P%C4%9Bn%C4%8D%C3%ADn_%28okres_Jablonec_nad_Nisou%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený. V žerďové a střední části zeleného pruhu náhrdelník se sedmi bílými perlami na žluté, nahoře zavázané šňůře. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném návrší zlatá sklářská pec ve stříbrném stavení s modrým kvádrovaným soklem, zelenou střechou s modrou nástavbou krytou zelenou stříškou, dvěma okny a dvěma komíny po stranách. V návrší náhrdelník se sedmi stříbrnými perlami na zlaté, nahoře zavázané šňůře."
    },
    {
        obec: "Pelhřimov",
        kod: 547492,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.430721,
        longitude: 15.223083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Pelhrimov.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý bílý a zelený, v poměru 1 : 2 : 1. Ve střední části listu poutník s černým kloboukem a pláštěm, bílou suknicí a šedou holí v pravé ruce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stojí hradba, z ní vynikají dvě věže, každá s jedním oknem, červenou valbovou střechou a dvěma zlatými makovicemi, vše stříbrné, z kvádrů a ukončené cimbuřím; do hradby je prolomena brána s polovytaženou zlatou mříží, otevřenými zlatými vraty, černě kovanými, z ní vychází muž oděný v poutnickém šatě se stříbrnými mušlemi a s holí v pravé ruce; pod cimbuřím po stranách brány jsou dvě střílny; mezi věžemi je štítek se znakem Českého království (nyní malý znak České republiky), pod ním je na cimbuří zavěšen štítek se znakem pražského arcibiskupství."
    },
    {
        obec: "Pěnčín",
        kod: 564303,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.594968,
        longitude: 15.076196,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Znakpencin.jpg",
        coatOfArmsFlagDescription: "List tvoří bílé karé široké jednu třetinu délky listu nad stejně velkým modrým čtvercovým polem a dva vodorovné pruhy, modrý a žlutý. V karé červené jablko na stopce se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře rostoucí zlatý lev s červenou zbrojí, dole červené jablko na stopce se dvěma zelenými listy."
    },
    {
        obec: "Pěnčín",
        kod: 589870,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79857,
        latitude: 49.567294,
        longitude: 17.013234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Pencin_PV_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený se žlutým vozovým kolem, bílý s modrým kalichem a modrý se třemi žlutými půlměsíci (2,1), z nichž horní dva jsou odvrácené a dolní směřuje cípy k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zvýšeně dělený štít, nahoře červeno-modře polcený, vpravo vozové kolo, vlevo tři (2,1) odvrácené půlměsíce, vše zlaté, v dolním stříbrném poli modrý kalich."
    },
    {
        obec: "Perálec",
        kod: 572004,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.826885,
        longitude: 16.079952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Per%C3%A1lec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, modrý, bílý, modrý, zelený, bílý a zelený, v poměru 5:3:3:2:1:2. U žerďového okraje horního modrého pruhu žlutý vztyčený lipový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zelenou patou s položeným stříbrným krojidlem stříbrný kráčející beránek se zlatou zbrojí držící pravou přední nohou ratiště zakončené křížkem a provázený vlevo nahoře vztyčeným lipovým listem, obojí zlaté."
    },
    {
        obec: "Perná",
        kod: 584789,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69186,
        latitude: 48.85232,
        longitude: 16.624527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Perna_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, červený a žlutý v poměru 1:2:1. V červeném pruhu bílá radlice a žlutý vinný hrozen na zeleném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vpravo stříbrná postavená radlice, vlevo zlatý vinný hrozen na zeleném stonku."
    },
    {
        obec: "Pernarec",
        kod: 559334,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33036,
        latitude: 49.840507,
        longitude: 13.102822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Pernarec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký čtvrtinu délky listu s trojím černým jelením parožím pod sebou a zelené pole. Do střední části listu vyniká z jeho dolního okraje žlutá berla závitem zakončeným lilií k žerďovému okraji. Ve vlající části koňská hlava s krkem nad šesticípou hvězdou, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou s trojím černým parožím vyrůstá vpravo zlatá berla se závitem zakončeným lilií, vlevo koňská hlava s krkem nad hvězdou, obojí stříbrné."
    },
    {
        obec: "Pernink",
        kod: 555452,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36236,
        latitude: 50.365797,
        longitude: 12.783825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Pernink_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě červené a žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Pernštejnské Jestřabí",
        kod: 596400,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59261,
        latitude: 49.406417,
        longitude: 16.32571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Pern%C5%A1tejnsk%C3%A9_Jest%C5%99ab%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý s černou zubří hlavou s červeným jazykem a žlutou houžví a červený s bílým rozkřídleným útočícím jestřábem se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně děleném štítě nahoře černá zubří hlava s červeným jazykem a zlatou houžví, dole stříbrný rozkřídlený útočící jestřáb se zlatou zbrojí."
    },
    {
        obec: "Perštejn",
        kod: 563285,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43163,
        latitude: 50.381769,
        longitude: 13.110315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Per%C5%A1tejn_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zelenou krokví s rameny vycházejícími z první a čtvrté čtvrtiny žerďového okraje s vrcholem ve středu listu. Trojúhelníkové žerďové pole bílé. Z krokve vychází k vlajícímu okraji vodorovný bílý pruh široký osminu šířky listu, lemovaný červenými pruhy širokými šestnáctinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trojvrší se stříbrnou, vzhůru do oblouku prohnutou štikou sv. Vendelín v zeleném šatě, s poutnickou holí v pravici a stříbrnou ovečkou u levé nohy."
    },
    {
        obec: "Pertoltice",
        kod: 564311,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46373,
        latitude: 50.979084,
        longitude: 15.076532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Znakpertoltice.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým jednu čtvrtinu délky listu. V bílém pruhu dva svěšené zelené lipové listy pod sebou, v modrém poli koruna provázená dole do oblouku pěti šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou se dvěma svěšenými zelenými lipovými listy zlatá koruna provázená dole do oblouku pěti zlatými hvězdami."
    },
    {
        obec: "Pertoltice",
        kod: 534307,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.75491,
        longitude: 15.181716
    },
    {
        obec: "Pertoltice pod Ralskem",
        kod: 514276,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47124,
        latitude: 50.680046,
        longitude: 14.719254,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Pertoltice_pod_Ralskem_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žluto-černě polcený, modrý a černo-žlutě polcený, v poměru 1:6:1. Do modrého pruhu vyrůstá žlutá berla závitem k žerďovému okraji, rašící do stran bílým lipovým dvojlistem a provázená u žerďového a vlajícího okraje žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zvýšenou černo-zlatě polcenou patou s polovinou palečného kola opačných barev vyrůstající zlatá berla rašící do stran stříbrným lipovým dvojlistem a nahoře provázená dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Peruc",
        kod: 566551,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43907,
        latitude: 50.342609,
        longitude: 13.959969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Peruc_znak.png",
        coatOfArmsFlagDescription: "Bílý list se žlutým kosmým pruhem o šířce jedné pětině šířky listu s červeným lemem širokým jednu třicetinu šířky listu. Celý list má modrý lem o šířce jedné patnáctiny šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Peřimov",
        kod: 577391,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51204,
        latitude: 50.619384,
        longitude: 15.44404,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Pe%C5%99imov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 5 : 3. Uprostřed žlutá vykořeněná hrušeň se šesti zelenými plody, provázená v zeleném pruhu dvěma žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi zeleným dvojvrším zlatá vykořeněná hrušeň se šesti zelenými plody, ve vrších dvě zlaté osmihroté hvězdy."
    },
    {
        obec: "Pesvice",
        kod: 546062,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43111,
        latitude: 50.463315,
        longitude: 13.484983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Pesvice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma šikmými bílými pruhy vycházejícími z první a třetí dvanáctiny dolního okraje do desáté a dvanácté dvanáctiny horního okraje listu. V žerďovém poli vztyčený žlutý dubový list, ve vlajícím bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné břevno se zelenou dvoulistou leteckou vrtulí. Nahoře vyrůstá zlatý dub, dole stříbrná lilie."
    },
    {
        obec: "Pětihosty",
        kod: 529656,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25167,
        latitude: 49.886462,
        longitude: 14.705522
    },
    {
        obec: "Pětipsy",
        kod: 563293,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43153,
        latitude: 50.321219,
        longitude: 13.349824
    },
    {
        obec: "Pětikozly",
        kod: 598241,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29430,
        latitude: 50.411512,
        longitude: 14.811952
    },
    {
        obec: "Petkovy",
        kod: 536431,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.413286,
        longitude: 15.076925,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Petkovy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 2. Z žerďové poloviny zeleného pruhu vyrůstá zelená třešeň s červenými plody. Pod ní v zeleném pruhu žlutý čížek. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená třešeň s červenými plody vyrůstající ze zvýšeného zeleného trávníku, v něm zlatý čížek."
    },
    {
        obec: "Petráveč",
        kod: 596418,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.327579,
        longitude: 16.041249,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Petr%C3%A1ve%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu červené srdce kvetoucí třemi červenými pětilistými květy se žlutými středy na zelených stoncích, v modrém bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře červené srdce kvetoucí třemi červenými pětilistými květy se zlatými středy na zelených stoncích, dole stříbrná lilie."
    },
    {
        obec: "Petrohrad",
        kod: 566560,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43985,
        latitude: 50.127625,
        longitude: 13.446526,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Petrohrad_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žluto-modře polcený, červeno-bíle polcený a modro-žlutě polcený, v poměru 1:6:1. V prostředním pruhu polovina orlice opačných barev se žlutou zbrojí a žlutým jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vyrůstá z hradby s cimbuřím orlice opačných barev se zlatou zbrojí a zlatým jetelovitě zakončeným perizoniem. Hradba polcena zlato-modře, vlevo dvě stříbrná břevna."
    },
    {
        obec: "Petroupim",
        kod: 530409,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.808356,
        longitude: 14.752698,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Petroupim_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s volnou bílou bránou se žlutou sedlovou střechou a otevřenými žlutými vraty na černých závěsech. Nad bránou tři žluté osmicípé hvězdy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod třemi zlatými osmihrotými hvězdami volná stříbrná brána se zlatou sedlovou střechou a otevřenými zlatými vraty na černých závěsech."
    },
    {
        obec: "Petrov",
        kod: 582204,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.533771,
        longitude: 16.489694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Coat_of_arms_of_Petrov_BK.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený a šest střídavě bílých a černých, v poměru 6 : 1 : 1 : 1 : 1 : 1 : 1. V červeném pruhu zkřížené žluté gotické klíče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. V horním červeném poli zkřížené zlaté gotické klíče, dolní pole pětkrát stříbrno-černě dělené."
    },
    {
        obec: "Petrov",
        kod: 586480,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69665,
        latitude: 48.882055,
        longitude: 17.278203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Znak_obce_Petrov.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed obrněné rameno s vinařským nožem - kosířem, oboje žluté. Nad ramenem v horní části modrého pruhu bílý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlaté obrněné rameno držící zlatý vinařský nůž - kosíř, provázené vlevo nahoře stříbrným vinným hroznem."
    },
    {
        obec: "Petrov",
        kod: 539546,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25281,
        latitude: 49.886498,
        longitude: 14.433839,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Petrov_CoA_PZ_CZ.svg",
        coatOfArmsFlagDescription: "Bílo-červeně štenýřovitě dělený list o dvanácti viditelných kusech se zeleným kantonem. V něm žlutý hořící plochý olejový hornický kahan. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve štítě zvýšený stříbrno-červený štenýř o šestnácti kusech, dole přes něj zelený vrch se zlatým hořícím plochým olejovým hornickým kahanem."
    },
    {
        obec: "Petrov nad Desnou",
        kod: 500020,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78816,
        latitude: 50.005536,
        longitude: 17.04438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Petrov_nad_Desnou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený smrkový s devíti stromy sahajícími do tří pětin šířky listu, vlnitý bílý a zvlněný zelený, v poměru 15 : 1 : 2 : 2. Vlnitý a zvlněný pruh mají po pěti vrcholech a čtyřech prohlubních. Ze smrkového pruhu vyrůstá černý dvouocasý lev se žlutou korunou a červenou zbrojí, držící svisle modrý klíč ozdobený třemi červenými kameny zuby dolů a k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zelené paty oddělené smrkovým řezem černý korunovaný dvouocasý lev s červenou zbrojí, držící postavený modrý klíč, ozdobený třemi červenými kameny. V patě stříbrné vlnité břevno."
    },
    {
        obec: "Petrovice",
        kod: 582212,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.411964,
        longitude: 16.69985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Petrovice_BK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, modrý a bílý, v poměru 1:1:1:9. V žerďové polovině bílého pruhu černé křídlo s kosmým žlutým pruhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou se zkříženými zlatými gotickými klíči černé orlí křídlo se zlatým kosmým břevnem."
    },
    {
        obec: "Petrovice",
        kod: 594610,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.006837,
        longitude: 16.292563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Petrovice_ZN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1:4:1. Uprostřed bílý tlapatý kříž s dolním prodlouženým ramenem v podobě klíče zuby k vlajícímu okraji, ovinutým žlutým stonkem s hroznem k žerďovému a listem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný tlapatý kříž s dolním prodlouženým ramenem v podobě klíče zuby doleva, ovinutým zlatým stonkem vinné révy s hroznem vpravo a listem vlevo."
    },
    {
        obec: "Petrovice",
        kod: 591360,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.241283,
        longitude: 15.78769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Petrovice_TR_CoA.JPG",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém pruhu vykračující žlutý kohout s bílou zbrojí, v červeném bílá šachová figura zvaná roh. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře vykračující zlatý kohout se stříbrnou zbrojí, dole stříbrná šachová figura zvaná roh."
    },
    {
        obec: "Petrovice",
        kod: 570567,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50355,
        latitude: 50.289854,
        longitude: 15.598601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Petrovice_HK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed žlutá koule postrkaná třemi (2, 1) bílými pštrosími pery, nahoře knížecí čepice ozdobená křížkem. Dolní pero provázené dvěma přivrácenými žlutými půlměsíci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatá koule postrkaná třemi (2, 1) stříbrnými pštrosími pery, nahoře knížecí čepice ozdobená křížkem, dole dva přivrácené zlaté půlměsíce."
    },
    {
        obec: "Petrovice",
        kod: 551848,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79384,
        latitude: 50.239024,
        longitude: 17.448488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Petrovice_BR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:2:1. V červeném pruhu čtyři bílé vlající klíny s vrcholy na žerďovém okraji, v modrém pruhu žlutý pes ve skoku s červenou zbrojí a obojkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se čtyřmi stříbrnými kužely zlatý pes ve skoku s červenou zbrojí a obojkem, držící stříbrnou radlici."
    },
    {
        obec: "Petrovice",
        kod: 580767,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.988215,
        longitude: 16.541627,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/CoA_Petrovice_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a modrý v poměru 3:1. V horním pruhu červený středový kříž s rameny širokými jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V stříbrném štítě červený latinský heroldský kříž provlečený modrým kotoučem."
    },
    {
        obec: "Petrovice",
        kod: 541044,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26255,
        latitude: 49.554366,
        longitude: 14.337472
    },
    {
        obec: "Petrovice",
        kod: 542229,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.067363,
        longitude: 13.63928,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/CoA_of_Petrovice_%28okres_Rakovn%C3%ADk%29.svg",
        coatOfArmsFlagDescription: "List dělený bílou krokví vycházející z první a poslední dvanáctiny horního okraje listu s vrcholem v páté osmině šířky listu, na horní modré pole se žlutou lilií a dolní červené pole se zkříženými bílými meči se žlutými jílci k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženou obrácenou a zkrácenou stříbrnou krokví modro-červeně dělený štít. Nahoře zlatá lilie, dole dva vztyčené zkřížené stříbrné meče se zlatými jílci."
    },
    {
        obec: "Petrovice",
        kod: 568147,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40337,
        latitude: 50.789131,
        longitude: 13.976255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Petrovice_UL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený. V bílém pruhu čtyři malé zelené smrky, mezi nimi tři velké modré smrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře vyrůstají čtyři malé zelené smrky, mezi nimi tři velké modré smrky, dole stříbrný dvouocasý korunovaný lev se zlatou zbrojí."
    },
    {
        obec: "Petrovice I",
        kod: 534315,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.811063,
        longitude: 15.297295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Petrovice_I_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list, v žerďové polovině černá plamenná orlice se žlutou zbrojí a jetelovitě zakončeným perizoniem. V pravém pařátu drží dva šedé zkřížené odvrácené klíče zuby dolů, v levém šedá hornická kladívka na hnědých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá plamenná orlice se zlatou zbrojí a jetelovitě zakončeným perizoniem. V pravém pařátu drží dva postavené zkřížené odvrácené klíče, v levém hornická kladívka, vše přirozené barvy."
    },
    {
        obec: "Petrovice II",
        kod: 534323,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.81004,
        longitude: 15.063514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/PetroviceII-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, zelený, bílý a zelený, v poměru 1 : 1 : 5 : 1 : 1. V prostředním zeleném pruhu polovina bílé rozkřídlené husy se žlutým zobákem a červeným jazykem, s levým červeným křídlem se žlutým, jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě polovina stříbrné rozkřídlené husy se zlatým zobákem a červeným jazykem, s levým červeným křídlem se zlatým, jetelovitě zakončeným perizoniem, nad sníženou zlatou kvádrovanou hradbou. Nad husou zkřížené sklářské píšťaly, kosmá zlatá podložená šikmou stříbrnou."
    },
    {
        obec: "Petrovice u Karviné",
        kod: 599077,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73572,
        latitude: 49.895965,
        longitude: 18.547898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Petrovice_u_Karvin%C3%A9_CoA.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutými zkříženými klíči, mezi nimi čtyři bílé šesticípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté zkřížené klíče provázené čtyřmi stříbrnými hvězdami."
    },
    {
        obec: "Petrovice u Sušice",
        kod: 556921,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.218758,
        longitude: 13.440898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Petrovice_u_Susice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a červený. V zeleném pruhu kosmo gotický klíč zuby nahoru a k žerdi, šikmo podložený mečem hrotem dolů, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném návrší stříbrná ohrazená věž kostela s prázdným sdruženým románským oknem a s věží zakončenou zlatou makovicí s patriarším křížem , v návrší kosmo vztyčený meč křížem přeložený vztyčeným gotickým klíčem zuby nahoru, obojí zlaté."
    },
    {
        obec: "Petrovičky",
        kod: 548871,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.299084,
        longitude: 15.607452
    },
    {
        obec: "Petrůvka",
        kod: 556874,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.105005,
        longitude: 17.810083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/CZ_Petr%C5%AFvka_COA.svg",
        coatOfArmsFlagDescription: "Zelený list s černým žerďovým pruhem širokým sedminu délky listu. V zeleném poli žluté říšské jablko. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté obrněné rameno se stříbrnou rukavicí držící zlaté říšské jablko. Na rameni černé křídlo se stříbrným břevnem."
    },
    {
        obec: "Petříkov",
        kod: 535699,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.847072,
        longitude: 14.754708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Pet%C5%99%C3%ADkov-znak.png"
    },
    {
        obec: "Petrůvky",
        kod: 591378,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.159311,
        longitude: 15.902416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Petr%C5%AFvky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, v poměru 1 : 2. V červeném pruhu tlapatý kříž nad zvonem, obojí bílé. K červenému poli přiléhá bílé pravoúhlé trojúhelníkové pole vycházející z druhé třetiny dolního okraje listu s vrcholem na horním okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli tlapatý kříž nad zvonem, obojí stříbrné, levé pole modro-stříbrně kosmo dělené."
    },
    {
        obec: "Petříkov",
        kod: 538612,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25169,
        latitude: 49.925301,
        longitude: 14.617066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Pet%C5%99%C3%ADkov_%28Prague-East_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zvlněný bílý. V modrém pruhu kosmo gotický klíč zuby dolů a k vlajícímu okraji listu, šikmo přeložený berlou závitem k dolnímu cípu, oboje bílé. Z dolního okraje bílého pruhu vyrůstá černý pes s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně vlnitě dělený štít, nahoře kosmo postavený gotický klíč, šikmo přeložený berlou, obojí stříbrné, dole vyrůstající černý pes s červeným jazykem."
    },
    {
        obec: "Petřvald",
        kod: 599085,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73541,
        latitude: 49.831096,
        longitude: 18.389513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Petrvald_%28Karvina%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý zelený a černý, v poměru 3:1:1. Ze zeleného pruhu vyrůstá žlutá orlice s červenou zbrojí, přes zelený a černý pruh žlutá zkřížená hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním modrém poli vyrůstající zlatá orlice s červenou zbrojí, v dolním, zeleno-černě děleném poli zlatá zkřížená hornická kladívka."
    },
    {
        obec: "Petřvald",
        kod: 599743,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74260,
        latitude: 49.707197,
        longitude: 18.15153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Petrvald_%28Novy_Jicin%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed bílý páv se žlutou zbrojí a korunkou, pravým pařátem přidržující kosmo položenou žlutou berlu závitem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrný páv se zlatou zbrojí držící v pravém pařátu zlatou berlu."
    },
    {
        obec: "Pchery",
        kod: 532720,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27308,
        latitude: 50.193882,
        longitude: 14.117927,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Pchery_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1:2:1. Uprostřed červeného pruhu bílá, žlutě lemovaná dalmatika se šesti (3,2,1) černými kotouči na prsou, kterou prorůstají bílá lekna, listy z rukávů a kořeny dole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná, zlatě lemovaná dalmatika se šesti (3,2,1) černými kotouči, kterou prorůstají stříbrná lekna, listy z rukávů a kořeny dole."
    },
    {
        obec: "Pičín",
        kod: 541052,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26225,
        latitude: 49.745546,
        longitude: 14.057909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Coats_of_arms_Pi%C4%8D%C3%ADn.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 1:6:1. V modrém pruhu bílá věž s cimbuřím, černým oknem a gotickou branou, červenou stanovou střechou zakončenou bílým maltézským křížem. Věž ze stran přidržují žlutí diví muži se zelenými věnci, držící na žlutých žerdích přivrácené červeno-bílé dvoucípé praporce převýšené dvěma žlutými osmicípými hvězdami. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrnou patou s červeným kaprem stříbrná věž s cimbuřím, černým oknem a gotickou branou, červenou stanovou střechou zakončenou stříbrným maltézským křížem. Věž ze stran přidržují zlatí diví muži se zelenými věnci, držící na zlatých žerdích přivrácené červeno-stříbrné dvoucípé praporce převýšené dvěma zlatými, osmihrotými hvězdami."
    },
    {
        obec: "Pikárec",
        kod: 549916,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59253,
        latitude: 49.432505,
        longitude: 16.122491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Pik%C3%A1rec_znak.jpg",
        coatOfArmsFlagDescription: "Modro-bíle kosmo dělený list. Z dolního rohu a horního cípu vychází po jedné třetině šikmého pruhu opačných barev, širokého jednu třetinu šířky listu. Zakončení pruhů je rovnoběžné s kosmým dělením listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze stříbrné vlnité paty stříbrný tis se zlatým kmenem mezi dvěma zlatými pryskyřníky na stříbrných stoncích nad tisem letící zlatá kachna."
    },
    {
        obec: "Pila",
        kod: 556947,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.179793,
        longitude: 12.925973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Pila_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, pilovitý zelený, bílý, červený a černý, v poměru 11 : 5 : 4 : 4. Zuby zeleného pruhu dosahují do dvou pětin šířky bílého pruhu. V horní části zeleného pruhu bílé uťaté lekno obrácené k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - stříbrně pilovitě polceném štítě černé návrší se stříbrnými hornickými kladívky na zlatých topůrkách, z něhož vyrůstají dvě přivrácená lekna, pravé stříbrné, levé červené."
    },
    {
        obec: "Pilníkov",
        kod: 579599,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54242,
        latitude: 50.532571,
        longitude: 15.820303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Piln%C3%ADkov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část a bílá vlající část v poměru 2:3. K červené části přiléhá polovina černé k žerdi hledící orlice. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Písečná",
        kod: 557226,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73991,
        latitude: 49.57477,
        longitude: 18.787469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Pioseczna_herb.png",
        coatOfArmsFlagDescription: "Žlutý list s modrým kantonem se žlutou polovinou slunce bez obličeje se střídavě rovnými a plamennými paprsky. Ve vlající polovině zelený jehličnatý strom s černým kmenem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou hlavou se zlatou volnou horní polovinou slunce tři zelené jehličnaté stromy s černými kmeny."
    },
    {
        obec: "Písařov",
        kod: 540650,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78991,
        latitude: 50.005065,
        longitude: 16.801608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/CoA_of_P%C3%ADsa%C5%99ov.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu, s bílým šikmo položeným brkem a dva vodorovné pruhy: bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně šikmo dělený štít, nahoře vztyčená modrá radlice, vlevo kalamář se zatknutým brkem, obojí stříbrné."
    },
    {
        obec: "Písečná",
        kod: 540684,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79082,
        latitude: 50.273123,
        longitude: 17.253835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Znak_obce_P%C3%ADse%C4%8Dn%C3%A1_u_Jesen%C3%ADku.png",
        coatOfArmsFlagDescription: "Žlutý list s modrým zvlněným pruhem na vlajícím okraji listu, širokým jednu čtvrtinu délky listu o třech vrcholech a dvou prohlubních. Ve žlutém poli černý kráčející medvěd pod třemi, do oblouku položenými červenými pětilistými květy s bílými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě s modrou vlnitou patou černý kráčející medvěd provázený nahoře třemi, do oblouku položenými červenými pětilistými květy se stříbrnými semeníky."
    },
    {
        obec: "Písečná",
        kod: 580775,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56170,
        latitude: 50.045242,
        longitude: 16.448156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/P%C3%ADse%C4%8Dn%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a zelené pole se žlutým čelně hledícím srncem s bílou zbrojí. Ve žlutém pruhu černá lovecká trubka s bílým kováním a černo-bílou kroucenou šňůrou, obrácená k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatou patou s černou loveckou trubkou se stříbrným kováním a černo-stříbrnou kroucenou šňůrou čelně hledící zlatý srnec se stříbrnou zbrojí."
    },
    {
        obec: "Písečné",
        kod: 546917,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37872,
        latitude: 48.965043,
        longitude: 15.462488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/P%C3%ADse%C4%8Dn%C3%A9_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří černo-bíle čtvrcené karé s knížecí korunou a dva vodorovné pruhy, modrý a žlutý, v poměru 3:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním černo-stříbrně čtvrceném poli knížecí koruna, v dolním modrém poli zlatá růže."
    },
    {
        obec: "Písečné",
        kod: 596434,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.568007,
        longitude: 16.23676,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/P%C3%ADse%C4%8Dn%C3%A9_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým plamenným mečem hrotem nahoru mezi dvěma bílými svěšenými lipovými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vztyčený zlatý plamenný meč provázený po stranách dvěma stříbrnými svěšenými lipovými listy."
    },
    {
        obec: "Písek",
        kod: 570575,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.15553,
        longitude: 15.501834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/CoA_of_P%C3%ADsek%28okres_Hradec_Kr%C3%A1lov%C3%A9%29.svg",
        coatOfArmsDescription: "Modro-zlatě čtyřikrát kosmo lomeně dělený štít."
    },
    {
        obec: "Písek",
        kod: 549240,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.303554,
        longitude: 14.158129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/P%C3%ADsek_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Písek",
        kod: 512028,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73984,
        latitude: 49.55934,
        longitude: 18.80242,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Piosek_herb.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část s polovinou slezské orlice přiléhající k modré vlající části se žlutou lopatou držadlem nahoru nad bílým zvlněným pruhem s jedním vrcholem a dvěma prohlubněmi širokým jednu pětinu šířky listu na dolním okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít. Vpravo vyniká půl slezské orlice, vlevo zlatá postavená lopata nad stříbrnou vlnitou patou."
    },
    {
        obec: "Písková Lhota",
        kod: 536440,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29431,
        latitude: 50.367841,
        longitude: 14.874736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/P%C3%ADskov%C3%A1_Lhota_%28Mlad%C3%A1_Boleslav_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý, v poměru 1 : 3. Bílý pruh má osm čtvercových zubů a sedm mezer. Z dolního okraje bílého pruhu vyniká modré půlkruhové pole o poloměru sedm patnáctin šířky listu a v něm polovina žlutého vozového kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kvádrovaný mostní oblouk na způsob hradby s pěti stínkami a dvěma prázdnými gotickými okny. Dole vyniká zlaté vozové kolo."
    },
    {
        obec: "Písková Lhota",
        kod: 537659,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.131421,
        longitude: 15.065865
    },
    {
        obec: "Pístina",
        kod: 561070,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.049781,
        longitude: 14.901411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/P%C3%ADstina_znak.jpg",
        coatOfArmsFlagDescription: "Ve žlutém listu modrá růže se žlutým semeníkem a zelenými kališními lístky nad dolní polovinou zlomeného modrého kola sv. Kateřiny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad dolní polovinou zlomeného modrého kola sv. Kateřiny modrá růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Píšť",
        kod: 548545,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.601839,
        longitude: 15.203524
    },
    {
        obec: "Písty",
        kod: 537667,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28913,
        latitude: 50.164646,
        longitude: 15.001364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/P%C3%ADsty_CoA.png",
        coatOfArmsFlagDescription: "Žluto-zeleně šikmo dělený list. V žerďovém poli vztyčený zelený lipový list, ve vlajícím žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně šikmo děleném štítě nahoře vztyčený zelený lipový list, dole zlatý zvon."
    },
    {
        obec: "Píšť",
        kod: 509647,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74718,
        latitude: 49.978666,
        longitude: 18.193601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Coa_Czech_Town_P%C3%AD%C5%A1%C5%A5.svg",
        coatOfArmsFlagDescription: "Na červený list položený bílý dvojitý kříž tak, že v žerďové části vznikne šest červených čtverců. Ve středním žerďovém čtverci, ve třech středových čtvercích a ve vlajícím červeném obdélníku celkem pět do kosočtverce položených žlutých lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červený štít se dvěma zúženými kůly a dvěma zúženými břevny, vše stříbrné. Ve vzniklých polích do kříže 5 zlatých lilií."
    },
    {
        obec: "Píšťany",
        kod: 542539,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41101,
        latitude: 50.519327,
        longitude: 14.069206,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/P%C3%AD%C5%A1%C5%A5any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, žlutý, vlnitý modrý a zvlněný žlutý. V žerďové polovině červeného pruhu polovina českého lva držícího v tlapách svisle žlutou kypřici. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě děleném štítě nahoře polovina českého lva držícího v tlapách postavenou zlatou kypřici, dole modré vlnité břevno."
    },
    {
        obec: "Pištín",
        kod: 535991,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37346,
        latitude: 49.045975,
        longitude: 14.335259,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Pistin_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list. V červeném poli čtyři mřežovitě propletené bílé obilné klasy, krajní s odkloněným listem. V modrém poli bílý čáp s černými křídly a červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě nahoře čtyři proložené stříbrné obilné klasy, krajní s odkloněným listem, dole čáp přirozené barvy."
    },
    {
        obec: "Pitín",
        kod: 592498,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68771,
        latitude: 49.038403,
        longitude: 17.850785,
        coatOfArmsFlagDescription: "Červeno-modře kosmo a šikmo čtvrcený list. Uprostřed kosmo žlutá berla závitem k hornímu okraji, šikmo podložená bílým mečem se žlutým jílcem hrotem dolů. Pod nimi žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně kosmo čtvrceném štítě kosmá zlatá berla závitem doleva šikmo podložená postaveným stříbrným mečem se zlatým jílcem, pod nimi zlatá osmihrotá hvězda."
    },
    {
        obec: "Pivín",
        kod: 589888,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79824,
        latitude: 49.384785,
        longitude: 17.184933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Pivin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy černý, červený a zelený v poměru 3:2:3 uprostřed zkřížená vztyčená bílá krojidla přesahující do žerďové a vlající části, mezi nimi žlutá otka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-červeno-zeleně polceném štítě zkřížená vztyčená stříbrná krojidla, mezi nimi zlatá otka."
    },
    {
        obec: "Pivkovice",
        kod: 598887,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.177594,
        longitude: 14.069728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Pivkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a zelený, v poměru 9 : 1 : 6. Do červeného pruhu vyrůstá korunovaný bílý dvouocasý lev se žlutou zbrojí a jazykem, držící žlutou lipovou větev, nahoře se dvěma listy s plodenstvím. V zeleném pruhu postavený pivní sud provázený nahoře dvěma svěšenými chmelovými šišticemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně sníženým zlatým zúženým obloukovým břevnem dělený štít. Nahoře vyrůstající korunovaný stříbrný dvouocasý lev se zlatou zbrojí a jazykem držící zlatou lipovou větev, nahoře se dvěma listy s plodenstvím. Dole postavený pivní sud provázený dvěma svěšenými chmelovými šišticemi, vše zlaté."
    },
    {
        obec: "Planá",
        kod: 535176,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.943775,
        longitude: 14.452868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Plana_cb_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černým pluhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře černý pluh, dole stříbrné břevno, uprostřed červená růže se zlatým semeníkem a zelenými okvětními lístky."
    },
    {
        obec: "Planá",
        kod: 561134,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.868267,
        longitude: 12.743897,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 1 : 2 : 1. V bílém pruhu červený rovnoramenný trojúhelník dole s bílým mezikružím a s vrcholem provázeným dvěma červenými mezikružími. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Planá nad Lužnicí",
        kod: 552828,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39111,
        latitude: 49.354541,
        longitude: 14.701577,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Coa_Plana-logo.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, vycházejícím ze třetí šestiny žerďového a vlajícího okraje. Nad první prohlubní slunce bez tváře, pod prohlubní ležící dubový list, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnitě dolů jednou prohnuté břevno, provázené nahoře sluncem bez tváře a dole položeným dubovým listem, obojí zlaté."
    },
    {
        obec: "Plaňany",
        kod: 533581,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28104,
        latitude: 50.050204,
        longitude: 15.029546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Pla%C5%88any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. Do bílého pruhu vyrůstají ve střední části dva zelené topoly s šedými kmeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstají ze zeleného trávníku dva topoly bílé, přirozené barvy."
    },
    {
        obec: "Plandry",
        kod: 587702,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58841,
        latitude: 49.42009,
        longitude: 15.53837,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Plandry_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a červený, v poměru 2:3:3. V bílém pruhu tři vztyčené zelené lipové listy pod sebou, v modrém a červeném pruhu vykračující bílý čáp se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě pod stříbrnou hlavou se třemi vztyčenými zelenými lipovými listy vykračující stříbrný čáp se zlatou zbrojí, provázený vpravo dvěma hvězdami pod sebou, vlevo gotickým dvojklíčem, vše zlaté."
    },
    {
        obec: "Pláně",
        kod: 530336,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33101,
        latitude: 49.9338,
        longitude: 13.389711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Pl%C3%A1n%C4%9B_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, přeložené bílým vodorovným pruhem vycházejícím ze šesté a sedmé osminy žerďového a vlajícího okraje. Uprostřed bílého pruhu červený maltézský kříž. Nad ním dvě přivrácené bílé labutě se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě dvě přivrácené stříbrné labutě se zlatou zbrojí, pod nimi položená stříbrná radlice s červeným maltézským křížem."
    },
    {
        obec: "Plánice",
        kod: 556955,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34034,
        latitude: 49.390036,
        longitude: 13.471166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Planice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 : 1. Do červeného pruhu vyniká bílá kvádrovaná, dole rozšířená patrová věž, s prázdnou branou s vytaženou černou mříží, dvěma černými okny v patře, černou valbovou střechou zakončenou dvěma žlutými makovicemi. Mezi nimi bílá labuť se žlutou zbrojí na bílém hnízdě. Věž dole provázena k žerďovému okraji lilií a k vlajícímu okraji růží, obojí bílé. Z brány bílá rozšiřující se cesta k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku se stříbrnou cestou stříbrná kvádrovaná, dole rozšířená patrová věž, s prázdnou branou s vytaženou černou mříží, dvěma černými okny v patře, černou valbovou střechou zakončenou dvěma zlatými makovicemi. Mezi nimi stříbrná labuť se zlatou zbrojí na stříbrném hnízdě. Věž dole provázena vpravo lilií a vlevo růží, obojí stříbrné."
    },
    {
        obec: "Plasy",
        kod: 559351,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33101,
        latitude: 49.934598,
        longitude: 13.390465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/CoA_of_Plasy.svg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole modré se žlutou šesticípou hvězdou, dolní žerďové pole bílé s červenou růží se žlutým semeníkem a zelenými kališními lístky, horní vlající pole s kosmo vztyčeným zeleným lipovým listem a dolní vlající pole červené s bílou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Čtvrcený štít, v 1. modrém poli zlatá hvězda, ve 2. stříbrném poli kosmo vztyčený zelený lipový list, ve 3. stříbrném poli červená růže se zlatým semeníkem a zelenými kališními lístky, ve 4. červeném poli stříbrná lilie."
    },
    {
        obec: "Plav",
        kod: 535346,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.901535,
        longitude: 14.487974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Plav_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu v žerďové polovině čtyři propletené zelené obilné klasy, z nichž krajní mají po jednom listu a ve vlající polovině hnědé vozové kolo s osmi loukotěmi. V modrém pruhu polovina muže v bílé zbroji, držící v pravici nad hlavou vodorovně bílý meč hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře čtyři propletené zelené obilné klasy, krajní s jedním listem a vozové kolo přirozené barvy, dole polovina muže ve stříbrné zbroji se stříbrným mečem nad hlavou."
    },
    {
        obec: "Plaveč",
        kod: 594628,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67132,
        latitude: 48.928863,
        longitude: 16.080106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Plave%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list. Uprostřed čelně žlutá býčí hlava s krkem podložená postaveným bílým mečem se žlutým jílcem, vynikajícím ze žluté loďky a provázeným nahoře dvěma bílými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě čelně zlatá býčí hlava s krkem, podložená postaveným stříbrným mečem se zlatým jílcem, vynikajícím ze zlaté loďky a provázeným nahoře dvěma stříbrnými liliemi."
    },
    {
        obec: "Plavsko",
        kod: 509078,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.084695,
        longitude: 14.904124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/CoA_of_Plavsko.svg",
        coatOfArmsFlagDescription: "List dělený šikmým modrým pruhem širokým jednu třetinu délky listu na žluté žerďové a bílé vlající pole. V žerďovém poli modrá růže s červeným semeníkem, v modrém pruhu žlutá růže s červeným semeníkem, ve vlajícím poli červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dvakrát šikmo vlnitě zlato-modro-stříbrně dělený štít se třemi kosmo položenými růžemi, horní modrá s červeným semeníkem, prostřední zlatá s červeným semeníkem, dolní červená se zlatým semeníkem."
    },
    {
        obec: "Plavy",
        kod: 563757,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46846,
        latitude: 50.703338,
        longitude: 15.317471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Plavy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, zelený, vlnitý bílý, vlnitý modrý, vlnitý bílý, vlnitý modrý, vlnitý bílý a zvlněný zelený, v poměru 11:1:1:1:1:1:4. Uprostřed horního zeleného pruhu žluté mlýnské kolo. Vlnité pruhy mají dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté mlýnské kolo, pod ním stříbrné vlnité břevno se dvěma modrými vlnitými břevny."
    },
    {
        obec: "Plesná",
        kod: 554740,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35135,
        latitude: 50.220815,
        longitude: 12.34681,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Znak_Plesn%C3%A1.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým žlutým polem a dva vodorovné pruhy, žlutý a červený. V žerďové a střední části bílé ozubené kolo se včelou uprostřed, podložené kosmo černou kotvou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě čtvrceném štítě stříbrné ozubené kolo se včelou uprostřed, podložené kosmou kotvou, obojí přirozené barvy. Ve třetím poli černý letopočet 1900."
    },
    {
        obec: "Plenkovice",
        kod: 550051,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67151,
        latitude: 48.919012,
        longitude: 16.002555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Plenkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se zvlněným bílým žerďovým pruhem širokým čtvrtinu délky listu se dvěma vrcholy a třemi prohlubněmi. V modrém poli bílé pravé obrněné rameno držící holou rukou šikmo tři žluté obilné klasy, krajní odkloněné, provázené v horním cípu bílým vinným hroznem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vmodrém štítě pod stříbrnou vlnitou hlavou stříbrné pravé obrněné rameno s rukou přirozené barvy držící šikmo tři zlaté obilné klasy, krajní odkloněné, provázené vlevo nahoře kosmým stříbrným vinným hroznem."
    },
    {
        obec: "Plazy",
        kod: 536458,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.414136,
        longitude: 14.975134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Plazy_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným zubatým lemem. Uprostřed dva vztyčení přivrácení zelení, černě okřídlení a černě korunovaní hadi s červenými jazyky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným cimbuřovým lemem dva vztyčení přivrácení zelení, černě okřídlení a černě korunovaní hadi s červenými jazyky."
    },
    {
        obec: "Pleše",
        kod: 507628,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.195629,
        longitude: 14.821465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Ple%C5%A1e_znak.jpg"
    },
    {
        obec: "Plešnice",
        kod: 559369,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.774084,
        longitude: 13.17685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Ple%C5%A1nice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, zubatý žlutý a modrý, v poměru 1 : 1 : 2. Ve žlutém pruhu s pěti zuby a šesti stejnými mezerami tři modré lilie pod sebou, ve vlajícím modrém pruhu bílá konvice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole modro-zlatě cimbuřovitě polcené, vlevo tři modré lilie pod sebou. V levém modrém poli stříbrná konvice."
    },
    {
        obec: "Plch",
        kod: 572942,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.12887,
        longitude: 15.70284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Plch_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed vyrůstají ze žluté koruny tři bílé orobince se žlutými palicemi, krajní odkloněné, prostřední se dvěma, krajní pouze s vnějšími listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zlaté koruny tři stříbrné orobince se zlatými palicemi, krajní odkloněné, prostřední se dvěma, krajní pouze s vnějšími listy."
    },
    {
        obec: "Pletený Újezd",
        kod: 532738,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.109433,
        longitude: 14.116178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Pleten%C3%BD_%C3%9Ajezd_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh, široký polovinu délky listu a dva vodorovné pruhy, bílý a červený. V žerďovém pruhu červeno-bíle polcená orlice s červeným jazykem, žlutou zbrojí, perizoniem a korunkou na krku. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně dělený štít. Nahoře červeno-stříbrně polcená orlice se zlatou zbrojí, perizoniem a korunou na krku. Uprostřed dolního pole stříbrný plot."
    },
    {
        obec: "Plchov",
        kod: 564125,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27375,
        latitude: 50.255276,
        longitude: 13.986724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Plchov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří karé a dva vodorovné pruhy, žlutý a červený. Karé tvoří tři svislé pruhy, černý, žlutý a černý, v poměru 1:2:1. Ve žlutém pruhu osmihrotý kříž nad šesticípou hvězdou, oboje červené. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě s černými boky osmihrotý kříž nad hvězdou, obojí červené."
    },
    {
        obec: "Plchovice",
        kod: 580783,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.045181,
        longitude: 16.181298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Plchovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě zelených a bílých, v poměru 9 : 1 : 1 : 1 : 4. U žerďového okraje horního zeleného pruhu žlutý plch na kosmém bílém trámu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý plch na kosmém stříbrném trámu nad dvěma stříbrnými vlnitými břevny."
    },
    {
        obec: "Plískov",
        kod: 566861,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.842744,
        longitude: 13.739921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Pl%C3%ADskov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 2 : 1. Uprostřed zelený dub s osmi (1, 2, 3, 2) svěšenými žlutými žaludy. Přes kmen žlutý pruh vycházející z třinácté patnáctiny žerďového a vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně sníženě děleném štítě zelený dub s osmi (1, 2, 3, 2) svěšenými zlatými žaludy a s kmenem dole přeloženým zlatou korunou."
    },
    {
        obec: "Ploskovice",
        kod: 565393,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41142,
        latitude: 50.56034,
        longitude: 14.198588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Ploskovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Žlutým a černým kosmým pruhem dělený list s červenou žerďovou a bílou vlající částí. Žlutý pruh vychází z první pětiny žerďového okraje do páté pětiny dolního okraje, černý pruh vychází z první pětiny horního okraje do páté pětiny vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě černo-zlatě dělený gryf se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Pluhův Žďár",
        kod: 546968,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.223995,
        longitude: 14.892803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Pluhuv_Zdar-znak.gif",
        coatOfArmsFlagDescription: "Červený list se dvěma zelenými trojúhelníky, žerďovým s vrcholy ve třetí osmině žerďového a páté dvanáctině dolního okraje listu a vlajícím s vrcholy v sedmé dvanáctině horního okraje listu a páté osmině vlajícího okraje. V červeném poli kosmo čelně postavená bílá radlice hrotem nahoru, v ní černá ostrev se šesti suky, mezi suky vyrůstají do stran čtyři (2,2) stonky, každý se dvěma zelenými jetelovými trojlisty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zelenou plamennou hlavou kosmá, čelně vztyčená stříbrná radlice, v ní černá ostrev se šesti suky. Mezi suky vyrůstají do stran čtyři (2,2) stonky, každý se dvěma jetelovými trojlisty, vše zelené."
    },
    {
        obec: "Plumlov",
        kod: 589896,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.466236,
        longitude: 17.015122,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Plumlov_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílou zavinutou střelou a žlutý s černou zubří hlavou s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Plužná",
        kod: 565580,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29423,
        latitude: 50.473807,
        longitude: 14.804269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Plu%C5%BEn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "Kosmým hermelínovým pruhem dělený list na červené žerďové a modré vlající pole. Kosmý pruh vychází z první třetiny žerďového okraje do třetí třetiny dolního okraje listu. V modrém poli kráčející žlutý korunovaný dvouocasý lev s červenou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně kosmo děleném štítě nahoře kráčející zlatý korunovaný dvouocasý lev s červenou zbrojí a jazykem, dole kosmá, hermelínem potažená radlice."
    },
    {
        obec: "Pnětluky",
        kod: 566578,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.250358,
        longitude: 13.703895,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Pn%C4%9Btluky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým modrým polem a dva vodorovné pruhy, modro - žlutě šachovaný (4 x 8) a bílý. Do červeného karé vyrůstá bílý korunovaný dvouocasý lev se žlutou zbrojí. Na styku modrého a bílého pole vyrůstající bílo - červeně polcená orlice se žlutou zbrojí, korunou navlečenou na krku a jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře polcený, V pravém červeném poli vyrůstající stříbrný korunovaný dvouocasý lev se zlatou zbrojí, levé pole modro - zlatě šachované, v dolním modro-stříbrně polceném poli vyrůstající stříbrno - červeně polcená orlice se zlatou zbrojí, korunou navlečenou na krku a jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Pňovany",
        kod: 559377,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.778992,
        longitude: 13.121864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/P%C5%88ovany_CoA.png",
        coatOfArmsFlagDescription: "Červený list; uprostřed bílá kvádrovaná hradební zeď s cimbuřím, prázdnou gotickou bránou se žlutými vraty. Ze zdi vyrůstají dvě bílé kvádrované věže s cimbuřím, každá s černým obloukovým oknem a modrou stanovou střechou se žlutou makovicí. Mezi věžemi půl bílého leknového trojlistu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Pňovice",
        kod: 552160,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78401,
        latitude: 49.721386,
        longitude: 17.161923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Znak_obce_P%C5%88ovice.svg",
        coatOfArmsFlagDescription: "List tvoří červené žerďové pole široké jednu polovinu délky listu se žlutou radlicí hrotem nahoru a ostřím k žerdi, a bílé vlající pole s pěti šikmými střídavě černými a bílými pruhy širokými jednu desetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo zlatá vztyčená radlice hrotem nahoru, vlevo tři černá šikmá břevna."
    },
    {
        obec: "Pňov-Předhradí",
        kod: 537675,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28941,
        latitude: 50.086521,
        longitude: 15.147597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/P%C5%88ov-P%C5%99edhrad%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý zubatý se třemi zuby a dvěma stejnými mezerami a modrý zvlněný se třemi vrcholy a dvěma prohlubněmi, v poměru 14 : 3 : 3. V horním modrém pruhu pod bílou podkovou mezi dvěma čtyřlístky ostrev se dvěma suky k žerďovému a jedním k vlajícímu okraji listu, z každého vyrůstá do stran dubový list, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno-modře vlnitě dělenou cimbuřovou patou vztyčená ostrev se třemi (2, 1) suky, z každého vyrůstá do stran dubový list, vše zlaté. Nahoře stříbrná podkova mezi dvěma zlatými čtyřlístky."
    },
    {
        obec: "Plzeň",
        kod: 554791,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 30100,
        latitude: 49.738531,
        longitude: 13.373737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Plzen_small_CoA.png",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové pole bílé, dolní žerďové pole zelené, horní vlající pole žluté a dolní vlající pole červené. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Poběžovice",
        kod: 554111,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.510406,
        longitude: 12.802721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Pob%C4%9B%C5%BEovice_CoA.png",
        coatOfArmsFlagDescription: "Čtvrcený list; horní žerďové a dolní vlající pole bílá, horní vlající pole červené a dolní žerďové modré. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Poběžovice u Holic",
        kod: 575461,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.097655,
        longitude: 16.000768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Pob%C4%9B%C5%BEovice_u_Holic_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, zelený smrkový se třemi smrky, bílý, zelený a bílý, v poměru 4 : 1 : 1 : 1 : 1. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou oddělenou smrkovým řezem se třemi zelenými smrky jelení hlava s korunou mezi parožím, vše stříbrné."
    },
    {
        obec: "Poběžovice u Přelouče",
        kod: 575470,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.991102,
        longitude: 15.581991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Pobezovice-u-prelouce-znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s žerďovým bílým klínem s vrcholem na vlajícím okraji. V klínu letící černý havran se žlutou zbrojí nesoucí v zobáku kosmo skloněnou, červeným plamenem hořící hnědou pochodeň. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít se stříbrným hrotem provázeným po stranách železným kloboukem se zlatou šňůrou. V hrotu letící černý havran se zlatou zbrojí držící v zobáku kosmo skloněnou, červeným plamenem hořící pochodeň přirozené barvy."
    },
    {
        obec: "Počaply",
        kod: 564214,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.538772,
        longitude: 13.983561,
        coatOfArmsFlagDescription: "List tvoří žlutý palisádový pruh, široký pětinu délky listu, a modré pole s dolní polovinou zlomeného bílého palečného kola, z něhož vyniká žlutá hlavice berly. V hlavici žlutý ohlížející se beránek s bílým procesním křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou palisádovou hlavou dolní polovina zlomeného stříbrného palečného kola, z něhož vyniká zlatá hlavice berly. V hlavici zlatý ohlížející se beránek se stříbrným procesním křížem."
    },
    {
        obec: "Pocinovice",
        kod: 554120,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34509,
        latitude: 49.343523,
        longitude: 13.133597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Pocinovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 10 : 1 : 10. V modrém pruhu žlutá lilie, v červeném zkřížené bílé čakany se žlutými, bíle zdobenými a zahrocenými topůrky, provázené třemi (2, 1) vztyčenými žlutými granátovými jablky se stopkou a dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě nad stříbrným vlnitým břevnem vpravo zlatá lilie, vlevo zkřížené stříbrné čakany se zlatými, stříbrně zdobenými a zahrocenými topůrky, provázené třemi (2, 1) vztyčenými zlatými granátovými jablky se stopkou a dvěma listy, v patě štítu zlaté návrší."
    },
    {
        obec: "Počátky",
        kod: 548561,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39464,
        latitude: 49.260301,
        longitude: 15.240324,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Pocatky_znak.gif",
        coatOfArmsFlagDescription: "List praporu je modrý se žlutým vodorovným pruhem širokým jednu dvacetinu šířky listu. V horním modrém poli je ve dvou pětinách délky listu od žerdě osmicípá žlutá hvězda a ve dvou pětinách od vlajícího okraje písmena 'AW'. V dolním modrém poli je stejnolehle umístěna žlutá pětilistá růže a písmena 'AL'. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Počedělice",
        kod: 566586,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.371926,
        longitude: 13.887909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Po%C4%8Ded%C4%9Blice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, vlnitý modrý, vlnitý bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 5 : 1 : 1 : 1. Nad první prohlubní polovina černého medvěda s červenou zbrojí a jazykem, držícího v levé tlapě tři zelené obilné klasy, krajní s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou se stříbrným vlnitým břevnem polovina černého medvěda s červenou zbrojí a jazykem, držícího v levé tlapě tři zelené obilné klasy, krajní s listem."
    },
    {
        obec: "Počenice-Tetětice",
        kod: 588865,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.271262,
        longitude: 17.228235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Po%C4%8Denice-Tet%C4%9Btice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modro-bíle dělený, červený se žlutým obilným klasem se dvěma listy a bílo-modře dělený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole modro-stříbrně dělené, nahoře červená, stříbrně lemovaná a zlatým knoflíkem zakončená pohanská čepice, dole modrá radlice. V levém červeném poli zlatý obilný klas se dvěma listy a vztyčený stříbrný nůž ostřím doleva se zlatou rukojetí."
    },
    {
        obec: "Počepice",
        kod: 541087,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26255,
        latitude: 49.596115,
        longitude: 14.380945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Po%C4%8Depice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 7:1. Na horním okraji zeleného pruhu stojí bílý čáp s černým křídlem a žlutou zbrojí obrácený k vlajícímu okraji. Na krku má na modré stuze zavěšenou bílou obálku uzavřenou červenou pečetí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku doleva obrácený stříbrný čáp s černým křídlem a zlatou zbrojí. Na krku má na modré stuze zavěšenou stříbrnou dopisní obálku uzavřenou červenou pečetí."
    },
    {
        obec: "Počítky",
        kod: 596442,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.588779,
        longitude: 15.971975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Pocitky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým beranem ve skoku se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ve skoku stříbrný beran se zlatou zbrojí."
    },
    {
        obec: "Podbořanský Rohozec",
        kod: 566608,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44101,
        latitude: 50.216536,
        longitude: 13.262884,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Podbo%C5%99ansk%C3%BD_Rohozec_coa.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a zelený, v poměru 7 : 1. V červeném pruhu žlutý klín vycházející z poslední osminy horního okraje pruhu a z první osminy vlajícího okraje pruhu s vrcholem sahajícím do tří čtvrtin žerďového okraje listu. Klín je přeložený bílým srpem se žlutou rukojetí, hrotem a ostřím k žerďovému okraji listu. Ze zeleného pruhu vyrůstá v polovině délky listu zelený trs sítiny s orobincem na rákosu se dvěma listy a hnědou palicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít. Vpravo šikmý zlatý zúžený klín přeložený stříbrným srpem se zlatou rukojetí, vlevo na zeleném trávníku vyrůstá z trsu sítiny orobinec se dvěma listy, vše přirozené barvy."
    },
    {
        obec: "Podbořany",
        kod: 566616,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44101,
        latitude: 50.229475,
        longitude: 13.41203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Coat_of_arms_of_Podbo%C5%99any.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - modrý, bílý, žlutý, bílý a modrý v poměru 1:1:2:1:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Podbrdy",
        kod: 534285,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.863079,
        longitude: 14.126698
    },
    {
        obec: "Poděbrady",
        kod: 537683,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.142525,
        longitude: 15.118988,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Znak_m%C4%9Bsta_Pod%C4%9Bbrady.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Podbřežice",
        kod: 550795,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.212563,
        longitude: 16.928192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Podb%C5%99e%C5%BEice_CoA.jpeg",
        coatOfArmsFlagDescription: "Modrý list s kosmým žlutým pruhem podloženým šikmým bílým pruhem, oba široké jednu desetinu šířky listu. V žerďovém a vlajícím poli půlměsíc s cípy k okraji, v horním a dolním poli šesticípá hvězda, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě postavený stříbrný meč se zlatým jílcem, kosmo přeložený zlatým a šikmo podložený stříbrným postaveným gotickým klíčem zuby nahoru. V horních rozích štítu dva odvrácené půlměsíce s hvězdou uprostřed, obojí zlaté."
    },
    {
        obec: "Podbřezí",
        kod: 576654,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51803,
        latitude: 50.259989,
        longitude: 16.214851,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Podb%C5%99ez%C3%AD_RK_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, uprostřed vztyčený zelený březový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vykořeněná bříza se šesti zelenými listy a dvěma zlatými jehnědami, provázená vpravo černou položenou routou a vlevo stříbrnou rybou."
    },
    {
        obec: "Poděšín",
        kod: 596451,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.507768,
        longitude: 15.800993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Pod%C4%9B%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed bílá lilie nad žlutým vykořeněným listnatým stromem, v žerďové a vlající části po jednom bílém meči hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě pod sebou stříbrná lilie a zlatý vykořeněný listnatý strom, provázené dvěma postavenými stříbrnými meči."
    },
    {
        obec: "Poděvousy",
        kod: 566365,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.527617,
        longitude: 13.131214
    },
    {
        obec: "Podhorní Újezd a Vojice",
        kod: 573311,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50754,
        latitude: 50.390156,
        longitude: 15.514528
    },
    {
        obec: "Podhořany u Ronova",
        kod: 572039,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53841,
        latitude: 49.936237,
        longitude: 15.536819,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Coat_of_arms_of_Podho%C5%99any_u_Ronova.svg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů střídavě modrých a žlutých, v poměru 1 : 1 : 1 : 1 : 1 : 1 : 6. Ve vlajícím pruhu turnajský helm s červeným tatarským kloboukem s ohrnutým, uprostřed prostřiženým, bílým okrajem, zakončeným žlutým knoflíkem postrkaným šesti (3, 3) černými kohoutími pery. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, pravé pole pětkrát modro-zlatě polcené, v levém modrém poli turnajský helm s červeným tatarským kloboukem s ohrnutým, uprostřed prostřiženým, stříbrným okrajem, zakončeným zlatým knoflíkem postrkaným šesti (3, 3) černými kohoutími pery."
    },
    {
        obec: "Podhradí",
        kod: 538817,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35201,
        latitude: 50.255461,
        longitude: 12.201857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Podhrad%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílo-červeně dělený, modrý a červeno-bíle dělený, v poměru 1 : 4 : 1. V modrém pruhu na šedé skále bílá věž s černým gotickým oknem a cimbuřím, z jehož prostředního zubu vynikají dvě odkloněné korouhve s ocasem na žlutých žerdích. Korouhev k žerďovému okraji listu tvoří dva svislé pruhy, červený a bílý s vydutým klínem opačných barev vycházejícím z dolního okraje listu s vrcholem uprostřed horního okraje listu korouhve. K vlajícímu okraji listu obrácenou korouhev tvoří tři vodorovné pruhy, bílý, červený a černý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na skále přirozené barvy stříbrná věž s černým gotickým oknem a cimbuřím, z jehož prostřední stínky vynikají dvě odkloněné korouhve s ocasem na zlatých žerdích. Pravá stříbrno-červeně polcená s vydutým polceným hrotem opačných barev, levá stříbrno-červeno-černě dělená."
    },
    {
        obec: "Podhradí",
        kod: 573329,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.418572,
        longitude: 15.310632,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Podhrad%C3%AD_%28Ji%C4%8D%C3%ADn_District%29_CoA_CZ.svg"
    },
    {
        obec: "Podhradí",
        kod: 534811,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76326,
        latitude: 49.140048,
        longitude: 17.77407,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/CoA_Podhrad%C3%AD.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou švestkovou větví se dvěma plody a dvěma listy. List je zubatě bílo-červeně čtvrceně lemován. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném návrší se třemi zleva vynikajícími červenými vlčími zuby zřícenina hradu, tvořená věží s cimbuřím a jedním prolomeným oknem a zleva k ní přiléhající zborcenou hradbou s prolomenou branou, provázená vlevo nahoře švestkovou větví se dvěma plody a dvěma listy, vše zlaté."
    },
    {
        obec: "Podhradí nad Dyjí",
        kod: 594636,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.900345,
        longitude: 15.686517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Podhrad%C3%AD_nad_Dyj%C3%AD_CoA.png"
    },
    {
        obec: "Podhradní Lhota",
        kod: 588873,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76871,
        latitude: 49.420849,
        longitude: 17.79516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Obecni-znak-podhradni-lhota2.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým klínem v podobě rovnostranného trojúhelníku se stylizovaným bílým kopřivovým listem ze znaku obce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném pahorku stříbrný hrad se třemi věžemi s cimbuřím - prostřední vyšší, na jehož zdi je umístěn červený štítek se stříbrným kopřivovým listem."
    },
    {
        obec: "Podivice",
        kod: 593486,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68321,
        latitude: 49.365845,
        longitude: 17.010822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Podivice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, zelený a červený, v poměru 1:3:1. V zeleném pruhu žlutý patriarší kříž mezi dvěma bílými parohy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně sníženě děleném štítě nahoře zlatý patriarší kříž mezi dvěma stříbrnými parohy, dole zlaté srdce se zkříženými černými sekerami."
    },
    {
        obec: "Podivín",
        kod: 584797,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69145,
        latitude: 48.82564,
        longitude: 16.848324,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Znak_obce_Podiv%C3%ADn.gif",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - žlutý, červený a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Podlesí",
        kod: 580805,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 50.020296,
        longitude: 16.281063
    },
    {
        obec: "Podkopná Lhota",
        kod: 585611,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76318,
        latitude: 49.305859,
        longitude: 17.831511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/CoA_Podkopn%C3%A1_Lhota.svg",
        coatOfArmsFlagDescription: "Modrý list s bílými zkříženými ostrvemi, každá s plodem trnky, mezi nimi hrotem nahoru bílý meč. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené ostrve, každá s jedním plodem trnky, mezi nimi hrotem nahoru kolmo prostrčen meč, vše stříbrné."
    },
    {
        obec: "Podlesí",
        kod: 564486,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.690395,
        longitude: 13.981947,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Coats_of_arms_Podles%C3%AD.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: zelený, žlutý a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trojvrší zvýšeném do úrovně hlavy štítu zkřížená stříbrná hornická kladívka se zlatými topůrky, přeložená vztyčenou stříbrnou odpichovací vidlicí. Pod tím stříbrné zúžené vlnité břevno."
    },
    {
        obec: "Podlešín",
        kod: 532754,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27325,
        latitude: 50.221126,
        longitude: 14.160368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Podle%C5%A1%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký třetinu délky listu se třemi žlutými hruškami pod sebou a pět střídavě bílých a černých vodorovných pruhů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - stříbrně polceném štítě vpravo tři zlaté hrušky pod sebou, vlevo kvádrovaný most o dvou a půl obloucích, nad ním zkřížená hornická kladívka, vše černé."
    },
    {
        obec: "Podluhy",
        kod: 531685,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.815724,
        longitude: 13.91363
    },
    {
        obec: "Podmoklany",
        kod: 548634,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58264,
        latitude: 49.721628,
        longitude: 15.767847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Podmoklany_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou podkovou, v ní horní část žluté berly s bílým sudariem, závitem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ve stříbrné podkově horní část zlaté berly se stříbrným sudariem."
    },
    {
        obec: "Podmokly",
        kod: 551686,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.230189,
        longitude: 13.578261,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Podmokly_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list; v žerďové polovině pod sebou tři šikmé ostrve, každá se třemi suky, ve vlající polovině lyra, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vpravo pod sebou tři šikmé ostrve, každá se třemi suky, vlevo lyra, vše stříbrné."
    },
    {
        obec: "Podmokly",
        kod: 560081,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.943339,
        longitude: 13.704478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Podmokly_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm šikmých pruhů, modrý, bílý, modrý, žlutý, modrý, bílý a modrý, v poměru 3:1:2:3:2:1:3. Ve žlutém pruhu šikmo tři červené růže se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá krokev nahoře se třemi červenými růžemi se zlatými semeníky a zelenými kališními lístky, provázená nahoře dvěma stříbrnými kapkami a dole zlatým korunovaným dvouocasým lvem s červenou zbrojí."
    },
    {
        obec: "Podmoky",
        kod: 548251,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.830381,
        longitude: 15.439055
    },
    {
        obec: "Podmoky",
        kod: 599590,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28904,
        latitude: 50.191564,
        longitude: 15.232291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Podmoky_%28Nymburk_District%29_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list se čtyřmi (2, 2) modrými kruhovými poli, každý se třemi bílými vlnitými pruhy, mezi nimi zelený lipový čtyřlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě mezi čtyřmi (2, 2) modrými kotouči, každý se třemi stříbrnými vlnitými břevny, zelený lipový čtyřlist."
    },
    {
        obec: "Podmolí",
        kod: 594644,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.850738,
        longitude: 15.940168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Podmol%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s vodorovným středovým bílým vlnitým pruhem širokým devítinu šířky listu s pěti vrcholy a šesti prohlubněmi. V horní části listu vinný hrozen na stonku se svěšeným listem, vše žluté, v dolní části listu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným vlnitým břevnem nahoře vinný hrozen na stonku se svěšeným listem, vše zlaté, dole stříbrná lilie."
    },
    {
        obec: "Podmyče",
        kod: 594652,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.887701,
        longitude: 15.782341,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Podmy%C4%8De_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý s bílým zvonem, žlutý s černými zkříženými ostrvemi a červený s bílým zvonem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatý hrot s černými zkříženými ostrvemi provázený dvěma stříbrnými zvony."
    },
    {
        obec: "Podolanka",
        kod: 538621,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25073,
        latitude: 50.159219,
        longitude: 14.601033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Podolanka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý. V červeném pruhu žlutý gotický klíč zuby nahoru a k žerďovému okraji, v modrém pruhu tři žluté odkloněné obilné klasy, v bílém pruhu červená lví noha se žlutou zbrojí šikmo vzhůru prostřelená modrým šípem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě pod červenou hlavou s doleva položeným zlatým gotickým klíčem zuby dolů vpravo tři zlaté odkloněné obilné klasy, vlevo červená lví noha se zlatou zbrojí šikmo vzhůru prostřelená modrým šípem."
    },
    {
        obec: "Podolí",
        kod: 583634,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66403,
        latitude: 49.190524,
        longitude: 16.72095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Podol%C3%AD_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 2:1. V žerďové polovině červeného pruhu bílý pták na žluté větvi se šesti lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným levým koutem na zlaté větvi se šesti lístky stříbrný pták."
    },
    {
        obec: "Podolí",
        kod: 596469,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.48436,
        longitude: 16.080553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Podol%C3%AD_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modro - červeně polcený. V bílém pruhu modrá lilie, v modrém poli bílý pták s černou zbrojí, v červeném poli bílý růženec se zavěšeným křížkem. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "Dělený štít, nahoře ve stříbrném poli modrá lilie, dolní pole modro - červeně polceno, vpravo stříbrný pták s černou zbrojí, vlevo růženec se zavěšeným křížkem, vše stříbrné."
    },
    {
        obec: "Podolí",
        kod: 516864,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75116,
        latitude: 49.445465,
        longitude: 17.530335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Coat_of_arms_of_Podol%C3%AD_PR.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým vydutým horním klínem, v něm vyrůstající zelený dub s hnědým kmenem a třemi (1, 2) žaludy. V žerďovém poli žlutý tlapatý patriarší kříž, ve vlajícím poli bílá sekera ostřím k žerďovému a žlutým topůrkem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý vydutý klín, v něm vyrůstající dub se třemi (1, 2) žaludy přirozené barvy. Vpravo zlatý tlapatý patriarší kříž, vlevo postavená stříbrná sekera se zlatým topůrkem."
    },
    {
        obec: "Podolí",
        kod: 592501,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68604,
        latitude: 49.0409,
        longitude: 17.529564,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Podoli_UH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a bílý. Na středu vozové kolo opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vozové kolo opačných barev."
    },
    {
        obec: "Podolí",
        kod: 569496,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75644,
        latitude: 49.430358,
        longitude: 17.857251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Podoli_VS_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 2:1. V žerďové polovině červeného pruhu bílý vykračující kůň s černým žlutě kovaným chomoutem. V zeleném pruhu v žerďové části kosmo radlice hrotem k dolnímu a ostřím k vlajícímu okraji, ve střední části šikmo podkova, obojí žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zeleným údolím stříbrný vykračující kůň s černým zlatě kovaným chomoutem. V údolí vpravo kosmo postavená doleva obrácená radlice, vlevo šikmo položená podkova, obojí zlaté."
    },
    {
        obec: "Podolí I",
        kod: 549754,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.361265,
        longitude: 14.312023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Podol%C3%AD_I_CoA.jpg",
        coatOfArmsFlagDescription: "Modro - zeleně šikmo dělený list. V modrém poli koruna převýšená šesticípou hvězdou, v zeleném vodorovně dřevorubecká pila - kaprovka, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně šikmo děleném štítě nahoře koruna převýšená hvězdou, dole dřevorubecká pila - kaprovka, vše zlaté."
    },
    {
        obec: "Podomí",
        kod: 550175,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68304,
        latitude: 49.344818,
        longitude: 16.833319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Podom%C3%AD_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou krokví, vycházející z páté až sedmé dvanáctiny horního a dolního okraje listu s vrcholem na žerďovém okraji, a se žlutým svislým pruhem vycházejícím z páté šestiny horního a dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zvýšenou stříbrnou krokví se třemi modrými květy barvínku zlaté váhy šikmo přeložené vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Podsedice",
        kod: 565415,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.472089,
        longitude: 13.948396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Podsedice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový pilovitý bílý pruh široký čtvrtinu délky listu a dva vodorovné pruhy, žlutý a zelený. Bílý pruh má tři zuby s vrcholy ve čtvrté desetině délky listu, v něm pět broušených červených českých granátů, dva u žerďového okraje pod sebou a tři u vlajícího okraje pruhu pod sebou, každý se šesti žlutými úchyty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý korunovaný lev s červenou zbrojí kráčející na třech stříbrných hrotech, v nich pět (3, 2) broušených českých granátů přirozené barvy, každý se šesti zlatými úchyty."
    },
    {
        obec: "Podůlšany",
        kod: 572951,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.128935,
        longitude: 15.738311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Pod%C5%AFl%C5%A1any_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, červený oboustranný zubatý posunutý, bílý oboustranný zubatý posunutý a zubatý červený, v poměru 8:1:3:3:3. Zuby i mezery jsou čtvercové. V žerďové a střední části modrého pruhu žlutá větévka olše lepkavé se dvěma odkloněnými listy, dvěma samčími jehnědami na stonku mezi nimi a dvěma trojicemi samčích jehněd na stonku pod nimi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli zlatá větévka olše lepkavé se dvěma odkloněnými listy, dvěma samčími jehnědami na stonku mezi nimi a dvěma trojicemi samičích jehněd na stonku pod nimi. Dolní pole třikrát stříbrno-červeně cimbuřovitě děleno."
    },
    {
        obec: "Podůlší",
        kod: 573345,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.469069,
        longitude: 15.340577,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Pod%C5%AFl%C5%A1%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "Žluto - zeleně čtvrcený list. Ve žlutých polích zelený olšový list bez řapíku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší olše přirozené barvy provázená dvěma stojícími přivrácenými černými ptáky s červenou zbrojí."
    },
    {
        obec: "Pohled",
        kod: 569291,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58221,
        latitude: 49.606211,
        longitude: 15.648156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Pohled_HB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed lilie, nahoře provázená dvěma vztyčenými odkloněnými lipovými listy, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě lilie nahoře provázená dvěma vztyčenými odkloněnými lipovými listy, vše stříbrné."
    },
    {
        obec: "Podveky",
        kod: 528196,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.825146,
        longitude: 14.994103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Podveky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý, v poměru 1 : 2 : 1. V zeleném pruhu žlutý medvěd ve skoku s červenou zbrojí, držící žlutou berlu závitem k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně vlevo polceném štítě vpravo zlatý medvěd ve skoku s červenou zbrojí držící zlatou berlu, vlevo tři svěšené stříbrné lipové listy pod sebou."
    },
    {
        obec: "Pohleď",
        kod: 573591,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.603572,
        longitude: 15.648294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Pohle%C4%8F_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, bílý a zelený. V červeném pruhu bílý buvolí roh postrkaný třemi žlutými větévkami o pěti listech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrný buvolí roh postrkaný třemi zlatými větévkami o pěti listech, vlevo nad zeleným návrším červený zvon."
    },
    {
        obec: "Pohledy",
        kod: 578550,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.695006,
        longitude: 16.560608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Pohledy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený. V červených pruzích po jednom žlutém jablku. Ve žlutém pruhu červené jablko a dva černé klíny, žerďový, sahající do první třetiny délky listu a vlající, sahající do druhé třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá, čelně postavená radlice provázená nahoře dvěma zlatými jablky, v ní vykračující černý kohout s červenou zbrojí, nad ním červené jablko."
    },
    {
        obec: "Pohnánec",
        kod: 560553,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.473682,
        longitude: 14.802766
    },
    {
        obec: "Pohnání",
        kod: 552852,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.477597,
        longitude: 14.811241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Pohn%C3%A1n%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý, uprostřed lilie opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě lilie opačných barev."
    },
    {
        obec: "Pohorovice",
        kod: 536911,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.188696,
        longitude: 14.135183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Znak2.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed žlutá koruna provázená dvěma bílými řeckými kříži nad dvěma odkloněnými žlutými obilnými klasy s listem. Pod korunou mezi klasy bílá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě dva odkloněné zlaté obilné klasy s listem převýšené zlatou korunou, provázenou dvěma řeckými kříži a dole hvězdou, vše stříbrné."
    },
    {
        obec: "Pohorská Ves",
        kod: 545694,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38283,
        latitude: 48.670025,
        longitude: 14.647748,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Pohorsk%C3%A1_Ves_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, palisádový žlutý a zvlněný modrý, v poměru 1:3:1. Uprostřed žlutého pruhu černá hyalitová váza, v modrém pruhu vodorovně tři žluté šindele vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou palisádovou hlavou, nad modrou patou se třemi (2,1) položenými zlatými šindeli černá hyalitová váza."
    },
    {
        obec: "Pohořelice",
        kod: 549461,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76361,
        latitude: 49.176665,
        longitude: 17.537954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Poho%C5%99elice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. Uprostřed bílý půlměsíc s osmicípou žlutou hvězdou mezi cípy směřujícími k vlajícímu okraji. V žerďové a vlající části každého rohu po jednom rovném tlapatém kříži. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě nad zlatou osmihrotou hvězdou ve stříbrném stoupajícím půlměsíci dva stříbrní dvouocasí lvi se zlatou zbrojí přivrácení ke zlatému řeckému kříži."
    },
    {
        obec: "Pohořelice",
        kod: 584801,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69123,
        latitude: 48.981287,
        longitude: 16.524557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Poho%C5%99elice_%28okres_Brno-venkov%29_znak.gif",
        coatOfArmsFlagDescription: "Červený list s bílou kvádrovanou hradbou s pěti obdélníkovými zuby a čtyřmi stejnými mezerami, s prázdnou gotickou branou se spuštěnou mříží. Hradba dosahuje do dvou pětin šířky listu. Za hradbou tři kvádrované věže s cimbuřím, nad každou růže, vše bílé. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Pohoří",
        kod: 576662,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.292257,
        longitude: 16.09906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Pohori_znak.gif",
        coatOfArmsFlagDescription: "Červený list s žerďovým bílým pruhem širokým jednu třetinu délky listu. V bílém pruhu půl zeleného listnatého stromu přiléhající k půli bílého vozového kola v červeném poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo vyniká půl zeleného listnatého stromu, vlevo vyniká půl stříbrného vozového kola."
    },
    {
        obec: "Pohoří",
        kod: 539562,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25401,
        latitude: 49.900021,
        longitude: 14.525125
    },
    {
        obec: "Pochvalov",
        kod: 542237,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27055,
        latitude: 50.227542,
        longitude: 13.794371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Coats_of_arms_Pochvalov.jpeg",
        coatOfArmsFlagDescription: "Čtvrcený list s bílým středovým vodorovným pruhem širokým jednu osminu šířky listu. Horní žerďové a dolní vlající pole žluté se zelenou svěšenou chmelovou šišticí, horní vlající pole zelené, dolní žerďové červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít. V horním zlatém poli stříbrná pata, na ní zvýšené zelené návrší, v něm vyrůstá šest stříbrných smrků. Dolní pole červeno-zlatě polcené, vpravo stříbrný osmihrotý kříž, vlevo zelená svěšená chmelová šištice."
    },
    {
        obec: "Pojbuky",
        kod: 552861,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.495907,
        longitude: 14.896833
    },
    {
        obec: "Pokojov",
        kod: 596477,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.481732,
        longitude: 15.906642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Pokojov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou kráčející liškou nad žlutou lilií, v žerďové a vlající části žlutý tlapatý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kráčející liška nad zlatou lilií po stranách dva zlaté tlapaté kříže."
    },
    {
        obec: "Pokojovice",
        kod: 550370,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.219742,
        longitude: 15.742458,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Pokojovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou berlou s bílým sudariem závitem k žerďovému okraji listu, provázenou v žerďové polovině listu bílou radlicí nad žlutou lilií, ve vlající polovině žlutou lilií nad bílou radlicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá berla se stříbrným sudariem provázená vpravo nahoře a vlevo dole stříbrnou radlicí, vpravo dole a vlevo nahoře zlatou lilií."
    },
    {
        obec: "Polánka",
        kod: 553565,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.435798,
        longitude: 13.555643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Pol%C3%A1nka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 4 : 1 : 4. V modrém pruhu osmicípá hvězda a mlýnské kolo, v zeleném květ kosatce, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně stříbrným vlnitým břevnem dělený štít. Nahoře osmihrotá hvězda a mlýnské kolo, dole květ kosatce, vše zlaté."
    },
    {
        obec: "Pokřikov",
        kod: 572063,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.79687,
        longitude: 15.988264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Pok%C5%99ikov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. Uprostřed svěšený lipový list opačných barev, v jeho žerďové polovině zelená uťatá lípa, ve vlající polovině polovina bílého koně ve skoku se žlutou zbrojí, červeným jazykem a uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě svěšený lipový list opačných barev, v něm vpravo zelená uťatá lípa, vlevo polovina stříbrného koně ve skoku se zlatou zbrojí, červeným jazykem a uzděním."
    },
    {
        obec: "Poleň",
        kod: 541788,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.425079,
        longitude: 13.176854,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Znak_Polen.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a červené pole s bílým koněm ve skoku směrem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě doleva stříbrný kůň ve skoku."
    },
    {
        obec: "Polepy",
        kod: 533599,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.004771,
        longitude: 15.206086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Polepy_%28okres_Kol%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a zelený, v poměru 2:1:1. V žerďové části bílého pruhu zelený papoušek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyniká zleva z modré vlnité paty se stříbrným mlýnským kamenem s černou položenou kypřicí listnatá větev přirozené barvy se zlatou skvrnou lepu na níž sedí zelený papoušek."
    },
    {
        obec: "Polepy",
        kod: 565431,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41147,
        latitude: 50.50573,
        longitude: 14.264579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Polepy_%28Litom%C4%9B%C5%99ice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Uprostřed knížecí koruna v chmelovém věnci opačných barev, střídavě se šesti listy a šesti šišticemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě knížecí koruna v chmelovém věnci opačných barev, střídavě se šesti listy a šesti šišticemi."
    },
    {
        obec: "Polerady",
        kod: 538639,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.200671,
        longitude: 14.593492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Coats_of_arms_Polerady.jpeg",
        coatOfArmsFlagDescription: "Zelený list s bílým lvem s červenou zbrojí stojícím na prohnutém obilném klasu a držícím v pravé přední tlapě lilii, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný lev s červenou zbrojí stojící na prohnutém obilném klasu a držící v pravé přední tlapě lilii, obojí zlaté."
    },
    {
        obec: "Polerady",
        kod: 567353,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.444544,
        longitude: 13.652795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Polerady_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří v horní polovině osm svislých, střídavě modrých a bílých pruhů, uprostřed se zkříženými černými hornickými kladívky. Dolní polovinu listu tvoří zelený vodorovný pruh s chmelovým listem se dvěma větévkami, každá se třemi šišticemi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním, sedmkrát modro-stříbrně polceném poli zkřížená černá hornická kladívka, v dolním zeleném poli chmelový list se dvěma větévkami, každá se třemi šišticemi, vše zlaté."
    },
    {
        obec: "Polesí",
        kod: 561436,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39468,
        latitude: 49.293206,
        longitude: 15.246599
    },
    {
        obec: "Polešovice",
        kod: 592510,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68737,
        latitude: 49.033999,
        longitude: 17.340746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Pole%C5%A1ovice%2C_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý červený s osmi čtvercovými zuby a sedmi stejnými mezerami. Z prostřední mezery vyrůstají dvě zelené odvrácené palmové ratolesti, pod nimi v červeném pruhu vodorovně zelený vinný hrozen se dvěma listy, stonkem k žerďovému okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Polevsko",
        kod: 561959,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47116,
        latitude: 50.786771,
        longitude: 14.533028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Polevsko_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a červený a na dolním okraji zelený pilovitý pruh široký osminu šířky listu se třemi zuby vysokými čtvrtinu šířky listu. Nad ním kosmo položený klíč zuby dolů a k vlajícímu okraji, šikmo podložený parohem se šesti výsadami k hornímu okraji listu, obojí opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě nad zelenou pilovitou patou se třemi hroty vpravo zkřížené postavené červené klíče, vlevo zkřížené zlaté parohy o šesti výsadách."
    },
    {
        obec: "Police",
        kod: 591394,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67534,
        latitude: 49.21454,
        longitude: 15.883581,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Znak_obce_Police_u_T%C5%99eb%C3%AD%C4%8De.jpg",
        coatOfArmsFlagDescription: "Kosmo dělený list s červenou žerďovou a černou vlající částí, uprostřed korunovaný lev s červenou zbrojí nesoucí přes pravé rameno bílý sloup. Pod levou přední tlapou kosmo položený bílý štítek, v něm uťatá supí hlava se žlutým zobákem a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě kosmo děleném štítě zlatý korunovaný lev s červenou zbrojí nesoucí přes pravé rameno stříbrný sloup. Pod levou přední tlapou kosmo položený stříbrný štítek, v něm červená uťatá supí hlava se zlatým zobákem a červeným jazykem."
    },
    {
        obec: "Police",
        kod: 540731,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78973,
        latitude: 49.814846,
        longitude: 16.996381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Police_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 2 : 1. V bílém pruhu černé krojidlo a radlice, hroty k hornímu, krojidlo ostřím k žerďovému a radlice ostřím k vlajícímu okraji listu, pod nimi rozdvojený zelený stonek lnu se dvěma modrými květy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vedle sebe postavené černé krojidlo a radlice, pod nimi rozdvojený zelený stonek lnu se dvěma modrými kvítky."
    },
    {
        obec: "Police",
        kod: 544621,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75644,
        latitude: 49.45682,
        longitude: 17.867653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Znak_Police.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 1 : 2. V bílém pruhu červené srdce mezi dvěma odkloněnými vztyčenými zelenými lipovými listy, v modrém pruhu bílý větrný mlýn s černými dveřmi, oknem, červenou jehlancovou střechou a žlutým větrným kolem se čtyřmi lopatkami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě, pod stříbrnou hlavou s červeným srdcem mezi dvěma odkloněnými vztyčenými zelenými lipovými listy, stříbrný větrný mlýn s černými dveřmi, oknem, červenou jehlancovou střechou a zlatým větrným kolem."
    },
    {
        obec: "Poličná",
        kod: 500071,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75701,
        latitude: 49.467058,
        longitude: 17.943924,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Uprostřed radlice ostřím dolů, hrotem k vlajícímu okraji, podložená svisle otkou, z níž vyrůstá větev lísky se dvěma listy, vše bílé, a nahoře se třemi (1, 2) žlutými oříšky, prostřední menší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zvýšeným zeleným dvojvrším položená radlice podložená vztyčenou otkou, z níž vyrůstá větev lísky se dvěma listy, vše stříbrné, a nahoře se třemi (1, 2) zlatými oříšky, prostřední menší."
    },
    {
        obec: "Police nad Metují",
        kod: 574341,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54954,
        latitude: 50.536968,
        longitude: 16.23361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Coat_of_arms_of_Police_nad_Metuj%C3%AD.gif",
        coatOfArmsFlagDescription: "Žlutý list s červenou černě lemovanou krokví s hrotem na středu vlajícího okraje. Šířka ramene krokve na žerďovém okraji je jedna čtvrtina šířky listu, včetně černého lemu o šířce jedné padesátiny šířky ramene krokve. Na průsečíku os úhlů trojúhelníkových žlutých polí jsou červené růže z městského znaku o průměru jedné čtvrtiny šířky listu praporu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Polička",
        kod: 578576,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.715034,
        longitude: 16.265977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Poli%C4%8Dka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří v žerďové části dva svislé pruhy: červený a žlutý, každý široký jednu čtvrtinu délky listu, a ve vlající části dva vodorovné pruhy: červený a bílý. V červeném žerďovém pruhu bílý dvouocasý lev se žlutou zbrojí a korunou, ve žlutém pruhu půl černé orlice s červenou zbrojí a žlutou korunou. Pod figurami, přes červené a žluté pole dvě zkřížené sekery - bradatice s hnědými topůrky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Polná na Šumavě",
        kod: 500194,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38226,
        latitude: 48.805608,
        longitude: 14.131502,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 7 : 4 : 7. V zeleném pruhu bílý meč se žlutým jílcem hrotem k dolnímu okraji listu, ve žlutých pruzích pod sebou dva květy hořečku českého. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený kůl s postaveným stříbrným mečem se zlatým jílcem provázený čtyřmi (2, 2) květy hořečku českého přirozené barvy."
    },
    {
        obec: "Polkovice",
        kod: 516899,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75144,
        latitude: 49.39183,
        longitude: 17.259275,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Polkovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. Na modrém poli bílá půlorlice se žlutou zbrojí a perisoniem a korunovaným červeným štítkem s bílým břevnem. Na bílém poli černá půlorlice se žlutou zbrojí a perisoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít, v pravé půli poloviční stříbrná orlice se zlatou zbrojí a perisoniem nesoucí na prsou korunovaný červený štítek se stříbrným břevnem. V levé půli poloviční černá orlice se zlatou zbrojí a perisoniem."
    },
    {
        obec: "Polná",
        kod: 587711,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.487099,
        longitude: 15.718914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Poln%C3%A1_CZ_CoA_ver_2022.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým španělským štítkem. V něm žlutý kapr podložený zkříženými černými ostrvemi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Polní Chrčice",
        kod: 513237,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.110106,
        longitude: 15.296802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Poln%C3%AD_Chr%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký polovinu délky listu a pět střídavě černých a bílých vodorovných pruhů. V žerďovém pruhu sedící červený chrt se žlutým obojkem s kroužkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-stříbrně dělený štít. Nahoře dvě stříbrná břevna, dole sedící červený chrt se zlatým obojkem s kroužkem."
    },
    {
        obec: "Polní Voděrady",
        kod: 513148,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 49.992409,
        longitude: 15.095338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Poln%C3%AD_Vod%C4%9Brady_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, bílý, modrý, bílý a žlutý, v poměru 2 : 1 : 18 : 1 : 2. V modrém pruhu zkřížené bílé meče hroty dolů, s červenou rukojetí, žlutou záštitou a hlavicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zkřížené postavené stříbrné meče s červenou rukojetí, zlatou záštitou a hlavicí, nahoře zlatý pšeničný klas s listy, dole stříbrná ryba."
    },
    {
        obec: "Polnička",
        kod: 596485,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59102,
        latitude: 49.607595,
        longitude: 15.916439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Polni%C4%8Dka_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým kosmým pruhem širokým pětinu délky listu na červené žerďové a zelené vlající pole. V červeném k dolnímu okraji obrácené kladivo, v zeleném k hornímu okraji obrácené kladivo, obě žlutá kosmo položená. V bílém pruhu modrá ostrev se čtyřmi suky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-zeleně dělený štít s kosmým stříbrným břevnem, v něm modrá ostrev, provázeným nahoře stříbrným květem se zlatým středem a kosmým zlatým kladivem, dole zlatou trubkou - polnicí."
    },
    {
        obec: "Polom",
        kod: 548723,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.059088,
        longitude: 16.305976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Polom_CoA_CZ.svg",
        coatOfArmsDescription: "V červeném štítě třikrát lomený stříbrný kůl, provázený vpravo postaveným mečem, vlevo palmovou ratolestí přeloženou dole vavřínovým věncem, vše zlaté."
    },
    {
        obec: "Polom",
        kod: 516911,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75364,
        latitude: 49.577179,
        longitude: 17.841791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Coat_of_arms_of_Polom_PR.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a bílých, v poměru 9 : 1 : 4 : 1 : 9. V prostředním pruhu žlutý obilný klas se dvěma listy, v žerďovém a vlajícím pruhu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma zúženými stříbrnými kůly zlatý obilný klas se dvěma listy. Kůly po vnějších stranách provázeny zlatou lilií."
    },
    {
        obec: "Polomí",
        kod: 549983,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79855,
        latitude: 49.631541,
        longitude: 16.949496,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Polom%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu bílý smrk zlomený k žerďovému okraji listu, pod ním žlutá radlice hrotem k dolnímu okraji listu a ostřím k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod doprava zlomeným stříbrným smrkem zlatá radlice provázená dvěma stříbrnými květy lnu se zlatými středy."
    },
    {
        obec: "Pomezí",
        kod: 578584,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56971,
        latitude: 49.710361,
        longitude: 16.317396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Pomez%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený. V bílém červený středový kříž s rameny širokými jednu šestinu délky listu, v červeném bílý lev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít, vpravo červený latinský heroldský kříž, vlevo stříbrný lev."
    },
    {
        obec: "Polště",
        kod: 562459,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.106486,
        longitude: 14.927954,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Pol%C5%A1t%C4%9B_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V žerďové části červeného pruhu utržená bílá orlí hlava se žlutou zbrojí a korunou. V žerďové části modrého pruhu žlutá růže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě, nahoře utržená stříbrná korunovaná orlí hlava se zlatou zbrojí. Dole zlatá růže."
    },
    {
        obec: "Pomezí nad Ohří",
        kod: 538868,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.086208,
        longitude: 12.279652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Pomez%C3%AD_nad_Oh%C5%99%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 4 : 1. Z prostřední třetiny modrého pruhu vyniká zelený vrch, z něho vyrůstá bílý dvouocasý korunovaný lev se žlutou zbrojí držící v tlapách bílo-modře kosmo čtvrcenou routu. V modrém pruhu a zeleném vrchu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze zeleno-modře děleného vrchu se zlatým mlýnským kolem stříbrný dvouocasý korunovaný lev se zlatou zbrojí držící v tlapách stříbrno-modře kosmo čtvrcenou routu."
    },
    {
        obec: "Ponědraž",
        kod: 562670,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.116075,
        longitude: 14.702066
    },
    {
        obec: "Ponědrážka",
        kod: 562653,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.136634,
        longitude: 14.703419
    },
    {
        obec: "Ponětovice",
        kod: 549738,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66451,
        latitude: 49.152257,
        longitude: 16.742391,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Pon%C4%9Btovice_znak.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým bílým pruhem, vycházejícím z první třetiny horního okraje listu do třetí třetiny vlajícího okraje listu, na zelené žerďové a červené vlající pole. V zeleném poli pět (1, 3, 1) šesticípých žlutých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně kosmo děleném štítě nahoře kosmá stříbrná ryba, dole pět zlatých hvězd (1, 3, 1)."
    },
    {
        obec: "Poniklá",
        kod: 577405,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51242,
        latitude: 50.661618,
        longitude: 15.463395,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Ponikl%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: žlutý a červený, uprostřed modrý válec rumpálu se dvěma klikami. Klika u žerdi je obrácená vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na černé stříbrně kvádrované šachtě rumpál s vytaženým okovem, vše zlaté s modrým kováním."
    },
    {
        obec: "Popelín",
        kod: 546992,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37855,
        latitude: 49.213683,
        longitude: 15.184019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Popel%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký jednu čtvrtinu délky listu se svislým cimbuřovým bílým břevnem a modré pole se žlutým vozovým kolem o pěti ramenech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se stříbrným cimbuřovým břevnem zlaté vozové kolo o pěti ramenech."
    },
    {
        obec: "Popice",
        kod: 584819,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69127,
        latitude: 48.927358,
        longitude: 16.66701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Coat_of_arms_of_Popice.jpeg",
        coatOfArmsFlagDescription: "Žlutý list s červeným lvem s modrou zbrojí, držící modrý vinný hrozen se zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trojvrší červený lev ve skoku s modrou zbrojí držící modrý vinný hrozen se zelenými listy."
    },
    {
        obec: "Popovice",
        kod: 583651,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.107197,
        longitude: 16.616203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Popovice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a čtvercové modré pole s bílým kosmým pruhem vycházejícím z první poloviny vlajícího okraje červeného pruhu do druhé poloviny vlajícího okraje listu. V bílém pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrná radlice, vlevo stříbrné kosmé břevno s červenou růží se zlatým semeníkem."
    },
    {
        obec: "Popovice",
        kod: 532649,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25701,
        latitude: 49.695492,
        longitude: 14.75256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Popovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílou orlicí se žlutou zbrojí a jazykem, červenou svatojakubskou mušlí na hrudi, provázenou po stranách krku kotvicovým křížem a šesticípou hvězdou, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná orlice se zlatou zbrojí a jazykem, s červenou svatojakubskou mušlí na hrudi, provázená po stranách krku vpravo kotvicovým křížem a vlevo hvězdou, obojí stříbrné."
    },
    {
        obec: "Popovice",
        kod: 592528,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68604,
        latitude: 49.052651,
        longitude: 17.527232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Popovice_UH_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, bílý a modrý, v poměru 6 : 1 : 1 : 8. V horním modrém pruhu žlutý tlapatý kříž, v dolním bílá lilie, z níž vzhůru do stran mezi listy vyrůstají dva žluté vinné hrozny na bílých stopkách. Poměr šířky k délce listuje 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nahoře zlatý tlapatý kříž do oblouku dolů provázený šňůrou z korálů, třech větších zlatých, po jednom na koncích a dole uprostřed, ostatních stříbrných. Dole stříbrná lilie, z níž vzhůru do stran mezi listy vyrůstají dva zlaté vinné hrozny na stříbrných stopkách."
    },
    {
        obec: "Postoloprty",
        kod: 566624,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43942,
        latitude: 50.359895,
        longitude: 13.70302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Postoloprty_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a červený, v poměru 1 : 3 : 3 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vynikají z kvádrované hradby dvě věže, vše stříbrné a s cimbuřím. Každá věž s černým oknem a červenou stanovou střechou se zlatou makovicí. Mezi věžemi červený štítek se zlatým kosmým kaprem"
    },
    {
        obec: "Poříčí u Litomyšle",
        kod: 572373,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.798443,
        longitude: 16.193901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Po%C5%99%C3%AD%C4%8D%C3%AD_u_Litomy%C5%A1le_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Na modrém listu bílý vzpínající se kůň. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Na modrém štítě stříbrný vzpínající se kůň."
    },
    {
        obec: "Popůvky",
        kod: 583669,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66441,
        latitude: 49.177467,
        longitude: 16.487676,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Popuvky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký jednu čtvrtinu délky listu. Střední a vlající část je rozdělena černým kosmým pruhem širokým jednu pětinu šířky listu na horní žluté a dolní bílé pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zlatou hlavou se třemi zelenými vrchy černé kosmé břevno provázené dvěma kosmo položenými orobinci přirozené barvy."
    },
    {
        obec: "Poříčí nad Sázavou",
        kod: 530441,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25721,
        latitude: 49.839058,
        longitude: 14.674564,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Porici_nad_Sazavou_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený a bílý. V horním žerďovém rohu polovina bílého berana se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná kostelní loď se zlatou střechou se třemi černými gotickými okny, k níž přiléhají dvě stříbrné věže, každá s černou branou a dvěma okny nad sebou, ukončené zlatými stanovými střechami, makovicemi a křížky. Ze střechy lodi vyniká stříbrný beran se zlatou zbrojí."
    },
    {
        obec: "Postřekov",
        kod: 554138,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34535,
        latitude: 49.458386,
        longitude: 12.806891,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Post%C5%99ekov_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a vlnkovitý modrý, v poměru 3 : 1. Uprostřed listu přes oba pruhy bílý meč hrotem k dolnímu okraji listu, v zeleném pruhu podložený zkříženými žlutými čakany. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s modrou vlnkovitou patou postavený stříbrný meč, nahoře podložený zkříženými zlatými čakany."
    },
    {
        obec: "Popůvky",
        kod: 591408,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67575,
        latitude: 49.159171,
        longitude: 16.127493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Pop%C5%AFvky_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-červeně šikmo dělený list. V zeleném poli šikmo bílá křesadlová pistole se žlutým kováním hlavní k hornímu okraji listu, v červeném šikmo růže na stonku se dvěma listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně šikmo děleném štítě nahoře šikmo doleva obrácená stříbrná křesadlová pistole se zlatým kováním, dole šikmo růže na stonku se dvěma listy, vše zlaté."
    },
    {
        obec: "Popovičky",
        kod: 599221,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.962644,
        longitude: 14.597904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Coat_of_arms_of_Popovi%C4%8Dky.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým tlapatým křížem s rameny na okrajích listu širokými třetinu délky listu. V žerďovém, horním a vlajícím rameni šesticípá červená hvězda, v dolním rameni vydutý červený klín vycházející z šesté a sedmé dvanáctiny dolního okraje listu, s vrcholem v sedmi desetinách šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný tlapatý kříž, v každém ze tří horních ramen hvězda, v dolním rameni vydutý hrot, vše červené."
    },
    {
        obec: "Postřelmov",
        kod: 540773,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78969,
        latitude: 49.907661,
        longitude: 16.912368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Post%C5%99elmov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět šikmých pruhů - černý, bílý, červený, bílý a černý v poměru 5:5:6:5:5. Na střed červeného pole je položena bílá střela hrotem k dolnímu žerďovému rohu, ohraničená kolmicemi vedenými z horního žerďového rohu a dolního vlajícího cípu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Štít rozdělen červeným zvýšeným hrotem, v němž ze zlaté koruny vyrůstá černý dvouocasý korunovaný lev se zlatou zbrojí držící stříbrnou střelu hrotem dolů, horní pole jsou vpravo šikmo a vlevo kosmo černo-stříbrně dělená."
    },
    {
        obec: "Poříčany",
        kod: 537705,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28914,
        latitude: 50.108165,
        longitude: 14.918285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/Po%C5%99%C3%AD%C4%8Dany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý. V žerďovém pruhu bílé průčelí barokního kostela s věží, s černými dveřmi, dvěma černými okny nad sebou a červenou střechou zakončenou černou makovicí s patriarším křížem. V bílém pruhu odvrácená křídla nad železničním kolem, obojí červené a tři modré vlnité pruhy vycházející ze sedmé, deváté a jedenácté dvanáctiny šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo stříbrné průčelí barokního kostela s věží, s černými dveřmi, dvěma černými okny nad sebou a červenou střechou zakončenou černou makovicí s patriarším křížem, vlevo nahoře odvrácená křídla nad železničním kolem, obojí červené, dole tři modrá vlnitá břevna."
    },
    {
        obec: "Postřelmůvek",
        kod: 553352,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.922722,
        longitude: 16.879208,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Post%C5%99elm%C5%AFvek_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed žluto-červeně polcený plamenný meč se žlutým jílcem, hrotem dolů, podložený zkříženými bílými šípy se žlutými hroty a opeřením, provázenými dvěma žlutými osmicípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě postavený zlato-červeně polcený plamenný meč se zlatým jílcem, podložený dvěma zkříženými stříbrnými šípy se zlatými hroty a opeřením, provázenými dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Postřižín",
        kod: 538647,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25070,
        latitude: 50.233091,
        longitude: 14.386813,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Post%C5%99i%C5%BE%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené karé nad stejně velkým bílým polem a dva vodorovné pruhy, bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě snížený zelený hrot se vztyčenými stříbrnými rozevřenými nůžkami."
    },
    {
        obec: "Postupice",
        kod: 530450,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25701,
        latitude: 49.728144,
        longitude: 14.777499,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Postupice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červené pole s českým lvem a modrý vlající zubatý pruh široký třetinu délky listu se žlutými hráběmi. Pruh má pět obdélníkových zubů a šest stejných mezer. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře vlevo cimbuřovitě polcený štít. V pravém poli český lev, v levém zlaté hrábě."
    },
    {
        obec: "Pošná",
        kod: 548600,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.458874,
        longitude: 15.042352
    },
    {
        obec: "Poštovice",
        kod: 564087,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27372,
        latitude: 50.312793,
        longitude: 14.137949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Po%C5%A1tovice_CoA.png",
        coatOfArmsFlagDescription: "List šikmo dělený bílým pruhem vycházejícím z třinácté šestnáctiny žerďového do deváté šestnáctiny vlajícího okraje listu na horní červené a dolní modré pole. Do žerďové poloviny červeného pole vyrůstá strom, k žerďovému okraji s lipovými a k vlajícímu okraji dubovými listy, uprostřed mezi větvemi devíticípá hvězda, vše žluté. U vlajícího okraje modrého pole žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít s modrou, stříbrným vlnitým břevnem oddělenou patou. Nahoře vyrůstající strom, vpravo s lipovými, vlevo s dubovými listy, uprostřed koruny devítihrotá hvězda, vše zlaté. V patě zlatý zvon."
    },
    {
        obec: "Poteč",
        kod: 549533,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76601,
        latitude: 49.154482,
        longitude: 18.035541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Potec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých v poměru 6:1:1:1:1:1:1. Ve středu žlutá vykořeněná lípa. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Na děleném štítě nahoře modrém, dole pětkrát stříbrno-modře děleném, zlatá vykořeněná lípa."
    },
    {
        obec: "Potěhy",
        kod: 534331,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28563,
        latitude: 49.86946,
        longitude: 15.4202,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Pot%C4%9Bhy_CoA.jpg"
    },
    {
        obec: "Potštát",
        kod: 517101,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75362,
        latitude: 49.637025,
        longitude: 17.651843,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Coat_of_arms_of_Pot%C5%A1t%C3%A1t.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy - bílý, černý, bílý a černý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Třikrát stříbrno-černě dělený štít."
    },
    {
        obec: "Potštejn",
        kod: 576671,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51743,
        latitude: 50.082189,
        longitude: 16.309021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Pot%C5%A1tejn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý s půlí černé zubří hlavy s červeným jazykem a hnědou houžví, přiléhající k bílému pruhu se třemi červenými kosmými pruhy vycházejícími z první, třetí a páté šestiny vlajícího okraje žlutého pruhu do druhé, čtvrté a šesté šestiny vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zlatém poli vyniká půl černé zubří hlavy s hnědou houžví, v levém stříbrném poli tři červená kosmá břevna."
    },
    {
        obec: "Potůčky",
        kod: 555479,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36235,
        latitude: 50.428273,
        longitude: 12.736597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Potucky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě červených a bílých. Uprostřed zkřížená hornická kladívka se žlutými topůrky zasahující do bílých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zkříženými černými hornickými kladívky se zlatými topůrky stříbrný lev."
    },
    {
        obec: "Poustka",
        kod: 577979,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.140272,
        longitude: 12.298291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Poustka_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený, v poměru 1 : 2. Do horního pruhu vyrůstá černá orlice s červenou zbrojí, v červeném pruhu bílá dvoupatrová věž s cimbuřím, dole kvádrovaná, s prolomenou branou a obloukovým oknem, obojí černé, podložená dvěma zkříženými žlutými palcáty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zvýšeně zlato-červeně děleném štítě nahoře vyrůstající černá orlice s červenou zbrojí, dole stříbrná dvoupatrová věž s cimbuřím, dole kvádrovaná, s prolomenou branou a obloukovým oknem, obojí černé, podložená dvěma zkříženými zlatými palcáty."
    },
    {
        obec: "Potvorov",
        kod: 530247,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 50.016105,
        longitude: 13.399461,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Potvorov_CoA.jpg"
    },
    {
        obec: "Pouzdřany",
        kod: 584835,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69126,
        latitude: 48.934526,
        longitude: 16.624973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Pouzdrany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "V bílém listu dvě dvakrát do oblouku zkřížené hnědé dubové ratolesti, každá dole s jedním listem a zakončená jedním svěšeným žaludem, obojí zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě dvě dvakrát do oblouku překřížené dubové ratolesti přirozené barvy, každá s jedním listem a zakončená jedním svěšeným žaludem."
    },
    {
        obec: "Povrly",
        kod: 568155,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40332,
        latitude: 50.672866,
        longitude: 14.160445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Povrly_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu čtvrtinu délky listu a tři vodorovné pruhy: bílý, žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá ze zeleného návrší se stříbrným vlnitým břevnem zlatý gryf s červenou zbrojí, držící v pravém spáru stříbrnou sekeru - bradatici na hnědém topůrku."
    },
    {
        obec: "Pozďatín",
        kod: 591416,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.235855,
        longitude: 16.037952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Poz%C4%8Fat%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený kosmým žlutým pruhem širokým polovinu šířky listu na žerďové modré pole a vlající bílé pole. V pruhu tři šesticípé modré hvězdy, v modrém poli kosmo bílý kapr a v bílém poli zelená vykořeněná borovice s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno - modře zlatým břevnem se třemi modrými hvězdami dělený štít, nahoře vykořeněná borovice přirozené barvy, dole kosmý stříbrný kapr."
    },
    {
        obec: "Pozděchov",
        kod: 544655,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.233333,
        longitude: 17.955546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Pozdechov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 4:1:1. V žerďové polovině horního modrého pruhu bílý kůň ve skoku, za jehož hlavou letí šikmo k hornímu okraji tři (2,1) žlutí skřivani. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůň ve skoku provázený vlevo nahoře třemi (2,1) doleva letícími zlatými skřivany."
    },
    {
        obec: "Pozdeň",
        kod: 532762,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27376,
        latitude: 50.242157,
        longitude: 13.943656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Pozde%C5%88_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu s bílým hříčem a tři vodorovné pruhy, zelený, bílý a zelený, v poměru 2 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít se zelenou zúženou hlavou. Nahoře chmelový list stopkami spojený se dvěma svěšenými šišticemi po stranách, vše zelené, dole stříbrný hříč."
    },
    {
        obec: "Pozlovice",
        kod: 549401,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76326,
        latitude: 49.129313,
        longitude: 17.769364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Pozlovice_znak.png",
        coatOfArmsFlagDescription: "Tři svislé pruhy - bílý, modrý, bílý. V modrém pruhu žlutá koruna nad žlutým kalichem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zlaté koruny vyrůstá stříbrný korunovaný pegas se zlatými kopyty a hřívou, nahoře provázený dvěma zlatými kalichy."
    },
    {
        obec: "Pozořice",
        kod: 583677,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66407,
        latitude: 49.209864,
        longitude: 16.790847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Znak_obce_Pozo%C5%99ice.gif",
        coatOfArmsFlagDescription: "Šikmo dělený list, v bílé žerďové části zelený lipový list stonkem dolů, v červené vlající části bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít, nahoře dva zelené lipové listy s plodem vedle sebe, dole lilie nad stoupajícím srpkem měsíce, obojí stříbrné."
    },
    {
        obec: "Prace",
        kod: 583685,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66458,
        latitude: 49.141161,
        longitude: 16.765498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Znak_obce_Prace_v2.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým vlajícím klínem s vrcholem ve středu listu. V žerďové polovině volný bílý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na modré patě vydutý hrot převýšený křížem, obojí stříbrné."
    },
    {
        obec: "Praha",
        kod: 554782,
        okres: "Praha",
        kod_okres: "CZ0100",
        kraj: "Hlavní město Praha",
        kod_kraj: "CZ010",
        psc: 11000,
        latitude: 50.075638,
        longitude: 14.4379,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Pracejovice",
        kod: 551619,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.257287,
        longitude: 13.849231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Pracejovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-žlutě šachovaný list (2x3). V horním žlutém poli kosmo červený šíp s modrým hrotem a bílým opeřením hrotem nahoru, v dolním modrém poli bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře čtvrceném štítě nahoře vpravo kosmá červená střela s modrým hrotem a stříbrným opeřením, vlevo stříbrná lilie, dole kruhové síto opačných barev."
    },
    {
        obec: "Práče",
        kod: 594679,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.877137,
        longitude: 16.201279,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Pr%C3%A1%C4%8De_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý. V žerďovém dva žluté zkřížené obilné klasy s jedním listem, v prostředním modrá gotická fiála a ve vlajícím pruhu žlutý vinný hrozen s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůl s modrou gotickou fiálou, provázený vpravo dvěma zkříženými obilnými klasy s jedním listem, vlevo vinným hroznem s jedním listem, obojí zlaté."
    },
    {
        obec: "Prackovice nad Labem",
        kod: 565458,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41133,
        latitude: 50.569522,
        longitude: 14.032075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Prackovice_nad_Labem_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed listu vztyčená zelená meruňková větev dole se dvěma listy a nahoře dvěma odkloněnými větévkami, v modrém pruhu s trsem bílých květů se žlutými středy, ve žlutém pruhu se třemi oranžovými plody pod třemi listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vztyčená zelená meruňková větev dole se dvěma listy a nahoře dvěma odkloněnými větévkami, vpravo s trsem stříbrných květů se zlatými středy, vlevo se třemi plody přirozené barvy pod třemi listy."
    },
    {
        obec: "Prádlo",
        kod: 558231,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.503547,
        longitude: 13.541553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Pr%C3%A1dlo_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s modrým kosmým vlnitým pruhem širokým pětinu délky listu, nahoře a dole bíle lemovaným, se třemi vrcholy a dvěma prohlubněmi a se třemi žlutými valouny. Ve vlajícím poli hlavice berly závitem k žerďovému okraji, v žerďovém osmicípá hvězda, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě modré, stříbrně lemované vlnité břevno se třemi valouny, provázené nahoře hlavicí berly, dole osmihrotou hvězdou, vše zlaté."
    },
    {
        obec: "Prachovice",
        kod: 572071,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53804,
        latitude: 49.893895,
        longitude: 15.628821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Prachovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 5 : 11. V bílém pruhu modrá koruna, pod ní v červeném pruhu vztyčené bílé kladivo mezi zkříženými žlutými ostrvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou hlavou s modrou korunou vztyčené stříbrné kladivo mezi zkříženými zlatými ostrvemi."
    },
    {
        obec: "Prachatice",
        kod: 550094,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.01101,
        longitude: 14.0001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Prachatice_znak.svg",
        coatOfArmsFlagDescription: "Červený list s bílým ondřejským křížem s rameny vycházejícími z první a čtvrté čtvrtiny žerďového a vlajícího okraje. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Prakšice",
        kod: 592536,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68756,
        latitude: 49.069003,
        longitude: 17.633144,
        coatOfArmsFlagDescription: "Žerďová část listu červená, vlající modrá. Na středu bílá lilie, provázená nahoře v červeném poli vztyčeným bílým, v modrém poli vztyčeným žlutým vinným hroznem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít. V něm stříbrná lilie nahoře provázená vztyčeným stříbrným a zlatým vinným hroznem."
    },
    {
        obec: "Praskačka",
        kod: 570656,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50333,
        latitude: 50.17297,
        longitude: 15.742802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Praska%C4%8Dka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list. V žerďové části žlutá berla s bílým sudariem závitem k vlajícímu okraji listu, do střední části šikmo podložená hrstí pěti žlutých obilných klasů. V horní polovině vlající části světlemodrý květ čekanky se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vpravo vyrůstající zlatá berla se stříbrným sudariem provázená vlevo nahoře květem čekanky přirozené barvy a dole šikmo podložená hrstí pěti zlatých obilných klasů."
    },
    {
        obec: "Prameny",
        kod: 539538,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 50.050184,
        longitude: 12.732909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Prameny_CoA.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list, v žerďovém modrém poli bílá kotva obtočená žlutou chmelovou lodyhou se čtyřmi (2, 2) listy a dvěma páry šištic mezi nimi. Vlající pole bílo-žlutě šikmo dělené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kotva obtočená zlatou chmelovou lodyhou se čtyřmi (2, 2) listy a dvěma páry šištic mezi nimi."
    },
    {
        obec: "Praskolesy",
        kod: 531693,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26754,
        latitude: 49.865986,
        longitude: 13.933537,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Praskolesy_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený, žlutý a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít. Nahoře vyrůstají tři zelené jetelové trojlístky. Dole tři (2,1) zlaté kotouče."
    },
    {
        obec: "Prasek",
        kod: 570648,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.238775,
        longitude: 15.548695,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Prasek_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý. V bílých pruzích zelený smrk s černým kmenem, v zeleném pruhu nad srdcem obilný klas se dvěma listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený kůl, v něm nad srdcem obilný klas se dvěma listy, vše zlaté, kůl provázejí dva zelené smrky s černými kmeny."
    },
    {
        obec: "Prášily",
        kod: 556084,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.10524,
        longitude: 13.378047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Pr%C3%A1%C5%A1ily_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 2 : 1. Do žlutého pruhu vyrůstá zelený jehličnatý strom s hnědým kmenem, pod ním bílé kruhové pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě jehličnatý strom přirozené barvy na zeleném trojvrší se stříbrným kotoučem."
    },
    {
        obec: "Prasklice",
        kod: 587397,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.268795,
        longitude: 17.186821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Prasklice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený, přes které jsou položeny dva žluté vodorovné pruhy, vycházející z druhé a sedmé osminy žerďového a vlajícího okraje. Uprostřed bílá lilie kvetoucí dvěma odkloněnými liliemi na stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná lilie kvetoucí dvěma odkloněnými liliemi na stoncích, ze středního listu vyskakují dva odvrácení zlatí jednorožci."
    },
    {
        obec: "Pravčice",
        kod: 588890,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76824,
        latitude: 49.324587,
        longitude: 17.495591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Prav%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem vycházejícím z druhé a třetí čtvrtiny žerďového okraje a s vrcholem na vlajícím okraji. Uprostřed listu žlutý vinařský nůž ostřím k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným kuželem kosmo položený zlatý vinařský nůž."
    },
    {
        obec: "Pravice",
        kod: 594687,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.843456,
        longitude: 16.361153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Pravice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený, žlutý kvádrovaný a zelený, v poměru 4:1:2:1. Do bílého pruhu vyrůstá červený dvouocasý lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře vyrůstající červený dvouocasý lev se zlatou zbrojí. Dole nad kvádrovanou hradbou se třemi stínkami tři vztyčené žaludy, vše zlaté."
    },
    {
        obec: "Pravlov",
        kod: 583693,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.055779,
        longitude: 16.487786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Pravlov_-_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý se žlutou limpou hrotem k hornímu okraji listu a červený s bílým vinařským nožem-kosířem se žlutou rukojetí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Pravonín",
        kod: 530476,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25709,
        latitude: 49.635687,
        longitude: 14.943477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Coat_of_arms_of_Pravon%C3%ADn.svg",
        coatOfArmsFlagDescription: "Červený list s bílým hříčem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný hříč."
    },
    {
        obec: "Pravy",
        kod: 572853,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.131879,
        longitude: 15.624255
    },
    {
        obec: "Pražmo",
        kod: 568813,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73904,
        latitude: 49.608863,
        longitude: 18.48628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Prazmo_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, modrý a žlutý v poměru 1:2:1. V modrém poli žlutá jelení hlava. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá jelení hlava."
    },
    {
        obec: "Prlov",
        kod: 544671,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.248001,
        longitude: 17.961456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Prlov_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě modrých a bílých v poměru 7:1:1:1:1. Druhý, třetí a čtvrtý jsou vlnité se třemi vrcholy a dvěma prohlubněmi. Na horním bílém pruhu pluje žlutá kachna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V patě modrého štítu dvě stříbrná zúžená vlnitá břevna, na nichž plave kachna provázaná dvěma vyrůstajícími přivrácenými orobnici, vše zlaté."
    },
    {
        obec: "Proboštov",
        kod: 567787,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41712,
        latitude: 50.667833,
        longitude: 13.836123,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Probostov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý a svislý modrý vlající pruh široký polovinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo červené břevno se stříbrnou mitrou provázené dvěma vztyčenými zelenými dubovými listy, vlevo stříbrná orlice s červenou zbrojí a zlatou korunou na krku."
    },
    {
        obec: "Probulov",
        kod: 561509,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.492895,
        longitude: 14.145234
    },
    {
        obec: "Prodašice",
        kod: 570753,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.350064,
        longitude: 15.116768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Proda%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý vlnitý nahoře lemovaný bílým lanem a žlutý zvlněný, v poměru 22 : 3 : 5. Z vlnitého pruhu vyskakuje modrý lev s červenou zbrojí, z jehož pravé tlapy kanou tři (1, 2) modré kapky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý lev s červenou zbrojí, z jehož pravé tlapy kanou tři (1, 2) modré kapky. Lev vyskakuje ze sníženého modrého břevna nahoře lemovaného stříbrným lanem."
    },
    {
        obec: "Prokopov",
        kod: 594695,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67154,
        latitude: 48.998438,
        longitude: 15.896085,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Coat_of_arms_of_Prokopov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, černý a bílý, v poměru 2:1:2. V horním bílém pruhu dvě, v dolním jedna růže, všechny červené se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černé břevno provázené nahoře dvěma a dole jednou červenou růží se zlatým semeníkem."
    },
    {
        obec: "Proseč",
        kod: 537829,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.600804,
        longitude: 15.329186
    },
    {
        obec: "Proruby",
        kod: 548758,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.070679,
        longitude: 16.295451
    },
    {
        obec: "Proseč",
        kod: 572080,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.805994,
        longitude: 16.11631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Prose%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílé žerďové čtvercové pole s černým vykračujícím kohoutem se žlutou zbrojí, červeným hřebenem a lalokem. Vlající část tvoří šest svislých pruhů střídavě zelených a bílých v poměru 1:1:2:1:1:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Proseč pod Ještědem",
        kod: 544345,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46343,
        latitude: 50.701762,
        longitude: 15.017139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Prosec_pod_Jestedem_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list, v horním rohu a cípu červená čtvrtkruhová pole o poloměru rovném polovině délky listu. V červených polích přivrácené bílé sekery, v bílém poli zelená vztyčená jasanová větev s pěti listy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný vydutý hrot se vztyčenou zelenou jasanovou větví s pěti listy, provázený dvěma přivrácenými stříbrnými sekerami."
    },
    {
        obec: "Proseč pod Křemešníkem",
        kod: 561941,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.415347,
        longitude: 15.295046,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Prose%C4%8D_pod_K%C5%99eme%C5%A1n%C3%ADkem.png",
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list. Do bílého pole vyrůstají tři zelené jehličnaté stromy s černými kmeny, s vrcholy u horního okraje listu. V zeleném poli koruna v mezikruží, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě tři zelené jehličnaté stromy s černými kmeny vyrůstajícími ze zeleného návrší, v něm koruna v kruhu, obojí zlaté."
    },
    {
        obec: "Prosečné",
        kod: 579602,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54373,
        latitude: 50.562125,
        longitude: 15.687438,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Prosecne_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červený, bílý a zelený v poměru 1:1:2:1:1. V červeném pruhu tři žluté květy s bílými středy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstají ze středu na třech zelených stoncích provázených dvěma listy tři zlaté květy se stříbrnými středy."
    },
    {
        obec: "Prosenice",
        kod: 517151,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75121,
        latitude: 49.488904,
        longitude: 17.484287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Prosenice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, modrý a žlutý v poměru 1:4:1. Na středu modrého pruhu žlutý útočící dravý pták s červenou zbrojí a dvěma zelenými stvoly prosa na rozevřených křídlech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý útočící dravý pták s červenou zbrojí se dvěma zelenými stvoly prosa na rozevřených křídlech."
    },
    {
        obec: "Prosenická Lhota",
        kod: 541133,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.689735,
        longitude: 14.482797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Coat_of_arms_of_Prosenick%C3%A1_Lhota.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a žluté pole. V pruhu kosmý bílý pruh, se třemi šesticípými modrými hvězdami, vycházející z prvních tří osmin žerďového do posledních tří osmin vlajícího okraje pruhu. Ve střední části listu šest (2, 1, 2, 1) zelených vztyčených lipových listů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo stříbrné kosmé břevno se třemi modrými hvězdami, vlevo šest (2, 1, 2, 1) vztyčených zelených lipových listů."
    },
    {
        obec: "Prosetín",
        kod: 596493,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59264,
        latitude: 49.526397,
        longitude: 16.394946,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Proset%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, střídavě černých a bílých a sedmý zelený pruh, v poměru 1 : 1 : 1 : 1 : 1 : 7 : 4. V dolním klínovitém bílém pruhu s vrcholem v sedmé osmině šířky listu černá zubří hlava se žlutou houžví a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod černou hlavou se dvěma stříbrnými břevny černá zubří hlava se zlatou houžví a červeným jazykem nad zeleným trojvrším s prostředním nižším vrchem."
    },
    {
        obec: "Prosetín",
        kod: 572098,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53976,
        latitude: 49.833121,
        longitude: 15.957751,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Proset%C3%ADn_%28Chrudim_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s kosmo vztyčeným mlátkem šikmo podloženým vztyčenou rozrážecí palicí, obojí šedé na hnědých topůrkách a přeložené šedým dlátem hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmo mlátek šikmo podložený rozrážecí palicí a přeložený postaveným dlátem, vše přirozené barvy."
    },
    {
        obec: "PROSTĚJOV",
        kod: 589250,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79601,
        latitude: 49.472549,
        longitude: 17.106851,
        coatOfArmsFlagDescription: "V polovině svisle dělený na pravou modrou a levou žlutou část, uprostřed na líci znak města Prostějova ve španělském štítu (výška štítu je 3/4 výšky praporu). Poměr stran 2:3."
    },
    {
        obec: "Prosíčka",
        kod: 569313,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.724592,
        longitude: 15.323639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Pros%C3%AD%C4%8Dka_HB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 11: 5. Bílý pruh má zub široký čtvrtinu délky listu a vysoký osminu šířky listu. Uprostřed zeleného pruhu dva přivrácení bílí lvi se žlutou zbrojí a jazyky. Lev v žerďové polovině drží v levé přední tlapě bílý srp se žlutou rukojetí, hrotem k hornímu a ostřím k vlajícímu okraji listu. Lev ve vlající polovině drží v pravé přední tlapě tři žluté obilné klasy. V bílém pruhu dva modré vlnité pruhy široké pětinu šířky pruhu s pěti vrcholy a čtyřmi prohlubněmi, přeložené zeleným latinským křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně sníženě uprostřed oboustranným stupněm dělený štít. Nahoře dva přivrácení stříbrní lvi se zlatou zbrojí a jazyky, pravý držící v levé přední tlapě doleva obrácený stříbrný srp se zlatou rukojetí, levý v pravé přední tlapě tři zlaté obilné klasy. Dole dvě modrá zúžená vlnitá břevna přeložená zeleným latinským křížem."
    },
    {
        obec: "Prosiměřice",
        kod: 594709,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.902803,
        longitude: 16.192067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Prosim%C4%9B%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1:3:1. V zeleném pruhu žlutý zajíc panáčkující k vlajícímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Prostějovičky",
        kod: 589918,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.427632,
        longitude: 16.997349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Prost%C4%9Bjovi%C4%8Dky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed žlutý vztyčený žalud na větvi se třemi malými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zlatý vztyčený žalud na větvi se třemi malými listy, nahoře provázený zavinutou střelou a lilií, obojí stříbrné."
    },
    {
        obec: "Prostiboř",
        kod: 541486,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.652373,
        longitude: 12.89399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Prostibo%C5%99_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, a zelený vodorovný pruh na dolním okraji listu, široký čtvrtinu šířky listu. Do červeného pruhu vyniká z hradby věž s cimbuřím, obojí bílé. Věž s obloukovým vstupem, dvěma okny v odsazeném patře a stanovou střechou, vše černé. Střecha zakončena žlutou makovicí. V modrém pruhu žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře polcený štít se zeleným návrším. Vpravo vyniká z hradby věž s cimbuřím, obojí stříbrné. Věž s obloukovým vstupem, dvěma okny v odsazeném patře a stanovou střechou, vše černé. Střecha zakončena zlatou makovicí. Vlevo zlatá lilie."
    },
    {
        obec: "Prostřední Bečva",
        kod: 544698,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75656,
        latitude: 49.436192,
        longitude: 18.252106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/CZ_Prost%C5%99edn%C3%AD_Be%C4%8Dva_COA.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, černo-červeně polcený, bílý, modrý, bílý a modrý, v poměru 7:1:1:1:1. Z horního bílého pruhu vyskakuje žlutý kozel s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-červeně polceném štítě vyskakuje ze stříbrného trojvrší na modré vlnité patě se stříbrným vlnitým břevnem zlatý kozel s červeným jazykem."
    },
    {
        obec: "Prostřední Poříčí",
        kod: 582221,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.572106,
        longitude: 16.497452
    },
    {
        obec: "Protivanov",
        kod: 589926,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79848,
        latitude: 49.483614,
        longitude: 16.836047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/ZnakProtivanova.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený s bílou lilií, bílý a modrý, v poměru 2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě nad stříbrnou lilií stříbrný nebozez se zlatou rukojetí, provázený vpravo stříbrnou radlicí, vlevo stříbrným pořízem se zlatými rukojeťmi."
    },
    {
        obec: "Protivín",
        kod: 549771,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39811,
        latitude: 49.199591,
        longitude: 14.217277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Znak_Mesta_Protivin.jpg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část o šířce jedné třetiny šířky listu a osm vodorovných pruhů střídavě bílých a modrých. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Provodín",
        kod: 561983,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47167,
        latitude: 50.617925,
        longitude: 14.601372
    },
    {
        obec: "Provodov",
        kod: 585661,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76345,
        latitude: 49.160363,
        longitude: 17.736899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/CoA_of_Provodov.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 1 : 1 : 4 : 1 : 1. Uprostřed listu vyrůstají do středního pruhu vedle sebe tři žluté lilie na stoncích, každý se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný korunovaný Pegas se zlatou zbrojí nad stříbrným návrším, z něhož vyrůstají tři zlaté lilie na stoncích, každý se dvěma listy."
    },
    {
        obec: "Provodovice",
        kod: 517208,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.451055,
        longitude: 17.768349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Provodice_znak.png",
        coatOfArmsFlagDescription: "Modrý list se dvěma kosmými pruhy, bílým a červeným, širokými jednu osmnáctinu šířky listu, procházejícími žlutým třmenem s jetelovým závěsem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý třmen s jetelovým závěsem, zprava proložený stříbrným listem kosy, provázený nahoře dvěma a provlečený dole jedním vztyčeným stříbro-červeně čtvrceným tkalcovským člunkem."
    },
    {
        obec: "Provodov-Šonov",
        kod: 574350,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54908,
        latitude: 50.382206,
        longitude: 16.114965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Provodov-%C5%A0onov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou ostrví o sedmi sucích (4+3) širokou jednu pětinu šířky listu a sahající od žerďového k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ostrvové břevno, nad ním hlavice berly závitem doprava, obojí zlaté."
    },
    {
        obec: "Prštice",
        kod: 583707,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66446,
        latitude: 49.115118,
        longitude: 16.470866,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Pr%C5%A1tice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu na zeleném trojvší bílý dub se šesti žlutými vztyčenými žaludy. Ze stran se o dub opírají přední nohou dva žlutí kozli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvší stříbrný dub se šesti zlatými vztyčenými žaludy. Ze stran se o dub opírají přední nohou dva zlatí kozli."
    },
    {
        obec: "Průhonice",
        kod: 539571,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25243,
        latitude: 50.001815,
        longitude: 14.560406
    },
    {
        obec: "Prusice",
        kod: 564788,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.968457,
        longitude: 14.877965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Prusice_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-žlutě děleným šikmým pruhem, vycházejícím z druhé osminy dolního a druhé osminy vlajícího okraje, dělený list. V žerďovém žlutém poli tři (2, 1) vztyčené zelené lipové listy, v červeném vlajícím poli žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-červeně sníženě šikmým červeno-zlatě děleným břevnem dělený štít, nahoře tři (2, 1) vztyčené zelené lipové listy, dole zlatý zvon."
    },
    {
        obec: "Prusinovice",
        kod: 588903,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76842,
        latitude: 49.379083,
        longitude: 17.587212,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Prusinovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé se žlutým jeruzalémským křížem a pět vodorovných pruhů, červený, bílý, černý, bílý a černý, v poměru 4 : 1 : 1 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře polceném štítě vpravo stříbrný buvolí roh se dvěma černými břevny, kosmo podložený postaveným zlatým mečem, vlevo nad třikrát stříbrno - černě dělenou hradbou se třemi stínkami zlatý jeruzalémský kříž."
    },
    {
        obec: "Prusy-Boškůvky",
        kod: 593494,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.258521,
        longitude: 17.063799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Znak_Prusy-Bo%C5%A1k%C5%AFvky.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý v poměru 4:1:1. V horním rohu bílo-červeně šachované karé o šířce poloviny šířky listu o čtyřech řadách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrno-červeně šachovaným koutem čáp přirozených barev přidržující stříbrné vozové kolo."
    },
    {
        obec: "Prušánky",
        kod: 586498,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69621,
        latitude: 48.828498,
        longitude: 16.980787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Prusanky_znak.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu polovinu délky listu a čtyři vodorovné pruhy, střídavě bílé a černé. V červeném pruhu žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli zlatý vinný hrozen, levé pole třikrát stříbrno-černě dělené."
    },
    {
        obec: "Prysk",
        kod: 561991,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47115,
        latitude: 50.796139,
        longitude: 14.468026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Prysk_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký třetinu délky listu, a pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 15 : 2 : 6 : 2 : 15. V červeném pruhu klíč zuby dolů a k žerďovému okraji a meč hrotem k hornímu okraji listu, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělený modrým vlnitým, stříbrně lemovaným břevnem. Nahoře ve zlato-černě polceném poli zelené trojvrší. Dole v červeném poli položený meč nad položeným klíčem, zuby doleva a dolů, obojí zlaté."
    },
    {
        obec: "Pržno",
        kod: 507181,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73911,
        latitude: 49.613573,
        longitude: 18.361716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Przno_%28Frydek-Mistek%29_znak_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým kohoutem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý kohout."
    },
    {
        obec: "Pržno",
        kod: 544728,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75623,
        latitude: 49.387569,
        longitude: 17.942957,
        coatOfArmsFlagDescription: "Modrý list se žlutým půlměsícem cípy vzhůru pod šesticípou žlutou hvězdou v žerďové a střední části. Na vlajícím okraji tři zelené rovnostranné trojúhelníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným trojvrším stoupající půlměsíc pod hvězdou, vše zlaté."
    },
    {
        obec: "Přáslavice",
        kod: 552411,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78354,
        latitude: 49.587462,
        longitude: 17.390548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Praslavice_znak_obce_01.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený. V žerďové polovině je žlutá přeslice s bílou panenkou lnu, z níž ke středu na vláknu visí žluté vřeteno, provázená u žerdi žlutým obilným klasem a na druhé straně nahoře žlutou lilií, jejíž dolní list přesahuje do červeného pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně děleném štítě zlatá přeslice se stříbrnou panenkou lnu, z níž vlevo na vláknu visí zlaté vřeteno, provázaná vpravo zlatým obilným klasem a vlevo zlatou lilií."
    },
    {
        obec: "Přeborov",
        kod: 561517,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.479579,
        longitude: 14.357241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/P%C5%99eborov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený smrkový a modrý, v poměru 3 : 7 : 1. Na modrém pruhu pluje bílá labuť se žlutým zobákem, provázená nahoře zvonem a v žerďové a vlající části liliemi, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou smrkovou hlavou na modré vlnité patě plovoucí stříbrná labuť se zlatým zobákem, provázená nahoře zvonem a po stranách dvěma liliemi, vše zlaté."
    },
    {
        obec: "Přebuz",
        kod: 560596,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.36589,
        longitude: 12.620158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Gerb_P%C5%99ebuz.png"
    },
    {
        obec: "Přeckov",
        kod: 591424,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.275939,
        longitude: 15.9164
    },
    {
        obec: "Předboj",
        kod: 538655,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25072,
        latitude: 50.225492,
        longitude: 14.476835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Coats_of_arms_P%C5%99edboj.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy modrý, zubatý bílý a zvlněný modrý, v poměru 1 : 10 : 2. V bílém pruhu dvě černé kohoutí hlavy s červenou zbrojí, mezi nimi osmihrotý kříž nad šesticípou hvězdou, obojí červené. Bílý pruh má tři zuby a dvě stejně velké mezery hluboké čtvrtinu šířky zubu, modrý zvlněný čtyři vrcholy a tři prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou cimbuřovou hlavou se dvěma stínkami a nad modrou vlnitou patou osmihrotý kříž nad hvězdou, obojí červené, provázené dvěma černými kohoutími hlavami s červenou zbrojí."
    },
    {
        obec: "Předhradí",
        kod: 572101,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53974,
        latitude: 49.831724,
        longitude: 16.038455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/P%C5%99edhrad%C3%AD_CR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu volná bílá kvádrovaná hradba s prázdnou branou. Z hradby vyniká věž s cimbuřím a gotickým oknem, provázená dvěma nižšími věžemi s červenými kuželovými střechami, každá se dvěma okny vedle sebe, věže bílé, okna černá. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou. Z hradby vyniká věž s cimbuřím a gotickým oknem, provázená dvěma nižšími věžemi s červenými kuželovými střechami, každá se dvěma okny vedle sebe, věže stříbrné, okna černá."
    },
    {
        obec: "Předenice",
        kod: 540412,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33209,
        latitude: 49.624495,
        longitude: 13.396669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/P%C5%99edenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 3:1:3. Uprostřed listu dvě přivrácená křídla, v bílých pruzích černá, v modrém bílá. Mezi křídly vyrůstá z modrého pruhu červená berla závitem k žerdi, s modrým, do stran vlajícím sudariem. Pod ní v modrém pruhu volné žluté cimbuří se třemi zuby nahoru a dvěma dolů, pod ním v bílém pruhu svisle modrá kypřice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrým břevnem dvě černá přivrácená křídla se stříbrným břevnem. Uprostřed modrého břevna, z něhož vyrůstá červená berla s modrým, do stran vlajícím sudariem, volné zlaté cimbuří se třemi stínkami nahoře a dvěma dole. Pod břevnem mezi křídly postavená modrá kypřice."
    },
    {
        obec: "Předín",
        kod: 591432,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67527,
        latitude: 49.198388,
        longitude: 15.673827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/P%C5%99ed%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený zeleným šikmým pruhem širokým jednu čtvrtinu šířky listu na žerďové bílé a vlající červené pole. V bílém poli černá plamenná orlice s červenou zbrojí, v zeleném pruhu pět žlutých kruhových polí a v červeném poli bílá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná kvádrovaná zeď s červeným štítkem se stříbrnými hornickými kladívky, z níž vyrůstá na zlaté žerdi stříbrný praporec se dvěma cípy s černou plamennou orlicí s červenou zbrojí, provázený podél okrajů štítu sedmi zlatými knoflíky."
    },
    {
        obec: "Předklášteří",
        kod: 549746,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66602,
        latitude: 49.35263,
        longitude: 16.402512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Predklasteri_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou krokví v podobě gotického oblouku, jehož vrchol sahá do dvou třetin listu. Krokev vychází z první a šesté šestiny žerďového okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá zlatý prázdný gotický oblouk provázený dvěma zlatými listovými korunami, v něm stříbrná lilie nad zlatým tlapatým křížem."
    },
    {
        obec: "Předměřice nad Jizerou",
        kod: 536491,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29474,
        latitude: 50.25558,
        longitude: 14.781377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/P%C5%99edm%C4%9B%C5%99ice_nad_Jizerou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, červený, žlutý a modrý, v poměru 1:5:5:1. V červeném pruhu bílý lev se žlutou zbrojí, ve žlutém pruhu půl černého orla se žlutou zbrojí a červeným jazykem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě pod modrou hlavou se stříbrnou svatojakubskou mušlí podloženou zkříženými zlatými poutnickými holemi vpravo stříbrný lev se zlatou zbrojí, vlevo půl černého orla se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Předměřice nad Labem",
        kod: 570672,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50302,
        latitude: 50.256426,
        longitude: 15.815669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/P%C5%99edm%C4%9B%C5%99ice_nad_Labem_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý modrý a zvlněný bílý o třech vrcholech a dvou prohlubních, v poměru 6:1:1. Do červeného pruhu vyskakuje k vlajícímu okraji obrácený bílý dvouocasý lev se žlutou korunou a zbrojí, držící žluté palečné kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře sníženě vlnitě dělený štít, nahoře od kýt vyrůstající doleva obrácený stříbrný korunovaný dvouocasý lev se zlatou zbrojí, držící zlaté palečné kolo, dole dvě stříbrná vlnitá břevna."
    },
    {
        obec: "Předmíř",
        kod: 551627,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38742,
        latitude: 49.489369,
        longitude: 13.770472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/P%C5%99edm%C3%AD%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "Kosmo a šikmo dělený list. Horní pole žluté, žerďové a vlající pole zelená se žlutým mlýnským kolem, dolní pole modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít kosmo čtvrcený. V horním zlatém poli čelně černá oslí hlava s červeným jazykem, v pravém a levém zeleném poli zlaté mlýnské kolo, v dolním modrém poli stříbrný kapr."
    },
    {
        obec: "Přední Výtoň",
        kod: 545716,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38273,
        latitude: 48.631244,
        longitude: 14.16459,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/P%C5%99edn%C3%AD_V%C3%BDto%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 2 : 1. V bílém pruhu černý havran s hnědým bochníkem chleba v zobáku, stojící na červené růži se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zvýšenou stříbrnou vlnitou hlavou vyrůstající stříbrná věž s cimbuřím, v ní černý havran s bochníkem chleba přirozené barvy v zobáku stojící na červené růži se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Přední Zborovice",
        kod: 536792,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.218622,
        longitude: 13.892282,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/P%C5%99edn%C3%AD_Zborovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, vlnitý bílý a červený, v poměru 5 : 1 : 5. V modrém pruhu bílý keltský meč se žlutým jílcem hrotem k dolnímu okraji listu, v červeném tři bílé maltézské kříže pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně stříbrným vlnitým kůlem polceném štítě vpravo postavený stříbrný keltský meč se zlatým jílcem, vlevo tři stříbrné maltézské kříže pod sebou."
    },
    {
        obec: "Předotice",
        kod: 509621,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.365038,
        longitude: 14.053525,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/P%C5%99edotice_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle cihlovaný list (3x3); na rozhraní třetin nahoře vodorovně klíč zuby dolů a k vlajícímu okraji, dole vodorovně meč hrotem k vlajícímu okraji, oboje opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně šachovaném štítě o devíti polích vpravo klíč zuby do středu, vlevo meč, obojí postavené a opačných barev."
    },
    {
        obec: "Předslav",
        kod: 557005,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.447575,
        longitude: 13.354485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/P%C5%99edslav_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový zubatý pruh široký třetinu délky listu s pěti čtvercovými zuby a čtyřmi stejnými mezerami a zelené pole se šesti bílými šesticípými hvězdami do kruhu (2, 2, 2), mezi nimi žlutý meč hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou cimbuřovou hlavou postavený zlatý meč provázený při okraji štítu šesti (3, 3) stříbrnými hvězdami."
    },
    {
        obec: "Předslavice",
        kod: 551635,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.132138,
        longitude: 13.935156
    },
    {
        obec: "Přehořov",
        kod: 552895,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.247699,
        longitude: 14.756334,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/P%C5%99eho%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým kosmým pruhem vycházejícím z první až třetí osminy žerďového okraje do sedmé až jedenácté jedenáctiny dolního okraje listu. V pruhu modrý květ lnu mezi dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky. Ve vlajícím poli žlutý papoušek s červenou zbrojí držící v pravém pařátu žluté jablko se stopkou a listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížené kosmé stříbrné břevno s modrým květem lnu mezi dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky. Nad břevnem zlatý papoušek s červenou zbrojí držící v pravém pařátu zlaté jablko se stopkou a jedním listem."
    },
    {
        obec: "Přehvozdí",
        kod: 513369,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 50.027508,
        longitude: 14.840073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/P%C5%99ehvozd%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 3 : 1. V zeleném pruhu dva listnaté stromy nad žebřinovým vozem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dva listnaté stromy nad žebřinovým vozem, vše zlaté."
    },
    {
        obec: "Přehýšov",
        kod: 559393,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.702736,
        longitude: 13.126957,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/P%C5%99eh%C3%BD%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré pětiúhelníkové pole vycházející z poloviny žerďového a vlajícího okraje s vrcholem na středu horního okraje listu. V poli mezi rozpolcenou žlutou lilií žlutá berla obtočená bílým sudariem se žlutým třepením a černým křížkem. Horní žerďový trojúhelník červený s bílým křížem, horní vlající trojúhelník žlutý s černým křížem, oba heroldské s rameny širokými desetinu šířky listu a s kratšími rameny k žerďovému a vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zlatě polceném štítě zvýšený modrý hrot provázený vpravo stříbrným, vlevo černým heroldským křížem. V něm mezi rozpolcenou zlatou lilií zlatá berla obtočená stříbrným sudariem se zlatým třepením a černým křížkem."
    },
    {
        obec: "Přechovice",
        kod: 536547,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.179643,
        longitude: 13.894871,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/P%C5%99echovice%2C_znak.gif",
        coatOfArmsFlagDescription: "Modrý list s průčelím domu s barokním štítem a třemi prázdnými okny provázené nahoře lilií a zvonem, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě průčelí domu s barokním štítem a třemi prázdnými okny provázené nahoře vpravo lilií a vlevo zvonem, vše stříbrné."
    },
    {
        obec: "Přelíc",
        kod: 532771,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27305,
        latitude: 50.20572,
        longitude: 14.045964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/P%C5%99el%C3%ADc_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo dělený list. V žerďovém poli vykořeněné bílé lekno, ve vlajícím poli tři (2, 1) obrácené červené rolničky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě nahoře tři (2, 1) obrácené červené rolničky, dole stříbrné vykořeněné lekno."
    },
    {
        obec: "Přelouč",
        kod: 575500,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.039948,
        longitude: 15.560413,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Znak_m%C4%9Bsta_P%C5%99elou%C4%8D.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří střídavě čtyři žluté a tři černé pruhy o stejné šířce; poměr délky k šířce je 3:2."
    },
    {
        obec: "Přelovice",
        kod: 575518,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.072887,
        longitude: 15.614899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/P%C5%99elovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a žlutý, v poměru 5:1:5. V modrém pruhu zkřížené žluté berly s bílými sudarii, ve žlutém pruhu černá zubří hlava se žlutými rohy a houžví a s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá levá čtvrť, v ní černá zubří hlava se zlatými rohy a houžví a s červeným jazykem. Vpravo nahoře zkřížené zlaté berly se stříbrnými sudarii, dole stříbrné vlnité břevno."
    },
    {
        obec: "Přemyslovice",
        kod: 589934,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79851,
        latitude: 49.556472,
        longitude: 16.955914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/P%C5%99emyslovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, bílý, zelený a bílý, v poměru 3 : 1 : 1 : 1. V modrém pruhu žlutá šesticípá hvězda nad bílou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot se zeleným jehličnatým stromem přirozené barvy, s kmenem proloženým zkříženými modrými sekyrami na zlatých topůrkách. Hrot provázen vpravo zlatou hvězdou a vlevo stříbrnou lilií."
    },
    {
        obec: "Přepeře",
        kod: 577413,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51261,
        latitude: 50.583032,
        longitude: 15.112901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/P%C5%99epe%C5%99e_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a modré, prostřední vlnité se třemi vrcholy a dvěma prohlubněmi. Ve žluto-černo-červeno-žlutě kosmo děleném karé bílá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve sníženě vlnitě děleném štítě nahoře ve zlato-červeno-černo-zlatě kosmo děleném poli stříbrná svatojakubská mušle, dole třikrát stříbrno-modře vlnitě dělené pole."
    },
    {
        obec: "Přepeře",
        kod: 570923,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.467724,
        longitude: 15.101992
    },
    {
        obec: "PŘEROV",
        kod: 511382,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.456579,
        longitude: 17.45033,
        coatOfArmsFlagDescription: "Žluto-modře čtvrcený list. Uprostřed znak města. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Přepychy",
        kod: 576689,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51732,
        latitude: 50.235959,
        longitude: 16.106634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Coat_of_Arms_P%C5%99epychy.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a modrý, v poměru 8 : 1 : 1. Z prostředních tří pětin zeleného pruhu vyniká zelený vrch do třetí pětiny šířky listu, na něm tři zelené listnaté stromy s hnědými kmeny. Ze střední třetiny modrého pruhu vyniká do vrchu žluté oboustranně stupňovité pole o třech stupních. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném vrchu tři listnaté stromy přirozené barvy. Ve vrchu nad sníženou modrou vlnitou patou snížený zlatý stupňovitý hrot o čtyřech stupních."
    },
    {
        obec: "Přepychy",
        kod: 575526,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.106455,
        longitude: 15.519991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/P%C5%99epychy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, modrý, červený a bílý, v poměru 1 : 2 : 2 : 1. V modrém pruhu k vlajícímu okraji listu obrácená žlutá hlava páva s krkem postrkaným od žerdi čtyřmi pavími pery, v červeném pruhu bílý zvon se žlutým závěsem a srdcem ve tvaru tlapatého kříže, pod ním šikmo volné bílé vlčí zuby. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. V pravém poli doleva obrácená zlatá hlava páva s krkem zprava postrkaným čtyřmi pavími pery přirozené barvy. V levém poli stříbrný zvon se zlatým závěsem a srdcem ve tvaru tlapatého kříže, pod ním volné stříbrné vlčí zuby."
    },
    {
        obec: "Přerov nad Labem",
        kod: 537721,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28916,
        latitude: 50.16037,
        longitude: 14.825129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Znak_Prerov_nad_Labem.svg"
    },
    {
        obec: "Přerubenice",
        kod: 598577,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.217271,
        longitude: 13.841132,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/P%C5%99erubenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 3 : 2. Uprostřed červený tatarský klobouk s bílým ohrnutým a uprostřed prostřiženým lemem, nahoře postrkaným trojicí černých pštrosích per a dvěma zelenými dubovými listy ze stran. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně sníženě děleném štítě červený tatarský klobouk se stříbrným ohrnutým a uprostřed prostřiženým lemem, nahoře postrkaným trojicí černých pštrosích per a dvěma zelenými dubovými listy ze stran."
    },
    {
        obec: "Přeskače",
        kod: 550078,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 49.01253,
        longitude: 16.107001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Preskace_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a bílý, v poměru 1:2:1. V červeném pruhu položená bílá levá rukavice - plechovice s červeným křížkem držící dva žluté obilné klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo položená stříbrná levá rukavice - plechovice s červeným křížkem držící dva zlaté obilné klasy, vlevo stříbrné břevno s modrou položenou radlicí, provázené dvěma zlatými růžemi s červenými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Přestanov",
        kod: 530620,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40317,
        latitude: 50.686777,
        longitude: 13.920379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/Prestanov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed listu bílá socha bohyně Niké. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná socha bohyně Niké."
    },
    {
        obec: "Přestavlky",
        kod: 517224,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.390351,
        longitude: 17.481629,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/P%C5%99estavlky_PR.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý v poměru 4:1:4. V červeném pruhu žlutý řemdich. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vyniká z levého okraje stříbrná obrněná, v lokti ohnutá paže držící zlatý řemdich."
    },
    {
        obec: "Přestavlky",
        kod: 572110,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.938074,
        longitude: 15.92634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/P%C5%99estavlky_CR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a červený, v poměru 1 : 3 : 1. Ve žlutém pruhu šikmo červený vlčí hák provázený nahoře černou zubří hlavou s červeným jazykem, dole černou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě šikmo červený vlčí hák provázený nahoře zubří hlavou, dole lilií, obojí černé."
    },
    {
        obec: "Přestavlky",
        kod: 530131,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.59986,
        longitude: 13.244478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/P%C5%99estavlky_%28Plze%C5%88-South_District%29_CoA_v2.png",
        coatOfArmsFlagDescription: "List bílo-černě dělený žlutým šikmým pruhem vycházejícím z první dvanáctiny dolního okraje listu do poslední dvanáctiny horního okraje listu. V bílém poli černá vlčí hlava s krkem, v černém poli kosmo žlutý klíč zuby dolů a k vlajícímu okraji listu, šikmo přeložený bílým mečem se žlutým jílcem hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-černě polceném štítě pod zlatou hlavou s trojím černým jelením parožím vpravo černá vlčí hlava s krkem, vlevo kosmo postavený zlatý klíč zuby doleva šikmo přeložený postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Přestavlky",
        kod: 565474,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.403615,
        longitude: 14.193965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/ZnakP%C5%99estavlkyLT.jpg",
        coatOfArmsFlagDescription: "List tvoří žluto-modře čtvrcený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, žlutý a modrý. Ve žlutém žerďovém poli kalich s hostií, oboje červené. V horním modrém poli bílý vlk ve skoku s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo kalich s hostií, obojí červené, vlevo stříbrný vlk ve skoku s červeným jazykem."
    },
    {
        obec: "Přestavlky u Čerčan",
        kod: 530492,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25723,
        latitude: 49.8531,
        longitude: 14.74423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/P%C5%99estavlky_u_%C4%8Cer%C4%8Dan%2C_CoA_%28crop_%E2%80%93_cal%29.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a červený. Ve žlutém pruhu šedá utržená vlčí hlava s bílou zbrojí a červeným jazykem hledící k vlajícímu okraji listu, v červeném pruhu bílá zavinutá střela. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - červeně polceném štítě vpravo doleva obrácená utržená vlčí hlava s krkem přirozené barvy, vlevo stříbrná zavinutá střela."
    },
    {
        obec: "Přešovice",
        kod: 591441,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67557,
        latitude: 49.050205,
        longitude: 16.061445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Presovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a bílý. V modrém kosmo žlutý gotický klíč zuby dolů a k vlajícímu okraji, šikmo podložený bílým mečem se žlutým jílcem hrotem dolů. V bílém pruhu utržená červená supí hlava se žlutým zobákem a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo kosmo postavený zlatý gotický klíč zuby nahoru, šikmo podložený stříbrným mečem se zlatým jílcem, vlevo utržená červená supí hlava se zlatým zobákem a červeným jazykem."
    },
    {
        obec: "Přeštěnice",
        kod: 549797,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.479763,
        longitude: 14.42045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/P%C5%99e%C5%A1t%C4%9Bnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a pilovitý bílý, v poměru 3 : 1. Bílý pruh má pět trojúhelníkových zubů sahajících do tří pětin šířky listu, V modrém pruhu dvě žluté zkřížené berly s bílými vlajícími sudarii. V bílém pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě zkřížené zlaté berly s vlajícími stříbrnými sudarii nad stříbrnou pilovitou patou s červenou růží se zlatým semeníkem."
    },
    {
        obec: "Přeštice",
        kod: 558249,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.573091,
        longitude: 13.333607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/P%C5%99e%C5%A1tice_-_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, černý, bílý, žlutý, bílý a černý, v poměru 2 : 5 : 1 : 5 : 2. V bílých pruzích ke žlutému pruhu přivrácená černá korunovaná orlice se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Přešťovice",
        kod: 551643,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.277278,
        longitude: 13.974442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/P%C5%99e%C5%A1%C5%A5ovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutou kaplí s černým vchodem a dvěma bílými bočními sloupy, s červenou stanovou střechou a lucernou s černým oknem a křížkem. Kaple provázena třemi (2, 1) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá kaple s černým vchodem a dvěma stříbrnými bočními sloupy, s červenou stanovou střechou a lucernou s černým oknem a křížkem. Kaple provázena třemi (2, 1) zlatými hvězdami."
    },
    {
        obec: "Převýšov",
        kod: 570681,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.151634,
        longitude: 15.410541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Znak_obce_P%C5%99ev%C3%BD%C5%A1ov.gif",
        coatOfArmsFlagDescription: "List tvoří červeno-modro-zeleně obráceně vidlicovitě dělené pole a tři vodorovné pruhy, vlnitý bílý, vlnitý modrý a zvlněný bílý. Poměr polí a pruhů na žerďovém a vlajícím okraji je 11:1:1:1. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě na stříbrné vlnité patě s modrým vlnitým břevnem zelený vrch, vpravo vinný hrozen na stonku s jedním listem, vlevo vykořeněný dub, obojí zlaté."
    },
    {
        obec: "Přezletice",
        kod: 538671,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25073,
        latitude: 50.155303,
        longitude: 14.575506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Prezletice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žluto modře čtvrcený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, žlutý a modrý. Ve žlutém žerďovém poli vztyčený modrý křížový hrot loveckého oštěpu, v horním modrém poli žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo vztyčený modrý křížový hrot loveckého oštěpu, vlevo zlatý obilný klas se dvěma listy."
    },
    {
        obec: "Přibice",
        kod: 584843,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69124,
        latitude: 48.961724,
        longitude: 16.57344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/P%C5%99ibice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 6:1:3. V žerďové polovině horního modrého pruhu žlutý vinný hrozen se dvěma listy, stonkem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným vlnitým břevnem stříbrný kostel s věží, červenými střechami, černými okny a dveřmi. Kostel je provázený vlevo nahoře položeným zlatým vinným hroznem se dvěma listy."
    },
    {
        obec: "Příbor",
        kod: 599808,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74258,
        latitude: 49.641036,
        longitude: 18.145096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Coat_of_arms_of_P%C5%99%C3%ADbor.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, červený a bílý v poměru 1:3:1. V červeném pruhu tři žluté krokve. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Příbram",
        kod: 539911,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.685532,
        longitude: 13.999045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/P%C5%99%C3%ADbram_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený v poměru 3:1:3. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Příbram na Moravě",
        kod: 583715,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.195583,
        longitude: 16.299175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/P%C5%99%C3%ADbram_na_Morav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou čelně postavenou radlicí, v ní modrý klín s vrcholem na horním okraji radlice a v dolní části červené plameny. Radlice je převýšena položeným žlutým obilným klasem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě čelně stříbrná radlice, v ní modrý hrot a v dolní části červené plameny. Radlice je převýšena položeným zlatým obilným klasem."
    },
    {
        obec: "Příbraz",
        kod: 561088,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.051469,
        longitude: 14.937708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/P%C5%99%C3%ADbraz_CoA_v2.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a červený bíle kvádrovaný. V modrém pruhu u žerdi žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky nad červenou, stříbrně kvádrovanou zdí se zkříženými vztyčenými zlatými rýči, kosmým zakončeným rovně a šikmým špičatým."
    },
    {
        obec: "Přibyslav",
        kod: 574368,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.373743,
        longitude: 16.16869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/P%C5%99ibyslav_%28N%C3%A1chod_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 8 : 1. V bílém pruhu vykořeněná zelená lípa s hnědým kmenem provázená nahoře mezi větvemi černými zkříženými ostrvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vykořeněná lípa přirozené barvy provázená nahoře mezi větvemi černými zkříženými ostrvemi."
    },
    {
        obec: "Přibyslav",
        kod: 569321,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.576939,
        longitude: 15.73865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Znak_m%C4%9Bsta_P%C5%99ibyslav_hires.svg",
        coatOfArmsFlagDescription: "List tvoří svislý červený žerďový pruh široký tři sedminy délky listu a dva vodorovné pruhy, modrý a žlutý. V červeném pruhu zkřížené bílé vinařské nože se žlutými rukojeťmi, převýšené červenou knížecí korunou. V modrém poli tři šedé ryby s červenými ploutvemi nad sebou, ve žlutém pruhu zkřížené černé ostrve. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v červeném poli zkřížené stříbrné vinařské nože se zlatými rukojeťmi převýšené knížecí korunou, dolní polovina modro zlatě polcena, vpravo tři ryby přirozené barvy nad sebou, vlevo zkřížené černé ostrve."
    },
    {
        obec: "Přibyslavice",
        kod: 583723,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.278696,
        longitude: 16.266436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/CoA_of_P%C5%99ibyslavice%28okres_Brno-venkov%29.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým vozovým kolem o průměru rovném osmi devítinám šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlaté vozové kolo."
    },
    {
        obec: "Přibyslavice",
        kod: 591459,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.260738,
        longitude: 15.778435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Pribyslavice_TR_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Ze středu dolního okraje listu vyrůstá bílý patriarší kříž vysoký tři čtvrtiny šířky listu s rameny širokými desetinu šířky listu, provázený nahoře v modrém pruhu bílou růží s prázdným semeníkem a v červeném pruhu bílou korunovanou lví hlavou se žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vyrůstající stříbrný patriarší kříž provázený nahoře vpravo stříbrnou růží s prázdným semeníkem a vlevo korunovanou stříbrnou lví hlavou se zlatým jazykem."
    },
    {
        obec: "Příčina",
        kod: 542253,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.065833,
        longitude: 13.667379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/CoA_of_P%C5%99%C3%AD%C4%8Dina.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Uprostřed volný kopec opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě volný kopec opačných barev."
    },
    {
        obec: "Příčovy",
        kod: 513547,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.672874,
        longitude: 14.389683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Coats_of_arms_of_Pricovy.jpeg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými jednu čtvrtinu šířky listu. Ve středu kříže červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou s červenou růží se zlatým semeníkem a zelenými kališními lístky stříbrný zděný větrný mlýn na zeleném návrší."
    },
    {
        obec: "Přídolí",
        kod: 545724,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38101,
        latitude: 48.781912,
        longitude: 14.35229,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Pridoli_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým klínem vycházejícím z horního okraje listu s vrcholem na dolním okraji listu. V klínu sv. Vavřinec v bílé albě a modré dalmatice, pravicí se opírá o postavený černý rošt a v levici drží zelenou palmovou ratolest. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě sv. Vavřinec ve stříbrné albě a modré dalmatice, pravicí se opírá o postavený černý rošt a v levici drží zelenou palmovou ratolest."
    },
    {
        obec: "Příchovice",
        kod: 558257,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.562138,
        longitude: 13.33946,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Prichovice_znak.gif",
        coatOfArmsFlagDescription: "Červený list, nahoře v žerďové polovině ze žluté koruny vyrůstají tři bílé husí krky se žlutými zobáky, nahoře ve vlající polovině bílá korunovaná orlice se žlutou zbrojí, v dolní části listu vodorovně bílé pádlo držadlem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nahoře vedle sebe ze zlaté koruny vyrůstající tři stříbrné husí krky se zlatými zobáky a stříbrná korunovaná orlice se zlatou zbrojí, pod nimi šikmo položené stříbrné pádlo."
    },
    {
        obec: "Příkazy",
        kod: 505013,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78333,
        latitude: 49.642471,
        longitude: 17.148305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Znak_obce_P%C5%99%C3%ADkazy.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu s bílým orientálním mečem se žlutým jílcem a záštitou, hrotem nahoru a ostřím k žerdi, a červené pole se čtyřmi bílými vlajícími klíny s vrcholy na okraji modrého pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod modrou hlavou se stříbrným orientálním mečem se zlatým jílem v červeném štítě čtyři stříbrné kužele."
    },
    {
        obec: "Příkosice",
        kod: 579131,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.668377,
        longitude: 13.663557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/P%C5%99%C3%ADkosice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Ve střední části listu zkřížené odvrácené žluté šavle hroty k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo zkřížené odvrácené postavené zlaté šavle, vlevo stříbrná lilie."
    },
    {
        obec: "Příkrý",
        kod: 577421,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.625029,
        longitude: 15.364623
    },
    {
        obec: "Přílepy",
        kod: 565261,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27001,
        latitude: 50.123281,
        longitude: 13.632702
    },
    {
        obec: "Přílepy",
        kod: 549720,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.320079,
        longitude: 17.616021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/P%C5%99%C3%ADlepy_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1:4:1. Uprostřed žlutý korunovaný lev s červenou zbrojí držící bílou věž s ochozem na kvádrovaném soklu, s třemi černými okny (2,1), nárožními věžičkami a valbovou střechou, střechy červené zakončená žlutými makovičkami s bílými praporky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý korunovaný lev s červenou zbrojí držící stříbrnou věž s ochozem na kvádrovaném soklu, s třemi černými okny (2,1), nárožními věžičkami a valbovou střechou, střechy červené zakončené zlatými makovičkami se stříbrnými praporky."
    },
    {
        obec: "Příluka",
        kod: 578622,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.864971,
        longitude: 16.15664,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/P%C5%99%C3%ADluka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený s bílou lilií ve středu a bílý, v poměru 7 : 6 : 7. V horním rohu a dolním cípu vztyčený zelený lipový list s plodenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - stříbrně polceném štítě pod zelenou hlavou se stříbrnou lilií dva vztyčené přivrácené zelené lipové listy s plodenstvím."
    },
    {
        obec: "Přimda",
        kod: 561151,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34806,
        latitude: 49.674989,
        longitude: 12.673772,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Primda_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list, na jeho dolním okraji zelené trojvrší, z něhož vyrůstá lípa se zelenou korunou a hnědým kmenem, na který šplhají ze stran dva černí medvědi s červenou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Přísečná",
        kod: 545732,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38101,
        latitude: 48.833173,
        longitude: 14.345507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/P%C5%99%C3%ADse%C4%8Dn%C3%A1-znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, bílý, žlutý a zelený, v poměru 3 : 1 : 8 : 1 : 3. V bílém pruhu proti sobě dva přeseknuté vykořeněné zelené smrky s hnědými kmeny. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně modrým vlnitým břevnem děleném štítě nahoře dva proti sobě přeseknuté vykořeněné smrky přirozené barvy, dole zlatá lilie."
    },
    {
        obec: "Příseka",
        kod: 569348,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.670936,
        longitude: 15.425737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/P%C5%99%C3%ADseka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, žlutý, bílý, zelený, bílý a žlutý, v poměru 2 : 1 : 6 : 1 : 2. V zeleném pruhu žlutý obilný snop, nad ním bílá šestilistá růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě mezi dvěma vztyčenými přivrácenými stříbrnými sekerami na zlatých topůrkách stříbrná šestilistá růže se zlatým semeníkem nad zlatým obilným snopem dole podloženým stříbrným zúženým vlnitým břevnem."
    },
    {
        obec: "Přísnotice",
        kod: 583731,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66463,
        latitude: 49.00323,
        longitude: 16.61361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/P%C5%99%C3%ADsnotice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. Ze čtvrté čtvrtiny žerďového okraje listu vychází šikmý zelený pruh do druhé čtvrtiny žerďového okraje zeleného pruhu. Ze třetí čtvrtiny vlajícího okraje bílého pruhu vychází šikmý bílý pruh do první čtvrtiny vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě kosmá zlatá zvýšená břevna, provázená nahoře dvouramenným křížem, dole šikmo dolů obráceným vinařským nožem - kosířem, kosmo přeloženým radlicí, vše stříbrné."
    },
    {
        obec: "Přistoupim",
        kod: 513393,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.054216,
        longitude: 14.878688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/P%C5%99istoupim_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. V zeleném knížecí koruna v mezikruží se zavěšeným zvonem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě knížecí koruna v kruhu se zavěšeným zvonem přerušujícím palisádovou hradbu, vše zlaté."
    },
    {
        obec: "Přišimasy",
        kod: 533611,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.049615,
        longitude: 14.764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/P%C5%99i%C5%A1imasy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3 : 5. V žerďové polovině modrého pruhu hnědý letící skřivan, pod ním v zeleném pruhu žlutý obilný snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným vrchem se zlatým snopem letící skřivan přirozené barvy."
    },
    {
        obec: "Příšov",
        kod: 567086,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.811806,
        longitude: 13.304241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/P%C5%99%C3%AD%C5%A1ov_coA_01.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým červeným pruhem, vycházejícím z první čtvrtiny dolního okraje listu do horního cípu, na žerďové žluté a zelené vlající pole. Ve žlutém poli černo-bíle dělené býčí rohy, v zeleném poli bílá sopka s černým kráterem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně červeným šikmým břevnem děleném štítě, nahoře černo-stříbrně dělené býčí rohy, dole stříbrná sopka s černým kráterem."
    },
    {
        obec: "Příšovice",
        kod: 564354,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46346,
        latitude: 50.578232,
        longitude: 15.084005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Prisovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený a střídavě bílé a modré, v poměru 5:1:1:1:1:1. V žerďové polovině červeného pruhu bílý vykračující lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře stříbrný vykračující lev se zlatou zbrojí, dole dvě modrá břevna."
    },
    {
        obec: "Příštpo",
        kod: 544752,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67551,
        latitude: 49.073941,
        longitude: 15.935004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Coat_of_arms_of_P%C5%99%C3%AD%C5%A1tpo.jpg",
        coatOfArmsFlagDescription: "Bílý list se šedým kamenným návrším na dolním okraji listu, z něhož vyrůstají do oblouku čtyři zelené jedle s hnědými kmeny, krajní nižší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě z kamenného návrší vyrůstají do oblouku čtyři jedle, krajní nižší, vše přirozené barvy."
    },
    {
        obec: "Přítluky",
        kod: 584851,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69104,
        latitude: 48.855089,
        longitude: 16.774055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Coat_of_arms_of_Pritluky.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 2 : 1. V červeném pruhu kolem bílého kolíku obtočená žlutá vinná réva se dvěma svěšenými hrozny a dvěma vztyčenými listy a bílý vinařský nůž - kosíř ostřím k žerdi se žlutou rukojetí, vše provázeno nahoře a dole bílým přirozeným květem lilie se žlutými pestíky. V modrém pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře sníženě děleném štítě nahoře vpravo kolem stříbrného kolíku obtočená zlatá vinná réva se dvěma svěšenými hrozny a dvěma vztyčenými listy, vlevo stříbrný vinařský nůž - kosíř se zlatou rukojetí, vše provázeno nahoře a dole stříbrným přirozeným květem lilie se zlatými pestíky, dole zlaté mlýnské kolo."
    },
    {
        obec: "Přívětice",
        kod: 560111,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.834798,
        longitude: 13.612239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/P%C5%99%C3%ADv%C4%9Btice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žluto-bíle polcený, v poměru 1 : 2. V bílém poli dva modré vlnité pruhy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-stříbrně polcený štít s červenou hlavou. V hlavě šikmo vztyčený meč kosmo přeložený zvlněným pláštěm, vše stříbrné. V pravém poli černá plavuně, v levém poli tři modrá vlnitá břevna."
    },
    {
        obec: "Přívrat",
        kod: 580821,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56002,
        latitude: 49.924704,
        longitude: 16.39707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/P_znak.jpg",
        coatOfArmsFlagDescription: "Vodorovně žluto-modře dělený list. Horní žluté pole s tokajícím tetřívkem přechází v polovině žerďového a vlajícího okraje do vydutého klínu, jehož vrchol je na středu dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře snížený zkráceným vydutým klínem dělený štít. Nahoře na větvi tokající tetřívek přirozené barvy. Dole vpravo kapr, vlevo štika, obojí stříbrné a přivrácené."
    },
    {
        obec: "Psárov",
        kod: 563374,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.319003,
        longitude: 14.905589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Ps%C3%A1rov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové polovině bílého pruhu černá psí hlava se žlutým obojkem s kroužkem, pod ní v červeném pruhu tři žluté spojené obilné klasy, krajní odkloněné s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře černá psí hlava se zlatým obojkem s kroužkem, dole tři zlaté spojené obilné klasy, krajní odkloněné s listem."
    },
    {
        obec: "Psáry",
        kod: 539597,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25244,
        latitude: 49.93632,
        longitude: 14.512867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Psary_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů, zelený a střídavě červené a bílé, v poměru 2 : 1 : 1 : 4 : 1 : 1. V zeleném pruhu bílý meč se žlutým jílcem hrotem nahoru, v prostředním červeném pruhu bílý kráčející ohlížející se pes se žlutou zbrojí, jazykem a obojkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zelenou hlavou se stříbrným položeným mečem se zlatým jílcem stříbrný kráčející ohlížející se pes se zlatou zbrojí, jazykem a obojkem mezi dvěma vyrůstajícími stříbrnými kvádrovanými věžemi s cimbuřím a černým gotickým oknem."
    },
    {
        obec: "Psáře",
        kod: 530514,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.755783,
        longitude: 14.96193,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Ps%C3%A1%C5%99e_CoA.png",
        coatOfArmsFlagDescription: "List dělený žlutým dolním klínem na modré žerďové a zelené vlající pole. V klínu černá psí hlava s bílým obojkem, v modrém poli osmicípá hvězda, v zeleném vztyčený dubový list, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě zlatý hrot s černou psí hlavou se stříbrným obojkem, provázený vpravo osmihrotou hvězdou, vlevo vztyčeným dubovým listem, obojí zlaté."
    },
    {
        obec: "Pstruží",
        kod: 552577,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73911,
        latitude: 49.564927,
        longitude: 18.347648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Pstruzi_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy v poměru 3:1:2, zelený s bílým kozlem v žerďové části, červený se třemi bílými rovnoramennými trojúhelníky a zelený s bílým pstruhem v žerďové části. Trojúhelníky mají základny široké jednu třetinu délky listu umístěné na dolním okraji červeného pruhu a vrcholy na horním okraji červeného pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě červené břevno se třemi stříbrnými kuželi. Nahoře vykračující stříbrný kozel, dole stříbrný pstruh."
    },
    {
        obec: "Pšánky",
        kod: 530671,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.294187,
        longitude: 15.620991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/P%C5%A1%C3%A1nky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký třetinu délky listu a modré pole se třemi kosmými bílými pruhy, prostřední dvojnásobné šířky. Z dolního okraje červeného pruhu vyniká žlutá vidlicová zvonička se zvonem a s černou stanovou stříškou. V prostředním bílém pruhu tři červené růže se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo vyniká zlatá vidlicová zvonička se zvonem a s černou stanovou stříškou, vlevo tři stříbrná kosmá břevna, v prostředním rozšířeném tři červené růže se zlatými semeníky."
    },
    {
        obec: "Pšov",
        kod: 555525,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36452,
        latitude: 50.051663,
        longitude: 13.170783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Psov_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu bílý maltézský kříž přeložený červeným srdcem. Srdce je ovinuto žlutou trnovou korunou, hoří čtyřmi žlutými plameny mezi rameny kříže a vyrůstá z něj černý latinský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný maltézský kříž přeložený červeným srdcem. Srdce je ovinuto zlatou trnovou korunou, hoří čtyřmi zlatými plameny mezi rameny kříže a vyrůstá z něj černý latinský kříž."
    },
    {
        obec: "Pšovlky",
        kod: 542270,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27031,
        latitude: 50.107947,
        longitude: 13.594013,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Coats_of_arms_P%C5%A1ovlky.jpeg",
        coatOfArmsDescription: "Dělený štít, horní polovina zlato-červeně polcena, vpravo na zeleném dvouvrší dva jehličnaté stromy přirozené barvy, vlevo dvě zkřížená zlatá kopí, v dolním modrém poli doleva kráčející stříbrný vlk."
    },
    {
        obec: "Ptení",
        kod: 589942,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79843,
        latitude: 49.511729,
        longitude: 16.961205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Pten%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený, bílý, černý, žlutý, černý, bílý a červený, v poměru 5:4:4:4:4:4:5. Uprostřed kosmo položený modrý čtverec dotýkající se okraje červených pruhů se třemi (2,1) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní pole polcené, vpravo červené a vlevo třikrát stříbrno-černě dělené, uprostřed zlatá hlava jednorožce s krkem, v dolním modrém poli horní půlka vozového kola mezi třemi hvězdami, vše zlaté."
    },
    {
        obec: "Ptenín",
        kod: 558265,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33452,
        latitude: 49.531607,
        longitude: 13.18506
    },
    {
        obec: "Ptice",
        kod: 532789,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25218,
        latitude: 50.05287,
        longitude: 14.166076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Ptice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, černo-červeně polcený, žluto-bíle polcený a černo-červeně polcený, v poměru 5 : 1 : 1 : 1. V bílém pruhu kosmo do horního rohu a šikmo do vlajícího cípu letící černá vlaštovka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Sníženě dělený štít. Nahoře ve stříbrném poli kosmá a šikmá letící vlaštovka přirozené barvy, dolní pole polceno černo-červeně, vpravo se zlatým a vlevo se stříbrným břevnem."
    },
    {
        obec: "Ptýrov",
        kod: 571938,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.504933,
        longitude: 14.947236,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Pt%C3%BDrov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, v poměru 12 : 1 : 1 : 1 : 1, střídavě bílých a modrých vlnitých se třemi vrcholy a dvěma prohlubněmi. Z horního modrého pruhu vyrůstá zelený dub s černým kmenem, provázený u horního okraje listu pěti černými siluetami čelně letících ptáků vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě z třikrát modro-stříbrně vlnitě dělené vlnité paty vyrůstající zelený dub s černým kmenem provázený nahoře pěti (3, 2) černými siluetami čelně letících ptáků."
    },
    {
        obec: "Puclice",
        kod: 554154,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.553725,
        longitude: 13.019504,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Puclice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými makovicemi na stoncích vyrůstajících z dolní poloviny bílé lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě tři zlaté makovice na stoncích vyrůstajících z dolní poloviny stříbrné lilie."
    },
    {
        obec: "Pucov",
        kod: 591491,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.247148,
        longitude: 16.174175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Pucov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1:1:2. V žerďové polovině dolního červeného pruhu bílý džber. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít červeno - modro - červeně dělený. Nahoře stříbrné vahadlo, uprostřed zkřížené sekery - bradatice na zlatých topůrkách, dole stříbrný džber."
    },
    {
        obec: "Puchlovice",
        kod: 573531,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.185916,
        longitude: 15.626417,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Coat_of_arms_of_Puchlovice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy v poměru 3 : 6 : 1 : 2, žluto-černě šachovaný (3 x 18), modrý s pěti šesticípými hvězdami (3, 2), bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi zlato-černě šachovanou hlavou a stříbrným vlnitým břevnem oddělenou zelenou patou pět (3, 2) zlatých hvězd."
    },
    {
        obec: "Puklice",
        kod: 587745,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58831,
        latitude: 49.374959,
        longitude: 15.700414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Puklice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, v poměru 1 : 3, červeno-bílo polcený a vlnkovaný modrý se třemi oblouky s vrcholy sahajícími do tří šestnáctin šířky listu. V modrém pruhu kosmo bílý meč se žlutým jílcem hrotem k hornímu rohu, přeložený šikmo žlutým klíčem zuby nahoru a k dolnímu cípu, obojí provázené nahoře, k žerdi a dole bílou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červeno-stříbrně polcenou, třemi oblouky oddělenou hlavou kosmo stříbrný meč se zlatým jílcem, šikmo přeložený doleva obráceným zlatým klíčem. Obojí vztyčené a provázené nahoře, vpravo a dole stříbrnou hvězdou."
    },
    {
        obec: "Pulečný",
        kod: 546577,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46802,
        latitude: 50.675488,
        longitude: 15.167986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Pule%C4%8Dn%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a tři vodorovné pruhy: zelený, žlutý a zelený v poměru 1:4:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se zlatou hvězdou ve stříbrném kruhu zlatý, zeleně lemovaný štít s rybou vrankou přirozené barvy hlavou vzhůru."
    },
    {
        obec: "Pustá Kamenice",
        kod: 578631,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56982,
        latitude: 49.756215,
        longitude: 16.085881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Pust%C3%A1_Kamenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý, v poměru 9 : 2 : 2 : 2. V červeném pruhu bílý kráčející jelen mezi dvěma volnými žlutými smrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít. Nahoře stříbrný kráčející jelen mezi dvěma volnými zlatými smrky, dole modré břevno."
    },
    {
        obec: "Pustá Polom",
        kod: 509736,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74769,
        latitude: 49.849271,
        longitude: 17.998005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Pusta_Polom_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří červený svislý pruh, široký jednu třetinu délky listu s bílým mečem hrotem dolů a sedm vodorovných pruhů střídavě bílých a zelených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrný štít se třemi zelenými krokvemi přeložený červeným kůlem, v němž je stříbrný meč hrotem dolů."
    },
    {
        obec: "Pustá Rybná",
        kod: 578649,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.714822,
        longitude: 16.1351
    },
    {
        obec: "Pustějov",
        kod: 568775,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74243,
        latitude: 49.700606,
        longitude: 18.004007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Pust%C4%9Bjov_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi žlutými snopy postavenými vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři zlaté obilné snopy postavené vedle sebe."
    },
    {
        obec: "Pustiměř",
        kod: 593508,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68321,
        latitude: 49.322614,
        longitude: 17.02803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Pustimer_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list se třemi bílými žerďovými klíny s vrcholy ve středu listu a dvěma bílými klíny s vrcholy na vlajícím okraji a se základnami mezi vrcholy žerďových klínů. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Pustina",
        kod: 580830,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.899577,
        longitude: 16.105003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Pustina_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, červený, bílý a zelený, v poměru 2 : 1 : 8 : 1 : 2. V červeném pruhu zvon provázený třemi (1, 2) osmicípými hvězdami, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ve vydutém červeném hrotu zvon provázený třemi (1, 2) osmihrotými hvězdami, vše zlaté. Hrot provázejí doleva obrácená radlice a postavená kosa bez kosiště ostřím doleva, obojí stříbrné."
    },
    {
        obec: "Pustověty",
        kod: 542288,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.055708,
        longitude: 13.814115
    },
    {
        obec: "Putim",
        kod: 549801,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.264627,
        longitude: 14.11913,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Putim_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený, bílý a modrý v poměru 2:1:1. V červeném poli žlutý rošt mezi dvěma žlutými kosmo a šikmo postavenými odvrácenými kratci (motykami) s bílými násadami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře stříbrným mostem dělený štít, nahoře zlatý rošt provázený dvěma kosmo a šikmo postavenými odvrácenými zlatými kratci (motykami) se stříbrnými násadami, dole stříbrný kapr."
    },
    {
        obec: "Putimov",
        kod: 598755,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.41393,
        longitude: 15.268871,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Coat_of_arms_of_Putimov.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové pole žluté, dolní černé, horní vlající bílé s modrým květem lnu se žlutým středem, dolní zelené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-zlato-černě dělený štít se zeleným kůlem provázeným vlevo modrým květem lnu se zlatým středem na zeleném stonku s listy. V kůlu stříbrné průčelí kaple s lucernou zakončenou křížkem."
    },
    {
        obec: "Pyšel",
        kod: 591505,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.250609,
        longitude: 16.066539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Py%C5%A1el_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v žerďové polovině listu zubatý, v poměru 11 : 5. Mezery mezi zuby jsou v druhé, čtvrté a šesté čtrnáctině délky listu a dosahují do tří čtvrtin šířky listu. V žerďové polovině červeného pruhu tři bílá supí pera, krajní odkloněná. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě tři supí pera, krajní odkloněná, nad cimbuřovou patou, vše stříbrné."
    },
    {
        obec: "Pyšely",
        kod: 538680,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25167,
        latitude: 49.876884,
        longitude: 14.677239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Pysely_znak.jpg"
    },
    {
        obec: "Rabakov",
        kod: 565652,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.38575,
        longitude: 15.101648
    },
    {
        obec: "Rabí",
        kod: 557013,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.280848,
        longitude: 13.617715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Rab%C3%AD_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý, v poměru 2 : 1. V modrém pruhu běžící hnědý vlk se šikmo položeným zeleným jelením parožím kolem krku. Bílý pruh má devět čtvercových zubů a osm stejných mezer. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kvádrovaná zeď s prázdnou branou s otevřenými vraty a vytaženou mříží, završená cimbuřím se třemi stínkami. Ze zdi vynikají tři kvádrované věže, každá s oknem a s kvádrovaným cimbuřím o třech stínkách, mříž zlatá, okna černá, vše ostatní stříbrné. Na cimbuří věží kráčející vlk přirozené barvy se zeleným jelením parožím kolem krku."
    },
    {
        obec: "Rabštejnská Lhota",
        kod: 556882,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.915662,
        longitude: 15.768153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Rab%C5%A1tejnsk%C3%A1_Lhota_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, a modrý zubatý pruh na dolním okraji listu široký osminu šířky listu s pěti zuby vysokými osminu šířky listu a šesti mezerami. V zeleném pruhu bílý zvon, v bílém pruhu zelený smrk. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě nad modrou hradbou vpravo stříbrný zvon, vlevo zelený smrk."
    },
    {
        obec: "Ráby",
        kod: 575534,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.07288,
        longitude: 15.802625,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Znak_obce_R%C3%A1by.gif",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh s černým havranem se žlutou zbrojí široký pět dvanáctin délky listu a žluto-černě šachované pole (6x5). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole zlato-černě šachované, v levém stříbrném poli černý havran se zlatou zbrojí."
    },
    {
        obec: "Rabyně",
        kod: 530522,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25208,
        latitude: 49.81429,
        longitude: 14.436202
    },
    {
        obec: "Racková",
        kod: 585670,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76001,
        latitude: 49.27717,
        longitude: 17.624991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Rackov%C3%A1znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, bílý, červený, modrý, červený, bílý a modrý v poměru 2:1:1:1:1:1:2. Na středním modrém pruhu dvě žluté šesticípé hvězdy s cípy zasahujícími do červených pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře rostoucí zlatý kanec s červenými kopyty a jazykem a stříbrnými kly, dole červeno-modro-červeně dělené břevno se dvěma zlatými hvězdami."
    },
    {
        obec: "Rácovice",
        kod: 511307,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 49.02106,
        longitude: 15.687019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Coat_of_arms_of_R%C3%A1covice.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a modré pole. Z dolního okraje pruhu vyniká žlutý procesní patriarší kříž, dole přeložený otevřenou bílou knihou ve žlutých deskách. V žerďové polovině modrého pole kapr provázený nahoře a dole vztyčeným lipovým listem s plodenstvím, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře polcený štít. Vpravo vyniká zlatý procesní patriarší kříž, dole přeložený otevřenou stříbrnou knihou ve zlatých deskách. Vlevo kapr provázený nahoře a dole vztyčeným lipovým listem s plodenstvím, vše zlaté."
    },
    {
        obec: "Račice",
        kod: 591521,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.108129,
        longitude: 16.024703,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Ra%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, zubatý bílý a zelený, v poměru 2 : 1 : 5. Bílý pruh má tři obdélníkové zuby sahající do jedné osminy šířky listu a dvě stejně široké a dvě poloviční mezery. V zeleném pruhu knížecí čepice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s červeno-stříbrně cimbuřově dělenou hlavou knížecí čepice."
    },
    {
        obec: "Račetice",
        kod: 546071,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.30526,
        longitude: 13.364964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Ra%C4%8Detice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s černým žerďovým pruhem, širokým čtvrtinu délky listu, v něm vodorovný žlutý pruh s červenou růží, široký třetinu šířky listu, podložený žlutou berlou závitem k žerďovému okraji. Ve střední části listu žlutý rak převýšený hraběcí korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-modře vpravo polcený štít. V pravém poli zlaté břevno s červenou růží podložené zlatou berlou, v levém poli zlatý rak převýšený hraběcí korunou."
    },
    {
        obec: "Račice",
        kod: 596507,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.497374,
        longitude: 16.139354,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Coat_of_arms_of_Ra%C4%8Dice_ZR.jpg",
        coatOfArmsFlagDescription: "Zeleno-modře bílým horním klínem s červeným rakem polcený list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře stříbrným klínem polcený štít. V klínu červený rak."
    },
    {
        obec: "Račice",
        kod: 599760,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27024,
        latitude: 50.024833,
        longitude: 13.923039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Ra%C4%8Dice_%28Rakovn%C3%ADk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný modrý, v poměru 7 : 1. V zeleném pruhu rak, provázený dole v žerďové části vázou a dole ve vlající části volným ohněm, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou vlnitou patou rak, provázený dole vpravo vázou a vlevo volným ohněm, vše zlaté."
    },
    {
        obec: "Račice",
        kod: 565482,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41108,
        latitude: 50.461398,
        longitude: 14.351181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ra%C4%8Dice_LI_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, v poměru 1 : 2. V červeném pruhu bílá větev vinné révy se třemi (2, 1) listy. Uprostřed listu dvě zkřížená vztyčená vesla, svisle přeložená pádlem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo stříbrná větev vinné révy se třemi (2, 1) listy, vlevo dvě zkřížená vztyčená vesla, přeložená pádlem, vše zlaté."
    },
    {
        obec: "Račice nad Trotinou",
        kod: 570702,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.315762,
        longitude: 15.796171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Ra%C4%8Dice_nad_Trotinou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý vlnitý se sedmi vrcholy a šesti prohlubněmi a bílý zvlněný, v poměru 3 : 2 : 3. Ve střední části listu červený rak. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrým vlnitým břevnem červený rak."
    },
    {
        obec: "Račín",
        kod: 588059,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59211,
        latitude: 49.617573,
        longitude: 15.866964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Coat_of_arms_of_Ra%C4%8D%C3%ADn_ZR.jpg",
        coatOfArmsFlagDescription: "V bílém listu kosmo červený rak provázený u horního a dolního okraje listu svěšeným zeleným leknínovým listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmo červený rak provázený nahoře a dole svěšeným zeleným leknínovým listem."
    },
    {
        obec: "Račice-Pístovice",
        kod: 593516,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68305,
        latitude: 49.276006,
        longitude: 16.872942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Znak_Ra%C4%8Dice-P%C3%ADstovice.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: modrý, žlutý, bílý a zelený v poměru 2:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít se stříbrným šikmým břevnem, v pravém modrém poli vyniká zlatý vykračující jelen, v levém zeleném poli stříbrná radlice."
    },
    {
        obec: "Račiněves",
        kod: 565491,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.373201,
        longitude: 14.218727,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Ra%C4%8Din%C4%9Bves_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký čtvrtinu délky listu a dva vodorovné pruhy, bílý a červený. V zeleném pruhu žlutý obilný klas se dvěma listy. Uprostřed listu korunovaný lev opačných barev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Vpravo polcený štít, v pravém zeleném poli zlatý obilný klas se dvěma listy, v levém stříbrno-červeně děleném poli korunovaný lev opačných barev se zlatou zbrojí."
    },
    {
        obec: "Radčice",
        kod: 563773,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.672861,
        longitude: 15.277749
    },
    {
        obec: "Radějov",
        kod: 586501,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69667,
        latitude: 48.859476,
        longitude: 17.34472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Rad%C4%9Bjov_CoA_HO_CZ.jpg",
        coatOfArmsFlagDescription: "List rozdělený černým šikmým pruhem širokým jednu čtvrtinu šířky listu na červené žerďové a bílé vlající pole. V červeném poli žlutý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném trojvrší černý korunovaný lev se zlatou zbrojí, držící v pravé přední tlapě stříbrný kosíř se zlatou rukojetí a v levé tlapě pod ním zlatou vinnou ratolest se dvěma listy a jedním hroznem."
    },
    {
        obec: "Radějovice",
        kod: 536423,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38773,
        latitude: 49.186371,
        longitude: 14.026543
    },
    {
        obec: "Radějovice",
        kod: 538698,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25168,
        latitude: 49.947328,
        longitude: 14.564877,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Rad%C4%9Bjovice_%28Prague-East_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým čtvrtinu délky listu s červeným středovým křížem s rameny širokými dvanáctinu délky listu. Ve střední části listu osmicípá hvězda nad lilií, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo červený heroldský kříž, vlevo osmihrotá hvězda nad lilií, obojí zlaté."
    },
    {
        obec: "Radenice",
        kod: 596515,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.427318,
        longitude: 16.064675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Radenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a zelené pole. V žerďové polovině listu bílý jednorožec ve skoku, pod ním v červeném pruhu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě jednorožec ve skoku provázený vpravo dole lilií, vše stříbrné."
    },
    {
        obec: "Radešín",
        kod: 596523,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59255,
        latitude: 49.470362,
        longitude: 16.087846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Rade%C5%A1%C3%ADn_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým okřídleným Pegasem se žlutou zbrojí, hřívou, ocasem a červeným jazykem ve skoku nad bílou věží s cimbuřím, černými vraty a dvěma okny, červenou valbovou střechou se žlutými makovicemi. Pegas má přes křídlo červený jetelový kříž a je provázený před hlavou žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný Pegas se zlatou zbrojí, hřívou, ocasem a červeným jazykem ve skoku nad stříbrnou věží s cimbuřím, s černými vraty a dvěma okny, červenou valbovou střechou se zlatými makovicemi. Pegas má přes křídlo červený jetelový kříž a vpravo nahoře je provázen zlatou osmihrotou hvězdou."
    },
    {
        obec: "Radenín",
        kod: 552917,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39120,
        latitude: 49.369486,
        longitude: 14.840783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Radenin_znak.png",
        coatOfArmsFlagDescription: "Modrý list se vzdutou plachtou na ráhnu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vzdutá plachta na ráhnu, vše zlaté."
    },
    {
        obec: "Radětice",
        kod: 552925,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.31946,
        longitude: 14.441364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Znak_obce_Rad%C4%9Btice_u_Bechyn%C4%9B.svg",
        coatOfArmsFlagDescription: "Zelený list s kosmým bílým pruhem vycházejícím z první pětiny žerďového a první osminy horního okraje do páté pětiny vlajícího a osmé osminy dolního okraje listu. V dolním rohu a horním cípu po jedné žluté osmicípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná věž kostela s červenými zavřenými vraty na zlatých závěsech, červenou římsou a červenou střechou, z níž vyniká zlatá zvonička se zlatým zvonem a červenou střechou zakončenou zlatou makovicí a křížem. Zvonička je provázena dvěma zlatými osmihrotými hvězdami, v patě štítu stříbrné vlnité břevno."
    },
    {
        obec: "Radešínská Svratka",
        kod: 596531,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59233,
        latitude: 49.508546,
        longitude: 16.085143,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Rsvratka_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vykračujícím čápem se žlutou zbrojí. V horním rohu a cípu žlutý vykořeněný topol. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vykračující čáp se zlatou zbrojí provázený nahoře dvěma zlatými vykořeněnými topoly."
    },
    {
        obec: "Radětice",
        kod: 564389,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.639925,
        longitude: 14.078828,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Rad%C4%9Btice_PB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a zelené pole. V žerďové a střední části listu koruna v dole přerušeném mezikruží s hrotem kopí k dolnímu okraji listu, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě nad postaveným hrotem kopí koruna v dole přerušeném kruhu, vše zlaté."
    },
    {
        obec: "Radhostice",
        kod: 550485,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.079455,
        longitude: 13.880611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Radhostice_CoA.png",
        coatOfArmsFlagDescription: "V zeleném listu žlutá berla závitem k žerďovému okraji listu s hvězdou v hlavici, nahoře provázená po stranách dvěma hvězdami nad sebou, všechny hvězdy bílé, sedmicípé. V dolní části listu je berla provázena dvěma bílými volnými spirálami s konci nahoře k okrajům listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá berla s hvězdou v hlavici, provázená po obou stranách dvěma hvězdami pod sebou a pod nimi spirálou vynikající z okraje štítu, závitem dolů. Vše stříbrné, hvězdy sedmihroté."
    },
    {
        obec: "Radhošť",
        kod: 575542,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 49.987647,
        longitude: 16.076121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Radho%C5%A1%C5%A5_UO_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným křížem s rameny vycházejícími z třetí osminy šířky listu a třetí dvanáctiny délky listu. Na nejdelším rameni kříže zavěšen modrý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pravý zvýšený červený heroldský kříž s modrým zvonem zavěšeným na levém rameni."
    },
    {
        obec: "Radíč",
        kod: 598488,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.714764,
        longitude: 14.417497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Radic_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů střídavě bílých a zelených, v poměru 1:1:1:1:1:1:1:1:8. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená snítka jmelí."
    },
    {
        obec: "Radíkov",
        kod: 517275,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.596189,
        longitude: 17.672293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Rad%C3%ADkov_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem širokým jednu desetinu šířky listu. Uprostřed dvě vztyčené zelené jehličnaté větévky nad černou radlicí hrotem k žerdi a ostřím k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném, zeleně lemovaném štítě dvě vztyčené zelené jehličnaté větévky nad doleva položenou černou radlicí."
    },
    {
        obec: "Radíkovice",
        kod: 570711,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.209432,
        longitude: 15.693658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Radikovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh, široký jednu polovinu délky listu, se žlutou zvoničkou a sedm střídavě bílých a červených šikmých pruhů. Poměry bílých a červených pruhů jsou na horním okraji listu 5:4:5, na vlajícím okraji listu 4:5:4:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo zlatá zvonička, vlevo tři šikmá červená břevna."
    },
    {
        obec: "Radim",
        kod: 573370,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50712,
        latitude: 50.458914,
        longitude: 15.428693,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Radim_JC_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červeno-modře polcený a zelený. V červeném poli bílá kvádrovaná kónická věž s cimbuřím, s černou branou s vytaženou bílou mříží a černou klíčovou střílnou. V modrém poli žlutá hrst čtyř převázaných obilných klasů. V zeleném pruhu bílý kostel, s věží k žerdi, s černým kruhovým oknem, červenou bání s lucernou zakončenou bílým křížkem, loď se čtyřmi černými obloukovými okny, červenou valbovou střechou zakončenou bílým křížkem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-modře polcený. Vpravo stříbrná kvádrovaná kónická věž s cimbuřím, s černou branou s vytaženou stříbrnou mříží a černou klíčovou střílnou. Vlevo zlatá hrst čtyř převázaných obilných klasů. Dole v zeleném poli stříbrný kostel, vpravo s věží s černým kruhovým oknem, červenou bání s lucernou zakončenou stříbrným křížkem, loď se čtyřmi černými obloukovými okny, červenou valbovou střechou zakončenou stříbrným křížkem."
    },
    {
        obec: "Radim",
        kod: 533629,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28103,
        latitude: 50.070165,
        longitude: 15.012548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Radim_znak.gif",
        coatOfArmsFlagDescription: "Bílý list se zeleným stromem s hnědým kmenem vyrůstajícím ze zeleného vodorovného pruhu na dolním okraji širokého jednu šestinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě listnatý strom přirozené barvy vyrůstající ze zeleného návrší."
    },
    {
        obec: "Radiměř",
        kod: 578657,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56907,
        latitude: 49.699586,
        longitude: 16.431386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Radim%C4%9B%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-bíle kosmo dělený list, v žerďovém poli bílé mlýnské kolo, ve vlajícím poli červené palečné kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Kosmo dělený štít, v horním stříbrném poli červené palečné kolo, v dolním modrém poli stříbrné mlýnské kolo."
    },
    {
        obec: "Radimovice",
        kod: 544582,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46344,
        latitude: 50.626455,
        longitude: 15.082181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Radimovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List dělený šikmým zeleným pruhem širokým čtvrtinu šířky listu na žerďové červené a vlající modré pole. V červeném poli bílý vykračující kohout. Zelený pruh je posázen deseti vztyčenými bílými lipovými listy ve dvou řadách. V modrém poli dva vodorovné bílé vlnité pruhy, široké osminu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleno-modře šikmo dělený štít, nahoře vykračující kohout, uprostřed deset (5,5) vztyčených lipových listů, dole dvě vlnitá břevna, vše stříbrné."
    },
    {
        obec: "Radimovice u Tábora",
        kod: 563196,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.457417,
        longitude: 14.639125
    },
    {
        obec: "Radimovice u Želče",
        kod: 552933,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.377371,
        longitude: 14.647655,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Radimovice_u_Zelce_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a bílý, v poměru 1 : 1 : 4. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s černou patou červená růže se zlatým semeníkem a zelenými kališními lístky. V patě zlaté břevno."
    },
    {
        obec: "Radkov",
        kod: 552941,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.465893,
        longitude: 14.611257
    },
    {
        obec: "Radkov",
        kod: 587761,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.145306,
        longitude: 15.475336
    },
    {
        obec: "Radkov",
        kod: 549924,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59253,
        latitude: 49.423179,
        longitude: 16.158345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Znak_Radkov.jpg",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list, v bílém poli černý havran se žlutým prstenem v zobáku, v modrém šikmo žluté vědro s bílou vytékající vodou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo děleném štítě nahoře černý havran se zlatým prstenem v zobáku, dole šikmo zlaté vědro s vytékající stříbrnou vodou."
    },
    {
        obec: "Radkov",
        kod: 568317,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74784,
        latitude: 49.825627,
        longitude: 17.770253,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Radkov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým vydutým klínem vycházejícím z dolního okraje a s vrcholem na horním okraji. V klínu červená lilie, v horním rohu a cípu po jedné růži na stonku vyrůstajícím z půlměsíce cípy nahoru, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se zlatým vydutým hrotem s červenou lilií, provázeným dvěma růžemi na stoncíh vyrůstajícími ze stoupajících půlměsíců, vše zlaté."
    },
    {
        obec: "Radkov",
        kod: 574333,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.734468,
        longitude: 16.741584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Radkov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, oboustranně zubatý modrý a žlutý, v poměru 1:4:1. Zubatý pruh má čtyři čtvercové zuby a tři stejné mezery a v něm vztyčený bílý rovný jelení paroh s pěti výsadami, provázený třemi žlutými šesticípými hvězdami, k žerďovému okraji dvěma mezi výsadami a k vlajícímu okraji jednou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo vztyčený stříbrný rovný jelení paroh s pěti výsadami, dvě nahoru, tři doprava, provázený třemi zlatými hvězdami dvě vpravo a jedna vlevo. Vlevo ze zdi vyrůstá věž s cimbuřím a prázdným oknem, obojí červené a kvádrované."
    },
    {
        obec: "Radkova Lhota",
        kod: 517321,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.44185,
        longitude: 17.620656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Radkova_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, přes ně svislý bílý pruh vycházející ze druhé čtvrtiny horního okraje listu. V horním rohu bílý půlměsíc cípy k vlajícímu okraji, ve vlající polovině modrého pruhu polovina bílého jednorožce ve skoku s červeným jazykem, ve vlající polovině červeného pruhu srdce převýšené třemi růžemi vedle sebe, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně děleném štítě vpravo stříbrná věž zvonice na soklu se třemi černými okny, kruhovým nad dvěma zahrocenými, a červenou stanovou střechou se zlatou makovicí, provázená nahoře ubývajícím půlměsícem a polovinou jednorožce ve skoku s červeným jazykem, obojí stříbrné, vlevo dole srdce převýšené třemi růžemi vedle sebe, vše zlaté."
    },
    {
        obec: "Radkovice",
        kod: 540421,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.547925,
        longitude: 13.368061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Radkovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří svislý červený pruh široký polovinu délky listu a šest vodorovných pruhů, střídavě bílých a červených. V červeném pruhu bílá věž s cimbuřím, s branou a dvěma okny vedle sebe, vše černé, pod ní bílá růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli stříbrná věž s cimbuřím, s branou a dvěma okny vedle sebe, vše černé, pod ní stříbrná růže, levé pole pětkrát stříbrno-červeně dělené."
    },
    {
        obec: "Radkovice u Budče",
        kod: 550493,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 38001,
        latitude: 49.082593,
        longitude: 15.621844
    },
    {
        obec: "Radkovice u Hrotovic",
        kod: 591548,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67559,
        latitude: 49.070537,
        longitude: 16.007196,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Coat_of_arms_of_Radkovice_u_Hrotovic.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě bílé a modré, v poměru 8 : 5 : 6 : 5. V žerďovém pruhu nad šesticípou hvězdou kotvicový kříž s kratšími vodorovnými rameny, obojí červené. Ve druhém bílém pruhu tři modré květy lnu pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře polceném štítě vpravo kotvicový kříž s kratšími vodorovnými rameny nad hvězdou, obojí červené, vlevo stříbrné orlí křídlo, v něm tři modré květy lnu pod sebou."
    },
    {
        obec: "Radkovy",
        kod: 517437,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.436222,
        longitude: 17.614936,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Radkovyznak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený, přeložené bílým vodorovným pruhem širokým jednu osminu šířky listu. Nad bílým pruhem ve střední části listu v modrém pruhu žlutá osmicípá hvězda, v zeleném pruhu bílo - černě fasetově čtvrcený liliový kříž. Pod bílým pruhem uprostřed dolní polovina žlutého palečného kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně polceném štítě stříbrné vlnité břevno, nad ním vpravo zlatá osmihrotá hvězda, vlevo stříbrno - černě fasetově čtvrcený liliový kříž, pod břevnem dolní polovina zlatého palečného kola."
    },
    {
        obec: "Rádlo",
        kod: 563781,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46803,
        latitude: 50.698552,
        longitude: 15.115859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/R%C3%A1dlo_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a žlutý, v poměru 2:1:1. V žerďové části modrého pruhu půl žlutého vozového kola obloukem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zlatém návrší stříbrný dub se zlatými listy, z jehož kmene vyniká vpravo půl zlatého vozového kola."
    },
    {
        obec: "Radnice",
        kod: 560120,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.856879,
        longitude: 13.605812,
        coatOfArmsFlagDescription: "List praporu tvoří zelené pole ve tvaru zeleného rovnoramenného trojúhelníku s vrcholem v dolním žerďovém rohu, bílé středové kosé pole s rožmberskou červenou růží z městského znaku uprostřed a červené pole ve tvaru rovnoramenného trojúhelníku v horním vlajícím cípu. Šířka bílého pole je na horním a dolním okraji listu rovna jedné třetině délky listu. Poměr šířky praporu k délce je 2:3."
    },
    {
        obec: "Radňoves",
        kod: 596540,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.388717,
        longitude: 16.214074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/CoA_of_Rad%C5%88oves.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým vozovým kolem v žerďové polovině listu a s dolním vlajícím trojúhelníkem tvořeným čtyřmi vodorovnými pruhy, žlutým, modrým, červeným a žlutým, v poměru 2 : 1 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - zlatě šikmo děleném štítě nahoře zlaté vozové kolo, dole modrý kotlík s dvěma uchy nad volným červeným ohněm."
    },
    {
        obec: "Radňovice",
        kod: 596558,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.56411,
        longitude: 16.034964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Rad%C5%88ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:10:1. Ke žlutým pruhům přiléhají bílá půlkruhová pole, každé s černým odvráceným křídlem se žlutým jetelovitě zakončeným perizoniem. Ve střední části listu koruna provázená nahoře zkříženými ostrvemi a dole uťatým smrkem, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva stříbrné vyduté boky, v nich černé odvrácené křídlo se zlatým jetelovitě zakončeným perizoniem. Boky spojeny korunou provázenou nahoře zkříženými ostrvemi, dole uťatým smrkem, vše zlaté."
    },
    {
        obec: "Radomyšl",
        kod: 551660,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38731,
        latitude: 49.31645,
        longitude: 13.930344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Radomysl_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou orlicí se žlutou korunou, perisoniem a zbrojí, převýšenou bílým maltézským křížem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Radonice",
        kod: 538701,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25073,
        latitude: 50.143163,
        longitude: 14.610368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Radonice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole se žlutým sluncem a bílé vlající pole s červeným klínem vycházejícím z vlajícího okraje. s vrcholem ve středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně dělený štít, nahoře zlaté slunce s tváří, dole červený hrot."
    },
    {
        obec: "Radonice",
        kod: 563323,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43155,
        latitude: 50.298139,
        longitude: 13.284757,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Radonice_CoA.png"
    },
    {
        obec: "Radonín",
        kod: 591556,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.279799,
        longitude: 15.72424,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Coat_of_arms_of_Radon%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu liliová hůlka provlečená dvěma mezikružími, vše žluté, a provázená po obou stranách třemi bílými šesticípými hvězdami pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě liliová hůlka provlečená dvěma kruhy, vše zlaté, a provázená po obou stranách třemi stříbrnými hvězdami pod sebou."
    },
    {
        obec: "Radostice",
        kod: 583740,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66446,
        latitude: 49.133707,
        longitude: 16.477112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Radostice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se zkříženými postavenými bílými kordy se žlutými jílci, podloženými třemi žlutými liliemi vějířovitě vyrůstajícími na stoncích ze společného kořene. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zkřížené postavené stříbrné kordy se zlatými jílci, podložené třemi zlatými liliemi vějířovitě vyrůstajícími na stoncích ze společného kořene."
    },
    {
        obec: "Radostín",
        kod: 569364,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.661841,
        longitude: 15.545004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Coat_of_arms_of_Radost%C3%ADn_HB.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým dolním vlajícím trojúhelníkem dosahujícím do první čtvrtiny délky listu, v něm šikmo zelený lipový list s plodenstvím. V zeleném poli žluté vozové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo děleném štítě nahoře zlaté vozové kolo, dole šikmo svěšený zelený lipový list s plodenstvím."
    },
    {
        obec: "Radostín",
        kod: 596566,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.652815,
        longitude: 15.875868
    },
    {
        obec: "Radostín nad Oslavou",
        kod: 596574,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.46203,
        longitude: 15.965213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Radost%C3%ADn_nad_Oslavou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, bílý a žlutý, v poměru 1 : 4 : 1. V bílém pruhu černé křídlo se sedmi letkami k hornímu okraji listu a žlutým oboustranně jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Radostná pod Kozákovem",
        kod: 577430,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51263,
        latitude: 50.577589,
        longitude: 15.245541
    },
    {
        obec: "Radošov",
        kod: 591564,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.335141,
        longitude: 15.786546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Znak_obce_Rado%C5%A1ov.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený vlnkovaný se třemi vrcholy, v poměru 1:4. Pod středním vrcholem bílý zvonek rozkladný s pěti květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou oddělenou třemi oblouky stříbrný zvonek rozkladný s pěti květy."
    },
    {
        obec: "Radostov",
        kod: 570729,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.20977,
        longitude: 15.663736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Znak_obce_Radostov.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílá kvádrovaná věž s cimbuřím, černou klíčovou střílnou, černou branou se zdviženou bílou mříží. Věž je převýšená šesticípou hvězdou a provázená obilným klasem se dvěma listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s cimbuřím, černou branou se zdviženou stříbrnou mříží a jednou černou klíčovou střílnou. Věž je převýšená hvězdou a provázená dvěma obilnými klasy, každý se dvěma svěšenými listy, vše zlaté."
    },
    {
        obec: "Radošovice",
        kod: 544965,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.019699,
        longitude: 14.273026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Radosovice_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílé žerďové pětiúhelníkové pole vycházející z první pětiny horního a dolního okraje listu, s vrcholem ve třetí pětině délky listu, a dva vodorovné pruhy, zelený a modrý. Přes pruhy bílá krokev vycházející z třetí šestiny horního a dolního okraje listu, s rameny rovnoběžnými se stranami bílého pole, v něm modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře zvýšeným stříbrným vydutým hrotem s modrou lilií děleném štítě v pravém koutě stříbrná růže s červeným semeníkem a zlatými kališními lístky, v levém stříbrný kráčející kohout se zlatou zbrojí, jazykem a s červeným lalokem a hřebenem."
    },
    {
        obec: "Radošovice",
        kod: 551678,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.233625,
        longitude: 13.898673
    },
    {
        obec: "Radošovice",
        kod: 530531,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.741095,
        longitude: 14.867658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Rado%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, modrý, červený a bílý, v poměru 1:2:2:1. Uprostřed listu bílá vykořeněná lekna, nad nimi v modrém pruhu hlava psa s červeným jazykem a žlutým obojkem, v červeném pruhu hlava kohouta se žlutou zbrojí, obě bílé a přivrácené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nahoře hlava psa s červeným jazykem a zlatým obojkem, a hlava kohouta se zlatou zbrojí, obě stříbrné a přivrácené, dole stříbrná vykořeněná lekna."
    },
    {
        obec: "Radotice",
        kod: 545040,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 48.980111,
        longitude: 15.590214
    },
    {
        obec: "Radotín",
        kod: 517445,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.47973,
        longitude: 17.63283,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, v poměru 1 : 2 : 1. Ve žlutém pruhu červený čtyřlistý květ se žlutým středem a modrý vinný hrozen. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vinný stonek přirozené barvy se třemi modrými hrozny (1, 2) obtočený kolem modrého kolíku, provázený dvěma červenými odvrácenými vinařskými noži převýšenými dvěma červenými čtyřlistými květy se zlatými středy."
    },
    {
        obec: "Radovesice",
        kod: 565521,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.41107,
        longitude: 14.068389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Radovesice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 6 : 1 : 1. V horním pruhu černá kančí hlava s bílou zbrojí nad zkříženými zelenými chmelovými lodyhami, každá se třemi listy a dvěma šišticemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá kančí hlava se stříbrnou zbrojí nad zkříženými zelenými chmelovými lodyhami, každá se třemi listy a dvěma šišticemi. V patě štítu modré vlnité břevno."
    },
    {
        obec: "Radovesnice I",
        kod: 533637,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.010493,
        longitude: 15.151007,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Radovesnice-1-znak.jpg",
        coatOfArmsFlagDescription: "V žerďové polovině modrého listu bílá býčí hlava se žlutými rohy a jazykem, s nozdrami prostřelenými k žerďovému okraji žlutým šípem s bílým hrotem a opeřením. Mezi rohy žlutá osmicípá hvězda. Pod hlavou vyniká z dolního okraje listu vrchol bílé věže s cimbuřím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná býčí hlava se zlatými rohy a jazykem, s nozdrami zleva prostřelenými zlatým šípem se stříbrným hrotem a opeřením a se zlatou osmihrotou hvězdou mezi rohy, pod ní vrchol stříbrné věže s cimbuřím."
    },
    {
        obec: "Radslavice",
        kod: 593524,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68321,
        latitude: 49.323013,
        longitude: 17.003932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Radslavice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, červený, zelený a bílý, v poměru 1:2:2:1. Uprostřed listu dvě zkřížené bílé střely se zavinutím, podložené žlutým obilným klasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě dvě zkřížené stříbrné střely se zavinutím, podložené zlatým obilným klasem."
    },
    {
        obec: "Radovesnice II",
        kod: 533645,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28128,
        latitude: 50.104165,
        longitude: 15.367826
    },
    {
        obec: "Radslavice",
        kod: 517534,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.478269,
        longitude: 17.516667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Radslavice_%28Prerov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, zelený a bílý, v poměru 1:2:1. V zeleném poli půl bílého jednorožce se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě půl stříbrného jednorožce se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Raduň",
        kod: 509841,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74761,
        latitude: 49.89297,
        longitude: 17.943349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Radu%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený se žlutým obilným klasem a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze stříbrného návrší vyrůstající zlatý obilný klas, provázený dvěma přivrácenými stříbrnými klíči zuby dolů."
    },
    {
        obec: "Radvanice",
        kod: 579629,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54212,
        latitude: 50.567552,
        longitude: 16.061824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Radvanice_%28Trutnov%29_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, s vykořeněným smrkem opačných barev, s kmenem podloženým v dolní části listu žluto-černě polceným vodorovným pruhem širokým jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vykořeněný smrk opačných barev s kmenem podloženým zlato-černě polceným vozovým kolem."
    },
    {
        obec: "Radvanec",
        kod: 530387,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47301,
        latitude: 50.753003,
        longitude: 14.59215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Radvanec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, vlnitý bílý a černý, v poměru 2 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - černě zúženým stříbrným vlnitým kůlem polceném štítě vpravo šikmo vztyčená kosa se dvěma držadly kosmo přeložená tkalcovským člunkem, vlevo hostie a pod ní slunce, vše zlaté."
    },
    {
        obec: "Radvanice",
        kod: 517569,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75121,
        latitude: 49.509905,
        longitude: 17.477349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Radvanice%2C_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý, v poměru 1:4:1. V modrém pruhu červeno-bíle šachovaná hlava orlice se žlutou zbrojí, šikmo podložená žlutou sukovitou palicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře stříbrno-modře polcený, vpravo černá zubří hlava se zlatou houžví a červeným jazykem, vlevo červeno-stříbrně šachovaná hlava orlice se zlatou zbrojí, šikmo podložená zlatou sukovitou palicí, v dolním červeném poli polovina zlatého vozového kola."
    },
    {
        obec: "Rájec",
        kod: 540854,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.856577,
        longitude: 16.903068,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Znak-rajec1.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, zelený, bílý a zelený v poměru 10:9:2:9. V žerďovém pruhu dva bílé klíny s vrcholy na žerďovém okraji a základnami širokými polovinu šířky listu. Přes zbývající pruhy žlutý vodorovný pruh široký jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se dvěma stříbrnými kužely zlatý rozkřídlený luňák s červenou zbrojí, stojící na listu stříbrného rýče."
    },
    {
        obec: "Ráječko",
        kod: 582247,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.393418,
        longitude: 16.641436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/R%C3%A1je%C4%8Dko_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová a modrá vlající část. Přes ně položen středový ondřejský kříž opačných barev tak, že svislé rameno je modro-žlutě polceno. Šířka ramen na okraji listu je rovna jedné desetině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vozové kolo o osmi ramenech opačných barev."
    },
    {
        obec: "Rajhrad",
        kod: 583758,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.090311,
        longitude: 16.603982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Rajhrad_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová část a žlutá vlající část. V žerďové části kosmá hnědá ostrev se třemi suky (2,1) přeložená žlutým vztyčeným klíčem zuby k vlajícímu cípu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rajhradice",
        kod: 583766,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.092135,
        longitude: 16.629436,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Rajhradice_znak.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým kruhovým polem o průměru tři pětiny šířky listu se středem dvě pětiny šířky listu od horního okraje listu. Přes kruhové pole modrá letící holubice se žlutou ratolestí v zobáku. Pod kruhovým polem obloukově čtyři žluté šesticípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vykořeněný listnatý strom, v něm letící modrá holubice se zelenou snítkou v zobáku. Strom je dole po každé straně provázen dvěma zlatými hvězdami."
    },
    {
        obec: "Rakousy",
        kod: 577448,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.620425,
        longitude: 15.187647
    },
    {
        obec: "Rájec-Jestřebí",
        kod: 582239,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.410419,
        longitude: 16.640964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/R%C3%A1jecJest%C5%99eb%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý a zvlněný červený, v poměru 3 : 1 : 3. V žerďové polovině zeleného pruhu kosmé krojidlo hrotem k hornímu a ostřím k vlajícímu okraji šikmo podložené radlicí hrotem k hornímu okraji, obojí žluté. V žerďové polovině červeného pruhu vzlétající bílý jestřáb se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně stříbrným zúženým vlnitým břevnem děleném štítě nahoře kosmé krojidlo šikmo podložené radlicí, obojí zlaté. Dole vzlétající stříbrný jestřáb se zlatou zbrojí."
    },
    {
        obec: "Rajnochovice",
        kod: 588920,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76871,
        latitude: 49.413088,
        longitude: 17.813268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/CoA_of_Rajnochovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý a zvlněný červený, v poměru 7 : 4 : 9. Vlnitý pruh má jeden vrchol a dvě prohlubně. V modrém pruhu žlutá osmicípá hvězda provázená v žerďové a vlající části pruhu přivrácenými sněženkami. Uprostřed červeného pruhu bílé zkřížené sekery-valašky na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně stříbrným vlnitým břevnem dělený štít, nahoře zlatá osmihrotá hvězda provázená dvěma přivrácenými sněženkami přirozené barvy, dole zkřížené stříbrné sekery-valašky na zlatých topůrkách."
    },
    {
        obec: "Rakov",
        kod: 517585,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.491801,
        longitude: 17.707876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Rakov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, červený a bílý, v poměru 1:2:1. V červeném poli žlutá lípa. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá lípa provázená dole stříbrnými račími klepety."
    },
    {
        obec: "Raková",
        kod: 566942,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.70043,
        longitude: 13.582052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Rakov%C3%A1_%28Rokycany_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed listu žlutý rak. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý rak."
    },
    {
        obec: "Raková u Konice",
        kod: 589951,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79857,
        latitude: 49.607347,
        longitude: 16.950931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Rakov%C3%A1_u_Konice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký jednu čtvrtinu délky listu se třemi utrženými černými vlčími hlavami s červenými jazyky nad sebou a zelené pole s bílo-červeně polceným rakem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrnou hlavou se třemi utrženými černými vlčími hlavami s červenými jazyky stříbrno-červeně polcený rak."
    },
    {
        obec: "Rakovice",
        kod: 562068,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.471321,
        longitude: 14.05466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Rakovice_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto-zeleně šikmo dělený list, ve žlutém poli červený rak, v zeleném šikmo žlutá lipová větévka se třemi listy a plodenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně šikmo děleném štítě nahoře červený rak, dole šikmo zlatá lipová větévka se třemi listy a plodenstvím."
    },
    {
        obec: "Rakovník",
        kod: 541656,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26901,
        latitude: 50.106223,
        longitude: 13.739762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Rakovnik_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným rakem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Rakůvka",
        kod: 589969,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79857,
        latitude: 49.610112,
        longitude: 16.938064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Rak%C5%AFvka_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polceným rakem s čelně vztyčenými žlutými radlicemi místo klepet. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně polcený rak s čelně vztyčenými zlatými radlicemi místo klepet."
    },
    {
        obec: "Rakvice",
        kod: 584860,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69103,
        latitude: 48.858211,
        longitude: 16.813408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Rakvice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, zelený, modrý, zelený a bílý v poměru 6:1:2:1:6. V horním žerďovém rohu červený rak klepety vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený rak, pod ním položený modrý vinný hrozen se zeleným listem."
    },
    {
        obec: "Ralsko",
        kod: 562017,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47124,
        latitude: 50.58629,
        longitude: 14.80363,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Ralsko_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a bílý, v poměru 1:2:1. V černém pruhu tři svěšené bílé lipové listy pod sebou, ve žlutém pruhu černý medvěd ve skoku s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod černou hlavou se třemi svěšenými stříbrnými lipovými listy černý medvěd ve skoku s červenou zbrojí, provázený nahoře dvěma dvojicemi černých zkřížených ostrví."
    },
    {
        obec: "Raná",
        kod: 572152,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53972,
        latitude: 49.796504,
        longitude: 15.96422,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Ran%C3%A1_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list dělený třemi kosmými pruhy, žlutým, zeleným a žlutým, každý široký jednu desetinu délky listu. Na dolním okraji žerďového pole bílá kvádrovaná hradba s cimbuřím s vycházejícím žlutým sluncem bez obličeje s devíti trojúhelníkovými paprsky. V horním cípu polovina bílého koně se žlutou zbrojí a uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlato-zeleno-zlatě dělené kosmé břevno. Nahoře stříbrná polovina koně se zlatou zbrojí a uzděním, dole stříbrná kvádrovaná hradba s cimbuřím s vycházejícím zlatým sluncem."
    },
    {
        obec: "Raná",
        kod: 566659,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43924,
        latitude: 50.411665,
        longitude: 13.779373,
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh se žlutou šesticípou hvězdou, široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě zlatá hvězda nad zeleným trojvrším se zlatým květem o dvanácti okvětních lístcích."
    },
    {
        obec: "Rančířov",
        kod: 587176,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58601,
        latitude: 49.362563,
        longitude: 15.588563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Ran%C4%8D%C3%AD%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a vypouklý žlutý, v poměru 7 : 1. Uprostřed modrého pruhu klíč zuby nahoru a k žerďovému okraji, meč hrotem k dolnímu okraji listu a jelení paroh, vše žluté. Ve žlutém pruhu sahajícím do sedmi desetin šířky listu černá hornická kladívka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým návrším s černými hornickými kladívky vztyčený klíč, postavený meč a jelení paroh, vše zlaté."
    },
    {
        obec: "Rantířov",
        kod: 587788,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58841,
        latitude: 49.408348,
        longitude: 15.515264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Coat_of_arms_of_Rant%C3%AD%C5%99ov.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, zelený, modrý a žlutý, v poměru 1 : 2 : 2 : 1. Ve středních pruzích mezi bílými parohy bílý srp se žlutou rukojetí, ostřím k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře polcený štít se zlatým trojvrším, z prostředního vrchu vyrůstají stříbrné jelení parohy, mezi nimi stříbrný srp se zlatou rukojetí.."
    },
    {
        obec: "Rapotice",
        kod: 591581,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67573,
        latitude: 49.192313,
        longitude: 16.25337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Rapotice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, červený a zelený, v poměru 1:3:1. V prostředním pruhu kvádrované trojúhelníkové pole se základnou na střední části horního okraje dolního zeleného pruhu a s vrcholem na dolním okraji horního zeleného pruhu, provázené v žerďové části radlicí hrotem dolů a ostřím k žerďovému okraji a ve vlající části vinným hroznem na stonku se dvěma úponky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném návrší kvádrovaný hrot, provázený vpravo radlicí a vlevo vinným hroznem na stonku se dvěma úponky, vše zlaté."
    },
    {
        obec: "Rapotín",
        kod: 540862,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78814,
        latitude: 50.000846,
        longitude: 17.019059,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Rapot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, modrý a bílý v poměru 1:3:1. V modrém poli tři (2,1) žluto-červeně polcené lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít, nahoře rostoucí černý dvouocasý korunovaný lev se zlatou zbrojí a červeným jazykem, dole tři (2,1) zlato-červeně polcené lilie."
    },
    {
        obec: "Rapšach",
        kod: 547069,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37807,
        latitude: 48.879314,
        longitude: 14.933012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Rap%C5%A1ach_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo vlnitě dělený na bílo-červeně šachované žerďové pole a zelené vlající. Ve vlajícím poli dva bílé smrky šikmo nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmo vlnitě dělený štít, horní pole stříbrno-červeně šachované, v dolním zeleném poli dva stříbrné smrky šikmo nad sebou."
    },
    {
        obec: "Rasošky",
        kod: 574376,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55221,
        latitude: 50.322012,
        longitude: 15.911774,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Raso%C5%A1ky-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zvlněný modrý. Ve žlutém pruhu vykořeněná zelená borovice s hnědým kmenem, v modrém žlutá hlava jelena desateráka s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře vlnitě polceném štítě vpravo vykořeněná borovice přirozené barvy, vlevo zlatá hlava jelena desateráka s krkem."
    },
    {
        obec: "Raspenava",
        kod: 564371,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.904256,
        longitude: 15.114757,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Raspenava_CoA_CZ.svg"
    },
    {
        obec: "Rašín",
        kod: 549274,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.333359,
        longitude: 15.678023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Ra%C5%A1%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu žlutý třmen mezi zkříženými bílými dubovými větvemi, každá s odvrácenými listy a žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý třmen mezi zkříženými stříbrnými dubovými větvemi, každá s odvrácenými listy a žaludem."
    },
    {
        obec: "Raškovice",
        kod: 549665,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73904,
        latitude: 49.619845,
        longitude: 18.472974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Raskovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý nad bílým v poměru 3:2. V horním rohu žlutý brhlík. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném vykořeněném listnatém stromě sedící zlatý brhlík (raška)."
    },
    {
        obec: "Rašov",
        kod: 582255,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.415645,
        longitude: 16.45383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Ra%C5%A1ov_-_znak.svg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo dělený list. Uprostřed zvon se svěšeným dubovým listem se žaludem, vše opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě zvon se svěšeným dubovým listem se žaludem, vše opačných barev."
    },
    {
        obec: "Rašovice",
        kod: 593532,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68501,
        latitude: 49.121064,
        longitude: 16.948329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Ra%C5%A1ovice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý. Ve žlutém pruhu tři kosmé zelené pruhy vycházející z lichých šestin žerďového okraje listu do sudých šestin okraje středního pruhu, v červeném pruhu žlutý vinný hrozen, v bílém pruhu tři kosmé černé pruhy vycházející z lichých šestin okraje středního pruhu do sudých šestin vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli pod sebou zlatý vinný hrozen, kosmý stříbrný vinařský nůž - kosíř se zlatou rukojetí a zlatá radlice, levé pole zeleno-stříbrně dělené, nahoře vyrůstající zlatý patriarší kříž, dole tři kosmá černá břevna."
    },
    {
        obec: "Rašovice",
        kod: 534340,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.882642,
        longitude: 15.102485
    },
    {
        obec: "Rataje",
        kod: 552976,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.350249,
        longitude: 14.44584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Rataje_TA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý v poměru 1:6:1. V žerďové polovině modrého pruhu bílá žlutě zdobená kbelíková přilba se vztyčeným palcátem mezi dvěma rozevřenými křídly, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná zlatě zdobená kbelíková přilba se vztyčeným palcátem mezi dvěma rozevřenými křídly, vše zlaté."
    },
    {
        obec: "Rataje",
        kod: 530549,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.704164,
        longitude: 14.97055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Rataje_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, ze kterého vyrůstá do modrého pruhu bílá kaple se zavřenými hnědými dveřmi a červenou stanovou střechou s červenou věžičkou zakončenou žlutou makovici s černým křížkem. Pod kaplí v zeleném pruhu černý pluh bez kol, s ojí svisle přeloženou žlutým ovesným klasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrná kaple s hnědými zavřenými dveřmi a červenou stanovou střechou s červenou věžičkou zakončenou zlatou makovicí s černým křížkem. V trávníku pluh přirozené barvy přeložený zlatým ovesným klasem."
    },
    {
        obec: "Rataje",
        kod: 588938,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76812,
        latitude: 49.2714,
        longitude: 17.335656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Rataje_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý v poměru 1:2:1. V bílém poli červený lev s bílými zuby, červeným jazykem, s modrými drápy a modrou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít vpravo zlatý, dolů obrácený klíč, křížem podložený stříbrným mečem se zlatou rukojetí a záštitou, vlevo červený, modře korunovaný lev s modrou zbrojí a červeným jazykem."
    },
    {
        obec: "Rataje nad Sázavou",
        kod: 534358,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28507,
        latitude: 49.841949,
        longitude: 14.956827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Rataje_nad_S%C3%A1zavou_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zubatý modrý, v poměru 1 : 2 : 7. V modrém pruhu se třemi zuby a čtyřmi stejnými mezerami bílý plazící se had. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Ratboř",
        kod: 533653,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28141,
        latitude: 49.978185,
        longitude: 15.159345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/CoA_of_Ratbo%C5%99.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý s červeným broušeným českým granátem, červený a zelený v poměru 5 : 1 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně čtvrcený štít s červeným kůlem, v 1. a 4. poli červený broušený český granát."
    },
    {
        obec: "Ratenice",
        kod: 537748,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.09171,
        longitude: 15.059361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Ratenice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a žlutý. Uprostřed svatojakubská mušle opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě svatojakubská mušle opačných barev."
    },
    {
        obec: "Ratiboř",
        kod: 507784,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.143619,
        longitude: 14.915123,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Ratibo%C5%99_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed červené orlí křídlo se sedmi žlutými srdíčky. U křídla v modrém pruhu žlutá růže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě červené orlí křídlo se sedmi zlatými srdíčky, provázené vpravo zlatou růží."
    },
    {
        obec: "Ratiboř",
        kod: 544787,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75621,
        latitude: 49.367793,
        longitude: 17.915097,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Ratibo%C5%99_%28VS%29_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi (2,1) bílými vztyčenými květy zvonku v horní žerďové části a žlutou korunou v dolní vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá koruna a nad ní tři (2,1) stříbrné vztyčené květy zvonku."
    },
    {
        obec: "Ratibořské Hory",
        kod: 552992,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39142,
        latitude: 49.462778,
        longitude: 14.769911
    },
    {
        obec: "Ratíškovice",
        kod: 586510,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69602,
        latitude: 48.920114,
        longitude: 17.165713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Rat%C3%AD%C5%A1kovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Na praporu přechází barva bílá k barvě modré, tj. stříbrná, v případě praporu bílá, nahoře. Oba pruhy jsou stejně široké.",
        coatOfArmsDescription: "Na modrém štítě jsou 3 stříbrné žaludy na stoncích vyrůstajících z jednoho místa mezi úponky. Mezi stonky se žaludy vyrůstají rovněž úponky. Nahoře mezi žaludy jsou 2 hvězdy. Na heraldicky pravé straně je na hrot postavena pěticípá hvězda a na levé straně šesticípá hvězda postavena rovněž na hrot."
    },
    {
        obec: "Ratměřice",
        kod: 532550,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25703,
        latitude: 49.643355,
        longitude: 14.756277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Ratmerice.svg",
        coatOfArmsFlagDescription: "List s modrým žerďovým pruhem širokým jednu třetinu délky listu. V modrém pruhu zkřížené žluté klíče, v bílém poli horní polovina černého medvěda s červenými drápy a jazykem a žlutým obojkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítu černý medvěd s červenou zbrojí a jazykem a se zlatým obojkem, rostoucí z hraběcí korunky a držící v tlapách modrý štítek se dvěma zkříženými klíči."
    },
    {
        obec: "Razová",
        kod: 597724,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.931401,
        longitude: 17.532102,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Razov%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:3:1. V modrém pruhu radlice hrotem k hornímu a ostřím k vlajícímu okraji, šikmo podložená kamenickým dlátem ostřím nahoru a provázená po stranách dvěma šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácená vztyčená radlice šikmo podložená vztyčeným kamenickým dlátem a provázená po stranách dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Ražice",
        kod: 549827,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39822,
        latitude: 49.240999,
        longitude: 14.101842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Razice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-zeleně kosmo děleném štítě nahoře kosmá zlatá přirozená lilie na stonku, dole stříbrná hornická kladívka na topůrkách přirozené barvy."
    },
    {
        obec: "Rebešovice",
        kod: 583774,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66461,
        latitude: 49.105224,
        longitude: 16.635332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Rebe%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým vlnkovaným bílým pruhem širokým 1/3 šířky listu, se dvěma polokruhovými oblouky o poloměru jedné čtvrtiny šířky listu s čtvrtkruhovými modrými poli o poloměru rovném jedné třetiny šířky listu se středy v horním a dolním žerďovém rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná čtyřhrotá hvězda dotýkající se okrajů štítu, v ní dvě zelené ryby spojené černým vlascem, horní doleva, dolní doprava. Hvězda vpravo nahoře provázena zlatou kadidelnicí na řetězu."
    },
    {
        obec: "Rejchartice",
        kod: 553395,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78701,
        latitude: 50.028348,
        longitude: 16.97802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Rejchartice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:2:1. V bílém pruhu dvě zkřížené vztyčené černé lví tlapy s červenou zbrojí, podložené červeným plamenným mečem hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná, čelně postavená radlice, v ní dvě zkřížené vztyčené černé lví tlapy s červenou zbrojí, podložené postaveným červeným plamenným mečem."
    },
    {
        obec: "Rejštejn",
        kod: 557021,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34192,
        latitude: 49.14064,
        longitude: 13.515272,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Rejstein_znak.png"
    },
    {
        obec: "Rešice",
        kod: 594725,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67173,
        latitude: 49.053466,
        longitude: 16.171572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Re%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý. V zeleném kráčející bílý rozsévač, v modrém do oblouku k dolnímu okraji pět žlutých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře děleném štítě nahoře kráčející stříbrný rozsévač, dole do oblouku pět zlatých hvězd."
    },
    {
        obec: "Roblín",
        kod: 571318,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25226,
        latitude: 49.961936,
        longitude: 14.248667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Robl%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 2 : 1 : 1. V žerďové polovině horního pruhu žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve vlnitě děleném štítě nahoře v zeleném poli zlatá koruna, dolní pole stříbrno-zeleně dělené smrkovým řezem třemi zelenými smrky."
    },
    {
        obec: "Ročov",
        kod: 566667,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43967,
        latitude: 50.253498,
        longitude: 13.774466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Ro%C4%8Dov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým lemem širokým desetinu šířky listu. V modrém poli bílo-červeně polcená orlice se žlutou zbrojí, jetelovitě zakončeným perizoniem a korunou navlečenou na krku, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém, zlatě lemovaném štítě stříbrno-červeně polcená orlice se zlatou zbrojí, jetelovitě zakončeným perizoniem a korunou navlečenou na krku, obojí zlaté."
    },
    {
        obec: "Rodinov",
        kod: 561789,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.282895,
        longitude: 15.103915
    },
    {
        obec: "Rodkov",
        kod: 587737,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.503956,
        longitude: 16.227678,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Coat_of_arms_of_Rodkov.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem na středu vlajícího okraje. Na středu je položen zelený rovnoramenný trojúhelník s vrcholem ve dvou třetinách délky listu a základnou ve vzdálenosti jedné desetiny délky listu od žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot se zeleným lipovým listem."
    },
    {
        obec: "Rodná",
        kod: 560626,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.490264,
        longitude: 14.8426,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Rodn%C3%A1_CoA.jpg"
    },
    {
        obec: "Rodvínov",
        kod: 547085,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.171096,
        longitude: 15.05709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Rodv%C3%ADnov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 5 : 2 : 9. V horním modrém pruhu koruna, ve střední části dolního modrého pruhu dvě šesticípé hvězdy, pod nimi růže, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný most se dvěma oblouky převýšený korunou, pod oblouky dvě hvězdy a pod nimi růže, vše zlaté."
    },
    {
        obec: "Rohatec",
        kod: 586528,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69601,
        latitude: 48.880497,
        longitude: 17.183408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Rohatec_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list s modrým šikmým pruhem z druhé čtvrtiny dolního okraje do třetí čtvrtiny horního okraje listu a se dvěma žlutými šikmými klíny vycházejícími ze čtvrté čtvrtiny žerďového a první čtvrtiny dolního okraje listu a ze čtvrté čtvrtiny horního a první čtvrtiny vlajícího okraje listu s vrcholy na okraji modrého pruhu. V horním rohu žluté slunce bez tváře a hnědá větévka s pěti žaludy (2,3). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad pětkrát stříbrno-modře vlnkovitě dělenou patou doleva položená větévka přirozené barvy se dvěma vztyčenými a třemi svěšenými zlatými žaludy, provázená vpravo nahoře zlatým sluncem."
    },
    {
        obec: "Rohatsko",
        kod: 565636,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.440386,
        longitude: 15.104846
    },
    {
        obec: "Rohenice",
        kod: 548669,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.309374,
        longitude: 16.032244,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Rohenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý s nízkým návrším. Uprostřed hnědá jelení hlava s děleným parožím, v modrém pruhu bílém, ve žlutém černém. Mezi parohy na návrší bílý kostel s věží k vlajícímu okraji, červenými střechami a prázdnými okny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zlatě do oblouku děleném štítě jelení hlava přirozené barvy se stříbrno - černě děleným parožím. Mezi parohy na dělení stříbrný kostel s věží vlevo, s červenými střechami a prázdnými okny."
    },
    {
        obec: "Rohle",
        kod: 540871,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78974,
        latitude: 49.860823,
        longitude: 17.013786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Rohle_CoA.jpg",
        coatOfArmsFlagDescription: "Kosmo a šikmo červeno-zeleně čtvrcený list. Uprostřed ve žlutém jelením paroží bílý meč se žlutým jílcem hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se zeleným hrotem zlaté jelení paroží, v něm vztyčený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Rohov",
        kod: 568376,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74725,
        latitude: 50.015707,
        longitude: 18.071507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Rohov_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou šachovou figurou z obecního znaku zvanou roh. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná šachová figura zvaná roh."
    },
    {
        obec: "Rohovládova Bělá",
        kod: 575551,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53343,
        latitude: 50.106311,
        longitude: 15.606265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Rohovl%C3%A1dova_B%C4%9Bl%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným rozkřídleným drakem; na pravém křídle bílý meč se žlutým jílcem hrotem nahoru, na levém křídle žlutý klíč zuby nahoru a k vlajícímu okraji. Drak má kolem krku žlutou kličku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený rozkřídlený drak se vztyčeným stříbrným mečem se zlatým jílcem na pravém křídle a vztyčeným zlatým klíčem zuby doleva na levém křídle. Kolem krku draka zlatá klička s konci do okrajů štítu."
    },
    {
        obec: "Rohozec",
        kod: 582263,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.390415,
        longitude: 16.482952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Rohozec_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým šikmým pruhem, širokým dvě třetiny šířky listu, v něm šikmo hnědý orobinec na zeleném stonku s listy. V dolním cípu bílý patriarší kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo orobinec s listy přirozené barvy, vlevo dole stříbrný patriarší kříž."
    },
    {
        obec: "Rohozec",
        kod: 526622,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.97612,
        longitude: 15.384746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Rohozec-znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s kosmým pruhem širokým čtvrtinu délky listu, v horním poli dóza na masti, v dolním lilie, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmé břevno provázené nahoře dózou na masti, dole lilií, vše zlaté."
    },
    {
        obec: "Rohozná",
        kod: 587796,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58844,
        latitude: 49.350316,
        longitude: 15.399269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Rohozn%C3%A1_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, červený a bílý, v poměru 5 : 1: 1 : 1. Z horního bílého pruhu vyrůstají od středu na obě strany špičák a hornická motyka, odvrácené, bílé a na žlutých násadách, provázené třemi vyrůstajícími žlutými obilnými klasy. Vše převýšeno knížecí čepicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě z prostředního vrchu stříbrného trojvrší s červenou svatojakubskou mušlí vyrůstají od středu na obě strany špičák a hornická motyka, odvrácené, stříbrné a na zlatých násadách, provázené třemi vyrůstajícími zlatými obilnými klasy. Vše převýšeno knížecí čepicí."
    },
    {
        obec: "Rohozná",
        kod: 578673,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56972,
        latitude: 49.653741,
        longitude: 16.407989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Rohozn%C3%A1_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílým dvouocasým lvem se žlutou zbrojí a bílý s hnědým orobincem se čtyřmi zelenými listy. Na dolním okraji listu tři vodorovné pruhy, modrý, bílý a modrý, každý široký šestnáctinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě nad modrou patou se stříbrným břevnem vpravo stříbrný dvouocasý lev se zlatou zbrojí, vlevo orobinec přirozené barvy se čtyřmi listy."
    },
    {
        obec: "Rohoznice",
        kod: 573221,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.391682,
        longitude: 15.699658
    },
    {
        obec: "Rohoznice",
        kod: 575569,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.127321,
        longitude: 15.664377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Znak_obce_Rohoznice_u_Pardubic.gif",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, vlnitý bílý, vlnitý modrý a zvlněný bílý, v poměru 17:1:1:1. V horním modrém pruhu žlutá berla s bílým sudariem závitem k žerďovému okraji provázená po stranách dvěma žlutými rákosy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma sníženými stříbrnými vlnitými břevny zlatá berla se stříbrným sudariem provázená dvěma zlatými rákosy."
    },
    {
        obec: "Rohy",
        kod: 591602,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.305639,
        longitude: 16.005703,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Rohy_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý v poměru 1:3:1. V zeleném pruhu bílá, žlutě zdobená mitra s bílými infulemi se žlutými třásněmi, v ní dvě k sobě přivrácená černá orlí křídla. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "v zeleném štítě stříbrná, zlatě lemovaná mitra s infulemi, v ní dvě přivrácená černá orlí křídla se zlatými perizonii."
    },
    {
        obec: "Rochlov",
        kod: 559423,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.730821,
        longitude: 13.146015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Rochlov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový zubatý pruh široký dvě pětiny šířky listu a modré pole. Žlutý pruh má tři čtvercové zuby a dvě stejné mezery. V modrém poli dvě vztyčená přivrácená bílá obrněná ramena držící žluté kruhové pole s modrými hornickými kladívky na černých topůrkách. Mezi rameny žlutý obilný klas se dvěma listy. V horním rohu a cípu modrého pole po jedné svěšené žluté chmelové šištici. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrém štítě nad zlatou cimbuřovou patou se třemi stínkami dvě vztyčená přivrácená stříbrná obrněná ramena držící zlatý kotouč s modrými hornickými kladívky na černých topůrkách. Uprostřed obilný klas se dvěma svěšenými listy, v koutech dvě svěšené chmelové šištice, vše zlaté."
    },
    {
        obec: "Rochov",
        kod: 565547,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.45949,
        longitude: 14.117546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Rochov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3 : 1. V modrém pruhu tři (1, 2), propletené kruhy, provázené třemi (2, 1) liliemi, vše žluté. V červeném pruhu tři kosmé bílé pruhy vycházející z první, třetí a páté šestiny horního okraje pruhu do druhé, čtvrté a šesté šestiny dolního okraje pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít s červenou patou. Ve štítě tři (1, 2) propletené kruhy provázené třemi (2, 1) liliemi, vše zlaté. V patě tři stříbrná kosmá břevna."
    },
    {
        obec: "Rojetín",
        kod: 596582,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.366746,
        longitude: 16.257478,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Rojet%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, u dolního okraje žlutá koruna s červenými drahokamy, z ní vyrůstají dva odkloněné žluté stonky konvalinek, každý se čtyřmi odvrácenými bílými květy. Mezi stonky u horního okraje listu žluté kruhové pole s červeným srdcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zlaté koruny s červenými drahokamy dva odkloněné zlaté stonky konvalinek, každý se čtyřmi odvrácenými stříbrnými květy, nahoře zlatý kotouč s červeným srdcem."
    },
    {
        obec: "Rokle",
        kod: 563331,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43201,
        latitude: 50.35328,
        longitude: 13.29881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Rokle_%28okres_Chomutov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílé pětiúhelníkové pole, vycházející z horního okraje, z první čtvrtiny žerďového a vlajícího okraje listu a s vrcholem ve druhé třetině šířky listu, pod ním zelené pole a bílý zvlněný pruh na dolním okraji listu, široký jednu čtvrtinu šířky listu, s jedním celým a dvěma polovičními vrcholy a dvěma prohlubněmi. Uprostřed listu mříž opačných barev tvořená osmi vodorovnými a pěti svislými pruty tvořícími čtvercová pole. Horní a dolní vodorovný prut přiléhá k okrajům listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně zvýšeným klínem děleném štítě nad stříbrnou vlnitou patou postavený rošt opačných barev."
    },
    {
        obec: "Rokycany",
        kod: 559717,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.738197,
        longitude: 13.592993,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Rokycany_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří červené žerďové a bílé vlající pole. V červeném poli bílý obdélníkový emblém se zdí, dvěma věžemi a figurou biskupa za černým štítkem se žlutým břevnem z městského znaku. Šířka emblému se rovná 1/4 délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rokytá",
        kod: 529605,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29501,
        latitude: 50.563767,
        longitude: 14.869309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Rokyt%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 7 : 2 : 7. Uprostřed horního pruhu dva bílé bramborové květy se žluto-zelenými středy, v dolním pruhu položený žlutý dubový list řapíkem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno, provázené nahoře dvěma bramborovými květy přirozené barvy a dole položeným zlatým dubovým listem."
    },
    {
        obec: "Rokytňany",
        kod: 548898,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.375876,
        longitude: 15.137489
    },
    {
        obec: "Rokytnice",
        kod: 517607,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75104,
        latitude: 49.466023,
        longitude: 17.391308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Rokytnice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený. V horním rohu bílá rozkřídlená husa s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-modře dělený štít, nahoře stříbrný kostel s věží vpravo, červenou střechou a zlatými křížky na věži a sanktusníku, provázený po stranách dvěma stříbrnými svatojakubskými mušlemi, dole na zeleném trávníku stříbrná rozkřídlená husa s červenou zbrojí."
    },
    {
        obec: "Rokytnice",
        kod: 556980,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.066239,
        longitude: 17.91147,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Rokytnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh se třemi žlutými šesticípými hvězdami pod sebou široký jednu třetinu délky listu a šikmo bílo-červeně dělené pole s černým kozlem ve skoku s červeným jazykem, držícím žlutý procesní patriarší kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně šikmo děleném štítě pod modrou hlavou se třemi zlatými hvězdami černý kozel ve skoku s červeným jazykem, držící zlatý procesní patriarší kříž."
    },
    {
        obec: "Rokytnice nad Jizerou",
        kod: 577456,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51244,
        latitude: 50.729228,
        longitude: 15.448787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Rokytnice_nad_Jizerou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žluté karé nad stejně velkým modrým polem a dva vodorovné pruhy, modrý a žlutý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Rokytnice nad Rokytnou",
        kod: 591611,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67525,
        latitude: 49.184456,
        longitude: 15.77297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Rokytnice_nad_Rokytnou_CoA.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený bílým vlnitým pruhem širokým jednu čtvrtinu šířky listu na žerďové červené a vlající modré pole. V červeném poli kosmo žlutá berla závitem nahoru s bílým sudariem. V modrém poli kosmo žluté síto. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně kosmým stříbrným vlnitým břevnem děleném štítě nahoře kosmo položené zlaté síto, dole kosmo položená zlatá berla závitem nahoru se stříbrným sudariem."
    },
    {
        obec: "Rokytno",
        kod: 575577,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53304,
        latitude: 50.104773,
        longitude: 15.889408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Rokytno_PA_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu dva zkřížené hnědé vrbové pruty se zelenými listy, v modrém dva žlutí kapři nad sebou, dolní hlavou k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře vlnitě dělený štít. Nahoře dva zkřížené vrbové pruty s listy přirozené barvy, dole dva zlatí kapři nad sebou, dolní doleva."
    },
    {
        obec: "Rokytnice v Orlických horách",
        kod: 576701,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51761,
        latitude: 50.164831,
        longitude: 16.46578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Znak_obce_Rokytnice_v_Orlick%C3%BDch_hor%C3%A1ch.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři svislé pruhy - červený, modrý a červený. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Rokytovec",
        kod: 571148,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29430,
        latitude: 50.406627,
        longitude: 14.830785
    },
    {
        obec: "Ronov nad Doubravou",
        kod: 572161,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53842,
        latitude: 49.888353,
        longitude: 15.531541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Coat_of_arms_of_Ronov_nad_Doubravou.jpg",
        coatOfArmsFlagDescription: "Červený list uprostřed s bílým dvouocasým lvem se žlutou korunou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ropice",
        kod: 556971,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73961,
        latitude: 49.705458,
        longitude: 18.61356,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Ropica_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, červený a žlutý, v poměru 5:1:1:1. V modrém pruhu žlutý pluh s koly obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělen zúženým břevnem, nahoře v modrém poli doleva obrácený pluh s koly, v dolním červeném poli zúžený kůl provázený hvězdou a postaveným pecnem chleba, vše zlaté."
    },
    {
        obec: "Roprachtice",
        kod: 577464,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.647554,
        longitude: 15.422263,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Roprachtice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý s modrým lvem s červenou zbrojí, červený a žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě vpravo modrý lev s červenou zbrojí, vlevo hrábě přeložené obilným snopem, obojí zlaté."
    },
    {
        obec: "Roseč",
        kod: 508004,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37846,
        latitude: 49.127862,
        longitude: 14.910845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Coat_of_arms_of_Rose%C4%8D.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, bílých a modrých, v poměru 1:2:1:2:1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři modrá břevna."
    },
    {
        obec: "Rosice",
        kod: 572179,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53834,
        latitude: 49.922359,
        longitude: 15.951376,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Rosice_%28Chrudim%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Červeno-zeleně čtvrcený list. V horním žerďovém poli kráčející bílý kůň se žlutou zbrojí. V dolním žerďovém poli žlutý zvon. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře kráčející stříbrný kůň se zlatou zbrojí, dole zlatý zvon."
    },
    {
        obec: "Rosice",
        kod: 583782,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66501,
        latitude: 49.18242,
        longitude: 16.387975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Znak_Rosice_%28Brno-venkov%29.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou štikou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rosička",
        kod: 560995,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.279706,
        longitude: 14.954045
    },
    {
        obec: "Rosička",
        kod: 549941,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.538671,
        longitude: 15.842695,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Rosi%C4%8Dka_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list. Uprostřed pod knížecí čepicí kosmo modrá sekera s hnědým topůrkem, šikmo podložená černou ostrví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kosmá modrá sekera s topůrkem přirozené barvy šikmo podložená černou ostrví, vše převýšeno knížecí čepicí."
    },
    {
        obec: "Rosovice",
        kod: 541206,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26211,
        latitude: 49.756754,
        longitude: 14.109079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Coats_of_arms_Rosovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, černý, žlutý a modrý. V žerďové polovině černého pruhu žlutá koruna, pod ní ve žlutém pruhu červené jablko. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-modře polceném štítě vpravo zlaté břevno s červeným jablkem, převýšené zlatou korunou, vlevo postavený zlatý meč podložený dvěma položenými gotickými klíči nad sebou, zlatým a stříbrným."
    },
    {
        obec: "Rostěnice-Zvonovice",
        kod: 554898,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.238368,
        longitude: 16.966061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Znak_Rost%C4%9Bnice.gif",
        coatOfArmsFlagDescription: "Zelený list se šikmým žlutým pruhem vycházejícím z první šestiny dolního do páté šestiny horního okraje listu. V žerďovém poli dva bílé květy se žlutými semeníky pod sebou, ve vlajícím poli zvon s kroužkem černo-bíle dělený, nahoře se dvěma bílými pruhy. Poměr šířky k délce listu 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmý zlatý procesní patriarší kříž provázený nahoře pod sebou dvěmi stříbrnými květy se zlatými semeníky, dole černo-stříbrně děleným zvonem s kroužkem, nahoře se dvěma stříbrnými břevny."
    },
    {
        obec: "Rostoklaty",
        kod: 533661,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28171,
        latitude: 50.074329,
        longitude: 14.801559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Rostoklaty_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrým žerďovým rovnoramenným trojúhelníkem s vrcholem v horním rohu listu. V modrém poli žlutá osmicípá hvězda, ve žlutém u přepony trojúhelníka šikmo černý chroust s červenými krovkami a tykadly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zlatě zvýšeně šikmo děleném štítě nahoře zlatá osmihrotá hvězda, dole šikmo lezoucí černý chroust s červenými krovkami a tykadly."
    },
    {
        obec: "Roštění",
        kod: 588946,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76843,
        latitude: 49.367004,
        longitude: 17.538595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Ro%C5%A1t%C4%9Bn%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký dvě pětiny délky listu, svislý červený pruh široký jednu pětinu délky listu a čtyři vodorovné pruhy, střídavě bílé a černé. V zeleném pruhu kosmo postavená žlutá putýnka, v červeném středový tlapatý bílý kříž s rameny širokými jednu dvanáctinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V polceném štítě pod červenou hlavou se stříbrným heroldským tlapatým křížem vpravo v zeleném poli zlatá, kosmo postavená putýnka, levé pole třikrát stříbrno-černě dělené."
    },
    {
        obec: "Roštín",
        kod: 588954,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76803,
        latitude: 49.188888,
        longitude: 17.286157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Rostin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, horní žlutý s kněžským černým kloboukem mezi dvěma modrými šesticípými hvězdami. V dolním modrém pruhu žlutá mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý vydutý snížený hrot se zlatou mušlí, provázený dvěma modrými hvězdami. Na jeho vrcholu černý kněžský klobouk s jedním třapcem na každé straně."
    },
    {
        obec: "Rotava",
        kod: 560600,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35701,
        latitude: 50.29638,
        longitude: 12.573526,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Rotava_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý, v poměru 3:1. V žerďové a střední části červeného pruhu bílá trojramenná výplň ozubeného kola s prázdným kruhovým otvorem, jedním ramenem k hornímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Roubanina",
        kod: 513709,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.615509,
        longitude: 16.594167,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/CoA_of_Roubanina.svg",
        coatOfArmsFlagDescription: "List tvoří zelený svislý pruh široký polovinu délky listu a dva vodorovné pruhy, červený a žlutý. V zeleném pruhu žlutá ostrev se zaťatou bílou sekerou na žlutém topůrku ostřím k žerdi, obojí kosmo. V červeném pruhu bílý ondřejský kříž s rameny širokými jednu desetinu délky listu. Ve žlutém pruhu odvrácené krojidlo a radlice hrotem dolů, obojí černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zeleném poli kosmá zlatá ostrev se zleva zaťatou stříbrnou sekerou na zlatém topůrku, levé pole červeno-zlatě děleno, nahoře stříbrný ondřejský kříž, dole postavené odvrácené černé krojidlo a radlice."
    },
    {
        obec: "Roudná",
        kod: 553018,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.304487,
        longitude: 14.718671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Roudn%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký čtvrtinu délky listu a dva vodorovné pruhy, červený a bílý. V zeleném pruhu žlutá vztyčená lipová ratolest se třemi listy a plodenstvím, v červeném pruhu volný bílý kvádrovaný most s jedním obloukem, v bílém pruhu dva modré vlnité pruhy se dvěma vrcholy a třemi prohlubněmi, každý široký dvanáctinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít se stříbrným sníženým hrotem se dvěma modrými vlnitými břevny. Nahoře vpravo zlatá vztyčená lipová ratolest se třemi listy a plodenstvím, vlevo volný stříbrný kvádrovaný most s jedním obloukem."
    },
    {
        obec: "Roudné",
        kod: 544973,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.934985,
        longitude: 14.487539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Roudne_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vlnkovitý modrý, v poměru 3 : 1. V bílém pruhu zelená hlávka zelí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou zvlněnou patou zelená hlávka zelí."
    },
    {
        obec: "Roudnice",
        kod: 570745,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.176262,
        longitude: 15.651804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/CoA_of_Roudnice.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutým obilným snopem ve střední části mezi dvěma bílými čelně postavenými radlicemi v dolní části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný snop provázený dole dvěma čelně postavenými stříbrnými radlicemi."
    },
    {
        obec: "Roudnice nad Labem",
        kod: 565555,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.425305,
        longitude: 14.260565,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Roudnice_nad_Labem_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1:3:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Roudno",
        kod: 597741,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.896091,
        longitude: 17.513637,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Roudno_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým křížem s rameny širokými jednu čtvrtinu šířky listu. V kříži devět (1,7,1) červených rout, dotýkajících se okrajů kříže a sebe navzájem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný, červeně routovaný heroldský kříž, provázený nahoře dvěma stříbrnými květy tulipánu."
    },
    {
        obec: "Rouchovany",
        kod: 591629,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67557,
        latitude: 49.070461,
        longitude: 16.107705,
        coatOfArmsFlagDescription: "Zelený list, v žerďové polovině žlutý pšeničný snop, pod nímž z žerďového okraje listu vyniká pravá paže s bílým rukávem držící ke snopu obrácený srp s šedým pilovitým ostřím. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Roupov",
        kod: 558290,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33453,
        latitude: 49.538655,
        longitude: 13.249019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Roupov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, černý a bílý, a červený dolní klín vysoký dvě pětiny šířky listu. Z klínu vyrůstá od pařátů žlutá orlice s červenou zbrojí. V klínu lilie se dvěma květy tulipánů na stoncích vyrůstajících mezi horními listy lilie, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s černým kůlem zlatá orlice s červenou zbrojí, vyrůstající od pařátů ze sníženého červeného hrotu. V hrotu lilie se dvěma květy tulipánů na stoncích vyrůstajících mezi horními listy lilie, vše zlaté."
    },
    {
        obec: "Rousínov",
        kod: 593559,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.201381,
        longitude: 16.882252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Rous%C3%ADnov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým dvouocasým nekorunovaným lvem z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rouské",
        kod: 517615,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.471832,
        longitude: 17.779252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Rousk%C3%A9_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy bílý a červený v poměru 3:2. Z červeného do bílého pruhu vyrůstá zelený drak s červenou zbrojí, v červeném pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z červeného seříznutého sníženého hrotu o dvou stupních na každé straně zelený drak s červenou zbrojí. Pod ním stříbrná růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Rousměrov",
        kod: 596604,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.457769,
        longitude: 16.032622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Coat_of_arms_of_Rousm%C4%9Brov.svg",
        coatOfArmsFlagDescription: "Modrý list s kohoutem držícím v pravém pařátu hořící svíci, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kohout - plivník s rousy, držící hořící svíčku, vše zlaté."
    },
    {
        obec: "Rovečné",
        kod: 596612,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.577723,
        longitude: 16.362673,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Rove%C4%8Dn%C3%A9.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý, v poměru 3 : 1. Z modrého pruhu vynikají dvě bílá trojúhelníková pole s vrcholy v první a třetí čtvrtině horního okraje listu. Mezi nimi v černém poli žlutá houžev. V žerďovém bílém poli meč hrotem k dolnímu okraji listu, ve vlajícím bílém poli kalich, obojí červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít polcen zeleno-černo-zeleně dvěma stříbrnými hroty nad modrou patou. V pravém klínu postavený meč, v levém klínu kalich, obojí červené. Mezi klíny zlatá houžev."
    },
    {
        obec: "Rovensko",
        kod: 540919,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.907785,
        longitude: 16.876347,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Rovensko_SU_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s rakem převýšeným korunou, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě rak převýšený korunou, obojí červené."
    },
    {
        obec: "Rovensko pod Troskami",
        kod: 577472,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51263,
        latitude: 50.535418,
        longitude: 15.259515,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Rovensko_pod_Troskami_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou ležící laní s pozvednutou pravou přední nohou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná ležící laň s pozvednutou pravou přední nohou."
    },
    {
        obec: "Rovná",
        kod: 536865,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.286443,
        longitude: 13.954057,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Rovn%C3%A1_%28Strakonice_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým středovým křížem s rameny širokými šestinu šířky listu a bílým žerďovým půlkruhovým polem s modrým květem hořce jarního s bílým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné návrší s modrým květem hořce jarního se stříbrným středem. Z návrší vyniká stříbrný heroldský kříž."
    },
    {
        obec: "Rovná",
        kod: 560618,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.104512,
        longitude: 12.668852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Rovn%C3%A1_SO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a zelený. V žerďovém pruhu polovina bílého korunovaného dvouocasého lva se žlutou zbrojí nad žlutým mlýnským kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo polovina stříbrného korunovaného dvouocasého lva se zlatou zbrojí nad zlatým mlýnským kolem. Vlevo na zeleném vrchu se třemi vytékajícími stříbrnými prameny jehličnatý strom přirozené barvy."
    },
    {
        obec: "Rovná",
        kod: 561274,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.510291,
        longitude: 15.129502
    },
    {
        obec: "Rozdrojovice",
        kod: 583791,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66434,
        latitude: 49.254744,
        longitude: 16.510033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Rozdrojovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed radlice hrotem nahoru a ostřím k vlajícímu okraji, provázená v horném rohu vinným hroznem a v horním cípu vinařským nožem ostřím k žerďovému okraji, na hrotu radlice ptáček, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená radlice s ptáčkem na hrotu, provázená vpravo vinným hroznem, vlevo vinařským nožem, vše stříbrné."
    },
    {
        obec: "Rozhovice",
        kod: 547808,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.967283,
        longitude: 15.713618,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Rozhovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modro-žlutě dělený, červený a žluto-modře dělený, v poměru 1:2:1. V červeném pruhu bílý korunovaný dvouocasý lev se žlutou zbrojí a jazykem držící na žluté žerdi černo-žlutě čtvrcený praporec. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina modro-červeně polcena, v pravém poli kosmo postavený gotický klíč zuby doleva, šikmo podložený postaveným mečem, obojí zlaté, v levém poli stříbrný korunovaný dvouocasý lev se zlatou zbrojí a jazykem držící na zlaté žerdi černo-zlatě čtvrcený praporec, v dolním zlato-modře polceném poli černo-zlatě polcený stoupající půlměsíc."
    },
    {
        obec: "Rozhraní",
        kod: 578681,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56903,
        latitude: 49.601761,
        longitude: 16.533472,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Rozhran%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve středu listu, překrývající žlutý žerďový klín s vrcholem ve druhé třetině délky listu, a dva vodorovné pruhy, červený a modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít s červenou patou oddělenou zlatým zúženým břevnem. Vpravo český lev, vlevo půl doleva hledící moravské orlice přiléhající k poltící linii, v patě tři stříbrné kužely."
    },
    {
        obec: "Rozkoš",
        kod: 594733,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 49.028696,
        longitude: 15.977759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Rozko%C5%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří horní žerďový zelený trojúhelník se žlutým srdcem a bílé pole se šikmým zeleným pruhem vycházejícím z druhé dvanáctiny dolního okraje do osmé dvanáctiny horního okraje listu a se třemi zelenými smrky s černými kmeny, prostředním větším. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný vydutý hrot, v něm pod zelenou vydutou krokví tři zelené smrky s černými kmeny, prostřední větší. Nahoře dvě zlatá srdce."
    },
    {
        obec: "Rozseč",
        kod: 587800,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58866,
        latitude: 49.155992,
        longitude: 15.616703
    },
    {
        obec: "Rozseč",
        kod: 596639,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.360238,
        longitude: 16.219462,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Rozse%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý dolní klín s vrcholem na horním okraji listu, tři šikmé pruhy, zelený, bílý a zelený, vycházející z žerďového okraje, a tři kosmé pruhy modrý, bílý a modrý, vycházející z vlajícího okraje listu. V klínu šedé zkřížené sekery s hnědými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě zlatý vydutý hrot se zkříženými sekerami přirozené barvy, provázený dvěma stříbrnými býčími rohy."
    },
    {
        obec: "Rozseč nad Kunštátem",
        kod: 582271,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67973,
        latitude: 49.524499,
        longitude: 16.46313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Rozse%C4%8D_nad_Kun%C5%A1t%C3%A1tem_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, žlutý, zelený, žlutý, černý, žlutý, zelený a žlutý, v poměru 1:1:1:2:1:1:1. Uprostřed modrý kosočtverec s vrcholy na okrajích listu, v něm dvě odkloněné bílé přirozené lilie, každá s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlatý štít s modrým klínem nad zeleným trojvrším. V klínu dvě odkloněné stříbrné přirozené lilie, každá s jedním listem. Z prostředního vrchu se stříbrnými hornickými kladívky na zlatých topůrkách vyrůstají dvě černá křídla, pravé se zlatým jetelovitě zakončeným perizoniem, levé se třemi stříbrnými břevny."
    },
    {
        obec: "Rozsíčka",
        kod: 582280,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.552383,
        longitude: 16.46709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Rozs%C3%AD%C4%8Dka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový klín s vrcholem v jedenácté dvanáctině délky listu a šest svislých pruhů, střídavě žlutých a černých, v poměru 7:1:1:1:1:1. V žerďové polovině klínu žlutý kráčející lev s červenou zbrojí a jazykem, podložený postaveným bílým rýčem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-černě děleném štítě, dole se dvěma zlatými břevny, zelený zkrácený klín, v něm zlatý kráčející lev s červenou zbrojí a jazykem, podložený postaveným stříbrným rýčem."
    },
    {
        obec: "Rozsochatec",
        kod: 569399,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58272,
        latitude: 49.672641,
        longitude: 15.633903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Coa_Czech_Town_Rozsochatec.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým pruhem širokým pětinu délky listu se třemi žlutými vztyčenými lipovými listy nad sebou. V bílém poli modrá vodorovná rozsocha se třemi rameny širokými pětinu šířky listu, krajní ramena vycházejí z druhé pětiny horního a dolního okraje listu. Ramena se spojují uprostřed bílého pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se třemi vztyčenými zlatými lipovými listy vyrůstající modrá rozsocha se třemi rameny do krajů, krajními ven prohnutými."
    },
    {
        obec: "Rozstání",
        kod: 589977,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79862,
        latitude: 49.397795,
        longitude: 16.840456
    },
    {
        obec: "Rozsochy",
        kod: 596647,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59257,
        latitude: 49.520317,
        longitude: 16.200201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Rozsochy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. K modrému pruhu přiléhá půl černé zubří hlavy se žlutou houžví a červeným jazykem. V modrém pruhu vyrůstá ze srdce procesní kříž provázený dvěma liliemi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě doprava vyniká půl černé zubří hlavy se zlatou houžví a červeným jazykem, vlevo ze srdce vyrůstá procesní kříž provázený dvěma liliemi, vše zlaté."
    },
    {
        obec: "Rozstání",
        kod: 578690,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.735067,
        longitude: 16.720164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Rozst%C3%A1n%C3%AD_SY_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černým koněm ve skoku s červeným jazykem a uzděním, přidržujícím pravou zadní nohou kosmý červený štítek s bílým hřebenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černý kůň ve skoku s červeným jazykem a uzděním přidržující pravou zadní nohou kosmý červený štítek se stříbrným hřebenem."
    },
    {
        obec: "Roztoky",
        kod: 539627,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25263,
        latitude: 50.158516,
        longitude: 14.397712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Roztoky_CoA_PZ_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílo-červeně čtvrcený a bílo-červeně dělený. V horním bílém žerďovém poli černá plamenná orlice se žlutou zbrojí a jetelovitě zakončeným, rozděleným žlutým perizoniem. V horním červeném poli bílý pětilistý květ se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě vpravo černá plamenná orlice se zlatou zbrojí a jetelovitě zakončeným, rozděleným zlatým perizoniem, vlevo stříbrný pětilistý květ se zlatým středem."
    },
    {
        obec: "Roztoky",
        kod: 598526,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.02682,
        longitude: 13.867976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Roztoky_RA_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Prapor tvoří bílý list, na němž stojí černá vrána s červenou zbrojí; poměr délky k šířce je 3 : 2.",
        coatOfArmsDescription: "Ve stříbrném štítě stojí černá vrána s červenou zbrojí."
    },
    {
        obec: "Roztoky u Jilemnice",
        kod: 577499,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51231,
        latitude: 50.563887,
        longitude: 15.499852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Znakroztokyujilemnice.png",
        coatOfArmsFlagDescription: "Bílo-modře šikmým zvlněným řezem dělený list. V bílém poli šikmo černá hůlka ovinutá zeleným hadem s červeným jazykem. V modrém poli dva zkřížené meče, kosmý hlavicí k vlajícímu okraji, přes něj šikmý hlavicí k dolnímu okraji, oba bílé se žlutými jílci. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo vlnitě děleném štítě nahoře zelený had s červeným jazykem ovinutým kolem černé šikmé hůlky, dole dva kosmo položené zkřížené stříbrné meče se zlatými jílci."
    },
    {
        obec: "Roztoky u Semil",
        kod: 577481,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.65855,
        longitude: 15.371639
    },
    {
        obec: "Rozvadov",
        kod: 561169,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34806,
        latitude: 49.668279,
        longitude: 12.551998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Rozvadov_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 1:6:1. V modrém pruhu žlutý vzpínající se kůň se žlutým sedlem, červenou zbrojí, uzděním a sedláním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý kůň se zlatým sedlem, červenou zbrojí, uzděním a sedláním, vzpínající se nad zlatým vozovým kolem a provázený vlevo nahoře červenou zlatě lemovanou knížecí čepicí."
    },
    {
        obec: "Rožďalovice",
        kod: 537756,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28934,
        latitude: 50.304864,
        longitude: 15.169724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Ro%C5%BE%C4%8Falovice_znak.png"
    },
    {
        obec: "Rožmberk nad Vltavou",
        kod: 545767,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38218,
        latitude: 48.654353,
        longitude: 14.365259,
        coatOfArmsFlagDescription: "Bílý list s červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Rožmitál na Šumavě",
        kod: 545775,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38292,
        latitude: 48.701499,
        longitude: 14.388377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Rozmital_na_Sumave_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. V žerďové polovině modrého pruhu žlutá šesticípá fazetová hvězda a bílý půlměsíc s cípy k žerďovému okraji, pod nimi v bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně děleném štítě nahoře zlatá fazetová hvězda a stříbrný dorůstající půlměsíc. Dole červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Rožmitál pod Třemšínem",
        kod: 541231,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.601864,
        longitude: 13.865835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Ro%C5%BEmit%C3%A1l_pod_T%C5%99em%C5%A1%C3%ADnem_-_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se zeleným návrším vycházejícím ze druhé až čtvrté pětiny dolního okraje listu. Na zeleném návrší hrad s okrouhlou hradbou, z níž vyniká věž s cimbuřím, vše bílé a kvádrované. Věž je zakončena červenou valbovou střechou se dvěma bílými makovicemi a pod cimbuřím nese kosmo položený žlutý kolčí štítek s uťatou černou kančí hlavou s bílou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Rožná",
        kod: 596655,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59252,
        latitude: 49.477368,
        longitude: 16.23859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Rozna_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s plamenným žerďovým modrým pruhem se dvěma vrcholy a dvěma polovrcholy sahajícími do poloviny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém poli tři zlaté plameny."
    },
    {
        obec: "Rožnov",
        kod: 574384,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.338147,
        longitude: 15.86139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Ro%C5%BEnov_%28N%C3%A1chod_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, zubatý bílý, oboustranně vlnkovitý černý a bílý, v poměru 5 : l : l : 1. V zeleném pruhu dvě bílé růže se žlutými semeníky. Bílý pruh má čtyři obdélníkové zuby sahající do poloviny šířky listu a pět stejných mezer. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně cimbuřově děleném štítě nahoře dvě stříbrné růže se zlatými semeníky, dole černé vlnkovité břevno."
    },
    {
        obec: "Rožnov pod Radhoštěm",
        kod: 544841,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75661,
        latitude: 49.458626,
        longitude: 18.143131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Ro%C5%BEnov_pod_Radho%C5%A1t%C4%9Bm_znak.png",
        coatOfArmsFlagDescription: "Červený list s černým dvouocasým lvem ve skoku se žlutou korunou a zbrojí ve střední části. V žerďové a vlající části okrajové svislé bílé pruhy o šířce jedné šestiny listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná kvádrová hradba s pěti stínkami a černě prolomenou branou, na cimbuří černý dvouocasý lev ve skoku se zlatou korunou a zbrojí."
    },
    {
        obec: "Rpety",
        kod: 531715,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.828376,
        longitude: 13.938596
    },
    {
        obec: "Rtyně nad Bílinou",
        kod: 567809,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41762,
        latitude: 50.603629,
        longitude: 13.908818,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Rtyn%C4%9B_nad_B%C3%ADlinou_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou žerďovou vidlicí s rameny širokými jednu čtvrtinu šířky listu, vycházejícími z horního a dolního okraje listu. V žerďovém klínu, s vrcholem v jedné třetině délky listu, žlutý lipový čtyřlist. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vztyčená stříbrná ryba se dvěma odvrácenými hlavami, převýšená zlatým lipovým čtyřlistem."
    },
    {
        obec: "Ruda",
        kod: 596663,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.322584,
        longitude: 16.122796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Ruda_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole bílé s černým křídlem se žlutým perizoniem, dolní žerďové zelené, horní vlající červené a dolní vlající bílé s černými hornickými kladívky. Přes zelené a červené pole šikmo žlutý šíp hrotem k hornímu cípu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeno-zeleno-stříbrně čtvrceném štítě šikmo zlatý šíp, provázený vpravo nahoře černým křídlem se zlatým perizoniem, vlevo dole černými hornickými kladívky."
    },
    {
        obec: "Rtyně v Podkrkonoší",
        kod: 579637,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54233,
        latitude: 50.505329,
        longitude: 16.071973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Rtyne_v_Podkrkonosi_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový klín s vrcholem na středu vlajícího okraje. Horní trojúhelníkové pole modré, dolní zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně děleném štítě zlatá zvonice, pod ní zlatá zkřížená hornická kladívka."
    },
    {
        obec: "Ruda",
        kod: 542326,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27101,
        latitude: 50.14089,
        longitude: 13.875801,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Znak_obce_Ruda.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý, v poměru 2:1. V černém pruhu zkřížená bílá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě černá pata se zkříženými stříbrnými hornickými kladívky."
    },
    {
        obec: "Ruda nad Moravou",
        kod: 540978,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78963,
        latitude: 49.981095,
        longitude: 16.877925,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Ruda_nad_Moravou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý, v poměru 5:1:1:1. Do červeného pruhu vyrůstá bílý listnatý strom se žlutým kmenem provázený dolů do oblouku šesti žlutými šesticípými hvězdami. V koruně stromu kosmý mlátek šikmo podložený želízkem, obojí modré. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném trojvrší s modrým vlnitým břevnem stříbrný listnatý strom se zlatým kmenem provázený dolů do oblouku šesti zlatými hvězdami. V koruně stromu kosmý mlátek šikmo podložený želízkem, obojí modré."
    },
    {
        obec: "Rudice",
        kod: 582298,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.337274,
        longitude: 16.725919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/CoA_of_Rudice.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutými zkříženými hornickými kladívky nad žlutým půlměsícem hroty vzhůru ve střední části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížená hornická kladívka, pod nimi půlměsíc hroty vzhůru, vše zlaté."
    },
    {
        obec: "Rudice",
        kod: 592552,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68732,
        latitude: 49.056153,
        longitude: 17.737584,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/CoA_of_Rudice_%28okres_Uhersk%C3%A9_Hradi%C5%A1t%C4%9B%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, červený a zelený, v poměru 1:4:1. V červeném pruhu bílý zvon se žlutě zdobenou červenou knížecí korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zeleným trojvrším stříbrný zvon s knížecí korunou."
    },
    {
        obec: "Rudíkov",
        kod: 591637,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67505,
        latitude: 49.288117,
        longitude: 15.94605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Znak_obce_Rud%C3%ADkov.jpg",
        coatOfArmsFlagDescription: "Bílo-černě šikmo dělený list. Do bílého pole vyrůstá zelený stonek s leknínovým listem. V černém poli dva šikmé pruhy, bílý, vycházející ze druhé šestiny dolního a vlajícího okraje, a žlutý, vycházející ze čtvrté šestiny dolního a vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-černě šikmo děleném štítě vyrůstá nahoře zelený stonek s leknínovým listem, dole šikmo postavený stříbrný meč se zlatým jílcem, pod ním šikmo vztyčený zlatý gotický klíč zuby ven."
    },
    {
        obec: "Rudimov",
        kod: 586919,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.079796,
        longitude: 17.828258,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Rudimovznak.jpg",
        coatOfArmsFlagDescription: "Zelený list se zkříženými žlutými berlami závity ven, s bílými a červenými sudarii, proloženými uprostřed bílým veslem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené zlaté berly se stříbrnými a červenými sudarii, proložené uprostřed postaveným stříbrným veslem."
    },
    {
        obec: "Rudka",
        kod: 583804,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.242573,
        longitude: 16.331102,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Rudka_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým ondřejským křížem s rameny širokými jednu dvacetinu šířky listu. V horní části mezi rameny kříže zkřížené mlátky, proložené vztyčeným kopáčem, vše bílé na žlutých topůrkách a násadě. Mezi zbývajícími rameny po jedné žluté růži. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené zlaté berly se stříbrnými sudarii, nahoře zkřížené mlátky proložené vztyčeným kopáčem, vše stříbrné se zlatými topůrky a násadou, po stranách a dole tři zlaté růže."
    },
    {
        obec: "Rudlice",
        kod: 594741,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.948588,
        longitude: 16.065799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Rudlice_CoA.jpg"
    },
    {
        obec: "Rudná",
        kod: 578703,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.659222,
        longitude: 16.573374,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Rudn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zeleno-černě šikmo dělený list se žlutým kosmým pruhem širokým jednu čtvrtinu šířky listu. Přes dolní zeleno-černé pole kosmo bílý srp se žlutou rukojetí k dolnímu cípu a ostřím ke žlutému pruhu. V horním zeleno-černém poli hornická kladívka, želízko bílé a mlátek žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-černě šikmo děleném štítě kosmo vztyčený rýč, provázený vpravo kosmým stříbrným srpem ostřím nahoru se zlatou rukojetí, vlevo hornická kladívka - želízko stříbrné a mlátek zlatý."
    },
    {
        obec: "Rudná",
        kod: 531723,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25219,
        latitude: 50.035128,
        longitude: 14.234454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Znak_m%C4%9Bsta_Rudn%C3%A1.png",
        coatOfArmsFlagDescription: "Dva vodorovné pruhy, horní červený se žlutou poštovní trubkou u žerdě, dolní černý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě děleném štítě zlatá poštovní trubka."
    },
    {
        obec: "Rudná pod Pradědem",
        kod: 551945,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79331,
        latitude: 50.020519,
        longitude: 17.377529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Rudn%C3%A1_pod_Prad%C4%9Bdem_znak.png",
        coatOfArmsFlagDescription: "Žlutý list s černým čápem s červeným zobákem a pařáty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vykračující černý čáp s červenou zbrojí."
    },
    {
        obec: "Rudník",
        kod: 579645,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54372,
        latitude: 50.578698,
        longitude: 15.736956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Rudnik_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený a bílý. Ve středu červeného pruhu vodorovně pět žlutých šesticípých hvězd vzdálených od sebe vždy jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstají stříbrná věž s cimbuřím se dvěma černými gotickými okny nad sebou. Nad ní pět zlatých hvězd vedle sebe."
    },
    {
        obec: "Rudolec",
        kod: 596671,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59214,
        latitude: 49.47692,
        longitude: 15.830304,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Rudolec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý se třemi vrcholy a čtyřmi prohlubněmi. V červeném pruhu žlutá oblouková pila s bílým listem, v modrém pruhu přivrácená vztyčená žlutá račí klepeta. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě nahoře zlatá oblouková pila se stříbrným listem, dole dvě přivrácená vztyčená zlatá račí klepeta."
    },
    {
        obec: "Rudolfov",
        kod: 544981,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37371,
        latitude: 48.993496,
        longitude: 14.541889,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Rudolfov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký polovinu délky listu a osm střídavě bílých a červených vodorovných pruhů. V modrém pruhu písmeno R převýšené korunou, oboje žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rudoltice",
        kod: 580848,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56125,
        latitude: 49.898427,
        longitude: 16.569965,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Znak_obce_Rudoltice.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1:4:1. Uprostřed žlutý rak převýšený knížecí korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý rak převýšený knížecí korunou, držící v pravém klepetu klíč zuby doleva, v levém meč, obojí stříbrné a postavené."
    },
    {
        obec: "Rumburk",
        kod: 562777,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40801,
        latitude: 50.953525,
        longitude: 14.555255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Rumburk_CoA_CZv3.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený v poměru 4:1:10:1:4. V modrém pruhu bílá lilie s osou v první třetině délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ruprechtov",
        kod: 593567,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68304,
        latitude: 49.32875,
        longitude: 16.851494,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Ruprechtov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý. V bílých polích červené klíny s vrcholy na středovém pruhu. V zeleném pruhu bílá zavinutá střela převýšená červenou, žlutě lemovanou knížecí čepicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný snížený hrot, v něm červený větrný mlýn se zlatými lopatkami převýšený červenou svatojakubskou mušlí. Na hrotu červená, zlatě lemovaná knížecí čepice provázená dvěma stříbrnými zavinutými střelami."
    },
    {
        obec: "Rusava",
        kod: 588962,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76841,
        latitude: 49.348527,
        longitude: 17.689193,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Rusava_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý středový kříž o šířce ramene jedné třetiny šířky listu. Horní žerďové a dolní vlající pole je červené, dolní žerďové a horní vlající pole je modré. Poměr šířky k délce listu praporu je 2:3.",
        coatOfArmsDescription: "Stříbrným heroltským křížem čtvrcený červeno-modrý štít. Ve druhém poli zvonec s ouškem, ve třetím sekera bez topůrka, vše stříbrné."
    },
    {
        obec: "Rusín",
        kod: 597775,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79397,
        latitude: 50.209884,
        longitude: 17.734323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Rus%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 3:1. V žerďové polovině modrého pruhu bílá orlice s červeným šestilistým květem na hrudi, provázená dole dvěma bílými šestilistými květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad dolní polovinou stříbrného mlýnského kamene s černou kypřicí stříbrná orlice s červeným květem na hrudi, dole provázená dvěma stříbrnými květy."
    },
    {
        obec: "Rušinov",
        kod: 569402,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.791978,
        longitude: 15.683152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Ru%C5%A1inov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký polovinu délky listu s černým kosmým pruhem širokým čtvrtinu šířky listu a dva vodorovné pruhy modrý a zubatý bílý se třemi hroty, v poměru 2 : 5. V bílém pruhu červený kostel se sanktusníkem, s černým obloukovým vchodem a třemi černými okny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-stříbrně polcený štít, vpravo kosmé černé břevno, vlevo pod modrou pilovitou hlavou červený kostel se sanktusníkem, s černým obloukovým vchodem a třemi černými okny."
    },
    {
        obec: "Růžďka",
        kod: 544850,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75625,
        latitude: 49.394052,
        longitude: 17.995701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/CZ_R%C5%AF%C5%BE%C4%8Fka_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, horní červený, dolní modrý. Na středu listu k žerdi hledící bílá rozkřídlená holubice bez nohou se zelenou lipovou snítkou v zobáku a červenou růží se žlutým semeníkem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítu stříbrná rozkřídlená holubice bez nohou, se zelenou lipovou snítkou v zobáku a červenou růží se zlatým semeníkem na hrudi."
    },
    {
        obec: "Růžená",
        kod: 587818,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58901,
        latitude: 49.270494,
        longitude: 15.428507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/R%C5%AF%C5%BEen%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:4:1. V modrém pruhu věnec z pěti žlutých růží (1,2,2) s červenými semeníky a zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zelený věnec s pěti (1,2,2) zlatými růžemi s červenými semeníky."
    },
    {
        obec: "Růžová",
        kod: 566900,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.842265,
        longitude: 14.293898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/R%C5%AF%C5%BEov%C3%A1_%28okres_D%C4%9B%C4%8D%C3%ADn%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a černý, v poměru 6 : 1 : 1. V žerďové polovině červeného pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se stříbrno - černě polceným levým bokem stříbrná růže se zlatým semeníkem a zelenými kališními lístky na zeleném trnitém stonku se čtyřmi listy přeloženém kosmým vztyčeným zlatým klíčem šikmo podloženým vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Rybí",
        kod: 568554,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74265,
        latitude: 49.600825,
        longitude: 18.076024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Ryb%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. V žerďovém poli šikmo položená bílá ryba hlavou k hornímu cípu, ve vlajícím poli červená lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě šikmo položená stříbrná ryba, pod ní na šikmo vztyčené stříbrné radlici červená lilie."
    },
    {
        obec: "Rybitví",
        kod: 575593,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53354,
        latitude: 50.060247,
        longitude: 15.704827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Znak_obce_Rybitv%C3%AD.gif",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a sedm vodorovných pruhů, střídavě modrých a bílých, v poměru 5:2:2:2:2:2:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře dělený štít, nahoře černý pluh (ruchadlo) se stříbrnou radlicí, dole tři (3,2,1) stříbrní plovoucí kapři, spodní doleva."
    },
    {
        obec: "Rybná nad Zdobnicí",
        kod: 576727,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51755,
        latitude: 50.10864,
        longitude: 16.365963,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Rybn%C3%A1_nad_Zdobnic%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a sedm vodorovných, střídavě modrých a bílých pruhů. V červeném pruhu vyskakující ryba nad svatojakubskou mušlí, oboje bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo vyskakující ryba nad svatojakubskou mušlí, vlevo tři vlnitá břevna, vše stříbrné."
    },
    {
        obec: "Rybné",
        kod: 587826,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.42295,
        longitude: 15.736398,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Rybn%C3%A9_znak.png",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými kapry nad sebou, horní plovoucí k vlajícímu okraji, dolní k žerďovému. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dva nad sebou opačně plující stříbrní kapři, horní doleva."
    },
    {
        obec: "Rybnice",
        kod: 559431,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33151,
        latitude: 49.908441,
        longitude: 13.373838
    },
    {
        obec: "Rybníček",
        kod: 553972,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.273976,
        longitude: 17.073903,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Znak_-_coat_of_arms_of_Rybn%C3%AD%C4%8Dek_%28Vy%C5%A1kov_District%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, horní červený s bílým žerďovým klínem s hrotem v polovině červeného vlajícího okraje, dolní pruh modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít, vpravo tři (2,1) stříbrné kužely, vlevo stříbrná vztyčená štika."
    },
    {
        obec: "Rybníček",
        kod: 569411,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.76947,
        longitude: 15.509141,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Rybn%C3%AD%C4%8Dek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 11 : 1 : 1 : 1 : 6, střední pruhy oboustranně vlnkovité. Z horního bílého pruhu vyrůstá lípa, ve střední části dolního zeleného pruhu vyrůstají z dolního okraje tři obilné klasy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ve sníženém stříbrném vlnkovitém břevnu modré vlnkovité břevno. Nahoře vyrůstá zlatá lípa. Dole tři vyrůstající zlaté obilné klasy."
    },
    {
        obec: "Rybník",
        kod: 547905,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56002,
        latitude: 49.888725,
        longitude: 16.474526,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Rybn%C3%ADk.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený se žlutou korunou v žerďové části a modrý zvlněný. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě nahoře vyrůstající zlaté stavidlo převýšené zlatou korunou a provázené dvěma vyrůstajícími přivrácenými stříbrnými lekny."
    },
    {
        obec: "Rybník",
        kod: 554189,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34525,
        latitude: 49.512872,
        longitude: 12.676863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Rybn%C3%ADk_%28Doma%C5%BElice_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a žluto-zeleně polcený. V bílém pruhu modrý vlnitý pruh široký šestinu šířky listu s jedním vrcholem a dvěma prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít se stříbrnou hlavou s modrým vlnitým břevnem. V pravém poli jehličnatý strom přirozené barvy, v levém poli zlatá hlava jelena s krkem a se stříbrným parožím."
    },
    {
        obec: "Rybníky",
        kod: 594750,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67201,
        latitude: 49.026365,
        longitude: 16.27947,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Rybn%C3%ADky_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, žlutý, modrý, žlutý, červený a žlutý, v poměru 3:4:1:1:1. V žerďovém pruhu pod sebou červený osmihrotý kříž, zkřížené černé ostrve a červená šesticípá hvězda. V modrém pruhu bílý procesní patriarší kříž provlečený korunou a obtočený stonkem vinné révy, na žerďové straně se dvěma listy a na vlající straně se dvěma hrozny, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo černé zkřížené ostrve provázené nahoře osmihrotým křížem dole hvězdou, obojí červené. Vlevo stříbrný procesní patriarší kříž provlečený korunou a obtočený stonkem vinné révy vpravo se dvěma listy, vlevo se dvěma hrozny, vše zlaté."
    },
    {
        obec: "Rybníky",
        kod: 541257,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.752744,
        longitude: 14.206005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Rybn%C3%ADky_%28P%C5%99%C3%ADbram_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 1 : 3. V bílém pruhu pět modrých šesticípých hvězd. V modrém pruhu tři orobince, každý se dvěma listy, prostřední posunutý k dolnímu okraji listu a převýšený řeckým křížem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnkovitou hlavou s pěti modrými hvězdami tři volné orobince se dvěma listy, prostřední dolů posunutý a převýšený řeckým křížem, vše zlaté."
    },
    {
        obec: "Rybniště",
        kod: 562793,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40751,
        latitude: 50.880068,
        longitude: 14.515148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Rybni%C5%A1t%C4%9B_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým klínem vycházejícím z druhé a třetí čtvrtiny žerďového okraje, s vrcholem sahajícím do jedné čtvrtiny délky listu a s bílou krokví vycházející z první poloviny horního a dolního okraje listu s vrcholem na středu vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná krokev, na ní zavěšená zlatá rybářská síť."
    },
    {
        obec: "Rychnov na Moravě",
        kod: 578711,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56934,
        latitude: 49.829244,
        longitude: 16.640214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Rychnov_na_Morav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně šachovaným (2 x 8) žerďovým pruhem širokým šestinu délky listu. U pruhu srdce, z něhož vyrůstají tři šestilisté květy na stoncích s listy, provázenými po stranách koulemi, vše bílé. V srdci červená koule. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrno-červeně šachovanou hlavou srdce kvetoucí třemi šestilistými květy na stoncích s listy, provázené po stranách koulemi, vše stříbrné. V srdci červená koule."
    },
    {
        obec: "Rychnov nad Kněžnou",
        kod: 576069,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51601,
        latitude: 50.166065,
        longitude: 16.277784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Rychnov_nad_Kn%C4%9B%C5%BEnou_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a červený v poměru 2:1:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rychnov u Jablonce nad Nisou",
        kod: 563790,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46802,
        latitude: 50.683891,
        longitude: 15.149898,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Rychnov_u_Jablonce_nad_Nisou_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý v podobě mostu se dvěma pilíři, a zelený, v poměru 3 : 2 : 7. Most má jeden celý a dva poloviční oblouky, pilíře jsou široké šestinu délky listu, s patou šestinu šířky listu od dolního okraje. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rychnovek",
        kod: 574406,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55225,
        latitude: 50.357467,
        longitude: 15.970419,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Rychnovek_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 12:1:1:1:1. V horním pruhu dva k sobě obrácení vzpřímení žlutí koně s propletenýma předníma nohama podloženýma bílým procesním lateránským křížem, provázení za hlavami bílým vztyčeným dubovým listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad třikrát stříbrno-modře vlnitě dělenou patou dva přivrácení vzpřímení zlatí koně s propletenýma předníma nohama, podloženýma stříbrným procesním lateránským křížem. V rozích dva vztyčené stříbrné dubové listy."
    },
    {
        obec: "Rychvald",
        kod: 599107,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73532,
        latitude: 49.86635,
        longitude: 18.376369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Rychvald_CoA.svg",
        coatOfArmsFlagDescription: "List praporu opakuje městský znak; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Stříbrnomodře dělený štít, nahoře z dělící linky vyrůstají tři modré lipové lístky, dole stříbrné břevno."
    },
    {
        obec: "Ryjice",
        kod: 546186,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40331,
        latitude: 50.688055,
        longitude: 14.092111
    },
    {
        obec: "Rýmařov",
        kod: 597783,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79501,
        latitude: 49.931927,
        longitude: 17.271873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Rymarov_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutým vlkem s červeným jazykem ve skoku prostřeleným šikmo žlutým šípem (List praporu opakuje městský znak). Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Rymice",
        kod: 588971,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.342008,
        longitude: 17.526316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Rymice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďové modré pole široké 7/15 délky listu a vlající pole s pěti střídavě bílými a červenými kosmými pruhy širokými jednu čtvrtinu šířky listu. V modrém poli polovina bílé býčí hlavy s červeným jazykem, žlutým rohem a kruhem v nozdrách, vzdálená 1/20 šířky listu od vlajícího pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli polovina stříbrné býčí hlavy s červeným jazykem, zlatým rohem a kruhem v nozdrách. Levé pole 4x stříbrno-červeně kosmo dělené."
    },
    {
        obec: "Rynárec",
        kod: 562009,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39401,
        latitude: 49.389706,
        longitude: 15.234826,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Ryn%C3%A1rec_COA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový červený s bílým leknem vyrůstajícím z dělící čáry a vlající modrý s polovinou žlutého klévského kola přiléhajícího k červenému pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo vyrůstá z dělící čáry stříbrné lekno, vlevo přiléhá půl zlatého klévského kola."
    },
    {
        obec: "Rynholec",
        kod: 542334,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27062,
        latitude: 50.138031,
        longitude: 13.922328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Rynholec_znak.gif",
        coatOfArmsFlagDescription: "Zelený list se žlutou krokví vycházející z první poloviny horního a dolního okraje s vrcholem na středu vlajícího okraje. V žerďovém klínu zkřížená žlutá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstající těžní věž, nad ní zkřížená hornická kladívka, vše zlaté."
    },
    {
        obec: "Ryžoviště",
        kod: 597791,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79356,
        latitude: 49.875695,
        longitude: 17.358331,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/R%C3%BD%C5%BEovi%C5%A1t%C4%9B_znak.png",
        coatOfArmsFlagDescription: "Modrý list mřežovaný devíti kosmými a devíti šikmými žlutými pruhy širokými jednu čtyřicetinu šířky listu s šikmo položenou bílou rybou hlavou k hornímu cípu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém zlatě mřežovaném štítě stříbrná šikmo položená ryba."
    },
    {
        obec: "Rynoltice",
        kod: 564397,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46353,
        latitude: 50.789106,
        longitude: 14.818942,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Rynoltice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu kráčející bílý lev se žlutou zbrojí a jazykem a s kosmým gotickým černo-žlutě polceným štítkem na rameni. V bílém pruhu brusinkový keř se dvěma hnědými odkloněnými větévkami, každá o pěti zelených listech, uprostřed pět (1, 2, 2) červených plodů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na třech stříbrných vrších kráčející stříbrný lev se zlatou zbrojí a jazykem a s kosmým gotickým černo-zlatě polceným štítkem na rameni. Ve vrších brusinkový keř se dvěma odkloněnými větévkami, každá o pěti listech, uprostřed pět (1, 2, 2) plodů, vše přirozené barvy."
    },
    {
        obec: "Řásná",
        kod: 587834,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.221126,
        longitude: 15.391382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Coat_of_arms_of_%C5%98%C3%A1sn%C3%A1.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým bílým vydutým klínovým pruhem, širokým třetinu délky listu a s vrcholem ve třetí čtvrtině délky listu, v pruhu zelená listová řasa. Ve vlající části nahoře osmicípá hvězda, dole růže, obojí žluté. Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený stříbrný vydutý hrot se zelenou listovou řasou, nahoře provázený vpravo osmihrotou hvězdou, vlevo růží, obojí zlaté."
    },
    {
        obec: "Řečany nad Labem",
        kod: 575607,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53313,
        latitude: 50.03599,
        longitude: 15.477455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/%C5%98e%C4%8Dany_nad_Labem_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý s třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 5 : 1 : 2. V žerďové polovině červeného pruhu bílá dóza na mast. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře sníženým zúženým vlnitým břevnem děleném štítě nahoře dóza na mast provázená liliovými hůlkami, dole ryba, vše stříbrné."
    },
    {
        obec: "Řečice",
        kod: 548715,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.602983,
        longitude: 15.371725,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/%C5%98e%C4%8Dice_PE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 2 : 3. V bílém pruhu červený kříž s kratším horním ramenem a s dolním ramenem zakončeným kružbou. V modrém pruhu žlutý zvon provázený dvěma vztyčenými bílými trojlístky na stoncích, v žerďové části kosmým, ve vlající části šikmým. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře zvýšeně děleném štítě nahoře červený kříž do krajů s dolním ramenem zakončeným trojdílnou kružbou. Dole zlatý zvon provázený dvěma stříbrnými vztyčenými trojlístky na stoncích."
    },
    {
        obec: "Řečice",
        kod: 596680,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59233,
        latitude: 49.513093,
        longitude: 16.063959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/%C5%98e%C4%8Dice_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů černý, bílý, černý, bílý, černý, bílý a modrý v poměru 1:1:1:1:1:5:12. V modrém pruhu bílý beran se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře vlnitě kosmo dělený štít, nahoře v černé hlavě dvě stříbrná břevna, dole stříbrný beran se zlatou zbrojí."
    },
    {
        obec: "Řehenice",
        kod: 538710,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25167,
        latitude: 49.869849,
        longitude: 14.650331,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/%C5%98ehenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed listu bílá rozevřená kniha, obklopená do kruhu sedmi (2, 2, 2, 1) odkloněnými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná otevřená kniha obklopená do kruhu sedmi (2, 2, 2, 1) odkloněnými zlatými lipovými listy."
    },
    {
        obec: "Řehlovice",
        kod: 568201,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40313,
        latitude: 50.607226,
        longitude: 13.954233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Znak_obce_%C5%98ehlovice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. V červeném pruhu osm (3, 2, 3) žlutých korun, v zeleném pruhu bílý pluh s kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně dělený štít. Nahoře osm (3, 2, 3) zlatých korun, dole stříbrný pluh s kolem."
    },
    {
        obec: "Řeka",
        kod: 552640,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73955,
        latitude: 49.641202,
        longitude: 18.571511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Rzeka_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy bílý, zelený vlnkovaný, bílý vlnitý a modrý zvlněný, v poměru 3:9:2:2. V žerďové části zeleného pruhu žlutý vykořeněný listnatý strom. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad stříbrno-modře vlnitě dělenou vlnitou patou ve zvýšeném zeleném trojvrší zlatý vykořeněný listnatý strom."
    },
    {
        obec: "Řemíčov",
        kod: 560511,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.515076,
        longitude: 14.775093,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Coat_of_arms_of_%C5%98em%C3%AD%C4%8Dov.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, v poměru 2 : 3. Na styku pruhů hornická kladívka nad kruhovým polem, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě hornická kladívka nad koulí, vše opačných barev."
    },
    {
        obec: "Řendějov",
        kod: 534366,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.767946,
        longitude: 15.080471
    },
    {
        obec: "Řenče",
        kod: 558303,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.579972,
        longitude: 13.4146
    },
    {
        obec: "Řepeč",
        kod: 553034,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39161,
        latitude: 49.403544,
        longitude: 14.51966,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/%C5%98epe%C4%8D_CoA.jpg"
    },
    {
        obec: "Řepice",
        kod: 536849,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.280612,
        longitude: 13.933539,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/%C5%98epice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy bílý se třemi černými liliemi vedle sebe a modrý se žlutým kaprem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré břevno se zlatým kaprem provázené třemi (2,1) černými liliemi."
    },
    {
        obec: "Řepín",
        kod: 535168,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27733,
        latitude: 50.366642,
        longitude: 14.634675,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/%C5%98ep%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a červené pole. V pruhu pod pětilistou korunou lilie, dole obklopená zkříženými převázanými vavřínovými ratolestmi, vše žluté. V žerďové polovině červeného pole přirozená bílá lilie na zeleném stonku s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo pod pětilistou korunou lilie, dole obklopená zkříženými převázanými vavřínovými ratolestmi, vše zlaté. Vlevo přirozená lilie na stonku s listy."
    },
    {
        obec: "Řepiště",
        kod: 568830,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73932,
        latitude: 49.733473,
        longitude: 18.317179,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Repiste_znak.png",
        coatOfArmsFlagDescription: "List tvoří bílé trojúhelníkové pole s vrcholem na středu horního okraje listu, jeho základnu tvoří dolní okraj listu. Zbytek listu je modrý, na žerďovém trojúhelníku je žlutá řepa, na vlajícím trojúhelníku žlutý vinný hrozen s listem. Na středu bílého pole červený rak s klepety vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se stříbrnou prohnutou špicí. Vpravo zlatá řepa, vlevo zlatý vinný hrozen s listem, ve špici červený rak."
    },
    {
        obec: "Řepníky",
        kod: 572209,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53865,
        latitude: 49.901232,
        longitude: 16.079849,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/%C5%98epn%C3%ADky_znak.JPG",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. V modrém pruhu bílý patriarší kříž se svislými a dolními vodorovnými rameny prodlouženými na okraje listu, na rameni v červeném pruhu je zavěšen žlutý zvon s bílým svěšeným květem konvalinky místo srdce, nad zvonem dva bílé svěšené květy konvalinky vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo pod dvěma svěšenými stříbrnými květy konvalinek zlatý zvon se stříbrným květem konvalinky místo srdce. Zvon je zavěšen na prodlouženém pravém ramenu dolního břevna patriaršího kříže v levém poli."
    },
    {
        obec: "Řepov",
        kod: 536580,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.402437,
        longitude: 14.957103,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Coat_of_arms_of_%C5%98epov.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě bílé a červené, v poměru 1 : 1 : 1 : 9. Ve vlajícím pruhu bílý vykračující kohout se žlutou zbrojí, před ním bílá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou hlavou s červeným břevnem stříbrný vykračující kohout se zlatou zbrojí provázený vpravo stříbrnou hvězdou."
    },
    {
        obec: "Řeřichy",
        kod: 565504,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.079276,
        longitude: 13.585937,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Coats_of_arms_%C5%98e%C5%99ichy.jpeg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným listem řeřichy se dvěma červenými květy na zelených stoncích. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený list řeřichy se dvěma červenými květy na zelených stoncích."
    },
    {
        obec: "Řestoky",
        kod: 572217,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.922503,
        longitude: 15.916661,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/%C5%98estoky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1 : 3 : 1. V žerďové polovině červeného pruhu nad rozevřenou bílou knihou se žlutými deskami bílý psací brk hrotem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě doleva položený stříbrný psací brk nad rozevřenou stříbrnou knihou se zlatými deskami."
    },
    {
        obec: "Řetová",
        kod: 580872,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56141,
        latitude: 49.945893,
        longitude: 16.38133,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/%C5%98etov%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, zelený, bílý, červený, bílý, červený, bílý a modrý, v poměru 7:1:2:8:2:1:7. V žerďovém a vlajícím pruhu po jedné bílé rostlině rýtu (mařinky barvířské) se třemi květy se žlutými středy a dvěma listovými růžicemi s osmi listy. V prostředním bílém pruhu černý milíř se žlutým plamenem uprostřed. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dvakrát zeleno-stříbrno-modře polcený. Uprostřed dvě zkřížené přivrácené červené kosy bez kosišť hroty dolů nad černým milířem se zlatým plamenem uprostřed. V krajních polích po jedné stříbrné rostlině rýtu (mařinky barvířské) se třemi květy se zlatými středy a dvěma listovými růžicemi s osmi listy."
    },
    {
        obec: "Řetůvka",
        kod: 580881,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56141,
        latitude: 49.95587,
        longitude: 16.356124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/%C5%98et%C5%AFvka.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký jednu třetinu délky listu a modré pole s bílým ondřejským křížem s rameny vycházejícími z první a čtvrté čtvrtiny horního a dolního okraje pole. Mezi rameny kříže u horního, vlajícího a dolního okraje po žluté šesticípé hvězdě. V bílém pruhu černý mlok se žlutými skvrnami, hlavou k hornímu rohu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou s mlokem přirozené barvy dvě zkřížená vztyčená krojidla provázená po stranách a dole třemi zlatými hvězdami."
    },
    {
        obec: "Řevnice",
        kod: 539643,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25230,
        latitude: 49.914064,
        longitude: 14.235875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/COA_%C5%98evnice.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený a čtyři střídavě bílé a modré, v poměru 6 : 1 : 1 : 1 : 1 . V červeném pruhu zkřížené žluté ostrve. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře vlnitě děleném štítě zkřížené zlaté ostrve, obě bez středního vnitřního suku. V horním poli český lev, v dolním poli tři stříbrná vlnitá břevna."
    },
    {
        obec: "Řevničov",
        kod: 542351,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.184992,
        longitude: 13.808344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Coats_of_arms_%C5%98evni%C4%8Dov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy zelený, žlutý s černou poštovskou trubkou a černý v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě pod zlatou hlavou s chmelovým listem a čtyřmi šišticemi, vše zelené, zlatá poštovská trubka."
    },
    {
        obec: "Říčany",
        kod: 583839,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66482,
        latitude: 49.215075,
        longitude: 16.393723,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Ricany_BO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed žlutý gotický dvojklíč s bílými sekerami - bradaticemi místo zubů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě zlatý gotický dvojklíč se stříbrnými sekyrami - bradaticemi místo zubů."
    },
    {
        obec: "Řícmanice",
        kod: 583821,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66401,
        latitude: 49.257564,
        longitude: 16.694045
    },
    {
        obec: "Říčky",
        kod: 549789,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66483,
        latitude: 49.233025,
        longitude: 16.355945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/%C5%98%C3%AD%C4%8Dky_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se svislým bílým vlnitým pruhem s třemi vrcholy a dvěma prohlubněmi, vycházejícím z druhé šestiny horního a dolního okraje listu. Ve vlajícím poli žluté vozové kolo nad obrácenou žlutou podkovou s bílou kypřicí mezi rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře vpravo vlnitě polceném štítě vpravo modrý volský roh, vlevo pod zlatým vozovým kolem obrácená zlatá podkova, v ní stříbrná kypřice."
    },
    {
        obec: "Říčany",
        kod: 538728,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.991778,
        longitude: 14.654376,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/CoA_Ricany.png",
        coatOfArmsFlagDescription: "List se dvěma vodorovnými pruhy. Horní bílý s kosmým černo-bílým karé, dolní pruh světle modrý. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná brána se dvěma věžemi, zlatou zvednutou mříží a pěti stínkami. Věže mají červené kuželové střechy se zlatými makovicemi. Mezi věžemi černo-stříbrně kosmo dělený štítek rodu Smiřických."
    },
    {
        obec: "Říčky v Orlických horách",
        kod: 576735,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51761,
        latitude: 50.211092,
        longitude: 16.45912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/%C5%98%C3%AD%C4%8Dky_v_Orlick%C3%BDch_hor%C3%A1ch_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1 : 3 : 1. V bílém pruhu vykořeněný zelený smrk s hnědým kmenem podloženým zkříženými šedými sekerami s hnědými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vykořeněný smrk s kmenem podloženým zkříženými sekerami, vše přirozené barvy."
    },
    {
        obec: "Řídeč",
        kod: 554103,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.765829,
        longitude: 17.25688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Ridec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 5 : 2 : 5. Přes ně šikmo bílá sekera ostřím k dolnímu cípu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý topol s kmenem šikmo přeloženým stříbrnou sekerou."
    },
    {
        obec: "Řídký",
        kod: 572233,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.894209,
        longitude: 16.243209
    },
    {
        obec: "Řídelov",
        kod: 587851,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.234597,
        longitude: 15.406922,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/%C5%98%C3%ADdelov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým palečným kolem, uprostřed se žlutou růží s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné palečné kolo, uprostřed se zlatou růží s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Říkov",
        kod: 530786,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55203,
        latitude: 50.384385,
        longitude: 16.016933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/%C5%98%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři střídavě modré a bílé vodorovné pruhy, v poměru 17 : 1 : 1 : 1. Z prvního bílého pruhu vyrůstá čelně žlutý anděl s bílým obličejem a rukama, přidržující před sebou zelenou jabloň s červenými plody a hnědým kmenem vyrůstajícím z dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá čelně ze stříbrné vlnité paty s modrým vlnitým břevnem zlatý anděl přidržující před sebou jabloň přirozené barvy s červenými plody vyrůstající z dolního okraje štítu."
    },
    {
        obec: "Římov",
        kod: 545007,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37324,
        latitude: 48.855719,
        longitude: 14.48694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/R%C3%ADmov_znak.png",
        coatOfArmsFlagDescription: "List dělený šikmým modrým pruhem širokým jednu dvacetinu délky listu na červenou žerďovou část se dvěma bílými šípy hrotu nahoru a bílou vlající část s modrou piniovou šiškou se zelenými okvětními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo dva stříbrné šípy hroty nahoru, vlevo pod zvýšeným modrým břevnem modrá piniová šiška se zelenými listy."
    },
    {
        obec: "Říkovice",
        kod: 517666,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75118,
        latitude: 49.381646,
        longitude: 17.451338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Rikovice-znak.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu se třemi vlajícími bílými klíny a modré pole se třemi zkříženými bílými řemdihy na žlutých násadách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se třemi kužely tři zkřížené stříbrné řemdihy na zlatých násadách."
    },
    {
        obec: "Římov",
        kod: 591645,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.170178,
        longitude: 15.757671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Rimov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílo-červeně šachovaný (3 x 15) a modrý, v poměru 5:3:2. V horním pruhu tři bílé květy vachty trojlisté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně třemi řadami šachované břevno, provázené nahoře třemi květy vachty trojlisté a dole jetelovým trojlistem, vše stříbrné."
    },
    {
        obec: "Řikonín",
        kod: 596698,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.364487,
        longitude: 16.305808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/%C5%98ikon%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený, v poměru 5 : 2. Ve vlající polovině zeleného pruhu žlutá laň, v žerďové polovině provázená u hlavy bílou lilií. V červeném pruhu bílá radlice hrotem k žerďovému a ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad červenou patou s položenou stříbrnou radlicí zlatá laň provázená vpravo nahoře stříbrnou lilií."
    },
    {
        obec: "Řimovice",
        kod: 532941,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.696448,
        longitude: 14.943106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/%C5%98imovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílým křížem s rameny vycházejícími ze čtvrté a páté osminy žerďového okraje a druhé šestiny horního okraje listu. V horním a dolním vlajícím poli vodorovný žlutý pruh široký osminu šířky listu. Ve svislém rameni kříže tři modré šesticípé hvězdy pod sebou, ve vlajícím rameni dvě. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ve stříbrném latinském kříži do krajů pět (3, 1, 1) modrých hvězd. Kříž provázen dole po zlatém zvonu se závěsem."
    },
    {
        obec: "Řípec",
        kod: 599115,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.214291,
        longitude: 14.736808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Ripec_znak.jpeg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým jednu čtvrtinu délky listu. V horní polovině bílého pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. V modrém poli zkřížená žlutá liliová žezla, mezi nimi nahoře žlutá koruna, po stranách položené odvrácené bílé lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným návrším s červenou růží se zlatým semeníkem a zelenými kališními lístky dvě zkřížená zlatá liliová žezla, mezi nimi nahoře zlatá koruna, po stranách položené odvrácené stříbrné lilie."
    },
    {
        obec: "Řisuty",
        kod: 532797,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27378,
        latitude: 50.216592,
        longitude: 14.005087,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/%C5%98isuty_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě červené a modré, v poměru 1 : 2 : 2 : 1. Uprostřed mezi přivrácenými bílými vykořeněnými lekny kalich, pod ním svatojakubská mušle, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatý kalich provázený dvěma přivrácenými stříbrnými vykořeněnými lekny, dole zlatá svatojakubská mušle."
    },
    {
        obec: "Řitka",
        kod: 539651,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25203,
        latitude: 49.894619,
        longitude: 14.299341,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/%C5%98itka_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým šikmým pruhem širokým čtvrtinu šířky listu, v něm šikmo tři vztyčené zelené lipové listy, v žerďovém poli čelně postavená kolčí přilba, ve vlajícím poli lovecká trubka s kováním a závěsem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlaté šikmé břevno se třemi šikmo vztyčenými zelenými lipovými listy provázené nahoře čelně postavenou kolčí přilbou a dole loveckou trubkou s kováním a závěsem, vše zlaté."
    },
    {
        obec: "Řitonice",
        kod: 565661,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.408309,
        longitude: 15.108053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/%C5%98itonice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 5 : 2 : 5, přes ně žlutý ondřejský kříž s rameny širokými šestinu šířky listu. Nahoře zvon, dole tři (1, 2) dotýkající se kruhová pole, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře stříbrným vlnitým břevnem děleném štítě kosmo palmová ratolest šikmo podložená obilným klasem s listem, obojí zlaté. Nahoře zvon, dole tři (1, 2) dotýkající se koule, vše stříbrné."
    },
    {
        obec: "Sádek",
        kod: 578720,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.692663,
        longitude: 16.225728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/S%C3%A1dek_SY_Coa.jpg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pětiúhelníkové pole s vrcholem ve druhé třetině délky listu. K němu přiléhá bílý hřeben o sedmi zubech, prostřední s hrotem na vlajícím okraji listu. V modrém poli dvě, do oblouku přivrácené, bílé ryby hlavou k hornímu okraji listu, mezi nimi žlutá osmicípá hvězda. Vlající část listu červená, v cípech po žluté osmicípé hvězdě. Hvězdy vždy dvěma cípy k okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - modře zvýšeným stříbrným hřebenem dělený štít, v horních rozích zlatá osmihrotá hvězda, dole dvě vztyčené, do oblouku přivrácené stříbrné ryby, mezi nimi zlatá osmihrotá hvězda."
    },
    {
        obec: "Sádek",
        kod: 541273,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.733317,
        longitude: 13.98323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/S%C3%A1dek_%28P%C5%99%C3%ADbram_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 1 : 6 : 1. Ze zeleného pruhu vyrůstá zelený listnatý strom s černým kmenem a červenými pětilistými květy se žlutými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vlevo polcený štít s modro-stříbrně vlevo polcenou hlavou. Ve stříbrném poli ze zeleného návrší vyrůstá zelený listnatý strom s černým kmenem a červenými pětilistými květy se zlatými středy."
    },
    {
        obec: "Sadov",
        kod: 555533,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.267226,
        longitude: 12.897194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Sadov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem širokým pětinu šířky listu a lemovaným k žerďovému a vlajícímu okraji listu černě a k hornímu a dolnímu okraji listu bíle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno-černě dělenou patou tři zlaté vykořeněné listnaté stromy spojené kořeny. Prostřední větší strom překrývá koruny krajních menších."
    },
    {
        obec: "Sadská",
        kod: 537764,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28912,
        latitude: 50.136066,
        longitude: 14.98644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Znak_m%C4%9Bsta_Sadsk%C3%A1.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3:1. Uprostřed listu bílý polygonální kostel s vchodem a oknem v průčelí, dvěma okny v bočních zdech, stanovou střechou s polygonální věžičkou se stanovou střechou. Ve vlající polovině přiléhá ke kostelní zdi hranolová věž, v průčelí s vchodem a dvěma okny nad sebou, v boční zdi dvěma okny nad sebou a sedlovou střechou. Stavba je bílá kvádrovaná, černé gotické vchody a gotická okna s kružbami a pruhy, střechy modré se žlutými makovicemi a křížky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném trávníku červeného štítu polygonální kostel s vchodem a oknem v průčelí, dvěma okny v bočních zdech, stanovou střechou s polygonální věžičkou se stanovou střechou. Zleva přiléhá ke kostelní zdi hranolová věž, v průčelí s vchodem a dvěma okny nad sebou, v boční zdi dvěma okny nad sebou a sedlovou střechou. Stavba je stříbrná kvádrovaná, černé gotické vchody a gotická okna s kružbami a pruhy, střechy modré se zlatými makovicemi a křížky."
    },
    {
        obec: "Sadová",
        kod: 573191,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.297512,
        longitude: 15.700009,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Sadov%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený zubatý o pěti čtvercových a dvou polovičních zubech na okrajích listu a šesti čtvercových mezerách. Z prostředního zubu vyrůstá do bílého pruhu zelená jabloň s červenými jablky a hnědým kmenem. V červeném pruhu žluté dělo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-červeně cimbuřově děleném štítě nahoře vyrůstající jabloň přirozené barvy s červenými jablky, dole zlaté dělo."
    },
    {
        obec: "Salačova Lhota",
        kod: 548731,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.524136,
        longitude: 14.980329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Coat_of_arms_of_Sala%C4%8Dova_Lhota.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem s vrcholem na vlajícím okraji. V klínu tři žlutá, jetelovitě spojená srdce, dvě k žerďovému, jedno k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený hrot se zlatým jetelovým trojlístkem."
    },
    {
        obec: "Salaš",
        kod: 592561,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68706,
        latitude: 49.140618,
        longitude: 17.342876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Sala%C5%A1%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý s bílou hlavou ovce s krkem a žlutý, v poměru 1:3:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě v modré korouhvi s červeným křížovým ráhnem a tlapatým křížkem uprostřed stříbrná hlava ovce s krkem."
    },
    {
        obec: "Samopše",
        kod: 534374,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.861533,
        longitude: 14.929498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Samop%C5%A1e_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý vlnitý s pěti vrcholy a čtyřmi prohlubněmi a zvlněný červený, v poměru 10 : 1 : 5. V zeleném pruhu pět žlutých špaldových klasů, v červeném pruhu uťaté bílé lekno. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně sníženým zúženým stříbrným vlnitým břevnem děleném štítě nahoře pět zlatých špaldových klasů, dole uťaté stříbrné lekno."
    },
    {
        obec: "Samotišky",
        kod: 547077,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 77900,
        latitude: 49.630522,
        longitude: 17.328172,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Samotisky_znak.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený, v žerďovém bílém poli kosmo položená zelená palmová ratolest, ve vlajícím červeném poli kosmo položená žlutá berla s bílým sudariem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít kosmo dělený červeně a stříbrně, nahoře kosmo položená zlatá berla se stříbrným sudariem, dole zelená palmová ratolest."
    },
    {
        obec: "Samšín",
        kod: 548740,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.485662,
        longitude: 15.061812
    },
    {
        obec: "Samšina",
        kod: 573442,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.459432,
        longitude: 15.23502
    },
    {
        obec: "Sázava",
        kod: 596701,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59211,
        latitude: 49.565295,
        longitude: 15.928353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Sazava_ZR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List rozdělený šikmým žlutým vlnitým pruhem s černou ostrví, širokým jednu třetinu délky listu, na červenou žerďovou a modrou vlající část. Žlutý pruh má dva vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře zlatým šikmým vlnitým břevnem s černou ostrví dělený štít."
    },
    {
        obec: "Sány",
        kod: 537772,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28906,
        latitude: 50.124752,
        longitude: 15.247746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Sany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; v žerďové polovině vzpřímený žlutý jelen, provázený u horního rohu ondřejským křížem a pod tělem dvěma šesticípými hvězdami pod sebou, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vzpřímený zlatý jelen provázený vpravo nahoře ondřejským křížem a dole dvěma hvězdami pod sebou, vše stříbrné."
    },
    {
        obec: "Sázava",
        kod: 574392,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.908253,
        longitude: 16.641003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/S%C3%A1zava.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý se žlutým vykořeněným leknem listem k vlajícímu okraji a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě dvě zlatá vykořeněná spojená lekna, mezi nimi vztyčená radlice a postavené krojidlo, obojí stříbrné."
    },
    {
        obec: "Sázava",
        kod: 534382,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.871745,
        longitude: 14.896842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Sazava_%28Benesov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: bílý dole zvlněný, červený vlnitý a modrý zvlněný. Červený pruh a modrý pruh mají čtyři vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě z modré vlnité paty vyniká červená zeď prolomená čtyřmi vysokými hrotitými oblouky, na ní červená věž s prolomeným hrotitým oknem a modrou stanovou střechou zakončenou zlatou makovicí a křížkem. Věž je provázena vpravo černou kápí a vlevo ze zdi vynikající zlatou berlou."
    },
    {
        obec: "Sázavka",
        kod: 569429,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58244,
        latitude: 49.734505,
        longitude: 15.411535
    },
    {
        obec: "Sazená",
        kod: 532801,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27324,
        latitude: 50.305277,
        longitude: 14.287363,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Sazen%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 6 : 1 : 3. Z bílého pruhu vyrůstá jabloň, v modrém pruhu žernov s prázdným otvorem pro položenou kypřici, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře sníženě mostem se dvěma pilíři děleném štítě, nahoře vyrůstající jabloň, dole žernov s prázdným otvorem pro položenou kypřici, vše stříbrné."
    },
    {
        obec: "Sazomín",
        kod: 588237,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.508871,
        longitude: 15.981076,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Znak_Sazom%C3%ADna.svg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným vlajícím pruhem širokým třetinu délky listu. Na dolním okraji žlutého pole zelené návrší s kavkou obrácenou k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší doleva obrácená kavka přirozené barvy."
    },
    {
        obec: "Sazovice",
        kod: 585726,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76301,
        latitude: 49.240997,
        longitude: 17.568782,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/CoA_of_Sazovice.svg",
        coatOfArmsFlagDescription: "Červený list s bíle lemovaným modrým středovým křížem. Vodorovná ramena kříže jsou široká jednu čtvrtinu šířky listu a svislá ramena jednu čtvrtinu délky listu. Lem kříže je široký jednu dvanáctinu délky listu. Uprostřed kříže žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře osmihrotá hvězda a čelně postavená radlice, obojí zlaté, dole červené orlí křídlo."
    },
    {
        obec: "Sběř",
        kod: 573451,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.320225,
        longitude: 15.424479
    },
    {
        obec: "Sebečice",
        kod: 560146,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.869965,
        longitude: 13.692257,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Sebe%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, vlnitý modrý se dvěma vrcholy a třemi prohlubněmi a zvlněný žlutý, v poměru 6 : 1 : 3. V zeleném poli čelně hlava jezevce nad žlutým trilobitem, ve vlajícím pruhu zelený topol s hnědým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-zlatě modrým vlnitým břevnem vlevo polcený štít. Vpravo čelně hlava jezevce přirozené barvy nad zlatým trilobitem, vlevo topol přirozené barvy."
    },
    {
        obec: "Sebranice",
        kod: 582310,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67931,
        latitude: 49.497022,
        longitude: 16.57464,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/CoA_of_Sebranice_%28okres_Blansko%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý. V bílém pruhu tři červené růže se zelenými semeníky a kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné břevno, v něm tři červené růže se zelenými středy a kališními lístky."
    },
    {
        obec: "Sebranice",
        kod: 578738,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56962,
        latitude: 49.771494,
        longitude: 16.249278,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Sebranice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, červený, bílý a zelený. V zelených pruzích po jednom žlutém srdci, v červeném pruhu žlutá berla s bílým sudariem závitem k žerďovému okraji. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červeným kůlem zelené trojvrší. Uprostřed vyrůstá zlatá berla se stříbrným sudariem, po stranách dva smrky přirozené barvy. V prostředním vrchu zlaté srdce."
    },
    {
        obec: "Seč",
        kod: 572225,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53807,
        latitude: 49.847037,
        longitude: 15.65653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Se%C4%8D.png",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým jednu pětinu šířky listu na žerďové červené a vlající modré pole. V červeném poli bílá lilie. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Seč",
        kod: 580902,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 50.025612,
        longitude: 16.294907,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Se%C4%8D_%28okres_%C3%9Ast%C3%AD_nad_Orlic%C3%AD%29_CoA.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list. Žerďové pole zelené se žlutou šikmo položenou uťatou lipovou větví, nahoře s listem mezi suky a dole se sukem mezi listy. Vlající pole tvoří pět střídavě bílých a modrých vodorovných pruhů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno - stříbrně šikmo dělený štít. Nahoře šikmo položená uťatá lipová větev, vpravo se dvěma suky a jedním listem, vlevo s jedním sukem a dvěma listy, vše zlaté. Dole dvě modrá břevna."
    },
    {
        obec: "Seč",
        kod: 558311,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.588075,
        longitude: 13.504999,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Se%C4%8D_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a červený. Uprostřed bílé průčelí barokního kostela provázené v horním rohu žaludem a v horním cípu lipovým listem, obojí vztyčené a žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-červeně polceném štítě stříbrné průčelí barokního kostela provázené nahoře vpravo žaludem a vlevo lipovým listem, obojí vztyčené a zlaté."
    },
    {
        obec: "Sedlatice",
        kod: 587877,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.192397,
        longitude: 15.613497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Coat_of_arms_of_Sedlatice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a zelený, v poměru 4 : 1 : 1. V červeném pruhu mezi přivrácenými křídly vztyčená hůlka zakončená koulemi, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad zeleným návrším s vlnitým břevnem mezi přivrácenými křídly vztyčená hůlka zakončená koulemi, vše stříbrné."
    },
    {
        obec: "Sedlčany",
        kod: 541281,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.660677,
        longitude: 14.426744,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coa_Czech_Town_Sedl%C4%8Dany.svg"
    },
    {
        obec: "Sedlec",
        kod: 545015,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37347,
        latitude: 49.072931,
        longitude: 14.286606,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Sedlec_CB_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Červeno-modře šikmo dělený list s bílou lilií uprostřed. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře šikmo děleném štítě stříbrná lilie."
    },
    {
        obec: "Sedlec",
        kod: 584878,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69121,
        latitude: 48.778993,
        longitude: 16.693989,
        coatOfArmsFlagDescription: "Modrý list s bílou radlicí hrotem k hornímu okraji a ostřím k vlajícímu okraji, provázenou dvěma bílými ratolestmi vinné révy, každá se třemi žlutými hrozny. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená doleva obrácená stříbrná radlice provázená dvěma stříbrnými ratolestmi vinné révy, každá se třemi zlatými hrozny."
    },
    {
        obec: "Sedlec",
        kod: 511081,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.167674,
        longitude: 16.134773,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Sedlec_TR_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným jelenem ve skoku s černou zbrojí, držícím modrý štítek s bílou leteckou třílistou vrtulí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený jelen ve skoku s černou zbrojí, držící modrý štítek se stříbrnou leteckou třílistou vrtulí."
    },
    {
        obec: "Sedlec",
        kod: 530271,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 50.006379,
        longitude: 13.427287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Sedlec_%28Plze%C5%88-North_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s bílou lilií, nahoře provázenou k žerďovému okraji patriarším křížem a k vlajícímu okraji vztyčeným olšovým listem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie nahoře provázená vpravo patriarším křížem a vlevo vztyčeným olšovým listem, obojí zlaté."
    },
    {
        obec: "Sedlec",
        kod: 529591,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29471,
        latitude: 50.300839,
        longitude: 14.79583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Sedlec_MB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený pilovitý se třemi zuby s vrcholy ve druhé pětině šířky listu. V bílém pruhu dva vztyčené odkloněné zelené vinné listy. Ve střední části zeleného pruhu tři z dolního okraje listu vyrůstající žluté obilné klasy, prostřední větší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně třemi hroty zvýšeně děleném štítě nahoře dva vztyčené odkloněné zelené vinné listy, dole tři vyrůstající zlaté obilné klasy, prostřední větší."
    },
    {
        obec: "Sedlec",
        kod: 598283,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25065,
        latitude: 50.186836,
        longitude: 14.457979,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a červený. Přes ně kosmý černý pruh vycházející z první třetiny žerďového a první a druhé devítiny horního okraje do třetí třetiny a osmé a deváté devítiny dolního okraje. V černém pruhu sova s červenou zbrojí, sedící na větvi, provázená dvěma liliemi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zvýšeně dělený štít, nahoře v černém poli sova s červenou zbrojí, sedící na větvi, provázená dvěma liliemi, vše zlaté. Dolní pole zeleno-stříbrně polcené, vpravo zlatý vinný hrozen, vlevo červený heroldský kříž."
    },
    {
        obec: "Sedlec",
        kod: 546721,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.450166,
        longitude: 14.016401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Sedlec_%28Litom%C4%9B%C5%99ice_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Na dolním okraji listu dva zelené klíny s vrcholy ve třetí čtvrtině šířky listu. Uprostřed listu čelně radlice podložená zkříženými obilnými klasy, vše žluté. V radlici černá kančí hlava. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad zeleným dvojvrším čelně radlice podložená zkříženými obilnými klasy, vše zlaté. V radlici černá kančí hlava."
    },
    {
        obec: "Sedlec-Prčice",
        kod: 530573,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25791,
        latitude: 49.573311,
        longitude: 14.533106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Sedlec-Prcice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří osm polí v podobě pravoúhlých trojúhelníků vždy s jedním vrcholem ve středu listu. Horní žerďové a dolní vlající pole jsou modrá, horní vlající a dolní žerďové jsou červená, zbývající čtyři pole jsou bílá. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Čtvrcený štít, v prvním a čtvrtém stříbrném poli červená hradební kvádrová zeď se třemi stínkami, otevřenými zlatými vraty a zdviženou zlatou mříží. Ze zdi vyrůstá černá orlice se zlatou zbrojí. Druhé a třetí pole je modro-stříbrně čtvrceno."
    },
    {
        obec: "Sedlečko u Soběslavě",
        kod: 599263,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.282784,
        longitude: 14.742959,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Sedle%C4%8Dko_u_Sob%C4%9Bslav%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině pravá půle bílé orlice s červeným jazykem, žlutou zbrojí a jetelovitě zakončeným perizoniem držící tři žluté obilné klasy s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě volně pravá půle stříbrné orlice s červeným jazykem, zlatou zbrojí a jetelovitě zakončeným perizoniem držící tři zlaté obilné klasy s jedním listem."
    },
    {
        obec: "Sedlejov",
        kod: 587885,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.22761,
        longitude: 15.495752,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Sedlejov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý s unciálním písmenem 'M' převýšeným korunou, obojí žluté, a červený s bílým dvouocasým lvem se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo unciální písmeno 'M' převýšené korunou, obojí zlaté, vlevo stříbrný dvouocasý lev se zlatou zbrojí a jazykem, dole zlatá růže s červeným semeníkem."
    },
    {
        obec: "Sedlice",
        kod: 551716,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38732,
        latitude: 49.377258,
        longitude: 13.939059,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Znak_sedlice_st_cz.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý, kvádrovaný, bílý v poměru 2:1. Do modrého pruhu vyrůstá hnědý medvěd se žlutou zbrojí a obojkem s řetězem. Bílý pruh má 5 zubů vysokých jednu desetinu šířky listu a čtyři stejné mezery. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Sedletín",
        kod: 548421,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.708551,
        longitude: 15.564631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Sedlet%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma žlutými obilnými snopy, mezi nimi u dolního okraje listu bílá lilie. Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dva zlaté obilné snopy, pod nimi stříbrná lilie"
    },
    {
        obec: "Sedlice",
        kod: 548766,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.515243,
        longitude: 15.256443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Sedlice_PE_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, přes které je položen bílý vodorovný pruh, vycházející z šesté osminy žerďového okraje. Z bílého pruhu vyrůstá uprostřed lípa provázená dvěma odvrácenými klíči zuby nahoru, vše žluté. Pod bílým pruhem uprostřed žlutý jetelový kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrná kvádrovaná zeď se třemi prolomenými oblouky, v prostředním zlatý jetelový kříž. Ze zdi vyrůstá lípa provázená dvěma vztyčenými odvrácenými zlatými gotickými klíči, vše zlaté."
    },
    {
        obec: "Sedlice",
        kod: 564630,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.621869,
        longitude: 13.880889,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Sedlice_PB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 8 : 3 : 8. V modrých pruzích dvě od žlutého pruhu odvrácené poloviny bílého mlýnského kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma odvrácenými polovinami stříbrného mlýnského kola nahoře vztyčený zlatý lipový list, dole zlatý zvon."
    },
    {
        obec: "Sedliště",
        kod: 548961,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.394226,
        longitude: 15.219151
    },
    {
        obec: "Sedliště",
        kod: 598674,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73936,
        latitude: 49.71846,
        longitude: 18.368797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Sedliste_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sedlem se třmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté sedlo se třmenem."
    },
    {
        obec: "Sedliště",
        kod: 578746,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.893977,
        longitude: 16.274918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Sedli%C5%A1t%C4%9B_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý s utrženou černou vlčí hlavou s červeným jazykem a modrý se žlutým patriarším křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo utržená černá vlčí hlava s červeným jazykem, vlevo zlatý patriarší kříž."
    },
    {
        obec: "Sedliště",
        kod: 540692,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.52758,
        longitude: 13.63234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Sedli%C5%A1t%C4%9B_%28Plze%C5%88-South_District%29_CoA.jpg"
    },
    {
        obec: "Sedlnice",
        kod: 599832,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74256,
        latitude: 49.657799,
        longitude: 18.08701,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/CoA_of_Sedlnice.svg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem s vrcholem na vlajícím okraji, v žerďové části červená zavinutá střela hrotem vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný hrot s červenou zavinutou střelou."
    },
    {
        obec: "Sedloňov",
        kod: 576743,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51791,
        latitude: 50.338401,
        longitude: 16.315588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Sedlo%C5%88ov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým, červeně vyloženým sedlem se žlutým třmenem, ze kterého vyrůstá žlutá lilie na stonku se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté sedlo s červeným vyložením, zlatým třmenem a vyrůstající zlatou lilií na stonku se dvěma listy."
    },
    {
        obec: "Sehradice",
        kod: 585734,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76323,
        latitude: 49.144368,
        longitude: 17.824269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/CoA_Sehradice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:3:1. V modrém pruhu bílá kvádrovaná tvrz tvořená hradbou a věží s prolomeným oknem, obojí s cimbuřím, dole překrytá třemi červenými kosmými vlčími zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná tvrz tvořená hradbou a věží s prolomeným oknem a cimbuřím, provázená nahoře dvěma vztyčenými odvrácenými radlicemi a překrytá v levém dolním rohu třemi červenými vlčími zuby."
    },
    {
        obec: "Sejřek",
        kod: 596710,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59262,
        latitude: 49.436195,
        longitude: 16.309362,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Sej%C5%99ek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Uprostřed bílého pruhu černá zubří hlava se žlutou houžví a červeným jazykem, v zeleném krojidlo hrotem k dolnímu okraji a ostřím k žerdi provázené otevřenou knihou a lilií, vše bílé.",
        coatOfArmsDescription: "Ve stříbrno - zeleně děleném štítě, nahoře černá zubří hlava se zlatou houžví a červeným jazykem, dole krojidlo provázené vpravo otevřenou knihou, vlevo lilií, vše stříbrné."
    },
    {
        obec: "Sekeřice",
        kod: 572144,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.286507,
        longitude: 15.380794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Seke%C5%99ice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 2 : 1. V žerďové části modrého pruhu vykračující bílý kohout s červeným hřebínkem a žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vykračující stříbrný kohout s červeným hřebínkem a zlatou zbrojí na zlaté palisádové hradbě se zkříženými sekerami přirozené barvy."
    },
    {
        obec: "Seletice",
        kod: 534757,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28934,
        latitude: 50.320442,
        longitude: 15.097101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Seletice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list; uprostřed zkřížené bílé kosy proložené žlutým středovým křížem s horním a dolním ramenem tvořeným dubovým listem. Poměr šířky k délce 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené stříbrné kosy proložené zúženým zlatým heroldským křížem s horním a dolním ramenem tvořeným dubovým listem."
    },
    {
        obec: "Selmice",
        kod: 575615,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.050233,
        longitude: 15.440919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Selmice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, žlutý, modrý, bílý, červený, žlutý a červený, červené karé o straně rovné třem sedminám šířky listu a stejně velké modré čtvercové pole v dolním rohu. V karé bílá hlava koně s krkem, žlutou hřívou a jazykem, v modrém poli bílá hlava kohouta se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý široký rošt s držadlem provázeným přivrácenými hlavami s krky, vpravo koňskou, vlevo kohoutí, obojí stříbrné se zlatou zbrojí a jazyky. Dole stříbrné vlnité břevno."
    },
    {
        obec: "Seloutky",
        kod: 506770,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79804,
        latitude: 49.441499,
        longitude: 17.063403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Seloutky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený. V žerďovém pruhu dvě zkřížené přivrácené bílé kosy bez kosišť hroty nahoru, ve žlutém pruhu zelený vinný hrozen, ve vlajícím pruhu bílá lilie. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě nahoře zelený vinný hrozen se dvěma odkloněnými větévkami, každá se třemi úponky a svěšeným listem, dole dvě zkřížené přivrácené kosy bez kosišť hroty nahoru, pod nimi lilie, vše stříbrné."
    },
    {
        obec: "Semanín",
        kod: 555240,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56002,
        latitude: 49.869404,
        longitude: 16.453026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Seman%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a bílý, v poměru 4:1:1. Ve žlutém pruhu černý kozoroh s rybím ocasem a s červenou šesticípou hvězdou nad hřbetem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad červenou patou s doleva položeným stříbrným nožem se zlatou rukojetí černý kozoroh s rybím ocasem a červenou hvězdou nad hřbetem."
    },
    {
        obec: "Semčice",
        kod: 536610,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29446,
        latitude: 50.368272,
        longitude: 15.007012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Sem%C4%8Dice_znak.jpg",
        coatOfArmsDescription: "V zeleném štítě doleva obrácené ruchadlo, na něm položený snop, za ním zkřížené hrábě a kosa, vše stříbrné."
    },
    {
        obec: "Semechnice",
        kod: 576751,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.260342,
        longitude: 16.148495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/CoA_of_Semechnice.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh, široký čtvrtinu délky listu a tři vodorovné pruhy, červený, bílý zubatý a modrý zvlněný, v poměru 2:1:1. Bílý pruh má tři obdélníkové zuby široké jednu osminu délky listu a vysoké jednu osminu šířky listu, dvě obdélníkové a dvě poloviční mezery. Zvlněný pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře stříbrným, nahoře cimbuřovým a dole vlnitým břevnem děleném štítě ze stínek vyrůstající tři obilné klasy, dole lilie, vše zlaté."
    },
    {
        obec: "Semice",
        kod: 537781,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28917,
        latitude: 50.164267,
        longitude: 14.871579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Semice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s horním žerďovým a dolním vlajícím červeným trojúhelníkem s odvěsnami do poloviny šířky a délky listu. V modrém poli kosmo žlutá ostruha kolečkem k žerďovému rohu, proložená šikmým bílým pruhem širokým desetinu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se zlatou korunou kosmo vztyčená zlatá ostruha šikmo proložená stříbrným vlnitým břevnem."
    },
    {
        obec: "Semily",
        kod: 576964,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.605258,
        longitude: 15.328241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Znaksemily.gif",
        coatOfArmsFlagDescription: "List praporu tvoří horní modrý pruh, dolní zelený pruh v poloviční šíři modrého; do zeleného pruhu po celé délce listu je jedním cípem vetknuto šest zlatých hvězd, navzájem se dotýkajících; poměr délky k šířce je 3:2."
    },
    {
        obec: "Semín",
        kod: 575623,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.053188,
        longitude: 15.519831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Semin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a zubatý červený, v poměru 3:4:2. V modrém pruhu nad bílou osmicípou hvězdou bílý zvon s červeným maltézským křížem. Ve žlutém pruhu zelená dubová větev střídavě s pěti žaludy a čtyřmi listy. Červený pruh má tři čtvercové zuby a dvě stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo nad stříbrnou osmihrotou hvězdou stříbrný zvon s červeným maltézským křížem, vlevo z červené kvádrované hradby s cimbuřím vyrůstá zelená dubová větev střídavě s pěti žaludy a čtyřmi listy."
    },
    {
        obec: "Semněvice",
        kod: 554201,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.597859,
        longitude: 12.929307,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Semn%C4%9Bvice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červeno-bíle dělený žerďový pruh široký čtvrtinu délky listu a dva vodorovné pruhy, žlutý a červený, V žerďovém pruhu pod sebou tři růže opačných barev se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. Pravé pole červeno-stříbrně dělené se třemi růžemi opačných barev pod sebou a se zlatými semeníky. V levém červeném poli kosmé zlaté plamenné břevno šikmo shora probodnuté stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Semtěš",
        kod: 530859,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.960036,
        longitude: 15.514411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Semt%C4%9B%C5%A1_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list. Z prostřední třetiny dolního okraje vyniká šedá kamenná věž o třech patrech, každé s černým oknem, na ní dvě přivrácené poštolky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyniká kamenná věž o třech patrech, každé s černým oknem, na ní dvě přivrácené poštolky, vše přirozené barvy."
    },
    {
        obec: "Sendraž",
        kod: 547727,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.367711,
        longitude: 16.202186
    },
    {
        obec: "Sendražice",
        kod: 570796,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.289952,
        longitude: 15.796074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Sendra%C5%BEice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutým dolním žerďovým trojúhelníkem vycházejícím z druhé až páté pětiny žerďového okraje listu s vrcholem v dolním cípu listu. Ve žlutém poli krkavec se zeleným prstenem v zobáku. V zeleném poli žlutá lípa. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-zlatě kosmo sníženě děleném štítě nahoře zlatá lípa, dole krkavec přirozené barvy se zeleným prstenem v zobáku."
    },
    {
        obec: "Senec",
        kod: 542369,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27036,
        latitude: 50.066842,
        longitude: 13.706979,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Znak_senec.jpg",
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list s červenou veverkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně šikmo děleném štítě červená veverka."
    },
    {
        obec: "Senetářov",
        kod: 582328,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.354781,
        longitude: 16.80399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Senetarov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem bez tváře o šestnácti paprscích, střídavě rovných a plamenných, a čtyřmi bílými liliemi směřujícími do rohů a cípů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "v modrém štítě stříbrná přirozená lilie na stonku provázená dvěma zlatými slunci."
    },
    {
        obec: "Senice",
        kod: 537799,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.184332,
        longitude: 15.207677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Senice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý. V modrém pruhu žlutý obilný snop, pod ním dvě odvrácené bílé ryby se zkříženými ocasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý obilný snop. V patě dvě odvrácené stříbrné ryby se zkříženými ocasy."
    },
    {
        obec: "Senice na Hané",
        kod: 505081,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78345,
        latitude: 49.62411,
        longitude: 17.085882,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Senice_na_Hane_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy v poměru 2:1:1:2. První a čtvrtý jsou bílé se třemi kosmými černými pruhy vycházejícími z první, třetí a páté sedminy žerďových okrajů do třetí, páté a sedmé sedminy vlajících okrajů pruhů. Přes druhý červený a třetí zelený pruh žlutá kvádrovaná věž s cimbuřím a černým oknem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít se zelenou hlavou, v ní zlaté zkřížené kosy hroty dolů a bez násad, přeložené stříbrno-červeně polcenou růží se zlatým semeníkem. V pravém červeném poli zlatá kvádrovaná věž s cimbuřím a jedním černým oknem, levé stříbrné pole se třemi černými kosmými břevny."
    },
    {
        obec: "Senička",
        kod: 552267,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78345,
        latitude: 49.632317,
        longitude: 17.05639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Senicka_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu se třemi bílými žerďovými klíny s vrcholy na okraji pruhu. V bílé vlající části červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně sníženě dělený štít, nahoře vyrůstá přirozená červená růže se zlatým semeníkem na zeleném stonku se čtyřmi listy, dole tři stříbrné kužely."
    },
    {
        obec: "Seninka",
        kod: 544906,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.273311,
        longitude: 17.96516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Coat_Of_Arms_Seninka.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. V horní části zeleného pruhu radlice hrotem k žerdi a ostřím k hornímu okraji, v horní části červeného pruhu vykořeněný buk, oboje žluté. V dolní části listu běžící bílý kůň se žlutou zbrojí, hřívou a ocasem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo položená radlice, vlevo vykořeněný buk, obojí zlaté, pod nimi běžící stříbrný kůň se zlatou zbrojí, hřívou a ocasem."
    },
    {
        obec: "Senohraby",
        kod: 538752,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25166,
        latitude: 49.895504,
        longitude: 14.718199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Znak_obce_Senohraby.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé nad stejně velkým modrým čtvercovým polem a zelené pole. V karé bílá zavinutá střela, v modrém poli žluté hrábě. V zeleném poli bílá kvádrovaná věž s cimbuřím a černým gotickým oknem, pod ní z dolní čtvrtiny vlajícího okraje listu vycházejí tři vodorovné pruhy, vlnitý bílý, vlnitý modrý a zvlněný bílý, každý se šesti vrcholy a pěti prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole červeno-modře dělené, nahoře stříbrná zavinutá střela, dole zlaté hrábě. V levém zeleném poli stříbrná kvádrovaná věž s cimbuřím a černým gotickým oknem nad stříbrnou vlnitou patou s modrým vlnitým břevnem."
    },
    {
        obec: "Senomaty",
        kod: 542377,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27031,
        latitude: 50.098281,
        longitude: 13.653828,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Coats_of_arms_Senomaty.jpeg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed hrábě a vidle-podávky, obojí vztyčené a bílé, mezi nimi v dolní části položená zelená otep sena převázaná žlutým povříslem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě položená zelená otep sena převázaná zlatým povříslem provázená vpravo hráběmi a vlevo vidlemi-podávkami, obojí vztyčené a stříbrné."
    },
    {
        obec: "Senorady",
        kod: 591661,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67575,
        latitude: 49.124447,
        longitude: 16.244551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Senorady_znak.jpg",
        coatOfArmsFlagDescription: "Kosmo červeno-zeleně dělený list; v horním zeleném poli kosmo bílá radlice hrotem nahoru a ostřím k hornímu okraji listu, v dolním červeném poli kosmo bílý hák hrotem k hornímu rohu a hákem k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně kosmo děleném štítě nahoře položená radlice, dole hák, obojí stříbrné."
    },
    {
        obec: "Senožaty",
        kod: 548774,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39456,
        latitude: 49.569169,
        longitude: 15.198114,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Znak_obce_Seno%C5%BEaty.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. V červeném pruhu svisle žlutý klíč zuby nahoru a k žerďovému okraji, dole provázený bílými přivrácenými srpy se žlutými rukojeťmi dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vztyčený zlatý klíč dole provázený dvěma přivrácenými stříbrnými srpy se zlatými rukojeťmi."
    },
    {
        obec: "Sentice",
        kod: 583847,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66603,
        latitude: 49.310864,
        longitude: 16.446775,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Sentice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1 : 4 : 1. Ve žlutém pruhu černé zkřížené kosy, provázené třemi červenými šestilistými květy se žlutými středy (2, 1). Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černé zkřížené kosy provázené po stranách a dole třemi červenými šestilistými květy se zlatými středy vyrůstajícími na zelených stoncích z okraje štítu."
    },
    {
        obec: "Sepekov",
        kod: 549843,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39851,
        latitude: 49.428755,
        longitude: 14.418256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Znak_obce_Sepekov.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 2:1. V žerďové polovině žlutého pruhu zelená růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zeleným návrším se třemi (1,2) zlatými jablky zelená růže se zlatým semeníkem."
    },
    {
        obec: "Sezemice",
        kod: 575640,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53304,
        latitude: 50.066605,
        longitude: 15.852803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Sezemice-znak-mesta.png",
        coatOfArmsFlagDescription: "Červený list se čtyřmi kosmými bílými pruhy, z nichž tři vycházejí z druhé, čtvrté a šesté šestiny žerďového okraje do první, třetí a páté šestiny dolního okraje a jeden z první šestiny horního okraje do šesté šestiny vlajícího okraje. V horním červeném poli žlutá koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Sezemice",
        kod: 599573,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29411,
        latitude: 50.58565,
        longitude: 15.005199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Sezemice_MB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu s bílým maltézským křížem, a modré pole se žlutým lvem s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý lev s červenou zbrojí držící v předních tlapách červený štítek se stříbrným maltézským křížem."
    },
    {
        obec: "Sezimovo Ústí",
        kod: 553069,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39101,
        latitude: 49.385291,
        longitude: 14.684908,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Coa_Czech_Town_Sezimovo_%C3%9Ast%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová a modrá vlající část. Uprostřed růže opačných barev s bílým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě růže opačných barev se stříbrným semeníkem."
    },
    {
        obec: "Schořov",
        kod: 531383,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.858229,
        longitude: 15.380002
    },
    {
        obec: "Sibřina",
        kod: 538761,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25084,
        latitude: 50.056746,
        longitude: 14.669513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Sibrina_PH_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený. Na dolním okraji listu zubatý pruh opačných barev, široký čtvrtinu šířky listu, s pěti celými a dvěma polovičními zuby vysokými osminu šířky listu. V bílém poli zelený stonek konopice se třemi listy, v červeném poli ruční stoupa z níž vyniká tlouk-stupice mezi dvěma odkloněnými obilnými klasy, každý s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s cimbuřovou patou opačných barev. V pravém poli zelený stonek konopice se třemi listy, v levém poli ruční stoupa, z níž vyniká tlouk-stupice mezi dvěma odkloněnými obilnými klasy, každý s listem, vše zlaté."
    },
    {
        obec: "Silůvky",
        kod: 583855,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66446,
        latitude: 49.106075,
        longitude: 16.46804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Sil%C5%AFvky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu bílý třešňový květ se žlutým středem mezi žlutou radlicí hrotem k hornímu a ostřím k žerďovému okraji a bílým vinařským nožem-kosířem ostřím k žerďovému okraji a žlutou rukojetí k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrný třešňový květ se zlatým středem mezi vztyčenou zlatou radlicí a stříbrným vinařským nožem-kosířem se zlatou rukojetí."
    },
    {
        obec: "Sirá",
        kod: 546488,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.821542,
        longitude: 13.738545,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Sira_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém zelený vykořeněný smrk s hnědým kmenem, v zeleném svisle palice obrácená dolů a krumpáč obrácený nahoru a hrotem k žerďovému okraji, obojí bílé se přeložené přes hnědé násady. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo vykořeněný smrk přirozené barvy, vlevo postavená palice a vztyčený krumpáč obojí stříbrné s násadami přirozené barvy."
    },
    {
        obec: "Siřejovice",
        kod: 565598,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.478454,
        longitude: 14.071012,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Sirejovice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří bílý kanton široký tři osminy šířky listu a dlouhý třetinu délky listu nad stejně širokým modrým žerďovým pruhem a dva vodorovné pruhy, modrý a žlutý, v poměru 3:5. V kantonu červený středový kříž s rameny širokými jednu osminu šířky listu. V žerďovém pruhu na zeleném návrší žlutá věž s cimbuřím, branou a osmi (3,3,2) okny, vše černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě, pod stříbrnou hlavou s červeným heroldským křížem, na zeleném návrší zlatá věž s cimbuřím, branou a osmi (3,3,2) okny, vše černé."
    },
    {
        obec: "Sivice",
        kod: 583863,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66407,
        latitude: 49.203807,
        longitude: 16.782647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Znak_obce_Sivice.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý s černým orlím křídlem a červený, v poměru 2:4:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo dva zlaté obilné klasy, vlevo pod červenou hlavou černé orlí křídlo."
    },
    {
        obec: "Skalice",
        kod: 553077,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.303479,
        longitude: 14.695306
    },
    {
        obec: "Skalice",
        kod: 594768,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67171,
        latitude: 48.964529,
        longitude: 16.224901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Skalice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 5:8:5. V červeném pruhu bílý kohout se žlutou zbrojí. Ve žlutých pruzích po jednom modrém vinném hroznu na hnědém stonku se zeleným listem, v žerďovém pruhu v dolní a ve vlajícím v horní polovině pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě vpravo modrý vinný hrozen na stonku s jedním zeleným listem, pod ním šikmý modrý vinařský nůž s rukojetí přirozené barvy. Vlevo na stříbrné skále vykračující stříbrný kohout se zlatou zbrojí."
    },
    {
        obec: "Sirákov",
        kod: 596728,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.504907,
        longitude: 15.818313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Sir%C3%A1kov.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, každý s horním žlutým trojúhelníkem. Trojúhelníky odvěsnami do poloviny okrajů listu. Uprostřed listu bílá osmicípá hvězda, v ní zelený rupertský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červeno-modře polcený zvýšený hrot se stříbrnou osmihrotou hvězdou, v ní zelený rupertský kříž."
    },
    {
        obec: "Skalice",
        kod: 570800,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.27647,
        longitude: 15.872134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Skalice_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý pilovitý se třemi zuby, v poměru 7:1. Zuby mají vrcholy ve třetí čtvrtině šířky listu. Mezi prvním a druhým zubem vyrůstá z bílého pruhu žlutý dub. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě z vlnité paty vyniká stříbrné skalnaté trojvrší z něhož vpravo vyrůstá zlatý dub provázený vlevo knížecí korunou."
    },
    {
        obec: "Skalice nad Svitavou",
        kod: 582336,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.481246,
        longitude: 16.603686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/CoA_of_Skalice_nad_Svitavou.svg",
        coatOfArmsFlagDescription: "List tvoří devět vodorovných pruhů, modrý, červený a sedm střídavě bílých a modrých, v poměru 7:7:1:1:1:1:1:1:1. V žerďové polovině modrého pruhu půlměsíc cípy k vlajícímu okraji, provázený k žerďovému okraji šesti a k vlajícímu okraji sedmi nepravidelně položenými hvězdami, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se zlatým ubývajícím půlměsícem a třinácti zlatými pětihrotými hvězdami stříbrná pata se třemi modrými vlnitými břevny."
    },
    {
        obec: "Skalička",
        kod: 545295,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66603,
        latitude: 49.357972,
        longitude: 16.516283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Skali%C4%8Dka_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a žlutý, v poměru 1:7:1. V zeleném pruhu dva přivrácení bílí korunovaní dvouocasí lvi se žlutou zbrojí držící červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva přivrácení stříbrní korunovaní dvouocasí lvi se zlatou zbrojí držící červené srdce."
    },
    {
        obec: "Skalice u České Lípy",
        kod: 562025,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47117,
        latitude: 50.745217,
        longitude: 14.53067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Skalice_u_Ceske_Lipy_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh se žlutou lilií široký jednu třetinu délky listu a čtyři vodorovné pruhy, střídavě bílé a červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Kosmo čtvrcený štít, v horním červeném a postraních stříbrných polích ramena osmihrotého kříže opačných barev. V dolním zeleném poli zlatá lilie."
    },
    {
        obec: "Skalička",
        kod: 517747,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75352,
        latitude: 49.515497,
        longitude: 17.795975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Skali%C4%8Dka_znak.png",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list. Na středu bílo-červeně čtvrcený rovný kříž podložený ondřejským jetelovým křížem tak, že ramena křížů v bílém horním vlajícím a dolním žerďovém poli jsou červená a ramena křížů v modrém horním žerďovém a dolním vlajícím poli jsou bílá. Průměr rovného kříže je pět osmin šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně čtvrceném štítě stříbrno-červeně čtvrcený štít mezi jehož rameny vybíhají jetelové stonky."
    },
    {
        obec: "Skalka",
        kod: 586536,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69648,
        latitude: 49.035877,
        longitude: 17.207332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Skalka_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zeleno-modře šikmo dělený list. Uprostřed žlutý vinný hrozen na stonku se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře šikmo děleném štítě nahoře zlatý vinný hrozen na stonku se dvěma listy. Dole stříbrná skála."
    },
    {
        obec: "Skalka",
        kod: 589993,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79824,
        latitude: 49.403186,
        longitude: 17.169915,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Skalka_PV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, bílý, červený a modrý, v poměru 11:7:7:11. Uprostřed modro-bíle polcená fontána. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červeno-stříbrně polcená kvádrovaná věž s cimbuřím na skále opačných barev provázená stříbrnými štítky s červenou loveckou trubkou bez řemení. Ve skále modro-stříbrně polcená fontána."
    },
    {
        obec: "Skalka u Doks",
        kod: 513890,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.563681,
        longitude: 14.619672,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Skalka_u_Doks_znak.jpg",
        coatOfArmsFlagDescription: "Bílo-modře kosmo a šikmo čtvrcený list. V žerďovém poli maltézský kříž, ve vlajícím poli šesticípá hvězda, oboje modré. V horním poli tři svěšené lipové listy (2,1), v dolním poli lví tlapa s červenou zbrojí, vše žluté. Poměr šířky k délce listu je 2:3 .",
        coatOfArmsDescription: "V modro-stříbrně kosmo a šikmo čtvrceném štítě nahoře tři (2,1) svěšené lipové listy, dole lví tlapa s červenou zbrojí, vše zlaté. Vpravo maltézský kříž, vlevo hvězda, oboje modré."
    },
    {
        obec: "Skalná",
        kod: 554812,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.170411,
        longitude: 12.361561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Skalna_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně děleném štítě, nahoře ze skály přirozené barvy vyrůstá hradní palác s deseti (5, 5) okny, s věží vpravo s jedním oknem, z pravého okraje štítu vyniká k věži most s jedním prázdným obloukem, vše stříbrné, okna černá, střechy valbové a červené. Dole zlatý štít s modrým břevnem, s korunovanou turnajskou přilbou a modro - zlatými přikrývadly."
    },
    {
        obec: "Skály",
        kod: 549851,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39811,
        latitude: 49.219961,
        longitude: 14.160276,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Sk%C3%A1ly_PI_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou kvádrovanou hradbou se čtyřmi (3, 1) červenými růžemi se zlatými semeníky vykračující zlatý kohout s červenou zbrojí."
    },
    {
        obec: "Skalsko",
        kod: 536636,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29426,
        latitude: 50.427224,
        longitude: 14.759137,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Skalsko_znak.GIF",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílo-černě dělený, modrý se žlutým korunovaným drakem s červenou zbrojí a červeno-bíle dělený, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole stříbrno-červeně dělené, nahoře černé rozevřené kamenické kleště, dole vyrůstající zlatá hlavice berly, vlevo v modrém poli zlatý korunovaný drak s červenou zbrojí."
    },
    {
        obec: "Skály",
        kod: 598909,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.183483,
        longitude: 13.99296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Sk%C3%A1ly_%28Strakonice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 8 : 1 : 7. Žlutý pruh ve třetině délky listu podložený bílým mečem hrotem k hornímu okraji listu. Žlutý jílec meče drží bílá rukavice - plechovice. Čepel meče nad žlutým pruhem provázejí dvě bílé lilie, každá ve středu s červenou růží se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý most. Nahoře dvě stříbrné lilie vedle sebe, každá ve středu s červenou růží se zlatým semeníkem. Most podložen vztyčeným stříbrným mečem se zlatým jílcem, drženým v mostním oblouku stříbrnou rukavicí - plechovicí."
    },
    {
        obec: "Skapce",
        kod: 579467,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.657074,
        longitude: 12.990454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Skapce_CoA.jpg",
        coatOfArmsDescription: "V modrém štítě dolní část stříbrné lilie s přepásáním, z něhož vyrůstají zlaté beraní rohy."
    },
    {
        obec: "Skašov",
        kod: 539937,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.510567,
        longitude: 13.431498
    },
    {
        obec: "Skaštice",
        kod: 588989,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.332412,
        longitude: 17.417495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Ska%C5%A1tice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy střídavě bílé a červené, v poměru 5:1:1:1. V žerďové části horního bílého pruhu zelené kruhové pole se třemi vyrůstajícími odkloněnými žlutými obilnými klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně sníženě vlnitě děleném štítě nahoře v zeleném kotouči tři vyrůstající odkloněné zlaté obilné klasy, dole tři stříbrné kužely."
    },
    {
        obec: "Sklené",
        kod: 596736,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.609496,
        longitude: 16.00697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Sklen%C3%A9_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. Uprostřed modrého pruhu bílá sekera-širočina bez topůrka, provázená nahoře pěti žlutými hvězdami do oblouku. V červeném pruhu bílý skleněný pohár provázený dvěma sedícími přivrácenými žlutými lvy s odvrácenými hlavami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře stříbrná sekera-širočina bez topůrka provázená do oblouku pěti zlatými pětihrotými hvězdami, dole stříbrný skleněný pohár provázený dvěma sedícími přivrácenými zlatými lvy s odvrácenými hlavami."
    },
    {
        obec: "Sklené",
        kod: 578754,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.70686,
        longitude: 16.530602,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Sklenn%C3%A9_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se třemi bílými žerďovými klíny s vrcholy v jedné třetině délky listu, v každém červená růže. Ve střední a žerďové části listu žlutý pohár provázený na žerďové straně klíčem zuby dolů a k dolnímu rohu a na vlající straně mečem hrotem dolů, oboje bílé a odkloněné. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se třemi stříbrnými klíny v hlavě, každý s jednou červenou růží, zlatý pohár provázený gotickým klíčem a mečem, obojí stříbrné, postavené a odkloněné."
    },
    {
        obec: "Sklené nad Oslavou",
        kod: 596744,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.438204,
        longitude: 16.057544,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Coat_of_arms_of_Sklen%C3%A9_nad_Oslavou.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. Uprostřed bílé kruhové pole o průměru dvou pětin šířky listu, ke kterému přiléhá v modrém pruhu polovina žlutého palečného kola, ze kterého vyniká do bílého pruhu červená cihlovaná sklářská pec. V bílém kruhovém poli černá sklenice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře vyniká cihlová sklářská pec, dole polovina zlatého palečného kola, uprostřed stříbrný kotouč, v něm černá sklenice."
    },
    {
        obec: "Skočice",
        kod: 551741,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38775,
        latitude: 49.186215,
        longitude: 14.104365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Sko%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3 : 5. V modrém pruhu lilie mezi dvěma mezikružími s vepsanými šesticípými hvězdami, vše žluté. V červeném pruhu zkřížené bílé sekery na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně zvýšeně děleném štítě, nahoře lilie mezi dvěma kruhy s vepsanými hvězdami, vše zlaté, dole zkřížené stříbrné sekery na zlatých topůrkách."
    },
    {
        obec: "Skomelno",
        kod: 566845,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.847471,
        longitude: 13.642262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Skomelno_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým kosmým pruhem širokým dvě pětiny šířky listu. V pruhu běžící červená liška. V žerďovém poli kosmo upolín evropský se žlutým květem, zeleným stonkem s listem k dolnímu okraji. Ve vlajícím poli kosmo žlutý dubový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmé zlaté břevno s běžící červenou liškou, provázené nahoře kosmo vztyčeným zlatým dubovým listem a dole kosmo položeným upolínem evropským s listem přirozené barvy."
    },
    {
        obec: "Skorkov",
        kod: 548324,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58253,
        latitude: 49.50855,
        longitude: 15.476213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Coat_of_arms_of_Skorkov_HB.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vlnkovitý modrý, v poměru 4 : 1. V bílém pruhu útočící černá rozkřídlená orlice se žlutou zbrojí a jazykem, v modrém bílá růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě útočící černá rozkřídlená orlice se zlatou zbrojí a jazykem nad modrou vlnkovitou patou se stříbrnou růží se zlatým semeníkem."
    },
    {
        obec: "Skopytce",
        kod: 553085,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.331555,
        longitude: 14.798253
    },
    {
        obec: "Skorkov",
        kod: 557030,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29474,
        latitude: 50.231801,
        longitude: 14.749378,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Skorkov_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s horními žlutými trojúhelníky, žerďovým s vrcholy v horním rohu, polovině žerďového a horního okraje, a vlajícím s vrcholy v horním cípu, uprostřed horního a vlajícího okraje listu. V zeleném poli pod žlutou slaměnou střechou na kůlech (brahem) skorec vodní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou slaměnou střechou na dvou kůlech (brahem) skorec vodní přirozené barvy."
    },
    {
        obec: "Skoronice",
        kod: 586544,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69641,
        latitude: 48.981428,
        longitude: 17.153534,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Skoronice_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Bílý list s černou orlicí se žlutou zbrojí držící v pařátech k vlajícímu okraji obráceného žlutě korunovaného zeleného hada. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá orlice se zlatou zbrojí držící v pařátech doleva obráceného zeleného korunovaného hada."
    },
    {
        obec: "Skorošice",
        kod: 553484,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79065,
        latitude: 50.308643,
        longitude: 17.080738,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Skoro%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý v poměru 1:2:1. V červeném poli dvě zkřížené žluté sekery provázené po stranách a dole třemi bílými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zkřížené zlaté sekery provázené po stranách a dole třemi stříbrnými růžemi se zlatými semeníky."
    },
    {
        obec: "Skorotice",
        kod: 596752,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59262,
        latitude: 49.457421,
        longitude: 16.359248,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Skorotice_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno - modře kosmo dělený list. V červeném poli bílý beránek ležící na žluté zavřené knize se dvěma propletenými bílými záložkami, v modrém poli žlutý půlměsíc cípy k hornímu okraji, mezi nimi bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro - červeně kosmo dělený štít. Nahoře stříbrná lilie uvnitř zlatého stoupajícího půlměsíce, dole stříbrný beránek ležící na položené zlaté zavřené knize se dvěma stříbrnými propletenými záložkami."
    },
    {
        obec: "Skořenice",
        kod: 580929,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.031358,
        longitude: 16.222061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Sko%C5%99enice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a modrý. Uprostřed listu žlutý vykořeněný kmen s dvojicí odvrácených listů po stranách, nahoře zakončený bílou loveckou trubkou se žlutým kováním a závěsem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-modře polceném štítě zlatý kmen s kořeny a dvojicí odvrácených listů po stranách, zakončený stříbrnou loveckou trubkou se zlatým kováním a závěsem."
    },
    {
        obec: "Skořice",
        kod: 506664,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.677828,
        longitude: 13.705369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Sko%C5%99ice_ofici%C3%A1ln%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm kosmých pruhů střídavě modrých a žlutých a červené karé o straně rovné jedné třetině délky listu s bílým beranem se žlutou zbrojí ve skoku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě se třemi krokvemi opačných barev červená čtvrť se stříbrným beranem se zlatou zbrojí ve skoku."
    },
    {
        obec: "Skotnice",
        kod: 568716,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74258,
        latitude: 49.657664,
        longitude: 18.134051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Skotnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý ondřejský kříž s rameny širokými jednu osminu šířky listu, bílé žerďové pole s červenou šesticípou hvězdou, horní a dolní červená pole s bílou šesticípou hvězdou a vlající červené pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrným sníženým hrotem dva zelené zkřížené vztyčené listy kos, provázené po stranách dvěma stříbrnými a v hrotu jednou červenou hvězdou."
    },
    {
        obec: "Skrbeň",
        kod: 552151,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78335,
        latitude: 49.641248,
        longitude: 17.176605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Skrben_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, černý a bílý v poměru 5:2:5. V horním žerďovém rohu červená koňská hlava s krkem o výšce poloviny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s černým kůlem červená koňská hlava."
    },
    {
        obec: "Skrchov",
        kod: 582344,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.57783,
        longitude: 16.549464,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Skrchov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý vlnitý a zvlněný žlutý, v poměru 5:1:4. V bílém pruhu v žerďové polovině položené černé křídlo se žlutým, jetelovitě zakončeným perizoniem, ve vlající polovině černé zkřížené ostrve. Ve žlutém pruhu červený zvon. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě modrým vlnitým břevnem děleném štítě nahoře položené černé křídlo se zlatým, jetelovitě zakončeným perizoniem a černé zkřížené ostrve, dole červený zvon."
    },
    {
        obec: "Skršín",
        kod: 567361,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.465729,
        longitude: 13.754506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Skr%C5%A1%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílým vrchem vynikajícím z druhé a třetí čtvrtiny dolního okraje listu a dosahujícím do sedmi desetin šířky listu, v něm modrý vinný hrozen. Na vrchu žlutý obilný snop po stranách provázený bílým pětilistým květem se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný snop na stříbrném vrchu s modrým vinným hroznem, provázený po stranách stříbrným pětilistým květem se zlatým středem."
    },
    {
        obec: "Skrýchov u Malšic",
        kod: 560669,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39175,
        latitude: 49.33027,
        longitude: 14.596666
    },
    {
        obec: "Skryje",
        kod: 549894,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.393663,
        longitude: 16.310789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Skryje_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se zeleným pravoúhlým klínem na dolním okraji listu. V horní polovině žerďové části červeného pole otevřená kniha, ve vlající hlavice berly závitem k vlajícímu okraji listu, obojí bílé. V klínu žlutý vykořeněný listnatý strom. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně sníženým hrotem děleném štítě nahoře vpravo otevřená kniha, vlevo hlavice berly závitem doleva, obojí stříbrné, dole zlatý vykořeněný listnatý strom."
    },
    {
        obec: "Skryje",
        kod: 569461,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.843818,
        longitude: 15.487779
    },
    {
        obec: "Skryje",
        kod: 542385,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27042,
        latitude: 49.964953,
        longitude: 13.764601,
        coatOfArmsFlagDescription: "Modrý list se žlutým trilobitem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý trilobit."
    },
    {
        obec: "Skřinářov",
        kod: 596761,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.347147,
        longitude: 16.1721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Sk%C5%99in%C3%A1%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, zubatý bílý, červený, zelený a zubatý bílý, v poměru 1 : 3 : 3 : 1. Uprostřed žlutý zvon s bílým liliovým srdcem zavěšený na bílém vlčím háku vodorovně probodávajícím dva rohy, bílý v červeném a žlutý v zeleném pruhu. Bílé pruhy mají pět obdélníkových zubů a čtyři čtvercové mezery sahající do poloviny šířky pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nad sníženou stříbrnou kvádrovanou hradbou dva rohy, vpravo stříbrný, vlevo zlatý, probodnuté nahoře stříbrným vlčím hákem se zavěšeným zlatým zvonem se stříbrným liliovým srdcem."
    },
    {
        obec: "Skřipel",
        kod: 533963,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.842088,
        longitude: 14.067132,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Sk%C5%99ipel_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý. Uprostřed žlutá kančí hlava s bílou zbrojí držící v tlamě kosmo žlutou berlu závitem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě kančí hlava se stříbrnou zbrojí držící v tlamě kosmo položenou berlu, obojí zlaté."
    },
    {
        obec: "Skřipov",
        kod: 510131,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74745,
        latitude: 49.818845,
        longitude: 17.910595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Sk%C5%99ipov_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým středovým kůlem širokým jednu pětinu délky listu. Na kůlu tři červené obrácené krokve široké jednu šestinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě stříbrný kůl se třemi červenými obrácenými krokvemi. Vpravo na pahorku jehličnatý, vlevo listnatý strom, vše přirozených barev."
    },
    {
        obec: "Skřípov",
        kod: 590002,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.583631,
        longitude: 16.82033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Skripov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý cimbuřový, zelený, bílý cimbuřový a červený v poměru 2:1:10:2:1. V zeleném pruhu žlutá lípa vyrůstající z bílé položené radlice hrotem k žerdi. Bílé pruhy mají po čtyřech čtvercových zubech a pěti stejných mezerách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se stříbrným cimbuřím zlatá lípa vyrůstající z položené stříbrné radlice."
    },
    {
        obec: "Skřivany",
        kod: 570834,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50352,
        latitude: 50.268616,
        longitude: 15.499348,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/CoA_of_Sk%C5%99ivany.svg",
        coatOfArmsFlagDescription: "Červený list se čtyřmi vlnitými pruhy, střídavě bílými a modrými, vycházejícími ze šestnácté až devatenácté dvacetiny žerďového a vlajícího okraje, s jedním vrcholem a dvěma prohlubněmi. Z pruhů vyrůstá žlutá věž s cimbuřím o šesti zubech a s pěti černými střílnami, provázená dvěma bílými, černě zabalenými homolemi cukru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze čtyřikrát modro-stříbrně vlnitě dělené paty vyrůstá zlatá věž s cimbuřím a pěti černými střílnami, provázená dvěma stříbrnými, černě zabalenými homolemi cukru."
    },
    {
        obec: "Skuhrov",
        kod: 569470,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58241,
        latitude: 49.684765,
        longitude: 15.532234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Skuhrov_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V červeném pruhu žlutá berla s lipovitě zakončeným závitem k žerdi, dole podložená obrácenou bílou podkovou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatá berla s lipovitě zakončeným závitem, dole podložená obrácenou stříbrnou podkovou."
    },
    {
        obec: "Skuhrov",
        kod: 563803,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.676103,
        longitude: 15.213411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Skuhrov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovný červený pruh u horního okraje listu, široký čtvrtinu šířky listu, a dva svislé pruhy, žlutý a černý. V červeném pruhu položené rozevřené bílé mačkářské kleště s modrým kamenem v dolním kaplíku. Do svislých pruhů z dolního okraje listu vyrůstá zeleno-žlutě polcená lípa. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-černě polceném štítě s červenou hlavou vyrůstající zeleno-zlatě polcená lípa. V hlavě štítu položené stříbrné rozevřené mačkářské kleště s modrým kamenem v dolním kaplíku."
    },
    {
        obec: "Skuhrov",
        kod: 531740,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26727,
        latitude: 49.87342,
        longitude: 14.146829,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Skuhrov_%28Beroun_District%29_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 9 : 2 : 9. V žerďové polovině modrého pruhu hvězda, ve vlající polovině oheň se třemi plameny. V žerďové polovině zeleného pruhu vztyčený dubový list, ve vlající polovině obilný klas bez stébla, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně stříbrným vlnitým břevnem děleném štítě nahoře pětihrotá hvězda a oheň se třemi plameny, dole vztyčený dubový list a obilný klas bez stébla, vše zlaté."
    },
    {
        obec: "Skuhrov nad Bělou",
        kod: 576778,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51703,
        latitude: 50.229557,
        longitude: 16.29241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/CoA_of_Skuhrov_nad_B%C4%9Blou.svg",
        coatOfArmsFlagDescription: "Na dolním okraji listu modrý vodorovný pruh široký jednu třetinu šířky listu. Z první třetiny horního okraje listu vychází do druhé třetiny horního okraje modrého pruhu bílý kosmý pruh a z druhé třetiny horního okraje listu vychází do třetí třetiny horního okraje modrého pruhu červený kosmý pruh. Žerďové trojúhelníkové pole je červené, vlající bílé. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Třikrát červeno-stříbrně kosmo dělený štít s modrou patou."
    },
    {
        obec: "Skuteč",
        kod: 572241,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53973,
        latitude: 49.843567,
        longitude: 15.996654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Skute%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zubatý bílý v poměru 5:2. Bílý pruh má sedm obdélníkových zubů a šest čtvrtinových mezer. Z něj vyrůstají nad druhou a pátou mezerou dvě bílé věže se třemi černými okny (1,2), s cimbuřím a černou báňovitou střechou se žlutou makovicí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Skvrňov",
        kod: 533696,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28144,
        latitude: 49.897204,
        longitude: 15.001923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Skvr%C5%88ov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. Uprostřed kosmo obilný klas s listem, šikmo podložený berlou závitem k hornímu rohu listu, provázený k žerďovému okraji pecnem chleba a dole osmicípou hvězdou, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě kosmo obilný klas, vlevo s listem, šikmo podložený berlou a provázený vpravo pecnem chleba a dole osmihrotou hvězdou, vše opačných barev."
    },
    {
        obec: "Slabce",
        kod: 542415,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27041,
        latitude: 50.000175,
        longitude: 13.714888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/CoA_of_Slabce.svg",
        coatOfArmsFlagDescription: "List tvoří bílá žerďová a vlající červená část, na středu listu zámek opačných barev. Zámek tvoří středová věž s branou a bočními křídly se sedlovými střechami. Věž i křídla mají po čtyřech (2+2) oknech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě budova zámku opačných barev. Zámek tvoří středová věž s branou a bočními křídly se sedlovými střechami. Věž i křídla mají po čtyřech (2,2) oknech"
    },
    {
        obec: "Slabčice",
        kod: 549860,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39847,
        latitude: 49.326635,
        longitude: 14.337975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Slab%C4%8Dice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a zelený, v poměru 2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném vrchu s lilií kalich, obojí zlaté."
    },
    {
        obec: "Slaná",
        kod: 577529,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51201,
        latitude: 50.580911,
        longitude: 15.329787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Slan%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a zelený, v poměru 3 : 4 : 3. V modrém pruhu bílá růže se zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně modrým obloukovým břevnem dělený štít, v něm čtyři stříbrné růže se zelenými kališními lístky."
    },
    {
        obec: "Slaník",
        kod: 536776,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.268386,
        longitude: 13.951671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/CoA_of_Slan%C3%ADk.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený. V horním pruhu tři vztyčené bílé lipové listy, přes střední a dolní pruh mezikruží s vepsaným osmihrotým křížem, vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná lípa vyrůstající ze zvýšeného, stříbrno - červeně vlnitě děleného návrší, v něm osmihrotý kříž v kruhu, obojí opačných barev."
    },
    {
        obec: "Slaný",
        kod: 532819,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27401,
        latitude: 50.230562,
        longitude: 14.087044,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Slan%C3%BD_znak.png",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými klíny, vycházejícími z dolní poloviny žerďového a horní poloviny vlajícího okraje listu, s vrcholy v polovině délky listu. V horní části žerďové poloviny listu český lev. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Slapsko",
        kod: 599026,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.575208,
        longitude: 14.762716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Slapsko_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zeleno-modře polcený, v poměru 1 : 2. V červeném pruhu bílý meč se žlutým jílcem hrotem k žerďovému okraji listu. V zeleném poli žlutý mlýnský kámen bez kypřice, v modrém bílá ryba. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít s červenou hlavou, v ní položený stříbrný meč se zlatým jílcem. V pravém poli dva zlaté mlýnské kameny bez kypřice pod sebou, v levém poli dvě modrá břevna, v každém stříbrná ryba."
    },
    {
        obec: "Slapy",
        kod: 599042,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39176,
        latitude: 49.38703,
        longitude: 14.616671,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Slapy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, a červeno-bíle dělený vlající klín sahající do první třetiny délky listu s bílo-modře dělenou podkovou obloukem k vlajícímu okraji. V žerďové části bílého pruhu červená růže s bílým semeníkem, pod ní v modrém pruhu bílá růže s modrým semeníkem. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě červeno-stříbrně polcený hrot provázený vpravo červenou růží se stříbrným semeníkem, vlevo stříbrnou růží s modrým semeníkem, v hrotu obrácená stříbrno-modře polcená podkova."
    },
    {
        obec: "Slatina",
        kod: 594776,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 49.019749,
        longitude: 16.018451,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Coat_of_arms_of_Slatina_ZN.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou palisádou na dolním okraji, vysokou třetinu šířky listu. Nad ní čelně bílá ovčí hlava. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě čelně ovčí hlava nad palisádou, vše stříbrné."
    },
    {
        obec: "Slapy",
        kod: 539660,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25208,
        latitude: 49.815412,
        longitude: 14.396225,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/CZE_Slapy_COA.svg",
        coatOfArmsFlagDescription: "List šikmo dělený bílým vlnitým pruhem na červené žerďové a modré vlající pole. Pruh s jednou prohlubní a jedním vrcholem vychází ze čtvrté čtvrtiny žerďového okraje do první čtvrtiny vlajícího okraje listu. V červeném poli pod žlutou korunou s modrými kameny kosmo žlutý gotický klíč zuby k žerďovému okraji, šikmo podložený bílým mečem se žlutým jílcem hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Šikmým stříbrným dvakrát prohnutým břevnem červeno-modře dělený štít.V červeném poli pod zlatou korunou s modrými kameny kosmo vztyčený zlatý gotický klíč šikmo podložený vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Slatina",
        kod: 568406,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74293,
        latitude: 49.790806,
        longitude: 17.979978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Slatina_%28Novy_Jicin%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: černý, červený a bílý v poměru 2:9:9. V červeném pruhu dvě zkřížené sekery nad radlicí, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-červeně dělený štít, nahoře horní polovina slezské orlice, dole dvě zkřížené sekery nad radlicí, vše stříbrné."
    },
    {
        obec: "Slatina",
        kod: 578762,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.629744,
        longitude: 16.607918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Slatina_SY_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým karé širokým dvě třetiny šířky listu; v něm bílé karé, široké jednu třetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou hlavou se zeleným motýlem zlatý glóbus."
    },
    {
        obec: "Slatina",
        kod: 580945,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.978184,
        longitude: 16.163803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Slatina.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, červený pilovitý, modrý vlnitý, bílý vlnitý a černý zvlněný, v poměru 8:3:1:1:1. Do bílého pruhu vyniká černá orlice s červenou zbrojí, držící v pravém pařátu kosmo bílý meč se žlutým jílcem a v levém šikmo žlutý klíč zuby k vlajícímu okraji. Vlnité a zvlněný pruh mají tři vrcholy a čtyři prohlubně. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě v patě dvě vlnitá břevna, modré a černé, z modrého vyrůstá červený prkenný plot, z něhož vyniká černá orlice s červenou zbrojí držící v pravém pařátu stříbrný meč se zlatým jílcem a v levém zlatý klíč."
    },
    {
        obec: "Slatina",
        kod: 578436,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.38787,
        longitude: 13.744269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Slatina_KT_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý. V bílých pruzích po jedné černé lilii. V modrém pruhu ze zeleného trojvrší na dolním okraji pruhu vyrůstá žlutý obilný klas se dvěma listy, k žerdi vztyčeným, k vlajícímu okraji svěšeným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zeleného trojvrší vyrůstají tři zlaté obilné klasy s listy, krajní odkloněné, přes ně stříbrné břevno se třemi černými liliemi."
    },
    {
        obec: "Slatina",
        kod: 566748,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.984635,
        longitude: 13.634016
    },
    {
        obec: "Slatina",
        kod: 532827,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27326,
        latitude: 50.224262,
        longitude: 14.220845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Slatina_%28Kladno_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným lemem, se čtyřmi (1, 2, 1) odvrácenými žlutými lipovými listy, širokým šestinu šířky listu. V bílém poli tři vlnité modré pruhy přeložené kosmo berlou závitem k dolnímu rohu, šikmo podloženou vztyčeným veslem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zeleným lemem se čtyřmi (1, 2, 1) odvrácenými zlatými lipovými listy tři modrá vlnitá břevna přeložená kosmo berlou, šikmo podloženou vztyčeným veslem, obojí zlaté."
    },
    {
        obec: "Slatina",
        kod: 565601,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.430683,
        longitude: 14.038155,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Slatina_Litomerice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V žerďové polovině listu modro-bíle dělené kruhové pole o průměru jedné poloviny šířky listu. V horním modrém poli pět (2,3) žlutých šesticípých hvězd, v dolním žlutém tři spojené stonky sivěnky slatinné, každý se dvěma květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě modro-stříbrně dělený kotouč, v něm nahoře pět (2,3) zlatých hvězd, dole tři dole spojené stonky sivěnky slatinné, každý se dvěma květy, přirozené barvy."
    },
    {
        obec: "Slatina nad Úpou",
        kod: 574422,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54947,
        latitude: 50.454193,
        longitude: 16.036726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Slatinaznak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed zvon se závěsem provázený třmenem a osmicípou hvězdou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dřevěná zvonice se zavěšeným zvonem, provázená nahoře třmenem a osmihrotou hvězdou, vše zlaté."
    },
    {
        obec: "Slatina nad Zdobnicí",
        kod: 576786,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51756,
        latitude: 50.133907,
        longitude: 16.397982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Slatina_nad_Zdobnici_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zvlněný žlutý se dvěma vrcholy a třemi prohlubněmi a zelený. Do červeného pruhu vyrůstá kosmo bílé lekno. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně zlatým zúženým vlnitým kůlem polceném štítě vpravo z kůlu vyrůstající stříbrné lekno, vlevo zlatý obilný snop převýšený kosmo dolů položeným stříbrným srpem."
    },
    {
        obec: "Slatiňany",
        kod: 572268,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53821,
        latitude: 49.921196,
        longitude: 15.813873,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Znak_m%C4%9Bsta_Slati%C5%88any.png",
        coatOfArmsFlagDescription: "Červený list s bílým kosmým pruhem širokým dvě třetiny šířky listu na žerďovém okraji, který vychází z horního rohu listu a končí v dolním cípu listu. Uprostřed černý cválající kůň se žlutým uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červená kvádrovaná hradba s cimbuřím a prolomenou branou. Nad hradbou černý cválající kůň se zlatým uzděním, v bráně černé majuskulní 'A'."
    },
    {
        obec: "Slatinice",
        kod: 505111,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78342,
        latitude: 49.561652,
        longitude: 17.100026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Slatinice_znak.jpeg",
        coatOfArmsFlagDescription: "Na bílém listu červený štír hlavou k žerdi, při dolním okraji modrý vlnitý pruh v šířce jedné sedminy šířky praporu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít rozdělen červeným kosmým pruhem, nahoře stříbrný s červeným štírem, dole šest vlnitých pruhů střídavě zlatých a modrých."
    },
    {
        obec: "Slatinky",
        kod: 590011,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78342,
        latitude: 49.548787,
        longitude: 17.094088,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Slatinky_CoA_CZ.png",
        coatOfArmsDescription: "V červeném štítě do středu obrácené tři stříbrné radlice (1,2) provázené třemi do středu obrácenými zlatými vinnými hrozny se zelenými úponky."
    },
    {
        obec: "Slatiny",
        kod: 573469,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.366869,
        longitude: 15.381502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Slatiny_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a černo - žlutě šachovaný (4 x 2), v poměru 3 : 5 : 4. V bílém pruhu tři modré šesticípé hvězdy nad sebou, v modrém bílá koňská hlava s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlevo polcený štít. V pravém modrém poli pod stříbrnou vlnitou hlavou se třemi modrými hvězdami stříbrná koňská hlava s krkem, levé pole černo - zlatě dvěma řadami šachované."
    },
    {
        obec: "Slavče",
        kod: 545023,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37321,
        latitude: 48.797949,
        longitude: 14.615065
    },
    {
        obec: "Slavětice",
        kod: 591688,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.1037,
        longitude: 16.108814,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu žlutý tlapatý kříž, ve žlutém modrý jetelový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě šikmo děleném štítě nahoře tlapatý kříž, dole jetelový trojlist, obojí opačných barev."
    },
    {
        obec: "Slavětín",
        kod: 548553,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58263,
        latitude: 49.668672,
        longitude: 15.772886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Slav%C4%9Bt%C3%ADn_HB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílé karé s čelně hledící černou jelení hlavou nad stejně velkým zeleným čtvercovým polem se vztyčeným žlutým lipovým listem a dva vodorovné pruhy, zelený se zkříženými žlutými obilnými klasy a bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný hrot s čelně hledící černou jelení hlavou, provázený vpravo zkříženými obilnými klasy, vlevo vztyčeným lipovým listem, vše zlaté."
    },
    {
        obec: "Slavětín",
        kod: 552194,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78324,
        latitude: 49.67466,
        longitude: 16.963636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Coat_of_arms_of_Slav%C4%9Bt%C3%ADn_OL.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Uprostřed ze zeleného pruhu vyrůstá pět zelených jehličnatých stromů s černými kmeny. Pod nimi z dolního okraje listu vyrůstají dva odkloněné žluté obilné klasy s listem, mezi nimi bílý patriarší kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře pět vyrůstajících zelených jehličnatých stromů s černými kmeny, dole dva vyrůstající odkloněné zlaté obilné klasy s listem, mezi nimi stříbrný patriarší kříž."
    },
    {
        obec: "Slavětín",
        kod: 566713,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43909,
        latitude: 50.350713,
        longitude: 13.907818,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Slavetin_LN_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list; z dolního okraje vynikají dvě věže na kvádrovaných soklech, obojí bílé a s cimbuřím. Sokly spojeny pod cimbuřím černým trámem. Mezi věžemi žlutý štítek s kosmým bílým vlnitým břevnem se třemi kosmo vztyčenými červenými leknínovými listy. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Slavětín nad Metují",
        kod: 574431,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.329,
        longitude: 16.052982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Slav%C4%9Bt%C3%ADn_nad_Metuj%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed svislý černo-bílo-červený dělený pruh široký sedminu šířky listu obtočený žlutou rybou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě černo-stříbrno-červeně dělený kůl obtočený zlatou rybou."
    },
    {
        obec: "Slavhostice",
        kod: 572187,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.308911,
        longitude: 15.349485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Slavhostice_Coa.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným křížem s rameny širokými šestinu délky listu. V horním modrém žerďovém poli bílá růže. Dolní vlající pole modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně červeným heroldským křížem čtvrcený štít. V prvním poli růže, ve čtvrtém poli palisáda, obojí stříbrné."
    },
    {
        obec: "Slavičín",
        kod: 585751,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.088085,
        longitude: 17.873599,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Slavicin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole s bílou biskupskou mitrou a bílé vlající pole s červenou kůží, na které je žluté ozubené kolo. Poměr modrého pole k bílému je 1:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Slavičky",
        kod: 591700,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67501,
        latitude: 49.17486,
        longitude: 15.967999
    },
    {
        obec: "Slavíkov",
        kod: 569488,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58265,
        latitude: 49.742924,
        longitude: 15.780817,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Slav%C3%ADkov_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý zubatý pruh široký jednu šestinu délky listu se třemi čtvercovými zuby a čtyřmi stejnými mezerami a modré pole. V něm žlutý lipový trojlist mezi dvěma obloukovitě přivrácenými bílými rybami s červenou zbrojí hlavami nahoru. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou zlatou kvádrovanou hradbou se třemi stínkami dvě vztyčené k okrajům štítu prohnuté přivrácené stříbrné ryby s červenou zbrojí, mezi nimi zlatý lipový trojlist na stonku."
    },
    {
        obec: "Slavíkovice",
        kod: 545180,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67531,
        latitude: 49.004391,
        longitude: 15.624453,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Slav%C3%ADkovice_TR_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou krokví, vycházející z třetí třetiny žerďového a vlajícího okraje s vrcholem na horním okraji listu, a žlutým klínem vycházejícím z druhé a třetí čtvrtiny dolního okraje listu s vrcholem ve druhé třetině šířky listu. V krokvi tři (1, 2) svěšené červené leknínové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná krokev se třemi (1, 2) svěšenými červenými leknínovými listy. Dole tři zlaté obilné klasy, krajní odkloněné a s listem."
    },
    {
        obec: "Slavkov",
        kod: 510289,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74757,
        latitude: 49.9219,
        longitude: 17.836519,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, červený a bílý v poměru 1:3:1. V červeném poli tři bílé meče se žlutým jílcem a záštitou, uspořádané do tvaru písmene 'Y'. Do středu směřující meče pronikají žlutým kruhovým polem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V hlavě červeného štítu tři stříbrné kužely, pod nimi zlatá koule probodena třemi stříbrnými meči se zlatými rukojeťmi."
    },
    {
        obec: "Slavkov",
        kod: 592579,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68764,
        latitude: 48.946995,
        longitude: 17.611677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Slavkov_UH.jpg",
        coatOfArmsFlagDescription: "Zelený list se šikmým červeným a žlutým pruhem, každým širokým jednu dvanáctinu délky listu. V horním rohu a dolním cípu bílý květ hvozdíku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou hlavou zlatý kůl, v nich červené hrábě. Kůl je provázen dvěma stříbrnými květy hvozdíku."
    },
    {
        obec: "Slavkov pod Hostýnem",
        kod: 588997,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.377245,
        longitude: 17.670437,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/CoA_of_Slavkov_pod_Host%C3%BDnem.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě černých a bílých (3+2) a zelený žerďový klín sahající do poloviny délky listu s bílo-červeně polcenou lilií. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-stříbrně dělený štít se dvěma stříbrnými břevny v horní části, překrytý zeleným vydutým hrotem, v něm stříbrno-červeně polcená lilie."
    },
    {
        obec: "Slavkov u Brna",
        kod: 593583,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.153354,
        longitude: 16.876598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Slavkov_u_Brna_znak_hires.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovné pruhy - modrý a červený. Ve střední části listu je žluté kruhové pole o průměru čtyři pětiny šířky listu. V první čtvrtině žlutého pole je čtvrtina černé k žerdi hledící orlice s červeným zobákem a vyplazeným jazykem, ve druhé čtvrtině žlutého pole je od žerdi hledící bílo-červeně šachovaná orlice s červeným vyplazeným jazykem. V dolní polovině žlutého pole je dolní polovina bílého dvouocasého lva. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Slavníč",
        kod: 548341,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58255,
        latitude: 49.514827,
        longitude: 15.452365
    },
    {
        obec: "Slavonice",
        kod: 547166,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37881,
        latitude: 48.997636,
        longitude: 15.351639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Slavonice_znak.png",
        coatOfArmsFlagDescription: "Modro-žlutě šikmo dvakrát lomeně dělený list s body lomu na třetinách vodorovné osy listu. V horním rohu žlutá růže s červeným semeníkem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Slavoňov",
        kod: 574457,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.343852,
        longitude: 16.204042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Coat_of_arms_of_Slavo%C5%88ov.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým lemem širokým jednu sedminu délky listu. V zeleném poli bílý vlk s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší se stříbrným kráčejícím vlkem s červenou zbrojí červená věž se dvěma okny, provázená dvěma smrky přirozené barvy."
    },
    {
        obec: "Slavošov",
        kod: 534391,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.762279,
        longitude: 15.152072
    },
    {
        obec: "Slepotice",
        kod: 575658,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.992168,
        longitude: 15.96145,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Znak_obce_Slepotice.gif",
        coatOfArmsFlagDescription: "Modrý list. V žerďové části pod sebou tři bílé růže se žlutými semeníky. Uprostřed ozbrojenec v plátové zbroji, stojící v loďce a opírající se pravicí o meč, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě v loďce stojící ozbrojenec v plátové zbroji opírající se pravicí o meč, vše zlaté, nad ním tři stříbrné růže se zlatými semeníky vedle sebe."
    },
    {
        obec: "Slezské Pavlovice",
        kod: 551881,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.309757,
        longitude: 17.700802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Slezsk%C3%A9_Pavlovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, černý, žlutý a červený, v poměru 1:2:1. Ve žlutém pruhu na červené kouli s bílou lilií ležící černý odvrácený jednorožec s červeným rohem a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na červené kouli se stříbrnou lilií ležící černý odvrácený jednorožec s červeným rohem a jazykem."
    },
    {
        obec: "Slezské Rudoltice",
        kod: 597813,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79397,
        latitude: 50.207003,
        longitude: 17.681129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Slezsk%C3%A9_Rudoltice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu čtvrtinu délky listu a bílé pole s černým koněm s modrou ohlávkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku u listnatého stromu přirozené barvy zleva modrou uzdou uvázaný černý kůň."
    },
    {
        obec: "Slopné",
        kod: 585769,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76323,
        latitude: 49.157064,
        longitude: 17.847218,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Znakslopne.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1:3:1. V zeleném pruhu bílý pes ve skoku s červenou zbrojí, jazykem a žlutým obojkem, držící žlutý chléb. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný pes ve skoku s červenou zbrojí, jazykem a zlatým obojkem, držící zlatý chléb a provázený vlevo nahoře zlatým obilným klasem."
    },
    {
        obec: "Sloup",
        kod: 582352,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67913,
        latitude: 49.414886,
        longitude: 16.739636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Znak_m%C4%9Bsta_Sloup.gif",
        coatOfArmsFlagDescription: "List tvoří svislý modrý žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů: červený , bílý, modrý, bílý a červený v poměru 1:1:2:1:1. V žerďovém pruhu pod sebou dvě žluté lilie a ve středu listu na vodorovném modrém pruhu žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou s třemi zlatými liliemi modrý kůl se zlatým sloupem, provázený dvěma červenými přivrácenými lososy."
    },
    {
        obec: "Sloup v Čechách",
        kod: 562050,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47152,
        latitude: 50.739037,
        longitude: 14.588922,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Sloup_v_Cechach_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list s bílou ostrví o čtyřech sucích sahající od horního k dolnímu okraji. Šířka ostrve je jedna šestina délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný ostrvový kůl se čtyřmi suky."
    },
    {
        obec: "Sloupno",
        kod: 548499,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.73709,
        longitude: 15.749894,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Sloupno_HB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým středovým křížem s rameny širokými jednu osminu šířky listu. Ve střední části polovina černého zubra ve skoku se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný sloupový heroldský kříž, přes něj polovina černého zubra ve skoku se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Sloupnice",
        kod: 580325,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56553,
        latitude: 49.920923,
        longitude: 16.330377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Sloupnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, bílý, zelený, žlutý, modrý, žlutý, zelený a bílý, v poměru 1:1:1:6:1:1:1. V modrém pruhu bílá plovoucí labuť se žlutým zobákem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na třikrát modro-stříbrně vlnitě dělené vlnité patě stříbrná plovoucí labuť se zlatým zobákem pod stříbrnou růží se zlatým semeníkem, provázená dvěma zlatými snopy."
    },
    {
        obec: "Sloupno",
        kod: 570851,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50353,
        latitude: 50.257418,
        longitude: 15.502075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Sloupno_HK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List se žlutou krokví vycházející ze čtvrté sedminy žerďového a vlajícího okraje a s vrcholem uprostřed horního okraje listu, s bílým vlnitým pruhem vycházejícím z páté sedminy žerďového a vlajícího okraje, s modrým vlnitým pruhem vycházejícím ze šesté sedminy žerďového a vlajícího okraje a s bílým zvlněným pruhem vycházejícím ze sedmé sedminy žerďového a vlajícího okraje. Horní žerďový trojúhelník nad krokví je zelený s bílým jablkem, horní vlající trojúhelník je červený s bílou pravou rukavicí - plechovnicí. V modrém poli pod krokví žlutý patriarší kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve štítě nad stříbrnou vlnitou patou s modrým vlnitým břevnem zlatá krokev, pod ní v modrém poli zlatý patriarší kříž, v pravém zeleném poli stříbrné jablko, v levém červeném poli vztyčená stříbrná pravá rukavice - plechovnice."
    },
    {
        obec: "Sloveč",
        kod: 537802,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28903,
        latitude: 50.229948,
        longitude: 15.326892,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Slove%C4%8D_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list s červeným šikmým pruhem širokým čtvrtinu délky listu s bílým mečem hrotem k hornímu cípu. V žerďovém poli modrá lilie, ve vlajícím vztyčený zelený lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červené šikmé břevno se vztyčeným stříbrným mečem provázené nahoře modrou lilií a dole vztyčeným zeleným lipovým listem."
    },
    {
        obec: "Slověnice",
        kod: 532231,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.757388,
        longitude: 14.885194
    },
    {
        obec: "Sluhy",
        kod: 538779,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.192538,
        longitude: 14.557782,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Sluhy_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým jednu čtvrtinu délky listu na žerďové modré a vlající červené pole. Horní okraj pruhu tvoří úhlopříčku listu. V modrém poli žlutá hlavice berly závitem k vlajícímu okraji, přes bílý pruh a červené pole černý beraní roh obrácený k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Šikmo dělený štít, nahoře v modrém poli zlatá hlavice berly závitem doleva, v dolním stříbrno-červeně šikmo děleném poli doprava obrácený černý beraní roh."
    },
    {
        obec: "Slunečná",
        kod: 546275,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47301,
        latitude: 50.735875,
        longitude: 14.482593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Slune%C4%8Dn%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se zeleným trojvrším na dolním okraji listu. Prostřední vrch vysoký dvě pětiny, krajní pětinu šířky listu. Z prostředního vrchu s maltézským křížem vyniká slunce bez tváře, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyniká ze zeleného trojvrší s maltézským křížem slunce, obojí zlaté."
    },
    {
        obec: "Slup",
        kod: 594784,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.781603,
        longitude: 16.199287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Slup_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a žlutých, v poměru 2:1:12:1:2. Z dolního žlutého pruhu vyrůstají tři bílé odkloněné lilie na stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Z dolního okraje modrého štítu vyniká zlaté palečné kolo se třemi vyrůstajícími stříbrnými odkloněnými liliemi na stoncích."
    },
    {
        obec: "Slušovice",
        kod: 585777,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.247918,
        longitude: 17.801607,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/Coa_Czech_Town_Slu%C5%A1ovice.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými osmicípými hvězdami ve střední části. Hvězdy tvoří trojúhelník s hrotem dolů. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný stojící a ohlížející se beránek sv. Jana Křtitele se zlatou zbrojí. Pravou přední nohou přidržuje zlatou hůl zakončenou stříbrným křížem. Beránek je provázen třemi zlatými osmihrotými hvězdami."
    },
    {
        obec: "Sluštice",
        kod: 538787,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25084,
        latitude: 50.039141,
        longitude: 14.685507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Slu%C5%A1tice_CoA.jpg"
    },
    {
        obec: "Služátky",
        kod: 573604,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.684981,
        longitude: 15.431488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Slu%C5%BE%C3%A1tky_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý s pěti modrými liliemi vedle sebe, a červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít se stříbrnou hlavou, v ní pět modrých lilií vedle sebe. V pravém modrém poli stříbrné průčelí kaple s obloukovým vstupem a věžičkou. Levé pole dvakrát černo-stříbrno-červeně dělené."
    },
    {
        obec: "Služovice",
        kod: 510297,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74728,
        latitude: 49.988605,
        longitude: 17.994974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Slu%C5%BEovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část a čtvercová střední a vlající modrá část. Na rozhraní obou polí listnatý vykořeněný strom opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě listnatý vykořeněný strom obrácených barev."
    },
    {
        obec: "Smečno",
        kod: 532835,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27305,
        latitude: 50.188542,
        longitude: 14.040474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Sme%C4%8Dno_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílými vykořeněnými lekny. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Smědčice",
        kod: 540722,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.799216,
        longitude: 13.512935
    },
    {
        obec: "Smetanova Lhota",
        kod: 549878,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39804,
        latitude: 49.447708,
        longitude: 14.087266,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Smetanova_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list, v něm mezi dvěma maltézskými kříži kaple s prázdnou lucernou a bání zakončenou makovicí s šesticípou hvězdou, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstající kaple s prázdnou lucernou a bání zakončenou makovicí s hvězdou. Lucerna provázena dvěma maltézskými kříži, vše stříbrné."
    },
    {
        obec: "Smidary",
        kod: 570869,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50353,
        latitude: 50.291556,
        longitude: 15.477355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Smidary_znak.png"
    },
    {
        obec: "Smilovice",
        kod: 552658,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73955,
        latitude: 49.660869,
        longitude: 18.571876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Smilowiceherb.PNG",
        coatOfArmsFlagDescription: "Šikmo dělený list, horní bílé pole se zelenou čtvercovou mříží a dolní červené s bílým rakem klepety vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně šikmo dělený štít, horní pole zeleně mřežováno, v dolním poli šikmo položený stříbrný rak."
    },
    {
        obec: "Smilkov",
        kod: 530611,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25789,
        latitude: 49.602598,
        longitude: 14.617508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Smilkov_CoA.jpg"
    },
    {
        obec: "Smilovice",
        kod: 536652,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29442,
        latitude: 50.306626,
        longitude: 14.961154
    },
    {
        obec: "Smilovice",
        kod: 565407,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.247047,
        longitude: 13.807807,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Smilovice_RA_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed bílý korunovaný lev se žlutou zbrojí. V žerďové části tři vztyčené dole spojené obilné klasy s odkloněnými listy, ve vlající části svěšená chmelová větvička se třemi šišticemi a listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný korunovaný lev se zlatou zbrojí, provázený nahoře třemi vztyčenými, dole spojenými obilnými klasy s odkloněnými listy a svěšenou chmelovou větvičkou se třemi šišticemi a listem, vše zlaté."
    },
    {
        obec: "Smilovy Hory",
        kod: 553123,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39152,
        latitude: 49.536512,
        longitude: 14.875956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Smilovy_Hory_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 4 : 1. V karé tři vodorovné pruhy, černý se třemi žlutými liliemi, žlutý a černý se třemi bílými růžemi se žlutými semeníky. Ve střední části červeného pruhu obrněné rameno držící šikmo vztyčený meč se žlutým jílcem, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo - červeně polceném štítě nad zeleným návrším vpravo zlaté břevno provázené nahoře třemi zlatými liliemi, dole třemi stříbrnými růžemi se zlatými semeníky, vlevo obrněné rameno držící šikmo vztyčený meč se zlatým jílcem, obojí stříbrné."
    },
    {
        obec: "Smiřice",
        kod: 570877,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.299879,
        longitude: 15.865181,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Znak_m%C4%9Bsta_Smi%C5%99ice.jpg"
    },
    {
        obec: "Smolné Pece",
        kod: 538027,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36225,
        latitude: 50.291579,
        longitude: 12.765442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Smolne_Pece_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílo-modře čtvrcený pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a modrý. V žerďovém bílém poli zelený vykořeněný smrk s hnědým kmenem. V horním modrém poli na zeleném návrší nahoře červeně hořící bílá kvádrovaná pec s černým topeništěm. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo vykořeněný smrk přirozené barvy, vlevo na zeleném návrší nahoře červeně hořící stříbrná kvádrovaná pec s černým topeništěm."
    },
    {
        obec: "Smolnice",
        kod: 566721,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43914,
        latitude: 50.308774,
        longitude: 13.848572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Smolnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a žlutý, v poměru 2 : 1 : 1. V červeném pruhu vykořeněný stonek vinné révy se třemi listy, v zeleném obilný klas se dvěma listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně vlevo polcený štít, vpravo vztyčený vykořeněný stonek vinné révy se třemi listy, vlevo obilný klas se dvěma listy, vše zlaté."
    },
    {
        obec: "Smolotely",
        kod: 541311,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26263,
        latitude: 49.620697,
        longitude: 14.135669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Coats_of_arms_Smolotely.jpeg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové části čtyři vodorovné pruhy, střídavě černé a žluté. V horním žlutém pruhu vedle sebe šesticípá hvězda a tlapatý kříž, oboje černé, v dolním černém pruhu žlutý šíp hrotem k žerdi. V modrém poli bílý, žlutě zdobený korunovaný turnajský helm s klenotem - vyrůstajícím bílým koněm se žlutou zbrojí a hřívou, s červeným jazykem a uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Vpravo polcený štít, pravé pole třikrát černo-zlatě dělené, v horním zlatém pruhu vedle sebe hvězda a tlapatý kříž, obojí černé, v dolním černém pruhu položený zlatý šíp. V levém modrém poli stříbrný, zlatě zdobený korunovaný turnajský helm s klenotem - vyrůstajícím stříbrným koněm se zlatou zbrojí a hřívou, s červeným jazykem a uzděním."
    },
    {
        obec: "Smrček",
        kod: 573817,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.868517,
        longitude: 15.893381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Smrcek-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V žerďové polovině listu ze zeleného pruhu vyniká mezi dvěma vyrůstajícími smrky kostelní věž s prázdným obloukovým oknem, vše zelené, pod nimi v zeleném pruhu bílá otevřená kniha. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené trojvrší se stříbrnou otevřenou knihou. Na středním vrchu kostelní věž s prázdným obloukovým oknem, na krajních smrk, vše zelené."
    },
    {
        obec: "Smrčná",
        kod: 550299,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58801,
        latitude: 49.471478,
        longitude: 15.537956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Smrcna_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a žlutý, v poměru 1:2:1. V zeleném pruhu bílý vykořeněný smrk s kmenem podloženým žluto-černě polceným vozovým kolem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo stříbrný vykořeněný smrk s kmenem podloženým zlato-černě polceným vozovým kolem, vlevo zlatý gotický dvojklíč převýšený stříbrnou, zlatě zdobenou mitrou se stříbrnými fimbriemi."
    },
    {
        obec: "Smrk",
        kod: 591726,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67501,
        latitude: 49.22568,
        longitude: 16.001974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Smrk_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 11:5. Ze zeleného pruhu vyrůstají tři zelené smrky s hnědými kmeny, prostřední vyšší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně sníženě děleném štítě nahoře vyrůstající tři smrky přirozené barvy, prostřední vyšší."
    },
    {
        obec: "Smržice",
        kod: 590029,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79817,
        latitude: 49.505793,
        longitude: 17.107084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Smr%C5%BEice_znak.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu a zelené čtvercové vlající pole. V červeném pruhu tři bílé klíny s vrcholy na žerďovém okraji, v zeleném žlutá šesticípá hvězda mezi dvěma bílými krojidly, hroty nahoru ostřím dovnitř a u dolního okraje listu překříženými. Poměr šířky k délce listu je 2:3",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými kužely zlatá hvězda mezi dvěma stříbrnými krojidly vzhůru a s překříženými dolními konci."
    },
    {
        obec: "Smržov",
        kod: 561045,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.076158,
        longitude: 14.682477
    },
    {
        obec: "Smržov",
        kod: 570885,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.299846,
        longitude: 15.917943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Smr%C5%BEov_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.png",
        coatOfArmsFlagDescription: "List dělený žlutou krokví vycházející z první a šesté šestiny vlajícího okraje listu s vrcholem na žerďovém okraji listu na tři pole - modré horní, červené dolní a zelené vlající. U žerďového okraje listu v modrém poli žlutý smrž s bílým třeněm, v červeném bílá sekera na žlutém topůrku, ostřím k žerďovém okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatý vydutý hrot. Vpravo zlatý smrž se stříbrným třeněm, vlevo stříbrná sekera na zlatém topůrku, v hrotu vyrůstající zelený jehličnatý strom s černým kmenem."
    },
    {
        obec: "Smržovka",
        kod: 563811,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46851,
        latitude: 50.738301,
        longitude: 15.246502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Smr%C5%BEovka_CoA_CZ.png"
    },
    {
        obec: "Snědovice",
        kod: 565610,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41174,
        latitude: 50.503633,
        longitude: 14.387857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Snedovice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a modrý v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít se zlatou patou, v ní zelená chmelová ratolest. Vpravo zlaté mlýnské kolo vlevo sedící vlk přirozené barvy s vyplazeným jazykem."
    },
    {
        obec: "Snět",
        kod: 532436,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.626545,
        longitude: 15.226922
    },
    {
        obec: "Sněžné",
        kod: 596787,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.645617,
        longitude: 16.121753,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Snezne_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: bílý a zelený. Ve střední části osm kruhových polí velikosti jedné čtrnáctiny umístěné do kruhu tak, že dvě protilehlé jsou položeny na rozhraní pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstají z červeného srdce tři zelené ratolesti jeřabiny se dvěma trsy červených plodů. Na bočních větévkách stojí dvě rozkřídlené přivrácené kvíčaly přirozené barvy se zlatou zbrojí."
    },
    {
        obec: "Sněžné",
        kod: 576794,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.340016,
        longitude: 16.277368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Sn%C4%9B%C5%BEn%C3%A9_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem, provázeným v rozích a ve vlající části třemi bílými sněhovými vločkami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté slunce s tváří provázené v rozích a dole třemi stříbrnými sněhovými vločkami."
    },
    {
        obec: "Snovídky",
        kod: 593591,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.132902,
        longitude: 17.106415,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Snov%C3%ADdky_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1 : 6 : 1. V modrém pruhu položená ostrev se čtyřmi suky k vlajícímu okraji, z níž vyrůstají vztyčený žalud mezi dvěma odkloněnými dubovými listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad palisádou s modrou osmihrotou hvězdou doleva položená ostrev se čtyřmi suky, z níž vyrůstají vztyčený žalud mezi dvěma odkloněnými dubovými listy, vše zlaté."
    },
    {
        obec: "Sobčice",
        kod: 573477,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.372376,
        longitude: 15.514931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Sob%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, vlnitý modrý, vlnitý bílý, vlnitý modrý a zvlněný bílý, v poměru 15:1:1:1:1. Z horního modrého pruhu vyniká žlutý kvádrovaný most se třemi oblouky, prostřední větší. Nad ním žlutá berla závitem k vlajícímu okraji s bílým sudariem mezi dvěma bílými čelně postavenými radlicemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyniká z třikrát modro stříbrně vlnitě dělené paty zlatý kvádrovaný most se třemi oblouky, prostřední větší, nad ním zlatá berla se stříbrným sudariem, provázená dvěma čelně postavenými stříbrnými radlicemi."
    },
    {
        obec: "Soběhrdy",
        kod: 530638,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.821122,
        longitude: 14.734312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/CoA_of_Sob%C4%9Bhrdy.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě červených a bílých, v poměru 2 : 3 : 5 : 3 : 2. V žerďovém bílém pruhu červený latinský kříž, v středním červeném žlutý vinný hrozen a ve vlajícím bílém červený kalich. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě dvě stříbrná kostelní průčelí s černými okny a dveřmi. V pravém latinský kříž, v levém kalich, obojí červené, mezi věžemi zlatý vinný hrozen."
    },
    {
        obec: "Soběchleby",
        kod: 517844,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.478963,
        longitude: 17.651641,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Sobechleby_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, modrý a žlutý, v poměru 1:2:1. V modrém poli bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře stříbrná lilie, dole tři (1+2) zlaté kotouče (bochníky chleba), vše mezi dvěma stříbrnými vinařskými noži se zlatými rukojeťmi."
    },
    {
        obec: "Soběkury",
        kod: 558346,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.576294,
        longitude: 13.239149,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Sob%C4%9Bkury_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 11 : 2 : 11. Uprostřed pod žlutou korunou žluté jetelovitě zakončené perizonium spojující bílé křídlo v červeném a červené křídlo v modrém poli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zvýšený stříbrný heroldský kříž. V každém horním poli horní polovina zlaté lilie, dole vpravo stříbrné a vlevo červené křídlo. Křídla spojena zlatým jetelovitě zakončeným perizoniem, mezi nimi zlatá koruna."
    },
    {
        obec: "Soběnov",
        kod: 545805,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38241,
        latitude: 48.762808,
        longitude: 14.545827,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Sob%C4%9Bnov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed listu bílý vykračující lev se žlutou zbrojí, podložený z dolního okraje vyrůstající žlutou berlou závitem k žerdi. V hlavici berly a po jejích stranách po jednom žlutém jablku, v prostředním červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrný vykračující lev se zlatou zbrojí podložený vyrůstající zlatou berlou. V hlavici berly a po jejích stranách tři zlatá jablka, v prostředním červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Soběraz",
        kod: 573361,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50713,
        latitude: 50.463465,
        longitude: 15.402094
    },
    {
        obec: "Soběslav",
        kod: 553131,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.260039,
        longitude: 14.718717,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Sobeslav_crest_Czech_Republic.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě modrých a bílých v poměru 1:1:1:4:1:1:1. Uprostřed v bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Soběslavice",
        kod: 564401,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.605,
        longitude: 15.034217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Sob%C4%9Bslavice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed bílá lípa vyrůstající z knížecí čepice dole do oblouku provázené pěti bílými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná lípa vyrůstající z knížecí čepice dole do oblouku provázené pěti stříbrnými hvězdami."
    },
    {
        obec: "Soběsuky",
        kod: 589004,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76802,
        latitude: 49.233688,
        longitude: 17.359592,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Sob%C4%9Bsuky_KR_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým mlýnským kamenem hořícím třemi (2, 1) červenými trojplameny. V kameni kypřice tvořená třemi (2, 1) červenými ostrvemi, každá se dvěma suky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý mlýnský kámen hořící třemi (2, 1) červenými trojplameny. V kameni kypřice tvořená třemi (2, 1) červenými ostrvemi, každá se dvěma suky."
    },
    {
        obec: "Soběšice",
        kod: 557099,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.207905,
        longitude: 13.684205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Sob%C4%9B%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý pilovitý s černou radlicí hrotem k žerdi a ostřím dolů, a modrý zvlněný, v poměru 3 : 6 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zelenou pilovitou hlavou a modrou vlnitou patou doleva položená a dolů obrácená černá radlice."
    },
    {
        obec: "Soběšín",
        kod: 534293,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.791162,
        longitude: 14.955973
    },
    {
        obec: "Soběšovice",
        kod: 568791,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73938,
        latitude: 49.724989,
        longitude: 18.4663,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Sobesovice_znak.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým zvlněným vodorovným pruhem uprostřed o šířce jedné osminy šířky listu. Zvlněný pruh má tři vrcholy a dvě prohlubně. V horním žerďovém rohu bílá letící holubice se žlutou snítkou v zobáku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné zvlněné břevno, přes něj uprostřed tři zlaté svázané klasy, nad nimi letící stříbrná holubice se zlatou snítkou v zobáku."
    },
    {
        obec: "Sobětuchy",
        kod: 572276,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.933341,
        longitude: 15.764066,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Sob%C4%9Btuchy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a vlnkovaný zelený s třemi vrcholy, v poměru 1 : 2. V modrém pruhu tři žluté osmicípé hvězdy vedle sebe, v zeleném bílé kolo se čtyřmi rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zvýšeným zeleným trojvrším tři zlaté osmihroté hvězdy vedle sebe, v trojvrší stříbrné kolo se čtyřmi rameny."
    },
    {
        obec: "Sobíňov",
        kod: 569518,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58262,
        latitude: 49.701095,
        longitude: 15.766977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Coat_of_arms_of_Sob%C3%AD%C5%88ov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a světle modrý. V žerďové části bílého pruhu vykračující černý kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře sníženě děleném štítě nahoře vykračující černý kohout s červenou zbrojí, dole stříbrné vlnité břevno."
    },
    {
        obec: "Sobíšky",
        kod: 517836,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75121,
        latitude: 49.503857,
        longitude: 17.450876,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Sobsiky_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se šikmým žlutým pruhem širokým jednu dvanáctinu délky listu. Uprostřed listu bílo-červeně šachovaná hlava orlice se žlutým zobákem a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně šachovaná hlava orlice se zlatým zobákem a červeným jazykem, šikmo podložená zlatou sukovitou palicí."
    },
    {
        obec: "Sobkovice",
        kod: 547964,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56164,
        latitude: 50.048008,
        longitude: 16.595086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Sobkovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý v poměru 2:1:2. Uprostřed dvě zkřížené vzhůru obrácené hnědé dřevěné lopaty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zkřížené vzhůru obrácené lopaty přirozené barvy nad nimi modrý květ lnu se zlatým semeníkem."
    },
    {
        obec: "Sobotka",
        kod: 573493,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50743,
        latitude: 50.467515,
        longitude: 15.176299,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Sobotka_CoA_CZ.jpg"
    },
    {
        obec: "Sobotín",
        kod: 540986,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78816,
        latitude: 50.011797,
        longitude: 17.090856,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Sobot%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List se dvěma vodorovnými pruhy, červeným a černým, mezi které je vsunut do poloviny délky listu bílý žerďový klín. Ve středu klínu jsou zkřížená hornická kladívka z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít dělen obráceným vidlicovým řezem, v pravém červeném poli černý dvouocasý korunovaný lev se zlatou zbrojí obrácený doleva, v levém černém poli zlatý lev s červenou zbrojí. Ve spodním stříbrném poli zkřížená hornická kladívka, želízko červené na černém topůrku, mlátek obrácených barev."
    },
    {
        obec: "Sobotovice",
        kod: 583880,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66467,
        latitude: 49.059999,
        longitude: 16.557396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Sobotovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s vinným hroznem pod zkříženými vinařskými noži hroty nahoru převýšenými tlapatým křížem mezi čepelemi, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad vinným hroznem zkřížené vinařské nože hroty nahoru převýšené tlapatým křížem, vše zlaté."
    },
    {
        obec: "Sobůlky",
        kod: 586552,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69701,
        latitude: 49.01974,
        longitude: 17.079041,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Sob%C5%AFlky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list s černým svislým středovým pruhem širokým jednu dvacetinu délky listu překrytý zeleným lomeným pruhem vycházejícím z páté šestiny horního a třetí šestiny dolního okraje a s vrcholem mezi druhou a třetí třetinou žerďového okraje. Ve vlající části modrý vinný hrozen na zeleném stonku s listem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Sojovice",
        kod: 536661,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29475,
        latitude: 50.222234,
        longitude: 14.762139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Sojovice_znak.gif",
        coatOfArmsFlagDescription: "Modrý list s bílou jelení hlavou s parohy. Na žerďovém a vlajícím okraji po jednom svislém bílém pruhu, děleném vždy na osm čtverců. V žerďovém pruhu jsou čtverce děleny kosmo tak, že žerďové trojúhelníky jsou střídavě modré, červené a žluté, čtverce ve vlajícím pruhu jsou děleny šikmo tak, že vlající trojúhelníky jsou střídavě žluté, modré a červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná jelení hlava s parohy, mezi nimi stříbrná věž s cimbuřím a černou prolomenou branou. Přes věž dvěma řadami šachované břevno, v horní řadě šachy střídavě šikmo stříbrno-modře, zlatě a červeně dělené, v dolní řadě střídavě šikmo stříbrno-červeně, modře a zlatě dělené."
    },
    {
        obec: "Sokoleč",
        kod: 537811,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.098519,
        longitude: 15.107025,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Sokolec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený a střídavě černé a bílé, v poměru 5:1:1:1:1:1. Na horním černém pruhu stojí rozkřídlený sokol. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě sníženě děleném štítě nahoře rozkřídlený sokol přirozené barvy, dole dvě stříbrná břevna."
    },
    {
        obec: "Sokolnice",
        kod: 583898,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66452,
        latitude: 49.11402,
        longitude: 16.721665,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Sokolnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý s černým sokolem se žlutou zbrojí a červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrném, černě uzděném koni zlatě oděný sokolník držící v pravé ruce stříbrnou skloněnou dýku, v levé ruce na černé rukavici sokola přirozené barvy."
    },
    {
        obec: "Sokolov",
        kod: 560286,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.174629,
        longitude: 12.659992,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Sokolov_COA.svg",
        coatOfArmsFlagDescription: "Prapor tvoří list o stejně širokých pruzích v barvě zelené a bílé na jejichž střed je položen sokol z městského znaku; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Ve stříbrném štítě stojí na zeleném trojvrší vpravo obrácený a napravo hledící sokol přirozených barev se zlatým zobákem, zlatými drápy a zlatým obnožníkem s rolničkou na levém pařátu."
    },
    {
        obec: "Solenice",
        kod: 541320,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26263,
        latitude: 49.617671,
        longitude: 14.195881,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Solenice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, bílý vlnitý se čtyřmi vrcholy a pěti prohlubněmi, modrý a žlutý, v poměru 2:3:2:3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři zúžená stříbrná vlnitá břevna, nad nimi slunce, pod nimi oběžné kolo Kaplanovy turbíny, obojí zlaté."
    },
    {
        obec: "Solnice",
        kod: 576808,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51701,
        latitude: 50.203755,
        longitude: 16.237723,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Solnice.jpg"
    },
    {
        obec: "Sopotnice",
        kod: 580961,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56115,
        latitude: 50.059141,
        longitude: 16.345368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Sopotnice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílými a červenými šindeli sestavenými do žerďového klínu (7,5,3,1) dosahujícími do jedné čtvrtiny délky listu. V modrém poli pod sebou žluté říšské jablko se třemi červenými kameny, šindelářská sekyrka zkřížená se struhákem, obojí žluté na bílých topůrkách, a bílý poříz se žlutými rukojeťmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrno-červeně vlnitě dělenou vlnitou patou a šesti (3,2,1) stříbrnými a červenými šindeli v koutech pod sebou zlaté říšské jablko se třemi červenými kameny, šindelářská sekyrka zkřížená se struhákem, obojí zlaté na stříbrných topůrkách, a stříbrný poříz se zlatými rukojeťmi."
    },
    {
        obec: "Sopřeč",
        kod: 575666,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.093704,
        longitude: 15.556821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Sop%C5%99e%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý vlnkovaný pruh široký jednu šestinu délky listu o čtyřech obloucích k vlající části se třemi zelenými žaludy a modré pole s kosmo položeným bílým kaprem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou oblakovou hlavou se třemi zelenými položenými žaludy kosmo stříbrný kapr."
    },
    {
        obec: "Sosnová",
        kod: 562076,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47001,
        latitude: 50.655151,
        longitude: 14.532396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Sosnov%C3%A1_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové polovině bílého pruhu červená sosnová větev se čtyřmi svazky zelených jehlic a visící červenou šiškou. V žerďové polovině červeného pruhu dvě bílá vykořeněná lekna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít, nahoře červená sosnová větev se čtyřmi svazky zelených jehlic a visící červenou šiškou. Dole dvě vykořeněná stříbrná lekna."
    },
    {
        obec: "Sosnová",
        kod: 597821,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79312,
        latitude: 49.998741,
        longitude: 17.663121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Sosnov%C3%A1_Brunt%C3%A1l_znak.png",
        coatOfArmsFlagDescription: "Kosmo a šikmo bílo-zeleně čtvrcený list, v bílých polích po jedné zelené vztyčené sosnové větévce a v zelených polích po jedné bílé šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená snížená krokev se třemi stříbrnými hvězdami, na ní zelený vydutý klín, v něm vztyčená stříbrná a po stranách dvě zelené sosnové větévky."
    },
    {
        obec: "Souňov",
        kod: 531065,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.880122,
        longitude: 15.315664
    },
    {
        obec: "Sousedovice",
        kod: 551759,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.232155,
        longitude: 13.868628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Sousedovice_-_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, zelený, bílý, zelený a žlutý, v poměru 1:1:4:1:1. V bílém pruhu červený maltézský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným trojvrším s červeným maltézským křížem dvě podané stříbrné ruce vynikající z volných zlatých rukávů."
    },
    {
        obec: "Soutice",
        kod: 599387,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25771,
        latitude: 49.726208,
        longitude: 15.053023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Soutice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé nad stejně velkým bílým polem a dva vodorovné pruhy, bílý a modrý. Z horního okraje karé vychází bílý klín s vrcholem na středu dolního okraje karé. V klínu červený černě okřídlený spár. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný klín, v něm červený černě okřídlený spár."
    },
    {
        obec: "Sovětice",
        kod: 570907,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.307552,
        longitude: 15.705857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/CoA_of_Sov%C4%9Btice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, poměru 5:2:5. V zelených pruzích po jednom žlutém obilném snopu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě postavené stříbrné hrábě mezi dvěma zlatými obilnými snopy."
    },
    {
        obec: "Sovínky",
        kod: 565539,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29429,
        latitude: 50.373175,
        longitude: 14.787182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Sovinky_znak.GIF",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, černý, žlutý, bílý, žlutý a černý, v poměru 6 : 1 : 16 : 1 : 6. V bílém pruhu čelně rozkřídlená černá sova se žlutou zbrojí držící v každém pařátu hnědého sysla. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Sovolusky",
        kod: 573027,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.969347,
        longitude: 15.5373
    },
    {
        obec: "Spálov",
        kod: 599867,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74237,
        latitude: 49.704203,
        longitude: 17.722514,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Spalov_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým pruhem širokým jednu třetinu délky listu. V bílém poli zelený tis s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trojvrší tis přirozených barev."
    },
    {
        obec: "Spálené Poříčí",
        kod: 558362,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33561,
        latitude: 49.613815,
        longitude: 13.605669,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Spalene_Porici_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v žerďové části červeného pruhu bílý rovnoramenný trojúhelník vrcholem dolů, v žerďové části bílého pruhu černý rovnoramenný trojúhelník vrcholem nahoru. Vrcholy jsou položeny v jednu třetinu délky listu, výška trojúhelníku se rovná jedné třetině šířky listu, základna jedné třetině délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Spáňov",
        kod: 554251,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.414624,
        longitude: 12.987415
    },
    {
        obec: "Spělkov",
        kod: 596795,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59203,
        latitude: 49.674861,
        longitude: 16.161048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Sp%C4%9Blkov_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle kosmo dělený list. V žerďovém poli bílý beránek ležící na položené zavřené žluté knize se dvěma propletenými bílými záložkami, ve vlajícím poli černá zubří hlava s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně kosmo děleném štítě nahoře černá zubří hlava s červeným jazykem a zlatou houžví, dole stříbrný beránek ležící na položené zavřené zlaté knize se dvěma propletenými stříbrnými záložkami."
    },
    {
        obec: "Spešov",
        kod: 556963,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.394891,
        longitude: 16.629388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Spe%C5%A1ov-znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý se žlutou šesticípou hvězdou v žerďové polovině a žlutý, v poměru 1 : 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou s pěti (3, 2) zlatými hvězdami tesařská koza převýšená volným ondřejským křížem, obojí modré."
    },
    {
        obec: "Spojil",
        kod: 574198,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.042269,
        longitude: 15.822517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Spojil_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 5:2:5. Uprostřed dva propletené hnědé orobince se zelenými stonky a listy sahající do modrých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným kůlem dva propletené orobince přirozené barvy."
    },
    {
        obec: "Spomyšl",
        kod: 535192,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27705,
        latitude: 50.33553,
        longitude: 14.360631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Spomy%C5%A1l_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 1 : 1 : 3. V modrém pruhu tři bílá pštrosí pera vyrůstající ze žluté koruny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Nahoře červeno-stříbrně děleno, dole v modrém poli tři stříbrná pštrosí pera vyrůstající ze zlaté koruny."
    },
    {
        obec: "Spytihněv",
        kod: 585793,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76364,
        latitude: 49.141251,
        longitude: 17.498191,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Spytihn%C4%9Bv%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 3 : 2. Na třetí, čtvrté a páté sedmině dolního okraje listu tři bílé klíny s vrcholy pod horním okrajem modrého pruhu, ze kterého vyrůstá černá plamenná orlice se žlutým zobákem a červeným jazykem, se žlutým jetelovitě zakončeným perizoniem s lilií na hrudi. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "Ve stříbrno - modře sníženě děleném štítě nahoře vyrůstající černá plamenná orlice se zlatou zbrojí, červeným jazykem, zlatým jetelovitě zakončeným perizoniem s lilií na hrudi. Dole tři stříbrné kužely."
    },
    {
        obec: "Spořice",
        kod: 563340,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43101,
        latitude: 50.44104,
        longitude: 13.391931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Sporice_znak.gif",
        coatOfArmsFlagDescription: "Červený list s bílým kosmým pruhem širokým jednu čtvrtinu délky listu a s modrým lemem širokým jednu osminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě za stříbrnou kruhovou hradbou s černou gotickou branou s otevřenými zlatými vraty stříbrná, červenou valbovou střechou krytá, podélná loď kostela a před ní uprostřed stříbrná věž s černým gotickým oknem a červenou stanovou střechou zakončenou zlatým křížkem. Z okraje paty štítu vede k bráně dřevěný most přirozené barvy."
    },
    {
        obec: "Srbce",
        kod: 590045,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79827,
        latitude: 49.30241,
        longitude: 17.229132,
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký polovinu šířky listu, se třemi bílými klíny s vrcholy na vlajícím okraji pruhu a zelené pole s utrženou bílou kohoutí hlavou se žlutým zobákem a červeným hřebenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi stříbrnými klíny utržená stříbrná kohoutí hlava se zlatým zobákem a červeným hřebínkem."
    },
    {
        obec: "Srbice",
        kod: 554260,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.508409,
        longitude: 13.118703,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Srbice_%28Doma%C5%BElice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a vlnkovitý bílý, v poměru 5 : 3. V horním pruhu kráčející bílý lev se žlutou zbrojí a jazykem, držící v přední levé tlapě žlutou palmovou ratolest, v dolním pruhu tři vztyčené zelené lipové listy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně vlnkovitě děleném štítě nahoře kráčející stříbrný lev se zlatou zbrojí a jazykem, držící v přední levé tlapě zlatou palmovou ratolest, dole tři (2, 1) vztyčené zelené lipové listy."
    },
    {
        obec: "Srbeč",
        kod: 542431,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27065,
        latitude: 50.224713,
        longitude: 13.883458
    },
    {
        obec: "Srbice",
        kod: 567833,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.660759,
        longitude: 13.869399,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. V modrém bílá hornická kladívka, nad a pod nimi žlutý snop. V červeném pruhu dolů obrácená svatojakubská mušle, z níž vyniká latinský kříž, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo zkřížená stříbrná hornická kladívka provázená nahoře a dole zlatým, stříbrně převázaným snopem, vlevo mušle s vyrůstajícím latinským křížem, obojí zlaté."
    },
    {
        obec: "Srbská Kamenice",
        kod: 546348,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40715,
        latitude: 50.820458,
        longitude: 14.352834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Srbsk%C3%A1_Kamenice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé žerďové pruhy, bílý a červený, každý široký šestinu délky listu, a pět vodorovných pruhů, zelený, žlutý pilovitý se třemi celými a dvěma polovičními zuby, modrý vlnitý se dvěma vrcholy a jednou prohlubní, žlutý zvlněný a zelený pilovitý se čtyřmi zuby. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrno - červeně dělenou hlavou zlatý oboustranně pilovitý rozšířený kůl a v něm modrý vlnitý kůl."
    },
    {
        obec: "Srbsko",
        kod: 531758,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26718,
        latitude: 49.937271,
        longitude: 14.136025,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Srbsko_%28Beroun%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý vlnitý a červený zvlněný se třemi vrcholy a dvěma prohlubněmi, v poměru 3:2:3. V zeleném pruhu nad každou prohlubní žlutý dvanáctilistý květ, uprostřed červeného pruhu tři bílé odkloněné lamačské rozštěpovací klíny hroty dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně stříbrným vlnitým břevnem děleném štítě nahoře vedle sebe dva zlaté dvanáctilisté květy, dole tři stříbrné odkloněné lamačské rozštěpovací klíny."
    },
    {
        obec: "Srby",
        kod: 554278,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.536976,
        longitude: 12.868429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Srby_%28Doma%C5%BElice_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 2 : 1 : 3. V horním modrém pruhu tři žluté šesticípé hvězdy vedle sebe, v dolním modrém pruhu bílo-červeně polcená růže mezi dvěma žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volný stříbrný most do krajů o dvou obloucích, nahoře a v obloucích provázený pěti (3, 2) zlatými hvězdami. Dole stříbrno-červeně polcená růže."
    },
    {
        obec: "Srby",
        kod: 540676,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.519054,
        longitude: 13.603397
    },
    {
        obec: "Srch",
        kod: 575682,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.084994,
        longitude: 15.763532,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Srch_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý vlnitý se třemi vrcholy a dvěma prohlubněmi, v poměru 3:1. Nad první prohlubní vzhůru letící sršeň, provázená dole vztyčenými odkloněnými zelenými dubovými listy, každý s jedním odvráceným žaludem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou vlnitou patou sršeň přirozené barvy provázená dole dvěma vztyčenými odkloněnými zelenými dubovými listy, každý s jedním odvráceným žaludem."
    },
    {
        obec: "Srní",
        kod: 557111,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34192,
        latitude: 49.087292,
        longitude: 13.480548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Srn%C3%AD_%28okres_Klatovy%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený se žlutým srnčím parožím a žlutý v poměru 1:6:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad návrším srnčí paroží, obojí zlaté."
    },
    {
        obec: "Srnín",
        kod: 536245,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38101,
        latitude: 48.845115,
        longitude: 14.343803,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Srn%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modro-bíle polcený, v poměru 3:2. V červeném pruhu žlutá srna, v modrém poli žlutá lilie, v bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít. V horním červeném poli zlatá srna, dolní pole modro-stříbrně polcené, vpravo zlatá lilie, vlevo červená růže se zlatý semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Srnojedy",
        kod: 553719,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.039211,
        longitude: 15.702721,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Srnojedy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý, v poměru 1 : 2. V žerďové a střední části listu na bílé lebeční kosti dva žluté srnčí parůžky o třech výsadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě na stříbrné lebeční kosti dva zlaté srnčí parůžky o třech výsadách."
    },
    {
        obec: "Srubec",
        kod: 545066,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37006,
        latitude: 48.948161,
        longitude: 14.541407,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Srubec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý žlutý a zelený, v poměru 19:7:16. Zubatý pruh má čtyři obdélníkové zuby a pět stejných mezer. V zeleném pruhu kosmo mlátek šikmo přeložený želízkem, oboje bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě palisáda uprostřed s roubenou věží s cimbuřím a zavřenou branou, vše zlaté. V patě štítu zelený hrot, v něm kosmý mlátek křížem přeložený želízkem, obojí stříbrné."
    },
    {
        obec: "Sruby",
        kod: 580970,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56544,
        latitude: 49.996975,
        longitude: 16.174354
    },
    {
        obec: "Stádlec",
        kod: 553140,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39162,
        latitude: 49.3797,
        longitude: 14.495169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/St%C3%A1dlec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi, v poměru 5 : 3. V modrém pruhu volný řetězový most se dvěma kvádrovanými mostními věžemi, vše žluté, v bílém pruhu červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně sníženě vlnitě dělený štít. Nahoře volný zlatý řetězový most se dvěma kvádrovanými mostními věžemi, pod ním dvě zúžená stříbrná vlnitá břevna. Dole červená růže se zlatým semeníkem."
    },
    {
        obec: "Stachy",
        kod: 550515,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38473,
        latitude: 49.1019,
        longitude: 13.666687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Stachy_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se vztyčeným korunovaným lvem držícím rychtářské právo, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vztyčený korunovaný lev držící rychtářské právo, vše zlaté."
    },
    {
        obec: "Stáj",
        kod: 587915,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.458085,
        longitude: 15.803502,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/St%C3%A1j_JI_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 11 : 1 : 4. V zeleném pruhu dvě přivrácené bílé čelně hledící koňské hlavy s krkem, v modrém pět šesticípých bílých hvězd vedle sebe cípem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře sníženým zúženým vlnitým břevnem dělený štít. Nahoře dvě čelně hledící koňské hlavy s krky vynikajícími z okrajů štítu, dole pět (3, 2) kosmo položených hvězd, vše stříbrné."
    },
    {
        obec: "Stálky",
        kod: 594792,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.870226,
        longitude: 15.684781,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Coat_of_arms_of_St%C3%A1lky.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený, v poměru 1 : 3 : 1. Ve žlutém pruhu červené hořící srdce, nad ním do oblouku tři červené růže se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červené hořící srdce, nad ním do oblouku tři červené růže se zlatými semeníky."
    },
    {
        obec: "Staňkov",
        kod: 562378,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37806,
        latitude: 48.979627,
        longitude: 14.952798,
        coatOfArmsFlagDescription: "Zelený list s bílým kruhovým polem se třemi modrými vodorovnými vlnitými pruhy, přeloženými vztyčenou žlutou ostrví. V horním rohu kosmo, v horním cípu šikmo vztyčený lipový list, oba žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kotouč se třemi modrými vlnitými břevny přeloženými vztyčenou zlatou ostrví a v koutech provázený vztyčeným odvráceným zlatým lipovým listem."
    },
    {
        obec: "Staňkov",
        kod: 554294,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34561,
        latitude: 49.553835,
        longitude: 13.06931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Sta%C5%88kov_%28okres_Doma%C5%BElice%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé se žlutou svatojakubskou mušlí nad stejně velikým žlutým čtvercovým polem s černým jelením parožím a dva vodorovné pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě ze stříbrné kvádrované hradby vyrůstající sv. Jakub s kloboukem a v poutnickém šatě přirozené barvy, držící v pravici zlatou poutnickou hůl. V hradbě zlatý štítek s trojím (2, 1) černým jelením parožím."
    },
    {
        obec: "Staňkovice",
        kod: 534412,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.875486,
        longitude: 15.015192
    },
    {
        obec: "Staňkovice",
        kod: 546828,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.589503,
        longitude: 14.167132,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Stankovice_LT_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý a maltézský kříž opačných barev vyrůstající ze zeleného trojvrší vycházejícího z poslední pětiny žerďového a vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě vyrůstá ze zeleného trojvrší osmihrotý kříž opačných barev."
    },
    {
        obec: "Staňkovice",
        kod: 543128,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43949,
        latitude: 50.349927,
        longitude: 13.571151,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Sta%C5%88kovice_znak_barevn%C3%BD.jpg",
        coatOfArmsFlagDescription: "Modro-bíle čtvrcený list, vodorovné dělení je provedeno cimbuřovým řezem o pěti zubech a čtyřech mezerách vysokých jednu osminu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě hradba s cimbuřím opačných barev, nad ní zlatá lilie."
    },
    {
        obec: "Stanovice",
        kod: 555550,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36001,
        latitude: 50.165217,
        longitude: 12.874064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Stanovice_KV_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem sahajícím do středu listu, v něm modrá krokev vycházející z druhé a sedmé osminy žerďového okraje s vrcholem ve třech osminách délky listu a modrým žerďovým klínem se základnou na čtvrté a páté osmině žerďového okraje a vrcholem v osmině délky listu. V horní vlající polovině listu tři (1, 2) žluté koruny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Nahoře v červeném poli tři (1, 2) zlaté koruny. Dolní pole pětkrát stříbrno-modře vlnitě děleno."
    },
    {
        obec: "Stanovice",
        kod: 548812,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.400709,
        longitude: 15.871501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Stanovice_%28Trutnov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Uprostřed žlutá stanová střecha na dvou bílých sloupech vynikajících ze čtvrté a sedmé desetiny dolního okraje listu, zakončená bílým tlapatým křížkem a provázená v modrém poli mlýnským kolem, v zeleném poli zkříženými kamenickými dláty ostřím k dolnímu okraji listu, mezi sloupy čelně postavenou radlicí, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě zlatá stanová střecha na dvou vynikajících stříbrných sloupech, zakončená stříbrným tlapatým křížkem a provázená vpravo mlýnským kolem, vlevo zkříženými kamenickými dláty, mezi sloupy čelně postavenou radlicí, vše zlaté."
    },
    {
        obec: "Stanoviště",
        kod: 583901,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.239197,
        longitude: 16.253595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Stanovi%C5%A1t%C4%9B_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými jednu šestnáctinu šířky listu. Uprostřed bílé vyduté kosočtverečné pole s vrcholy na okrajích listu, v něm modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený stříbrný vydutý hrot s modrou lilií a na vrcholu se zlatou korunou, posázenou červenými a zelenými drahokamy a perlami. Ze stran do hrotu kosmo a šikmo vetknuty dva zlaté meče."
    },
    {
        obec: "Stará Červená Voda",
        kod: 541036,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79053,
        latitude: 50.329431,
        longitude: 17.201256,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Coat_of_arms_of_Star%C3%A1_%C4%8Cerven%C3%A1_Voda.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný červený v poměru 3:2. V bílém pruhu tři červené šestilisté květy s bílými semeníky; červený pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně sníženě vlnitě dělený štít, nahoře na jednom zeleném vyrůstajícím stonku tři červené šestilisté květy se stříbrnými semeníky."
    },
    {
        obec: "Stará Huť",
        kod: 541338,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26202,
        latitude: 49.782357,
        longitude: 14.197461,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Star%C3%A1_Hu%C5%A5_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 7 : 1 : 7. V červeném pruhu vztyčené kovářské kleště podložené zkříženými slévačskými lžícemi, v modrém pruhu mlýnské kolo, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře zúženým stříbrným břevnem dělený štít, nahoře vztyčené kovářské kleště podložené zkříženými slévačskými lžícemi, dole mlýnské kolo, vše zlaté."
    },
    {
        obec: "Stará Lysá",
        kod: 537837,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28926,
        latitude: 50.224749,
        longitude: 14.799247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Coats_of_arms_Star%C3%A1_Lys%C3%A1.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, červený a bílý v poměru 1:2:1. V červeném pruhu bílý korunovaný dvouocasý lev se žlutou zbrojí, držící na žluté žerdi s černo-žlutě čtvrcený praporec. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trojvrší stříbrný korunovaný dvouocasý lev se zlatou zbrojí, držící na zlaté žerdi černo-zlatě čtvrcený praporec."
    },
    {
        obec: "Stará Paka",
        kod: 573507,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50791,
        latitude: 50.510424,
        longitude: 15.49455,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Star%C3%A1_Paka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý v poměru 5:1:2. V horním rohu žlutá vykořeněná lípa. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý snížený hrot, v něm zlatá vykořeněná lípa obklopená šesti stříbrnými fasetami."
    },
    {
        obec: "Stará Ves",
        kod: 551911,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79501,
        latitude: 49.958482,
        longitude: 17.236572,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Star%C3%A1_Ves_%28okres_Brunt%C3%A1l%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a zvlněný modrý, v poměru 3:1. Modrý pruh má tři vrcholy a dvě prohlubně. Z prostředního vrcholu vyrůstá žlutý listnatý strom podložený dvěma zkříženými bílými hornickými kladívky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře sníženě vlnitě děleném štítě nahoře vyrůstající zlatý listnatý strom podložený zkříženými stříbrnými hornickými kladívky, dole dvě stříbrné stisknuté ruce s rukávy."
    },
    {
        obec: "Stará Říše",
        kod: 587923,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58867,
        latitude: 49.179652,
        longitude: 15.594868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Stara_Rise_znak.png",
        coatOfArmsFlagDescription: "Žlutý list se vzpřímeným modrým dvouocasým lvem držícím červenou muří nohu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Stará Ves",
        kod: 517887,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75002,
        latitude: 49.378741,
        longitude: 17.488182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/CoA_of_Star%C3%A1_Ves_%28okres_P%C5%99erov%29.svg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a černé pole. V červeném pruhu tři bílé vlající klíny s vrcholy na žerďovém okraji, v černém poli žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě pod červenou hlavou se třemi stříbrnými kužely zlatá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Stará Ves nad Ondřejnicí",
        kod: 598739,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73923,
        latitude: 49.727472,
        longitude: 18.193435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Star%C3%A1_Ves_nad_Ond%C5%99ejnic%C3%AD_CoA.svg",
        coatOfArmsFlagDescription: "Černý list se žlutou růží s červeným semeníkem a zelenými kališními lístky a dvěma žlutými svislými vlnitými pruhy vycházejícími z deváté a jedenácté dvanáctiny horního okraje. Pruhy mají k vlajícímu okraji dva vrcholy a tři prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Stará Voda",
        kod: 539112,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.991631,
        longitude: 12.59591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Stara_Voda_CH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, modrý, žlutý a zelený, v poměru 4:3:2:3:4. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě dvakrát jedlovým řezem polceném štítě modrý zúžený vlnitý kůl."
    },
    {
        obec: "Stará Voda",
        kod: 570915,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50351,
        latitude: 50.150422,
        longitude: 15.530668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Stara-voda-HK_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu žlutý meč hrotem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volná stříbrná kvádrovaná studna s červenou valbovou stříškou na dvou stříbrných sloupcích, přeložených položeným zlatým mečem hrotem doprava."
    },
    {
        obec: "Staré Bříště",
        kod: 561843,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.490839,
        longitude: 15.362636
    },
    {
        obec: "Staré Buky",
        kod: 579661,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54101,
        latitude: 50.532161,
        longitude: 15.859366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Star%C3%A9_Buky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1:1:6. V žerďové polovině horního bílého pruhu zelený listnatý strom s černým vykořeněným kmenem, v koruně žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zvýšeným červeným břevnem zelený vykořeněný buk s černým kmenem, v koruně zlatá lilie."
    },
    {
        obec: "Staré Hamry",
        kod: 598747,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73915,
        latitude: 49.472486,
        longitude: 18.44589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Stare_Hamry_znak.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 3:1:1. V žerďové polovině modrého pruhu kovadlina ve špalku a kosmo vztyčené kladivo, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve sníženě vlnitě děleném štítě nahoře v modrém poli kovadlina ve špalku a kosmo vztyčené kladivo, obojí zlaté, dolní pole stříbrno-červeně vlnitě dělené."
    },
    {
        obec: "Staré Heřminovy",
        kod: 551813,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79312,
        latitude: 49.933251,
        longitude: 17.625765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Star%C3%A9_He%C5%99minovy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu na kosmé žluté radlici ostřím dolů a hrotem k dolnímu cípu bílá rozkřídlená labuť. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na kosmé zlaté radlici stříbrná rozkřídlená labuť."
    },
    {
        obec: "Staré Hobzí",
        kod: 547204,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37871,
        latitude: 49.010499,
        longitude: 15.453308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Star%C3%A9_Hobz%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 7 : 1. Z prostřední třetiny horního okraje zeleného pruhu vyniká zelený vrch, z něhož vyrůstá zelený růžový keř s trny a třemi (1, 2) bílými květy se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném vrchu zelený růžový keř s trny a třemi (1, 2) stříbrnými květy se zlatými semeníky."
    },
    {
        obec: "Staré Hodějovice",
        kod: 545074,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37008,
        latitude: 48.945486,
        longitude: 14.521073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Stare_Hodejovice_CZ_CoA.svg",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře červená růže se zlatým semeníkem, dole stříbrný doleva obrácený půlměsíc."
    },
    {
        obec: "Staré Hradiště",
        kod: 575704,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53352,
        latitude: 50.065498,
        longitude: 15.778951,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/CoA_of_Star%C3%A9_Hradi%C5%A1t%C4%9B.svg",
        coatOfArmsFlagDescription: "Černý list s kosmým černým pruhem vycházejícím z poloviny šířky a délky listu. V bílém pruhu kosmý modrý vlnitý pruh se čtyřmi vrcholy a čtyřmi prohlubněmi široký jednu třetinu šířky bílého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě kosmé, modré, vlnité břevno, provázené nahoře roubenou tvrzí a palisádovou hradbou, dole třemi volnými kůly, obojí černé."
    },
    {
        obec: "Staré Hrady",
        kod: 530735,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.386111,
        longitude: 15.213064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Star%C3%A9_Hrady%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zubatý bílo-červeně dělený pruh se čtyřmi obdélníkovými zuby a třemi stejnými mezerami, široký čtvrtinu délky listu, a dva vodorovné pruhy, červený a modrý. Ve střední a vlající části listu polovina bílého koně ve skoku se žlutými kopyty, jazykem a uzděním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě nad stříbrno-červeně polcenou hradbou s přivrácenými podkovami opačných barev polovina stříbrného koně se zlatými kopyty, jazykem a uzděním."
    },
    {
        obec: "Staré Hutě",
        kod: 592587,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68601,
        latitude: 49.129212,
        longitude: 17.277842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Znak-starehute.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:2:1. V modrém pruhu bílý pohár s víkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný pohár s víkem, provázený dvěma zlatými dubovými listy."
    },
    {
        obec: "Staré Jesenčany",
        kod: 575712,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.000581,
        longitude: 15.754182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Star%C3%A9_Jesen%C4%8Dany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý klín s vrcholem sahajícím do dvou třetin délky listu a pět vodorovných pruhů, střídavě zelených a žlutých, v poměru 1 : 1 : 6 : 1 : 1. V klínu vodorovně zelený jasanový list řapíkem k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zvýšený vydutý stříbrný hrot se vztyčeným zeleným jasanovým listem, provázený vpravo houžví a vlevo zvonem, obojí zlaté."
    },
    {
        obec: "Staré Křečany",
        kod: 562823,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40761,
        latitude: 50.950546,
        longitude: 14.496283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Star%C3%A9_K%C5%99e%C4%8Dany_CoA_CZ.png",
        coatOfArmsFlagDescription: "Zelený list s bílým karé nad stejně velkým červeným polem. V karé červená růže se žlutým semeníkem, v červeném poli bílá růže se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě zvýšené zelené návrší, nad ním dvě růže opačných barev se zlatými semeníky. V návrší zlatá bečka vrchovatě naplněná stříbrnou solí."
    },
    {
        obec: "Staré Město",
        kod: 551767,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 50.003657,
        longitude: 17.432173,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Stare_Mesto_%28Bruntal%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým pruhem na dolním okraji listu, se třemi obdélníkovými zuby a stejně velikými čtyřmi mezerami. Zuby dosahují do poloviny šířky listu a na žerďovém sedí žlutý pták obrácený k vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná patrová věž s prázdnou branou a jedním oknem, zakončená červenou bání se zlatou makovicí a doprava vlajícím praporkem. Věž je kvádrovou hradbou s cimbuřím spojena vpravo se stříbrnou polorozbořenou úzkou věží s oknem, na níž sedí doleva obrácený zlatý pták, a vlevo se stříbrnou úzkou věží s oknem, zakončenou červenou bání se zlatou makovicí a praporkem."
    },
    {
        obec: "Staré Město",
        kod: 552551,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73801,
        latitude: 49.670019,
        longitude: 18.363604,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Stare_Mesto_%28Frydek-Mistek%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý, v poměru 3:1, oddělené pilovitým řezem se čtyřmi žlutými zuby zasahujícími do druhé třetiny délky listu. V horním žerďovém rohu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V dolní části modrého štítu stříbrné zúžené břevno, v němž stojí zlatá palisáda s prázdnou branou a valbovou střechou se dvěma makovicemi, provázaná nahoře stříbrnými liliemi."
    },
    {
        obec: "Staré Město",
        kod: 541079,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78832,
        latitude: 50.161832,
        longitude: 16.947446,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Star%C3%A9_M%C4%9Bsto_pod_Sn%C4%9B%C5%BEn%C3%ADkem_CoA.png",
        coatOfArmsFlagDescription: "Modrý list s hnědým vzpřímeným medvědem k vlajícímu okraji listu, proti němu horník v černém kabátci s flekem, s černou hornickou čapkou a botami a se žlutými kalhotami, bránící se bílým želízkem na hnědém topůrku ve zdvižené pravici. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Staré Město",
        kod: 578789,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56932,
        latitude: 49.794091,
        longitude: 16.675033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Star%C3%A9_M%C4%9Bsto_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 7 : 3. V červeném pruhu bílá silueta kostelní věže s prázdnou gotickou bránou, v zeleném pruhu bílá dubová větev se dvěma odvrácenými listy a třemi (1, 2) žaludy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ze zelené paty vyrůstající stříbrná kostelní věž. V patě stříbrná dubová větev se dvěma odvrácenými listy a třemi (1, 2) žaludy."
    },
    {
        obec: "Staré Město",
        kod: 550752,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68603,
        latitude: 49.075248,
        longitude: 17.433488,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Star%C3%A9_M%C4%9Bsto_%28Uhersk%C3%A9_Hradi%C5%A1t%C4%9B_District%29_CoA_2021.png",
        coatOfArmsFlagDescription: "Modrý list, z jehož dolního okraje vyniká žlutá palisáda tvořená dvaceti třemi kůly vysokými tři osminy šířky listu, devíti prostředními nižšími. Z prostředních sedmi kůlů vyniká bílá rotunda s červenou střechou zakončenou žlutým patriarším křížkem a s čelně umístěnou apsidou s černým obloukovým oknem a červenou střechou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyniká ze zlaté, po stranách zvýšené palisády stříbrná rotunda s červenou střechou zakončenou zlatým patriarším křížkem a s čelně umístěnou apsidou s černým obloukovým oknem a červenou střechou."
    },
    {
        obec: "Staré Město pod Landštejnem",
        kod: 547212,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37882,
        latitude: 49.00345,
        longitude: 15.254229,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Stare-mesto-pod-lanstejnem-znak.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený na žerďovém okraji listu v poměru 1 : 1, na vlajícím okraji v poměru 3 : 1 na červené a černé pole. Ve vlající polovině červeného pole kosmo vztyčená bílá větévka se třemi listy a dvěma kořínky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V horním poli červeno-černě kosmo sníženě děleného štítu kosmo vztyčená stříbrná větévka se třemi listy a dvěma kořínky."
    },
    {
        obec: "Staré Místo",
        kod: 549096,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.40511,
        longitude: 15.342338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Coat_of_arms_of_Star%C3%A9_M%C3%ADsto.svg",
        coatOfArmsFlagDescription: "List tvoří žluto-černě dělený žerďový zubatý pruh široký jednu čtvrtinu délky listu, se třemi čtvercovými a dvěma krajními polovičními zuby, a dva vodorovné pruhy opačných barev, horní s bílou lilií a žlutým mezikružím vedle sebe, dolní s černou korunou a černým mezikružím vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě pod cimbuřovou hlavou opačných barev vpravo vyrůstající stříbrná liliová hůlka provlečená zlatým kruhem, vlevo pod sebou koruna a kruh, obojí černé."
    },
    {
        obec: "Staré Sedliště",
        kod: 561185,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34801,
        latitude: 49.744446,
        longitude: 12.694252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Star%C3%A9_Sedli%C5%A1t%C4%9B_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list se třemi přes sebe položenými modrými rybami, z nichž spodní je položena kosmo hlavou k hornímu rohu, prostřední šikmo hlavou k hornímu cípu a vrchní vodorovně hlavou k vlajícímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Staré Sedlo",
        kod: 560642,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.181807,
        longitude: 12.719941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Stare_Sedlo_SO_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Uprostřed vzpínající se bílý kůň se žlutou zbrojí, sedlem a uzděním, provázený za hlavou třemi (1,2) dotýkajícími se žlutými šesticípými hvězdami a pod břichem bílými hornickými kladívky se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě stříbrný vzpínající se kůň se zlatou zbrojí, sedlem a uzděním, provázený vlevo nahoře třemi (1,2) dotýkajícími se zlatými hvězdami a vpravo dole stříbrnými hornickými kladívky se zlatými topůrky."
    },
    {
        obec: "Staré Sedlo",
        kod: 561193,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34802,
        latitude: 49.666003,
        longitude: 12.863729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Star%C3%A9_Sedlo_%28okres_Tachov%29_znak.jpg",
        coatOfArmsFlagDescription: "Červeno - černě šikmo dělený list. V žerďovém poli bílá labuť se žlutou zbrojí, ve vlajícím poli bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - černě šikmo děleném štítě nahoře labuť se zlatou zbrojí, dole lilie, obojí stříbrné."
    },
    {
        obec: "Staré Smrkovice",
        kod: 573523,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.336688,
        longitude: 15.494647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Star%C3%A9_Smrkovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý smrkový pruh široký třetinu délky listu, se dvěma celými a dvěma polovičními smrky, a dva vodorovné pruhy, červený a zelený. Ve střední a vlající části bílý mlýnský kámen s položenou černou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít se stříbrnou hlavou oddělenou smrkovým řezem o třech vrcholech. Ve štítě stříbrný mlýnský kámen s položenou černou kypřicí."
    },
    {
        obec: "Staré Těchanovice",
        kod: 568198,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74901,
        latitude: 49.81838,
        longitude: 17.692077,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Star%C3%A9_T%C4%9Bchanovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a bílý, v poměru 2:1:1. V žerďové části modrého pruhu žlutá včela. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném trávníku zlatý včelí úl převýšený zlatou včelou a provázený dvěma vyrůstajícími stříbrnými zvonky na zlatých stoncích."
    },
    {
        obec: "Staré Ždánice",
        kod: 575721,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53344,
        latitude: 50.124004,
        longitude: 15.720737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Znak_St._%C5%BDd%C3%A1nice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3:1. V žerďové polovině na zeleném pruhu černý kohout s červenou zbrojí, za ním zelený listnatý strom s hnědým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku černý kohout s červenou zbrojí, za ním listnatý strom přirozené barvy."
    },
    {
        obec: "Starkoč",
        kod: 530875,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.937353,
        longitude: 15.507667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Starko%C4%8D_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed tři bílé přirozené lilie na stoncích, krajní odkloněné, dole přeložené položenou zlatou spojenou střelou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě tři stříbrné přirozené lilie na stoncích, krajní odkloněné, dole přeložené položenou zlatou spojenou střelou."
    },
    {
        obec: "Stárkov",
        kod: 574465,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54936,
        latitude: 50.534022,
        longitude: 16.158279,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Znak_starkova.jpg"
    },
    {
        obec: "Starosedlský Hrádek",
        kod: 564583,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.57629,
        longitude: 14.008329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Starosedlsk%C3%BD_Hr%C3%A1dek_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý zubatý se šesti čtvercovými zuby, pěti stejnými a dvěma polovičními mezerami, modrý a bílý, v poměru 5 : 1 : 1 : 1. V červeném pruhu tři (1, 2) dotýkající se žluté šesticípé hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně cimbuřově dělený štít. Nahoře tři (1, 2) dotýkající se zlaté hvězdy, dole modré vlnité břevno."
    },
    {
        obec: "Starovice",
        kod: 584894,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.951684,
        longitude: 16.706715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Coat_of_arms_of_Starovice.jpeg",
        coatOfArmsFlagDescription: "List kosmo dělený červeným koštýřem, s ouškem k žerďovému okraji, na zelené žerďové a bílé vlající pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně šikmo děleném štítě panter opačných barev s červenou zbrojí a ušima, šikmo přeložený červeným koštýřem."
    },
    {
        obec: "Starovičky",
        kod: 584908,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.907566,
        longitude: 16.775476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Coat_of_arms_of_Starovicky.jpeg",
        coatOfArmsFlagDescription: "Žlutý list s modrým vinným hroznem se zeleným listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý vinný hrozen se zeleným listem, provázený vpravo vinařským nožem, vlevo srpem, obojí modré s hnědými rukojeťmi, vztyčené a přivrácené."
    },
    {
        obec: "Starý Hrozenkov",
        kod: 592609,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68774,
        latitude: 48.965972,
        longitude: 17.86312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Stary_Hrozenkov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - zelený, bílý a modrý v poměru 1:2:1. Na prostředním bílém pruhu je položen na zeleném stonku s listem modrý vinný hrozen z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném stonku s listem modrý hrozen."
    },
    {
        obec: "Starý Jičín",
        kod: 599905,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74231,
        latitude: 49.576961,
        longitude: 17.961801,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Stary_jicin_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý s červeným korunovaným kozlem vztyčeným k vlajícímu okraji, a červený se žlutým snopem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Starý Kolín",
        kod: 533700,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28123,
        latitude: 50.009872,
        longitude: 15.293985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Coat_of_arms_of_Star%C3%BD_Kol%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zvlněný modrý se čtyřmi vrcholy a pěti prohlubněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře vlnitě děleném štítě zkřížené dřevěné dole přitesané kůly přirozené barvy."
    },
    {
        obec: "Starý Bydžov",
        kod: 573132,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50357,
        latitude: 50.25854,
        longitude: 15.452434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Star%C3%BD_Byd%C5%BEov_CoA.png",
        coatOfArmsFlagDescription: "List šikmo červeno-bíle dělený. V červeném poli šikmo žlutá berla s bílým sudariem, závitem k vlajícímu okraji listu, v bílém poli šikmo zelený jasanový list s pěti lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně šikmo dělený štít. Nahoře šikmo, doleva obrácená zlatá berla se stříbrným sudariem, dole šikmo zelený jasanový list s pěti lístky."
    },
    {
        obec: "Starý Mateřov",
        kod: 575739,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 50.001737,
        longitude: 15.71522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Star%C3%BD_Mate%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list; uprostřed stojící bílý chrt se žlutou zbrojí a obojkem, provázený dvěma lipovými větévkami, u žerďového okraje kosmou, u vlajícího okraje šikmou, každá se třemi listy a plodenstvím, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se zeleným trojvrším stojící stříbrný chrt se zlatou zbrojí a obojkem, pod ním položená zlatá lipová větévka s třemi listy a plodenstvím."
    },
    {
        obec: "Starý Petřín",
        kod: 594806,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.890137,
        longitude: 15.734188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Star%C3%BD_Pet%C5%99%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a bílý, v poměru 1 : 2 : 1. V modrém pruhu kosmo bílá sekera se žlutým topůrkem ostřím k žerďovému okraji, podložená šikmo žlutou ostrví. Poměr šířky délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře modro - stříbrně polcený, vpravo kosmá stříbrná sekera se zlatým topůrkem šikmo podložená zlatou ostrví, vlevo černé křídlo se zlatým, jetelovitě zakončeným perizoniem, v dolním červeném poli tři (2, 1) stříbrné růže."
    },
    {
        obec: "Starý Plzenec",
        kod: 558371,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33202,
        latitude: 49.697786,
        longitude: 13.47361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Staryplzenec_coa.gif",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část u středu listu s bílým dvouocasým lvem ve skoku se žlutou zbrojí a bílá vlající část s polovinou černé orlice s červenou zbrojí, vyrůstající z žerďové části. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Starý Poddvorov",
        kod: 586561,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69616,
        latitude: 48.878704,
        longitude: 16.981633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Star%C3%BD_Poddvorov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý a zelený, v poměru 1 : 1 : 10. V zeleném pruhu žlutý vinný hrozen se stonkem a dvěma listy, provázený dvěma přivrácenými bílými vinařskými noži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod černo-stříbrně dělenou hlavou zlatý vinný hrozen se stonkem a dvěma listy, provázený dvěma přivrácenými stříbrnými vinařskými noži."
    },
    {
        obec: "Starý Šachov",
        kod: 545538,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.715168,
        longitude: 14.360479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Star%C3%BD_%C5%A0achov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. Uprostřed tři šachové figury, král provázený přivrácenými koňmi, pod nimi dva vodorovné vlnité pruhy s pěti vrcholy a šesti prohlubněmi, vycházející z třinácté a patnácté šestnáctiny žerďového okraje listu. Vše opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě tři šachové figury, král provázený přivrácenými koňmi, nad třikrát vlnitě dělenou vlnitou patou, vše opačných barev."
    },
    {
        obec: "Starý Vestec",
        kod: 534889,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28916,
        latitude: 50.144821,
        longitude: 14.846456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Star%C3%BD_Vestec_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový vydutý klín s vrcholem ve středu listu a čtyři vodorovné pruhy, modrý, žlutý, červený a modrý, v poměru 5 : 3 : 3 : 5. V klínu červená růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný zkrácený vydutý klín s červenou růží. Pod ním knížecí čepice, ze které do stran vyrůstá po dvou zlatých obilných klasech, každý s odkloněným listem."
    },
    {
        obec: "Stařeč",
        kod: 591742,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67522,
        latitude: 49.19793,
        longitude: 15.828017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/CoA_of_Sta%C5%99e%C4%8D.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý. Ve žlutém pruhu modrý dvouocasý lev se žlutou zbrojí a červeným jazykem obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Stařechovice",
        kod: 590053,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79841,
        latitude: 49.532985,
        longitude: 17.053825
    },
    {
        obec: "Staříč",
        kod: 552569,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73943,
        latitude: 49.686041,
        longitude: 18.272916,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Sta%C5%99%C3%AD%C4%8D_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy; horní červený se čtyřmi bílými trojúhelníkovými poli, dolní svisle rozdělený na zelené žerďové a černé vlající pole. Základna trojúhelníkových polí je rovna jedné čtvrtině délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleně a černě polceném štítu zlatý květ lnu žlutého; v červené hlavě štítu čtyři stříbrné špice."
    },
    {
        obec: "Stašov",
        kod: 578801,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.674979,
        longitude: 16.369023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Sta%C5%A1ov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed žlutá kvádrovaná okrouhlá studna s tryskajícími bílými prameny, převýšená žlutým rohem hrotem k žerdi a provázená dvěma bílými liliemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá kvádrovaná okrouhlá studna s tryskajícími stříbrnými prameny, převýšená položeným zlatým rohem a provázená dvěma stříbrnými liliemi."
    },
    {
        obec: "Stašov",
        kod: 531766,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.883446,
        longitude: 13.95763,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Stasov_BE_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh, široký jednu čtvrtinu délky listu, a zelené pole se třemi bílými klíny, šikmým, vlajícím a kosmým, s vrcholy uprostřed vlajícího okraje žlutého pruhu. Šikmý klín vychází z osmé až dvanácté dvanáctiny horního okraje listu, vlající klín vychází ze čtvrté a páté osminy vlajícího okraje listu a kosmý klín vychází z osmé až dvanácté dvanáctiny dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší zelený dub se zlatými žaludy a černým kmenem, pod ním zlatá koruna se třemi zapíchnutými stříbrnými cvoky."
    },
    {
        obec: "Statenice",
        kod: 539686,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25262,
        latitude: 50.142677,
        longitude: 14.318642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Statenice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, vlnitý modrý a zvlněný bílý, v poměru 3:1:4. V horním bílém pruhu červený středový kříž s rameny širokými jednu osminu šířky listu. Modrý pruh má tři vrcholy a dvě prohlubně. V dolním bílém pruhu černá, čelně hledící volská hlava. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modré vlnité břevno provázené nahoře červeným heroldským křížem a dole černou, čelně hledící volskou hlavou."
    },
    {
        obec: "Stavenice",
        kod: 570281,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78973,
        latitude: 49.786942,
        longitude: 16.973733,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Stavenice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý, v poměru 7 : 1 : 1 : 1. V žerďové polovině červeného pruhu přivrácené krojidlo a radlice hroty k hornímu okraji listu, obojí bílé. Mezi nimi žlutá osmicípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou pilovitou patou s modrým vlnitým břevnem přivrácené krojidlo a radlice, obojí stříbrné, vztyčené. Mezi nimi nahoře zlatá osmihrotá hvězda."
    },
    {
        obec: "Stavěšice",
        kod: 586579,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69638,
        latitude: 49.001739,
        longitude: 17.030565,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Stavesice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s černým vodorovným středovým pruhem o šířce jedné desetiny šířky listu, procházejícím na žerďovém a vlajícím okraji ve střední části pod lomeným žlutým pruhem o šířce jedné třetiny šířky listu. Žlutý pruh vychází ze středu žerďového do středu vlajícího okraje a má vrchol na dolním okraji listu v jedné čtvrtině délky listu a druhý vrchol na horním okraji ve třech čtvrtinách délky listu. V horním žerďovém rohu kosmo postavená žlutá putna s bílou vytékající vodou dosahující na černý pruh. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá vinná réva se třemi listy a jedním hroznem, ovinuta kolem černého kolíku, provázená vlevo kosmo postavenou zlatou putnou se stříbrnou vytékající vodou."
    },
    {
        obec: "Stéblová",
        kod: 572934,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53345,
        latitude: 50.092124,
        longitude: 15.746276,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/St%C3%A9blov%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený, a na dolním okraji listu vodorovný modrý pruh široký osminu šířky listu. Z něj do bílého pruhu vyrůstá z trsu zelených kopinatých listů orobinec s hnědou palicí a dvěma odkloněnými zelenými listy, do červeného pruhu vyrůstá žlutý obilný klas se dvěma odkloněnými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou vlnitou patou, z níž vyrůstají vpravo z trsu zelených kopinatých listů orobinec s palicí a dvěma odkloněnými listy, vše přirozené barvy, vlevo zlatý obilný klas se dvěma odkloněnými listy."
    },
    {
        obec: "Stebno",
        kod: 546925,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.615203,
        longitude: 14.01732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/CoA_of_Stebno.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý dole pilovitý a zelený, v poměru 5 : 1 : 2. Uprostřed listu bílá halapartna se žlutým ratištěm provázená v horním zeleném pruhu čtyřmi (2, 2) žlutými lipovými listy, horními vztyčenými a dolními svěšenými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná halapartna se zlatým ratištěm nahoře provázená čtyřmi (2, 2) zlatými lipovými listy, horními vztyčenými a dolními svěšenými. Ratiště podloženo stříbrným listem dřevařské pily vynikajícím z okrajů štítu."
    },
    {
        obec: "Stěbořice",
        kod: 510343,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74751,
        latitude: 49.937181,
        longitude: 17.805574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/St%C4%9Bbo%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým beránkem přidržujícím pravou přední nohou žlutý kříž s bílou dvoucípou korouhví s červeným křížem. Ve vlající části pod sebou tři žluté růže s červenými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad třemi zlatými růžemi s červenými semeníky vedle sebe stříbrný beránek přidržující pravou přední nohou zlatý kříž se stříbrnou dvoucípou korouhví s červeným heroldským křížem."
    },
    {
        obec: "Stehelčeves",
        kod: 532851,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27342,
        latitude: 50.171748,
        longitude: 14.190956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Stehelceves_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové polovině bílého pruhu letící stehlík, v žerďové polovině červeného pruhu žluté zkřížené býčí rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně děleném štítě nahoře letící stehlík přirozené barvy, dole zkřížené zlaté býčí rohy."
    },
    {
        obec: "Stehlovice",
        kod: 529885,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.393498,
        longitude: 14.322986
    },
    {
        obec: "Stěžery",
        kod: 570931,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50321,
        latitude: 50.215821,
        longitude: 15.748412,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/CoA_of_St%C4%9B%C5%BEery.svg",
        coatOfArmsFlagDescription: "Žlutý list s červeným kruhovým polem se třemi (2, 1) vyrůstajícími zelenými lipovými listy. V poli bílá rozevřená kniha. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě červený kotouč se třemi (2, 1) vyrůstajícími zelenými lipovými listy. V kotouči stříbrná rozevřená kniha."
    },
    {
        obec: "Stínava",
        kod: 590061,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.495308,
        longitude: 16.934611
    },
    {
        obec: "Stod",
        kod: 558389,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.639199,
        longitude: 13.164846,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Stod_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se svislým bílým vlajícím pruhem širokým jednu třetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý štítek s trojím (2,1) černým parožím. Na štítu ze stříbrných přikrývadel vyrůstá postava Spravedlnosti se zlatými vlasy držící v pravici stříbrný meč se zlatým jílcem v levici zlaté miskové váhy. Pod štítkem stříbrný dvouocasý korunovaný lev se zlatou zbrojí a červeným jazykem ležícím na stříbrné trojité římse."
    },
    {
        obec: "Stochov",
        kod: 532860,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27303,
        latitude: 50.146411,
        longitude: 13.96356,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Stochov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a černý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá plamenná orlice s červenou zbrojí a stříbrnými hornickými kladívky na hrudi. Pod ní zelený žalud mezi dvěma dubovými zelenými listy."
    },
    {
        obec: "Stojčín",
        kod: 561452,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39468,
        latitude: 49.237781,
        longitude: 15.222001
    },
    {
        obec: "Stojice",
        kod: 575755,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.956461,
        longitude: 15.614128,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/CoA_of_Stojice.svg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený, vlnitý zelený, bílý, modrý, bílý a zvlněný zelený, v poměru 9:3:1:1:1:3. Pruhy mají tři vrcholy a dvě prohlubně. Uprostřed červeného pruhu žlutá dvanácticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně vlnitě děleném štítě nahoře zlatá dvanáctihrotá hvězda, dole vlnité stříbrno-modro-stříbrně vlnitě dělené břevno."
    },
    {
        obec: "Stolany",
        kod: 547891,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.9279,
        longitude: 15.745214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Stolany_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s červeným žerďovým pruhem širokým čtvrtinu délky listu a s bílým vlajícím klínem vycházejícím z druhé až čtvrté pětiny vlajícího okraje s vrcholem na vlajícím okraji červeného pruhu. V červeném pruhu tři žlutá jablka pod sebou, každé se stopkou a dvěma odkloněnými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - modře vpravo polceném štítě v pravém poli tři zlatá jablka pod sebou, každé se stopkou a dvěma odkloněnými listy. Vlevo na zeleném trávníku stříbrná věž s cimbuřím a stanovou střechou se zlatou makovicí s křížkem, gotickou branou a obloukovým oknem, obojí černé."
    },
    {
        obec: "Stonařov",
        kod: 587931,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.282212,
        longitude: 15.585729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Stona%C5%99ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a zelený. V horním rohu žlutá knížecí koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném trávníku stojící sv. Václav ve zbroji s knížecí korunou na hlavě, v pravé ruce drží na zlaté žerdi stříbrný praporec - vlaštovčí ocas s černou orlicí v žerďové části. Postava je provázena stříbrnými majuskulními písmeny S W, pod písmenem W stříbrný ježek."
    },
    {
        obec: "Stonava",
        kod: 599140,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73534,
        latitude: 49.817011,
        longitude: 18.525286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Stonawa_herb.gif",
        coatOfArmsFlagDescription: "Zelený list s figurou ze znaku obce - zelený list s bílým gryfem se žlutou korunou a zbrojí, držící žlutý štítek s černým majuskulním 'S'. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný korunovaný gryf se zlatou zbrojí držící zlatý štítek s černým majuskulním 'S'."
    },
    {
        obec: "Stošíkovice na Louce",
        kod: 594814,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.896689,
        longitude: 16.214975,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/CoA_of_Sto%C5%A1%C3%ADkovice_na_Louce.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém pruhu polovina modré zavinuté střely přiléhající k polovině bílé růže se žlutým semeníkem a kališními lístky v zeleném pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo přiléhá k dělící čáře půl modré zavinuté střely, vlevo půl stříbrné růže se zlatým semeníkem a kališními lístky."
    },
    {
        obec: "Stožec",
        kod: 550523,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38444,
        latitude: 48.859471,
        longitude: 13.821516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Sto%C5%BEec_CoA.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem sahajícím do páté dvanáctiny délky listu a červeno-modře dělenou vidlicí. Ke klínu přiléhající ramena vycházejí z první čtvrtiny horního a dolního okraje a ze dvou prostředních osmin vlajícího okraje listu. V klínu žlutá lilie, v bílých polích červená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělen modro-červeně polcenou vidlicí. V horním zeleném poli zlatá lilie, v dolních stříbrných polích červená růže se zlatým semeníkem."
    },
    {
        obec: "Stožice",
        kod: 530034,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.132595,
        longitude: 14.150308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Sto%C5%BEice_CoA.png",
        coatOfArmsFlagDescription: "Zeleno-červeně kosmo dělený list. V zeleném poli kosmo žlutý obilný klas s listy, v červeném žlutý býk ve skoku s černou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně kosmo dělený štít. Nahoře zlatý býk ve skoku s černou zbrojí, dole kosmo zlatý obilný klas s listy."
    },
    {
        obec: "Stračov",
        kod: 570958,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50314,
        latitude: 50.301628,
        longitude: 15.64079,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/CoA_of_Stra%C4%8Dov.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed obrácená mušle ve třmenu, provázeném nahoře dvěma kruhovými poli, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě obrácená mušle ve třmenu, provázeném nahoře dvěma kotouči, vše zlaté."
    },
    {
        obec: "Stradonice",
        kod: 599441,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27371,
        latitude: 50.291461,
        longitude: 14.047036
    },
    {
        obec: "Stradouň",
        kod: 572306,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53863,
        latitude: 49.97246,
        longitude: 16.065657
    },
    {
        obec: "Strahovice",
        kod: 512869,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74724,
        latitude: 50.002181,
        longitude: 18.087213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Znak_obce_Strahovice.svg",
        coatOfArmsFlagDescription: "Modro-žlutý kosmo dělený list. Na středu kosmo dělené žluto-modré kruhové pole z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě kosmo dělený štít, v horním poli půl zlatého hodinového ciferníku s modrými rafiemi a římskými číslicemi, v dolním poli půl modrého květu lnu se zlatým semeníkem. Obé vycházející z dělícího řezu."
    },
    {
        obec: "Strachoňovice",
        kod: 587940,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.135205,
        longitude: 15.488129
    },
    {
        obec: "Strachotice",
        kod: 594822,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67129,
        latitude: 48.796236,
        longitude: 16.1724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Strachotice_CoA_v2.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 5 : 4. Do bílého pruhu vyrůstá černá korunovaná orlice se žlutou zbrojí. V červeném pruhu žluté majuskulní písmeno 'W'. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Strachotín",
        kod: 584916,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69301,
        latitude: 48.905517,
        longitude: 16.651453,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Znak_obce_Strachot%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislá pole v poměru 1:2:1, bílé, červené se žlutým žaludem ze znaku obce a bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zlaté větévce velký vztyčený zlatý žalud, provázený stříbrným kosířem a krojidlem."
    },
    {
        obec: "Strachujov",
        kod: 596809,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59242,
        latitude: 49.613204,
        longitude: 16.226861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Strachujov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý kanton a sedm vodorovných pruhů, pět střídavě černých a bílých, zelený a zvlněný žlutý, v poměru 1 : 1 : 1 : 1 : 1 : 4 : 1. V kantonu černá zubří hlava se žlutou houžví. V zeleném pruhu tři bílé pětilisté květy (1, 2). Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "Dělený štít se zlatou vlnitou patou. Nahoře zlato - černě polcený, vpravo černá zubří hlava se zlatou houžví, vlevo dvě stříbrná břevna. V dolním zeleném poli tři (1, 2) stříbrné pětilisté květy."
    },
    {
        obec: "Strakonice",
        kod: 550787,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.260504,
        longitude: 13.910408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Coat_of_arms_of_Strakonice.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří pokosem dělený list s horním červeným polem s bílým maltézským křížem a dolním žlutým polem s kosmo položenou střelou z městského znaku; poměr délky k šířce je 3:2."
    },
    {
        obec: "Strakov",
        kod: 578819,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.866605,
        longitude: 16.366914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Strakov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílo-červeně šikmo dělený list. V žerďovém bílém poli šikmý zelený pruh, v červeném vlajícím poli žlutý šikmý pruh, oba široké jednu desetinu šířky listu a vycházející ze středu všech okrajů listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně šikmo děleném štítě nahoře šikmo vztyčený zelený lipový list, dole šest (1,2,3) zlatých lilií."
    },
    {
        obec: "Straky",
        kod: 537853,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28925,
        latitude: 50.23368,
        longitude: 14.959345
    },
    {
        obec: "Strančice",
        kod: 538809,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25163,
        latitude: 49.948217,
        longitude: 14.677553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Strancice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři polcené vodorovné pruhy, zeleno-bílý, žluto-zelený a zeleno-bílý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatá lilie kvetoucí do rohů dvěma stříbrnými liliemi na stoncích."
    },
    {
        obec: "Stránecká Zhoř",
        kod: 596817,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59442,
        latitude: 49.380122,
        longitude: 15.927483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Stranecka_zhor_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: červený, žlutý, červený, bílý a červený v poměru 10:9:2:9:10. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na stříbrné kvádrované hradbě s cimbuřím stojí stříbrný holub se zlatou zbrojí a krkem prostřeleným zlatým šípem se stříbrným opeřením, provázený nahoře dvěma zlatými kápěmi."
    },
    {
        obec: "Stránka",
        kod: 531731,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27735,
        latitude: 50.423083,
        longitude: 14.661242
    },
    {
        obec: "Strání",
        kod: 592617,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68765,
        latitude: 48.902257,
        longitude: 17.706822,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Strani_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý v poměru 1:2:1. V zeleném pruhu vykořeněný strom se žlutou korunou a bílým kmenem kosmo podloženým žlutou sekyrou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vykořeněný strom se zlatou korunou a stříbrným kmenem, kosmo podloženým zlatou sekerou."
    },
    {
        obec: "Stranný",
        kod: 599379,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25756,
        latitude: 49.753854,
        longitude: 14.494082,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Strann%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený modrým pruhem širokým jednu pětinu šířky listu na bílé žerďové a žluté vlající pole. V bílém poli černý kos se žlutým zobákem a šedýma nohama, obrácený k vlajícímu okraji, ve žlutém modrá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě šikmo děleném štítě šikmo položená, z okraje štítu vynikající modrá berla závitem nahoru, na jejíž holi stojí doleva obrácený kos přirozené barvy, v levém poli modrá lilie."
    },
    {
        obec: "Strašice",
        kod: 551775,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.202554,
        longitude: 13.729712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Strasice_znak.jpg",
        coatOfArmsFlagDescription: "Modro - žlutě šikmo dělený list. V modrém poli žlutá ostrev se šesti suky, ve žlutém poli tři černá mlýnská kola, vše šikmo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zlatě šikmo děleném štítě nahoře zlatá ostrev dole tři černá mlýnská kola, vše šikmo."
    },
    {
        obec: "Strašice",
        kod: 560162,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33845,
        latitude: 49.735624,
        longitude: 13.757656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Strasice_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo dělený na žluté žerďové a zelené vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Šikmo zlato-zeleně dělený štít. V horním poli červená (rožmberská) růže, v dolním zlatá kladívka, mezi nimi zlatá hutnická vidlice."
    },
    {
        obec: "Strašín",
        kod: 557129,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.179345,
        longitude: 13.640501,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Stra%C5%A1%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh, svislý červený pruh a tři vodorovné pruhy, bílý, červený a bílý. Poměr pruhů na horním a dolním okraji listu je 2:2:3 a na vlajícím okraji listu 6:5:6. V modrém pruhu bílá věž s kvádrovaným soklem, černým oknem, cimbuřím a červenou stanovou střechou se žlutou makovicí, převýšená žlutou osmicípou hvězdou. V červeném poli bílý meč se žlutým jílcem hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli vedle sebe zlatá osmihrotá hvězda a stříbrná věž s kvádrovaným soklem, s cimbuřím, s černým oknem, s červenou stanovou střechou se zlatou makovicí. Dolní pole červeno-stříbrně polceno, vlevo červené břevno, vše přeloženo doleva obráceným položeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Straškov-Vodochody",
        kod: 565679,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41184,
        latitude: 50.363689,
        longitude: 14.249643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Straskov-Vodochody_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrném poli černá plamenná orlice se stříbrným perizoniem, levé pole zlato-zeleně dělené modrým vlnitým břevnem."
    },
    {
        obec: "Strašnov",
        kod: 536709,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29431,
        latitude: 50.351613,
        longitude: 14.889289
    },
    {
        obec: "Strašov",
        kod: 575763,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.087564,
        longitude: 15.522806,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Stra%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh široký třetinu délky listu a dva vodorovné pruhy, bílý a modrý. Ve žlutém pruhu hnědá palice orobince na zeleném stonku se dvěma listy. V bílém pruhu šikmo vztyčený zelený dubový list, v něm vztyčená žlutá borová šiška, v modrém pruhu šikmo bílá ryba hlavou k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. V pravém zlatém poli orobinec přirozené barvy, v levém stříbrno-modře děleném poli nahoře šikmo vztyčený zelený dubový list, v něm vztyčená zlatá borová šiška, dole šikmo stříbrná ryba."
    },
    {
        obec: "Stratov",
        kod: 537861,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28922,
        latitude: 50.19305,
        longitude: 14.90991,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Coats_of_arms_Stratov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žerďový, tvořený sedmi kosmými pruhy, střídavě bílými a modrými, prostřední červený se žlutým obilným klasem převýšeným bílým vztyčeným lipovým listem a vlající bílý se třemi červenými vlajícími klíny s vrcholy na vlajícím okraji červeného pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený kůl, v něm zlatý obilný klas převýšený vztyčeným stříbrným lipovým listem, vpravo tři kosmá modrá břevna, vlevo tři červené klíny ku středu."
    },
    {
        obec: "Stráž",
        kod: 554316,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.414854,
        longitude: 12.913028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Str%C3%A1%C5%BE_%28okres_Doma%C5%BElice%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 7 : 1. Uprostřed zkřížené bílé chodské čakany, v červeném pruhu přeložené přivrácenými žlutými ručnicemi se zkříženými hlavněmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se zeleným návrším zkřížené stříbrné chodské čakany přeložené zlatými přivrácenými ručnicemi se zkříženými hlavněmi."
    },
    {
        obec: "Stráž",
        kod: 561207,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34802,
        latitude: 49.668894,
        longitude: 12.775569,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Str%C3%A1%C5%BE_%28Tachov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1:6:1. V žerďové polovině červeného pruhu bílý dvouocasý lev se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný dvouocasý lev se zlatou zbrojí."
    },
    {
        obec: "Stráž nad Nežárkou",
        kod: 547221,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.069814,
        longitude: 14.905614,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/CoA_of_Str%C3%A1%C5%BE_nad_Ne%C5%BE%C3%A1rkou.svg",
        coatOfArmsFlagDescription: "Žlutý list s modrou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Stráž nad Nisou",
        kod: 544477,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46303,
        latitude: 50.791093,
        longitude: 15.026936,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Straz_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, bílý a modrý, zakončené rovnoramennými trojúhelníky, jejichž vrcholy dosahují ve žlutém pruhu do jedné šestiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrná otevřená brána se zlatými vraty, v ní stříbrné vozové kolo na zlaté koruně o třech listech."
    },
    {
        obec: "Stráž nad Ohří",
        kod: 555584,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.337331,
        longitude: 13.051067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Straz_nad_Ohri_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žluto-modré oblakovým řezem dělené karé nad stejně velkým žlutým čtvercovým polem a dva vodorovné pruhy, bílý a modrý. V karé nahoře polovina černého lva s červenou zbrojí, dole osm (5,3) bílých kruhových polí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře oblakovým řezem děleném štítě nahoře polovina černého lva s červenou zbrojí, dole osm (5,3) stříbrných kotoučů."
    },
    {
        obec: "Stráž pod Ralskem",
        kod: 562092,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47127,
        latitude: 50.702903,
        longitude: 14.801127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Str%C3%A1%C5%BE_pod_Ralskem_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý se žlutým květem slunečnice a žlutý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Strážek",
        kod: 596825,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59253,
        latitude: 49.440836,
        longitude: 16.193422,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Strazek_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list, v horní polovině střední části černá zubří hlava s červeným jazykem a polovinou hnědé houžve v levé nozdře. V žerďové části kosmo hnědá vrš, ve vlající části šikmo vztyčená bílá otka na hnědé násadě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá zubří hlava s červeným jazykem a polovinou houžve přirozené barvy v levé nozdře, pod ní vpravo kosmá vrš, a vlevo šikmá vztyčená otka, obojí přirozené barvy."
    },
    {
        obec: "Stražisko",
        kod: 590070,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79844,
        latitude: 49.543242,
        longitude: 16.930233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Stra%C5%BEisko_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 1:2:1. V bílém pruhu zelený kosočtverec se žlutým lipovým trojlistem na stonku. Kosočtverec se dotýká horního a dolního pruhu a jeho delší osa se rovná dvěma třetinám délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo ze zeleného trojvrší se zlatým lipovým trojlistem na stonku vyrůstá stříbrná kvádrovaná věž s cimbuřím, vlevo nad stříbrnou lilií zlatá koruna s vyrůstajícím stříbrným patriarším křížem."
    },
    {
        obec: "Strážiště",
        kod: 571881,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29413,
        latitude: 50.592452,
        longitude: 14.929303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Str%C3%A1%C5%BEi%C5%A1t%C4%9B_MB_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, v poměru 1 : 2. V žerďové a střední části listu žlutá berla mezi zkříženými bílými kopími na žlutých násadách, provázená po stranách dvěma odkloněnými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatá berla mezi zkříženými stříbrnými kopími na zlatých násadách, provázená po stranách dvěma odkloněnými zlatými lipovými listy."
    },
    {
        obec: "Strážkovice",
        kod: 545082,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.899683,
        longitude: 14.569486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Str%C3%A1%C5%BEkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. V bílém pruhu zkřížené modré halapartny na hnědých ratištích, přeložené červenou růží se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené návrší, v něm stříbrná kovárna s valbovou střechou, vpravo s věžičkou. Nahoře zkřížené halapartny přirozené barvy přeložené červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Strážná",
        kod: 580988,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.911309,
        longitude: 16.71062,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Str%C3%A1%C5%BEn%C3%A1.svg",
        coatOfArmsFlagDescription: "Žluto-modře šikmo dělený list. Ve žlutém žerďovém poli vzlétající hnědý dravý pták se zdviženými křídly a červenou zbrojí. V modrém vlajícím poli dva bílé květy bledulí na zkřížených zelených stoncích s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modré trojvrší se dvěma zkříženými stříbrnými bledulemi na zelených stoncích, na prostředním vysokém zahroceném vrchu vzlétající dravý pták přirozené barvy s červenou zbrojí, z krajních vrchů vyrůstají jehličnaté stromy přirozené barvy."
    },
    {
        obec: "Strážné",
        kod: 579696,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54352,
        latitude: 50.663681,
        longitude: 15.615183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Str%C3%A1%C5%BEn%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s pěti zelenými klíny vycházejícími z dolního okraje listu s vrcholy na horním okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené trojvrší, v něm stříbrné dvojvrší s černými hornickými kladívky. Ze dvou spodních vrchů vyrůstají dva buky, ze tří horních vrchů tři smrky, vše přirozené barvy."
    },
    {
        obec: "Strážnice",
        kod: 586587,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69662,
        latitude: 48.901117,
        longitude: 17.31691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Straznice_HO_CZ_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílým křížem s rameny širokými jednu třetinu šířky listu. Střed kříže překryt černým čtvercovým polem širokým dvě třetiny šířky listu se žlutým lvem s červeným jazykem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Strážný",
        kod: 550531,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38443,
        latitude: 48.908412,
        longitude: 13.720476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Str%C3%A1%C5%BEn%C3%BD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, zelený, bílý, zelený a červený, v poměru 1 : 1 : 4 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze zeleného trávníku doprava natočená stříbrná věž, dole kvádrovaná, s černým oknem v obou stěnách. Za věží vynikají dole do stran keře, nahoře vpravo koruna listnatého stromu, vlevo vedle věže za keřem vyrůstá smrk, vše přirozené barvy."
    },
    {
        obec: "Strážov",
        kod: 557137,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.30337,
        longitude: 13.246341,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Str%C3%A1%C5%BEov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý bílý v poměru 3:7. Bílý pruh má čtyři obdélníkové zuby a pět stejných mezer. V bílém pruhu červená běžící liška, svisle prostřelená středem těla červeným šípem hrotem dolů. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Strážovice",
        kod: 586595,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69638,
        latitude: 49.009307,
        longitude: 17.047511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Strazovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, černý, bílý, modrý a žlutý, v poměru 1:1:4:1:1. Uprostřed mezi dvěma červenými vinnými hrozny zkřížené modré sekery - širočiny s černými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Černo-modře stříbrným hrotem polcený štít. V něm červený vinný hrozen a pod ním zkřížené modré sekery - širočiny s černými topůrky. Vpravo zlatá hvězda, vlevo stříbrná hlava psa - věžníka s červeným jazykem a zlatým ostnatým obojkem."
    },
    {
        obec: "Strenice",
        kod: 536717,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29430,
        latitude: 50.392653,
        longitude: 14.82316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Strenice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 1 : 4 : 1. Do bílého pruhu vyrůstá zelená lípa s šedým kmenem provázená v horní polovině vlající části pruhu slavíkem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Strhaře",
        kod: 582379,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.435714,
        longitude: 16.436401
    },
    {
        obec: "Strmilov",
        kod: 547239,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.158567,
        longitude: 15.199439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Coa_Czech_Town_Strmilov.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma žlutými šikmými pruhy vycházejícími z první a třetí čtvrtiny dolního okraje do první a třetí čtvrtiny vlajícího okraje. V modrém žerďovém poli žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Strojetice",
        kod: 599361,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25765,
        latitude: 49.651638,
        longitude: 15.066126
    },
    {
        obec: "Stropešín",
        kod: 587460,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67555,
        latitude: 49.154144,
        longitude: 16.077352,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Coat_of_arms_of_Strope%C5%A1%C3%ADn.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 16 : 1 : 1 : 1 : 1, bílé pruhy vlnité se třemi vrcholy a dvěma prohlubněmi. V horním modrém pruhu bílý, k vlajícímu okraji cválající kůň se žlutým uzděním, provázený nad ocasem bílou šesticípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad třikrát stříbrno-modře vlnitě dělenou vlnitou patou doleva cválající stříbrný kůň se zlatým uzděním, provázený vpravo nahoře stříbrnou hvězdou."
    },
    {
        obec: "Struhařov",
        kod: 530689,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.765636,
        longitude: 14.762317,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Struha%C5%99ov_%28okr._Bene%C5%A1ov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří vodorovný pilovitý žlutý pruh s patnácti zuby, široký osminu šířky listu a dva svislé pruhy, červený a modrý. Pod žlutým pruhem bílý trojlist (2, 1) se stonky přeloženými osmicípou fasetovanou žlutou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě pod zlatou pilovitou hlavou stříbrný trojlist se stonky přeloženými zlatou osmihrotou fasetovanou hvězdou."
    },
    {
        obec: "Struhařov",
        kod: 538825,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25164,
        latitude: 49.952026,
        longitude: 14.74454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coats_of_arms_Struha%C5%99ov.jpeg",
        coatOfArmsFlagDescription: "Šikmým žlutým pruhem dělený list na žerďovou modrou a vlající zelenou část. Pruh vychází z druhé čtvrtiny dolního okraje listu do třetí čtvrtiny horního okraje listu, v modrém poli žluté vozové kolo s osmi rameny, v zeleném poli šikmo bílý poříz ostřím k vlajícímu okraji se žlutými rukojeťmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně šikmým zlatým břevnem děleném štítě nahoře zlaté vozové kolo s osmi rameny, dole šikmý stříbrný poříz se zlatými držadly."
    },
    {
        obec: "Strukov",
        kod: 569054,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78401,
        latitude: 49.733438,
        longitude: 17.175596,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Coat_of_arms_of_Strukov.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým obilným snopem vyrůstajícím z ostří bílého srpu se žlutou rukojetí kosmo k žerďovému okraji listu. Snop převýšen pěti bílými sedmicípými hvězdami do oblouku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný snop vyrůstající z ostří kosmo dolů položeného stříbrného srpu se zlatou rukojetí. Snop převýšen pěti stříbrnými sedmihrotými hvězdami do oblouku."
    },
    {
        obec: "Strunkovice nad Blanicí",
        kod: 550540,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38426,
        latitude: 49.084131,
        longitude: 14.055317,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Strunkovice_nad_Blanic%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed bílá kvádrovaná věž s cimbuřím a prázdnou branou s polovytaženou černou mříží. Nad branou červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s cimbuřím a prázdnou branou s polovytaženou černou mříží. Nad branou červená růže se zlatým semeníkem a zelenými lístky."
    },
    {
        obec: "Strunkovice nad Volyňkou",
        kod: 536784,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.2086,
        longitude: 13.889431,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Strunkovice_nad_Voly%C5%88kou_-_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, žlutý a černý, v poměru 3:2:3. V horním černém pruhu osmihrotý kříž, po jeho stranách dvě šesticípé hvězdy, vnitřní níže položené; v dolním černém pruhu ryba, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě kvádrovaný most s cimbuřím, nad ním osmihrotý kříž a z každé strany dvě hvězdy, vnitřní níže položené, dole ryba, vše zlaté."
    },
    {
        obec: "Strupčice",
        kod: 563358,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43114,
        latitude: 50.471126,
        longitude: 13.530649,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Strup%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, červený, bílý a modrý, v poměru 2 : 1 : 6 : 1 : 2. V červeném pruhu dvě věže spojené kvádrovanou hradbou se třemi zuby a prázdnou branou, vše bílé. Věže se dvěma černými okny nad sebou a modrou valbovou střechou se dvěma žlutými makovicemi. Mezi věžemi bílý půlměsíc cípy k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Strýčice1)",
        kod: 536032,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.011922,
        longitude: 14.266127,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a modrý. Mezi nimi červený kříž s rameny širokými šestinu délky listu, jehož vodorovná ramena vycházejí z třetí čtvrtiny žerďového a vlajícího okraje listu. V kříži bílý meč se žlutým jílcem, hrotem k hornímu okraji listu. V horním bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky, v horním modrém poli žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě červený svatopetrský kříž, v něm vztyčený stříbrný meč se zlatým jílcem. Horní rameno kříže provází vpravo červená růže se zlatým semeníkem a zelenými kališními lístky, vlevo zlatá lilie."
    },
    {
        obec: "Stružinec",
        kod: 577545,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51251,
        latitude: 50.550949,
        longitude: 15.358086
    },
    {
        obec: "Stružná",
        kod: 555592,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36471,
        latitude: 50.183216,
        longitude: 13.005785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Stru%C5%BEn%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý se čtyřmi bílými šesticípými hvězdami, žlutý, červený, žlutý a modrý, v poměru 10 : 1 : 8 : 1 : 10. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě v červeném, po obvodu dovnitř hořícím, kotouči stříbrná konvice s víčkem. Kotouč převýšen čtyřmi stříbrnými hvězdami do oblouku."
    },
    {
        obec: "Stružnice",
        kod: 562106,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47002,
        latitude: 50.696974,
        longitude: 14.450096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Struznice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý vlnitý se třemi vrcholy a dvěma prohlubněmi a žlutý v poměru 3:2:3. V horním pruhu dvě a v dolním jedny černé zkřížené ostrve o šesti sucích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modré vlnité břevno provázené nahoře dvěma a dole jedněmi zkříženými černými ostrvemi."
    },
    {
        obec: "Středokluky",
        kod: 539708,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25268,
        latitude: 50.132287,
        longitude: 14.234301,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/St%C5%99edokluky_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed tři zkřížené žluté šípy - kluky s bílým opeřením, dva s hroty k dolnímu rohu a cípu, přeložené třetím s hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě tři zkřížené zlaté šípy - kluky se stříbrným opeřením, dva postavené, přeložené vztyčeným."
    },
    {
        obec: "Střelice",
        kod: 583910,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66447,
        latitude: 49.152312,
        longitude: 16.50409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/St%C5%99elice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou střelou hrotem vzhůru jednu třetinu délky listu od žerďového okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vztyčená přivrácená kosa a střela, obojí zlaté."
    },
    {
        obec: "Střelice",
        kod: 594831,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.994181,
        longitude: 15.983267,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/St%C5%99elice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed zkřížené žluté klíče převýšené červeno-žlutě polceným srdcem prostřeleným vodorovně k žerďovému okraji bílým šípem se žlutým hrotem a opeřením. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zkříženými zlatými klíči červeno-zlatě polcené srdce, zleva prostřelené stříbrným šípem se zlatým hrotem a opeřením."
    },
    {
        obec: "Střelice",
        kod: 540056,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.630615,
        longitude: 13.134702,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/St%C5%99elice_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed volný bílý kvádrovaný most se třemi červeně zakončenými pilíři, podložený mečem hrotem dolů, provázeným dvěma liliemi u jílce a se dvěma šípy s hroty v obloucích mostu, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný volný kvádrovaný most se třemi červeně zakončenými pilíři, podložený postaveným mečem provázeným nahoře dvěma liliemi a dole dvěma šípy hroty do oblouků mostu, vše zlaté."
    },
    {
        obec: "Střelná",
        kod: 544914,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.177303,
        longitude: 18.097917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/CZ_St%C5%99eln%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý v poměru 1:4:1. V bílém poli černé rozložené křídlo, pery dolů, se žlutým perizoniem, vzhůru prostřelené modrým šípem s červeným opeřením a hrotem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černé rozložené křídlo, pery dolů, se zlatým perizoniem, vzhůru prostřelené modrým šípem s červeným opeřením a hrotem, provázeným modrými liliemi."
    },
    {
        obec: "Střelské Hoštice",
        kod: 551791,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38715,
        latitude: 49.297703,
        longitude: 13.75606,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Znak_str_hostice_cz.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým kosmým pruhem vycházejícím z první čtvrtiny horního okraje do čtvrté čtvrtiny dolního okraje listu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté kosmé břevno provázené nahoře stříbrným kozlem ve skoku se zlatou zbrojí, dole kosmou vztyčenou zlatou střelou se stříbrným opeřením."
    },
    {
        obec: "Střemošice",
        kod: 572314,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53854,
        latitude: 49.89166,
        longitude: 16.072158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/St%C5%99emo%C5%A1ice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině dva svislé pruhy, modrý a zelený, v poměru 2 : 1 a ve vlající polovině dva vodorovné pruhy, bílý a červený. V modrém pruhu tři bílé květy střemchy se žlutými středy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stojící stříbrný vzpřímený kůň s červenou zbrojí, opírající se o stříbrno-červeně polcenou kvádrovanou věž s černým oknem a cimbuřím, nad nimi tři stříbrné květy střemchy se zlatými středy vedle sebe."
    },
    {
        obec: "Střemy",
        kod: 535214,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27734,
        latitude: 50.384262,
        longitude: 14.565624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/St%C5%99emy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 7:2. Uprostřed listu červená věž s cimbuřím, kvádrovaným soklem, černou branou a dvěma okny, přeložená bílou krokví s červenou růží ve vrcholu. Věž je provázená v žerďové a vlající části černými zkříženými ostrvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trávníku červená věž s cimbuřím, kvádrovaným soklem, černou branou a dvěma okny, přeložená stříbrnou krokví s červenou růží ve vrcholu a provázená po stranách černými zkříženými ostrvemi."
    },
    {
        obec: "Střeň",
        kod: 547018,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78332,
        latitude: 49.69162,
        longitude: 17.153923,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/St%C5%99e%C5%88_znak.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým rakem hlavou k žerdi s červeným středovým křížem na krunýři. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný rak s červeným heroldským křížem na krunýři."
    },
    {
        obec: "Střevač",
        kod: 573540,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50722,
        latitude: 50.404458,
        longitude: 15.274587
    },
    {
        obec: "Střezetice",
        kod: 570966,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50312,
        latitude: 50.258368,
        longitude: 15.718242,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/CoA_of_St%C5%99e%C5%BEetice.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma propletenými vztyčenými rybářskými háčky, provázenými dvěma odvrácenými podkovami, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dva propletené vztyčené rybářské háčky provázené po stranách dvěma odvrácenými podkovami, vše stříbrné."
    },
    {
        obec: "Střezimíř",
        kod: 530701,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25787,
        latitude: 49.532109,
        longitude: 14.611434,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/St%C5%99ezim%C3%AD%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, pilovitý modrý s pěti zuby vysokými osminu šířky listu, bílý a zelený, v poměru 2 : 1 : 5 : 1. V žerďové polovině dolního bílého pruhu na horním okraji zeleného pruhu vykračující červený kohout se žlutou zbrojí, červeným hřebínkem a zelenou lipovou větvičkou s pěti listy v zobáku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě, pod stříbrno - modře devětkrát nahoru lomeně dělenou hlavou, na zeleném návrší vykračující červený kohout se zlatou zbrojí, červeným hřebínkem a zelenou lipovou větvičkou s pěti listy v zobáku."
    },
    {
        obec: "Stříbrná",
        kod: 560651,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.356766,
        longitude: 12.526519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Stribrna_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý o pěti vrcholech a čtyřech prohlubních, v poměru 3:1. V bílém pruhu zelený vykořeněný smrk, pod ním v modrém pruhu bílé kruhové pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zvýšenou modrou vlnitou patou se stříbrným kotoučem zelený vykořeněný smrk."
    },
    {
        obec: "Stříbrná Skalice",
        kod: 533718,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28167,
        latitude: 49.897765,
        longitude: 14.846268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/St%C5%99%C3%ADbrn%C3%A1_Skalice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, v dolním rohu ze tří čtvrtin žerďového a poloviny dolního okraje listu vyniká bílá skála, u ní stojí horník, v kukle, kabátci s flekem, střevících, vše černé, v bílých kalhotách a s hnědým opaskem, držící v napřažené pravici mlátek a v levici želízko přiložené ke skále. Hornická kladívka jsou bílá na hnědých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Z pravého okraje modrého štítu vyniká skála, vpravo od ní stojí horník, v kukle, kabátci s flekem, střevících, vše černé, ve stříbrných kalhotách a s koženým opaskem, držící v napřažené pravici mlátek a v levici želízko přiložené ke skále. Skála, opasek a hornická kladívka přirozené barvy."
    },
    {
        obec: "Stříbrnice",
        kod: 552887,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75201,
        latitude: 49.328553,
        longitude: 17.24617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/St%C5%99%C3%ADbrnice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi vzájemně se dotýkajícími bílými routami, jejichž strany vycházejí ze středů žerďového, vlajícího, horního a dolního okraje a v prodloužení směřují do rohů a cípů listu. V prostřední routě modrá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný hrot s modrou lilií, provázený dvěma stříbrnými zvony se závěsy."
    },
    {
        obec: "Stříbrnice",
        kod: 592625,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68709,
        latitude: 49.05671,
        longitude: 17.307593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/St%C5%99%C3%ADbrnice_%28UH%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a zelený, v poměru 2 : 1 : 2. V bílém pruhu zelený vinný hrozen s listem a úponkem, v červeném bílý popravčí meč se žlutým jílcem nahoře a v zeleném pruhu bílý vinařský nůž-kosíř se žlutou rukojetí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít s červenou hlavou, v ní doleva položený stříbrný popravčí meč se zlatým jílcem. Vpravo zelený vinný hrozen s listem a úponkem, vlevo stříbrný vinařský nůž-kosíř se zlatou rukojetí."
    },
    {
        obec: "Stříbro",
        kod: 561215,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.753048,
        longitude: 13.004203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Stribro_%28Tachov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - bílý, žlutý a modrý v poměru 3:1:3. Poměr šířky praporu k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prolomenou černou gotickou branou se zlatou lilií a otevřenými černými vraty na zlatých závěsech. Z hradby vynikají dvě stříbrné věže, každá s černým oknem, cimbuřím a červenou kuželovou střechou se zlatou makovicí. Mezi věžemi na hradbě český lev opřený předními tlapami o pravou věž."
    },
    {
        obec: "Stříbřec",
        kod: 547247,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37818,
        latitude: 49.029989,
        longitude: 14.878351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/St%C5%99%C3%ADb%C5%99ec_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený klín s vrcholem ve středu listu a dva vodorovné pruhy, bílý a červený. V klínu bíle lemovaná modrá šesticípá hvězda, v bílém pruhu černá mnišská kápě se žlutou podšívkou, v červeném pruhu bílá dřevorubecká pila-břichatka se žlutými rukojeťmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vidlicově dělený štít, v horním zeleném poli stříbrně lemovaná modrá hvězda, v pravém poli černá mnišská kápě se zlatou podšívkou, vlevo šikmo stříbrná dřevorubecká pila-břichatka se zlatými rukojeťmi."
    },
    {
        obec: "Stříbrné Hory",
        kod: 569534,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.601344,
        longitude: 15.691053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/St%C5%99%C3%ADbrn%C3%A9_Hory_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý vlnkovaný se třemi vrcholy, v poměru 5:1. V červeném pruhu žlutý luk s bílým šípem se žlutým hrotem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad třemi stříbrnými horami položený zlatý luk se stříbrným šípem se zlatým hrotem."
    },
    {
        obec: "Střílky",
        kod: 589039,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76804,
        latitude: 49.142634,
        longitude: 17.211177
    },
    {
        obec: "Střítež",
        kod: 551538,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.775738,
        longitude: 14.434542
    },
    {
        obec: "Střítež",
        kod: 587958,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58811,
        latitude: 49.443643,
        longitude: 15.598749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/St%C5%99%C3%ADte%C5%BE_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsFlagDescription: "Červeno - bíle čtvrcený list. Uprostřed šikmý orobinec s hnědým květenstvím a zelenými listy. V horním žerďovém poli bílá korunovaná lví hlava s krkem a žlutým jazykem, v dolním vlajícím poli bílé vozové kolo se čtyřmi rameny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně čtvrceném štítě šikmý orobinec s listy přirozené barvy provázený v prvním poli stříbrnou korunovanou lví hlava s krkem a zlatým jazykem, ve čtvrtém poli stříbrným vozovým kolem se čtyřmi rameny."
    },
    {
        obec: "Střítež",
        kod: 537594,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.364014,
        longitude: 15.126619
    },
    {
        obec: "Střítež",
        kod: 554871,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.191417,
        longitude: 15.893355,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Stritez_TR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, červený a bílý, v poměru 5:1:1:1. V žerďové polovině modrého pruhu kráčející bílý okřídlený čelně hledící lev se žlutou zbrojí a svatozáří a červeným jazykem držící v pravé tlapě bílý květ na vztyčeném žlutém stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrnou vlnitou patou se dvěma červenými květy kráčející stříbrný okřídlený čelně hledící lev se zlatou zbrojí a svatozáří a červeným jazykem držící v pravé tlapě stříbrný květ na vztyčeném zlatém stonku."
    },
    {
        obec: "Střítež",
        kod: 549886,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59251,
        latitude: 49.440602,
        longitude: 16.256802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Stritez_ZR_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným vlajícím klínem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelený hrot, v něm čelně vzlétající holubice obklopená paprsky, vše stříbrné. Hrot provázejí nahoře dva modré květy chrpy."
    },
    {
        obec: "Střítež",
        kod: 552674,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73959,
        latitude: 49.67941,
        longitude: 18.569214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Stritez_FM_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s kráčejícím bílým koněm a se žlutým vlajícím zvlněným pruhem, širokým jednu čtvrtinu délky listu, se dvěma vrcholy a třemi prohlubněmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou vlnitou patou kráčející stříbrný kůň."
    },
    {
        obec: "Střítež nad Bečvou",
        kod: 544922,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75652,
        latitude: 49.458663,
        longitude: 18.056978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/CZ_St%C5%99%C3%ADte%C5%BE_nad_Be%C4%8Dvou_COA.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma vodorovnými bílými vlnitými pruhy, které vycházejí z dvanácté a čtrnácté dvacetiny žerďového a vlajícího okraje. V žerďové a střední části nad pruhy tři oboustranně uťaté žluté stvoly rákosu, oba krajní s jedním listem, pod nimi pod pruhy kosmo bílá radlice ostřím nahoru a hrotem k vlajícímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrná zúžená vlnitá břevna provázená nahoře třemi zlatými oboustranně uťatými stvoly rákosu, oba krajní s jedním listem, dole kosmo poleženou stříbrnou radlicí ostřím doleva."
    },
    {
        obec: "Střítež nad Ludinou",
        kod: 517909,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75363,
        latitude: 49.609436,
        longitude: 17.739016,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/St%C5%99%C3%ADte%C5%BE_nad_Ludinou_znak.png",
        coatOfArmsFlagDescription: "Zelený list s bílou žerďovou krokví s hrotem na středu vlajícího okraje a s červeným žerďovým klínem s hrotem dosahujícím do třetiny délky listu. Šířka klínu na žerďovém okraji je třetina šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě s vlnitou, stříbrně a modře rovněž vlnitě dělenou patou, stříbrný klín a na něm zkřížený oštěp s brkovým perem, obojí červené."
    },
    {
        obec: "Střítež pod Křemešníkem",
        kod: 561487,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.434133,
        longitude: 15.320258,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Coat_of_arms_of_St%C5%99%C3%ADte%C5%BE_pod_K%C5%99eme%C5%A1n%C3%ADkem.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a modré, v poměru 5 : 1 : 1 : 1 a zelený kanton se čtyřmi (2, 2) bílými květy střemchy se žlutými středy. Ve vlající polovině listu vyrůstá z dolního okraje listu hnědá palice orobince na zeleném stonku se čtyřmi listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod zeleným obloukovým břevnem se čtyřmi stříbrnými květy střemchy se zlatými středy vyrůstající orobinec se čtyřmi listy přirozené barvy, dole podložený dvěma modrými břevny."
    },
    {
        obec: "Střížovice",
        kod: 508152,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.140007,
        longitude: 15.156956,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/St%C5%99%C3%AD%C5%BEovice_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s kotvou ovinutou kolem dříku bílým lanem a provázenou dvěma růžemi s červenými semeníky, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kotva kolem dříku ovinutá stříbrným lanem a provázená dvěma růžemi s červenými semeníky, vše zlaté."
    },
    {
        obec: "Střížov",
        kod: 545091,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.890503,
        longitude: 14.527777
    },
    {
        obec: "Střížovice",
        kod: 558401,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33207,
        latitude: 49.611889,
        longitude: 13.458498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/St%C5%99%C3%AD%C5%BEovice_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed tři bílé toskánské sloupy, prostřední vyšší, završené žlutými tlapatými křížky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě tři stříbrné toskánské sloupy, prostřední vyšší, završené zlatými tlapatými křížky."
    },
    {
        obec: "Střížovice",
        kod: 589047,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.257184,
        longitude: 17.449702,
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a žlutých, v poměru 1 : 1 : 4 : 1 : 1, přes ně šikmý bílý pruh široký třetinu šířky listu, v něm šikmo tři červené zavinuté střely. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné šikmé břevno se třemi šikmými červenými zavinutými střelami provázené nahoře sluncem s tváří a dole šikmo vztyčeným krojidlem, obojí zlaté."
    },
    {
        obec: "Studánka",
        kod: 561223,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.780682,
        longitude: 12.603786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Stud%C3%A1nka_%28Tachov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V červeném pruhu bílý středový vodorovný pruh široký jednu čtvrtinu šířky listu se třemi modrými klíny s vrcholy na horním okraji bílého pruhu. V bílém vlajícím pruhu modrý středový vlnitý pruh široký jednu čtvrtinu šířky listu se třemi vrcholy a dvěma prohlubněmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý vlnitý kůl pod červenou hlavou. V ní stříbrné břevno se třemi modrými klíny."
    },
    {
        obec: "Studená",
        kod: 547263,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37856,
        latitude: 49.185258,
        longitude: 15.286981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Studen%C3%A1_%28Jind%C5%99ich%C5%AFv_Hradec_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V žerďové polovině červeného pruhu paroží, v něm rovnostranný trojúhelník vrcholem k dolnímu okraji listu, oboje bílé. Pod parožím v modrém pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Studená",
        kod: 553603,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.960729,
        longitude: 13.643134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Studen%C3%A1_%28Plze%C5%88-North_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý vlnitý se šesti vrcholy a pěti prohlubněmi a zvlněný zelený, v poměru 6 : 1 : 3. V žerďové polovině červeného pruhu bílý korbel, dole provázený dvěma svěšenými žlutými chmelovými šišticemi, v zeleném pruhu pod korbelem žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně sníženým stříbrným vlnitým břevnem dělený štít. V horním poli stříbrný korbel dole provázený dvěma svěšenými zlatými chmelovými šišticemi, v dolním poli zlatý zvon."
    },
    {
        obec: "Studené",
        kod: 580996,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56164,
        latitude: 50.066658,
        longitude: 16.589294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Studen%C3%A9_CoA.png",
        coatOfArmsFlagDescription: "Bílý list s levým modře obrněným ramenem držícím v holé ruce na zeleném stonku se dvěma listy červenou šestilistou růži se žlutým semeníkem a zelenými kališními lístky. V horním žerďovém rohu a v horním vlajícím cípu červený trojúhelník, oba s odvěsnami dlouhými polovinu délky listu a se žlutou osmicípou hvězdou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě ve stříbrném trojvrší levé modře obrněné rameno držící v ruce přirozené barvy na zeleném stonku se dvěma listy červenou šestilistou růži se zlatým semeníkem a zelenými kališními lístky. Nad krajními vrchy zlatá osmihrotá hvězda."
    },
    {
        obec: "Studenec",
        kod: 591769,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67502,
        latitude: 49.20022,
        longitude: 16.064912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Studenec-T%C5%99eb%C3%AD%C4%8D-znak.svg",
        coatOfArmsFlagDescription: "Modrý list s bílou kvádrovanou studnou, žlutým rumpálem a okovem na bílém provazu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná okrouhlá studna se zlatým rumpálem a okovem visícím na stříbrném provazu."
    },
    {
        obec: "Studenec",
        kod: 577553,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51233,
        latitude: 50.553543,
        longitude: 15.54947,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Studenec_CoA.svg",
        coatOfArmsFlagDescription: "Čtvrcený list s černou horní a žlutou dolní žerďovou částí a bílou horní a červenou dolní vlající částí. Uprostřed listu v modrém volném karé o šířce poloviny šířky listu bílý vztyčený meč se žlutým jílcem a záštitou, podložený žlutými váhami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravá polovina dělená, nahoře v modrém poli stříbrný vztyčený meč se zlatým jílcem a záštitou podložený zlatými váhami, dolní pole černo-štříbrno-červeně děleno. V levé zlaté polovině černá vztyčená ostrev s osmi suky."
    },
    {
        obec: "Studeněves",
        kod: 551457,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27379,
        latitude: 50.224222,
        longitude: 14.044495
    },
    {
        obec: "Studénka",
        kod: 599921,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74213,
        latitude: 49.723516,
        longitude: 18.078634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Stud%C3%A9nka_znak.png",
        coatOfArmsFlagDescription: "Červeno-modře čtvrcený list se žlutým vodorovným pruhem vycházejícím ze střední pětiny žerďového a vlajícího okraje. V horním žerďovém a dolním vlajícím poli bílá tryskající fontána, v dolním žerďovém a horním vlajícím poli žlutá lilie. Ve žlutém pruhu dva k sobě obrácené černé železniční nárazníky, plochý a vypouklý, vynikající z okrajů listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře čtvrcený štít, v 1. a 4. poli stříbrná tryskající fontána, ve 2. a 3. poli zlatá lilie, přes štít zlaté břevno se dvěma černými železničními nárazníky, pravý plochý, levý vypouklý."
    },
    {
        obec: "Studnice",
        kod: 593605,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68308,
        latitude: 49.375694,
        longitude: 16.881134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Studnice_okres_Vy%C5%A1kov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pilovitý pruh o sedmi zubech široký jednu čtvrtinu délky listu s červeným šípem hrotem dolů a tři vodorovné pruhy, červený, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole červeno-zlatě pilovitě děleno, nahoře vyniká stříbrná kvádrovaná studna, dole kosmý, dolů obrácený červený šíp, v levém stříbrném poli vyrůstá ze zeleného trávníku smrk přirozené barvy."
    },
    {
        obec: "Studený",
        kod: 530719,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.607512,
        longitude: 15.127817,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Studen%C3%BD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 11 : 1 : 1 : 1 : 2. V žerďové polovině horního pruhu polovina žlutého koně ve skoku s červeným uzděním, provázeného u žerďového okraje listu bílou sněhovou vločkou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad třikrát stříbrno-modře vlnkovitě dělenou vlnkovitou patou zlatá polovina koně ve skoku s červeným uzděním, provázená vpravo nahoře stříbrnou sněhovou vločkou."
    },
    {
        obec: "Studnice",
        kod: 591777,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.291088,
        longitude: 16.029048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Studnice_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy žlutý, modrý a žlutý, v poměru 1:5:1. V modrém pruhu bílo-červeně polcený rak provázený bůvolími rohy, žerďovým červeným a vlajícím bílým. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá okrouhlá kvádrovaná studna převýšená stříbrno-červeně polceným rakem a provázená bůvolími rohy, pravým červeným, levým stříbrným."
    },
    {
        obec: "Studnice",
        kod: 574481,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54948,
        latitude: 50.421368,
        longitude: 16.089978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Studnice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým zvlněným pruhem širokým jednu třetinu délky listu, se dvěma vrcholy a třemi prohlubněmi. V bílém pruhu u žerďového okraje tři červené, u zvlněného okraje dva červeno-modře polcené luční květy. V modrém poli bílý vykračující kohout s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou vlnitou hlavou stříbrný vykračující kohout s červenou zbrojí. V hlavě pět pětilistých květů, prostřední tři červené, krajní polceny modro-červeně a naopak."
    },
    {
        obec: "Studnice",
        kod: 572322,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.738058,
        longitude: 15.90302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Studnice_%28okres_Chrudim%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou kvádrovanou studnou s vytaženým okovem, provázenou v žerďové části bílým zvonem a ve vlající části fasetově střídavě bílo-černě polceným a děleným liliovým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá kvádrovaná studna s vytaženým okovem, provázená vpravo stříbrným zvonem a vlevo fasetově střídavě stříbrno-černě polceným a děleným liliovým křížem."
    },
    {
        obec: "Stupava",
        kod: 592633,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68601,
        latitude: 49.117266,
        longitude: 17.248767,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Stupava_znak.GIF",
        coatOfArmsFlagDescription: "Modro-žlutě kosmo a šikmo čtvrcený list. Uprostřed černý kosočtverec dlouhý dvě třetiny délky listu a vysoký pět sedmin šířky listu se žlutou vztyčenou dubovou větévkou s jedním žaludem mezi dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě černý kopec se zlatou vyrůstající dubovou větévkou se vztyčeným žaludem a dvěma listy po stranách. Z kopce vyrůstají na každé straně dvě pštrosí pera opačných barev, nad nimi dvě přivrácené radlice, černá a zlatá."
    },
    {
        obec: "Stvolínky",
        kod: 562114,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47102,
        latitude: 50.632379,
        longitude: 14.428717,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Stvol%C3%ADnky_CoA.png"
    },
    {
        obec: "Stvolová",
        kod: 582620,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67961,
        latitude: 49.589318,
        longitude: 16.540541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Coat_of_arms_of_Stvolov%C3%A1.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy žlutý, zelený a bílý, v poměru 1 : 3 : 1. V zeleném pruhu tři bílé dvanáctilisté květy se žlutými středy na dole spojených žlutých stvolech, krajních prohnutých. Stvoly provázeny v žerďové polovině pruhu krojidlem, ve vlající polovině pruhu radlicí, obojí hroty nahoru a odvrácené, a dole patriarším křížem, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě tři stříbrné dvanáctilisté květy se zlatými středy na dole spojených zlatých stvolech, krajních prohnutých. Stvoly provázeny vpravo krojidlem, vlevo radlicí, obojí vztyčené a odvrácené, a dole patriarším křížem, vše stříbrné."
    },
    {
        obec: "Sudějov",
        kod: 531391,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.860798,
        longitude: 15.103948
    },
    {
        obec: "Sudice",
        kod: 582395,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.53,
        longitude: 16.668628,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/CoA_of_Sudice_%28Blansko_District%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 2 : 1 : 1, červený, bílý a pilovitý modrý se čtyřmi zuby vysokými osminu délky listu. V červeném pruhu volný hřeben se sedmi zuby, pod ním lilie, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém červeném poli volný hřeben nad lilií, obojí stříbrné, levé pole stříbrno-modře pilovitě polcené."
    },
    {
        obec: "Sudice",
        kod: 591785,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67573,
        latitude: 49.181993,
        longitude: 16.238291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Znak_obce_Sudice_u_T%C5%99eb%C3%AD%C4%8De.jpg",
        coatOfArmsFlagDescription: "Červeno-zeleně kosmo dělený list. V horním zeleném poli kosmo bílá radlice hrotem a ostřím nahoru, v dolním červeném poli kosmo žlutá kamenná sekera bez násady hrotem k dolnímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně kosmo děleném štítě nahoře stříbrná vztyčená radlice ostřím nahoru, dole zlatá kamenná sekera bez násady."
    },
    {
        obec: "Sudice",
        kod: 510378,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74725,
        latitude: 50.031637,
        longitude: 18.068504,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Sudice_%28Opava_District%29_CoA.png",
        coatOfArmsFlagDescription: "Červený list s hlavou sv. Jana Křtitele z obecního znaku uprostřed. Průměr žluté mísy je dvě třetiny šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Sudislav nad Orlicí",
        kod: 581003,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 49.989392,
        longitude: 16.313242,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Sudislav_nad_Orlic%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Šikmo žluto-modro-bílo-modro vlnitě dělený list, v poměru 15 : 2 : 2 : 5. Do žlutého pole vyrůstá černá orlice s červenou zbrojí držící v pravém pařátu kosmo červenou osmilistou růži se žlutým semeníkem na zeleném stonku se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-modře sníženě vlnitě šikmo dělený štít. Z dolního pole se šikmým stříbrným vlnitým břevnem vyrůstá černá orlice s červenou zbrojí držící v pařátu červenou osmilistou růži se zlatým semeníkem na zeleném stonku se dvěma listy."
    },
    {
        obec: "Sudoměř",
        kod: 565920,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29425,
        latitude: 50.445088,
        longitude: 14.739481,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Sudom%C4%9B%C5%99_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list. Z horní poloviny vlajícího okraje listu vychází červený klín s vrcholem v sedmé dvanáctině délky listu, k němuž přiléhá žlutá hrotitá krokev s vrcholem v páté dvanáctině délky listu. Z dolní poloviny žerďového okraje listu vychází červený klín s vrcholem v páté dvanáctině délky listu, k němuž přiléhá žlutá hrotitá krokev s vrcholem v sedmé dvanáctině délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě na zeleném vrchu zlatá bedněná zvonice s valbovou střechou a zvonovým patrem s vydutou jehlancovou střechou zakončenou makovicí s korouhvičkou. Střechy zvonice jsou provázeny vpravo hvězdou nad lilií, obojí zlaté, vlevo českým lvem."
    },
    {
        obec: "Sudkov",
        kod: 541109,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78821,
        latitude: 49.919123,
        longitude: 16.945268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Sudkov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modro - černě polcený, žlutý a černo - modře polcený. Uprostřed horního a dolního pruhu žlutá lilie, mezi nimi ve žlutém pruhu modrý květ lnu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-černě čtvrcený štít se zlatým břevnem, provázeným dvěma zlatými liliemi. Na břevně je položen modrý květ lnu."
    },
    {
        obec: "Sudoměřice",
        kod: 586609,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69666,
        latitude: 48.867328,
        longitude: 17.256869,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Sudom%C4%9B%C5%99ice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červeno-žlutě čtyřikrát polcený, bílý a zelený, v poměru 11:1:2:1:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě pět sudlic opačných barev, střídavě nahoru a dolů postavených, přeložené čtyřikrát červeno-zlatě polceným zúženým břevnem."
    },
    {
        obec: "Sudoměřice u Bechyně",
        kod: 553166,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39172,
        latitude: 49.289825,
        longitude: 14.538534,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Sudom%C4%9B%C5%99ice_u_Bechyn%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu a žluté pole se třemi kosmými modrými pruhy širokými jednu pětinu šířky listu. V červeném pruhu osmicípá hvězda nad dvanácti šesticípými hvězdami, z nichž devět je sestaveno do ondřejského kříže, mezi jehož rameny jsou nahoře a po stranách položeny tři zbývající hvězdy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo osmihrotá hvězda nad dvanácti (3,2,3,2,2) hvězdami, vše zlaté. Vlevo tři kosmá modrá břevna."
    },
    {
        obec: "Sudoměřice u Tábora",
        kod: 553174,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39136,
        latitude: 49.510295,
        longitude: 14.658595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Sudom%C4%9B%C5%99ice_u_T%C3%A1bora_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 3:1. Uprostřed červeného pruhu žlutý obilný klas se dvěma listy, provázený dvěma bílými postavenými kypřicemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad modrou vlnitou patou zlatý obilný klas se dvěma listy provázený dvěma postavenými stříbrnými kypřicemi."
    },
    {
        obec: "Sudovo Hlavno",
        kod: 571954,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29476,
        latitude: 50.262851,
        longitude: 14.685423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Sudovo_Hlavno_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s dolním vlajícím bílým trojúhelníkem, v něm postavená červená borová šiška. V žerďové polovině listu korunovaný, žlutě zdobený a červeně vyložený bílý turnajský helm se zavěšeným žlutým zvonkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo nahoru do oblouku sníženě děleném štítě nahoře stříbrný korunovaný turnajský helm se zavěšeným zlatým zvonkem, dole vztyčená červená borová šiška."
    },
    {
        obec: "Sudslava",
        kod: 581011,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56113,
        latitude: 50.040677,
        longitude: 16.286549,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Sudslava_-_Znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modro-bíle dělený. V červeném pruhu sv. Jan Nepomucký se svatozáří s pěti šesticípými žlutými hvězdami, v černobílém oděvu a s krucifixem v levé ruce. Z bílého pole do modrého pole vyniká bílá kvádrovaná věž s cimbuřím, vchodem a kruhovým oknem, oboje černé, v bílém poli černá lovecká trubka se žlutým kováním a závěsem, nátrubkem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli sv. Jan Nepomucký se svatozáří s pěti zlatými hvězdami, v černobílém oděvu a s krucifixem v levé ruce. Levé pole modro-stříbrně dělené, nahoře vyniká stříbrná kvádrovaná věž s cimbuřím, vchodem a kruhovým oknem, oboje černé, dole černá lovecká trubka se zlatým kováním a závěsem, nátrubkem doprava."
    },
    {
        obec: "Suchá",
        kod: 587982,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58833,
        latitude: 49.301108,
        longitude: 15.577541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Such%C3%A1_%28okres_Jihlava%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým k vlajícímu okraji vykračujícím, čelně hledícím býkem, z jehož hřbetu vyrůstá žlutý obilný snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě doleva vykračující stříbrný čelně hledící býk, z jehož hřbetu vyrůstá zlatý snop."
    },
    {
        obec: "Suchá Lhota",
        kod: 578835,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.877328,
        longitude: 16.173642,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Such%C3%A1_Lhota_CoA_ver2.png",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým klínem lemovaným červenou krokví vycházející z první a šesté šestiny žerďového okraje a s vrcholem na vlajícím okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít s červeným hrotem, provázeným nahoře vpravo zlatou hvězdou a vlevo vztyčeným zeleným lipovým listem. V hrotu volná stříbrná kaple s obloukovým oknem a se zlatou stanovou střechou zakončenou patriarším křížkem."
    },
    {
        obec: "Suchdol",
        kod: 590088,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79845,
        latitude: 49.547269,
        longitude: 16.896773,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Suchdol_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře čtvrcený list. Uprostřed horní poloviny listu bílá svatojakubská mušle, uprostřed dolní poloviny listu červeno-žlutě polcené srdce probodnuté do oblouku sedmi bílými meči se žlutými jílci. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře čtvrcený štít, v prvním poli stříbrná svatojakubská mušle, ve druhém poli stříbrná labuť se zlatou zbrojí a červeným jazykem, dole červeno-zlatě polcené srdce probodnuté do oblouku sedmi stříbrnými meči se zlatými jílci."
    },
    {
        obec: "Suchá Loz",
        kod: 592641,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68753,
        latitude: 48.970109,
        longitude: 17.713899,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Sucha_Loz_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zelenou vrbou vyrůstající z černé radlice hrotem žerdi a ostřím dolů, provázenou dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z položené černé radlice zelená vrba, provázená dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Suchdol",
        kod: 534439,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28502,
        latitude: 49.95257,
        longitude: 15.166616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Znak_obce_Suchdol.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4 : 1. V žerďové části listu hnědý kmen zlomeného stromu s větví k vlajícímu okraji, z níž vyrůstá zelená jedle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku kmen zlomeného stromu s větví doleva, z níž vyrůstá jedle, vše přirozené barvy."
    },
    {
        obec: "Suchdol nad Lužnicí",
        kod: 547280,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37806,
        latitude: 48.893435,
        longitude: 14.877056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Suchdol_nad_Lu%C5%BEnic%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vodorovným oboustraně vlnkovitým pruhem vycházejícím ze sedmé desetiny žerďového a vlajícího okraje. V žerďové části listu šikmo žlutý klíč se zuby dole a k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným zvlněným břevnem šikmo postavený zlatý klíč zuby nahoru."
    },
    {
        obec: "Suchdol nad Odrou",
        kod: 599930,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74201,
        latitude: 49.65582,
        longitude: 17.928263,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Suchdol_nad_Odrou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: červený, vlnitý bílý o třech vrcholech a dvou prohlubních a modrý v poměru 9:2:5. V horní žerďové polovině listu zkřížená bílá kosa a hrábě v dolní žerďové polovině bílé okřídlené železniční kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Sníženým vlnitým břevnem dělený štít, nahoře v červeném poli zkřížená kosa a hrábě, dole v modrém poli okřídlené železniční kolo, vše stříbrné."
    },
    {
        obec: "Suchodol",
        kod: 541371,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.724148,
        longitude: 14.082522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Suchodol_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 3 : 1. V modrém pruhu loďka, z níž vyrůstají dva odkloněné obilné klasy, každý s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě loďka, z níž vyrůstají dva odkloněné obilné klasy, každý s listem, vše zlaté."
    },
    {
        obec: "Suchohrdly",
        kod: 555231,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.866218,
        longitude: 16.089121,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Suchohrdly_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a červený. V zeleném pruhu žlutý vinný hrozen, v bílém pruhu knížecí čepice podložená modrým mečem hrotem dolů, v červeném pruhu žlutý drak s modrou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě pod stříbrnou hlavou s knížecí čepicí podloženou doleva položeným modrým mečem vpravo vinný hrozen na stonku, vlevo drak s modrou zbrojí, obojí zlaté."
    },
    {
        obec: "Suchohrdly u Miroslavi",
        kod: 594849,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67172,
        latitude: 48.943156,
        longitude: 16.363302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Suchohrdly_u_Miroslavi_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy zelený a červený. Uprostřed listu volná bílá věž s ochozem a jehlancovou střechou, s gotickým vchodem a dvěma okny nad sebou, horním obloukovým, vše černé. Věž je u horního okraje listu podložena dvěma propletenými žlutými větévkami vinné révy, každá s listy a se svěšeným hroznem, a po stranách provázena odvrácenými bílými vinařskými noži - kosíři se žlutými rukojeťmi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě volná stříbrná věž s ochozem a jehlancovou střechou, s gotickým vchodem a dvěma okny nad sebou, horním obloukovým, vše černé. Věž nahoře podložena dvěma propletenými zlatými větévkami vinné révy, každá s listy a se svěšeným hroznem, a po stranách provázena odvrácenými stříbrnými vinařskými noži - kosíři se zlatými rukojeťmi."
    },
    {
        obec: "Suchomasty",
        kod: 531782,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26722,
        latitude: 49.895459,
        longitude: 14.056714,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Suchomasty_CoA.png",
        coatOfArmsFlagDescription: "Žluto-zeleně šikmo dělený list. Ve žlutém poli černé sluneční hodiny s bílým ciferníkem v podobě stuhy. V zeleném poli bílá vápenice s černým topeništěm, v něm červený oheň. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně dělený štít. Nahoře černé sluneční hodiny se stříbrným ciferníkem v podobě stuhy, dole stříbrná vápenice s černým topeništěm, v něm červený oheň."
    },
    {
        obec: "Suchonice",
        kod: 569771,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78357,
        latitude: 49.527138,
        longitude: 17.382014,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Suchonice_CoA.png",
        coatOfArmsFlagDescription: "Červeno-modře kosmo a šikmo dělený list. Uprostřed kosmo žlutý listnatý stvol, šikmo přeložený bílým mečem se žlutým jílcem hrotem k hornímu cípu, nad nimi knížecí čepice ozdobená křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně kosmo čtvrceném štítě kosmý zlatý listnatý stvol křížem přeložený vztyčeným stříbrným mečem se zlatým jílcem, nahoře knížecí čepice ozdobená křížkem."
    },
    {
        obec: "Suchov",
        kod: 586617,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69671,
        latitude: 48.907522,
        longitude: 17.563562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Suchov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený se žlutým snopem a modrý, v poměru 1 : 2 : 1. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, vpravo zeleno - modře dělený. Nahoře zlatý snop, dole stříbrné mlýnské vodní kolo, v levém červeném poli stříbrná zavinutá střela."
    },
    {
        obec: "Suchovršice",
        kod: 579726,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54232,
        latitude: 50.523502,
        longitude: 15.99831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Suchovrsice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný modrý vycházející z poloviny žerďového okraje do dolní třetiny vlajícího okraje listu. Modrý pruh má poloviční vrchol, prohlubeň, vrchol a poloviční prohlubeň. V první třetině délky listu roubená zvonice, převýšená kosmým mlátkem šikmo podloženým želízkem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve sníženě červeno-modře děleném štítě roubená zvonice převýšená kosmým mlátkem šikmo podloženým želízkem, vše zlaté."
    },
    {
        obec: "Suchý",
        kod: 582409,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.482895,
        longitude: 16.762483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Such%C3%BD_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s vodorovným pruhem na dolním okraji, širokým jednu třetinu šířky listu a tvořeným dvaceti osmi šindeli ve dvou řadách, střídavě žlutými a červenými. Z pruhu vyrůstá modrý lev s červenou zbrojí držící černou sekeru s hnědým topůrkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve stříbrném poli rostoucí modrý lev s červenou zbrojí držící sekyru přirozených barev, dole dvě řady šindelů střídavě zlatých a červených."
    },
    {
        obec: "Sukorady",
        kod: 548880,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.325546,
        longitude: 15.582808,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Sukorady_JI_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený, žlutý, červený, zubatý bílý, zvlněný modrý, žlutý a modrý, v poměru 1 : 1 : 2 : 2 : 2 : 1 : 1. Bílý pruh má čtyři obdélníkové zuby se třemi mezerami do poloviny listu, modrý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělen červeno-modře stříbrným zúženým břevnem, nahoře cimbuřovým a dole vlnitým. V horním poli vyrůstající zlatý dub s žaludy, dole vyrůstající zlatý obilný klas provázený dvěma stříbrnými květy lnu se zlatými středy."
    },
    {
        obec: "Suchý Důl",
        kod: 574490,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54962,
        latitude: 50.540173,
        longitude: 16.265476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Sdul_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový černý se žlutým puklým pohárem a vlající žlutý s vykořeněnou lípou přirozených barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatém polceném štítě vpravo zlatý puklý pohár vlevo vykořeněná lípa přirozených barev."
    },
    {
        obec: "Sukorady",
        kod: 536768,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.426341,
        longitude: 15.029101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Sukorady_%28okres_Mlad%C3%A1_Boleslav%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a žlutý, v poměru 1 : 3 : 1. V zeleném pruhu žlutý obilný klas přeložený zkříženými bílými sekerami se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý obilný klas přeložený zkříženými stříbrnými sekerami se zlatými topůrky."
    },
    {
        obec: "Sulejovice",
        kod: 565695,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41111,
        latitude: 50.498891,
        longitude: 14.037519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Sulejovice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými dotýkajícími se osmicípými hvězdami pod sebou a žluto-červeně šachované orlí křídlo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři dotýkající se zlaté hvězdy pod sebou a zlato-červeně šachované orlí křídlo."
    },
    {
        obec: "Sulice",
        kod: 538833,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25168,
        latitude: 49.925727,
        longitude: 14.556739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Sulice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a modrý, v poměru 2 : 1 : 3. V červeném pruhu čtyři vztyčené žluté lipové listy, v modrém pruhu žlutá loďka přeložená bílým mečem se žlutým jílcem hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlnitě dělený štít. Horní pole červeno-stříbrně cimbuřově děleno, nahoře čtyři vztyčené zlaté lipové listy. V dolním modrém poli zlatá loďka přeložená postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Sulíkov",
        kod: 582417,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.544691,
        longitude: 16.489716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Sul%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "List dělený žlutým ondřejským křížem s rameny širokými jednu osminu šířky listu. Žerďové a vlající pole tvoří pět vodorovných pruhů, modrý, bílý, červený, bílý a modrý, v poměru 2:1:1:1:2. Horní a dolní pole modrá, každé s bílým lipovým listem, nahoře vztyčeným, dole svěšeným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Sníženě dělený štít, nahoře v modrém poli stříbrný větrný mlýn se zlatými lopatkami, černými dveřmi a červenou kuželovou střechou se zlatou makovicí, provázený dvěma položenými odvrácenými stříbrnými lipovými listy, dolní pole polcené, vpravo třikrát stříbrno-modře dělené, vlevo červené, uprostřed zlatá dóza na masti."
    },
    {
        obec: "Sulkovec",
        kod: 596833,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.60943,
        longitude: 16.309674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Sulkovec_CoA.JPG",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 3. Z žerďové poloviny zeleného pruhu vyrůstají tři zelené jehličnaté stromy s hnědými kmeny, v zeleném pruhu pod prostředním stromem bílý květ přirozené lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě tři jehličnaté stromy přirozené barvy vyrůstající ze zeleného vrchu, v něm stříbrný květ přirozené lilie."
    },
    {
        obec: "Sulimov",
        kod: 589055,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.226782,
        longitude: 17.422982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Sulimov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V bílém dvě zkřížené modré motyky s černými násadami. V modrém tři (2+1) žluté osmicípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře zkřížené modré motyky s černými násadami, dole tři (2,1) zlaté hvězdy."
    },
    {
        obec: "Sušice",
        kod: 518026,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.486027,
        longitude: 17.537486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Su%C5%A1iceznak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zeleno-bíle polcený a červený. Do zeleného pole vyrůstá žlutá lípa, v bílém poli černá plamenná orlice s červenou zbrojí, žlutým zobákem a žlutým jetelovitě zakončeným perizoniem. V červeném pruhu bílá růže s červeným semeníkem a zelenými kališními lístky, podložená zkříženými žlutými cepy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně sníženě děleném štítě vpravo nahoře vyrůstající zlatá lípa, uprostřed zlatá žerď se stříbrným praporcem s ocasem, na černá plamenná orlice s červenou zbrojí, zlatým zobákem a zlatým jetelovitě zakončeným perizoniem, dole přeložená zkříženými zlatými cepy a stříbrnou růží s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Sulislav",
        kod: 561231,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.748658,
        longitude: 13.072824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Sulislav_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, modrý, žlutý, modrý, bílý oboustranně zubatý posunutý, modrý, žlutý a modrý, v poměru 2:1:6:3:3:1:2. V modrém pruhu nad zubatým pruhem žlutá lilie mezi dvěma odvrácenými čtvercovými praporci s ocasy na žlutých žerdích vycházejících z vnějších krajů prostředních zubů. List praporců tvoří červený žerďový pruh široký polovinu délky listu a šest vodorovných pruhů střídavě bílých a červených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dolů obrácený zlatý rošt, z něhož vyrůstají na zlatých žerdích dva odvrácené praporce s ocasy, pravý polcený, vpravo 5x stříbrno-červeně dělený a vlevo červený, levý naopak, mezi nimi zlatá lilie. Vše přeloženo stříbrným cimbuřovým břevnem."
    },
    {
        obec: "Supíkovice",
        kod: 541117,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79051,
        latitude: 50.297785,
        longitude: 17.255253,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Sup%C3%ADkovice_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list uprostřed s červenou růží s bílým semeníkem a zelenými kališními lístky nad červenou kamenickou palicí a s polovinou slezské orlice hledící k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vpravo červená růže se stříbrným semeníkem a zelenými kališními lístky nad červenou kamenickou palicí, vlevo polovina slezské orlice."
    },
    {
        obec: "Sušice",
        kod: 592650,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68704,
        latitude: 49.122346,
        longitude: 17.452331,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Su%C5%A1ice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 3:2. Do modrého pruhu vyrůstá žlutý obilný snop provázený dvěma bílými vinnými hrozny, ke kterému přiléhá v červeném pruhu dolní polovina bílé lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně sníženě děleném štítě nahoře zlatý vyrůstající obilný snop provázený dvěma stříbrnými vinnými hrozny, dole k snopu přiléhající dolní polovina stříbrné lilie."
    },
    {
        obec: "Sušice",
        kod: 557153,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.231776,
        longitude: 13.520509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Su%C5%A1ice-znak.svg"
    },
    {
        obec: "Svárov",
        kod: 599433,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.062028,
        longitude: 14.15064,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Svarov_KL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, černý a žlutý, v poměru 2 : 1 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zlatě polceném štítě vynikají vpravo půl stříbrného orla se zlatou zbrojí, jetelovitě zakončeným perizoniem a červeným jazykem, vlevo půl černé volské hlavy."
    },
    {
        obec: "Svárov",
        kod: 592668,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.116539,
        longitude: 17.62464,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Sv%C3%A1rov_%28Uhersk%C3%A9_Hradi%C5%A1t%C4%9B_District%29_CoA.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným ondřejským křížem s rameny širokými jednu osminu šířky listu, překrytým modrým kosočtvercem dotýkajícím se středů všech okrajů listu. Uprostřed dvě zkřížené orientální šavle, kosmá žlutá, šikmá bílá, hroty nahoru a ostřím ven. Nad a pod šavlemi po šesticípé hvězdě, po stranách po vinném hroznu, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zeleného trojvrší dvě obrněná rámě s dolů zkříženými orientálními šavlemi, pravé zlaté, levé stříbrné. Nahoře vinný hrozen provázený dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Svatá",
        kod: 531791,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.939239,
        longitude: 13.961726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Svat%C3%A1_BE_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďové půlkruhové žluté pole, v něm černý dvojitý klín s vrcholy v pětině délky listu a v třetinách šířky listu, a dva vodorovné pruhy, červený a zelený. V červeném kosmo mlátek šikmo podložený želízkem, v zeleném šikmo vztyčený dubový list, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně polcený štít se zlatou obloukovou patou, v ní dva spojené snížené černé hroty. V pravém poli kosmo mlátek šikmo podložený želízkem, v levém poli šikmo vztyčený dubový list, vše stříbrné."
    },
    {
        obec: "Svatá Maří",
        kod: 550558,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38501,
        latitude: 49.064631,
        longitude: 13.831531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Svat%C3%A1_Ma%C5%99%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem na vlajícím okraji se třemi zelenými vykořeněnými smrky, zmenšujícími se k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně kosmo děleném štítě vpravo stříbrný kostel s červenou střechou, s věží po pravé straně, zakončenou černou makovicí s křížem. Ve věži tři obdélná okna, v lodi tři gotická okna, mezi prvním a druhým otevřený vchod s lomeným obloukem a otevřenými stříbrnými vraty s černým kováním. Vlevo tři zelené vykořeněné smrky vedle sebe."
    },
    {
        obec: "Svatava",
        kod: 538434,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35703,
        latitude: 50.192341,
        longitude: 12.625358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Svatava_SO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený v poměru 7:17 a vodorovný bílý zvlněný pruh na dolním okraji široký tři osminy šířky listu se třemi vrcholy a dvěma prohlubněmi. Na rozhraní červeného a zeleného pruhu žlutá lilie s prostředním horním listem nahrazeným bílou růží se žlutým semeníkem vyrůstající na žlutém stonku se dvěma listy. Pod lilií v bílém pruhu černá hornická kladívka na hnědých násadách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít sníženě vlnitě dělený, nahoře v červeno-zeleně polceném poli zlatá lilie s prostředním horním listem nahrazeným stříbrnou růží se zlatým semeníkem vyrůstající na zlatém stonku se dvěma listy. V dolním stříbrném poli černá hornická kladívka na topůrkách přirozené barvy."
    },
    {
        obec: "Svaté Pole",
        kod: 541389,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.751419,
        longitude: 14.169084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/29/Svat%C3%A9_Pole_znak.jpg",
        coatOfArmsFlagDescription: "List dělený vlnkovitým řezem, vycházejícím z horního rohu do sedmé osminy vlajícího okraje listu, na bílé žerďové a modré vlající pole. V bílém poli kosmo modrá sekera-bradatice ostřím k žerďovému rohu, v modrém poli žlutý berličkový kříž přeložený kosmo bílou čtyřlistou růží se žlutým semeníkem a bílými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnkovitě kosmo děleném štítě nahoře zlatý berličkový kříž, přeložený kosmo položenou stříbrnou čtyřlistou růží se zlatým semeníkem a stříbrnými kališními lístky, dole kosmo modrá sekera-bradatice."
    },
    {
        obec: "Svatobořice-Mistřín",
        kod: 586625,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69604,
        latitude: 48.977127,
        longitude: 17.089396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Svatoborice-Mistrin_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, modrý a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou s lilií provázenou dvěma houslemi, vše stříbrné, modro-stříbrně polcený štít. V pravém poli stříbrná vykořeněná lípa, v levém poli 3 (2,1) zelené vinné listy."
    },
    {
        obec: "Svatojanský Újezd",
        kod: 573302,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50781,
        latitude: 50.42605,
        longitude: 15.543039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Svatojansk%C3%BD_%C3%9Ajezd_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým křížem s rameny širokými jednu osminu šířky listu a ve druhé třetině délky listu podloženým bílým svislým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, širokým jednu osminu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý latinský rákosový převázaný kříž do krajů, podložený dole stříbrným vlnitým břevnem."
    },
    {
        obec: "Svatoslav",
        kod: 583928,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.30187,
        longitude: 16.308896,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Svatoslav_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílo-červeně šachovaný (4x2) žerďový pruh široký jednu třetinu délky listu a osm střídavě bílých a červených vodorovných pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad zeleným trojvrším s hořící stříbrnou svíčkou ve zlaté miskovité lampičce s ouškem stříbrná monstrance se zlatými paprsky."
    },
    {
        obec: "Svatoňovice",
        kod: 547131,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74787,
        latitude: 49.793607,
        longitude: 17.667329,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Svato%C5%88ovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s vlnitým vodorovným žlutým pruhem se třemi vrcholy a dvěma prohlubněmi, vycházejícím ze sedmé osminy žerďového okraje. Na prostředním vrcholu plave k vlajícímu okraji bílá labuť s červeným zobákem s černým hrbolem. V žerďové části tři žluté pětilisté květy, dva vedle sebe v horním rohu a třetí pod prvním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatým obilným snopem mezi dvěma přivrácenými prohnutými stříbrnými stonky se třemi zlatými pětilistými květy dvě zlatá vlnitá břevna, na horním plave doleva labuť přirozené barvy."
    },
    {
        obec: "Svatoslav",
        kod: 591793,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67507,
        latitude: 49.31921,
        longitude: 15.848599,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Znak_obce_Svatoslav_u_T%C5%99eb%C3%AD%C4%8De.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými jednu desetinu šířky listu. Mezi rameny po jednom žlutém šestilistém květu s červeným středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě zkřížené vztyčené stříbrné lopaty provázené čtyřmi zlatými šestilistými květy s červenými středy."
    },
    {
        obec: "Svatý Jan",
        kod: 541397,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26256,
        latitude: 49.636938,
        longitude: 14.311096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Sv_jan_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý. V žerďové části modrého pruhu pět žlutých osmicípých hvězd do kruhu (1,2,2). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný kostel s věží s červenou střechou a bání zakončenou zlatou makovicí. Ve věži dvě okna a dveře, v lodi tři okna, vše černé. Nad střechou lodi pět zlatých osmihrotých hvězd do kruhu."
    },
    {
        obec: "Svatý Jan nad Malší",
        kod: 545104,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37323,
        latitude: 48.824139,
        longitude: 14.508621,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Znak_obce_Svat%C3%BD_Jan_nad_Mal%C5%A1%C3%AD.jpg",
        coatOfArmsFlagDescription: "Modrý list s pěti žlutými hvězdami uprostřed ve vodorovné řadě. V horní a dolní části listu po dvou vodorovných bílých pruzích, do středu listu o třech vrcholech a čtyřech prohlubních. Poměr modrých a bílých pruhů na žerďovém i vlajícím okraji je 2:3:1:1:10:1:1:3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná hlava kněze s černými vousy a biretem, obklopená pěti zlatými pětihrotými hvězdami."
    },
    {
        obec: "Svatý Jan pod Skalou",
        kod: 531804,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 49.969011,
        longitude: 14.133272,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Svaty_Jan_pod_Skalou_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý. V modrém pruhu bílý řecký kříž mezi osmicípou hvězdou a hlavicí berly závitem k žerďovému okraji, obojí žluté. V žlutém pruhu černá kančí hlava s bílou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté dvojvrší s černou kančí hlavou se stříbrnou zbrojí a červeným jazykem, z levého vyššího vrchu vyrůstá stříbrný latinský kříž, pod rameny provázený vpravo osmihrotou hvězdou, vlevo hlavicí berly, obojí zlaté."
    },
    {
        obec: "Svatý Jiří",
        kod: 580708,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 49.972104,
        longitude: 16.270429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Svat%C3%BD_Ji%C5%99%C3%AD.svg",
        coatOfArmsFlagDescription: "Bílý list s červeným středovým křížem s rameny širokými jednu čtvrtinu šířky listu, v něm žlutý středový kříž s rameny širokými jednu třetinu šířky ramene červeného kříže. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený heroldský kříž, v něm zlatá rozletitá střela s kruhem uprostřed a berlou místo horní střely."
    },
    {
        obec: "Svatý Mikuláš",
        kod: 534196,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.991014,
        longitude: 15.350597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Svaty_Mikulas_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, bílý a červený v poměru 4:1:1. Na modrém a bílém pruhu dva žlutí vztyčení medvědi s bílými zuby, červeným jazykem a drápy, držící bílou, žlutě zdobenou a červeně podšitou mitru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modrý štít se stříbrným sníženým vydutým hrotem se třemi (1,2) červenými jablky. Ve štítě dva zlatí vztyčení medvědi s červenou zbrojí, držící nad hrotem stříbrnou, zlatě zdobenou mitru."
    },
    {
        obec: "Svébohov",
        kod: 541125,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.92004,
        longitude: 16.839277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Coat_of_arms_of_Sv%C3%A9bohov.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 10 : 2 : 1. V horním zeleném pruhu Boží oko obklopené do oblouku dvanácti osmicípími hvězdami hrotem k hornímu okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným trojvrším Boží oko obklopené do oblouku dvanácti osmihrotými hvězdami, vše zlaté."
    },
    {
        obec: "Svémyslice",
        kod: 598305,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25091,
        latitude: 50.146005,
        longitude: 14.648214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Coats_of_arms_Sv%C3%A9myslice.jpeg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh, široký jednu třetinu délky listu, svislý červený pruh, široký jednu osminu šířky listu a pět vodorovných pruhů, modrý, bílý, červený, bílý a modrý, v poměru 1:2:2:2:1. Ve žlutém pruhu půl černého orla s červenou zbrojí a jazykem, který přiléhá k červenému pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě polceném štítě vpravo půl červeného heroldského kříže a nahoře modrá, zlatě lemovaná mitra, vlevo ke kříži přiléhá půl černého orla s červenou zbrojí a jazykem."
    },
    {
        obec: "Svépravice",
        kod: 598763,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.495694,
        longitude: 15.232792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Sv%C3%A9pravice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný zelený, v poměru 4 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrné stisknuté pravice vyrůstající ze stran štítu nad zelenou patou oddělenou stříbrným vlnitým břevnem."
    },
    {
        obec: "Svéradice",
        kod: 557161,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.371445,
        longitude: 13.739694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Sv%C3%A9radice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý žlutý a zvlněný zelený v poměru 11:3:11. V červeném pruhu tři obilné klasy, v zeleném šikmo lipová ratolest se třemi listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně šikmým vlnitým břevnem děleném štítě vpravo tři obilné klasy, vlevo lipová ratolest, vše zlaté."
    },
    {
        obec: "Svésedlice",
        kod: 552429,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78354,
        latitude: 49.572579,
        longitude: 17.381252,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Svesedlice_znak.jpeg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, červený, bílý a zelený, v poměru 5 : 1 : 3 : 1 : 5. V zelených pruzích žlutý obilný klas se dvěma svěšenými listy, v červeném tři stejné bílé trojúhelníky nad sebou se základnami na dolním okraji pruhu a ve třetinách šířky listu a s vrcholy ve třetinách šířky listu a na horním okraji pruhu. Poměr šířky k délce pruhu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě červený stříbrně lemovaný kůl se třemi stříbrnými kužely nad sebou. Kůl je provázen dvěma zlatými obilnými klasy, každý se dvěma svěšenými listy."
    },
    {
        obec: "Světce",
        kod: 561029,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.270655,
        longitude: 14.941505
    },
    {
        obec: "Světec",
        kod: 567841,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41753,
        latitude: 50.576236,
        longitude: 13.811707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Sv%C4%9Btec_%28okres_Teplice%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů střídavě černých a bílých. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-černě polcený štít, vpravo na zeleném trojvrší zelený listnatý strom s hnědým kmenem, vlevo dvě stříbrná břevna."
    },
    {
        obec: "Světí",
        kod: 548154,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50312,
        latitude: 50.257478,
        longitude: 15.775579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/CoA_of_Sv%C4%9Bt%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině tři vodorovné pruhy, černý, bílý a červený a vlající bílý pruh široký polovinu délky listu. K vodorovným pruhům přiléhá ve svislém pruhu půl černé zubří hlavy se žlutým rohem a houžví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole dvakrát černo-stříbrno-červeně děleno, v levém stříbrném poli vyniká z poltící linie půl černé zubří hlavy se zlatou zbrojí a houžví."
    },
    {
        obec: "Světice",
        kod: 538841,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.970179,
        longitude: 14.665911,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/CoA_of_Sv%C4%9Btice.svg",
        coatOfArmsFlagDescription: "Zelený list s kráčejícím žlutým pštrosem držícím v zobáku bílou dýku se žlutou záštitou a hlavicí, hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý kráčející pštros držící v zobáku postavenou stříbrnou dýku se zlatou záštitou a hlavicí."
    },
    {
        obec: "Světlá",
        kod: 586064,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67963,
        latitude: 49.565958,
        longitude: 16.718297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Sv%C4%9Btl%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu třetinu délky listu se žlutou berlou závitem k vlajícímu okraji a s bílým sudariem a bílé pole se dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky nad červeným srdcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatou položenou berlou se stříbrným sudariem, vrcholem doprava a závitem nahoru, červené srdce, z něhož na zelených stoncích vyrůstají dvě červené růže se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Světlá Hora",
        kod: 597872,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79331,
        latitude: 50.046192,
        longitude: 17.399982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Svetla_Hora_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem v horním žerďovém rohu a bílým dorůstajícím půlměsícem ve vlající části v prodloužení dolního cípu půlměsíc obloukově tři bílé šesticípé hvězdy, s poslední ve středu dolní části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V pravém horním rohu modrého štítu zlaté slunce, pod ním do oblouku tři hvězdy a dorůstající měsíc, vše stříbrné."
    },
    {
        obec: "Světlá nad Sázavou",
        kod: 569569,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.66811,
        longitude: 15.404038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Svetla_nad_Sazavou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Barva štítu přechází do dolního pruhu a barva znaku do horního pruhu. V případě znaku Světlé nad Sázavou je štít červený a znak stříbrný. Na praporu přechází barva bílá k barvě červené, tj. červená dole, stříbrná, v případě praporu bílá, nahoře. Oba pruhy jsou stejně široké."
    },
    {
        obec: "Světlá pod Ještědem",
        kod: 564427,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46343,
        latitude: 50.711344,
        longitude: 14.986005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Sv%C4%9Btl%C3%A1_pod_Je%C5%A1t%C4%9Bdem_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu kráčející bílý lev se žlutou zbrojí držící shora pravou přední tlapou bílý maltézský kříž. Z dolního okraje listu vyrůstá zelený vydutý klín s vrcholem na dolním okraji červeného pruhu, nahoře kosmo a šikmo provázený dvěma zelenými lipovými listy s plodenstvími k sobě. V klínu tři (1, 2) jablka se dvěma listy na stopce, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně dělený štít. Nahoře kráčející stříbrný lev se zlatou zbrojí, držící shora pravou přední tlapou stříbrný maltézský kříž. Dole zelený vydutý hrot se třemi (1, 2) zlatými jablky, každé se dvěma listy na stopce provázený dvěma odkloněnými zelenými lipovými listy s plodenstvími k sobě."
    },
    {
        obec: "Světlík",
        kod: 545813,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38216,
        latitude: 48.731761,
        longitude: 14.21107,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Wappen_svetlik.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, zelený, bílý a červený, v poměru 1 : 1 : 4 : 1 : 1. V zeleném pruhu zkřížené žluté odvrácené berly s bílými sudarii se žlutým třepením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě snížený stříbrný hrot s červenou růží se zlatým semeníkem. Nad hrotem zkřížené zlaté odvrácené berly se stříbrnými sudarii se zlatým třepením, mezi jejich hlavami vykračující stříbrný kohout s červenou zbrojí a ocasem."
    },
    {
        obec: "Světnov",
        kod: 596841,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59102,
        latitude: 49.620631,
        longitude: 15.955318,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Sv%C4%9Btnov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a červený, v poměru 3 : 4 : 3. V žerďovém pruhu zelená jedle, v modrém milíř podložený kosmo vztyčenou lopatou a šikmo sekerou ostřím k dolnímu cípu listu, vše bílé a převýšené žlutou lilií. V červeném pruhu sedící žlutý lev s bílou zbrojí a jazykem, s hlavou odvrácenou k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít rozdělen sníženým vydutým hrotem, v pravém zlatém poli vyrůstající jedle přirozené barvy, v levém červeném poli sedící odvrácený zlatý lev se stříbrnou zbrojí a jazykem, v dolním modrém poli milíř podložený kosmo vztyčenou lopatou a šikmo sekerou, vše stříbrné a převýšené zlatou lilií."
    },
    {
        obec: "Sviadnov",
        kod: 569631,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73925,
        latitude: 49.689354,
        longitude: 18.327726,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Sviadnov_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: modrý, bílý a červený v poměru 2:1:2. V modrém a červeném pruhu žlutá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně dělený štít, v horní části na dělící čáře stříbrná kvádrová věž s cimbuřím provázená dvěma zlatými svatojakubskými mušlemi, v dolní části tři stříbrné kužely."
    },
    {
        obec: "Svídnice",
        kod: 548693,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.086151,
        longitude: 16.223384,
        coatOfArmsFlagDescription: "Bílý list s modrým dolním klínem, v něm žlutý obilný snop. V bílých polích vztyčená větvička svídy s černými plody a třemi zelenými listy, v žerďovém šikmo, ve vlajícím kosmo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý vydutý hrot se zlatým obilným snopem, provázený vztyčenými přivrácenými větvičkami svídy s plody a třemi listy, vše přirozené barvy."
    },
    {
        obec: "Svídnice",
        kod: 572331,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53824,
        latitude: 49.890007,
        longitude: 15.813804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Sv%C3%ADdnice_CR_CoA.jpg",
        coatOfArmsFlagDescription: "List šikmo bílo-modře vlnkovitě dělený. V bílém poli kosmo větévka svídy krvavé s plody, v modrém poli žlutá svatojakubská mušle. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo vlnkovitě děleném štítě nahoře kosmo vztyčená větévka svídy krvavé s plody přirozené barvy, dole zlatá svatojakubská mušle"
    },
    {
        obec: "Svijanský Újezd",
        kod: 564435,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46345,
        latitude: 50.587332,
        longitude: 15.043456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Svijansk%C3%BD_%C3%9Ajezd_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a modrý, v poměru 1:6:1. Ve žlutém pruhu polovina modrého lva s červenou zbrojí držícího v pravé tlapě zelený stonek lnu se třemi modrými květy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě polovina modrého lva s červenou zbrojí držícího v pravé tlapě stonek lnu se třemi květy přirozené barvy."
    },
    {
        obec: "Svijany",
        kod: 564443,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46346,
        latitude: 50.572684,
        longitude: 15.063976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Svijany_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Žlutý list s modrým korunovaným lvem se červenou zbrojí a s černým svislým pruhem vycházejícím z druhé jedenáctiny horního a dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě s černým kůlem vpravo modrý korunovaný lev s červenou zbrojí."
    },
    {
        obec: "Svinaře",
        kod: 531812,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26728,
        latitude: 49.891728,
        longitude: 14.1844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Svina%C5%99e_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a modré pole. V bílém pruhu modrý meč se žlutým jílcem hrotem nahoru, přeložený červenou, žlutě lemovanou knížecí čepicí. V modrém poli bílá hlava býka s krkem, žlutými rohy, červenými nozdrami a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vpravo polceném štítě vpravo vztyčený modrý meč se zlatým jílcem přeložený červenou, zlatě lemovanou knížecí čepicí, vlevo stříbrná hlava býka s krkem se zlatými rohy červenými nozdrami a jazykem."
    },
    {
        obec: "Svinařov",
        kod: 532908,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27305,
        latitude: 50.181412,
        longitude: 14.050377,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Svinarov_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se dvěma ondřejskými kříži žlutým a bílým. Kosmé rameno žlutého vychází z první čtvrtiny horního okraje listu do čtvrté pětiny vlajícího okraje a šikmé rameno ze čtvrté pětiny žerďového okraje do čtvrté čtvrtiny horního okraje. Kosmé rameno bílého kříže vychází z druhé pětiny žerďového okraje do čtvrté čtvrtiny dolního okraje a šikmé rameno z první čtvrtiny dolního okraje do druhé pětiny vlajícího okraje. Šikmé rameno žlutého kříže překrývá kosmé rameno bílého a šikmé rameno bílého kříže překrývá kosmé rameno žlutého. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené zlaté vztyčené odvrácené klíče proložené vztyčenými stříbrnými sekerami."
    },
    {
        obec: "Svinčany",
        kod: 575771,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.976687,
        longitude: 15.639615,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Znak_obce_Svin%C4%8Dany.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy - žlutý, černý a žlutý. Ve středu černého pruhu bílý zvon z obecního znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě černá kančí (sviní) hlava, v černé patě štítu stříbrný zvon."
    },
    {
        obec: "Svinošice",
        kod: 582433,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.334534,
        longitude: 16.576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Svinosice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno- bílo-žluto-modře čtvrcený list. V červeném poli bílá lilie, v modrém poli žlutý vykořeněný dub. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře stříbrná lilie, dole na trávníku dub, obojí zlaté."
    },
    {
        obec: "Sviny",
        kod: 553182,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.187242,
        longitude: 14.63601,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Sviny_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, od žerďového okraje vyniká do pětiny délky listu šest žlutých plamenů. Ve střední a vlající části listu převrácená bílá růže se žlutým semeníkem a žlutými kališními lístky, podložená zkříženými bílými kopími, žlutými hroty k dolnímu okraji listu, a obklopená pěti (1, 2, 2) žlutými šesticípými hvězdami do kruhu tak, že kališní lístky směřují mezi dva cípy hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě převrácená stříbrná růže se zlatým semeníkem a zlatými kališními lístky podložená zkříženými stříbrnými kopími, zlatými hroty dolů, a obklopená pěti (1, 2, 2) zlatými hvězdami do kruhu. V patě štítu šest zlatých plamenů."
    },
    {
        obec: "Sviny",
        kod: 596850,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59451,
        latitude: 49.364289,
        longitude: 16.085112,
        coatOfArmsFlagDescription: "Zelený list se žlutým tlapatým latinským křížem, provázeným mezi rameny čtyřmi bílými čtyřlistými růžemi se žlutými semeníky a kališními lístky, nad bílým půlměsícem s tváří, cípy k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý tlapatý latinský kříž, provázený mezi rameny čtyřmi stříbrnými čtyřlistými růžemi se zlatými semeníky a kališními lístky, nad stříbrným stoupajícím půlměsícem s tváří."
    },
    {
        obec: "Svitávka",
        kod: 582441,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67932,
        latitude: 49.501876,
        longitude: 16.598037,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Svitavka_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List je rozdělen šikmým černým pruhem o šířce jedné poloviny šířky listu na modré žerďové a žluté vlající pole. V černém pruhu tři šikmo položené růže se žlutými semeníky, červená mezi bílými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře ve zlatém poli od pasu vyrůstající svatý Štěpán se zelenou palmovou ratolestí v pravé a se třemi kameny v levé ruce. Dolní pole modro-zlatě šikmo rozdělené černým břevnem, v němž tři růže, červená mezi stříbrnými."
    },
    {
        obec: "Svitavy",
        kod: 577731,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56802,
        latitude: 49.755263,
        longitude: 16.469286,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Svitavy_znak_2.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, žlutý, modrý a bílý v poměru 5:1:2:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba se čtyřmi stínkami, s otevřenou branou se zlatými vraty a černým kováním. Z hradby vynikají dvě pootočené čtyřhranné dvoupatrové věže, každá se dvěma černými okny nad sebou v levé části. Věže mají červené stanové střechy se zlatými makovicemi, mezi věžemi zlatá hlava tura domácího s krkem."
    },
    {
        obec: "Svoboda nad Úpou",
        kod: 579734,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54224,
        latitude: 50.626061,
        longitude: 15.816588,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Svoboda_nad_%C3%9Apou%2C_m%C4%9Bstsk%C3%BD_znak.jpg"
    },
    {
        obec: "Svobodné Heřmanice",
        kod: 597881,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79313,
        latitude: 49.946147,
        longitude: 17.674365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Coat_of_arms_of_Svobodn%C3%A9_He%C5%99manice.svg",
        coatOfArmsFlagDescription: "Zeleno-bíle čtvrcený list s bílou radlicí v horním žerďovém poli, zelenou šesticípou hvězdou v dolním žerďovém a horním vlajícím poli a bílou šesticípou hvězdou v dolním vlajícím poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-stříbrně čtvrcený štít, v prvním poli postavená radlice, v ostatních polích po jedné hvězdě, vše opačných barev."
    },
    {
        obec: "Svojanov",
        kod: 578843,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56973,
        latitude: 49.624723,
        longitude: 16.410306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Svojanov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list s dvouocasým vzpřímeným kráčejícím lvem v žerďové polovině u horního okraje listu a se šesti vlajícími klíny s vrcholy ve druhé třetině délky listu, vše bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Svojek",
        kod: 577561,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 50791,
        latitude: 50.553516,
        longitude: 15.455663,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Svojek_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 5:3. Ze druhé až šesté dvanáctiny horního okraje zeleného pruhu vyniká zelený vrch a na něm tři bílé přirozené lilie na stoncích s listy mezi dvěma žlutými obilnými klasy, oba s listem a odkloněné. Pod vrchem v zeleném pruhu zkřížené stříbrné hasičské sekerky se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném vrchu se zkříženými stříbrnými hasičskými sekerkami se zlatými topůrky, tři přirozené lilie na stoncích s listy mezi dvěma zlatými obilnými klasy, oba s listem a odkloněné."
    },
    {
        obec: "Svojetice",
        kod: 538850,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25162,
        latitude: 49.971526,
        longitude: 14.7386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Svojetice-znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, v poměru 1 : 2. V zeleném pruhu bílá podkova, v červeném dva vlající klíny, bílý a žlutý s vrcholy na žerďovém okraji červeného pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě vyrůstají ze zeleného návrší se stříbrnou podkovou dva jehličnaté stromy, pravý stříbrný a levý zlatý."
    },
    {
        obec: "Svojetín",
        kod: 542458,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27004,
        latitude: 50.190121,
        longitude: 13.621021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Svojetin_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým chmelařským průbojem s nášlapnou příčkou k žerdi, esovitě obtočeným žlutou chmelovou révou střídavě se třemi listy a třemi šišticemi, vyrůstající z vlající strany horního okraje žlutého hrotu průboje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný chmelařský průboj s nášlapnou příčkou vpravo, esovitě obtočený zlatou chmelovou révou se třemi šišticemi a listy, vyrůstající z levého horního okraje zlatého hrotu průboje."
    },
    {
        obec: "Svojkovice",
        kod: 587991,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.166246,
        longitude: 15.635608
    },
    {
        obec: "Svojkov",
        kod: 546283,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47153,
        latitude: 50.719575,
        longitude: 14.599127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Svojkov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký jednu čtvrtinu délky listu, dělený modro-bíle v poměru 3 : 1, a dva vodorovné pruhy, bílý a černý, v poměru 1 : 3. Ve střední části listu v bílém pruhu červený lev s modrou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně vpravo polceném štítě se zvýšenou, stříbrno - černě vpravo polcenou patou, vlevo červený lev s modrou zbrojí a červeným jazykem."
    },
    {
        obec: "Svojkovice",
        kod: 541176,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33822,
        latitude: 49.759974,
        longitude: 13.648336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Svojkovice_%28Rokycany_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, černý, bílý, zelený, bílý a zelený. V horní části listu žlutá koruna s červenými kameny, v dolní části listu žluté palečné kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zeleným návrším černé břevno přeložené zlatou korunou s červenými kameny. V návrší stříbrné vlnité břevno přeložené zlatým palečným kolem."
    },
    {
        obec: "Svojšice",
        kod: 575780,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53362,
        latitude: 49.965437,
        longitude: 15.601078,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Svoj%C5%A1ice_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zubatý červený, v poměru 5:6:5. V bílém pruhu vodorovně černý šíp hrotem k žerďovému okraji. Červený pruh má tři obdélníkové a dva poloviční zuby a čtyři obdélníkové mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zelenou hlavou a červenou cihlovou cimbuřovou patou černý položený šíp."
    },
    {
        obec: "Svojšice",
        kod: 533726,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28107,
        latitude: 50.003239,
        longitude: 15.043205
    },
    {
        obec: "Svojšice",
        kod: 564273,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.572827,
        longitude: 14.042977,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Svoj%C5%A1ice_PB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, oba se žlutou osmicípou hvězdou. Uprostřed listu bílý meč se žlutým jílcem hrotem k dolnímu okraji listu, prostrčený žlutou lilií bez středních listů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě postavený stříbrný meč se zlatým jílcem prostrčený zlatou lilií bez středních listů, nahoře provázený dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Svojšín",
        kod: 561258,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.766164,
        longitude: 12.910779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Svojsin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě bílých a červených a svislý modrý pruh vycházející z druhé a třetí šestiny horního a dolního okraje listu. V modrém pruhu pod sebou dva zkřížené palcáty, koruna a šesticípá hvězda, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo tři červená břevna, vlevo dva zkřížené palcáty nad korunou a hvězdou, vše zlaté."
    },
    {
        obec: "Svor",
        kod: 562131,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47151,
        latitude: 50.7919,
        longitude: 14.596917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Svor_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, v poměru 7 : 3, bílý a zelený vlnkovaný se třemi vrcholy. V bílém pruhu pod sebou dva květy lilie zlatohlavé tyčinkami k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zeleného trojvrší lilie zlatohlavá přirozené barvy se dvěma květy nad dvěma poupaty (2, 2), provázená nahoře dvěma položenými odvrácenými zelenými lipovými listy se stříbrnými kapkami rosy."
    },
    {
        obec: "Svrabov",
        kod: 563170,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39131,
        latitude: 49.447575,
        longitude: 14.626643
    },
    {
        obec: "Svratka",
        kod: 596868,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59202,
        latitude: 49.710763,
        longitude: 16.032241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Znak_m%C4%9Bsta_Svratka.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý s černou kosmou ostrví se čtyřmi suky a bílý s vykořeněnou lípou s hnědým kmenem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Svratouch",
        kod: 572349,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53942,
        latitude: 49.72454,
        longitude: 16.034289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Svratouch_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. Do modrého pole vyrůstá bílá koňská hlava s krkem a žlutou hřívou. V horní vlající polovině šikmo žlutá radlice hrotem k dolnímu rohu a ostřím k dolnímu okraji listu, v červeném poli dvě bílé šesticípé hvězdy vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrný kůň ve skoku se zlatou zbrojí, hřívou a ocasem, nad ním v červeném poli dvě stříbrné hvězdy pod sebou, pod ním šikmo zlatá radlice."
    },
    {
        obec: "Svrkyně",
        kod: 571342,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25264,
        latitude: 50.171469,
        longitude: 14.294955,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Svrkyn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým ondřejským křížem s rameny širokými desetinu šířky listu. Uprostřed bílý archanděl Michael, se žlutými vlasy, čelenkou s křížkem, překříženou štolou na prsou a střevíci, držící v pravici meč se žlutým jílcem a s čepelí v podobě červeného plamene, v levici žlutý zvon. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný archanděl Michael, se zlatými vlasy, čelenkou s křížkem, překříženou štolou na prsou a střevíci, držící v pravici meč se zlatým jílcem a s čepelí v podobě červeného plamene, v levici zlatý zvon. V patě štítu dva vyrůstající zkřížené zlaté obilné klasy, každý s odkloněným listem."
    },
    {
        obec: "Sychrov",
        kod: 564451,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46344,
        latitude: 50.626916,
        longitude: 15.088809,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Sychrov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Uprostřed žluto-zeleně polcená hrst pěti odkloněných obilných klasů, krajní s listem, dole spojených a převázaných bílo-červeně polcenou stuhou s mašlí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě zlato-zeleně polcená hrst pěti odkloněných obilných klasů, krajní s listem, dole spojených a převázaných stříbrno-červeně polcenou stuhou s mašlí."
    },
    {
        obec: "Sýkořice",
        kod: 542466,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27024,
        latitude: 50.034689,
        longitude: 13.931831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/S%C3%BDko%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1 : 3 : 1. V horním rohu žlutá koruna. V bílém pruhu dvě odvrácené sýkory koňadry stojící na dvou odkloněných zelených lipových listech zeleného vykořeněného pařezu. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou vlnitou hlavou se zlatou korunou dvě odvrácené sýkory koňadry přirozené barvy stojící na dvou odkloněných lipových listech vykořeněného pařezu, vše zelené."
    },
    {
        obec: "Synalov",
        kod: 582450,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.432831,
        longitude: 16.413973,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Synalov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, zelený a žlutý, v poměru 2 : 3 : 2. V zeleném pruhu šikmo tři lískové oříšky na jedné stopce, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě široké šikmé zelené břevno, v něm šikmo tři stříbrné lískové oříšky na jedné stopce."
    },
    {
        obec: "Synkov-Slemeno",
        kod: 576816,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51601,
        latitude: 50.145372,
        longitude: 16.219056,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Synkov-Slemeno_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu v modrém pruhu tři žluté květy pod sebou, v červeném pruhu přiléhá k modrému pruhu půl snopu provázeného vztyčenou borovou šiškou, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo tři květy pod sebou, vlevo vyniká půl obilného snopu provázeného vlevo vztyčenou borovou šiškou, vše zlaté."
    },
    {
        obec: "Syrov",
        kod: 598771,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.578021,
        longitude: 15.177802
    },
    {
        obec: "Syrovátka",
        kod: 571008,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.154859,
        longitude: 15.662838,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/CoA_of_Syrov%C3%A1tka.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým třmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý třmen."
    },
    {
        obec: "Syrovice",
        kod: 583936,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66467,
        latitude: 49.079913,
        longitude: 16.546565,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Syrovice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Zelený list se šikmým klínem vycházejícím z poloviny žerďového a dolního okraje listu s vrcholem v horním cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šikmý zlatý hrot, v něm tři zkřížené zelené vinné ratolesti, prostřední s kořenem, listem a hroznem, kosmá s hroznem a šikmá s listem."
    },
    {
        obec: "Syrovín",
        kod: 586633,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69684,
        latitude: 49.025923,
        longitude: 17.264039,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Syrov%C3%ADn_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Uprostřed žlutý meč hrotem dolů, ke kterému přiléhají dva odvrácené vinařské nože - kosíře, bílý v červeném a červený v bílém pruhu. V oblouku ostří nožů po jednom vinném hroznu, v červeném pruhu žlutý, v bílém červený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě postavený zlatý meč provázený přiléhajícími odvrácenými vinařskými noži - kosíři opačných barev. V oblouku ostří nožů vinné hrozny, vpravo zlatý, vlevo červený."
    },
    {
        obec: "Syřenov",
        kod: 577570,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51251,
        latitude: 50.498928,
        longitude: 15.427821,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Sy%C5%99enov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modro-žlutě šikmo dělený list, uprostřed věž s cimbuřím a prázdnou branou opačných barev. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Štít dělený červeno-stříbrně děleným břevnem, nahoře ve zlatém poli kráčející modrá lvice s červenou zbrojí, dole v modro-zlatě šikmo děleném poli věž s cimbuřím a prázdnou branou opačných barev."
    },
    {
        obec: "Sytno",
        kod: 557081,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.739789,
        longitude: 13.041764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Sytno_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený se žlutou lilií a bílý, v poměru 1:2:1. Z červeného pruhu vyrůstají vodorovně dvě modré lilie dotýkající se žerďového a vlajícího okraje, každá na stonku se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě čelně vztyčená červená radlice se zlatou lilií, z níž do rohů vyrůstají dvě modré lilie, každá na stonku se dvěma listy."
    },
    {
        obec: "Šabina",
        kod: 538396,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.135748,
        longitude: 12.582432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Sabina_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové čtvrtině tři vodorovné pruhy, žlutý, modrý se žlutou lilií a žlutý, v poměru 1:2:1, a šest vodorovných pruhů střídavě zelených a bílých v poměru 15:1:1:1:1:1. V horním zeleném pruhu ve střední části listu žlutá žába. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít vpravo polcený, v pravém zlatém poli modré břevno se zlatou lilií, levé pole zeleno stříbrně sníženě dělené, nahoře zlatá žába, dole dvě zelená břevna."
    },
    {
        obec: "Šafov",
        kod: 594865,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67106,
        latitude: 48.866906,
        longitude: 15.734756,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Safov_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou kvádrovanou hradbou s prázdnou branou se žlutou vytaženou mříží, provázenou po stranách vždy dvěma černými klíčovými střílnami. Z hradby vynikají dvě bílé věže s cimbuřím, každá se třemi (2, 1) černými okny. Na hradbě mezi věžemi čelně vztyčený hnědý medvěd opírající se o věže. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Šakvice",
        kod: 584924,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69167,
        latitude: 48.897558,
        longitude: 16.714344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Coat_of_arms_of_Sakvice.jpeg",
        coatOfArmsFlagDescription: "Bílý list se třemi kosmo položenými červenými obdélníkovými poli, z nichž každé má délku jedné třetiny délky listu a šířku jedné třetiny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrný štít s červeným tlapatým heroldským křížem. V prvním poli stojící doleva obrácená černá kočka, ve druhém poli stojící čejka přirozených barev s červenou zbrojí, ve třetím poli černý, doleva obrácený lev s červenou zbrojí, ve čtvrtém poli černý papežský kříž."
    },
    {
        obec: "Šanov",
        kod: 594873,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67168,
        latitude: 48.800986,
        longitude: 16.378684,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/%C5%A0anov_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, modrý a bílý v poměru 2:3:2. Uprostřed modrého pruhu dole dva zkřížené prohnuté žluté obilné klasy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná postavená radlice mezi dvěma dole zkříženými prohnutými zlatými obilnými klasy."
    },
    {
        obec: "Šanov",
        kod: 542474,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27031,
        latitude: 50.087735,
        longitude: 13.631307,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/Coats_of_arms_%C5%A0anov.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy uprostřed černý železný klobouk se žlutým třetinovým vodorovným pruhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě černo-zlato-černě dělený železný klobouk."
    },
    {
        obec: "Šanov",
        kod: 585807,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76321,
        latitude: 49.045564,
        longitude: 17.898513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/%C5%A0anov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý klín s vrcholem ve středu vlajícího okraje a horní červené a dolní zelené trojúhelníkové pole. V klínu vodorovně, hrotem k vlajícímu okraji modrý meč se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší červený korunovaný lev se zlatou zbrojí, držící postavený modrý meč se zlatým jílcem."
    },
    {
        obec: "Šaplava",
        kod: 571016,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50353,
        latitude: 50.315142,
        longitude: 15.542057,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/CoA_of_%C5%A0aplava.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a žlutých, v poměru 12 : 1 : 1 : 1 : 1. V žerďové polovině horního pruhu pět šesticípých hvězd (1, 2, 2), mezi dolními zvon, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pět hvězd (1, 2, 2), mezi dolními zvon, pod ním dvě zúžená břevna, vše zlaté."
    },
    {
        obec: "Šaratice",
        kod: 593613,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68352,
        latitude: 49.117564,
        longitude: 16.803613,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Saratice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List je rozdělen bílým kosmým pruhem na červenou žerďovou a modrou vlající část. Bílý pruh vychází první čtvrtiny žerďového okraje a první šestiny horního okraje listu a končí na poslední čtvrtině vlajícího a poslední šestině dolního okraje listu. Středem bílého pruhu modrý vlnitý pruh s pěti vrcholy a čtyřmi prohlubněmi o šířce jedné osminy šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře polcený štít se stříbrnou patou, v ní kosmé modré vlnité břevno. Vpravo stříbrná dvoupatrová věž s černou prolomenou branou, čtyřmi okny a modrou valbovou střechou se dvěma zlatými křížky. Vlevo půl stříbrného vozového kola vynikajícího z poltící linie."
    },
    {
        obec: "Šardice",
        kod: 586641,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69613,
        latitude: 48.964128,
        longitude: 17.028222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/%C5%A0ardice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený, v poměru 2:1:1. V modrém pruhu žlutá vinná ratolest se dvěma hrozny k žerďovému a dvěma listy k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli zlatá vinná ratolest se dvěma hrozny a dvěma listy, levé pole červeno-stříbrně dělené, nahoře stříbrný vinařský nůž - kosíř, dole kosmý mlátek, šikmo přeložený želízkem, obojí černé."
    },
    {
        obec: "Šárovcova Lhota",
        kod: 573256,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50759,
        latitude: 50.407609,
        longitude: 15.562386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/CoA_of_%C5%A0%C3%A1rovcova_Lhota.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým trojúhelníkem s vrcholy na středu horního okraje, v dolním rohu a v dolním cípu. Uprostřed trojúhelníka vztyčený zelený javorový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý rovnostranný trojúhelník vrcholem vzhůru, v něm vztyčený zelený javorový list."
    },
    {
        obec: "Šarovy",
        kod: 585815,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76351,
        latitude: 49.148682,
        longitude: 17.606966,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/CoA_%C5%A0arovy.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, oboustranně zubatý modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žlutá krokvice zakončená nahoře makovicí s červeno-bíle děleným praporkem - plamenem. Po stranách nahoře je krokvice postrkaná dvěma žlutými dubovými dvoulisty s žaludem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou stříbrnou kvádrovanou hradbou zlatá krokvice zakončená nahoře zlatou makovicí a doleva obráceným červeno-stříbrně děleným praporkem - plamenem. Po stranách nahoře je krokvice postrkaná dvěma zlatými dubovými dvoulisty s žaludem."
    },
    {
        obec: "Šelešovice",
        kod: 557188,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76701,
        latitude: 49.254875,
        longitude: 17.36013,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/%C5%A0ele%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, přes které je položen svislý modrý pruh široký polovinu délky listu. V modrém pruhu bílý kolčí helm, klenotem prázdný bílý kotouč se třemi zelenými pavími pery, červeno-bílá přikrývka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole červeno-stříbrno-červeně dělené se třemi položenými radlicemi opačných barev, v levém modrém poli stříbrný kolčí helm, klenotem prázdný stříbrný kotouč se třemi zelenými pavími pery, červeno-stříbrná přikrývka."
    },
    {
        obec: "Šebestěnice",
        kod: 531421,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.829543,
        longitude: 15.365413,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/%C5%A0ebest%C4%9Bnice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a modrý. Uprostřed listu zkřížené žluté šípy s bílými hroty a opeřením nad žlutou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě zkřížené zlaté šípy se stříbrnými hroty a opeřením nad zlatou lilií."
    },
    {
        obec: "Šedivec",
        kod: 581038,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.04929,
        longitude: 16.535815,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/%C5%A0edivec.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým daňkem ve skoku se žlutou zbrojí a červeným jazykem, držící červený hradební žebřík se třemi žlutými stupni. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný daněk ve skoku se zlatou zbrojí a červeným jazykem, držící červený hradební žebřík se třemi zlatými stupni."
    },
    {
        obec: "Šebkovice",
        kod: 591807,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67545,
        latitude: 49.123204,
        longitude: 15.813715,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/%C5%A0ebkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s vykořeněnou bílou lípou, mezi kořeny žlutá nádoba na masti. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná vykořeněná lípa, mezi kořeny zlatá nádoba na masti."
    },
    {
        obec: "Šemnice",
        kod: 555614,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36272,
        latitude: 50.237016,
        longitude: 12.976213,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/%C5%A0emnice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, zelený, bílý a zelený, v poměru 2 : 1 : 1 : 2. Ve žlutém pruhu červený lev s modrou zbrojí a jazykem, s ocasem staženým mezi nohy, držící levou přední tlapou černé hrábě. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě vpravo červený lev s modrou zbrojí a jazykem, ocasem staženým mezi nohy, držící levou tlapou černé hrábě, vlevo stříbrný vlnitý kůl."
    },
    {
        obec: "Šebetov",
        kod: 582468,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67935,
        latitude: 49.548719,
        longitude: 16.711728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Sebetov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: červený s bílou, žlutě zdobenou mitrou a žlutý se čtyřmi čtvercovými zuby sahajícími do poloviny šířky listu a třemi modrými čtvercovými mezerami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě široké červené břevno se stříbrnou zlatě zdobenou mitrou, nahoře zlatá kvádrová zeď se čtyřmi červeně krytými stínkami, pod ní položená stříbrná radlice."
    },
    {
        obec: "Šatov",
        kod: 594881,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67122,
        latitude: 48.793274,
        longitude: 16.010024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Satov_znak.jpg"
    },
    {
        obec: "Šebrov-Kateřina",
        kod: 582476,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.328598,
        longitude: 16.602548,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Sebrov-Katerina_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Ve žlutém pruhu zelená vykořeněná lípa s hnědým kmenem. V modrém pruhu stojící bíle oděná sv. Kateřina, držící v pravé ruce zelenou palmovou ratolest a levou rukou bílý meč hrotem dolů se žlutou záštitou a žlutou hlavicí zakončeným jílcem, u levé nohy rozlomené žluté kolo s bílými noži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře polceném štítě vpravo vykořeněná lípa přirozené barvy, vlevo čelně stojící sv. Kateřina ve stříbrném šatě, držící v pravé ruce zelenou palmovou ratolest, levou rukou kosmo postavený stříbrný meč se zlatou záštitou a hlavicí, u levé nohy zlaté rozlomené kolo se stříbrnými noži."
    },
    {
        obec: "Šebířov",
        kod: 553204,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.566211,
        longitude: 14.82603,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/%C5%A0eb%C3%AD%C5%99ov_CoA.png",
        coatOfArmsDescription: "Červeno-stříbrně šikmo dělený štít, v obou polích lilie opačných barev."
    },
    {
        obec: "Šenov",
        kod: 598798,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73934,
        latitude: 49.793249,
        longitude: 18.376174,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/%C5%A0enov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 6 : 1, přes ně černý středový svislý pruh široký šestinu délky listu. V žerďové a vlající části listu vyrůstá ze zeleného pruhu zelený stvol orobince s listy a hnědým květenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrný štít s černým kůlem, po jehož obou stranách vyrůstá ze zeleného trávníku (nivy) po jednom stvolu orobince s listy, vše v přirozených barvách."
    },
    {
        obec: "Šenov u Nového Jičína",
        kod: 554171,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74242,
        latitude: 49.604647,
        longitude: 18.003387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/%C5%A0enov_u_Nov%C3%A9ho_Ji%C4%8D%C3%ADna_znak.gif",
        coatOfArmsFlagDescription: "Bílý list se zelenou vykořeněnou jabloní s červenými jablky a hnědým kmenem ve středu bílého pole, odděleného svislým zeleným pruhem o šířce jedné čtyřiadvacetiny délky listu, vzdálené jedné osminy délky listu od vlajícího lemu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném vrchu jabloň přirozených barev, pod ní položený vpravo obrácený stříbrný meč."
    },
    {
        obec: "Šerkovice",
        kod: 583944,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.38568,
        longitude: 16.430063,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/%C5%A0erkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový zelený s bílým čtyřlistým květem se žlutým středem, a vlající bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo dva stříbrné čtyřlisté květy se zlatými středy vpravo nad sebou, vlevo z návrší vyrůstající olše, vše zelené."
    },
    {
        obec: "Šestajovice",
        kod: 547654,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.341229,
        longitude: 16.008082,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/%C5%A0estajovice_%28okres_N%C3%A1chod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a modrý, v poměru 3 : 1. Uprostřed listu bílý čáp s černým křídlem a červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s modrou patou stříbrný čáp s černým křídlem a červenou zbrojí."
    },
    {
        obec: "Šestajovice",
        kod: 538876,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25092,
        latitude: 50.10852,
        longitude: 14.681812,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Sestajovice_znak.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený bílo - červený list. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně šikmo děleném štítě člun s patriarším křížem řádu Křižovníků Božího hrobu opačných tinktur."
    },
    {
        obec: "Šetějovice",
        kod: 532509,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.645276,
        longitude: 15.222583,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/%C5%A0et%C4%9Bjovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu, zelené pole a vodorovný bílý pruh vycházející z deváté desetiny žerďového okraje listu. Nad ním v žerďové a střední části listu zkřížené žluté klíče. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zkřížené zlaté klíče nad sníženým stříbrným vlnitým břevnem."
    },
    {
        obec: "Ševětín",
        kod: 545121,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37363,
        latitude: 49.100158,
        longitude: 14.572298,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Sevetin_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. V žerďovém poli červená růže se žlutým semeníkem a zelenými kališními lístky, ve vlajícím poli žlutá třípodlažní kostelní věž uprostřed s hodinami a oknem v prvním a třetím podlaží, zakončená černou cibulovitou střechou s makovicí a křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře šikmo děleném štítě nahoře červená růže se zlatým semeníkem a zelenými kališními lístky, dole zlatá třípodlažní kostelní věž uprostřed s hodinami a oknem v prvním a třetím podlaží, zakončená černou cibulovitou střechou s makovicí a křížkem."
    },
    {
        obec: "Šilheřovice",
        kod: 510432,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74715,
        latitude: 49.926122,
        longitude: 18.270274,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Znak_obce_%C5%A0ilhe%C5%99ovice.jpg",
        coatOfArmsFlagDescription: "Žlutý list s polovinou černé orlice s červenou zbrojí spojenou se střídavě polovičním zeleným listem, žaludem, listem a polovičním žaludem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě půl černé orlice s červenou zbrojí spojené s polovinou zelené dubové ratolesti s viditelným polovičním a celým listem i žaludem."
    },
    {
        obec: "Šimanov",
        kod: 588008,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.458171,
        longitude: 15.450115,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/%C5%A0imanov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým klínem vycházejícím ze druhé třetiny dolního okraje listu s vrcholem ve středu listu. Z dolního okraje listu vyrůstá do klínu červený plamen. Nad klínem, provázeným dvěma bílými šesticípými hvězdami, zkřížené žluté klíče s bílou šesticípou hvězdou u horního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě snížený stříbrný hrot s červeným vyrůstajícím plamenem provázený dvěma stříbrnými hvězdami. Vše převýšeno zkříženými zlatými klíči, nahoře stříbrná hvězda."
    },
    {
        obec: "Šimonovice",
        kod: 564460,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46312,
        latitude: 50.706477,
        longitude: 15.052732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/%C5%A0imonovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 5 : 1. Ze zeleného pruhu vyrůstá zelený strom s černým kmenem a třemi bílými javorovými listy v koruně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trojvrší zelený strom s černým kmenem a třemi stříbrnými javorovými listy v koruně."
    },
    {
        obec: "Šindelová",
        kod: 560677,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35801,
        latitude: 50.318764,
        longitude: 12.603439,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Sindelova_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V bílém pruhu zelená koruna smrku, v červeném bílá sklářská merka tvořená ležatou osmičkou se svěšeným patkovým křížkem uprostřed. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně sníženě děleném štítě nahoře vyrůstající smrk přirozené barvy s černou loveckou trubkou zavěšenou na kmeni na červené šňůře, dole stříbrná sklářská merka tvořená ležatou osmičkou se svěšeným patkovým křížkem uprostřed."
    },
    {
        obec: "Šípy",
        kod: 598496,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27034,
        latitude: 50.014425,
        longitude: 13.616563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Coats_of_arms_%C5%A0%C3%ADpy.jpeg",
        coatOfArmsFlagDescription: "Červený list se třemi zkříženými žlutými šípy hroty nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě tři zkřížené zlaté šípy."
    },
    {
        obec: "Široká Niva",
        kod: 597899,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 50.063922,
        longitude: 17.480203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C5%A0irok%C3%A1_Niva_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený v poměru 5:2:5. V zelených pruzích po jednom bílém ptáku hledícím do středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V dolní části zeleného štítu stříbrné zúžené vlnité břevno, z něhož dole vyniká polovina zlatého palečného kola. Nad břevnem zlatý postavený meč provázený dvěma stříbrnými přivrácenými ptáky."
    },
    {
        obec: "Široký Důl",
        kod: 578851,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57201,
        latitude: 49.746139,
        longitude: 16.220866,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/%C5%A0irok%C3%BD_D%C5%AFl_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené žerďové pětiúhelníkové pole, k němu přiléhající tři pravoúhlé krokve, modrá, bílá a modrá, vycházející ze čtvrté až šesté patnáctiny horního a dolního okraje listu, a bílé pole. V zeleném poli žlutý středový kříž s rameny širokými patnáctinu délky listu, ve vlající části listu tři modré kapky pod sebou, prostřední u vlajícího okraje. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně modrou sníženou krokví děleném štítě nahoře tři (1, 2) modré kapky, dole zlatý heroldský kříž. V krokvi stříbrné zlomené vlnité břevno."
    },
    {
        obec: "Šišma",
        kod: 552950,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75111,
        latitude: 49.464011,
        longitude: 17.588144,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/%C5%A0i%C5%A1ma_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - modrý, bílý, modrý, bílý a modrý. Na modrých pruzích v rozích a cípech a ve středním modrém pruhu na středu po jedné šesticípé žluté hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné volské rohy provázené pěti zlatými hvězdami."
    },
    {
        obec: "Šitbořice",
        kod: 584932,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69176,
        latitude: 49.014427,
        longitude: 16.77986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Coat_of_arms_of_Sitborice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý v poměru 1:2. V modrém pruhu žlutá hliněná láhev s uchem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou, v níž jsou vedle sebe černé položené orlí křídlo se zlatým perisoniem a modrý vinný hrozen na zeleném stonku, zlatá hliněná láhev s uchem."
    },
    {
        obec: "Škrdlovice",
        kod: 596876,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.634772,
        longitude: 15.926038,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Znak_m%C4%9Bsta_%C5%A0krdlovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Uprostřed od žerďového do vlajícího okraje je položená černá ostrev se třemi suky (2+1) směřujícími k vlajícímu okraji. Poměr pruhů a šířky ostrve je 3:2:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře černou ostrví (2,1) o třech sucích dělený štít."
    },
    {
        obec: "Škvorec",
        kod: 538884,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25083,
        latitude: 50.046972,
        longitude: 14.730519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Skvorec_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma vlajícími bílými klíny s vrcholy v polovině délky listu. V žerďové polovině bílá kvádrovaná věž s prázdnou branou, s vytaženou žlutou mříží, se třemi černými okny vedle sebe, a červenou valbovou střechou se dvěma žlutými odvrácenými praporky s vlaštovčím ocasem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná věž s prázdnou branou, s vytaženou zlatou mříží, nad branou tři černá okna vedle sebe, a s červenou valbovou střechou zakončenou dvěma odvrácenými praporky s vlaštovčím ocasem, vše zlaté."
    },
    {
        obec: "Škvořetice",
        kod: 551830,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.402515,
        longitude: 13.949224,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Znak_%C5%A0kvoretice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a červený zubatý se šesti čtvercovými zuby a pěti stejnými mezerami. V dolní části listu žluté obrněné rameno držící modrý meč se žlutým jílcem hrotem nahoru s nabodnutým červeným srdcem, provázeným v červeném pruhu žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně cimbuřovitě polceném štítě zlaté obrněné rameno držící na modrém meči se zlatým jílcem nabodnuté červené srdce a provázené vlevo nahoře zlatou korunou."
    },
    {
        obec: "Šlapanice",
        kod: 583952,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66451,
        latitude: 49.168729,
        longitude: 16.727418,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/%C5%A0lapanice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s kosmo položenou horní částí berly závitem k žerdi, a žlutý s modrým vinným hroznem na zeleném stonku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Šlapanice",
        kod: 532916,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27372,
        latitude: 50.314773,
        longitude: 14.111319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/%C5%A0lapanice_%28Kladno_District%29_CoA.png"
    },
    {
        obec: "Šlapanov",
        kod: 569585,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58251,
        latitude: 49.542525,
        longitude: 15.657789,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/%C5%A0lapanov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žluto-červeně polcený, modrý, bílý a modrý, v poměru 3:1:1:1. Ve žlutém poli černý ondřejský kříž s rameny vycházejícími z první a třetí šestiny horního okraje listu; v červeném poli bílý ondřejský kříž s rameny vycházejícími ze čtvrté a šesté šestiny horního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina zlato-červeně polcena, v pravém poli kosmo položený mlátek křížem přeložený želízkem, obojí černé, v levém poli šikmo položený meč hrotem dolů křížem přeložený klíčem zuby dolů a ven, obojí stříbrné, v dolní modré polovině stříbrný kapr."
    },
    {
        obec: "Šléglov",
        kod: 570117,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78825,
        latitude: 50.153075,
        longitude: 16.982641
    },
    {
        obec: "Šluknov",
        kod: 562858,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40777,
        latitude: 51.003793,
        longitude: 14.452699,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/%C5%A0luknov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý bílý s osmi zuby vysokými dvanáctinu šířky listu a sedmi stejnými mezerami, a černý, v poměru 3 : 5 : 4. V bílém a černém pruhu žlutý gotický štítek se zkříženými březovými ostrvemi. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Šonov",
        kod: 574511,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54971,
        latitude: 50.590932,
        longitude: 16.401645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Sonov_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým ondřejským křížem s rameny širokými desetinu šířky listu. V žerďovém, dolním a vlajícím poli červená růže se žlutým semeníkem a zelenými kališními lístky, v horním poli červené srdce. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě dvě modré, červeno-zlatým plamenem hořící zkřížené ostrve se dvěma suky ven, provázené nahoře červeným srdcem, po stranách a dole červenou pětilistou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Šošůvka",
        kod: 582484,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67913,
        latitude: 49.410633,
        longitude: 16.751985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Sosuvka_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a zelený, v poměru 1:1:5. V žerďové polovině zeleného pruhu bílý rýč bez násady hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vztyčený rýč bez násady. Hlava štítu dělena, nahoře v modrém poli zlatá osmihrotá hvězda, dole zlatá medová plástev."
    },
    {
        obec: "Špičky",
        kod: 570061,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75366,
        latitude: 49.548258,
        longitude: 17.807885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/CoA_of_%C5%A0pi%C4%8Dky.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý pilovitý s pěti zuby, v poměru 4:1. Vrcholy trojúhelníkových zubů sahají do první čtvrtiny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě pilovitě dělený štít."
    },
    {
        obec: "Špindlerův Mlýn",
        kod: 579742,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54351,
        latitude: 50.72625,
        longitude: 15.609549,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Znak_spindleruv_mlyn_krkonose.svg"
    },
    {
        obec: "Štáblovice",
        kod: 568333,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74782,
        latitude: 49.880131,
        longitude: 17.818639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/%C5%A0t%C3%A1blovice-znak.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým antonínským křížem tvořeným svislým žerďovým pruhem širokým jednu čtvrtinu délky listu a vodorovným pruhem širokým jednu pětinu šířky listu. V horním a dolním cípu po jedné bílé růži se žlutým semeníkem a červenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném trojvrší zlatý antonínský kříž provázený dvěma stříbrnými růžemi se zlatými semeníky a červenými kališními lístky. V trojvrší červený rošt sv. Vavřince."
    },
    {
        obec: "Šťáhlavy",
        kod: 558427,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33203,
        latitude: 49.675659,
        longitude: 13.504047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/%C5%A0%C5%A5%C3%A1hlavy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové pruhy, černý a červený, každý široký jednu čtvrtinu délky listu a sedm vodorovných, střídavě modrých a bílých pruhů. Přes černý a červený pruh, uprostřed pět (1,3,1) bílých kruhových vzájemně se dotýkajících polí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém černo-červeně polceném poli pět stříbrných koulí do kříže, v levém modrém poli tři stříbrná břevna."
    },
    {
        obec: "Štarnov",
        kod: 552011,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78314,
        latitude: 49.686232,
        longitude: 17.272686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/%C5%A0tarnov_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílou koňskou hlavou s krkem a modrý s osmicípou žlutou hvězdou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrný běžící kůň se zlatými kopyty, provázený vlevo nahoře zlatou osmihrotou hvězdou."
    },
    {
        obec: "Štědrá",
        kod: 555622,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36452,
        latitude: 50.058313,
        longitude: 13.114279,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Coat_of_arms_of_%C5%A0t%C4%9Bdr%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý, a žlutý zubatý lem široký jednu dvacetinu šířky listu se čtvercovými zuby a stejnými mezerami. Uprostřed tři korunované bílé, žlutě zdobené turnajské přilby (2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě se zlatým cimbuřovým lemem tři korunované stříbrné, zlatě zdobené, turnajské přilby (2,1)."
    },
    {
        obec: "Štěchov",
        kod: 582492,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.452359,
        longitude: 16.506182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/%C5%A0t%C4%9Bchov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 7:9:7. V žerďovém pruhu modrá lilie. V prostředním pruhu bílý meč se žlutým jílcem hrotem dolů, přeloženým dole knížecí čepicí. Ve vlajícím pruhu modrá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě modrý hrot s postaveným stříbrným mečem se zlatým jílcem přeloženým knížecí čepicí, provázený vpravo lilií a vlevo hornickými kladívky, vše modré."
    },
    {
        obec: "Štěchovice",
        kod: 536920,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.255508,
        longitude: 13.765623
    },
    {
        obec: "Štěchovice",
        kod: 539732,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25207,
        latitude: 49.851197,
        longitude: 14.405534,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Stechovice_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými vlajícími klíny s vrcholy v polovině délky listu. K žerďovému okraji přiléhá žluté slunce o průměru poloviny šířky listu se sedmnácti úplnými rovnými paprsky. Hroty dvou svislých paprsků se dotýkají horního a dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý dvouřádkový majuskulní nápis HOR ZLATICH, provázený nahoře vpravo kosmým špičákem a vlevo šikmým mlátkem, obojí stříbrné s topůrky přirozené barvy. Z paty štítu vyniká zlaté slunce s tváří."
    },
    {
        obec: "Štěkeň",
        kod: 551856,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38751,
        latitude: 49.267249,
        longitude: 14.005988,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/CoA_of_%C5%A0t%C4%9Bke%C5%88.svg",
        coatOfArmsFlagDescription: "Červený list s vlčí hlavou s krkem v žerďové polovině listu a třemi vlajícímí klíny s vrcholy v polovině délky listu, vše bílé. Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vlčí hlava s krkem."
    },
    {
        obec: "Štěměchy",
        kod: 591815,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67527,
        latitude: 49.193393,
        longitude: 15.714768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Coat_of_arms_of_%C5%A0t%C4%9Bm%C4%9Bchy.jpg",
        coatOfArmsFlagDescription: "Černo-žlutě kosmo dělený list. V černém poli krojidlo ostřím k žerďovému okraji a radlice ostřím k vlajícímu okraji, obojí žluté a hroty k hornímu okraji listu. Ve žlutém poli modrý pětilistý květ na zeleném stonku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-černě kosmo děleném štítě, nahoře modrý pětilistý květ na zeleném stonku, dole vztyčené krojidlo a radlice, obojí zlaté a odvrácené."
    },
    {
        obec: "Štěnovice",
        kod: 558435,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33209,
        latitude: 49.670623,
        longitude: 13.399739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Stenovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List čtvrcený; horní žerďové pole bílé, dolní žerďové černé, horní vlající modré, dolní vlající žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě šikmo dělený štít. V horním poli postavená stříbrná palice podložená zkříženým špičákem a dlátem a převýšeno úhelníkem, vše stříbrné. V dolním poli černá kančí hlava."
    },
    {
        obec: "Štěnovický Borek",
        kod: 539741,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33209,
        latitude: 49.649772,
        longitude: 13.426875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/%C5%A0t%C4%9Bnovick%C3%BD_Borek_CoA.png",
        coatOfArmsFlagDescription: "List dělený kosmým žlutým pruhem širokým třetinu šířky listu na modré žerďové a červené vlající pole. Ve žlutém pruhu tři kosmo vztyčené zelené lipové listy, v modrém poli úhelník vrcholem k dolnímu rohu listu, uvnitř něj vztyčená palice podložená zkříženými špičákem a dlátem, vše bílé, v červeném poli bílá, žlutě zdobená mitra s fimbriemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře kosmým zlatým břevnem se třemi kosmo vztyčenými zelenými lipovými listy dělený štít. Nahoře stříbrná, zlatě zdobená mitra s fimbriemi, dole úhelník, uvnitř se vztyčenou palicí podloženou zkříženými špičákem a dlátem, vše stříbrné."
    },
    {
        obec: "Štěpánov",
        kod: 505161,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78313,
        latitude: 49.684136,
        longitude: 17.220518,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/%C5%A0t%C4%9Bp%C3%A1nov_znak.png",
        coatOfArmsFlagDescription: "Žlutý list s modrým okrajem o šířce jedné šestiny listu, přes žluté pole dva kosmé černé pruhy o šířce jedné dvanáctiny šířky listu, žlutý mezipruh je široký jednu osmnáctinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém modře lemovaném štítě, zdvojené kosmé černé břevno šikmo přeložené červenou berlou."
    },
    {
        obec: "Štěpánkovice",
        kod: 510483,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74728,
        latitude: 49.95748,
        longitude: 18.037518,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/%C5%A0t%C4%9Bp%C3%A1nkovice_znak.png",
        coatOfArmsFlagDescription: "Čtvrcený černo-žlutý list s kolem sv. Kateřiny opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě čtvrceném štítě kolo sv. Kateřiny opačných barev."
    },
    {
        obec: "Štěpánov nad Svratkou",
        kod: 596884,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59263,
        latitude: 49.504688,
        longitude: 16.339224,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/%C5%A0t%C4%9Bp%C3%A1nov_nad_Svratkou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed horník s bílou kuklou v černém kabátci s flekem a bílým opaskem, bílými kalhotami a černými botami. V pravé ruce drží želízko, v pozdvižené levé mlátek, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě horník se stříbrnou kuklou v černém kabátci s flekem a stříbrným opaskem, stříbrnými kalhotami a černými botami. V pravé ruce drží želízko, v pozdvižené levé mlátek, obojí stříbrné."
    },
    {
        obec: "Štěpánovice",
        kod: 545139,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37373,
        latitude: 49.00193,
        longitude: 14.653443,
        coatOfArmsFlagDescription: "List tvoří u horního okraje listu vodorovný modrý pruh se žlutou korunou, široký tři desetiny šířky listu, a dva svislé pruhy, červený a bílý, s růží opačných barev se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě, pod modrou oblakovou hlavou se zlatou korunou, růže opačných tinktur se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Štěpánovice",
        kod: 583961,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66602,
        latitude: 49.373052,
        longitude: 16.387683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/%C5%A0t%C4%9Bp%C3%A1novice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a zvlněný žlutý, v poměru 5:4:1. V červeném pruhu bílá rozevřená kniha, v modrém pruhu bílá radlice hrotem k vlajícímu okraji a ostřím k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě se zlatou vlnitou patou nahoře rozevřená kniha, dole položená radlice, obojí stříbrné."
    },
    {
        obec: "Štěpkov",
        kod: 591823,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.084115,
        longitude: 15.650302,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a červený, v poměru 1 : 3. Z druhé až šesté sedminy dolního okraje listu vyniká zelené návrší s bílou rotundou, apsidou k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou hlavou na zeleném návrší stříbrná rotunda, vlevo s apsidou."
    },
    {
        obec: "Šternberk",
        kod: 505188,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78501,
        latitude: 49.730542,
        longitude: 17.298995,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/%C5%A0ternberk_znak.png",
        coatOfArmsFlagDescription: "List o horním pruhu modrém, ve kterém je u žerdi osmicípá žlutá hvězda a dolním zeleném pruhu; šíře modrého pruhu je 2/3 zeleného 1/3; poměr délky k šířce je 3:2."
    },
    {
        obec: "Štětí",
        kod: 565709,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41108,
        latitude: 50.453087,
        longitude: 14.374322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Steti_-_erb.gif",
        coatOfArmsFlagDescription: "List tvoří modré karé nad stejně velkým bílým polem a dva vodorovné pruhy bílý a modrý. V karé bílá kvádrovaná věž s cimbuřím a červenou stanovou střechou se žlutou makovicí, se dvěma černými okny vedle sebe, prolomenou branou se žlutými otevřenými vraty, šikmo přeložená černou ostrví se šesti suky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Štětkovice",
        kod: 541419,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26401,
        latitude: 49.670811,
        longitude: 14.506319,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/%C5%A0t%C4%9Btkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, v horních dvou třetinách žerďové poloviny listu polovina žlutého lva s červenou zbrojí provázená u horního a žerďového okraje listu šesticípou hvězdou a dole lilií, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě polovina zlatého lva s červenou zbrojí, provázená nahoře a vpravo hvězdou a dole lilií, vše stříbrné."
    },
    {
        obec: "Štíhlice",
        kod: 571644,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 50.005666,
        longitude: 14.780051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/%C5%A0t%C3%ADhlice-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Uprostřed bílá věž s cimbuřím, černou branou a dvěma okny, podložená vztyčenými hráběmi a provázená po stranách položenými odvrácenými lipovými listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě stříbrná věž s cimbuřím, černou branou a dvěma okny, podložená vztyčenými hráběmi a provázená dvěma položenými odvrácenými lipovými listy, vše zlaté."
    },
    {
        obec: "Štichov",
        kod: 566101,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.585019,
        longitude: 13.048969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5e/%C5%A0tichov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 7 : 5. Do horního pruhu vyrůstá červený drak, v dolním pruhu bílo-červeně polcená šestilistá růže se žlutým semeníkem nad bílou šňůrou se sedmi perlami do oblouku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně vlnitě děleném štítě nahoře vyrůstající červený drak, dole stříbrno-červeně polcená šestilistá růže se zlatým semeníkem nad stříbrnou šňůrou se sedmi perlami do oblouku."
    },
    {
        obec: "Štichovice",
        kod: 578797,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.977911,
        longitude: 13.302933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/%C5%A0tichovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed dva zkřížené žluté obilné klasy, každý s jedním listem, mezi nimi u horního okraje listu kosmo žlutý klíč zuby nahoru a k žerdi, šikmo přeložený bílým mečem se žlutým jílcem, hrotem nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zkřížené zlaté obilné klasy, každý s jedním odvráceným listem, nahoře kosmý zlatý klíč křížem přeložený vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Štipoklasy",
        kod: 530930,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.824707,
        longitude: 15.213391
    },
    {
        obec: "Štítary",
        kod: 594890,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.935202,
        longitude: 15.844388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Stitary_znak.jpg"
    },
    {
        obec: "Štítina",
        kod: 510491,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74791,
        latitude: 49.914966,
        longitude: 18.012559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/%C5%A0t%C3%ADtina_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s modrým žerďovým klínem sahajícím do středu vlajícího okraje. Na klínu středové žluté břevno široké jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý dole zahrocený kůl provázený stříbrnými buvolími rohy."
    },
    {
        obec: "Štítná nad Vláří-Popov",
        kod: 585831,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76333,
        latitude: 49.070718,
        longitude: 17.972465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CoA_of_%C5%A0t%C3%ADtn%C3%A1_nad_Vl%C3%A1%C5%99%C3%AD-Popov.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a zelený, v zelených po jedné žluté šesticípé hvězdě, v bílém červený štítek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zelenou patou stříbrný klín provázený dvěma zlatými hvězdami, v něm červený štítek s kosmo položenou stříbrnou tesařskou sekerou na zlatém topůrku."
    },
    {
        obec: "Štítov",
        kod: 579343,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.666038,
        longitude: 13.678024,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Znak_obce_%C5%A0t%C3%ADtov.png",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list se zeleným středovým křížem s rameny širokými jednu dvanáctinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě se zelenou patou vpravo stříbrný beran se zlatou zbrojí ve skoku, vlevo doleva obrácená černá kančí hlava se zlatými kly a červeným rypákem a jazykem."
    },
    {
        obec: "Štíty",
        kod: 541168,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78991,
        latitude: 49.961321,
        longitude: 16.765863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Coat_of_arms_of_%C5%A0t%C3%ADty.svg",
        coatOfArmsFlagDescription: "Modrý list s osmicípou žlutou hvězdou s osmi žlutými osmicípými hvězdičkami mezi cípy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá osmihrotá hvězda provázená mezi hroty osmihrotými hvězdami."
    },
    {
        obec: "Štoky",
        kod: 569593,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58253,
        latitude: 49.502586,
        longitude: 15.588733,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Stoky_znak.jpg",
        coatOfArmsFlagDescription: "Bíle lemovaný list se dvěma vodorovnými pruhy, černým a červeným, přes které je šikmo položeno bílé pštrosí pero brkem k dolnímu rohu. Lem je široký jednu pětinu šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Štramberk",
        kod: 599948,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74266,
        latitude: 49.591905,
        longitude: 18.117521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Erb-stramberk.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh s bílou zavinutou střelou a modré pole s polovinou bílé orlice se žlutou zbrojí a červeným jazykem, přiléhající k červenému pruhu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Študlov",
        kod: 578860,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.602645,
        longitude: 16.498432,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/%C5%A0tudlov_SY_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 4:1:1:3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě doprava obrácená stříbrná beraní hlava se zlatým rohem."
    },
    {
        obec: "Študlov",
        kod: 544931,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.162525,
        longitude: 18.083277,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/CoA_of_%C5%A0tudlov.svg",
        coatOfArmsFlagDescription: "Modrý list se svislým žlutým pruhem vycházejícím z páté šestiny horního a dolního okraje listu. V žerďovém poli bílý kozel ve skoku ku vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě doleva obrácený stříbrný kozel ve skoku ku zlatému vykořeněnému listnatému stromu."
    },
    {
        obec: "Šubířov",
        kod: 590096,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79852,
        latitude: 49.602264,
        longitude: 16.813444,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/%C5%A0ub%C3%AD%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 1 : 3 : 1. Žerďový a vlající pruh tvoří sedm vodorovných, střídavě bílých a červených pruhů, střední svislý pruh je modrý se žlutým koněm ve skoku k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě se třemi červenými břevny široký modrý kůl se zlatým koněm ve skoku doleva."
    },
    {
        obec: "Šumice",
        kod: 594903,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67175,
        latitude: 48.9922,
        longitude: 16.437469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C5%A0umice_%28Brno-Country%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:5:1. Do modrého pruhu vyrůstají dvě odvrácené přirozené bílé lilie s jedním listem, mezi nimi žluto-červeně polcený vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě vyrůstají ze zlaté položené radlice dvě odvrácené stříbrné přirozené lilie, každá s jedním listem po straně, mezi nimi zlato-červeně polcený vinný hrozen."
    },
    {
        obec: "Šumavské Hoštice",
        kod: 550574,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38471,
        latitude: 49.039501,
        longitude: 13.872365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/%C5%A0umavsk%C3%A9_Ho%C5%A1tice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutým lipovým čtyřlistem s kotoučem uprostřed, list směřující k žerdi je větší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený a vlevo dělený štít. V pravém zeleném poli zlatý lipový čtyřlist s větším horním listem a kotoučem uprostřed. Vlevo nahoře ve stříbrném poli šikmo zelená smrková větévka, dole v červeném poli tři zlaté převázané obilné klasy."
    },
    {
        obec: "Šumice",
        kod: 592676,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68731,
        latitude: 49.028682,
        longitude: 17.72216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/%C5%A0umiceUH_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. Z druhé šestiny horního a dolního okraje listu vychází svislý, modro-červeně dělený pruh. V modrém poli žlutý jetelový patriarší kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně šikmým stříbrným břevnem dělený štít, v něm větev dřínu se čtyřmi zelenými listy a třemi červenými plody, nahoře vyrůstající zlatý jetelovitě zakončený patriarší kříž, dole stříbrná zednická lžíce."
    },
    {
        obec: "Šumná",
        kod: 594911,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.922527,
        longitude: 15.871054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/%C5%A0umn%C3%A1_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed zvon nad vykořeněným pařezem, obojí žluté, mezi bílou sekerou se žlutým topůrkem ostřím k žerďovému okraji a bílým mečem se žlutým jílcem hrotem k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zvon nad vykořeněným pařezem, obojí zlaté, vpravo stříbrná sekera se zlatým topůrkem, vlevo postavený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Šumperk",
        kod: 523704,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78701,
        latitude: 49.977941,
        longitude: 16.971875,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Sumperk_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří žluté žerďové pole s černou půlorlicí s červenou zbrojí a vlající modré pole se svislým bílým parohem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Šumvald",
        kod: 505218,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78385,
        latitude: 49.831136,
        longitude: 17.132851,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Sumvald.znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 4:1. Ze žlutého pruhu vyrůstá do žerďové a střední části bílý dvouocasý korunovaný lev s červenou zbrojí a jazykem, držící v pravé tlapě žlutou dubovou větévku se čtyřmi listy a třemi žaludy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě ze zlatého trojvrší vyrůstající stříbrný dvouocasý korunovaný lev s červenou zbrojí a jazykem, držící v pravé tlapě zlatou dubovou větévku se čtyřmi listy a třemi žaludy."
    },
    {
        obec: "Švábenice",
        kod: 593621,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68323,
        latitude: 49.278501,
        longitude: 17.123499,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/%C5%A0V%C3%81BENICE_znak_-_png.png",
        coatOfArmsFlagDescription: "Modrý list se žlutou rozletitou střelou s kruhem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá rozletitá střela s kruhem."
    },
    {
        obec: "Švábov",
        kod: 547255,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58851,
        latitude: 49.308525,
        longitude: 15.357131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/%C5%A0v%C3%A1bov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a bílý. Ze střední třetiny okrajů zeleného pruhu vyrůstají dva protilehlé trojúhelníky s vrcholy na okrajích listu. Uprostřed zeleného pole žlutý patriarší kříž vysoký tři čtvrtiny šířky listu. V rozích a cípech listu po jedné červené routě dotýkající se horního a dolního okraje bílých pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě čelně vztyčená zelená radlice se zlatým patriarším křížem, provázená nahoře dvěma červenými routami."
    },
    {
        obec: "Švihov",
        kod: 565326,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27033,
        latitude: 50.109935,
        longitude: 13.5644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/%C5%A0vihov_%28Rakovn%C3%ADk_District%29_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílou orlicí se žlutou zbrojí a jazykem nad zkříženými biči, každý s bílým bičištěm, červeným střapcem nahoře, žlutou rukojetí a švihem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná orlice se zlatou zbrojí a jazykem nad zkříženými biči, každý se stříbrným bičištěm, červeným střapcem nahoře, zlatou rukojetí a švihem."
    },
    {
        obec: "Švihov",
        kod: 557200,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34012,
        latitude: 49.481466,
        longitude: 13.284281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Svihov_KT_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři svislá pole - červené, bílé a červené v poměru 1:2:1. Na střed bílého pole je položen růžový keř z městského znaku tak, že zaujímá polovinu výšky i šířky pole. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Tábor",
        kod: 552046,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.413089,
        longitude: 14.677566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Tabor_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - horní červený a dolní černý. Na středu listu bílý český lev se žlutou korunou a zbrojí třímající v předních tlapách žlutý kalich s bílou hostií a černým řeckým křížem. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Tachlovice",
        kod: 531821,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25217,
        latitude: 50.014555,
        longitude: 14.240788,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Tachlovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list. Horní žerďové pole bílé s červenou svatojakubskou mušlí, dolní žerďové pole černé, horní vlající pole zelené, dolní vlající pole žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá z černé paty se stříbrným vlnitým břevnem stříbrná vrba se zlatými listy. Přes kmen vrby červená svatojakubská mušle držená v zobácích dvěma přivrácenými zlatými kuřaty."
    },
    {
        obec: "Tachov",
        kod: 553638,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.543414,
        longitude: 14.638554,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Tachov_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený s deseti (1, 2, 1, 2, 1, 2, 1) vztyčenými žlutými lipovými listy a červený s bílou utrženou korunovanou lví hlavou se žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně polceném štítě vpravo na zeleném vrchu listnatý strom přirozené barvy s korunou posypanou deseti (1, 2, 1, 2, 1, 2, 1) vztyčenými zlatými lipovými listy, vlevo stříbrná utržená korunovaná lví hlava se zlatým jazykem."
    },
    {
        obec: "Tachov",
        kod: 560715,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34701,
        latitude: 49.79888,
        longitude: 12.636292,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Tachov_znak.svg",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list, v horním žerďovém poli korunovaný bílý dvouocasý lev se zlatou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Tálín",
        kod: 549932,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39815,
        latitude: 49.247661,
        longitude: 14.227263,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/T%C3%A1l%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zeleno-žluto-zeleně polcený, v poměru 1 : 2 : 3, a zvlněný modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na modré vlnité patě zelená hráz se zlatým stavidlem mezi dvěma duby přirozené barvy."
    },
    {
        obec: "Tanvald",
        kod: 563820,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46841,
        latitude: 50.737629,
        longitude: 15.307982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Tanvald_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílé karé nad zeleným čtvercovým polem a dva vodorovné pruhy, zelený a žlutý. Z čtvercového pole vyrůstají tři zelené vrchy, z nich tři zelené jedle s hnědými kmeny; za vrchy vychází žluté slunce bez tváře s paprsky dosahujícími na okraje karé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Tasov",
        kod: 586650,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69663,
        latitude: 48.906964,
        longitude: 17.429767,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Tasznak.jpg",
        coatOfArmsFlagDescription: "Žluto-modře kosmo a šikmo čtvrcený list. V horním poli červená, žlutě zdobená knížecí koruna, v dolním poli žlutá osmicípá hvězda, v žerďovém poli modrý a ve vlajícím zelený vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít. Nahoře vyrůstající sv. Václav ve stříbrném brnění s červeným pláštěm, držící v pravici na zlaté žerdi stříbrnou korouhev s černou plamennou orlicí. Dole modrý hrot se zlatou osmihrotou hvězdou, provázený vinnými hrozny, vpravo modrým a vlevo zeleným."
    },
    {
        obec: "Tasov",
        kod: 591831,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67579,
        latitude: 49.288374,
        longitude: 16.094889,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Tasov_CoA_CZ.jpg"
    },
    {
        obec: "Tasovice",
        kod: 582506,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.496043,
        longitude: 16.441633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Tasovice_BK_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi (2, 1) od středu odvrácenými květy bledulí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě tři (2, 1) od středu odvrácené květy bledulí přirozené barvy."
    },
    {
        obec: "Tasovice",
        kod: 594920,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67125,
        latitude: 48.836196,
        longitude: 16.155687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Tasovice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed bílá radlice hrotem dolů a ostřím k vlajícímu okraji a bílý vinařský nůž se žlutou rukojetí dolů a ostřím k žerdi, převýšené žlutou korunou a provázené v modrém pruhu žlutým obilným klasem se dvěma listy a v červeném pruhu žlutým prohnutým vinným stonkem s přivráceným hroznem a dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vedle sebe doleva obrácená stříbrná radlice a stříbrný vinařský nůž se zlatou rukojetí převýšené zlatou korunou a provázené vpravo zlatým obilným klasem se dvěma listy a vlevo prohnutým zlatým vinným stonkem s přivráceným hroznem a dvěma listy."
    },
    {
        obec: "Tašov",
        kod: 568287,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.614624,
        longitude: 14.135906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Ta%C5%A1ov_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý. Ve žlutém pruhu černé jelení paroží mezi vinným hroznem a chmelovou šišticí, obojí zelené a svěšené na stoncích se dvěma odvrácenými úponky. V modrém pruhu bílý maltézský kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrým skalnatým trojvrším černé jelení paroží mezi vinným hroznem a chmelovou šišticí, obojí zelené a svěšené na stoncích se dvěma odvrácenými úponky. V trojvrší stříbrný maltézský kříž."
    },
    {
        obec: "Tatce",
        kod: 537888,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.091224,
        longitude: 14.977345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Tatce_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý, v poměru 1 : 4 : 1. V červeném pruhu bílá lípa vyrůstající z položeného žlutého klíče zuby k žerďovému a dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná lípa vyrůstající z položeného zlatého klíče."
    },
    {
        obec: "Tatenice",
        kod: 581046,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56131,
        latitude: 49.871076,
        longitude: 16.697311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Tatenice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a zelený v poměru 2:2:1. Ve žlutém zkřížená sekera a mlátek, oboje černé. V červeném žlutý procesní kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě nad zelenou patou vpravo zkřížená sekera a mlátek, obojí černé, vlevo vyrůstající zlatý procesní kříž."
    },
    {
        obec: "Tatiná",
        kod: 578983,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.848699,
        longitude: 13.299824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Tatin%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a černý. Uprostřed tři zkřížené žluté obilné klasy, krajní s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě tři zkřížené zlaté obilné klasy, krajní s jedním listem."
    },
    {
        obec: "Tatobity",
        kod: 577596,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51253,
        latitude: 50.571343,
        longitude: 15.273268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Tatobity-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený šikmý pruh široký pět šestin šířky listu, lemovaný bílými pruhy širokými šestinu šířky listu, horní roh a dolní cíp listu červené. V zeleném pruhu šikmo dva spojené bílé lipové listy, žerďový menší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítu z trávníku vyrůstající lípa s mohutným kmenem a čtyřmi větvemi, po stranách kmene vpravo broušený granát a vlevo drůza ametystu, vše přirozené barvy."
    },
    {
        obec: "Tatrovice",
        kod: 538663,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35735,
        latitude: 50.276819,
        longitude: 12.697409,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Tatrovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílo-červeno-bíle dělený klín s vrcholem uprostřed listu a tři vodorovné pruhy, modrý, bílý a modrý. V modrých pruzích u vlajícího okraje listu žlutý řecký kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře stříbrná hlava jednorožce se zlatým rohem, nahoře provázená dvěma zlatými řeckými kříži. Dole červené břevno."
    },
    {
        obec: "Tavíkovice",
        kod: 594938,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 49.033846,
        longitude: 16.103265,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Tav%C3%ADkovice_CoA.png"
    },
    {
        obec: "Tečovice",
        kod: 549649,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76302,
        latitude: 49.221167,
        longitude: 17.587443,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Te%C4%8Dovice_znak.png",
        coatOfArmsFlagDescription: "Zelený list se svatojakubskou mušlí ve střední části, v žerďové a vlající části stejně velké středověké ostruhy bodci k dolnímu okraji, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pár jezdeckých ostruh se třemi ostny hroty dolů, pod nimi mušle, vše stříbrné."
    },
    {
        obec: "Tehov",
        kod: 530751,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.735542,
        longitude: 14.966838,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Tehov_%28okr._Bene%C5%A1ov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed listu nad vztyčeným žlutým gotickým dvojklíčem žlutá osmicípá fasetovaná hvězda, z níž k hornímu rohu a cípu vyrůstají dva bílé leknínové listy na stoncích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad vztyčeným zlatým gotickým dvojklíčem zlatá osmihrotá fasetovaná hvězda, z níž vyrůstají dva odkloněné stříbrné leknínové listy na stoncích."
    },
    {
        obec: "Tehov",
        kod: 538892,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25101,
        latitude: 49.97162,
        longitude: 14.693897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Tehov-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký jednu třetinu délky listu, a pět vodorovných pruhů, střídavě žlutých a červených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na černém skalnatém návrší višeň přirozené barvy s červenými plody, z koruny po obou stranách vlaje dolů červená stuha."
    },
    {
        obec: "Tehovec",
        kod: 599719,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25162,
        latitude: 49.981597,
        longitude: 14.730399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Tehovec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. Uprostřed bílo-červeně polcený leknínový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo zlatý obilný klas se dvěma odkloněnými listy, vlevo vykořeněný smrk přirozené barvy, nahoře stříbrno-červeně polcený leknínový trojlist."
    },
    {
        obec: "Těchařovice",
        kod: 529664,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.599507,
        longitude: 14.048897
    },
    {
        obec: "Těchlovice",
        kod: 571024,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.207992,
        longitude: 15.711159,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/T%C4%9Bchlovice_CoA_HK_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, žlutý, bílý, žlutý a zelený, v poměru 10 : 1 : 2 : 1 : 10. V zeleném žerďovém pruhu bílá růže se žlutým semeníkem, ve vlajícím bílý třešňový květ se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné průčelí kaple se zlatým soklem, zlatými gotickými dveřmi a portálem zakončeným zlatým latinským křížem vyrůstajícím do zlatě lemovaného štítu kaple; nahoře vpravo stříbrná růže se zlatým semeníkem a vlevo stříbrný třešňový květ se zlatým středem."
    },
    {
        obec: "Těchlovice",
        kod: 555193,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.696428,
        longitude: 14.205036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Techlovice_DC_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červeno-modrý dělený bílou krokví a žlutý, v poměru 1:8:1. Krokev vychází ze sedmé až deváté desetiny žerďového a vlajícího okraje a má vrchol na dolním okraji horního žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve štítě se zlatým lemem nahoře s červeným latinským křížem stříbrná krokev provázená nahoře v červeném poli dvěma zlatými jablky s odvráceným stříbrným listem na stopce, dole v modrém poli vztyčené zlaté pádlo křížem přeložené vztyčenou stříbrnou rybou."
    },
    {
        obec: "Těchobuz",
        kod: 561355,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.510188,
        longitude: 14.930738
    },
    {
        obec: "Těchonín",
        kod: 581054,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56166,
        latitude: 50.065031,
        longitude: 16.618015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/T%C4%9Bchon%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. V modrém pruhu bílé mlýnské kolo, pod ním v dolní polovině pruhu tři vodorovné vlnité bílé pruhy, každý se třemi vrcholy a dvěma prohlubněmi. V zeleném pruhu bílý meč se žlutým jílcem hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě vpravo nahoře mlýnské kolo, pod ním tři zúžená vlnitá břevna, vše stříbrné, vlevo vztyčený stříbrný meč se zlatým jilcem."
    },
    {
        obec: "Telč",
        kod: 588024,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.183338,
        longitude: 15.454373,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Telc_CoA.png",
        coatOfArmsFlagDescription: "Červený list s bílým písmenem 'T' sahajícím na okraje listu s rameny o šířce 1/3 šířky listu praporu, které je položeno na list tak, že jeho kratší rameno je rovnoběžné se žerdí a je od žerďového okraje vzdáleno na 1/3 šířky listu praporu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Telecí",
        kod: 578878,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56994,
        latitude: 49.694722,
        longitude: 16.180806,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Telec%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:2:1. V bílém pruhu zelená vykořeněná lípa s hnědým kmenem; mezi kořeny zavřená černá kniha se žlutým chrismonem a ořízkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vykořeněná lípa přirozené barvy, mezi kořeny zavřená černá kniha se zlatým chrismonem a ořízkou."
    },
    {
        obec: "Telnice",
        kod: 583979,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66459,
        latitude: 49.101969,
        longitude: 16.71785,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Telnice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: modrý, žlutý a červený v poměru 3:1:1. V horním rohu bílá věž s prolomenou branou, dvěma okny a jehlancovou střechou mezi dvěma vížkami (věž z obecního znaku). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít rozdělen zlatým kůlem, vpravo v červeném poli stříbrná vrba, vlevo v modrém poli stříbrná věž s prolomenou branou, dvěma okny a jehlancovou střechou mezi dvěma vížkami."
    },
    {
        obec: "Telnice",
        kod: 568295,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40338,
        latitude: 50.733064,
        longitude: 13.957891,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Telnice_UL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List s bílým kosmým pruhem širokým polovinu šířky listu. Horní vlající pole zelené, dolní žerďové červené. V horním pruhu modrá dvanáctihrotá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrno-červeně polceném štítě dvě zkřížené stříbrné sekery, proložené dole černými hornickými kladívky, obojí na hnědých topůrkách, nad sekerami modrá dvanáctihrotá hvězda."
    },
    {
        obec: "Temelín",
        kod: 545155,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37301,
        latitude: 49.19294,
        longitude: 14.348847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Coat_of_arms_of_Temel%C3%ADn.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý, uprostřed žlutý květ jabloně s bílým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě zlatý květ jabloně se stříbrným středem."
    },
    {
        obec: "Temešvár",
        kod: 562271,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.356486,
        longitude: 14.263631,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Teme%C5%A1v%C3%A1r_%28P%C3%ADsek_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý s bílou lilií a černý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě vpravo vyniká polovina černého orla s červenou zbrojí, a červeně lemovanou svatozáří, držící stříbrný meč se zlatým jílcem. Vlevo stříbrná lilie."
    },
    {
        obec: "Těmice",
        kod: 586668,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69684,
        latitude: 49.00165,
        longitude: 17.265026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Temice_znak.gif",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. V bílém poli červený vinný hrozen na stonku, v modrém poli dva žluté vodorovné pruhy, vycházející ze třetí a páté šestiny vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná radlice s červeným vinným hroznem s ratolestí, provázená vpravo doleva obráceným krojidlem a vlevo vinařským nožem - kosířem, obojí zlaté."
    },
    {
        obec: "Těmice",
        kod: 548936,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39496,
        latitude: 49.351896,
        longitude: 15.060549,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/T%C4%9Bmice_PE_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým vlajícím klínem s vrcholem na žerďovém okraji listu. V červených polích po bílé zavinuté střele hrotem k žerďovému okraji, v klínu červený lev s modrou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý hrot, v něm červený lev s modrou zbrojí a jazykem. Hrot provázen dvěma stříbrnými zavinutými střelami."
    },
    {
        obec: "Těně",
        kod: 560189,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33845,
        latitude: 49.7513,
        longitude: 13.796268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/T%C4%9Bn%C4%9B%2C_znak.jpg",
        coatOfArmsFlagDescription: "Žlutý list s černými hornickými kladívky s hnědými topůrky nad dvěma odvrácenými vztyčenými zelenými dubovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá hornická kladívka s topůrky přirozené barvy nad dvěma odvrácenými vztyčenými zelenými dubovými listy."
    },
    {
        obec: "Teplá",
        kod: 555631,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36461,
        latitude: 49.981953,
        longitude: 12.86316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Tepl%C3%A1_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý, v poměru 5:16. V žerďové polovině červeného pruhu žlutá koruna, pod ní ve žlutém pruhu tři (2,1) černá paroží. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Teplice",
        kod: 567442,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.644558,
        longitude: 13.835384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CoA_of_Teplice.svg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký čtvrtinu délky listu a tři vodorovné pruhy, modrý, žlutý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Teplice nad Bečvou",
        kod: 519031,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.528613,
        longitude: 17.74019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Teplice_nad_Be%C4%8Dvou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu žlutá, bíle tryskající fontána převýšená červeným srdcem podloženým zkříženými bílými liliovými hůlkami. Ve fontáně zkřížené modré sekery s hnědými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá, stříbrně tryskající fontána převýšená červeným srdcem podloženým dvěma zkříženými stříbrnými liliovými hůlkami. Ve fontáně dvě zkřížené modré sekery s topůrky přirozené barvy."
    },
    {
        obec: "Teplice nad Metují",
        kod: 574538,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54957,
        latitude: 50.59443,
        longitude: 16.170483,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Coat_of_arms_of_Teplice_nad_Metuj%C3%AD.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, červený, žlutý a modrý v poměru 3:4:1:4:3. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Teplička",
        kod: 537845,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36464,
        latitude: 50.148702,
        longitude: 12.85168,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Tepli%C4%8Dka_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým bílým zvlněným pruhem širokým třetinu délky listu se dvěma vrcholy a třemi prohlubněmi. V bílém pruhu tři modro-černě polcené růže se žlutým semeníkem pod sebou, v modrém žluté palečné kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zvýšeně stříbrno-modře vlnitě děleném štítě nahoře tři modro-černě polcené růže se zlatými semeníky, dole zlaté palečné kolo."
    },
    {
        obec: "Teplýšovice",
        kod: 530760,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.806208,
        longitude: 14.801688,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Tepl%C3%BD%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s žerďovým modrým pruhem širokým třetinu délky listu. V pruhu větší osmicípá hvězda nad třemi (2, 1) šesticípými hvězdami, všechny žluté. V bílém poli na položené červené ostrvi se dvěma suky vzpřímený černý medvěd se žlutou zbrojí, držící v předních tlapách hnědý bochník chleba bez patky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo osmihrotá hvězda nad třemi (2, 1) hvězdami, vše zlaté, vlevo na položené červené ostrvi se dvěma suky vzpřímený černý medvěd se zlatou zbrojí, držící předními tlapami bochník chleba bez patky přirozené barvy."
    },
    {
        obec: "Terešov",
        kod: 566802,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.896783,
        longitude: 13.69985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Tere%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený modrým šikmým pruhem širokým desetinu délky listu na bílé žerďové a žluté vlající pole. V bílém poli tis, ve žlutém vykračující černý kohout s červeným hřebínkem a lalokem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zlatě šikmým modrým břevnem děleném štítě vpravo tis přirozené barvy, vlevo vykračující černý kohout s červeným hřebínkem a lalokem."
    },
    {
        obec: "Terezín",
        kod: 586676,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69614,
        latitude: 48.955636,
        longitude: 16.942659,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Terezin_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký jednu třetinu délky listu s bílým rýčem hrotem dolů a zelené čtvercové pole s vinným hroznem provázeným dvěma klasy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Pod červenou hlavou se stříbrným rýčem v zeleném poli vinný hrozen provázený dvěma obilnými klasy, vše zlaté."
    },
    {
        obec: "Těrlicko",
        kod: 599158,
        okres: "Karviná",
        kod_okres: "CZ0803",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73542,
        latitude: 49.752419,
        longitude: 18.48302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/CoA_of_T%C4%9Brlicko.svg",
        coatOfArmsFlagDescription: "Modrý list se zlatým sluncem uprostřed.",
        coatOfArmsDescription: "Modře-stříbrně dělený štít, v horním poli zlaté slunce, v dolním červený pluh."
    },
    {
        obec: "Terezín",
        kod: 565717,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41155,
        latitude: 50.511102,
        longitude: 14.150658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Terez%C3%ADn_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří černý žerďový klín se žlutými spojenými iniciálami 'MT' lemovaný červenou krokví vycházející z první a osmé osminy žerďového okraje s vrcholem ve středu listu. V horní polovině listu čtyři kosmé pruhy, střídavě černé a žluté. V dolní polovině listu čtyři šikmé pruhy, střídavě žluté a černé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Těšany",
        kod: 583995,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66454,
        latitude: 49.039658,
        longitude: 16.770132,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Znak-tesany.jpg",
        coatOfArmsFlagDescription: "Zelený list, ve střední části pod rozevřenou knihou podkova, oboje bílé. V žerďové a vlající části po žlutém vinném hroznu na stonku s listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod rozevřenou knihou podkova, obojí stříbrné, provázené dvěma zlatými vinnými hrozny na stonku s jedním listem."
    },
    {
        obec: "Těšetice",
        kod: 594946,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.888944,
        longitude: 16.158304,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/T%C4%9B%C5%A1etice_ZN_znak.jpg",
        coatOfArmsFlagDescription: "Šikmo dělený list. V modrém žerďovém poli žlutý vinný hrozen s listy, vlající pole červené se žlutým šikmým pruhem, dělícím dolní a vlající okraj, listu v poměru 4 : 4 : 5. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě nahoře vinný hrozen s listy, dole šikmo postavená, doleva obrácená radlice, obojí zlaté."
    },
    {
        obec: "Těšetice",
        kod: 505269,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78346,
        latitude: 49.593272,
        longitude: 17.126175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/T%C4%9B%C5%A1etice_OL_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 3:2. Ve žlutém pruhu zkřížené modré klíče, přeložené bílým mečem s červenou rukojetí, modrou záštitou a hlavicí hrotem nahoru. V zeleném pruhu bílá radlice hrotem k žerďovému a ostřím k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně sníženě děleném štítě nahoře zkřížené modré klíče přeložené vztyčeným stříbrným mečem s červenou rukojetí a modrou záštitou a hlavicí, dole položená stříbrná radlice."
    },
    {
        obec: "Těškov",
        kod: 546526,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.802297,
        longitude: 13.698135,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/T%C4%9B%C5%A1kov%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém pruhu tři červené kosmé pruhy vycházející z druhé, čtvrté a šesté osminy žerďového okraje do třetí, páté a sedmé osminy vlajícího okraje pruhu. V zeleném pruhu na dolním okraji listu bílý vrch s červenou růží se žlutým semeníkem. Z vrchu vyrůstá bílý smrk. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo tři červená kosmá břevna, vlevo stříbrný smrk vyrůstající ze stříbrného vrchu, v něm červená růže se zlatým semeníkem."
    },
    {
        obec: "Těškovice",
        kod: 512745,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74764,
        latitude: 49.814189,
        longitude: 18.02101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Znak_teskovice.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. V žerďové polovině bílá hlava beránka se žlutou svatozáří v podobě mezikruží. V horním cípu bílá růže se žlutým semeníkem a kališními lístky, v dolním cípu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná hlava beránka se zlatou svatozáří v podobě mezikruží, provázená nahoře vpravo stříbrnou růží se zlatým semeníkem a kališními lístky, vlevo stříbrnou lilií."
    },
    {
        obec: "Těšovice",
        kod: 550582,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.057215,
        longitude: 14.024005
    },
    {
        obec: "Těšovice",
        kod: 579360,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35601,
        latitude: 50.182307,
        longitude: 12.676998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Tesovice_znak_s.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:3:1:3:1. Uprostřed červená kvádrovaná věž s cimbuřím, jejímž černým obloukovým oknem je modrý pruh prostrčen. Věž je provázená dvěma přivrácenými černými křídly se žlutým vodorovným pruhem širokým jednu devítinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně sníženě děleném štítě nahoře červená věž s cimbuřím, kvádrovaným soklem a černým obloukovým oknem, jímž zprava proniká modré břevno, přeložené dvěma přivrácenými černými křídly se zlatým břevnem. Dole stříbrný hrot, v něm a po stranách tři (2,1) kruhy opačných barev."
    },
    {
        obec: "Tetčice",
        kod: 583987,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66417,
        latitude: 49.170348,
        longitude: 16.405736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Tet%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý vlnitý se čtyřmi vrcholy a třemi prohlubněmi a zvlněný modrý, v poměru 4:1:2. V zeleném pruhu bílý květ se žlutým středem, v modrém pruhu bílá štika. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře sníženě stříbrným vlnitým břevnem děleném štítě nahoře stříbrný květ na stonku se zlatým středem, dole stříbrná štika."
    },
    {
        obec: "Tetín",
        kod: 572756,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.42462,
        longitude: 15.636457,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, a bílý klín vycházející z druhé až páté šestiny dolního okraje listu s vrcholem na horním okraji listu. V klínu modrá lilie, v modrém poli žlutá lví hlava s krkem hledící k vlajícímu okraji listu, v červeném bílá vlčí hlava s krkem a žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě stříbrný hrot s modrou lilií, provázený přivrácenými hlavami, vpravo zlatou lví, vlevo stříbrnou vlčí se zlatým jazykem."
    },
    {
        obec: "Tetín",
        kod: 531839,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 49.94942,
        longitude: 14.102357,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Tetin_BE_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List stvoří dva vodorovné pruhy modrý a červený. V modrém pruhu tři žluté latinské kříže vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se třemi zlatými latinskými kříži stříbrná dole přeložená šála."
    },
    {
        obec: "Tetov",
        kod: 575810,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.080585,
        longitude: 15.445738,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Tetov_CoA.jpg",
        coatOfArmsFlagDescription: "Modro-červeně šikmo dělený list. V modrém poli šikmo žlutý zvon. Z dolního cípu listu vyrůstají tři bílé vlčí zuby. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně šikmo děleném štítě nahoře šikmý zlatý zvon, dole tři zleva vyrůstající stříbrné vlčí zuby."
    },
    {
        obec: "Tchořovice",
        kod: 536598,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.437148,
        longitude: 13.809985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Tcho%C5%99ovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s kaprem nad kvádrovaným mostem s jedním obloukem, vycházejícím z druhé poloviny žerďového a vlajícího okraje, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kvádrovaný most s jedním obloukem. Nad ním stříbrný kapr, pod ním stříbrná zadní koňská noha se zlatým kopytem."
    },
    {
        obec: "Tichá",
        kod: 599956,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74274,
        latitude: 49.570086,
        longitude: 18.221589,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Ticha_znak.jpg",
        coatOfArmsFlagDescription: "Modro-bíle šikmo dělený list. Do modrého pole vyrůstá v žerďové části žlutá berla závitem k vlajícímu okraji. V bílém vlajícím poli dva zelené šikmé pruhy vycházející z druhé a čtvrté čtvrtiny dolního a vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně šikmým stříbrným vlnitým břevnem děleném štítě nahoře vyrůstající zlatá berla závitem doleva, dole stříbrný květ lnu."
    },
    {
        obec: "Tichonice",
        kod: 530778,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25763,
        latitude: 49.777164,
        longitude: 14.992382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Tichonice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy modrý, bílý vlnitý se třemi vrcholy a dvěma prohlubněmi a zvlněný zelený, v poměru 4 : 1 : 10. V modrém pruhu dvě osmicípé hvězdy pod sebou, v zeleném pruhu mlýnské kolo, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zúženým stříbrným vlnitým břevnem oddělenou modrou hlavou se dvěma osmihrotými hvězdami mlýnské kolo, vše zlaté."
    },
    {
        obec: "Tichov",
        kod: 535184,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76601,
        latitude: 49.177486,
        longitude: 17.982741,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Tichov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený v poměru 9:2:9. V horním pruhu žlutý snop, v dolním žlutá radlice hrotem k žerdi a ostřím k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva zlaté obilné snopy vedle sebe, pod nimi pod sebou položené stříbrná kosa násadou doprava a zlatá radlice."
    },
    {
        obec: "Tis",
        kod: 569615,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58243,
        latitude: 49.705057,
        longitude: 15.493338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Tis_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém pruhu zelený tis s černým rozdvojeným kmenem a červenými míšky. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený tis s černým rozdvojeným kmenem a červenými míšky."
    },
    {
        obec: "Tis u Blatna",
        kod: 559482,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33165,
        latitude: 50.085845,
        longitude: 13.347908
    },
    {
        obec: "Tisá",
        kod: 568309,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40336,
        latitude: 50.784605,
        longitude: 14.031415,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Coat_of_arms_of_Tis%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:2:1. V bílém pruhu zelená trojitá větévka tisu s červenými plody směřující k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným širokým kůlem tři (2,1) zelené větévky tisu s červenými plody, prostřední převrácená."
    },
    {
        obec: "Tísek",
        kod: 599964,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74301,
        latitude: 49.792766,
        longitude: 18.014705,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/T%C3%ADsek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1:4:1. V zeleném pruhu žlutý pluh bez kol obrácený k vlajícímu okraji a provázený čtyřmi bílými květy na prohnutých odvrácených stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý doleva obrácený pluh bez kol provázený čtyřmi stříbrnými květy na prohnutých a odvrácených stoncích."
    },
    {
        obec: "Tisem",
        kod: 532592,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25756,
        latitude: 49.755736,
        longitude: 14.608061
    },
    {
        obec: "Tismice",
        kod: 533734,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.056273,
        longitude: 14.821163,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Tismice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 3:1. V modrém pruhu bílé volné průčelí románského kostela se dvěma věžemi s červenými střechami zakončenými žlutými makovicemi s patriarším křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrné kvádrované průčelí románského kostela se dvěma věžemi s červenými střechami zakončenými zlatými makovicemi s patriarším křížem."
    },
    {
        obec: "Tisová",
        kod: 581062,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.936258,
        longitude: 16.224633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Tisov%C3%A1_UO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. V zeleném pruhu žlutá berla závitem k žerdi podložená otevřenou bílou knihou, ve žlutém pruhu zelená větvička tisu se třemi výhonky a sedmi (2, 3, 2) červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - zlatě polceném štítě vpravo zlatá berla podložená otevřenou stříbrnou knihou, vlevo šikmo položená zelená větévka tisu se třemi výhonky a sedmi (2, 3, 2) červenými plody."
    },
    {
        obec: "Tisová",
        kod: 541621,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34801,
        latitude: 49.765288,
        longitude: 12.713935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Tisova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 6:1. V bílém pruhu vykořeněný tis, provázený v horním rohu a cípu po jednom listu javoru klenu, vše zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad modrou patou vykořeněný tis, provázený nahoře dvěma zelenými listy javoru klenu, vše zelené."
    },
    {
        obec: "Tisovec",
        kod: 572381,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53973,
        latitude: 49.824479,
        longitude: 15.914659,
        coatOfArmsFlagDescription: "Bílý list s pěti (1, 2, 2) do kruhu odvrácenými zelenými větvičkami tisu s červenými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě do kruhu pět (1, 2, 2) odvrácených zelených větviček tisu s červenými plody."
    },
    {
        obec: "Tišice",
        kod: 535222,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27715,
        latitude: 50.269904,
        longitude: 14.554222,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Ti%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě bílých a modrých vlnitých se dvěma vrcholy a třemi prohlubněmi, v poměru 1 : 1 : 1 : 1 : 1 : 1 : 10. V dolním pruhu svěšený zelený lipový trojlist. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod třemi zúženými modrými vlnitými břevny svěšený zelený lipový trojlist."
    },
    {
        obec: "Tišnov",
        kod: 584002,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.348818,
        longitude: 16.424485,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Znak_m%C4%9Bsta_Ti%C5%A1nov.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:7:1. V modrém pruhu žlutý dvouocasý lev. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Tišnovská Nová Ves",
        kod: 596892,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.383986,
        longitude: 16.293684
    },
    {
        obec: "Tištín",
        kod: 590100,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79829,
        latitude: 49.306988,
        longitude: 17.165505,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Ti%C5%A1t%C3%ADn_PV_CZ_CoA.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený se šikmým žlutým pruhem podloženým pod bílým pruhem širokým jednu šestinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrná vztyčená radlice šikmo podložená stříbrnou vztyčenou otkou na zlaté násadě."
    },
    {
        obec: "Tlumačov",
        kod: 554341,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.40364,
        longitude: 12.929095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Znak_obce_Tluma%C4%8Dov_u_Doma%C5%BElic_verze_3.svg",
        coatOfArmsFlagDescription: "Červeno - modře čtvrcený list. Uprostřed žlutý květ třezalky tečkované. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - modře čtvrcený štít, v 1. poli vyrůstající stříbrný čakan, ve 2. poli dvě zlaté vlčí udice pod sebou, dole tři (2, 1) zlaté květy třezalky tečkované."
    },
    {
        obec: "Tlučná",
        kod: 559491,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33026,
        latitude: 49.724362,
        longitude: 13.235453,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Znak_obce_Tlu%C4%8Dn%C3%A1.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed dvě propletené a odvrácené dvojice ječmenných klasů. Nad klasy zkřížená hornická kladívka, pod klasy krokodýl, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě propletené a odvrácené dvojice ječmenných klasů, převýšené hornickými kladívky, pod klasy krokodýl, vše zlaté."
    },
    {
        obec: "Tlumačov",
        kod: 585858,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76362,
        latitude: 49.253605,
        longitude: 17.495694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Tluma%C4%8Dov_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři kosmé pruhy, střídavě červené a bílé, uprostřed listu do červeného pruhu vyrůstá bílá rozkřídlená husa se žlutou zbrojí, pod ní v bílém pruhu kosmo červená zavinutá střela. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně kosmo dělený štít, v horním poli vyrůstá z dělící čáry stříbrná rozkřídlená husa se zlatým zobákem. V dolním poli kosmo položená červená zavinutá střela."
    },
    {
        obec: "Tlustice",
        kod: 531847,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26801,
        latitude: 49.850393,
        longitude: 13.885465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Tlustice_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh, široký jednu třetinu délky listu, a tři vodorovné pruhy, bílý, modrý a žlutý. V červeném pruhu bílý dospělec jepice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém červeném poli stříbrný dospělec jepice, levé pole dvakrát stříbrno-modro-zlatě dělené."
    },
    {
        obec: "Tmaň",
        kod: 531855,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26721,
        latitude: 49.907174,
        longitude: 14.033932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Tma%C5%88_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký jednu třetinu délky listu a pět vodorovných pruhů, střídavě červených a bílých. V modrém pruhu ve žluté váze se dvěma uchy tři červené šestilisté květy se žlutými středy na zelených stoncích se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít se stříbrným zúženým kůlem. Vpravo ve zlaté váze s uchy tři červené šestilisté květy se zlatými středy na zelených stoncích se dvěma listy, vlevo zkřížené stříbrné lamačské špičáky na zlatých násadách."
    },
    {
        obec: "Točník",
        kod: 534463,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.8855,
        longitude: 13.882878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/To%C4%8Dn%C3%ADk_znak.jpg",
        coatOfArmsFlagDescription: "Modro-žlutě kosmo dělený list. V dolním žerďovém poli k vlajícímu okraji obrácený ledňáček v točenici, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženou kvádrovanou hradbou s cimbuřím doleva obrácený ledňáček v točenici, vše zlaté."
    },
    {
        obec: "Tochovice",
        kod: 541427,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26281,
        latitude: 49.59559,
        longitude: 13.992506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Tochovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva žerďové pruhy, bílý a modrý, každý široký jednu šestinu délky listu a žluté pole s černým vykračujícím koněm. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina sedmkrát stříbrno-modře polcena, v dolní zlaté polovině vykračující černý kůň."
    },
    {
        obec: "Tojice",
        kod: 578614,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.492585,
        longitude: 13.62662
    },
    {
        obec: "Tomice",
        kod: 532541,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25768,
        latitude: 49.645143,
        longitude: 15.157197,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Tomice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, ve střední části listu lilie nad zkříženými klíči, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě lilie nad zkříženými klíči, vše zlaté."
    },
    {
        obec: "Topolany",
        kod: 593630,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.277451,
        longitude: 17.040411,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Znak_Topolany.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří pět svislých pruhů, bílý, zelený, bílý, zelený a bílý v poměru 1:1:3:1:1. V prostředním pruhu zelený topol s černým kmenem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený kůl, v jehož horní polovině je 6 (4,2) stříbrných kuželů, v dolní části 3 (1,2) zlatá jablka. Po obou stranách kůlu vyrůstá ze zeleného trávníku topol přirozených barev."
    },
    {
        obec: "Topolná",
        kod: 592692,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68711,
        latitude: 49.121967,
        longitude: 17.544444,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Topoln%C3%A1%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 2:1:2. V bílém pruhu zelený topol s hnědým kmenem, v žerďovém pruhu žlutá, ve vlajícím bílá růže s červenými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůl s topolem přirozené barvy provázený vpravo zlatou růží a vlevo stříbrnou růží s červenými semeníky."
    },
    {
        obec: "Toušice",
        kod: 533742,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.987988,
        longitude: 15.006578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Tou%C5%A1ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 9:1:8. V horním modrém pruhu na bílých odkloněných stoncích pět šestilistých květů, střídavě červených a bílých. V dolním modrém pruhu žlutá osmicípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný most se třemi oblouky, z něhož vyrůstá na odkloněných stříbrných stoncích pět šestilistých květů, střídavě tři červené a dva stříbrné. Ve středním oblouku mostu zlatá osmihrotá hvězda, dole dvě zúžená stříbrná vlnitá břevna."
    },
    {
        obec: "Toužetín",
        kod: 566829,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.315391,
        longitude: 13.886229,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Tou%C5%BEet%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový klín sahající do středu listu a pět vodorovných pruhů, střídavě bílých a zelených. V klínu pět vodorovných pruhů, modrý, žlutý, fialový, žlutý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad zvýšenou modrou patou se dvěma zlatými kůly květ šafránu se šesti listy (3, 3), vše přirozené barvy."
    },
    {
        obec: "Toužim",
        kod: 555657,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36401,
        latitude: 50.060604,
        longitude: 12.985171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Touzim_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, černý a modrý. V černém pruhu žlutý vykračující lev s červenou zbrojí a korunou, v modrém bílé levé obrněné rameno držící červené srdce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo - modře děleném štítě nahoře zlatý vykračující lev s červenou zbrojí a korunou, dole stříbrné levé obrněné rameno držící červené srdce."
    },
    {
        obec: "Tovačov",
        kod: 519146,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75101,
        latitude: 49.429711,
        longitude: 17.285739,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Tova%C4%8Dov_znak.png",
        coatOfArmsFlagDescription: "List čtvrcený, horní žerďové a dolní vlající pole bílé, horní vlající a dolní žerďové pole modré. Na středu červený štít se třemi bílými vodorovnými oboustranně zubatými pruhy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku mezi červenou a stříbrnou kulatou věží se třemi okny, cimbuřím a valbovou střechou erb pánů z Cimburka - v červeném štítě 3 stříbrná cimbuřová břevna, kolčí korunovaný helm s rozevřenými křídly opakujícími znamení štítu, přikryvadla červeno-stříbrná."
    },
    {
        obec: "Tovéř",
        kod: 552089,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78316,
        latitude: 49.640125,
        longitude: 17.31912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Tov%C3%A9%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 4 : 1. V zeleném pruhu kosa bez kosiště ostřím k žerďovému okraji listu a radlice ostřím k vlajícímu okraji, oboje žluté a hroty k hornímu okraji listu. Z radlice vyniká ke středu listu bíle oděná levá ruka držící na bílé žerdi žlutý dvojcípý praporek vlající k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vedle sebe vztyčená zlatá kosa bez kosiště a vztyčená, doleva obrácená zlatá radlice, z níž do středu vyniká stříbrně oděná levá ruka držící na stříbrné žerdi zlatý dvojcípý praporek vlající doprava. V patě štítu vyrůstají tři dole spojené odkloněné obilné klasy se dvěma listy po stranách."
    },
    {
        obec: "Traplice",
        kod: 592706,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68704,
        latitude: 49.130976,
        longitude: 17.436325,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Traplice%2C_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-modře kosmo a šikmo čtvrcený list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně kosmo čtvrcený štít v prvním poli zlatá lilie, ve druhém stříbrná vztyčená radlice ostřím doleva, ve třetím stříbrný vinařský nůž - kosíř a ve čtvrtém zlatá baňatá keramická láhev."
    },
    {
        obec: "Travčice",
        kod: 565741,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.503389,
        longitude: 14.189878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Travcice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list; v žerďové polovině vzpřímený černý býk se zelenou zbrojí s červeným jazykem, držící zelený obilný klas se dvěma listy. Býk má kolem krku zelený věnec se třemi červenými růžemi se žlutými semeníky a na boku bílou, žlutě zdobenou mitru kosmo podloženou žlutou berlou závitem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vzpřímený černý býk se zelenou zbrojí a červeným jazykem, držící zelený obilný klas se dvěma listy. Býk má kolem krku zelený věnec se třemi červenými růžemi se zlatými semeníky a na boku stříbrnou, zlatě zdobenou mitru kosmo podloženou zlatou berlou."
    },
    {
        obec: "Trboušany",
        kod: 584011,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66464,
        latitude: 49.050783,
        longitude: 16.46295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Trbou%C5%A1any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a červený, v poměru 3:2:1. V žerďové části modrého pruhu bílý vztyčený vinařský nůž se žlutou rukojetí, ostřím k žerdi a žlutý uťatý výhonek chřestu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-modře polcený štít, vpravo půl černého orla s červenou zbrojí, majícího na hrudi červené srdce přeložené vztyčeným stříbrným mečem a na křídle stříbrnou růži, vlevo vedle sebe vztyčený stříbrný vinařský nůž se zlatou rukojetí a uťatý zlatý výhonek chřestu, pod nimi zlatý vinný hrozen se dvěma listy."
    },
    {
        obec: "Trhanov",
        kod: 554359,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34533,
        latitude: 49.420341,
        longitude: 12.845635,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Trhanov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a černý. Uprostřed listu bílé palečné kolo ve svatozáři s pěti (1, 2, 2) šesticípými hvězdami a dole zavěšenou vlčí udicí, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě stříbrné palečné kolo ve svatozáři s pěti (1, 2, 2) hvězdami a dole zavěšenou vlčí udicí, vše zlaté."
    },
    {
        obec: "Trhová Kamenice",
        kod: 572390,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53952,
        latitude: 49.786894,
        longitude: 15.816049,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Trhov%C3%A1_Kamenice_CoA.png",
        coatOfArmsFlagDescription: "Červený list s bílým dvouocasým lvem se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Trhové Dušníky",
        kod: 598429,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26101,
        latitude: 49.71355,
        longitude: 14.012935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Coats_of_arms_Trhov%C3%A9_Du%C5%A1n%C3%ADky.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy červený a modrý. Uprostřed mezi dvěma bílými buvolími rohy jetelový trojlístek převýšený korunou, obojí žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě mezi dvěma stříbrnými buvolími rohy jetelový trojlístek převýšený korunou, obojí zlaté."
    },
    {
        obec: "Trhové Sviny",
        kod: 545171,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.842418,
        longitude: 14.639338,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Trhove_Sviny_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou zaoblenou střílnou, v ní červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná věž s cimbuřím, černým oknem, červenou valbovou střechou a dvěma zlatými makovicemi přiléhající k stříbrné kvádrované hradbě s cimbuřím. Ve spodní části věže červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Trhový Štěpánov",
        kod: 530816,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25763,
        latitude: 49.711576,
        longitude: 15.013668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Trhov%C3%BD_%C5%A0t%C4%9Bp%C3%A1nov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 1:6:1. Uprostřed červená, žlutě zdobená knížecí koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Trmice",
        kod: 553697,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40004,
        latitude: 50.642967,
        longitude: 13.994605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Trmice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený, bílý, modrý, žlutý, bílý a červený, v poměru 2:2:1:1:2:2. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Trnava",
        kod: 591840,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.254784,
        longitude: 15.924247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Trnava_TR_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený a bílý. V žerďové části zeleného pruhu kosmo bílý meč se žlutým jílcem hrotem k hornímu rohu šikmo přeložený žlutým gotickým klíčem zuby nahoru a k dolnímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší s kosmo vztyčeným stříbrným mečem se zlatým jílcem šikmo přeloženým zlatým gotickým klíčem koniklec přirozené barvy."
    },
    {
        obec: "Trnava",
        kod: 585866,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76318,
        latitude: 49.296132,
        longitude: 17.842017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Trnava_ZL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým jelenem s červeným jazykem a kopýtky kráčejícím po bílém meči hrotem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý jelen s červeným jazykem a kopýtky kráčející po obnaženém stříbrném meči."
    },
    {
        obec: "Trnávka",
        kod: 569755,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74258,
        latitude: 49.684846,
        longitude: 18.178045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Trn%C3%A1vka_NJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu třetinu délky listu se třemi žlutými liliemi pod sebou a tři vodorovné pruhy: bílý, červený a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně polcený štít, vpravo tři zlaté lilie pod sebou, vlevo červené břevno."
    },
    {
        obec: "Trnávka",
        kod: 530794,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.032844,
        longitude: 15.461124,
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 4 : 1. Ze zeleného pruhu vyrůstá černý trnkový keř se třemi odkloněnými bezlistými trnitými větvemi se šesti (2, 1, 3) modrými plody. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trávníku černý trnkový keř se třemi odkloněnými bezlistými trnitými větvemi se šesti (2, 1, 3) modrými plody."
    },
    {
        obec: "Trnov",
        kod: 576824,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51733,
        latitude: 50.244775,
        longitude: 16.164517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Trnov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, vlnitý bílý, vlnitý zelený, vlnitý bílý a zvlněný zelený, v poměru 4 : 1 : 1 : 1 : 1. V žerďové polovině červeného pruhu vztyčená žlutá oboustranně uťatá větev se dvěma listy a čtyřmi trny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně stříbrným vlnitým břevnem dělený štít, nahoře vztyčená zlatá oboustranně uťatá větev se dvěma listy a čtyřmi trny. Dole kosmo vztyčená, doleva obrácená radlice a šikmá, čelně postavená motyka bez násady, obojí stříbrné."
    },
    {
        obec: "Trnová",
        kod: 559504,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33013,
        latitude: 49.861152,
        longitude: 13.323963,
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modro - červeně dělený a bílý. V modrém poli bílá růže se žlutým semeníkem a kališními lístky, v bílém poli svisle černý prut s pěti (3, 2) trny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít,vpravo modro - červeně dělený, nahoře stříbrná růže se zlatým semeníkem a kališními lístky, v levém stříbrném poli vztyčený černý prut s pěti (3, 2) trny."
    },
    {
        obec: "Trnová",
        kod: 598313,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.915541,
        longitude: 14.357824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Trnova_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modro - červeně dělený a bílý. V modrém poli bílá růže se žlutým semeníkem a kališními lístky, v bílém poli svisle černý prut s pěti (3, 2) trny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít,vpravo modro - červeně dělený, nahoře stříbrná růže se zlatým semeníkem a kališními lístky, v levém stříbrném poli vztyčený černý prut s pěti (3, 2) trny."
    },
    {
        obec: "Trnovany",
        kod: 542407,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.543317,
        longitude: 14.17905,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Trnovany_LT_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s černým klínem vycházejícím z šesté a sedmé dvanáctiny dolního okraje listu s vrcholem na horním okraji listu. V žerďové polovině listu černá větvička šípkové růže se dvěma červenými plody nahoře a pěti zelenými listy, ve vlající polovině zelený vinný hrozen s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zúžený černý hrot provázený vpravo černou větvičkou šípkové růže se dvěma červenými plody nahoře a pěti zelenými listy, vlevo zeleným vinným hroznem s listem."
    },
    {
        obec: "Trnové Pole",
        kod: 594954,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.94504,
        longitude: 16.409578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Coat_of_arms_of_Trnov%C3%A9_Pole.svg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným trnovým lemem širokým desetinu šířky listu, uprostřed červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným trnovým lemem červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Trojanovice",
        kod: 599999,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74401,
        latitude: 49.520494,
        longitude: 18.238113,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Coat_of_arms_of_Trojanovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený. V zeleném pruhu u žerďového okraje žluté srdce. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo dvě zlatá srdce nad sebou, vlevo čtyři (2, 2) stříbrné kužely."
    },
    {
        obec: "Trojovice",
        kod: 572403,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53833,
        latitude: 49.932925,
        longitude: 15.919674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Trojovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 5 : 3. V modrém pruhu zkřížené berly, provázené čtyřmi (1, 2, 1) liliemi, vše žluté. V červeném pruhu tři (2, 1) žluté růže s modrými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně snížené dělený štít. Nahoře zkřížené berly provázené čtyřmi (1, 2, 1) liliemi, vše zlaté. Dole tři (2, 1) zlaté růže s modrými semeníky."
    },
    {
        obec: "Trokavec",
        kod: 579246,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.647028,
        longitude: 13.702351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Trokavec_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 1:2:1. Uprostřed horního zeleného pruhu žlutá pohanská koruna z níž do bílého pruhu splývají černo-červená plášťová přikrývadla. Pod nimi v bílém a zeleném pruhu tři zelené smrky s hnědými kmeny, prostřední větší. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrný štít se zelenou hlavou, v ní zlatá pohanská koruna z níž do stříbrného pole splývají černo-červená plášťová přikrývadla. Pod nimi ze zeleného návrší vyrůstají tři smrky přirozené barvy, prostřední vyšší."
    },
    {
        obec: "Troskotovice",
        kod: 594962,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67178,
        latitude: 48.919119,
        longitude: 16.437643,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/Troskotovice_znak.jpg"
    },
    {
        obec: "Troskovice",
        kod: 577600,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51263,
        latitude: 50.511687,
        longitude: 15.22242
    },
    {
        obec: "Trotina",
        kod: 579751,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.409736,
        longitude: 15.716281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Trotina_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 7 : 9. V bílém pruhu vztyčený zelený lipový dvojlist. V modrém pruhu bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře zvýšeně vlnitě děleném štítě nahoře vztyčený zelený lipový dvojlist, dole stříbrné mlýnské kolo."
    },
    {
        obec: "Troubelice",
        kod: 505293,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78383,
        latitude: 49.817411,
        longitude: 17.08111,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Troubelice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutou osmicípou hvězdou, provázenou v rozích a cípech čtyřmi bílými pětilistými květy se žlutými středy a kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá osmihrotá hvězda kvetoucí na stříbrných stoncích čtyřmi stříbrnými pětilistými květy se zlatými středy a kališními lístky."
    },
    {
        obec: "Troubky",
        kod: 519651,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75102,
        latitude: 49.432248,
        longitude: 17.34925,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Troubky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list s červenou loveckou trubkou se žlutým kováním a s červeným řemením ve střední části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-stříbrně čtvrcený štít, v 1. poli černá zubří hlava se zlatými rohy a houžví, ve 2. a 3. poli červená lovecká trubka s červeným řemením, náústky ven, ve 4. poli černá radlice."
    },
    {
        obec: "Troubky-Zdislavice",
        kod: 589080,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76802,
        latitude: 49.230501,
        longitude: 17.268275,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Troubky-Zdislavice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polcenou lilií mezi dvěma bílými přivrácenými loveckými trubkami se žlutým kováním, závěsy a náústky nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě stříbrné přivrácené lovecké trubky se zlatým kováním, závěsy a náústky nahoru, mezi nimi stříbrno-červeně polcená lilie."
    },
    {
        obec: "Troubsko",
        kod: 584029,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66441,
        latitude: 49.169608,
        longitude: 16.510886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Troubsko_znak.png",
        coatOfArmsFlagDescription: "Modrý list se žlutým stonkem vinné révy se dvěma hrozny a třemi listy, bílým vinařským nožem - kosířem a krojidlem, oboje ostřím k žerdi a hrotem k hornímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vpravo zlatý stonek vinné révy se dvěma hrozny a třemi listy, vlevo vedle sebe vinařský nůž a krojidlo, obé stříbrné."
    },
    {
        obec: "Trpík",
        kod: 547921,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.847575,
        longitude: 16.570003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Trp%C3%ADk.svg",
        coatOfArmsFlagDescription: "Žlutý list se žerďovým klínem s vrcholem ve středu listu zakončeným jetelovým trojlistem, oboje zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyniká z levého horního koutu jetelový trojlist, oboje zelené."
    },
    {
        obec: "Trpín",
        kod: 578886,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56974,
        latitude: 49.593988,
        longitude: 16.402214,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Trp%C3%ADn_Coa_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílý vodorovný pruh široký jednu čtvrtinu šířky listu a dva svislé pruhy zelený a červený. V bílém pruhu červená koruna, v zeleném u středu žlutý obilný snop, v červeném u středu bílá kvádrovaná věž s cimbuřím a jedním černým oknem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě pod stříbrnou hlavou s červenou korunou vpravo zlatý obilný snop, vlevo stříbrná kvádrovaná věž s cimbuřím a jedním černým oknem."
    },
    {
        obec: "Trpísty",
        kod: 561291,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.822747,
        longitude: 13.058639,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Trpisty_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, modrý, červený a bílý, v poměru 1:3:3:1. Uprostřed listu pod žlutou korunou tři (1,2) rohy se dotýkající bílé obdélníky, v modrém pruhu jeden, v červeném dva. Výška obdélníků je jedna osmina šířky listu a délka jedna pětina šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě s červenou patou na dělící linii tři (1,2) dotýkající se stříbrné kvádry, na horním zlatá koruna, z níž vyrůstá stříbrná rozkřídlená labuť s červeným zobákem a zlatým jazykem a dvěma svěšenými zelenými lipovými listy na křídlech."
    },
    {
        obec: "Trstěnice",
        kod: 594971,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67171,
        latitude: 48.986862,
        longitude: 16.195522,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Trstenice_ZN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 10:2:3. V horním modrém pruhu polovina žlutého rozkřídleného draka s červeným jazykem a zeleným vinným hroznem na hrudi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě polovina zlatého rozkřídleného draka s červeným jazykem a zeleným vinným hroznem na hrudi, pod ním dolní polovina stříbrného palečného kola."
    },
    {
        obec: "Trpišovice",
        kod: 569623,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.655284,
        longitude: 15.332281,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Coat_of_arms_of_Trpi%C5%A1ovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, v poměru 1 : 3 : 1, bílý, zelený s bílým k žerďovému okraji otočeným bramborovým květem se žlutým středem a zvlněný modrý se třemi vrcholy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrný štít se zvýšeným zeleným vrchem nad modrou vlnitou patou. Ve vrchu stříbrný bramborový květ se zlatým středem."
    },
    {
        obec: "Trstěnice",
        kod: 554855,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.91921,
        longitude: 12.676134,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Trst%C4%9Bnice_%28Cheb_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zeleno-červeně polcený a modrý. V zeleném poli dva žluté odvrácené obilné klasy s listem, v červeném bílá psí hlava se žlutým jazykem a v modrém žlutý pstruh. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít se sníženým modrým hrotem. V pravém zeleném poli dva odvrácené zlaté obilné klasy s listem, v levém červeném poli stříbrná psí hlava se zlatým jazykem, v hrotu zlatý pstruh."
    },
    {
        obec: "Trstěnice",
        kod: 578894,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56957,
        latitude: 49.794239,
        longitude: 16.348852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Trst%C4%9Bnice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, červený, bílý a modrý, v poměru 1:1:4:1:1. V červeném pruhu žlutý kříž s rameny stejně širokými jako bílé pruhy. Osa svislých ramen kříže je v první čtvrtině délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě zlatý latinský kříž do krajů, provázený v dolním poli vpravo lilií a vlevo zvonem se závěsem, obojí stříbrné."
    },
    {
        obec: "Tršice",
        kod: 505366,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78357,
        latitude: 49.541173,
        longitude: 17.42708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Tr%C5%A1ice_CoA.png"
    },
    {
        obec: "Trubín",
        kod: 533106,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26701,
        latitude: 49.94378,
        longitude: 14.002294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Trub%C3%ADn%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. V horní části listu zkřížené klíče, v červeném pruhu žluté, v bílém modré. Klíče zdola držené tlapami odvráceně ohnutých nohou, v červeném pruhu bílou lví se žlutou zbrojí, v bílém pruhu černou medvědí s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě zkřížené zlato-modře polcené klíče zdola držené tlapami odvráceně ohnutých nohou, vpravo stříbrnou lví se zlatou zbrojí, vlevo černou medvědí s červenou zbrojí."
    },
    {
        obec: "Trubská",
        kod: 531880,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 49.95498,
        longitude: 13.993555,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Trubsk%C3%A1_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list se zeleným žerďovým pruhem širokým čtvrtinu délky listu. V pruhu vztyčená žlutá trubka. Do žlutého pole z dolního okraje listu vyrůstá zelený dub s černým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou hlavou se zlatou trubkou vyrůstající zelený dub s černým kmenem."
    },
    {
        obec: "Truskovice",
        kod: 536512,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.104163,
        longitude: 14.161196,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Truskovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3:2:3. V horním pruhu vodorovně žlutý gotický klíč zuby nahoru a k vlajícímu okraji, v prostředním pruhu tři modré květy lnu, v dolním pruhu vodorovně žlutý gotický klíč zuby dolů a k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi modrými květy lnu dva položené zlaté gotické klíče, horní doleva, s odvrácenými zuby."
    },
    {
        obec: "Trusnov",
        kod: 575828,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.000215,
        longitude: 16.044093,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Trusnov_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed čtyři žluté obilné klasy se stvoly přeloženými dvěma vodorovnými pruhy, žlutým a bílým, vycházejícími z deváté a jedenácté čtrnáctiny žerďového a vlajícího okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě čtyři zlaté obilné klasy přeložené přes stvoly zlatou štikou a pod ní doleva plovoucím stříbrným kaprem."
    },
    {
        obec: "Trutnov",
        kod: 579025,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54101,
        latitude: 50.565484,
        longitude: 15.909192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Trutnov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list se třemi bílými žerďovými klíny sahajícími do poloviny délky listu. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Tržek",
        kod: 572616,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.886335,
        longitude: 16.260047
    },
    {
        obec: "Třanovice",
        kod: 552623,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73953,
        latitude: 49.712553,
        longitude: 18.529296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Trzanowice_herb.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý v poměru 14:1:1. V modrém poli žlutý kalich mezi dvěma bílými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným břevnem zlatý kalich provázený stříbrnými růžemi se zlatým semeníkem."
    },
    {
        obec: "Třebařov",
        kod: 578908,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56933,
        latitude: 49.829794,
        longitude: 16.702624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/T%C5%99eb%C3%A1%C5%99ov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu se třemi dolů obrácenými červenými mušlemi pod sebou a modré pole s bílou vztyčenou dubovou větví se třemi listy nahoře a dvěma žaludy po stranách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi dolů obrácenými červenými mušlemi vztyčená stříbrná dubová větev se třemi listy nahoře a dvěma žaludy po stranách."
    },
    {
        obec: "Třebčice",
        kod: 578592,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33501,
        latitude: 49.483479,
        longitude: 13.612175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/T%C5%99eb%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený, v poměru 4 : 1 : 4. V modrém pruhu žlutá osmicípá hvězda, nahoře a dole provázená čtyřmi (2, 2) bílými šesticípými hvězdami, v zeleném pruhu žlutý zvon s modrou lilií. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zeleně stříbrným vlnitým břevnem dělený štít. Nahoře zlatá osmihrotá hvězda provázená čtyřmi (2, 2) stříbrnými hvězdami, dole zlatý zvon s modrou lilií."
    },
    {
        obec: "Třebechovice pod Orebem",
        kod: 571041,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50346,
        latitude: 50.201066,
        longitude: 15.992336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/CoA_of_T%C5%99ebechovice_pod_Orebem.svg",
        coatOfArmsFlagDescription: "Žlutý list, uprostřed svislá hnědá ostrev převýšená zlatou, červeně vyloženou korunou a provázená po každé straně třemi (1,2) dotýkajícími se modrými dělovými koulemi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Třebějice",
        kod: 563153,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.244526,
        longitude: 14.81988
    },
    {
        obec: "Třebelovice",
        kod: 591858,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67532,
        latitude: 49.022254,
        longitude: 15.666335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Coat_of_arms_of_T%C5%99ebelovice.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, zubatý černý, zubatý modrý a bílý. Zubaté pruhy mají po dvou zubech sahajících do třetiny bílých pruhů a po třech stejných mezerách. Na černém a modrém pruhu tři žluté trojlístky na stoncích (2,1). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-modře polceném štítě stříbrná kvádrovaná hradba se dvěma prázdnými klíčovými střílnami a třemi stínkami, nad nimi tři vztyčené zlaté trojlístky na stoncích."
    },
    {
        obec: "Třebeň",
        kod: 539023,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.128744,
        longitude: 12.400268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8f/T%C5%99ebe%C5%88_coA.jpg",
        coatOfArmsFlagDescription: "Zelený list s červeno-bíle pětkrát děleným a sedmkrát polceným lemem širokým pětinu šířky listu. Uprostřed listu žlutý obilný klas se dvěma listy, podložený zkříženými bílými palcáty. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s červeno-stříbrně šachovaným lemem zlatý obilný klas se dvěma listy, podložený zkříženými stříbrnými palcáty."
    },
    {
        obec: "Třebenice",
        kod: 591866,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.163856,
        longitude: 16.015873,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 2:1:1. V žerďové a vlající části horního modrého pruhu žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s cimbuřím, v ní vztyčená modrá radlice. Nad hradbou stříbrný patriarší kříž provázený v rozích dvěma zlatými liliemi."
    },
    {
        obec: "Třebenice",
        kod: 565768,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41113,
        latitude: 50.476429,
        longitude: 13.990159,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/T%C5%99ebenice_znak.png",
        coatOfArmsFlagDescription: "Žlutý list s rytířem v šedé plátové zbroji stojícím na zelené, na zádech ležící sani bez křídel s červenou zbrojí, obojí otočené k vlajícímu okraji. Rytíř drží v pravé ruce nad hlavou meč se žlutým jílcem, levou rukou vráží hnědý dřevec do tlamy saně. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Třebestovice",
        kod: 537896,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28912,
        latitude: 50.124705,
        longitude: 14.958358,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Coats_of_arms_T%C5%99ebestovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a modré, v poměru 2:1:1:4. V žerďové polovině horního bílého pruhu vedle sebe tři červené růže se žlutými semeníky. V žerďové polovině dolního modrého pruhu žlutá kypřice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky stříbrné ostrvové břevno nad zlatou kypřicí."
    },
    {
        obec: "Třebešice",
        kod: 532304,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.775348,
        longitude: 14.831949,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený se žlutým lemem širokým osmnáctinu šířky listu, modrý a bílý, v poměru 2 : 1 : 1. V červeném žerďovém pruhu tři žluté koruny pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém modrém poli nahoře stříbrná utržená hlava psa-věžníka se zlatým obojkem, dole zlatá hlavice berly, v levém červeném, zlatě lemovaném poli tři zlaté koruny pod sebou."
    },
    {
        obec: "Třebešice",
        kod: 530981,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.927948,
        longitude: 15.337909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/T%C5%99ebe%C5%A1ice_%28Kutn%C3%A1_Hora_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 3 : 2 : 3. V modrém pruhu pět šesticípých hvězd vedle sebe, v červeném spodní polovina vozového kola, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrno-červeně dělený štít. V horním poli pět hvězd vedle sebe, v dolním poli spodní polovina vozového kola, vše stříbrné."
    },
    {
        obec: "Třebešov",
        kod: 576832,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51601,
        latitude: 50.16845,
        longitude: 16.204241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/T%C5%99ebe%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílo - červeně dělený zubatý žerďový pruh a modré pole. V něm k vlajícímu okraji obrácený bílý beránek přidržující levou nohou kosmý žlutý procesní kříž s bílou dvoucípou korouhví s červeným křížem. Zubatý pruh má tři čtvercové a dva poloviční zuby a čtyři stejné mezery. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrno - červeně polcenou cimbuřovou hlavou doleva obrácený stříbrný beránek přidržující levou nohou kosmý zlatý procesní kříž se stříbrnou dvoucípou korouhví s červeným heroldským křížem."
    },
    {
        obec: "Třebětice",
        kod: 562327,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.047741,
        longitude: 15.52082,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/T%C5%99eb%C4%9Btice_%28okres_Jind%C5%99ich%C5%AFv_Hradec%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a pět vodorovných pruhů, bílý, zelený, červený, zelený a bílý. V žerďovém pruhu žlutý obilný klas se dvěma listy, nad ním ve žlutém mezikruží knížecí čepice ozdobená jablkem s křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo nad zlatým obilným klasem se dvěma listy ve zlatém kruhu knížecí čepice ozdobená jablkem s křížkem, vlevo na zeleném návrší moták pochop přirozené barvy."
    },
    {
        obec: "Třebětice",
        kod: 589098,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.319485,
        longitude: 17.519351,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/T%C5%99eb%C4%9Btice_KR_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s žerďovým bílým klínem s vrcholem na vlajícím okraji listu. V klínu položena přirozená růže se dvěma červenými květy na zeleném stonku s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrný vydutý klín provázený dvěma vztyčenými odvrácenými krojidly. V klínu přirozená růže se dvěma červenými květy na zeleném stonku s listy."
    },
    {
        obec: "Třebětín",
        kod: 530972,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.77084,
        longitude: 15.269464
    },
    {
        obec: "Třebíč",
        kod: 590266,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.214887,
        longitude: 15.879652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c5/CoA_of_Trebic.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - červený, bílý a červený v poměru 2:3:2. Ve středu bílého pruhu tři černé kápě z městského znaku. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Třebihošť",
        kod: 579769,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.433387,
        longitude: 15.709795
    },
    {
        obec: "Třebichovice",
        kod: 532959,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27306,
        latitude: 50.190464,
        longitude: 14.079146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/T%C5%99ebichovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, vycházejícím z osmé šestnáctiny žerďového a vlajícího okraje listu. Uprostřed listu dvě přivrácené bledule jarní s prohnutými, dole spojenými stonky s listy. Stonek k žerďovému okraji pruhem podložený, stonek k vlajícímu okraji pruhem přeložený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě dvě přivrácené bledule jarní na dole spojených prohnutých stoncích s listy, vše přirozené barvy, vpravo podložené a vlevo přeložené zúženým stříbrným vlnitým břevnem."
    },
    {
        obec: "Třebívlice",
        kod: 565776,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41115,
        latitude: 50.45817,
        longitude: 13.899372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Trebivlice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se svislým modrým žerďovým pruhem širokým jednu třetinu délky listu. Přes celý list tři vodorovné červené pruhy, ve vlající třetině se vlnitě zužující (se dvěma vrcholy) s hroty na vlajícím okraji. Šířky vzniklých modrých a červených pruhů na žerďovém okraji jsou jednou sedminou šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě od středu do krajů štítu osm červených plamenů, přes ně ve středu modro-stříbrně dělený štítek se třemi (2,1) dotýkajícími se vybroušenými českými granáty přirozené barvy, drženými zlatými zrnky."
    },
    {
        obec: "Třebíz",
        kod: 532967,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27375,
        latitude: 50.269721,
        longitude: 13.990901
    },
    {
        obec: "Třebnouševes",
        kod: 573612,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50801,
        latitude: 50.339344,
        longitude: 15.651767,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/CoA_of_T%C5%99ebnou%C5%A1eves.svg",
        coatOfArmsFlagDescription: "Červený list s černým žerďovým klínem. V klínu žluté mezikruží široké dvacetinu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě černý klín, v něm zlatý kruh."
    },
    {
        obec: "Třeboc",
        kod: 542504,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27054,
        latitude: 50.214417,
        longitude: 13.752838
    },
    {
        obec: "Třebohostice",
        kod: 551899,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38737,
        latitude: 49.33264,
        longitude: 13.861096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/T%C5%99ebohostice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 2 : 1 : 1. V horním zeleném pruhu mezi dvěma bílými vztyčenými lipovými listy knížecí čepice ozdobená křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou stříbrný kvádrovaný most s jedním obloukem, nad ním knížecí čepice ozdobená křížkem mezi dvěma vztyčenými stříbrnými lipovými listy."
    },
    {
        obec: "Třebom",
        kod: 569101,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74725,
        latitude: 50.046244,
        longitude: 18.025387,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/T%C5%99ebom_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou dračí hlavou čelistí nahoru, probodnutou žlutým mečem hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V pravé části zeleného štítu vyniká z dolního okraje obloukovitě prohnutý zlatý klas, vlevo stříbrná vzhůru hledící dračí hlava s otevřenou tlamou, probodenou zlatým mečem."
    },
    {
        obec: "Třeboň",
        kod: 547336,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.003615,
        longitude: 14.764232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Znak_mesta_trebon_II.svg",
        coatOfArmsFlagDescription: "List se dvěma vodorovnými pruhy, horní bílý s červenou růží z městského znaku, dolní červený. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Třebonín",
        kod: 571741,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28544,
        latitude: 49.869804,
        longitude: 15.313026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/T%C5%99ebon%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vypouklý zelený s bílým zvonem se závěsem, v poměru 3 : 1. V bílém pruhu modrý kříž s rameny širokými osminu délky listu, vodorovná ramena vycházejí z páté až sedmé šestnáctiny šířky listu. Ze zeleného pruhu vyniká do kříže kopí s bílým hrotem a žlutým ratištěm. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší se stříbrným zvonem se závěsem modrý heroldský kříž, do něhož vyniká z návrší kopí se stříbrným hrotem a zlatým ratištěm."
    },
    {
        obec: "Třebosice",
        kod: 572985,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.997348,
        longitude: 15.734932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/T%C5%99ebosice_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s vodorovným bílým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, vycházejícím ze sedmé osminy žerďového okraje listu. Nad ním v žerďové polovině listu čelně žlutá kozlí hlava. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě čelně zlatá kozlí hlava nad stříbrným vlnitým břevnem."
    },
    {
        obec: "Třebotov",
        kod: 539759,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25226,
        latitude: 49.972514,
        longitude: 14.291274,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/T%C5%99ebotov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový bílo-červeně polcený pruh široký třetinu délky listu a zelené pole. V žerďovém pruhu osmihrotý kříž nad šesticípou hvězdou, obojí polcené a opačných barev. Ve střední části listu žlutá berla závitem k žerďovému okraji, dole přeložená vykračující bílou rozkřídlenou husou se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. V pravém stříbrno-červeně polceném poli osmihrotý kříž nad hvězdou, obojí polcené a opačných barev. V levém zeleném poli zlatá berla, dole přeložená vykračující stříbrnou rozkřídlenou husou se zlatou zbrojí."
    },
    {
        obec: "Třebovice",
        kod: 581071,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56124,
        latitude: 49.859351,
        longitude: 16.502143,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/T%C5%99ebovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým šikmým pruhem širokým jednu čtvrtinu šířky listu. V horní polovině žerďové části bílé lekno. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným vlnitým břevnem nahoře vyrůstající stříbrná lekna, mezi nimi zlatá hvězda, dole zlatá saň s červenou zbrojí."
    },
    {
        obec: "Třebovle",
        kod: 533751,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 50.027347,
        longitude: 14.961866,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/T%C5%99ebovle_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý, v poměru 1 : 2. Na styku pruhů dva zkřížené žluté obilné klasy s listem, proložené bílým mečem hrotem k dolnímu okraji listu, s jílcem provázeným dvěma bílými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě dva zkřížené zlaté obilné klasy s listem, proložené postaveným stříbrným mečem, nahoře provázeným dvěma stříbrnými hvězdami."
    },
    {
        obec: "Třebsko",
        kod: 541451,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.625764,
        longitude: 13.96765,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Trebsko_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 2:2:2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným břevnem stříbrná hlava jednorožce s krkem a červeným jazykem."
    },
    {
        obec: "Třebusice",
        kod: 532975,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27341,
        latitude: 50.200879,
        longitude: 14.180749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/T%C5%99ebusice_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a pilovitý bílý, v poměru 9 : 11, se zuby sahajícími do jedenácti dvacetin šířky listu. V žerďové polovině listu v zeleném pruhu žlutý zvon mezi bílými ostruhami, v bílém pruhu zkřížené vztyčené zelené jasanové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně pilovitě děleném štítě nahoře zlatý zvon mezi dvěma stříbrnými ostruhami, dole dva zkřížené vztyčené zelené jasanové listy."
    },
    {
        obec: "Třebušín",
        kod: 565792,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.599744,
        longitude: 14.20553,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Trebusin_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list, do kterého vyrůstá z dolního okraje zelená hora, v ní bílý kalich. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě zelená hora se stříbrným kalichem."
    },
    {
        obec: "Třemešná",
        kod: 597911,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79382,
        latitude: 50.20503,
        longitude: 17.57542,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/T%C5%99eme%C5%A1n%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý s bílým zvlněným pruhem se dvěma vrcholy a jednou prohlubní na dolním okraji, široký jednu čtvrtinu šířky listu. V červeném pruhu bílý šíp hrotem dolů pod třemi dlouhými rovnoramennými trojúhelníky se základnami v první čtvrtině šířky listu a s vrcholy na horním okraji listu. V modrém pruhu bílá volavka se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo pod třemi kužely dolů obrácená střela, vše stříbrné, vlevo nad stříbrnou vlnitou patou stříbrná volavka se zlatou zbrojí."
    },
    {
        obec: "Třemešné",
        kod: 561304,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34806,
        latitude: 49.625373,
        longitude: 12.695768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Tremesne_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a červený. V modrém pruhu dolů obrácené květenství střemchy s pěti květy. V bílém pruhu dub s černým kmenem a zelenou korunou s pěti svěšenými žlutými žaludy a listy. V červeném pruhu bílé zkřížené čakany. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V polceném štítě vpravo v modrém poli dolů obrácené květenství střemchy s pěti květy přirozené barvy, levé pole červeno-stříbrně dělené, nahoře zkřížené stříbrné čakany, dole zelený dub s černým kmenem a pěti svěšenými zlatými žaludy s listy."
    },
    {
        obec: "Třemošná",
        kod: 559521,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.815946,
        longitude: 13.395104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/T%C5%99emo%C5%A1n%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list, v žerďové polovině kosmo položené a dolů obrácené bílé květenství střemchy hroznovité se dvěma zelenými listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrné květenství střemchy hroznovité visící na zeleném stonku se dvěma listy."
    },
    {
        obec: "Třemošnice",
        kod: 572411,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.869219,
        longitude: 15.580129,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Tremosnice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, žlutý a červený v poměru 2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatý kůl se třemi černými mošnami."
    },
    {
        obec: "Třesov",
        kod: 550311,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67502,
        latitude: 49.180335,
        longitude: 16.082925
    },
    {
        obec: "Třesovice",
        kod: 571059,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50315,
        latitude: 50.265178,
        longitude: 15.688982,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Tresovice_HK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů střídavě modrých a bílých, v poměru 1 : 2 : 6 : 2 : 1. V prostředním modrém pruhu žlutý obilný snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý obilný snop nahoře podložený kosmo cepem a šikmo kosou a dole provázený dvěma vztyčenými dubovými listy, vše stříbrné."
    },
    {
        obec: "Třešovice",
        kod: 536679,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.211424,
        longitude: 13.98145,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/T%C5%99e%C5%A1ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a bílých, v poměru 1 : 1 : 4 : 1 : 1. V prostředním pruhu květ třezalky tečkované mezi dvěma klíny, žerďovým a vlajícím, s vrcholy ve třetinách délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma sníženými stříbrnými břevny květ třezalky tečkované přirozené barvy mezi dvěma zlatými vrbami."
    },
    {
        obec: "Třešť",
        kod: 588032,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58901,
        latitude: 49.290971,
        longitude: 15.483401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/T%C5%99e%C5%A1%C5%A5_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modrá žerďová čtvercová část s bílým jednorožcem ve skoku a bílá vlající část. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Třeštice",
        kod: 588041,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.245602,
        longitude: 15.453316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Coat_of_arms_of_T%C5%99e%C5%A1tice.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a žluté pole. V žerďové a střední části listu volná hnědá roubená zvonice s věží provázenou v modrém pruhu žlutou růží s červeným semeníkem a ve žlutém poli modrým květem lnu se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě volná roubená zvonice přirozené barvy, s věží provázenou vpravo zlatou růží s červeným semeníkem a vlevo modrým květem lnu se zlatým středem."
    },
    {
        obec: "Třeština",
        kod: 553336,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78973,
        latitude: 49.796306,
        longitude: 16.963987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/T%C5%99e%C5%A1tina_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě bílé a modré, v poměru 1:2:1:8. V užším modrém pruhu tři žluté osmicípé hvězdy pod sebou. Ve vlajícím pruhu tři žluté odkloněné orobince, prostřední se dvěma, krajní s jedním listem, vyrůstající z dolní poloviny bílé lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrnou patou tři zlaté odkloněné orobince, prostřední se dvěma listy, krajní s jedním, vyrůstající z dolní poloviny stříbrné lilie. V patě položená modrá radlice, v ní tři (2,1) zlaté osmihroté hvězdy."
    },
    {
        obec: "Tři Dvory",
        kod: 533769,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.032056,
        longitude: 15.256593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Tri_Dvory_coa.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi, v poměru 1 : 2. V modrém pruhu tři bílé volné prázdné brány se žlutými otevřenými vraty do dvora a červenými valbovými střechami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zúženou stříbrnou vlnitou hlavou tři (2, 1) stříbrné volné prázdné brány do dvora se zlatými otevřenými vraty a červenými valbovými střechami."
    },
    {
        obec: "Tři Sekery",
        kod: 554880,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.942277,
        longitude: 12.616745,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Tri_Sekery_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý, zelený v poměru 1:4:1. V bílém pruhu na zeleném návrší vycházejícím z dolního okraje bílého pruhu zelená jedle s hnědým kmenem a kořeny, do nichž jsou zatnuty tři (2,1) sekery na hnědých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném návrší jedle přirozené barvy do jejíchž kořenů jsou zatknuty tři (2,1) sekery přirozené barvy."
    },
    {
        obec: "Tři Studně",
        kod: 587753,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59204,
        latitude: 49.612941,
        longitude: 16.036598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/T%C5%99i_Studn%C4%9B_CoA.gif",
        coatOfArmsFlagDescription: "Zelený list se třemi bílými kvádrovanými studněmi o výšce jedné poloviny šířky listu vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě 3 (2,1) stříbrné kvádrované studně."
    },
    {
        obec: "Třibřichy",
        kod: 504921,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.965357,
        longitude: 15.739289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/T%C5%99ib%C5%99ichy_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s kráčejícím čelně hledícím bílým okřídleným lvem přidržujícím pravou tlapou žlutý železný klobouk Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kráčející čelně hledící stříbrný okřídlený lev přidržující pravou tlapou zlatý železný klobouk."
    },
    {
        obec: "Třinec",
        kod: 598810,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73961,
        latitude: 49.677731,
        longitude: 18.67089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Coat_of_arms_of_T%C5%99inec.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou orlicí s červenou zbrojí, na hrudi červený štítek se značkou třineckých železáren. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Třtěnice",
        kod: 573639,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50704,
        latitude: 50.377493,
        longitude: 15.469231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Znak_obce_T%C5%99t%C4%9Bnice.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy žlutý, červený, zelený a žlutý v poměru 1:2:2:1. Z dolního okraje červeného a zeleného pruhu vychází bílý klín s vrcholem ve třetí osmině šířky listu. Na vrcholu klínu šesticípá hvězda, v červeném pruhu růže a v zeleném pruhu lilie, vše žluté. V bílém klínu orobinec se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě snížený vydutý stříbrný hrot, s hvězdou na vrcholu, provázený růží a lilií, vše zlaté. Ve hrotu orobinec se dvěma listy přirozené barvy."
    },
    {
        obec: "Třtice",
        kod: 542512,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27101,
        latitude: 50.185105,
        longitude: 13.863591,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Trtice_znak_obce.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy žlutý, zelený a žlutý v poměru 3:5:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným kůlem vyrůstá ze sníženého zúženého modrého vlnitého břevna zlatý rákos obecný."
    },
    {
        obec: "Tučapy",
        kod: 553239,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39126,
        latitude: 49.291392,
        longitude: 14.803517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Tucapy_TA_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 1:1:4. V žerďové části listu dvě černé svatojakubské mušle pod sebou, v modrém pruhu bílý čáp se žlutou zbrojí držící pravou nohou kosmý červeno-bíle polcený štítek se třemi černými kosmými břevny v bílém poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě s červeno-stříbrně dělenými kouty, vpravo šikmo a vlevo kosmo, stříbrný čáp se zlatou zbrojí držící pravou nohou kosmý červeno-stříbrně polcený štítek, vlevo se třemi černými kosmými břevny. V koutech černá svatojakubská mušle."
    },
    {
        obec: "Tučapy",
        kod: 593648,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.233446,
        longitude: 16.918474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Tu%C4%8Dapy_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy červený žlutý a červený. V červených pruzích bílé vlající klíny s vrcholy ve středu pruhů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá vykořeněná vrba provázená dole dvěma vyrůstajícími stříbrnými kužely."
    },
    {
        obec: "Tučapy",
        kod: 592714,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68709,
        latitude: 49.048213,
        longitude: 17.331526,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Tucapy_UH_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žluto-bíle polcený, zelený a bílo-žlutě polcený. V zeleném pruhu dva vinné hrozny, žlutý a bílý, na propletených stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad zlato-stříbrně čtvrcenou věží s cimbuřím dva propletené stonky vinné révy, každý s jedním hroznem, pravý zlatý, levý stříbrný."
    },
    {
        obec: "Tuhaň",
        kod: 546267,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.536579,
        longitude: 14.467408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Tuha%C5%88_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutá krokev, vycházející z první a páté pětiny žerďového okraje listu s vrcholem ve středu listu, modrý žerďový klín s bílým srpem ostřím k žerdi a pět vodorovných pruhů, střídavě modrých a bílých. V horním a dolním cípu v modrých pruzích žlutý řecký kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě odvrácené stříbrné sekery se zlatým ostřím a topůrky, dole mezi nimi zlatý srp se stříbrným ostřím. Sekery mají proseknuty u ostří otvor ve tvaru řeckého kříže, srp u rukojeti otvor ve tvaru srdce."
    },
    {
        obec: "Tučín",
        kod: 520047,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75116,
        latitude: 49.45409,
        longitude: 17.515128,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Tu%C4%8D%C3%ADn_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, bílý, černý, bílý, zelený a žlutý, v poměru 1:1:6:1:1. V prostředním pruhu červená růže se žlutým semeníkem na zeleném stonku se dvěma listy překříženém dvěma modrými řemdihy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve štítě stříbrný zvýšený vydutý hrot, v něm červená růže se zlatým semeníkem na zeleném stonku se dvě listy překříženém dvěma modrými řemdihy. V pravém zeleném poli šikmo zlatá sekera ostřím doleva, v levém černém poli kosmo stříbrná radlice."
    },
    {
        obec: "Tuhaň",
        kod: 531561,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27741,
        latitude: 50.295483,
        longitude: 14.517383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Tuha%C5%88_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy zelený a červený a svislý vlnitý bílý pruh se dvěma vrcholy a třemi prohlubněmi vycházející z druhé desetiny horního a dolního okraje. Uprostřed listu žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně děleném štítě vpravo stříbrný vlnitý kůl, vlevo zlatá lilie."
    },
    {
        obec: "Tuchlovice",
        kod: 532983,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27302,
        latitude: 50.138132,
        longitude: 13.991262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Tuchlovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílo-černě polcený, červený a bílo-černě polcený. V červeném pruhu žlutá šesticípá hvězda mezi dvěma odvrácenými bílými lekny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou se zlatou hvězdou provázenou dvěma odvrácenými stříbrnými lekny černý medvěd ve skoku s červenou zbrojí, držící v levé přední tlapě ostrev přirozené barvy."
    },
    {
        obec: "Tuchoměřice",
        kod: 539767,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25267,
        latitude: 50.135539,
        longitude: 14.282293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Tuchomerice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý. V žerďové polovině červeného pruhu bílý ležící lev se žlutou zbrojí, pod ním ve žlutém pruhu tři černé lamačské rozštěpovací klíny vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě děleném štítě nahoře stříbrný ležící lev se zlatou zbrojí, dole tři černé lamačské rozštěpovací klíny vedle sebe."
    },
    {
        obec: "Tuchořice",
        kod: 566853,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43969,
        latitude: 50.28475,
        longitude: 13.662622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Tucho%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s černým klínem na dolním okraji listu, vymezeným bílou krokví vycházející z třetí a šesté osminy dolního okraje listu s vrcholem ve třetí čtvrtině šířky listu. V horní části listu tři bílé šesticípé hvězdy vedle sebe nad žlutou větévkou chmele se dvěma listy uprostřed a dvěma trojicemi šištic v dolní části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-černě stříbrnou sníženou krokví dělený štít, nahoře zlatá chmelová větévka se dvěma listy a dvěma trojicemi šištic pod sebou, převýšená třemi stříbrnými hvězdami."
    },
    {
        obec: "Tuklaty",
        kod: 533785,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25082,
        latitude: 50.084879,
        longitude: 14.769602,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Tuklaty_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy modrý a zelený, v poměru 7 : 1. Z prostředních dvou čtvrtin zeleného pruhu vyniká zelené návrší, v něm žlutý mlýnský kámen s položenou černou kypřicí. Z návrší vyniká bílá věž se vchodem, obloukovým oknem a se stanovou střechou, vše černé. Střecha zakončena makovicí a na žerďové straně provázena šesticípou hvězdou, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrná věž se vchodem v přízemí, obloukovým oknem v patře a se stanovou střechou, vše černé. Střecha zakončena makovicí a vpravo provázena hvězdou, obojí zlaté. V návrší zlatý mlýnský kámen s položenou černou kypřicí."
    },
    {
        obec: "Tulešice",
        kod: 594989,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67173,
        latitude: 49.038646,
        longitude: 16.207235,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Tule%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a červený, v poměru 17 : 1 : 8. V modrém pruhu palma vyrůstající z rozložených spojených křídel, vše bílé. V červeném pruhu zkřížené bílé sekery přeložené bílým mlýnským kamenem s červenou kypřicí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně sníženým zúženým stříbrným břevnem dělený štít. Nahoře palma vyrůstající z rozložených spojených křídel, vše stříbrné. Dole zkřížené stříbrné sekery přeložené stříbrným mlýnským kamenem s červenou kypřicí."
    },
    {
        obec: "Tuněchody",
        kod: 572420,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53701,
        latitude: 49.978962,
        longitude: 15.84134
    },
    {
        obec: "Tupadly",
        kod: 534480,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28563,
        latitude: 49.869103,
        longitude: 15.40356,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Tupadly_KH_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modro-žlutě dělený, bílý s červeným korunovaným lvem a žluto-modře dělený, v poměru 1 : 2 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít s modrou hlavou, v ní zlatá kráčející, čelně hledící lvice s červenou zbrojí a stříbrnou kbelíkovou, zlatě zdobenou přilbou se dvěma černými otvory ve tvaru tlapatého kříže. Vpravo červený korunovaný lev, vlevo tři zlaté chmelové šištice pod sebou a dolů plovoucí stříbrná ryba."
    },
    {
        obec: "Tupadly",
        kod: 529583,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.441913,
        longitude: 14.475036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Tupadly_%28okres_M%C4%9Bln%C3%ADk%29_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se třemi žerďovými pruhy, bílým, červeným a bílým, každý široký patnáctinu délky listu. V žerďové části zeleného pole bílý dvouocasý korunovaný lev se žlutou zbrojí a jazykem. Poměr šířky délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo červená patrová věž se dvěma černými okny nad sebou, zakončená ochozem se zábradlím, vlevo stříbrný dvouocasý korunovaný lev se zlatou zbrojí a jazykem."
    },
    {
        obec: "Tuchoraz",
        kod: 533777,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.048724,
        longitude: 14.849303
    },
    {
        obec: "Tupesy",
        kod: 592722,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68707,
        latitude: 49.084369,
        longitude: 17.369935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Tupesy%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:3:1. V modrém pruhu dva svěšené žluté vinné hrozny na jednom bílém stonku se dvěma listy vyrůstajícím ze žlutého džbánu, provázeného dvěma bílými vinařskými noži - kosíři se žlutými rukojeťmi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dva svěšené zlaté vinné hrozny na jednom stříbrném stonku se dvěma listy vyrůstajícím ze zlatého džbánu, provázeného dvěma stříbrnými vinařskými noži - kosíři se zlatými rukojeťmi."
    },
    {
        obec: "Turkovice",
        kod: 575844,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53363,
        latitude: 49.952551,
        longitude: 15.548856,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Turkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve třetí čtvrtině délky listu a dva vodorovné pruhy, červený a černý. V klínu vodorovně černý meč hrotem k vlajícímu okraji, ve vlající části pruhů býčí hlavy, v červeném bílá, v černém žlutá. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-černě polceném štítě stříbrný hrot se vztyčeným černým mečem, provázený dvěma býčími hlavami, vpravo stříbrnou a vlevo zlatou."
    },
    {
        obec: "Turnov",
        kod: 577626,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51101,
        latitude: 50.587385,
        longitude: 15.156909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Turnov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, červený a bílý v poměru 1:2:1. V červeném poli bílý dvouocasý lev se žlutou korunou a zbrojí. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Turovec",
        kod: 551601,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39121,
        latitude: 49.378459,
        longitude: 14.769036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/CoA_of_Turovec.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým pruhem, širokým 1/3 šířky listu, ze kterého vychází zelený středový vodorovný pruh o téže šíři. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zelenou hlavou a kůlem černá zubří hlava se zlatou houžví a červeným jazykem."
    },
    {
        obec: "Turovice",
        kod: 552836,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75114,
        latitude: 49.423817,
        longitude: 17.580887,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Turovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: červený, bílý a červený v poměru 1:3:1. V bílém poli černá hlava tura se žlutými rohy, červenými nozdrami a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černá hlava tura se zlatými rohy, červenými nozdrami a jazykem. Na jejím temeni postaveny dvě červené odvrácené radlice."
    },
    {
        obec: "Tursko",
        kod: 539775,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25265,
        latitude: 50.19134,
        longitude: 14.32199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Tursko_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, žlutý a zelený. V bílém pruhu červeno-černě čtvrcený kotvicový kříž, pod ním červená šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo červeno-černě čtvrcený kotvicový kříž provázený dole červenou hvězdou, vlevo zlatá sekera - bradatice."
    },
    {
        obec: "Tuř",
        kod: 573647,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.395077,
        longitude: 15.417033,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Tu%C5%99_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým pruhem širokým třetinu délky listu. V pruhu půl černé orlice s celou hlavou a červenou zbrojí, přiléhající k modrému poli. Ve střední části listu dvě žluté tuří hlavy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře polceném štítě vpravo půl vynikající černé orlice s celou hlavou a červenou zbrojí, vlevo pod sebou čelně hledící dvě zlaté tuří hlavy."
    },
    {
        obec: "Tuřany",
        kod: 539619,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35002,
        latitude: 50.08412,
        longitude: 12.522102,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Tu%C5%99any_CH_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílou žerďovou vidlicí s rameny širokými čtvrtinu šířky listu. V žerďové polovině listu napnutý luk s šípem hrotem k žerďovému okraji listu, oboje žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vidlice přeložená napnutým lukem s šípem hrotem nahoru, oboje zlaté."
    },
    {
        obec: "Tuřany",
        kod: 532177,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27379,
        latitude: 50.232342,
        longitude: 14.029138,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Tu%C5%99any_%28okres_Kladno%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý v poměru 3 : 5. Uprostřed tři bílá pštrosí pera vyrůstající ze žluté koruny, provázená v červeném pruhu dvěma žlutými osmicípými hvězdami a v modrém pruhu do oblouku dole přeťatými žlutými pouty s okovy nahoře. Poměr šířky k délce listuje 2 : 3.",
        coatOfArmsDescription: "V modrém štítě s červenou hlavou vyrůstají ze zlaté koruny tři stříbrná pštrosí pera, provázená v hlavním poli do oblouku dole přeťatými pouty s okovy nahoře, v hlavě dvěma osmihrotými hvězdami, vše zlaté."
    },
    {
        obec: "Tuřice",
        kod: 570982,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29474,
        latitude: 50.248907,
        longitude: 14.769264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Tu%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2:1. V žerďové polovině žlutého pruhu černý vykračující tur s červenou zbrojí. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou černý vykračující tur s červenou zbrojí."
    },
    {
        obec: "Tušovice",
        kod: 598330,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.575713,
        longitude: 14.030638
    },
    {
        obec: "Tutleky",
        kod: 576841,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.134014,
        longitude: 16.241556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Tutleky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý a zvlněný zelený, v poměru 7 : 1 : 4. Do červeného pruhu vyrůstá bílý dvouocasý lev se žlutou zbrojí a jazykem, držící v pravé tlapě zkřížené žluté šípy s bílými hroty a opeřením. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zeleně stříbrným vlnitým břevnem dělený štít. Nahoře vyrůstá stříbrný dvouocasý lev se zlatou zbrojí a jazykem držící v pravé tlapě zkřížené zlaté šípy se stříbrnými hroty a opeřením. Dole dva zlaté vztyčené odvrácené listy, vpravo dubový a vlevo hlohový."
    },
    {
        obec: "Tužice",
        kod: 553522,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.335115,
        longitude: 13.511527
    },
    {
        obec: "Tvarožná",
        kod: 584037,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66405,
        latitude: 49.19187,
        longitude: 16.771563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Tvaro%C5%BEn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů: zelený, bílý, zelený, bílý a zelený v poměru 2:1:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížené stříbrné vidle a kopáč se dvěma zuby."
    },
    {
        obec: "Tvarožná Lhota",
        kod: 586684,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69662,
        latitude: 48.877349,
        longitude: 17.359497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Tvaro%C5%BEn%C3%A1_Lhota_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený a modrý, uprostřed vinařský nůž - kosíř hrotem nahoru a ostřím k žerdi provázený dvěma vinnými hrozny, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře kosmo děleném štítě vinařský nůž - kosíř provázený dvěma vinnými hrozny, vše zlaté."
    },
    {
        obec: "Tvorovice",
        kod: 590118,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79823,
        latitude: 49.374487,
        longitude: 17.22203,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Tvorovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou prohnutou rybou přeloženou bílým srpem se žlutou rukojetí. Hlava ryby a hrot srpu k vlajícímu, ocas ryby a rukojeť srpu k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá, doleva vyskakující ryba, křížem přeložená stříbrným, doleva obráceným srpem se zlatou rukojetí."
    },
    {
        obec: "Tvořihráz",
        kod: 594997,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67134,
        latitude: 48.917649,
        longitude: 16.135878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Tvo%C5%99ihr%C3%A1z_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list; uprostřed žlutá věž s cimbuřím, dvěma černými okny a branou, převýšená fasetově střídavě bílo-černě polceným a děleným liliovým křížem, provázeným dvěma odkloněnými žlutými obilnými klasy, každý s odvráceným listem. Po stranách dva prohnuté stonky vinné révy, nahoře s odvráceným hroznem a dole s přivráceným listem, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá věž s cimbuřím, dvěma černými okny a obloukovou branou převýšená fasetově střídavě stříbrno-černě polceným a děleným liliovým křížem, provázeným dvěma odkloněnými zlatými obilnými klasy, každý s odvráceným listem. Po stranách dva prohnuté stonky vinné révy, nahoře s odvráceným hroznem a dole s přivráceným listem, vše stříbrné."
    },
    {
        obec: "Tvrdkov",
        kod: 551821,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79344,
        latitude: 49.895592,
        longitude: 17.178202,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Coat_of_arms_of_Tvrdkov.svg",
        coatOfArmsDescription: "Ve stříbrném štítu černý kohout s červenou zbrojí, stojící na dvou zkřížených černých hornických kladívkách černé barvy s násadami přirozené (hnědé) barvy a hledící k červené lilii v pravém horním rohu."
    },
    {
        obec: "Tvrdonice",
        kod: 584941,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69153,
        latitude: 48.760582,
        longitude: 16.994567,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Tvrdonice_znak_obce.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. Uprostřed bílé vozové kolo, v červeném pruhu v žerďové části vinný hrozen se dvěma listy a ve vlající části radlice hrotem nahoru a ostřím k žerďovému okraji. V modrém pruhu v žerďové části rybářský háček obrácený k žerďovému okraji a ve vlající části rybářský háček obrácený k vlajícímu okraji, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře vedle sebe vinný hrozen se dvěma listy, vztyčená radlice a vozové kolo, dole dvě dvojice odvrácených rybářských háčků, vše stříbrné."
    },
    {
        obec: "Tvrzice",
        kod: 550604,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.121841,
        longitude: 13.967232,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/TVRZICE_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý zelený, v poměru 1 : 4. Zelený pruh má tři zuby vysoké desetinu šířky listu a čtyři stejně velké mezery. Z dolního okraje listu vyrůstá bílý lovecký pes se žlutou zbrojí a obojkem provázený před čenichem žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou vyrůstající stříbrný lovecký pes se zlatou zbrojí a obojkem, vpravo nahoře provázený zlatou korunou."
    },
    {
        obec: "Týček",
        kod: 546534,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.869348,
        longitude: 13.794421,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/T%C3%BD%C4%8Dek_CoA.png",
        coatOfArmsFlagDescription: "Kosmo a šikmo čtvrcený list. V bílém žerďovém poli červená růže se žlutým semeníkem, horní a dolní pole zelená, vlající žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít se sníženým stříbrným vydutým hrotem, nad ním položená oboustranně uťatá lipová větev se čtyřmi (2, 2) suky a dvěma svěšenými, odvrácenými listy, vše zlaté. V hrotu červená růže se zlatým semeníkem."
    },
    {
        obec: "Tymákov",
        kod: 558460,
        okres: "Plzeň-město",
        kod_okres: "CZ0323",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33201,
        latitude: 49.719174,
        longitude: 13.509931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Tym%C3%A1kov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě zelených a bílých, v poměru 2 : 1 : 10 : 1 : 2. V prostředním zeleném pruhu volná bílá brána s otevřenými žlutými vraty a červenou valbovou střechou. V bráně žlutý snop. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě volná stříbrná brána s otevřenými zlatými vraty a červenou valbovou střechou, pod ní stříbrný meč se zlatým jílcem hrotem doleva. V bráně zlatý snop."
    },
    {
        obec: "Týn nad Bečvou",
        kod: 570079,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75131,
        latitude: 49.51705,
        longitude: 17.618164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Tyn_nad_Becvou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list s červenou orlicí se žlutou zbrojí a bílými vinařskými noži se žlutými držadly na křídlech. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červená orlice se zlatou zbrojí mající na křídlech stříbrné vinařské nože se zlatou rukojetí."
    },
    {
        obec: "Týn nad Vltavou",
        kod: 545201,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.223502,
        longitude: 14.42067,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/T%C3%BDn_nad_Vltavou_znak_2011.png"
    },
    {
        obec: "Týnec",
        kod: 584959,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69154,
        latitude: 48.779531,
        longitude: 17.013324,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Znaktynec.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý žlutý s osmi čtvercovými zuby a devíti stejnými mezerami, v poměru 5 : 3. Do modrého pruhu vynikají tři žluté věže s cimbuřím a prázdným oknem, prostřední větší a s šikmo odlomenou horní částí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku mezi keři při okrajích štítu zlatá kvádrovaná hradba s prázdnou gotickou branou, se zdviženou zlatou mříží a červenými vraty. Za hradbou vynikají tři zlaté věže s cimbuřím a prázdným oknem, prostřední větší a s šikmo odlomenou horní částí."
    },
    {
        obec: "Týnec",
        kod: 541885,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34021,
        latitude: 49.347334,
        longitude: 13.264018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Tynec_KT_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy: zelený, žlutý a černý v poměru 2:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě šest zlatých palisádových kůlů, černě propletené."
    },
    {
        obec: "Týnec nad Labem",
        kod: 533807,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28126,
        latitude: 50.042117,
        longitude: 15.358462,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Tynec_nad_Labem_CZ_CoA_2007.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě modré a bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně děleném štítě, dole s šikmým stříbrným břevnem, vpravo natočená stříbrná kvádrovaná věž na šikmo rozšířeném soklu s černou branou a s červenou stanovou střechou se zlatou makovicí. Mezi branou a levým okrajem štítu dřevěný příhradový most na třech podpěrách přirozené barvy."
    },
    {
        obec: "Týniště",
        kod: 539929,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.521194,
        longitude: 13.417703
    },
    {
        obec: "Týnec nad Sázavou",
        kod: 530841,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25741,
        latitude: 49.833579,
        longitude: 14.58994,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Tynec_nad_Sazavou_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré karé široké jednu třetinu délky listu nad stejně velkým žlutým čtvercovým polem a dva vodorovné pruhy, bílý a červený. V modrém poli žlutá vyskakující ryba, ve žlutém poli tři (2,1) černá paroží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na modré patě za hradbou s cimbuřím románská kvádrovaná rotunda s apsidou, za ní hranolová věž, vše stříbrné s černými střechami. Věž provázená dvěma gotickými štítky, pravý modrý se zlatou vyskakující rybou, levý zlatý s trojím (2,1) černým parožím."
    },
    {
        obec: "Týniště nad Orlicí",
        kod: 576859,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51721,
        latitude: 50.151203,
        longitude: 16.078551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Tyniste_nad_Orlici_CoA_CZ.png",
        coatOfArmsFlagDescription: "Červený list se zelenou borovicí se žlutým vykořeněným kmenem. Přes kmen černo-bíle dělený gotický štítek, nahoře se dvěma bílými břevny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zelená borovice se zlatým vykořeněným kmenem. Přes kmen gotický štítek černo-stříbrně dělený, nahoře se dvěma stříbrnými břevny."
    },
    {
        obec: "Týnišťko",
        kod: 575861,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.999487,
        longitude: 16.097653,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/53/T%C3%BDni%C5%A1%C5%A5ko_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký čtvrtinu délky listu a modré pole se žlutým zvonem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod zvýšeným břevnem, nahoře pilovitým a dole vlnitým, zvon, vše zlaté."
    },
    {
        obec: "Úbislavice",
        kod: 573655,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50792,
        latitude: 50.473031,
        longitude: 15.47042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Znak_obce_%C3%9Abislavice.jpg",
        coatOfArmsFlagDescription: "List tvoří žluto-černě dělený zubatý žerďový pruh široký jednu třetinu délky listu a zelené pole s vykořeněnou žlutou třešní kvetoucí čtyřmi bílými květy se žlutými středy. Žerďový pruh má sedm obdélníkových zubů a šest stejných mezer dosahujících do první čtvrtiny délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlato-černě polcenou cimbuřovou hlavou zlatá vykořeněná třešeň kvetoucí čtyřmi stříbrnými květy se zlatými středy."
    },
    {
        obec: "Ublo",
        kod: 585874,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.233156,
        longitude: 17.901231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Ubloznak.jpeg",
        coatOfArmsFlagDescription: "Bílý list s červenou růží se žlutým semeníkem a zelenými kališními lístky, černým žerďovým klínem s vrcholem v jedné třetině délky listu a se zeleným vlajícím klínem s vrcholem ve druhé třetině listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý kráčející tetřívek s červeným hřebínkem, za ním na zeleném stonku červená růže se zlatým semeníkem a zelenými kališními lístky, provázená v horních rozích dvěma stejnými růžemi bez stonku."
    },
    {
        obec: "Úboč",
        kod: 566161,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.443698,
        longitude: 13.086983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/%C3%9Abo%C4%8D_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé žerďové pruhy, červený a šestkrát modro-bíle dělený, každý široký šestinu délky listu, a zelené pole. V poli vztyčený bílý meč se žlutým jílcem přeložený zkříženými žlutými obilnými klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s červeno-modře polceným pravým bokem vztyčený stříbrný meč se zlatým jílcem přeložený zkříženými zlatými obilnými klasy. V levém poli pravého boku tři stříbrná břevna."
    },
    {
        obec: "Ubušínek",
        kod: 549959,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.610581,
        longitude: 16.281794,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Ubu%C5%A1%C3%ADnek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V bílém pruhu černá zubří hlava se žlutou houžví, v zeleném pruhu žluté zlomené kolo sv. Kateřiny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně děleném štítě nahoře černá zubří hlava se zlatou houžví, dole zlaté zlomené kolo sv. Kateřiny."
    },
    {
        obec: "Údlice",
        kod: 563382,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43141,
        latitude: 50.440706,
        longitude: 13.457492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Udlice_znak.jpg"
    },
    {
        obec: "Údrnice",
        kod: 573663,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.374485,
        longitude: 15.261676,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/%C3%9Adrnice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý bílý a zvlněný zelený, v poměru 7 : 1 : 12. Uprostřed modrého pruhu tři žluté zvony vedle sebe, z dolního okraje listu vyrůstají do zeleného pruhu čtyři žluté odkloněné obilné klasy s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod modrou, stříbrným vlnitým břevnem oddělenou hlavou se třemi zvony čtyři vyrůstající odkloněné obilné klasy s listem, vše zlaté."
    },
    {
        obec: "Uhelná",
        kod: 541214,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79070,
        latitude: 50.365038,
        longitude: 17.027399,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Uheln%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "Ve žlutém listu červené srdce, z něhož na černém stonku vyrůstají tři červené lilie střídavě mezi dvěma červenými růžemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě srdce kvetoucí třemi liliemi a dvěma růžemi, vše červené, na černých stoncích."
    },
    {
        obec: "Uhelná Příbram",
        kod: 569640,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58245,
        latitude: 49.768087,
        longitude: 15.585655,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Znak_obce_Uheln%C3%A1_P%C5%99%C3%ADbram.jpg",
        coatOfArmsFlagDescription: "Červený list s vykořeněným zeleným listnatým stromem s hnědým kmenem a českým lvem držícím v předních tlapách bílou sekeru-bradatici se žlutým topůrkem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Úherce",
        kod: 546411,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.701479,
        longitude: 13.213823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/%C3%9Aherce_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červené karé se žlutým vozovým kolem nad stejně velkým žlutým polem s černým parožím a sedm vodorovných pruhů, bílý, modrý, bílý, černý, bílý, modrý a bílý, v poměru 3 : 2 : 2 : 2 : 2 : 2 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělen sníženým zúženým vlnitým modře lemovaným stříbrným břevnem.V červeno-zlatě polceném horním poli vpravo zlaté vozové kolo, vlevo černé paroží. V dolním stříbrném poli obrácená černá hornická kladívka s topůrky přirozené barvy."
    },
    {
        obec: "Úherce",
        kod: 546178,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.298161,
        longitude: 13.949094,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/%C3%9Aherce_%28Louny_District%29_CoA.png",
        coatOfArmsFlagDescription: "Žlutý list se zeleným šikmým pruhem širokým dvě pětiny šířky listu. V pruhu šikmo tři žluté trojlaločné závěsky úhereckého typu s profilovaným ouškem, v horním žerďovém poli modrý vinný hrozen se dvěma zelenými listy, v dolním vlajícím poli kosmo svěšená zelená švestková větev se třemi modrými plody a dvěma odkloněnými zelenými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít se šikmým zeleným břevnem, v něm šikmo tři zlaté trojlaločné závěsky úhereckého typu s profilovaným ouškem, nad břevnem modrý vinný hrozen se dvěma zelenými listy, pod břevnem kosmo svěšená zelená švestková větev se třemi modrými plody a dvěma odkloněnými zelenými listy."
    },
    {
        obec: "Uherčice",
        kod: 584967,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69162,
        latitude: 48.967902,
        longitude: 16.653546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Uhercice_%28Breclav%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený se žlutým hroznem v žerďové části a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstající Panna Maria s Ježíškem ve stříbrném šatě, se zlatými korunami a žezlem, provázená po obou stranách zlatou révovou ratolestí. Pravá má nahoře jeden list a tři hrozny pod sebou, levá jeden list a jeden hrozen."
    },
    {
        obec: "Uherčice",
        kod: 595004,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.913336,
        longitude: 15.630477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Uhercice_ZN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh se třemi žlutými liliemi pod sebou, široký jednu třetinu délky listu a bílo-červeně kosmo dělené pole se žlutou korunovanou hlavou lva s bílými zuby a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně kosmo děleném štítě pod modrou hlavou se třemi zlatými liiemi zlatá korunovaná hlava lva se stříbrnými zuby a červeným jazykem."
    },
    {
        obec: "Úherčice",
        kod: 530697,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.919564,
        longitude: 15.677746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/%C3%9Aher%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a bílé pole. V horní části žerďového pruhu bílá lilie. V bílém poli tři kosmé zelené pruhy vycházející z první, třetí a páté sedminy žerďového okraje pole do třetí, páté a sedmé sedminy vlajícího okraje pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít. V pravém poli nahoře lilie, v levém tři kosmá břevna, vše opačných barev."
    },
    {
        obec: "Uhersko",
        kod: 575879,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53373,
        latitude: 49.992474,
        longitude: 16.017879,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Uhersko_%28Pardubice%29_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list se svislým žlutým pruhem širokým jednu čtvrtinu délky listu, podloženým bílým ondřejským křížem s rameny širokými jednu dvacetinu délky listu a stejně širokým červeným vodorovným pruhem. Ve žlutém pruhu červená lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě čelně vztyčená zlatá radlice s červenou lilií, převýšená zlatou korunou a podložená dvěma zkříženými stříbrnými liliovými hůlkami. Z ostří radlice vyrůstají na každé straně dvě pštrosí péra, červené a stříbrné."
    },
    {
        obec: "Uherské Hradiště",
        kod: 592005,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68601,
        latitude: 49.059897,
        longitude: 17.49595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/UH_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy - horní modrý a dolní červený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Uherský Brod",
        kod: 592731,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68801,
        latitude: 49.030373,
        longitude: 17.649938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Znak_m%C4%9Bsta_Uhersk%C3%BD_Brod.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovné bílé a červené zubaté pole se šesti obdélníkovými zuby a pěti polovičními čtvercovými mezerami. V bílém poli bílo-červeně šachovaná orlice se žlutou zbrojí, v dolním červeném poli žlutý ležící lev. Poměr mezi bílým polem a výškou zubů je 6:1:5. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Uherský Ostroh",
        kod: 592749,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68724,
        latitude: 48.985657,
        longitude: 17.389953,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Uhersk%C3%BD_Ostroh%2C_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý zubatý a žlutý, v poměru 3:1:2. Bílý pruh má osm čtvercových zubů a devět stejných mezer a dva poloviční zuby. Strana zubů se rovná jedné dvanáctině šířky listu. Z bílého pruhu vyrůstá věž s cimbuřím, třemi okny (2+1), valbovou střechou se dvěma makovicemi. Věž a makovice jsou žluté, střecha a cimbuří bílé. Pod věží ve žlutém pruhu červená prolomená brána široká jednu pětinu délky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Úhlejov",
        kod: 573671,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.427287,
        longitude: 15.687441,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/%C3%9Ahlejov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý. V bílých pruzích na zeleném návrší zelený listnatý strom s černým kmenem, v modrém pruhu liliový kříž nahoře a dole provázený šesticípou hvězdou cípem k žerďovému okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně dvěma zvýšenými hroty děleném štítě nahoře liliový kříž provázený dvěma hvězdami, vše zlaté. Dole ze zeleného trojvrší vyrůstají dva zelené listnaté stromy s černými kmeny."
    },
    {
        obec: "Uhlířov",
        kod: 568341,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74784,
        latitude: 49.897154,
        longitude: 17.841402,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Uhlirov_CoA.png",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým klínem s vrcholem na středu vlajícího okraje. V žerďové části půl červené lilie přiléhající k černému půlzvonu, v červených polích bílé nože ostřím k sobě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný hrot provázený dvěma přivrácenými stříbrnými vinařskými noži. V něm půl červené lilie přiléhající k polovině černého zvonu."
    },
    {
        obec: "Uhlířská Lhota",
        kod: 533815,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28126,
        latitude: 50.079045,
        longitude: 15.391155
    },
    {
        obec: "Uhlířské Janovice",
        kod: 534498,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.880291,
        longitude: 15.06492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Coat_of_arms_of_Uhl%C3%AD%C5%99sk%C3%A9_Janovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý bílý a zelený, v poměru 5:2:1. V modrém pruhu žlutá osmicípá hvězda. Bílý pruh má tři obdélníkové zuby, dvě stejné a dvě poloviční mezery; zuby jsou vysoké jednu osminu šířky listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Úholičky",
        kod: 571351,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25264,
        latitude: 50.16146,
        longitude: 14.335349,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/%C3%9Aholi%C4%8Dky_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, a na dolním okraji vodorovný žlutý pruh široký čtvrtinu šířky listu. Z druhé a třetí čtvrtiny žlutého pruhu vychází vydutý klín přecházející v lípu provázenou při kmeni dvěma šesticípými hvězdami, vše žluté. V klínu a střední části žlutého pruhu červená kotva. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vyrůstá ze sníženého vydutého hrotu lípa provázená po stranách dvěma hvězdami, vše zlaté. V hrotu červená kotva."
    },
    {
        obec: "Úhonice",
        kod: 532991,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25218,
        latitude: 50.043449,
        longitude: 14.186328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/%C3%9Ahonice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list s černo-žluto-černě děleným žerďovým pruhem, širokým čtvrtinu délky listu. V horním černém poli žlutá lilie, v dolním černém poli bílá růže se žlutým semeníkem. Ve střední části listu bílý pes-věžník se žlutým jazykem a modrým obojkem se žlutými trny a lemováním. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo černo-červeně polcený štít, vpravo zlaté břevno provázené nahoře zlatou lilií a dole stříbrnou růží se zlatým semeníkem, vlevo stříbrný pes-věžník ve skoku se zlatým jazykem a modrým obojkem se zlatými trny a lemováním."
    },
    {
        obec: "Úhořilka",
        kod: 548537,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58253,
        latitude: 49.524779,
        longitude: 15.530975
    },
    {
        obec: "Úhřetice",
        kod: 572446,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53832,
        latitude: 49.979251,
        longitude: 15.867345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/%C3%9Ah%C5%99etice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký polovinu délky listu, a tři vodorovné pruhy, černý, bílý a červený. V žerďové polovině listu zkřížené žluté obilné klasy s listem, ve vlající polovině listu zkřížené žluté ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém zeleném poli zkřížené zlaté obilné klasy s listem, v levém černo-stříbrno-červeně děleném poli zkřížené zlaté ostrve."
    },
    {
        obec: "Úhřetická Lhota",
        kod: 575887,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53002,
        latitude: 49.988669,
        longitude: 15.872852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/%C3%9Ah%C5%99etick%C3%A1_Lhota_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký jednu čtvrtinu délky listu, svislý bílý pruh široký jednu dvanáctinu délky listu a modré pole s bílým vodorovným pruhem širokým jednu dvanáctinu délky listu. Svislý bílý pruh je obtočen vztyčenou žlutou rybou hlavou i ocasem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou stříbrný kůl, zleva obtočený vztyčenou zlatou rybou."
    },
    {
        obec: "Uhřice",
        kod: 582531,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67963,
        latitude: 49.595164,
        longitude: 16.735111,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8c/CoA_of_Uh%C5%99ice_%28okres_Blansko%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a bílý, v poměru 1:2:1. Ve středním pruhu tlapatý rovný kříž převýšený korunou, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná zvonice s červenou valbovou střechou se dvěma makovicemi, zavěšeným zvonem a provázená tlapatými kříži převýšenými korunami, vše zlaté."
    },
    {
        obec: "Uhřice",
        kod: 586692,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69634,
        latitude: 49.050447,
        longitude: 16.947682,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Uh%C5%99ice_CoA_HO_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polcenou korunovanou orlicí s červeným jazykem, na hrudi s třícípou korouhví opačných barev s černým tlapatým křížem a držící v každém pařátu žlutý vztyčený vinný hrozen na stonku se dvěma listy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrno-červeně polcená korunovaná orlice s červeným jazykem, nesoucí na hrudi třícípou korouhev opačných barev s černým tlapatým křížem a držící v každém pařátu zlatý vztyčený vinný hrozen na stonku se dvěma listy."
    },
    {
        obec: "Uhřice",
        kod: 550191,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68333,
        latitude: 49.172448,
        longitude: 17.078762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Uh%C5%99ice_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy zubatý červený, bílý, modrý a bílý v poměru 5:2:7:7. Červený pruh má čtyři obdélníkové zuby a tři stejné mezery. V modrém bílá růže se žlutým semeníkem, zelenými kališními lístky nad žlutým palečným kolem. V bílém pruhu tři kosmé černé pruhy vycházející z první třetí a páté sedminy žerďového okraje pruhu do třetí páté a sedmé sedminy vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červeno-stříbrně dělená hradba se čtyřmi stínkami a vlevo se třemi černými kosmými břevny. Nad ní vpravo stříbrná růže se zlatým semeníkem a zelenými kališními lístky, vlevo zlaté palečné kolo."
    },
    {
        obec: "Uhřice",
        kod: 589110,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.281835,
        longitude: 17.201536,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Uh%C5%99ice_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma bílými vlčími hlavami s červenými jazyky nad bílou rozkřídlenou labutí se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě stříbrné vlčí hlavy s červenými jazyky nad stříbrnou rozkřídlenou labutí se zlatou zbrojí."
    },
    {
        obec: "Uhřičice",
        kod: 552879,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75201,
        latitude: 49.371673,
        longitude: 17.290435,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/CoA_of_Uh%C5%99i%C4%8Dice.svg",
        coatOfArmsFlagDescription: "Zelený list ve střední části s bílým žernovem s červeným oškrtem a přes žernov přeloženými třemi žlutými obilnými klasy do vidlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný žernov s červeným oškrtem, přes něj položeny tři zlaté obilné klasy postavené do vidlice."
    },
    {
        obec: "Uhřínov",
        kod: 596906,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59441,
        latitude: 49.349931,
        longitude: 15.938057,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Coat_of_arms_of_Uh%C5%99%C3%ADnov.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed latinský kříž s rameny širokými osminu šířky listu dole provázený odvrácenými půlměsíci, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně polceném štítě latinský kříž dole provázený odvrácenými půlměsíci, vše stříbrné."
    },
    {
        obec: "Uhy",
        kod: 533009,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27324,
        latitude: 50.284336,
        longitude: 14.273969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Uhy_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílým svislým pruhem vycházejícím z druhé dvanáctiny horního a dolního okraje listu, uprostřed listu na bílém kvádrovaném podstavci volná žlutá vidlicová zvonička s jehlancovou stříškou a bílým zvonem. Na vlajícím okraji listu tři bílé vlající klíny s vrcholy ve třech čtvrtinách délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném kvádrovaném podstavci zlatá vidlicová zvonička s jehlancovou stříškou se stříbrným zvonem. Zvoničku dole provází vpravo lilie a vlevo tři vlčí zuby vynikající z levého okraje štítu, vše stříbrné."
    },
    {
        obec: "Ujčov",
        kod: 596914,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59262,
        latitude: 49.488459,
        longitude: 16.331188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Uj%C4%8Dov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V bílém pruhu černá zubří hlava s červeným jazykem a žlutou houžví, v červeném pruhu žlutý vykořeněný listnatý strom. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně děleném štítě nahoře černá zubří hlava s červeným jazykem a zlatou houžví, dole zlatý vykořeněný listnatý strom provázený vpravo sekerou a vlevo vidlemi-podávkami, obojí stříbrné."
    },
    {
        obec: "Újezd",
        kod: 595012,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67140,
        latitude: 49.023647,
        longitude: 16.053361,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/da/Coat_of_arms_of_%C3%9Ajezd_ZN.jpg",
        coatOfArmsFlagDescription: "List dělený bílou krokví, vycházející z šesté devítiny horního a dolního okraje listu a s vrcholem v první třetině délky listu, na červené žerďové pole se žlutou šesticípou hvězdou a vlající zelené s obrácenými bílými hornickými kladívky se žlutými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně sníženou stříbrnou krokví děleném štítě nahoře zlatá hvězda, dole obrácená stříbrná hornická kladívka se zlatými topůrky."
    },
    {
        obec: "Újezd",
        kod: 596922,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59212,
        latitude: 49.509261,
        longitude: 15.870346,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/%C3%9Ajezd_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V žerďové části bílého pruhu zelený listnatý strom. V žerďové části červeného pruhu bílý latinský kříž šikmo přeložený žlutou listnatou větévkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo stříbrný latinský kříž šikmo přeložený zlatou listnatou větévkou. Vlevo zelený listnatý strom."
    },
    {
        obec: "Újezd",
        kod: 505501,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78396,
        latitude: 49.764066,
        longitude: 17.180511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Ujezd_%28OL%29_znak.jpeg",
        coatOfArmsFlagDescription: "Zelený list, v žerďové polovině dvě žluté osmicípé hvězdy dvě pod sebou ve vzdálenosti jedné šestiny délky listu od žerdi, třetí s nimi vytváří rovnostranný trojúhelník. Ve vlající polovině kosmo postavená bílá otka se žlutým hrotem u horního okraje přeložená šikmo žlutou kosou na bílém kosišti, hrotem k dolnímu vlajícímu cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo postavená stříbrná otka se zlatým hrotem přeložená šikmo dolů zlatou kosou na stříbrném kosišti, obě provázené třemi (1,2) zlatými osmihrotými hvězdami."
    },
    {
        obec: "Újezd",
        kod: 554383,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.435642,
        longitude: 12.869668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/%C3%9Ajezd_%28Doma%C5%BElice_District%29_CoA.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým dolním klínem vycházejícím z druhé až sedmé osminy dolního okraje listu s vrcholem na horním okraji listu. V klínu černá psí hlava s červeným jazykem. V zelených polích po jednom přivráceném bílém čakanu na žlutém topůrku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zelený štít se stříbrným hrotem, v něm černá psí hlava s červeným jazykem. Hrot provázen přivrácenými stříbrnými čakany na zlatých topůrkách."
    },
    {
        obec: "Újezd",
        kod: 531901,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26761,
        latitude: 49.831356,
        longitude: 13.837576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0b/%C3%9Ajezd_%28Beroun_District%29_CoA_ver2.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 1. Z druhé a třetí čtvrtiny zeleného pruhu vyniká zelený vrch, v něm zkřížené žluté obilné klasy, každý s odkloněným listem. Na vrchu černá, žlutě kvádrovaná hradba, z níž vyrůstá zelený dub s hnědým kmenem a červenými žaludy v zelených miskách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vrch, na něm černá, zlatě kvádrovaná hradba, z níž vyrůstá dub přirozené barvy. Ve vrchu zkřížené zlaté obilné klasy, každý s odkloněným listem."
    },
    {
        obec: "Újezd",
        kod: 585882,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76325,
        latitude: 49.168187,
        longitude: 17.906215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Ujezd-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, žlutý, modrý, žlutý a červený, v poměru 1:1:6:1:1. Uprostřed modrého pruhu bílá, žlutě zdobená mitra s červenou růží se žlutým semeníkem a zelenými kališními lístky podložená dvěma zkříženými žlutými berlami závitem k dolnímu okraji a bílými sudarii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádou se třemi (2+1) červenými jablky mitra s červenou růží se zlatým semeníkem a zelenými kališními lístky podložená zkříženými zlatými berlami závity ven, se stříbrnými sudarii."
    },
    {
        obec: "Újezd nade Mží",
        kod: 566594,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.788854,
        longitude: 13.195175,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/%C3%9Ajezd_nade_M%C5%BE%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, vlnitý modrý se třemi vrcholy a dvěma prohlubněmi a zvlněný zelený, v poměru 7 : 2 : 7. Ve žlutém pruhu černý jelení paroh, v zeleném bílá lilie na vlnitém stonku s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně modrým vlnitým kůlem polcený štít. Vpravo vztyčený černý jelení paroh, vlevo stříbrná lilie na vlnitém stonku s listy."
    },
    {
        obec: "Újezd pod Troskami",
        kod: 573680,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51263,
        latitude: 50.506631,
        longitude: 15.262655,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/%C3%9Ajezd_pod_Troskami_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3 : 1. Z druhé a třetí čtvrtiny horního okraje zeleného pruhu vyniká zelený vrch, na něm bílý, čelně hledící, kráčející beránek se žlutou zbrojí a svatozáří. V zeleném pruhu do vrchu vějířovitě čtyři žluté obilné klasy, vnitřní s odkloněným listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě na zeleném vrchu stříbrný, čelně hledící, kráčející beránek se zlatou zbrojí a svatozáří. Ve vrchu čtyři vějířovitě rozložené zlaté obilné klasy, vnitřní s odkloněným listem."
    },
    {
        obec: "Újezd u Boskovic",
        kod: 534692,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.463998,
        longitude: 16.654269,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/CoA_of_%C3%9Ajezd_u_Boskovic.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zelený, v poměru 2:5:5. V bílém pruhu hnědý orobinec na zeleném stonku s listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít s červeným hrotem v patě, v něm stříbrný hřeben. Vpravo vyrůstající orobinec přirozené barvy, levé pole stříbrně mřížované."
    },
    {
        obec: "Újezd u Brna",
        kod: 584045,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66453,
        latitude: 49.104448,
        longitude: 16.757474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Znak_%C3%9Ajezd_u_Brna.svg",
        coatOfArmsFlagDescription: "Zelený list se šikmým bílým pruhem širokým 2/5 šířky listu. V horním žerďovém rohu zkřížené bílé klíče. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zelený štít se stříbrným kosmým břevnem. Na něm hvězda a vinný list, oboje zelené, v horním poli zkřížené klíče, v dolním poli kosmo hrotem dolů položená šavle, oboje stříbrné."
    },
    {
        obec: "Újezd u Černé Hory",
        kod: 582557,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.370995,
        longitude: 16.543917,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/%C3%9Ajezd_u_%C4%8Cern%C3%A9_Hory_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se žlutým vzpínajícím se koněm. Poměr šíř-ky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý vzpínající se kůň."
    },
    {
        obec: "Újezd u Chocně",
        kod: 581089,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 50.026995,
        longitude: 16.164691,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/%C3%9Ajezd_u_Chocn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený a žlutý kosmý pruh široký osminu délky listu. Dole v červeném pruhu žlutý žalud, nahoře v zeleném pruhu vykračující žlutý kohout s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě kosmá zlatá ostrve provázená vpravo zlatým žaludem, vlevo vykračujícím zlatým kohoutem s červenou zbrojí."
    },
    {
        obec: "Újezd u Plánice",
        kod: 578321,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.417621,
        longitude: 13.457818,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/%C3%9Ajezd_u_Pl%C3%A1nice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, zelený, žlutý a zelený, v poměru 7 : 2 : 1 : 2. V červeném pruhu bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrné mlýnské kolo nad zeleným trojvrším se zlatým vlnitým břevnem."
    },
    {
        obec: "Újezd u Rosic",
        kod: 584053,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.222512,
        longitude: 16.253748,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/%C3%9Ajezd_u_Rosic_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 5:1:2. V žerďové polovině horního bílého pruhu černý vzpínající se kůň s červenou zbrojí a žlutým uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě černý kůň s červenou zbrojí a zlatým uzděním, vzpínající se nad červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Újezd u Přelouče",
        kod: 575909,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.102031,
        longitude: 15.494626,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/%C3%9Ajezd_u_P%C5%99elou%C4%8De_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým sluncem podloženým třemi (2, 1) bílými rozletitými střelami. Ve slunci s osmnácti paprsky čelně červená lví hlava se žlutým kruhem v tlamě. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zlatém slunci podloženém třemi stříbrnými rozletitými střelami čelně červená lví hlava se zlatým kruhem v tlamě."
    },
    {
        obec: "Újezd u Sezemic",
        kod: 572888,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53304,
        latitude: 50.114148,
        longitude: 15.855832,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/%C3%9Ajezd_u_Sezemic_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto-zeleně šachovaný list (2x3), dolní střední pole černé. Ve střední části žlutá hlavice berly závitem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě kosmo postavený černo-zlatě šachovaný čtverec (3x3), z horního pole vyrůstá zlatá hlavice berly a po stranách z rohů dva zlaté obilné klasy se dvěma listy."
    },
    {
        obec: "Újezd u Svatého Kříže",
        kod: 546551,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.859837,
        longitude: 13.56961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Ujezd_u_Svateho_Krize_RO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zeleno-modře polcený a černý, v poměru 2:3. V horním pruhu žlutý klín vycházející z horního okraje listu s vrcholem na horním okraji černého pruhu. V klínu vyrůstá červený latinský kříž. V černém pruhu žlutá dvouuchá nádoba zdobená vlnovkami. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleno-modře polceném štítě nad černou patou se zlatou dvouuchou, vlnovkami zdobenou nádobou zlatý klín s vyrůstajícím červeným latinským křížem."
    },
    {
        obec: "Újezd u Tišnova",
        kod: 549908,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59455,
        latitude: 49.365622,
        longitude: 16.32465,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/%C3%9Ajezd_u_Ti%C5%A1nova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený v poměru 3:2. V modrém pruhu volná bílá hradba s cimbuřím a černou gotickou branou s otevřenými hnědými vraty. Z ní vyniká na vlající straně bílá věž s cimbuřím a černým obloukovým oknem. Hradbu provází na žerďové straně žlutá berla s bílým sudariem závitem k žerďovému okraji a na vlající straně svisle bílý šíp se žlutým opeřením, hrotem dolů. V zeleném pruhu žlutý rak. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší se zlatým rakem volná stříbrná hradba s cimbuřím, černou gotickou branou s otevřenými dřevěnými vraty přirozené barvy a vlevo vynikající stříbrnou věží s cimbuřím s černým obloukovým oknem. Vpravo zlatá berla s doleva vlajícím stříbrným sudariem, vlevo postavený stříbrný šíp se zlatým hrotem a opeřením."
    },
    {
        obec: "Újezdec",
        kod: 507644,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.207626,
        longitude: 14.784836
    },
    {
        obec: "Újezdec",
        kod: 537535,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.108008,
        longitude: 13.964075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/%C3%9Ajezdec_%28Prachatice_District%29_CoA.jpg"
    },
    {
        obec: "Újezdec",
        kod: 578916,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.877862,
        longitude: 16.206035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/%C3%9Ajezdec_%28Svitavy_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, žlutý posetý červenými růžemi s bílými semeníky a zelený, v poměru 1 : 12 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý, zeleně lemovaný štít posetý červenými růžemi se stříbrnými semeníky."
    },
    {
        obec: "Újezdec",
        kod: 599492,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27745,
        latitude: 50.283451,
        longitude: 14.420989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/%C3%9Ajezdec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý, v poměru 3 : 1. V žerďové polovině zeleného pruhu na bílém pruhu kráčející kůň s jezdcem s kloboukem, provázený za zády růží, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě na trávníku kráčející kůň s jezdcem s kloboukem, provázený vlevo nahoře růží, vše stříbrné"
    },
    {
        obec: "Újezdec",
        kod: 592757,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68741,
        latitude: 49.036216,
        longitude: 17.272063,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Ujezdec_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým žerďovým pruhem širokým jednu čtvrtinu délky listu. V horní polovině bílého pruhu modrý vinný hrozen, v zeleném poli žlutý korunovaný dvouocasý lev s červenou zbrojí, držící v pravé tlapě bílý vinařský nůž-kosíř se žlutou rukojetí ostřím k sobě a v levé tlapě oválný žlutě lemovaný bílý štít se třemi červenými vlčími zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na stříbrném trojvrší s modrým vinným hroznem zlatý korunovaný dvouocasý lev s červenou zbrojí, držící v pravé tlapě stříbrný vinařský nůž-kosíř se zlatou rukojetí a v levé oválný zlatě lemovaný stříbrný štít se třemi červenými vlčími zuby."
    },
    {
        obec: "Újezdeček",
        kod: 567850,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.645818,
        longitude: 13.789867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Ujezdecek_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s modrým žerďovým klínem, který přiléhá k bílé krokvi vycházející z první pětiny horního a dolního okraje listu s vrcholem ve středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrným, dolů zalomeným břevnem modro-červeně dělený štít, nahoře zlatá šikmá uťatá větev s lipovým listem vpravo a dubovým vlevo."
    },
    {
        obec: "Ujkovice",
        kod: 570761,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.366961,
        longitude: 15.099546
    },
    {
        obec: "Úlehle",
        kod: 536695,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.201553,
        longitude: 13.838465
    },
    {
        obec: "Úlibice",
        kod: 573698,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50707,
        latitude: 50.43413,
        longitude: 15.437401,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/%C3%9Alibice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený, a vodorovný bílý zubatý pruh široký pětinu šířky listu se čtyřmi zuby sahajícími do sedmi desetin šířky listu a třemi stejnými mezerami. Nad ním žlutý obilný snop provázený dole dvěma šestilistými bílými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad stříbrnou cimbuřovou patou zlatý obilný snop provázený dvěma stříbrnými šestilistými růžemi se zlatými semeníky."
    },
    {
        obec: "Úlice",
        kod: 559555,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33033,
        latitude: 49.760902,
        longitude: 13.148283,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/%C3%9Alice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a červený. U dolního okraje listu zvlněný modrý pruh široký čtvrtinu šířky listu se třemi vrcholy a dvěma prohlubněmi. V první, třetí a páté osmině žlutého pruhu černé vodorovné pruhy, v červeném pruhu žlutý džbán. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít se zvýšenou modrou vlnitou patou. Pravé pole pětkrát černo-zlatě dělené, v levém červeném poli zlatý džbán."
    },
    {
        obec: "Úmonín",
        kod: 534501,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28546,
        latitude: 49.888776,
        longitude: 15.271497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/%C3%9Amon%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žluto-zeleně a zeleno-žlutě dělené v poměru 3 : 1. Do žerďového žlutého pole vyrůstá zelená lipová větev se šesti listy a třemi plodenstvími. Do vlajícího zeleného pole vyniká žlutá třípatrová hranolová věž se soklem, atikou, římsami mezi patry, třemi prázdnými okny nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít. Vpravo vyrůstá ze zelené paty zelená lipová větev se šesti listy a třemi plodenstvími, vlevo na zlaté patě zlatá hranolová třípatrová věž se soklem, atikou, římsami mezi patry, třemi prázdnými okny nad sebou."
    },
    {
        obec: "Úmyslovice",
        kod: 537900,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29001,
        latitude: 50.203491,
        longitude: 15.177694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/%C3%9Amyslovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 1 : 1 : 8 : 1 : 1. V prostředním pruhu cválající bílý kůň šikmo podložený žlutou berlou závitem k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se stříbrnou vlnkovitou patou cválající stříbrný kůň šikmo podložený zlatou berlou, převýšený zlatým řetězem vynikajícím z okrajů štítu."
    },
    {
        obec: "Únanov",
        kod: 595021,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67131,
        latitude: 48.900923,
        longitude: 16.063609,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Znak_unanov.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový klín sahající do čtyř pětin délky listu a pět vodorovných pruhů, černý, bílý, červený, bílý a černý. V zeleném klínu vodorovně položený žlutý řetěz o pěti článcích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve štítě zelený hrot, v něm je ke zlaté berle závitem doprava zlatým obojkem přikován černý ďábel s červenou zbrojí. Pravé pole pětkrát černo-stříbrně dělené, v levém stříbrném poli srdce kvetoucí třemi květy, vše červené."
    },
    {
        obec: "Unčín",
        kod: 596931,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59242,
        latitude: 49.607762,
        longitude: 16.245786,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Un%C4%8D%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě černých a bílých a svislý středový zelený pruh široký dvě třetiny šířky listu s bílým jehličnatým stromem s kmenem prostrčeným zkříženými bílými sekerkami na žlutých topůrkách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Černo-stříbrně dělený štít, nahoře se dvěma stříbrnými břevny. Ve štítě zelený hrot se stříbrným smrkem, s kmenem prostrčeným zkříženými stříbrnými sekerami na zlatých topůrkách."
    },
    {
        obec: "Únehle",
        kod: 541435,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.791701,
        longitude: 13.015482,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/%C3%9Anehle_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modro-červeně čtvrcený žerďový pruh, široký polovinu délky listu, a dva vodorovné pruhy, modrý a červený. V horní polovině čtvrceného pruhu žluto-bíle polcená lilie s odkloněnými obilnými klasy mezi listy, bílým v modrém a žlutým v červeném poli. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlato-stříbrně polcená lilie s odkloněnými obilnými klasy mezi listy, pravým stříbrným a levým zlatým."
    },
    {
        obec: "Únějovice",
        kod: 554391,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.462139,
        longitude: 13.118705
    },
    {
        obec: "Úněšov",
        kod: 559563,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33038,
        latitude: 49.882689,
        longitude: 13.149299
    },
    {
        obec: "Únětice",
        kod: 540528,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.584402,
        longitude: 13.470115,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/%C3%9An%C4%9Btice_PJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, bílý, modrý a bílý, v poměru 1 : 1 : 2 : 2 a vodorovný modrý pruh vycházející z třetí pětiny žerďového okraje. Ve svislém modrém pruhu žlutá věž s kvádrovaným soklem a cimbuřím, černou branou a obloukovým oknem. Věž provázena nahoře jednou a ve vodorovném pruhu čtyřmi (2, 2) šesticípými žlutými hvězdami vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červenou hlavou čelně postavená modrá radlice, v ní zlatá věž s kvádrovaným soklem a cimbuřím, s černou branou a obloukovým oknem. Věž provázena pěti (1, 2, 2) zlatými hvězdami."
    },
    {
        obec: "Únětice",
        kod: 539805,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25262,
        latitude: 50.150214,
        longitude: 14.354207,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Unetice_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žluto - černě čtvrcený list, v dolním žerďovém poli žlutý únětický koflík, v horním vlajícím poli žlutý vlnitý pruh se třemi vrcholy a dvěma prohlubněmi, široký osminu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černém štítě pod zvýšeným vlnitým břevnem únětický koflík, vše zlaté."
    },
    {
        obec: "Unhošť",
        kod: 533017,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.085455,
        longitude: 14.130183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Unhost_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, zelený, žlutý a modrý, v poměru 2 : 1 : 2 : 1 : 2. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku jelen se zlatým parožím a obojkem s kroužkem, vyskakující z lesa, vše přirozené barvy."
    },
    {
        obec: "Únice",
        kod: 563951,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.312345,
        longitude: 13.868508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/%C3%9Anice_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto - modře šikmo dělený list. V žerďové části žlutého pole šikmá lipová větévka se dvěma vztyčenými listy a plodenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře šikmo děleném štítě nahoře šikmá lipová větévka se dvěma vztyčenými listy a plodenstvím, dole šikmo položené dudy přirozené barvy."
    },
    {
        obec: "Uničov",
        kod: 505587,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78391,
        latitude: 49.771013,
        longitude: 17.121542,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Uni%C4%8Dov_m%C4%9Bstsk%C3%BD_znak_-_ofici%C3%A1ln%C3%AD_verze_-_barva.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený v poměru 1:2:1. Ve žlutém pruhu černá orlice s červenou zbrojí hledící k vlajícímu okraji. Červené pruhy bíle úhlopříčně propleteně mřežovány. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "včelná"
    },
    {
        obec: "Unín",
        kod: 582565,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67924,
        latitude: 49.382277,
        longitude: 16.491456,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Un%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý, v poměru 5 : 3. V zeleném pruhu položený klíč zuby nahoru a k vlajícímu okraji a meč hrotem k žerďovému okraji, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nahoře doleva položený gotický klíč zuby nahoru, pod ním položený meč, dole pět vyrůstajících odkloněných obilných klasů, vše zlaté."
    },
    {
        obec: "Unkovice",
        kod: 584061,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66463,
        latitude: 49.019318,
        longitude: 16.604146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Unkovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. V obou kosmý pruh vycházející z první třetiny žerďového okraje a první čtvrtiny horního okraje pruhu do druhé a třetí třetiny vlajícího okraje pruhu, v modrém žlutý, v zeleném bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - zeleně polceném štítě vpravo zlaté kosmé břevno, vlevo od středu vyniká půl stříbrného kalicha."
    },
    {
        obec: "Úpice",
        kod: 579777,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54232,
        latitude: 50.512472,
        longitude: 16.016173,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Upice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List praporu tvoří pět vodorovných pruhů - hnědý, žlutý, zelený, žlutý a hnědý v poměru 1:1:4:1:1. Ve středu žerďové části na zeleném pruhu je žlutá koruna. Poměr šířky k délce listu praporu je 2:3."
    },
    {
        obec: "Úpohlavy",
        kod: 565806,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.463202,
        longitude: 14.035645,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/%C3%9Apohlavy%2Cznak.b.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 3 : 5. V bílém pruhu červený středový kříž s rameny širokými desetinu šířky listu přeložený knížecí korunou. V zeleném pruhu tři zkřížené žluté obilné klasy, krajní s odkloněnými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou hlavou s červeným heroldským křížem přeloženým knížecí korunou tři zkřížené zlaté obilné klasy, krajní s odkloněnými listy."
    },
    {
        obec: "Urbanice",
        kod: 513717,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50327,
        latitude: 50.18002,
        longitude: 15.727458,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Urbanice_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený, a dva žluté vodorovné pruhy na horním a dolním okraji, široké jednu čtrnáctinu šířky listu. Uprostřed kosmo bílý okoun s červenými ploutvemi, šikmo podložený žlutou berlou s bílým sudariem závitem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě kosmý stříbrný okoun s červenými ploutvemi, šikmo podložený zlatou berlou závitem doleva se stříbrným sudariem, převýšený zvonem mezi dvěma svěšenými lipovými listy, vše zlaté."
    },
    {
        obec: "Urbanice",
        kod: 575917,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 49.974044,
        longitude: 15.567111
    },
    {
        obec: "Urbanov",
        kod: 588067,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.214457,
        longitude: 15.514113,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Coat_of_arms_of_Urbanov.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. V červeném pruhu žlutý zvon nad bílým pruhem vycházejícím ze sedmé osminy žerďového a vlajícího okraje pruhu, v zeleném bílá věž s cimbuřím a třemi (2, 1) červenými gotickými okny převýšená žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě vpravo zlatý zvon nad sníženým stříbrným vlnitým břevnem, vlevo stříbrná věž s cimbuřím a třemi (2, 1) červenými gotickými okny převýšená zlatou korunou."
    },
    {
        obec: "Určice",
        kod: 590126,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79804,
        latitude: 49.430551,
        longitude: 17.073015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Ur%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou zavinutou střelou hrotem nahoru ve žlutém mezikruží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě čelně zlatý talíř s hlavou sv. Jana Křtitele a třemi (2,1) vynikajícími stříbrnými zavinutými střelami."
    },
    {
        obec: "Úsilné",
        kod: 535494,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37010,
        latitude: 49.01349,
        longitude: 14.507364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Znak_obce_%C3%9Asiln%C3%A9.jpg",
        coatOfArmsFlagDescription: "Zelený list s modrým šikmým pruhem vycházejícím z první třetiny dolního okraje do třetí třetiny horního okraje lemovaným dvěma bílými pruhy širokými jednu třetinu šířky modrého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě, nahoře kráčející osel přirozené barvy, dole modré vlnité břevno se stříbrným lemem."
    },
    {
        obec: "Úsilov",
        kod: 554405,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34506,
        latitude: 49.396955,
        longitude: 13.127071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/%C3%9Asilov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý žlutý žerďový pruh široký polovinu délky listu a sedm vodorovných pruhů, střídavě červených a bílých, v poměru 10:1:1:1:1:1:1. Ve žlutém pruhu černý vlk ve skoku s červenou zbrojí nesoucí v zubech bílou ovci. V horním červeném pruhu bílá věž s cimbuřím a kvádrovaným soklem, s černou prolomenou branou a oknem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně polceném štítě vpravo černý vlk ve skoku s červenou zbrojí nesoucí v zubech stříbrnou ovci, vlevo nad třemi stříbrnými břevny stříbrná věž s cimbuřím a kvádrovaným soklem, s černou prolomenou branou a oknem."
    },
    {
        obec: "Úsobí",
        kod: 569658,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58254,
        latitude: 49.5143,
        longitude: 15.502573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Usobi_znak.jpg"
    },
    {
        obec: "Úsobrno",
        kod: 582573,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67939,
        latitude: 49.589023,
        longitude: 16.762988,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Usborno_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, modrý a červený, v poměru 1:3:1. Uprostřed žlutý kosočtverec dlouhý dvě třetiny délky a vysoký čtyři pětiny šířky listu se šikmo položenou modrou radlicí ostřím nahoru a hrotem k hornímu cípu, kosmo protknutou černým krojidlem ostřím nahoru a hrotem k dolnímu cípu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě palisáda, z ní vyrůstá roubená věž se dvěma okny a stanovou střechou, vše zlaté. Na palisádě šikmo položená modrá radlice hrotem nahoru, kosmo protknutá černým krojidlem hrotem dolů. Nad věží zlatá seříznutá krokev s černými kouty, přes ně dvě červené růže se zlatými semeníky."
    },
    {
        obec: "Úsov",
        kod: 541222,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78973,
        latitude: 49.798406,
        longitude: 17.010654,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/%C3%9Asov_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, červený a bílý v poměru 1:4:1. Na červeném poli bílý lev se žlutou zbrojí zády k žerdi. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ústí",
        kod: 588075,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.47362,
        longitude: 15.414883,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Usti_Jihlava_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým klínem vycházejícím z horního okraje listu s vrcholem na dolním okraji listu. V klínu knížecí koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě ve zlatém klínu knížecí koruna."
    },
    {
        obec: "Ústí",
        kod: 520306,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.516945,
        longitude: 17.766477,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/%C3%9Ast%C3%AD_PR_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - červený, modrý a žlutý, v poměru 1:2:1. V modrém poli bílá vrba se čtyřmi větvemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná vrba mezi zlato-červeně polcenými rýči."
    },
    {
        obec: "Ústí",
        kod: 570371,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75501,
        latitude: 49.308336,
        longitude: 18.003211,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/CZ_%C3%9Ast%C3%AD_COA.svg",
        coatOfArmsFlagDescription: "Modrý list se třemi žlutými liliemi (1,2) v žerďové polovině listu nad dvěma bílými vlnitými pruhy vycházejícími ze sedmnácté a devatenácté dvacetiny žerďového a vlajícího okraje. Vlnité pruhy mají čtyři vrcholy a pět prohlubní. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad dvěma stříbrnými zúženými vlnitými břevny tři (1,2) zlaté lilie."
    },
    {
        obec: "Ústí nad Labem",
        kod: 554804,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40001,
        latitude: 50.661216,
        longitude: 14.053246,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/CoA_of_%C3%9Ast%C3%AD_nad_Labem.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovným pruhů střídavě bílých a červených. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Ústí nad Orlicí",
        kod: 579891,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 49.97228,
        longitude: 16.399762,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/%C3%9Ast%C3%AD_nad_Orlic%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý. Uprostřed listu znak města. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Ústín",
        kod: 552364,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78346,
        latitude: 49.587135,
        longitude: 17.157685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c6/%C3%9Ast%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "Bílý list se zeleným pruhem na dolním okraji, širokým jednu osminu šířky listu, ze kterého vyrůstá červený jelen se žlutou zbrojí, držící vztyčenou vyrůstající žlutou berlu závitem k vlajícímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zelené paty červený jelen se zlatou zbrojí držící vztyčenou zlatou berlu závitem doleva."
    },
    {
        obec: "Ústrašice",
        kod: 599123,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.34038,
        longitude: 14.68458
    },
    {
        obec: "Ústrašín",
        kod: 537608,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.382588,
        longitude: 15.16866
    },
    {
        obec: "Ústup",
        kod: 553883,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67974,
        latitude: 49.565027,
        longitude: 16.463018
    },
    {
        obec: "Úsuší",
        kod: 584070,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.334845,
        longitude: 16.356089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Coat_of_arms_of_%C3%9Asu%C5%A1%C3%AD.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený. V žerďové polovině každého pruhu žlutá šesticípá hvězda. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Nahoře hvězda nad stoupajícím půlměsícem mezi dvěma sloupy, dole čížek provázený po stranách a dole pěti hvězdami, vše zlaté."
    },
    {
        obec: "Úštěk",
        kod: 565814,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41145,
        latitude: 50.585804,
        longitude: 14.34769,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/%C3%9A%C5%A1t%C4%9Bk_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu se dvěma černými zkříženými ostrvemi o šesti sucích a pět vodorovných pruhů střídavě modrých a bílých. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Útěchov",
        kod: 572624,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.728486,
        longitude: 16.64466,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/%C3%9At%C4%9Bchov_SY_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým jednu čtvrtinu délky listu. V bílém pruhu pod sebou tři červené růže se žlutými semeníky a zelenými kališními lístky. V modrém poli bílá koňská hlava s krkem se žlutou hřívou a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky a zelenými kališními lístky stříbrná koňská hlava s krkem se zlatou hřívou a červeným jazykem."
    },
    {
        obec: "Útěchovice",
        kod: 548987,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.478174,
        longitude: 15.116486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/%C3%9At%C4%9Bchovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a černý. V bílém pruhu červený květ vlčího máku na zeleném stonku s listem a makovicí, v černém šikmý žlutý pruh široký devítinu šířky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-černě polceném štítě vpravo červený květ vlčího máku na zeleném stonku s listem a makovicí, vlevo šikmé zlaté břevno."
    },
    {
        obec: "Útěchovice pod Stražištěm",
        kod: 562033,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.538607,
        longitude: 15.0292
    },
    {
        obec: "Útěchovičky",
        kod: 537730,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.460685,
        longitude: 15.099772
    },
    {
        obec: "Úterý",
        kod: 559571,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33041,
        latitude: 49.940181,
        longitude: 13.00429,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/%C3%9Ater%C3%BD_znak.png"
    },
    {
        obec: "Útušice",
        kod: 558486,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33209,
        latitude: 49.677894,
        longitude: 13.382148
    },
    {
        obec: "Útvina",
        kod: 555681,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36401,
        latitude: 50.070847,
        longitude: 12.954852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Coat_of_arms_of_%C3%9Atvina.svg",
        coatOfArmsFlagDescription: "Žlutý list s červeným srdcem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Úvalno",
        kod: 597937,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79391,
        latitude: 50.047254,
        longitude: 17.744838,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/%C3%9Avalno_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a zelený v poměru 7:1. Ze zeleného pruhu vyrůstá bílá kvádrovaná věž se čtyřmi prolomenými okny (2,2) zakončená stupňovitým ochozem, provázená dvěma bílými květy na zelených stoncích. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trojvrší stříbrná kvádrovaná věž se čtyřmi prolomenými okny (2,2), zakončená stupňovitým ochozem, provázená dvěma stříbrnými květy na zelených stoncích."
    },
    {
        obec: "Úvaly",
        kod: 538957,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25082,
        latitude: 50.073837,
        longitude: 14.730945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Znakuvaly.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký jednu čtvrtinu délky listu a tři vodorovné pruhy, modrý, bílý a modrý, v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku vpravo lípa přirozené barvy, vlevo volná stříbrná kvádrovaná hradba s cimbuřím a černou obloukovou branou, z ní vyniká stříbrná věž se třemi černými okny vedle sebe, s červenou valbovou střechou se dvěma zlatými makovicemi."
    },
    {
        obec: "Uzenice",
        kod: 551937,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.473354,
        longitude: 13.959944,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Uzenice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 1 : 2. V červeném pruhu tři žluté řecké kříže. V modrém pruhu knížecí koruna mezi dvěma dole zkříženými žlutými klasy, každý se dvěma listy. Poměr šířky k délce listu vlajky je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou se třemi zlatými řeckými kříži knížecí koruna mezi dvěma dole zkříženými zlatými obilnými klasy, každý se dvěma listy."
    },
    {
        obec: "Uzeničky",
        kod: 536890,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38801,
        latitude: 49.488972,
        longitude: 13.952507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Uzeni%C4%8Dky_CoA.jpg"
    },
    {
        obec: "Úžice",
        kod: 534510,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.872126,
        longitude: 14.9728
    },
    {
        obec: "Úžice",
        kod: 535257,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27745,
        latitude: 50.253007,
        longitude: 14.378957,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/%C3%9A%C5%BEice_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílou, černě zabalenou homolí cukru, podloženou čtyřmi mřežovitě zkříženými žlutými obilnými klasy, každý s jedním listem, a převýšenou dolní polovinou bílého vozového kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná, černě zabalená homole cukru, podložená čtyřmi mřežovitě zkříženými zlatými obilnými klasy, každý s jedním listem, a převýšená dolní polovinou stříbrného vozového kola."
    },
    {
        obec: "Vacenovice",
        kod: 586706,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69606,
        latitude: 48.945204,
        longitude: 17.174146,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Vacenovice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým zubatým pruhem na dolním okraji, širokým jednu pětinu šířky listu, s pěti obdélníkovými zuby a čtyřmi stejnými mezerami. Z prostředního zubu vyrůstá žlutá berla závitem k žerdi mezi dvěma odkloněnými vztyčenými bílými žaludy na žlutých stoncích s odvráceným listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná kvádrovaná hradba s cimbuřím, z prostřední stínky vyrůstá zlatá berla mezi dvěma odkloněnými vztyčenými stříbrnými žaludy na zlatých stoncích s odvráceným listem."
    },
    {
        obec: "Václavice",
        kod: 532061,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25601,
        latitude: 49.789406,
        longitude: 14.61365,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/V%C3%A1clavice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V žerďové polovině bílého pruhu černá plamenná orlice se žlutou zbrojí a jetelovitě zakončeným, uprostřed přerušeným perizoniem. Pod ní v zeleném pruhu tři žluté zkřížené obilné klasy, krajní s odkloněným listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad třemi zkříženými zlatými obilnými klasy, krajní s odkloněným listem, stříbrný praporec se třemi cípy na zlaté žerdi vynikající z pravého dolního okraje štítu, s černou plamennou orlicí se zlatou zbrojí a jetelovitě zakončeným, uprostřed přerušeným, perizoniem."
    },
    {
        obec: "Václavov u Bruntálu",
        kod: 597945,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.979799,
        longitude: 17.371834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/V%C3%A1clavov_u_Brunt%C3%A1lu_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět pruhů střídavě modrých a žlutých v poměru 11:1:1:1:2. V žerďové polovině modrého pruhu bílý vlk s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný stojící vlk s červeným jazykem, podložený dvěma zlatými odkloněnými liliemi na jednom stonku."
    },
    {
        obec: "Václavovice",
        kod: 598836,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73934,
        latitude: 49.755403,
        longitude: 18.372312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Vaclavovice_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou krokví vycházející z druhé čtvrtiny horního a dolního okraje a s vrcholem na vlajícím okraji. V žerďové polovině žluté slunce bez tváře s šestnácti střídavě rovnými a zvlněnými paprsky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě slunce nad dvěma zkříženými prohnutými obilnými klasy, vše zlaté."
    },
    {
        obec: "Václavy",
        kod: 565512,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.063791,
        longitude: 13.59452,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Coats_of_arms_V%C3%A1clavy.jpeg",
        coatOfArmsFlagDescription: "Zelený list s bílým trsem čtyř václavek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě trs čtyř stříbrných václavek."
    },
    {
        obec: "Vacov",
        kod: 550621,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38486,
        latitude: 49.13697,
        longitude: 13.729209,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Vacov_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Žlutý list s modrým žerďovým klínem s vrcholem na vlajícím okraji. V klínu vedle sebe tři žlutá kruhová pole o průměru rovném jedné čtvrtině šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zúžený kůl, provázený vpravo třemi koulemi pod sebou, vlevo berlou, vše zlaté."
    },
    {
        obec: "Vacovice",
        kod: 536504,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.140476,
        longitude: 13.781044
    },
    {
        obec: "Val",
        kod: 553255,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.146155,
        longitude: 14.756143,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Val_TU_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list. Uprostřed kosmo bílý kapr, u žerďového okraje tři žluté šesticípé hvězdy pod sebou, na vlajícím okraji tři svislé pruhy, žlutý, modrý a bílý, každý široký patnáctinu délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kosmo stříbrný kapr provázený nahoře třemi zlatými hvězdami vedle sebe a dole stříbrným hamrem se zlatým bucharem a kovadlinkou."
    },
    {
        obec: "Val",
        kod: 576875,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51801,
        latitude: 50.311302,
        longitude: 16.181692,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Val_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 5:3. Ve žlutém pruhu čtyři za sebou a vedle sebe vykračující červení volci, poslední se zvednutou oháňkou. V modrém pruhu vodorovně k žerďovému okraji obrácený žlutý obilný klas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod zlatou hlavou se čtyřmi za sebou a vedle sebe vykračujícími červenými volky, poslední se zvednutou oháňkou, tři zlaté obilné klasy."
    },
    {
        obec: "Valašská Bystřice",
        kod: 544949,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75627,
        latitude: 49.415172,
        longitude: 18.109885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/CZ_Vala%C5%A1sk%C3%A1_Byst%C5%99ice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 4:1:1. V žerďové polovině horního pruhu zkřížená sekera-širočina a sekera, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrným trojvrším kosmo položená sekera-širočina šikmo podložená sekerou, obojí zlaté."
    },
    {
        obec: "Valašská Polanka",
        kod: 544990,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75611,
        latitude: 49.262249,
        longitude: 17.996783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Vala%C5%A1sk%C3%A1_Polanka_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:4:1. V modrém poli nad kráčející bílou ovcí radlice hrotem dolů a ostřím k vlajícímu okraji a vozové kolo s dvanácti rameny, obojí žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě kráčející stříbrná ovce, provázená nahoře vpravo radlicí vlevo vozovým kolem se dvanácti rameny, obojí zlaté."
    },
    {
        obec: "Valašské Klobouky",
        kod: 585891,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76601,
        latitude: 49.139664,
        longitude: 18.00857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Valasske-klobouky-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a zelený v poměru 4:7:4. V bílém poli vysoký černý klobouk s modrou stuhou se žlutou sponou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítu nad zeleným trojvrším vysoký černý klobouk s modrou stuhou se zlatou sponou."
    },
    {
        obec: "Valašské Meziříčí",
        kod: 545058,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75701,
        latitude: 49.471904,
        longitude: 17.971237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/CoA_of_Vala%C5%A1sk%C3%A9_Mezi%C5%99%C3%AD%C4%8D%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: červený, bílý, červený a černý v poměru 6:1:1:1. V horním červeném poli žlutá hrotitá koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Valašské Příkazy",
        kod: 545112,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75612,
        latitude: 49.165971,
        longitude: 18.053888,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/CZ_Vala%C5%A1sk%C3%A9_P%C5%99%C3%ADkazy_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený. V modrém pruhu bílá sekera ostřím k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo stříbrná sekera, vlevo uťatý zelený jehličnatý strom s černým kmenem."
    },
    {
        obec: "Valdice",
        kod: 573701,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50711,
        latitude: 50.455113,
        longitude: 15.384989,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Valdice_znak.gif",
        coatOfArmsFlagDescription: "Žluto-modře čtvrcený list, uprostřed lev opačných barev s červenými drápy, jazykem a bílými zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zlato-modře čtvrceném štítě lev opačných barev s červenou zbrojí."
    },
    {
        obec: "Valašská Senice",
        kod: 553026,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75614,
        latitude: 49.225377,
        longitude: 18.11706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Vala%C5%A1sk%C3%A1_Senice_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy zelený a červený. Uprostřed bílý květ kopretiny se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě pod třemi stříbrnými květy kopretin se zlatými semeníky tři zlaté obilné klasy provázené dvěma stříbrnými kosami bez násad hroty dolů."
    },
    {
        obec: "Valdíkov",
        kod: 587699,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67503,
        latitude: 49.247109,
        longitude: 15.985337,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Valdikov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, červený a bílý, v poměru 1:2:1. V červeném pruhu kosmo žlutá berla závitem k žerdi s bílým sudariem provázená nahoře kosmo kosou bez kosiště hrotem a ostřím dolů, dole kosmo radlicí hrotem nahoru a ostřím k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kosmá zlatá berla závitem doleva se stříbrným sudariem, provázená nahoře kosmou kosou bez kosiště, dole kosmou vztyčenou radlicí, obojí stříbrné."
    },
    {
        obec: "Valeč",
        kod: 555690,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36453,
        latitude: 50.174239,
        longitude: 13.254676,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/Znak_obce_Vale%C4%8D.svg",
        coatOfArmsFlagDescription: "Červený list, z dolního okraje vyniká bílá kvádrovaná věž s cimbuřím se třemi zuby a prázdnou branou se spuštěnou žlutou mříží. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Valeč",
        kod: 591874,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67553,
        latitude: 49.144404,
        longitude: 16.03579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Vale%C4%8D_%28TR%29_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pilovitým pruhem, širokým jednu čtvrtinu délky listu se třemi zuby sahajícími do poloviny délky listu. V žerďové části červený položený latinský kříž, ve vlající části žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad ostřím doleva položeným stříbrným krojidlem vpravo vztyčená stříbrná radlice s červeným latinským křížem, vlevo půl stříbrné zavinuté střely, provázené nahoře zlatou růží s červeným semeníkem."
    },
    {
        obec: "Valchov",
        kod: 582581,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.470283,
        longitude: 16.71998,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Valchov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, černý, zelený, bílý, zelený a černý, v poměru 1:1:4:1:1, přes tři prostřední je položen žerďový červený klín s vrcholem na vlajícím okraji. V žerďové části žluté palečné kolo. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít s červeným hrotem, v něm dolů obrácené stříbrné postřihačské nůžky a mezi jejich rameny zlaté palečné kolo pod kosmým stříbrným želízkem, šikmo přeložené zlatým mlátkem. Pravé pole štítu černo-zlatě šikmo dělené, levé zlato-černě kosmo dělené, v obou po jednom zeleném dubovém věníku."
    },
    {
        obec: "Valkeřice",
        kod: 562874,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40724,
        latitude: 50.701933,
        longitude: 14.322006,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Valke%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a modrý, v poměru 3 : 1 : 8. V zeleném pruhu vztyčené žluté kopí. Ve střední části listu polovina korunovaného bílého lva s červenou zbrojí držící žlutou žerď s bílo-červeně šachovaným (4x7) praporcem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrným zúženým vlnitým kůlem zeleno-modře vpravo polcený štít. Vpravo vztyčené zlaté kopí, vlevo polovina stříbrného korunovaného lva s červenou zbrojí držící zlatou žerď se stříbrno-červeně šachovaným praporcem."
    },
    {
        obec: "Valšov",
        kod: 551783,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79201,
        latitude: 49.933456,
        longitude: 17.43824,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Val%C5%A1ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a žlutý. V modrém pruhu bílý buben s červenými šňůrami podložený šípem hrotem k hornímu okraji, kosmo mečem hrotem dolů a šikmo šavlí ostřím a hrotem dolů, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný buben s červenými šňůrami podložený vztyčeným šípem, kosmým postaveným mečem a šikmou postavenou šavlí, vše zlaté."
    },
    {
        obec: "Valtice",
        kod: 584975,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69142,
        latitude: 48.74079,
        longitude: 16.755094,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Coat_of_arms_of_Valtice.svg",
        coatOfArmsFlagDescription: "List je žlutý, v žerďové části dva červené klíny sahající hroty do středu listu praporu; poměr délky k šířce je 3:2.",
        coatOfArmsDescription: "Polcený štít, vpravo třikrát zlato-červeně dělený, vlevo ve zlatě dva červené klíny sahající do poloviny pole."
    },
    {
        obec: "Valtrovice",
        kod: 595039,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.793826,
        longitude: 16.221077,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Valtrovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, zelený, bílý a černý, v poměru 1 : 1 : 2 : 1 : 1. V zeleném pruhu žlutý stonek vinné révy střídavě se dvěma listy a dvěma červenými hrozny, obtočený kolem bílého kolíku, nahoře zakončeného křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený, vpravo dělený štít. Nahoře v červeném poli doleva obrácený stříbrný kosíř se zlatou rukojetí a vztyčená stříbrná radlice, dolní pole pětkrát stříbrno-černě děleno. V levém zeleném poli zlatý stonek vinné révy střídavě se dvěma listy a dvěma červenými hrozny, obtočený kolem stříbrného kolíku, nahoře zakončeného křížkem."
    },
    {
        obec: "Valy",
        kod: 539481,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.976907,
        longitude: 12.654905,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/37/Valy_Cheb_CoA.gif",
        coatOfArmsFlagDescription: "Bílo-zeleně jedním stupněm dělený list, na žerďovém okraji v poměru 3:1, na vlajícím okraji 1:1. V žerďové polovině bílého pole zelený vykořeněný smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě levý zelený stupeň, vpravo vykořeněný zelený smrk."
    },
    {
        obec: "Valy",
        kod: 575925,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.029694,
        longitude: 15.61687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Valy_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List praporu je bílým šikmým pruhem o šířce jedné osminy délky listu rozdělen na dvě pole, horní žerďové červené se žlutou vlčí hlavou a dolní vlající modré. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře šikmo dělený štít, nahoře zlatá vlčí hlava, dole čáp přirozených barev."
    },
    {
        obec: "Vamberk",
        kod: 576883,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51754,
        latitude: 50.117657,
        longitude: 16.290779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Vamberk_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné, střídavě červené a zelené pruhy, v poměru 2 : 2 : 3 : 3. Uprostřed listu přes všechny pruhy kráčející bílý jednorožec se žlutou zbrojí a jazykem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vanov",
        kod: 588083,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.207777,
        longitude: 15.413736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Coat_of_arms_of_Vanov.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký třetinu délky listu a sedm vodorovných pruhů, střídavě modrých a žlutých. V červeném pruhu pět (2, 2, 1) žlutých mlýnských kamenů s položenými černými kypřicemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo pět (2, 2, 1) zlatých mlýnských kamenů s položenými černými kypřicemi, vlevo tři zlatá břevna."
    },
    {
        obec: "Vanovice",
        kod: 582590,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67936,
        latitude: 49.567378,
        longitude: 16.666313,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Vanovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, žlutý a černý, v poměru 2:5:13, přes které je položen bílý klín vycházející z dolního okraje s vrcholem na středu horního okraje listu. V bílém klínu zelený vykořeněný strom bez listů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černém štítě stříbrný hrot se zeleným vykořeněným stromkem bez listů. Hrot je provázen vztyčeným klíčem a kalichem, obojí zlaté."
    },
    {
        obec: "Vápenice",
        kod: 592773,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68774,
        latitude: 48.967992,
        longitude: 17.843216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/V%C3%A1penice_znak.gif",
        coatOfArmsFlagDescription: "Bílý list se dvěma svěšenými odvrácenými květy lilie zlatohlavé na dvou prohnutých zelených stoncích vyrůstajících z jednoho kořene, dole prostrčených zkříženými modrými motykami na hnědých násadách. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě z jednoho kořene dva vyrůstající prohnuté zelené stonky se svěšenými odvrácenými květy lilie zlatohlavé přirozené barvy, dole prostrčené zkříženými modrými motykami s dřevěnými násadami přirozené barvy."
    },
    {
        obec: "Vanůvek",
        kod: 588091,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.222589,
        longitude: 15.422729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Coat_of_arms_of_Van%C5%AFvek.jpg",
        coatOfArmsFlagDescription: "List dělený šikmým kvádrovaným bílým pruhem širokým třetinu šířky listu na modré žerďové a zelené vlající pole. V modrém poli žlutá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře dělený štít. Nahoře stříbrná kvádrovaná zeď s plochou červenou stříškou. Ve zdi tři otvory sklepů-mlíčníků s doleva otevřenými dřevěnými dveřmi přirozené barvy na zlatých závěsech. Každý otvor černo-modře dělený sníženým stříbrným vlnitým břevnem. Dole zlatá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Vápenná",
        kod: 541249,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79064,
        latitude: 50.283473,
        longitude: 17.097722,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/V%C3%A1penn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 1:3:1. V bílém pruhu zelený listnatý strom s hnědým kmenem kosmo přeloženým černou sekerou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě ze zeleného návrší vyrůstající listnatý strom přirozené barvy s kmenem kosmo přeloženým černou sekerou."
    },
    {
        obec: "Vápenný Podol",
        kod: 572454,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.889133,
        longitude: 15.66685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/V%C3%A1penn%C3%BD_Podol_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, vlnitý zelený, vlnitý bílý a zvlněný černý. Přes pruhy horní vydutý modrý klín s vrcholem na dolním okraji listu. V klínu červená knížecí čepice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleno-stříbrno-černě vlnitě děleném štítě modrý vydutý klín s knížecí čepicí."
    },
    {
        obec: "Vápno",
        kod: 575933,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.10432,
        longitude: 15.533003,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/V%C3%A1pno_PA_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý s červeným křížem s rameny širokými osminu šířky listu a modrý s bílou alchymistickou značkou vápna tvořenou řeckým křížem a dolní částí mezikruží spojenou s horním ramenem kříže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném koni s červeným uzděním, zlatým sedlem a modrou sedlovou pokrývkou odvrácený sv. Jiří v římské zbroji s červeným vlajícím pláštěm, držící stříbrný štít s červeným heroldským křížem a probodávající stříbrným kopím zakončeným křížem doleva obráceného zlatého draka pod koněm. Vpravo nahoře stříbrná alchymistická značka vápna tvořená řeckým křížem a na něm položeným dolním půlobloukem."
    },
    {
        obec: "Vápovice",
        kod: 588105,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.154876,
        longitude: 15.580807
    },
    {
        obec: "Varnsdorf",
        kod: 562882,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40747,
        latitude: 50.911648,
        longitude: 14.618348,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/COA_Varnsdorf.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: modrý a bílý. V horním modrém pruhu v žerďové části šesticípá hvězda s půlměsícem cípy nahoru, obojí bílé. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Varvažov",
        kod: 562211,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.439009,
        longitude: 14.142696
    },
    {
        obec: "Vatín",
        kod: 596949,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.525037,
        longitude: 15.96732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Vat%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a žlutých v poměru 3:1:4:1:3. V prostředním modrém pruhu bílý kapr. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kapr provázený nahoře třemi zlatými hvězdami vedle sebe a dole ve spodní části zkříženými zlatými vavřínovými ratolestmi."
    },
    {
        obec: "Vavřinec",
        kod: 582603,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67913,
        latitude: 49.402702,
        longitude: 16.719949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Vav%C5%99inec_%28Blansko%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a zelený, v poměru 1:1:1:3:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře modro-zeleně polcený, vpravo doleva obrácený půlměsíc s tváří provázený vlevo osmihrotou hvězdou, obojí zlaté, vlevo stříbrný kůň ve skoku, v dolním stříbrném poli na zeleném dvojvrší lípa přirozené barvy."
    },
    {
        obec: "Vavřinec",
        kod: 534528,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.913742,
        longitude: 15.033253,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Vav%C5%99inec_%28Kutn%C3%A1_Hora_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, každý dělený v poměru 5 : 3, žerďový modro-žlutě a vlající žluto-modře. V horním žerďovém poli žlutý rošt, v horním vlajícím poli černá mnišská kápě obrácená k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě nad trojvrším opačných barev vpravo zlatý rošt, vlevo doleva obrácená černá mnišská kápě."
    },
    {
        obec: "Vážany",
        kod: 582611,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.53129,
        longitude: 16.688799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Vazany_BK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 10:1:1:1:3. Ve střední části žlutá berla závitem k žerďovému okraji s bílým sudariem vlajícím do stran. V horním rohu a v horním cípu po jedné bílé růži s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě mezi dvěma zkříženými otkami, zlatou a stříbrnou, zlatá berla se stříbrným sudariem vlajícím do stran, provázená nahoře dvěma stříbrnými růžemi s červenými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Vážany",
        kod: 593656,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.244453,
        longitude: 17.050332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/V%C3%A1%C5%BEany_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 4 : 2 : 1. Z první čtvrtiny bílého pruhu vyrůstá šikmo bílá větev jilmu se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě šikmo dolů položená větev jilmu se čtyřmi listy a čtyřmi plody, vše stříbrné."
    },
    {
        obec: "Vážany",
        kod: 592781,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68737,
        latitude: 49.034552,
        longitude: 17.312035,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/V%C3%A1%C5%BEany%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 4 : 2 : 1. Z první čtvrtiny bílého pruhu vyrůstá šikmo bílá větev jilmu se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě šikmo dolů položená větev jilmu se čtyřmi listy a čtyřmi plody, vše stříbrné."
    },
    {
        obec: "Vážany nad Litavou",
        kod: 593664,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68401,
        latitude: 49.128919,
        longitude: 16.857178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Vazany_nad_Litavou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - červený, bílý a modrý v poměru 2:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, horní polovina modro-červeně polcená, vpravo stříbrný nůž, vlevo rostoucí stříbrný jednorožec, v dolní stříbrné polovině modré vlnité břevno."
    },
    {
        obec: "Včelákov",
        kod: 572462,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53957,
        latitude: 49.817781,
        longitude: 15.884462,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/V%C4%8Del%C3%A1kov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený s bílým středovým křížem s rameny širokými třetinu šířky pruhu a modro - zeleně polcený, v poměru 1 : 2. V modrém poli bílá hornická kladívka se zlatými topůrky, v zeleném tří (1, 2) žluté úly. Na dolním okraji žluté trojvrší, prostřední vrch vyšší. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno - modře polcený štít s červenou hlavou se stříbrným heroldským křížem a zlatým trojvrším v patě. Vpravo stříbrná hornická kladívka se zlatými topůrky, vlevo tři (1, 2) zlaté včelí úly."
    },
    {
        obec: "Včelná",
        kod: 545228,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37382,
        latitude: 48.923831,
        longitude: 14.453933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Vcelna_znak.jpg",
        coatOfArmsFlagDescription: "List dělený žlutým šikmým pruhem širokým jednu čtvrtinu šířky listu na modré žerďové a zelené vlající pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlatým šikmým břevnem modro-zeleně dělený štít, nahoře zlatá podkova."
    },
    {
        obec: "Včelnička",
        kod: 549002,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.304506,
        longitude: 15.042508,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/V%C4%8Delni%C4%8Dka_CoA.jpg",
        coatOfArmsDescription: "Stříbrno-červeně polcený štít. Vpravo červená růže se zlatým semeníkem a zelenými lístky, vlevo stříbrný čáp se zlatou zbrojí."
    },
    {
        obec: "Věcov",
        kod: 596957,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59244,
        latitude: 49.616241,
        longitude: 16.170047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/V%C4%9Bcov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký polovinu délky listu a tři vodorovné pruhy, červený, bílý a červený. V žerďovém pruhu žlutý korunovaný dvouocasý lev s červenou zbrojí a jazykem, držící na žluté žerdi bílý praporec s ocasem s červeným středovým křížem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo zlatý korunovaný dvouocasý lev s červenou zbrojí držící na zlaté žerdi stříbrný praporec s ocasem s červeným heroldským křížem, vlevo stříbrný kůň ve skoku se zlatou zbrojí, jazykem, hřívou a ocasem, přidržující postavený stříbrný meč se zlatým jílcem. Dole zkřížené stříbrné sekery se zlatými topůrky."
    },
    {
        obec: "Vědomice",
        kod: 565831,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.43399,
        longitude: 14.254008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Vedomice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi, vycházejícím z dvanácté a třinácté šestnáctiny žerďového a vlajícího okraje listu. Nad první prohlubní žluté slunce s tváří o dvanácti paprscích, střídavě rovných a plamenných. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným vlnitým břevnem zlaté slunce s tváří a dvanácti paprsky, střídavě přímými a vlnitými."
    },
    {
        obec: "Vedrovice",
        kod: 595047,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67175,
        latitude: 49.020997,
        longitude: 16.378598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Vedrovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, bílý, zelený a bílý, v poměru 4:1:2:1. V červeném pruhu žlutá vydra hlavou dolů, čelně hledící a s ocasem k hornímu žerďovému rohu, v zeleném tři (1,2) žluté hrušky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo zlatá vydra hlavou dolů čelně hledící, vlevo zelená vykořeněná hrušeň s černým kmenem a třemi (1,2) zlatými hruškami."
    },
    {
        obec: "Věchnov",
        kod: 596965,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.499464,
        longitude: 16.279089,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/V%C4%9Bchnov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 1 : 3. V modrém pruhu bílá lilie, v bílém černá zubří hlava se žlutou houžví a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou hlavou se stříbrnou lilií černá zubří hlava se zlatou houžví a červeným jazykem."
    },
    {
        obec: "Vejprnice",
        kod: 559580,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33027,
        latitude: 49.729991,
        longitude: 13.276386,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Vejprnice_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-černě šikmo dělený list. V žerďovém poli půl bílého koně, ve vlajícím šikmo bílá berla se sudariem, žlutou hlavicí a hrotem, závitem k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-černě šikmo děleném štítě nahoře půl stříbrného koně, dole šikmo stříbrná berla se sudariem, zlatou hlavicí a hrotem."
    },
    {
        obec: "Vejprty",
        kod: 563404,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43191,
        latitude: 50.492426,
        longitude: 13.032237,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Vejprty_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, a svislý žlutý pruh vycházející z druhé a třetí šestiny horního a dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně děleném štítě nahoře na zeleném trávníku dva kráčející horníci v černých kabátcích, flecích a botách, ve stříbrných kalhotách a zelených čepicích, nesoucí na dřevěné tyči přirozené barvy zlatý vinný hrozen se dvěma zelenými listy, dole na zeleném trávníku kosmo vyvrácená jedle, pod vývratem vlevo valoun stříbra, vše přirozené barvy."
    },
    {
        obec: "Vejvanov",
        kod: 560227,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33828,
        latitude: 49.868769,
        longitude: 13.652523,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Vejvanov%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zvlněný zelený s jedním vrcholem a jednou prohlubní a bílý. V zeleném pruhu kosmý mlátek, šikmo přeložený želízkem, oboje černé se žlutými násadami. V bílém pruhu vztyčený zelený lipový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře modrý vlnitý kůl provázený dvěma vztyčenými zelenými lipovými listy, dole kosmý mlátek, šikmo přeložený želízkem, obojí černé na zlatých topůrkách."
    },
    {
        obec: "Vejvanovice",
        kod: 572471,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53862,
        latitude: 49.971029,
        longitude: 15.880178,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Vejvanovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a modrý, v poměru 4 : 1 : 1 : 1 : 1. Do červeného pole vyrůstají dvě dvojice překřížených a odkloněných obilných klasů převýšených korunou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlnitě dělený štít, v horním v červeném poli vyrůstají dvě dvojice překřížených a odkloněných obilných klasů převýšených korunou, vše zlaté, dolní pole třikrát stříbrno - modře vlnitě dělené."
    },
    {
        obec: "Velečín",
        kod: 578924,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33165,
        latitude: 50.074416,
        longitude: 13.401709
    },
    {
        obec: "Velehrad",
        kod: 592790,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68706,
        latitude: 49.105527,
        longitude: 17.394372,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Velehrad_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: červený, bílý a modrý. V horním žerďovém rohu červeného pruhu žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě hlava moravské orlice."
    },
    {
        obec: "Velemín",
        kod: 565849,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41131,
        latitude: 50.53909,
        longitude: 13.976864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Velem%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý se zeleným dolním klínem s vrcholem na horním okraji listu, a modrý. V klínu bílý meč se žlutým jílcem hrotem k hornímu okraji listu s čepelí provázenou dvěma žlutými ostruhovými kolečky. V modrém pruhu bílo-červeně šachované křídlo. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "Stříbrno-modře polcený štít. Vpravo v zeleném zvýšeném hrotu vztyčený stříbrný meč se zlatým jílcem provázený po stranách čepele dvěma zlatými ostruhovými kolečky, vlevo stříbrno-červeně šachované křídlo."
    },
    {
        obec: "Velemyšleves",
        kod: 566870,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.402684,
        longitude: 13.563783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Velemy%C5%A1leves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě červených a žlutých, v poměru 6 : 1 : 1 : 1 : 1. Z horního žlutého pruhu vyrůstají dva odvrácení bílí kamzíci se žlutou zbrojí, mezi nimi žlutá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlaté zúžené břevno, z něhož vyrůstají dva odvrácení stříbrní kamzíci se zlatou zbrojí, mezi nimi zlatá lilie. Dole zlaté vozové kolo."
    },
    {
        obec: "Velatice",
        kod: 584096,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66405,
        latitude: 49.197473,
        longitude: 16.753684,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Znak_obce_Velatice.gif",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem s vrcholem na vlajícím okraji, překrytým bílým žerďovým klínem s vrcholem ve středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený hrot, v něm vztyčené krojidlo provázené dvěma vztyčenými odvrácenými radlicemi, vše stříbrné. Hrot je provázen dvěma černými nádobami, každá s uchem vlevo."
    },
    {
        obec: "Veleň",
        kod: 538965,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25063,
        latitude: 50.173318,
        longitude: 14.554314,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Velen_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy modrý a bílý. Uprostřed bílo-červeně polcená věž se třemi stínkami a prázdnou branou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě stříbrno-červeně polcená věž se třemi stínkami a prázdnou branou, v ní vztyčená zelená olivová ratolest se sedmi listy."
    },
    {
        obec: "Velenice",
        kod: 562173,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47002,
        latitude: 50.719064,
        longitude: 14.664004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Znakvelenice.gif",
        coatOfArmsFlagDescription: "Bílý list s červeným žerďovým zubatým pruhem, širokým jednu čtvrtinu délky listu, se čtyřmi čtvercovými zuby a třemi stejnými mezerami. V bílém poli modrá zbrojná rukavice palcem k vlajícímu okraji s červeným tlapatým křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou cimbuřovou hlavou modrá zbrojná rukavice palcem doleva s červeným tlapatým křížem."
    },
    {
        obec: "Velenice",
        kod: 536083,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28901,
        latitude: 50.214463,
        longitude: 15.226475,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/63/Velenice_NB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 2:1:2 a na dolním okraji tři vodorovné pruhy, vlnitý modrý, vlnitý bílý a zvlněný modrý, každý široký jednu dvanáctinu šířky listu. V zelených pruzích po jednom žlutém obilném klasu se dvěma listy, ve žlutém hnědá pastýřská hůl. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě s modrou vlnitou patou se stříbrným vlnitým břevnem zlatý kůl s pastýřskou holí přirozené barvy, provázený dvěma zlatými obilnými klasy se dvěma listy."
    },
    {
        obec: "Velenka",
        kod: 534871,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28912,
        latitude: 50.139824,
        longitude: 14.895712,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Velenka_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy zelený s obilným klasem se dvěma listy a červený s gotickým klíčem zuby dolů a k žerďovému okraji, oboje žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo obilný klas se dvěma listy vlevo postavený gotický klíč zuby dovnitř, vše zlaté."
    },
    {
        obec: "Velenov",
        kod: 530824,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68001,
        latitude: 49.48694,
        longitude: 16.73287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/Velenov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a bílý, v poměru 2:1:1. Z červeného pruhu vyskakuje černý kůň s červenou zbrojí a uzděním. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-červeně děleném štítě nahoře vyskakující černý kůň s červenou zbrojí a uzděním, dole stříbrný hřeben."
    },
    {
        obec: "Velešín",
        kod: 545821,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.829589,
        longitude: 14.462614,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Vele%C5%A1%C3%ADn_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a bílý. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Velešovice",
        kod: 593681,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68301,
        latitude: 49.179261,
        longitude: 16.849241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Znak_Vele%C5%A1ovic.svg",
        coatOfArmsFlagDescription: "Modrý list; v žerďové polovině bílé vozové kolo, ze kterého vyrůstá žlutý latinský kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vozové kolo s vyrůstajícím zlatým latinským křížem."
    },
    {
        obec: "Veletiny",
        kod: 592803,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68733,
        latitude: 49.037101,
        longitude: 17.562531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Veletiny_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed žluté vozové kolo s osmi paprsky, v něm bílý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě zlaté vozové kolo, jehož středem prorůstá vykořeněná stříbrná hlava vinné révy se dvěma svěšenými hrozny nahoře po stranách. Pod nimi dva odvrácené stříbrné vinařské nože se zlatými rukojeťmi."
    },
    {
        obec: "Veletov",
        kod: 533823,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.028423,
        longitude: 15.304983
    },
    {
        obec: "Velhartice",
        kod: 557366,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.265299,
        longitude: 13.389852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Velhartice_CoA.svg"
    },
    {
        obec: "Velichov",
        kod: 555703,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36301,
        latitude: 50.284179,
        longitude: 13.009826,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/Velichov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílo - červeným pilovitým lemem širokým devítinu šířky listu. V modrém poli bílý ondřejský kříž s rameny širokými devítinu šířky listu, přeložený bílou patrovou věží s prázdnou branou, dvěma černými okny vedle sebe a s cimbuřím se třemi zuby. V horní a dolní části modrého pole dvě lilie po stranách věže, v žerďové části tři (od žerdi 2, 1) a ve vlající tři (od žerdi 1, 2) lilie, všechny žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě posetém zlatými liliemi stříbrná patrová věž s prázdnou branou, dvěma černými okny vedle sebe a s třemi stínkami, podložená zkříženými praporci s ocasy na stříbrných dřevcích, pravý červený se stříbrným vydutým hrotem se třemi (2, 1) kruhy opačných barev, levý červený se stříbrnou krokví se třemi (1, 2) červenými růžemi se zlatými semeníky."
    },
    {
        obec: "Velichovky",
        kod: 574554,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55211,
        latitude: 50.356869,
        longitude: 15.84179,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Velichovky_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů: červený, černý, bílý, černý, bílý a černý v poměru 11:3:2:3:2:3. V červeném pruhu na dělící linii žlutá fontána se dvěma tryskajícími prameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-černě dělený štít, nahoře zlatá fontána se dvěma tryskajícími prameny, dole dvě stříbrná břevna."
    },
    {
        obec: "Veliká Ves",
        kod: 538973,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25070,
        latitude: 50.243718,
        longitude: 14.455186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Velik%C3%A1_Ves_%28Prague-East_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, v poměru 5 : 6, bílý, červeně mřížovaný třemi vodorovnými a dvěma svislými pruhy, širokými sedminu šířky listu, a modrý s bílým klíčem v žerďové polovině pruhu, zuby dolů a k žerďovému okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo červená mříž do krajů, vlevo postavený stříbrný klíč."
    },
    {
        obec: "Veliká Ves",
        kod: 563412,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44101,
        latitude: 50.269771,
        longitude: 13.37473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Velik%C3%A1_Ves_CV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký čtvrtinu délky listu a dva vodorovné pruhy, červený a bílý. V červeném pruhu kosmo svěšený lipový dvojlist s plodenstvím a šikmo svěšený stonek chmele se šišticí a dvěma listy, vše žluté. V bílém pruhu kosmý mlátek šikmo přeložený želízkem, obojí černé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně zeleným břevnem děleném štítě, nahoře vpravo kosmo svěšený lipový dvojlist s plodenstvím, vlevo šikmo svěšený stonek chmele se šišticí a dvěma listy, vše zlaté. Dole kosmý mlátek šikmo přeložený želízkem, obojí černé."
    },
    {
        obec: "Velim",
        kod: 533831,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28101,
        latitude: 50.059756,
        longitude: 15.107217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Velim_CoA_CZ.gif",
        coatOfArmsFlagDescription: "List tvoří bílý kanton a dva vodorovné pruhy, žlutý a červený. V kantonu zelená větévka kakaovníku se dvěma listy a dvěma žlutými plody. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě volná čtyřmi řadami střídavě zlato-stříbrně kvádrovaná zeď, z níž vyrůstá stříbrný dvouocasý korunovaný lev se zlatou zbrojí držící v pravé tlapě zelenou větévku kakaovníku se dvěma listy a dvěma zlatými plody."
    },
    {
        obec: "Veliny",
        kod: 575941,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53401,
        latitude: 50.071154,
        longitude: 16.053816,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Veliny_CoA.jpg",
        coatOfArmsFlagDescription: "Bílo-zeleně šindelovaný list (2x5). V horních a v dolním prostředním zeleném poli vztyčená žlutá borová šiška. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Štít se šesti šindely ve dvou řadách, střídavě zelenými a stříbrnými, v zelených vztyčená zlatá borová šiška."
    },
    {
        obec: "Veliš",
        kod: 573728,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50721,
        latitude: 50.407596,
        longitude: 15.317707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Velis_znak.jpg",
        coatOfArmsFlagDescription: "List dělený bílou krokví, vycházející ze sedmé desetiny žerďového a vlajícího okraje, s vrcholem v deváté dvacetině svislé osy listu, na horní modré a dolní černé pole. V modrém poli bílá hradební zeď s cimbuřím a černou branou se zdviženou mříží mezi dvěma bílými kvádrovanými věžemi s cimbuřím, každá se třemi černými střílnami nad sebou, s červenou stanovou střechou zakončenou žlutou makovicí. V černém poli pod pohanskou korunou zkřížené meče hroty nahoru, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-černě stříbrným, nahoru lomeným břevnem dělený štít. Nahoře stříbrná zeď s cimbuřím a černou branou se zdviženou mříží mezi dvěma stříbrnými kvádrovanými věžemi s cimbuřím, každá se třemi černými střílnami nad sebou, s červenou stanovou střechou zakončenou zlatou makovicí. Dole pod zlatou pohanskou korunou zkřížené meče, vše zlaté."
    },
    {
        obec: "Veliš",
        kod: 530867,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25706,
        latitude: 49.668658,
        longitude: 14.823244,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Veli%C5%A1_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 2, zelený a bílý zubatý se sedmi čtvercovými zuby vysokými osminu šířky listu, krajní poloviční šířky, a se šesti stejně velikými mezerami. Do zeleného pruhu vějířovitě vyrůstají z prostředního zubu čtyři žluté obilné klasy, vnitřní s odkloněným listem. Klasy jsou provázené postavenými, přivrácenými bílými úhelníky. V bílém pruhu, mezi dvěma zelenými lipovými listy s plodenstvím červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně sníženě cimbuřově dělený štít. Nahoře z prostřední stínky vyrůstají čtyři zlaté vějířovitě rozložené obilné klasy, vnitřní s odkloněným listem, provázené na každé straně postaveným přivráceným stříbrným úhelníkem. Dole mezi dvěma vztyčenými zelenými lipovými listy s plodenstvím červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Velká Bíteš",
        kod: 596973,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59501,
        latitude: 49.288727,
        longitude: 16.225957,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Velk%C3%A1_B%C3%ADte%C5%A1%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a modrý. Ve žlutém pruhu půl červeno - bíle šachované orlice s celou hlavou, se žlutou zbrojí a červeným jazykem, přiléhající k modrému pruhu. V žerďové části modrého pruhu bílý dvouocasý korunovaný lev se žlutou zbrojí a červeným jazykem obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře polceném štítě vpravo vyniká půl červeno - stříbrně šachované orlice s celou hlavou se zlatou zbrojí a červeným jazykem, vlevo doleva obrácený stříbrný korunovaný dvouocasý lev se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Velká Buková",
        kod: 542563,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27023,
        latitude: 50.03466,
        longitude: 13.847293,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Coats_of_arms_Velk%C3%A1_Bukov%C3%A1.jpeg",
        coatOfArmsFlagDescription: "Zeleno-bíle kosmo dělený list; uprostřed vztyčený bukový list opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně kosmo děleném štítě vztyčený bukový list opačných barev."
    },
    {
        obec: "Velká Bukovina",
        kod: 562891,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40729,
        latitude: 50.730538,
        longitude: 14.396877,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Vbukovina_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1:2:1. Ve žlutém pruhu nad dvěma černými zkříženými ostrvemi, zelená vztyčená buková větev se třemi listy a dvěma červenými bukvicemi pod nimi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad černými zkříženými ostrvemi zelená vztyčená buková větev se třemi listy a dvěma červenými bukvicemi po stranách."
    },
    {
        obec: "Velká Bystřice",
        kod: 505609,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78353,
        latitude: 49.594326,
        longitude: 17.364082,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Coat_of_arms_of_Velk%C3%A1_Byst%C5%99ice.svg",
        coatOfArmsFlagDescription: "Modrý list, v horní části půlměsíc cípy k hornímu okraji, dole provázený třemi šesticípými hvězdami do oblouku, hrotem k hornímu okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod stoupajícím půlměsícem, tři (2, 1) hvězdy, vše zlaté."
    },
    {
        obec: "Velká Dobrá",
        kod: 533025,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27361,
        latitude: 50.109746,
        longitude: 14.069904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Velk%C3%A1_Dobr%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří patnáct střídavě bílých a modrých vodorovných pruhů v žerďové části listu a modré pole. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno - modře vpravo polcený štít, vpravo sedm modrých břeven, vlevo pět zlatých zkřížených obilných klasů se dvěma listy."
    },
    {
        obec: "Velká Hleďsebe",
        kod: 539279,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.966767,
        longitude: 12.666767,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Velka_Hledsebe_CoA.gif",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list. V horním žerďovém poli bílý šraňk, v dolním žerďovém poli tři (2,1) černé svatojakubské mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře stříbrný šraňk, dole tři (2,1) černé svatojakubské mušle."
    },
    {
        obec: "Velká Chmelištná",
        kod: 529699,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27034,
        latitude: 50.063738,
        longitude: 13.551658,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Coats_of_arms_Velk%C3%A1_Chmeli%C5%A1tn%C3%A1.jpeg",
        coatOfArmsFlagDescription: "Žlutý list se vztyčeným zeleným chmelovým listem se dvěma vyrůstajícími odvrácenými svěšenými stonky, každý s jedním lístkem a dvěma šišticemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vztyčený zelený chmelový list se dvěma vyrůstajícími odvrácenými svěšenými stonky, každý s jedním lístkem a dvěma šišticemi."
    },
    {
        obec: "Velká Chyška",
        kod: 549011,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39428,
        latitude: 49.511858,
        longitude: 15.037368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Velk%C3%A1_Chy%C5%A1ka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 1:2. V žerďové a střední části zeleného pruhu žlutý gotický dvojklíč. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod zvýšeným břevnem gotický dvojklíč, vše zlaté."
    },
    {
        obec: "Velká Jesenice",
        kod: 574562,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55224,
        latitude: 50.361175,
        longitude: 16.037736,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Velk%C3%A1_Jesenice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený, a bílý zvlněný vodorovný pruh na dolním okraji široký jednu pětinu šířky listu. V modrém pruhu vzpřímený bílý dvouocasý lev s červeným jazykem. V zeleném pruhu průčelí bílé kaple s černými dveřmi, stanovou střechou zakončenou černým patriarším křížkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě se stříbrnou vlnitou patou vpravo vzpřímený stříbrný dvouocasý lev s červeným jazykem, vlevo průčelí stříbrné kaple s černými dveřmi, stanovou střechou zakončenou černým patriarším křížkem."
    },
    {
        obec: "Velká Kraš",
        kod: 553468,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79058,
        latitude: 50.363176,
        longitude: 17.146487,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Velka_Kras_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Bílo-červeně čtvrcený list. Uprostřed antonínský kříž bez stupňů zakončený nahoře a dole dvěma prázdnými kruhy a provázený dvěma odvrácenými přiléhajícími půlměsíci, vše černé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně čtvrceném štítě antonínský kříž bez stupňů zakončený nahoře a dole dvěma prázdnými kruhy a provázený dvěma odvrácenými přiléhajícími půlměsíci, vše černé."
    },
    {
        obec: "Velká Lečice",
        kod: 513539,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26205,
        latitude: 49.814815,
        longitude: 14.323587,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Znak_obce_Velk%C3%A1_Le%C4%8Dice.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě modrých a vlnitých žlutých se dvěma vrcholy a třemi prohlubněmi, v poměru 7 : 1 : 1 : 1 : 6. V horním pruhu koruna, v dolním pruhu vedle sebe tři křížové kypřice, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vlnitě zlato-modro-zlatě dělené vlnité břevno provázené nahoře korunou, dole třemi (2,1) křížovými kypřicemi, vše zlaté."
    },
    {
        obec: "Velká Lhota",
        kod: 545147,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75701,
        latitude: 49.437895,
        longitude: 18.035719,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/CZ_Velk%C3%A1_Lhota_COA.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červeno-zeleně polcený a bílý, v poměru 1 : 6 : 1. V polceném pruhu kosmo sekera-širočina šikmo podložená motykou, obojí bílé na žlutých násadách a provázené čtyřmi (1, 2, 1) bílými kříži. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě pod stříbrnou hlavou oddělenou trojvrším kosmo položená sekera-širočina šikmo podložená motykou, obojí stříbrné na zlatých násadách a provázené čtyřmi (1, 2, 1) stříbrnými kříži."
    },
    {
        obec: "Velká Losenice",
        kod: 596981,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59211,
        latitude: 49.580804,
        longitude: 15.836834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Znak_Velka_Losenice.jpeg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a modré pole. Na dělící lince červeno-bíle polcená svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě červeno-stříbrně polcená svatojakubská mušle."
    },
    {
        obec: "Velká nad Veličkou",
        kod: 586714,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69674,
        latitude: 48.882667,
        longitude: 17.520706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Velk%C3%A1_nad_Veli%C4%8Dkou_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři pruhy, modrý, bílý a modrý, v poměru 4 : 1 : 2. V horním pruhu bílý kostel, loď se dvěma dvojicemi černých oken vedle sebe, s červenou valbovou střechou se třemi žlutými makovicemi. K lodi na vlající straně přiléhá stejně vysoká bílá věž s vysokým černým prolomeným vchodem, s červenou stanovou střechou se žlutou makovicí a bílo - červeně čtvrceným praporcem. Ze střechy věže šikmo vyniká žlutý věnec na žerdi. V dolním pruhu položena bílá orientální šavle se žlutým jílcem ostřím dolů a hrotem k žerďovému okraji. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem stříbrný kostel, loď se dvěma dvojicemi černých oken vedle sebe, s červenou valbovou střechou se třemi zlatými makovicemi. K lodi zleva přiléhá stejně vysoká stříbrná věž s vysokým černým prolomeným vchodem, s červenou stanovou střechou se zlatou makovicí a stříbrno - červeně čtvrceným praporcem. Ze střechy věže šikmo vyniká zlatý věnec na žerdi. Dole v patě položena stříbrná orientální šavle se zlatým jílcem."
    },
    {
        obec: "Velká Polom",
        kod: 510882,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74764,
        latitude: 49.863692,
        longitude: 18.093414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/Velk%C3%A1_Polom_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list s vykořeněnou zlomenou lípou s hnědým kmenem a zelenou korunou ve vlající části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě do leva zlomená vykořeněná lípa přirozených barev."
    },
    {
        obec: "Velká Skrovnice",
        kod: 581101,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56201,
        latitude: 50.030493,
        longitude: 16.317005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Velk%C3%A1_Skrovnice.svg"
    },
    {
        obec: "Velká Štáhle",
        kod: 551694,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79351,
        latitude: 49.9298,
        longitude: 17.35708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/Velk%C3%A1_%C5%A0t%C3%A1hle_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy: zelený a bílý v poměru 3:1. V žerďové polovině vyrůstají z bílého pruhu tři žluté obilné klasy, krajní odkloněné od středního. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě ze stříbrného návrší vyrůstající tři zlaté, krajní od středního odkloněné obilné klasy, provázené po obou stranách stříbrným květem na stonku."
    },
    {
        obec: "Velká Turná",
        kod: 536741,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38601,
        latitude: 49.340306,
        longitude: 13.958805
    },
    {
        obec: "Velké Bílovice",
        kod: 584983,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69102,
        latitude: 48.849388,
        longitude: 16.892379,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Coat_of_arms_of_Velke_Bilovice.jpeg",
        coatOfArmsFlagDescription: "Červený list se žlutým šikmým pruhem o šířce 1/3 šířky listu. V horním žerďovém rohu žlutý vinný hrozen se zeleným listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Na červeném štítě se zlatým kůlem černá tyč ovinutá dvěma zelenými stonky vinné révy se zelenými listy zakončenými zlatým hroznem, provázená stříbrným vinařským nožem a radlicí."
    },
    {
        obec: "Velké Albrechtice",
        kod: 568422,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74291,
        latitude: 49.750537,
        longitude: 18.044045,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Velk%C3%A9_Albrechtice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s vykořeněným listnatým stromem s bílou korunou a žlutým kmenem v žerďové polovině listu a žlutým sluncem při horním okraji. Na vlajícím okraji bílý svislý pruh široký jednu pětinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze stříbrného návrší listnatý strom se zlatým kmenem a stříbrnou korunou, provázený vlevo nahoře zlatým sluncem."
    },
    {
        obec: "Velké Březno",
        kod: 568350,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40323,
        latitude: 50.662808,
        longitude: 14.141852,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Velk%C3%A9_B%C5%99ezno_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, vlnitý žlutý, vlnitý bílý, vlnitý žlutý a zvlněný červený, v poměru 3 : 1 : 3 : 1 : 4. Vlnité pruhy mají tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrným vlnitým břevnem modro-červeně dělený štít. V horním poli zlatý latinský kříž kosmo podložený naběráčkem a šikmo lopatkou, obojí stříbrné. V dolním poli vyniká z břevna zlaté vozové kolo."
    },
    {
        obec: "Velké Hamry",
        kod: 563838,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46845,
        latitude: 50.713834,
        longitude: 15.315498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Velk%C3%A9_Hamry_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílý list se dvěma svislými modrými pruhy, vycházejícími z druhé a čtvrté dvanáctiny horního a dolního okraje listu, a s volným černým hamrem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod dvěma modrými břevny černý hamr."
    },
    {
        obec: "Velké Heraltice",
        kod: 510891,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74775,
        latitude: 49.975025,
        longitude: 17.728896,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Velke_Heraltice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým třetinovým pruhem překrytým červeným žerďovým klínem s bílou lilií a s vrcholem sahajícím k okraji bílého pole. Z bílého pole vychází polovina bílého mezikruží se čtyřmi bílými plameny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít, nahoře červený klín se stříbrnou lilií, dole polovina stříbrného plamenného kola."
    },
    {
        obec: "Velké Hostěrádky",
        kod: 584991,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69174,
        latitude: 49.033238,
        longitude: 16.870336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Coat_of_arms_of_Velke_Hosteradky.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V bílém červená kvádrovaná věž s cimbuřím, podložená dvěma černými zkříženými palcáty. V zeleném žlutý vinný hrozen mezi dvěma kosmo a šikmo přivrácenými vinařskými noži - kosíři. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně děleném štítě nahoře červená kvádrovaná věž s cimbuřím podložená dvěma zkříženými černými palcáty, dole zlatý vinný hrozen provázený dvěma přivrácenými stříbrnými vinařskými noži (kosíři) se zlatými rukojeťmi."
    },
    {
        obec: "Velké Hoštice",
        kod: 510939,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74731,
        latitude: 49.936189,
        longitude: 17.973904,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Znak_obce_Velk%C3%A9_Ho%C5%A1tice.gif",
        coatOfArmsFlagDescription: "Žlutý list s černým bůvolím rohem a radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě bůvolí roh a radlice, obojí černé."
    },
    {
        obec: "Velké Hydčice",
        kod: 557374,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.299235,
        longitude: 13.668052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/Velke_Hydcice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý s bílou vodorovnou ostrví se třemi suky (2, 1) k vlajícímu okraji a červený s bílou kotvou bez příčného ramene. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře položená ostrev se třemi (2, 1) suky doleva, dole kotva bez příčného ramene, obojí stříbrné."
    },
    {
        obec: "Velké Chvojno",
        kod: 555223,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40334,
        latitude: 50.731698,
        longitude: 14.036815,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Velk%C3%A9_Chvojno_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový jedlový bílý pruh široký čtvrtinu délky listu a zelené pole, v něm žlutý snop se šesti klasy provázený k žerďovému okraji bílým osmihrotým křížem a k vlajícímu okraji bílou růží se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou jedlovou hlavou zlatý obilný snop se šesti klasy provázený vpravo stříbrným osmihrotým křížem a vlevo stříbrnou růží se zlatým semeníkem."
    },
    {
        obec: "Velké Janovice",
        kod: 596990,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.587942,
        longitude: 16.220152,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Velk%C3%A9_Janovice_CoA.jpg",
        coatOfArmsFlagDescription: "U horního okraje listu červený vodorovný pruh s knížecí korunou široký čtvrtinu šířky listu, pod ním dva svislé pruhy, modrý s bílou lilií a bílý s černou zubří hlavou se žlutou houžví a červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře polceném štítě pod červenou hlavou s knížecí korunou vpravo stříbrná lilie, vlevo černá zubří hlava se zlatou houžví a červeným jazykem."
    },
    {
        obec: "Velké Karlovice",
        kod: 545163,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75606,
        latitude: 49.360736,
        longitude: 18.283665,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/CZ_Velk%C3%A9_Karlovice_COA.svg",
        coatOfArmsFlagDescription: "List tvoří horní červené a dolní zelené pole v poměru 3:1. V červeném poli je bílý stojící kozel se žlutou zbrojí (rohy, kopyta). Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném dvojvrší s pravým vyšším a levým nižším vrchem stojí stříbrný kozel se zlatou zbrojí."
    },
    {
        obec: "Velké Kunětice",
        kod: 569453,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79052,
        latitude: 50.316673,
        longitude: 17.266034,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Coat_of_arms_of_Velk%C3%A9_Kun%C4%9Btice.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 3:2. V modrém pruhu tři bílé květy se šesti okvětními lístky a žlutým semeníkem, v bílém pruhu červená koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném srdci, z něhož vyrůstají tři stříbrné šestilisté květy se zlatými semeníky, červená koruna."
    },
    {
        obec: "Velké Losiny",
        kod: 541265,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78815,
        latitude: 50.03207,
        longitude: 17.040687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Velke_Losiny_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list s červenou merkou z obecního znaku zaujímající třetinu délky a dvě třetiny šířky listu. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Vlnitým řezem dělený štít, v horním červeném poli vyrůstá půl černého korunovaného dvouocasého lva se zlatou zbrojí. Lev drží stříbrný štítek s červenou merkou v podobě arabské čtyřky, na níž je položeno písmeno 'S'. Spodní pole třikrát stříbrně a černě vlnitě děleno."
    },
    {
        obec: "Velké Meziříčí",
        kod: 597007,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.356189,
        longitude: 16.013158,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/80/Velk%C3%A9_Mezi%C5%99%C3%AD%C4%8D%C3%AD_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, červený a bílý v poměru 1:5:1. Ve středním pruhu žluté vypuklé perizonium ukončené jetelovými trojlístky a postrkané sedmi bílými pery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlaté vypuklé perizonium ukončené jetelovými trojlístky a postrkané sedmi stříbrnými pery."
    },
    {
        obec: "Velké Němčice",
        kod: 585009,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69163,
        latitude: 48.991777,
        longitude: 16.672186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Znak_m%C4%9Bstysu_Velk%C3%A9_N%C4%9Bm%C4%8Dice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. V červeném pruhu horní polovina bílé berly s bílým sudariem se žlutým třepením, závitem k žerďovému okraji. K červenému pruhu přiléhá půl černého orla s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zlatě polceném štítě vpravo vyrůstající stříbrná berla se stříbrným sudariem se zlatým třepením, vlevo vynikající půl černého orla s červeným jazykem."
    },
    {
        obec: "Velké Opatovice",
        kod: 582646,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67963,
        latitude: 49.612471,
        longitude: 16.679574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Velk%C3%A9_Opatovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutá žerďová část, ke které přiléhá polovina černé orlice s červenou zbrojí a žlutou korunou v bílé vlající části. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Velké Pavlovice",
        kod: 585017,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69106,
        latitude: 48.904792,
        longitude: 16.816155,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Velk%C3%A9_Pavlovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový modrý s bílou zavinutou střelou hrotem k hornímu okraji listu, a vlající, dělený šikmým žluto-červeně děleným pruhem vycházejícím z druhé a třetí třetiny žerďového okraje pruhu do první a druhé třetiny vlajícího okraje pruhu na horní červené a dolní žluté pole. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Velké Petrovice",
        kod: 574571,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54954,
        latitude: 50.517437,
        longitude: 16.206593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/Velk%C3%A9_Petrovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým obrněným ramenem bez rukavice držícím šikmo liliové žezlo, provázené nahoře dvěma bílými vztyčenými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě obrněné rameno s rukou přirozené barvy držící liliové žezlo, provázené nahoře dvěma vztyčenými lipovými listy, vše stříbrné."
    },
    {
        obec: "Velké Popovice",
        kod: 538981,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25169,
        latitude: 49.922621,
        longitude: 14.63945,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Velke_Popovice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, červený, bílý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, pravé pole červeno-stříbrně kosmo dvěma a půl hroty děleno, v levém zeleném poli vztyčená stříbrná limpa."
    },
    {
        obec: "Velké Poříčí",
        kod: 547646,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54932,
        latitude: 50.46187,
        longitude: 16.189414,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Vporici_znak.jpg",
        coatOfArmsFlagDescription: "List se dvěma vodorovnými pruhy - bílým a modrým, mezi které je vsunut žlutý žerďový klín sahající do poloviny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Na modrém štítě je stříbrný kráčející beránek (agnus Dei) se zlatými kopýtky a zlatou svatozáří, držící pod pravou přední pozdviženou nohou zlatou křížem ukončenou žerď, na níž je zavěšena korouhev se zlatým příčným ráhnem a se dvěma cípy, z nichž pravý je stříbrný a levý zlatý."
    },
    {
        obec: "Velké Přílepy",
        kod: 539813,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25264,
        latitude: 50.160605,
        longitude: 14.314579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Velk%C3%A9_P%C5%99%C3%ADlepy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma žlutými zvony vedle sebe. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zvýšeně černo-modře třikrát lomeným zlatým zúženým břevnem dělený štít, dole dva zvony, pod nimi řecký kříž, vše zlaté."
    },
    {
        obec: "Velké Přítočno",
        kod: 533033,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27351,
        latitude: 50.116572,
        longitude: 14.128345,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Velk%C3%A9_P%C5%99%C3%ADto%C4%8Dno_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, červený, bílý a zvlněný modrý, v poměru 1 : 1 : 1 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře stříbrným břevnem, nahoře vydutým a dole vlnitým, dělený štít. Nahoře celý stříbrný pluh se zlatým kováním, dole stříbrná ovce se zlatou zbrojí."
    },
    {
        obec: "Velké Svatoňovice",
        kod: 579785,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54235,
        latitude: 50.531736,
        longitude: 16.02864,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Coats_of_arms_of_Velke_Svatonovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, vlnitý žlutý s jedním vrcholem a dvěma prohlubněmi, a zvlněný zelený, v poměru 5 : 3 : 8. V modrém pruhu v žerďové polovině kosmý mlátek šikmo podložený želízkem, ve vlající polovině kosmo letící jestřáb, v zeleném pruhu třmen s jetelovým závěsem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší volná palisádová hradba s branou a roubenou tvrzí s oknem, obojí prázdné, zakončenou stanovou střechou, provázená nahoře vpravo kosmým mlátkem šikmo podloženým želízkem, vlevo kosmo letícím jestřábem. V návrší třmen s jetelovým závěsem. Vše zlaté."
    },
    {
        obec: "Velké Tresné",
        kod: 587893,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.575246,
        longitude: 16.380585,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Velk%C3%A9_Tresn%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest svislých pruhů střídavě černých a bílých v poměru 1 : 1 : 1 : 1 : 1 : 10. Ve střední a vlající části černá zubří hlava s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod černou hlavou se dvěma stříbrnými břevny černá zubří hlava s červeným jazykem a zlatou houžví."
    },
    {
        obec: "Velké Všelisy",
        kod: 536857,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29427,
        latitude: 50.379137,
        longitude: 14.745169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Velk%C3%A9_V%C5%A1elisy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, oboustranně zubatý modrý a bílý, v poměru 3 : 10 : 3. Do modrého pruhu vyrůstají čtyři odkloněné žluté obilné klasy, každý s listem, mezi nimi bílý meč se žlutým jílcem hrotem k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrý štít se stříbrnou kvádrovanou hradbou v patě, z níž vyrůstají čtyři odkloněné zlaté obilné klasy, každý s listem. Mezi klasy postavený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Velké Žernoseky",
        kod: 565857,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41201,
        latitude: 50.539512,
        longitude: 14.063884,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Velkez_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý modrý žerďový pruh o šířce jedné třetiny délky listu a dva vodorovné pruhy žlutý a zelený. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlato-zeleně dělený štít, nahoře modrý vinný hrozen se dvěma zelenými listy s úponky, dole stříbrný mlýnský kámen s černou kypřicí."
    },
    {
        obec: "Velký Beranov",
        kod: 588113,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58821,
        latitude: 49.405141,
        longitude: 15.667107,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Velk%C3%BD_Beranov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 1 : 2. V červeném pruhu otevřená kniha, v modrém beraní hlava s krkem a žlutou zbrojí, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě, pod červenou hlavou s otevřenou knihou, beraní hlava s krkem a zlatou zbrojí, vše stříbrné."
    },
    {
        obec: "Velký Bor",
        kod: 557382,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34101,
        latitude: 49.365032,
        longitude: 13.701518,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Vb_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený se žlutou korunou a bílý se zelenou vykořeněnou borovicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo zlatá koruna, vlevo zelená vykořeněná borovice."
    },
    {
        obec: "Velký Borek",
        kod: 535265,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27731,
        latitude: 50.345385,
        longitude: 14.515362,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Velk%C3%BD_Borek_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, bílý a zelený, v poměru 6 : 1 : 1. V horním zeleném pruhu mlýnské kolo převýšené zkříženými obilnými klasy, každý s listem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným vlnitým břevnem v patě, mlýnské kolo převýšené zkříženými obilnými klasy, každý s listem, vše zlaté."
    },
    {
        obec: "Velký Chlumec",
        kod: 531910,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.82779,
        longitude: 14.087127,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Velky_Chlumec_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři šikmé pruhy: bílý, červený a žlutý, mezi horním černým trojúhelníkovým polem a dolním červeným trojúhelníkovým polem. Šikmé pruhy jsou široké jednu čtvrtinu šířky listu a dělící čára mezi bílým a červeným pruhem tvoří úhlopříčku listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený vysoký pahorek, v němž zlatý kalich. Nad pahorkem dvě černé vzhůru postavené kančí hlavy se stříbrnou zbrojí a červeným jazykem."
    },
    {
        obec: "Velký Karlov",
        kod: 545325,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67128,
        latitude: 48.804633,
        longitude: 16.305563,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Velk%C3%BD_Karlov_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným lemem širokým jednu osminu šířky listu. Uprostřed tři odkloněné zelené obilné klasy, krajní s jedním listem, vodorovně přeložené černou ostrví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným lemem tři odkloněné zelené obilné klasy, krajní s jedním listem, doleva přeložené černou ostrví."
    },
    {
        obec: "Velký Luh",
        kod: 578002,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.19907,
        longitude: 12.372344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Velk%C3%BD_Luh_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelené čtvercové pole se žlutým žerďovým klínem s červenou vážkou a dva svislé pruhy, bílý a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý klín s červenou vážkou nad stříbrno-modře vlnitě dělenou vlnitou patou."
    },
    {
        obec: "Velký Malahov",
        kod: 554413,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.623677,
        longitude: 12.955437,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/Velk%C3%BD_Malahov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 1 : 3. V bílém pruhu kosmo modrý meč hrotem k hornímu rohu listu, šikmo přeložený zelenou jívovou větévkou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou hlavou kosmo vztyčený modrý meč šikmo přeložený zelenou větévkou jívy."
    },
    {
        obec: "Velký Ořechov",
        kod: 585912,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76307,
        latitude: 49.109464,
        longitude: 17.668544,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Velky_orechov_znak.gif",
        coatOfArmsFlagDescription: "Červený list s bílým žerďovým a vlajícím klínem s vrcholy ve středu listu. V bílém žerďovém poli hnědý vlašský ořech s puklou zelenou slupkou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný snížený hrot se zeleným puklým ořechem, řapíkem nahoru, hrot je provázen dvěma stříbrnými liliemi."
    },
    {
        obec: "Velký Osek",
        kod: 533840,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28151,
        latitude: 50.098769,
        longitude: 15.186392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Velky_Osek_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý. V žerďové části bílého pruhu červené dračí křídlo kosmo probodené žlutým kopím zakončeným křížem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou patou červený rozkřídlený drak, doleva hledící, šikmo dolů probodený zlatým kopím zakončeným křížem; v patě stříbrné vřídlo."
    },
    {
        obec: "Velký Ratmírov",
        kod: 562599,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37701,
        latitude: 49.177749,
        longitude: 14.937594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/Velk%C3%BD_Ratm%C3%ADrov_CoA.png",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým sedminu šířky listu na žerďové zelené pole se žlutým snopem a na vlající modré pole se žlutým křídlem pery k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře šikmým stříbrným vlnitým břevnem dělený štít. Nahoře snop, dole křídlo, obojí zlaté."
    },
    {
        obec: "Velký Rybník",
        kod: 549045,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.490331,
        longitude: 15.308075,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/Velk%C3%BD_Rybn%C3%ADk_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed dvě odvrácené prohnuté bílé ryby hlavami k hornímu rohu a cípu, provázené pěti (1,2,1,1) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě vztyčené odvrácené prohnuté stříbrné ryby provázené pěti (1,2,1,1) zlatými hvězdami."
    },
    {
        obec: "Velký Šenov",
        kod: 562912,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40778,
        latitude: 50.990885,
        longitude: 14.374393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Velk%C3%BD_%C5%A0enov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, bílý, červený, bílý a modrý, v poměru 15 : 1 : 8 : 1 : 15. Ve žlutém pruhu horní polovina černého ozubeného kola vynikající z horního bílého pruhu, v červeném pruhu bílý meandr. V modrém pruhu pět (3, 2) zelených jetelových trojlístků. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Velký Třebešov",
        kod: 574589,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55202,
        latitude: 50.392984,
        longitude: 16.006468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Vtrebesov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině tři vodorovné pruhy, bílý, černý a bílý, v poměru 2:1:1 a modré pole ve vlající polovině. V horním bílém pruhu červená růže se žlutým semeníkem a kališními lístky. V modrém poli bílý čáp s černým křídlem a červenou zbrojí. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře polceném štítě vpravo černé břevno provázené nahoře červenou růží se zlatým semeníkem a kališními lístky, vlevo stříbrný čáp s černým křídlem a červenou zbrojí."
    },
    {
        obec: "Velký Týnec",
        kod: 505650,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78372,
        latitude: 49.552072,
        longitude: 17.33766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Velk%C3%BD_T%C3%BDnec_znak.svg",
        coatOfArmsFlagDescription: "List tvoří červená žerďová část se třemi bílými klíny s vrcholy na žerďovém okraji a zelená vlající část s bílou vztyčenou radlicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře tři kužely, dole doprava otočená radlice, vše stříbrné."
    },
    {
        obec: "Velký Újezd",
        kod: 505668,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78355,
        latitude: 49.578679,
        longitude: 17.483576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/Velky_Ujezd_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří červené žerďové pole s bílým parožím a modré vlající pole se žlutým dvouocasým lvem s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře čtvrcený štít, v prvém poli stříbrné paroží, ve druhém poli zlatý dvouocasý lev s červenou zbrojí, přes třetí a čtvrté pole položena stříbrná svatojakubská mušle."
    },
    {
        obec: "Velký Valtinov",
        kod: 562203,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47125,
        latitude: 50.743566,
        longitude: 14.735433,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Velk%C3%BD_Valtinov_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se žluto-červeně polceným žerďovým pruhem širokým jednu třetinu délky listu. V žerďovém pruhu dvě polcené krokve opačných barev vycházející z první a třetí čtvrtiny žerďového okraje s vrcholy v polovině šířky listu a na dolním okraji. Ve střední části listu bílý korunovaný dvouocasý lev se žlutou zbrojí a červeným jazykem, držící bílý žlutě korunovaný sloup, z koruny vyrůstají dvě žluté odkloněné palmové ratolesti. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít. Vpravo ve zlato-červeně polceném poli dvě odvrácené krokve opačných barev. Vlevo v zeleném poli stříbrný korunovaný dvouocasý lev se zlatou zbrojí a červeným jazykem, držící stříbrný zlatě korunovaný sloup, z koruny vyrůstají dvě zlaté odkloněné palmové ratolesti."
    },
    {
        obec: "Velký Vřešťov",
        kod: 579793,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54454,
        latitude: 50.358583,
        longitude: 15.751652,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Velk%C3%BD_V%C5%99e%C5%A1%C5%A5ov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zvlněný modrý, v poměru 3 : 1. V bílém pruhu modrý zvon, v něm žlutý třmen. V modrém pruhu bílý sloup pranýře. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vlevo vlnitě polcený štít. Vpravo modrý zvon, v něm zlatý třmen, vlevo stříbrný sloup pranýře."
    },
    {
        obec: "Veltěže",
        kod: 566896,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.353316,
        longitude: 13.875325
    },
    {
        obec: "Veltruby",
        kod: 533858,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.070692,
        longitude: 15.184648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Veltruby_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1 : 2 : 1. V modrém pruhu ze žluté trubky se závěsem vyrůstá bílý dvouocasý korunovaný lev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V děleném štítě nahoře v modrém poli ze zlaté trubky se závěsem vyrůstá stříbrný dvouocasý korunovaný lev. V dolním zlato - zeleně děleném poli nad modrou vlnitou patou vykořeněná lípa přirozené barvy."
    },
    {
        obec: "Veltrusy",
        kod: 535273,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27746,
        latitude: 50.270563,
        longitude: 14.328679,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Veltrusy_-_CoA.png"
    },
    {
        obec: "Vělopolí",
        kod: 552615,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73959,
        latitude: 49.700533,
        longitude: 18.571008,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/V%C4%9Blopol%C3%AD_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Uprostřed bílý kráčející kůň. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně šikmo děleném štítě stříbrný kráčející kůň nad položeným zlatým obilným klasem."
    },
    {
        obec: "Velvary",
        kod: 533041,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27324,
        latitude: 50.281619,
        longitude: 14.236266,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Velvary%2C_znak.svg",
        coatOfArmsFlagDescription: "V červeném listu dvě bílé věže. Každá má rozšířený sokl, římsou oddělené patro, dvě černá okna nad sebou, rozšířený ochoz, valbovou střechu zakončenou dvěma makovicemi a na ochoze dvě nárožní věžičky, každá s černým oknem a stanovou střechou zakončenou makovicí, střechy modré, makovice žluté. Mezi věžemi nízká bílá kvádrová zeď, na ní bílý korunovaný lev se žlutou zbrojí opírající se o věž v žerďové části. Lví ocas ovíjí zezadu druhou věž a jeho konec je prostrčen dolním oknem k vlajícímu okraji. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě dvě stříbrné věže. Každá má rozšířený sokl, římsou oddělené patro, dvě černá okna nad sebou, rozšířený ochoz, valbovou střechu zakončenou dvěma makovicemi a na ochoze dvě nárožní věžičky, každá s černým oknem a stanovou střechou zakončenou makovicí, střechy modré, makovice zlaté. Mezi věžemi nízká stříbrná kvádrová zeď, na ní stříbrný korunovaný lev se zlatou zbrojí opírající se o pravou věž. Lví ocas ovíjí zezadu levou věž a jeho konec je prostrčen dolním oknem."
    },
    {
        obec: "Vémyslice",
        kod: 595055,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67142,
        latitude: 49.022141,
        longitude: 16.256897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/11/V%C3%A9myslice_-_CoA.png"
    },
    {
        obec: "Vendolí",
        kod: 578932,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56914,
        latitude: 49.739926,
        longitude: 16.413371,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Vendol%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 1:3:1. V modrém pruhu vyrůstají z dolního okraje listu tři odkloněné obilné klasy, krajní s jedním listem, prostřední provázený dvěma šesticípými hvězdami, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě tři vyrůstající odkloněné obilné klasy, krajní s jedním listem, prostřední provázený dvěma hvězdami, vše zlaté."
    },
    {
        obec: "Vendryně",
        kod: 554928,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73994,
        latitude: 49.666714,
        longitude: 18.713186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Wedrynia_CoA.svg",
        coatOfArmsFlagDescription: "Červený list s modrým svislým žerďovým pruhem s polovinou žluté orlice s červenou zbrojí, širokým jednu třetinu délky listu. U orlice v červeném poli tři bílé mušle pod sebou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. V pravém poli vyniká polovina zlaté orlice s červenou zbrojí, v levém tři stříbrné mušle pod sebou."
    },
    {
        obec: "Vepříkov",
        kod: 569674,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.744231,
        longitude: 15.571018,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/88/Vep%C5%99%C3%ADkov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 3 : 2. V bílém pruhu černý kanec se žlutými zuby a červeným jazykem, v červeném pruhu bílý pluh koly k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně sníženě děleném štítě nahoře černý kanec se zlatými zuby a červeným jazykem, dole stříbrný, doleva obrácený pluh s koly."
    },
    {
        obec: "Vepřová",
        kod: 597015,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59211,
        latitude: 49.612545,
        longitude: 15.82748,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Vep%C5%99ov%C3%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žlutý, zelený, bílý a zelený, v poměru 1:4:1:1:1. Do žlutého pruhu vyrůstá černý kanec s bílými zuby a červenou zbrojí a jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě ze zeleného trojvrší se zkříženými stříbrnými vinařskými noži se zlatými rukojeťmi vyrůstá černý kanec se stříbrnými zuby, červenou zbrojí a jazykem."
    },
    {
        obec: "Verměřovice",
        kod: 581119,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56152,
        latitude: 50.005589,
        longitude: 16.562656,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Verm%C4%9B%C5%99ovice.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým šikmým pruhem širokým jednu třetinu šířky listu s červeným parohem, v horní žerďové a dolní vlající části po žlutém šestilistém květu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné šikmé břevno s červeným parohem, provázené dvěma zlatými květy."
    },
    {
        obec: "Verneřice",
        kod: 562921,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40725,
        latitude: 50.661245,
        longitude: 14.301255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/Verne%C5%99ice_CoA.png"
    },
    {
        obec: "Vernéřovice",
        kod: 547743,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54982,
        latitude: 50.614973,
        longitude: 16.221073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Vern%C3%A9%C5%99ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a modrý, v poměru 6 : 1 : 3. V červeném pruhu vztyčený žlutý plamenný meč podložený bílými váhami. V modrém pruhu žlutá růže s bílým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře sníženým stříbrným zúženým vlnitým břevnem děleném štítě nahoře vztyčený zlatý plamenný meč podložený stříbrnými váhami, dole zlatá růže se stříbrným semeníkem."
    },
    {
        obec: "Vernířovice",
        kod: 554146,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78815,
        latitude: 50.031808,
        longitude: 17.130104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Vern%C3%AD%C5%99ovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pilovité pruhy, žerďový červený s bílou halapartnou bez ratiště v horním rohu, vlající žlutý pruh má čtyři zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě vyrůstá ze zlatých plamenů černý korunovaný dvouocasý lev se zlatou zbrojí, držící před sebou stříbrnou halapartnu na zlaté násadě. V plamenech černá zkřížená hornická kladívka."
    },
    {
        obec: "Věrovany",
        kod: 552119,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78375,
        latitude: 49.461184,
        longitude: 17.288054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/V%C4%9Brovany_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, žlutý, zelený, bílý a červený v poměru 1:2:3:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou patou se dvěma červenými račími klepety vztyčený zlatý obilný klas provázený dvěma přivrácenými stříbrnými radlicemi."
    },
    {
        obec: "Verušičky",
        kod: 555711,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36452,
        latitude: 50.14131,
        longitude: 13.173868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Veru%C5%A1i%C4%8Dky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. Z poslední pětiny horního a dolního okraje listu s vrcholem ve středu listu vycházející bílá krokev. V žerďové části tři poloviční bílí dvouocasí lvi nad sebou se žlutou zbrojí, každý drží šikmo vztyčený bílý meč se žlutým jílcem. V krokvi tři červené růže. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě snížená stříbrná krokev se třemi červenými růžemi, provázená třemi polovičními dvouocasými lvy se zlatou zbrojí, každý drží šikmo vztyčený stříbrný meč se zlatým jílcem."
    },
    {
        obec: "Veřovice",
        kod: 500259,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74273,
        latitude: 49.539264,
        longitude: 18.114362,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Verovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílou ovcí držící šikmo žlutou motyku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná ovce držící zlatou motyku."
    },
    {
        obec: "Ves Touškov",
        kod: 558494,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33301,
        latitude: 49.661704,
        longitude: 13.119336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Ves_Tou%C5%A1kov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed žlutá mitra s trojím černým jelením parožím (2, 1) a bílými vlajícími fimbriemi se žlutým třepením, nahoře provázená žlutými liliemi a dole do oblouku šňůrou devíti bílých perel. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě zlatá mitra s trojím (2, 1) černým jelením parožím, se stříbrnými fimbriemi se zlatým třepením, provázená nahoře dvěma zlatými liliemi, dole do oblouku šňůrou devíti stříbrných perel."
    },
    {
        obec: "Vesce",
        kod: 553263,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.249796,
        longitude: 14.676986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Vesce_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, zelený, bílý pilovitý, modrý, bílý, černý a bílý, v poměru 5 : 3 : 1 : 1 : 1 : 1. Pilovitý pruh má tři zuby s vrcholy ve dvanáctině šířky listu, v každém zubu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modrým vlnitým břevnem dělený štít. V horním, třemi hroty zeleno-stříbrně děleném poli dole tři červené růže se zlatými semeníky a zelenými kališními lístky. V dolním stříbrném poli šest (2, 1, 2, 1) volných dotýkajících se černých cihel."
    },
    {
        obec: "Veselá",
        kod: 549053,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39470,
        latitude: 49.319162,
        longitude: 15.226841,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/CoA_of_Vesel%C3%A1%28okres_Pelh%C5%99imov%29.svg",
        coatOfArmsFlagDescription: "Červený list s bílou supí hlavou s krkem, se žlutým zobákem a bílým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě stříbrná supí hlava s krkem, se zlatým zobákem a stříbrným jazykem."
    },
    {
        obec: "Veselá",
        kod: 577642,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51252,
        latitude: 50.546714,
        longitude: 15.308967,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Vesel%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Bílo-zeleně šikmo dělený list. V bílém poli červená čtvrtina slunce bez tváře s pěti paprsky, vycházející z poloviny žerďového a první třetiny horního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené zelené trojvrší, z něhož mezi prostředním a levým vrchem vyrůstá zelený listnatý strom s černým kmenem. Z pravého rohu vyniká červené slunce s pěti paprsky. V trojvrší stříbrné vlnité břevno."
    },
    {
        obec: "Veselá",
        kod: 560235,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33701,
        latitude: 49.695183,
        longitude: 13.602933,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Vesel%C3%A1_%28Rokycany_District%29_CoA.png",
        coatOfArmsFlagDescription: "Modrý list. V žerďové části bílý tlapatý kříž, ve střední části bílá lilie nad žlutým mlýnským kolem a ve vlající části bílá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatým mlýnským kolem lilie mezi tlapatým křížem a hvězdou, vše stříbrné."
    },
    {
        obec: "Veselá",
        kod: 585921,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.237591,
        longitude: 17.775561,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/CoA_of_Vesel%C3%A1_%28Zl%C3%ADn_District%29.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým křížem s rameny širokými jednu jedenáctinu délky listu. V horním a dolním žerďovém poli po jedné osmicípé žluté hvězdě, ve střední a vlající části listu přes vlající rameno kříže bílá lilie kvetoucí do stran dvěma červenými růžemi se žlutými semeníky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý latinský heroldský kříž přeložený dole stříbrnou lilií kvetoucí do stran dvěma červenými růžemi se zlatými semeníky a provázený nahoře dvěma zlatými osmihrotými hvězdami."
    },
    {
        obec: "Veselé",
        kod: 562939,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40502,
        latitude: 50.784784,
        longitude: 14.37384,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Vesel%C3%A9_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list s žerďovým červeným pruhem širokým jednu třetinu délky listu a vodorovným zeleným pruhem na celém dolním okraji listu širokým jednu čtvrtinu šířky listu. V červeném poli žlutá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně polceném štítě nad zeleným vrchem vpravo zlatá hvězda, vlevo vztyčená, ven prohnutá černá medvědí noha s červenou zbrojí."
    },
    {
        obec: "Veselí",
        kod: 575968,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53501,
        latitude: 50.011243,
        longitude: 15.618926,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Veseli_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký dvě sedminy délky listu a devět vodorovných pruhů, střídavě zelených a bílých, přes které je uprostřed listu položen červený květ vlčího máku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě s modrou hlavou a čtyřmi stříbrnými kůly červený květ vlčího máku."
    },
    {
        obec: "Veselí nad Lužnicí",
        kod: 553271,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.186616,
        longitude: 14.699294,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Coat_of_arms_of_Vesel%C3%AD_nad_Lu%C5%BEnic%C3%AD.jpg",
        coatOfArmsFlagDescription: "Červeno - bíle kosmo dělený list. V červeném poli bílý lev se žlutou zbrojí, a bílými zuby, v bílém poli červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně kosmo děleném štítě, nahoře červená růže se zlatým semeníkem a zelenými kališními lístky, dole stříbrný lev se zlatou zbrojí a stříbrnými zuby."
    },
    {
        obec: "Veselí nad Moravou",
        kod: 586722,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69801,
        latitude: 48.952477,
        longitude: 17.381015,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Znakm%C4%9BstaVesel%C3%ADnadMoravou.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, střídavě červených a bílých 8:2:1:4:1:2:4. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V dolní části červeného štítu nízká kvádrovaná zeď, z ní vyrůstá uprostřed kvádrovaná věž s prázdnou branou a širokým cimbuřím a po stranách dvě menší věže s cimbuřím a jedním černým oknem, vše stříbrné."
    },
    {
        obec: "Veselíčko",
        kod: 549975,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39842,
        latitude: 49.388105,
        longitude: 14.35687,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Vesel%C3%AD%C4%8Dko_PI_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové a střední části žluté slunce bez tváře, v páté šestině délky listu svislý vlnitý bílý pruh. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným vlnitým břevnem zlaté slunce."
    },
    {
        obec: "Veselice",
        kod: 565725,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29404,
        latitude: 50.396148,
        longitude: 15.127224
    },
    {
        obec: "Veselíčko",
        kod: 520420,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75125,
        latitude: 49.532193,
        longitude: 17.509241,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Vesel%C3%AD%C4%8Dko_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, zelený a bílý, v poměru 1:2:1. V zeleném poli žlutý dvouocasý lev ve skoku s bílými zuby a červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná paroží, mezi nimi zlatý lev ve skoku s bílými zuby a s červenou zbrojí."
    },
    {
        obec: "Veselý Žďár",
        kod: 569682,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.639737,
        longitude: 15.526155,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/Vesel%C3%BD_%C5%BD%C4%8F%C3%A1r_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a plamenný červený v poměru 2:1. V bílém pruhu zelená lípa s černým kmenem, červený pruh má osm plamenů sahajících do poloviny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě dvě zelené lípy s černými kmeny vyrůstající ze sníženého červeného plamenného hrotu."
    },
    {
        obec: "Vestec",
        kod: 547565,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55205,
        latitude: 50.421946,
        longitude: 16.02139,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Vestec_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým zvonem, bílou osmicípou hvězdou a bílou koňskou hlavou s krkem se žlutou hřívou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatý zvon a stříbrná koňská hlava s krkem se zlatou hřívou, pod nimi stříbrná osmihrotá hvězda."
    },
    {
        obec: "Vestec",
        kod: 537942,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28933,
        latitude: 50.23837,
        longitude: 15.146101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Vestec_NB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký polovinu délky listu se žlutou věží-zvonicí a dva vodorovné pruhy, bílý a zelený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli zlatá věž-zvonice, levé pole stříbrno-zeleně dělené, nahoře černý havran s červenou zbrojí, dole stříbrná radlice."
    },
    {
        obec: "Vestec",
        kod: 513458,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25242,
        latitude: 49.9806,
        longitude: 14.504981,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Vestec_PZ_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří červeno-žlutě čtvrcený žerďový pruh, široký jednu třetinu délky listu, a dva vodorovné pruhy, červený a žlutý. V horním červeném žerďovém poli půl bílé orlice se žlutou zbrojí, přiléhající k hornímu žlutému poli s deseti zelenými střapci z arcibiskupského klobouku. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo vynikající půl stříbrné orlice se zlatou zbrojí, vlevo deset zelených střapců z arcibiskupského klobouku."
    },
    {
        obec: "Věstín",
        kod: 597031,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59265,
        latitude: 49.566977,
        longitude: 16.340016,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/V%C4%9Bst%C3%ADn.png",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo dělený list. V zeleném poli bílá, k vlajícímu okraji listu vzlétající holubice se žlutou zbrojí a olivovou ratolestí v zobáku. V bílém poli šikmý černý pruh vycházející z druhé třetiny dolního a vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně šikmo děleném štítě nahoře stříbrná, doleva vzlétající holubice se zlatou zbrojí a olivovou ratolestí v zobáku, dole černé šikmé břevno."
    },
    {
        obec: "Věšín",
        kod: 541508,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26243,
        latitude: 49.615355,
        longitude: 13.824322,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Vesin_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červený se třemi zkříženými bílými kovářskými hřeby, bílý a zelený, v poměru 1:1:4:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo tři stříbrné zkřížené kovářské hřeby, pod nimi kosmo položené stříbrné cvočkařské kladivo s hnědým topůrkem šikmo podložené stříbrnými kleštěmi, vlevo na zeleném návrší vykořeněný smrk přirozené barvy."
    },
    {
        obec: "Věteřov",
        kod: 586731,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69701,
        latitude: 49.028625,
        longitude: 17.056248,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/V%C4%9Bte%C5%99ov_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve dvou třetinách délky listu a sedm střídavě zelených a žlutých pruhů v poměru 4:3:3:3:3:3:4. V klínu osmihrotý kříž a šesticípá hvězda vedle sebe, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na zlatém trojvrší stříbrný klín s osmihrotým křížem nad hvězdou, obojí červené, z trojvrší vyrůstají do stran dva zlaté stonky, každý s jedním vinným hroznem a listem."
    },
    {
        obec: "Větrný Jeníkov",
        kod: 588121,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.475877,
        longitude: 15.478957,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/V%C4%9Btrn%C3%BD_Jen%C3%ADkov.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 13 : 3. Uprostřed listu bílá uzavřená kvádrovaná hradba ve tvaru položené routy, v jejímž předním hrotu je žlutě prolomená brána s černou vytaženou mříží a s okrouhlou věží o dvou patrech, v každém s černým obloukovým oknem, a s černou zvonovou střechou zakončenou žlutou makovicí. Po stranách brány v hradbě pod cimbuřím tři černé čtvercové střílny vedle sebe. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Větrušice",
        kod: 538990,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25067,
        latitude: 50.190903,
        longitude: 14.383516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Vetrusice_znak.gif",
        coatOfArmsFlagDescription: "Modro-červeně čtvrcený list. V horním žerďovém poli bílý bůh větu Boreas, v dolním žerďovém poli vodorovně žlutý klíč zuby k žerdi a k dolnímu okraji. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře stříbrný bůh větru Boreas. Dole položený zlatý klíč."
    },
    {
        obec: "Větřkovice",
        kod: 570036,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74743,
        latitude: 49.780606,
        longitude: 17.820558,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Coa_Czech_Town_V%C4%9Bt%C5%99kovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, zelený a bílý v poměru 1:2:1. V zeleném poli bílá lilie podložená zkříženými žlutými kosami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě zlaté zkřížené kosy přeložené stříbrnou lilií."
    },
    {
        obec: "Větřní",
        kod: 545830,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38211,
        latitude: 48.774351,
        longitude: 14.286258,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Vetrni_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm pruhů střídavě bílých a červených v poměru 8:1:1:1:1:1:1. V horním bílém poli červená růže o průměru dvě sedminy šířky listu se žlutým semeníkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad třemi červenými oblouky pod sebou červená růže se zlatým semeníkem."
    },
    {
        obec: "Vevčice",
        kod: 595063,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67153,
        latitude: 48.962305,
        longitude: 16.044533
    },
    {
        obec: "Veverská Bítýška",
        kod: 584100,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66471,
        latitude: 49.276013,
        longitude: 16.436969,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Veverska_Bityska_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě modrých a žlutých, v poměru 4 : 1 : 1 : 1 : 1. Z prvního žlutého pruhu vyniká k žerďovému okraji půl moravské orlice. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, do pravého modrého pole vyniká půl moravské orlice. Levé pole třikrát zlato - modře polcené."
    },
    {
        obec: "Veverské Knínice",
        kod: 584118,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66481,
        latitude: 49.236997,
        longitude: 16.401931,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Veverske_Kninice_znak.png",
        coatOfArmsFlagDescription: "Dva vodorovné pruhy: bílý a zelený. Uprostřed modrá válcová věž s bílým oknem a červenou kuželovitou střechou zakončenou žlutou makovicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku modrá válcová věž s jedním prázdným oknem a červenou kuželovitou střechou zakončenou zlatou makovicí."
    },
    {
        obec: "Věž",
        kod: 569691,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58256,
        latitude: 49.563955,
        longitude: 15.459156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/V%C4%9B%C5%BE-znak.jpg",
        coatOfArmsFlagDescription: "žlutých žerdích po stranách. Zámek se žlutými zavřenými vraty provázenými dvěma černými čtvrcenými obloukovými okny. Nad vraty vyniká úzká věž s obloukovým oknem pod kotoučem, obojí černé. Věž je zakončena červenou bání se žlutou makovicí s křížkem, provázenou po obou stranách dvojicí žlutých hvězd vedle sebe. Poměř šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrným vlnitým břevnem v patě štítu stříbrný zámek s červenou valbovou střechou se dvěma odvrácenými stříbrnými praporky - vlaštovčími ocasy na zlatých žerdích po stranách. Zámek se zlatými zavřenými vraty provázenými dvěma černými čtvrcenými obloukovými okny. Nad vraty vyniká úzká věž s obloukovým oknem pod kotoučem, obojí černé. Věž je zakončena červenou bání se zlatou makovicí s křížkem, provázenou po obou stranách dvojicí zlatých hvězd vedle sebe."
    },
    {
        obec: "Věžky",
        kod: 552755,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75119,
        latitude: 49.405119,
        longitude: 17.42328,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/V%C4%9B%C5%BEky_PR_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 4:1:1. V horním pruhu žluté slunce s tváří. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté slunce s tváří, pod ním tři překřížené stříbrné řemdihy."
    },
    {
        obec: "Věžky",
        kod: 589128,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76833,
        latitude: 49.28519,
        longitude: 17.281547,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/10/V%C4%9B%C5%BEky_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, žlutý a zelený v poměru 5:2:5. V zelených polích po jedné bílé věži s cimbuřím a dvěma zelenými okny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý řemdich provázený dvěma stříbrnými věžemi s cimbuřím a dvěma okny."
    },
    {
        obec: "Věžná",
        kod: 549061,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.414284,
        longitude: 14.988823,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Znak_obce_V%C4%9B%C5%BEn%C3%A1.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý s červeným svatojiřským středovým křížem a zelený. Šířka ramen kříže je dvanáctina délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo červený heroldský kříž, vlevo stříbrná věž s oknem a s červenou střechou."
    },
    {
        obec: "Věžná",
        kod: 597040,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.460826,
        longitude: 16.271321,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/V%C4%9B%C5%BEn%C3%A1_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený se zeleným žerďovým klínem sahajícím do středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červený štít se zelenou patou o třech hrotech. Na středním hrotu stojí stříbrná věž s cimbuřím a s dvěma prázdnými okny, provázená vpravo zlatým jablkem, vlevo zlatým kopřivovým listem. Pod věží stříbrné palečné kolo."
    },
    {
        obec: "Věžnice",
        kod: 569704,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58252,
        latitude: 49.514522,
        longitude: 15.695774,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Veznice_HB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, střídavě modrých a bílých, v poměru 4:1:1:1:1:1:4, přeložených vodorovným, červeno-žlutě kosmo děleným pruhem širokým dvě pětiny šířky listu. V něm dva odvrácené bílé vinařské nože se žlutými rukojeťmi. Nahoře přes tři prostřední pruhy žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na volném zlatém kvádrovaném mostě s jedním obloukem a dvěma půloblouky tři stříbrné věže, krajní s jedním černým oknem, s červenými stanovými střechami zakončenými černo-zlatě dělenými praporky. Přes střední věž položen korunovaný zlato-červeně kosmo dělený štítek se dvěma odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi. Pod mostním obloukem stříbrné palečné kolo."
    },
    {
        obec: "Věžnice",
        kod: 588130,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58827,
        latitude: 49.480083,
        longitude: 15.695408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b8/V%C4%9B%C5%BEnice_JI_Coa.jpg",
        coatOfArmsFlagDescription: "Z horního okraje listu vychází modrý klín s vrcholem na dolním okraji listu. Z horní poloviny žerďového a vlajícího okraje vycházejí bílé pruhy do druhé a třetí čtvrtiny dolního okraje listu. Dolní žerďový a vlající trojúhelník červený. V dolním vrcholu klínu zelený vrch, z něho vyniká volná kvádrovaná hradba, z níž vyniká věž, obojí bílé, věž má tři (2, 1) černá okna a červenou kuželovou střechu zakončenou žlutým křížkem. Z vrchu před hradbou vynikají kosmo kosa a šikmo sekera-širočina, obojí bílé a odvrácené, kosiště a topůrko hnědé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném vrchu volná kvádrovaná hradba, z níž vyniká věž, obojí stříbrné, věž má tři (2, 1) černá okna a červenou kuželovou střechu zakončenou zlatým křížkem. Z vrchu před hradbou vynikají kosmo kosa a šikmo sekera-širočina, obojí stříbrné a odvrácené, kosiště a topůrko přirozené barvy."
    },
    {
        obec: "Věžnička",
        kod: 588148,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.46043,
        longitude: 15.70555
    },
    {
        obec: "Věžovatá Pláně",
        kod: 536300,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.77629,
        longitude: 14.409159
    },
    {
        obec: "Vchynice",
        kod: 565865,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.510608,
        longitude: 14.020198,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Vchynice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Červený list; v horní části žerďové poloviny dvě žluté věže s cimbuřím vedle sebe, každá s černým gotickým oknem a prázdnou gotickou branou s černými otevřenými vraty na bílých závěsech. Pod věžemi vyrůstají z dolního okraje listu tři bílé vlčí zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad třemi stříbrnými vlčími zuby dvě zlaté věže, každá s cimbuřím, s černým gotickým oknem a prázdnou gotickou branou s černými otevřenými vraty na stříbrných závěsech."
    },
    {
        obec: "Víceměřice",
        kod: 590134,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79826,
        latitude: 49.344156,
        longitude: 17.171909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/V%C3%ADcem%C4%9B%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s ondřejským křížem tvořený červeným kosmým a bílým šikmým pruhem širokým jednu dvanáctinu délky listu, uprostřed se žlutým šestilistým květem a červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížená vztyčená krojidla, kosmé červené a šikmé stříbrné. Uprostřed zlatý šestilistý květ s červeným semeníkem a zelenými kališními lístky. Vše provázeno čtyřmi (1,2,1) zlatými svěšenými žaludy."
    },
    {
        obec: "Vícemil",
        kod: 561037,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.263954,
        longitude: 14.894383
    },
    {
        obec: "Vícenice",
        kod: 588342,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67602,
        latitude: 49.08913,
        longitude: 15.812238,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/V%C3%ADcenice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu sedící ohlížející se žlutá liška. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelené návrší se třemi listnatými stromy přirozené barvy, v něm sedící ohlížející se zlatá liška."
    },
    {
        obec: "Vícenice u Náměště nad Oslavou",
        kod: 511242,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.202721,
        longitude: 16.126096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/V%C3%ADcenice_u_N%C3%A1m%C4%9Bst%C4%9B_nad_Oslavou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V žerďové části červeného pruhu bílý štítek s červeným srdcem přeloženým zkříženými žlutými kříži. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-modře stříbrnou zúženou krokví dělený štít, vpravo nahoře stříbrný štítek s červeným srdcem přeloženým zkříženými zlatými klíčemi, vlevo stříbrný lev se zlatou zbrojí, dole stříbrný anděl se zlatou svatozáří, vztyčenou pravicí, držící v levici stříbrnou lžičku se zlatou hostií, provázený dvěma zlatými hvězdami."
    },
    {
        obec: "Vícov",
        kod: 590142,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79803,
        latitude: 49.488573,
        longitude: 16.959663,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/V%C3%ADcov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený s bílým ježkem a bílý v poměru 1:2:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ježek převýšený širokou prázdnou kvádrovanou branou přecházející v nízkou cimbuřím zakončenou věž se dvěma prázdnými okny, vše stříbrné."
    },
    {
        obec: "Vidče",
        kod: 545198,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75653,
        latitude: 49.441612,
        longitude: 18.094836,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Vid%C4%8De_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a bílý. V modrém pruhu otevřená kniha a procesní patriarší kříž, oboje žluté, v horní části červeného pruhu zkřížené sekery, kosmá tesařská širočina, pod nimi v dolní části kosmo radlice hrotem k hornímu okraji listu a ostřím k žerdi, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeném polceném štítě vpravo otevřená kniha a procesní patriarší kříž, obojí zlaté, vlevo zkřížené sekery, kosmá tesařská širočina, pod nimi kosmo vztyčená radlice, vše stříbrné."
    },
    {
        obec: "Vídeň",
        kod: 597058,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59401,
        latitude: 49.392227,
        longitude: 16.03173,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Viden_vysocina_znak.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým křížem, jehož ramena vycházejí z druhé pětiny žerďového a třetí sedminy horního okraje listu. Ve středu kříže červená šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "v červeném štítě zvýšený stříbrný heroldský kříž s červenou hvězdou uprostřed, provázený dole dvěma zlatými liliemi."
    },
    {
        obec: "Vidice",
        kod: 554421,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34601,
        latitude: 49.613863,
        longitude: 12.835622,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Vidice_DO_znak.jpg",
        coatOfArmsDescription: "Štít dělený sníženým modrým břevnem se třemi stříbrno-červeně polcenými růžemi. V horním stříbrném poli kráčející korunovaný červený lev se zlatou zbrojí. Dolní pole stříbrno-modře cimbuřově dělené."
    },
    {
        obec: "Vidice",
        kod: 534536,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28401,
        latitude: 49.91671,
        longitude: 15.165597,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Vidice_%28Kutn%C3%A1_Hora_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý vlnitý s pěti vrcholy a čtyřmi prohlubněmi, a modrý zvlněný, v poměru 6 : 1 : 4. Ve střední části listu žlutá berla závitem k žerďovému okraji, v červeném pruhu provázená odvrácenými žlutými lipovými dvojlisty a v modrém pruhu zakončená polovinou bílé přepásané lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně stříbrným vlnitým břevnem děleném štítě zlatá berla dole zakončená polovinou přepásané stříbrné lilie, s hlavicí provázenou odvrácenými zlatými lipovými dvojlisty."
    },
    {
        obec: "Vidim",
        kod: 531936,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.467953,
        longitude: 14.525934
    },
    {
        obec: "Vidlatá Seč",
        kod: 578941,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57001,
        latitude: 49.836139,
        longitude: 16.205311,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Vidlat%C3%A1_se%C4%8D_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: žlutý, zelený a žlutý v poměru 1:4:1. V zeleném poli bílé podávky, mezi jejich dvěma hroty žlutý klas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné vidle - podávky uprostřed se zlatým klasem, provázené dvěma přivrácenými zlatými kosami, hroty dolů."
    },
    {
        obec: "Vidnava",
        kod: 541303,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79055,
        latitude: 50.372435,
        longitude: 17.18636,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Znak_m%C4%9Bsta_Vidnava.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. V bílém pruhu u žerďového okraje zelená vrba s hnědým uťatým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku vrba přirozené barvy."
    },
    {
        obec: "Vidochov",
        kod: 573736,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50901,
        latitude: 50.512639,
        longitude: 15.565907,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Vidochov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, modrý, vlnitý žlutý, vlnitý modrý a zvlněný bílý, všechny s jedním vrcholem a jednou prohlubní, v poměru 11 : 2 : 1 : 10. Středové pruhy provázejí nahoře v žerďovém pruhu bílý vykořeněný listnatý strom a dole ve vlajícím pruhu kosmo nakloněný modrý pětilistý květ. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro - stříbrně polcený štít se zlato - modře vlevo vlnitě polceným vlnitým kůlem. Vpravo nahoře stříbrný vykořeněný listnatý strom, vlevo dole kosmo nakloněný modrý pětilistý květ."
    },
    {
        obec: "Vidonín",
        kod: 597066,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59457,
        latitude: 49.379842,
        longitude: 16.222546,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Vidon%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 6 : 1 : 3. V žerďové polovině červeného pruhu radlice, ostřím k žerďovému a hrotem k dolnímu okraji listu, a šesticípá hvězda, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červené štítě nad stříbrno-modře vlnitě dělenou vlnitou patou radlice a hvězda, obojí stříbrné."
    },
    {
        obec: "Vidov",
        kod: 535737,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37007,
        latitude: 48.926848,
        longitude: 14.494749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Vidov_CZ_CoA.svg",
        coatOfArmsFlagDescription: "Bílo-modře šikmo dělený list. V žerďovém poli červená růže se žlutým semeníkem a zelenými kališními lístky, ve vlajícím poli tři (2,1) přivrácené žluté hroty šípů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnkovitě děleném štítě nahoře červená růže se zlatým semeníkem a zelenými kališními lístky, dole tři (2,1) přivrácené zlaté hroty šípů."
    },
    {
        obec: "Vigantice",
        kod: 545210,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75661,
        latitude: 49.443885,
        longitude: 18.184136,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Coat_of_Arm_Vigantice.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý se žlutým kloboukem, bílý a červený, v poměru 1 : 1 : 4 : 1 : 1. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zvýšené modré trojvrší, z něhož vpravo vyniká červené slunce. V trojvrší vyrůstající stříbrně oděný rolník se zlatým kloboukem a vestou, držící v pravici vidle-podávky a v levici hrábě, obojí zlaté."
    },
    {
        obec: "Víchová nad Jizerou",
        kod: 577651,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51241,
        latitude: 50.630181,
        longitude: 15.487779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/V%C3%ADchov%C3%A1_nad_Jizerou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed žlutý snop. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý snop."
    },
    {
        obec: "Vikantice",
        kod: 553191,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78825,
        latitude: 50.13639,
        longitude: 16.99307
    },
    {
        obec: "Vikýřovice",
        kod: 569445,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78813,
        latitude: 49.976988,
        longitude: 17.013562,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Vikyrovice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený v poměru 2:1. Do červeného pruhu vyrůstá v žerďové polovině černý dvouocasý lev se žlutou korunou a zbrojí. Zelený pruh je bíle mřížovaný třemi vodorovnými a devíti svislými pruhy, vodorovné pruhy mřížování přiléhají k červenému pruhu a k dolnímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě nahoře vyrůstající černý korunovaný dvouocasý lev se zlatou zbrojí, dole stříbrná mříž, jejíž horní tyč tvoří dělící linii."
    },
    {
        obec: "Vílanec",
        kod: 588156,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58835,
        latitude: 49.334609,
        longitude: 15.576862,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/V%C3%ADlanec_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 7 : 2 : 3. V modrém pruhu bílý květ tolije bahenní se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně sníženým stříbrným vlnitým břevnem děleném štítě nahoře stříbrný květ tolije bahenní se zlatým středem."
    },
    {
        obec: "Vilantice",
        kod: 574597,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.365933,
        longitude: 15.788877,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Vilantice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a zelený, v poměru 2:5:5. V modrém pruhu dva vztyčené bílé lipové listy pod sebou, v bílém pruhu hnědá roubená patrová zvonice s jehlancovou střechou, v zeleném pruhu tři volné bílé krokve pod sebou. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít s modrou patou. Vpravo roubená patrová zvonice s jehlancovou střechou, vše přirozené barvy, vlevo tři volné stříbrné krokve pod sebou. V patě vztyčený stříbrný lipový dvoulist na stonku."
    },
    {
        obec: "Vilémov",
        kod: 569712,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58283,
        latitude: 49.815761,
        longitude: 15.53594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Vil%C3%A9mov_%28Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_CoA_CZ.gif",
        coatOfArmsFlagDescription: "Červeno-bíle šikmo dělený list, v červeném poli kosmo bílý gotický klíč zuby nahoru a k žerďovému okraji, podložený šikmo bílým mečem se žlutým jílcem hrotem k hornímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě kosmo vztyčený stříbrný gotický klíč šikmo podložený vztyčeným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Vilémov",
        kod: 568961,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78322,
        latitude: 49.635568,
        longitude: 16.99541,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Vilemov_%28OL%29_znak.jpeg",
        coatOfArmsFlagDescription: "Zelený list s bílým pelikánem rozdírajícím si hruď a krmícím svou krví tři bílá mláďata ve žlutém hnízdě, všichni mají žluté zobáky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pelikán ve zlatém hnízdě krmící krví z vlastní hrudi tři mláďata, všichni ptáci jsou stříbrní se zlatými zobáky."
    },
    {
        obec: "Vilémov",
        kod: 562947,
        okres: "Děčín",
        kod_okres: "CZ0421",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40780,
        latitude: 50.990647,
        longitude: 14.335423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Znak_Vilemov_u_Sluknova.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh s oblakovým okrajem o pěti vrcholech široký jednu čtvrtinu délky listu a tři vodorovné pruhy: bílý, červený a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod modrou, oblakovým řezem oddělenou hlavou se zlatou korunou o pěti viditelných listech červený tkací mřížkový stav tvořený prkénkem s pěti mezerami a čtyřmi otvory uprostřed."
    },
    {
        obec: "Vilémov",
        kod: 563439,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43154,
        latitude: 50.301078,
        longitude: 13.312156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Vil%C3%A9movznak.png",
        coatOfArmsFlagDescription: "List tvoří osm vodorovných pruhů, sedm střídavě bílých a červených a osmý modrý, v poměru 1 : 1 : 1 : 1 : 1 : 1 : 1 : 7. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná kvádrovaná hradba s prázdnou branou s vytaženou zlatou mříží. Z hradby vynikají dvě stříbrné věže, každá se dvěma římsami, z nichž spodní dělí věž na horní nižší a dolní vyšší patro, v nich pět (3, 2) černých obloukových oken, dolní vyšší. Na horní římse kopule zakončená makovicí a červeným do stran vlajícím praporkem s vlaštovčím ocasem. Mezi věžemi stříbrný štítek se třemi červenými břevny."
    },
    {
        obec: "Vilémovice",
        kod: 582654,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67906,
        latitude: 49.363886,
        longitude: 16.746834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Vil%C3%A9movice_okr_Blansko_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a modrý. V žerďovém pruhu dvě osmicípé žluté hvězdy pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít s modrou vlnitou patou. Vpravo dvě zlaté osmihroté hvězdy nad sebou, vlevo vztyčená volná ruka přirozené barvy držící se kmene zprava vzhůru vyrůstajícího zeleného smrku."
    },
    {
        obec: "Vilémovice",
        kod: 569721,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58401,
        latitude: 49.686513,
        longitude: 15.321935,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Vil%C3%A9movice_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý se vztyčenou větévkou tisu, žlutý s tisem převýšeným modrým zvonem, a modrý se žlutým gotickým klíčem zuby nahoru a k vlajícímu okraji, v poměru 3 : 4 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý kůl, v něm tis přirozené barvy převýšený modrým zvonem, vpravo vztyčená větévka tisu přirozené barvy, vlevo vztyčený zlatý gotický klíč zuby doleva."
    },
    {
        obec: "Vilice",
        kod: 553280,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.564574,
        longitude: 14.864415
    },
    {
        obec: "Vinary",
        kod: 571083,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50353,
        latitude: 50.289608,
        longitude: 15.428963
    },
    {
        obec: "Vimperk",
        kod: 550647,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38501,
        latitude: 49.052562,
        longitude: 13.774586,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/90/CoA_Vimperk.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Z první a sedmé sedminy žerďového okraje vychází červená krokev opačných barev s vrcholem na vlajícím okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vinary",
        kod: 572489,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53863,
        latitude: 49.959207,
        longitude: 16.060381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Vinary_UO_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleným šikmým pruhem širokým jednu pětadvacetinu délky listu dělený list na modré žerďové a žluté vlající pole. V modrém poli bílé trojvrší s kosmým mlátkem šikmo přeloženým želízkem, obojí černé. Do žlutého pole vyrůstá ze zeleného pruhu modrý vinný hrozen na zeleném stonku s jedním listem nahoře a úponkem dole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě šikmým zeleným zúženým břevnem dělený štít, nahoře stříbrné trojvrší s kosmým mlátkem, šikmo přeloženým želízkem, obojí černé, dole vyrůstá z břevna modrý vinný hrozen na zeleném stonku s listem nahoře a úponkem dole."
    },
    {
        obec: "Vinaře",
        kod: 534544,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.909198,
        longitude: 15.501395,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Vinare_CZ_CoA.jpg",
        coatOfArmsDescription: "V červeném štítě pod zlatým zubrem zlatý vinný hrozen se třemi listy, provázený dvěma přivrácenými stříbrnými vinařskými noži se zlatými rukojeťmi."
    },
    {
        obec: "Vinařice",
        kod: 534234,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26701,
        latitude: 49.892649,
        longitude: 14.098173,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Vina%C5%99ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený v poměru 1 : 3. V zeleném pruhu bílý dolní klín, v něm vyniká z druhé třetiny dolního okraje listu červený klín, oba s vrcholem na dolním okraji bílého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě s vydutou stříbrnou hlavou snížený stříbrný hrot. V hrotu červená nádoba s vlnitým ornamentem na hrdle. Hrot provázen nahoře vpravo vinným hroznem, vlevo lipovým trojlistem, obojí stříbrné."
    },
    {
        obec: "Vinařice",
        kod: 533050,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27307,
        latitude: 50.175951,
        longitude: 14.091171,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Vinarice_KL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, žlutý, černý, žlutý, modro-červeně dělený, žlutý, černý a žlutý, v poměru 1:1:1:9:1:1:1. Uprostřed v děleném pruhu dva přivrácené bílé vinné hrozny na stoncích, pod hrozny bílá hornická kladívka se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlaté návrší s černým beraním rohem. Z návrší vyrůstají dva přivrácené stříbrné vinné hrozny na stoncích, pod hrozny stříbrná hornická kladívka se zlatými topůrky."
    },
    {
        obec: "Vinařice",
        kod: 565628,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29441,
        latitude: 50.373911,
        longitude: 14.952383,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/Vina%C5%99ice_MB_znak.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle šikmo dělený list. V horním rohu žlutý vinný hrozen s listem, v bílém poli šikmo červený kříž s rameny širokými desetinu délky listu, kosmé rameno vychází z dolního cípu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně šikmo děleném štítě nahoře zlatý vinný hrozen s listem, dole šikmo červený heroldský kříž."
    },
    {
        obec: "Vinařice",
        kod: 566918,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43915,
        latitude: 50.265764,
        longitude: 13.822865,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Vina%C5%99ice_znak.jpg",
        coatOfArmsFlagDescription: "V žerďové části tři vodorovné pruhy: červený, žlutý a červený, ve vlající části bílý, zelený a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, v pravém poli stříbrné břevno, nad ním zlatý smrk a pod ním zlatý vinný hrozen, v levém poli zelený chmelový stonek se třemi šišticemi a dvěma listy."
    },
    {
        obec: "Vinec",
        kod: 570842,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29301,
        latitude: 50.394714,
        longitude: 14.869901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Vinec_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Z druhé a třetí čtvrtiny dolního okraje listu vyniká bílá kvádrovaná kostelní věž s roubenou stanovou střechou zakončenou křížkem, vše žluté. Střecha provázena dvěma (1, 1) svěšenými bílými vinnými hrozny se žlutými stonky se dvěma odkloněnými úponky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vyniká stříbrná kvádrovaná kostelní věž s roubenou stanovou střechou zakončenou křížkem, vše zlaté. Střecha provázena dvěma (1, 1) svěšenými stříbrnými vinnými hrozny se zlatými stonky se dvěma odkloněnými úponky."
    },
    {
        obec: "Viničné Šumice",
        kod: 584126,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66406,
        latitude: 49.213678,
        longitude: 16.825512,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Vini%C4%8Dn%C3%A9_%C5%A0umice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - bílý a zelený. V horním rohu na bílém poli modrý vinný hrozen na zeleném stonku s jedním listem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-stříbrně polcený štít, v pravé polovině vedle sebe vinařský nůž a krojidlo, obé stříbrné, v levé polovině modrý vinný hrozen na zeleném stonku s jedním listem."
    },
    {
        obec: "Vintířov",
        kod: 560685,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35735,
        latitude: 50.233928,
        longitude: 12.717598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Vintirov_SO_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří vodorovný zelený pruh, na dolním okraji široký 1/3 šířky listu. Nad ním dvě stejně veliká pole - černé žerďové a bílé vlající. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-stříbrně polceném štítě se zelenou patou hornická kladívka opačných barev."
    },
    {
        obec: "Vír",
        kod: 597074,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59266,
        latitude: 49.557484,
        longitude: 16.32369,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/V%C3%ADr_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy: červený, bílý a modrý v poměru 3:1:3. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně děleném štítě nahoře kapr, dole doleva položená radlice, obojí stříbrné."
    },
    {
        obec: "Víska",
        kod: 573582,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58301,
        latitude: 49.770412,
        longitude: 15.652667,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/V%C3%ADska_%28okres_Havl%C3%AD%C4%8Dk%C5%AFv_Brod%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý pilovitý a modrý, v poměru 1 : 3 : 1. Bílý pruh má sedm zubů dotýkajících se hroty horního okraje listu. Uprostřed kráčející černý havran držící v zobáku svěšený zelený lipový list s květenstvím. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrou pilovitou hlavou se dvěma celými klíny a modrou vlnitou patou kráčející černý havran držící v zobáku svěšený zelený lipový list s květenstvím."
    },
    {
        obec: "Vincencov",
        kod: 544710,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79804,
        latitude: 49.405804,
        longitude: 17.062571,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Vincencov_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílým kosmým vlnitým pruhem širokým jednu čtvrtinu šířky listu na žerďové červené pole s bílým mlýnským kamenem a vlající černé pole se žlutou šesticípou hvězdou. Vlnitý pruh má tři vrcholy a dvě prohlubně. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-červeně kosmým stříbrným vlnitým břevnem děleném štítě nahoře zlatá hvězda, dole stříbrný mlýnský kámen."
    },
    {
        obec: "Víska u Jevíčka",
        kod: 578959,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.652641,
        longitude: 16.707835,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/V%C3%ADska_u_Jev%C3%AD%C4%8Dka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a černý, v poměru 4 : 1 : 1. V modrém pruhu tři růže pod sebou a radlice hrotem k dolnímu a ostřím k vlajícímu okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno - černě dělenou patou vpravo tři růže pod sebou, vlevo doleva obrácená radlice, vše zlaté."
    },
    {
        obec: "Vísky",
        kod: 582662,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67933,
        latitude: 49.538535,
        longitude: 16.625711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Visky_BK_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutými cípovými rovnoramennými trojúhelníky s odvěsnou rovnou jedné třetině šířky listu. V žerďové polovině bílé obrněné rameno se žlutým plamenným mečem a s bílým jílcem. Ve vlající polovině bílý hřeben, jehož prostřední zub dosahuje středu vlajícího okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný hřeben, provázený nahoře dvěma zlatými zvony a dole stříbrným obrněným ramenem držícím zlatý plamenný meč se stříbrným jílcem."
    },
    {
        obec: "Vísky",
        kod: 579289,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33843,
        latitude: 49.651304,
        longitude: 13.682749,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Visky_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký jednu třetinu délky listu a bílé pole s černou sviní hlavou se žlutou zbrojí a červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad třemi zelenými vrchy černá sviní hlava se zlatou zbrojí a červeným jazykem."
    },
    {
        obec: "Višňová",
        kod: 507652,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.219107,
        longitude: 14.841334
    },
    {
        obec: "Višňová",
        kod: 564494,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46401,
        latitude: 50.966732,
        longitude: 15.02506,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Visnova_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým vlnitým pruhem se třemi vrcholy a dvěma prohlubněmi vycházejícím ze čtvrté pětiny žerďového a vlajícího okraje listu. Nad první prohlubní bílý višňový květ se žlutým semeníkem a pylovými tyčinkami široký jednu čtvrtinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad sníženým stříbrným vlnitým břevnem stříbrný višňový květ se zlatým semeníkem a pylovými tyčinkami."
    },
    {
        obec: "Višňová",
        kod: 541516,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26261,
        latitude: 49.705897,
        longitude: 14.145576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Vi%C5%A1%C5%88ov%C3%A1_%28okres_P%C5%99%C3%ADbram%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a zelený, v poměru 15 : 1 : 4. V červeném pruhu žluté nožové kolo, v horní části vylomené, přeložené bílým višňovým květem se žlutým středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít se zelenou, zúženým stříbrným vlnitým břevnem oddělenou patou. Nahoře zlaté nožové kolo, v horní části vylomené, přeložené stříbrným višňovým květem se zlatým středem."
    },
    {
        obec: "Višňové",
        kod: 595071,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67138,
        latitude: 48.982515,
        longitude: 16.150357,
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký polovinu délky listu s vykračujícím bílým kohoutem se žlutou zbrojí a červeným hřebínkem, a tři vodorovné pruhy, modrý, bílý a modrý. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vítanov",
        kod: 572497,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.749189,
        longitude: 15.88183,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/V%C3%ADtanov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu čtvrtinu délky listu a modré pole. V horní části žlutého pruhu modrý volný řecký kříž. V modrém poli polovina bílého koně se žlutou zbrojí a hřívou, červeným uzděním, šikmo podložená žlutou berlou s bílým sudariem se žlutým třepením; mezi křížem a hlavou koně žluté jablko. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádovou hradbou s modrým řeckým křížem polovina stříbrného koně se zlatou zbrojí a hřívou, červeným uzděním, šikmo podložená zlatou berlou závitem doleva se stříbrným sudariem a zlatým třepením. Vpravo nahoře zlaté jablko."
    },
    {
        obec: "Vitčice",
        kod: 590151,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79827,
        latitude: 49.311939,
        longitude: 17.240961,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Vit%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed polovina vztyčeného žlutého medvěda s červeným jazykem držícího v pravé tlapě bílou zavinutou střelu v červeném pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě polovina vztyčeného zlatého medvěda s červeným jazykem, držícího v pravé tlapě stříbrnou zavinutou střelu."
    },
    {
        obec: "Vítějeves",
        kod: 578967,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56906,
        latitude: 49.615824,
        longitude: 16.465469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/35/V%C3%ADt%C4%9Bjeves_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený se žlutou korunou, bílý a červený, v poměru 3:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá koruna nad dolní polovinou zlomeného stříbrného kola sv. Kateřiny."
    },
    {
        obec: "Vitějovice",
        kod: 550655,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38427,
        latitude: 49.046493,
        longitude: 14.073468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Vit%C4%9Bjovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zvlněný žlutý pruh se dvěma vrcholy a třemi prohlubněmi, široký šestinu délky listu a zelené pole. V poli hrad se dvěma věžemi, každá s černým oknem a s cimbuřím, spojenými hradbou s prázdnou gotickou bránou, vše bílé. Hrad podložen žlutým latinským křížem s perlou na křížení a s dolním ramenem zakončeným hrotem střely, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zlatou vlnitou zvýšenou hlavou hrad se dvěma věžemi, každá s černým oknem a s cimbuřím, spojenými hradbou, prázdnou gotickou bránou, vše stříbrné. Hrad podložen zlatým latinským křížem s perlou na křížení a s dolním ramenem zakončeným hrotem střely, obojí stříbrné."
    },
    {
        obec: "Vítězná",
        kod: 579815,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54462,
        latitude: 50.488959,
        longitude: 15.802302,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/V%C3%ADt%C4%9Bzn%C3%A1_coat_of_arms.svg",
        coatOfArmsFlagDescription: "Bílý list se zeleným vlnkovaným pruhem na dolním okraji, širokým jednu pětinu šířky listu. Pruh má pět vrcholů, z prostředního vyššího vyrůstá na zelených stoncích s listy pět žlutých květů upolínu evropského. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze středu zeleného pětivrší na stoncích s listy pět květů upolínu evropského přirozené barvy."
    },
    {
        obec: "Vitice",
        kod: 533866,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28106,
        latitude: 50.031106,
        longitude: 14.914611
    },
    {
        obec: "Vitín",
        kod: 535893,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37363,
        latitude: 49.089591,
        longitude: 14.545974,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Coat_of_arms_of_Vit%C3%ADn.svg",
        coatOfArmsDescription: "V zeleném štítě rozšířený stříbrný kůl s pěti (3, 2) modrými hvězdami nad modrou podkovou obklopující červenou růži se zlatým semeníkem."
    },
    {
        obec: "Vitiněves",
        kod: 573752,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.393404,
        longitude: 15.378316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Vitin%C4%9Bves_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, žlutý, modrý, bílý a modrý, v poměru 2:1:2:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý kvádrovaný obloukový most se dvěma pilíři vyrůstající ze sníženého vlnitého stříbrného břevna, v patě zlatá ryba."
    },
    {
        obec: "Vítkov",
        kod: 511021,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74901,
        latitude: 49.774549,
        longitude: 17.749517,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/56/Coat_of_arms_of_V%C3%ADtkov.svg",
        coatOfArmsFlagDescription: "List tvoří modré žerďové pole s černou loveckou trubkou náústkem nahoru, se žlutým kováním a červenou šňůrou a bílé vlající pole se dvěma červenými krokvemi. Horní krokev s hrotem na horním okraji listu, obě krokve vycházejí z modrého pole a dolní se dotýká dolního okraje listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Vítkovice",
        kod: 577669,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51238,
        latitude: 50.679115,
        longitude: 15.531308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/V%C3%ADtkovice_%28Semily_District%29_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a bílý, v poměru 1 : 4 : 1. V modrém pruhu bílý kozel se žlutými rohy ve skoku, přidržující předníma nohama kosmo žlutou sklářskou píšťalu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zlaté skále stříbrný kozel se zlatými rohy ve skoku, přidržující předníma nohama kosmo zlatou sklářskou píšťalu."
    },
    {
        obec: "Vítonice",
        kod: 595080,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67161,
        latitude: 48.919467,
        longitude: 16.198985,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Vitonice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým korunovaným lvem ve skoku s červenou zbrojí stojícím na kosmo položené bílé ostrvi a držícím v pravé tlapě bílý zvonek. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na kosmé stříbrné ostrvi zlatý korunovaný dvouocasý lev s červenou zbrojí držící v pravé přední tlapě stříbrný zvonek."
    },
    {
        obec: "Vítonice",
        kod: 589136,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76861,
        latitude: 49.447233,
        longitude: 17.693071,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/V%C3%ADtonice_%28okres_Krom%C4%9B%C5%99%C3%AD%C5%BE%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový klín s vrcholem ve středu listu a sedm vodorovných pruhů střídavě červených a žlutých, v poměru 2:2:3:2:3:2:2. V bílém klínu modrý květ lnu se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě snížený stříbrný hrot s modrým květem lnu se zlatým středem. Na hrotu stříbrný pták se zlatou zbrojí, provázený vpravo obilným klasem a vlevo patriarším křížem, obojí zlaté."
    },
    {
        obec: "Vizovice",
        kod: 585939,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.223073,
        longitude: 17.854921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Vizovice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Černo-stříbrně dělený štít. V horní polovině dvě stříbrná břevna."
    },
    {
        obec: "Vižina",
        kod: 534048,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26724,
        latitude: 49.857297,
        longitude: 14.10473,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Vi%C5%BEina_-_znak.jpg",
        coatOfArmsDescription: "Štít dělen smrkovým řezem stříbrno-zeleně. Nahoře černá trubka uprostřed omotaná zlatou šňůrou, jejíž konce se zlatými střapci zasahují do dolního pole, provázená po stranách červenou hvězdou. Dole dva přivrácené, ostřím zkřížené, stříbrné srpy se zlatými rukojeťmi."
    },
    {
        obec: "Vlačice",
        kod: 534552,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.943143,
        longitude: 15.437595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Vla%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý, v poměru 1 : 2. Přes ně dva oboustranně vlnkovité pruhy, vycházející z dvanácté a čtrnácté šestnáctiny žerďového a vlajícího okraje listu, v zeleném pruhu bílé a ve žlutém zelené. Nad nimi v zeleném pruhu postavené žluté polní brány, ve střední části listu čáp s červeným zobákem a nohama vyrůstajícíma z horního vlnkovitého pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-zlatě polceném štítě nad dvěma stříbrno-zeleně polcenými sníženými zúženými vlnkovitými břevny vpravo postavené zlaté polní brány, vlevo čáp přirozené barvy s nohama vyrůstajícíma z horního břevna."
    },
    {
        obec: "Vladislav",
        kod: 591904,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67501,
        latitude: 49.21027,
        longitude: 15.988403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Vladislav_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1:4:1. Uprostřed modrého pruhu žlutá postavená vrš. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá postavená vrš provázená dvěma postavenými odvrácenými stříbrnými limpami."
    },
    {
        obec: "Vlachova Lhota",
        kod: 586994,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76601,
        latitude: 49.149968,
        longitude: 17.953557,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Vlachova_Lhota_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený v poměru 1:1:2:1:1. Z modrého pruhu uprostřed vyrůstají protilehlá trojúhelníková pole se základnou rovnou jedné pětině délky listu, dotýkající se vrcholy červených pruhů. Uprostřed modrého pruhu žlutá včela mezi dvěma položenými bílými vlčími udicemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně sníženě dělený štít, nahoře zlatý včelí úl provázený na každé straně dvěma zlatými včelami a mezi nimi položenou stříbrnou vlčí udici. Dole dva červené šestilisté květy se zlatými středy vyrůstající na zelených stoncích se třemi zelenými listy."
    },
    {
        obec: "Vlachovice",
        kod: 587869,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.597135,
        longitude: 16.041266,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Vlachovice_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy modrý a zelený v poměru 17:3. Do modrého pruhu vyrůstá bílá lípa se žlutým kmenem, pod kterou po obou stranách vyrůstá po osmi žlutých obilných klasech. Nad lípou do oblouku pět žlutých hvězd. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku stříbrná lípa se zlatým kmenem, provázená nahoře do oblouku pěti zlatými pětihrotými hvězdami, dole osmi vyrůstajícími zlatými obilnými klasy po obou stranách."
    },
    {
        obec: "Vlachovice",
        kod: 585955,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76324,
        latitude: 49.123872,
        longitude: 17.940124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/CoA_Vlachovice.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý s bílým losím parohem a modrý, v poměru 5:3:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný losí paroh."
    },
    {
        obec: "Vlachovo Březí",
        kod: 550663,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38422,
        latitude: 49.081457,
        longitude: 13.958519,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Vlachovo_Brezi_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s šedým kozlem ve skoku s bílou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vlastec",
        kod: 598844,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.365671,
        longitude: 14.211797,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Vlastec_%28P%C3%ADsek_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, v poměru 4 : 1 : 1 : 10, bílý, vlnitý modrý, vlnitý bílý a zvlněný červený, každý se šesti vrcholy a pěti prohlubněmi. V horním pruhu vedle sebe tři červené růže se žlutými semeníky a zelenými kališními lístky. V červeném pruhu bílá labuť se žlutou zbrojí provázená nad křídlem třemi žlutými obilnými klasy, krajními odkloněnými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod modro-stříbrně vlnitě děleným vlnitým břevnem oddělenou stříbrnou hlavou se třemi červenými růžemi se zlatými semeníky a zelenými kališními lístky stříbrná labuť se zlatou zbrojí, provázená vlevo nahoře třemi zlatými obilnými klasy, krajními odkloněnými."
    },
    {
        obec: "Vlastějovice",
        kod: 534561,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28523,
        latitude: 49.731406,
        longitude: 15.174893
    },
    {
        obec: "Vlastiboř",
        kod: 553298,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.258041,
        longitude: 14.63683
    },
    {
        obec: "Vlastiboř",
        kod: 563846,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.664974,
        longitude: 15.311826
    },
    {
        obec: "Vlasatice",
        kod: 585025,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69130,
        latitude: 48.933846,
        longitude: 16.484529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Vlasatice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, uprostřed bílá věž s červenou valbovou střechou se dvěma žlutými makovicemi a dvěma černými okny pod sebou, oddělenými římsou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Vlastibořice",
        kod: 564516,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46344,
        latitude: 50.618816,
        longitude: 15.052112,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Vlastibo%C5%99ice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a černý. Uprostřed listu roubená patrová zvonice s prázdnými dveřmi a dvěma okny, s jehlancovou střechou zakončenou makovicí, vše opačných barev. V horním rohu listu zkřížené jívové ratolesti, v horním cípu bílé palečné kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-černě polceném štítě na zeleném trávníku roubená patrová zvonice s prázdnými dveřmi a dvěma okny, s jehlancovou střechou zakončenou makovicí, vše opačných barev. Nahoře vpravo zkřížené jívové ratolesti přirozené barvy, vlevo stříbrné palečné kolo."
    },
    {
        obec: "Vlčetínec",
        kod: 598640,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.264592,
        longitude: 15.054625
    },
    {
        obec: "Vlčeves",
        kod: 563447,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.357129,
        longitude: 14.900608,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Vl%C4%8Deves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zvlněný bílý, v poměru 7 : 3. V modrém pruhu kráčející bílý vlk s červenou zbrojí, v bílém červená lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě kráčející stříbrný vlk s červenou zbrojí nad stříbrnou vlnitou patou s červenou lilií."
    },
    {
        obec: "Vlčí",
        kod: 566691,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.511993,
        longitude: 13.360356
    },
    {
        obec: "Vlčí Habřina",
        kod: 575984,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.085931,
        longitude: 15.596053,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Vl%C4%8D%C3%AD_Hab%C5%99ina_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, modrý a žlutý, v poměru 4:7:4. Ve žlutých pruzích po jednom zeleném vykořeněném habru s černým kmenem, v modrém pruhu žlutá utržená vlčí hlava s červeným jazykem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý klín, v něm utržená zlatá vlčí hlava s červeným jazykem, po stranách dva zelené vykořeněné habry s černými kmeny."
    },
    {
        obec: "Vlčice",
        kod: 541346,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79065,
        latitude: 50.345377,
        longitude: 17.046051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Vl%C4%8Dice_%28okres_Jesen%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, bílý, žlutý, zelený, žlutý a bílý v poměru 1:1:6:1:1. V zeleném pruhu bílý vyskakující vlk se žlutou zbrojí a červeným jazykem držící tři žluté obilné klasy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný vyskakující vlk se zlatou zbrojí a červeným jazykem držící tři zlaté obilné klasy, provázené vpravo a dole dvěma stříbrnými květy."
    },
    {
        obec: "Vlčkov",
        kod: 581127,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56002,
        latitude: 49.907677,
        longitude: 16.350071
    },
    {
        obec: "Vlčkovice v Podkrkonoší",
        kod: 579831,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.411116,
        longitude: 15.918264,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/45/Vlckovice_znak.jpeg",
        coatOfArmsFlagDescription: "Bílý list se zelenou lípou s hnědým kmenem přidržovanou ze stran dvěma černými vlky s bílou zbrojí a červenými jazyky, vše vyrůstající ze zeleného návrší na dolním okraji listu. V návrší bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě lípa přirozené barvy přidržovaná ze stran dvěma černými vlky se stříbrnou zbrojí a červenými jazyky, vše vyrůstající ze zeleného návrší se stříbrnou lilií."
    },
    {
        obec: "Vlčková",
        kod: 585963,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76319,
        latitude: 49.31062,
        longitude: 17.763428,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f1/CoA_Vl%C4%8Dkov%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, černý, žlutý, modrý, žlutý a černý, v poměru 4:7:11:7:4. V modrém pruhu tři žluté osmicípé hvězdy vedle sebe. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno s černým běžícím vlkem držícím v zubech stříbrného beránka, provázené nahoře dvěma a dole jednou zlatou osmihrotou hvězdou."
    },
    {
        obec: "Vlčnov",
        kod: 592820,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68761,
        latitude: 49.009996,
        longitude: 17.581938,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Znak_Vl%C4%8Dnova.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý. V červeném pruhu bílý vinařský nůž - kosíř hrotem nahoru a ostřím k žerďovému okraji. Ve žlutých pruzích po jednom modrém hroznu na zeleném stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě vpravo stříbrný vinařský nůž - kosíř se zlatou rukojetí, vlevo černá tyč ovinutá zeleným stonkem vinné révy se dvěma listy a dvěma modrými hrozny."
    },
    {
        obec: "Vlčice",
        kod: 579823,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54101,
        latitude: 50.562693,
        longitude: 15.821685,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Vl%C4%8Dice_%28Trutnov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým šikmým klínem vycházejícím z třetí třetiny horního a první třetiny vlajícího okraje listu a šikmým černým klínem vycházejícím z třetí šestiny vlajícího okraje listu, oba s vrcholy v dolním rohu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný klín, v něm černý vlk ve skoku s červenou zbrojí."
    },
    {
        obec: "Vlčtejn",
        kod: 540706,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.614724,
        longitude: 13.495764,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Vlctejn-PJ.png",
        coatOfArmsFlagDescription: "List kosmo a šikmo čtvrcený. V bílém žerďovém poli červená růže se žlutým semeníkem, horní a dolní pole modrá, vlající žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na stříbrné skále s červenou růží se zlatým semeníkem zlatá kvádrovaná věž se třemi prázdnými gotickými okny vedle sebe a s cimbuřím o pěti stínkách."
    },
    {
        obec: "Vlkančice",
        kod: 533874,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.901994,
        longitude: 14.897229
    },
    {
        obec: "Vlkaneč",
        kod: 534579,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28564,
        latitude: 49.806485,
        longitude: 15.403224,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/Vlkane%C4%8D_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, zelený a modrý. V červeném pruhu bílá vlčí hlava s krkem a žlutým jazykem, obrácená k vlajícímu okraji. V zeleném pruhu žlutý lipový věnec a v modrém pruhu bílá kozí hlava s krkem a žlutými rohy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít, nahoře červeno-modře polcený, vpravo vlčí hlava s krkem a zlatým jazykem, vlevo kozí hlava s krkem a zlatými rohy, obojí stříbrné a přivrácené. Dolní pole zelené se zlatým lipovým věncem."
    },
    {
        obec: "Vlkanov",
        kod: 569739,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58291,
        latitude: 49.715726,
        longitude: 15.356843
    },
    {
        obec: "Vlkanov",
        kod: 566250,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34522,
        latitude: 49.488639,
        longitude: 12.80529
    },
    {
        obec: "Vlkava",
        kod: 565571,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29443,
        latitude: 50.271435,
        longitude: 14.961431
    },
    {
        obec: "Vlkoš",
        kod: 586749,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69641,
        latitude: 48.989701,
        longitude: 17.163666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/31/Vlkos_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vlkem ve skoku v žerďové části, nahoře provázeným dvěma žlutými hrozny. Vlk s červeným jazykem, šikmo zespodu prostřelený žlutým šípem s bílým hrotem a opeřením. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný vlk ve skoku prostřelený zespodu zlatým šípem se stříbrným hrotem a opeřením, provázený v horních rozích zlatými vinnými hrozny."
    },
    {
        obec: "Vlkoš",
        kod: 547433,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75119,
        latitude: 49.395884,
        longitude: 17.419119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Vlko%C5%A1_P%C5%99erov_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žerďový červený s bílým vlkem se žlutou zbrojí ve skoku, vlající bílý. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný vlk ve skoku se zlatou zbrojí, pod ním položena stříbrná radlice."
    },
    {
        obec: "Vlkov",
        kod: 535915,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.113445,
        longitude: 14.522263
    },
    {
        obec: "Vlkov",
        kod: 553310,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.151291,
        longitude: 14.725326
    },
    {
        obec: "Vlkov",
        kod: 597082,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.324479,
        longitude: 16.200393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Vlkov_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zvlněný modrý, v poměru 7:5:2. V horním modrém pruhu vztyčený bílý rošt, pod ním v bílém pruhu šikmo vztyčený zelený lipový list s plodenstvím. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře vztyčený stříbrný rošt, dole nad modrou vlnitou patou šikmo vztyčený zelený lipový list s plodenstvím."
    },
    {
        obec: "Vlkov",
        kod: 574601,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.312721,
        longitude: 15.898493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Vlkov_NA_Coa.jpg",
        coatOfArmsFlagDescription: "Žlutý list s modrou krokví vycházející z třetí pětiny horního a dolního okraje listu s vrcholem v devíti desetinách délky listu. V žerďovém poli kráčející černý vlk. V krokvi tři žluté lipové listy, horní a dolní řapíkem k vlajícímu okraji listu, prostřední řapíkem k žerďovému. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě snížená obrácená modrá krokev se třemi (2, 1) zlatými lipovými listy, horními vztyčenými a dolním svěšeným. Nad krokví kráčející černý vlk."
    },
    {
        obec: "Vlkov pod Oškobrhem",
        kod: 537951,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28904,
        latitude: 50.156096,
        longitude: 15.22052
    },
    {
        obec: "Vlkovice",
        kod: 539376,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.948927,
        longitude: 12.739122,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Vlkovice_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list. V žerďové polovině žlutá berla závitem k žerďovému okraji s bílým sudariem a bílý vlk ve skoku s červeným jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlatá berla se stříbrným sudariem a stříbrný vlk ve skoku s červeným jazykem."
    },
    {
        obec: "Vlksice",
        kod: 598852,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.481012,
        longitude: 14.441291,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Vlksice_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným vlajícím pilovitým pruhem širokým třetinu délky listu. Pruh má tři zuby s hroty dosahujícími do poloviny délky listu. V žerďové polovině listu býčí rohy s částí lebky nad šesticípou hvězdou, obojí modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vpravo nad hvězdou býčí rohy s částí lebky, obojí modré, zleva vyrůstající červená vlčí sanice se třemi zuby."
    },
    {
        obec: "Vnorovy",
        kod: 586757,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69661,
        latitude: 48.931064,
        longitude: 17.350609,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Vnorovy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, střídavě bílých a červených, v poměru 3 : 4 : 7 : 4 : 3. Prostřední pruh je oboustranně zubatý se sedmi zuby vysokými polovinu šířky červeného pruhu a šesti stejnými mezerami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - stříbrně zúženým dřevěným břevnem přirozené barvy děleném štítě nahoře stříbrné cimbuřové břevno, dole doleva obrácený pluh s kolem přirozené barvy."
    },
    {
        obec: "Voděrady",
        kod: 582671,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67901,
        latitude: 49.481598,
        longitude: 16.557996,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Vod%C4%9Brady_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a žlutý, v poměru 1:4:1. V modrém pruhu dva žluté odvrácené půlměsíce, cípy k žerďovému a vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře děleném štítě nahoře černé beraní rohy s horní částí lebky, dole dva odvrácené zlaté půlměsíce."
    },
    {
        obec: "Voděrady",
        kod: 576891,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51734,
        latitude: 50.208788,
        longitude: 16.153245,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Voderady_%28Rychnov_nad_Kneznou%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým půlměsícem cípy k žerdi. Mezi cípy kosmo položený dolů obrácený žlutý klíč zuby vzhůru, šikmo podložený bílým mečem se žlutým jílcem a záštitou hrotem dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý dorůstající půlměsíc. Mezi hroty zlatý kosmo položený, dolů obrácený zlatý klíč zuby vzhůru, šikmo podložený stříbrným mečem se zlatým jílcem a záštitou hrotem dolů."
    },
    {
        obec: "Voděrady",
        kod: 581143,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.949695,
        longitude: 16.285165,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Vod%C4%9Brady_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, v něm šikmo nakloněný žlutý džbán, z něhož vytéká k dolnímu okraji bílá voda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vytéká ze šikmo nakloněného zlatého džbánu stříbrná voda k dolnímu okraji štítu."
    },
    {
        obec: "Vodice",
        kod: 553328,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39153,
        latitude: 49.464414,
        longitude: 14.909084
    },
    {
        obec: "Vodňany",
        kod: 551953,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38901,
        latitude: 49.147997,
        longitude: 14.175231,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Vodnany_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy - zelený, žlutý, zelený a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě polopostava doprava hledícího horníka v zeleném šatě s kápí na hlavě držící před sebou červený štít s českým lvem. Horník ve provázen nahoře šikmo a kosmo položenými modrými hornickými necičkami a dole dvojími modrými hornickými kladívky na černých topůrkách."
    },
    {
        obec: "Vodochody",
        kod: 539015,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25069,
        latitude: 50.206369,
        longitude: 14.398885,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Vodochody_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list se třemi vodorovnými oboustranně vlnkovitými modrými pruhy vycházejícími ze sedmé, deváté a jedenácté dvanáctiny žerďového a vlajícího okraje. V horní žerďové polovině dvě černá plamenná křídla se žlutými jetelovitě zakončenými perisonii. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad třemi modrými zvlněnými břevny dvě černá plamenná křídla se zlatým jetelovitě zakončeným perisoniem."
    },
    {
        obec: "Vodranty",
        kod: 531014,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.890465,
        longitude: 15.336406,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Vodranty_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a modrý. Uprostřed kráčející bílý korunovaný lev se žlutou zbrojí a jazykem mezi dvěma žlutými vodorovnými pruhy vycházejícími z třetí a čtrnácté šestnáctiny žerďového a vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrné vlnité břevno, nad ním kráčející stříbrný korunovaný lev se zlatou zbrojí a jazykem, dole vykořeněný pařez provázený po obou stranách jedním svěšeným lipovým listem, vše zlaté."
    },
    {
        obec: "Vodslivy",
        kod: 532614,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25724,
        latitude: 49.84965,
        longitude: 14.837298
    },
    {
        obec: "Vohančice",
        kod: 584134,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.321692,
        longitude: 16.39396,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Vohan%C4%8Dice_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma vztyčenými bílými račími klepety. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dvě vztyčená stříbrná račí klepeta."
    },
    {
        obec: "Vochov",
        kod: 559601,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33023,
        latitude: 49.757083,
        longitude: 13.278711,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Coat_of_arms_of_Vochov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, s mezikružím opačných barev v žerďové polovině. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě kruh opačných barev."
    },
    {
        obec: "Vojkov",
        kod: 530891,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25753,
        latitude: 49.65232,
        longitude: 14.519906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/00/Vojkov_CoA.png",
        coatOfArmsFlagDescription: "Zelený list, ve střední části dva bílé býčí rohy, mezi nimi žlutá poutnická hůl přeložená bílou svatojakubskou mušlí. V dolní třetině listu pět (3, 2) žlutých věží s cimbuřím a prázdnou bránou, dolní ze třetiny vsunuté mezi horní. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nahoře dva stříbrné býčí rohy, mezi nimi zlatá poutnická hůl přeložená stříbrnou svatojakubskou mušlí. Dole pět (3, 2) zlatých věží s cimbuřím a prázdnou bránou."
    },
    {
        obec: "Vojkovice",
        kod: 584142,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66701,
        latitude: 49.05148,
        longitude: 16.608308,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Vojkovice_%28okres_Brno-venkov%29_-_znak.svg",
        coatOfArmsFlagDescription: "List tvoří sedm šikmých pruhů, střídavě červené a žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě doleva položený rošt, pod ním vozové kolo, vše zlaté."
    },
    {
        obec: "Vojkovice",
        kod: 555738,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36273,
        latitude: 50.303424,
        longitude: 13.016307,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Vojkovice_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modro - červeně čtvrcený list. V žerďové polovině kapr hlavou dolů a hřbetem k žerdi, ve vlající polovině svatojakubský kříž, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně čtvrceném štítě vedle sebe kapr hlavou dolů a svatojakubský kříž, obojí stříbrné."
    },
    {
        obec: "Vojkovice",
        kod: 552488,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.68244,
        longitude: 18.468124,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/70/Vojkovice_znak.svg",
        coatOfArmsFlagDescription: "Zelený list se žlutými zkříženými šavlemi hroty nahoru a ostřím dolů nad bílou lilií. Ve vlající části dva svislé pruhy, každý široký jednu osminu délky listu, první žluto-černě šachovaný ve třech svislých řadách, druhý na vlajícím okraji modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad modrou patou zlato - černě řadami šachované břevno. Nad ním zlaté zkřížené šavle, pod nimi stříbrná lilie."
    },
    {
        obec: "Vojkovice",
        kod: 535290,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27744,
        latitude: 50.296287,
        longitude: 14.375238,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Vojkovice_ME_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:2:1. Ve žlutém pruhu zelená chmelová šištice se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z modré vlnité paty se dvěma stříbrnými vlnitými břevny červený kůl obtočený zeleným chmelovým štokem střídavě se čtyřmi šišticemi a čtyřmi listy."
    },
    {
        obec: "Vojníkov",
        kod: 562289,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.35378,
        longitude: 14.177156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/Vojn%C3%ADkov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a červený, v poměru 2 : 1 : 6. V modrém pruhu tři bílé tlapaté kříže pod sebou, v červeném žlutý zvon podložený zkříženými bílými meči hroty k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červený štít s modrou, zlatým vlnitým břevnem oddělenou hlavou se třemi stříbrnými tlapatými kříži. Dole zlatý zvon podložený zkříženými postavenými stříbrnými meči."
    },
    {
        obec: "Vojnův Městec",
        kod: 597091,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.679555,
        longitude: 15.878389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/CoA_of_Vojn%C5%AFv_M%C4%9Bstec.svg",
        coatOfArmsFlagDescription: "Červený list se dvěma zkříženými černými ostrvemi, přes ně vodorovně bílý vinařský nůž se žlutou rukojetí, hrotem k vlajícímu okraji a ostřím nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě černé zkřížené ostrve přeložené doleva položeným stříbrným vinařským nožem se zlatou rukojetí."
    },
    {
        obec: "Vojslavice",
        kod: 549126,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.593204,
        longitude: 15.229069
    },
    {
        obec: "Vojtanov",
        kod: 539074,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35134,
        latitude: 50.166294,
        longitude: 12.317021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/43/Vojtanov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Uprostřed zkřížené bílé, žlutě hořící pochodně, provázené třemi (2, 1) žlutými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě zkřížené stříbrné, zlatě hořící pochodně, provázené třemi (2, 1) zlatými liliemi."
    },
    {
        obec: "Vojtěchov",
        kod: 572501,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.788649,
        longitude: 15.973606,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/CoA_of_Vojt%C4%9Bchov_%28okres_Chrudim%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a červený, a svislý středový kvádrovaný bílý pruh s modrou krokví vycházející ze žlutého pruhu, s vrcholem na dolním okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se zlatým břevnem stříbrný kvádrovaný kůl, v něm snížená a dolů obrácená modrá krokev."
    },
    {
        obec: "Vokov",
        kod: 599239,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.395631,
        longitude: 15.219887
    },
    {
        obec: "Volanice",
        kod: 573761,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.335635,
        longitude: 15.398381,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Volanice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed pod korunou se sedmi perlami šesticípá hvězda uvnitř půlměsíce s cípy k hornímu okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod korunou se sedmi perlami hvězda uvnitř stoupajícího půlměsíce, vše zlaté."
    },
    {
        obec: "Volárna",
        kod: 533882,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.091878,
        longitude: 15.240554,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/23/Vol%C3%A1rna_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový klín s vrcholem ve dvou třetinách délky listu, žlutá krokev s rameny vycházejícími z první třetiny horního a dolního okraje listu a s vrcholem na vlajícím okraji listu a vlající modré trojúhelníky. V klínu bílá volská hlava se žlutými rohy a jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře zlatou obrácenou krokví dělený štít. Nahoře stříbrná volská hlava se zlatými rohy a jazykem."
    },
    {
        obec: "Volary",
        kod: 550671,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38451,
        latitude: 48.908916,
        longitude: 13.886674,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Volary_znak.png",
        coatOfArmsFlagDescription: "Zelený list s bílou krokví vymezenou zeleným žerďovým klínem s vrcholem ve středu listu. Vrchol krokve je na středu vlajícího okraje listu. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Volduchy",
        kod: 560251,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33822,
        latitude: 49.776171,
        longitude: 13.623155,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Volduchy_CoA.png",
        coatOfArmsFlagDescription: "List s bílým kosmým pruhem vycházejícím z první poloviny žerďového a horního okraje listu do druhé poloviny vlajícího a dolního okraje listu. Horní vlající pole je červené, dolní žerďové modré. V horní části bílého pruhu vodorovně modrý přirozený zdobený hřeben bez rukojeti s dvaceti čtyřmi zuby hroty dolů. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrno-modře děleném štítě uprostřed modrý zdobený přirozený hřeben bez rukojeti s dvaceti čtyřmi zuby."
    },
    {
        obec: "Voleč",
        kod: 575992,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.117449,
        longitude: 15.572804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/59/Volec_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list, u žerdě pod sebou tři modré květy lnu se žlutými středy, ve střední a vlající části černá volská hlava s bílými rohy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod třemi modrými květy lnu se zlatými středy černá, dopředu hledící volská hlava se stříbrnými rohy."
    },
    {
        obec: "Volenice",
        kod: 551961,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.252497,
        longitude: 13.747986,
        coatOfArmsFlagDescription: "Modro-žlutě šikmo dělený list. V žerďovém poli žluté kolo se čtyřmi rameny, ve vlajícím šikmo černý gotický klíč zuby nahoru a k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě kosmo dělený štít. Nahoře zlaté kolo se čtyřmi rameny, dole kosmo vztyčený černý gotický klíč."
    },
    {
        obec: "Volenice",
        kod: 541524,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.547875,
        longitude: 13.883773
    },
    {
        obec: "Volevčice",
        kod: 588164,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.211082,
        longitude: 15.444727
    },
    {
        obec: "Volevčice",
        kod: 546437,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.434989,
        longitude: 13.690498,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Volev%C4%8Dice_MO_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červeno-bíle dělený a zelený, v poměru 1 : 2. V žerďovém pruhu žluto-modře dělený jetelový kříž. V zeleném pruhu stojí na jedné noze volavka popelavá. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. V pravém červeno-stříbrně děleném poli zlato-modře dělený jetelový kříž. V levém zeleném poli volavka popelavá přirozené barvy stojící na jedné noze."
    },
    {
        obec: "Volfartice",
        kod: 562220,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47112,
        latitude: 50.730204,
        longitude: 14.453036,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Volfartice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý žluto - modře zubatě dělený pruh široký tři pětiny délky listu a dva vodorovné pruhy, modrý a žlutý. Do žlutého pole vyrůstá ohlížející se černý vlk s červenou zbrojí. Pod ním v modrém poli tři (2, 1) žluté šesticípé hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato - modře cimbuřovitě děleném štítě nahoře vyrůstající ohlížející se černý vlk s červenou zbrojí, dole tři (2, 1) zlaté hvězdy."
    },
    {
        obec: "Volfířov",
        kod: 547441,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38001,
        latitude: 49.106922,
        longitude: 15.370353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Volf%C3%AD%C5%99ov_CoA.jpg",
        coatOfArmsFlagDescription: "List dělený bílou krokví vycházející z první čtvrtiny žerďového a vlajícího okraje listu s vrcholem na dolním okraji listu. Horní pole modré se žlutou šestilistou růží s červeným semeníkem, obě dolní pole zelená. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně obrácenou stříbrnou krokví děleném štítě nahoře zlatá šestilistá růže s červeným semeníkem."
    },
    {
        obec: "Volyně",
        kod: 551970,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38701,
        latitude: 49.165886,
        longitude: 13.886339,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Volyne_Coat_of_Arms.jpg",
        coatOfArmsFlagDescription: "Bílo-červeně čtvrcený list, v horním žerďovém a dolním vlajícím poli po černé plamenné orlici. Poměr šířky k délce je 2:3."
    },
    {
        obec: "Vonoklasy",
        kod: 539830,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25228,
        latitude: 49.950219,
        longitude: 14.276801,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/Vonoklasy_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se dvěma zkříženými žlutými obilnými klasy, každý se dvěma listy nad sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zlatě polceném štítě vpravo zkřížené zlaté obilné klasy, každý se dvěma listy nad sebou, vlevo postava sv. Václava s knížecí korunou na hlavě, s mečem u boku, opírající se pravicí o štít a v levici držící praporec, oboje stříbrné s černou plamennou orlicí se zlatým perizoniem."
    },
    {
        obec: "Vortová",
        kod: 572527,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53961,
        latitude: 49.713143,
        longitude: 15.93634
    },
    {
        obec: "Votice",
        kod: 530905,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25901,
        latitude: 49.64023,
        longitude: 14.63819,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Votice_znak.png",
        coatOfArmsFlagDescription: "Pole praporu je žluté s šikmým modrým pruhem v šířce 1/3 výšky praporu."
    },
    {
        obec: "Voznice",
        kod: 541541,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.817253,
        longitude: 14.21665,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/CoA_of_Voznice.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 1 : 1 : 4 : 1 : 1. V modrém pruhu bílé vozové kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-modře sníženě vlnkovitě děleném štítě nahoře tři vyrůstající zelené jehličnaté stromy s černými kmeny, mezi nimi dva modré vztyčené zvonkovité květy, dole stříbrné vozové kolo."
    },
    {
        obec: "Vrábče",
        kod: 545261,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.917453,
        longitude: 14.376014,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Vrabce_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zeleným žerďovým klínem s vrcholem na vlajícím okraji. V klínu čtyři do oblouku přivrácené a proložené žluté obilné klasy, krajní s listem. V horním cípu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený hrot, v něm čtyři do oblouku přivrácené a proložené zlaté obilné klasy, krajní s listem. Hrot vpravo provázen červenou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Vraclav",
        kod: 581151,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56542,
        latitude: 49.968138,
        longitude: 16.090073,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Vraclav_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutou kotvou s rameny zakončenými bílou lilií, nad příčným ramenem přeložená červenou knížecí čepicí s hermelínovým lemem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá kotva s rameny zakončenými stříbrnou lilií, na vrcholu přeložená červenou knížecí čepicí s hermelínovým lemem."
    },
    {
        obec: "Vracov",
        kod: 586765,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69642,
        latitude: 48.975328,
        longitude: 17.211104,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Znak_m%C4%9Bsta_Vracov.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, modrý, bílý a červený, v poměru 1 : 1 : 6 : 1 : 1. V modrém pruhu volná bílá zeď o čtrnácti kvádrech (5, 4, 3, 2), na ní tři bílé válcové věže, prostřední větší se čtyřmi černými okny, krajní se třemi černými okny. Věže s červenými kuželovými střechami a žlutými makovicemi s křížky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vracovice",
        kod: 550019,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.902225,
        longitude: 15.898792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Vracovice_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh, široký jednu šestinu délky listu, a tři vodorovné pruhy, žlutý, bílý a modrý, v poměru 7:2:7. V žerďové části žlutého pruhu černé zkřížené ostrve, v modrém pruhu žluto-červeně polcený plamenný meč se žlutým jílcem hrotem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře polceném štítě na zelené patě stříbrná okrouhlá kaple s věží s červenými střechami, dvěma černými okny a zlatým křížkem, k níž zprava přiléhá černá prázdná brána, vpravo nahoře zkřížené černé ostrve, vlevo postavený zlato-červeně polcený plamenný meč se zlatým jílcem."
    },
    {
        obec: "Vracovice",
        kod: 530913,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25801,
        latitude: 49.662562,
        longitude: 14.932317,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Vracovice_%28Bene%C5%A1ov_District%29_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý. V červeném pruhu bílá kniha se žlutým kováním a sponami, podložená kosmo patriarším procesním křížem a šikmo berlou, oboje žluté. V modrém pruhu pět (3, 2) žlutých šesticípých hvězd. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře vlnitě dělený štít. Nahoře stříbrná kniha se zlatým kováním a sponami, podložená kosmo patriarším procesním křížem a šikmo berlou, obojí zlaté. Dole pět (3, 2) zlatých hvězd."
    },
    {
        obec: "Vračovice-Orlov",
        kod: 548022,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.952537,
        longitude: 16.252476,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Vra%C4%8Dovice_Orlov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý modrý, v poměru 13 : 3. Z modrého pruhu vyrůstá černý orel se žlutou zbrojí a s červenými jazyky. Z modrého pruhu vyniká vydutý klín zakončený na hrudi orla tlapatým křížem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá z modré kvádrované cimbuřové paty černý orel se zlatou zbrojí a červenými jazyky. Z paty vyniká vydutý hrot zakončený na hrudi orla tlapatým křížem, vše zlaté."
    },
    {
        obec: "Vraňany",
        kod: 535303,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27707,
        latitude: 50.317291,
        longitude: 14.361804,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Vra%C5%88any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a modrý, v poměru 2:5:1:1:1. V horním bílém pruhu černá vykračující vrána se žlutými pařáty. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s červenou hlavou a modrou patou se stříbrným břevnem černá vykračující vrána se zlatými pařáty."
    },
    {
        obec: "Vrančice",
        kod: 564362,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.610735,
        longitude: 14.041861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Coats_of_arms_Vran%C4%8Dice.jpeg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, zelený, žlutý, zelený, bílý, zelený, žlutý a zelený v poměru 3:1:1:5:1:1:3. V bílém pruhu vzlétající černá vrána s červeným zobákem provázená osmihrotým křížem a šesticípou hvězdou, obojí červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-zeleně čtvrcený štít. V prvním poli vzlétající černá vrána s červeným zobákem provázená nahoře osmihrotým křížem a hvězdou, obojí červené. Ve druhém poli zlatý patriarší kříž, ve třetím poli zlatá osmihrotá hvězda, ve čtvrtém poli černá hornická kladívka."
    },
    {
        obec: "Vrané nad Vltavou",
        kod: 539848,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25246,
        latitude: 49.938512,
        longitude: 14.383447,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Vrane_nad_Vltavou_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List praporu je bílý s dolním modrým pruhem širokým jednu třetinu výšky. Na bílém poli je červený kosmý pruh o šířce jedné čtvrtiny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítu s vlnitou modrou patou, v níž plave stříbrný pstruh, tři kosmo položené červené růže se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Vranov",
        kod: 584151,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66432,
        latitude: 49.309357,
        longitude: 16.613878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Vranov-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a žlutý, v poměru 4:5. Uprostřed červeného pruhu žlutá koruna, pod ní ve žlutém pruhu modrý mariánský anagram. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zlatě zvýšeně děleném štítě nahoře zlatá koruna dole modrý mariánský anagram."
    },
    {
        obec: "Vranov",
        kod: 579491,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.764487,
        longitude: 13.042022,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/CoA_of_Vranov_%28okres_Tachov%29.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 2 : 1. Z horního okraje listu vychází zelený klín s vrcholem na horním okraji modrého pruhu. V klínu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou patou zelený klín se stříbrnou lilií."
    },
    {
        obec: "Vranov",
        kod: 530921,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25722,
        latitude: 49.853416,
        longitude: 14.777021,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Vranov_%28BN%29%2C_znak.jpeg",
        coatOfArmsFlagDescription: "List dělený kosmým žlutým pruhem vycházejícím z první dvanáctiny horního a dvanácté dvanáctiny dolního okraje listu na zelené žerďové pole se žlutou devíticípou hvězdou a bílé vlající pole s černou rozkřídlenou vránou s šedou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně vzhůru do oblouku dělený štít, nahoře dvě přivrácené rozkřídlené vrány přirozené barvy, dole devítihrotá hvězda ve vyrůstajícím lomeném oblouku, obojí zlaté."
    },
    {
        obec: "Vranov nad Dyjí",
        kod: 595098,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67103,
        latitude: 48.894799,
        longitude: 15.812799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Vranov_nad_Dyj%C3%AD_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, červený, bílý a modrý, v poměru 2 : 2 : 7 : 2 : 2. V červeném pruhu černá rozkřídlená vrána k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vranová",
        kod: 582689,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67962,
        latitude: 49.553154,
        longitude: 16.525131,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Vranov%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "Žluto-modře šikmo dělený list. Ve žlutém poli na černé kosmé ostrvi černá vrána s červenou zbrojí, v modrém poli bílá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý hrot, v něm na kosmé černé ostrvi černá vrána s červenou zbrojí, nad ní modrá vztyčená radlice a krojidlo, hrot je provázen dvěma stříbrnými růžemi s červenými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Vranová Lhota",
        kod: 578975,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 57101,
        latitude: 49.710743,
        longitude: 16.825363,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Vranov%C3%A1_Lhota_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové pole a dolní vlající pole bílé s černou vykračující vránou, dolní žerďové a horní vlající pole modré se žlutým půlměsícem. Horní půlměsíc couvající, dolní dorůstající. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-modře dělený štít, nahoře černá vykračující vrána, dole dva odvrácené zlaté půlměsíce."
    },
    {
        obec: "Vranovice",
        kod: 585033,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69125,
        latitude: 48.966097,
        longitude: 16.606707,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Vranovice_znak.gif",
        coatOfArmsFlagDescription: "Červený list se žlutou lilií mezi dvěma odvrácenými bílými vinařskými noži se žlutými rukojeťmi, mezi nimi u dolního okraje bílý vinný hrozen. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá lilie, provázená po stranách dvěma odvrácenými stříbrnými vinařskými noži se zlatými rukojeťmi a dole stříbrným vinným hroznem."
    },
    {
        obec: "Vranovice",
        kod: 541567,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.622726,
        longitude: 13.899074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Vranovice_%28P%C5%99%C3%ADbram_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Ve žlutém pruhu černá vrána nad černou kančí hlavou s bílou zbrojí, v zeleném lilie nad hornickými kladívky, oboje žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít se zeleným vydutým hrotem provázeným vpravo černou vránou, obrácenou doleva, vlevo černou kančí hlavou se stříbrnou zbrojí. V hrotu lilie nad hornickými kladívky, vše zlaté."
    },
    {
        obec: "Vranovice-Kelčice",
        kod: 590177,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79808,
        latitude: 49.400982,
        longitude: 17.111366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Vranovice_Kel%C4%8Dice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý se žlutou korunou, bílý s černou letící vránou se žlutou zbrojí a červený se dvěma bílými klíny vycházejícími z čtvrté a páté osminy dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-stříbrno-červeně dělený štít. Nahoře zlatá koruna, uprostřed letící černá vrána se zlatou zbrojí, dole dva stříbrné kužely."
    },
    {
        obec: "Vranovská Ves",
        kod: 595101,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67151,
        latitude: 48.951592,
        longitude: 15.918586,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Vranovsk%C3%A1_Ves_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. Uprostřed žlutá koruna s prostrčeným bílým mečem hrotem dolů se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě postavený stříbrný meč se zlatým jílcem prostrčený zlatou korunou."
    },
    {
        obec: "Vraný",
        kod: 533068,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27373,
        latitude: 50.327189,
        longitude: 14.017251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Vrany_CZ_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 3 : 1. Ze zeleného pruhu vynikají dvě věže, obě bílé a kvádrované, s oknem, cimbuřím a stanovou střechou se žlutou makovicí, okna a střechy jsou černé. Mezi věžemi modro-žlutě čtvrcený štítek, v 1. a 4. poli žlutý zajíc ve skoku, ve a 2. a 3. poli černá kančí hlava se stříbrnou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vratěnín",
        kod: 595110,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.904186,
        longitude: 15.595278,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Vrat%C4%9Bn%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, červený, bílý, zelený, bílý a červený v poměru 2:1:6:1:2. V zeleném poli žlutý korunovaný lev s červeným jazykem a drápy, bílými zuby, držící v pravé tlapě bílý meč s hrotem nad hlavou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý korunovaný lev s červenou zbrojí držící v pravé tlapě stříbrný meč. V červených koutech štítu stříbrné poštovní trubky se zlatými šňůrami a kováním, náústky k sobě."
    },
    {
        obec: "Vratimov",
        kod: 598879,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73932,
        latitude: 49.77005,
        longitude: 18.310255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Vratimov_znak.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým kolem s dvanácti zuby. Do vnitřního kruhu vepsán bílý čtverec a do něj vepsána stylizovaná žlutá radlice. (Městský znak převeden do listu praporu.) Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Vratislávka",
        kod: 597104,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59451,
        latitude: 49.386513,
        longitude: 16.244137,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Vratisl%C3%A1vka_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílou lilií ve žlutém vavřínovém věnci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrná lilie ve zlatém vavřínovém věnci."
    },
    {
        obec: "Vrátkov",
        kod: 564826,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28201,
        latitude: 50.047055,
        longitude: 14.834885
    },
    {
        obec: "Vrátno",
        kod: 572021,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29426,
        latitude: 50.432702,
        longitude: 14.693699,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Vr%C3%A1tno_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list. Uprostřed bílá prázdná brána se žlutými otevřenými vraty a červenou valbovou střechou. V bráně obilný klas se čtyřmi listy (2,2), vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná prázdná brána se zlatými otevřenými vraty a s červenou valbovou střechou. Uprostřed obilný klas se čtyřmi listy (2,2), vše zlaté."
    },
    {
        obec: "Vráž",
        kod: 549991,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39832,
        latitude: 49.383148,
        longitude: 14.12798,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Vraz-znak.svg",
        coatOfArmsFlagDescription: "Červený list s bílým klínem s vrcholem na středu vlajícího okraje, v žerďové části černá plamenná orlice. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný klín v něm černá plamenná orlice."
    },
    {
        obec: "Vráto",
        kod: 535796,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.988054,
        longitude: 14.527017,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Vr%C3%A1to_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 3 : 1. V horní polovině žerďové části červená brána se zavřenými vraty. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě nad modrou vlnitou patou brána se zavřenými vraty, provázená nahoře dvěma obilnými snopy, vše červené."
    },
    {
        obec: "Vráž",
        kod: 531944,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26711,
        latitude: 49.983443,
        longitude: 14.12902,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Znak_vraz.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový pruh široký jednu třetinu délky listu, bílo-modro-bíle dělený v poměru 1:3:1, a tři vodorovné pruhy opačných barev. V modrém žerďovém poli na zeleném návrší bílá věž se dvěma černými gotickými okny nad sebou, červenou valbovou střechou se dvěma žlutými patriaršími křížky a dvěma bílými přístavky s červenou šikmou a kosmou střechou. Pod věží bílý nůž se žlutou rukojetí hrotem k žerdi a ostřím k dolnímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší s položeným stříbrným nožem se zlatou rukojetí stříbrná věž se dvěma černými gotickými okny nad sebou, červenou valbovou střechou se dvěma zlatými patriaršími křížky a dvěma stříbrnými přístavky s červenou šikmou a kosmou střechou."
    },
    {
        obec: "Vražkov",
        kod: 565881,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.373533,
        longitude: 14.268153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Vrazkov_ZNAK.jpg",
        coatOfArmsFlagDescription: "List tvoří modro-bíle čtvrcený žerďový pruh široký jednu třetinu délky listu a dva vodorovné pruhy, modrý a bílý. V horním modrém rohovém poli berla s lilií v závitu obráceném k žerdi, oboje žluté. V horním bílém poli čtvrceného pruhu červená sekera - širočina ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo obrácená zlatá berla s lilií v závitu, vlevo červená odvrácená sekera - širočina."
    },
    {
        obec: "Vražné",
        kod: 554910,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74235,
        latitude: 49.628789,
        longitude: 17.866773,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Vra%C5%BEn%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vodorovným pruhem širokým osminu šířky listu podloženým žlutým dvojklíčem a bílým přirozeným květem lilie v horním pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě za stříbrnou kvádrovanou zdí s prolomenou branou zlatý dvojklíč, nahoře uprostřed stříbrný přirozený květ lilie."
    },
    {
        obec: "Vrážné",
        kod: 572292,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.675991,
        longitude: 16.778122,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Vr%C3%A1%C5%BEn%C3%A9_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se žlutým žerďovým palisádovým pruhem se čtyřmi hroty a třemi stejnými mezerami, širokým jednu pětinu délky listu. V horní části červeného pole dvě bílé růže se žlutými semeníky a zelenými kališními lístky, mezi nimi dole žlutý volný tlapatý patriarší kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod zlatou palisádovou hlavou zlatý patriarší kříž provázený dvěma stříbrnými růžemi se zlatými semeníky a zelenými kališními lístky, pod nimi položené zlaté krojidlo."
    },
    {
        obec: "Vrbátky",
        kod: 590185,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79813,
        latitude: 49.508146,
        longitude: 17.200051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/07/Vrb%C3%A1tky_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zelenou vrbou, v ní na stonku s lístky bílý pětilistý květ se žlutým středem. Z dolní části kmenu vyrůstají dvě orlí křídla, k žerďovému okraji modré, k vlajícímu červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená vrba, v ní na stonku stříbrný pětilistý květ se zlatým středem. Z kmene vrby vyrůstá vpravo modré a vlevo červené orlí křídlo."
    },
    {
        obec: "Vrbatův Kostelec",
        kod: 572535,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53956,
        latitude: 49.856443,
        longitude: 15.941145,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/30/Vrbat%C5%AFv_Kostelec_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, červený, bílý a zelený, v poměru 3 : 1 : 1 : 3, a žlutý svislý středový pruh široký třetinu délky listu se vztyčenou černou medvědí tlapou se čtyřmi prsty s červenými drápy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlato-zeleně polcený štít. Vpravo medvědí tlapa se čtyřmi prsty a červenými drápy. Vlevo stříbrný kostel vpravo s věží s vchodem převýšeným oknem, lodí se dvěma okny a s věžičkou nad kněžištěm. Kostel má černý vchod a oblouková okna, červené střechy, na věžích zakončené zlatými křížky Pod kostelem doleva obrácená zlatá hlavice berly se stříbrným patriarším křížem v závitu."
    },
    {
        obec: "Vrbčany",
        kod: 533891,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.050307,
        longitude: 14.992861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Vrb%C4%8Dany_CoA.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, červený, žlutý a modrý, v poměru 1 : 1 : 5 : 1. Do žlutého pruhu vyrůstá zelená vrba s hnědým kmenem. V modrém a červeném pruhu bílá šesticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z modré vlnité paty vrba přirozené barvy. Vpravo vyniká černá, hrotem zakončená žerď s vlajícím modro-červeně děleným dvojcípým praporcem se stříbrnou hvězdou."
    },
    {
        obec: "Vrbice",
        kod: 529915,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38473,
        latitude: 49.141384,
        longitude: 13.715891
    },
    {
        obec: "Vrbice",
        kod: 585041,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69109,
        latitude: 48.915184,
        longitude: 16.897893,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Vrbice_BV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s žerďovým žlutým klínem s vrcholem na vlajícím okraji listu. V žerďové části klínu zelená vykořeněná vrba. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý hrot s vykořeněnou vrbou přirozené barvy, provázený dvěma zlatými vinnými hrozny."
    },
    {
        obec: "Vrbice",
        kod: 566675,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36453,
        latitude: 50.14965,
        longitude: 13.233839,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Vrbice_KV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový modrý pruh široký jednu třetinu délky listu a bílé pole se zelenou krokví, vycházející z druhé třetiny horního a dolního okraje listu, s vrcholem na vlajícím okraji listu. V modrém pruhu žlutá růže na stonku se dvěma listy nad třemi (2,1) bílými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně polceném štítě vpravo zlatá růže na stonku se dvěma listy nad třemi (2,1) stříbrnými hvězdami, vlevo vykořeněná vrba přirozené barvy."
    },
    {
        obec: "Vrbice",
        kod: 572128,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.369139,
        longitude: 15.428878,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Vrbice_JC_CZ_CoA.gif",
        coatOfArmsFlagDescription: "Bílý list se zelenou vrbou s hnědým kmenem podloženým červeným pluhem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě vrba přirozené barvy podložená dole červeným pluhem."
    },
    {
        obec: "Vrbice",
        kod: 548707,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51741,
        latitude: 50.08923,
        longitude: 16.251784,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Vrbice_Rychnov_znak.gif",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 5:3. Do modrého pruhu vyrůstá v žerďové části žlutá vrba, pod ní v bílém poli červený květ vrbky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá vrba vyrůstající ze stříbrného trojvrší s červeným květem vrbky."
    },
    {
        obec: "Vrbice",
        kod: 599611,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28904,
        latitude: 50.181015,
        longitude: 15.230617,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Vrbice_NB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutou vykořeněnou vrbou. Poměr šířky k délce listu je 2:3",
        coatOfArmsDescription: "V zeleném štítě zlatá vykořeněná vrba."
    },
    {
        obec: "Vrbice",
        kod: 565890,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41164,
        latitude: 50.4833,
        longitude: 14.286605,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/Vrbice_LT_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh, široký pět dvanáctin délky listu, a dva vodorovné pruhy, červený a modrý. V bílém pruhu tři zelené zkřížené vztyčené vrbové pruty s listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě se zvýšenou modrou patou vpravo stříbrná ohnutá lví noha se zlatou zbrojí, vlevo tři zelené zkřížené vztyčené vrbové pruty s listy, v patě zlatá koruna."
    },
    {
        obec: "Vrbičany",
        kod: 571431,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.308192,
        longitude: 13.995897,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Vrbi%C4%8Dany_%28Kladno_District%29_CoA.png",
        coatOfArmsFlagDescription: "Červený list se žlutým horním klínem, z jehož vrcholu vyrůstá zelená vrba s hnědým kmenem. V žerďovém poli kosmo a ve vlajícím šikmo vztyčená žlutá šištice chmele se stonkem a odvráceným úponkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatý klín s vyrůstající vrbou přirozené barvy provázený po stranách vztyčenou zlatou šišticí chmele se stonkem a odvráceným úponkem."
    },
    {
        obec: "Vrbičany",
        kod: 565903,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41121,
        latitude: 50.462705,
        longitude: 14.084782,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Vrbicany_LT_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, žlutý a zelený, v poměru 1:2:1. Ve žlutém pruhu červené hrábě podložené zkříženými zelenými vrbovými pruty s listy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě červené hrábě podložené zkříženými zelenými vrbovými pruty s listy."
    },
    {
        obec: "Vrbka",
        kod: 542393,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76821,
        latitude: 49.228329,
        longitude: 17.413233,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Vrbka_KM_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a zelený v poměru 1:4:1. Ve středním poli zelená vrba se čtyřmi větvemi, přes kmen přeložená dvěma zkříženými černými sekerkami se žlutými topůrky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě ve středním poli zelená vrba, přes kmen zkříženy černé sekery na zlatých topůrkách."
    },
    {
        obec: "Vrbno nad Lesy",
        kod: 566926,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43906,
        latitude: 50.32262,
        longitude: 13.907083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/16/Vrbno_nad_Lesy_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a modrý, v poměru 11 : 5. Z modrého pruhu vyrůstá černá vrba se zelenými listy. V modrém pruhu bílá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z modré paty se stříbrnou lilií černá vrba se zelenými listy."
    },
    {
        obec: "Vrbno pod Pradědem",
        kod: 597961,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79326,
        latitude: 50.121048,
        longitude: 17.383262,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/75/Vrbno_pod_Prad%C4%9Bdem_znak.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, oranžový, modrý, žlutý a modrý, v poměru 2 : 1 : 1 : 1 : 2 . Poměr šířky k délce listu 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlaté břevno. Nahoře běžící liška přirozené barvy. Dole na zeleném trávníku vlevo skála přirozené barvy s černým ústím štoly, z ní horník, v černém kabátu s flekem, ve stříbrných nohavicích a punčochách a se zelenou čepicí na hlavě, vyváží na táčkách rudu."
    },
    {
        obec: "Vrbovec",
        kod: 595128,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67124,
        latitude: 48.799878,
        longitude: 16.100716,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Vrbovec_znak.gif",
        coatOfArmsFlagDescription: "Modrý list s bílým ondřejským křížem s rameny širokými jednu desetinu šířky listu. V žerďovém a vlajícím poli po odvráceném vinném listu, v horním poli knížecí čepice nad osmicípou hvězdou, v dolním poli vinný hrozen. Knížecí čepice je červená, žlutě zdobená, vše ostatní žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zkřížené zlaté sudlice se stříbrnými násadami, provázené nahoře červenou knížecí čepicí se zlatým lemováním nad osmihrotou hvězdou, dole vinným hroznem, po stranách dvěma položenými odvrácenými vinnými listy, vše zlaté."
    },
    {
        obec: "Vrbová Lhota",
        kod: 537977,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28911,
        latitude: 50.112053,
        longitude: 15.062754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Coats_of_arms_Vrbov%C3%A1_Lhota.jpeg",
        coatOfArmsFlagDescription: "Modrý list s bílou zvonicí se zavřenými hnědými dveřmi, se žlutým zvonem a červenou stanovou střechou s křížkem mezi dvěma žlutými vrbami v horní polovině listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná zvonice se zavřenými dveřmi, se zlatým zvonem a červenou stanovou střechou s křížkem, nad ní dvě zlaté vrby."
    },
    {
        obec: "Vrcovice",
        kod: 550001,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.344758,
        longitude: 14.174529,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Vrcovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 2, modrý a pilovitý bílý o deseti zubech s vrcholy do poloviny šířky listu. V modrém pruhu dvě vztyčené borové šišky, mezi nimi tři převázané obilné klasy, krajní odkloněné, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrná palisáda, nad ní dvě vztyčené borové šišky, mezi nimi tři převázané obilné klasy, krajní odkloněné, vše zlaté."
    },
    {
        obec: "Vrčeň",
        kod: 558559,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33541,
        latitude: 49.508064,
        longitude: 13.618988,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Vr%C4%8De%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři šikmé pruhy modrý, žlutý, černý a bílý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře stříbrná mitra s fimbriemi, zlatě zdobená, kosmo podložená zlatým veslem. Dole kosmý mlátek šikmo přeložený želízkem, obojí černé."
    },
    {
        obec: "Vrdy",
        kod: 534587,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28571,
        latitude: 49.921633,
        longitude: 15.472535,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/20/Vrdy_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zvlněný bílý o třech vrcholech a dvou prohlubních. Nad žerďovou prohlubní bílá, korunovaná hlava lva se žlutou zbrojí a jazykem. Pod ní v bílém pruhu čtyři mřežovitě propletené zelené vrbové pruty s listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně vlnitě děleném štítě nahoře stříbrná korunovaná hlava lva se zlatou zbrojí a jazykem, dole čtyři mřežovitě propletené zelené vrbové pruty s listy."
    },
    {
        obec: "Vrhaveč",
        kod: 557455,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33901,
        latitude: 49.345613,
        longitude: 13.29636
    },
    {
        obec: "Vrchlabí",
        kod: 579858,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54301,
        latitude: 50.617189,
        longitude: 15.605106,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Vrchlab%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, červený, bílý a zelený v poměru 2:1:4:1:2. V červeném pruhu žlutý štítek se zkříženými hornickými kladívky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Vrchoslavice",
        kod: 590193,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79827,
        latitude: 49.332781,
        longitude: 17.219343,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Vrchoslavice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. V žerďové části listu bílé jelení paroží, mezi ním žlutý lipový trojlist, na kterém sedí žlutý pták. Ve vlající části obou pruhů po jedné bílé šesticípé hvězdě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně děleném štítě jelení paroží převýšené dvěma hvězdami, obojí stříbrné, uprostřed lipový trojlist, na kterém sedí pták, obojí zlaté."
    },
    {
        obec: "Vrchotovy Janovice",
        kod: 530948,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25753,
        latitude: 49.666919,
        longitude: 14.577909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/57/Vrchotovy_Janovice_znak.png",
        coatOfArmsFlagDescription: "Modrý list s bílo-červeně polcenou orlicí, se žlutým štítkem s černým parožím na hrudi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém poli stříbrno-červeně polcená orlice. Na hrudi zlatý štítek s černým parožím."
    },
    {
        obec: "Vrchovany",
        kod: 562238,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.55324,
        longitude: 14.57122,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Vrchovany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý a zelený. Uprostřed červeno-žlutě polcená věž s cimbuřím, s černou prolomenou branou a obloukovým oknem, ze kterého k žerďovému okraji vyniká černá ostrev se dvěma suky. V zeleném poli ze žluté poloviny věže vyniká vodorovně žlutá žerď se žlutým praporcem s ocasem, na jeho listu zkřížené černé ostrve. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě červeno-zlatě polcená věž s cimbuřím s černou prolomenou branou a obloukovým oknem, z něj doprava vyniká položená černá ostrev se dvěma suky, doleva položená zlatá žerď se svěšeným zlatým praporcem s ocasem se zkříženými černými ostrvemi."
    },
    {
        obec: "Vrchovnice",
        kod: 548057,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.323032,
        longitude: 15.757627
    },
    {
        obec: "Vrchy",
        kod: 569747,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74245,
        latitude: 49.75108,
        longitude: 17.871191,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Vrchy_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - červený a zelený, v poměru 2:1. V červeném poli bílý pádící kůň. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě na zeleném dvojvrší stříbrný pádící kůň."
    },
    {
        obec: "Vroutek",
        kod: 566934,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43982,
        latitude: 50.180096,
        longitude: 13.37997,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/Vroutek_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, žlutý, bílý, červený a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě kněz v červené klerice a pláštíku, stříbrné rochetě, s černým kloboukem se šesti střapci na každé straně, se zlatým pektorálem na zlaté stuze na krku, stojící s pozdviženýma rukama v prázdném gotickém oblouku a v červeném sloupoví, mezi dvěma černými gotickými okny s kružbami."
    },
    {
        obec: "Vrskmaň",
        kod: 563463,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43115,
        latitude: 50.488266,
        longitude: 13.495732,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Vrskma%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu se třemi položenými černými routami nad sebou a modré pole se dvěma zkříženými obilnými klasy převýšenými hruškou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zlatým lemem posázeným položenými černými routami zkřížené obilné klasy převýšené hruškou, vše zlaté."
    },
    {
        obec: "Vršce",
        kod: 573795,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50733,
        latitude: 50.323952,
        longitude: 15.323155
    },
    {
        obec: "Vršovice",
        kod: 511161,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74761,
        latitude: 49.884925,
        longitude: 17.93542,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Vr%C5%A1ovice_OP_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 2:1. V červeném pruhu dvě žluté zkřížené kosy, provázené mezi násadami nahoře a po stranách třemi bílými osmihrotými kříži. V bílém pruhu červená růže se žlutým semeníkem a kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrným trojvrším s červenou růží se zlatým semeníkem dvě zkřížené zlaté kosy, provázené mezi násadami nahoře a po stranách třemi stříbrnými osmihrotými kříži."
    },
    {
        obec: "Vršovice",
        kod: 546887,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.368582,
        longitude: 13.842119,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Vr%C5%A1ovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, červený, modro-zeleně dělený, červený a modrý. Ze šestých osmin vnějších okrajů vnitřních pruhů vychází bílý vlnitý pruh se třemi vrcholy a dvěma prohlubněmi. Do modrého pole děleného pruhu vyrůstá zelený vrch, ze kterého vyrůstá hřib - satan s bílým kloboukem a žluto-červeně pilovitě zvýšeně dělenou tření. Ve vlajícím červeném pruhu nad vlnitým pruhem žlutá sekera ostřím k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě, se stříbrným vlnitým břevnem v patě, vpravo na zeleném vrchu hřib - satan se stříbrným kloboukem a zlato-červeně zvýšeně pilovitě dělenou tření, vlevo nahoře zlatá sekera."
    },
    {
        obec: "Vršovka",
        kod: 574627,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54901,
        latitude: 50.323287,
        longitude: 16.120261,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/Vr%C5%A1ovka_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a šikmo pilovitý zelený, v poměru 1 : 2 a červený pruh u dolního okraje listu široký tři osminy šířky listu. Vrcholy čtyř zubů sahají do šestiny délky listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě vyrůstá ze zvýšeného červeného návrší se stříbrnými vykořeněnými lekny pět zelených smrků s černými kmeny."
    },
    {
        obec: "Vrutice",
        kod: 565911,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41147,
        latitude: 50.502216,
        longitude: 14.289857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Vrutice_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří žerďový červený pruh široký třetinu délky listu a sedm střídavě bílých a modrých vodorovných pruhů. V žerďovém pruhu dva zkřížené žluté klíče, podložené přirozenou lilií na stonku s listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo dva zkřížené zlaté klíče podložené přirozenou lilií na stonku s listy, vlevo tři modrá břevna."
    },
    {
        obec: "Vřesina",
        kod: 568244,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74720,
        latitude: 49.945634,
        longitude: 18.190598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/24/V%C5%99esina_OP_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zelenou snítkou vřesu s jedním červeným květem mezi dvěma poupaty v žerďové části. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená snítka vřesu s jedním červeným květem mezi dvěma poupaty."
    },
    {
        obec: "Vřesina",
        kod: 500291,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74285,
        latitude: 49.824282,
        longitude: 18.125802,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Vresina_OS_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Na středu zeleného listu bílý květ vřesu se žlutou bliznou a žlutými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný květ vřesu se zlatou bliznou a zlatými kališními lístky."
    },
    {
        obec: "Vřeskovice",
        kod: 566055,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33401,
        latitude: 49.525544,
        longitude: 13.271216,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/V%C5%99eskovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý pruh, široký polovinu délky listu, a šest vodorovných pruhů střídavě bílých a červených. Ve žlutém pruhu snítka vřesu se dvěma květy. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Polcený štít, vpravo ve zlatém poli snítka vřesu se dvěma květy přirozené barvy. Levé pole pětkrát stříbrno-červeně děleno."
    },
    {
        obec: "Vřesová",
        kod: 560707,
        okres: "Sokolov",
        kod_okres: "CZ0413",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35735,
        latitude: 50.259197,
        longitude: 12.696593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Vresova_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený, s bílou lilií a polcený černo-modrý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v zeleném poli stříbrná lilie, dolní pole černo-modře polceno."
    },
    {
        obec: "Vřesovice",
        kod: 586773,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69648,
        latitude: 49.059182,
        longitude: 17.215114,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/V%C5%99esovice_-_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a žlutý. V horním žerďovém rohu vinný hrozen na stonku s jedním listem a kosíř ostřím k žerdi, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě polcený štít, vpravo vinný hrozen s jedním listem a pod ním kosíř, obojí stříbrné, vlevo vykořeněná jabloň přirozených barev."
    },
    {
        obec: "Vřesník",
        kod: 549029,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50771,
        latitude: 50.438105,
        longitude: 15.628263
    },
    {
        obec: "Vřesovice",
        kod: 590207,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79809,
        latitude: 49.401769,
        longitude: 17.139043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6d/V%C5%99esovice_PV_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří modré čtvercové pole a žlutý svislý vlající pruh. V modrém poli kosmo položený klíč zuby nahoru a k žerdi, šikmo podložený mečem hrotem k hornímu cípu, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod jelenem ve skoku šikmo položený meč hrotem vzhůru, křížem přeložený klíčem zuby nahoru a ven, vše zlaté."
    },
    {
        obec: "Vsetín",
        kod: 541630,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75501,
        latitude: 49.339025,
        longitude: 17.993952,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Coat_of_arms_of_Vset%C3%ADn.svg",
        coatOfArmsFlagDescription: "List praporu tvoří vodorovné červené a zelené pole v poměru 3:1. Na červeném poli stojící bílá ovečka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červený štít, v němž stojí na zeleném trojvrší doprava kráčející bílá ovce."
    },
    {
        obec: "Všehrdy",
        kod: 566497,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.963781,
        longitude: 13.585179
    },
    {
        obec: "Vstiš",
        kod: 558567,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33441,
        latitude: 49.645645,
        longitude: 13.249051,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Vsti%C5%A1_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, červený, bílý, modrý, bílý a červený, v poměru 1:1:3:1:1. V žerďové polovině červené čtvercové pole široké čtyři sedminy šířky listu, v něm žlutá hlavice berly závitem k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, nahoře v modrém poli stříbrný pluh s kolem, dole polcený, v pravém červeném poli zlatá hlavice berly, levé pole pětkrát stříbrno-červeně dělené."
    },
    {
        obec: "Všehrdy",
        kod: 563471,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43001,
        latitude: 50.420047,
        longitude: 13.461343,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/V%C5%A1ehrdy_%28Chomutov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý kvádrovaný pruh široký třetinu délky listu a tři vodorovné pruhy, modrý, bílý a modrý, v poměru 10 : 1 : 1. V horním modrém pruhu žlutý obilný klas se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se zlatým kvádrovaným pravým bokem zlatý obilný klas se dvěma listy, pod ním stříbrné vlnité břevno."
    },
    {
        obec: "Všechlapy",
        kod: 532266,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25726,
        latitude: 49.779125,
        longitude: 14.914341
    },
    {
        obec: "Všechlapy",
        kod: 537985,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28802,
        latitude: 50.221181,
        longitude: 15.028509,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/V%C5%A1echlapy_NB_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý, v poměru 1 : 2 : 1. V červeném pruhu volná bílá hradba s prázdnou branou s otevřenými žlutými vraty, v ní bílý zvon. Z mezer v cimbuří vyrůstají tři žluté obilné klasy, střední se dvěma listy, krajní odkloněné s jedním listem. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě volná stříbrná hradba s prázdnou branou, s otevřenými zlatými vraty, v ní stříbrný zvon. Z mezer v cimbuří vyrůstají tři zlaté obilné klasy, střední se dvěma listy, krajní odkloněné s jedním listem."
    },
    {
        obec: "Všechovice",
        kod: 584169,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66603,
        latitude: 49.357215,
        longitude: 16.494182,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f5/V%C5%A1echovice_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým lvem s červenou zbrojí a jazykem, držícím žlutou berlu závitem k žerďovému okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrný lev s červenou zbrojí a jazykem držící zlatou berlu."
    },
    {
        obec: "Všechovice",
        kod: 521531,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75353,
        latitude: 49.462861,
        longitude: 17.758247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/Vsechovice_%28Prerov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, modrý a žlutý, v poměru 1:3:1. Uprostřed žlutý gryf s červenou zbrojí, vyrůstající z červené obrácené tatarské čepice s bílým lemem a pštrosím perem na vrcholu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý gryf s červenou zbrojí vyrůstající z obrácené červené tatarské čepice se stříbrným lemem a pštrosím perem na vrcholu."
    },
    {
        obec: "Všejany",
        kod: 536938,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29443,
        latitude: 50.25487,
        longitude: 14.953611,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/82/V%C5%A1ejany_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů střídavě modrých a bílých, v poměru 4 : 1 : 6 : 1 : 4. Z dolního bílého pruhu vyrůstají dva odkloněné lipové listy s plodenstvím uprostřed, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný most, z něhož vyrůstají dva odvrácené zlaté lipové listy s plodenstvím, v oblouku zlatý květ třezalky tečkované se zelenými kališními lístky."
    },
    {
        obec: "Všekary",
        kod: 566071,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34562,
        latitude: 49.603515,
        longitude: 13.042559,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/V%C5%A1ekary_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, černý a zelený, přes ně bílý vodorovný pruh vycházející z druhé desetiny žerďového okraje listu a červený vodorovný pruh vycházející z deváté desetiny žerďového okraje listu. Mezi vodorovnými pruhy žlutá hlavice berly s liliovitě zakončeným závitem k žerďovému okraji listu, převrácená bílá hornická kladívka na žlutých topůrkách a bílo-červeně polcená růže se žlutým semeníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V černo-zeleně polceném štítě vpravo zlatá hlavice berly s liliovitě zakončeným závitem, vlevo stříbrno-červeně polcená růže se zlatým semeníkem. Dole převrácená stříbrná hornická kladívka na zlatých topůrkách."
    },
    {
        obec: "Všelibice",
        kod: 564532,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46348,
        latitude: 50.64615,
        longitude: 14.95149,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Vselibice_CoA_CZ.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Uprostřed žluto-zeleně polcená větev, na každé straně se dvěma výhonky o třech listech, k žerdi lipovými a k vlajícímu okraji dubovými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě zlato-zeleně polcená větev, na každé straně se dvěma výhonky o třech listech, vpravo lipových a vlevo dubových."
    },
    {
        obec: "Všemina",
        kod: 585971,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76315,
        latitude: 49.2811,
        longitude: 17.876932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Vsemina_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, červený, bílý, modrý a bílý v poměru 10:3:4:3. V žerďové a střední části červeného pruhu dva zkřížené bílé meče hroty nahoru se žlutými jílci a záštitami. V modrém pruhu pět žlutých šesticípých hvězd. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře dva zkřížené meče se zlatými jílci a záštitami, dole modrý heroldský kříž s pěti (1,3,1) zlatými hvězdami."
    },
    {
        obec: "Všemyslice",
        kod: 545287,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37501,
        latitude: 49.217064,
        longitude: 14.35799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/15/Vsemyslice_znak.gif",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, bílý, modrý, bílý, modrý, bílý a červený v poměru 1:1:1:1:1:5. V červeném pruhu dvě bílá lekna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně dělený štít, nahoře dvě modrá vlnitá břevna, dole dvě stříbrná lekna."
    },
    {
        obec: "Všeň",
        kod: 577677,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51265,
        latitude: 50.557553,
        longitude: 15.105323,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/V%C5%A1e%C5%88_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým klínem s vrcholem na středu vlajícího okraje listu. Na bílý klín je položen žlutý žerďový klín sahající do středu listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se dvěma stříbrnými břevny zlato-černě polcený snížený vydutý hrot se stříbrným třešňovým květem se zlatým semeníkem na vrcholu."
    },
    {
        obec: "Všenice",
        kod: 566799,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33824,
        latitude: 49.817992,
        longitude: 13.560771,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/V%C5%A1enice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří zelené karé nad stejně velkým modrým čtvercovým polem a dva vodorovné pruhy, modrý a zelený. V žerďovém modrém poli žluté říšské jablko se zeleným přepásáním, z něhož vyniká do karé bílý liliový kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-modře vlnitě dělený štít. V něm zlaté říšské jablko se zeleným přepásáním a stříbrným liliovým křížem."
    },
    {
        obec: "Všenory",
        kod: 539856,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25231,
        latitude: 49.928938,
        longitude: 14.304031,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Vsenory_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, modrý, černý a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-zlatě dělený štít, nahoře stříbrný vzlétající holub s červenou zbrojí, dole černá kančí hlava."
    },
    {
        obec: "Všeradice",
        kod: 531952,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26726,
        latitude: 49.873756,
        longitude: 14.104868,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/V%C5%A1eradice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červeno-bíle čtvrcený a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo vyrůstá ze zlaté palisádové hradby stříbrná věž se dvěma černými okny a červenou valbovou střechou se dvěma zlatými makovicemi, vlevo zlatá trnitá rozdvojená propletená větev."
    },
    {
        obec: "Všeruby",
        kod: 554456,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34507,
        latitude: 49.340829,
        longitude: 12.983339,
        coatOfArmsFlagDescription: "Žlutý list se vztyčeným medvědem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vztyčený medvěd přirozené barvy."
    },
    {
        obec: "Všeradov",
        kod: 572543,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.747799,
        longitude: 15.837299,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/47/V%C5%A1eradov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, žluto-bíle polcený, zelený, bílo-žlutě polcený a zelený, v poměru 1 : 1 : 6 : 1 : 1. Ve středním pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě dvě zvýšená stříbrná zúžená vlnitá břevna, nad nimi zlatá koruna, provázená dvěma stříbrnými květy lnu se zlatými středy, pod nimi zlaté mlýnské kolo."
    },
    {
        obec: "Všepadly",
        kod: 554448,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34543,
        latitude: 49.45098,
        longitude: 13.105384
    },
    {
        obec: "Všestary",
        kod: 571091,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50312,
        latitude: 50.255417,
        longitude: 15.760224,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/CoA_of_V%C5%A1estary_%28okres_Hradec_Kr%C3%A1lov%C3%A9%29.svg",
        coatOfArmsFlagDescription: "Modrý list s červeným žerďovým pruhem širokým jednu třetinu délky listu. Na rozhraní žerďové a střední části listu žlutý obilný klas podložený zkříženými bílými šavlemi ostřím dolů a hroty nahoru a provázený po stranách šesti (3,3) žlutými dělovými koulemi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý obilný klas podložený zkříženými stříbrnými šavlemi a provázený po stranách šesti (3,3) zlatými dělovými koulemi."
    },
    {
        obec: "Všeruby",
        kod: 559628,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33016,
        latitude: 49.841781,
        longitude: 13.229541,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 3 : 4 : 3. Uprostřed listu černé jelení paroží. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Všestary",
        kod: 539031,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25163,
        latitude: 49.958709,
        longitude: 14.68537,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/Coats_of_arms_V%C5%A1estary.jpeg",
        coatOfArmsFlagDescription: "Modrý list s polovinou bílého koně ve skoku se žlutou zbrojí a červeným jazykem, provázenou třemi žlutými mlýnskými koly, dvěma v žerďové a jedním ve vlající části. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě polovina stříbrného koně ve skoku se zlatou zbrojí a červeným jazykem, provázená třemi (2, 1) zlatými mlýnskými koly."
    },
    {
        obec: "Všestudy",
        kod: 563480,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43111,
        latitude: 50.457467,
        longitude: 13.507709,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/V%C5%A1estudy_%28CV%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, žluto-černě šachovaný (2 x 9) a modrý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě černo-zlatě šachovaný kůl provázený vpravo zlatým obilným klasem a vlevo korunovaným stříbrným dvouocasým lvem se zlatou zbrojí."
    },
    {
        obec: "Všetaty",
        kod: 535320,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27716,
        latitude: 50.281971,
        longitude: 14.593081,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/V%C5%A1etaty_ME_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů střídavě červeno-modře polcených a bílých, přeložených žlutým klínem vycházejícím z druhé až páté šestiny horního okraje listu a s vrcholem na dolním okraji listu. V klínu černá vlčí hlava s krkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V pětkrát děleném štítě zlatý klín s černou vlčí hlavou s krkem. Pravé pole dělené červeno-stříbrně, levé modro-stříbrně."
    },
    {
        obec: "Všestudy",
        kod: 535311,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27746,
        latitude: 50.290915,
        longitude: 14.342686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/V%C5%A1estudy_CoA.jpg"
    },
    {
        obec: "Všesulov",
        kod: 565130,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27034,
        latitude: 50.039761,
        longitude: 13.610986,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Coats_of_arms_V%C5%A1esulov.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a modrý, v poměru 7:1:7. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě vpravo vyniká půl ozbrojence v úplné plátové zbroji s postaveným mečem v pravici, vše stříbrné, vlevo vyniká stříbrno-červeně šachované křídlo."
    },
    {
        obec: "Všetaty",
        kod: 542598,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27021,
        latitude: 50.046201,
        longitude: 13.757751,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Vsetaty_znak.svg",
        coatOfArmsFlagDescription: "Červený list s bílou katovskou sekerou ostřím dolů a s hnědým ulomeným topůrkem směrem k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě položená stříbrná katovská sekera na ulomeném topůrku přirozené barvy."
    },
    {
        obec: "Vševily",
        kod: 598437,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26272,
        latitude: 49.565277,
        longitude: 13.882648
    },
    {
        obec: "Výčapy",
        kod: 591939,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67401,
        latitude: 49.143644,
        longitude: 15.877648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Vycapy_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a vlnkovitý modrý, v poměru 3:1. Z modrého pruhu vyrůstají dva přivrácení bílí čápi s černými křídly a červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě dva k sobě obrácení čápi přirozených barev, vyrůstající z modré vlnité paty štítu."
    },
    {
        obec: "Vydří",
        kod: 562629,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37802,
        latitude: 49.09253,
        longitude: 14.945728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Vyd%C5%99%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě žlutých a vlnitých modrých se dvěma vrcholy a třemi prohlubněmi, v poměru 12 : 1 : 1 : 1 : 9. Z prvního modrého pruhu vyrůstá k žerďovému okraji nad modrou růží se žlutým semeníkem černá vydra ve skoku s modrým okounem v tlamě. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě vlevo dva modré vlnité zúžené kůly. Z pravého kůlu vyrůstá nad modrou růží se zlatým semeníkem černá vydra ve skoku s modrým okounem v tlamě."
    },
    {
        obec: "Vykáň",
        kod: 537993,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28915,
        latitude: 50.119966,
        longitude: 14.816787,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/61/Vyk%C3%A1%C5%88_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, žlutý s hnědým medvědem ve skoku s červenou zbrojí a červený se žlutým obilným klasem v žerďové polovině pruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-červeně vlevo polceném štítě vpravo medvěd ve skoku přirozené barvy s červenou zbrojí, vlevo zlatý obilný klas."
    },
    {
        obec: "Vyklantice",
        kod: 549134,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39427,
        latitude: 49.554788,
        longitude: 15.040579,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Vyklantice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a bílý, v poměru 1 : 3 : 1. V červeném pruhu žlutá koule postrkaná třemi (2, 1) bílými pštrosími pery, provázená nahoře mezi pery bílým přirozeným květem lilie a po obou stranách bílým šestilistým květem se žlutým středem na žlutém stonku se třemi listy. Poměr šířky k délce je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zlatá koule postrkaná třemi (2, 1) stříbrnými pštrosími pery, nahoře stříbrná přirozená lilie, po stranách po jednom stříbrném šestilistém květu se zlatým středem na zlatém prohnutém stonku se třemi listy, vyrůstajícím z okraje štítu."
    },
    {
        obec: "Výkleky",
        kod: 547450,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75125,
        latitude: 49.554998,
        longitude: 17.475728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/67/Coat_of_arms_of_V%C3%BDkleky.svg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, polcený červeno-modrý, žlutý, černý, žlutý a černý, v poměru 4:1:1:1. Z horního žlutého pruhu vyrůstají tři bílé přirozené lilie, krajní odvrácené s jedním listem na stonku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlaté srdce, z něhož vyrůstají tři stříbrné přirozené lilie, krajní odvrácené s jedním listem na stonku. V srdci kosmý mlátek šikmo podložený špičákem, obojí černé."
    },
    {
        obec: "Výprachtice",
        kod: 581178,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56134,
        latitude: 49.987302,
        longitude: 16.663918,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/ec/V%C3%BDprachtice.svg",
        coatOfArmsFlagDescription: "Zelený list, se třemi odkloněnými, dole spojenými žlutými lipovými listy, v horní části mezi listy dvě bílé čtyřcípé hvězdy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě tři odkloněné, dole spojené zlaté lipové listy, provázené nahoře dvěma stříbrnými čtyřhrotými hvězdami."
    },
    {
        obec: "Výrava",
        kod: 571105,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50303,
        latitude: 50.269739,
        longitude: 15.979052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/V%C3%BDrava_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý se žlutým čtvercovým polem širokým dvě třetiny šířky listu se středem v první třetině délky listu. Žluté pole ve žlutém pruhu modře lemováno. V poli modrý klín vycházející z dolního okraje pole s vrcholem na horním okraji pole. V klínu z dolního okraje vyrůstají tři žluté obilné klasy, prostřední vyšší. Ve žlutých polích čelně postavená modrá radlice. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý hrot se třemi vyrůstajícími zlatými obilnými klasy, prostřední vyšší, provázený dvěma čelně postavenými modrými radlicemi."
    },
    {
        obec: "Výrov",
        kod: 566969,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 49.961857,
        longitude: 13.462671
    },
    {
        obec: "Výrovice",
        kod: 595136,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67134,
        latitude: 48.928356,
        longitude: 16.120391,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ab/V%C3%BDrovice_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým vydutým žerďovým klínem s vrcholem na vlajícím okraji. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "V modrém štítě stříbrný vydutý klín se sedícím výrem přirozené barvy. Klín je provázen dvěma zlatými proutěnými košíky s uchem."
    },
    {
        obec: "Vyskeř",
        kod: 577685,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51264,
        latitude: 50.529406,
        longitude: 15.15805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Vyske%C5%99_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zubatý zelený, v poměru 1 : 3. Ze čtvrté a desáté třináctiny dolního okraje listu vyniká prázdný gotický oblouk, v něm lilie, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod stříbrnou cimbuřovou hlavou ve vyrůstajícím prázdném gotickém oblouku lilie, vše stříbrné."
    },
    {
        obec: "Vyskytná",
        kod: 549142,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39405,
        latitude: 49.428265,
        longitude: 15.365624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Vyskytn%C3%A1_Pelh%C5%99imov-znak.jpg",
        coatOfArmsFlagDescription: "Modrý list; v žerďové a střední části červeno-bíle dělená střela hrotem k žerdi, ve střední a vlající části bílá lilie bez středního horního listu obrácená k žerdi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie bez středního horního listu, převýšená stříbrno-červeně polcenou střelou."
    },
    {
        obec: "Vyskytná nad Jihlavou",
        kod: 588172,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58841,
        latitude: 49.419455,
        longitude: 15.508243,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Vyskytn%C3%A1_nad_Jihlavou_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří svislý žerďový modrý pruh široký polovinu délky listu se vztyčeným žlutým roštem a tři vodorovné pruhy, černý, bílý a červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít, v pravém modrém poli vztyčený zlatý rošt, levé pole černo-stříbrno-červeně dělené."
    },
    {
        obec: "Výsluní",
        kod: 563498,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43183,
        latitude: 50.466591,
        longitude: 13.237511,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c3/V%C3%BDslun%C3%AD_CoA.png"
    },
    {
        obec: "Vysočany",
        kod: 582701,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67913,
        latitude: 49.432008,
        longitude: 16.811126,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Vyso%C4%8Dany_%28Blansko%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list, uprostřed vykořeněný kmen přecházející v patriarší kříž, dole vyrůstají do stran dvojice lipových listů na stoncích, vše bílé. V žerďové a vlající části žlutá šesticípá hvězda. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vykořeněný kmen přecházející v patriarší kříž, dole vyrůstají do stran dvojice lipových listů na stoncích, vše stříbrné. V rozích dvě zlaté hvězdy."
    },
    {
        obec: "Vysočany",
        kod: 595144,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.941156,
        longitude: 15.689792,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Vyso%C4%8Dany_ZN_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3 : 2 : 3, přeložené uprostřed svislým červeným pruhem širokým tři sedminy délky listu s kosmo položenou žlutou putýnkou s vytékající bílou vodou protékající šikmo položenou žlutou korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně polcený štít. Vpravo stříbrný smrk vyrůstající ze zlatého vrchu, vlevo kosmo položená zlatá putýnka s vytékající stříbrnou vodou protékající šikmo položenou zlatou korunou."
    },
    {
        obec: "Vysočina",
        kod: 572551,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53901,
        latitude: 49.76477,
        longitude: 15.829087,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Vyso%C4%8Dina%2C_znak.jpg",
        coatOfArmsFlagDescription: "Modro-zeleno čtvrcený list. Uprostřed bílý mlýn se dvěma černými okny, s hnědou dřevěnou střechou a kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-zeleně čtvrceném štítě stříbrný mlýn se dvěma černými okny, s dřevěnou střechou a kolem přirozené barvy."
    },
    {
        obec: "Vysoká",
        kod: 530654,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.562373,
        longitude: 15.622295,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/69/Coat_of_arms_of_Vysok%C3%A1_HB.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným horním žerďovým trojúhelníkem dosahujícím do poloviny horního okraje listu. V trojúhelníku bílá holubice se žlutou zbrojí a ratolestí v zobáku. Z dvanácté až jednadvacáté čtyřiadvacetiny dolního okraje listu vyniká zelené návrší vysoké desetinu šířky listu, z něhož vyrůstá zelená jedle s černým kmenem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zlatý štít se zelenou hlavou, v ní stříbrná holubice se zlatou zbrojí a ratolestí v zobáku. Ve štítě zelená jedle s černým kmenem na zeleném návrší."
    },
    {
        obec: "Vysoká",
        kod: 597970,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79399,
        latitude: 50.26149,
        longitude: 17.590344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Vysok%C3%A1_%28okres_Brunt%C3%A1l%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým koněm držícím pravou nohou šikmo žlutou berlu závitem k žerdi a s bílým trojúhelníkem s vrcholy ve středu vlajícího okraje a ve třech čtvrtinách horního a dolního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě nad položenou stříbrnou radlicí stříbrný kůň držící pravou nohou šikmo zlatou berlu."
    },
    {
        obec: "Vysoká",
        kod: 578991,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56943,
        latitude: 49.663714,
        longitude: 16.810197
    },
    {
        obec: "Vysoká",
        kod: 535338,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27724,
        latitude: 50.412655,
        longitude: 14.538285,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Vysok%C3%A1_%28okres_M%C4%9Bln%C3%ADk%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s pěti do vějíře svázanými žlutými obilnými klasy provázenými žlutým kalichem a knížecí čepici s křížkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pět do vějíře svázaných zlatých obilných klasů provázených vpravo zlatým kalichem a vlevo knížecí čepicí s křížkem."
    },
    {
        obec: "Vysoká Lhota",
        kod: 561771,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.416842,
        longitude: 15.042363
    },
    {
        obec: "Vysoká Libyně",
        kod: 530280,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33141,
        latitude: 50.021988,
        longitude: 13.450793,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/66/Vysok%C3%A1_Libyn%C4%9B_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 3 : 2, horní modrý a dolní bílo-červeně-žlutě polcený v poměru 1 : 1 : 2. Do modrého pruhu vyrůstá bílý korunovaný dvouocasý lev se žlutou zbrojí a jazykem. Na styku bílého a červeného pole žlutá koruna, ve žlutém černé jelení paroží. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. V horním modrém poli vyrůstající stříbrný korunovaný dvouocasý lev se zlatou zbrojí a jazykem. Dolní pole polcené, v pravém stříbrno-červeně polceném poli zlatá koruna, v levém zlatém poli černé jelení paroží."
    },
    {
        obec: "Vysoká nad Labem",
        kod: 571113,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50331,
        latitude: 50.153472,
        longitude: 15.824842,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/14/Znak_Vysok%C3%A9_nad_Labem.svg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem, vycházejícím z první třetiny dolního okraje listu do třetí třetiny horního okraje listu, na zelené žerďové a modré vlající pole. V bílém pruhu šikmý černý pruh vycházející z druhé a třetí dvanáctiny dolního okraje do desáté a jedenácté dvanáctiny horního okraje listu. Přes šikmé pruhy kosmo žlutá berla závitem k žerdi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - modře šikmým vlnitým černým, stříbrně lemovaným břevnem děleném štítě kosmá zlatá berla."
    },
    {
        obec: "Vysoká Pec",
        kod: 578029,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36221,
        latitude: 50.348275,
        longitude: 12.696743,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Vp_znak.gif",
        coatOfArmsFlagDescription: "Zelený list s mlátkem křížem přeloženým želízkem a podložený vztyčenými rýžovacími hráběmi, převýšenými korunou s pěti bílými perlami, jinak vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě mlátek křížem přeložený želízkem a podložený vztyčenými rýžovacími hráběmi, převýšenými korunou s pěti stříbrnými perlami, jinak vše zlaté."
    },
    {
        obec: "Vysoká Pec",
        kod: 563501,
        okres: "Chomutov",
        kod_okres: "CZ0422",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43159,
        latitude: 50.520381,
        longitude: 13.470366,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Vysok%C3%A1_Pec_%28okres_Chomutov%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým karé, nad stejně velkým červeno-bíle děleným polem. V karé kosmá černá orlice se žlutou zbrojí, červeným jazykem a žlutým, jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo nad sníženým červeným břevnem kosmá černá orlice se zlatou zbrojí a červeným jazykem a zlatým, jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Vysoká Srbská",
        kod: 574635,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54931,
        latitude: 50.487806,
        longitude: 16.22766,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/CoA_of_Vysok%C3%A1_Srbsk%C3%A1.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, oboustranně zubatý bílý a modrý, v poměru 1:2:1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Bílý pruh má tři čtvercové zuby a čtyři stejné mezery. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě pod červenou cimbuřovou hlavou dva přivrácené modré srpy s černými rukojeťmi, mezi nimi červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Vysoká u Příbramě",
        kod: 541583,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26242,
        latitude: 49.63432,
        longitude: 13.948497,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/38/Vysok%C3%A1_u_P%C5%99%C3%ADbram%C4%9B_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, zelený, bílý, červený, bílý a zelený, v poměru 2 : 1 : 3 : 1 : 2. V červeném pruhu žlutá lyra. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě červený kotouč se stříbrným lemem, z něhož vzhůru vyrůstají dvě přivrácená stříbrná lekna. V kotouči zlatá lyra."
    },
    {
        obec: "Vysoké",
        kod: 588334,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.5819,
        longitude: 15.965315,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/44/Vysok%C3%A9_CoA.jpg",
        coatOfArmsFlagDescription: "Bílý list se zelenou lípou s černým kmenem a s vlajícím zeleným pruhem širokým jednu čtvrtinu délky listu. V něm pod sebou tři bílé pětilisté květy se zelenými středy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě lípa přirozených barev vyrůstající ze zeleného návrší se třemi stříbrnými pětilistými květy se zelenými středy do oblouku nahoru."
    },
    {
        obec: "Vysoké Chvojno",
        kod: 576000,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53321,
        latitude: 50.109088,
        longitude: 15.973593,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Vysok%C3%A9_Chvojno_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový bílý pruh široký čtvrtinu délky listu a červené pole s bílým lvem se žlutou zbrojí a jazykem. V bílém pruhu vodorovně pod sebou tři zelené větévky jedlového chvojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou hlavou se třemi zelenými větévkami jedlového chvojí stříbrný lev se zlatou zbrojí a jazykem."
    },
    {
        obec: "Vysoké Mýto",
        kod: 581186,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.954364,
        longitude: 16.164268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Vysok%C3%A9_M%C3%BDto_-_znak.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy - horní zelený, dolní červený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Vysoké nad Jizerou",
        kod: 577693,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51211,
        latitude: 50.685688,
        longitude: 15.401633,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Vysok%C3%A9_nad_Jizerou%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, světle modrý a zelený, v poměru 7:3. Uprostřed k žerdi obrácený vousatý muž v červeném černě přepásaném kabátci a žlutých nohavicích a v černých vysokých botách, s červeným kloboukem s pštrosím pérem, šikmo probodávající žlutým kopím vzpřímeného k vlajícímu okraji obráceného černého medvěda s červeným jazykem před třemi zelenými listnatými stromy s hnědými kmeny. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku doprava obrácený vousatý muž v červeném černě přepásaném kabátci a zlatých nohavicích v černých vysokých botách, s červeným kloboukem s pštrosím pérem, šikmo probodávající zlatým kopím vzpřímeného doleva obráceného černého medvěda s červeným jazykem před třemi listnatými stromy přirozené barvy."
    },
    {
        obec: "Vysoké Pole",
        kod: 585980,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76325,
        latitude: 49.177519,
        longitude: 17.93744,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Vysok%C3%A9_Pole_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, červený, modrý a bílý, v poměru 1:2:2:1. Uprostřed bílá lilie mezi dvěma zkříženými žlutými obilnými klasy, každý se třemi listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě stříbrné trojvrší s červenou růží se zlatým semeníkem a zelenými kališními lístky. Z prostředního vrchu vyrůstají dva odkloněné zlaté obilné klasy, mezi nimi stříbrná lilie."
    },
    {
        obec: "Vysoké Popovice",
        kod: 584177,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.183126,
        longitude: 16.284964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Vysok%C3%A9_Popovice_znak.jpg",
        coatOfArmsFlagDescription: "Bílý list, na dolním okraji listu zelené návrší vysoké tři sedminy šířky listu s bílým pětilistým květem se žlutým středem, nad návrším černý klobouk se dvěma svěšenými zlatými šňůrami zakončenými třapci. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad vysokým zeleným návrším se stříbrným pětilistým květem se zlatým středem černý klobouk se dvěma svěšenými zlatými šňůrami zakončenými třapci."
    },
    {
        obec: "Vysoké Studnice",
        kod: 588181,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58821,
        latitude: 49.394112,
        longitude: 15.73023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Vysok%C3%A9_Studnice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří šest vodorovných pruhů, červený a střídavě bílé a modré, v poměru 5:1:1:1:1:1. Do žerďové poloviny červeného pruhu vyrůstá žlutý kůň s bílými zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře děleném štítě nahoře vyrůstající zlatý kůň, dole tři zkřížené stříbrné palcáty."
    },
    {
        obec: "Vysoké Veselí",
        kod: 573809,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50703,
        latitude: 50.331055,
        longitude: 15.436421,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Vysok%C3%A9_Vesel%C3%AD_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Modrý list s volnou kvádrovanou kamennou světle hnědou věží se dvěma poschodími. Dole věž prolomena černou gotickou branou, v ní zavěšen na žlutém řetězu polcený štítek, vpravo žluto-černě šachovaný a vlevo žlutý, nad branou ochoz s cimbuřím o pěti zubech. Nad ochozem pod sebou tři černá gotická okna s bílými mřížemi. Věž zakončena cimbuřím o sedmi zubech, nad ním vedle sebe tři černé stanové střechy, každá s bílou korouhvičkou ocasem k vlajícímu okraji listu na černé žerdi. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Vysokov",
        kod: 574643,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54912,
        latitude: 50.403868,
        longitude: 16.116742,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Coat_of_arms_of_Vysokov.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zelený, v poměru 7:4. Ve žlutém pruhu černý vykračující kohout s červenou zbrojí, v zeleném pruhu zkřížené bílé šavle se žlutými jílci, hroty a ostřím nahoru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném návrší se zkříženými vztyčenými stříbrnými šavlemi se zlatými jílci vykračující černý kohout s červenou zbrojí."
    },
    {
        obec: "Vysoký Chlumec",
        kod: 541591,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26252,
        latitude: 49.617757,
        longitude: 14.389867,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Coats_of_Arms_of_Vysoky_Chlumec.jpeg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, bílý, červený, bílý a zelený, v poměru 3 : 1 : 4 : 4. V žerďovém pruhu vztyčená zelená habrová větev s pěti listy, ve středním bílém pruhu černá orlice se žlutou zbrojí a jetelovitě zakončeným perizoniem, v zeleném pruhu zkřížené žluté limpy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít dělený zúženým červeným břevnem, nahoře stříbrno - zeleně polcený, dole stříbrný. Vpravo černá orlice se zlatou zbrojí a jetelovitě zakončeným perizoniem, vlevo zkřížené zlaté limpy, dole položená zelená habrová větev s pěti listy."
    },
    {
        obec: "Vysoký Újezd",
        kod: 548677,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51771,
        latitude: 50.24034,
        longitude: 16.020182,
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zelený, v poměru 3 : 1 : 8. V červeném pruhu u horního okraje žlutá koruna, v zeleném poli bílá vykračující ovce provázená nad hřbetem dvěma svěšenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně sníženým stříbrným vlnitým břevnem děleném štítě nahoře stříbrná vykračující ovce provázená nad hřbetem dvěma svěšenými zlatými lipovými listy, dole zlatá koruna."
    },
    {
        obec: "Vysoký Újezd",
        kod: 513482,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25744,
        latitude: 49.813385,
        longitude: 14.476422,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Vysok%C3%BD_%C3%9Ajezd_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, a bíle lemovaný červený kříž. Ramena kříže jsou široká jednu osminu šířky listu, lem je široký jednu šestnáctinu šířky listu. V horním žerďovém poli bílá devíticípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zvýšeném zeleném návrší stříbrný kostel s červenými střechami a sanktusníkem a s černými okny. Věž a sanktusník zakončeny černými křížky. Nad lodí kostela devítihrotá hvězda, v návrší dvě vztyčené odkloněné lipové ratolesti, každá o čtyřech listech, vše stříbrné."
    },
    {
        obec: "Vysoký Újezd",
        kod: 531961,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26716,
        latitude: 49.992234,
        longitude: 14.206303,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Vysok%C3%BD_%C3%9Ajezd_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a zelený, v poměru 3 : 1 : 8. V červeném pruhu u horního okraje žlutá koruna, v zeleném poli bílá vykračující ovce provázená nad hřbetem dvěma svěšenými žlutými lipovými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno - červeně sníženým stříbrným vlnitým břevnem děleném štítě nahoře stříbrná vykračující ovce provázená nad hřbetem dvěma svěšenými zlatými lipovými listy, dole zlatá koruna."
    },
    {
        obec: "Vystrčenovice",
        kod: 588199,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.151246,
        longitude: 15.53056
    },
    {
        obec: "Vystrkov",
        kod: 561797,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39601,
        latitude: 49.51978,
        longitude: 15.348901,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Coat_of_arms_of_Vystrkov.jpg",
        coatOfArmsFlagDescription: "Modrý list, uprostřed vztyčený lipový list s plodenstvím nad zkříženými kopími, dole přeloženými gotickým dvojklíčem s tlapatým křížkem v držadle, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčený lipový list s plodenstvím nad zkříženými kopími, dole přeloženými gotickým dvojklíčem s tlapatým křížkem v držadle, vše zlaté."
    },
    {
        obec: "Vyšehněvice",
        kod: 576018,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53341,
        latitude: 50.102402,
        longitude: 15.583741,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Coat_of_arms_of_Vy%C5%A1ehn%C4%9Bvice.svg",
        coatOfArmsFlagDescription: "Bílý list s vykračujícím černým kohoutem se žlutou zbrojí, červeným hřebenem a lalokem, a zeleným dolním vlajícím trojúhelníkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném vrchu se stříbrným zvonem vykračující černý kohout se zlatou zbrojí, červeným hřebenem a lalokem."
    },
    {
        obec: "Vyšehoří",
        kod: 570095,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.93371,
        longitude: 16.87848,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Vy%C5%A1eho%C5%99%C3%AD_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět šikmých střídavě modrých a bílých pruhů, v poměru 2:1:2:1:2. V horním rohu a dolním cípu po jedné hvězdě, v prostředním pruhu pět hvězd, všechny žluté a osmicípé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou třemi obrácenými oblouky oddělenou hlavou vztyčená doleva obrácená stříbrná radlice provázená vpravo třemi a vlevo čtyřmi zlatými osmihrotými hvězdami."
    },
    {
        obec: "Vyšehořovice",
        kod: 539040,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25087,
        latitude: 50.117881,
        longitude: 14.773799,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Vy%C5%A1eho%C5%99ovice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a žlutý. V červeném bílá kozlí hlava s krkem a žlutým jazykem, ve žlutém hnědý vysoký vykořeněný dubový pařez se zelenými listy a žaludy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zlatě polceném štítě nad sníženou stříbrnou kvádrovanou hradbou vpravo kozlí hlava s krkem a se zlatým jazykem, vlevo vysoký vykořeněný dubový pařez s listy a žaludy přirozené barvy."
    },
    {
        obec: "Vyškov",
        kod: 592889,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68201,
        latitude: 49.277552,
        longitude: 16.996199,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Znak_m%C4%9Bsta_Vy%C5%A1kova.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, bílý a červený, v poměru 1:2:1. V bílém pruhu červená kvádrovaná věž s cimbuřím se čtyřmi zuby a prázdným čtyřlistým oknem, prázdnou gotickou branou s černou zdviženou mříží. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Výškov",
        kod: 566951,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.394975,
        longitude: 13.670442,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8b/V%C3%BD%C5%A1kov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, bílý a modrý, v poměru 1 : 1 : 2. V modrém žerďovém pruhu žlutá berla závitem k žerdi a bílým sudariem. V bílém pruhu a žerďové části modrého vlajícího pruhu obilný klas se dvěma listy, v bílém zelený, v modrém žlutý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný kůl se zeleným obilným klasem, provázený vpravo zlatou berlou se stříbrným sudariem, vlevo zlatým obilným klasem; oba klasy se dvěma odkloněnými listy."
    },
    {
        obec: "Vyškovec",
        kod: 592838,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68774,
        latitude: 48.94083,
        longitude: 17.853445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/Vy%C5%A1kovec%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 4 : 1. Na zeleném pruhu žlutá koruna, z níž do vějíře vyrůstá pět bílých liliovitých květů na stoncích. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší zlatá koruna, z níž do vějíře vyrůstá pět stříbrných liliovitých květů na stoncích."
    },
    {
        obec: "Vyšní Lhoty",
        kod: 552682,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73951,
        latitude: 49.635907,
        longitude: 18.457023,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Vysni_Lhoty_znak.svg",
        coatOfArmsFlagDescription: "Zelený list s bílým kráčejícím býkem. Pod ním vlnitý bílý pruh vzdálený od dolního okraje 1/8 šířky listu a široký 1/8 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný kráčející býk, pod ním stříbrné vlnité břevno."
    },
    {
        obec: "Výšovice",
        kod: 590215,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79809,
        latitude: 49.416444,
        longitude: 17.13914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/78/CoA_of_V%C3%BD%C5%A1ovice.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý, a vodorovný červený pruh na dolním okraji, široký jednu sedminu šířky listu. Uprostřed polcená lilie provázená dvěma orobinci, každý se dvěma listy, vše opačných barev. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě nad červenou patou s doleva položeným stříbrným roštem lilie provázená dvěma orobinci, každý se dvěma listy, vše opačných barev."
    },
    {
        obec: "Vyšší Brod",
        kod: 545848,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38273,
        latitude: 48.616082,
        longitude: 14.311919,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Vy%C5%A1%C5%A1%C3%AD_Brod_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený v poměru 1:2:1. V žerďové části bílého pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Výžerky",
        kod: 564796,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.945155,
        longitude: 14.883469,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f2/V%C3%BD%C5%BEerky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří červený žerďový klín s vrcholem ve středu listu, tři krokve, bílá, zelená a bílá, vycházející z první až třetí šestiny horního a dolního okraje listu, poslední s vrcholem na vlajícím okraji listu a modré horní a dolní vlající pole. V klínu korunovaná bílá lví hlava s hřívou se žlutým jazykem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrnou obrácenou krokví se třemi (2, 1) vztyčenými zelenými lipovými listy červeno-modře dělený štít. Nahoře korunovaná stříbrná hlava lva s hřívou se zlatým jazykem."
    },
    {
        obec: "Vyžice",
        kod: 547841,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53803,
        latitude: 49.923275,
        longitude: 15.621171
    },
    {
        obec: "Vyžlovka",
        kod: 533904,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.984752,
        longitude: 14.789195,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Vy%C5%BElovka_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, modrý, bílý, zelený a bílý, v poměru 5 : 1 : 1 : 1. V modrém pruhu dvojice vykračujících bílých loveckých psů s červeným jazykem a obojkem s kroužkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně vlnitě děleném štítě nahoře dvojice vykračujících stříbrných loveckých psů s červeným jazykem a obojkem s kroužkem, dole pod zúženým modrým vlnitým břevnem tři vztyčené zelené bukové listy, prostřední větší."
    },
    {
        obec: "Xaverov",
        kod: 571415,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28506,
        latitude: 49.842785,
        longitude: 14.884454,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/76/Xaverov_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený pruh široký třetinu délky listu se vztyčenou žlutou lipovou ratolestí, střídavě se třemi listy a dvěma plodenstvími, a modré pole. V něm bílá výklenková kaple s červenou střechou a s červeným planoucím srdcem ve výklenku. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítu na zeleném trávníku s položenou zlatou lipovou ratolestí, střídavě se třemi listy a dvěma plodenstvími, stříbrná výklenková kaple s červenou střechou a červeným planoucím srdcem ve výklenku."
    },
    {
        obec: "Zábeštní Lhota",
        kod: 547514,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75127,
        latitude: 49.511965,
        longitude: 17.431217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Coat_of_arms_of_Z%C3%A1be%C5%A1tn%C3%AD_Lhota.jpg",
        coatOfArmsFlagDescription: "Kosmo a šikmo čtvrcený list. V žerďovém červeném poli bílá psí hlava se žlutým jazykem a obojkem, v horním modrém poli bílá lilie, ve vlajícím zeleném poli bílá sekera se žlutým topůrkem provázená k žerďovému okraji listu bílou radlicí ostřím k žerdi, v dolním černém poli žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Štít kosmo čtvrcený. V horním modrém poli stříbrná lilie, v pravém červeném poli stříbrná psí hlava se zlatým jazykem a obojkem, v levém zeleném poli stříbrná sekera se zlatým topůrkem, vpravo provázená stříbrnou radlicí, v dolním černém poli zlaté mlýnské kolo."
    },
    {
        obec: "Záblatí",
        kod: 508683,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37901,
        latitude: 49.107283,
        longitude: 14.686266
    },
    {
        obec: "Záblatí",
        kod: 550680,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38433,
        latitude: 48.998654,
        longitude: 13.931392,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Z%C3%A1blat%C3%AD_PT_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list, v horním rohu kosmo hnědá hořící přirozená ostrev se čtyřmi suky šikmo podložená bílou sekerou na hnědém topůrku. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Záblatí",
        kod: 597112,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59453,
        latitude: 49.320849,
        longitude: 16.166438,
        coatOfArmsFlagDescription: "Zelený list s bílým vlnitým pruhem širokým osminu šířky listu, přeloženým radlicí a krojidlem, obojí hrotem k hornímu okraji listu, ostřím k vlajícímu okraji listu a žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě se stříbrným vlnitým břevnem radlice a krojidlo, obojí zlaté a vztyčené."
    },
    {
        obec: "Záborná",
        kod: 588202,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.486751,
        longitude: 15.756929,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Z%C3%A1born%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, zelený, žlutý a červený, v poměru 4 : 1 : 1 : 1 : 1. V žerďové polovině horního zeleného pruhu žlutá kráčející lvice s červenou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad zlatým návrším se svěšenou červenou borovou šiškou kráčející zlatá lvice s červenou zbrojí, pod ní položené stříbrné krojidlo ostřím vzhůru."
    },
    {
        obec: "Záboří",
        kod: 545317,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37384,
        latitude: 48.990054,
        longitude: 14.26727,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Z%C3%A1bo%C5%99%C3%AD_%28CB%29%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený. V červeném vztyčená bílá borová šiška, v zeleném dva odvrácené žluté listy, lipový s listenem o dvou plodech a bukový. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně do oblouku děleném štítě nahoře vztyčená stříbrná borová šiška, dole dva odvrácené listy, vpravo lipový s listenem o dvou plodech, vlevo bukový, vše zlaté."
    },
    {
        obec: "Záboří",
        kod: 551988,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38734,
        latitude: 49.378898,
        longitude: 13.827052,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/65/Z%C3%A1bo%C5%99%C3%AD_u_Blatn%C3%A9_znak.png",
        coatOfArmsDescription: "Polcený štít, v pravém zeleném poli kosmo postavený stříbrný gotický klíč, šikmo podložený vztyčeným stříbrným mečem se zlatým jílcem, levé pole červeno-stříbrně čtvrcené."
    },
    {
        obec: "Záboří nad Labem",
        kod: 534595,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28574,
        latitude: 50.025315,
        longitude: 15.349976,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Zabori_nad_Labem_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený v poměru 1:1:4:1:1. V modrém pruhu žlutý žalud mezi dvěma přivrácenými bílými hlavami psů - věžníků s červenými jazyky a žlutými trnovými obojky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře děleném štítě nahoře vykořeněný zelený dub, dole zlatý žalud provázený přivrácenými stříbrnými hlavami psů - věžníků s červenými jazyky a zlatými trnovými obojky."
    },
    {
        obec: "Zábrdí",
        kod: 537195,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.028068,
        longitude: 13.939516,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Z%C3%A1brd%C3%AD_CoA.png"
    },
    {
        obec: "Zábrodí",
        kod: 574651,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54941,
        latitude: 50.459652,
        longitude: 16.11255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Z%C3%A1brod%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým žerďovým pruhem širokým jednu čtvrtinu délky listu. V bílém pruhu tři modré květy lnu, v modrém poli bílý vykračující kohout s červenou zbrojí a ocasem, provázený u laloku bílým půlměsícem cípy k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod stříbrnou hlavou se třemi modrými květy lnu stříbrný vykračující kohout s červenou zbrojí a ocasem provázený vpravo stříbrným ubývajícím půlměsícem."
    },
    {
        obec: "Zabrušany",
        kod: 567868,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41771,
        latitude: 50.605057,
        longitude: 13.787596,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Zabru%C5%A1any_-_znak.jpg",
        coatOfArmsFlagDescription: "Bíle lemovaný červený list s bílým fénixem. Lem je široký jednu desetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným lemem vzlétá ze zlatých plamenů stříbrný Fenix se zlatou zbrojí."
    },
    {
        obec: "Zábřeh",
        kod: 541354,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.8827,
        longitude: 16.872332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Z%C3%A1breh_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří dva vodorovné pruhy, horní bílý a dolní modrý. V bílém je pět šesticípých červených hvězd položených shodně do oblouku s cípem směřujícím vzhůru. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Zábřezí-Řečice",
        kod: 548839,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.411359,
        longitude: 15.735635
    },
    {
        obec: "Zadní Chodov",
        kod: 541362,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34815,
        latitude: 49.890602,
        longitude: 12.654668,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Z_chod_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený se zkříženými bílými čakany a bílý s kosmou černou holinkou s poutkem nad zeleným trojvrším vycházejícím z dolního okraje bílého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře dva zkřížené stříbrné čakany, dole nad zeleným trojvrším kosmá černá holinka s poutkem."
    },
    {
        obec: "Zadní Střítež",
        kod: 563927,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.494184,
        longitude: 14.916811
    },
    {
        obec: "Zadní Třebaň",
        kod: 531979,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26729,
        latitude: 49.918124,
        longitude: 14.208189,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Zt_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy zelený, modrý, bílý a modrý v poměru 3:1:1:1. V horním rohu žlutý košík provázený dvěma bílými ke středu vzhůru plovoucími rybami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad dvěma modrými vlnitými břevny zelené trojvrší, v něm zlatý košík provázený dvěma stříbrnými, ke středu vzhůru plovoucími rybami."
    },
    {
        obec: "Zadní Vydří",
        kod: 587231,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.134444,
        longitude: 15.417199
    },
    {
        obec: "Zadní Zhořec",
        kod: 597121,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.431175,
        longitude: 15.939309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Zadn%C3%AD_Zho%C5%99ec_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený, v poměru 2:1. V žerďové polovině modrého pruhu žlutý pluh s koly a bílou radlicí, obrácený k vlajícímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším doleva obrácený zlatý pluh s koly a stříbrnou radlicí."
    },
    {
        obec: "Zádolí",
        kod: 581194,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56601,
        latitude: 49.903246,
        longitude: 16.127162
    },
    {
        obec: "Zádub-Závišín",
        kod: 539431,
        okres: "Cheb",
        kod_okres: "CZ0411",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 35301,
        latitude: 49.971876,
        longitude: 12.744072,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Zadub-Zavisin_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným žerďovým zvlněným pruhem se dvěma vrcholy a třemi prohlubněmi, široký jednu šestinu délky listu. Ve žlutém poli vykořeněný strom s černým kmenem a zelenými listy, na žerďové straně lipovými a na vlající straně dubovými, pod stromem černé jelení paroží. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě pod zelenou vlnitou hlavou černý vykořeněný strom se zelenými listy, vpravo lipovými, vlevo dubovými, pod ním černé jelení paroží."
    },
    {
        obec: "Zahájí",
        kod: 536016,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37348,
        latitude: 49.089687,
        longitude: 14.370905,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Zahaji_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, zelený pilovitý, žlutý a zelený, v poměru 13:3:2:2. V bílém pruhu modrá lilie mezi dvěma přivrácenými rozkřídlenými černými havrany se žlutou zbrojí. Pilovitý pruh má devět celých a dva poloviční zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně pilovitě děleném štítě nahoře modrá lilie mezi dvěma do středu obrácenými rozkřídlenými černými havrany se zlatou zbrojí, dole kosmý mlátek šikmo přeložený kladívkem."
    },
    {
        obec: "Zádveřice-Raková",
        kod: 585998,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76312,
        latitude: 49.216644,
        longitude: 17.805635,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/CoA_of_Z%C3%A1dve%C5%99ice-Rakov%C3%A1.svg",
        coatOfArmsFlagDescription: "Šikmo dělený list, horní žerďová část modrá s polovinou odvráceného žlutého koně s červenou zbrojí v dolní vlající části čtyři šikmé pruhy, bílý, červený, bílý a černý v poměru 1:1:1:2 na vlajícím a dolním okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý odvrácený kůň s červenou zbrojí přidržující stříbrný praporec s ocasem nahoře s třemi černými pruhy. Pod ním stříbrno-červeně polcený rak."
    },
    {
        obec: "Zahorčice",
        kod: 536482,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38719,
        latitude: 49.208759,
        longitude: 13.817615
    },
    {
        obec: "Zahnašovice",
        kod: 589152,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.304651,
        longitude: 17.560195,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Zahnasovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Tři svislé pruhy: zelený, žlutý a zelený. V horním žerďovém rohu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zkřížená kosa a cep, obojí stříbrné, v dolní části přes ně položený zlatý obilný snop, v horní části stříbrná lilie."
    },
    {
        obec: "Záhornice",
        kod: 534471,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28903,
        latitude: 50.247737,
        longitude: 15.291028,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/Zahornice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a červený, v poměru 2:3. V modrém tři podkovy vedle sebe, v červeném kosmo meč hrotem dolů šikmo podložený širočinou ostřím dolů, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě pod modrou hlavou se třemi podkovami kosmo postavený meč šikmo podložený širočinou, vše zlaté."
    },
    {
        obec: "Zahořany",
        kod: 554464,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.434161,
        longitude: 13.002035
    },
    {
        obec: "Záhorovice",
        kod: 592846,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68771,
        latitude: 49.022764,
        longitude: 17.779332,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/83/Z%C3%A1horovice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, červený a žlutý. Ve žlutých pruzích dva šikmé modré pruhy, vždy vycházející z druhé a čtvrté čtvrtiny žerďového okraje pruhu do první a třetí čtvrtiny vlajícího okraje pruhu, a bílý pruh vycházející ze třetí čtvrtiny žerďového okraje do druhé čtvrtiny vlajícího okraje pruhu. V červeném pruhu bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Modro-stříbrně třikrát dělený štít s červeným hrotem, v něm vykračující zlatý kohout pod stříbrnou růží se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Zahořany",
        kod: 571288,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25210,
        latitude: 49.849446,
        longitude: 14.295423,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Zaho%C5%99any_PZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a vypouklý zelený, v poměru 3 : 1. Vrchol zeleného pruhu sahá do pěti osmin šířky listu a vyrůstají z něho dva zelené jehličnaté stromy s černými kmeny. V zeleném pruhu zkřížená přivrácená žlutá želízka. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě dva zelené jehličnaté stromy s černými kmeny vyrůstající ze zelené hory, v ní zkřížená přivrácená zlatá želízka."
    },
    {
        obec: "Záhoří",
        kod: 507695,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37821,
        latitude: 49.212955,
        longitude: 14.798462
    },
    {
        obec: "Záhoří",
        kod: 550027,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39818,
        latitude: 49.343828,
        longitude: 14.212649,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Municipal_arms_of_Z%C3%A1ho%C5%99%C3%AD.png",
        coatOfArmsFlagDescription: "List tvoří černé karé nad stejně velkým červeným čtvercovým polem a dva vodorovné pruhy, bílý a žlutý. V karé bílý osmihrotý kříž. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-zlatě polceném štítě vpravo osmihrotý kříž nad hvězdou, obojí stříbrné, vlevo červené miskové váhy."
    },
    {
        obec: "Záhoří",
        kod: 599000,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39165,
        latitude: 49.239696,
        longitude: 14.513589
    },
    {
        obec: "Záhoří",
        kod: 577707,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51301,
        latitude: 50.61161,
        longitude: 15.274194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Z%C3%A1ho%C5%99%C3%AD_CoA_SM_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a bílý, v poměru 1 : 6 : 1. V modrém pruhu bílý žerďový klín sahající do tří čtvrtin délky listu, v něm modrý zvon se srdcem zakončeným tlapatým křížem. Ve vlající části modrého pruhu čtyři (2, 2) šesticípé žluté hvězdy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné trojvrší s hrotem uprostřed, provázený čtyřmi (2, 2) zlatými hvězdami. V trojvrší modrý zvon se srdcem zakončeným tlapatým křížem."
    },
    {
        obec: "Zahrádka",
        kod: 591947,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67571,
        latitude: 49.244522,
        longitude: 16.098416,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Zahr%C3%A1dka_TR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a bílý. Ve středu listu půl bílé zavinuté střely, dole zakončené lipovým listem, a černé orlí křídlo se žlutým jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě vpravo půl stříbrné zavinuté střely dole zakončené lipovým listem, vlevo černé křídlo se zlatým, jetelovitě zakončeným perizoniem."
    },
    {
        obec: "Zahrádka",
        kod: 578827,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33035,
        latitude: 49.880856,
        longitude: 13.211746,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/33/Zahr%C3%A1dka_PS_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, žlutý, zelený a bílý, v poměru 1 : 4 : 1. V zeleném pruhu půlměsíc cípy k dolnímu okraji listu s vyrůstajícím křížkovým křížem, vše žluté. Mezi cípy u dolního okraje bílá horní polovina lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě klesající půlměsíc, z něhož vyrůstá křížkový kříž, vše zlaté. Pod půlměsícem stříbrná horní polovina lilie."
    },
    {
        obec: "Zahrádky",
        kod: 547468,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37853,
        latitude: 49.20085,
        longitude: 15.241907
    },
    {
        obec: "Zahrádky",
        kod: 562246,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47101,
        latitude: 50.634208,
        longitude: 14.524708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/03/Zahr%C3%A1dky_%28okres_%C4%8Cesk%C3%A1_L%C3%ADpa%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, v poměru 1:3. V žerďové části červeného pruhu kosmá bílá bledule s listy, v modrém pruhu bílý středový kříž s rameny širokými jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě pod červenou hlavou stříbrný heroldský kříž. V hlavě dvě do oblouku přivrácené stříbrné bledule s listy."
    },
    {
        obec: "Záchlumí",
        kod: 581208,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56186,
        latitude: 50.095447,
        longitude: 16.376348,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/48/Z%C3%A1chlum%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý, v poměru 2:1. V žerďové části bílého pruhu zelený smrk. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě s modrým zúženým břevnem vyrůstají z trávníku tři smrky, prostřední vyšší, vše zelené."
    },
    {
        obec: "Záchlumí",
        kod: 541664,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.80022,
        longitude: 12.965032,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Z%C3%A1chlum%C3%AD_%28okres_Tachov%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a pět vodorovných pruhů, zelený, bílý, červený, bílý a zelený, v poměru 1 : 2 : 2 : 2 : 1. V modrém pruhu z dolní poloviny lilie vyrůstají dvě odkloněné růže na stoncích s listem a trnem, mezi nimi na hůlce šesticípá hvězda, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - stříbrně polceném štítě, vpravo z dolní poloviny lilie vyrůstající dvě odkloněné růže na stoncích s listem a trnem, mezi nimi na hůlce hvězda, vše zlaté, vlevo nad zeleným vrchem červená střela s dvojitým křížem."
    },
    {
        obec: "Zachotín",
        kod: 549177,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.462434,
        longitude: 15.347987,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Zachot%C3%ADn_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 7 : 1 : 1 : 1 : 5. V horním pruhu tři bílé lilie. V dolním pruhu bílý tlapatý kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě modré vlnkovité břevno ve stříbrném vlnkovitém břevnu. Nahoře tři lilie, dole tlapatý kříž, vše stříbrné."
    },
    {
        obec: "Zachrašťany",
        kod: 571130,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.211244,
        longitude: 15.483086,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Zachra%C5%A1%C5%A5any_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý. V červeném pruhu žlutá uzavřená liliová koruna vrcholící křížkem na oblouku. Do bílého pruhu vyrůstá zelený trnitý keř se třemi olistěnými větvemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-stříbrně cimbuřově dělený štít. Nahoře zlatá uzavřená liliová koruna vrcholící křížkem na oblouku, dole vyrůstá zelený trnitý keř se třemi olistěnými větvemi."
    },
    {
        obec: "Zaječí",
        kod: 585050,
        okres: "Břeclav",
        kod_okres: "CZ0644",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69105,
        latitude: 48.87305,
        longitude: 16.766566,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/95/Zajeci_znak.gif",
        coatOfArmsFlagDescription: "List tvoří žluté pole s hnědým běžícím zajícem. Na dolním okraji zelený vodorovný pruh široký 1/5 šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě na zeleném trávníku běžící zajíc přirozených barev."
    },
    {
        obec: "Zaječice",
        kod: 572578,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53835,
        latitude: 49.910081,
        longitude: 15.884208,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/be/Zajecice_znak.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a bílý. V obou pruzích kvádrovaná věž s cimbuřím opačné barvy a s černou gotickou branou. Uprostřed u dolního okraje listu lipový trojlist, prostřední list modrý, žerďový bílý a vlající červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě dvě kvádrované věže s cimbuřím opačných barev a s černou gotickou branou. Pod nimi stříbrno-modro-červený trojlist."
    },
    {
        obec: "Zájezd",
        kod: 571598,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27343,
        latitude: 50.166081,
        longitude: 14.220609,
        coatOfArmsFlagDescription: "List dělený kosmým modrým vlnitým pruhem širokým šestinu šířky listu se třemi vrcholy a dvěma prohlubněmi na žerďové červené a vlající žluté pole s maltézským křížem nad šesticípou hvězdou, obojí červené. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Kosmým modrým vlnitým břevnem dělený štít. V horním zlatém poli osmihrotý kříž nad hvězdou, dolní pole kvádrované, vše červené."
    },
    {
        obec: "Zaječov",
        kod: 531995,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26763,
        latitude: 49.774455,
        longitude: 13.840825,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/Zajecov_znak.gif",
        coatOfArmsFlagDescription: "List dělený pokosem na horní žluté a dolní modré pole. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Zlato-modře kosmo dělený štít. V horním poli černá kančí hlava, v dolním poli zlatý zajíc ve skoku."
    },
    {
        obec: "Zájezdec",
        kod: 547859,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53851,
        latitude: 49.931755,
        longitude: 15.926941,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Z%C3%A1jezdec_CoA.png",
        coatOfArmsDescription: "V zeleném poli pod pěti hvězdami vedle sebe vozové kolo s deseti rameny, vše zlaté."
    },
    {
        obec: "Zajíčkov",
        kod: 561975,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.378057,
        longitude: 15.258087
    },
    {
        obec: "Zákolany",
        kod: 533092,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27328,
        latitude: 50.196515,
        longitude: 14.247943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/Zakolany_znak.gif",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a bílý, v poměru 1:3:1. V červeném pruhu románská rotunda se dvěma prázdnými okny, k níž od žerdě přiléhá věž se dvěma románskými sdruženými okny nad sebou a dvojitou valbovou střechou s makovicí, vše stříbrné. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě románská rotunda se dvěma prázdnými okny, k níž zprava přiléhá věž se dvěma románskými sdruženými okny nad sebou a dvojitou valbovou střechou s makovicí, vše stříbrné."
    },
    {
        obec: "Zakřany",
        kod: 584185,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.170641,
        longitude: 16.32536,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Zakrany_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý se zeleným listnatým keřem a zelený, v poměru 3:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě se zvýšenou zelenou patou listnatý keř, v patě dva stříbrné žaludy."
    },
    {
        obec: "Zákupy",
        kod: 562262,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47123,
        latitude: 50.684854,
        longitude: 14.645335,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Z%C3%A1kupy_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy - modrý, žlutý, modrý v poměru 1:2:1. Ve středu žlutého pruhu jsou černé zkřížené ostrve o šesti sucích. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Zálesí",
        kod: 595152,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67102,
        latitude: 48.954232,
        longitude: 15.782156,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Z%C3%A1les%C3%AD_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed smrk mezi dvěma obilnými klasy s listy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě smrk provázený dvěma obilnými klasy s listy, vše zlaté."
    },
    {
        obec: "Zálesná Zhoř",
        kod: 584193,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.250286,
        longitude: 16.28985
    },
    {
        obec: "Zalešany",
        kod: 513202,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.03578,
        longitude: 15.007909,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Zale%C5%A1any_-_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě červené a modré, v poměru 1:2:2:1. Uprostřed nad žlutým šraňkem bílý holub se žlutou zbrojí stojící na bílé ostrvi. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě nad zlatým šraňkem stříbrný holub se zlatou zbrojí stojící na stříbrné ostrvi."
    },
    {
        obec: "Zálezlice",
        kod: 535354,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27745,
        latitude: 50.307557,
        longitude: 14.438251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Zalezlice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a modrý zvlněný se třemi vrcholy a dvěma prohlubněmi. V bílém pruhu červený kříž s rameny širokými jednu dvanáctinu délky listu, vynikající dolním ramenem z první prohlubně. V modrém pruhu tři žlutá jablka (2,1), každé se dvěma listy na stopce, prostřední pod svislým ramenem kříže. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-modře vlnitě děleném štítě nahoře červený heroldský kříž, dole tři (2,1) zlatá jablka, každé se dvěma listy na stopce."
    },
    {
        obec: "Zálezly",
        kod: 550698,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38481,
        latitude: 49.10755,
        longitude: 13.894683
    },
    {
        obec: "Zaloňov",
        kod: 574660,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55101,
        latitude: 50.374068,
        longitude: 15.88795,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Zalo%C5%88ov_CoA.jpg",
        coatOfArmsFlagDescription: "Žluto-modře kosmo čtvrcený list. Ve žlutých polích odvráceně položené zelené listy, v žerďovém poli lipový, ve vlajícím dubový. V horním kosmo vztyčený klíč, šikmo podložený mečem hrotem k hornímu okraji, v dolním poli čelně radlice, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě kosmo čtvrcený štít. V horním poli kosmo vztyčený klíč, šikmo podložený vztyčeným mečem, v dolním poli čelně radlice, vše stříbrné. V bočních polích odvráceně položené zelené listy, vpravo lipový, vlevo dubový."
    },
    {
        obec: "Zálší",
        kod: 553361,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.218022,
        longitude: 14.599292,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/Z%C3%A1l%C5%A1%C3%AD_%28T%C3%A1bor_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a černý. V modrém pruhu u žerďového a vlajícího okraje žlutá šesticípá hvězda. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. V černém pruhu u žerdi kosmo vztyčený olšový list, u vlajícího okraje šikmo vztyčená borová šiška, obojí žluté. Poměr šířky k délce listu je 2: 3.",
        coatOfArmsDescription: "Modrý štít s černou patou, na ní snížený stříbrný hrot s červenou růží se zlatým semeníkem a zelenými kališními lístky provázený dvěma zlatými hvězdami. V patě olšový list a borová šiška, obojí zlaté, vztyčené a odkloněné."
    },
    {
        obec: "Zálší",
        kod: 581216,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 49.965363,
        longitude: 16.240831,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Z%C3%A1l%C5%A1%C3%AD.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým šikmým pruhem vycházejícím ze třetí třetiny žerďového a první třetiny dolního okraje do třetí třetiny horního a první třetiny vlajícího okraje. V pruhu šikmo zelená větévka olše se dvěma listy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě šikmé zlaté břevno, v něm zelená větévka olše se dvěma listy."
    },
    {
        obec: "Zalužany",
        kod: 541613,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26284,
        latitude: 49.542353,
        longitude: 14.085834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Zalu%C5%BEany_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, bílý, modrý, bílý a zelený, v poměru 10 : 1 : 2 : 1 : 10. Ve střední části horního zeleného pruhu tři listnaté stromy, prostřední nižší, v dolním zeleném pruhu vozové kolo se čtyřmi rameny, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě modré, stříbrně lemované vlnité břevno. Nahoře tři listnaté stromy, prostřední nižší, dole vozové kolo se čtyřmi rameny, vše zlaté."
    },
    {
        obec: "Záluží",
        kod: 532002,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26761,
        latitude: 49.842798,
        longitude: 13.860599,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/CoA_of_Z%C3%A1lu%C5%BE%C3%AD_%28Beroun_District%29.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, zubatý žlutý a zelený, v poměru 2 : 4 : 3. Žlutý pruh má pět čtvercových zubů a čtyři stejné mezery, v zeleném pruhu běžící žlutý zajíc. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatá hradba na zelené patě s běžícím zlatým zajícem."
    },
    {
        obec: "Záluží",
        kod: 542482,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41301,
        latitude: 50.460172,
        longitude: 14.32257,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/04/Z%C3%A1lu%C5%BE%C3%AD_CoA_LIT_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý s červeným lososem prohnutým do oblouku k hornímu okraji a zelený s bílou kozlí hlavou s krkem se žlutými rohy a bradkou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně vlnitě děleném štítě nahoře červený do oblouku nahoru prohnutý losos, dole stříbrná kozlí hlava s krkem se zlatými rohy a bradkou."
    },
    {
        obec: "Zálužice",
        kod: 530603,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.321307,
        longitude: 13.604272
    },
    {
        obec: "Záměl",
        kod: 576921,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51743,
        latitude: 50.096649,
        longitude: 16.298906,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/92/Z%C3%A1m%C4%9Bl_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, červený, žlutý a červený v poměru 5:2:5 a vodorovný bílý pruh na dolním okraji listu, široký jednu čtvrtinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě ze stříbrné vlnité paty vyrůstající zlatý lev s modrými křídly, zlatým nimbem a červeným jazykem, držící stříbrný žernov se svislým otvorem pro kypřici."
    },
    {
        obec: "Zámostí-Blata",
        kod: 549193,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50601,
        latitude: 50.470898,
        longitude: 15.264128
    },
    {
        obec: "Zámrsk",
        kod: 581224,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56543,
        latitude: 49.986276,
        longitude: 16.128847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/34/Z%C3%A1mrsk.svg",
        coatOfArmsFlagDescription: "Červený list se žlutou žerďovou vidlicí, jejíž ramena, široká jednu pětinu šířky listu, vycházejí u žerdi z horního a dolního okraje listu. V žerďovém klínu, jehož vrchol dosahuje do jedné třetině délky listu, žlutá koruna. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě postavený pšeničný snop převýšený rytířskou korunou."
    },
    {
        obec: "Zámrsky",
        kod: 522775,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75301,
        latitude: 49.50645,
        longitude: 17.827929,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/Z%C3%A1mrsky_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený, přes které jsou položeny tři bílé šikmé pruhy, vycházející z šesté šestiny žerďového okraje do šesté šestiny horního okraje, z druhé šestiny dolního okraje do druhé šestiny vlajícího okraje a ze čtvrté šestiny dolního okraje do čtvrté šestiny vlajícího okraje. V horní polovině žerďové části listu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě stříbrné, zlatě zdobené obrněné rameno držící důtky se zlatou rukojetí, třemi stříbrnými řemeny zakončenými zlatými kuličkami, provázené vpravo nahoře stříbrnou lilií."
    },
    {
        obec: "Zápy",
        kod: 505781,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25001,
        latitude: 50.1651,
        longitude: 14.680157,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Zapy_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. V bílém dva přivrácení černo-žlutě dělení gryfové se žlutou zbrojí a červeným jazykem, v červeném bílá svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře dva přivrácení zlato-černě dělení gryfové se zlatou zbrojí a červenými jazyky, dole stříbrná svatojakubská mušle."
    },
    {
        obec: "Zárubice",
        kod: 591955,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67552,
        latitude: 49.124327,
        longitude: 15.981536,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Zarubice_CoA.svg",
        coatOfArmsFlagDescription: "Bílo - zeleně šikmo dělený list. V žerďové části zelená čtyřcípá hvězda. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně šikmo děleném štítě vpravo zelená čtyřhrotá hvězda."
    },
    {
        obec: "Záryby",
        kod: 535362,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27713,
        latitude: 50.220678,
        longitude: 14.625983,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Z%C3%A1ryby_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, v poměru 1 : 3 : 1. Z dolního okraje modrého pruhu vyrůstá mezi dvě vztyčené přivrácené bílé parmy žlutá slunečnice na stonku se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vyrůstá mezi dvě vztyčené přivrácené stříbrné parmy zlatá slunečnice na stonku se dvěma listy."
    },
    {
        obec: "Zářecká Lhota",
        kod: 581232,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56501,
        latitude: 49.993208,
        longitude: 16.246224
    },
    {
        obec: "Záříčí",
        kod: 589161,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76811,
        latitude: 49.382726,
        longitude: 17.352936,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Z%C3%A1%C5%99%C3%AD%C4%8D%C3%AD_-_znak_obce.svg",
        coatOfArmsFlagDescription: "Modrý list uprostřed se žlutou rybou obtáčející bílý mlýnský kámen s červenou kypřicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá ryba obtáčející stříbrný mlýnský kámen s červenou kypřicí."
    },
    {
        obec: "Zásada",
        kod: 563854,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46825,
        latitude: 50.697784,
        longitude: 15.27004,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Z%C3%A1sada_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, žlutý, zelený, červený a bílý, v poměru 1:2:2:1. V zeleném pruhu žlutý pes ve skoku s červenou zbrojí, v červeném pruhu žlutá berla mezi zkříženými bílými ostrvemi se čtyřmi (2+2) odvrácenými suky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vpravo zlatý pes ve skoku s červenou zbrojí, vlevo zlatá berla mezi zkříženými stříbrnými ostrvemi se čtyřmi (2+2) odvrácenými suky."
    },
    {
        obec: "Zastávka",
        kod: 584207,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.188096,
        longitude: 16.363201,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Zast%C3%A1vka_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s černým vlajícím horním cípem v podobě trojúhelníkového pole, jehož jedna odvěsna tvoří dvě třetiny délky listu na horním okraji a druhá odvěsna dvě třetiny šířky listu na vlajícím okraji. Uprostřed listu křížem přeložený bílý mlátek a želízko převázané žluto-červenou stuhou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě s černým levým koutem uprostřed křížem přeložený stříbrný mlátek a želízko, převázané zlato-červenou stuhou, pod nimi stříbrná otevřená kniha."
    },
    {
        obec: "Zásmuky",
        kod: 533921,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28144,
        latitude: 49.954806,
        longitude: 15.030686,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Znak_m%C4%9Bsta_Z%C3%A1smuky.jpg",
        coatOfArmsFlagDescription: "Modrý list s volnou prázdnou bránou se čtyřmi zuby mezi dvěma věžemi, vše bílé a kvádrované. Každá věž se dvěma černými okny vedle sebe a červenou stanovou střechou zakončenou žlutým křížkem. V bráně a dolní části listu bílo-červeně polcená orlice se žlutou zbrojí, jazykem a jetelovitě zakončeným perizoniem. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Zástřizly",
        kod: 587257,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76805,
        latitude: 49.149944,
        longitude: 17.23521,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/50/Z%C3%A1st%C5%99izly_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený. Z poloviny bílé lilie v červeném pruhu vyrůstá do bílého pruhu žalud mezi dvěma dubovými listy, vše zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě vyrůstá nahoře zelená dubová větev s jedním vztyčeným žaludem mezi dvěma listy, dole spodní polovina stříbrné lilie."
    },
    {
        obec: "Zašová",
        kod: 545236,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75651,
        latitude: 49.474196,
        longitude: 18.044471,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b9/CZ_Za%C5%A1ov%C3%A1_COA.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy modrý a červený překryté bílým vodorovným pruhem vycházejícím ze čtvrté pětiny žerďového a vlajícího okraje. Z bílého pruhu vyrůstá černý dvouocasý lev se žlutou korunou, červenou zbrojí a žlutou osmicípou hvězdou v pravé tlapě. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vyrůstá ze stříbrného trojvrší černý korunovaný dvouocasý lev s červenou zbrojí, držící v pravé přední tlapě zlatou osmihrotou hvězdu. V trojvrší volné modré břevno přeložené volným červeným kůlem."
    },
    {
        obec: "Zašovice",
        kod: 591963,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67521,
        latitude: 49.258337,
        longitude: 15.72578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a3/CoA_Za%C5%A1ovice_TR.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený. Do bílého pruhu vyrůstá zelená poutnická hůl mezi dvěma zelenými růžemi s bílými semeníky. V zeleném pruhu bílá hlava anděla s křídly. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - zeleně děleném štítě nahoře vyrůstající zelená poutnická hůl mezi dvěma zelenými růžemi se stříbrnými semeníky, dole stříbrná hlava anděla s křídly."
    },
    {
        obec: "Zátor",
        kod: 597988,
        okres: "Bruntál",
        kod_okres: "CZ0801",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 79316,
        latitude: 50.034227,
        longitude: 17.593061,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/84/Z%C3%A1tor_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a žlutý. V zeleném pruhu radlice zkřížená s krojidlem, obojí ostřím k žerdi, mezi nimi vyrůstají tři blatouchy, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě radlice zkřížená s krojidlem, mezi nimi vyrůstají tři blatouchy, vše zlaté."
    },
    {
        obec: "Závada",
        kod: 553492,
        okres: "Opava",
        kod_okres: "CZ0805",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74719,
        latitude: 49.954135,
        longitude: 18.165759,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Z%C3%A1vada_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se třemi zelenými vinnými listy (1,2). Na vlajícím okraji svislý zelený pruh široký jednu šestinu délky listu se třemi obdélníkovými zuby, dvěma mezerami a dvěma polovičními mezerami. Výška zubů je jedna dvanáctina délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě nad zelenou hradbou o třech stínkách tři (1,2) zelené vinné listy."
    },
    {
        obec: "Zavidov",
        kod: 542601,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27035,
        latitude: 50.056797,
        longitude: 13.620408,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Zavidov_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým návrším vycházejícím z druhé a třetí čtvrtiny dolního okraje, na něm bílý vzpřímený lev s černou zbrojí a červeným jazykem, přeložený uprostřed bílo-červeně polceným vodorovným pruhem širokým jednu sedminu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na stříbrném návrší stříbrný vzpřímený lev s černou zbrojí a červeným jazykem, přes něj stříbrno-červeně polcené břevno."
    },
    {
        obec: "Závist",
        kod: 586005,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67922,
        latitude: 49.375272,
        longitude: 16.57215,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4e/CoA_of_Z%C3%A1vist_%28Blansko_District%29.svg",
        coatOfArmsFlagDescription: "List dělený šikmým bílým pruhem širokým šestinu šířky listu na červené žerďové a zelené vlající pole. V červeném poli žlutý zvon, v zeleném bílá sekera - širočina na žlutém topůrku ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně šikmým zúženým stříbrným břevnem děleném štítě nahoře zlatý zvon, dole doleva obrácená stříbrná sekera - širočina na zlatém topůrku."
    },
    {
        obec: "Závišice",
        kod: 568473,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74221,
        latitude: 49.613357,
        longitude: 18.103114,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Z%C3%A1vi%C5%A1ice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a červený, v poměru 5:1:2. V modrém pruhu bílý pluh bez kol obrácený k vlajícímu okraji, nad ním dvě žluté šesticípé hvězdy. V červeném pruhu dva bílé rovnoramenné trojúhelníky se základnami na dolním okraji listu a s vrcholy na dolním okraji žlutého pruhu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zlatým břevnem modro-červeně dělený štít, nahoře stříbrný pluh bez kol obrácený doleva, nad ním dvě zlaté hvězdy, dole dva stříbrné kužely."
    },
    {
        obec: "Zavlekov",
        kod: 557463,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34142,
        latitude: 49.337465,
        longitude: 13.49054,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Zavlekov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy červený s bílou zříceninou tvrze na skále a bílý se zeleným jetelovým trojlístkem, oboje v žerďové polovině listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně děleném štítě nahoře stříbrná zřícenina tvrze na skále, dole zelený jetelový trojlist."
    },
    {
        obec: "Závraty",
        kod: 599778,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37001,
        latitude: 48.93815,
        longitude: 14.381608
    },
    {
        obec: "Zbečno",
        kod: 542610,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27024,
        latitude: 50.0419,
        longitude: 13.920468,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Coats_of_arms_Zbe%C4%8Dno.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 3:1. V modrém pruhu knížecí čepice zdobená jablkem s křížkem, podložená kosmo bílým křížovým kopím na žluté násadě a šikmo bílým mečem hrotem dolů se žlutým jílcem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad zlatou palisádou knížecí čepice zdobená jablkem s křížkem, podložená kosmým stříbrným křížovým kopím na zlaté násadě a šikmo postaveným stříbrným mečem se zlatým jílcem."
    },
    {
        obec: "Zbelítov",
        kod: 550035,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.456735,
        longitude: 14.329173,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Zbel%C3%ADtov_%28P%C3%ADsek_District%29_CoA.png",
        coatOfArmsFlagDescription: "Žluto-modře šikmo, z dolního rohu do první čtvrtiny vlajícího okraje, vlnitě dělený list. Ve žlutém poli modrý zvon, v modrém žlutý klíčový kříž. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlato-modře šikmo sníženě vlnitě děleném štítě nahoře zvon, dole klíčový kříž, obojí opačných barev."
    },
    {
        obec: "Zbenice",
        kod: 564320,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26231,
        latitude: 49.592261,
        longitude: 14.089159,
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, modrý a zelený, v poměru 1 : 6 : 1. V modrém pruhu bílá lilie, dole tvořená dvěma svěšenými odkloněnými lipovými listy, převýšená zkříženými bílými motykami - kratci na žlutých násadách. Po stranách dva do oblouku přivrácené žluté obilné klasy s jedním listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad zeleným návrším stříbrná lilie, dole tvořená dvěma svěšenými odkloněnými lipovými listy, převýšená zkříženými stříbrnými motykami - kratci na zlatých násadách. Po stranách dva do oblouku přivrácené zlaté obilné klasy s jedním listem."
    },
    {
        obec: "Zběšičky",
        kod: 550043,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39843,
        latitude: 49.392605,
        longitude: 14.426574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Zb%C4%9B%C5%A1i%C4%8Dky_CoA.png",
        coatOfArmsFlagDescription: "List kosmo dělený bílým pruhem vycházejícím ze třetí dvanáctiny horního okraje do desáté dvanáctiny dolního okraje listu na žerďové modré a vlající zelené pole. V modrém poli kosmo bílé orlí křídlo, v zeleném tři (2, 1) bílé šesticípé hvězdy, u vlajícího okraje pod sebou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-modře kosmým zúženým břevnem děleném štítě nahoře tři (2, 1) hvězdy, dole kosmé orlí křídlo, vše stříbrné."
    },
    {
        obec: "Zbilidy",
        kod: 588211,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.443804,
        longitude: 15.422783,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Zbilidy_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, vlnitý bílý, vlnitý modrý a zvlněný bílý, v poměru 7 : 1 : 1 : 1. V zeleném pruhu bílý vykračující kůň se žlutými kopyty a bílým vztyčeným lipovým listem nad ocasem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad stříbrnou vlnitou patou s modrým vlnitým břevnem vykračující stříbrný kůň se zlatými kopyty, provázený vlevo nahoře vztyčeným stříbrným lipovým listem."
    },
    {
        obec: "Zbinohy",
        kod: 588229,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58842,
        latitude: 49.48738,
        longitude: 15.480494
    },
    {
        obec: "Zbiroh",
        kod: 560260,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.860345,
        longitude: 13.772729,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/18/Zbiroh_-_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, žlutý, zelený, bílý, zelený a žlutý, v poměru 1 : 1 : 6 : 1 : 1. V bílém pruhu červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Zbizuby",
        kod: 534609,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28504,
        latitude: 49.831085,
        longitude: 15.047665
    },
    {
        obec: "Zblovice",
        kod: 595161,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67107,
        latitude: 48.957838,
        longitude: 15.70531,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Coat_of_arms_of_Zblovice.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený. V horní části žerďového pruhu vinný hrozen a vlajícího pruhu lilie, obojí bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě kruhová vápenná pec provázená nahoře vpravo vinným hroznem a vlevo lilií, vše stříbrné."
    },
    {
        obec: "Zborov",
        kod: 570338,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.943754,
        longitude: 16.835964,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Zborov_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy v poměru 7:4, zelený, do kterého vyrůstá bílá lípa, a žlutý se svěšenou hnědou borovou šiškou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vyrůstá ze zlatého trojvrší se svěšenou borovou šiškou přirozené barvy stříbrná lípa."
    },
    {
        obec: "Zborovice",
        kod: 589187,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76832,
        latitude: 49.248989,
        longitude: 17.284751,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Zborovice_znak.jpg",
        coatOfArmsFlagDescription: "List kosmo dělený na horní zelené pole a dolní žluté se zeleným kosmo položeným žaludem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-zlatě kosmo dělený štít, nahoře dva zkřížené zlaté klasy ječmene, dole dva zelené odvrácené žaludy na jednom stonku."
    },
    {
        obec: "Zborovy",
        kod: 578312,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34034,
        latitude: 49.381199,
        longitude: 13.515763
    },
    {
        obec: "Zbožíčko",
        kod: 599697,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28925,
        latitude: 50.225835,
        longitude: 14.939922,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Zbo%C5%BE%C3%AD%C4%8Dko_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zelený, v poměru 7 : 1. Ze zeleného pruhu vyrůstá do žerďové a střední části listu bříza, ve střední a vlající části listu červený jednorožec ve skoku se žlutým rohem a kopyty, šikmo bíle přepásaný. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku bříza přirozené barvy a červený jednorožec ve skoku se zlatým rohem a kopyty, šikmo stříbrně přepásaný."
    },
    {
        obec: "Zbraslav",
        kod: 584215,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66484,
        latitude: 49.221653,
        longitude: 16.294251,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Zbraslav_%28okres_Brno-venkov%29_znak.jpg",
        coatOfArmsFlagDescription: "List se šesti vodorovnými pruhy - černým, žlutým, černým, žlutým, černým, žlutým v poměru 1:1:1:1:1:5. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním černém poli dvě zlatá břevna, spodní pole zlaté."
    },
    {
        obec: "Zbraslavec",
        kod: 582727,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67972,
        latitude: 49.483999,
        longitude: 16.528495,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f6/CoA_of_Zbraslavec.svg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, bílý, modrý, žlutý lomený, modrý, žlutý lomený, modrý a bílý, v poměru 2:5:1:2:1:5:2. Žluté pruhy se třikrát lomí vždy ve čtvrtinách délky tak, že se prostředním lomem dotýkají bílých pruhů. Ve vzniklém kosočtverečně rozšířeném modrém pruhu bílá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě položené zlaté kovářské kleště provázené nahoře třemi liliemi a dole polovinou palečného kola, obojí stříbrné."
    },
    {
        obec: "Zbraslavice",
        kod: 534617,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28521,
        latitude: 49.811891,
        longitude: 15.183296,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Zbraslavice_CoA_CZ.gif"
    },
    {
        obec: "Zbrašín",
        kod: 566977,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.299412,
        longitude: 13.766026,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Zbra%C5%A1%C3%ADn_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a čtyři vodorovné pruhy, modrý, bílý zubatý, červený a bílý zubatý. Čtyři zuby a tři mezery jsou čtvercové se stranou rovnou dvanáctině délky listu, krajní mezery jsou poloviční. V žerďovém pruhu obilný klas se dvěma listy provázený dvěma vztyčenými odkloněnými chmelovými lodyhami, každá se dvěma listy a trojící šištic, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Vpravo v zeleném poli obilný klas provázený dvěma vztyčenými odkloněnými chmelovými lodyhami, každá se dvěma listy a s trojicí šištic, vše zlaté. Levé pole dělené, nahoře děleno modro - stříbrně a dole červeno - stříbrně, obojí cimbuřovitě."
    },
    {
        obec: "Zbůch",
        kod: 559661,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33022,
        latitude: 49.678509,
        longitude: 13.225754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Coat_of_arms_of_Zb%C5%AFch.svg",
        coatOfArmsFlagDescription: "Černý list se žlutým klínem vycházejícím z horního okraje listu s vrcholem na dolním okraji listu. V klínu troje černé paroží (2,1), v žerďovém a vlajícím poli obrácená žlutá hornická kladívka. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-černě zvýšeným klínem děleném štítě nahoře troje černé paroží (2,1), dole dvoje obrácená zlatá hornická kladívka."
    },
    {
        obec: "Zbuzany",
        kod: 539872,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25225,
        latitude: 50.024277,
        longitude: 14.286854,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Zbuzany_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a modrý, a žlutý kříž, s rameny širokými jednu osminu šířky listu, jehož žerďové rameno je zakončeno kruhovým polem o průměru jedné pětiny šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě zlatý heroldský latinský kříž s horním ramenem zakončeným kotoučem."
    },
    {
        obec: "Zbyslavice",
        kod: 568449,
        okres: "Ostrava-město",
        kod_okres: "CZ0806",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74283,
        latitude: 49.805441,
        longitude: 18.075576,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/09/Zbyslavice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, modrý se žlutým šestilistým květem a zelený. Přes vodorovné pruhy kosmo krojidlo šikmo podložené radlicí, oboje bílé a hrotem a ostřím k hornímu okraji listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrný větrný mlýn se zlatými lopatkami a s červenou střechou, provázený dvěma zlatými šestilistými květy. V návrší šikmá radlice, kosmo přeložená krojidlem, obojí vztyčené a stříbrné."
    },
    {
        obec: "Zbýšov",
        kod: 584223,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66411,
        latitude: 49.155344,
        longitude: 16.349616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/02/Znak_Zb%C3%BD%C5%A1ov_%28okres_Brno-venkov%29.gif",
        coatOfArmsFlagDescription: "List tvoří sedm šikmých pruhů, střídavě zelených a černých. Ve střední části listu kosmý mlátek šikmo podložený želízkem, pod nimi k vlajícímu okraji obrácený pluh provázený u dolního okraje mezi radlicí a kolem vinným hroznem, vše žluté. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Zbýšov",
        kod: 593699,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68352,
        latitude: 49.131422,
        longitude: 16.80578,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Zbysov_%28Vyskov%29_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří pět šikmých pruhů střídavě žlutých a zelených. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě klobouk se třemi pavími pery a rozvázaným šňůrovým podbradníkem, provázený vpravo radlicí a vlevo vinným hroznem, vše zlaté."
    },
    {
        obec: "Zbýšov",
        kod: 534625,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28565,
        latitude: 49.811657,
        longitude: 15.353208,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Zby%C5%A1ov_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, zelený a střídavě žluté a modré, v poměru 7 : 1 : 1 : 1 : 1 : 1 : 7. V zeleném pruhu bílý středový kříž s rameny širokými dvě devatenáctiny šířky listu. V dolním modrém pruhu bílá ryba. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dvakrát dělený štít. V horním zeleném poli stříbrný heroldský kříž, v prostředním zlatém poli dvě modrá břevna, v dolním modrém poli stříbrná ryba."
    },
    {
        obec: "Zbytiny",
        kod: 550701,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38441,
        latitude: 48.942727,
        longitude: 13.978153,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/94/Zbytiny_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, vlnitý modrý, vlnitý žlutý, vlnitý modrý a zvlněný žlutý v poměru 5:1:1:1:1. Vlnité pruhy mají pět prohlubní a pět vrcholů. Do horního žlutého pruhu vyrůstá zelený lev s červenou zbrojí. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě dole dvě modrá vlnitá břevna, z horního vyrůstá zelený lev s červenou zbrojí."
    },
    {
        obec: "Zděchov",
        kod: 545244,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75607,
        latitude: 49.261068,
        longitude: 18.077943,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Zd%C4%9Bchov_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, modrý a bílý, a vodorovný zelený pruh na dolním okraji listu, široký jednu třetinu šířky listu. V bílých pruzích po jedné vztyčené jalovcové větévce s plody. V modrém pruhu bílý květ kopretiny se žlutým středem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě ze zeleného trojvrší vyrůstá modrý rozšířený kůl, v něm stříbrný květ kopretiny se zlatým středem. Kůl je provázen dvěma větévkami jalovce s plody, přirozených barev."
    },
    {
        obec: "Zdechovice",
        kod: 573744,
        okres: "Hradec Králové",
        kod_okres: "CZ0521",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50401,
        latitude: 50.224827,
        longitude: 15.56176,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Zdechovice_%28Hradec_Kr%C3%A1lov%C3%A9_District%29_CoA.png",
        coatOfArmsFlagDescription: "Zelený list se šachovaným pruhem na vlajícím okraji listu tvořeným dvanácti (6 x 2) bílo-zeleně šikmo dělenými čtvercovými poli. V zeleném poli tři obilné klasy vyrůstající z dolního okraje listu, krajní s listem, převýšené korunou, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vlevo polcený štít. V pravém zeleném poli tři vyrůstající obilné klasy, krajní s listem, převýšené korunou, vše zlaté. Levé pole dvěma řadami dolů šachované, každé pole stříbrno-zeleně šikmo dělené."
    },
    {
        obec: "Zdechovice",
        kod: 576026,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53311,
        latitude: 50.013006,
        longitude: 15.46958,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/17/Zdechovice-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu třetinu délky listu a tři vodorovné pruhy, zelený, žlutý a zelený, v poměru 5:1:2. V bílém pruhu červený svatopetrský kříž, jehož vodorovné rameno navazuje na stejně široký žlutý pruh. V kříži bílý meč se žlutým jílcem hrotem k hornímu okraji. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně polceném štítě vpravo červený svatopetrský kříž do krajů se vztyčeným stříbrným mečem se zlatým jílcem, vlevo nahoru otevřená podkova nad křesací ocílkou, obojí zlaté."
    },
    {
        obec: "Zdelov",
        kod: 576930,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51721,
        latitude: 50.100779,
        longitude: 16.142255,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Zdelov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý s bílým kaprem a bílý s červeným mlýnským kolem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-stříbrně děleném štítě nahoře stříbrný kapr, dole červené mlýnské kolo."
    },
    {
        obec: "Zdemyslice",
        kod: 558583,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.607059,
        longitude: 13.519921,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Zdemyslice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 8 : 1 : 7. Uprostřed horního pruhu dvě přivrácená křídla, bílé a červené, spojená žlutým perizoniem, mezi nimi žlutá koruna. V dolním pruhu žluté kruhové pole s červenou růží se žlutým semeníkem, provázené nahoře dvěma menšími žlutými kruhovými poli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrný zděný most na třech pilířích se dvěma oblouky a dvěma půloblouky, nad ním dvě přivrácená křídla, stříbrné a červené, spojená zlatým perizoniem, mezi nimi zlatá koruna. Pod mostem tři zlaté kotouče, v prostředním větším červená růže se zlatým semeníkem."
    },
    {
        obec: "Zdeňkov",
        kod: 588245,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.140036,
        longitude: 15.62007
    },
    {
        obec: "Zderaz",
        kod: 573876,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53944,
        latitude: 49.828645,
        longitude: 16.100192,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Zderaz_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří červené žerďové čtvrtkruhové pole o poloměru rovném šířce listu a se středem v horním rohu. K němu přiléhá bílé pole vymezené čtvrtinou elipsy se středem v horním rohu a s poloosami rovnými hornímu a žerďovému okraji listu. Vlající pole je hnědé. V červeném poli dvě žluté zkřížené berly. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-stříbrně šikmo děleném štítě nahoře zkřížené zlaté berly, dole vyrůstající pískovcové skály přirozené barvy."
    },
    {
        obec: "Zdětín",
        kod: 590223,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79843,
        latitude: 49.50595,
        longitude: 16.988886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Zdetin-znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, modrý a červený, v poměru 1 : 3 : 1. V modrém pruhu knížecí čepice ozdobená křížkem mezi dvěma bílými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno - zlatě polcený štít s modrou hlavou, v ní knížecí čepice ozdobená křížkem mezi dvěma stříbrnými liliemi. Ve štítě dvě vztyčené pěstmi spojené stříbrné obrněné paže."
    },
    {
        obec: "Zdětín",
        kod: 571814,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29471,
        latitude: 50.312702,
        longitude: 14.812523,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/62/Zd%C4%9Bt%C3%ADn_MB_coA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři svislé pruhy, střídavě bílé a červené, v poměru 1 : 2 : 2 : 1. Uprostřed listu maltézský kříž nad vykořeněnými lekny, obojí opačných barev. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně polceném štítě maltézský kříž nad lekny, obojí opačných barev."
    },
    {
        obec: "Zdiby",
        kod: 539058,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25066,
        latitude: 50.168096,
        longitude: 14.451284,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/58/Zdiby_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-modře čtvrcený list. V horní části listu polovina bílého vozového kola nad žlutou poštovní trubkou v dolní části listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře čtvrceném štítě horní polovina stříbrného vozového kola nad zlatou poštovní trubkou."
    },
    {
        obec: "Zdice",
        kod: 532011,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26751,
        latitude: 49.912178,
        longitude: 13.977574,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/CoA_of_Zdice.svg",
        coatOfArmsFlagDescription: "List praporu tvoří pět vodorovných pruhů - modrý, bílý, červený, bílý a modrý v poměru 1:1:4:1:1. Na červeném pruhu jsou dvě bílé lilie z městského znaku. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít, v horním červeném poli dvě stříbrné lilie, dolní pole pětkrát stříbrno-modře dělené."
    },
    {
        obec: "Zdíkov",
        kod: 550728,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38472,
        latitude: 49.084672,
        longitude: 13.697479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Zdikov_znak.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové a dolní vlající pole tvoří dva svislé pruhy, zelený a bílý, s bílo-červeně polcenou orlicí se žlutou zbrojí a žlutým perizoniem. Horní vlající a dolní žerďové pole jsou modrá se žlutým kosmým pruhem širokým jednu desetinu šířky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě stříbrno-červeně polcená orlice se zlatou zbrojí a perizoniem, nesoucí na hrudi modrý štítek se zlatým kosmým břevnem."
    },
    {
        obec: "Zdislava",
        kod: 564541,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46353,
        latitude: 50.765065,
        longitude: 14.876403,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Zdislava_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, bílý a červený, v poměru 9 : 2 : 9. V horním červeném pruhu čelně hledící bílý beránek se žlutou svatozáří a zbrojí, levou přední nohou přidržující šikmo žlutou žerď s dvoucípou bílou korouhví s červeným křížem na příčném ráhnu. V dolním červeném pruhu bílý kráčející lev se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě zúžené stříbrné vlnité břevno. Nahoře čelně hledící stříbrný beránek se zlatou svatozáří a zbrojí, levou přední nohou přidržující šikmo zlatou žerď s dvoucípou stříbrnou korouhví s červeným heroldským křížem na příčném ráhnu. Dole stříbrný kráčející lev se zlatou zbrojí."
    },
    {
        obec: "Zdislavice",
        kod: 531022,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25764,
        latitude: 49.686587,
        longitude: 14.974594,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/86/Zdislavice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a modrý, v poměru 2 : 1 : 1. Do žerďové části horního pruhu vyrůstá bílo-červeně polcená hlava orlice s krkem a se žlutou zbrojí. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Zdobín",
        kod: 548847,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54401,
        latitude: 50.415397,
        longitude: 15.704708,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Zdob%C3%ADn_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle čtvrcený list. Uprostřed zelené čtvercové pole široké tři pětiny šířky listu s položenou osekanou větví, ze které vyrůstá větévka se dvěma žaludy nad dvěma dubovými listy, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít; v pravém zeleném poli položená osekaná větev, z níž vyrůstá větévka se dvěma žaludy nad dvěma dubovými listy, pod větví prázdná osmihrotá hvězda, vše zlaté. Levé pole stříbrno-červeně dělené, nahoře červený korunovaný lev s modrou zbrojí, dole stříbrný tlapatý kříž."
    },
    {
        obec: "Zdobnice",
        kod: 576948,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51601,
        latitude: 50.238579,
        longitude: 16.408683,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Zdobnice.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, vlnitý bílý, vlnitý modrý, vlnitý bílý a zvlněný zelený, v poměru 10 : 1 : 1 : 1 : 7. Z horního bílého pruhu vyrůstají tři bílé stromy, listnatý mezi jehličnatými. V dolním zeleném pruhu řecký kříž v obrácené podkově provázené šesticípými hvězdami, vše bílé. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrné vlnité břevno dvakrát stříbrno-modře vlnitě dělené, z něhož vyrůstají tři stříbrné stromy, listnatý mezi jehličnatými. Dole řecký kříž v obrácené podkově provázené hvězdami, vše stříbrné."
    },
    {
        obec: "Zdounky",
        kod: 589195,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76802,
        latitude: 49.227808,
        longitude: 17.319096,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Zdounky_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List se zeleným svislým žerďovým pruhem širokým jednu třetinu délky listu a dvěma vodorovnými pruhy - žlutým a červeným. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Dělený štít. V horní zelené polovině zlatý vinný hrozen mezi dvěma stříbrnými vinařskými noži se zlatými střenkami. Spodní polovina červeno-zlatě polcená s křídly opačných barev."
    },
    {
        obec: "Zduchovice",
        kod: 513512,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26263,
        latitude: 49.638685,
        longitude: 14.209044,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/22/Coats_of_arms_Zduchovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 1 : 6 : 1. V modrém pruhu pohanská koruna převýšená třemi liliemi (1, 2), vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad stříbrno - červeně polcenou cimbuřovou patou pohanská koruna převýšená třemi liliemi (1, 2), vše zlaté."
    },
    {
        obec: "Zelená Hora",
        kod: 593702,
        okres: "Vyškov",
        kod_okres: "CZ0646",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 68321,
        latitude: 49.329083,
        longitude: 17.01382,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Zelen%C3%A1_hora_VY_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se středovým žlutým křížem s rameny širokými jednu dvanáctinu délky listu, uprostřed zelené, bíle lemované čtvercové pole široké jednu třetinu délky listu, se zkříženými bílými sekyrami na žlutých topůrkách. V červených polích po jednom hermelínovém pětilistém květu. Bílý lem čtvercového pole je široký jednu čtyřiadvacetinu délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně sníženě dělený štít, nahoře zlatý větrný mlýn provázený dvěma hermelínovými pětilistými květy, dole v zeleném trojvrší dvě zkřížené stříbrné sekery na zlatých topůrkách."
    },
    {
        obec: "Zelenecká Lhota",
        kod: 573183,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50723,
        latitude: 50.401703,
        longitude: 15.178861,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Zeleneck%C3%A1_Lhota_CoA.png",
        coatOfArmsFlagDescription: "Zelený list, v něm do kruhu střídavě tři (1, 2) bílá kruhová pole s černými korunami a tři (2, 1) odvrácené žluté lipové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě tři (2, 1) odvrácené zlaté lipové listy provázené třemi (1, 2) stříbrnými kotouči s černými korunami."
    },
    {
        obec: "Zeleneč",
        kod: 539066,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25091,
        latitude: 50.133721,
        longitude: 14.660805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Zelenec_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, bílý, červený a žlutý, v poměru 4 : 1 : 1 : 4. V zeleném pruhu tři vztyčené žluté lipové listy na spojených řapících, krajní odkloněné s listenem a plody. Ve žlutém pruhu tři vztyčené zelené dubové listy na spojených řapících, mezi nimi po červeném žaludu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-červeně děleným břevnem zeleno-zlatě dělený štít. Nahoře tři vztyčené zlaté lipové listy na spojených řapících, krajní odkloněné s listenem a plody. Dole tři vztyčené zelené dubové listy na spojených řapících, mezi nimi po červeném žaludu."
    },
    {
        obec: "Zemětice",
        kod: 558591,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33452,
        latitude: 49.578482,
        longitude: 13.186446
    },
    {
        obec: "Zhoř",
        kod: 550060,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39901,
        latitude: 49.501316,
        longitude: 14.382796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Zho%C5%99_PI_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý, v poměru 4 : 1. V červeném pruhu bílý kráčející lev se žlutou zbrojí. Ze druhé a třetí čtvrtiny bílého pruhu vyniká pět plamenů, prostřední nejvyšší sahá do poloviny a krajní nejnižší do pěti osmin šířky listu. V bílém pruhu a v prostředním plameni modrá lilie. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě kráčející stříbrný lev se zlatou zbrojí nad sníženým stříbrným plamenným hrotem s pěti plameny, v něm modrá lilie."
    },
    {
        obec: "Zhoř",
        kod: 582735,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67923,
        latitude: 49.414782,
        longitude: 16.47899
    },
    {
        obec: "Zhoř",
        kod: 588253,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58826,
        latitude: 49.4427,
        longitude: 15.771449
    },
    {
        obec: "Zhoř",
        kod: 541460,
        okres: "Tachov",
        kod_okres: "CZ0327",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34901,
        latitude: 49.660005,
        longitude: 12.969779,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Zho%C5%99_%28Tachov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 5:3. V žerďové polovině modrého pruhu žlutá lilie, pod ní ve žlutém pruhu šlehající červený oheň. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zlatě sníženě děleném štítě nahoře zlatá lilie, z dolního okraje štítu šlehá červený oheň."
    },
    {
        obec: "Zhoř u Mladé Vožice",
        kod: 599034,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39143,
        latitude: 49.5436,
        longitude: 14.764978,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Zho%C5%99_u_Mlad%C3%A9_Vo%C5%BEice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 3 : 1. V žerďové polovině bílého pruhu lilie v mezikruží, obojí modré. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad vynikajícími červenými plameny lilie v kruhu, obojí modré."
    },
    {
        obec: "Zhoř u Tábora",
        kod: 560430,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39002,
        latitude: 49.357623,
        longitude: 14.657625,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Zho%C5%99_u_T%C3%A1bora_CoA.png",
        coatOfArmsFlagDescription: "Bílý list, na jeho dolním okraji červený plamenný pruh s pěti plameny sahajícími do devíti šestnáctin šířky listu. V horní polovině listu červená růže se žlutým semeníkem a zelenými kališními lístky, z níž do stran vynikají modré hroty šípů. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě nad šlehajícími červenými plameny červená růže se zlatým semeníkem a zelenými kališními lístky, z níž do stran vynikají modré hroty šípů."
    },
    {
        obec: "Zhořec",
        kod: 561363,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.489741,
        longitude: 14.962556,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Zho%C5%99ec_PE_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a plamenný modrý, v poměru 1 : 2 : 3. V modrém vlajícím pruhu vlnitě pod sebou pět šesticípých žlutých hvězd, střídavě k vlajícímu a k žerďovému okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě plamenným řezem dělený štít, nahoře pět zlatých hvězd (3, 2), dole vyrůstající modré mlýnské kolo."
    },
    {
        obec: "Zichovec",
        kod: 571423,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27374,
        latitude: 50.271159,
        longitude: 13.926136,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/55/Zichovec_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové a střední části ostruhové kolečko v mezikruží, vše žluté. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V modrém štítě ostruhové kolečko obtočené dvěma propletenými klasy, pravým pšeničným, levým žitným, vše zlaté."
    },
    {
        obec: "Zlámanec",
        kod: 592854,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68712,
        latitude: 49.129954,
        longitude: 17.628309,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Zlamanec-znak.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a červený. Ve střední části listu v modrém pruhu do oblouku svěšená větévka oskeruše s listy a plody, v červeném pruhu půl zlámaného vozového kola loukotěmi k vlajícímu okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro - červeně polceném štítě vpravo svěšená větévka oskeruše s listy a sedmi plody, vlevo půl zlámaného vozového kola, vše zlaté."
    },
    {
        obec: "Zlatá",
        kod: 513644,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25083,
        latitude: 50.04033,
        longitude: 14.710148,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/Zlat%C3%A1_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, žlutý a červený v poměru 6 : 1 : 1. V žerďové polovině modrého pruhu žluté slunce bez tváře s paprsky střídavě přímými a plamennými. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Snížené dělený štít. Nahoře v modrém poli zlaté slunce s paprsky střídavě přímými a plamennými. Dolní pole zlato-červeně dělené."
    },
    {
        obec: "Zlatá Koruna",
        kod: 545864,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38202,
        latitude: 48.854873,
        longitude: 14.369638,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/CZ_Zlat%C3%A1_Koruna_COA.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutou korunou uprostřed. Bílý okraj široký jednu šestinu šířky listu se čtyřmi červenými růžemi se žlutým semeníkem vždy ve středu okraje. Koruna zaujímá jednu třetinu šířky i délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě se stříbrným lemem zlatá koruna se třemi viditelnými listy. V lemu jsou umístěny do kříže čtyři červené růže se zlatými semeníky."
    },
    {
        obec: "Zlatá Olešnice",
        kod: 579866,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54101,
        latitude: 50.61779,
        longitude: 15.944887,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/28/Zlat%C3%A1_Ole%C5%A1nice_%28Trutnov_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu a tři vodorovné pruhy, modrý, bílý a modrý, v poměru 3 : 2 : 3. V horní polovině žerďového pruhu modrá hornická kladívka s černými topůrky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě stříbrné vlnité břevno, nad ním horní polovina zlomeného zlatého nožového kola, dole ve zlatém trojvrší modrá hornická kladívka s černými topůrky."
    },
    {
        obec: "Zlatá Olešnice",
        kod: 563862,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46847,
        latitude: 50.708647,
        longitude: 15.353616,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/26/Zlata_Olesnice_Tanvald-znak.png",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký jednu třetinu délky listu a zelené pole přes které jsou položeny tři vodorovné vlnité pruhy modrý, bílý a modrý vycházející z třinácté, čtrnácté a patnácté šestnáctiny žerďového okraje listu. Ve žlutém pruhu k vlajícímu okraji položená černá olšová větévka se dvěma jehnědami, třemi šiškami a nahoře zeleným listem. V zeleném poli bílý kostel vpravo s věží, se dvěma černými okny nad sebou, obloukovým a kulatým, červenou bání zakončenou černým křížkem. Loď se sakristií, dveřmi a třemi obloukovými okny, vše černé, s červenou valbovou střechou zakončenou černým sanktusníkem. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve zlato-zeleně polceném štítě dole stříbrné modře-lemované vlnité břevno. Vpravo doleva položená černá olšová větévka se dvěma jehnědami, třemi šiškami a nahoře zeleným listem. Vlevo stříbrný kostel vpravo s věží, se dvěma černými okny nad sebou, obloukovým a kulatým, červenou bání zakončenou černým křížkem. Loď se sakristií, dveřmi a třemi obloukovými okny, vše černé, s červenou valbovou střechou zakončenou černým sanktusníkem."
    },
    {
        obec: "Zlaté Hory",
        kod: 597996,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79376,
        latitude: 50.263894,
        longitude: 17.396122,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Zlat%C3%A9_Hory_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý s bílými zkříženými hornickými kladívky v žerďové části a žlutý. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zeleném trávníku horník v černém šatě a botách, stříbrných kalhotách a ve stříbrné kukle, dobývající zlato ze skály přirozené barvy. Vlevo listnatý strom přirozené barvy."
    },
    {
        obec: "Zlátenka",
        kod: 598666,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39501,
        latitude: 49.423554,
        longitude: 15.058658
    },
    {
        obec: "Zlatníky-Hodkovice",
        kod: 539881,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25241,
        latitude: 49.960627,
        longitude: 14.480805,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Zlatniky-Hodkovice_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, žlutý, červený a žlutý, v poměru 3:1:1:1. V zeleném pruhu žlutá zeď s prolomenou gotickou branou mezi dvěma žlutými baštami, každá s jednou černou klíčovou střílnou a červenou kuželovou střechou se žlutou makovicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě děleném štítě nahoře zlatá zeď s prolomenou gotickou branou mezi dvěma zlatými kruhovými baštami, každá s jednou černou klíčovou střílnou a červenou kuželovou střechou se zlatou makovicí, dole červené břevno."
    },
    {
        obec: "Zlechov",
        kod: 592862,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68710,
        latitude: 49.074059,
        longitude: 17.379491,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Znak_zlechov.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, bílý, zelený a bílý, v poměru 1:3:1. V zeleném pruhu vztyčený vinný hrozen na prohnutém stonku s jedním listem obráceným k dolnímu cípu, provázený na žerďové straně vinařským nožem hrotem a ostřím k žerdi a na vlající straně radlicí ostřím k vlajícímu a hrotem k hornímu okraji, vše bílé. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vztyčený vinný hrozen na prohnutém stonku s jedním doleva svěšeným listem, provázený vpravo vinařským nožem a vlevo vztyčenou, doleva obrácenou radlicí, vše stříbrné."
    },
    {
        obec: "Zlín",
        kod: 585068,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76001,
        latitude: 49.224537,
        longitude: 17.662863,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Zlin_-_znak.svg",
        coatOfArmsFlagDescription: "List praporu tvoří pět vodorovných pruhů (modrý - žlutý - modrý - žlutý - modrý) ve vzájemném poměru 1:1:2:1:1. Ve středu na modrém pruhu je osmicípá žlutá hvězda. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Zliv",
        kod: 545341,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37344,
        latitude: 49.066188,
        longitude: 14.366234,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Zliv_znak.png"
    },
    {
        obec: "Zlobice",
        kod: 589217,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76831,
        latitude: 49.301917,
        longitude: 17.312714,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Zlobice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, červený, bílý a červený v poměru 3:1:1:3. Do zeleného pruhu vyrůstá žlutý patriarší kříž, v dolním červeném pruhu žlutý dolů obrácený lipový list. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Zeleno-červeně stříbrným kůlem polcený štít, vpravo zlatý patriarší kříž přeložený červeným břevnem se čtyřmi stříbrnými kužely, vlevo zlatá vykořeněná lípa."
    },
    {
        obec: "Zlončice",
        kod: 531511,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27801,
        latitude: 50.228883,
        longitude: 14.35768,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/19/Zlon%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří v žerďové polovině šest vodorovných pruhů, bílý, vlnitý modrý, vlnitý bílý, vlnitý modrý, vlnitý bílý a zvlněný modrý se žlutým květem hlaváčku jarního, v poměru 1 : 1 : 1 : 1 : 1 : 5. Ve vlající polovině tři vodorovné pruhy, bílý se vztyčenými černými kamenickými kleštěmi, vlnitý modrý a zvlněný bílý, v poměru 7 : 1 : 2. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Polcený štít. Pravé pole stříbrno-modře vlnitě dělené, nahoře dvě modrá vlnitá břevna, dole zlatý květ hlaváčku jarního. V levém stříbrném poli vztyčené černé kamenické kleště nad sníženým modrým vlnitým břevnem."
    },
    {
        obec: "Zlonice",
        kod: 533114,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27371,
        latitude: 50.287603,
        longitude: 14.092247,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Zlonice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a červený, v poměru 5 : 3. Ve střední části listu půl černé orlice s celou hlavou s červeným jazykem otočenou k žerďovému a křídlem k vlajícímu okraji listu. Nad ní žlutá koruna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - červeně děleném štítě levá půle černé orlice s celou hlavou otočenou doprava a s červeným jazykem."
    },
    {
        obec: "Zlonín",
        kod: 539082,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25064,
        latitude: 50.215834,
        longitude: 14.507857,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Coats_of_arms_Zlon%C3%ADn.jpeg",
        coatOfArmsFlagDescription: "Žlutý list; v žerďové polovině černá plamenná orlice s bílým perizoniem a červeným jazykem, místo ocasních per tři zelené lipové listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá plamenná orlice se stříbrným perizoniem a červeným jazykem, místo ocasních per tři zelené lipové listy."
    },
    {
        obec: "Zlosyň",
        kod: 535389,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27744,
        latitude: 50.278049,
        longitude: 14.368713,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/81/Zlosy%C5%88_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a červený, v poměru 3 : 2. V zeleném pruhu tři žluté obilné klasy. V červeném pruhu dolní polovina bílého vozového kola. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně sníženě děleném štítě nahoře tři zlaté obilné klasy, dole dolní polovina stříbrného vozového kola."
    },
    {
        obec: "Zlukov",
        kod: 599271,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.193033,
        longitude: 14.745188,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Zlukov_CoA.png"
    },
    {
        obec: "Znětínek",
        kod: 597139,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59444,
        latitude: 49.469654,
        longitude: 15.926644,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Zn%C4%9Bt%C3%ADnek_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený, a dva rovnoběžné kosmé a dva rovnoběžné šikmé pruhy, všechny žluté, vycházející z třetích a osmnáctých dvacetin okrajů listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě zvýšeně zkřížené kosa a cep, dole přeložené hráběmi zkříženými s vidlemi, vše zlaté."
    },
    {
        obec: "Znojmo",
        kod: 593711,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66902,
        latitude: 48.856011,
        longitude: 16.054368,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d0/ZNOJMO_znak_m%C4%9Bsta.png",
        coatOfArmsFlagDescription: "List tvoří modré karé a dva vodorovné pruhy žlutý a červený. V karé korunovaná žluto-červeně šachovaná orlice se žlutou zbrojí a jazykem nesoucí na hrudi modrý štítek se žlutým majuskulním písmenem Z a provázená po stranách šesti (3, 3) žlutými liliemi. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě korunovaná zlato-červeně šachovaná orlice se zlatou zbrojí a jazykem nesoucí na hrudi modrý štítek se zlatým majuskulním písmenem Z a provázená po stranách šesti (3, 3) zlatými liliemi."
    },
    {
        obec: "Zruč nad Sázavou",
        kod: 534633,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28522,
        latitude: 49.740205,
        longitude: 15.106164,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Zruc_nad_sazavou_znak.gif",
        coatOfArmsFlagDescription: "Červený list s bílým vlajícím pruhem širokým jednu třetinu délky listu. V červeném poli bílé buvolí rohy. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Zruč-Senec",
        kod: 559679,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33008,
        latitude: 49.8031,
        longitude: 13.419093,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/60/Zru%C4%8D-Senec_CoA.png",
        coatOfArmsFlagDescription: "List tvoří modrý žerďový pruh široký třetinu délky listu a červené pole. V modrém pruhu žlutá berla závitem k žerdi přeložená vodorovným bílo-červeně šachovaným (2x4) pruhem vycházejícím z prostředních dvou osmin žerďového a vlajícího okraje pruhu. V žerďové části červeného pole bílá chrtice ve skoku se žlutým jazykem a obojkem s kroužkem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modro-červeně polceném štítě vpravo zlatá berla přeložená stříbrno-červeně dvěma řadami šachovaným břevnem, vlevo stříbrná chrtice ve skoku se zlatým jazykem a obojkem s kroužkem."
    },
    {
        obec: "Zubčice",
        kod: 545872,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.794137,
        longitude: 14.40728,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cf/CoA_of_Zub%C4%8Dice.svg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, pětkrát lomený bílý s vrcholy ve třetinách šířky listu, a červený, v poměru 3 : 1 : 2. V červených pruzích kosmé žluté střely, v horním dvě a v dolním jedna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pětkrát lomené stříbrné břevno, provázené třemi (2, 1) kosmými zlatými střelami."
    },
    {
        obec: "Zubří",
        kod: 597147,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59231,
        latitude: 49.571209,
        longitude: 16.123791,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/97/Zubri_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a zelený v poměru 1:2:1. V bílém pruhu černá zubří hlava s červeným jazykem a se žlutou houžví. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě na zeleném trávníku mezi dvěma listnatými stromy přirozených barev černá zubří hlava se zlatou houžví pod třemi (1,2) červenými hvězdami."
    },
    {
        obec: "Zubrnice",
        kod: 568384,
        okres: "Ústí nad Labem",
        kod_okres: "CZ0427",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 40002,
        latitude: 50.649558,
        longitude: 14.220493,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Zubrnice_CoA.png",
        coatOfArmsFlagDescription: "Zelený list s bílou krokví vycházející ze třetí čtvrtiny žerďového a vlajícího okraje s vrcholem na horním okraji listu. V horním rohu a cípu po jednom svěšeném bílém lipovém listu. V krokvi pět modrých sedmicípých hvězd. Pod krokví bílé mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zvýšená stříbrná krokev s pěti modrými sedmihrotými hvězdami, provázená nahoře dvěma svěšenými lipovými listy, dole mlýnské kolo, vše stříbrné."
    },
    {
        obec: "Zubří",
        kod: 545252,
        okres: "Vsetín",
        kod_okres: "CZ0723",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 75654,
        latitude: 49.466126,
        longitude: 18.092595,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/06/CZ_Zub%C5%99%C3%AD_COA.svg",
        coatOfArmsFlagDescription: "Žlutý list s černou zubří hlavou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě černá zubří hlava."
    },
    {
        obec: "Zvánovice",
        kod: 539091,
        okres: "Praha-východ",
        kod_okres: "CZ0209",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25165,
        latitude: 49.93205,
        longitude: 14.780336,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Coats_of_arms_Zv%C3%A1novice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, modrý a zelený. Na dolním okraji listu vodorovný pilovitý žlutý pruh, široký jednu devítinu šířky listu, se třemi zaoblenými zuby dosahujícími do třetiny šířky listu. V modrém pruhu bílý mlýnský kámen s černou postavenou kypřicí, v zeleném pruhu čelně hledící bílá beraní hlava se žlutými rohy. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-zeleně polceném štítě se zlatým trojvrším, vpravo stříbrný mlýnský kámen s černou postavenou kypřicí, vlevo čelně hledící stříbrná beraní hlava se zlatými rohy."
    },
    {
        obec: "Zvěrkovice",
        kod: 591980,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67602,
        latitude: 49.02563,
        longitude: 15.863268,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/13/Zv%C4%9Brkovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, zelený a bílý, v poměru 1 : 2 : 1, přes ně šikmý žlutý pruh vycházející ze sedmé až desáté desetiny žerďového okraje do první až třetí desetiny vlajícího okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě volné zlaté šikmé břevno, z něhož nahoře vyniká doleva položené krojidlo, dole položená radlice, obojí stříbrné."
    },
    {
        obec: "Zvěrotice",
        kod: 553409,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39201,
        latitude: 49.272067,
        longitude: 14.744794
    },
    {
        obec: "Zvěřínek",
        kod: 534862,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28913,
        latitude: 50.153287,
        longitude: 15.005845,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Zv%C4%9B%C5%99%C3%ADnek_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list, v žerďové polovině volná bílá zvonice se žlutými boky, s dveřmi a kruhovým oknem, obojí černé, se střechou a lucernou, obojí červené, a se žlutou makovicí. Nad věží letící žlutý výr. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě volná stříbrná zvonice se zlatými boky, s dveřmi a kruhovým oknem, obojí černé, se střechou a lucernou, obojí červené, a se zlatou makovicí. Nad věží letící zlatý výr."
    },
    {
        obec: "Zvěstov",
        kod: 531049,
        okres: "Benešov",
        kod_okres: "CZ0201",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25706,
        latitude: 49.632768,
        longitude: 14.792228,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Zv%C4%9Bstov_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, červený a modrý. V červeném pruhu volná bílá věž s cimbuřím, modrým gotickým oknem, v něm knížecí čepice nad převrácenými žlutými hornickými kladívky. V modrých pruzích nad sebou po dvou bílých květech tolije bahenní. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě červený kůl se stříbrnou věží s cimbuřím a modrým gotickým oknem, v něm knížecí čepice nad převrácenými zlatými hornickými kladívky. Kůl provázen čtyřmi (2, 2) stříbrnými květy tolije bahenní."
    },
    {
        obec: "Zvěstovice",
        kod: 548626,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58282,
        latitude: 49.848023,
        longitude: 15.512694,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Zv%C4%9Bstovice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm vodorovných pruhů, červený a šest střídavě bílých a modrých, v poměru 14 : 1 : 1 : 1 : 1 : 1 : 1. V červeném pruhu bílá krokev vycházející z osmé a deváté dvacetiny žerďového okraje listu s vrcholem na horním okraji listu, pod ní tři vějířovitě rozložené žluté obilné klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě mezi zvýšenou stříbrnou krokví a pětkrát stříbrno-modře dělenou patou tři vějířovitě rozložené zlaté obilné klasy."
    },
    {
        obec: "Zvíkov",
        kod: 535591,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37372,
        latitude: 48.980946,
        longitude: 14.617022,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/af/Zvikov_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno - zeleně šikmo dělený list. V červeném poli bílé trojpeří do kruhu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně šikmo děleném štítě vpravo stříbrné trojpeří do kruhu, vlevo šikmo položená kosa, hrotem doleva, vlevo provázená čtyřmi mřežovitě propletenými obilnými klasy se dvěma listy po stranách, vše zlaté."
    },
    {
        obec: "Zvíkov",
        kod: 536237,
        okres: "Český Krumlov",
        kod_okres: "CZ0312",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38232,
        latitude: 48.802632,
        longitude: 14.448859
    },
    {
        obec: "Zvíkovec",
        kod: 541061,
        okres: "Rokycany",
        kod_okres: "CZ0326",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33808,
        latitude: 49.954183,
        longitude: 13.688647,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Zvikovec_RO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, zelený, bílý, modrý a žlutý v poměru 5:2:2:2:5. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vyrůstá z modré vlnité paty se stříbrným vlnitým břevnem Kyčelnice trojlaločná se čtyřmi zelenými listy a stříbrným okvětím."
    },
    {
        obec: "Zvíkovské Podhradí",
        kod: 562165,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39701,
        latitude: 49.426678,
        longitude: 14.200666,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Znak_zvikovske_podhradi.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod hlavou sedmkrát stříbrno-modře polcenou vyrůstající stříbrná kvádrovaná věž s černým gotickým oknem, provázená vpravo berlou a vlevo postaveným mečem, obojí zlaté."
    },
    {
        obec: "Zvole",
        kod: 597155,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59256,
        latitude: 49.494545,
        longitude: 16.174948,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Zvole_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým mlýnským kolem pod žlutou korunou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrné mlýnské kolo převýšené zlatou korunou."
    },
    {
        obec: "Zvole",
        kod: 541478,
        okres: "Šumperk",
        kod_okres: "CZ0715",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78901,
        latitude: 49.839915,
        longitude: 16.914445,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/Zvole_SU_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: bílý, modrý a bílý v poměru 1:2:1. V modrém poli žlutá lilie. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě čáp přirozených barev provázený vlevo nahoře zlatou lilií."
    },
    {
        obec: "Zvole",
        kod: 539902,
        okres: "Praha-západ",
        kod_okres: "CZ020A",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 25245,
        latitude: 49.934765,
        longitude: 14.417796,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/36/Zvole_%28okres_Praha-z%C3%A1pad%29_znak.svg",
        coatOfArmsFlagDescription: "Modrý list s vlajícím bílým pětiúhelníkem vycházejícím z třetí třetiny horního a dolního okraje listu a s vrcholem v druhé pětině délky listu. Uprostřed leknínový trojlist opačných barev se dvěma listy v modrém poli. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě se sníženým stříbrným hrotem leknínový trojlist opačných barev."
    },
    {
        obec: "Zvoleněves",
        kod: 533122,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27325,
        latitude: 50.231256,
        longitude: 14.182316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Zvolen%C4%9Bves_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený. V zeleném vykračující bílá rozkřídlená husa se žlutou zbrojí a jazykem převýšená bílou mitrou s fimbriemi, v ní modrý středový kříž se žlutou lilií. V červeném pruhu bílá cukrová řepa se třemi žlutými listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zeleno-červeně polcený štít. Vpravo vykračující stříbrná rozkřídlená husa se zlatou zbrojí a jazykem převýšená stříbrnou mitrou s fimbriemi, v ní modrý heroldský kříž se zlatou lilií. Vlevo stříbrná cukrová řepa se třemi zlatými listy."
    },
    {
        obec: "Zvolenovice",
        kod: 588261,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58856,
        latitude: 49.163385,
        longitude: 15.505675
    },
    {
        obec: "Zvotoky",
        kod: 536873,
        okres: "Strakonice",
        kod_okres: "CZ0316",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38716,
        latitude: 49.212476,
        longitude: 13.757754,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/de/Zvotoky_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, zelený, vlnitý bílý, vlnitý modrý, vlnitý bílý a zvlněný zelený, v poměru 6 : 1 : 1 : 1 : 1. V žerďové části zeleného pruhu žluté mlýnské kolo. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě zlaté mlýnské kolo nad sníženým vlnitým, dvakrát stříbrno-modro-stříbrně vlnitě děleným břevnem."
    },
    {
        obec: "Žabčice",
        kod: 584231,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66463,
        latitude: 49.011697,
        longitude: 16.602677,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Zabcice-znak.png",
        coatOfArmsFlagDescription: "Červený list s bílou vydutou krokví s vrcholem na středu vlajícího okraje. Krokev vychází z jedné třetiny délky listu u žerdě. Červený žerďový klín dosahuje do jedné třetiny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě nad sníženým stříbrným břevnem se třemi položenými černými podkovami dva odvrácené stříbrné vinařské nože."
    },
    {
        obec: "Žabeň",
        kod: 552691,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73925,
        latitude: 49.708875,
        longitude: 18.304316,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Zaben_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy: zelený, bílý a zelený v poměru 1:2:1. V bílém pruhu zelená žába. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená žába."
    },
    {
        obec: "Žabonosy",
        kod: 513261,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28002,
        latitude: 50.035467,
        longitude: 15.027312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/01/%C5%BDabonosy_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, červený a modrý, v poměru 2:1:1. V žerďové části bílého pruhu černé plamenné orlí křídlo se žlutým jetelovitě zakončeným perizoniem. Pod ním přes červený a modrý pruh žlutá žába. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Polcený štít, v pravém stříbrném poli doleva obrácené černé plamenné orlí křídlo se zlatým, jetelovitě zakončeným perizoniem, v levém červeno-modře děleném poli zlatá žába."
    },
    {
        obec: "Žabovřesky",
        kod: 545368,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37341,
        latitude: 49.002908,
        longitude: 14.334957,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Zabovresky_CB_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, bílý a zvlněný modrý se třemi vrcholy a dvěma prohlubněmi. V bílém pruhu zelená žába mezi dvěma červenými růžemi se žlutými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrno - modře sníženě vlnitě děleném štítě nahoře zelená žába provázená po stranách dvěma červenými růžemi se zlatými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Žabovřesky nad Ohří",
        kod: 565938,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41002,
        latitude: 50.413264,
        longitude: 14.091136,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/Zabovresky_nad_Ohri_CZ_CoA.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, vlnitý modrý, vlnitý bílý, vlnitý modrý a zvlněný žlutý, v poměru 4:1:1:1:4, přes které je položen svislý zelený pruh, vycházející ze druhé čtvrtiny horního okraje listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě se sníženým dvakrát modro-stříbrně vlnitě děleným vlnitým břevnem zelená žába."
    },
    {
        obec: "Žacléř",
        kod: 579874,
        okres: "Trutnov",
        kod_okres: "CZ0525",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54201,
        latitude: 50.663393,
        longitude: 15.91074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/39/Zacler_znak.gif",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů - červený, zelený, červený, zelený a červený. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žádovice",
        kod: 586781,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69649,
        latitude: 49.013463,
        longitude: 17.197031,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/Zadovice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "Bílý list s červeným křížem s rameny širokými jednu třetinu šířky listu. Na středu kříže a každého ramene bílá šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě červený heroldský kříž s pěti (1,3,1) stříbrnými hvězdami. Kříž ve spodní části provázejí dva zelené lipové listy s řapíky nahoru."
    },
    {
        obec: "Žákava",
        kod: 530182,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33204,
        latitude: 49.630078,
        longitude: 13.530832,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Coat_of_arms_of_%C5%BD%C3%A1kava.svg",
        coatOfArmsFlagDescription: "Červený list se dvěma bílými kosmými pruhy vycházejícími z první a třetí čtvrtiny žerďového okraje do druhé a čtvrté šestiny dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě od poloviny dvě stříbrná kosmá břevna."
    },
    {
        obec: "Žákovice",
        kod: 523453,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75354,
        latitude: 49.454345,
        longitude: 17.660074,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/%C5%BD%C3%A1kovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy - bílý, zelený a bílý v poměru 1:2:1. V zeleném poli žlutý rýč. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý rýč mezi stříbrnými volskými rohy."
    },
    {
        obec: "Žáky",
        kod: 534641,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28601,
        latitude: 49.885374,
        longitude: 15.3684
    },
    {
        obec: "Žalany",
        kod: 567876,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41763,
        latitude: 50.59013,
        longitude: 13.906447
    },
    {
        obec: "Žalhostice",
        kod: 565946,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41101,
        latitude: 50.523199,
        longitude: 14.091474,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a7/%C5%BDalhostice%2C_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový zelený klín s vrcholem ve středu listu a pět vodorovných pruhů, bílý, modrý, červený, modrý a bílý. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelený vrch, v něm nad stříbrnou vlnitou patou vedle sebe tři zlatá jablka, prostřední se dvěma odvrácenými lístky na stopce. Na vrchu červený viničný kůl, obtočený dvěma zelenými stonky vinné révy, každá nahoře s přivráceným úponkem a odvráceným listem a dole se svěšeným modrým hroznem."
    },
    {
        obec: "Žalkovice",
        kod: 589225,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76823,
        latitude: 49.372004,
        longitude: 17.436042,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Zalkovice_znak.svg",
        coatOfArmsFlagDescription: "Znak je převeden do listu praporu: uprostřed modrého listu žlutý latinský kříž nad bílým půlměsícem s hroty vzhůru. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatý latinský kříž nad stříbrným stoupajícím půlměsícem."
    },
    {
        obec: "Žampach",
        kod: 581267,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.038071,
        longitude: 16.427306,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/%C5%BDampach_UO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý zubatý se třemi zuby vysokými osminu šířky listu a dvěma stejnými mezerami, a zelený, v poměru 1 : 3 : 4. V bílém pruhu zelený jehličnatý strom s vykořeněným hnědým kmenem v zeleném pruhu. Na kmeni bílá lovecká trubka se žlutým kováním a červeným závěsem kolem kmene.V žerďové a vlající části zeleného pruhu bílý pětilistý květ s červeným středem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě na zeleném návrší stříbrná věž s cimbuřím o třech stínkách. Vše přeloženo vykořeněným jehličnatým stromem přirozené barvy se stříbrnou loveckou trubkou se zlatým kováním a červeným závěsem kolem kmene. Trubka provázená po stranách dvěma stříbrnými pětilistými květy s červenými středy."
    },
    {
        obec: "Žamberk",
        kod: 581259,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56401,
        latitude: 50.087401,
        longitude: 16.465301,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/COA_Znak_m%C4%9Bsta_%C5%BDamberk.svg"
    },
    {
        obec: "Žandov",
        kod: 562297,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47107,
        latitude: 50.714039,
        longitude: 14.396344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/eb/%C5%BDandov_CoA.png"
    },
    {
        obec: "Žár",
        kod: 545376,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37401,
        latitude: 48.806971,
        longitude: 14.708287,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Zar_znak.jpeg",
        coatOfArmsFlagDescription: "Červený list s kosmým modrým pruhem širokým čtvrtinu délky listu a lemovaným bílými pruhy širokými desetinu šířky pruhu.V pruhu tři bílí kapři kosmo plující k hornímu rohu listu. Ve vlajícím červeném poli bílá růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě modré, stříbrně lemované kosmé břevno se třemi kosmo plujícími stříbrnými kapry, nahoře stříbrná růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Žáravice",
        kod: 576042,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53316,
        latitude: 50.105854,
        longitude: 15.557217,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/%C5%BD%C3%A1ravice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří černo-žlutě šachovaný (3 x 8) žerďový pruh široký čtvrtinu délky listu a tři vodorovné pruhy, bílý, červený a bílý, v poměru 6 : 1 : 1. Uprostřed listu černá zubří hlava se žlutými rohy a houžví. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Vpravo polcený štít. Pravé pole černo-zlatě šachované, v levém stříbrném poli černá zubří hlava se zlatými rohy a houžví nad červenými plameny."
    },
    {
        obec: "Žarošice",
        kod: 586790,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69634,
        latitude: 49.040748,
        longitude: 16.967239,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a2/%C5%BDaro%C5%A1ice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří sedm svislých pruhů, bílý, modrý, bílý, červený se žlutou listovou korunou s křížem na vrcholu, bílý, modrý a bílý, v poměru 1:1:1:4:1:1:1. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě červený vydutý hrot se zlatou listovou korunou s křížem na vrcholu, vpravo vztyčené krojidlo, vlevo kosíř, obojí stříbrné."
    },
    {
        obec: "Žárovná",
        kod: 529923,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.046302,
        longitude: 13.899419,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4f/%C5%BD%C3%A1rovn%C3%A1_coA.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým klínem vycházejícím z dolního okraje listu a s vrcholem na horním okraji listu. V žerďovém trojúhelníku postavený meč, ve vlajícím trojúhelníku patriarší kříž na kruhovém poli, obojí žluté.V klínu zelený smrk s černým kmenem vyrůstajícím z červených plamenů na druhé až čtvrté pětině dolního okraje listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě stříbrný hrot provázený vpravo postaveným mečem a vlevo patriarším křížem na kotouči, vše zlaté. V hrotu zelený smrk s černým kmenem vyrůstajícím z červených plamenů."
    },
    {
        obec: "Žatčany",
        kod: 584240,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66453,
        latitude: 49.087986,
        longitude: 16.733849,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Zatcany_znak.svg",
        coatOfArmsFlagDescription: "Modrý list se dvěma křížem přeloženými bílými rybami s červenými ploutvemi a ocasy, provázenými po stranách žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě křížem přeložené stříbrné ryby s červenými ploutvemi a ocasy, provázené nahoře a dole zlatou hvězdou."
    },
    {
        obec: "Žatec",
        kod: 588270,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58862,
        latitude: 49.206444,
        longitude: 15.509005,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Coat_of_arms_of_%C5%BDatec_JI.jpg"
    },
    {
        obec: "Žatec",
        kod: 566985,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.327276,
        longitude: 13.545886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/52/Znak_m%C4%9Bsta_%C5%BDatec.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, modrý, bílý a zelený, v poměru 9 : 2 : 9. V horním pruhu žlutá růže s červeným semeníkem a zelenými kališními lístky, v dolním pruhu zkřížené žluté obilné klasy, každý se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Zúženým stříbrným vlnitým břevnem modro-zeleně dělený štít. Nahoře zlatá růže s červeným semeníkem a zelenými kališními lístky, dole zkřížené zlaté obilné klasy, každý se dvěma listy."
    },
    {
        obec: "Ždánice",
        kod: 586803,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69632,
        latitude: 49.067387,
        longitude: 17.027619,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Zdanice_CoA_CZ.png",
        coatOfArmsFlagDescription: "Modrý list u žerdě s bílou lilií mezi dvěma vinnými ratolestmi, každá nahoře s listem, dole s hroznem, vše žluté. Pod lilií nad sebou dvě bílé hvězdičky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě stříbrná lilie, provázená dvěma zlatými vinnými ratolestmi, nahoře s listem, dole s hroznem. Mezi hrozny dvě (1,1) stříbrné pětihroté hvězdy."
    },
    {
        obec: "Ždánice",
        kod: 597163,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59301,
        latitude: 49.54756,
        longitude: 16.262191,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/68/%C5%BD%C4%8F%C3%A1nice_ZR_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a žlutý, v poměru 1 : 3. V modrém pruhu tři bílé pětilisté květy s modrými středy, ve žlutém černá zubří hlava s červeným jazykem a žlutou houžví. Poměr šířky k délce listu je 2 : 3 .",
        coatOfArmsDescription: "Ve zlatém štítě pod modrou hlavou s třemi pětilistými stříbrnými květy s modrými středy černá zubří hlava s červeným jazykem a zlatou houžví."
    },
    {
        obec: "Ždánice",
        kod: 513164,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28163,
        latitude: 49.969517,
        longitude: 14.959179
    },
    {
        obec: "Ždánov",
        kod: 554472,
        okres: "Domažlice",
        kod_okres: "CZ0321",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34401,
        latitude: 49.461029,
        longitude: 12.854517
    },
    {
        obec: "Žďár",
        kod: 529761,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37842,
        latitude: 49.254193,
        longitude: 15.076662
    },
    {
        obec: "Žďár",
        kod: 598861,
        okres: "Písek",
        kod_okres: "CZ0314",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39811,
        latitude: 49.233222,
        longitude: 14.227928
    },
    {
        obec: "Žďár",
        kod: 582743,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67902,
        latitude: 49.421675,
        longitude: 16.697884,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/6b/%C5%BD%C4%8F%C3%A1r_%28Blansko%29_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový pruh široký jednu čtvrtinu délky listu a dva vodorovné pruhy, zelený a červený, oddělené od bílého pruhu plamenným řezem se šesti plameny sahajícími do třetiny bílého pruhu. Ve střední a vlající části listu dvě zkřížená odvrácená krojidla hroty nahoru, provázená třemi (1, 2) žlutými šesticípými hvězdami. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno - zeleně polceném štítě pod stříbrnou plamennou hlavou zkřížená stříbrná krojidla provázená třemi (1, 2) zlatými hvězdami."
    },
    {
        obec: "Žďár",
        kod: 536971,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29412,
        latitude: 50.543762,
        longitude: 15.080449,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2e/%C5%BD%C4%8F%C3%A1r_%28okres_Mlad%C3%A1_Boleslav%29_CoA_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zubatý žlutý se šesti čtvercovými zuby, pěti stejnými a dvěma polovičními mezerami. V modrém pruhu žlutá zářící šesticípá hvězda, ve žlutém pruhu pět (3, 2) zelených listnatých stromů s hnědými kmeny. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-zlatě cimbuřovitě dělený štít. Nahoře zlatá zářící hvězda, dole pět (3, 2) listnatých stromů přirozené barvy."
    },
    {
        obec: "Žďár",
        kod: 598518,
        okres: "Rakovník",
        kod_okres: "CZ020C",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27033,
        latitude: 50.058554,
        longitude: 13.459724,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/%C5%BD%C4%8F%C3%A1r_%28Rakovn%C3%ADk_District%29_CoA.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, žlutý a zelený, v poměru 5 : 2 : 5. Uprostřed červeného a zeleného pruhu vztyčený žlutý lipový list. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-zlatě plamenným řezem dělený štít se zvýšenou zelenou patou oddělenou smrkovým řezem. Nahoře a v patě vztyčený zlatý lipový list."
    },
    {
        obec: "Žďár nad Metují",
        kod: 574686,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54955,
        latitude: 50.538847,
        longitude: 16.213389,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d1/%C5%BD%C4%8F%C3%A1r_nad_Metuj%C3%AD_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, bílý, plamenný červený, bílý a modrý, v poměru 3 : 1 : 1 : 3. Červený pruh má šest plamenů s vrcholy v první osmině šířky listu. V modrém pruhu vedle sebe tři žluté růže s červenými semeníky a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod stříbrnou plamennou hlavou stříbrná stolová hora, v ní modré šikmé břevno se třemi zlatými růžemi s červenými semeníky a zelenými kališními lístky."
    },
    {
        obec: "Žďár nad Orlicí",
        kod: 576956,
        okres: "Rychnov nad Kněžnou",
        kod_okres: "CZ0524",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 51723,
        latitude: 50.119855,
        longitude: 16.069446,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/89/%C5%BD%C4%8F%C3%A1r_nad_Orlic%C3%AD.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, červený, plamenný žlutý a zvlněný modrý se dvěma prohlubněmi, v poměru 3 : 7 : 2. Ve žlutém pruhu tři odkloněné zelené obilné klasy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě mezi červenou plamennou hlavou a modrou vlnitou patou tři odkloněné zelené obilné klasy."
    },
    {
        obec: "Žďár nad Sázavou",
        kod: 595209,
        okres: "Žďár nad Sázavou",
        kod_okres: "CZ0635",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 59101,
        latitude: 49.564288,
        longitude: 15.939507,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Zdar_nad_Sazavou_CoA.png",
        coatOfArmsFlagDescription: "List je kosmo dělený na červené žerďové a žluté vlající pole. Na úhlopříčky listu jsou položeny černé ostrve 5/6 délky listu dlouhé o šesti sucích. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žďárec",
        kod: 597171,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 59456,
        latitude: 49.379867,
        longitude: 16.266562
    },
    {
        obec: "Žďárek",
        kod: 544604,
        okres: "Liberec",
        kod_okres: "CZ0513",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46344,
        latitude: 50.640548,
        longitude: 15.111598,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/%C5%BD%C4%8F%C3%A1rek_-_CoA.png",
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, modrý, bílý, zelený, bílý a modrý, v poměru 1 : 1 : 5 : 1 : 1. V zeleném pruhu čtyři převázané odkloněné žluté obilné klasy, krajní s listem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-modře vlnitě dělený štít. Nahoře ve zvýšeném zeleném návrší čtyři převázané odkloněné zlaté obilné klasy, krajní s listem, dole dvě stříbrná vlnitá břevna."
    },
    {
        obec: "Žďárky",
        kod: 574694,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 54937,
        latitude: 50.469046,
        longitude: 16.227922,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/%C5%BD%C4%8F%C3%A1rky_znak.jpg",
        coatOfArmsFlagDescription: "Zelený list s bílým šikmým pruhem širokým jednu osminu šířky listu. Uprostřed listu žlutý skákající jelen s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě zlatý skákající jelen s červenou zbrojí, pod ním hornická kladívka, želízko zlaté se stříbrným topůrkem, mlátek stříbrný se zlatým topůrkem."
    },
    {
        obec: "Ždírec",
        kod: 530662,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58001,
        latitude: 49.629262,
        longitude: 15.642039
    },
    {
        obec: "Žďárná",
        kod: 582751,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67952,
        latitude: 49.468738,
        longitude: 16.758612,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e3/%C5%BD%C4%8F%C3%A1rn%C3%A1_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a zelené v poměru 9:1:1:1. V žerďové polovině horního pruhu šikmo položená černá větev se zelenými listy a s červenými jablky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-zeleně šikmo děleném štítě vpravo černá větev se zelenými listy a červenými jablky, vlevo stříbrná radlice."
    },
    {
        obec: "Ždírec",
        kod: 588288,
        okres: "Jihlava",
        kod_okres: "CZ0632",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58813,
        latitude: 49.454999,
        longitude: 15.678575,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d5/%C5%BDd%C3%ADrec_-_znak.svg",
        coatOfArmsFlagDescription: "Červený list s bílým plamenným žerďovým pruhem širokým třetinu délky listu. V červeném poli bílý kůň se žlutým uzděním vykračující k vlajícímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě pod zúženou plamenitou hlavou doleva vykračující kůň se zlatým uzděním, vše stříbrné."
    },
    {
        obec: "Ždírec",
        kod: 514195,
        okres: "Česká Lípa",
        kod_okres: "CZ0511",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 47201,
        latitude: 50.515564,
        longitude: 14.624388,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/91/Zdirec_CL_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený a zelený. Uprostřed listu bílá obrácená podkova, uvnitř žlutý oheň se třemi plameny převýšený zkříženými bílými sekerami na žlutých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-zeleně polceném štítě stříbrná obrácená podkova, uvnitř zlatý oheň se třemi plameny převýšený zkříženými stříbrnými sekerami se zlatými topůrky."
    },
    {
        obec: "Ždírec nad Doubravou",
        kod: 569780,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58263,
        latitude: 49.696057,
        longitude: 15.813706,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Zdirec_nad_Doubravou_CoA_CZ.svg",
        coatOfArmsFlagDescription: "Zelený list s bílou vidlicí, jejíž dvě ramena vycházejí u žerdi z horního a dolního okraje. Šířka ramene vidlice je 1/5 šířky listu. Zelený žerďový klín mezi rameny vidlice sahá do 1/3 délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrnou obrácenou vidlicí křížem dělený zelený štít s černou patou, z níž vyniká zlatý plamen."
    },
    {
        obec: "Ždírec",
        kod: 558605,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33601,
        latitude: 49.551092,
        longitude: 13.572958,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Zdirec_PJ_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří červený žerďový pruh široký polovinu délky listu a čtyři vodorovné pruhy, modrý, vlnitý bílý, vlnitý modrý a zvlněný bílý se třemi vrcholy a dvěma prohlubněmi. V červeném pruhu vzpřímený bílý beran se žlutou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně polceném štítě nad třikrát modro-stříbrně vlnitou patou vpravo černá plamenná orlice s červenou zbrojí a stříbrným jetelovitě zakončeným perizoniem. Vlevo vzpřímený stříbrný beran se zlatou zbrojí."
    },
    {
        obec: "Žebrák",
        kod: 532029,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26753,
        latitude: 49.875844,
        longitude: 13.897457,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3b/%C5%BDebr%C3%A1k_znak.png"
    },
    {
        obec: "Žehuň",
        kod: 538035,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28905,
        latitude: 50.135585,
        longitude: 15.291395,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/93/%C5%BDehu%C5%88_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, zelený, červený, vlnitý bílý a zvlněný modrý, v poměru 4 : 3 : 2 : 3. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Červeno-modře stříbrným vlnitým břevnem dělený štít se zelenou hlavou, v ní rozevřená kniha, v horním poli položená berla, v dolním kapr, vše zlaté."
    },
    {
        obec: "Žehušice",
        kod: 534650,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28575,
        latitude: 49.969555,
        longitude: 15.407492,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Znak_obce_%C5%BDehu%C5%A1ice.gif"
    },
    {
        obec: "Želeč",
        kod: 553417,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39174,
        latitude: 49.318442,
        longitude: 14.646734,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4b/%C5%BDele%C4%8D_TA_CoA.jpg",
        coatOfArmsFlagDescription: "Zeleno-bíle šikmo dělený list. Zelené pole vychází z první třetiny dolního okraje do dvou třetin horního okraje listu. V žerďovém poli žlutá lipová větévka, ve vlajícím červená růže se žlutým semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-stříbrně polceném štítě vpravo zlatá lipová větévka, vlevo červená růže se zlatým semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Želatovice",
        kod: 523640,
        okres: "Přerov",
        kod_okres: "CZ0714",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 75116,
        latitude: 49.444143,
        longitude: 17.506312,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/54/%C5%BDelatoviceznak.jpg",
        coatOfArmsFlagDescription: "List tvoří žlutý žerďový pruh široký třetinu délky listu a zelené pole s mečem šikmo, hrotem k hornímu cípu listu, podloženým kosmo vztyčeným klíčem zuby k žerďovému okraji, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě pod zkráceným vydutým klínem šikmo vztyčený meč kosmo podložený vztyčeným klíčem, provázený dole hvězdou, vše zlaté."
    },
    {
        obec: "Želeč",
        kod: 590240,
        okres: "Prostějov",
        kod_okres: "CZ0713",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79807,
        latitude: 49.346065,
        longitude: 17.097896,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c8/%C5%BDele%C4%8D_PV_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, střídavě zelených a bílých, v poměru 1 : 1 : 3 : 1 : 1. V prostředním pruhu tři žluté obilné klasy na jednom stonku se dvěma listy. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad červenou patou se třemi stříbrnými kužely tři zlaté obilné klasy na jednom stonku se dvěma listy provázené dvěma vztyčenými odvrácenými stříbrnými krojidly."
    },
    {
        obec: "Želechovice",
        kod: 552399,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78391,
        latitude: 49.754196,
        longitude: 17.144047,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Zelechovice_znak.jpeg",
        coatOfArmsFlagDescription: "Zeleno-červeně kosmo a šikmo čtvrcený list. Uprostřed kosmá žlutá berla závitem k žerďovému okraji s bílým sudariem šikmo přeložená bílou otkou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-zeleně kosmo čtvrceném štítě kosmá zlatá berla se stříbrným sudariem šikmo přeložená stříbrnou otkou."
    },
    {
        obec: "Želechovice nad Dřevnicí",
        kod: 500011,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76311,
        latitude: 49.218113,
        longitude: 17.747486,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/08/CoA_%C5%BDelechovice_nad_D%C5%99evnic%C3%AD.svg",
        coatOfArmsFlagDescription: "List tvoří pět svislých pruhů, modrý, bílý, zelený, bílý a modrý, v poměru 1 : 1 : 8 : 1 : 1. V zeleném pruhu bílý kostel s pěti černými okny, s nízkou věží k vlajícímu okraji a se sanktusníkem zakončeným žlutým kohoutem. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad vlnitou, třikrát stříbrno-modře vlnitě dělenou patou stříbrný kostel s pěti černými okny, s nízkou věží vlevo a se sanktusníkem zakončeným zlatým kohoutem."
    },
    {
        obec: "Želenice",
        kod: 599425,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27341,
        latitude: 50.210487,
        longitude: 14.164618
    },
    {
        obec: "Želenice",
        kod: 567426,
        okres: "Most",
        kod_okres: "CZ0425",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43401,
        latitude: 50.527718,
        longitude: 13.726169,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/42/Zelenice_MO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se dvěma žlutými žerďovými klíny a dvěma třikrát lomenými pruhy, černým a bílým, vycházejícími z první a druhé osminy horního a dolního okraje listu, s jedním vrcholem na žerďovém okraji a dvěma vrcholy v polovině délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-zlatě děleném štítě stříbrno-černě dělené třikrát nahoru lomené břevno, provázené nahoře stříbrným beranem ve skoku se zlatou zbrojí."
    },
    {
        obec: "Želešice",
        kod: 584266,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66443,
        latitude: 49.116986,
        longitude: 16.581479,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Znak_obce_%C5%BDele%C5%A1ice.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílým kosířem ostřím k žerdi a bílý s vinným hroznem na stonku se dvěma listy, vše červené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Červeno-stříbrně polcený štít, vpravo kosíř, vlevo vinný hrozen na stonku se dvěma listy, opačných barev."
    },
    {
        obec: "Želetice",
        kod: 586811,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69637,
        latitude: 49.014333,
        longitude: 17.008221,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Zeletice_HO_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, zelený a bílý. V zeleném dva zkřížené vinné stonky, každý s jedním vinným hroznem, k žerďovému okraji žlutý, k vlajícímu bílý. V bílém pruhu červená svatojakubská mušle. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-červeně děleném štítě nahoře na dvou zkřížených stoncích dva vinné hrozny, vpravo zlatý a vlevo stříbrný, dole stříbrný hrot s červenou svatojakubskou mušlí, provázený dvěma stříbrnými zvýšenými hroty."
    },
    {
        obec: "Želetava",
        kod: 591998,
        okres: "Třebíč",
        kod_okres: "CZ0634",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 67526,
        latitude: 49.141973,
        longitude: 15.673101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Znak_obce_%C5%BDeletava.gif",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, modrý, žlutý a modrý, v poměru 1:1:4. Uprostřed vlajícího modrého pruhu žlutá šestilistá růže s červeným semeníkem a zelenými kališními lístky. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým zlatým břevnem zlatá šestilistá růže s červeným semeníkem a zelenými kališními lístky."
    },
    {
        obec: "Želetice",
        kod: 595179,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67134,
        latitude: 48.936428,
        longitude: 16.182913,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Zeletice_ZN_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list; uprostřed žlutá, dolů obrácená svatojakubská mušle, v ní černá beraní hlava s červenou zbrojí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě v dolů obrácené zlaté svatojakubské mušli černá beraní hlava s červenou zbrojí."
    },
    {
        obec: "Železná",
        kod: 599417,
        okres: "Beroun",
        kod_okres: "CZ0202",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26601,
        latitude: 50.009803,
        longitude: 14.091043,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/2c/%C5%BDelezn%C3%A1_okr_Beroun_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, oboustranně zubatý posunutý bílý a zubatý červený, v poměru 8 : 1 : 1. V žerďové části nahoře zkřížené žluté sekery na bílých topůrkách, ve vlající části nahoře zkřížené žluté obilné klasy, každý se dvěma listy. Ve střední části bílá lilie nad žlutým půlměsícem cípy k hornímu okraji listu. Zubaté pruhy mají pět obdélníkových zubů a čtyři stejné mezery. Zuby jsou vysoké třetinu šířky zubu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě nad červenou patou oddělenou stříbrným cimbuřovým břevnem stříbrná lilie nad zlatým stoupajícím půlměsícem, provázená nahoře vpravo zkříženými zlatými sekerami na stříbrných topůrkách a vlevo zkříženými zlatými obilnými klasy, každý se dvěma s listy."
    },
    {
        obec: "Železná Ruda",
        kod: 557528,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34004,
        latitude: 49.137543,
        longitude: 13.235305,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/%C5%BDelezn%C3%A1_Ruda_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří zelený žerďový pruh široký třetinu délky listu a šest vodorovných pruhů, střídavě bílých a červených. V žerďové a střední části listu žluté jelení paroží s deseti výsadami. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Železné",
        kod: 584274,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66601,
        latitude: 49.359718,
        longitude: 16.45055,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Zelezne_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se dvěma bílými vztyčenými račími klepety. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě dvě vztyčená stříbrná račí klepeta."
    },
    {
        obec: "Železnice",
        kod: 573825,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50713,
        latitude: 50.472837,
        longitude: 15.384697,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/4c/%C5%BDeleznice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a bílý zubatý kvádrovaný s pěti zuby vysokými desetinu šířky listu a čtyřmi polovičními mezerami, v poměru 2:1. V horní části žerďové poloviny listu bílá hradební koruna. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Železný Brod",
        kod: 563871,
        okres: "Jablonec nad Nisou",
        kod_okres: "CZ0512",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 46822,
        latitude: 50.64284,
        longitude: 15.254186,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/72/Coat_of_arms_of_%C5%BDelezn%C3%BD_Brod.jpg",
        coatOfArmsFlagDescription: "List s modrým lemem širokým jednu osmnáctinu šířky listu. Vnitřní pole listu tvoří sedm vodorovných střídavě bílých a modrých pruhů v poměru 9:2:1:2:1:2:1. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Želiv",
        kod: 549215,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39444,
        latitude: 49.529938,
        longitude: 15.221912,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/8d/%C5%BDeliv_znak.png",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy: zelený, žlutý, bílý a modrý v poměru 2:1:1:2. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleno-modře děleném štítě šikmo položené stříbrné vidle-podávky kosmo přeložené zlatým vztyčeným klíčem zuby dolů."
    },
    {
        obec: "Želivsko",
        kod: 572195,
        okres: "Svitavy",
        kod_okres: "CZ0533",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56904,
        latitude: 49.641253,
        longitude: 16.570083,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7f/%C5%BDelivsko_-_znak.jpg",
        coatOfArmsDescription: "V zeleném štítě nad dole zkříženými a převázanými stříbrnými ratolestmi čelně vztyčená zlatá radlice se dvěma zelenými hřeby, na ní stříbrný ptáček."
    },
    {
        obec: "Želízy",
        kod: 535397,
        okres: "Mělník",
        kod_okres: "CZ0206",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27721,
        latitude: 50.423837,
        longitude: 14.464874,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/27/%C5%BDel%C3%ADzy_CoA.jpg",
        coatOfArmsFlagDescription: "Žlutý list se zeleným lemem širokým osminu šířky listu. V žerďové polovině žlutého pole čelně postavená červeno-modře polcená radlice, v ní tři (1, 2) žluté lilie, pod nimi tři (2, 1) bílé růže se žlutými semeníky.Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Ve zlatém štítě se zeleným lemem čelně postavená červeno-modře polcená radlice, v ní tři (1, 2) zlaté lilie nad třemi (2, 1) stříbrnými růžemi se zlatými semeníky."
    },
    {
        obec: "Želkovice",
        kod: 530565,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.464261,
        longitude: 13.876828,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/25/%C5%BDelkovice_CoA.jpg"
    },
    {
        obec: "Želnava",
        kod: 550761,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38451,
        latitude: 48.812147,
        longitude: 13.965123,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/74/%C5%BDelnava_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, zelený, vlnitý bílý se třemi vrcholy a dvěma prohlubněmi a zvlněný modrý, v poměru 9 : 1 : 6. Z vlnitého pruhu vyrůstají tři žluté obilné klasy, prostřední se dvěma listy, krajní s jedním odkloněným. V modrém pruhu bílá svatojakubská mušle. Poměr šířky k délce listu je 2 : 3",
        coatOfArmsDescription: "Zeleno-modře sníženým stříbrným vlnitým břevnem dělený štít. Nahoře vyrůstající tři zlaté obilné klasy, prostřední se dvěma listy, krajní s jedním odkloněným, dole stříbrná svatojakubská mušle."
    },
    {
        obec: "Ženklava",
        kod: 568732,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74267,
        latitude: 49.563672,
        longitude: 18.107297,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/df/CoA_of_%C5%BDenklava.svg",
        coatOfArmsFlagDescription: "Zelený list, u žerdi se třemi žlutými růžemi pod sebou, s bílými semeníky a červenými kališními lístky, vedle nich půl bílé zavinuté střely sahající do poloviny délky listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě vpravo pod sebou tři zlaté růže se stříbrnými semeníky a červenými kališními lístky, vlevo půl stříbrné zavinuté střely."
    },
    {
        obec: "Žeranovice",
        kod: 589233,
        okres: "Kroměříž",
        kod_okres: "CZ0721",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76901,
        latitude: 49.291574,
        longitude: 17.604528,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/db/Zeranovice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, červený s bílým roštem svatého Vavřince a modrý se žlutým lvem s červeným jazykem, drápy a bílými zuby. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeno-modře polceném štítě zlatý lev s červenou zbrojí a jazykem držící před sebou stříbrný rošt svatého Vavřince."
    },
    {
        obec: "Žeravice",
        kod: 586820,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69647,
        latitude: 49.022984,
        longitude: 17.23737,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/46/%C5%BDeravice_CoA_CZ.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy v poměru 1:1:2. Žerďový pruh tvoří čtyři vodorovné pruhy, střídavě černé a bílé. Střední pruh je červený. Ve vlajícím zeleném pruhu vinařský nůž - kosíř a srp, obojí přivrácené, bílé se žlutými rukojeťmi. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V zeleném štítě polcený štítek, vpravo třikrát černo-stříbrně dělený, vlevo červený. Po stranách dva zlaté svícny provázené doleva obráceným vinařským nožem - kosířem a srpem, obojí stříbrné se zlatými rukojeťmi."
    },
    {
        obec: "Žeraviny",
        kod: 586838,
        okres: "Hodonín",
        kod_okres: "CZ0645",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 69663,
        latitude: 48.908328,
        longitude: 17.391624,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1f/%C5%BDeraviny_CoA.png",
        coatOfArmsFlagDescription: "Bílý list se zelenou větví jeřábu se třemi vztyčenými listy a svěšeným červeným plodenstvím. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "Ve stříbrném štítě zelená větev jeřábu se třemi vztyčenými listy a svěšeným červeným plodenstvím."
    },
    {
        obec: "Žerčice",
        kod: 536989,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29446,
        latitude: 50.374263,
        longitude: 15.036019,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/96/%C5%BDer%C4%8Dice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list se žlutou volskou hlavou s krkem. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá volská hlava s krkem."
    },
    {
        obec: "Žeretice",
        kod: 573833,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50702,
        latitude: 50.353646,
        longitude: 15.411844,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/41/%C5%BDeretice_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, bílý, modrý a červený, v poměru 3 : 5 : 8. V modrém pruhu tři vztyčené žluté lipové listy, v červeném pruhu kosmo bílá řeznická sekera na žlutém topůrku ostřím k dolnímu okraji listu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Dělený štít. Horní pole stříbrno-modře vlnitě dělené, v modrém poli tři vztyčené zlaté lipové listy. V dolním červeném poli kosmo stříbrná řeznická sekera na zlatém topůrku."
    },
    {
        obec: "Žermanice",
        kod: 568163,
        okres: "Frýdek-Místek",
        kod_okres: "CZ0802",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 73937,
        latitude: 49.738583,
        longitude: 18.442932,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/85/%C5%BDermanice_CoA.svg",
        coatOfArmsFlagDescription: "Modrý list se žlutým žerďovým zvlněným pruhem se dvěma vrcholy a třemi prohlubněmi, širokým šestinu délky listu. V modrém poli koruna mezi dvěma odkloněnými orobinci, vše žluté. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlato-modře zvýšeně vlnitě děleném štítě dole koruna mezi dvěma odkloněnými orobinci, vše zlaté."
    },
    {
        obec: "Žernov",
        kod: 574708,
        okres: "Náchod",
        kod_okres: "CZ0523",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 55203,
        latitude: 50.430501,
        longitude: 16.05755,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/8/87/Zernov_znak.gif",
        coatOfArmsFlagDescription: "Modrý list se žlutou osmicípou hvězdou v bílém třmenu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě zlatá osmihrotá hvězda ve stříbrném třmenu."
    },
    {
        obec: "Žernov",
        kod: 577723,
        okres: "Semily",
        kod_okres: "CZ0514",
        kraj: "Liberecký kraj",
        kod_kraj: "CZ051",
        psc: 51263,
        latitude: 50.556845,
        longitude: 15.26975,
        coatOfArmsFlagDescription: "List tvoří pět vodorovných pruhů, žlutý, modrý, bílý, modrý a žlutý, v poměru 2 : 5 : 2 : 5 : 2. Uprostřed listu žlutý žernov s prázdným otvorem pro kypřici, prostrčeným bílým pruhem pod žerďovou a přes vlající část žernovu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě pod třemi zlatými jablky, každé s listem na stopce, zlatý žernov s prázdným otvorem pro kypřici zleva prostrčeným stříbrným vlnitým břevnem."
    },
    {
        obec: "Žernovice",
        kod: 537209,
        okres: "Prachatice",
        kod_okres: "CZ0315",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 38301,
        latitude: 49.028655,
        longitude: 14.039648,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/a/a6/%C5%BDernovice_CoA.png",
        coatOfArmsFlagDescription: "Modrý list, z jeho dolního okraje vyniká bílý vrch do poloviny šířky listu, v něm černá kobylí hlava s krkem. V modrém poli bílý žernov s položenou černou kypřicí mezi šesticípou hvězdou a vztyčeným dubovým listem, obojí žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě vztyčený zlatý dubový list mezi dvěma stříbrnými žernovy s položenými černými kypřicemi. Nahoře pět (3, 2) zlatých hvězd, dole stříbrný vrch s černou kobylí hlavou s krkem."
    },
    {
        obec: "Žernovník",
        kod: 582760,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67921,
        latitude: 49.407034,
        longitude: 16.545573,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Coat_of_arms_of_%C5%BDernovn%C3%ADk.svg",
        coatOfArmsFlagDescription: "List tvoří čtyři vodorovné pruhy, střídavě bílé a červené v poměru 3:1:1:1. V horním bílém pruhu červený žernov s bílou kypřicí. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrno-červeně děleném štítě nahoře červený žernov se stříbrnou kypřicí, dole stříbrný hřeben."
    },
    {
        obec: "Žerotice",
        kod: 595187,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67134,
        latitude: 48.92681,
        longitude: 16.168847,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/d/d7/%C5%BDerotice_CoA.png",
        coatOfArmsFlagDescription: "List tvoří bílý žerďový zubatý pruh široký osminu délky listu se čtyřmi mezerami a třemi stejně širokými zuby sahajícími do čtvrtiny délky listu, a červené pole. V poli bílý žernov s prázdným otvorem pro kypřici, provázeným dvěma zelenými vinnými hrozny, horním vztyčeným, dolním svěšeným. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeném štítě nad stříbrnou cimbuřovou patou se třemi stínkami stříbrný žernov s prázdným otvorem pro kypřici, provázeným dvěma zelenými vinnými hrozny, horním vztyčeným, dolním svěšeným."
    },
    {
        obec: "Žerotín",
        kod: 505862,
        okres: "Olomouc",
        kod_okres: "CZ0712",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 78401,
        latitude: 49.724632,
        longitude: 17.186914,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/6/64/%C5%BDerot%C3%ADn_CoA_OL_CZ.svg",
        coatOfArmsFlagDescription: "Červený list se třemi bílými rovnoramennými vzájemně se dotýkajícími trojúhelníky s vrcholy na dolním okraji listu. Výška trojúhelníků je rovna jedné čtvrtině šířky listu. Z prostředního vyskakuje černý lev se žlutou korunou a zbrojí. Poměr šířky k délce je 2:3.",
        coatOfArmsDescription: "V červeném štítě černý korunovaný lev se zlatou zbrojí a jazykem, vyskakující ze tří dolů obrácených kuželů."
    },
    {
        obec: "Žerotín",
        kod: 543012,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 44001,
        latitude: 50.286331,
        longitude: 13.912212,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/ca/%C5%BDerot%C3%ADn_CoA_LN_CZ.svg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, bílý a zelený. V bílém půl černé orlice se žlutou zbrojí, červeným jazykem a žlutým jetelovitě zakončeným perizoniem přiléhající k zelenému pruhu, v něm nahoře zkřížené žlutě hořící bílé svíce převázané žlutou stuhou, pod nimi bílá kvádrovaná kruhová studna. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Stříbrno-zeleně polcený štít, vpravo vyniká půl černé orlice se zlatou zbrojí, červeným jazykem a zlatým jetelovitě zakončeným perizoniem. Vlevo nahoře zkřížené zlatě hořící stříbrné svíce převázané zlatou stuhou, pod nimi stříbrná kvádrovaná kruhová studna."
    },
    {
        obec: "Žerůtky",
        kod: 582778,
        okres: "Blansko",
        kod_okres: "CZ0641",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67971,
        latitude: 49.440351,
        longitude: 16.536844
    },
    {
        obec: "Žerůtky",
        kod: 595195,
        okres: "Znojmo",
        kod_okres: "CZ0647",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 67151,
        latitude: 48.906004,
        longitude: 15.963194,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/12/%C5%BDer%C5%AFtky_%28Znojmo_District%29_CoA.jpg"
    },
    {
        obec: "Židlochovice",
        kod: 584282,
        okres: "Brno-venkov",
        kod_okres: "CZ0643",
        kraj: "Jihomoravský kraj",
        kod_kraj: "CZ064",
        psc: 66701,
        latitude: 49.039624,
        longitude: 16.618911,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/0/05/%C5%BDidlochovice_znak.png",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, žlutý, červený a žlutý, v poměru 1 : 2 : 1. V červeném pruhu třetinu délky listu od žerďového okraje vztyčená žlutá vinná ratolest se třemi (2, 1) hrozny. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Židněves",
        kod: 536997,
        okres: "Mladá Boleslav",
        kod_okres: "CZ0207",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 29406,
        latitude: 50.41173,
        longitude: 14.994635,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/fb/%C5%BDidn%C4%9Bves_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým liliovým trojhranem (2, 1). Uprostřed bílý pruh široký osminu šířky listu provlečený pod žerďovým ramenem trojhranu. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě zlatý liliový trojhran s rameny přeloženými dvojicemi odkloněných stříbrných leknínových listů, stonky do středu."
    },
    {
        obec: "Židovice",
        kod: 573841,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50732,
        latitude: 50.295469,
        longitude: 15.32061
    },
    {
        obec: "Židovice",
        kod: 565954,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41183,
        latitude: 50.446388,
        longitude: 14.233166,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/49/%C5%BDidovice_LT_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří tři svislé pruhy, zelený, bílý a zelený, v poměru 2 : 1 : 3. V žerďovém pruhu vztyčený žlutý vinný list se dvěma úponky převýšený knížecí korunou. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě vpravo vztyčený zlatý vinný list se dvěma úponky převýšený knížecí korunou, vlevo stříbrný vlnitý kůl."
    },
    {
        obec: "Žihle",
        kod: 559695,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33165,
        latitude: 50.04502,
        longitude: 13.37513,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/b/b2/%C5%BDihle_znak.png",
        coatOfArmsFlagDescription: "Červený list s bílou věží s černou branou se zdviženou žlutou mříží, dvěma okny a cimbuřím, ze kterých šlehají žluté plameny. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žihobce",
        kod: 557536,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.215645,
        longitude: 13.631095,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/1/1e/%C5%BDihobce_znak.jpg",
        coatOfArmsFlagDescription: "Čtvrcený list, horní žerďové a dolní vlající pole bílé, horní vlající pole červené s bílou psí hlavou s černým obojkem a dolní žerďové pole červené s bílou divadelní maskou. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Stříbrno-červeně čtvrcený štít. Ve druhém poli stříbrná psí hlava s černým obojkem, ve třetím poli stříbrná divadelní maska."
    },
    {
        obec: "Žichlínek",
        kod: 581275,
        okres: "Ústí nad Orlicí",
        kod_okres: "CZ0534",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 56301,
        latitude: 49.884005,
        longitude: 16.636393,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/99/%C5%BDichl%C3%ADnek_CoA.jpg",
        coatOfArmsFlagDescription: "Modrý list s bílým zvlněným pruhem se třemi vrcholy a dvěma prohlubněmi k vlajícímu okraji, vycházejícím z druhé a třetí devítiny horního a dolního okraje listu. Ve střední a vlající části listu uťatá lví tlapa držící kosmo tři žluté obilné klasy. Nad tlapou bílá radlice ostřím k vlajícímu okraji. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V modrém štítě nad sníženým stříbrným kosmým vlnitým břevnem stříbrná uťatá lví tlapa držící kosmo tři zlaté obilné klasy. Vlevo nahoře doleva obrácená stříbrná radlice."
    },
    {
        obec: "Žichovice",
        kod: 557544,
        okres: "Klatovy",
        kod_okres: "CZ0322",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 34201,
        latitude: 49.267334,
        longitude: 13.627949,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/3/32/%C5%BDichovice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list se žlutým svislým vlajícím pruhem širokým jednu pětinu délky listu. V modrém poli žerďový klín se sedmi svislými pruhy, střídavě červenými a bílými. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě na zlaté vlnité patě červený klín se třemi stříbrnými břevny provázený vpravo kosmou ostrví a vlevo postavenou prosticí (nádoba na sůl), obojí stříbrné."
    },
    {
        obec: "Žilina",
        kod: 533149,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27301,
        latitude: 50.099975,
        longitude: 14.005859,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Zilina_%28Kladno%29_znak.png",
        coatOfArmsFlagDescription: "List praporu tvoří tři vodorovné pruhy, zelený, červený a bílý v poměru 2:1:2. Poměr šířky a délky listu je 2:3.",
        coatOfArmsDescription: "Ve stříbrném poli z plamenů v patě štítu vyrůstá smrk v přirozených barvách."
    },
    {
        obec: "Žilov",
        kod: 559709,
        okres: "Plzeň-sever",
        kod_okres: "CZ0325",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33011,
        latitude: 49.840106,
        longitude: 13.313289,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/%C5%BDilov_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří tři vodorovné pruhy, černý, bílý a červený. V bílém pruhu modrá pravěká ryba Acrolepis gigas. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V černo-bílo-červeně děleném štítě uprostřed modrá pravěká ryba Acrolepis gigas."
    },
    {
        obec: "Žim",
        kod: 567884,
        okres: "Teplice",
        kod_okres: "CZ0426",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41501,
        latitude: 50.585066,
        longitude: 13.965364,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/73/%C5%BDim_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, červený a zelený, v poměru 1:2. V červeném pruhu tři řecké kříže vedle sebe, žlutý mezi bílými. V zeleném pruhu přivrácená bílo-červeně šachovaná křídla. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě pod červenou hlavou se třemi řeckými kříži, zlatý mezi stříbrnými, dvě přivrácená stříbrno-červeně šachovaná křídla."
    },
    {
        obec: "Žimutice",
        kod: 545384,
        okres: "České Budějovice",
        kod_okres: "CZ0311",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37366,
        latitude: 49.20382,
        longitude: 14.510527,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Zimutice_znak.jpg",
        coatOfArmsFlagDescription: "Modrý list s půlí žlutého koně ve skoku s červeným uzděním provázeným v půlkruhu osmi žlutými šesticípými hvězdami vycházejícími od horního rohu do horního cípu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modrém štítě půl zlatého koně ve skoku s červeným uzděním provázeným dolů do oblouku osmi zlatými hvězdami."
    },
    {
        obec: "Žinkovy",
        kod: 558630,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33554,
        latitude: 49.483512,
        longitude: 13.489551,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5c/%C5%BDinkovy_znak.png",
        coatOfArmsFlagDescription: "Červený list se třemi šikmými bílými pruhy vycházejícími z první, třetí a páté desetiny dolního okraje do šesté, osmé a desáté desetiny horního okraje listu. V horním rohu čtyři (1,2,1) bílá kruhová pole. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě stříbrný dvouocasý lev se zlatou zbrojí a jazykem, s hlavou převýšenou čtyřmi stříbrnými kotouči do oblouku."
    },
    {
        obec: "Žirov",
        kod: 561908,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39301,
        latitude: 49.464349,
        longitude: 15.323704
    },
    {
        obec: "Žirovnice",
        kod: 549231,
        okres: "Pelhřimov",
        kod_okres: "CZ0633",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 39468,
        latitude: 49.253284,
        longitude: 15.18834,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/%C5%BDirovnice_-_znak.svg",
        coatOfArmsFlagDescription: "Na středu modrého listu bílý jednorožec ve skoku, který zaujímá 5/6 šířky a 4/9 délky listu. (Městský znak převeden do listu praporu.) Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žíšov",
        kod: 563897,
        okres: "Tábor",
        kod_okres: "CZ0317",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 39181,
        latitude: 49.200048,
        longitude: 14.69348
    },
    {
        obec: "Žitenice",
        kod: 565962,
        okres: "Litoměřice",
        kod_okres: "CZ0423",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 41141,
        latitude: 50.555387,
        longitude: 14.156757,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/4/40/Zitenice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list se žlutým ondřejským křížem s rameny vycházejícími z horního a dolního rohu do třetí čtvrtiny horního a dolního okraje. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě zlatá orlice s modrou zbrojí stojící na zkřížených vztyčených zlatých klíčích."
    },
    {
        obec: "Žítková",
        kod: 592871,
        okres: "Uherské Hradiště",
        kod_okres: "CZ0722",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 68774,
        latitude: 48.980354,
        longitude: 17.880344,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Coat_of_arms_of_%C5%BD%C3%ADtkov%C3%A1.svg",
        coatOfArmsFlagDescription: "Žlutý list s modrým kosočtvercem s vrcholy uprostřed okrajů listu, podloženým černým ondřejským křížem s rameny širokými jednu sedminu šířky listu. V modrém poli ve žlutém srdci kvetoucím třemi žlutými trojlisty dvě zkřížené modré sekyry na černých topůrkách. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě modrý černě okřídlený hrot, v něm zlaté srdce se zkříženými modrými sekyrami na černých topůrkách kvetoucí třemi zlatými trojlisty."
    },
    {
        obec: "Žitovlice",
        kod: 503410,
        okres: "Nymburk",
        kod_okres: "CZ0208",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28934,
        latitude: 50.294076,
        longitude: 15.137094
    },
    {
        obec: "Životice",
        kod: 540137,
        okres: "Plzeň-jih",
        kod_okres: "CZ0324",
        kraj: "Plzeňský kraj",
        kod_kraj: "CZ032",
        psc: 33544,
        latitude: 49.466586,
        longitude: 13.685301
    },
    {
        obec: "Živanice",
        kod: 576051,
        okres: "Pardubice",
        kod_okres: "CZ0532",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53342,
        latitude: 50.062251,
        longitude: 15.644886,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/77/Zivanice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Zelený list se žlutým klínem vycházejícím z horního okraje a s vrcholem na dolním okraji. V klínu vztyčený hnědý žalud. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "Ve zlatém štítě vztyčený žalud na stopce přirozené barvy, přeložené dvěma zkříženými vztyčenými zelenými lipovými listy."
    },
    {
        obec: "Životice u Nového Jičína",
        kod: 547000,
        okres: "Nový Jičín",
        kod_okres: "CZ0804",
        kraj: "Moravskoslezský kraj",
        kod_kraj: "CZ080",
        psc: 74272,
        latitude: 49.557223,
        longitude: 18.047353,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/51/%C5%BDivotice_u_NJ_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří zelené čtvercové pole s bílým koněm ve skoku od žerdi a ve vlající části čtyři svislé pruhy střídavě žluté a zelené. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V zeleném štítě na zlatém dvojvrší stříbrný kůň ve skoku obrácený doleva ke zlatému listnatému stromu."
    },
    {
        obec: "Žiželice",
        kod: 533947,
        okres: "Kolín",
        kod_okres: "CZ0204",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28129,
        latitude: 50.132019,
        longitude: 15.393302,
        coatOfArms: "https://www.cestyapamatky.cz/img/galerie/obec/046/zizelice_znak.jpg",
        coatOfArmsDescription: "Znak byl Žiželicím udělen v roce 1321 králem Janem Lucemburským při jejich povýšení na město. Jeho základem je pečetní znamení majitele panství Dětocha z Třebelovic, které tvoří dva zkřížené meče v červeném poli a nad nimi královská koruna. Znak nebyl v novodobé historii obci potvrzen, neužívá ani vlajku."
    },
    {
        obec: "Žiželice",
        kod: 567019,
        okres: "Louny",
        kod_okres: "CZ0424",
        kraj: "Ústecký kraj",
        kod_kraj: "CZ042",
        psc: 43801,
        latitude: 50.367513,
        longitude: 13.540101,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/7a/%C5%BDi%C5%BEelice_LO_CoA.jpg",
        coatOfArmsFlagDescription: "List tvoří žerďový žlutý klín s vrcholem ve středu listu a tři vodorovné pruhy, modrý, bílý a červený. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "Modro-červeně stříbrným zúženým vlnitým břevnem dělený štít. Nahoře pět o sebe opřených obilných snopů, dole svěšená chmelová šiška, vše zlaté."
    },
    {
        obec: "Žižice",
        kod: 533157,
        okres: "Kladno",
        kod_okres: "CZ0203",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 27401,
        latitude: 50.246382,
        longitude: 14.154001,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Zizice_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červeno-bíle kosmo vlnitým řezem dělený list. V žerďovém poli bílá hlavice berly závitem k vlajícímu okraji, ve vlajícím poli kosmá zelená lipová větev s pěti listy. Vlnitý řez má čtyři vrcholy a tři prohlubně. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V červeno-stříbrně kosmo vlnitě děleném štítě vpravo stříbrná hlavice berly závitem doleva, vlevo kosmá zelená lipová větev s pěti listy."
    },
    {
        obec: "Žižkovo Pole",
        kod: 569801,
        okres: "Havlíčkův Brod",
        kod_okres: "CZ0631",
        kraj: "Kraj Vysočina",
        kod_kraj: "CZ063",
        psc: 58222,
        latitude: 49.610734,
        longitude: 15.735236,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Zizkovo_Pole_CZ_CoA.jpg",
        coatOfArmsFlagDescription: "Červený list s bílým kosmo položeným čtvercovým polem o úhlopříčce rovné šířce listu. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V červeném štítě kosmé stříbrné palisádové břevno. V něm černý palcát."
    },
    {
        obec: "Žlebské Chvalovice",
        kod: 574091,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53843,
        latitude: 49.892807,
        longitude: 15.566816,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/79/%C5%BDlebsk%C3%A9_Chvalovice_znak.jpg",
        coatOfArmsFlagDescription: "List tvoří dva svislé pruhy, zelený a červený s bílým trojvrším na dolním okraji listu s prostředním vrchem dosahujícím do třetí čtvrtiny šířky listu. Z něj vyniká bílý kříž s vodorovnými rameny vycházejícími z druhé desetiny žerďového a vlajícího okraje listu. Pod rameny kříže vyrůstá z obou krajních vrchů bílý jehličnatý strom. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleno-červeně polceném štítě vyniká z trojvrší latinský kříž do krajů, pod jehož rameny vyrůstá z obou krajních vrchů jehličnatý strom, vše stříbrné."
    },
    {
        obec: "Žleby",
        kod: 534668,
        okres: "Kutná Hora",
        kod_okres: "CZ0205",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 28561,
        latitude: 49.889685,
        longitude: 15.488634,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/7/71/%C5%BDleby_CoA.png",
        coatOfArmsFlagDescription: "Červený list s českým lvem držícím v pravé přední tlapě bílou lilii. Poměr šířky k délce listu je 2 : 3."
    },
    {
        obec: "Žlunice",
        kod: 573850,
        okres: "Jičín",
        kod_okres: "CZ0522",
        kraj: "Královéhradecký kraj",
        kod_kraj: "CZ052",
        psc: 50734,
        latitude: 50.30222,
        longitude: 15.382081,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/5/5f/%C5%BDlunice_Coa.jpg",
        coatOfArmsFlagDescription: "V zeleném listu kosmo meč hrotem k hornímu rohu, šikmo přeložený gotickým klíčem zuby nahoru a k vlajícímu okraji, obojí přeložené malířským štětcem, vše žluté. Poměr šířky k délce listu je 2 : 3.",
        coatOfArmsDescription: "V zeleném štítě kosmo vztyčený meč, šikmo přeložený vztyčeným, doleva obráceným gotickým klíčem, obojí přeloženo malířským štětcem, vše zlaté."
    },
    {
        obec: "Žlutava",
        kod: 586013,
        okres: "Zlín",
        kod_okres: "CZ0724",
        kraj: "Zlínský kraj",
        kod_kraj: "CZ072",
        psc: 76361,
        latitude: 49.199857,
        longitude: 17.49048,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e1/CoA_of_%C5%BDlutava.svg",
        coatOfArmsFlagDescription: "Červený list s bílým středovým křížem s rameny širokými dvanáctinu délky listu, uprostřed zelené čtvercové pole široké polovinu šířky listu s bílou lípou a žlutým lemem širokým dvacetinu šířky listu. Poměr šířky k délce listuje 2 : 3.",
        coatOfArmsDescription: "V červeném štítě se stříbrným heroldským křížem zlatě lemovaný zelený štítek se stříbrnou lípou."
    },
    {
        obec: "Žlutice",
        kod: 555762,
        okres: "Karlovy Vary",
        kod_okres: "CZ0412",
        kraj: "Karlovarský kraj",
        kod_kraj: "CZ041",
        psc: 36452,
        latitude: 50.092028,
        longitude: 13.163084,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/e/e5/%C5%BDlutice_%E2%80%93_znak_m%C4%9Bsta.jpg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, žlutý a zubatý černý se šesti zuby a pěti mezerami. Uprostřed vztyčené hrábě opačných barev. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žulová",
        kod: 541575,
        okres: "Jeseník",
        kod_okres: "CZ0711",
        kraj: "Olomoucký kraj",
        kod_kraj: "CZ071",
        psc: 79065,
        latitude: 50.30943,
        longitude: 17.098814,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/2/21/Zulova_CZ_CoA.svg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a zelený v poměru 4:1. V modrém pruhu na dělící lince bílá zřícenina hradu tvořená okrouhlou věží se dvěma okny nad sebou a s cimbuřím, z něhož vyrůstá zelený listnatý strom s hnědým kmenem. K věži přiléhá směrem k žerdi zachovalé stavení s otevřenou branou, třemi okny a červenou střechou se žlutým křížkem, a směrem k vlajícímu okraji přiléhá pobořená zeď s prázdnou branou. Poměr šířky k délce listu je 2:3."
    },
    {
        obec: "Žumberk",
        kod: 572641,
        okres: "Chrudim",
        kod_okres: "CZ0531",
        kraj: "Pardubický kraj",
        kod_kraj: "CZ053",
        psc: 53836,
        latitude: 49.873077,
        longitude: 15.858205,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/9b/%C5%BDumberk_CoA_CZ.gif",
        coatOfArmsDescription: "Znak městečka se zřejmě vyvinul z pečetního znamení, barvy jsou známy jen z literatury. Na červeném štítě stojí na kopci zelené barvy stříbrný hrad s černými střechami. Sestává ze střední budovy se třemi okny v obou podlažích a se stanovou střechou, po levé straně budovy stojí mohutná šestihranná věž se stanovou střechou a třikrát třemi okny nad sebou, po pravé straně je užší a nižší věž se dvěma okny nad sebou a helmicí s lucernou se zlatým křížem. Za věží stojí po pravé straně ještě malé přízemní stavení se dvěma okny. Po obou stranách kopce, na němž stojí hrad, jsou stříbrní dvouocasí lvi bez koruny, vztyčení ke skoku a obrácení ke středu štítu, předními tlapami jako by podpírali hrad."
    },
    {
        obec: "Županovice",
        kod: 564338,
        okres: "Příbram",
        kod_okres: "CZ020B",
        kraj: "Středočeský kraj",
        kod_kraj: "CZ020",
        psc: 26301,
        latitude: 49.707893,
        longitude: 14.298816,
        coatOfArms: "https://upload.wikimedia.org/wikipedia/commons/9/98/Coats_of_arms_%C5%BDupanovice.jpeg",
        coatOfArmsFlagDescription: "List tvoří dva vodorovné pruhy, modrý a bílý, v poměru 5:3. V žerďové polovině modrého pruhu žlutá kotva provázená nad rameny dvěma bílými lamačskými klíny, pod ní v bílém pruhu červená šesticípá hvězda. Poměr šířky k délce listu je 2:3.",
        coatOfArmsDescription: "V modro-stříbrně sníženě vlnitě děleném štítě nahoře zlatá kotva provázená nad rameny dvěma stříbrnými lamačskými klíny, dole červená hvězda."
    },
    {
        obec: "Županovice",
        kod: 562424,
        okres: "Jindřichův Hradec",
        kod_okres: "CZ0313",
        kraj: "Jihočeský kraj",
        kod_kraj: "CZ031",
        psc: 37881,
        latitude: 48.957211,
        longitude: 15.506224
    }
]
