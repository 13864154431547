const nicknames = [
    'Lucifer',
    'Bezhlavý Rytíř',
    'Čaroděj',
    'Paleček',
    'Sněhurka',
    'Azrael',
    'Vochomůrka',
    'Mrazík',
    'Gargamel',
    'Motýl Emanuel',
    'Fantomas',
    'Strýček Skrblík',
    'Šupináč',
    'Karkulka',
    'Brahma',
    'Durgá',
    'Ganéša',
    'Lakšmí',
    'Párvátí',
    'Sarasvátí',
    'Šiva',
    'Višňu',
    'Abel Tasman',
    'Francis Drake',
    'Bartolomeu Dias',
    'Kryštof Kolumbus',
    'Vitus Bering',
    'Myšpulín',
    'Homer Simpson',
    'Ijáček',
    'Asterix',
    'Obelix',
    'Medvídek Pú',
    'John Glenn',
    'Malenka',
    'Popelka',
    'Šípková Růženka',
    'Děda Mráz',
    'DJ Loutna',
    'Lara Croft',
    'Dvanácterák',
    'Panoramix',
    'Bílý Tesák',
    'Pocahontas',
    'Tichošlápek',
    'Tlustý Mnich',
    'Han Solo',
    'Terminátor',
    'Randle McMurphy',
    'Rocky Balboa',
    'Predátor',
    'HAL 9000',
    'Sarah Connor',
    'Kapitán Nemo',
    'Kapitán Demo',
    'Petr Pan',
    'Pan Tau',
    'Jack Sparrow',
    'Tarzan',
    'John Hammond',
    'Aquaman',
    'Jára Cimrman',
    'Hulk',
    'Vinnetou',
    'Old Shatterhand',
    'Sam Hawkens',
    'Nscho-tschi',
    'Frederic Santer',
    'Intschu-tschuna',
    'Klekih-petra',
    'Sherlock Holmes',
    'Mauglí',
    'Don Juan',
    'Hawkeye Pierce',
    'Radar',
    "Radar O'Reilly",
    'Maxwell Klinger',
    'Trapper John',
    'Frank Burns',
    'Margaret Houlihan',
    'Sherman T. Potter',
    'B. J. Hunnicutt',
    'Shrek',
    'Princezna Fiona',
    'Perníček',
    'Pinocchio',
    'Agent Smith',
    'Charles Winchester III ',
    'Otec Mulcahy',
    'Arnold Rimmer',
    'Kocour',
    'Kryton',
    'Dave Lister',
    'Holly',
    'Kristina Kochanská',
    'Bernard Black',
    'Manny Bianco',
    'Darth Vader',
    'Obi-Wan Kenobi',
    'Luke Skywalker',
    'Chewbacca',
    'R2-D2',
    'C-3PO',
    'Greedo',
    'Ufňukaná Uršula',
    'Vendelína Výstřednice',
    'Vrba Mlátička',
    'Héfaistos',
    'Leia Organa',
    'Michael Corleone',
    'Pallas Athéna',
    'Penelopa',
    'Odysseus',
    'Batman',
    'Iron Man',
    'Indiana Jones',
    'Hannibal Lecter',
    'Superman',
    'James Bond',
    'Freddy Krueger',
    'Glum',
    'Aragorn',
    'Gandalf',
    'Legolas',
    'Bilbo Pytlík',
    'Sauron',
    'Frodo Pytlík',
    'Galadriel',
    'Gimli',
    'Samvěd Křepelka',
    'Saruman',
    'Elrond',
    'Boromir',
    'Smělír Brandorád',
    'Alois Nebel',
    'Stewie Griffin',
    'Peter Griffin',
    'Meg Griffin',
    'Lois Griffin',
    'Glenn Quagmire',
    'Brian Griffin',
    'Joe Swanson',
    'Chris Griffin',
    'Otec Brown',
    'Umpa-lumpa',
    'Willy Wonka',
    'Jan Tleskač',
    'Stromovous',
    'Dědek Vrbák',
    'Děd Vševěd',
    'Bender',
    'Philip J. Fry',
    'Ferda Mravenec',
    'Mooncake',
    'Avocato',
    'Little Cato',
    'Gary Goodspeed',
    'Ash Graven',
    'Lord Commander',
    'Hue',
    'Thor',
    'Star-Lord',
    'Clarence',
    'Sheryl Goodspeed',
    'Quinn Airgon',
    'Tribore Menendez',
    'Gaia',
    'Uranos',
    'Scooby-Doo',
    'Kronos',
    'Rheia',
    'Okeanos',
    'Téthys',
    'Sindibád',
    'Hyperion',
    'Hádés',
    'Poseidón',
    'Kraken',
    'Zeus',
    'Afrodita',
    'Athéna',
    'Arés',
    'Héraklés',
    'Kirké',
    'Kapitán Haddock',
    'Tintin',
    'Cipísek',
    'Turanga Leela',
    'Dr. John Zoidberg',
    'John Rambo',
    'Amy Wong',
    'Profesor Hluchavka',
    'Otesánek',
    'Rumcajs',
    'Kocour v botách',
    'Mr. Bean',
    'Kojot Vilda',
    'Dean Moriarty',
    'Starouš Bull Lee',
    'Pan Šmudla',
    'Gríma Červivec',
    'Sal Paradise',
    'Jack Kerouac',
    'Allen Ginsberg',
    'Ken Kesey',
    'Neal Cassady',
    'Davy Jones',
    'King Kong',
    'Detektiv Klubíčko',
    'Barnabáš Blouznivý',
    'Sirius Black',
    'Albus Brumbál',
    'Rumburak',
    'Pan Spejbl',
    'Jirka Lábus',
    'Pes Žeryk',
    'Rufus Brousek',
    'Vilemína Červotočková',
    'Dudley Dursley',
    'Janus Paklíč',
    'Kapitán Hook',
    'Humphrey Říhal',
    'Peregrin Bral',
    'Smělmír Brandorád',
    'Hrabě Drákula',
    'Eric Cartman',
    'Kenny McCormic',
    'Kyle Broflovski',
    'Leopold Stotch',
    'Randy Marsh',
    'Stan Marsh',
    'Ike Broflovski',
    'Jimbo',
    'Mr. Hankey',
    'Pan Mackey',
    'Pan Klobouk',
    'Santa Claus',
    'Craig Tucker',
    'Docent Chocholoušek',
    'Joker',
    'Barbar Conan',
    'Brouk Pytlík',
    'Bugs Bunny',
    'Nestor Burma',
    'Grinch',
    "D'Artagnan",
    'Daredevil',
    'Deadpool',
    'Dj Arnoštek',
    'Doctor Strange',
    'Antoine Doinel',
    'Soudce Dredd',
    'Inspektor Dreyfus',
];

export default nicknames;
