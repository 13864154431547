export default {
    CZ041: [
        {
            obec: 'Abertamy',
            kod: 554979,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36235,
            latitude: 50.368855,
            longitude: 12.818377,
        },
        {
            obec: 'Andělská Hora',
            kod: 538001,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36471,
            latitude: 50.20449,
            longitude: 12.962918,
        },
        {
            obec: 'Aš',
            kod: 554499,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35201,
            latitude: 50.223983,
            longitude: 12.195113,
        },
        {
            obec: 'Bečov nad Teplou',
            kod: 554995,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36464,
            latitude: 50.083561,
            longitude: 12.838429,
        },
        {
            obec: 'Bochov',
            kod: 555029,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36471,
            latitude: 50.151007,
            longitude: 13.045282,
        },
        {
            obec: 'Boží Dar',
            kod: 506486,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.409844,
            longitude: 12.924571,
        },
        {
            obec: 'Božičany',
            kod: 555045,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36225,
            latitude: 50.258843,
            longitude: 12.769752,
        },
        {
            obec: 'Bražec',
            kod: 500101,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36471,
            latitude: 50.172681,
            longitude: 13.046575,
        },
        {
            obec: 'Březová',
            kod: 537870,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.197494,
            longitude: 12.870986,
        },
        {
            obec: 'Březová',
            kod: 560294,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.145752,
            longitude: 12.643488,
        },
        {
            obec: 'Bublava',
            kod: 560308,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.374494,
            longitude: 12.505287,
        },
        {
            obec: 'Bukovany',
            kod: 560316,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35755,
            latitude: 50.166437,
            longitude: 12.572766,
        },
        {
            obec: 'Citice',
            kod: 560324,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.162671,
            longitude: 12.61344,
        },
        {
            obec: 'Černava',
            kod: 538019,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36221,
            latitude: 50.291365,
            longitude: 12.711084,
        },
        {
            obec: 'Čichalov',
            kod: 506621,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36452,
            latitude: 50.12436,
            longitude: 13.17119,
        },
        {
            obec: 'Dalovice',
            kod: 537918,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36263,
            latitude: 50.247898,
            longitude: 12.895923,
        },
        {
            obec: 'Dasnice',
            kod: 560332,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35709,
            latitude: 50.146566,
            longitude: 12.567344,
        },
        {
            obec: 'Děpoltovice',
            kod: 538116,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36225,
            latitude: 50.294681,
            longitude: 12.814015,
        },
        {
            obec: 'Dolní Nivy',
            kod: 560341,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.243218,
            longitude: 12.637158,
        },
        {
            obec: 'Dolní Rychnov',
            kod: 538591,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35604,
            latitude: 50.164731,
            longitude: 12.645192,
        },
        {
            obec: 'Dolní Žandov',
            kod: 554502,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35493,
            latitude: 50.018055,
            longitude: 12.551125,
        },
        {
            obec: 'Doupovské Hradiště',
            kod: 500127,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36272,
            latitude: 50.25,
            longitude: 13,
        },
        {
            obec: 'Drmoul',
            kod: 554511,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.93907,
            longitude: 12.665618,
        },
        {
            obec: 'Františkovy Lázně',
            kod: 554529,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35101,
            latitude: 50.120159,
            longitude: 12.349781,
        },
        {
            obec: 'Habartov',
            kod: 560359,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35709,
            latitude: 50.183078,
            longitude: 12.550653,
        },
        {
            obec: 'Hájek',
            kod: 538159,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.283247,
            longitude: 12.919811,
        },
        {
            obec: 'Hazlov',
            kod: 554545,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35132,
            latitude: 50.156457,
            longitude: 12.272617,
        },
        {
            obec: 'Horní Blatná',
            kod: 555169,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36235,
            latitude: 50.390493,
            longitude: 12.771048,
        },
        {
            obec: 'Horní Slavkov',
            kod: 560367,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35731,
            latitude: 50.138742,
            longitude: 12.807697,
        },
        {
            obec: 'Hory',
            kod: 551651,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.213854,
            longitude: 12.789994,
        },
        {
            obec: 'Hradiště (vojenský újezd)',
            kod: 555177,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36006,
            latitude: 50.174549,
            longitude: 13.046831,
        },
        {
            obec: 'Hranice',
            kod: 554553,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35124,
            latitude: 50.304702,
            longitude: 12.175894,
        },
        {
            obec: 'Hroznětín',
            kod: 555185,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36233,
            latitude: 50.309508,
            longitude: 12.871918,
        },
        {
            obec: 'Cheb',
            kod: 554481,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.079633,
            longitude: 12.369964,
        },
        {
            obec: 'Chlum Svaté Maří',
            kod: 560375,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35709,
            latitude: 50.149949,
            longitude: 12.535762,
        },
        {
            obec: 'Chodov',
            kod: 578011,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36464,
            latitude: 50.068626,
            longitude: 12.863016,
        },
        {
            obec: 'Chodov',
            kod: 560383,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35735,
            latitude: 50.23983,
            longitude: 12.747709,
        },
        {
            obec: 'Chyše',
            kod: 555207,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36453,
            latitude: 50.104736,
            longitude: 13.249179,
        },
        {
            obec: 'Jáchymov',
            kod: 555215,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36251,
            latitude: 50.358573,
            longitude: 12.934767,
        },
        {
            obec: 'Jenišov',
            kod: 537926,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.226649,
            longitude: 12.800046,
        },
        {
            obec: 'Jindřichovice',
            kod: 560413,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.28276,
            longitude: 12.602422,
        },
        {
            obec: 'Josefov',
            kod: 511587,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35709,
            latitude: 50.211627,
            longitude: 12.579546,
        },
        {
            obec: 'Kaceřov',
            kod: 560421,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35751,
            latitude: 50.14885,
            longitude: 12.504505,
        },
        {
            obec: 'Karlovy Vary',
            kod: 554961,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.231952,
            longitude: 12.872062,
        },
        {
            obec: 'Kolová',
            kod: 555258,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.187545,
            longitude: 12.903449,
        },
        {
            obec: 'Krajková',
            kod: 560456,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35709,
            latitude: 50.216079,
            longitude: 12.534192,
        },
        {
            obec: 'Královské Poříčí',
            kod: 560464,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.19412,
            longitude: 12.67954,
        },
        {
            obec: 'Kraslice',
            kod: 560472,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.323835,
            longitude: 12.517588,
        },
        {
            obec: 'Krásná',
            kod: 538795,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35201,
            latitude: 50.234889,
            longitude: 12.168117,
        },
        {
            obec: 'Krásné Údolí',
            kod: 555304,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36401,
            latitude: 50.072448,
            longitude: 12.921351,
        },
        {
            obec: 'Krásno',
            kod: 538337,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35731,
            latitude: 50.109287,
            longitude: 12.786342,
        },
        {
            obec: 'Krásný Les',
            kod: 578045,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.344955,
            longitude: 13.002857,
        },
        {
            obec: 'Křižovatka',
            kod: 554596,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.194269,
            longitude: 12.393172,
        },
        {
            obec: 'Kynšperk nad Ohří',
            kod: 560499,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35751,
            latitude: 50.119038,
            longitude: 12.530388,
        },
        {
            obec: 'Kyselka',
            kod: 555347,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36272,
            latitude: 50.262803,
            longitude: 13.000007,
        },
        {
            obec: 'Lázně Kynžvart',
            kod: 554600,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35491,
            latitude: 50.010693,
            longitude: 12.624853,
        },
        {
            obec: 'Libá',
            kod: 554618,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35131,
            latitude: 50.128275,
            longitude: 12.230943,
        },
        {
            obec: 'Libavské Údolí',
            kod: 560502,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35751,
            latitude: 50.128978,
            longitude: 12.552602,
        },
        {
            obec: 'Lipová',
            kod: 554626,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.036398,
            longitude: 12.448928,
        },
        {
            obec: 'Loket',
            kod: 560537,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35733,
            latitude: 50.186112,
            longitude: 12.754163,
        },
        {
            obec: 'Lomnice',
            kod: 560545,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.211851,
            longitude: 12.632789,
        },
        {
            obec: 'Luby',
            kod: 554634,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35137,
            latitude: 50.252592,
            longitude: 12.406065,
        },
        {
            obec: 'Mariánské Lázně',
            kod: 554642,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.964699,
            longitude: 12.701298,
        },
        {
            obec: 'Merklín',
            kod: 555363,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36234,
            latitude: 50.32819,
            longitude: 12.863613,
        },
        {
            obec: 'Milhostov',
            kod: 554651,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.156155,
            longitude: 12.453308,
        },
        {
            obec: 'Milíkov',
            kod: 538906,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.055886,
            longitude: 12.547716,
        },
        {
            obec: 'Mírová',
            kod: 537934,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35735,
            latitude: 50.237288,
            longitude: 12.770109,
        },
        {
            obec: 'Mnichov',
            kod: 554677,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 50.03698,
            longitude: 12.787043,
        },
        {
            obec: 'Nebanice',
            kod: 554693,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.115352,
            longitude: 12.472085,
        },
        {
            obec: 'Nejdek',
            kod: 555380,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36221,
            latitude: 50.322529,
            longitude: 12.729474,
        },
        {
            obec: 'Nová Role',
            kod: 555398,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36225,
            latitude: 50.271028,
            longitude: 12.784334,
        },
        {
            obec: 'Nová Ves',
            kod: 560561,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36464,
            latitude: 50.084201,
            longitude: 12.775287,
        },
        {
            obec: 'Nové Hamry',
            kod: 506494,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36221,
            latitude: 50.360145,
            longitude: 12.719531,
        },
        {
            obec: 'Nové Sedlo',
            kod: 560570,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35734,
            latitude: 50.206578,
            longitude: 12.738121,
        },
        {
            obec: 'Nový Kostel',
            kod: 554707,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.217131,
            longitude: 12.445362,
        },
        {
            obec: 'Odrava',
            kod: 539554,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.103171,
            longitude: 12.48425,
        },
        {
            obec: 'Okrouhlá',
            kod: 538922,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.062566,
            longitude: 12.487772,
        },
        {
            obec: 'Oloví',
            kod: 560588,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35707,
            latitude: 50.251243,
            longitude: 12.558884,
        },
        {
            obec: 'Ostrov',
            kod: 555428,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.306035,
            longitude: 12.939183,
        },
        {
            obec: 'Otovice',
            kod: 537969,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.25545,
            longitude: 12.871721,
        },
        {
            obec: 'Otročín',
            kod: 555444,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36401,
            latitude: 50.032393,
            longitude: 12.892407,
        },
        {
            obec: 'Ovesné Kladruby',
            kod: 539473,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.953112,
            longitude: 12.779206,
        },
        {
            obec: 'Pernink',
            kod: 555452,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36236,
            latitude: 50.365797,
            longitude: 12.783825,
        },
        {
            obec: 'Pila',
            kod: 556947,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.179793,
            longitude: 12.925973,
        },
        {
            obec: 'Plesná',
            kod: 554740,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35135,
            latitude: 50.220815,
            longitude: 12.34681,
        },
        {
            obec: 'Podhradí',
            kod: 538817,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35201,
            latitude: 50.255461,
            longitude: 12.201857,
        },
        {
            obec: 'Pomezí nad Ohří',
            kod: 538868,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.086208,
            longitude: 12.279652,
        },
        {
            obec: 'Potůčky',
            kod: 555479,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36235,
            latitude: 50.428273,
            longitude: 12.736597,
        },
        {
            obec: 'Poustka',
            kod: 577979,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.140272,
            longitude: 12.298291,
        },
        {
            obec: 'Prameny',
            kod: 539538,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 50.050184,
            longitude: 12.732909,
        },
        {
            obec: 'Přebuz',
            kod: 560596,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.36589,
            longitude: 12.620158,
        },
        {
            obec: 'Pšov',
            kod: 555525,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36452,
            latitude: 50.051663,
            longitude: 13.170783,
        },
        {
            obec: 'Rotava',
            kod: 560600,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35701,
            latitude: 50.29638,
            longitude: 12.573526,
        },
        {
            obec: 'Rovná',
            kod: 560618,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.104512,
            longitude: 12.668852,
        },
        {
            obec: 'Sadov',
            kod: 555533,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.267226,
            longitude: 12.897194,
        },
        {
            obec: 'Skalná',
            kod: 554812,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.170411,
            longitude: 12.361561,
        },
        {
            obec: 'Smolné Pece',
            kod: 538027,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36225,
            latitude: 50.291579,
            longitude: 12.765442,
        },
        {
            obec: 'Sokolov',
            kod: 560286,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.174629,
            longitude: 12.659992,
        },
        {
            obec: 'Stanovice',
            kod: 555550,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36001,
            latitude: 50.165217,
            longitude: 12.874064,
        },
        {
            obec: 'Stará Voda',
            kod: 539112,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.991631,
            longitude: 12.59591,
        },
        {
            obec: 'Staré Sedlo',
            kod: 560642,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.181807,
            longitude: 12.719941,
        },
        {
            obec: 'Stráž nad Ohří',
            kod: 555584,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.337331,
            longitude: 13.051067,
        },
        {
            obec: 'Stružná',
            kod: 555592,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36471,
            latitude: 50.183216,
            longitude: 13.005785,
        },
        {
            obec: 'Stříbrná',
            kod: 560651,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.356766,
            longitude: 12.526519,
        },
        {
            obec: 'Svatava',
            kod: 538434,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35703,
            latitude: 50.192341,
            longitude: 12.625358,
        },
        {
            obec: 'Šabina',
            kod: 538396,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.135748,
            longitude: 12.582432,
        },
        {
            obec: 'Šemnice',
            kod: 555614,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36272,
            latitude: 50.237016,
            longitude: 12.976213,
        },
        {
            obec: 'Šindelová',
            kod: 560677,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35801,
            latitude: 50.318764,
            longitude: 12.603439,
        },
        {
            obec: 'Štědrá',
            kod: 555622,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36452,
            latitude: 50.058313,
            longitude: 13.114279,
        },
        {
            obec: 'Tatrovice',
            kod: 538663,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35735,
            latitude: 50.276819,
            longitude: 12.697409,
        },
        {
            obec: 'Teplá',
            kod: 555631,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36461,
            latitude: 49.981953,
            longitude: 12.86316,
        },
        {
            obec: 'Teplička',
            kod: 537845,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36464,
            latitude: 50.148702,
            longitude: 12.85168,
        },
        {
            obec: 'Těšovice',
            kod: 579360,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35601,
            latitude: 50.182307,
            longitude: 12.676998,
        },
        {
            obec: 'Toužim',
            kod: 555657,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36401,
            latitude: 50.060604,
            longitude: 12.985171,
        },
        {
            obec: 'Trstěnice',
            kod: 554855,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.91921,
            longitude: 12.676134,
        },
        {
            obec: 'Třebeň',
            kod: 539023,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.128744,
            longitude: 12.400268,
        },
        {
            obec: 'Tři Sekery',
            kod: 554880,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.942277,
            longitude: 12.616745,
        },
        {
            obec: 'Tuřany',
            kod: 539619,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35002,
            latitude: 50.08412,
            longitude: 12.522102,
        },
        {
            obec: 'Útvina',
            kod: 555681,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36401,
            latitude: 50.070847,
            longitude: 12.954852,
        },
        {
            obec: 'Valeč',
            kod: 555690,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36453,
            latitude: 50.174239,
            longitude: 13.254676,
        },
        {
            obec: 'Valy',
            kod: 539481,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.976907,
            longitude: 12.654905,
        },
        {
            obec: 'Velichov',
            kod: 555703,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36301,
            latitude: 50.284179,
            longitude: 13.009826,
        },
        {
            obec: 'Velká Hleďsebe',
            kod: 539279,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.966767,
            longitude: 12.666767,
        },
        {
            obec: 'Velký Luh',
            kod: 578002,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.19907,
            longitude: 12.372344,
        },
        {
            obec: 'Verušičky',
            kod: 555711,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36452,
            latitude: 50.14131,
            longitude: 13.173868,
        },
        {
            obec: 'Vintířov',
            kod: 560685,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35735,
            latitude: 50.233928,
            longitude: 12.717598,
        },
        {
            obec: 'Vlkovice',
            kod: 539376,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.948927,
            longitude: 12.739122,
        },
        {
            obec: 'Vojkovice',
            kod: 555738,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36273,
            latitude: 50.303424,
            longitude: 13.016307,
        },
        {
            obec: 'Vojtanov',
            kod: 539074,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35134,
            latitude: 50.166294,
            longitude: 12.317021,
        },
        {
            obec: 'Vrbice',
            kod: 566675,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36453,
            latitude: 50.14965,
            longitude: 13.233839,
        },
        {
            obec: 'Vřesová',
            kod: 560707,
            okres: 'Sokolov',
            kod_okres: 'CZ0413',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35735,
            latitude: 50.259197,
            longitude: 12.696593,
        },
        {
            obec: 'Vysoká Pec',
            kod: 578029,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36221,
            latitude: 50.348275,
            longitude: 12.696743,
        },
        {
            obec: 'Zádub-Závišín',
            kod: 539431,
            okres: 'Cheb',
            kod_okres: 'CZ0411',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 35301,
            latitude: 49.971876,
            longitude: 12.744072,
        },
        {
            obec: 'Žlutice',
            kod: 555762,
            okres: 'Karlovy Vary',
            kod_okres: 'CZ0412',
            kraj: 'Karlovarský kraj',
            kod_kraj: 'CZ041',
            psc: 36452,
            latitude: 50.092028,
            longitude: 13.163084,
        },
    ],
    CZ031: [
        {
            obec: 'Adamov',
            kod: 535826,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 49.000624,
            longitude: 14.539603,
        },
        {
            obec: 'Albrechtice nad Vltavou',
            kod: 549258,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39816,
            latitude: 49.253337,
            longitude: 14.302929,
        },
        {
            obec: 'Babice',
            kod: 537241,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.024865,
            longitude: 14.237677,
        },
        {
            obec: 'Balkova Lhota',
            kod: 563251,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.452124,
            longitude: 14.608319,
        },
        {
            obec: 'Báňovice',
            kod: 562726,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.011855,
            longitude: 15.49034,
        },
        {
            obec: 'Bavorov',
            kod: 550809,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.121951,
            longitude: 14.079028,
        },
        {
            obec: 'Bečice',
            kod: 536156,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.215784,
            longitude: 14.501318,
        },
        {
            obec: 'Bečice',
            kod: 563366,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39175,
            latitude: 49.381437,
            longitude: 14.539787,
        },
        {
            obec: 'Bednárec',
            kod: 562548,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.195339,
            longitude: 15.093296,
        },
        {
            obec: 'Bednáreček',
            kod: 561053,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.210179,
            longitude: 15.144034,
        },
        {
            obec: 'Bechyně',
            kod: 552054,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.295336,
            longitude: 14.468202,
        },
        {
            obec: 'Bělčice',
            kod: 550817,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38743,
            latitude: 49.502517,
            longitude: 13.875856,
        },
        {
            obec: 'Běleč',
            kod: 560448,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.543779,
            longitude: 14.837357,
        },
        {
            obec: 'Benešov nad Černou',
            kod: 545406,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38282,
            latitude: 48.729507,
            longitude: 14.627471,
        },
        {
            obec: 'Bernartice',
            kod: 549266,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.368999,
            longitude: 14.381118,
        },
        {
            obec: 'Besednice',
            kod: 545414,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38281,
            latitude: 48.790089,
            longitude: 14.556987,
        },
        {
            obec: 'Bezdědovice',
            kod: 598895,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.444294,
            longitude: 13.880068,
        },
        {
            obec: 'Bílsko',
            kod: 550833,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.159145,
            longitude: 14.059108,
        },
        {
            obec: 'Blatná',
            kod: 550850,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.424993,
            longitude: 13.881864,
        },
        {
            obec: 'Blažejov',
            kod: 561711,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37852,
            latitude: 49.142295,
            longitude: 15.096553,
        },
        {
            obec: 'Bohdalovice',
            kod: 536253,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38101,
            latitude: 48.74294,
            longitude: 14.286864,
        },
        {
            obec: 'Bohumilice',
            kod: 550116,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.09618,
            longitude: 13.816441,
        },
        {
            obec: 'Bohunice',
            kod: 537527,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.132496,
            longitude: 13.973287,
        },
        {
            obec: 'Boletice (vojenský újezd)',
            kod: 545422,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38229,
            latitude: 48.824975,
            longitude: 14.217435,
        },
        {
            obec: 'Borek',
            kod: 544272,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37367,
            latitude: 49.023491,
            longitude: 14.500984,
        },
        {
            obec: 'Borkovice',
            kod: 552097,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.208048,
            longitude: 14.643532,
        },
        {
            obec: 'Borotín',
            kod: 552101,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39135,
            latitude: 49.505132,
            longitude: 14.61307,
        },
        {
            obec: 'Borová Lada',
            kod: 545902,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38492,
            latitude: 48.989939,
            longitude: 13.659961,
        },
        {
            obec: 'Borovany',
            kod: 544281,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37312,
            latitude: 48.898704,
            longitude: 14.642367,
        },
        {
            obec: 'Borovany',
            kod: 598780,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.343099,
            longitude: 14.392567,
        },
        {
            obec: 'Borovnice',
            kod: 535681,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.912174,
            longitude: 14.519089,
        },
        {
            obec: 'Boršov nad Vltavou',
            kod: 544299,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37382,
            latitude: 48.921882,
            longitude: 14.434092,
        },
        {
            obec: 'Bořetín',
            kod: 562742,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.189321,
            longitude: 15.211941,
        },
        {
            obec: 'Bošice',
            kod: 550124,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.089388,
            longitude: 13.840431,
        },
        {
            obec: 'Bošilec',
            kod: 535401,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37365,
            latitude: 49.149418,
            longitude: 14.646319,
        },
        {
            obec: 'Boudy',
            kod: 562122,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.448057,
            longitude: 14.035591,
        },
        {
            obec: 'Božetice',
            kod: 549291,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.45123,
            longitude: 14.444001,
        },
        {
            obec: 'Bradáčov',
            kod: 552127,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.490902,
            longitude: 14.871519,
        },
        {
            obec: 'Branice',
            kod: 549304,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.402569,
            longitude: 14.33964,
        },
        {
            obec: 'Branišov',
            kod: 551490,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.978807,
            longitude: 14.395791,
        },
        {
            obec: 'Bratronice',
            kod: 537063,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.365361,
            longitude: 13.842454,
        },
        {
            obec: 'Brloh',
            kod: 545431,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38206,
            latitude: 48.92998,
            longitude: 14.218667,
        },
        {
            obec: 'Břehov',
            kod: 536059,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.022388,
            longitude: 14.330668,
        },
        {
            obec: 'Březí',
            kod: 530018,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 26242,
            latitude: 49.507873,
            longitude: 13.797583,
        },
        {
            obec: 'Březina',
            kod: 507733,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.290639,
            longitude: 14.90976,
        },
        {
            obec: 'Březnice',
            kod: 552135,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39171,
            latitude: 49.251874,
            longitude: 14.514089,
        },
        {
            obec: 'Budeč',
            kod: 546020,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37892,
            latitude: 49.087277,
            longitude: 15.589973,
        },
        {
            obec: 'Budislav',
            kod: 552143,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.293941,
            longitude: 14.837518,
        },
        {
            obec: 'Budíškovice',
            kod: 546038,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37891,
            latitude: 49.076348,
            longitude: 15.531006,
        },
        {
            obec: 'Budkov',
            kod: 561576,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.070156,
            longitude: 14.007074,
        },
        {
            obec: 'Budyně',
            kod: 560171,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.14606,
            longitude: 14.071176,
        },
        {
            obec: 'Bujanov',
            kod: 545449,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38241,
            latitude: 48.702429,
            longitude: 14.429747,
        },
        {
            obec: 'Buk',
            kod: 537519,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.038011,
            longitude: 13.845247,
        },
        {
            obec: 'Bušanovice',
            kod: 550159,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.115542,
            longitude: 13.934788,
        },
        {
            obec: 'Buzice',
            kod: 529966,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.42273,
            longitude: 13.932715,
        },
        {
            obec: 'Cehnice',
            kod: 550906,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38752,
            latitude: 49.215169,
            longitude: 14.029493,
        },
        {
            obec: 'Cep',
            kod: 509191,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 48.919602,
            longitude: 14.807457,
        },
        {
            obec: 'Cerhonice',
            kod: 549321,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.418097,
            longitude: 14.055739,
        },
        {
            obec: 'Cizkrajov',
            kod: 546054,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37881,
            latitude: 49.03065,
            longitude: 15.389754,
        },
        {
            obec: 'Čakov',
            kod: 535541,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.980659,
            longitude: 14.307773,
        },
        {
            obec: 'Čečelovice',
            kod: 510068,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.374116,
            longitude: 13.794971,
        },
        {
            obec: 'Čejetice',
            kod: 550922,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.25292,
            longitude: 14.020488,
        },
        {
            obec: 'Čejkovice',
            kod: 544329,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.014436,
            longitude: 14.383008,
        },
        {
            obec: 'Čenkov u Bechyně',
            kod: 598976,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.233538,
            longitude: 14.500943,
        },
        {
            obec: 'Čepřovice',
            kod: 550949,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38756,
            latitude: 49.155339,
            longitude: 13.975181,
        },
        {
            obec: 'Černá v Pošumaví',
            kod: 545457,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38223,
            latitude: 48.738072,
            longitude: 14.110637,
        },
        {
            obec: 'Černýšovice',
            kod: 563722,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.325232,
            longitude: 14.520546,
        },
        {
            obec: 'Červený Hrádek',
            kod: 509116,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.122981,
            longitude: 15.539027,
        },
        {
            obec: 'České Budějovice',
            kod: 544256,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.975758,
            longitude: 14.480355,
        },
        {
            obec: 'České Velenice',
            kod: 546089,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37810,
            latitude: 48.768608,
            longitude: 14.963775,
        },
        {
            obec: 'Český Krumlov',
            kod: 545392,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38101,
            latitude: 48.812835,
            longitude: 14.317566,
        },
        {
            obec: 'Český Rudolec',
            kod: 546097,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37883,
            latitude: 49.068454,
            longitude: 15.324472,
        },
        {
            obec: 'Čestice',
            kod: 550957,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.167929,
            longitude: 13.8038,
        },
        {
            obec: 'Číčenice',
            kod: 550965,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38771,
            latitude: 49.153152,
            longitude: 14.230951,
        },
        {
            obec: 'Čimelice',
            kod: 549339,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.465681,
            longitude: 14.069328,
        },
        {
            obec: 'Číměř',
            kod: 546101,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37832,
            latitude: 49.060706,
            longitude: 15.073772,
        },
        {
            obec: 'Čížkrajice',
            kod: 551503,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.806592,
            longitude: 14.636226,
        },
        {
            obec: 'Čížová',
            kod: 549347,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39831,
            latitude: 49.356861,
            longitude: 14.093158,
        },
        {
            obec: 'Čkyně',
            kod: 550167,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.115134,
            longitude: 13.829161,
        },
        {
            obec: 'Člunek',
            kod: 561703,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37861,
            latitude: 49.111546,
            longitude: 15.12686,
        },
        {
            obec: 'Dačice',
            kod: 546127,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.07992,
            longitude: 15.434304,
        },
        {
            obec: 'Dasný',
            kod: 535249,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.019337,
            longitude: 14.407512,
        },
        {
            obec: 'Dešná',
            kod: 546143,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37873,
            latitude: 48.957675,
            longitude: 15.542926,
        },
        {
            obec: 'Deštná',
            kod: 546151,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37825,
            latitude: 49.265237,
            longitude: 14.924058,
        },
        {
            obec: 'Dírná',
            kod: 552208,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39127,
            latitude: 49.242967,
            longitude: 14.844867,
        },
        {
            obec: 'Dívčí Kopy',
            kod: 529753,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.260383,
            longitude: 15.046007,
        },
        {
            obec: 'Dívčice',
            kod: 544361,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37348,
            latitude: 49.108733,
            longitude: 14.309399,
        },
        {
            obec: 'Dlouhá Lhota',
            kod: 563650,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39155,
            latitude: 49.352618,
            longitude: 14.789052,
        },
        {
            obec: 'Dobev',
            kod: 549363,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.29535,
            longitude: 14.048531,
        },
        {
            obec: 'Dobrá Voda u Českých Budějovic',
            kod: 535206,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37316,
            latitude: 48.968311,
            longitude: 14.525035,
        },
        {
            obec: 'Dobrohošť',
            kod: 562416,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.078488,
            longitude: 15.488583,
        },
        {
            obec: 'Dobronice u Bechyně',
            kod: 552224,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.343411,
            longitude: 14.496837,
        },
        {
            obec: 'Dobšice',
            kod: 536199,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.216003,
            longitude: 14.483885,
        },
        {
            obec: 'Dolní Bukovsko',
            kod: 544388,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37365,
            latitude: 49.170963,
            longitude: 14.58137,
        },
        {
            obec: 'Dolní Dvořiště',
            kod: 545465,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38272,
            latitude: 48.656521,
            longitude: 14.452309,
        },
        {
            obec: 'Dolní Hořice',
            kod: 552241,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39155,
            latitude: 49.435483,
            longitude: 14.850632,
        },
        {
            obec: 'Dolní Hrachovice',
            kod: 560529,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.486182,
            longitude: 14.797204,
        },
        {
            obec: 'Dolní Novosedly',
            kod: 562301,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.329225,
            longitude: 14.196093,
        },
        {
            obec: 'Dolní Pěna',
            kod: 562467,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.110604,
            longitude: 15.022153,
        },
        {
            obec: 'Dolní Třebonín',
            kod: 545473,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38201,
            latitude: 48.855526,
            longitude: 14.409805,
        },
        {
            obec: 'Dolní Žďár',
            kod: 562602,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.101227,
            longitude: 14.988276,
        },
        {
            obec: 'Domanín',
            kod: 562840,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 48.971783,
            longitude: 14.739869,
        },
        {
            obec: 'Doňov',
            kod: 507610,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.21318,
            longitude: 14.776207,
        },
        {
            obec: 'Doubravice',
            kod: 535664,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37006,
            latitude: 48.935899,
            longitude: 14.510822,
        },
        {
            obec: 'Doubravice',
            kod: 550981,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38735,
            latitude: 49.352117,
            longitude: 13.862024,
        },
        {
            obec: 'Doudleby',
            kod: 544400,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.893631,
            longitude: 14.501587,
        },
        {
            obec: 'Drahonice',
            kod: 551015,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.200853,
            longitude: 14.074682,
        },
        {
            obec: 'Drahotěšice',
            kod: 535958,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.124437,
            longitude: 14.546683,
        },
        {
            obec: 'Drahov',
            kod: 563765,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.175466,
            longitude: 14.754277,
        },
        {
            obec: 'Drachkov',
            kod: 560219,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.242924,
            longitude: 13.84157,
        },
        {
            obec: 'Dráchov',
            kod: 552275,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.226539,
            longitude: 14.706724,
        },
        {
            obec: 'Dražice',
            kod: 552283,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.415254,
            longitude: 14.640682,
        },
        {
            obec: 'Dražíč',
            kod: 549371,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.305974,
            longitude: 14.383923,
        },
        {
            obec: 'Dražičky',
            kod: 552291,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39175,
            latitude: 49.399131,
            longitude: 14.59882,
        },
        {
            obec: 'Drážov',
            kod: 551023,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.167415,
            longitude: 13.740707,
        },
        {
            obec: 'Drhovice',
            kod: 563307,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.432915,
            longitude: 14.558481,
        },
        {
            obec: 'Drhovle',
            kod: 549380,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.333979,
            longitude: 14.04237,
        },
        {
            obec: 'Droužetice',
            kod: 560243,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.289184,
            longitude: 13.89812,
        },
        {
            obec: 'Drslavice',
            kod: 537187,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.025427,
            longitude: 13.920865,
        },
        {
            obec: 'Drunče',
            kod: 560987,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.296949,
            longitude: 14.936562,
        },
        {
            obec: 'Dřešín',
            kod: 551040,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.161244,
            longitude: 13.775775,
        },
        {
            obec: 'Dříteň',
            kod: 544426,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37351,
            latitude: 49.142904,
            longitude: 14.346061,
        },
        {
            obec: 'Dub',
            kod: 550183,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38425,
            latitude: 49.108339,
            longitude: 14.012153,
        },
        {
            obec: 'Dubičné',
            kod: 535788,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 48.981174,
            longitude: 14.538863,
        },
        {
            obec: 'Dubné',
            kod: 544442,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.976295,
            longitude: 14.360478,
        },
        {
            obec: 'Dunajovice',
            kod: 562815,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.035149,
            longitude: 14.695348,
        },
        {
            obec: 'Dvory',
            kod: 537144,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.039499,
            longitude: 13.946255,
        },
        {
            obec: 'Dvory nad Lužnicí',
            kod: 509141,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37808,
            latitude: 48.852822,
            longitude: 14.900629,
        },
        {
            obec: 'Dynín',
            kod: 544451,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37364,
            latitude: 49.137304,
            longitude: 14.631296,
        },
        {
            obec: 'Frahelž',
            kod: 562637,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.117232,
            longitude: 14.732029,
        },
        {
            obec: 'Frymburk',
            kod: 545481,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38279,
            latitude: 48.661044,
            longitude: 14.165732,
        },
        {
            obec: 'Habří',
            kod: 535575,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.948248,
            longitude: 14.334947,
        },
        {
            obec: 'Hadravova Rosička',
            kod: 562696,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.250953,
            longitude: 15.047507,
        },
        {
            obec: 'Hajany',
            kod: 551597,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.446654,
            longitude: 13.835973,
        },
        {
            obec: 'Hájek',
            kod: 561690,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.11119,
            longitude: 14.061257,
        },
        {
            obec: 'Halámky',
            kod: 562807,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37806,
            latitude: 48.85324,
            longitude: 14.915223,
        },
        {
            obec: 'Hamr',
            kod: 562386,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37806,
            latitude: 48.949506,
            longitude: 14.913529,
        },
        {
            obec: 'Hartmanice',
            kod: 536075,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37365,
            latitude: 49.208049,
            longitude: 14.565216,
        },
        {
            obec: 'Haškovcova Lhota',
            kod: 563625,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.332597,
            longitude: 14.466726,
        },
        {
            obec: 'Hatín',
            kod: 546291,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.108751,
            longitude: 14.911134,
        },
        {
            obec: 'Heřmaň',
            kod: 598593,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.91146,
            longitude: 14.503761,
        },
        {
            obec: 'Heřmaň',
            kod: 549398,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39811,
            latitude: 49.232575,
            longitude: 14.13555,
        },
        {
            obec: 'Heřmaneč',
            kod: 546305,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.129602,
            longitude: 15.28546,
        },
        {
            obec: 'Hlasivo',
            kod: 560481,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.498562,
            longitude: 14.750424,
        },
        {
            obec: 'Hlavatce',
            kod: 535851,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37348,
            latitude: 49.067405,
            longitude: 14.263279,
        },
        {
            obec: 'Hlavatce',
            kod: 552321,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39173,
            latitude: 49.295598,
            longitude: 14.621315,
        },
        {
            obec: 'Hlincová Hora',
            kod: 598607,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 48.983725,
            longitude: 14.56456,
        },
        {
            obec: 'Hluboká nad Vltavou',
            kod: 544485,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.052354,
            longitude: 14.434371,
        },
        {
            obec: 'Hlupín',
            kod: 536628,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.334796,
            longitude: 13.806877,
        },
        {
            obec: 'Hodětín',
            kod: 598992,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.252274,
            longitude: 14.548733,
        },
        {
            obec: 'Hodonice',
            kod: 562904,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.268626,
            longitude: 14.487466,
        },
        {
            obec: 'Holubov',
            kod: 545490,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38203,
            latitude: 48.890096,
            longitude: 14.321188,
        },
        {
            obec: 'Homole',
            kod: 544493,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.939,
            longitude: 14.429572,
        },
        {
            obec: 'Horní Dvořiště',
            kod: 545503,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38293,
            latitude: 48.603883,
            longitude: 14.405822,
        },
        {
            obec: 'Horní Kněžeklady',
            kod: 536105,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.193834,
            longitude: 14.481489,
        },
        {
            obec: 'Horní Meziříčko',
            kod: 598658,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.156684,
            longitude: 15.244609,
        },
        {
            obec: 'Horní Němčice',
            kod: 562785,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.1579,
            longitude: 15.27978,
        },
        {
            obec: 'Horní Pěna',
            kod: 546364,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37831,
            latitude: 49.104515,
            longitude: 15.042397,
        },
        {
            obec: 'Horní Planá',
            kod: 545511,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38226,
            latitude: 48.767465,
            longitude: 14.032672,
        },
        {
            obec: 'Horní Poříčí',
            kod: 536881,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.286063,
            longitude: 13.782924,
        },
        {
            obec: 'Horní Radouň',
            kod: 546381,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37843,
            latitude: 49.25718,
            longitude: 15.005828,
        },
        {
            obec: 'Horní Skrýchov',
            kod: 561061,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.172879,
            longitude: 15.028473,
        },
        {
            obec: 'Horní Slatina',
            kod: 562319,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.090577,
            longitude: 15.568834,
        },
        {
            obec: 'Horní Stropnice',
            kod: 544515,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37335,
            latitude: 48.761347,
            longitude: 14.735114,
        },
        {
            obec: 'Horní Vltavice',
            kod: 550205,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38491,
            latitude: 48.957149,
            longitude: 13.758797,
        },
        {
            obec: 'Hornosín',
            kod: 536369,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38742,
            latitude: 49.482857,
            longitude: 13.839873,
        },
        {
            obec: 'Horosedly',
            kod: 561525,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.512896,
            longitude: 14.054107,
        },
        {
            obec: 'Hořice na Šumavě',
            kod: 545520,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38222,
            latitude: 48.766018,
            longitude: 14.178522,
        },
        {
            obec: 'Hosín',
            kod: 544523,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.037403,
            longitude: 14.476024,
        },
        {
            obec: 'Hoslovice',
            kod: 551104,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.191286,
            longitude: 13.763173,
        },
        {
            obec: 'Hospříz',
            kod: 546402,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.128663,
            longitude: 15.085627,
        },
        {
            obec: 'Hosty',
            kod: 535524,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.260396,
            longitude: 14.393359,
        },
        {
            obec: 'Hoštice',
            kod: 551121,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.194319,
            longitude: 13.909569,
        },
        {
            obec: 'Hradce',
            kod: 535460,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.933382,
            longitude: 14.359977,
        },
        {
            obec: 'Hracholusky',
            kod: 550221,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.0589,
            longitude: 14.088637,
        },
        {
            obec: 'Hrachoviště',
            kod: 562831,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 48.929471,
            longitude: 14.77121,
        },
        {
            obec: 'Hranice',
            kod: 544540,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.83852,
            longitude: 14.868392,
        },
        {
            obec: 'Hrazany',
            kod: 549410,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.52294,
            longitude: 14.33498,
        },
        {
            obec: 'Hrdějovice',
            kod: 544558,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37361,
            latitude: 49.019035,
            longitude: 14.478672,
        },
        {
            obec: 'Hrejkovice',
            kod: 549428,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39859,
            latitude: 49.474473,
            longitude: 14.290912,
        },
        {
            obec: 'Hříšice',
            kod: 546445,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.103431,
            longitude: 15.495538,
        },
        {
            obec: 'Hůry',
            kod: 535753,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 49.006592,
            longitude: 14.541183,
        },
        {
            obec: 'Husinec',
            kod: 550230,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38421,
            latitude: 49.055065,
            longitude: 13.987074,
        },
        {
            obec: 'Hvozdec',
            kod: 535613,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37372,
            latitude: 48.99242,
            longitude: 14.62741,
        },
        {
            obec: 'Chelčice',
            kod: 551139,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.121887,
            longitude: 14.169215,
        },
        {
            obec: 'Chlum',
            kod: 529982,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.447303,
            longitude: 13.851194,
        },
        {
            obec: 'Chlum u Třeboně',
            kod: 546461,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37804,
            latitude: 48.96245,
            longitude: 14.928089,
        },
        {
            obec: 'Chlumany',
            kod: 550248,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.06667,
            longitude: 13.965462,
        },
        {
            obec: 'Chlumec',
            kod: 536229,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.870362,
            longitude: 14.395976,
        },
        {
            obec: 'Chobot',
            kod: 536903,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.462516,
            longitude: 13.93382,
        },
        {
            obec: 'Chotěmice',
            kod: 552453,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.275324,
            longitude: 14.877036,
        },
        {
            obec: 'Chotoviny',
            kod: 552461,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39137,
            latitude: 49.478132,
            longitude: 14.677049,
        },
        {
            obec: 'Chotýčany',
            kod: 535907,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37362,
            latitude: 49.067394,
            longitude: 14.520691,
        },
        {
            obec: 'Choustník',
            kod: 552470,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39118,
            latitude: 49.333045,
            longitude: 14.838492,
        },
        {
            obec: 'Chrášťany',
            kod: 544591,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37304,
            latitude: 49.296087,
            longitude: 14.387621,
        },
        {
            obec: 'Chrášťovice',
            kod: 551155,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.333292,
            longitude: 13.89714,
        },
        {
            obec: 'Chrbonín',
            kod: 563145,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39155,
            latitude: 49.358766,
            longitude: 14.864401,
        },
        {
            obec: 'Chroboly',
            kod: 550264,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38404,
            latitude: 48.956632,
            longitude: 14.067078,
        },
        {
            obec: 'Chvalovice',
            kod: 537420,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.01045,
            longitude: 14.231802,
        },
        {
            obec: 'Chvalšiny',
            kod: 545546,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38208,
            latitude: 48.854119,
            longitude: 14.211239,
        },
        {
            obec: 'Chýnov',
            kod: 552496,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39155,
            latitude: 49.406872,
            longitude: 14.811319,
        },
        {
            obec: 'Chyšky',
            kod: 549452,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39853,
            latitude: 49.52356,
            longitude: 14.427673,
        },
        {
            obec: 'Jankov',
            kod: 544612,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.966544,
            longitude: 14.297986,
        },
        {
            obec: 'Jarošov nad Nežárkou',
            kod: 546500,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37841,
            latitude: 49.189995,
            longitude: 15.06744,
        },
        {
            obec: 'Jedlany',
            kod: 562963,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39137,
            latitude: 49.494676,
            longitude: 14.71654,
        },
        {
            obec: 'Jetětice',
            kod: 549479,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39848,
            latitude: 49.386055,
            longitude: 14.29271,
        },
        {
            obec: 'Jickovice',
            kod: 562084,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.450736,
            longitude: 14.215979,
        },
        {
            obec: 'Jilem',
            kod: 562769,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.17677,
            longitude: 15.268924,
        },
        {
            obec: 'Jílovice',
            kod: 544639,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37332,
            latitude: 48.889587,
            longitude: 14.726899,
        },
        {
            obec: 'Jindřichův Hradec',
            kod: 545881,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.144582,
            longitude: 15.006239,
        },
        {
            obec: 'Jinín',
            kod: 551163,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.227879,
            longitude: 13.982656,
        },
        {
            obec: 'Jistebnice',
            kod: 552534,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39133,
            latitude: 49.485634,
            longitude: 14.527704,
        },
        {
            obec: 'Jivno',
            kod: 535761,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 48.99562,
            longitude: 14.569321,
        },
        {
            obec: 'Kačlehy',
            kod: 562491,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.108963,
            longitude: 15.077166,
        },
        {
            obec: 'Kadov',
            kod: 551180,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38733,
            latitude: 49.402831,
            longitude: 13.774881,
        },
        {
            obec: 'Kájov',
            kod: 545554,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38221,
            latitude: 48.810921,
            longitude: 14.258692,
        },
        {
            obec: 'Kalenice',
            kod: 536946,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.271655,
            longitude: 13.7168,
        },
        {
            obec: 'Kamenná',
            kod: 535982,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.779882,
            longitude: 14.671182,
        },
        {
            obec: 'Kamenný Malíkov',
            kod: 598631,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.211998,
            longitude: 15.122369,
        },
        {
            obec: 'Kamenný Újezd',
            kod: 544663,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37381,
            latitude: 48.897635,
            longitude: 14.446481,
        },
        {
            obec: 'Kaplice',
            kod: 545562,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38241,
            latitude: 48.738589,
            longitude: 14.496373,
        },
        {
            obec: 'Kardašova Řečice',
            kod: 546542,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.184882,
            longitude: 14.853256,
        },
        {
            obec: 'Katov',
            kod: 530042,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.281817,
            longitude: 14.828622,
        },
        {
            obec: 'Katovice',
            kod: 551201,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38711,
            latitude: 49.273591,
            longitude: 13.830458,
        },
        {
            obec: 'Kestřany',
            kod: 549487,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39821,
            latitude: 49.269153,
            longitude: 14.072641,
        },
        {
            obec: 'Kladruby',
            kod: 560405,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.268814,
            longitude: 13.763561,
        },
        {
            obec: 'Klec',
            kod: 562688,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.099391,
            longitude: 14.748496,
        },
        {
            obec: 'Klenovice',
            kod: 563986,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.278877,
            longitude: 14.715759,
        },
        {
            obec: 'Kluky',
            kod: 549495,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39819,
            latitude: 49.316885,
            longitude: 14.245388,
        },
        {
            obec: 'Kocelovice',
            kod: 536601,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38742,
            latitude: 49.465172,
            longitude: 13.827677,
        },
        {
            obec: 'Komárov',
            kod: 599280,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.250167,
            longitude: 14.594124,
        },
        {
            obec: 'Komařice',
            kod: 535877,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37314,
            latitude: 48.878549,
            longitude: 14.545763,
        },
        {
            obec: 'Kostelec nad Vltavou',
            kod: 549509,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39858,
            latitude: 49.49999,
            longitude: 14.211859,
        },
        {
            obec: 'Kostelní Radouň',
            kod: 509108,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.225517,
            longitude: 15.008829,
        },
        {
            obec: 'Kostelní Vydří',
            kod: 508357,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.106163,
            longitude: 15.421758,
        },
        {
            obec: 'Košice',
            kod: 552585,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39117,
            latitude: 49.325429,
            longitude: 14.751505,
        },
        {
            obec: 'Košín',
            kod: 562955,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39137,
            latitude: 49.456508,
            longitude: 14.658548,
        },
        {
            obec: 'Kovářov',
            kod: 549517,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39855,
            latitude: 49.517705,
            longitude: 14.278199,
        },
        {
            obec: 'Kožlí',
            kod: 561550,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.515875,
            longitude: 14.146759,
        },
        {
            obec: 'Krajníčko',
            kod: 536342,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.147647,
            longitude: 14.028846,
        },
        {
            obec: 'Králova Lhota',
            kod: 549525,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.495809,
            longitude: 14.110652,
        },
        {
            obec: 'Kraselov',
            kod: 551261,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.227936,
            longitude: 13.804051,
        },
        {
            obec: 'Krašlovice',
            kod: 536806,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.166695,
            longitude: 14.132576,
        },
        {
            obec: 'Krátošice',
            kod: 560634,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.325991,
            longitude: 14.786807,
        },
        {
            obec: 'Kratušín',
            kod: 537136,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.020356,
            longitude: 13.928608,
        },
        {
            obec: 'Krejnice',
            kod: 536954,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.233828,
            longitude: 13.718584,
        },
        {
            obec: 'Krtov',
            kod: 563030,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.348553,
            longitude: 14.832583,
        },
        {
            obec: 'Krty-Hradec',
            kod: 536555,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.291408,
            longitude: 13.848107,
        },
        {
            obec: 'Křemže',
            kod: 545571,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38203,
            latitude: 48.90507,
            longitude: 14.305777,
        },
        {
            obec: 'Křenovice',
            kod: 562181,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.367396,
            longitude: 14.342995,
        },
        {
            obec: 'Křišťanov',
            kod: 561673,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 48.909239,
            longitude: 14.020131,
        },
        {
            obec: 'Křižanov',
            kod: 509752,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.39884,
            longitude: 14.378237,
        },
        {
            obec: 'Ktiš',
            kod: 550329,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38403,
            latitude: 48.917082,
            longitude: 14.132752,
        },
        {
            obec: 'Kubova Huť',
            kod: 563978,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38501,
            latitude: 48.983333,
            longitude: 13.771924,
        },
        {
            obec: 'Kučeř',
            kod: 549541,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39834,
            latitude: 49.434342,
            longitude: 14.245724,
        },
        {
            obec: 'Kunžak',
            kod: 546615,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37862,
            latitude: 49.12129,
            longitude: 15.190385,
        },
        {
            obec: 'Kuřimany',
            kod: 560391,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.204002,
            longitude: 13.968229,
        },
        {
            obec: 'Kváskovice',
            kod: 536750,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.188506,
            longitude: 14.003793,
        },
        {
            obec: 'Květov',
            kod: 598801,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.425542,
            longitude: 14.275882,
        },
        {
            obec: 'Kvilda',
            kod: 550337,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38493,
            latitude: 49.019358,
            longitude: 13.57968,
        },
        {
            obec: 'Kvítkovice',
            kod: 529729,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.957506,
            longitude: 14.33126,
        },
        {
            obec: 'Lásenice',
            kod: 546623,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37801,
            latitude: 49.074391,
            longitude: 14.970283,
        },
        {
            obec: 'Lažánky',
            kod: 598950,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.391505,
            longitude: 13.836837,
        },
        {
            obec: 'Lažany',
            kod: 560197,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.36295,
            longitude: 13.883237,
        },
        {
            obec: 'Lažiště',
            kod: 550345,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38432,
            latitude: 49.03766,
            longitude: 13.92781,
        },
        {
            obec: 'Lčovice',
            kod: 561649,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.114639,
            longitude: 13.850712,
        },
        {
            obec: 'Ledenice',
            kod: 544736,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37311,
            latitude: 48.932311,
            longitude: 14.618133,
        },
        {
            obec: 'Lenora',
            kod: 550353,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38442,
            latitude: 48.922415,
            longitude: 13.79337,
        },
        {
            obec: 'Lety',
            kod: 549568,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.517034,
            longitude: 14.087866,
        },
        {
            obec: 'Lhenice',
            kod: 550361,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38402,
            latitude: 48.994897,
            longitude: 14.149903,
        },
        {
            obec: 'Libějice',
            kod: 563544,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.376947,
            longitude: 14.623952,
        },
        {
            obec: 'Libějovice',
            kod: 551333,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38772,
            latitude: 49.114439,
            longitude: 14.193459,
        },
        {
            obec: 'Libětice',
            kod: 536831,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.219149,
            longitude: 13.862757,
        },
        {
            obec: 'Libín',
            kod: 544744,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37373,
            latitude: 48.969727,
            longitude: 14.683706,
        },
        {
            obec: 'Libníč',
            kod: 535800,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 49.019769,
            longitude: 14.543731,
        },
        {
            obec: 'Lipí',
            kod: 544761,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.949581,
            longitude: 14.352239,
        },
        {
            obec: 'Lipno nad Vltavou',
            kod: 545597,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38278,
            latitude: 48.639427,
            longitude: 14.22942,
        },
        {
            obec: 'Lipovice',
            kod: 537071,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.100635,
            longitude: 13.993981,
        },
        {
            obec: 'Lišov',
            kod: 544779,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37372,
            latitude: 49.016073,
            longitude: 14.608479,
        },
        {
            obec: 'Litochovice',
            kod: 551341,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.159413,
            longitude: 13.94153,
        },
        {
            obec: 'Litvínovice',
            kod: 544795,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.962172,
            longitude: 14.451559,
        },
        {
            obec: 'Lnáře',
            kod: 551350,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38742,
            latitude: 49.458006,
            longitude: 13.784168,
        },
        {
            obec: 'Ločenice',
            kod: 544809,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37322,
            latitude: 48.824304,
            longitude: 14.527609,
        },
        {
            obec: 'Lodhéřov',
            kod: 546666,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37826,
            latitude: 49.215189,
            longitude: 14.960045,
        },
        {
            obec: 'Lom',
            kod: 536822,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.410552,
            longitude: 13.989092,
        },
        {
            obec: 'Lom',
            kod: 563587,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.362958,
            longitude: 14.62841,
        },
        {
            obec: 'Lomnice nad Lužnicí',
            kod: 546674,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37816,
            latitude: 49.084786,
            longitude: 14.717374,
        },
        {
            obec: 'Loučovice',
            kod: 545601,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38276,
            latitude: 48.620292,
            longitude: 14.257565,
        },
        {
            obec: 'Lužice',
            kod: 537322,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.031398,
            longitude: 14.205036,
        },
        {
            obec: 'Lužnice',
            kod: 508501,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.063083,
            longitude: 14.756284,
        },
        {
            obec: 'Mačkov',
            kod: 536466,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.40436,
            longitude: 13.886196,
        },
        {
            obec: 'Mahouš',
            kod: 537381,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.047948,
            longitude: 14.256061,
        },
        {
            obec: 'Majdalena',
            kod: 546712,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37803,
            latitude: 48.960809,
            longitude: 14.86141,
        },
        {
            obec: 'Malenice',
            kod: 551384,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38706,
            latitude: 49.126512,
            longitude: 13.882929,
        },
        {
            obec: 'Malonty',
            kod: 545619,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38291,
            latitude: 48.686212,
            longitude: 14.576875,
        },
        {
            obec: 'Malovice',
            kod: 550418,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.091285,
            longitude: 14.225863,
        },
        {
            obec: 'Malšice',
            kod: 552666,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39175,
            latitude: 49.364014,
            longitude: 14.578788,
        },
        {
            obec: 'Malšín',
            kod: 536296,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38273,
            latitude: 48.678126,
            longitude: 14.287135,
        },
        {
            obec: 'Mazelov',
            kod: 535940,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37363,
            latitude: 49.103053,
            longitude: 14.617886,
        },
        {
            obec: 'Mažice',
            kod: 562866,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.213602,
            longitude: 14.612918,
        },
        {
            obec: 'Mečichov',
            kod: 551392,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38736,
            latitude: 49.347873,
            longitude: 13.812134,
        },
        {
            obec: 'Měkynec',
            kod: 536415,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.15838,
            longitude: 14.029485,
        },
        {
            obec: 'Meziříčí',
            kod: 563234,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.436646,
            longitude: 14.575126,
        },
        {
            obec: 'Mezná',
            kod: 599255,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.258242,
            longitude: 14.800827,
        },
        {
            obec: 'Mičovice',
            kod: 550426,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 48.983641,
            longitude: 14.125165,
        },
        {
            obec: 'Milejovice',
            kod: 536539,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.188193,
            longitude: 13.930185,
        },
        {
            obec: 'Milevsko',
            kod: 549576,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.450998,
            longitude: 14.360109,
        },
        {
            obec: 'Miloňovice',
            kod: 551414,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.22067,
            longitude: 13.958469,
        },
        {
            obec: 'Minice',
            kod: 562149,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.489944,
            longitude: 14.01224,
        },
        {
            obec: 'Mirkovice',
            kod: 545627,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.811076,
            longitude: 14.391352,
        },
        {
            obec: 'Mirotice',
            kod: 549584,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39801,
            latitude: 49.429185,
            longitude: 14.037078,
        },
        {
            obec: 'Mirovice',
            kod: 549592,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39806,
            latitude: 49.515657,
            longitude: 14.035924,
        },
        {
            obec: 'Mišovice',
            kod: 549606,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.495358,
            longitude: 14.005039,
        },
        {
            obec: 'Mladá Vožice',
            kod: 552704,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.53323,
            longitude: 14.808669,
        },
        {
            obec: 'Mladošovice',
            kod: 544817,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 48.940569,
            longitude: 14.69925,
        },
        {
            obec: 'Mlýny',
            kod: 552712,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.336335,
            longitude: 14.877147,
        },
        {
            obec: 'Mnichov',
            kod: 536563,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.302187,
            longitude: 13.830176,
        },
        {
            obec: 'Modrá Hůrka',
            kod: 598615,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.180142,
            longitude: 14.525766,
        },
        {
            obec: 'Mojné',
            kod: 598623,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.834818,
            longitude: 14.407373,
        },
        {
            obec: 'Mokrý Lom',
            kod: 535842,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.844884,
            longitude: 14.514153,
        },
        {
            obec: 'Mutěnice',
            kod: 560278,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.23981,
            longitude: 13.89664,
        },
        {
            obec: 'Mydlovary',
            kod: 535281,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37349,
            latitude: 49.091233,
            longitude: 14.35445,
        },
        {
            obec: 'Myslín',
            kod: 549614,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.532189,
            longitude: 14.02744,
        },
        {
            obec: 'Myslkovice',
            kod: 552721,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39116,
            latitude: 49.299224,
            longitude: 14.7457,
        },
        {
            obec: 'Myštice',
            kod: 551473,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.453257,
            longitude: 13.97019,
        },
        {
            obec: 'Nadějkov',
            kod: 549631,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39852,
            latitude: 49.502815,
            longitude: 14.479339,
        },
        {
            obec: 'Nákří',
            kod: 535371,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37348,
            latitude: 49.118968,
            longitude: 14.329443,
        },
        {
            obec: 'Nasavrky',
            kod: 559016,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.452054,
            longitude: 14.640664,
        },
        {
            obec: 'Nebahovy',
            kod: 550434,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38401,
            latitude: 49.005858,
            longitude: 14.052436,
        },
        {
            obec: 'Nebřehovice',
            kod: 536644,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.238537,
            longitude: 13.962118,
        },
        {
            obec: 'Nedabyle',
            kod: 544825,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37006,
            latitude: 48.928509,
            longitude: 14.515037,
        },
        {
            obec: 'Němčice',
            kod: 550396,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.031903,
            longitude: 14.271361,
        },
        {
            obec: 'Němčice',
            kod: 536474,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.192799,
            longitude: 13.800188,
        },
        {
            obec: 'Němětice',
            kod: 536725,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.194724,
            longitude: 13.879078,
        },
        {
            obec: 'Nemyšl',
            kod: 552747,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.513482,
            longitude: 14.693011,
        },
        {
            obec: 'Neplachov',
            kod: 535435,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37365,
            latitude: 49.130401,
            longitude: 14.601091,
        },
        {
            obec: 'Nerestce',
            kod: 562157,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.50082,
            longitude: 14.063758,
        },
        {
            obec: 'Netolice',
            kod: 550442,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.049406,
            longitude: 14.197098,
        },
        {
            obec: 'Netřebice',
            kod: 545643,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.790887,
            longitude: 14.455665,
        },
        {
            obec: 'Nevězice',
            kod: 549657,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.477833,
            longitude: 14.153463,
        },
        {
            obec: 'Nicov',
            kod: 529893,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38473,
            latitude: 49.125538,
            longitude: 13.620561,
        },
        {
            obec: 'Nihošovice',
            kod: 551520,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.188367,
            longitude: 13.861812,
        },
        {
            obec: 'Nišovice',
            kod: 536962,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.15533,
            longitude: 13.897452,
        },
        {
            obec: 'Nová Bystřice',
            kod: 546798,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37833,
            latitude: 49.019361,
            longitude: 15.103258,
        },
        {
            obec: 'Nová Olešná',
            kod: 562734,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.175714,
            longitude: 15.161053,
        },
        {
            obec: 'Nová Pec',
            kod: 550451,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38462,
            latitude: 48.778263,
            longitude: 13.930124,
        },
        {
            obec: 'Nová Včelnice',
            kod: 546801,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.239453,
            longitude: 15.072705,
        },
        {
            obec: 'Nová Ves',
            kod: 535648,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37315,
            latitude: 48.920709,
            longitude: 14.52874,
        },
        {
            obec: 'Nová Ves',
            kod: 513661,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38203,
            latitude: 48.948418,
            longitude: 14.24726,
        },
        {
            obec: 'Nová Ves',
            kod: 560201,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.187495,
            longitude: 13.733768,
        },
        {
            obec: 'Nová Ves nad Lužnicí',
            kod: 562360,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37809,
            latitude: 48.810739,
            longitude: 14.925675,
        },
        {
            obec: 'Nová Ves u Chýnova',
            kod: 552763,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39155,
            latitude: 49.392801,
            longitude: 14.783071,
        },
        {
            obec: 'Nová Ves u Mladé Vožice',
            kod: 563455,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.543134,
            longitude: 14.748066,
        },
        {
            obec: 'Nové Hrady',
            kod: 544868,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37333,
            latitude: 48.789733,
            longitude: 14.778483,
        },
        {
            obec: 'Nové Hutě',
            kod: 561568,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38501,
            latitude: 49.038803,
            longitude: 13.645759,
        },
        {
            obec: 'Novosedly',
            kod: 551554,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.26277,
            longitude: 13.797628,
        },
        {
            obec: 'Novosedly nad Nežárkou',
            kod: 546844,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37817,
            latitude: 49.081103,
            longitude: 14.82226,
        },
        {
            obec: 'Okrouhlá',
            kod: 529877,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.413732,
            longitude: 14.364412,
        },
        {
            obec: 'Okrouhlá Radouň',
            kod: 562475,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.239826,
            longitude: 15.017172,
        },
        {
            obec: 'Oldřichov',
            kod: 552798,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.558828,
            longitude: 14.754364,
        },
        {
            obec: 'Olešná',
            kod: 562190,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.346255,
            longitude: 14.310591,
        },
        {
            obec: 'Olešnice',
            kod: 544884,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37331,
            latitude: 48.84133,
            longitude: 14.704931,
        },
        {
            obec: 'Olešník',
            kod: 544892,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37350,
            latitude: 49.107235,
            longitude: 14.363919,
        },
        {
            obec: 'Olšovice',
            kod: 537365,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38411,
            latitude: 49.056923,
            longitude: 14.235971,
        },
        {
            obec: 'Omlenice',
            kod: 545660,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38241,
            latitude: 48.725918,
            longitude: 14.443732,
        },
        {
            obec: 'Opařany',
            kod: 552801,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39161,
            latitude: 49.396884,
            longitude: 14.481477,
        },
        {
            obec: 'Orlík nad Vltavou',
            kod: 549681,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39807,
            latitude: 49.509979,
            longitude: 14.165613,
        },
        {
            obec: 'Osek',
            kod: 598828,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.443367,
            longitude: 14.299945,
        },
        {
            obec: 'Osek',
            kod: 551562,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.318408,
            longitude: 13.963291,
        },
        {
            obec: 'Oslov',
            kod: 549703,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39835,
            latitude: 49.399365,
            longitude: 14.212059,
        },
        {
            obec: 'Ostrolovský Újezd',
            kod: 535231,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.880718,
            longitude: 14.600707,
        },
        {
            obec: 'Ostrovec',
            kod: 549711,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39833,
            latitude: 49.421243,
            longitude: 14.115581,
        },
        {
            obec: 'Paračov',
            kod: 551571,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.201136,
            longitude: 13.994739,
        },
        {
            obec: 'Paseky',
            kod: 562254,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39811,
            latitude: 49.25174,
            longitude: 14.254921,
        },
        {
            obec: 'Peč',
            kod: 507717,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.057931,
            longitude: 15.392475,
        },
        {
            obec: 'Pěčnov',
            kod: 537543,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.053207,
            longitude: 13.942131,
        },
        {
            obec: 'Petříkov',
            kod: 535699,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.847072,
            longitude: 14.754708,
        },
        {
            obec: 'Písečné',
            kod: 546917,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37872,
            latitude: 48.965043,
            longitude: 15.462488,
        },
        {
            obec: 'Písek',
            kod: 549240,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.303554,
            longitude: 14.158129,
        },
        {
            obec: 'Pístina',
            kod: 561070,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.049781,
            longitude: 14.901411,
        },
        {
            obec: 'Pištín',
            kod: 535991,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37346,
            latitude: 49.045975,
            longitude: 14.335259,
        },
        {
            obec: 'Pivkovice',
            kod: 598887,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.177594,
            longitude: 14.069728,
        },
        {
            obec: 'Planá',
            kod: 535176,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.943775,
            longitude: 14.452868,
        },
        {
            obec: 'Planá nad Lužnicí',
            kod: 552828,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39111,
            latitude: 49.354541,
            longitude: 14.701577,
        },
        {
            obec: 'Plav',
            kod: 535346,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.901535,
            longitude: 14.487974,
        },
        {
            obec: 'Plavsko',
            kod: 509078,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.084695,
            longitude: 14.904124,
        },
        {
            obec: 'Pleše',
            kod: 507628,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.195629,
            longitude: 14.821465,
        },
        {
            obec: 'Pluhův Žďár',
            kod: 546968,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.223995,
            longitude: 14.892803,
        },
        {
            obec: 'Podolí I',
            kod: 549754,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.361265,
            longitude: 14.312023,
        },
        {
            obec: 'Pohnánec',
            kod: 560553,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.473682,
            longitude: 14.802766,
        },
        {
            obec: 'Pohnání',
            kod: 552852,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.477597,
            longitude: 14.811241,
        },
        {
            obec: 'Pohorovice',
            kod: 536911,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.188696,
            longitude: 14.135183,
        },
        {
            obec: 'Pohorská Ves',
            kod: 545694,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38283,
            latitude: 48.670025,
            longitude: 14.647748,
        },
        {
            obec: 'Pojbuky',
            kod: 552861,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.495907,
            longitude: 14.896833,
        },
        {
            obec: 'Polná na Šumavě',
            kod: 500194,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38226,
            latitude: 48.805608,
            longitude: 14.131502,
        },
        {
            obec: 'Polště',
            kod: 562459,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.106486,
            longitude: 14.927954,
        },
        {
            obec: 'Ponědraž',
            kod: 562670,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.116075,
            longitude: 14.702066,
        },
        {
            obec: 'Ponědrážka',
            kod: 562653,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.136634,
            longitude: 14.703419,
        },
        {
            obec: 'Popelín',
            kod: 546992,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37855,
            latitude: 49.213683,
            longitude: 15.184019,
        },
        {
            obec: 'Pracejovice',
            kod: 551619,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.257287,
            longitude: 13.849231,
        },
        {
            obec: 'Prachatice',
            kod: 550094,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.01101,
            longitude: 14.0001,
        },
        {
            obec: 'Probulov',
            kod: 561509,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.492895,
            longitude: 14.145234,
        },
        {
            obec: 'Protivín',
            kod: 549771,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39811,
            latitude: 49.199591,
            longitude: 14.217277,
        },
        {
            obec: 'Přeborov',
            kod: 561517,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.479579,
            longitude: 14.357241,
        },
        {
            obec: 'Předmíř',
            kod: 551627,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38742,
            latitude: 49.489369,
            longitude: 13.770472,
        },
        {
            obec: 'Přední Výtoň',
            kod: 545716,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38273,
            latitude: 48.631244,
            longitude: 14.16459,
        },
        {
            obec: 'Přední Zborovice',
            kod: 536792,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.218622,
            longitude: 13.892282,
        },
        {
            obec: 'Předotice',
            kod: 509621,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.365038,
            longitude: 14.053525,
        },
        {
            obec: 'Předslavice',
            kod: 551635,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.132138,
            longitude: 13.935156,
        },
        {
            obec: 'Přehořov',
            kod: 552895,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.247699,
            longitude: 14.756334,
        },
        {
            obec: 'Přechovice',
            kod: 536547,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.179643,
            longitude: 13.894871,
        },
        {
            obec: 'Přeštěnice',
            kod: 549797,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.479763,
            longitude: 14.42045,
        },
        {
            obec: 'Přešťovice',
            kod: 551643,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.277278,
            longitude: 13.974442,
        },
        {
            obec: 'Příbraz',
            kod: 561088,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.051469,
            longitude: 14.937708,
        },
        {
            obec: 'Přídolí',
            kod: 545724,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38101,
            latitude: 48.781912,
            longitude: 14.35229,
        },
        {
            obec: 'Přísečná',
            kod: 545732,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38101,
            latitude: 48.833173,
            longitude: 14.345507,
        },
        {
            obec: 'Psárov',
            kod: 563374,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.319003,
            longitude: 14.905589,
        },
        {
            obec: 'Putim',
            kod: 549801,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.264627,
            longitude: 14.11913,
        },
        {
            obec: 'Radějovice',
            kod: 536423,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38773,
            latitude: 49.186371,
            longitude: 14.026543,
        },
        {
            obec: 'Radenín',
            kod: 552917,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39120,
            latitude: 49.369486,
            longitude: 14.840783,
        },
        {
            obec: 'Radětice',
            kod: 552925,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.31946,
            longitude: 14.441364,
        },
        {
            obec: 'Radhostice',
            kod: 550485,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.079455,
            longitude: 13.880611,
        },
        {
            obec: 'Radimovice u Tábora',
            kod: 563196,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.457417,
            longitude: 14.639125,
        },
        {
            obec: 'Radimovice u Želče',
            kod: 552933,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.377371,
            longitude: 14.647655,
        },
        {
            obec: 'Radkov',
            kod: 552941,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.465893,
            longitude: 14.611257,
        },
        {
            obec: 'Radomyšl',
            kod: 551660,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38731,
            latitude: 49.31645,
            longitude: 13.930344,
        },
        {
            obec: 'Radošovice',
            kod: 544965,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.019699,
            longitude: 14.273026,
        },
        {
            obec: 'Radošovice',
            kod: 551678,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.233625,
            longitude: 13.898673,
        },
        {
            obec: 'Rakovice',
            kod: 562068,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.471321,
            longitude: 14.05466,
        },
        {
            obec: 'Rapšach',
            kod: 547069,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37807,
            latitude: 48.879314,
            longitude: 14.933012,
        },
        {
            obec: 'Rataje',
            kod: 552976,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.350249,
            longitude: 14.44584,
        },
        {
            obec: 'Ratiboř',
            kod: 507784,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.143619,
            longitude: 14.915123,
        },
        {
            obec: 'Ratibořské Hory',
            kod: 552992,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39142,
            latitude: 49.462778,
            longitude: 14.769911,
        },
        {
            obec: 'Ražice',
            kod: 549827,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39822,
            latitude: 49.240999,
            longitude: 14.101842,
        },
        {
            obec: 'Rodná',
            kod: 560626,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.490264,
            longitude: 14.8426,
        },
        {
            obec: 'Rodvínov',
            kod: 547085,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.171096,
            longitude: 15.05709,
        },
        {
            obec: 'Roseč',
            kod: 508004,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37846,
            latitude: 49.127862,
            longitude: 14.910845,
        },
        {
            obec: 'Rosička',
            kod: 560995,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.279706,
            longitude: 14.954045,
        },
        {
            obec: 'Roudná',
            kod: 553018,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.304487,
            longitude: 14.718671,
        },
        {
            obec: 'Roudné',
            kod: 544973,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.934985,
            longitude: 14.487539,
        },
        {
            obec: 'Rovná',
            kod: 536865,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.286443,
            longitude: 13.954057,
        },
        {
            obec: 'Rožmberk nad Vltavou',
            kod: 545767,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38218,
            latitude: 48.654353,
            longitude: 14.365259,
        },
        {
            obec: 'Rožmitál na Šumavě',
            kod: 545775,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38292,
            latitude: 48.701499,
            longitude: 14.388377,
        },
        {
            obec: 'Rudolfov',
            kod: 544981,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37371,
            latitude: 48.993496,
            longitude: 14.541889,
        },
        {
            obec: 'Řemíčov',
            kod: 560511,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.515076,
            longitude: 14.775093,
        },
        {
            obec: 'Řepeč',
            kod: 553034,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39161,
            latitude: 49.403544,
            longitude: 14.51966,
        },
        {
            obec: 'Řepice',
            kod: 536849,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.280612,
            longitude: 13.933539,
        },
        {
            obec: 'Římov',
            kod: 545007,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37324,
            latitude: 48.855719,
            longitude: 14.48694,
        },
        {
            obec: 'Řípec',
            kod: 599115,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.214291,
            longitude: 14.736808,
        },
        {
            obec: 'Sedlec',
            kod: 545015,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37347,
            latitude: 49.072931,
            longitude: 14.286606,
        },
        {
            obec: 'Sedlečko u Soběslavě',
            kod: 599263,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.282784,
            longitude: 14.742959,
        },
        {
            obec: 'Sedlice',
            kod: 551716,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38732,
            latitude: 49.377258,
            longitude: 13.939059,
        },
        {
            obec: 'Sepekov',
            kod: 549843,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39851,
            latitude: 49.428755,
            longitude: 14.418256,
        },
        {
            obec: 'Sezimovo Ústí',
            kod: 553069,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39101,
            latitude: 49.385291,
            longitude: 14.684908,
        },
        {
            obec: 'Skalice',
            kod: 553077,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.303479,
            longitude: 14.695306,
        },
        {
            obec: 'Skály',
            kod: 549851,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39811,
            latitude: 49.219961,
            longitude: 14.160276,
        },
        {
            obec: 'Skály',
            kod: 598909,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.183483,
            longitude: 13.99296,
        },
        {
            obec: 'Skočice',
            kod: 551741,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38775,
            latitude: 49.186215,
            longitude: 14.104365,
        },
        {
            obec: 'Skopytce',
            kod: 553085,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.331555,
            longitude: 14.798253,
        },
        {
            obec: 'Skrýchov u Malšic',
            kod: 560669,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39175,
            latitude: 49.33027,
            longitude: 14.596666,
        },
        {
            obec: 'Slabčice',
            kod: 549860,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39847,
            latitude: 49.326635,
            longitude: 14.337975,
        },
        {
            obec: 'Slaník',
            kod: 536776,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.268386,
            longitude: 13.951671,
        },
        {
            obec: 'Slapsko',
            kod: 599026,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.575208,
            longitude: 14.762716,
        },
        {
            obec: 'Slapy',
            kod: 599042,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39176,
            latitude: 49.38703,
            longitude: 14.616671,
        },
        {
            obec: 'Slavče',
            kod: 545023,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37321,
            latitude: 48.797949,
            longitude: 14.615065,
        },
        {
            obec: 'Slavonice',
            kod: 547166,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37881,
            latitude: 48.997636,
            longitude: 15.351639,
        },
        {
            obec: 'Smetanova Lhota',
            kod: 549878,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39804,
            latitude: 49.447708,
            longitude: 14.087266,
        },
        {
            obec: 'Smilovy Hory',
            kod: 553123,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39152,
            latitude: 49.536512,
            longitude: 14.875956,
        },
        {
            obec: 'Smržov',
            kod: 561045,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.076158,
            longitude: 14.682477,
        },
        {
            obec: 'Soběnov',
            kod: 545805,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38241,
            latitude: 48.762808,
            longitude: 14.545827,
        },
        {
            obec: 'Soběslav',
            kod: 553131,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.260039,
            longitude: 14.718717,
        },
        {
            obec: 'Sousedovice',
            kod: 551759,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.232155,
            longitude: 13.868628,
        },
        {
            obec: 'Srnín',
            kod: 536245,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38101,
            latitude: 48.845115,
            longitude: 14.343803,
        },
        {
            obec: 'Srubec',
            kod: 545066,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37006,
            latitude: 48.948161,
            longitude: 14.541407,
        },
        {
            obec: 'Stádlec',
            kod: 553140,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39162,
            latitude: 49.3797,
            longitude: 14.495169,
        },
        {
            obec: 'Stachy',
            kod: 550515,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38473,
            latitude: 49.1019,
            longitude: 13.666687,
        },
        {
            obec: 'Staňkov',
            kod: 562378,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37806,
            latitude: 48.979627,
            longitude: 14.952798,
        },
        {
            obec: 'Staré Hobzí',
            kod: 547204,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37871,
            latitude: 49.010499,
            longitude: 15.453308,
        },
        {
            obec: 'Staré Hodějovice',
            kod: 545074,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37008,
            latitude: 48.945486,
            longitude: 14.521073,
        },
        {
            obec: 'Staré Město pod Landštejnem',
            kod: 547212,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37882,
            latitude: 49.00345,
            longitude: 15.254229,
        },
        {
            obec: 'Stehlovice',
            kod: 529885,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.393498,
            longitude: 14.322986,
        },
        {
            obec: 'Stožec',
            kod: 550523,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38444,
            latitude: 48.859471,
            longitude: 13.821516,
        },
        {
            obec: 'Stožice',
            kod: 530034,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.132595,
            longitude: 14.150308,
        },
        {
            obec: 'Strakonice',
            kod: 550787,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.260504,
            longitude: 13.910408,
        },
        {
            obec: 'Strašice',
            kod: 551775,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.202554,
            longitude: 13.729712,
        },
        {
            obec: 'Stráž nad Nežárkou',
            kod: 547221,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.069814,
            longitude: 14.905614,
        },
        {
            obec: 'Strážkovice',
            kod: 545082,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.899683,
            longitude: 14.569486,
        },
        {
            obec: 'Strážný',
            kod: 550531,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38443,
            latitude: 48.908412,
            longitude: 13.720476,
        },
        {
            obec: 'Strmilov',
            kod: 547239,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.158567,
            longitude: 15.199439,
        },
        {
            obec: 'Strunkovice nad Blanicí',
            kod: 550540,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38426,
            latitude: 49.084131,
            longitude: 14.055317,
        },
        {
            obec: 'Strunkovice nad Volyňkou',
            kod: 536784,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.2086,
            longitude: 13.889431,
        },
        {
            obec: 'Strýčice1)',
            kod: 536032,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.011922,
            longitude: 14.266127,
        },
        {
            obec: 'Střelské Hoštice',
            kod: 551791,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38715,
            latitude: 49.297703,
            longitude: 13.75606,
        },
        {
            obec: 'Stříbřec',
            kod: 547247,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37818,
            latitude: 49.029989,
            longitude: 14.878351,
        },
        {
            obec: 'Střítež',
            kod: 551538,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.775738,
            longitude: 14.434542,
        },
        {
            obec: 'Střížov',
            kod: 545091,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.890503,
            longitude: 14.527777,
        },
        {
            obec: 'Střížovice',
            kod: 508152,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.140007,
            longitude: 15.156956,
        },
        {
            obec: 'Studená',
            kod: 547263,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37856,
            latitude: 49.185258,
            longitude: 15.286981,
        },
        {
            obec: 'Sudoměřice u Bechyně',
            kod: 553166,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39172,
            latitude: 49.289825,
            longitude: 14.538534,
        },
        {
            obec: 'Sudoměřice u Tábora',
            kod: 553174,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39136,
            latitude: 49.510295,
            longitude: 14.658595,
        },
        {
            obec: 'Suchdol nad Lužnicí',
            kod: 547280,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37806,
            latitude: 48.893435,
            longitude: 14.877056,
        },
        {
            obec: 'Svatá Maří',
            kod: 550558,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38501,
            latitude: 49.064631,
            longitude: 13.831531,
        },
        {
            obec: 'Svatý Jan nad Malší',
            kod: 545104,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37323,
            latitude: 48.824139,
            longitude: 14.508621,
        },
        {
            obec: 'Světce',
            kod: 561029,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.270655,
            longitude: 14.941505,
        },
        {
            obec: 'Světlík',
            kod: 545813,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38216,
            latitude: 48.731761,
            longitude: 14.21107,
        },
        {
            obec: 'Sviny',
            kod: 553182,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.187242,
            longitude: 14.63601,
        },
        {
            obec: 'Svrabov',
            kod: 563170,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39131,
            latitude: 49.447575,
            longitude: 14.626643,
        },
        {
            obec: 'Šebířov',
            kod: 553204,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.566211,
            longitude: 14.82603,
        },
        {
            obec: 'Ševětín',
            kod: 545121,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37363,
            latitude: 49.100158,
            longitude: 14.572298,
        },
        {
            obec: 'Škvořetice',
            kod: 551830,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.402515,
            longitude: 13.949224,
        },
        {
            obec: 'Štěchovice',
            kod: 536920,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.255508,
            longitude: 13.765623,
        },
        {
            obec: 'Štěkeň',
            kod: 551856,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38751,
            latitude: 49.267249,
            longitude: 14.005988,
        },
        {
            obec: 'Štěpánovice',
            kod: 545139,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37373,
            latitude: 49.00193,
            longitude: 14.653443,
        },
        {
            obec: 'Šumavské Hoštice',
            kod: 550574,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38471,
            latitude: 49.039501,
            longitude: 13.872365,
        },
        {
            obec: 'Tábor',
            kod: 552046,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.413089,
            longitude: 14.677566,
        },
        {
            obec: 'Tálín',
            kod: 549932,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39815,
            latitude: 49.247661,
            longitude: 14.227263,
        },
        {
            obec: 'Temelín',
            kod: 545155,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37301,
            latitude: 49.19294,
            longitude: 14.348847,
        },
        {
            obec: 'Temešvár',
            kod: 562271,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.356486,
            longitude: 14.263631,
        },
        {
            obec: 'Těšovice',
            kod: 550582,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.057215,
            longitude: 14.024005,
        },
        {
            obec: 'Tchořovice',
            kod: 536598,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.437148,
            longitude: 13.809985,
        },
        {
            obec: 'Trhové Sviny',
            kod: 545171,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.842418,
            longitude: 14.639338,
        },
        {
            obec: 'Truskovice',
            kod: 536512,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.104163,
            longitude: 14.161196,
        },
        {
            obec: 'Třebějice',
            kod: 563153,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.244526,
            longitude: 14.81988,
        },
        {
            obec: 'Třebětice',
            kod: 562327,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.047741,
            longitude: 15.52082,
        },
        {
            obec: 'Třebohostice',
            kod: 551899,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38737,
            latitude: 49.33264,
            longitude: 13.861096,
        },
        {
            obec: 'Třeboň',
            kod: 547336,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.003615,
            longitude: 14.764232,
        },
        {
            obec: 'Třešovice',
            kod: 536679,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.211424,
            longitude: 13.98145,
        },
        {
            obec: 'Tučapy',
            kod: 553239,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39126,
            latitude: 49.291392,
            longitude: 14.803517,
        },
        {
            obec: 'Turovec',
            kod: 551601,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39121,
            latitude: 49.378459,
            longitude: 14.769036,
        },
        {
            obec: 'Tvrzice',
            kod: 550604,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.121841,
            longitude: 13.967232,
        },
        {
            obec: 'Týn nad Vltavou',
            kod: 545201,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.223502,
            longitude: 14.42067,
        },
        {
            obec: 'Újezdec',
            kod: 507644,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.207626,
            longitude: 14.784836,
        },
        {
            obec: 'Újezdec',
            kod: 537535,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.108008,
            longitude: 13.964075,
        },
        {
            obec: 'Úlehle',
            kod: 536695,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.201553,
            longitude: 13.838465,
        },
        {
            obec: 'Únice',
            kod: 563951,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.312345,
            longitude: 13.868508,
        },
        {
            obec: 'Úsilné',
            kod: 535494,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37010,
            latitude: 49.01349,
            longitude: 14.507364,
        },
        {
            obec: 'Ústrašice',
            kod: 599123,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.34038,
            longitude: 14.68458,
        },
        {
            obec: 'Uzenice',
            kod: 551937,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.473354,
            longitude: 13.959944,
        },
        {
            obec: 'Uzeničky',
            kod: 536890,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38801,
            latitude: 49.488972,
            longitude: 13.952507,
        },
        {
            obec: 'Vacov',
            kod: 550621,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38486,
            latitude: 49.13697,
            longitude: 13.729209,
        },
        {
            obec: 'Vacovice',
            kod: 536504,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.140476,
            longitude: 13.781044,
        },
        {
            obec: 'Val',
            kod: 553255,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.146155,
            longitude: 14.756143,
        },
        {
            obec: 'Varvažov',
            kod: 562211,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.439009,
            longitude: 14.142696,
        },
        {
            obec: 'Včelná',
            kod: 545228,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37382,
            latitude: 48.923831,
            longitude: 14.453933,
        },
        {
            obec: 'Velešín',
            kod: 545821,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.829589,
            longitude: 14.462614,
        },
        {
            obec: 'Velká Turná',
            kod: 536741,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38601,
            latitude: 49.340306,
            longitude: 13.958805,
        },
        {
            obec: 'Velký Ratmírov',
            kod: 562599,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37701,
            latitude: 49.177749,
            longitude: 14.937594,
        },
        {
            obec: 'Vesce',
            kod: 553263,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.249796,
            longitude: 14.676986,
        },
        {
            obec: 'Veselí nad Lužnicí',
            kod: 553271,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.186616,
            longitude: 14.699294,
        },
        {
            obec: 'Veselíčko',
            kod: 549975,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39842,
            latitude: 49.388105,
            longitude: 14.35687,
        },
        {
            obec: 'Větřní',
            kod: 545830,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38211,
            latitude: 48.774351,
            longitude: 14.286258,
        },
        {
            obec: 'Věžovatá Pláně',
            kod: 536300,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.77629,
            longitude: 14.409159,
        },
        {
            obec: 'Vícemil',
            kod: 561037,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.263954,
            longitude: 14.894383,
        },
        {
            obec: 'Vidov',
            kod: 535737,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37007,
            latitude: 48.926848,
            longitude: 14.494749,
        },
        {
            obec: 'Vilice',
            kod: 553280,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.564574,
            longitude: 14.864415,
        },
        {
            obec: 'Vimperk',
            kod: 550647,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38501,
            latitude: 49.052562,
            longitude: 13.774586,
        },
        {
            obec: 'Višňová',
            kod: 507652,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.219107,
            longitude: 14.841334,
        },
        {
            obec: 'Vitějovice',
            kod: 550655,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38427,
            latitude: 49.046493,
            longitude: 14.073468,
        },
        {
            obec: 'Vitín',
            kod: 535893,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37363,
            latitude: 49.089591,
            longitude: 14.545974,
        },
        {
            obec: 'Vlachovo Březí',
            kod: 550663,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38422,
            latitude: 49.081457,
            longitude: 13.958519,
        },
        {
            obec: 'Vlastec',
            kod: 598844,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.365671,
            longitude: 14.211797,
        },
        {
            obec: 'Vlastiboř',
            kod: 553298,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.258041,
            longitude: 14.63683,
        },
        {
            obec: 'Vlčetínec',
            kod: 598640,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.264592,
            longitude: 15.054625,
        },
        {
            obec: 'Vlčeves',
            kod: 563447,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.357129,
            longitude: 14.900608,
        },
        {
            obec: 'Vlkov',
            kod: 535915,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.113445,
            longitude: 14.522263,
        },
        {
            obec: 'Vlkov',
            kod: 553310,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.151291,
            longitude: 14.725326,
        },
        {
            obec: 'Vlksice',
            kod: 598852,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.481012,
            longitude: 14.441291,
        },
        {
            obec: 'Vodice',
            kod: 553328,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39153,
            latitude: 49.464414,
            longitude: 14.909084,
        },
        {
            obec: 'Vodňany',
            kod: 551953,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38901,
            latitude: 49.147997,
            longitude: 14.175231,
        },
        {
            obec: 'Vojníkov',
            kod: 562289,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.35378,
            longitude: 14.177156,
        },
        {
            obec: 'Volary',
            kod: 550671,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38451,
            latitude: 48.908916,
            longitude: 13.886674,
        },
        {
            obec: 'Volenice',
            kod: 551961,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.252497,
            longitude: 13.747986,
        },
        {
            obec: 'Volfířov',
            kod: 547441,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38001,
            latitude: 49.106922,
            longitude: 15.370353,
        },
        {
            obec: 'Volyně',
            kod: 551970,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38701,
            latitude: 49.165886,
            longitude: 13.886339,
        },
        {
            obec: 'Vrábče',
            kod: 545261,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.917453,
            longitude: 14.376014,
        },
        {
            obec: 'Vráto',
            kod: 535796,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.988054,
            longitude: 14.527017,
        },
        {
            obec: 'Vráž',
            kod: 549991,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39832,
            latitude: 49.383148,
            longitude: 14.12798,
        },
        {
            obec: 'Vrbice',
            kod: 529915,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38473,
            latitude: 49.141384,
            longitude: 13.715891,
        },
        {
            obec: 'Vrcovice',
            kod: 550001,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.344758,
            longitude: 14.174529,
        },
        {
            obec: 'Všemyslice',
            kod: 545287,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37501,
            latitude: 49.217064,
            longitude: 14.35799,
        },
        {
            obec: 'Vydří',
            kod: 562629,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37802,
            latitude: 49.09253,
            longitude: 14.945728,
        },
        {
            obec: 'Vyšší Brod',
            kod: 545848,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38273,
            latitude: 48.616082,
            longitude: 14.311919,
        },
        {
            obec: 'Záblatí',
            kod: 508683,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37901,
            latitude: 49.107283,
            longitude: 14.686266,
        },
        {
            obec: 'Záblatí',
            kod: 550680,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38433,
            latitude: 48.998654,
            longitude: 13.931392,
        },
        {
            obec: 'Záboří',
            kod: 545317,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37384,
            latitude: 48.990054,
            longitude: 14.26727,
        },
        {
            obec: 'Záboří',
            kod: 551988,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38734,
            latitude: 49.378898,
            longitude: 13.827052,
        },
        {
            obec: 'Zábrdí',
            kod: 537195,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.028068,
            longitude: 13.939516,
        },
        {
            obec: 'Zadní Střítež',
            kod: 563927,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.494184,
            longitude: 14.916811,
        },
        {
            obec: 'Zahájí',
            kod: 536016,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37348,
            latitude: 49.089687,
            longitude: 14.370905,
        },
        {
            obec: 'Zahorčice',
            kod: 536482,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38719,
            latitude: 49.208759,
            longitude: 13.817615,
        },
        {
            obec: 'Záhoří',
            kod: 507695,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37821,
            latitude: 49.212955,
            longitude: 14.798462,
        },
        {
            obec: 'Záhoří',
            kod: 550027,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39818,
            latitude: 49.343828,
            longitude: 14.212649,
        },
        {
            obec: 'Záhoří',
            kod: 599000,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39165,
            latitude: 49.239696,
            longitude: 14.513589,
        },
        {
            obec: 'Zahrádky',
            kod: 547468,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37853,
            latitude: 49.20085,
            longitude: 15.241907,
        },
        {
            obec: 'Zálezly',
            kod: 550698,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38481,
            latitude: 49.10755,
            longitude: 13.894683,
        },
        {
            obec: 'Zálší',
            kod: 553361,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.218022,
            longitude: 14.599292,
        },
        {
            obec: 'Závraty',
            kod: 599778,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37001,
            latitude: 48.93815,
            longitude: 14.381608,
        },
        {
            obec: 'Zbelítov',
            kod: 550035,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.456735,
            longitude: 14.329173,
        },
        {
            obec: 'Zběšičky',
            kod: 550043,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39843,
            latitude: 49.392605,
            longitude: 14.426574,
        },
        {
            obec: 'Zbytiny',
            kod: 550701,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38441,
            latitude: 48.942727,
            longitude: 13.978153,
        },
        {
            obec: 'Zdíkov',
            kod: 550728,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38472,
            latitude: 49.084672,
            longitude: 13.697479,
        },
        {
            obec: 'Zhoř',
            kod: 550060,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39901,
            latitude: 49.501316,
            longitude: 14.382796,
        },
        {
            obec: 'Zhoř u Mladé Vožice',
            kod: 599034,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39143,
            latitude: 49.5436,
            longitude: 14.764978,
        },
        {
            obec: 'Zhoř u Tábora',
            kod: 560430,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39002,
            latitude: 49.357623,
            longitude: 14.657625,
        },
        {
            obec: 'Zlatá Koruna',
            kod: 545864,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38202,
            latitude: 48.854873,
            longitude: 14.369638,
        },
        {
            obec: 'Zliv',
            kod: 545341,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37344,
            latitude: 49.066188,
            longitude: 14.366234,
        },
        {
            obec: 'Zlukov',
            kod: 599271,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.193033,
            longitude: 14.745188,
        },
        {
            obec: 'Zubčice',
            kod: 545872,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.794137,
            longitude: 14.40728,
        },
        {
            obec: 'Zvěrotice',
            kod: 553409,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39201,
            latitude: 49.272067,
            longitude: 14.744794,
        },
        {
            obec: 'Zvíkov',
            kod: 535591,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37372,
            latitude: 48.980946,
            longitude: 14.617022,
        },
        {
            obec: 'Zvíkov',
            kod: 536237,
            okres: 'Český Krumlov',
            kod_okres: 'CZ0312',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38232,
            latitude: 48.802632,
            longitude: 14.448859,
        },
        {
            obec: 'Zvíkovské Podhradí',
            kod: 562165,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39701,
            latitude: 49.426678,
            longitude: 14.200666,
        },
        {
            obec: 'Zvotoky',
            kod: 536873,
            okres: 'Strakonice',
            kod_okres: 'CZ0316',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38716,
            latitude: 49.212476,
            longitude: 13.757754,
        },
        {
            obec: 'Žabovřesky',
            kod: 545368,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37341,
            latitude: 49.002908,
            longitude: 14.334957,
        },
        {
            obec: 'Žár',
            kod: 545376,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37401,
            latitude: 48.806971,
            longitude: 14.708287,
        },
        {
            obec: 'Žárovná',
            kod: 529923,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.046302,
            longitude: 13.899419,
        },
        {
            obec: 'Žďár',
            kod: 529761,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37842,
            latitude: 49.254193,
            longitude: 15.076662,
        },
        {
            obec: 'Žďár',
            kod: 598861,
            okres: 'Písek',
            kod_okres: 'CZ0314',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39811,
            latitude: 49.233222,
            longitude: 14.227928,
        },
        {
            obec: 'Želeč',
            kod: 553417,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39174,
            latitude: 49.318442,
            longitude: 14.646734,
        },
        {
            obec: 'Želnava',
            kod: 550761,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38451,
            latitude: 48.812147,
            longitude: 13.965123,
        },
        {
            obec: 'Žernovice',
            kod: 537209,
            okres: 'Prachatice',
            kod_okres: 'CZ0315',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 38301,
            latitude: 49.028655,
            longitude: 14.039648,
        },
        {
            obec: 'Žimutice',
            kod: 545384,
            okres: 'České Budějovice',
            kod_okres: 'CZ0311',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37366,
            latitude: 49.20382,
            longitude: 14.510527,
        },
        {
            obec: 'Žíšov',
            kod: 563897,
            okres: 'Tábor',
            kod_okres: 'CZ0317',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 39181,
            latitude: 49.200048,
            longitude: 14.69348,
        },
        {
            obec: 'Županovice',
            kod: 562424,
            okres: 'Jindřichův Hradec',
            kod_okres: 'CZ0313',
            kraj: 'Jihočeský kraj',
            kod_kraj: 'CZ031',
            psc: 37881,
            latitude: 48.957211,
            longitude: 15.506224,
        },
    ],
    CZ064: [
        {
            obec: 'Adamov',
            kod: 581291,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67904,
            latitude: 49.295708,
            longitude: 16.663955,
        },
        {
            obec: 'Archlebov',
            kod: 586030,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69633,
            latitude: 49.043689,
            longitude: 17.004911,
        },
        {
            obec: 'Babice nad Svitavou',
            kod: 582794,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66401,
            latitude: 49.283402,
            longitude: 16.696235,
        },
        {
            obec: 'Babice u Rosic',
            kod: 582808,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.173078,
            longitude: 16.358273,
        },
        {
            obec: 'Bantice',
            kod: 593729,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.883187,
            longitude: 16.182493,
        },
        {
            obec: 'Bavory',
            kod: 584304,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69201,
            latitude: 48.834789,
            longitude: 16.622349,
        },
        {
            obec: 'Bedřichov',
            kod: 581313,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.461276,
            longitude: 16.465422,
        },
        {
            obec: 'Běhařovice',
            kod: 593737,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67139,
            latitude: 49.004036,
            longitude: 16.078723,
        },
        {
            obec: 'Běleč',
            kod: 581321,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.434036,
            longitude: 16.383235,
        },
        {
            obec: 'Benešov',
            kod: 581330,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67953,
            latitude: 49.509531,
            longitude: 16.770698,
        },
        {
            obec: 'Bezkov',
            kod: 593745,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.871321,
            longitude: 15.951352,
        },
        {
            obec: 'Bílovice nad Svitavou',
            kod: 582824,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66401,
            latitude: 49.247178,
            longitude: 16.672575,
        },
        {
            obec: 'Biskoupky',
            kod: 582832,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.097789,
            longitude: 16.281386,
        },
        {
            obec: 'Bítov',
            kod: 593753,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67110,
            latitude: 48.936395,
            longitude: 15.729075,
        },
        {
            obec: 'Blanné',
            kod: 593761,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 48.992037,
            longitude: 15.883346,
        },
        {
            obec: 'Blansko',
            kod: 581283,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67801,
            latitude: 49.36495,
            longitude: 16.647855,
        },
        {
            obec: 'Blatnice pod Svatým Antonínkem',
            kod: 586048,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69671,
            latitude: 48.946824,
            longitude: 17.466069,
        },
        {
            obec: 'Blatnička',
            kod: 586056,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69671,
            latitude: 48.935609,
            longitude: 17.530151,
        },
        {
            obec: 'Blažovice',
            kod: 582841,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66408,
            latitude: 49.165785,
            longitude: 16.786216,
        },
        {
            obec: 'Blížkovice',
            kod: 593770,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67155,
            latitude: 48.999862,
            longitude: 15.834918,
        },
        {
            obec: 'Blučina',
            kod: 582859,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66456,
            latitude: 49.055066,
            longitude: 16.644601,
        },
        {
            obec: 'Bohaté Málkovice',
            kod: 592897,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.189126,
            longitude: 17.014284,
        },
        {
            obec: 'Bohdalice-Pavlovice',
            kod: 592901,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68341,
            latitude: 49.214143,
            longitude: 17.030228,
        },
        {
            obec: 'Bohutice',
            kod: 593788,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67176,
            latitude: 48.991375,
            longitude: 16.357739,
        },
        {
            obec: 'Bojanovice',
            kod: 593796,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.968259,
            longitude: 15.989159,
        },
        {
            obec: 'Boleradice',
            kod: 584321,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69112,
            latitude: 48.966776,
            longitude: 16.814026,
        },
        {
            obec: 'Borač',
            kod: 595314,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59261,
            latitude: 49.400915,
            longitude: 16.361217,
        },
        {
            obec: 'Borkovany',
            kod: 584339,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69175,
            latitude: 49.028264,
            longitude: 16.810211,
        },
        {
            obec: 'Borotice',
            kod: 593800,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.857391,
            longitude: 16.242711,
        },
        {
            obec: 'Borotín',
            kod: 581356,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67937,
            latitude: 49.581447,
            longitude: 16.671585,
        },
        {
            obec: 'Borovník',
            kod: 595331,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.355656,
            longitude: 16.229456,
        },
        {
            obec: 'Bořetice',
            kod: 584347,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69108,
            latitude: 48.913124,
            longitude: 16.853168,
        },
        {
            obec: 'Bořitov',
            kod: 581364,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.425135,
            longitude: 16.591291,
        },
        {
            obec: 'Boskovice',
            kod: 581372,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.487612,
            longitude: 16.660076,
        },
        {
            obec: 'Boskovštejn',
            kod: 593818,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 48.982797,
            longitude: 15.929654,
        },
        {
            obec: 'Bošovice',
            kod: 592919,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68354,
            latitude: 49.053609,
            longitude: 16.836868,
        },
        {
            obec: 'Božice',
            kod: 593826,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67164,
            latitude: 48.836967,
            longitude: 16.288856,
        },
        {
            obec: 'Braníškov',
            kod: 582875,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.294469,
            longitude: 16.345762,
        },
        {
            obec: 'Branišovice',
            kod: 593834,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67177,
            latitude: 48.962911,
            longitude: 16.431951,
        },
        {
            obec: 'Brankovice',
            kod: 592927,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.156081,
            longitude: 17.134911,
        },
        {
            obec: 'Bratčice',
            kod: 582883,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66467,
            latitude: 49.065247,
            longitude: 16.523184,
        },
        {
            obec: 'Brno',
            kod: 582786,
            okres: 'Brno-město',
            kod_okres: 'CZ0642',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 60200,
            latitude: 49.19516,
            longitude: 16.606937,
        },
        {
            obec: 'Brod nad Dyjí',
            kod: 584355,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69181,
            latitude: 48.875309,
            longitude: 16.535482,
        },
        {
            obec: 'Brťov-Jeneč',
            kod: 581381,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.413781,
            longitude: 16.517076,
        },
        {
            obec: 'Brumov',
            kod: 581402,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.462484,
            longitude: 16.425856,
        },
        {
            obec: 'Brumovice',
            kod: 584363,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69111,
            latitude: 48.960895,
            longitude: 16.89626,
        },
        {
            obec: 'Břeclav',
            kod: 584291,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69002,
            latitude: 48.75324,
            longitude: 16.882617,
        },
        {
            obec: 'Březí',
            kod: 584371,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69181,
            latitude: 48.819377,
            longitude: 16.567583,
        },
        {
            obec: 'Březina',
            kod: 581429,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67905,
            latitude: 49.282167,
            longitude: 16.755163,
        },
        {
            obec: 'Březina',
            kod: 582891,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.281698,
            longitude: 16.749623,
        },
        {
            obec: 'Březina (vojenský újezd)',
            kod: 592935,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 79805,
            latitude: 49.368199,
            longitude: 16.960939,
        },
        {
            obec: 'Břežany',
            kod: 593842,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67165,
            latitude: 48.870023,
            longitude: 16.341852,
        },
        {
            obec: 'Bučovice',
            kod: 592943,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.149402,
            longitude: 17.003981,
        },
        {
            obec: 'Bukovany',
            kod: 586072,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69631,
            latitude: 49.042267,
            longitude: 17.100961,
        },
        {
            obec: 'Bukovice',
            kod: 581437,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.406829,
            longitude: 16.489825,
        },
        {
            obec: 'Bukovina',
            kod: 581445,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67905,
            latitude: 49.297608,
            longitude: 16.773268,
        },
        {
            obec: 'Bukovinka',
            kod: 581453,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67905,
            latitude: 49.294279,
            longitude: 16.805039,
        },
        {
            obec: 'Bulhary',
            kod: 584380,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69189,
            latitude: 48.831565,
            longitude: 16.748843,
        },
        {
            obec: 'Býkovice',
            kod: 581461,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.430303,
            longitude: 16.538385,
        },
        {
            obec: 'Bzenec',
            kod: 586081,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69681,
            latitude: 48.973463,
            longitude: 17.266959,
        },
        {
            obec: 'Cetkovice',
            kod: 581470,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67938,
            latitude: 49.578884,
            longitude: 16.721351,
        },
        {
            obec: 'Citonice',
            kod: 593851,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67101,
            latitude: 48.884055,
            longitude: 15.964567,
        },
        {
            obec: 'Crhov',
            kod: 554162,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.545112,
            longitude: 16.434827,
        },
        {
            obec: 'Ctidružice',
            kod: 593869,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 48.982616,
            longitude: 15.86009,
        },
        {
            obec: 'Cvrčovice',
            kod: 550272,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69123,
            latitude: 48.993768,
            longitude: 16.514581,
        },
        {
            obec: 'Čebín',
            kod: 582913,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66423,
            latitude: 49.313339,
            longitude: 16.478018,
        },
        {
            obec: 'Čejč',
            kod: 586099,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69614,
            latitude: 48.946631,
            longitude: 16.965214,
        },
        {
            obec: 'Čejkovice',
            kod: 586102,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69615,
            latitude: 48.906019,
            longitude: 16.942409,
        },
        {
            obec: 'Čejkovice',
            kod: 593877,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.877801,
            longitude: 16.287428,
        },
        {
            obec: 'Čeložnice',
            kod: 586111,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69651,
            latitude: 49.051964,
            longitude: 17.15476,
        },
        {
            obec: 'Čermákovice',
            kod: 593885,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67173,
            latitude: 49.032153,
            longitude: 16.195072,
        },
        {
            obec: 'Černá Hora',
            kod: 581496,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.413707,
            longitude: 16.581502,
        },
        {
            obec: 'Černín',
            kod: 593893,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.983053,
            longitude: 16.021013,
        },
        {
            obec: 'Černovice',
            kod: 581500,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67975,
            latitude: 49.48363,
            longitude: 16.423018,
        },
        {
            obec: 'Černvír',
            kod: 595446,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59262,
            latitude: 49.445413,
            longitude: 16.346286,
        },
        {
            obec: 'Česká',
            kod: 582921,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66431,
            latitude: 49.280178,
            longitude: 16.56551,
        },
        {
            obec: 'Čučice',
            kod: 582930,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.137181,
            longitude: 16.278687,
        },
        {
            obec: 'Dambořice',
            kod: 586129,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69635,
            latitude: 49.038383,
            longitude: 16.917678,
        },
        {
            obec: 'Damnice',
            kod: 593907,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.920412,
            longitude: 16.374203,
        },
        {
            obec: 'Deblín',
            kod: 582948,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66475,
            latitude: 49.320459,
            longitude: 16.346542,
        },
        {
            obec: 'Deštná',
            kod: 581518,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.612653,
            longitude: 16.563659,
        },
        {
            obec: 'Dětkovice',
            kod: 592978,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68323,
            latitude: 49.272783,
            longitude: 17.144316,
        },
        {
            obec: 'Diváky',
            kod: 584401,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69171,
            latitude: 48.988794,
            longitude: 16.791869,
        },
        {
            obec: 'Dlouhá Lhota',
            kod: 581526,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.428757,
            longitude: 16.5097,
        },
        {
            obec: 'Dobelice',
            kod: 593923,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.017342,
            longitude: 16.27873,
        },
        {
            obec: 'Dobré Pole',
            kod: 584410,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69181,
            latitude: 48.824436,
            longitude: 16.535282,
        },
        {
            obec: 'Dobročkovice',
            kod: 592986,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.163035,
            longitude: 17.109099,
        },
        {
            obec: 'Dobřínsko',
            kod: 593931,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.052312,
            longitude: 16.26177,
        },
        {
            obec: 'Dobšice',
            kod: 546941,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67182,
            latitude: 48.848447,
            longitude: 16.085512,
        },
        {
            obec: 'Dolenice',
            kod: 593958,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.909233,
            longitude: 16.365753,
        },
        {
            obec: 'Dolní Bojanovice',
            kod: 586137,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69617,
            latitude: 48.858707,
            longitude: 17.028691,
        },
        {
            obec: 'Dolní Dubňany',
            kod: 593966,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67173,
            latitude: 49.055887,
            longitude: 16.2268,
        },
        {
            obec: 'Dolní Dunajovice',
            kod: 584428,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69185,
            latitude: 48.854571,
            longitude: 16.592933,
        },
        {
            obec: 'Dolní Kounice',
            kod: 582956,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.070212,
            longitude: 16.465024,
        },
        {
            obec: 'Dolní Loučky',
            kod: 595527,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.361092,
            longitude: 16.358812,
        },
        {
            obec: 'Dolní Věstonice',
            kod: 584436,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69129,
            latitude: 48.888324,
            longitude: 16.643792,
        },
        {
            obec: 'Domanín',
            kod: 586145,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69683,
            latitude: 49.00177,
            longitude: 17.284869,
        },
        {
            obec: 'Domašov',
            kod: 582964,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.245429,
            longitude: 16.343924,
        },
        {
            obec: 'Doubravice nad Svitavou',
            kod: 581542,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67911,
            latitude: 49.436736,
            longitude: 16.629473,
        },
        {
            obec: 'Doubravník',
            kod: 595551,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59261,
            latitude: 49.424208,
            longitude: 16.352799,
        },
        {
            obec: 'Drahonín',
            kod: 595560,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59261,
            latitude: 49.414161,
            longitude: 16.277005,
        },
        {
            obec: 'Drásov',
            kod: 582972,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66424,
            latitude: 49.331934,
            longitude: 16.47809,
        },
        {
            obec: 'Dražovice',
            kod: 592994,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.194842,
            longitude: 16.943484,
        },
        {
            obec: 'Dražůvky',
            kod: 586153,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69633,
            latitude: 49.030633,
            longitude: 17.019078,
        },
        {
            obec: 'Drnholec',
            kod: 584444,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69183,
            latitude: 48.857558,
            longitude: 16.485962,
        },
        {
            obec: 'Drnovice',
            kod: 581551,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67976,
            latitude: 49.469406,
            longitude: 16.542335,
        },
        {
            obec: 'Drnovice',
            kod: 593001,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68304,
            latitude: 49.276395,
            longitude: 16.951569,
        },
        {
            obec: 'Drysice',
            kod: 593010,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68321,
            latitude: 49.334642,
            longitude: 17.057846,
        },
        {
            obec: 'Dubňany',
            kod: 586161,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69603,
            latitude: 48.917038,
            longitude: 17.090148,
        },
        {
            obec: 'Dyjákovice',
            kod: 593974,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67126,
            latitude: 48.771975,
            longitude: 16.301068,
        },
        {
            obec: 'Dyjákovičky',
            kod: 593982,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.7824,
            longitude: 16.095728,
        },
        {
            obec: 'Dyje',
            kod: 593991,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.847219,
            longitude: 16.1166,
        },
        {
            obec: 'Džbánice',
            kod: 594008,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67171,
            latitude: 49.001361,
            longitude: 16.211038,
        },
        {
            obec: 'Grešlové Mýto',
            kod: 594016,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67156,
            latitude: 48.980854,
            longitude: 15.886269,
        },
        {
            obec: 'Habrovany',
            kod: 593028,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.232934,
            longitude: 16.878393,
        },
        {
            obec: 'Habrůvka',
            kod: 581569,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67905,
            latitude: 49.3036,
            longitude: 16.72362,
        },
        {
            obec: 'Hajany',
            kod: 582999,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66443,
            latitude: 49.110916,
            longitude: 16.55509,
        },
        {
            obec: 'Havraníky',
            kod: 594024,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.811525,
            longitude: 16.008488,
        },
        {
            obec: 'Heroltice',
            kod: 583014,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.311457,
            longitude: 16.412898,
        },
        {
            obec: 'Heršpice',
            kod: 550213,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.119869,
            longitude: 16.913668,
        },
        {
            obec: 'Hevlín',
            kod: 594032,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67169,
            latitude: 48.75219,
            longitude: 16.381414,
        },
        {
            obec: 'Hlína',
            kod: 583022,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.113718,
            longitude: 16.426268,
        },
        {
            obec: 'Hlohovec',
            kod: 584452,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69143,
            latitude: 48.774086,
            longitude: 16.762404,
        },
        {
            obec: 'Hlubočany',
            kod: 593036,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.230519,
            longitude: 17.000469,
        },
        {
            obec: 'Hluboké Dvory',
            kod: 581577,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.383241,
            longitude: 16.514309,
        },
        {
            obec: 'Hluboké Mašůvky',
            kod: 594041,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67152,
            latitude: 48.923757,
            longitude: 16.025651,
        },
        {
            obec: 'Hnanice',
            kod: 594059,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.798587,
            longitude: 15.98678,
        },
        {
            obec: 'Hodějice',
            kod: 593044,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.141595,
            longitude: 16.913792,
        },
        {
            obec: 'Hodonice',
            kod: 594067,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67125,
            latitude: 48.8369,
            longitude: 16.163476,
        },
        {
            obec: 'Hodonín',
            kod: 581593,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.501284,
            longitude: 16.410379,
        },
        {
            obec: 'Hodonín',
            kod: 586021,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69501,
            latitude: 48.853039,
            longitude: 17.126102,
        },
        {
            obec: 'Holasice',
            kod: 583031,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.075956,
            longitude: 16.607667,
        },
        {
            obec: 'Holštejn',
            kod: 581615,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67913,
            latitude: 49.406486,
            longitude: 16.777804,
        },
        {
            obec: 'Holubice',
            kod: 550825,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68351,
            latitude: 49.177588,
            longitude: 16.812243,
        },
        {
            obec: 'Horní Bojanovice',
            kod: 584461,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.949805,
            longitude: 16.800252,
        },
        {
            obec: 'Horní Břečkov',
            kod: 594075,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.890055,
            longitude: 15.898559,
        },
        {
            obec: 'Horní Dubňany',
            kod: 594083,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67173,
            latitude: 49.06346,
            longitude: 16.201379,
        },
        {
            obec: 'Horní Dunajovice',
            kod: 594091,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67134,
            latitude: 48.950344,
            longitude: 16.161627,
        },
        {
            obec: 'Horní Kounice',
            kod: 594105,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 49.026094,
            longitude: 16.152366,
        },
        {
            obec: 'Horní Loučky',
            kod: 595667,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.366906,
            longitude: 16.341499,
        },
        {
            obec: 'Horní Poříčí',
            kod: 581631,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.587696,
            longitude: 16.477436,
        },
        {
            obec: 'Horní Smržov',
            kod: 513695,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.618358,
            longitude: 16.577085,
        },
        {
            obec: 'Horní Věstonice',
            kod: 584479,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69181,
            latitude: 48.874651,
            longitude: 16.62566,
        },
        {
            obec: 'Hostěnice',
            kod: 583057,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66404,
            latitude: 49.236641,
            longitude: 16.776652,
        },
        {
            obec: 'Hostěradice',
            kod: 594113,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67171,
            latitude: 48.950156,
            longitude: 16.259416,
        },
        {
            obec: 'Hostěrádky-Rešov',
            kod: 593052,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68352,
            latitude: 49.117751,
            longitude: 16.784406,
        },
        {
            obec: 'Hostim',
            kod: 594121,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 49.018691,
            longitude: 15.894995,
        },
        {
            obec: 'Hoštice-Heroltice',
            kod: 593061,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.287107,
            longitude: 17.062864,
        },
        {
            obec: 'Hovorany',
            kod: 586170,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69612,
            latitude: 48.95503,
            longitude: 16.993562,
        },
        {
            obec: 'Hrabětice',
            kod: 594130,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67168,
            latitude: 48.797751,
            longitude: 16.393527,
        },
        {
            obec: 'Hradčany',
            kod: 583065,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66603,
            latitude: 49.327964,
            longitude: 16.442271,
        },
        {
            obec: 'Hrádek',
            kod: 594148,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67127,
            latitude: 48.772933,
            longitude: 16.268293,
        },
        {
            obec: 'Hroznová Lhota',
            kod: 586188,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69663,
            latitude: 48.90794,
            longitude: 17.417082,
        },
        {
            obec: 'Hrubá Vrbka',
            kod: 586196,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69673,
            latitude: 48.871977,
            longitude: 17.477119,
        },
        {
            obec: 'Hrušky',
            kod: 584487,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69156,
            latitude: 48.792823,
            longitude: 16.97415,
        },
        {
            obec: 'Hrušky',
            kod: 593079,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68352,
            latitude: 49.129301,
            longitude: 16.833583,
        },
        {
            obec: 'Hrušovany u Brna',
            kod: 583081,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66462,
            latitude: 49.038731,
            longitude: 16.59439,
        },
        {
            obec: 'Hrušovany nad Jevišovkou',
            kod: 594156,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67167,
            latitude: 48.830008,
            longitude: 16.40281,
        },
        {
            obec: 'Hustopeče',
            kod: 584495,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.940946,
            longitude: 16.737726,
        },
        {
            obec: 'Hvězdlice',
            kod: 593087,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68341,
            latitude: 49.193463,
            longitude: 17.083627,
        },
        {
            obec: 'Hvozdec',
            kod: 583090,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.247005,
            longitude: 16.425292,
        },
        {
            obec: 'Hýsly',
            kod: 586200,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69650,
            latitude: 49.023388,
            longitude: 17.179934,
        },
        {
            obec: 'Chrudichromy',
            kod: 581534,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.502996,
            longitude: 16.628098,
        },
        {
            obec: 'Chudčice',
            kod: 583111,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.288591,
            longitude: 16.458829,
        },
        {
            obec: 'Chvalatice',
            kod: 594164,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.947809,
            longitude: 15.751157,
        },
        {
            obec: 'Chvalkovice',
            kod: 593095,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68341,
            latitude: 49.187125,
            longitude: 17.110212,
        },
        {
            obec: 'Chvalovice',
            kod: 594172,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.786677,
            longitude: 16.082449,
        },
        {
            obec: 'Ivaň',
            kod: 584517,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69123,
            latitude: 48.929811,
            longitude: 16.575843,
        },
        {
            obec: 'Ivančice',
            kod: 583120,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.101545,
            longitude: 16.377622,
        },
        {
            obec: 'Ivanovice na Hané',
            kod: 593117,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68323,
            latitude: 49.305518,
            longitude: 17.093537,
        },
        {
            obec: 'Jabloňany',
            kod: 581666,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.465713,
            longitude: 16.6061,
        },
        {
            obec: 'Jamolice',
            kod: 594181,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.072435,
            longitude: 16.253784,
        },
        {
            obec: 'Jaroslavice',
            kod: 594199,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.756671,
            longitude: 16.233615,
        },
        {
            obec: 'Javorník',
            kod: 586218,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69674,
            latitude: 48.862174,
            longitude: 17.533503,
        },
        {
            obec: 'Javůrek',
            kod: 583154,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.255628,
            longitude: 16.362283,
        },
        {
            obec: 'Jedovnice',
            kod: 581682,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.344631,
            longitude: 16.756055,
        },
        {
            obec: 'Jevišovice',
            kod: 594202,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.987465,
            longitude: 15.990027,
        },
        {
            obec: 'Jevišovka',
            kod: 584525,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69183,
            latitude: 48.82869,
            longitude: 16.46626,
        },
        {
            obec: 'Jezeřany-Maršovice',
            kod: 594211,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67175,
            latitude: 49.028912,
            longitude: 16.437429,
        },
        {
            obec: 'Ježkovice',
            kod: 593125,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68304,
            latitude: 49.298073,
            longitude: 16.892634,
        },
        {
            obec: 'Ježov',
            kod: 586226,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69648,
            latitude: 49.027077,
            longitude: 17.210115,
        },
        {
            obec: 'Jinačovice',
            kod: 583171,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66434,
            latitude: 49.267903,
            longitude: 16.529591,
        },
        {
            obec: 'Jiřice u Miroslavi',
            kod: 594229,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.920914,
            longitude: 16.39205,
        },
        {
            obec: 'Jiřice u Moravských Budějovic',
            kod: 550841,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 48.993974,
            longitude: 15.925489,
        },
        {
            obec: 'Jiříkovice',
            kod: 583189,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66451,
            latitude: 49.166816,
            longitude: 16.758096,
        },
        {
            obec: 'Josefov',
            kod: 586234,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69621,
            latitude: 48.839579,
            longitude: 17.010146,
        },
        {
            obec: 'Kadov',
            kod: 594237,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 48.982858,
            longitude: 16.287457,
        },
        {
            obec: 'Kaly',
            kod: 595837,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.379422,
            longitude: 16.351706,
        },
        {
            obec: 'Kanice',
            kod: 583197,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66401,
            latitude: 49.263805,
            longitude: 16.714624,
        },
        {
            obec: 'Karlín',
            kod: 586242,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69614,
            latitude: 48.975875,
            longitude: 16.977406,
        },
        {
            obec: 'Kašnice',
            kod: 550256,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69172,
            latitude: 48.996536,
            longitude: 16.882945,
        },
        {
            obec: 'Katov',
            kod: 587907,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.332793,
            longitude: 16.278861,
        },
        {
            obec: 'Kelčany',
            kod: 586251,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69649,
            latitude: 49.005319,
            longitude: 17.173901,
        },
        {
            obec: 'Ketkovice',
            kod: 583201,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.159216,
            longitude: 16.26264,
        },
        {
            obec: 'Klentnice',
            kod: 584541,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69201,
            latitude: 48.844725,
            longitude: 16.644789,
        },
        {
            obec: 'Klobouky u Brna',
            kod: 584550,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69172,
            latitude: 48.995112,
            longitude: 16.859619,
        },
        {
            obec: 'Kněždub',
            kod: 586269,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69664,
            latitude: 48.886761,
            longitude: 17.395572,
        },
        {
            obec: 'Kněževes',
            kod: 581721,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.587125,
            longitude: 16.423892,
        },
        {
            obec: 'Knínice u Boskovic',
            kod: 581739,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67934,
            latitude: 49.539688,
            longitude: 16.69513,
        },
        {
            obec: 'Kobeřice u Brna',
            kod: 593141,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.092284,
            longitude: 16.885919,
        },
        {
            obec: 'Kobylí',
            kod: 584568,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69110,
            latitude: 48.932959,
            longitude: 16.891691,
        },
        {
            obec: 'Kobylnice',
            kod: 583219,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66451,
            latitude: 49.138134,
            longitude: 16.73193,
        },
        {
            obec: 'Kojátky',
            kod: 593150,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.171903,
            longitude: 17.026237,
        },
        {
            obec: 'Komořany',
            kod: 593168,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.216446,
            longitude: 16.906888,
        },
        {
            obec: 'Korolupy',
            kod: 594253,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.930251,
            longitude: 15.646296,
        },
        {
            obec: 'Kořenec',
            kod: 581755,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.530418,
            longitude: 16.754045,
        },
        {
            obec: 'Kostelec',
            kod: 586277,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69651,
            latitude: 49.02693,
            longitude: 17.153306,
        },
        {
            obec: 'Kostice',
            kod: 584576,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69152,
            latitude: 48.74695,
            longitude: 16.978794,
        },
        {
            obec: 'Kotvrdovice',
            kod: 581763,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67907,
            latitude: 49.355206,
            longitude: 16.784459,
        },
        {
            obec: 'Kovalovice',
            kod: 583227,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66406,
            latitude: 49.204721,
            longitude: 16.81914,
        },
        {
            obec: 'Kozárov',
            kod: 581771,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.443935,
            longitude: 16.458762,
        },
        {
            obec: 'Kozlany',
            kod: 550108,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68341,
            latitude: 49.20417,
            longitude: 17.035316,
        },
        {
            obec: 'Kozojídky',
            kod: 586285,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69663,
            latitude: 48.918744,
            longitude: 17.399733,
        },
        {
            obec: 'Kožušice',
            kod: 593184,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.15519,
            longitude: 17.18489,
        },
        {
            obec: 'Krásensko',
            kod: 593192,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68304,
            latitude: 49.364634,
            longitude: 16.829977,
        },
        {
            obec: 'Krasová',
            kod: 581780,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.361476,
            longitude: 16.768035,
        },
        {
            obec: 'Kratochvilka',
            kod: 583235,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.156753,
            longitude: 16.376457,
        },
        {
            obec: 'Kravsko',
            kod: 594261,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67151,
            latitude: 48.921596,
            longitude: 15.986425,
        },
        {
            obec: 'Krhov',
            kod: 581798,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.46036,
            longitude: 16.583923,
        },
        {
            obec: 'Krhovice',
            kod: 594270,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.815911,
            longitude: 16.173693,
        },
        {
            obec: 'Krumvíř',
            kod: 584584,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69173,
            latitude: 48.989106,
            longitude: 16.910379,
        },
        {
            obec: 'Křenovice',
            kod: 593214,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68352,
            latitude: 49.142261,
            longitude: 16.829426,
        },
        {
            obec: 'Křepice',
            kod: 584592,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69165,
            latitude: 48.999961,
            longitude: 16.719993,
        },
        {
            obec: 'Křepice',
            kod: 594288,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 48.98749,
            longitude: 16.097506,
        },
        {
            obec: 'Křetín',
            kod: 581801,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.563204,
            longitude: 16.504506,
        },
        {
            obec: 'Křídlůvky',
            kod: 550086,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.781811,
            longitude: 16.239054,
        },
        {
            obec: 'Křižanovice',
            kod: 593222,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.142428,
            longitude: 16.939154,
        },
        {
            obec: 'Křižanovice u Vyškova',
            kod: 593231,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.287758,
            longitude: 17.040305,
        },
        {
            obec: 'Křižínkov',
            kod: 595934,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59453,
            latitude: 49.32587,
            longitude: 16.270362,
        },
        {
            obec: 'Křtěnov',
            kod: 581810,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.545085,
            longitude: 16.419371,
        },
        {
            obec: 'Křtiny',
            kod: 581828,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67905,
            latitude: 49.297155,
            longitude: 16.744194,
        },
        {
            obec: 'Kubšice',
            kod: 594296,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67176,
            latitude: 49.000765,
            longitude: 16.413164,
        },
        {
            obec: 'Kučerov',
            kod: 593249,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.218686,
            longitude: 17.005432,
        },
        {
            obec: 'Kuchařovice',
            kod: 594300,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.876057,
            longitude: 16.077071,
        },
        {
            obec: 'Kulířov',
            kod: 581836,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.379422,
            longitude: 16.846914,
        },
        {
            obec: 'Kunčina Ves',
            kod: 581844,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.44708,
            longitude: 16.475366,
        },
        {
            obec: 'Kunice',
            kod: 581852,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.478926,
            longitude: 16.490834,
        },
        {
            obec: 'Kuničky',
            kod: 581861,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.435026,
            longitude: 16.681468,
        },
        {
            obec: 'Kunštát',
            kod: 581879,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67972,
            latitude: 49.506551,
            longitude: 16.517324,
        },
        {
            obec: 'Kupařovice',
            kod: 583243,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.043314,
            longitude: 16.490816,
        },
        {
            obec: 'Kurdějov',
            kod: 555282,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.957874,
            longitude: 16.763556,
        },
        {
            obec: 'Kuřim',
            kod: 583251,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66434,
            latitude: 49.298618,
            longitude: 16.531546,
        },
        {
            obec: 'Kuřimská Nová Ves',
            kod: 595985,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.345656,
            longitude: 16.297158,
        },
        {
            obec: 'Kuřimské Jestřabí',
            kod: 595993,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.346676,
            longitude: 16.314032,
        },
        {
            obec: 'Kuželov',
            kod: 586293,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69673,
            latitude: 48.859737,
            longitude: 17.488933,
        },
        {
            obec: 'Kyjov',
            kod: 586307,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69701,
            latitude: 49.009967,
            longitude: 17.12261,
        },
        {
            obec: 'Kyjovice',
            kod: 594318,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.913818,
            longitude: 16.166617,
        },
        {
            obec: 'Labuty',
            kod: 586315,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69648,
            latitude: 49.046739,
            longitude: 17.214588,
        },
        {
            obec: 'Ladná',
            kod: 558443,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69146,
            latitude: 48.805496,
            longitude: 16.872412,
        },
        {
            obec: 'Lančov',
            kod: 594326,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.907159,
            longitude: 15.767901,
        },
        {
            obec: 'Lanžhot',
            kod: 584622,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69151,
            latitude: 48.724531,
            longitude: 16.967053,
        },
        {
            obec: 'Lazinov',
            kod: 581887,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.563913,
            longitude: 16.523495,
        },
        {
            obec: 'Lažánky',
            kod: 583260,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.279046,
            longitude: 16.388167,
        },
        {
            obec: 'Lažany',
            kod: 581909,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.354468,
            longitude: 16.550117,
        },
        {
            obec: 'Ledce',
            kod: 583278,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66462,
            latitude: 49.051603,
            longitude: 16.55712,
        },
        {
            obec: 'Lednice',
            kod: 584631,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69144,
            latitude: 48.800018,
            longitude: 16.803498,
        },
        {
            obec: 'Lechovice',
            kod: 594334,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67163,
            latitude: 48.872953,
            longitude: 16.221921,
        },
        {
            obec: 'Lelekovice',
            kod: 583286,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66431,
            latitude: 49.291537,
            longitude: 16.578848,
        },
        {
            obec: 'Lesná',
            kod: 594342,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.907277,
            longitude: 15.868824,
        },
        {
            obec: 'Lesní Hluboké',
            kod: 583294,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.268807,
            longitude: 16.306928,
        },
        {
            obec: 'Lesonice',
            kod: 594351,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.003355,
            longitude: 16.310735,
        },
        {
            obec: 'Letonice',
            kod: 593257,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68335,
            latitude: 49.17736,
            longitude: 16.959237,
        },
        {
            obec: 'Letovice',
            kod: 581917,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.547189,
            longitude: 16.573678,
        },
        {
            obec: 'Lhota Rapotina',
            kod: 581925,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.466146,
            longitude: 16.624954,
        },
        {
            obec: 'Lhota u Lysic',
            kod: 581933,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.469778,
            longitude: 16.496885,
        },
        {
            obec: 'Lhota u Olešnice',
            kod: 581941,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.544909,
            longitude: 16.390829,
        },
        {
            obec: 'Lipov',
            kod: 586323,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69672,
            latitude: 48.905046,
            longitude: 17.46182,
        },
        {
            obec: 'Lipovec',
            kod: 581950,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67915,
            latitude: 49.38404,
            longitude: 16.805938,
        },
        {
            obec: 'Lipůvka',
            kod: 581968,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.340382,
            longitude: 16.553004,
        },
        {
            obec: 'Litobratřice',
            kod: 594369,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.887149,
            longitude: 16.402604,
        },
        {
            obec: 'Litostrov',
            kod: 583308,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.225246,
            longitude: 16.330795,
        },
        {
            obec: 'Loděnice',
            kod: 594377,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67175,
            latitude: 49.011698,
            longitude: 16.463176,
        },
        {
            obec: 'Lomnice',
            kod: 581976,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.404719,
            longitude: 16.413696,
        },
        {
            obec: 'Lomnička',
            kod: 583316,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.367091,
            longitude: 16.431352,
        },
        {
            obec: 'Louka',
            kod: 553875,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.529269,
            longitude: 16.43115,
        },
        {
            obec: 'Louka',
            kod: 586331,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69676,
            latitude: 48.915266,
            longitude: 17.489382,
        },
        {
            obec: 'Lovčice',
            kod: 586340,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69639,
            latitude: 49.063682,
            longitude: 17.05439,
        },
        {
            obec: 'Lovčičky',
            kod: 593265,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68354,
            latitude: 49.068009,
            longitude: 16.850309,
        },
        {
            obec: 'Lubě',
            kod: 581992,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.396274,
            longitude: 16.526068,
        },
        {
            obec: 'Lubné',
            kod: 596078,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.354268,
            longitude: 16.275314,
        },
        {
            obec: 'Lubnice',
            kod: 594385,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.939563,
            longitude: 15.613963,
        },
        {
            obec: 'Ludíkov',
            kod: 531006,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.455493,
            longitude: 16.731887,
        },
        {
            obec: 'Lukov',
            kod: 594393,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.861685,
            longitude: 15.910794,
        },
        {
            obec: 'Lukovany',
            kod: 583324,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.162573,
            longitude: 16.297954,
        },
        {
            obec: 'Luleč',
            kod: 593273,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68303,
            latitude: 49.253592,
            longitude: 16.928755,
        },
        {
            obec: 'Lužice',
            kod: 586358,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69618,
            latitude: 48.841077,
            longitude: 17.071145,
        },
        {
            obec: 'Lysice',
            kod: 582018,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.451697,
            longitude: 16.537263,
        },
        {
            obec: 'Lysovice',
            kod: 593281,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.21801,
            longitude: 16.970637,
        },
        {
            obec: 'Mackovice',
            kod: 594407,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.883712,
            longitude: 16.309445,
        },
        {
            obec: 'Makov',
            kod: 582026,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67972,
            latitude: 49.519247,
            longitude: 16.483791,
        },
        {
            obec: 'Malá Lhota',
            kod: 582034,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.39199,
            longitude: 16.549636,
        },
        {
            obec: 'Malá Roudka',
            kod: 582042,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67963,
            latitude: 49.60178,
            longitude: 16.644574,
        },
        {
            obec: 'Malá Vrbka',
            kod: 586366,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69673,
            latitude: 48.869505,
            longitude: 17.459307,
        },
        {
            obec: 'Malešovice',
            kod: 583332,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66465,
            latitude: 49.023694,
            longitude: 16.501392,
        },
        {
            obec: 'Malhostovice',
            kod: 583341,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66603,
            latitude: 49.333699,
            longitude: 16.502322,
        },
        {
            obec: 'Malínky',
            kod: 593290,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.159408,
            longitude: 17.160321,
        },
        {
            obec: 'Maršov',
            kod: 583359,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.28378,
            longitude: 16.359854,
        },
        {
            obec: 'Mašovice',
            kod: 594415,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.857475,
            longitude: 15.973646,
        },
        {
            obec: 'Medlice',
            kod: 594423,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 49.003081,
            longitude: 16.121404,
        },
        {
            obec: 'Medlov',
            kod: 583367,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66466,
            latitude: 49.03519,
            longitude: 16.522482,
        },
        {
            obec: 'Medlovice',
            kod: 550141,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.27451,
            longitude: 17.093049,
        },
        {
            obec: 'Mělčany',
            kod: 583375,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.076242,
            longitude: 16.4923,
        },
        {
            obec: 'Měnín',
            kod: 583383,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66457,
            latitude: 49.082495,
            longitude: 16.694343,
        },
        {
            obec: 'Míchov',
            kod: 582069,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.524132,
            longitude: 16.615857,
        },
        {
            obec: 'Mikulčice',
            kod: 586374,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69619,
            latitude: 48.816526,
            longitude: 17.051171,
        },
        {
            obec: 'Mikulov',
            kod: 584649,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69201,
            latitude: 48.805383,
            longitude: 16.637788,
        },
        {
            obec: 'Mikulovice',
            kod: 594431,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67133,
            latitude: 48.956878,
            longitude: 16.09478,
        },
        {
            obec: 'Milešovice',
            kod: 593320,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68354,
            latitude: 49.082845,
            longitude: 16.854355,
        },
        {
            obec: 'Milíčovice',
            kod: 594440,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.891673,
            longitude: 15.932448,
        },
        {
            obec: 'Milonice',
            kod: 582077,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.366571,
            longitude: 16.568459,
        },
        {
            obec: 'Milonice',
            kod: 593338,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.15947,
            longitude: 17.071226,
        },
        {
            obec: 'Milotice',
            kod: 586382,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69605,
            latitude: 48.955858,
            longitude: 17.141387,
        },
        {
            obec: 'Milovice',
            kod: 584657,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69188,
            latitude: 48.852588,
            longitude: 16.698247,
        },
        {
            obec: 'Miroslav',
            kod: 594458,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67172,
            latitude: 48.94777,
            longitude: 16.312624,
        },
        {
            obec: 'Miroslavské Knínice',
            kod: 594466,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67172,
            latitude: 48.977901,
            longitude: 16.32241,
        },
        {
            obec: 'Modřice',
            kod: 583391,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66442,
            latitude: 49.12799,
            longitude: 16.614517,
        },
        {
            obec: 'Mokrá-Horákov',
            kod: 583405,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66404,
            latitude: 49.22287,
            longitude: 16.751677,
        },
        {
            obec: 'Morašice',
            kod: 594474,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67171,
            latitude: 48.957631,
            longitude: 16.209004,
        },
        {
            obec: 'Moravany',
            kod: 583413,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66448,
            latitude: 49.14566,
            longitude: 16.576269,
        },
        {
            obec: 'Moravany',
            kod: 586391,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69650,
            latitude: 49.040216,
            longitude: 17.169127,
        },
        {
            obec: 'Moravská Nová Ves',
            kod: 584665,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69155,
            latitude: 48.803142,
            longitude: 17.013814,
        },
        {
            obec: 'Moravské Bránice',
            kod: 583421,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.083374,
            longitude: 16.436388,
        },
        {
            obec: 'Moravské Knínice',
            kod: 583430,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66434,
            latitude: 49.293382,
            longitude: 16.50184,
        },
        {
            obec: 'Moravské Málkovice',
            kod: 593346,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.257021,
            longitude: 17.090766,
        },
        {
            obec: 'Moravský Krumlov',
            kod: 594482,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.049031,
            longitude: 16.311798,
        },
        {
            obec: 'Moravský Písek',
            kod: 586404,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69685,
            latitude: 48.990264,
            longitude: 17.332795,
        },
        {
            obec: 'Moravský Žižkov',
            kod: 584673,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69101,
            latitude: 48.832952,
            longitude: 16.931502,
        },
        {
            obec: 'Morkůvky',
            kod: 584681,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69172,
            latitude: 48.966228,
            longitude: 16.862319,
        },
        {
            obec: 'Mouchnice',
            kod: 593354,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.111822,
            longitude: 17.135627,
        },
        {
            obec: 'Mouřínov',
            kod: 557048,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.121563,
            longitude: 16.978594,
        },
        {
            obec: 'Moutnice',
            kod: 583448,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66455,
            latitude: 49.049337,
            longitude: 16.73752,
        },
        {
            obec: 'Mutěnice',
            kod: 586412,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69611,
            latitude: 48.904228,
            longitude: 17.02928,
        },
        {
            obec: 'Násedlovice',
            kod: 586421,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69636,
            latitude: 49.012277,
            longitude: 16.960303,
        },
        {
            obec: 'Našiměřice',
            kod: 594512,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67176,
            latitude: 48.968632,
            longitude: 16.372859,
        },
        {
            obec: 'Nebovidy',
            kod: 583456,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66448,
            latitude: 49.141432,
            longitude: 16.551476,
        },
        {
            obec: 'Nedvědice',
            kod: 596175,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59262,
            latitude: 49.457116,
            longitude: 16.334163,
        },
        {
            obec: 'Nechvalín',
            kod: 586439,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69631,
            latitude: 49.054642,
            longitude: 17.080689,
        },
        {
            obec: 'Nelepeč-Žernůvka',
            kod: 583464,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.334089,
            longitude: 16.386382,
        },
        {
            obec: 'Němčany',
            kod: 593371,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.164452,
            longitude: 16.919514,
        },
        {
            obec: 'Němčice',
            kod: 582085,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67951,
            latitude: 49.450065,
            longitude: 16.718057,
        },
        {
            obec: 'Němčičky',
            kod: 583472,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66466,
            latitude: 49.050477,
            longitude: 16.5007,
        },
        {
            obec: 'Němčičky',
            kod: 584703,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69107,
            latitude: 48.93569,
            longitude: 16.824364,
        },
        {
            obec: 'Němčičky',
            kod: 594521,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.942345,
            longitude: 16.088509,
        },
        {
            obec: 'Nemochovice',
            kod: 593389,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.18054,
            longitude: 17.135056,
        },
        {
            obec: 'Nemojany',
            kod: 593397,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68303,
            latitude: 49.246812,
            longitude: 16.913617,
        },
        {
            obec: 'Nemotice',
            kod: 593401,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.129699,
            longitude: 17.124981,
        },
        {
            obec: 'Nenkovice',
            kod: 586447,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69637,
            latitude: 49.003151,
            longitude: 17.01104,
        },
        {
            obec: 'Neslovice',
            kod: 583481,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.142313,
            longitude: 16.387883,
        },
        {
            obec: 'Nesovice',
            kod: 593419,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.151205,
            longitude: 17.081054,
        },
        {
            obec: 'Nesvačilka',
            kod: 583499,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66454,
            latitude: 49.064124,
            longitude: 16.754104,
        },
        {
            obec: 'Nevojice',
            kod: 593427,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.135416,
            longitude: 17.049752,
        },
        {
            obec: 'Níhov',
            kod: 596191,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.341792,
            longitude: 16.257645,
        },
        {
            obec: 'Nikolčice',
            kod: 584711,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69171,
            latitude: 48.993283,
            longitude: 16.754291,
        },
        {
            obec: 'Nížkovice',
            kod: 593435,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.109637,
            longitude: 16.901793,
        },
        {
            obec: 'Nosislav',
            kod: 584720,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69164,
            latitude: 49.013902,
            longitude: 16.654416,
        },
        {
            obec: 'Nová Lhota',
            kod: 586455,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69674,
            latitude: 48.861047,
            longitude: 17.593418,
        },
        {
            obec: 'Nová Ves',
            kod: 583502,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66491,
            latitude: 49.108212,
            longitude: 16.310943,
        },
        {
            obec: 'Nové Bránice',
            kod: 583511,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.071249,
            longitude: 16.441855,
        },
        {
            obec: 'Nové Sady',
            kod: 593443,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68308,
            latitude: 49.408906,
            longitude: 16.902045,
        },
        {
            obec: 'Novosedly',
            kod: 584746,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69182,
            latitude: 48.83714,
            longitude: 16.492837,
        },
        {
            obec: 'Nový Poddvorov',
            kod: 586463,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69616,
            latitude: 48.868447,
            longitude: 16.961126,
        },
        {
            obec: 'Nový Přerov',
            kod: 584754,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69181,
            latitude: 48.809718,
            longitude: 16.493828,
        },
        {
            obec: 'Nový Šaldorf-Sedlešovice',
            kod: 587729,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67181,
            latitude: 48.830248,
            longitude: 16.060675,
        },
        {
            obec: 'Nýrov',
            kod: 582107,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67972,
            latitude: 49.528083,
            longitude: 16.539265,
        },
        {
            obec: 'Obora',
            kod: 582115,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.452311,
            longitude: 16.605726,
        },
        {
            obec: 'Odrovice',
            kod: 583529,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.010907,
            longitude: 16.509119,
        },
        {
            obec: 'Ochoz u Brna',
            kod: 583537,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66402,
            latitude: 49.254302,
            longitude: 16.737338,
        },
        {
            obec: 'Ochoz u Tišnova',
            kod: 582123,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.425693,
            longitude: 16.394338,
        },
        {
            obec: 'Okrouhlá',
            kod: 582131,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.507604,
            longitude: 16.732103,
        },
        {
            obec: 'Olbramkostel',
            kod: 594555,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67151,
            latitude: 48.921815,
            longitude: 15.949704,
        },
        {
            obec: 'Olbramovice',
            kod: 594563,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67176,
            latitude: 48.984904,
            longitude: 16.402498,
        },
        {
            obec: 'Oleksovice',
            kod: 594571,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67162,
            latitude: 48.902158,
            longitude: 16.248504,
        },
        {
            obec: 'Olešnice',
            kod: 582158,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.557654,
            longitude: 16.421795,
        },
        {
            obec: 'Olomučany',
            kod: 582166,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67903,
            latitude: 49.33097,
            longitude: 16.671791,
        },
        {
            obec: 'Olšany',
            kod: 550132,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.248136,
            longitude: 16.86616,
        },
        {
            obec: 'Olší',
            kod: 596302,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59261,
            latitude: 49.421365,
            longitude: 16.290327,
        },
        {
            obec: 'Omice',
            kod: 583545,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66441,
            latitude: 49.170033,
            longitude: 16.451834,
        },
        {
            obec: 'Onšov',
            kod: 594580,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.905385,
            longitude: 15.834328,
        },
        {
            obec: 'Opatovice',
            kod: 583553,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.075131,
            longitude: 16.641079,
        },
        {
            obec: 'Orlovice',
            kod: 593460,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.239184,
            longitude: 17.093701,
        },
        {
            obec: 'Ořechov',
            kod: 583561,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66444,
            latitude: 49.11128,
            longitude: 16.523392,
        },
        {
            obec: 'Osiky',
            kod: 582174,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.456294,
            longitude: 16.421688,
        },
        {
            obec: 'Oslavany',
            kod: 583588,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66412,
            latitude: 49.123447,
            longitude: 16.336633,
        },
        {
            obec: 'Oslnovice',
            kod: 594598,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.932188,
            longitude: 15.6875,
        },
        {
            obec: 'Ostopovice',
            kod: 583596,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66449,
            latitude: 49.161102,
            longitude: 16.54559,
        },
        {
            obec: 'Ostrov u Macochy',
            kod: 582182,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67914,
            latitude: 49.382462,
            longitude: 16.762786,
        },
        {
            obec: 'Ostrovačice',
            kod: 583600,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66481,
            latitude: 49.210924,
            longitude: 16.409562,
        },
        {
            obec: 'Ostrovánky',
            kod: 586471,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69631,
            latitude: 49.042793,
            longitude: 17.080434,
        },
        {
            obec: 'Otmarov',
            kod: 506699,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66457,
            latitude: 49.100613,
            longitude: 16.672783,
        },
        {
            obec: 'Otnice',
            kod: 593478,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68354,
            latitude: 49.086517,
            longitude: 16.814533,
        },
        {
            obec: 'Pamětice',
            kod: 582191,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.549563,
            longitude: 16.644594,
        },
        {
            obec: 'Pasohlávky',
            kod: 584762,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69122,
            latitude: 48.90306,
            longitude: 16.543748,
        },
        {
            obec: 'Pavlice',
            kod: 594601,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67156,
            latitude: 48.971577,
            longitude: 15.899056,
        },
        {
            obec: 'Pavlov',
            kod: 584771,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69201,
            latitude: 48.874256,
            longitude: 16.672359,
        },
        {
            obec: 'Perná',
            kod: 584789,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69186,
            latitude: 48.85232,
            longitude: 16.624527,
        },
        {
            obec: 'Pernštejnské Jestřabí',
            kod: 596400,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59261,
            latitude: 49.406417,
            longitude: 16.32571,
        },
        {
            obec: 'Petrov',
            kod: 582204,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.533771,
            longitude: 16.489694,
        },
        {
            obec: 'Petrov',
            kod: 586480,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69665,
            latitude: 48.882055,
            longitude: 17.278203,
        },
        {
            obec: 'Petrovice',
            kod: 582212,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.411964,
            longitude: 16.69985,
        },
        {
            obec: 'Petrovice',
            kod: 594610,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.006837,
            longitude: 16.292563,
        },
        {
            obec: 'Plaveč',
            kod: 594628,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67132,
            latitude: 48.928863,
            longitude: 16.080106,
        },
        {
            obec: 'Plenkovice',
            kod: 550051,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67151,
            latitude: 48.919012,
            longitude: 16.002555,
        },
        {
            obec: 'Podbřežice',
            kod: 550795,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.212563,
            longitude: 16.928192,
        },
        {
            obec: 'Podhradí nad Dyjí',
            kod: 594636,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.900345,
            longitude: 15.686517,
        },
        {
            obec: 'Podivice',
            kod: 593486,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68321,
            latitude: 49.365845,
            longitude: 17.010822,
        },
        {
            obec: 'Podivín',
            kod: 584797,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69145,
            latitude: 48.82564,
            longitude: 16.848324,
        },
        {
            obec: 'Podmolí',
            kod: 594644,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.850738,
            longitude: 15.940168,
        },
        {
            obec: 'Podmyče',
            kod: 594652,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.887701,
            longitude: 15.782341,
        },
        {
            obec: 'Podolí',
            kod: 583634,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66403,
            latitude: 49.190524,
            longitude: 16.72095,
        },
        {
            obec: 'Podomí',
            kod: 550175,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68304,
            latitude: 49.344818,
            longitude: 16.833319,
        },
        {
            obec: 'Pohořelice',
            kod: 584801,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69123,
            latitude: 48.981287,
            longitude: 16.524557,
        },
        {
            obec: 'Ponětovice',
            kod: 549738,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66451,
            latitude: 49.152257,
            longitude: 16.742391,
        },
        {
            obec: 'Popice',
            kod: 584819,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69127,
            latitude: 48.927358,
            longitude: 16.66701,
        },
        {
            obec: 'Popovice',
            kod: 583651,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.107197,
            longitude: 16.616203,
        },
        {
            obec: 'Popůvky',
            kod: 583669,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66441,
            latitude: 49.177467,
            longitude: 16.487676,
        },
        {
            obec: 'Pouzdřany',
            kod: 584835,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69126,
            latitude: 48.934526,
            longitude: 16.624973,
        },
        {
            obec: 'Pozořice',
            kod: 583677,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66407,
            latitude: 49.209864,
            longitude: 16.790847,
        },
        {
            obec: 'Prace',
            kod: 583685,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66458,
            latitude: 49.141161,
            longitude: 16.765498,
        },
        {
            obec: 'Práče',
            kod: 594679,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.877137,
            longitude: 16.201279,
        },
        {
            obec: 'Pravice',
            kod: 594687,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.843456,
            longitude: 16.361153,
        },
        {
            obec: 'Pravlov',
            kod: 583693,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.055779,
            longitude: 16.487786,
        },
        {
            obec: 'Prokopov',
            kod: 594695,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67154,
            latitude: 48.998438,
            longitude: 15.896085,
        },
        {
            obec: 'Prosiměřice',
            kod: 594709,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.902803,
            longitude: 16.192067,
        },
        {
            obec: 'Prostřední Poříčí',
            kod: 582221,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.572106,
            longitude: 16.497452,
        },
        {
            obec: 'Prštice',
            kod: 583707,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66446,
            latitude: 49.115118,
            longitude: 16.470866,
        },
        {
            obec: 'Prusy-Boškůvky',
            kod: 593494,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.258521,
            longitude: 17.063799,
        },
        {
            obec: 'Prušánky',
            kod: 586498,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69621,
            latitude: 48.828498,
            longitude: 16.980787,
        },
        {
            obec: 'Předklášteří',
            kod: 549746,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66602,
            latitude: 49.35263,
            longitude: 16.402512,
        },
        {
            obec: 'Přeskače',
            kod: 550078,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 49.01253,
            longitude: 16.107001,
        },
        {
            obec: 'Přibice',
            kod: 584843,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69124,
            latitude: 48.961724,
            longitude: 16.57344,
        },
        {
            obec: 'Příbram na Moravě',
            kod: 583715,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.195583,
            longitude: 16.299175,
        },
        {
            obec: 'Přibyslavice',
            kod: 583723,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.278696,
            longitude: 16.266436,
        },
        {
            obec: 'Přísnotice',
            kod: 583731,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66463,
            latitude: 49.00323,
            longitude: 16.61361,
        },
        {
            obec: 'Přítluky',
            kod: 584851,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69104,
            latitude: 48.855089,
            longitude: 16.774055,
        },
        {
            obec: 'Pustiměř',
            kod: 593508,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68321,
            latitude: 49.322614,
            longitude: 17.02803,
        },
        {
            obec: 'Račice-Pístovice',
            kod: 593516,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68305,
            latitude: 49.276006,
            longitude: 16.872942,
        },
        {
            obec: 'Radějov',
            kod: 586501,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69667,
            latitude: 48.859476,
            longitude: 17.34472,
        },
        {
            obec: 'Radostice',
            kod: 583740,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66446,
            latitude: 49.133707,
            longitude: 16.477112,
        },
        {
            obec: 'Radslavice',
            kod: 593524,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68321,
            latitude: 49.323013,
            longitude: 17.003932,
        },
        {
            obec: 'Rájec-Jestřebí',
            kod: 582239,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.410419,
            longitude: 16.640964,
        },
        {
            obec: 'Ráječko',
            kod: 582247,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.393418,
            longitude: 16.641436,
        },
        {
            obec: 'Rajhrad',
            kod: 583758,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.090311,
            longitude: 16.603982,
        },
        {
            obec: 'Rajhradice',
            kod: 583766,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.092135,
            longitude: 16.629436,
        },
        {
            obec: 'Rakvice',
            kod: 584860,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69103,
            latitude: 48.858211,
            longitude: 16.813408,
        },
        {
            obec: 'Rašov',
            kod: 582255,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.415645,
            longitude: 16.45383,
        },
        {
            obec: 'Rašovice',
            kod: 593532,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68501,
            latitude: 49.121064,
            longitude: 16.948329,
        },
        {
            obec: 'Ratíškovice',
            kod: 586510,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69602,
            latitude: 48.920114,
            longitude: 17.165713,
        },
        {
            obec: 'Rebešovice',
            kod: 583774,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66461,
            latitude: 49.105224,
            longitude: 16.635332,
        },
        {
            obec: 'Rešice',
            kod: 594725,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67173,
            latitude: 49.053466,
            longitude: 16.171572,
        },
        {
            obec: 'Rohatec',
            kod: 586528,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69601,
            latitude: 48.880497,
            longitude: 17.183408,
        },
        {
            obec: 'Rohozec',
            kod: 582263,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.390415,
            longitude: 16.482952,
        },
        {
            obec: 'Rojetín',
            kod: 596582,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.366746,
            longitude: 16.257478,
        },
        {
            obec: 'Rosice',
            kod: 583782,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66501,
            latitude: 49.18242,
            longitude: 16.387975,
        },
        {
            obec: 'Rostěnice-Zvonovice',
            kod: 554898,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.238368,
            longitude: 16.966061,
        },
        {
            obec: 'Roubanina',
            kod: 513709,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.615509,
            longitude: 16.594167,
        },
        {
            obec: 'Rousínov',
            kod: 593559,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.201381,
            longitude: 16.882252,
        },
        {
            obec: 'Rozdrojovice',
            kod: 583791,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66434,
            latitude: 49.254744,
            longitude: 16.510033,
        },
        {
            obec: 'Rozkoš',
            kod: 594733,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 49.028696,
            longitude: 15.977759,
        },
        {
            obec: 'Rozseč nad Kunštátem',
            kod: 582271,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67973,
            latitude: 49.524499,
            longitude: 16.46313,
        },
        {
            obec: 'Rozsíčka',
            kod: 582280,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.552383,
            longitude: 16.46709,
        },
        {
            obec: 'Rudice',
            kod: 582298,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.337274,
            longitude: 16.725919,
        },
        {
            obec: 'Rudka',
            kod: 583804,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.242573,
            longitude: 16.331102,
        },
        {
            obec: 'Rudlice',
            kod: 594741,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.948588,
            longitude: 16.065799,
        },
        {
            obec: 'Ruprechtov',
            kod: 593567,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68304,
            latitude: 49.32875,
            longitude: 16.851494,
        },
        {
            obec: 'Rybníček',
            kod: 553972,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.273976,
            longitude: 17.073903,
        },
        {
            obec: 'Rybníky',
            kod: 594750,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67201,
            latitude: 49.026365,
            longitude: 16.27947,
        },
        {
            obec: 'Řícmanice',
            kod: 583821,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66401,
            latitude: 49.257564,
            longitude: 16.694045,
        },
        {
            obec: 'Říčany',
            kod: 583839,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66482,
            latitude: 49.215075,
            longitude: 16.393723,
        },
        {
            obec: 'Říčky',
            kod: 549789,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66483,
            latitude: 49.233025,
            longitude: 16.355945,
        },
        {
            obec: 'Řikonín',
            kod: 596698,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.364487,
            longitude: 16.305808,
        },
        {
            obec: 'Sebranice',
            kod: 582310,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67931,
            latitude: 49.497022,
            longitude: 16.57464,
        },
        {
            obec: 'Sedlec',
            kod: 584878,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69121,
            latitude: 48.778993,
            longitude: 16.693989,
        },
        {
            obec: 'Senetářov',
            kod: 582328,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.354781,
            longitude: 16.80399,
        },
        {
            obec: 'Senorady',
            kod: 591661,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67575,
            latitude: 49.124447,
            longitude: 16.244551,
        },
        {
            obec: 'Sentice',
            kod: 583847,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66603,
            latitude: 49.310864,
            longitude: 16.446775,
        },
        {
            obec: 'Silůvky',
            kod: 583855,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66446,
            latitude: 49.106075,
            longitude: 16.46804,
        },
        {
            obec: 'Sivice',
            kod: 583863,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66407,
            latitude: 49.203807,
            longitude: 16.782647,
        },
        {
            obec: 'Skalice',
            kod: 594768,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67171,
            latitude: 48.964529,
            longitude: 16.224901,
        },
        {
            obec: 'Skalice nad Svitavou',
            kod: 582336,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.481246,
            longitude: 16.603686,
        },
        {
            obec: 'Skalička',
            kod: 545295,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66603,
            latitude: 49.357972,
            longitude: 16.516283,
        },
        {
            obec: 'Skalka',
            kod: 586536,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69648,
            latitude: 49.035877,
            longitude: 17.207332,
        },
        {
            obec: 'Skoronice',
            kod: 586544,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69641,
            latitude: 48.981428,
            longitude: 17.153534,
        },
        {
            obec: 'Skrchov',
            kod: 582344,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.57783,
            longitude: 16.549464,
        },
        {
            obec: 'Skryje',
            kod: 549894,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.393663,
            longitude: 16.310789,
        },
        {
            obec: 'Slatina',
            kod: 594776,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 49.019749,
            longitude: 16.018451,
        },
        {
            obec: 'Slavkov u Brna',
            kod: 593583,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.153354,
            longitude: 16.876598,
        },
        {
            obec: 'Sloup',
            kod: 582352,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67913,
            latitude: 49.414886,
            longitude: 16.739636,
        },
        {
            obec: 'Slup',
            kod: 594784,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.781603,
            longitude: 16.199287,
        },
        {
            obec: 'Snovídky',
            kod: 593591,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.132902,
            longitude: 17.106415,
        },
        {
            obec: 'Sobotovice',
            kod: 583880,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66467,
            latitude: 49.059999,
            longitude: 16.557396,
        },
        {
            obec: 'Sobůlky',
            kod: 586552,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69701,
            latitude: 49.01974,
            longitude: 17.079041,
        },
        {
            obec: 'Sokolnice',
            kod: 583898,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66452,
            latitude: 49.11402,
            longitude: 16.721665,
        },
        {
            obec: 'Spešov',
            kod: 556963,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.394891,
            longitude: 16.629388,
        },
        {
            obec: 'Stálky',
            kod: 594792,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.870226,
            longitude: 15.684781,
        },
        {
            obec: 'Stanoviště',
            kod: 583901,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.239197,
            longitude: 16.253595,
        },
        {
            obec: 'Starovice',
            kod: 584894,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.951684,
            longitude: 16.706715,
        },
        {
            obec: 'Starovičky',
            kod: 584908,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.907566,
            longitude: 16.775476,
        },
        {
            obec: 'Starý Petřín',
            kod: 594806,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.890137,
            longitude: 15.734188,
        },
        {
            obec: 'Starý Poddvorov',
            kod: 586561,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69616,
            latitude: 48.878704,
            longitude: 16.981633,
        },
        {
            obec: 'Stavěšice',
            kod: 586579,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69638,
            latitude: 49.001739,
            longitude: 17.030565,
        },
        {
            obec: 'Stošíkovice na Louce',
            kod: 594814,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.896689,
            longitude: 16.214975,
        },
        {
            obec: 'Strachotice',
            kod: 594822,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67129,
            latitude: 48.796236,
            longitude: 16.1724,
        },
        {
            obec: 'Strachotín',
            kod: 584916,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69301,
            latitude: 48.905517,
            longitude: 16.651453,
        },
        {
            obec: 'Strážnice',
            kod: 586587,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69662,
            latitude: 48.901117,
            longitude: 17.31691,
        },
        {
            obec: 'Strážovice',
            kod: 586595,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69638,
            latitude: 49.009307,
            longitude: 17.047511,
        },
        {
            obec: 'Strhaře',
            kod: 582379,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.435714,
            longitude: 16.436401,
        },
        {
            obec: 'Střelice',
            kod: 583910,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66447,
            latitude: 49.152312,
            longitude: 16.50409,
        },
        {
            obec: 'Střelice',
            kod: 594831,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.994181,
            longitude: 15.983267,
        },
        {
            obec: 'Studnice',
            kod: 593605,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68308,
            latitude: 49.375694,
            longitude: 16.881134,
        },
        {
            obec: 'Stvolová',
            kod: 582620,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67961,
            latitude: 49.589318,
            longitude: 16.540541,
        },
        {
            obec: 'Sudice',
            kod: 582395,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.53,
            longitude: 16.668628,
        },
        {
            obec: 'Sudoměřice',
            kod: 586609,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69666,
            latitude: 48.867328,
            longitude: 17.256869,
        },
        {
            obec: 'Suchohrdly',
            kod: 555231,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.866218,
            longitude: 16.089121,
        },
        {
            obec: 'Suchohrdly u Miroslavi',
            kod: 594849,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67172,
            latitude: 48.943156,
            longitude: 16.363302,
        },
        {
            obec: 'Suchov',
            kod: 586617,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69671,
            latitude: 48.907522,
            longitude: 17.563562,
        },
        {
            obec: 'Suchý',
            kod: 582409,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.482895,
            longitude: 16.762483,
        },
        {
            obec: 'Sulíkov',
            kod: 582417,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.544691,
            longitude: 16.489716,
        },
        {
            obec: 'Svatobořice-Mistřín',
            kod: 586625,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69604,
            latitude: 48.977127,
            longitude: 17.089396,
        },
        {
            obec: 'Svatoslav',
            kod: 583928,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.30187,
            longitude: 16.308896,
        },
        {
            obec: 'Světlá',
            kod: 586064,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67963,
            latitude: 49.565958,
            longitude: 16.718297,
        },
        {
            obec: 'Svinošice',
            kod: 582433,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.334534,
            longitude: 16.576,
        },
        {
            obec: 'Svitávka',
            kod: 582441,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67932,
            latitude: 49.501876,
            longitude: 16.598037,
        },
        {
            obec: 'Synalov',
            kod: 582450,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.432831,
            longitude: 16.413973,
        },
        {
            obec: 'Syrovice',
            kod: 583936,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66467,
            latitude: 49.079913,
            longitude: 16.546565,
        },
        {
            obec: 'Syrovín',
            kod: 586633,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69684,
            latitude: 49.025923,
            longitude: 17.264039,
        },
        {
            obec: 'Šafov',
            kod: 594865,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67106,
            latitude: 48.866906,
            longitude: 15.734756,
        },
        {
            obec: 'Šakvice',
            kod: 584924,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69167,
            latitude: 48.897558,
            longitude: 16.714344,
        },
        {
            obec: 'Šanov',
            kod: 594873,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67168,
            latitude: 48.800986,
            longitude: 16.378684,
        },
        {
            obec: 'Šaratice',
            kod: 593613,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68352,
            latitude: 49.117564,
            longitude: 16.803613,
        },
        {
            obec: 'Šardice',
            kod: 586641,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69613,
            latitude: 48.964128,
            longitude: 17.028222,
        },
        {
            obec: 'Šatov',
            kod: 594881,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67122,
            latitude: 48.793274,
            longitude: 16.010024,
        },
        {
            obec: 'Šebetov',
            kod: 582468,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67935,
            latitude: 49.548719,
            longitude: 16.711728,
        },
        {
            obec: 'Šebrov-Kateřina',
            kod: 582476,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.328598,
            longitude: 16.602548,
        },
        {
            obec: 'Šerkovice',
            kod: 583944,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.38568,
            longitude: 16.430063,
        },
        {
            obec: 'Šitbořice',
            kod: 584932,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69176,
            latitude: 49.014427,
            longitude: 16.77986,
        },
        {
            obec: 'Šlapanice',
            kod: 583952,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66451,
            latitude: 49.168729,
            longitude: 16.727418,
        },
        {
            obec: 'Šošůvka',
            kod: 582484,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67913,
            latitude: 49.410633,
            longitude: 16.751985,
        },
        {
            obec: 'Štěchov',
            kod: 582492,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.452359,
            longitude: 16.506182,
        },
        {
            obec: 'Štěpánovice',
            kod: 583961,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66602,
            latitude: 49.373052,
            longitude: 16.387683,
        },
        {
            obec: 'Štítary',
            kod: 594890,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.935202,
            longitude: 15.844388,
        },
        {
            obec: 'Šumice',
            kod: 594903,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67175,
            latitude: 48.9922,
            longitude: 16.437469,
        },
        {
            obec: 'Šumná',
            kod: 594911,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.922527,
            longitude: 15.871054,
        },
        {
            obec: 'Švábenice',
            kod: 593621,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68323,
            latitude: 49.278501,
            longitude: 17.123499,
        },
        {
            obec: 'Tasov',
            kod: 586650,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69663,
            latitude: 48.906964,
            longitude: 17.429767,
        },
        {
            obec: 'Tasovice',
            kod: 582506,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.496043,
            longitude: 16.441633,
        },
        {
            obec: 'Tasovice',
            kod: 594920,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67125,
            latitude: 48.836196,
            longitude: 16.155687,
        },
        {
            obec: 'Tavíkovice',
            kod: 594938,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 49.033846,
            longitude: 16.103265,
        },
        {
            obec: 'Telnice',
            kod: 583979,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66459,
            latitude: 49.101969,
            longitude: 16.71785,
        },
        {
            obec: 'Těmice',
            kod: 586668,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69684,
            latitude: 49.00165,
            longitude: 17.265026,
        },
        {
            obec: 'Terezín',
            kod: 586676,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69614,
            latitude: 48.955636,
            longitude: 16.942659,
        },
        {
            obec: 'Těšany',
            kod: 583995,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66454,
            latitude: 49.039658,
            longitude: 16.770132,
        },
        {
            obec: 'Těšetice',
            kod: 594946,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.888944,
            longitude: 16.158304,
        },
        {
            obec: 'Tetčice',
            kod: 583987,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66417,
            latitude: 49.170348,
            longitude: 16.405736,
        },
        {
            obec: 'Tišnov',
            kod: 584002,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.348818,
            longitude: 16.424485,
        },
        {
            obec: 'Tišnovská Nová Ves',
            kod: 596892,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.383986,
            longitude: 16.293684,
        },
        {
            obec: 'Topolany',
            kod: 593630,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.277451,
            longitude: 17.040411,
        },
        {
            obec: 'Trboušany',
            kod: 584011,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66464,
            latitude: 49.050783,
            longitude: 16.46295,
        },
        {
            obec: 'Trnové Pole',
            kod: 594954,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.94504,
            longitude: 16.409578,
        },
        {
            obec: 'Troskotovice',
            kod: 594962,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67178,
            latitude: 48.919119,
            longitude: 16.437643,
        },
        {
            obec: 'Troubsko',
            kod: 584029,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66441,
            latitude: 49.169608,
            longitude: 16.510886,
        },
        {
            obec: 'Trstěnice',
            kod: 594971,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67171,
            latitude: 48.986862,
            longitude: 16.195522,
        },
        {
            obec: 'Tučapy',
            kod: 593648,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.233446,
            longitude: 16.918474,
        },
        {
            obec: 'Tulešice',
            kod: 594989,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67173,
            latitude: 49.038646,
            longitude: 16.207235,
        },
        {
            obec: 'Tvarožná',
            kod: 584037,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66405,
            latitude: 49.19187,
            longitude: 16.771563,
        },
        {
            obec: 'Tvarožná Lhota',
            kod: 586684,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69662,
            latitude: 48.877349,
            longitude: 17.359497,
        },
        {
            obec: 'Tvořihráz',
            kod: 594997,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67134,
            latitude: 48.917649,
            longitude: 16.135878,
        },
        {
            obec: 'Tvrdonice',
            kod: 584941,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69153,
            latitude: 48.760582,
            longitude: 16.994567,
        },
        {
            obec: 'Týnec',
            kod: 584959,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69154,
            latitude: 48.779531,
            longitude: 17.013324,
        },
        {
            obec: 'Uherčice',
            kod: 584967,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69162,
            latitude: 48.967902,
            longitude: 16.653546,
        },
        {
            obec: 'Uherčice',
            kod: 595004,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.913336,
            longitude: 15.630477,
        },
        {
            obec: 'Uhřice',
            kod: 582531,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67963,
            latitude: 49.595164,
            longitude: 16.735111,
        },
        {
            obec: 'Uhřice',
            kod: 586692,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69634,
            latitude: 49.050447,
            longitude: 16.947682,
        },
        {
            obec: 'Uhřice',
            kod: 550191,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68333,
            latitude: 49.172448,
            longitude: 17.078762,
        },
        {
            obec: 'Újezd',
            kod: 595012,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67140,
            latitude: 49.023647,
            longitude: 16.053361,
        },
        {
            obec: 'Újezd u Boskovic',
            kod: 534692,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.463998,
            longitude: 16.654269,
        },
        {
            obec: 'Újezd u Brna',
            kod: 584045,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66453,
            latitude: 49.104448,
            longitude: 16.757474,
        },
        {
            obec: 'Újezd u Černé Hory',
            kod: 582557,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.370995,
            longitude: 16.543917,
        },
        {
            obec: 'Újezd u Rosic',
            kod: 584053,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.222512,
            longitude: 16.253748,
        },
        {
            obec: 'Újezd u Tišnova',
            kod: 549908,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59455,
            latitude: 49.365622,
            longitude: 16.32465,
        },
        {
            obec: 'Únanov',
            kod: 595021,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67131,
            latitude: 48.900923,
            longitude: 16.063609,
        },
        {
            obec: 'Unín',
            kod: 582565,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67924,
            latitude: 49.382277,
            longitude: 16.491456,
        },
        {
            obec: 'Unkovice',
            kod: 584061,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66463,
            latitude: 49.019318,
            longitude: 16.604146,
        },
        {
            obec: 'Úsobrno',
            kod: 582573,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67939,
            latitude: 49.589023,
            longitude: 16.762988,
        },
        {
            obec: 'Ústup',
            kod: 553883,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67974,
            latitude: 49.565027,
            longitude: 16.463018,
        },
        {
            obec: 'Úsuší',
            kod: 584070,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.334845,
            longitude: 16.356089,
        },
        {
            obec: 'Vacenovice',
            kod: 586706,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69606,
            latitude: 48.945204,
            longitude: 17.174146,
        },
        {
            obec: 'Valchov',
            kod: 582581,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.470283,
            longitude: 16.71998,
        },
        {
            obec: 'Valtice',
            kod: 584975,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69142,
            latitude: 48.74079,
            longitude: 16.755094,
        },
        {
            obec: 'Valtrovice',
            kod: 595039,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.793826,
            longitude: 16.221077,
        },
        {
            obec: 'Vanovice',
            kod: 582590,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67936,
            latitude: 49.567378,
            longitude: 16.666313,
        },
        {
            obec: 'Vavřinec',
            kod: 582603,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67913,
            latitude: 49.402702,
            longitude: 16.719949,
        },
        {
            obec: 'Vážany',
            kod: 582611,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.53129,
            longitude: 16.688799,
        },
        {
            obec: 'Vážany',
            kod: 593656,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.244453,
            longitude: 17.050332,
        },
        {
            obec: 'Vážany nad Litavou',
            kod: 593664,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68401,
            latitude: 49.128919,
            longitude: 16.857178,
        },
        {
            obec: 'Vedrovice',
            kod: 595047,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67175,
            latitude: 49.020997,
            longitude: 16.378598,
        },
        {
            obec: 'Velatice',
            kod: 584096,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66405,
            latitude: 49.197473,
            longitude: 16.753684,
        },
        {
            obec: 'Velenov',
            kod: 530824,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68001,
            latitude: 49.48694,
            longitude: 16.73287,
        },
        {
            obec: 'Velešovice',
            kod: 593681,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68301,
            latitude: 49.179261,
            longitude: 16.849241,
        },
        {
            obec: 'Velká nad Veličkou',
            kod: 586714,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69674,
            latitude: 48.882667,
            longitude: 17.520706,
        },
        {
            obec: 'Velké Bílovice',
            kod: 584983,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69102,
            latitude: 48.849388,
            longitude: 16.892379,
        },
        {
            obec: 'Velké Hostěrádky',
            kod: 584991,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69174,
            latitude: 49.033238,
            longitude: 16.870336,
        },
        {
            obec: 'Velké Němčice',
            kod: 585009,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69163,
            latitude: 48.991777,
            longitude: 16.672186,
        },
        {
            obec: 'Velké Opatovice',
            kod: 582646,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67963,
            latitude: 49.612471,
            longitude: 16.679574,
        },
        {
            obec: 'Velké Pavlovice',
            kod: 585017,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69106,
            latitude: 48.904792,
            longitude: 16.816155,
        },
        {
            obec: 'Velký Karlov',
            kod: 545325,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67128,
            latitude: 48.804633,
            longitude: 16.305563,
        },
        {
            obec: 'Vémyslice',
            kod: 595055,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67142,
            latitude: 49.022141,
            longitude: 16.256897,
        },
        {
            obec: 'Veselí nad Moravou',
            kod: 586722,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69801,
            latitude: 48.952477,
            longitude: 17.381015,
        },
        {
            obec: 'Věteřov',
            kod: 586731,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69701,
            latitude: 49.028625,
            longitude: 17.056248,
        },
        {
            obec: 'Vevčice',
            kod: 595063,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67153,
            latitude: 48.962305,
            longitude: 16.044533,
        },
        {
            obec: 'Veverská Bítýška',
            kod: 584100,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66471,
            latitude: 49.276013,
            longitude: 16.436969,
        },
        {
            obec: 'Veverské Knínice',
            kod: 584118,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66481,
            latitude: 49.236997,
            longitude: 16.401931,
        },
        {
            obec: 'Vilémovice',
            kod: 582654,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67906,
            latitude: 49.363886,
            longitude: 16.746834,
        },
        {
            obec: 'Viničné Šumice',
            kod: 584126,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66406,
            latitude: 49.213678,
            longitude: 16.825512,
        },
        {
            obec: 'Vísky',
            kod: 582662,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67933,
            latitude: 49.538535,
            longitude: 16.625711,
        },
        {
            obec: 'Višňové',
            kod: 595071,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67138,
            latitude: 48.982515,
            longitude: 16.150357,
        },
        {
            obec: 'Vítonice',
            kod: 595080,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67161,
            latitude: 48.919467,
            longitude: 16.198985,
        },
        {
            obec: 'Vlasatice',
            kod: 585025,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69130,
            latitude: 48.933846,
            longitude: 16.484529,
        },
        {
            obec: 'Vlkoš',
            kod: 586749,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69641,
            latitude: 48.989701,
            longitude: 17.163666,
        },
        {
            obec: 'Vnorovy',
            kod: 586757,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69661,
            latitude: 48.931064,
            longitude: 17.350609,
        },
        {
            obec: 'Voděrady',
            kod: 582671,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67901,
            latitude: 49.481598,
            longitude: 16.557996,
        },
        {
            obec: 'Vohančice',
            kod: 584134,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.321692,
            longitude: 16.39396,
        },
        {
            obec: 'Vojkovice',
            kod: 584142,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66701,
            latitude: 49.05148,
            longitude: 16.608308,
        },
        {
            obec: 'Vracov',
            kod: 586765,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69642,
            latitude: 48.975328,
            longitude: 17.211104,
        },
        {
            obec: 'Vracovice',
            kod: 550019,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.902225,
            longitude: 15.898792,
        },
        {
            obec: 'Vranov',
            kod: 584151,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66432,
            latitude: 49.309357,
            longitude: 16.613878,
        },
        {
            obec: 'Vranov nad Dyjí',
            kod: 595098,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67103,
            latitude: 48.894799,
            longitude: 15.812799,
        },
        {
            obec: 'Vranová',
            kod: 582689,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67962,
            latitude: 49.553154,
            longitude: 16.525131,
        },
        {
            obec: 'Vranovice',
            kod: 585033,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69125,
            latitude: 48.966097,
            longitude: 16.606707,
        },
        {
            obec: 'Vranovská Ves',
            kod: 595101,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67151,
            latitude: 48.951592,
            longitude: 15.918586,
        },
        {
            obec: 'Vratěnín',
            kod: 595110,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.904186,
            longitude: 15.595278,
        },
        {
            obec: 'Vratislávka',
            kod: 597104,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59451,
            latitude: 49.386513,
            longitude: 16.244137,
        },
        {
            obec: 'Vrbice',
            kod: 585041,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69109,
            latitude: 48.915184,
            longitude: 16.897893,
        },
        {
            obec: 'Vrbovec',
            kod: 595128,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67124,
            latitude: 48.799878,
            longitude: 16.100716,
        },
        {
            obec: 'Vřesovice',
            kod: 586773,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69648,
            latitude: 49.059182,
            longitude: 17.215114,
        },
        {
            obec: 'Všechovice',
            kod: 584169,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66603,
            latitude: 49.357215,
            longitude: 16.494182,
        },
        {
            obec: 'Výrovice',
            kod: 595136,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67134,
            latitude: 48.928356,
            longitude: 16.120391,
        },
        {
            obec: 'Vysočany',
            kod: 582701,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67913,
            latitude: 49.432008,
            longitude: 16.811126,
        },
        {
            obec: 'Vysočany',
            kod: 595144,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.941156,
            longitude: 15.689792,
        },
        {
            obec: 'Vysoké Popovice',
            kod: 584177,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.183126,
            longitude: 16.284964,
        },
        {
            obec: 'Vyškov',
            kod: 592889,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68201,
            latitude: 49.277552,
            longitude: 16.996199,
        },
        {
            obec: 'Zaječí',
            kod: 585050,
            okres: 'Břeclav',
            kod_okres: 'CZ0644',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69105,
            latitude: 48.87305,
            longitude: 16.766566,
        },
        {
            obec: 'Zakřany',
            kod: 584185,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.170641,
            longitude: 16.32536,
        },
        {
            obec: 'Zálesí',
            kod: 595152,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67102,
            latitude: 48.954232,
            longitude: 15.782156,
        },
        {
            obec: 'Zálesná Zhoř',
            kod: 584193,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.250286,
            longitude: 16.28985,
        },
        {
            obec: 'Zastávka',
            kod: 584207,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.188096,
            longitude: 16.363201,
        },
        {
            obec: 'Závist',
            kod: 586005,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67922,
            latitude: 49.375272,
            longitude: 16.57215,
        },
        {
            obec: 'Zblovice',
            kod: 595161,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67107,
            latitude: 48.957838,
            longitude: 15.70531,
        },
        {
            obec: 'Zbraslav',
            kod: 584215,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66484,
            latitude: 49.221653,
            longitude: 16.294251,
        },
        {
            obec: 'Zbraslavec',
            kod: 582727,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67972,
            latitude: 49.483999,
            longitude: 16.528495,
        },
        {
            obec: 'Zbýšov',
            kod: 584223,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66411,
            latitude: 49.155344,
            longitude: 16.349616,
        },
        {
            obec: 'Zbýšov',
            kod: 593699,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68352,
            latitude: 49.131422,
            longitude: 16.80578,
        },
        {
            obec: 'Zelená Hora',
            kod: 593702,
            okres: 'Vyškov',
            kod_okres: 'CZ0646',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 68321,
            latitude: 49.329083,
            longitude: 17.01382,
        },
        {
            obec: 'Zhoř',
            kod: 582735,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67923,
            latitude: 49.414782,
            longitude: 16.47899,
        },
        {
            obec: 'Znojmo',
            kod: 593711,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66902,
            latitude: 48.856011,
            longitude: 16.054368,
        },
        {
            obec: 'Žabčice',
            kod: 584231,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66463,
            latitude: 49.011697,
            longitude: 16.602677,
        },
        {
            obec: 'Žádovice',
            kod: 586781,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69649,
            latitude: 49.013463,
            longitude: 17.197031,
        },
        {
            obec: 'Žarošice',
            kod: 586790,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69634,
            latitude: 49.040748,
            longitude: 16.967239,
        },
        {
            obec: 'Žatčany',
            kod: 584240,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66453,
            latitude: 49.087986,
            longitude: 16.733849,
        },
        {
            obec: 'Ždánice',
            kod: 586803,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69632,
            latitude: 49.067387,
            longitude: 17.027619,
        },
        {
            obec: 'Žďár',
            kod: 582743,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67902,
            latitude: 49.421675,
            longitude: 16.697884,
        },
        {
            obec: 'Žďárec',
            kod: 597171,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 59456,
            latitude: 49.379867,
            longitude: 16.266562,
        },
        {
            obec: 'Žďárná',
            kod: 582751,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67952,
            latitude: 49.468738,
            longitude: 16.758612,
        },
        {
            obec: 'Želešice',
            kod: 584266,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66443,
            latitude: 49.116986,
            longitude: 16.581479,
        },
        {
            obec: 'Želetice',
            kod: 586811,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69637,
            latitude: 49.014333,
            longitude: 17.008221,
        },
        {
            obec: 'Želetice',
            kod: 595179,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67134,
            latitude: 48.936428,
            longitude: 16.182913,
        },
        {
            obec: 'Železné',
            kod: 584274,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66601,
            latitude: 49.359718,
            longitude: 16.45055,
        },
        {
            obec: 'Žeravice',
            kod: 586820,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69647,
            latitude: 49.022984,
            longitude: 17.23737,
        },
        {
            obec: 'Žeraviny',
            kod: 586838,
            okres: 'Hodonín',
            kod_okres: 'CZ0645',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 69663,
            latitude: 48.908328,
            longitude: 17.391624,
        },
        {
            obec: 'Žernovník',
            kod: 582760,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67921,
            latitude: 49.407034,
            longitude: 16.545573,
        },
        {
            obec: 'Žerotice',
            kod: 595187,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67134,
            latitude: 48.92681,
            longitude: 16.168847,
        },
        {
            obec: 'Žerůtky',
            kod: 582778,
            okres: 'Blansko',
            kod_okres: 'CZ0641',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67971,
            latitude: 49.440351,
            longitude: 16.536844,
        },
        {
            obec: 'Žerůtky',
            kod: 595195,
            okres: 'Znojmo',
            kod_okres: 'CZ0647',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 67151,
            latitude: 48.906004,
            longitude: 15.963194,
        },
        {
            obec: 'Židlochovice',
            kod: 584282,
            okres: 'Brno-venkov',
            kod_okres: 'CZ0643',
            kraj: 'Jihomoravský kraj',
            kod_kraj: 'CZ064',
            psc: 66701,
            latitude: 49.039624,
            longitude: 16.618911,
        },
    ],
    CZ020: [
        {
            obec: 'Adamov',
            kod: 531367,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.857917,
            longitude: 15.409018,
        },
        {
            obec: 'Babice',
            kod: 538043,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 50.008089,
            longitude: 14.715907,
        },
        {
            obec: 'Bakov nad Jizerou',
            kod: 535427,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29401,
            latitude: 50.482406,
            longitude: 14.941596,
        },
        {
            obec: 'Barchovice',
            kod: 533173,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.944355,
            longitude: 14.969206,
        },
        {
            obec: 'Bašť',
            kod: 538051,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25065,
            latitude: 50.205242,
            longitude: 14.477405,
        },
        {
            obec: 'Bavoryně',
            kod: 534421,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.895907,
            longitude: 13.961126,
        },
        {
            obec: 'Bdín',
            kod: 565423,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.214245,
            longitude: 13.858851,
        },
        {
            obec: 'Bečváry',
            kod: 533181,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28143,
            latitude: 49.956809,
            longitude: 15.079916,
        },
        {
            obec: 'Bělá pod Bezdězem',
            kod: 535443,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29421,
            latitude: 50.501314,
            longitude: 14.80429,
        },
        {
            obec: 'Běleč',
            kod: 535010,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27363,
            latitude: 50.056269,
            longitude: 13.993082,
        },
        {
            obec: 'Běloky',
            kod: 532070,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27353,
            latitude: 50.131539,
            longitude: 14.220862,
        },
        {
            obec: 'Bělušice',
            kod: 533190,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.069172,
            longitude: 15.322001,
        },
        {
            obec: 'Benátky nad Jizerou',
            kod: 535451,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29471,
            latitude: 50.289261,
            longitude: 14.824612,
        },
        {
            obec: 'Benešov',
            kod: 529303,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.783882,
            longitude: 14.68747,
        },
        {
            obec: 'Bernardov',
            kod: 531111,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 50.014231,
            longitude: 15.398668,
        },
        {
            obec: 'Bernartice',
            kod: 532568,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.675666,
            longitude: 15.129249,
        },
        {
            obec: 'Beroun',
            kod: 531057,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 49.967305,
            longitude: 14.086384,
        },
        {
            obec: 'Běrunice',
            kod: 537021,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28908,
            latitude: 50.185843,
            longitude: 15.333694,
        },
        {
            obec: 'Beřovice',
            kod: 532088,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27371,
            latitude: 50.269119,
            longitude: 14.12366,
        },
        {
            obec: 'Běštín',
            kod: 531073,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.807406,
            longitude: 14.01634,
        },
        {
            obec: 'Bezděkov pod Třemšínem',
            kod: 529672,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.577203,
            longitude: 13.878564,
        },
        {
            obec: 'Bezno',
            kod: 535478,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29429,
            latitude: 50.367388,
            longitude: 14.795726,
        },
        {
            obec: 'Bílá Hlína',
            kod: 565750,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.532604,
            longitude: 14.925357,
        },
        {
            obec: 'Bílé Podolí',
            kod: 533971,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28572,
            latitude: 49.956586,
            longitude: 15.491127,
        },
        {
            obec: 'Bílichov',
            kod: 535125,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.262151,
            longitude: 13.916459,
        },
        {
            obec: 'Bílkovice',
            kod: 530743,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.759822,
            longitude: 14.861056,
        },
        {
            obec: 'Bítouchov',
            kod: 535486,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29401,
            latitude: 50.475882,
            longitude: 14.889523,
        },
        {
            obec: 'Blažejovice',
            kod: 532380,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.619897,
            longitude: 15.200036,
        },
        {
            obec: 'Blevice',
            kod: 532100,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27328,
            latitude: 50.209825,
            longitude: 14.236267,
        },
        {
            obec: 'Bludov',
            kod: 530964,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.807592,
            longitude: 15.255286,
        },
        {
            obec: 'Bobnice',
            kod: 537039,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28931,
            latitude: 50.219681,
            longitude: 15.053622,
        },
        {
            obec: 'Bohdaneč',
            kod: 533980,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28525,
            latitude: 49.778273,
            longitude: 15.222656,
        },
        {
            obec: 'Bohostice',
            kod: 564559,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.602243,
            longitude: 14.138233,
        },
        {
            obec: 'Bohutín',
            kod: 539953,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26241,
            latitude: 49.65562,
            longitude: 13.943995,
        },
        {
            obec: 'Bojanovice',
            kod: 539104,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25206,
            latitude: 49.854542,
            longitude: 14.352145,
        },
        {
            obec: 'Boreč',
            kod: 535508,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29426,
            latitude: 50.407453,
            longitude: 14.732498,
        },
        {
            obec: 'Borek',
            kod: 534684,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.222684,
            longitude: 14.647521,
        },
        {
            obec: 'Borotice',
            kod: 539970,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26215,
            latitude: 49.735072,
            longitude: 14.279808,
        },
        {
            obec: 'Borovnice',
            kod: 532096,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.648835,
            longitude: 15.018438,
        },
        {
            obec: 'Bořanovice',
            kod: 538086,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25065,
            latitude: 50.178336,
            longitude: 14.478857,
        },
        {
            obec: 'Boseň',
            kod: 535516,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.504204,
            longitude: 15.022906,
        },
        {
            obec: 'Bradlec',
            kod: 570788,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29306,
            latitude: 50.451452,
            longitude: 14.910293,
        },
        {
            obec: 'Brambory',
            kod: 530832,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.97825,
            longitude: 15.470316,
        },
        {
            obec: 'Brandýs nad Labem - Stará Boleslav',
            kod: 538094,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25001,
            latitude: 50.186426,
            longitude: 14.659344,
        },
        {
            obec: 'Brandýsek',
            kod: 532118,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27341,
            latitude: 50.18933,
            longitude: 14.162098,
        },
        {
            obec: 'Branov',
            kod: 541672,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.012981,
            longitude: 13.843021,
        },
        {
            obec: 'Branžež',
            kod: 571946,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29402,
            latitude: 50.507685,
            longitude: 15.058005,
        },
        {
            obec: 'Braškov',
            kod: 532126,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.102133,
            longitude: 14.100859,
        },
        {
            obec: 'Bratčice',
            kod: 531286,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.855317,
            longitude: 15.430482,
        },
        {
            obec: 'Bratkovice',
            kod: 539988,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.740519,
            longitude: 13.999043,
        },
        {
            obec: 'Bratronice',
            kod: 532142,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27363,
            latitude: 50.067851,
            longitude: 14.014265,
        },
        {
            obec: 'Bratřínov',
            kod: 571199,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25205,
            latitude: 49.842681,
            longitude: 14.340242,
        },
        {
            obec: 'Brázdim',
            kod: 538108,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.183633,
            longitude: 14.58829,
        },
        {
            obec: 'Brdy (vojenský újezd)',
            kod: 539996,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.789268,
            longitude: 13.903243,
        },
        {
            obec: 'Brodce',
            kod: 535559,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29473,
            latitude: 50.33078,
            longitude: 14.86928,
        },
        {
            obec: 'Broumy',
            kod: 531081,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26742,
            latitude: 49.955171,
            longitude: 13.852368,
        },
        {
            obec: 'Březí',
            kod: 564869,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 50.014766,
            longitude: 14.699389,
        },
        {
            obec: 'Březina',
            kod: 535567,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29411,
            latitude: 50.548647,
            longitude: 15.032901,
        },
        {
            obec: 'Březnice',
            kod: 540013,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.557739,
            longitude: 13.950734,
        },
        {
            obec: 'Březno',
            kod: 535583,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.406292,
            longitude: 15.005584,
        },
        {
            obec: 'Březová',
            kod: 531090,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.904184,
            longitude: 13.883116,
        },
        {
            obec: 'Březová-Oleško',
            kod: 599735,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25245,
            latitude: 49.904148,
            longitude: 14.411028,
        },
        {
            obec: 'Březovice',
            kod: 599514,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29424,
            latitude: 50.470818,
            longitude: 14.733711,
        },
        {
            obec: 'Břežany',
            kod: 565041,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27034,
            latitude: 50.00696,
            longitude: 13.582896,
        },
        {
            obec: 'Břežany I',
            kod: 533211,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.035026,
            longitude: 15.079055,
        },
        {
            obec: 'Břežany II',
            kod: 533220,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.093862,
            longitude: 14.804403,
        },
        {
            obec: 'Bříství',
            kod: 537047,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28915,
            latitude: 50.134028,
            longitude: 14.841845,
        },
        {
            obec: 'Bubovice',
            kod: 531103,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.969761,
            longitude: 14.166601,
        },
        {
            obec: 'Budiměřice',
            kod: 537055,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.195426,
            longitude: 15.098869,
        },
        {
            obec: 'Buková u Příbramě',
            kod: 540021,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.755238,
            longitude: 14.066683,
        },
        {
            obec: 'Bukovany',
            kod: 532924,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25741,
            latitude: 49.822898,
            longitude: 14.624388,
        },
        {
            obec: 'Bukovany',
            kod: 564664,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.574617,
            longitude: 14.10264,
        },
        {
            obec: 'Bukovno',
            kod: 535605,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.446863,
            longitude: 14.840687,
        },
        {
            obec: 'Buš',
            kod: 540048,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25208,
            latitude: 49.80341,
            longitude: 14.384043,
        },
        {
            obec: 'Buštěhrad',
            kod: 532169,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27343,
            latitude: 50.156043,
            longitude: 14.189083,
        },
        {
            obec: 'Býchory',
            kod: 533238,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.067727,
            longitude: 15.273572,
        },
        {
            obec: 'Býkev',
            kod: 534714,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27601,
            latitude: 50.347271,
            longitude: 14.418129,
        },
        {
            obec: 'Bykoš',
            kod: 534145,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26701,
            latitude: 49.883057,
            longitude: 14.063765,
        },
        {
            obec: 'Bystřice',
            kod: 529451,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25751,
            latitude: 49.732235,
            longitude: 14.667506,
        },
        {
            obec: 'Byšice',
            kod: 534722,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27732,
            latitude: 50.310544,
            longitude: 14.61147,
        },
        {
            obec: 'Bzová',
            kod: 531120,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26743,
            latitude: 49.897991,
            longitude: 13.862507,
        },
        {
            obec: 'Cerhenice',
            kod: 533246,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28102,
            latitude: 50.071376,
            longitude: 15.07214,
        },
        {
            obec: 'Cerhovice',
            kod: 531138,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26761,
            latitude: 49.849643,
            longitude: 13.834934,
        },
        {
            obec: 'Cetyně',
            kod: 564419,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.599728,
            longitude: 14.121455,
        },
        {
            obec: 'Církvice',
            kod: 599476,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28144,
            latitude: 49.909456,
            longitude: 15.015818,
        },
        {
            obec: 'Církvice',
            kod: 533998,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28533,
            latitude: 49.945703,
            longitude: 15.335153,
        },
        {
            obec: 'Cítov',
            kod: 534731,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27704,
            latitude: 50.372354,
            longitude: 14.398243,
        },
        {
            obec: 'Ctiboř',
            kod: 532690,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.736767,
            longitude: 14.904302,
        },
        {
            obec: 'Ctiměřice',
            kod: 570991,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29446,
            latitude: 50.377891,
            longitude: 14.993465,
        },
        {
            obec: 'Cvrčovice',
            kod: 532185,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27341,
            latitude: 50.179896,
            longitude: 14.154895,
        },
        {
            obec: 'Čachovice',
            kod: 535621,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29443,
            latitude: 50.27557,
            longitude: 14.946032,
        },
        {
            obec: 'Čakov',
            kod: 529478,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25724,
            latitude: 49.825696,
            longitude: 14.836436,
        },
        {
            obec: 'Čakovičky',
            kod: 598291,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.231701,
            longitude: 14.531487,
        },
        {
            obec: 'Čáslav',
            kod: 534005,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.910995,
            longitude: 15.390894,
        },
        {
            obec: 'Čečelice',
            kod: 534749,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27732,
            latitude: 50.294103,
            longitude: 14.618687,
        },
        {
            obec: 'Čechtice',
            kod: 529486,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.624128,
            longitude: 15.048346,
        },
        {
            obec: 'Čejkovice',
            kod: 529524,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.791923,
            longitude: 15.349767,
        },
        {
            obec: 'Čelákovice',
            kod: 538132,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25088,
            latitude: 50.162803,
            longitude: 14.751089,
        },
        {
            obec: 'Čenkov',
            kod: 540072,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.777803,
            longitude: 14.00086,
        },
        {
            obec: 'Čerčany',
            kod: 529516,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25722,
            latitude: 49.853032,
            longitude: 14.703093,
        },
        {
            obec: 'Černé Voděrady',
            kod: 533254,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.941659,
            longitude: 14.80689,
        },
        {
            obec: 'Černíky',
            kod: 599301,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28915,
            latitude: 50.102118,
            longitude: 14.820104,
        },
        {
            obec: 'Černíny',
            kod: 534013,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.840116,
            longitude: 15.218931,
        },
        {
            obec: 'Černolice',
            kod: 539121,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.90979,
            longitude: 14.299358,
        },
        {
            obec: 'Černošice',
            kod: 539139,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25228,
            latitude: 49.960184,
            longitude: 14.319902,
        },
        {
            obec: 'Černuc',
            kod: 532207,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27323,
            latitude: 50.301245,
            longitude: 14.202603,
        },
        {
            obec: 'Červené Janovice',
            kod: 534021,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28542,
            latitude: 49.834712,
            longitude: 15.253438,
        },
        {
            obec: 'Červené Pečky',
            kod: 533262,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28121,
            latitude: 49.978318,
            longitude: 15.208666,
        },
        {
            obec: 'Červený Újezd',
            kod: 529532,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25788,
            latitude: 49.555506,
            longitude: 14.604165,
        },
        {
            obec: 'Červený Újezd',
            kod: 532215,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.069809,
            longitude: 14.166017,
        },
        {
            obec: 'Český Brod',
            kod: 533271,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.074304,
            longitude: 14.860919,
        },
        {
            obec: 'Český Šternberk',
            kod: 529541,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.810991,
            longitude: 14.928256,
        },
        {
            obec: 'Čestín',
            kod: 534030,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28510,
            latitude: 49.807713,
            longitude: 15.104503,
        },
        {
            obec: 'Čestlice',
            kod: 538141,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 50.002617,
            longitude: 14.583719,
        },
        {
            obec: 'Číčovice',
            kod: 539147,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25268,
            latitude: 50.156814,
            longitude: 14.249483,
        },
        {
            obec: 'Čilec',
            kod: 599671,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28925,
            latitude: 50.219494,
            longitude: 14.981754,
        },
        {
            obec: 'Čím',
            kod: 540081,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.77862,
            longitude: 14.377907,
        },
        {
            obec: 'Činěves',
            kod: 537080,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28901,
            latitude: 50.230096,
            longitude: 15.213992,
        },
        {
            obec: 'Čisovice',
            kod: 539155,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25204,
            latitude: 49.86348,
            longitude: 14.314883,
        },
        {
            obec: 'Čistá',
            kod: 535630,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29423,
            latitude: 50.472083,
            longitude: 14.843595,
        },
        {
            obec: 'Čistá',
            kod: 541699,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27034,
            latitude: 50.099047,
            longitude: 13.733683,
        },
        {
            obec: 'Čtyřkoly',
            kod: 529567,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25722,
            latitude: 49.868613,
            longitude: 14.719742,
        },
        {
            obec: 'Daleké Dušníky',
            kod: 540099,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.727533,
            longitude: 14.186636,
        },
        {
            obec: 'Dalovice',
            kod: 570818,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.425788,
            longitude: 14.880041,
        },
        {
            obec: 'Davle',
            kod: 539163,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25206,
            latitude: 49.89228,
            longitude: 14.400478,
        },
        {
            obec: 'Děkanovice',
            kod: 532746,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.618789,
            longitude: 15.148645,
        },
        {
            obec: 'Děkov',
            kod: 565181,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.171283,
            longitude: 13.554483,
        },
        {
            obec: 'Divišov',
            kod: 529621,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.788592,
            longitude: 14.875895,
        },
        {
            obec: 'Dlouhá Lhota',
            kod: 535656,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29405,
            latitude: 50.420201,
            longitude: 15.054223,
        },
        {
            obec: 'Dlouhá Lhota',
            kod: 513504,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.721224,
            longitude: 14.120705,
        },
        {
            obec: 'Dlouhopolsko',
            kod: 537098,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28903,
            latitude: 50.175313,
            longitude: 15.306231,
        },
        {
            obec: 'Dobročovice',
            kod: 565008,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25082,
            latitude: 50.056421,
            longitude: 14.700015,
        },
        {
            obec: 'Dobrovice',
            kod: 535672,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29441,
            latitude: 50.369427,
            longitude: 14.962439,
        },
        {
            obec: 'Dobrovítov',
            kod: 529559,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.787634,
            longitude: 15.328817,
        },
        {
            obec: 'Dobrovíz',
            kod: 539171,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25261,
            latitude: 50.113163,
            longitude: 14.217841,
        },
        {
            obec: 'Dobřejovice',
            kod: 538167,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.981717,
            longitude: 14.57837,
        },
        {
            obec: 'Dobřeň',
            kod: 531570,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.479505,
            longitude: 14.556812,
        },
        {
            obec: 'Dobříč',
            kod: 539180,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25225,
            latitude: 50.020021,
            longitude: 14.259017,
        },
        {
            obec: 'Dobřichov',
            kod: 533289,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.079731,
            longitude: 15.032473,
        },
        {
            obec: 'Dobřichovice',
            kod: 539198,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25229,
            latitude: 49.927597,
            longitude: 14.274802,
        },
        {
            obec: 'Dobříš',
            kod: 540111,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.781238,
            longitude: 14.167273,
        },
        {
            obec: 'Dobšice',
            kod: 537101,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28905,
            latitude: 50.132535,
            longitude: 15.268716,
        },
        {
            obec: 'Dobšín',
            kod: 571989,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.483425,
            longitude: 15.116761,
        },
        {
            obec: 'Doksy',
            kod: 532223,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27364,
            latitude: 50.119102,
            longitude: 14.04793,
        },
        {
            obec: 'Dolany',
            kod: 513130,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.115571,
            longitude: 14.149791,
        },
        {
            obec: 'Dolany',
            kod: 539201,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27801,
            latitude: 50.22214,
            longitude: 14.349629,
        },
        {
            obec: 'Dolní Beřkovice',
            kod: 534765,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27701,
            latitude: 50.393236,
            longitude: 14.450352,
        },
        {
            obec: 'Dolní Bousov',
            kod: 535702,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.438348,
            longitude: 15.128232,
        },
        {
            obec: 'Dolní Břežany',
            kod: 539210,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25241,
            latitude: 49.963314,
            longitude: 14.458611,
        },
        {
            obec: 'Dolní Hbity',
            kod: 540129,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26262,
            latitude: 49.657558,
            longitude: 14.169935,
        },
        {
            obec: 'Dolní Chvatliny',
            kod: 533297,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28144,
            latitude: 49.980602,
            longitude: 15.06939,
        },
        {
            obec: 'Dolní Kralovice',
            kod: 529648,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.644223,
            longitude: 15.177648,
        },
        {
            obec: 'Dolní Krupá',
            kod: 535711,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.546419,
            longitude: 14.86727,
        },
        {
            obec: 'Dolní Pohleď',
            kod: 531260,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.743519,
            longitude: 15.133625,
        },
        {
            obec: 'Dolní Slivno',
            kod: 535729,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29478,
            latitude: 50.308882,
            longitude: 14.732843,
        },
        {
            obec: 'Dolní Stakory',
            kod: 570940,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.437396,
            longitude: 14.972584,
        },
        {
            obec: 'Dolní Zimoř',
            kod: 531987,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.426717,
            longitude: 14.501473,
        },
        {
            obec: 'Dománovice',
            kod: 513181,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.111955,
            longitude: 15.322621,
        },
        {
            obec: 'Domousnice',
            kod: 535745,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29448,
            latitude: 50.394302,
            longitude: 15.101704,
        },
        {
            obec: 'Doubek',
            kod: 564885,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 50.017715,
            longitude: 14.737871,
        },
        {
            obec: 'Doubravčice',
            kod: 533301,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.022153,
            longitude: 14.793181,
        },
        {
            obec: 'Doubravička',
            kod: 571067,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29430,
            latitude: 50.395208,
            longitude: 14.776212,
        },
        {
            obec: 'Drahelčice',
            kod: 531146,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25219,
            latitude: 50.031926,
            longitude: 14.203056,
        },
        {
            obec: 'Drahenice',
            kod: 599298,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26285,
            latitude: 49.520949,
            longitude: 13.95851,
        },
        {
            obec: 'Drahlín',
            kod: 540145,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.732317,
            longitude: 13.968974,
        },
        {
            obec: 'Drahňovice',
            kod: 532151,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.822575,
            longitude: 14.90175,
        },
        {
            obec: 'Drahobudice',
            kod: 564681,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28144,
            latitude: 49.937579,
            longitude: 15.066361,
        },
        {
            obec: 'Drahouš',
            kod: 529711,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27033,
            latitude: 50.080232,
            longitude: 13.47577,
        },
        {
            obec: 'Drásov',
            kod: 540153,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.702186,
            longitude: 14.117807,
        },
        {
            obec: 'Drevníky',
            kod: 540170,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.72026,
            longitude: 14.274132,
        },
        {
            obec: 'Drhovy',
            kod: 540188,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.739163,
            longitude: 14.232487,
        },
        {
            obec: 'Drnek',
            kod: 512991,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27377,
            latitude: 50.195744,
            longitude: 13.972514,
        },
        {
            obec: 'Drobovice',
            kod: 531341,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.889628,
            longitude: 15.413523,
        },
        {
            obec: 'Drozdov',
            kod: 531154,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26761,
            latitude: 49.863241,
            longitude: 13.840685,
        },
        {
            obec: 'Družec',
            kod: 532274,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27362,
            latitude: 50.103086,
            longitude: 14.04551,
        },
        {
            obec: 'Dřetovice',
            kod: 532282,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27342,
            latitude: 50.182789,
            longitude: 14.210368,
        },
        {
            obec: 'Dřevčice',
            kod: 538191,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25001,
            latitude: 50.167854,
            longitude: 14.628327,
        },
        {
            obec: 'Dřínov',
            kod: 532291,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27371,
            latitude: 50.275664,
            longitude: 14.069855,
        },
        {
            obec: 'Dřínov',
            kod: 534773,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27745,
            latitude: 50.273754,
            longitude: 14.39761,
        },
        {
            obec: 'Dřísy',
            kod: 534781,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.256089,
            longitude: 14.643653,
        },
        {
            obec: 'Dubenec',
            kod: 598381,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.695809,
            longitude: 14.07934,
        },
        {
            obec: 'Dublovice',
            kod: 540218,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26251,
            latitude: 49.671881,
            longitude: 14.360998,
        },
        {
            obec: 'Dubno',
            kod: 564508,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.695958,
            longitude: 14.051851,
        },
        {
            obec: 'Dunice',
            kod: 532843,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.603247,
            longitude: 15.152746,
        },
        {
            obec: 'Dvory',
            kod: 537110,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.21175,
            longitude: 14.995558,
        },
        {
            obec: 'Dymokury',
            kod: 537128,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28901,
            latitude: 50.245776,
            longitude: 15.202652,
        },
        {
            obec: 'Felbabka',
            kod: 531162,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.813487,
            longitude: 13.942011,
        },
        {
            obec: 'Grunta',
            kod: 599450,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.974214,
            longitude: 15.255089,
        },
        {
            obec: 'Háje',
            kod: 598402,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.672735,
            longitude: 14.047452,
        },
        {
            obec: 'Herink',
            kod: 564915,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.966961,
            longitude: 14.575012,
        },
        {
            obec: 'Heřmaničky',
            kod: 529702,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25789,
            latitude: 49.605411,
            longitude: 14.582592,
        },
        {
            obec: 'Hlásná Třebaň',
            kod: 531171,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.922344,
            longitude: 14.198762,
        },
        {
            obec: 'Hlavenec',
            kod: 565989,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29474,
            latitude: 50.238392,
            longitude: 14.701862,
        },
        {
            obec: 'Hlízov',
            kod: 531197,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28532,
            latitude: 49.985096,
            longitude: 15.295206,
        },
        {
            obec: 'Hluboš',
            kod: 540242,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26222,
            latitude: 49.746325,
            longitude: 14.020299,
        },
        {
            obec: 'Hlubyně',
            kod: 564605,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.559767,
            longitude: 13.923345,
        },
        {
            obec: 'Hobšovice',
            kod: 513075,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27321,
            latitude: 50.270647,
            longitude: 14.162268,
        },
        {
            obec: 'Holubice',
            kod: 539228,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25265,
            latitude: 50.203189,
            longitude: 14.292949,
        },
        {
            obec: 'Horčápsko',
            kod: 564257,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.584502,
            longitude: 13.984557,
        },
        {
            obec: 'Horka I',
            kod: 531430,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.982795,
            longitude: 15.44004,
        },
        {
            obec: 'Horka II',
            kod: 534056,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.732179,
            longitude: 15.13385,
        },
        {
            obec: 'Horky',
            kod: 531359,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.868864,
            longitude: 15.439898,
        },
        {
            obec: 'Horky nad Jizerou',
            kod: 535818,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29473,
            latitude: 50.327164,
            longitude: 14.856169,
        },
        {
            obec: 'Horní Bezděkov',
            kod: 532312,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.082352,
            longitude: 14.067597,
        },
        {
            obec: 'Horní Bukovina',
            kod: 535834,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.543257,
            longitude: 14.926231,
        },
        {
            obec: 'Horní Kruty',
            kod: 533327,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28146,
            latitude: 49.919546,
            longitude: 14.959813,
        },
        {
            obec: 'Horní Počaply',
            kod: 534790,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27703,
            latitude: 50.424505,
            longitude: 14.390039,
        },
        {
            obec: 'Horní Slivno',
            kod: 599531,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29479,
            latitude: 50.304132,
            longitude: 14.704984,
        },
        {
            obec: 'Horoměřice',
            kod: 539236,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25262,
            latitude: 50.131831,
            longitude: 14.338894,
        },
        {
            obec: 'Horoušany',
            kod: 538221,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25082,
            latitude: 50.106504,
            longitude: 14.74068,
        },
        {
            obec: 'Horušice',
            kod: 531481,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28573,
            latitude: 49.996584,
            longitude: 15.429043,
        },
        {
            obec: 'Hořany',
            kod: 534854,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28914,
            latitude: 50.09771,
            longitude: 14.945897,
        },
        {
            obec: 'Hořátev',
            kod: 537152,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28913,
            latitude: 50.150278,
            longitude: 15.039418,
        },
        {
            obec: 'Hořesedly',
            kod: 541729,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.16239,
            longitude: 13.602769,
        },
        {
            obec: 'Hořešovice',
            kod: 532321,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.27169,
            longitude: 13.966906,
        },
        {
            obec: 'Hořešovičky',
            kod: 535150,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.274989,
            longitude: 13.95589,
        },
        {
            obec: 'Hořín',
            kod: 534803,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27601,
            latitude: 50.34492,
            longitude: 14.464285,
        },
        {
            obec: 'Hořovice',
            kod: 531189,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.836078,
            longitude: 13.902789,
        },
        {
            obec: 'Hořovičky',
            kod: 541737,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.155721,
            longitude: 13.531515,
        },
        {
            obec: 'Hospozín',
            kod: 532339,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27322,
            latitude: 50.306981,
            longitude: 14.17187,
        },
        {
            obec: 'Hostín',
            kod: 531499,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27732,
            latitude: 50.340525,
            longitude: 14.588998,
        },
        {
            obec: 'Hostín u Vojkovic',
            kod: 531928,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27744,
            latitude: 50.297177,
            longitude: 14.397072,
        },
        {
            obec: 'Hostivice',
            kod: 539244,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25301,
            latitude: 50.081679,
            longitude: 14.25867,
        },
        {
            obec: 'Hostomice',
            kod: 531201,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.825363,
            longitude: 14.045712,
        },
        {
            obec: 'Hostouň',
            kod: 532347,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27353,
            latitude: 50.11449,
            longitude: 14.201384,
        },
        {
            obec: 'Hostovlice',
            kod: 534064,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28562,
            latitude: 49.860355,
            longitude: 15.466403,
        },
        {
            obec: 'Hovorčovice',
            kod: 538230,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25064,
            latitude: 50.178764,
            longitude: 14.517993,
        },
        {
            obec: 'Hraběšín',
            kod: 531413,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.852623,
            longitude: 15.331919,
        },
        {
            obec: 'Hradčany',
            kod: 537161,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28905,
            latitude: 50.158082,
            longitude: 15.269964,
        },
        {
            obec: 'Hradečno',
            kod: 532355,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27304,
            latitude: 50.188435,
            longitude: 13.991367,
        },
        {
            obec: 'Hradešín',
            kod: 564800,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.038947,
            longitude: 14.756771,
        },
        {
            obec: 'Hradiště',
            kod: 532932,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.684271,
            longitude: 14.848805,
        },
        {
            obec: 'Hradištko',
            kod: 537179,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28912,
            latitude: 50.165593,
            longitude: 14.935157,
        },
        {
            obec: 'Hradištko',
            kod: 539252,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25209,
            latitude: 49.870321,
            longitude: 14.407963,
        },
        {
            obec: 'Hracholusky',
            kod: 565202,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27041,
            latitude: 50.003379,
            longitude: 13.773965,
        },
        {
            obec: 'Hrdlív',
            kod: 532363,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27306,
            latitude: 50.199356,
            longitude: 14.072592,
        },
        {
            obec: 'Hrdlořezy',
            kod: 535869,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29307,
            latitude: 50.451494,
            longitude: 14.875505,
        },
        {
            obec: 'Hrubý Jeseník',
            kod: 599638,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28932,
            latitude: 50.251247,
            longitude: 15.094151,
        },
        {
            obec: 'Hrusice',
            kod: 538248,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25166,
            latitude: 49.909951,
            longitude: 14.738162,
        },
        {
            obec: 'Hrušov',
            kod: 565733,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29473,
            latitude: 50.344973,
            longitude: 14.846482,
        },
        {
            obec: 'Hřebeč',
            kod: 532371,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27345,
            latitude: 50.136054,
            longitude: 14.164571,
        },
        {
            obec: 'Hřebečníky',
            kod: 541761,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27041,
            latitude: 49.984643,
            longitude: 13.753332,
        },
        {
            obec: 'Hředle',
            kod: 531219,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.903899,
            longitude: 13.920708,
        },
        {
            obec: 'Hředle',
            kod: 541770,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27008,
            latitude: 50.188106,
            longitude: 13.749524,
        },
        {
            obec: 'Hřiměždice',
            kod: 540285,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26214,
            latitude: 49.686956,
            longitude: 14.275016,
        },
        {
            obec: 'Hudčice',
            kod: 513580,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.531789,
            longitude: 13.922614,
        },
        {
            obec: 'Hudlice',
            kod: 531227,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26703,
            latitude: 49.961069,
            longitude: 13.970742,
        },
        {
            obec: 'Hulice',
            kod: 529737,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25763,
            latitude: 49.709703,
            longitude: 15.087693,
        },
        {
            obec: 'Husí Lhota',
            kod: 599522,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.435646,
            longitude: 15.00482,
        },
        {
            obec: 'Husinec',
            kod: 538256,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25068,
            latitude: 50.173845,
            longitude: 14.375186,
        },
        {
            obec: 'Hvězdonice',
            kod: 529745,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25724,
            latitude: 49.872921,
            longitude: 14.777379,
        },
        {
            obec: 'Hvozd',
            kod: 565334,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.051606,
            longitude: 13.698511,
        },
        {
            obec: 'Hvozdec',
            kod: 531235,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.807152,
            longitude: 13.880406,
        },
        {
            obec: 'Hvozdnice',
            kod: 539261,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25205,
            latitude: 49.872607,
            longitude: 14.3712,
        },
        {
            obec: 'Hvožďany',
            kod: 540315,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26244,
            latitude: 49.528354,
            longitude: 13.805216,
        },
        {
            obec: 'Hýskov',
            kod: 531243,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26706,
            latitude: 49.990829,
            longitude: 14.050654,
        },
        {
            obec: 'Chabeřice',
            kod: 534081,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.750246,
            longitude: 15.073898,
        },
        {
            obec: 'Chaloupky',
            kod: 531251,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.791959,
            longitude: 13.868465,
        },
        {
            obec: 'Charvatce',
            kod: 565784,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29445,
            latitude: 50.322727,
            longitude: 14.998718,
        },
        {
            obec: 'Chářovice',
            kod: 532886,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25741,
            latitude: 49.820354,
            longitude: 14.577967,
        },
        {
            obec: 'Chleby',
            kod: 532878,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25741,
            latitude: 49.827106,
            longitude: 14.556986,
        },
        {
            obec: 'Chleby',
            kod: 599620,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28931,
            latitude: 50.222917,
            longitude: 15.089496,
        },
        {
            obec: 'Chlístov',
            kod: 532045,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.801625,
            longitude: 14.654822,
        },
        {
            obec: 'Chlístovice',
            kod: 534099,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.885817,
            longitude: 15.202598,
        },
        {
            obec: 'Chlum',
            kod: 529770,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25763,
            latitude: 49.693104,
            longitude: 14.99586,
        },
        {
            obec: 'Chlumín',
            kod: 534820,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27743,
            latitude: 50.289218,
            longitude: 14.449384,
        },
        {
            obec: 'Chlustina',
            kod: 534455,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.878043,
            longitude: 13.919627,
        },
        {
            obec: 'Chmelná',
            kod: 529788,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.648205,
            longitude: 14.988457,
        },
        {
            obec: 'Chocerady',
            kod: 529796,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25724,
            latitude: 49.873005,
            longitude: 14.802174,
        },
        {
            obec: 'Chocnějovice',
            kod: 535923,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29413,
            latitude: 50.577007,
            longitude: 14.971496,
        },
        {
            obec: 'Chodouň',
            kod: 534447,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.898288,
            longitude: 13.986668,
        },
        {
            obec: 'Choratice',
            kod: 532606,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.83943,
            longitude: 14.874791,
        },
        {
            obec: 'Chorušice',
            kod: 534838,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27737,
            latitude: 50.390682,
            longitude: 14.670873,
        },
        {
            obec: 'Choťánky',
            kod: 537217,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.138263,
            longitude: 15.16254,
        },
        {
            obec: 'Choteč',
            kod: 539287,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25226,
            latitude: 49.986657,
            longitude: 14.283022,
        },
        {
            obec: 'Chotěšice',
            kod: 537225,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28901,
            latitude: 50.278663,
            longitude: 15.273164,
        },
        {
            obec: 'Chotětov',
            kod: 535931,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29428,
            latitude: 50.337534,
            longitude: 14.801624,
        },
        {
            obec: 'Chotilsko',
            kod: 540323,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.770587,
            longitude: 14.352585,
        },
        {
            obec: 'Choťovice',
            kod: 534994,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28905,
            latitude: 50.142489,
            longitude: 15.31978,
        },
        {
            obec: 'Chotusice',
            kod: 534102,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28576,
            latitude: 49.949241,
            longitude: 15.394385,
        },
        {
            obec: 'Chotutice',
            kod: 533343,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28103,
            latitude: 50.070077,
            longitude: 14.989985,
        },
        {
            obec: 'Chotýšany',
            kod: 529818,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25728,
            latitude: 49.744203,
            longitude: 14.814683,
        },
        {
            obec: 'Chrást',
            kod: 537233,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28914,
            latitude: 50.119017,
            longitude: 14.898236,
        },
        {
            obec: 'Chrást',
            kod: 564249,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.594513,
            longitude: 13.956768,
        },
        {
            obec: 'Chrášťany',
            kod: 532037,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.792155,
            longitude: 14.582032,
        },
        {
            obec: 'Chrášťany',
            kod: 533351,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.065584,
            longitude: 14.930251,
        },
        {
            obec: 'Chrášťany',
            kod: 539295,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25219,
            latitude: 50.046148,
            longitude: 14.261423,
        },
        {
            obec: 'Chrášťany',
            kod: 541818,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27001,
            latitude: 50.146543,
            longitude: 13.667388,
        },
        {
            obec: 'Chraštice',
            kod: 540358,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.576891,
            longitude: 14.071933,
        },
        {
            obec: 'Chroustov',
            kod: 534706,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28902,
            latitude: 50.28669,
            longitude: 15.347273,
        },
        {
            obec: 'Chrustenice',
            kod: 533670,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26712,
            latitude: 50.006145,
            longitude: 14.152325,
        },
        {
            obec: 'Chržín',
            kod: 532398,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27324,
            latitude: 50.295476,
            longitude: 14.270238,
        },
        {
            obec: 'Chudíř',
            kod: 571849,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29445,
            latitude: 50.308893,
            longitude: 15.013907,
        },
        {
            obec: 'Chvatěruby',
            kod: 534846,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27801,
            latitude: 50.232842,
            longitude: 14.342788,
        },
        {
            obec: 'Chyňava',
            kod: 531294,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26707,
            latitude: 50.027467,
            longitude: 14.074092,
        },
        {
            obec: 'Chýně',
            kod: 539309,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25301,
            latitude: 50.060826,
            longitude: 14.227054,
        },
        {
            obec: 'Chýnice',
            kod: 513431,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25217,
            latitude: 49.995792,
            longitude: 14.264385,
        },
        {
            obec: 'Jabkenice',
            kod: 535966,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29445,
            latitude: 50.324168,
            longitude: 15.01488,
        },
        {
            obec: 'Jablonná',
            kod: 540374,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26263,
            latitude: 49.661146,
            longitude: 14.138547,
        },
        {
            obec: 'Jankov',
            kod: 529842,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25703,
            latitude: 49.653039,
            longitude: 14.730847,
        },
        {
            obec: 'Janov',
            kod: 565270,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27006,
            latitude: 50.210508,
            longitude: 13.639913,
        },
        {
            obec: 'Jarpice',
            kod: 532401,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27372,
            latitude: 50.321709,
            longitude: 14.08533,
        },
        {
            obec: 'Javorník',
            kod: 529851,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25763,
            latitude: 49.686598,
            longitude: 15.026636,
        },
        {
            obec: 'Jedomělice',
            kod: 532410,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27378,
            latitude: 50.233057,
            longitude: 13.972409,
        },
        {
            obec: 'Jemníky',
            kod: 532428,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27401,
            latitude: 50.207644,
            longitude: 14.119076,
        },
        {
            obec: 'Jeneč',
            kod: 539317,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25261,
            latitude: 50.087404,
            longitude: 14.214924,
        },
        {
            obec: 'Jenštejn',
            kod: 538264,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25073,
            latitude: 50.152759,
            longitude: 14.611909,
        },
        {
            obec: 'Jesenice',
            kod: 539325,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25242,
            latitude: 49.968246,
            longitude: 14.513602,
        },
        {
            obec: 'Jesenice',
            kod: 540391,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.607849,
            longitude: 14.47823,
        },
        {
            obec: 'Jesenice',
            kod: 541834,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27033,
            latitude: 50.097137,
            longitude: 13.469595,
        },
        {
            obec: 'Jestřabí Lhota',
            kod: 533360,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.089936,
            longitude: 15.261812,
        },
        {
            obec: 'Ješetice',
            kod: 532134,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25789,
            latitude: 49.581263,
            longitude: 14.610228,
        },
        {
            obec: 'Jevany',
            kod: 533378,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28166,
            latitude: 49.970671,
            longitude: 14.810582,
        },
        {
            obec: 'Jeviněves',
            kod: 531871,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27705,
            latitude: 50.345214,
            longitude: 14.33821,
        },
        {
            obec: 'Jíkev',
            kod: 537250,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28932,
            latitude: 50.264465,
            longitude: 15.060123,
        },
        {
            obec: 'Jílové u Prahy',
            kod: 539333,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25401,
            latitude: 49.895549,
            longitude: 14.493434,
        },
        {
            obec: 'Jíloviště',
            kod: 539341,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25202,
            latitude: 49.927626,
            longitude: 14.342843,
        },
        {
            obec: 'Jince',
            kod: 540404,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.786343,
            longitude: 13.978781,
        },
        {
            obec: 'Jinočany',
            kod: 539350,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25225,
            latitude: 50.032857,
            longitude: 14.268789,
        },
        {
            obec: 'Jirny',
            kod: 538272,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25090,
            latitude: 50.115879,
            longitude: 14.699337,
        },
        {
            obec: 'Jiřice',
            kod: 599581,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28922,
            latitude: 50.252532,
            longitude: 14.836959,
        },
        {
            obec: 'Jivina',
            kod: 531308,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.794246,
            longitude: 13.836497,
        },
        {
            obec: 'Jivina',
            kod: 535974,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29414,
            latitude: 50.554218,
            longitude: 14.947263,
        },
        {
            obec: 'Jizbice',
            kod: 537268,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.257782,
            longitude: 14.993959,
        },
        {
            obec: 'Jizerní Vtelno',
            kod: 566039,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29431,
            latitude: 50.369496,
            longitude: 14.853294,
        },
        {
            obec: 'Josefův Důl',
            kod: 529613,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29307,
            latitude: 50.453507,
            longitude: 14.893781,
        },
        {
            obec: 'Kácov',
            kod: 534129,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28509,
            latitude: 49.777968,
            longitude: 15.027994,
        },
        {
            obec: 'Kačice',
            kod: 532444,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27304,
            latitude: 50.162716,
            longitude: 13.98826,
        },
        {
            obec: 'Kadlín',
            kod: 531774,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27735,
            latitude: 50.399568,
            longitude: 14.699845,
        },
        {
            obec: 'Kaliště',
            kod: 538281,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25165,
            latitude: 49.882761,
            longitude: 14.774613,
        },
        {
            obec: 'Kalivody',
            kod: 565440,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.208527,
            longitude: 13.841968,
        },
        {
            obec: 'Kamberk',
            kod: 531031,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25706,
            latitude: 49.597236,
            longitude: 14.840416,
        },
        {
            obec: 'Kamenice',
            kod: 538299,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25168,
            latitude: 49.901617,
            longitude: 14.582527,
        },
        {
            obec: 'Kamenné Zboží',
            kod: 537276,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.193015,
            longitude: 14.99953,
        },
        {
            obec: 'Kamenné Žehrovice',
            kod: 532452,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27301,
            latitude: 50.127079,
            longitude: 14.0182,
        },
        {
            obec: 'Kamenný Most',
            kod: 513032,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27326,
            latitude: 50.241505,
            longitude: 14.205845,
        },
        {
            obec: 'Kamenný Přívoz',
            kod: 539368,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25282,
            latitude: 49.863096,
            longitude: 14.503494,
        },
        {
            obec: 'Kamýk nad Vltavou',
            kod: 540439,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26263,
            latitude: 49.639124,
            longitude: 14.253549,
        },
        {
            obec: 'Kanina',
            kod: 531651,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27735,
            latitude: 50.424809,
            longitude: 14.600046,
        },
        {
            obec: 'Káraný',
            kod: 564974,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25075,
            latitude: 50.174869,
            longitude: 14.7371,
        },
        {
            obec: 'Karlík',
            kod: 599727,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25229,
            latitude: 49.935506,
            longitude: 14.259616,
        },
        {
            obec: 'Karlova Ves',
            kod: 565288,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 49.987386,
            longitude: 13.862509,
        },
        {
            obec: 'Karlštejn',
            kod: 531316,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.939604,
            longitude: 14.188146,
        },
        {
            obec: 'Katusice',
            kod: 536008,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29425,
            latitude: 50.445203,
            longitude: 14.777464,
        },
        {
            obec: 'Kbel',
            kod: 513415,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.988323,
            longitude: 15.143645,
        },
        {
            obec: 'Keblov',
            kod: 529907,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.677749,
            longitude: 15.071992,
        },
        {
            obec: 'Kladno',
            kod: 532053,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27201,
            latitude: 50.141799,
            longitude: 14.106846,
        },
        {
            obec: 'Kladruby',
            kod: 533084,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.723269,
            longitude: 14.950154,
        },
        {
            obec: 'Klášter Hradiště nad Jizerou',
            kod: 536024,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29415,
            latitude: 50.523509,
            longitude: 14.944829,
        },
        {
            obec: 'Klášterní Skalice',
            kod: 571687,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 50.023624,
            longitude: 14.98539,
        },
        {
            obec: 'Klecany',
            kod: 538311,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25067,
            latitude: 50.176091,
            longitude: 14.411589,
        },
        {
            obec: 'Klíčany',
            kod: 538329,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25069,
            latitude: 50.202124,
            longitude: 14.434551,
        },
        {
            obec: 'Klínec',
            kod: 571211,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.900957,
            longitude: 14.343355,
        },
        {
            obec: 'Klobuky',
            kod: 532461,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.294113,
            longitude: 13.987593,
        },
        {
            obec: 'Klokočná',
            kod: 513628,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25164,
            latitude: 49.958513,
            longitude: 14.718623,
        },
        {
            obec: 'Klučenice',
            kod: 540447,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26256,
            latitude: 49.553233,
            longitude: 14.212131,
        },
        {
            obec: 'Klučov',
            kod: 533386,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.095114,
            longitude: 14.910171,
        },
        {
            obec: 'Kluky',
            kod: 534137,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28545,
            latitude: 49.907559,
            longitude: 15.325361,
        },
        {
            obec: 'Kluky',
            kod: 571075,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29426,
            latitude: 50.440316,
            longitude: 14.725295,
        },
        {
            obec: 'Kly',
            kod: 534897,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27741,
            latitude: 50.308894,
            longitude: 14.501593,
        },
        {
            obec: 'Kmetiněves',
            kod: 532479,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27322,
            latitude: 50.309285,
            longitude: 14.157106,
        },
        {
            obec: 'Kněževes',
            kod: 539384,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25268,
            latitude: 50.121321,
            longitude: 14.259214,
        },
        {
            obec: 'Kněževes',
            kod: 541877,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27001,
            latitude: 50.146689,
            longitude: 13.637213,
        },
        {
            obec: 'Kněžice',
            kod: 537292,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28902,
            latitude: 50.257311,
            longitude: 15.335464,
        },
        {
            obec: 'Kněžičky',
            kod: 551481,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28908,
            latitude: 50.173997,
            longitude: 15.344934,
        },
        {
            obec: 'Kněžmost',
            kod: 536041,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29402,
            latitude: 50.489274,
            longitude: 15.038396,
        },
        {
            obec: 'Kňovice',
            kod: 598461,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.688403,
            longitude: 14.400412,
        },
        {
            obec: 'Knovíz',
            kod: 532487,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27401,
            latitude: 50.212665,
            longitude: 14.137056,
        },
        {
            obec: 'Kobylnice',
            kod: 531405,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 50.006398,
            longitude: 15.375802,
        },
        {
            obec: 'Kobylnice',
            kod: 571172,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29446,
            latitude: 50.371738,
            longitude: 15.053963,
        },
        {
            obec: 'Kochánky',
            kod: 536067,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29474,
            latitude: 50.277131,
            longitude: 14.780339,
        },
        {
            obec: 'Kojetice',
            kod: 538345,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25072,
            latitude: 50.238202,
            longitude: 14.508588,
        },
        {
            obec: 'Kokořín',
            kod: 534901,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27723,
            latitude: 50.429981,
            longitude: 14.567322,
        },
        {
            obec: 'Kolaje',
            kod: 537306,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28904,
            latitude: 50.155469,
            longitude: 15.238544,
        },
        {
            obec: 'Koleč',
            kod: 532495,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27329,
            latitude: 50.198872,
            longitude: 14.22351,
        },
        {
            obec: 'Kolešov',
            kod: 565199,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.157254,
            longitude: 13.510143,
        },
        {
            obec: 'Kolešovice',
            kod: 541893,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27002,
            latitude: 50.139748,
            longitude: 13.610387,
        },
        {
            obec: 'Kolín',
            kod: 533165,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.027429,
            longitude: 15.202828,
        },
        {
            obec: 'Kolomuty',
            kod: 570974,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.402199,
            longitude: 14.979095,
        },
        {
            obec: 'Komárov',
            kod: 531324,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.806602,
            longitude: 13.856467,
        },
        {
            obec: 'Konárovice',
            kod: 533394,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28125,
            latitude: 50.041558,
            longitude: 15.284322,
        },
        {
            obec: 'Kondrac',
            kod: 529931,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.667248,
            longitude: 14.884609,
        },
        {
            obec: 'Koněprusy',
            kod: 531332,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 49.920996,
            longitude: 14.065859,
        },
        {
            obec: 'Konětopy',
            kod: 531553,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.276506,
            longitude: 14.653932,
        },
        {
            obec: 'Konojedy',
            kod: 564761,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.948677,
            longitude: 14.851354,
        },
        {
            obec: 'Korkyně',
            kod: 599204,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26206,
            latitude: 49.780542,
            longitude: 14.351437,
        },
        {
            obec: 'Korno',
            kod: 533793,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.920584,
            longitude: 14.136968,
        },
        {
            obec: 'Koryta',
            kod: 599557,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29411,
            latitude: 50.572055,
            longitude: 15.011824,
        },
        {
            obec: 'Kořenice',
            kod: 533408,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.977216,
            longitude: 15.140969,
        },
        {
            obec: 'Kosmonosy',
            kod: 570826,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29306,
            latitude: 50.438608,
            longitude: 14.929915,
        },
        {
            obec: 'Kosoř',
            kod: 539392,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25226,
            latitude: 49.98758,
            longitude: 14.326682,
        },
        {
            obec: 'Kosořice',
            kod: 536121,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29441,
            latitude: 50.33348,
            longitude: 14.96974,
        },
        {
            obec: 'Kosova Hora',
            kod: 540498,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26291,
            latitude: 49.654247,
            longitude: 14.471834,
        },
        {
            obec: 'Kostelec nad Černými Lesy',
            kod: 533416,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.994137,
            longitude: 14.859324,
        },
        {
            obec: 'Kostelec nad Labem',
            kod: 534935,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27713,
            latitude: 50.226634,
            longitude: 14.585628,
        },
        {
            obec: 'Kostelec u Křížků',
            kod: 538370,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25168,
            latitude: 49.907123,
            longitude: 14.557334,
        },
        {
            obec: 'Kostelní Hlavno',
            kod: 536130,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29476,
            latitude: 50.257529,
            longitude: 14.699086,
        },
        {
            obec: 'Kostelní Lhota',
            kod: 537314,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28912,
            latitude: 50.129367,
            longitude: 15.024308,
        },
        {
            obec: 'Kostomlátky',
            kod: 529630,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28921,
            latitude: 50.171133,
            longitude: 14.984131,
        },
        {
            obec: 'Kostomlaty nad Labem',
            kod: 537331,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28921,
            latitude: 50.184587,
            longitude: 14.954085,
        },
        {
            obec: 'Košátky',
            kod: 566047,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29479,
            latitude: 50.315276,
            longitude: 14.667096,
        },
        {
            obec: 'Košice',
            kod: 551465,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.895897,
            longitude: 15.150945,
        },
        {
            obec: 'Košík',
            kod: 537349,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28935,
            latitude: 50.318352,
            longitude: 15.134157,
        },
        {
            obec: 'Kotenčice',
            kod: 513555,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.736397,
            longitude: 14.093917,
        },
        {
            obec: 'Kotopeky',
            kod: 534072,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.856839,
            longitude: 13.925264,
        },
        {
            obec: 'Kounice',
            kod: 537357,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28915,
            latitude: 50.10777,
            longitude: 14.856029,
        },
        {
            obec: 'Kounov',
            kod: 541907,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27006,
            latitude: 50.212979,
            longitude: 13.675205,
        },
        {
            obec: 'Koupě',
            kod: 529681,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.514885,
            longitude: 13.918493,
        },
        {
            obec: 'Kouřim',
            kod: 533424,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28161,
            latitude: 50.003158,
            longitude: 14.977137,
        },
        {
            obec: 'Kouty',
            kod: 534943,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.193218,
            longitude: 15.148855,
        },
        {
            obec: 'Kováň',
            kod: 571971,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29425,
            latitude: 50.423409,
            longitude: 14.777875,
        },
        {
            obec: 'Kovanec',
            kod: 572012,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29426,
            latitude: 50.417808,
            longitude: 14.77674,
        },
        {
            obec: 'Kovanice',
            kod: 537373,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.169567,
            longitude: 15.070901,
        },
        {
            obec: 'Kozárovice',
            kod: 540536,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26284,
            latitude: 49.555628,
            longitude: 14.106587,
        },
        {
            obec: 'Kozmice',
            kod: 529940,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.824883,
            longitude: 14.796183,
        },
        {
            obec: 'Kozojedy',
            kod: 533432,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.996652,
            longitude: 14.814366,
        },
        {
            obec: 'Kozojedy',
            kod: 565385,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.255389,
            longitude: 13.815687,
        },
        {
            obec: 'Kozomín',
            kod: 571792,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27745,
            latitude: 50.236919,
            longitude: 14.371215,
        },
        {
            obec: 'Krakov',
            kod: 565351,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.037468,
            longitude: 13.647573,
        },
        {
            obec: 'Krakovany',
            kod: 533441,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28127,
            latitude: 50.066166,
            longitude: 15.36897,
        },
        {
            obec: 'Krakovec',
            kod: 565369,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.018944,
            longitude: 13.638347,
        },
        {
            obec: 'Královice',
            kod: 535109,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27401,
            latitude: 50.263129,
            longitude: 14.055907,
        },
        {
            obec: 'Kralupy nad Vltavou',
            kod: 534951,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27801,
            latitude: 50.241745,
            longitude: 14.310745,
        },
        {
            obec: 'Králův Dvůr',
            kod: 533203,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26701,
            latitude: 49.949911,
            longitude: 14.034561,
        },
        {
            obec: 'Krásná Hora nad Vltavou',
            kod: 540552,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26256,
            latitude: 49.604711,
            longitude: 14.277524,
        },
        {
            obec: 'Krásná Ves',
            kod: 536164,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29425,
            latitude: 50.42478,
            longitude: 14.792241,
        },
        {
            obec: 'Krhanice',
            kod: 529958,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25742,
            latitude: 49.856112,
            longitude: 14.557463,
        },
        {
            obec: 'Krchleby',
            kod: 534153,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.888996,
            longitude: 15.350407,
        },
        {
            obec: 'Krchleby',
            kod: 537390,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.239265,
            longitude: 15.022812,
        },
        {
            obec: 'Krňany',
            kod: 529974,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25744,
            latitude: 49.844913,
            longitude: 14.477553,
        },
        {
            obec: 'Krnsko',
            kod: 536172,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29431,
            latitude: 50.372511,
            longitude: 14.863152,
        },
        {
            obec: 'Kropáčova Vrutice',
            kod: 536181,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29479,
            latitude: 50.330635,
            longitude: 14.717485,
        },
        {
            obec: 'Kroučová',
            kod: 541940,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.206875,
            longitude: 13.784056,
        },
        {
            obec: 'Krty',
            kod: 598500,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27033,
            latitude: 50.091708,
            longitude: 13.432026,
        },
        {
            obec: 'Krupá',
            kod: 533459,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 50.017461,
            longitude: 14.871405,
        },
        {
            obec: 'Krupá',
            kod: 541966,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27009,
            latitude: 50.174955,
            longitude: 13.731687,
        },
        {
            obec: 'Krušovice',
            kod: 541974,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27053,
            latitude: 50.172357,
            longitude: 13.775232,
        },
        {
            obec: 'Krychnov',
            kod: 599484,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.006062,
            longitude: 15.057761,
        },
        {
            obec: 'Křečhoř',
            kod: 533467,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.025621,
            longitude: 15.127128,
        },
        {
            obec: 'Křečkov',
            kod: 537403,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.180061,
            longitude: 15.114377,
        },
        {
            obec: 'Křečovice',
            kod: 529991,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25756,
            latitude: 49.721417,
            longitude: 14.475637,
        },
        {
            obec: 'Křenek',
            kod: 534960,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.229642,
            longitude: 14.629558,
        },
        {
            obec: 'Křenice',
            kod: 564991,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25084,
            latitude: 50.031104,
            longitude: 14.668617,
        },
        {
            obec: 'Křepenice',
            kod: 540579,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.702999,
            longitude: 14.347666,
        },
        {
            obec: 'Křesetice',
            kod: 534161,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28547,
            latitude: 49.907238,
            longitude: 15.262953,
        },
        {
            obec: 'Křešín',
            kod: 540587,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.799554,
            longitude: 13.948174,
        },
        {
            obec: 'Křinec',
            kod: 537411,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28933,
            latitude: 50.264587,
            longitude: 15.137929,
        },
        {
            obec: 'Křivoklát',
            kod: 541982,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.037956,
            longitude: 13.870474,
        },
        {
            obec: 'Křivsoudov',
            kod: 530000,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25766,
            latitude: 49.633077,
            longitude: 15.087465,
        },
        {
            obec: 'Křížkový Újezdec',
            kod: 538418,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25168,
            latitude: 49.930882,
            longitude: 14.585468,
        },
        {
            obec: 'Kšely',
            kod: 571717,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.04352,
            longitude: 14.902034,
        },
        {
            obec: 'Kublov',
            kod: 531375,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26741,
            latitude: 49.943803,
            longitude: 13.876779,
        },
        {
            obec: 'Kunice',
            kod: 538426,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25163,
            latitude: 49.936652,
            longitude: 14.671607,
        },
        {
            obec: 'Kuňovice',
            kod: 530026,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.658386,
            longitude: 14.999521,
        },
        {
            obec: 'Kutná Hora',
            kod: 533955,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.952531,
            longitude: 15.268754,
        },
        {
            obec: 'Kutrovice',
            kod: 571512,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27375,
            latitude: 50.261654,
            longitude: 14.018974,
        },
        {
            obec: 'Květnice',
            kod: 564982,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25084,
            latitude: 50.057363,
            longitude: 14.684222,
        },
        {
            obec: 'Kvílice',
            kod: 532517,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27375,
            latitude: 50.258155,
            longitude: 14.002429,
        },
        {
            obec: 'Kyšice',
            kod: 532525,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.091291,
            longitude: 14.106343,
        },
        {
            obec: 'Kytín',
            kod: 571261,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.850721,
            longitude: 14.219156,
        },
        {
            obec: 'Lány',
            kod: 541991,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27061,
            latitude: 50.124811,
            longitude: 13.950519,
        },
        {
            obec: 'Lašovice',
            kod: 542008,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27021,
            latitude: 50.05506,
            longitude: 13.78231,
        },
        {
            obec: 'Láz',
            kod: 540625,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26241,
            latitude: 49.652138,
            longitude: 13.916712,
        },
        {
            obec: 'Lázně Toušeň',
            kod: 538914,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25089,
            latitude: 50.16937,
            longitude: 14.716112,
        },
        {
            obec: 'Lazsko',
            kod: 564346,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.623599,
            longitude: 14.003954,
        },
        {
            obec: 'Lážovice',
            kod: 533939,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.85831,
            longitude: 14.071383,
        },
        {
            obec: 'Ledce',
            kod: 532533,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27305,
            latitude: 50.196529,
            longitude: 14.005095,
        },
        {
            obec: 'Ledce',
            kod: 536202,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29447,
            latitude: 50.353466,
            longitude: 15.084579,
        },
        {
            obec: 'Ledčice',
            kod: 534978,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27708,
            latitude: 50.341051,
            longitude: 14.294576,
        },
        {
            obec: 'Ledečko',
            kod: 534170,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.84992,
            longitude: 14.94898,
        },
        {
            obec: 'Lešany',
            kod: 530051,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25744,
            latitude: 49.844269,
            longitude: 14.525051,
        },
        {
            obec: 'Lešetice',
            kod: 513521,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.647301,
            longitude: 14.02119,
        },
        {
            obec: 'Lety',
            kod: 539406,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25229,
            latitude: 49.9211,
            longitude: 14.255311,
        },
        {
            obec: 'Lhota',
            kod: 513041,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27301,
            latitude: 50.088744,
            longitude: 14.011917,
        },
        {
            obec: 'Lhota',
            kod: 534986,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.243309,
            longitude: 14.65652,
        },
        {
            obec: 'Lhota u Příbramě',
            kod: 598411,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.714255,
            longitude: 13.980642,
        },
        {
            obec: 'Lhotka',
            kod: 533335,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26723,
            latitude: 49.83232,
            longitude: 13.996335,
        },
        {
            obec: 'Lhotka',
            kod: 531898,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27731,
            latitude: 50.371468,
            longitude: 14.548888,
        },
        {
            obec: 'Lhotky',
            kod: 536211,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.396401,
            longitude: 15.052956,
        },
        {
            obec: 'Libčice nad Vltavou',
            kod: 539414,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25266,
            latitude: 50.199129,
            longitude: 14.362784,
        },
        {
            obec: 'Liběchov',
            kod: 535001,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.407939,
            longitude: 14.446855,
        },
        {
            obec: 'Libenice',
            kod: 533475,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.984575,
            longitude: 15.258007,
        },
        {
            obec: 'Libeř',
            kod: 539422,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25241,
            latitude: 49.924257,
            longitude: 14.480724,
        },
        {
            obec: 'Líbeznice',
            kod: 538442,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25065,
            latitude: 50.192079,
            longitude: 14.493682,
        },
        {
            obec: 'Libež',
            kod: 530069,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.757522,
            longitude: 14.916873,
        },
        {
            obec: 'Libice nad Cidlinou',
            kod: 537438,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28907,
            latitude: 50.126874,
            longitude: 15.178306,
        },
        {
            obec: 'Libiš',
            kod: 571784,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27711,
            latitude: 50.274275,
            longitude: 14.50251,
        },
        {
            obec: 'Liblice',
            kod: 531502,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27732,
            latitude: 50.31343,
            longitude: 14.590516,
        },
        {
            obec: 'Libodřice',
            kod: 533483,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.004416,
            longitude: 15.088387,
        },
        {
            obec: 'Libochovičky',
            kod: 564150,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27342,
            latitude: 50.172676,
            longitude: 14.239708,
        },
        {
            obec: 'Libomyšl',
            kod: 531448,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26723,
            latitude: 49.872637,
            longitude: 13.998731,
        },
        {
            obec: 'Libovice',
            kod: 571601,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27379,
            latitude: 50.235701,
            longitude: 14.016452,
        },
        {
            obec: 'Libušín',
            kod: 532576,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27306,
            latitude: 50.168293,
            longitude: 14.05469,
        },
        {
            obec: 'Lidice',
            kod: 532584,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27354,
            latitude: 50.143626,
            longitude: 14.190234,
        },
        {
            obec: 'Lichoceves',
            kod: 571326,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25264,
            latitude: 50.152863,
            longitude: 14.284353,
        },
        {
            obec: 'Lipec',
            kod: 513270,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28126,
            latitude: 50.085084,
            longitude: 15.364668,
        },
        {
            obec: 'Lipník',
            kod: 565563,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29443,
            latitude: 50.272647,
            longitude: 14.912532,
        },
        {
            obec: 'Líský',
            kod: 571555,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27376,
            latitude: 50.250661,
            longitude: 13.929036,
        },
        {
            obec: 'Lišany',
            kod: 542016,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27052,
            latitude: 50.147452,
            longitude: 13.742038,
        },
        {
            obec: 'Líšnice',
            kod: 539457,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.888884,
            longitude: 14.319068,
        },
        {
            obec: 'Liteň',
            kod: 531456,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.903811,
            longitude: 14.149092,
        },
        {
            obec: 'Litichovice',
            kod: 532258,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.783034,
            longitude: 14.850678,
        },
        {
            obec: 'Lobeč',
            kod: 531677,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27736,
            latitude: 50.460567,
            longitude: 14.666882,
        },
        {
            obec: 'Loděnice',
            kod: 531464,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26712,
            latitude: 49.994962,
            longitude: 14.157959,
        },
        {
            obec: 'Lochovice',
            kod: 531472,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26723,
            latitude: 49.853286,
            longitude: 13.981372,
        },
        {
            obec: 'Loket',
            kod: 530093,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.655592,
            longitude: 15.116995,
        },
        {
            obec: 'Lošany',
            kod: 533505,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.99764,
            longitude: 15.126048,
        },
        {
            obec: 'Loucká',
            kod: 535095,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27324,
            latitude: 50.327578,
            longitude: 14.239126,
        },
        {
            obec: 'Loučeň',
            kod: 537446,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28937,
            latitude: 50.285812,
            longitude: 15.02016,
        },
        {
            obec: 'Loukov',
            kod: 570770,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29411,
            latitude: 50.561381,
            longitude: 15.035739,
        },
        {
            obec: 'Loukovec',
            kod: 536261,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29411,
            latitude: 50.562014,
            longitude: 15.015815,
        },
        {
            obec: 'Louňovice',
            kod: 538451,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25162,
            latitude: 49.982366,
            longitude: 14.761849,
        },
        {
            obec: 'Louňovice pod Blaníkem',
            kod: 530107,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25706,
            latitude: 49.637336,
            longitude: 14.846795,
        },
        {
            obec: 'Lštění',
            kod: 532193,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25722,
            latitude: 49.864385,
            longitude: 14.71437,
        },
        {
            obec: 'Lubná',
            kod: 542032,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27036,
            latitude: 50.07703,
            longitude: 13.700847,
        },
        {
            obec: 'Luštěnice',
            kod: 536270,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29442,
            latitude: 50.323059,
            longitude: 14.93678,
        },
        {
            obec: 'Lužce',
            kod: 534404,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.98422,
            longitude: 14.196827,
        },
        {
            obec: 'Lužec nad Vltavou',
            kod: 535028,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27706,
            latitude: 50.321488,
            longitude: 14.400326,
        },
        {
            obec: 'Lužná',
            kod: 542041,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27051,
            latitude: 50.123876,
            longitude: 13.770152,
        },
        {
            obec: 'Lysá nad Labem',
            kod: 537454,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28922,
            latitude: 50.201532,
            longitude: 14.832919,
        },
        {
            obec: 'Makotřasy',
            kod: 532622,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27354,
            latitude: 50.144584,
            longitude: 14.214806,
        },
        {
            obec: 'Malá Hraštice',
            kod: 540714,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.807609,
            longitude: 14.279979,
        },
        {
            obec: 'Malá Víska',
            kod: 533319,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.776121,
            longitude: 13.875624,
        },
        {
            obec: 'Malé Kyšice',
            kod: 532631,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.061193,
            longitude: 14.09042,
        },
        {
            obec: 'Malé Přítočno',
            kod: 513113,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.107798,
            longitude: 14.135713,
        },
        {
            obec: 'Malešov',
            kod: 534188,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28541,
            latitude: 49.911117,
            longitude: 15.224443,
        },
        {
            obec: 'Malíkovice',
            kod: 532657,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27377,
            latitude: 50.211503,
            longitude: 13.982657,
        },
        {
            obec: 'Malinová',
            kod: 565377,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.047922,
            longitude: 13.667032,
        },
        {
            obec: 'Málkov',
            kod: 534218,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26701,
            latitude: 49.888313,
            longitude: 14.023652,
        },
        {
            obec: 'Malotice',
            kod: 533513,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.965299,
            longitude: 14.977728,
        },
        {
            obec: 'Malý Újezd',
            kod: 535036,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27731,
            latitude: 50.33257,
            longitude: 14.529687,
        },
        {
            obec: 'Maršovice',
            kod: 530115,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25755,
            latitude: 49.71367,
            longitude: 14.564615,
        },
        {
            obec: 'Máslovice',
            kod: 538469,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25069,
            latitude: 50.208722,
            longitude: 14.37903,
        },
        {
            obec: 'Masojedy',
            kod: 564702,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.026078,
            longitude: 14.777985,
        },
        {
            obec: 'Mcely',
            kod: 537462,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28936,
            latitude: 50.294356,
            longitude: 15.075984,
        },
        {
            obec: 'Mečeříž',
            kod: 565644,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29477,
            latitude: 50.29048,
            longitude: 14.7369,
        },
        {
            obec: 'Medonosy',
            kod: 529575,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.494256,
            longitude: 14.487049,
        },
        {
            obec: 'Měchenice',
            kod: 539490,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25206,
            latitude: 49.908274,
            longitude: 14.389375,
        },
        {
            obec: 'Mělnické Vtelno',
            kod: 535044,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27738,
            latitude: 50.352413,
            longitude: 14.695218,
        },
        {
            obec: 'Mělník',
            kod: 534676,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27601,
            latitude: 50.354002,
            longitude: 14.481881,
        },
        {
            obec: 'Měňany',
            kod: 531529,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.910104,
            longitude: 14.117791,
        },
        {
            obec: 'Městec Králové',
            kod: 537489,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28903,
            latitude: 50.207281,
            longitude: 15.297687,
        },
        {
            obec: 'Městečko',
            kod: 542067,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.050739,
            longitude: 13.86367,
        },
        {
            obec: 'Měšice',
            kod: 538477,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25064,
            latitude: 50.198097,
            longitude: 14.520024,
        },
        {
            obec: 'Mezno',
            kod: 530158,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25786,
            latitude: 49.534622,
            longitude: 14.64504,
        },
        {
            obec: 'Mezouň',
            kod: 531537,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 50.004378,
            longitude: 14.214277,
        },
        {
            obec: 'Milčice',
            kod: 537497,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.10547,
            longitude: 14.991803,
        },
        {
            obec: 'Milešov',
            kod: 540749,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26256,
            latitude: 49.589338,
            longitude: 14.22151,
        },
        {
            obec: 'Miličín',
            kod: 530166,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25786,
            latitude: 49.570793,
            longitude: 14.66151,
        },
        {
            obec: 'Milín',
            kod: 540757,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.632028,
            longitude: 14.046103,
        },
        {
            obec: 'Milostín',
            kod: 542075,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.193824,
            longitude: 13.669209,
        },
        {
            obec: 'Milovice',
            kod: 537501,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28924,
            latitude: 50.226061,
            longitude: 14.888739,
        },
        {
            obec: 'Milý',
            kod: 565466,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.232771,
            longitude: 13.867148,
        },
        {
            obec: 'Mirošovice',
            kod: 538485,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25166,
            latitude: 49.909949,
            longitude: 14.711306,
        },
        {
            obec: 'Miřetice',
            kod: 530174,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.659049,
            longitude: 14.976635,
        },
        {
            obec: 'Miskovice',
            kod: 534200,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28501,
            latitude: 49.946226,
            longitude: 15.204823,
        },
        {
            obec: 'Mladá Boleslav',
            kod: 535419,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.413525,
            longitude: 14.908538,
        },
        {
            obec: 'Mnichovice',
            kod: 530191,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.668707,
            longitude: 15.039688,
        },
        {
            obec: 'Mnichovice',
            kod: 538493,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25164,
            latitude: 49.936117,
            longitude: 14.709175,
        },
        {
            obec: 'Mnichovo Hradiště',
            kod: 536326,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.527029,
            longitude: 14.972104,
        },
        {
            obec: 'Mníšek pod Brdy',
            kod: 540765,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.866601,
            longitude: 14.261865,
        },
        {
            obec: 'Močovice',
            kod: 530999,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.906414,
            longitude: 15.348298,
        },
        {
            obec: 'Modletice',
            kod: 598267,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.97026,
            longitude: 14.591186,
        },
        {
            obec: 'Modřovice',
            kod: 599751,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.617462,
            longitude: 13.956485,
        },
        {
            obec: 'Mohelnice nad Jizerou',
            kod: 565822,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29413,
            latitude: 50.559891,
            longitude: 14.977625,
        },
        {
            obec: 'Mochov',
            kod: 538507,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25087,
            latitude: 50.141925,
            longitude: 14.795066,
        },
        {
            obec: 'Mokrovraty',
            kod: 540781,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.795772,
            longitude: 14.25023,
        },
        {
            obec: 'Mořina',
            kod: 531545,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26717,
            latitude: 49.95294,
            longitude: 14.20874,
        },
        {
            obec: 'Mořinka',
            kod: 533912,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.939081,
            longitude: 14.237277,
        },
        {
            obec: 'Mrač',
            kod: 530204,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25721,
            latitude: 49.829754,
            longitude: 14.690616,
        },
        {
            obec: 'Mratín',
            kod: 538515,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.203151,
            longitude: 14.551084,
        },
        {
            obec: 'Mrzky',
            kod: 513288,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.044722,
            longitude: 14.810127,
        },
        {
            obec: 'Mšec',
            kod: 542105,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27064,
            latitude: 50.205291,
            longitude: 13.898432,
        },
        {
            obec: 'Mšecké Žehrovice',
            kod: 542113,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27064,
            latitude: 50.179113,
            longitude: 13.923232,
        },
        {
            obec: 'Mšeno',
            kod: 535052,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27735,
            latitude: 50.438194,
            longitude: 14.632611,
        },
        {
            obec: 'Mukařov',
            kod: 571865,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.56925,
            longitude: 14.925793,
        },
        {
            obec: 'Mukařov',
            kod: 538523,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25162,
            latitude: 49.991254,
            longitude: 14.741654,
        },
        {
            obec: 'Mutějovice',
            kod: 542121,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27007,
            latitude: 50.194353,
            longitude: 13.709619,
        },
        {
            obec: 'Načeradec',
            kod: 530212,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25708,
            latitude: 49.610286,
            longitude: 14.906434,
        },
        {
            obec: 'Nalžovice',
            kod: 540790,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26293,
            latitude: 49.69869,
            longitude: 14.370852,
        },
        {
            obec: 'Narysov',
            kod: 564478,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.640955,
            longitude: 13.970987,
        },
        {
            obec: 'Nebovidy',
            kod: 533521,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.991627,
            longitude: 15.219584,
        },
        {
            obec: 'Nebužely',
            kod: 535061,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27734,
            latitude: 50.390406,
            longitude: 14.5909,
        },
        {
            obec: 'Nečín',
            kod: 540811,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26213,
            latitude: 49.698546,
            longitude: 14.23414,
        },
        {
            obec: 'Nedomice',
            kod: 531863,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.259765,
            longitude: 14.616064,
        },
        {
            obec: 'Nedrahovice',
            kod: 540820,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.609069,
            longitude: 14.455837,
        },
        {
            obec: 'Nehvizdy',
            kod: 538540,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25081,
            latitude: 50.130663,
            longitude: 14.730041,
        },
        {
            obec: 'Nechvalice',
            kod: 540846,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.576649,
            longitude: 14.396667,
        },
        {
            obec: 'Nelahozeves',
            kod: 535079,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27751,
            latitude: 50.261583,
            longitude: 14.298919,
        },
        {
            obec: 'Němčice',
            kod: 571636,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.084482,
            longitude: 15.294147,
        },
        {
            obec: 'Němčice',
            kod: 536334,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29442,
            latitude: 50.346866,
            longitude: 14.926664,
        },
        {
            obec: 'Nemyslovice',
            kod: 571156,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29429,
            latitude: 50.358549,
            longitude: 14.76393,
        },
        {
            obec: 'Nenačovice',
            kod: 533602,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 50.019222,
            longitude: 14.139071,
        },
        {
            obec: 'Nepoměřice',
            kod: 534226,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28511,
            latitude: 49.880863,
            longitude: 15.148908,
        },
        {
            obec: 'Nepomuk',
            kod: 564524,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.642895,
            longitude: 13.837368,
        },
        {
            obec: 'Neprobylice',
            kod: 571521,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27375,
            latitude: 50.264054,
            longitude: 14.027719,
        },
        {
            obec: 'Nepřevázka',
            kod: 536351,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.378415,
            longitude: 14.916429,
        },
        {
            obec: 'Neratovice',
            kod: 535087,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27711,
            latitude: 50.259361,
            longitude: 14.517703,
        },
        {
            obec: 'Nespeky',
            kod: 530263,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25722,
            latitude: 49.859411,
            longitude: 14.659464,
        },
        {
            obec: 'Nestrašovice',
            kod: 564222,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.560455,
            longitude: 14.026157,
        },
        {
            obec: 'Nesuchyně',
            kod: 542130,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27007,
            latitude: 50.179666,
            longitude: 13.688009,
        },
        {
            obec: 'Nesvačily',
            kod: 534269,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.881363,
            longitude: 14.124461,
        },
        {
            obec: 'Netřebice',
            kod: 534919,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.213205,
            longitude: 15.142899,
        },
        {
            obec: 'Netvořice',
            kod: 530298,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25744,
            latitude: 49.815795,
            longitude: 14.518381,
        },
        {
            obec: 'Neuměřice',
            kod: 532665,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27326,
            latitude: 50.241781,
            longitude: 14.220097,
        },
        {
            obec: 'Neumětely',
            kod: 531588,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.852562,
            longitude: 14.036716,
        },
        {
            obec: 'Neustupov',
            kod: 530301,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25786,
            latitude: 49.615106,
            longitude: 14.698704,
        },
        {
            obec: 'Neveklov',
            kod: 530310,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25756,
            latitude: 49.753835,
            longitude: 14.533011,
        },
        {
            obec: 'Neveklovice',
            kod: 571997,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29413,
            latitude: 50.570239,
            longitude: 14.948481,
        },
        {
            obec: 'Nezabudice',
            kod: 598585,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.013274,
            longitude: 13.822228,
        },
        {
            obec: 'Niměřice',
            kod: 571121,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29430,
            latitude: 50.39588,
            longitude: 14.808547,
        },
        {
            obec: 'Nižbor',
            kod: 531596,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26705,
            latitude: 49.999065,
            longitude: 14.001056,
        },
        {
            obec: 'Nosálov',
            kod: 531707,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27735,
            latitude: 50.473589,
            longitude: 14.670766,
        },
        {
            obec: 'Nová Telib',
            kod: 536377,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.39127,
            longitude: 15.032963,
        },
        {
            obec: 'Nová Ves',
            kod: 535117,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27752,
            latitude: 50.312898,
            longitude: 14.308286,
        },
        {
            obec: 'Nová Ves',
            kod: 538558,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.219324,
            longitude: 14.535739,
        },
        {
            obec: 'Nová Ves I',
            kod: 533530,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.052246,
            longitude: 15.144556,
        },
        {
            obec: 'Nová Ves pod Pleší',
            kod: 540889,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26204,
            latitude: 49.831865,
            longitude: 14.275336,
        },
        {
            obec: 'Nová Ves u Bakova',
            kod: 571806,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29401,
            latitude: 50.490776,
            longitude: 14.926496,
        },
        {
            obec: 'Nové Dvory',
            kod: 534242,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28531,
            latitude: 49.970682,
            longitude: 15.324978,
        },
        {
            obec: 'Nové Dvory',
            kod: 540897,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.803328,
            longitude: 14.357021,
        },
        {
            obec: 'Nové Strašecí',
            kod: 542164,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27101,
            latitude: 50.152823,
            longitude: 13.900538,
        },
        {
            obec: 'Nový Dům',
            kod: 542181,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26901,
            latitude: 50.08824,
            longitude: 13.831001,
        },
        {
            obec: 'Nový Dvůr',
            kod: 599654,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28932,
            latitude: 50.238112,
            longitude: 15.022165,
        },
        {
            obec: 'Nový Jáchymov',
            kod: 531600,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26703,
            latitude: 49.980867,
            longitude: 13.943296,
        },
        {
            obec: 'Nový Knín',
            kod: 540901,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26203,
            latitude: 49.788108,
            longitude: 14.293661,
        },
        {
            obec: 'Nový Vestec',
            kod: 538566,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25075,
            latitude: 50.183861,
            longitude: 14.720401,
        },
        {
            obec: 'Nučice',
            kod: 533548,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.955626,
            longitude: 14.8845,
        },
        {
            obec: 'Nučice',
            kod: 531618,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25216,
            latitude: 50.018581,
            longitude: 14.230078,
        },
        {
            obec: 'Nupaky',
            kod: 564907,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.994052,
            longitude: 14.602336,
        },
        {
            obec: 'Nymburk',
            kod: 537004,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.185682,
            longitude: 15.04376,
        },
        {
            obec: 'Občov',
            kod: 513571,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.713206,
            longitude: 14.066994,
        },
        {
            obec: 'Obecnice',
            kod: 540935,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26221,
            latitude: 49.716261,
            longitude: 13.947414,
        },
        {
            obec: 'Obory',
            kod: 540943,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.680504,
            longitude: 14.216474,
        },
        {
            obec: 'Obořiště',
            kod: 540951,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26212,
            latitude: 49.742352,
            longitude: 14.151959,
        },
        {
            obec: 'Obrubce',
            kod: 570893,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.447959,
            longitude: 15.06372,
        },
        {
            obec: 'Obruby',
            kod: 536407,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29403,
            latitude: 50.460227,
            longitude: 15.082414,
        },
        {
            obec: 'Obříství',
            kod: 535133,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27742,
            latitude: 50.295983,
            longitude: 14.478492,
        },
        {
            obec: 'Odolena Voda',
            kod: 538574,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25070,
            latitude: 50.233515,
            longitude: 14.410889,
        },
        {
            obec: 'Odřepsy',
            kod: 537551,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28907,
            latitude: 50.14424,
            longitude: 15.188842,
        },
        {
            obec: 'Ohaře',
            kod: 533556,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28130,
            latitude: 50.097882,
            longitude: 15.29512,
        },
        {
            obec: 'Ohrazenice',
            kod: 540960,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26223,
            latitude: 49.78714,
            longitude: 13.959817,
        },
        {
            obec: 'Ohrobec',
            kod: 539503,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25245,
            latitude: 49.941829,
            longitude: 14.432139,
        },
        {
            obec: 'Okoř',
            kod: 571334,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25264,
            latitude: 50.162011,
            longitude: 14.258724,
        },
        {
            obec: 'Okrouhlo',
            kod: 539511,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25401,
            latitude: 49.918924,
            longitude: 14.447417,
        },
        {
            obec: 'Okřesaneč',
            kod: 534251,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.852528,
            longitude: 15.475842,
        },
        {
            obec: 'Okřínek',
            kod: 537560,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.164391,
            longitude: 15.192429,
        },
        {
            obec: 'Olbramovice',
            kod: 530344,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25901,
            latitude: 49.67328,
            longitude: 14.640953,
        },
        {
            obec: 'Oleška',
            kod: 533564,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28162,
            latitude: 49.971695,
            longitude: 14.916786,
        },
        {
            obec: 'Olešná',
            kod: 531626,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26764,
            latitude: 49.780462,
            longitude: 13.810119,
        },
        {
            obec: 'Olešná',
            kod: 542199,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26901,
            latitude: 50.128597,
            longitude: 13.695961,
        },
        {
            obec: 'Olovnice',
            kod: 532673,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27326,
            latitude: 50.234699,
            longitude: 14.240487,
        },
        {
            obec: 'Ondřejov',
            kod: 538582,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25165,
            latitude: 49.904717,
            longitude: 14.78431,
        },
        {
            obec: 'Onomyšl',
            kod: 534277,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.89933,
            longitude: 15.12436,
        },
        {
            obec: 'Opatovice I',
            kod: 530956,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.85525,
            longitude: 15.248583,
        },
        {
            obec: 'Oplany',
            kod: 571679,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.924267,
            longitude: 14.867406,
        },
        {
            obec: 'Opočnice',
            kod: 537578,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28904,
            latitude: 50.172581,
            longitude: 15.257777,
        },
        {
            obec: 'Opolany',
            kod: 537586,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28907,
            latitude: 50.130814,
            longitude: 15.217103,
        },
        {
            obec: 'Oráčov',
            kod: 542202,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27032,
            latitude: 50.111647,
            longitude: 13.549003,
        },
        {
            obec: 'Ořech',
            kod: 539520,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25225,
            latitude: 50.020209,
            longitude: 14.296653,
        },
        {
            obec: 'Osečany',
            kod: 598470,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.697825,
            longitude: 14.437729,
        },
        {
            obec: 'Oseček',
            kod: 599662,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28941,
            latitude: 50.10165,
            longitude: 15.148691,
        },
        {
            obec: 'Osek',
            kod: 531634,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26762,
            latitude: 49.819743,
            longitude: 13.858627,
        },
        {
            obec: 'Oskořínek',
            kod: 537616,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28932,
            latitude: 50.240157,
            longitude: 15.084104,
        },
        {
            obec: 'Osov',
            kod: 531642,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26725,
            latitude: 49.844478,
            longitude: 14.08402,
        },
        {
            obec: 'Ostrá',
            kod: 537624,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28922,
            latitude: 50.180537,
            longitude: 14.893003,
        },
        {
            obec: 'Ostrov',
            kod: 599395,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25706,
            latitude: 49.665402,
            longitude: 14.851197,
        },
        {
            obec: 'Ostrov',
            kod: 598372,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.611219,
            longitude: 14.014609,
        },
        {
            obec: 'Ostředek',
            kod: 530352,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25724,
            latitude: 49.834893,
            longitude: 14.8324,
        },
        {
            obec: 'Otmíče',
            kod: 534111,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.869145,
            longitude: 13.947252,
        },
        {
            obec: 'Otročiněves',
            kod: 531669,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26703,
            latitude: 49.980598,
            longitude: 13.978869,
        },
        {
            obec: 'Otvovice',
            kod: 532681,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27327,
            latitude: 50.211664,
            longitude: 14.27303,
        },
        {
            obec: 'Ouběnice',
            kod: 541010,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.717747,
            longitude: 14.159939,
        },
        {
            obec: 'Ovčáry',
            kod: 533572,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.062882,
            longitude: 15.239607,
        },
        {
            obec: 'Ovčáry',
            kod: 535141,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27714,
            latitude: 50.253151,
            longitude: 14.613962,
        },
        {
            obec: 'Paběnice',
            kod: 531278,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28543,
            latitude: 49.84174,
            longitude: 15.302429,
        },
        {
            obec: 'Páleč',
            kod: 564192,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27371,
            latitude: 50.312029,
            longitude: 14.049685,
        },
        {
            obec: 'Panenské Břežany',
            kod: 538604,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25070,
            latitude: 50.213867,
            longitude: 14.44021,
        },
        {
            obec: 'Panoší Újezd',
            kod: 542211,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27021,
            latitude: 50.036103,
            longitude: 13.716918,
        },
        {
            obec: 'Pašinka',
            kod: 513423,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.996452,
            longitude: 15.186297,
        },
        {
            obec: 'Pátek',
            kod: 537632,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.165617,
            longitude: 15.158562,
        },
        {
            obec: 'Pavlíkov',
            kod: 544248,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27021,
            latitude: 50.056094,
            longitude: 13.73647,
        },
        {
            obec: 'Pavlov',
            kod: 532711,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.096481,
            longitude: 14.167154,
        },
        {
            obec: 'Pavlovice',
            kod: 533076,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.715216,
            longitude: 14.933216,
        },
        {
            obec: 'Pečice',
            kod: 541028,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26232,
            latitude: 49.600518,
            longitude: 14.105591,
        },
        {
            obec: 'Pěčice',
            kod: 571032,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29446,
            latitude: 50.35125,
            longitude: 15.004099,
        },
        {
            obec: 'Pečky',
            kod: 537641,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.090527,
            longitude: 15.030275,
        },
        {
            obec: 'Pertoltice',
            kod: 534307,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.75491,
            longitude: 15.181716,
        },
        {
            obec: 'Pětihosty',
            kod: 529656,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25167,
            latitude: 49.886462,
            longitude: 14.705522,
        },
        {
            obec: 'Pětikozly',
            kod: 598241,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29430,
            latitude: 50.411512,
            longitude: 14.811952,
        },
        {
            obec: 'Petkovy',
            kod: 536431,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.413286,
            longitude: 15.076925,
        },
        {
            obec: 'Petroupim',
            kod: 530409,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.808356,
            longitude: 14.752698,
        },
        {
            obec: 'Petrov',
            kod: 539546,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25281,
            latitude: 49.886498,
            longitude: 14.433839,
        },
        {
            obec: 'Petrovice',
            kod: 541044,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26255,
            latitude: 49.554366,
            longitude: 14.337472,
        },
        {
            obec: 'Petrovice',
            kod: 542229,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.067363,
            longitude: 13.63928,
        },
        {
            obec: 'Petrovice I',
            kod: 534315,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.811063,
            longitude: 15.297295,
        },
        {
            obec: 'Petrovice II',
            kod: 534323,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.81004,
            longitude: 15.063514,
        },
        {
            obec: 'Petříkov',
            kod: 538612,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25169,
            latitude: 49.925301,
            longitude: 14.617066,
        },
        {
            obec: 'Pchery',
            kod: 532720,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27308,
            latitude: 50.193882,
            longitude: 14.117927,
        },
        {
            obec: 'Pičín',
            kod: 541052,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26225,
            latitude: 49.745546,
            longitude: 14.057909,
        },
        {
            obec: 'Písková Lhota',
            kod: 536440,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29431,
            latitude: 50.367841,
            longitude: 14.874736,
        },
        {
            obec: 'Písková Lhota',
            kod: 537659,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.131421,
            longitude: 15.065865,
        },
        {
            obec: 'Písty',
            kod: 537667,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28913,
            latitude: 50.164646,
            longitude: 15.001364,
        },
        {
            obec: 'Plaňany',
            kod: 533581,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28104,
            latitude: 50.050204,
            longitude: 15.029546,
        },
        {
            obec: 'Plazy',
            kod: 536458,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.414136,
            longitude: 14.975134,
        },
        {
            obec: 'Pletený Újezd',
            kod: 532738,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.109433,
            longitude: 14.116178,
        },
        {
            obec: 'Plchov',
            kod: 564125,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27375,
            latitude: 50.255276,
            longitude: 13.986724,
        },
        {
            obec: 'Plužná',
            kod: 565580,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29423,
            latitude: 50.473807,
            longitude: 14.804269,
        },
        {
            obec: 'Pňov-Předhradí',
            kod: 537675,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28941,
            latitude: 50.086521,
            longitude: 15.147597,
        },
        {
            obec: 'Počaply',
            kod: 564214,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.538772,
            longitude: 13.983561,
        },
        {
            obec: 'Počepice',
            kod: 541087,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26255,
            latitude: 49.596115,
            longitude: 14.380945,
        },
        {
            obec: 'Podbrdy',
            kod: 534285,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.863079,
            longitude: 14.126698,
        },
        {
            obec: 'Poděbrady',
            kod: 537683,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.142525,
            longitude: 15.118988,
        },
        {
            obec: 'Podlesí',
            kod: 564486,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.690395,
            longitude: 13.981947,
        },
        {
            obec: 'Podlešín',
            kod: 532754,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27325,
            latitude: 50.221126,
            longitude: 14.160368,
        },
        {
            obec: 'Podluhy',
            kod: 531685,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.815724,
            longitude: 13.91363,
        },
        {
            obec: 'Podmoky',
            kod: 599590,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28904,
            latitude: 50.191564,
            longitude: 15.232291,
        },
        {
            obec: 'Podolanka',
            kod: 538621,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25073,
            latitude: 50.159219,
            longitude: 14.601033,
        },
        {
            obec: 'Podveky',
            kod: 528196,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.825146,
            longitude: 14.994103,
        },
        {
            obec: 'Pohoří',
            kod: 539562,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25401,
            latitude: 49.900021,
            longitude: 14.525125,
        },
        {
            obec: 'Pochvalov',
            kod: 542237,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27055,
            latitude: 50.227542,
            longitude: 13.794371,
        },
        {
            obec: 'Polepy',
            kod: 533599,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.004771,
            longitude: 15.206086,
        },
        {
            obec: 'Polerady',
            kod: 538639,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.200671,
            longitude: 14.593492,
        },
        {
            obec: 'Polní Chrčice',
            kod: 513237,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.110106,
            longitude: 15.296802,
        },
        {
            obec: 'Polní Voděrady',
            kod: 513148,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 49.992409,
            longitude: 15.095338,
        },
        {
            obec: 'Popovice',
            kod: 532649,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25701,
            latitude: 49.695492,
            longitude: 14.75256,
        },
        {
            obec: 'Popovičky',
            kod: 599221,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.962644,
            longitude: 14.597904,
        },
        {
            obec: 'Poříčany',
            kod: 537705,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28914,
            latitude: 50.108165,
            longitude: 14.918285,
        },
        {
            obec: 'Poříčí nad Sázavou',
            kod: 530441,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25721,
            latitude: 49.839058,
            longitude: 14.674564,
        },
        {
            obec: 'Postřižín',
            kod: 538647,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25070,
            latitude: 50.233091,
            longitude: 14.386813,
        },
        {
            obec: 'Postupice',
            kod: 530450,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25701,
            latitude: 49.728144,
            longitude: 14.777499,
        },
        {
            obec: 'Poštovice',
            kod: 564087,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27372,
            latitude: 50.312793,
            longitude: 14.137949,
        },
        {
            obec: 'Potěhy',
            kod: 534331,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28563,
            latitude: 49.86946,
            longitude: 15.4202,
        },
        {
            obec: 'Pozdeň',
            kod: 532762,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27376,
            latitude: 50.242157,
            longitude: 13.943656,
        },
        {
            obec: 'Praskolesy',
            kod: 531693,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26754,
            latitude: 49.865986,
            longitude: 13.933537,
        },
        {
            obec: 'Pravonín',
            kod: 530476,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25709,
            latitude: 49.635687,
            longitude: 14.943477,
        },
        {
            obec: 'Prodašice',
            kod: 570753,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.350064,
            longitude: 15.116768,
        },
        {
            obec: 'Prosenická Lhota',
            kod: 541133,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.689735,
            longitude: 14.482797,
        },
        {
            obec: 'Průhonice',
            kod: 539571,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25243,
            latitude: 50.001815,
            longitude: 14.560406,
        },
        {
            obec: 'Prusice',
            kod: 564788,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.968457,
            longitude: 14.877965,
        },
        {
            obec: 'Předboj',
            kod: 538655,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25072,
            latitude: 50.225492,
            longitude: 14.476835,
        },
        {
            obec: 'Předměřice nad Jizerou',
            kod: 536491,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29474,
            latitude: 50.25558,
            longitude: 14.781377,
        },
        {
            obec: 'Přehvozdí',
            kod: 513369,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 50.027508,
            longitude: 14.840073,
        },
        {
            obec: 'Přelíc',
            kod: 532771,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27305,
            latitude: 50.20572,
            longitude: 14.045964,
        },
        {
            obec: 'Přepeře',
            kod: 570923,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.467724,
            longitude: 15.101992,
        },
        {
            obec: 'Přerov nad Labem',
            kod: 537721,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28916,
            latitude: 50.16037,
            longitude: 14.825129,
        },
        {
            obec: 'Přerubenice',
            kod: 598577,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.217271,
            longitude: 13.841132,
        },
        {
            obec: 'Přestavlky u Čerčan',
            kod: 530492,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25723,
            latitude: 49.8531,
            longitude: 14.74423,
        },
        {
            obec: 'Přezletice',
            kod: 538671,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25073,
            latitude: 50.155303,
            longitude: 14.575506,
        },
        {
            obec: 'Příbram',
            kod: 539911,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.685532,
            longitude: 13.999045,
        },
        {
            obec: 'Příčina',
            kod: 542253,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.065833,
            longitude: 13.667379,
        },
        {
            obec: 'Příčovy',
            kod: 513547,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.672874,
            longitude: 14.389683,
        },
        {
            obec: 'Přílepy',
            kod: 565261,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27001,
            latitude: 50.123281,
            longitude: 13.632702,
        },
        {
            obec: 'Přistoupim',
            kod: 513393,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.054216,
            longitude: 14.878688,
        },
        {
            obec: 'Přišimasy',
            kod: 533611,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.049615,
            longitude: 14.764,
        },
        {
            obec: 'Psáry',
            kod: 539597,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25244,
            latitude: 49.93632,
            longitude: 14.512867,
        },
        {
            obec: 'Psáře',
            kod: 530514,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.755783,
            longitude: 14.96193,
        },
        {
            obec: 'Pšovlky',
            kod: 542270,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27031,
            latitude: 50.107947,
            longitude: 13.594013,
        },
        {
            obec: 'Ptice',
            kod: 532789,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25218,
            latitude: 50.05287,
            longitude: 14.166076,
        },
        {
            obec: 'Ptýrov',
            kod: 571938,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.504933,
            longitude: 14.947236,
        },
        {
            obec: 'Pustověty',
            kod: 542288,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.055708,
            longitude: 13.814115,
        },
        {
            obec: 'Pyšely',
            kod: 538680,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25167,
            latitude: 49.876884,
            longitude: 14.677239,
        },
        {
            obec: 'Rabakov',
            kod: 565652,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.38575,
            longitude: 15.101648,
        },
        {
            obec: 'Rabyně',
            kod: 530522,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25208,
            latitude: 49.81429,
            longitude: 14.436202,
        },
        {
            obec: 'Račice',
            kod: 599760,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27024,
            latitude: 50.024833,
            longitude: 13.923039,
        },
        {
            obec: 'Radějovice',
            kod: 538698,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25168,
            latitude: 49.947328,
            longitude: 14.564877,
        },
        {
            obec: 'Radětice',
            kod: 564389,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.639925,
            longitude: 14.078828,
        },
        {
            obec: 'Radíč',
            kod: 598488,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.714764,
            longitude: 14.417497,
        },
        {
            obec: 'Radim',
            kod: 533629,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28103,
            latitude: 50.070165,
            longitude: 15.012548,
        },
        {
            obec: 'Radonice',
            kod: 538701,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25073,
            latitude: 50.143163,
            longitude: 14.610368,
        },
        {
            obec: 'Radošovice',
            kod: 530531,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.741095,
            longitude: 14.867658,
        },
        {
            obec: 'Radovesnice I',
            kod: 533637,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.010493,
            longitude: 15.151007,
        },
        {
            obec: 'Radovesnice II',
            kod: 533645,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28128,
            latitude: 50.104165,
            longitude: 15.367826,
        },
        {
            obec: 'Rakovník',
            kod: 541656,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26901,
            latitude: 50.106223,
            longitude: 13.739762,
        },
        {
            obec: 'Rašovice',
            kod: 534340,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.882642,
            longitude: 15.102485,
        },
        {
            obec: 'Rataje',
            kod: 530549,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.704164,
            longitude: 14.97055,
        },
        {
            obec: 'Rataje nad Sázavou',
            kod: 534358,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28507,
            latitude: 49.841949,
            longitude: 14.956827,
        },
        {
            obec: 'Ratboř',
            kod: 533653,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28141,
            latitude: 49.978185,
            longitude: 15.159345,
        },
        {
            obec: 'Ratenice',
            kod: 537748,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.09171,
            longitude: 15.059361,
        },
        {
            obec: 'Ratměřice',
            kod: 532550,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25703,
            latitude: 49.643355,
            longitude: 14.756277,
        },
        {
            obec: 'Roblín',
            kod: 571318,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25226,
            latitude: 49.961936,
            longitude: 14.248667,
        },
        {
            obec: 'Rohatsko',
            kod: 565636,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.440386,
            longitude: 15.104846,
        },
        {
            obec: 'Rohozec',
            kod: 526622,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.97612,
            longitude: 15.384746,
        },
        {
            obec: 'Rokytá',
            kod: 529605,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29501,
            latitude: 50.563767,
            longitude: 14.869309,
        },
        {
            obec: 'Rokytovec',
            kod: 571148,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29430,
            latitude: 50.406627,
            longitude: 14.830785,
        },
        {
            obec: 'Rosovice',
            kod: 541206,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26211,
            latitude: 49.756754,
            longitude: 14.109079,
        },
        {
            obec: 'Rostoklaty',
            kod: 533661,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28171,
            latitude: 50.074329,
            longitude: 14.801559,
        },
        {
            obec: 'Roztoky',
            kod: 539627,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25263,
            latitude: 50.158516,
            longitude: 14.397712,
        },
        {
            obec: 'Roztoky',
            kod: 598526,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.02682,
            longitude: 13.867976,
        },
        {
            obec: 'Rožďalovice',
            kod: 537756,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28934,
            latitude: 50.304864,
            longitude: 15.169724,
        },
        {
            obec: 'Rožmitál pod Třemšínem',
            kod: 541231,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.601864,
            longitude: 13.865835,
        },
        {
            obec: 'Rpety',
            kod: 531715,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.828376,
            longitude: 13.938596,
        },
        {
            obec: 'Ruda',
            kod: 542326,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27101,
            latitude: 50.14089,
            longitude: 13.875801,
        },
        {
            obec: 'Rudná',
            kod: 531723,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25219,
            latitude: 50.035128,
            longitude: 14.234454,
        },
        {
            obec: 'Rybníky',
            kod: 541257,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.752744,
            longitude: 14.206005,
        },
        {
            obec: 'Rynholec',
            kod: 542334,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27062,
            latitude: 50.138031,
            longitude: 13.922328,
        },
        {
            obec: 'Řehenice',
            kod: 538710,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25167,
            latitude: 49.869849,
            longitude: 14.650331,
        },
        {
            obec: 'Řendějov',
            kod: 534366,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.767946,
            longitude: 15.080471,
        },
        {
            obec: 'Řepín',
            kod: 535168,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27733,
            latitude: 50.366642,
            longitude: 14.634675,
        },
        {
            obec: 'Řepov',
            kod: 536580,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.402437,
            longitude: 14.957103,
        },
        {
            obec: 'Řeřichy',
            kod: 565504,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.079276,
            longitude: 13.585937,
        },
        {
            obec: 'Řevnice',
            kod: 539643,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25230,
            latitude: 49.914064,
            longitude: 14.235875,
        },
        {
            obec: 'Řevničov',
            kod: 542351,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.184992,
            longitude: 13.808344,
        },
        {
            obec: 'Říčany',
            kod: 538728,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.991778,
            longitude: 14.654376,
        },
        {
            obec: 'Řimovice',
            kod: 532941,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.696448,
            longitude: 14.943106,
        },
        {
            obec: 'Řisuty',
            kod: 532797,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27378,
            latitude: 50.216592,
            longitude: 14.005087,
        },
        {
            obec: 'Řitka',
            kod: 539651,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25203,
            latitude: 49.894619,
            longitude: 14.299341,
        },
        {
            obec: 'Řitonice',
            kod: 565661,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.408309,
            longitude: 15.108053,
        },
        {
            obec: 'Sádek',
            kod: 541273,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.733317,
            longitude: 13.98323,
        },
        {
            obec: 'Sadská',
            kod: 537764,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28912,
            latitude: 50.136066,
            longitude: 14.98644,
        },
        {
            obec: 'Samopše',
            kod: 534374,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.861533,
            longitude: 14.929498,
        },
        {
            obec: 'Sány',
            kod: 537772,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28906,
            latitude: 50.124752,
            longitude: 15.247746,
        },
        {
            obec: 'Sázava',
            kod: 534382,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.871745,
            longitude: 14.896842,
        },
        {
            obec: 'Sazená',
            kod: 532801,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27324,
            latitude: 50.305277,
            longitude: 14.287363,
        },
        {
            obec: 'Sedlčany',
            kod: 541281,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.660677,
            longitude: 14.426744,
        },
        {
            obec: 'Sedlec',
            kod: 529591,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29471,
            latitude: 50.300839,
            longitude: 14.79583,
        },
        {
            obec: 'Sedlec',
            kod: 598283,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25065,
            latitude: 50.186836,
            longitude: 14.457979,
        },
        {
            obec: 'Sedlec-Prčice',
            kod: 530573,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25791,
            latitude: 49.573311,
            longitude: 14.533106,
        },
        {
            obec: 'Sedlice',
            kod: 564630,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.621869,
            longitude: 13.880889,
        },
        {
            obec: 'Seletice',
            kod: 534757,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28934,
            latitude: 50.320442,
            longitude: 15.097101,
        },
        {
            obec: 'Semčice',
            kod: 536610,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29446,
            latitude: 50.368272,
            longitude: 15.007012,
        },
        {
            obec: 'Semice',
            kod: 537781,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28917,
            latitude: 50.164267,
            longitude: 14.871579,
        },
        {
            obec: 'Semtěš',
            kod: 530859,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.960036,
            longitude: 15.514411,
        },
        {
            obec: 'Senec',
            kod: 542369,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27036,
            latitude: 50.066842,
            longitude: 13.706979,
        },
        {
            obec: 'Senice',
            kod: 537799,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.184332,
            longitude: 15.207677,
        },
        {
            obec: 'Senohraby',
            kod: 538752,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25166,
            latitude: 49.895504,
            longitude: 14.718199,
        },
        {
            obec: 'Senomaty',
            kod: 542377,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27031,
            latitude: 50.098281,
            longitude: 13.653828,
        },
        {
            obec: 'Sezemice',
            kod: 599573,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29411,
            latitude: 50.58565,
            longitude: 15.005199,
        },
        {
            obec: 'Schořov',
            kod: 531383,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.858229,
            longitude: 15.380002,
        },
        {
            obec: 'Sibřina',
            kod: 538761,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25084,
            latitude: 50.056746,
            longitude: 14.669513,
        },
        {
            obec: 'Skalsko',
            kod: 536636,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29426,
            latitude: 50.427224,
            longitude: 14.759137,
        },
        {
            obec: 'Skorkov',
            kod: 557030,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29474,
            latitude: 50.231801,
            longitude: 14.749378,
        },
        {
            obec: 'Skryje',
            kod: 542385,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27042,
            latitude: 49.964953,
            longitude: 13.764601,
        },
        {
            obec: 'Skřipel',
            kod: 533963,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.842088,
            longitude: 14.067132,
        },
        {
            obec: 'Skuhrov',
            kod: 531740,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26727,
            latitude: 49.87342,
            longitude: 14.146829,
        },
        {
            obec: 'Skvrňov',
            kod: 533696,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28144,
            latitude: 49.897204,
            longitude: 15.001923,
        },
        {
            obec: 'Slabce',
            kod: 542415,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27041,
            latitude: 50.000175,
            longitude: 13.714888,
        },
        {
            obec: 'Slaný',
            kod: 532819,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27401,
            latitude: 50.230562,
            longitude: 14.087044,
        },
        {
            obec: 'Slapy',
            kod: 539660,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25208,
            latitude: 49.815412,
            longitude: 14.396225,
        },
        {
            obec: 'Slatina',
            kod: 532827,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27326,
            latitude: 50.224262,
            longitude: 14.220845,
        },
        {
            obec: 'Slavošov',
            kod: 534391,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.762279,
            longitude: 15.152072,
        },
        {
            obec: 'Sloveč',
            kod: 537802,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28903,
            latitude: 50.229948,
            longitude: 15.326892,
        },
        {
            obec: 'Slověnice',
            kod: 532231,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.757388,
            longitude: 14.885194,
        },
        {
            obec: 'Sluhy',
            kod: 538779,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.192538,
            longitude: 14.557782,
        },
        {
            obec: 'Sluštice',
            kod: 538787,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25084,
            latitude: 50.039141,
            longitude: 14.685507,
        },
        {
            obec: 'Smečno',
            kod: 532835,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27305,
            latitude: 50.188542,
            longitude: 14.040474,
        },
        {
            obec: 'Smilkov',
            kod: 530611,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25789,
            latitude: 49.602598,
            longitude: 14.617508,
        },
        {
            obec: 'Smilovice',
            kod: 536652,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29442,
            latitude: 50.306626,
            longitude: 14.961154,
        },
        {
            obec: 'Smilovice',
            kod: 565407,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.247047,
            longitude: 13.807807,
        },
        {
            obec: 'Smolotely',
            kod: 541311,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26263,
            latitude: 49.620697,
            longitude: 14.135669,
        },
        {
            obec: 'Snět',
            kod: 532436,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.626545,
            longitude: 15.226922,
        },
        {
            obec: 'Soběhrdy',
            kod: 530638,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.821122,
            longitude: 14.734312,
        },
        {
            obec: 'Soběšín',
            kod: 534293,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.791162,
            longitude: 14.955973,
        },
        {
            obec: 'Sojovice',
            kod: 536661,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29475,
            latitude: 50.222234,
            longitude: 14.762139,
        },
        {
            obec: 'Sokoleč',
            kod: 537811,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.098519,
            longitude: 15.107025,
        },
        {
            obec: 'Solenice',
            kod: 541320,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26263,
            latitude: 49.617671,
            longitude: 14.195881,
        },
        {
            obec: 'Souňov',
            kod: 531065,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.880122,
            longitude: 15.315664,
        },
        {
            obec: 'Soutice',
            kod: 599387,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25771,
            latitude: 49.726208,
            longitude: 15.053023,
        },
        {
            obec: 'Sovínky',
            kod: 565539,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29429,
            latitude: 50.373175,
            longitude: 14.787182,
        },
        {
            obec: 'Spomyšl',
            kod: 535192,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27705,
            latitude: 50.33553,
            longitude: 14.360631,
        },
        {
            obec: 'Srbeč',
            kod: 542431,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27065,
            latitude: 50.224713,
            longitude: 13.883458,
        },
        {
            obec: 'Srbsko',
            kod: 531758,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26718,
            latitude: 49.937271,
            longitude: 14.136025,
        },
        {
            obec: 'Staňkovice',
            kod: 534412,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.875486,
            longitude: 15.015192,
        },
        {
            obec: 'Stará Huť',
            kod: 541338,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26202,
            latitude: 49.782357,
            longitude: 14.197461,
        },
        {
            obec: 'Stará Lysá',
            kod: 537837,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28926,
            latitude: 50.224749,
            longitude: 14.799247,
        },
        {
            obec: 'Starkoč',
            kod: 530875,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.937353,
            longitude: 15.507667,
        },
        {
            obec: 'Starosedlský Hrádek',
            kod: 564583,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.57629,
            longitude: 14.008329,
        },
        {
            obec: 'Starý Kolín',
            kod: 533700,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28123,
            latitude: 50.009872,
            longitude: 15.293985,
        },
        {
            obec: 'Starý Vestec',
            kod: 534889,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28916,
            latitude: 50.144821,
            longitude: 14.846456,
        },
        {
            obec: 'Stašov',
            kod: 531766,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.883446,
            longitude: 13.95763,
        },
        {
            obec: 'Statenice',
            kod: 539686,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25262,
            latitude: 50.142677,
            longitude: 14.318642,
        },
        {
            obec: 'Stehelčeves',
            kod: 532851,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27342,
            latitude: 50.171748,
            longitude: 14.190956,
        },
        {
            obec: 'Stochov',
            kod: 532860,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27303,
            latitude: 50.146411,
            longitude: 13.96356,
        },
        {
            obec: 'Stradonice',
            kod: 599441,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27371,
            latitude: 50.291461,
            longitude: 14.047036,
        },
        {
            obec: 'Straky',
            kod: 537853,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28925,
            latitude: 50.23368,
            longitude: 14.959345,
        },
        {
            obec: 'Strančice',
            kod: 538809,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25163,
            latitude: 49.948217,
            longitude: 14.677553,
        },
        {
            obec: 'Stránka',
            kod: 531731,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27735,
            latitude: 50.423083,
            longitude: 14.661242,
        },
        {
            obec: 'Stranný',
            kod: 599379,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25756,
            latitude: 49.753854,
            longitude: 14.494082,
        },
        {
            obec: 'Strašnov',
            kod: 536709,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29431,
            latitude: 50.351613,
            longitude: 14.889289,
        },
        {
            obec: 'Stratov',
            kod: 537861,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28922,
            latitude: 50.19305,
            longitude: 14.90991,
        },
        {
            obec: 'Strážiště',
            kod: 571881,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29413,
            latitude: 50.592452,
            longitude: 14.929303,
        },
        {
            obec: 'Strenice',
            kod: 536717,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29430,
            latitude: 50.392653,
            longitude: 14.82316,
        },
        {
            obec: 'Strojetice',
            kod: 599361,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25765,
            latitude: 49.651638,
            longitude: 15.066126,
        },
        {
            obec: 'Struhařov',
            kod: 530689,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.765636,
            longitude: 14.762317,
        },
        {
            obec: 'Struhařov',
            kod: 538825,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25164,
            latitude: 49.952026,
            longitude: 14.74454,
        },
        {
            obec: 'Středokluky',
            kod: 539708,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25268,
            latitude: 50.132287,
            longitude: 14.234301,
        },
        {
            obec: 'Střemy',
            kod: 535214,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27734,
            latitude: 50.384262,
            longitude: 14.565624,
        },
        {
            obec: 'Střezimíř',
            kod: 530701,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25787,
            latitude: 49.532109,
            longitude: 14.611434,
        },
        {
            obec: 'Stříbrná Skalice',
            kod: 533718,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28167,
            latitude: 49.897765,
            longitude: 14.846268,
        },
        {
            obec: 'Studeněves',
            kod: 551457,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27379,
            latitude: 50.224222,
            longitude: 14.044495,
        },
        {
            obec: 'Studený',
            kod: 530719,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.607512,
            longitude: 15.127817,
        },
        {
            obec: 'Sudějov',
            kod: 531391,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.860798,
            longitude: 15.103948,
        },
        {
            obec: 'Sudoměř',
            kod: 565920,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29425,
            latitude: 50.445088,
            longitude: 14.739481,
        },
        {
            obec: 'Sudovo Hlavno',
            kod: 571954,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29476,
            latitude: 50.262851,
            longitude: 14.685423,
        },
        {
            obec: 'Suchdol',
            kod: 534439,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28502,
            latitude: 49.95257,
            longitude: 15.166616,
        },
        {
            obec: 'Suchodol',
            kod: 541371,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.724148,
            longitude: 14.082522,
        },
        {
            obec: 'Suchomasty',
            kod: 531782,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26722,
            latitude: 49.895459,
            longitude: 14.056714,
        },
        {
            obec: 'Sukorady',
            kod: 536768,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.426341,
            longitude: 15.029101,
        },
        {
            obec: 'Sulice',
            kod: 538833,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25168,
            latitude: 49.925727,
            longitude: 14.556739,
        },
        {
            obec: 'Svárov',
            kod: 599433,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.062028,
            longitude: 14.15064,
        },
        {
            obec: 'Svatá',
            kod: 531791,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.939239,
            longitude: 13.961726,
        },
        {
            obec: 'Svaté Pole',
            kod: 541389,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.751419,
            longitude: 14.169084,
        },
        {
            obec: 'Svatý Jan',
            kod: 541397,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26256,
            latitude: 49.636938,
            longitude: 14.311096,
        },
        {
            obec: 'Svatý Jan pod Skalou',
            kod: 531804,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 49.969011,
            longitude: 14.133272,
        },
        {
            obec: 'Svatý Mikuláš',
            kod: 534196,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.991014,
            longitude: 15.350597,
        },
        {
            obec: 'Svémyslice',
            kod: 598305,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25091,
            latitude: 50.146005,
            longitude: 14.648214,
        },
        {
            obec: 'Světice',
            kod: 538841,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.970179,
            longitude: 14.665911,
        },
        {
            obec: 'Svinaře',
            kod: 531812,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26728,
            latitude: 49.891728,
            longitude: 14.1844,
        },
        {
            obec: 'Svinařov',
            kod: 532908,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27305,
            latitude: 50.181412,
            longitude: 14.050377,
        },
        {
            obec: 'Svojetice',
            kod: 538850,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25162,
            latitude: 49.971526,
            longitude: 14.7386,
        },
        {
            obec: 'Svojetín',
            kod: 542458,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27004,
            latitude: 50.190121,
            longitude: 13.621021,
        },
        {
            obec: 'Svojšice',
            kod: 533726,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28107,
            latitude: 50.003239,
            longitude: 15.043205,
        },
        {
            obec: 'Svojšice',
            kod: 564273,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.572827,
            longitude: 14.042977,
        },
        {
            obec: 'Svrkyně',
            kod: 571342,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25264,
            latitude: 50.171469,
            longitude: 14.294955,
        },
        {
            obec: 'Sýkořice',
            kod: 542466,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27024,
            latitude: 50.034689,
            longitude: 13.931831,
        },
        {
            obec: 'Šanov',
            kod: 542474,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27031,
            latitude: 50.087735,
            longitude: 13.631307,
        },
        {
            obec: 'Šebestěnice',
            kod: 531421,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.829543,
            longitude: 15.365413,
        },
        {
            obec: 'Šestajovice',
            kod: 538876,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25092,
            latitude: 50.10852,
            longitude: 14.681812,
        },
        {
            obec: 'Šetějovice',
            kod: 532509,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.645276,
            longitude: 15.222583,
        },
        {
            obec: 'Šípy',
            kod: 598496,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27034,
            latitude: 50.014425,
            longitude: 13.616563,
        },
        {
            obec: 'Škvorec',
            kod: 538884,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25083,
            latitude: 50.046972,
            longitude: 14.730519,
        },
        {
            obec: 'Šlapanice',
            kod: 532916,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27372,
            latitude: 50.314773,
            longitude: 14.111319,
        },
        {
            obec: 'Štěchovice',
            kod: 539732,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25207,
            latitude: 49.851197,
            longitude: 14.405534,
        },
        {
            obec: 'Štětkovice',
            kod: 541419,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26401,
            latitude: 49.670811,
            longitude: 14.506319,
        },
        {
            obec: 'Štíhlice',
            kod: 571644,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 50.005666,
            longitude: 14.780051,
        },
        {
            obec: 'Štipoklasy',
            kod: 530930,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.824707,
            longitude: 15.213391,
        },
        {
            obec: 'Švihov',
            kod: 565326,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27033,
            latitude: 50.109935,
            longitude: 13.5644,
        },
        {
            obec: 'Tachlovice',
            kod: 531821,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25217,
            latitude: 50.014555,
            longitude: 14.240788,
        },
        {
            obec: 'Tatce',
            kod: 537888,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.091224,
            longitude: 14.977345,
        },
        {
            obec: 'Tehov',
            kod: 530751,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.735542,
            longitude: 14.966838,
        },
        {
            obec: 'Tehov',
            kod: 538892,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25101,
            latitude: 49.97162,
            longitude: 14.693897,
        },
        {
            obec: 'Tehovec',
            kod: 599719,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25162,
            latitude: 49.981597,
            longitude: 14.730399,
        },
        {
            obec: 'Těchařovice',
            kod: 529664,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.599507,
            longitude: 14.048897,
        },
        {
            obec: 'Teplýšovice',
            kod: 530760,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.806208,
            longitude: 14.801688,
        },
        {
            obec: 'Tetín',
            kod: 531839,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 49.94942,
            longitude: 14.102357,
        },
        {
            obec: 'Tichonice',
            kod: 530778,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25763,
            latitude: 49.777164,
            longitude: 14.992382,
        },
        {
            obec: 'Tisem',
            kod: 532592,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25756,
            latitude: 49.755736,
            longitude: 14.608061,
        },
        {
            obec: 'Tismice',
            kod: 533734,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.056273,
            longitude: 14.821163,
        },
        {
            obec: 'Tišice',
            kod: 535222,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27715,
            latitude: 50.269904,
            longitude: 14.554222,
        },
        {
            obec: 'Tlustice',
            kod: 531847,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26801,
            latitude: 49.850393,
            longitude: 13.885465,
        },
        {
            obec: 'Tmaň',
            kod: 531855,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26721,
            latitude: 49.907174,
            longitude: 14.033932,
        },
        {
            obec: 'Točník',
            kod: 534463,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.8855,
            longitude: 13.882878,
        },
        {
            obec: 'Tochovice',
            kod: 541427,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26281,
            latitude: 49.59559,
            longitude: 13.992506,
        },
        {
            obec: 'Tomice',
            kod: 532541,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25768,
            latitude: 49.645143,
            longitude: 15.157197,
        },
        {
            obec: 'Toušice',
            kod: 533742,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.987988,
            longitude: 15.006578,
        },
        {
            obec: 'Trhové Dušníky',
            kod: 598429,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26101,
            latitude: 49.71355,
            longitude: 14.012935,
        },
        {
            obec: 'Trhový Štěpánov',
            kod: 530816,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25763,
            latitude: 49.711576,
            longitude: 15.013668,
        },
        {
            obec: 'Trnová',
            kod: 598313,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.915541,
            longitude: 14.357824,
        },
        {
            obec: 'Trubín',
            kod: 533106,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26701,
            latitude: 49.94378,
            longitude: 14.002294,
        },
        {
            obec: 'Trubská',
            kod: 531880,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 49.95498,
            longitude: 13.993555,
        },
        {
            obec: 'Třebestovice',
            kod: 537896,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28912,
            latitude: 50.124705,
            longitude: 14.958358,
        },
        {
            obec: 'Třebešice',
            kod: 532304,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.775348,
            longitude: 14.831949,
        },
        {
            obec: 'Třebešice',
            kod: 530981,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.927948,
            longitude: 15.337909,
        },
        {
            obec: 'Třebětín',
            kod: 530972,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.77084,
            longitude: 15.269464,
        },
        {
            obec: 'Třebichovice',
            kod: 532959,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27306,
            latitude: 50.190464,
            longitude: 14.079146,
        },
        {
            obec: 'Třebíz',
            kod: 532967,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27375,
            latitude: 50.269721,
            longitude: 13.990901,
        },
        {
            obec: 'Třeboc',
            kod: 542504,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27054,
            latitude: 50.214417,
            longitude: 13.752838,
        },
        {
            obec: 'Třebonín',
            kod: 571741,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28544,
            latitude: 49.869804,
            longitude: 15.313026,
        },
        {
            obec: 'Třebotov',
            kod: 539759,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25226,
            latitude: 49.972514,
            longitude: 14.291274,
        },
        {
            obec: 'Třebovle',
            kod: 533751,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 50.027347,
            longitude: 14.961866,
        },
        {
            obec: 'Třebsko',
            kod: 541451,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.625764,
            longitude: 13.96765,
        },
        {
            obec: 'Třebusice',
            kod: 532975,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27341,
            latitude: 50.200879,
            longitude: 14.180749,
        },
        {
            obec: 'Tři Dvory',
            kod: 533769,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.032056,
            longitude: 15.256593,
        },
        {
            obec: 'Třtice',
            kod: 542512,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27101,
            latitude: 50.185105,
            longitude: 13.863591,
        },
        {
            obec: 'Tuhaň',
            kod: 531561,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27741,
            latitude: 50.295483,
            longitude: 14.517383,
        },
        {
            obec: 'Tuchlovice',
            kod: 532983,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27302,
            latitude: 50.138132,
            longitude: 13.991262,
        },
        {
            obec: 'Tuchoměřice',
            kod: 539767,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25267,
            latitude: 50.135539,
            longitude: 14.282293,
        },
        {
            obec: 'Tuchoraz',
            kod: 533777,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.048724,
            longitude: 14.849303,
        },
        {
            obec: 'Tuklaty',
            kod: 533785,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25082,
            latitude: 50.084879,
            longitude: 14.769602,
        },
        {
            obec: 'Tupadly',
            kod: 534480,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28563,
            latitude: 49.869103,
            longitude: 15.40356,
        },
        {
            obec: 'Tupadly',
            kod: 529583,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.441913,
            longitude: 14.475036,
        },
        {
            obec: 'Tursko',
            kod: 539775,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25265,
            latitude: 50.19134,
            longitude: 14.32199,
        },
        {
            obec: 'Tuřany',
            kod: 532177,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27379,
            latitude: 50.232342,
            longitude: 14.029138,
        },
        {
            obec: 'Tuřice',
            kod: 570982,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29474,
            latitude: 50.248907,
            longitude: 14.769264,
        },
        {
            obec: 'Tušovice',
            kod: 598330,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.575713,
            longitude: 14.030638,
        },
        {
            obec: 'Týnec nad Labem',
            kod: 533807,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28126,
            latitude: 50.042117,
            longitude: 15.358462,
        },
        {
            obec: 'Týnec nad Sázavou',
            kod: 530841,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25741,
            latitude: 49.833579,
            longitude: 14.58994,
        },
        {
            obec: 'Uhlířská Lhota',
            kod: 533815,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28126,
            latitude: 50.079045,
            longitude: 15.391155,
        },
        {
            obec: 'Uhlířské Janovice',
            kod: 534498,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.880291,
            longitude: 15.06492,
        },
        {
            obec: 'Úholičky',
            kod: 571351,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25264,
            latitude: 50.16146,
            longitude: 14.335349,
        },
        {
            obec: 'Úhonice',
            kod: 532991,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25218,
            latitude: 50.043449,
            longitude: 14.186328,
        },
        {
            obec: 'Uhy',
            kod: 533009,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27324,
            latitude: 50.284336,
            longitude: 14.273969,
        },
        {
            obec: 'Újezd',
            kod: 531901,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26761,
            latitude: 49.831356,
            longitude: 13.837576,
        },
        {
            obec: 'Újezdec',
            kod: 599492,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27745,
            latitude: 50.283451,
            longitude: 14.420989,
        },
        {
            obec: 'Ujkovice',
            kod: 570761,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.366961,
            longitude: 15.099546,
        },
        {
            obec: 'Úmonín',
            kod: 534501,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28546,
            latitude: 49.888776,
            longitude: 15.271497,
        },
        {
            obec: 'Úmyslovice',
            kod: 537900,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29001,
            latitude: 50.203491,
            longitude: 15.177694,
        },
        {
            obec: 'Únětice',
            kod: 539805,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25262,
            latitude: 50.150214,
            longitude: 14.354207,
        },
        {
            obec: 'Unhošť',
            kod: 533017,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.085455,
            longitude: 14.130183,
        },
        {
            obec: 'Úvaly',
            kod: 538957,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25082,
            latitude: 50.073837,
            longitude: 14.730945,
        },
        {
            obec: 'Úžice',
            kod: 534510,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.872126,
            longitude: 14.9728,
        },
        {
            obec: 'Úžice',
            kod: 535257,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27745,
            latitude: 50.253007,
            longitude: 14.378957,
        },
        {
            obec: 'Václavice',
            kod: 532061,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25601,
            latitude: 49.789406,
            longitude: 14.61365,
        },
        {
            obec: 'Václavy',
            kod: 565512,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.063791,
            longitude: 13.59452,
        },
        {
            obec: 'Vavřinec',
            kod: 534528,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.913742,
            longitude: 15.033253,
        },
        {
            obec: 'Veleň',
            kod: 538965,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25063,
            latitude: 50.173318,
            longitude: 14.554314,
        },
        {
            obec: 'Velenice',
            kod: 536083,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28901,
            latitude: 50.214463,
            longitude: 15.226475,
        },
        {
            obec: 'Velenka',
            kod: 534871,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28912,
            latitude: 50.139824,
            longitude: 14.895712,
        },
        {
            obec: 'Veletov',
            kod: 533823,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.028423,
            longitude: 15.304983,
        },
        {
            obec: 'Veliká Ves',
            kod: 538973,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25070,
            latitude: 50.243718,
            longitude: 14.455186,
        },
        {
            obec: 'Velim',
            kod: 533831,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28101,
            latitude: 50.059756,
            longitude: 15.107217,
        },
        {
            obec: 'Veliš',
            kod: 530867,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25706,
            latitude: 49.668658,
            longitude: 14.823244,
        },
        {
            obec: 'Velká Buková',
            kod: 542563,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27023,
            latitude: 50.03466,
            longitude: 13.847293,
        },
        {
            obec: 'Velká Dobrá',
            kod: 533025,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27361,
            latitude: 50.109746,
            longitude: 14.069904,
        },
        {
            obec: 'Velká Chmelištná',
            kod: 529699,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27034,
            latitude: 50.063738,
            longitude: 13.551658,
        },
        {
            obec: 'Velká Lečice',
            kod: 513539,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26205,
            latitude: 49.814815,
            longitude: 14.323587,
        },
        {
            obec: 'Velké Popovice',
            kod: 538981,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25169,
            latitude: 49.922621,
            longitude: 14.63945,
        },
        {
            obec: 'Velké Přílepy',
            kod: 539813,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25264,
            latitude: 50.160605,
            longitude: 14.314579,
        },
        {
            obec: 'Velké Přítočno',
            kod: 533033,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27351,
            latitude: 50.116572,
            longitude: 14.128345,
        },
        {
            obec: 'Velké Všelisy',
            kod: 536857,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29427,
            latitude: 50.379137,
            longitude: 14.745169,
        },
        {
            obec: 'Velký Borek',
            kod: 535265,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27731,
            latitude: 50.345385,
            longitude: 14.515362,
        },
        {
            obec: 'Velký Chlumec',
            kod: 531910,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.82779,
            longitude: 14.087127,
        },
        {
            obec: 'Velký Osek',
            kod: 533840,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28151,
            latitude: 50.098769,
            longitude: 15.186392,
        },
        {
            obec: 'Veltruby',
            kod: 533858,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.070692,
            longitude: 15.184648,
        },
        {
            obec: 'Veltrusy',
            kod: 535273,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27746,
            latitude: 50.270563,
            longitude: 14.328679,
        },
        {
            obec: 'Velvary',
            kod: 533041,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27324,
            latitude: 50.281619,
            longitude: 14.236266,
        },
        {
            obec: 'Veselice',
            kod: 565725,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29404,
            latitude: 50.396148,
            longitude: 15.127224,
        },
        {
            obec: 'Vestec',
            kod: 537942,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28933,
            latitude: 50.23837,
            longitude: 15.146101,
        },
        {
            obec: 'Vestec',
            kod: 513458,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25242,
            latitude: 49.9806,
            longitude: 14.504981,
        },
        {
            obec: 'Věšín',
            kod: 541508,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26243,
            latitude: 49.615355,
            longitude: 13.824322,
        },
        {
            obec: 'Větrušice',
            kod: 538990,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25067,
            latitude: 50.190903,
            longitude: 14.383516,
        },
        {
            obec: 'Vidice',
            kod: 534536,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28401,
            latitude: 49.91671,
            longitude: 15.165597,
        },
        {
            obec: 'Vidim',
            kod: 531936,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.467953,
            longitude: 14.525934,
        },
        {
            obec: 'Vinaře',
            kod: 534544,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.909198,
            longitude: 15.501395,
        },
        {
            obec: 'Vinařice',
            kod: 534234,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26701,
            latitude: 49.892649,
            longitude: 14.098173,
        },
        {
            obec: 'Vinařice',
            kod: 533050,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27307,
            latitude: 50.175951,
            longitude: 14.091171,
        },
        {
            obec: 'Vinařice',
            kod: 565628,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29441,
            latitude: 50.373911,
            longitude: 14.952383,
        },
        {
            obec: 'Vinec',
            kod: 570842,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29301,
            latitude: 50.394714,
            longitude: 14.869901,
        },
        {
            obec: 'Višňová',
            kod: 541516,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26261,
            latitude: 49.705897,
            longitude: 14.145576,
        },
        {
            obec: 'Vitice',
            kod: 533866,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28106,
            latitude: 50.031106,
            longitude: 14.914611,
        },
        {
            obec: 'Vižina',
            kod: 534048,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26724,
            latitude: 49.857297,
            longitude: 14.10473,
        },
        {
            obec: 'Vlačice',
            kod: 534552,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.943143,
            longitude: 15.437595,
        },
        {
            obec: 'Vlastějovice',
            kod: 534561,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28523,
            latitude: 49.731406,
            longitude: 15.174893,
        },
        {
            obec: 'Vlašim',
            kod: 530883,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.706428,
            longitude: 14.89891,
        },
        {
            obec: 'Vlkančice',
            kod: 533874,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.901994,
            longitude: 14.897229,
        },
        {
            obec: 'Vlkaneč',
            kod: 534579,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28564,
            latitude: 49.806485,
            longitude: 15.403224,
        },
        {
            obec: 'Vlkava',
            kod: 565571,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29443,
            latitude: 50.271435,
            longitude: 14.961431,
        },
        {
            obec: 'Vlkov pod Oškobrhem',
            kod: 537951,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28904,
            latitude: 50.156096,
            longitude: 15.22052,
        },
        {
            obec: 'Vodochody',
            kod: 539015,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25069,
            latitude: 50.206369,
            longitude: 14.398885,
        },
        {
            obec: 'Vodranty',
            kod: 531014,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.890465,
            longitude: 15.336406,
        },
        {
            obec: 'Vodslivy',
            kod: 532614,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25724,
            latitude: 49.84965,
            longitude: 14.837298,
        },
        {
            obec: 'Vojkov',
            kod: 530891,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25753,
            latitude: 49.65232,
            longitude: 14.519906,
        },
        {
            obec: 'Vojkovice',
            kod: 535290,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27744,
            latitude: 50.296287,
            longitude: 14.375238,
        },
        {
            obec: 'Volárna',
            kod: 533882,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.091878,
            longitude: 15.240554,
        },
        {
            obec: 'Volenice',
            kod: 541524,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.547875,
            longitude: 13.883773,
        },
        {
            obec: 'Vonoklasy',
            kod: 539830,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25228,
            latitude: 49.950219,
            longitude: 14.276801,
        },
        {
            obec: 'Votice',
            kod: 530905,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25901,
            latitude: 49.64023,
            longitude: 14.63819,
        },
        {
            obec: 'Voznice',
            kod: 541541,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.817253,
            longitude: 14.21665,
        },
        {
            obec: 'Vracovice',
            kod: 530913,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25801,
            latitude: 49.662562,
            longitude: 14.932317,
        },
        {
            obec: 'Vraňany',
            kod: 535303,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27707,
            latitude: 50.317291,
            longitude: 14.361804,
        },
        {
            obec: 'Vrančice',
            kod: 564362,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.610735,
            longitude: 14.041861,
        },
        {
            obec: 'Vrané nad Vltavou',
            kod: 539848,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25246,
            latitude: 49.938512,
            longitude: 14.383447,
        },
        {
            obec: 'Vranov',
            kod: 530921,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25722,
            latitude: 49.853416,
            longitude: 14.777021,
        },
        {
            obec: 'Vranovice',
            kod: 541567,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.622726,
            longitude: 13.899074,
        },
        {
            obec: 'Vraný',
            kod: 533068,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27373,
            latitude: 50.327189,
            longitude: 14.017251,
        },
        {
            obec: 'Vrátkov',
            kod: 564826,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28201,
            latitude: 50.047055,
            longitude: 14.834885,
        },
        {
            obec: 'Vrátno',
            kod: 572021,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29426,
            latitude: 50.432702,
            longitude: 14.693699,
        },
        {
            obec: 'Vráž',
            kod: 531944,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26711,
            latitude: 49.983443,
            longitude: 14.12902,
        },
        {
            obec: 'Vrbčany',
            kod: 533891,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.050307,
            longitude: 14.992861,
        },
        {
            obec: 'Vrbice',
            kod: 599611,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28904,
            latitude: 50.181015,
            longitude: 15.230617,
        },
        {
            obec: 'Vrbičany',
            kod: 571431,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.308192,
            longitude: 13.995897,
        },
        {
            obec: 'Vrbová Lhota',
            kod: 537977,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28911,
            latitude: 50.112053,
            longitude: 15.062754,
        },
        {
            obec: 'Vrdy',
            kod: 534587,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28571,
            latitude: 49.921633,
            longitude: 15.472535,
        },
        {
            obec: 'Vrchotovy Janovice',
            kod: 530948,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25753,
            latitude: 49.666919,
            longitude: 14.577909,
        },
        {
            obec: 'Všechlapy',
            kod: 532266,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25726,
            latitude: 49.779125,
            longitude: 14.914341,
        },
        {
            obec: 'Všechlapy',
            kod: 537985,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28802,
            latitude: 50.221181,
            longitude: 15.028509,
        },
        {
            obec: 'Všejany',
            kod: 536938,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29443,
            latitude: 50.25487,
            longitude: 14.953611,
        },
        {
            obec: 'Všenory',
            kod: 539856,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25231,
            latitude: 49.928938,
            longitude: 14.304031,
        },
        {
            obec: 'Všeradice',
            kod: 531952,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26726,
            latitude: 49.873756,
            longitude: 14.104868,
        },
        {
            obec: 'Všestary',
            kod: 539031,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25163,
            latitude: 49.958709,
            longitude: 14.68537,
        },
        {
            obec: 'Všestudy',
            kod: 535311,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27746,
            latitude: 50.290915,
            longitude: 14.342686,
        },
        {
            obec: 'Všesulov',
            kod: 565130,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27034,
            latitude: 50.039761,
            longitude: 13.610986,
        },
        {
            obec: 'Všetaty',
            kod: 535320,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27716,
            latitude: 50.281971,
            longitude: 14.593081,
        },
        {
            obec: 'Všetaty',
            kod: 542598,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27021,
            latitude: 50.046201,
            longitude: 13.757751,
        },
        {
            obec: 'Vševily',
            kod: 598437,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26272,
            latitude: 49.565277,
            longitude: 13.882648,
        },
        {
            obec: 'Vykáň',
            kod: 537993,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28915,
            latitude: 50.119966,
            longitude: 14.816787,
        },
        {
            obec: 'Vysoká',
            kod: 535338,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27724,
            latitude: 50.412655,
            longitude: 14.538285,
        },
        {
            obec: 'Vysoká u Příbramě',
            kod: 541583,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26242,
            latitude: 49.63432,
            longitude: 13.948497,
        },
        {
            obec: 'Vysoký Chlumec',
            kod: 541591,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26252,
            latitude: 49.617757,
            longitude: 14.389867,
        },
        {
            obec: 'Vysoký Újezd',
            kod: 513482,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25744,
            latitude: 49.813385,
            longitude: 14.476422,
        },
        {
            obec: 'Vysoký Újezd',
            kod: 531961,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26716,
            latitude: 49.992234,
            longitude: 14.206303,
        },
        {
            obec: 'Vyšehořovice',
            kod: 539040,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25087,
            latitude: 50.117881,
            longitude: 14.773799,
        },
        {
            obec: 'Výžerky',
            kod: 564796,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.945155,
            longitude: 14.883469,
        },
        {
            obec: 'Vyžlovka',
            kod: 533904,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.984752,
            longitude: 14.789195,
        },
        {
            obec: 'Xaverov',
            kod: 571415,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28506,
            latitude: 49.842785,
            longitude: 14.884454,
        },
        {
            obec: 'Záboří nad Labem',
            kod: 534595,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28574,
            latitude: 50.025315,
            longitude: 15.349976,
        },
        {
            obec: 'Zadní Třebaň',
            kod: 531979,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26729,
            latitude: 49.918124,
            longitude: 14.208189,
        },
        {
            obec: 'Záhornice',
            kod: 534471,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28903,
            latitude: 50.247737,
            longitude: 15.291028,
        },
        {
            obec: 'Zahořany',
            kod: 571288,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25210,
            latitude: 49.849446,
            longitude: 14.295423,
        },
        {
            obec: 'Zaječov',
            kod: 531995,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26763,
            latitude: 49.774455,
            longitude: 13.840825,
        },
        {
            obec: 'Zájezd',
            kod: 571598,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27343,
            latitude: 50.166081,
            longitude: 14.220609,
        },
        {
            obec: 'Zákolany',
            kod: 533092,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27328,
            latitude: 50.196515,
            longitude: 14.247943,
        },
        {
            obec: 'Zalešany',
            kod: 513202,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.03578,
            longitude: 15.007909,
        },
        {
            obec: 'Zálezlice',
            kod: 535354,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27745,
            latitude: 50.307557,
            longitude: 14.438251,
        },
        {
            obec: 'Zalužany',
            kod: 541613,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26284,
            latitude: 49.542353,
            longitude: 14.085834,
        },
        {
            obec: 'Záluží',
            kod: 532002,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26761,
            latitude: 49.842798,
            longitude: 13.860599,
        },
        {
            obec: 'Zápy',
            kod: 505781,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25001,
            latitude: 50.1651,
            longitude: 14.680157,
        },
        {
            obec: 'Záryby',
            kod: 535362,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27713,
            latitude: 50.220678,
            longitude: 14.625983,
        },
        {
            obec: 'Zásmuky',
            kod: 533921,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28144,
            latitude: 49.954806,
            longitude: 15.030686,
        },
        {
            obec: 'Zavidov',
            kod: 542601,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27035,
            latitude: 50.056797,
            longitude: 13.620408,
        },
        {
            obec: 'Zbečno',
            kod: 542610,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27024,
            latitude: 50.0419,
            longitude: 13.920468,
        },
        {
            obec: 'Zbenice',
            kod: 564320,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26231,
            latitude: 49.592261,
            longitude: 14.089159,
        },
        {
            obec: 'Zbizuby',
            kod: 534609,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28504,
            latitude: 49.831085,
            longitude: 15.047665,
        },
        {
            obec: 'Zbožíčko',
            kod: 599697,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28925,
            latitude: 50.225835,
            longitude: 14.939922,
        },
        {
            obec: 'Zbraslavice',
            kod: 534617,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28521,
            latitude: 49.811891,
            longitude: 15.183296,
        },
        {
            obec: 'Zbuzany',
            kod: 539872,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25225,
            latitude: 50.024277,
            longitude: 14.286854,
        },
        {
            obec: 'Zbýšov',
            kod: 534625,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28565,
            latitude: 49.811657,
            longitude: 15.353208,
        },
        {
            obec: 'Zdětín',
            kod: 571814,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29471,
            latitude: 50.312702,
            longitude: 14.812523,
        },
        {
            obec: 'Zdiby',
            kod: 539058,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25066,
            latitude: 50.168096,
            longitude: 14.451284,
        },
        {
            obec: 'Zdice',
            kod: 532011,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26751,
            latitude: 49.912178,
            longitude: 13.977574,
        },
        {
            obec: 'Zdislavice',
            kod: 531022,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25764,
            latitude: 49.686587,
            longitude: 14.974594,
        },
        {
            obec: 'Zduchovice',
            kod: 513512,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26263,
            latitude: 49.638685,
            longitude: 14.209044,
        },
        {
            obec: 'Zeleneč',
            kod: 539066,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25091,
            latitude: 50.133721,
            longitude: 14.660805,
        },
        {
            obec: 'Zichovec',
            kod: 571423,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27374,
            latitude: 50.271159,
            longitude: 13.926136,
        },
        {
            obec: 'Zlatá',
            kod: 513644,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25083,
            latitude: 50.04033,
            longitude: 14.710148,
        },
        {
            obec: 'Zlatníky-Hodkovice',
            kod: 539881,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25241,
            latitude: 49.960627,
            longitude: 14.480805,
        },
        {
            obec: 'Zlončice',
            kod: 531511,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27801,
            latitude: 50.228883,
            longitude: 14.35768,
        },
        {
            obec: 'Zlonice',
            kod: 533114,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27371,
            latitude: 50.287603,
            longitude: 14.092247,
        },
        {
            obec: 'Zlonín',
            kod: 539082,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25064,
            latitude: 50.215834,
            longitude: 14.507857,
        },
        {
            obec: 'Zlosyň',
            kod: 535389,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27744,
            latitude: 50.278049,
            longitude: 14.368713,
        },
        {
            obec: 'Zruč nad Sázavou',
            kod: 534633,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28522,
            latitude: 49.740205,
            longitude: 15.106164,
        },
        {
            obec: 'Zvánovice',
            kod: 539091,
            okres: 'Praha-východ',
            kod_okres: 'CZ0209',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25165,
            latitude: 49.93205,
            longitude: 14.780336,
        },
        {
            obec: 'Zvěřínek',
            kod: 534862,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28913,
            latitude: 50.153287,
            longitude: 15.005845,
        },
        {
            obec: 'Zvěstov',
            kod: 531049,
            okres: 'Benešov',
            kod_okres: 'CZ0201',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25706,
            latitude: 49.632768,
            longitude: 14.792228,
        },
        {
            obec: 'Zvole',
            kod: 539902,
            okres: 'Praha-západ',
            kod_okres: 'CZ020A',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 25245,
            latitude: 49.934765,
            longitude: 14.417796,
        },
        {
            obec: 'Zvoleněves',
            kod: 533122,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27325,
            latitude: 50.231256,
            longitude: 14.182316,
        },
        {
            obec: 'Žabonosy',
            kod: 513261,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28002,
            latitude: 50.035467,
            longitude: 15.027312,
        },
        {
            obec: 'Žáky',
            kod: 534641,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28601,
            latitude: 49.885374,
            longitude: 15.3684,
        },
        {
            obec: 'Ždánice',
            kod: 513164,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28163,
            latitude: 49.969517,
            longitude: 14.959179,
        },
        {
            obec: 'Žďár',
            kod: 536971,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29412,
            latitude: 50.543762,
            longitude: 15.080449,
        },
        {
            obec: 'Žďár',
            kod: 598518,
            okres: 'Rakovník',
            kod_okres: 'CZ020C',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27033,
            latitude: 50.058554,
            longitude: 13.459724,
        },
        {
            obec: 'Žebrák',
            kod: 532029,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26753,
            latitude: 49.875844,
            longitude: 13.897457,
        },
        {
            obec: 'Žehuň',
            kod: 538035,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28905,
            latitude: 50.135585,
            longitude: 15.291395,
        },
        {
            obec: 'Žehušice',
            kod: 534650,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28575,
            latitude: 49.969555,
            longitude: 15.407492,
        },
        {
            obec: 'Želenice',
            kod: 599425,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27341,
            latitude: 50.210487,
            longitude: 14.164618,
        },
        {
            obec: 'Železná',
            kod: 599417,
            okres: 'Beroun',
            kod_okres: 'CZ0202',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26601,
            latitude: 50.009803,
            longitude: 14.091043,
        },
        {
            obec: 'Želízy',
            kod: 535397,
            okres: 'Mělník',
            kod_okres: 'CZ0206',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27721,
            latitude: 50.423837,
            longitude: 14.464874,
        },
        {
            obec: 'Žerčice',
            kod: 536989,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29446,
            latitude: 50.374263,
            longitude: 15.036019,
        },
        {
            obec: 'Židněves',
            kod: 536997,
            okres: 'Mladá Boleslav',
            kod_okres: 'CZ0207',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 29406,
            latitude: 50.41173,
            longitude: 14.994635,
        },
        {
            obec: 'Žilina',
            kod: 533149,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27301,
            latitude: 50.099975,
            longitude: 14.005859,
        },
        {
            obec: 'Žitovlice',
            kod: 503410,
            okres: 'Nymburk',
            kod_okres: 'CZ0208',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28934,
            latitude: 50.294076,
            longitude: 15.137094,
        },
        {
            obec: 'Žiželice',
            kod: 533947,
            okres: 'Kolín',
            kod_okres: 'CZ0204',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28129,
            latitude: 50.132019,
            longitude: 15.393302,
        },
        {
            obec: 'Žižice',
            kod: 533157,
            okres: 'Kladno',
            kod_okres: 'CZ0203',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 27401,
            latitude: 50.246382,
            longitude: 14.154001,
        },
        {
            obec: 'Žleby',
            kod: 534668,
            okres: 'Kutná Hora',
            kod_okres: 'CZ0205',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 28561,
            latitude: 49.889685,
            longitude: 15.488634,
        },
        {
            obec: 'Županovice',
            kod: 564338,
            okres: 'Příbram',
            kod_okres: 'CZ020B',
            kraj: 'Středočeský kraj',
            kod_kraj: 'CZ020',
            psc: 26301,
            latitude: 49.707893,
            longitude: 14.298816,
        },
    ],
    CZ052: [
        {
            obec: 'Adršpach',
            kod: 547786,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54957,
            latitude: 50.624387,
            longitude: 16.083501,
        },
        {
            obec: 'Albrechtice nad Orlicí',
            kod: 576077,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51722,
            latitude: 50.139886,
            longitude: 16.064472,
        },
        {
            obec: 'Babice',
            kod: 569828,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.192196,
            longitude: 15.585617,
        },
        {
            obec: 'Bačalky',
            kod: 553701,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.385047,
            longitude: 15.15353,
        },
        {
            obec: 'Bačetín',
            kod: 576085,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.299877,
            longitude: 16.233416,
        },
        {
            obec: 'Barchov',
            kod: 569836,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.197698,
            longitude: 15.56774,
        },
        {
            obec: 'Bartošovice v Orlických horách',
            kod: 576093,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51761,
            latitude: 50.16408,
            longitude: 16.547862,
        },
        {
            obec: 'Bašnice',
            kod: 572667,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.334576,
            longitude: 15.605129,
        },
        {
            obec: 'Batňovice',
            kod: 579041,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54237,
            latitude: 50.517692,
            longitude: 16.03674,
        },
        {
            obec: 'Běchary',
            kod: 572675,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.31413,
            longitude: 15.292617,
        },
        {
            obec: 'Běleč nad Orlicí',
            kod: 569852,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.198081,
            longitude: 15.942805,
        },
        {
            obec: 'Benátky',
            kod: 569861,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.310292,
            longitude: 15.736096,
        },
        {
            obec: 'Bernartice',
            kod: 579050,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54204,
            latitude: 50.644783,
            longitude: 15.965926,
        },
        {
            obec: 'Bezděkov nad Metují',
            kod: 573884,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54964,
            latitude: 50.509838,
            longitude: 16.229341,
        },
        {
            obec: 'Bílá Třemešná',
            kod: 579068,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54472,
            latitude: 50.444749,
            longitude: 15.74112,
        },
        {
            obec: 'Bílé Poličany',
            kod: 579076,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54452,
            latitude: 50.390894,
            longitude: 15.731985,
        },
        {
            obec: 'Bílsko u Hořic',
            kod: 548901,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.369489,
            longitude: 15.600604,
        },
        {
            obec: 'Bílý Újezd',
            kod: 576107,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.238541,
            longitude: 16.22537,
        },
        {
            obec: 'Blešno',
            kod: 569879,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.214011,
            longitude: 15.930746,
        },
        {
            obec: 'Boháňka',
            kod: 572705,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.365894,
            longitude: 15.708485,
        },
        {
            obec: 'Boharyně',
            kod: 569887,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50323,
            latitude: 50.203911,
            longitude: 15.630961,
        },
        {
            obec: 'Bohdašín',
            kod: 576115,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.338561,
            longitude: 16.225153,
        },
        {
            obec: 'Bohuslavice',
            kod: 573892,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54906,
            latitude: 50.312731,
            longitude: 16.088688,
        },
        {
            obec: 'Bolehošť',
            kod: 576123,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51731,
            latitude: 50.213724,
            longitude: 16.077076,
        },
        {
            obec: 'Borek',
            kod: 548979,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.438962,
            longitude: 15.654346,
        },
        {
            obec: 'Borohrádek',
            kod: 576131,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51724,
            latitude: 50.09787,
            longitude: 16.093365,
        },
        {
            obec: 'Borová',
            kod: 573906,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54701,
            latitude: 50.386091,
            longitude: 16.253287,
        },
        {
            obec: 'Borovnice',
            kod: 576140,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.063008,
            longitude: 16.245418,
        },
        {
            obec: 'Borovnice',
            kod: 579092,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54477,
            latitude: 50.509256,
            longitude: 15.617294,
        },
        {
            obec: 'Borovnička',
            kod: 548821,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54475,
            latitude: 50.500964,
            longitude: 15.667313,
        },
        {
            obec: 'Božanov',
            kod: 573914,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54974,
            latitude: 50.526878,
            longitude: 16.365391,
        },
        {
            obec: 'Brada-Rybníček',
            kod: 549100,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.465297,
            longitude: 15.337606,
        },
        {
            obec: 'Broumov',
            kod: 573922,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55001,
            latitude: 50.585758,
            longitude: 16.331915,
        },
        {
            obec: 'Brzice',
            kod: 573931,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.444489,
            longitude: 15.958411,
        },
        {
            obec: 'Březina',
            kod: 549070,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.434394,
            longitude: 15.313367,
        },
        {
            obec: 'Bříšťany',
            kod: 548855,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.316342,
            longitude: 15.620923,
        },
        {
            obec: 'Budčeves',
            kod: 548952,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.308573,
            longitude: 15.254641,
        },
        {
            obec: 'Bukovice',
            kod: 547751,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54954,
            latitude: 50.549647,
            longitude: 16.228038,
        },
        {
            obec: 'Bukvice',
            kod: 573353,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.412586,
            longitude: 15.294637,
        },
        {
            obec: 'Butoves',
            kod: 549282,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.386551,
            longitude: 15.427515,
        },
        {
            obec: 'Bystré',
            kod: 576166,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.321556,
            longitude: 16.255333,
        },
        {
            obec: 'Bystřice',
            kod: 572772,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.392983,
            longitude: 15.244218,
        },
        {
            obec: 'Byzhradec',
            kod: 576174,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.215939,
            longitude: 16.1903,
        },
        {
            obec: 'Cerekvice nad Bystřicí',
            kod: 572781,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50777,
            latitude: 50.333348,
            longitude: 15.723633,
        },
        {
            obec: 'Častolovice',
            kod: 576182,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51750,
            latitude: 50.129194,
            longitude: 16.181387,
        },
        {
            obec: 'Čermná',
            kod: 579106,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54377,
            latitude: 50.550083,
            longitude: 15.769729,
        },
        {
            obec: 'Čermná nad Orlicí',
            kod: 576191,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51725,
            latitude: 50.080411,
            longitude: 16.144089,
        },
        {
            obec: 'Černčice',
            kod: 573957,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.335841,
            longitude: 16.102688,
        },
        {
            obec: 'Černíkovice',
            kod: 576204,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51704,
            latitude: 50.186688,
            longitude: 16.207062,
        },
        {
            obec: 'Černilov',
            kod: 569917,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50343,
            latitude: 50.262743,
            longitude: 15.922644,
        },
        {
            obec: 'Černožice',
            kod: 569925,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50304,
            latitude: 50.318732,
            longitude: 15.874133,
        },
        {
            obec: 'Černý Důl',
            kod: 579114,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54344,
            latitude: 50.635059,
            longitude: 15.710701,
        },
        {
            obec: 'Červená Hora',
            kod: 505099,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54941,
            latitude: 50.450166,
            longitude: 16.058927,
        },
        {
            obec: 'Červená Třemešná',
            kod: 548995,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.39943,
            longitude: 15.643602,
        },
        {
            obec: 'Červený Kostelec',
            kod: 573965,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54941,
            latitude: 50.476361,
            longitude: 16.093,
        },
        {
            obec: 'Česká Čermná',
            kod: 573973,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54921,
            latitude: 50.400822,
            longitude: 16.229527,
        },
        {
            obec: 'Česká Metuje',
            kod: 573981,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54956,
            latitude: 50.545672,
            longitude: 16.180328,
        },
        {
            obec: 'Česká Skalice',
            kod: 573990,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55203,
            latitude: 50.394767,
            longitude: 16.042868,
        },
        {
            obec: 'České Meziříčí',
            kod: 576212,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.286898,
            longitude: 16.044385,
        },
        {
            obec: 'Čestice',
            kod: 576221,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.127918,
            longitude: 16.145832,
        },
        {
            obec: 'Češov',
            kod: 572811,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.339204,
            longitude: 15.360429,
        },
        {
            obec: 'Čistěves',
            kod: 569933,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.28893,
            longitude: 15.731935,
        },
        {
            obec: 'Deštné v Orlických horách',
            kod: 576247,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51791,
            latitude: 50.304743,
            longitude: 16.350751,
        },
        {
            obec: 'Dětenice',
            kod: 572829,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50724,
            latitude: 50.36818,
            longitude: 15.170627,
        },
        {
            obec: 'Dílce',
            kod: 549118,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.467293,
            longitude: 15.359434,
        },
        {
            obec: 'Divec',
            kod: 569941,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50003,
            latitude: 50.239875,
            longitude: 15.921759,
        },
        {
            obec: 'Dobrá Voda u Hořic',
            kod: 572837,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50773,
            latitude: 50.348096,
            longitude: 15.602674,
        },
        {
            obec: 'Dobré',
            kod: 576263,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51793,
            latitude: 50.269855,
            longitude: 16.263866,
        },
        {
            obec: 'Dobruška',
            kod: 576271,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.292111,
            longitude: 16.160118,
        },
        {
            obec: 'Dobřany',
            kod: 576280,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.322221,
            longitude: 16.285397,
        },
        {
            obec: 'Dobřenice',
            kod: 569968,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50325,
            latitude: 50.147821,
            longitude: 15.641146,
        },
        {
            obec: 'Dohalice',
            kod: 569976,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50313,
            latitude: 50.287371,
            longitude: 15.694605,
        },
        {
            obec: 'Dolany',
            kod: 574015,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55201,
            latitude: 50.380098,
            longitude: 15.96138,
        },
        {
            obec: 'Dolní Branná',
            kod: 579122,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54362,
            latitude: 50.593265,
            longitude: 15.593924,
        },
        {
            obec: 'Dolní Brusnice',
            kod: 554863,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54472,
            latitude: 50.458843,
            longitude: 15.726876,
        },
        {
            obec: 'Dolní Dvůr',
            kod: 579149,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54342,
            latitude: 50.652348,
            longitude: 15.655683,
        },
        {
            obec: 'Dolní Kalná',
            kod: 579157,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54374,
            latitude: 50.538973,
            longitude: 15.6393,
        },
        {
            obec: 'Dolní Lánov',
            kod: 579165,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54341,
            latitude: 50.587095,
            longitude: 15.664426,
        },
        {
            obec: 'Dolní Lochov',
            kod: 549088,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.452243,
            longitude: 15.286389,
        },
        {
            obec: 'Dolní Olešnice',
            kod: 579173,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54375,
            latitude: 50.524045,
            longitude: 15.712271,
        },
        {
            obec: 'Dolní Přím',
            kod: 569984,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50316,
            latitude: 50.239725,
            longitude: 15.708601,
        },
        {
            obec: 'Dolní Radechová',
            kod: 574023,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54911,
            latitude: 50.438436,
            longitude: 16.151339,
        },
        {
            obec: 'Doubravice',
            kod: 579181,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54451,
            latitude: 50.405574,
            longitude: 15.761986,
        },
        {
            obec: 'Doudleby nad Orlicí',
            kod: 576301,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51742,
            latitude: 50.107644,
            longitude: 16.261846,
        },
        {
            obec: 'Dřevěnice',
            kod: 573337,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50713,
            latitude: 50.45237,
            longitude: 15.447215,
        },
        {
            obec: 'Dubenec',
            kod: 579190,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54455,
            latitude: 50.37772,
            longitude: 15.795177,
        },
        {
            obec: 'Dvůr Králové nad Labem',
            kod: 579203,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.43182,
            longitude: 15.814127,
        },
        {
            obec: 'Habřina',
            kod: 569992,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.326035,
            longitude: 15.826971,
        },
        {
            obec: 'Hajnice',
            kod: 579211,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54466,
            latitude: 50.487956,
            longitude: 15.908701,
        },
        {
            obec: 'Havlovice',
            kod: 579220,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54232,
            latitude: 50.487625,
            longitude: 16.029428,
        },
        {
            obec: 'Hejtmánkovice',
            kod: 574031,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55001,
            latitude: 50.599352,
            longitude: 16.299544,
        },
        {
            obec: 'Heřmanice',
            kod: 574040,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55212,
            latitude: 50.379697,
            longitude: 15.91865,
        },
        {
            obec: 'Heřmánkovice',
            kod: 574058,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54984,
            latitude: 50.620004,
            longitude: 16.323954,
        },
        {
            obec: 'Hlušice',
            kod: 570001,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50356,
            latitude: 50.264697,
            longitude: 15.403364,
        },
        {
            obec: 'Hněvčeves',
            kod: 570010,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.314969,
            longitude: 15.71623,
        },
        {
            obec: 'Holín',
            kod: 572900,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.451706,
            longitude: 15.320807,
        },
        {
            obec: 'Holohlavy',
            kod: 570028,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.308038,
            longitude: 15.860186,
        },
        {
            obec: 'Holovousy',
            kod: 572918,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.375381,
            longitude: 15.577533,
        },
        {
            obec: 'Horní Brusnice',
            kod: 579238,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54474,
            latitude: 50.473162,
            longitude: 15.68114,
        },
        {
            obec: 'Horní Kalná',
            kod: 579254,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54371,
            latitude: 50.562856,
            longitude: 15.62487,
        },
        {
            obec: 'Horní Maršov',
            kod: 579262,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54226,
            latitude: 50.658659,
            longitude: 15.819859,
        },
        {
            obec: 'Horní Olešnice',
            kod: 579271,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54371,
            latitude: 50.53276,
            longitude: 15.677502,
        },
        {
            obec: 'Horní Radechová',
            kod: 574066,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54946,
            latitude: 50.46743,
            longitude: 16.144576,
        },
        {
            obec: 'Hořenice',
            kod: 547531,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.369279,
            longitude: 15.910937,
        },
        {
            obec: 'Hořice',
            kod: 572926,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.366189,
            longitude: 15.63194,
        },
        {
            obec: 'Hořičky',
            kod: 574074,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.444856,
            longitude: 15.993634,
        },
        {
            obec: 'Hořiněves',
            kod: 570044,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50306,
            latitude: 50.309639,
            longitude: 15.767421,
        },
        {
            obec: 'Hostinné',
            kod: 579297,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54371,
            latitude: 50.540762,
            longitude: 15.723442,
        },
        {
            obec: 'Hradec Králové',
            kod: 569810,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50002,
            latitude: 50.210461,
            longitude: 15.825311,
        },
        {
            obec: 'Hrádek',
            kod: 570052,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.218662,
            longitude: 15.675654,
        },
        {
            obec: 'Hronov',
            kod: 574082,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54931,
            latitude: 50.480164,
            longitude: 16.181777,
        },
        {
            obec: 'Hřibiny-Ledská',
            kod: 548642,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.148079,
            longitude: 16.175968,
        },
        {
            obec: 'Hřibojedy',
            kod: 579301,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.392163,
            longitude: 15.834831,
        },
        {
            obec: 'Humburky',
            kod: 570087,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.226124,
            longitude: 15.511631,
        },
        {
            obec: 'Hvozdnice',
            kod: 573621,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.191374,
            longitude: 15.711346,
        },
        {
            obec: 'Hynčice',
            kod: 574163,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54983,
            latitude: 50.623831,
            longitude: 16.285403,
        },
        {
            obec: 'Chleny',
            kod: 576310,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51745,
            latitude: 50.077977,
            longitude: 16.243846,
        },
        {
            obec: 'Chlístov',
            kod: 548791,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.322469,
            longitude: 16.169563,
        },
        {
            obec: 'Chlumec nad Cidlinou',
            kod: 570109,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.154506,
            longitude: 15.460363,
        },
        {
            obec: 'Cholenice',
            kod: 549355,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.324828,
            longitude: 15.280763,
        },
        {
            obec: 'Chomutice',
            kod: 572969,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50753,
            latitude: 50.359371,
            longitude: 15.496287,
        },
        {
            obec: 'Choteč',
            kod: 549037,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50781,
            latitude: 50.432957,
            longitude: 15.516036,
        },
        {
            obec: 'Chotěvice',
            kod: 579319,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54371,
            latitude: 50.521498,
            longitude: 15.766799,
        },
        {
            obec: 'Choustníkovo Hradiště',
            kod: 579327,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54442,
            latitude: 50.426514,
            longitude: 15.878682,
        },
        {
            obec: 'Chudeřice',
            kod: 570125,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.149139,
            longitude: 15.550972,
        },
        {
            obec: 'Chvaleč',
            kod: 579335,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54211,
            latitude: 50.595999,
            longitude: 16.03941,
        },
        {
            obec: 'Chvalkovice',
            kod: 574112,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55204,
            latitude: 50.414437,
            longitude: 15.978392,
        },
        {
            obec: 'Chyjice',
            kod: 549223,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.387734,
            longitude: 15.29557,
        },
        {
            obec: 'Jahodov',
            kod: 548782,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51601,
            latitude: 50.150364,
            longitude: 16.333536,
        },
        {
            obec: 'Janov',
            kod: 576328,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.332407,
            longitude: 16.25188,
        },
        {
            obec: 'Janské Lázně',
            kod: 579351,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54225,
            latitude: 50.631188,
            longitude: 15.781916,
        },
        {
            obec: 'Jaroměř',
            kod: 574121,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.356904,
            longitude: 15.924238,
        },
        {
            obec: 'Jasenná',
            kod: 574139,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55222,
            latitude: 50.318379,
            longitude: 15.99108,
        },
        {
            obec: 'Javornice',
            kod: 576336,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51711,
            latitude: 50.171465,
            longitude: 16.349372,
        },
        {
            obec: 'Jeníkovice',
            kod: 570133,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.22828,
            longitude: 16.002737,
        },
        {
            obec: 'Jeřice',
            kod: 572993,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.344654,
            longitude: 15.680876,
        },
        {
            obec: 'Jestřebí',
            kod: 574147,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.362184,
            longitude: 16.182163,
        },
        {
            obec: 'Jetřichov',
            kod: 574155,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54983,
            latitude: 50.615071,
            longitude: 16.266763,
        },
        {
            obec: 'Jičín',
            kod: 572659,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.435433,
            longitude: 15.361144,
        },
        {
            obec: 'Jičíněves',
            kod: 573001,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50731,
            latitude: 50.373176,
            longitude: 15.337392,
        },
        {
            obec: 'Jílovice',
            kod: 576352,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51772,
            latitude: 50.250722,
            longitude: 16.012785,
        },
        {
            obec: 'Jinolice',
            kod: 549151,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.478592,
            longitude: 15.32977,
        },
        {
            obec: 'Jívka',
            kod: 579378,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54213,
            latitude: 50.535096,
            longitude: 16.109111,
        },
        {
            obec: 'Kacákova Lhota',
            kod: 549312,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.409627,
            longitude: 15.419326,
        },
        {
            obec: 'Káranice',
            kod: 570150,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50366,
            latitude: 50.153056,
            longitude: 15.557599,
        },
        {
            obec: 'Kbelnice',
            kod: 549169,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.454929,
            longitude: 15.350713,
        },
        {
            obec: 'Klamoš',
            kod: 570168,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.125681,
            longitude: 15.501358,
        },
        {
            obec: 'Klášterská Lhota',
            kod: 579386,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54371,
            latitude: 50.559794,
            longitude: 15.663687,
        },
        {
            obec: 'Kněžnice',
            kod: 573043,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.493286,
            longitude: 15.322548,
        },
        {
            obec: 'Kobylice',
            kod: 573710,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.245928,
            longitude: 15.587203,
        },
        {
            obec: 'Kocbeře',
            kod: 579394,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54464,
            latitude: 50.453757,
            longitude: 15.858823,
        },
        {
            obec: 'Kohoutov',
            kod: 579408,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.451411,
            longitude: 15.904893,
        },
        {
            obec: 'Konecchlumí',
            kod: 573051,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50705,
            latitude: 50.402345,
            longitude: 15.480335,
        },
        {
            obec: 'Kopidlno',
            kod: 573060,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.330951,
            longitude: 15.2704,
        },
        {
            obec: 'Kosice',
            kod: 570176,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.179841,
            longitude: 15.534913,
        },
        {
            obec: 'Kosičky',
            kod: 570184,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50365,
            latitude: 50.178703,
            longitude: 15.555822,
        },
        {
            obec: 'Kostelec',
            kod: 548928,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.381344,
            longitude: 15.327377,
        },
        {
            obec: 'Kostelec nad Orlicí',
            kod: 576361,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.122511,
            longitude: 16.215084,
        },
        {
            obec: 'Kostelecké Horky',
            kod: 576387,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.051755,
            longitude: 16.206964,
        },
        {
            obec: 'Kounov',
            kod: 576395,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51792,
            latitude: 50.298871,
            longitude: 16.258155,
        },
        {
            obec: 'Kovač',
            kod: 548944,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.39615,
            longitude: 15.470916,
        },
        {
            obec: 'Kozojedy',
            kod: 572136,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.316531,
            longitude: 15.374882,
        },
        {
            obec: 'Králíky',
            kod: 570192,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.259542,
            longitude: 15.53668,
        },
        {
            obec: 'Králova Lhota',
            kod: 576409,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.294662,
            longitude: 15.998922,
        },
        {
            obec: 'Královec',
            kod: 530808,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54203,
            latitude: 50.676859,
            longitude: 15.97381,
        },
        {
            obec: 'Kramolna',
            kod: 574546,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54701,
            latitude: 50.420798,
            longitude: 16.13492,
        },
        {
            obec: 'Kratonohy',
            kod: 570206,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50324,
            latitude: 50.169243,
            longitude: 15.607018,
        },
        {
            obec: 'Krchleby',
            kod: 548685,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.079206,
            longitude: 16.234632,
        },
        {
            obec: 'Křinice',
            kod: 574171,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55001,
            latitude: 50.571797,
            longitude: 16.306073,
        },
        {
            obec: 'Kuks',
            kod: 579416,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54443,
            latitude: 50.401587,
            longitude: 15.888322,
        },
        {
            obec: 'Kunčice',
            kod: 570214,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.217617,
            longitude: 15.636021,
        },
        {
            obec: 'Kunčice nad Labem',
            kod: 579424,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54361,
            latitude: 50.581962,
            longitude: 15.619536,
        },
        {
            obec: 'Kvasiny',
            kod: 576425,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51702,
            latitude: 50.212594,
            longitude: 16.263334,
        },
        {
            obec: 'Kyje',
            kod: 572047,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50713,
            latitude: 50.494389,
            longitude: 15.37809,
        },
        {
            obec: 'Lampertice',
            kod: 548804,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54101,
            latitude: 50.664684,
            longitude: 15.951068,
        },
        {
            obec: 'Lánov',
            kod: 579432,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54341,
            latitude: 50.620172,
            longitude: 15.65585,
        },
        {
            obec: 'Lanžov',
            kod: 579441,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.386918,
            longitude: 15.760854,
        },
        {
            obec: 'Lázně Bělohrad',
            kod: 573094,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50781,
            latitude: 50.428843,
            longitude: 15.582797,
        },
        {
            obec: 'Ledce',
            kod: 576433,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.223913,
            longitude: 16.04283,
        },
        {
            obec: 'Lejšovka',
            kod: 570222,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.297761,
            longitude: 15.942389,
        },
        {
            obec: 'Lhota pod Hořičkami',
            kod: 574180,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.422788,
            longitude: 16.003204,
        },
        {
            obec: 'Lhota pod Libčany',
            kod: 570231,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.172522,
            longitude: 15.696345,
        },
        {
            obec: 'Lhoty u Potštejna',
            kod: 576441,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.055957,
            longitude: 16.268803,
        },
        {
            obec: 'Libáň',
            kod: 573108,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.375516,
            longitude: 15.21695,
        },
        {
            obec: 'Libčany',
            kod: 570249,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50322,
            latitude: 50.191851,
            longitude: 15.695007,
        },
        {
            obec: 'Libel',
            kod: 548651,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.15517,
            longitude: 16.202418,
        },
        {
            obec: 'Liberk',
            kod: 576450,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51712,
            latitude: 50.199288,
            longitude: 16.343029,
        },
        {
            obec: 'Libchyně',
            kod: 547701,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.356657,
            longitude: 16.190034,
        },
        {
            obec: 'Libňatov',
            kod: 579475,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54236,
            latitude: 50.481436,
            longitude: 16.002299,
        },
        {
            obec: 'Libníkovice',
            kod: 570257,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.244268,
            longitude: 15.996241,
        },
        {
            obec: 'Libošovice',
            kod: 573116,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50744,
            latitude: 50.490669,
            longitude: 15.162912,
        },
        {
            obec: 'Libotov',
            kod: 579483,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.399472,
            longitude: 15.811963,
        },
        {
            obec: 'Librantice',
            kod: 570265,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.242363,
            longitude: 15.95985,
        },
        {
            obec: 'Libřice',
            kod: 570273,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50344,
            latitude: 50.287938,
            longitude: 15.963989,
        },
        {
            obec: 'Libuň',
            kod: 573124,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50715,
            latitude: 50.498255,
            longitude: 15.298603,
        },
        {
            obec: 'Lično',
            kod: 576468,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51735,
            latitude: 50.177967,
            longitude: 16.172124,
        },
        {
            obec: 'Lípa nad Orlicí',
            kod: 576476,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51721,
            latitude: 50.135714,
            longitude: 16.098112,
        },
        {
            obec: 'Lískovice',
            kod: 573086,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.336986,
            longitude: 15.554989,
        },
        {
            obec: 'Lišice',
            kod: 570290,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.181052,
            longitude: 15.408168,
        },
        {
            obec: 'Litíč',
            kod: 546470,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.375254,
            longitude: 15.84848,
        },
        {
            obec: 'Litoboř',
            kod: 573388,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.449962,
            longitude: 16.015368,
        },
        {
            obec: 'Lodín',
            kod: 570303,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.270952,
            longitude: 15.610553,
        },
        {
            obec: 'Lochenice',
            kod: 570311,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50302,
            latitude: 50.272221,
            longitude: 15.820017,
        },
        {
            obec: 'Lovčice',
            kod: 570320,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50361,
            latitude: 50.165301,
            longitude: 15.38474,
        },
        {
            obec: 'Lukavec u Hořic',
            kod: 573141,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.395819,
            longitude: 15.616987,
        },
        {
            obec: 'Lukavice',
            kod: 576492,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51603,
            latitude: 50.200324,
            longitude: 16.293747,
        },
        {
            obec: 'Lupenice',
            kod: 576506,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.132992,
            longitude: 16.273884,
        },
        {
            obec: 'Lužany',
            kod: 570354,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50305,
            latitude: 50.33965,
            longitude: 15.819476,
        },
        {
            obec: 'Lužany',
            kod: 573159,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50706,
            latitude: 50.431154,
            longitude: 15.470396,
        },
        {
            obec: 'Lužec nad Cidlinou',
            kod: 570362,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50362,
            latitude: 50.213644,
            longitude: 15.411967,
        },
        {
            obec: 'Machov',
            kod: 574210,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54963,
            latitude: 50.499363,
            longitude: 16.277008,
        },
        {
            obec: 'Malá Úpa',
            kod: 579505,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54227,
            latitude: 50.744947,
            longitude: 15.820639,
        },
        {
            obec: 'Malé Svatoňovice',
            kod: 579513,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54234,
            latitude: 50.534044,
            longitude: 16.049886,
        },
        {
            obec: 'Markvartice',
            kod: 573167,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50742,
            latitude: 50.430868,
            longitude: 15.196175,
        },
        {
            obec: 'Maršov u Úpice',
            kod: 579530,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54232,
            latitude: 50.484721,
            longitude: 15.976044,
        },
        {
            obec: 'Martínkovice',
            kod: 574228,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54973,
            latitude: 50.547626,
            longitude: 16.342079,
        },
        {
            obec: 'Máslojedy',
            kod: 573779,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.297029,
            longitude: 15.75893,
        },
        {
            obec: 'Měník',
            kod: 570397,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50364,
            latitude: 50.215505,
            longitude: 15.528273,
        },
        {
            obec: 'Mezilečí',
            kod: 574236,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.453464,
            longitude: 15.983292,
        },
        {
            obec: 'Mezilesí',
            kod: 574244,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54923,
            latitude: 50.356207,
            longitude: 16.217095,
        },
        {
            obec: 'Meziměstí',
            kod: 574252,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54981,
            latitude: 50.624705,
            longitude: 16.242178,
        },
        {
            obec: 'Miletín',
            kod: 573175,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.403916,
            longitude: 15.682367,
        },
        {
            obec: 'Milovice u Hořic',
            kod: 548863,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.329866,
            longitude: 15.63112,
        },
        {
            obec: 'Mladé Buky',
            kod: 579548,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54223,
            latitude: 50.606524,
            longitude: 15.833588,
        },
        {
            obec: 'Mladějov',
            kod: 573205,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50745,
            latitude: 50.482181,
            longitude: 15.232724,
        },
        {
            obec: 'Mlázovice',
            kod: 573213,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50758,
            latitude: 50.411963,
            longitude: 15.521712,
        },
        {
            obec: 'Mlékosrby',
            kod: 570401,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.191824,
            longitude: 15.501571,
        },
        {
            obec: 'Mokré',
            kod: 576522,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.259297,
            longitude: 16.066529,
        },
        {
            obec: 'Mokrovousy',
            kod: 570419,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.27513,
            longitude: 15.68594,
        },
        {
            obec: 'Mostek',
            kod: 579556,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54475,
            latitude: 50.486326,
            longitude: 15.696323,
        },
        {
            obec: 'Myštěves',
            kod: 570427,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.297719,
            longitude: 15.557476,
        },
        {
            obec: 'Mžany',
            kod: 570435,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.296508,
            longitude: 15.675954,
        },
        {
            obec: 'Nahořany',
            kod: 574261,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54907,
            latitude: 50.351812,
            longitude: 16.082511,
        },
        {
            obec: 'Náchod',
            kod: 573868,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54701,
            latitude: 50.414672,
            longitude: 16.165735,
        },
        {
            obec: 'Neděliště',
            kod: 570443,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50312,
            latitude: 50.27676,
            longitude: 15.78561,
        },
        {
            obec: 'Nechanice',
            kod: 570451,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.237468,
            longitude: 15.632865,
        },
        {
            obec: 'Nemojov',
            kod: 579564,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54461,
            latitude: 50.467018,
            longitude: 15.753975,
        },
        {
            obec: 'Nemyčeves',
            kod: 573230,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.384534,
            longitude: 15.370126,
        },
        {
            obec: 'Nepolisy',
            kod: 570478,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50363,
            latitude: 50.192306,
            longitude: 15.463452,
        },
        {
            obec: 'Nevratice',
            kod: 549207,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.347043,
            longitude: 15.493052,
        },
        {
            obec: 'Nová Paka',
            kod: 573248,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50901,
            latitude: 50.494593,
            longitude: 15.515138,
        },
        {
            obec: 'Nová Ves',
            kod: 576549,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51721,
            latitude: 50.13079,
            longitude: 16.050209,
        },
        {
            obec: 'Nové Město',
            kod: 570494,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.147769,
            longitude: 15.495214,
        },
        {
            obec: 'Nové Město nad Metují',
            kod: 574279,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.344662,
            longitude: 16.151571,
        },
        {
            obec: 'Nový Bydžov',
            kod: 570508,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.241602,
            longitude: 15.490926,
        },
        {
            obec: 'Nový Hrádek',
            kod: 574287,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54922,
            latitude: 50.357375,
            longitude: 16.244478,
        },
        {
            obec: 'Nový Ples',
            kod: 574295,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.321268,
            longitude: 15.94929,
        },
        {
            obec: 'Obědovice',
            kod: 548065,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.161163,
            longitude: 15.582212,
        },
        {
            obec: 'Očelice',
            kod: 576557,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.239986,
            longitude: 16.067392,
        },
        {
            obec: 'Ohařice',
            kod: 549185,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.454396,
            longitude: 15.258528,
        },
        {
            obec: 'Ohaveč',
            kod: 548910,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.4497,
            longitude: 15.30877,
        },
        {
            obec: 'Ohnišov',
            kod: 576565,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51784,
            latitude: 50.322692,
            longitude: 16.213912,
        },
        {
            obec: 'Ohnišťany',
            kod: 570516,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50354,
            latitude: 50.32963,
            longitude: 15.511087,
        },
        {
            obec: 'Olešnice',
            kod: 570524,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.142363,
            longitude: 15.445092,
        },
        {
            obec: 'Olešnice',
            kod: 576581,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51736,
            latitude: 50.14431,
            longitude: 16.148606,
        },
        {
            obec: 'Olešnice v Orlických horách',
            kod: 576573,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51783,
            latitude: 50.37326,
            longitude: 16.30998,
        },
        {
            obec: 'Opočno',
            kod: 576590,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51773,
            latitude: 50.267515,
            longitude: 16.114902,
        },
        {
            obec: 'Orlické Záhoří',
            kod: 576603,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51764,
            latitude: 50.278761,
            longitude: 16.475033,
        },
        {
            obec: 'Osečnice',
            kod: 576611,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51703,
            latitude: 50.257266,
            longitude: 16.307,
        },
        {
            obec: 'Osek',
            kod: 573264,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50743,
            latitude: 50.463947,
            longitude: 15.159618,
        },
        {
            obec: 'Osice',
            kod: 570532,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50326,
            latitude: 50.142888,
            longitude: 15.686098,
        },
        {
            obec: 'Osičky',
            kod: 570541,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.143649,
            longitude: 15.676288,
        },
        {
            obec: 'Ostroměř',
            kod: 573272,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50752,
            latitude: 50.372556,
            longitude: 15.549593,
        },
        {
            obec: 'Ostružno',
            kod: 573281,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.443397,
            longitude: 15.289734,
        },
        {
            obec: 'Otovice',
            kod: 574317,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54972,
            latitude: 50.557245,
            longitude: 16.387685,
        },
        {
            obec: 'Pec pod Sněžkou',
            kod: 579581,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54221,
            latitude: 50.693981,
            longitude: 15.733267,
        },
        {
            obec: 'Pecka',
            kod: 573299,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50782,
            latitude: 50.480435,
            longitude: 15.608329,
        },
        {
            obec: 'Pěčín',
            kod: 576620,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51757,
            latitude: 50.153878,
            longitude: 16.424789,
        },
        {
            obec: 'Petrovice',
            kod: 570567,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50355,
            latitude: 50.289854,
            longitude: 15.598601,
        },
        {
            obec: 'Petrovičky',
            kod: 548871,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.299084,
            longitude: 15.607452,
        },
        {
            obec: 'Pilníkov',
            kod: 579599,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54242,
            latitude: 50.532571,
            longitude: 15.820303,
        },
        {
            obec: 'Písek',
            kod: 570575,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.15553,
            longitude: 15.501834,
        },
        {
            obec: 'Podbřezí',
            kod: 576654,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51803,
            latitude: 50.259989,
            longitude: 16.214851,
        },
        {
            obec: 'Podhorní Újezd a Vojice',
            kod: 573311,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50754,
            latitude: 50.390156,
            longitude: 15.514528,
        },
        {
            obec: 'Podhradí',
            kod: 573329,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.418572,
            longitude: 15.310632,
        },
        {
            obec: 'Podůlší',
            kod: 573345,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.469069,
            longitude: 15.340577,
        },
        {
            obec: 'Pohoří',
            kod: 576662,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.292257,
            longitude: 16.09906,
        },
        {
            obec: 'Police nad Metují',
            kod: 574341,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54954,
            latitude: 50.536968,
            longitude: 16.23361,
        },
        {
            obec: 'Polom',
            kod: 548723,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.059088,
            longitude: 16.305976,
        },
        {
            obec: 'Potštejn',
            kod: 576671,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51743,
            latitude: 50.082189,
            longitude: 16.309021,
        },
        {
            obec: 'Prasek',
            kod: 570648,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.238775,
            longitude: 15.548695,
        },
        {
            obec: 'Praskačka',
            kod: 570656,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50333,
            latitude: 50.17297,
            longitude: 15.742802,
        },
        {
            obec: 'Proruby',
            kod: 548758,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.070679,
            longitude: 16.295451,
        },
        {
            obec: 'Prosečné',
            kod: 579602,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54373,
            latitude: 50.562125,
            longitude: 15.687438,
        },
        {
            obec: 'Provodov-Šonov',
            kod: 574350,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54908,
            latitude: 50.382206,
            longitude: 16.114965,
        },
        {
            obec: 'Předměřice nad Labem',
            kod: 570672,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50302,
            latitude: 50.256426,
            longitude: 15.815669,
        },
        {
            obec: 'Přepychy',
            kod: 576689,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51732,
            latitude: 50.235959,
            longitude: 16.106634,
        },
        {
            obec: 'Převýšov',
            kod: 570681,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.151634,
            longitude: 15.410541,
        },
        {
            obec: 'Přibyslav',
            kod: 574368,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.373743,
            longitude: 16.16869,
        },
        {
            obec: 'Pšánky',
            kod: 530671,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.294187,
            longitude: 15.620991,
        },
        {
            obec: 'Puchlovice',
            kod: 573531,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.185916,
            longitude: 15.626417,
        },
        {
            obec: 'Račice nad Trotinou',
            kod: 570702,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.315762,
            longitude: 15.796171,
        },
        {
            obec: 'Radíkovice',
            kod: 570711,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.209432,
            longitude: 15.693658,
        },
        {
            obec: 'Radim',
            kod: 573370,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50712,
            latitude: 50.458914,
            longitude: 15.428693,
        },
        {
            obec: 'Radostov',
            kod: 570729,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.20977,
            longitude: 15.663736,
        },
        {
            obec: 'Radvanice',
            kod: 579629,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54212,
            latitude: 50.567552,
            longitude: 16.061824,
        },
        {
            obec: 'Rasošky',
            kod: 574376,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55221,
            latitude: 50.322012,
            longitude: 15.911774,
        },
        {
            obec: 'Rašín',
            kod: 549274,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.333359,
            longitude: 15.678023,
        },
        {
            obec: 'Rohenice',
            kod: 548669,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.309374,
            longitude: 16.032244,
        },
        {
            obec: 'Rohoznice',
            kod: 573221,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.391682,
            longitude: 15.699658,
        },
        {
            obec: 'Rokytňany',
            kod: 548898,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.375876,
            longitude: 15.137489,
        },
        {
            obec: 'Rokytnice v Orlických horách',
            kod: 576701,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51761,
            latitude: 50.164831,
            longitude: 16.46578,
        },
        {
            obec: 'Roudnice',
            kod: 570745,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.176262,
            longitude: 15.651804,
        },
        {
            obec: 'Rožnov',
            kod: 574384,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.338147,
            longitude: 15.86139,
        },
        {
            obec: 'Rtyně v Podkrkonoší',
            kod: 579637,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54233,
            latitude: 50.505329,
            longitude: 16.071973,
        },
        {
            obec: 'Rudník',
            kod: 579645,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54372,
            latitude: 50.578698,
            longitude: 15.736956,
        },
        {
            obec: 'Rybná nad Zdobnicí',
            kod: 576727,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51755,
            latitude: 50.10864,
            longitude: 16.365963,
        },
        {
            obec: 'Rychnov nad Kněžnou',
            kod: 576069,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51601,
            latitude: 50.166065,
            longitude: 16.277784,
        },
        {
            obec: 'Rychnovek',
            kod: 574406,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55225,
            latitude: 50.357467,
            longitude: 15.970419,
        },
        {
            obec: 'Říčky v Orlických horách',
            kod: 576735,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51761,
            latitude: 50.211092,
            longitude: 16.45912,
        },
        {
            obec: 'Říkov',
            kod: 530786,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55203,
            latitude: 50.384385,
            longitude: 16.016933,
        },
        {
            obec: 'Sadová',
            kod: 573191,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.297512,
            longitude: 15.700009,
        },
        {
            obec: 'Samšina',
            kod: 573442,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.459432,
            longitude: 15.23502,
        },
        {
            obec: 'Sběř',
            kod: 573451,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.320225,
            longitude: 15.424479,
        },
        {
            obec: 'Sedliště',
            kod: 548961,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.394226,
            longitude: 15.219151,
        },
        {
            obec: 'Sedloňov',
            kod: 576743,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51791,
            latitude: 50.338401,
            longitude: 16.315588,
        },
        {
            obec: 'Sekeřice',
            kod: 572144,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.286507,
            longitude: 15.380794,
        },
        {
            obec: 'Semechnice',
            kod: 576751,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.260342,
            longitude: 16.148495,
        },
        {
            obec: 'Sendraž',
            kod: 547727,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.367711,
            longitude: 16.202186,
        },
        {
            obec: 'Sendražice',
            kod: 570796,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.289952,
            longitude: 15.796074,
        },
        {
            obec: 'Skalice',
            kod: 570800,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.27647,
            longitude: 15.872134,
        },
        {
            obec: 'Skřivany',
            kod: 570834,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50352,
            latitude: 50.268616,
            longitude: 15.499348,
        },
        {
            obec: 'Skuhrov nad Bělou',
            kod: 576778,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51703,
            latitude: 50.229557,
            longitude: 16.29241,
        },
        {
            obec: 'Slatina nad Úpou',
            kod: 574422,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54947,
            latitude: 50.454193,
            longitude: 16.036726,
        },
        {
            obec: 'Slatina nad Zdobnicí',
            kod: 576786,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51756,
            latitude: 50.133907,
            longitude: 16.397982,
        },
        {
            obec: 'Slatiny',
            kod: 573469,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.366869,
            longitude: 15.381502,
        },
        {
            obec: 'Slavětín nad Metují',
            kod: 574431,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.329,
            longitude: 16.052982,
        },
        {
            obec: 'Slavhostice',
            kod: 572187,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.308911,
            longitude: 15.349485,
        },
        {
            obec: 'Slavoňov',
            kod: 574457,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.343852,
            longitude: 16.204042,
        },
        {
            obec: 'Sloupno',
            kod: 570851,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50353,
            latitude: 50.257418,
            longitude: 15.502075,
        },
        {
            obec: 'Smidary',
            kod: 570869,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50353,
            latitude: 50.291556,
            longitude: 15.477355,
        },
        {
            obec: 'Smiřice',
            kod: 570877,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.299879,
            longitude: 15.865181,
        },
        {
            obec: 'Smržov',
            kod: 570885,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.299846,
            longitude: 15.917943,
        },
        {
            obec: 'Sněžné',
            kod: 576794,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.340016,
            longitude: 16.277368,
        },
        {
            obec: 'Sobčice',
            kod: 573477,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.372376,
            longitude: 15.514931,
        },
        {
            obec: 'Soběraz',
            kod: 573361,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50713,
            latitude: 50.463465,
            longitude: 15.402094,
        },
        {
            obec: 'Sobotka',
            kod: 573493,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50743,
            latitude: 50.467515,
            longitude: 15.176299,
        },
        {
            obec: 'Solnice',
            kod: 576808,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51701,
            latitude: 50.203755,
            longitude: 16.237723,
        },
        {
            obec: 'Sovětice',
            kod: 570907,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.307552,
            longitude: 15.705857,
        },
        {
            obec: 'Stanovice',
            kod: 548812,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.400709,
            longitude: 15.871501,
        },
        {
            obec: 'Stará Paka',
            kod: 573507,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50791,
            latitude: 50.510424,
            longitude: 15.49455,
        },
        {
            obec: 'Stará Voda',
            kod: 570915,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50351,
            latitude: 50.150422,
            longitude: 15.530668,
        },
        {
            obec: 'Staré Buky',
            kod: 579661,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54101,
            latitude: 50.532161,
            longitude: 15.859366,
        },
        {
            obec: 'Staré Hrady',
            kod: 530735,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.386111,
            longitude: 15.213064,
        },
        {
            obec: 'Staré Místo',
            kod: 549096,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.40511,
            longitude: 15.342338,
        },
        {
            obec: 'Staré Smrkovice',
            kod: 573523,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.336688,
            longitude: 15.494647,
        },
        {
            obec: 'Stárkov',
            kod: 574465,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54936,
            latitude: 50.534022,
            longitude: 16.158279,
        },
        {
            obec: 'Starý Bydžov',
            kod: 573132,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50357,
            latitude: 50.25854,
            longitude: 15.452434,
        },
        {
            obec: 'Stěžery',
            kod: 570931,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50321,
            latitude: 50.215821,
            longitude: 15.748412,
        },
        {
            obec: 'Stračov',
            kod: 570958,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50314,
            latitude: 50.301628,
            longitude: 15.64079,
        },
        {
            obec: 'Strážné',
            kod: 579696,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54352,
            latitude: 50.663681,
            longitude: 15.615183,
        },
        {
            obec: 'Střevač',
            kod: 573540,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50722,
            latitude: 50.404458,
            longitude: 15.274587,
        },
        {
            obec: 'Střezetice',
            kod: 570966,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50312,
            latitude: 50.258368,
            longitude: 15.718242,
        },
        {
            obec: 'Studnice',
            kod: 574481,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54948,
            latitude: 50.421368,
            longitude: 16.089978,
        },
        {
            obec: 'Suchovršice',
            kod: 579726,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54232,
            latitude: 50.523502,
            longitude: 15.99831,
        },
        {
            obec: 'Suchý Důl',
            kod: 574490,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54962,
            latitude: 50.540173,
            longitude: 16.265476,
        },
        {
            obec: 'Sukorady',
            kod: 548880,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.325546,
            longitude: 15.582808,
        },
        {
            obec: 'Svatojanský Újezd',
            kod: 573302,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50781,
            latitude: 50.42605,
            longitude: 15.543039,
        },
        {
            obec: 'Světí',
            kod: 548154,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50312,
            latitude: 50.257478,
            longitude: 15.775579,
        },
        {
            obec: 'Svídnice',
            kod: 548693,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.086151,
            longitude: 16.223384,
        },
        {
            obec: 'Svoboda nad Úpou',
            kod: 579734,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54224,
            latitude: 50.626061,
            longitude: 15.816588,
        },
        {
            obec: 'Synkov-Slemeno',
            kod: 576816,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51601,
            latitude: 50.145372,
            longitude: 16.219056,
        },
        {
            obec: 'Syrovátka',
            kod: 571008,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.154859,
            longitude: 15.662838,
        },
        {
            obec: 'Šaplava',
            kod: 571016,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50353,
            latitude: 50.315142,
            longitude: 15.542057,
        },
        {
            obec: 'Šárovcova Lhota',
            kod: 573256,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50759,
            latitude: 50.407609,
            longitude: 15.562386,
        },
        {
            obec: 'Šestajovice',
            kod: 547654,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.341229,
            longitude: 16.008082,
        },
        {
            obec: 'Šonov',
            kod: 574511,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54971,
            latitude: 50.590932,
            longitude: 16.401645,
        },
        {
            obec: 'Špindlerův Mlýn',
            kod: 579742,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54351,
            latitude: 50.72625,
            longitude: 15.609549,
        },
        {
            obec: 'Těchlovice',
            kod: 571024,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.207992,
            longitude: 15.711159,
        },
        {
            obec: 'Teplice nad Metují',
            kod: 574538,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54957,
            latitude: 50.59443,
            longitude: 16.170483,
        },
        {
            obec: 'Tetín',
            kod: 572756,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.42462,
            longitude: 15.636457,
        },
        {
            obec: 'Trnov',
            kod: 576824,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51733,
            latitude: 50.244775,
            longitude: 16.164517,
        },
        {
            obec: 'Trotina',
            kod: 579751,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.409736,
            longitude: 15.716281,
        },
        {
            obec: 'Trutnov',
            kod: 579025,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54101,
            latitude: 50.565484,
            longitude: 15.909192,
        },
        {
            obec: 'Třebechovice pod Orebem',
            kod: 571041,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50346,
            latitude: 50.201066,
            longitude: 15.992336,
        },
        {
            obec: 'Třebešov',
            kod: 576832,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51601,
            latitude: 50.16845,
            longitude: 16.204241,
        },
        {
            obec: 'Třebihošť',
            kod: 579769,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.433387,
            longitude: 15.709795,
        },
        {
            obec: 'Třebnouševes',
            kod: 573612,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50801,
            latitude: 50.339344,
            longitude: 15.651767,
        },
        {
            obec: 'Třesovice',
            kod: 571059,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50315,
            latitude: 50.265178,
            longitude: 15.688982,
        },
        {
            obec: 'Třtěnice',
            kod: 573639,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50704,
            latitude: 50.377493,
            longitude: 15.469231,
        },
        {
            obec: 'Tuř',
            kod: 573647,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.395077,
            longitude: 15.417033,
        },
        {
            obec: 'Tutleky',
            kod: 576841,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.134014,
            longitude: 16.241556,
        },
        {
            obec: 'Týniště nad Orlicí',
            kod: 576859,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51721,
            latitude: 50.151203,
            longitude: 16.078551,
        },
        {
            obec: 'Úbislavice',
            kod: 573655,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50792,
            latitude: 50.473031,
            longitude: 15.47042,
        },
        {
            obec: 'Údrnice',
            kod: 573663,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.374485,
            longitude: 15.261676,
        },
        {
            obec: 'Úhlejov',
            kod: 573671,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.427287,
            longitude: 15.687441,
        },
        {
            obec: 'Újezd pod Troskami',
            kod: 573680,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51263,
            latitude: 50.506631,
            longitude: 15.262655,
        },
        {
            obec: 'Úlibice',
            kod: 573698,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50707,
            latitude: 50.43413,
            longitude: 15.437401,
        },
        {
            obec: 'Úpice',
            kod: 579777,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54232,
            latitude: 50.512472,
            longitude: 16.016173,
        },
        {
            obec: 'Urbanice',
            kod: 513717,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50327,
            latitude: 50.18002,
            longitude: 15.727458,
        },
        {
            obec: 'Val',
            kod: 576875,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51801,
            latitude: 50.311302,
            longitude: 16.181692,
        },
        {
            obec: 'Valdice',
            kod: 573701,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50711,
            latitude: 50.455113,
            longitude: 15.384989,
        },
        {
            obec: 'Vamberk',
            kod: 576883,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51754,
            latitude: 50.117657,
            longitude: 16.290779,
        },
        {
            obec: 'Velichovky',
            kod: 574554,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55211,
            latitude: 50.356869,
            longitude: 15.84179,
        },
        {
            obec: 'Veliš',
            kod: 573728,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50721,
            latitude: 50.407596,
            longitude: 15.317707,
        },
        {
            obec: 'Velká Jesenice',
            kod: 574562,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55224,
            latitude: 50.361175,
            longitude: 16.037736,
        },
        {
            obec: 'Velké Petrovice',
            kod: 574571,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54954,
            latitude: 50.517437,
            longitude: 16.206593,
        },
        {
            obec: 'Velké Poříčí',
            kod: 547646,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54932,
            latitude: 50.46187,
            longitude: 16.189414,
        },
        {
            obec: 'Velké Svatoňovice',
            kod: 579785,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54235,
            latitude: 50.531736,
            longitude: 16.02864,
        },
        {
            obec: 'Velký Třebešov',
            kod: 574589,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55202,
            latitude: 50.392984,
            longitude: 16.006468,
        },
        {
            obec: 'Velký Vřešťov',
            kod: 579793,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54454,
            latitude: 50.358583,
            longitude: 15.751652,
        },
        {
            obec: 'Vernéřovice',
            kod: 547743,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54982,
            latitude: 50.614973,
            longitude: 16.221073,
        },
        {
            obec: 'Vestec',
            kod: 547565,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55205,
            latitude: 50.421946,
            longitude: 16.02139,
        },
        {
            obec: 'Vidochov',
            kod: 573736,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50901,
            latitude: 50.512639,
            longitude: 15.565907,
        },
        {
            obec: 'Vilantice',
            kod: 574597,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.365933,
            longitude: 15.788877,
        },
        {
            obec: 'Vinary',
            kod: 571083,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50353,
            latitude: 50.289608,
            longitude: 15.428963,
        },
        {
            obec: 'Vítězná',
            kod: 579815,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54462,
            latitude: 50.488959,
            longitude: 15.802302,
        },
        {
            obec: 'Vitiněves',
            kod: 573752,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.393404,
            longitude: 15.378316,
        },
        {
            obec: 'Vlčice',
            kod: 579823,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54101,
            latitude: 50.562693,
            longitude: 15.821685,
        },
        {
            obec: 'Vlčkovice v Podkrkonoší',
            kod: 579831,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.411116,
            longitude: 15.918264,
        },
        {
            obec: 'Vlkov',
            kod: 574601,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.312721,
            longitude: 15.898493,
        },
        {
            obec: 'Voděrady',
            kod: 576891,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51734,
            latitude: 50.208788,
            longitude: 16.153245,
        },
        {
            obec: 'Volanice',
            kod: 573761,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.335635,
            longitude: 15.398381,
        },
        {
            obec: 'Vrbice',
            kod: 572128,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.369139,
            longitude: 15.428878,
        },
        {
            obec: 'Vrbice',
            kod: 548707,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51741,
            latitude: 50.08923,
            longitude: 16.251784,
        },
        {
            obec: 'Vrchlabí',
            kod: 579858,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54301,
            latitude: 50.617189,
            longitude: 15.605106,
        },
        {
            obec: 'Vrchovnice',
            kod: 548057,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.323032,
            longitude: 15.757627,
        },
        {
            obec: 'Vršce',
            kod: 573795,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50733,
            latitude: 50.323952,
            longitude: 15.323155,
        },
        {
            obec: 'Vršovka',
            kod: 574627,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54901,
            latitude: 50.323287,
            longitude: 16.120261,
        },
        {
            obec: 'Vřesník',
            kod: 549029,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50771,
            latitude: 50.438105,
            longitude: 15.628263,
        },
        {
            obec: 'Všestary',
            kod: 571091,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50312,
            latitude: 50.255417,
            longitude: 15.760224,
        },
        {
            obec: 'Výrava',
            kod: 571105,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50303,
            latitude: 50.269739,
            longitude: 15.979052,
        },
        {
            obec: 'Vysoká nad Labem',
            kod: 571113,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50331,
            latitude: 50.153472,
            longitude: 15.824842,
        },
        {
            obec: 'Vysoká Srbská',
            kod: 574635,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54931,
            latitude: 50.487806,
            longitude: 16.22766,
        },
        {
            obec: 'Vysoké Veselí',
            kod: 573809,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50703,
            latitude: 50.331055,
            longitude: 15.436421,
        },
        {
            obec: 'Vysokov',
            kod: 574643,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54912,
            latitude: 50.403868,
            longitude: 16.116742,
        },
        {
            obec: 'Vysoký Újezd',
            kod: 548677,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51771,
            latitude: 50.24034,
            longitude: 16.020182,
        },
        {
            obec: 'Zábrodí',
            kod: 574651,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54941,
            latitude: 50.459652,
            longitude: 16.11255,
        },
        {
            obec: 'Zábřezí-Řečice',
            kod: 548839,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.411359,
            longitude: 15.735635,
        },
        {
            obec: 'Zachrašťany',
            kod: 571130,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.211244,
            longitude: 15.483086,
        },
        {
            obec: 'Zaloňov',
            kod: 574660,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55101,
            latitude: 50.374068,
            longitude: 15.88795,
        },
        {
            obec: 'Záměl',
            kod: 576921,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51743,
            latitude: 50.096649,
            longitude: 16.298906,
        },
        {
            obec: 'Zámostí-Blata',
            kod: 549193,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50601,
            latitude: 50.470898,
            longitude: 15.264128,
        },
        {
            obec: 'Zdechovice',
            kod: 573744,
            okres: 'Hradec Králové',
            kod_okres: 'CZ0521',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50401,
            latitude: 50.224827,
            longitude: 15.56176,
        },
        {
            obec: 'Zdelov',
            kod: 576930,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51721,
            latitude: 50.100779,
            longitude: 16.142255,
        },
        {
            obec: 'Zdobín',
            kod: 548847,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54401,
            latitude: 50.415397,
            longitude: 15.704708,
        },
        {
            obec: 'Zdobnice',
            kod: 576948,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51601,
            latitude: 50.238579,
            longitude: 16.408683,
        },
        {
            obec: 'Zelenecká Lhota',
            kod: 573183,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50723,
            latitude: 50.401703,
            longitude: 15.178861,
        },
        {
            obec: 'Zlatá Olešnice',
            kod: 579866,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54101,
            latitude: 50.61779,
            longitude: 15.944887,
        },
        {
            obec: 'Žacléř',
            kod: 579874,
            okres: 'Trutnov',
            kod_okres: 'CZ0525',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54201,
            latitude: 50.663393,
            longitude: 15.91074,
        },
        {
            obec: 'Žďár nad Metují',
            kod: 574686,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54955,
            latitude: 50.538847,
            longitude: 16.213389,
        },
        {
            obec: 'Žďár nad Orlicí',
            kod: 576956,
            okres: 'Rychnov nad Kněžnou',
            kod_okres: 'CZ0524',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 51723,
            latitude: 50.119855,
            longitude: 16.069446,
        },
        {
            obec: 'Žďárky',
            kod: 574694,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 54937,
            latitude: 50.469046,
            longitude: 16.227922,
        },
        {
            obec: 'Železnice',
            kod: 573825,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50713,
            latitude: 50.472837,
            longitude: 15.384697,
        },
        {
            obec: 'Žeretice',
            kod: 573833,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50702,
            latitude: 50.353646,
            longitude: 15.411844,
        },
        {
            obec: 'Žernov',
            kod: 574708,
            okres: 'Náchod',
            kod_okres: 'CZ0523',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 55203,
            latitude: 50.430501,
            longitude: 16.05755,
        },
        {
            obec: 'Židovice',
            kod: 573841,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50732,
            latitude: 50.295469,
            longitude: 15.32061,
        },
        {
            obec: 'Žlunice',
            kod: 573850,
            okres: 'Jičín',
            kod_okres: 'CZ0522',
            kraj: 'Královéhradecký kraj',
            kod_kraj: 'CZ052',
            psc: 50734,
            latitude: 50.30222,
            longitude: 15.382081,
        },
    ],
    CZ080: [
        {
            obec: 'Albrechtice',
            kod: 598925,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73543,
            latitude: 49.786552,
            longitude: 18.524545,
        },
        {
            obec: 'Albrechtičky',
            kod: 568741,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74255,
            latitude: 49.70179,
            longitude: 18.095627,
        },
        {
            obec: 'Andělská Hora',
            kod: 551929,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79331,
            latitude: 50.060911,
            longitude: 17.389192,
        },
        {
            obec: 'Bartošovice',
            kod: 599212,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74254,
            latitude: 49.66894,
            longitude: 18.054715,
        },
        {
            obec: 'Baška',
            kod: 598011,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73901,
            latitude: 49.645943,
            longitude: 18.372443,
        },
        {
            obec: 'Bělá',
            kod: 512974,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74723,
            latitude: 49.972429,
            longitude: 18.144995,
        },
        {
            obec: 'Bernartice nad Odrou',
            kod: 568481,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74101,
            latitude: 49.610207,
            longitude: 17.947885,
        },
        {
            obec: 'Bílá',
            kod: 598020,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73915,
            latitude: 49.442171,
            longitude: 18.453037,
        },
        {
            obec: 'Bílčice',
            kod: 597198,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79368,
            latitude: 49.869715,
            longitude: 17.565611,
        },
        {
            obec: 'Bílov',
            kod: 546984,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74301,
            latitude: 49.73417,
            longitude: 18.001712,
        },
        {
            obec: 'Bílovec',
            kod: 599247,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74301,
            latitude: 49.756486,
            longitude: 18.015919,
        },
        {
            obec: 'Bítov',
            kod: 554936,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74301,
            latitude: 49.800422,
            longitude: 18.047592,
        },
        {
            obec: 'Bocanovice',
            kod: 511633,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73991,
            latitude: 49.569174,
            longitude: 18.738514,
        },
        {
            obec: 'Bohumín',
            kod: 599051,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73581,
            latitude: 49.904426,
            longitude: 18.357074,
        },
        {
            obec: 'Bohuslavice',
            kod: 506192,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74719,
            latitude: 49.942415,
            longitude: 18.128764,
        },
        {
            obec: 'Bohušov',
            kod: 597201,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.243389,
            longitude: 17.713963,
        },
        {
            obec: 'Bolatice',
            kod: 506214,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74723,
            latitude: 49.951819,
            longitude: 18.08369,
        },
        {
            obec: 'Bordovice',
            kod: 568431,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74401,
            latitude: 49.546979,
            longitude: 18.152729,
        },
        {
            obec: 'Branka u Opavy',
            kod: 554197,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74741,
            latitude: 49.888057,
            longitude: 17.882754,
        },
        {
            obec: 'Brantice',
            kod: 597210,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79393,
            latitude: 50.063603,
            longitude: 17.629214,
        },
        {
            obec: 'Bratříkovice',
            kod: 553107,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74752,
            latitude: 49.932674,
            longitude: 17.687641,
        },
        {
            obec: 'Bravantice',
            kod: 556858,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74281,
            latitude: 49.757218,
            longitude: 18.082863,
        },
        {
            obec: 'Brumovice',
            kod: 506320,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74771,
            latitude: 50.015393,
            longitude: 17.749685,
        },
        {
            obec: 'Bruntál',
            kod: 597180,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.988277,
            longitude: 17.463794,
        },
        {
            obec: 'Brušperk',
            kod: 598038,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73944,
            latitude: 49.700196,
            longitude: 18.222205,
        },
        {
            obec: 'Bruzovice',
            kod: 598046,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73936,
            latitude: 49.717092,
            longitude: 18.409749,
        },
        {
            obec: 'Březová',
            kod: 506451,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74744,
            latitude: 49.791678,
            longitude: 17.865664,
        },
        {
            obec: 'Břidličná',
            kod: 597228,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79351,
            latitude: 49.911767,
            longitude: 17.371179,
        },
        {
            obec: 'Budišov nad Budišovkou',
            kod: 506460,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74787,
            latitude: 49.79521,
            longitude: 17.629792,
        },
        {
            obec: 'Budišovice',
            kod: 568261,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74764,
            latitude: 49.861418,
            longitude: 18.039719,
        },
        {
            obec: 'Bukovec',
            kod: 511935,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73984,
            latitude: 49.556686,
            longitude: 18.821553,
        },
        {
            obec: 'Býkov-Láryšov',
            kod: 569607,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79401,
            latitude: 50.052449,
            longitude: 17.687486,
        },
        {
            obec: 'Bystřice',
            kod: 598062,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73995,
            latitude: 49.63667,
            longitude: 18.720488,
        },
        {
            obec: 'Čaková',
            kod: 552003,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79316,
            latitude: 50.051137,
            longitude: 17.548483,
        },
        {
            obec: 'Čavisov',
            kod: 569119,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74764,
            latitude: 49.829577,
            longitude: 18.080797,
        },
        {
            obec: 'Čeladná',
            kod: 598071,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73912,
            latitude: 49.548829,
            longitude: 18.337697,
        },
        {
            obec: 'Čermná ve Slezsku',
            kod: 569950,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74901,
            latitude: 49.782431,
            longitude: 17.702213,
        },
        {
            obec: 'Český Těšín',
            kod: 598933,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73701,
            latitude: 49.747144,
            longitude: 18.623896,
        },
        {
            obec: 'Darkovice',
            kod: 568228,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74717,
            latitude: 49.936016,
            longitude: 18.222234,
        },
        {
            obec: 'Děhylov',
            kod: 568236,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74794,
            latitude: 49.869481,
            longitude: 18.163431,
        },
        {
            obec: 'Dětmarovice',
            kod: 598941,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73571,
            latitude: 49.894214,
            longitude: 18.460901,
        },
        {
            obec: 'Dětřichov nad Bystřicí',
            kod: 597252,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79303,
            latitude: 49.834288,
            longitude: 17.401831,
        },
        {
            obec: 'Dívčí Hrad',
            kod: 551864,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.243942,
            longitude: 17.633923,
        },
        {
            obec: 'Dlouhá Stráň',
            kod: 551708,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.960143,
            longitude: 17.500561,
        },
        {
            obec: 'Dobrá',
            kod: 598089,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.673927,
            longitude: 18.414037,
        },
        {
            obec: 'Dobratice',
            kod: 552542,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.660491,
            longitude: 18.492374,
        },
        {
            obec: 'Dobroslavice',
            kod: 569895,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74794,
            latitude: 49.880434,
            longitude: 18.141029,
        },
        {
            obec: 'Dolní Benešov',
            kod: 506702,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74722,
            latitude: 49.923215,
            longitude: 18.101406,
        },
        {
            obec: 'Dolní Domaslavice',
            kod: 598101,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73938,
            latitude: 49.712825,
            longitude: 18.481063,
        },
        {
            obec: 'Dolní Lhota',
            kod: 506711,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74766,
            latitude: 49.842458,
            longitude: 18.092517,
        },
        {
            obec: 'Dolní Lomná',
            kod: 511951,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73991,
            latitude: 49.547928,
            longitude: 18.706198,
        },
        {
            obec: 'Dolní Lutyně',
            kod: 598968,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73553,
            latitude: 49.898856,
            longitude: 18.428262,
        },
        {
            obec: 'Dolní Moravice',
            kod: 597287,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79501,
            latitude: 49.978038,
            longitude: 17.32237,
        },
        {
            obec: 'Dolní Tošanovice',
            kod: 552607,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73953,
            latitude: 49.684257,
            longitude: 18.488568,
        },
        {
            obec: 'Dolní Životice',
            kod: 553051,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74756,
            latitude: 49.897418,
            longitude: 17.779797,
        },
        {
            obec: 'Doubrava',
            kod: 568864,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73533,
            latitude: 49.858829,
            longitude: 18.480287,
        },
        {
            obec: 'Dvorce',
            kod: 597317,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79368,
            latitude: 49.833416,
            longitude: 17.547725,
        },
        {
            obec: 'Frenštát pod Radhoštěm',
            kod: 599344,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74401,
            latitude: 49.548445,
            longitude: 18.21089,
        },
        {
            obec: 'Fryčovice',
            kod: 598135,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73945,
            latitude: 49.666867,
            longitude: 18.223315,
        },
        {
            obec: 'FRÝDEK-MÍSTEK',
            kod: 598003,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73801,
            latitude: 49.682031,
            longitude: 18.367422,
        },
        {
            obec: 'Frýdlant nad Ostravicí',
            kod: 598143,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73911,
            latitude: 49.591902,
            longitude: 18.358605,
        },
        {
            obec: 'Fulnek',
            kod: 599352,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74245,
            latitude: 49.712474,
            longitude: 17.903301,
        },
        {
            obec: 'Háj ve Slezsku',
            kod: 506753,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74792,
            latitude: 49.899842,
            longitude: 18.089995,
        },
        {
            obec: 'Hať',
            kod: 568210,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74716,
            latitude: 49.94654,
            longitude: 18.23942,
        },
        {
            obec: 'HAVÍŘOV',
            kod: 555088,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73601,
            latitude: 49.780492,
            longitude: 18.430725,
        },
        {
            obec: 'Heřmanice u Oder',
            kod: 568562,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.713775,
            longitude: 17.805443,
        },
        {
            obec: 'Heřmánky',
            kod: 568571,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.707069,
            longitude: 17.768413,
        },
        {
            obec: 'Heřmanovice',
            kod: 597325,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79374,
            latitude: 50.193392,
            longitude: 17.397871,
        },
        {
            obec: 'Hladké Životice',
            kod: 569666,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74247,
            latitude: 49.683,
            longitude: 17.957475,
        },
        {
            obec: 'Hlavnice',
            kod: 553093,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74752,
            latitude: 49.924487,
            longitude: 17.727963,
        },
        {
            obec: 'Hlinka',
            kod: 551872,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.280106,
            longitude: 17.672253,
        },
        {
            obec: 'Hlubočec',
            kod: 568368,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74769,
            latitude: 49.840666,
            longitude: 17.97043,
        },
        {
            obec: 'Hlučín',
            kod: 507016,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74801,
            latitude: 49.896627,
            longitude: 18.190649,
        },
        {
            obec: 'Hněvošice',
            kod: 507105,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74735,
            latitude: 50.00309,
            longitude: 18.008393,
        },
        {
            obec: 'Hnojník',
            kod: 598160,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73953,
            latitude: 49.682383,
            longitude: 18.541393,
        },
        {
            obec: 'Hodslavice',
            kod: 599409,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74271,
            latitude: 49.53866,
            longitude: 18.023783,
        },
        {
            obec: 'Holasovice',
            kod: 507113,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74774,
            latitude: 49.998525,
            longitude: 17.808573,
        },
        {
            obec: 'Holčovice',
            kod: 597341,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79371,
            latitude: 50.156849,
            longitude: 17.486079,
        },
        {
            obec: 'Horní Benešov',
            kod: 597350,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79312,
            latitude: 49.966918,
            longitude: 17.60273,
        },
        {
            obec: 'Horní Bludovice',
            kod: 598178,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73937,
            latitude: 49.749749,
            longitude: 18.436881,
        },
        {
            obec: 'Horní Domaslavice',
            kod: 512192,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.694745,
            longitude: 18.461438,
        },
        {
            obec: 'Horní Lhota',
            kod: 569500,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74764,
            latitude: 49.853142,
            longitude: 18.068485,
        },
        {
            obec: 'Horní Lomná',
            kod: 511986,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73991,
            latitude: 49.527977,
            longitude: 18.637844,
        },
        {
            obec: 'Horní Město',
            kod: 597368,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79344,
            latitude: 49.908546,
            longitude: 17.211221,
        },
        {
            obec: 'Horní Suchá',
            kod: 552739,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73535,
            latitude: 49.797901,
            longitude: 18.481997,
        },
        {
            obec: 'Horní Tošanovice',
            kod: 552631,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73953,
            latitude: 49.692472,
            longitude: 18.505428,
        },
        {
            obec: 'Horní Životice',
            kod: 551805,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79312,
            latitude: 49.968984,
            longitude: 17.6409,
        },
        {
            obec: 'Hostašovice',
            kod: 568511,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74101,
            latitude: 49.534363,
            longitude: 17.997408,
        },
        {
            obec: 'Hošťálkovy',
            kod: 597392,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79401,
            latitude: 50.111701,
            longitude: 17.599627,
        },
        {
            obec: 'Hrabyně',
            kod: 507261,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74763,
            latitude: 49.882583,
            longitude: 18.054935,
        },
        {
            obec: 'Hradec nad Moravicí',
            kod: 507270,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74741,
            latitude: 49.871107,
            longitude: 17.875924,
        },
        {
            obec: 'Hrádek',
            kod: 512176,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73997,
            latitude: 49.616708,
            longitude: 18.737315,
        },
        {
            obec: 'Hrčava',
            kod: 598232,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73998,
            latitude: 49.520116,
            longitude: 18.833235,
        },
        {
            obec: 'Hukvaldy',
            kod: 598691,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73946,
            latitude: 49.623907,
            longitude: 18.221998,
        },
        {
            obec: 'Chlebičov',
            kod: 512923,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74731,
            latitude: 49.959517,
            longitude: 17.967589,
        },
        {
            obec: 'Chotěbuz',
            kod: 555291,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73561,
            latitude: 49.768592,
            longitude: 18.569232,
        },
        {
            obec: 'Chuchelná',
            kod: 507334,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74724,
            latitude: 49.986735,
            longitude: 18.116667,
        },
        {
            obec: 'Chvalíkovice',
            kod: 569909,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74706,
            latitude: 49.885738,
            longitude: 17.911052,
        },
        {
            obec: 'Jablunkov',
            kod: 598259,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73991,
            latitude: 49.576815,
            longitude: 18.764694,
        },
        {
            obec: 'Jakartovice',
            kod: 507377,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74753,
            latitude: 49.915196,
            longitude: 17.684108,
        },
        {
            obec: 'Jakubčovice nad Odrou',
            kod: 554065,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74236,
            latitude: 49.695138,
            longitude: 17.787781,
        },
        {
            obec: 'Janov',
            kod: 597431,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79384,
            latitude: 50.244949,
            longitude: 17.481294,
        },
        {
            obec: 'Janovice',
            kod: 507423,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73911,
            latitude: 49.621505,
            longitude: 18.406126,
        },
        {
            obec: 'Jeseník nad Odrou',
            kod: 599468,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74233,
            latitude: 49.61204,
            longitude: 17.905365,
        },
        {
            obec: 'Jezdkovice',
            kod: 547191,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74755,
            latitude: 49.923129,
            longitude: 17.774912,
        },
        {
            obec: 'Jindřichov',
            kod: 597449,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79383,
            latitude: 50.251932,
            longitude: 17.519131,
        },
        {
            obec: 'Jiříkov',
            kod: 597457,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79351,
            latitude: 49.854028,
            longitude: 17.277651,
        },
        {
            obec: 'Jistebník',
            kod: 599506,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74282,
            latitude: 49.754094,
            longitude: 18.130739,
        },
        {
            obec: 'Kaňovice',
            kod: 598275,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73936,
            latitude: 49.744405,
            longitude: 18.39622,
        },
        {
            obec: 'Karlova Studánka',
            kod: 597473,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79324,
            latitude: 50.073193,
            longitude: 17.30696,
        },
        {
            obec: 'Karlovice',
            kod: 597481,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79326,
            latitude: 50.105736,
            longitude: 17.445738,
        },
        {
            obec: 'KARVINÁ',
            kod: 598917,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73301,
            latitude: 49.856752,
            longitude: 18.543319,
        },
        {
            obec: 'Kateřinice',
            kod: 568643,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74258,
            latitude: 49.669288,
            longitude: 18.188284,
        },
        {
            obec: 'Klimkovice',
            kod: 599549,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74283,
            latitude: 49.788191,
            longitude: 18.125961,
        },
        {
            obec: 'Kobeřice',
            kod: 507504,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74727,
            latitude: 49.98558,
            longitude: 18.052227,
        },
        {
            obec: 'Komorní Lhotka',
            kod: 507237,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73953,
            latitude: 49.658204,
            longitude: 18.527882,
        },
        {
            obec: 'Kopřivnice',
            kod: 599565,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74221,
            latitude: 49.59957,
            longitude: 18.14491,
        },
        {
            obec: 'Košařiska',
            kod: 507458,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73981,
            latitude: 49.589496,
            longitude: 18.69578,
        },
        {
            obec: 'Kozlovice',
            kod: 598321,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73947,
            latitude: 49.590494,
            longitude: 18.256672,
        },
        {
            obec: 'Kozmice',
            kod: 547182,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74711,
            latitude: 49.9129,
            longitude: 18.15595,
        },
        {
            obec: 'Krásná',
            kod: 549673,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73904,
            latitude: 49.582088,
            longitude: 18.481485,
        },
        {
            obec: 'Krasov',
            kod: 597511,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79401,
            latitude: 50.091869,
            longitude: 17.546206,
        },
        {
            obec: 'Kravaře',
            kod: 507580,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74721,
            latitude: 49.93213,
            longitude: 18.004827,
        },
        {
            obec: 'Krmelín',
            kod: 598348,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73924,
            latitude: 49.729234,
            longitude: 18.235519,
        },
        {
            obec: 'Krnov',
            kod: 597520,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79401,
            latitude: 50.090683,
            longitude: 17.703168,
        },
        {
            obec: 'Kružberk',
            kod: 568180,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74786,
            latitude: 49.831881,
            longitude: 17.683296,
        },
        {
            obec: 'Křišťanovice',
            kod: 597538,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79368,
            latitude: 49.850911,
            longitude: 17.51114,
        },
        {
            obec: 'Kujavy',
            kod: 555312,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74245,
            latitude: 49.703412,
            longitude: 17.972636,
        },
        {
            obec: 'Kunčice pod Ondřejníkem',
            kod: 598356,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73913,
            latitude: 49.550798,
            longitude: 18.261235,
        },
        {
            obec: 'Kunín',
            kod: 568546,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74253,
            latitude: 49.63387,
            longitude: 17.989756,
        },
        {
            obec: 'Kyjovice',
            kod: 512907,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74768,
            latitude: 49.832445,
            longitude: 18.042227,
        },
        {
            obec: 'Leskovec nad Moravicí',
            kod: 597546,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79368,
            latitude: 49.905954,
            longitude: 17.574652,
        },
        {
            obec: 'Lhotka',
            kod: 598364,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73947,
            latitude: 49.597285,
            longitude: 18.298465,
        },
        {
            obec: 'Lhotka u Litultovic',
            kod: 553115,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74755,
            latitude: 49.872905,
            longitude: 17.730857,
        },
        {
            obec: 'Libhošť',
            kod: 500046,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74257,
            latitude: 49.626557,
            longitude: 18.077075,
        },
        {
            obec: 'Lichnov',
            kod: 597554,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79315,
            latitude: 50.007958,
            longitude: 17.62651,
        },
        {
            obec: 'Lichnov',
            kod: 599603,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74275,
            latitude: 49.563651,
            longitude: 18.170051,
        },
        {
            obec: 'Liptaň',
            kod: 597571,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.222367,
            longitude: 17.605521,
        },
        {
            obec: 'Litultovice',
            kod: 507920,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74755,
            latitude: 49.90397,
            longitude: 17.751529,
        },
        {
            obec: 'Lomnice',
            kod: 597589,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79302,
            latitude: 49.871099,
            longitude: 17.415174,
        },
        {
            obec: 'Luboměř',
            kod: 599646,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.688754,
            longitude: 17.706584,
        },
        {
            obec: 'Lučina',
            kod: 598399,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73939,
            latitude: 49.716011,
            longitude: 18.449769,
        },
        {
            obec: 'Ludgeřovice',
            kod: 507971,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74714,
            latitude: 49.89052,
            longitude: 18.24019,
        },
        {
            obec: 'Ludvíkov',
            kod: 551996,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79326,
            latitude: 50.107531,
            longitude: 17.342351,
        },
        {
            obec: 'Malá Morávka',
            kod: 597601,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79336,
            latitude: 50.021157,
            longitude: 17.311666,
        },
        {
            obec: 'Malá Štáhle',
            kod: 551902,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79501,
            latitude: 49.9515,
            longitude: 17.341559,
        },
        {
            obec: 'Malenovice',
            kod: 552593,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73911,
            latitude: 49.579287,
            longitude: 18.398071,
        },
        {
            obec: 'Mankovice',
            kod: 568589,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.637559,
            longitude: 17.880249,
        },
        {
            obec: 'Markvartovice',
            kod: 508128,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74714,
            latitude: 49.906049,
            longitude: 18.23613,
        },
        {
            obec: 'Melč',
            kod: 508144,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74784,
            latitude: 49.850024,
            longitude: 17.757906,
        },
        {
            obec: 'Město Albrechtice',
            kod: 597635,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79395,
            latitude: 50.163036,
            longitude: 17.574913,
        },
        {
            obec: 'Metylovice',
            kod: 512184,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73949,
            latitude: 49.606756,
            longitude: 18.339222,
        },
        {
            obec: 'Mezina',
            kod: 551724,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.958602,
            longitude: 17.477161,
        },
        {
            obec: 'Mikolajice',
            kod: 568279,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74784,
            latitude: 49.877258,
            longitude: 17.793425,
        },
        {
            obec: 'Milíkov',
            kod: 507547,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73981,
            latitude: 49.585749,
            longitude: 18.719541,
        },
        {
            obec: 'Milotice nad Opavou',
            kod: 569526,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.995792,
            longitude: 17.542152,
        },
        {
            obec: 'Mladecko',
            kod: 553042,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74754,
            latitude: 49.898335,
            longitude: 17.708278,
        },
        {
            obec: 'Mokré Lazce',
            kod: 508373,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74762,
            latitude: 49.904714,
            longitude: 18.029644,
        },
        {
            obec: 'Moravice',
            kod: 569097,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74784,
            latitude: 49.857852,
            longitude: 17.720204,
        },
        {
            obec: 'Morávka',
            kod: 598445,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73904,
            latitude: 49.596169,
            longitude: 18.524824,
        },
        {
            obec: 'Moravskoslezský Kočov',
            kod: 551732,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.960503,
            longitude: 17.441174,
        },
        {
            obec: 'Mořkov',
            kod: 599689,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74272,
            latitude: 49.536937,
            longitude: 18.059792,
        },
        {
            obec: 'Mosty u Jablunkova',
            kod: 598453,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73998,
            latitude: 49.527472,
            longitude: 18.754281,
        },
        {
            obec: 'Mošnov',
            kod: 568686,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74251,
            latitude: 49.689142,
            longitude: 18.132574,
        },
        {
            obec: 'Návsí',
            kod: 554014,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73992,
            latitude: 49.5873,
            longitude: 18.759182,
        },
        {
            obec: 'Neplachovice',
            kod: 553158,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74774,
            latitude: 49.992379,
            longitude: 17.809969,
        },
        {
            obec: 'Nižní Lhoty',
            kod: 552526,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.648584,
            longitude: 18.433859,
        },
        {
            obec: 'Nošovice',
            kod: 552518,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.660825,
            longitude: 18.426436,
        },
        {
            obec: 'Nová Pláň',
            kod: 546950,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.922008,
            longitude: 17.475201,
        },
        {
            obec: 'Nové Heřminovy',
            kod: 569551,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 50.023575,
            longitude: 17.5283,
        },
        {
            obec: 'Nové Lublice',
            kod: 512893,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74901,
            latitude: 49.862875,
            longitude: 17.678131,
        },
        {
            obec: 'Nové Sedlice',
            kod: 555274,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74706,
            latitude: 49.902222,
            longitude: 18.001326,
        },
        {
            obec: 'Nový Jičín',
            kod: 599191,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74101,
            latitude: 49.594425,
            longitude: 18.013636,
        },
        {
            obec: 'Nýdek',
            kod: 507091,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73995,
            latitude: 49.656185,
            longitude: 18.756984,
        },
        {
            obec: 'Oborná',
            kod: 569577,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 50.003161,
            longitude: 17.497004,
        },
        {
            obec: 'Odry',
            kod: 599701,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.662648,
            longitude: 17.83095,
        },
        {
            obec: 'Olbramice',
            kod: 554049,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74283,
            latitude: 49.78965,
            longitude: 18.089056,
        },
        {
            obec: 'Oldřišov',
            kod: 509574,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74733,
            latitude: 49.991395,
            longitude: 17.960845,
        },
        {
            obec: 'Opava',
            kod: 505927,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74601,
            latitude: 49.94076,
            longitude: 17.894899,
        },
        {
            obec: 'Orlová',
            kod: 599069,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73511,
            latitude: 49.870238,
            longitude: 18.425108,
        },
        {
            obec: 'Osoblaha',
            kod: 597716,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.275267,
            longitude: 17.715337,
        },
        {
            obec: 'Ostrava',
            kod: 554821,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 70200,
            latitude: 49.821023,
            longitude: 18.262624,
        },
        {
            obec: 'Ostravice',
            kod: 598542,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73914,
            latitude: 49.535196,
            longitude: 18.391751,
        },
        {
            obec: 'Otice',
            kod: 509612,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74781,
            latitude: 49.916939,
            longitude: 17.869927,
        },
        {
            obec: 'Palkovice',
            kod: 598551,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73941,
            latitude: 49.63477,
            longitude: 18.315185,
        },
        {
            obec: 'Paskov',
            kod: 598569,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73921,
            latitude: 49.731885,
            longitude: 18.290478,
        },
        {
            obec: 'Pazderna',
            kod: 552500,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.704374,
            longitude: 18.434339,
        },
        {
            obec: 'Petrovice',
            kod: 551848,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79384,
            latitude: 50.239024,
            longitude: 17.448488,
        },
        {
            obec: 'Petrovice u Karviné',
            kod: 599077,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73572,
            latitude: 49.895965,
            longitude: 18.547898,
        },
        {
            obec: 'Petřvald',
            kod: 599085,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73541,
            latitude: 49.831096,
            longitude: 18.389513,
        },
        {
            obec: 'Petřvald',
            kod: 599743,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74260,
            latitude: 49.707197,
            longitude: 18.15153,
        },
        {
            obec: 'Písečná',
            kod: 557226,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73991,
            latitude: 49.57477,
            longitude: 18.787469,
        },
        {
            obec: 'Písek',
            kod: 512028,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73984,
            latitude: 49.55934,
            longitude: 18.80242,
        },
        {
            obec: 'Píšť',
            kod: 509647,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74718,
            latitude: 49.978666,
            longitude: 18.193601,
        },
        {
            obec: 'Pražmo',
            kod: 568813,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73904,
            latitude: 49.608863,
            longitude: 18.48628,
        },
        {
            obec: 'Pržno',
            kod: 507181,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73911,
            latitude: 49.613573,
            longitude: 18.361716,
        },
        {
            obec: 'Příbor',
            kod: 599808,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74258,
            latitude: 49.641036,
            longitude: 18.145096,
        },
        {
            obec: 'Pstruží',
            kod: 552577,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73911,
            latitude: 49.564927,
            longitude: 18.347648,
        },
        {
            obec: 'Pustá Polom',
            kod: 509736,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74769,
            latitude: 49.849271,
            longitude: 17.998005,
        },
        {
            obec: 'Pustějov',
            kod: 568775,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74243,
            latitude: 49.700606,
            longitude: 18.004007,
        },
        {
            obec: 'Radkov',
            kod: 568317,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74784,
            latitude: 49.825627,
            longitude: 17.770253,
        },
        {
            obec: 'Raduň',
            kod: 509841,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74761,
            latitude: 49.89297,
            longitude: 17.943349,
        },
        {
            obec: 'Raškovice',
            kod: 549665,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73904,
            latitude: 49.619845,
            longitude: 18.472974,
        },
        {
            obec: 'Razová',
            kod: 597724,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.931401,
            longitude: 17.532102,
        },
        {
            obec: 'Rohov',
            kod: 568376,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74725,
            latitude: 50.015707,
            longitude: 18.071507,
        },
        {
            obec: 'Ropice',
            kod: 556971,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73961,
            latitude: 49.705458,
            longitude: 18.61356,
        },
        {
            obec: 'Roudno',
            kod: 597741,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.896091,
            longitude: 17.513637,
        },
        {
            obec: 'Rudná pod Pradědem',
            kod: 551945,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79331,
            latitude: 50.020519,
            longitude: 17.377529,
        },
        {
            obec: 'Rusín',
            kod: 597775,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79397,
            latitude: 50.209884,
            longitude: 17.734323,
        },
        {
            obec: 'Rybí',
            kod: 568554,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74265,
            latitude: 49.600825,
            longitude: 18.076024,
        },
        {
            obec: 'Rychvald',
            kod: 599107,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73532,
            latitude: 49.86635,
            longitude: 18.376369,
        },
        {
            obec: 'Rýmařov',
            kod: 597783,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79501,
            latitude: 49.931927,
            longitude: 17.271873,
        },
        {
            obec: 'Ryžoviště',
            kod: 597791,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79356,
            latitude: 49.875695,
            longitude: 17.358331,
        },
        {
            obec: 'Řeka',
            kod: 552640,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73955,
            latitude: 49.641202,
            longitude: 18.571511,
        },
        {
            obec: 'Řepiště',
            kod: 568830,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73932,
            latitude: 49.733473,
            longitude: 18.317179,
        },
        {
            obec: 'Sedliště',
            kod: 598674,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73936,
            latitude: 49.71846,
            longitude: 18.368797,
        },
        {
            obec: 'Sedlnice',
            kod: 599832,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74256,
            latitude: 49.657799,
            longitude: 18.08701,
        },
        {
            obec: 'Skotnice',
            kod: 568716,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74258,
            latitude: 49.657664,
            longitude: 18.134051,
        },
        {
            obec: 'Skřipov',
            kod: 510131,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74745,
            latitude: 49.818845,
            longitude: 17.910595,
        },
        {
            obec: 'Slatina',
            kod: 568406,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74293,
            latitude: 49.790806,
            longitude: 17.979978,
        },
        {
            obec: 'Slavkov',
            kod: 510289,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74757,
            latitude: 49.9219,
            longitude: 17.836519,
        },
        {
            obec: 'Slezské Pavlovice',
            kod: 551881,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.309757,
            longitude: 17.700802,
        },
        {
            obec: 'Slezské Rudoltice',
            kod: 597813,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79397,
            latitude: 50.207003,
            longitude: 17.681129,
        },
        {
            obec: 'Služovice',
            kod: 510297,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74728,
            latitude: 49.988605,
            longitude: 17.994974,
        },
        {
            obec: 'Smilovice',
            kod: 552658,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73955,
            latitude: 49.660869,
            longitude: 18.571876,
        },
        {
            obec: 'Soběšovice',
            kod: 568791,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73938,
            latitude: 49.724989,
            longitude: 18.4663,
        },
        {
            obec: 'Sosnová',
            kod: 597821,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79312,
            latitude: 49.998741,
            longitude: 17.663121,
        },
        {
            obec: 'Spálov',
            kod: 599867,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74237,
            latitude: 49.704203,
            longitude: 17.722514,
        },
        {
            obec: 'Stará Ves',
            kod: 551911,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79501,
            latitude: 49.958482,
            longitude: 17.236572,
        },
        {
            obec: 'Stará Ves nad Ondřejnicí',
            kod: 598739,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73923,
            latitude: 49.727472,
            longitude: 18.193435,
        },
        {
            obec: 'Staré Hamry',
            kod: 598747,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73915,
            latitude: 49.472486,
            longitude: 18.44589,
        },
        {
            obec: 'Staré Heřminovy',
            kod: 551813,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79312,
            latitude: 49.933251,
            longitude: 17.625765,
        },
        {
            obec: 'Staré Město',
            kod: 551767,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 50.003657,
            longitude: 17.432173,
        },
        {
            obec: 'Staré Město',
            kod: 552551,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73801,
            latitude: 49.670019,
            longitude: 18.363604,
        },
        {
            obec: 'Staré Těchanovice',
            kod: 568198,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74901,
            latitude: 49.81838,
            longitude: 17.692077,
        },
        {
            obec: 'Starý Jičín',
            kod: 599905,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74231,
            latitude: 49.576961,
            longitude: 17.961801,
        },
        {
            obec: 'Staříč',
            kod: 552569,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73943,
            latitude: 49.686041,
            longitude: 18.272916,
        },
        {
            obec: 'Stěbořice',
            kod: 510343,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74751,
            latitude: 49.937181,
            longitude: 17.805574,
        },
        {
            obec: 'Stonava',
            kod: 599140,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73534,
            latitude: 49.817011,
            longitude: 18.525286,
        },
        {
            obec: 'Strahovice',
            kod: 512869,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74724,
            latitude: 50.002181,
            longitude: 18.087213,
        },
        {
            obec: 'Střítež',
            kod: 552674,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73959,
            latitude: 49.67941,
            longitude: 18.569214,
        },
        {
            obec: 'Studénka',
            kod: 599921,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74213,
            latitude: 49.723516,
            longitude: 18.078634,
        },
        {
            obec: 'Sudice',
            kod: 510378,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74725,
            latitude: 50.031637,
            longitude: 18.068504,
        },
        {
            obec: 'Suchdol nad Odrou',
            kod: 599930,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74201,
            latitude: 49.65582,
            longitude: 17.928263,
        },
        {
            obec: 'Svatoňovice',
            kod: 547131,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74787,
            latitude: 49.793607,
            longitude: 17.667329,
        },
        {
            obec: 'Světlá Hora',
            kod: 597872,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79331,
            latitude: 50.046192,
            longitude: 17.399982,
        },
        {
            obec: 'Sviadnov',
            kod: 569631,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73925,
            latitude: 49.689354,
            longitude: 18.327726,
        },
        {
            obec: 'Svobodné Heřmanice',
            kod: 597881,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79313,
            latitude: 49.946147,
            longitude: 17.674365,
        },
        {
            obec: 'Šenov',
            kod: 598798,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73934,
            latitude: 49.793249,
            longitude: 18.376174,
        },
        {
            obec: 'Šenov u Nového Jičína',
            kod: 554171,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74242,
            latitude: 49.604647,
            longitude: 18.003387,
        },
        {
            obec: 'Šilheřovice',
            kod: 510432,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74715,
            latitude: 49.926122,
            longitude: 18.270274,
        },
        {
            obec: 'Široká Niva',
            kod: 597899,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 50.063922,
            longitude: 17.480203,
        },
        {
            obec: 'Štáblovice',
            kod: 568333,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74782,
            latitude: 49.880131,
            longitude: 17.818639,
        },
        {
            obec: 'Štěpánkovice',
            kod: 510483,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74728,
            latitude: 49.95748,
            longitude: 18.037518,
        },
        {
            obec: 'Štítina',
            kod: 510491,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74791,
            latitude: 49.914966,
            longitude: 18.012559,
        },
        {
            obec: 'Štramberk',
            kod: 599948,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74266,
            latitude: 49.591905,
            longitude: 18.117521,
        },
        {
            obec: 'Těrlicko',
            kod: 599158,
            okres: 'Karviná',
            kod_okres: 'CZ0803',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73542,
            latitude: 49.752419,
            longitude: 18.48302,
        },
        {
            obec: 'Těškovice',
            kod: 512745,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74764,
            latitude: 49.814189,
            longitude: 18.02101,
        },
        {
            obec: 'Tichá',
            kod: 599956,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74274,
            latitude: 49.570086,
            longitude: 18.221589,
        },
        {
            obec: 'Tísek',
            kod: 599964,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74301,
            latitude: 49.792766,
            longitude: 18.014705,
        },
        {
            obec: 'Trnávka',
            kod: 569755,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74258,
            latitude: 49.684846,
            longitude: 18.178045,
        },
        {
            obec: 'Trojanovice',
            kod: 599999,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74401,
            latitude: 49.520494,
            longitude: 18.238113,
        },
        {
            obec: 'Třanovice',
            kod: 552623,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73953,
            latitude: 49.712553,
            longitude: 18.529296,
        },
        {
            obec: 'Třebom',
            kod: 569101,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74725,
            latitude: 50.046244,
            longitude: 18.025387,
        },
        {
            obec: 'Třemešná',
            kod: 597911,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79382,
            latitude: 50.20503,
            longitude: 17.57542,
        },
        {
            obec: 'Třinec',
            kod: 598810,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73961,
            latitude: 49.677731,
            longitude: 18.67089,
        },
        {
            obec: 'Tvrdkov',
            kod: 551821,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79344,
            latitude: 49.895592,
            longitude: 17.178202,
        },
        {
            obec: 'Uhlířov',
            kod: 568341,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74784,
            latitude: 49.897154,
            longitude: 17.841402,
        },
        {
            obec: 'Úvalno',
            kod: 597937,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79391,
            latitude: 50.047254,
            longitude: 17.744838,
        },
        {
            obec: 'Václavov u Bruntálu',
            kod: 597945,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.979799,
            longitude: 17.371834,
        },
        {
            obec: 'Václavovice',
            kod: 598836,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73934,
            latitude: 49.755403,
            longitude: 18.372312,
        },
        {
            obec: 'Valšov',
            kod: 551783,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79201,
            latitude: 49.933456,
            longitude: 17.43824,
        },
        {
            obec: 'Velká Polom',
            kod: 510882,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74764,
            latitude: 49.863692,
            longitude: 18.093414,
        },
        {
            obec: 'Velká Štáhle',
            kod: 551694,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79351,
            latitude: 49.9298,
            longitude: 17.35708,
        },
        {
            obec: 'Velké Albrechtice',
            kod: 568422,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74291,
            latitude: 49.750537,
            longitude: 18.044045,
        },
        {
            obec: 'Velké Heraltice',
            kod: 510891,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74775,
            latitude: 49.975025,
            longitude: 17.728896,
        },
        {
            obec: 'Velké Hoštice',
            kod: 510939,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74731,
            latitude: 49.936189,
            longitude: 17.973904,
        },
        {
            obec: 'Vělopolí',
            kod: 552615,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73959,
            latitude: 49.700533,
            longitude: 18.571008,
        },
        {
            obec: 'Vendryně',
            kod: 554928,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73994,
            latitude: 49.666714,
            longitude: 18.713186,
        },
        {
            obec: 'Veřovice',
            kod: 500259,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74273,
            latitude: 49.539264,
            longitude: 18.114362,
        },
        {
            obec: 'Větřkovice',
            kod: 570036,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74743,
            latitude: 49.780606,
            longitude: 17.820558,
        },
        {
            obec: 'Vítkov',
            kod: 511021,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74901,
            latitude: 49.774549,
            longitude: 17.749517,
        },
        {
            obec: 'Vojkovice',
            kod: 552488,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.68244,
            longitude: 18.468124,
        },
        {
            obec: 'Vratimov',
            kod: 598879,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73932,
            latitude: 49.77005,
            longitude: 18.310255,
        },
        {
            obec: 'Vražné',
            kod: 554910,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74235,
            latitude: 49.628789,
            longitude: 17.866773,
        },
        {
            obec: 'Vrbno pod Pradědem',
            kod: 597961,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79326,
            latitude: 50.121048,
            longitude: 17.383262,
        },
        {
            obec: 'Vrchy',
            kod: 569747,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74245,
            latitude: 49.75108,
            longitude: 17.871191,
        },
        {
            obec: 'Vršovice',
            kod: 511161,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74761,
            latitude: 49.884925,
            longitude: 17.93542,
        },
        {
            obec: 'Vřesina',
            kod: 568244,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74720,
            latitude: 49.945634,
            longitude: 18.190598,
        },
        {
            obec: 'Vřesina',
            kod: 500291,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74285,
            latitude: 49.824282,
            longitude: 18.125802,
        },
        {
            obec: 'Vysoká',
            kod: 597970,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79399,
            latitude: 50.26149,
            longitude: 17.590344,
        },
        {
            obec: 'Vyšní Lhoty',
            kod: 552682,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73951,
            latitude: 49.635907,
            longitude: 18.457023,
        },
        {
            obec: 'Zátor',
            kod: 597988,
            okres: 'Bruntál',
            kod_okres: 'CZ0801',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 79316,
            latitude: 50.034227,
            longitude: 17.593061,
        },
        {
            obec: 'Závada',
            kod: 553492,
            okres: 'Opava',
            kod_okres: 'CZ0805',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74719,
            latitude: 49.954135,
            longitude: 18.165759,
        },
        {
            obec: 'Závišice',
            kod: 568473,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74221,
            latitude: 49.613357,
            longitude: 18.103114,
        },
        {
            obec: 'Zbyslavice',
            kod: 568449,
            okres: 'Ostrava-město',
            kod_okres: 'CZ0806',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74283,
            latitude: 49.805441,
            longitude: 18.075576,
        },
        {
            obec: 'Žabeň',
            kod: 552691,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73925,
            latitude: 49.708875,
            longitude: 18.304316,
        },
        {
            obec: 'Ženklava',
            kod: 568732,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74267,
            latitude: 49.563672,
            longitude: 18.107297,
        },
        {
            obec: 'Žermanice',
            kod: 568163,
            okres: 'Frýdek-Místek',
            kod_okres: 'CZ0802',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 73937,
            latitude: 49.738583,
            longitude: 18.442932,
        },
        {
            obec: 'Životice u Nového Jičína',
            kod: 547000,
            okres: 'Nový Jičín',
            kod_okres: 'CZ0804',
            kraj: 'Moravskoslezský kraj',
            kod_kraj: 'CZ080',
            psc: 74272,
            latitude: 49.557223,
            longitude: 18.047353,
        },
    ],
    CZ053: [
        {
            obec: 'Albrechtice',
            kod: 547981,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.927643,
            longitude: 16.644801,
        },
        {
            obec: 'Anenská Studánka',
            kod: 573426,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.849856,
            longitude: 16.54033,
        },
        {
            obec: 'Banín',
            kod: 572560,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.665712,
            longitude: 16.46203,
        },
        {
            obec: 'Barchov',
            kod: 574724,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.999411,
            longitude: 15.68091,
        },
        {
            obec: 'Bělá nad Svitavou',
            kod: 572586,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56905,
            latitude: 49.640287,
            longitude: 16.482734,
        },
        {
            obec: 'Bělá u Jevíčka',
            kod: 505391,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.639593,
            longitude: 16.646009,
        },
        {
            obec: 'Benátky',
            kod: 577774,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.854287,
            longitude: 16.326847,
        },
        {
            obec: 'Běstovice',
            kod: 553760,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.020237,
            longitude: 16.211026,
        },
        {
            obec: 'Běstvina',
            kod: 571181,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53845,
            latitude: 49.837134,
            longitude: 15.595868,
        },
        {
            obec: 'Bezděčí u Trnávky',
            kod: 574309,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.692503,
            longitude: 16.763539,
        },
        {
            obec: 'Bezděkov',
            kod: 574741,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.008869,
            longitude: 15.644768,
        },
        {
            obec: 'Biskupice',
            kod: 573949,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.872861,
            longitude: 15.512907,
        },
        {
            obec: 'Biskupice',
            kod: 572519,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.647572,
            longitude: 16.758148,
        },
        {
            obec: 'Bítovany',
            kod: 505005,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.897307,
            longitude: 15.86473,
        },
        {
            obec: 'Bohuňov',
            kod: 577812,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.599198,
            longitude: 16.463955,
        },
        {
            obec: 'Bohuňovice',
            kod: 577821,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.907982,
            longitude: 16.262964,
        },
        {
            obec: 'Bojanov',
            kod: 571202,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53826,
            latitude: 49.841414,
            longitude: 15.704985,
        },
        {
            obec: 'Bor u Skutče',
            kod: 547867,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.822412,
            longitude: 16.127052,
        },
        {
            obec: 'Borek',
            kod: 574783,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.127301,
            longitude: 15.856639,
        },
        {
            obec: 'Borová',
            kod: 577839,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56982,
            latitude: 49.741782,
            longitude: 16.162337,
        },
        {
            obec: 'Borušov',
            kod: 572632,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.780652,
            longitude: 16.740556,
        },
        {
            obec: 'Bořice',
            kod: 571229,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.97695,
            longitude: 15.925435,
        },
        {
            obec: 'Bošín',
            kod: 548006,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.034233,
            longitude: 16.203069,
        },
        {
            obec: 'Bousov',
            kod: 571237,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.901849,
            longitude: 15.535529,
        },
        {
            obec: 'Brandýs nad Orlicí',
            kod: 579947,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56112,
            latitude: 50.002042,
            longitude: 16.285384,
        },
        {
            obec: 'Brloh',
            kod: 574791,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.000688,
            longitude: 15.557385,
        },
        {
            obec: 'Brněnec',
            kod: 577863,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.627449,
            longitude: 16.522125,
        },
        {
            obec: 'Břehy',
            kod: 574805,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.051994,
            longitude: 15.577582,
        },
        {
            obec: 'Březina',
            kod: 577871,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56923,
            latitude: 49.651038,
            longitude: 16.619713,
        },
        {
            obec: 'Březinky',
            kod: 572250,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.662787,
            longitude: 16.78703,
        },
        {
            obec: 'Březiny',
            kod: 577898,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.690726,
            longitude: 16.118233,
        },
        {
            obec: 'Březová nad Svitavou',
            kod: 505145,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56902,
            latitude: 49.644277,
            longitude: 16.518096,
        },
        {
            obec: 'Bučina',
            kod: 579963,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.896972,
            longitude: 16.192941,
        },
        {
            obec: 'Budislav',
            kod: 577910,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56965,
            latitude: 49.801511,
            longitude: 16.174739,
        },
        {
            obec: 'Bukovina nad Labem',
            kod: 574813,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.12496,
            longitude: 15.823385,
        },
        {
            obec: 'Bukovina u Přelouče',
            kod: 574821,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.948338,
            longitude: 15.562977,
        },
        {
            obec: 'Bukovka',
            kod: 574830,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.101131,
            longitude: 15.625408,
        },
        {
            obec: 'Bylany',
            kod: 571245,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53801,
            latitude: 49.957375,
            longitude: 15.733261,
        },
        {
            obec: 'Bystré',
            kod: 577928,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56992,
            latitude: 49.628557,
            longitude: 16.346893,
        },
        {
            obec: 'Bystřec',
            kod: 579971,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56154,
            latitude: 50.011781,
            longitude: 16.619105,
        },
        {
            obec: 'Býšť',
            kod: 574848,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53322,
            latitude: 50.132532,
            longitude: 15.911265,
        },
        {
            obec: 'Cerekvice nad Loučnou',
            kod: 577936,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56953,
            latitude: 49.898991,
            longitude: 16.215295,
        },
        {
            obec: 'Cotkytle',
            kod: 579980,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56132,
            latitude: 49.936282,
            longitude: 16.72414,
        },
        {
            obec: 'Ctětín',
            kod: 571253,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.830355,
            longitude: 15.839524,
        },
        {
            obec: 'Čankovice',
            kod: 571270,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.963804,
            longitude: 15.937395,
        },
        {
            obec: 'Časy',
            kod: 572799,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.069473,
            longitude: 15.898214,
        },
        {
            obec: 'Čenkovice',
            kod: 579998,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56164,
            latitude: 50.011481,
            longitude: 16.682509,
        },
        {
            obec: 'Čeperka',
            kod: 574856,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.13312,
            longitude: 15.773339,
        },
        {
            obec: 'Čepí',
            kod: 574864,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53332,
            latitude: 49.985238,
            longitude: 15.717675,
        },
        {
            obec: 'Černá u Bohdanče',
            kod: 572896,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.05613,
            longitude: 15.671936,
        },
        {
            obec: 'Červená Voda',
            kod: 580015,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56161,
            latitude: 50.040389,
            longitude: 16.742787,
        },
        {
            obec: 'Česká Rybná',
            kod: 580023,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56185,
            latitude: 50.071923,
            longitude: 16.392345,
        },
        {
            obec: 'Česká Třebová',
            kod: 580031,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56002,
            latitude: 49.901982,
            longitude: 16.447385,
        },
        {
            obec: 'České Heřmanice',
            kod: 580040,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56552,
            latitude: 49.931529,
            longitude: 16.253203,
        },
        {
            obec: 'České Lhotice',
            kod: 571296,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.847036,
            longitude: 15.777938,
        },
        {
            obec: 'České Libchavy',
            kod: 580058,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56114,
            latitude: 50.029927,
            longitude: 16.371803,
        },
        {
            obec: 'České Petrovice',
            kod: 580066,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.119374,
            longitude: 16.605474,
        },
        {
            obec: 'Čistá',
            kod: 577944,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56956,
            latitude: 49.827322,
            longitude: 16.328783,
        },
        {
            obec: 'Damníkov',
            kod: 580074,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56123,
            latitude: 49.871344,
            longitude: 16.560355,
        },
        {
            obec: 'Dašice',
            kod: 574899,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53303,
            latitude: 50.028543,
            longitude: 15.912545,
        },
        {
            obec: 'Dědová',
            kod: 571300,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.761203,
            longitude: 15.986569,
        },
        {
            obec: 'Desná',
            kod: 572357,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.807641,
            longitude: 16.224152,
        },
        {
            obec: 'Dětřichov',
            kod: 577961,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.80007,
            longitude: 16.532701,
        },
        {
            obec: 'Dětřichov u Moravské Třebové',
            kod: 572683,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.787241,
            longitude: 16.710609,
        },
        {
            obec: 'Dlouhá Loučka',
            kod: 577987,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.700947,
            longitude: 16.641393,
        },
        {
            obec: 'Dlouhá Třebová',
            kod: 548014,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56117,
            latitude: 49.940498,
            longitude: 16.423397,
        },
        {
            obec: 'Dlouhoňovice',
            kod: 548031,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.070296,
            longitude: 16.441645,
        },
        {
            obec: 'Dobříkov',
            kod: 580091,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 50.002605,
            longitude: 16.133921,
        },
        {
            obec: 'Dolany',
            kod: 574902,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.113474,
            longitude: 15.691707,
        },
        {
            obec: 'Dolní Bezděkov',
            kod: 505030,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.960537,
            longitude: 15.883877,
        },
        {
            obec: 'Dolní Čermná',
            kod: 580112,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56153,
            latitude: 49.979635,
            longitude: 16.564857,
        },
        {
            obec: 'Dolní Dobrouč',
            kod: 580121,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56102,
            latitude: 49.992831,
            longitude: 16.497763,
        },
        {
            obec: 'Dolní Morava',
            kod: 580163,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56169,
            latitude: 50.122417,
            longitude: 16.799687,
        },
        {
            obec: 'Dolní Roveň',
            kod: 574911,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53371,
            latitude: 50.029373,
            longitude: 15.967844,
        },
        {
            obec: 'Dolní Ředice',
            kod: 574929,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53375,
            latitude: 50.077906,
            longitude: 15.924033,
        },
        {
            obec: 'Dolní Újezd',
            kod: 577995,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56961,
            latitude: 49.82572,
            longitude: 16.25471,
        },
        {
            obec: 'Dřenice',
            kod: 504301,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.982634,
            longitude: 15.745503,
        },
        {
            obec: 'Dříteč',
            kod: 574953,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53305,
            latitude: 50.105191,
            longitude: 15.808857,
        },
        {
            obec: 'Dubany',
            kod: 572977,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.993164,
            longitude: 15.725091,
        },
        {
            obec: 'Dvakačovice',
            kod: 504955,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.979022,
            longitude: 15.894469,
        },
        {
            obec: 'Džbánov',
            kod: 580210,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.916456,
            longitude: 16.159605,
        },
        {
            obec: 'Gruna',
            kod: 574325,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.762991,
            longitude: 16.742872,
        },
        {
            obec: 'Hamry',
            kod: 571377,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.739067,
            longitude: 15.926515,
        },
        {
            obec: 'Hartinkov',
            kod: 572284,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.679202,
            longitude: 16.814035,
        },
        {
            obec: 'Hartmanice',
            kod: 578037,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56992,
            latitude: 49.625328,
            longitude: 16.373032,
        },
        {
            obec: 'Hejnice',
            kod: 580228,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.055981,
            longitude: 16.399519,
        },
        {
            obec: 'Helvíkovice',
            kod: 548049,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.092319,
            longitude: 16.43105,
        },
        {
            obec: 'Heřmanův Městec',
            kod: 571385,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.94717,
            longitude: 15.665028,
        },
        {
            obec: 'Hlavečník',
            kod: 574961,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53315,
            latitude: 50.075555,
            longitude: 15.426616,
        },
        {
            obec: 'Hlinsko',
            kod: 571393,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.762231,
            longitude: 15.907661,
        },
        {
            obec: 'Hluboká',
            kod: 571407,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53973,
            latitude: 49.84568,
            longitude: 16.071649,
        },
        {
            obec: 'Hnátnice',
            kod: 580261,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56101,
            latitude: 50.020435,
            longitude: 16.438656,
        },
        {
            obec: 'Hodonín',
            kod: 547794,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.837054,
            longitude: 15.78717,
        },
        {
            obec: 'Holetín',
            kod: 571440,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53971,
            latitude: 49.794858,
            longitude: 15.923513,
        },
        {
            obec: 'Holice',
            kod: 574988,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.06611,
            longitude: 15.986005,
        },
        {
            obec: 'Holotín',
            kod: 573019,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.945993,
            longitude: 15.580349,
        },
        {
            obec: 'Honbice',
            kod: 571458,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.933561,
            longitude: 15.893093,
        },
        {
            obec: 'Horka',
            kod: 571466,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.887322,
            longitude: 15.916873,
        },
        {
            obec: 'Horky',
            kod: 578053,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.920822,
            longitude: 16.243471,
        },
        {
            obec: 'Horní Bradlo',
            kod: 571474,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53953,
            latitude: 49.802435,
            longitude: 15.744393,
        },
        {
            obec: 'Horní Čermná',
            kod: 580279,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56156,
            latitude: 49.970628,
            longitude: 16.607822,
        },
        {
            obec: 'Horní Heřmanice',
            kod: 580295,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56133,
            latitude: 49.961095,
            longitude: 16.711296,
        },
        {
            obec: 'Horní Jelení',
            kod: 574996,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53374,
            latitude: 50.049109,
            longitude: 16.084069,
        },
        {
            obec: 'Horní Ředice',
            kod: 575011,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53375,
            latitude: 50.076611,
            longitude: 15.958886,
        },
        {
            obec: 'Horní Třešňovec',
            kod: 580333,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.939321,
            longitude: 16.602641,
        },
        {
            obec: 'Horní Újezd',
            kod: 572365,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.803578,
            longitude: 16.233042,
        },
        {
            obec: 'Hošťalovice',
            kod: 571482,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.934901,
            longitude: 15.57603,
        },
        {
            obec: 'Hradec nad Svitavou',
            kod: 572691,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56901,
            latitude: 49.71153,
            longitude: 16.480687,
        },
        {
            obec: 'Hrádek',
            kod: 547972,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 49.968309,
            longitude: 16.338406,
        },
        {
            obec: 'Hrobice',
            kod: 575046,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.103527,
            longitude: 15.78888,
        },
        {
            obec: 'Hrochův Týnec',
            kod: 571491,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.959563,
            longitude: 15.910648,
        },
        {
            obec: 'Hroubovice',
            kod: 571504,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53854,
            latitude: 49.882632,
            longitude: 15.990827,
        },
        {
            obec: 'Hrušová',
            kod: 580341,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56555,
            latitude: 49.91361,
            longitude: 16.197832,
        },
        {
            obec: 'Chmelík',
            kod: 572730,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.773488,
            longitude: 16.332883,
        },
        {
            obec: 'Choceň',
            kod: 580350,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.001704,
            longitude: 16.223131,
        },
        {
            obec: 'Choltice',
            kod: 575054,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53361,
            latitude: 49.987822,
            longitude: 15.619515,
        },
        {
            obec: 'Chornice',
            kod: 578096,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56942,
            latitude: 49.667478,
            longitude: 16.742731,
        },
        {
            obec: 'Choteč',
            kod: 575062,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53304,
            latitude: 50.083128,
            longitude: 15.880341,
        },
        {
            obec: 'Chotěnov',
            kod: 578100,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.823356,
            longitude: 16.189972,
        },
        {
            obec: 'Chotovice',
            kod: 578118,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.851152,
            longitude: 16.171354,
        },
        {
            obec: 'Chrast',
            kod: 571539,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.902149,
            longitude: 15.934067,
        },
        {
            obec: 'Chrastavec',
            kod: 578126,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.617858,
            longitude: 16.503245,
        },
        {
            obec: 'Chroustovice',
            kod: 571547,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53863,
            latitude: 49.955625,
            longitude: 15.991536,
        },
        {
            obec: 'Chrtníky',
            kod: 572764,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.9819,
            longitude: 15.604954,
        },
        {
            obec: 'Chrudim',
            kod: 571164,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.949824,
            longitude: 15.795158,
        },
        {
            obec: 'Chvaletice',
            kod: 575071,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53312,
            latitude: 50.034532,
            longitude: 15.418562,
        },
        {
            obec: 'Chvojenec',
            kod: 575089,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.109676,
            longitude: 15.937327,
        },
        {
            obec: 'Chýšť',
            kod: 575097,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.128259,
            longitude: 15.541389,
        },
        {
            obec: 'Jablonné nad Orlicí',
            kod: 580376,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56164,
            latitude: 50.029734,
            longitude: 16.600698,
        },
        {
            obec: 'Jamné nad Orlicí',
            kod: 580392,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56165,
            latitude: 50.039685,
            longitude: 16.633004,
        },
        {
            obec: 'Jankovice',
            kod: 575101,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.007735,
            longitude: 15.529259,
        },
        {
            obec: 'Janov',
            kod: 578134,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56955,
            latitude: 49.840871,
            longitude: 16.385333,
        },
        {
            obec: 'Janůvky',
            kod: 578142,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.671651,
            longitude: 16.592171,
        },
        {
            obec: 'Jaroměřice',
            kod: 578151,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56944,
            latitude: 49.625661,
            longitude: 16.751955,
        },
        {
            obec: 'Jaroslav',
            kod: 575119,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.012339,
            longitude: 16.077983,
        },
        {
            obec: 'Jarošov',
            kod: 578169,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56966,
            latitude: 49.824915,
            longitude: 16.16347,
        },
        {
            obec: 'Javorník',
            kod: 572713,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.782812,
            longitude: 16.427483,
        },
        {
            obec: 'Javorník',
            kod: 580406,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.889566,
            longitude: 16.156118,
        },
        {
            obec: 'Jedlová',
            kod: 578185,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56991,
            latitude: 49.661104,
            longitude: 16.306186,
        },
        {
            obec: 'Jedousov',
            kod: 575127,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.995451,
            longitude: 15.610295,
        },
        {
            obec: 'Jehnědí',
            kod: 580414,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 49.971109,
            longitude: 16.310007,
        },
        {
            obec: 'Jeníkov',
            kod: 547816,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.74259,
            longitude: 15.957449,
        },
        {
            obec: 'Jeníkovice',
            kod: 575135,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.981594,
            longitude: 15.660618,
        },
        {
            obec: 'Jenišovice',
            kod: 571563,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53864,
            latitude: 49.929644,
            longitude: 16.03112,
        },
        {
            obec: 'Jevíčko',
            kod: 578193,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.632295,
            longitude: 16.71136,
        },
        {
            obec: 'Jezbořice',
            kod: 575143,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.980674,
            longitude: 15.694777,
        },
        {
            obec: 'Kamenec u Poličky',
            kod: 578207,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.707979,
            longitude: 16.233812,
        },
        {
            obec: 'Kameničky',
            kod: 571571,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53941,
            latitude: 49.736024,
            longitude: 15.975128,
        },
        {
            obec: 'Kameničná',
            kod: 580422,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.120278,
            longitude: 16.435011,
        },
        {
            obec: 'Kamenná Horka',
            kod: 578215,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.738007,
            longitude: 16.52403,
        },
        {
            obec: 'Karle',
            kod: 572748,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.766089,
            longitude: 16.364833,
        },
        {
            obec: 'Kasalice',
            kod: 575151,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.118105,
            longitude: 15.609768,
        },
        {
            obec: 'Kladno',
            kod: 571580,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.772574,
            longitude: 15.982386,
        },
        {
            obec: 'Kladruby nad Labem',
            kod: 575178,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53314,
            latitude: 50.057816,
            longitude: 15.487192,
        },
        {
            obec: 'Klášterec nad Orlicí',
            kod: 580431,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56182,
            latitude: 50.111702,
            longitude: 16.554593,
        },
        {
            obec: 'Klešice',
            kod: 573787,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.963712,
            longitude: 15.681376,
        },
        {
            obec: 'Kněžice',
            kod: 574007,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.861905,
            longitude: 15.527475,
        },
        {
            obec: 'Koclířov',
            kod: 578231,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56911,
            latitude: 49.766393,
            longitude: 16.539938,
        },
        {
            obec: 'Kočí',
            kod: 571610,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53861,
            latitude: 49.947276,
            longitude: 15.855614,
        },
        {
            obec: 'Kojice',
            kod: 575194,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53312,
            latitude: 50.043079,
            longitude: 15.386682,
        },
        {
            obec: 'Koldín',
            kod: 580465,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.03692,
            longitude: 16.251721,
        },
        {
            obec: 'Korouhev',
            kod: 578258,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56993,
            latitude: 49.67142,
            longitude: 16.245888,
        },
        {
            obec: 'Koruna',
            kod: 578266,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.846904,
            longitude: 16.714969,
        },
        {
            obec: 'Kosořín',
            kod: 574449,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 49.979239,
            longitude: 16.233825,
        },
        {
            obec: 'Kostelec u Heřmanova Městce',
            kod: 571628,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.923421,
            longitude: 15.651641,
        },
        {
            obec: 'Kostěnice',
            kod: 575232,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.009667,
            longitude: 15.903791,
        },
        {
            obec: 'Králíky',
            kod: 580481,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56169,
            latitude: 50.083939,
            longitude: 16.760646,
        },
        {
            obec: 'Krasíkov',
            kod: 580490,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.855681,
            longitude: 16.695801,
        },
        {
            obec: 'Krásné',
            kod: 571652,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.821656,
            longitude: 15.745623,
        },
        {
            obec: 'Krouna',
            kod: 571661,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53943,
            latitude: 49.772498,
            longitude: 16.026846,
        },
        {
            obec: 'Křenov',
            kod: 578274,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56922,
            latitude: 49.679791,
            longitude: 16.628932,
        },
        {
            obec: 'Křičeň',
            kod: 572861,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.109618,
            longitude: 15.65169,
        },
        {
            obec: 'Křižanovice',
            kod: 547824,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.859518,
            longitude: 15.761796,
        },
        {
            obec: 'Kukle',
            kod: 572594,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.793206,
            longitude: 16.425709,
        },
        {
            obec: 'Kunčina',
            kod: 578282,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56924,
            latitude: 49.794652,
            longitude: 16.627737,
        },
        {
            obec: 'Kunětice',
            kod: 573515,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53304,
            latitude: 50.070734,
            longitude: 15.827021,
        },
        {
            obec: 'Kunvald',
            kod: 580503,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56181,
            latitude: 50.129372,
            longitude: 16.50007,
        },
        {
            obec: 'Květná',
            kod: 578291,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.736321,
            longitude: 16.34626,
        },
        {
            obec: 'Labské Chrčice',
            kod: 575259,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 28126,
            latitude: 50.051519,
            longitude: 15.410279,
        },
        {
            obec: 'Lanškroun',
            kod: 580511,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.912141,
            longitude: 16.612597,
        },
        {
            obec: 'Lány',
            kod: 504807,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.94646,
            longitude: 15.727244,
        },
        {
            obec: 'Lány u Dašic',
            kod: 572845,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.043622,
            longitude: 15.889187,
        },
        {
            obec: 'Lavičné',
            kod: 578304,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.643678,
            longitude: 16.462574,
        },
        {
            obec: 'Lázně Bohdaneč',
            kod: 574767,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.075702,
            longitude: 15.67989,
        },
        {
            obec: 'Leština',
            kod: 571695,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.867762,
            longitude: 16.117895,
        },
        {
            obec: 'Leštinka',
            kod: 571709,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53973,
            latitude: 49.845309,
            longitude: 15.958993,
        },
        {
            obec: 'Letohrad',
            kod: 580538,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56151,
            latitude: 50.036122,
            longitude: 16.500231,
        },
        {
            obec: 'Libecina',
            kod: 580562,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.882981,
            longitude: 16.118331,
        },
        {
            obec: 'Libchavy',
            kod: 580147,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56116,
            latitude: 50.005575,
            longitude: 16.38914,
        },
        {
            obec: 'Libišany',
            kod: 575305,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.153387,
            longitude: 15.756738,
        },
        {
            obec: 'Libkov',
            kod: 571725,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.841833,
            longitude: 15.75641,
        },
        {
            obec: 'Liboměřice',
            kod: 571733,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.87195,
            longitude: 15.741396,
        },
        {
            obec: 'Licibořice',
            kod: 547832,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53823,
            latitude: 49.877003,
            longitude: 15.760021,
        },
        {
            obec: 'Lichkov',
            kod: 580571,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56168,
            latitude: 50.097876,
            longitude: 16.666117,
        },
        {
            obec: 'Linhartice',
            kod: 578339,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.757429,
            longitude: 16.695338,
        },
        {
            obec: 'Lipoltice',
            kod: 575313,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53364,
            latitude: 49.98787,
            longitude: 15.568989,
        },
        {
            obec: 'Lipovec',
            kod: 547875,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.916436,
            longitude: 15.545079,
        },
        {
            obec: 'Líšnice',
            kod: 580589,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56184,
            latitude: 50.079921,
            longitude: 16.508318,
        },
        {
            obec: 'Litomyšl',
            kod: 578347,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.870623,
            longitude: 16.311162,
        },
        {
            obec: 'Litošice',
            kod: 575330,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.984932,
            longitude: 15.514334,
        },
        {
            obec: 'Lozice',
            kod: 571750,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53854,
            latitude: 49.916576,
            longitude: 16.023891,
        },
        {
            obec: 'Lubná',
            kod: 578355,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56963,
            latitude: 49.774189,
            longitude: 16.223798,
        },
        {
            obec: 'Lubník',
            kod: 580619,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.888599,
            longitude: 16.661647,
        },
        {
            obec: 'Lukavice',
            kod: 571768,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.888935,
            longitude: 15.839045,
        },
        {
            obec: 'Lukavice',
            kod: 580627,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56151,
            latitude: 50.060386,
            longitude: 16.482177,
        },
        {
            obec: 'Luková',
            kod: 580635,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56123,
            latitude: 49.875582,
            longitude: 16.606774,
        },
        {
            obec: 'Luže',
            kod: 571776,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53854,
            latitude: 49.893513,
            longitude: 16.028604,
        },
        {
            obec: 'Makov',
            kod: 578363,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.855103,
            longitude: 16.191233,
        },
        {
            obec: 'Malé Výkleky',
            kod: 572802,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.119436,
            longitude: 15.538618,
        },
        {
            obec: 'Malíkov',
            kod: 578371,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.731136,
            longitude: 16.69618,
        },
        {
            obec: 'Městečko Trnávka',
            kod: 578380,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56941,
            latitude: 49.709363,
            longitude: 16.727541,
        },
        {
            obec: 'Míčov-Sušice',
            kod: 571822,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.905062,
            longitude: 15.605872,
        },
        {
            obec: 'Mikuleč',
            kod: 578398,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.805919,
            longitude: 16.422601,
        },
        {
            obec: 'Mikulovice',
            kod: 575372,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.989526,
            longitude: 15.775487,
        },
        {
            obec: 'Miřetice',
            kod: 571831,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53955,
            latitude: 49.841358,
            longitude: 15.884829,
        },
        {
            obec: 'Mistrovice',
            kod: 580643,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56164,
            latitude: 50.02377,
            longitude: 16.571618,
        },
        {
            obec: 'Mladějov na Moravě',
            kod: 578401,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56935,
            latitude: 49.822707,
            longitude: 16.591176,
        },
        {
            obec: 'Mladkov',
            kod: 580651,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56167,
            latitude: 50.096953,
            longitude: 16.627025,
        },
        {
            obec: 'Mladoňovice',
            kod: 571857,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.887468,
            longitude: 15.724015,
        },
        {
            obec: 'Mokošín',
            kod: 575381,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.014912,
            longitude: 15.572047,
        },
        {
            obec: 'Morašice',
            kod: 571873,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53802,
            latitude: 49.934791,
            longitude: 15.709072,
        },
        {
            obec: 'Morašice',
            kod: 573035,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.001981,
            longitude: 15.486111,
        },
        {
            obec: 'Morašice',
            kod: 578428,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56951,
            latitude: 49.867799,
            longitude: 16.233701,
        },
        {
            obec: 'Moravany',
            kod: 575399,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53372,
            latitude: 50.00105,
            longitude: 15.940811,
        },
        {
            obec: 'Moravská Třebová',
            kod: 578444,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.757697,
            longitude: 16.663631,
        },
        {
            obec: 'Mostek',
            kod: 580660,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.009486,
            longitude: 16.265146,
        },
        {
            obec: 'Mrákotín',
            kod: 554847,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.814666,
            longitude: 15.95683,
        },
        {
            obec: 'Nabočany',
            kod: 571890,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.94387,
            longitude: 15.886976,
        },
        {
            obec: 'Načešice',
            kod: 571903,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.941391,
            longitude: 15.628949,
        },
        {
            obec: 'Nasavrky',
            kod: 571911,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53825,
            latitude: 49.844597,
            longitude: 15.804712,
        },
        {
            obec: 'Nasavrky',
            kod: 580678,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.025583,
            longitude: 16.259185,
        },
        {
            obec: 'Nedvězí',
            kod: 578452,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56992,
            latitude: 49.630695,
            longitude: 16.301003,
        },
        {
            obec: 'Nekoř',
            kod: 580686,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56163,
            latitude: 50.055817,
            longitude: 16.551297,
        },
        {
            obec: 'Němčice',
            kod: 572870,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.093029,
            longitude: 15.805868,
        },
        {
            obec: 'Němčice',
            kod: 580694,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56118,
            latitude: 49.890679,
            longitude: 16.343315,
        },
        {
            obec: 'Neratov',
            kod: 573078,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.079868,
            longitude: 15.647275,
        },
        {
            obec: 'Nová Sídla',
            kod: 572608,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.884023,
            longitude: 16.237288,
        },
        {
            obec: 'Nová Ves u Jarošova',
            kod: 572438,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.837637,
            longitude: 16.160009,
        },
        {
            obec: 'Nové Hrady',
            kod: 571920,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53945,
            latitude: 49.851778,
            longitude: 16.143909,
        },
        {
            obec: 'Oldřiš',
            kod: 578479,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56982,
            latitude: 49.727227,
            longitude: 16.19381,
        },
        {
            obec: 'Opatov',
            kod: 578487,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56912,
            latitude: 49.825097,
            longitude: 16.504684,
        },
        {
            obec: 'Opatovec',
            kod: 572721,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.805398,
            longitude: 16.480232,
        },
        {
            obec: 'Opatovice nad Labem',
            kod: 575429,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.145512,
            longitude: 15.790553,
        },
        {
            obec: 'Orel',
            kod: 571962,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.919409,
            longitude: 15.839135,
        },
        {
            obec: 'Orlické Podhůří',
            kod: 580716,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 50.00342,
            longitude: 16.35071,
        },
        {
            obec: 'Orličky',
            kod: 580724,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56155,
            latitude: 50.031626,
            longitude: 16.681575,
        },
        {
            obec: 'Osík',
            kod: 578509,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56967,
            latitude: 49.843645,
            longitude: 16.28477,
        },
        {
            obec: 'Ostrov',
            kod: 574104,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53863,
            latitude: 49.972703,
            longitude: 16.030432,
        },
        {
            obec: 'Ostrov',
            kod: 580732,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56122,
            latitude: 49.930345,
            longitude: 16.540637,
        },
        {
            obec: 'Ostřešany',
            kod: 575437,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.993525,
            longitude: 15.805408,
        },
        {
            obec: 'Ostřetín',
            kod: 575445,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.044716,
            longitude: 16.030478,
        },
        {
            obec: 'Otradov',
            kod: 554952,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53943,
            latitude: 49.793442,
            longitude: 16.043608,
        },
        {
            obec: 'Oucmanice',
            kod: 580741,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 49.989464,
            longitude: 16.281865,
        },
        {
            obec: 'Pardubice',
            kod: 555134,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.034409,
            longitude: 15.781299,
        },
        {
            obec: 'Pastviny',
            kod: 580759,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.094975,
            longitude: 16.566309,
        },
        {
            obec: 'Perálec',
            kod: 572004,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.826885,
            longitude: 16.079952,
        },
        {
            obec: 'Petrovice',
            kod: 580767,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.988215,
            longitude: 16.541627,
        },
        {
            obec: 'Písečná',
            kod: 580775,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56170,
            latitude: 50.045242,
            longitude: 16.448156,
        },
        {
            obec: 'Plch',
            kod: 572942,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.12887,
            longitude: 15.70284,
        },
        {
            obec: 'Plchovice',
            kod: 580783,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.045181,
            longitude: 16.181298,
        },
        {
            obec: 'Poběžovice u Holic',
            kod: 575461,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.097655,
            longitude: 16.000768,
        },
        {
            obec: 'Poběžovice u Přelouče',
            kod: 575470,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.991102,
            longitude: 15.581991,
        },
        {
            obec: 'Podhořany u Ronova',
            kod: 572039,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53841,
            latitude: 49.936237,
            longitude: 15.536819,
        },
        {
            obec: 'Podlesí',
            kod: 580805,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 50.020296,
            longitude: 16.281063,
        },
        {
            obec: 'Podůlšany',
            kod: 572951,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.128935,
            longitude: 15.738311,
        },
        {
            obec: 'Pohledy',
            kod: 578550,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.695006,
            longitude: 16.560608,
        },
        {
            obec: 'Pokřikov',
            kod: 572063,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.79687,
            longitude: 15.988264,
        },
        {
            obec: 'Polička',
            kod: 578576,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.715034,
            longitude: 16.265977,
        },
        {
            obec: 'Pomezí',
            kod: 578584,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56971,
            latitude: 49.710361,
            longitude: 16.317396,
        },
        {
            obec: 'Poříčí u Litomyšle',
            kod: 572373,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.798443,
            longitude: 16.193901,
        },
        {
            obec: 'Prachovice',
            kod: 572071,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53804,
            latitude: 49.893895,
            longitude: 15.628821,
        },
        {
            obec: 'Pravy',
            kod: 572853,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.131879,
            longitude: 15.624255,
        },
        {
            obec: 'Proseč',
            kod: 572080,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.805994,
            longitude: 16.11631,
        },
        {
            obec: 'Prosetín',
            kod: 572098,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53976,
            latitude: 49.833121,
            longitude: 15.957751,
        },
        {
            obec: 'Předhradí',
            kod: 572101,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53974,
            latitude: 49.831724,
            longitude: 16.038455,
        },
        {
            obec: 'Přelouč',
            kod: 575500,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.039948,
            longitude: 15.560413,
        },
        {
            obec: 'Přelovice',
            kod: 575518,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.072887,
            longitude: 15.614899,
        },
        {
            obec: 'Přepychy',
            kod: 575526,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.106455,
            longitude: 15.519991,
        },
        {
            obec: 'Přestavlky',
            kod: 572110,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.938074,
            longitude: 15.92634,
        },
        {
            obec: 'Příluka',
            kod: 578622,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.864971,
            longitude: 16.15664,
        },
        {
            obec: 'Přívrat',
            kod: 580821,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56002,
            latitude: 49.924704,
            longitude: 16.39707,
        },
        {
            obec: 'Pustá Kamenice',
            kod: 578631,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56982,
            latitude: 49.756215,
            longitude: 16.085881,
        },
        {
            obec: 'Pustá Rybná',
            kod: 578649,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.714822,
            longitude: 16.1351,
        },
        {
            obec: 'Pustina',
            kod: 580830,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.899577,
            longitude: 16.105003,
        },
        {
            obec: 'Rabštejnská Lhota',
            kod: 556882,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.915662,
            longitude: 15.768153,
        },
        {
            obec: 'Ráby',
            kod: 575534,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.07288,
            longitude: 15.802625,
        },
        {
            obec: 'Radhošť',
            kod: 575542,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 49.987647,
            longitude: 16.076121,
        },
        {
            obec: 'Radiměř',
            kod: 578657,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56907,
            latitude: 49.699586,
            longitude: 16.431386,
        },
        {
            obec: 'Radkov',
            kod: 574333,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.734468,
            longitude: 16.741584,
        },
        {
            obec: 'Raná',
            kod: 572152,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53972,
            latitude: 49.796504,
            longitude: 15.96422,
        },
        {
            obec: 'Rohovládova Bělá',
            kod: 575551,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53343,
            latitude: 50.106311,
            longitude: 15.606265,
        },
        {
            obec: 'Rohozná',
            kod: 578673,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56972,
            latitude: 49.653741,
            longitude: 16.407989,
        },
        {
            obec: 'Rohoznice',
            kod: 575569,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.127321,
            longitude: 15.664377,
        },
        {
            obec: 'Rokytno',
            kod: 575577,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53304,
            latitude: 50.104773,
            longitude: 15.889408,
        },
        {
            obec: 'Ronov nad Doubravou',
            kod: 572161,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53842,
            latitude: 49.888353,
            longitude: 15.531541,
        },
        {
            obec: 'Rosice',
            kod: 572179,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53834,
            latitude: 49.922359,
            longitude: 15.951376,
        },
        {
            obec: 'Rozhovice',
            kod: 547808,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.967283,
            longitude: 15.713618,
        },
        {
            obec: 'Rozhraní',
            kod: 578681,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56903,
            latitude: 49.601761,
            longitude: 16.533472,
        },
        {
            obec: 'Rozstání',
            kod: 578690,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.735067,
            longitude: 16.720164,
        },
        {
            obec: 'Rudná',
            kod: 578703,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.659222,
            longitude: 16.573374,
        },
        {
            obec: 'Rudoltice',
            kod: 580848,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56125,
            latitude: 49.898427,
            longitude: 16.569965,
        },
        {
            obec: 'Rybitví',
            kod: 575593,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53354,
            latitude: 50.060247,
            longitude: 15.704827,
        },
        {
            obec: 'Rybník',
            kod: 547905,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56002,
            latitude: 49.888725,
            longitude: 16.474526,
        },
        {
            obec: 'Rychnov na Moravě',
            kod: 578711,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56934,
            latitude: 49.829244,
            longitude: 16.640214,
        },
        {
            obec: 'Řečany nad Labem',
            kod: 575607,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53313,
            latitude: 50.03599,
            longitude: 15.477455,
        },
        {
            obec: 'Řepníky',
            kod: 572209,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53865,
            latitude: 49.901232,
            longitude: 16.079849,
        },
        {
            obec: 'Řestoky',
            kod: 572217,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.922503,
            longitude: 15.916661,
        },
        {
            obec: 'Řetová',
            kod: 580872,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56141,
            latitude: 49.945893,
            longitude: 16.38133,
        },
        {
            obec: 'Řetůvka',
            kod: 580881,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56141,
            latitude: 49.95587,
            longitude: 16.356124,
        },
        {
            obec: 'Řídký',
            kod: 572233,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.894209,
            longitude: 16.243209,
        },
        {
            obec: 'Sádek',
            kod: 578720,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.692663,
            longitude: 16.225728,
        },
        {
            obec: 'Sázava',
            kod: 574392,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.908253,
            longitude: 16.641003,
        },
        {
            obec: 'Sebranice',
            kod: 578738,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56962,
            latitude: 49.771494,
            longitude: 16.249278,
        },
        {
            obec: 'Seč',
            kod: 572225,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53807,
            latitude: 49.847037,
            longitude: 15.65653,
        },
        {
            obec: 'Seč',
            kod: 580902,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 50.025612,
            longitude: 16.294907,
        },
        {
            obec: 'Sedliště',
            kod: 578746,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.893977,
            longitude: 16.274918,
        },
        {
            obec: 'Selmice',
            kod: 575615,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.050233,
            longitude: 15.440919,
        },
        {
            obec: 'Semanín',
            kod: 555240,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56002,
            latitude: 49.869404,
            longitude: 16.453026,
        },
        {
            obec: 'Semín',
            kod: 575623,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.053188,
            longitude: 15.519831,
        },
        {
            obec: 'Sezemice',
            kod: 575640,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53304,
            latitude: 50.066605,
            longitude: 15.852803,
        },
        {
            obec: 'Sklené',
            kod: 578754,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.70686,
            longitude: 16.530602,
        },
        {
            obec: 'Skořenice',
            kod: 580929,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.031358,
            longitude: 16.222061,
        },
        {
            obec: 'Skuteč',
            kod: 572241,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53973,
            latitude: 49.843567,
            longitude: 15.996654,
        },
        {
            obec: 'Slatina',
            kod: 578762,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.629744,
            longitude: 16.607918,
        },
        {
            obec: 'Slatina',
            kod: 580945,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.978184,
            longitude: 16.163803,
        },
        {
            obec: 'Slatiňany',
            kod: 572268,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53821,
            latitude: 49.921196,
            longitude: 15.813873,
        },
        {
            obec: 'Slepotice',
            kod: 575658,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.992168,
            longitude: 15.96145,
        },
        {
            obec: 'Sloupnice',
            kod: 580325,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56553,
            latitude: 49.920923,
            longitude: 16.330377,
        },
        {
            obec: 'Smrček',
            kod: 573817,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.868517,
            longitude: 15.893381,
        },
        {
            obec: 'Sobětuchy',
            kod: 572276,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.933341,
            longitude: 15.764066,
        },
        {
            obec: 'Sobkovice',
            kod: 547964,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56164,
            latitude: 50.048008,
            longitude: 16.595086,
        },
        {
            obec: 'Sopotnice',
            kod: 580961,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56115,
            latitude: 50.059141,
            longitude: 16.345368,
        },
        {
            obec: 'Sopřeč',
            kod: 575666,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.093704,
            longitude: 15.556821,
        },
        {
            obec: 'Sovolusky',
            kod: 573027,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.969347,
            longitude: 15.5373,
        },
        {
            obec: 'Spojil',
            kod: 574198,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.042269,
            longitude: 15.822517,
        },
        {
            obec: 'Srch',
            kod: 575682,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.084994,
            longitude: 15.763532,
        },
        {
            obec: 'Srnojedy',
            kod: 553719,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.039211,
            longitude: 15.702721,
        },
        {
            obec: 'Sruby',
            kod: 580970,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56544,
            latitude: 49.996975,
            longitude: 16.174354,
        },
        {
            obec: 'Staré Hradiště',
            kod: 575704,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53352,
            latitude: 50.065498,
            longitude: 15.778951,
        },
        {
            obec: 'Staré Jesenčany',
            kod: 575712,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.000581,
            longitude: 15.754182,
        },
        {
            obec: 'Staré Město',
            kod: 578789,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56932,
            latitude: 49.794091,
            longitude: 16.675033,
        },
        {
            obec: 'Staré Ždánice',
            kod: 575721,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53344,
            latitude: 50.124004,
            longitude: 15.720737,
        },
        {
            obec: 'Starý Mateřov',
            kod: 575739,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 50.001737,
            longitude: 15.71522,
        },
        {
            obec: 'Stašov',
            kod: 578801,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.674979,
            longitude: 16.369023,
        },
        {
            obec: 'Stéblová',
            kod: 572934,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53345,
            latitude: 50.092124,
            longitude: 15.746276,
        },
        {
            obec: 'Stojice',
            kod: 575755,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.956461,
            longitude: 15.614128,
        },
        {
            obec: 'Stolany',
            kod: 547891,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.9279,
            longitude: 15.745214,
        },
        {
            obec: 'Stradouň',
            kod: 572306,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53863,
            latitude: 49.97246,
            longitude: 16.065657,
        },
        {
            obec: 'Strakov',
            kod: 578819,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.866605,
            longitude: 16.366914,
        },
        {
            obec: 'Strašov',
            kod: 575763,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.087564,
            longitude: 15.522806,
        },
        {
            obec: 'Strážná',
            kod: 580988,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.911309,
            longitude: 16.71062,
        },
        {
            obec: 'Střemošice',
            kod: 572314,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53854,
            latitude: 49.89166,
            longitude: 16.072158,
        },
        {
            obec: 'Studené',
            kod: 580996,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56164,
            latitude: 50.066658,
            longitude: 16.589294,
        },
        {
            obec: 'Studnice',
            kod: 572322,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.738058,
            longitude: 15.90302,
        },
        {
            obec: 'Sudislav nad Orlicí',
            kod: 581003,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 49.989392,
            longitude: 16.313242,
        },
        {
            obec: 'Sudslava',
            kod: 581011,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56113,
            latitude: 50.040677,
            longitude: 16.286549,
        },
        {
            obec: 'Suchá Lhota',
            kod: 578835,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.877328,
            longitude: 16.173642,
        },
        {
            obec: 'Svatý Jiří',
            kod: 580708,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 49.972104,
            longitude: 16.270429,
        },
        {
            obec: 'Svídnice',
            kod: 572331,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53824,
            latitude: 49.890007,
            longitude: 15.813804,
        },
        {
            obec: 'Svinčany',
            kod: 575771,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.976687,
            longitude: 15.639615,
        },
        {
            obec: 'Svitavy',
            kod: 577731,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56802,
            latitude: 49.755263,
            longitude: 16.469286,
        },
        {
            obec: 'Svojanov',
            kod: 578843,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56973,
            latitude: 49.624723,
            longitude: 16.410306,
        },
        {
            obec: 'Svojšice',
            kod: 575780,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53362,
            latitude: 49.965437,
            longitude: 15.601078,
        },
        {
            obec: 'Svratouch',
            kod: 572349,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53942,
            latitude: 49.72454,
            longitude: 16.034289,
        },
        {
            obec: 'Šedivec',
            kod: 581038,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.04929,
            longitude: 16.535815,
        },
        {
            obec: 'Široký Důl',
            kod: 578851,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57201,
            latitude: 49.746139,
            longitude: 16.220866,
        },
        {
            obec: 'Študlov',
            kod: 578860,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.602645,
            longitude: 16.498432,
        },
        {
            obec: 'Tatenice',
            kod: 581046,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56131,
            latitude: 49.871076,
            longitude: 16.697311,
        },
        {
            obec: 'Těchonín',
            kod: 581054,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56166,
            latitude: 50.065031,
            longitude: 16.618015,
        },
        {
            obec: 'Telecí',
            kod: 578878,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56994,
            latitude: 49.694722,
            longitude: 16.180806,
        },
        {
            obec: 'Tetov',
            kod: 575810,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.080585,
            longitude: 15.445738,
        },
        {
            obec: 'Tisová',
            kod: 581062,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.936258,
            longitude: 16.224633,
        },
        {
            obec: 'Tisovec',
            kod: 572381,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53973,
            latitude: 49.824479,
            longitude: 15.914659,
        },
        {
            obec: 'Trhová Kamenice',
            kod: 572390,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53952,
            latitude: 49.786894,
            longitude: 15.816049,
        },
        {
            obec: 'Trnávka',
            kod: 530794,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.032844,
            longitude: 15.461124,
        },
        {
            obec: 'Trojovice',
            kod: 572403,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53833,
            latitude: 49.932925,
            longitude: 15.919674,
        },
        {
            obec: 'Trpík',
            kod: 547921,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.847575,
            longitude: 16.570003,
        },
        {
            obec: 'Trpín',
            kod: 578886,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56974,
            latitude: 49.593988,
            longitude: 16.402214,
        },
        {
            obec: 'Trstěnice',
            kod: 578894,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56957,
            latitude: 49.794239,
            longitude: 16.348852,
        },
        {
            obec: 'Trusnov',
            kod: 575828,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.000215,
            longitude: 16.044093,
        },
        {
            obec: 'Tržek',
            kod: 572616,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.886335,
            longitude: 16.260047,
        },
        {
            obec: 'Třebařov',
            kod: 578908,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56933,
            latitude: 49.829794,
            longitude: 16.702624,
        },
        {
            obec: 'Třebosice',
            kod: 572985,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.997348,
            longitude: 15.734932,
        },
        {
            obec: 'Třebovice',
            kod: 581071,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56124,
            latitude: 49.859351,
            longitude: 16.502143,
        },
        {
            obec: 'Třemošnice',
            kod: 572411,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.869219,
            longitude: 15.580129,
        },
        {
            obec: 'Třibřichy',
            kod: 504921,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.965357,
            longitude: 15.739289,
        },
        {
            obec: 'Tuněchody',
            kod: 572420,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53701,
            latitude: 49.978962,
            longitude: 15.84134,
        },
        {
            obec: 'Turkovice',
            kod: 575844,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53363,
            latitude: 49.952551,
            longitude: 15.548856,
        },
        {
            obec: 'Týnišťko',
            kod: 575861,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.999487,
            longitude: 16.097653,
        },
        {
            obec: 'Úherčice',
            kod: 530697,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.919564,
            longitude: 15.677746,
        },
        {
            obec: 'Uhersko',
            kod: 575879,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53373,
            latitude: 49.992474,
            longitude: 16.017879,
        },
        {
            obec: 'Úhřetice',
            kod: 572446,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53832,
            latitude: 49.979251,
            longitude: 15.867345,
        },
        {
            obec: 'Úhřetická Lhota',
            kod: 575887,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53002,
            latitude: 49.988669,
            longitude: 15.872852,
        },
        {
            obec: 'Újezd u Chocně',
            kod: 581089,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 50.026995,
            longitude: 16.164691,
        },
        {
            obec: 'Újezd u Přelouče',
            kod: 575909,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.102031,
            longitude: 15.494626,
        },
        {
            obec: 'Újezd u Sezemic',
            kod: 572888,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53304,
            latitude: 50.114148,
            longitude: 15.855832,
        },
        {
            obec: 'Újezdec',
            kod: 578916,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.877862,
            longitude: 16.206035,
        },
        {
            obec: 'Urbanice',
            kod: 575917,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 49.974044,
            longitude: 15.567111,
        },
        {
            obec: 'Ústí nad Orlicí',
            kod: 579891,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 49.97228,
            longitude: 16.399762,
        },
        {
            obec: 'Útěchov',
            kod: 572624,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.728486,
            longitude: 16.64466,
        },
        {
            obec: 'Valy',
            kod: 575925,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.029694,
            longitude: 15.61687,
        },
        {
            obec: 'Vápenný Podol',
            kod: 572454,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.889133,
            longitude: 15.66685,
        },
        {
            obec: 'Vápno',
            kod: 575933,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.10432,
            longitude: 15.533003,
        },
        {
            obec: 'Včelákov',
            kod: 572462,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53957,
            latitude: 49.817781,
            longitude: 15.884462,
        },
        {
            obec: 'Vejvanovice',
            kod: 572471,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53862,
            latitude: 49.971029,
            longitude: 15.880178,
        },
        {
            obec: 'Veliny',
            kod: 575941,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53401,
            latitude: 50.071154,
            longitude: 16.053816,
        },
        {
            obec: 'Velká Skrovnice',
            kod: 581101,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56201,
            latitude: 50.030493,
            longitude: 16.317005,
        },
        {
            obec: 'Vendolí',
            kod: 578932,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56914,
            latitude: 49.739926,
            longitude: 16.413371,
        },
        {
            obec: 'Verměřovice',
            kod: 581119,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56152,
            latitude: 50.005589,
            longitude: 16.562656,
        },
        {
            obec: 'Veselí',
            kod: 575968,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53501,
            latitude: 50.011243,
            longitude: 15.618926,
        },
        {
            obec: 'Vidlatá Seč',
            kod: 578941,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57001,
            latitude: 49.836139,
            longitude: 16.205311,
        },
        {
            obec: 'Vinary',
            kod: 572489,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53863,
            latitude: 49.959207,
            longitude: 16.060381,
        },
        {
            obec: 'Víska u Jevíčka',
            kod: 578959,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.652641,
            longitude: 16.707835,
        },
        {
            obec: 'Vítanov',
            kod: 572497,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.749189,
            longitude: 15.88183,
        },
        {
            obec: 'Vítějeves',
            kod: 578967,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56906,
            latitude: 49.615824,
            longitude: 16.465469,
        },
        {
            obec: 'Vlčí Habřina',
            kod: 575984,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.085931,
            longitude: 15.596053,
        },
        {
            obec: 'Vlčkov',
            kod: 581127,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56002,
            latitude: 49.907677,
            longitude: 16.350071,
        },
        {
            obec: 'Voděrady',
            kod: 581143,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.949695,
            longitude: 16.285165,
        },
        {
            obec: 'Vojtěchov',
            kod: 572501,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.788649,
            longitude: 15.973606,
        },
        {
            obec: 'Voleč',
            kod: 575992,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.117449,
            longitude: 15.572804,
        },
        {
            obec: 'Vortová',
            kod: 572527,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53961,
            latitude: 49.713143,
            longitude: 15.93634,
        },
        {
            obec: 'Vraclav',
            kod: 581151,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56542,
            latitude: 49.968138,
            longitude: 16.090073,
        },
        {
            obec: 'Vračovice-Orlov',
            kod: 548022,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.952537,
            longitude: 16.252476,
        },
        {
            obec: 'Vranová Lhota',
            kod: 578975,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 57101,
            latitude: 49.710743,
            longitude: 16.825363,
        },
        {
            obec: 'Vrážné',
            kod: 572292,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.675991,
            longitude: 16.778122,
        },
        {
            obec: 'Vrbatův Kostelec',
            kod: 572535,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53956,
            latitude: 49.856443,
            longitude: 15.941145,
        },
        {
            obec: 'Všeradov',
            kod: 572543,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.747799,
            longitude: 15.837299,
        },
        {
            obec: 'Výprachtice',
            kod: 581178,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56134,
            latitude: 49.987302,
            longitude: 16.663918,
        },
        {
            obec: 'Vysočina',
            kod: 572551,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53901,
            latitude: 49.76477,
            longitude: 15.829087,
        },
        {
            obec: 'Vysoká',
            kod: 578991,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56943,
            latitude: 49.663714,
            longitude: 16.810197,
        },
        {
            obec: 'Vysoké Chvojno',
            kod: 576000,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53321,
            latitude: 50.109088,
            longitude: 15.973593,
        },
        {
            obec: 'Vysoké Mýto',
            kod: 581186,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.954364,
            longitude: 16.164268,
        },
        {
            obec: 'Vyšehněvice',
            kod: 576018,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53341,
            latitude: 50.102402,
            longitude: 15.583741,
        },
        {
            obec: 'Vyžice',
            kod: 547841,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53803,
            latitude: 49.923275,
            longitude: 15.621171,
        },
        {
            obec: 'Zádolí',
            kod: 581194,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56601,
            latitude: 49.903246,
            longitude: 16.127162,
        },
        {
            obec: 'Záchlumí',
            kod: 581208,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56186,
            latitude: 50.095447,
            longitude: 16.376348,
        },
        {
            obec: 'Zaječice',
            kod: 572578,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53835,
            latitude: 49.910081,
            longitude: 15.884208,
        },
        {
            obec: 'Zájezdec',
            kod: 547859,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53851,
            latitude: 49.931755,
            longitude: 15.926941,
        },
        {
            obec: 'Zálší',
            kod: 581216,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 49.965363,
            longitude: 16.240831,
        },
        {
            obec: 'Zámrsk',
            kod: 581224,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56543,
            latitude: 49.986276,
            longitude: 16.128847,
        },
        {
            obec: 'Zářecká Lhota',
            kod: 581232,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56501,
            latitude: 49.993208,
            longitude: 16.246224,
        },
        {
            obec: 'Zdechovice',
            kod: 576026,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53311,
            latitude: 50.013006,
            longitude: 15.46958,
        },
        {
            obec: 'Zderaz',
            kod: 573876,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53944,
            latitude: 49.828645,
            longitude: 16.100192,
        },
        {
            obec: 'Žamberk',
            kod: 581259,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.087401,
            longitude: 16.465301,
        },
        {
            obec: 'Žampach',
            kod: 581267,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56401,
            latitude: 50.038071,
            longitude: 16.427306,
        },
        {
            obec: 'Žáravice',
            kod: 576042,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53316,
            latitude: 50.105854,
            longitude: 15.557217,
        },
        {
            obec: 'Želivsko',
            kod: 572195,
            okres: 'Svitavy',
            kod_okres: 'CZ0533',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56904,
            latitude: 49.641253,
            longitude: 16.570083,
        },
        {
            obec: 'Žichlínek',
            kod: 581275,
            okres: 'Ústí nad Orlicí',
            kod_okres: 'CZ0534',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 56301,
            latitude: 49.884005,
            longitude: 16.636393,
        },
        {
            obec: 'Živanice',
            kod: 576051,
            okres: 'Pardubice',
            kod_okres: 'CZ0532',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53342,
            latitude: 50.062251,
            longitude: 15.644886,
        },
        {
            obec: 'Žlebské Chvalovice',
            kod: 574091,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53843,
            latitude: 49.892807,
            longitude: 15.566816,
        },
        {
            obec: 'Žumberk',
            kod: 572641,
            okres: 'Chrudim',
            kod_okres: 'CZ0531',
            kraj: 'Pardubický kraj',
            kod_kraj: 'CZ053',
            psc: 53836,
            latitude: 49.873077,
            longitude: 15.858205,
        },
    ],
    CZ051: [
        {
            obec: 'Albrechtice v Jizerských horách',
            kod: 563528,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46843,
            latitude: 50.7624,
            longitude: 15.275813,
        },
        {
            obec: 'Bedřichov',
            kod: 563536,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46812,
            latitude: 50.791188,
            longitude: 15.142571,
        },
        {
            obec: 'Bělá',
            kod: 576972,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.534417,
            longitude: 15.445427,
        },
        {
            obec: 'Benecko',
            kod: 576981,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51237,
            latitude: 50.666433,
            longitude: 15.548272,
        },
        {
            obec: 'Benešov u Semil',
            kod: 576999,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51206,
            latitude: 50.603258,
            longitude: 15.368478,
        },
        {
            obec: 'Bezděz',
            kod: 561398,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.533742,
            longitude: 14.722084,
        },
        {
            obec: 'Bílá',
            kod: 563901,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46343,
            latitude: 50.664931,
            longitude: 15.035522,
        },
        {
            obec: 'Bílý Kostel nad Nisou',
            kod: 563919,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46331,
            latitude: 50.823246,
            longitude: 14.924507,
        },
        {
            obec: 'Bílý Potok',
            kod: 546631,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46362,
            latitude: 50.873842,
            longitude: 15.222203,
        },
        {
            obec: 'Blatce',
            kod: 561401,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.502986,
            longitude: 14.603251,
        },
        {
            obec: 'Blíževedly',
            kod: 561410,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47104,
            latitude: 50.608503,
            longitude: 14.396598,
        },
        {
            obec: 'Bohatice',
            kod: 561428,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47002,
            latitude: 50.669033,
            longitude: 14.679426,
        },
        {
            obec: 'Bozkov',
            kod: 577006,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51213,
            latitude: 50.640171,
            longitude: 15.337206,
        },
        {
            obec: 'Bradlecká Lhota',
            kod: 577014,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 50713,
            latitude: 50.488672,
            longitude: 15.396332,
        },
        {
            obec: 'Brniště',
            kod: 561444,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47129,
            latitude: 50.729288,
            longitude: 14.703495,
        },
        {
            obec: 'Bukovina u Čisté',
            kod: 577031,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.542694,
            longitude: 15.58394,
        },
        {
            obec: 'Bulovka',
            kod: 563935,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.971244,
            longitude: 15.12367,
        },
        {
            obec: 'Bystrá nad Jizerou',
            kod: 577049,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.604834,
            longitude: 15.401976,
        },
        {
            obec: 'Cetenov',
            kod: 563943,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46348,
            latitude: 50.644523,
            longitude: 14.917705,
        },
        {
            obec: 'Cvikov',
            kod: 561479,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47154,
            latitude: 50.777293,
            longitude: 14.633019,
        },
        {
            obec: 'Černousy',
            kod: 545996,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46373,
            latitude: 51.004564,
            longitude: 15.050573,
        },
        {
            obec: 'Česká Lípa',
            kod: 561380,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47001,
            latitude: 50.67862,
            longitude: 14.539799,
        },
        {
            obec: 'Český Dub',
            kod: 563960,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46343,
            latitude: 50.661116,
            longitude: 14.996287,
        },
        {
            obec: 'Čistá u Horek',
            kod: 577057,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51235,
            latitude: 50.5317,
            longitude: 15.60765,
        },
        {
            obec: 'Čtveřín',
            kod: 544531,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.592605,
            longitude: 15.100281,
        },
        {
            obec: 'Dalešice',
            kod: 530425,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46802,
            latitude: 50.681858,
            longitude: 15.184679,
        },
        {
            obec: 'Desná',
            kod: 563552,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46861,
            latitude: 50.75481,
            longitude: 15.313862,
        },
        {
            obec: 'Dětřichov',
            kod: 546607,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.892748,
            longitude: 15.036676,
        },
        {
            obec: 'Dlouhý Most',
            kod: 530468,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46312,
            latitude: 50.711714,
            longitude: 15.075978,
        },
        {
            obec: 'Doksy',
            kod: 561495,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.565945,
            longitude: 14.654377,
        },
        {
            obec: 'Dolní Řasnice',
            kod: 563994,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.945787,
            longitude: 15.168786,
        },
        {
            obec: 'Držkov',
            kod: 563561,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46824,
            latitude: 50.68764,
            longitude: 15.303285,
        },
        {
            obec: 'Dubá',
            kod: 561533,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47141,
            latitude: 50.540444,
            longitude: 14.540343,
        },
        {
            obec: 'Dubnice',
            kod: 561541,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47126,
            latitude: 50.726074,
            longitude: 14.808823,
        },
        {
            obec: 'Frýdlant',
            kod: 564028,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.921496,
            longitude: 15.079851,
        },
        {
            obec: 'Frýdštejn',
            kod: 563579,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46342,
            latitude: 50.652357,
            longitude: 15.158867,
        },
        {
            obec: 'Habartice',
            kod: 564036,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46373,
            latitude: 51.019134,
            longitude: 15.065266,
        },
        {
            obec: 'Háje nad Jizerou',
            kod: 577073,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.613238,
            longitude: 15.421312,
        },
        {
            obec: 'Hamr na Jezeře',
            kod: 544337,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47128,
            latitude: 50.702975,
            longitude: 14.838293,
        },
        {
            obec: 'Harrachov',
            kod: 577081,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51246,
            latitude: 50.772186,
            longitude: 15.431521,
        },
        {
            obec: 'Hejnice',
            kod: 564044,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46362,
            latitude: 50.877301,
            longitude: 15.181785,
        },
        {
            obec: 'Heřmanice',
            kod: 544353,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.897,
            longitude: 15.006457,
        },
        {
            obec: 'Hlavice',
            kod: 564052,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46348,
            latitude: 50.631998,
            longitude: 14.926704,
        },
        {
            obec: 'Hodkovice nad Mohelkou',
            kod: 564061,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46342,
            latitude: 50.665963,
            longitude: 15.08996,
        },
        {
            obec: 'Holany',
            kod: 561584,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47002,
            latitude: 50.618099,
            longitude: 14.493073,
        },
        {
            obec: 'Holenice',
            kod: 577111,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 50601,
            latitude: 50.520473,
            longitude: 15.300548,
        },
        {
            obec: 'Horka u Staré Paky',
            kod: 574201,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51234,
            latitude: 50.52618,
            longitude: 15.57583,
        },
        {
            obec: 'Horní Branná',
            kod: 577120,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51236,
            latitude: 50.608357,
            longitude: 15.571571,
        },
        {
            obec: 'Horní Libchava',
            kod: 561592,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47111,
            latitude: 50.712584,
            longitude: 14.493538,
        },
        {
            obec: 'Horní Police',
            kod: 561606,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47106,
            latitude: 50.704219,
            longitude: 14.404106,
        },
        {
            obec: 'Horní Řasnice',
            kod: 564079,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.965564,
            longitude: 15.201599,
        },
        {
            obec: 'Hrádek nad Nisou',
            kod: 564095,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46334,
            latitude: 50.852892,
            longitude: 14.844658,
        },
        {
            obec: 'Hrubá Skála',
            kod: 577146,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.544906,
            longitude: 15.200342,
        },
        {
            obec: 'Chlum',
            kod: 561614,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.578538,
            longitude: 14.562772,
        },
        {
            obec: 'Chotovice',
            kod: 561622,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47301,
            latitude: 50.74039,
            longitude: 14.559384,
        },
        {
            obec: 'Chotyně',
            kod: 564109,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46334,
            latitude: 50.83632,
            longitude: 14.869039,
        },
        {
            obec: 'Chrastava',
            kod: 564117,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46331,
            latitude: 50.817027,
            longitude: 14.968947,
        },
        {
            obec: 'Chuchelna',
            kod: 577154,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.602387,
            longitude: 15.300449,
        },
        {
            obec: 'Jablonec nad Jizerou',
            kod: 577162,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51243,
            latitude: 50.703569,
            longitude: 15.429698,
        },
        {
            obec: 'Jablonec nad Nisou',
            kod: 563510,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46601,
            latitude: 50.722153,
            longitude: 15.170414,
        },
        {
            obec: 'Jablonné v Podještědí',
            kod: 561631,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47125,
            latitude: 50.765382,
            longitude: 14.760633,
        },
        {
            obec: 'Janov nad Nisou',
            kod: 563595,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46811,
            latitude: 50.772141,
            longitude: 15.169244,
        },
        {
            obec: 'Janovice v Podještědí',
            kod: 561657,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46353,
            latitude: 50.762974,
            longitude: 14.824344,
        },
        {
            obec: 'Janův Důl',
            kod: 546658,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46352,
            latitude: 50.704656,
            longitude: 14.940408,
        },
        {
            obec: 'Jenišovice',
            kod: 563609,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46833,
            latitude: 50.627796,
            longitude: 15.136637,
        },
        {
            obec: 'Jeřmanice',
            kod: 530484,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46312,
            latitude: 50.699666,
            longitude: 15.09393,
        },
        {
            obec: 'Jesenný',
            kod: 577171,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51212,
            latitude: 50.660595,
            longitude: 15.338516,
        },
        {
            obec: 'Jestřabí v Krkonoších',
            kod: 577189,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.680618,
            longitude: 15.491835,
        },
        {
            obec: 'Jestřebí',
            kod: 561665,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47161,
            latitude: 50.608694,
            longitude: 14.584657,
        },
        {
            obec: 'Jilemnice',
            kod: 577197,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.608996,
            longitude: 15.506637,
        },
        {
            obec: 'Jílové u Držkova',
            kod: 563617,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.670963,
            longitude: 15.295375,
        },
        {
            obec: 'Jindřichovice pod Smrkem',
            kod: 564133,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46365,
            latitude: 50.960916,
            longitude: 15.249643,
        },
        {
            obec: 'Jiřetín pod Bukovou',
            kod: 546585,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46843,
            latitude: 50.755788,
            longitude: 15.26303,
        },
        {
            obec: 'Josefův Důl',
            kod: 563633,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46844,
            latitude: 50.782016,
            longitude: 15.231574,
        },
        {
            obec: 'Kacanovy',
            kod: 577201,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.550769,
            longitude: 15.14538,
        },
        {
            obec: 'Kamenický Šenov',
            kod: 561681,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47114,
            latitude: 50.773693,
            longitude: 14.472979,
        },
        {
            obec: 'Karlovice',
            kod: 577219,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.561843,
            longitude: 15.20728,
        },
        {
            obec: 'Klokočí',
            kod: 577227,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.599761,
            longitude: 15.221716,
        },
        {
            obec: 'Koberovy',
            kod: 563641,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.62476,
            longitude: 15.228332,
        },
        {
            obec: 'Kobyly',
            kod: 564141,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.610271,
            longitude: 15.004443,
        },
        {
            obec: 'Kořenov',
            kod: 563668,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46849,
            latitude: 50.759359,
            longitude: 15.365424,
        },
        {
            obec: 'Košťálov',
            kod: 577235,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51202,
            latitude: 50.571751,
            longitude: 15.404135,
        },
        {
            obec: 'Kozly',
            kod: 546232,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47001,
            latitude: 50.655449,
            longitude: 14.45693,
        },
        {
            obec: 'Krásný Les',
            kod: 564168,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.940324,
            longitude: 15.127667,
        },
        {
            obec: 'Kravaře',
            kod: 561720,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47103,
            latitude: 50.632503,
            longitude: 14.392521,
        },
        {
            obec: 'Krompach',
            kod: 561738,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47157,
            latitude: 50.828174,
            longitude: 14.701621,
        },
        {
            obec: 'Kruh',
            kod: 577243,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.565638,
            longitude: 15.480245,
        },
        {
            obec: 'Kryštofovo Údolí',
            kod: 564176,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46001,
            latitude: 50.774549,
            longitude: 14.933501,
        },
        {
            obec: 'Křižany',
            kod: 564184,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46353,
            latitude: 50.73858,
            longitude: 14.901417,
        },
        {
            obec: 'Ktová',
            kod: 547484,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51263,
            latitude: 50.519937,
            longitude: 15.246847,
        },
        {
            obec: 'Kunratice',
            kod: 530433,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.921515,
            longitude: 15.026152,
        },
        {
            obec: 'Kunratice u Cvikova',
            kod: 561746,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47155,
            latitude: 50.768777,
            longitude: 14.678722,
        },
        {
            obec: 'Kvítkov',
            kod: 546259,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47001,
            latitude: 50.655024,
            longitude: 14.486766,
        },
        {
            obec: 'Lázně Libverda',
            kod: 564206,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46362,
            latitude: 50.890241,
            longitude: 15.190471,
        },
        {
            obec: 'Lažany',
            kod: 545937,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.601,
            longitude: 15.109852,
        },
        {
            obec: 'Levínská Olešnice',
            kod: 577375,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51401,
            latitude: 50.528307,
            longitude: 15.540814,
        },
        {
            obec: 'Liberec',
            kod: 563889,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46001,
            latitude: 50.76638,
            longitude: 15.054439,
        },
        {
            obec: 'Libštát',
            kod: 577294,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51203,
            latitude: 50.559503,
            longitude: 15.416037,
        },
        {
            obec: 'Líšný',
            kod: 563676,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.64567,
            longitude: 15.212313,
        },
        {
            obec: 'Lomnice nad Popelkou',
            kod: 577308,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51251,
            latitude: 50.530724,
            longitude: 15.373517,
        },
        {
            obec: 'Loučky',
            kod: 573400,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.617659,
            longitude: 15.218593,
        },
        {
            obec: 'Loužnice',
            kod: 563684,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.679747,
            longitude: 15.2678,
        },
        {
            obec: 'Lučany nad Nisou',
            kod: 563692,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46871,
            latitude: 50.741456,
            longitude: 15.220572,
        },
        {
            obec: 'Luka',
            kod: 514161,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.51251,
            longitude: 14.664183,
        },
        {
            obec: 'Malá Skála',
            kod: 563706,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.646408,
            longitude: 15.195534,
        },
        {
            obec: 'Maršovice',
            kod: 563714,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46801,
            latitude: 50.696455,
            longitude: 15.198577,
        },
        {
            obec: 'Martinice v Krkonoších',
            kod: 573418,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51232,
            latitude: 50.580946,
            longitude: 15.533412,
        },
        {
            obec: 'Mařenice',
            kod: 561827,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47156,
            latitude: 50.807507,
            longitude: 14.678659,
        },
        {
            obec: 'Mimoň',
            kod: 561835,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47124,
            latitude: 50.655768,
            longitude: 14.724956,
        },
        {
            obec: 'Mírová pod Kozákovem',
            kod: 577316,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.591643,
            longitude: 15.19435,
        },
        {
            obec: 'Mníšek',
            kod: 564231,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46331,
            latitude: 50.831733,
            longitude: 15.056405,
        },
        {
            obec: 'Modřišice',
            kod: 577324,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.574176,
            longitude: 15.119437,
        },
        {
            obec: 'Mříčná',
            kod: 577332,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51204,
            latitude: 50.600813,
            longitude: 15.468897,
        },
        {
            obec: 'Nová Ves',
            kod: 546593,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46331,
            latitude: 50.831607,
            longitude: 15.001175,
        },
        {
            obec: 'Nová Ves nad Nisou',
            kod: 563731,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46827,
            latitude: 50.72524,
            longitude: 15.214758,
        },
        {
            obec: 'Nová Ves nad Popelkou',
            kod: 577341,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51271,
            latitude: 50.52041,
            longitude: 15.411952,
        },
        {
            obec: 'Nové Město pod Smrkem',
            kod: 564265,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46365,
            latitude: 50.925011,
            longitude: 15.229539,
        },
        {
            obec: 'Noviny pod Ralskem',
            kod: 561851,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47124,
            latitude: 50.692151,
            longitude: 14.746964,
        },
        {
            obec: 'Nový Bor',
            kod: 561860,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47301,
            latitude: 50.757714,
            longitude: 14.555664,
        },
        {
            obec: 'Nový Oldřichov',
            kod: 561878,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47113,
            latitude: 50.765514,
            longitude: 14.442254,
        },
        {
            obec: 'Ohrazenice',
            kod: 577359,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.597745,
            longitude: 15.126071,
        },
        {
            obec: 'Okna',
            kod: 561886,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47162,
            latitude: 50.527594,
            longitude: 14.671066,
        },
        {
            obec: 'Okrouhlá',
            kod: 561894,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47301,
            latitude: 50.766932,
            longitude: 14.527685,
        },
        {
            obec: 'Oldřichov v Hájích',
            kod: 564281,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46331,
            latitude: 50.849733,
            longitude: 15.083777,
        },
        {
            obec: 'Olešnice',
            kod: 577367,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.545045,
            longitude: 15.119305,
        },
        {
            obec: 'Osečná',
            kod: 564290,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46352,
            latitude: 50.69499,
            longitude: 14.921485,
        },
        {
            obec: 'Paceřice',
            kod: 545953,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46344,
            latitude: 50.619335,
            longitude: 15.113636,
        },
        {
            obec: 'Paseky nad Jizerou',
            kod: 547476,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51247,
            latitude: 50.724065,
            longitude: 15.400555,
        },
        {
            obec: 'Pěnčín',
            kod: 563749,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46821,
            latitude: 50.687791,
            longitude: 15.236038,
        },
        {
            obec: 'Pěnčín',
            kod: 564303,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.594968,
            longitude: 15.076196,
        },
        {
            obec: 'Pertoltice',
            kod: 564311,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46373,
            latitude: 50.979084,
            longitude: 15.076532,
        },
        {
            obec: 'Pertoltice pod Ralskem',
            kod: 514276,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47124,
            latitude: 50.680046,
            longitude: 14.719254,
        },
        {
            obec: 'Peřimov',
            kod: 577391,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51204,
            latitude: 50.619384,
            longitude: 15.44404,
        },
        {
            obec: 'Plavy',
            kod: 563757,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46846,
            latitude: 50.703338,
            longitude: 15.317471,
        },
        {
            obec: 'Polevsko',
            kod: 561959,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47116,
            latitude: 50.786771,
            longitude: 14.533028,
        },
        {
            obec: 'Poniklá',
            kod: 577405,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51242,
            latitude: 50.661618,
            longitude: 15.463395,
        },
        {
            obec: 'Proseč pod Ještědem',
            kod: 544345,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46343,
            latitude: 50.701762,
            longitude: 15.017139,
        },
        {
            obec: 'Provodín',
            kod: 561983,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47167,
            latitude: 50.617925,
            longitude: 14.601372,
        },
        {
            obec: 'Prysk',
            kod: 561991,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47115,
            latitude: 50.796139,
            longitude: 14.468026,
        },
        {
            obec: 'Přepeře',
            kod: 577413,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51261,
            latitude: 50.583032,
            longitude: 15.112901,
        },
        {
            obec: 'Příkrý',
            kod: 577421,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.625029,
            longitude: 15.364623,
        },
        {
            obec: 'Příšovice',
            kod: 564354,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46346,
            latitude: 50.578232,
            longitude: 15.084005,
        },
        {
            obec: 'Pulečný',
            kod: 546577,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46802,
            latitude: 50.675488,
            longitude: 15.167986,
        },
        {
            obec: 'Radčice',
            kod: 563773,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.672861,
            longitude: 15.277749,
        },
        {
            obec: 'Radimovice',
            kod: 544582,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46344,
            latitude: 50.626455,
            longitude: 15.082181,
        },
        {
            obec: 'Rádlo',
            kod: 563781,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46803,
            latitude: 50.698552,
            longitude: 15.115859,
        },
        {
            obec: 'Radostná pod Kozákovem',
            kod: 577430,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51263,
            latitude: 50.577589,
            longitude: 15.245541,
        },
        {
            obec: 'Radvanec',
            kod: 530387,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47301,
            latitude: 50.753003,
            longitude: 14.59215,
        },
        {
            obec: 'Rakousy',
            kod: 577448,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.620425,
            longitude: 15.187647,
        },
        {
            obec: 'Ralsko',
            kod: 562017,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47124,
            latitude: 50.58629,
            longitude: 14.80363,
        },
        {
            obec: 'Raspenava',
            kod: 564371,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.904256,
            longitude: 15.114757,
        },
        {
            obec: 'Rokytnice nad Jizerou',
            kod: 577456,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51244,
            latitude: 50.729228,
            longitude: 15.448787,
        },
        {
            obec: 'Roprachtice',
            kod: 577464,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.647554,
            longitude: 15.422263,
        },
        {
            obec: 'Rovensko pod Troskami',
            kod: 577472,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51263,
            latitude: 50.535418,
            longitude: 15.259515,
        },
        {
            obec: 'Roztoky u Jilemnice',
            kod: 577499,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51231,
            latitude: 50.563887,
            longitude: 15.499852,
        },
        {
            obec: 'Roztoky u Semil',
            kod: 577481,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.65855,
            longitude: 15.371639,
        },
        {
            obec: 'Rychnov u Jablonce nad Nisou',
            kod: 563790,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46802,
            latitude: 50.683891,
            longitude: 15.149898,
        },
        {
            obec: 'Rynoltice',
            kod: 564397,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46353,
            latitude: 50.789106,
            longitude: 14.818942,
        },
        {
            obec: 'Semily',
            kod: 576964,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.605258,
            longitude: 15.328241,
        },
        {
            obec: 'Skalice u České Lípy',
            kod: 562025,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47117,
            latitude: 50.745217,
            longitude: 14.53067,
        },
        {
            obec: 'Skalka u Doks',
            kod: 513890,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.563681,
            longitude: 14.619672,
        },
        {
            obec: 'Skuhrov',
            kod: 563803,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.676103,
            longitude: 15.213411,
        },
        {
            obec: 'Slaná',
            kod: 577529,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51201,
            latitude: 50.580911,
            longitude: 15.329787,
        },
        {
            obec: 'Sloup v Čechách',
            kod: 562050,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47152,
            latitude: 50.739037,
            longitude: 14.588922,
        },
        {
            obec: 'Slunečná',
            kod: 546275,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47301,
            latitude: 50.735875,
            longitude: 14.482593,
        },
        {
            obec: 'Smržovka',
            kod: 563811,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46851,
            latitude: 50.738301,
            longitude: 15.246502,
        },
        {
            obec: 'Soběslavice',
            kod: 564401,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.605,
            longitude: 15.034217,
        },
        {
            obec: 'Sosnová',
            kod: 562076,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47001,
            latitude: 50.655151,
            longitude: 14.532396,
        },
        {
            obec: 'Stráž nad Nisou',
            kod: 544477,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46303,
            latitude: 50.791093,
            longitude: 15.026936,
        },
        {
            obec: 'Stráž pod Ralskem',
            kod: 562092,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47127,
            latitude: 50.702903,
            longitude: 14.801127,
        },
        {
            obec: 'Stružinec',
            kod: 577545,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51251,
            latitude: 50.550949,
            longitude: 15.358086,
        },
        {
            obec: 'Stružnice',
            kod: 562106,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47002,
            latitude: 50.696974,
            longitude: 14.450096,
        },
        {
            obec: 'Studenec',
            kod: 577553,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51233,
            latitude: 50.553543,
            longitude: 15.54947,
        },
        {
            obec: 'Stvolínky',
            kod: 562114,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47102,
            latitude: 50.632379,
            longitude: 14.428717,
        },
        {
            obec: 'Světlá pod Ještědem',
            kod: 564427,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46343,
            latitude: 50.711344,
            longitude: 14.986005,
        },
        {
            obec: 'Svijanský Újezd',
            kod: 564435,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46345,
            latitude: 50.587332,
            longitude: 15.043456,
        },
        {
            obec: 'Svijany',
            kod: 564443,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46346,
            latitude: 50.572684,
            longitude: 15.063976,
        },
        {
            obec: 'Svojek',
            kod: 577561,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 50791,
            latitude: 50.553516,
            longitude: 15.455663,
        },
        {
            obec: 'Svojkov',
            kod: 546283,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47153,
            latitude: 50.719575,
            longitude: 14.599127,
        },
        {
            obec: 'Svor',
            kod: 562131,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47151,
            latitude: 50.7919,
            longitude: 14.596917,
        },
        {
            obec: 'Sychrov',
            kod: 564451,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46344,
            latitude: 50.626916,
            longitude: 15.088809,
        },
        {
            obec: 'Syřenov',
            kod: 577570,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51251,
            latitude: 50.498928,
            longitude: 15.427821,
        },
        {
            obec: 'Šimonovice',
            kod: 564460,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46312,
            latitude: 50.706477,
            longitude: 15.052732,
        },
        {
            obec: 'Tachov',
            kod: 553638,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.543414,
            longitude: 14.638554,
        },
        {
            obec: 'Tanvald',
            kod: 563820,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46841,
            latitude: 50.737629,
            longitude: 15.307982,
        },
        {
            obec: 'Tatobity',
            kod: 577596,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51253,
            latitude: 50.571343,
            longitude: 15.273268,
        },
        {
            obec: 'Troskovice',
            kod: 577600,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51263,
            latitude: 50.511687,
            longitude: 15.22242,
        },
        {
            obec: 'Tuhaň',
            kod: 546267,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.536579,
            longitude: 14.467408,
        },
        {
            obec: 'Turnov',
            kod: 577626,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51101,
            latitude: 50.587385,
            longitude: 15.156909,
        },
        {
            obec: 'Velenice',
            kod: 562173,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47002,
            latitude: 50.719064,
            longitude: 14.664004,
        },
        {
            obec: 'Velké Hamry',
            kod: 563838,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46845,
            latitude: 50.713834,
            longitude: 15.315498,
        },
        {
            obec: 'Velký Valtinov',
            kod: 562203,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47125,
            latitude: 50.743566,
            longitude: 14.735433,
        },
        {
            obec: 'Veselá',
            kod: 577642,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51252,
            latitude: 50.546714,
            longitude: 15.308967,
        },
        {
            obec: 'Víchová nad Jizerou',
            kod: 577651,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51241,
            latitude: 50.630181,
            longitude: 15.487779,
        },
        {
            obec: 'Višňová',
            kod: 564494,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46401,
            latitude: 50.966732,
            longitude: 15.02506,
        },
        {
            obec: 'Vítkovice',
            kod: 577669,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51238,
            latitude: 50.679115,
            longitude: 15.531308,
        },
        {
            obec: 'Vlastiboř',
            kod: 563846,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.664974,
            longitude: 15.311826,
        },
        {
            obec: 'Vlastibořice',
            kod: 564516,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46344,
            latitude: 50.618816,
            longitude: 15.052112,
        },
        {
            obec: 'Volfartice',
            kod: 562220,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47112,
            latitude: 50.730204,
            longitude: 14.453036,
        },
        {
            obec: 'Vrchovany',
            kod: 562238,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.55324,
            longitude: 14.57122,
        },
        {
            obec: 'Všelibice',
            kod: 564532,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46348,
            latitude: 50.64615,
            longitude: 14.95149,
        },
        {
            obec: 'Všeň',
            kod: 577677,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51265,
            latitude: 50.557553,
            longitude: 15.105323,
        },
        {
            obec: 'Vyskeř',
            kod: 577685,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51264,
            latitude: 50.529406,
            longitude: 15.15805,
        },
        {
            obec: 'Vysoké nad Jizerou',
            kod: 577693,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51211,
            latitude: 50.685688,
            longitude: 15.401633,
        },
        {
            obec: 'Záhoří',
            kod: 577707,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51301,
            latitude: 50.61161,
            longitude: 15.274194,
        },
        {
            obec: 'Zahrádky',
            kod: 562246,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47101,
            latitude: 50.634208,
            longitude: 14.524708,
        },
        {
            obec: 'Zákupy',
            kod: 562262,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47123,
            latitude: 50.684854,
            longitude: 14.645335,
        },
        {
            obec: 'Zásada',
            kod: 563854,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46825,
            latitude: 50.697784,
            longitude: 15.27004,
        },
        {
            obec: 'Zdislava',
            kod: 564541,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46353,
            latitude: 50.765065,
            longitude: 14.876403,
        },
        {
            obec: 'Zlatá Olešnice',
            kod: 563862,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46847,
            latitude: 50.708647,
            longitude: 15.353616,
        },
        {
            obec: 'Žandov',
            kod: 562297,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47107,
            latitude: 50.714039,
            longitude: 14.396344,
        },
        {
            obec: 'Žďárek',
            kod: 544604,
            okres: 'Liberec',
            kod_okres: 'CZ0513',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46344,
            latitude: 50.640548,
            longitude: 15.111598,
        },
        {
            obec: 'Ždírec',
            kod: 514195,
            okres: 'Česká Lípa',
            kod_okres: 'CZ0511',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 47201,
            latitude: 50.515564,
            longitude: 14.624388,
        },
        {
            obec: 'Železný Brod',
            kod: 563871,
            okres: 'Jablonec nad Nisou',
            kod_okres: 'CZ0512',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 46822,
            latitude: 50.64284,
            longitude: 15.254186,
        },
        {
            obec: 'Žernov',
            kod: 577723,
            okres: 'Semily',
            kod_okres: 'CZ0514',
            kraj: 'Liberecký kraj',
            kod_kraj: 'CZ051',
            psc: 51263,
            latitude: 50.556845,
            longitude: 15.26975,
        },
    ],
    CZ071: [
        {
            obec: 'Alojzov',
            kod: 506761,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79804,
            latitude: 49.423541,
            longitude: 17.041589,
        },
        {
            obec: 'Babice',
            kod: 552356,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.737929,
            longitude: 17.260966,
        },
        {
            obec: 'Bedihošť',
            kod: 589268,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79821,
            latitude: 49.448359,
            longitude: 17.166534,
        },
        {
            obec: 'Bělá pod Pradědem',
            kod: 523917,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79001,
            latitude: 50.164036,
            longitude: 17.196677,
        },
        {
            obec: 'Bělkovice-Lašťany',
            kod: 500526,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78316,
            latitude: 49.668757,
            longitude: 17.317289,
        },
        {
            obec: 'Bělotín',
            kod: 512231,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75364,
            latitude: 49.5913,
            longitude: 17.806648,
        },
        {
            obec: 'Beňov',
            kod: 512281,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.41746,
            longitude: 17.501452,
        },
        {
            obec: 'Bernartice',
            kod: 524891,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79057,
            latitude: 50.389907,
            longitude: 17.078379,
        },
        {
            obec: 'Bezuchov',
            kod: 512401,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.462827,
            longitude: 17.608881,
        },
        {
            obec: 'Bílá Lhota',
            kod: 500623,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78321,
            latitude: 49.709629,
            longitude: 16.97518,
        },
        {
            obec: 'Bílá Voda',
            kod: 525227,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79069,
            latitude: 50.442133,
            longitude: 16.916515,
        },
        {
            obec: 'Bílovice-Lutotín',
            kod: 589276,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79841,
            latitude: 49.511097,
            longitude: 17.043319,
        },
        {
            obec: 'Bílsko',
            kod: 552062,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.644661,
            longitude: 17.019776,
        },
        {
            obec: 'Biskupice',
            kod: 589284,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79812,
            latitude: 49.459774,
            longitude: 17.236699,
        },
        {
            obec: 'Blatec',
            kod: 500801,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78375,
            latitude: 49.528444,
            longitude: 17.238673,
        },
        {
            obec: 'Bludov',
            kod: 525588,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78961,
            latitude: 49.941116,
            longitude: 16.928111,
        },
        {
            obec: 'Bohdíkov',
            kod: 525804,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78964,
            latitude: 50.010058,
            longitude: 16.904438,
        },
        {
            obec: 'Bohuňovice',
            kod: 500852,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78314,
            latitude: 49.663112,
            longitude: 17.287037,
        },
        {
            obec: 'Bohuslavice',
            kod: 589292,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79856,
            latitude: 49.620952,
            longitude: 16.957654,
        },
        {
            obec: 'Bohuslavice',
            kod: 525880,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78972,
            latitude: 49.826153,
            longitude: 16.940636,
        },
        {
            obec: 'Bohuslávky',
            kod: 569178,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.555256,
            longitude: 17.567886,
        },
        {
            obec: 'Bohutín',
            kod: 525979,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78962,
            latitude: 49.953611,
            longitude: 16.882039,
        },
        {
            obec: 'Bochoř',
            kod: 512532,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.421475,
            longitude: 17.428459,
        },
        {
            obec: 'Bousín',
            kod: 589306,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79861,
            latitude: 49.455207,
            longitude: 16.889847,
        },
        {
            obec: 'Bouzov',
            kod: 500861,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78325,
            latitude: 49.704361,
            longitude: 16.892988,
        },
        {
            obec: 'Branná',
            kod: 526169,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78825,
            latitude: 50.15336,
            longitude: 17.011954,
        },
        {
            obec: 'Bratrušov',
            kod: 569437,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78701,
            latitude: 50.009487,
            longitude: 16.949028,
        },
        {
            obec: 'Brníčko',
            kod: 530727,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78975,
            latitude: 49.894982,
            longitude: 16.968308,
        },
        {
            obec: 'Brodek u Konice',
            kod: 589314,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79846,
            latitude: 49.548791,
            longitude: 16.83297,
        },
        {
            obec: 'Brodek u Prostějova',
            kod: 589322,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79807,
            latitude: 49.370064,
            longitude: 17.090164,
        },
        {
            obec: 'Brodek u Přerova',
            kod: 512800,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.484289,
            longitude: 17.338354,
        },
        {
            obec: 'Březsko',
            kod: 589331,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.608693,
            longitude: 16.892539,
        },
        {
            obec: 'Budětsko',
            kod: 589349,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.587129,
            longitude: 16.92698,
        },
        {
            obec: 'Buk',
            kod: 512826,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75121,
            latitude: 49.504545,
            longitude: 17.468822,
        },
        {
            obec: 'Buková',
            kod: 589357,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79848,
            latitude: 49.509838,
            longitude: 16.829233,
        },
        {
            obec: 'Bukovany',
            kod: 552402,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.60536,
            longitude: 17.343594,
        },
        {
            obec: 'Bušín',
            kod: 532894,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78962,
            latitude: 49.976227,
            longitude: 16.837978,
        },
        {
            obec: 'Bystročice',
            kod: 500879,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.536347,
            longitude: 17.193626,
        },
        {
            obec: 'Bystrovany',
            kod: 547026,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.597116,
            longitude: 17.323754,
        },
        {
            obec: 'Býškovice',
            kod: 512877,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.467566,
            longitude: 17.714164,
        },
        {
            obec: 'Císařov',
            kod: 569135,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.466135,
            longitude: 17.352711,
        },
        {
            obec: 'Citov',
            kod: 512982,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.467819,
            longitude: 17.325561,
        },
        {
            obec: 'Čehovice',
            kod: 589365,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79821,
            latitude: 49.43255,
            longitude: 17.175182,
        },
        {
            obec: 'Čechy',
            kod: 552771,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75115,
            latitude: 49.429155,
            longitude: 17.534434,
        },
        {
            obec: 'Čechy pod Kosířem',
            kod: 589381,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79858,
            latitude: 49.551061,
            longitude: 17.037775,
        },
        {
            obec: 'Čelčice',
            kod: 589390,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79823,
            latitude: 49.412429,
            longitude: 17.19374,
        },
        {
            obec: 'Čelechovice',
            kod: 513059,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.510136,
            longitude: 17.372246,
        },
        {
            obec: 'Čelechovice na Hané',
            kod: 589403,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79816,
            latitude: 49.516364,
            longitude: 17.093902,
        },
        {
            obec: 'Černá Voda',
            kod: 533491,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79054,
            latitude: 50.308312,
            longitude: 17.1471,
        },
        {
            obec: 'Černotín',
            kod: 513067,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75368,
            latitude: 49.531975,
            longitude: 17.772231,
        },
        {
            obec: 'Červenka',
            kod: 552186,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78401,
            latitude: 49.718889,
            longitude: 17.083808,
        },
        {
            obec: 'Česká Ves',
            kod: 569356,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79081,
            latitude: 50.257454,
            longitude: 17.228153,
        },
        {
            obec: 'Daskabát',
            kod: 552445,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.578642,
            longitude: 17.447349,
        },
        {
            obec: 'Dětkovice',
            kod: 589420,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79804,
            latitude: 49.415458,
            longitude: 17.082286,
        },
        {
            obec: 'Dlouhá Loučka',
            kod: 501476,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78386,
            latitude: 49.816357,
            longitude: 17.179781,
        },
        {
            obec: 'Dlouhomilov',
            kod: 533688,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.906997,
            longitude: 16.991411,
        },
        {
            obec: 'Dobrčice',
            kod: 513105,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.402129,
            longitude: 17.480594,
        },
        {
            obec: 'Dobrochov',
            kod: 589438,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79807,
            latitude: 49.385961,
            longitude: 17.105673,
        },
        {
            obec: 'Dobromilice',
            kod: 589446,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79825,
            latitude: 49.35854,
            longitude: 17.141647,
        },
        {
            obec: 'Dolany',
            kod: 501646,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78316,
            latitude: 49.649973,
            longitude: 17.322508,
        },
        {
            obec: 'Dolní Nětčice',
            kod: 569259,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.476274,
            longitude: 17.675121,
        },
        {
            obec: 'Dolní Studénky',
            kod: 553379,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78820,
            latitude: 49.93516,
            longitude: 16.971178,
        },
        {
            obec: 'Dolní Těšice',
            kod: 552984,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.495466,
            longitude: 17.806908,
        },
        {
            obec: 'Dolní Újezd',
            kod: 513199,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75123,
            latitude: 49.546068,
            longitude: 17.535582,
        },
        {
            obec: 'Doloplazy',
            kod: 568392,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78356,
            latitude: 49.567276,
            longitude: 17.411581,
        },
        {
            obec: 'Doloplazy',
            kod: 589454,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79826,
            latitude: 49.350312,
            longitude: 17.160101,
        },
        {
            obec: 'Domašov nad Bystřicí',
            kod: 545279,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78306,
            latitude: 49.742397,
            longitude: 17.445404,
        },
        {
            obec: 'Domašov u Šternberka',
            kod: 552313,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.705886,
            longitude: 17.338,
        },
        {
            obec: 'Domaželice',
            kod: 513211,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75115,
            latitude: 49.427781,
            longitude: 17.545867,
        },
        {
            obec: 'Drahanovice',
            kod: 501751,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78344,
            latitude: 49.578656,
            longitude: 17.077119,
        },
        {
            obec: 'Drahany',
            kod: 589462,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79861,
            latitude: 49.433359,
            longitude: 16.897488,
        },
        {
            obec: 'Drozdov',
            kod: 570141,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.907835,
            longitude: 16.78745,
        },
        {
            obec: 'Držovice',
            kod: 558419,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79607,
            latitude: 49.491208,
            longitude: 17.134108,
        },
        {
            obec: 'Dřevnovice',
            kod: 589489,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79826,
            latitude: 49.328187,
            longitude: 17.143364,
        },
        {
            obec: 'Dřevohostice',
            kod: 513229,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.426043,
            longitude: 17.592153,
        },
        {
            obec: 'Dub nad Moravou',
            kod: 501794,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78375,
            latitude: 49.482585,
            longitude: 17.27734,
        },
        {
            obec: 'Dubčany',
            kod: 552071,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.649318,
            longitude: 17.078926,
        },
        {
            obec: 'Dubicko',
            kod: 534927,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78972,
            latitude: 49.828159,
            longitude: 16.962769,
        },
        {
            obec: 'Dzbel',
            kod: 589497,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79853,
            latitude: 49.612993,
            longitude: 16.850985,
        },
        {
            obec: 'Grygov',
            kod: 501841,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78373,
            latitude: 49.537953,
            longitude: 17.310944,
        },
        {
            obec: 'Grymov',
            kod: 569194,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75121,
            latitude: 49.481108,
            longitude: 17.498114,
        },
        {
            obec: 'Hačky',
            kod: 549967,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79855,
            latitude: 49.622521,
            longitude: 16.937715,
        },
        {
            obec: 'Haňovice',
            kod: 552178,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78321,
            latitude: 49.681155,
            longitude: 17.045152,
        },
        {
            obec: 'Hanušovice',
            kod: 535532,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78833,
            latitude: 50.080583,
            longitude: 16.936514,
        },
        {
            obec: 'Hlásnice',
            kod: 552330,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.753458,
            longitude: 17.294675,
        },
        {
            obec: 'Hlinsko',
            kod: 569267,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.49464,
            longitude: 17.579251,
        },
        {
            obec: 'Hlubočky',
            kod: 502146,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78361,
            latitude: 49.627673,
            longitude: 17.402638,
        },
        {
            obec: 'Hluchov',
            kod: 589501,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79841,
            latitude: 49.541496,
            longitude: 16.996747,
        },
        {
            obec: 'Hlušovice',
            kod: 552020,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78314,
            latitude: 49.638193,
            longitude: 17.277495,
        },
        {
            obec: 'Hněvotín',
            kod: 502235,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78347,
            latitude: 49.572151,
            longitude: 17.179642,
        },
        {
            obec: 'Hnojice',
            kod: 502405,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.716409,
            longitude: 17.224227,
        },
        {
            obec: 'Horka nad Moravou',
            kod: 502545,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78335,
            latitude: 49.640186,
            longitude: 17.210806,
        },
        {
            obec: 'Horní Loděnice',
            kod: 569798,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78305,
            latitude: 49.771108,
            longitude: 17.37728,
        },
        {
            obec: 'Horní Moštěnice',
            kod: 513491,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75117,
            latitude: 49.412225,
            longitude: 17.4589,
        },
        {
            obec: 'Horní Nětčice',
            kod: 569275,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.470245,
            longitude: 17.684498,
        },
        {
            obec: 'Horní Studénky',
            kod: 535770,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.952757,
            longitude: 16.811723,
        },
        {
            obec: 'Horní Štěpánov',
            kod: 589519,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79847,
            latitude: 49.549106,
            longitude: 16.790882,
        },
        {
            obec: 'Horní Těšice',
            kod: 552968,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.495759,
            longitude: 17.786086,
        },
        {
            obec: 'Horní Újezd',
            kod: 569542,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.448387,
            longitude: 17.727285,
        },
        {
            obec: 'Hoštejn',
            kod: 535885,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.874482,
            longitude: 16.774872,
        },
        {
            obec: 'Hraběšice',
            kod: 553387,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78815,
            latitude: 49.97673,
            longitude: 17.079623,
        },
        {
            obec: 'Hrabišín',
            kod: 536091,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78804,
            latitude: 49.91415,
            longitude: 17.036459,
        },
        {
            obec: 'Hrabová',
            kod: 553221,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.845548,
            longitude: 16.953951,
        },
        {
            obec: 'Hrabůvka',
            kod: 513636,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.578604,
            longitude: 17.691361,
        },
        {
            obec: 'Hradčany',
            kod: 513733,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.452714,
            longitude: 17.571737,
        },
        {
            obec: 'Hradčany-Kobeřice',
            kod: 589527,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79807,
            latitude: 49.364185,
            longitude: 17.128429,
        },
        {
            obec: 'Hradec-Nová Ves',
            kod: 553301,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79084,
            latitude: 50.283247,
            longitude: 17.281327,
        },
        {
            obec: 'Hranice',
            kod: 513750,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.549764,
            longitude: 17.735022,
        },
        {
            obec: 'Hraničné Petrovice',
            kod: 546976,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78306,
            latitude: 49.740948,
            longitude: 17.405043,
        },
        {
            obec: 'Hrdibořice',
            kod: 589535,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79812,
            latitude: 49.478575,
            longitude: 17.220521,
        },
        {
            obec: 'Hrubčice',
            kod: 589543,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79821,
            latitude: 49.450115,
            longitude: 17.193197,
        },
        {
            obec: 'Hruška',
            kod: 543543,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79827,
            latitude: 49.358882,
            longitude: 17.22677,
        },
        {
            obec: 'Hustopeče nad Bečvou',
            kod: 513768,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75366,
            latitude: 49.530631,
            longitude: 17.870046,
        },
        {
            obec: 'Huzová',
            kod: 597414,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79351,
            latitude: 49.820063,
            longitude: 17.298064,
        },
        {
            obec: 'Hvozd',
            kod: 589560,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79855,
            latitude: 49.636303,
            longitude: 16.910181,
        },
        {
            obec: 'Hynčina',
            kod: 536113,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.848884,
            longitude: 16.793108,
        },
        {
            obec: 'Charváty',
            kod: 568872,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78375,
            latitude: 49.518462,
            longitude: 17.254067,
        },
        {
            obec: 'Cholina',
            kod: 502839,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.656198,
            longitude: 17.054513,
        },
        {
            obec: 'Chromeč',
            kod: 569305,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.933846,
            longitude: 16.893779,
        },
        {
            obec: 'Ivaň',
            kod: 589578,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79823,
            latitude: 49.424694,
            longitude: 17.251454,
        },
        {
            obec: 'Jakubovice',
            kod: 553344,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78991,
            latitude: 49.994405,
            longitude: 16.828875,
        },
        {
            obec: 'Janoušov',
            kod: 553212,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78991,
            latitude: 50.01321,
            longitude: 16.845209,
        },
        {
            obec: 'Javorník',
            kod: 536148,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79070,
            latitude: 50.390868,
            longitude: 17.002821,
        },
        {
            obec: 'Jedlí',
            kod: 536288,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.931312,
            longitude: 16.794838,
        },
        {
            obec: 'Jesenec',
            kod: 589586,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79853,
            latitude: 49.608512,
            longitude: 16.862184,
        },
        {
            obec: 'Jeseník',
            kod: 536385,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79001,
            latitude: 50.224725,
            longitude: 17.198147,
        },
        {
            obec: 'Jestřebí',
            kod: 536393,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.852199,
            longitude: 16.868736,
        },
        {
            obec: 'Jezernice',
            kod: 556998,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.547873,
            longitude: 17.625008,
        },
        {
            obec: 'Jindřichov',
            kod: 513873,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.644693,
            longitude: 17.748345,
        },
        {
            obec: 'Jindřichov',
            kod: 536521,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78823,
            latitude: 50.095891,
            longitude: 16.985006,
        },
        {
            obec: 'Jívová',
            kod: 503142,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78316,
            latitude: 49.70927,
            longitude: 17.394675,
        },
        {
            obec: 'Kamenná',
            kod: 536571,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78974,
            latitude: 49.859436,
            longitude: 17.031142,
        },
        {
            obec: 'Kladky',
            kod: 589594,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79854,
            latitude: 49.650182,
            longitude: 16.841358,
        },
        {
            obec: 'Kladníky',
            kod: 569283,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.483773,
            longitude: 17.603034,
        },
        {
            obec: 'Klenovice na Hané',
            kod: 589608,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79823,
            latitude: 49.403017,
            longitude: 17.211237,
        },
        {
            obec: 'Klokočí',
            kod: 514047,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75361,
            latitude: 49.560292,
            longitude: 17.682377,
        },
        {
            obec: 'Klopina',
            kod: 536687,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78973,
            latitude: 49.818661,
            longitude: 17.019494,
        },
        {
            obec: 'Klopotovice',
            kod: 589616,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79821,
            latitude: 49.446217,
            longitude: 17.250871,
        },
        {
            obec: 'Kobylá nad Vidnavkou',
            kod: 557218,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79065,
            latitude: 50.342045,
            longitude: 17.123713,
        },
        {
            obec: 'Kojetín',
            kod: 514055,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75201,
            latitude: 49.352911,
            longitude: 17.30366,
        },
        {
            obec: 'Kokory',
            kod: 514152,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75105,
            latitude: 49.494915,
            longitude: 17.375546,
        },
        {
            obec: 'Kolšov',
            kod: 536733,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78821,
            latitude: 49.903176,
            longitude: 16.947532,
        },
        {
            obec: 'Komárov',
            kod: 547123,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.764167,
            longitude: 17.241052,
        },
        {
            obec: 'Konice',
            kod: 589624,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.590395,
            longitude: 16.889217,
        },
        {
            obec: 'Kopřivná',
            kod: 553247,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78833,
            latitude: 50.046475,
            longitude: 16.946863,
        },
        {
            obec: 'Kosov',
            kod: 536814,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.883233,
            longitude: 16.79882,
        },
        {
            obec: 'Kostelec na Hané',
            kod: 589632,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79841,
            latitude: 49.514078,
            longitude: 17.058346,
        },
        {
            obec: 'Koválovice-Osíčany',
            kod: 589641,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79829,
            latitude: 49.284523,
            longitude: 17.171314,
        },
        {
            obec: 'Kozlov',
            kod: 500135,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77200,
            latitude: 49.603626,
            longitude: 17.535662,
        },
        {
            obec: 'Kožušany-Tážaly',
            kod: 503304,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78375,
            latitude: 49.537792,
            longitude: 17.253626,
        },
        {
            obec: 'Kralice na Hané',
            kod: 589659,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79812,
            latitude: 49.463062,
            longitude: 17.180583,
        },
        {
            obec: 'Krčmaň',
            kod: 552437,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.520843,
            longitude: 17.344253,
        },
        {
            obec: 'Krchleby',
            kod: 569372,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.820815,
            longitude: 16.833722,
        },
        {
            obec: 'Krumsín',
            kod: 589667,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.446019,
            longitude: 17.001558,
        },
        {
            obec: 'Křelov-Břuchotín',
            kod: 554901,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78336,
            latitude: 49.614533,
            longitude: 17.195665,
        },
        {
            obec: 'Křenovice',
            kod: 569143,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75201,
            latitude: 49.330514,
            longitude: 17.272737,
        },
        {
            obec: 'Křtomil',
            kod: 552780,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.409188,
            longitude: 17.626333,
        },
        {
            obec: 'Laškov',
            kod: 589675,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79857,
            latitude: 49.584113,
            longitude: 17.002235,
        },
        {
            obec: 'Lazníčky',
            kod: 514446,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75125,
            latitude: 49.545565,
            longitude: 17.460189,
        },
        {
            obec: 'Lazníky',
            kod: 514471,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75125,
            latitude: 49.524536,
            longitude: 17.462787,
        },
        {
            obec: 'Lesnice',
            kod: 537284,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.88386,
            longitude: 16.941325,
        },
        {
            obec: 'Lešany',
            kod: 589683,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79842,
            latitude: 49.499779,
            longitude: 17.024171,
        },
        {
            obec: 'Leština',
            kod: 537713,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78971,
            latitude: 49.868883,
            longitude: 16.927588,
        },
        {
            obec: 'Lhota',
            kod: 514497,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.497745,
            longitude: 17.614881,
        },
        {
            obec: 'Lhotka',
            kod: 514527,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75124,
            latitude: 49.499836,
            longitude: 17.410166,
        },
        {
            obec: 'Libavá (vojenský újezd)',
            kod: 503941,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.723088,
            longitude: 17.523528,
        },
        {
            obec: 'Libina',
            kod: 539961,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78805,
            latitude: 49.876529,
            longitude: 17.08282,
        },
        {
            obec: 'Liboš',
            kod: 569003,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78313,
            latitude: 49.691682,
            longitude: 17.225136,
        },
        {
            obec: 'Lipina',
            kod: 552305,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.741758,
            longitude: 17.323633,
        },
        {
            obec: 'Lipinka',
            kod: 540005,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78383,
            latitude: 49.837875,
            longitude: 17.050055,
        },
        {
            obec: 'Lipník nad Bečvou',
            kod: 514705,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.526832,
            longitude: 17.586743,
        },
        {
            obec: 'Lipová',
            kod: 589691,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79845,
            latitude: 49.526065,
            longitude: 16.861891,
        },
        {
            obec: 'Lipová',
            kod: 514772,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.409927,
            longitude: 17.614169,
        },
        {
            obec: 'Lipová-lázně',
            kod: 540030,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79061,
            latitude: 50.227866,
            longitude: 17.14055,
        },
        {
            obec: 'Líšná',
            kod: 514802,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75115,
            latitude: 49.411684,
            longitude: 17.547451,
        },
        {
            obec: 'Líšnice',
            kod: 540161,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78985,
            latitude: 49.75594,
            longitude: 16.876089,
        },
        {
            obec: 'Litovel',
            kod: 503444,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78401,
            latitude: 49.701309,
            longitude: 17.076255,
        },
        {
            obec: 'Lobodice',
            kod: 515191,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75101,
            latitude: 49.395559,
            longitude: 17.292031,
        },
        {
            obec: 'Loštice',
            kod: 540196,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78983,
            latitude: 49.744796,
            longitude: 16.929028,
        },
        {
            obec: 'Loučany',
            kod: 552232,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78344,
            latitude: 49.601996,
            longitude: 17.082765,
        },
        {
            obec: 'Loučka',
            kod: 552038,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.656944,
            longitude: 17.011221,
        },
        {
            obec: 'Loučná nad Desnou',
            kod: 540226,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78811,
            latitude: 50.07166,
            longitude: 17.091064,
        },
        {
            obec: 'Luběnice',
            kod: 552216,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78346,
            latitude: 49.576275,
            longitude: 17.120578,
        },
        {
            obec: 'Luboměř pod Strážnou',
            kod: 500151,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75362,
            latitude: 49.689117,
            longitude: 17.663752,
        },
        {
            obec: 'Ludmírov',
            kod: 589705,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79855,
            latitude: 49.641418,
            longitude: 16.87332,
        },
        {
            obec: 'Luká',
            kod: 503622,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78324,
            latitude: 49.649243,
            longitude: 16.947383,
        },
        {
            obec: 'Lukavice',
            kod: 540234,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.823003,
            longitude: 16.920503,
        },
        {
            obec: 'Lutín',
            kod: 503657,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78349,
            latitude: 49.558552,
            longitude: 17.135827,
        },
        {
            obec: 'Lužice',
            kod: 569844,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.715844,
            longitude: 17.259668,
        },
        {
            obec: 'Majetín',
            kod: 503738,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.498174,
            longitude: 17.333246,
        },
        {
            obec: 'Malá Morava',
            kod: 540331,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78833,
            latitude: 50.096366,
            longitude: 16.826768,
        },
        {
            obec: 'Malé Hradisko',
            kod: 589713,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79849,
            latitude: 49.493414,
            longitude: 16.87669,
        },
        {
            obec: 'Maletín',
            kod: 540366,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.799359,
            longitude: 16.78743,
        },
        {
            obec: 'Malhotice',
            kod: 515329,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.490108,
            longitude: 17.764264,
        },
        {
            obec: 'Medlov',
            kod: 552372,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78391,
            latitude: 49.787639,
            longitude: 17.062716,
        },
        {
            obec: 'Měrotín',
            kod: 568911,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78324,
            latitude: 49.692571,
            longitude: 17.001818,
        },
        {
            obec: 'Měrovice nad Hanou',
            kod: 552909,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75201,
            latitude: 49.34167,
            longitude: 17.245955,
        },
        {
            obec: 'Město Libavá',
            kod: 500160,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78307,
            latitude: 49.721619,
            longitude: 17.520135,
        },
        {
            obec: 'Mikulovice',
            kod: 540382,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79084,
            latitude: 50.298634,
            longitude: 17.321655,
        },
        {
            obec: 'Milenov',
            kod: 515418,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75361,
            latitude: 49.562684,
            longitude: 17.66712,
        },
        {
            obec: 'Milotice nad Bečvou',
            kod: 515477,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75367,
            latitude: 49.536608,
            longitude: 17.839864,
        },
        {
            obec: 'Mírov',
            kod: 569381,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.798197,
            longitude: 16.84759,
        },
        {
            obec: 'Mladeč',
            kod: 504246,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78321,
            latitude: 49.705924,
            longitude: 17.01963,
        },
        {
            obec: 'Mladějovice',
            kod: 552348,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.752475,
            longitude: 17.23154,
        },
        {
            obec: 'Mohelnice',
            kod: 540471,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78985,
            latitude: 49.777082,
            longitude: 16.919567,
        },
        {
            obec: 'Moravičany',
            kod: 540480,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78982,
            latitude: 49.757034,
            longitude: 16.960527,
        },
        {
            obec: 'Moravský Beroun',
            kod: 597678,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79305,
            latitude: 49.79384,
            longitude: 17.442222,
        },
        {
            obec: 'Mořice',
            kod: 589721,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79828,
            latitude: 49.330574,
            longitude: 17.196995,
        },
        {
            obec: 'Mostkovice',
            kod: 589730,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79802,
            latitude: 49.472246,
            longitude: 17.052223,
        },
        {
            obec: 'Mrsklesy',
            kod: 554944,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78365,
            latitude: 49.602712,
            longitude: 17.401657,
        },
        {
            obec: 'Mutkov',
            kod: 547093,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79351,
            latitude: 49.801996,
            longitude: 17.284841,
        },
        {
            obec: 'Myslejovice',
            kod: 589748,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79805,
            latitude: 49.406547,
            longitude: 17.027659,
        },
        {
            obec: 'Nahošovice',
            kod: 552810,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.437824,
            longitude: 17.57822,
        },
        {
            obec: 'Náklo',
            kod: 504441,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78332,
            latitude: 49.654721,
            longitude: 17.129794,
        },
        {
            obec: 'Náměšť na Hané',
            kod: 504505,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78344,
            latitude: 49.602226,
            longitude: 17.065499,
        },
        {
            obec: 'Nelešovice',
            kod: 515787,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75103,
            latitude: 49.510794,
            longitude: 17.388256,
        },
        {
            obec: 'Němčice nad Hanou',
            kod: 589756,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79827,
            latitude: 49.341914,
            longitude: 17.206072,
        },
        {
            obec: 'Nemile',
            kod: 553476,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.874866,
            longitude: 16.847553,
        },
        {
            obec: 'Nezamyslice',
            kod: 589764,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79826,
            latitude: 49.325526,
            longitude: 17.173366,
        },
        {
            obec: 'Niva',
            kod: 589772,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79861,
            latitude: 49.445315,
            longitude: 16.851911,
        },
        {
            obec: 'Norberčany',
            kod: 597686,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79305,
            latitude: 49.754048,
            longitude: 17.506344,
        },
        {
            obec: 'Nová Hradečná',
            kod: 552381,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78383,
            latitude: 49.834478,
            longitude: 17.077461,
        },
        {
            obec: 'Nový Malín',
            kod: 540501,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78803,
            latitude: 49.942722,
            longitude: 17.03201,
        },
        {
            obec: 'Obědkovice',
            kod: 589799,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79823,
            latitude: 49.394852,
            longitude: 17.227643,
        },
        {
            obec: 'Ohrozim',
            kod: 589802,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.48656,
            longitude: 17.02073,
        },
        {
            obec: 'Ochoz',
            kod: 589811,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.600231,
            longitude: 16.915109,
        },
        {
            obec: 'Olbramice',
            kod: 552259,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.613591,
            longitude: 17.001576,
        },
        {
            obec: 'Oldřichov',
            kod: 515825,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.49756,
            longitude: 17.539328,
        },
        {
            obec: 'Olomouc',
            kod: 500496,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.593878,
            longitude: 17.250979,
        },
        {
            obec: 'Olšany',
            kod: 540510,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78962,
            latitude: 49.965186,
            longitude: 16.859046,
        },
        {
            obec: 'Olšany u Prostějova',
            kod: 589829,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79814,
            latitude: 49.53655,
            longitude: 17.16467,
        },
        {
            obec: 'Olšovec',
            kod: 552844,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.590212,
            longitude: 17.717277,
        },
        {
            obec: 'Ondratice',
            kod: 589837,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79807,
            latitude: 49.363049,
            longitude: 17.063785,
        },
        {
            obec: 'Opatovice',
            kod: 516201,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75356,
            latitude: 49.49929,
            longitude: 17.739289,
        },
        {
            obec: 'Oplocany',
            kod: 553000,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75101,
            latitude: 49.409663,
            longitude: 17.260311,
        },
        {
            obec: 'Oprostovice',
            kod: 516350,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.469951,
            longitude: 17.620402,
        },
        {
            obec: 'Osek nad Bečvou',
            kod: 516619,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75122,
            latitude: 49.511313,
            longitude: 17.528401,
        },
        {
            obec: 'Oskava',
            kod: 540544,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78801,
            latitude: 49.895329,
            longitude: 17.132249,
        },
        {
            obec: 'Ostružná',
            kod: 569330,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78825,
            latitude: 50.185253,
            longitude: 17.051691,
        },
        {
            obec: 'Otaslavice',
            kod: 589845,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79806,
            latitude: 49.388426,
            longitude: 17.071186,
        },
        {
            obec: 'Otinoves',
            kod: 589853,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79861,
            latitude: 49.421275,
            longitude: 16.871783,
        },
        {
            obec: 'Palonín',
            kod: 540595,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78983,
            latitude: 49.729051,
            longitude: 16.952733,
        },
        {
            obec: 'Paršovice',
            kod: 516635,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75355,
            latitude: 49.50123,
            longitude: 17.708077,
        },
        {
            obec: 'Partutovice',
            kod: 516686,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.631419,
            longitude: 17.708201,
        },
        {
            obec: 'Paseka',
            kod: 504785,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78397,
            latitude: 49.796542,
            longitude: 17.222863,
        },
        {
            obec: 'Pavlov',
            kod: 540609,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78985,
            latitude: 49.74289,
            longitude: 16.879352,
        },
        {
            obec: 'Pavlovice u Kojetína',
            kod: 557196,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79830,
            latitude: 49.302054,
            longitude: 17.212617,
        },
        {
            obec: 'Pavlovice u Přerova',
            kod: 516694,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.46952,
            longitude: 17.547853,
        },
        {
            obec: 'Pěnčín',
            kod: 589870,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79857,
            latitude: 49.567294,
            longitude: 17.013234,
        },
        {
            obec: 'Petrov nad Desnou',
            kod: 500020,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78816,
            latitude: 50.005536,
            longitude: 17.04438,
        },
        {
            obec: 'Písařov',
            kod: 540650,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78991,
            latitude: 50.005065,
            longitude: 16.801608,
        },
        {
            obec: 'Písečná',
            kod: 540684,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79082,
            latitude: 50.273123,
            longitude: 17.253835,
        },
        {
            obec: 'Pivín',
            kod: 589888,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79824,
            latitude: 49.384785,
            longitude: 17.184933,
        },
        {
            obec: 'Plumlov',
            kod: 589896,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.466236,
            longitude: 17.015122,
        },
        {
            obec: 'Pňovice',
            kod: 552160,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78401,
            latitude: 49.721386,
            longitude: 17.161923,
        },
        {
            obec: 'Podolí',
            kod: 516864,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75116,
            latitude: 49.445465,
            longitude: 17.530335,
        },
        {
            obec: 'Police',
            kod: 540731,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78973,
            latitude: 49.814846,
            longitude: 16.996381,
        },
        {
            obec: 'Polkovice',
            kod: 516899,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75144,
            latitude: 49.39183,
            longitude: 17.259275,
        },
        {
            obec: 'Polom',
            kod: 516911,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75364,
            latitude: 49.577179,
            longitude: 17.841791,
        },
        {
            obec: 'Polomí',
            kod: 549983,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79855,
            latitude: 49.631541,
            longitude: 16.949496,
        },
        {
            obec: 'Postřelmov',
            kod: 540773,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78969,
            latitude: 49.907661,
            longitude: 16.912368,
        },
        {
            obec: 'Postřelmůvek',
            kod: 553352,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.922722,
            longitude: 16.879208,
        },
        {
            obec: 'Potštát',
            kod: 517101,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75362,
            latitude: 49.637025,
            longitude: 17.651843,
        },
        {
            obec: 'Prosenice',
            kod: 517151,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75121,
            latitude: 49.488904,
            longitude: 17.484287,
        },
        {
            obec: 'PROSTĚJOV',
            kod: 589250,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79601,
            latitude: 49.472549,
            longitude: 17.106851,
        },
        {
            obec: 'Prostějovičky',
            kod: 589918,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.427632,
            longitude: 16.997349,
        },
        {
            obec: 'Protivanov',
            kod: 589926,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79848,
            latitude: 49.483614,
            longitude: 16.836047,
        },
        {
            obec: 'Provodovice',
            kod: 517208,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.451055,
            longitude: 17.768349,
        },
        {
            obec: 'Přáslavice',
            kod: 552411,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78354,
            latitude: 49.587462,
            longitude: 17.390548,
        },
        {
            obec: 'Přemyslovice',
            kod: 589934,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79851,
            latitude: 49.556472,
            longitude: 16.955914,
        },
        {
            obec: 'PŘEROV',
            kod: 511382,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.456579,
            longitude: 17.45033,
        },
        {
            obec: 'Přestavlky',
            kod: 517224,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.390351,
            longitude: 17.481629,
        },
        {
            obec: 'Příkazy',
            kod: 505013,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78333,
            latitude: 49.642471,
            longitude: 17.148305,
        },
        {
            obec: 'Ptení',
            kod: 589942,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79843,
            latitude: 49.511729,
            longitude: 16.961205,
        },
        {
            obec: 'Radíkov',
            kod: 517275,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.596189,
            longitude: 17.672293,
        },
        {
            obec: 'Radkova Lhota',
            kod: 517321,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.44185,
            longitude: 17.620656,
        },
        {
            obec: 'Radkovy',
            kod: 517437,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.436222,
            longitude: 17.614936,
        },
        {
            obec: 'Radotín',
            kod: 517445,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.47973,
            longitude: 17.63283,
        },
        {
            obec: 'Radslavice',
            kod: 517534,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.478269,
            longitude: 17.516667,
        },
        {
            obec: 'Radvanice',
            kod: 517569,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75121,
            latitude: 49.509905,
            longitude: 17.477349,
        },
        {
            obec: 'Rájec',
            kod: 540854,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.856577,
            longitude: 16.903068,
        },
        {
            obec: 'Rakov',
            kod: 517585,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.491801,
            longitude: 17.707876,
        },
        {
            obec: 'Raková u Konice',
            kod: 589951,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79857,
            latitude: 49.607347,
            longitude: 16.950931,
        },
        {
            obec: 'Rakůvka',
            kod: 589969,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79857,
            latitude: 49.610112,
            longitude: 16.938064,
        },
        {
            obec: 'Rapotín',
            kod: 540862,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78814,
            latitude: 50.000846,
            longitude: 17.019059,
        },
        {
            obec: 'Rejchartice',
            kod: 553395,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78701,
            latitude: 50.028348,
            longitude: 16.97802,
        },
        {
            obec: 'Rohle',
            kod: 540871,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78974,
            latitude: 49.860823,
            longitude: 17.013786,
        },
        {
            obec: 'Rokytnice',
            kod: 517607,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75104,
            latitude: 49.466023,
            longitude: 17.391308,
        },
        {
            obec: 'Rouské',
            kod: 517615,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.471832,
            longitude: 17.779252,
        },
        {
            obec: 'Rovensko',
            kod: 540919,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.907785,
            longitude: 16.876347,
        },
        {
            obec: 'Rozstání',
            kod: 589977,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79862,
            latitude: 49.397795,
            longitude: 16.840456,
        },
        {
            obec: 'Ruda nad Moravou',
            kod: 540978,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78963,
            latitude: 49.981095,
            longitude: 16.877925,
        },
        {
            obec: 'Řídeč',
            kod: 554103,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.765829,
            longitude: 17.25688,
        },
        {
            obec: 'Říkovice',
            kod: 517666,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75118,
            latitude: 49.381646,
            longitude: 17.451338,
        },
        {
            obec: 'Samotišky',
            kod: 547077,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 77900,
            latitude: 49.630522,
            longitude: 17.328172,
        },
        {
            obec: 'Seloutky',
            kod: 506770,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79804,
            latitude: 49.441499,
            longitude: 17.063403,
        },
        {
            obec: 'Senice na Hané',
            kod: 505081,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78345,
            latitude: 49.62411,
            longitude: 17.085882,
        },
        {
            obec: 'Senička',
            kod: 552267,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78345,
            latitude: 49.632317,
            longitude: 17.05639,
        },
        {
            obec: 'Skalička',
            kod: 517747,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75352,
            latitude: 49.515497,
            longitude: 17.795975,
        },
        {
            obec: 'Skalka',
            kod: 589993,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79824,
            latitude: 49.403186,
            longitude: 17.169915,
        },
        {
            obec: 'Skorošice',
            kod: 553484,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79065,
            latitude: 50.308643,
            longitude: 17.080738,
        },
        {
            obec: 'Skrbeň',
            kod: 552151,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78335,
            latitude: 49.641248,
            longitude: 17.176605,
        },
        {
            obec: 'Skřípov',
            kod: 590002,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.583631,
            longitude: 16.82033,
        },
        {
            obec: 'Slatinice',
            kod: 505111,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78342,
            latitude: 49.561652,
            longitude: 17.100026,
        },
        {
            obec: 'Slatinky',
            kod: 590011,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78342,
            latitude: 49.548787,
            longitude: 17.094088,
        },
        {
            obec: 'Slavětín',
            kod: 552194,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78324,
            latitude: 49.67466,
            longitude: 16.963636,
        },
        {
            obec: 'Smržice',
            kod: 590029,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79817,
            latitude: 49.505793,
            longitude: 17.107084,
        },
        {
            obec: 'Soběchleby',
            kod: 517844,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.478963,
            longitude: 17.651641,
        },
        {
            obec: 'Sobíšky',
            kod: 517836,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75121,
            latitude: 49.503857,
            longitude: 17.450876,
        },
        {
            obec: 'Sobotín',
            kod: 540986,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78816,
            latitude: 50.011797,
            longitude: 17.090856,
        },
        {
            obec: 'Srbce',
            kod: 590045,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79827,
            latitude: 49.30241,
            longitude: 17.229132,
        },
        {
            obec: 'Stará Červená Voda',
            kod: 541036,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79053,
            latitude: 50.329431,
            longitude: 17.201256,
        },
        {
            obec: 'Stará Ves',
            kod: 517887,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75002,
            latitude: 49.378741,
            longitude: 17.488182,
        },
        {
            obec: 'Staré Město',
            kod: 541079,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78832,
            latitude: 50.161832,
            longitude: 16.947446,
        },
        {
            obec: 'Stařechovice',
            kod: 590053,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79841,
            latitude: 49.532985,
            longitude: 17.053825,
        },
        {
            obec: 'Stavenice',
            kod: 570281,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78973,
            latitude: 49.786942,
            longitude: 16.973733,
        },
        {
            obec: 'Stínava',
            kod: 590061,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.495308,
            longitude: 16.934611,
        },
        {
            obec: 'Stražisko',
            kod: 590070,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79844,
            latitude: 49.543242,
            longitude: 16.930233,
        },
        {
            obec: 'Strukov',
            kod: 569054,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78401,
            latitude: 49.733438,
            longitude: 17.175596,
        },
        {
            obec: 'Střeň',
            kod: 547018,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78332,
            latitude: 49.69162,
            longitude: 17.153923,
        },
        {
            obec: 'Stříbrnice',
            kod: 552887,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75201,
            latitude: 49.328553,
            longitude: 17.24617,
        },
        {
            obec: 'Střítež nad Ludinou',
            kod: 517909,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75363,
            latitude: 49.609436,
            longitude: 17.739016,
        },
        {
            obec: 'Sudkov',
            kod: 541109,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78821,
            latitude: 49.919123,
            longitude: 16.945268,
        },
        {
            obec: 'Suchdol',
            kod: 590088,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79845,
            latitude: 49.547269,
            longitude: 16.896773,
        },
        {
            obec: 'Suchonice',
            kod: 569771,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78357,
            latitude: 49.527138,
            longitude: 17.382014,
        },
        {
            obec: 'Supíkovice',
            kod: 541117,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79051,
            latitude: 50.297785,
            longitude: 17.255253,
        },
        {
            obec: 'Sušice',
            kod: 518026,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.486027,
            longitude: 17.537486,
        },
        {
            obec: 'Svébohov',
            kod: 541125,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.92004,
            longitude: 16.839277,
        },
        {
            obec: 'Svésedlice',
            kod: 552429,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78354,
            latitude: 49.572579,
            longitude: 17.381252,
        },
        {
            obec: 'Šišma',
            kod: 552950,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75111,
            latitude: 49.464011,
            longitude: 17.588144,
        },
        {
            obec: 'Šléglov',
            kod: 570117,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78825,
            latitude: 50.153075,
            longitude: 16.982641,
        },
        {
            obec: 'Špičky',
            kod: 570061,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75366,
            latitude: 49.548258,
            longitude: 17.807885,
        },
        {
            obec: 'Štarnov',
            kod: 552011,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78314,
            latitude: 49.686232,
            longitude: 17.272686,
        },
        {
            obec: 'Štěpánov',
            kod: 505161,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78313,
            latitude: 49.684136,
            longitude: 17.220518,
        },
        {
            obec: 'Šternberk',
            kod: 505188,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78501,
            latitude: 49.730542,
            longitude: 17.298995,
        },
        {
            obec: 'Štíty',
            kod: 541168,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78991,
            latitude: 49.961321,
            longitude: 16.765863,
        },
        {
            obec: 'Šubířov',
            kod: 590096,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79852,
            latitude: 49.602264,
            longitude: 16.813444,
        },
        {
            obec: 'Šumperk',
            kod: 523704,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78701,
            latitude: 49.977941,
            longitude: 16.971875,
        },
        {
            obec: 'Šumvald',
            kod: 505218,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78385,
            latitude: 49.831136,
            longitude: 17.132851,
        },
        {
            obec: 'Teplice nad Bečvou',
            kod: 519031,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.528613,
            longitude: 17.74019,
        },
        {
            obec: 'Těšetice',
            kod: 505269,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78346,
            latitude: 49.593272,
            longitude: 17.126175,
        },
        {
            obec: 'Tištín',
            kod: 590100,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79829,
            latitude: 49.306988,
            longitude: 17.165505,
        },
        {
            obec: 'Tovačov',
            kod: 519146,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75101,
            latitude: 49.429711,
            longitude: 17.285739,
        },
        {
            obec: 'Tovéř',
            kod: 552089,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78316,
            latitude: 49.640125,
            longitude: 17.31912,
        },
        {
            obec: 'Troubelice',
            kod: 505293,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78383,
            latitude: 49.817411,
            longitude: 17.08111,
        },
        {
            obec: 'Troubky',
            kod: 519651,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75102,
            latitude: 49.432248,
            longitude: 17.34925,
        },
        {
            obec: 'Tršice',
            kod: 505366,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78357,
            latitude: 49.541173,
            longitude: 17.42708,
        },
        {
            obec: 'Třeština',
            kod: 553336,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78973,
            latitude: 49.796306,
            longitude: 16.963987,
        },
        {
            obec: 'Tučín',
            kod: 520047,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75116,
            latitude: 49.45409,
            longitude: 17.515128,
        },
        {
            obec: 'Turovice',
            kod: 552836,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75114,
            latitude: 49.423817,
            longitude: 17.580887,
        },
        {
            obec: 'Tvorovice',
            kod: 590118,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79823,
            latitude: 49.374487,
            longitude: 17.22203,
        },
        {
            obec: 'Týn nad Bečvou',
            kod: 570079,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75131,
            latitude: 49.51705,
            longitude: 17.618164,
        },
        {
            obec: 'Uhelná',
            kod: 541214,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79070,
            latitude: 50.365038,
            longitude: 17.027399,
        },
        {
            obec: 'Uhřičice',
            kod: 552879,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75201,
            latitude: 49.371673,
            longitude: 17.290435,
        },
        {
            obec: 'Újezd',
            kod: 505501,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78396,
            latitude: 49.764066,
            longitude: 17.180511,
        },
        {
            obec: 'Uničov',
            kod: 505587,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78391,
            latitude: 49.771013,
            longitude: 17.121542,
        },
        {
            obec: 'Určice',
            kod: 590126,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79804,
            latitude: 49.430551,
            longitude: 17.073015,
        },
        {
            obec: 'Úsov',
            kod: 541222,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78973,
            latitude: 49.798406,
            longitude: 17.010654,
        },
        {
            obec: 'Ústí',
            kod: 520306,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.516945,
            longitude: 17.766477,
        },
        {
            obec: 'Ústín',
            kod: 552364,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78346,
            latitude: 49.587135,
            longitude: 17.157685,
        },
        {
            obec: 'Vápenná',
            kod: 541249,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79064,
            latitude: 50.283473,
            longitude: 17.097722,
        },
        {
            obec: 'Velká Bystřice',
            kod: 505609,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78353,
            latitude: 49.594326,
            longitude: 17.364082,
        },
        {
            obec: 'Velká Kraš',
            kod: 553468,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79058,
            latitude: 50.363176,
            longitude: 17.146487,
        },
        {
            obec: 'Velké Kunětice',
            kod: 569453,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79052,
            latitude: 50.316673,
            longitude: 17.266034,
        },
        {
            obec: 'Velké Losiny',
            kod: 541265,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78815,
            latitude: 50.03207,
            longitude: 17.040687,
        },
        {
            obec: 'Velký Týnec',
            kod: 505650,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78372,
            latitude: 49.552072,
            longitude: 17.33766,
        },
        {
            obec: 'Velký Újezd',
            kod: 505668,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78355,
            latitude: 49.578679,
            longitude: 17.483576,
        },
        {
            obec: 'Vernířovice',
            kod: 554146,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78815,
            latitude: 50.031808,
            longitude: 17.130104,
        },
        {
            obec: 'Věrovany',
            kod: 552119,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78375,
            latitude: 49.461184,
            longitude: 17.288054,
        },
        {
            obec: 'Veselíčko',
            kod: 520420,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75125,
            latitude: 49.532193,
            longitude: 17.509241,
        },
        {
            obec: 'Věžky',
            kod: 552755,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75119,
            latitude: 49.405119,
            longitude: 17.42328,
        },
        {
            obec: 'Víceměřice',
            kod: 590134,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79826,
            latitude: 49.344156,
            longitude: 17.171909,
        },
        {
            obec: 'Vícov',
            kod: 590142,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79803,
            latitude: 49.488573,
            longitude: 16.959663,
        },
        {
            obec: 'Vidnava',
            kod: 541303,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79055,
            latitude: 50.372435,
            longitude: 17.18636,
        },
        {
            obec: 'Vikantice',
            kod: 553191,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78825,
            latitude: 50.13639,
            longitude: 16.99307,
        },
        {
            obec: 'Vikýřovice',
            kod: 569445,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78813,
            latitude: 49.976988,
            longitude: 17.013562,
        },
        {
            obec: 'Vilémov',
            kod: 568961,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78322,
            latitude: 49.635568,
            longitude: 16.99541,
        },
        {
            obec: 'Vincencov',
            kod: 544710,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79804,
            latitude: 49.405804,
            longitude: 17.062571,
        },
        {
            obec: 'Vitčice',
            kod: 590151,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79827,
            latitude: 49.311939,
            longitude: 17.240961,
        },
        {
            obec: 'Vlčice',
            kod: 541346,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79065,
            latitude: 50.345377,
            longitude: 17.046051,
        },
        {
            obec: 'Vlkoš',
            kod: 547433,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75119,
            latitude: 49.395884,
            longitude: 17.419119,
        },
        {
            obec: 'Vranovice-Kelčice',
            kod: 590177,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79808,
            latitude: 49.400982,
            longitude: 17.111366,
        },
        {
            obec: 'Vrbátky',
            kod: 590185,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79813,
            latitude: 49.508146,
            longitude: 17.200051,
        },
        {
            obec: 'Vrchoslavice',
            kod: 590193,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79827,
            latitude: 49.332781,
            longitude: 17.219343,
        },
        {
            obec: 'Vřesovice',
            kod: 590207,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79809,
            latitude: 49.401769,
            longitude: 17.139043,
        },
        {
            obec: 'Všechovice',
            kod: 521531,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75353,
            latitude: 49.462861,
            longitude: 17.758247,
        },
        {
            obec: 'Výkleky',
            kod: 547450,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75125,
            latitude: 49.554998,
            longitude: 17.475728,
        },
        {
            obec: 'Vyšehoří',
            kod: 570095,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.93371,
            longitude: 16.87848,
        },
        {
            obec: 'Výšovice',
            kod: 590215,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79809,
            latitude: 49.416444,
            longitude: 17.13914,
        },
        {
            obec: 'Zábeštní Lhota',
            kod: 547514,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75127,
            latitude: 49.511965,
            longitude: 17.431217,
        },
        {
            obec: 'Zábřeh',
            kod: 541354,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.8827,
            longitude: 16.872332,
        },
        {
            obec: 'Zámrsky',
            kod: 522775,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75301,
            latitude: 49.50645,
            longitude: 17.827929,
        },
        {
            obec: 'Zborov',
            kod: 570338,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.943754,
            longitude: 16.835964,
        },
        {
            obec: 'Zdětín',
            kod: 590223,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79843,
            latitude: 49.50595,
            longitude: 16.988886,
        },
        {
            obec: 'Zlaté Hory',
            kod: 597996,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79376,
            latitude: 50.263894,
            longitude: 17.396122,
        },
        {
            obec: 'Zvole',
            kod: 541478,
            okres: 'Šumperk',
            kod_okres: 'CZ0715',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78901,
            latitude: 49.839915,
            longitude: 16.914445,
        },
        {
            obec: 'Žákovice',
            kod: 523453,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75354,
            latitude: 49.454345,
            longitude: 17.660074,
        },
        {
            obec: 'Želatovice',
            kod: 523640,
            okres: 'Přerov',
            kod_okres: 'CZ0714',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 75116,
            latitude: 49.444143,
            longitude: 17.506312,
        },
        {
            obec: 'Želeč',
            kod: 590240,
            okres: 'Prostějov',
            kod_okres: 'CZ0713',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79807,
            latitude: 49.346065,
            longitude: 17.097896,
        },
        {
            obec: 'Želechovice',
            kod: 552399,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78391,
            latitude: 49.754196,
            longitude: 17.144047,
        },
        {
            obec: 'Žerotín',
            kod: 505862,
            okres: 'Olomouc',
            kod_okres: 'CZ0712',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 78401,
            latitude: 49.724632,
            longitude: 17.186914,
        },
        {
            obec: 'Žulová',
            kod: 541575,
            okres: 'Jeseník',
            kod_okres: 'CZ0711',
            kraj: 'Olomoucký kraj',
            kod_kraj: 'CZ071',
            psc: 79065,
            latitude: 50.30943,
            longitude: 17.098814,
        },
    ],
    CZ063: [
        {
            obec: 'Arneštovice',
            kod: 509388,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.53161,
            longitude: 15.117873,
        },
        {
            obec: 'Arnolec',
            kod: 586854,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.436298,
            longitude: 15.823702,
        },
        {
            obec: 'Babice',
            kod: 590274,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.135413,
            longitude: 15.770586,
        },
        {
            obec: 'Bácovice',
            kod: 561118,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.455771,
            longitude: 15.158118,
        },
        {
            obec: 'Bačice',
            kod: 590282,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.081736,
            longitude: 16.027802,
        },
        {
            obec: 'Bačkov',
            kod: 546194,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.735384,
            longitude: 15.462118,
        },
        {
            obec: 'Bačkovice',
            kod: 544833,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.965699,
            longitude: 15.589,
        },
        {
            obec: 'Baliny',
            kod: 595217,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.332666,
            longitude: 15.952501,
        },
        {
            obec: 'Bartoušov',
            kod: 548260,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.601128,
            longitude: 15.618547,
        },
        {
            obec: 'Batelov',
            kod: 586862,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.290179,
            longitude: 15.410734,
        },
        {
            obec: 'Bělá',
            kod: 573566,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.754546,
            longitude: 15.240686,
        },
        {
            obec: 'Bělá',
            kod: 561126,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.312617,
            longitude: 15.260715,
        },
        {
            obec: 'Benetice',
            kod: 590304,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67506,
            latitude: 49.297738,
            longitude: 15.867436,
        },
        {
            obec: 'Bezděkov',
            kod: 548430,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.732931,
            longitude: 15.730501,
        },
        {
            obec: 'Bílý Kámen',
            kod: 586889,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58841,
            latitude: 49.417417,
            longitude: 15.560205,
        },
        {
            obec: 'Biskupice-Pulkov',
            kod: 590312,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67558,
            latitude: 49.038255,
            longitude: 16.009555,
        },
        {
            obec: 'Bítovčice',
            kod: 586897,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58822,
            latitude: 49.368136,
            longitude: 15.734153,
        },
        {
            obec: 'Blatnice',
            kod: 590321,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.070235,
            longitude: 15.871431,
        },
        {
            obec: 'Blažkov',
            kod: 595241,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.473174,
            longitude: 16.185267,
        },
        {
            obec: 'Blízkov',
            kod: 595250,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59442,
            latitude: 49.400584,
            longitude: 15.90881,
        },
        {
            obec: 'Bobrová',
            kod: 595268,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.482402,
            longitude: 16.118399,
        },
        {
            obec: 'Bobrůvka',
            kod: 595276,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.44874,
            longitude: 16.093912,
        },
        {
            obec: 'Bohdalec',
            kod: 595284,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.474379,
            longitude: 16.05987,
        },
        {
            obec: 'Bohdalín',
            kod: 547549,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39491,
            latitude: 49.307781,
            longitude: 15.015943,
        },
        {
            obec: 'Bohdalov',
            kod: 595292,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59213,
            latitude: 49.480764,
            longitude: 15.876542,
        },
        {
            obec: 'Bohuňov',
            kod: 595306,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.560508,
            longitude: 16.202547,
        },
        {
            obec: 'Bohuslavice',
            kod: 586901,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.14953,
            longitude: 15.575917,
        },
        {
            obec: 'Bohušice',
            kod: 550400,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.085207,
            longitude: 15.852226,
        },
        {
            obec: 'Bochovice',
            kod: 590347,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.320924,
            longitude: 15.896424,
        },
        {
            obec: 'Bojiště',
            kod: 568457,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.667888,
            longitude: 15.286899,
        },
        {
            obec: 'Boňkov',
            kod: 573558,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58255,
            latitude: 49.54715,
            longitude: 15.447573,
        },
        {
            obec: 'Borek',
            kod: 568465,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.791915,
            longitude: 15.590083,
        },
        {
            obec: 'Borovná',
            kod: 587184,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.164698,
            longitude: 15.394712,
        },
        {
            obec: 'Borovnice',
            kod: 595322,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59242,
            latitude: 49.667328,
            longitude: 16.202654,
        },
        {
            obec: 'Boršov',
            kod: 586927,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.404043,
            longitude: 15.434297,
        },
        {
            obec: 'Bory',
            kod: 595641,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59461,
            latitude: 49.425865,
            longitude: 16.026282,
        },
        {
            obec: 'Bořetice',
            kod: 561240,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.496166,
            longitude: 15.11005,
        },
        {
            obec: 'Bořetín',
            kod: 509418,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.310234,
            longitude: 14.947722,
        },
        {
            obec: 'Božejov',
            kod: 547581,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39461,
            latitude: 49.362207,
            longitude: 15.155899,
        },
        {
            obec: 'Bransouze',
            kod: 590363,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.302776,
            longitude: 15.751924,
        },
        {
            obec: 'Bratřice',
            kod: 547603,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.516554,
            longitude: 15.001328,
        },
        {
            obec: 'Brtnice',
            kod: 586943,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58832,
            latitude: 49.307047,
            longitude: 15.676522,
        },
        {
            obec: 'Brtnička',
            kod: 590371,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67527,
            latitude: 49.237281,
            longitude: 15.624317,
        },
        {
            obec: 'Brzkov',
            kod: 586951,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.526068,
            longitude: 15.72724,
        },
        {
            obec: 'Břevnice',
            kod: 548278,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.631361,
            longitude: 15.612697,
        },
        {
            obec: 'Březejc',
            kod: 595349,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.346399,
            longitude: 16.091562,
        },
        {
            obec: 'Březí',
            kod: 595357,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.343279,
            longitude: 16.216045,
        },
        {
            obec: 'Březí nad Oslavou',
            kod: 595365,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.502993,
            longitude: 15.935697,
        },
        {
            obec: 'Březník',
            kod: 590380,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67574,
            latitude: 49.172155,
            longitude: 16.194204,
        },
        {
            obec: 'Březské',
            kod: 595381,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.330018,
            longitude: 16.238271,
        },
        {
            obec: 'Budeč',
            kod: 595390,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.53647,
            longitude: 15.912421,
        },
        {
            obec: 'Budíkov',
            kod: 547638,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.579243,
            longitude: 15.362705,
        },
        {
            obec: 'Budišov',
            kod: 590401,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.271484,
            longitude: 16.003938,
        },
        {
            obec: 'Budkov',
            kod: 590410,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67542,
            latitude: 49.054591,
            longitude: 15.65903,
        },
        {
            obec: 'Bukov',
            kod: 595403,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.454403,
            longitude: 16.223666,
        },
        {
            obec: 'Buřenice',
            kod: 561312,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.552815,
            longitude: 15.066329,
        },
        {
            obec: 'Bystrá',
            kod: 561801,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.50857,
            longitude: 15.371288,
        },
        {
            obec: 'Bystřice nad Pernštejnem',
            kod: 595411,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.523046,
            longitude: 16.261571,
        },
        {
            obec: 'Býšovec',
            kod: 595420,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.475308,
            longitude: 16.289398,
        },
        {
            obec: 'Cejle',
            kod: 586978,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.3694,
            longitude: 15.472878,
        },
        {
            obec: 'Cerekvička-Rosice',
            kod: 586986,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.334875,
            longitude: 15.587278,
        },
        {
            obec: 'Cetoraz',
            kod: 547662,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39411,
            latitude: 49.455335,
            longitude: 14.957412,
        },
        {
            obec: 'Cidlina',
            kod: 590428,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.128295,
            longitude: 15.735638,
        },
        {
            obec: 'Cikháj',
            kod: 588016,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59102,
            latitude: 49.644947,
            longitude: 15.96749,
        },
        {
            obec: 'Čachotín',
            kod: 568490,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.695303,
            longitude: 15.608696,
        },
        {
            obec: 'Čáslavice',
            kod: 590436,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67524,
            latitude: 49.152316,
            longitude: 15.772506,
        },
        {
            obec: 'Čáslavsko',
            kod: 547689,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.592512,
            longitude: 15.00285,
        },
        {
            obec: 'Častohostice',
            kod: 553964,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67602,
            latitude: 49.014482,
            longitude: 15.819302,
        },
        {
            obec: 'Častrov',
            kod: 547719,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39463,
            latitude: 49.307899,
            longitude: 15.180392,
        },
        {
            obec: 'Čečkovice',
            kod: 548375,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.784709,
            longitude: 15.664069,
        },
        {
            obec: 'Čechočovice',
            kod: 590444,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.202965,
            longitude: 15.793702,
        },
        {
            obec: 'Čechtín',
            kod: 590452,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.293149,
            longitude: 15.819975,
        },
        {
            obec: 'Čejov',
            kod: 547735,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.565751,
            longitude: 15.379353,
        },
        {
            obec: 'Čelistná',
            kod: 529826,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.360262,
            longitude: 15.206421,
        },
        {
            obec: 'Černá',
            kod: 595438,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59442,
            latitude: 49.426181,
            longitude: 15.86393,
        },
        {
            obec: 'Černíč',
            kod: 587001,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.127972,
            longitude: 15.45943,
        },
        {
            obec: 'Černov',
            kod: 561142,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.344066,
            longitude: 15.314148,
        },
        {
            obec: 'Černovice',
            kod: 547760,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39494,
            latitude: 49.372757,
            longitude: 14.960993,
        },
        {
            obec: 'Červená Lhota',
            kod: 590461,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.284564,
            longitude: 15.806208,
        },
        {
            obec: 'Červená Řečice',
            kod: 547778,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39446,
            latitude: 49.511334,
            longitude: 15.178468,
        },
        {
            obec: 'Česká Bělá',
            kod: 568503,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58261,
            latitude: 49.643015,
            longitude: 15.691066,
        },
        {
            obec: 'Číhalín',
            kod: 590479,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.268545,
            longitude: 15.812163,
        },
        {
            obec: 'Číhošť',
            kod: 568520,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58287,
            latitude: 49.741853,
            longitude: 15.33518,
        },
        {
            obec: 'Číchov',
            kod: 590487,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.284719,
            longitude: 15.762199,
        },
        {
            obec: 'Čikov',
            kod: 590495,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67578,
            latitude: 49.269605,
            longitude: 16.141012,
        },
        {
            obec: 'Číměř',
            kod: 590509,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67501,
            latitude: 49.200243,
            longitude: 15.998557,
        },
        {
            obec: 'Čížkov',
            kod: 598704,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.440552,
            longitude: 15.118556,
        },
        {
            obec: 'Čížov',
            kod: 587117,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58601,
            latitude: 49.38725,
            longitude: 15.575048,
        },
        {
            obec: 'Dalečín',
            kod: 595454,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59241,
            latitude: 49.59137,
            longitude: 16.240185,
        },
        {
            obec: 'Dalešice',
            kod: 590517,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67554,
            latitude: 49.129105,
            longitude: 16.08201,
        },
        {
            obec: 'Daňkovice',
            kod: 595462,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.654082,
            longitude: 16.148711,
        },
        {
            obec: 'Dědice',
            kod: 590525,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67541,
            latitude: 49.029803,
            longitude: 15.727855,
        },
        {
            obec: 'Dehtáře',
            kod: 561924,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.486202,
            longitude: 15.275386,
        },
        {
            obec: 'Dešov',
            kod: 590533,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67533,
            latitude: 48.985724,
            longitude: 15.70151,
        },
        {
            obec: 'Dlouhá Brtnice',
            kod: 587010,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58834,
            latitude: 49.236176,
            longitude: 15.604269,
        },
        {
            obec: 'Dlouhá Ves',
            kod: 568538,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.578654,
            longitude: 15.67485,
        },
        {
            obec: 'Dlouhé',
            kod: 595471,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.509081,
            longitude: 16.123148,
        },
        {
            obec: 'Dobrá Voda',
            kod: 561932,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.358246,
            longitude: 15.270103,
        },
        {
            obec: 'Dobrá Voda',
            kod: 595489,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.393997,
            longitude: 16.065144,
        },
        {
            obec: 'Dobrá Voda u Pacova',
            kod: 561762,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.40438,
            longitude: 15.02781,
        },
        {
            obec: 'Dobronín',
            kod: 587028,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58812,
            latitude: 49.478429,
            longitude: 15.650023,
        },
        {
            obec: 'Dobroutov',
            kod: 587036,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.462343,
            longitude: 15.73659,
        },
        {
            obec: 'Dolní Cerekev',
            kod: 587044,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58845,
            latitude: 49.344588,
            longitude: 15.456651,
        },
        {
            obec: 'Dolní Heřmanice',
            kod: 595501,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.304851,
            longitude: 16.060208,
        },
        {
            obec: 'Dolní Krupá',
            kod: 568597,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58271,
            latitude: 49.660734,
            longitude: 15.60314,
        },
        {
            obec: 'Dolní Lažany',
            kod: 587664,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.100034,
            longitude: 15.822815,
        },
        {
            obec: 'Dolní Libochová',
            kod: 595519,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59253,
            latitude: 49.408387,
            longitude: 16.184706,
        },
        {
            obec: 'Dolní Město',
            kod: 568601,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58233,
            latitude: 49.627806,
            longitude: 15.382401,
        },
        {
            obec: 'Dolní Rožínka',
            kod: 595535,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.47711,
            longitude: 16.210566,
        },
        {
            obec: 'Dolní Sokolovec',
            kod: 548448,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.726648,
            longitude: 15.715276,
        },
        {
            obec: 'Dolní Vilémovice',
            kod: 590550,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.153662,
            longitude: 15.977292,
        },
        {
            obec: 'Dolní Vilímeč',
            kod: 587061,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.135989,
            longitude: 15.520541,
        },
        {
            obec: 'Domamil',
            kod: 590568,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67543,
            latitude: 49.083336,
            longitude: 15.695675,
        },
        {
            obec: 'Doupě',
            kod: 587079,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.236937,
            longitude: 15.434721,
        },
        {
            obec: 'Druhanov',
            kod: 568619,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.69888,
            longitude: 15.410323,
        },
        {
            obec: 'Dubovice',
            kod: 551546,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.424582,
            longitude: 15.216681,
        },
        {
            obec: 'Dudín',
            kod: 587087,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.451107,
            longitude: 15.39771,
        },
        {
            obec: 'Dukovany',
            kod: 590576,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67556,
            latitude: 49.081352,
            longitude: 16.192279,
        },
        {
            obec: 'Důl',
            kod: 529869,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.452191,
            longitude: 15.030185,
        },
        {
            obec: 'Dušejov',
            kod: 587095,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.408817,
            longitude: 15.432836,
        },
        {
            obec: 'Dvorce',
            kod: 506729,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.375465,
            longitude: 15.490478,
        },
        {
            obec: 'Dyjice',
            kod: 587109,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.174727,
            longitude: 15.493972,
        },
        {
            obec: 'Eš',
            kod: 561754,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.434699,
            longitude: 15.001399,
        },
        {
            obec: 'Fryšava pod Žákovou horou',
            kod: 595578,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59204,
            latitude: 49.630512,
            longitude: 16.044434,
        },
        {
            obec: 'Golčův Jeníkov',
            kod: 568635,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.816361,
            longitude: 15.476967,
        },
        {
            obec: 'Habry',
            kod: 568651,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58281,
            latitude: 49.756128,
            longitude: 15.484963,
        },
        {
            obec: 'Hamry nad Sázavou',
            kod: 595586,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.566124,
            longitude: 15.901791,
        },
        {
            obec: 'Hartvíkovice',
            kod: 590584,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67576,
            latitude: 49.171199,
            longitude: 16.090438,
        },
        {
            obec: 'Havlíčkova Borová',
            kod: 568660,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58223,
            latitude: 49.635883,
            longitude: 15.781671,
        },
        {
            obec: 'Havlíčkův Brod',
            kod: 568414,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.604436,
            longitude: 15.579755,
        },
        {
            obec: 'Herálec',
            kod: 568678,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58255,
            latitude: 49.530935,
            longitude: 15.457184,
        },
        {
            obec: 'Herálec',
            kod: 595594,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59201,
            latitude: 49.689015,
            longitude: 15.994419,
        },
        {
            obec: 'Heraltice',
            kod: 590592,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.230933,
            longitude: 15.730648,
        },
        {
            obec: 'Heřmanice',
            kod: 548596,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.82401,
            longitude: 15.54733,
        },
        {
            obec: 'Heřmanov',
            kod: 595608,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59458,
            latitude: 49.379465,
            longitude: 16.181332,
        },
        {
            obec: 'Hladov',
            kod: 587125,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.214727,
            longitude: 15.611458,
        },
        {
            obec: 'Hluboké',
            kod: 590614,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.226034,
            longitude: 16.227607,
        },
        {
            obec: 'Hněvkovice',
            kod: 568694,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58294,
            latitude: 49.685299,
            longitude: 15.202877,
        },
        {
            obec: 'Hodice',
            kod: 587141,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58901,
            latitude: 49.270172,
            longitude: 15.480092,
        },
        {
            obec: 'Hodíškov',
            kod: 595624,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.503592,
            longitude: 16.038149,
        },
        {
            obec: 'Hodov',
            kod: 590622,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67504,
            latitude: 49.29408,
            longitude: 15.983949,
        },
        {
            obec: 'Hojanovice',
            kod: 547883,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.597358,
            longitude: 15.265616,
        },
        {
            obec: 'Hojkov',
            kod: 587150,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.389244,
            longitude: 15.413786,
        },
        {
            obec: 'Hojovice',
            kod: 561096,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.32846,
            longitude: 14.919872,
        },
        {
            obec: 'Horní Cerekev',
            kod: 547913,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39403,
            latitude: 49.320362,
            longitude: 15.327794,
        },
        {
            obec: 'Horní Dubenky',
            kod: 587168,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58852,
            latitude: 49.259681,
            longitude: 15.317105,
        },
        {
            obec: 'Horní Heřmanice',
            kod: 590631,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.329454,
            longitude: 15.920489,
        },
        {
            obec: 'Horní Krupá',
            kod: 568708,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.672094,
            longitude: 15.588589,
        },
        {
            obec: 'Horní Libochová',
            kod: 595659,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.407729,
            longitude: 16.148981,
        },
        {
            obec: 'Horní Myslová',
            kod: 587206,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.165516,
            longitude: 15.422663,
        },
        {
            obec: 'Horní Paseka',
            kod: 548243,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.629757,
            longitude: 15.293283,
        },
        {
            obec: 'Horní Radslavice',
            kod: 595675,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.338339,
            longitude: 15.902364,
        },
        {
            obec: 'Horní Rápotice',
            kod: 551589,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.575426,
            longitude: 15.320586,
        },
        {
            obec: 'Horní Rožínka',
            kod: 595683,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.5001,
            longitude: 16.193,
        },
        {
            obec: 'Horní Smrčné',
            kod: 550612,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.32869,
            longitude: 15.756199,
        },
        {
            obec: 'Horní Újezd',
            kod: 590649,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.142893,
            longitude: 15.842256,
        },
        {
            obec: 'Horní Ves',
            kod: 547930,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.293507,
            longitude: 15.307554,
        },
        {
            obec: 'Horní Vilémovice',
            kod: 590657,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.29166,
            longitude: 15.880909,
        },
        {
            obec: 'Hornice',
            kod: 590665,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.995287,
            longitude: 15.680464,
        },
        {
            obec: 'Hořepník',
            kod: 547948,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39421,
            latitude: 49.512566,
            longitude: 15.107097,
        },
        {
            obec: 'Hořice',
            kod: 547956,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.599362,
            longitude: 15.185502,
        },
        {
            obec: 'Hostětice',
            kod: 587192,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.191764,
            longitude: 15.414157,
        },
        {
            obec: 'Hradec',
            kod: 568724,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.707329,
            longitude: 15.295565,
        },
        {
            obec: 'Hrotovice',
            kod: 590673,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.1078,
            longitude: 16.060777,
        },
        {
            obec: 'Hroznatín',
            kod: 550639,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.29784,
            longitude: 15.908371,
        },
        {
            obec: 'Hrutov',
            kod: 590681,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67527,
            latitude: 49.255545,
            longitude: 15.680298,
        },
        {
            obec: 'Hubenov',
            kod: 587222,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.392193,
            longitude: 15.469125,
        },
        {
            obec: 'Humpolec',
            kod: 547999,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.541624,
            longitude: 15.359423,
        },
        {
            obec: 'Hurtova Lhota',
            kod: 530646,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.596462,
            longitude: 15.508912,
        },
        {
            obec: 'Hvězdoňovice',
            kod: 590690,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.220011,
            longitude: 15.762318,
        },
        {
            obec: 'Hybrálec',
            kod: 550281,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58601,
            latitude: 49.409551,
            longitude: 15.589163,
        },
        {
            obec: 'Chlístov',
            kod: 590703,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.202158,
            longitude: 15.743777,
        },
        {
            obec: 'Chlum',
            kod: 590711,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.313525,
            longitude: 15.767159,
        },
        {
            obec: 'Chlumek',
            kod: 595713,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59442,
            latitude: 49.374585,
            longitude: 15.854861,
        },
        {
            obec: 'Chlumětín',
            kod: 595721,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59202,
            latitude: 49.727586,
            longitude: 16.003178,
        },
        {
            obec: 'Chlum-Korouhvice',
            kod: 595705,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.575317,
            longitude: 16.320783,
        },
        {
            obec: 'Chotěboř',
            kod: 568759,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.720714,
            longitude: 15.671142,
        },
        {
            obec: 'Chotěbudice',
            kod: 550477,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.058086,
            longitude: 15.574099,
        },
        {
            obec: 'Chrtníč',
            kod: 568767,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.767642,
            longitude: 15.443719,
        },
        {
            obec: 'Chřenovice',
            kod: 573574,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.716741,
            longitude: 15.216372,
        },
        {
            obec: 'Chýstovice',
            kod: 561339,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.585781,
            longitude: 15.079667,
        },
        {
            obec: 'Chyšná',
            kod: 561321,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.586467,
            longitude: 15.10003,
        },
        {
            obec: 'Jabloňov',
            kod: 595730,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.324114,
            longitude: 16.089764,
        },
        {
            obec: 'Jakubov u Moravských Budějovic',
            kod: 590746,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.081114,
            longitude: 15.761519,
        },
        {
            obec: 'Jamné',
            kod: 587249,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.431232,
            longitude: 15.723948,
        },
        {
            obec: 'Jámy',
            kod: 595756,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59232,
            latitude: 49.530363,
            longitude: 16.005752,
        },
        {
            obec: 'Jankov',
            kod: 561461,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.415196,
            longitude: 15.38632,
        },
        {
            obec: 'Jaroměřice nad Rokytnou',
            kod: 590754,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.094184,
            longitude: 15.893408,
        },
        {
            obec: 'Jasenice',
            kod: 590762,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.25824,
            longitude: 16.163698,
        },
        {
            obec: 'Javorek',
            kod: 595748,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.65028,
            longitude: 16.17387,
        },
        {
            obec: 'Jedlá',
            kod: 568783,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.73822,
            longitude: 15.234446,
        },
        {
            obec: 'Jemnice',
            kod: 590789,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.019086,
            longitude: 15.570038,
        },
        {
            obec: 'Jersín',
            kod: 587265,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58825,
            latitude: 49.421358,
            longitude: 15.830768,
        },
        {
            obec: 'Jeřišno',
            kod: 568805,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58274,
            latitude: 49.792454,
            longitude: 15.641764,
        },
        {
            obec: 'Jezdovice',
            kod: 587273,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58901,
            latitude: 49.323203,
            longitude: 15.484258,
        },
        {
            obec: 'Ježená',
            kod: 587281,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58841,
            latitude: 49.416395,
            longitude: 15.465658,
        },
        {
            obec: 'Ježov',
            kod: 548073,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.613127,
            longitude: 15.23382,
        },
        {
            obec: 'Jihlava',
            kod: 586846,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58601,
            latitude: 49.41586,
            longitude: 15.595469,
        },
        {
            obec: 'Jihlávka',
            kod: 587290,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.261225,
            longitude: 15.292576,
        },
        {
            obec: 'Jilem',
            kod: 548413,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.713475,
            longitude: 15.58347,
        },
        {
            obec: 'Jimramov',
            kod: 595772,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59242,
            latitude: 49.637288,
            longitude: 16.226424,
        },
        {
            obec: 'Jindřichovice',
            kod: 587303,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.137705,
            longitude: 15.638697,
        },
        {
            obec: 'Jinošov',
            kod: 590797,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.231477,
            longitude: 16.193554,
        },
        {
            obec: 'Jiratice',
            kod: 550591,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.983174,
            longitude: 15.612866,
        },
        {
            obec: 'Jiřice',
            kod: 548081,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.55332,
            longitude: 15.31844,
        },
        {
            obec: 'Jitkov',
            kod: 546216,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.662324,
            longitude: 15.7274,
        },
        {
            obec: 'Jívoví',
            kod: 595802,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.40547,
            longitude: 16.090844,
        },
        {
            obec: 'Kadolec',
            kod: 595811,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.374803,
            longitude: 16.141838,
        },
        {
            obec: 'Kadov',
            kod: 595829,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.632666,
            longitude: 16.079322,
        },
        {
            obec: 'Kalhov',
            kod: 587320,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.481127,
            longitude: 15.44153,
        },
        {
            obec: 'Kaliště',
            kod: 587338,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.241354,
            longitude: 15.301432,
        },
        {
            obec: 'Kaliště',
            kod: 548090,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39451,
            latitude: 49.5927,
            longitude: 15.304162,
        },
        {
            obec: 'Kámen',
            kod: 568821,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58242,
            latitude: 49.711957,
            longitude: 15.519989,
        },
        {
            obec: 'Kámen',
            kod: 548103,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39413,
            latitude: 49.423391,
            longitude: 15.015964,
        },
        {
            obec: 'Kamenice',
            kod: 587346,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58823,
            latitude: 49.366688,
            longitude: 15.780333,
        },
        {
            obec: 'Kamenice nad Lipou',
            kod: 548111,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.303136,
            longitude: 15.075292,
        },
        {
            obec: 'Kamenná',
            kod: 587362,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.51005,
            longitude: 15.652675,
        },
        {
            obec: 'Kamenná',
            kod: 590801,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.272529,
            longitude: 16.060488,
        },
        {
            obec: 'Kamenná Lhota',
            kod: 568848,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58292,
            latitude: 49.643905,
            longitude: 15.275344,
        },
        {
            obec: 'Karlov',
            kod: 595845,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.647105,
            longitude: 15.914538,
        },
        {
            obec: 'Kdousov',
            kod: 590819,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.986074,
            longitude: 15.647401,
        },
        {
            obec: 'Kejžlice',
            kod: 548120,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39452,
            latitude: 49.590575,
            longitude: 15.392728,
        },
        {
            obec: 'Kladeruby nad Oslavou',
            kod: 590827,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67575,
            latitude: 49.144911,
            longitude: 16.17187,
        },
        {
            obec: 'Klatovec',
            kod: 587371,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.222518,
            longitude: 15.294793,
        },
        {
            obec: 'Klokočov',
            kod: 568856,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.801828,
            longitude: 15.674289,
        },
        {
            obec: 'Klučov',
            kod: 590835,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.167093,
            longitude: 15.935181,
        },
        {
            obec: 'Kněževes',
            kod: 595853,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.456579,
            longitude: 15.980924,
        },
        {
            obec: 'Kněžice',
            kod: 590843,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67529,
            latitude: 49.27088,
            longitude: 15.672268,
        },
        {
            obec: 'Knínice',
            kod: 587389,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.094109,
            longitude: 15.604156,
        },
        {
            obec: 'Knyk',
            kod: 548286,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.647038,
            longitude: 15.578739,
        },
        {
            obec: 'Koberovice',
            kod: 548146,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.587669,
            longitude: 15.262965,
        },
        {
            obec: 'Kochánov',
            kod: 548529,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58253,
            latitude: 49.534276,
            longitude: 15.540336,
        },
        {
            obec: 'Kojatice',
            kod: 590851,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.998947,
            longitude: 15.703223,
        },
        {
            obec: 'Kojatín',
            kod: 510645,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.242707,
            longitude: 16.010276,
        },
        {
            obec: 'Kojčice',
            kod: 598712,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39409,
            latitude: 49.476052,
            longitude: 15.255999,
        },
        {
            obec: 'Kojetice',
            kod: 590860,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67523,
            latitude: 49.157925,
            longitude: 15.818142,
        },
        {
            obec: 'Kojetín',
            kod: 568881,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.657213,
            longitude: 15.658186,
        },
        {
            obec: 'Komárovice',
            kod: 590878,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.070089,
            longitude: 15.701107,
        },
        {
            obec: 'Komorovice',
            kod: 548162,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.508793,
            longitude: 15.345625,
        },
        {
            obec: 'Koněšín',
            kod: 590886,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67502,
            latitude: 49.190593,
            longitude: 16.041305,
        },
        {
            obec: 'Koroužné',
            kod: 595861,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.527574,
            longitude: 16.347737,
        },
        {
            obec: 'Kostelec',
            kod: 587401,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58861,
            latitude: 49.3614,
            longitude: 15.490771,
        },
        {
            obec: 'Kostelní Myslová',
            kod: 587419,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.147908,
            longitude: 15.42899,
        },
        {
            obec: 'Kostníky',
            kod: 590894,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.974807,
            longitude: 15.639908,
        },
        {
            obec: 'Košetice',
            kod: 548171,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39422,
            latitude: 49.558467,
            longitude: 15.116371,
        },
        {
            obec: 'Kotlasy',
            kod: 595870,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.492812,
            longitude: 15.946201,
        },
        {
            obec: 'Kouty',
            kod: 548189,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.648891,
            longitude: 15.293094,
        },
        {
            obec: 'Kouty',
            kod: 590908,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67508,
            latitude: 49.314835,
            longitude: 15.792795,
        },
        {
            obec: 'Kozlany',
            kod: 590916,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67502,
            latitude: 49.179832,
            longitude: 16.061293,
        },
        {
            obec: 'Kozlov',
            kod: 568899,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.734658,
            longitude: 15.293073,
        },
        {
            obec: 'Kozlov',
            kod: 587427,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58821,
            latitude: 49.413143,
            longitude: 15.700567,
        },
        {
            obec: 'Kozlov',
            kod: 595888,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.379245,
            longitude: 16.078773,
        },
        {
            obec: 'Kožichovice',
            kod: 545309,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.199963,
            longitude: 15.922024,
        },
        {
            obec: 'Kožlí',
            kod: 568902,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58293,
            latitude: 49.670414,
            longitude: 15.253313,
        },
        {
            obec: 'Kraborovice',
            kod: 548618,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.800604,
            longitude: 15.571977,
        },
        {
            obec: 'Krahulčí',
            kod: 587435,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.181877,
            longitude: 15.414923,
        },
        {
            obec: 'Krahulov',
            kod: 550710,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.223007,
            longitude: 15.808241,
        },
        {
            obec: 'Kralice nad Oslavou',
            kod: 590941,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67573,
            latitude: 49.199373,
            longitude: 16.201664,
        },
        {
            obec: 'Kramolín',
            kod: 590959,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67577,
            latitude: 49.134304,
            longitude: 16.132027,
        },
        {
            obec: 'Krasíkovice',
            kod: 561371,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.461735,
            longitude: 15.228765,
        },
        {
            obec: 'Krásná Hora',
            kod: 568929,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58234,
            latitude: 49.602787,
            longitude: 15.470441,
        },
        {
            obec: 'Krásné',
            kod: 595896,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.677952,
            longitude: 16.145108,
        },
        {
            obec: 'Krásněves',
            kod: 595900,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.44451,
            longitude: 15.981262,
        },
        {
            obec: 'Krasonice',
            kod: 587443,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58864,
            latitude: 49.11513,
            longitude: 15.616146,
        },
        {
            obec: 'Krátká Ves',
            kod: 568937,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.625837,
            longitude: 15.684616,
        },
        {
            obec: 'Krhov',
            kod: 590967,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.100437,
            longitude: 16.019513,
        },
        {
            obec: 'Krokočín',
            kod: 590975,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.244186,
            longitude: 16.23308,
        },
        {
            obec: 'Krucemburk',
            kod: 568945,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58266,
            latitude: 49.688511,
            longitude: 15.852171,
        },
        {
            obec: 'Křeč',
            kod: 548201,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39495,
            latitude: 49.389356,
            longitude: 14.917101,
        },
        {
            obec: 'Křelovice',
            kod: 548219,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39445,
            latitude: 49.536955,
            longitude: 15.168678,
        },
        {
            obec: 'Křešín',
            kod: 548227,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39424,
            latitude: 49.581824,
            longitude: 15.043819,
        },
        {
            obec: 'Křídla',
            kod: 587842,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.52733,
            longitude: 16.122451,
        },
        {
            obec: 'Křižánky',
            kod: 595918,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59202,
            latitude: 49.687674,
            longitude: 16.0644,
        },
        {
            obec: 'Křižanov',
            kod: 595926,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.388682,
            longitude: 16.109707,
        },
        {
            obec: 'Křoví',
            kod: 595951,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59454,
            latitude: 49.306061,
            longitude: 16.26435,
        },
        {
            obec: 'Kuklík',
            kod: 595969,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.630022,
            longitude: 16.113247,
        },
        {
            obec: 'Kundratice',
            kod: 595977,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.402684,
            longitude: 16.134262,
        },
        {
            obec: 'Kunemil',
            kod: 548570,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.707561,
            longitude: 15.431616,
        },
        {
            obec: 'Kuroslepy',
            kod: 550302,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67575,
            latitude: 49.154432,
            longitude: 16.210217,
        },
        {
            obec: 'Květinov',
            kod: 568953,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.564751,
            longitude: 15.50741,
        },
        {
            obec: 'Kyjov',
            kod: 548308,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.629564,
            longitude: 15.623325,
        },
        {
            obec: 'Kyjov',
            kod: 596001,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.443544,
            longitude: 15.881657,
        },
        {
            obec: 'Kynice',
            kod: 568970,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.739281,
            longitude: 15.360244,
        },
        {
            obec: 'Lány',
            kod: 548481,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.759535,
            longitude: 15.712996,
        },
        {
            obec: 'Lavičky',
            kod: 596019,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.380597,
            longitude: 15.968444,
        },
        {
            obec: 'Láz',
            kod: 510556,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67541,
            latitude: 49.012392,
            longitude: 15.785165,
        },
        {
            obec: 'Ledeč nad Sázavou',
            kod: 568988,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.695269,
            longitude: 15.277827,
        },
        {
            obec: 'Leskovice',
            kod: 548235,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39414,
            latitude: 49.43,
            longitude: 15.08121,
        },
        {
            obec: 'Lesná',
            kod: 562041,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.518016,
            longitude: 15.057662,
        },
        {
            obec: 'Lesná',
            kod: 590983,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.171418,
            longitude: 15.679494,
        },
        {
            obec: 'Lesní Jakubov',
            kod: 590991,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67573,
            latitude: 49.205878,
            longitude: 16.243465,
        },
        {
            obec: 'Lesonice',
            kod: 591009,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.107958,
            longitude: 15.755986,
        },
        {
            obec: 'Lesůňky',
            kod: 573485,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.107167,
            longitude: 15.84245,
        },
        {
            obec: 'Leškovice',
            kod: 568996,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.762995,
            longitude: 15.537109,
        },
        {
            obec: 'Leština u Světlé',
            kod: 569011,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58286,
            latitude: 49.761453,
            longitude: 15.39771,
        },
        {
            obec: 'Lhánice',
            kod: 591025,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67575,
            latitude: 49.109192,
            longitude: 16.223507,
        },
        {
            obec: 'Lhota-Vlasenice',
            kod: 561282,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.302785,
            longitude: 15.12482,
        },
        {
            obec: 'Lhotice',
            kod: 544876,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.019369,
            longitude: 15.60716,
        },
        {
            obec: 'Lhotka',
            kod: 587451,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.209194,
            longitude: 15.385751,
        },
        {
            obec: 'Lhotka',
            kod: 596035,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.582281,
            longitude: 16.002978,
        },
        {
            obec: 'Libice nad Doubravou',
            kod: 569020,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58277,
            latitude: 49.745023,
            longitude: 15.704338,
        },
        {
            obec: 'Libkova Voda',
            kod: 598721,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39462,
            latitude: 49.376595,
            longitude: 15.191112,
        },
        {
            obec: 'Lidmaň',
            kod: 561100,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.381938,
            longitude: 15.036826,
        },
        {
            obec: 'Lípa',
            kod: 569038,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58257,
            latitude: 49.554584,
            longitude: 15.542919,
        },
        {
            obec: 'Lipnice nad Sázavou',
            kod: 569046,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58232,
            latitude: 49.613125,
            longitude: 15.413664,
        },
        {
            obec: 'Lipník',
            kod: 591041,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.144357,
            longitude: 15.950723,
        },
        {
            obec: 'Lísek',
            kod: 596051,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.586936,
            longitude: 16.201244,
        },
        {
            obec: 'Líšná',
            kod: 596060,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.640272,
            longitude: 16.151155,
        },
        {
            obec: 'Litohoř',
            kod: 550507,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.066937,
            longitude: 15.768521,
        },
        {
            obec: 'Litohošť',
            kod: 561266,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.449741,
            longitude: 15.091095,
        },
        {
            obec: 'Litovany',
            kod: 591068,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67557,
            latitude: 49.055056,
            longitude: 16.044024,
        },
        {
            obec: 'Lomy',
            kod: 587605,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.065617,
            longitude: 15.601184,
        },
        {
            obec: 'Loukovice',
            kod: 591092,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.137327,
            longitude: 15.793465,
        },
        {
            obec: 'Lovčovice',
            kod: 544957,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 48.981577,
            longitude: 15.53442,
        },
        {
            obec: 'Lučice',
            kod: 569062,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58235,
            latitude: 49.662406,
            longitude: 15.496764,
        },
        {
            obec: 'Luka nad Jihlavou',
            kod: 587478,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58822,
            latitude: 49.374132,
            longitude: 15.702039,
        },
        {
            obec: 'Lukavec',
            kod: 548332,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39426,
            latitude: 49.565511,
            longitude: 14.990517,
        },
        {
            obec: 'Lukov',
            kod: 550566,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67602,
            latitude: 49.073601,
            longitude: 15.824336,
        },
        {
            obec: 'Malá Losenice',
            kod: 596086,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59211,
            latitude: 49.600028,
            longitude: 15.796704,
        },
        {
            obec: 'Malčín',
            kod: 569071,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.688715,
            longitude: 15.465269,
        },
        {
            obec: 'Maleč',
            kod: 569089,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58276,
            latitude: 49.772128,
            longitude: 15.676917,
        },
        {
            obec: 'Malý Beranov',
            kod: 587486,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58603,
            latitude: 49.396595,
            longitude: 15.637796,
        },
        {
            obec: 'Markvartice',
            kod: 587494,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.178289,
            longitude: 15.617202,
        },
        {
            obec: 'Markvartice',
            kod: 591114,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.200958,
            longitude: 15.767422,
        },
        {
            obec: 'Martinice',
            kod: 596094,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.371786,
            longitude: 16.038309,
        },
        {
            obec: 'Martinice u Onšova',
            kod: 561347,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.579951,
            longitude: 15.115482,
        },
        {
            obec: 'Martínkov',
            kod: 550388,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67544,
            latitude: 49.096759,
            longitude: 15.711505,
        },
        {
            obec: 'Mastník',
            kod: 591122,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.180293,
            longitude: 15.827933,
        },
        {
            obec: 'Matějov',
            kod: 596108,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.528697,
            longitude: 15.863108,
        },
        {
            obec: 'Menhartice',
            kod: 545031,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 48.990055,
            longitude: 15.552611,
        },
        {
            obec: 'Měřín',
            kod: 596116,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59442,
            latitude: 49.393312,
            longitude: 15.883916,
        },
        {
            obec: 'Měšín',
            kod: 587508,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58601,
            latitude: 49.437406,
            longitude: 15.657133,
        },
        {
            obec: 'Mezilesí',
            kod: 548367,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.541028,
            longitude: 14.949183,
        },
        {
            obec: 'Meziříčko',
            kod: 591149,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.109422,
            longitude: 15.660333,
        },
        {
            obec: 'Meziříčko',
            kod: 587516,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.405486,
            longitude: 15.844614,
        },
        {
            obec: 'Mezná',
            kod: 529834,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.34713,
            longitude: 15.211484,
        },
        {
            obec: 'Michalovice',
            kod: 548316,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.577036,
            longitude: 15.524426,
        },
        {
            obec: 'Mikulovice',
            kod: 591157,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.161765,
            longitude: 15.846693,
        },
        {
            obec: 'Milasín',
            kod: 549835,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.466338,
            longitude: 16.228909,
        },
        {
            obec: 'Milešín',
            kod: 587770,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.368503,
            longitude: 16.201661,
        },
        {
            obec: 'Milíčov',
            kod: 587524,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.395568,
            longitude: 15.393119,
        },
        {
            obec: 'Mirošov',
            kod: 587532,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.389533,
            longitude: 15.463549,
        },
        {
            obec: 'Mirošov',
            kod: 596132,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.465697,
            longitude: 16.155648,
        },
        {
            obec: 'Mladé Bříště',
            kod: 548383,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39443,
            latitude: 49.491565,
            longitude: 15.335272,
        },
        {
            obec: 'Mladoňovice',
            kod: 591165,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 49.010221,
            longitude: 15.64373,
        },
        {
            obec: 'Mnich',
            kod: 548391,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39492,
            latitude: 49.298466,
            longitude: 14.965835,
        },
        {
            obec: 'Modlíkov',
            kod: 569127,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.608287,
            longitude: 15.770401,
        },
        {
            obec: 'Mohelno',
            kod: 591173,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67575,
            latitude: 49.114222,
            longitude: 16.190323,
        },
        {
            obec: 'Moravec',
            kod: 596141,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59254,
            latitude: 49.440204,
            longitude: 16.143415,
        },
        {
            obec: 'Moravecké Pavlovice',
            kod: 596159,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59262,
            latitude: 49.425534,
            longitude: 16.256425,
        },
        {
            obec: 'Moraveč',
            kod: 548405,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.409182,
            longitude: 15.076977,
        },
        {
            obec: 'Moravské Budějovice',
            kod: 591181,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67602,
            latitude: 49.05219,
            longitude: 15.808746,
        },
        {
            obec: 'Mrákotín',
            kod: 587541,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58854,
            latitude: 49.189556,
            longitude: 15.376196,
        },
        {
            obec: 'Mysletice',
            kod: 587559,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.142671,
            longitude: 15.389524,
        },
        {
            obec: 'Mysletín',
            kod: 561819,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.476693,
            longitude: 15.368309,
        },
        {
            obec: 'Mysliboř',
            kod: 587567,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.209518,
            longitude: 15.484514,
        },
        {
            obec: 'Myslibořice',
            kod: 591190,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67560,
            latitude: 49.102992,
            longitude: 15.98466,
        },
        {
            obec: 'Nadějov',
            kod: 587575,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.422331,
            longitude: 15.773162,
        },
        {
            obec: 'Naloučany',
            kod: 550779,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.233146,
            longitude: 16.135313,
        },
        {
            obec: 'Náměšť nad Oslavou',
            kod: 591211,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.205556,
            longitude: 16.155845,
        },
        {
            obec: 'Nárameč',
            kod: 591220,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.264513,
            longitude: 15.974825,
        },
        {
            obec: 'Nejepín',
            kod: 548197,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.744607,
            longitude: 15.600679,
        },
        {
            obec: 'Netín',
            kod: 596183,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.413112,
            longitude: 15.950806,
        },
        {
            obec: 'Nevcehle',
            kod: 587583,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.225836,
            longitude: 15.534527,
        },
        {
            obec: 'Nimpšov',
            kod: 591238,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67541,
            latitude: 49.022725,
            longitude: 15.747338,
        },
        {
            obec: 'Nížkov',
            kod: 596205,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.532665,
            longitude: 15.805946,
        },
        {
            obec: 'Nová Buková',
            kod: 561177,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.346075,
            longitude: 15.297103,
        },
        {
            obec: 'Nová Cerekev',
            kod: 548456,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39415,
            latitude: 49.417337,
            longitude: 15.116849,
        },
        {
            obec: 'Nová Říše',
            kod: 587591,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58865,
            latitude: 49.13951,
            longitude: 15.563696,
        },
        {
            obec: 'Nová Ves',
            kod: 591246,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.248618,
            longitude: 15.803952,
        },
        {
            obec: 'Nová Ves',
            kod: 596213,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.391108,
            longitude: 16.196383,
        },
        {
            obec: 'Nová Ves u Chotěboře',
            kod: 569160,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58273,
            latitude: 49.756205,
            longitude: 15.648091,
        },
        {
            obec: 'Nová Ves u Leštiny',
            kod: 569151,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.785954,
            longitude: 15.404017,
        },
        {
            obec: 'Nová Ves u Nového Města na Moravě',
            kod: 596221,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.540029,
            longitude: 16.084205,
        },
        {
            obec: 'Nová Ves u Světlé',
            kod: 555266,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.648874,
            longitude: 15.437793,
        },
        {
            obec: 'Nové Dvory',
            kod: 587974,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.564483,
            longitude: 15.808971,
        },
        {
            obec: 'Nové Město na Moravě',
            kod: 596230,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.561543,
            longitude: 16.074288,
        },
        {
            obec: 'Nové Sady',
            kod: 596248,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59501,
            latitude: 49.293788,
            longitude: 16.193421,
        },
        {
            obec: 'Nové Syrovice',
            kod: 591254,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67541,
            latitude: 49.01773,
            longitude: 15.773555,
        },
        {
            obec: 'Nové Veselí',
            kod: 596256,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.519866,
            longitude: 15.90863,
        },
        {
            obec: 'Nový Jimramov',
            kod: 596264,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59242,
            latitude: 49.634722,
            longitude: 16.187315,
        },
        {
            obec: 'Nový Rychnov',
            kod: 548464,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39404,
            latitude: 49.383617,
            longitude: 15.36629,
        },
        {
            obec: 'Nový Telečkov',
            kod: 591262,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.32127,
            longitude: 15.938454,
        },
        {
            obec: 'Nyklovice',
            kod: 596272,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.603976,
            longitude: 16.345044,
        },
        {
            obec: 'Obrataň',
            kod: 548472,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39412,
            latitude: 49.425159,
            longitude: 14.943589,
        },
        {
            obec: 'Obyčtov',
            kod: 596281,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.499959,
            longitude: 16.001685,
        },
        {
            obec: 'Ocmanice',
            kod: 510980,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.233244,
            longitude: 16.125399,
        },
        {
            obec: 'Odunec',
            kod: 591289,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.120315,
            longitude: 16.014372,
        },
        {
            obec: 'Okarec',
            kod: 591297,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67502,
            latitude: 49.206057,
            longitude: 16.088033,
        },
        {
            obec: 'Okrouhlice',
            kod: 569186,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58231,
            latitude: 49.630014,
            longitude: 15.490933,
        },
        {
            obec: 'Okrouhlička',
            kod: 548588,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58253,
            latitude: 49.534541,
            longitude: 15.556975,
        },
        {
            obec: 'Okřešice',
            kod: 546933,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.25948,
            longitude: 15.852076,
        },
        {
            obec: 'Okříšky',
            kod: 591301,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.245493,
            longitude: 15.769689,
        },
        {
            obec: 'Olešenka',
            kod: 569208,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.549005,
            longitude: 15.765938,
        },
        {
            obec: 'Olešná',
            kod: 569216,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.68167,
            longitude: 15.547785,
        },
        {
            obec: 'Olešná',
            kod: 561967,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.445848,
            longitude: 15.263754,
        },
        {
            obec: 'Olšany',
            kod: 587613,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.186221,
            longitude: 15.566091,
        },
        {
            obec: 'Olší',
            kod: 587214,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.155548,
            longitude: 15.373333,
        },
        {
            obec: 'Ondřejov',
            kod: 537691,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.393042,
            longitude: 15.178062,
        },
        {
            obec: 'Onšov',
            kod: 548502,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.578327,
            longitude: 15.136477,
        },
        {
            obec: 'Opatov',
            kod: 587621,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58805,
            latitude: 49.431128,
            longitude: 15.394379,
        },
        {
            obec: 'Opatov',
            kod: 591319,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67528,
            latitude: 49.224435,
            longitude: 15.661768,
        },
        {
            obec: 'Oponešice',
            kod: 591327,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 49.037839,
            longitude: 15.652148,
        },
        {
            obec: 'Ořechov',
            kod: 587630,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.200027,
            longitude: 15.532067,
        },
        {
            obec: 'Ořechov',
            kod: 596329,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59452,
            latitude: 49.350482,
            longitude: 16.139044,
        },
        {
            obec: 'Oslavice',
            kod: 596337,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.340872,
            longitude: 15.990807,
        },
        {
            obec: 'Oslavička',
            kod: 511412,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.315507,
            longitude: 15.966948,
        },
        {
            obec: 'Osová Bítýška',
            kod: 596345,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.325143,
            longitude: 16.168556,
        },
        {
            obec: 'Osové',
            kod: 596353,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.325323,
            longitude: 16.015528,
        },
        {
            obec: 'Ostašov',
            kod: 591335,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.155648,
            longitude: 15.918092,
        },
        {
            obec: 'Ostrov',
            kod: 548359,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.693104,
            longitude: 15.301494,
        },
        {
            obec: 'Ostrov nad Oslavou',
            kod: 596361,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59445,
            latitude: 49.486919,
            longitude: 15.990033,
        },
        {
            obec: 'Otín',
            kod: 587648,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.2666,
            longitude: 15.569796,
        },
        {
            obec: 'Otín',
            kod: 596370,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.362965,
            longitude: 15.906706,
        },
        {
            obec: 'Oudoleň',
            kod: 569224,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58224,
            latitude: 49.659429,
            longitude: 15.755752,
        },
        {
            obec: 'Ovesná Lhota',
            kod: 569232,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.722657,
            longitude: 15.38416,
        },
        {
            obec: 'Pacov',
            kod: 548511,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.470865,
            longitude: 15.001783,
        },
        {
            obec: 'Pálovice',
            kod: 550469,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.00562,
            longitude: 15.537655,
        },
        {
            obec: 'Panenská Rozsíčka',
            kod: 587656,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58901,
            latitude: 49.252661,
            longitude: 15.515001,
        },
        {
            obec: 'Panské Dubenky',
            kod: 587672,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 37853,
            latitude: 49.219628,
            longitude: 15.265918,
        },
        {
            obec: 'Pavlínov',
            kod: 596388,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.348216,
            longitude: 15.878564,
        },
        {
            obec: 'Pavlov',
            kod: 569241,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.699317,
            longitude: 15.332621,
        },
        {
            obec: 'Pavlov',
            kod: 587681,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.242892,
            longitude: 15.55721,
        },
        {
            obec: 'Pavlov',
            kod: 561916,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.398772,
            longitude: 15.244081,
        },
        {
            obec: 'Pavlov',
            kod: 596396,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.450847,
            longitude: 15.913945,
        },
        {
            obec: 'Pelhřimov',
            kod: 547492,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.430721,
            longitude: 15.223083,
        },
        {
            obec: 'Petráveč',
            kod: 596418,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.327579,
            longitude: 16.041249,
        },
        {
            obec: 'Petrovice',
            kod: 591360,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.241283,
            longitude: 15.78769,
        },
        {
            obec: 'Petrůvky',
            kod: 591378,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.159311,
            longitude: 15.902416,
        },
        {
            obec: 'Pikárec',
            kod: 549916,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59253,
            latitude: 49.432505,
            longitude: 16.122491,
        },
        {
            obec: 'Písečné',
            kod: 596434,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.568007,
            longitude: 16.23676,
        },
        {
            obec: 'Píšť',
            kod: 548545,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.601839,
            longitude: 15.203524,
        },
        {
            obec: 'Plandry',
            kod: 587702,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58841,
            latitude: 49.42009,
            longitude: 15.53837,
        },
        {
            obec: 'Počátky',
            kod: 548561,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39464,
            latitude: 49.260301,
            longitude: 15.240324,
        },
        {
            obec: 'Počítky',
            kod: 596442,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.588779,
            longitude: 15.971975,
        },
        {
            obec: 'Poděšín',
            kod: 596451,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.507768,
            longitude: 15.800993,
        },
        {
            obec: 'Podmoklany',
            kod: 548634,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58264,
            latitude: 49.721628,
            longitude: 15.767847,
        },
        {
            obec: 'Podmoky',
            kod: 548251,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.830381,
            longitude: 15.439055,
        },
        {
            obec: 'Podolí',
            kod: 596469,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.48436,
            longitude: 16.080553,
        },
        {
            obec: 'Pohled',
            kod: 569291,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58221,
            latitude: 49.606211,
            longitude: 15.648156,
        },
        {
            obec: 'Pohleď',
            kod: 573591,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.603572,
            longitude: 15.648294,
        },
        {
            obec: 'Pokojov',
            kod: 596477,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.481732,
            longitude: 15.906642,
        },
        {
            obec: 'Pokojovice',
            kod: 550370,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.219742,
            longitude: 15.742458,
        },
        {
            obec: 'Polesí',
            kod: 561436,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39468,
            latitude: 49.293206,
            longitude: 15.246599,
        },
        {
            obec: 'Police',
            kod: 591394,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67534,
            latitude: 49.21454,
            longitude: 15.883581,
        },
        {
            obec: 'Polná',
            kod: 587711,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.487099,
            longitude: 15.718914,
        },
        {
            obec: 'Polnička',
            kod: 596485,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59102,
            latitude: 49.607595,
            longitude: 15.916439,
        },
        {
            obec: 'Popůvky',
            kod: 591408,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67575,
            latitude: 49.159171,
            longitude: 16.127493,
        },
        {
            obec: 'Pošná',
            kod: 548600,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.458874,
            longitude: 15.042352,
        },
        {
            obec: 'Pozďatín',
            kod: 591416,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.235855,
            longitude: 16.037952,
        },
        {
            obec: 'Proseč',
            kod: 537829,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.600804,
            longitude: 15.329186,
        },
        {
            obec: 'Proseč pod Křemešníkem',
            kod: 561941,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.415347,
            longitude: 15.295046,
        },
        {
            obec: 'Prosetín',
            kod: 596493,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59264,
            latitude: 49.526397,
            longitude: 16.394946,
        },
        {
            obec: 'Prosíčka',
            kod: 569313,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.724592,
            longitude: 15.323639,
        },
        {
            obec: 'Přeckov',
            kod: 591424,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.275939,
            longitude: 15.9164,
        },
        {
            obec: 'Předín',
            kod: 591432,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67527,
            latitude: 49.198388,
            longitude: 15.673827,
        },
        {
            obec: 'Přešovice',
            kod: 591441,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67557,
            latitude: 49.050205,
            longitude: 16.061445,
        },
        {
            obec: 'Přibyslav',
            kod: 569321,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.576939,
            longitude: 15.73865,
        },
        {
            obec: 'Přibyslavice',
            kod: 591459,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.260738,
            longitude: 15.778435,
        },
        {
            obec: 'Příseka',
            kod: 569348,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.670936,
            longitude: 15.425737,
        },
        {
            obec: 'Příštpo',
            kod: 544752,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67551,
            latitude: 49.073941,
            longitude: 15.935004,
        },
        {
            obec: 'Pucov',
            kod: 591491,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.247148,
            longitude: 16.174175,
        },
        {
            obec: 'Puklice',
            kod: 587745,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58831,
            latitude: 49.374959,
            longitude: 15.700414,
        },
        {
            obec: 'Putimov',
            kod: 598755,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.41393,
            longitude: 15.268871,
        },
        {
            obec: 'Pyšel',
            kod: 591505,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.250609,
            longitude: 16.066539,
        },
        {
            obec: 'Rácovice',
            kod: 511307,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 49.02106,
            longitude: 15.687019,
        },
        {
            obec: 'Račice',
            kod: 591521,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.108129,
            longitude: 16.024703,
        },
        {
            obec: 'Račice',
            kod: 596507,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.497374,
            longitude: 16.139354,
        },
        {
            obec: 'Račín',
            kod: 588059,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59211,
            latitude: 49.617573,
            longitude: 15.866964,
        },
        {
            obec: 'Radenice',
            kod: 596515,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.427318,
            longitude: 16.064675,
        },
        {
            obec: 'Radešín',
            kod: 596523,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59255,
            latitude: 49.470362,
            longitude: 16.087846,
        },
        {
            obec: 'Radešínská Svratka',
            kod: 596531,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59233,
            latitude: 49.508546,
            longitude: 16.085143,
        },
        {
            obec: 'Radkov',
            kod: 587761,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.145306,
            longitude: 15.475336,
        },
        {
            obec: 'Radkov',
            kod: 549924,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59253,
            latitude: 49.423179,
            longitude: 16.158345,
        },
        {
            obec: 'Radkovice u Budče',
            kod: 550493,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 38001,
            latitude: 49.082593,
            longitude: 15.621844,
        },
        {
            obec: 'Radkovice u Hrotovic',
            kod: 591548,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67559,
            latitude: 49.070537,
            longitude: 16.007196,
        },
        {
            obec: 'Radňoves',
            kod: 596540,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.388717,
            longitude: 16.214074,
        },
        {
            obec: 'Radňovice',
            kod: 596558,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.56411,
            longitude: 16.034964,
        },
        {
            obec: 'Radonín',
            kod: 591556,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.279799,
            longitude: 15.72424,
        },
        {
            obec: 'Radostín',
            kod: 569364,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.661841,
            longitude: 15.545004,
        },
        {
            obec: 'Radostín',
            kod: 596566,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.652815,
            longitude: 15.875868,
        },
        {
            obec: 'Radostín nad Oslavou',
            kod: 596574,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.46203,
            longitude: 15.965213,
        },
        {
            obec: 'Radošov',
            kod: 591564,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.335141,
            longitude: 15.786546,
        },
        {
            obec: 'Radotice',
            kod: 545040,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 48.980111,
            longitude: 15.590214,
        },
        {
            obec: 'Rančířov',
            kod: 587176,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58601,
            latitude: 49.362563,
            longitude: 15.588563,
        },
        {
            obec: 'Rantířov',
            kod: 587788,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58841,
            latitude: 49.408348,
            longitude: 15.515264,
        },
        {
            obec: 'Rapotice',
            kod: 591581,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67573,
            latitude: 49.192313,
            longitude: 16.25337,
        },
        {
            obec: 'Rodinov',
            kod: 561789,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.282895,
            longitude: 15.103915,
        },
        {
            obec: 'Rodkov',
            kod: 587737,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.503956,
            longitude: 16.227678,
        },
        {
            obec: 'Rohozná',
            kod: 587796,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58844,
            latitude: 49.350316,
            longitude: 15.399269,
        },
        {
            obec: 'Rohy',
            kod: 591602,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.305639,
            longitude: 16.005703,
        },
        {
            obec: 'Rokytnice nad Rokytnou',
            kod: 591611,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67525,
            latitude: 49.184456,
            longitude: 15.77297,
        },
        {
            obec: 'Rosička',
            kod: 549941,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.538671,
            longitude: 15.842695,
        },
        {
            obec: 'Rouchovany',
            kod: 591629,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67557,
            latitude: 49.070461,
            longitude: 16.107705,
        },
        {
            obec: 'Rousměrov',
            kod: 596604,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.457769,
            longitude: 16.032622,
        },
        {
            obec: 'Rovečné',
            kod: 596612,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.577723,
            longitude: 16.362673,
        },
        {
            obec: 'Rovná',
            kod: 561274,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.510291,
            longitude: 15.129502,
        },
        {
            obec: 'Rozseč',
            kod: 587800,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58866,
            latitude: 49.155992,
            longitude: 15.616703,
        },
        {
            obec: 'Rozseč',
            kod: 596639,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.360238,
            longitude: 16.219462,
        },
        {
            obec: 'Rozsochatec',
            kod: 569399,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58272,
            latitude: 49.672641,
            longitude: 15.633903,
        },
        {
            obec: 'Rozsochy',
            kod: 596647,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59257,
            latitude: 49.520317,
            longitude: 16.200201,
        },
        {
            obec: 'Rožná',
            kod: 596655,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59252,
            latitude: 49.477368,
            longitude: 16.23859,
        },
        {
            obec: 'Ruda',
            kod: 596663,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.322584,
            longitude: 16.122796,
        },
        {
            obec: 'Rudíkov',
            kod: 591637,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.288117,
            longitude: 15.94605,
        },
        {
            obec: 'Rudolec',
            kod: 596671,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59214,
            latitude: 49.47692,
            longitude: 15.830304,
        },
        {
            obec: 'Rušinov',
            kod: 569402,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.791978,
            longitude: 15.683152,
        },
        {
            obec: 'Růžená',
            kod: 587818,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58901,
            latitude: 49.270494,
            longitude: 15.428507,
        },
        {
            obec: 'Rybné',
            kod: 587826,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.42295,
            longitude: 15.736398,
        },
        {
            obec: 'Rybníček',
            kod: 569411,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.76947,
            longitude: 15.509141,
        },
        {
            obec: 'Rynárec',
            kod: 562009,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39401,
            latitude: 49.389706,
            longitude: 15.234826,
        },
        {
            obec: 'Řásná',
            kod: 587834,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.221126,
            longitude: 15.391382,
        },
        {
            obec: 'Řečice',
            kod: 548715,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.602983,
            longitude: 15.371725,
        },
        {
            obec: 'Řečice',
            kod: 596680,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59233,
            latitude: 49.513093,
            longitude: 16.063959,
        },
        {
            obec: 'Řídelov',
            kod: 587851,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.234597,
            longitude: 15.406922,
        },
        {
            obec: 'Římov',
            kod: 591645,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.170178,
            longitude: 15.757671,
        },
        {
            obec: 'Salačova Lhota',
            kod: 548731,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.524136,
            longitude: 14.980329,
        },
        {
            obec: 'Samšín',
            kod: 548740,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.485662,
            longitude: 15.061812,
        },
        {
            obec: 'Sázava',
            kod: 596701,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59211,
            latitude: 49.565295,
            longitude: 15.928353,
        },
        {
            obec: 'Sázavka',
            kod: 569429,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58244,
            latitude: 49.734505,
            longitude: 15.411535,
        },
        {
            obec: 'Sazomín',
            kod: 588237,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.508871,
            longitude: 15.981076,
        },
        {
            obec: 'Sedlatice',
            kod: 587877,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.192397,
            longitude: 15.613497,
        },
        {
            obec: 'Sedlec',
            kod: 511081,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.167674,
            longitude: 16.134773,
        },
        {
            obec: 'Sedlejov',
            kod: 587885,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.22761,
            longitude: 15.495752,
        },
        {
            obec: 'Sedletín',
            kod: 548421,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.708551,
            longitude: 15.564631,
        },
        {
            obec: 'Sedlice',
            kod: 548766,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.515243,
            longitude: 15.256443,
        },
        {
            obec: 'Sejřek',
            kod: 596710,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59262,
            latitude: 49.436195,
            longitude: 16.309362,
        },
        {
            obec: 'Senožaty',
            kod: 548774,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39456,
            latitude: 49.569169,
            longitude: 15.198114,
        },
        {
            obec: 'Sirákov',
            kod: 596728,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.504907,
            longitude: 15.818313,
        },
        {
            obec: 'Sklené',
            kod: 596736,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.609496,
            longitude: 16.00697,
        },
        {
            obec: 'Sklené nad Oslavou',
            kod: 596744,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.438204,
            longitude: 16.057544,
        },
        {
            obec: 'Skorkov',
            kod: 548324,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58253,
            latitude: 49.50855,
            longitude: 15.476213,
        },
        {
            obec: 'Skorotice',
            kod: 596752,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59262,
            latitude: 49.457421,
            longitude: 16.359248,
        },
        {
            obec: 'Skryje',
            kod: 569461,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.843818,
            longitude: 15.487779,
        },
        {
            obec: 'Skřinářov',
            kod: 596761,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.347147,
            longitude: 16.1721,
        },
        {
            obec: 'Skuhrov',
            kod: 569470,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58241,
            latitude: 49.684765,
            longitude: 15.532234,
        },
        {
            obec: 'Slavětice',
            kod: 591688,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.1037,
            longitude: 16.108814,
        },
        {
            obec: 'Slavětín',
            kod: 548553,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58263,
            latitude: 49.668672,
            longitude: 15.772886,
        },
        {
            obec: 'Slavičky',
            kod: 591700,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67501,
            latitude: 49.17486,
            longitude: 15.967999,
        },
        {
            obec: 'Slavíkov',
            kod: 569488,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58265,
            latitude: 49.742924,
            longitude: 15.780817,
        },
        {
            obec: 'Slavíkovice',
            kod: 545180,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67531,
            latitude: 49.004391,
            longitude: 15.624453,
        },
        {
            obec: 'Slavníč',
            kod: 548341,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58255,
            latitude: 49.514827,
            longitude: 15.452365,
        },
        {
            obec: 'Sloupno',
            kod: 548499,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.73709,
            longitude: 15.749894,
        },
        {
            obec: 'Služátky',
            kod: 573604,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.684981,
            longitude: 15.431488,
        },
        {
            obec: 'Smrčná',
            kod: 550299,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58801,
            latitude: 49.471478,
            longitude: 15.537956,
        },
        {
            obec: 'Smrk',
            kod: 591726,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67501,
            latitude: 49.22568,
            longitude: 16.001974,
        },
        {
            obec: 'Sněžné',
            kod: 596787,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.645617,
            longitude: 16.121753,
        },
        {
            obec: 'Sobíňov',
            kod: 569518,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58262,
            latitude: 49.701095,
            longitude: 15.766977,
        },
        {
            obec: 'Spělkov',
            kod: 596795,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59203,
            latitude: 49.674861,
            longitude: 16.161048,
        },
        {
            obec: 'Stáj',
            kod: 587915,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.458085,
            longitude: 15.803502,
        },
        {
            obec: 'Stará Říše',
            kod: 587923,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58867,
            latitude: 49.179652,
            longitude: 15.594868,
        },
        {
            obec: 'Staré Bříště',
            kod: 561843,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.490839,
            longitude: 15.362636,
        },
        {
            obec: 'Stařeč',
            kod: 591742,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67522,
            latitude: 49.19793,
            longitude: 15.828017,
        },
        {
            obec: 'Stojčín',
            kod: 561452,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39468,
            latitude: 49.237781,
            longitude: 15.222001,
        },
        {
            obec: 'Stonařov',
            kod: 587931,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.282212,
            longitude: 15.585729,
        },
        {
            obec: 'Strachoňovice',
            kod: 587940,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.135205,
            longitude: 15.488129,
        },
        {
            obec: 'Strachujov',
            kod: 596809,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59242,
            latitude: 49.613204,
            longitude: 16.226861,
        },
        {
            obec: 'Stránecká Zhoř',
            kod: 596817,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59442,
            latitude: 49.380122,
            longitude: 15.927483,
        },
        {
            obec: 'Strážek',
            kod: 596825,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59253,
            latitude: 49.440836,
            longitude: 16.193422,
        },
        {
            obec: 'Stropešín',
            kod: 587460,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67555,
            latitude: 49.154144,
            longitude: 16.077352,
        },
        {
            obec: 'Stříbrné Hory',
            kod: 569534,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.601344,
            longitude: 15.691053,
        },
        {
            obec: 'Střítež',
            kod: 587958,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58811,
            latitude: 49.443643,
            longitude: 15.598749,
        },
        {
            obec: 'Střítež',
            kod: 537594,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.364014,
            longitude: 15.126619,
        },
        {
            obec: 'Střítež',
            kod: 554871,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.191417,
            longitude: 15.893355,
        },
        {
            obec: 'Střítež',
            kod: 549886,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59251,
            latitude: 49.440602,
            longitude: 16.256802,
        },
        {
            obec: 'Střítež pod Křemešníkem',
            kod: 561487,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.434133,
            longitude: 15.320258,
        },
        {
            obec: 'Studenec',
            kod: 591769,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67502,
            latitude: 49.20022,
            longitude: 16.064912,
        },
        {
            obec: 'Studnice',
            kod: 591777,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.291088,
            longitude: 16.029048,
        },
        {
            obec: 'Sudice',
            kod: 591785,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67573,
            latitude: 49.181993,
            longitude: 16.238291,
        },
        {
            obec: 'Suchá',
            kod: 587982,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58833,
            latitude: 49.301108,
            longitude: 15.577541,
        },
        {
            obec: 'Sulkovec',
            kod: 596833,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.60943,
            longitude: 16.309674,
        },
        {
            obec: 'Svatoslav',
            kod: 591793,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67507,
            latitude: 49.31921,
            longitude: 15.848599,
        },
        {
            obec: 'Svépravice',
            kod: 598763,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.495694,
            longitude: 15.232792,
        },
        {
            obec: 'Světlá nad Sázavou',
            kod: 569569,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.66811,
            longitude: 15.404038,
        },
        {
            obec: 'Světnov',
            kod: 596841,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59102,
            latitude: 49.620631,
            longitude: 15.955318,
        },
        {
            obec: 'Sviny',
            kod: 596850,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59451,
            latitude: 49.364289,
            longitude: 16.085112,
        },
        {
            obec: 'Svojkovice',
            kod: 587991,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.166246,
            longitude: 15.635608,
        },
        {
            obec: 'Svratka',
            kod: 596868,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59202,
            latitude: 49.710763,
            longitude: 16.032241,
        },
        {
            obec: 'Syrov',
            kod: 598771,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.578021,
            longitude: 15.177802,
        },
        {
            obec: 'Šebkovice',
            kod: 591807,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67545,
            latitude: 49.123204,
            longitude: 15.813715,
        },
        {
            obec: 'Šimanov',
            kod: 588008,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.458171,
            longitude: 15.450115,
        },
        {
            obec: 'Škrdlovice',
            kod: 596876,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.634772,
            longitude: 15.926038,
        },
        {
            obec: 'Šlapanov',
            kod: 569585,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58251,
            latitude: 49.542525,
            longitude: 15.657789,
        },
        {
            obec: 'Štěměchy',
            kod: 591815,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67527,
            latitude: 49.193393,
            longitude: 15.714768,
        },
        {
            obec: 'Štěpánov nad Svratkou',
            kod: 596884,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59263,
            latitude: 49.504688,
            longitude: 16.339224,
        },
        {
            obec: 'Štěpkov',
            kod: 591823,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.084115,
            longitude: 15.650302,
        },
        {
            obec: 'Štoky',
            kod: 569593,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58253,
            latitude: 49.502586,
            longitude: 15.588733,
        },
        {
            obec: 'Švábov',
            kod: 547255,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58851,
            latitude: 49.308525,
            longitude: 15.357131,
        },
        {
            obec: 'Tasov',
            kod: 591831,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67579,
            latitude: 49.288374,
            longitude: 16.094889,
        },
        {
            obec: 'Těchobuz',
            kod: 561355,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.510188,
            longitude: 14.930738,
        },
        {
            obec: 'Telč',
            kod: 588024,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.183338,
            longitude: 15.454373,
        },
        {
            obec: 'Těmice',
            kod: 548936,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39496,
            latitude: 49.351896,
            longitude: 15.060549,
        },
        {
            obec: 'Tis',
            kod: 569615,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58243,
            latitude: 49.705057,
            longitude: 15.493338,
        },
        {
            obec: 'Trnava',
            kod: 591840,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.254784,
            longitude: 15.924247,
        },
        {
            obec: 'Trpišovice',
            kod: 569623,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.655284,
            longitude: 15.332281,
        },
        {
            obec: 'Třebelovice',
            kod: 591858,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67532,
            latitude: 49.022254,
            longitude: 15.666335,
        },
        {
            obec: 'Třebenice',
            kod: 591866,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.163856,
            longitude: 16.015873,
        },
        {
            obec: 'Třebíč',
            kod: 590266,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.214887,
            longitude: 15.879652,
        },
        {
            obec: 'Třesov',
            kod: 550311,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67502,
            latitude: 49.180335,
            longitude: 16.082925,
        },
        {
            obec: 'Třešť',
            kod: 588032,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58901,
            latitude: 49.290971,
            longitude: 15.483401,
        },
        {
            obec: 'Třeštice',
            kod: 588041,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.245602,
            longitude: 15.453316,
        },
        {
            obec: 'Tři Studně',
            kod: 587753,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59204,
            latitude: 49.612941,
            longitude: 16.036598,
        },
        {
            obec: 'Ubušínek',
            kod: 549959,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.610581,
            longitude: 16.281794,
        },
        {
            obec: 'Uhelná Příbram',
            kod: 569640,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58245,
            latitude: 49.768087,
            longitude: 15.585655,
        },
        {
            obec: 'Úhořilka',
            kod: 548537,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58253,
            latitude: 49.524779,
            longitude: 15.530975,
        },
        {
            obec: 'Uhřínov',
            kod: 596906,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59441,
            latitude: 49.349931,
            longitude: 15.938057,
        },
        {
            obec: 'Ujčov',
            kod: 596914,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59262,
            latitude: 49.488459,
            longitude: 16.331188,
        },
        {
            obec: 'Újezd',
            kod: 596922,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59212,
            latitude: 49.509261,
            longitude: 15.870346,
        },
        {
            obec: 'Unčín',
            kod: 596931,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59242,
            latitude: 49.607762,
            longitude: 16.245786,
        },
        {
            obec: 'Urbanov',
            kod: 588067,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.214457,
            longitude: 15.514113,
        },
        {
            obec: 'Úsobí',
            kod: 569658,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58254,
            latitude: 49.5143,
            longitude: 15.502573,
        },
        {
            obec: 'Ústí',
            kod: 588075,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.47362,
            longitude: 15.414883,
        },
        {
            obec: 'Ústrašín',
            kod: 537608,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.382588,
            longitude: 15.16866,
        },
        {
            obec: 'Útěchovice',
            kod: 548987,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.478174,
            longitude: 15.116486,
        },
        {
            obec: 'Útěchovice pod Stražištěm',
            kod: 562033,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.538607,
            longitude: 15.0292,
        },
        {
            obec: 'Útěchovičky',
            kod: 537730,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.460685,
            longitude: 15.099772,
        },
        {
            obec: 'Valdíkov',
            kod: 587699,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67503,
            latitude: 49.247109,
            longitude: 15.985337,
        },
        {
            obec: 'Valeč',
            kod: 591874,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67553,
            latitude: 49.144404,
            longitude: 16.03579,
        },
        {
            obec: 'Vanov',
            kod: 588083,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.207777,
            longitude: 15.413736,
        },
        {
            obec: 'Vanůvek',
            kod: 588091,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.222589,
            longitude: 15.422729,
        },
        {
            obec: 'Vápovice',
            kod: 588105,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.154876,
            longitude: 15.580807,
        },
        {
            obec: 'Vatín',
            kod: 596949,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.525037,
            longitude: 15.96732,
        },
        {
            obec: 'Včelnička',
            kod: 549002,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.304506,
            longitude: 15.042508,
        },
        {
            obec: 'Věcov',
            kod: 596957,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59244,
            latitude: 49.616241,
            longitude: 16.170047,
        },
        {
            obec: 'Věchnov',
            kod: 596965,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.499464,
            longitude: 16.279089,
        },
        {
            obec: 'Velká Bíteš',
            kod: 596973,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59501,
            latitude: 49.288727,
            longitude: 16.225957,
        },
        {
            obec: 'Velká Chyška',
            kod: 549011,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39428,
            latitude: 49.511858,
            longitude: 15.037368,
        },
        {
            obec: 'Velká Losenice',
            kod: 596981,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59211,
            latitude: 49.580804,
            longitude: 15.836834,
        },
        {
            obec: 'Velké Janovice',
            kod: 596990,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.587942,
            longitude: 16.220152,
        },
        {
            obec: 'Velké Meziříčí',
            kod: 597007,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.356189,
            longitude: 16.013158,
        },
        {
            obec: 'Velké Tresné',
            kod: 587893,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.575246,
            longitude: 16.380585,
        },
        {
            obec: 'Velký Beranov',
            kod: 588113,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58821,
            latitude: 49.405141,
            longitude: 15.667107,
        },
        {
            obec: 'Velký Rybník',
            kod: 549045,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.490331,
            longitude: 15.308075,
        },
        {
            obec: 'Vepříkov',
            kod: 569674,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.744231,
            longitude: 15.571018,
        },
        {
            obec: 'Vepřová',
            kod: 597015,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59211,
            latitude: 49.612545,
            longitude: 15.82748,
        },
        {
            obec: 'Veselá',
            kod: 549053,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39470,
            latitude: 49.319162,
            longitude: 15.226841,
        },
        {
            obec: 'Veselý Žďár',
            kod: 569682,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.639737,
            longitude: 15.526155,
        },
        {
            obec: 'Věstín',
            kod: 597031,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59265,
            latitude: 49.566977,
            longitude: 16.340016,
        },
        {
            obec: 'Větrný Jeníkov',
            kod: 588121,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.475877,
            longitude: 15.478957,
        },
        {
            obec: 'Věž',
            kod: 569691,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58256,
            latitude: 49.563955,
            longitude: 15.459156,
        },
        {
            obec: 'Věžná',
            kod: 549061,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.414284,
            longitude: 14.988823,
        },
        {
            obec: 'Věžná',
            kod: 597040,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.460826,
            longitude: 16.271321,
        },
        {
            obec: 'Věžnice',
            kod: 569704,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58252,
            latitude: 49.514522,
            longitude: 15.695774,
        },
        {
            obec: 'Věžnice',
            kod: 588130,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58827,
            latitude: 49.480083,
            longitude: 15.695408,
        },
        {
            obec: 'Věžnička',
            kod: 588148,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.46043,
            longitude: 15.70555,
        },
        {
            obec: 'Vícenice',
            kod: 588342,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67602,
            latitude: 49.08913,
            longitude: 15.812238,
        },
        {
            obec: 'Vícenice u Náměště nad Oslavou',
            kod: 511242,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.202721,
            longitude: 16.126096,
        },
        {
            obec: 'Vídeň',
            kod: 597058,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59401,
            latitude: 49.392227,
            longitude: 16.03173,
        },
        {
            obec: 'Vidonín',
            kod: 597066,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59457,
            latitude: 49.379842,
            longitude: 16.222546,
        },
        {
            obec: 'Vílanec',
            kod: 588156,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58835,
            latitude: 49.334609,
            longitude: 15.576862,
        },
        {
            obec: 'Vilémov',
            kod: 569712,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58283,
            latitude: 49.815761,
            longitude: 15.53594,
        },
        {
            obec: 'Vilémovice',
            kod: 569721,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58401,
            latitude: 49.686513,
            longitude: 15.321935,
        },
        {
            obec: 'Vír',
            kod: 597074,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59266,
            latitude: 49.557484,
            longitude: 16.32369,
        },
        {
            obec: 'Víska',
            kod: 573582,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58301,
            latitude: 49.770412,
            longitude: 15.652667,
        },
        {
            obec: 'Vladislav',
            kod: 591904,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67501,
            latitude: 49.21027,
            longitude: 15.988403,
        },
        {
            obec: 'Vlachovice',
            kod: 587869,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.597135,
            longitude: 16.041266,
        },
        {
            obec: 'Vlčatín',
            kod: 591912,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67505,
            latitude: 49.305945,
            longitude: 15.949022,
        },
        {
            obec: 'Vlkanov',
            kod: 569739,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58291,
            latitude: 49.715726,
            longitude: 15.356843,
        },
        {
            obec: 'Vlkov',
            kod: 597082,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.324479,
            longitude: 16.200393,
        },
        {
            obec: 'Vojnův Městec',
            kod: 597091,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.679555,
            longitude: 15.878389,
        },
        {
            obec: 'Vojslavice',
            kod: 549126,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.593204,
            longitude: 15.229069,
        },
        {
            obec: 'Vokov',
            kod: 599239,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.395631,
            longitude: 15.219887,
        },
        {
            obec: 'Volevčice',
            kod: 588164,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.211082,
            longitude: 15.444727,
        },
        {
            obec: 'Výčapy',
            kod: 591939,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67401,
            latitude: 49.143644,
            longitude: 15.877648,
        },
        {
            obec: 'Vyklantice',
            kod: 549134,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39427,
            latitude: 49.554788,
            longitude: 15.040579,
        },
        {
            obec: 'Vyskytná',
            kod: 549142,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39405,
            latitude: 49.428265,
            longitude: 15.365624,
        },
        {
            obec: 'Vyskytná nad Jihlavou',
            kod: 588172,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58841,
            latitude: 49.419455,
            longitude: 15.508243,
        },
        {
            obec: 'Vysoká',
            kod: 530654,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.562373,
            longitude: 15.622295,
        },
        {
            obec: 'Vysoká Lhota',
            kod: 561771,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.416842,
            longitude: 15.042363,
        },
        {
            obec: 'Vysoké',
            kod: 588334,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.5819,
            longitude: 15.965315,
        },
        {
            obec: 'Vysoké Studnice',
            kod: 588181,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58821,
            latitude: 49.394112,
            longitude: 15.73023,
        },
        {
            obec: 'Vystrčenovice',
            kod: 588199,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.151246,
            longitude: 15.53056,
        },
        {
            obec: 'Vystrkov',
            kod: 561797,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39601,
            latitude: 49.51978,
            longitude: 15.348901,
        },
        {
            obec: 'Záblatí',
            kod: 597112,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59453,
            latitude: 49.320849,
            longitude: 16.166438,
        },
        {
            obec: 'Záborná',
            kod: 588202,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.486751,
            longitude: 15.756929,
        },
        {
            obec: 'Zadní Vydří',
            kod: 587231,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.134444,
            longitude: 15.417199,
        },
        {
            obec: 'Zadní Zhořec',
            kod: 597121,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.431175,
            longitude: 15.939309,
        },
        {
            obec: 'Zahrádka',
            kod: 591947,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67571,
            latitude: 49.244522,
            longitude: 16.098416,
        },
        {
            obec: 'Zachotín',
            kod: 549177,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.462434,
            longitude: 15.347987,
        },
        {
            obec: 'Zajíčkov',
            kod: 561975,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.378057,
            longitude: 15.258087,
        },
        {
            obec: 'Zárubice',
            kod: 591955,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67552,
            latitude: 49.124327,
            longitude: 15.981536,
        },
        {
            obec: 'Zašovice',
            kod: 591963,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67521,
            latitude: 49.258337,
            longitude: 15.72578,
        },
        {
            obec: 'Zbilidy',
            kod: 588211,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.443804,
            longitude: 15.422783,
        },
        {
            obec: 'Zbinohy',
            kod: 588229,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58842,
            latitude: 49.48738,
            longitude: 15.480494,
        },
        {
            obec: 'Zdeňkov',
            kod: 588245,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.140036,
            longitude: 15.62007,
        },
        {
            obec: 'Zhoř',
            kod: 588253,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58826,
            latitude: 49.4427,
            longitude: 15.771449,
        },
        {
            obec: 'Zhořec',
            kod: 561363,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.489741,
            longitude: 14.962556,
        },
        {
            obec: 'Zlátenka',
            kod: 598666,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39501,
            latitude: 49.423554,
            longitude: 15.058658,
        },
        {
            obec: 'Znětínek',
            kod: 597139,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59444,
            latitude: 49.469654,
            longitude: 15.926644,
        },
        {
            obec: 'Zubří',
            kod: 597147,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59231,
            latitude: 49.571209,
            longitude: 16.123791,
        },
        {
            obec: 'Zvěrkovice',
            kod: 591980,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67602,
            latitude: 49.02563,
            longitude: 15.863268,
        },
        {
            obec: 'Zvěstovice',
            kod: 548626,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58282,
            latitude: 49.848023,
            longitude: 15.512694,
        },
        {
            obec: 'Zvole',
            kod: 597155,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59256,
            latitude: 49.494545,
            longitude: 16.174948,
        },
        {
            obec: 'Zvolenovice',
            kod: 588261,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58856,
            latitude: 49.163385,
            longitude: 15.505675,
        },
        {
            obec: 'Žatec',
            kod: 588270,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58862,
            latitude: 49.206444,
            longitude: 15.509005,
        },
        {
            obec: 'Ždánice',
            kod: 597163,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59301,
            latitude: 49.54756,
            longitude: 16.262191,
        },
        {
            obec: 'Žďár nad Sázavou',
            kod: 595209,
            okres: 'Žďár nad Sázavou',
            kod_okres: 'CZ0635',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 59101,
            latitude: 49.564288,
            longitude: 15.939507,
        },
        {
            obec: 'Ždírec',
            kod: 530662,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58001,
            latitude: 49.629262,
            longitude: 15.642039,
        },
        {
            obec: 'Ždírec',
            kod: 588288,
            okres: 'Jihlava',
            kod_okres: 'CZ0632',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58813,
            latitude: 49.454999,
            longitude: 15.678575,
        },
        {
            obec: 'Ždírec nad Doubravou',
            kod: 569780,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58263,
            latitude: 49.696057,
            longitude: 15.813706,
        },
        {
            obec: 'Želetava',
            kod: 591998,
            okres: 'Třebíč',
            kod_okres: 'CZ0634',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 67526,
            latitude: 49.141973,
            longitude: 15.673101,
        },
        {
            obec: 'Želiv',
            kod: 549215,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39444,
            latitude: 49.529938,
            longitude: 15.221912,
        },
        {
            obec: 'Žirov',
            kod: 561908,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39301,
            latitude: 49.464349,
            longitude: 15.323704,
        },
        {
            obec: 'Žirovnice',
            kod: 549231,
            okres: 'Pelhřimov',
            kod_okres: 'CZ0633',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 39468,
            latitude: 49.253284,
            longitude: 15.18834,
        },
        {
            obec: 'Žižkovo Pole',
            kod: 569801,
            okres: 'Havlíčkův Brod',
            kod_okres: 'CZ0631',
            kraj: 'Kraj Vysočina',
            kod_kraj: 'CZ063',
            psc: 58222,
            latitude: 49.610734,
            longitude: 15.735236,
        },
    ],
    CZ042: [
        {
            obec: 'Arnoltice',
            kod: 562343,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40714,
            latitude: 50.837124,
            longitude: 14.264055,
        },
        {
            obec: 'Bečov',
            kod: 567043,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43526,
            latitude: 50.449824,
            longitude: 13.717955,
        },
        {
            obec: 'Bechlín',
            kod: 564575,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41186,
            latitude: 50.416252,
            longitude: 14.341026,
        },
        {
            obec: 'Bělušice',
            kod: 567051,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.449262,
            longitude: 13.762928,
        },
        {
            obec: 'Benešov nad Ploučnicí',
            kod: 562351,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40722,
            latitude: 50.741692,
            longitude: 14.3125,
        },
        {
            obec: 'Bílence',
            kod: 562980,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.424657,
            longitude: 13.50601,
        },
        {
            obec: 'Bílina',
            kod: 567451,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41801,
            latitude: 50.548647,
            longitude: 13.775459,
        },
        {
            obec: 'Bitozeves',
            kod: 565997,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.37326,
            longitude: 13.640793,
        },
        {
            obec: 'Blatno',
            kod: 562998,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.511799,
            longitude: 13.358172,
        },
        {
            obec: 'Blatno',
            kod: 566004,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43984,
            latitude: 50.097026,
            longitude: 13.389809,
        },
        {
            obec: 'Blažim',
            kod: 566012,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.40695,
            longitude: 13.628683,
        },
        {
            obec: 'Blšany',
            kod: 566021,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43988,
            latitude: 50.217441,
            longitude: 13.471313,
        },
        {
            obec: 'Blšany u Loun',
            kod: 542547,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.347086,
            longitude: 13.852757,
        },
        {
            obec: 'Bohušovice nad Ohří',
            kod: 564591,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41156,
            latitude: 50.493264,
            longitude: 14.150595,
        },
        {
            obec: 'Boleboř',
            kod: 563005,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43121,
            latitude: 50.540271,
            longitude: 13.413447,
        },
        {
            obec: 'Bořislav',
            kod: 567469,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.579462,
            longitude: 13.929313,
        },
        {
            obec: 'Braňany',
            kod: 567060,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43522,
            latitude: 50.543055,
            longitude: 13.700283,
        },
        {
            obec: 'Brandov',
            kod: 567078,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43547,
            latitude: 50.63211,
            longitude: 13.390831,
        },
        {
            obec: 'Brňany',
            kod: 564613,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.481073,
            longitude: 14.141967,
        },
        {
            obec: 'Brodec',
            kod: 546895,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.295025,
            longitude: 13.801725,
        },
        {
            obec: 'Brozany nad Ohří',
            kod: 564621,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41181,
            latitude: 50.453763,
            longitude: 14.145512,
        },
        {
            obec: 'Brzánky',
            kod: 546755,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.461941,
            longitude: 14.301296,
        },
        {
            obec: 'Březno',
            kod: 563013,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43145,
            latitude: 50.40228,
            longitude: 13.420942,
        },
        {
            obec: 'Bříza',
            kod: 564648,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.360615,
            longitude: 14.216366,
        },
        {
            obec: 'Břvany',
            kod: 566063,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.401253,
            longitude: 13.721648,
        },
        {
            obec: 'Budyně nad Ohří',
            kod: 564656,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41118,
            latitude: 50.404377,
            longitude: 14.126018,
        },
        {
            obec: 'Býčkovice',
            kod: 546780,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.559879,
            longitude: 14.213625,
        },
        {
            obec: 'Bynovec',
            kod: 544647,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.820934,
            longitude: 14.264287,
        },
        {
            obec: 'Bystřany',
            kod: 567477,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41761,
            latitude: 50.628776,
            longitude: 13.8643,
        },
        {
            obec: 'Bžany',
            kod: 567485,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.589151,
            longitude: 13.879624,
        },
        {
            obec: 'Cítoliby',
            kod: 542571,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43902,
            latitude: 50.332834,
            longitude: 13.812247,
        },
        {
            obec: 'Ctiněves',
            kod: 564672,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.374801,
            longitude: 14.307223,
        },
        {
            obec: 'Čeradice',
            kod: 530581,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.308851,
            longitude: 13.494015,
        },
        {
            obec: 'Černčice',
            kod: 542628,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43901,
            latitude: 50.361485,
            longitude: 13.845324,
        },
        {
            obec: 'Černěves',
            kod: 546852,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.454495,
            longitude: 14.243217,
        },
        {
            obec: 'Černiv',
            kod: 564699,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.445046,
            longitude: 14.059049,
        },
        {
            obec: 'Černouček',
            kod: 542423,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.360924,
            longitude: 14.307446,
        },
        {
            obec: 'Černovice',
            kod: 563021,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.447783,
            longitude: 13.359354,
        },
        {
            obec: 'Česká Kamenice',
            kod: 562394,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40721,
            latitude: 50.797908,
            longitude: 14.417784,
        },
        {
            obec: 'Český Jiřetín',
            kod: 567108,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43601,
            latitude: 50.70759,
            longitude: 13.547489,
        },
        {
            obec: 'Čížkovice',
            kod: 564711,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41112,
            latitude: 50.484284,
            longitude: 14.028506,
        },
        {
            obec: 'Děčany',
            kod: 564729,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.438699,
            longitude: 13.908578,
        },
        {
            obec: 'DĚČÍN',
            kod: 562335,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.772656,
            longitude: 14.212861,
        },
        {
            obec: 'Deštnice',
            kod: 566128,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.230738,
            longitude: 13.609915,
        },
        {
            obec: 'Dlažkovice',
            kod: 564737,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.465654,
            longitude: 13.96506,
        },
        {
            obec: 'Dobkovice',
            kod: 562408,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40703,
            latitude: 50.714949,
            longitude: 14.193259,
        },
        {
            obec: 'Dobrná',
            kod: 545783,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40741,
            latitude: 50.769646,
            longitude: 14.293376,
        },
        {
            obec: 'Dobroměřice',
            kod: 546861,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.370197,
            longitude: 13.794844,
        },
        {
            obec: 'Dobříň',
            kod: 564745,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.437691,
            longitude: 14.29389,
        },
        {
            obec: 'Doksany',
            kod: 564753,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41182,
            latitude: 50.454991,
            longitude: 14.16124,
        },
        {
            obec: 'Dolánky nad Ohří',
            kod: 553646,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.472956,
            longitude: 14.162716,
        },
        {
            obec: 'Dolní Habartice',
            kod: 545856,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.747281,
            longitude: 14.333391,
        },
        {
            obec: 'Dolní Podluží',
            kod: 562432,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40755,
            latitude: 50.879988,
            longitude: 14.595146,
        },
        {
            obec: 'Dolní Poustevna',
            kod: 562441,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40782,
            latitude: 50.982619,
            longitude: 14.286956,
        },
        {
            obec: 'Dolní Zálezly',
            kod: 567931,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40301,
            latitude: 50.597423,
            longitude: 14.04319,
        },
        {
            obec: 'Domašín',
            kod: 563048,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43151,
            latitude: 50.420806,
            longitude: 13.172313,
        },
        {
            obec: 'Domoušice',
            kod: 566152,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43968,
            latitude: 50.234257,
            longitude: 13.729627,
        },
        {
            obec: 'Doubice',
            kod: 530417,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40747,
            latitude: 50.888706,
            longitude: 14.461724,
        },
        {
            obec: 'Drahobuz',
            kod: 564770,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.525222,
            longitude: 14.320808,
        },
        {
            obec: 'Droužkovice',
            kod: 563056,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43144,
            latitude: 50.430887,
            longitude: 13.429205,
        },
        {
            obec: 'Dubí',
            kod: 567507,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41701,
            latitude: 50.681139,
            longitude: 13.788947,
        },
        {
            obec: 'Duchcov',
            kod: 567515,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41901,
            latitude: 50.603871,
            longitude: 13.746322,
        },
        {
            obec: 'Dušníky',
            kod: 564818,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.424637,
            longitude: 14.190273,
        },
        {
            obec: 'Evaň',
            kod: 564834,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.384155,
            longitude: 14.028989,
        },
        {
            obec: 'Františkov nad Ploučnicí',
            kod: 545899,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40723,
            latitude: 50.725809,
            longitude: 14.327018,
        },
        {
            obec: 'Habrovany',
            kod: 567957,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.598676,
            longitude: 13.981796,
        },
        {
            obec: 'Háj u Duchcova',
            kod: 567523,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41722,
            latitude: 50.630921,
            longitude: 13.712691,
        },
        {
            obec: 'Havraň',
            kod: 567141,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43501,
            latitude: 50.450149,
            longitude: 13.598881,
        },
        {
            obec: 'Heřmanov',
            kod: 562483,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.726587,
            longitude: 14.291315,
        },
        {
            obec: 'Hlinná',
            kod: 564842,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.572465,
            longitude: 14.10679,
        },
        {
            obec: 'Holedeč',
            kod: 566187,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.277534,
            longitude: 13.563098,
        },
        {
            obec: 'Homole u Panny',
            kod: 567973,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.62938,
            longitude: 14.186494,
        },
        {
            obec: 'Hora Svaté Kateřiny',
            kod: 567167,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43546,
            latitude: 50.606416,
            longitude: 13.436834,
        },
        {
            obec: 'Hora Svatého Šebestiána',
            kod: 563064,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43182,
            latitude: 50.510328,
            longitude: 13.251454,
        },
        {
            obec: 'Horní Beřkovice',
            kod: 564851,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41185,
            latitude: 50.358792,
            longitude: 14.346588,
        },
        {
            obec: 'Horní Habartice',
            kod: 545929,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.762175,
            longitude: 14.340721,
        },
        {
            obec: 'Horní Jiřetín',
            kod: 567175,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43543,
            latitude: 50.5732,
            longitude: 13.547284,
        },
        {
            obec: 'Horní Podluží',
            kod: 562505,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40757,
            latitude: 50.881485,
            longitude: 14.545731,
        },
        {
            obec: 'Horní Řepčice',
            kod: 546763,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.556722,
            longitude: 14.253325,
        },
        {
            obec: 'Hostomice',
            kod: 567531,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41752,
            latitude: 50.587806,
            longitude: 13.808142,
        },
        {
            obec: 'Hoštka',
            kod: 564877,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41172,
            latitude: 50.488694,
            longitude: 14.33511,
        },
        {
            obec: 'Hrob',
            kod: 567558,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41704,
            latitude: 50.659299,
            longitude: 13.726873,
        },
        {
            obec: 'Hrobce',
            kod: 564893,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41183,
            latitude: 50.462572,
            longitude: 14.230906,
        },
        {
            obec: 'Hrobčice',
            kod: 567566,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41757,
            latitude: 50.517448,
            longitude: 13.785716,
        },
        {
            obec: 'Hrušovany',
            kod: 563072,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43143,
            latitude: 50.387359,
            longitude: 13.498471,
        },
        {
            obec: 'Hřensko',
            kod: 562513,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40717,
            latitude: 50.87451,
            longitude: 14.242617,
        },
        {
            obec: 'Hříškov',
            kod: 566195,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43904,
            latitude: 50.291438,
            longitude: 13.864363,
        },
        {
            obec: 'Hřivice',
            kod: 566217,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43965,
            latitude: 50.288215,
            longitude: 13.730349,
        },
        {
            obec: 'Huntířov',
            kod: 562521,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.789539,
            longitude: 14.303537,
        },
        {
            obec: 'Chabařovice',
            kod: 568007,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40317,
            latitude: 50.673287,
            longitude: 13.941955,
        },
        {
            obec: 'Chbany',
            kod: 563081,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43157,
            latitude: 50.332881,
            longitude: 13.430488,
        },
        {
            obec: 'Chlumčany',
            kod: 566233,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43903,
            latitude: 50.335042,
            longitude: 13.843328,
        },
        {
            obec: 'Chlumec',
            kod: 568015,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40339,
            latitude: 50.699825,
            longitude: 13.939752,
        },
        {
            obec: 'Chodouny',
            kod: 564923,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41171,
            latitude: 50.47327,
            longitude: 14.250171,
        },
        {
            obec: 'Chodovlice',
            kod: 564931,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.463209,
            longitude: 13.993372,
        },
        {
            obec: 'Chomutov',
            kod: 562971,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43004,
            latitude: 50.463598,
            longitude: 13.410837,
        },
        {
            obec: 'Chotěšov',
            kod: 564940,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.439891,
            longitude: 14.085254,
        },
        {
            obec: 'Chotiměř',
            kod: 564958,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.548326,
            longitude: 14.000996,
        },
        {
            obec: 'Chotiněves',
            kod: 564966,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.552187,
            longitude: 14.279353,
        },
        {
            obec: 'Chožov',
            kod: 566241,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43922,
            latitude: 50.39887,
            longitude: 13.859103,
        },
        {
            obec: 'Chraberce',
            kod: 546429,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.408357,
            longitude: 13.829608,
        },
        {
            obec: 'Chřibská',
            kod: 562530,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40744,
            latitude: 50.863346,
            longitude: 14.483083,
        },
        {
            obec: 'Chuderov',
            kod: 568023,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.68875,
            longitude: 14.046285,
        },
        {
            obec: 'Chudoslavice',
            kod: 546810,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.583028,
            longitude: 14.187346,
        },
        {
            obec: 'Janov',
            kod: 544680,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.860304,
            longitude: 14.265378,
        },
        {
            obec: 'Janská',
            kod: 530395,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.80461,
            longitude: 14.363559,
        },
        {
            obec: 'Jenčice',
            kod: 505528,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.483044,
            longitude: 14.004486,
        },
        {
            obec: 'Jeníkov',
            kod: 567582,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41724,
            latitude: 50.628702,
            longitude: 13.749019,
        },
        {
            obec: 'Jetřichovice',
            kod: 562556,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40716,
            latitude: 50.852494,
            longitude: 14.394022,
        },
        {
            obec: 'Jílové',
            kod: 562564,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40701,
            latitude: 50.760925,
            longitude: 14.103948,
        },
        {
            obec: 'Jimlín',
            kod: 546011,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.320403,
            longitude: 13.747256,
        },
        {
            obec: 'Jirkov',
            kod: 563099,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43111,
            latitude: 50.500627,
            longitude: 13.448885,
        },
        {
            obec: 'Jiřetín pod Jedlovou',
            kod: 562572,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40756,
            latitude: 50.874552,
            longitude: 14.57519,
        },
        {
            obec: 'Jiříkov',
            kod: 562581,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40753,
            latitude: 50.993471,
            longitude: 14.568449,
        },
        {
            obec: 'Kadaň',
            kod: 563102,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43201,
            latitude: 50.376092,
            longitude: 13.271422,
        },
        {
            obec: 'Kalek',
            kod: 563111,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43132,
            latitude: 50.577837,
            longitude: 13.322071,
        },
        {
            obec: 'Kámen',
            kod: 546453,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40713,
            latitude: 50.810319,
            longitude: 14.268547,
        },
        {
            obec: 'Kamýk',
            kod: 546771,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.558481,
            longitude: 14.07749,
        },
        {
            obec: 'Keblice',
            kod: 565016,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.480149,
            longitude: 14.101914,
        },
        {
            obec: 'Kladruby',
            kod: 567604,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.615093,
            longitude: 13.826385,
        },
        {
            obec: 'Klapý',
            kod: 565024,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41116,
            latitude: 50.43154,
            longitude: 14.006583,
        },
        {
            obec: 'Klášterec nad Ohří',
            kod: 563129,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43151,
            latitude: 50.384561,
            longitude: 13.171393,
        },
        {
            obec: 'Kleneč',
            kod: 565032,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.392685,
            longitude: 14.248831,
        },
        {
            obec: 'Klíny',
            kod: 567191,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43601,
            latitude: 50.638349,
            longitude: 13.548232,
        },
        {
            obec: 'Korozluky',
            kod: 567221,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.478686,
            longitude: 13.723088,
        },
        {
            obec: 'Kostomlaty pod Milešovkou',
            kod: 567612,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41754,
            latitude: 50.560217,
            longitude: 13.873166,
        },
        {
            obec: 'Kostomlaty pod Řípem',
            kod: 565059,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.383135,
            longitude: 14.333177,
        },
        {
            obec: 'Košťany',
            kod: 567621,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41723,
            latitude: 50.655181,
            longitude: 13.755656,
        },
        {
            obec: 'Koštice',
            kod: 566284,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43921,
            latitude: 50.403319,
            longitude: 13.944037,
        },
        {
            obec: 'Kovářská',
            kod: 563137,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43186,
            latitude: 50.438324,
            longitude: 13.053997,
        },
        {
            obec: 'Kozly',
            kod: 530557,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.45686,
            longitude: 13.784625,
        },
        {
            obec: 'Krabčice',
            kod: 565067,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41187,
            latitude: 50.405474,
            longitude: 14.301425,
        },
        {
            obec: 'Krásná Lípa',
            kod: 562611,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40746,
            latitude: 50.913136,
            longitude: 14.510101,
        },
        {
            obec: 'Krásný Dvůr',
            kod: 566306,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43972,
            latitude: 50.254271,
            longitude: 13.367878,
        },
        {
            obec: 'Krupka',
            kod: 567639,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41741,
            latitude: 50.684596,
            longitude: 13.858264,
        },
        {
            obec: 'Kryry',
            kod: 566314,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43981,
            latitude: 50.174477,
            longitude: 13.426708,
        },
        {
            obec: 'Kryštofovy Hamry',
            kod: 563315,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43191,
            latitude: 50.499571,
            longitude: 13.136207,
        },
        {
            obec: 'Křesín',
            kod: 565075,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.394622,
            longitude: 13.980842,
        },
        {
            obec: 'Křešice',
            kod: 565083,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41148,
            latitude: 50.522692,
            longitude: 14.21461,
        },
        {
            obec: 'Křimov',
            kod: 563161,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.486132,
            longitude: 13.301587,
        },
        {
            obec: 'Kunratice',
            kod: 546330,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.818924,
            longitude: 14.41998,
        },
        {
            obec: 'Kyškovice',
            kod: 565091,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.448307,
            longitude: 14.285987,
        },
        {
            obec: 'Kytlice',
            kod: 562645,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40745,
            latitude: 50.812746,
            longitude: 14.53547,
        },
        {
            obec: 'Labská Stráň',
            kod: 544701,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.848754,
            longitude: 14.231709,
        },
        {
            obec: 'Lahošť',
            kod: 567647,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41725,
            latitude: 50.618346,
            longitude: 13.765404,
        },
        {
            obec: 'Ledvice',
            kod: 567655,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41772,
            latitude: 50.585119,
            longitude: 13.772139,
        },
        {
            obec: 'Lenešice',
            kod: 566322,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43923,
            latitude: 50.375267,
            longitude: 13.766007,
        },
        {
            obec: 'Levín',
            kod: 565105,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.613935,
            longitude: 14.284017,
        },
        {
            obec: 'Lhotka nad Labem',
            kod: 565113,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.525685,
            longitude: 14.047429,
        },
        {
            obec: 'Libčeves',
            kod: 566349,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43926,
            latitude: 50.452574,
            longitude: 13.838289,
        },
        {
            obec: 'Libědice',
            kod: 563188,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.315996,
            longitude: 13.385678,
        },
        {
            obec: 'Liběšice',
            kod: 565121,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41146,
            latitude: 50.568962,
            longitude: 14.289175,
        },
        {
            obec: 'Liběšice',
            kod: 566357,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43963,
            latitude: 50.293667,
            longitude: 13.621573,
        },
        {
            obec: 'Libkovice pod Řípem',
            kod: 565148,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.392532,
            longitude: 14.342424,
        },
        {
            obec: 'Libočany',
            kod: 530590,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43975,
            latitude: 50.33349,
            longitude: 13.513599,
        },
        {
            obec: 'Libochovany',
            kod: 565156,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41103,
            latitude: 50.566669,
            longitude: 14.039146,
        },
        {
            obec: 'Libochovice',
            kod: 565164,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41117,
            latitude: 50.406368,
            longitude: 14.044498,
        },
        {
            obec: 'Libořice',
            kod: 566381,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.254811,
            longitude: 13.515189,
        },
        {
            obec: 'Libotenice',
            kod: 565172,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.477022,
            longitude: 14.229354,
        },
        {
            obec: 'Libouchec',
            kod: 568058,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40335,
            latitude: 50.75865,
            longitude: 14.040826,
        },
        {
            obec: 'Lipno',
            kod: 566403,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.300702,
            longitude: 13.680972,
        },
        {
            obec: 'Lipová',
            kod: 562661,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40781,
            latitude: 51.011146,
            longitude: 14.360332,
        },
        {
            obec: 'Lišany',
            kod: 566411,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.344465,
            longitude: 13.642025,
        },
        {
            obec: 'Lišnice',
            kod: 567248,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.454552,
            longitude: 13.63312,
        },
        {
            obec: 'Líšťany',
            kod: 546879,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.312594,
            longitude: 13.800031,
        },
        {
            obec: 'Litoměřice',
            kod: 564567,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.53852,
            longitude: 14.130646,
        },
        {
            obec: 'Litvínov',
            kod: 567256,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43601,
            latitude: 50.598526,
            longitude: 13.610342,
        },
        {
            obec: 'Lkáň',
            kod: 546691,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.443126,
            longitude: 13.969778,
        },
        {
            obec: 'Lobendava',
            kod: 545708,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40784,
            latitude: 51.019574,
            longitude: 14.314344,
        },
        {
            obec: 'Lom',
            kod: 567264,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43511,
            latitude: 50.593384,
            longitude: 13.657403,
        },
        {
            obec: 'Loučná pod Klínovcem',
            kod: 546518,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43191,
            latitude: 50.420711,
            longitude: 12.986674,
        },
        {
            obec: 'Louka u Litvínova',
            kod: 567272,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43533,
            latitude: 50.587967,
            longitude: 13.636592,
        },
        {
            obec: 'Louny',
            kod: 565971,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.354081,
            longitude: 13.803455,
        },
        {
            obec: 'Lovečkovice',
            kod: 565211,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.621676,
            longitude: 14.263257,
        },
        {
            obec: 'Lovosice',
            kod: 565229,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.515143,
            longitude: 14.051142,
        },
        {
            obec: 'Lubenec',
            kod: 566438,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43983,
            latitude: 50.132182,
            longitude: 13.313301,
        },
        {
            obec: 'Ludvíkovice',
            kod: 546496,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40713,
            latitude: 50.794169,
            longitude: 14.256524,
        },
        {
            obec: 'Lukavec',
            kod: 565237,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.501745,
            longitude: 14.085804,
        },
        {
            obec: 'Lukov',
            kod: 546909,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41804,
            latitude: 50.528228,
            longitude: 13.885519,
        },
        {
            obec: 'Lužice',
            kod: 567281,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43524,
            latitude: 50.491802,
            longitude: 13.75425,
        },
        {
            obec: 'Malá Veleň',
            kod: 562700,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.741627,
            longitude: 14.270704,
        },
        {
            obec: 'Malé Březno',
            kod: 567299,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.462594,
            longitude: 13.560308,
        },
        {
            obec: 'Malé Březno',
            kod: 568091,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.672709,
            longitude: 14.172366,
        },
        {
            obec: 'Malé Žernoseky',
            kod: 565245,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.534078,
            longitude: 14.05484,
        },
        {
            obec: 'Malečov',
            kod: 568104,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40327,
            latitude: 50.632028,
            longitude: 14.119558,
        },
        {
            obec: 'Malíč',
            kod: 542491,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.543636,
            longitude: 14.082115,
        },
        {
            obec: 'Málkov',
            kod: 563200,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.446278,
            longitude: 13.333493,
        },
        {
            obec: 'Malšovice',
            kod: 562718,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.735259,
            longitude: 14.169817,
        },
        {
            obec: 'Mariánské Radčice',
            kod: 567302,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43532,
            latitude: 50.574142,
            longitude: 13.665101,
        },
        {
            obec: 'Markvartice',
            kod: 545678,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40742,
            latitude: 50.775633,
            longitude: 14.355383,
        },
        {
            obec: 'Martiněves',
            kod: 565253,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41119,
            latitude: 50.372805,
            longitude: 14.152928,
        },
        {
            obec: 'Mašťov',
            kod: 563218,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43156,
            latitude: 50.262382,
            longitude: 13.280572,
        },
        {
            obec: 'Měděnec',
            kod: 563226,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43184,
            latitude: 50.421723,
            longitude: 13.115541,
        },
        {
            obec: 'Měcholupy',
            kod: 566454,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43931,
            latitude: 50.26677,
            longitude: 13.537482,
        },
        {
            obec: 'Merboltice',
            kod: 545791,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.684307,
            longitude: 14.339205,
        },
        {
            obec: 'Měrunice',
            kod: 567698,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41804,
            latitude: 50.480523,
            longitude: 13.818088,
        },
        {
            obec: 'Meziboří',
            kod: 567311,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43513,
            latitude: 50.62126,
            longitude: 13.598813,
        },
        {
            obec: 'Michalovice',
            kod: 542521,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.536682,
            longitude: 14.082042,
        },
        {
            obec: 'Mikulášovice',
            kod: 562751,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40779,
            latitude: 50.965179,
            longitude: 14.363794,
        },
        {
            obec: 'Mikulov',
            kod: 567701,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41901,
            latitude: 50.687713,
            longitude: 13.721712,
        },
        {
            obec: 'Miřejovice',
            kod: 530506,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.553345,
            longitude: 14.109038,
        },
        {
            obec: 'Místo',
            kod: 563242,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43158,
            latitude: 50.447156,
            longitude: 13.26742,
        },
        {
            obec: 'Mlékojedy',
            kod: 565296,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.526999,
            longitude: 14.11933,
        },
        {
            obec: 'Mnetěš',
            kod: 565300,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.36565,
            longitude: 14.281839,
        },
        {
            obec: 'Modlany',
            kod: 567710,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41713,
            latitude: 50.650606,
            longitude: 13.895449,
        },
        {
            obec: 'Moldava',
            kod: 567728,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41781,
            latitude: 50.720738,
            longitude: 13.657342,
        },
        {
            obec: 'Most',
            kod: 567027,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.501655,
            longitude: 13.633012,
        },
        {
            obec: 'Mšené-lázně',
            kod: 565318,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41119,
            latitude: 50.362321,
            longitude: 14.127166,
        },
        {
            obec: 'Nepomyšl',
            kod: 566501,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43971,
            latitude: 50.218181,
            longitude: 13.313325,
        },
        {
            obec: 'Nezabylice',
            kod: 546160,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.424293,
            longitude: 13.478643,
        },
        {
            obec: 'Nová Ves',
            kod: 542636,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.285958,
            longitude: 13.842789,
        },
        {
            obec: 'Nová Ves v Horách',
            kod: 567329,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43545,
            latitude: 50.594847,
            longitude: 13.482558,
        },
        {
            obec: 'Nové Dvory',
            kod: 565342,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.447702,
            longitude: 14.179516,
        },
        {
            obec: 'Nové Sedlo',
            kod: 566519,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.339745,
            longitude: 13.474941,
        },
        {
            obec: 'Novosedlice',
            kod: 567752,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41731,
            latitude: 50.656439,
            longitude: 13.823154,
        },
        {
            obec: 'Obora',
            kod: 542580,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.373356,
            longitude: 13.863081,
        },
        {
            obec: 'Obrnice',
            kod: 567337,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43521,
            latitude: 50.505083,
            longitude: 13.695511,
        },
        {
            obec: 'Očihov',
            kod: 566527,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43987,
            latitude: 50.19866,
            longitude: 13.460534,
        },
        {
            obec: 'Ohníč',
            kod: 567761,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41765,
            latitude: 50.587135,
            longitude: 13.844786,
        },
        {
            obec: 'Okounov',
            kod: 563269,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43151,
            latitude: 50.362327,
            longitude: 13.106679,
        },
        {
            obec: 'Oleško',
            kod: 542440,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.480652,
            longitude: 14.196615,
        },
        {
            obec: 'Opočno',
            kod: 542555,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.311585,
            longitude: 13.735342,
        },
        {
            obec: 'Osek',
            kod: 567779,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41705,
            latitude: 50.622637,
            longitude: 13.691488,
        },
        {
            obec: 'Otvice',
            kod: 563277,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43111,
            latitude: 50.480923,
            longitude: 13.45081,
        },
        {
            obec: 'Panenský Týnec',
            kod: 566535,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43905,
            latitude: 50.295028,
            longitude: 13.917056,
        },
        {
            obec: 'Patokryje',
            kod: 567345,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.499915,
            longitude: 13.705297,
        },
        {
            obec: 'Perštejn',
            kod: 563285,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43163,
            latitude: 50.381769,
            longitude: 13.110315,
        },
        {
            obec: 'Peruc',
            kod: 566551,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43907,
            latitude: 50.342609,
            longitude: 13.959969,
        },
        {
            obec: 'Pesvice',
            kod: 546062,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43111,
            latitude: 50.463315,
            longitude: 13.484983,
        },
        {
            obec: 'Pětipsy',
            kod: 563293,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43153,
            latitude: 50.321219,
            longitude: 13.349824,
        },
        {
            obec: 'Petrohrad',
            kod: 566560,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43985,
            latitude: 50.127625,
            longitude: 13.446526,
        },
        {
            obec: 'Petrovice',
            kod: 568147,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40337,
            latitude: 50.789131,
            longitude: 13.976255,
        },
        {
            obec: 'Píšťany',
            kod: 542539,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41101,
            latitude: 50.519327,
            longitude: 14.069206,
        },
        {
            obec: 'Ploskovice',
            kod: 565393,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41142,
            latitude: 50.56034,
            longitude: 14.198588,
        },
        {
            obec: 'Pnětluky',
            kod: 566578,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.250358,
            longitude: 13.703895,
        },
        {
            obec: 'Počedělice',
            kod: 566586,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.371926,
            longitude: 13.887909,
        },
        {
            obec: 'Podbořanský Rohozec',
            kod: 566608,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44101,
            latitude: 50.216536,
            longitude: 13.262884,
        },
        {
            obec: 'Podbořany',
            kod: 566616,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44101,
            latitude: 50.229475,
            longitude: 13.41203,
        },
        {
            obec: 'Podsedice',
            kod: 565415,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.472089,
            longitude: 13.948396,
        },
        {
            obec: 'Polepy',
            kod: 565431,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41147,
            latitude: 50.50573,
            longitude: 14.264579,
        },
        {
            obec: 'Polerady',
            kod: 567353,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.444544,
            longitude: 13.652795,
        },
        {
            obec: 'Postoloprty',
            kod: 566624,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43942,
            latitude: 50.359895,
            longitude: 13.70302,
        },
        {
            obec: 'Povrly',
            kod: 568155,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40332,
            latitude: 50.672866,
            longitude: 14.160445,
        },
        {
            obec: 'Prackovice nad Labem',
            kod: 565458,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41133,
            latitude: 50.569522,
            longitude: 14.032075,
        },
        {
            obec: 'Proboštov',
            kod: 567787,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41712,
            latitude: 50.667833,
            longitude: 13.836123,
        },
        {
            obec: 'Přestanov',
            kod: 530620,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40317,
            latitude: 50.686777,
            longitude: 13.920379,
        },
        {
            obec: 'Přestavlky',
            kod: 565474,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.403615,
            longitude: 14.193965,
        },
        {
            obec: 'Račetice',
            kod: 546071,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.30526,
            longitude: 13.364964,
        },
        {
            obec: 'Račice',
            kod: 565482,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41108,
            latitude: 50.461398,
            longitude: 14.351181,
        },
        {
            obec: 'Račiněves',
            kod: 565491,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.373201,
            longitude: 14.218727,
        },
        {
            obec: 'Radonice',
            kod: 563323,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43155,
            latitude: 50.298139,
            longitude: 13.284757,
        },
        {
            obec: 'Radovesice',
            kod: 565521,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.41107,
            longitude: 14.068389,
        },
        {
            obec: 'Raná',
            kod: 566659,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43924,
            latitude: 50.411665,
            longitude: 13.779373,
        },
        {
            obec: 'Ročov',
            kod: 566667,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43967,
            latitude: 50.253498,
            longitude: 13.774466,
        },
        {
            obec: 'Rochov',
            kod: 565547,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.45949,
            longitude: 14.117546,
        },
        {
            obec: 'Rokle',
            kod: 563331,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43201,
            latitude: 50.35328,
            longitude: 13.29881,
        },
        {
            obec: 'Roudnice nad Labem',
            kod: 565555,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.425305,
            longitude: 14.260565,
        },
        {
            obec: 'Rtyně nad Bílinou',
            kod: 567809,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41762,
            latitude: 50.603629,
            longitude: 13.908818,
        },
        {
            obec: 'Rumburk',
            kod: 562777,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40801,
            latitude: 50.953525,
            longitude: 14.555255,
        },
        {
            obec: 'Růžová',
            kod: 566900,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.842265,
            longitude: 14.293898,
        },
        {
            obec: 'Rybniště',
            kod: 562793,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40751,
            latitude: 50.880068,
            longitude: 14.515148,
        },
        {
            obec: 'Ryjice',
            kod: 546186,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40331,
            latitude: 50.688055,
            longitude: 14.092111,
        },
        {
            obec: 'Řehlovice',
            kod: 568201,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40313,
            latitude: 50.607226,
            longitude: 13.954233,
        },
        {
            obec: 'Sedlec',
            kod: 546721,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.450166,
            longitude: 14.016401,
        },
        {
            obec: 'Siřejovice',
            kod: 565598,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.478454,
            longitude: 14.071012,
        },
        {
            obec: 'Skršín',
            kod: 567361,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.465729,
            longitude: 13.754506,
        },
        {
            obec: 'Slatina',
            kod: 565601,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.430683,
            longitude: 14.038155,
        },
        {
            obec: 'Slavětín',
            kod: 566713,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43909,
            latitude: 50.350713,
            longitude: 13.907818,
        },
        {
            obec: 'Smolnice',
            kod: 566721,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43914,
            latitude: 50.308774,
            longitude: 13.848572,
        },
        {
            obec: 'Snědovice',
            kod: 565610,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41174,
            latitude: 50.503633,
            longitude: 14.387857,
        },
        {
            obec: 'Spořice',
            kod: 563340,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43101,
            latitude: 50.44104,
            longitude: 13.391931,
        },
        {
            obec: 'Srbice',
            kod: 567833,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.660759,
            longitude: 13.869399,
        },
        {
            obec: 'Srbská Kamenice',
            kod: 546348,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40715,
            latitude: 50.820458,
            longitude: 14.352834,
        },
        {
            obec: 'Staňkovice',
            kod: 546828,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.589503,
            longitude: 14.167132,
        },
        {
            obec: 'Staňkovice',
            kod: 543128,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43949,
            latitude: 50.349927,
            longitude: 13.571151,
        },
        {
            obec: 'Staré Křečany',
            kod: 562823,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40761,
            latitude: 50.950546,
            longitude: 14.496283,
        },
        {
            obec: 'Starý Šachov',
            kod: 545538,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.715168,
            longitude: 14.360479,
        },
        {
            obec: 'Stebno',
            kod: 546925,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.615203,
            longitude: 14.01732,
        },
        {
            obec: 'Straškov-Vodochody',
            kod: 565679,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41184,
            latitude: 50.363689,
            longitude: 14.249643,
        },
        {
            obec: 'Strupčice',
            kod: 563358,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43114,
            latitude: 50.471126,
            longitude: 13.530649,
        },
        {
            obec: 'Sulejovice',
            kod: 565695,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41111,
            latitude: 50.498891,
            longitude: 14.037519,
        },
        {
            obec: 'Světec',
            kod: 567841,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41753,
            latitude: 50.576236,
            longitude: 13.811707,
        },
        {
            obec: 'Šluknov',
            kod: 562858,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40777,
            latitude: 51.003793,
            longitude: 14.452699,
        },
        {
            obec: 'Štětí',
            kod: 565709,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41108,
            latitude: 50.453087,
            longitude: 14.374322,
        },
        {
            obec: 'Tašov',
            kod: 568287,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.614624,
            longitude: 14.135906,
        },
        {
            obec: 'Těchlovice',
            kod: 555193,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.696428,
            longitude: 14.205036,
        },
        {
            obec: 'Telnice',
            kod: 568295,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40338,
            latitude: 50.733064,
            longitude: 13.957891,
        },
        {
            obec: 'Teplice',
            kod: 567442,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.644558,
            longitude: 13.835384,
        },
        {
            obec: 'Terezín',
            kod: 565717,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41155,
            latitude: 50.511102,
            longitude: 14.150658,
        },
        {
            obec: 'Tisá',
            kod: 568309,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40336,
            latitude: 50.784605,
            longitude: 14.031415,
        },
        {
            obec: 'Toužetín',
            kod: 566829,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.315391,
            longitude: 13.886229,
        },
        {
            obec: 'Travčice',
            kod: 565741,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.503389,
            longitude: 14.189878,
        },
        {
            obec: 'Trmice',
            kod: 553697,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40004,
            latitude: 50.642967,
            longitude: 13.994605,
        },
        {
            obec: 'Trnovany',
            kod: 542407,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.543317,
            longitude: 14.17905,
        },
        {
            obec: 'Třebenice',
            kod: 565768,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41113,
            latitude: 50.476429,
            longitude: 13.990159,
        },
        {
            obec: 'Třebívlice',
            kod: 565776,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41115,
            latitude: 50.45817,
            longitude: 13.899372,
        },
        {
            obec: 'Třebušín',
            kod: 565792,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.599744,
            longitude: 14.20553,
        },
        {
            obec: 'Tuchořice',
            kod: 566853,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43969,
            latitude: 50.28475,
            longitude: 13.662622,
        },
        {
            obec: 'Údlice',
            kod: 563382,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43141,
            latitude: 50.440706,
            longitude: 13.457492,
        },
        {
            obec: 'Úherce',
            kod: 546178,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.298161,
            longitude: 13.949094,
        },
        {
            obec: 'Újezdeček',
            kod: 567850,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.645818,
            longitude: 13.789867,
        },
        {
            obec: 'Úpohlavy',
            kod: 565806,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.463202,
            longitude: 14.035645,
        },
        {
            obec: 'Ústí nad Labem',
            kod: 554804,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40001,
            latitude: 50.661216,
            longitude: 14.053246,
        },
        {
            obec: 'Úštěk',
            kod: 565814,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41145,
            latitude: 50.585804,
            longitude: 14.34769,
        },
        {
            obec: 'Valkeřice',
            kod: 562874,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40724,
            latitude: 50.701933,
            longitude: 14.322006,
        },
        {
            obec: 'Varnsdorf',
            kod: 562882,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40747,
            latitude: 50.911648,
            longitude: 14.618348,
        },
        {
            obec: 'Vědomice',
            kod: 565831,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.43399,
            longitude: 14.254008,
        },
        {
            obec: 'Vejprty',
            kod: 563404,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43191,
            latitude: 50.492426,
            longitude: 13.032237,
        },
        {
            obec: 'Velemín',
            kod: 565849,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41131,
            latitude: 50.53909,
            longitude: 13.976864,
        },
        {
            obec: 'Velemyšleves',
            kod: 566870,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.402684,
            longitude: 13.563783,
        },
        {
            obec: 'Veliká Ves',
            kod: 563412,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44101,
            latitude: 50.269771,
            longitude: 13.37473,
        },
        {
            obec: 'Velká Bukovina',
            kod: 562891,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40729,
            latitude: 50.730538,
            longitude: 14.396877,
        },
        {
            obec: 'Velké Březno',
            kod: 568350,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40323,
            latitude: 50.662808,
            longitude: 14.141852,
        },
        {
            obec: 'Velké Chvojno',
            kod: 555223,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40334,
            latitude: 50.731698,
            longitude: 14.036815,
        },
        {
            obec: 'Velké Žernoseky',
            kod: 565857,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41201,
            latitude: 50.539512,
            longitude: 14.063884,
        },
        {
            obec: 'Velký Šenov',
            kod: 562912,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40778,
            latitude: 50.990885,
            longitude: 14.374393,
        },
        {
            obec: 'Veltěže',
            kod: 566896,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.353316,
            longitude: 13.875325,
        },
        {
            obec: 'Verneřice',
            kod: 562921,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40725,
            latitude: 50.661245,
            longitude: 14.301255,
        },
        {
            obec: 'Veselé',
            kod: 562939,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40502,
            latitude: 50.784784,
            longitude: 14.37384,
        },
        {
            obec: 'Vchynice',
            kod: 565865,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.510608,
            longitude: 14.020198,
        },
        {
            obec: 'Vilémov',
            kod: 562947,
            okres: 'Děčín',
            kod_okres: 'CZ0421',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40780,
            latitude: 50.990647,
            longitude: 14.335423,
        },
        {
            obec: 'Vilémov',
            kod: 563439,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43154,
            latitude: 50.301078,
            longitude: 13.312156,
        },
        {
            obec: 'Vinařice',
            kod: 566918,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43915,
            latitude: 50.265764,
            longitude: 13.822865,
        },
        {
            obec: 'Vlastislav',
            kod: 565873,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41114,
            latitude: 50.497071,
            longitude: 13.955542,
        },
        {
            obec: 'Volevčice',
            kod: 546437,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.434989,
            longitude: 13.690498,
        },
        {
            obec: 'Vražkov',
            kod: 565881,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.373533,
            longitude: 14.268153,
        },
        {
            obec: 'Vrbice',
            kod: 565890,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41164,
            latitude: 50.4833,
            longitude: 14.286605,
        },
        {
            obec: 'Vrbičany',
            kod: 565903,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41121,
            latitude: 50.462705,
            longitude: 14.084782,
        },
        {
            obec: 'Vrbno nad Lesy',
            kod: 566926,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43906,
            latitude: 50.32262,
            longitude: 13.907083,
        },
        {
            obec: 'Vroutek',
            kod: 566934,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43982,
            latitude: 50.180096,
            longitude: 13.37997,
        },
        {
            obec: 'Vrskmaň',
            kod: 563463,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43115,
            latitude: 50.488266,
            longitude: 13.495732,
        },
        {
            obec: 'Vršovice',
            kod: 546887,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.368582,
            longitude: 13.842119,
        },
        {
            obec: 'Vrutice',
            kod: 565911,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41147,
            latitude: 50.502216,
            longitude: 14.289857,
        },
        {
            obec: 'Všehrdy',
            kod: 563471,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43001,
            latitude: 50.420047,
            longitude: 13.461343,
        },
        {
            obec: 'Všestudy',
            kod: 563480,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43111,
            latitude: 50.457467,
            longitude: 13.507709,
        },
        {
            obec: 'Výsluní',
            kod: 563498,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43183,
            latitude: 50.466591,
            longitude: 13.237511,
        },
        {
            obec: 'Vysoká Pec',
            kod: 563501,
            okres: 'Chomutov',
            kod_okres: 'CZ0422',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43159,
            latitude: 50.520381,
            longitude: 13.470366,
        },
        {
            obec: 'Výškov',
            kod: 566951,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.394975,
            longitude: 13.670442,
        },
        {
            obec: 'Zabrušany',
            kod: 567868,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41771,
            latitude: 50.605057,
            longitude: 13.787596,
        },
        {
            obec: 'Záluží',
            kod: 542482,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41301,
            latitude: 50.460172,
            longitude: 14.32257,
        },
        {
            obec: 'Zálužice',
            kod: 530603,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.321307,
            longitude: 13.604272,
        },
        {
            obec: 'Zbrašín',
            kod: 566977,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.299412,
            longitude: 13.766026,
        },
        {
            obec: 'Zubrnice',
            kod: 568384,
            okres: 'Ústí nad Labem',
            kod_okres: 'CZ0427',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 40002,
            latitude: 50.649558,
            longitude: 14.220493,
        },
        {
            obec: 'Žabovřesky nad Ohří',
            kod: 565938,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41002,
            latitude: 50.413264,
            longitude: 14.091136,
        },
        {
            obec: 'Žalany',
            kod: 567876,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41763,
            latitude: 50.59013,
            longitude: 13.906447,
        },
        {
            obec: 'Žalhostice',
            kod: 565946,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41101,
            latitude: 50.523199,
            longitude: 14.091474,
        },
        {
            obec: 'Žatec',
            kod: 566985,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.327276,
            longitude: 13.545886,
        },
        {
            obec: 'Želenice',
            kod: 567426,
            okres: 'Most',
            kod_okres: 'CZ0425',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43401,
            latitude: 50.527718,
            longitude: 13.726169,
        },
        {
            obec: 'Želkovice',
            kod: 530565,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.464261,
            longitude: 13.876828,
        },
        {
            obec: 'Žerotín',
            kod: 543012,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 44001,
            latitude: 50.286331,
            longitude: 13.912212,
        },
        {
            obec: 'Židovice',
            kod: 565954,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41183,
            latitude: 50.446388,
            longitude: 14.233166,
        },
        {
            obec: 'Žim',
            kod: 567884,
            okres: 'Teplice',
            kod_okres: 'CZ0426',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41501,
            latitude: 50.585066,
            longitude: 13.965364,
        },
        {
            obec: 'Žitenice',
            kod: 565962,
            okres: 'Litoměřice',
            kod_okres: 'CZ0423',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 41141,
            latitude: 50.555387,
            longitude: 14.156757,
        },
        {
            obec: 'Žiželice',
            kod: 567019,
            okres: 'Louny',
            kod_okres: 'CZ0424',
            kraj: 'Ústecký kraj',
            kod_kraj: 'CZ042',
            psc: 43801,
            latitude: 50.367513,
            longitude: 13.540101,
        },
    ],
    CZ072: [
        {
            obec: 'Babice',
            kod: 592013,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68703,
            latitude: 49.123859,
            longitude: 17.476827,
        },
        {
            obec: 'Bánov',
            kod: 592021,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68754,
            latitude: 48.988113,
            longitude: 17.717631,
        },
        {
            obec: 'Bařice-Velké Těšany',
            kod: 588300,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.245527,
            longitude: 17.426201,
        },
        {
            obec: 'Bělov',
            kod: 588318,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.21609,
            longitude: 17.484879,
        },
        {
            obec: 'Bezměrov',
            kod: 588326,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.329903,
            longitude: 17.334894,
        },
        {
            obec: 'Bílovice',
            kod: 592030,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.099749,
            longitude: 17.549716,
        },
        {
            obec: 'Biskupice',
            kod: 585076,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76341,
            latitude: 49.083208,
            longitude: 17.710527,
        },
        {
            obec: 'Blazice',
            kod: 542318,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.44257,
            longitude: 17.645401,
        },
        {
            obec: 'Bohuslavice nad Vláří',
            kod: 557102,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.090273,
            longitude: 17.926421,
        },
        {
            obec: 'Bohuslavice u Zlína',
            kod: 585092,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76351,
            latitude: 49.163201,
            longitude: 17.636864,
        },
        {
            obec: 'Bojkovice',
            kod: 592048,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68771,
            latitude: 49.038744,
            longitude: 17.814983,
        },
        {
            obec: 'Boršice',
            kod: 592064,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68709,
            latitude: 49.062643,
            longitude: 17.350953,
        },
        {
            obec: 'Boršice u Blatnice',
            kod: 592056,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68763,
            latitude: 48.934962,
            longitude: 17.571104,
        },
        {
            obec: 'Bořenovice',
            kod: 549690,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.361618,
            longitude: 17.564472,
        },
        {
            obec: 'Branky',
            kod: 541648,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75645,
            latitude: 49.461056,
            longitude: 17.89306,
        },
        {
            obec: 'Bratřejov',
            kod: 585106,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.222558,
            longitude: 17.913427,
        },
        {
            obec: 'Brumov-Bylnice',
            kod: 585114,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76331,
            latitude: 49.092465,
            longitude: 18.022809,
        },
        {
            obec: 'Brusné',
            kod: 588377,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.36344,
            longitude: 17.660807,
        },
        {
            obec: 'Břest',
            kod: 588385,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76823,
            latitude: 49.351167,
            longitude: 17.440825,
        },
        {
            obec: 'Břestek',
            kod: 592072,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68708,
            latitude: 49.094985,
            longitude: 17.356023,
        },
        {
            obec: 'Březnice',
            kod: 538744,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76001,
            latitude: 49.184965,
            longitude: 17.656742,
        },
        {
            obec: 'Březolupy',
            kod: 592081,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68713,
            latitude: 49.121484,
            longitude: 17.580451,
        },
        {
            obec: 'Březová',
            kod: 592099,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68767,
            latitude: 48.922188,
            longitude: 17.742769,
        },
        {
            obec: 'Březová',
            kod: 585131,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 48.923332,
            longitude: 17.741218,
        },
        {
            obec: 'Březůvky',
            kod: 585149,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76345,
            latitude: 49.153427,
            longitude: 17.699728,
        },
        {
            obec: 'Buchlovice',
            kod: 592102,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68708,
            latitude: 49.086371,
            longitude: 17.338628,
        },
        {
            obec: 'Bystřice pod Hostýnem',
            kod: 588393,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.399337,
            longitude: 17.674121,
        },
        {
            obec: 'Bystřice pod Lopeníkem',
            kod: 592111,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68755,
            latitude: 48.974921,
            longitude: 17.764159,
        },
        {
            obec: 'Bystřička',
            kod: 541711,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75624,
            latitude: 49.415411,
            longitude: 17.974099,
        },
        {
            obec: 'Cetechovice',
            kod: 588407,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76802,
            latitude: 49.172391,
            longitude: 17.261484,
        },
        {
            obec: 'Částkov',
            kod: 592137,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.10453,
            longitude: 17.619917,
        },
        {
            obec: 'Dešná',
            kod: 585157,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.266709,
            longitude: 17.846028,
        },
        {
            obec: 'Dobrkovice',
            kod: 585165,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76307,
            latitude: 49.094555,
            longitude: 17.669619,
        },
        {
            obec: 'Dolní Bečva',
            kod: 541800,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75655,
            latitude: 49.455076,
            longitude: 18.194298,
        },
        {
            obec: 'Dolní Lhota',
            kod: 585173,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76323,
            latitude: 49.137271,
            longitude: 17.812463,
        },
        {
            obec: 'Dolní Němčí',
            kod: 592145,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68762,
            latitude: 48.968468,
            longitude: 17.585962,
        },
        {
            obec: 'Doubravy',
            kod: 585181,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76345,
            latitude: 49.142547,
            longitude: 17.668211,
        },
        {
            obec: 'Drnovice',
            kod: 585190,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76325,
            latitude: 49.17899,
            longitude: 17.957414,
        },
        {
            obec: 'Drslavice',
            kod: 592153,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68733,
            latitude: 49.047152,
            longitude: 17.600184,
        },
        {
            obec: 'Držková',
            kod: 585203,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76319,
            latitude: 49.319242,
            longitude: 17.78562,
        },
        {
            obec: 'Dřínov',
            kod: 588431,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.292989,
            longitude: 17.233486,
        },
        {
            obec: 'Francova Lhota',
            kod: 542644,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75614,
            latitude: 49.201841,
            longitude: 18.11186,
        },
        {
            obec: 'Fryšták',
            kod: 585211,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76316,
            latitude: 49.285304,
            longitude: 17.683572,
        },
        {
            obec: 'Halenkov',
            kod: 542679,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75603,
            latitude: 49.317501,
            longitude: 18.1476,
        },
        {
            obec: 'Halenkovice',
            kod: 585220,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76363,
            latitude: 49.171122,
            longitude: 17.471664,
        },
        {
            obec: 'Haluzice',
            kod: 585238,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76324,
            latitude: 49.137973,
            longitude: 17.897406,
        },
        {
            obec: 'Hluk',
            kod: 592170,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68725,
            latitude: 48.988219,
            longitude: 17.526788,
        },
        {
            obec: 'Holešov',
            kod: 588458,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.33341,
            longitude: 17.57843,
        },
        {
            obec: 'Honětice',
            kod: 542342,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76813,
            latitude: 49.203806,
            longitude: 17.253216,
        },
        {
            obec: 'Horní Bečva',
            kod: 542687,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75657,
            latitude: 49.43226,
            longitude: 18.288701,
        },
        {
            obec: 'Horní Lapač',
            kod: 588474,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.301023,
            longitude: 17.617522,
        },
        {
            obec: 'Horní Lhota',
            kod: 585246,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76323,
            latitude: 49.154412,
            longitude: 17.80455,
        },
        {
            obec: 'Horní Lideč',
            kod: 542725,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.181314,
            longitude: 18.061103,
        },
        {
            obec: 'Horní Němčí',
            kod: 592188,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68764,
            latitude: 48.933273,
            longitude: 17.624276,
        },
        {
            obec: 'Hostějov',
            kod: 592196,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68741,
            latitude: 49.040274,
            longitude: 17.257265,
        },
        {
            obec: 'Hostětín',
            kod: 550736,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68771,
            latitude: 49.050017,
            longitude: 17.879516,
        },
        {
            obec: 'Hostišová',
            kod: 585254,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76301,
            latitude: 49.253781,
            longitude: 17.591153,
        },
        {
            obec: 'Hošťálková',
            kod: 542750,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75622,
            latitude: 49.354774,
            longitude: 17.869548,
        },
        {
            obec: 'Hoštice',
            kod: 588482,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76813,
            latitude: 49.208645,
            longitude: 17.233888,
        },
        {
            obec: 'Hovězí',
            kod: 542768,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75601,
            latitude: 49.303745,
            longitude: 18.060734,
        },
        {
            obec: 'Hradčovice',
            kod: 592200,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68733,
            latitude: 49.049868,
            longitude: 17.582201,
        },
        {
            obec: 'Hrobice',
            kod: 585262,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.277494,
            longitude: 17.788329,
        },
        {
            obec: 'Hřivínův Újezd',
            kod: 585271,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76307,
            latitude: 49.121057,
            longitude: 17.690424,
        },
        {
            obec: 'Hulín',
            kod: 588491,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76824,
            latitude: 49.316992,
            longitude: 17.463848,
        },
        {
            obec: 'Huslenky',
            kod: 542784,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75602,
            latitude: 49.303274,
            longitude: 18.090479,
        },
        {
            obec: 'Huštěnovice',
            kod: 592218,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68703,
            latitude: 49.108271,
            longitude: 17.464577,
        },
        {
            obec: 'Hutisko-Solanec',
            kod: 542814,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75662,
            latitude: 49.429584,
            longitude: 18.217836,
        },
        {
            obec: 'Hvozdná',
            kod: 585289,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76311,
            latitude: 49.248581,
            longitude: 17.751742,
        },
        {
            obec: 'Chomýž',
            kod: 588504,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.362473,
            longitude: 17.645513,
        },
        {
            obec: 'Choryně',
            kod: 542831,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75642,
            latitude: 49.495846,
            longitude: 17.898722,
        },
        {
            obec: 'Chropyně',
            kod: 588512,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76811,
            latitude: 49.35654,
            longitude: 17.364618,
        },
        {
            obec: 'Chvalčov',
            kod: 506737,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76872,
            latitude: 49.389281,
            longitude: 17.711595,
        },
        {
            obec: 'Chvalnov-Lísky',
            kod: 588547,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76805,
            latitude: 49.166079,
            longitude: 17.231009,
        },
        {
            obec: 'Jablůnka',
            kod: 542865,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75623,
            latitude: 49.383651,
            longitude: 17.950317,
        },
        {
            obec: 'Jalubí',
            kod: 592226,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68705,
            latitude: 49.116359,
            longitude: 17.427985,
        },
        {
            obec: 'Jankovice',
            kod: 588555,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.35638,
            longitude: 17.618744,
        },
        {
            obec: 'Jankovice',
            kod: 592234,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68704,
            latitude: 49.154219,
            longitude: 17.388585,
        },
        {
            obec: 'Janová',
            kod: 570346,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75501,
            latitude: 49.312144,
            longitude: 18.020158,
        },
        {
            obec: 'Jarcová',
            kod: 542903,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75701,
            latitude: 49.444628,
            longitude: 17.965972,
        },
        {
            obec: 'Jarohněvice',
            kod: 588563,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76801,
            latitude: 49.267176,
            longitude: 17.377672,
        },
        {
            obec: 'Jasenná',
            kod: 585301,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76313,
            latitude: 49.253493,
            longitude: 17.894297,
        },
        {
            obec: 'Jestřabí',
            kod: 585319,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76333,
            latitude: 49.068135,
            longitude: 17.955981,
        },
        {
            obec: 'Kaňovice',
            kod: 585327,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76341,
            latitude: 49.109153,
            longitude: 17.698856,
        },
        {
            obec: 'Karlovice',
            kod: 587052,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76302,
            latitude: 49.176512,
            longitude: 17.585926,
        },
        {
            obec: 'Karolín',
            kod: 587354,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.230694,
            longitude: 17.43461,
        },
        {
            obec: 'Karolinka',
            kod: 542911,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75605,
            latitude: 49.351376,
            longitude: 18.240174,
        },
        {
            obec: 'Kašava',
            kod: 585343,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76319,
            latitude: 49.295509,
            longitude: 17.785771,
        },
        {
            obec: 'Kateřinice',
            kod: 542946,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75621,
            latitude: 49.382193,
            longitude: 17.885865,
        },
        {
            obec: 'Kelč',
            kod: 542989,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75643,
            latitude: 49.478541,
            longitude: 17.815441,
        },
        {
            obec: 'Kelníky',
            kod: 592251,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76307,
            latitude: 49.110985,
            longitude: 17.644771,
        },
        {
            obec: 'Kladeruby',
            kod: 542997,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75643,
            latitude: 49.492821,
            longitude: 17.865253,
        },
        {
            obec: 'Kněžpole',
            kod: 592269,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.098647,
            longitude: 17.516817,
        },
        {
            obec: 'Komárno',
            kod: 588598,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76871,
            latitude: 49.434675,
            longitude: 17.780479,
        },
        {
            obec: 'Komárov',
            kod: 549436,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76361,
            latitude: 49.150786,
            longitude: 17.568861,
        },
        {
            obec: 'Komňa',
            kod: 592277,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68771,
            latitude: 48.99393,
            longitude: 17.800925,
        },
        {
            obec: 'Koryčany',
            kod: 588601,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76805,
            latitude: 49.10649,
            longitude: 17.164435,
        },
        {
            obec: 'Korytná',
            kod: 592285,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68752,
            latitude: 48.94107,
            longitude: 17.665308,
        },
        {
            obec: 'Kostelany',
            kod: 588521,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.203174,
            longitude: 17.382954,
        },
        {
            obec: 'Kostelany nad Moravou',
            kod: 592293,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68601,
            latitude: 49.045476,
            longitude: 17.407042,
        },
        {
            obec: 'Kostelec u Holešova',
            kod: 588610,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76843,
            latitude: 49.374174,
            longitude: 17.511445,
        },
        {
            obec: 'Košíky',
            kod: 592307,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68704,
            latitude: 49.157681,
            longitude: 17.416297,
        },
        {
            obec: 'Krhová',
            kod: 500062,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75701,
            latitude: 49.465249,
            longitude: 17.97427,
        },
        {
            obec: 'Kroměříž',
            kod: 588296,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.291758,
            longitude: 17.39948,
        },
        {
            obec: 'Křekov',
            kod: 586960,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76601,
            latitude: 49.129596,
            longitude: 17.967873,
        },
        {
            obec: 'Kudlovice',
            kod: 592323,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68703,
            latitude: 49.131767,
            longitude: 17.457274,
        },
        {
            obec: 'Kunkovice',
            kod: 588628,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76813,
            latitude: 49.182821,
            longitude: 17.174357,
        },
        {
            obec: 'Kunovice',
            kod: 550744,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68604,
            latitude: 49.045088,
            longitude: 17.470215,
        },
        {
            obec: 'Kunovice',
            kod: 543021,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75644,
            latitude: 49.444343,
            longitude: 17.809991,
        },
        {
            obec: 'Kurovice',
            kod: 588636,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76852,
            latitude: 49.289235,
            longitude: 17.515668,
        },
        {
            obec: 'Kvasice',
            kod: 588644,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.242324,
            longitude: 17.469853,
        },
        {
            obec: 'Kyselovice',
            kod: 588652,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76811,
            latitude: 49.377252,
            longitude: 17.402164,
        },
        {
            obec: 'Lačnov',
            kod: 543098,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.178879,
            longitude: 18.016608,
        },
        {
            obec: 'Lechotice',
            kod: 588661,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76852,
            latitude: 49.273774,
            longitude: 17.588001,
        },
        {
            obec: 'Leskovec',
            kod: 544264,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.284384,
            longitude: 17.998777,
        },
        {
            obec: 'Lešná',
            kod: 544302,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75641,
            latitude: 49.520653,
            longitude: 17.930151,
        },
        {
            obec: 'Lhota',
            kod: 573434,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76302,
            latitude: 49.169435,
            longitude: 17.602533,
        },
        {
            obec: 'Lhota u Vsetína',
            kod: 556866,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75501,
            latitude: 49.304675,
            longitude: 17.958094,
        },
        {
            obec: 'Lhotsko',
            kod: 549550,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.212296,
            longitude: 17.883478,
        },
        {
            obec: 'Lidečko',
            kod: 544370,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.20313,
            longitude: 18.051411,
        },
        {
            obec: 'Lípa',
            kod: 549622,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76311,
            latitude: 49.215383,
            longitude: 17.768842,
        },
        {
            obec: 'Lipová',
            kod: 586871,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.121999,
            longitude: 17.879725,
        },
        {
            obec: 'Liptál',
            kod: 544396,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75501,
            latitude: 49.290608,
            longitude: 17.921879,
        },
        {
            obec: 'Litenčice',
            kod: 588695,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76813,
            latitude: 49.202303,
            longitude: 17.208524,
        },
        {
            obec: 'Lopeník',
            kod: 592340,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68767,
            latitude: 48.942882,
            longitude: 17.781225,
        },
        {
            obec: 'Loučka',
            kod: 544418,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75644,
            latitude: 49.438791,
            longitude: 17.832764,
        },
        {
            obec: 'Loučka',
            kod: 585432,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76325,
            latitude: 49.170133,
            longitude: 17.876186,
        },
        {
            obec: 'Loukov',
            kod: 588709,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76875,
            latitude: 49.421886,
            longitude: 17.721131,
        },
        {
            obec: 'Lubná',
            kod: 588717,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.220846,
            longitude: 17.398055,
        },
        {
            obec: 'Ludkovice',
            kod: 585441,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76341,
            latitude: 49.114836,
            longitude: 17.72672,
        },
        {
            obec: 'Ludslavice',
            kod: 588725,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76852,
            latitude: 49.299616,
            longitude: 17.540777,
        },
        {
            obec: 'Luhačovice',
            kod: 585459,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76326,
            latitude: 49.099922,
            longitude: 17.757583,
        },
        {
            obec: 'Lukov',
            kod: 585467,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76317,
            latitude: 49.290372,
            longitude: 17.729695,
        },
        {
            obec: 'Lukoveček',
            kod: 557145,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76316,
            latitude: 49.30359,
            longitude: 17.668809,
        },
        {
            obec: 'Lutonina',
            kod: 585483,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.238638,
            longitude: 17.882915,
        },
        {
            obec: 'Lutopecny',
            kod: 588733,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.301479,
            longitude: 17.344491,
        },
        {
            obec: 'Lužná',
            kod: 544434,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.239886,
            longitude: 18.019755,
        },
        {
            obec: 'Machová',
            kod: 585491,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76301,
            latitude: 49.254401,
            longitude: 17.545053,
        },
        {
            obec: 'Malá Bystřice',
            kod: 544469,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75627,
            latitude: 49.409504,
            longitude: 18.04863,
        },
        {
            obec: 'Martinice',
            kod: 588741,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.310408,
            longitude: 17.601188,
        },
        {
            obec: 'Medlovice',
            kod: 592366,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68741,
            latitude: 49.049125,
            longitude: 17.27186,
        },
        {
            obec: 'Mikulůvka',
            kod: 544507,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75624,
            latitude: 49.413614,
            longitude: 17.925167,
        },
        {
            obec: 'Mistřice',
            kod: 592382,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.086778,
            longitude: 17.536075,
        },
        {
            obec: 'Míškovice',
            kod: 588750,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76852,
            latitude: 49.276669,
            longitude: 17.549103,
        },
        {
            obec: 'Modrá',
            kod: 592391,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68706,
            latitude: 49.112121,
            longitude: 17.403508,
        },
        {
            obec: 'Morkovice-Slížany',
            kod: 588768,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.249941,
            longitude: 17.209733,
        },
        {
            obec: 'Mrlínek',
            kod: 553905,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.428534,
            longitude: 17.678364,
        },
        {
            obec: 'Mysločovice',
            kod: 585505,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76301,
            latitude: 49.256096,
            longitude: 17.566252,
        },
        {
            obec: 'Napajedla',
            kod: 585513,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76361,
            latitude: 49.171658,
            longitude: 17.512053,
        },
        {
            obec: 'Návojná',
            kod: 585521,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76332,
            latitude: 49.108405,
            longitude: 18.053057,
        },
        {
            obec: 'Nedachlebice',
            kod: 592404,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.099385,
            longitude: 17.589225,
        },
        {
            obec: 'Nedakonice',
            kod: 592412,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68738,
            latitude: 49.031694,
            longitude: 17.38152,
        },
        {
            obec: 'Nedašov',
            kod: 585530,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76332,
            latitude: 49.107884,
            longitude: 18.070468,
        },
        {
            obec: 'Nedašova Lhota',
            kod: 585548,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76332,
            latitude: 49.121404,
            longitude: 18.079714,
        },
        {
            obec: 'Němčice',
            kod: 588784,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76843,
            latitude: 49.364766,
            longitude: 17.497843,
        },
        {
            obec: 'Neubuz',
            kod: 585556,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.260516,
            longitude: 17.825454,
        },
        {
            obec: 'Nezdenice',
            kod: 592421,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68732,
            latitude: 49.016782,
            longitude: 17.752313,
        },
        {
            obec: 'Nítkovice',
            kod: 588806,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76813,
            latitude: 49.203726,
            longitude: 17.166004,
        },
        {
            obec: 'Nivnice',
            kod: 592439,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68751,
            latitude: 48.974744,
            longitude: 17.647683,
        },
        {
            obec: 'Nová Dědina',
            kod: 588814,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.210212,
            longitude: 17.453821,
        },
        {
            obec: 'Nový Hrozenkov',
            kod: 544566,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75604,
            latitude: 49.337068,
            longitude: 18.198046,
        },
        {
            obec: 'Oldřichovice',
            kod: 549444,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76361,
            latitude: 49.181867,
            longitude: 17.561448,
        },
        {
            obec: 'Ořechov',
            kod: 592447,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68737,
            latitude: 49.030686,
            longitude: 17.300934,
        },
        {
            obec: 'Osíčko',
            kod: 588822,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.431087,
            longitude: 17.751607,
        },
        {
            obec: 'Ostrata',
            kod: 557170,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76311,
            latitude: 49.271025,
            longitude: 17.76694,
        },
        {
            obec: 'Ostrožská Lhota',
            kod: 592455,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68723,
            latitude: 48.975689,
            longitude: 17.467622,
        },
        {
            obec: 'Ostrožská Nová Ves',
            kod: 592463,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68722,
            latitude: 49.004438,
            longitude: 17.436427,
        },
        {
            obec: 'Osvětimany',
            kod: 592471,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68742,
            latitude: 49.055871,
            longitude: 17.249789,
        },
        {
            obec: 'Otrokovice',
            kod: 585599,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76502,
            latitude: 49.210016,
            longitude: 17.530876,
        },
        {
            obec: 'Oznice',
            kod: 544574,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75624,
            latitude: 49.432567,
            longitude: 17.917399,
        },
        {
            obec: 'Pacetluky',
            kod: 588831,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76843,
            latitude: 49.379562,
            longitude: 17.56585,
        },
        {
            obec: 'Pačlavice',
            kod: 588849,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76834,
            latitude: 49.25977,
            longitude: 17.169012,
        },
        {
            obec: 'Pašovice',
            kod: 592480,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68756,
            latitude: 49.07963,
            longitude: 17.644446,
        },
        {
            obec: 'Petrůvka',
            kod: 556874,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.105005,
            longitude: 17.810083,
        },
        {
            obec: 'Pitín',
            kod: 592498,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68771,
            latitude: 49.038403,
            longitude: 17.850785,
        },
        {
            obec: 'Počenice-Tetětice',
            kod: 588865,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.271262,
            longitude: 17.228235,
        },
        {
            obec: 'Podhradí',
            kod: 534811,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76326,
            latitude: 49.140048,
            longitude: 17.77407,
        },
        {
            obec: 'Podhradní Lhota',
            kod: 588873,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76871,
            latitude: 49.420849,
            longitude: 17.79516,
        },
        {
            obec: 'Podkopná Lhota',
            kod: 585611,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76318,
            latitude: 49.305859,
            longitude: 17.831511,
        },
        {
            obec: 'Podolí',
            kod: 592501,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68604,
            latitude: 49.0409,
            longitude: 17.529564,
        },
        {
            obec: 'Podolí',
            kod: 569496,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75644,
            latitude: 49.430358,
            longitude: 17.857251,
        },
        {
            obec: 'Pohořelice',
            kod: 549461,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76361,
            latitude: 49.176665,
            longitude: 17.537954,
        },
        {
            obec: 'Polešovice',
            kod: 592510,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68737,
            latitude: 49.033999,
            longitude: 17.340746,
        },
        {
            obec: 'Police',
            kod: 544621,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75644,
            latitude: 49.45682,
            longitude: 17.867653,
        },
        {
            obec: 'Poličná',
            kod: 500071,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75701,
            latitude: 49.467058,
            longitude: 17.943924,
        },
        {
            obec: 'Popovice',
            kod: 592528,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68604,
            latitude: 49.052651,
            longitude: 17.527232,
        },
        {
            obec: 'Poteč',
            kod: 549533,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76601,
            latitude: 49.154482,
            longitude: 18.035541,
        },
        {
            obec: 'Pozděchov',
            kod: 544655,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.233333,
            longitude: 17.955546,
        },
        {
            obec: 'Pozlovice',
            kod: 549401,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76326,
            latitude: 49.129313,
            longitude: 17.769364,
        },
        {
            obec: 'Prakšice',
            kod: 592536,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68756,
            latitude: 49.069003,
            longitude: 17.633144,
        },
        {
            obec: 'Prasklice',
            kod: 587397,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.268795,
            longitude: 17.186821,
        },
        {
            obec: 'Pravčice',
            kod: 588890,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76824,
            latitude: 49.324587,
            longitude: 17.495591,
        },
        {
            obec: 'Prlov',
            kod: 544671,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.248001,
            longitude: 17.961456,
        },
        {
            obec: 'Prostřední Bečva',
            kod: 544698,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75656,
            latitude: 49.436192,
            longitude: 18.252106,
        },
        {
            obec: 'Provodov',
            kod: 585661,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76345,
            latitude: 49.160363,
            longitude: 17.736899,
        },
        {
            obec: 'Prusinovice',
            kod: 588903,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76842,
            latitude: 49.379083,
            longitude: 17.587212,
        },
        {
            obec: 'Pržno',
            kod: 544728,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75623,
            latitude: 49.387569,
            longitude: 17.942957,
        },
        {
            obec: 'Přílepy',
            kod: 549720,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.320079,
            longitude: 17.616021,
        },
        {
            obec: 'Racková',
            kod: 585670,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76001,
            latitude: 49.27717,
            longitude: 17.624991,
        },
        {
            obec: 'Rajnochovice',
            kod: 588920,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76871,
            latitude: 49.413088,
            longitude: 17.813268,
        },
        {
            obec: 'Rataje',
            kod: 588938,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76812,
            latitude: 49.2714,
            longitude: 17.335656,
        },
        {
            obec: 'Ratiboř',
            kod: 544787,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75621,
            latitude: 49.367793,
            longitude: 17.915097,
        },
        {
            obec: 'Rokytnice',
            kod: 556980,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.066239,
            longitude: 17.91147,
        },
        {
            obec: 'Roštění',
            kod: 588946,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76843,
            latitude: 49.367004,
            longitude: 17.538595,
        },
        {
            obec: 'Roštín',
            kod: 588954,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76803,
            latitude: 49.188888,
            longitude: 17.286157,
        },
        {
            obec: 'Rožnov pod Radhoštěm',
            kod: 544841,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75661,
            latitude: 49.458626,
            longitude: 18.143131,
        },
        {
            obec: 'Rudice',
            kod: 592552,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68732,
            latitude: 49.056153,
            longitude: 17.737584,
        },
        {
            obec: 'Rudimov',
            kod: 586919,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.079796,
            longitude: 17.828258,
        },
        {
            obec: 'Rusava',
            kod: 588962,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76841,
            latitude: 49.348527,
            longitude: 17.689193,
        },
        {
            obec: 'Růžďka',
            kod: 544850,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75625,
            latitude: 49.394052,
            longitude: 17.995701,
        },
        {
            obec: 'Rymice',
            kod: 588971,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.342008,
            longitude: 17.526316,
        },
        {
            obec: 'Salaš',
            kod: 592561,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68706,
            latitude: 49.140618,
            longitude: 17.342876,
        },
        {
            obec: 'Sazovice',
            kod: 585726,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76301,
            latitude: 49.240997,
            longitude: 17.568782,
        },
        {
            obec: 'Sehradice',
            kod: 585734,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76323,
            latitude: 49.144368,
            longitude: 17.824269,
        },
        {
            obec: 'Seninka',
            kod: 544906,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.273311,
            longitude: 17.96516,
        },
        {
            obec: 'Skaštice',
            kod: 588989,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.332412,
            longitude: 17.417495,
        },
        {
            obec: 'Slavičín',
            kod: 585751,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.088085,
            longitude: 17.873599,
        },
        {
            obec: 'Slavkov',
            kod: 592579,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68764,
            latitude: 48.946995,
            longitude: 17.611677,
        },
        {
            obec: 'Slavkov pod Hostýnem',
            kod: 588997,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.377245,
            longitude: 17.670437,
        },
        {
            obec: 'Slopné',
            kod: 585769,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76323,
            latitude: 49.157064,
            longitude: 17.847218,
        },
        {
            obec: 'Slušovice',
            kod: 585777,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.247918,
            longitude: 17.801607,
        },
        {
            obec: 'Soběsuky',
            kod: 589004,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76802,
            latitude: 49.233688,
            longitude: 17.359592,
        },
        {
            obec: 'Spytihněv',
            kod: 585793,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76364,
            latitude: 49.141251,
            longitude: 17.498191,
        },
        {
            obec: 'Staré Hutě',
            kod: 592587,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68601,
            latitude: 49.129212,
            longitude: 17.277842,
        },
        {
            obec: 'Staré Město',
            kod: 550752,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68603,
            latitude: 49.075248,
            longitude: 17.433488,
        },
        {
            obec: 'Starý Hrozenkov',
            kod: 592609,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68774,
            latitude: 48.965972,
            longitude: 17.86312,
        },
        {
            obec: 'Strání',
            kod: 592617,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68765,
            latitude: 48.902257,
            longitude: 17.706822,
        },
        {
            obec: 'Střelná',
            kod: 544914,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.177303,
            longitude: 18.097917,
        },
        {
            obec: 'Stříbrnice',
            kod: 592625,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68709,
            latitude: 49.05671,
            longitude: 17.307593,
        },
        {
            obec: 'Střílky',
            kod: 589039,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76804,
            latitude: 49.142634,
            longitude: 17.211177,
        },
        {
            obec: 'Střítež nad Bečvou',
            kod: 544922,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75652,
            latitude: 49.458663,
            longitude: 18.056978,
        },
        {
            obec: 'Střížovice',
            kod: 589047,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.257184,
            longitude: 17.449702,
        },
        {
            obec: 'Stupava',
            kod: 592633,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68601,
            latitude: 49.117266,
            longitude: 17.248767,
        },
        {
            obec: 'Suchá Loz',
            kod: 592641,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68753,
            latitude: 48.970109,
            longitude: 17.713899,
        },
        {
            obec: 'Sulimov',
            kod: 589055,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.226782,
            longitude: 17.422982,
        },
        {
            obec: 'Sušice',
            kod: 592650,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68704,
            latitude: 49.122346,
            longitude: 17.452331,
        },
        {
            obec: 'Svárov',
            kod: 592668,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.116539,
            longitude: 17.62464,
        },
        {
            obec: 'Šanov',
            kod: 585807,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76321,
            latitude: 49.045564,
            longitude: 17.898513,
        },
        {
            obec: 'Šarovy',
            kod: 585815,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76351,
            latitude: 49.148682,
            longitude: 17.606966,
        },
        {
            obec: 'Šelešovice',
            kod: 557188,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76701,
            latitude: 49.254875,
            longitude: 17.36013,
        },
        {
            obec: 'Štítná nad Vláří-Popov',
            kod: 585831,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76333,
            latitude: 49.070718,
            longitude: 17.972465,
        },
        {
            obec: 'Študlov',
            kod: 544931,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.162525,
            longitude: 18.083277,
        },
        {
            obec: 'Šumice',
            kod: 592676,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68731,
            latitude: 49.028682,
            longitude: 17.72216,
        },
        {
            obec: 'Tečovice',
            kod: 549649,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76302,
            latitude: 49.221167,
            longitude: 17.587443,
        },
        {
            obec: 'Tichov',
            kod: 535184,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76601,
            latitude: 49.177486,
            longitude: 17.982741,
        },
        {
            obec: 'Tlumačov',
            kod: 585858,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76362,
            latitude: 49.253605,
            longitude: 17.495694,
        },
        {
            obec: 'Topolná',
            kod: 592692,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68711,
            latitude: 49.121967,
            longitude: 17.544444,
        },
        {
            obec: 'Traplice',
            kod: 592706,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68704,
            latitude: 49.130976,
            longitude: 17.436325,
        },
        {
            obec: 'Trnava',
            kod: 585866,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76318,
            latitude: 49.296132,
            longitude: 17.842017,
        },
        {
            obec: 'Troubky-Zdislavice',
            kod: 589080,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76802,
            latitude: 49.230501,
            longitude: 17.268275,
        },
        {
            obec: 'Třebětice',
            kod: 589098,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.319485,
            longitude: 17.519351,
        },
        {
            obec: 'Tučapy',
            kod: 592714,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68709,
            latitude: 49.048213,
            longitude: 17.331526,
        },
        {
            obec: 'Tupesy',
            kod: 592722,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68707,
            latitude: 49.084369,
            longitude: 17.369935,
        },
        {
            obec: 'Ublo',
            kod: 585874,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.233156,
            longitude: 17.901231,
        },
        {
            obec: 'Uherské Hradiště',
            kod: 592005,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68601,
            latitude: 49.059897,
            longitude: 17.49595,
        },
        {
            obec: 'Uherský Brod',
            kod: 592731,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68801,
            latitude: 49.030373,
            longitude: 17.649938,
        },
        {
            obec: 'Uherský Ostroh',
            kod: 592749,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68724,
            latitude: 48.985657,
            longitude: 17.389953,
        },
        {
            obec: 'Uhřice',
            kod: 589110,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.281835,
            longitude: 17.201536,
        },
        {
            obec: 'Újezd',
            kod: 585882,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76325,
            latitude: 49.168187,
            longitude: 17.906215,
        },
        {
            obec: 'Újezdec',
            kod: 592757,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68741,
            latitude: 49.036216,
            longitude: 17.272063,
        },
        {
            obec: 'Ústí',
            kod: 570371,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75501,
            latitude: 49.308336,
            longitude: 18.003211,
        },
        {
            obec: 'Valašská Bystřice',
            kod: 544949,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75627,
            latitude: 49.415172,
            longitude: 18.109885,
        },
        {
            obec: 'Valašská Polanka',
            kod: 544990,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75611,
            latitude: 49.262249,
            longitude: 17.996783,
        },
        {
            obec: 'Valašská Senice',
            kod: 553026,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75614,
            latitude: 49.225377,
            longitude: 18.11706,
        },
        {
            obec: 'Valašské Klobouky',
            kod: 585891,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76601,
            latitude: 49.139664,
            longitude: 18.00857,
        },
        {
            obec: 'Valašské Meziříčí',
            kod: 545058,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75701,
            latitude: 49.471904,
            longitude: 17.971237,
        },
        {
            obec: 'Valašské Příkazy',
            kod: 545112,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75612,
            latitude: 49.165971,
            longitude: 18.053888,
        },
        {
            obec: 'Vápenice',
            kod: 592773,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68774,
            latitude: 48.967992,
            longitude: 17.843216,
        },
        {
            obec: 'Vážany',
            kod: 592781,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68737,
            latitude: 49.034552,
            longitude: 17.312035,
        },
        {
            obec: 'Velehrad',
            kod: 592790,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68706,
            latitude: 49.105527,
            longitude: 17.394372,
        },
        {
            obec: 'Veletiny',
            kod: 592803,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68733,
            latitude: 49.037101,
            longitude: 17.562531,
        },
        {
            obec: 'Velká Lhota',
            kod: 545147,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75701,
            latitude: 49.437895,
            longitude: 18.035719,
        },
        {
            obec: 'Velké Karlovice',
            kod: 545163,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75606,
            latitude: 49.360736,
            longitude: 18.283665,
        },
        {
            obec: 'Velký Ořechov',
            kod: 585912,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76307,
            latitude: 49.109464,
            longitude: 17.668544,
        },
        {
            obec: 'Veselá',
            kod: 585921,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.237591,
            longitude: 17.775561,
        },
        {
            obec: 'Věžky',
            kod: 589128,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76833,
            latitude: 49.28519,
            longitude: 17.281547,
        },
        {
            obec: 'Vidče',
            kod: 545198,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75653,
            latitude: 49.441612,
            longitude: 18.094836,
        },
        {
            obec: 'Vigantice',
            kod: 545210,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75661,
            latitude: 49.443885,
            longitude: 18.184136,
        },
        {
            obec: 'Vítonice',
            kod: 589136,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76861,
            latitude: 49.447233,
            longitude: 17.693071,
        },
        {
            obec: 'Vizovice',
            kod: 585939,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.223073,
            longitude: 17.854921,
        },
        {
            obec: 'Vlachova Lhota',
            kod: 586994,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76601,
            latitude: 49.149968,
            longitude: 17.953557,
        },
        {
            obec: 'Vlachovice',
            kod: 585955,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76324,
            latitude: 49.123872,
            longitude: 17.940124,
        },
        {
            obec: 'Vlčková',
            kod: 585963,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76319,
            latitude: 49.31062,
            longitude: 17.763428,
        },
        {
            obec: 'Vlčnov',
            kod: 592820,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68761,
            latitude: 49.009996,
            longitude: 17.581938,
        },
        {
            obec: 'Vrbka',
            kod: 542393,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76821,
            latitude: 49.228329,
            longitude: 17.413233,
        },
        {
            obec: 'Vsetín',
            kod: 541630,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75501,
            latitude: 49.339025,
            longitude: 17.993952,
        },
        {
            obec: 'Všemina',
            kod: 585971,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76315,
            latitude: 49.2811,
            longitude: 17.876932,
        },
        {
            obec: 'Vysoké Pole',
            kod: 585980,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76325,
            latitude: 49.177519,
            longitude: 17.93744,
        },
        {
            obec: 'Vyškovec',
            kod: 592838,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68774,
            latitude: 48.94083,
            longitude: 17.853445,
        },
        {
            obec: 'Zádveřice-Raková',
            kod: 585998,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76312,
            latitude: 49.216644,
            longitude: 17.805635,
        },
        {
            obec: 'Zahnašovice',
            kod: 589152,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.304651,
            longitude: 17.560195,
        },
        {
            obec: 'Záhorovice',
            kod: 592846,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68771,
            latitude: 49.022764,
            longitude: 17.779332,
        },
        {
            obec: 'Záříčí',
            kod: 589161,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76811,
            latitude: 49.382726,
            longitude: 17.352936,
        },
        {
            obec: 'Zástřizly',
            kod: 587257,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76805,
            latitude: 49.149944,
            longitude: 17.23521,
        },
        {
            obec: 'Zašová',
            kod: 545236,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75651,
            latitude: 49.474196,
            longitude: 18.044471,
        },
        {
            obec: 'Zborovice',
            kod: 589187,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76832,
            latitude: 49.248989,
            longitude: 17.284751,
        },
        {
            obec: 'Zděchov',
            kod: 545244,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75607,
            latitude: 49.261068,
            longitude: 18.077943,
        },
        {
            obec: 'Zdounky',
            kod: 589195,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76802,
            latitude: 49.227808,
            longitude: 17.319096,
        },
        {
            obec: 'Zlámanec',
            kod: 592854,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68712,
            latitude: 49.129954,
            longitude: 17.628309,
        },
        {
            obec: 'Zlechov',
            kod: 592862,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68710,
            latitude: 49.074059,
            longitude: 17.379491,
        },
        {
            obec: 'Zlín',
            kod: 585068,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76001,
            latitude: 49.224537,
            longitude: 17.662863,
        },
        {
            obec: 'Zlobice',
            kod: 589217,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76831,
            latitude: 49.301917,
            longitude: 17.312714,
        },
        {
            obec: 'Zubří',
            kod: 545252,
            okres: 'Vsetín',
            kod_okres: 'CZ0723',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 75654,
            latitude: 49.466126,
            longitude: 18.092595,
        },
        {
            obec: 'Žalkovice',
            kod: 589225,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76823,
            latitude: 49.372004,
            longitude: 17.436042,
        },
        {
            obec: 'Želechovice nad Dřevnicí',
            kod: 500011,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76311,
            latitude: 49.218113,
            longitude: 17.747486,
        },
        {
            obec: 'Žeranovice',
            kod: 589233,
            okres: 'Kroměříž',
            kod_okres: 'CZ0721',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76901,
            latitude: 49.291574,
            longitude: 17.604528,
        },
        {
            obec: 'Žítková',
            kod: 592871,
            okres: 'Uherské Hradiště',
            kod_okres: 'CZ0722',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 68774,
            latitude: 48.980354,
            longitude: 17.880344,
        },
        {
            obec: 'Žlutava',
            kod: 586013,
            okres: 'Zlín',
            kod_okres: 'CZ0724',
            kraj: 'Zlínský kraj',
            kod_kraj: 'CZ072',
            psc: 76361,
            latitude: 49.199857,
            longitude: 17.49048,
        },
    ],
    CZ032: [
        {
            obec: 'Babylon',
            kod: 553433,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.398761,
            longitude: 12.862893,
        },
        {
            obec: 'Bdeněves',
            kod: 566756,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33032,
            latitude: 49.76964,
            longitude: 13.235291,
        },
        {
            obec: 'Běhařov',
            kod: 541842,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.344879,
            longitude: 13.160291,
        },
        {
            obec: 'Bělá nad Radbuzou',
            kod: 553441,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34526,
            latitude: 49.591261,
            longitude: 12.717718,
        },
        {
            obec: 'Benešovice',
            kod: 560723,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.726964,
            longitude: 12.901799,
        },
        {
            obec: 'Běšiny',
            kod: 555797,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.301709,
            longitude: 13.312677,
        },
        {
            obec: 'Bezděkov',
            kod: 555801,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.3795,
            longitude: 13.297971,
        },
        {
            obec: 'Bezděkov',
            kod: 541095,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.824389,
            longitude: 13.596645,
        },
        {
            obec: 'Bezdružice',
            kod: 560740,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34953,
            latitude: 49.906771,
            longitude: 12.971351,
        },
        {
            obec: 'Bezvěrov',
            kod: 558656,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33041,
            latitude: 49.995118,
            longitude: 13.059113,
        },
        {
            obec: 'Bílov',
            kod: 530239,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 50.017964,
            longitude: 13.42495,
        },
        {
            obec: 'Biřkov',
            kod: 578088,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.518156,
            longitude: 13.230256,
        },
        {
            obec: 'Blatnice',
            kod: 558672,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33025,
            latitude: 49.719824,
            longitude: 13.156096,
        },
        {
            obec: 'Blažim',
            kod: 566764,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33038,
            latitude: 49.916024,
            longitude: 13.047724,
        },
        {
            obec: 'Blížejov',
            kod: 553450,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34545,
            latitude: 49.500115,
            longitude: 12.989366,
        },
        {
            obec: 'Blovice',
            kod: 557587,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.582303,
            longitude: 13.540199,
        },
        {
            obec: 'Bohy',
            kod: 566471,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.937892,
            longitude: 13.575531,
        },
        {
            obec: 'Bolešiny',
            kod: 555835,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.410002,
            longitude: 13.361712,
        },
        {
            obec: 'Bolkov',
            kod: 540463,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.533675,
            longitude: 13.223973,
        },
        {
            obec: 'Bor',
            kod: 560758,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34802,
            latitude: 49.711706,
            longitude: 12.775276,
        },
        {
            obec: 'Borovno',
            kod: 566705,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33561,
            latitude: 49.622087,
            longitude: 13.690481,
        },
        {
            obec: 'Borovy',
            kod: 542156,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.525208,
            longitude: 13.302442,
        },
        {
            obec: 'Brnířov',
            kod: 566136,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.382698,
            longitude: 13.04969,
        },
        {
            obec: 'Brod nad Tichou',
            kod: 541605,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.835396,
            longitude: 12.741203,
        },
        {
            obec: 'Brodeslavy',
            kod: 566489,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.953778,
            longitude: 13.557097,
        },
        {
            obec: 'Broumov',
            kod: 541401,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.889478,
            longitude: 12.606805,
        },
        {
            obec: 'Břasy',
            kod: 559725,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.837107,
            longitude: 13.578439,
        },
        {
            obec: 'Březina',
            kod: 559733,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.807642,
            longitude: 13.596901,
        },
        {
            obec: 'Břežany',
            kod: 541923,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.348864,
            longitude: 13.61746,
        },
        {
            obec: 'Bučí',
            kod: 558699,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.885275,
            longitude: 13.302879,
        },
        {
            obec: 'Budětice',
            kod: 555894,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.283469,
            longitude: 13.58477,
        },
        {
            obec: 'Bujesily',
            kod: 559741,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.914681,
            longitude: 13.574492,
        },
        {
            obec: 'Buková',
            kod: 546372,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33452,
            latitude: 49.537495,
            longitude: 13.158861,
        },
        {
            obec: 'Bukovec',
            kod: 553506,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.586461,
            longitude: 12.995414,
        },
        {
            obec: 'Bukovník',
            kod: 530085,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.222588,
            longitude: 13.661427,
        },
        {
            obec: 'Bušovice',
            kod: 559750,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.795629,
            longitude: 13.534766,
        },
        {
            obec: 'Cebiv',
            kod: 560782,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34952,
            latitude: 49.829449,
            longitude: 12.979175,
        },
        {
            obec: 'Cekov',
            kod: 559768,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.821968,
            longitude: 13.763901,
        },
        {
            obec: 'Ctiboř',
            kod: 579459,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.82733,
            longitude: 12.61012,
        },
        {
            obec: 'Čachrov',
            kod: 555941,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.265538,
            longitude: 13.302687,
        },
        {
            obec: 'Částkov',
            kod: 560804,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34801,
            latitude: 49.756077,
            longitude: 12.673799,
        },
        {
            obec: 'Čečovice',
            kod: 566080,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.585171,
            longitude: 13.021847,
        },
        {
            obec: 'Čeminy',
            kod: 558711,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.80007,
            longitude: 13.253217,
        },
        {
            obec: 'Čermná',
            kod: 553514,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.531701,
            longitude: 13.116707,
        },
        {
            obec: 'Černíkov',
            kod: 553531,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.422778,
            longitude: 13.13013,
        },
        {
            obec: 'Černíkovice',
            kod: 566632,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.975551,
            longitude: 13.56701,
        },
        {
            obec: 'Černošín',
            kod: 560812,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34958,
            latitude: 49.816245,
            longitude: 12.883925,
        },
        {
            obec: 'Černovice',
            kod: 566098,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.615661,
            longitude: 12.99704,
        },
        {
            obec: 'Čerňovice',
            kod: 530328,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33036,
            latitude: 49.813006,
            longitude: 13.103784,
        },
        {
            obec: 'Červené Poříčí',
            kod: 542172,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.501992,
            longitude: 13.294553,
        },
        {
            obec: 'Česká Bříza',
            kod: 558745,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.828513,
            longitude: 13.429819,
        },
        {
            obec: 'Česká Kubice',
            kod: 553549,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34532,
            latitude: 49.369488,
            longitude: 12.858904,
        },
        {
            obec: 'Číhaň',
            kod: 542024,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.34251,
            longitude: 13.425893,
        },
        {
            obec: 'Čilá',
            kod: 540927,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.961766,
            longitude: 13.742166,
        },
        {
            obec: 'Čímice',
            kod: 578495,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.253746,
            longitude: 13.605991,
        },
        {
            obec: 'Čižice',
            kod: 557641,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33209,
            latitude: 49.648043,
            longitude: 13.397992,
        },
        {
            obec: 'Čížkov',
            kod: 557650,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33564,
            latitude: 49.540479,
            longitude: 13.683496,
        },
        {
            obec: 'Čmelíny',
            kod: 578568,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.488923,
            longitude: 13.654594,
        },
        {
            obec: 'Dešenice',
            kod: 556041,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34022,
            latitude: 49.27441,
            longitude: 13.171052,
        },
        {
            obec: 'Díly',
            kod: 553557,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.446373,
            longitude: 12.786498,
        },
        {
            obec: 'Dlažov',
            kod: 556068,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.365855,
            longitude: 13.16645,
        },
        {
            obec: 'Dlouhá Ves',
            kod: 556076,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.196383,
            longitude: 13.50936,
        },
        {
            obec: 'Dlouhý Újezd',
            kod: 560839,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.768405,
            longitude: 12.62858,
        },
        {
            obec: 'Dnešice',
            kod: 557668,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33443,
            latitude: 49.60493,
            longitude: 13.264653,
        },
        {
            obec: 'Dobršín',
            kod: 530123,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.261401,
            longitude: 13.561152,
        },
        {
            obec: 'Dobřany',
            kod: 557676,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33441,
            latitude: 49.654933,
            longitude: 13.293177,
        },
        {
            obec: 'Dobříč',
            kod: 558770,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33005,
            latitude: 49.88466,
            longitude: 13.468475,
        },
        {
            obec: 'Dobřív',
            kod: 559776,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33844,
            latitude: 49.715763,
            longitude: 13.686921,
        },
        {
            obec: 'Dolany',
            kod: 556106,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.443368,
            longitude: 13.248222,
        },
        {
            obec: 'Dolany',
            kod: 558788,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.807924,
            longitude: 13.472466,
        },
        {
            obec: 'Dolce',
            kod: 539945,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.552967,
            longitude: 13.393513,
        },
        {
            obec: 'Dolní Bělá',
            kod: 558796,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.896264,
            longitude: 13.273369,
        },
        {
            obec: 'Dolní Hradiště',
            kod: 558800,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.913843,
            longitude: 13.49632,
        },
        {
            obec: 'Dolní Lukavice',
            kod: 557684,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33444,
            latitude: 49.602031,
            longitude: 13.34434,
        },
        {
            obec: 'Domažlice',
            kod: 553425,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.439803,
            longitude: 12.931243,
        },
        {
            obec: 'Domoraz',
            kod: 578517,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.250731,
            longitude: 13.662108,
        },
        {
            obec: 'Drahkov',
            kod: 540251,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.557261,
            longitude: 13.47254,
        },
        {
            obec: 'Drahoňův Újezd',
            kod: 530379,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.877208,
            longitude: 13.725945,
        },
        {
            obec: 'Drahotín',
            kod: 553573,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.52424,
            longitude: 12.758259,
        },
        {
            obec: 'Dražeň',
            kod: 578665,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33101,
            latitude: 49.926953,
            longitude: 13.287978,
        },
        {
            obec: 'Draženov',
            kod: 553581,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.455812,
            longitude: 12.872799,
        },
        {
            obec: 'Dražovice',
            kod: 566683,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.224775,
            longitude: 13.607113,
        },
        {
            obec: 'Druztová',
            kod: 558834,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33007,
            latitude: 49.797516,
            longitude: 13.448829,
        },
        {
            obec: 'Dýšina',
            kod: 558851,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33002,
            latitude: 49.777455,
            longitude: 13.491611,
        },
        {
            obec: 'Ejpovice',
            kod: 559792,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.74619,
            longitude: 13.514367,
        },
        {
            obec: 'Erpužice',
            kod: 560855,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.807058,
            longitude: 13.036075,
        },
        {
            obec: 'Frymburk',
            kod: 578525,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.249661,
            longitude: 13.707375,
        },
        {
            obec: 'Halže',
            kod: 560863,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.831133,
            longitude: 12.579508,
        },
        {
            obec: 'Hamry',
            kod: 578240,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34022,
            latitude: 49.215418,
            longitude: 13.160547,
        },
        {
            obec: 'Hartmanice',
            kod: 556181,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.169587,
            longitude: 13.454657,
        },
        {
            obec: 'Hejná',
            kod: 541796,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.28948,
            longitude: 13.6748,
        },
        {
            obec: 'Heřmanova Huť',
            kod: 558869,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.709118,
            longitude: 13.089275,
        },
        {
            obec: 'Hlavňovice',
            kod: 556203,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.237952,
            longitude: 13.395157,
        },
        {
            obec: 'Hlince',
            kod: 566390,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.948335,
            longitude: 13.625133,
        },
        {
            obec: 'Hlohová',
            kod: 553620,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.53414,
            longitude: 13.074684,
        },
        {
            obec: 'Hlohovčice',
            kod: 566641,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.52085,
            longitude: 13.113875,
        },
        {
            obec: 'Hlohovice',
            kod: 559806,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.889578,
            longitude: 13.643448,
        },
        {
            obec: 'Hnačov',
            kod: 542083,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34034,
            latitude: 49.361927,
            longitude: 13.48178,
        },
        {
            obec: 'Hněvnice',
            kod: 530221,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.728607,
            longitude: 13.103241,
        },
        {
            obec: 'Holoubkov',
            kod: 559814,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33801,
            latitude: 49.776089,
            longitude: 13.692566,
        },
        {
            obec: 'Holovousy',
            kod: 566730,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.96798,
            longitude: 13.614819,
        },
        {
            obec: 'Holýšov',
            kod: 553654,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.593721,
            longitude: 13.101403,
        },
        {
            obec: 'Honezovice',
            kod: 540668,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.638318,
            longitude: 13.062664,
        },
        {
            obec: 'Hora Svatého Václava',
            kod: 566276,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.518986,
            longitude: 12.739958,
        },
        {
            obec: 'Horažďovice',
            kod: 556254,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.320794,
            longitude: 13.701106,
        },
        {
            obec: 'Horní Bělá',
            kod: 558877,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.889081,
            longitude: 13.264218,
        },
        {
            obec: 'Horní Bříza',
            kod: 558885,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33012,
            latitude: 49.84023,
            longitude: 13.355689,
        },
        {
            obec: 'Horní Kamenice',
            kod: 553662,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.569701,
            longitude: 13.101163,
        },
        {
            obec: 'Horní Kozolupy',
            kod: 541290,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34952,
            latitude: 49.837622,
            longitude: 12.941751,
        },
        {
            obec: 'Horní Lukavice',
            kod: 539821,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.612427,
            longitude: 13.32708,
        },
        {
            obec: 'Horská Kvilda',
            kod: 542091,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 38501,
            latitude: 49.057721,
            longitude: 13.558075,
        },
        {
            obec: 'Horšice',
            kod: 557722,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33455,
            latitude: 49.531575,
            longitude: 13.386298,
        },
        {
            obec: 'Horšovský Týn',
            kod: 553671,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.530286,
            longitude: 12.944527,
        },
        {
            obec: 'Hostouň',
            kod: 553689,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34525,
            latitude: 49.559821,
            longitude: 12.771578,
        },
        {
            obec: 'Hošťka',
            kod: 560898,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34806,
            latitude: 49.696306,
            longitude: 12.597568,
        },
        {
            obec: 'Hradec',
            kod: 557731,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33211,
            latitude: 49.635159,
            longitude: 13.121483,
        },
        {
            obec: 'Hrádek',
            kod: 556301,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.261381,
            longitude: 13.498603,
        },
        {
            obec: 'Hrádek',
            kod: 559822,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33842,
            latitude: 49.710027,
            longitude: 13.65421,
        },
        {
            obec: 'Hradešice',
            kod: 556319,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.323206,
            longitude: 13.596547,
        },
        {
            obec: 'Hradiště',
            kod: 566179,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.464875,
            longitude: 13.051832,
        },
        {
            obec: 'Hradiště',
            kod: 557749,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33544,
            latitude: 49.439432,
            longitude: 13.756199,
        },
        {
            obec: 'Hradiště',
            kod: 541001,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.963121,
            longitude: 13.717739,
        },
        {
            obec: 'Hromnice',
            kod: 558915,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.849175,
            longitude: 13.44157,
        },
        {
            obec: 'Hůrky',
            kod: 559849,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.745983,
            longitude: 13.683207,
        },
        {
            obec: 'Hvozd',
            kod: 558931,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33101,
            latitude: 49.939841,
            longitude: 13.26323,
        },
        {
            obec: 'Hvožďany',
            kod: 566331,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.505677,
            longitude: 12.771387,
        },
        {
            obec: 'Chanovice',
            kod: 556335,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.405001,
            longitude: 13.717463,
        },
        {
            obec: 'Cheznovice',
            kod: 559857,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33806,
            latitude: 49.779032,
            longitude: 13.785491,
        },
        {
            obec: 'Chlistov',
            kod: 556343,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.320001,
            longitude: 13.360983,
        },
        {
            obec: 'Chlum',
            kod: 557773,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.605305,
            longitude: 13.479305,
        },
        {
            obec: 'Chlum',
            kod: 540803,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.935789,
            longitude: 13.65979,
        },
        {
            obec: 'Chlumčany',
            kod: 557781,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33442,
            latitude: 49.632666,
            longitude: 13.313337,
        },
        {
            obec: 'Chlumy',
            kod: 540307,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.43786,
            longitude: 13.638713,
        },
        {
            obec: 'Chocenice',
            kod: 557803,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.547648,
            longitude: 13.520152,
        },
        {
            obec: 'Chocomyšl',
            kod: 553727,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.467282,
            longitude: 13.13077,
        },
        {
            obec: 'Chodov',
            kod: 553735,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34533,
            latitude: 49.418088,
            longitude: 12.830346,
        },
        {
            obec: 'Chodová Planá',
            kod: 560901,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34813,
            latitude: 49.893323,
            longitude: 12.730254,
        },
        {
            obec: 'Chodská Lhota',
            kod: 553743,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.358456,
            longitude: 13.083271,
        },
        {
            obec: 'Chodský Újezd',
            kod: 560910,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.864425,
            longitude: 12.650047,
        },
        {
            obec: 'Chomle',
            kod: 541141,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.86238,
            longitude: 13.63461,
        },
        {
            obec: 'Chotěšov',
            kod: 557838,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33214,
            latitude: 49.65419,
            longitude: 13.202822,
        },
        {
            obec: 'Chotíkov',
            kod: 558940,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33017,
            latitude: 49.793052,
            longitude: 13.317774,
        },
        {
            obec: 'Chrást',
            kod: 558966,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33003,
            latitude: 49.793305,
            longitude: 13.49369,
        },
        {
            obec: 'Chrastavice',
            kod: 553751,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.453663,
            longitude: 12.956921,
        },
        {
            obec: 'Chříč',
            kod: 558974,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.97192,
            longitude: 13.646002,
        },
        {
            obec: 'Chudenice',
            kod: 556378,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.466785,
            longitude: 13.17397,
        },
        {
            obec: 'Chudenín',
            kod: 556386,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34022,
            latitude: 49.29315,
            longitude: 13.100904,
        },
        {
            obec: 'Chválenice',
            kod: 557846,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33205,
            latitude: 49.643095,
            longitude: 13.476053,
        },
        {
            obec: 'Janovice nad Úhlavou',
            kod: 556394,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.345257,
            longitude: 13.218239,
        },
        {
            obec: 'Jarov',
            kod: 578541,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.521192,
            longitude: 13.507696,
        },
        {
            obec: 'Jarov',
            kod: 558982,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.872569,
            longitude: 13.474387,
        },
        {
            obec: 'Javor',
            kod: 541869,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.325542,
            longitude: 13.247541,
        },
        {
            obec: 'Ježovy',
            kod: 541753,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.491162,
            longitude: 13.228504,
        },
        {
            obec: 'Kaceřov',
            kod: 558991,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.870714,
            longitude: 13.512774,
        },
        {
            obec: 'Kakejcov',
            kod: 579017,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.667036,
            longitude: 13.624286,
        },
        {
            obec: 'Kamenec',
            kod: 566811,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.881166,
            longitude: 13.596339,
        },
        {
            obec: 'Kamenný Újezd',
            kod: 541150,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.723772,
            longitude: 13.618864,
        },
        {
            obec: 'Kanice',
            kod: 566144,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.475118,
            longitude: 13.072809,
        },
        {
            obec: 'Kaničky',
            kod: 553778,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.474432,
            longitude: 13.145358,
        },
        {
            obec: 'Kařez',
            kod: 559903,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.824356,
            longitude: 13.781719,
        },
        {
            obec: 'Kařízek',
            kod: 530361,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.813466,
            longitude: 13.799741,
        },
        {
            obec: 'Kasejovice',
            kod: 557862,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33544,
            latitude: 49.462805,
            longitude: 13.740706,
        },
        {
            obec: 'Kašperské Hory',
            kod: 556432,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34192,
            latitude: 49.143,
            longitude: 13.556264,
        },
        {
            obec: 'Kaznějov',
            kod: 559008,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.893239,
            longitude: 13.383058,
        },
        {
            obec: 'Kbel',
            kod: 557871,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.493815,
            longitude: 13.365703,
        },
        {
            obec: 'Kbelany',
            kod: 538183,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.732444,
            longitude: 13.126409,
        },
        {
            obec: 'Kdyně',
            kod: 553786,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.390348,
            longitude: 13.040638,
        },
        {
            obec: 'Kejnice',
            kod: 541826,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.266995,
            longitude: 13.694293,
        },
        {
            obec: 'Klabava',
            kod: 559911,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33841,
            latitude: 49.753546,
            longitude: 13.539382,
        },
        {
            obec: 'Kladruby',
            kod: 559920,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.912977,
            longitude: 13.631799,
        },
        {
            obec: 'Kladruby',
            kod: 560928,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34961,
            latitude: 49.715408,
            longitude: 12.980021,
        },
        {
            obec: 'Klášter',
            kod: 557897,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.50201,
            longitude: 13.576777,
        },
        {
            obec: 'Klatovy',
            kod: 555771,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.395655,
            longitude: 13.295194,
        },
        {
            obec: 'Klenčí pod Čerchovem',
            kod: 553794,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34534,
            latitude: 49.434948,
            longitude: 12.814835,
        },
        {
            obec: 'Klenová',
            kod: 541851,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.333543,
            longitude: 13.233291,
        },
        {
            obec: 'Kočín',
            kod: 578771,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.930488,
            longitude: 13.476248,
        },
        {
            obec: 'Kočov',
            kod: 541559,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.808767,
            longitude: 12.736709,
        },
        {
            obec: 'Kokašice',
            kod: 541494,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34952,
            latitude: 49.878396,
            longitude: 12.947328,
        },
        {
            obec: 'Kolinec',
            kod: 556467,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.299057,
            longitude: 13.439106,
        },
        {
            obec: 'Koloveč',
            kod: 553816,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.486895,
            longitude: 13.108876,
        },
        {
            obec: 'Konstantinovy Lázně',
            kod: 560952,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34952,
            latitude: 49.881263,
            longitude: 12.977983,
        },
        {
            obec: 'Kopidlo',
            kod: 559024,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.943303,
            longitude: 13.464495,
        },
        {
            obec: 'Kornatice',
            kod: 579033,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.659042,
            longitude: 13.592325,
        },
        {
            obec: 'Koryta',
            kod: 559032,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.900611,
            longitude: 13.474862,
        },
        {
            obec: 'Kostelec',
            kod: 503916,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.674415,
            longitude: 13.026491,
        },
        {
            obec: 'Kotovice',
            kod: 540633,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.672114,
            longitude: 13.154585,
        },
        {
            obec: 'Kout na Šumavě',
            kod: 553824,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34502,
            latitude: 49.402659,
            longitude: 13.002193,
        },
        {
            obec: 'Kovčín',
            kod: 578177,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.415936,
            longitude: 13.606717,
        },
        {
            obec: 'Kozlovice',
            kod: 540200,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.465098,
            longitude: 13.582934,
        },
        {
            obec: 'Kozojedy',
            kod: 559041,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.931343,
            longitude: 13.542516,
        },
        {
            obec: 'Kozolupy',
            kod: 559059,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33032,
            latitude: 49.763902,
            longitude: 13.252214,
        },
        {
            obec: 'Kožlany',
            kod: 559067,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33144,
            latitude: 49.993998,
            longitude: 13.541176,
        },
        {
            obec: 'Kralovice',
            kod: 559075,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.982024,
            longitude: 13.487584,
        },
        {
            obec: 'Kramolín',
            kod: 557943,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.446183,
            longitude: 13.577065,
        },
        {
            obec: 'Krašovice',
            kod: 559083,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33013,
            latitude: 49.872448,
            longitude: 13.30535,
        },
        {
            obec: 'Krsy',
            kod: 559091,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33038,
            latitude: 49.926348,
            longitude: 13.055063,
        },
        {
            obec: 'Křelovice',
            kod: 559105,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33036,
            latitude: 49.874423,
            longitude: 13.075914,
        },
        {
            obec: 'Křenice',
            kod: 556505,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.502807,
            longitude: 13.200398,
        },
        {
            obec: 'Křenovy',
            kod: 553832,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.53926,
            longitude: 13.021618,
        },
        {
            obec: 'Kšice',
            kod: 560979,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.80252,
            longitude: 12.997365,
        },
        {
            obec: 'Kunějovice',
            kod: 559121,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33035,
            latitude: 49.862066,
            longitude: 13.237025,
        },
        {
            obec: 'Kvášňovice',
            kod: 541931,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.413124,
            longitude: 13.642076,
        },
        {
            obec: 'Kvíčovice',
            kod: 553841,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.590322,
            longitude: 13.073059,
        },
        {
            obec: 'Kyšice',
            kod: 559130,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33001,
            latitude: 49.753353,
            longitude: 13.486312,
        },
        {
            obec: 'Ledce',
            kod: 559148,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33014,
            latitude: 49.821738,
            longitude: 13.328535,
        },
        {
            obec: 'Lesná',
            kod: 561002,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.754286,
            longitude: 12.537785,
        },
        {
            obec: 'Lestkov',
            kod: 561011,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34953,
            latitude: 49.883163,
            longitude: 12.871116,
        },
        {
            obec: 'Letiny',
            kod: 557951,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.540003,
            longitude: 13.455391,
        },
        {
            obec: 'Letkov',
            kod: 540561,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 32600,
            latitude: 49.730543,
            longitude: 13.465091,
        },
        {
            obec: 'Lhota pod Radčem',
            kod: 567001,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.827464,
            longitude: 13.710816,
        },
        {
            obec: 'Lhotka u Radnic',
            kod: 559946,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.89004,
            longitude: 13.581715,
        },
        {
            obec: 'Lhůta',
            kod: 578606,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33201,
            latitude: 49.70137,
            longitude: 13.52891,
        },
        {
            obec: 'Libkov',
            kod: 553859,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.366295,
            longitude: 13.13447,
        },
        {
            obec: 'Liblín',
            kod: 559954,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.916919,
            longitude: 13.544351,
        },
        {
            obec: 'Líně',
            kod: 559164,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33021,
            latitude: 49.694828,
            longitude: 13.257039,
        },
        {
            obec: 'Lisov',
            kod: 540064,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.638638,
            longitude: 13.100966,
        },
        {
            obec: 'Líšina',
            kod: 540617,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.603604,
            longitude: 13.164824,
        },
        {
            obec: 'Líšná',
            kod: 559962,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.885111,
            longitude: 13.806522,
        },
        {
            obec: 'Líšťany',
            kod: 559172,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33035,
            latitude: 49.831183,
            longitude: 13.181206,
        },
        {
            obec: 'Líté',
            kod: 566420,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.913753,
            longitude: 13.26085,
        },
        {
            obec: 'Litohlavy',
            kod: 553611,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.766543,
            longitude: 13.56469,
        },
        {
            obec: 'Lochousice',
            kod: 566462,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.675204,
            longitude: 13.090555,
        },
        {
            obec: 'Lom u Tachova',
            kod: 541702,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.818772,
            longitude: 12.692703,
        },
        {
            obec: 'Lomec',
            kod: 578070,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.371502,
            longitude: 13.267633,
        },
        {
            obec: 'Losiná',
            kod: 558001,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.6693,
            longitude: 13.448442,
        },
        {
            obec: 'Loučim',
            kod: 553867,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.367168,
            longitude: 13.112222,
        },
        {
            obec: 'Louňová',
            kod: 558010,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.562286,
            longitude: 13.608016,
        },
        {
            obec: 'Loza',
            kod: 566446,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.893909,
            longitude: 13.290472,
        },
        {
            obec: 'Lužany',
            kod: 558028,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33454,
            latitude: 49.546752,
            longitude: 13.315847,
        },
        {
            obec: 'Luženičky',
            kod: 553891,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.458913,
            longitude: 12.897685,
        },
        {
            obec: 'Malý Bor',
            kod: 556629,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.330621,
            longitude: 13.652357,
        },
        {
            obec: 'Manětín',
            kod: 559202,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33162,
            latitude: 49.991884,
            longitude: 13.233304,
        },
        {
            obec: 'Maňovice',
            kod: 578410,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.393065,
            longitude: 13.653942,
        },
        {
            obec: 'Meclov',
            kod: 553913,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34521,
            latitude: 49.505676,
            longitude: 12.880927,
        },
        {
            obec: 'Měčín',
            kod: 556637,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.480195,
            longitude: 13.402993,
        },
        {
            obec: 'Medový Újezd',
            kod: 579009,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.773949,
            longitude: 13.719185,
        },
        {
            obec: 'Měcholupy',
            kod: 539716,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33551,
            latitude: 49.519592,
            longitude: 13.532756,
        },
        {
            obec: 'Merklín',
            kod: 558044,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33452,
            latitude: 49.560538,
            longitude: 13.198008,
        },
        {
            obec: 'Město Touškov',
            kod: 559211,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.77598,
            longitude: 13.251189,
        },
        {
            obec: 'Mešno',
            kod: 579084,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.655921,
            longitude: 13.622053,
        },
        {
            obec: 'Mezholezy',
            kod: 553921,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.411826,
            longitude: 13.103952,
        },
        {
            obec: 'Mezholezy',
            kod: 553930,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.624867,
            longitude: 12.905124,
        },
        {
            obec: 'Mezihoří',
            kod: 578461,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.488506,
            longitude: 13.254671,
        },
        {
            obec: 'Milavče',
            kod: 553948,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.467066,
            longitude: 12.980829,
        },
        {
            obec: 'Mileč',
            kod: 558052,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.469232,
            longitude: 13.605014,
        },
        {
            obec: 'Milínov',
            kod: 530140,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.643171,
            longitude: 13.566047,
        },
        {
            obec: 'Milíře',
            kod: 541532,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.796407,
            longitude: 12.5507783,
        },
        {
            obec: 'Mirošov',
            kod: 559997,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.68793,
            longitude: 13.658174,
        },
        {
            obec: 'Mířkov',
            kod: 553956,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.589255,
            longitude: 12.881612,
        },
        {
            obec: 'Míšov',
            kod: 546399,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33563,
            latitude: 49.622664,
            longitude: 13.725854,
        },
        {
            obec: 'Mladotice',
            kod: 559237,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.985347,
            longitude: 13.364505,
        },
        {
            obec: 'Mladý Smolivec',
            kod: 558061,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.506411,
            longitude: 13.735393,
        },
        {
            obec: 'Mlečice',
            kod: 560006,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.920399,
            longitude: 13.696075,
        },
        {
            obec: 'Mlýnské Struhadlo',
            kod: 542059,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.432149,
            longitude: 13.469571,
        },
        {
            obec: 'Mnichov',
            kod: 566292,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.49683,
            longitude: 12.764531,
        },
        {
            obec: 'Močerady',
            kod: 599174,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.518078,
            longitude: 13.073554,
        },
        {
            obec: 'Modrava',
            kod: 542148,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34192,
            latitude: 49.024173,
            longitude: 13.499417,
        },
        {
            obec: 'Mohelnice',
            kod: 558079,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.48089,
            longitude: 13.641798,
        },
        {
            obec: 'Mochtín',
            kod: 556718,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.360477,
            longitude: 13.357005,
        },
        {
            obec: 'Mokrosuky',
            kod: 556726,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.275464,
            longitude: 13.454904,
        },
        {
            obec: 'Mokrouše',
            kod: 540641,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33201,
            latitude: 49.715159,
            longitude: 13.524657,
        },
        {
            obec: 'Mrákov',
            kod: 553981,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34501,
            latitude: 49.403252,
            longitude: 12.951311,
        },
        {
            obec: 'Mrtník',
            kod: 559245,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.895538,
            longitude: 13.312306,
        },
        {
            obec: 'Mutěnín',
            kod: 553999,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34525,
            latitude: 49.544857,
            longitude: 12.74467,
        },
        {
            obec: 'Myslinka',
            kod: 566543,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.748125,
            longitude: 13.219142,
        },
        {
            obec: 'Myslív',
            kod: 556734,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.414314,
            longitude: 13.572532,
        },
        {
            obec: 'Myslovice',
            kod: 541745,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.406902,
            longitude: 13.387145,
        },
        {
            obec: 'Mýto',
            kod: 560014,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33805,
            latitude: 49.789152,
            longitude: 13.734722,
        },
        {
            obec: 'Nadryby',
            kod: 559253,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.820798,
            longitude: 13.524572,
        },
        {
            obec: 'Nalžovské Hory',
            kod: 556751,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.331486,
            longitude: 13.546557,
        },
        {
            obec: 'Nebílovy',
            kod: 540340,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.630199,
            longitude: 13.429246,
        },
        {
            obec: 'Nečtiny',
            kod: 559261,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33162,
            latitude: 49.974944,
            longitude: 13.164739,
        },
        {
            obec: 'Nehodiv',
            kod: 578223,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.410858,
            longitude: 13.556463,
        },
        {
            obec: 'Nekmíř',
            kod: 559270,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33152,
            latitude: 49.858193,
            longitude: 13.263246,
        },
        {
            obec: 'Nekvasovy',
            kod: 558095,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33547,
            latitude: 49.434661,
            longitude: 13.62585,
        },
        {
            obec: 'Nemanice',
            kod: 554006,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.436663,
            longitude: 12.720642,
        },
        {
            obec: 'Němčice',
            kod: 599166,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.425183,
            longitude: 13.077609,
        },
        {
            obec: 'Němčovice',
            kod: 566837,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.877038,
            longitude: 13.574993,
        },
        {
            obec: 'Nepomuk',
            kod: 558109,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.48627,
            longitude: 13.582361,
        },
        {
            obec: 'Netunice',
            kod: 558117,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.616473,
            longitude: 13.430689,
        },
        {
            obec: 'Neuměř',
            kod: 554022,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.597691,
            longitude: 13.064248,
        },
        {
            obec: 'Neurazy',
            kod: 558125,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33555,
            latitude: 49.441098,
            longitude: 13.515683,
        },
        {
            obec: 'Nevid',
            kod: 541192,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.685555,
            longitude: 13.605743,
        },
        {
            obec: 'Nevolice',
            kod: 554031,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.420508,
            longitude: 12.933341,
        },
        {
            obec: 'Nevřeň',
            kod: 559288,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.822935,
            longitude: 13.274982,
        },
        {
            obec: 'Nezamyslice',
            kod: 578533,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.262507,
            longitude: 13.675029,
        },
        {
            obec: 'Nezbavětice',
            kod: 553590,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.655024,
            longitude: 13.477167,
        },
        {
            obec: 'Nezdice',
            kod: 542296,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.531654,
            longitude: 13.316677,
        },
        {
            obec: 'Nezdice na Šumavě',
            kod: 556815,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.172674,
            longitude: 13.612383,
        },
        {
            obec: 'Nezdřev',
            kod: 540102,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33544,
            latitude: 49.426677,
            longitude: 13.728163,
        },
        {
            obec: 'Nezvěstice',
            kod: 558141,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.640535,
            longitude: 13.519727,
        },
        {
            obec: 'Nová Ves',
            kod: 599131,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.366549,
            longitude: 13.038868,
        },
        {
            obec: 'Nová Ves',
            kod: 540269,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33441,
            latitude: 49.686066,
            longitude: 13.292789,
        },
        {
            obec: 'Nové Mitrovice',
            kod: 558176,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33563,
            latitude: 49.579685,
            longitude: 13.683735,
        },
        {
            obec: 'Nový Kramolín',
            kod: 554057,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.477383,
            longitude: 12.805087,
        },
        {
            obec: 'Nýrsko',
            kod: 556831,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34022,
            latitude: 49.293979,
            longitude: 13.143639,
        },
        {
            obec: 'Nýřany',
            kod: 559300,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.712816,
            longitude: 13.204705,
        },
        {
            obec: 'Obora',
            kod: 559318,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.889575,
            longitude: 13.413718,
        },
        {
            obec: 'Obora',
            kod: 541443,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.814715,
            longitude: 12.5378,
        },
        {
            obec: 'Obytce',
            kod: 541915,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.390514,
            longitude: 13.378436,
        },
        {
            obec: 'Olbramov',
            kod: 566888,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.843499,
            longitude: 12.866878,
        },
        {
            obec: 'Olšany',
            kod: 541958,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.400933,
            longitude: 13.627031,
        },
        {
            obec: 'Oplot',
            kod: 539783,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.579961,
            longitude: 13.274335,
        },
        {
            obec: 'Osek',
            kod: 560057,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33821,
            latitude: 49.778115,
            longitude: 13.59104,
        },
        {
            obec: 'Oselce',
            kod: 558184,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33546,
            latitude: 49.437309,
            longitude: 13.672931,
        },
        {
            obec: 'Ostrov u Bezdružic',
            kod: 559326,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33038,
            latitude: 49.900238,
            longitude: 13.042079,
        },
        {
            obec: 'Ostrovec-Lhotka',
            kod: 566993,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.907578,
            longitude: 13.728204,
        },
        {
            obec: 'Ostřetice',
            kod: 578061,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.422439,
            longitude: 13.347004,
        },
        {
            obec: 'Osvračín',
            kod: 554073,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.513274,
            longitude: 13.047035,
        },
        {
            obec: 'Ošelín',
            kod: 541681,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.772115,
            longitude: 12.856261,
        },
        {
            obec: 'Otěšice',
            kod: 540293,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.544137,
            longitude: 13.220352,
        },
        {
            obec: 'Otov',
            kod: 566209,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.486597,
            longitude: 12.842602,
        },
        {
            obec: 'Pačejov',
            kod: 556912,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.373007,
            longitude: 13.632835,
        },
        {
            obec: 'Pařezov',
            kod: 599182,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.470477,
            longitude: 12.83793,
        },
        {
            obec: 'Pasečnice',
            kod: 554081,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.395458,
            longitude: 12.893516,
        },
        {
            obec: 'Pastuchovice',
            kod: 566781,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33165,
            latitude: 50.069086,
            longitude: 13.382322,
        },
        {
            obec: 'Pec',
            kod: 554090,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.398684,
            longitude: 12.831288,
        },
        {
            obec: 'Pelechy',
            kod: 566373,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.389227,
            longitude: 12.914379,
        },
        {
            obec: 'Pernarec',
            kod: 559334,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33036,
            latitude: 49.840507,
            longitude: 13.102822,
        },
        {
            obec: 'Petrovice u Sušice',
            kod: 556921,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.218758,
            longitude: 13.440898,
        },
        {
            obec: 'Planá',
            kod: 561134,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.868267,
            longitude: 12.743897,
        },
        {
            obec: 'Pláně',
            kod: 530336,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33101,
            latitude: 49.9338,
            longitude: 13.389711,
        },
        {
            obec: 'Plánice',
            kod: 556955,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34034,
            latitude: 49.390036,
            longitude: 13.471166,
        },
        {
            obec: 'Plasy',
            kod: 559351,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33101,
            latitude: 49.934598,
            longitude: 13.390465,
        },
        {
            obec: 'Plešnice',
            kod: 559369,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.774084,
            longitude: 13.17685,
        },
        {
            obec: 'Plískov',
            kod: 566861,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.842744,
            longitude: 13.739921,
        },
        {
            obec: 'Plzeň',
            kod: 554791,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 30100,
            latitude: 49.738531,
            longitude: 13.373737,
        },
        {
            obec: 'Pňovany',
            kod: 559377,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.778992,
            longitude: 13.121864,
        },
        {
            obec: 'Poběžovice',
            kod: 554111,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.510406,
            longitude: 12.802721,
        },
        {
            obec: 'Pocinovice',
            kod: 554120,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34509,
            latitude: 49.343523,
            longitude: 13.133597,
        },
        {
            obec: 'Poděvousy',
            kod: 566365,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.527617,
            longitude: 13.131214,
        },
        {
            obec: 'Podmokly',
            kod: 551686,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.230189,
            longitude: 13.578261,
        },
        {
            obec: 'Podmokly',
            kod: 560081,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.943339,
            longitude: 13.704478,
        },
        {
            obec: 'Polánka',
            kod: 553565,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.435798,
            longitude: 13.555643,
        },
        {
            obec: 'Poleň',
            kod: 541788,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.425079,
            longitude: 13.176854,
        },
        {
            obec: 'Postřekov',
            kod: 554138,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34535,
            latitude: 49.458386,
            longitude: 12.806891,
        },
        {
            obec: 'Potvorov',
            kod: 530247,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 50.016105,
            longitude: 13.399461,
        },
        {
            obec: 'Prádlo',
            kod: 558231,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.503547,
            longitude: 13.541553,
        },
        {
            obec: 'Prášily',
            kod: 556084,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.10524,
            longitude: 13.378047,
        },
        {
            obec: 'Prostiboř',
            kod: 541486,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.652373,
            longitude: 12.89399,
        },
        {
            obec: 'Předenice',
            kod: 540412,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33209,
            latitude: 49.624495,
            longitude: 13.396669,
        },
        {
            obec: 'Předslav',
            kod: 557005,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.447575,
            longitude: 13.354485,
        },
        {
            obec: 'Přehýšov',
            kod: 559393,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.702736,
            longitude: 13.126957,
        },
        {
            obec: 'Přestavlky',
            kod: 530131,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.59986,
            longitude: 13.244478,
        },
        {
            obec: 'Přeštice',
            kod: 558249,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.573091,
            longitude: 13.333607,
        },
        {
            obec: 'Příchovice',
            kod: 558257,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.562138,
            longitude: 13.33946,
        },
        {
            obec: 'Příkosice',
            kod: 579131,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.668377,
            longitude: 13.663557,
        },
        {
            obec: 'Přimda',
            kod: 561151,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34806,
            latitude: 49.674989,
            longitude: 12.673772,
        },
        {
            obec: 'Příšov',
            kod: 567086,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.811806,
            longitude: 13.304241,
        },
        {
            obec: 'Přívětice',
            kod: 560111,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.834798,
            longitude: 13.612239,
        },
        {
            obec: 'Ptenín',
            kod: 558265,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33452,
            latitude: 49.531607,
            longitude: 13.18506,
        },
        {
            obec: 'Puclice',
            kod: 554154,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.553725,
            longitude: 13.019504,
        },
        {
            obec: 'Rabí',
            kod: 557013,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.280848,
            longitude: 13.617715,
        },
        {
            obec: 'Radkovice',
            kod: 540421,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.547925,
            longitude: 13.368061,
        },
        {
            obec: 'Radnice',
            kod: 560120,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.856879,
            longitude: 13.605812,
        },
        {
            obec: 'Raková',
            kod: 566942,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.70043,
            longitude: 13.582052,
        },
        {
            obec: 'Rejštejn',
            kod: 557021,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34192,
            latitude: 49.14064,
            longitude: 13.515272,
        },
        {
            obec: 'Rochlov',
            kod: 559423,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.730821,
            longitude: 13.146015,
        },
        {
            obec: 'Rokycany',
            kod: 559717,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.738197,
            longitude: 13.592993,
        },
        {
            obec: 'Roupov',
            kod: 558290,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33453,
            latitude: 49.538655,
            longitude: 13.249019,
        },
        {
            obec: 'Rozvadov',
            kod: 561169,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34806,
            latitude: 49.668279,
            longitude: 12.551998,
        },
        {
            obec: 'Rybnice',
            kod: 559431,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33151,
            latitude: 49.908441,
            longitude: 13.373838,
        },
        {
            obec: 'Rybník',
            kod: 554189,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34525,
            latitude: 49.512872,
            longitude: 12.676863,
        },
        {
            obec: 'Řenče',
            kod: 558303,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.579972,
            longitude: 13.4146,
        },
        {
            obec: 'Sebečice',
            kod: 560146,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.869965,
            longitude: 13.692257,
        },
        {
            obec: 'Seč',
            kod: 558311,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.588075,
            longitude: 13.504999,
        },
        {
            obec: 'Sedlec',
            kod: 530271,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 50.006379,
            longitude: 13.427287,
        },
        {
            obec: 'Sedliště',
            kod: 540692,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.52758,
            longitude: 13.63234,
        },
        {
            obec: 'Semněvice',
            kod: 554201,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.597859,
            longitude: 12.929307,
        },
        {
            obec: 'Sirá',
            kod: 546488,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.821542,
            longitude: 13.738545,
        },
        {
            obec: 'Skapce',
            kod: 579467,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.657074,
            longitude: 12.990454,
        },
        {
            obec: 'Skašov',
            kod: 539937,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.510567,
            longitude: 13.431498,
        },
        {
            obec: 'Skomelno',
            kod: 566845,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.847471,
            longitude: 13.642262,
        },
        {
            obec: 'Skořice',
            kod: 506664,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.677828,
            longitude: 13.705369,
        },
        {
            obec: 'Slatina',
            kod: 578436,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.38787,
            longitude: 13.744269,
        },
        {
            obec: 'Slatina',
            kod: 566748,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.984635,
            longitude: 13.634016,
        },
        {
            obec: 'Smědčice',
            kod: 540722,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.799216,
            longitude: 13.512935,
        },
        {
            obec: 'Soběkury',
            kod: 558346,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.576294,
            longitude: 13.239149,
        },
        {
            obec: 'Soběšice',
            kod: 557099,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.207905,
            longitude: 13.684205,
        },
        {
            obec: 'Spálené Poříčí',
            kod: 558362,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33561,
            latitude: 49.613815,
            longitude: 13.605669,
        },
        {
            obec: 'Spáňov',
            kod: 554251,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.414624,
            longitude: 12.987415,
        },
        {
            obec: 'Srbice',
            kod: 554260,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.508409,
            longitude: 13.118703,
        },
        {
            obec: 'Srby',
            kod: 554278,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.536976,
            longitude: 12.868429,
        },
        {
            obec: 'Srby',
            kod: 540676,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.519054,
            longitude: 13.603397,
        },
        {
            obec: 'Srní',
            kod: 557111,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34192,
            latitude: 49.087292,
            longitude: 13.480548,
        },
        {
            obec: 'Staňkov',
            kod: 554294,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34561,
            latitude: 49.553835,
            longitude: 13.06931,
        },
        {
            obec: 'Staré Sedliště',
            kod: 561185,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34801,
            latitude: 49.744446,
            longitude: 12.694252,
        },
        {
            obec: 'Staré Sedlo',
            kod: 561193,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34802,
            latitude: 49.666003,
            longitude: 12.863729,
        },
        {
            obec: 'Starý Plzenec',
            kod: 558371,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33202,
            latitude: 49.697786,
            longitude: 13.47361,
        },
        {
            obec: 'Stod',
            kod: 558389,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.639199,
            longitude: 13.164846,
        },
        {
            obec: 'Strašice',
            kod: 560162,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33845,
            latitude: 49.735624,
            longitude: 13.757656,
        },
        {
            obec: 'Strašín',
            kod: 557129,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.179345,
            longitude: 13.640501,
        },
        {
            obec: 'Stráž',
            kod: 554316,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.414854,
            longitude: 12.913028,
        },
        {
            obec: 'Stráž',
            kod: 561207,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34802,
            latitude: 49.668894,
            longitude: 12.775569,
        },
        {
            obec: 'Strážov',
            kod: 557137,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.30337,
            longitude: 13.246341,
        },
        {
            obec: 'Střelice',
            kod: 540056,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.630615,
            longitude: 13.134702,
        },
        {
            obec: 'Stříbro',
            kod: 561215,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.753048,
            longitude: 13.004203,
        },
        {
            obec: 'Střížovice',
            kod: 558401,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33207,
            latitude: 49.611889,
            longitude: 13.458498,
        },
        {
            obec: 'Studánka',
            kod: 561223,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.780682,
            longitude: 12.603786,
        },
        {
            obec: 'Studená',
            kod: 553603,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.960729,
            longitude: 13.643134,
        },
        {
            obec: 'Sulislav',
            kod: 561231,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.748658,
            longitude: 13.072824,
        },
        {
            obec: 'Sušice',
            kod: 557153,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.231776,
            longitude: 13.520509,
        },
        {
            obec: 'Svéradice',
            kod: 557161,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.371445,
            longitude: 13.739694,
        },
        {
            obec: 'Svojkovice',
            kod: 541176,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33822,
            latitude: 49.759974,
            longitude: 13.648336,
        },
        {
            obec: 'Svojšín',
            kod: 561258,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.766164,
            longitude: 12.910779,
        },
        {
            obec: 'Sytno',
            kod: 557081,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.739789,
            longitude: 13.041764,
        },
        {
            obec: 'Šťáhlavy',
            kod: 558427,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33203,
            latitude: 49.675659,
            longitude: 13.504047,
        },
        {
            obec: 'Štěnovice',
            kod: 558435,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33209,
            latitude: 49.670623,
            longitude: 13.399739,
        },
        {
            obec: 'Štěnovický Borek',
            kod: 539741,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33209,
            latitude: 49.649772,
            longitude: 13.426875,
        },
        {
            obec: 'Štichov',
            kod: 566101,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.585019,
            longitude: 13.048969,
        },
        {
            obec: 'Štichovice',
            kod: 578797,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.977911,
            longitude: 13.302933,
        },
        {
            obec: 'Štítov',
            kod: 579343,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.666038,
            longitude: 13.678024,
        },
        {
            obec: 'Švihov',
            kod: 557200,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34012,
            latitude: 49.481466,
            longitude: 13.284281,
        },
        {
            obec: 'Tachov',
            kod: 560715,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34701,
            latitude: 49.79888,
            longitude: 12.636292,
        },
        {
            obec: 'Tatiná',
            kod: 578983,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.848699,
            longitude: 13.299824,
        },
        {
            obec: 'Těně',
            kod: 560189,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33845,
            latitude: 49.7513,
            longitude: 13.796268,
        },
        {
            obec: 'Terešov',
            kod: 566802,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.896783,
            longitude: 13.69985,
        },
        {
            obec: 'Těškov',
            kod: 546526,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.802297,
            longitude: 13.698135,
        },
        {
            obec: 'Tis u Blatna',
            kod: 559482,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33165,
            latitude: 50.085845,
            longitude: 13.347908,
        },
        {
            obec: 'Tisová',
            kod: 541621,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34801,
            latitude: 49.765288,
            longitude: 12.713935,
        },
        {
            obec: 'Tlučná',
            kod: 559491,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33026,
            latitude: 49.724362,
            longitude: 13.235453,
        },
        {
            obec: 'Tlumačov',
            kod: 554341,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.40364,
            longitude: 12.929095,
        },
        {
            obec: 'Tojice',
            kod: 578614,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.492585,
            longitude: 13.62662,
        },
        {
            obec: 'Trhanov',
            kod: 554359,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34533,
            latitude: 49.420341,
            longitude: 12.845635,
        },
        {
            obec: 'Trnová',
            kod: 559504,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33013,
            latitude: 49.861152,
            longitude: 13.323963,
        },
        {
            obec: 'Trokavec',
            kod: 579246,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.647028,
            longitude: 13.702351,
        },
        {
            obec: 'Trpísty',
            kod: 561291,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.822747,
            longitude: 13.058639,
        },
        {
            obec: 'Třebčice',
            kod: 578592,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33501,
            latitude: 49.483479,
            longitude: 13.612175,
        },
        {
            obec: 'Třemešné',
            kod: 561304,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34806,
            latitude: 49.625373,
            longitude: 12.695768,
        },
        {
            obec: 'Třemošná',
            kod: 559521,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.815946,
            longitude: 13.395104,
        },
        {
            obec: 'Tužice',
            kod: 553522,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.335115,
            longitude: 13.511527,
        },
        {
            obec: 'Týček',
            kod: 546534,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.869348,
            longitude: 13.794421,
        },
        {
            obec: 'Tymákov',
            kod: 558460,
            okres: 'Plzeň-město',
            kod_okres: 'CZ0323',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33201,
            latitude: 49.719174,
            longitude: 13.509931,
        },
        {
            obec: 'Týnec',
            kod: 541885,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34021,
            latitude: 49.347334,
            longitude: 13.264018,
        },
        {
            obec: 'Týniště',
            kod: 539929,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.521194,
            longitude: 13.417703,
        },
        {
            obec: 'Úboč',
            kod: 566161,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.443698,
            longitude: 13.086983,
        },
        {
            obec: 'Úherce',
            kod: 546411,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.701479,
            longitude: 13.213823,
        },
        {
            obec: 'Újezd',
            kod: 554383,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.435642,
            longitude: 12.869668,
        },
        {
            obec: 'Újezd nade Mží',
            kod: 566594,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.788854,
            longitude: 13.195175,
        },
        {
            obec: 'Újezd u Plánice',
            kod: 578321,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.417621,
            longitude: 13.457818,
        },
        {
            obec: 'Újezd u Svatého Kříže',
            kod: 546551,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.859837,
            longitude: 13.56961,
        },
        {
            obec: 'Úlice',
            kod: 559555,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33033,
            latitude: 49.760902,
            longitude: 13.148283,
        },
        {
            obec: 'Únehle',
            kod: 541435,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.791701,
            longitude: 13.015482,
        },
        {
            obec: 'Únějovice',
            kod: 554391,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.462139,
            longitude: 13.118705,
        },
        {
            obec: 'Úněšov',
            kod: 559563,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33038,
            latitude: 49.882689,
            longitude: 13.149299,
        },
        {
            obec: 'Únětice',
            kod: 540528,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.584402,
            longitude: 13.470115,
        },
        {
            obec: 'Úsilov',
            kod: 554405,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34506,
            latitude: 49.396955,
            longitude: 13.127071,
        },
        {
            obec: 'Úterý',
            kod: 559571,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33041,
            latitude: 49.940181,
            longitude: 13.00429,
        },
        {
            obec: 'Útušice',
            kod: 558486,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33209,
            latitude: 49.677894,
            longitude: 13.382148,
        },
        {
            obec: 'Vejprnice',
            kod: 559580,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33027,
            latitude: 49.729991,
            longitude: 13.276386,
        },
        {
            obec: 'Vejvanov',
            kod: 560227,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33828,
            latitude: 49.868769,
            longitude: 13.652523,
        },
        {
            obec: 'Velečín',
            kod: 578924,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33165,
            latitude: 50.074416,
            longitude: 13.401709,
        },
        {
            obec: 'Velhartice',
            kod: 557366,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.265299,
            longitude: 13.389852,
        },
        {
            obec: 'Velké Hydčice',
            kod: 557374,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.299235,
            longitude: 13.668052,
        },
        {
            obec: 'Velký Bor',
            kod: 557382,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34101,
            latitude: 49.365032,
            longitude: 13.701518,
        },
        {
            obec: 'Velký Malahov',
            kod: 554413,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.623677,
            longitude: 12.955437,
        },
        {
            obec: 'Ves Touškov',
            kod: 558494,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33301,
            latitude: 49.661704,
            longitude: 13.119336,
        },
        {
            obec: 'Veselá',
            kod: 560235,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33701,
            latitude: 49.695183,
            longitude: 13.602933,
        },
        {
            obec: 'Vidice',
            kod: 554421,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34601,
            latitude: 49.613863,
            longitude: 12.835622,
        },
        {
            obec: 'Vísky',
            kod: 579289,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33843,
            latitude: 49.651304,
            longitude: 13.682749,
        },
        {
            obec: 'Vlčí',
            kod: 566691,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.511993,
            longitude: 13.360356,
        },
        {
            obec: 'Vlčtejn',
            kod: 540706,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.614724,
            longitude: 13.495764,
        },
        {
            obec: 'Vlkanov',
            kod: 566250,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34522,
            latitude: 49.488639,
            longitude: 12.80529,
        },
        {
            obec: 'Vochov',
            kod: 559601,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33023,
            latitude: 49.757083,
            longitude: 13.278711,
        },
        {
            obec: 'Volduchy',
            kod: 560251,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33822,
            latitude: 49.776171,
            longitude: 13.623155,
        },
        {
            obec: 'Vranov',
            kod: 579491,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.764487,
            longitude: 13.042022,
        },
        {
            obec: 'Vrčeň',
            kod: 558559,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33541,
            latitude: 49.508064,
            longitude: 13.618988,
        },
        {
            obec: 'Vrhaveč',
            kod: 557455,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33901,
            latitude: 49.345613,
            longitude: 13.29636,
        },
        {
            obec: 'Vřeskovice',
            kod: 566055,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33401,
            latitude: 49.525544,
            longitude: 13.271216,
        },
        {
            obec: 'Vstiš',
            kod: 558567,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33441,
            latitude: 49.645645,
            longitude: 13.249051,
        },
        {
            obec: 'Všehrdy',
            kod: 566497,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.963781,
            longitude: 13.585179,
        },
        {
            obec: 'Všekary',
            kod: 566071,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34562,
            latitude: 49.603515,
            longitude: 13.042559,
        },
        {
            obec: 'Všenice',
            kod: 566799,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33824,
            latitude: 49.817992,
            longitude: 13.560771,
        },
        {
            obec: 'Všepadly',
            kod: 554448,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34543,
            latitude: 49.45098,
            longitude: 13.105384,
        },
        {
            obec: 'Všeruby',
            kod: 554456,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34507,
            latitude: 49.340829,
            longitude: 12.983339,
        },
        {
            obec: 'Všeruby',
            kod: 559628,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33016,
            latitude: 49.841781,
            longitude: 13.229541,
        },
        {
            obec: 'Výrov',
            kod: 566969,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 49.961857,
            longitude: 13.462671,
        },
        {
            obec: 'Vysoká Libyně',
            kod: 530280,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33141,
            latitude: 50.021988,
            longitude: 13.450793,
        },
        {
            obec: 'Zadní Chodov',
            kod: 541362,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34815,
            latitude: 49.890602,
            longitude: 12.654668,
        },
        {
            obec: 'Zahořany',
            kod: 554464,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.434161,
            longitude: 13.002035,
        },
        {
            obec: 'Zahrádka',
            kod: 578827,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33035,
            latitude: 49.880856,
            longitude: 13.211746,
        },
        {
            obec: 'Záchlumí',
            kod: 541664,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.80022,
            longitude: 12.965032,
        },
        {
            obec: 'Zavlekov',
            kod: 557463,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34142,
            latitude: 49.337465,
            longitude: 13.49054,
        },
        {
            obec: 'Zbiroh',
            kod: 560260,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.860345,
            longitude: 13.772729,
        },
        {
            obec: 'Zborovy',
            kod: 578312,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34034,
            latitude: 49.381199,
            longitude: 13.515763,
        },
        {
            obec: 'Zbůch',
            kod: 559661,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33022,
            latitude: 49.678509,
            longitude: 13.225754,
        },
        {
            obec: 'Zdemyslice',
            kod: 558583,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.607059,
            longitude: 13.519921,
        },
        {
            obec: 'Zemětice',
            kod: 558591,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33452,
            latitude: 49.578482,
            longitude: 13.186446,
        },
        {
            obec: 'Zhoř',
            kod: 541460,
            okres: 'Tachov',
            kod_okres: 'CZ0327',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34901,
            latitude: 49.660005,
            longitude: 12.969779,
        },
        {
            obec: 'Zruč-Senec',
            kod: 559679,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33008,
            latitude: 49.8031,
            longitude: 13.419093,
        },
        {
            obec: 'Zvíkovec',
            kod: 541061,
            okres: 'Rokycany',
            kod_okres: 'CZ0326',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33808,
            latitude: 49.954183,
            longitude: 13.688647,
        },
        {
            obec: 'Žákava',
            kod: 530182,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33204,
            latitude: 49.630078,
            longitude: 13.530832,
        },
        {
            obec: 'Ždánov',
            kod: 554472,
            okres: 'Domažlice',
            kod_okres: 'CZ0321',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34401,
            latitude: 49.461029,
            longitude: 12.854517,
        },
        {
            obec: 'Ždírec',
            kod: 558605,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33601,
            latitude: 49.551092,
            longitude: 13.572958,
        },
        {
            obec: 'Železná Ruda',
            kod: 557528,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34004,
            latitude: 49.137543,
            longitude: 13.235305,
        },
        {
            obec: 'Žihle',
            kod: 559695,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33165,
            latitude: 50.04502,
            longitude: 13.37513,
        },
        {
            obec: 'Žihobce',
            kod: 557536,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.215645,
            longitude: 13.631095,
        },
        {
            obec: 'Žichovice',
            kod: 557544,
            okres: 'Klatovy',
            kod_okres: 'CZ0322',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 34201,
            latitude: 49.267334,
            longitude: 13.627949,
        },
        {
            obec: 'Žilov',
            kod: 559709,
            okres: 'Plzeň-sever',
            kod_okres: 'CZ0325',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33011,
            latitude: 49.840106,
            longitude: 13.313289,
        },
        {
            obec: 'Žinkovy',
            kod: 558630,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33554,
            latitude: 49.483512,
            longitude: 13.489551,
        },
        {
            obec: 'Životice',
            kod: 540137,
            okres: 'Plzeň-jih',
            kod_okres: 'CZ0324',
            kraj: 'Plzeňský kraj',
            kod_kraj: 'CZ032',
            psc: 33544,
            latitude: 49.466586,
            longitude: 13.685301,
        },
    ],
    // https://wwwinfo.mfcr.cz/ares/obce/obce_okr_PH.html.cz
    CZ010: [
        {
            obec: 'Praha 1',
            kod: 500054,
            okres: 'Praha',
            kod_okres: 'CZ0101',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 11000,
            latitude: 50.0886981,
            longitude: 14.4034269,
        },
        {
            obec: 'Praha 2',
            kod: 500089,
            okres: 'Praha',
            kod_okres: 'CZ0102',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 12000,
            latitude: 50.0705453,
            longitude: 14.4235978,
        },
        {
            obec: 'Praha 3',
            kod: 500097,
            okres: 'Praha',
            kod_okres: 'CZ0103',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 13000,
            latitude: 50.0888331,
            longitude: 14.4662369,
        },
        {
            obec: 'Praha 4',
            kod: 500119,
            okres: 'Praha',
            kod_okres: 'CZ0104',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 14000,
            latitude: 50.0406436,
            longitude: 14.4360997,
        },
        {
            obec: 'Praha 5',
            kod: 500143,
            okres: 'Praha',
            kod_okres: 'CZ0105',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 15000,
            latitude: 50.0552925,
            longitude: 14.3796906,
        },
        {
            obec: 'Praha 6',
            kod: 500178,
            okres: 'Praha',
            kod_okres: 'CZ0106',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 16000,
            latitude: 50.0902633,
            longitude: 14.3257014,
        },
        {
            obec: 'Praha 7',
            kod: 500186,
            okres: 'Praha',
            kod_okres: 'CZ0107',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 17000,
            latitude: 50.1036525,
            longitude: 14.4388578,
        },
        {
            obec: 'Praha 8',
            kod: 500208,
            okres: 'Praha',
            kod_okres: 'CZ0108',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 18000,
            latitude: 50.1243992,
            longitude: 14.45457,
        },
        {
            obec: 'Praha 9',
            kod: 500216,
            okres: 'Praha',
            kod_okres: 'CZ0109',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19000,
            latitude: 50.1069575,
            longitude: 14.5102069,
        },
        {
            obec: 'Praha 10',
            kod: 500224,
            okres: 'Praha',
            kod_okres: 'CZ010A',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 10000,
            latitude: 50.0636389,
            longitude: 14.4736611,
        },
        {
            obec: 'Praha 11',
            kod: 547034,
            okres: 'Praha',
            kod_okres: 'CZ010B',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 14900,
            latitude: 50.0351469,
            longitude: 14.5058339,
        },
        {
            obec: 'Praha 12',
            kod: 547107,
            okres: 'Praha',
            kod_okres: 'CZ010C',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 14300,
            latitude: 50.0100731,
            longitude: 14.4069322,
        },
        {
            obec: 'Praha 13',
            kod: 539694,
            okres: 'Praha',
            kod_okres: 'CZ010D',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 15500,
            latitude: 50.0492356,
            longitude: 14.3167903,
        },
        {
            obec: 'Praha 14',
            kod: 547361,
            okres: 'Praha',
            kod_okres: 'CZ010E',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19800,
            latitude: 50.1027253,
            longitude: 14.5521611,
        },
        {
            obec: 'Praha 15',
            kod: 547387,
            okres: 'Praha',
            kod_okres: 'CZ010F',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 10900,
            latitude: 50.0442092,
            longitude: 14.5574583,
        },
        {
            obec: 'Praha 16',
            kod: 539601,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 15300,
            latitude: 49.9883239,
            longitude: 14.3665311,
        },
        {
            obec: 'Praha 17',
            kod: 547174,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 16300,
            latitude: 50.0705081,
            longitude: 14.3009117,
        },
        {
            obec: 'Praha 18',
            kod: 547417,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19900,
            latitude: 50.1375969,
            longitude: 14.5151436,
        },
        {
            obec: 'Praha 19',
            kod: 547344,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19700,
            latitude: 50.1317189,
            longitude: 14.5516808,
        },
        {
            obec: 'Praha 20',
            kod: 538213,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19300,
            latitude: 50.1151128,
            longitude: 14.6215047,
        },
        {
            obec: 'Praha 21',
            kod: 538949,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 19016,
            latitude: 50.0757517,
            longitude: 14.6593589,
        },
        {
            obec: 'Praha 22',
            kod: 538931,
            okres: 'Praha',
            kod_okres: 'UNKNOWN',
            kraj: 'Hlavní město Praha',
            kod_kraj: 'CZ010',
            psc: 10400,
            latitude: 50.0343925,
            longitude: 14.5905172,
        },
    ],
};
