import { useEffect } from 'react';

export default function useGameMenuResize() {
    useEffect(() => {
        /*
        const menuLogoElement = document.querySelector('#menu-container .logo > h2');
        menuLogoElement.classList.add('logo-not-displayed');
        return () => {
            menuLogoElement.classList.remove('logo-not-displayed');
        };
         */
    }, []);
}
