const gameModes = {
    randomRegion: 'randomRegion',
    random: 'random',
    geolocation: 'geolocation',
    city: 'city',
    custom: 'custom',
    heraldry: 'heraldry',
};

export default gameModes;
